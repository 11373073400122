import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { Pettycashrequest } from '../../Models/Pettycashrequest';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class PettycashService {
  public _Action: IAction;
  public _PettycashService = environment.PettycashService;
  public _httpOptions;
  public _PettycashList: Pettycashrequest[] = [];
  token: any;
  header: any;

  constructor(private http: HttpClient, private _CustomExceptionService: CustomExceptionService) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
   }
   createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(p_Pettycashid: number): Observable<any> {
    const headers = this.header;
    var Query = this._PettycashService + '/' + p_Pettycashid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll(userid:number,branchid: number,roleid:number,fromdate:string,todate:string) {
    const headers = this.header;
    var Query = this._PettycashService + '/FetchAll/' +userid+'/'+ branchid +'/'+roleid+'/'+fromdate+'/'+todate;
   
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }
  create(p_Pettycashid: object) {
    const headers = this.header;
    console.log("Create");
    var Query = this._PettycashService;
    return this.http.post<{ data: any }>(Query, p_Pettycashid, { headers });
  }
  edit(p_Pettycash: object) {
    const headers = this.header;
    console.log("Update");
    var Query = this._PettycashService;
   
    return this.http.put<{ data: any }>(Query, p_Pettycash, { headers });
  }
  Cancel(pettycashid : number)
  { 
    const headers = this.header;
    console.log("cancel");
    var Query = this._PettycashService  +'/Cancel/' + pettycashid ; 
   
    return this.http.get<{data:any}>(Query, { headers });
  }
  SendToApproval(userid:number,branchid:number,pettycashid:number) {
    const headers = this.header;
    console.log("Send To Approval");
    var Query = this._PettycashService+ '/SendToApproval' + '/' +userid +'/' +branchid + '/' +pettycashid ;
   
    return this.http.post<{ data: any }>(Query,'', { headers });
  }
}
