export class Journaldetail {
    journaldetailid: number;
    journalid: number;
    account: number;
    refType: string;
    refNo: string;
    debitamount: number;
    creditamount: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    entity : string;
    entityreference : string;
    currencyuniquerefid:number
}