import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { vBranchCode, vDateSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { BlogService } from 'src/app/core/Services/ecommerce/blog.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { DatePipe } from '@angular/common';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Blogheader } from 'src/app/core/Models/Blogheader';
import { NgxImageCompressService } from 'ngx-image-compress';
declare var $: any;
@Component({
  selector: 'app-c-blog',
  templateUrl: './c-blog.component.html',
  styleUrls: ['./c-blog.component.css']
})
export class CBlogComponent implements OnInit {
  toolbarOptions: string;
  disabled: boolean = true;
  pageURL = '';
  _IsProgressSpinner: boolean;
  isDisabledSave: boolean;
  isDisabledClear: boolean;
  _blogid: any;
  blogheaderform: FormGroup;
  _submitted: boolean;
  _BLOG: any;
  BLOGHEADER: Blogheader;
  isview: boolean = false;
  iseditorview: boolean = false;


  _Iscreatefileupload: boolean = true;
  _Isviewfileupload: boolean = true;

  fileUpload: any;
  imageChangedEvent: any;
  croppedImage: any;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  _action: string = "create";
  iii: string;
  blogobj: any;
  _BlogFORM: FormData;
  _CropImage: File;
  blog: any;
  displayimagepopup: boolean;
  position1: string;
  _LastAddedImage: File;
  imageselect: string = "";

  disablereset: boolean = false;
  clearimage: boolean = false
  imageCropperTool: boolean;
  _status: any[] = [];
  Weburl: any;
  Mobileurl: any;
  _WebImage: File;
  _MobileImage: File;
  constructor(private utility: CommonUtilities, private _BlogService: BlogService,
    private _DomSanitizationService: DomSanitizer, private fb: FormBuilder, private _router: Router, private _CustomExceptionService: CustomExceptionService,
    private imageCompress: NgxImageCompressService) { }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    // console.log("Image", this.imageChangedEvent);
    this.imageCropperTool = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    // console.log(event, base64ToFile(event.base64));
    //console.log("crop",this.croppedImage);
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {

  }

  loadImageFailed() {
  }
  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  InitializeForm() {
    this.blogheaderform = this.fb.group({
      blogid: [0],
      pagename: ['', vBranchCode],
      pageurlname: [],
      description: [""],
      template: [],
      blogdate: ['', vDateSelect],
      recordstatus: ["MSC00001"],
      createdon: [],
      createdby: [],
      modifiedon: [],
      modifiedby: [],
      recordstatusname: [],
      // createbyname:[]
      createdbyname: [],
      blogurl: [],
      blogurlname: [],
      blogyear: [],
      blogmonth: [],
      mobileblogurl: [],
      mobileblogurlname: [],
    });

  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();

    // this.minDate = new Date();

    this._blogid = history.state?.blogid ? history.state?.blogid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    if (this._action == 'create') {
      this._Iscreatefileupload = true;
      this._Isviewfileupload = false;
    }

    if (this._action == 'create') {
      this.blogheaderform.get("pageurlname").disable();
      this.isview = true; this.iseditorview = false;
    }
    if (this._action == 'view') {
      this.blogheaderform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
      this.isview = false; this.iseditorview = true;
    }

    if (this._action == 'edit') {
      this.blogheaderform.get("pageurlname").disable();
      this.isDisabledClear = true;
      this.isDisabledSave = false;
      this.isview = true; this.iseditorview = false;
    }
    this._IsProgressSpinner = true;
    this._BlogService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._status = resultJSON.recordstatuslst
      $(document).on("change", ".file-upload-field", function () {
        $(this).parent(".file-upload-wrapper").attr("data-text", $(this).val().replace(/.*(\/|\\)/, ''));
      });
      this._IsProgressSpinner = false;
    });
    //For Update
    this._blogid = history.state.blogid;
    this._IsProgressSpinner = true;
    if (this._action == 'edit' || this._action == 'view') {
      this._Isviewfileupload = true;
      this._Iscreatefileupload = false;
      this._BlogService.View(this._blogid).subscribe((result) => {
        const updateJSON = JSON.parse(JSON.stringify(result));

        if (updateJSON) {
          updateJSON.blogheaderviews.blogdate = new Date(updateJSON.blogheaderviews.blogdate);
        }
        this.blogheaderform.setValue(updateJSON.blogheaderviews);
        if (updateJSON.blogheaderviews.blogurl) {
          this.blog = updateJSON.blogheaderviews.blogurl;
        }
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  showImageDialog(position1: string) {
    if (this._LastAddedImage) {
      this.blog = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedImage));
      this.imageselect = this._LastAddedImage.name;
    }
    if (this.blog == null) {
      return;
    }
    var url = this.blog

    window.open(url, "MsgWindow", "width=800,height=800");

  }
  onImageClear() {
    this._Iscreatefileupload = true;
    this._Isviewfileupload = false;
    this.blog = null;
    this._LastAddedImage = null;
    this._CropImage = null
  }
  selectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      const img = new Image();
      img.src = URL.createObjectURL(event.target.files[0]);
      img.onload = (e: any) => {
        if ( e.srcElement.width == 800 && e.srcElement.height == 450) {
          var reader = new FileReader();
        reader.onload = (event: any) => {
          let url = event.target.result;
          this.webcompressFile(url, -1)
        }
        reader.readAsDataURL(event.target.files[0]);
        } else {
          $(".file-upload-wrapper").attr("data-text", 'Select your file!');
          this._CustomExceptionService.handleWarning("Your uploaded image should be minimum Width and height of 800px X 450px")
        }
      }
     
     
    }
  }

  webcompressFile(image: any, orientation: any) {
    this.imageCompress
      .compressFile(image, orientation, 100, 70)
      .then((result: any) => {
        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        this.Weburl = imageBlob;
        this.mobilecompressFile(result, -1);
      });

  }
  mobilecompressFile(image: any, orientation: any) {
    this.imageCompress
      .compressFile(image, orientation, 75, 70)
      .then((result: any) => {
        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        this.Mobileurl = imageBlob
        this.createBlobImageFileAndShow(this.Weburl, this.Mobileurl);
      });
    // });
  }
  createBlobImageFileAndShow(web: any, mobile: any): void {
    // const imageBlob: Blob = base64ToFile(this.croppedImage);
    const webimageName: string = this.generateName();
    const mobileimageName: string = webimageName;
    this._WebImage = new File([web], webimageName, {
      type: "image/webp"
    });
    this._MobileImage = new File([mobile], mobileimageName, {
      type: "image/webp"
    });
  }

  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/webp' });
    return blob;
  }
  formconverstion(_Blog: Blogheader, _webimage: File, _mobileimage: File) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    if (_webimage != undefined && _mobileimage != undefined) {
      if (_Blog.blogurlname == this._WebImage.name && _Blog.mobileblogurlname == this._MobileImage.name) {

        // var file = base64ToFile(this.croppedImage);
        var WebNewFileName = time.getTime() + '_' + this._WebImage.name;
        var MobileNewFileName = time.getTime() + '_' + this._MobileImage.name;
        _Blog.blogurlname = WebNewFileName;
        _Blog.blogurl = MobileNewFileName;
        _Blog.mobileblogurl = MobileNewFileName;
        _Blog.mobileblogurlname = MobileNewFileName;
        formData.append('webfile', _webimage, WebNewFileName);
        formData.append('mobilefile', _mobileimage, MobileNewFileName);
      }
    }

    this.blogobj = {
      "Blogheader": this.BLOGHEADER


    };
    var blogs = JSON.stringify(this.blogobj);
    formData.append('strBlog', blogs);
    return (formData);
  }
  // createBlobImageFileAndShow(): void {
  //   const imageBlob: Blob = base64ToFile(this.croppedImage);
  //   const imageName: string = this.generateName();
  //   this._CropImage = new File([imageBlob], imageName, {
  //     type: "image/jpeg"
  //   });


  // }



  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = "";
    const possibleText: string =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + "." + text + ".webp";
  }

  saveImage(event) {
    // 530 x 285
    var imgWidth = $('.source-image').get(0).naturalWidth;
    var imgheight = $('.source-image').get(0).naturalHeight;
    if (imgWidth >= 800 && imgheight >= 450) {
      // this.createBlobImageFileAndShow();
    } else {
      this._CustomExceptionService.handleWarning("Your uploaded image should be minimum Width and height of 530px X 285px")
    }
    this.imageCropperTool = false
  }


  onSave() {
    this._submitted = true;
    this.BLOGHEADER = this.blogheaderform.getRawValue();
    this.BLOGHEADER.createdon = new Date();
    var _blogdate = this.blogheaderform.get("blogdate").value;
    var datePipe = new DatePipe("en-US");
    this.BLOGHEADER.blogdate = new Date(datePipe.transform(_blogdate, 'yyyy-MM-dd'));
    this.BLOGHEADER.createdby = sessionStorage["userid"];
    this.BLOGHEADER.modifiedon = new Date();
    this.BLOGHEADER.modifiedby = sessionStorage["userid"];
    if (this.blogheaderform.valid) {

      if (this._action == 'create') {
        // console.log("YEs");
        if (this._WebImage == undefined || this._WebImage == null || this._MobileImage == undefined || this._MobileImage == null) {
          this._CustomExceptionService.handleWarning("Please ADD Blog image");
          return
        } else {
          this.BLOGHEADER.blogurl = this._WebImage.name;
          this.BLOGHEADER.blogurlname = this._WebImage.name;
          this.BLOGHEADER.mobileblogurl = this._MobileImage.name;
          this.BLOGHEADER.mobileblogurlname = this._MobileImage.name;
        }

        this._IsProgressSpinner = true;
        this._BlogFORM = this.formconverstion(this.BLOGHEADER, this._WebImage, this._MobileImage);
        this._BlogService.Create(this._BlogFORM).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
      else if (this._action == 'edit') {
        // if (this._CropImage == null) {
        //   this._CustomExceptionService.handleWarning("Please ADD Blog image");
        //   return
        // }
        if (this._WebImage == undefined && this._MobileImage == undefined) {
          this.BLOGHEADER.blogurl = this.blogheaderform.get("blogurl").value;
          this.BLOGHEADER.blogurlname = this.blogheaderform.get("blogurlname").value;
          this.BLOGHEADER.mobileblogurl = this.blogheaderform.get("mobileblogurl").value;
          this.BLOGHEADER.mobileblogurlname = this.blogheaderform.get("mobileblogurlname").value;
        }
        if (this._WebImage && this._MobileImage) {
          this.BLOGHEADER.blogurl = this._WebImage.name;
          this.BLOGHEADER.blogurlname = this._WebImage.name;
          this.BLOGHEADER.mobileblogurl = this._MobileImage.name;
          this.BLOGHEADER.mobileblogurlname =this._MobileImage.name;
        }
        this._IsProgressSpinner = true;
        this._BlogFORM = this.formconverstion(this.BLOGHEADER , this._WebImage, this._MobileImage);
        this._BlogService.Update(this._BlogFORM).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
    }
  }
  // onKey(value: string) {
  //   var chars = {',':'','&':'',' ':'-'};
  //   this.pageURL = value.replace(/[,& ]/g, m => chars[m]).toLowerCase();
  //   this.blogheaderform.controls["pageurlname"].setValue(this.pageURL)
  // }
  onKey(value: string) {
    var chars = { ',': '', '&': '', ' ': '-' };
    this.pageURL = value.replace(/[,& ]/g, m => chars[m]).toLowerCase();
  }

  reset(event) {
    this._submitted = true;
    this.blogheaderform.reset();
    this.isDisabledSave = false;
    this.isDisabledClear = false;
    this.InitializeForm();
    this._submitted = false;
    this.imageselect = "";
    $(".file-upload-wrapper").attr("data-text", 'Select your file!');
  }

  goBack(event) {
    this._router.navigate(['/vBlog']);
  }

}
