import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Quoterequest } from 'src/app/core/Models/Quoterequest';
import { Quoterequestpaymentterm } from 'src/app/core/Models/Quoterequestpaymentterm';
import { Quoterequestproductdetail } from 'src/app/core/Models/Quoterequestproductdetail';
import { Quoterequestsupplierdetail } from 'src/app/core/Models/Quoterequestsupplierdetail';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Termsandcondition } from 'src/app/core/Models/Termsandcondition';
import { RequestforquoteService } from 'src/app/core/Services/Purchase/requestforquote.service';
import { vContactPersonName, vDeliveryTermsSelect, vEmailID, vExpectedDeliveryDateSelect, vMobileNumber, vOtherTermsSelect, vPaymentTermsSelect, vProductName, vQuantity, vSelectSupplier, vSelectSupplierName, vSupplierName, vSupplierNameSelect, vSupplierType, vUOMSelect } from 'src/app/core/Validators/validation';
import { Quoterequestproductdetailsview } from 'src/app/core/Views/Quoterequestproductdetailsview';

import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { Supplieraddressdetail } from 'src/app/core/Models/Supplieraddressdetail';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { KeyPress } from 'src/assets/js/KeyPress';
import { Supplierview } from 'src/app/core/Views/Supplierview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
  selector: 'app-c-requestforquote',
  templateUrl: './c-requestforquote.component.html',
  styleUrls: ['./c-requestforquote.component.css']
})
export class CRequestforquoteComponent implements OnInit {
  _selectedPaymentIndex: number[] = [];
  _selectedDeliveryIndex: number[] = [];
  _selectedOtherIndex: number[] = [];
  _Quoterequestform: FormGroup;
  _Quoterequestproductdetailform: FormGroup;
  _Quoterequestsupplierdetailform: FormGroup;
  _Quoterequestpaymenttermform: FormGroup;
  _supplierquicksaveform: FormGroup;
  _supplierquicksavedetailform: FormGroup;
  _quoteothertermsform: FormGroup;
  _Quoterequest: Quoterequest;
  _quoterequestid: number;
  _validate: boolean;
  _validateproduct: boolean;
  _userid: number;
  _supplierquicksave: Supplier;
  _supplierquicksavedetail: Supplieraddressdetail;
  _Quoterequestproductdetails: Quoterequestproductdetail[] = [];
  SelectedProductDetailsrows: any;
  _Quoterequestproductdetail: Quoterequestproductdetail;
  _Quoterequestsupplierdetail: Quoterequestsupplierdetail;
  _Quoterequestsupplierdetails: Quoterequestsupplierdetail[] = [];

  filteredProducts: any[];

  _paymentterms: Termsandcondition[];
  _deliveryterms: Termsandcondition[];
  _otherterms: Termsandcondition[];
  _action: string = "create";
  isDisabledsave = false;
  isDisablereset = false;
  isDisabledSendEmail = true;
  isDisabledremovesupplier = true;
  isDisabledremoveproduct = false;
  isDisabledaddproduct = false;
  isDisabledclearproduct = false;
  isDisableasuplier = false;
  _producttype: Producttype[] = [];
  _productname: Productoverallview[] = [];
  _variantdetails: Productvariant[] = [];
  _variant: Productvariant[] = [];
  _suppliername: Supplierview[] = [];
  _suppliertype: Metadatum[] = [];
  _uomdetails: Metadatum[] = [];
  _uomtemp: Metadatum[] = [];
  _Supplieraddressdetailform: FormGroup
  _submitted = false;
  _productsubmitted = false;
  _suppliersubmitted = false;

  ManageDialog: Boolean;
  _productcategory: Productcategory[] = [];
  _QuestOBJ: any;
  _Questquicksaveobj: any;
  _SelectedPaymentTerm: Quoterequestpaymentterm;
  _SelectedPaymentTerms: Quoterequestpaymentterm[] = [];
  _SelectedDeliveryTerm: Quoterequestpaymentterm;
  _SelectedDeliveryTerms: Quoterequestpaymentterm[] = [];
  _SelectedOtherTerm: Quoterequestpaymentterm;
  _SelectedOtherTerms: Quoterequestpaymentterm[] = [];
  _branchid = sessionStorage["currentbranchid"]
  _companyid: number;
  _selectedsupplierid: number[] = [];
  _selectedpaymentid: number[] = [];
  _selectedothertermsid: number[] = [];
  _selecteddeliveryid: number[] = []
  _prevSelectedsupplier: Quoterequestsupplierdetail[] = []
  _suppliernamequicksave: string;
  _contactpersonnamequicksave: string;
  _phonenoquicksave: string;
  _emailidquicksave: string;
  _supplierquicksavedetails: Supplieraddressdetail[] = [];
  isDisableaddsuplier = true;
  isDisableaddsupliername = true;
  //13.sendmail button should be disable
  isDisabledissendmail = true;
  isDisabledpayment = true;
  isDisableddelivery = true;
  isDisabledother = true;
  isDisablequicksave = false;
  isDisablePaymentTerms: boolean = false;
  isDisableDeliveryTerms: boolean = false;
  isDisableOtherTerms: boolean = false;
  _IsProgressSpinner: boolean = true;
  today = new Date();
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productuom: any;
  isdisableduom = true;
  addressRowIndex: number = -1;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _Action: IAction;
  hidequantity: boolean = true;
  mindate: Date;
  maxdate: Date;

  //_branchid = sessionStorage["BranchID"];
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private utility: CommonUtilities, private _Requestforquoteservice: RequestforquoteService, private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService, private _router: Router, public keypress: KeyPress,
    private _AccessRightsService: AccessRightsService
  ) {
    this._quoterequestid = history.state?.quoterequestid ? history.state?.quoterequestid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();

  }
  InitializeForm() {
    this._Quoterequestform = this.fb.group({
      companyid: [],
      branchid: [],
      quoterequestid: [],
      quoterequestno: [],
      quoterequestdate: [],
      expecteddeliverydate: ['', vExpectedDeliveryDateSelect],
      remarks: [],
      status: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      rv: [],
      suppliertype: ['', vSupplierType],
      quoterequestpaymentterms: [],
      quoterequestproductdetails: [],
      quoterequestsupplierdetails: []
    });
    this._Quoterequestproductdetailform = this.fb.group({
      quoterequestdetailid: [],
      quoterequestid: [],
      producttypeid: [],
      productcategoryid: [],
      productid: ['', vProductName],
      variantid: [],
      quantity: [],
      uom: ['', vUOMSelect],
      remarks: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
    this._Quoterequestsupplierdetailform = this.fb.group({
      quoterequestsupplierdetailid: [],
      quoterequestid: [],
      supplierid: ['', vSelectSupplier],
      submitteddate: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
    this._supplierquicksaveform = this.fb.group({
      supplierid: [],
      suppliercode: [],
      suppliername: ['', vSupplierName],
      suppliertypecode: [],
      suppliercategorycode: [],
      //accountgroupcode: [],
      currencyid: [1],
      paymodecode: [],
      contactpersonname: [],
      mobileno: [],
      phoneno: [],
      address: [],
      emailid: [],
      countryid: [],
      stateid: [],
      vatno: [],
      bpno: [],
      faxno: [],
      vattype: [],
      credittype: [],
      creditlimit: [],
      creditdays: [],
      series: [],
      supplierrating: [],
      supplierstatus: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
    this._supplierquicksavedetailform = this.fb.group({
      supplieraddressdetailid: [0],
      supplierid: [],
      contactpersonname: ['', vContactPersonName],
      mobileno: ['', vMobileNumber],
      phoneno: [],
      address: [],
      mailid: ['', vEmailID],
      countryid: [],
      stateid: [],
      districtid: [],
      faxno: [],
      isdfaultaddress: [],
    });
    this._quoteothertermsform = this.fb.group({
      paymenttermsid: [],
      deliveryermsid: [],
      othertermsid: []
    });
    this._Quoterequestform.controls['quoterequestdate'].setValue(new Date());
    this._Quoterequestform.controls['expecteddeliverydate'].setValue(new Date());
    if (sessionStorage['Environmentenddate'] == "null") {
      this._Quoterequestform.controls['quoterequestdate'].setValue(new Date());
      this._Quoterequestform.controls['expecteddeliverydate'].setValue(new Date());
      this.maxdate = new Date();
    }
    else {
      this._Quoterequestform.controls['quoterequestdate'].setValue(new Date(sessionStorage['Environmentenddate']));
      this._Quoterequestform.controls['expecteddeliverydate'].setValue(new Date(sessionStorage['Environmentenddate']));
    }
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(38);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();
    var userid = parseInt(sessionStorage["userid"]);
    this.hidequantity = true;
    this._IsProgressSpinner = true;
    this._Requestforquoteservice.PageOnLoad(this._quoterequestid, this._branchid, userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //5.Quote Date should fetch current date and  previous date and future date should not allow
      // this._Quoterequestform.controls['quoterequestdate'].setValue(new Date());
      // this._Quoterequestform.controls['expecteddeliverydate'].setValue(new Date());
      this._producttype = resultJSON.productTypeList;
      this._productcategory = resultJSON.productCategoriesList;
      this._variantdetails = resultJSON.productVariantList;
      this._suppliertype = resultJSON.supplierTypeList;

      // this._productname = resultJSON.productoverallList;
      //12.in grid uom dropdown should load from metadata table
      this._uomdetails = resultJSON.uomList;
      // this._uomtemp = resultJSON.uomList;

      this._IsProgressSpinner = false;

      if (this._action == 'create') {
        this.isDisableaddsuplier = false;
        this.isDisabledpayment = false;
        this.isDisableddelivery = false;
        this.isDisabledother = false;
      }
      if (this._action == 'view') {
        //49.all the controls should disable
        this.isDisabledsave = true;
        this.isDisablereset = true;
        this.isDisabledaddproduct = true;
        this.isDisabledclearproduct = true;
        this.isDisableasuplier = true;
        this.isDisabledremoveproduct = true;
        this.isDisabledremovesupplier = true;
        this.isDisableaddsuplier = true;
        this.isDisableaddsupliername = true;
        this.isDisabledpayment = false;
        this.isDisableddelivery = false;
        this.isDisabledother = false;
        this.isDisabledSendEmail = true;
        this.isDisablePaymentTerms = true;
        this.isDisableDeliveryTerms = true;
        this.isDisableOtherTerms = true;
        this._Quoterequestform.disable();
        this._Quoterequestproductdetailform.disable();

      }
      if (this._action == 'edit') {
        this.isDisabledsave = false;
        this.isDisablereset = true;
        this.isDisabledaddproduct = false;
        this.isDisabledclearproduct = false;
        this.isDisableaddsuplier = false;
        this.isDisabledremoveproduct = false;
        this.isDisabledpayment = false;
        this.isDisableddelivery = false;
        this.isDisabledother = false; ``
        this.isDisabledSendEmail = true;
      }
      if (this._action == 'edit' || this._action == 'view') {
        //48. if all the data is valid allow to view the record from the database based on requestforquote 
        const updateJSON = JSON.parse(JSON.stringify(result));
        // this._suppliername = resultJSON.supplierList;

        updateJSON.quoterequestheaderlist[0].quoterequestdate = new Date(updateJSON.quoterequestheaderlist[0].quoterequestdate);
        updateJSON.quoterequestheaderlist[0].quoterequestno = updateJSON.quoterequestheaderlist[0].quoterequestno;
        updateJSON.quoterequestheaderlist[0].expecteddeliverydate = new Date(updateJSON.quoterequestheaderlist[0].expecteddeliverydate);

        this._Quoterequestform.setValue(updateJSON.quoterequestheaderlist[0]);
        this._suppliername = updateJSON.supplierList;
        this._Quoterequestsupplierdetails = updateJSON.quoterequestsupplierlist;
        var supplierid: number[] = [];
        Object.keys(updateJSON.quoterequestsupplierlist).map((Index) => {
          supplierid.push(updateJSON.quoterequestsupplierlist[Index].supplierid);
        });
        this._selectedsupplierid = supplierid;
        this._Quoterequestproductdetails = updateJSON.quoterequestproductlist;
        // 9.delivery terms checkbox should load from terms&conditions master based on terms type as (delivery)
        this._deliveryterms = resultJSON.deliveryTermsList;
        // 10.others terms checkbox should load from terms&conditions master based on terms type as (others)
        this._otherterms = resultJSON.otherTermsList;
        // 8.payment terms checkbox should load from terms &conditions master based on terms type as  (purchase)
        this._paymentterms = resultJSON.paymentTermsList;
        var termsandconditionidpayment: number[] = [];
        Object.keys(updateJSON.quoterequespaymenttermslist).map((Index) => {
          termsandconditionidpayment.push(updateJSON.quoterequespaymenttermslist[Index].termsandconditionid);
        });
        this._selectedPaymentIndex = termsandconditionidpayment;
        this._SelectedPaymentTerms = updateJSON.quoterequespaymenttermslist;

        var termsandconditioniddelivery: number[] = [];
        Object.keys(updateJSON.quoterequesdeliverytermslist).map((Index) => {
          termsandconditioniddelivery.push(updateJSON.quoterequesdeliverytermslist[Index].termsandconditionid);
        });
        this._selectedDeliveryIndex = termsandconditioniddelivery;
        this._SelectedDeliveryTerms = updateJSON.quoterequesdeliverytermslist;

        var termsandconditionidother: number[] = [];
        Object.keys(updateJSON.quoterequesothertermslist).map((Index) => {
          termsandconditionidother.push(updateJSON.quoterequesothertermslist[Index].termsandconditionid);
        });
        this._selectedOtherIndex = termsandconditionidother;
        this._SelectedOtherTerms = updateJSON.quoterequesothertermslist;
      }

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  //Customers Quick save
  quicksave() {
    this._suppliersubmitted = true;
    if (this._supplierquicksaveform.valid) {
      if (this._supplierquicksavedetailform.valid) {
        var l_supplieraddressdetails: Supplieraddressdetail[] = [];
        var l_supplieraddressdetail = new Supplieraddressdetail();
        l_supplieraddressdetail.supplieraddressdetailid = 0;
        l_supplieraddressdetail.supplierid = 0;
        l_supplieraddressdetail.mobileno = this._supplierquicksavedetailform.get("mobileno").value;
        l_supplieraddressdetail.contactpersonname = this._supplierquicksavedetailform.get("contactpersonname").value;
        l_supplieraddressdetail.mailid = this._supplierquicksavedetailform.get("mailid").value;
        l_supplieraddressdetails.push(l_supplieraddressdetail);
        this._supplierquicksavedetails = l_supplieraddressdetails;

        this._supplierquicksave = this._supplierquicksaveform.value;
        this._supplierquicksave.supplierid = 0;
        this._supplierquicksave.currencyid = 1;
        this._supplierquicksave.createdby = parseInt(sessionStorage["userid"]);
        this._supplierquicksave.branchid = parseInt(sessionStorage["BranchID"]);
        this._supplierquicksave.createdon = new Date();
        this._Questquicksaveobj = {
          "Supplier": this._supplierquicksave, "Supplieraddressdetail": this._supplierquicksavedetails
        };
        this.isDisablequicksave = true;
        //this._IsProgressSpinner = true;
        //this.onEd();
        this._Requestforquoteservice.SupplierQuickSave(this._Questquicksaveobj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._Quoterequestform.get("suppliertype").setValue("MSC00135");
            this.getSupplier();
            this._IsProgressSpinner = false;
            this.ManageDialog = false;
            this.isDisablequicksave = false;
            this.cancel(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            this.isDisablequicksave = false;
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isDisablequicksave = false; });
      }
      this._suppliersubmitted = false;
    }
  }
  onAddProduct(event) {
    this._productsubmitted = true;
    if (this._Quoterequestproductdetailform.valid) {
      var Quoterequestproductdetail = new Quoterequestproductdetailsview();
      Quoterequestproductdetail.productid = this._Quoterequestproductdetailform.get("productid").value;
      Quoterequestproductdetail.quoterequestdetailid = 0;
      Quoterequestproductdetail.quantity = this._Quoterequestproductdetailform.get("quantity").value;
      var uomcode = (this._Quoterequestproductdetailform.get("uom").value);
      var uomname = (this._uomdetails || []).filter(f => f.metasubcode == uomcode)[0].metasubdescription;
      Quoterequestproductdetail.uom = uomcode;
      Quoterequestproductdetail.uomname = uomname;
      if (this._Quoterequestproductdetailform.get("variantid").value == "" || this._Quoterequestproductdetailform.get("variantid").value == null) {
        Quoterequestproductdetail.variantid = 0;
        Quoterequestproductdetail.variantname = "";
      }
      else {
        var variantid = (this._Quoterequestproductdetailform.get("variantid").value);
        var variantname = (this._variantdetails || []).filter(f => f.productvariantdetailid == variantid)[0].variantdescription;
        Quoterequestproductdetail.variantid = parseInt(variantid);
        Quoterequestproductdetail.variantname = variantname;
      }
      var productid = (this._Quoterequestproductdetailform.get("productid").value.productid);
      var productname = (this._productname || []).filter(f => f.productid == productid)[0].productname;
      Quoterequestproductdetail.productid = parseInt(productid);
      Quoterequestproductdetail.productname = productname;
      Quoterequestproductdetail.createdby = parseInt(sessionStorage["userid"]);
      Quoterequestproductdetail.modifiedby = parseInt(sessionStorage["userid"]);
      Quoterequestproductdetail.modifiedon = new Date();
      Quoterequestproductdetail.createdon = new Date();
      this._productsubmitted = false;
      this._Quoterequestproductdetailform.controls["producttypeid"].reset();
      this._productcategory = [];
      this.productcatid = [];
      this._Quoterequestproductdetailform.controls["productcategoryid"].reset();
      this._Quoterequestproductdetailform.controls["productid"].reset();
      this._Quoterequestproductdetailform.controls["variantid"].reset();
      this._Quoterequestproductdetailform.controls["quantity"].reset();
      this._Quoterequestproductdetailform.controls["uom"].reset();
      if (this._Quoterequestproductdetails.length > 0) {
        for (var i = 0; i < this._Quoterequestproductdetails.length; i++) {
          if (Quoterequestproductdetail.productid == this._Quoterequestproductdetails[i].productid && Quoterequestproductdetail.variantid == this._Quoterequestproductdetails[i].variantid) {
            this._CustomExceptionService.handleWarning("Product or Variant Already Exists!");
            return;
          }
        }
        this._Quoterequestproductdetails.push(Quoterequestproductdetail);

      } else {
        this._Quoterequestproductdetails.push(Quoterequestproductdetail);
      }
    }
  }
  cancel(event) {
    this.ManageDialog = false;
    this._supplierquicksaveform.reset();
    this._supplierquicksavedetailform.reset();
  }
  clear(event) {
    //25.If clear clicking all fields should clear in grid
    this._Quoterequestproductdetailform.reset();
    this._productcategory = [];
    this.productcatid = [];
  }
  RemoveProductDetail(pdetail: Quoterequestproductdetailsview) {
    if (this._Quoterequestproductdetails.length >= 1) {
      var index = this._Quoterequestproductdetails.indexOf(pdetail);
      this._Quoterequestproductdetails.splice(index, 1)
    }
  }




  onSelectPaymentTerms(event) {
    var l_Paymentterms: Quoterequestpaymentterm[] = [];
    var l_paymentterm: Quoterequestpaymentterm;
    Object.keys(event.value).map((Index) => {
      l_paymentterm = new Quoterequestpaymentterm();
      var l_paymenttermlist: Termsandcondition[] = this._paymentterms;
      l_paymentterm.termsandconditionid = event.value[Index];
      l_paymentterm.termdescription = l_paymenttermlist[Index].termsdescription;
      l_paymentterm.termtype = l_paymenttermlist[Index].termstype;
      l_paymentterm.quoterequestid = 0;
      l_paymentterm.quotedetailid = 0;
      l_paymentterm.createdon = new Date();
      l_paymentterm.createdby = parseInt(sessionStorage["userid"]);
      l_paymentterm.modifiedby = parseInt(sessionStorage["userid"]);
      l_paymentterm.modifiedon = new Date();
      l_Paymentterms.push(l_paymentterm);
    })
    this._SelectedPaymentTerms = l_Paymentterms;
  }
  onselectSupplier(event) {
    var Selectedsuppliier = event.value;
    if (Selectedsuppliier.length == 0 || Selectedsuppliier == null) {
      return;
    }
    else {
      var l_quoterequestsupplierdetails: Quoterequestsupplierdetail[] = [];
      var l_quoterequestsupplierdetail: Quoterequestsupplierdetail;
      Object.keys(event.value).map((Index) => {
        l_quoterequestsupplierdetail = new Quoterequestsupplierdetail();
        l_quoterequestsupplierdetail.supplierid = event.value[Index];
        l_quoterequestsupplierdetail.quoterequestid = 0;
        l_quoterequestsupplierdetail.quoterequestsupplierdetailid = 0;
        l_quoterequestsupplierdetail.createdon = new Date();
        l_quoterequestsupplierdetail.submitteddate = new Date();
        l_quoterequestsupplierdetail.createdby = parseInt(sessionStorage["userid"]);
        l_quoterequestsupplierdetail.modifiedby = parseInt(sessionStorage["userid"]);
        l_quoterequestsupplierdetail.modifiedon = new Date();
        l_quoterequestsupplierdetails.push(l_quoterequestsupplierdetail);
      })
      this._Quoterequestsupplierdetails = l_quoterequestsupplierdetails;
      this.onchangesuppliername(event);
    }
  }
  onchangesuppliername(event) {
    var selectedProductType = event.value;
    this._Requestforquoteservice.onchangesuppliername(selectedProductType).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSelectOtherTerms(event) {
    var l_Otherterms: Quoterequestpaymentterm[] = [];
    var l_otherterm: Quoterequestpaymentterm;
    var l_othertermlist: Termsandcondition[] = this._otherterms;
    Object.keys(event.value).map((Index) => {
      l_otherterm = new Quoterequestpaymentterm();
      l_otherterm.termsandconditionid = event.value[Index];
      l_otherterm.termdescription = l_othertermlist[Index].termsdescription;
      l_otherterm.termtype = l_othertermlist[Index].termstype;
      l_otherterm.quoterequestid = 0;
      l_otherterm.quotedetailid = 0;
      l_otherterm.createdon = new Date();
      l_otherterm.createdby = parseInt(sessionStorage["userid"]);
      l_otherterm.modifiedby = parseInt(sessionStorage["userid"]);
      l_otherterm.modifiedon = new Date();
      l_Otherterms.push(l_otherterm);
    })
    this._SelectedOtherTerms = l_Otherterms;
  }
  onSelectDeliveryTerms(event) {
    var l_Deliveryterms: Quoterequestpaymentterm[] = [];
    var l_deliveryterm: Quoterequestpaymentterm;
    var l_deliverytermlist: Termsandcondition[] = this._deliveryterms;
    Object.keys(event.value).map((Index) => {
      l_deliveryterm = new Quoterequestpaymentterm();
      l_deliveryterm.termsandconditionid = event.value[Index];
      l_deliveryterm.termdescription = l_deliverytermlist[Index].termsdescription;
      l_deliveryterm.quoterequestid = 0;
      l_deliveryterm.quotedetailid = 0;
      l_deliveryterm.termtype = l_deliverytermlist[Index].termstype;
      l_deliveryterm.createdon = new Date();
      l_deliveryterm.createdby = parseInt(sessionStorage["userid"]);
      l_deliveryterm.modifiedby = parseInt(sessionStorage["userid"]);
      l_deliveryterm.modifiedon = new Date();
      l_Deliveryterms.push(l_deliveryterm);
    })
    this._SelectedDeliveryTerms = l_Deliveryterms;
  }

  validate(): boolean {
    //29.if "product details " is not select, show error as "Please add any one of the product details"(grid column is 0)
    if (this._Quoterequestproductdetails.length == 0) {
      this._validate = false;
    }
    else {
      this._validate = true;
    }
    return this._validate;
  }
  onSave() {
    //24.if all the fields valid should add the row in grid
    this._submitted = true;

    if (this._Quoterequestform.valid) {
      if (this._Quoterequestsupplierdetailform.valid) {
        var RequestDate = this._Quoterequestform.get("quoterequestdate").value;
        var DeliveryDate = this._Quoterequestform.get("expecteddeliverydate").value;
        var datePipe = new DatePipe("en-US");
        var pRequestDate = datePipe.transform(RequestDate, 'yyyy-MM-dd');
        var pDeliveryDate = datePipe.transform(DeliveryDate, 'yyyy-MM-dd');
        this.validate();
        if (this._validate == true) {
          this.isDisabledsave = true;
          this._Quoterequest = this._Quoterequestform.value;
          this._Quoterequest.quoterequestdate = (new Date(pRequestDate));
          this._Quoterequest.expecteddeliverydate = (new Date(pDeliveryDate));
          this._Quoterequestform.get("quoterequestid").setValue(this._Quoterequestform.get("quoterequestid").value);
          if (this._action == 'create') {
            this._Quoterequest.branchid = parseInt(sessionStorage["BranchID"]);
            this._Quoterequest.companyid = 0;
            this._Quoterequest.quoterequestno = "0";
            this._Quoterequest.quoterequestid = 0;
            this._Quoterequest.createdon = new Date();
            this._Quoterequest.createdby = parseInt(sessionStorage["userid"]);
            this._Quoterequest.accountingyear = new Date().getFullYear();
            //33.status must save  default as active while saving.
            this._Quoterequest.status = "MSC00001";
            this._SelectedPaymentTerms = this._SelectedPaymentTerms.concat(this._SelectedOtherTerms);
            this._SelectedPaymentTerms = this._SelectedPaymentTerms.concat(this._SelectedDeliveryTerms);
            this._QuestOBJ = {
              "Quoterequest": this._Quoterequest, "Quoterequestsupplierdetails": this._Quoterequestsupplierdetails,
              "Quoterequestproductdetails": this._Quoterequestproductdetails, "Quoterequestpaymentterms": this._SelectedPaymentTerms,
            };
            this._IsProgressSpinner = true;
            this._Requestforquoteservice.create(this._QuestOBJ).subscribe((result) => {
              const resultJSON = JSON.parse(JSON.stringify(result));
              if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
                this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
                //35.after saving the record, get the response A, display the success message as "request for quote A  Registered Succesfully "
                this._IsProgressSpinner = false;
                this.reset(null);
              }
              else {
                this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
                this.isDisabledsave = false;
              }
              this._IsProgressSpinner = false;
            },
              error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isDisabledsave = false; });
            this._submitted = false;
            this._productsubmitted = false;
          }
          else if (this._action == 'edit') {
            this._Quoterequest.modifiedon = new Date();
            this._SelectedPaymentTerms = this._SelectedPaymentTerms.concat(this._SelectedOtherTerms);
            this._SelectedPaymentTerms = this._SelectedPaymentTerms.concat(this._SelectedDeliveryTerms);

            this._QuestOBJ = {
              "Quoterequest": this._Quoterequest, "Quoterequestsupplierdetails": this._Quoterequestsupplierdetails,
              "Quoterequestproductdetails": this._Quoterequestproductdetails, "Quoterequestpaymentterms": this._SelectedPaymentTerms,
            };
            //this.onEd();
            this._IsProgressSpinner = true;
            // this.onEd();
            this._Requestforquoteservice.edit(this._QuestOBJ).subscribe((result) => {
              const resultJSON = JSON.parse(JSON.stringify(result));

              if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
                this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
                this._IsProgressSpinner = false;
              }
              else {
                this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
              }
              this._IsProgressSpinner = false;
            },
              error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
            this._submitted = false;
            this._productsubmitted = false;
          }
        }
        else {
          this._CustomExceptionService.handleWarning(usererrors.Grid_Select_02);
        }
      }
    }
  }

  getSupplier() {
    var suppliertypecode = this._Quoterequestform.get("suppliertype").value;
    this._userid = sessionStorage["userid"];
    this._Quoterequestsupplierdetailform.reset();
    this._IsProgressSpinner = true;
    this._Requestforquoteservice.getSupplier(suppliertypecode, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._suppliername = resultJSON.supplierList;
      //9.delivery terms checkbox should load from terms&conditions master based on terms type as (delivery)
      this._deliveryterms = resultJSON.deliveryTermsList;
      //10.others terms checkbox should load from terms&conditions master based on terms type as (others)
      this._otherterms = resultJSON.otherTermsList;
      // 8.payment terms checkbox should load from terms &conditions master based on terms type as  (purchase)
      this._paymentterms = resultJSON.paymentTermsList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory(event) {
    //14.Product Category checkbox load from Product category master based on product type 
    this.producttypeid = event.value;
    if (this.producttypeid.length == 0 || this.producttypeid == null) {
      this._Quoterequestproductdetailform.reset();
      this._productcategory = [];
      this.productcatid = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": this.producttypeid || [], "branchid": this._branchid || 0 };
      this._Requestforquoteservice.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategory = resultJSON.productCategoriesList;
        // this._productname = <Productoverallview[]>resultJSON.productoverallList || [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    let productList = this._productname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
    this._productname = productList.length ? productList : this._productname;

  }
  getProduct(event) {
    var selectedproductcategory = event.value;
    if (selectedproductcategory.length == 0 || selectedproductcategory == null) {
      this._productname = [];
      this._variantdetails = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedproductcategory || [], "branchid": this._branchid || 0 };
      this._Requestforquoteservice.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        // this._productname = resultJSON.productList;
        this._productname = <Productoverallview[]>resultJSON.productoverallList || [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    let productList = [];
    if (this.productcatid.length) {
      productList = this._productname.filter(f => this.producttypeid.includes(f.producttypeid) && this.productcatid.includes(f.productcategoryid)) || [];
    } else {
      productList = this._productname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
    }
    this._productname = productList;
  }
  getVariant(event) {
    this._branchid = parseInt(sessionStorage["BranchID"]);
    var selectedProduct = event.productid;
    if (selectedProduct) {
      this._IsProgressSpinner = true;
      this._Requestforquoteservice.getVariant(selectedProduct, this._branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._variantdetails = resultJSON.productVariantList;
        this._productcategory = [];
        this._productcategory = resultJSON.productCategoriesList;
        this._variant = resultJSON.productVariantList;
        let product = this._productname.filter(f => f.productid == selectedProduct)[0];
        this.producttypeid = [];
        this.producttypeid.push(product.producttypeid);
        this.productcatid = [];
        this.productcatid.push(product.productcategoryid);
        this._Quoterequestproductdetailform.controls['producttypeid'].setValue(this.producttypeid);
        // this._Quoterequestproductdetailform.controls['productcategoryid'].setValue(this.productcatid);
        this._Quoterequestproductdetailform.controls['uom'].setValue(product.purchaseuom);
        if (product.purchaseuom == "MSC00037") {
          this.hidequantity = true;
        }
        else {
          this.hidequantity = false
        }
        this._IsProgressSpinner = false;

      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  showBasicDialog() {
    this.ManageDialog = true;
  }
  goBack(event) {
    this._router.navigate(['/vRequestForQuote']);
  }
  reset(event) {
    //36.clear all the fields which the data has entered
    this._Quoterequestform.reset();
    this._Quoterequestproductdetailform.reset();
    this._Quoterequestsupplierdetailform.reset();
    this._quoteothertermsform.reset();
    this.InitializeForm();
    this._suppliername = [];
    this._submitted = false;
    this._productsubmitted = false;
    this._Quoterequestproductdetails = [];

  };
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view' && !this.isDisabledsave) {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

  filterProducts(event) {
    if (event.query == "" || event.query == undefined) {
      return;
    }
    else {
      if (this.productcatid.length > 0) {
        let query = (<string>event.query).toLowerCase();
        this.filteredProducts = this._productname.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
        return;
      }
      else {
        this._IsProgressSpinner = true;
        this._Requestforquoteservice.GetProduct(event.query, this._branchid).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._productname = <Productoverallview[]>resultJSON.productsearchlist || [];
          let query = (<string>event.query).toLowerCase();
          this.filteredProducts = this._productname.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }

  SendMail(event) {
    // var workflowid = 5;
    var requestno = event.quoterequestno;
    this._userid = sessionStorage["userid"];
    this._branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;

    this._Requestforquoteservice.SendMail(this._userid, this._branchid, requestno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && resultJSON) {
        this._CustomExceptionService.handleSuccess(usererrors.MailSent_Success);
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);

      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  onFilterProductSelect(event) {
    alert(event);
  }
}
