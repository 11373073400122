<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Quick Sales Invoice</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save" [disabled]="isDisabledsave" (click)="onSave()" class="big-btn"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear" icon="pi pi-trash" class="" [disabled]="isDisabledClear" class="p-button-danger big-btn"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search" title="Back to Search" class="p-button p-button-success p-mr-2 big-btn"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="row">
                    <div class="col-md-4">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Customer Information</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <form [formGroup]="_salesinvoiceposform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="salesinvoiceno" formControlName="salesinvoiceno"
                                                disabled>
                                            <label for="salesinvoiceno">Invoice No</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-calendar name="salesinvoicedate" [showIcon]="true" dateFormat="dd/mm/yy"
                                                [readonlyInput]="true" formControlName="salesinvoicedate"
                                                [disabled]="true">
                                            </p-calendar>
                                            <label for="salesinvoicedate">
                                                Invoice Date <span class="hlt-txt">*</span>
                                        </label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_submitted || _salesinvoiceposform.get('salesinvoicedate').touched) && _salesinvoiceposform.get('salesinvoicedate').errors?.SelectInvoiceDate">
                                            Please select invoice date
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_Customernames"
                                                    optionLabel="customername" optionValue="customerid"
                                                    [virtualScroll]="true" itemSize="15" name="customerid"
                                                    formControlName="customerid" [filter]="true" filterBy="customername"
                                                    (onChange)="GetCustomer($event)">
                                                </p-dropdown>
                                                <label for="customerid">Customer Name <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                            <button class="p-inputgroup-addon p-button" title="Add" type="button" [disabled]="_action == 'view'||_action =='edit'" (click)="showBasicDialog()">
                                                <i class="las la-plus"></i>
                                            </button>
                                        </div>
                                        <span class="text-danger" *ngIf="(_submitted || _salesinvoiceposform.get('customerid').touched) && _salesinvoiceposform.get('customerid').errors?.SelectCustomerName">
                                            Please select customer name
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                                optionLabel="currencyname" optionValue="currencyid" name="currencyid"
                                                formControlName="currencyid"
                                                (onChange)="OnUserPreferredCurrencyChange()">
                                            </p-dropdown>
                                            <label for="currencyid">Currency</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox formControlName="deliveryrequired" [disabled]="IsDisabledLayBuy" (onChange)="OnChangeDeliverymode($event)" label="Is Delivery Required" binary="true"></p-checkbox>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </form>
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Product Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <form [formGroup]="_salesproductform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="productbarcode" formControlName="productbarcode"
                                                pInputText (keyup.enter)="FetchProductUsingBarcode()"
                                                autocomplete="off" />
                                            <label for="productbarcode">Bar code</label>
                                        </span>
                                    </td>
                                    <td>
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <p-autoComplete formControlName="productid"
                                                    [suggestions]="filteredProducts" [disabled]="IsDisabledLayBuy"
                                                    (completeMethod)="filterProducts($event)" field="productname"
                                                    (onSelect)="GetVariantName()">
                                                    <ng-template let-product pTemplate="item">
                                                        <div>{{product.productname}}</div>
                                                    </ng-template>
                                                </p-autoComplete>
                                                <label for="productid">Product Name <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                            <button type="button" class="p-inputgroup-addon p-button" pTooltip="Find More Products" [disabled]="_action == 'view'||_action =='edit'" tooltipPosition=" top" (click)="showQuickInvoice()">
                                                <i class="las la-search"></i>
                                            </button>
                                        </div>
                                        <span class="text-danger" *ngIf="(_submitted1 || _salesproductform.get('productid').touched) && _salesproductform.get('productid').errors?.SelectProductName">
                                            Please Select Product Name
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-inputNumber *ngIf="hidequantity == true" formControlName="quantity"
                                                autocomplete="off" maxlength="14"
                                                (keyup.enter)="GetProductPrice($event)" mode="decimal"
                                                [minFractionDigits]="0" [maxFractionDigits]="0"></p-inputNumber>
                                            <label *ngIf="hidequantity == true" for="quantity">Quantity </label>
                                        </span>

                                        <span class="p-float-label">
                                            <p-inputNumber *ngIf="hidequantity != true" formControlName="quantity"
                                                autocomplete="off" maxlength="14"
                                                (keyup.enter)="GetProductPrice($event)" mode="decimal"
                                                [minFractionDigits]="3" [maxFractionDigits]="3"></p-inputNumber>
                                            <label *ngIf="hidequantity != true" for="quantity"> Quantity <span
                                                    class="hlt-txt">*</span>
                                        </label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_submitted1 || _salesproductform.get('quantity').touched) && _salesproductform.get('quantity').errors?.required">
                                            Please Enter Quantity
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="stockinhand" formControlName="stockinhand" disabled
                                                pInputText>
                                            <label for="stockinhand">Stock In Hand </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="text-align: center;">
                                        <button pButton pRipple label="Add" icon="pi pi-plus" class="p-button-success" *ngIf="_action != 'view'" [disabled]="IsDisabledLayBuy||_action =='edit'" type="button" (click)="GetProductPrice($event)"></button>
                                        <button pButton pRipple label="Clear" icon="pi pi-trash" class="p-button-danger" *ngIf="_action != 'view'" [disabled]="IsDisabledLayBuy||_action =='edit'" type="button" (click)="Clear($event)"></button>
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                    <div class="col-md-8">
                        <div class="card">
                            <p-table [value]="_ProductDetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['productname','variantdescription']" [rowHover]="true" dataKey="billno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:60px;" class="text-center">Action</th>
                                        <th style="width:250px">Product Name</th>
                                        <th style="width:80px;">UOM</th>
                                        <th style="width:50px;">Qty</th>
                                        <th>Unit Price</th>
                                        <th>Price</th>
                                        <th>Discount %</th>
                                        <th>Discount Amount</th>
                                        <th>Tax Amount</th>
                                        <th>Final Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_ProductDetail>
                                    <tr>
                                        <td style="text-align:center">
                                            <button pButton pRipple title="Remove" icon="pi pi-trash" [disabled]="IsDisabledLayBuy||_action == 'view'||_action =='edit'" class="p-button-danger p-mr-2" (click)="RemoveProduct(_ProductDetail)">
                                            </button>
                                        </td>
                                        <td>
                                            {{_ProductDetail.productname}}
                                        </td>
                                        <td>
                                            {{_ProductDetail.uomname}}
                                        </td>
                                        <td style="text-align: right;">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_ProductDetail.quantity" [disabled]="_ProductDetail.disableFreeProductFeild || _action != 'create'" (keyup)="allocatequantity(_ProductDetail)" (focusout)="allocatequantity(_ProductDetail)" pInputText>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.quantity}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align: right;" pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_ProductDetail.unitprice" [disabled]="_ProductDetail.disableunitprice" (keydown.enter)="OnChangeUnitPrice(_ProductDetail)" (keyup.tab)="OnChangeUnitPrice(_ProductDetail)" (onBlur)="OnChangeUnitPrice(_ProductDetail)" pInputText
                                                        *ngIf="_action != 'view'">
                                                    <div *ngIf="_action == 'view'">
                                                        {{_ProductDetail.unitprice | number:'1.2'}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.unitprice | number: '1.2'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align: right;">
                                            {{_ProductDetail.productamount | number: '1.2':'en-US'}}
                                        </td>
                                        <td style="text-align: right;" pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" [(ngModel)]="_ProductDetail.customerdiscountpercentage" [disabled]="IsDisabledLayBuy || _ProductDetail.disableFreeProductFeild || _action != 'create'" (keyup)="allocatequantity(_ProductDetail)" (focusout)="allocatequantity(_ProductDetail)"
                                                        pInputText>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.customerdiscountpercentage}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="text-align: right;">
                                            {{_ProductDetail.customerdiscountamount | number: '1.2' }}
                                        </td>
                                        <td style="text-align: right;">
                                            {{_ProductDetail.taxamount | number: '1.2' }}
                                        </td>
                                        <td style="text-align: right;">
                                            {{_ProductDetail.finalamount | number: '1.2'}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Payment Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <form [formGroup]="_salesinvoiceposform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="productamount" formControlName="productamount"
                                                disabled>
                                            <label for="productamount">Gross Amount</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="taxamount" formControlName="taxamount" disabled>
                                            <label for="taxamount">Tax Amount</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_Descriptions"
                                                optionLabel="otherchargename" optionValue="otherchargeid" filter="true"
                                                filterBy="otherchargename" [showClear]="true"
                                                [disabled]="IsDisabledLayBuy ||_action != 'create'"
                                                [(ngModel)]="otherchargeid" [ngModelOptions]="{standalone: true}"
                                                (onChange)="OnchangeOtherCharge($event)">
                                            </p-dropdown>
                                            <label for="otherchargeid">Other Charges</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="otherchargesamount" type="number" pKeyFilter="num"
                                                formControlName="otherchargesamount" maxlength="10"
                                                pattern="^\d{1,10}(\.\d{0,2})?$" min="0"
                                                (keypress)="keypress.kpDecimalInput($event)"
                                                (keyup)="oneditothercharge()">
                                            <label for="otherchargesamount">Other Charges Amount</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Salesby"
                                                optionLabel="username" optionValue="userid" name="salesby" filter="true"
                                                filterBy="username" [showClear]="true" formControlName="salesby">
                                            </p-dropdown>
                                            <label for="salesby">Sales By <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_submitted || _salesinvoiceposform.get('salesby').touched) && _salesinvoiceposform.get('salesby').errors?.SelectSupplier">
                                            Please select sales by
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText
                                                style="background: yellow !important;font-size: 16px !important;"
                                                name="netamountincustomercurrency" disabled
                                                formControlName="netamountincustomercurrency">
                                            <label for="netamountincustomercurrency">Amount in Customer Currency</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" pInputText name="paidamount"
                                                formControlName="paidamount" disabled
                                                (keyup)="CalculateBalanceAmount()">
                                            <label for="paidamount">Customer Paid Amount</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="balanceamount" formControlName="balanceamount"
                                                disabled>
                                            <label for="balanceamount">Balance paid to customer</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div class="p-formgroup-inline">
                                            <h6>Payment Mode </h6>
                                            <div class="p-field-radiobutton" *ngFor="let PM of _PaymentModes">
                                                <p-radioButton name="paymenttype" formControlName="paymenttype" label={{PM.metasubdescription}} value={{PM.metasubcode}} (click)="OnChangePaymode()">
                                                </p-radioButton>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_toaccounts"
                                                optionLabel="name" optionValue="ledgerid" [filter]="true"
                                                formControlName="bankledgerid">
                                            </p-dropdown>
                                            <label for="bankledgerid">Bank Accounts<span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input pInputText name="paymentreferenceno" maxlength="50"
                                                formControlName="paymentreferenceno">
                                            <label for="paymentreferenceno">Reference No</label>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                    <div class="col-md-4">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Amount Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <form [formGroup]="_salesinvoiceposform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width:25%">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Multicurrencies1"
                                                optionLabel="currencyname" optionValue="currencyid"
                                                (onChange)="OnSelectCurrency1()" formControlName="cuscurrencyid1">
                                            </p-dropdown>
                                            <label for="cuscurrencyid1">Currency 1<span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td style="width: 25%;">
                                        <span class="p-float-label">
                                            <input type="number" name="cuscurrencyamount1" min="0"
                                                formControlName="cuscurrencyamount1" (keyup)="OnSelectCurrency2()"
                                                (focusout)="OnSelectCurrency2()" pInputText>
                                            <label for="cuscurrencyamount1">Customer Paid Amount 1
                                            </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:25%">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Multicurrencies2"
                                                optionLabel="currencyname" optionValue="currencyid"
                                                (onChange)="Multicurrencycalculation()" (onChange)="onchangecurrency2()"
                                                formControlName="cuscurrencyid2">
                                            </p-dropdown>
                                            <label for="cuscurrencyid2">Currency 2<span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td style="width: 25%;">
                                        <span class="p-float-label">
                                            <input type="number" name="cuscurrencyamount2"
                                                formControlName="cuscurrencyamount2" min="0"
                                                (keyup)="onchangeMulticurrency2()" (focusout)="onchangeMulticurrency2()"
                                                pInputText>
                                            <label for="cuscurrencyamount2">Customer Paid Amount 2
                                            </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:25%">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                                optionLabel="currencyname" optionValue="currencyid" [filter]="true"
                                                filterBy="currencyname" [showClear]="true"
                                                (onChange)="ChangeReturnCurrency($event)"
                                                formControlName="returncurrencyid">
                                            </p-dropdown>
                                            <label for="returncurrencyid">Return Currency<span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td style="width: 25%;">
                                        <span class="p-float-label">
                                            <input type="number" name="returnamount" formControlName="returnamount"
                                                disabled pInputText>
                                            <label for="returnamount">Return Amount
                                            </label>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                </div>
                <table>
                    <tr>
                        <td>
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            <p-dialog #cd [style]="{width: '400px'}" header="Payment Request" [(visible)]="paymentModel.visiblePaymentPopup" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
                <ng-template pTemplate="content">
                    <label [innerHTML]="paymentModel.Message"></label>
                </ng-template>
                <ng-template pTemplate="footer">
                    <button type="button" pButton icon="pi pi-refresh" label="Retry" autofocus class="p-button-success" (click)="paymentModel.Retry()" [hidden]="paymentModel.IsRetryEnable"></button>
                    <button type="button" pButton icon="pi pi-times" label="Cancel Invoice" class="p-button-danger" (click)="paymentModel.CancelInvoice()" [hidden]="paymentModel.IsRetryEnable"></button>
                    <button type="button" pButton icon="pi pi-times" label="Cancel Request" class="p-button-warning" (click)="paymentModel.CancelRequest()" [hidden]="paymentModel.isHideCancelRequestButton"></button>
                </ng-template>
            </p-dialog>
            <p-dialog #warning [style]="{width: '400px'}" header="Warning" [(visible)]="creditnotemodel.showdialog" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
                <ng-template pTemplate="content">
                    <label [innerHTML]="creditnotemodel.message"></label>
                </ng-template>
                <ng-template pTemplate="footer">
                    <button type="button" pButton icon="las la-check" label="Yes" autofocus (click)="creditnotemodel.Yes()"></button>
                    <button type="button" pButton icon="pi pi-times" label="No" autofocus (click)="creditnotemodel.Cancel()"></button>
                </ng-template>
            </p-dialog>
        </div>
    </div>
    <p-dialog header="Electrical Product Type" [(visible)]="displayBasicProductType" modal="true" class="popup-new" [style]="{width: '500px'}">
        <p>Please Advise the Customer to Check Electrical Products Before Leaving the Premises</p>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="CloseProductTypeDialog(false,Salesinvoiceresultjson,salesproductprice)" label="OK" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
    <p-dialog header="Scheme" [(visible)]="dispalyBasicScheme" modal="true" class="popup-new" [style]="{width: '500px'}">
        <ul *ngFor="let sr of schemeRemarks" class="bullet01">
            <li><i class="las la-gift"></i>{{sr}}</li>
        </ul>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="CloseProductTypeDialog(false,Salesinvoiceresultjson,salesproductprice)" label="Proceed With Current Qty" styleClass="p-button-text"></p-button>
            <p-button icon="las la-pen" (click)="modifyqty()" label="Modify Qty" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
    <p-dialog header="Customers" [(visible)]="ManageDialog" [style]="{width: '350px',height:'400px'}" [maximizable]="false">
        <ng-template pTemplate="content">
            <form [formGroup]="_customerform" (ngSubmit)="Save()">
                <table class="normal-table">
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="customername" formControlName="customername" pInputText>
                                <label for="customername">Customer Name <span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger" *ngIf="(_customersubmitted || _customerform.get('customername').touched) && _customerform.get('customername').errors?.CustomerNameRequired">
                                Please Enter Customer Name
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="vatno" formControlName="vatno" pInputText>
                                <label for="vatno">VAT No. <span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger" *ngIf="(_customersubmitted || _customerform.get('vatno').touched) && _customerform.get('vatno').errors?.CustomerNameRequired">
                                Please Enter Vat No
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="bpno" formControlName="bpno" pInputText>
                                <label for="bpno">BP No. <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="mobileno" formControlName="mobileno" pInputText
                                    (keypress)="keypress.kpMobileNumber($event)" maxlength="20">
                                <label for="mobileno">Phone Number <span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger" *ngIf="(_customersubmitted || _customerform.get('mobileno').touched) && _customerform.get('mobileno').errors?.MobileNumberRequired">
                                Please Enter Phone Number
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="mailid" formControlName="mailid" pInputText>
                                <label for="mailid">Email <span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger" *ngIf="(_customersubmitted || _customerform.get('mailid').touched) && _customerform.get('mailid').errors?.EmailIDRequired">
                                Please Enter Email
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <input type="text" name="address" formControlName="address" pInputText>
                                <label for="address"> Address <span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger" *ngIf="(_customersubmitted || _customerform.get('address').touched) && _customerform.get('address').errors?.AddressRequired">
                                Please Enter Address
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <p-dropdown [options]="_customertype" [autoDisplayFirst]="false" name="customertype"
                                    optionLabel="metasubdescription" optionValue="metasubcode"
                                    formControlName="customertype">
                                </p-dropdown>
                                <label for="customertype"> Customer Type <span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger" *ngIf="(_customersubmitted || _customerform.get('customertype').touched) && _customerform.get('customertype').errors?.CustomerNameRequired">
                                Please choose Customer Type
                            </span>
                        </td>
                    </tr>
                </table>
            </form>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Save" (click)="Save()" icon="las la-check" class="p-button-text"></button>
            <button pButton pRipple label="Cancel" icon="pi pi-times" (click)="Cancel()" class="p-button-text"></button>
        </ng-template>
    </p-dialog>
    <p-dialog header="Reward Details" [(visible)]="dispalyBasicReward" modal="true" class="popup-new" [style]="{width: '800px',height:'500px'}">
        <div class="row">
            <div class="col-md-6">
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Earned Reward Points</h5>
                        </div>
                        <div class="col-md-6 text-right">
                            <h3>Total : {{totalearningpoints| number : '1.2'}}</h3>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <p-table #dtBranchWiseStocks [value]="earnedrewarddetails" selectionMode="single" [rows]="15" [paginator]="true" [rowsPerPageOptions]="[15,25,50]" [globalFilterFields]="['branchname','closingstock']" [rowHover]="true" dataKey="branchid">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="earneddate">Earned Date
                                    <p-sortIcon field="earneddate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="earnedpoints" style="width: 120px;">Earned Points
                                    <p-sortIcon field="earnedpoints">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="expiredate" style="width: 120px;">Expire Date
                                    <p-sortIcon field="expiredate">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_earnedpoint>
                            <tr>
                                <td>
                                    {{_earnedpoint.earneddate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td class="text-right">
                                    {{_earnedpoint.earnedpoints| number : '1.2'}}
                                </td>
                                <td>
                                    {{_earnedpoint.expiredate | date:'dd-MMM-yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="col-md-6">
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Used Reward Points</h5>
                        </div>
                        <div class="col-md-6  text-right">
                            <h3>Total : {{totalusedpoints| number : '1.2'}}</h3>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <p-table #dtProductCategoryWiseStocks [value]="usedrewarddetails" [rows]="15" [paginator]="true" [rowsPerPageOptions]="[15,25,50]" [globalFilterFields]="['productcategoryname','closingstock']" [rowHover]="true" dataKey="productcategoryid">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="useddate">Used Date
                                    <p-sortIcon field="useddate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="usedpoints" style="width: 120px;">Used Points
                                    <p-sortIcon field="usedpoints">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-_usedpoints let-i="rowIndex">
                            <tr>
                                <td>
                                    {{_usedpoints.useddate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td class="text-right">
                                    {{_usedpoints.usedpoints| number : '1.2'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="col-md-12 text-right">
                <h5>Total Remaining Points : {{totalremainingpoints| number : '1.2'}}</h5>
            </div>
        </div>
    </p-dialog>
</div>
<p-dialog [(visible)]="QuickBilling" [showHeader]="false" class="quick-invoice" [style]="{width:'100vw',height:'100vh'}" [baseZIndex]="10000">
    <div class="quick-container">
        <div class="quick-left">
            <div class="hdr d-flex justify-content-between align-items-center">
                <div class="hdr-left">
                    <h3>Menu</h3>
                </div>
                <div class="hdr-right">
                    <input type="text" placeholder="Bar code" [(ngModel)]="productbarcodequick" pInputText [ngModelOptions]="{standalone: true}" (keyup.enter)="FetchProductUsingBarcodequick()" autocomplete="off" />
                    <p-autoComplete [(ngModel)]="productnamequick" placeholder="Search Product" [suggestions]="filteredProducts" [disabled]="IsDisabledLayBuy" (completeMethod)="filterProductsquick($event)" field="productname">
                        <ng-template let-product pTemplate="item">
                            <div>{{product.productname}}</div>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>
            <div class="body scroll-y">
                <div class="text-center" *ngIf="NoProductsFound">
                    <img src="assets/images/no-data.jpg" class="img-fluid" alt="No Data Available">
                </div>
                <div class="row">
                    <div class="col-md-2" *ngFor="let MO of ProductQuickList">
                        <a href="javascript:void(0)" (click)="GetProductPriceQuick(MO)" class="product-listing text-center" [class.product-select]="MO.productSelected==true">
                            <img src="{{MO.productimageurl}}" alt="assets/images/no-img-product.jpg" class="img-fluid">
                            <span class="product-name block-ellipsis clamp2">{{MO.productname}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="quick-right">
            <div class="hdr">
                <h3>Quick Invoice</h3>
            </div>
            <div class="body scroll-y">
                <ul class="invoice-list">
                    <li *ngFor="let MO of _ProductDetails">
                        <div class="wrapper" [class.swipe-left]="classToggled==true">
                            <div class="d-inline-block">
                                <div class="d-flex justify-content-between align-items-center editable">
                                    <span (click)="showSwipeAction()">
                                        <strong class="product-name block-ellipsis clamp1"
                                            title={{MO.productname}}>{{MO.productname}}</strong>
                                    </span>
                                    <span class="text-right">
                                        <span class="multiply">x</span>
                                    <strong class="qty">
                                            <input type="number" pInputText [(ngModel)]="MO.quantity"
                                                (keyup)="OnChangeUnitPrice(MO)" />
                                        </strong>
                                    </span>
                                    <span class="text-right">
                                        <strong class="price">
                                            {{MO.productamount| number:'1.2'}}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                            <button pButton pRipple title="Delete" icon="pi pi-trash" (click)="RemoveProduct(MO)" class="d-inline-block del-btn p-button-danger"></button>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="body-ftr">
                <ul class="invoice-list invoice-total">
                    <li>
                        <div class="d-flex justify-content-between align-items-center">
                            <span>
                                <span class="product-name block-ellipsis clamp1">Tax</span>
                            </span>
                            <span class="text-right">
                                <span class="price">
                                    {{TotalChecklistTaxAmount| number: '1.2'}}
                                </span>
                            </span>
                        </div>
                    </li>
                    <li>
                        <hr>
                    </li>
                    <li>
                        <div class="d-flex justify-content-between align-items-center">
                            <span>
                                <strong class="product-name block-ellipsis clamp1">
                                    Total
                                </strong>
                            </span>
                            <span class="text-right">
                                <strong class="price text-right">
                                    {{TotalChecklistAmount| number: '1.2'}}
                                </strong>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="ftr text-center">
                <button pButton pRipple label="Save and Proceed" title="Save and Proceed" icon="pi pi-save" (click)="AddProductToGridQuick()" class="p-button-success"></button>
            </div>
        </div>
    </div>
</p-dialog>
<p-toast position="bottom-right"></p-toast>