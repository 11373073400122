import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Employee } from 'src/app/core/Models/Employee';
import { vProductNameSelect, vQuantity, vRequestDateSelect } from 'src/app/core/Validators/validation';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { KeyPress } from 'src/assets/js/KeyPress';
import { usererrors } from 'src/app/core/errors/usererrors';
import { DatePipe } from '@angular/common';
import { ProductRequestService } from 'src/app/core/Services/inventory/product-request.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Metadatum } from 'src/app/core/Models/metadatum';

@Component({
	selector: 'app-c-product-request',
	templateUrl: './c-product-request.component.html',
	styleUrls: ['./c-product-request.component.css']
})
export class CProductRequestComponent implements OnInit {
	isDisabledSave = false;
	_Materialrequestno: string
	_action: string = "create";
	materialrequestid: number;
	_username = sessionStorage["username"];
	_branchname = sessionStorage["currentbranchname"];
	_userid = sessionStorage["userid"];
	_branchid = sessionStorage["currentbranchid"];
	_submitted1: Boolean = false
	_submitted: Boolean = false;
	_IsProgressSpinner: boolean = true;
	_productrequstform: FormGroup
	_productrequstdetailform: FormGroup
	_stockform: FormGroup
	minimumDate: Date = new Date();
	_ProductTypes: any[] = []
	_ProductCategories: any[] = []
	productcatid: any[] = []
	_Varients: any[] = []
	_Productdetails: any[] = []
	filteredProducts: any[];
	_requsttypelist: any[] = []
	_employeelist: any[] = []
	_Employees: any[] = []
	producttypeid: any[] = []
	_productnameList: any[] = []
	_ProductNames: Productoverallview[] = [];
	_tempproductname: Productoverallview[] = [];
	_Requesttype: Metadatum[] = [];
	_button: boolean = false
	_RefNo: any[] = []
	_Action: IAction
	hidequantity: boolean = false
	mindate: Date;
	maxdate: Date;

	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	constructor(
		private router: Router,
		private utility: CommonUtilities,
		private fb: FormBuilder,
		public keypress: KeyPress,
		public productRequestService: ProductRequestService,
		private _CustomExceptionService: CustomExceptionService,
		private _AccessRightsService: AccessRightsService

	) {
		this.materialrequestid = history.state?.materialrequestid ? history.state?.materialrequestid : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(343);
		this.hidequantity = true;
		this.utility.pageLoadScript();
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.InitializeForm()
		if (this._action == 'view') {
			this._productrequstform.disable()
			this._productrequstdetailform.disable()
			this._button = true;
		}
		this._IsProgressSpinner = true;
		this.productRequestService.PageOnLoad(this.materialrequestid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._Employees = resultJSON.lstUsers;
			for (let i = 0; i < this._Employees.length; i++) {
				const x = this._Employees[i];
				if (x.employeelastname) {
					x.username = x.username + ' ' + x.employeelastname
				}
			}
			this._ProductTypes = resultJSON.producttypeList;
			this._requsttypelist = resultJSON.requestTypeList;
			this._ProductCategories = resultJSON.productcategoryList;
			this._Requesttype = resultJSON.requesttypes;
			this._IsProgressSpinner = false;
			this._RefNo = resultJSON.jobcardList;
			this._productrequstform.get("refdocno").disable();
			if (this._action == 'edit' || this._action == 'view') {
				this._RefNo = resultJSON.jobcardupdateList;
				this._productrequstform.setValue({
					branchid: resultJSON.materialrequest.branchid,
					companyid: resultJSON.materialrequest.companyid,
					createdby: resultJSON.materialrequest.createdby,
					createdon: new Date(resultJSON.materialrequest.createdon),
					materialrequestdate: new Date(resultJSON.materialrequest.materialrequestdate),
					materialrequestid: resultJSON.materialrequest.materialrequestid,
					materialrequestno: resultJSON.materialrequest.materialrequestno,
					mrbranchid: resultJSON.materialrequest.mrbranchid,
					requestreason: resultJSON.materialrequest.requestreason,
					requestedby: resultJSON.materialrequest.requestedby,
					materialrequesttype: resultJSON.materialrequest.materialrequesttype,
					refdocno: resultJSON.materialrequest.refdocno,
					status: resultJSON.materialrequest.status,
					modifiedby: resultJSON.materialrequest.modifiedby,
					modifiedon: resultJSON.materialrequest.modifiedon,
					rv: resultJSON.materialrequest.rv,
					materialrequestdetails: resultJSON.materialrequest.materialrequestdetails,
					totalamount: resultJSON.materialrequest.totalamount,
					transactionincompleted: resultJSON.materialrequest.transactionincompleted,
					requesttype: resultJSON.materialrequest.requesttype,
				});
				this._Productdetails = resultJSON.materialrequestdetailList
				if (this._action == 'edit') {
					let type = this._productrequstform.get("materialrequesttype").value;
					if (type == "MSC00331") {
						this._productrequstform.get("refdocno").disable();
					} else {
						this._productrequstform.get("refdocno").enable();
					}
				}
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	reset(event) {
		if (this._action != 'edit') {
			this._productrequstform.reset()
		}
		this._productrequstdetailform.reset()
		this._Productdetails = []
		this.ngOnInit()
	}
	goBack(event) {
		this.router.navigate(['/vProductRequest']);
	}
	getrefno() {
		let type = this._productrequstform.get("materialrequesttype").value;
		if (type == "MSC00331") {
			this._productrequstform.get("refdocno").reset();
			this._productrequstform.get("refdocno").disable();
		} else {
			this._productrequstform.get("refdocno").reset();
			this._productrequstform.get("refdocno").enable();
		}
	}
	InitializeForm() {
		this._productrequstform = this.fb.group({
			companyid: [0],
			branchid: [this._branchid],
			materialrequestid: [0],
			materialrequestno: [],
			materialrequestdate: [, Validators.required],
			mrbranchid: [this._branchid],
			requestreason: [null, Validators.required],
			requestedby: [null, Validators.required],
			materialrequesttype: ['MSC00331', Validators.required],
			refdocno: [],
			status: [],
			createdby: [this._userid],
			createdon: [new Date()],
			modifiedby: [this._userid],
			modifiedon: [new Date()],
			rv: [],
			materialrequestdetails: [],
			totalamount: [],
			transactionincompleted: [1],
			requesttype: [null, Validators.required]

		})

		if (sessionStorage['Environmentenddate'] == "null" ) {
			this._productrequstform.get("materialrequestdate").setValue(new Date);
		  }
		  else {
			let date = new Date(sessionStorage['Environmentenddate'])
			this._productrequstform.get("materialrequestdate").setValue(date);
		  }
		this._productrequstdetailform = this.fb.group({

			materialrequestdetailid: [0],
			materialrequestid: [],
			productid: ['', vProductNameSelect],
			uomid: [],
			requestqty: [],
			ismaterialissued: [],
			availableuantity: [],
			ProductType: [],
			ProductCategory: []
		})
	}
	getProductcategory(event) {
		this._IsProgressSpinner = true;
		let data = {
			"producttypeids": this.producttypeid
		}
		this.productRequestService.Getproductcategory(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._ProductCategories = resultJSON.productcategoryList;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	getProductname() {
		this._IsProgressSpinner = true;
		let data = {
			"productcatids": this.productcatid
		}
		this.productRequestService.GetProductname(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._productnameList = resultJSON.productnameList;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	filterProducts(event) {
		if (event.query == "" || event.query == null) {
			return;
		}
		this._IsProgressSpinner = true;
		this.productRequestService.GetProductSearch(event.query, this._branchid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this._ProductNames = <Productoverallview[]>resultJSON.productsearchlist || [];
			this._tempproductname = <Productoverallview[]>resultJSON.productsearchlist || [];
			let filtered: any[] = [];
			let query = (<string>event.query).toLowerCase();
			this.filteredProducts = this._ProductNames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	getProductVariants(event) {
		var selectedProductVariant = event.productid;
		this._IsProgressSpinner = true;
		this.productRequestService.getProductVariants(selectedProductVariant, this._branchid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this._Varients = resultJSON.productvariants;
			this._ProductTypes = resultJSON.producttypes;
			this._ProductCategories = resultJSON.productCategoriesList;
			let product = this._tempproductname.filter(f => f.productid == selectedProductVariant)[0];
			this._productrequstdetailform.controls['ProductType'].setValue(product.producttypename);
			this._productrequstdetailform.controls['ProductCategory'].setValue(product.categoryname);
			this._productrequstdetailform.get("requestqty").reset();
			var uom = product.salesuom;

			if (uom == "MSC00037") {
				this.hidequantity = true;
			}
			else {
				this.hidequantity = false
			}
			this._productrequstdetailform.controls['availableuantity'].setValue(resultJSON.currentBranchStock.stock1);
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	getProductstock(event) { }
	CheckQuantity(event) { }
	AddProduct(event) {

		this._submitted1 = true;
		if (!this._productrequstdetailform.valid) return
		if (!this._productrequstdetailform.get("productid").value.productid) {
			this._CustomExceptionService.handleWarning("Please select  Product Name");
			return
		}
		let available = this._productrequstdetailform.get('availableuantity').value;
		let enterqnty = this._productrequstdetailform.get('requestqty').value;
		if (!enterqnty || enterqnty == 0) {
			this._CustomExceptionService.handleWarning("Please enter quantity");
			return
		}
		if (available < enterqnty) {
			this._CustomExceptionService.handleWarning("Quantity exceeds available quantity");
			return
		}
		if (this._productrequstdetailform.valid) {
			var productid = parseInt(this._productrequstdetailform.get("productid").value.productid);
			var productName = (this._ProductNames || []).filter(f => f.productid == productid)[0].productname;
			var uom = (this._ProductNames || []).filter(f => f.productid == productid)[0].salesuom;
			var purchaserateinusd = (this._ProductNames || []).filter(f => f.productid == productid)[0].purchaserateinusd;

			var qty = this._productrequstdetailform.get('requestqty').value;
			var finalamount: number = parseFloat((purchaserateinusd * qty).toFixed(2));

			var uomname = (this._ProductNames || []).filter(f => f.productid == productid)[0].salesuomname;
			var uomname = (this._ProductNames || []).filter(f => f.productid == productid)[0].salesuomname;
			var uomname = (this._ProductNames || []).filter(f => f.productid == productid)[0].salesuomname;
			let existingProduct = this._Productdetails.find(product => product.productid === productid);
			if (existingProduct) {
				this._CustomExceptionService.handleWarning(productName + " already exists");
				return;
			}
			let ProductRequst = {
				"productid": productid,
				"productName": productName,
				"uomid": uom,
				"uomname": uomname,
				"requestqty": qty,
				"purchaserateinusd": purchaserateinusd,
				"finalamount": finalamount
			}
			this._Productdetails.push(ProductRequst)

			var totalamount: number = 0;
			for (let i = 0; i < this._Productdetails.length; i++) {
				totalamount += (this._Productdetails[i].finalamount)
				// totalamount += (parseFloat(this._Productdetails[i].finalamount).toFixed(2));
			}
			this._productrequstform.controls['totalamount'].setValue(totalamount);

			this._Productdetails = [...this._Productdetails]
			this._productrequstdetailform.reset()
			this._submitted1 = false
		}
	}
	Clear(event) {
		this._productrequstdetailform.reset()
	}
	RemoveProduct(pProduct: any) {
		var index = this._Productdetails.indexOf(pProduct);
		this._Productdetails.splice(index, 1)
	}
	onSave() {
		this._productrequstform.markAllAsTouched()
		if (!this._productrequstform.valid) return;
		let type = this._productrequstform.get("materialrequesttype").value;
		let refdocno = this._productrequstform.get("refdocno").value;
		let request = this._productrequstform.get("requesttype").value;
		if (!type) {
			this._CustomExceptionService.handleWarning("Please Select Product Request Type");
			return
		}
		if (type == "MSC00331" && refdocno) {
			this._productrequstform.get("materialrequesttype").reset();
			this._productrequstform.get("refdocno").reset();
			this._productrequstform.get("refdocno").disable();
			this._CustomExceptionService.handleWarning("Please Select Product Request Type");
			return
		}
		if (type == "MSC00330" && !refdocno) {
			this._CustomExceptionService.handleWarning("Please Select Ref Doc No");
			return
		}
		if (!this._Productdetails || !this._Productdetails.length) {
			this._CustomExceptionService.handleWarning("Please add atleast 1 product in the grid")
			return
		}
		this._submitted = true;
		if (this._action == 'create') {
			if (this._productrequstform.valid) {
				var datePipe = new DatePipe("en-US");
				let Data = {
					"Materialrequest": this._productrequstform.getRawValue(),
					"Materialrequestdetail": this._Productdetails
				}
				if (this._Productdetails.length == 0) { return }
				this._IsProgressSpinner = true;
				this.productRequestService.create(Data).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
					this._Materialrequestno = resultJSON.materialrequestno;
					this.isDisabledSave = true;
					this._IsProgressSpinner = false;
				}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
		}
		else if (this._action == 'edit') {
			if (this._productrequstform.valid) {
				let Data = {
					"Materialrequest": this._productrequstform.getRawValue(),
					"Materialrequestdetail": this._Productdetails
				}
				if (this._Productdetails.length == 0) { return }
				this._IsProgressSpinner = true;
				this.productRequestService.update(Data).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
					this._Materialrequestno = resultJSON.materialrequestno;
					this.isDisabledSave = true;
					this._IsProgressSpinner = false;
				}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
		}
	}
	SendToApproval() {
		var workflowid = 62;
		this._IsProgressSpinner = true;
		this.productRequestService.SendToApproval(workflowid, this._userid, this._Materialrequestno).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this._CustomExceptionService.handleSuccess(usererrors.Approved_01);
			this._IsProgressSpinner = false;
			this.isDisabledSave = false;
			this._productrequstform.reset()
			this._productrequstdetailform.reset()
			this._Productdetails = []
			this.ngOnInit()
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
}