import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class PurchaseordersummaryService {

  public _Action: IAction;
  public _httpOptions;
  public _RptPurchaseSummaryService = environment.RptPurchaseSummaryService;

  constructor(private http: HttpClient) { }
   // Page Load :
   PageOnload(): Observable<any> {
    var Query = this._RptPurchaseSummaryService + '/PageInitialize';
    
    return this.http.get<{ data: any }>(Query);
  }

  Print(branchid :number,userid :number,supplireid :number,productid : number,currencyid : number,fromdate :string,todate :string) {
    var Query = this._RptPurchaseSummaryService + '/Print/'+ branchid +'/'+userid +'/'+ supplireid+'/'+ productid 
    + '/'+ currencyid+'/'+fromdate+'/'+todate;
    //
    return this.http.get(Query,{responseType: 'blob'});
  }

}
