import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map,catchError} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { Password } from 'primeng/password';
import { IAction } from '../security/IActions';


@Injectable({
  providedIn: 'root'
})
export class ChangepasswordService {
  public _Action : IAction ; 
  public _ChangePasswordService = environment.ChangePasswordService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http:HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(){ 
    const headers = this.header;
    var Query = this._ChangePasswordService ;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  
  edit(obj : object)
  { 
    const headers = this.header;
    var Query = this._ChangePasswordService; 
    return this.http.post<{data:any}>(Query,obj, { headers });
  }
}



  // FetchAll()
  // {
  //   var Query = this._ChangePasswordService + '/FetchAll' ;
  //  
  //   return this.http.get<{data:any}>(Query,this._httpOptions).pipe(map(data=>{
  //     const resultJSON = JSON.parse(JSON.stringify(data));
  //     console.log(resultJSON);
  //     // let _CityScreenAccess = resultJSON.userData.lstFunctions.find(funct=>funct.functionid=="15");
  //     // this._Action  = { 
  //     //   _functionId : 15,
  //     //   _functionName : "City",
  //     //   _Add :   _CityScreenAccess.iscreateallowed,
  //     //   _Delete : _CityScreenAccess.isdeleteallowed,
  //     //   _Update : _CityScreenAccess.iseditallowed,
  //     //   _View : _CityScreenAccess.isviewallowed,
  //     //   _Approve : _CityScreenAccess.isviewallowed
  //     // }   
  //    return data; 
  //   }),);
  // }
  
  // view(p_ChangePasswordCode : string)
  // {
  //   var Query = this._ChangePasswordService + '/' + p_ChangePasswordCode   ;
  //  
  //   return this.http.get<{data:any}>(Query);
  // }
  
