<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
          </div>
        <div class="white">
            <form [formGroup]="_ShippingLineform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Shipping Line</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" [disabled]="isDisabledsave" title="Save" icon="pi pi-save" ></button>
                                <button pButton pRipple type="button" [disabled]="isDisabledreset" (click)="reset($event)" title="Clear"
                                    icon="pi pi-trash" class="" class="p-button-danger"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                    title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="shippinglinecode" formControlName="shippinglinecode">
                                    <label for="shippinglinecode"> Enter Shipping Line Code <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- <span class="text-danger" 
                                     *ngIf="(_submitted || _countryform.get('countrycode').touched) && _countryform.get('countrycode').errors?.CountryCodeRequired"> 
                                    Please enter country code 
                                </span> -->
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText name="shippinglinename" formControlName="shippinglinename">
                                    <label for="shippinglinename"> Enter Shipping Line Name <span class="hlt-txt">*</span></label>
                                </span>
                             
                                <!-- <span class="text-danger" 
                                     *ngIf="(_submitted || _countryform.get('countryname').touched )&& _countryform.get('countryname').errors?.CountryNameRequired"> 
                                    Please enter country name 
                                </span> -->
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_ShippingLinestatus" optionLabel="metasubdescription"
                                        optionValue="metasubcode" name="countrystatus" formControlName="status">
                                    </p-dropdown>
                                    <label for="status"> Status <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_countryform.get('countrystatus').touched || _submitted) && _countryform.get('countrystatus').errors?.SelectStatus">
                                    Please select country status
                                </span> -->
                            </td>
                            <td>
                                   <!-- Debug -->
                                   <!-- <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div> -->
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>

