<div class="page-container">
    <div class="inner-page-container">
        <div class="white">
            <form [formGroup]="_accounttypeform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Account Type </h3>
                        </div>
                        <div class="col-md-7 text-right">

                            <div class="action-btn">
                                <button pButton pRipple title="Save" type="submit" icon="pi pi-save"></button>
                              <!-- 10.All entered input fields should be cleared. -->
                                <button pButton pRipple label="" title="Clear" icon="pi pi-trash"
                                    class="p-button-danger" (click)="reset($event)"></button>
                                    <!-- 14.on clicking search button , the data must be filtered and display in the grid -->
                                <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                    class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                                <!-- <button pButton pRipple label="" icon="pi pi-times" class="" (click)="hideDialog()" ></button>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="accounttypename" formControlName="accounttypename"
                                        pInputText>
                                    <label for="accounttypename">Account Type Name <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <!-- 7.if name is not given, show error "Please enter Account Type Name." -->
                                 <span class="text-danger"
                                 *ngIf="(_submitted || _accounttypeform.get('accounttypename').touched) && _accounttypeform.get('accounttypename').errors?.AccountTypeNameRequired">
                                     Please Enter Account Type Name
                                 </span>
                                
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label" >
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_accounttypestatus"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="accounttypestatus">
                                    </p-dropdown>
                                    <label for="accounttypestatus"> Status</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;">
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>

                            </td>
                        </tr>

                    </table>

                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>