<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Journal</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save" (click)="onSave()" [disabled]="_action == 'view'"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear" [disabled]="_IsClearVisible" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search" title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_Journalform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="journaldate" [showIcon]="true" formControlName="journaldate"
                                    [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="journaldate">Journal Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Journalform.get('journaldate').touched) && _Journalform.get('journaldate').errors?.SelectRequestDate">
                                    Journal Date Required.
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_Currencies" [autoDisplayFirst]="false"
                                        optionLabel="currencyname" (onChange)="ChangeCurrencies($event)"
                                        optionValue="currencyid" name="currentcurrencyid"
                                        formControlName="currentcurrencyid">
                                    </p-dropdown>
                                    <label for="currentcurrencyid"> Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Journalform.get('currentcurrencyid').touched) && _Journalform.get('currentcurrencyid').errors?.SelectCurrency">
                                    Currency Required.
                                </span>
                            </td>
                            <td style="width: 20%;" rowspan="2">
                                <span class="p-float-label">
                                    <textarea rows="3" cols="30" pInputTextarea name="narration"
                                        formControlName="narration"></textarea>
                                    <label for="narration">Narration</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="exchangerate" formControlName="exchangerate"
                                        disabled>
                                    <label for="exchangerate"> Exchange Rate </label>
                                </span>
                            </td>
                            <td>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Journal Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_Journaldetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['account','refType','refNo','debitamount','creditamount']" [rowHover]="true" dataKey="customerid" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px;text-align:center">Action</th>
                                <th>Account</th>
                                <th>Entity</th>
                                <th>Entity Reference</th>
                                <th>Reference Type</th>
                                <th style="width: 200px;text-align:center">Reference No</th>
                                <th>Balance Amount</th>
                                <th>Debit</th>
                                <th>Credit</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Journaldetail let-_journalOperations>
                            <tr>
                                <td style="width: 50px" style="text-align:center">
                                    <button pButton pRipple label="" title="Add" icon="las la-plus" [disabled]="_action == 'view'" class="p-button-success p-mr-2" (click)="addJournal($event)"></button>
                                    <button pButton pRipple label="" title="Remove" icon="pi pi-trash" [disabled]="_action == 'view'" class="p-button-danger p-mr-2" (click)="RemoveJournal(_Journaldetail)"></button>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [options]="_accounts" [autoDisplayFirst]="false" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" [virtualScroll]="true" itemSize="30" optionValue="ledgerid" [(ngModel)]="_Journaldetail.account" [disabled]="_action == 'view'"
                                                (onChange)="ChangeAccount($event,_Journaldetail,_journalOperations)">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Journaldetail.accountdesc}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    <p-dropdown [options]="_lstEntity" [autoDisplayFirst]="false" optionLabel="metasubdescription" optionValue="metasubcode" [(ngModel)]="_Journaldetail.entity" (onChange)="ChangeEntityReference(_Journaldetail)" [disabled]="_action == 'view'">
                                    </p-dropdown>
                                </td>
                                <td>
                                    <p-dropdown [options]="_lstEntityReferences" [autoDisplayFirst]="false" optionLabel="desc" optionValue="code" [filter]="true" filterBy="desc" [showClear]="true" [(ngModel)]="_Journaldetail.entityreference" *ngIf="_action != 'view'">
                                    </p-dropdown>
                                    <p-dropdown [options]="_Journaldetail._lstEntityReferences" [autoDisplayFirst]="false" optionLabel="desc" optionValue="code" [filter]="true" filterBy="desc" [showClear]="true" [(ngModel)]="_Journaldetail.entityreference" [disabled]="true" *ngIf="_action == 'view'">
                                    </p-dropdown>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [options]="_refTypes" [autoDisplayFirst]="false" optionLabel="metasubdescription" optionValue="metasubid" [(ngModel)]="_Journaldetail.refType" [virtualScroll]="true" itemSize="30" (onChange)="ChangeReference($event,_Journaldetail)" [disabled]="_journalOperations._showRefType || _action == 'view'">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Journaldetail.refTypeDesc}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td style="width: 200px;text-align:center" pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [options]="_Journaldetail._refNos" [autoDisplayFirst]="false" optionLabel="voucherno" [filter]="true" filterBy="referenceno" [showClear]="true" optionValue="referenceno" [virtualScroll]="true" itemSize="30" [(ngModel)]="_Journaldetail.refNo"
                                                (onChange)="ChangeReferenceNO($event,_Journaldetail)" [disabled]="_journalOperations._showRefType || _action == 'view'">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Journaldetail.refNo}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>{{_Journaldetail.balanceamount}}</td>
                                <td hidden pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [(ngModel)]="_Journaldetail.currencyuniquerefid">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Journaldetail.currencyuniquerefid}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [(ngModel)]="_Journaldetail.debitamount" [disabled]="_journalOperations.isDebitDisable || _action == 'view'" (keyup)="ChangeDebitAmount($event.target.value,_journalOperations)">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Journaldetail.debitamount}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [(ngModel)]="_Journaldetail.creditamount" [disabled]="_journalOperations.isCreditDisable || _action == 'view'" (keyup)="ChangeCreditAmount($event.target.value,_journalOperations)">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Journaldetail.creditamount}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="number" pInputText [(ngModel)]="_totalDebitAmount" disabled>
                                <label for="_totalDebitAmount"> Total Debit Amount </label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="number" pInputText [(ngModel)]="_totalCreditAmount" disabled>
                                <label for="_totalCreditAmount"> Total Credit Amount </label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="number" pInputText [(ngModel)]="_totalBalanceAmount" disabled>
                                <label for="_totalBalanceAmount" *ngIf="creditbalance"> Total Credit Balance Amount </label>
                                <label for="_totalBalanceAmount" *ngIf="debitbalance"> Total Debit Balance Amount </label>
                                <label for="_totalBalanceAmount" *ngIf="balanceamount"> Total Balance Amount </label>
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>