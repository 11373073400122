import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { Branch } from 'src/app/core/Models/Branch';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { RptPurchaseVsSalesService } from 'src/app/core/Services/reports/rpt-purchase-vs-sales/rpt-purchase-vs-sales.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rpt-purchase-vs-sales',
  templateUrl: './rpt-purchase-vs-sales.component.html',
  styleUrls: ['./rpt-purchase-vs-sales.component.css']
})
export class RptPurchaseVsSalesComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptproductvssalesform: FormGroup
  _producttypelist: any[] = [];
  producttypeid: any[] = [];
  branchids: any[] = [];
  _productcategorylist: any[] = [];
  productcatid: any[] = [];
  _productlist: any[] = [];
  productid: any[] = [];
  MaxDate = new Date();
  PurchaseVsSaleslist: any[] = [];
  _BranchList: Branch[] = [];
  datepipe = new DatePipe('en-US');
  isDisablededit: boolean = true;

  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _RptPurchaseVsSalesService: RptPurchaseVsSalesService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private exportUtility: ExportUtility,
    private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }
  initialise() {
    let headerbranch = parseInt(sessionStorage["BranchID"])
    this.branchids.push(headerbranch)
    this.branchids = [...this.branchids]
  }
  ngOnInit(): void {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1)

    this._rptproductvssalesform = this.fb.group({
      fromdate: [new Date()],
      todate: [new Date()],
      sproductvssalesdate: [currentDate],
    })

    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptproductvssalesform.get("sproductvssalesdate").setValue(currentDate);
    }
    else {
      this._rptproductvssalesform.get("sproductvssalesdate").setValue(new Date(sessionStorage['Environmentenddate']));
    }
    this.utility.pageLoadScript();
    this.initialise();
    this.isDisablededit = true;
    this._IsProgressSpinner = true;
    this._RptPurchaseVsSalesService.PageOnLoad({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._producttypelist = resultJSON.producttypeList;
      this._BranchList = resultJSON.branchList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }

  getProductCategory() {
    this._productlist = []
    this.productid = []

    let Data = {
      "producttypeids": this.producttypeid
    }

    this._IsProgressSpinner = true;
    this._RptPurchaseVsSalesService.Getproductcategory(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productcategorylist = resultJSON.productcategoryList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });


  }
  getProduct() {

    let Data = {
      "productcatids": this.productcatid
    }

    this._IsProgressSpinner = true;
    this._RptPurchaseVsSalesService.GetProductheader(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productlist = resultJSON.productheaderList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }


  Clear() {
    this.producttypeid = [];
    this._productcategorylist = [];
    this.productcatid = [];
    this._productlist = [];
    this.productid = [];
    this.PurchaseVsSaleslist = []
    this._rptproductvssalesform.get('fromdate').setValue(new Date())
    this._rptproductvssalesform.get('todate').setValue(new Date())
    this.branchids = []
    this.initialise();
  }

  Search() {
    this._IsProgressSpinner = true;
    var FromDate = this._rptproductvssalesform.get('fromdate').value
    var ToDate = this._rptproductvssalesform.get('todate').value
    let Data = {
      "FromDate": this.datepipe.transform(FromDate, 'yyyy-MM-dd HH:mm:ss'),
      "ToDate": this.datepipe.transform(ToDate, 'yyyy-MM-dd HH:mm:ss'),
      "branchids": this.branchids,
      "ProducttypeIds": this.producttypeid,
      "ProductcatIds": this.productcatid,
      "ProductIds": this.productid
    }
    this._RptPurchaseVsSalesService.Search(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.PurchaseVsSaleslist = resultJSON.purchaseVsSaleslist
      this._IsProgressSpinner = false;

    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
  }

  exportExcel() {
    let PurchaseVsSalesList = this.PurchaseVsSales();
    this.exportUtility.exportExcel(PurchaseVsSalesList, 'Purchase_Vs_Sales_Report', 'xlsx');

  }
  ExportToPdf() {

    let finalizedList = this.PurchaseVsSales();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Branch',
      'Product Type',
      'Product Category',
      'Product Code',
      'Product Name',
      'Product UOM',
      'Purchase Qty',
      'Sales Qty',
      // 'Stock In Hand'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Purchase_Vs_Sales_Report.pdf');
      })
    })

  }
  PurchaseVsSales() {
    let PurchaseVsSalesList = []

    if (!this.PurchaseVsSaleslist || !this.PurchaseVsSaleslist.length) {
      this._CustomExceptionService.handleWarning("No Data Available");
      return
    }
    this.PurchaseVsSaleslist.forEach(element => {
      let PurchaseVsSales: any = {};
      PurchaseVsSales["Branch"] = element.BRANCHNAME;
      PurchaseVsSales["Product Type"] = element.PRODUCTTYPENAME;
      PurchaseVsSales["Product Category"] = element.PRODUCTCATEGORYNAME;
      PurchaseVsSales["Product Code"] = element.PRODUCTCODE;
      PurchaseVsSales["Product Name"] = element.PRODUCTNAME;
      PurchaseVsSales["Product UOM"] = element.SALESUOM;
      PurchaseVsSales["Purchase Qty"] = element.PURCHASEQUANTITY;
      PurchaseVsSales["Sales Qty"] = element.SALESQUANTITY;
      // PurchaseVsSales["Stock In Hand"] = element.STOCKINHAND;
      PurchaseVsSalesList.push(PurchaseVsSales);
    });
    return PurchaseVsSalesList;
  }
}

