<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Sales Return</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple icon="pi pi-plus" title="Add" [disabled]="!_Action._Add"
                                class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate"
                                    [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [minDate]="mindate" [maxDate]="maxdate" [(ngModel)]="todate"
                                    [showIcon]="true" dateFormat="dd/mm/yy" [monthNavigator]="true"
                                    yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;" [hidden]="_Branchdrop">
                            <span class="p-float-label">
                                <p-dropdown 
                                 [options]="_Branch" [(ngModel)]="_Branchname"
                                 [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="branchname"
                                    optionValue="branchid" optionLabel="branchname">
                                </p-dropdown>
                                <label for="_Branchname">Branch <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search"
                                icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="exportUtility.exportCSV(dt)" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_salesreturndetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [globalFilterFields]="['salesreturnno','voucherno','salesinvoiceno','salesreturndate','customername','statusname','createdbyname','createdon']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="salesquotationno"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">

                        <ng-template pTemplate="header">
                            <tr>

                                <th style="width:100px;text-align: center;">Action
                                </th>

                                <th pSortableColumn="salesreturnno">Sales Return No <p-sortIcon field="salesreturnno">
                                    </p-sortIcon>
                                </th>

                                <th pSortableColumn="salesreturndate">Sales Return Date <p-sortIcon
                                        field="salesreturndate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="voucherno">Credit Note No.<p-sortIcon field="voucherno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="salesinvoiceno">Invoice No.<p-sortIcon field="salesinvoiceno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="salesreturndate">Sales Invoice Date <p-sortIcon
                                        field="salesreturndate"></p-sortIcon>

                                <th pSortableColumn="customername">Customer <p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>

                                <!-- <th pSortableColumn="returnquantity">Return Qty <p-sortIcon field="returnquantity"></p-sortIcon>
                                </th> -->
                                <th pSortableColumn="statusname">Status<p-sortIcon field="statusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By<p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On<p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_salesreturndetail>
                            <tr>

                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="_View || !_Action._View"
                                        (click)="view(_salesreturndetail)"></button>
                                    <!-- <button pButton pRipple icon="las la-pen" title="Edit"
                                        class="p-button p-button-success p-mr-2" [disabled]="_update"
                                        [disabled]="_salesreturndetail.status == 'MSC00150' ||_salesreturndetail.status == 'MSC00046' || _salesreturndetail.status == 'MSC00047' ||_salesreturndetail.status == 'MSC00048'"
                                        (click)="edit(_salesreturndetail)"></button> -->
                                    <!-- <button pButton pRipple icon="pi pi-trash" title="Delete"
                                        class="p-button p-button-danger" [disabled]="_delete"
                                        (click)="delete(_salesreturndetail)"></button> -->
                                    <button pButton pRipple icon="las la-times" title="Cancel"
                                        [disabled]="_salesreturndetail.status == 'MSC00150' || _salesreturndetail.status == 'MSC00047' ||_salesreturndetail.status == 'MSC00046' || !_Action._Delete"
                                        class="p-button p-button-danger" (click)="confirm(_salesreturndetail)"></button>
                                    <!-- <button pButton pRipple icon="las la-print" title="Print"
                                        [disabled]="_salesreturndetail.status == 'MSC00150' ||_salesreturndetail.status == 'MSC00046' || _salesreturndetail.status == 'MSC00045' ||_salesreturndetail.status == 'MSC00048'"
                                        class="p-button-warning p-mr-2" (click)="Print(_salesreturndetail)"></button> -->
                                    <button pButton pRipple icon="las la-print" title="Print"
                                        [disabled]="!_Action._Print || _salesreturndetail.status != 'MSC00047'"
                                        class="p-button-warning p-mr-2" (click)="Print(_salesreturndetail)"></button>
                                </td>
                                <td>
                                    {{_salesreturndetail.salesreturnno}}
                                </td>
                                <td>
                                    {{_salesreturndetail.salesreturndate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_salesreturndetail.voucherno}}
                                </td>
                                <td>
                                    {{_salesreturndetail.salesinvoiceno}}
                                </td>
                                <td>
                                    {{_salesreturndetail.salesinvoicedate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_salesreturndetail.customername}}
                                </td>

                                <!-- <td>
                                    {{_salesreturndetail.returnquantity}}
                                </td> -->
                                <td>
                                    {{_salesreturndetail.statusname }}
                                </td>
                                <td>
                                    {{_salesreturndetail.createdbyname}}
                                </td>
                                <td>
                                    {{_salesreturndetail.createdon|date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>