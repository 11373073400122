import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import * as ace from "ace-builds";

import { RptrevenueexpenseService } from 'src/app/core/Services/reports/Accounting/RptRevenueExpense/rptrevenueexpense.service';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Port } from 'src/app/core/Models/Port';
import { vContainerNo, vDateSelect, vSupplierName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Producttype } from 'src/app/core/Models/Producttype';
import { MetadataMaster } from 'src/app/core/Models/overallclass';
import { AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Currencyview } from 'src/app/core/Views/Currencyview';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';



@Component({
  selector: 'app-rptrevenueexpense',
  templateUrl: './rptrevenueexpense.component.html',
  styleUrls: ['./rptrevenueexpense.component.css']
})
export class RptrevenueexpenseComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptrevenueexpenseform: FormGroup;
  _branchid: number;
  _userid: number;
  datepipe: any;
  _currencylist: Currencyview[];
  _src: SafeResourceUrl;
  _Action: IAction;
  mindate: Date;
  maxdate: Date;

  constructor(private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private fb: FormBuilder, private _RptRevenueExpenseService: RptrevenueexpenseService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }

  // @ViewChild('iframe') iframe: ElementRef;
  // ngAfterViewInit() {
  //     this.iframe.nativeElement.setAttribute('src', this.srcUrl);
  // }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(107);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptrevenueexpenseform = this.fb.group({
      month: [''],
      currencyid: [0]

    })
    this._branchid = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    // this.InitializeForm();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptrevenueexpenseform.get("month").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptrevenueexpenseform.get("month").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._rptrevenueexpenseform.controls['currencyid'].setValue(1);
    this._RptRevenueExpenseService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._currencylist = resultJSON.currencyList;
      this._IsProgressSpinner = false;
      //this.isDisabledSave = false;
      // this.isDisabledClear = false;


    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }

  clear(event) {
    //25.If clear clicking all fields should clear in grid
    this._rptrevenueexpenseform.reset();
    var dt = new Date();
    dt.setDate(dt.getDate() - 7);
    this._rptrevenueexpenseform.controls['month'].setValue(dt);
    this._rptrevenueexpenseform.controls['currencyid'].setValue(1);
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }


  GenerateReport(event) {
    var _month = this._rptrevenueexpenseform.get("month").value;
    var BranchID = parseInt(sessionStorage["currentbranchid"]);
    //var _todate = this._rptbalancesheetform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var month = datePipe.transform(_month, 'MM-yyyy');
    if (month == null) {
      this._CustomExceptionService.handleError("Please Select Month");
      return;
    }
    var currencyid = this._rptrevenueexpenseform.get("currencyid").value == null ? 0 : this._rptrevenueexpenseform.get("currencyid").value;
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._RptRevenueExpenseService.Print(month, this._userid, currencyid, BranchID).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

}
