import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Branch } from 'src/app/core/Models/Branch';
import { Level } from 'src/app/core/Models/Level';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Role } from 'src/app/core/Models/Role';
import { User } from 'src/app/core/Models/User';
import { Userbranchmap } from 'src/app/core/Models/Userbranchmap';
import { Userrolemap } from 'src/app/core/Models/Userrolemap';
import { UserService } from 'src/app/core/services/users/user.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { Dropdown } from 'primeng/dropdown';
import { MultiSelect } from 'primeng/multiselect';
import { EmptyError } from 'rxjs';
import { Employee } from 'src/app/core/Models/Employee';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { vBranchName, vBranchNameSelect, vEmployeeSelect, vNewPassword, vRoleNameSelect, vUserDiscount, vUserName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-c-user',
  templateUrl: './c-user.component.html',
  styleUrls: ['./c-user.component.css']
})
export class CUserComponent implements OnInit {
  _Userform: FormGroup;
  _Userrolemapform: FormGroup;
  _Userbranchmapform: FormGroup;
  _action: string = "create";
  _submitted = false;
  _userid: number;
  displayBasic: boolean;
  _employeeid: Employee;
  _User: User;
  _Userrolemap: Userrolemap[];
  _Userbranchmap: Userbranchmap[];
  _levels: Level;
  _series: Metadatum;
  _userstatus: Metadatum;
  _branches: Branch[];
  _roles: Role[];
  _Employeelist: User;
  _Employee: User;
  _selectedRoles: Role[] = [];
  _selectedBranches: Branch[] = [];
  _selectedDefaultBranch: Branch = new Branch();
  _selectedDefaultRole: Role = new Role();
  _employees: Employee;
  _SelectedRoleIndex: number[] = [];
  _selectedBranchIndex: number[] = [];
  _UserOBJ: any;
  selectedrows: Userrolemap[] = [];
  _UserRoleDetails: Userrolemap[] = [];
  _UserRoleDetail: Userrolemap[] = [];
  _IsProgressSpinner: boolean = true;
  selectedroleid: any;
  selectedlevelid: any;
  isDisabledsave: boolean;
  rolelevels: number = 1;
  defaultrole: number = 0;
  isdefault: boolean;
  showseries: boolean = true;
  disableseries: boolean = false;
  isDisabledreset = false;
  isdefaultbranchchanged: boolean = false;
  _Action: IAction;
  _Associateusers: User[];
  _IsshowAssociateuser: boolean = false;
  password: any;
  showblockreason: boolean = true;
  show: boolean = false;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private _UserService: UserService,
    private utility: CommonUtilities,
    private _CustomExceptionService: CustomExceptionService,
    private _router: Router,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,
    private confirmationService: ConfirmationService
  ) {
    this._Action = this._AccessRightsService.getAccessRights(19);
  }
  InitializeForm() {
    this._Userform = this.fb.group({
      companyid: [0],
      userid: [0],
      username: ['', vUserName],
      password: ['', vNewPassword],
      employeeid: [],
      userdiscountpercentage: ['', vUserDiscount],
      logincount: [],
      inactivecount: [],
      userstatus: [],
      accountingyear: [2020],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      series: [],   // it should be replaced.
      associateuser: [],
      userbranchmaps: [],
      userrolemaps: [],
      userscreenmappings: [],
      isonline: [],
      isblocked: [0],
      blockreason: []
    });
    this._Userrolemapform = this.fb.group({
      companyid: [0],
      userid: [0],
      roleid: ['', vRoleNameSelect],
      userrolestatus: ['Active'],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      accountingyear: [2020],
      isdefault: [],
      User: []
    });
    this._Userbranchmapform = this.fb.group({
      companyid: [0],
      userid: [0],
      branchid: ['', vBranchNameSelect],
      userbranchstatus: ['Active'],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      accountingyear: [2020],
      isdefault: ['', vBranchName],
      User: []
    });
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.HotKeyIntegration();
    this.InitializeForm();
    this._userid = history.state?.userid ? history.state?.userid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.checkseriesRights();
    this.password = 'password';
    this._IsProgressSpinner = true;
    this._UserService.PageOnLoad(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._levels = resultJSON.levels;
      this._userstatus = resultJSON.userStatus;
      this._series = resultJSON.series;
      this._roles = resultJSON.roles;
      this._branches = resultJSON.lstBranches;
      this._employees = resultJSON.employees;
      Object.keys(this._employees).map((Index) => {
        this._employees[Index].employeefullname = this._employees[Index].employeename;
        if (this._employees[Index].employeelastname != null)
          this._employees[Index].employeefullname = this._employees[Index].employeename + ' ' + this._employees[Index].employeelastname;
      })
      this._UserRoleDetails = resultJSON.usersrolemapviews;
      this._UserRoleDetail = resultJSON.usersrolemapviews;
      this._Userform.controls['userstatus'].setValue("MSC00001");
      this._Userform.controls['series'].setValue("MSC00093");
      this._Userform.controls["userdiscountpercentage"].setValue(0);
      this._IsProgressSpinner = false;
      this.isDisabledsave = false;
      this.showblockreason = false;
      if (this._action == 'view') {
        this._Userform.disable();
        this._Userrolemapform.disable();
        this._Userbranchmapform.disable();
        this.isDisabledsave = true;
        this.isDisabledreset = true
      }
      if (this._action == 'edit' || this._action == 'view') {
        this.disableseries = true;
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._Associateusers = updateJSON.userviews;
        this._Userform.setValue(updateJSON.loggedUser);
        var RoleDetails = updateJSON.usersrolemapviews;
        this.selectedrows = updateJSON.userrolemap;
        this.isDisabledreset = true;
        Object.keys(updateJSON.userrolemap).map((Index) => {
          var index = RoleDetails.findIndex(obj => obj.roleid == updateJSON.userrolemap[Index].roleid);
          RoleDetails[index].levelid = updateJSON.userrolemap[Index].levelid;
          RoleDetails[index].isdefault = updateJSON.userrolemap[Index].isdefault;
        });
        this._UserRoleDetails = RoleDetails;
        var localBranches = this._branches;
        var selectedBranchesArray: Branch[] = [];
        var selectedBranchArrayIndex: number[] = [];
        var selectedBranches;
        var selectedBranchObj = updateJSON.userbranchmap;
        var defaultBranchID: any;
        Object.keys(selectedBranchObj).map(function (index) {
          selectedBranches = localBranches.find((obj) => {
            return obj.branchid == selectedBranchObj[index].branchid
          })
          defaultBranchID = selectedBranchObj[index].isdefault == true ? selectedBranchObj[index].branchid : defaultBranchID;
          selectedBranchesArray.push(selectedBranches);
          selectedBranchArrayIndex.push(selectedBranches.branchid)
        });
        this._selectedBranches = selectedBranchesArray;
        this._selectedBranchIndex = selectedBranchArrayIndex;
        this._selectedDefaultBranch = this._selectedBranches.find((obj) => { return obj.branchid == defaultBranchID });
        this._Userbranchmapform.controls["isdefault"].setValue(defaultBranchID);
        if (this._Userform.get("series").value == "MSC00094") {
          if (this._Userform.get("associateuser").value) {
            this._IsshowAssociateuser = true;
            this._Userform.get("associateuser").disable();
          } else {
            this.getassociateuser();
            this._IsshowAssociateuser = true
          }
        }
        if (this._Userform.get("isblocked").value)
          this.showblockreason = true;
      }
      (this._UserRoleDetails || []).forEach(element => {
        element.isdisable = true;
        (this.selectedrows || []).forEach(element1 => {
          if (element.roleid == element1.roleid)
            element.isdisable = false;
        });
      });
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onClick() {
    if (this.password == 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
  checkseriesRights() {
    if (!this._Action._Series) {
      this.showseries = true;
    } else {
      this.showseries = false;
    }
  }
  onchangeseries(event) {
    let selectedseries = event.value;
    if (selectedseries == "MSC00093") {
      this._Userform.get("associateuser").reset();
      this._IsshowAssociateuser = false;
    }
    if (selectedseries == "MSC00094") {
      if (!this._Userbranchmapform.get("isdefault").value) {
        this._CustomExceptionService.handleError("Please select default branch");
        return;
      }
      this.getassociateuser();
      this._IsshowAssociateuser = true;
    }
  }
  OnDefaultRole(_RoleDetail: Userrolemap) {
    if (this._UserRoleDetails && this._UserRoleDetails.length) {
      for (let i = 0; i < this._UserRoleDetails.length; i++) {
        if (this._UserRoleDetails[i].roleid !== _RoleDetail.roleid) {
          this._UserRoleDetails[i].isdefault = false;
          this.defaultrole = 0;
        }
      }
    }
  }
  getassociateuser() {
    var username = this._Userform.get("username").value;
    var series = this._Userform.get("series").value;
    if (series == "MSC00094") {
      this._IsProgressSpinner = true;
      this._UserService.getassociateuser(username, this._Userbranchmapform.get("isdefault").value).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Associateusers = resultJSON.userviews
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  onSave() {
    this._submitted = true;
    var series = this._Userform.get("series").value;
    var psw = this._Userform.get("password").value;
    var isblocked = this._Userform.get("isblocked").value;
    var blockreason = this._Userform.get("blockreason").value;
    this.fillUserBranchForm();
    if (this._Userform.valid) {
      if (this._Userbranchmapform.valid) {
        var errMsg = null;
        if (psw.length < 7) {
          errMsg = "Password Should have Atleast 7 Characters";
          this.ShowErrorMsg(errMsg);
          return;
        }
        if (this.selectedrows.length == 0) {
          errMsg = "Please Select Atleast One Role in the Grid";
          this.ShowErrorMsg(errMsg);
          return;
        }
        if (isblocked) {
          if (blockreason == '' || blockreason == null) {
            errMsg = "Please Enter Reason For Block";
            this.ShowErrorMsg(errMsg);
            return;
          }
        }
        else {
          let selectedRoles = [];// this._UserRoleDetails.filter(f => this.selectedrows.includes(f));
          for (let i = 0; i < this._UserRoleDetails.length; i++) {
            let selectedList = this.selectedrows.filter(f => f.roleid == this._UserRoleDetails[i].roleid);
            if (selectedList.length > 0)
              selectedRoles.push(selectedList[0]);
          }
          let isLevelSelected = selectedRoles.some(s => s.levelid == 0);
          let isDefaultRoleSelected = selectedRoles.some(s => s.isdefault == true);
          if (!isDefaultRoleSelected) {
            errMsg = "Please Select Default Role in the Grid";
          }
          if (isLevelSelected) {
            errMsg = "Please Select Level For All Selected Roles";
          }
          var userdiscountpercentage = this._Userform.controls["userdiscountpercentage"].value;
          if (userdiscountpercentage > 100) {
            errMsg = "User Discount Percentage Should be less than or Equal to 100 ";
          }
        }
        if (this.ShowErrorMsg(errMsg))
          return;
        var l_userrolemap: Userrolemap[] = [];
        for (let i = 0; i < this._UserRoleDetails.length; i++) {
          for (let j = 0; j < this.selectedrows.length; j++) {
            if (this._UserRoleDetails[i].roleid !== this.selectedrows[j].roleid)
              continue;
            var currentRow = this._UserRoleDetails[i];
            var l_userrolemaps = new Userrolemap();
            l_userrolemaps.userid = this._Userform.get("userid").value;
            l_userrolemaps.roleid = currentRow.roleid;
            l_userrolemaps.companyid = this._Userform.get("companyid").value;
            l_userrolemaps.levelid = currentRow.levelid;
            l_userrolemaps.accountingyear = new Date().getFullYear();
            l_userrolemaps.createdby = sessionStorage["userid"];
            l_userrolemaps.createdon = new Date();
            l_userrolemaps.userrolestatus = "MSC00001";
            l_userrolemaps.isdefault = currentRow.isdefault;
            l_userrolemap.push(l_userrolemaps);
          }
        }
        this._UserRoleDetail = l_userrolemap;
        if (series == "MSC00094" && this._action == 'create') {
          if (!this._Userform.get("associateuser").value) {
            this._CustomExceptionService.handleError("Please select associate user");
            return;
          }
          if (this._Userform.get("associateuser").value) {
            this.confirmationService.confirm({
              message: 'Kindly Ensure asscoiate user before you save the data?',
              accept: () => {
                this.Save();
              },
              reject: () => {
              }
            });
          }
        }
        else if (series == "MSC00094" && this._action == 'edit' && this.isdefaultbranchchanged) {
          if (!this._Userform.get("associateuser").value) {
            this._CustomExceptionService.handleError("Please select associate user");
            return;
          }
          if (this._Userform.get("associateuser").value) {
            let msg = "Kindly Confirm to change Associated User's Default Branch also";
            this.confirmationService.confirm({
              message: msg,
              accept: () => {
                this.Save();
              },
              reject: () => {
              }
            });
          }
        }
        else {
          this.Save();
        }
      }
    }
  }
  Save() {
    this._User = this._Userform.getRawValue();
    this._User.createdon = new Date();
    const resultJSONRole = JSON.parse(JSON.stringify(this._UserRoleDetail));
    const resultJSONBranch = JSON.parse(JSON.stringify(this._Userbranchmap));
    this._UserOBJ = { "User": this._User, "Userrolemap": resultJSONRole, "Userbranchmap": resultJSONBranch };
    this._IsProgressSpinner = true;
    if (this._action == 'create') {
      this._User.createdon = new Date();
      this._User.createdby = sessionStorage["userid"];
      this._UserService.create(this._UserOBJ).subscribe((result) => {
        const resutJSON = JSON.parse(JSON.stringify(result));
        if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
          this.InitializeForm();
          this._Userform.controls['userstatus'].setValue("MSC00001");
          this._Userform.controls['series'].setValue("MSC00093");
          this._submitted = false;
          this._IsProgressSpinner = false;
          this.selectedrows = [];
          this.isdefault = false;
          this._IsshowAssociateuser = false;
          this._Associateusers = []
          this._UserService.PageOnLoad(this._userid).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            this._levels = resultJSON.levels;
            this._UserRoleDetails = resultJSON.usersrolemapviews;
            this.defaultrole = 0;
          });
        }
        else {
          this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          this._IsProgressSpinner = false;
        }
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    else if (this._action == 'edit') {
      this._User.modifiedon = new Date();
      this._User.modifiedby = sessionStorage["userid"];
      this._User.createdon = new Date();
      this._User.createdby = sessionStorage["userid"];
      this._IsProgressSpinner = true;
      this._UserService.edit(this._UserOBJ).subscribe((result) => {
        const resutJSON = JSON.parse(JSON.stringify(result));
        if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
        }
        else {
          this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
        }
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  reset() {
    this._Userform.reset();
    this.InitializeForm();
    this._submitted = false;
    this.showblockreason = false;
    this.selectedrows = [];
    this.defaultrole = 0;
    this._UserService.PageOnLoad(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._levels = resultJSON.levels;
      this._UserRoleDetails = resultJSON.usersrolemapviews;
    });
  }
  goBack(event) {
    this._router.navigate(['/vUser']);
  }
  showBasicDialog() {
    this.displayBasic = true;
  }
  OnChangeofBanches(event) {
    if (event != null || event != undefined) {
      var localBranches = this._branches;
      var selectedBranchesArray: Branch[] = [];
      var selectedBranches;
      Object.keys(event.value).map(function (index) {
        selectedBranches = localBranches.find((obj) => {
          return obj.branchid == event.value[index]
        })
        selectedBranchesArray.push(selectedBranches);
      });
      this._selectedBranches = selectedBranchesArray;
      if (this._selectedBranches.length > 0) {
        if (!this._selectedBranches.some(b => b.branchid == this._Userbranchmapform.get("isdefault").value)) {
          this._Userbranchmapform.controls["isdefault"].setValue(null);
          this._selectedDefaultBranch = null;
        }
      }
    }
  }
  setDefaultBranch(event) {
    var localBranches = this._branches;
    var selectedBranches = localBranches.find((obj) => {
      return obj.branchid == event.value
    })
    this._selectedDefaultBranch = selectedBranches;
    this.isdefaultbranchchanged = true;
  }
  fillUserBranchForm() {
    var selectedBranches = this._selectedBranches
    var UserBranches: Userbranchmap[] = [];
    var localbranch: Userbranchmap;
    var isDefault: any = this._selectedDefaultBranch?.branchid;
    var userId: any = this._Userform.get("userid").value;
    Object.keys(selectedBranches).map(function (Index) {
      localbranch = new Userbranchmap();
      localbranch.userid = userId;
      localbranch.branchid = selectedBranches[Index].branchid;
      localbranch.isdefault = (selectedBranches[Index].branchid == isDefault) ? true : false;
      localbranch.createdon = new Date();
      localbranch.modifiedon = new Date();
      localbranch.companyid = 0;
      localbranch.userbranchstatus = "MSC00001";
      localbranch.createdby = sessionStorage["userid"];
      localbranch.modifiedby = sessionStorage["userid"];
      localbranch.accountingyear = 2020;
      UserBranches.push(localbranch);
    });
    this._Userbranchmap = UserBranches;
  }
  onRowSelect(event) {
    event.data.isdisable = false;
    this.isdefault = false;
  }
  onRowUnselect(event) {
    event.data.isdisable = true;
    event.data.isdefault = false;
    this.isdefault = false;
  }
  defaultSelectedBranch(SelectedBranchObj) { }
  onRowSelectPopup(event) {
    let row = event.data;
    this._employeeid = row._employeeid;
    this.displayBasic = false;
  }
  isblocked(event) {
    if (event.checked) {
      this.showblockreason = true;
    }
    else {
      this.showblockreason = false;
    }
  }
  onEd() {
    alert("test");
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._UserOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  ShowErrorMsg(errMsg) {
    if (!errMsg)
      return false;
    this.messageService.add({ severity: 'error', summary: 'Error Message', sticky: true, detail: errMsg });
    return true;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}