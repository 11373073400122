import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { RptreceivablespayablesService } from 'src/app/core/Services/reports/Accounting/RptReceivablesPayables/rptreceivablespayables.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
	selector: 'app-rpt-receivables-payables',
	templateUrl: './rpt-receivables-payables.component.html',
	styleUrls: ['./rpt-receivables-payables.component.css']
})
export class RptReceivablesPayablesComponent implements OnInit {
	_IsProgressSpinner: boolean = false;
	_partyTypeList: Metadatum[] = [];
	_submitted = false;
	_statuslist: Metadatum[];
	status: string = "";
	_paymentReceivableViews: any[] = [];
	cols: any[] = [];
	cash_cols: any[];
	_userid: number = parseInt(sessionStorage["userid"]);
	_rptreceivablespayables: FormGroup;
	_receivablepayableLists: any[] = [];
	_src: SafeResourceUrl;
	partytype: string;
	payablelist: any[] = [];
	_screenlist: any[] = [];
	PartyType: string;
	colWidth: any = 100;
	constructor(
		private utility: CommonUtilities,
		private _RptreceivablespayablesService: RptreceivablespayablesService,
		private _CustomExceptionService: CustomExceptionService,
		private exportUtility: ExportUtility,
	) { }
	ngOnInit(): void {
		this.utility.pageLoadScript();
		this._IsProgressSpinner = true;
		this._userid = parseInt(sessionStorage["userid"]);
		this._RptreceivablespayablesService.PageOnload().subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._screenlist = resultJSON.partyTypeList;
			// this.cols = resultJSON.paymentReceivable;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	GenerateReport() {
		if (!this.PartyType || this.PartyType == "") {
			this._CustomExceptionService.handleWarning("Please Select Party Type");
			return
		}
		let data = {
			partytype: this.PartyType
		};
		this._IsProgressSpinner = true;
		this._RptreceivablespayablesService.PayableReceivableReport(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._paymentReceivableViews = resultJSON.payablelist;
			var onlykeys = this._paymentReceivableViews[0];
			this.cols = Object.keys(onlykeys);
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	clear() {
		this.PartyType = "";
		this._paymentReceivableViews = [];
	}
	exportExcel() {
		let PayableReceivableList = this.PayableReceivableList();
		this.exportUtility.exportExcel(PayableReceivableList, 'Payables Receivables Report', 'xlsx');
	}
	exportPdf() {
		let PayableReceivableList = this.PayableReceivableList();
		const data = PayableReceivableList.map(Object.values);
		const head = [['Party Type', 'Party Code', 'Party Name', 'USD', 'BOND', 'BANK', 'RAND ', "Conslidated In USD"
		]]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					},
					// theme: 'grid',
					headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
						// fontSize: 15,
					},
					bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('Payables Receivables Report.pdf');
			})
		})
	}

	PayableReceivableList() {
		let payablereceivableledgerLists = [];

		this._paymentReceivableViews.forEach((element, index) => {
			let payablesreceivables: any = {};
			payablesreceivables["Party Type"] = element.PARTYNAME;
			payablesreceivables["Party Code"] = element.PARTYCODE;
			payablesreceivables["Party Name"] = element.REFNAME;
			payablesreceivables["USD"] = element.USD;
			payablesreceivables["BOND"] = element.BOND;
			payablesreceivables["BANK"] = element.BANK;
			payablesreceivables["RAND"] = element.RAND;
			payablesreceivables["Conslidated In USD"] = element.CONSLIDATEDINUSD;
			payablereceivableledgerLists.push(payablesreceivables);
		});
		return payablereceivableledgerLists;
	}
}