import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { vEffectiveFrom, vRoleName } from 'src/app/core/Validators/validation';
import { Role } from 'src/app/core/Models/Role';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { RoleService } from 'src/app/core/Services/users/role.service';
//Debug
import * as ace from "ace-builds";
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { DatePipe } from '@angular/common';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

@Component({
  selector: 'app-c-role',
  templateUrl: './c-role.component.html',
  styleUrls: ['./c-role.component.css']
})
export class CRoleComponent implements OnInit {
  _roleform: FormGroup;
  _rolestatus: Metadatum;
  _submitted = false;
  _action: string = "create"
  _roleid: number;
  _Role: Role;
  minDate: Date;
  isDisabledSave = false;
  isDisabledClear = false;
  _IsProgressSpinner: boolean = true;
  //Debug
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, private _RoleService: RoleService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService) {
    this.HotKeyIntegration();
    // //Save Short cut keys
    // this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
    //   this.onSave();
    //   return false; // Prevent bubbling
    // }));
    // //Back To Search Short cut keys
    // this._hotkeysService.add(new Hotkey('backspace', (event: KeyboardEvent): boolean => {
    //   this.goBack(event);
    //   return false; // Prevent bubbling
    // }));

    // //Clear
    // this._hotkeysService.add(new Hotkey('alt+x', (event: KeyboardEvent): boolean => {
    //   this.reset(event);
    //   return false; // Prevent bubbling
    // }));
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  InitializeForm() {
    this._roleform = this.fb.group({
      companyid: [0],
      roleid: [0],
      rolename: ['', vRoleName],
      rolestatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      userrolemaps: [],
      effectivefrom: ['', vEffectiveFrom],
      effectiveto: [],
      userscreenmappings: []
    });
    this._roleform.controls['rolestatus'].setValue("MSC00001");
    this._roleform.controls['effectivefrom'].setValue(new Date());
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._roleform.controls['effectivefrom'].setValue(new Date());
    this._roleid = history.state?.roleid ? history.state?.roleid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;


    if (this._action == 'view') {
      this._roleform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
    }

    if (this._action == 'edit') {
      this.isDisabledClear = true;
      this.isDisabledSave = false;
    }
    this._IsProgressSpinner = true;
    this._RoleService.PageOnLoad(this._roleid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._rolestatus = resultJSON.status;
      this._IsProgressSpinner = false;
      this._roleform.controls['rolestatus'].setValue("MSC00001");

      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        if (updateJSON.role.effectiveto == null) {

        }
        else {
          updateJSON.role.effectiveto = new Date(updateJSON.role.effectiveto);
        }
        updateJSON.role.effectivefrom = new Date(updateJSON.role.effectivefrom);

        this._roleform.setValue(updateJSON.role);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSave() {
    this._submitted = true;

    if (this._roleform.valid) {

      var Effectivefrom = this._roleform.get("effectivefrom").value;
      var Effectiveto = this._roleform.get("effectiveto").value;
      if (Effectiveto == null) {
        this._roleform.get("effectiveto").setValue(null);
      }
      else {
        var datePipe = new DatePipe("en-US");
        var peffectiveto = datePipe.transform(Effectiveto, 'yyyy-MM-dd');
        // this._Role.effectiveto = (new Date(peffectiveto));
        this._roleform.get("effectiveto").setValue(new Date(peffectiveto))
        // this._roleform.get("effectiveto").setValue(this._Role.effectiveto);
      }
    
      var datePipe = new DatePipe("en-US");
      var peffectivefrom = datePipe.transform(Effectivefrom, 'yyyy-MM-dd');

      this._Role = this._roleform.value;
      this._Role.effectivefrom = (new Date(peffectivefrom));
      if(peffectivefrom>peffectiveto)
      {
        this._CustomExceptionService.handleWarning("Effective From Date should be less than the Effective to Date");
        return;
      }
      //Debug
      //  this.onEd();

      if (this._action == 'create') {
        this._Role.createdon = new Date();
        this._Role.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._RoleService.create(this._Role).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
      else if (this._action == 'edit') {
        this._Role.modifiedon = new Date();
        this._Role.modifiedby = sessionStorage["userid"];
        this._RoleService.edit(this._Role).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);

          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          } this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        this.isDisabledClear = true;
        this.isDisabledSave = true;
      }
    }
  }
  //12. All entered input fields should be cleared.
  reset(event) {
    this._submitted = true;
    this._roleform.reset();
    this.isDisabledSave = false;
    this.isDisabledClear = false;
    this.InitializeForm();
    this._submitted = false;
  }
  //24. on clicking back button , it Navigates to the search screen
  goBack(event) {
    this._router.navigate(['/vRole']);
  }
  OnFromDateSelect(event) {
    this.minDate = event;
  }
  //Debug
  //  onEd()
  //  {
  //    ace.config.set("fontSize", "14px");
  //    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //    const aceEditor = ace.edit(this.editor.nativeElement);
  //    aceEditor.getSession().setMode("ace/mode/json");
  //    var myObj =  this._Role ;
  //    var myJSON = JSON.stringify(myObj);
  //    aceEditor.session.setValue(myJSON);

  //  }
}


