import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class OnlineStockDeliveryService {
  public _Action : IAction ;
  public  _OnlineStockDeliveryService = environment.OnlineStockDeliveryService;
  constructor(private http:HttpClient) { }

  PageOnload(deliveryid:number,branchid:number,isselfpickup:boolean): Observable<any> {
    var Query = this._OnlineStockDeliveryService+ '/PageOnLoad/' + deliveryid+'/'+branchid+'/'+isselfpickup;
    return this.http.get<{data:any}>(Query);
  }
  GetInvoiceProducts(Invoice:object) {
    var Query = this._OnlineStockDeliveryService + '/GetInvoiceProducts'
    return this.http.post<{ data: any }>(Query,Invoice);
  }
  Create(onlinestockdelivery:object) {
    var Query = this._OnlineStockDeliveryService + '/Create'
    return this.http.post<{ data: any }>(Query,onlinestockdelivery);
  }
  Cancel(salesinvoiceno:string,orderid:string,orderno:string,productid:number): Observable<any> {
    var Query = this._OnlineStockDeliveryService+ '/Cancel/' + salesinvoiceno+'/'+orderid+'/'+orderno+'/'+productid;
    return this.http.get<{data:any}>(Query);
  }

  FetchAll(fromdate:string,todate:string,userid:number,branchid:number,roleid:number): Observable<any> {
    var Query = this._OnlineStockDeliveryService+ '/FetchAll/' + fromdate+'/'+todate+'/'+userid+'/'+branchid +'/'+roleid;
    return this.http.get<{data:any}>(Query);
  }
  GetStock(productid:number,branchid:number):Observable<any>{
    var Query=this._OnlineStockDeliveryService+'/GetStock/'+productid+'/'+branchid;
    return this.http.get<{data:any}>(Query);
  }

}
