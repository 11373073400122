<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_Pettycashform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Petty Cash</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save" [disabled]="_IsSaveDisable"></button>
                                <button pButton pRipple type="button" (click)="reset($event)" title="Clear" icon="pi pi-trash" class="" class="p-button-danger" [disabled]="_IsClearDisable"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search" title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="pettycashno" disabled formControlName="pettycashno" >
                                    <label for="pettycashno">Petty Cash No </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar  [showIcon]="true" [minDate]="today" [maxDate]="maxdate" formControlName="requestdate" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="requestdate">Request Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Pettycashform.get('requestdate').touched) && _Pettycashform.get('requestdate').errors?.SelectRequestDate"> 
                                    Please Select Request Date 
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Branches" optionLabel="branchname" optionValue="branchid"
                                        name="requestedbranchid" formControlName="requestedbranchid" [disabled]="true">
                                    </p-dropdown>
                                    <label for="requestedbranchid"> Branch</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Users" optionLabel="username" optionValue="userid"
                                        name="requestperson" formControlName="requestperson" [disabled]="true">
                                    </p-dropdown>
                                    <label for="requestperson">Request Person Name </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="requestamount"
                                        formControlName="requestamount">
                                    <label for="requestamount"> Request Amount <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Pettycashform.get('requestamount').touched) && _Pettycashform.get('requestamount').errors?.RequestAmountRequired"> 
                                    Please enter Request Amount 
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies" optionLabel="currencyname" (onChange)="ChangeCurrencies($event)"
                                        optionValue="currencyid" name="currentcurrencyid" formControlName="currentcurrencyid">
                                    </p-dropdown>
                                    <label for="currencyid"> Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Pettycashform.get('currentcurrencyid').touched) && _Pettycashform.get('currentcurrencyid').errors?.SelectCurrency"> 
                                    Please Select Currency
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="exchangerateinusd"
                                    formControlName="exchangerateinusd" disabled>
                                    <label for="exchangerateinusd"> Exchange Rate </label>
                                </span>
                            </td>
                            <td rowspan="2">
                                <span class="p-float-label" rowspan="3">
                                    <textarea rows="2" cols="30" pInputTextarea name="reason"
                                        formControlName="reason"></textarea>
                                    <label for="reason">Reason <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Pettycashform.get('reason').touched) && _Pettycashform.get('reason').errors?.ReasonRequired"> 
                                    Please Enter Reason
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr>
                            <td>
                                <!-- <p-button label="Send to Approval" icon="las la-check" (click)="SendToApproval($event)"></p-button> -->
                            </td>
                            <td>
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>