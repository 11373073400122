


export class Userrolemap {
  companyid: number;
  userid: number;
  rolename: number;
  roleid: number;
  levelid: number;
  userrolestatus: string;
  createdby: number;
  createdon: Date ;
  modifiedby: number ;
  modifiedon: Date ;
  accountingyear: number;
  isdefault: boolean ;
  isdisable: boolean ;
}
