import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ProductService } from 'src/app/core/Services/masters/product.service';
import { SmsconfigurationService } from 'src/app/core/Services/masters/smsconfiguration.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Productview } from 'src/app/core/Views/Productview';
import { Smsemailalertview } from 'src/app/core/Views/Smsemailalertview';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-v-sms-configuration',
  templateUrl: './v-sms-configuration.component.html',
  styleUrls: ['./v-sms-configuration.component.css']
})
export class VSmsConfigurationComponent implements OnInit {

  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  // _productsview: Productview;
  // _productview: Productview;
  _Emailsmsviews: Smsemailalertview;
  _Emailsmsview: Smsemailalertview;
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  constructor(private fb: FormBuilder,  private _SmsConfigurationService: SmsconfigurationService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService, private confirmationService: ConfirmationService) {
   
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(144);
    this._IsProgressSpinner = true;
    this.utility.pageLoadScript();
    this.HotKeyIntegration();
    this._SmsConfigurationService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Emailsmsviews = resultJSON.smsemailalertviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }

  view(event) {
    this.router.navigate(['/cSmsConfiguration'], { state: { smsemailsettingid: event.smsemailsettingid, action: 'view' } });
  }

  confirm(view) {
   
  }

  ChangeStatus(view) {
    //alert("delete");
    // let userid = sessionStorage["userid"];
    // this._IsProgressSpinner = true;
    // this.utility.pageLoadScript();
    // this._ProductService.changestatus(view.productid, userid).subscribe((result) => {
    //   const resultJSON = JSON.parse(JSON.stringify(result));
    //   if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
    //     this._productsview = resultJSON.productviews;
    //     this._IsProgressSpinner = false;
    //   }
    //   else {
    //     this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
    //     this._IsProgressSpinner = false;
    //   }
    // },
    //   error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  edit(event) {
    this.router.navigate(['/cSmsConfiguration'], { state: { smsemailsettingid: event.smsemailsettingid, action: 'edit' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cSmsConfiguration']);
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

}
