import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../../domain/appconfig';
@Injectable({
	providedIn: 'root'
})
export class PurchaseDashboardService {
	public _purchaseDashboardService = environment.PurchaseDashboardService;
	token: any;
	header: any;
	config: AppConfig = {
		theme: 'saga-blue',
		dark: false,
		inputStyle: 'outlined',
		ripple: true
	};
	constructor(private http: HttpClient) {
		this.token = sessionStorage['AuthToken'];
		this.header = this.createHeaders(this.token)
	}
	createHeaders(token: any): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`, // Include your token here	
		});
	}
	private configUpdate = new Subject<AppConfig>();
	configUpdate$ = this.configUpdate.asObservable();

	updateConfig(config: AppConfig) {
		this.config = config;
		this.configUpdate.next(config);
	}

	getConfig() {
		return this.config;
	}

	PageOnload(): Observable<any> {
		const headers = this.header;
		var Query = this._purchaseDashboardService + '/pageload';
		return this.http.get<{ data: any }>(Query, { headers });
	}
	PurchaseReport(obj: object): Observable<any> {
		const headers = this.header;
		var Query = this._purchaseDashboardService + '/PurchaseReport';
		return this.http.post(Query, obj, { headers });
	}
	SalesByCategory(branchid: number): Observable<any> {
		const headers = this.header;
		var Query = this._purchaseDashboardService + '/FastMovingStock/' + branchid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	StockFreshNess(branchid: number): Observable<any> {
		const headers = this.header;
		var Query = this._purchaseDashboardService + '/StockFreshNess/' + branchid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
}