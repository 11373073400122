import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { ProductcategoryService } from 'src/app/core/services/masters/productcategory.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { vBranchNameSelect, vCategoryName, vStatusSelect, vProductTypeName } from 'src/app/core/Validators/validation';
import { Branchwiseproductcategory } from 'src/app/core/Models/Branchwiseproductcategory';
import { usererrors } from 'src/app/core/errors/usererrors';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-c-product-category',
  templateUrl: './c-product-category.component.html',
  styleUrls: ['./c-product-category.component.css']
})
export class CProductCategoryComponent implements OnInit {
  _productcategoryform: FormGroup;
  _branchwiseproductcategoryform: FormGroup;
  _categorystatus: Metadatum;//26.status is drop down column load from metadata(active, in active)
  _producttypes: Producttype;//5.product type load from ProductType
  _branches: Branch[];//6.branch load from branch master (branch code, branch name) and it is multiselect 
  _categoryid: number;
  _category: Productcategory;
  _branchwisecategory: Branchwiseproductcategory[];
  _CategoryOBJ: any;
  _selectedDefaultBranch: Branch = new Branch();
  _selectedBranchIndex: number[] = [];
  _action: string = "create";
  _submitted = false;
  _IsProgressSpinner: boolean = true;
  isDisabledSave = false;
  isDisabledClear = false;
  _Action: IAction;
 

  _selectedBranches: number[] = [];
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private _CustomExceptionService: CustomExceptionService,
    private _CategoryService: ProductcategoryService,
    private _router: Router,
    private utility: CommonUtilities,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,

  ) {
    this._categoryid = history.state?.productcategoryid ? history.state?.productcategoryid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._productcategoryform = this.fb.group({
      productcategoryid: [0],
      categoryname: ['', vCategoryName],
      producttypeid: ['', vProductTypeName],
      categorystatus: ['', vStatusSelect],
      accountingyear: [2020],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      branchwiseproductcategories: [],
      customerwisediscounts: [],
      producttype: [],
      eccategoryname: [],
      eccategoryid: [],
      ecnavigateurl: [],
      // isbranchlevelprice: false
    });
    this._branchwiseproductcategoryform = this.fb.group({
      productcategorydetailid: [0],
      productcategoryid: [0],
      branchid: ['', vBranchNameSelect],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      producttypeid: [],
      productcategory: []
    });
    this._productcategoryform.controls['categorystatus'].setValue("MSC00001");
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(16);
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;
    if (this._action == 'view') {
      this._productcategoryform.disable();
      this._branchwiseproductcategoryform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
    }
    if (this._action == 'edit') {
      this.isDisabledClear = true;
      this.isDisabledSave = false;
      this._productcategoryform.get("producttypeid").disable();
    }
    this._CategoryService.PageOnLoad(this._categoryid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._categorystatus = resultJSON.productCategoryStatus;
      this._producttypes = resultJSON.producttypes;
      this._branches = resultJSON.branches;
      this._productcategoryform.controls['categorystatus'].setValue("MSC00001");
      this._IsProgressSpinner = false;
      this._categoryid = history.state.productcategoryid;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._productcategoryform.setValue(updateJSON.productcategory);
        var selectedBranchArrayIndex: number[] = [];
        var selectedBranchObj = updateJSON.branchwiseproductcategories;
        Object.keys(selectedBranchObj).map(function (index) {
          selectedBranchArrayIndex.push(selectedBranchObj[index].branchid);
        });
        this._selectedBranchIndex = selectedBranchArrayIndex;
        this._selectedBranches = selectedBranchArrayIndex;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSave() {
    this._submitted = true;
    this.fillBranchwiseProductCategoryForm();
    if (this._productcategoryform.valid && this._branchwiseproductcategoryform.valid) {
      let ECname = this._productcategoryform.get("eccategoryname").value
      let Cname = this._productcategoryform.get("categoryname").value
      if (!ECname || ECname == "" || ECname == undefined || ECname == null) {
        this._productcategoryform.get("eccategoryname").setValue(Cname);
      }
      this._category = this._productcategoryform.value;
      const resultJSONBranch = JSON.parse(JSON.stringify(this._branchwisecategory));
      this._CategoryOBJ = { "Productcategory": this._category, "Branchwiseproductcategory": resultJSONBranch };
      if (this._action == 'create') {
        this._category.accountingyear = new Date("YYYY").getFullYear();
        this._category.createdon = new Date();
        this._category.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._CategoryService.create(this._CategoryOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._IsProgressSpinner = false;
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.InitializeForm();
            this._submitted = false;
            this._productcategoryform.controls['categorystatus'].setValue("MSC00001");
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          } this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        this._category.modifiedon = new Date();
        this._category.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._CategoryService.edit(this._CategoryOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._IsProgressSpinner = false;
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          } this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
  reset(event) {
    this.InitializeForm();
    this._submitted = false;
  }
  goBack(event) {
    this._router.navigate(['/vProductCategory']);
  }
  OnChangeofBanches(event) {
    this._selectedBranches = event.value;
  }
  fillBranchwiseProductCategoryForm() {
    var selectedBranches = this._selectedBranches
    var BranchProductCategories: Branchwiseproductcategory[] = [];  //output objects
    var branchproductcategory: Branchwiseproductcategory;
    var productcategoryId: any = this._productcategoryform.get("productcategoryid").value;
    var producttypeId: any = this._productcategoryform.get("producttypeid").value;
    // var isbranchlevelprice: Boolean = this._productcategoryform.get("isbranchlevelprice").value;

    Object.keys(selectedBranches).map(function (Index) {
      branchproductcategory = new Branchwiseproductcategory();
      branchproductcategory.productcategoryid = productcategoryId;
      branchproductcategory.producttypeid = producttypeId;
      branchproductcategory.branchid = selectedBranches[Index];
      branchproductcategory.createdon = new Date();
      branchproductcategory.modifiedon = new Date();
      branchproductcategory.createdby = sessionStorage["userid"];
      branchproductcategory.modifiedby = sessionStorage["userid"];
      // branchproductcategory.isbranchlevelprice = isbranchlevelprice;

      BranchProductCategories.push(branchproductcategory);
    });
    this._branchwisecategory = BranchProductCategories;
  }
  onEd() {
    alert("test");
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._CategoryOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
} 