import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Creditnotegettransactiondetailsview } from 'src/app/core/Models/Credinotegettransactiondetailsview';
import { Creditnote } from 'src/app/core/Models/Creditnote';
import { Creditnotedetail } from 'src/app/core/Models/Creditnotedetail';
import { Creditnotegettransactionview } from 'src/app/core/Models/Creditnotegettransactionview';
import { Creditnoteothercharge } from 'src/app/core/Models/Creditnoteothercharges';
import { Currency } from 'src/app/core/Models/Currency';
import { Customer } from 'src/app/core/Models/Customer';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { Tax } from 'src/app/core/Models/Tax';
import { CreditnoteService } from 'src/app/core/services/accounts/creditnote.service';
import { DirectcreditnoteService } from 'src/app/core/Services/accounts/directcreditnote.service';
import { vSelectCreditnoteDate, vSelectPartyType, vSelectAccountName, vSelectReferenceNo, vSelectCurrency, vEmployeeSelect } from 'src/app/core/Validators/validation';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { KeyPress } from 'src/assets/js/KeyPress';

@Component({
  selector: 'app-c-direct-credit-note',
  templateUrl: './c-direct-credit-note.component.html',
  styleUrls: ['./c-direct-credit-note.component.css']
})
export class CDirectCreditNoteComponent implements OnInit {
  _IsSaveHidden: boolean = false;
  _IsClearHidden: boolean = false;
  _Creditnoteform: FormGroup;
  _creditnotetransactionform: FormGroup;
  _Creditnotedetailform: FormGroup;
  voucherno: string;
  _Creditnote: Creditnote;
  _Creditnotedetail: Creditnotedetail;
  _CreditnoteOBJ: any;
  _taxlist: Tax[] = [];
  _Reasons: Metadatum[] = [];
  _ProductNames: Productoverallview[] = [];
  // For DropDown
  _Currencies: Currency;
  _CurrenciesTemp: Currency[];
  _accounts: Accountledger[];
  _EvidenceTypes: Metadatum[];
  _EvidenceNumbers: Metadatum[];
  _Partytypes: Metadatum[];
  _status: Metadatum[];
  // For Grid
  _TransactionDetails: Creditnotedetail[] = [];
  _TransactionDetail: Creditnotegettransactiondetailsview;
  selectedrows: Creditnotegettransactiondetailsview[] = [];
  // For Poppup
  _CreditReferences: Creditnotegettransactionview[];
  _CreditReference: Creditnotegettransactionview;
  selectedReferenceNo: any;
  _customers: Customer[];
  _action: string = "create";
  _submitted = false;
  displayBasic: boolean;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"];
  _IsProgressSpinner: boolean = true;
  _creditnoteOBJ: any;
  creditnoteothercharges: Creditnoteothercharge[] = [];
  _Descriptions: Othercharge[] = [];
  _ChargesTemp: Othercharge[] = [];
  _OtherBalance: number;
  grossamount: number;
  otherchargesamount: number;
  netamount: number;
  selectedOtherChargesRows: any;
  mindate: Date;
	maxdate: Date;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _CreditnoteService: DirectcreditnoteService,
    //_CreditnoteService = environment.CreditnoteService
    private utility: CommonUtilities,
    private _router: Router, public keypress: KeyPress,
    private _hotkeysService: HotkeysService) {
  }


  InitializeForm() {
    this._Creditnoteform = this.fb.group({
      creditnoteid: [0],
      voucherno: [0],
      creditnotedate: ['', vSelectCreditnoteDate],
      partytypeid: ['', vSelectPartyType],
      accountid: ['', vSelectAccountName],
      refNo: ['', vSelectReferenceNo],
      currencyuniquerefid: [1],
      currentcurrencyid: ['', vSelectCurrency],
      exchangerate: [],
      amountinusd: [0],
      creditnotestatusid: [1],
      creditnotestatusdesc: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      branchid: [sessionStorage["BranchID"]],
      invoicedate: [null, vSelectCreditnoteDate],
      salesinvoiceamount: [],
      duedate: [],
      customerid: [],
      customername: [],
      series: [],
      invoiceamount: [0],
      transactionincompleted: [1],
    });
    if (sessionStorage['Environmentenddate'] == "null" ) {
      this._Creditnoteform.get("creditnotedate").setValue(new Date);
      this.maxdate = new Date();
		  }
		  else {
			let date = new Date(sessionStorage['Environmentenddate'])
			this._Creditnoteform.get("creditnotedate").setValue(date);
		  }

    this._creditnotetransactionform = this.fb.group({
      salesinvoiceno: [],
      salesinvoicedate: [],
      customerid: [],
      customername: [],
      amountinusd: [],
      taxamount: [],
      series: [],
      evidenceno: ["sales"]

    });
    this._Creditnotedetailform = this.fb.group({
      creditnotedetailid: [],
      creditnoteid: [],
      invoiceno: [],
      invoicedate: [],
      productid: [],
      quantity: [],
      returnquantity: [],
      unitprice: [],
      totalamount: [],
      creditamount: [],
      reason: [],
      taxPer: [],
      taxAmount: [],
      taxId: [],
      discountPer: [],
      discountAmount: [],
    });
  }


  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();

    this.voucherno = history.state?.voucherno ? history.state?.voucherno : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    console.log("credit Note on Create", this.voucherno);
    this.HotKeyIntegration();
    this._IsProgressSpinner = true;
    this._CreditnoteService.PageOnLoad(this._userid, this.voucherno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("pageload", resultJSON);
      // For DropDown
      this._Currencies = resultJSON.currencies;
      this._status = resultJSON.recordStatus;
      this._Partytypes = resultJSON.partyTypes || [];
      this._accounts = resultJSON.accountledgers;
      this._customers = resultJSON.customers;
      this._IsProgressSpinner = false;
      this._taxlist = resultJSON.taxlist;
      this._Reasons = resultJSON.returnReasonList;
      this._Descriptions = resultJSON.othercharges;
      this._ChargesTemp = resultJSON.othercharges;
      if (this._Partytypes.length)
        this._Creditnoteform.get("partytypeid").setValue(this._Partytypes[0].metasubid);
      // For Exchange Rate
      this._CurrenciesTemp = resultJSON.currencies || [];
      // For Grid
      //Other Charges 
      if (this._action == 'create') {
        var l_othercharge = new Creditnoteothercharge();
        l_othercharge.creditnoteotherchargesdetailid = 0;
        l_othercharge.creditnoteid = 0;
        l_othercharge.otherchargeid = 0;
        l_othercharge.otherchargeamount = 0;
        l_othercharge.createdby = sessionStorage["userid"];
        l_othercharge.modifiedby = sessionStorage["userid"];
        l_othercharge.createdon = new Date();
        l_othercharge.modifiedon = new Date();
        l_othercharge.accountingyear = new Date().getFullYear();
        this.creditnoteothercharges.push(l_othercharge);
      }
      if (this._action == 'view') {

        this._Creditnoteform.disable();

        const updateJSON = resultJSON;

        console.log("view pageload", updateJSON);

        updateJSON.creditnoteheaderview.creditnotedate = new Date(updateJSON.creditnoteheaderview.creditnotedate);
        updateJSON.creditnoteheaderview.invoicedate = new Date(updateJSON.creditnotedetailsviews[0].invoicedate);

        // this._creditnotetransactionform.controls['amountinusd'].setValue(updateJSON.creditnoteheaderview.salesinvoiceamount);
        // this._creditnotetransactionform.controls['salesinvoicedate'].setValue(updateJSON.creditnoteheaderview.invoicedate);
        // this._creditnotetransactionform.controls['customername'].setValue(updateJSON.creditnoteheaderview.customername);

        this._Creditnoteform.setValue(updateJSON.creditnoteheaderview);
        this._TransactionDetails = updateJSON.creditnotedetailsviews;
        this.creditnoteothercharges = updateJSON.creditnoteotherchargesviews || [0]
        this.CalculateotherCharge();
        this.Visiblity(true);
      }
      else {
        this.AddProductToGrid();
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ChangeCurrencies(event): void {
    var exchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].exchangerate;
    this._Creditnoteform.controls['exchangerate'].setValue(exchangerate);
  }
  //Other Charges:
  AddOtherCharges(event) {
    let otherChargesExists = (this.creditnoteothercharges.filter(s => s.otherchargeid > 0).length == this.creditnoteothercharges.length) && this.creditnoteothercharges.some(s => s.otherchargeid > 0);
    if (!otherChargesExists)
      return;
    var l_othercharge = new Creditnoteothercharge();
    l_othercharge.otherchargeid = 0;
    l_othercharge.creditnoteid = 0;
    l_othercharge.creditnoteotherchargesdetailid = 0;
    l_othercharge.otherchargeamount = 0;
    l_othercharge.createdby = sessionStorage["userid"];
    l_othercharge.modifiedby = sessionStorage["userid"];
    l_othercharge.createdon = new Date();
    l_othercharge.modifiedon = new Date();
    this.creditnoteothercharges.push(l_othercharge);
  }
  //Remove Other Charges:
  RemoveOtherCharges(pOthercharges: Creditnoteothercharge) {
    // if (this.salesreturnothercharges.length > 1) {
    var index = this.creditnoteothercharges.indexOf(pOthercharges);
    this.creditnoteothercharges.splice(index, 1)
    this.CalculateotherCharge();
    if (this.creditnoteothercharges.length <= 0) {
      var l_othercharge = new Creditnoteothercharge();
      l_othercharge.otherchargeid = 0;
      l_othercharge.creditnoteid = 0;
      l_othercharge.creditnoteotherchargesdetailid = 0;
      l_othercharge.otherchargeamount = 0;
      l_othercharge.createdby = sessionStorage["userid"];
      l_othercharge.modifiedby = sessionStorage["userid"];
      l_othercharge.createdon = new Date();
      l_othercharge.modifiedon = new Date();
      this.creditnoteothercharges.push(l_othercharge);

    }
  }
  // }
  OnChangeotherCharge(row: any, event) {
    let OtherChargesAmount = row.otherchargeamount;
    // this._salesreturnform.controls['salesreturndeductionamount'].setValue(OtherChargesAmount.toFixed(3));
    this.CalculateotherCharge();
  }
  //Rule18:if invoice no dropdown selected value from sales ordertable then sales orderno column  assigned to referno  in grid based on invoiceno selection
  OnchangeOtherCharge(pOtherCharges: Creditnoteothercharge, event: any) {

    let isAlreadyExists = this.creditnoteothercharges.filter(s => s.otherchargeid == event.value).length > 1;
    if (isAlreadyExists) {
      this._CustomExceptionService.handleWarning("Other Charges Already Exists!");
      var index = this.creditnoteothercharges.indexOf(pOtherCharges);
      this.creditnoteothercharges.splice(index, 1)
      return;
    }
    var otherchargeamount = (this._ChargesTemp || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount;
    console.log(pOtherCharges);
    console.log(otherchargeamount);
    pOtherCharges.otherchargeamount = otherchargeamount;
    this.CalculateotherCharge();
  }
  CalculateotherCharge() {
    this._OtherBalance = 0;
    this.netamount = 0;
    this.otherchargesamount = 0;
    for (var i = 0; i < this.creditnoteothercharges.length; i++) {
      this._OtherBalance += this.creditnoteothercharges[i].otherchargeamount;
    }
    this.otherchargesamount = this._OtherBalance;

    var GrossAmount = 0
    var FinalAmount = 0;
    for (var i = 0; i < this._TransactionDetails.length; i++) {
      GrossAmount += this._TransactionDetails[i].unitprice;
      FinalAmount += this._TransactionDetails[i].creditamount;
    }
    this.grossamount = GrossAmount;
    this.netamount = this.otherchargesamount + FinalAmount;
    this._Creditnoteform.get("invoiceamount").setValue(FinalAmount.toFixed(2));

  }

  CalculateInvoiceAmount()
  {

  }

  onSave() {
    console.log("Submitted");
    console.log(this._action);
    this._submitted = true;

    console.log("test");
    let errMsg = '';
    let isDataImproper = this._TransactionDetails.some(s => s.creditamount <= 0);
    let isQuantityProper = this._TransactionDetails.some(s => s.returnquantity > s.quantity);
    if (isDataImproper) {
      errMsg = "Credit Amount must be greater than zero in all rows";
    }
    if (!this._TransactionDetails.length) {
      errMsg = "Please add Atleast one row in a grid.";
    }
    if (isQuantityProper) {
      errMsg = "Return Quantity must not greater than Invoice Quantity";
    }

    if (this.ShowErrorMsg(errMsg))
      return;

    if (this._Creditnoteform.valid && !this.ValidateOnSave()) {
      console.log("test123");
      this._Creditnotedetailform.get("creditnoteid").setValue(this._Creditnoteform.get("creditnoteid").value);
      this._Creditnote = this._Creditnoteform.getRawValue();
      this._creditnoteOBJ = { "Creditnote": this._Creditnote, "Creditnotedetail": this._TransactionDetails, "Creditnoteothercharge": this.creditnoteothercharges };
      let invoicedate = this._Creditnoteform.get("invoicedate").value;
      let invoiceno = this._Creditnoteform.get("refNo").value;

      // console.log(this._Creditnote);
      // return;

      (this._TransactionDetails || []).forEach(element => {
        element.invoiceno = invoiceno;
        element.invoicedate = invoicedate;
      });

      if (this._action == 'create') {
        console.log("IN");
        this._Creditnote.createdon = new Date();
        this._Creditnote.createdby = sessionStorage["userid"];
        this._Creditnote.branchid = sessionStorage["BranchID"];
        this._Creditnote.accountingyear = new Date().getFullYear();
        //this.onEd();
        this.Visiblity(true);
        this._IsProgressSpinner = true;
        this._CreditnoteService.create(this._creditnoteOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this.Visiblity(false);
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this.Visiblity(false); this._IsProgressSpinner = false; });
      }
    }

  }

  ValidateOnSave() {
    let isGridInValid = (this._TransactionDetails.some(s => s.productid == null || s.productid <= 0) && this._TransactionDetails.length == 1);
    let isProductInValid = this._TransactionDetails.some(s => s.productid == null || s.productid <= 0);
    let isInvoiceQtyInValid = this._TransactionDetails.some(s => s.quantity == null || s.quantity <= 0);
    let isReturnQtyInValid = this._TransactionDetails.some(s => s.returnquantity == null || s.returnquantity <= 0);
    let isReturnReasonInValid = this._TransactionDetails.some(s => s.returnreason == null || s.returnreason == "");
    let isProductAmountInValid = this._TransactionDetails.some(s => s.productamount == null || s.productamount <= 0);
    let isTotalAmountInValid = this._TransactionDetails.some(s => s.totalamount == null || s.totalamount <= 0);

    let totalProductAmount = 0;
    (this._TransactionDetails || []).forEach(element => {
      totalProductAmount += element.productamount;
    });
    var invoiceamount = parseFloat(this._Creditnoteform.get("invoiceamount").value || 0);

    let isError = false;

    if (isGridInValid) {
      isError = true;
      this._CustomExceptionService.handleWarning("Add atleast one product in the grid.");
    } else {
      if (isProductInValid) {
        isError = true;
        this._CustomExceptionService.handleWarning("Enter Product name in the grid.");
      }
      if (isInvoiceQtyInValid) {
        isError = true;
        this._CustomExceptionService.handleWarning("Enter Invoice Qty in the grid.");
      }
      if (isReturnQtyInValid) {
        isError = true;
        this._CustomExceptionService.handleWarning("Enter Return Qty in the grid.");
      }
      if (isReturnReasonInValid) {
        isError = true;
        this._CustomExceptionService.handleWarning("Enter Return Reason in the grid.");
      }
      if (isProductAmountInValid) {
        isError = true;
        this._CustomExceptionService.handleWarning("Product Amount should be calculated.");
      }
      if (isTotalAmountInValid) {
        isError = true;
        this._CustomExceptionService.handleWarning("Credit Amount should be calculated.");
      }
      if (invoiceamount > 0 && (totalProductAmount != invoiceamount)) {
        isError = true;
        this._CustomExceptionService.handleWarning("Total grid product amount should be equal to invoice amount.");
      }
    }
    return isError;
  }

  onRowSelect(event) {
    console.log("Selected Suppliers");
    console.log(this.selectedrows);
    event.data.enableQuantity = true;
    event.data.enableReason = true;
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
    event.data.enableQuantity = false;
    event.data.enableReason = false;
    event.data.returnquantity = 0;
    event.data.reason = '';
  }
  showBasicDialog() {
    this.displayBasic = true;
  }
  // onRowSelectPopup(event: any) {

  //   var row = event.data;
  //   this._Creditnoteform.controls['refNo'].setValue(row.salesinvoiceno);
  //   this._creditnotetransactionform.controls['salesinvoicedate'].setValue(row.salesinvoicedate);
  //   this._creditnotetransactionform.controls['customername'].setValue(row.customername);
  //   this._creditnotetransactionform.controls['amountinusd'].setValue(row.amountinusd);
  //   this._IsProgressSpinner = true;
  //   this._CreditnoteService.getTransactionDetails(row.salesinvoiceno).subscribe((result) => {
  //     const resultJSON = JSON.parse(JSON.stringify(result));
  //     console.log("popup and grid view", resultJSON);
  //     this._TransactionDetails = resultJSON.creditnotegettransactiondetailsviews;
  //     this._IsProgressSpinner = false;
  //   },
  //     error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  //   this.displayBasic = false;
  // }
  onRowUnSelectPopup(event: any) {
    this._Creditnoteform.controls['refNo'].setValue(null);
    this._creditnotetransactionform.controls['salesinvoicedate'].setValue(null);
    this._creditnotetransactionform.controls['customername'].setValue(null);
    this._creditnotetransactionform.controls['amountinusd'].setValue(null);
    this._TransactionDetails = [];
    this.displayBasic = false;
  }
  // onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._creditnoteOBJ;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }
  reset(event) {
    this._Creditnoteform.reset();
    this._TransactionDetails = [];
    this.InitializeForm();
    this._submitted = false;
    this._CreditReferences = [];
    this.selectedrows = [];
    if (this._Partytypes.length)
      this._Creditnoteform.get("partytypeid").setValue(this._Partytypes[0].metasubid);
  }
  goBack(event) {
    console.log("goBack");
    this._router.navigate(['/vDirectCreditnote']);
  }
  calcuateCreditAmount(row: Creditnotedetail) {
    row.creditamount = 0;
    row.totalamount = 0;
    let isError = false;
    let invoiceqty = parseInt(row.quantity + "" || "0");
    let returnqty = parseInt(row.returnquantity + "" || "0");

    if (row.returnquantity > row.quantity) {
      isError = true;
      this.ShowErrorMsg("Return Quantity should less than invoice quantity.")
    }

    if (isError)
      return;


    let invoiceamount = row.productamount;
    let taxPer = 0;
    let tax = this._taxlist.find(f => f.taxid == row.taxId);
    if (tax) {
      taxPer = tax.taxpercentage;
    }
    row.taxAmount = 0;
    row.taxPer = 0;
    //row.productamount = 0;

    if (!invoiceqty || !invoiceamount)
      return;

    let singleqtyAmount = (invoiceamount / invoiceqty);
    row.unitprice = singleqtyAmount;

    if (!returnqty)
      return;

    let returnqtyAmount = (singleqtyAmount * returnqty);
    let taxAmount = (returnqtyAmount * (taxPer / 100));

    row.taxAmount = taxAmount;
    row.taxPer = taxPer;

    row.totalamount = (returnqtyAmount + taxAmount)
    row.creditamount = row.totalamount;
    //this.CalculateotherCharge();
    this.CalculateotherCharge();
  }

  // GetCustomerBasedEvidenceNo($event) {
  //   let userid = sessionStorage["userid"];
  //   this._IsProgressSpinner = true;
  //   let account = this._accounts.find(f => f.ledgerid == $event.value)
  //   let customer = this._customers.find(f => f.customerid == account.partycode);

  //   if (customer) {
  //     this._Creditnoteform.get("currentcurrencyid").setValue(customer.currencyid);
  //   }

  //   this._CreditnoteService.GetCustomerBasedEvidenceNo($event.value, userid).subscribe((result) => {
  //     const resultJSON = JSON.parse(JSON.stringify(result));
  //     this._CreditReferences = resultJSON.creditnotegettransactionviews;
  //     this._IsProgressSpinner = false;
  //   },
  //     error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  // }

  Visiblity(ishide: boolean) {
    this._IsSaveHidden = ishide;
    this._IsClearHidden = ishide;
  }

  ShowErrorMsg(errMsg) {
    if (!errMsg)
      return false;

    this.messageService.add({ severity: 'warning', summary: 'Error Message', sticky: true, detail: errMsg });
    return true;
  }

  AddProductToGrid() {
    let product: Creditnotedetail = new Creditnotedetail();
    this._TransactionDetails.push(product);
  }

  RemoveProductFromGrid(index: number) {
    if (this._TransactionDetails.length <= 1)
      return;

    this._TransactionDetails.splice(index, 1);
  }

  OnProductSelect(product: Productoverallview, ngModel: Creditnotedetail) {
    let isProductExists = this._TransactionDetails.some(s => s.productid == product.productid);
    if (!isProductExists) {
      ngModel.productid = product.productid;
      ngModel.productname = product.productname;
    } else {
      ngModel.productid = 0;
      ngModel.productname = '';
      this._CustomExceptionService.handleWarning("Product already exists in the grid.");
    }
  }

  OnProductClear(ngModel: Productoverallview) {
    ngModel.productid = 0;
  }

  filterProducts(event: { query: string; }, ngModel) {
    if (event.query == "" || event.query == undefined) {
      return;
    }
    else {
      //this._IsProgressSpinner = true;
      // if(event.query){
      this._CreditnoteService.GetProduct(event.query, this._branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this._ProductNames = <Productoverallview[]>resultJSON.productsearchlist || [];

        let filtered: any[] = [];
        let query = (<string>event.query).toLowerCase();
        ngModel.filteredProducts = this._ProductNames;
        //this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

    }
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}
