import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class ImportentryService {
  public _Action: IAction;
  public _httpOptions;
  public _ImportentryService = environment.ImportentryService;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  // PageOnload(importid: number): Observable<any> {
  //   var Query = this._ImportentryService + '/PageInitialize/' + importid;
  //  
  //   return this.http.get<{ data: any }>(Query);
  // }
  PageOnload(importentryid: string, userid: number): Observable<any> {
    const headers = this.header;
    var Query = this._ImportentryService + '/' + importentryid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  create(importentry: object) {
    const headers = this.header;
    var Query = this._ImportentryService;

    return this.http.post<{ data: any }>(Query, importentry, { headers });

  }
  FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
    const headers = this.header;
    var Query = this._ImportentryService + '/FetchAll' + '/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;
    // + '/FetchAll'

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }
  edit(importentry: object) {
    const headers = this.header;
    console.log("Edit");
    var Query = this._ImportentryService;

    return this.http.put<{ data: any }>(Query, importentry, { headers });
  }
  getAgentAccountDetails(agentid: number, currencyid: number, containerno: string) {
    const headers = this.header;

    var Query = this._ImportentryService + '/GetAgentDetails/' + agentid + '/' + currencyid + '/' + containerno;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetContainerNos(supplierid: number) {
    const headers = this.header;

    var Query = this._ImportentryService + '/GetContainerNos/' + supplierid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetContainerDetails(containertrackingid: number) {
    const headers = this.header;

    var Query = this._ImportentryService + '/GetContainerDetails/' + containertrackingid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  getSupplierDetails(countryid: number, userid: number) {
    const headers = this.header;
    var Query = this._ImportentryService + '/Getsupplier/' + countryid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  Cancel(p_importentryno: string, userid: number): Observable<any> {
    const headers = this.header;
    var Query = this._ImportentryService + '/Cancel/' + p_importentryno + '/' + userid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  SendToApproval(workflowid: number, userid: number, branchid: number, productid: string) {
    const headers = this.header;

    var Query = this._ImportentryService + '/SendToApproval' + '/' + workflowid + '/' + userid + '/' + branchid + '/' + productid;

    return this.http.post<{ data: any }>(Query, '', { headers });
  }
}
