<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Workflow</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="filter-container workflow-filter">
                            View By :
                            <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_Level" optionLabel="metasubdescription"
                                optionValue="metasubcode" placeholder="Select a Level">
                            </p-dropdown> -->
                            <p-dropdown [autoDisplayFirst]="false" [options]="_Duration" optionLabel="name"
                                optionValue="id" placeholder="Duration" class="year" (onChange)="ChangeDate($event)"
                                [(ngModel)]="id.id">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <table class="normal-table" [hidden]="_isDisable">
                <tr>
                    <td style="width: 20%;">
                        <span class="p-float-label">
                            <p-multiSelect [options]="_branchlist" optionLabel="branchname" optionValue="branchid"
                                [(ngModel)]="branchid" [ngModelOptions]="{standalone: true}">
                                <ng-template let-account pTemplate="item">
                                    <div
                                        [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                        {{account.branchname}}</div>
                                </ng-template>
                            </p-multiSelect>
                            <label for="producttypeid">Branch Name </label>
                        </span>
                    </td>
                    <td>
                        <button pButton pRipple label="Search" icon="pi pi-search" (click)="Search()"
                            class="p-button-sm p-button-success"></button>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </table>
            <div class="form-container scroll-y">
                <div class="row workflow-widget">
                    <div class="col-md-3">
                        <div class="workflow-summary color01">
                            <div class="row">
                                <div class="col-md-4">
                                    <a href="javascript:void(0);" class="workflow-links"
                                        (click)="ChangePurchaseapproved()">
                                        <h4>{{_purchaseapprovedcount}} </h4>
                                        <p>Approved</p>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <a href="javascript:void(0);" class="workflow-links"
                                        (click)="ChangePurchasepending()">
                                        <h4>{{_purchasependingcount}}</h4>
                                        <p>Pending</p>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <a href="javascript:void(0);" class="workflow-links"
                                        (click)="ChangePurchaserejected()">
                                        <h4>{{_purchaserejectedcount}}</h4>
                                        <p>Rejected</p>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <div class="workflow-title">
                                        <i class="las la-shopping-cart"></i>
                                        <h2>Purchase</h2>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <a href="javascript:void(0);" class="workflow-links select"
                                        (click)="ChangePurchaseoverall()">
                                        <h4>{{_purchaseoverallcount}}</h4>
                                        <p>Overall</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="workflow-summary color02">
                            <div class="row">
                                <div class="col-md-4">
                                    <a href="javascript:void(0);" class="workflow-links"
                                        (click)="ChangeSalesApproved()">
                                        <h4>{{_salesapprovedcount}}</h4>
                                        <p>Approved</p>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <a href="javascript:void(0);" class="workflow-links" (click)="ChangeSalesPending()">
                                        <h4>{{_salespendingcount}}</h4>
                                        <p>Pending</p>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <a href="javascript:void(0);" class="workflow-links"
                                        (click)="ChangeSalesRejected()">
                                        <h4>{{_salesrejectedcount}}</h4>
                                        <p>Rejected</p>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <div class="workflow-title">
                                        <i class="las la-shopping-basket"></i>
                                        <h2>Sales</h2>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <a href="javascript:void(0);" class="workflow-links" (click)="ChangeSalesOverAll()">
                                        <h4>{{_salesoverallcount}}</h4>
                                        <p>Overall</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="workflow-summary color03">
                            <div class="row">
                                <div class="col-md-4">
                                    <a href="javascript:void(0);" class="workflow-links"
                                        (click)="ChangeAccountsApproved()">
                                        <h4>{{_accountsapprovedcount}}</h4>
                                        <p>Approved</p>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <a href="javascript:void(0);" class="workflow-links"
                                        (click)="ChangeAccountsPending()">
                                        <h4>{{_accountspendingcount}}</h4>
                                        <p>Pending</p>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <a href="javascript:void(0);" class="workflow-links"
                                        (click)="ChangeAccountsRejected()">
                                        <h4>{{_accountsrejectedcount}}</h4>
                                        <p>Rejected</p>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <div class="workflow-title">
                                        <i class="las la-coins"></i>
                                        <h2>Accounts</h2>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <a href="javascript:void(0);" class="workflow-links"
                                        (click)="ChangeAccountsOverAll()">
                                        <h4>{{_accountsoverallcount}}</h4>
                                        <p>Overall</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="workflow-summary color04">
                            <div class="row">
                                <div class="col-md-4">
                                    <a href="javascript:void(0);" class="workflow-links"
                                        (click)="ChangeOthersApproved()">
                                        <h4>{{_othersapprovedcount}}</h4>
                                        <p>Approved</p>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <a href="javascript:void(0);" class="workflow-links" (click)="ChangeOtherPending()">
                                        <h4>{{_otherspendingcount}}</h4>
                                        <p>Pending</p>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <a href="javascript:void(0);" class="workflow-links"
                                        (click)="ChangeOthersRejected()">
                                        <h4>{{_othersrejectedcount}}</h4>
                                        <p>Rejected</p>
                                    </a>
                                </div>
                                <div class="col-md-4">
                                    <div class="workflow-title">
                                        <i class="las la-cog"></i>
                                        <h2>Others</h2>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <a href="javascript:void(0);" class="workflow-links"
                                        (click)="ChangeOthersOverall()">
                                        <h4>{{_othersoverallcount}}</h4>
                                        <p>Overall</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="las la-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                (click)="ExportToPdf()" class="p-button-warning p-mr-2" pTooltip="PDF"
                                tooltipPosition="bottom"></button>
                        </ng-template>
                    </p-toolbar>

                    <p-table #dt [value]="_Approvalworkflowdetails" [rows]="20" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['branch','documenttype','documentno','stageid','requestdate','requestby','approvername','remarks','documentstatus']"
                        [rowHover]="true" dataKey="workflowid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:120px;" class="text-center">Action</th>
                                <th pSortableColumn="branch">Branch <p-sortIcon field="branch"></p-sortIcon>
                                </th>
                                <th pSortableColumn="documenttype">Document Type <p-sortIcon field="documenttype">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="documentno">Document No <p-sortIcon field="documentno">
                                    </p-sortIcon>
                                </th>
                                <th [hidden]="true" pSortableColumn="documentno">Document ID <p-sortIcon
                                        field="documentno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="stageid">Stages<p-sortIcon field="stageid"></p-sortIcon>
                                </th>

                                <th pSortableColumn="requestdate">Requested Date<p-sortIcon field="requestdate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="requestby">Requested By <p-sortIcon field="requestby"></p-sortIcon>
                                </th>
                                <th pSortableColumn="approvername">Approver Name <p-sortIcon field="approvername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="remarks">Remarks <p-sortIcon field="remarks"></p-sortIcon>
                                </th>
                                <th pSortableColumn="documentstatus">Document Status<p-sortIcon field="documentstatus">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Approvalworkflowdetail>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-check" title="Approve"
                                        class="p-button-info p-mr-2"
                                        [disabled]="_Approvalworkflowdetail.documentstatus == 'Approved' || _Approvalworkflowdetail.documentstatus == 'Rejected'||_userid==1"
                                        (click)="showBasicDialog(_Approvalworkflowdetail)"></button>
                                    <button pButton pRipple icon="las la-sitemap" title="View Workflow"
                                        (click)="ApprovalWorkFlowStage(_Approvalworkflowdetail)" [disabled]="_userid==1"
                                        class="p-button p-mr-2"></button>
                                    <button pButton pRipple icon="las la-eye" title="View Document"
                                        class="p-button-success p-mr-2" [disabled]="_userid==1"
                                        (click)="ViewApproval(_Approvalworkflowdetail)"></button>
                                    <button pButton pRipple icon="las la-times" title="approval rejected"
                                        class="p-button p-button-danger p-mr-2"
                                        [disabled]="_Approvalworkflowdetail.documentstatus == 'Rejected' || _Approvalworkflowdetail.documentstatus == 'Approved'||_userid==1"
                                        (click)="showBasicDialog1(_Approvalworkflowdetail)"></button>
                                </td>
                                <td>
                                    {{_Approvalworkflowdetail.branch}}
                                </td>
                                <td>
                                    {{_Approvalworkflowdetail.documenttype}}
                                </td>
                                <td>
                                    {{_Approvalworkflowdetail.documentno}}
                                </td>
                                <td [hidden]="true">
                                    {{_Approvalworkflowdetail.documentdetailid}}
                                </td>
                                <td>
                                    {{_Approvalworkflowdetail.stageid}}
                                </td>
                                <td>
                                    {{_Approvalworkflowdetail.requestdate | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_Approvalworkflowdetail.requestby}}
                                </td>
                                <td>
                                    {{_Approvalworkflowdetail.approvername}}
                                </td>
                                <td>
                                    {{_Approvalworkflowdetail.remarks}}
                                </td>
                                <td>
                                    {{_Approvalworkflowdetail.documentstatus}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <tr>
                        <td>
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="workflow-container">
    <div class="page-title">
        <h3>View Workflow</h3>
        <a href="javascript:void(0);" class="close" pTooltip="Close" tooltipPosition="top"><i
                class="las la-times-circle"></i></a>
    </div>
    <div class="workflow-panel scroll-y">
        <div id="idworkflowview" class="workflow-animation" data-level-completed="0" data-level-rejected="0">
            <div class="workflow-desc" *ngFor="let wf of _workflow">
                <div class="level-title">{{wf.designation}}</div>
                <div class="workflow-details level01">
                    <div class="linear-circle"></div>
                    <div class="linear-chart">
                        <div class="linear-value"></div>
                    </div>
                    <table class="workflow-table">
                        <tr>
                            <td><i class="la la-hourglass-1"></i></td>
                            <td><strong>Status :</strong>{{wf.logstatusname}}</td>
                        </tr>
                        <tr>
                            <td><i class="la la-user"></i></td>
                            <td><strong>Approved By :</strong> {{ wf.approvername}}</td>
                        </tr>
                        <tr>
                            <td><i class="la la-suitcase"></i></td>
                            <td><strong>Designation :</strong> {{wf.designation}}</td>
                        </tr>
                        <tr>
                            <td><i class="la la-clock"></i></td>
                            <td><strong>Duration :</strong> {{wf.date | date:'dd/MM/yyyy'}}</td>
                        </tr>
                        <tr>
                            <td><i class="la la-comment"></i></td>
                            <td><strong>Comments :</strong> {{wf.remarks}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <!-- <div class="workflow-desc">
                <div class="level-title">Procurement Manager Approval</div>
                <div class="workflow-details level02">
                    <div class="linear-circle"></div>
                    <div class="linear-chart">
                        <div class="linear-value"></div>
                    </div>
                    <table class="workflow-table">
                        <tr>
                            <td><i class="la la-hourglass-1"></i></td>
                            <td><strong>Status :</strong> Approved</td>
                        </tr>
                        <tr>
                            <td><i class="la la-user"></i></td>
                            <td><strong>Approved By :</strong> Prabha</td>
                        </tr>
                        <tr>
                            <td><i class="la la-suitcase"></i></td>
                            <td><strong>Designation :</strong> Managing Director</td>
                        </tr>
                        <tr>
                            <td><i class="la la-clock"></i></td>
                            <td><strong>Duration :</strong> Sep 25, 2020 12:11 PM - Sep 25, 2020 12:17 PM</td>
                        </tr>
                        <tr>
                            <td><i class="la la-comment"></i></td>
                            <td><strong>Comments :</strong> Comments Will Comes Here</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="workflow-desc">
                <div class="level-title">General Manager Approval</div>
                <div class="workflow-details level03">
                    <div class="linear-circle"></div>
                    <div class="linear-chart">
                        <div class="linear-value"></div>
                    </div>
                    <table class="workflow-table">
                        <tr>
                            <td><i class="la la-hourglass-1"></i></td>
                            <td><strong>Status :</strong> Approved</td>
                        </tr>
                        <tr>
                            <td><i class="la la-user"></i></td>
                            <td><strong>Approved By :</strong> Prabha</td>
                        </tr>
                        <tr>
                            <td><i class="la la-suitcase"></i></td>
                            <td><strong>Designation :</strong> Managing Director</td>
                        </tr>
                        <tr>
                            <td><i class="la la-clock"></i></td>
                            <td><strong>Duration :</strong> Sep 25, 2020 12:11 PM - Sep 25, 2020 12:17 PM</td>
                        </tr>
                        <tr>
                            <td><i class="la la-comment"></i></td>
                            <td><strong>Comments :</strong> Comments Will Comes Here</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="workflow-desc">
                <div class="level-title">MD Approval</div>
                <div class="workflow-details level04">
                    <div class="linear-circle"></div>
                    <div class="linear-chart">
                        <div class="linear-value"></div>
                    </div>
                    <table class="workflow-table">
                        <tr>
                            <td><i class="la la-hourglass-1"></i></td>
                            <td><strong>Status :</strong> Approved</td>
                        </tr>
                        <tr>
                            <td><i class="la la-user"></i></td>
                            <td><strong>Approved By :</strong> Prabha</td>
                        </tr>
                        <tr>
                            <td><i class="la la-suitcase"></i></td>
                            <td><strong>Designation :</strong> Managing Director</td>
                        </tr>
                        <tr>
                            <td><i class="la la-clock"></i></td>
                            <td><strong>Duration :</strong> Sep 25, 2020 12:11 PM - Sep 25, 2020 12:17 PM</td>
                        </tr>
                        <tr>
                            <td><i class="la la-comment"></i></td>
                            <td><strong>Comments :</strong> Comments Will Comes Here</td>
                        </tr>
                    </table>
                </div>-->
            <p-dialog header="WorkFlow" [(visible)]="ManageDialog" [style]="{width: '350px',height:'300px'}"
                [maximizable]="false" [modal]="true">
                <ng-template pTemplate="content">
                    <form [formGroup]="workflowform">
                        <table class="normal-table">
                            <tr>
                                <td>
                                    <span class="p-float-label">
                                        <textarea rows="7" cols="35" formControlName="remarks"
                                            pInputTextarea></textarea>
                                        <label for="remarks">Remarks</label>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </form>
                </ng-template>
                <ng-template pTemplate="footer" class="text-center">
                    <button pButton pRipple label="Approve" icon="las la-check" class="p-button-text" title="Approve"
                        (click)="Approve()"></button>
                    <button pButton pRipple label="Cancel" icon="pi pi-times" (click)="Cancel()" class="p-button-text"
                        title="Cancel"></button>
                </ng-template>
            </p-dialog>
            <p-dialog header="WorkFlow" [(visible)]="ManageDialog1" [style]="{width: '350px',height:'300px'}"
                [maximizable]="false" [modal]="true">
                <ng-template pTemplate="content">
                    <form [formGroup]="workflowform">
                        <table class="normal-table">
                            <tr>
                                <td>
                                    <span class="p-float-label">
                                        <textarea rows="7" cols="35" formControlName="remarks"
                                            pInputTextarea></textarea>
                                        <label for="remarks">Remarks</label>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </form>
                </ng-template>
                <ng-template pTemplate="footer" class="text-center">
                    <button pButton pRipple label="Reject" icon="las la-ban" class="p-button-text" title="Reject"
                        (click)="Reject()"></button>
                    <button pButton pRipple label="Cancel" icon="pi pi-times" (click)="Cancel()" class="p-button-text"
                        title="Cancel"></button>
                </ng-template>
            </p-dialog>
        </div>
        <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>