import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class ApprovalconfigurationcorporateService {
  public _Action: IAction;
  public _ApprovalconfigurationcorporateService = environment.ApprovalconfigurationcorporateService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(userid: number): Observable<any> { 
    const headers = this.header;
    var Query = this._ApprovalconfigurationcorporateService + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  create(ApprovalCorporate: object[]) { 
    const headers = this.header;
    console.log("Create");
    var Query = this._ApprovalconfigurationcorporateService;
    return this.http.post<{ data: any }>(Query, ApprovalCorporate, { headers });
  }
  edit(ApprovalCorporate: object) { 
    const headers = this.header;
    console.log("Update");
    var Query = this._ApprovalconfigurationcorporateService;

    return this.http.put<{ data: any }>(Query, ApprovalCorporate, { headers });
  }
  Cancel(workflowid: number, stageid: number, modifiedby: number) { 
    const headers = this.header;
    var Query = this._ApprovalconfigurationcorporateService + '/Cancel' + '/' + workflowid + '/' + stageid + '/' + modifiedby;

    return this.http.get<{ data: any }>(Query, { headers });
  }
}
