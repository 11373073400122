import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppConfig } from 'src/app/core/domain/appconfig';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { OutstandingPayableDashboardService } from 'src/app/core/Services/dashboard/outstanding-payable-dashboard.service';
@Component({
	selector: 'app-outstanding-payable-dashboard',
	templateUrl: './outstanding-payable-dashboard.component.html',
	styleUrls: ['./outstanding-payable-dashboard.component.css']
})
export class OutstandingPayableDashboardComponent implements OnInit {
	datePipe = new DatePipe('en-US');
	_IsProgressSpinner: boolean = false;
	_Branches: any[] = [];
	_outstandingpayabledashboardform: FormGroup;
	documentStyle: any;
	basicOptions: any;
	_Isbarsd: boolean = true;
	_Islinesd: boolean = true;
	_Ispiesd: boolean = true;
	_Isdoughnutsd: boolean = true;
	_Isbarsd1: boolean = true;
	_Islinesd1: boolean = true;
	_Ispiesd1: boolean = true;
	_Isdoughnutsd1: boolean = true;
	_Isbarpd: boolean = true;
	_Islinepd: boolean = true;
	_Ispiepd: boolean = true;
	_Isdoughnutpd: boolean = true;
	_IsLineBranch: boolean = false;
	_IsbarBranch: boolean = true;
	_IspieBranch: boolean = false;
	_IsdoughnutBranch: boolean = false;
	config: AppConfig;
	trimlableDataProduct: any = [];
	noOutstandingPayableBranchWise: boolean = false;
	_selectedTopBranch: any[] = [];
	_isDoubleBar: boolean = true;
	topSellingBranchWiseLabel: any;
	topSellingBranchWiseValue: any;
	bIds: any[] = [];
	OutstandingPayableDashboardService: any;
	noOutstandingPayableBranchwise: boolean;
	tempOutstandingPayableBranchwise: {};
	outstandingpayableBranchWise: any;
	topSellingProductBranchWiseValues: any[];
	constructor(
		private utility: CommonUtilities,
		private fb: FormBuilder,
		private outstandingPayableDashboardService: OutstandingPayableDashboardService,
	) { }
	ngOnInit() {
		this.utility.pageLoadScript();
		this.documentStyle = getComputedStyle(document.documentElement);
		this._outstandingpayabledashboardform = this.fb.group({
			branchid: [parseInt(sessionStorage['currentbranchid'])],
		});
		this.outstandingPayableDashboardService.PageOnload().subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			this._Branches = resultJSON.branches;
			this.bIds = [26, 29, 1, 28, 35];
			this._selectedTopBranch = this.bIds;
		});
		this.initializeCharts();
	}
	initializeCharts() {
		this.OutstandingPayableBranchwise();
	}
	chartChangedBranch(charttype1) {
		if (charttype1 == 'bar') {
			this._IsLineBranch = false;
			this._IsbarBranch = true;
			this._IspieBranch = false;
			this._IsdoughnutBranch = false;
		}
		if (charttype1 == 'line') {
			this._IsLineBranch = true;
			this._IsbarBranch = false;
			this._IspieBranch = false;
			this._IsdoughnutBranch = false;
		}
		if (charttype1 == 'pie') {
			this._IsLineBranch = false;
			this._IsbarBranch = false;
			this._IspieBranch = true;
			this._IsdoughnutBranch = false;
		}
		if (charttype1 == 'doughnut') {
			this._IsLineBranch = false;
			this._IsbarBranch = false;
			this._IspieBranch = false;
			this._IsdoughnutBranch = true;
		}
	}
	OutstandingPayableBranchwise() {
		this._IsProgressSpinner = true;
		this.fetchOutstandingPayableBranchwise();
	}
	filterBranch(event) {
		this._IsProgressSpinner = true;
		if (this._selectedTopBranch.length > 5) {
			this._IsProgressSpinner = false;
			return;
		} else if (this._selectedTopBranch.length <= 5 && this._selectedTopBranch.length > 0) {
			this.fetchOutstandingPayableBranchwise();
			this._IsProgressSpinner = false;
		}
		if (this._selectedTopBranch.length == 0) {
			this._IsProgressSpinner = false;
		}
		return
	}
	fetchOutstandingPayableBranchwise() {
		this._IsProgressSpinner = true;
		this.outstandingPayableDashboardService.OutstandingPayableBranchwise(this._selectedTopBranch).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this.trimlableDataProduct = [];
			this.outstandingpayableBranchWise = [];
			this.topSellingProductBranchWiseValues = [];
			if (resultJSON.outstandingpayableBranchWise != null && resultJSON.outstandingpayableBranchWise) {
				this.noOutstandingPayableBranchwise = false;
				if (resultJSON.outstandingpayableBranchWise.length > 0) {
					var stockFreshLabel = resultJSON.outstandingpayableBranchWise;
					for (let i = 0; i < resultJSON.outstandingpayableBranchWise.length; i++) {
						this.trimlableDataProduct[i] = (stockFreshLabel[i].BRANCHNAME).slice(0, 15) + '...';
						this.topSellingProductBranchWiseValues[i] = stockFreshLabel[i].AMOUNT;
						this.outstandingpayableBranchWise = {
							labels: this.trimlableDataProduct,
							datasets: [
								{
									label: 'Branch Wise Outstanding',
									backgroundColor: [
										'#42A5F5',
										"#FF6384",
										"#3655eb",
										"#e757eb",
										"#b64c11",
										"#FFCE56"
									],
									data: this.topSellingProductBranchWiseValues
								}
							],
						};
					}
				} else { this.noOutstandingPayableBranchWise = true; }
			} else { this.noOutstandingPayableBranchWise = true; }
			this._IsProgressSpinner = false;
		});
	}
}