import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Customer } from 'src/app/core/Models/Customer';
import { RptrewardpointtransactionService } from 'src/app/core/Services/reports/Sales/Rptrewardpointtransaction/rptrewardpointtransaction.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-rptrewardpointstransaction',
  templateUrl: './rptrewardpointstransaction.component.html',
  styleUrls: ['./rptrewardpointstransaction.component.css']
})
export class RptrewardpointstransactionComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptrewardpointtransactionform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _userid: number;
  datepipe: any;
  _productlist: Productoverallview[] = [];
  _src: SafeResourceUrl;
  filteredProducts: any[];
  _customerlist: Customer[] = [];
  selectedProductDetail: any;
  _Action: IAction;
  mindate: Date;
  maxdate: Date;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptrewardpointtransactionService: RptrewardpointtransactionService,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer,
    private _AccessRightsService: AccessRightsService,
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(117);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptrewardpointtransactionform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      productid: [0],
      customerid: [0]
    })
    this._branchlist = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptrewardpointtransactionform.get("fromdate").setValue(new Date);
      this._rptrewardpointtransactionform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptrewardpointtransactionform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptrewardpointtransactionform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._IsProgressSpinner = true;
    this._RptrewardpointtransactionService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._productlist = resultJSON.productList;
      this._customerlist = resultJSON.customers;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._rptrewardpointtransactionform.reset();
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  GenerateReport(event) {
    var _fromdate = this._rptrewardpointtransactionform.get("fromdate").value;
    var _todate = this._rptrewardpointtransactionform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    var customerid = this._rptrewardpointtransactionform.get("customerid").value == null ? 0 : this._rptrewardpointtransactionform.get("customerid").value;
    var branchid = this._rptrewardpointtransactionform.get("branchid").value || parseInt(sessionStorage["currentbranchid"]);
    if (branchid == 0) {
      branchid = sessionStorage["currentbranchid"]
    }
    var mediaType = 'application/pdf';
    if (this.selectedProductDetail == undefined || null) {
      let productid = 0
      this._IsProgressSpinner = true;
      this._RptrewardpointtransactionService.Print(fromdate, todate, productid, branchid, customerid).subscribe((result) => {
        var blob = new Blob([result], { type: mediaType });
        var url = window.URL.createObjectURL(blob);
        this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    } else {
      let productid = this.selectedProductDetail.productid
      this._IsProgressSpinner = true;
      this._RptrewardpointtransactionService.Print(fromdate, todate, productid, branchid, customerid).subscribe((result) => {
        var blob = new Blob([result], { type: mediaType });
        var url = window.URL.createObjectURL(blob);
        this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  filterProducts(event) {
    let Product = event.query || ""
    let obj = { "product": Product || "", "branchid": sessionStorage["currentbranchid"] };
    this._RptrewardpointtransactionService.GetProduct(obj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this.filteredProducts = resultJSON.productList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}