<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Reward Point Transaction Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptrewardpointtransactionform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="fromdate">From Date <span class="hlt-txt">*</span> </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="todate">To Date <span class="hlt-txt">*</span> </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-autoComplete formControlName="productid" [suggestions]="filteredProducts"
                                        (completeMethod)="filterProducts($event)" [(ngModel)]="selectedProductDetail"
                                        field="productname" [virtualScroll]="true" itemSize="30">
                                        <ng-template let-product pTemplate="item">
                                            <div>{{product.productname}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <label for="productid">Product Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_branchlist"
                                        optionValue="branchid" optionLabel="branchname" formControlName="branchid"
                                        [filter]="true" filterBy="producttypename"
                                        [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-dropdown>
                                    <label for="branchname">Branch Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_customerlist"
                                        optionValue="customerid" optionLabel="customername" formControlName="customerid"
                                        [filter]="true" filterBy="customername"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-dropdown>
                                    <label for="customername">Customer Name</label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <iframe #iframe height="500" width="100%" [src]="_src"></iframe>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>