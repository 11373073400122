<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Stock Delivery</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate" [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                            (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                            pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_Stockdeliveryviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['deliveryno','createdbyname','deliverydate','grnno','referenceno','deliveryto','deliverytypename','invoicetypename','vehicleno','drivername','statusname']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="stocktransferno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:150px;text-align: center;">Action</th>
                                <th [hidden]="true"></th>
                                <th pSortableColumn="deliveryno">Delivery No
                                    <p-sortIcon field="deliveryno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="referenceno">Reference No
                                    <p-sortIcon field="referenceno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="grnno">GRN No
                                    <p-sortIcon field="grnno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="customerorbranchname">Customer / Branch Name
                                    <p-sortIcon field="customerorbranchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="deliverydate">Delivery Date
                                    <p-sortIcon field="deliverydate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="deliveryto">Delivery To
                                    <p-sortIcon field="deliveryto"></p-sortIcon>
                                </th>
                                <th pSortableColumn="deliverytypename">Delivery Type
                                    <p-sortIcon field="deliverytypename"></p-sortIcon>
                                </th>
                                <th pSortableColumn="invoicetypename">Invoice Type
                                    <p-sortIcon field="invoicetypename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="vehicleno">Vehicle No.
                                    <p-sortIcon field="vehicleno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="drivername">Driver Name
                                    <p-sortIcon field="drivername"></p-sortIcon>
                                </th>
                                <th pSortableColumn="Createdbyname">Created By
                                    <p-sortIcon field="Createdbyname">
                                    </p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="Status">Status<p-sortIcon field="Status"></p-sortIcon>
                                </th> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Stockdeliveryview let-ri="rowIndex">
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_Stockdeliveryview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" [disabled]=" !_Action._Update ||  _Stockdeliveryview.deliveryno == _Stockdeliveryview.purchaseinwarddeliveryno || _Stockdeliveryview.invoicetype == 'MSC00210'||_Stockdeliveryview.invoicetype == 'MSC00332' || _Stockdeliveryview.stostatus == 'MSC00150' || _Stockdeliveryview.sapcstatus == 'MSC00338' || _Stockdeliveryview.sastatus == 'MSC00150'"
                                        (click)="edit(_Stockdeliveryview)">
                                    </button>
                                    <!-- <button pButton pRipple icon="pi pi-times" title="Cancel"
                                        (click)="confirm(_Stockdeliveryview)" class="p-button p-button-danger"
                                        [disabled]="!_Action._Delete"
                                        [disabled]="_Stockdeliveryview.status == 'MSC00150'"></button> -->
                                    <button pButton pRipple icon="las la-print" title="Print" [disabled]="!_Action._Print" class="p-button-warning p-mr-2" (click)="Print(_Stockdeliveryview)"></button> {{_Stockdeliveryview.Action}}
                                </td>
                                <td [hidden]="true">
                                    {{_Stockdeliveryview.stockdeliveryid}}
                                </td>
                                <td>
                                    {{_Stockdeliveryview.deliveryno}}
                                </td>
                                <td>
                                    {{_Stockdeliveryview.referenceno}}
                                </td>
                                <td>
                                    {{_Stockdeliveryview.grnno}}
                                </td>
                                <td>
                                    {{_Stockdeliveryview.customerorbranchname}}
                                </td>
                                <td>
                                    {{_Stockdeliveryview.deliverydate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_Stockdeliveryview.deliveryto}}
                                </td>
                                <td>
                                    {{_Stockdeliveryview.deliverytypename}}
                                </td>
                                <td>
                                    {{_Stockdeliveryview.invoicetypename}}
                                </td>
                                <td>
                                    {{_Stockdeliveryview.vehicleno}}
                                </td>
                                <td>
                                    {{_Stockdeliveryview.drivername}}
                                </td>
                                <td>
                                    {{_Stockdeliveryview.createdbyname}}
                                </td>
                                <!-- <td>
                                    {{_Stockdeliveryview.statusname}}
                                </td> -->
                            </tr>
                        </ng-template>
                    </p-table>

                </div>
                <p-toast position="bottom-right"></p-toast>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>