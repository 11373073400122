import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import { RptsalescommissionService } from 'src/app/core/Services/reports/Sales/RptSalesCommission/rptsalescommission.service';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Producttype } from 'src/app/core/Models/Producttype';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-rptsalescommission',
  templateUrl: './rptsalescommission.component.html',
  styleUrls: ['./rptsalescommission.component.css']
})
export class RptsalescommissionComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _supplierlist: Supplier[];
  _currencylist: Currency[];
  _productlist: Productoverallview[];
  _salestypelist: Metadatum[];
  _productcategorylist: Productcategory[];
  _producttypelist: Producttype[];
  _branchlist: Branch[];
  _rptsalescommissionform: FormGroup;
  _branchid: number;
  _userid: number;
  _src: SafeResourceUrl;
  SalesMans: any[] = [];
  Currencies: any[] = [];
  _Branch: any[] = []
  _selectedBranch: number[] = []
  _selectedSalesMans: number[] = []
  SalesCommissionDetails: any[] = [];
  selectedrows: any[] = [];
  Currency: string;
  totalCommissionAmount: number = 0;
  totalCommissionCount: number = 0;
  format = '1.2-2';
  _Action: IAction
  mindate: Date;
  maxdate: Date;

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptSalesCommissionService: RptsalescommissionService,
    private _CustomExceptionService: CustomExceptionService,
    public exportUtility: ExportUtility,
    private sanitizer: DomSanitizer,
    private _AccessRightsService: AccessRightsService,
    private decimalPipe: DecimalPipe
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(101);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptsalescommissionform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      productid: [0],
      currencyid: [1],
      userid: [],
      productcategoryid: [0],
      salestypeid: [0]
    })
    this._branchid = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    this.Currency = 'USD'


    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalescommissionform.get("fromdate").setValue(new Date);
      this._rptsalescommissionform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalescommissionform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalescommissionform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptsalescommissionform.get('fromdate').value;
    dt.setDate(dt.getDate() - 7);
    this._rptsalescommissionform.controls['fromdate'].setValue(dt);
    this._RptSalesCommissionService.PageOnload({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._salestypelist = resultJSON.salesManTypes;
      this.Currencies = resultJSON.currencies
      this.SalesMans = resultJSON.users
      this._Branch = resultJSON.branches
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._rptsalescommissionform.reset();
    this._selectedSalesMans = []
    this._selectedBranch = []
    var dt = new Date();
    dt.setDate(dt.getDate() - 7);
    this._rptsalescommissionform.controls['fromdate'].setValue(dt);
    this._rptsalescommissionform.controls['todate'].setValue(new Date());
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.totalCommissionAmount = 0;
    this.totalCommissionCount = 0;
    this.SalesCommissionDetails = []
  }
  GenerateReport(event) {
    var branchid = parseInt(sessionStorage["currentbranchid"]);
    var _fromdate = this._rptsalescommissionform.get("fromdate").value;
    var _todate = this._rptsalescommissionform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var salestypeid = this._rptsalescommissionform.get("salestypeid").value == null ? 0 : this._rptsalescommissionform.get("salestypeid").value;
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    if (!salestypeid) {
      this._CustomExceptionService.handleError("Please Select Sales Type");
      return;
    }
    if (this._selectedSalesMans.length == 0) {
      this._CustomExceptionService.handleError("Please Select Atleast One Sales Man");
      return;
    }
    if (this._selectedBranch.length == 0) {
      this._CustomExceptionService.handleError("Please Select Atleast One Branch");
      return;
    }
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this.totalCommissionAmount = 0;
    this.totalCommissionCount = 0;
    this._RptSalesCommissionService.GetSalesCommissionDetails({
      "EffectiveFrom": fromdate,
      "Effectiveto": todate,
      "SalesManType": salestypeid,
      "SalesMan": this._selectedSalesMans,
      "Branch": this._selectedBranch,
      "Currency": 1
    }).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.SalesCommissionDetails = resultJSON.salesCommissionDetails;
      this.SalesCommissionDetails.forEach(element => {
        this.totalCommissionAmount += element.commissionamount;
      });
      this.totalCommissionCount = resultJSON.salesCommissionDetails.length;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let SalesCommissionList = this.GetSalesCommissionList();
    this.exportUtility.exportExcel(SalesCommissionList, 'Rpt-SalesCommission', 'xlsx');
  }
  ExportToPdf() {
    if (!this.SalesCommissionDetails || !this.SalesCommissionDetails.length)
      return;
    let StatusHistoryDetails = this.GetSalesCommissionList();
    const data = StatusHistoryDetails.map(Object.values);
    const head = [['S. No', 'Sales Man', 'Sales Type', 'Customer Name', 'Invoice Date', 'Invoice No', 'Invoice Amt', 'Amt In USD', 'Currency', 'Commission Amt']]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Rpt-SalesCommission.pdf');
      })
    })
  }
  GetSalesCommissionList() {
    if (!this.SalesCommissionDetails || !this.SalesCommissionDetails.length)
      return;
    let SalesCommissionList = [];
    this.SalesCommissionDetails.forEach((element, index) => {
      let salesCommission: any = {};
      salesCommission["S. No"] = index += 1;
      salesCommission["Sales Man"] = element.username;
      salesCommission["Sales Type"] = element.salestypename;
      salesCommission["Customer Name"] = element.customername
      salesCommission["Invoice Date"] = this.decimalPipe.transform(element.salesinvoicedate, this.format);
      salesCommission["Invoice No"] = element.salesinvoiceno;
      salesCommission["Invoice Amt"] = this.decimalPipe.transform(element.salesinvoiceamount, this.format);
      salesCommission["Amt In USD"] = this.decimalPipe.transform(element.amountinusd, this.format);
      salesCommission["Currency"] = element.currencyname;
      salesCommission["Commission Amt"] = this.decimalPipe.transform(element.commissionamount, this.format);
      SalesCommissionList.push(salesCommission);
    });
    return SalesCommissionList;
  }
}