import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { RptageingService } from 'src/app/core/Services/reports/Accounting/RptAgeing/rptageing.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { Currencyview } from 'src/app/core/Views/Currencyview';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-rpt-ageing-detail-report',
  templateUrl: './rpt-ageing-detail-report.component.html',
  styleUrls: ['./rpt-ageing-detail-report.component.css']
})
export class RptAgeingDetailReportComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptageingform: FormGroup;
  _branchlist = sessionStorage["BranchID"];;
  _userid: number;
  datepipe: any;

  _typelist =
    [{ type: 'Customer', value: '11' },
    { type: 'Supplier', value: '15' }];

  _customertypelist =
    [{ customertype: 'All', value: '4' },
    { customertype: 'Credit', value: '1' },
    { customertype: 'Default', value: '2' },
    { customertype: 'Cash', value: '3' }];

  _suppliertypelist =
    [{ customertype: 'All', value: '1' },
    { customertype: 'Product', value: '2' },
    { customertype: 'Vehicle', value: '3' },
    { customertype: 'Service', value: '4' },
    { customertype: 'GPS', value: '5' },
    { customertype: 'Agent', value: '6' }];

  _accountnamelist: Accountledger[];
  _src: SafeResourceUrl;
  _currencylist: Currencyview[];
  _customer_supplierlist: Accountledgerview[] = [];
  _PartytypeObj: any;
  _AgeingReportObj: any;

  currencyids: number[] = [];
  IsAmountZero: boolean = false;
  _ReportLists: any[] = [];
  cols: any[];
  _Action: IAction;
  _userlist: any[] = [];
  isDisabledresper: boolean = true;
  mindate: Date;
  maxdate: Date;

  constructor(private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private fb: FormBuilder,
    private _RptAgeingService: RptageingService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private sanitizer: DomSanitizer,
    private exportUtility: ExportUtility) { }

  InitiliazeForm() {
    this._rptageingform = this.fb.group({
      date: [(new Date())],
      partytype: [],
      customer_supplierid: [],
      currencyid: [],
      transactioncurrencyname: [],
      branchid: [],
      month: [(new Date())],
      customertype: [],
      _responsibleperson: []

    })
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptageingform.get("date").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptageingform.get("date").setValue(new Date(sessionStorage['Environmentenddate']));
    }
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(115);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);

    this.cols = [
      { field: 'actualname', header: 'Account Name' },
      { field: 'currencyname', header: 'Currency' },
      { field: 'openingbalance', header: 'Opening Balance' },
      { field: 'current', header: '0 Day' },
      { field: 'days30', header: '30 Days' },
      { field: 'days60', header: '60 Days' },
      { field: 'days90', header: '90 Days' },
      { field: 'days120', header: '120 Days' },
      { field: 'days150', header: '150 Days' },
      { field: 'days180', header: '180 Days' },
      { field: 'abovedays180', header: 'Above 180 Days' },
      { field: 'total', header: 'Total' }
    ]

    this.InitiliazeForm();
    this._branchlist = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    this._rptageingform.controls['currencyid'].setValue(1);
    this._RptAgeingService.PageOnload({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._userlist = resultJSON.users;
      this.isDisabledresper = true;
      this.OnSetCurrency();
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  OnSetCurrency() {
    // this._rptageingform.controls['currencyid'].setValue(1);
    this.currencyids = [];
    this.currencyids.push(1);
  }

  clear(event) {
    this._rptageingform.reset();
    this.InitiliazeForm();
    var dt = new Date();
    dt.setDate(dt.getDate() - 7);
    this._rptageingform.controls['date'].setValue(new Date());
    this._rptageingform.controls['currencyid'].setValue(1);
    this.OnSetCurrency();
    this._ReportLists = [];
    this.IsAmountZero = false;
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }

  GenerateReport(event) {
    var branchid = parseInt(sessionStorage["currentbranchid"]);
    var date = this._rptageingform.get("date").value;
    var datePipe = new DatePipe("en-US");
    var Date = datePipe.transform(date, 'yyyy-MM-dd');
    var userid = parseInt(sessionStorage["userid"]);
    var currencyid = this._rptageingform.get("currencyid").value || 0;
    var customerorsupplier = this._rptageingform.get("customer_supplierid").value || [];
    var partytype = this._rptageingform.get("partytype").value || [];
    var customertype = this._rptageingform.get("partytype").value || 0;
    var transactioncurrency = this._rptageingform.get("transactioncurrencyname").value || 0;
    var responsibleperson = this._rptageingform.get("_responsibleperson").value || 0;

    if (Date == null) {
      this._CustomExceptionService.handleError("Please Select Date");
      return;
    }

    if (partytype.length == 0) {
      this._CustomExceptionService.handleError("Please Select Party Type");
      return;
    }

    if (customerorsupplier.length == 0) {
      this._CustomExceptionService.handleError("Please Select Party Name");
      return;
    }
    // if(currencyid == 0 ){
    //   this._CustomExceptionService.handleError("Please Select Report in currency");
    //   return;
    // }

    this._IsProgressSpinner = true;

    this._AgeingReportObj = {
      "ledgerid": customerorsupplier,
      "date": Date,
      "partytype": customertype,
      "transactioncurrency": transactioncurrency,
      "currencyid": currencyid,
      "type": this.IsAmountZero,
      "userid": userid,
      "responsibleperson": responsibleperson
    }

    this._RptAgeingService.getageingdetailreport(this._AgeingReportObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ReportLists = resultJSON.ageingreportList;
      if (!resultJSON.ageingreportList || !resultJSON.ageingreportList.length) {
        this._CustomExceptionService.handleError("No Data Available");
      }

      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
  }

  onchangepartytype(event) {
    this._rptageingform.get("_responsibleperson").reset();
    this._rptageingform.get("customer_supplierid").reset();
    this.IsAmountZero = false;

    this._ReportLists = [];
    var partytype = event.value || [];
    var userid = parseInt(sessionStorage["userid"]);
    this._rptageingform.controls['customertype'].setValue(0);
    var resperson = this._rptageingform.get("_responsibleperson").value || 0;

    this.isDisabledresper = false;

    if (partytype == 11) {
      this._customertypelist =
        [{ customertype: 'All', value: '4' },
        { customertype: 'Credit', value: '1' },
        { customertype: 'Default', value: '2' },
        { customertype: 'Cash', value: '3' }];

    }
    else {
      this._customertypelist = this._suppliertypelist =
        [{ customertype: 'All', value: '1' },
        { customertype: 'Product', value: '2' },
        { customertype: 'Vehicle', value: '3' },
        { customertype: 'Service', value: '4' },
        { customertype: 'GPS', value: '5' },
        { customertype: 'Agent', value: '6' }];

      this.isDisabledresper = true;

    }

    this._IsProgressSpinner = true;

    this._PartytypeObj =
    {
      "partytype": partytype,
      "userid": userid,
      "Customertype": 0,
      "resperson": resperson
    };

    this._RptAgeingService.onchangepartytype(this._PartytypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accountnamelist = resultJSON.accountnameList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
  }
  onchangeresperson(event) {
    var resperson = event.value || 0;
    var userid = parseInt(sessionStorage["userid"]);
    var customertype = this._rptageingform.get("customertype").value || 0;
    var partytype = this._rptageingform.get("partytype").value || 0;

    if (!partytype) {
      this._CustomExceptionService.handleError("Please select party type")
      return;
    }

    this._IsProgressSpinner = true;

    this._PartytypeObj = {
      "partytype": partytype,
      "userid": userid,
      "Customertype": customertype,
      "resperson": resperson
    };

    this._RptAgeingService.onchangepartytype(this._PartytypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accountnamelist = resultJSON.accountnameList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
  }

  onchangecustomertype(event) {
    this._rptageingform.get("_responsibleperson").reset();

    var customertype = event.value || 0;
    var userid = parseInt(sessionStorage["userid"]);
    var partytype = this._rptageingform.get("partytype").value || 0;
    var resperson = this._rptageingform.get("_responsibleperson").value || 0;

    this._IsProgressSpinner = true;
    this._PartytypeObj = {
      "partytype": partytype,
      "userid": userid,
      "Customertype": customertype,
      "resperson": resperson
    };

    this._RptAgeingService.onchangepartytype(this._PartytypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accountnamelist = resultJSON.accountnameList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
  }

  exportExcel() {
    let reportageinglists = this.GetAgeingReportList();
    this.exportUtility.exportExcel(reportageinglists, 'Ageing Report', 'xlsx');
  }

  ExportToPdf() {
    let branchwisestocklists = this.GetAgeingReportList();
    const data = branchwisestocklists.map(Object.values);
    const head = [['Account Name', 'Currency', '0 Day', '30 Days', '60 Days', '90 Days', '120 Days', '150 Days', '180 Days', 'Above 180 Days', 'Total'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Ageing Report.pdf');
      })
    })
  }

  GetAgeingReportList() {
    let ageingreportlists = [];
    this._ReportLists.forEach(element => {
      let ageingreport: any = {};
      ageingreport["Account Name"] = element.NAME;
      ageingreport["Currency"] = element.CURRENCYNAME;
      ageingreport["0 Day"] = element.CURRENT;
      ageingreport["30 Days"] = element.DAYS30;
      ageingreport["60 Days"] = element.DAYS60;
      ageingreport["90 Days"] = element.DAYS90;
      ageingreport["120 Days"] = element.DAYS120;
      ageingreport["150 Days"] = element.DAYS150;
      ageingreport["180 Days"] = element.DAYS180;
      ageingreport["Above 180 Days"] = element.ABOVEDAYS180;
      ageingreport["Total"] = element.TOTAL;
      ageingreportlists.push(ageingreport);
    });
    return ageingreportlists;
  }
}
