export class Branchproductdetail {
    branchproductdetailid: number;
    companyid: number;
    branchid: number;
    producttypeid: number;
    productcategoryid: number;
    productid: number;
    productprice: number;
    recordstatus: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    minimumlevel:number;
    maximumlevel:number;
    reorderlevel:number;
}