<div class="apps-left">
    <div class="logo text-center">
        <img src="assets/images/apps-logo.png" alt="AgES Logo" />
    </div>
    <div class="sidebar">
        <div class="menu-tabs" rel="main-menu">
            <a href="javascript:void(0);" rel="dashboard" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="Dashboard" [hidden]="_Dashboard"><i class="las la-chart-bar"></i></a>
            <a href="javascript:void(0);" rel="tracking" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="Admin" [hidden]="_Tracking"><i
                    class="las la-user-shield"></i></a>
            <a href="javascript:void(0);" rel="configuration" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="Masters" [hidden]="_Master"><i class="las la-tools"></i></a>
            <a href="javascript:void(0);" rel="users" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="Users" [hidden]="_Users"><i class="las la-users"></i> </a>
            <a href="javascript:void(0);" rel="approval" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="Approval" [hidden]="_Approval"><i class="las la-user-check"></i> </a>
            <a href="javascript:void(0);" rel="purchase" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="Purchase" [hidden]="_Purchase"><i class="las la-shopping-cart"></i></a>
            <a href="javascript:void(0);" rel="stock" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="Stock" [hidden]="_Stock"><i class="las la-boxes"></i></a>
            <a href="javascript:void(0);" rel="rewards" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="Rewards" [hidden]="_Rewards"><i class="las la-gift"></i></a>
            <a href="javascript:void(0);" rel="sales" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="Sales" [hidden]="_Sales"><i class="las la-shopping-basket"></i></a>
            <a href="javascript:void(0);" rel="accounts" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="Accounts" [hidden]="_Accounts"><i class="las la-coins"></i></a>
            <a href="javascript:void(0);" rel="reports" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="Reports" [hidden]="_Report"><i class="las la-file-invoice"></i></a>
            <a href="javascript:void(0);" rel="DBBackup" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="DB Backup" [hidden]="_Offline"><i class="las la-server"></i></a>
			<!-- Ecommerce -->
            <a href="javascript:void(0);" rel="ECommerce" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="ECommerce" [hidden]="_Ecommerce"><i class="las la-globe"></i></a>

            <a href="javascript:void(0);" rel="WorkShop" data-toggle-second="tooltip" data-container="body"
                data-placement="right" title="WorkShop" [hidden]="_Workshop"><i class="las la-wrench"></i></a>
																								   

        </div>
    </div>
</div>
<div class="apps-center white">
    <div class="logo text-center">
        <img src="assets/images/logo.png" alt="AgES Logo" />
    </div>
    <div class="main-menu scroll-y">
        <div class="menu-tabs-container dashboard">
            <h5>Dashboard</h5>
            <div class="left-menu">
                <p-menu [model]="menuDashboard"></p-menu>
                <ul class="nav">
                </ul>
            </div>
        </div>
        <div class="menu-tabs-container configuration">
            <h5>Masters</h5>
            <div class="left-menu">
                <p-menu [model]="menuMaster"></p-menu>
                <ul class="nav">
                </ul>
            </div>
        </div>
        <div class="menu-tabs-container users">
            <h5>Users</h5>
            <div class="left-menu">
                <p-menu [model]="menuUsers"></p-menu>
                <ul class="nav">
                </ul>
            </div>
        </div>
        <div class="menu-tabs-container approval">
            <h5>Approval</h5>
            <div class="left-menu">
                <p-menu [model]="menuApproval"></p-menu>
                <ul class="nav">
                </ul>
            </div>
        </div>
        <div class="menu-tabs-container tracking">
            <h5>Admin</h5>
            <div class="left-menu">
                <p-menu [model]="menuTracking"></p-menu>
                <ul class="nav">
                </ul>
            </div>
        </div>
        <div class="menu-tabs-container purchase">
            <h5>Purchase</h5>
            <div class="left-menu">
                <p-menu [model]="menuPurchase"></p-menu>
                <ul class="nav">
                </ul>
            </div>
        </div>
        <div class="menu-tabs-container stock">
            <h5>Stock</h5>
            <div class="left-menu">
                <p-menu [model]="menuStock"></p-menu>
                <ul class="nav">

                </ul>
            </div>
        </div>
        <div class="menu-tabs-container rewards">
            <h5>Rewards</h5>
            <div class="left-menu">
                <p-menu [model]="menuRewards"></p-menu>
                <ul class="nav">
                </ul>
            </div>
        </div>
        <div class="menu-tabs-container sales">
            <h5>Sales</h5>
            <div class="left-menu">
                <p-menu [model]="menuSales"></p-menu>
                <ul class="nav">

                </ul>
            </div>
        </div>
        <div class="menu-tabs-container accounts">
            <h5>Accounts</h5>
            <div class="left-menu">
                <p-menu [model]="menuAccounts"></p-menu>
                <ul class="nav">
                </ul>
            </div>
        </div>



        <div class="menu-tabs-container reports">
            <h5>Reports</h5>
            <div class="left-menu">
                <p-panelMenu [model]="menuReport"></p-panelMenu>
                <ul class="nav">
                </ul>
            </div>
        </div>
        <div class="menu-tabs-container DBBackup">
            <h5>OFF Line Process</h5>
            <div class="left-menu">
                <p-menu [model]="menuOffline"></p-menu>
                <ul class="nav">
                </ul>
            </div>
        </div>
<!-- Ecommerce -->
        <div class="menu-tabs-container ECommerce">
            <h5>ECommerce</h5>
            <div class="left-menu">
                <p-menu [model]="menuEcommerce"></p-menu>
                <ul class="nav">
                </ul>
            </div>
        </div>  

        <div class="menu-tabs-container WorkShop">
            <h5>Work Shop</h5>
            <div class="left-menu">
                <p-menu [model]="menuWorkshop"></p-menu>
                <ul class="nav">
                </ul>
            </div>
        </div>  
    </div>
    <div class="copyright bottom-fixed">
        <div class="timestamp">Date : <strong id="clockbox"></strong></div>
        <div class="timestamp">Login on : <strong>03/11/2020 04:45 PM</strong></div>
    </div>
</div>