import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  public _Action: IAction;
  public _SupplierService = environment.SupplierService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 1)
  }
  createHeaders(token: any, type: number): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(Supplierid: number, userid: number): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._SupplierService + '/' + Supplierid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll(userid: number, selectedtype: number, supplierstatus: string, responsiblepersonid: number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._SupplierService + '/FetchAll/' + userid + '/' + selectedtype + '/' + supplierstatus + '/' + responsiblepersonid;

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON); 
      return data;
    }));
  }

  getStates(countryid: string) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._SupplierService + '/getStates/' + countryid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  getDistrict(stateid: string) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._SupplierService + '/getDistrict/' + stateid;

    return this.http.get<{ data: any }>(Query), { headers };
  }
  // Create
  create(p_customer: object) {
    const headers = this.createHeaders(this.token, 2)
    //console.log("Create");
    var Query = this._SupplierService;
    return this.http.post<{ data: any }>(Query, p_customer, { headers });
  }
  // View
  view(p_supplierid: number) {
    const headers = this.createHeaders(this.token, 2)

    var Query = this._SupplierService + '/' + p_supplierid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  // Edit
  edit(p_supplier: object) {
    const headers = this.createHeaders(this.token, 2)
    //console.log("Update");
    var Query = this._SupplierService;

    return this.http.put<{ data: any }>(Query, p_supplier, { headers });
  }
  ChangeInactive(supplierid: number, modifiedby: number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._SupplierService + '/ChangeInactive/' + supplierid + '/' + modifiedby;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  getProductCategory(producttype: object) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._SupplierService + '/GetProductCategory';

    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }
  //To Get Product :
  getProduct(productcategory: object) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._SupplierService + '/GetProductName';

    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }
  getProductdetails(product: object) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._SupplierService + '/FetchProductdetails';

    return this.http.post<{ data: any }>(Query, product, { headers });
  }

  getProductbrand(product: object) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._SupplierService + '/getProductbrand';

    return this.http.post<{ data: any }>(Query, product, { headers });
  }

  getSupplierMappedproducts(obj: object): Observable<any> {
		const headers = this.header;
		var Query = this._SupplierService + '/getSupplierMappedproducts';
		return this.http.post(Query, obj, { headers });
	}
}
