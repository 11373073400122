import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Brand } from 'src/app/core/Models/Brand';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { productsCattegory } from 'src/app/core/Models/overallclass';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Stocktransferorder } from 'src/app/core/Models/Stocktransferorder';
import { StockmissingService } from 'src/app/core/Services/reports/Stock/StockMissing/stockmissing.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-stockmissing',
  templateUrl: './stockmissing.component.html',
  styleUrls: ['./stockmissing.component.css']
})
export class StockmissingComponent implements OnInit {
  _producttype: Producttype[];
  _productcategory: productsCattegory[];
  _stocktransfer: Stocktransferorder[];
  _tobranch: Branch[];
  _productname: any[];
  _StockMissingform: FormGroup;
  _StockMissingviews: any[];
  StockMissing: any;
  _branch: Branch;
  selectedrows: any;
  today = new Date();
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _IsProgressSpinner: boolean = true;
  _ProductwisestockObj: any;
  cols: any[];
  _submitted = false;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  stocktranferno: string[] = [];
  _inwardtype: Metadatum[];
  _userid: number;
  Ans: number = 0;
  _branchlist: Branch[] = [];
  branchids: number[] = [];
  DisableBranch: boolean = false
  Stockmodel: any = {};
  _brandlist: any;
  brandid: number[] = [];
  _ProductObj: any;
  _Tempbrandlist: Brand;
  Stockmissing: any = {};
  mindate: Date;
  maxdate: Date;
  datepipe = new DatePipe('en-US');

  constructor(
    private fb: FormBuilder,
    private utility: CommonUtilities,
    private _StockmissingService: StockmissingService,
    private _CustomExceptionService: CustomExceptionService,
    private exportUtility: ExportUtility
  ) { }
  InitializeStockMissingForm() {
    this._StockMissingform = this.fb.group({
      supplieridorbranchid: [],
      stocktransferno: [],
      producttypeid: [],
      productcategoryid: [],
      productid: [],
      inwardtype: [],
      fromdate: [''],
      todate: [''],
    });
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeStockMissingForm();
    this.cols = [
      { field: 'stocktransferno', header: 'Reference No' },
      { field: 'date', header: 'Reference Date' },
      { field: 'frombranchname', header: 'Supplier' },
      { field: 'tobranchname', header: 'Receiver' },
      { field: 'productname', header: 'Product Name' },
      { field: 'brandname', header: 'Brand Name' },
      { field: 'requestquantity', header: 'PO / STO Qty' },
      { field: 'deliveryquantity', header: 'Delivery Qty' },
      { field: 'balancedeliveryquantity', header: 'Balance Delivery Qty' },
      { field: 'inwardquantity', header: 'Inward Qty' },
      { field: 'inwardmissingquantity', header: 'Inward Missing Qty' },
      { field: 'grnquantity', header: 'GRN Qty' },
      { field: 'grnmissingquantity', header: 'GRN Missing Qty' },
      { field: 'salesuomname', header: 'UOM' },
      { field: 'status', header: 'Status' },
    ]
    if (sessionStorage['Environmentenddate'] == "null") {
      this._StockMissingform.get("fromdate").setValue(new Date);
      this._StockMissingform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._StockMissingform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._StockMissingform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

    this._StockmissingService.PageOnLoad().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._producttype = resultJSON.producttypes;
      this._branch = resultJSON.branches;
      this._inwardtype = resultJSON.inwardTypes;
      this._branchlist = resultJSON.branches;
      this._brandlist = resultJSON.brandList;
      this._Tempbrandlist = resultJSON.brandList;
      this.search();
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductcategory(event) {
    var selectedProductType = event.value;
    var branchid = sessionStorage["currentbranchid"];
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._StockMissingform.controls["productcategoryid"].reset();
      this._productcategory = [];
      return;
    }
    this._IsProgressSpinner = true;
    this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": branchid || 0 };
    this._StockmissingService.getProductCategories(this._ProductTypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productcategory = resultJSON.productcategories;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductname(event) {
    var selectedProductCategory = event.value;
    var branchid = sessionStorage["currentbranchid"];
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      return;
    }
    this._IsProgressSpinner = true;
    this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": branchid };
    this._StockmissingService.getProducts(this._ProductCategoryObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productname = resultJSON.productdetails;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear() {
    this._submitted = true;
    this._StockMissingform.reset();
    this.InitializeStockMissingForm();
    this._submitted = false;
    this._StockMissingviews = [];
    this._productcategory = [];
    this._productname = [];
    this.brandid = [];
    this._brandlist = this._Tempbrandlist
    if (sessionStorage['Environmentenddate'] == "null") {
      this._StockMissingform.get("fromdate").setValue(new Date);
      this._StockMissingform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._StockMissingform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._StockMissingform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this.Ans = 0;
  }
  search() {
    this._submitted = true;
    if (this._StockMissingform.valid) {
      var Stocktransferno = this._StockMissingform.get("stocktransferno").value || [];
      var productid = this._StockMissingform.get("productid").value || [];
      var inwardtype = this._StockMissingform.get("inwardtype").value || 0;
      var branchid = this._StockMissingform.get("supplieridorbranchid").value || 0;
      var _fromdate = this._StockMissingform.get("fromdate").value;
      var _todate = this._StockMissingform.get("todate").value;
      var datePipe = new DatePipe("en-US");
      var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
      var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
      var userid = parseInt(sessionStorage["userid"]);
      if (!fromdate) {
        this._CustomExceptionService.handleError("Please select From Date");
        return;
      }
      if (!todate) {
        this._CustomExceptionService.handleError("Please select To Date");
        return;
      }
      if (fromdate > todate) {
        this._CustomExceptionService.handleError("From Date should be less than To Date");
        return;
      }
      this._IsProgressSpinner = true;
      this._ProductwisestockObj = {
        "ProductId": productid || [],
        "Stocktransferno": Stocktransferno,
        "InwardType": inwardtype,
        "Currenctbranchid": branchid,
        "FromDate": fromdate,
        "ToDate": todate,
        "UserId": userid,
        "branches": (this.branchids || sessionStorage["currentbranchid"]),
        "brandid": this.brandid || []
      };
      this._StockmissingService.Search(this._ProductwisestockObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._StockMissingviews = resultJSON.stockdetails;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  gettobranch(event) {
    this._IsProgressSpinner = true;
    var inwardtype = this._StockMissingform.get("inwardtype").value || 0;
    this._StockmissingService.gettobranch(event.value).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._tobranch = resultJSON.stockmissingbranchfilterviews;
      this._stocktransfer = resultJSON.stockinwardgetrequestnoviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getSupplierNameorBranchName(event) {
    var inwardtype = event.value;
    this.branchids = []
    // if (inwardtype == "MSC00189") {
    //   this.DisableBranch = true
    // } else {
    //   this.DisableBranch = false
    // }
    this._IsProgressSpinner = true;
    this._StockMissingform.controls['supplieridorbranchid'].reset();
    this._StockMissingform.controls['stocktransferno'].reset();
    var supplierid = this._StockMissingform.get("supplieridorbranchid").value || 0;
    var userid = parseInt(sessionStorage["userid"]);
    this._StockmissingService.getSupplierNameorBranchName(inwardtype, supplierid, userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._tobranch = resultJSON.stockinwardgetsupplierorbranchviews;
      this._stocktransfer = resultJSON.stockinwardgetrequestnoviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getstocktransferno(event) {
    var selectedvalue = event.value;
    if (selectedvalue == null || selectedvalue == undefined) {
      return;
    }
    var inwardtype = this._StockMissingform.get("inwardtype").value || 0;
    this._IsProgressSpinner = true;
    this._StockmissingService.getstocktransferno(event.value, inwardtype).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._stocktransfer = resultJSON.stockinwardgetrequestnoviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let StockmissingList = this.GetStockmissingList();
    this.exportUtility.exportExcel(StockmissingList, 'Stockmissing', 'xlsx');
  }
  ExportToPdf() {
    let StockmissingList = this.GetStockmissingList();
    const data = StockmissingList.map(Object.values);
    const head = [[
      'Reference No',
      'Reference Date',
      'Supplier',
      'Receiver',
      'Product Name',
      'Brand Name',
      'PO / STO Qty',
      'Delivery Qty',
      'Balance Delivery Qty',
      'Inward Qty',
      'Inward Missing Qty',
      'GRN Qty',
      'GRN Missing Qty',
      'UOM',
      'Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Stockmissing.pdf');
      })
    })
  }
  GetStockmissingList() {
    let StockmissingList = [];
    this._StockMissingviews.forEach(element => {
      let stocktmovement: any = {};
      stocktmovement["Reference No"] = element.stocktransferno;
      stocktmovement["Reference Date"] = this.datepipe.transform(element.date, 'dd/MM/yyyy');
      stocktmovement["Supplier"] = element.frombranchname;
      stocktmovement["Receiver"] = element.tobranchname;
      stocktmovement["Product Name"] = element.productname;
      stocktmovement["Brand Name"] = element.brandname;
      stocktmovement["PO / STO Qty"] = element.requestquantity;
      stocktmovement["Delivery Qty"] = element.deliveryquantity;
      stocktmovement["Balance Delivery Qty"] = element.balancedeliveryquantity;
      stocktmovement["Inward Qty"] = element.inwardquantity;
      stocktmovement["Inward Missing Qty"] = element.inwardmissingquantity;
      stocktmovement["GRN Qty"] = element.grnquantity;
      stocktmovement["GRN Missing Qty"] = element.grnmissingquantity;
      stocktmovement["UOM"] = element.salesuomname;
      stocktmovement["Status"] = element.status;
      StockmissingList.push(stocktmovement);
    });
    return StockmissingList;
  }
  ChangeProduct(event) {
    var selectedProduct = event.value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._brandlist = this._Tempbrandlist
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductObj = { "Products": selectedProduct };
      this._StockmissingService.getBrand(this._ProductObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        let productbrand = resultJSON.productbrandlist;
        let uniqueChars = [...new Set(productbrand)];
        uniqueChars = productbrand.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.brandid === test.brandid
          ));
        this._brandlist = uniqueChars;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  stockupdate(stockmissing: any) {
    this.StockMissing = stockmissing;
    if (this.Ans == 0) {
      this.showconfirm('Are you sure you want to proceed this stocks for updation?', () => {
      }, null)
      return
    }
    var index = this._StockMissingviews.findIndex(f => f.grnno == stockmissing.grnno && f.productid == stockmissing.productid);
    this._IsProgressSpinner = true;
    this._StockmissingService.updatestock(stockmissing.grnno, stockmissing.productid, stockmissing.grnmissingquantity).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._StockMissingviews[index].grnmissingquantity = 0;
      this._IsProgressSpinner = false;
      this.Ans = 0;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.Ans = 0; })
  }
  showconfirm(message: string, yesHandler: any, NoHandler?: any) {
    this.Stockmodel.showdialog = true;
    this.Stockmodel.message = message;
    this.Stockmodel.Yes = () => {
      if (typeof yesHandler === 'function')
        yesHandler();
      this.Stockmodel.showdialog = false;
    };
    this.Stockmodel.Cancel = () => {
      if (typeof NoHandler === 'function')
        NoHandler();
      this.Stockmodel.showdialog = false;
      return
    };
  }
  Yes() {
    this.Stockmodel.showdialog = false;
    this.Ans = 1;
    this.stockupdate(this.StockMissing);
    return
  }
  Cancel() {
    this.Stockmodel.showdialog = false;
    this.Ans = 0;
    return
  }
  postatusupdate(row: any, event) {
    this.Stockmissing.stocktransferno = row.stocktransferno
    this.Stockmissing.productid = row.productid;
    this.showconfirmstockmissing('Please Confirm to Remove Product Name : ' + row.productname + ' From Stock Missing Report ??', () => { }, null)
  }
  updatemissing(stktransferno, productid) {
    var purchaseorderno = stktransferno;
    var productid = productid;
    this._IsProgressSpinner = true;
    this._StockmissingService.updatepostatus(purchaseorderno, productid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.search();
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
  }
  Yes1() {
    this.Stockmissing.showdialog = false;
    this.updatemissing(this.Stockmissing.stocktransferno, this.Stockmissing.productid)
    return
  }
  Cancel1() {
    this.Stockmissing.showdialog = false;
    return
  }
  showconfirmstockmissing(message: string, yesHandler: any, NoHandler?: any) {
    this.Stockmissing.showdialog = true;
    this.Stockmissing.message = message;
    this.Stockmissing.Yes = () => {
      if (typeof yesHandler === 'function')
        yesHandler();
      this.Stockmissing.showdialog = false;
    };
    this.Stockmissing.Cancel = () => {
      if (typeof NoHandler === 'function')
        NoHandler();
      this.Stockmissing.showdialog = false;
      return
    };
  }
  onchangefromdate() {
    let fromdate = this._StockMissingform.get("fromdate").value
    this._StockMissingform.get("todate").reset();
    this.mindate = fromdate;
    let onemonth = new Date(fromdate);
    onemonth.setDate(fromdate.getDate() + 30)
    this.maxdate = onemonth
  }
}