<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Delivered & Not Delivered</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <div class="row">
                    <div class="col-md-6">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Branch: Delivered & Not Delivered</h5>
                                </div>
                                <div class="col-md-6 text-right">
                                    <h3> Delivered Qty.:{{OverallBranchDeliveredQty}},
                                        Not Delivered Qty.: {{OverallBranchNotDeliveredQty}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtBranchWiseDeliverednotDelivered.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>

                                </ng-template>
                                <ng-template pTemplate="right">

                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                        (click)="dtBranchWiseDeliverednotDelivered.exportCSV()" class="p-mr-2"
                                        pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                        class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                        (click)="exportExcel()"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                        class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                        (click)="ExportToPdf()"></button>
                                </ng-template>

                            </p-toolbar>
                            <p-table #dtBranchWiseDeliverednotDelivered [value]="_BranchDeliveredNotdelivereds"
                                selectionMode="single" [rows]="25" [paginator]="true"
                                [rowsPerPageOptions]="[15,25,50,100]" (onRowSelect)="onRowSelectBranch($event)"
                                [columns]="cols" [globalFilterFields]="['branchname','invoicequantity','deliveredquantity','notdeliveredquantity']"
                                [rowHover]="true" dataKey="branchid"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedBranchwiseLockedqty">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="branchname" style="width:250px">Branch Name<p-sortIcon field="branchname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="invoicequantity">Invoice Qty.<p-sortIcon
                                                field="invoicequantity">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="deliveredquantity">Delivered Qty.<p-sortIcon
                                                field="deliveredquantity">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="notdeliveredquantity">Not Delivered Qty.<p-sortIcon
                                                field="notdeliveredquantity">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_BranchDeliveredNotdelivered>
                                    <tr [pSelectableRow]="_BranchDeliveredNotdelivered">
                                        <td>
                                            {{_BranchDeliveredNotdelivered.branchname}}
                                        </td>
                                        <td class="text-right">
                                            {{_BranchDeliveredNotdelivered.invoicequantity}}
                                        </td>
                                        <td class="text-right">
                                            {{_BranchDeliveredNotdelivered.deliveredquantity}}
                                        </td>
                                        <td class="text-right">
                                            {{_BranchDeliveredNotdelivered.notdeliveredquantity}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-6" [hidden]="IsShowProductWiseDeliveredNotDelivered">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Product Wise Delivered & Not Delivered</h5>
                                </div>
                                <div class="col-md-6  text-right">
                                    <h3> Delivered Qty.:{{OverallPrddeliveredqty}}, Not Delivered
                                        Qty.:{{OverallPrdnotdeliveredqty}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtProdeliverednotDeliveredqty.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>

                                </ng-template>
                                <ng-template pTemplate="right">

                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                        (click)="dtProdeliverednotDeliveredqty.exportCSV()" class="p-mr-2"
                                        pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                        class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                        (click)="exportExcelProductWiseQuantity()"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                        class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                        (click)="ExportToPdfProductWiseQuantity()"></button>
                                </ng-template>

                            </p-toolbar>
                            <p-table #dtProdeliverednotDeliveredqty [value]="_PrdDeliveredNotDeliveredQtys"
                                selectionMode="single" [rows]="25" [paginator]="true"
                                [rowsPerPageOptions]="[15,25,50,100]" [columns]="ProductdeliverednotDeliveredcols"
                                [globalFilterFields]="['productname','invoicequantity','deliveredquantity','notdeliveredquantity']"
                                [rowHover]="true" dataKey="productid" (onRowSelect)="onRowSelectProduct($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedProductRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="productname" style="width:350px">Product Name<p-sortIcon field="productname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="invoicequantity">Invoice Qty.<p-sortIcon
                                                field="invoicequantity">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="deliveredquantity">Delivered Qty.<p-sortIcon
                                                field="deliveredquantity">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="notdeliveredquantity">Not Delivered Qty.<p-sortIcon
                                                field="notdeliveredquantity">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-_PrdDeliveredNotDeliveredQty
                                    let-i="rowIndex">
                                    <tr [pSelectableRow]="rowData">
                                        <td>
                                            {{_PrdDeliveredNotDeliveredQty.productname}}
                                        </td>
                                        <td class="text-right">
                                            {{_PrdDeliveredNotDeliveredQty.invoicequantity}}
                                        </td>
                                        <td class="text-right">
                                            {{_PrdDeliveredNotDeliveredQty.deliveredquantity}}
                                        </td>
                                        <td class="text-right">
                                            {{_PrdDeliveredNotDeliveredQty.notdeliveredquantity}}
                                        </td>
                                    </tr>
                                </ng-template>

                            </p-table>
                        </div>
                        <p-dialog header="Delivered & Not Delivered Details" [(visible)]="griddetaillockqty"
                            [modal]="true" [style]="{width: '800px',height: '600px'}" [baseZIndex]="10000">
                            <ng-template pTemplate="content">
                                <div class="popup-body">
                                    <div class="card">
                                        <p-toolbar styleClass="p-mb-4">
                                            <ng-template pTemplate="left">
                                                <span class="p-input-icon-left">
                                                    <i class="pi pi-search"></i>
                                                    <input pInputText type="text"
                                                        (input)="dt_DeliveredNotDelivereddetails.filterGlobal($event.target.value, 'contains')"
                                                        placeholder="Search..." />
                                                </span>

                                            </ng-template>
                                            <ng-template pTemplate="right">

                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    title="Export CSV"
                                                    (click)="dt_DeliveredNotDelivereddetails.exportCSV()" class="p-mr-2"
                                                    pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS"
                                                    tooltipPosition="bottom"
                                                    (click)="exportExcelDeliveredNotDelivereddetails()"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-pdf"
                                                    title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF"
                                                    tooltipPosition="bottom"
                                                    (click)="ExportToPdfDeliveredNotDelivereddetails()"></button>
                                            </ng-template>

                                        </p-toolbar>
                                        <p-table #dt_DeliveredNotDelivereddetails
                                            [value]="_DeliveredNotDelivereddetails" selectionMode="single"
                                            [(selection)]="selectedrefno" [paginator]="true"
                                            [columns]="_DeliveredNotDelivereddetailscols" [rows]="15"
                                            [globalFilterFields]="['customerorbranchname','referenceno','invoicequantity']"
                                            [rowHover]="true" dataKey="referenceno"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                            [showCurrentPageReport]="true">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th pSortableColumn="customerorbranchname" style="width:250px">Customer/Branch
                                                        <p-sortIcon field="customerorbranchname">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="referenceno"> Ref. No.<p-sortIcon
                                                            field="referenceno">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="invoicequantity">Invoice Qty.<p-sortIcon
                                                            field="invoicequantity">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="deliveredquantity">Delivered Qty.<p-sortIcon
                                                            field="deliveredquantity">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="notdeliveredquantity" >Not Delivered Qty.
                                                        <p-sortIcon field="notdeliveredquantity">
                                                        </p-sortIcon>
                                                    </th>

                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-_DeliveredNotDelivereddetail>
                                                <tr>

                                                    <td>
                                                        {{_DeliveredNotDelivereddetail.customerorbranchname}}
                                                    </td>
                                                    <td class="text-right">
                                                        {{_DeliveredNotDelivereddetail.referenceno}}
                                                    </td>
                                                    <td class="text-right">
                                                        {{_DeliveredNotDelivereddetail.invoicequantity}}
                                                    </td>
                                                    <td class="text-right">
                                                        {{_DeliveredNotDelivereddetail.deliveredquantity}}
                                                    </td>
                                                    <td class="text-right">
                                                        {{_DeliveredNotDelivereddetail.notdeliveredquantity}}
                                                    </td>

                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </ng-template>
                        </p-dialog>

                    </div>

                </div>


            </div>
        </div>
    </div>
</div>