import { Injectable } from '@angular/core';
import { Userrolemappedfunctionsview } from '../../Views/Userrolemappedfunctionsview';
import { IAction } from './IActions';

@Injectable({
  providedIn: 'root'
})
export class AccessRightsService {
  _Action: IAction;
  constructor() { }

  getAccessRights(screenid: number) {

    var roleid = sessionStorage["currentRole"]

    var screenAccessRights: Userrolemappedfunctionsview[] = JSON.parse(sessionStorage.getItem("Functions"));

    // console.log(screenAccessRights);
    let _CountryScreenAccess = screenAccessRights.find(funct => funct.screenid == screenid && funct.roleid == roleid);
    this._Action = {
      _functionId: screenid,
      _functionName: _CountryScreenAccess.screenname,
      _Add: _CountryScreenAccess.create,
      _Delete: _CountryScreenAccess.delete,
      _Update: _CountryScreenAccess.update,
      _View: _CountryScreenAccess.view,
      _Approve: _CountryScreenAccess.approval,
      _Print: _CountryScreenAccess.print,
      _Series: _CountryScreenAccess.series,
      _PriceHistory: _CountryScreenAccess.pricehistory,
      _Profit: _CountryScreenAccess.profit,
      _TabViews: _CountryScreenAccess.tabviews
    }
    return this._Action;
  }

}
