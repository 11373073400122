import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Accounttype } from 'src/app/core/Models/Accounttype';
import { AccounttypeService } from 'src/app/core/Services/masters/accounttype.service';
import { Accounttypeview } from 'src/app/core/Views/Accounttypeview';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-v-account-type',
  templateUrl: './v-account-type.component.html',
  styleUrls: ['./v-account-type.component.css']
})
export class VAccountTypeComponent implements OnInit {
  _accounttypesview: Accounttypeview;
  _AccountTypeview: Accounttypeview;
  _AccountType: Accounttype;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  constructor(private fb: FormBuilder, private _AccountTypeService: AccounttypeService, private router: Router, private utility: CommonUtilities) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._AccountTypeService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accounttypesview = resultJSON.accounttypeview;
      // this._Add = this._CityService._Action._Add;
      // this._delete = this._CityService._Action._Delete;
      // this._update = this._CityService._Action._Update;
      // this._View = this._CityService._Action._View;
    });
  }
  onRowSelect(event) {
    //console.log("eventfired");
    //console.log(event.data);
    //console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    //console.log("eventUnfired");
    //console.log(event.data);
    //console.log(this.selectedrows);
  }

  view(event) {
    this.router.navigate(['/cAccountType'], { state: { accounttypecode: event.accounttypecode, action: 'view' } });
  }

  delete(event) {
    alert("delete");
  }

  edit(event) {

    // this.router.navigate(['/cCustomer']);
    //console.log("event.value");
    //console.log(event.accounttypecode);

    this.router.navigate(['/cAccountType'], { state: { accounttypecode: event.accounttypecode, action: 'edit' } });
  }


}

