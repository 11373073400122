
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import * as ace from "ace-builds";
import { Containertracking } from 'src/app/core/Models/Containertracking';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Port } from 'src/app/core/Models/Port';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { vContainerNo, vDateSelect, vSupplierName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { RptpurchaseinwardsummaryService } from 'src/app/core/Services/reports/Purchase/RptPurchaseInwardsummary/rptpurchaseinwardsummary.service';
@Component({
  selector: 'app-rptpurchaseinwardsummary',
  templateUrl: './rptpurchaseinwardsummary.component.html',
  styleUrls: ['./rptpurchaseinwardsummary.component.css']
})
export class RptpurchaseinwardsummaryComponent implements OnInit {

  _IsPO: boolean = true;
  _IsStocktransfer: boolean = false;
  _supplierlist: Supplier[];
  _branchlist: Branch[];
  _currencylist: Currency[];
  _inwardtypelist: Metadatum[];
  // _portList: Port[];
  _purchaseinwardsummaryform: FormGroup;
  _branchid = sessionStorage["currentbranchid"];
  _userid: number;
  _src: SafeResourceUrl;
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _IsProgressSpinner: boolean = true;
  _PurchaseInwardPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  mindate: Date;
  maxdate: Date;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _RptpurchaseinwardsummaryService: RptpurchaseinwardsummaryService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) {
    // this._containertrackingid = history.state?.containertrackingid ? history.state?.containertrackingid : 0;
    // this._action = history.state.action == null ? 'create' : history.state.action;
    // this.HotKeyIntegration();
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._purchaseinwardsummaryform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      productid: [0],
      currencyid: [0],
      inwardtypeid: [0],
      supplierorbranchid: [0]
    })
    //this._branchid= parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    // this.InitializeForm();

    this._IsProgressSpinner = true;
    if (sessionStorage['Environmentenddate'] == "null") {
      this._purchaseinwardsummaryform.get("fromdate").setValue(new Date);
      this._purchaseinwardsummaryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._purchaseinwardsummaryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._purchaseinwardsummaryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._RptpurchaseinwardsummaryService.PageOnload(this._branchid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._supplierlist = resultJSON.supplierList;
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._producttypelist = resultJSON.producttypeList;
      this._inwardtypelist = resultJSON.inwardtypeList;
      this._IsProgressSpinner = false;
      //this.isDisabledSave = false;
      // this.isDisabledClear = false;


    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  clear(event) {
    //25.If clear clicking all fields should clear in grid
    this._purchaseinwardsummaryform.reset();
    this.productcatid = [];
    this.producttypeid = [];
    this.productid = [];
    this._productcategorylist = [];
    this._productlist = [];

    if (sessionStorage['Environmentenddate'] == "null") {
      this._purchaseinwardsummaryform.get("fromdate").setValue(new Date);
      this._purchaseinwardsummaryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._purchaseinwardsummaryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._purchaseinwardsummaryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }

  onChange(event) {
    if (this._purchaseinwardsummaryform.get("inwardtypeid").value == "MSC00189") {
      this._IsPO = true;
      this._IsStocktransfer = false;
    }
    else if (this._purchaseinwardsummaryform.get("inwardtypeid").value == "MSC00190") {
      this._IsStocktransfer = true;
      this._IsPO = false;
    }
    else {
      this._IsStocktransfer = true;
      this._IsPO = false;
    }

  }
  GenerateReport(event) {
    this._IsProgressSpinner = true;
    var _fromdate = this._purchaseinwardsummaryform.get("fromdate").value;
    var _todate = this._purchaseinwardsummaryform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select TO Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    var branchid = this._purchaseinwardsummaryform.get("branchid").value == null ? 0 : this._purchaseinwardsummaryform.get("branchid").value;
    var Inwardtype = this._purchaseinwardsummaryform.get("inwardtypeid").value == null ? 0 : this._purchaseinwardsummaryform.get("inwardtypeid").value;
    var productid = this._purchaseinwardsummaryform.get("productid").value == null ? 0 : this._purchaseinwardsummaryform.get("productid").value;
    if (this._purchaseinwardsummaryform.get("inwardtypeid").value == null) {
      var supplierorbranchid = this._purchaseinwardsummaryform.get("inwardtypeid").value == null ? 0 : this._purchaseinwardsummaryform.get("supplierid").value;
    }
    else {
      var supplierorbranchid = this._purchaseinwardsummaryform.get("inwardtypeid").value == "MSC00189" ? (this._purchaseinwardsummaryform.get("supplierid").value == null ? 0 : this._purchaseinwardsummaryform.get("supplierid").value) : (this._purchaseinwardsummaryform.get("branchid").value == null ? 0 : this._purchaseinwardsummaryform.get("branchid").value);
    }
    this._IsProgressSpinner = true;

    var mediaType = 'application/pdf';
    this._PurchaseInwardPrintObj = {
      "Productheader": this.productid, "branchid": branchid || parseInt(sessionStorage["currentbranchid"]), "SupplieridOrBranchName": supplierorbranchid, "InwardType": Inwardtype,
      "FromDate": fromdate, "ToDate": todate, "userid": this._userid
    };
    this._RptpurchaseinwardsummaryService.Print(this._PurchaseInwardPrintObj).subscribe((result) => {
      if (result.type != mediaType) {
        let reader = new FileReader();
        var blob = new Blob([result], { type: 'application/json' });
        let message = reader.readAsText(blob);
        return;
      }
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;

    },
      async (error) => {
        const message = await error.error.text();
        this._IsProgressSpinner = false;
        this._CustomExceptionService.handleError(message);
      });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptpurchaseinwardsummaryService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("category", resultJSON);
        this._productcategorylist = resultJSON.productcategories;
        //this._productcategory = [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptpurchaseinwardsummaryService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productList;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
}
