import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class StockvaluationService {
  public _Action: IAction;
  public _StockvaluationService = environment.StockvaluationService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  StockValuationReport(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._StockvaluationService + '/StockValuationReport';
    return this.http.post(Query, obj, { headers });
  }
  getProductCategories(producttype: object) {
    const headers = this.header;
    var Query = this._StockvaluationService + '/getCategory';

    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }

  getProducts(productcategory: object) {
    const headers = this.header;
    var Query = this._StockvaluationService + '/getProduct';

    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }
  BrandSuppliersearch(obj: object) {
    const headers = this.header;
    var Query = this._StockvaluationService + '/BrandSuppliersearch';

    return this.http.post<{ data: any }>(Query, obj, { headers });
  }

}
