export class Tenderdocumentdetail {
    tenderdocumentdetailid: number;
    tenderid: number;
    documentdescription: string;
    attachmenturl: string;
    attachmentname: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    accountingyear: number;
    documentname: any;
}

