import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
	providedIn: 'root'
})
export class SalesinvoiceposService {
	public _Action: IAction;
	public _SalesinvoiceposService = environment.SalesinvoiceposService;
	public _salescardapi = environment.salescardintegrationapi;
	public _httpOptions;
	token: any;
	header: any;

	constructor(private http: HttpClient) {
		this.token = sessionStorage['AuthToken'];
		this.header = this.createHeaders(this.token)

	}
	createHeaders(token: any): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`, // Include your token here	
		});
	}

	PageOnLoad(salesinvoiceno: string, userid: number, branchid: number): Observable<any> {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/' + salesinvoiceno + '/' + userid + '/' + branchid;
		return this.http.get<{ data: any }>(Query, { headers });
	}

	GetReferenceNo(referencetype: string, userid: number, customerid: number) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetReferenceNo' + '/' + referencetype + '/' + userid + '/' + customerid;
		return this.http.get<{ data: any }>(Query, { headers });
	}

	GetReferenceDetails(referencetype: string, referenceno: string, userid: number) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetReferenceDetails' + '/' + referencetype + '/' + referenceno + '/' + userid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetProductCategory(producttype: object) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetProductCategory';
		return this.http.post<{ data: any }>(Query, producttype, { headers });
	}
	GetProductName(productcategory: object) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetProductName';
		return this.http.post<{ data: any }>(Query, productcategory, { headers });
	}

	// GetProductPrice(productid: number, variantid: number, quantity: number, branchid: number, customerid: number, userid: number,markuplevelid:number) {
	//   var Query = this._SalesinvoiceposService + '/GetProductPrice' + '/' + productid + '/' + variantid + '/' + quantity + '/' + branchid + '/' + customerid + '/' + userid + '/' + markuplevelid;
	//   return this.http.get<{ data: any }>(Query);
	// }
	GetProductPrice(productid: number, variantid: number, quantity: number, branchid: number, customerid: number, userid: number, markuplevelid: number, isdamage: boolean, severity: string, roleid: number, currentbranchid: number) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetProductPrice' + '/' + productid + '/' + variantid + '/' + quantity + '/' + branchid + '/' + customerid + '/' + userid + '/' + markuplevelid + '/' + isdamage + '/' + severity + '/' + roleid + '/' + currentbranchid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetProduct(data) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetProduct';
		return this.http.post<{ data: any }>(Query, data, { headers });
	}
	GetVariant(product: number, branchid: number, isDamaged: boolean, severity: string) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetVariant' + '/' + product + '/' + branchid + '/' + isDamaged + '/' + severity;
		return this.http.get<{ data: any }>(Query, { headers });
	}

	GetCustomerDetails(customerid: number, type: string) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetCustomerDetails' + '/' + customerid + '/' + type;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetCustomerBasedCreditNoteDetails(customerid: number) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetCustomerBasedCreditNoteDetails' + '/' + customerid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	CheckCollectingBranchStock(productid: number, branchid: number, variantid: number) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/CheckCollectingBranchStock' + '/' + productid + '/' + branchid + '/' + variantid;
		return this.http.get<{ data: any }>(Query, { headers });
	}

	// customercreate(customer: object) {
	//   console.log("Customer Create");
	//   var Query = this._CustomerService + '/CustomerQuickSave';
	//   console.log(Query);
	//   return this.http.post<{ data: any }>(Query, customer);
	// }

	create(salesinvoicetax: object) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService;
		return this.http.post<{ data: any }>(Query, salesinvoicetax, { headers });
	}
	FetchAll(branchid: number, userid: number, fromdate: string, todate: string, roleid: number, type: string) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/FetchAll' + '/' + branchid + '/' + userid + '/' + fromdate + '/' + todate + '/' + roleid + '/' + type;
		return this.http.get<{ data: any }>(Query, { headers });
	}

	SendToApproval(workflowid: number, userid: number, branchid: number, salesinvoiceid: number) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/SendToApproval' + '/' + workflowid + '/' + userid + '/' + branchid + '/' + salesinvoiceid;
		return this.http.post<{ data: any }>(Query, '', { headers });
	}

	Cancel(salesinvoiceid: number, branchid: number, userid: number) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/Cancel/' + salesinvoiceid + '/' + branchid + '/' + userid;
		return this.http.get<{ data: any }>(Query, { headers });
	}

	GetCreditNoteNos(salesinvoiceno: string) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetCreditnoteDetails/' + salesinvoiceno;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetDamagedProductName(isdamagedproduct: boolean, severity: string, branchid: number, userid: number, customerid: number) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetDamagedProductName' + '/' + isdamagedproduct + '/' + severity + '/' + branchid + '/' + userid + '/' + customerid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetPaymentApiDetails(obj: object) {
		var Query = this._salescardapi;
		return this.http.post<{ data: any }>(Query, obj);
	}

	UpdatePaymentStatus(obj: object) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/UpdatePaymentStatus';
		return this.http.post<{ data: any }>(Query, obj, { headers });
	}

	CopyInvoice(obj: object) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/CopyInvoice';
		return this.http.post<{ data: any }>(Query, obj, { headers });
	}

	GetConversion(obj: object) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetConversion';
		return this.http.post<{ data: any }>(Query, obj, { headers });
	}

	GetCustomer(customer: string, user: number) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/GetCustomer' + '/' + customer + '/' + user;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	getProductQuickList(obj: object) {
		const headers = this.header;
		var Query = this._SalesinvoiceposService + '/getProductQuickList';
		return this.http.post<{ data: any }>(Query, obj, { headers });
	}
}
