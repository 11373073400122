<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Receivables And Payables Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_screenlist" optionValue="metasubcode"
                                    optionLabel="metasubdescription" [filter]="true" [(ngModel)]="PartyType"
                                    filterBy="metasubcode">
                                </p-dropdown>
                                <label for="name">Party Type<span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport()"
                                class="p-button-sm p-button-success"></button>
                            <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()"
                                class="p-button-danger"></button>
                        </td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt_paymentReceivableViews.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="exportPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_paymentReceivableViews" [columns]="cols" [rows]="10" [paginator]="true"
                        [scrollable]="true" [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['PARTYNAME','PARTYCODE','PARTYNAME','USD','BOND','BANK','RAND','CONSLIDATEDINUSD']"
                        [rowHover]="true" dataKey="PARTYNAME"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup id="colgroup"></colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of cols" style="width: 100px;text-align: center;"
                                    pSortableColumn="{{col}}">
                                    {{col}}
                                    <p-sortIcon field="{{col}}">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-colvalues="columns">
                            <tr>
                                <td *ngFor="let col of colvalues" style="width: 100px;">
                                    {{rowData[col]}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>