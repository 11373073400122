import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { RptAssetValueReportService } from 'src/app/core/Services/reports/Accounting/RptAssetValueReport/rpt-asset-value-report.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
  selector: 'app-rpt-asset-value-report',
  templateUrl: './rpt-asset-value-report.component.html',
  styleUrls: ['./rpt-asset-value-report.component.css']
})
export class RptAssetValueReportComponent implements OnInit {
  _assetValueReport: any[] = []
  _branchlist: any[] = []
  _ProductList: any[] = []
  branchid: any[] = []
  productid: any[] = []
  _IsProgressSpinner: boolean = true;
  datepipe = new DatePipe('en-US');
  _Action:IAction;

  constructor(
    private _CustomExceptionService: CustomExceptionService,
    private _RptAssetValueReportService: RptAssetValueReportService,
    private utility: CommonUtilities,
    private _AccessRightsService : AccessRightsService,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(339);
    this.utility.pageLoadScript();
    this._IsProgressSpinner = false;
    let headerbranch = parseInt(sessionStorage["BranchID"])
    this.branchid.push(headerbranch)
    let TaskName = "PAGEONLOAD"
    let Data = {
      "TaskName": TaskName,
      "BranchId": this.branchid || [],
      "ProductId": this.productid || [],
    }
    this._IsProgressSpinner = true;
    this._RptAssetValueReportService.Search(Data).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        console.log(result, 'objectes');
        this._branchlist = result.branches;
        this._ProductList = result.productlist;
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
        this._IsProgressSpinner = false;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GenerateReport() {
    if (!this.branchid || !this.branchid.length) {
      this._CustomExceptionService.handleError("Please Select Atleast One Branch");
      return;
    }
    let TaskName = "SEARCH"
    let Data = {
      "TaskName": TaskName,
      "BranchId": this.branchid || [],
      "ProductId": this.productid || [],
    }
    this._IsProgressSpinner = true;
    this._RptAssetValueReportService.Search(Data).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        console.log(result, 'objectes');
        this._assetValueReport = result.assetRportLists;
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
        this._IsProgressSpinner = false;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear() {
    this.branchid = []
    this.productid = []
    this._assetValueReport = []
    let headerbranch = parseInt(sessionStorage["BranchID"])
    this.branchid.push(headerbranch)
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'Asset_Value_Report', 'xlsx');
  }
  ExportToPdf() {
    let finalizedList = this.GetAssetRegistrationList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Registration Code',
      'Product Name',
      'Purchase Date',
      'Purchase Amount',
      'Current Asset Value',
      'App Or Dep Per Year',
      'App Or Dep Per Year Amount'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Asset_Value_Report.pdf');
      })
    })
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._assetValueReport.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Registration Code"] = element.registrationCode;
      assetregistration["Product Name"] = element.productName;
      assetregistration["Purchase Date"] = this.datepipe.transform(element.purchaseDate, 'dd/MM/yyyy');
      assetregistration["Purchase Amount"] = element.purchaseAmount;
      assetregistration["Current Asset Value"] = element.currentAssetValue;
      assetregistration["App Or Dep Per Year"] = element.apporDepPerYear;
      assetregistration["App Or Dep Per Year Amount"] = element.apporDepPerYearAmount;
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
}