import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptClosingBalanceVerificationService {
  public _Action: IAction;
  public _httpOptions;
  public _RptClosingBalanceVerificationService = environment.RptClosingBalanceVerificationService;
  constructor(private http: HttpClient) { }

  PageOnload(_ClosingReportObj: object): Observable<any> {
    var Query = this._RptClosingBalanceVerificationService + '/PageInitialize';

    return this.http.post<{ data: any }>(Query, _ClosingReportObj);

  }

  Print(_ClosingReportObj: object) {
    var Query = this._RptClosingBalanceVerificationService + '/Print';
    return this.http.post<{ data: any }>(Query, _ClosingReportObj);

  }

  onchangepartytype(_ClosingReportObj: object) {
    var Query = this._RptClosingBalanceVerificationService + '/onchangepartytype'

    return this.http.post<{ data: any }>(Query, _ClosingReportObj);
  }
}
