import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/core/domain/appconfig';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Customer } from 'src/app/core/Models/Customer';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Stockadjustmentdetail } from 'src/app/core/Models/Stockadjustmentdetail';
import { CustomerdashboardService } from 'src/app/core/Services/dashboard/customerdashboard.service';
import { vProductName } from 'src/app/core/Validators/validation';
@Component({
    selector: 'app-customer-dashboard',
    templateUrl: './customer-dashboard.component.html',
    styleUrls: ['./customer-dashboard.component.css']
})
export class CustomerDashboardComponent implements OnInit {

    _IsProgressSpinner: boolean = true;

    productsalesdetails: [];
    ProductWiseSalesdetails:any[];
    _Isbarpws: boolean = true; _Islinepws: boolean = true; _Ispiepws: boolean = true; _Isdoughnutpws: boolean = true;
    _Isbarsd: boolean = true; _Islinesd: boolean = true; _Ispiesd: boolean = true; _Isdoughnutsd: boolean = true;
    _Isbarrd: boolean = true; _Islinerd: boolean = true; _Ispierd: boolean = true; _Isdoughnutrd: boolean = true;
    _Isbarod: boolean = true; _Islineod: boolean = true; _Ispieod: boolean = true; _Isdoughnutod: boolean = true;
    lableDataProductWise: any = [];
    trimlableDataProductWise: any = [];
    templableDataProductWise: any = [];
    temptrimlableDataProductWise: any = [];
    lableDataOutStanding: any = [];
    trimlableDataOutStanding: any = [];
    lableDataSales: any = [];
    trimlableDataSales: any = [];
    _productname: Productheader[] = [];
    salesValueProductWise: any = [];
    tempsalesValueProductWise: any = [];
    salesValueOutStanding: any = [];
    salesValueSales: any = [];
    lableData3: any = [];
    trimlableData3: any = [];
    salesValuepay: any = [];
    salesValuerec: any = [];
    basicData: any;
    basicData1: any;
    basicData2: any;
    ChangebasicData:any;
    _productdetails: Stockadjustmentdetail[] = [];
    basicOptions: any;
    multiAxisData: any;
    _submitted1 = false;
    chartOptions: any;
    _productsubmitted = false;
    subscription: Subscription;
    _customerdashboardform: FormGroup;
    _salesdashboardform: FormGroup;
    _outstandingdashboardform: FormGroup;
    filterBranchs: any[] = [];
    _Branches: Branch[] = [];
    _Customers: Customer[];
    filterCustomers: any[] = [];
    config: AppConfig;
    charttype: any;
    userid: number;
    branchid: number;
    fromdate: Date;
    todate: Date;
    customerid: number;
    _producttype: Producttype[] = [];
    _productcategory: Productcategory[] = [];
    _ProductTypeObj: any;
    _ProductCategoryObj: any;
    userLogo: string = "assets/images/no-data.jpg";
    _IsNoData:boolean = false;
    _IsNoDataOustanding:boolean = false;
    constructor(private _customerDashboardService: CustomerdashboardService, private fb: FormBuilder, private _CustomExceptionService: CustomExceptionService) { }

    ngOnInit() {
        this._customerdashboardform = this.fb.group({
            branchid: [],
            fromdate: [],
            todate: [new Date()],
            customerid: [],
            producttypeid: [],
            productcategoryid: [],
            productid: []
        })
        this._salesdashboardform = this.fb.group({
            branchid: [],
            customerid: [],
        })
        this._outstandingdashboardform = this.fb.group({
            branchid: [],
            customerid: [],
            
        })

        this._Isbarpws = true; this._Islinepws = false; this._Ispiepws = false; this._Isdoughnutpws = false;
        this._Isbarsd = true; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = false;
        this._Isbarrd = true; this._Islinerd = false; this._Ispierd = false; this._Isdoughnutrd = false;
        this._Isbarod = true; this._Islineod = false; this._Ispieod = false; this._Isdoughnutod = false;
        var dt = new Date();
        dt.setDate(dt.getDate() - 30);
        this._customerdashboardform.controls['fromdate'].setValue(dt);
        this.config = this._customerDashboardService.config;
        this.updateChartOptions();
        this.subscription = this._customerDashboardService.configUpdate$.subscribe(config => {
            this.config = config;
            this.updateChartOptions();
        });
        this._IsProgressSpinner = true;
        this._customerDashboardService.PageOnload().subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);
            this._Branches = <Branch[]>resultJSON.branches;
            this._Customers = <Customer[]>resultJSON.customers;
            this._producttype = resultJSON.producttypes
        });
        this.onChangefilter();
        this.onChangefilterSales();
        this.onChangefilterOutstanding();
        this._IsProgressSpinner = false;
    }

    getProductCategory(event) {
        //9.based on selected Product Type system should filter Product Category
        var selectedProductType = event.value;
        var branchid = parseInt(sessionStorage["currentbranchid"]);
        console.log(selectedProductType);
        if (selectedProductType.length == 0 || selectedProductType == null) {
            this._customerdashboardform.controls["productcategoryid"].reset();
            this._productcategory = [];
            return;
        }
        else {
            this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": branchid || 0 };
            this._customerDashboardService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
                const resultJSON = JSON.parse(JSON.stringify(result));
                console.log(resultJSON);
                this._productcategory = resultJSON.productcategories;
            },
                error => { this._CustomExceptionService.handleError(error); });
        }
    }
    getProduct(event) {
        //10.load Product Name based on selected product category, it contains Product code, Product name
        var selectedProductCategory = event.value;
        var branchid = parseInt(sessionStorage["currentbranchid"]);
        console.log(selectedProductCategory);
        if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
            this._customerdashboardform.reset();
            return;
        }
        else {
            this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": branchid || 0 };
            this._customerDashboardService.getProduct(this._ProductCategoryObj).subscribe((result) => {
                const resultJSON = JSON.parse(JSON.stringify(result));
                console.log(resultJSON);
                this._productname = resultJSON.productsearch;

            },
                error => { this._CustomExceptionService.handleError(error); });
        }
    }

    onChangefilter() {
        var branchid = parseInt(sessionStorage["currentbranchid"]);
        var customerid = this._customerdashboardform.get("customerid").value || 0;
        this.userid = parseInt(sessionStorage["userid"]);
        var userid = this.userid;
        var _fromdate = this._customerdashboardform.get("fromdate").value;
        var _todate = this._customerdashboardform.get("todate").value;
        var productid = this._customerdashboardform.get("productid").value || [0];
        this._customerDashboardService.ProductWiseSales(productid, userid, customerid, branchid, _fromdate, _todate).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);
            this.lableDataProductWise=[]; this.trimlableDataProductWise=[]; this.salesValueProductWise=[];
            this.basicData =[]; 
       
            this.productsalesdetails = resultJSON.productsalesdetails;
            for (let i = 0; i < resultJSON.productsalesdetails.length; i++) {
                this.lableDataProductWise[i] = resultJSON.productsalesdetails[i].PRODUCTNAME;
                this.trimlableDataProductWise[i] = (resultJSON.productsalesdetails[i].PRODUCTNAME).slice(0, 15) + '.';
                this.salesValueProductWise[i] = resultJSON.productsalesdetails[i].SALESVALUE;
                this.basicData = {
                    labels: this.trimlableDataProductWise,
                    datasets: [
                        {
                            label: '',
                            backgroundColor: ['#42A5F5',
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"],
                            data: this.salesValueProductWise
                        }
                    ]
                };
            }
            console.log("Label list", this.trimlableDataProductWise);
            this._IsProgressSpinner = false;
        });

        this._IsProgressSpinner = false; 
    }
    ProductWiseSales(event){
        
        var branchid = event.branchid || 0;
        var customerid = event.customerid || 0;
        this.userid = parseInt(sessionStorage["userid"]);
        var userid = this.userid;
        var _fromdate = this._customerdashboardform.get("fromdate").value;
        var _todate = this._customerdashboardform.get("todate").value;
        var productid = this._customerdashboardform.get("productid").value || [0];
        this._customerDashboardService.ProductWiseSales(productid, userid, customerid, branchid, _fromdate, _todate).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);     
            this.templableDataProductWise=[];  this.temptrimlableDataProductWise=[];  this.tempsalesValueProductWise=[];  this.basicData =[]; 
            this.ProductWiseSalesdetails = resultJSON.productsalesdetails;
            for (let i = 0; i < this.ProductWiseSalesdetails.length; i++) {
                this.templableDataProductWise[i] = this.ProductWiseSalesdetails[i].PRODUCTNAME;
                this.temptrimlableDataProductWise[i] = (this.ProductWiseSalesdetails[i].PRODUCTNAME).slice(0, 15) + '...';
                this.tempsalesValueProductWise[i] = this.ProductWiseSalesdetails[i].SALESVALUE;
                this.basicData = {
                    labels: this.temptrimlableDataProductWise,
                    datasets: [
                        {
                            label: '',
                            backgroundColor: ['#42A5F5',
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"],
                            data: this.tempsalesValueProductWise
                        }
                    ]
                };
            }
            console.log("Label list", this.templableDataProductWise);
            this._IsProgressSpinner = false;
        });

        this._IsProgressSpinner = false;
     
    }
    onChangefilterSales() {
        var branchid = parseInt(sessionStorage["currentbranchid"]);
        var customerid = this._salesdashboardform.get("customerid").value || 0;
        this.userid = parseInt(sessionStorage["userid"]);
        var userid = this.userid;
        var _fromdate = this._customerdashboardform.get("fromdate").value;
        var _todate = this._customerdashboardform.get("todate").value;
        this._customerDashboardService.CustomerMonthWiseSales(this.userid, customerid, branchid, _fromdate, _todate).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);
            this.lableDataSales=[];  this.trimlableDataSales=[]; this.salesValueSales=[];  this.basicData1 =[];
            for (let i = 0; i < resultJSON.productsalesdetails.length; i++) {
                this.lableDataSales[i] = resultJSON.productsalesdetails[i].PRODUCTNAME;
                this.trimlableDataSales[i] = (resultJSON.productsalesdetails[i].MONTH).slice(0, 15) + '...';
                this.salesValueSales[i] = resultJSON.productsalesdetails[i].SALESVALUE;
                // this.lableData[i].slice(0, 10)+'...';
                // alert(this.lableData[i]);
                this.basicData1 = {
                    labels: this.trimlableDataSales,
                    datasets: [
                        {
                            label: '',
                            backgroundColor: ['#42A5F5',
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"],
                            data: this.salesValueSales
                        }
                    ]
                };
            }
        });
    }
    MonthWiseSales(event){
        var branchid = event.branchid || 0;
        var customerid = event.customerid || 0;
        this.userid = parseInt(sessionStorage["userid"]);
        var userid = this.userid;
        var _fromdate = this._customerdashboardform.get("fromdate").value;
        var _todate = this._customerdashboardform.get("todate").value;
        this._customerDashboardService.CustomerMonthWiseSales(this.userid, customerid, branchid, _fromdate, _todate).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);
            this.lableDataSales=[]; this.trimlableDataSales=[]; this.salesValueSales=[]; this.basicData1 =[];
            for (let i = 0; i < resultJSON.productsalesdetails.length; i++) {
                this.lableDataSales[i] = resultJSON.productsalesdetails[i].PRODUCTNAME;
                this.trimlableDataSales[i] = (resultJSON.productsalesdetails[i].MONTH).slice(0, 15) + '...';
                this.salesValueSales[i] = resultJSON.productsalesdetails[i].SALESVALUE;
                // this.lableData[i].slice(0, 10)+'...';
                // alert(this.lableData[i]);
                this.basicData1 = {
                    labels: this.trimlableDataSales,
                    datasets: [
                        {
                            label: '',
                            backgroundColor: ['#42A5F5',
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"],
                            data: this.salesValueSales
                        }
                    ]
                };
            }
        });  
    }
    onChangefilterOutstanding() {
        var branchid = parseInt(sessionStorage["currentbranchid"]);
        var customerid = this._outstandingdashboardform.get("customerid").value || 0;
        this._customerDashboardService.Customerwiseoutstanding(customerid, branchid).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            var pay = resultJSON.productsalesdetails.filter(out => out.TYPE == "PAYABLE");
            var rec = resultJSON.productsalesdetails.filter(out => out.TYPE == "RECEIVABLE");
            console.log(resultJSON);
            this.trimlableData3=[];this.salesValuepay=[];this.salesValuerec=[]; this.lableData3=[]; this.basicData2=[];
            this._IsNoDataOustanding=false;
            this.lableData3=[]; this.trimlableData3=[]; this.salesValuerec=[]; this.salesValuepay=[];  this.basicData2=[];
            for (let i = 0; i < pay.length; i++) {
                this.lableData3[i] = pay[i].PRODUCTNAME;
                this.trimlableData3[i] = (pay[i].CUSTOMERNAME).slice(0, 15) + '...';
                this.salesValuepay[i] = pay[i].BALANCE;
            }
            for (let i = 0; i < rec.length; i++) {

                this.lableData3[i] = rec[i].PRODUCTNAME;
                this.trimlableData3[i] = (rec[i].CUSTOMERNAME).slice(0, 15) + '...';
                this.salesValuerec[i] = rec[i].BALANCE;
            }
            this.basicData2 = {
                labels: this.trimlableData3,
                datasets: [
                    {
                        label: 'Payable',
                        backgroundColor: ['#42A5F5',
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56"],
                        data: this.salesValuepay
                    },
                    {
                        label: 'Receivable',
                        backgroundColor: ['#42A5F5',
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56"],
                        data: this.salesValuerec
                    }
                ]
            };

        });
    }
    OutStandingSales(event){
        var branchid = event.branchid ||0;
        var customerid = event.customerid || 0;
        this._customerDashboardService.Customerwiseoutstanding(customerid, branchid).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            this.trimlableData3=[];this.salesValuepay=[];this.salesValuerec=[]; this.lableData3=[]; this.basicData2=[];
            var pay = resultJSON.productsalesdetails.filter(out => out.TYPE == "PAYABLE");
            var rec = resultJSON.productsalesdetails.filter(out => out.TYPE == "RECEIVABLE");
            console.log(resultJSON);
            for (let i = 0; i < pay.length; i++) {
                this.lableData3[i] = pay[i].PRODUCTNAME;
                this.trimlableData3[i] = (pay[i].CUSTOMERNAME).slice(0, 15) + '...';
                this.salesValuepay[i] = pay[i].BALANCE;
            }
            for (let i = 0; i < rec.length; i++) {

                this.lableData3[i] = rec[i].PRODUCTNAME;
                this.trimlableData3[i] = (rec[i].CUSTOMERNAME).slice(0, 15) + '...';
                this.salesValuerec[i] = rec[i].BALANCE;
            }
            this.basicData2 = {
                labels: this.trimlableData3,
                datasets: [
                    {
                        label: 'Payable',
                        backgroundColor: ['#42A5F5',
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56"],
                        data: this.salesValuepay
                    },
                    {
                        label: 'Receivable',
                        backgroundColor: ['#42A5F5',
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56"],
                        data: this.salesValuerec
                    }
                ]
            };

        });  
    }
    updateChartOptions() {
        if (this.config.dark)
            this.applyDarkTheme();
        else
            this.applyLightTheme();
    }

    applyDarkTheme() {
        this.basicOptions = {
            legend: {
                labels: {
                    fontColor: '#ebedef'
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#ebedef'
                    },
                    gridLines: {
                        color: 'rgba(255,255,255,0.2)'
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: '#ebedef'
                    },
                    gridLines: {
                        color: 'rgba(255,255,255,0.2)'
                    }
                }]
            }
        };
    }

    applyLightTheme() {
        this.basicOptions = {
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#495057'
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: '#495057'
                    }
                }]
            }
        };
    }

    chartchangepws(charttype) {

        if (charttype == "bar") {
            this._Isbarpws = true; this._Islinepws = false; this._Ispiepws = false; this._Isdoughnutpws = false;
        }
        if (charttype == "line") {
            this._Isbarpws = false; this._Islinepws = true; this._Ispiepws = false; this._Isdoughnutpws = false;
        }
        if (charttype == "pie") {
            this._Isbarpws = false; this._Islinepws = false; this._Ispiepws = true; this._Isdoughnutpws = false;
        }
        if (charttype == "doughnut") {
            this._Isbarpws = false; this._Islinepws = false; this._Ispiepws = false; this._Isdoughnutpws = true;
        }
    }
    chartchangesd(charttype1) {

        if (charttype1 == "bar") {
            this._Isbarsd = true; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = false;
        }
        if (charttype1 == "line") {
            this._Isbarsd = false; this._Islinesd = true; this._Ispiesd = false; this._Isdoughnutsd = false;
        }
        if (charttype1 == "pie") {
            this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = true; this._Isdoughnutsd = false;
        }
        if (charttype1 == "doughnut") {
            this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = true;
        }
    }
    chartchangerd(charttype2) {

        if (charttype2 == "bar") {
            this._Isbarrd = true; this._Islinerd = false; this._Ispierd = false; this._Isdoughnutrd = false;
        }
        if (charttype2 == "line") {
            this._Isbarrd = false; this._Islinerd = true; this._Ispierd = false; this._Isdoughnutrd = false;
        }
        if (charttype2 == "pie") {
            this._Isbarrd = false; this._Islinerd = false; this._Ispierd = true; this._Isdoughnutrd = false;
        }
        if (charttype2 == "doughnut") {
            this._Isbarrd = false; this._Islinerd = false; this._Ispierd = false; this._Isdoughnutrd = true;
        }
    }
    chartchangeod(charttype3) {

        if (charttype3 == "bar") {
            this._Isbarod = true; this._Islineod = false; this._Ispieod = false; this._Isdoughnutod = false;
        }
        if (charttype3 == "line") {
            this._Isbarod = false; this._Islineod = true; this._Ispieod = false; this._Isdoughnutod = false;
        }
        if (charttype3 == "pie") {
            this._Isbarod = false; this._Islineod = false; this._Ispieod = true; this._Isdoughnutod = false;
        }
        if (charttype3 == "doughnut") {
            this._Isbarod = false; this._Islineod = false; this._Ispieod = false; this._Isdoughnutod = true;
        }
    }

    filterBranch(event) {
        let filtered: any[] = [];
        let query = (<string>event.query).toLowerCase();
        this.filterBranchs = this._Branches.filter(f => f.branchname.toLowerCase().indexOf(query) > -1) || [];
    }
    filterCustomer(event) {
        let filtered: any[] = [];
        let query = (<string>event.query).toLowerCase();
        this.filterCustomers = this._Customers.filter(f => f.customername.toLowerCase().indexOf(query) > -1) || [];
    }
    OnChangeBranch(event) {
        console.log(event);
        this.branchid = event.branchid;

    }
    OnChangeCustomer(event) {
        console.log(event);
        this.customerid = event.customerid;

    }

}

function obj(obj: any, arg1: (value: any, key: any) => void) {
    throw new Error('Function not implemented.');
}
