<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Numbering Schema</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRiple type="button" icon="pi pi-plus" title="Add"
                                    [disabled]="!_Action._Add" (click)="GoToCreate($event)" 
                                    class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>


                                <!-- <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger "></button> -->
                            </ng-template>

                            <ng-template pTemplate="right">
                                <!-- <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                    (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                    tooltipPosition="bottom"></button> -->
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                    (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                    tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                    class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                    (click)="ExportToPdf()"></button>
                                <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                    (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                    pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                            </ng-template>

                        </p-toolbar>
                        <p-table #dt [value]="_numberingschemasview" [rows]="10" [paginator]="true"
                            [rowsPerPageOptions]="[10,25,50,100]"
                            [globalFilterFields]="['documentname', 'prefix','suffix','numberingschemastatusname']"
                            [rowHover]="true" dataKey="documentname" [(selection)]="selectedrows"
                            (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>

                                    <th pSortableColumn="action" class="text-center" style="width: 120px;">Action
                                        <p-sortIcon field="id">
                                        </p-sortIcon>
                                    </th>
                                    <!-- <th pSortableColumn="s.no" style="width: 50px;">ID<p-sortIcon field="s.no"></p-sortIcon>
                                </th> -->
                                    <th pSortableColumn="documentname">Document <p-sortIcon field="documentname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="prefix">Prefix<p-sortIcon field="prefix"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="suffix">Suffix<p-sortIcon field="suffix"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="numberingschemastatusname">Status <p-sortIcon
                                            field="numberingschemastatusname"></p-sortIcon>
                                    </th>


                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_numberingschemaview>
                                <tr>

                                    <td class="text-center">
                                        <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2"
                                            [disabled]="!_Action._View" (click)="view(_numberingschemaview)"></button>
                                        <!-- on clicking update button in search grid , it Navigates to the update screen -->
                                        <!-- on clicking back button , it Navigates to the search screen -->
                                        <button pButton pRipple icon="las la-pen" title="Edit"
                                            [disabled]="!_Action._Update" class="p-button p-button-success p-mr-2"
                                            (click)="edit(_numberingschemaview)"></button>
                                        <button pButton pRipple icon="pi pi-times" title="Delete"
                                            class="p-button p-button-danger" 
                                            (click)="confirm(_numberingschemaview)"
                                            [disabled]="_numberingschemaview.numberingschemastatus == 'MSC00002' || !_Action._Delete"></button>

                                    </td>
                                    <!-- {{numberingschema.id}}
                                </td> -->
                                    <td>
                                        {{_numberingschemaview.documentname}}
                                    </td>
                                    <td>
                                        {{_numberingschemaview.prefix}}
                                    </td>
                                    <td>
                                        {{_numberingschemaview.suffix}}
                                    </td>
                                    <td>
                                        {{_numberingschemaview.numberingschemastatusname}}
                                    </td>


                                </tr>

                            </ng-template>
                        </p-table>

                    </div>
                </div>
            </form>

            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>