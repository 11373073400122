<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Workshop Summary Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_branchlist" optionLabel="branchname" optionValue="branchid"
                                    [(ngModel)]="branchid" [filter]="true" [virtualScroll]="true" itemSize="30"
                                    (onChange)="OnChangeBranch()" [ngModelOptions]="{standalone: true}">
                                    <ng-template let-account pTemplate="item">
                                        <div
                                            [style]="account.branchstatus == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                            {{account.branchname}}</div>
                                    </ng-template>
                                </p-multiSelect>
                                <label for="producttypeid">Branch</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true" [maxDate]="maxdate" [minDate]="mindate"
                                    dateFormat="dd/mm/yy" (onSelect)="OnChangeBranch()" [monthNavigator]="true"
                                    yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" (onSelect)="OnChangeBranch()" [(ngModel)]="todate"
                                    [showIcon]="true" dateFormat="dd/mm/yy" [maxDate]="maxdate" [minDate]="mindate"
                                    [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_requestTypeList" optionLabel="metasubdescription"
                                    optionValue="metasubid" [(ngModel)]="requesttype" [filter]="true"
                                    [virtualScroll]="true" itemSize="30" (onChange)="GetServiceRequest()"
                                    [ngModelOptions]="{standalone: true}">
                                </p-multiSelect>
                                <label for="stocktype">Request Type</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_serviceReqNoList" optionLabel="servicerequestnumber"
                                    optionValue="servicerequestnumber" [(ngModel)]="servicerequestno" [filter]="true"
                                    [virtualScroll]="true" itemSize="30" [ngModelOptions]="{standalone: true}">
                                </p-multiSelect>
                                <label for="stocktype">Service Req No</label>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Search" icon="pi pi-search" (click)="GenerateReport()"
                                class="p-button-sm p-button-success"></button>
                            <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()"
                                class="p-button-danger"></button>
                        </td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_workshopsummaryreport" [scrollable]="true" scrollHeight="450px" [rows]="500"
                        [paginator]="true" [rowsPerPageOptions]="[100,200,300]"
                        [rowsPerPageOptions]="[500,1000,1500,2000]" [globalFilterFields]="['servicebranchname','reftypename','productorcustomername','servicerequestdate','servicerequestnumber','serviceatname', 'jobcardstatus',
                        'serviceworkorderstatus','deliverystatus']" [rowHover]="true" dataKey="productname"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" pSortableColumn="servicebranchname">Service Branch
                                    <p-sortIcon field="servicebranchname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="reftypename">Request Type
                                    <p-sortIcon field="reftypename">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="productorcustomername">Customer /
                                    Product Name
                                    <p-sortIcon field="productorcustomername">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="servicerequestdate">Ser.Req Date
                                    <p-sortIcon field="servicerequestdate">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="servicerequestnumber">Ser.Req No
                                    <p-sortIcon field="servicerequestnumber">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="servicerequestnumber">Ser.Product Name
                                    <p-sortIcon field="servicerequestnumber">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="servicerequestnumber">Qty
                                    <p-sortIcon field="servicerequestnumber">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="serviceatname">Service At<p-sortIcon
                                        field="serviceatname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="serviceatname">Employee / Supplier Name
                                    <p-sortIcon field="serviceatname">
                                    </p-sortIcon>
                                </th>
                                <th colspan="2" style="text-align: center;width:200px;" pSortableColumn="severityname">
                                    Job Card
                                    Status
                                    <p-sortIcon field="severityname">
                                    </p-sortIcon>
                                </th>
                                <th colspan="2" style="text-align: center;width:200px;" pSortableColumn="stock">Service
                                    Work Order
                                    Status
                                    <p-sortIcon field="stock">
                                    </p-sortIcon>
                                </th>
                                <th colspan="2" style="text-align: center;width:220px;" pSortableColumn="expirydate">
                                    Delivery Status
                                    <p-sortIcon field="expirydate">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                            <tr>
                                <td>
                                    {{_ReportList.servicebranchname}}
                                </td>
                                <td>
                                    {{_ReportList.reftypename}}
                                </td>
                                <td>
                                    {{_ReportList.productorcustomername}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.servicerequestdate |date:'dd/MM/yyyy'}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.servicerequestnumber}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.serviceproductname}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.srquantity}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.serviceatname}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.employeeorsuppliername}}
                                </td>
                                <td colspan="2" style="text-align: center;width: 200px;">
                                    {{_ReportList.jobcardstatusname}}
                                </td>
                                <td colspan="2" style="text-align: center;width:200px;">
                                    {{_ReportList.swostatusname}}
                                </td>
                                <td colspan="2" style="text-align: center;width:220px;">
                                    {{_ReportList.deliverystatusname}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="9"></td>
                                <td style="text-align: center; width: 100px;">
                                    Open = {{OpenJC}}
                                </td>
                                <td style="text-align: center;width: 100px;">
                                    Closed={{ClosedJC}}
                                </td>
                                <td style="text-align: center;width:100px;">
                                    Open = {{OpenSWO}}
                                </td>
                                <td style="text-align: center;width:100px;">
                                    Closed={{ClosedSWO}}
                                </td>
                                <td style="text-align: center;width:120px;">
                                    Not Delivered = {{OpenDel}}
                                </td>
                                <td style="text-align: center;width:100px;">
                                    Delivered={{ClosedDel}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>