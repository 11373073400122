import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MaterialrequestService {
  public  _MaterialReturnRequestService = environment.MaterialReturnRequestService;
  public _httpOptions;
  constructor(private http:HttpClient) { }
  Pageonload(pageonload:object) {
    var Query = this._MaterialReturnRequestService + '/PageOnLoad'
    return this.http.post<{ data: any }>(Query,pageonload);
  }
  PageInitialise(obj: object): Observable<any> {
    var Query = this._MaterialReturnRequestService + '/PageInitialise';
    return this.http.post(Query, obj);
  }
  Create(materialrequest:object) {
    var Query = this._MaterialReturnRequestService + '/Create'
    return this.http.post<{ data: any }>(Query,materialrequest);
  }
}
