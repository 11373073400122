import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { DirectsalesreturnService } from 'src/app/core/Services/sales/directsalesreturn.service';
import { SalesreturnService } from 'src/app/core/Services/sales/salesreturn.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Salesreturnview } from 'src/app/core/Views/Salesreturnview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-directsalesreturn',
  templateUrl: './v-directsalesreturn.component.html',
  styleUrls: ['./v-directsalesreturn.component.css']
})
export class VDirectsalesreturnComponent implements OnInit {

  _salesreturndetails: Salesreturnview[];
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"];
  _roleid = sessionStorage["currentRole"];
  _IsProgressSpinner: boolean = true;
  cols: any[];
  _salesreturnno: string;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  constructor(private fb: FormBuilder, private _SalesReturnService: DirectsalesreturnService,
    private _CustomExceptionService: CustomExceptionService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService, public exportUtility: ExportUtility, private confirmationService: ConfirmationService) {


  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(57);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this.fromdate = new Date();
    this.todate = new Date();
   
    this.cols = [
      { field: 'salesreturnno', header: 'Sales Return No' },
      { field: 'salesreturndate', header: 'Sales Return Date' },
      { field: 'customername', header: 'Customer Name' },
      { field: 'salesinvoiceno', header: 'Sales Invoice No' },
      // { field: 'returnquantity', header: 'Return Quantity' },
      { field: 'statusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this.Fetchall();
   

  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._SalesReturnService.FetchAll(this._userid,this._branchid,this._roleid,fromdate,todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._salesreturndetails = resultJSON.salesreturnviews;
      this._IsProgressSpinner = false;
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  view(event) {
    this.router.navigate(['/cDirectSalesReturn'], { state: { salesreturnno: event.salesreturnno, action: 'view' } });
  }

  GoToCreate(event) {
    this.router.navigate(['/cDirectSalesReturn']);
  }
  exportExcel() {
    let SalesReturnList = this.GetSalesReturnList();
    this.exportUtility.exportExcel(SalesReturnList, 'SalesReturn', 'xlsx');
  }

  ExportToPdf() {
    let SalesReturnList = this.GetSalesReturnList();
    this.exportUtility.ExportToPdf(SalesReturnList, 'SalesReturn.pdf');
  }

  GetSalesReturnList() {
    if (!this._salesreturndetails || !this._salesreturndetails.length)
      return;
    let SalesReturnList = [];
    this._salesreturndetails.forEach(element => {
      let salesreturn: any = {};
      salesreturn["Sales Return No"] = element.salesreturnno;
      salesreturn["Sales Return Date"] = element.salesreturndate;
      salesreturn["Customer Name"] = element.customername;
      salesreturn["Sales Invoice No"] = element.salesinvoiceno;
      // salesreturn["Return Quantity"] = element.returnquantity;
      salesreturn["Status"] = element.statusname;
      salesreturn["Created By"] = element.createdbyname;
      salesreturn["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      SalesReturnList.push(salesreturn);
    });
    return SalesReturnList;
  }
  edit(event) {
    if (event.status == "MSC00045") {
      this.router.navigate(['/vDirectSalesreturn'], { state: { salesreturnno: event.salesreturnno, action: 'edit' } });
    }
    else {
      this._CustomExceptionService.handleWarning("Document Send For Approval!");
    }
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Sales Return <b>"' + event.salesreturnno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._salesreturnno = event.salesreturnno;
    this._IsProgressSpinner = true;
    this._SalesReturnService.Cancel(this._userid, event.salesreturnno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.SalesReturnCancel);
      this._IsProgressSpinner = false;
     this.Fetchall();

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  Print(event) {
    var salesreturnno = event.salesreturnno;
    var branchid = sessionStorage["currentbranchid"];
    this._IsProgressSpinner = true;
    //var mediaType = 'application/pdf';
    this._SalesReturnService.Print(salesreturnno,branchid);
    this._IsProgressSpinner = false;
  }
}
