import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseRoutingModule } from './purchase-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ChipsModule} from 'primeng/chips';
import { FieldsetModule, } from 'primeng/fieldset';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { HttpClientModule } from '@angular/common/http';
import {TabViewModule} from 'primeng/tabview';
import { CRequestforquoteComponent } from './requestforquote/c-requestforquote/c-requestforquote.component';
import { CQuotefinalizeComponent } from './quotefinalize/c-quotefinalize/c-quotefinalize.component';
import { VQuotefinalizeComponent } from './quotefinalize/v-quotefinalize/v-quotefinalize.component';
import { VPurchaseinvoiceComponent } from './purchaseinvoice/v-purchaseinvoice/v-purchaseinvoice.component';
import { CPurchaseinvoiceComponent } from './purchaseinvoice/c-purchaseinvoice/c-purchaseinvoice.component';
import { CPurchasereturnComponent } from './purchasereturn/c-purchasereturn/c-purchasereturn.component';
import { VPurchasereturnComponent } from './purchasereturn/v-purchasereturn/v-purchasereturn.component';
import { VRequestforquoteComponent } from './requestforquote/v-requestforquote/v-requestforquote.component';
import { CDocumentverificationComponent } from './documentverification/c-documentverification/c-documentverification.component';
import { CPurchaseorderComponent } from './Purchaseorder/c-purchaseorder/c-purchaseorder.component';
import { VPurchaseorderComponent } from './Purchaseorder/v-purchaseorder/v-purchaseorder.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';



@NgModule({
  declarations: [
     CRequestforquoteComponent,
     VRequestforquoteComponent,
     CQuotefinalizeComponent, 
     VQuotefinalizeComponent,
     CPurchaseorderComponent,
     VPurchaseorderComponent ,
     VPurchaseinvoiceComponent, CPurchaseinvoiceComponent,
     CPurchasereturnComponent, VPurchasereturnComponent,CDocumentverificationComponent
  ],
  imports: [
    CommonModule,
    PurchaseRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChipsModule,
    FieldsetModule,
    AutoCompleteModule,
    OverlayPanelModule,
    CheckboxModule,
    PasswordModule,
    HttpClientModule,
    TabViewModule,
    ProgressSpinnerModule
  ]
})
export class PurchaseModule { }
