import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//bharat
import { CCustomerComponent } from './Customer/c-customer/c-customer.component';
import { CEmployeeComponent } from './Employee/c-employee/c-employee.component';
import { VEmployeeComponent } from './Employee/v-employee/v-employee.component';
import { CLevelComponent } from './Level/c-level/c-level.component';
import { VLevelComponent } from './Level/v-level/v-level.component';
import { CNumberingSchemaComponent } from './NumberingSchema/c-numbering-schema/c-numbering-schema.component';
import { VNumberingSchemaComponent } from './NumberingSchema/v-numbering-schema/v-numbering-schema.component';
import { CStateComponent } from './State/c-state/c-state.component';
import { VStateComponent } from './State/v-state/v-state.component';


//rahul
import { CBranchComponent } from './branch/c-branch/c-branch.component';
import { VBranchComponent } from './branch/v-branch/v-branch.component';
import { CCountryComponent } from './country/c-country/c-country.component';
import { VCountryComponent } from './country/v-country/v-country.component';
import { CProductCategoryComponent } from './product-category/c-product-category/c-product-category.component';
import { VProductCategoryComponent } from './product-category/v-product-category/v-product-category.component';
import { CSupplierComponent } from './supplier/c-supplier/c-supplier.component';
import { VSupplierComponent } from './supplier/v-supplier/v-supplier.component';
import { CTermsAndConditionComponent } from './terms-and-condition/c-terms-and-condition/c-terms-and-condition.component';
import { VTermsAndConditionComponent } from './terms-and-condition/v-terms-and-condition/v-terms-and-condition.component';
import { CVehicleComponent } from './vehicle/c-vehicle/c-vehicle.component';
import { VVehicleComponent } from './vehicle/v-vehicle/v-vehicle.component';

//rajesh
import { CCityComponent } from '../masters/city/c-city/c-city.component';
// import { CAccountGroupComponent } from './account-group/c-account-group/c-account-group.component';
// import { VAccountGroupComponent } from './account-group/v-account-group/v-account-group.component';
import { CAccountTypeComponent } from './account-type/c-account-type/c-account-type.component';
import { VAccountTypeComponent } from './account-type/v-account-type/v-account-type.component';
import { CBankComponent } from './bank/c-bank/c-bank.component';
import { VBankComponent } from './bank/v-bank/v-bank.component';
import { VCityComponent } from './city/v-city/v-city.component';
import { CCurrencyComponent } from './currency/c-currency/c-currency.component';
import { VCurrencyComponent } from './currency/v-currency/v-currency.component';
import { CPortComponent } from './port/c-port/c-port.component';
import { VPortComponent } from './port/v-port/v-port.component';
import { CProductTypeComponent } from './product-type/c-product-type/c-product-type.component';
import { VProductTypeComponent } from './product-type/v-product-type/v-product-type.component';
import { VProductComponent } from './product/v-product/v-product.component';
import { CTaxComponent } from './tax/c-tax/c-tax.component';
import { VTaxComponent } from './tax/v-tax/v-tax.component';
import { VSchemeComponent } from '../rewards/Scheme/v-scheme/v-scheme.component';
import { CSchemeComponent } from '../rewards/Scheme/c-scheme/c-scheme.component';
import { VOtherchargesComponent } from './othercharges/v-othercharges/v-othercharges.component';
import { COtherchargesComponent } from './othercharges/c-othercharges/c-othercharges.component';
import { CTenderComponent } from './Tender/c-tender/c-tender.component';
import { VTenderComponent } from './Tender/v-tender/v-tender.component';
import { VSmsConfigurationComponent } from './sms-configuration/v-sms-configuration/v-sms-configuration.component';
import { CSmsConfigurationComponent } from './sms-configuration/c-sms-configuration/c-sms-configuration.component';
import { CDefaultCustomerComponent } from './Default Customer/c-default-customer/c-default-customer.component';
import { VDefaultCustomerComponent } from './Default Customer/v-default-customer/v-default-customer.component';
import { CDocumentdescriptionComponent } from './DocumentDescription/c-documentdescription/c-documentdescription.component';
import { VDocumentdescriptionComponent } from './DocumentDescription/v-documentdescription/v-documentdescription.component';
import { VCustomerComponent } from './Customer/v-customer/v-customer.component';
import { CProductComponent } from './product/c-product/c-product.component';
import { VCompetitorComponent } from './competitor/v-competitor/v-competitor.component';
import { CCompetitorComponent } from './competitor/c-competitor/c-competitor.component';
import { CNotificationComponent } from './Notification/c-notification/c-notification.component';
import { VNotificationComponent } from './Notification/v-notification/v-notification.component';
import { CShippingLineComponent } from './shipping-line/c-shipping-line/c-shipping-line.component';
import { VShippingLineComponent } from './shipping-line/v-shipping-line/v-shipping-line.component';
import { CBrandComponent } from './brand/c-brand/c-brand.component';
import { VBrandComponent } from './brand/v-brand/v-brand.component';
import { SmsLogComponent } from './sms-log/sms-log.component';
import { CCategoryComponent } from './category/c-category/c-category.component';
import { VCategoryComponent } from './category/v-category/v-category.component';
import { CSubcategoryComponent } from './subcategory/c-subcategory/c-subcategory.component';
import { VSubcategoryComponent } from './subcategory/v-subcategory/v-subcategory.component';


const routes: Routes = [
  //bhrat
  { path: 'cState', component: CStateComponent },
  { path: 'vState', component: VStateComponent },
  { path: 'cLevel', component: CLevelComponent },
  { path: 'vLevel', component: VLevelComponent },
  { path: 'cNumberingschema', component: CNumberingSchemaComponent },
  { path: 'vNumberingschema', component: VNumberingSchemaComponent },
  { path: 'cEmployee', component: CEmployeeComponent },
  { path: 'vEmployee', component: VEmployeeComponent },
  { path: 'cCustomer', component: CCustomerComponent },
  { path: 'vCustomer', component: VCustomerComponent },
  //rahul
  { path: 'cCountry', component: CCountryComponent },
  { path: 'vCountry', component: VCountryComponent },
  { path: 'cTax', component: CTaxComponent },
  { path: 'vTax', component: VTaxComponent },
  { path: 'cBranch', component: CBranchComponent },
  { path: 'vBranch', component: VBranchComponent },
  { path: 'cProductCategory', component: CProductCategoryComponent },
  { path: 'vProductCategory', component: VProductCategoryComponent },
  { path: 'cSupplier', component: CSupplierComponent },
  { path: 'vSupplier', component: VSupplierComponent },
  { path: 'cTermsAndCondition', component: CTermsAndConditionComponent },
  { path: 'vTermsAndCondition', component: VTermsAndConditionComponent },
  { path: 'cVehicle', component: CVehicleComponent },
  { path: 'vVehicle', component: VVehicleComponent },
  //rajesh
  { path: 'cCity', component: CCityComponent },
  { path: 'vCity', component: VCityComponent },
  { path: 'cCurrency', component: CCurrencyComponent },
  { path: 'vCurrency', component: VCurrencyComponent },
  { path: 'cAccountType', component: CAccountTypeComponent },
  { path: 'vAccountType', component: VAccountTypeComponent },
  //  {path:'cAccountGroup',component:CAccountGroupComponent},
  //  {path:'vAccountGroup',component:VAccountGroupComponent},
  { path: 'cBank', component: CBankComponent },
  { path: 'vBank', component: VBankComponent },
  { path: 'cProductType', component: CProductTypeComponent },
  { path: 'vProductType', component: VProductTypeComponent },
  { path: 'cPort', component: CPortComponent },
  { path: 'vPort', component: VPortComponent },
  { path: 'cProduct', component: CProductComponent },
  { path: 'vProduct', component: VProductComponent },
  { path: 'cScheme', component: CSchemeComponent },
  { path: 'vScheme', component: VSchemeComponent },
  { path: 'vOtherCharges', component: VOtherchargesComponent },
  { path: 'cOtherCharges', component: COtherchargesComponent },
  //Deepak
  { path: 'cTender', component: CTenderComponent },
  { path: 'vTender', component: VTenderComponent },
  { path: 'vSmsConfiguration', component: VSmsConfigurationComponent },
  { path: 'cSmsConfiguration', component: CSmsConfigurationComponent },
  { path: 'vDefaultCustomer', component: VDefaultCustomerComponent },
  { path: 'cDefaultCustomer', component: CDefaultCustomerComponent },
  { path: 'cdocumentdescription', component: CDocumentdescriptionComponent },
  { path: 'vdocumentdescription', component: VDocumentdescriptionComponent },
  { path: 'vCompetitor', component: VCompetitorComponent },
  { path: 'cCompetitor', component: CCompetitorComponent },
  { path: 'cNotification', component: CNotificationComponent },
  { path: 'vNotification', component: VNotificationComponent },
  { path: 'cShippingline', component: CShippingLineComponent },
  { path: 'vShippingline', component: VShippingLineComponent },
  { path: 'cBrand', component: CBrandComponent },
  { path: 'vBrand', component: VBrandComponent },
  { path: 'SmsLog', component: SmsLogComponent },
  { path: 'cCategory', component: CCategoryComponent},
  { path: 'vCategory', component: VCategoryComponent},
  { path: 'cSubCategory', component: CSubcategoryComponent},
  { path: 'vSubCategory', component: VSubcategoryComponent}


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MastersRoutingModule { }
