import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptPendingDeliveryService {

  public _Action: IAction;
  public _httpOptions;
  public _RptPendingDeliveryService = environment.RptPendingDeliveryService;
  token: any;
  header: any;
  
  constructor(private http: HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnload(branchwise: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptPendingDeliveryService + '/PageInitialize';

    return this.http.post<{ data: any }>(Query, branchwise, { headers });
  }
  Getpendinglists(branchwise: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptPendingDeliveryService + '/Getpendinglists';

    return this.http.post<{ data: any }>(Query, branchwise, { headers });
  }
  Getproductwisependinglists(branchwise: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptPendingDeliveryService + '/Getproductwisependinglists';

    return this.http.post<{ data: any }>(Query, branchwise, { headers });
  }


}
