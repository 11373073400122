import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomExceptionService } from './core/errorHandlers/custom-exception.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Bhola';
  router: boolean=false;
  visibility:boolean= false;
  loginVisible:boolean =true;
  Firstlogin: boolean = false;

  constructor(private _router: Router,private activatedRoute:ActivatedRoute,private _CustomExceptionService:CustomExceptionService){

    }
    
    ngOnInit() {
     
    }

    onOpen(visible:any)
    {
      this.visibility = visible;
      this.loginVisible = !(visible);
    if (sessionStorage["passwordlength"] < 7) {
      this._CustomExceptionService.handleError("Your Password is too weak,so Please set the strong password");
      this.Firstlogin = true;
    }
  }
}
