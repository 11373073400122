export class Stockallocationgetproductdetail {
    reservedetailid: number;
    reserveid: number;
    tobranch: number;
    tobranchname: string;
    productid: number;
    productname: string;
    variantid: number;
    variantdescription: string;
    quantity: number;
    status: boolean;
    statusremarks: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    stock:number;
}