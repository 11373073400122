export class Customerslabwisediscount {
    companyid: number;
    branchid: number;
    customerslabid: number;
    slabwisecode : string;
    customerid: number;
    minimumvalue: number;
    maximumvalue: number;
    discountpercentage: number;
    effectivefrom: Date ;
    effectiveto: Date;
    recordstatus: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}

