<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Scheme</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" [disabled]="!_Action._Add" icon="pi pi-plus" title="Add"
                                class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>


                        </ng-template>

                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_schemeviews" [rows]="10" [columns]="cols" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['schemename', 'productname','discountpercentage','rewardpoints', 'freeproductname','maximumqty', 'effectivefrom','effectiveto','schemestatusname']"
                        [rowHover]="true" dataKey="schemeid" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>

                                <th style="text-align: center;width: 125px;" pSortableColumn="Action">Action <p-sortIcon
                                        field="name"></p-sortIcon>
                                <th pSortableColumn="schemename">Scheme Name<p-sortIcon field="schemename"></p-sortIcon>
                                </th>
                                <th pSortableColumn="effectivefrom">Effective From<p-sortIcon
                                        field="effectivefrom"></p-sortIcon>
                                </th>
                                <th pSortableColumn="effectiveto">Effective To<p-sortIcon
                                        field="effectiveto"></p-sortIcon>
                                </th>
                                <th pSortableColumn="schemestatusname">Status<p-sortIcon
                                        field="schemestatusname"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_schemeview>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="!_Action._View" 
                                        (click)="view(_schemeview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit"
                                        class="p-button p-button-success p-mr-2" 
                                        [disabled]="!_Action._Update" (click)="edit(_schemeview)"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Delete"
                                        [disabled]="!_Action._Delete" class="p-button p-button-danger"
                                        (click)="confirm(_schemeview)"></button>
                                </td>
                                <td>
                                    {{_schemeview.schemename}}
                                </td>

                                <td>
                                    {{_schemeview.effectivefrom |date:'dd/MM/yyyy hh:mm a'}}
                                </td>
                                <td>
                                    {{_schemeview.effectiveto |date:'dd/MM/yyyy hh:mm a'}}
                                </td>
                                <td>
                                    {{_schemeview.schemestatusname}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>