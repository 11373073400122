import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Branch } from 'src/app/core/Models/Branch';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Stockallocation } from 'src/app/core/Models/Stockallocation';
import { Stockallocationdetail } from 'src/app/core/Models/Stockallocationdetail ';
import { StockallocationService } from 'src/app/core/Services/inventory/stockallocation.service';
import { vProductName, vQuantity, vreservationdate } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
//Debug
import * as ace from "ace-builds";
import { Stockallocationgetproductdetail } from 'src/app/core/Models/Stockallocationgetproductdetail';
import { StocktransferorderService } from 'src/app/core/Services/inventory/stocktransferorder.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { KeyPress } from 'src/assets/js/KeyPress';

@Component({
  selector: 'app-c-stockallocation',
  templateUrl: './c-stockallocation.component.html',
  styleUrls: ['./c-stockallocation.component.css']
})
export class CStockallocationComponent implements OnInit {
  _producttype: Producttype[] = [];
  _productcategory: Productcategory[] = [];
  _submitted = false;
  _productname: Productheader[] = [];
  _stockallocationform: FormGroup;
  _stockForm: FormGroup;
  _productsubmitted = false;
  _variantdetails: Productvariant[] = [];
  _branches: Branch[];
  _reservationid: number;
  _stockdetailform: FormGroup;
  isDisabledsave = true;
  isDisablereset = true;
  _StockallocationObj: any;
  _stockallocation: Stockallocation;
  _productdetail: Stockallocationgetproductdetail;
  _productdetails: Stockallocationgetproductdetail[] = [];
  _stockproductdetails: {
    productid: number,
    productname: string,
    variantid: number,
    variantname: string,
    stock: number,
    temp_stock: number
  }[] = [];
  selectedProductDetailsRows: Stockallocationgetproductdetail[] = [];
  isDisabledaddproduct = true;
  isDisabledclearproduct = true;
  _action: string = "create";
  _frombranch: Branch[] = [];
  isDisabledremoveproduct = true;
  isDisabledproductcategory = true;
  _IsProgressSpinner: boolean = true;
  _branchname = sessionStorage["currentbranchname"]
  _branchid = sessionStorage["currentbranchid"];
  isDisabledselectall = false;
  isDisabledquantity = false;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _ProductHeaderObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  _Action: IAction;
  quantity: any;
  salesuom: any;
  mindate: Date;
  maxdate: Date;

  //Debug
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private _StockallocationService: StockallocationService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private _StockTransferOrderService: StocktransferorderService,
    private _hotkeysService: HotkeysService,
    public keypress: KeyPress) {
    this._reservationid = history.state?.reserveid ? history.state?.reserveid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeStockallocationForm() {
    this._stockallocationform = this.fb.group({
      companyid: [],
      branchid: [],
      reserveid: [],
      reserveno: [],
      reservedate: ['', vreservationdate],
      frombranch: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      deliverystatus: [],
      stockallocationdetails: [],
      status: [],
      transactionincompleted: [1]

    });
    // this._stockallocationform.get("reservedate").setValue(new Date());

    if (sessionStorage['Environmentenddate'] == "null" ) {
      this._stockallocationform.get("reservedate").setValue(new Date);
    }
    else {
      let date = new Date(sessionStorage['Environmentenddate'])
      this._stockallocationform.get("reservedate").setValue(date);
    }

  }
  InitializestockFrom() {
    this._stockForm = this.fb.group({
      frombranchname: []
    })
    this._stockForm.controls['frombranchname'].setValue(this._branchname);
  }
  InitializeStockdetailForm() {
    this._stockdetailform = this.fb.group({
      tobranch: [],
      producttypeid: [],
      productcategoryid: [],
      productid: [],
      variantid: []
    });
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(89);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeStockallocationForm();
    this.InitializeStockdetailForm();
    this.InitializestockFrom();

    this._IsProgressSpinner = true;
    this._StockallocationService.PageOnLoad(this._reservationid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //console.log(resultJSON);

      // this._stockallocationform.get("reservedate").setValue(new Date);
      this._stockForm.controls['frombranchname'].setValue(this._branchname);
      // this._stockallocationform.get("frombranch").setValue(parseInt(sessionStorage["BranchID"]))
      this._branches = resultJSON.fromBranches;
      this._producttype = resultJSON.producttypes;
      // this._productdetails = resultJSON.stockallocationgetproductdetails;
      // this._stockproductdetails = resultJSON.stockallocationgetproductdetails;
      this._IsProgressSpinner = false;
      // var branchname = (this._frombranch || []).filter(f => f.branchid == parseInt(sessionStorage["BranchID"]))[0].branchname;
      // this._stockallocationform.get("frombranchname").setValue(branchname);
      // this._StockallocationService.getProductCategory(parseInt(sessionStorage["BranchID"])).subscribe((result) => {
      //   const resultJSON = JSON.parse(JSON.stringify(result));
      //   this._stockallocationform.get("frombranch").setValue(parseInt(sessionStorage["BranchID"]))
      //   this._productcategory = resultJSON.stockallocationgetproductcategories;
      // });
      if (this._action == 'create') {
        this.isDisabledproductcategory = false;
        this.isDisabledsave = false;
        this.isDisablereset = false;

      }
      if (this._action == 'view') {
        this.isDisabledselectall = true;
        this._stockdetailform.disable();
      }
      if (this._action == 'edit') {
        this.isDisabledselectall = true;
      }
      if (this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        //console.log(updateJSON);
        updateJSON.stockallocations[0].reservedate = new Date(updateJSON.stockallocations[0].reservedate);
        this._stockallocationform.setValue(updateJSON.stockallocations[0]);
        //this._stockallocationform.setValue(this.);
        // updateJSON.stockallocations[0].reserveno = new Date(updateJSON.stockallocations[0].reserveno);
        // updateJSON.stockallocations[0].reserveid = new Date(updateJSON.stockallocations[0].reserveid);
        this._productdetails = updateJSON.stockallocationdetaisviews;
        this.selectedProductDetailsRows = updateJSON.stockallocationdetaisviews;
        // var ProductDetails = updateJSON.stockallocationgetproductdetails;
        // Object.keys(updateJSON.stockallocationdetaisviews).map((Index) => {
        //   var index = ProductDetails.findIndex(obj => obj.productid == updateJSON.stockallocationdetaisviews[Index].productid);
        //   ProductDetails[index].variantid = updateJSON.stockallocationdetaisviews[Index].variantid;
        //   // ProductDetails[index].tobranch = updateJSON.stockallocationdetails[Index].tobranch;
        // });

        // this._productdetails = ProductDetails;
        this.isDisabledproductcategory = true;
        this.isDisabledremoveproduct = true;
        this._stockallocationform.disable();
        this._stockdetailform.disable();
      }

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }



  RemoveProductDetail(pdetail: Stockallocationgetproductdetail) {
    if (this._productdetails.length == 2) {
      var index = this._productdetails.indexOf(pdetail);
      this._productdetails.splice(index, 1);
    }
  }
  onRowSelect(event) {
    //console.log("eventfired");
    //console.log(event.data);
    //console.log(this.selectedProductDetailsRows);
  }
  onRowUnselect(event) {
    //console.log("eventUnfired");
    //console.log(event.data);
    //console.log(this.selectedProductDetailsRows);
  }
  SelectAll(event) {
    //console.log(event.data);
  }
  getBranchProduct(event) {
    var selectedBranch = event.value;
    if (selectedBranch.length == 0 || selectedBranch == null) {
      this._productdetails = [];
      //this.GroupProductForStock(this._productdetails);
      return;
    }
    else {
      this._StockallocationService.getBranchProduct(selectedBranch, this._branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        //console.log(resultJSON);
        this.selectedProductDetailsRows = [];
        this._productdetails = resultJSON.stockallocationgetproductdetails;
        // this._stockproductdetails = resultJSON.stockallocationgetproductdetails;
        this._productdetails = (this._productdetails || []).filter(f => f.tobranch != this._branchid);
        //this.GroupProductForStock(this._productdetails);
      }, error => { this._CustomExceptionService.handleError(error); });
    }

  }

  getProductCategory(event) {
    //9.based on selected Product Type system should filter Product Category
    var selectedProductType = event.value;
    //console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._stockdetailform.controls["productcategoryid"].reset();
      this._productcategory = [];
      return;
    }
    else {
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._StockallocationService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        //console.log(resultJSON);
        this._stockdetailform.controls["tobranch"].reset();
        this._productdetails = [];
        this._productcategory = resultJSON.productcategories;
      },
        error => { this._CustomExceptionService.handleError(error); });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    //console.log(selectedProductCategory);
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._stockdetailform.reset();
      return;
    }
    else {
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._StockallocationService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        //console.log(resultJSON);

        this._productname = resultJSON.productsearch;

      },
        error => { this._CustomExceptionService.handleError(error); });
    }
  }
  getProductSearch(event) {
    var selectedProduct = event.value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._productdetails = [];
      //this.GroupProductForStock(this._productdetails);
      return;
    }
    else {
      this._ProductHeaderObj = { "Productheader": selectedProduct || [], "Branchid": parseInt(sessionStorage["currentbranchid"]) }
      this._StockallocationService.getProductSearch(this._ProductHeaderObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        //console.log(resultJSON);
        this.selectedProductDetailsRows = [];
        this._productdetails = resultJSON.stockallocationgetproductdetails;
        this._productdetails = (this._productdetails || []).filter(f => f.tobranch != this._branchid);
        //this.GroupProductForStock(this._productdetails);
      },
        error => { this._CustomExceptionService.handleError(error); });
    }
  }
  onchangequantity(row: any, event) {

    if (row.quantity == null) {
      row.quantity = 0;
    }
    if (row.quantity > row.stock) {
      this._CustomExceptionService.handleWarning("Quantity should not greater than stock!");
      row.quantity = 0;
    }
  }

  onSave() {
    this._submitted = true;

    let quantityIsZero = false;
    this.selectedProductDetailsRows.forEach(element => {
      if (element.quantity == 0) {
        quantityIsZero = true;
      }
    });

    if (quantityIsZero) {
      this._CustomExceptionService.handleWarning("Please Enter the Quantity");
      return;
  }
    //Product Details
    var l_ProductDetails: Stockallocationgetproductdetail[] = [];
    Object.keys(this.selectedProductDetailsRows).map((Index) => {
      var currentRow = this.selectedProductDetailsRows[Index];
      var l_productdetails = new Stockallocationgetproductdetail();
      l_productdetails.reservedetailid = 0;
      l_productdetails.reserveid = 0;
      l_productdetails.tobranch = currentRow.tobranch;
      l_productdetails.productname = currentRow.productname;
      l_productdetails.variantdescription = currentRow.variantdescription;
      l_productdetails.tobranchname = currentRow.tobranchname;
      l_productdetails.productid = currentRow.productid;
      l_productdetails.variantid = currentRow.variantid;
      l_productdetails.quantity = currentRow.quantity;
      l_productdetails.createdby = sessionStorage["userid"];
      l_productdetails.createdon = new Date();
      l_productdetails.modifiedby = sessionStorage["userid"];
      l_productdetails.modifiedon = new Date();
      l_ProductDetails.push(l_productdetails);
    })
    // this._stockproductdetails = l_ProductDetails;
    if (this.selectedProductDetailsRows.length == 0) {
      this._CustomExceptionService.handleWarning("Please select Atleast one row in a grid.");
      return;
    }

    if (this._stockallocationform.valid && this.IsStockValid()) {
      if (this._action == 'create') {

        var ReserveDate = this._stockallocationform.get("reservedate").value;
        var datePipe = new DatePipe("en-US");
        var pReserveDate = datePipe.transform(ReserveDate, 'yyyy-MM-dd');
        //For Model and Form Mapping
        this._stockallocation = this._stockallocationform.value;
        this._stockallocation.reservedate = (new Date(pReserveDate));
        this._stockallocation.reserveid = 0;
        // this._productdetails[0].createdby = parseInt(sessionStorage["userid"]);
        this._stockallocation.createdon = new Date();
        this._stockallocation.createdby = sessionStorage["userid"];
        this._stockallocation.modifiedon = new Date();
        this._stockallocation.modifiedby = sessionStorage["userid"];
        this._stockallocation.accountingyear = new Date().getFullYear();
        this._stockallocation.frombranch = sessionStorage["BranchID"];
        this._stockallocation.branchid = parseInt(sessionStorage["BranchID"]);
        this._stockallocation.companyid = 0;
        // this.onEd();

        this._StockallocationObj = { "Stockallocation": this._stockallocation, "Stockallocationdetail": l_ProductDetails };
        this._IsProgressSpinner = true;
        this._StockallocationService.create(this._StockallocationObj).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          //console.log(resutJSON);
          this._IsProgressSpinner = false;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.selectedProductDetailsRows = [];
            this.reset(null);
            this._IsProgressSpinner = false;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },

          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
    else {
      this._CustomExceptionService.handleError(usererrors.StockProductDetails);
    }

  }



  // getProduct(event) {
  //   var selectedProductCategory = event.value;
  //   //console.log(selectedProductCategory);
  //   this._StockallocationService.getProduct(selectedProductCategory).subscribe((result) => {
  //     const resultJSON = JSON.parse(JSON.stringify(result));
  //     this._productname = resultJSON.products;
  //   });
  // }
  reset(event) {
    this._submitted = true;
    this._stockdetailform.reset();
    this._stockallocationform.reset();
    this._productdetails = [];
    for (var i = 0; i < this._productdetails.length; i++) {
      this._productdetails[i].quantity = 0;
    }
    this.InitializeStockallocationForm();
    this.InitializeStockdetailForm();

    this._submitted = false;
  }

  //Search Screen Call:
  goBack(event) {
    this._router.navigate(['/vstockallocation']);
  }
  //Debug
  // onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._StockallocationObj;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }

  IsStockValid() {
    this._stockproductdetails = [];
    this.selectedProductDetailsRows.forEach(pd => {
      let isExists = this._stockproductdetails.some(s => s.productid == pd.productid && s.variantid == pd.variantid);
      if (!isExists) {
        let product = {
          productid: pd.productid,
          productname: pd.productname,
          variantid: pd.variantid,
          variantname: pd.variantdescription,
          stock: pd.stock,
          temp_stock: pd.stock
        };
        this._stockproductdetails.push(product);
      }
    });

    for (let i = 0; i < this._stockproductdetails.length; i++) {
      let stockProduct = this._stockproductdetails[i];
      let sumQuantity = 0;
      for (let j = 0; j < this.selectedProductDetailsRows.length; j++) {
        let stockSelectedProduct = this.selectedProductDetailsRows[j];
        if (stockProduct.productid == stockSelectedProduct.productid && stockProduct.variantid == stockSelectedProduct.variantid) {
          sumQuantity += stockSelectedProduct.quantity;
        }
      }
      let errorMsg = "";
      if (sumQuantity > stockProduct.stock) {
        if (stockProduct.variantname) {
          errorMsg = "Product Name: " + stockProduct.productname + " with variant : " + stockProduct.variantname + " has available stock (" + stockProduct.stock + ") but entered (" + sumQuantity + ")"
        } else {
          errorMsg = "Product Name: " + stockProduct.productname + " has available stock (" + stockProduct.stock + ") but entered (" + sumQuantity + ")"
        }
        this._CustomExceptionService.handleWarning(errorMsg);
        return false;
      }
    }

    return true;

  }


  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}
