import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Pricecomparison } from 'src/app/core/Models/Pricecomparison';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { PricecomparisonService } from 'src/app/core/Services/sales/pricecomparison.service';
import { vCurrencySelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Level } from 'src/app/core/Models/Level';
import { Competitor } from 'src/app/core/Models/Competitor';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
	selector: 'app-c-pricecomparison',
	templateUrl: './c-pricecomparison.component.html',
	styleUrls: ['./c-pricecomparison.component.css']
})
export class CPricecomparisonComponent implements OnInit {
	_pricecomparisonform: FormGroup;
	_pricecomparisontypeform: FormGroup
	_Pricecomparison: Pricecomparison;
	_ProductTypes: Producttype;
	_ProductCategories: Productcategory[];
	_ProductNames: Productoverallview[];
	_TempProductSearch: Productoverallview[];
	_Currencies: Currency[] = [];
	_Levels: Level[] = [];
	_action: string = "create";
	selectedrows: any;
	_currencyname: string;
	_Levelid: number;
	isDisableproducttype = false;
	isDisablecategory = false;
	filteredProducts: any[];
	_competitors: Competitor[] = [];
	_competitor: Competitor;
	_pricecomparisondetails: any[] = [];
	_pricecomparisondetail: Pricecomparison;
	_levelid = sessionStorage["levelid"];
	_pricecomparisonid: number;
	_branchid = sessionStorage["currentbranchid"];
	_submitted = false;
	_PricecomparisonOBJ: any;
	pricecomparisonobj: any;
	_IsProgressSpinner: boolean = true;
	isDisabledSave = false;
	isDisabledClear = false;
	productcatid: any[] = [];
	producttypeid: any[] = [];
	_ProductTypeObj: any;
	_ProductCategoryObj: any;
	_ProductPriceObj: any;
	productid: any[] = [];
	_Action:IAction;

	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	constructor(
		private utility: CommonUtilities,
		private fb: FormBuilder,
		private _router: Router,
		private _CustomExceptionService: CustomExceptionService,
		private _PricecomparisonService: PricecomparisonService,
		private _hotkeysService: HotkeysService,
		private _AccessRightsService : AccessRightsService

	) {
		this._pricecomparisonid = history.state?.pricecomparisonid ? history.state?.pricecomparisonid : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this.HotKeyIntegration();
	}
	InitializeForm() {
		this._pricecomparisonform = this.fb.group({
			pricecomparisonid: [],
			productid: [],
			c1name: [],
			c1unitprice: [],
			c2name: [],
			c2unitprice: [],
			c3name: [],
			c3unitprice: [],
			c4name: [],
			c4unitprice: [],
			currencyreferenceid: [],
			currencyid: ['', vCurrencySelect],
			accountingyear: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			levelid: [],
		});
		this._pricecomparisontypeform = this.fb.group({
			productcategoryid: [],
			producttypeid: [],
			currencyid: []

		});
		this._pricecomparisonform.controls['currencyid'].setValue(1);
		this._pricecomparisonform.controls['levelid'].setValue(parseInt(sessionStorage["levelid"]));
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(60);
		this.utility.pageLoadScript();
		this.InitializeForm();
		this._IsProgressSpinner = true;
		this._PricecomparisonService.PageOnLoad(this._pricecomparisonid, parseInt(this._levelid), this._branchid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._ProductTypes = resultJSON.producttypes;
			this._competitors = resultJSON.competitors;
			this._Currencies = resultJSON.currencies;
			this._Levels = resultJSON.levels;
			this._IsProgressSpinner = false;
			if (this._action == 'edit' || this._action == 'view') {
				this._ProductTypes = resultJSON.producttypes;
				this._ProductCategories = resultJSON.productCategoyList;
				this._ProductNames = resultJSON.pricecomparisonviews;
				this.producttypeid = [];
				this.producttypeid.push(resultJSON.pricecomparisonviews[0].producttypeid);
				this.productcatid = [];
				this.productcatid.push(resultJSON.pricecomparisonviews[0].productcategoryid);
				this.productid = [];
				this.productid.push(resultJSON.pricecomparisonviews[0].productid)
				this._pricecomparisonform.controls['levelid'].setValue(resultJSON.pricecomparisonviews[0].levelid)
				this._pricecomparisontypeform.controls['producttypeid'].setValue(this.producttypeid);
				this._pricecomparisontypeform.controls['productcategoryid'].setValue(this.productcatid);
				this._pricecomparisonform.controls['productid'].setValue(this.productid);
				this._pricecomparisonform.controls['currencyid'].setValue(resultJSON.pricecomparisonviews[0].currencyid)
				this._pricecomparisontypeform.get("producttypeid").disable();
				this._pricecomparisontypeform.get("productcategoryid").disable();
				this._pricecomparisonform.get("productid").disable();
				this._pricecomparisontypeform.get("currencyid").disable();
				this._pricecomparisondetails = resultJSON.pricecomparisonviews;
				this.pricecomparisonobj = (this._ProductNames || []).find(f => f.productid == resultJSON.pricecomparisonviews[0].productid);
				this.isDisabledClear = true;
			}
			if (this._action == 'view') {
				this.isDisableproducttype = true;
				this.isDisablecategory = true;
				this._pricecomparisonform.disable();
				this._pricecomparisontypeform.disable();
				this.isDisabledSave = true;
				this.isDisabledClear = true;
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	OnCurrencychange(event) {
		var currencyid = this._pricecomparisonform.get('currencyid').value;
		this._currencyname = (this._Currencies || []).filter(f => f.currencyid == currencyid)[0].currencyname;
		for (var i in this._pricecomparisondetails) {
			this._pricecomparisondetails[i].bholaprice = this._pricecomparisondetails[i][this._currencyname.toLowerCase()];
		}
	}
	GetProductCategory(event) {
		var selectedProductType = event.value;
		if (selectedProductType.length == 0 || selectedProductType == null) {
			this._pricecomparisontypeform.controls["productcategoryid"].reset();
			this._ProductCategories = [];
			return;
		} else {
			this._IsProgressSpinner = true;
			this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": this._branchid || 0 };
			this._PricecomparisonService.GetProductCategory(this._ProductTypeObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._ProductCategories = resultJSON.productcategories;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	GetProductName(event) {
		var selectedProductCategory = event.value;
		this._ProductNames = this._TempProductSearch;
		if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
			this._pricecomparisontypeform.reset();
			return;
		} else {
			this._IsProgressSpinner = true;
			this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": this._branchid || 0 };
			this._PricecomparisonService.GetProductName(this._ProductCategoryObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._ProductNames = <Productoverallview[]>resultJSON.productsearch || [];
				this._TempProductSearch = <Productoverallview[]>resultJSON.productsearch || [];
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	GetProductPrice(event) {
		var Product = this.productid;
		var _levelid = this._pricecomparisonform.get("levelid").value || sessionStorage["levelid"]
		this._IsProgressSpinner = true;
		this._ProductPriceObj = {
			"Product": Product || [],
			"branchid": parseInt(sessionStorage["currentbranchid"]),
			"Levelid": _levelid
		};
		this._PricecomparisonService.GetProductPrice(this._ProductPriceObj).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._ProductTypes = resultJSON.producttypes;
			this._ProductCategories = resultJSON.productcategories;
			this._pricecomparisondetails = resultJSON.salesproductprice;
			this.OnCurrencychange(event);
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	onSave() {
		this._submitted = true;
		if (this._pricecomparisonform.valid) {
			for (let i = 0; i < this._pricecomparisondetails.length; i++) {
				const x = this._pricecomparisondetails[i];
				if (!x.c1id) {
					this._CustomExceptionService.handleWarning("Please Select Compettitor 1")
					return
				}
				if (x.c1id && (x.c1id == x.c2id || x.c1id == x.c3id || x.c1id == x.c4id)) {
					this._CustomExceptionService.handleWarning("this Competitor has already selected")
					return
				}
				if (x.c2id && (x.c2id == x.c1id || x.c2id == x.c3id || x.c2id == x.c4id)) {
					this._CustomExceptionService.handleWarning("this Competitor has already selected")
					return
				}
				if (x.c3id && (x.c3id == x.c4id || x.c2id == x.c3id || x.c1id == x.c3id)) {
					this._CustomExceptionService.handleWarning("this Competitor has already selected")
					return
				}
				if (x.c4id && (x.c1id == x.c4id || x.c2id == x.c4id || x.c3id == x.c4id)) {
					this._CustomExceptionService.handleWarning("this Competitor has already selected")
					return
				}
			}
			if (this._action == 'create') {
				for (var i in this._pricecomparisondetails) {
					this._pricecomparisondetails[i].pricecomparisonid = 0;
					this._pricecomparisondetails[i].levelid = this._pricecomparisonform.get("levelid").value;
					this._pricecomparisondetails[i].currencyid = this._pricecomparisonform.get("currencyid").value;
					this._pricecomparisondetails[i].currencyreferenceid = 0;
					this._pricecomparisondetails[i].createdon = new Date();
					this._pricecomparisondetails[i].createdby = sessionStorage["userid"];
				}
				this._PricecomparisonOBJ = { "Pricecomparison": this._pricecomparisondetails };
				this._IsProgressSpinner = true;
				this._PricecomparisonService.create(this._PricecomparisonOBJ).subscribe((result) => {
					const resutJSON = JSON.parse(JSON.stringify(result));
					this._IsProgressSpinner = false;
					if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
						this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
						this.reset(null);
					} else { this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message); }
				}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			} else if (this._action == 'edit') {
				this._IsProgressSpinner = true;
				for (var i in this._pricecomparisondetails) {
					this._pricecomparisondetails[i].pricecomparisonid = 0;
					this._pricecomparisondetails[i].levelid = this._pricecomparisonform.get("levelid").value;
					this._pricecomparisondetails[i].currencyid = this._pricecomparisonform.get("currencyid").value;
					this._pricecomparisondetails[i].currencyreferenceid = 0;
					this._pricecomparisondetails[i].modifiedon = new Date();
					this._pricecomparisondetails[i].modifiedby = sessionStorage["userid"];
				}
				this._PricecomparisonOBJ = {
					"Pricecomparison": this._pricecomparisondetails
				};
				this._PricecomparisonService.edit(this._PricecomparisonOBJ).subscribe((result) => {
					const resutJSON = JSON.parse(JSON.stringify(result));
					this._IsProgressSpinner = false;
					if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
						this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
					} else { this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message); }
				}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
		}
	}
	goBack(event) {
		this._router.navigate(['/vPricecomparison']);
	}
	reset(event) {
		this._submitted = false;
		this._pricecomparisonform.reset();
		this._pricecomparisontypeform.reset();
		this.productcatid = [];
		this.producttypeid = [];
		this.pricecomparisonobj = [];
		this._pricecomparisondetails = [];
		this.productid = [];
		this.InitializeForm();
	}
	HotKeyIntegration() {
		this._hotkeysService.reset();
		if (this._action != 'view') {
			this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
				this.onSave();
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		if (this._action == 'create') {
			this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
				this.reset(null);
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
			this.goBack(null);
			return false; // Prevent bubbling
		}, ['INPUT', 'SELECT', 'TEXTAREA']));
	}
	filterProducts(event) {
		if (event.query == "" || event.query == undefined) {
			return;
		} else {
			this._IsProgressSpinner = true;
			this._PricecomparisonService.GetProduct(event.query, this._branchid).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._ProductNames = <Productoverallview[]>resultJSON.productsearchlist || [];
				this._TempProductSearch = <Productoverallview[]>resultJSON.productsearchlist || [];
				let filtered: any[] = [];
				let query = (<string>event.query).toLowerCase();
				this.filteredProducts = this._ProductNames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	Competitor1change(data: any) {
		data.c1unitprice = null;
		data.c2id = null;
		data.c2unitprice = null;
		data.c3id = null;
		data.c3unitprice = null;
		data.c4id = null;
		data.c4unitprice = null;
	}
	Competitor2change(data: any) {
		data.c2unitprice = null;
		data.c3id = null;
		data.c3unitprice = null;
		data.c4id = null;
		data.c4unitprice = null;
		if (data.c1id == data.c2id) {
			data.c2id = null;
			this._CustomExceptionService.handleWarning("this Competitor has already selected")
			return
		}
	}
	Competitor3change(data: any) {
		data.c3unitprice = null;
		data.c4id = null;
		data.c4unitprice = null;
		if (data.c1id == data.c3id) {
			data.c3id = null;
			this._CustomExceptionService.handleWarning("this Competitor has already selected 1")
			return
		}
		if (data.c2id == data.c3id) {
			data.c3id = null;
			this._CustomExceptionService.handleWarning("this Competitor has already selected 2")
			return
		}
	}
	Competitor4change(data: any) {
		data.c4unitprice = null;
		if (data.c1id == data.c4id) {
			data.c4id = null;
			this._CustomExceptionService.handleWarning("this Competitor has already selected 1")
			return
		}
		if (data.c2id == data.c4id) {
			data.c4id = null;
			this._CustomExceptionService.handleWarning("this Competitor has already selected 2")
			return
		}
		if (data.c3id == data.c4id) {
			data.c4id = null;
			this._CustomExceptionService.handleWarning("this Competitor has already selected 3")
			return
		}
	}
}