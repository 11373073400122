import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { IAction } from '../security/IActions';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SalesquotationService {
  public _Action: IAction;
  public _SalesQuotationService = environment.SalesQuotationService;
  public _RptSQPrint = environment.RptSalesQuotationPrintService;
  public _CustomerService = environment.CustomerService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(salesquotationno: string, userid: number, branchid: number): Observable<any> {
		const headers = this.header;
    var Query = this._SalesQuotationService + '/' + salesquotationno + '/' + userid + '/' + branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  GetProductCategory(producttype: object) {
		const headers = this.header;
    var Query = this._SalesQuotationService + '/GetProductCategory';
    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }
  GetProductName(productcategory: object) {
		const headers = this.header;
    var Query = this._SalesQuotationService + '/GetProductName';
    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }
  GetProductPrice(productid: number, customerid: number, userid: number, levelid: number, branchid: number, roleid: number) {
		const headers = this.header;
    var Query = this._SalesQuotationService + '/GetProductPrice' + '/' + productid + '/' + customerid + '/' + userid + '/' + levelid + '/' + branchid + '/' + roleid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  GetProduct(product: string, branchid: number) {
		const headers = this.header;
    var Query = this._SalesQuotationService + '/GetProduct' + '/' + product + '/' + branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  customercreate(customer: object) {
		const headers = this.header;
    console.log("Customer Create");
    var Query = this._CustomerService + '/CustomerQuickSave';
    return this.http.post<{ data: any }>(Query, customer, { headers });
  }
  create(salesquotation: object) {
		const headers = this.header;
    console.log("Create");
    var Query = this._SalesQuotationService;
    return this.http.post<{ data: any }>(Query, salesquotation, { headers });
  }

  FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
		const headers = this.header;
    var Query = this._SalesQuotationService + '/FetchAll' + '/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  edit(salesquotation: object) {
		const headers = this.header;
    console.log("Edit");
    var Query = this._SalesQuotationService;
    return this.http.put<{ data: any }>(Query, salesquotation, { headers });
  }


  SendToApproval(workflowid: number, userid: number, branchid: number, salesquotationno: string, series: string) {
		const headers = this.header;
    console.log("Send To Approval");
    var Query = this._SalesQuotationService + '/SendToApproval' + '/' + workflowid + '/' + userid + '/' + branchid + '/' + salesquotationno + '/' + series;
    return this.http.post<{ data: any }>(Query, '', { headers });
  }
  Cancel(salesquotationno: string) {
		const headers = this.header;
    var Query = this._SalesQuotationService + '/Cancel/' + salesquotationno;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetCustomerDetails(customerid: number) {
		const headers = this.header;
    var Query = this._SalesQuotationService + '/GetCustomerDetails' + '/' + customerid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  // Print(salesquotationno: string,branchid: number) {
  //   var Query = this._RptSQPrint + '/Print/' + salesquotationno+'/'+branchid;
  //   window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes'); 
  // }
  Print(obj: any) {
		const headers = this.header;
    // var Query = this._RptSQPrint + '/Print/' + salesquotationno+'/'+branchid;
    // window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes'); 
    var Query = this._SalesQuotationService + '/SQPRINT';
    return this.http.post(Query, obj, { responseType: 'blob' ,  headers });
  }
  SendMail(userid: number, branchid: number, quoteno: string) {
		const headers = this.header;
    var Query = this._SalesQuotationService + '/PoMail' + '/' + userid + '/' + branchid + '/' + quoteno;
    return this.http.get<{ data: any }>(Query, { headers });
  }
}

