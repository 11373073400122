import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DamagedproductpricedetailsService {
  public _DamagedproductpricedetailsService = environment.DamagedproductpricedetailsService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  FetchAll() {
		const headers = this.header;
    var Query = this._DamagedproductpricedetailsService + '/FetchAll';

    return this.http.get<{ data: any }>(Query , { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      return data;
    }));
  }
  PageOnLoad(p_productid: number, p_severityid: number, p_levelid: number, branchid: number): Observable<any> {
		const headers = this.header;
    var Query = this._DamagedproductpricedetailsService + '/' + p_productid + '/' + p_severityid + '/' + p_levelid + '/' + branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  getProductCategory(producttype: object) {
		const headers = this.header;
    var Query = this._DamagedproductpricedetailsService + '/getProductCategories';
    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }
  getProduct(productcategory: object) {
		const headers = this.header;
    var Query = this._DamagedproductpricedetailsService + '/getProducts';
    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }

  getVariant(productid: number) {
		const headers = this.header;
    var Query = this._DamagedproductpricedetailsService + '/getProductVariants' + '/' + productid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetProductSearch(product: string, branchid: number) {
		const headers = this.header;
    var Query = this._DamagedproductpricedetailsService + '/GetProductSearch' + '/' + product + '/' + branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  create(p_damagedproductpricedetail: object) {
		const headers = this.header;
    var Query = this._DamagedproductpricedetailsService;
    return this.http.post<{ data: any }>(Query, p_damagedproductpricedetail, { headers });
  }
  SendToApproval(workflowid: number, userid: number, branchid: number, productid: number, severity: number) {
		const headers = this.header;
    var Query = this._DamagedproductpricedetailsService + '/SendToApproval' + '/' + workflowid + '/' + userid + '/' + branchid + '/' + productid + '/' + severity;
    return this.http.post<{ data: any }>(Query, '', { headers });
  }

  edit(p_damagedproductpricedetail: object) {
		const headers = this.header;
    var Query = this._DamagedproductpricedetailsService;
    return this.http.put<{ data: any }>(Query, p_damagedproductpricedetail, { headers });
  }
}
