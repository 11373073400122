<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Contra</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Add" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <p-toast></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_contraviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['voucherno','voucherdate','Paymentmodename','paymentamount','fromaccountname','toaccountname','createdbyname','createdon','contrastatusdesc']"
                        [rowHover]="true" dataKey="voucherno" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="action" style="text-align: center;">Action
                                    <p-sortIcon field="action"></p-sortIcon>
                                </th>
                                <th class="text-center" style="width: 120px;" pSortableColumn="sno">SNo
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="voucherno">Voucher No
                                    <p-sortIcon field="voucherno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="fromaccountname">Form Account Name
                                    <p-sortIcon field="fromaccountname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="toaccountname">To Account Name
                                    <p-sortIcon field="toaccountname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="amount">Amount
                                    <p-sortIcon field="amount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname">Currency
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="narration">Narration
                                    <p-sortIcon field="narration"></p-sortIcon>
                                </th>
                                <th pSortableColumn="contrastatusdesc">Status
                                    <p-sortIcon field="contrastatusdesc">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_contraview let-i="rowIndex">
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_contraview)"></button>
                                    <button pButton pRipple title="Send To Approval" icon="las la-check" type="button" [disabled]="!_Action._Approve ||_contraview.contrastatusid != 45" (click)="SendToApproval(_contraview)"></button>
                                    <button pButton pRipple icon="las la-times" title="Cancel" class="p-button p-button-danger p-mr-2" [disabled]="!_Action._Delete || _contraview.contrastatusid == 46" (click)="confirm(_contraview)" [hidden]="_contraview.contrastatusid == 150||_contraview.contrastatusid == 47 || _contraview.contrastatusid == 48"></button>
                                    <button pButton pRipple icon="las la-print" title="Print" class="p-button-warning p-mr-2" (click)="Print(_contraview)"></button>

                                </td>
                                <td style="text-align: center;">
                                    {{i+ 1}}
                                </td>
                                <td>
                                    {{_contraview.voucherno}}
                                </td>
                                <td>
                                    {{_contraview.fromaccountname}}
                                </td>
                                <td>
                                    {{_contraview.toaccountname}}
                                </td>
                                <td>
                                    {{_contraview.amount}}
                                </td>
                                <td>
                                    {{_contraview.currencyname}}
                                </td>
                                <td>
                                    {{_contraview.narration}}
                                </td>
                                <td>
                                    {{_contraview.contrastatusdesc}}
                                </td>
                                <td>
                                    {{_contraview.createdbyname}}
                                </td>
                                <td>
                                    {{_contraview.createdon|date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>