import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../security/IActions';


@Injectable({
  providedIn: 'root'
})
export class ProductpriceService {
  public _Action: IAction;
  public _ProductPriceService = environment.ProductPriceService;
  public _httpOptions;
  constructor(private http: HttpClient) { }

  PageOnLoad(PageOnLoad: object) {
    var Query = this._ProductPriceService + '/PageOnLoad';

    return this.http.post<{ data: any }>(Query, PageOnLoad);
  }
  getProductCategories(producttype: object) {
    var Query = this._ProductPriceService + '/GetProductCategory';

    return this.http.post<{ data: any }>(Query, producttype);
  }
  GetCustomer(producttype: object) {
    var Query = this._ProductPriceService + '/GetCustomer';

    return this.http.post<{ data: any }>(Query, producttype);
  }

  getProducts(productcategory: object) {
    var Query = this._ProductPriceService + '/GetProduct';

    return this.http.post<{ data: any }>(Query, productcategory);
  }
  getProductwise(productprice: object) {
    var Query = this._ProductPriceService + '/GetProductPrice';

    return this.http.post<{ data: any }>(Query, productprice);
  }
  Print(productprice: object) {
    var Query = this._ProductPriceService + '/Print';

    return this.http.post(Query, productprice, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }
  getBrand(products: object) {
    var Query = this._ProductPriceService + '/getBrand';

    return this.http.post<{ data: any }>(Query, products);
  }
}
