<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Supplier Payment Due Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_reportform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" formControlName="fromdate"
                                        [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                        <label for="fromdate">From Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" formControlName="todate"
                                        [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                        <label for="todate">To Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchids" [filter]="true"
                                          [virtualScroll]="true"
                                        itemSize="30" [filter]="true" filterBy="branchname"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                        <label for="name">Branch Name  <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_supplierlist" optionLabel="suppliername"
                                        optionValue="supplierid" [(ngModel)]="supplierids" [filter]="true"
                                          [virtualScroll]="true"
                                        itemSize="30" [filter]="true" filterBy="suppliername"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                        <label for="name">Supplier Name  <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_currencylist" optionLabel="currencyname"
                                        optionValue="currencyid" [(ngModel)]="currencyids" [filter]="true"
                                          [virtualScroll]="true"
                                        itemSize="30" [filter]="true" filterBy="currencyname"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                        <label for="name">Currency Name  <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>

                            </tr>

                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" icon="las la-search" (click)="GenerateReport()" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()" class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                    </form>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['SUPPLIERNAME','GRNCODE','GRNDTAE','GRNBRANCH','PURCHASEINVOICENO','PURCHASEINVOICEDATE','BILLINGBRANCH','CURRENCYNAME','INVOICEAMOUNT','DUEAMOUNT','DUEDATE','PAYMENTAMOUNT','PAYMENTDATE','DELAYDAYS']"
                            [rowHover]="true" dataKey="actualname" [columns]="cols" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center;" pSortableColumn="SUPPLIERNAME">Supplier Name
                                        <p-sortIcon field="SUPPLIERNAME">
                                        </p-sortIcon>
                                    </th>
                                    <th style="text-align: center;" pSortableColumn="GRNCODE">GRN No
                                        <p-sortIcon field="GRNCODE">
                                        </p-sortIcon>
                                    </th>
                                    <th style="text-align: center;" pSortableColumn="GRNDTAE">GRN Date
                                        <p-sortIcon field="GRNDTAE">
                                        </p-sortIcon>
                                    </th>
                                    <th style="text-align: center;" pSortableColumn="GRNBRANCH">GRN Branch
                                        <p-sortIcon field="GRNBRANCH">
                                        </p-sortIcon>
                                    </th>
                                    <th style="text-align: center;" pSortableColumn="PURCHASEINVOICENO">Purchase Invoice No
                                        <p-sortIcon field="PURCHASEINVOICENO">
                                        </p-sortIcon>
                                    </th>
                                    <th style="text-align: center;" pSortableColumn="PURCHASEINVOICEDATE"> Purchase Invoice Date
                                        <p-sortIcon field="PURCHASEINVOICEDATE">
                                        </p-sortIcon>
                                    </th>
                                    <th style="text-align: center;" pSortableColumn="BILLINGBRANCH">Billing Branch
                                        <p-sortIcon field="BILLINGBRANCH">
                                        </p-sortIcon>
                                    </th>
                                    <th style="text-align: center;" pSortableColumn="CURRENCYNAME">Currency
                                        <p-sortIcon field="CURRENCYNAME">
                                        </p-sortIcon>
                                    </th>
                                    <th style="text-align: center;" pSortableColumn="INVOICEAMOUNT">Invoice Amount
                                        <p-sortIcon field="INVOICEAMOUNT">
                                        </p-sortIcon>
                                    </th>
                                    <th style="text-align: center;" pSortableColumn="DUEAMOUNT">Due Amount
                                        <p-sortIcon field="DUEAMOUNT">
                                        </p-sortIcon>
                                    </th>
                                    <th style="text-align: center;" pSortableColumn="DUEDATE">Due date
                                        <p-sortIcon field="DUEDATE">
                                        </p-sortIcon>
                                    </th>
                                    <th style="text-align: center;" pSortableColumn="PAYMENTAMOUNT">Payment Amount
                                        <p-sortIcon field="PAYMENTAMOUNT">
                                        </p-sortIcon>
                                    </th>
                                    <th style="text-align: center;" pSortableColumn="PAYMENTDATE">Payment Date
                                        <p-sortIcon field="PAYMENTDATE">
                                        </p-sortIcon>
                                    </th>
                                    <!-- <th pSortableColumn="TOTAL">Created By<p-sortIcon field="TOTAL">
                                        </p-sortIcon>
                                    </th>  -->
                                    <th style="text-align: center;" pSortableColumn="DELAYDAYS">Delayed Payment (In Days)
                                        <p-sortIcon field="DELAYDAYS">
                                        </p-sortIcon>
                                    </th>
                                </tr>

                            </ng-template>
                            <ng-template pTemplate="body" let-_reportlist let-ri="rowIndex">
                                <tr>
                                    <td style="text-align: left;">
                                        {{_reportlist.SUPPLIERNAME}}
                                    </td>
                                    <td style="text-align: left;"> {{_reportlist.GRNCODE}}
                                    </td>
                                    <td style="text-align: left;">
                                        {{_reportlist.GRNDTAE| date:'dd/MM/yyyy'}}
                                    </td>
                                    <td style="text-align: left;">
                                        {{_reportlist.GRNBRANCH}}
                                    </td>
                                    <td style="text-align: left;">
                                        {{_reportlist.PURCHASEINVOICENO}}
                                    </td>
                                    <td style="text-align: left;">
                                        {{_reportlist.PURCHASEINVOICEDATE| date:'dd/MM/yyyy'}}
                                    </td>
                                    <td style="text-align: left;">
                                        {{_reportlist.BILLINGBRANCH}}
                                    </td>
                                    <td style="text-align: left;">
                                        {{_reportlist.CURRENCYNAME}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_reportlist.INVOICEAMOUNT| number: '1.2-2'}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_reportlist.DUEAMOUNT| number: '1.2-2'}}
                                    </td>
                                    <td style="text-align: left;">
                                        {{_reportlist.DUEDATE| date:'dd/MM/yyyy'}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_reportlist.PAYMENTAMOUNT | number: '1.2-2'}}
                                    </td>
                                    <td style="text-align: left;">
                                        {{_reportlist.PAYMENTDATE | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_reportlist.DELAYDAYS}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>