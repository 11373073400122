import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { RptsupplierdueService } from 'src/app/core/Services/reports/Accounting/Rptsupplierdue/rptsupplierdue.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-rpt-supplier-due',
  templateUrl: './rpt-supplier-due.component.html',
  styleUrls: ['./rpt-supplier-due.component.css']
})
export class RptSupplierDueComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _reportform: FormGroup;
  _ReportLists: any[] = [];
  _Action: IAction;
  _branchlist: any
  branchids: any[]
  _supplierlist: any
  supplierids: any[]
  _currencylist: any
  currencyids: any
  cols: any[];
  mindate: Date;
  maxdate: Date;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _AccessRightsService: AccessRightsService,
    private _RptsupplierdueService: RptsupplierdueService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private sanitizer: DomSanitizer,
    private exportUtility: ExportUtility

  ) { }

  InitiliazeForm() {
    var dt = new Date();
    dt.setDate(dt.getDate() - 7);
    this._reportform = this.fb.group({
      fromdate: [dt],
      todate: [new Date()],
    })
    if (sessionStorage['Environmentenddate'] == "null") {
      this._reportform.get("fromdate").setValue(new Date);
      this._reportform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._reportform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._reportform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(359);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);

    this.cols = [
      { field: 'SUPPLIERNAME', header: 'Supplier Name' },
      { field: 'GRNCODE', header: 'GRN No' },
      { field: 'GRNDTAE', header: 'GRN Date' },
      { field: 'GRNBRANCH', header: 'GRN Branch' },
      { field: 'PURCHASEINVOICENO', header: 'Purchase Invoice No' },
      { field: 'PURCHASEINVOICEDATE', header: 'Purchase Invoice Date' },
      { field: 'BILLINGBRANCH', header: 'Billing Branch' },
      { field: 'CURRENCYNAME', header: 'Currency' },
      { field: 'INVOICEAMOUNT', header: 'Invoice Amount' },
      { field: 'DUEAMOUNT', header: 'Due Amount' },
      { field: 'DUEDATE', header: 'Due date' },
      { field: 'PAYMENTAMOUNT', header: 'Payment Amount' },
      { field: 'PAYMENTDATE', header: 'Payment Date' },
      { field: 'DELAYDAYS', header: 'Delayed Payment (In Days)' }
    ]

    this.InitiliazeForm();
    this._IsProgressSpinner = true;
    let data = {
      userid: sessionStorage["userid"],
    }
    this._RptsupplierdueService.PageOnload(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._supplierlist = resultJSON.supplierList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })

  }

  GenerateReport() {

    var _fromdate = this._reportform.get("fromdate").value;
    var _todate = this._reportform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');

    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than To Date");
      return;
    }

    let data = {
      "userid": sessionStorage["userid"],
      "Fromdate": fromdate,
      "Todate": todate,
      "supplierid": this.supplierids || [],
      "branchid": this.branchids || [],
      "currencyid": this.currencyids || [],
    }

    this._RptsupplierdueService.search(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON.supplierduelists, 'details');

      this._ReportLists = resultJSON.supplierduelists;
      if (this._ReportLists.length == 0) {
        this._CustomExceptionService.handleWarning("No Data Available")

      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
  }

  clear() {
    this.InitiliazeForm();
    this.supplierids = []
    this.branchids = []
    this.currencyids = []
    this._ReportLists = []
  }

  exportExcel() {
    let reportlists = this.GetsupplierdueReportList();
    this.exportUtility.exportExcel(reportlists, 'Supplier_Payment_Due_Report', 'xlsx');
  }

  ExportToPdf() {
    let reportlists = this.GetsupplierdueReportList();
    const data = reportlists.map(Object.values);
    const head = [['Supplier Name', 'GRN No', 'GRN Date', 'GRN Branch', 'Purchase Invoice No', 'Purchase Invoice Date', 'Billing Branch', 'Currency', 'Invoice Amount', 'Due Amount', 'Due date', 'Payment Amount', 'Payment Date', 'Delayed Payment (In Days)'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Supplier_Payment_Due_Report.pdf');
      })
    })
  }

  GetsupplierdueReportList() {
    let supplierreportlists = [];
    this._ReportLists.forEach(element => {
      let reportlists: any = {};
      reportlists["Supplier Name"] = element.SUPPLIERNAME;
      reportlists["GRN No"] = element.GRNCODE;
      reportlists["GRN Date"] = element.GRNDTAE ? new Date(element.GRNDTAE).toLocaleDateString('en-US') : '';
      reportlists["GRN Branch"] = element.GRNBRANCH;
      reportlists["Purchase Invoice No"] = element.PURCHASEINVOICENO;
      reportlists["Purchase Invoice Date"] = element.PURCHASEINVOICEDATE ? new Date(element.PURCHASEINVOICEDATE).toLocaleDateString('en-US') : '';
      reportlists["Billing Branch"] = element.BILLINGBRANCH;
      reportlists["Currency"] = element.CURRENCYNAME;
      reportlists["Invoice Amount"] = element.INVOICEAMOUNT;
      reportlists["Due Amount"] = element.DUEAMOUNT;
      reportlists["Due date"] = element.DUEDATE ? new Date(element.DUEDATE).toLocaleDateString('en-US') : '';
      reportlists["Payment Amount"] = element.PAYMENTAMOUNT;
      reportlists["Payment Date"] = element.PAYMENTDATE ? new Date(element.PAYMENTDATE).toLocaleDateString('en-US') : '';
      reportlists["Delayed Payment (In Days)"] = element.DELAYDAYS;
      supplierreportlists.push(reportlists);
    });
    return supplierreportlists;
  }

}
