<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-4">
                        <h3>User Discount Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_UserDiscountreportform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="fromdate" formControlName="fromdate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true" (onSelect)="PageOnLoad()"
                                        yearRange="1900:2050" [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="todate" formControlName="todate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" (onSelect)="PageOnLoad()"
                                        [yearNavigator]="true" yearRange="1900:2050" [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="todate">To Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="row">
                    <div class="col-md-4">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Branch Wise Discount</h5>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtBranchWiseDiscounts.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>

                                </ng-template>
                                <ng-template pTemplate="right">

                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dtBranchWiseDiscounts.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                                </ng-template>

                            </p-toolbar>
                            <p-table #dtBranchWiseDiscounts [value]="_BranchWiseDiscounts" selectionMode="single" [rows]="15" [paginator]="true" [rowsPerPageOptions]="[15,25,50,100]" (onRowSelect)="onRowSelectBranch($event)" [columns]="branchwisecols" [globalFilterFields]="['branchname','discountamount']"
                                [rowHover]="true" dataKey="branchid" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" [(selection)]="selectedBranchwiseDiscount">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="branchname">Branch Name
                                            <p-sortIcon field="branchname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="discountamount">Discount Amount
                                            <p-sortIcon field="discountamount">
                                            </p-sortIcon>
                                        </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-_branchwisediscount let-i="rowIndex">
                                    <tr [pSelectableRow]="rowData">
                                        <td>
                                            {{_branchwisediscount.branchname}}
                                        </td>
                                        <td class="text-right">
                                            {{CurrencySymbol}}{{_branchwisediscount.discountamount}}
                                        </td>
                                    </tr>
                                </ng-template>
                                <!-- <ng-template pTemplate="footer">
                                    <tr>
                                        <td colspan="2" class="text-right"></td>

                                    </tr>
                                </ng-template> -->
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-8" *ngIf="IsShowBranchWiseUserDiscount">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Branch Wise User Discount</h5>
                                </div>
                                <div class="col-md-6 text-right">
                                    <h3>Overall Branch Wise User Discount : {{CurrencySymbol}}{{Overalluserdiscount}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-5">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtBranchWiseUserDiscount.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>

                                </ng-template>
                                <ng-template pTemplate="right">

                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dtBranchWiseUserDiscount.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcelBranchWiseUserDiscount()"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdfBranchWiseUserDiscount()"></button>
                                </ng-template>

                            </p-toolbar>
                            <p-table #dtBranchWiseUserDiscount [value]="_BranchWiseUserDiscounts" selectionMode="single" [rows]="15" [paginator]="true" [rowsPerPageOptions]="[15,25,50,100]" [columns]="branchuserwisediscountcols" [globalFilterFields]="['branchname','createdbyname','salesinvoiceno','salesinvoiceamount','discountpercentage','discountamount','finalamount']"
                                [rowHover]="true" dataKey="slaesinvoiceno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" [(selection)]="selectedBranchWiseUserDiscountRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="branchname">Branch Name
                                            <p-sortIcon field="branchname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="createdbyname">User
                                            <p-sortIcon field="createdbyname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="salesinvoiceno">Invoice No
                                            <p-sortIcon field="salesinvoiceno">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="salesinvoiceamount">Invoice Amount
                                            <p-sortIcon field="salesinvoiceamount">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="discountpercentage">Discount %
                                            <p-sortIcon field="discountpercentage">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="discountamount">Discount Amount
                                            <p-sortIcon field="discountamount">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="finalamount">Final Amount
                                            <p-sortIcon field="finalamount">
                                            </p-sortIcon>
                                        </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-_branchwiseuserdiscount let-i="rowIndex">
                                    <tr [pSelectableRow]="rowData">
                                        <td>
                                            {{_branchwiseuserdiscount.branchname}}
                                        </td>
                                        <td>
                                            {{_branchwiseuserdiscount.createdbyname}}
                                        </td>
                                        <td>
                                            {{_branchwiseuserdiscount.salesinvoiceno}}
                                        </td>
                                        <td class="text-right">
                                            {{CurrencySymbol}}{{_branchwiseuserdiscount.salesinvoiceamount}}
                                        </td>
                                        <td class="text-center">
                                            {{_branchwiseuserdiscount.discountpercentage}}
                                        </td>
                                        <td class="text-right">
                                            {{CurrencySymbol}}{{_branchwiseuserdiscount.discountamount}}
                                        </td>
                                        <td class="text-right">
                                            {{CurrencySymbol}}{{_branchwiseuserdiscount.finalamount}}
                                        </td>

                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>