import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Documentdescriptionview } from 'src/app/core/Models/DocumentDescriptionView';
import { DocumentdescriptionService } from 'src/app/core/Services/masters/documentdescription.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-v-documentdescription',
  templateUrl: './v-documentdescription.component.html',
  styleUrls: ['./v-documentdescription.component.css']
})
export class VDocumentdescriptionComponent implements OnInit {
  selectedrows: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  datepipe = new DatePipe('en-US');
  _documentdescriptionview: Documentdescriptionview;
  _documentdescriptionsview: Documentdescriptionview[]=[];
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  _userid = sessionStorage["userid"];

  constructor(private fb: FormBuilder, private _DocumentDescriptionService: DocumentdescriptionService, private router: Router, private utility: CommonUtilities,
    private _CustomExceptionService: CustomExceptionService,private confirmationService: ConfirmationService,
    private _AccessRightsService: AccessRightsService, private _hotkeysService: HotkeysService,
    private exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;
    this._Action = this._AccessRightsService.getAccessRights(146);
    this.HotKeyIntegration();
    this._DocumentDescriptionService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._documentdescriptionsview = resultJSON.documentdescriptionviews;
      this._IsProgressSpinner = false;
    });
  }
  view(event) {
    this.router.navigate(['/cdocumentdescription'], { state: { documentdescriptionid: event.documentdescriptionid, action: 'view' } });
  }
  delete(event) {
    alert("delete");
  }
  edit(event) {
    // 14.on clicking update button in search grid , it Navigates to the update screen
    this.router.navigate(['/cdocumentdescription'], { state: { documentdescriptionid: event.documentdescriptionid, action: 'edit' } });
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
   //View
   HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  GoToCreate()
  {
    this.router.navigate(['/cdocumentdescription']);
  }
  exportExcel() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    this.exportUtility.exportExcel(TermsAndConditionList, 'DocumenDescription', 'xlsx');
  }
  ExportToPdf() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    const data = TermsAndConditionList.map(Object.values);
    const head = [[
      'Document Desccription Name',
      'Document Type Name',
      'Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('DocumenDescription.pdf');
      })
    })
  }
  GetTermsAndConditionList() {
    let TermsAndConditionList = [];
    this._documentdescriptionsview.forEach(element => {
      let TermsAndCondition: any = {};
      TermsAndCondition["Document Desccription Name"] = element.documentdescriptionname;
      TermsAndCondition["Document Type Name"] = element.documentdescriptiontypename;
      TermsAndCondition["Status"] = element.statusname;
      TermsAndCondition["Created By"] = element.createdbyname;
      TermsAndCondition["Created On"] = this.datepipe.transform(element.createdon,'dd/MM/yyyy');
      TermsAndCondition["Modified By"] = element.modifiedbyname;
      TermsAndCondition["Modified On"] = this.datepipe.transform(element.modifiedon,'dd/MM/yyyy');
      TermsAndConditionList.push(TermsAndCondition);
    });
    return TermsAndConditionList;
  }
}
