export class Productdescription {
    productdescriptiondetailid: number;
    productid: number;
    shortdescription: string;
    longdescription: string;
    createdby: number;
    createdon: Date ;
    modifiedby: number ;
    modifiedon: Date ;

  
}