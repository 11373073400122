<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
          </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Customer Rebate</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" class="" type="submit"
                                (click)="onSave()" [disabled]="_isDisableSave"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button"
                                [disabled]="isDisabledsendapparoval" (click)="SendToApproval($event)"></button>
                            <button pButton pRipple label="" title="Clear" icon="pi pi-trash" class=""
                                (click)="reset($event)" class="p-button-danger" [disabled]="_action == 'edit'||_action=='view'"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_customerslabwisediscountform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                <p-multiSelect [options]="_Customernames" optionLabel="customername"
                                    optionValue="customerid" formControlName="customerid" [virtualScroll]="true" itemSize="40"
                                    (onChange)="onSelectCustomers($event)" [(ngModel)]="_selectedCustomersIds"
                                    ></p-multiSelect>
                                <label for="customerid">Customer Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="effectivefrom"  [monthNavigator]="true" 
                                        (onSelect)="OnFromDateSelect($event)" dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="2000:2030">
                                    </p-calendar>
                                    <label for="effectivefrom">Effective From <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="effectiveto" dateFormat="dd/mm/yy"
                                     [minDate]="minDate"  [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030">

                                    </p-calendar>
                                    <label for="effectiveto">Effective To <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown inputId="dropdown" [autoDisplayFirst]="true"
                                        formControlName="recordstatus" [options]="_Recordstatus" name="recordstatus"
                                        optionLabel="metasubdescription" optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="recordstatus">Status</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Customer Slabwise Discount Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_customerslabdetails" [rows]="50" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['sno','customerid','discount']"
                        [rowHover]="true" dataKey="customerid" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 120px;">Action </th>
                                <th pSortableColumn="minimumvalue">Minimum Value <p-sortIcon field="minimumvalue">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="maximumvalue">Maximum Value<p-sortIcon field="maximumvalue">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="discountpercentage">Discount %<p-sortIcon field="discountpercentage">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_customerslabdetail let-i="rowIndex">
                            <tr>
                                <td style="text-align: center;">
                                    <button pButton pRipple title="Add" icon="pi pi-plus"
                                        class="p-button-success p-mr-2" (click)="AddNewSlab($event)" [disabled]="_action=='view'"></button>
                                    <button pButton pRipple title="Remove" icon="pi pi-trash"
                                        class="p-button-danger p-mr-2"
                                        (click)="RemoveVariant(_customerslabdetail)" [disabled]="_action=='view'"></button>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText maxlength="5"
                                            (keypress)="keypress.kpNumber($event)" [disabled]="MinimumRange"
                                                [(ngModel)]="_customerslabdetail.minimumvalue" (focusout)="checkDuplicate($event,'minimumvalue',i)" *ngIf="_action != 'view'">
                                                <div *ngIf="_action == 'view'">{{_customerslabdetail.minimumvalue}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_customerslabdetail.minimumvalue }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText 
                                                [(ngModel)]="_customerslabdetail.maximumvalue" (focusout)="checkDuplicate($event,'maximumvalue',i)" *ngIf="_action != 'view'">
                                                <div *ngIf="_action == 'view'">{{_customerslabdetail.maximumvalue}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_customerslabdetail.maximumvalue }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText 
                                                [(ngModel)]="_customerslabdetail.discountpercentage" (focusout)="checkDuplicate($event,'discountpercentage',i)" *ngIf="_action != 'view'">
                                                <div *ngIf="_action == 'view'">{{_customerslabdetail.discountpercentage}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_customerslabdetail.discountpercentage }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <!-- <table>
                    <tr>
                        <td>
                            
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table> -->
            </div>
        </div>
        <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>