export class Productcategory {
    productcategoryid: number;
    categoryname: string;
    producttypeid : string;
    branchid: string;
    categorystatus: string;
    accountingyear: number;
    createdby: string;
    createdon: Date;
    modifiedby: string;
    modifiedon: Date;
} 