import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { StockinwardService } from 'src/app/core/Services/inventory/stockinward.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Stockinwardview } from 'src/app/core/Views/Stockinwardview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-stockinward',
  templateUrl: './v-stockinward.component.html',
  styleUrls: ['./v-stockinward.component.css']
})
export class VStockinwardComponent implements OnInit {

  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _stockinwardview: Stockinwardview;
  _stockinwardviews: Stockinwardview[] = [];
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  cols: any[];
  _userid = sessionStorage["userid"]
  _branchid = parseInt(sessionStorage["currentbranchid"]);
  _roleid = sessionStorage["currentRole"]
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  mindate: Date;
  maxdate: Date;
  _Branch: any[] = [];
  _Branchdrop: Boolean = true;
  _Branchname: number;
  constructor(private fb: FormBuilder, private _StockinwardService: StockinwardService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _hotkeysService: HotkeysService, private _CustomExceptionService: CustomExceptionService, private confirmationService: ConfirmationService,
    private exportUtility: ExportUtility) { }

  ngOnInit(): void {

    this._Action = this._AccessRightsService.getAccessRights(48);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    // this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._Branchname  = this._branchid;

    this.cols = [
      { field: 'inwardtype', header: 'Inward Type' },
      { field: 'inwardtypename', header: 'Inward Type Name' },
      { field: 'inwardno', header: 'Inward No' },
      { field: 'inwarddate', header: 'Inward Date' },
      { field: 'Referenceno', header: 'Reference Number' },
      { field: 'Grnno', header: 'GRN Number' },
      { field: 'suppliernameorbranchname', header: 'Supplier/Branch Name' },
      { field: 'vehicleno', header: 'Vehicle No' },
      { field: 'drivername', header: 'Driver' },
      { field: 'metasubdescription', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
     if (sessionStorage['Environmentenddate'] == "null" ) {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    if(this._roleid == 1) 
    {
      this._Branchdrop = false;
        
    }
    else{
      this._Branchdrop = true;
    }
    this.Fetchall();
  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    if (this._roleid != 1) 
    {
      this._branchid = parseInt(sessionStorage["currentbranchid"]);
    }
    else
    {
      this._branchid = this._Branchname;
    }
    this._IsProgressSpinner = true;

    this._StockinwardService.FetchAll(this._branchid, this._userid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //console.log("fetchall",resultJSON)
      this._stockinwardviews = resultJSON.stockinwardviews;
      this._Branch = resultJSON.inwardbranch;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
    //console.log("eventfired");
    //console.log(event.data);
    //console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    //console.log("eventUnfired");
    //console.log(event.data);
    //console.log(this.selectedrows);
  }

  view(event) {
    this.router.navigate(['/cStockinward'], { state: { inwardid: event.inwardid, inwardtype: event.inwardtype, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cStockinward']);
  }
  edit(event) {
    // console.log("event.value");
    this.router.navigate(['/cStockinward'], { state: { inwardid: event.inwardid, inwardtype: event.inwardtype, action: 'edit' } });
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let inwardList = this.GetInwardList();
    this.exportUtility.exportExcel(inwardList, 'Stock Inward', 'xlsx');
  }

  // ExportToPdf() {
  //   let inwardList = this.GetInwardList();
  //   this.exportUtility.ExportToPdf(inwardList, 'Stock Inward.pdf');
  // }
  ExportToPdf() {
   
    let employeeList = this.GetInwardList();
    const data = employeeList.map(Object.values);
    const head = [[
      'Inward Type',
      'Inward Type Name',
      'Inward No',
      'Inward Date',
      'Reference Number',
      'GRN Number',
      'Supplier/Branch Name',
      'Vehicle No',
      'Driver',
      'Status',
      'Created On',
      'Created By'
      
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Employees.pdf');
      })
    })
  }

  GetInwardList() {
    let inwardList = [];
    this._stockinwardviews.forEach(element => {
      let quotes: any = {};
      quotes["Inward Type"] = element.inwardtype;
      quotes["Inward Type Name"] = element.inwardtypename;
      quotes["Inward No"] = element.inwardno;
      quotes["Inward Branch Name"] = element.inwardbranchname;
      quotes["Inward Date"] = this.datepipe.transform(element.inwarddate, 'dd/MM/yyyy')
      quotes["Reference Number"] = element.referenceno;
      quotes["GRN Number"] = element.grnno;
      quotes["Supplier/Branch Name"] = element.suppliernameorbranchname;
      quotes["Vehicle No"] = element.vehicleno;
      quotes["Driver"] = element.drivername;
      quotes["Status"] = element.metasubdescription;
      quotes["Created By"] = element.createdbyname;
      quotes["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      inwardList.push(quotes);
    });
    return inwardList;
  }
  Print(event) {
    this._IsProgressSpinner = true;
    var stockinwardid = event.inwardid;
    var branchid = this._branchid;

    let data = {
      "stockinwardid" : stockinwardid,
      "branchid" : branchid
    }

    this._StockinwardService.Print(data).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
  }
}

