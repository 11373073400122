import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Currency } from 'src/app/core/Models/Currency';
import { productsCattegory } from 'src/app/core/Models/overallclass';
import { Producttype } from 'src/app/core/Models/Producttype';
import { RptmonthwisestockService } from 'src/app/core/Services/reports/Stock/RptMonthWiseStock/rptmonthwisestock.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable'
@Component({
  selector: 'app-monthwisestockreport',
  templateUrl: './monthwisestockreport.component.html',
  styleUrls: ['./monthwisestockreport.component.css']
})
export class MonthwisestockreportComponent implements OnInit {
  _producttype: Producttype[];
  _productcategory: productsCattegory[];
  _productname: any[];
  _branchlist: Branch;
  _currencylist: Currency;
  _IsProgressSpinner: boolean = false;
  selectedrows: any;
  _submitted = false;
  cols: any[];
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _MonthWiseStockObj: any;
  _monthwisestockreportform: FormGroup;
  _MonthWiseStocks: any[];
  _yearlist = [{ year: '2020' }, { year: '2021' }, { year: '2022' }, { year: '2023' }, { year: '2024' }, { year: '2025' }
    , { year: '2026' }, { year: '2027' }, { year: '2028' }, { year: '2029' }, { year: '2030' }, { year: '2031' }];
  constructor(private fb: FormBuilder, private router: Router, private _MonthWiseStockService: RptmonthwisestockService,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService, private exportUtility: ExportUtility) { }
  InitializeMonthWiseForm() {
    this._monthwisestockreportform = this.fb.group({
      producttypeid: [],
      productcategoryid: [],
      productid: [],
      branchid: [],
      isdamaged: [],
      year: []
    });
    this._monthwisestockreportform.get("isdamaged").setValue(false);
    this._monthwisestockreportform.controls['year'].setValue(this._yearlist[1].year);
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeMonthWiseForm();
    this.cols = [
      {field:'producttypename', header: 'Product Type Name'},
      { field: 'productcategoryname', header: 'Product Category Name' },
      { field: 'productname', header: 'Product Name' },
      { field: 'jan', header: 'Jan' },
      { field: 'feb', header: 'Feb' },
      { field: 'mar', header: 'Mar' },
      { field: 'apr', header: 'Apr' },
      { field: 'may', header: 'May' },
      { field: 'jun', header: 'Jun' },
      { field: 'jul', header: 'Jul' },
      { field: 'aug', header: 'Aug' },
      { field: 'sep', header: 'Sep' },
      { field: 'oct', header: 'Oct' },
      { field: 'nov', header: 'Nov' },
      { field: 'dec', header: 'Dec' },
    ]
    this.PageOnLoad();


  }
  PageOnLoad() {
    this._IsProgressSpinner = true;
    this._MonthWiseStockService.PageOnLoad().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      
      this._producttype = resultJSON.producttypes;
      this._currencylist = resultJSON.currencies;
      this._branchlist = resultJSON.branches;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductcategory(event) {
    var selectedProductType = event.value;
    var branchid = this._monthwisestockreportform.get("branchid").value||[];
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._monthwisestockreportform.controls["productcategoryid"].reset();
      this._productcategory = [];
      return;
    }
    this._IsProgressSpinner = true;
    this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": branchid  };
    this._MonthWiseStockService.getProductCategories(this._ProductTypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      
      this._productcategory = resultJSON.productcategories;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductname(event) {
    var branchid = this._monthwisestockreportform.get("branchid").value||[];
    var selectedProductCategory = event.value;
    console.log(selectedProductCategory);
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productname = [];
      return;
    }
    this._IsProgressSpinner = true;
    this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": branchid };
    this._MonthWiseStockService.getProducts(this._ProductCategoryObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      
      this._productname = resultJSON.productdetails;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  search() {
    let productid = this._monthwisestockreportform.get("productid").value;
    let producttypeid = this._monthwisestockreportform.get("producttypeid").value;
    let productcateggoryid = this._monthwisestockreportform.get("productcategoryid").value;
    var branchid = this._monthwisestockreportform.get("branchid").value||[];
    let Year =  this._monthwisestockreportform.get("year").value;
    var damgedstock =  this._monthwisestockreportform.get("isdamaged").value || false;

    this._IsProgressSpinner = true;
    this._MonthWiseStockObj = { "Productid": productid || [], "Productcategoryid": productcateggoryid || [], "Producttypeid": producttypeid || [], "Branchid":branchid ,"Year":Year,"DamagedStock": damgedstock};
    this._MonthWiseStockService.getMonthWiseStock(this._MonthWiseStockObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      

      this._MonthWiseStocks = resultJSON.rptMonthWiseStock;
     
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
 
  clear() {
    this.InitializeMonthWiseForm();
    this._productcategory = [] = [];
    this._productname = [] = [];
    this._MonthWiseStocks = [] = [];
    this._monthwisestockreportform.get("isdamaged").setValue(false);
  }

  exportExcel() {
    let MonthWiseStockList = this.GetMonthWiseStockList();
    this.exportUtility.exportExcel(MonthWiseStockList, 'MonthWiseStockReport', 'xlsx');
  }
 
  ExportToPdf() {
    if (!this._MonthWiseStocks || !this._MonthWiseStocks.length)
    return; 
    let MonthWiseStockList = this.GetMonthWiseStockList();
    const data = MonthWiseStockList.map(Object.values);
    const head = [['Product Type Name','Product Category Name','Product Name', 'Jan', 'Feb', 'Mar', 'Apr', 'May','Jun','Jul','Aug','Sep','Oct',
    'Nov','Dec']]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        // doc.autoTable(this.exportColumns, this._ExpenseReportviews);
        doc.save('MonthWiseStockReport.pdf');
      })
    })
  }


  GetMonthWiseStockList() {
    if (!this._MonthWiseStocks || !this._MonthWiseStocks.length)
      return;
    let MonthWiseStockList = [];
    this._MonthWiseStocks.forEach(element => {
      let monthwisestock: any = {};
      monthwisestock["Product Type Name"] = element.producttypename;
      monthwisestock["Product Category Name"] = element.productcategoryname;
      monthwisestock["Product Name"] = element.productname;
      monthwisestock["Jan"] = element.January;
      monthwisestock["Feb"] = element.February;
      monthwisestock["Mar"] = element.March;
      monthwisestock["Apr"] = element.April;
      monthwisestock["May"] = element.May;
      monthwisestock["Jun"] = element.June;
      monthwisestock["Jul"] = element.July;
      monthwisestock["Aug"] = element.August;
      monthwisestock["Sep"] = element.September;
      monthwisestock["Oct"] = element.October;
      monthwisestock["Nov"] = element.November;
      monthwisestock["Dec"] = element.December;
      MonthWiseStockList.push(monthwisestock);
    });
    return MonthWiseStockList;
  }
}

