import { getAttrsForDirectiveMatching } from '@angular/compiler/src/render3/view/util';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { AsyncLocalStorage } from 'async_hooks';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ApprovalRequest } from 'src/app/core/Models/Approvalrequest';
import { ApprovalWorkFlowSummary } from 'src/app/core/Models/ApprovalWorkFlowSummary';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { WorkflowService } from 'src/app/core/Services/users/workflow.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Approvalworkflowsummarystageview } from 'src/app/core/Models/Approvalworkflowsummarystageview';
import autoTable from 'jspdf-autotable';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-c-workflow',
  templateUrl: './c-workflow.component.html',
  styleUrls: ['./c-workflow.component.css']
})
export class CWorkflowComponent implements OnInit {
  workflowapprovalform: FormGroup;
  workflowform: FormGroup;
  _approvalrequest: ApprovalRequest;

  _Approvalworkflowdetails: ApprovalWorkFlowSummary[] = [];
  _Approvalworkflowdetail: ApprovalRequest;
  _Approvalworkflow: ApprovalRequest;


  _workflowdetails: ApprovalWorkFlowSummary[] = [];
  _workflowoveralldetails: ApprovalWorkFlowSummary[] = [];
  _OverAllApprovedList: ApprovalWorkFlowSummary[] = [];
  _overallresult: ApprovalWorkFlowSummary[];

  ManageDialog: boolean;
  ManageDialog1: boolean;
  _approvedcount: any;
  _pendingcount: any;
  _rejectedcount: any;
  _overallcount: any
  currentdate: any;
  //local variables:
  //Others
  _othersoverallcount: number;
  _othersapprovedcount: number;
  _otherspendingcount: number
  _othersrejectedcount: number
  //purchase
  _purchaseapprovedcount: number;
  _purchasependingcount: number
  _purchaserejectedcount: number
  _purchaseoverallcount: number
  //Sales
  _salesapprovedcount: number;
  _salespendingcount: number;
  _salesrejectedcount: number;
  _salesoverallcount: number;
  //Accounts
  _accountsapprovedcount: number;
  _accountspendingcount: number;
  _accountsrejectedcount: number;
  _accountsoverallcount: number;

  _newdate: Date;
  _workflowOBJ: any;
  month: Date;
  _userid = parseInt(sessionStorage["userid"]);
  _branchid = sessionStorage["BranchID"];
  _workflow: Approvalworkflowsummarystageview[];

  _wcompleted: number = 1;
  _wrejected: number = 1;

  isDisabledApprove: boolean = false;
  isDisabledReject: boolean = false

  _Level
  _Duration = [];
  id: any
  _IsProgressSpinner: boolean = false;
  datepipe = new DatePipe('en-US');
  branchid: number[] = [];
  _branchlist: any[] = [];
  _isDisable: boolean = true;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _router: Router, private _WorkflowService: WorkflowService,
    private _CustomExceptionService: CustomExceptionService, private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService, private confirmationService: ConfirmationService, private exportUtility: ExportUtility) {


    _hotkeysService.reset();
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.workflowapprovalform = this.fb.group({
      companyid: [],
      branchid: [],
      requesterid: [],
      workflowid: [],
      documentno: [],
      remarks: [],
      logstatus: [],
      approverid: [],
      stageid: [],
      alternateuser: [],
      parentid: [],
      userid: [],
      requestfromdate: [],
      requesttodate: [],
      isviewed: [],
    });
    this.workflowform = this.fb.group({
      remarks: [],
    });
    this._Duration = [
      { id: 1, name: '1 month' },
      { id: 2, name: '3 month' },
      { id: 3, name: '6 month' },
      { id: 4, name: '1 year' }
    ];
    //this.id = this._Duration[1];
    this.id = this._Duration[0];
    this.PageLoad();
  }
  ChangeDate(event) {
    var selectedid = event.value;
    if (selectedid == 1) {
      this.currentdate = new Date();
      var newMonth = this.currentdate.getMonth() - 1;
      this.currentdate.setMonth(newMonth);
      this.PageLoad();
      this.Search();
    }
    if (selectedid == 2) {
      this.currentdate = new Date();
      var newMonth = this.currentdate.getMonth() - 3;
      this.currentdate.setMonth(newMonth);
      this.PageLoad();
      this.Search();
    }
    if (selectedid == 3) {
      this.currentdate = new Date();
      var newMonth = this.currentdate.getMonth() - 6;
      this.currentdate.setMonth(newMonth);
      this.PageLoad();
      this.Search();
    }
    if (selectedid == 4) {
      this.currentdate = new Date();
      var newMonth = this.currentdate.getMonth() - 12;
      this.currentdate.setMonth(newMonth);
      this.PageLoad();
      this.Search();
    }
  }
  exportExcel() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    this.exportUtility.exportExcel(TermsAndConditionList, 'Workflow', 'xlsx');
  }
  ExportToPdf() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    const data = TermsAndConditionList.map(Object.values);
    const head = [[
      'Branch',
      'Document Type',
      'Document No',
      'Stages',
      'Requested Date',
      'Requested By',
      'Remarks',
      'Document Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Workflow.pdf');
      })
    })
  }
  GetTermsAndConditionList() {
    let TermsAndConditionList = [];
    this._workflowoveralldetails.forEach(element => {
      let TermsAndCondition: any = {};
      TermsAndCondition["Branch"] = element.branch;
      TermsAndCondition["Document Type"] = element.documenttype;
      TermsAndCondition["Document No"] = element.documentno;
      TermsAndCondition["Stages"] = element.stageid;
      TermsAndCondition["Requested Date"] = this.datepipe.transform(element.requestdate, 'dd/MM/yyyy');
      TermsAndCondition["Requested By"] = element.requestby;
      TermsAndCondition["Remarks"] = element.remarks;
      TermsAndCondition["Document Status"] = element.documentstatus;
      TermsAndConditionList.push(TermsAndCondition);
    });
    return TermsAndConditionList;
  }
  PageLoad() {
    this._approvalrequest = new ApprovalRequest();
    this._approvalrequest.userid = parseInt(sessionStorage["userid"]);
    this._approvalrequest.requestfromdate = this.currentdate;
    this._approvalrequest.requesttodate = new Date();
    //this._workflowOBJ = { "ApprovalWorkFlowSummary": this._approvalrequest }

    //this.onEd();
    this._IsProgressSpinner = true;

    var userid = parseInt(sessionStorage["userid"])

    if (userid == 1)//Arif
    {
      this._isDisable = false;
    }
    else {
      this._isDisable = true;
    }

    this._WorkflowService.ApprovalWorkFlowSummary(this._approvalrequest).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this._workflowoveralldetails = resultJSON.approvalWorkFlowSummaries;
      this._branchlist = resultJSON.branchList;
      this.Total();
      this._IsProgressSpinner = false;
    });


  }

  //OTHERS
  ChangeOthersApproved() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "Approved" && (f.documenttype.indexOf("Purchase") < 0 && f.documenttype.indexOf("Sales") < 0 && f.documenttype.indexOf("Accounts") < 0));
    this._othersapprovedcount = this._Approvalworkflowdetails.length;
  }
  ChangeOtherPending() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "In Process" && (f.documenttype.indexOf("Purchase") < 0 && f.documenttype.indexOf("Sales") < 0 && f.documenttype.indexOf("Accounts") < 0));
    this._otherspendingcount = this._Approvalworkflowdetails.length;
  }
  ChangeOthersRejected() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "Rejected" && (f.documenttype.indexOf("Purchase") < 0 && f.documenttype.indexOf("Sales") < 0 && f.documenttype.indexOf("Accounts") < 0));
    this._othersrejectedcount = this._Approvalworkflowdetails.length;
  }
  ChangeOthersOverall() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documenttype.indexOf("Purchase") < 0 && f.documenttype.indexOf("Sales") < 0 && f.documenttype.indexOf("Accounts") < 0);
    //this._masteroverallcount=this._workflowoveralldetails.length;
    this._othersoverallcount = this._Approvalworkflowdetails.length;
    //this.disable();
  }
  // PURCHASE
  ChangePurchaseapproved() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "Approved" && f.documenttype == "Purchase");
    this._purchaseapprovedcount = this._Approvalworkflowdetails.length;
  }
  ChangePurchasepending() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "In Process" && f.documenttype == "Purchase");
    this._purchasependingcount = this._Approvalworkflowdetails.length;
  }
  ChangePurchaserejected() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "Rejected" && f.documenttype == "Purchase");
    this._purchaserejectedcount = this._Approvalworkflowdetails.length;
  }
  ChangePurchaseoverall() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documenttype == "Purchase");
    this._purchaseoverallcount = this._Approvalworkflowdetails.length;
  }
  //Sales
  ChangeSalesApproved() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "Approved" && f.documenttype == "Sales");
    this._salesapprovedcount = this._Approvalworkflowdetails.length;
  }
  ChangeSalesPending() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "In Process" && f.documenttype == "Sales");
    this._salespendingcount = this._Approvalworkflowdetails.length;
  }
  ChangeSalesRejected() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "Rejected" && f.documenttype == "Sales");
    this._salesrejectedcount = this._Approvalworkflowdetails.length;
  }
  ChangeSalesOverAll() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documenttype == "Sales");
    this._salesoverallcount = this._Approvalworkflowdetails.length;
  }

  //Accounts
  ChangeAccountsApproved() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "Approved" && f.documenttype == "Accounts");
    this._accountsapprovedcount = this._Approvalworkflowdetails.length;
  }
  ChangeAccountsPending() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "In Process" && f.documenttype == "Accounts");
    this._accountspendingcount = this._Approvalworkflowdetails.length;
  }
  ChangeAccountsRejected() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "Rejected" && f.documenttype == "Accounts");
    this._accountsrejectedcount = this._Approvalworkflowdetails.length;
  }
  ChangeAccountsOverAll() {
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documenttype == "Accounts");
    this._accountsoverallcount = this._Approvalworkflowdetails.length;
  }

  showBasicDialog(event: ApprovalRequest) {
    this.ManageDialog = true;
    this._IsProgressSpinner = true;
    this._Approvalworkflowdetail = new ApprovalRequest();
    this._Approvalworkflowdetail.workflowid = event.workflowid;
    this._Approvalworkflowdetail.companyid = "0";
    this._Approvalworkflowdetail.branchid = sessionStorage["BranchID"];
    this._Approvalworkflowdetail.requesterid = event.requesterid;
    this._Approvalworkflowdetail.approverid = parseInt(sessionStorage["userid"]);
    this._Approvalworkflowdetail.stageid = event.stageid;
    if (event.workflowid != 58)//TELLER CASH
    {
      this._Approvalworkflowdetail.documentdetailid = event.documentdetailid;
    }
    else {
      this._Approvalworkflowdetail.documentdetailid = parseInt(event.documentno);
    }
    this._Approvalworkflowdetail.alternateuser = event.alternateuser;
    this._Approvalworkflowdetail.documentno = event.documentno;
    this._Approvalworkflowdetail.parentid = event.parentid;
    this._Approvalworkflowdetail.userid = parseInt(sessionStorage["userid"]);
    // this._Approvalworkflowdetail.requestfromdate = event.requestfromdate;
    // this._Approvalworkflowdetail.requesttodate = event.requesttodate;

    this._Approvalworkflowdetail.isviewed = event.isviewed;
    this._Approvalworkflowdetail.logstatus = "MSC00047";
    this._IsProgressSpinner = false;
    this._Approvalworkflow = this._Approvalworkflowdetail;

  }
  Approve(event) {
    this.ManageDialog = false;
    //this.onEd();
    this._Approvalworkflow.remarks = this.workflowform.get("remarks").value;
    this._IsProgressSpinner = true;
    this._WorkflowService.ApprovalWorkFlowUpdate(this._Approvalworkflow).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this.PageLoad();
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Approved_01);
        this._IsProgressSpinner = false;
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
      //this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error) });
    this._IsProgressSpinner = false;

  }
  showBasicDialog1(event: ApprovalRequest) {
    this.ManageDialog1 = true;
    //this.ManageDialog = true;
    this._Approvalworkflowdetail = new ApprovalRequest();
    this._Approvalworkflowdetail.workflowid = event.workflowid;
    this._Approvalworkflowdetail.companyid = "0";
    this._Approvalworkflowdetail.branchid = sessionStorage["BranchID"];
    this._Approvalworkflowdetail.requesterid = event.requesterid;
    this._Approvalworkflowdetail.approverid = parseInt(sessionStorage["userid"]);
    this._Approvalworkflowdetail.stageid = event.stageid;
    this._Approvalworkflowdetail.documentdetailid = event.documentdetailid
    this._Approvalworkflowdetail.alternateuser = event.alternateuser;
    this._Approvalworkflowdetail.documentno = event.documentno;
    this._Approvalworkflowdetail.parentid = event.parentid;
    this._Approvalworkflowdetail.userid = parseInt(sessionStorage["userid"]);
    // this._Approvalworkflowdetail.requestfromdate = event.requestfromdate;
    // this._Approvalworkflowdetail.requesttodate = event.requesttodate;

    this._Approvalworkflowdetail.isviewed = event.isviewed;
    this._Approvalworkflowdetail.logstatus = "MSC00048";

    this._Approvalworkflow = this._Approvalworkflowdetail;
  }
  Reject() {
    this.ManageDialog1 = false;
    this._Approvalworkflow.remarks = this.workflowform.get("remarks").value;
    this._IsProgressSpinner = true;
    //this.onEd()
    this._WorkflowService.ApprovalWorkFlowUpdate(this._Approvalworkflow).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Rejected_01);
        this._IsProgressSpinner = false;
        this.PageLoad();

      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error) });
    this._IsProgressSpinner = false;

  }
  Cancel() {
    this.ManageDialog = false;
    this.ManageDialog1 = false;
  }

  ApprovalWorkFlowStage(event: ApprovalWorkFlowSummary) {
    //this.utility.workflowStatus();
    this._Approvalworkflowdetail = new ApprovalRequest();
    this._Approvalworkflowdetail.workflowid = event.workflowid;
    this._Approvalworkflowdetail.documentno = event.documentno;
    this._Approvalworkflowdetail.userid = parseInt(sessionStorage["userid"]);

    var selectedworkflowid = this._Approvalworkflowdetail.workflowid;
    var selecteddocumentno = this._Approvalworkflowdetail.documentno;
    var logid = event.logid;
    this._workflow = [];
    this._IsProgressSpinner = true;
    this._WorkflowService.ApprovalWorkFlowStage(selectedworkflowid, selecteddocumentno, this._userid, logid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this._workflow = <Approvalworkflowsummarystageview[]>resultJSON.approvalworkflowsummarystageviews || [];
      this._wcompleted = this._workflow.filter(f => f.logstatus == 'MSC00047').length;
      this._wrejected = this._workflow.filter(f => f.logstatus == 'MSC00048').length;
      //reload();
      //function reload() {
      $('#idworkflowview').attr('data-level-completed', this._wcompleted);
      $('#idworkflowview').attr('data-level-rejected', this._wrejected);
      this.utility.workflowStatus();
      //}
      // $('#idworkflowview').attr('data-level-completed',this._wcompleted);
      // $('#idworkflowview').attr('data-level-rejected',this._wrejected);
      //this.utility.workflowStatus();

    });
  }
  ViewApproval(event: ApprovalWorkFlowSummary) {

    let data = {
      path: '',
      state: {}
    }

    switch (event.workflowid) {
      case 13:
      case 23:
        {
          data.path = '/cProduct';
          data.state = { productid: event.documentdetailid };
          break;
        }
      case 5: {
        data.path = '/cPurchaseOrder';
        data.state = { purchaseorderid: event.documentdetailid, purchaseorderno: event.documentno };
        break;
      }
      case 6: {
        data.path = '/cPurchaseinvoice';
        data.state = { purchaseinvoiceid: event.documentdetailid, purchaseinvoiceno: event.documentno }
        break;
      }
      case 7: {
        data.path = '/cPurchasereturn';
        data.state = { purchasereturnid: event.documentno }
        break;
      }
      case 8: {
        data.path = '/cStockTransferOrder';
        data.state = { stocktransferid: event.documentdetailid }
        break;
      }
      case 9: {
        data.path = '/cGoodsReceiptNote';
        data.state = { grnno: event.documentno }
        break;
      }
      case 30: {
        data.path = '/cStockAdjustment';
        data.state = { stocktakeid: event.documentdetailid }
        break;
      }
      case 19: {
        data.path = '/cSalesQuotation';
        data.state = { salesquotationno: event.documentno }
        break;
      }
      case 20: {
        data.path = '/cSalesOrder';
        data.state = { salesorderno: event.documentno }
        break;
      }
      case 21: {
        data.path = '/cSalesInvoicetax';
        data.state = { salesinvoiceno: event.documentno }
        break;
      }
      case 22: {
        data.path = '/cSalesinvoicepos';
        data.state = { salesinvoiceno: event.documentno }
        break;
      }
      case 18: {
        data.path = '/cSalesreturn';
        data.state = { salesreturnno: event.documentno }
        break;
      }
      case 17: {
        data.path = '/CPettycash';
        data.state = { pettycashid: event.documentno }
        break;
      }
      case 12: {
        data.path = '/CPettycashSettlement';
        data.state = { pettycashsettlementid: event.documentno }
        break;
      }
      case 16: {
        data.path = '/cSalescommission';
        data.state = { salescommissionid: event.documentno }
        break;
      }
      case 11: {
        data.path = '/cdamagedproductpricedetails';
        data.state = { productid: event.documentno }
        break;
      }
      case 4: {
        data.path = '/cQuotefinalize';
        data.state = { supplierquotesno: event.documentdetailid }
        break;
      }
      case 24:
      case 25: {
        data.path = '/cCustomer';
        data.state = { customerid: event.documentdetailid }
        break;
      }

      case 80: {
        data.path = '/cReward';
        data.state = { rewardid: event.documentno }
        break;
      }
      case 83: {
        data.path = '/cCustomerslabwisediscount';
        data.state = { customerid: event.documentno }
        break;
      }
      case 77: {
        data.path = '/cCustomerwisediscount';
        data.state = { customerid: event.documentno }
        break;
      }
      case 79: {
        data.path = '/cScheme';
        data.state = { schemeid: event.documentno }
        break;
      }

    }

    data.state["action"] = 'view';
    this._router.navigate([data.path], { state: data.state });

  }
  onEd() {
    alert("test");
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._Approvalworkflow;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }


  Search() {

    if (!this.branchid || !this.branchid.length) {
      this._CustomExceptionService.handleError("Please Select Atleast One Branch Name");
      return;
    }

    let data = {
      "BranchId": this.branchid || [] || parseInt(sessionStorage["currentbranchid"]),
    };
    this._IsProgressSpinner = true;
    this._WorkflowService.Search(data).subscribe((result) => {
      this._IsProgressSpinner = false;
      const resultJSON = JSON.parse(JSON.stringify(result));

      if (!resultJSON.approvalWorkFlowSummaries || !resultJSON.approvalWorkFlowSummaries.length) {
        this._CustomExceptionService.handleError("No Data Available");
      } else {
        this._workflowoveralldetails = resultJSON.approvalWorkFlowSummaries;
        this.Total();

      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  Total() {
    //this.disable();

    // this._OverAllApprovedList = resultJSON.approvalWorkFlowSummaries
    this._Approvalworkflowdetails = this._workflowoveralldetails.filter(f => f.documentstatus == "In Process");
    //OTHERS
    let ApprovedOthers = this._workflowoveralldetails.filter(f => f.documentstatus == "Approved" && (f.documenttype.indexOf("Purchase") < 0 && f.documenttype.indexOf("Sales") < 0 && f.documenttype.indexOf("Accounts") < 0));
    this._othersapprovedcount = ApprovedOthers.length;

    let PendingOthers = this._workflowoveralldetails.filter(f => f.documentstatus == "In Process" && (f.documenttype.indexOf("Purchase") < 0 && f.documenttype.indexOf("Sales") < 0 && f.documenttype.indexOf("Accounts") < 0));
    this._otherspendingcount = PendingOthers.length;

    let RejectedOthers = this._workflowoveralldetails.filter(f => f.documentstatus == "Rejected" && (f.documenttype.indexOf("Purchase") < 0 && f.documenttype.indexOf("Sales") < 0 && f.documenttype.indexOf("Accounts") < 0));
    this._othersrejectedcount = RejectedOthers.length;

    let OverallOthers = this._workflowoveralldetails.filter(f => f.documenttype.indexOf("Purchase") < 0 && f.documenttype.indexOf("Sales") < 0 && f.documenttype.indexOf("Accounts") < 0);
    this._othersoverallcount = OverallOthers.length;

    //PURCHASE
    let ApprovedPurchase = this._workflowoveralldetails.filter(f => f.documentstatus == "Approved" && f.documenttype == "Purchase");
    this._purchaseapprovedcount = ApprovedPurchase.length;

    let PendingPurchase = this._workflowoveralldetails.filter(f => f.documentstatus == "In Process" && f.documenttype == "Purchase");
    this._purchasependingcount = PendingPurchase.length;

    let RejectedPurchase = this._workflowoveralldetails.filter(f => f.documentstatus == "Rejected" && f.documenttype == "Purchase");
    this._purchaserejectedcount = RejectedPurchase.length;

    let OverallPurchase = this._workflowoveralldetails.filter(f => f.documenttype == "Purchase");
    this._purchaseoverallcount = OverallPurchase.length;

    //Sales
    let ApprovedSales = this._workflowoveralldetails.filter(f => f.documentstatus == "Approved" && f.documenttype == "Sales");
    this._salesapprovedcount = ApprovedSales.length;

    let PendingSales = this._workflowoveralldetails.filter(f => f.documentstatus == "In Process" && f.documenttype == "Sales");
    this._salespendingcount = PendingSales.length;

    let RejectedSales = this._workflowoveralldetails.filter(f => f.documentstatus == "Rejected" && f.documenttype == "Sales");
    this._salesrejectedcount = RejectedSales.length;

    let OverallSales = this._workflowoveralldetails.filter(f => f.documenttype == "Sales");
    this._salesoverallcount = OverallSales.length;

    //ACCOUNTS
    let ApprovedAccounts = this._workflowoveralldetails.filter(f => f.documentstatus == "Approved" && f.documenttype == "Accounts");
    this._accountsapprovedcount = ApprovedAccounts.length;

    let PendingAccounts = this._workflowoveralldetails.filter(f => f.documentstatus == "In Process" && f.documenttype == "Accounts");
    this._accountspendingcount = PendingAccounts.length;

    let RejectedAccounts = this._workflowoveralldetails.filter(f => f.documentstatus == "Rejected" && f.documenttype == "Accounts");
    this._accountsrejectedcount = RejectedAccounts.length;

    let OverallAccounts = this._workflowoveralldetails.filter(f => f.documenttype == "Accounts");
    this._accountsoverallcount = OverallAccounts.length;
  }



}
