<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Container Tracking</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add"
                                class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true" [minDate]="mindate" [maxDate]="maxdate"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate"
                                    [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search"
                                icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>

                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_containertrackings" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [globalFilterFields]="['containertrackingid','containertrackingno','blno','containerno','suppliername','lastupdateddate',
                        'containerstatusname','shippinglinename','arraivaldate',
                        'portagent','borderagent','localtransporter','tupdateddate','statusname','createdbyname','createdon']"
                        [rowHover]="true" dataKey="containertrackingno" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>

                                <th class="text-center" style="width: 120px;">Action </th>
                                <!-- <th pSortableColumn="containertrackingid" [hidden]="true">Container Tracking ID.<p-sortIcon
                                        field="containertrackingid">
                                    </p-sortIcon>
                                </th> -->
                                <th pSortableColumn="containertrackingno">Container Tracking No.<p-sortIcon
                                        field="containertrackingno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="containerno">Container No.<p-sortIcon field="containerno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="blno">B.L. Number<p-sortIcon field="blno">
                                </p-sortIcon>
                                </th>
                                <th pSortableColumn="containerno">Supplier Name<p-sortIcon field="containerno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="lastupdateddate">Last Updated Date.<p-sortIcon
                                        field="lastupdateddate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="statusname">Container Status<p-sortIcon field="statusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="shippinglinename">Shipping Line<p-sortIcon
                                        field="shippinglinename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="arraivaldate">Arrival Date<p-sortIcon field="arraivaldate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="portagent">Port Agent<p-sortIcon field="portagent">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="borderagent">Border Agent<p-sortIcon field="borderagent">
                                    </p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="localtransporter">Local Transporter<p-sortIcon field="localtransporter">
                                    </p-sortIcon>
                                </th> -->
                                <th pSortableColumn="statusname">Status<p-sortIcon field="statusname">
                                    </p-sortIcon>
                                </th>

                                <th pSortableColumn="createdbyname">Created By<p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On<p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_containertracking>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="!_Action._View" (click)="view(_containertracking)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit"
                                        class="p-button p-button-success p-mr-2"
                                        [disabled]="!_Action._Update || _containertracking.paymentstatus === 'MSC00177'|| _containertracking.status === 'MSC00150'"
                                        (click)="edit(_containertracking)"></button>
                                    <!-- <button pButton pRipple icon="pi pi-trash" title="Delete"
                                        class="p-button p-button-danger" [disabled]="!_Action._Delete"
                                        (click)="delete(_containertracking)"></button> -->
                                    <button pButton pRipple icon="pi pi-times" title="Delete"
                                        class="p-button p-button-danger" 
                                        (click)="confirm(_containertracking)"
                                        [disabled]="!_Action._Delete || _containertracking.status === 'MSC00150' || _containertracking.paymentstatus === 'MSC00177'"></button>
                                </td>
                                <!-- <td [hidden]="true">
                                    {{_containertracking.containertrackingid}}
                                </td> -->
                                <td>
                                    {{_containertracking.containertrackingno}}
                                </td>
                                <td>
                                    {{_containertracking.containerno}}
                                </td>
                                <td>
                                    {{_containertracking.blno}}
                                </td>
                                <td>
                                    {{_containertracking.suppliername}}
                                </td>
                                <td>
                                    {{_containertracking.lastupdateddate | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_containertracking.containerstatusname}}
                                </td>
                                <td>
                                    {{_containertracking.shippinglinename}}
                                </td>
                                <td>
                                    {{_containertracking.arraivaldate | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_containertracking.portagent}}
                                </td>
                                <td>
                                    {{_containertracking.borderagent}}
                                </td>
                                <!-- <td>
                                    {{_containertracking.localtransporter}}
                                </td> -->
                                <td>
                                    {{_containertracking.statusname}}
                                </td>
                                <td>
                                    {{_containertracking.createdbyname}}
                                </td>
                                <td>
                                    {{_containertracking.createdon|date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <p-toast position="bottom-right"></p-toast>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </div>
        </div>
    </div>