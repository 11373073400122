<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
          </div>
        <div class="white">
            <form [formGroup]="_accountgroupform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Account Group</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple title="Save" type="submit" icon="pi pi-save" [disabled]="_isSaveHide"></button>
                               <!--13. All entered input fields should be cleared. -->
                                <button pButton pRipple title="Clear" icon="pi pi-trash" class="p-button-danger"
                                    (click)="reset($event)" [disabled]="_isClearHide"></button>
                                  <!--  17.on clicking search button , the data must be filtered and display in the grid -->
                                <button pButton pRiple icon="pi pi-search" title="Back to Search"
                                    class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <table class="normal-table">
                        <tr>
                           
                            <td style="width: 20%;">
                            <!-- 5. Parent group load from Account Group master, it contains group name and code -->
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false"  [options]="_parentgroup"
                                        name="accountparentgroupid" optionLabel="accountgroupname"
                                        optionValue="accountgroupid" formControlName="accountparentgroupid">
                                    </p-dropdown>
                                  <label for="accountparentgroupid"> Parent Group <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 8.if Parent group is not selected, show error "Please select Parent group" -->
                                  <span class="text-danger"
                                  *ngIf="(_submitted || _accountgroupform.get('accountparentgroupid').touched) && _accountgroupform.get('accountparentgroupid').errors?.SelectParentGroup">
                                  Please Select Parent group
                              </span>

                            </td>
                            <td style="widows: 20%;">
                             
                                <span class="p-float-label">
                                    <input type="text" name="accountgroupname" formControlName="accountgroupname"
                                        pInputText>
                                    <label for="accountgroupname">Account Group Name <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                 <!-- 9.if "Account group Name" is not given, show error as "Please enter Account Group Name" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _accountgroupform.get('accountgroupname').touched) && _accountgroupform.get('accountgroupname').errors?.AccountGroupNameRequired">
                                        Please Enter Account Group Name
                                    </span>  
                            </td>
                            <td style="width: 20%;">
                                <!-- <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_accountgroupstatus"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="accountgroupstatus" >
                                    </p-dropdown>
                                    <label for="status"> Status </label>
                                </span> -->
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;">
                                <!-- Debug -->
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </tr>
                    </table>

                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>