import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FileUpload } from 'primeng/fileupload';
import { elementAt } from 'rxjs/operators';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Myordershistory } from 'src/app/core/Models/Orderstatus';
import { User } from 'src/app/core/Models/User';
import { CodpaymentstatusService } from 'src/app/core/Services/ecommerce/codpaymentstatus.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-cod-history',
  templateUrl: './v-cod-history.component.html',
  styleUrls: ['./v-cod-history.component.css']
})
export class VCodHistoryComponent implements OnInit {

  fromdate: Date;
  todate: Date;
  CODHistoryGrid
  viewByList = [];
  Tellerid: any;
  PaymentMode: any;
  viewisAmountCollectedList = [];
  viewisAmountCollected = [];
  datepipe = new DatePipe('en-Us');
  _tellernames: User;
  _PaymentModes: Metadatum[] = [];
  _IsProgressSpinner: boolean = false;
  _LastAddedDocument: File;
  _selectedDocuments: File[] = [];
  _CODHistoryObj: any;
  selectedCODHistoriesRows: Myordershistory[] = [];
  _CODFORM: FormData;
  IsAmountCollected: boolean = false;
  SelectedCodHistories: Myordershistory[] = [];
  _branchid = sessionStorage["currentbranchid"];
  _userid = sessionStorage["userid"];
  isDisabledsave: boolean = false;
  DisabledPayment: boolean = false;
  constructor(private router: Router, private utility: CommonUtilities, private _CODPaymentStatus: CodpaymentstatusService, private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService, public exportUtility: ExportUtility, private _DomSanitizationService: DomSanitizer) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.fromdate = new Date();
    this.todate = new Date();
    this.PageOnload();

  }
  PageOnload() {
    this._IsProgressSpinner = true;
    this._CODPaymentStatus.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      this._tellernames = resultJSON.users;
      this._PaymentModes = resultJSON.paymentmode || [];
      this.PaymentMode = this._PaymentModes.length > 0 ? this._PaymentModes[0].metasubcode : "";
     
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  GetOrderDetails() {
  
    if (!this.Tellerid) {
      this._CustomExceptionService.handleError("Please Select Teller Name");
      return;
    }
    if (!this.PaymentMode) {
      this._CustomExceptionService.handleError("Please Select Payment Mode");
      return;
    }
    this._IsProgressSpinner = true;
    this.selectedCODHistoriesRows = [] = [];
    this._CODPaymentStatus.getorderdetails(this.Tellerid, this.IsAmountCollected, this.PaymentMode).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON)
      
      this.CODHistoryGrid = resultJSON.myordershistoryheaders;
      // if(resultJSON.myordershistoryheaders.modeofpayment == "ECO00052"){
      //   this.CODHistoryGrid.modeofpayment = 1 
      // }
      if(this.PaymentMode == "ECO00052"){
        this.selectedCODHistoriesRows = resultJSON.myordershistoryheaders;
        this.DisabledPayment = true
      }else{
        this.CODHistoryGrid = resultJSON.myordershistoryheaders; 
        this.DisabledPayment = false
      }
      if (this.IsAmountCollected == true) {
        this.isDisabledsave = true
        // this.isDisabledPayment = true
        
        this.selectedCODHistoriesRows = resultJSON.myordershistoryheaders;
        this.DisabledPayment = true
      } else if (this.IsAmountCollected == false) {
        this.isDisabledsave = false
        // this.isDisabledPayment = false
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  showdocumentDialog(document: FileUpload, position: string, _CodDocument: any) {
    let url = null;
    if (_CodDocument.attachmenturl) {
      url = _CodDocument.attachmenturl;
    }
    if (!url)
      return;

    window.open(url, "MsgWindow", "width=800,height=800");
  }
  showdocumentGridDialog(position: string, _CodDocument: any) {
    let url = null;
    if (_CodDocument.attachmenturl) {
      url = _CodDocument.attachmenturl;
    }
    if (!url)
      return;

    window.open(url, "MsgWindow", "width=800,height=800");
  }

  onDocumentSelect(event, document, _CODHistoryGrid: any) {
    this._LastAddedDocument = event.currentFiles[0];
    _CODHistoryGrid.attachmenturl = URL.createObjectURL(this._LastAddedDocument);
    _CODHistoryGrid.attachmentname = this._LastAddedDocument.name;
    this._selectedDocuments.push(this._LastAddedDocument);
    document.clear()
  }
  onDocumentClear(_CODHistoryGrid: any) {
    this._LastAddedDocument = null
    _CODHistoryGrid.attachmenturl = "";
    _CODHistoryGrid.attachmentname = "";
  }

  formconverstion(_MyOrderHistories: Myordershistory[], documents: File[],) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    for (var x in documents) {
      var index = _MyOrderHistories.findIndex(obj => obj.attachmentname == documents[x].name);
      if (index != -1) {
        var NewFileName = time.getTime() + '_' + documents[x].name;
        _MyOrderHistories[index].attachmentname = NewFileName;
        _MyOrderHistories[index].attachmenturl = NewFileName;
        formData.append('files', documents[x], NewFileName);
      }
    }
    this._CODHistoryObj = {
      "Myordershistory": this.selectedCODHistoriesRows,
      "Branchid": this._branchid,
      "Tellerid": this.Tellerid,
      // "AmountCollectedDate":
    };
    var CODUpload = JSON.stringify(this._CODHistoryObj);
    formData.append('strmo', CODUpload);
    return (formData);
  }
  onSave() {
    if (!this.Tellerid) {
      this._CustomExceptionService.handleError("Please Select Teller");
      return;
    }
    if (!this.selectedCODHistoriesRows || !this.selectedCODHistoriesRows.length) {
      this._CustomExceptionService.handleError("Please select atleast one row");
      return;
    }
    for (var i = 0; i < this.selectedCODHistoriesRows.length; i++) {
      this.selectedCODHistoriesRows[i].isamountcollected = true
    }
    this._CODFORM = this.formconverstion(this.selectedCODHistoriesRows, this._selectedDocuments);
    this._IsProgressSpinner = true;
    this._CODPaymentStatus.Create(this._CODFORM).subscribe((result) => {
      const resutJSON = JSON.parse(JSON.stringify(result));
      if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
        this._IsProgressSpinner = false;
        this.reset();
      }
      else {
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);

      } this._IsProgressSpinner = false;
    },

      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }



  SelectAll(event) {

  }
  reset() {
    this.Tellerid =0;
    this.CODHistoryGrid = [] = [];
    this.selectedCODHistoriesRows = [] = [];
  }
}
