import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RptdeliverednotdeliveredService {

  public _RptDeliveredNotDeliveredService = environment.DeliveredNotDeliveredService;
  constructor(private http: HttpClient) { }

  PageOnload(userid:number): Observable<any> {
    var Query = this._RptDeliveredNotDeliveredService+"/"+userid;
    
    return this.http.get<{ data: any }>(Query);
  }
  getProductwiselockedqty(branchid: number,userid:number) {
    var Query = this._RptDeliveredNotDeliveredService + '/ProductWiseDeliveredUnDeliveredQuantity/' + branchid+"/"+userid;
    
    return this.http.get<{ data: any }>(Query);
  }
  getlockedqtydetail(branchid: number, productid: number,userid:number) {
    var Query = this._RptDeliveredNotDeliveredService + '/DetailWiseDeliveredUnDeliveredQuantity/' + branchid + '/' + productid+"/"+userid;
    
    return this.http.get<{ data: any }>(Query);
  }
  
}
