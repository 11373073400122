import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import { RptstocktransferService } from 'src/app/core/Services/reports/Stock/StockTransfer/rptstocktransfer.service';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Producttype } from 'src/app/core/Models/Producttype';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-stocktransfer',
  templateUrl: './stocktransfer.component.html',
  styleUrls: ['./stocktransfer.component.css']
})
export class StocktransferComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _supplierlist: Supplier[];
  _branchlist: Branch[];
  _currencylist: Currency[];
  _stocktransfertypelist: Metadatum[];
  _stocktransferform: FormGroup;
  _branchid: number;
  _userid: number;
  _src: SafeResourceUrl;
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _StockTransferPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  _brandlist: any;
  brandid: number[] = [];
  _ProductObj: any;
  _Tempbrandlist: any;
  _fromlocationbranchlist: Branch[] = [];
  _tolocationbranchlist: Branch[] = [];
  _tempfromlocationbranchlist: Branch[] = [];
  _temptolocationbranchlist: Branch[] = [];
  mindate: Date;
  maxdate: Date;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptStockTransferService: RptstocktransferService,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._stocktransferform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      productid: [0],
      currencyid: [0],
      productcategoryid: [0],
      producttypeid: [0],
      stocktransfertype: [0],
      frombranchid: [0],
      tobranchid: [0]
    })
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._stocktransferform.get("fromdate").setValue(new Date);
      this._stocktransferform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._stocktransferform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._stocktransferform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._stocktransferform.get("fromdate").value;
    dt.setDate(dt.getDate() - 1);
    this._stocktransferform.controls['fromdate'].setValue(dt);

    this._RptStockTransferService.PageOnload(this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._supplierlist = resultJSON.supplierList;
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._producttypelist = resultJSON.producttypeList;
      this._stocktransfertypelist = resultJSON.stocktransfertypeList;
      this._brandlist = resultJSON.brandList;
      this._Tempbrandlist = resultJSON.brandList;
      this._IsProgressSpinner = false;
      this._fromlocationbranchlist = resultJSON.branchList;
      this._tolocationbranchlist = resultJSON.branchList;
      this._tempfromlocationbranchlist = resultJSON.branchList;
      this._temptolocationbranchlist = resultJSON.branchList;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._stocktransferform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._stocktransferform.get("fromdate").setValue(new Date);
      this._stocktransferform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._stocktransferform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._stocktransferform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._stocktransferform.get("fromdate").value;
    dt.setDate(dt.getDate() - 1);
    this._stocktransferform.controls['fromdate'].setValue(dt);
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this._productcategorylist = [];
    this._productlist = [];
    this.brandid = [];
    this._brandlist = this._Tempbrandlist
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  GenerateReport(event) {
    var _fromdate = this._stocktransferform.get("fromdate").value;
    var _todate = this._stocktransferform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than To Date");
      return;
    }
    var currencyid = this._stocktransferform.get("currencyid").value == null ? 0 : this._stocktransferform.get("currencyid").value;
    var frombranchid = this._stocktransferform.get("frombranchid").value == null ? 0 : this._stocktransferform.get("frombranchid").value;
    var tobranchid = this._stocktransferform.get("tobranchid").value == null ? 0 : this._stocktransferform.get("tobranchid").value;
    var mediaType = 'application/pdf';
    this._StockTransferPrintObj = { "Productcategory": this.productcatid || [], "Producttype": this.producttypeid, "Productheader": this.productid, "branchid": parseInt(sessionStorage["currentbranchid"]), "Frombranchid": frombranchid, "Tobranchid": tobranchid, "Curencyid": currencyid, "Userid": parseInt(sessionStorage["userid"]), "FromDate": fromdate, "ToDate": todate, "brandid": this.brandid || [] };
    this._IsProgressSpinner = true;
    this._RptStockTransferService.Print(this._StockTransferPrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptStockTransferService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("category", resultJSON);
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptStockTransferService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productList;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  ChangeProduct(event) {
    var selectedProduct = event.value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._brandlist = this._Tempbrandlist
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductObj = { "Products": selectedProduct };
      this._RptStockTransferService.getBrand(this._ProductObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("brand", resultJSON);
        let productbrand = resultJSON.productbrandlist;
        let uniqueChars = [...new Set(productbrand)];
        uniqueChars = productbrand.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.brandid === test.brandid
          )
        );
        this._brandlist = uniqueChars;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  OnChangeFromBranch(event) {
    var selectedbranch = event.value;
    this._tolocationbranchlist = (this._temptolocationbranchlist || []).filter(f => f.branchid != selectedbranch)
  }
  OnChangeToBranch(event) {
    var selectedbranch = event.value;
    this._fromlocationbranchlist = (this._tempfromlocationbranchlist || []).filter(f => f.branchid != selectedbranch)
  }
}