import { Component, OnInit } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Role } from 'src/app/core/Models/Role';
import { Function } from 'src/app/core/Models/Function';
import { RolewisescreenmappingService } from 'src/app/core/Services/users/rolewisescreenmapping.service';
import { vModuleNameSelect, vRoleNameSelect } from 'src/app/core/Validators/validation';
import { Functionrolemap } from 'src/app/core/Models/Rolewisescreenmapping';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Rolewisescreenmappingview } from 'src/app/core/Views/Rolewisescreenmappingview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

@Component({
  selector: 'app-c-role-wise-screen-mapping',
  templateUrl: './c-role-wise-screen-mapping.component.html',
  styleUrls: ['./c-role-wise-screen-mapping.component.css']
})
export class CRoleWiseScreenMappingComponent implements OnInit {
  _rolewisescreenmappingform: FormGroup;
  _roles: Role;
  _modules: Function;
  _Screens: Rolewisescreenmappingview[] = [];
  _Screen: Rolewisescreenmappingview;
  _roleid: string;
  _RoleWiseScreenMapping: Functionrolemap;
  _submitted = false;
  _action: string = "create";
  _FunctionRolesMap: Functionrolemap[] = [];
  // _ScreenId : number = 0;
  selectedrows: any;
  pSelectableRow: any;
  selectedRole: any;
  _selectedScreens: any;
  _selectedModuleArrayIndex: number[] = [];
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  rowsempty: boolean;
  constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, private _RoleWiseScreenMappingService: RolewisescreenmappingService, private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService, private _hotkeysService: HotkeysService) {
    this.HotKeyIntegration();
    // //Save Short cut keys
    // this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
    //   this.onSave();
    //   return false; // Prevent bubbling
    // }));
    // //Clear
    // this._hotkeysService.add(new Hotkey('alt+x', (event: KeyboardEvent): boolean => {
    //   this.reset(event);
    //   return false; // Prevent bubbling
    // }));
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
   
  }
  InitializeForm() {
    this._rolewisescreenmappingform = this.fb.group({
      roleid: ['', vRoleNameSelect],
      functionid: ['', vModuleNameSelect],
      functionrolemapstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: []
    });
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(33);
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;
    this._RoleWiseScreenMappingService.PageOnLoad().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._roles = resultJSON.roles;
      this._modules = resultJSON.modules;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  getScreenList(event) {
    var selectedFunctions = event.value;
    var roleid = this.selectedRole;
    if (selectedFunctions.length == 0 || selectedFunctions == null) {
      this._Screens = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._RoleWiseScreenMappingService.getFunctions(selectedFunctions, roleid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Screens = resultJSON.allscreens;
        //selected functions
        this._selectedScreens = resultJSON.Rolewisescreenmappingviews;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      //return true;
    }
  }

  GetDefualtModules(event) {
    var selectedFunctions = event.value;
    this.selectedRole = event.value;
    this._IsProgressSpinner = true;
    this._RoleWiseScreenMappingService.GetDefualtModules(selectedFunctions).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      var selectedModuleArrayIndex: number[] = [];
      var selectedModulesObj = resultJSON.modules;

      Object.keys(selectedModulesObj).map(function (index) {
        selectedModuleArrayIndex.push(selectedModulesObj[index].functionid)
      });
      this._selectedModuleArrayIndex = selectedModuleArrayIndex;

      var removeUnselectedModules: Rolewisescreenmappingview[] = [];
      var selectedModules: Rolewisescreenmappingview[] = [];
      removeUnselectedModules = resultJSON.allscreens;
      Object.keys(selectedModuleArrayIndex).map(function (Index) {
        var selRows = removeUnselectedModules.filter(obj => obj.moduleid == selectedModuleArrayIndex[Index]);
        selRows.forEach(element => {
          selectedModules.push(element);
        });
      });
      this._Screens = selectedModules;
      //Already selected screen needs to be defaulted
      this.selectedrows = resultJSON.rolewisescreenmappingviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onScreenSelection(event) {
  }

  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  SelectAll(event){
  }
  onSave() {
    this._submitted = true;
    if (this._rolewisescreenmappingform.valid) {
      this.fillFunctionRoleMap();
      if (this.rowsempty == false) {
        this._RoleWiseScreenMapping = this._rolewisescreenmappingform.value;
        var FunctionRoleOBJ = { "Functionrolemap": this._FunctionRolesMap };
        if (this._action == 'create') {
          this._RoleWiseScreenMapping.createdon = new Date();
          this._RoleWiseScreenMapping.createdby = sessionStorage["userid"];
          this._IsProgressSpinner = true;
          this._RoleWiseScreenMappingService.create(FunctionRoleOBJ).subscribe((result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            this._IsProgressSpinner = false;
            if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
              this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
              //this.reset(null);
            }
            else {
              this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            }
            this._IsProgressSpinner = false;
          },

            error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        }
      }
      else {
        this._CustomExceptionService.handleError("Please Map atleast One Screen for this Role");
      }
    }
  }
  // All entered input fields should be cleared.
  reset(event) {
    //  this._rolewisescreenmappingform.reset();
    this._Screens = [];
    this.InitializeForm();
    this._submitted = false;
  }
  fillFunctionRoleMap() {
    var selectedscreens = this.selectedrows
    var FunctionRolesMap: Functionrolemap[] = [];
    var tempFunctions: Functionrolemap;
    var roleid: any = this._rolewisescreenmappingform.get("roleid").value;
    if (this.selectedrows.length > 0) {
      Object.keys(selectedscreens).map(function (Index) {
        tempFunctions = new Functionrolemap();
        tempFunctions.roleid = roleid;
        tempFunctions.functionid = selectedscreens[Index].screenid;
        tempFunctions.functionrolemapstatus = "MSC00001"
        tempFunctions.createdon = new Date();
        tempFunctions.modifiedon = new Date();
        tempFunctions.createdby = sessionStorage["userid"];
        tempFunctions.modifiedby = sessionStorage["userid"];
        FunctionRolesMap.push(tempFunctions);
        //other values needs to be filled even it is null;            
      });
      this._FunctionRolesMap = FunctionRolesMap;
      this.rowsempty = false;
    }
    else {
      this.rowsempty = true;

    }
  }

}