import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Termsandcondition } from 'src/app/core/Models/Termsandcondition';
import { TermsandconditionService } from 'src/app/core/services/masters/termsandcondition.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { vBuisnessTypeSelect, vDetails, vPartyTypeSelect, vStatusSelect, vTermsTypeSelect } from 'src/app/core/Validators/validation';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-c-terms-and-condition',
  templateUrl: './c-terms-and-condition.component.html',
  styleUrls: ['./c-terms-and-condition.component.css']
})
export class CTermsAndConditionComponent implements OnInit {
  _termsandconditionform: FormGroup;
  _termsandconditionid: number;
  _termsandcondition: Termsandcondition;
  //19. Party type dropdown load from metadata (customer,supplier)
  //20. business type dropdown load from metadata (local,international)
  //21. Terms type dropdown load from metadata (sales,delivery,others) based on party type(customer )
  //22. Terms type dropdown load from metadata (purchase,delivery,others) based on party type(supplier)
  //24. status dropdown load from metadata table
  //31. status is drop down column load from metadata(active, in active)
  _partytypes: Metadatum;
  _businesstypes: Metadatum;
  _termstypes: Metadatum;
  _termsandconditionstatus: Metadatum;//14. status must save default as active while saving(active,inactive)
  _action: string = "create";
  _submitted = false;
  _IsProgressSpinner: boolean = true;
  isDisabledSave = false;
  isDisabledClear = false;
  _Action: IAction;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder,
    private _CustomExceptionService: CustomExceptionService,
    private _route: ActivatedRoute,
    private _AccessRightsService: AccessRightsService,
    private _TermsandconditionService: TermsandconditionService, private _router: Router, private utility: CommonUtilities, private _hotkeysService: HotkeysService) {
    this._termsandconditionid = history.state?.termsandconditionid ? history.state?.termsandconditionid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._termsandconditionform = this.fb.group({
      companyid: [0],
      branchid: [],
      termsandconditionid: [0],
      //8. if "party type" dropdown is not select, show error as "Please select party type"
      //9. if "business type" dropdown is not select, show error as "Please select business type"
      //10. if "Terms type" dropdown is not select, show error as "Please select Terms type"
      //11. if "Details" textarea is not enter, show error as "Please Enter Details"(Textarea)
      // 32.if "Status " is not select, show error as "Please Select Status"
      partytype: ['', vPartyTypeSelect],
      businesstype: ['', vBuisnessTypeSelect],
      termstype: ['', vTermsTypeSelect],
      termsdescription: ['', vDetails],
      termsandconditionstatus: ['', vStatusSelect],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: []
    });
    this._termsandconditionform.controls['termsandconditionstatus'].setValue("MSC00001");
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(27);
    this.utility.pageLoadScript();
    this.InitializeForm();

    this._IsProgressSpinner = true;

    if (this._action == 'view') {
      this._termsandconditionform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
    }
    if (this._action == 'edit') {
      this.isDisabledClear = true;
      this.isDisabledSave = false;
      this._termsandconditionform.controls['partytype'].disable();
      this._termsandconditionform.controls['businesstype'].disable();
      this._termsandconditionform.controls['termstype'].disable();
    }
    this._TermsandconditionService.PageOnLoad(this._termsandconditionid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // throw this._CustomExceptionService.handleError("Please  fill customer form properly");
      this._partytypes = resultJSON.partyTypes;
      this._businesstypes = resultJSON.businessTypes;
      this._termstypes = resultJSON.termsFor;
      this._termsandconditionstatus = resultJSON.termsandConditionStatus;
      this._termsandconditionform.controls['termsandconditionstatus'].setValue("MSC00001");
      this._IsProgressSpinner = false;
      //For Update
      this._termsandconditionid = history.state.termsandconditionid;

      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        // updateJSON.termsandcondition.createdon = new Date(updateJSON.termsandcondition.createdon);
        // updateJSON.termsandcondition.modifiedon = new Date(updateJSON.termsandcondition.modifiedon);
        this._termsandconditionform.setValue(updateJSON.termsandcondition);
      }
    });
    //error => { this._TermsandconditionService.handleError(error); this._IsProgressSpinner = false; });

  }
  onSave() {
    this._submitted = true;
    if (this._termsandconditionform.valid) {
      this._termsandcondition = this._termsandconditionform.getRawValue();
      this._termsandcondition.branchid = sessionStorage["BranchID"];
      // this.onEd();
      //12. check in the Terms&Condittions table whether details is already given, show error as "Terms&conditions A already Exists"
      //13. if all the data is valid allow to save the record in the database
      if (this._action == 'create') {
        // this._termsandcondition.accountingyear= new Date("YYYY").getFullYear();
        this._termsandcondition.createdon = new Date();
        this._termsandcondition.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._TermsandconditionService.create(this._termsandcondition).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            //15. after saving the record, get the response A, display the success message as "Terms & Conditions A registered Succesfully "
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsProgressSpinner = false;
            this._submitted = false;
            this.reset(null);
            this._termsandconditionform.controls['termsandconditionstatus'].setValue("MSC00001");
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      //33. if all the data is valid allow to Update the record in the database based on terms id
      //34. after Updating the record, get the response A, display the success message as "Terms&Conditions A Modified Succesfully "
      else if (this._action == 'edit') {
        this._termsandcondition.modifiedon = new Date();
        this._termsandcondition.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._TermsandconditionService.edit(this._termsandcondition).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this._IsProgressSpinner = false;

          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
  reset(event) {
    //16. clear all the fields which the data has entered
    this._termsandconditionform.reset();
    this.InitializeForm();
    this._submitted = false;
  }
  goBack(event) {
    //18.on clicking update button in search grid , it Navigates to the update screen
    //35. on clicking back button , it Navigates to the search screen
    //36. saved record should show based on the modified date in the search screen
    this._router.navigate(['/vTermsAndCondition']);
  }

  // onEd() {
  //   alert("test");
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._termsandcondition;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }


  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
      if (this._action == 'create') {
        this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
          this.reset(null);
          return false; // Prevent bubbling
        }, ['INPUT', 'SELECT', 'TEXTAREA']));
      }
      //this._hotkeysService.add(new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
      //  this.router.navigate(['/cBank'], { state: { bankid: this._bankid, action: 'edit' }});
      //  return false; // Prevent bubbling
      //}));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}
