<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Account Ledger</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add"
                                (click)="GoToCreate($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>


                            </ng-template>
                            <ng-template pTemplate="right">
                                <!-- <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                    (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                    tooltipPosition="bottom"></button> -->
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                    (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                    tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                    (click)="ExportToPdf()" class="p-button-warning p-mr-2" pTooltip="PDF"
                                    tooltipPosition="bottom"></button>
                                <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                    (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                    pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                            </ng-template>

                        </p-toolbar>
                        <p-table #dt [value]="_Accountledgerviews" [rows]="10" [paginator]="true"
                            [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['name', 'accountgroupname', 'status']"
                            [rowHover]="true" dataKey="name" [(selection)]="selectedrows"
                            (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="text-center" style="width:120px;">Action
                                    </th>
                                    <th pSortableColumn="name">Ledger Name<p-sortIcon field="name">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="accountgroupname">Account Group<p-sortIcon
                                            field="accountgroupname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="status">Status<p-sortIcon
                                        field="status">
                                    </p-sortIcon>
                                </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_Accountledgerview>
                                <tr>
                                    <td class="text-center">
                                        <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2"
                                            (click)="view(_Accountledgerview)" [disabled]="!_Action._View"></button>
                                        <!-- <button pButton pRipple icon="pi pi-trash" title="Delete"
                                            class="p-button p-button-danger" [disabled]="!_Action._Delete"
                                            (click)="delete(_Accountledgerview)"></button> -->
                                    </td>
                                    <td>
                                        {{_Accountledgerview.name}}
                                    </td>
                                    <td>
                                        {{_Accountledgerview.accountgroupname}}
                                    </td>
                                    <td>
                                        {{_Accountledgerview.status}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </form>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>