import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PurchaseorderService {

  public _Purchaseorderservice = environment.PurchaseorderService;
  public _RptPOPrint = environment.RptPOPrintService;
  public _sendmailPrint = environment.sendMailService;

  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
    const headers = this.header;
    var Query = this._Purchaseorderservice + '/FetchAll' + '/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      return data;
    }));
  }
  PageOnLoad(purchaseorderid: number, purchaseorderno: string, branchid: number, userid: number): Observable<any> {
    const headers = this.header;
    var Query = this._Purchaseorderservice + '/PageInitialize' + '/' + purchaseorderid + '/' + purchaseorderno + '/' + branchid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  onchangesuppliername(supplierid: number) {
    const headers = this.header;
    var Query = this._Purchaseorderservice + '/OnSupplierNameChange' + '/' + supplierid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  getSupplier(suppliertypecode: string, userid: number) {
    const headers = this.header;
    var Query = this._Purchaseorderservice + '/getSupplier/' + suppliertypecode + '/' + userid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  onchangerefno(productcategoryid: number[]) {
    const headers = this.header;
    let paramvalue = "RefNoList="
    paramvalue = paramvalue + productcategoryid.join('&RefNoList=');
    var Query = this._Purchaseorderservice + '/OnRefNoChange/' + '?' + paramvalue;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  getProductCategory(producttype: object) {
    const headers = this.header;
    var Query = this._Purchaseorderservice + '/OnProductTypeChange';
    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }
  getProduct(productcategory: object) {
    const headers = this.header;
    var Query = this._Purchaseorderservice + '/OnProductCategoryChange';
    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }
  getVariant(productid: number, branchid: number) {
    const headers = this.header;
    var Query = this._Purchaseorderservice + '/OnProductNameChange' + '/' + productid + '/' + branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  OnRefNoChange(producttypeid: number[], supplierid: number) {
    const headers = this.header;
    let paramvalue = "RefNoList="
    paramvalue = paramvalue + producttypeid.join('&RefNoList=');
    var Query = this._Purchaseorderservice + '/OnRefNoChange/' + supplierid + '?' + paramvalue;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  edit(p_Purchase: object) {
    const headers = this.header;
    var Query = this._Purchaseorderservice;
    return this.http.put<{ data: any }>(Query, p_Purchase, { headers });
  }
  create(p_Purchase: object) {
    const headers = this.header;
    var Query = this._Purchaseorderservice;
    return this.http.post<{ data: any }>(Query, p_Purchase, { headers });
  }
  SendToApproval(userid: number, branchid: number, Purchaseorderno: string) {
    const headers = this.header;

    var Query = this._Purchaseorderservice + '/SendToApproval' + '/' + userid + '/' + branchid + '/' + Purchaseorderno;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  Cancel(purchaseorderid: number): Observable<any> {
    const headers = this.header;

    var Query = this._Purchaseorderservice + '/Cancel' + '/' + purchaseorderid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  CancelInwardedorder(purchaseorderid: string): Observable<any> {
    const headers = this.header;
    var Query = this._Purchaseorderservice + '/Cancelinwardedorder' + '/' + purchaseorderid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetProduct(product: object) {
    const headers = this.header;
    var Query = this._Purchaseorderservice + '/GetProduct';
    return this.http.post<{ data: any }>(Query, product, { headers });
  }
  // Print(purchaseorderno: string, branchid: number, tempprint : number) {
  //   var Query = this._RptPOPrint + '/Print/' + purchaseorderno + '/' + branchid + '/' + tempprint;
  //   window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
  // }
  Print(product: object) {
    const headers = this.header;
    var Query = this._Purchaseorderservice + '/POPrint';
    return this.http.post(Query, product, { responseType: 'blob' ,  headers });
  }
  SendMail(userid: number, branchid: number, Purchaseorderno: string) {
    const headers = this.header;
    var Query = this._Purchaseorderservice + '/PoMail' + '/' + userid + '/' + branchid + '/' + Purchaseorderno;
    return this.http.get<{ data: any }>(Query, { headers });
  }


}
