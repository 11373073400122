<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Stock Adjustment</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" [disabled]="isDisabledsave" type="submit" (click)="onSave()" title="Save" icon="pi pi-save" class=""></button>
                            <button pButton pRipple label="" [disabled]="isDisablereset" type="button" (click)="reset($event)" title="Clear" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button" (click)="SendToApproval($event)" [disabled]="isDisabledsendapparoval"></button>
                            <button pButton pRipple [disabled]="isDisabledprint" title="Print" icon="las la-print"></button>
                            <!-- 27.should redirect to search page. -->
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search" type="button" (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_stockadjustmentform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="stocktakeno">
                                    <label for="id">Stock Taker No</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_cycleno" [filter]="true"
                                        filterBy="metasubdescription" [showClear]="true" optionValue="metasubcode"
                                        formControlName="cycleno" optionLabel="metasubdescription">
                                    </p-dropdown>
                                    <label for="id">Cycle No <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockadjustmentform.get('cycleno').touched || _submitted) && _stockadjustmentform.get('cycleno').errors?.CyclenoRequired">
                                    Please Select Cycle No
                                </span>
                                <!-- 15.if "Cycle No." is empty, show error "Please Select Cycle No." -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" name="stocktakedate" formControlName="stocktakedate"
                                    [minDate]="mindate" [maxDate]="maxdate"
                                        dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="stocktakedate">Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockadjustmentform.get('stocktakedate').touched || _submitted) && _stockadjustmentform.get('stocktakedate').errors?.StockAdjustmentdateRequired">
                                    Please Select Date
                                </span>
                            </td>
                            <td style="width: 20%;">
                            </td>
                            <td style="width: 20%;">
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_stocktakers"
                                        optionValue="employeeid" [filter]="true" filterBy="employeename"
                                        [virtualScroll]="true" itemSize="15" [showClear]="true"
                                        formControlName="teamlead" optionLabel="employeename">
                                    </p-dropdown>

                                    <label for="id">Team Lead <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockadjustmentform.get('teamlead').touched || _submitted) && _stockadjustmentform.get('teamlead').errors?.StocktakerRequired">
                                    Please Select Team Lead
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_stocktakers"
                                        optionValue="employeeid" [filter]="true" filterBy="employeename"
                                        [virtualScroll]="true" itemSize="15" [showClear]="true"
                                        formControlName="stocktaker1" optionLabel="employeename">
                                    </p-dropdown>

                                    <label for="id">Stock Taker 1 <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockadjustmentform.get('stocktaker1').touched || _submitted) && _stockadjustmentform.get('stocktaker1').errors?.SelectStockTakers">
                                    Please Select Stock Taker 1
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_stocktakers"
                                        optionValue="employeeid" [filter]="true" filterBy="employeename"
                                        [virtualScroll]="true" itemSize="15" [showClear]="true"
                                        formControlName="stocktaker2" optionLabel="employeename">
                                    </p-dropdown>

                                    <label for="id">Stock Taker 2 <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockadjustmentform.get('stocktaker2').touched || _submitted) && _stockadjustmentform.get('stocktaker2').errors?.SelectStockTakers">
                                    Please Select Stock Taker 2
                                </span>
                            </td>
                            <td style="width: 20%;">
                            </td>
                            <td style="width: 20%;">
                            </td>
                        </tr>
                    </table>
                </form>

                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_stockadjustmentdetailform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <form [formGroup]="_stockadjustmentproductform">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_producttype" optionLabel="producttypename"
                                            formControlName="producttypeid" [disabled]="isDisabledproducttype"
                                            [virtualScroll]="true" itemSize="30" optionValue="producttypeid"
                                            (onChange)="getProductCategory($event)" displaySelectedLabel=true>
                                        </p-multiSelect>
                                        <label for="producttypeid">Product Type <span class="hlt-txt">*</span></label>
                                    </span>
                                    <span class="text-danger" *ngIf="(_stockadjustmentproductform.get('producttypeid').touched || _submitted1) && _stockadjustmentproductform .get('producttypeid').errors?.SelectProductType">
                                        Please Select Product Type
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_stockadjustmentproductform">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_productcategory" formControlName="productcategoryid"
                                            [disabled]="isDisabledproductcategory" optionLabel="categoryname"
                                            [virtualScroll]="true" itemSize="30" optionValue="productcategoryid"
                                            (onChange)="getProduct($event)" displaySelectedLabel=true>
                                        </p-multiSelect>
                                        <label for="productcategoryid">Product Category <span
                                                class="hlt-txt">*</span></label>
                                    </span>
                                    <span class="text-danger" *ngIf="(_stockadjustmentproductform.get('productcategoryid').touched || _submitted1) && _stockadjustmentproductform .get('productcategoryid').errors?.SelectProductCategory">
                                        Please Select Product Category
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_productname" optionValue="productid"
                                    [filter]="true" filterBy="searchfilter" [showClear]="true" optionLabel="productname"
                                    formControlName="productid" (onChange)="getVariant($event)">
                                </p-dropdown> -->
                                    <p-multiSelect [options]="_productname" formControlName="productid"
                                        optionLabel="productname" optionValue="productid" [virtualScroll]="true"
                                        itemSize="30" (onChange)="getproductdetails($event)" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockadjustmentdetailform.get('productid').touched || _productsubmitted) && _stockadjustmentdetailform .get('productid').errors?.ProductNameRequired">
                                    Please Select Product
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_brandlist" formControlName="brandid"
                                        optionLabel="brandname" optionValue="brandid" [virtualScroll]="true"
                                        itemSize="30" (onChange)="getbranddetails($event)" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="brandid">Brand Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>


                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>

                    <p-table #dt [value]="_productdetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['productname','variantdescription','closingstock','actualstock','stockdifference','unitprice','amount','remarks']" [rowHover]="true"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 50px;">
                                    <p-tableHeaderCheckbox (click)="SelectAll($event)" [disabled]="_action == 'view'">
                                    </p-tableHeaderCheckbox>
                                </th>
                                <th>Product Name</th>
                                <th>Variant Name</th>
                                <th>Lot Number</th>
                                <th>Supplier</th>
                                <th>GRN Qty</th>
                                <th>Invoice Qty</th>
                                <th>Sales Return Qty</th>
                                <th>Purchase Return Qty</th>
                                <th>Closing Stock</th>
                                <th>Actual Ok Stock</th>
                                <th>Damaged Stock</th>
                                <th>Severity</th>
                                <th>Stock Difference</th>
                                <th>Rate</th>
                                <th>Stock Value</th>
                                <th>Difference Value</th>
                                <th>Remarks</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_productdetail let-ri="rowIndex">
                            <tr>
                                <td style="text-align: center;width: 50px;">
                                    <p-tableCheckbox [value]="_productdetail" [disabled]="isDisabledselectall">
                                    </p-tableCheckbox>
                                </td>
                                <td>
                                    {{_productdetail.productname}}
                                </td>

                                <td>
                                    {{_productdetail.variantdescription}}
                                </td>
                                <td>
                                    {{_productdetail.lotnumber}}
                                </td>
                                <td>
                                    {{_productdetail.suppliername}}
                                </td>
                                <td>
                                    {{_productdetail.grnquantity}}
                                </td>
                                <td>
                                    {{_productdetail.salesinvoiceqty}}
                                </td>
                                <td>
                                    {{_productdetail.salesreturnqty}}
                                </td>
                                <td>
                                    {{_productdetail.purchasereturnqty}}
                                </td>
                                <td>
                                    {{_productdetail.closingstock}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" (onBlur)="onchangeactualstock(_productdetail,$event)" *ngIf="_productdetail.uomcode != 'MSC00039'&& _action != 'view' " (keyup)="onchangeactualstock(_productdetail,$event)" (keyup.enter)="onchangeactualstock(_productdetail,$event)"
                                                (Keydown.enter)="onchangeactualstock(_productdetail,$event)" (focusout)="onchangeactualstock(_productdetail,$event)" maxlength="13" (keypress)="keypress.kpNumber($event)" [(ngModel)]="_productdetail.actualstock"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                            </p-inputNumber>
                                            <p-inputNumber autocomplete="off" (onBlur)="onchangeactualstock(_productdetail,$event)" *ngIf="_productdetail.uomcode == 'MSC00039'&& _action != 'view' " (keyup)="onchangeactualstock(_productdetail,$event)" (keyup.enter)="onchangeactualstock(_productdetail,$event)"
                                                (Keydown.enter)="onchangeactualstock(_productdetail,$event)" (focusout)="onchangeactualstock(_productdetail,$event)" maxlength="13" (keypress)="keypress.kpNumber($event)" [(ngModel)]="_productdetail.actualstock"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                            </p-inputNumber>
                                            <div *ngIf="_action == 'view'">{{_productdetail.actualstock}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_productdetail.actualstock}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_productdetail.actualstock" 
                                            *ngIf="_action != 'view'" 
                                            (onBlur)="onchangeactualstock(_productdetail,$event)" 
                                            (keypress)="onchangeactualstock(_productdetail,$event)" 
                                            (keyup)="onchangeactualstock(_productdetail,$event)" pInputText>
                                            <div *ngIf="_action == 'view'">{{_productdetail.actualstock}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_productdetail.actualstock}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_productdetail.damagedstock" [disabled]="_productdetail.isdamagedstock" *ngIf="_action != 'view'" (onBlur)="onchangeactualstock(_productdetail,$event)" (keypress)="onchangeactualstock(_productdetail,$event)" (keyup)="onchangeactualstock(_productdetail,$event)"
                                                (Keydown.enter)="onchangeactualstock(_productdetail,$event)" pInputText>

                                            <div *ngIf="_action == 'view'">{{_productdetail.damagedstock}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_productdetail.damagedstock}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_severities" [showClear]="true" optionLabel="metasubdescription" optionValue="metasubcode" [disabled]="_productdetail.isseverity || _action == 'view'" [(ngModel)]="_productdetail.severity"></p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [disabled]="true" [autoDisplayFirst]="false" [showClear]="true" [options]="_severities" optionLabel="metasubdescription" [disabled]="_productdetail.isseverity || _action == 'view'" optionValue="metasubcode" [(ngModel)]="_productdetail.severity">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_productdetail.stockdifference}}
                                </td>
                                <td>
                                    {{_productdetail.unitprice}}
                                </td>
                                <td>
                                    {{_productdetail.stockamount}}
                                </td>
                                <td>
                                    {{_productdetail.amount}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" [(ngModel)]="_productdetail.remarks" *ngIf="_action != 'view'" pInputText>
                                            <div *ngIf="_action == 'view'">{{_productdetail.remarks}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_productdetail.remarks}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-toast position="bottom-right"></p-toast>
            </div>
        </div>
    </div>
</div>