import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Brand } from 'src/app/core/Models/Brand';
import { Currency } from 'src/app/core/Models/Currency';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { RptLockedQuantityDetailReportService } from 'src/app/core/Services/reports/Stock/RptLockedQuantityDetailReport/rpt-locked-quantity-detail-report.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
@Component({
  selector: 'app-rpt-lockedquantitydetailreport',
  templateUrl: './rpt-lockedquantitydetailreport.component.html',
  styleUrls: ['./rpt-lockedquantitydetailreport.component.css']
})
export class RptLockedquantitydetailreportComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptLockedDetailform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _userid: number;
  datepipe: any;
  _src: SafeResourceUrl;
  filteredProducts: any[];
  _currencylist: Currency[] = [];
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _SalesReportPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  branchid: number[] = [];
  _invoicetypelist: Metadatum[] = [];
  _brandlist: any;
  brandid: number[] = [];
  _ProductObj: any;
  _Tempbrandlist: Brand;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptLockedQunatityDetailService: RptLockedQuantityDetailReportService,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._rptLockedDetailform = this.fb.group({
      branchid: [0],
      productid: [0],
      productcategoryid: [0],
      producttypeid: [0],
      invoicetype: []
    })
    this._branchlist = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    this._IsProgressSpinner = true;
    this._RptLockedQunatityDetailService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branches;
      this._producttypelist = resultJSON.producttypeList;
      this._invoicetypelist = resultJSON.invoiceType;
      this._brandlist = resultJSON.brandList;
      this._Tempbrandlist = resultJSON.brandList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._rptLockedDetailform.reset();
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this.branchid = [];
    this.brandid = [];
    this._brandlist = this._Tempbrandlist
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptLockedQunatityDetailService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("category", resultJSON);
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptLockedQunatityDetailService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  GenerateReport(event) {
    var Invoicetype = this._rptLockedDetailform.get("invoicetype").value || "";
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._SalesReportPrintObj = {
      "Productheader": this.productid || [], 
      "Branches": this.branchid || [], 
      "branchid": sessionStorage["BranchID"],
      "Userid": parseInt(sessionStorage["userid"]), 
      "InvoiceType": Invoicetype, 
      "brandid": this.brandid || []
    }
    this._RptLockedQunatityDetailService.Print(this._SalesReportPrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ChangeProduct(event) {
    var selectedProduct = event.value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._brandlist = this._Tempbrandlist
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductObj = { "Products": selectedProduct };
      this._RptLockedQunatityDetailService.getBrand(this._ProductObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("brand", resultJSON);
        let productbrand = resultJSON.productbrandlist;
        let uniqueChars = [...new Set(productbrand)];
        uniqueChars = productbrand.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.brandid === test.brandid
          ));
        this._brandlist = uniqueChars;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
}