import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public _Action: IAction;
  public _NotificationService = environment.NotificationService;
  public _httpOptions;
  constructor(private http: HttpClient) { }

  PageOnLoad(pageonload:object): Observable<any> {
    var Query = this._NotificationService+'/PageOnload';
   
    return this.http.post<{ data: any }>(Query, pageonload);
  }

  create(_NotificationFORM: FormData) {
    var Query = this._NotificationService+'/Create';
    return this.http.post<{ data: any }>(Query, _NotificationFORM);
  }
  FetchAll(userid: number,isviewed:boolean) {
    var Query = this._NotificationService + '/FetchAll'+'/' + userid+'/'+isviewed;
   
    return this.http.get<{ data: any }>(Query);
  }
  View(Messageid: number,userid:number) {
    var Query = this._NotificationService + '/View'+'/' + Messageid+'/'+userid;
   
    return this.http.get<{ data: any }>(Query);
  }

  SendItemsFetchAll(userid: number) {
    var Query = this._NotificationService + '/FetchSendItem'+'/' + userid;
   
    return this.http.get<{ data: any }>(Query);
  }
}
