<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
          </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Account Closing</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" (click)="onSave()"
                                [disabled]="_IsSaveHidden" icon="pi pi-save"></button>
                            <button pButton pRipple title="Clear" icon="pi pi-trash" class="p-button-danger"
                                (click)="reset($event)" [disabled]="_IsClearHidden"></button>
                            <button pButton pRipple icon="pi pi-search" title="Back to Search"
                                class="p-button p-button-success p-mr-2" (click)=" goBack($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_accountclosingform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar view="month" dateFormat="mm/yy" [yearNavigator]="true"
                                        [minDate]="minDate" [maxDate]="maxDate" yearRange="2000:2030" [showIcon]="true"
                                        formControlName="month" [readonlyInput]="true"></p-calendar>
                                    <label for="month">Month <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _accountclosingform.get('month').touched) && _accountclosingform.get('month').errors?.SelectMonth">
                                    Please Select Month
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" name="openingbalance" formControlName="openingbalance" disabled
                                        pInputText>
                                    <label for="openingbalance"> Opening Balance</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" name="closingbalance" formControlName="closingbalance" disabled
                                        pInputText>
                                    <label for="closingbalance"> Closing Balance</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" name="creditamount" formControlName="creditamount" disabled
                                        pInputText>
                                    <label for="creditamount"> Credit Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" name="debitamount" formControlName="debitamount" disabled
                                        pInputText>
                                    <label for="debitamount"> Debit Amount</label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" type="button" (click)="SearchMonthWise()"
                                    title="Search" icon="pi pi-search" [hidden]="_IsSearchHidden"></button>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>

                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_Accountsclosing" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['groupName','ledgerName','openingbalance','debit','credit','closingbalance']"
                        [rowHover]="true" dataKey="accountgroupid" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <!-- <th>Action</th> -->
                                <th>Account Group</th>
                                <th>Account Name</th>
                                <th>Opening Balance</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>Closing Balance</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_accountclosing let-ri="rowIndex">
                            <tr>
                                <!-- <td>
                                    <button pButton pRipple title="Add" icon="pi pi-plus"
                                        class="p-button-success p-mr-2"></button>
                                    <button pButton pRipple title="Remove" icon="pi pi-trash"
                                        class="p-button-danger p-mr-2"></button>
                                </td> -->
                                <td>
                                    {{_accountclosing.groupName}}
                                </td>
                                <td>
                                    {{_accountclosing.ledgerName}}
                                </td>
                                <td>
                                    {{_accountclosing.openingbalance}}
                                </td>
                                <td>
                                    {{_accountclosing.debit}}
                                </td>
                                <td>
                                    {{_accountclosing.credit}}

                                </td>
                                <td>
                                    {{_accountclosing.closingbalance}}
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>

                </div>
                <table>
                    <tr>
                        <!-- Debug -->
                        <td>
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>