<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Stock Value Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_branch" optionLabel="branchname" optionValue="branchid"
                                    [(ngModel)]="branchid" [filter]="true" filterBy="branchname"
                                    [ngModelOptions]="{standalone: true}"
                                    [virtualScroll]="true" itemSize="30">
                                    <ng-template let-account pTemplate="item">
                                        <div
                                            [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                            {{account.branchname}}</div>
                                    </ng-template>
                                </p-multiSelect>
                                <label for="branchid">Branch Name <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [filter]="true" [options]="_producttype" [(ngModel)]="producttypeid"
                                    [ngModelOptions]="{standalone: true}" optionLabel="producttypename"
                                    optionValue="producttypeid" (onChange)="getProductCategory($event)"
                                    displaySelectedLabel=true
                                    [virtualScroll]="true" itemSize="30">
                                </p-multiSelect>
                                <label for="producttypeid">Product Type <span class="hlt-txt"></span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [filter]="true" [options]="_productcategory" 
                                    [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}"
                                    optionLabel="categoryname" optionValue="productcategoryid"
                                    (onChange)="getProduct($event)" displaySelectedLabel=true
                                    [virtualScroll]="true" itemSize="30">
                                </p-multiSelect>
                                <label for="productcategoryid">Product Category <span class="hlt-txt"></span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_rptstockvalueform">
                                <span class="p-float-label">
                                    <p-autoComplete formControlName="productid"
                                        (completeMethod)="filterProducts($event)" [suggestions]="filteredProducts"
                                        (onSelect)="GetProducts($event)" field="productname"
                                        [virtualScroll]="true" itemSize="30">
                                        <ng-template let-product pTemplate="item">
                                            <div>{{product.productname}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <label for="productid">Product Name <span class="hlt-txt"></span></label>
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_rptstockvalueform">
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox name="reporttype" binary="true" formControlName="reporttype">
                                        </p-checkbox>
                                        <label for="reporttype">Summary Report</label>
                                    </div>
                                </div>
                            </form>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Generate" icon="las la-print"
                                (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                            <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)"
                                class="p-button-danger"></button>
                        </td>
                    </tr>
                </table>
                <iframe #iframe height="550" width="100%" [src]="_src"></iframe>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>