import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Servicerequest } from 'src/app/core/Models/servicerequest';
import { Servicerequestassetbaseddetail } from 'src/app/core/Models/servicerequestassetbaseddetail';
import { Servicerequestcustomerbaseddetail } from 'src/app/core/Models/servicerequestcustomerbaseddetail';
import { ServicerequestService } from 'src/app/core/Services/workshop/servicerequest.service';
import { vPaymentDateSelect, vSelectAccountgroup } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';

@Component({
	selector: 'app-c-service-request',
	templateUrl: './c-service-request.component.html',
	styleUrls: ['./c-service-request.component.css']
})
export class CServiceRequestComponent implements OnInit {
	_action: string = "create";
	_submitted = false;
	_IsProgressSpinner: boolean = true;
	_IsSaveDisable: boolean = false;
	_servicerequestform: FormGroup;
	_Status: any
	_reftype: any
	_cusorproname: any[] = []
	_refno: any
	customerlists: any
	customerlistsview: any[]
	mobileno: number
	mailid: any
	Address: any
	_Assetrequests: any[] = []
	_Customerrequests: any[] = []
	isDisabledremoveproduct = false;
	Disablecustomer: boolean = false
	Disableasset: boolean = false
	iscustomer: boolean = false
	isDisabledsendapparoval: boolean = true
	customertype: any
	_selectedtype: number
	servicerequestOBJ: Servicerequest
	servicerequestid: number
	Iscustomersrq: boolean = false
	selectedRows: any[] = [];
	datepipe = new DatePipe('en-US');
	_BranchList: any[] = []
	_cusorpronames: Servicerequestassetbaseddetail[] = [];
	_tempcusorpronames: Servicerequestassetbaseddetail[] = [];
	_branchid = sessionStorage["currentbranchid"]
	iscustomerview: boolean = false;
	isrefno: boolean = false;
	_Action: IAction
	getcustomers: any[] = [];
	mindate: Date;
	maxdate: Date;

	constructor(
		private fb: FormBuilder,
		private _CustomExceptionService: CustomExceptionService,
		private _ServicerequestService: ServicerequestService,
		private _router: Router,
		private utility: CommonUtilities,
		private _AccessRightsService: AccessRightsService
	) { }
	InitializeFormGroup(): void {
		this._servicerequestform = this.fb.group({
			servicerequestid: [0],
			servicerequestnumber: [],
			servicerequestdate: [(new Date()), vPaymentDateSelect],
			branchid: [],
			reftype: [],
			productorcustomerid: [0],
			refno: [],
			remarks: [],
			status: ['MSC00045'],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			servicerequestassetbaseddetails: [],
			servicerequestcustomerbaseddetails: [],
			servicebranchid: []
		});
		if (sessionStorage['Environmentenddate'] == "null") {
			this._servicerequestform.get("servicerequestdate").setValue(new Date);
		}
		else {
			let date = new Date(sessionStorage['Environmentenddate'])
			this._servicerequestform.get("servicerequestdate").setValue(date);
		}
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(329);
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.utility.pageLoadScript();
		this.InitializeFormGroup();
		this._IsProgressSpinner = false;
		this.customertype = [
			{ id: 1, name: 'Quick Customer' },
			{ id: 2, name: 'Normal Customer' },
			{ id: 3, name: 'Online Customer' },
			{ id: 4, name: 'Credit Customer' },
		]
		let Servicerequest = history.state?.serviceRequestId ?? 0;
		this._action = history.state?.action ?? this._action;
		let Branchid = sessionStorage["BranchID"];
		let obj = {
			"ServiceRequestId": Servicerequest
		}
		this._IsProgressSpinner = true;
		this._ServicerequestService.PageOnLoad(obj).subscribe((result: any) => {
			this._IsProgressSpinner = false;
			if (result && result.tranStatus.result) {
				console.log(result, 'objectes');
				this._reftype = result.serviceRequestTypes;
				this._Status = result.status;
				this._BranchList = result.branchlist;
				this._servicerequestform.get("servicebranchid").setValue(parseInt(Branchid))
				this._servicerequestform.controls['reftype'].setValue(386)
				if (this._action == 'create') {
					this.getcustomerorasset()
				}
				if (this._action == 'edit' || this._action == 'view') {
					this.iscustomer = true;
					result.serviceRequest.servicerequestdate = new Date(result.serviceRequest.servicerequestdate);
					var id = result.serviceRequest.reftype
					if (id == 386 || id == 414) { //customer based
						this.Disablecustomer = true
						this.Disableasset = false
						this.iscustomerview = true
					}
					else if (id == 387) { //Asset Based
						this.Disablecustomer = false
						this.Disableasset = true
						this.iscustomerview = false
					}
					this._cusorproname = result.productOrCustomerViewList
					this._refno = result.refNoViewList
					this.customerlistsview = result.customerInfoViewList
					this._servicerequestform.setValue(result.serviceRequest);
					var obj = this._cusorproname.find(f => f.productorcustomerid == this._servicerequestform.get("productorcustomerid").value)
					this._servicerequestform.get("productorcustomerid").setValue(obj);
					this._Customerrequests = result.serviceRequestCustomerBasedDetail
					this.selectedRows = result.serviceRequestCustomerBasedDetail
					this._Assetrequests = result.serviceRequestAssetBasedDetail
					if (this.customerlistsview.length > 0) {
						this.mobileno = this.customerlistsview[0].MOBILENO
						this.mailid = this.customerlistsview[0].MAILID
						this.Address = this.customerlistsview[0].ADDRESS
					}
				}
				if (this._action == 'view') { this._servicerequestform.disable() }
			} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); this._IsProgressSpinner = false; }
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	getcustomerorasset() {
		if (this._action == 'create') {
			this._servicerequestform.get('productorcustomerid').reset()
			this.mobileno = null
			this.mailid = null
			this.Address = null
		}
		var id = this._servicerequestform.get("reftype").value
		if (!id) return
		if (id == 386) { //customer based
			this._selectedtype = 1
			this.Disablecustomer = true
			this.Disableasset = false
			this.iscustomer = false
			this._Customerrequests = []
			// this.getcustomer();
		} else if (id == 387) { //Asset Based
			this._selectedtype = 0
			this.Disablecustomer = false
			this.Disableasset = true
			this.iscustomer = true
			this._cusorproname = []
			// let obj = {
			// 	"ServiceRequestType": id,
			// 	"CustomerTypeId": 0
			// }
			// this._IsProgressSpinner = true;
			// this._ServicerequestService.GetProductOrCustomer(obj).subscribe((result: any) => {
			// 	this._IsProgressSpinner = false;
			// 	if (result && result.tranStatus.result) {
			// 		console.log("product", result);
			// 		this._cusorproname = result.productOrCustomerList;
			// 	} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); this._IsProgressSpinner = false; }
			// }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
		else if (id == 414) { //Product Based
			this._selectedtype = 2
			this.iscustomer = true
			this.isrefno = true
			this._Customerrequests = []
		}
	}
	getproduct(event, id: any) {
		if (event.query == "" || event.query == undefined) {
			return;
		}
		else {
			this._cusorproname = []
			let obj = {
				"ServiceRequestType": id,
				"CustomerTypeId": 0,
				"text": event.query
			}
			this._IsProgressSpinner = true;
			this._ServicerequestService.GetProductOrCustomer(obj).subscribe((result: any) => {
				this._IsProgressSpinner = false;
				if (result && result.tranStatus.result) {
					console.log("product", result);
					this._cusorproname = result.productOrCustomerList;
					this._cusorproname = [...this._cusorproname]
				} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); this._IsProgressSpinner = false; }
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	getcustomer(event) {

		// this._Customerrequests = []
		this._refno = []
		this._servicerequestform.get("refno").reset()
		this.mobileno = null
		this.mailid = null
		this.Address = null

		if (event.query == "" || event.query == undefined) {
			return;
		}
		else {
			var id = this._servicerequestform.get("reftype").value
			if (!id) {
				this._CustomExceptionService.handleWarning("Please Select Reference Type");
				return
			}
			let obj: any = {}
			if (id == 386) {
				this._Customerrequests = []
				if (!this._selectedtype) {
					this._CustomExceptionService.handleWarning("Please Select Customer Type");
					return
				}
				obj = {
					"ServiceRequestType": id,
					"CustomerTypeId": this._selectedtype,
					"text": event.query,
					"branchid": sessionStorage["BranchID"]
				}
			}
			else if (id == 414) {
				obj = {
					"ServiceRequestType": id,
					"CustomerTypeId": 0,
					"text": event.query,
					"branchid": sessionStorage["BranchID"]
				}
			}

			else {
				this._Customerrequests = []
				obj = {
					"ServiceRequestType": id,
					"CustomerTypeId": 0,
					"text": event.query,
					"branchid": sessionStorage["BranchID"]
				}
			}
			this._IsProgressSpinner = true;
			this._ServicerequestService.GetProductOrCustomer(obj).subscribe((result: any) => {
				this._IsProgressSpinner = false;
				if (result && result.tranStatus.result) {
					console.log("product", result);
					this._cusorproname = result.productOrCustomerList;
				} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); this._IsProgressSpinner = false; }
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	getrefno() {
		this._servicerequestform.get('refno').reset()
		var id = this._servicerequestform.get("reftype").value;
		var productorcustomerid = this._servicerequestform.get("productorcustomerid").value;

		if (id != 414) {
			this._Customerrequests = [];
			this._Assetrequests = [];
			var customerorproductid = this._servicerequestform.get('productorcustomerid').value.productorcustomerid;
			if (!id) return

			if (!customerorproductid) {
				this._CustomExceptionService.handleWarning("Please Select Customer or Product Name");
				return
			}
			let obj = {
				"ServiceRequestType": id,
				"ProductOrCustomerId": customerorproductid
			}
			this._IsProgressSpinner = true;
			this._ServicerequestService.GetRefNumber(obj).subscribe((result: any) => {
				this._IsProgressSpinner = false;
				if (result && result.tranStatus.result) {
					console.log("refno", result);
					this._refno = result.getAssetOrInvoiceNoList;
					this.customerlists = result.customerInfo;
					if (this.customerlists != null) {
						this.mobileno = this.customerlists.mobileno
						this.mailid = this.customerlists.mailid
						this.Address = this.customerlists.address
					}
				} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); this._IsProgressSpinner = false; }
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
		else {
			// this._Customerrequests = [];
			let obj = {
				"productid": productorcustomerid.productorcustomerid,
				"branchid": sessionStorage["BranchID"]
			}
			this._IsProgressSpinner = true;
			this._ServicerequestService.getproductdetails(obj).subscribe((result: any) => {
				this._IsProgressSpinner = false;
				if (result && result.tranStatus.result) {
					console.log(result.getproductdetails, "details");

					// this._Customerrequests = result.getproductdetails
					// this.selectedRows =[...result.getproductdetails] 

					Object.keys(result.getproductdetails).map((Index) => {
						var currentRow = result.getproductdetails[Index];
						var Customerbased: any = {};

						Customerbased.productid = currentRow.productid;
						Customerbased.productname = currentRow.productname;
						Customerbased.invoicedate = currentRow.invoicedate;
						Customerbased.quantity = currentRow.quantity;
						Customerbased.uomdesc = currentRow.uomdesc;
						Customerbased.uomcode = currentRow.uomcode;
						Customerbased.unitprice = currentRow.unitprice;
						Customerbased.finalamount = currentRow.finalamount;
						Customerbased.invoiceno = currentRow.invoiceno;
						Customerbased.barcode = currentRow.barcode;
						Customerbased.producttypeid = currentRow.producttypeid;
						Customerbased.producttypename = currentRow.producttypename;
						Customerbased.variantid = currentRow.variantid;
						Customerbased.variantname = currentRow.variantname;
						Customerbased.taxid = currentRow.taxid;
						Customerbased.taxpercentage = currentRow.taxpercentage;
						Customerbased.taxamount = currentRow.taxamount;
						Customerbased.discountpercentage = currentRow.discountpercentage;
						Customerbased.discountamount = currentRow.discountamount;
						Customerbased.isschemeapplied = currentRow.isschemeapplied;
						Customerbased.isschemeappliedname = currentRow.isschemeappliedname;
						Customerbased.srquantity = currentRow.srquantity;
						Customerbased.warranty = currentRow.warranty;
						Customerbased.warrantyperiod = currentRow.warrantyperiod;
						Customerbased.iswarrantyapplicable = currentRow.iswarrantyapplicable;
						Customerbased.iswarrantyapplicablename = currentRow.iswarrantyapplicablename;
						Customerbased.warrantydate = currentRow.warrantydate;
						Customerbased.tobranch = currentRow.tobranch;
						Customerbased.warrantyStatus = currentRow.warrantyStatus;

						var products = this._Customerrequests.filter(f => f.productid == currentRow.productid)
						if (products.length != 0 ) {
							this._CustomExceptionService.handleWarning("This Material +" +  currentRow.productname + " is Already added.");
							this._servicerequestform.get("productorcustomerid").reset();
							return
						}
						this._Customerrequests.push(Customerbased);
						this.selectedRows.push(Customerbased);
						this._Customerrequests = [...this._Customerrequests]
						this.selectedRows = [...this.selectedRows]
						this._servicerequestform.get("productorcustomerid").reset();
					})


				} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); this._IsProgressSpinner = false; }
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	GetAssetBasedDetail() {
		this._Customerrequests = [];
		this.selectedRows = []
		this._Assetrequests = [];
		var id = this._servicerequestform.get("reftype").value;
		var refno = this._servicerequestform.get('refno').value;
		var customerorproductid = this._servicerequestform.get('productorcustomerid').value.productorcustomerid;
		let obj = {
			"ProductOrCustomerId": customerorproductid,
			"RegistrationCode": refno
		}
		if (id == 387) {
			this._IsProgressSpinner = true;
			this._ServicerequestService.GetAssetBasedDetail(obj).subscribe((result: any) => {
				this._IsProgressSpinner = false;
				if (result && result.tranStatus.result) {
					console.log("Asset", result);
					this._Assetrequests = result.getAssetBasedDetail;
					for (var i = 0; i < this._Assetrequests.length; i++) {
						var exDate = this.datepipe.transform((this._Assetrequests[i].warrantydate), 'dd/MM/yyyy')
						var currentdate = this.datepipe.transform(new Date(), 'dd/MM/yyyy')
						if (exDate <= currentdate) {
							this._Assetrequests[i].iswarrantyapplicablename = 'Yes'
							this._Assetrequests[i].iswarrantyapplicable = 1
						}
						else {
							this._Assetrequests[i].iswarrantyapplicablename = 'No'
							this._Assetrequests[i].iswarrantyapplicable = 0
						}
					}
				} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); this._IsProgressSpinner = false; }
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
		else if (id == 386) {
			this._IsProgressSpinner = true;
			this._ServicerequestService.GetCustomerBasedDetail(obj).subscribe((result: any) => {
				this._IsProgressSpinner = false;
				if (result && result.tranStatus.result) {
					console.log("Customer", result);
					this._Customerrequests = result.getCustomerBasedDetail;
					for (var i = 0; i < this._Customerrequests.length; i++) {
						var InvoiceDate = new Date(this._Customerrequests[i].invoicedate)
						var warrentydate = new Date();
						var currentdate = new Date();
						var todaydate = new Date();
						if (this._Customerrequests[i].warrantyperiod == 'MSC00113') { //Days
							warrentydate.setDate(InvoiceDate.getDate() + parseInt(this._Customerrequests[i].warranty))
							if (warrentydate >= currentdate) {
								this._Customerrequests[i].iswarrantyapplicable = 1
								this._Customerrequests[i].iswarrantyapplicablename = 'Yes'
								this._Customerrequests[i].warrantydate = warrentydate
							} else {
								this._Customerrequests[i].iswarrantyapplicable = 0
								this._Customerrequests[i].iswarrantyapplicablename = 'No'
								this._Customerrequests[i].warrantydate = warrentydate
							}
						}
						if (this._Customerrequests[i].warrantyperiod == 'MSC00112') { //Month
							warrentydate.setMonth(InvoiceDate.getMonth() + parseInt(this._Customerrequests[i].warranty))
							if (warrentydate >= currentdate) {
								this._Customerrequests[i].iswarrantyapplicable = 1
								this._Customerrequests[i].iswarrantyapplicablename = 'Yes'
								this._Customerrequests[i].warrantydate = warrentydate
							} else {
								this._Customerrequests[i].iswarrantyapplicable = 0
								this._Customerrequests[i].iswarrantyapplicablename = 'No'
								this._Customerrequests[i].warrantydate = warrentydate
							}
						}
						if (this._Customerrequests[i].warrantyperiod == 'MSC00111') { //Year
							warrentydate.setFullYear(InvoiceDate.getFullYear() + parseInt(this._Customerrequests[i].warranty))
							if (warrentydate >= currentdate) {
								this._Customerrequests[i].iswarrantyapplicable = 1
								this._Customerrequests[i].iswarrantyapplicablename = 'Yes'
								this._Customerrequests[i].warrantydate = warrentydate
							} else {
								this._Customerrequests[i].iswarrantyapplicable = 0
								this._Customerrequests[i].iswarrantyapplicablename = 'No'
								this._Customerrequests[i].warrantydate = warrentydate
							}
						}
						if (todaydate > this._Customerrequests[i].warrantydate) {
							this._Customerrequests.splice(this._Customerrequests[i], 1)
						}
					}
					if (!this._Customerrequests || !this._Customerrequests.length || this._Customerrequests.length == 0) {
						this._CustomExceptionService.handleWarning("Selected invoices will not have the warranty, or else the job card will not be closed.")
					}
				} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); this._IsProgressSpinner = false; }
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	allocatecustomerquantity(row: any) {
		var qty = row.quantity
		var srqty = row.srquantity
		if (qty < srqty) {
			this._CustomExceptionService.handleWarning("Service Request Quantity Should not greater than Quantity");
			row.srquantity = 0
			return
		}
	}
	onSave() {
		this._submitted = true;
		if (!this._servicerequestform.valid) return;
		this.servicerequestOBJ = this._servicerequestform.getRawValue()
		var Effectivefrom = this._servicerequestform.get("servicerequestdate").value;
		var reftype = this._servicerequestform.get("reftype").value;
		var refno = this._servicerequestform.get("refno").value;
		if (reftype != 414) {
			if (!refno) {
				this._CustomExceptionService.handleWarning("Please Select Ref No.");
				return
			}
			if (!this._servicerequestform.get('productorcustomerid').value || this._servicerequestform.get('productorcustomerid').value == null || this._servicerequestform.get('productorcustomerid').value == undefined) {
				this._CustomExceptionService.handleWarning("Please Select Customer / product Name.");
				return
			}
			this.servicerequestOBJ.productorcustomerid = this._servicerequestform.get('productorcustomerid').value.productorcustomerid;
		}
		else{
			this.servicerequestOBJ.productorcustomerid =0
		}

		var datePipe = new DatePipe("en-US");
		var peffectivefrom = datePipe.transform(Effectivefrom, 'yyyy-MM-dd');
		
		this.servicerequestOBJ.servicerequestdate = (new Date(peffectivefrom))
		this.servicerequestOBJ.createdon = new Date();
		this.servicerequestOBJ.createdby = sessionStorage["userid"];
		this.servicerequestOBJ.modifiedon = new Date();
		this.servicerequestOBJ.modifiedby = sessionStorage["userid"];
		this.servicerequestOBJ.branchid = sessionStorage["BranchID"];
		var Tempearningcustomerbased = []
		var TempearningAssetbased = []
		if (this._servicerequestform.get("reftype").value == 386 || this._servicerequestform.get("reftype").value == 414) {//customer
			if (!this._Customerrequests.length) {
				this._CustomExceptionService.handleWarning("Grid must not be empty.");
				return
			}
			this._Customerrequests.forEach(element => {
				if (element.isschemeapplied == true) {
					element.isschemeapplied = 1
				} else if (element.isschemeapplied == false) {
					element.isschemeapplied = 0
				}
			});
			if (!this.selectedRows.length || this.selectedRows.length == 0) {
				this._CustomExceptionService.handleWarning("Select Atleast One Product");
				return
			}
			if(reftype != 414){
				if (this.selectedRows.length > 1) {
					this.selectedRows = []
					this._CustomExceptionService.handleWarning("Please Select Only One Product");
					return
				}
			}
			
			Object.keys(this.selectedRows).map((Index) => {
				var currentRow = this.selectedRows[Index];
				var Customerbased = new Servicerequestcustomerbaseddetail();
				Customerbased.servicerequestcustomerbaseddetailid = currentRow.servicerequestcustomerbaseddetailid || 0;
				Customerbased.servicerequestid = currentRow.servicerequestid || 0;
				Customerbased.barcode = currentRow.barcode;
				Customerbased.producttypeid = currentRow.producttypeid;
				Customerbased.productid = currentRow.productid;
				Customerbased.variantid = currentRow.variantid || 0;
				Customerbased.quantity = currentRow.quantity;
				Customerbased.uomcode = currentRow.uomcode;
				Customerbased.unitprice = currentRow.unitprice;
				Customerbased.finalamount = currentRow.finalamount;
				Customerbased.taxid = currentRow.taxid;
				Customerbased.taxpercentage = currentRow.taxpercentage;
				Customerbased.taxamount = currentRow.taxamount;
				Customerbased.discountpercentage = currentRow.discountpercentage;
				Customerbased.discountamount = currentRow.discountamount;
				Customerbased.isschemeapplied = currentRow.isschemeapplied;
				Customerbased.srquantity = currentRow.srquantity;
				Customerbased.warrantydate = currentRow.warrantydate;
				Customerbased.status = currentRow.status || "MSC00001";
				Customerbased.iswarrantyapplicable = currentRow.iswarrantyapplicable || 0;
				Tempearningcustomerbased.push(Customerbased);
			})
			for (var i = 0; i < Tempearningcustomerbased.length; i++) {
				if (!Tempearningcustomerbased[i].srquantity || Tempearningcustomerbased[i].srquantity <= 0) {
					this._CustomExceptionService.handleWarning("0 or Negative value not permitted in Service Request Qty.");
					return;
				}
			}
		}
		else if (this._servicerequestform.get("reftype").value == 387) {//Asset
			for (var i = 0; i < this._Assetrequests.length; i++) {
				var assetbased = new Servicerequestassetbaseddetail();
				assetbased.servicerequestassetbaseddetailid = this._Assetrequests[i].servicerequestassetbaseddetailid || 0;
				assetbased.servicerequestid = this._Assetrequests[i].serviceRequestId || 0;
				assetbased.grnno = this._Assetrequests[i].grnno;
				assetbased.productid = this._Assetrequests[i].productid;
				assetbased.supplierid = this._Assetrequests[i].supplierid;
				assetbased.uomcode = this._Assetrequests[i].uomcode;
				assetbased.quantity = this._Assetrequests[i].quantity;
				assetbased.unitprice = this._Assetrequests[i].unitprice;
				assetbased.taxper = this._Assetrequests[i].taxper;
				assetbased.taxamount = this._Assetrequests[i].taxamount;
				assetbased.finalamount = this._Assetrequests[i].finalamount;
				assetbased.srquantity = this._Assetrequests[i].srquantity;
				assetbased.status = this._Assetrequests[i].status || "MSC00001";
				assetbased.iswarrantyapplicable = this._Assetrequests[i].iswarrantyapplicable || 0;
				assetbased.warrantydate = this._Assetrequests[i].warrantydate;
				TempearningAssetbased.push(assetbased);
			}
		}
		let OBJ = {
			"ServiceRequest": this.servicerequestOBJ,
			"ServiceRequestAssetBasedDetail": TempearningAssetbased,
			"ServiceRequestCustomerBasedDetail": Tempearningcustomerbased,
		}
		this._IsProgressSpinner = true;
		if (this._action == 'create') {
			this._ServicerequestService.create(OBJ).subscribe((result) => {
				this._IsProgressSpinner = false;
				const resutJSON = JSON.parse(JSON.stringify(result));
				if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
					this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
					this.servicerequestid = result.serviceRequestId
					this._IsSaveDisable = true;
					this._servicerequestform.disable();
					this.Iscustomersrq = true
					this.isDisabledremoveproduct = true
					this.isDisabledsendapparoval = false;
				} else { this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message); this._IsProgressSpinner = false; this._IsSaveDisable = false; }
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	reset() {
		this._submitted = false;
		this._IsSaveDisable = false;
		this.Iscustomersrq = false;
		this.selectedRows = [];
		this.isDisabledremoveproduct = false;
		this._servicerequestform.enable();
		this._servicerequestform.reset();
		this.InitializeFormGroup();
		this._Assetrequests = []
		this.mailid = null
		this.mobileno = null
		this.Address = null
		this._refno = []
		this._cusorproname = []
		this._Customerrequests = []
		this._Assetrequests = []
		this.ngOnInit()
	}
	goBack() {
		this._router.navigate(['/VServicerequest']);
	}
	SendToApproval(event) {
		if (!this.servicerequestid) {
			return
		}
		let data = {
			"ServiceRequestId": this.servicerequestid,
			"UserId": sessionStorage["userid"],
			"BranchId": sessionStorage["BranchID"],
		}
		this._IsProgressSpinner = true;
		this._ServicerequestService.SendToApproval(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
			this._IsSaveDisable = false;
			if (this._action != 'edit') {
				this.reset();
			}
			this._IsProgressSpinner = false;
			this.isDisabledsendapparoval = true;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	onRowSelectPopup(event: any) { this.PopupRowValidation(); }
	onRowUnSelectPopup(event: any) { this.PopupRowValidation(); }
	PopupRowValidation() {
		let s_request = (this.selectedRows || []);
		(this._Customerrequests || []).forEach(element => {
			element.disablecheckbox = false;
		});
		if (!s_request || !s_request.length) return;
		if (this._servicerequestform.get("reftype").value != 414) {
			(this._Customerrequests || []).filter(f => f.productid != s_request[0].productid).forEach(element => {
				element.disablecheckbox = true;
			});
		}


	}
	filterCustomersOrProducts(event) {
		if (event.query == "" || event.query == undefined) {
			return;
		}
		else {
			this._IsProgressSpinner = true;
			// let obj = {
			// 	"ServiceRequestType": id,
			// 	"CustomerTypeId": this._selectedtype
			// }
			this._ServicerequestService.GetProductOrCustomer(event.query).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._cusorpronames = <Servicerequestassetbaseddetail[]>resultJSON.ProductOrCustomerList || [];
				this._tempcusorpronames = <Servicerequestassetbaseddetail[]>resultJSON.ProductOrCustomerList || [];
				let filtered: any[] = [];
				let query = (<string>event.query).toLowerCase();
				this._cusorproname = this._cusorpronames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
				this._IsProgressSpinner = false;
			},
				error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
}