
 
 export class Vehicledocumentdetail {
    vehicledocumentdetailid: number;
    vehicleid: number;
    noofalerts: number ;
    effectivefrom: Date ;
    effectiveto: Date;
    documentname: string;
    remarks: string;
    attachmenturl: string;
    attachmentname: string;
    documentstatus: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}

