import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { ImportentryService } from 'src/app/core/Services/tracking/importentry.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-v-importentry',
  templateUrl: './v-importentry.component.html',
  styleUrls: ['./v-importentry.component.css']
})
export class VImportentryComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"];
  _roleid = sessionStorage["currentRole"];
  _Importentryviews: any[];
  _Importentryview: any;
  _Action: IAction;
  _IsProgressSpinner: boolean = true;
  _importentryno: string;
  cols: any[];
  fromdate: Date;
  todate: Date;
  mindate: Date;
  maxdate: Date;
  datepipe = new DatePipe('en-US');
  constructor(private fb: FormBuilder, private router: Router, private utility: CommonUtilities,
    private _ImportentryService: ImportentryService, private _hotkeysService: HotkeysService,
    private _CustomExceptionService: CustomExceptionService, private _AccessRightsService: AccessRightsService,
    private confirmationService: ConfirmationService, private exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;
    this._Action = this._AccessRightsService.getAccessRights(45);
    this.HotKeyIntegration();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.cols = [
      { field: 'importno', header: 'Import No' },
      { field: 'importdate', header: 'Import Date' },
      { field: 'containerno', header: 'Container No' },
      { field: 'agentid', header: 'Agent Name' },
      { field: 'agentinvoiceno', header: 'Agent Invoice No' },
      { field: 'agentinvoiceamount', header: 'Agent Invoice Amount' },
      { field: 'dutycharge', header: 'Duty Amount' },
      { field: 'importstatusname', header: 'StatusStatus' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this.Fetchall();
  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._branchid = sessionStorage["currentbranchid"];
    this._userid = sessionStorage["userid"];
    this._roleid = sessionStorage["currentRole"];
    this._IsProgressSpinner = true;
    this._ImportentryService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Importentryviews = resultJSON.importentryviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/cImportentry'], { state: { importno: event.importno, action: 'view' } });
  }
  delete(event) {
    alert("delete");
    //this._importentryform.reset();

  }
  edit(event) {
    // 36.update initialize should load all fields based on search page selected Import No.
    // 50.if all the data is valid allow to Update the record in the database With Respect To Import No.
    this.router.navigate(['/cImportentry'], { state: { importno: event.importno, action: 'edit', editallowed: 1 } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cImportentry']);
  }
  confirm(event) {
    this._importentryno = event.importno;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Document <b>"' + event.importno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');

    this._branchid = sessionStorage["currentbranchid"];
    this._userid = sessionStorage["userid"];
    this._roleid = sessionStorage["currentRole"];
    this._IsProgressSpinner = true;
    var _userid = sessionStorage["userid"];
    this._ImportentryService.Cancel(this._importentryno, _userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.containertracking);
        this._ImportentryService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._Importentryviews = resultJSON.importentryviews;
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        this._IsProgressSpinner = false;
      }
      else {
        this._CustomExceptionService.handleWarning(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let finalizedList = this.GetFinalizedList();
    this.exportUtility.exportExcel(finalizedList, 'Import Entry', 'xlsx');
  }



  GetFinalizedList() {
    let finalizedList = [];
    this._Importentryviews.forEach(element => {
      let quotes: any = {};
      quotes["Import No"] = element.importno;
      quotes["Import Date"] = this.datepipe.transform(element.importdate, 'dd/MM/yyyy');
      quotes["Container No"] = element.containerno;
      quotes["Agent Name"] = element.agentid;
      quotes["Agent Invoice No"] = element.agentinvoiceno;
      quotes["Agent Invoice Amount"] = element.agentinvoiceamount;
      quotes["Duty Amount"] = element.dutycharge;
      quotes["Status"] = element.importstatusname;
      quotes["Payment Status"] = element.paymentstatusname;
      quotes["Created By"] = element.createdbyname;
      quotes["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      finalizedList.push(quotes);
    });
    return finalizedList;
  }

  ExportToPdf() {
    if (!this._Importentryviews || !this._Importentryviews.length)
      return;
    let StatusHistoryDetails = this.GetFinalizedList();
    const data = StatusHistoryDetails.map(Object.values);
    const head = [['Import No', 'Import Date', 'Country', 'Agent Name', 'Agent Invoice No', 'Agent Invoice Amount','Container No', 'Status', 'Payment Status', 'Created By', 'Created On']]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Import Entry.pdf');
      })
    })

  }
}
