<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Approval Configuration Branch</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                (click)="onSave()" [disabled]="isDisabledSave"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                icon="pi pi-trash" class="" class="p-button-danger" [disabled]="isDisabledClear"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_Approvalbranchdetailform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Documentname"
                                        optionLabel="functionname" optionValue="documenttypeid" name="documenttypeid"
                                        formControlName="documenttypeid" (onChange)="ChangeDocument()" [filter]="true" filterBy="functionname" [showClear]="true">
                                    </p-dropdown>
                                    <!-- (onChange)="ChangeDocument()" -->
                                    <label for="documenttypeid">Document Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Approvalbranchdetailform.get('documenttypeid').touched) && _Approvalbranchdetailform.get('documenttypeid').errors?.SelectDocumentName">
                                    Please Select Document Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Workflowname"
                                        optionLabel="workflowname" optionValue="workflowid" name="workflowid"
                                        formControlName="workflowid" (onChange)="ChangeWorkflow()">
                                    </p-dropdown>
                                    <label for="workflowid">Workflow Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Approvalbranchdetailform.get('workflowid').touched) && _Approvalbranchdetailform.get('workflowid').errors?.WorkflowName">
                                    Please Select Workflow Type
                                </span>
                            </td>
                           
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Workflow Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_workflowdetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['documenttypename','workflowtypename','workflowname','conditiontypename','unitprice','transactionamount','debitamount','reason']"
                        [rowHover]="true" dataKey="productid" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px;text-align:center">Stage No</th>
                                <th>Stage Name</th>
                                <th>Role</th>
                                <th>Primary User</th>
                                <th>Secondary User</th>
                                <th>Workflow Action</th>
                                <th>Workflow Status</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_workflowdetail let-i="rowIndex">
                            <tr>
                                <!-- (click)="RemoveVariant(_customerslabdetail)" -->
                                <td style="text-align:center">
                                    {{_workflowdetail.stageid}}
                                </td>
                                <td>
                                    {{_workflowdetail.stagename}}
                                </td>
                                <td>
                                    {{_workflowdetail.rolename}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Primaryapproved" [filter]="true"
                                                optionLabel="username" optionValue="userid" name="primaryapprover"
                                                [disabled]="_action == 'view'" [(ngModel)]="_workflowdetail.primaryapprover">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Primaryapproved" [filter]="true"
                                                optionLabel="username" optionValue="userid" name="primaryapprover"
                                                [(ngModel)]="_workflowdetail.primaryapprover" [disabled]="_action == 'view'">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Secondaryapproved" [filter]="true"
                                                optionLabel="username" optionValue="userid" [disabled]="_action == 'view'"
                                                name="secondaryapprover"
                                                [(ngModel)]="_workflowdetail.secondaryapprover">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Secondaryapproved" [filter]="true"
                                                optionLabel="username" optionValue="userid" name="secondaryapprover"
                                                [(ngModel)]="_workflowdetail.secondaryapprover" [disabled]="_action == 'view'">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_workflowdetail.stageactionname}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_Workflowstatus"
                                                optionLabel="metasubdescription" optionValue="metasubcode" [filter]="true"
                                                name="stagestatus" [disabled]="_action == 'view'"
                                                [(ngModel)]="_workflowdetail.stagestatus">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_Workflowstatus" [filter]="true"
                                                optionLabel="metasubdescription" optionValue="metasubcode" [disabled]="_action == 'view'"
                                                name="stagestatus" [(ngModel)]="_workflowdetail.stagestatus">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <tr>
                        <td>
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>