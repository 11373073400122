import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Serviceworkorder } from 'src/app/core/Models/serviceworkorder';
import { ServiceWorkOrderAdditionalChargeDetail } from 'src/app/core/Models/ServiceWorkOrderAdditionalChargeDetail';
import { Serviceworkorderdetail } from 'src/app/core/Models/serviceworkorderdetail';
import { ServiceWorkOrderOtherChargeDetail } from 'src/app/core/Models/ServiceWorkOrderOtherChargeDetail';
import { ServiceWorkOrderService } from 'src/app/core/Services/workshop/service-work-order.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
	selector: 'app-c-service-work-order',
	templateUrl: './c-service-work-order.component.html',
	styleUrls: ['./c-service-work-order.component.css']
})
export class CServiceWorkOrderComponent implements OnInit {
	_action: string = "create";
	_submitted = false;
	_IsProgressSpinner: boolean = true;
	_IsSaveDisable: boolean = false;
	_serviceworkorderform: FormGroup;
	_servicerequestno: any
	_serviceworkorderdetails: any[]
	isDisabledsendapparoval: boolean = true
	_status: any
	_serviceat: any
	_employee: any
	_tempemployee: any
	_tempsupplier: any
	_supplier: any
	_currency: any
	_payby: any
	vattype: any
	_othercharges: any
	mobileno: number
	mailid: any
	Address: any
	Checklist: any
	serviceworkorderOBJ: Serviceworkorder
	isexternal: boolean = false
	Serviceworkorderid: any
	_OtherCharges: any[] = []
	selectedOtherChargesRows: any[] = []
	_Descriptions: any[] = []
	isDisabledother: boolean = false
	isDisabledremove: boolean = false
	_AdditionalCharges: any[] = []
	_AddDescriptions: any[] = []
	constructor(
		private fb: FormBuilder,
		private messageService: MessageService,
		private _CustomExceptionService: CustomExceptionService,
		private _route: ActivatedRoute,
		private _serviceworkorderService: ServiceWorkOrderService,
		private _router: Router,
		private utility: CommonUtilities,
		private _hotkeysService: HotkeysService
	) { }
	InitializeFormGroup(): void {
		this._serviceworkorderform = this.fb.group({
			serviceworkorderid: [0],
			serviceworkorderno: [],
			serviceworkorderdate: [],
			checklistobservation: [],
			remarks: [],
			payby: [],
			jobcardno: [],
			jobcarddate: [],
			servicerequestno: [],
			branchid: [],
			jobcardtype: [],
			employeeid: [],
			supplierid: [],
			checklistdescription: [],
			approximateamountinusd: [],
			vatid: [],
			vatamount: [],
			currencyid: [],
			currencyuniqerefid: [],
			ocid: [],
			ocvalue: [],
			advalue: [],
			netamount: [],
			status: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			serviceworkorderdetails: [],
			serviceworkorderotherchargedetails: [],
			serviceworkorderadditionalchargedetails: []
		});
	}

	ngOnInit(): void {
		this.utility.pageLoadScript();
		this.InitializeFormGroup();
		this._IsProgressSpinner = false;
		let serviceworkorder = history.state?.serviceworkorderid ?? 0;

		this._action = history.state?.action ?? this._action;
		this._serviceworkorderform.controls["branchid"].setValue(sessionStorage["BranchID"],)
		let obj = {
			"ServiceWorkOrderId": serviceworkorder,
		}
		this._IsProgressSpinner = true;
		this._serviceworkorderService.PageOnLoad(obj).subscribe((result: any) => {
			this._IsProgressSpinner = false;
			if (result && result.tranStatus.result) {
				console.log(result, 'objectes');
				this._servicerequestno = result.serviceRequestList;
				this._employee = result.employeeList;
				this._supplier = result.supplierList;
				this._othercharges = result.otherChargeList;
				this._currency = result.currencyList;
				this._payby = result.paybyList;
				this._serviceat = result.jobCardTypesList;
				this.vattype = result.taxList;
				this._status = result.statusList;
				this._Descriptions = result.otherChargeList;
				this._AddDescriptions = result.serviceCoustList;
				var l_othercharge = new ServiceWorkOrderOtherChargeDetail();
				l_othercharge.serviceworkorderotherchargedetailid = 0;
				l_othercharge.serviceworkorderid = 0;
				l_othercharge.ocvalue = 0;
				this._OtherCharges.push(l_othercharge);

				if (this._action == 'edit' || this._action == 'view') {
					result.serviceWorkOrder.serviceworkorderdate = new Date(result.serviceWorkOrder.serviceworkorderdate);
					result.serviceWorkOrder.jobcarddate = new Date(result.serviceWorkOrder.jobcarddate);
					this._servicerequestno = result.serviceRequestViewList;
					this._serviceworkorderform.setValue(result.serviceWorkOrder);
					this._serviceworkorderdetails = result.serviceWorkOrderDetail;
					this._OtherCharges = result.serviceWorkOrderOtherChargeDetail;
					this._AdditionalCharges = result.serviceWorkOrderAdditionalChargeDetail;
					this.Checklist = result.checkList
					var list = this._supplier.filter(f => f.supplierid == result.serviceWorkOrder.supplierid)
					if (list.length != 0) {
						this.mailid = list[0].mailid
						this.mobileno = list[0].mobileno
						this.Address = list[0].address
					}
					if (this._action == 'edit') {
						this._status = this._status.filter(f => f.metadatatypecode == 'MDT00098')
					}
					this._serviceworkorderform.get('ocvalue').disable();
					this._serviceworkorderform.get('advalue').disable();
					if (result.serviceWorkOrder.jobcardtype == 'MSC00312') {
						this._serviceworkorderform.get('approximateamountinusd').disable();
						this._serviceworkorderform.get('vatid').disable();
						this._serviceworkorderform.get('ocid').disable();
						this._serviceworkorderform.get('ocvalue').disable();
					}
					if (result.serviceWorkOrder.ocid) {
						this._serviceworkorderform.get('ocvalue').disable();
					}
				}
				if (this._action == 'view') {
					this._serviceworkorderform.disable();
					this._IsSaveDisable = true
					this.isDisabledother = true
					this.isDisabledremove = true
				}
			}
			else {
				this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
				this._IsProgressSpinner = false;
			}
		},
			(error) => {
				this._CustomExceptionService.handleError(error);
				this._IsProgressSpinner = false;
			}
		);
	}
	AddOtherCharges(event) {
		let otherChargesExists = (this._OtherCharges.filter(s => s.ocid > 0).length == this._OtherCharges.length) && this._OtherCharges.some(s => s.ocid > 0);
		if (!otherChargesExists)
			return;
		var l_othercharge = new ServiceWorkOrderOtherChargeDetail();
		l_othercharge.serviceworkorderotherchargedetailid = 0;
		l_othercharge.serviceworkorderid = 0;
		l_othercharge.ocvalue = 0;
		this._OtherCharges.push(l_othercharge);
	}
	RemoveOtherCharges(pOthercharges: ServiceWorkOrderOtherChargeDetail) {
		var index = this._OtherCharges.indexOf(pOthercharges);
		this._OtherCharges.splice(index, 1)
		this.netcalculation();
		if (this._OtherCharges.length <= 0) {
			var l_othercharge = new ServiceWorkOrderOtherChargeDetail();
			l_othercharge.serviceworkorderotherchargedetailid = 0;
			l_othercharge.serviceworkorderid = 0;
			l_othercharge.ocvalue = 0;
			this._OtherCharges.push(l_othercharge);
		}
	}
	OnchangeOtherCharge(pOtherCharges: ServiceWorkOrderOtherChargeDetail, event: any) {
		let isAlreadyExists = this._OtherCharges.filter(s => s.ocid == event.value).length > 1;
		if (isAlreadyExists) {
			this._CustomExceptionService.handleWarning("Other Charges Already Exists!");
			var index = this._OtherCharges.indexOf(pOtherCharges);
			this._OtherCharges.splice(index, 1)
			return;
		}
		let OCValue = (this._Descriptions || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount;
		pOtherCharges.ocvalue = OCValue;
		pOtherCharges.ocid = event.value;
		this.netcalculation()
	}
	OnChangeotherCharge(row: any, event) {
		let OtherChargesAmount = row.ocvalue || 0;
		parseFloat(OtherChargesAmount)
		this.netcalculation()
	}
	allocatePaymentAmount() { // amount CAlculation
		this._AdditionalCharges = []
		var A_charges = new ServiceWorkOrderAdditionalChargeDetail();
		A_charges.serviceworkorderadditionalchargedetailid = 0;
		A_charges.serviceworkorderid = 0;
		A_charges.advalue = 0;
		A_charges.adper = 0;
		this._AdditionalCharges.push(A_charges);
		let amount: number = 0.00;
		amount = this._serviceworkorderform.get('approximateamountinusd').value
		if (!amount || amount == 0) {
			this._serviceworkorderform.get('approximateamountinusd').setValue(0)
			this._CustomExceptionService.handleWarning(" Please Enter Service Cost")
			return
		}
		var currencyId = this._serviceworkorderform.get('currencyid').value
		if (!currencyId) {
			this._serviceworkorderform.get('approximateamountinusd').setValue(0)
			this._CustomExceptionService.handleWarning("Please Select Currency");
			return
		}
		this.netcalculation();
	}
	getvat() { // vat CAlculation
		this.netcalculation();
	}
	getchargesid() {
		var id = this._serviceworkorderform.get('ocid').value;
		if (!id) {
			return
		}
		this._serviceworkorderform.get('ocvalue').disable();
		var amount = this._othercharges.filter(f => f.otherchargeid == id)[0].otherchargeamount
		this._serviceworkorderform.controls["ocvalue"].setValue(amount);
		this.getothercharges();
	}

	getothercharges() { // vat CAlculation
		this.netcalculation()
	}

	////Calculation

	onSave() {
		this._submitted = true;
		if (!this._serviceworkorderform.valid) return;
		this.serviceworkorderOBJ = this._serviceworkorderform.getRawValue();
		var Effectivefrom = this._serviceworkorderform.get("jobcarddate").value;
		var Effectiveto = this._serviceworkorderform.get("serviceworkorderdate").value;
		var datePipe = new DatePipe("en-US");
		var peffectivefrom = datePipe.transform(Effectivefrom, 'yyyy-MM-dd');
		var peffectiveto = datePipe.transform(Effectiveto, 'yyyy-MM-dd');
		this.serviceworkorderOBJ.jobcarddate = (new Date(peffectivefrom))
		this.serviceworkorderOBJ.serviceworkorderdate = (new Date(peffectiveto))
		this.serviceworkorderOBJ.createdon = new Date();
		this.serviceworkorderOBJ.createdby = sessionStorage["userid"];
		this.serviceworkorderOBJ.modifiedon = new Date();
		this.serviceworkorderOBJ.modifiedby = sessionStorage["userid"];
		this.serviceworkorderOBJ.branchid = sessionStorage["BranchID"];
		if (this.serviceworkorderOBJ.jobcardtype == 'MSC00313') { //external
			if (!this.serviceworkorderOBJ.supplierid) {
				this._CustomExceptionService.handleWarning("Please Select Supplier");
				return;
			}
		}
		else if (this.serviceworkorderOBJ.jobcardtype == 'MSC00312') { // internal
			if (!this.serviceworkorderOBJ.employeeid) {
				this._CustomExceptionService.handleWarning("Please Select Employee ");
				return;
			}
		}


		if (this.serviceworkorderOBJ.jobcardtype == 'MSC00313') {
			if (!this.serviceworkorderOBJ.approximateamountinusd) {
				this._CustomExceptionService.handleWarning("Please Entre Service Cost ");
				return;
			}
		}

		// if (this.serviceworkorderOBJ.ocid) {
		//   if (!this.serviceworkorderOBJ.ocvalue || this.serviceworkorderOBJ.ocvalue <= 0) {
		//     this._CustomExceptionService.handleWarning("Please Ensure Other charges value");
		//     return
		//   }
		// }

		var Tempserviceworkorderdetail = []
		for (var i = 0; i < this._serviceworkorderdetails.length; i++) {
			var serviceworkorderdetail = new Serviceworkorderdetail();
			serviceworkorderdetail.serviceworkorderdetailid = this._serviceworkorderdetails[i].serviceworkorderdetailid || 0;
			serviceworkorderdetail.serviceworkorderid = this._serviceworkorderdetails[i].serviceworkorderid || 0;
			serviceworkorderdetail.productid = this._serviceworkorderdetails[i].productid;
			serviceworkorderdetail.uom = this._serviceworkorderdetails[i].uom;
			serviceworkorderdetail.unitprice = this._serviceworkorderdetails[i].unitprice;
			serviceworkorderdetail.finalamount = this._serviceworkorderdetails[i].finalamount;
			serviceworkorderdetail.srquantity = this._serviceworkorderdetails[i].srquantity;
			Tempserviceworkorderdetail.push(serviceworkorderdetail);
		}
		for (let i = 0; i < this._OtherCharges.length; i++) {
			if (this._OtherCharges[i].ocid && !this._OtherCharges[i].ocvalue) {
				this._CustomExceptionService.handleError("Please Enter Valid Other Charges")
				return
			}
			if (!this._OtherCharges[i].ocid && this._OtherCharges[i].ocvalue) {
				this._CustomExceptionService.handleError("Please Enter Valid Other Charges")
				return
			}
		}
		for (let i = 0; i < this._AdditionalCharges.length; i++) {
			if (this._AdditionalCharges[i].adid && !this._AdditionalCharges[i].adper) {
				this._CustomExceptionService.handleError("Please Enter Valid Other Charges")
				return
			}
			if (!this._AdditionalCharges[i].adid && this._AdditionalCharges[i].adper) {
				this._CustomExceptionService.handleError("Please Enter Valid Other Charges")
				return
			}
		}
		let OBJ = {
			"ServiceWorkOrder": this.serviceworkorderOBJ,
			"ServiceWorkOrderDetail": Tempserviceworkorderdetail,
			"ServiceWorkOrderOtherChargeDetail": this._OtherCharges,
			"ServiceWorkOrderAdditionalChargeDetail": this._AdditionalCharges
		}
		this._IsProgressSpinner = true;
		if (this._action == 'create') {
			this._serviceworkorderService.create(OBJ).subscribe((result) => {
				this._IsProgressSpinner = false;
				const resutJSON = JSON.parse(JSON.stringify(result));
				if (resutJSON.tranStatus.result == true) {
					this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
					this.reset();
				}
				else {
					this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
					this._IsProgressSpinner = false;
					this._IsSaveDisable = false;
				}
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
		else if (this._action == 'edit') {
			this._serviceworkorderService.edit(OBJ).subscribe((result) => {
				this._IsProgressSpinner = false;
				const resutJSON = JSON.parse(JSON.stringify(result));

				if (resutJSON.tranStatus.result == true) {
					this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
					this.Serviceworkorderid = result.serviceWorkOrderId
					this.isDisabledsendapparoval = false
					this._IsSaveDisable = true
					this._serviceworkorderform.get('status').disable();
					this._serviceworkorderform.get('approximateamountinusd').disable();
					this._serviceworkorderform.get('vatid').disable();
					this._serviceworkorderform.get('ocid').disable();
					this._serviceworkorderform.get('ocvalue').disable();

				}
				else {
					this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
					this._IsProgressSpinner = false;
					this._IsSaveDisable = false;
				}
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}


	}





	reset() {
		this._submitted = false
		this._serviceworkorderform.reset();
		this.InitializeFormGroup();
		this._employee = [];
		this._supplier = [];
		this._serviceworkorderdetails = [];
		this._OtherCharges = []
		this.ngOnInit();
		this.mailid = null;
		this.mobileno = null;
		this.Address = null;
	}

	goBack() {
		this._router.navigate(['/Vserviceworkorder']);
	}
	SendToApproval(event) {
		if (this._serviceworkorderform.get('status').value == 'MSC00318') {
			this._CustomExceptionService.handleWarning("Please Ensure Status");
			return
		}
		// var workflowid = 5;
		if (!this.Serviceworkorderid) {
			return
		}
		let data = {
			"ServiceWorkOrderId": this.Serviceworkorderid,
			"UserId": sessionStorage["userid"],
			"BranchId": sessionStorage["BranchID"],
		}

		this._IsProgressSpinner = true;
		this._serviceworkorderService.SendToApproval(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
			if (this._action != 'edit') {
				this.reset();
			}
			this._IsProgressSpinner = false;
			this.isDisabledsendapparoval = true;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		this.goBack();
	}
	AddAdditionalCharges(event) {
		let otherChargesExists = (this._AdditionalCharges.filter(s => s.adid > 0).length == this._AdditionalCharges.length) && this._AdditionalCharges.some(s => s.adid > 0);
		if (!otherChargesExists) return;
		for (let i = 0; i < this._AdditionalCharges.length; i++) {
			const x = this._AdditionalCharges[i];
			if (x.adid && (!x.adper || x.adper == 0 || !x.advalue || x.advalue == 0)) {
				this._CustomExceptionService.handleWarning("Please Enter Percentage for the previous line items")
				return
			}
		}
		var A_charges = new ServiceWorkOrderAdditionalChargeDetail();
		A_charges.serviceworkorderadditionalchargedetailid = 0;
		A_charges.serviceworkorderid = 0;
		A_charges.advalue = 0;
		A_charges.adper = 0;
		this._AdditionalCharges.push(A_charges);
	}
	RemoveAdditionalCharges(pOthercharges: ServiceWorkOrderAdditionalChargeDetail) {
		var index = this._AdditionalCharges.indexOf(pOthercharges);
		this._AdditionalCharges.splice(index, 1)
		this.netcalculation();
		if (this._AdditionalCharges.length <= 0) {
			var A_charges = new ServiceWorkOrderAdditionalChargeDetail();
			A_charges.serviceworkorderadditionalchargedetailid = 0;
			A_charges.serviceworkorderid = 0;
			A_charges.advalue = 0;
			A_charges.adper = 0;
			this._AdditionalCharges.push(A_charges);
		}
	}
	OnchangeAdditionalCharge(a_charge: ServiceWorkOrderAdditionalChargeDetail, event: any) {
		let isAlreadyExists = this._AdditionalCharges.filter(s => s.adid == event.value).length > 1;
		if (isAlreadyExists) {
			this._CustomExceptionService.handleWarning("Additional Charges Already Exists!");
			var index = this._AdditionalCharges.indexOf(a_charge);
			this._AdditionalCharges.splice(index, 1)
			return;
		}
		a_charge.advalue = 0;
		a_charge.adid = event.value;
		this.netcalculation()
	}
	OnchangeAdditionalChargePer(a_charge: ServiceWorkOrderAdditionalChargeDetail, event: any) {
		let ServiceCost: number = this._serviceworkorderform.get("approximateamountinusd").value
		if (!ServiceCost || ServiceCost == 0) {
			this._AdditionalCharges = []
			var A_charges = new ServiceWorkOrderAdditionalChargeDetail();
			A_charges.serviceworkorderadditionalchargedetailid = 0;
			A_charges.serviceworkorderid = 0;
			A_charges.advalue = 0;
			A_charges.adper = 0;
			this._AdditionalCharges.push(A_charges);
			this._CustomExceptionService.handleWarning("Please Enter Service Cost");
			return
		}
		if (!a_charge.adid) {
			a_charge.adper = 0
			a_charge.advalue = 0
			this._CustomExceptionService.handleWarning("Please Select Description");
			return
		}
		a_charge.adper = a_charge.adper || 0;
		a_charge.advalue = (a_charge.adper * ServiceCost) / 100;
		this.netcalculation()
	}
	netcalculation() {
		let cost: number = 0,
			percentage: number = 0,
			vatamount: number = 0,
			otherchargesamount: number = 0,
			netamount: number = 0,
			additionalchargesamount: number = 0;
		cost = this._serviceworkorderform.get('approximateamountinusd').value;
		if (!cost) {
			this._CustomExceptionService.handleWarning(" Please Entre Service Cost");
			return
		}
		var taxid = this._serviceworkorderform.get('vatid').value;
		if (taxid) {
			percentage = this.vattype.filter(f => f.taxid == taxid)[0].taxpercentage
		}
		for (let i = 0; i < this._OtherCharges.length; i++) {
			otherchargesamount += this._OtherCharges[i].ocvalue;
		}
		for (let i = 0; i < this._AdditionalCharges.length; i++) {
			additionalchargesamount += this._AdditionalCharges[i].advalue
		}
		vatamount = ((cost + additionalchargesamount) * percentage / 100);
		this._serviceworkorderform.controls['vatamount'].setValue(vatamount.toFixed(2))
		this._serviceworkorderform.get('advalue').setValue(additionalchargesamount.toFixed(2))
		this._serviceworkorderform.get('ocvalue').setValue(otherchargesamount)
		netamount = (cost + vatamount + otherchargesamount + additionalchargesamount)
		this._serviceworkorderform.get('netamount').setValue(netamount)
	}
}
