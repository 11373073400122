import { Injectable } from '@angular/core';
import { User } from '../../Models/User'
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { observable } from 'rxjs';
import { v4 as SessionID } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public UserService = environment.UserService;
  constructor(private http: HttpClient) { }

  validate(pUserName: string, pPassword: string, ipAddress: string) {
    var user: User = new User();
    user.username = pUserName;
    user.password = pUserName;
    var sessionId = SessionID();

    var httpOptions = {
      headers: new HttpHeaders({ 'SessionId': sessionId , 'Client-IP': ipAddress})
    };

    var Query = this.UserService + '/' + pUserName + '/' + pPassword + '/' + ipAddress;
   
    return this.http.get<{ data: any }>(Query, httpOptions);
  }

  logout() {
    var sessionId = sessionStorage["SessionID"];
    var httpOptions = {
      headers: new HttpHeaders({ 'SessionId': sessionId })
    };
    var Query = this.UserService + '/Logout';
    return this.http.get<{ data: any }>(Query, httpOptions);
  }


}
