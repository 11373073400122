import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Receipt } from 'src/app/core/Models/Receipt';
import { Receiptdetail } from 'src/app/core/Models/Receiptdetail';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Currency } from 'src/app/core/Models/Currency';
import { ReceiptService } from 'src/app/core/Services/accounts/receipt.service';
import { usererrors } from 'src/app/core/errors/usererrors';
//Debug
import * as ace from "ace-builds";
import { vCurrencyNameSelect, vFromAccountSelect, vReceiptAmount, vReceiptDateSelect, vReceiptModeSelect, vToAccountSelect } from 'src/app/core/Validators/validation';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { Receiptdetailview } from 'src/app/core/Views/ReceiptDetailView';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUpload } from 'primeng/fileupload';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-c-receipt',
	templateUrl: './c-receipt.component.html',
	styleUrls: ['./c-receipt.component.css']
})
export class CReceiptComponent implements OnInit {
	receiptmodel: any = {};
	_maxDate: Date = new Date();
	_IsSaveDisable: boolean = false;
	_IsClearDisable: boolean = false;
	_receiptform: FormGroup;
	_receiptbalanceform: FormGroup;
	_receiptotherform: FormGroup;
	_submitted = false;
	_action: string = "create";
	_Receipt: Receipt;
	_branchid = sessionStorage["currentbranchid"];
	_Voucherno: string = "";
	_BillDetails: Receiptdetailview[] = [];
	_BillDetail: Receiptdetailview;
	_oldBillDetails: Receiptdetailview[] = [];
	_Receiptid: number;
	_userid = sessionStorage["userid"];
	_ReceiptOBJ: any;
	_CurrenciesTemp: Currency[] = [];
	_withoutfilterCurrenciesTemp: Currency[] = [];
	_fromaccountsTemp: Accountledgerview[] = [];
	_toaccountsTemp: Accountledgerview[] = [];
	_partyTypeList: Metadatum[] = [];
	//Drop down & Radio button
	_ReceiptModes: Metadatum;
	_fromaccounts: Accountledgerview[] = [];
	_toaccounts: Accountledgerview[] = [];
	_currencies: Currency[] = [];
	selectedBillDetailsRows: Receiptdetailview[] = [];
	_paymode: any;
	_series: any;
	_TotalReceiptAmount: number = 0.00;
	_AdvanceReceiptAmount = 0;
	_IsProgressSpinner: boolean = true;

	imageselect: string = "";

	_receiptsFor: Metadatum[] = [];

	currentDocument: File;
	currentDocumentSafeURL: SafeUrl;
	isDisabledButton = false;
	_isdatedisabled: boolean = true;

	currentroleid = sessionStorage["currentRole"];

	//Debug
	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, private messageService: MessageService, private _CustomExceptionService: CustomExceptionService, private _ReceiptService: ReceiptService, private _hotkeysService: HotkeysService,
		private _DomSanitizationService: DomSanitizer) {

	}


	InitializeForm() {
		this._receiptform = this.fb.group({
			receiptid: [0],
			receiptmode: ['', vReceiptModeSelect],
			voucherno: [],
			receiptdate: [(new Date()), vReceiptDateSelect],
			fromaccount: ['', vFromAccountSelect],
			toaccount: ['', vToAccountSelect],
			receiptamount: [0, vReceiptAmount],
			referenceno: [],
			currencyuniquerefid: [1],
			currentcurrencyid: [1, vCurrencyNameSelect],
			exchangerateinusd: [1],
			amountinusd: [0],
			narration: [],
			receiptstatusid: [45],
			receiptstatusdesc: ['Created'],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			accountingyear: [],
			branchid: [],
			advanceamount: [0],
			metasubdescription: [],
			fromaccountname: [],
			toaccountname: [],
			series: [],
			partytype: [],
			attachmentname: [],
			transactionincompleted: [1],
		});

		this._receiptbalanceform = this.fb.group({
			fromaccountclosingbalance: [0],
			toaccountclosingbalance: [0],
			totalreceiptamount: [0]
		});

		this._receiptotherform = this.fb.group({
			paymentfor: [],
			documentno: []
		})

		this.SetDefaults();
	}

	SetDefaults() {
		this._receiptform.controls["toaccount"].reset();
		let userid = parseInt(sessionStorage["userid"]);
		let fromaccountid = this._receiptform.get("fromaccount").value || [];
		let paymentType = this._receiptform.get("receiptmode").value;
		if (this._receiptform.get("fromaccount").value && paymentType == "MSC00146") {
			let ledger = this._fromaccounts.find(w => w.ledgerid == fromaccountid);
			if (ledger) {
				this._toaccounts = this._toaccountsTemp.filter(w => w.series == ledger.series && w.accountgroupid == 30);
			}
		}
		if (this.currentroleid != 1) {
			let paymentType = this._receiptform.get("receiptmode").value;
			if (paymentType == "MSC00146") {
				if (!this._toaccountsTemp.length) return;
				let fromaccount = (this._toaccountsTemp || []).find(f => f.partycode == userid && f.accountgroupid == 30);
				// if (fromaccount)
				// {
				// this._receiptform.get("toaccount").setValue(parseInt(fromaccount.ledgerid+'' || '0'));
				// }
				// else {
				//   this._receiptform.controls["toaccount"].reset();
				// }
			}
			else {
				this._receiptform.controls["toaccount"].reset();
			}
		}
	}


	ngOnInit(): void {
		this.utility.pageLoadScript();
		this.InitializeForm();
		this._Receiptid = history.state?.receiptid ? history.state?.receiptid : 0;
		let voucherno = history.state?.voucherno ? history.state?.voucherno : null;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this.HotKeyIntegration();
		this._receiptform.get("receiptdate").disable();
		const data = sessionStorage.getItem('LedgerData');
		if (data) {
			const parsedData = JSON.parse(data);
			this._Receiptid = parsedData.Receiptid;
			this._userid = parsedData.userid;
			this._branchid = parsedData.branchid;
			voucherno = parsedData.voucherno;
			this._action = parsedData.action;
			sessionStorage.removeItem('LedgerData');
		  }
		this._IsProgressSpinner = true;
		this._ReceiptService.PageOnLoad(this._Receiptid, this._userid, this._branchid, voucherno).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._ReceiptModes = resultJSON.receiptmodeList;
			this._currencies = resultJSON.currencies;
			this._CurrenciesTemp = resultJSON.currencies || [];
			this._withoutfilterCurrenciesTemp = resultJSON.currencies || [];
			this._toaccountsTemp = resultJSON.toaccounts || [];
			this._partyTypeList = resultJSON.partyTypeList || [];
			this._receiptsFor = resultJSON.receiptforList || [];
			this._IsProgressSpinner = false;
			this.SetDefaults();
			if (this._action == 'view') {
				const updateJSON = JSON.parse(JSON.stringify(result));
				// this._receiptform.disable();
				updateJSON.receiptupdateview.receiptdate = new Date(updateJSON.receiptupdateview.receiptdate);
				this._fromaccounts = updateJSON.fromaccounts;
				this._toaccounts = updateJSON.toaccounts;
				this._fromaccountsTemp = updateJSON.fromaccounts || [];
				this._toaccountsTemp = updateJSON.toaccounts || [];
				this._receiptbalanceform.controls['fromaccountclosingbalance'].setValue(this._fromaccounts[0].currentbalance);
				this._receiptbalanceform.controls['toaccountclosingbalance'].setValue(this._toaccounts[0].currentbalance);

				this._receiptform.setValue(updateJSON.receiptupdateview);

				this._BillDetails = updateJSON.receiptdetailupdateviews;
				this.selectedBillDetailsRows = updateJSON.receiptdetailupdateviews;
				this._receiptform.disable();
				this.TotalAmount();
				this._IsSaveDisable = true;
				this._IsClearDisable = true;

				var exchangerateinusd = (this._CurrenciesTemp || []).filter(f => f.currencyid == updateJSON.receiptupdateview.currentcurrencyid)[0].exchangerate;

				this._receiptform.controls['exchangerateinusd'].setValue(exchangerateinusd);
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	getfromcustomerclosingbalance(): void {
		let fromaccountid = this._receiptform.get("fromaccount").value;
		if (!fromaccountid) return;
		let partytype = this._receiptform.get("partytype").value;
		let ledger = (this._fromaccounts || []).find(w => w.ledgerid == fromaccountid);

		if (ledger.customerorsupplierseries == "MSC00094")// W Series
		{
			this._currencies = this._CurrenciesTemp.filter(f => f.currencyid != 4);// Bank Currency
		}
		else {
			this._currencies = this._withoutfilterCurrenciesTemp;
		}

		if (ledger) {
			this._toaccounts = this._toaccountsTemp.filter(w => w.series == ledger.series && w.accountgroupid == 30);
		}
		//this._receiptform.controls['toaccount'].setValue('');
		this._BillDetails = [];
		this._oldBillDetails = [];
		var receiptfor = this._receiptotherform.get("paymentfor").value;
		var documentno = this._receiptotherform.get("documentno").value || "";
		this.OnChangeReceiptMode();
		if (!receiptfor || !partytype || !fromaccountid) {
			return;
		}

		var currencyid = this._receiptform.get("currentcurrencyid").value || 1;

		var data = {
			fromaccountid,
			receiptfor: receiptfor,
			documentno: documentno,
			currencyid: currencyid
		}
		this._IsProgressSpinner = true;
		this._receiptform.get("receiptamount").setValue(0);
		this.selectedBillDetailsRows = [];
		this._ReceiptService.FetchBillDetails(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._BillDetails = resultJSON.receiptdetailviews || [];
			this._oldBillDetails = resultJSON.receiptdetailviews || [];
			this._IsProgressSpinner = false;

			if (resultJSON.customer)
				this._paymode = resultJSON.customer.paymode; //paymode -- MSC00065 --Manual /  MSC00066 -- Fifo
			else
				this._paymode = resultJSON.supplier.paymodecode;

			this._receiptform.get("receiptamount").enable();
			if (this._paymode == "MSC00065" && this._BillDetails.length)
				this._receiptform.get("receiptamount").disable();

			this._receiptbalanceform.controls['fromaccountclosingbalance'].setValue(resultJSON.closingbalance);
			this.TotalAmount();
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	gettoclosingbalance(event): void {
		if (!event.value) {
			return;
		}
		let partyType = this._receiptform.controls['partytype'].value;
		this._IsProgressSpinner = true;
		this._ReceiptService.GetToAccountClosingBalance(event.value, 104).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus && resultJSON.tranStatus.result) {
				this._receiptbalanceform.controls['toaccountclosingbalance'].setValue(resultJSON.closingbalance);
			}
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	onSave() {

		if (this._IsSaveDisable) return;

		this._submitted = true;

		let errMsg = '';
		let toaccount = this._receiptform.get("toaccount").value;
		let receiptamount = parseFloat(this._receiptform.get("receiptamount").value || 0);
		let totalreceiptamount = parseFloat(this._receiptbalanceform.get("totalreceiptamount").value || 0);
		let paidAmountInValid = (this.selectedBillDetailsRows || []).some(f => !parseFloat(f.paidamount + '') || parseFloat(f.paidamount + '') <= 0);

		if (!toaccount) {
			errMsg = "Please select To Account.";
		}

		if (paidAmountInValid) {
			errMsg = "Paid amount should be greater than 0 in selected rows.";
		}

		if (totalreceiptamount <= 0 && receiptamount <= 0) {
			errMsg = "0 or Negative value not permitted in total receipt amount.";
		}

		if (this.ShowErrorMsg(errMsg))
			return;

		//Bill Details
		var l_BillDetails: Receiptdetailview[] = [];
		let receiptTotalAmount = 0;
		Object.keys(this.selectedBillDetailsRows).map((Index) => {
			var currentRow = this.selectedBillDetailsRows[Index];
			var l_billdetails = new Receiptdetailview();
			l_billdetails.receiptdetailid = 0;
			l_billdetails.voucherno = currentRow.voucherno;
			l_billdetails.receiptid = this._receiptform.get("receiptid").value;
			l_billdetails.voucherno = currentRow.voucherno;
			l_billdetails.series = currentRow.series;
			l_billdetails.billdate = currentRow.billdate;
			l_billdetails.billno = currentRow.billno;
			l_billdetails.creditamount = currentRow.creditamount;
			l_billdetails.debitamount = currentRow.debitamount;
			l_billdetails.paidamount = currentRow.paidamount;
			l_billdetails.pendingamount = currentRow.pendingamount;
			l_billdetails.vouchertypeid = currentRow.vouchertypeid;
			l_billdetails.vouchertypedesc = currentRow.vouchertypedesc;
			l_billdetails.currencyuniquerefid = currentRow.currencyuniquerefid;
			l_BillDetails.push(l_billdetails);

			receiptTotalAmount += currentRow.paidamount;
		})
		// this._BillDetails = l_BillDetails;
		if (this._receiptform.valid) {
			//For Model and Form Mapping
			this._Receipt = this._receiptform.getRawValue();
			if (!this._Receipt.receiptamount || this._Receipt.receiptamount <= 0)
				this._Receipt.receiptamount = receiptTotalAmount;
			this._ReceiptOBJ = { "Receipt": this._Receipt, "Receiptdetail": l_BillDetails };
			if (this._action == 'create') {
				this._Receipt.createdon = new Date();
				var paymentdate = this._receiptform.get("receiptdate").value;
				var datePipe = new DatePipe("en-US");
				var receiptdates = datePipe.transform(paymentdate, 'yyyy-MM-dd');
				this._Receipt.receiptdate = (new Date(receiptdates))
				this._Receipt.createdby = parseInt(sessionStorage["userid"]);
				this._Receipt.branchid = parseInt(sessionStorage["BranchID"]);
				this._Receipt.accountingyear = new Date().getFullYear();

				//Debug
				//this.onEd();
				this._IsSaveDisable = true;
				this._IsClearDisable = true;
				this._IsProgressSpinner = true;
				this._ReceiptOBJ = this.ConvertToFormObject(this._ReceiptOBJ);
				if (parseFloat(this._Receipt.advanceamount + '' || '0') > 0) {
					let currencyname = this._CurrenciesTemp.find(f => f.currencyid == this._Receipt.currentcurrencyid).currencyname;
					let message = 'Please confirm to put the remaining amount as advance amount ' + this._Receipt.advanceamount + '(' + currencyname + ')';
					this.ShowConfirm(message, () => { this.OnSaveDbCall(); });
				} else {
					this.OnSaveDbCall();
				}
			}
		}
	}

	OnSaveDbCall() {
		this._ReceiptService.create(this._ReceiptOBJ).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));

			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
				this.reset(null);
			}
			else {
				this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
				this._IsSaveDisable = false;
			}

			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsSaveDisable = false; this._IsProgressSpinner = false; });
	}


	ConvertToFormObject(object: Object): any {
		const formData: FormData = new FormData();
		//TimeStamp Add:
		if (this.currentDocument) {
			formData.append('file', this.currentDocument, this.currentDocument.name);
		}
		let jsonData = JSON.stringify(object);
		formData.append('Receipt', jsonData);
		return formData;
	}

	//Debug
	onEd() {
		ace.config.set("fontSize", "14px");
		ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
		const aceEditor = ace.edit(this.editor.nativeElement);
		aceEditor.getSession().setMode("ace/mode/json");
		var myObj = this._ReceiptOBJ;
		var myJSON = JSON.stringify(myObj);
		aceEditor.session.setValue(myJSON);

	}
	reset(event) {
		this._IsSaveDisable = false;
		this._IsClearDisable = false;
		this._submitted = true;
		this.selectedBillDetailsRows = [];
		this._receiptform.reset();
		this.InitializeForm();
		this._submitted = false;
		this._BillDetails = [];
		this._oldBillDetails = [];
		this._fromaccounts = [];
		this._toaccounts = [];
		this.receiptmodel = {};
	}
	// on clicking back button , it Navigates to the search screen
	goBack(event) {
		//alert('This');
		console.log("goBack");
		this._router.navigate(['/vReceipt']);
	}
	ChangeCurrencies(event): void {
		var exchangerateinusd = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].exchangerate;
		this._receiptform.controls['exchangerateinusd'].setValue(exchangerateinusd);
		this.getfromcustomerclosingbalance();
	}

	allocateReceiptAmount(event) {
		// get Paymode from customer master and check if it is FIFO . MSC00065 --Manual /  MSC00066
		this.selectedBillDetailsRows = [];

		var RemainingAmount = 0;
		var ReceiptAmount = parseFloat(this._receiptform.get("receiptamount").value || 0);

		this.TotalAmount();

		if (this._paymode != 'MSC00066' || !this._BillDetails.length || ReceiptAmount <= 0)
			return;

		this._BillDetails.every((item, index) => {
			item.isselected = true;
			this.selectedBillDetailsRows.push(item);
			item.pendingamount = item.tempPendingamount;
			RemainingAmount = ReceiptAmount - item.tempPendingamount;
			if ((RemainingAmount >= 0))
				item.paidamount = item.tempPendingamount;
			else
				item.paidamount = ReceiptAmount;

			item.pendingamount -= (item.paidamount);
			if (RemainingAmount <= 0)
				return false;
			else {
				ReceiptAmount = RemainingAmount;
				return true;
			}
		});
		this.TotalAmount();
	}

	OnChangeReceiptMode() {
		let userid = parseInt(sessionStorage["userid"] || 0);
		var receiptMode = this._receiptform.get("receiptmode").value;
		this._isdatedisabled = receiptMode !== "MSC00148"
		this._receiptform.get('receiptdate').setValue(new Date());
		this._receiptform.controls["toaccount"].reset();
		let cashgroups = [30, 31];
		this.AccountBasedonReceiptMode();
		if (receiptMode == "MSC00146") { // Cash
			this._toaccounts = this._toaccountsTemp.filter(f => cashgroups.includes(f.accountgroupid));
			var ledger = this._toaccounts.find(f => f.partycode == userid);
			if (ledger)
				this._receiptform.get("toaccount").setValue(ledger.ledgerid);
		}
		else
			this._toaccounts = this._toaccountsTemp.filter(f => !cashgroups.includes(f.accountgroupid));
		this.SetDefaults();
	}


	RowSelect(event) {
		var data = <Receiptdetailview>event.data;
		data.paidamount = parseFloat(data.paidamount + '' || '0');
		data.isselected = true;
		if (data.vouchertypeid == 7) {
			data.paidamount = Math.abs(data.creditamount);
		}
		this.TotalAmount();
	}

	RowUnSelect(event) {
		var data = <Receiptdetailview>event.data;
		data.isselected = false;
		data.paidamount = 0;
		data.pendingamount = data.tempPendingamount;
		this.TotalAmount();
	}

	selectall() {
		let isChecked = (this.selectedBillDetailsRows.length > 0);
		if (isChecked) {
			for (var i = 0; i < this.selectedBillDetailsRows.length; i++) {
				let event = { data: this.selectedBillDetailsRows[i] };
				this.RowSelect(event);
			}
		}
		else {
			for (var i = 0; i < this._BillDetails.length; i++) {
				let event = { data: this._BillDetails[i] };
				this.RowUnSelect(event);
			}
		}
	}

	TotalAmount() {
		let advanceAmount = 0;
		let paidAmount = 0;
		let receiptamount = parseFloat(this._receiptform.get("receiptamount").value || 0);
		let creditamount = 0;
		let selectedList = this._BillDetails.filter(f => this.selectedBillDetailsRows.includes(f));
		selectedList.forEach(element => {
			if (element.creditamount > 0) {
				paidAmount += parseFloat(element.paidamount + '' || '0');
			}
		});

		if ((this._paymode == "MSC00065" && this._BillDetails.length)) // -- Manual
		{
			receiptamount = paidAmount;//- creditamount;
			this._receiptform.get("receiptamount").setValue(receiptamount);
		}

		if (paidAmount >= receiptamount) {
			receiptamount = paidAmount;
			this._receiptform.get("receiptamount").setValue(receiptamount);
		}

		//this._TotalReceiptAmount -= debitAmount;
		this._receiptbalanceform.controls['totalreceiptamount'].setValue(receiptamount);
		//if (this._paymode == "MSC00065") {
		advanceAmount = (receiptamount - paidAmount);
		if (advanceAmount > 0) {
			this._receiptform.controls['advanceamount'].setValue(advanceAmount);
		} else {
			this._receiptform.controls['advanceamount'].setValue(0);
		}
		//}
	}

	OnChangePaidAmount(value, modelObject) {
		modelObject['paidamount'] = parseFloat(modelObject['paidamount'] || 0);
		var pendingAmount = parseFloat(modelObject['tempPendingamount'] || 0);
		let debitAmount = parseFloat(modelObject["debitamount"] || 0);

		var paidAmount = parseFloat(value || 0);
		var amount = debitAmount == 0 ? (pendingAmount - paidAmount) : (paidAmount + pendingAmount);
		if (amount < 0 && debitAmount == 0) {
			if (this.ShowErrorMsg("Paid Amount must be less than Pending Amount.")) {
				modelObject['paidamount'] = pendingAmount;
				modelObject['pendingamount'] = 0;
			}
		}
		else {
			if (debitAmount != 0 && paidAmount > Math.abs(debitAmount)) {
				if (this.ShowErrorMsg("Paid Amount must be less than Pending Amount.")) {
					amount = 0;
				}
			}
			modelObject['pendingamount'] = amount.toFixed(2);
		}
		this.TotalAmount();
	}

	ShowErrorMsg(errMsg) {
		if (!errMsg)
			return false;

		this.messageService.add({ severity: 'error', summary: 'Error Message', sticky: true, detail: errMsg });
		return true;
	}

	GetCustomerByPartyType($event) {
		let userid = sessionStorage["userid"];
		this.selectedBillDetailsRows = [];
		this._BillDetails = [];
		this._fromaccounts = [];
		this._fromaccountsTemp = [];
		this._IsProgressSpinner = true;
		this._ReceiptService.GetCustomerByPartyType(userid, $event.value).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._fromaccounts = resultJSON.fromaccounts;
			this._fromaccountsTemp = resultJSON.fromaccounts || [];
			this.AccountBasedonReceiptMode();
			this._IsProgressSpinner = false;

		});
	}

	HotKeyIntegration() {
		this._hotkeysService.reset();
		if (this._action != 'view') {
			this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
				this.onSave();
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		if (this._action == 'create') {
			this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
				this.reset(null);
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
			this.goBack(null);
			return false; // Prevent bubbling
		}, ['INPUT', 'SELECT', 'TEXTAREA']));
	}


	//Document Select:
	onDocumentSelect(event, document) {
		this.currentDocument = event.currentFiles[0];
		this._receiptform.get("attachmentname").setValue(this.currentDocument.name);
		document.clear();
	}

	// Document Upload 
	showdocumentDialog(document: FileUpload) {
		let url = null;
		if (this._action != 'view') {
			if (this.currentDocument) {
				//this.currentDocumentSafeURL = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.currentDocument));
				this.imageselect = this.currentDocument.name;
				url = URL.createObjectURL(this.currentDocument);
			}
			if (!url)
				return;

			window.open(url, "MsgWindow", "width=800,height=800");
		} else {
			let attachment = this._receiptform.get("attachmentname").value;
			if (attachment) {
				url = attachment;
				window.open(url, "MsgWindow", "width=800,height=800");
			}
		}
	}

	//Document Clear:
	onDocumentClear(document: FileUpload) {
		this._receiptform.get("attachmentname").reset();
		this.currentDocumentSafeURL = null;
		this.currentDocument = null;
		document.clear();
	}

	SendMail(event) {
		// var workflowid = 5;
		this._userid = sessionStorage["userid"];

		//76.after clicking the send to approve button, the purchase order is given to 4 levels of approval 
		this._IsProgressSpinner = true;

		this._ReceiptService.SendMail(this._userid, this._branchid, this._Voucherno).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && resultJSON) {
				this._CustomExceptionService.handleSuccess(usererrors.MailSent_Success);
			}
			else {
				this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);

			}
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	ShowConfirm(message: string, yesHandler: any, NoHandler?: any) {
		this.receiptmodel.showdialog = true;

		this.receiptmodel.message = message;

		this.receiptmodel.Yes = () => {
			if (typeof yesHandler === 'function')
				yesHandler();
			this.receiptmodel.showdialog = false;
		};

		this.receiptmodel.Cancel = () => {
			if (typeof NoHandler === 'function')
				NoHandler();
			this._IsSaveDisable = false;
			this.receiptmodel.showdialog = false;
			this._IsProgressSpinner = false;
		};
	}

	AccountBasedonReceiptMode() {
		let receiptMode = this._receiptform.get('receiptmode').value;
		let partytype = this._receiptform.get('partytype').value;
		this._fromaccounts = this._fromaccountsTemp.filter(account => {
			if (receiptMode === 'MSC00148') {
				return account.customerorsupplierseries !== 'MSC00094';
			} else {
				return true;
			}
		});
	}

}