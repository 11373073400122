<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
          </div>
        <div class="white">
            <form [formGroup]="_roleform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Role Creation</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple  title="Save" type="submit" icon="pi pi-save" 
                                [disabled]="isDisabledSave"   ></button>
                                    <!-- 12.All entered input fields should be cleared. -->
                                <button pButton pRipple  title="Clear" type="button" icon="pi pi-trash" 
                                     class="p-button-danger" (click)="reset($event)" [disabled]="isDisabledClear"></button>
                                       <!--24. on clicking back button , it Navigates to the search screen -->
                                <button pButton pRiple  icon="pi pi-search" title="Back to Search"
                                    class="p-button p-button-success p-mr-2" (click)="goBack($event)" ></button>
                                <!-- <button pButton pRipple label="" icon="pi pi-times" class="" (click)="hideDialog()" ></button>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <table class="normal-table">
                        <tr>

                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="rolename" formControlName="rolename"  maxlength="50" pInputText >
                                     <label for="rolename">Role Name <span class="hlt-txt">*</span></label>
                                </span>
                                <!--6. if "Role name" is not given, show error as "Please Enter Role name" -->
                                <span class="text-danger"
                                *ngIf="(_submitted || _roleform.get('rolename').touched) && _roleform.get('rolename').errors?.RoleNameRequired">
                                Please enter role name
                            </span>
                            </td>

                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar dateFormat="dd/mm/yy" [monthNavigator]="true"
                                    [yearNavigator]="true" yearRange="1980:2030" 
                                    (onSelect)="OnFromDateSelect($event)" formControlName="effectivefrom" [showIcon]="true"></p-calendar>
                                    <!-- <p-calendar name="effectivefrom" [readonlyInput]="true" formControlName="effectivefrom" [showIcon]="true" dateFormat="dd/mm/yy"
                                    (onSelect)="OnFromDateSelect($event)">
                                    </p-calendar> -->
                                    <label for="effectivefrom">Effective From <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_submitted || _roleform.get('effectivefrom').touched) && _roleform.get('effectivefrom').errors?.EffectiveFromRequired">
                                Please select effective from
                            </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="effectiveto" [monthNavigator]="true"
                                    [yearNavigator]="true" yearRange="1980:2030"  [readonlyInput]="true" formControlName="effectiveto" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [minDate]="minDate">
                                    </p-calendar>
                                    <label for="effectiveto">Effective To</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown  [autoDisplayFirst]="false" [options]="_rolestatus"
                                    optionLabel="metasubdescription" optionValue="metasubcode" formControlName="rolestatus">
                                    </p-dropdown>
                                    <label for="rolestatus"> Status </label>
                                </span>
                            </td>
                          
                            <td style="width: 20%;"> 
                                 <!-- Debug -->
                                <!-- <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div> -->
                            </td>
                        </tr>
                    </table>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>
