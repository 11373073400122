import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Materialreturnrequest } from 'src/app/core/Models/Materialreturnrequest';
import { MaterialrequestService } from 'src/app/core/Services/ecommerce/materialrequest.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-c-order-return',
  templateUrl: './c-order-return.component.html',
  styleUrls: ['./c-order-return.component.css']
})
export class COrderReturnComponent implements OnInit {
  _action: string = "edit";
  RequestNo: any
  OrderNo: any
  OrderId: any
  ProductName: any
  TotalReturnQuantity: any
  _SalesReturnDetail: any[] = []
  _Tellernames: any[] = []
  _IsProgressSpinner: boolean = true;
  SalesReturn: any
  TellerId: any
  ShowExistingData: boolean
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _route: ActivatedRoute,
    private _serviceProductDeliveryService: MaterialrequestService,
    private _router: Router,
    private utility: CommonUtilities,
    private _hotkeysService: HotkeysService
  ) { }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._IsProgressSpinner = false;
    this.SalesReturn = history.state?.salesreturnno ? history.state?.salesreturnno : 0;
    this.ShowExistingData = history.state.ShowExistingData;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.OrderNo = this.SalesReturn.orderno
    this.ProductName = this.SalesReturn.productname
    this.OrderId = this.SalesReturn.orderid
    this.TotalReturnQuantity = this.SalesReturn.returnquantity
    if (this._action == "edit") {
      let obj = {
        "orderno": this.SalesReturn.orderno,
        "orderid": this.SalesReturn.orderid,
        "productid": this.SalesReturn.productid,
        "ordercancelid": this.SalesReturn.ordercancelid,
        "ShowExistingData": this.ShowExistingData,
      }
      this._IsProgressSpinner = true;
      this._serviceProductDeliveryService.PageInitialise(obj).subscribe((result: any) => {
        this._IsProgressSpinner = false;
        if (result && result.tranStatus.result) {
          console.log(result, 'objectes');
          this._Tellernames = result.userList
          this._SalesReturnDetail = result.redturnDetail
        }
        else {
          this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
          this._IsProgressSpinner = false;
        }
      }, (error) => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    else if (this._action == "view") {
      this.RequestNo = this.SalesReturn.salesreturnno
      let obj = {
        "orderno": this.SalesReturn.orderno,
        "orderid": this.SalesReturn.orderid,
        "productid": this.SalesReturn.productid,
        "ordercancelid": this.SalesReturn.ordercancelid,
        "ShowExistingData": this.ShowExistingData,
      }
      this._IsProgressSpinner = true;
      this._serviceProductDeliveryService.PageInitialise(obj).subscribe((result: any) => {
        this._IsProgressSpinner = false;
        if (result && result.tranStatus.result) {
          console.log(result, 'objectes');
          this._Tellernames = result.userList
          this._SalesReturnDetail = result.redturnDetail
          this.TellerId = result.redturnDetail[0].pickedby
        }
        else {
          this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
          this._IsProgressSpinner = false;
        }
      }, (error) => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    else { this.goBack() }
  }
  goBack() {
    this._router.navigate(['/VMaterialReturnRequest']);
  }
  OnChangereceivedquantity(row: any, event) {
    let receivedquantity = row.receivedquantity
    let DeliveredQty = row.deliveredquantity
    if (receivedquantity > DeliveredQty) {
      row.receivedquantity = 0
      this._CustomExceptionService.handleError("Received QTy Should Not Be Greater Than The Delivered Qty For Lot No" + row.lotnumber + "");
      return
    }
  }
  onSave() {
    if (!this.TellerId) {
      this._CustomExceptionService.handleError("Please Select Picked By");
      return
    }
    let ToalReceivedQty: number = 0
    if (!this._SalesReturnDetail || !this._SalesReturnDetail.length) {
      this._CustomExceptionService.handleError("No Data In Grid");
      return
    }
    for (let i = 0; i < this._SalesReturnDetail.length; i++) {
      if (!this._SalesReturnDetail[i].receivedquantity) {
        this._SalesReturnDetail[i].receivedquantity = 0
      }
      if (this._SalesReturnDetail[i].receivedquantity > this._SalesReturnDetail[i].deliveredquantity) {
        this._SalesReturnDetail[i].receivedquantity = 0
        this._CustomExceptionService.handleError("Received QTy Should Not Be Greater Than The Delivered Qty For Lot No" + this._SalesReturnDetail[i].lotnumber + "");
        return
      }
      if (this._SalesReturnDetail[i].receivedquantity != 0) {
        ToalReceivedQty += this._SalesReturnDetail[i].receivedquantity
      }
    }
    this._SalesReturnDetail = this._SalesReturnDetail.filter(f => f.receivedquantity > 0)
    var l_OrderReturns: Materialreturnrequest[] = [];
    Object.keys(this._SalesReturnDetail).map((Index) => {
      var currentRow = this._SalesReturnDetail[Index];
      var l_orderreturns = new Materialreturnrequest();
      l_orderreturns.materialreturnrequestid = 0;
      l_orderreturns.salesreturnid = 0;
      l_orderreturns.salesreturnno = "";
      l_orderreturns.returnbranchid = currentRow.returnbranchid;
      l_orderreturns.pickedby = this.TellerId;
      l_orderreturns.ordercancelid = currentRow.ordercancelid;
      l_orderreturns.returnbycustomer = currentRow.returnbycustomer;
      l_orderreturns.receivedquantity = currentRow.receivedquantity || 0;
      l_orderreturns.eorderid = this.OrderId;
      l_orderreturns.lotnumber = currentRow.lotnumber;
      l_orderreturns.eorderno = this.OrderNo;
      l_orderreturns.createdby = sessionStorage["userid"];
      l_orderreturns.createdon = new Date();
      l_OrderReturns.push(l_orderreturns);
    })
    if (ToalReceivedQty > this.TotalReturnQuantity) {
      this._CustomExceptionService.handleError("Total Received QTy Should Not Be Greater Than The Total Returned Qty");
      return
    }
    if (ToalReceivedQty != this.TotalReturnQuantity) {
      this._CustomExceptionService.handleError("Total Received QTy Should Not Be Equal To The Total Returned Qty");
      return
    }
    if (ToalReceivedQty == this.TotalReturnQuantity) {
      this._IsProgressSpinner = true;
      let Data = {
        "Materialreturnrequest": l_OrderReturns
      }
      this._serviceProductDeliveryService.Create(Data).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._IsProgressSpinner = false;
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
        }
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    this.goBack()
  }
}