<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Sales Return Request</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                (click)="onSave()"></button>
                            <button pButton pRiple type="button" (click)="goBack()" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="text" disabled pInputText [(ngModel)]="RequestNo">
                                <label>Request No</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="text" disabled pInputText [(ngModel)]="OrderNo">
                                <label for="serviceproductdeliverydate">Order No <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="text" disabled pInputText [(ngModel)]="ProductName">
                                <label for="servicerequestno">Product Name<span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="text" disabled pInputText [(ngModel)]="TotalReturnQuantity">
                                <label for="servicerequestno">Total Return Quantity<span
                                        class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [options]="_Tellernames" optionLabel="username" [filter]="true"
                                    [disabled]="_action=='view'" filterBy="username" optionValue="userid"
                                    [(ngModel)]="TellerId" [autoDisplayFirst]="false">
                                </p-dropdown>
                                <label for="servicerequestno">Picked By<span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_SalesReturnDetail" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['productname','pruchaseorinvoicedate','srquantity','uomdesc','unitprice','finalamount']"
                        [rowHover]="true" dataKey="productid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="productname">Branch<p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product Name <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="uomdesc">Lot No <p-sortIcon field="uomdesc">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="unitprice">Delivered Qty<p-sortIcon field="unitprice"></p-sortIcon>
                                </th>
                                <th pSortableColumn="srquantity">Received Qty<p-sortIcon field="srquantity">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="srquantity" style="text-align: center;">Is Customer Returned
                                    <p-sortIcon field="srquantity">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_serviceproductdeliverydetail>
                            <tr>
                                <td>
                                    {{_serviceproductdeliverydetail.branchname }}
                                </td>
                                <td>
                                    {{_serviceproductdeliverydetail.productname }}
                                </td>
                                <td>
                                    {{_serviceproductdeliverydetail.lotnumber }}
                                </td>
                                <td>
                                    {{_serviceproductdeliverydetail.deliveredquantity }}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText name="receivedquantity"
                                                [disabled]="_action=='view'"
                                                (keyup)="OnChangereceivedquantity(_serviceproductdeliverydetail,$event)"
                                                [(ngModel)]="_serviceproductdeliverydetail.receivedquantity">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_serviceproductdeliverydetail.receivedquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td style="text-align: center;">
                                    <p-checkbox [(ngModel)]="_serviceproductdeliverydetail.returnbycustomer"
                                        [disabled]="_action=='view'" binary="true">
                                    </p-checkbox>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>