import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OpeningBalanceLedgerAndCurrencyWise } from '../../Models/OpeningBalanceLedgerAndCurrencyWise';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class OpeningBalanceUpdationService {
  public _Action: IAction;
  public _httpOptions;
  public _OpeningBalanceUpdationService = environment.OpeningBalanceUpdationService;
  constructor(private http: HttpClient) { }
  PageOnload(): Observable<any> {
    var Query = this._OpeningBalanceUpdationService + '/PageOnLoad';
    return this.http.get<{ data: any }>(Query);
  }
  Search(obj: object): Observable<any> {
    var Query = this._OpeningBalanceUpdationService + '/Search';
    return this.http.post(Query, obj);
  }
  
  Create(obj: object): Observable<any> {
    var Query = this._OpeningBalanceUpdationService + '/Create';
    return this.http.post(Query, obj);
  }
 
}
