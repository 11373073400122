import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Currency } from 'src/app/core/Models/Currency';
import { Level } from 'src/app/core/Models/Level';
import { productsCattegory } from 'src/app/core/Models/overallclass';
import { Producttype } from 'src/app/core/Models/Producttype';
import { RptproductpricehistoryService } from 'src/app/core/Services/reports/RptProductPriceHistory/rptproductpricehistory.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { vProductTypeMultiNameSelect, vProductCategoryMultiNameSelect, vProductMultiNameSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-rptproductpricehistory',
  templateUrl: './rptproductpricehistory.component.html',
  styleUrls: ['./rptproductpricehistory.component.css']
})
export class RptproductpricehistoryComponent implements OnInit {

  _producttype: Producttype[];
  _productcategory: productsCattegory[];
  _productname: any[];
  _productprices: any[];
  _productpricehistoryform: FormGroup;
  _productPriceHistories: any[];
  _productPriceHistory: any;
  ProductpriceObj: any[];
  today = new Date();
  _levellist: Level[];
  _IsProgressSpinner: boolean = false;
  selectedrows: any;
  _branch: Branch[];
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _ProductwisestockObj: any;
  datepipe = new DatePipe('en-US');
  _submitted = false;
  cols: any[];
  _Action: IAction;
  mindate: Date;
  maxdate: Date;

  constructor(private fb: FormBuilder, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _ProductPriceHistoryService: RptproductpricehistoryService, private _CustomExceptionService: CustomExceptionService, private exportUtility: ExportUtility) { }
  InitializeProductPriceForm() {
    this._productpricehistoryform = this.fb.group({
      producttypeid: ['', vProductTypeMultiNameSelect],
      productcategoryid: ['', vProductCategoryMultiNameSelect],
      productid: ['', vProductMultiNameSelect],
      currencyid: [],
      branchid: [],
      fromdate: [],
      todate: [],
      levelid: []
    });

    if (sessionStorage['Environmentenddate'] == "null") {
      this._productpricehistoryform.get("fromdate").setValue(new Date);
      this._productpricehistoryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._productpricehistoryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._productpricehistoryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(168);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeProductPriceForm();

    this._IsProgressSpinner = true;
    this._ProductPriceHistoryService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      this._producttype = resultJSON.producttypes;
      this._levellist = resultJSON.levels;
      this._branch = resultJSON.branches;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductcategory(event) {
    var selectedProductType = event.value;

    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategory = [];
      this._productpricehistoryform.controls["productcategoryid"].reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": sessionStorage["currentbranchid"] || 0 };
      this._ProductPriceHistoryService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategory = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }

  }
  getProductname(event) {
    var selectedProductCategory = event.value;
    //var branchid = this._productpricehistoryform.get("branchid").value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      return;
    }
    this._IsProgressSpinner = true;
    this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": sessionStorage["currentbranchid"] || 0 };
    this._ProductPriceHistoryService.getProduct(this._ProductCategoryObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      let products = resultJSON.productdetails;
      let uniqueChars = [...new Set(products)];
      uniqueChars = products.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.productid === test.productid
        )
      );
      this._productname = uniqueChars;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  search() {
    this._submitted = true;
    if (this._productpricehistoryform.valid) {
      var _fromdate = this._productpricehistoryform.get("fromdate").value;
      var _todate = this._productpricehistoryform.get("todate").value;
      var datePipe = new DatePipe("en-US");
      var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
      var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
      if (fromdate == null) {
        this._CustomExceptionService.handleError("Please Select From Date");
        return;
      }
      if (todate == null) {
        this._CustomExceptionService.handleError("Please Select To Date");
        return;
      }
      if (fromdate > todate) {
        this._CustomExceptionService.handleError("From Date should be less than the To Date");
        return;
      }
      var levelid = this._productpricehistoryform.get("levelid").value;
      // var branchid = this._productpricehistoryform.get("branchid").value || [];
      var productid = this._productpricehistoryform.get("productid").value || [];

      this._IsProgressSpinner = true;
      this._ProductwisestockObj = { "ProductId": productid || [], "levelid": levelid || [], "fromdate": fromdate, "todate": todate };
      //  this.onEd();
      this._ProductPriceHistoryService.getProductPriceHistory(this._ProductwisestockObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productPriceHistories = resultJSON.productslist;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  clear() {
    this._submitted = true;
    this._productpricehistoryform.reset();
    this.InitializeProductPriceForm();
    this._submitted = false;
    this._productcategory = [];
    this._productname = [];
  }
  exportExcel() {
    let PettyCashExpenseList = this.GetPettyCashExpenseList();
    this.exportUtility.exportExcel(PettyCashExpenseList, 'Product_Price_History', 'xlsx');
  }
  ExportToPdf() {
    let PettyCashExpenseList = this.GetPettyCashExpenseList();
    const data = PettyCashExpenseList.map(Object.values);
    const head = [[
      'Changed Date',
      'Changed by',
      'Product Name',
      'Purchase Rate',
      'Markup Level',
      'Markup %',
      'USD Include',
      'USD Exclude',
      'RAND Include',
      'RAND Exclude',
      'BOND Include',
      'BOND Exclude',
      'BANK Include',
      'BANK Exclude'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Product_Price_History.pdf');
      })
    })
  }
  GetPettyCashExpenseList() {
    if (!this._productPriceHistories || !this._productPriceHistories.length)
      return;
    let PettyCashExpenseList = [];
    this._productPriceHistories.forEach(element => {
      let PettyCashExpense: any = {};
      PettyCashExpense["Changed Date"] = this.datepipe.transform(element.changedate, 'dd/MM/yyyy');
      PettyCashExpense["Changed by"] = element.changedbyname;
      PettyCashExpense["Product Name"] = element.productname;
      PettyCashExpense["Purchase Rate"] = element.purchaserateinusd;
      PettyCashExpense["Markup Level"] = element.levelname;
      PettyCashExpense["Markup %"] = element.markuppercentage;
      PettyCashExpense["USD Include"] = element.temp_usd_vatin;
      PettyCashExpense["USD Exclude"] = element.temp_usd;
      PettyCashExpense["RAND Include"] = element.temp_rand_vatin;
      PettyCashExpense["RAND Exclude"] = element.temp_rand;
      PettyCashExpense["BOND Include"] = element.temp_bond_vatin;
      PettyCashExpense["BOND Exclude"] = element.temp_bond;
      PettyCashExpense["BANK Include"] = element.temp_bank_vatin;
      PettyCashExpense["BANK Exclude"] = element.temp_bank;
      PettyCashExpenseList.push(PettyCashExpense);
    });
    return PettyCashExpenseList;
  }
}
