<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Sales Summary Report</h3>
                    </div>

                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="GenerateReportexcel()" [disabled]="_exceldisable"></button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptsalesform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                        [yearNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030"
                                        dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="fromdate">From Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                        [yearNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="todate">To Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;" *ngIf="branchselectionlimit==0">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchid" [filter]="true"
                                        [virtualScroll]="true" itemSize="30"
                                        [filter]="true" filterBy="branchname" [ngModelOptions]="{standalone: true}">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="branchname">Branch Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;" *ngIf="branchselectionlimit>0">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchid" [filter]="true"
                                         [selectionLimit]="1" [virtualScroll]="true"
                                        itemSize="30" [filter]="true" filterBy="branchname"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                    <label for="branchname">Branch Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_currencylist" optionLabel="currencyname"
                                        optionValue="currencyid" [(ngModel)]="currencyid" [filter]="true"
                                        filterBy="currencyname" [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                    <label for="currencyid">Currency Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <iframe #iframe height="550" width="100%" [src]="_src"></iframe>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>