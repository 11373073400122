import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Brand } from 'src/app/core/Models/Brand';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { RptbranchwisestockService } from 'src/app/core/Services/reports/Stock/RptBranchwiseStock/rptbranchwisestock.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-product-price-upload',
  templateUrl: './product-price-upload.component.html',
  styleUrls: ['./product-price-upload.component.css']
})
export class ProductPriceUploadComponent implements OnInit {

  _IsProgressSpinner: boolean = true;
  _rptproductpriceuploadform: FormGroup;
  _src: SafeResourceUrl;
  _branchlist = sessionStorage["BranchID"];
  _branchID: number = parseInt(sessionStorage["currentbranchid"]);
  _userid: number = parseInt(sessionStorage["userid"]);
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _brandlist: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  branchid: number;
  brandid: number[] = [];
  _ReportLists: any[] = [];
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _ProductObj: any;
  _Tempbrandlist: Brand;
  cols: any[];
  _BranchWiseObj: any;


  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptbranchwisestockService: RptbranchwisestockService,
    private exportUtility: ExportUtility,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'branchname', header: 'Branch Name' },
      { field: 'productcategoryname', header: 'Category Name' },
      { field: 'productcode', header: 'Product Code' },
      { field: 'productname', header: 'Product Name' },
      { field: 'brandname', header: 'Brand' },
      { field: 'salesuomname', header: 'Uom' },
      { field: 'okstock', header: 'UnRestricted Ok Stock' },
      { field: 'lockedstock', header: 'Blocked / Lock Stock' },
      { field: 'closingstock', header: 'Closing Stock' },
    ]
    this._rptproductpriceuploadform = this.fb.group({
      branchid: [0],
      brandid: [0],
      productid: [0],
      productcategoryid: [0],
      producttypeid: [0]

    })
    this._userid = parseInt(sessionStorage["userid"]);

    this._RptbranchwisestockService.PageOnload({}).subscribe((result) => {
      this._IsProgressSpinner = true;
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._producttypelist = resultJSON.producttypeList;
      this._brandlist = resultJSON.brandList;
      this._rptproductpriceuploadform.get("branchid").setValue(this._branchID);

      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    this.productcatid = [];
    this.productid = [];
    this._productlist = [];
    this.brandid = [];
    this._brandlist = [];
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      this._rptproductpriceuploadform.controls["productcategoryid"].reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = {
        "Producttype": selectedProductType || [],
        "branchid": parseInt(sessionStorage["currentbranchid"])
      };
      this._RptbranchwisestockService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    this.productid = [];
    this.brandid = [];
    this._brandlist = [];
    var branchid = this._rptproductpriceuploadform.get("branchid").value == null ? 0 : this._rptproductpriceuploadform.get("branchid").value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    } else {
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": branchid || parseInt(sessionStorage["currentbranchid"]) };
      this._RptbranchwisestockService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
      });
    }
  }
  ChangeProduct(event) {
    var selectedProduct = event.value;
    var branchid = this._rptproductpriceuploadform.get("branchid").value == null ? 0 : this._rptproductpriceuploadform.get("branchid").value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._brandlist = this._Tempbrandlist
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductObj = {
        "Products": selectedProduct || [],
        "branchid": parseInt(sessionStorage["currentbranchid"])
      };
      this._RptbranchwisestockService.getBrand(this._ProductObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        let productbrand = resultJSON.productbrandlist;
        let uniqueChars = [...new Set(productbrand)];
        uniqueChars = productbrand.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.brandid === test.brandid
          )
        );
        this._brandlist = uniqueChars;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  GenerateReport(event) {

    var branchid = this._rptproductpriceuploadform.get("branchid").value == null ? 0 : this._rptproductpriceuploadform.get("branchid").value;
    if (!branchid) {
      this._CustomExceptionService.handleError("Please Select Branch Name");
      return;
    }
    this._BranchWiseObj = {
      "BranchId": branchid || parseInt(sessionStorage["currentbranchid"]),
      "ProductType": this.producttypeid || [],
      "ProductCategory": this.productcatid || [],
      "Product": this.productid || [],
      "Brand": this.brandid
    };
    this._IsProgressSpinner = true;
    this._RptbranchwisestockService.ProductPriceUpload(this._BranchWiseObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ReportLists = resultJSON.rptProductPriceUpload;
      if (!resultJSON.rptProductPriceUpload || !resultJSON.rptProductPriceUpload.length) {
        this._CustomExceptionService.handleError("No Data Available");
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })

  }
  clear(event) {
    this._rptproductpriceuploadform.reset();
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this.branchid = 0;
    this._productcategorylist = [];
    this._productlist = [];
    this.brandid = [];
    this._ReportLists = [];
    this._brandlist = [];
    this.ngOnInit();

  }
  exportExcel1() {
    let GetProductpricedetail = this.GetProductpricedetail();
    this.exportUtility.exportExcel(GetProductpricedetail, 'ProductPriceUpload', 'xlsx');
  }

  GetProductpricedetail() {
    if (!this._ReportLists || !this._ReportLists.length)
      return;
    let ProductpricechangeList = [];
    this._ReportLists.forEach(element => {
      let productpricechange: any = {};
      productpricechange["Ind"] = element["Ind"];
      productpricechange["PLU #"] = element["PLU #"];
      productpricechange["Department"] = element["Department"];
      productpricechange["Bar Format"] = element["Bar Format"];
      productpricechange["EAN #"] = element["EAN #"];
      productpricechange["Price"] = element["Price"];
      productpricechange["Shelf Life"] = element["Shelf Life"];
      productpricechange["Best By"] = element["Best By"];
      productpricechange["Wt/Qty"] = element["Wt/Qty"];
      productpricechange["CDV"] = element["CDV"];
      productpricechange["Font Line 1"] = element["Font Line 1"];
      productpricechange["Description Line 1"] = element["Description Line 1"];
      productpricechange["Font Line 2"] = element["Font Line 2"];
      productpricechange["Description Line 2"] = element["Description Line 2"];
      productpricechange["Font Line 3"] = element["Font Line 3"];
      productpricechange["Description Line 3"] = element["Description Line 3"];
      productpricechange["Font Line 4"] = element["Font Line 4"];
      productpricechange["Description Line 4"] = element["Description Line 4"];

      ProductpricechangeList.push(productpricechange);
    });
    return ProductpricechangeList;
  }
  exportExcel() {
    const csvContent = this.exportToCSV();
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'ProductPriceUpload.csv');
  }

  exportToCSV() {
    const columnsToExport = [
      "Ind",
      "PLU #",
      "Department",
      "Bar Format",
      "EAN #",
      "Price",
      "Shelf Life",
      "Best By",
      "Wt/Qty",
      "CDV",
      "Font Line 1",
      "Description Line 1",
      "Font Line 2",
      "Description Line 2",
      "Font Line 3",
      "Description Line 3",
      "Font Line 4",
      "Description Line 4"
    ];
    const header = columnsToExport.join(',');
    const csv = this._ReportLists.map(row => {
      return columnsToExport.map(col => row[col]).join(',');
    }).join('\n');

    const csvContent = `${header}\n${csv}`;

    return csvContent;

  }

}
