export class Grndocumentdetail {
    grndocumentdetailid: number;
    grnid: number;
    documentname: string;
    documentdescription: string;
    attachmenturl: string;
    attachmentname: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}