<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Balance Sheet Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptbalancesheetform">
                    <table class="normal-table">
                        <tr>
                            <!-- <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar view="month" dateFormat="mm/yy" [yearNavigator]="true"
                                        yearRange="2020:2030" [showIcon]="true"
                                        formControlName="todate" [readonlyInput]="true"></p-calendar>
                                    <label for="month">Month <span class="hlt-txt">*</span></label>
                                </span>
                            </td> -->
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar view="month" dateFormat="mm/yy" [yearNavigator]="true"
                                        yearRange="2000:2030" [showIcon]="true" formControlName="month"
                                        [readonlyInput]="true"  [minDate]="mindate" [maxDate]="maxdate"></p-calendar>
                                    <label for="month">Month <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_currencylist"
                                        optionValue="currencyid" optionLabel="currencyname" formControlName="currencyid"
                                        [filter]="true" filterBy="currencyname">
                                    </p-dropdown>
                                    <label for="currencyname">Currency</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <iframe #iframe height="500" width="100%" [src]="_src"></iframe>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>