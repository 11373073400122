import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { StockadjustmentService } from 'src/app/core/Services/inventory/stockadjustment.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Stockadjustmentview } from 'src/app/core/Views/Stockadjustmentview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-v-stockadjustment',
  templateUrl: './v-stockadjustment.component.html',
  styleUrls: ['./v-stockadjustment.component.css']
})
export class VStockadjustmentComponent implements OnInit {
  _Action: IAction;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _branchid: number;
  _userid: number;
  _roleid: number;
  _UserId: number;
  selectedRows: any;
  _productdetails: Stockadjustmentview[];
  _IsProgressSpinner: boolean = true;
  cols: any[];
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  mindate: Date;
  maxdate: Date;
  constructor(private fb: FormBuilder, private _StockadjustmentService: StockadjustmentService, private router: Router, private confirmationService: ConfirmationService,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private exportUtility: ExportUtility, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService) { }


  ngOnInit(): void {
    this._IsProgressSpinner = true;
    this._Action = this._AccessRightsService.getAccessRights(51);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.cols = [
      { field: 'stocktakeno', header: 'Stock Take No' },
      { field: 'stocktakedate', header: 'Stock Take Date' },
      { field: 'cycleno', header: 'Cycle No' },
      { field: 'teamlead', header: 'Team Lead' },
      { field: 'stocktaker1', header: 'Stock Taker 1' },
      { field: 'stocktaker2', header: 'Stock Taker 2' },
      { field: 'status', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' }
    ]
    this.Fetchall();
  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = sessionStorage["userid"];
    this._roleid = sessionStorage["currentRole"];
    //  31.on clicking search button , the data must be filtered and display in the grid
    this._StockadjustmentService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //42.grid should load with updated data, and rows shouldbe in discending order  
      this._productdetails = resultJSON.stockadjustmentviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    //33.on clicking view button, it show the view screen.
    this.router.navigate(['/cStockAdjustment'], { state: { stocktakeid: event.stocktakeid, action: 'view' } });
  }
  edit(event) {
    //36.on clicking update button, it show the update screen.
    // if (event.status == "MSC00045" || event.status == null) {
    this.router.navigate(['/cStockAdjustment'], { state: { stocktakeid: event.stocktakeid, action: 'edit' } });
  }
  // else {
  //   this._CustomExceptionService.handleWarning("Approved Record Should not Update");
  // }

  GoToCreate(event) {
    this.router.navigate(['/cStockAdjustment']);
  }

  exportExcel() {
    let StockAdjustmentList = this.GetStockAdjustmentList();
    this.exportUtility.exportExcel(StockAdjustmentList, 'StockAdjustment', 'xlsx');
  }

  ExportToPdf() {
    let StockAdjustmentList = this.GetStockAdjustmentList();
    this.exportUtility.ExportToPdf(StockAdjustmentList, 'StockAdjustment.pdf');
  }


  Print(event) {
    var stocktakeno = event.stocktakeno;
    this._IsProgressSpinner = true;
    this._StockadjustmentService.Print(stocktakeno, this._branchid);
    this._IsProgressSpinner = false;

  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Stock Adjustment <b>"' + event.stocktakeno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    let data = {
      "stocktakeno": event.stocktakeno
    };
    this._IsProgressSpinner = true;
    this._StockadjustmentService.Cancel(data).subscribe((response: any) => {
      if (response && response.tranStatus && response.tranStatus.result) {
        this._CustomExceptionService.handleWarning(usererrors.Cancel_Success_00);
        this.Fetchall();
      }
      else {
        this._CustomExceptionService.handleWarning(response.tranStatus.lstErrorItem[0].message)
      }

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    this._IsProgressSpinner = false;

  }

  GetStockAdjustmentList() {
    let StockAdjustmentList = [];
    this._productdetails.forEach(element => {
      let stockadjustment: any = {};
      stockadjustment["Stock Take No"] = element.stocktakeno;
      stockadjustment["Stock Take Date"] = this.datepipe.transform(element.stocktakedate, 'dd/MM/yyyy');
      stockadjustment["Cycle No"] = element.cycleno;
      stockadjustment["Team Lead"] = element.teamlead;
      stockadjustment["Stock Taker 1"] = element.stocktaker1;
      stockadjustment["Stock Taker 2"] = element.stocktaker2;
      stockadjustment["Status"] = element.status;
      stockadjustment["Created By"] = element.createdbyname;
      stockadjustment["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      StockAdjustmentList.push(stockadjustment);
    });
    return StockAdjustmentList;
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
}
