<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>City</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" class="p-button p-button-success p-mr-2" [routerLink]="['/cCity']" [disabled]="!_Action._Add"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" (click)="ExportToPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_districtview" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['countryname','statename','districtcode','districtname','districtstatusname']" [rowHover]="true" dataKey="citycode" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 120px;">Action
                                </th>
                                <th pSortableColumn="countryname">Country
                                    <p-sortIcon field="countryname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="statename">Province
                                    <p-sortIcon field="statename"></p-sortIcon>
                                </th>
                                <th pSortableColumn="districtcode">City Code
                                    <p-sortIcon field="districtCode">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="districtname">City Name
                                    <p-sortIcon field="districtName">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="districtstatusname">Status
                                    <p-sortIcon field="districtstatusname">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_District>
                            <tr>

                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" [disabled]="!_Action._View" class="p-button p-mr-2" (click)="view(_District)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update" (click)="edit(_District)"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Delete" class="p-button p-button-danger" (click)="confirm(_District)" [disabled]="_District.districtstatus == 'MSC00002' || !_Action._Delete"></button>
                                </td>
                                <td>
                                    {{_District.countryname}}
                                </td>
                                <td>
                                    {{_District.statename}}
                                </td>
                                <td>
                                    {{_District.districtcode}}
                                </td>
                                <td>
                                    {{_District.districtname}}
                                </td>
                                <td>
                                    {{_District.districtstatusname}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>