import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class ShippinglineService {
  public _Action: IAction;
  public _ShippingLineService = environment.ShippingLineService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient, private _CustomExceptionService: CustomExceptionService) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(shippinglineid: number): Observable<any> {
    const headers = this.header;
    var Query = this._ShippingLineService + '/' + shippinglineid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll() {
    const headers = this.header;
    var Query = this._ShippingLineService + '/FetchAll';
   
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      return data;
    }));
  }

  create(shippingline: object) {
    const headers = this.header;
    //console.log("Create");
    var Query = this._ShippingLineService;
    return this.http.post<{ data: any }>(Query, shippingline, { headers });
  }
 
  edit(shippingline: object) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._ShippingLineService;
   
    return this.http.put<{ data: any }>(Query, shippingline, { headers });
  }
  Cancel(Shippinglineid:number,userid :number)
  {
    const headers = this.header;
    //console.log("Update");
    var Query = this._ShippingLineService + '/Cancel/' + Shippinglineid +'/'+ userid ;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }

}
