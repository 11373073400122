
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../../domain/appconfig';

@Injectable({
  providedIn: 'root'
})
export class OutstandingPayableDashboardService {

  public _OutstandingPayableDashboardService = environment.OutstandingPayableDashboardService;
  config: AppConfig = {
    theme: 'saga-blue',
    dark: false,
    inputStyle: 'outlined',
    ripple: true
  };
  constructor(private http: HttpClient) { }
  private configUpdate = new Subject<AppConfig>();

  configUpdate$ = this.configUpdate.asObservable();

  updateConfig(config: AppConfig) {
    this.config = config;
    this.configUpdate.next(config);
  }

  getConfig() {
    return this.config;
  }

  PageOnload(): Observable<any> {
    var Query = this._OutstandingPayableDashboardService + '/pageload';

    return this.http.get<{ data: any }>(Query);
  }


  OutstandingPayableBranchwise(branchid: number[]): Observable<any> {
  
    var Query = this._OutstandingPayableDashboardService + '/OutstandingPayableBranchwise'

    return this.http.get<{ data: any }>(Query);
  }
}
