<div class="page-container ">
  <div class="inner-page-container">
    <div class="page-loader" *ngIf="_IsProgressSpinner">
      <p-progressSpinner strokeWidth="5"></p-progressSpinner>
    </div>
    <div class="white">
      <div class="page-title">
        <div class="row">
          <div class="col-md-5">
            <h3>User-Screen Configuration </h3>
          </div>
          <div class="col-md-7 text-right">
            <div class="action-btn">
              <button pButton pRipple label="" title="Save" icon="pi pi-save" class="" type="submit"
                (click)="onSave()"></button>
              <button pButton pRipple label="" title="Clear" icon="pi pi-trash" class="" (click)="reset($event)"
                [disabled]="!_Action._Delete" class="p-button-danger"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-container scroll-y">
        <form [formGroup]="_userwisescreenmappingform" (ngSubmit)="onSave()">
          <table class="normal-table">
            <tr>
              <td style="width: 20%;">
                <span class="p-float-label">
                  <p-dropdown [autoDisplayFirst]="false" [options]="_Userrole" formControlName="roleid"
                   [filter]="true" (onChange)="getUsers($event)" optionValue="roleid" optionLabel="rolename">
                  </p-dropdown>
                  <label for="roleid">User Role <span class="hlt-txt">*</span></label>
                </span>
                <span class="text-danger"
                  *ngIf="(_submitted || _userwisescreenmappingform.get('roleid').touched) && _userwisescreenmappingform.get('roleid').errors?.SelectCurrency">
                  Please select user role
                </span>
              </td>
              <td style="width: 20%;">
                <span class="p-float-label">
                  <p-dropdown [autoDisplayFirst]="false" [options]="_Username" formControlName="userid"
                  [filter]="true" (onChange)="getModuleandFunction($event)" optionValue="userid" optionLabel="username">
                  </p-dropdown>
                  <label for="userid">User Name <span class="hlt-txt">*</span></label>
                </span>
                <span class="text-danger"
                  *ngIf="(_submitted || _userwisescreenmappingform.get('userid').touched) && _userwisescreenmappingform.get('userid').errors?.SelectCurrency">
                  Please select user name
                </span>
              </td>
              <td style="width: 20%;">
                <span class="p-float-label">
                    <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_productname" optionValue="productid"
                        [filter]="true" filterBy="searchfilter" [showClear]="true" optionLabel="productname"
                        formControlName="productid" (onChange)="getVariant($event)">
                    </p-dropdown> -->
                    <p-multiSelect [options]="_UserModules" formControlName="moduleid"
                     (onChange)="getModuleandFunction($event)"  optionLabel="functionname" optionValue="functionid"
                        displaySelectedLabel=true>
                    </p-multiSelect>
                    <label for="moduleid">Module Name <span class="hlt-txt">*</span></label>
                </span>
            </td>
              <td style="width: 20%;"></td>
              <td style="width: 20%;"></td>
              <!-- <td style="width: 20%;"></td> -->
            </tr>
          </table>
        </form>

        <div class="card">
          <p-toolbar styleClass="p-mb-4">
            <ng-template pTemplate="left">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search..." />
              </span>
            </ng-template>
          </p-toolbar>
          <p-table #dt [value]="_userscreens" [scrollable]="true" scrollHeight="450px"  [rows]="50" [paginator]="true" [rowsPerPageOptions]="[50,100,150,200]"
             [globalFilterFields]="['modulename','screenname']" [rowHover]="true" dataKey="screenid"
            [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true" (onHeaderCheckboxToggle)="Header($event)">
            <ng-template pTemplate="header" >
              <tr>

                <th>Module</th>
                <th>Screen</th>
                <th style="text-align: center;">Create <br>
                  <p-checkbox [(ngModel)]="_gridAction._Add" (onChange)="CheckHeader('create')" binary="true">
                  </p-checkbox>
                </th>
                <th style="text-align: center;">Update <br>
                  <p-checkbox [(ngModel)]="_gridAction._Update" (onChange)="CheckHeader('update')" binary="true">
                  </p-checkbox>
                </th>
                <th style="text-align: center;">Delete <br>
                  <p-checkbox [(ngModel)]="_gridAction._Delete" (onChange)="CheckHeader('delete')" binary="true">
                  </p-checkbox>
                </th>
                <th style="text-align: center;">View <br>
                  <p-checkbox [(ngModel)]="_gridAction._View" (onChange)="CheckHeader('view')" binary="true">
                  </p-checkbox>
                </th>
                <th style="text-align: center;">Print <br>
                  <p-checkbox [(ngModel)]="_gridAction._Print" (onChange)="CheckHeader('print')" binary="true">
                  </p-checkbox>
                </th>
                <th style="text-align: center;">Approval <br>
                  <p-checkbox [(ngModel)]="_gridAction._Approve" (onChange)="CheckHeader('approval')" binary="true">
                  </p-checkbox>
                </th>
                <th style="text-align: center;">Price History <br>
                  <p-checkbox [(ngModel)]="_gridAction._PriceHistories" (onChange)="CheckHeader('pricehistory')" binary="true">
                  </p-checkbox>
                </th>
                <!--  -->
                <th [hidden]="showseriesheader" style="text-align: center;">Series <br>
                  <p-checkbox [(ngModel)]="_gridAction._Series" (onChange)="CheckHeader('series')" binary="true">
                  </p-checkbox>
                </th>
                <th style="text-align: center;">Profit <br>
                  <p-checkbox [(ngModel)]="_gridAction._Profit"  (onChange)="CheckHeader('profit')" binary="true">
                  </p-checkbox>
                </th>
<!-- Ecommerce -->
                <th style="text-align: center;">Tab Views <br>
                  <p-checkbox [(ngModel)]="_gridAction._TabViews"  (onChange)="CheckHeader('tabviews')" binary="true">
                  </p-checkbox>
                </th> 
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-_userscreen let-i="rowIndex">
              <tr>

                <td>
                  {{_userscreen.modulename}}
                </td>
                <td>
                  {{_userscreen.screenname}}
                </td>
                <td style="text-align:center">
                  <p-checkbox [(ngModel)]="_userscreen.create" (onChange)="onScreenSelect($event,_userscreen)"
                    binary="true">
                  </p-checkbox>
                </td>
                <td style="text-align:center">
                  <p-checkbox [(ngModel)]="_userscreen.update" (onChange)="onScreenSelect($event,_userscreen)"
                    binary="true">
                  </p-checkbox>
                </td>
                <td style="text-align:center">
                  <p-checkbox [(ngModel)]="_userscreen.delete" (onChange)="onScreenSelect($event,_userscreen)"
                    binary="true">
                  </p-checkbox>
                </td>
                <td style="text-align:center">
                  <p-checkbox [(ngModel)]="_userscreen.view" (onChange)="onScreenSelect($event,_userscreen)"
                    binary="true">
                  </p-checkbox>
                </td>
                <td style="text-align:center">
                  <p-checkbox [(ngModel)]="_userscreen.print" (onChange)="onScreenSelect($event,_userscreen)"
                    binary="true">
                  </p-checkbox>
                </td>
                <td style="text-align:center">
                  <p-checkbox [(ngModel)]="_userscreen.approval" (onChange)="onScreenSelect($event,_userscreen)"
                    binary="true">
                  </p-checkbox>
                </td>
                <td style="text-align:center">
                  <p-checkbox [(ngModel)]="_userscreen.pricehistory" [disabled]="_userscreen.screenid !=17" (onChange)="onScreenSelect($event,_userscreen)"
                    binary="true">
                  </p-checkbox>
                </td>
                <td style="text-align:center" [hidden]="showseries">
                  <p-checkbox [(ngModel)]="_userscreen.series" [disabled]="_userscreen.screenid !=21 && _userscreen.screenid !=19 && _userscreen.screenid !=30" (onChange)="onScreenSelect($event,_userscreen)"
                    binary="true">
                  </p-checkbox>
                </td>
                <td style="text-align:center">
                  <p-checkbox [(ngModel)]="_userscreen.profit" [disabled]="_userscreen.screenid !=55 && _userscreen.screenid !=56 " (onChange)="onScreenSelect($event,_userscreen)"
                    binary="true">
                  </p-checkbox>
                </td>
				<!-- Ecommerce -->
                <td style="text-align:center">
                  <p-checkbox [(ngModel)]="_userscreen.tabviews" [disabled]="_userscreen.screenid !=17  " (onChange)="onScreenSelect($event,_userscreen)"
                    binary="true">
                  </p-checkbox>
                </td>	 
              </tr>
            </ng-template>
          </p-table>
        </div>
        <!-- <table>
          <tr>
            <td>
              <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
            </td>
          </tr>
        </table> -->
      </div>
    </div>
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
  </div>
</div>
<p-toast position="bottom-right"></p-toast>