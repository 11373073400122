import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ReportsRoutingModule } from './reports-routing.module';
import { PurchaseordersummaryComponent } from './purchase/purchaseordersummary/purchaseordersummary.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { ToolbarModule } from 'primeng/toolbar';
import { RatingModule } from 'primeng/rating';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChipsModule } from 'primeng/chips';
import { FieldsetModule, } from 'primeng/fieldset';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { HttpClientModule } from '@angular/common/http';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PurchasepricerevisionComponent } from './purchase/purchasepricerevision/purchasepricerevision.component';
import { PurchasereturnComponent } from './purchase/purchasereturn/purchasereturn.component';
import { StocktransferComponent } from './Stock/stocktransfer/stocktransfer.component';
import { GrnreportComponent } from './Stock/grnreport/grnreport.component';
import { RptdamagedproductComponent } from './Stock/rptdamagedproduct/rptdamagedproduct.component';
import { RptproductdeliveryComponent } from './Stock/rptproductdelivery/rptproductdelivery.component';
import { RptsalesquotationComponent } from './Sales/rptsalesquotation/rptsalesquotation.component';
import { RptsalescommissionComponent } from './Sales/rptsalescommission/rptsalescommission.component';
import { RptsalesreturnComponent } from './Sales/rptsalesreturn/rptsalesreturn.component';
import { RptaccountledgerComponent } from './Accounting/rptaccountledger/rptaccountledger.component';
import { RptbalancesheetComponent } from './Accounting/rptbalancesheet/rptbalancesheet.component';
import { RptprofitlossComponent } from './Accounting/rptprofitloss/rptprofitloss.component';
import { RptrevenueexpenseComponent } from './Accounting/rptrevenueexpense/rptrevenueexpense.component';
import { RptoutstandingComponent } from './Accounting/rptoutstanding/rptoutstanding.component';
import { RptpayablereceivableComponent } from './Accounting/rptpayablereceivable/rptpayablereceivable.component';
import { RptpaymentComponent } from './Accounting/rptpayment/rptpayment.component';
import { RptreconcilationunreconcilationComponent } from './Accounting/rptreconcilationunreconcilation/rptreconcilationunreconcilation.component';
import { RptvatinputoutputComponent } from './Accounting/rptvatinputoutput/rptvatinputoutput.component';
import { RptbranchwisestockComponent } from './Stock/rptbranchwisestock/rptbranchwisestock.component';
import { RptageingComponent } from './Accounting/rptageing/rptageing.component';
import { RptposummaryComponent } from './purchase/rptposummary/rptposummary.component';
import { RptpurchaseinwardComponent } from './purchase/rptpurchaseinward/rptpurchaseinward.component';
import { RptrewardpointstransactionComponent } from './Sales/rptrewardpointstransaction/rptrewardpointstransaction.component';
import { RptsalesComponent } from './Sales/rptsales/rptsales.component';
import { RpttrialbalancemonthComponent } from './Accounting/rpttrialbalancemonth/rpttrialbalancemonth.component';
import { RptTellercashComponent } from './Accounting/rpt-tellercash/rpt-tellercash.component';
import { StockmovementComponent } from './StockMovement/stockmovement/stockmovement.component';
import { RptsalessummaryreportComponent } from './Sales/rptsalessummaryreport/rptsalessummaryreport.component';
import { DiscountreportComponent } from './Sales/discountreport/discountreport.component';
import { VatincludeexcludereportComponent } from './Sales/vatincludeexcludereport/vatincludeexcludereport.component';
import { ProductbarcodeprintComponent } from './productbarcodeprint/productbarcodeprint.component';
import { RptLockedquantitydetailreportComponent } from './Stock/rptLockedquantitydetailreport/rpt-lockedquantitydetailreport/rpt-lockedquantitydetailreport.component';
import { RptlockedquantitysummaryreportComponent } from './Stock/rptLockedquantitysummaryreport/rptlockedquantitysummaryreport/rptlockedquantitysummaryreport.component';
import { PurchaseorderdetailreportComponent } from './purchase/purchaseorderdetailreport/purchaseorderdetailreport.component';
import { RptstocksummaryComponent } from './Stock/rptstocksummary/rptstocksummary.component';
import { RptpurchaseinwardsummaryComponent } from './purchase/rptpurchaseinwardsummary/rptpurchaseinwardsummary.component';
import { RptpurchaseordersummaryComponent } from './purchase/rptpurchaseordersummary/rptpurchaseordersummary.component';
import { RptsalessummaryComponent } from './Sales/rptsalessummary/rptsalessummary.component';
import { StockvaluereportComponent } from './Stock/stockvaluereport/stockvaluereport.component';
import { RptsalesquotationsummaryComponent } from './Sales/rptsalesquotationsummary/rptsalesquotationsummary.component';
import { RptsalesprofitandlossComponent } from './Sales/rptsalesprofitandloss/rptsalesprofitandloss.component';
import { StockmissingComponent } from './Stock/StockMissing/stockmissing/stockmissing.component';
import { RptstockcountingComponent } from './Stock/rptstockcounting/rptstockcounting.component';
import { RptstockmovementsummaryComponent } from './Stock/rptstockmovementsummary/rptstockmovementsummary.component';
import { StockvaluationComponent } from './Stock/StockValuation/stockvaluation/stockvaluation.component';
import { RptconsignmentstockComponent } from './Stock/rptconsignmentstock/rptconsignmentstock.component';
import { RptproductpricehistoryComponent } from './rptproductpricehistory/rptproductpricehistory.component';
import { ProductpricereportComponent } from './productpricereport/productpricereport.component';
import { RptExpenseComponent } from './Accounting/rpt-expense/rpt-expense.component';
import { UserdiscountreportComponent } from './Sales/userdiscountreport/userdiscountreport.component';
import { MonthwisestockreportComponent } from './Stock/monthwisestockreport/monthwisestockreport.component';
import { RptlockedquantityComponent } from './Stock/rptlockedquantity/rptlockedquantity.component';
import { RptdeliveredNotdeliveredComponent } from './Stock/rptdelivered-notdelivered/rptdelivered-notdelivered.component';
import { RptCashbookComponent } from './Accounting/rpt-cashbook/rpt-cashbook.component';
import { RptcriticalstockComponent } from './Stock/rptcriticalstock/rptcriticalstock/rptcriticalstock.component';
import { ProductbarcodestickerprintComponent } from './productbarcodestickerprint/productbarcodestickerprint/productbarcodestickerprint.component';
import { RptpurchaseinvoiceComponent } from './purchase/rptpurchaseinvoice/rptpurchaseinvoice.component';
import { RptAccountsmonitorComponent } from './Accounting/rpt-accountsmonitor/rpt-accountsmonitor.component';
import { RptOutstandingcreditnoteComponent } from './Accounting/rpt-outstandingcreditnote/rpt-outstandingcreditnote.component';
import { RptLotexpiryageingComponent } from './Stock/rpt-lotexpiryageing/rpt-lotexpiryageing.component';
import { RptLotWiseStockComponent } from './Stock/rpt-lot-wise-stock/rpt-lot-wise-stock.component';
import { RptAssetValueReportComponent } from './Accounting/rpt-asset-value-report/rpt-asset-value-report.component';
import { RptStockadjsutmentreportComponent } from './Stock/rpt-stockadjsutmentreport/rpt-stockadjsutmentreport.component';
import { RptProductMarkupLevelComponent } from './rpt-product-markup-level/rpt-product-markup-level.component';
import { RptPurchaseVsSalesComponent } from './rpt-purchase-vs-sales/rpt-purchase-vs-sales.component';
import { MaterialConsumptionReportComponent } from './Stock/material-consumption-report/material-consumption-report.component';
import { RptsupplierwiseclosingstockComponent } from './Stock/rptsupplierwiseclosingstock/rptsupplierwiseclosingstock.component';
import { PendingApprovalStatusComponent } from './Stock/pending-approval-status/pending-approval-status.component';
import { RptClosingBalanceVerificationComponent } from './Accounting/rpt-closing-balance-verification/rpt-closing-balance-verification.component';
import { AccountsGroupWiseReportComponent } from './Accounting/accounts-group-wise-report/accounts-group-wise-report.component';
import { RptReceivablesPayablesComponent } from './Accounting/rpt-receivables-payables/rpt-receivables-payables.component';
import { RptAgeingDetailReportComponent } from './Accounting/rpt-ageing-detail-report/rpt-ageing-detail-report.component';
import { RptproductpricechangehistoryComponent } from './rptproductpricechangehistory/rptproductpricechangehistory.component';
import { RptSupplierDueComponent } from './Accounting/rpt-supplier-due/rpt-supplier-due/rpt-supplier-due.component';
import { RptbrandwisestockComponent } from './Stock/rptbrandwisestock/rptbrandwisestock.component';
import { RptInvoicedelaytimeComponent } from './Stock/rpt-invoicedelaytime/rpt-invoicedelaytime.component';
import { RptPendingDeliveryComponent } from './Stock/rpt-pending-delivery/rpt-pending-delivery.component';
import { RptMinusStockComponent } from './Stock/rpt-minusstock/rpt-minusstock.component';
import { OutstandingpurchaseorderreportComponent } from './purchase/outstandingpurchaseorderreport/outstandingpurchaseorderreport.component';
import { LaybuyreportComponent } from './Sales/laybuyreport/laybuyreport.component';
import { PurchasetrackingreportComponent } from './purchase/purchasetrackingreport/purchasetrackingreport.component';
@NgModule({
	declarations: [
		PurchaseordersummaryComponent,
		RptageingComponent,
		RptposummaryComponent,
		PurchasepricerevisionComponent,
		PurchasereturnComponent,
		StocktransferComponent,
		GrnreportComponent,
		RptdamagedproductComponent,
		RptproductdeliveryComponent,
		RptsalesquotationComponent,
		RptsalescommissionComponent,
		RptsalesreturnComponent,
		RptaccountledgerComponent,
		RptbalancesheetComponent,
		RptprofitlossComponent,
		RptrevenueexpenseComponent,
		RptoutstandingComponent,
		RptpayablereceivableComponent,
		RptpaymentComponent,
		RptreconcilationunreconcilationComponent,
		RptvatinputoutputComponent,
		RptsalesComponent,
		RptbranchwisestockComponent,
		RptpurchaseinwardComponent,
		RptrewardpointstransactionComponent,
		RpttrialbalancemonthComponent,
		RptTellercashComponent,
		StockmovementComponent,
		RptsalessummaryreportComponent,
		DiscountreportComponent,
		VatincludeexcludereportComponent,
		ProductbarcodeprintComponent,
		RptLockedquantitydetailreportComponent,
		RptlockedquantitysummaryreportComponent,
		PurchaseorderdetailreportComponent,
		RptstocksummaryComponent,
		RptpurchaseinwardsummaryComponent,
		RptsalessummaryComponent,
		RptpurchaseordersummaryComponent,
		StockvaluereportComponent,
		RptsalesquotationsummaryComponent,
		RptsalesprofitandlossComponent,
		StockmissingComponent,
		RptstockcountingComponent,
		RptstockmovementsummaryComponent,
		StockvaluationComponent,
		RptconsignmentstockComponent,
		RptproductpricehistoryComponent,
		ProductpricereportComponent,
		RptExpenseComponent,
		UserdiscountreportComponent,
		MonthwisestockreportComponent,
		RptlockedquantityComponent,
		RptdeliveredNotdeliveredComponent,
		RptCashbookComponent,
		RptcriticalstockComponent,
		ProductbarcodestickerprintComponent,
		RptpurchaseinvoiceComponent,
		RptAccountsmonitorComponent,
		RptOutstandingcreditnoteComponent,
		RptLotexpiryageingComponent,
		RptLotWiseStockComponent,
		RptAssetValueReportComponent,
		RptStockadjsutmentreportComponent,
		RptProductMarkupLevelComponent,
		RptPurchaseVsSalesComponent,
		MaterialConsumptionReportComponent,
		RptsupplierwiseclosingstockComponent,
		PendingApprovalStatusComponent,
		RptClosingBalanceVerificationComponent,
		AccountsGroupWiseReportComponent,
		RptReceivablesPayablesComponent,
		RptAgeingDetailReportComponent,
		RptproductpricechangehistoryComponent,
		RptSupplierDueComponent,
		RptbrandwisestockComponent,
		RptInvoicedelaytimeComponent,
		RptPendingDeliveryComponent,
		RptMinusStockComponent,
		OutstandingpurchaseorderreportComponent,
		LaybuyreportComponent,
        PurchasetrackingreportComponent
	],
	imports: [
		ReportsRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		TableModule,
		CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
		InputTextModule,
		ProgressBarModule,
		FileUploadModule,
		ToolbarModule,
		RatingModule,
		FormsModule,
		RadioButtonModule,
		InputNumberModule,
		ConfirmDialogModule,
		InputTextareaModule,
		ChipsModule,
		FieldsetModule,
		AutoCompleteModule,
		OverlayPanelModule,
		CheckboxModule,
		PasswordModule,
		HttpClientModule,
		TabViewModule,
		ProgressSpinnerModule
	],
	providers: [DecimalPipe],
})
export class ReportsModule { }