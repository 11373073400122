<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product Category</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" (click)="GoToCreate($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_categoryviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="[ 'producttypename','categoryname','eccategoryname','branchname','categorystatusname']" [rowHover]="true"
                            dataKey="productcategoryid" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="Action" style="width: 120px;text-align: center;">Action</th>
                                    <th pSortableColumn="producttypename">Product Type
                                        <p-sortIcon field="producttypename">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="categoryname">Category Name
                                        <p-sortIcon field="categoryname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="eccategoryname">EC-Category Name
                                        <p-sortIcon field="eccategoryname">
                                        </p-sortIcon>
                                    </th>
                                    <!-- <th pSortableColumn="branchname">Branch Name<p-sortIcon field="branchname">
                                        </p-sortIcon>
                                    </th> -->
                                    <th pSortableColumn="categorystatusname">Status
                                        <p-sortIcon field="categorystatusname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdby">Created By
                                        <p-sortIcon field="createdby">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdon">Created on
                                        <p-sortIcon field="createdon">
                                        </p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_categoryview>
                                <tr>
                                    <td class="text-center">
                                        <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2" [disabled]="!_Action._View" (click)="view(_categoryview)"></button>
                                        <button pButton pRipple icon="las la-pen" title="Edit" [disabled]="!_Action._Update" class="p-button p-button-success p-mr-2" (click)="edit(_categoryview)"></button>
                                        <button pButton pRipple icon="pi pi-times" title="Delete" class="p-button p-button-danger" (click)="confirm(_categoryview)" [disabled]="_categoryview.categorystatuscode == 'MSC00002' || !_Action._Delete"></button>
                                    </td>
                                    <td>
                                        {{_categoryview.producttypename}}
                                    </td>
                                    <td>
                                        {{_categoryview.categoryname}}
                                    </td>
                                    <td>
                                        {{_categoryview.eccategoryname}}
                                    </td>
                                    <!-- <td>
                                        {{_categoryview.branchname}}
                                    </td> -->
                                    <td>
                                        {{_categoryview.categorystatusname}}
                                    </td>
                                    <td>
                                        {{_categoryview.createdby}}
                                    </td>
                                    <td>
                                        {{_categoryview.createdon |date:'dd/MM/yyyy'}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>