<p-toast></p-toast>
<div class="page-container">
    <div class="inner-page-container">
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-6">
                        <h3>CMS Pages</h3>
                    </div>
                    <div class="col-md-6 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="button" label="" class="p-button p-button-success p-mr-2" title="Add" [disabled]="!_Action._Add"
                            (click)="GoToCreate($event)"  icon="las la-plus"></button>
                          </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="las la-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                        <ng-template pTemplate="right">
                            <!-- <button type="button" pButton pRipple icon="las la-file-csv" title="Export CSV" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-filter" title="Filter" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>
                    </p-toolbar>

                    <p-table #dt [value]="cmsListGrid" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['pagename','pageurlname','cmsid']"
                        [rowHover]="true" dataKey="cmsid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>                                
                                <th style="width:100px;" class="text-center">Action</th>
                                <th style="width:250px;" pSortableColumn="pagename">Page Name <p-sortIcon field="pagename"></p-sortIcon>
                                </th>
                                <th pSortableColumn="pageurlname">Page URL <p-sortIcon field="pageurlname"></p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="pageContent">Page Content <p-sortIcon field="template"></p-sortIcon>
                                </th> -->
                                <!-- <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon>
                                </th> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_cmsListGrid>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" [disabled]="!_Action._View"  (click)="view(_cmsListGrid)" class="p-button p-mr-2"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" (click)="edit(_cmsListGrid)" [disabled]="!_Action._Update" class="p-button-success p-mr-2"></button>
                                    <!-- <button pButton pRipple icon="las la-trash" title="Delete" class="p-button-danger p-mr-2"></button> -->
                                </td>
                                <td>
                                    {{_cmsListGrid.pagename}}
                                </td>
                                <td>
                                    {{_cmsListGrid.pageurlname}}
                                </td>
                                <!-- <td>
                                    {{_cmsListGrid.template}}
                                </td> -->
                                <!-- <td>
                                    {{_cmsListGrid.recordstatus}}
                                </td> -->
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>