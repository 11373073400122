<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>

        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Stock Delivery</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" [disabled]="isDisabledsave" type="submit" (click)="onSave()" title="Save" icon="pi pi-save" class=""></button>
                            <button pButton pRipple label="" [disabled]="isDisablereset" type="button" (click)="reset($event)" title="Clear" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRiple label="" type="submit" icon="pi pi-search" title="Back to Search" (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <form [formGroup]="_stockdeliveryform">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="deliveryno">
                                    <label for="id">Delivery No</label>
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_stockdeliveryform">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" name="deliverydate" formControlName="deliverydate"
                                    [minDate]="mindate" [maxDate]="maxdate"
                                        dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="id">Delivery Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockdeliveryform.get('deliverydate').touched || _submitted) && _stockdeliveryform.get('deliverydate').errors?.SelectDeliveryDate">
                                    Please Select Delivery Date
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_stockdeliveryform">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_invoicetype"
                                        optionValue="metasubcode" (onChange)="onchangeinvoicetype()"
                                        optionLabel="metasubdescription"
                                        formControlName="invoicetype">
                                        <!-- (onChange)="onchangeinvoicetype1()"  -->
                                    </p-dropdown>
                                    <label for="invoicetype">Invoice Type <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockdeliveryform.get('invoicetype').touched || _submitted) && _stockdeliveryform.get('invoicetype').errors?.SelectInvoicetype">
                                    Please Select Invoice Type
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">

                            <span class="p-float-label">
                                <input type="text" name="productbarcode" autocomplete="off" [(ngModel)]="productbarcode"
                                    pInputText [disabled]=isdisabledbarcode (keyup.enter)="FetchProductUsingBarcode()">
                                <label for="productbarcode">Bar code</label>
                            </span>

                        </td>
                        <td style="width: 20%;">
                        </td>
                    </tr>
                    <tr>
                        <td *ngIf="_stockdeliveryform.get('invoicetype').value == 'MSC00211' || _stockdeliveryform.get('invoicetype').value == 'MSC00224' ">
                            <span class="p-float-label">
                                <p-multiSelect [filter]="false" [options]="_branchlist" optionLabel="branchname"
                                    optionValue="branchid" [(ngModel)]="branchids" (onChange)="onchangeinvoicetype()"
                                    [ngModelOptions]="{standalone: true}" displaySelectedLabel="true" [filter]="true"
                                    filterBy="branchname" [virtualScroll]="true" itemSize="30">
                                </p-multiSelect>
                                <label>Branch</label>
                            </span>
                        </td>
                        <td *ngIf="_stockdeliveryform.get('invoicetype').value == 'MSC00210'">
                            <span class="p-float-label">
                                <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_invoicetype"
                                        optionValue="metasubcode" (onChange)="onchangeinvoicetype()"
                                        (onChange)="onchangeinvoicetype1()" optionLabel="metasubdescription"
                                        formControlName="invoicetype">
                                    </p-dropdown> -->
                                <p-dropdown [filter]="false" [options]="_customerlist" optionLabel="customername"
                                    optionValue="customerid" [(ngModel)]="customerids" (onChange)="onchangeinvoicetype()"
                                    [ngModelOptions]="{standalone: true}" displaySelectedLabel="true" [filter]="true"
                                    filterBy="customername" [virtualScroll]="true" itemSize="30"
                                    [autoDisplayFirst]="false">
                                </p-dropdown>
                                <label>Customer Name</label>
                            </span>
                        </td>
                        <td>
                            <form [formGroup]="_stockform">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_invoiceno" [disabled]="isDisabledstockrequest"
                                        optionLabel="tempSalesinvoiceno" (onChange)="onchangeinvoiceno($event)"
                                        optionValue="salesinvoiceno" name="_evidenceno" [virtualScroll]="true"
                                        itemSize="30" displaySelectedLabel="true" [filter]="true"
                                        [(ngModel)]="_selectedevidencenoIndex" formControlName="salesinvoiceid">
                                    </p-multiSelect>
                                    <label for="id">Invoice/Stock Request No. <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- <span class="text-danger" *ngIf="(_submitted && !_selectedevidencenoIndex.length)">
                                    Please Select Invoice/Stock Request No
                                </span> -->
                            </form>
                        </td>

                        <!-- <td style="width: 20%;">
                        <form [formGroup]="_stockdeliverydetailform">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_customernameorbranch"
                                    optionValue="customerid" optionLabel="customername"
                                    formControlName="customerorbranchid">
                                </p-dropdown>
                                <label for="id">Customer/Branch Name<span class="hlt-txt">*</span></label>
                            </span>
                            <span class="text-danger"
                                *ngIf="(_stockdeliverydetailform.get('evidenceno').touched || _submitted) && _stockdeliverydetailform.get('evidenceno').errors?.SelectInvoiceno">
                                Please Select Customer/Branch Name
                            </span>
                        </form>
                    </td> -->

                        <td>
                            <form [formGroup]="_stockdeliveryform">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_deliveryto"
                                        optionValue="metasubcode" optionLabel="metasubdescription"
                                        [disabled]="isdisableddeliveryto" formControlName="deliveryto">
                                    </p-dropdown>
                                    <label for="deliveryto">Delivery To <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockdeliveryform.get('deliveryto').touched || _submitted) && _stockdeliveryform.get('deliveryto').errors?.SelectDeliveryto">
                                    Please Select Delivery To
                                </span>
                            </form>
                        </td>
                        <td>
                            <form [formGroup]="_stockdeliveryform">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_deliverytype"
                                        (onChange)="onchangedeliverytype($event)" optionValue="metasubcode"
                                        optionLabel="metasubdescription" formControlName="deliverytype">
                                    </p-dropdown>
                                    <label for="deliverytype">Delivery Type <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockdeliveryform.get('deliverytype').touched || _submitted) && _stockdeliveryform.get('deliverytype').errors?.SelectDeliverytype">
                                    Please Select Delivery Type
                                </span>
                            </form>
                        </td>
                        <td>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <form [formGroup]="_stockdeliveryform">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [disabled]="isDisabledvehicle"
                                        [filter]="true" filterBy="vehicleno" [showClear]="true" [options]="_vehicleno"
                                        optionValue="vehicleid" optionLabel="vehicleno" formControlName="vehicleid"
                                        (onChange)="ChangeVehicle($event)">
                                    </p-dropdown>
                                    <label for="id">Vehicle no</label>
                                </span>
                            </form>

                        </td>
                        <td>
                            <form [formGroup]="_stockdeliveryform">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [disabled]="isDisableddriver" [filter]="true"
                                        filterBy="employeename" [showClear]="true" [options]="_drivername"
                                        optionValue="employeeid" optionLabel="employeename"
                                        formControlName="drivername">
                                    </p-dropdown>
                                    <label for="id">Driver Name</label>
                                </span>
                            </form>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <!-- <tr>
                    <td>
                        <div class="p-formgroup-inline">
                            <div class="p-field-checkbox" *ngFor="let SD of _SalesDiscountLimit">
                                <p-checkbox name="ismultidelivery" binary="true" formControlName="ismultidelivery"
                                    label={{SD.metasubdescription}} value={{SD.metasubcode}}>
                                </p-checkbox>
                            </div>
                        </div>
                    </td>
                </tr> -->
                </table>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_productdetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [rowHover]="true" [globalFilterFields]="['customerorbranchname','productname','variantdescription','evidenceno','evidencedate','uomname','isDisabledotherdeliveryrequired','otherbranchstock','otherbranchid','isdelivered','totalinvoicequnatity','invoicequantity','currentstock','deliveryquantity','balancequantity']"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 50px;">
                                    <!-- <p-tableHeaderCheckbox [disabled]="_action == 'view' || _action == 'edit'" [value]="_productdetails" (click)="SelectAll($event)" ></p-tableHeaderCheckbox> -->
                                    <p-tableHeaderCheckbox [disabled]="_action == 'view' || _action == 'edit'" (click)="SelectAll($event)"></p-tableHeaderCheckbox>
                                </th>
                                <th>Customer/Branch</th>
                                <th>Product Name</th>
                                <th>Variant Name</th>
                                <th>Invoice No.</th>
                                <th>Date</th>
                                <th>Invoice Quantity</th>
                                <th>Remaining Quantity</th>
                                <th>Current Stock</th>
                                <th> Previous Delivery Quantity</th>
                                <th>Delivery Quantity</th>
                                <th class="text-center">S.No</th>
                                <th>Balance Quantity</th>
                                <th>UOM</th>
                                <!-- <th>Is Delivered </th> -->
                                <!-- <th>Other Branch</th>
                                <th>Other Branch Stock</th>
                                <th>Other Branch Delivered required </th> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_productdetail let-ri="rowIndex">
                            <tr>

                                <td style="text-align: center;width: 50px;">
                                    <p-tableCheckbox [disabled]="isDisabledselectall" [value]="_productdetail">
                                    </p-tableCheckbox>
                                </td>
                                <td>
                                    {{_productdetail.customerorbranchname}}
                                </td>
                                <td>
                                    {{_productdetail.productname}}
                                </td>
                                <td>
                                    {{_productdetail.variantdescription}}
                                </td>
                                <td>
                                    {{_productdetail.evidenceno}}
                                </td>
                                <td style="text-align: left">
                                    {{_productdetail.evidencedate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_productdetail.totalinvoicequnatity}}
                                </td>
                                <td>
                                    {{_productdetail.invoicequantity}}
                                </td>
                                <td>
                                    {{_productdetail.currentstock}}
                                </td>
                                <td>
                                    {{_productdetail.previousdeliveryquantity}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" (onBlur)="onchangebalance(_productdetail,$event)" *ngIf="_productdetail.uomcode != 'MSC00039'&& _action != 'view' " [disabled]="_productdetail.isDisabledisdeliveryQuantity " (onInput)="onchangebalance(_productdetail,$event)"
                                                (Keydown.enter)="onchangebalance(_productdetail,$event)" (keypress)="keypress.kpNumber($event)" (focusout)="onchangebalance(_productdetail,$event)" maxlength="13" [(ngModel)]="_productdetail.deliveryquantity"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                            </p-inputNumber>
                                            <p-inputNumber autocomplete="off" (onBlur)="onchangebalance(_productdetail,$event)" *ngIf="_productdetail.uomcode == 'MSC00039'&& _action != 'view' " [disabled]="_productdetail.isDisabledisdeliveryQuantity " (onInput)="onchangebalance(_productdetail,$event)"
                                                (Keydown.enter)="onchangebalance(_productdetail,$event)" (keypress)="keypress.kpNumber($event)" (focusout)="onchangebalance(_productdetail,$event)" maxlength="13" [(ngModel)]="_productdetail.deliveryquantity"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                            </p-inputNumber>
                                            <div *ngIf="_action == 'view'">
                                                {{_productdetail.deliveryquantity}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_productdetail.deliveryquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td style="text-align: center;width: 50px;">
                                    <button pButton pRipple label="" type="button" [disabled]="isDisabledQuicksave" (click)="showBasicDialog(_productdetail)" title="Add" icon="las la-plus" class="p-button p-mr-2"></button>

                                </td>
                                <td>
                                    {{_productdetail.balancequantity}}
                                </td>
                                <td>
                                    {{_productdetail.uomname}}
                                </td>
                                <!-- <td style="text-align: center">
                                    <p-checkbox [disabled]="isDisabledisdelivered" [(ngModel)]="_productdetail.isdelivered" binary="true">
                                    </p-checkbox>
                                </td> -->
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_branch" [disabled]="_productdetail.isDisabledotherdeliveryrequired" optionLabel="branchname" optionValue="branchid" [filter]="true" [showClear]="true" filterBy="branchname" *ngIf="_action != 'view'"
                                                [(ngModel)]="_productdetail.otherbranchid" (onChange)="GetotherBranchStock(_productdetail,$event)">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [disabled]="true" [autoDisplayFirst]="false" [options]="_branch" optionLabel="branchname" optionValue="branchid" [(ngModel)]="_productdetail.otherbranchid">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_productdetail.otherbranchstock}}
                                </td>
                                <td style="text-align: center">
                                    <p-checkbox [disabled]="_productdetail.isDisabledotherdeliveryrequired" [(ngModel)]="_productdetail.otherbranchdeliveredrequired" binary="true">
                                    </p-checkbox>
                                </td> -->
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <p-dialog header="Product Details" [(visible)]="ManageDialog" [style]="{width: '600px',height:'400px'}" [modal]="true" [maximizable]="false">
                <ng-template pTemplate="content">
                    <form [formGroup]="_stockdeliverydetailform">
                        <table class="normal-table">
                            <tr>
                                <td>
                                    <span class="p-float-label">
                                        <textarea rows="12" name="serialnumber" pInputTextarea
                                            formControlName="serialnumber"></textarea>
                                        <label for="serialnumber">Serial Number </label>
                                    </span>
                                    <!-- <span class="text-danger"
                                    *ngIf="(_customersubmitted || _customerform.get('serialnumber').touched) && _customerform.get('serialnumber').errors?.AddressRequired">
                                    Please Enter Address
                                </span> -->
                                </td>
                            </tr>
                        </table>
                    </form>
                </ng-template>

                <ng-template pTemplate="footer">
                    <button pButton pRipple label="Save" (click)="Save()" icon="las la-check" class="p-button-text"></button>
                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="Clear()" class="p-button-text"></button>
                </ng-template>

            </p-dialog>

            <table>
                <td>
                    <!-- Debug -->
                    <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                </td>
            </table>
            <p-toast position="bottom-right"></p-toast>
        </div>
        <p-dialog #warning [style]="{width: '500px'}" header="Confirm" [(visible)]="Stockmodel.showdialog" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
            <ng-template pTemplate="content">
                <label [innerHTML]="Stockmodel.message"></label>
            </ng-template>
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="las la-check" label="Yes" autofocus (click)="Yes()"></button>
                <button type="button" pButton icon="pi pi-times" label="No" autofocus (click)="Cancel()"></button>
            </ng-template>
        </p-dialog>
        <p-confirmDialog [style]="{width: '750px'}"></p-confirmDialog>
        <p-dialog #warning [style]="{width: '500px'}" header="Confirm" [(visible)]="Stockmodel1.showdialog" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
            <ng-template pTemplate="content">
                <label [innerHTML]="Stockmodel1.message"></label>
            </ng-template>
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="las la-check" label="Yes" autofocus (click)="Yes1()"></button>
                <button type="button" pButton icon="pi pi-times" label="No" autofocus (click)="Cancel1()"></button>
            </ng-template>
        </p-dialog>
        <p-confirmDialog [style]="{width: '750px'}"></p-confirmDialog>
    </div>
</div>