<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Reconcilation / UnReconcilation Report</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_rptreconcilationunconcilationform">
                        <table class="normal-table">
                            <tr>

                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="fromdate" dateFormat="dd/mm/yy"
                                            [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2030"
                                            [minDate]="mindate" [maxDate]="maxdate">
                                        </p-calendar>
                                        <label for="fromdate">From Date</label>
                                    </span>

                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="todate" dateFormat="dd/mm/yy"
                                            [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2030"
                                            [minDate]="mindate" [maxDate]="maxdate">
                                        </p-calendar>
                                        <label for="todate">To Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_branchlist"
                                            optionValue="branchid" optionLabel="branchname" formControlName="branchid"
                                            [virtualScroll]="true" itemSize="30" (onChange)="onchangeBranch()"
                                            [showClear]="true" [filter]="true" filterBy="branchname">
                                            <ng-template let-account pTemplate="item">
                                                <div
                                                    [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                    {{account.branchname}}</div>
                                            </ng-template>
                                        </p-dropdown>
                                        <label for="branchname">Branch Name</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">

                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_typelist" optionValue="value"
                                            optionLabel="type" formControlName="partytype"
                                            (onChange)="onchangepartytype($event)">
                                        </p-dropdown>
                                        <label for="partytypename">Party Type <span class="hlt-txt">*</span></label>
                                    </span>


                                </td>

                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_accountnamelist"
                                            optionValue="ledgerid" optionLabel="name" [virtualScroll]="true"
                                            itemSize="30" formControlName="customer_supplierid" [filter]="true"
                                            filterBy="customer_suppliername">
                                        </p-dropdown>
                                        <label for="customer_suppliername">Party Name <span class="hlt-txt">*</span>
                                    </label>
                                    </span>
                                </td>

                            </tr>
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_currencylist"
                                            optionValue="currencyid" optionLabel="currencyname"
                                            formControlName="currencyid" [filter]="true" filterBy="currencyname">
                                        </p-dropdown>
                                        <label for="currencyname">Currency</label>
                                    </span>
                                </td>
                                <!-- <td style="width: 20%;">
                                <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_typelist"
                                    optionValue="value" optionLabel="type" formControlName="type"
                                [filter]="true" filterBy="type">
                                </p-dropdown>
                                    <label for="type">Type</label>
                                </span>
                            </td> -->
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_conciletypelist"
                                            optionValue="value" optionLabel="type" formControlName="conciletype">
                                        </p-dropdown>
                                        <label for="_conciletypelist">Concile Type <span
                                                class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">

                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_Paymenttypelist"
                                            optionValue="value" optionLabel="type" formControlName="Paymenttype">
                                        </p-dropdown>
                                        <label for="Paymenttype">Transaction Type <span class="hlt-txt"></span></label>
                                    </span>


                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div class="output-container">
                    <iframe #iframe class="scroll-height" width="100%" [src]="_src"></iframe>
                </div>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>
        </div>
    </div>

</div>
<p-toast position="bottom-right"></p-toast>