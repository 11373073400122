import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { IAction } from '../security/IActions';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class StocktransferorderService {
  public _Action: IAction;
  public _StockTransferOrderService = environment.StockTransferOrderService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(stocktransferid: number, branchid: number): Observable<any> {
    const headers = this.header;
    var Query = this._StockTransferOrderService + '/' + stocktransferid + '/' + branchid;
    console.log(Query);
    return this.http.get<{ data: any }>(Query, { headers });
  }

  getProductCategories(producttype: object) {
    const headers = this.header;
    var Query = this._StockTransferOrderService + '/getProductCategories';
    console.log(Query);
    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }

  getProducts(productcategory: object) {
    const headers = this.header;
    var Query = this._StockTransferOrderService + '/getProducts';
    console.log(Query);
    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }


  getProductVariants(productid: number, variantid: number, branchid: number, currentbranchid: number) {
    const headers = this.header;
    var Query = this._StockTransferOrderService + '/getProductVariants' + '/' + productid + '/' + variantid + '/' + branchid + '/' + currentbranchid;
    console.log(Query);
    return this.http.get<{ data: any }>(Query, { headers });
  }


  create(stocktransferorder: object) {
    const headers = this.header;
    console.log("Create");
    var Query = this._StockTransferOrderService;
    console.log(Query);
    return this.http.post<{ data: any }>(Query, stocktransferorder, { headers });
  }

  FetchAll(branchid: number, userid: number, roleid: number, fromdate: string, todate: string) {
    const headers = this.header;
    var Query = this._StockTransferOrderService + '/FetchAll' + '/' + branchid + '/' + userid + '/' + roleid + '/' + fromdate + '/' + todate;
    console.log(Query);
    return this.http.get<{ data: any }>(Query, { headers });

  }
  edit(stocktransferorder: object) {
    const headers = this.header;
    console.log("Update");
    var Query = this._StockTransferOrderService;
    console.log(Query);
    return this.http.put<{ data: any }>(Query, stocktransferorder, { headers });
  }
  SendToApproval(workflowid: number, userid: number, branchid: number, stocktransferid: string) {
    const headers = this.header;
    console.log("Send To Approval");
    var Query = this._StockTransferOrderService + '/SendToApproval' + '/' + workflowid + '/' + userid + '/' + branchid + '/' + stocktransferid;
    console.log(Query);
    return this.http.post<{ data: any }>(Query, '', { headers });
  }
  Cancel(stocktransferid: string, modifiedby: number) {
    const headers = this.header;
    var Query = this._StockTransferOrderService + '/Cancel/' + stocktransferid + '/' + modifiedby;
    console.log(Query);
    return this.http.get<{ data: any }>(Query, { headers });
  }

  stocancel(stocktransferid: string, modifiedby: number) {
    const headers = this.header;
    var Query = this._StockTransferOrderService + '/StoCancel/' + stocktransferid + '/' + modifiedby;
    console.log(Query);
    return this.http.get<{ data: any }>(Query, { headers });
  }

  GetProductSearch(product: string, branchid: number) {
    const headers = this.header;
    var Query = this._StockTransferOrderService + '/GetProductSearch' + '/' + product + '/' + branchid;
    console.log(Query);
    return this.http.get<{ data: any }>(Query, { headers });
  }
}
