import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/core/Services/masters/product.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Productview } from 'src/app/core/Views/Productview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { SafeUrl } from '@angular/platform-browser';
import { ExportUtility } from 'src/assets/js/export-utility';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productquestionandanswer } from 'src/app/core/Models/Productquestionandanswer';
import { Productreview } from 'src/app/core/Models/Productreview';
import { Brand } from 'src/app/core/Models/Brand';
import autoTable from 'jspdf-autotable';
import { element } from 'protractor';
@Component({
	selector: 'app-v-product',
	templateUrl: './v-product.component.html',
	styleUrls: ['./v-product.component.css']
})
export class VProductComponent implements OnInit {
	selectedrows: any;
	pSelectableRow: any;
	_Add: Boolean;
	_Delete: Boolean;
	_Update: Boolean;
	_View: Boolean;
	_productsview: any[] = [];
	_productsviews: any[];
	_productview: Productview;
	_Action: IAction;
	productimage: SafeUrl;
	cols: any[];
	displayBasic: boolean;
	displayBasicReview: boolean;
	ManageDialogFAQ: boolean;
	ManageDialogFAQUpdate: boolean;
	ManageDialogReview: boolean;
	ManageDialogReviewUpdate: boolean;
	ProductReviews: Productview;
	Productquestionanswers: Productview;
	_Productreviewform: FormGroup;
	_faqform: FormGroup;
	_FAQStatus: Metadatum;
	_ReviewStatus: Metadatum;
	ProductFAQ: Productquestionandanswer;
	ProductReview: Productreview;
	_ProductId: number;
	_IsProgressSpinner: boolean = true;
	_productstatus: string = "0";
	_statuslist: any[];
	_Brands: Brand[] = [];
	_SelectedBrand: any[] = [];
	_VatTypes: any[] = [];
	_vattype: any;
	_perishableSearchForm: FormGroup;
	getproductlist: any[] = [];
	getproductlist1: any[] = [];
	getproductlist2: any[] = [];
	isExcelVisible: boolean = false;
	_userid: any;
	isStatuslist: boolean = false;
	isVatList: boolean = false;
	mappedSupplierdetails: boolean = false;
	_suppliermappedlist: any[] = [];
	selectedProductName: any;
	selectedProductType: any;
	selectedProductCategory: any;


	constructor(
		private fb: FormBuilder,
		private _ProductService: ProductService,
		private router: Router,
		private utility: CommonUtilities,
		private _AccessRightsService: AccessRightsService,
		private _CustomExceptionService: CustomExceptionService,
		private _hotkeysService: HotkeysService,
		private confirmationService: ConfirmationService,
		private exportUtility: ExportUtility
	) { }
	InitializeForm() {
		this._Productreviewform = this.fb.group({
			reviewid: [0],
			reviewdate: [],
			reviewrating: [],
			reviewdescription: [],
			customerid: [],
			emailid: [],
			productid: [],
			variantid: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			productcode: [],
			recordstatus: ["MSC00001"],
			recordstatusname: [],
			reviewby: [],
			reviewtype: ["ECO00022"],
			reviewtypename: []
		});
		this._Productreviewform.controls['reviewby'].setValue(sessionStorage["username"]);
		this._faqform = this.fb.group({
			productquestionandanswerid: [0],
			customerid: [0],
			productid: [],
			variantid: [0],
			question: [],
			answer: [""],
			answertype: ["ECO00022"],
			answerby: [""],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			recordstatus: ["MSC00001"],
			productcode: [],
			recordstatusname: [],
		})
		this._faqform.controls['answerby'].setValue(sessionStorage["username"]);
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(17);
		this.utility.pageLoadScript();
		this.cols = [
			{ field: 'productcode', header: 'Product Code' },
			{ field: 'producttypename', header: 'Product Type' },
			{ field: 'categoryname', header: 'Product Category' },
			{ field: 'productname', header: 'Product Name.' },
			{ field: 'brandname', header: 'Brand Name.' },
			{ field: 'purchaserateinusd', header: 'Purchase Rate(USD)' },
			{ field: 'discountpercentage', header: 'Discount %' },
			{ field: 'specification', header: 'Specification' },
			{ field: 'barcode', header: 'Barcode' },
			{ field: 'createdbyname', header: 'Created By' },
			{ field: 'createdon', header: 'Created On' },
			{ field: 'modifiedbyname', header: 'Modified By' },
			{ field: 'modifiedon', header: 'Modified On' },
			{ field: 'productstatusname', header: 'Status' },
			{ field: 'approvalstatusname', header: 'Approve Status' }
		]
		this._userid = parseInt(sessionStorage["userid"]);
		if (this._userid == 1) {
			this.isExcelVisible = true;
		}
		this.HotKeyIntegration();
		this.InitializeForm();
		this._perishableSearchForm = this.fb.group({
			isperishable: []
		})
		this._statuslist = [
			{ statusid: "0", statusname: 'Both' },
			{ statusid: "MSC00001", statusname: 'Active' },
			{ statusid: "MSC00002", statusname: 'Inactive' },
			{ statusid: "MSC00343", statusname: 'Hold' }
		]
		this._VatTypes =
			[
				{ vattypeid: 1, vattypename: 'VAT Products' },
				{ vattypeid: 2, vattypename: 'VAT Free Products' },
				{ vattypeid: 3, vattypename: 'Both' },
			]
		this._productstatus = "0";
		this._vattype = 3;
		this.FetchAll(1);
		this._productsview = [];
	}

	isperishableCheck() {
		let IP = this._perishableSearchForm.get("isperishable").value;

		if (IP === true) {
			this._productstatus = "0";
			this.isStatuslist = true;
			this._vattype = 3
			this.isVatList = true;
		}
		else {
			this.isStatuslist = false;
			this.isVatList = false;
		}
	}

	FetchAll(type: number) {
		this.isperishableCheck();
		let IP = this._perishableSearchForm.get("isperishable").value;
		//this._perishableSearchForm.get("isperishable").setValue(true);

		if (!IP) {
			IP = false
		}

		let data = {
			"type": type,
			"productstatus": this._productstatus,
			"Brandid": this._SelectedBrand || [],
			"isperishable": IP,
			"vattype": this._vattype == 1 ? 1 : this._vattype == 2 ? 2 : 3
		}
		this._IsProgressSpinner = true;
		this._ProductService.FetchAll(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._productsview = [];
			this._productsview = resultJSON.productviews;
			console.log("product list", this._productsview)
			if (type == 1) {
				this._Brands = resultJSON.brands
			}
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	SerchAllProducts() {
		this._IsProgressSpinner = true;
		let IP = this._perishableSearchForm.get("isperishable").value
		if (!IP) {
			IP = false
		}
		let data = {
			"productstatus": this._productstatus,
			"Brandid": this._SelectedBrand || [],
			"isperishable": IP,
			"vattype": this._vattype == 1 ? 1 : this._vattype == 2 ? 0 : 2
		}
		this._ProductService.SearchAllProducts(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._productsview = [];
			this._productsview = resultJSON.productviews;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	GetMappedSupplier(view) {
		var selectedProductId = view.productid;
		this.selectedProductName = view.productname;
		this.selectedProductType = view.producttypename;
		this.selectedProductCategory = view.categoryname;
		this.mappedSupplierdetails = true;
		this._IsProgressSpinner = true;
		let data = {
			"product": selectedProductId
		}
		this._ProductService.getSupplierMappedproducts(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._suppliermappedlist = resultJSON.suppliermappedlist
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
		this._IsProgressSpinner = false;

	}
	clear() {
		this._vattype = 3;
		this._productstatus = "0";
		this._SelectedBrand = [];
		this.FetchAll(1);
		this.ngOnInit();
	}
	onRowSelect(event) { }
	onRowUnselect(event) { }
	view(event) {
		this.router.navigate(['/cProduct'], { state: { productid: event.productid, action: 'view' } });
	}
	confirm(view) {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to in-active this Product <b>"' + view.productname + '"</b> ?',
			accept: () => { this.ChangeStatus(view); }
		});
	}
	ChangeStatus(view) {
		let userid = sessionStorage["userid"];
		let branchid = sessionStorage["currentbranchid"];
		this._IsProgressSpinner = true;
		this.utility.pageLoadScript();
		this._ProductService.changestatus(view.productid, userid, branchid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this._productsview = resultJSON.productviews;
				this._IsProgressSpinner = false;
			} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); this._IsProgressSpinner = false; }
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	edit(event) {
		this.router.navigate(['/cProduct'], { state: { productid: event.productid, action: 'edit' } });
	}
	GoToCreate(event) {
		this.router.navigate(['/cProduct']);
	}
	exportExcel() {
		let productlist = this.GetProductList();
		this.exportUtility.exportExcel(productlist, 'Product', 'xlsx');
	}

	ExportToPdf() {
		if (!this._productsview || !this._productsview.length)
			return;
		let StatusHistoryDetails = this.GetProductPDFList();
		const data = StatusHistoryDetails.map(Object.values);
		const head = [['Product Code', 'Type', 'Category', 'Name', 'Brand', 'Pur Rate(USD)', 'Discount%', 'Bar Code','No.of.suppliers Mapped', 'Is Desc?', 'Is Image?']]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					},
					headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					},
					bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('Product.pdf');
			})
		})
	}
	GetProductList() {
		let productlist = [];
		this._productsview.forEach(element => {
			let product: any = {};
			product["Product Code"] = element.productcode;
			product["Product Type"] = element.producttypename;
			product["Product Category"] = element.categoryname;
			product["Product Name"] = element.productname;
			product["Brand Name"] = element.brandname;
			product["Purchase Rate(USD)"] = element.purchaserateinusd;
			product["Discount %"] = element.discountpercentage;
			product["Barcode"] = element.barcode;
			product["No.of.suppliers Mapped"] = element.totalsuppliersmapped;
			// product["Review"] = element.reviewcount;
			product["Created By"] = element.createdbyname;
			product["Status"] = element.productstatusname;
			product["Is Product Description?"] = element.isproductdescription;
			product["Is Product Image?"] = element.isproductimage;
			product["Approve Status"] = element.approvalstatusname;
			productlist.push(product);
		});
		return productlist;
	}

	GetProductPDFList() {
		let productlist = [];
		this._productsview.forEach(element => {
			let product: any = {};
			product["Product Code"] = element.productcode;
			product["Product Type"] = element.producttypename;
			product["Product Category"] = element.categoryname;
			product["Product Name"] = element.productname;
			product["Brand Name"] = element.brandname;
			product["Purchase Rate(USD)"] = element.purchaserateinusd;
			product["Discount %"] = element.discountpercentage;
			product["Barcode"] = element.barcode;
			product["No.of.suppliers Mapped"] = element.totalsuppliersmapped;
			product["Is Product Description?"] = element.isproductdescription;
			product["Is Product Image?"] = element.isproductimage;
			productlist.push(product);
		});
		return productlist;
	}
	FAQ() {
		this.ManageDialogFAQ = true;
		this.displayBasic = false;
	}
	GetFAQ(view) {
		this.displayBasic = true;
		this._IsProgressSpinner = true;
		this._faqform.controls['productid'].setValue(view.productid);
		this._faqform.controls['productcode'].setValue(view.productcode);
		this._ProductId = view.productid
		this._ProductService.GetFAQ(view.productid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this.Productquestionanswers = resultJSON.productfaqviews;
				this._FAQStatus = resultJSON.recordstatuslst;
				this._IsProgressSpinner = false;
			} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); this._IsProgressSpinner = false; }
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	EditFAQ(view) {
		this.ManageDialogFAQUpdate = true;
		this.displayBasic = false;
		this._faqform.setValue(view);
	}
	SaveFAQ() {
		this.ProductFAQ = this._faqform.value;
		this.ProductFAQ.createdby = sessionStorage["userid"];
		this.ProductFAQ.createdon = new Date();
		this.ProductFAQ.modifiedby = sessionStorage["userid"];
		this.ProductFAQ.modifiedon = new Date();
		this.ProductFAQ.customerid = 0;
		this.ProductFAQ.variantid = 0;
		this.ProductFAQ.answerby = sessionStorage["username"];
		this._ProductService.createFAQ(this.ProductFAQ).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this._CustomExceptionService.handleSuccess("FAQ Added Successfully");
				this.InitializeForm();
				this.ManageDialogFAQ = false;
				this.displayBasic = false;
				this.FetchAll(1);
			} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); }
		}, error => { this._CustomExceptionService.handleError(error); });
	}
	UpdateFAQ() {
		this.ProductFAQ = this._faqform.value;
		this._ProductService.UpdateFAQ(this.ProductFAQ).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this._CustomExceptionService.handleSuccess("FAQ Updated Successfully");
				this.ManageDialogFAQ = false;
				this.ManageDialogFAQUpdate = false;
				this.displayBasic = false;
				this.InitializeForm();
				this.FetchAll(1);
			} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); }
		}, error => { this._CustomExceptionService.handleError(error); });
	}
	GetReview(view) {
		this.displayBasicReview = true;
		this._IsProgressSpinner = true;
		this._Productreviewform.controls['productid'].setValue(view.productid);
		this._Productreviewform.controls['productcode'].setValue(view.productcode);
		this._ProductService.GetReview(view.productid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this.ProductReviews = resultJSON.reviewfilterviews;
				this._ReviewStatus = resultJSON.recordstatuslst;
				console.log(resultJSON)
				this._IsProgressSpinner = false;
			} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); this._IsProgressSpinner = false; }
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	Review(view) {
		this.ManageDialogReview = true;
		this.displayBasicReview = false;
	}
	EditReview(view) {
		this.ManageDialogReviewUpdate = true;
		this.displayBasicReview = false;
		this._Productreviewform.setValue(view);
	}
	createReview() {
		this.ProductReview = this._Productreviewform.value;
		this.ProductReview.createdby = sessionStorage["userid"];
		this.ProductReview.createdon = new Date();
		this.ProductReview.modifiedby = sessionStorage["userid"];
		this.ProductReview.modifiedon = new Date();
		this.ProductReview.reviewdate = new Date();
		this.ProductReview.customerid = 0;
		this.ProductReview.variantid = 0;
		this._ProductService.createReview(this.ProductReview).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this._CustomExceptionService.handleSuccess("Review Added Successfully");
				this.InitializeForm();
				this.ManageDialogReview = false;
				this.displayBasicReview = false;
				this.FetchAll(1);
			} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); }
		}, error => { this._CustomExceptionService.handleError(error); });
	}
	UpdateReview() {
		this.ProductReview = this._Productreviewform.value;
		this._ProductService.UpdateReview(this.ProductReview).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this._CustomExceptionService.handleSuccess("Review Updated Successfully");
				this.InitializeForm();
				this.ManageDialogReview = false;
				this.ManageDialogReviewUpdate = false
				this.displayBasicReview = false;
				this.FetchAll(1);
			} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); }
		}, error => { this._CustomExceptionService.handleError(error); });
	}
	Cancel() {
		this.InitializeForm()
	}
	HotKeyIntegration() {
		this._hotkeysService.reset();
		if (this._Action._Add) {
			this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
				this.GoToCreate(null);
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
	}
	exportExcelproductnotmapped() {

		let type = 1;
		this._IsProgressSpinner = true;
		this._ProductService.GetProductmappedlist(type).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this.getproductlist = resultJSON.productmappedlist;
			this._IsProgressSpinner = false;
			if (this.getproductlist.length === 0) {
				throw new Error("No data available");
			}
			let Productmappedlist = this.GetProductmappednotlist();
			this.exportUtility.exportExcel(Productmappedlist, 'SUPPLIER_NOT_MAPPED_PRODUCT_LIST', 'xlsx');
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	exportExcelproductnotmappedsupplier() {
		let type = 3;
		this._IsProgressSpinner = true;
		this._ProductService.GetProductmappedlist(type).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this.getproductlist2 = resultJSON.productmappedlist;
			this._IsProgressSpinner = false;
			if (this.getproductlist2.length === 0) {
				throw new Error("No data available");
			}
			let productnotmappedsupplier = this.getproductnotmappedsupplier();
			this.exportUtility.exportExcel(productnotmappedsupplier, 'PRODUCT_NOT_MAPPED_SUPPLIER_LIST', 'xlsx');
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	getproductnotmappedsupplier() {
		let getproductnotmappedsupplier = [];
		this.getproductlist2.forEach(element => {
			let product: any = {};
			product["Product ID"] = element.PRODUCTID;
			product["Product Code"] = element.PRODUCTCODE;
			product["Product Type Name"] = element.PRODUCTTYPENAME;
			product["Product Category Name"] = element.PRODUCTCATEGORYNAME;
			product["Product Name"] = element.PRODUCTNAME;
			product["Status"] = element.STATUS;
			getproductnotmappedsupplier.push(product);
		});
		return getproductnotmappedsupplier;
	}

	GetProductmappednotlist() {
		let productlist = [];
		this.getproductlist.forEach(element => {
			let product: any = {};
			product["Supplier Id"] = element.SUPPLIERID;
			product["Supplier Code"] = element.SUPPLIERCODE;
			product["Supplier Name"] = element.SUPPLIERNAME;
			product["Currency"] = element.CURRENCYNAME;
			product["Supplier Category"] = element.SUPPLIERCATEGORY;
			product["Supplier Type"] = element.SUPPLIERTYPE;
			product["Series"] = element.SERIES;
			product["Supplier Status"] = element.STATUS;

			productlist.push(product);
		});
		return productlist;
	}
	exportExcelproductmapped() {
		let type = 2;
		this._IsProgressSpinner = true;
		this._ProductService.GetProductmappedlist(type).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this.getproductlist1 = resultJSON.productmappedlist;
			if (this.getproductlist1.length === 0) {
				throw new Error("No data available");
			}
			let Productmappedlist = this.GetProductmappedlist();
			this.exportUtility.exportExcel(Productmappedlist, 'SUPPLIER_MAPPED_PRODUCT_LIST', 'xlsx');
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	GetProductmappedlist() {
		let productlist = [];
		this.getproductlist1.forEach(element => {
			let product: any = {};
			product["Supplier Id"] = element.SUPPLIERID;
			product["Supplier Code"] = element.SUPPLIERCODE;
			product["Supplier Name"] = element.SUPPLIERNAME;
			product["Currency"] = element.SUPPLIERCURRENCY;
			product["Product Type"] = element.PRODUCTTYPENAME;
			product["Product Category"] = element.PRODUCTCATEGORYNAME;
			product["Product Code"] = element.PRODUCTCODE;
			product["Product Name"] = element.PRODUCTNAME;
			product["Cost Price(USD)"] = element.PURCHASERATEINUSD;
			product["Series"] = element.SERIES;
			product["Supplier Status"] = element.SUPPLIERSTATUS;
			productlist.push(product);
		});
		return productlist;
	}

}