<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Pending Delivery Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_rptpendingdeliveryform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                            [yearNavigator]="true" yearRange="1980:2090" [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                                        </p-calendar>
                                        <label for="fromdate">From Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                            [yearNavigator]="true" yearRange="1980:2090" [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                                        </p-calendar>
                                        <label for="todate">To Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_invoicetype"
                                            optionValue="metasubcode" optionLabel="metasubdescription" (onChange)="getinwardtype()"
                                            formControlName="invoicetype" [filter]="true" filterBy="metasubdescription">
                                        </p-dropdown>
                                        <label for="invoicetypename">Inward Type </label>
                                    </span>
                                </td>

                                <td style="width: 20%;" *ngIf="customertype">
                                    <span class="p-float-label">
                                        
                                        <p-multiSelect [filter]="false" [options]="_customers" optionLabel="customername"
                                            optionValue="customerid" [(ngModel)]="_customersid"
                                            [ngModelOptions]="{standalone: true}" displaySelectedLabel="true"
                                            [filter]="true" filterBy="customername" [virtualScroll]="true" itemSize="30">
                                        </p-multiSelect>
                                        <label>Customer  </label>
                                    </span>

                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        
                                        <p-multiSelect [filter]="false" [options]="_branches" optionLabel="branchname"
                                            optionValue="branchid" [(ngModel)]="_branchesid"
                                            [ngModelOptions]="{standalone: true}" displaySelectedLabel="true"
                                            [filter]="true" filterBy="branchname" [virtualScroll]="true" itemSize="30">
                                        </p-multiSelect>
                                        <label>Branch </label>
                                    </span>

                                </td>
                                <td></td>

                            </tr>

                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" icon="las la-search" (click)="GenerateReport()" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()" class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_pendingdeliverylists" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['supplierid', 'suppliername','suppliertypename','paymodename','currencyname',
                            'supplierstatusname','createdbyname','totalproducts']" [rowHover]="true" dataKey="supplierid" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="INVOICETYPE">Invoice Type
                                    <p-sortIcon field="INVOICETYPE">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="BRANCHNAME">Branch Name
                                    <p-sortIcon field="BRANCHNAME"></p-sortIcon>
                                </th>
                                <th pSortableColumn="REFERANCENO">Referance No
                                    <p-sortIcon field="REFERANCENO">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="REFERANCEDATE">Referance Date
                                    <p-sortIcon field="REFERANCEDATE">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="CUS_OR_BRANCH_NAME">Customer_or_supplier Name
                                    <p-sortIcon field="CUS_OR_BRANCH_NAME">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="REQUESTEDQTY">Requested Qty
                                    <p-sortIcon field="REQUESTEDQTY">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="DELIVERYQUANTITY">Delivered Qty
                                    <p-sortIcon field="DELIVERYQUANTITY"> </p-sortIcon>
                                </th>
                                <th pSortableColumn="BALANCEQUANTITY">Balance Qty
                                    <p-sortIcon field="BALANCEQUANTITY"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_pendingdelivery let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 60px;">
                                    {{i+1}}
                                </td>
                                <td>
                                    {{_pendingdelivery.INVOICETYPE}}
                                </td>
                                <td>
                                    {{_pendingdelivery.BRANCHNAME}}
                                </td>
                                <td>
                                    {{_pendingdelivery.REFERANCENO}}
                                </td>
                                <td>
                                    {{_pendingdelivery.REFERANCEDATE |date:'dd/MM/yyyy' }}
                                </td>
                                <td>
                                    {{_pendingdelivery.CUS_OR_BRANCH_NAME }}
                                </td>
                                <td>
                                    {{_pendingdelivery.REQUESTEDQTY }}
                                </td>

                                <td>
                                    {{_pendingdelivery.DELIVERYQUANTITY}}
                                </td>
                                <td class="text-center" style="width: 100px;">
                                    <a href="javascript:void(0);" (click)="getproductwisedetails(_pendingdelivery)">
                                        {{_pendingdelivery.BALANCEQUANTITY}}
                                    </a>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-dialog header="Pending Delivery Product Wise" [(visible)]="_productwise" [modal]="true" [style]="{width: '800px',height: '500px'}" [baseZIndex]="10000">
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="card">
                                <p-table #dtProductWiselockedqty [value]="_ProductWisepending" selectionMode="single" [columns]="ProductWiseLockedcols" [rowHover]="true" dataKey="productid" [showCurrentPageReport]="true" [(selection)]="selectedProductRows">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th class="text-center" style="width: 60px;">S.No

                                            </th>
                                            <th pSortableColumn="INVOICENO">Referance No
                                                <p-sortIcon field="INVOICENO">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="PRODUCTNAME">Product Name
                                                <p-sortIcon field="PRODUCTNAME">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="UOM">UOM
                                                <p-sortIcon field="UOM">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="REQUESTQTY">Request Qty
                                                <p-sortIcon field="REQUESTQTY">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="DELIVERYQUANTITY">Delivery Qty
                                                <p-sortIcon field="DELIVERYQUANTITY">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="BALANCEQTY">Balance Qty
                                                <p-sortIcon field="BALANCEQTY">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="deliveryStatus">Status
                                                <p-sortIcon field="deliveryStatus">
                                                </p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-_ProductWise let-i="rowIndex">
                                        <tr>
                                            <td class="text-center" style="width: 60px;">
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{_ProductWise.INVOICENO}}
                                            </td>
                                            <td>
                                                {{_ProductWise.PRODUCTNAME}}
                                            </td>
                                            <td>
                                                {{_ProductWise.UOM}}
                                            </td>
                                            <td>
                                                {{_ProductWise.REQUESTQTY}}
                                            </td>
                                            <td>
                                                {{_ProductWise.DELIVERYQUANTITY}}
                                            </td>
                                            <td>
                                                {{_ProductWise.BALANCEQTY}}
                                            </td>
                                            <td>
                                                {{_ProductWise.deliveryStatus}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>