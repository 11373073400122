import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptvatinputoutputService {

  public _Action: IAction;
  public _httpOptions;
  public _RptVATInputOutputService = environment.RptVATInputOutputService;
  constructor(private http: HttpClient) { }
  PageOnload(): Observable<any> {
    var Query = this._RptVATInputOutputService + '/PageInitialize';
    
    return this.http.get<{ data: any }>(Query);
  }
  // Print(fromdate: string, todate: string,branchid:number,currencyid:number) {
  //   var Query = this._RptVATInputOutputService + '/Print/' + fromdate + '/' + todate +'/'+ branchid+'/'+ currencyid;
  //  // window.open(Query, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
  //  return this.http.get(Query,{responseType: 'blob'});
  // }
  Print(vatreport:object) {
    var Query = this._RptVATInputOutputService ;
    return this.http.post(Query,vatreport, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }


  // onchangepartytype(partytype: string) {
  //   var Query = this._RptVATInputOutputService + '/onchangepartytype' + '/' + partytype;
  //   
  //   return this.http.get<{ data: any }>(Query);
  // }
}
