import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Documenttypeworkflow } from 'src/app/core/Models/Documenttypeworkflow';
import { Documenttypeworkflowstagebranchview } from 'src/app/core/Models/Documenttypeworkflowstagebranchview';
import { Documenttypeworkflowstageview } from 'src/app/core/Models/Documenttypeworkflowstageview';
import { Employee } from 'src/app/core/Models/Employee';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Role } from 'src/app/core/Models/Role';
import { User } from 'src/app/core/Models/User';
import { ApprovalconfigurationcorporateService } from 'src/app/core/Services/users/approvalconfigurationcorporate.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { vSelectDocumentName, vWorkflowName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { elementAt } from 'rxjs/operators';

@Component({
  selector: 'app-c-approvalconfigurationcorporate',
  templateUrl: './c-approvalconfigurationcorporate.component.html',
  styleUrls: ['./c-approvalconfigurationcorporate.component.css']
})
export class CApprovalconfigurationcorporateComponent implements OnInit {
  _Approvalconfigurationcorporateform: FormGroup;
  _Approvalcorporateworkflowdetailform: FormGroup;
  _action: string = "create";
  _submitted = false;
  _Approvalcorporate: Documenttypeworkflowstageview;

  _workflowdetails: any[] = [];
  _workflowdetail: Documenttypeworkflowstageview;
  _workflowdetailform: Documenttypeworkflowstageview[];

  selectedrows: any;
  _workflowid: number;
  _documenttypeid: number;
  documenttypeworkflowdetails: Documenttypeworkflowstageview[] = [];
  _Documentname: Documenttypeworkflow[];
  _Workflowname: Documenttypeworkflowstageview[];
  _Workflowlist: Documenttypeworkflowstageview[];
  _Roles: Role[];
  _RolesTemp: Role[];
  _Primaryapproved: User[];
  _PrimaryTemp: any;
  _Secondaryapproved: User[];
  _SecondaryTemp: any;
  _Stageaction: Metadatum[];
  _Stagestatus: Metadatum[];
  _ApprovalcorporateOBJ: any;
  _branchid = 1; //head office id by default
  isDisabledPrimary: any;
  isDisabledSecondary; any;
  isDisabledSave = false;
  isDisabledClear = false;
  isDisabledAdd = false;
  isDisabledRemove = false;
  _validate: boolean;
  _IsProgressSpinner: boolean = true;
  _userid = sessionStorage["userid"];
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _router: Router, private _CustomExceptionService: CustomExceptionService,
    private _ApprovalconfigurationcorporateService: ApprovalconfigurationcorporateService, private _hotkeysService: HotkeysService) {
    this.HotKeyIntegration();
  }
  //Form Initialize:
  InitializeForm() {
    this._Approvalconfigurationcorporateform = this.fb.group({
      documenttypeworkflowstageid: [],
      companyid: [0],
      branchid: [],
      documenttypeid: ['', vSelectDocumentName],
      documenttypename: [],
      workflowid: ['', vWorkflowName],
      workflowname: [],
      stageid: [],
      stagename: [],
      roleid: [],
      rolename: [],
      isbranchdependent: []
    });
    this._Approvalcorporateworkflowdetailform = this.fb.group({
      companyid: [],
      branchid: [],
      workflowid: [],
      workflowname: [],
      stageid: [],
      stagename: [],
      primaryapprovercode: [],
      primaryapprovername: [],
      secondaryapprovercode: [],
      secondaryapprovername: [],
      recordstatuscode: [],
      recordstatusname: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._workflowid = history.state?.workflowid ? history.state?.workflowid : 0;
    this._documenttypeid = history.state?.documenttypeid ? history.state?.documenttypeid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._IsProgressSpinner = true;
    this._ApprovalconfigurationcorporateService.PageOnLoad(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      // 5.these drop downs should be load in pageload 
      // 6.Document type drop down should load from approval type table (document name , document id)
      this._Documentname = resultJSON.documentlist;
      this._Workflowlist = resultJSON.documenttypeworkflowviews;
      this.documenttypeworkflowdetails = resultJSON.documenttypeworkflowstageviews;
      // 8.Role In grid load from role master (role id, role name)
      this._Roles = resultJSON.role;
      this._RolesTemp = resultJSON.role;
      // 9.Level in grid load from level master(level id, level name)
      this._Primaryapproved = resultJSON.approvalconfigurationusersviews;
      this._PrimaryTemp = resultJSON.approvalconfigurationusersviews;
      this._SecondaryTemp = resultJSON.approvalconfigurationusersviews;
      this._Secondaryapproved = resultJSON.approvalconfigurationusersviews;
      this._Stageaction = resultJSON.stageaction;
      // 10.status in grid load from metadata(active, inactive)
      this._Stagestatus = resultJSON.stagestatus;
      this._IsProgressSpinner = false;
      this.isDisabledPrimary = false;
      this.isDisabledSecondary = false;
      if (this._action == 'view') {
        this._Approvalconfigurationcorporateform.disable();
        this.isDisabledSave = true;
        this.isDisabledClear = true;
        this.isDisabledAdd = true;
        this.isDisabledRemove = true;
      }
      if (this._action == 'edit') {
        this.isDisabledSave = false;
        this.isDisabledClear = true;
        this.isDisabledAdd = false;
        this.isDisabledRemove = true;
      }
      if (this._action == 'create') {
        var l_corporateworkflow = new Documenttypeworkflowstageview();
        l_corporateworkflow.workflowid = 0;
        l_corporateworkflow.modifiedon = new Date();
        l_corporateworkflow.modifiedby = sessionStorage["userid"];
        l_corporateworkflow.createdby = sessionStorage["userid"];
        l_corporateworkflow.createdon = new Date();
        l_corporateworkflow.stagestatus =  "MSC00001";
        // for (var i = 0; i < this._workflowdetails.length; i++) {
        //   this._workflowdetails[i].stagestatus = "MSC00001"
        // }
        this._workflowdetails.push(l_corporateworkflow);
      }

      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        // 13.based on the documnet name and proces name , the grid values will fetches from approval type table
        this._workflowdetails = (this.documenttypeworkflowdetails || []).filter(f => f.documenttypeid == this._documenttypeid && f.workflowid == this._workflowid);
        //this._workflowdetails = (this.documenttypeworkflowdetails || []).filter(f => f.workflowid == this._workflowid);
        this._workflowdetailform = (this._workflowdetails || []).filter(f => f.workflowid == this._workflowid && f.branchid == this._branchid);
        this._Approvalconfigurationcorporateform.controls["documenttypeid"].setValue(this._workflowdetailform[0].documenttypeid);
        this.ChangeDocument();

        if (this._workflowdetails && this._workflowdetails.length) {
          this._workflowdetails.forEach(element => {
            element._Primaryapproved = (this._PrimaryTemp || []).filter(f => f.roleid == element.roleid);
          });
        }
        this._Approvalconfigurationcorporateform.controls["workflowid"].setValue(this._workflowdetailform[0].workflowid);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

  ChangeDocument(): void {
    var documnettype = parseInt(this._Approvalconfigurationcorporateform.get("documenttypeid").value);
    if (!documnettype)
      return;
    this._Workflowname = (this._Workflowlist || []).filter(f => f.documenttypeid == documnettype);
  }
  ChangeWorKflow(event) {
    let workflowId = this._Approvalcorporateworkflowdetailform.get("workflowid").value;

    //this._workflowdetails.stagestatus =  "MSC00001";

    var id = event.value;
    this._workflowdetails = (this.documenttypeworkflowdetails || []).filter(f => f.workflowid == id);
    this._workflowdetailform = (this._workflowdetails || []).filter(f => f.workflowid == this._workflowid && f.branchid == this._branchid);

    if (this._workflowdetails && this._workflowdetails.length) {
      this._workflowdetails.forEach(element => {
        element._Primaryapproved = (this._PrimaryTemp || []).filter(f => f.roleid == element.roleid);
      });
    }
    // if (this._workflowdetails && this._workflowdetails.length)
    this._action = 'edit';
    if ((this._action == 'create' || this._action == 'edit') && !this._workflowdetails.length) {
      var l_corporateworkflow = new Documenttypeworkflowstageview();
      l_corporateworkflow.workflowid = workflowId;
      l_corporateworkflow.documenttypeworkflowstageid = 0;
      l_corporateworkflow.modifiedon = new Date();
      l_corporateworkflow.modifiedby = sessionStorage["userid"];
      l_corporateworkflow.createdby = sessionStorage["userid"];
      l_corporateworkflow.createdon = new Date();
      l_corporateworkflow.stagestatus =  "MSC00001";
      this._workflowdetails.push(l_corporateworkflow);
    }
  }
  AddNewworkflow(event) {
    let workflowId = this._Approvalcorporateworkflowdetailform.get("workflowid").value;
    //  19. On clicking add button, it adds the new row in the grid 
    var l_corporateworkflow = new Documenttypeworkflowstageview();
    l_corporateworkflow.workflowid = workflowId;
    l_corporateworkflow.documenttypeworkflowstageid = 0;
    l_corporateworkflow.createdby = sessionStorage["userid"];
    l_corporateworkflow.modifiedby = sessionStorage["userid"];
    l_corporateworkflow.createdon = new Date();
    l_corporateworkflow.modifiedon = new Date();
    l_corporateworkflow.isbranchdependent = false
    l_corporateworkflow.stagestatus =  "MSC00001";
    this._workflowdetails.push(l_corporateworkflow);
  }
  //  20. on clicking remove button, it delete the particular row in the grid
  RemoveWorkflow(pWorkflowdetail: Documenttypeworkflowstageview) {
    if (this._workflowdetails.length > 1) {
      var index = this._workflowdetails.indexOf(pWorkflowdetail);
      this._workflowdetails.splice(index, 1)
    }
    // if (this._action == 'edit') {
    //   this._workflowdetails[length].stagestatus = "MSC00002";
    // }
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  // 21.On selecting role in the grid , the users should load in primary and secondary column which is mapped with the corresponding role 
  disableApproved(objectModel) {
    //  24. if " Is branch Dependent" is checked, then the flow wil work based on the branch level
    if (objectModel.isbranchdependent) {
      objectModel.primaryapprover = '';
      objectModel.secondaryapprover = '';
    }
  }
  OnChangeRole(workflowdetail: any, event: any) {
    var selectedrole = (this._RolesTemp || []).filter(f => f.roleid == event.value)[0].roleid;
    workflowdetail._Primaryapproved = (this._PrimaryTemp || []).filter(f => f.roleid == selectedrole);
    workflowdetail._Secondaryapproved = (this._PrimaryTemp || []).filter(f => f.roleid == selectedrole);
  }
  onSave() {
    this._submitted = true;
    // 34.if all the data is valid, allow to save the record in the database 
    if (!this._Approvalconfigurationcorporateform.valid) return;
    for (var i in this._workflowdetails) {
      this._workflowdetails[i].documenttypeid = this._workflowdetails[i].documenttypeid || this._Approvalconfigurationcorporateform.get("documenttypeid").value;
      this._workflowdetails[i].workflowid = this._workflowdetails[i].workflowid || this._Approvalconfigurationcorporateform.get("workflowid").value;
      this._workflowdetails[i].documenttypeworkflowstageid = this._workflowdetails[i].documenttypeworkflowstageid || 0;
      this._workflowdetails[i].stageaction = 'MSC00157';
      this._workflowdetails[i].approvedstatus = 'APPROVED';
      this._workflowdetails[i].rejectedstatus = 'REJECTED';
      this._workflowdetails[i].branchid = this._branchid;
      this._workflowdetails[i].companyid = 0;
      this._workflowdetails[i].status = "MSC00001";
    }
    this._ApprovalcorporateOBJ = { "Documenttypeworkflowstage": this._workflowdetails, "Documenttypeworkflowstagebranch": this._workflowdetails };
    if (this._action == 'create') {
      //if (this._validate == true) { this._workflowdetails.length == 1 ||
      if (this._workflowdetails[i].stageid == null && this._workflowdetails[i].stagename && this._workflowdetails[i].roleid &&
        this._workflowdetails[i].primaryapprover && this._workflowdetails[i].secondaryapprover && this._workflowdetails[i].approvedstatus && this._workflowdetails[i].rejectedstatus && this._workflowdetails[i].stagestatus) {
        this._CustomExceptionService.handleWarning("Please enter all the details");
        return;
      }
      this._IsProgressSpinner = true;
      //this.onEd();
      this._ApprovalconfigurationcorporateService.create(this._ApprovalcorporateOBJ).subscribe((result) => {
        const resutJSON = JSON.parse(JSON.stringify(result));
        if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
          //16. if there is already saved record for this document and process name , it should fetch all the datas in the grid
          //35.then show succes message as "Approval configuration for corporate level is registered succesfully".
          this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
          this.reset(null);
          this.InitializeForm();
        }
        else {
          this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
        }
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    else if (this._action == 'edit') {
      this._IsProgressSpinner = true;
      //this.onEd();
      this._ApprovalconfigurationcorporateService.edit(this._ApprovalcorporateOBJ).subscribe((result) => {
        const resutJSON = JSON.parse(JSON.stringify(result));
        if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
        }
        else {
          this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
        }
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    this._workflowdetails = [];
    this.ngOnInit();
  }
  // 18.On clicking clear button, it clear the document name and process name 
  reset(event) {
    this._Approvalconfigurationcorporateform.reset();
    this._submitted = false;
    this._workflowdetails = [{}];
    var l_corporateworkflow = new Documenttypeworkflowstageview();
        l_corporateworkflow.workflowid = 0;
        l_corporateworkflow.modifiedon = new Date();
        l_corporateworkflow.modifiedby = sessionStorage["userid"];
        l_corporateworkflow.createdby = sessionStorage["userid"];
        l_corporateworkflow.createdon = new Date();
        l_corporateworkflow.stagestatus =  "MSC00001";
        this._workflowdetails.push(l_corporateworkflow);
    this.InitializeForm();
  }
  goBack(event) {
    this._router.navigate(['/vApprovalconfigurationcorporate']);
  }
  onEd() {
    alert("test");
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._ApprovalcorporateOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  
}
