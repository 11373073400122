import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class OtherchargesService {
  public _Action: IAction;
  public _OtherChargesService = environment.OtherChargesService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient, private _CustomExceptionService: CustomExceptionService) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(p_otherchargesid: number): Observable<any> {
    const headers = this.header;
    var Query = this._OtherChargesService + '/' + p_otherchargesid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll() {
    const headers = this.header;
    // 15.all the datas should fetch in particular columns.based on country code
    var Query = this._OtherChargesService + '/FetchAll';
   
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      return data;
    }));
  }

  create(p_othercharges: object) {
    const headers = this.header;
    //console.log("Create");
    var Query = this._OtherChargesService;
    return this.http.post<{ data: any }>(Query, p_othercharges, { headers });
  }
 
  edit(p_othercharges: object) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._OtherChargesService;
   
    return this.http.put<{ data: any }>(Query, p_othercharges, { headers });
  }

  ChangeInactive(otherchargeid : number,userid: number)
  { 
    const headers = this.header;
    var Query = this._OtherChargesService  +'/Cancel/' + otherchargeid + '/' + userid; 
   
    return this.http.get<{data:any}>(Query, { headers });
  }

}
