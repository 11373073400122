import { Component, OnInit } from '@angular/core';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { RptcriticalstockserviceService } from 'src/app/core/Services/reports/Stock/RptCriticalStock/rptcriticalstockservice.service';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
    selector: 'app-rptcriticalstock',
    templateUrl: './rptcriticalstock.component.html',
    styleUrls: ['./rptcriticalstock.component.css']
})
export class RptcriticalstockComponent implements OnInit {
    _IsProgressSpinner: boolean = true;
    _userid = sessionStorage["userid"]
    _branchid = sessionStorage["currentbranchid"]
    _roleid = sessionStorage["currentRole"]
    _Obj: any;
    _rptcriticalstockviews: any[] = [];
    _branches: Branch[];
    branchid: any = parseInt(sessionStorage["BranchID"]);;
    cols: any[];
    constructor(
        private _RptcriticalstockserviceService: RptcriticalstockserviceService,
        private _CustomExceptionService: CustomExceptionService,
        private exportUtility: ExportUtility
    ) { }
    ngOnInit(): void {
        this._RptcriticalstockserviceService.PageOnload().subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            this._branches = resultJSON.branches;
            this.GetProducts();
            this.cols = [
                { field: 'producttypename', header: 'Product Type Name' },
                { field: 'categoryname', header: 'Product Category Name' },
                { field: 'productname', header: 'Product Name' },
                { field: 'minimumlevel', header: 'Minimum Stock Level' },
                { field: 'stock', header: 'Current Stock' }
            ]
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    GetProducts() {
        this._IsProgressSpinner = true;
        this._Obj = { "branchid": this.branchid };
        this._RptcriticalstockserviceService.Getcriticalproducts(this._Obj).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);
            this._rptcriticalstockviews = resultJSON.rptcriticalstockviews;
            this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    exportExcel() {
        let finalizedList = this.GetFinalizedList();
        this.exportUtility.exportExcel(finalizedList, 'CriticalStock', 'xlsx');
    }
    ExportToPdf() {
        if (!this._rptcriticalstockviews || !this._rptcriticalstockviews.length)
            return;
        let finalizedList = this.GetFinalizedList();
        const data = finalizedList.map(Object.values);
        let head = [["Product Type Name", "Product Category Name", "Product Name", "Minimum Stock Level", "Current Stock"]];
        import("jspdf").then(jsPDF => {
            import("jspdf-autotable").then(x => {
                const doc = new jsPDF.default('l', 'mm', 'a4');
                autoTable(doc, {
                    margin: { top: 30 },
                    head: head,
                    body: data,
                    tableLineColor: [0, 0, 0],
                    tableLineWidth: 0,
                    styles: {
                        lineColor: [0, 0, 0],
                        lineWidth: 0.1,
                    },
                    headStyles: {
                        fillColor: [211, 211, 211],
                        textColor: 0
                    },
                    bodyStyles: {
                        textColor: 0
                    },
                    columnStyles: { 2: { halign: 'right' }, 1: { halign: 'right' } },
                    didDrawCell: (data) => { },
                });
                doc.save('CriticalStock.pdf');
            })
        })
    }
    GetFinalizedList() {
        let finalizedList = [];
        this._rptcriticalstockviews.forEach(element => {
            let quotes: any = {};
            quotes["Product Type Name"] = element.producttypename;
            quotes["Product Category Name"] = element.categoryname;
            quotes["Product Name"] = element.productname;
            quotes["Minimum Stock Level"] = element.minimumlevel;
            quotes["Current Stock"] = element.stock;
            finalizedList.push(quotes);
        });
        return finalizedList;
    }
}