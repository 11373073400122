import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CCustomerslabwisediscountComponent } from './Customerslabwisediscount/c-customerslabwisediscount/c-customerslabwisediscount.component';
import { VCustomerslabwisediscountComponent } from './Customerslabwisediscount/v-customerslabwisediscount/v-customerslabwisediscount.component';


import { CCustomerwisediscountComponent } from './Customerwisediscount/c-customerwisediscount/c-customerwisediscount.component';
import { VCustomerwisediscountComponent } from './Customerwisediscount/v-customerwisediscount/v-customerwisediscount.component';
import { CRewardComponent } from './Reward/c-reward/c-reward.component';
import { VRewardComponent } from './Reward/v-reward/v-reward.component';
import { CSalescommissionComponent } from './Salescommission/c-salescommission/c-salescommission.component';
import { VSalescommissionComponent } from './Salescommission/v-salescommission/v-salescommission.component';
import { CSchemeComponent } from './Scheme/c-scheme/c-scheme.component';
import { VSchemeComponent } from './Scheme/v-scheme/v-scheme.component';

const routes: Routes = [

  { path: 'cCustomerwisediscount', component: CCustomerwisediscountComponent },
  { path: 'vCustomerwisediscount', component: VCustomerwisediscountComponent },
  { path: 'cScheme', component: CSchemeComponent },
  { path: 'vScheme', component: VSchemeComponent },
  { path: 'cSalescommission', component: CSalescommissionComponent },
  { path: 'vSalescommission', component: VSalescommissionComponent },

  { path: 'cReward', component: CRewardComponent },
  { path: 'vReward', component: VRewardComponent },
  {path:'cCustomerslabwisediscount',component:CCustomerslabwisediscountComponent},
  {path:'vCustomerslabwisediscount',component:VCustomerslabwisediscountComponent},



];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RewardsRoutingModule { }
