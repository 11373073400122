import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { Debitnote } from '../../Models/Debitnote';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class DebitnoteService {
  public _Action: IAction;
  public _DebitnoteService = environment.DebitnoteService;
  public _httpOptions;
  token: any;
	header: any;

  public _PettycashList: Debitnote[] = [];
  constructor(
    private http: HttpClient,
    private _CustomExceptionService: CustomExceptionService
  ) { 
    this.token = sessionStorage['AuthToken'];
		this.header = this.createHeaders(this.token)	
  }
  createHeaders(token: any): HttpHeaders {
		return new HttpHeaders({
		  'Content-Type': 'application/json',
		  Authorization: `Bearer ${token}`, // Include your token here	
		});
	  }
  PageOnLoad(Debitnoteid: any,userid:number, voucherno:string): Observable<any> {
    const headers = this.header;
    var Query = this._DebitnoteService + '/' + Debitnoteid+'/'+userid+'/'+voucherno;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll(userid: number, branchid: number,roleid:number,fromdate:string,todate:string) {
    const headers = this.header;
    var Query = this._DebitnoteService + '/FetchAll/' + userid + '/' + branchid+'/'+roleid+'/'+fromdate+'/'+todate;
   
    return this.http.get<{ data: any }>(Query , { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }
  create(Debitnote: object) {
    const headers = this.header;
    console.log("Create");
    var Query = this._DebitnoteService;
    return this.http.post<{ data: any }>(Query, Debitnote, { headers });
  }
  getTransactionDetails(purchaseinvoiceid: number) {
    const headers = this.header;
    var Query = this._DebitnoteService + '/getTransactionDetails/' + purchaseinvoiceid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  Cancel(userid:number,Debitnoteid: any) {
    const headers = this.header;
    console.log("Cancel")
    var Query = this._DebitnoteService + '/Cancel/' + userid +'/' +  Debitnoteid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetCustomerBasedEvidenceNo(ledgerid: number, userid: number) {
    const headers = this.header;
    var Query = this._DebitnoteService + '/GetCustomerBasedEvidenceNo/' + ledgerid + '/' + userid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
}
