export class Branchwiseproductcategory {
    productcategorydetailid: number;
    productcategoryid: number;
    branchid: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    producttypeid: number;
  isbranchlevelprice: any;
}
