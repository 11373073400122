import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptstockmovementsummaryService {
  public _Action: IAction;
  public _StocksummaryService = environment.StockMovementsummaryService;
  public _httpOptions;
  token: any;
  header: any;
  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(): Observable<any> {
    const headers = this.header;
    var Query = this._StocksummaryService + '/pageload';

    return this.http.get<{ data: any }>(Query, { headers });
  }
  getProductCategories(producttype: object) {
    const headers = this.header;
    var Query = this._StocksummaryService + '/getProductCategories';

    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }

  getProducts(productcategory: object) {
    const headers = this.header;
    var Query = this._StocksummaryService + '/getProducts';

    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }
  getProductwise(productwisestock: object) {
    const headers = this.header;
    var Query = this._StocksummaryService + '/Productwisestock';

    return this.http.post<{ data: any }>(Query, productwisestock, { headers });
  }
  getBrand(products: object) {
    const headers = this.header;
    var Query = this._StocksummaryService + '/getBrand';

    return this.http.post<{ data: any }>(Query, products, { headers });
  }
}

