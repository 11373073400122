import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { StockvaluereportService } from 'src/app/core/Services/reports/Stock/Stockvaluereport/stockvaluereport.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
@Component({
  selector: 'app-stockvaluereport',
  templateUrl: './stockvaluereport.component.html',
  styleUrls: ['./stockvaluereport.component.css']
})
export class StockvaluereportComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptstockvalueform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _userid: number;
  datepipe: any;
  _src: SafeResourceUrl;
  filteredProducts: any[];
  _SalesReportPrintObj: any;
  branchid: number[] = [];
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _producttype: Producttype[] = [];
  _productcategory: Productcategory[];
  _productlist: Productoverallview[] = [];
  _ProductNames: Productoverallview[] = [];
  _tempproductname: Productoverallview[] = [];
  productcatid: number[] = [];
  _branch: Branch[] = [];
  producttypeid: number[] = [];
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _StockvaluereportService: StockvaluereportService,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer
  ) { }
  InitializeStockvalueForm() {
    this._rptstockvalueform = this.fb.group({
      branchid: [0],
      productid: [0],
      productcategoryid: [0],
      producttypeid: [0],
      reporttype: []
    })
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeStockvalueForm();
    this._branchlist = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    this._rptstockvalueform.controls['reporttype'].setValue(true);
    this._IsProgressSpinner = true;
    this._StockvaluereportService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branch = resultJSON.branchList;
      this._producttype = resultJSON.producttypeList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
  }
  clear(event) {
    this.InitializeStockvalueForm();
    this.branchid = [];
    this.productcatid = [];
    this.producttypeid = [];
    this._productcategory = [];
    this._rptstockvalueform.controls['reporttype'].setValue(true);
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategory = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]), "Branchids": this.branchid || [] };
      this._StockvaluereportService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("category", resultJSON);
        this._productcategory = resultJSON.productcategoryList;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]), "Branchids": this.branchid || [] };
      this._StockvaluereportService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  GetProducts(event) {
    var selectedproduct = event.productid;
    let product = this._productlist.filter(f => f.productid == selectedproduct)[0];
    this.producttypeid = [];
    this.producttypeid.push(product.producttypeid);
    this.productcatid = [];
    this.productcatid.push(product.productcategoryid);
  }
  filterProducts(event) {
    this._IsProgressSpinner = true;
    this._StockvaluereportService.GetProductSearch(event.query, this._branchlist).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productlist = <Productoverallview[]>resultJSON.productSearchList || [];
      this._productlist = <Productoverallview[]>resultJSON.productSearchList || [];
      this._productcategory = resultJSON.productcategories || [];
      this._producttype = resultJSON.producttypeList || [];
      let filtered: any[] = [];
      let query = (<string>event.query).toLowerCase();
      this.filteredProducts = this._productlist.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GenerateReport(event) {
    if (this.branchid.length == 0) {
      this._CustomExceptionService.handleError("Please Select Atleast One Branch");
      return;
    }
    var Reporttype = this._rptstockvalueform.get("reporttype").value || false;
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    var ProductId = parseInt(this._rptstockvalueform.get("productid").value.productid) || 0;
    this._SalesReportPrintObj = { "Branchids": this.branchid || [], "ProductCategoryIds": this.productcatid || [], "ProductId": ProductId || 0, "CurrenctBranchId": sessionStorage["BranchID"], "ReportType": Reporttype }
    this._StockvaluereportService.Print(this._SalesReportPrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}