<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Sales Report</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="GenerateReportexcel()" [disabled]="_exceldisable"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptsalesform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                        (onSelect)="OnChangeDate()" (onInput)="OnChangeDate()" [minDate]="mindate" [maxDate]="maxdate"
                                        [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="fromdate">From Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                        (onSelect)="OnChangeDate()" (onInput)="OnChangeDate()" [yearNavigator]="true"
                                        [minDate]="mindate" [maxDate]="maxdate"
                                        yearRange="1980:2030" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="todate">To Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;" *ngIf="branchselectionlimit==0">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchid" [filter]="true"
                                        [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="branchname"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="branchname">Branch Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;" *ngIf="branchselectionlimit>0">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchid" [filter]="true"
                                        [virtualScroll]="true" itemSize="30" [filter]="true"
                                        filterBy="branchname" [ngModelOptions]="{standalone: true}">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="branchname">Branch Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_currencylist" optionLabel="currencyname"
                                        optionValue="currencyid" [(ngModel)]="currencyid" [virtualScroll]="true"
                                        itemSize="30" [filter]="true" filterBy="currencyname"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                    <label for="currencyid">Currency Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_customertypelist"
                                        optionValue="metasubcode" optionLabel="metasubdescription"
                                        (onChange)="onchangecustomertype($event)" formControlName="customertype"
                                        [filter]="true" filterBy="customertype">
                                    </p-dropdown>
                                    <label for="customertype">Customer Type</label>
                                </span>
                            </td>

                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_customerlist" optionLabel="customername"
                                        optionValue="customerid" [(ngModel)]="customerid" [filter]="true"
                                        [virtualScroll]="true" itemSize="30" filterBy="customername"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                    <label for="customername">Customer Name</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_supplierlist" optionLabel="suppliername"
                                        optionValue="supplierid" [(ngModel)]="supplierids" [filter]="true"
                                        [virtualScroll]="true" itemSize="30" filterBy="suppliername"
                                        (onChange)="onchangesupplier()"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                    <label for="suppliername">Supplier Name</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_producttypelist" optionLabel="producttypename"
                                        optionValue="producttypeid" [(ngModel)]="producttypeid" [filter]="true"
                                        [virtualScroll]="true" itemSize="30" [ngModelOptions]="{standalone: true}"
                                        (onChange)="getProductCategory($event)">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type Name </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                                        [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}" [filter]="true"
                                        [virtualScroll]="true" itemSize="30" (onChange)="getProduct($event)"
                                        optionValue="productcategoryid">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category Name</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productlist" optionLabel="productname"
                                        optionValue="productid" [(ngModel)]="productid" [filter]="true"
                                        [virtualScroll]="true" itemSize="30" (onChange)="ChangeProduct($event)"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>
                            </td>

                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                                        [filter]="true" [virtualScroll]="true" itemSize="30" [(ngModel)]="brandid"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                    <label for="brandid">Brand Name </label>
                                </span>
                            </td>


                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Salesby" optionLabel="username"
                                        optionValue="userid" formControlName="userid" [filter]="true"
                                        filterBy="username" [virtualScroll]="true" itemSize="30">
                                    </p-dropdown>
                                    <label for="userid">Sales by</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_Levellist" optionLabel="levelname" optionValue="levelid"
                                        [(ngModel)]="levelid" [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                    <label for="levelid">Markup Level</label>
                                </span>
                            </td>

                            <td>
                                <div class="p-formgroup-inline" *ngFor="false">
                                    <div class="p-field-checkbox">
                                        <p-checkbox formControlName="isdamaged" label="Is Damaged Product" binary="true"></p-checkbox>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <iframe #iframe height="500" width="100%" [src]="_src"></iframe>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>