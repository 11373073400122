<p-toast></p-toast>
<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Order Status</h3>
                    </div>
                    <div class="col-md-7 text-right"></div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [options]="_PaymentModes" [autoDisplayFirst]="false"
                                    optionLabel="metasubdescription" optionValue="metasubcode" name="status"
                                    [(ngModel)]="paymentmode">
                                </p-dropdown>
                                <label for="recordstatusname">Payment Status <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [options]="_OrderStatuses" [autoDisplayFirst]="false"
                                    optionLabel="metasubdescription" optionValue="metasubdescription" name="status"
                                    [(ngModel)]="orderstatus">
                                </p-dropdown>
                                <label for="recordstatusname">Order Status <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="OrderView()" title="Search"
                                icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>

                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="las la-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="las la-file-csv" title="Export CSV"
                                class="p-mr-2" pTooltip="CSV" (click)="exportUtility.exportCSV(dt)"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" (click)="exportExcel()"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" (click)="ExportToPdf()"
                                tooltipPosition="bottom"></button>
                            <!-- <button type="button" pButton pRipple icon="las la-filter" title="Filter" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>
                    </p-toolbar>

                    <p-table #dt [value]="orderstatusListGrid" [rows]="25" [paginator]="true"
                        [rowsPerPageOptions]="[25,50,100,150]"
                        [globalFilterFields]="['orderno','orderid','customername','orderdate','paymentmodename','orderamount','cancelledamount','status','ordertime','netamount','salesinvoiceno','orderstatusname','paynowreference','paymentcompletestatus']"
                        [rowHover]="true" dataKey="orderno" [columns]="cols"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:100px;" class="text-center">Action</th>
                                <th pSortableColumn="orderno">Order No <p-sortIcon field="orderno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="salesinvoiceno">Invoice No <p-sortIcon field="salesinvoiceno">
                                    </p-sortIcon>
                                </th>
                                <!-- <th style="width:150px;" pSortableColumn="orderid">Order ID <p-sortIcon field="orderid"</p-sortIcon>
                                </th> -->
                                <th pSortableColumn="customername">Customer Name <p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="orderdate">Order Date <p-sortIcon field="orderdate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="ordertime">Order Time <p-sortIcon field="ordertime"></p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="orderstatusname">Status <p-sortIcon field="orderstatusname"></p-sortIcon>
                                </th> -->
                                <th pSortableColumn="orderamount">Order Amount <p-sortIcon field="orderamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="cancelledamount">Cancelled Amount <p-sortIcon
                                        field="cancelledamount"></p-sortIcon>
                                </th>
                                <th pSortableColumn="netamount">Total Amount <p-sortIcon field="netamount"></p-sortIcon>
                                </th>
                                <th pSortableColumn="paymentmodename">Payment Mode <p-sortIcon field="paymentmodename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="orderstatusname">Order Status<p-sortIcon field="orderstatusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="isselfpickup">Is Self PickUp<p-sortIcon field="isselfpickup">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="paynowreference">Gateway Referernce <p-sortIcon
                                        field="paynowreference"></p-sortIcon>
                                </th>
                                <th pSortableColumn="paymentcompletestatus">Payment Status <p-sortIcon
                                        field="paymentcompletestatus"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_orderstatusListGrid>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-pen" title="Edit"
                                        class="p-button-success p-mr-2"
                                        *ngIf="_orderstatusListGrid.paymentmode=='ECO00052' && _orderstatusListGrid.isreconciled || _orderstatusListGrid.paymentmode=='ECO00053' && !_orderstatusListGrid.isreconciled &&_orderstatusListGrid.salesinvoiceno"
                                        (click)="view(_orderstatusListGrid)"></button>
                                    <button pButton pRipple icon="las la-copy" title="Is Reconciled"
                                        class="p-button p-mr-2"
                                        *ngIf="_orderstatusListGrid.isonlinepayment &&_orderstatusListGrid.ispaymentcompleted && !_orderstatusListGrid.isreconciled "
                                        (click)="PaymentReconcilation(_orderstatusListGrid)"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Delete"
                                        class="p-button-danger p-mr-2" (click)="confirm(_orderstatusListGrid)"
                                        *ngIf="_orderstatusListGrid.orderstatusname=='Pending' &&_orderstatusListGrid.salesinvoiceno"></button>
                                    <button pButton pRipple icon="las la-undo" title="Return"
                                        class="p-button-danger p-mr-2"
                                        (click)="ReturnOrderPopup(_orderstatusListGrid)"></button>
                                    <!-- <button pButton pRipple icon="las la-cube" title="Packed" class="p-button p-mr-2" ></button> isreconciled [disabled]="!_Action._Update"
                                    <button pButton pRipple icon="las la-shipping-fast" title="Shiiped" class="p-button p-mr-2"></button>
                                  [ngClass]="{'qs-row': _orderstatusListGrid.orderstatuscode === 'ECO00036'}"   <button pButton pRipple icon="las la-people-carry" title="Delivered" class="p-button p-mr-2" ></button> -->
                                </td>
                                <td>
                                    {{_orderstatusListGrid.orderno}}
                                </td>
                                <td>
                                    {{_orderstatusListGrid.salesinvoiceno}}
                                </td>
                                <td>
                                    {{_orderstatusListGrid.customername}}
                                </td>
                                <td>
                                    {{_orderstatusListGrid.orderdate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_orderstatusListGrid.ordertime}}
                                </td>
                                <td class="text-right">
                                    {{_orderstatusListGrid.orderamount| number: '1.2'}}
                                </td>
                                <td class="text-right">
                                    {{_orderstatusListGrid.cancelledamount| number: '1.2'}}
                                </td>
                                <!-- <td>
                                    {{_orderstatusListGrid.orderstatusname}}
                                </td> -->
                                <td class="text-right">
                                    {{_orderstatusListGrid.netamount| number: '1.2'}}
                                </td>
                                <td>
                                    {{_orderstatusListGrid.paymentmodename}}
                                </td>
                                <td>
                                    {{_orderstatusListGrid.orderstatusname}}
                                </td>
                                <td>
                                    {{_orderstatusListGrid.isselfpickup ? 'Yes' : 'No'}}
                                </td>
                                <td>
                                    {{_orderstatusListGrid.paynowreference}}
                                </td>
                                <td>
                                    {{_orderstatusListGrid.paymentcompletestatus}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-dialog header="Sales Return" [(visible)]="openpopup" [modal]="true"
                    [style]="{width: '80vw',height: '500px'}" [baseZIndex]="10000">
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="search-container">
                                <div class="p-inputgroup" style="width:150px !important;">
                                    <!-- <span class="p-float-label">
                                        <input type="text" pInputText
                                        (input)="dtBanklist.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                    </span> -->
                                </div>
                                <div class="text-right">
                                    <span class="p-float-label">
                                        <div class="action-btn">
                                            <button pButton pRiple label="Confirm" icon="las la-check" title="Confirm"
                                                class="p-button p-button p-mr-2" (click)="ConfirmReturn()"></button>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="card">
                                <p-table [value]="_ReturnOrderPopup" dataKey="ORDERID" [(selection)]="selectedProduct"
                                    [paginator]="true" [rows]="25" [rowsPerPageOptions]="[25,50,100,150]"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [showCurrentPageReport]="true" [rows]="5">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="text-align: center;width: 5%;">
                                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                            </th>
                                            <th style="text-align: center;width: 15%;">Order Id</th>
                                            <th style="text-align: center;width: 20%;">Order No</th>
                                            <th style="text-align: center;width: 20%;" pSortableColumn="question">
                                                Product Name<p-sortIcon field="question">
                                                </p-sortIcon>
                                            </th>
                                            <th style="text-align: center;width: 20%;" pSortableColumn="answer">Quantity
                                                <p-sortIcon field="answer">
                                                </p-sortIcon>
                                            </th>
                                            <th style="text-align: center;width: 20%;" pSortableColumn="answerby">Return
                                                Quantity<p-sortIcon field="answerby">
                                                </p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-_selectedReturnOrder>
                                        <tr>
                                            <td class="text-center">
                                                <p-tableCheckbox [value]="_selectedReturnOrder">
                                                </p-tableCheckbox>
                                            </td>
                                            <td style="text-align: center;">
                                                {{_selectedReturnOrder.ORDERID}}
                                            </td>
                                            <td style="text-align: center;">
                                                {{_selectedReturnOrder.ORDERNO}}
                                            </td>
                                            <td>
                                                {{_selectedReturnOrder.PRODUCTNAME}}
                                            </td>
                                            <td style="text-align: right;">
                                                {{_selectedReturnOrder.QUANTITY}}
                                            </td>
                                            <td style="text-align: right;" pEditableColumn>
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <p-inputNumber [(ngModel)]="_selectedReturnOrder.RETURNQUANTITY" mode="decimal"
                                                            locale="en-US" [useGrouping]="false" [maxFractionDigits]="3"
                                                            min="0" maxlength="15" (onInput)="returnQtyValidation(_selectedReturnOrder)"
                                                            (onBlur)="returnQtyValidation(_selectedReturnOrder)"
                                                            (onFocus)="returnQtyValidation(_selectedReturnOrder)"></p-inputNumber>
                                                        <!-- <input type="number" pInputText [min]="0"
                                                            (focusout)="returnQtyValidation(_selectedReturnOrder)"
                                                            (keydown.enter)="returnQtyValidation(_selectedReturnOrder)"
                                                            (keydown.tab)="returnQtyValidation(_selectedReturnOrder)"
                                                            (keydown.shift.tab)="returnQtyValidation(_selectedReturnOrder)"
                                                            > -->
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{_selectedReturnOrder.RETURNQUANTITY}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>