<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Approval Corporate</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save" (click)="onSave()"
                                [disabled]="isDisabledSave"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                icon="pi pi-trash" class="" class="p-button-danger"
                                [disabled]="isDisabledClear"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_Approvalconfigurationcorporateform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Documentname"
                                        optionLabel="functionname" optionValue="documenttypeid" name="documenttypeid"
                                        formControlName="documenttypeid" (onChange)="ChangeDocument()" [filter]="true"
                                        filterBy="functionname" [showClear]="true">
                                    </p-dropdown>
                                    <label for="documenttypeid">Document Name <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 11. if document name is not given, show error as "please select document name" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Approvalconfigurationcorporateform.get('documenttypeid').touched) && _Approvalconfigurationcorporateform.get('documenttypeid').errors?.SelectDocumentName">
                                    Please Select Document Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Workflowname"
                                        optionLabel="workflowname" optionValue="workflowid" name="workflowid"
                                        formControlName="workflowid" (onChange)="ChangeWorKflow($event)">
                                    </p-dropdown>
                                    <label for="workflowid">Workflow Name <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 14.the workflow no and workflow name will fetch in the grid according to the the document name  and process name  -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Approvalconfigurationcorporateform.get('workflowid').touched) && _Approvalconfigurationcorporateform.get('workflowid').errors?.WorkflowName">
                                    Please Select Workflow Type
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Workflow Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_workflowdetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['documenttypename','workflowtypename','workflowname']"
                        [rowHover]="true" dataKey="productid" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px;text-align:center">Action</th>
                                <th>Stage No</th>
                                <th>Stage Name</th>
                                <th>Role</th>
                                <th>Is Branch Dependent</th>
                                <th>Primary User</th>
                                <th>Secondary User</th>
                                <!-- <th>Stage Action</th>
                                <th>Approved Status</th>
                                <th>Reject Status</th> -->
                                <th>Stage Status</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_workflowdetail let-i="rowIndex">
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple title="Add" icon="pi pi-plus"
                                        class="p-button-success p-mr-2" (click)="AddNewworkflow($event)"
                                        [disabled]="isDisabledAdd"></button>
                                    <button pButton pRipple title="Remove" icon="pi pi-trash"
                                        class="p-button-danger p-mr-2" (click)="RemoveWorkflow(_workflowdetail)"
                                        [disabled]="isDisabledRemove"></button>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText [(ngModel)]="_workflowdetail.stageid"
                                                *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_workflowdetail.stageid}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_workflowdetail.stageid}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText [(ngModel)]="_workflowdetail.stagename"
                                                *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_workflowdetail.stagename}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_workflowdetail.stagename}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Roles" [filter]="true"
                                                optionLabel="rolename" optionValue="roleid" name="roleid"
                                                (onChange)="OnChangeRole(_workflowdetail,$event)"
                                                [disabled]="_action == 'view'" [(ngModel)]="_workflowdetail.roleid">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Roles" [filter]="true"
                                                optionLabel="rolename" optionValue="roleid" name="roleid"
                                                [(ngModel)]="_workflowdetail.roleid" [disabled]="_action == 'view'">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td style="width: 100px;text-align:center">
                                    <p-checkbox [(ngModel)]="_workflowdetail.isbranchdependent"
                                        (onChange)="disableApproved(_workflowdetail)" binary="true"
                                        [disabled]="_action == 'view'">
                                    </p-checkbox>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_workflowdetail._Primaryapproved"
                                                optionLabel="username" optionValue="userid" name="primaryapprover" [filter]="true"
                                                [disabled]="_workflowdetail.isbranchdependent || _action == 'view'"
                                                [(ngModel)]="_workflowdetail.primaryapprover">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_workflowdetail._Primaryapproved"
                                                optionLabel="username" optionValue="userid" [filter]="true"
                                                [disabled]="_workflowdetail.isbranchdependent || _action == 'view'"
                                                name="primaryapprover" [(ngModel)]="_workflowdetail.primaryapprover">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_workflowdetail._Primaryapproved"
                                                optionLabel="username" optionValue="userid" name="secondaryapprover" [filter]="true"
                                                [disabled]="_workflowdetail.isbranchdependent || _action == 'view'"
                                                [(ngModel)]="_workflowdetail.secondaryapprover">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_workflowdetail._Primaryapproved"
                                                optionLabel="username" optionValue="userid" name="secondaryapprover" [filter]="true"
                                                [disabled]="_workflowdetail.isbranchdependent || _action == 'view'"
                                                [(ngModel)]="_workflowdetail.secondaryapprover">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Stageaction"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                name="stageaction" [disabled]="_action == 'view'"
                                                [(ngModel)]="_workflowdetail.stageaction">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Stageaction"
                                                optionLabel="metasubdescription" optionValue="metasubcode" [disabled]="_action == 'view'"
                                                name="stageaction" [(ngModel)]="_workflowdetail.stageaction">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText *ngIf="_action != 'view'"
                                                [(ngModel)]="_workflowdetail.approvedstatus">
                                            <div *ngIf="_action == 'view'">{{_workflowdetail.approvedstatus}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_workflowdetail.approvedstatus}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText *ngIf="_action != 'view'"
                                                [(ngModel)]="_workflowdetail.rejectedstatus">
                                            <div *ngIf="_action == 'view'">{{_workflowdetail.rejectedstatus}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_workflowdetail.rejectedstatus}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_Stagestatus"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                name="stagestatus" [disabled]="_action == 'view'"
                                                [(ngModel)]="_workflowdetail.stagestatus">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_Stagestatus"
                                                optionLabel="metasubdescription" optionValue="metasubcode" [disabled]="_action == 'view'"
                                                name="stagestatus" [(ngModel)]="_workflowdetail.stagestatus">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <tr>
                        <td>
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>