import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SchemeService {
  public _Action: IAction;
  public _SchemeService = environment.SchemeService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(_schemeid: number, branchid: number): Observable<any> {
		const headers = this.header;
    var Query = this._SchemeService + '/' + _schemeid + '/' + branchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll() {
		const headers = this.header;
    var Query = this._SchemeService + '/FetchAll';

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);

      return data;
    }));
  }

  // '/OnProductCategoryChange' + '/'+  branchid + '/?' + paramvalue

  getProductCategory(producttypeid: number[], branchid: number) {
		const headers = this.header;
    let paramvalue = "Ids="
    paramvalue = paramvalue + producttypeid.join('&Ids=')
    console.log(paramvalue);
    var Query = this._SchemeService + '/getProductCategory' + '/' + branchid + '/?' + paramvalue;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  getFreeProductCategory(producttypeid: number[], branchid: number) {
		const headers = this.header;
    let paramvalue = "Ids="
    paramvalue = paramvalue + producttypeid.join('&Ids=')
    console.log(paramvalue);
    var Query = this._SchemeService + '/getProductCategory' + '/' + branchid + '/?' + paramvalue;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  getProduct(productcategoryid: number[], branchid: number) {
		const headers = this.header;
    let paramvalue = "Ids="
    paramvalue = paramvalue + productcategoryid.join('&Ids=')
    var Query = this._SchemeService + '/GetProductName' + '/' + branchid + '/?' + paramvalue;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  getFreeProduct(productcategoryid: number[]) {
		const headers = this.header;
    let paramvalue = "Ids="
    paramvalue = paramvalue + productcategoryid.join('&Ids=')
    var Query = this._SchemeService + '/GetProductName?' + paramvalue;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  getProductVariant(productid: number) {
		const headers = this.header;
    var Query = this._SchemeService + '/GetVariantName/' + productid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  getUOM(productvariantdetailid: number) {
		const headers = this.header;
    var Query = this._SchemeService + '/GetUomPrice/' + productvariantdetailid;

    return this.http.get<{ data: any }>(Query, { headers });
  }


  // Create
  create(p_Scheme: object) {
		const headers = this.header;
    console.log("Create");
    var Query = this._SchemeService;
    return this.http.post<{ data: any }>(Query, p_Scheme, { headers });
  }

  // Edit
  edit(p_Customerwisediscount: object) {
		const headers = this.header;
    console.log("Update");
    var Query = this._SchemeService;

    return this.http.put<{ data: any }>(Query, p_Customerwisediscount, { headers });
  }
  SendToApproval(workflowid: number, userid: number, branchid: number, schemeid: number) {
		const headers = this.header;
    var Query = this._SchemeService + '/SendToApproval' + '/' + workflowid + '/' + userid + '/' + branchid + '/' + schemeid;

    return this.http.post<{ data: any }>(Query, '', { headers });
  }
  Cancel(schemeid: number, userid: number) {
		const headers = this.header;
    var Query = this._SchemeService + '/ChangeStatus' + '/' + schemeid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
}
