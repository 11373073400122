import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class DbsyncService {

  public _Action: IAction;
  public _httpOptions;
  public _DBSyncService = environment.DBSyncService;
  constructor(private http: HttpClient) { }

  PageOnload(dbsync:object) {
    var Query = this._DBSyncService+'/Pageload';
    console.log(Query);
    return this.http.post<{ data: any }>(Query, dbsync);
  }
  OnSearch(dbsync:object) {
    var Query = this._DBSyncService + '/OnSearch';
    console.log(Query);
    return this.http.post<{ data: any }>(Query, dbsync);
  }
  Backupdetails(dbsync:object) {
    var Query = this._DBSyncService + '/Backupdetails';
    console.log(Query);
    return this.http.post<{ data: any }>(Query, dbsync);
  }
}