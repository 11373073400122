<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Coupon</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple title="Save" type="button" (click)="onSave()"
                                [disabled]="isDisabledSave" icon="pi pi-save"></button>
                            <button pButton pRipple title="Clear" icon="pi pi-trash" class="p-button-danger"
                                [disabled]="isDisabledClear" (click)="reset($event)"></button>
                            <button pButton pRiple icon="pi pi-search" title="Back to Search"
                                class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-tabView #tab [(activeIndex)]="selectedtab">
                    <p-tabPanel header="General">
                        <form [formGroup]="_couponform" (ngSubmit)="onSave()">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="couponcode" formControlName="couponcode" disabled
                                                pInputText>
                                            <label for="couponcode">Coupon Code </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="couponname" formControlName="couponname"
                                                pInputText>
                                            <label for="couponname">Coupon Name <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _couponform.get('couponname').touched) && _couponform.get('couponname').errors?.CouponNameRequired">
                                            Please enter couponname
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-calendar name="fromdate" formControlName="fromdate" [minDate]="maxDate"
                                                dateFormat="dd/mm/yy" [showIcon]="true">
                                            </p-calendar>
                                            <label for="fromdate"> Coupon From Date <span class="hlt-txt">*</span>
                                            </label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _couponform.get('fromdate').touched) && _couponform.get('fromdate').errors?.EffectiveFromRequired">
                                            Please select coupon from date
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-calendar name="todate" formControlName="todate" [minDate]="maxDate"
                                                dateFormat="dd/mm/yy" [showIcon]="true">
                                            </p-calendar>
                                            <label for="todate"> Coupon To Date <span class="hlt-txt">*</span> </label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _couponform.get('todate').touched) && _couponform.get('todate').errors?.EffectiveToRequired">
                                            Please select coupon to date
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_PaymentMode"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                formControlName="paymentmode">
                                            </p-dropdown>
                                            <label for="discounttype">Payment Mode <span class="hlt-txt">*</span>
                                            </label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="( _couponform.get('paymentmode').touched )&& _couponform.get('paymentmode').errors?.required">
                                            Please Select Payment Mode
                                        </span>
                                    </td>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="allowfreeshipping" binary="true"
                                                    formControlName="allowfreeshipping">
                                                </p-checkbox>
                                                <label for="allowfreeshipping">Allow Free Shipping</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_status"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                formControlName="couponstatus">
                                            </p-dropdown>
                                            <label for="discounttype"> Status </label>
                                        </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                        <table>
                            <td>
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </table>
                    </p-tabPanel>
                    <p-tabPanel header="Usage Restriction">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <form [formGroup]="_couponform" (ngSubmit)="onSave()">
                                        <span class="p-float-label">
                                            <input type="number" name="minamount"
                                                (keypress)="keypress.kpDecimalInput($event)" formControlName="minamount"
                                                pInputText>
                                            <label for="minamount">Minimun Spend<span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _couponform.get('minamount').touched) && _couponform.get('minamount').errors?.MinimumSpendRequired">
                                            Please enter minimumamount
                                        </span>
                                    </form>
                                </td>
                                <td style="width: 20%;">
                                    <form [formGroup]="_couponform" (ngSubmit)="onSave()">
                                        <span class="p-float-label">
                                            <input type="number" name="maxamount"
                                                (keypress)="keypress.kpDecimalInput($event)" formControlName="maxamount"
                                                pInputText>
                                            <label for="maxamount">Maximum spend <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _couponform.get('maxamount').touched) && _couponform.get('maxamount').errors?.MaximumSpendRequired">
                                            Please enter maximumamount
                                        </span>
                                    </form>
                                </td>
                                <td style="width: 20%;"></td>
                                <td style="width: 20%;"></td>
                                <td style="width: 20%;"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_includeproduccategories" optionLabel="categoryname"
                                            [(ngModel)]="_selectedIncludeCategoryIDs" [virtualScroll]="true"
                                            itemSize="30" [disabled]="isDisabledSave"
                                            (onChange)="getincludeProducts($event)" optionValue="productcategoryid"
                                            displaySelectedLabel=true>
                                        </p-multiSelect>
                                        <label for="producttypeid">Product Categories
                                        </label>
                                    </span>
                                </td>
                                <td>
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_includeproducts" optionLabel="productname"
                                            (onChange)="IncludeProducts($event)" [virtualScroll]="true" itemSize="30"
                                            [disabled]="isDisabledSave" [(ngModel)]="_selectedIncludeProductsIDs"
                                            optionValue="productid" displaySelectedLabel=true>
                                        </p-multiSelect>
                                        <label for="productcategoryid">Products </label>
                                    </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">
                                    <form [formGroup]="_couponform" (ngSubmit)="onSave()">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_discounttype"
                                                optionLabel="metasubdescription" (onChange)="DiscountCheck()"
                                                optionValue="metasubcode" formControlName="discounttype">
                                            </p-dropdown>
                                            <label for="discounttype"> Discount Type <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _couponform.get('discounttype').touched) && _couponform.get('discounttype').errors?.DiscountTypeRequired">
                                            Please Select discounttype
                                        </span>
                                    </form>
                                </td>
                                <td style="width: 20%;" *ngIf="_IsCouponAmount">
                                    <form [formGroup]="_couponform" (ngSubmit)="onSave()">
                                        <span class="p-float-label">
                                            <input type="number" name="couponamount" formControlName="couponamount"
                                                (keypress)="keypress.kpDecimalInput($event)" pInputText>
                                            <label for="couponamount">Coupon Amount <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _couponform.get('couponamount').touched) && _couponform.get('couponamount').errors?.CouponAmountRequired">
                                            Please enter couponamount
                                        </span>
                                    </form>
                                </td>
                                <td style="width: 20%;" *ngIf="_IsCouponPercentage">
                                    <form [formGroup]="_couponform" (ngSubmit)="onSave()">
                                        <span class="p-float-label">
                                            <input type="number" name="couponamount" formControlName="couponamount"
                                                (keypress)="keypress.kpDecimalInput($event)"
                                                (keyup)="DiscountPercentage()" pInputText>
                                            <label for="couponamount">Coupon Percentage <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _couponform.get('couponamount').touched) && _couponform.get('couponamount').errors?.CouponAmountRequired">
                                            Please enter coupon percentage
                                        </span>
                                    </form>
                                </td>
                                <td style="width: 20%;" *ngIf="_IsCouponPercentage">
                                    <form [formGroup]="_couponform" (ngSubmit)="onSave()">
                                        <span class="p-float-label">
                                            <input type="number" name="percentagemaxlimit"
                                                formControlName="percentagemaxlimit"
                                                (keypress)="keypress.kpDecimalInput($event)" pInputText>
                                            <label for="percentagemaxlimit">Maximum Amount Limit <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                    </form>
                                </td>
                            </tr>
                        </table>
                    </p-tabPanel>
                    <p-tabPanel header="Usage Limits">
                        <form [formGroup]="_couponform" (ngSubmit)="onSave()">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="usagelimitpercoupon"
                                                (keypress)="keypress.kpNumber($event)"
                                                formControlName="usagelimitpercoupon" pInputText>
                                            <label for="usagelimitpercoupon">Usage Limit Per Coupon<span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _couponform.get('usagelimitpercoupon').touched) && _couponform.get('usagelimitpercoupon').errors?.UsageLimitPerCoupanRequired">
                                            Please enter usage limit per coupan
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="usagelimitperitems"
                                                (keypress)="keypress.kpNumber($event)"
                                                formControlName="usagelimitperitems" pInputText>
                                            <label for="usagelimitperitems">Usage Limit to Items
                                            </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="usagelimitperuser"
                                                (keypress)="keypress.kpNumber($event)"
                                                formControlName="usagelimitperuser" pInputText>
                                            <label for="usagelimitperuser">Usage Limit Per Users<span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _couponform.get('usagelimitperuser').touched) && _couponform.get('usagelimitperuser').errors?.UsageLimitPerUsersRequired">
                                            Please enter usage limit per users
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                </tr>
                            </table>
                        </form>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>