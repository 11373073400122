import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { JournalService } from 'src/app/core/services/accounts/journal.service';
import { IAction } from 'src/app/core/services/security/IActions';

import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Journalview } from 'src/app/core/Views/Journalview';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { DatePipe } from '@angular/common';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-v-journal',
  templateUrl: './v-journal.component.html',
  styleUrls: ['./v-journal.component.css']
})
export class VJournalComponent implements OnInit {
  _Action: IAction;
  selectedrows: any;
  _Journalview: Journalview;
  _Journalviews: Journalview[];
  _userID: number;
  _IsProgressSpinner: boolean = true;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _branchid: number;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  cols: any[];
  _roleid = sessionStorage["currentRole"]
  mindate: Date;
  maxdate: Date;
  constructor(
    private _AccessRightsService: AccessRightsService,
    private fb: FormBuilder,
    private _JournalService: JournalService,
    private router: Router,
    private utility: CommonUtilities,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,
    private _hotkeysService: HotkeysService,
    private exportUtility: ExportUtility) {

  }



  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(67);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'voucherno', header: 'Voucher No' },
      { field: 'journaldate', header: 'Journal Date' },
      { field: 'narration', header: 'Narration' },
      { field: 'fromaccount', header: 'From Account' },
      { field: 'toaccount', header: 'To Account' },
      { field: 'creditamount', header: 'Credit Amount' },
      { field: 'debitamount', header: 'Debit Amount' },
      { field: 'currencyname', header: 'Currency' },
      { field: 'journalstatusdesc', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this._userID = sessionStorage["userid"];
    this.HotKeyIntegration();
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.Fetchall();

  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._JournalService.FetchAll(this._userID, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Journalviews = resultJSON.journalviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  onRowSelect(event) {
    // console.log("eventfired");
    // console.log(event.data);
    // console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    // console.log("eventUnfired");
    // console.log(event.data);
    // console.log(this.selectedrows);
  }
  view(event) {
    if (event.voucherno.indexOf('/') != -1) {
      event.voucherno = this.replaceAll((String)(event.voucherno), '/', '-')
    }
    this.router.navigate(['/CJournal'], { state: { journalid: event.voucherno, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/CJournal']);
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Voucher <b>"' + event.voucherno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    if (event.voucherno.indexOf('/') != -1) {
      event.voucherno = this.replaceAll((String)(event.voucherno), '/', '-')
    }
    var journalid = event.voucherno;
    this._IsProgressSpinner = true;
    this._JournalService.Cancel(journalid, this._userID).subscribe((result) => {

      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus && resultJSON.tranStatus.result) {
        this.Fetchall();
        this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      }
      else {
        this._CustomExceptionService.handleWarning(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      //this._Journalviews = resultJSON.journalviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let JournalList = this.GeJournalList();
    this.exportUtility.exportExcel(JournalList, 'Journal', 'xlsx');
  }

  // ExportToPdf() {
  //   let JournalList = this.GeJournalList();
  //   this.exportUtility.ExportToPdf(JournalList, 'Journal.pdf');
  // }
  ExportToPdf() {
    let TermsAndConditionList = this.GeJournalList();
    const data = TermsAndConditionList.map(Object.values);
    const head = [[
      'Voucher No',
      'Journal Date',
      'Narration',
      'From Account',
      'To Account',
      'Credit Amount',
      'Debit Amount',
      'Currency',
      'Status',
      'Created By',
      'Created On'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('journal.pdf');
      })
    })
  }

  GeJournalList() {
    if (!this._Journalviews || !this._Journalviews.length)
      return;
    let JournalList = [];
    this._Journalviews.forEach(element => {
      let journal: any = {};
      journal["Voucher No"] = element.voucherno;
      journal["Journal Date"] = this.datepipe.transform(element.journaldate, 'dd/MM/yyyy');
      journal["Narration"] = element.narration;
      journal["From Account"] = element.fromaccount;
      journal["To Account"] = element.toaccount;
      journal["Credit Amount"] = element.creditamount;
      journal["Debit Amount"] = element.debitamount;
      journal["Currency"] = element.currencyname;
      journal["Status"] = element.journalstatusdesc;
      journal["Created By"] = element.createdbyname;
      journal["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      JournalList.push(journal);
    });
    return JournalList;
  }
}
