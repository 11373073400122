export class Quoterequestpaymentterm {
    quotedetailid: number;
    quoterequestid: number;
    termtype: string;
    termsandconditionid: number;
    termdescription: string;
    accountingyear: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;

 
}