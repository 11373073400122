import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../../domain/appconfig';
import { Subject, Observable } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class OutstandingReceivableDashboardServiceService {
	public _outstandingReceivableDashboardService = environment.OutstandingReceivableDashboardService;
	config: AppConfig = {
		theme: 'saga-blue',
		dark: false,
		inputStyle: 'outlined',
		ripple: true
	};
	constructor(private http: HttpClient) { }
	private configUpdate = new Subject<AppConfig>();
	configUpdate$ = this.configUpdate.asObservable();
	updateConfig(config: AppConfig) {
		this.config = config;
		this.configUpdate.next(config);
	}
	getConfig() {
		return this.config;
	}
	PageOnload(): Observable<any> {
		var Query = this._outstandingReceivableDashboardService + '/pageload';
		return this.http.get<{ data: any }>(Query);
	}
	OutstandingreceiveBranchWise(branchid: number[]): Observable<any> {
		var Query = this._outstandingReceivableDashboardService + "/OutstandingreceiveBranchWise"
		return this.http.get<{ data: any }>(Query);
	}
}