import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Currency } from 'src/app/core/Models/Currency';
import { Customer } from 'src/app/core/Models/Customer';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Supplier } from 'src/app/core/Models/Supplier';

import { RptpaymentService } from 'src/app/core/Services/reports/Accounting/RptPayment/rptpayment.service';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-rptpayment',
  templateUrl: './rptpayment.component.html',
  styleUrls: ['./rptpayment.component.css']
})
export class RptpaymentComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptpaymentform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _branchid = parseInt(sessionStorage["currentbranchid"]);
  _userid: number;
  _currencylist: Currency[] = [];
  datepipe: any;
  _Action: IAction;
  _typelists = [{ type: 'Customer', value: '11' }, { type: 'Supplier', value: '15' }];

  mindate: Date;
  maxdate: Date;


  _customer_supplierlist: Accountledgerview[] = [];
  _accountnamelist: Accountledgerview[];
  _src: SafeResourceUrl;
  _typelist = [{ type: 'PAYMENT' }, { type: 'RECEIPT' }];//,{ type: 'Both'}
  constructor(private utility: CommonUtilities,private _AccessRightsService: AccessRightsService, private fb: FormBuilder, private _RptpaymentService: RptpaymentService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }


  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(110);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptpaymentform = this.fb.group({
      fromdate: [''],
      todate: [''],
      partytype: [0],
      customer_supplierid: [0],
      branchid: [0],
      type: [0],
      currencyid: [0]
    })

    this._userid = parseInt(sessionStorage["userid"]);
    // this.InitializeForm();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptpaymentform.get("fromdate").setValue(new Date);
      this._rptpaymentform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptpaymentform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptpaymentform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

    // this._rptpaymentform.controls['fromdate'].setValue(dt);
    // this._rptpaymentform.controls['todate'].setValue(new Date());
    this._rptpaymentform.controls['type'].setValue('PAYMENT');
    this._rptpaymentform.controls['currencyid'].setValue(1);
    this._RptpaymentService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // this._partytypelist = resultJSON.partytypeList;
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this._rptpaymentform.reset();
    var dt = new Date();
    dt.setDate(dt.getDate() - 7);
    this._rptpaymentform.controls['fromdate'].setValue(dt);
    this._rptpaymentform.controls['todate'].setValue(new Date());

    this._rptpaymentform.controls['type'].setValue('PAYMENT');
    this._rptpaymentform.controls['currencyid'].setValue(1);

  }

  GenerateReport(event) {
    var _fromdate = this._rptpaymentform.get("fromdate").value;
    var _todate = this._rptpaymentform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');

    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    var customerorsupplier = this._rptpaymentform.get("customer_supplierid").value == null ? 0 : this._rptpaymentform.get("customer_supplierid").value;
    var partytype = this._rptpaymentform.get("partytype").value == null ? 0 : this._rptpaymentform.get("partytype").value;
    var branchid = this._rptpaymentform.get("branchid").value == null ? 0 : this._rptpaymentform.get("branchid").value;
    var type = (this._rptpaymentform.get("type").value == null || this._rptpaymentform.get("type").value == "Both") ? 0 : this._rptpaymentform.get("type").value;
    // this._RptpaymentService.Print(fromdate, todate, partytype, customerorsupplier, branchid, this._userid);
    if (partytype == null || partytype == 0) {
      this._CustomExceptionService.handleError("Please Select Party Type");
      return;
    }
    if (customerorsupplier == null || customerorsupplier == 0) {
      this._CustomExceptionService.handleError("Please Select Party Name");
      return;
    }
    if (type == null || type == 0) {
      this._CustomExceptionService.handleError("Please Select Type");
      return;
    }
    var currentbranchid = parseInt(sessionStorage["currentbranchid"]);
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    let currencyid = this._rptpaymentform.controls['currencyid'].value;
    this._RptpaymentService.Print(fromdate, todate, partytype, customerorsupplier, branchid, this._userid, type, currentbranchid, currencyid).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  onchangepartytype(event) {
    this._userid = parseInt(sessionStorage["userid"]);
    var partytype = event.value;
    if (partytype == null && partytype == 0) {
      this._CustomExceptionService.handleError("Please Select Party Type");
      return;
    }
    this._IsProgressSpinner = true;
    this._RptpaymentService.onchangepartytype(partytype, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accountnamelist = resultJSON.accountnameList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

}
