import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { RptPendingApprovalStatusService } from 'src/app/core/Services/reports/Stock/rpt-pending-approval-status/rpt-pending-approval-status.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-pending-approval-status',
  templateUrl: './pending-approval-status.component.html',
  styleUrls: ['./pending-approval-status.component.css']
})
export class PendingApprovalStatusComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptpendingapprovalstatusform: FormGroup;
  _branchlist: any[] = [];
  _src: SafeResourceUrl;
  branchid: number[] = [];
  cols: any[];
  isdisable = false;
  _BranchWiseObj: any;
  Pendingapprovalview: any[] = [];
  _screenlist: any = [
    { functionid: 1, functionname: "GRN" },
    { functionid: 2, functionname: "Teller Cash" },
    { functionid: 3, functionname: "Stock Adjustment" }]
  screenname: any;

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _PendingApprovalStatusService: RptPendingApprovalStatusService,
    private _CustomExceptionService: CustomExceptionService,
    private exportUtility: ExportUtility,
    private sanitizer: DomSanitizer) { }

    InitiliazeForm() {
      
      this._rptpendingapprovalstatusform = this.fb.group({
        screenlist: [],
       
      })
      
    }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'BRANCHNAME', header: 'Branch Name' },
      { field: 'SCREENNAME', header: 'Screen Name' },
      { field: 'REFNO', header: 'Ref No' },
      { field: 'REFDATE', header: 'Ref Date' },
      { field: 'CREATEDBY', header: 'Created By' },
      { field: 'CREATEDON', header: 'Created On' },
      { field: 'APPROVALSTATUS', header: 'Approval Status' },

    ]
    this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
    this.InitiliazeForm();
    this._IsProgressSpinner = true;
    this._PendingApprovalStatusService.PageOnload({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branches;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  clear(event) {
    this._rptpendingapprovalstatusform.reset();
    this.branchid = [];
    this.Pendingapprovalview = [];
    this._rptpendingapprovalstatusform.controls['screenlist'].setValue(0);
  }

  GenerateReport() {
    var screenname = this._rptpendingapprovalstatusform.get("screenlist").value || 0;
    this._BranchWiseObj = {
      "branchname": this.branchid || [],
      "screenname": screenname
    };
    this._IsProgressSpinner = true;
    this._PendingApprovalStatusService.Print(this._BranchWiseObj).subscribe((result) => {
      this._IsProgressSpinner = false;
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.pendingApprovalStatus.length == 0) {
        this.Pendingapprovalview = [];
        this._CustomExceptionService.handleError("No Data Available");
      } else {
        this.Pendingapprovalview = [];
        this.Pendingapprovalview = resultJSON.pendingApprovalStatus;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  exportExcel() {
    let pendingApprovalLists = this.GetPendingApprovalList();
    this.exportUtility.exportExcel(pendingApprovalLists, 'Pending Approval List', 'xlsx');
  }

  ExportToPdf() {
    let pendingApprovalLists = this.GetPendingApprovalList();
    const data = pendingApprovalLists.map(Object.values);
    const head = [[
      'Branch Name',
      'Screen Name',
      'Ref No',
      'Ref Date',
      'Created By',
      'Created On',
      'Approval Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          }, headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          }, bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Pending Approval List.pdf');
      })
    })
  }

  GetPendingApprovalList() {
    let pendingApprovalLists = [];
    this.Pendingapprovalview.forEach(element => {
      let pendingapprovalstatus: any = {};
      pendingapprovalstatus["BRANCHNAME"] = element.BRANCHNAME;
      pendingapprovalstatus["SCREENNAME"] = element.SCREENNAME;
      pendingapprovalstatus["REFNO"] = element.REFNO;
      pendingapprovalstatus["REFDATE"] = element.REFDATE;
      pendingapprovalstatus["CREATEDBY"] = element.CREATEDBY;
      pendingapprovalstatus["CREATEDON"] = element.CREATEDON;
      pendingapprovalstatus["APPROVALSTATUS"] = element.APPROVALSTATUS;
      pendingApprovalLists.push(pendingapprovalstatus);
    });
    return pendingApprovalLists;
  }
}
