import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ShippinglineService } from 'src/app/core/Services/masters/shippingline.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Shippinglineview } from 'src/app/core/Views/Shippinglineview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-shipping-line',
  templateUrl: './v-shipping-line.component.html',
  styleUrls: ['./v-shipping-line.component.css']
})
export class VShippingLineComponent implements OnInit {
  selectedrows: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _ShippingLine: Shippinglineview;
  _ShippingLineViews: Shippinglineview[];
  _Action: IAction;
  _IsProgressSpinner: boolean = false;
  datepipe = new DatePipe('en-US');
  cols: any[];
  _ShippingLineid: number;
  _userid = sessionStorage["userid"];
  constructor(private fb: FormBuilder, private _ShippingLineService: ShippinglineService, private router: Router, private utility: CommonUtilities, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService, private exportUtility: ExportUtility){}

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(170);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();

    this.cols = [
      { field: 'shippinglinecode', header: 'Shipping Line Code' },
      { field: 'shippinglinename', header: 'Shipping Line Name' },
      { field: 'statusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
      { field: 'modifiedbyname', header: 'Modified By' },
      { field: 'modifiedon', header: 'Modified On' },
    ]

    this._IsProgressSpinner = true;
    this._ShippingLineService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ShippingLineViews = resultJSON.shippinglineviews;
      console.log(this._ShippingLineViews)
      this.selectedrows = resultJSON.shippinglineviews;
      this._IsProgressSpinner = false;
      console.log(this._ShippingLineViews)
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/cShippingline'], { state: { shippinglineid: event.shippinglineid, action: 'view' } });
  }
  edit(event) {
    this.router.navigate(['/cShippingline'], { state: { shippinglineid: event.shippinglineid, action: 'edit' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cShippingline']);
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  exportExcel() {
    let ShippingLineList = this.GetShippingLineList();
    this.exportUtility.exportExcel(ShippingLineList, 'ShippingLine', 'xlsx');
  }

  ExportToPdf() {
    let ShippingLineList = this.GetShippingLineList();
    this.exportUtility.ExportToPdf(ShippingLineList, 'ShippingLine.pdf');
  }

  GetShippingLineList() {
    let GetShippingLineList = [];
    this._ShippingLineViews.forEach(element => {
      let shippingline: any = {};
      shippingline["Shipping Line Code"] = element.shippinglinecode;
      shippingline["Shipping Line Name"] = element.shippinglinename;
      shippingline["Status"] = element.statusname;
      shippingline["Created By"] = element.createdbyname;
      shippingline["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      shippingline["Modified By"] = element.modifiedbyname;
      shippingline["Modified On"] = this.datepipe.transform(element.modifiedon, 'dd/MM/yyyy');
      GetShippingLineList.push(shippingline);
    });
    return GetShippingLineList;
  }
  confirm(event) {
    this._ShippingLineid = event.Shippinglineid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this SippingLine <b>"' + event.shippinglinename + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  onRowSelect(event){

  }
  onRowUnselect(event){

  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._ShippingLineService.Cancel(event.shippinglineid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_09);
        this._ShippingLineService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._ShippingLineViews = resultJSON.shippinglineviews;
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
