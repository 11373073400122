<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Document Description</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" [routerLink]="['/cdocumentdescription']"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" (click)="ExportToPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_documentdescriptionsview" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['documentdescriptionname','documentdescriptiontypename','statusname']" [rowHover]="true" dataKey="otherchargeid"
                        [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">

                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:100px;text-align: center;">Action</th>
                                <th pSortableColumn="otherchargename">Document Description Name
                                    <p-sortIcon field="otherchargename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="otherchargeamount">Document Description Type
                                    <p-sortIcon field="otherchargeamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="otherchargestatusname">Status
                                    <p-sortIcon field="otherchargestatusname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                    <th pSortableColumn="modifiedbyname">Modified By
                                        <p-sortIcon field="modifiedbyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="modifiedon">Modified On
                                        <p-sortIcon field="modifiedon">
                                        </p-sortIcon>
                                    </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_documentdescriptionview>
                            <tr>
                                <td style=" text-align: center">

                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2" [disabled]="!_Action._View" (click)="view(_documentdescriptionview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update" (click)="edit(_documentdescriptionview)"></button>

                                    <!-- <button pButton pRipple icon="las la-times" title="Cancel"
                                        class="p-button p-button-danger" [disabled]="!_Action._Delete"
                                        (click)="confirm(_otherchargeview)"
                                        [disabled]="_otherchargeview.otherchargestatusname=='Inactive'"></button> -->
                                    <!-- <button pButton pRipple icon="pi pi-trash" title="Delete"
                                        class="p-button p-button-danger" [disabled]="!_Action._Delete"
                                        (click)="delete(_otherchargeview)"></button> -->
                                </td>
                                <td>
                                    {{_documentdescriptionview.documentdescriptionname}}
                                </td>
                                <td>
                                    {{_documentdescriptionview.documentdescriptiontypename}}
                                </td>
                                <td>
                                    {{_documentdescriptionview.statusname}}
                                </td>
                                <td>
                                    {{_documentdescriptionview.createdbyname}}
                                </td>
                                <td>
                                    {{_documentdescriptionview.createdon |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_documentdescriptionview.modifiedbyname}}
                                </td>
                                <td>
                                    {{_documentdescriptionview.modifiedon |date:'dd/MM/yyyy'}}
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>