import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { CouponService } from 'src/app/core/Services/ecommerce/coupon.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-coupon',
  templateUrl: './v-coupon.component.html',
  styleUrls: ['./v-coupon.component.css']
})
export class VCouponComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _IsProgressSpinner: boolean = false;
  // selectedrows:any
  _Action: IAction;
  _couponviews:any[];
  _couponview:any
  cols: any[];
  constructor(private router: Router,private _CounponService: CouponService,private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService, private utility: CommonUtilities,public exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(302);
    this.cols = [
      { field: 'couponcode', header: 'Coupon Code' },
      { field: 'couponname', header: 'Coupon Name' },
      { field: 'fromdate', header: 'From Date' },
      { field: 'todate', header: 'To Date' },
      { field: 'usagelimitpercoupon', header: 'Coupon Limit' },
      { field: 'currentusagelimitpercoupon', header: 'Coupon usage' },
      { field: 'couponstatusname', header: 'Status' },
    ]
  
    this._CounponService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // console.log("fetch",resultJSON);
     
      this._couponviews = resultJSON.couponviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    //33.on clicking view button, it show the view screen.
    this.router.navigate(['/cCoupon'], { state: { couponid: event.couponid, action: 'view' } });
  }
  edit(event) {
    //36.on clicking update button, it show the update screen.
    // if (event.status == "MSC00045" || event.status == null) {
      this.router.navigate(['/cCoupon'], { state: { couponid: event.couponid, action: 'edit' } });
    }
  GoToCreate(event) {
    this.router.navigate(['/cCoupon']);
  }
  exportExcel() {
    let CouponList = this.GetCouponList();
    this.exportUtility.exportExcel(CouponList, 'Couopn', 'xlsx');
  }

  ExportToPdf() {
    let CouponList = this.GetCouponList();
    this.exportUtility.ExportToPdf(CouponList, 'Coupon.pdf');
  }

  GetCouponList() {
    if (!this._couponviews || !this._couponviews.length)
      return;
    let CouponList = [];
    this._couponviews.forEach(element => {
      let coupon: any = {};
      coupon["Coupon Code"] = element.couponcode;
      coupon["Coupon Name"] = element.couponname;
      coupon["From Date"] = element.fromdate;
      coupon["To Date"] = element.todate;
      coupon["Coupon Limit"] = element.usagelimitpercoupon;
      coupon["Coupon Usage"] = element.currentusagelimitpercoupon ||0;
      coupon["Status"] = element.couponstatusname;
      CouponList.push(coupon);
    });
    return CouponList;
  }
}
