<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_changepasswordform" (ngSubmit)="onSave()" autocomplete="off">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3> Change Password</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple title="Save" type="submit" icon="pi pi-save"></button>
                                <!--16. clear all the fields which the data has entered -->
                                <button pButton pRipple title="Clear" icon="pi pi-trash" type="button"
                                    class="p-button-danger" (click)="reset($event)"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Username" [filter]="true"
                                        formControlName="username" optionValue="username" optionLabel="username">
                                    </p-dropdown>
                                    <label for="username">User Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _changepasswordform.get('username').touched) && _changepasswordform.get('username').errors?.SelectUser">
                                    Please select user name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!-- <input type="password" formControlName="oldpassword" pInputText autocomplete="off"> -->
                                    <p-password type="password" formControlName="oldpassword" maxlength="20">
                                        <!-- <ng-template pTemplate="header">
                                            <h6>Pick a password</h6>
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <p-divider></p-divider>
                                            <p class="p-mt-2">Suggestions</p>
                                            <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                                                <li>At least one lowercase</li>
                                                <li>At least one uppercase</li>
                                                <li>At least one numeric</li>
                                                <li>At least one special character</li>
                                                <li>Minimum 8 characters</li>
                                            </ul>
                                        </ng-template> -->
                                    </p-password>
                                    <label for="oldpassword">Old Password <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 6.if old password is not given, then show error as "please enter old password" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _changepasswordform.get('oldpassword').touched) && _changepasswordform.get('oldpassword').errors?.OldPasswordRequired">
                                    Please enter old password
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _changepasswordform.get('oldpassword').touched) && _changepasswordform.get('oldpassword').errors?.OldPasswordMustHave8Characters">
                                    Password should not exceed 20 characters
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!-- <input type="password" formControlName="newpassword" pInputText> -->
                                    <p-password formControlName="newpassword" maxlength="20">
                                        <ng-template pTemplate="header">
                                            <h6>Pick a password</h6>
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <p-divider></p-divider>
                                            <p class="p-mt-2">Suggestions</p>
                                            <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                                                <li>At least one lowercase</li>
                                                <li>At least one uppercase</li>
                                                <li>At least one numeric</li>
                                                <li>At least one special character</li>
                                                <li>Minimum 8 characters</li>
                                                <li>Maximum 20 characters</li>
                                            </ul>
                                        </ng-template>
                                    </p-password>
                                    <label for="newpassword">New Password <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- (focusout)="Changepassword()" -->
                                <!--7. if new password is not given, then show error as "please enter new password" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _changepasswordform.get('newpassword').touched) && _changepasswordform.get('newpassword').errors?.NewPasswordRequired">
                                    Please enter New password
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _changepasswordform.get('newpassword').touched) && _changepasswordform.get('newpassword').errors?.ValidPassword">
                                    Please enter Valid Password
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="password" name="retypepassword" formControlName="retypepassword"
                                        pInputText>
                                    <label for="retypepassword">Retype Password <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- if Re-type password is not given, then show error as "please enter Re-type  password" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _changepasswordform.get('retypepassword').touched) && _changepasswordform.get('retypepassword').errors?.RetypePasswordRequired">
                                    Please enter retype password
                                </span>
                                <!-- if Retype password length is below 8, show error as "Retype password must have 8 characters" -->
                                <!-- <span class="text-danger"
                            *ngIf="(_submitted || _changepasswordform.get('retypepassword').touched) && _changepasswordform.get('retypepassword').errors?.RetypePasswordMustHave8Characters">
                            Retype Password Must Have 8 Characters
                        </span> -->

                            </td>
                            <td style="width: 20%;">
                                <!-- Debug -->
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </tr>
                    </table>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>