import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CDocumentverificationComponent } from './documentverification/c-documentverification/c-documentverification.component';
import { CPurchaseinvoiceComponent } from './purchaseinvoice/c-purchaseinvoice/c-purchaseinvoice.component';
import { VPurchaseinvoiceComponent } from './purchaseinvoice/v-purchaseinvoice/v-purchaseinvoice.component';
import { CPurchaseorderComponent } from './Purchaseorder/c-purchaseorder/c-purchaseorder.component';
import { VPurchaseorderComponent } from './Purchaseorder/v-purchaseorder/v-purchaseorder.component';
import { CPurchasereturnComponent } from './purchasereturn/c-purchasereturn/c-purchasereturn.component';
import { VPurchasereturnComponent } from './purchasereturn/v-purchasereturn/v-purchasereturn.component';
import { CQuotefinalizeComponent } from './quotefinalize/c-quotefinalize/c-quotefinalize.component';
import { VQuotefinalizeComponent } from './quotefinalize/v-quotefinalize/v-quotefinalize.component';
import { CRequestforquoteComponent } from './requestforquote/c-requestforquote/c-requestforquote.component';
import { VRequestforquoteComponent } from './requestforquote/v-requestforquote/v-requestforquote.component';
// import { CRequestforquoteComponent } from './requestforquote/c-requestforquote/c-requestforquote.component';
// import { VRequestforquoteComponent } from './requestforquote/v-requestforquote/v-requestforquote.component';
const routes: Routes =
    [
        { path: 'cRequestForQuote', component: CRequestforquoteComponent },
        { path: 'vRequestForQuote', component: VRequestforquoteComponent },
        //Deepak
        { path: 'cQuotefinalize', component: CQuotefinalizeComponent },
        { path: 'vQuotefinalize', component: VQuotefinalizeComponent },
        { path: 'cPurchaseOrder', component: CPurchaseorderComponent },
        { path: 'vPurchaseOrder', component: VPurchaseorderComponent },
        { path: 'cPurchaseinvoice', component: CPurchaseinvoiceComponent },
        { path: 'vPurchaseinvoice', component: VPurchaseinvoiceComponent },
        { path: 'vPurchasereturn', component: VPurchasereturnComponent },
        { path: 'cPurchasereturn', component: CPurchasereturnComponent },
        { path:'cDocumentverification', component: CDocumentverificationComponent}
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PurchaseRoutingModule { }
