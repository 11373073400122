<p-toast></p-toast>
<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Online Payment Status</h3>
                    </div>
                    <div class="col-md-7 text-right">
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_paymenttransactionform">
                    <table class="normal-table">
                        <tr>
                            <td style="width:20%">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="PaymentStatus"
                                        formControlName="paymentstatus" optionValue="metasubdescription"
                                        optionLabel="metasubdescription"></p-dropdown>
                                    <label for="dropdown">Payment Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _paymenttransactionform.get('paymentstatus').touched) && _paymenttransactionform.get('paymentstatus').errors?.SelectOrderStatus">
                                    Please Select Payment Status
                                </span>
                            </td>
                            <td style="width:20%">
                                <span class="p-float-label">
                                    <p-calendar dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="1900:2050" formControlName="fromdate" [showIcon]="true">
                                    </p-calendar>
                                    <label for="inputtext">From Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _paymenttransactionform.get('fromdate').touched) && _paymenttransactionform.get('fromdate').errors?.EffectiveFromRequired">
                                    Please Select From Date
                                </span>
                            </td>
                            <td style="width:20%">
                                <span class="p-float-label">
                                    <p-calendar dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="1900:2050" formControlName="todate" [showIcon]="true">
                                    </p-calendar>
                                    <label for="inputtext">To Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _paymenttransactionform.get('todate').touched) && _paymenttransactionform.get('todate').errors?.EffectiveToRequired">
                                    Please Select To Date
                                </span>
                            </td>
                            <!-- <td style="width:20%">
                        <span class="p-float-label">
                            <p-dropdown [options]="viewPaymentModeList" [(ngModel)]="viewPaymentMode" optionLabel="viewPaymentMode"></p-dropdown>
                            <label for="dropdown">Payment Mode By</label>
                        </span>
                    </td> -->

                            <td style="width:20%"></td>
                            <td style="width:20%"></td>

                        </tr>

                        <tr>
                            <td>
                                <button pButton pRipple label="Search" type="button" (click)="search()" title="Search"
                                    icon="pi pi-search"></button>
                                <button pButton pRipple label="Clear" title="Clear" (click)="clear()" icon="pi pi-times"
                                    class="p-button-danger"></button>
                            </td>

                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="las la-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="las la-file-csv" title="Export CSV"
                                class="p-mr-2" pTooltip="CSV" (click)="exportUtility.exportCSV(dt)"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" (click)="exportExcel()"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" (click)="ExportToPdf()"
                                tooltipPosition="bottom"></button>
                        </ng-template>
                    </p-toolbar>

                    <p-table #dt [rows]="20" [value]="paymentHistoryGrid" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [globalFilterFields]="['transactionid','orderno','amountinusd','paymentdate','paymentmode','paymentstatus','customername']"
                        [rowHover]="true" dataKey="transactionid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                
                                <th pSortableColumn="transactionid">Transaction ID <p-sortIcon field="transactionid"></p-sortIcon>
                                </th>
                                <th pSortableColumn="orderno">Order No.<p-sortIcon field="orderno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="amountinusd">Amount (in USD)<p-sortIcon field="amountinusd"></p-sortIcon>
                                </th>
                                <th pSortableColumn="paymentdate">Payment Date & Time <p-sortIcon field="paymentdate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="paymentmode">Payment Mode <p-sortIcon field="paymentmode"></p-sortIcon>
                                </th>
                                <th pSortableColumn="paymentstatus">Payment Status<p-sortIcon field="paymentstatus"></p-sortIcon>
                                </th>
                                <th pSortableColumn="customername">Customer Name<p-sortIcon field="customername"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_paymentHistoryGrid>
                            <tr>
                                <td>
                                    {{_paymentHistoryGrid.transactionid}}
                                </td>
                                <td>
                                    {{_paymentHistoryGrid.orderno}}
                                </td>
                                <td class="text-right">
                                    {{_paymentHistoryGrid.amountinusd}}
                                </td>
                                <td>
                                    {{_paymentHistoryGrid.paymentdate|
                                    date:'dd/MM/yyyy HH:mm:ss a'}}
                                </td>
                                <td>
                                    {{_paymentHistoryGrid.paymentmode}}
                                </td>
                                <td>
                                    {{_paymentHistoryGrid.paymentstatus}}
                                </td>
                                <td>
                                    {{_paymentHistoryGrid.customername}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>