import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';


@Injectable({
  providedIn: 'root'
})
export class UserdiscountreportService {
  public _Action: IAction;
  public _httpOptions;
  public _RptUserDiscountService = environment.UserDiscountService;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnload(pageonload: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptUserDiscountService + '/PageOnLoad';

    return this.http.post<{ data: any }>(Query, pageonload, { headers });
  }
  SearchBranchDetails(branchdetail: object) {
    const headers = this.header;
    var Query = this._RptUserDiscountService + '/Detail';

    return this.http.post<{ data: any }>(Query, branchdetail, { headers });
  }
}
