import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { SwipeService } from 'src/app/core/Services/sales/swipe.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-salesmultiswipe',
  templateUrl: './salesmultiswipe.component.html',
  styleUrls: ['./salesmultiswipe.component.css']
})
export class SalesmultiswipeComponent implements OnInit, OnDestroy {

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  _IsProgressSpinner = false;
  _swipeForm: FormGroup;
  _swipeDetail: any[] = [];
  _currencyUsd: any = {};
  _currencyBank: any = {};
  paymentModel: any = {};
  _salesinvoicenos: any[] = [];
  _currencyname = "Bank";
  _paymentMode = "Card";
  _branchid: number;
  _salesinvoiceno: string;
  _userid = sessionStorage["userid"];

  swipe = {
    swipeid: 0,
    swipeamount: 0,
    swipeamount_usd: 0,
    rrn: '',
    ref: '',
    imei: '',
    pan: '',
    createdby: sessionStorage["userid"],
    createdon: new Date()
  };



  constructor(private router: Router, private location: Location, private fb: FormBuilder, private swipeService: SwipeService,
    private exceptionService: CustomExceptionService) { }

  ngOnInit(): void {
    this._branchid = history.state?.branchid || null;
    this._salesinvoiceno = history.state?.salesinvoiceno || null;

    this.Initialize();

    if (!this._salesinvoiceno || !this._branchid) { this.goBack(); return; }

    this.GetSalesinvoiceData();
  }

  Initialize() {
    this._swipeForm = this.fb.group({
      swipeid: [0],
      salesinvoiceid: [0],
      salesinvoiceno: { value: this._salesinvoiceno, disabled: true },
      currencyname: { value: this._currencyname, disabled: true },
      paymentmode: { value: this._paymentMode, disabled: true },
      salesinvoiceamount: { value: 0, disabled: true },
      customernetamount: { value: 0, disabled: true },
      swipedamount: { value: 0, disabled: true }
    });
  }

  GetSalesinvoiceData() {
    let data = {
      salesinvoiceno: this._salesinvoiceno,
      branchid: this._branchid
    }
    this._IsProgressSpinner = true;
    this.swipeService.GetSalesInvoiceData(data).subscribe((result: any) => {
      if (result.tranStatus && result.tranStatus.result) {
        let swipehdr = result.salesinvoiceMultiSwipe;
        if (swipehdr) {
          this._swipeForm.get("swipeid").setValue(swipehdr.swipeid || 0);
          this._swipeForm.get("salesinvoiceid").setValue(swipehdr.salesinvoiceid || 0);
          this._swipeForm.get("salesinvoiceamount").setValue(swipehdr.salesinvoiceamount);
          this._swipeForm.get("customernetamount").setValue(swipehdr.customernetamount);
          this._swipeForm.get("swipedamount").setValue(swipehdr.swipedamount);

          this._swipeDetail = result.salesinvoiceMultiSwipeDetailList || [];
          this._currencyUsd = result.currencyUsd;
          this._currencyBank = result.currencyBank;

          this.defaultRow();
        }
      }
      else {
        if (result.tranStatus && result.tranStatus.lstErrorItem && result.tranStatus.lstErrorItem.length)
          this.exceptionService.handleError(result.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this.exceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onSwipeAmountChange(value: any) {

    if (!this._currencyUsd || !this._currencyBank) return;

    let customerNetAmount = parseFloat(this._swipeForm.get("customernetamount").value || 0);

    if (!customerNetAmount) {
      value.swipeamountUsd = value.swipeamount = 0;
      this.exceptionService.handleError('Customer Net amount should not be zero.');
      return;
    }

    let sumOfSwipeAmount = this.SumOfSwipeAmount();
    if (sumOfSwipeAmount > customerNetAmount) {
      value.swipeamount = value.swipeamount - (sumOfSwipeAmount - customerNetAmount);
      this.exceptionService.handleError('Swipe amount not exceed to customer net amount.');
    }
    //note
    value.swipeamountUsd = value.swipeamount / this._currencyBank.exchangerate;
  }

  OnAdd(value: any) {
    if (!this._swipeDetail)
      this._swipeDetail = [];

    let hasZero = this._swipeDetail.some(s => parseFloat(s.swipeamount || 0) == 0)

    if (hasZero) {
      this.exceptionService.handleError('Swipe amount must be greater than zero.');
      return
    }

    let hasSwiped = this._swipeDetail.some(s => s.rrn == '');
    if (hasSwiped) {
      this.exceptionService.handleError('Please Swipe first then add other amounts.');
      return
    }

    let sumOfSwipeAmount = this.SumOfSwipeAmount();

    let customerNetAmount = parseFloat(this._swipeForm.get("customernetamount").value || 0);

    if (!customerNetAmount) return;

    if (sumOfSwipeAmount == customerNetAmount) {
      this.exceptionService.handleError('Maximum amount limit reached.');
      return;
    }

    if (sumOfSwipeAmount > customerNetAmount) {
      this.exceptionService.handleError('Swipe amount in grid should not exceed Customer net amount');
      return;
    }

    let swipe = Object.create(this.swipe, {});
    swipe.swipeamount = customerNetAmount - sumOfSwipeAmount;
    this._swipeDetail.push(swipe);
    this.onSwipeAmountChange(swipe);
  }

  OnRemove(value: any) {

    if (value.rrn || this._swipeDetail.length == 1) return;

    let index = this._swipeDetail.indexOf(value);
    if (index > -1) this._swipeDetail.splice(index, 1);

  }

  OnSwipe(value: any) {
    let sumOfSwipeAmount = this.SumOfSwipeAmount();

    let customerNetAmount = parseFloat(this._swipeForm.get("customernetamount").value || 0);

    if (!customerNetAmount) return;

    if (sumOfSwipeAmount > customerNetAmount) {
      this.exceptionService.handleError('Swipe amount in grid should not exceed Customer net amount');
      return;
    }

    let swipedAmount = parseFloat(this._swipeForm.get("swipedamount").value || 0) + parseFloat(value.swipeamount || 0)


    let swipe = {
      swipeid: this._swipeForm.get("swipeid").value || 0,
      salesinvoiceid: this._swipeForm.get("salesinvoiceid").value,
      salesinvoiceno: this._salesinvoiceno,
      salesinvoicedate: new Date(),
      salesinvoiceamount: this._swipeForm.get("salesinvoiceamount").value,
      customernetamount: this._swipeForm.get("customernetamount").value,
      swipedamount: swipedAmount,
      createdby: this._userid,
      createdon: new Date(),
      modifiedby: 0,
      modifiedon: new Date(),
    }

    let swipedetail = {
      swipeid: this._swipeForm.get("swipeid").value || 0,
      swipedetailid: 0,
      swipeamount: value.swipeamount,
      swipeamountUsd: value.swipeamountUsd,
      rrn: '00125421',//value.rrn,
      ref: 'jshdtgs',//value.ref,
      imei: '438757',//value.imei,
      pan: '9843579459',//value.pan,
      createdby: this._userid,
      createdon: new Date()
    }

    let data = {
      swipe, swipedetails: [swipedetail]
    };
    this.OnSwipeCall(value.swipeamount, (res) => {
      this.OnSave(data, res);
    });
  }

  OnSwipeCall(amount: number, callback?: Function) {
    this.paymentModel.visiblePaymentPopup = true;
    this.paymentModel.isHideCancelRequestButton = true;
    this.paymentModel.Message = "Payment request processing..."

    this.paymentModel.CancelRequest = () => {
      //this.paymentModel.isHideCancelRequestButton = false;
      this.CancelPendingRequestPaymentGateWay();
      this._IsProgressSpinner = false;
      this.paymentModel.visiblePaymentPopup = false;
    };

    setTimeout(() => {
      this.paymentModel.isHideCancelRequestButton = false;
    }, 10000);

    let username = sessionStorage["username"];

    let doller = 0,
      cent = 0;

    // let salesAmount = amount.toString();

    // if (salesAmount.indexOf('.') != -1) {
    //   doller = parseInt(salesAmount.split('.')[0]);
    //   cent = parseInt((<string>salesAmount.split('.')[1]).substr(0, 2));
    // }
    // else {
    //   doller = parseInt(salesAmount);
    // }
    doller = amount;
    let cents = (doller * 100).toFixed(0);

    //let cents = (doller * 100) + cent;

    var currencys = this._swipeForm.get("currencyname").value;
    var type: string = ""
    if (currencys == "USD") {
      type = "USD"
    }
    else {
      type = "ZWG"
    }

    let obj = {
      saleAmount: cents.toString(),
      cashBack: "0",
      posUser: username,
      tenderType: "SWIPE",
      currency: type,
      transactionId: this._salesinvoiceno
    };
    let message = "";
    this._IsProgressSpinner = true;

    this.swipeService.GetPaymentApiDetails(obj).pipe(takeUntil(this.ngUnsubscribe)).subscribe((p_result: any) => {
      let json = JSON.stringify(p_result);
      this._IsProgressSpinner = false;
      this.paymentModel.visiblePaymentPopup = false;

      if (p_result && p_result.code != "00") {
        message = 'Payment failed with ' + p_result.description + '.';
        this.exceptionService.handleError(message);
        return;
      }

      if (callback)
        callback(json);
    },
      error => {
        this.paymentModel.visiblePaymentPopup = false;
        this.paymentModel.isHideCancelRequestButton = false;
        this._IsProgressSpinner = false;
        message = 'Payment failed.';
        this.exceptionService.handleError(message);
        this.exceptionService.handleError(error);
      });
  }

  CancelPendingRequestPaymentGateWay() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  OnSave(data: any, responseJson: string) {
    this._IsProgressSpinner = true;
    this.swipeService.CreateOrUpdate(data).subscribe((result: any) => {
      if (result.tranStatus && result.tranStatus.result) {
        this.exceptionService.handleSuccess("Swipe success.");

        this.GetSalesinvoiceData();

        if (result.isfullpaymentStatus == true) {
          window.open("pos-printer-service:$" + this._swipeForm.get("salesinvoiceid").value + "$" + this._salesinvoiceno + "$" + this._userid + "$" + responseJson);
        }
      }
      else {
        if (result.tranStatus && result.tranStatus.lstErrorItem && result.tranStatus.lstErrorItem.length)
          this.exceptionService.handleError(result.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this.exceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  goBack() {
    this.location.back();
  }

  private SumOfSwipeAmount(): number {
    if (!this._swipeDetail || !this._swipeDetail.length) return 0;

    let sum = 0;
    this._swipeDetail.forEach(element => {
      sum += parseFloat(element.swipeamount)
    });

    return sum;
  }

  defaultRow() {
    if (!this._swipeDetail || !this._swipeDetail.length)
      this._swipeDetail = [];
    else return;
    let customerNetAmount = parseFloat(this._swipeForm.get("customernetamount").value || 0);

    let swipe = Object.create(this.swipe, {});
    swipe.swipeamount = customerNetAmount;
    this._swipeDetail.push(swipe);
    this.onSwipeAmountChange(swipe);
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  public ngOnDestroy(): void {
    this.CancelPendingRequestPaymentGateWay();
  }

}
