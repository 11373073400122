export class Couponexcludeproductdetail {
    coupondetailid: number;
    couponid: number;
    excludeproductid: number;
    createdby: number;
    createdon: Date | string;
    modifiedby: number | null;
    modifiedon: Date | string | null;

    
}