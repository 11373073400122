<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Service Product Delivery</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                [disabled]="_IsSaveDisable" (click)="onSave()"></button>
                            <button pButton pRipple type="button" (click)="reset()" title="Clear" icon="pi pi-trash"
                                class="" class="p-button-danger" [disabled]="_IsSaveDisable"></button>
                            <button pButton pRiple type="button" (click)="goBack()" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_serviceproductdeliveryform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="serviceproductdeliveryno">
                                    <label>Delivery Code</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="serviceproductdeliverydate" [minDate]="mindate" [maxDate]="maxdate" 
                                        dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="serviceproductdeliverydate"> Delivery Date <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _serviceproductdeliveryform.get('serviceproductdeliverydate').touched) && _serviceproductdeliveryform.get('serviceproductdeliverydate').errors?.SelectPaymentDate">
                                    Please Select Delivery Date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_customer" optionLabel="customername" [filter]="true"
                                        filterBy="customername" optionValue="customerid" formControlName="customerid"
                                        (onChange)="GetServiceRequestNo($event)" [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="servicerequestno">Customer Name<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _serviceproductdeliveryform.get('customerid').touched) && _serviceproductdeliveryform.get('customerid').errors?.SelectAccountgroup">
                                    Please Select Customer Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_servicerequest" optionLabel="servicerequestnumber"
                                        optionValue="servicerequestnumber" formControlName="servicerequestno"
                                        (onChange)="Getinvoiceno()" [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="servicerequestno">Service Request<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _serviceproductdeliveryform.get('servicerequestno').touched) && _serviceproductdeliveryform.get('servicerequestno').errors?.SelectAccountgroup">
                                    Please Select Service Request
                                </span>
                            </td>
                            <td style="width: 20%;">

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="invoiceno">
                                    <label>Invoice No</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="invoicedate" [disabled]=true
                                        dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="invoicedate"> Invoice Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="serviceworkorderno">
                                    <label>Service Work order No</label>
                                </span>
                            </td>
                            <td rowspan="2">
                                <span class="p-float-label">
                                    <textarea rows="3" name="remarks" formControlName="remarks"
                                        pInputTextarea></textarea>
                                    <label for="remarks">Remarks </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_currency" optionLabel="currencyname"
                                        optionValue="currencyid" formControlName="currencyid" (onChange)="getversion()"
                                        [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="currencyid">Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _serviceproductdeliveryform.get('currencyid').touched) && _serviceproductdeliveryform.get('currencyid').errors?.SelectAccountgroup">
                                    Please Select Currency
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-inputNumber formControlName="approximateamountinusd" autocomplete="off"
                                        maxlength="13" (onBlur)="allocatePaymentAmount()" mode="decimal"
                                        [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                                    <label for="approximateamountinusd"> Service Cost <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _serviceproductdeliveryform.get('approximateamountinusd').touched) && _serviceproductdeliveryform.get('approximateamountinusd').errors?.PaymentAmountRequired">
                                    Please Entre Delivery Cost
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="vattype" formControlName="vatid"
                                        [disabled]='isexternal' optionLabel="taxname" optionValue="taxid"
                                        (onChange)="getvat()">
                                    </p-dropdown>
                                    <label for="name"> VAT Type <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <!-- <td>
                                <span class="p-float-label">
                                    <input type="number" name="vatAmount" disabled formControlName="vatamount"
                                        pInputText>
                                    <label for="vatAmount"> VAT Amount </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_othercharges"
                                        formControlName="ocid" optionLabel="otherchargename" [disabled]='isexternal'
                                        (onChange)="getchargesid()" optionValue="otherchargeid">
                                    </p-dropdown>
                                    <label for="name"> Other Charges <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-inputNumber formControlName="ocvalue" autocomplete="off"
                                        (onInput)="getothercharges()" maxlength="13" (onFocus)="getothercharges()"
                                        (onBlur)="getothercharges()" mode="decimal" [minFractionDigits]="2"
                                        [maxFractionDigits]="2"></p-inputNumber>
                                    <label for="ocValue"> Other Charges Amount </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" name="netamount" disabled formControlName="netamount"
                                        pInputText>
                                    <label for="netamount"> Net Amount </label>
                                </span>
                            </td> -->
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_serviceproductdeliverydetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['productname','pruchaseorinvoicedate','srquantity','uomdesc','unitprice','finalamount']"
                        [rowHover]="true" dataKey="productid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="productname">Product Name <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="uomdesc">UOM <p-sortIcon field="uomdesc">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="unitprice">Unit Price<p-sortIcon field="unitprice"></p-sortIcon>
                                </th>
                                <th pSortableColumn="finalamount">Final Amount <p-sortIcon field="finalamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="srquantity"> Service Request Quantity <p-sortIcon
                                        field="srquantity">
                                    </p-sortIcon>
                                <th pSortableColumn="srquantity">Delivery Quantity <p-sortIcon field="srquantity">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_serviceproductdeliverydetail>
                            <tr>
                                <td>
                                    {{_serviceproductdeliverydetail.productname }}
                                </td>

                                <td>
                                    {{_serviceproductdeliverydetail.uomdesc }}
                                </td>
                                <td>
                                    {{_serviceproductdeliverydetail.unitprice }}
                                </td>
                                <td>
                                    {{_serviceproductdeliverydetail.finalamount}}
                                </td>
                                <td>
                                    {{_serviceproductdeliverydetail.srquantity}}
                                </td>
                                <td>
                                    {{_serviceproductdeliverydetail.deliveryquantity}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Other Charges</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <div class="card">
                            <p-table [value]="_OtherCharges" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['ocid','ocvalue']"
                                [rowHover]="true" dataKey="ocid"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedOtherChargesRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:100px;text-align: center;">Action</th>
                                        <th>Description</th>
                                        <th>Value</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_othercharge>
                                    <tr>
                                        <td style="text-align:center">
                                            <button pButton pRipple title="Add" icon="pi pi-plus"
                                                class="p-button-success p-mr-2" [disabled]="isDisabledother"
                                                (click)="AddOtherCharges($event)"></button>
                                            <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                class="p-button-danger" [disabled]="isDisabledremove"
                                                (click)="RemoveOtherCharges(_othercharge)"></button>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown #id [autoDisplayFirst]="false" [options]="_Descriptions"
                                                        *ngIf="_action != 'view'" optionLabel="otherchargename"
                                                        optionValue="otherchargeid" [(ngModel)]="_othercharge.ocid"
                                                        (onChange)="OnchangeOtherCharge(_othercharge,$event)">
                                                    </p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                        [options]="_Descriptions" optionLabel="otherchargename"
                                                        optionValue="otherchargeid" [(ngModel)]="_othercharge.ocid">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" pInputText name="ocvalue"
                                                        (keyup)="OnChangeotherCharge(_othercharge,$event)"
                                                        [(ngModel)]="_othercharge.ocvalue" *ngIf="_action != 'view'">
                                                    <div *ngIf="_action == 'view'">{{_othercharge.ocvalue}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_othercharge.ocvalue}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div>
                            <div class="row">
                                <div class="col-md-5">
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <form [formGroup]="_serviceproductdeliveryform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" name="vatAmount" disabled formControlName="vatamount"
                                                pInputText>
                                            <label for="vatAmount"> VAT Amount </label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-inputNumber formControlName="ocvalue" autocomplete="off"
                                                (onInput)="getothercharges()" maxlength="13"
                                                (onFocus)="getothercharges()" (onBlur)="getothercharges()"
                                                mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
                                            </p-inputNumber>
                                            <label for="ocValue"> Other Charges Amount </label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" name="netamount" disabled formControlName="netamount"
                                                pInputText>
                                            <label for="netamount"> Net Amount </label>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>