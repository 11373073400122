import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CDamagedproductstockComponent } from './Damagedproductstock/c-damagedproductstock/c-damagedproductstock.component';
import { CDeliveryChallanComponent } from './Delivery-Note/c-delivery-challan/c-delivery-challan.component';
import { VDeliveryChallanComponent } from './Delivery-Note/v-delivery-challan/v-delivery-challan.component';
import { CGoodsReceiptNoteComponent } from './GRN/c-goods-receipt-note/c-goods-receipt-note.component';
import { VGoodsReceiptNoteComponent } from './GRN/v-goods-receipt-note/v-goods-receipt-note.component';
import { CStockTransferOrderComponent } from './stock-transfer-order/c-stock-transfer-order/c-stock-transfer-order.component';
import { VStockTransferOrderComponent } from './stock-transfer-order/v-stock-transfer-order/v-stock-transfer-order.component';
import { CStockadjustmentComponent } from './StockAdjustment/c-stockadjustment/c-stockadjustment.component';
import { VStockadjustmentComponent } from './StockAdjustment/v-stockadjustment/v-stockadjustment.component';
import { CStockallocationComponent } from './Stockallocation/c-stockallocation/c-stockallocation.component';
import { VStockallocationComponent } from './Stockallocation/v-stockallocation/v-stockallocation.component';
import { CStockdeliveryComponent } from './StockDelivery/c-stockdelivery/c-stockdelivery.component';
import { VStockdeliveryComponent } from './StockDelivery/v-stockdelivery/v-stockdelivery.component';
import { CStockinwardComponent } from './StockInward/c-stockinward/c-stockinward.component';
import { VStockinwardComponent } from './StockInward/v-stockinward/v-stockinward.component';
import { CTripsheetComponent } from './TripSheet/c-tripsheet/c-tripsheet.component';
import { CTripstartComponent } from './Tripstart/c-tripstart/c-tripstart.component';
import { VTripstartComponent } from './Tripstart/v-tripstart/v-tripstart.component';
import { CProductFreezingComponent } from './Product-Freezing/c-product-freezing/c-product-freezing.component';
import { VProductRequestComponent } from './Product-Request/v-product-request/v-product-request.component';
import { CProductRequestComponent } from './Product-Request/c-product-request/c-product-request.component';
import { ProductPriceUploadComponent } from './product-price-upload/product-price-upload.component';
const routes: Routes = [
   //Deepak
   { path: 'cStockinward', component: CStockinwardComponent },
   { path: 'vStockinward', component: VStockinwardComponent },
   {path:'cStockTransferOrder',component:CStockTransferOrderComponent},
  {path:'vStockTransferOrder',component:VStockTransferOrderComponent},
  { path: 'cDamagedproductstock', component: CDamagedproductstockComponent },
  { path: 'cGoodsReceiptNote', component: CGoodsReceiptNoteComponent },
  { path: 'vGoodsReceiptNote', component: VGoodsReceiptNoteComponent },
  { path: 'cTripstart', component: CTripstartComponent },
  { path: 'vTripstart', component: VTripstartComponent },
  { path: 'cstockallocation', component: CStockallocationComponent },
  { path: 'vstockallocation', component: VStockallocationComponent },
  { path: 'cStockAdjustment', component: CStockadjustmentComponent },
  { path: 'vStockAdjustment', component: VStockadjustmentComponent },
  { path: 'cStockDelivery', component: CStockdeliveryComponent },
  { path: 'vStockDelivery', component: VStockdeliveryComponent },
  { path: 'cDeliveryNote', component: CDeliveryChallanComponent },
  { path: 'vDeliveryNote', component: VDeliveryChallanComponent },
  { path: 'ctripsheet', component: CTripsheetComponent },
  {path:'ProductFreezing',component:CProductFreezingComponent},
  {path:'vProductRequest',component:VProductRequestComponent},
  {path:'cProductRequest',component:CProductRequestComponent},
  {path:'ProductPriceUpload',component:ProductPriceUploadComponent},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoryRoutingModule { }
