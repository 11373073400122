import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
@Injectable({
	providedIn: 'root'
})
export class JobcardService {
	public _Action: IAction;
	public _jobcardService = environment.JobcardService;
	public _httpOptions;
	token: any;
	header: any;

	constructor(private http: HttpClient) {
		this.token = sessionStorage['AuthToken'];
		this.header = this.createHeaders(this.token)
	}
	createHeaders(token: any): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`, // Include your token here	
		});
	}
	PageOnLoad(obj: object): Observable<any> {
		const headers = this.header;
		var Query = this._jobcardService + '/PageOnload';
		return this.http.post(Query, obj, { headers });
	}
	GetSRDetail(obj: object): Observable<any> {
		const headers = this.header;
		var Query = this._jobcardService + '/GetSRDetail';
		return this.http.post(Query, obj, { headers });
	}
	create(obj: object): Observable<any> {
		const headers = this.header;
		var Query = this._jobcardService + '/Create';
		return this.http.post(Query, obj, { headers });
	}
	edit(obj: object): Observable<any> {
		const headers = this.header;
		var Query = this._jobcardService + '/Update';
		return this.http.post(Query, obj, { headers });
	}
	Delete(obj: object): Observable<any> {
		const headers = this.header;
		var Query = this._jobcardService + '/Delete';
		return this.http.post(Query, obj, { headers });
	}
	FetchAll(obj: object): Observable<any> {
		const headers = this.header;
		var Query = this._jobcardService + '/FetchAll';
		return this.http.post(Query, obj, { headers });
	}
	Print(obj: any) {
		const headers = this.header;
		var Query = this._jobcardService + '/JobCardPrint';
		return this.http.post(Query, obj, { responseType: 'blob', headers});
	}
}