<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Lay Buy</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save" (click)="onSave()" [disabled]="_isSaveHide"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear" icon="pi pi-trash" class="" class="p-button-danger" [disabled]="_isClearHide"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search" title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-tabView [(activeIndex)]="selectedtab">
                    <p-tabPanel header="Laybuy Detail">
                        <form [formGroup]="_LaybuyHeaderform">
                            <table>
                                <tr>
                                    <td style="width:60%">
                                        <table class="normal-table">
                                            <tr>
                                                <td style="width:34%">
                                                    <span class="p-float-label">
                                                        <p-dropdown [options]="_Customers" [autoDisplayFirst]="false"
                                                            optionLabel="customername"
                                                            (onChange)="OnCustomerChange($event)"
                                                            optionValue="customerid" name="customerid" [filter]="true"
                                                            filterBy="customername" [virtualScroll]="true" itemSize="30"
                                                            formControlName="customerid">
                                                        </p-dropdown>
                                                        <label for="customerid"> Customer Name <span
                                                                class="hlt-txt">*</span></label>
                                                    </span>
                                                    <span class="text-danger" *ngIf="(_submitted || _LaybuyHeaderform.get('customerid').touched) && _LaybuyHeaderform.get('customerid').errors?.CustomerNameRequired">
                                                        please Select Customer Name
                                                    </span>
                                                </td>
                                                <td style="width:33%">
                                                    <span class="p-float-label">
                                                        <input type="number" pInputText name="mobileno"
                                                            formControlName="mobileno">
                                                        <label for="mobileno">Mobile No <span
                                                                class="hlt-txt">*</span></label>
                                                    </span>
                                                    <span class="text-danger" *ngIf="(_submitted || _LaybuyHeaderform.get('mobileno').touched) && _LaybuyHeaderform.get('mobileno').errors?.MobileNumberRequired">
                                                        please Enter Mobile No
                                                    </span>
                                                </td>
                                                <td style="width:33%">
                                                    <span class="p-float-label">
                                                        <input type="text" pInputText name="laybuycode"
                                                            formControlName="laybuycode" disabled>
                                                        <label for="laybuycode">Laybuy No</label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span class="p-float-label">
                                                        <p-dropdown [options]="_Currencies" [autoDisplayFirst]="false"
                                                            optionLabel="currencyname"
                                                            (onChange)="ChangeCurrencies($event)"
                                                            optionValue="currencyid" name="currencyid" [disabled]="true"
                                                            formControlName="currencyid">
                                                        </p-dropdown>
                                                        <label for="currencyid"> Currency <span
                                                                class="hlt-txt">*</span></label>
                                                    </span>
                                                    <span class="text-danger" *ngIf="(_submitted || _LaybuyHeaderform.get('currencyid').touched) && _LaybuyHeaderform.get('currencyid').errors?.CurrencyCodeRequired">
                                                        please Select Currency
                                                    </span>
                                                </td>
                                                <!-- <td>
                                                    <form [formGroup]="_LaybuyExchangerateDetailform">
                                                        <span class="p-float-label">
                                                            <input type="number" pInputText name="exchangerate"
                                                                formControlName="exchangerate" disabled>
                                                            <label for="exchangerate"> Exchange Rate </label>
                                                        </span>
                                                    </form>
                                                </td> -->
                                                <td>
                                                    <span class="p-float-label">
                                                        <p-calendar name="startdate" [showIcon]="true"
                                                            (onSelect)="OnFromDateSelect($event)" [minDate]="startDate"
                                                            formControlName="startdate" dateFormat="dd/mm/yy">
                                                        </p-calendar>
                                                        <label for="startdate">Start Date <span
                                                                class="hlt-txt">*</span></label>
                                                    </span>
                                                    <span class="text-danger" *ngIf="(_submitted || _LaybuyHeaderform.get('startdate').touched) && _LaybuyHeaderform.get('startdate').errors?.SelectDate">
                                                        please Select Start Date
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <p-calendar name="enddate" [showIcon]="true"
                                                            formControlName="enddate" dateFormat="dd/mm/yy"
                                                            [minDate]="minDate" [maxDate]="maxDate">
                                                        </p-calendar>
                                                        <label for="enddate">End Date</label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="number" pInputText name="productprice"
                                                            formControlName="productprice" disabled>
                                                        <label for="productprice">Product Price</label>
                                                    </span>
                                                </td>
                                                <td>
                                                    <form [formGroup]="_LaybuyExchangerateDetailform">
                                                        <span class="p-float-label">
                                                            <input type="number" pInputText name="paidamount"
                                                                formControlName="paidamount" disabled>
                                                            <label for="paidamount">Paid Amount</label>
                                                        </span>
                                                    </form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="number" pInputText name="currentpayment"
                                                            (keyup)="Calculation()" formControlName="currentpayment">
                                                        <label for="currentpayment">Current Payment <span
                                                                class="hlt-txt">*</span></label>
                                                    </span>
                                                    <span class="text-danger" *ngIf="(_submitted || _LaybuyHeaderform.get('currentpayment').touched) && _LaybuyHeaderform.get('currentpayment').errors?.CustomerNameRequired">
                                                        Current Payment Required.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="text" pInputText name="balance"
                                                            [(ngModel)]="balance" [ngModelOptions]="{standalone: true}"
                                                            disabled />
                                                        <label for="balance">Balance</label>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="text" pInputText name="transactionreferenceno"
                                                            formControlName="transactionreferenceno">
                                                        <label for="transactionreferenceno">Transaction Reference
                                                            no</label>
                                                    </span>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span class="p-float-label">
                                                        <p-calendar name="firstpaymentdate"
                                                            formControlName="firstpaymentdate" [showIcon]="false"
                                                            [disabled]=true dateFormat="dd/mm/yy"
                                                            [monthNavigator]="true">
                                                        </p-calendar>
                                                        <label for="firstpaymentdate">Initial Payment 30%</label>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <p-calendar name="secondpaymentdate"
                                                            formControlName="secondpaymentdate" [showIcon]="false"
                                                            [disabled]=true dateFormat="dd/mm/yy"
                                                            [monthNavigator]="true">
                                                        </p-calendar>
                                                        <label for="secondpaymentdate">2nd Payment 30%</label>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <p-calendar name="thirdpaymentdate"
                                                            formControlName="thirdpaymentdate" [showIcon]="false"
                                                            [disabled]=true dateFormat="dd/mm/yy"
                                                            [monthNavigator]="true">
                                                        </p-calendar>
                                                        <label for="thirdpaymentdate">3rd Payment 40%</label>
                                                    </span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="text" pInputText name="firstpaymentamount"
                                                            formControlName="firstpaymentamount" disabled>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="text" pInputText name="secondpaymentamount"
                                                            formControlName="secondpaymentamount" disabled>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <input type="text" pInputText name="thirdpaymentamount"
                                                            formControlName="thirdpaymentamount" disabled>
                                                    </span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="p-formgroup-inline">
                                                        <h6>Transaction Mode <span class="hlt-txt">*</span></h6>
                                                        <div class="p-field-radiobutton" *ngFor="let RM of _Modes">
                                                            <p-radioButton label={{RM.metasubdescription}} [disabled]="_isSaveHide" value={{RM.metasubcode}} formControlName="mode" (onClick)="OnChangeMode($event)">
                                                            </p-radioButton>
                                                        </div>
                                                    </div>
                                                    <span class="text-danger" *ngIf="(_submitted || _LaybuyHeaderform.get('mode').touched) && _LaybuyHeaderform.get('mode').errors?.CustomerNameRequired">
                                                        Please Select Mode
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <p-dropdown [options]="_toaccounts" [autoDisplayFirst]="false"
                                                            [filter]="true" optionLabel="name" optionValue="ledgerid"
                                                            name="toaccount" formControlName="toaccount">
                                                        </p-dropdown>
                                                        <label for="toaccount">To Account<span
                                                                class="hlt-txt">*</span></label>
                                                    </span>
                                                    <span class="text-danger" *ngIf="(_submitted || _LaybuyHeaderform.get('toaccount').touched) && _LaybuyHeaderform.get('toaccount').errors?.SelectToAccount">
                                                        Please Select Mode
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td rowspan="2">
                                                    <span class="p-float-label">
                                                        <textarea rows="3" formControlName="remarks1" name="remarks1"
                                                            pInputTextarea></textarea>
                                                        <label for="remarks1">Remarks 1 </label>
                                                    </span>

                                                </td>
                                                <td>
                                                    <span class="p-float-label">
                                                        <textarea rows="3" formControlName="remarks2" name="remarks2"
                                                            pInputTextarea></textarea>
                                                        <label for="remarks2">Remarks 2 </label>
                                                    </span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>

                                        </table>
                                    </td>
                                    <td style="width:40%;vertical-align:top;">
                                        <div class="sub-title">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <h5>Amount Details</h5>
                                                </div>
                                                <div class="col-md-7"></div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <p-toolbar styleClass="p-mb-4">
                                                <ng-template pTemplate="left">
                                                    <span class="p-input-icon-left">
                                                        <i class="pi pi-search"></i>
                                                        <input pInputText type="text"
                                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                            placeholder="Search..." />
                                                    </span>
                                                </ng-template>
                                            </p-toolbar>
                                            <p-table #dt [value]="_LaybuyAmountDetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['paymentdate','paidamount']" [rowHover]="true" dataKey="date" [(selection)]="selectedAmountDetail" (onRowSelect)="onRowSelect($event)"
                                                (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th style="text-align: center;">Action
                                                        </th>
                                                        <th style="width: 50px;text-align:center">SNo</th>
                                                        <th>Date</th>
                                                        <th>Paid Amount</th>
                                                        <th>Status</th>

                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-_LaybuyAmountDetail let-i="rowIndex">
                                                    <tr>
                                                        <td class="text-center">
                                                            <button pButton pRipple icon="las la-times" title="Cancel" class="p-button p-button-danger p-mr-2" [hidden]="_LaybuyAmountDetail.amountstatusid =='150' || _action == 'view'" (click)="confirm(_LaybuyAmountDetail)"></button>
                                                        </td>
                                                        <td style="width:50px" style="text-align:center">
                                                            {{i+ 1}}
                                                        </td>
                                                        <td>{{_LaybuyAmountDetail.paymentdate | date:'dd-MM-yyyy'}}</td>
                                                        <td>{{_LaybuyAmountDetail.paidamount}}</td>
                                                        <td>{{_LaybuyAmountDetail.amountstatusdesc}}</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </form>

                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Product Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <form [formGroup]="_LaybuyProductDetailform">
                            <table class="normal-table" *ngIf=" isdisable != 1 && _action != 'view'">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                <p-dropdown [options]="_ProductTypes" [autoDisplayFirst]="false" [filter]="true"
                                    optionLabel="producttypename" (onChange)="OnProductTypeChange($event)"
                                    optionValue="producttypeid" name="producttypeid" formControlName="producttypeid">
                                </p-dropdown>
                                <label for="producttypeid">Product Type</label>
                            </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                <p-dropdown [options]="_ProductCategories" [autoDisplayFirst]="false" [filter]="true"
                                    optionLabel="categoryname" optionValue="productcategoryid" name="productcategoryid"
                                    formControlName="productcategoryid">
                                </p-dropdown>
                                <label for="productcategoryid">Product Category</label>
                            </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                <!-- <p-dropdown [options]="_Products" [autoDisplayFirst]="false" optionLabel="productname"
                                    (onChange)="OnProductNameChange($event)" optionValue="productid" name="productid"
                                    formControlName="productid">
                                </p-dropdown> -->
                                <p-autoComplete formControlName="productid" [suggestions]="filteredProducts"
                                    (completeMethod)="filterProducts($event)" field="productname"
                                    (onSelect)="OnProductNameChange($event)">
                                    <ng-template let-product pTemplate="item">
                                        <div>{{product.productname}}</div>
                                    </ng-template>
                                </p-autoComplete>
                                <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_productsubmitted || _LaybuyProductDetailform.get('productid').touched) && _LaybuyProductDetailform.get('productid').errors?.SelectProductName">
                                Please Select Product Name
                            </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                <p-dropdown [options]="_Variants" [autoDisplayFirst]="false"
                                    optionLabel="variantdescription" optionValue="productvariantdetailid" name="variant"
                                    formControlName="variant">
                                </p-dropdown>
                                <label for="variant">Varient</label>
                            </span>
                                    </td>
                                    <td>


                                        <span class="p-float-label">
                                    <p-inputNumber *ngIf="hidequantity == true" formControlName="quantity" autocomplete="off" maxlength="14"
                                         mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                    </p-inputNumber>
                                    <label *ngIf="hidequantity == true" for="quantity">Quantity <span class="hlt-txt">*</span></label>
                                        </span>

                                        <span class="p-float-label">
                                    <p-inputNumber *ngIf="hidequantity != true" formControlName="quantity" autocomplete="off" maxlength="14"
                                         mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                    </p-inputNumber>
                                    <label *ngIf="hidequantity != true" for="quantity"> Quantity <span class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- <span class="p-float-label">
                                <input type="text" pInputText name="quantity" formControlName="quantity">
                                <label for="quantity">Quantity <span class="hlt-txt">*</span></label>
                            </span> -->
                                        <span class="text-danger" *ngIf="(_productsubmitted || _LaybuyProductDetailform.get('quantity').touched) && _LaybuyProductDetailform.get('quantity').errors?.QuantityRequired">
                                Please Enter Quantity
                            </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <button pButton pRipple label="Add" title="Add" icon="pi pi-plus" [hidden]="_action == 'view' || isdisableaddbutton == 1" (click)="onAddProductDetails($event)" class="p-button-success p-mr-2"></button>
                                        <button pButton pRipple label="Clear" (click)="Clear()" icon="pi pi-times" [hidden]="_action == 'view'" class="p-button-danger"></button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                </tr>
                            </table>
                        </form>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                        </span>
                                </ng-template>
                            </p-toolbar>
                            <p-table #dt [value]="_LaybuyProductDetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['productname','variant','quantity','price','totalamount']" [rowHover]="true" dataKey="customerid" [(selection)]="selectedProductDetail"
                                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 100px;text-align:center">Action</th>
                                        <th>Product Name</th>
                                        <th>Variant</th>
                                        <th>Unit price</th>
                                        <th>Quantity</th>
                                        <th>Tax Percentage</th>
                                        <th>Tax amount</th>
                                        <th>Total Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_LaybuyProductDetail>
                                    <tr>
                                        <td style="text-align: center;">
                                            <button pButton pRipple title="Remove" icon="pi pi-trash" [hidden]="_action == 'view'" [disabled]="updatelaybuy" (click)="RemoveJournal(_LaybuyProductDetail)" class="p-button-danger p-mr-2"></button>
                                        </td>
                                        <td>{{_LaybuyProductDetail.productname}}</td>
                                        <td>{{_LaybuyProductDetail.variantdescription}}</td>
                                        <td>{{_LaybuyProductDetail.price | number : '1.2'}}</td>
                                        <td>{{_LaybuyProductDetail.quantity}}</td>
                                        <td>{{_LaybuyProductDetail.taxpercentage}}</td>
                                        <td>{{_LaybuyProductDetail.taxamount | number : '1.2'}}</td>
                                        <td>{{_LaybuyProductDetail.totalamount | number : '1.2'}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Terms And Condition</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <form [formGroup]="_LaybuyPaymentTermform">
                                        <span class="p-float-label">
                                <p-multiSelect [options]="_PaymentTerms" [(ngModel)]="_selectedPaymentIndex"
                                    optionLabel="termsdescription" optionValue="termsandconditionid"
                                    (onChange)="OnChangeofPayment($event)" name="paymenttermsid" [filter]="true"
                                    formControlName="paymenttermsid"></p-multiSelect>
                                <label for="paymenttermsid">Payment Terms </label>
                            </span>
                                    </form>
                                </td>
                                <td style="width: 20%;">
                                    <form [formGroup]="_LaybuyDeliveryTermform">
                                        <span class="p-float-label">
                                <p-multiSelect [options]="_DeliveryTerms" [(ngModel)]="_selectedDeliveryIndex"
                                    optionLabel="termsdescription" optionValue="termsandconditionid"
                                    (onChange)="OnChangeofDelivery($event)" name="deliverytermsid" [filter]="true"
                                    formControlName="deliverytermsid"></p-multiSelect>
                                <label for="deliverytermsid">Delivery Terms </label>
                            </span>
                                    </form>
                                </td>
                                <td style="width: 20%;">
                                    <form [formGroup]="_LaybuyOtherTermform">
                                        <span class="p-float-label">
                                <p-multiSelect [options]="_OtherTerms" [(ngModel)]="_selectedOtherIndex"
                                    optionLabel="termsdescription" optionValue="termsandconditionid"
                                    (onChange)="OnChangeofOther($event)" name="othertermsid" [filter]="true"
                                    formControlName="othertermsid"></p-multiSelect>
                                <label for="othertermsid">Other Terms </label>
                            </span>
                                    </form>
                                </td>
                                <td style="width: 20%;">
                                    <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                                </td>
                                <td style="width: 20%;"></td>
                            </tr>
                        </table>
                    </p-tabPanel>
                    <p-tabPanel header="Document Detail">
                        <form [formGroup]="_LaybuyDocumentDetailform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                        <div class="browse-links">
                                            <p-button label="Link" styleClass="p-button-link"
                                                (click)="showdocumentDialog(document)">
                                            </p-button>
                                        </div>
                                    </span>
                                            <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only" pTooltip="Browse File" tooltipPosition="top" mode="basic" #document accept="image/*,.pdf,.xlsx,.xls,.xlsm,.docx,.txt" maxFileSize="1000000" [auto]="true" chooseLabel="" (onSelect)="onDocumentSelect($event,document)"
                                                [disabled]="_action == 'edit' || _action == 'view'">
                                            </p-fileUpload>
                                            <button class="p-inputgroup-addon p-button" pTooltip="Cancel" (click)="onDocumentClear()" tooltipPosition="top" [disabled]="_action == 'edit' || _action == 'view'">
                                        <i class="pi pi-times"></i>
                                    </button>
                                        </div>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                    <input type="text" pInputText name="remarks" formControlName="documentdescription">
                                    <label for="remarks">Description</label>
                                </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <button pButton pRipple label="Add" icon="las la-check" (click)="onAddProof($event)" class="p-button-sm p-button-success" [disabled]="_action == 'edit' || _action == 'view'"></button>
                                        <button pButton pRipple label="Clear" icon="pi pi-times" class="p-button-danger" [disabled]="_action == 'edit' || _action == 'view'"></button>
                                    </td>
                                    <td>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                        <div class="card">
                            <p-table #dt [value]="_Proofs" [rows]="5" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['attachmenturl','attachmentname','documentdescription']" [rowHover]="true" dataKey="attachmenturl" [(selection)]="selectedrows"
                                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 100px;text-align: center;">Action</th>
                                        <th>Document Name</th>
                                        <th>Document Description</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_Proof let-ri="rowIndex">
                                    <tr>
                                        <td style="text-align:center">
                                            <button pButton pRipple label="" title="Remove" icon="pi pi-trash" [disabled]="_IsRemoveDisable" (click)="RemoveProofDetail(_proof)" class="p-button-danger p-mr-2">
                                    </button>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" (click)="showdocumentGridDialog('bottom-right',_Proof)">
                                        {{_Proof.attachmentname}} </a>
                                        </td>
                                        <td>{{_Proof.documentdescription}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
            <p-dialog header="Image Preview" [(visible)]="displayBasic1" [position]="position" [style]="{width: '500px'}" [baseZIndex]="10000">
                <img src="../../assets/images/chart03.jpg" alt="" class="img-preview">
                <button class="p-inputgroup-addon p-button" title="Delete">
                <i class="pi pi-trash"></i>
            </button>
                <ng-template pTemplate="footer">
                    <p-button icon="pi pi-check" (click)="displayBasic=false" label="Ok" styleClass="p-button-text">
                    </p-button>
                </ng-template>
            </p-dialog>
            <p-dialog header="Laybuy Document" [(visible)]="displaydocumentgridpopup" [position]="position" [style]="{width: '500px'}" [baseZIndex]="10000">
                <img [src]="documentimage" alt="" class="img-preview">
                <ng-template pTemplate="footer">
                    <p-button icon="pi pi-check" (click)="closePopupGridDialog()" label="Ok" styleClass="p-button-text">
                    </p-button>
                </ng-template>
            </p-dialog>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>