import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class ApprovaltypeService {
  public _Action: IAction;
  public _ApprovaltypeService = environment.ApprovaltypeService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient,
    private _CustomExceptionService: CustomExceptionService) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(): Observable<any> { 
    const headers = this.header;
    var Query = this._ApprovaltypeService;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  create(approvaltype: object) { 
    const headers = this.header;
    console.log("Create");
    var Query = this._ApprovaltypeService;
    return this.http.post<{ data: any }>(Query, approvaltype, { headers });
  }
  edit(approvaltype: object) { 
    const headers = this.header;
    console.log("Update");
    var Query = this._ApprovaltypeService;

    return this.http.put<{ data: any }>(Query, approvaltype, { headers });
  }

}
