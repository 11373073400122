<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="form-container scroll-y">
                <form [formGroup]="_tripstartform">
                    <div class="page-title">
                        <div class="row">
                            <div class="col-md-5">
                                <h3>Trip Start</h3>
                            </div>
                            <div class="col-md-7 text-right">
                                <div class="action-btn">
                                    <button pButton pRipple title="Clear" [disabled]="isDisabledClear"
                                        icon="pi pi-trash" class="p-button-danger" (click)="reset($event)"></button>
                                    <button pButton pRiple icon="pi pi-search" title="Back to Search"
                                        class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Vehicleno" name="vehicleid"
                                        [filter]="true" optionLabel="vehicleno" optionValue="vehicleid"
                                        formControlName="vehicleid" (onChange)="ChangeVehicle($event)"> </p-dropdown>
                                    <label for="vehicleid">Vehicle No <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _tripstartform.get('vehicleid').touched) && _tripstartform.get('vehicleid').errors?.SelectVehicleNumber">
                                    Please Select Vehicle No
                                </span>
                            </td>
                            <td style="width: 20%;">

                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_DriverName" name="driverid"
                                        optionLabel="employeename" optionValue="employeeid"
                                        formControlName="drivername">
                                    </p-dropdown>
                                    <label for="driverid">Driver Name <span class="hlt-txt">*</span></label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_tripstartassiantanceform">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_DriverName" name="functionid"
                                            optionLabel="employeename" [(ngModel)]="_selectedDrivernameIndex"
                                            optionValue="employeeid" formControlName="assistanceid"
                                            (onChange)="getAssitanceList($event)">
                                        </p-multiSelect>
                                        <label for="assistantid">Assistant Name <span class="hlt-txt">*</span></label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <textarea rows="3" name="address" pInputTextarea
                                        formControlName="referenceno"></textarea>
                                    <label for="address">Reference No <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" name="odometerreading" formControlName="odometerreading"
                                        pInputText (keyup)="ChangeOdometerReading($event)">
                                    <label for="odometerreading">Odometer Reading</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="averagekm" formControlName="averagekm" pInputText>
                                    <label for="averagekm">AVG.KM</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-inputNumber formControlName="startkm" autocomplete="off" maxlength="13"
                                        [disabled]="disablestartingkm" mode="decimal" [minFractionDigits]="3"
                                        [maxFractionDigits]="3">
                                    </p-inputNumber>
                                    <label for="startingkm">Starting KM </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-inputNumber formControlName="endkm" autocomplete="off" maxlength="13"
                                        [disabled]="disableendingkm" mode="decimal" [minFractionDigits]="3"
                                        [maxFractionDigits]="3">
                                    </p-inputNumber>
                                    <label for="endingkm">Ending KM </label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <!-- <td>
                                <span class="p-float-label" [hidden]="true">
                                    <input type="text" name="receivername" formControlName="receivername" pInputText>
                                    <label for="receivername">Receiver Name <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label" [hidden]="true">
                                    <input type="text" name="receivermobileno" formControlName="receivermobileno"
                                        maxlength="15" pInputText>
                                    <label for="receivermobileno">Receiver Mobile No <span
                                            class="hlt-txt">*</span></label>
                                </span>
                            </td> -->
                        </tr>
                        <tr>
                            <td colspan="2">
                                <button pButton pRipple label="Trip Start" icon="las la-check" type="button"
                                    [disabled]="_IsonstarttripDisable" (click)="OnStartTrip()"></button>
                                <button pButton pRipple label="Trip Close" icon="las la-times" type="button"
                                    [disabled]="_IsonclosetripDisable" (click)="OnCloseTrip()"></button>
                            </td>
                        </tr>
                        <tr>
                            <td>

                                <div #editor style="width: 500px;height: 20px; overflow-y:auto;"></div>
                            </td>
                        </tr>
                    </table>

                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>

                    <p-table #dt [value]="_TripStartSrockDetailsList" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['deliveryno','evidenceno','evidencedate','customername']"
                        [rowHover]="true" dataKey="salesquotationno"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">

                        <ng-template pTemplate="header">
                            <tr>
                                <th>Delivery No</th>
                                <th>Invoice No</th>
                                <th>Invoice Date</th>
                                <th>Customer Name </th>
                                <th>Receivers Name </th>
                                <th>Receivers Contact No </th>
                                <th>Reference Doc </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_TripStartview>
                            <tr>
                                <td> {{_TripStartview.deliveryNo}} </td>
                                <td> {{_TripStartview.invoiceNo }} </td>
                                <td> {{_TripStartview.invoiceDate | date:'dd/MM/yyyy'}} </td>
                                <td> {{_TripStartview.customername}} </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText name="receivername" maxlength="30"
                                                [(ngModel)]="_TripStartview.receivername" *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_TripStartview.receivername}}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_TripStartview.receivername}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber inputId="locale-us"
                                                [(ngModel)]="_TripStartview.receivermobileno" mode="decimal"
                                                locale="en-US" [minFractionDigits]="0" [maxFractionDigits]="0"
                                                [useGrouping]="false" [min]="0" maxlength="15">
                                            </p-inputNumber>
                                            <div *ngIf="_action == 'view'">{{_TripStartview.receivermobileno}}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_TripStartview.receivermobileno}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <div class="browse-links">
                                                <p-button styleClass="p-button-link"
                                                    label="{{_TripStartview.attachmentname}}" title="Document"
                                                    (click)="showdocumentDialog(document,'bottom-right',_TripStartview)"
                                                    tooltipPosition="top">
                                                </p-button>
                                            </div>
                                        </span>

                                        <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                            pTooltip="Browse File" tooltipPosition="top" mode="basic" #document
                                            (onSelect)="onDocumentSelect($event,document,_TripStartview)"
                                            accept="image/*,.pdf,.xlsx,.xls,.xlsm,.docx,.txt"
                                            [disabled]="_action=='view'" maxFileSize="2147483648" [auto]="true">
                                        </p-fileUpload>
                                        <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                            (click)="onDocumentClear(_TripStartview)" tooltipPosition="top" (click)="on"
                                            [disabled]="_action=='view'">
                                            <i class="pi pi-times"></i>
                                        </button>
                                    </div>
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
        <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>