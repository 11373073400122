<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Lot Wise Ageing Report</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptexpiryageingform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_branchlist"
                                        optionValue="branchid" optionLabel="branchname" formControlName="branchid"
                                        [filter]="true" filterBy="branchname"
                                        [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-dropdown>
                                    <label for="branchid">Branch Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_producttypelist" optionLabel="producttypename"
                                        optionValue="producttypeid" [(ngModel)]="producttypeid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProductCategory($event)"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type Name </label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                                        [selectionLimit]="50" [(ngModel)]="productcatid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProduct($event)"
                                        optionValue="productcategoryid"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category Name</label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productlist" optionLabel="productname"
                                        optionValue="productid" [(ngModel)]="productid"
                                        (onChange)="ChangeProduct($event)" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                                        [(ngModel)]="brandid" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="brandid">Brand Name </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_stocktypelist"
                                        optionValue="metasubcode" optionLabel="metasubdescription"
                                        formControlName="stocktype">
                                    </p-dropdown>
                                    <label for="stocktype">Stock Type</label>
                                </span>
                            </td>
                            <td>
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox name="isexpired" binary="true" (click)="onchangeexpired()"
                                            formControlName="isexpired">
                                        </p-checkbox>
                                        <label for="isexpired">is Expired</label>
                                    </div>
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" icon="pi pi-search" (click)="GenerateReport()"
                                    class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()"
                                    class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card" [hidden]="ExpiryHeaderReport">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>

                    <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="500"
                        [paginator]="true" [rowsPerPageOptions]="[100,200,300]"
                        [rowsPerPageOptions]="[500,1000,1500,2000]"
                        [globalFilterFields]="['branchname','productcategoryname','productcode','productname','brandname','salesuomname','severityname','okstock','lockedstock','closingstock']"
                        [rowHover]="true" dataKey="productname"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" pSortableColumn="branchname">1-30 Days <p-sortIcon
                                        field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="productcategoryname">31-60 Days
                                    <p-sortIcon field="productcategoryname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="productcode">61-90 Days
                                    <p-sortIcon field="productcode">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="productname">91-120 Days
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="brandname">121-150 Days
                                    <p-sortIcon field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="brandname">151-180 Days
                                    <p-sortIcon field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="brandname">180 Days

                                    <p-sortIcon field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="brandname">Above 180 Days
                                    <p-sortIcon field="brandname">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                            <tr>
                                <td>

                                    <a href="javascript:void(0);" (click)="thirtydays()">
                                        {{_ReportList.thirtydays}}
                                    </a>
                                </td>
                                <td>
                                    <a href="javascript:void(0);" (click)="sixtydays()">
                                        {{_ReportList.sixtydays}}
                                    </a>
                                </td>
                                <td>
                                    <a href="javascript:void(0);" (click)="ninetydays()">
                                        {{_ReportList.ninetydays}}
                                    </a>
                                </td>
                                <td>
                                    <a href="javascript:void(0);" (click)="onetwentydays()">
                                        {{_ReportList.onetwentydays}}
                                    </a>
                                </td>
                                <td>
                                    <a href="javascript:void(0);" (click)="onefiftydays()">
                                        {{_ReportList.onefiftydays}}
                                    </a>
                                </td>
                                <td>
                                    <a href="javascript:void(0);" (click)="oneeightydays()">
                                        {{_ReportList.oneeightydays}}
                                    </a>
                                </td>
                                <td>
                                    <a href="javascript:void(0);" (click)="oneeightydaysequal()">
                                        {{_ReportList.oneeightydaysequal}}
                                    </a>
                                </td>
                                <td>
                                    <a href="javascript:void(0);" (click)="aboveoneeightydays()">
                                        {{_ReportList.aboveoneeightydays}}
                                    </a>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-dialog header="Product Details" [(visible)]="ExpiryDetailReport" [modal]="true"
                    [style]="{width: '80vw',height: '700px'}" [baseZIndex]="10000">
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="search-container">
                                <div class="p-inputgroup" style="width:150px !important;">
                                    <span class="p-float-label">
                                        <input type="text" pInputText
                                            (input)="dtReportdetails.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                    <!-- <button class="p-inputgroup-addon p-button" title="Search">
                                                    <i class="pi pi-search"></i>
                                                </button> -->
                                </div>
                            </div>
                            <div class="card">
                                <p-toolbar styleClass="p-mb-4">
                                    <ng-template pTemplate="left">
                                    </ng-template>
                                    <ng-template pTemplate="right">
                                        <button type="button" pButton pRipple icon="pi pi-file-excel"
                                            title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS"
                                            tooltipPosition="bottom" (click)="exportExcel1()"></button>
                                        <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                            class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                            (click)="ExportToPdf1()"></button>
                                    </ng-template>
                                </p-toolbar>
                                <p-table #dtReportdetails [value]="Reportdetails" [paginator]="true" [rows]="10"
                                    [globalFilterFields]="['branchname','producttypename','productcategoryname','productname','lotno','expirydate','severity','stock']"
                                    [rowHover]="true" dataKey="referenceno"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [showCurrentPageReport]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="text-align: center;" pSortableColumn="branchname">Branch Name
                                                <p-sortIcon field="branchname">
                                                </p-sortIcon>
                                            </th>
                                            <th style="text-align: center;" pSortableColumn="producttypename">Product
                                                Type Name<p-sortIcon field="producttypename">
                                                </p-sortIcon>
                                            </th>
                                            <th style="text-align: center;" pSortableColumn="productcategoryname">
                                                Product Category Name<p-sortIcon field="productcategoryname">
                                                </p-sortIcon>
                                            </th>
                                            <th style="text-align: center;" pSortableColumn="productname">Product Name
                                                <p-sortIcon field="productname">
                                                </p-sortIcon>
                                            </th>
                                            <th style="text-align: center;" pSortableColumn="lotno">Lot No<p-sortIcon
                                                    field="lotno">
                                                </p-sortIcon>
                                            </th>
                                            <th style="text-align: center;" pSortableColumn="expirydate">Expiry Date
                                                <p-sortIcon field="expirydate">
                                                </p-sortIcon>
                                            </th>
                                            <th style="text-align: center;" pSortableColumn="severity">Severity
                                                <p-sortIcon field="severity">
                                                </p-sortIcon>
                                            </th>

                                            <th style="text-align: center;" pSortableColumn="stock">Stock<p-sortIcon
                                                    field="stock">
                                                </p-sortIcon>
                                            </th>

                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-Reportdetail>
                                        <tr>
                                            <td>
                                                {{Reportdetail.branchname}}
                                            </td>
                                            <td>
                                                {{Reportdetail.producttypename}}
                                            </td>
                                            <td>
                                                {{Reportdetail.productcategoryname}}
                                            </td>
                                            <td>
                                                {{Reportdetail.productname}}
                                            </td>
                                            <td>
                                                {{Reportdetail.lotno}}
                                            </td>
                                            <td>
                                                {{Reportdetail.expirydate}}
                                            </td>
                                            <td>
                                                {{Reportdetail.severity}}
                                            </td>
                                            <td>
                                                {{Reportdetail.stock}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
                <div class="card" [hidden]="ExpiredReport">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt_ReportList.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt_ReportList [value]="_ReportLists" [paginator]="true" [rows]="10"
                        [globalFilterFields]="['branchname','producttypename','productcategoryname','productname','lotno','expirydate','severity','stock']"
                        [rowHover]="true" dataKey="referenceno"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" pSortableColumn="branchname">Branch Name
                                    <p-sortIcon field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="producttypename">Product Type Name
                                    <p-sortIcon field="producttypename">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="productcategoryname">Product Category
                                    Name<p-sortIcon field="productcategoryname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="productname">Product Name<p-sortIcon
                                        field="productname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="lotno">Lot No<p-sortIcon field="lotno">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="expirydate">Expiry Date<p-sortIcon
                                        field="expirydate">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="severity">Severity<p-sortIcon
                                        field="severity">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="stock">Stock<p-sortIcon field="stock">
                                    </p-sortIcon>
                                </th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ReportList>
                            <tr>
                                <td>
                                    {{_ReportList.branchname}}
                                </td>
                                <td>
                                    {{_ReportList.producttypename}}
                                </td>
                                <td>
                                    {{_ReportList.productcategoryname}}
                                </td>
                                <td>
                                    {{_ReportList.productname}}
                                </td>
                                <td>
                                    {{_ReportList.lotno}}
                                </td>
                                <td>
                                    {{_ReportList.expirydate}}
                                </td>
                                <td>
                                    {{_ReportList.severity}}
                                </td>
                                <td>
                                    {{_ReportList.stock}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

            </div>
        </div>
    </div>

</div>
<p-toast position="bottom-right"></p-toast>