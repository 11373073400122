export class Containertrackingdocumentdetail {
    containertrackingdocumentdetailid: number;
    containertrackingid: number;
    documentid: number;
    documentname: string;
    documentdescription: string;
    agentid:number;
    agentname:string;
    attachmenturl: string;
    attachmentname: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}