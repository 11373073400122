import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { ProductbarcodeprintService } from 'src/app/core/Services/reports/ProductBarcodePrint/productbarcodeprint.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-productbarcodeprint',
  templateUrl: './productbarcodeprint.component.html',
  styleUrls: ['./productbarcodeprint.component.css']
})
export class ProductbarcodeprintComponent implements OnInit {
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _src: SafeResourceUrl;
  _productbarcodeprintform: FormGroup;
  _IsProgressSpinner: boolean = false;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _ProductPrintObj: any;
  _Action: IAction;

  _currenctbranchid = sessionStorage["currenctbranchid"];
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _AccessRightsService: AccessRightsService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private sanitizer: DomSanitizer, private _ProductBarcodeService: ProductbarcodeprintService) { }
  InitializeProductbarcodeprintForm() {
    this._productbarcodeprintform = this.fb.group({
      producttypeid: [],
      productcategoryid: [],
      productid: [],
    });
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(143);
    this.utility.pageLoadScript();
    this.InitializeProductbarcodeprintForm();
    this._IsProgressSpinner = true;
    this._ProductBarcodeService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._producttypelist = resultJSON.productTypeList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    this._productcategorylist = [];
    this._productlist = [];
    if (!selectedProductType || !selectedProductType.length) {
      return;
    }

    this._IsProgressSpinner = true;
    this._ProductTypeObj = { "productType": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
    this._ProductBarcodeService.GetProductCategory(this._ProductTypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("category", resultJSON);
      this._productcategorylist = resultJSON.productCategoryList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  getProduct(event) {
    var selectedProductCategory = event.value;
    // if (selectedProductCategory.length > 2) {
    //   this._CustomExceptionService.handleError("Please Select AtMost Two ProductCategory");
    //   return;
    // }
    this._productlist = [];
    if (!selectedProductCategory || !selectedProductCategory.length) {
      return;
    }
    console.log(selectedProductCategory);
    this._ProductCategoryObj = { "category": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
    this._ProductBarcodeService.GetProduct(this._ProductCategoryObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productlist = resultJSON.productsearch;
    });
  }
  GenerateReport(event) {

    var ProductTypeId = this._productbarcodeprintform.get("producttypeid").value || [];
    var ProductCategoryId = this._productbarcodeprintform.get("producttypeid").value || [];
    var ProductId = this._productbarcodeprintform.get("productid").value || [];
    if (ProductId.length == 0 || ProductId == null) {
      this._CustomExceptionService.handleError("Please Select Atleast One Product Name");
      return;
    }
    if (ProductCategoryId.length == 0 || ProductCategoryId == null) {
      this._CustomExceptionService.handleError("Please Select Atleast One Product Category");
      return;
    }
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this._ProductPrintObj = { "productid": ProductId || [], "category": ProductCategoryId || [], "branchid": sessionStorage["currentbranchid"] }
    this._ProductBarcodeService.Print(this._ProductPrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this.InitializeProductbarcodeprintForm();
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
}
