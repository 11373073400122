import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptrevenueexpenseService {
  public _Action: IAction;
  public _httpOptions;
  public _RptRevenueExpenseService = environment.RptRevenueExpenseService;
  constructor(private http: HttpClient) { }
  
  PageOnload(): Observable<any> {
    var Query = this._RptRevenueExpenseService + '/PageInitialize';
    
    return this.http.get<{ data: any }>(Query); 
  }
  Print(fromdate :string,userid :number,currencyid:number,branchid:number) {
    var Query = this._RptRevenueExpenseService+'/Print/'+fromdate+'/'+ userid+'/'+ currencyid + '/'+branchid;
    //
   // window.open(Query,'_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    return this.http.get(Query,{responseType: 'blob'});
  }

}
