import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Salesproductpriceview } from 'src/app/core/Models/Productpricechecking';
import { Producttype } from 'src/app/core/Models/Producttype';
import { PricecheckingService } from 'src/app/core/Services/sales/pricechecking.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { vProductNameSelect, vProductCategorySelect, vProductTypeSelect } from 'src/app/core/Validators/validation';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { KeyPress } from 'src/assets/js/KeyPress';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-c-pricechecking',
  templateUrl: './c-pricechecking.component.html',
  styleUrls: ['./c-pricechecking.component.css']
})
export class CPricecheckingComponent implements OnInit {
  filteredProducts: any[];
  barcodevalue: string;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: any[] = [];
  _productdetails: Productoverallview[] = [];
  _productpricecheckingform: FormGroup;
  _productpriceform: FormGroup;
  _ProductTypes: Producttype;
  _ProductCategories: Productcategory;
  _branchlist: Branch;
  _ProductNames: Productoverallview[];
  _tempproductname: Productoverallview[] = [];
  _action: string = "create";
  selectedrows: any;
  selectedProductDetailsRows: any
  _Productpricechecking: Salesproductpriceview;
  _pricedetails: Salesproductpriceview[];
  _pricedetail: Salesproductpriceview;
  _levelid = sessionStorage["levelid"];
  _submitted = false;
  _branchid = parseInt(sessionStorage["currentbranchid"])
  _Action: IAction;
  _IsProgressSpinner: boolean = true;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _Product: any;
  _stockinhand: any[] = [];
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _router: Router, public keypress: KeyPress, private _CustomExceptionService: CustomExceptionService,
    private _PricecheckingService: PricecheckingService, private _hotkeysService: HotkeysService, private _AccessRightsService: AccessRightsService, private exportUtility: ExportUtility) {
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._productpricecheckingform = this.fb.group({
      productid: ['', vProductNameSelect],
      productname: [],
      stock: [],
      stockinbranch: [],
      salesuom: [],
      uomname: [],
      salesdiscount: [],
      discountpercentage: [],
      barcode: [],
      minimumlevel: [],
      maximumlevel: [],
      vatapplicable: [],
      taxpercentage: [],
      disallownegativeqty: [],
      isavailable: [],
      markuplevel: [],
      usd: [],
      rand: [],
      zim1: [],
      zim2: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      branchid: [],

    });
    this._productpriceform = this.fb.group({
      productcategoryid: ['', vProductCategorySelect],
      producttypeid: ['', vProductTypeSelect],
      stockinhand:[]
    });
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(61);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;
    this._PricecheckingService.PageOnLoad(this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ProductTypes = resultJSON.producttypes;
      this._branchlist= resultJSON.branches;
      this._productpricecheckingform.controls['branchid'].setValue(this._branchid);
      // this._ProductNames = <Productoverallview[]>resultJSON.productsearch || [];
      // this._tempproductname = <Productoverallview[]>resultJSON.productsearch || [];
      this._IsProgressSpinner = false;
    });
  }
  GetProductCategory(event) {
    var selectedProductType = event.value;
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productpricecheckingform.reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": this._branchid || 0 };
      this._PricecheckingService.GetProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._ProductCategories = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      });
    }
    // let productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
    // this._ProductNames = productList.length ? productList : this._tempproductname;
  }
  GetProductName(event) {

    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productpricecheckingform.reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": this._branchid || 0 };
      this._PricecheckingService.GetProductName(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._ProductNames = resultJSON.productsearch;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  GetProductPrice(event) {
    var selectedproduct = event.value;
    if (selectedproduct.length == 0 || selectedproduct == null) {
      return;
    }
    else {
      this._IsProgressSpinner = true;
      var branchid = this._productpricecheckingform.get("branchid").value || 0;
      this._Product = { "Product": selectedproduct || [], "levelid": this._levelid, "branchid": branchid };
      this._PricecheckingService.GetProductPrice(this._Product).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._pricedetails = resultJSON.salesproductprice; 
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  onchangebranch(event) {
    var branchid = event.value; 
      this._IsProgressSpinner = true;
      var products = this._productpricecheckingform.get("productid").value || [];
      this._Product = { "Product": products || [], "levelid": this._levelid, "branchid": branchid };
      this._PricecheckingService.GetProductPrice(this._Product).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._pricedetails = resultJSON.salesproductprice; 
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GetProductPriceUsingBarcode() {

    if (!this.barcodevalue)
      return;

    this._IsProgressSpinner = true;
    setTimeout(() => {
      var branchid = this._productpricecheckingform.get("branchid").value || 0;
      this._Product = { "barcode": this.barcodevalue, "levelid": this._levelid, "branchid": branchid };
      this._PricecheckingService.GetProductPriceUsingBarcode(this._Product).subscribe((result: any) => {
        if (result.salesproductprice && result.salesproductprice.length) {
          this._pricedetails = result.salesproductprice;
          this._IsProgressSpinner = false;
          this.barcodevalue = null;
        }
      },(error: any) => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }, 300);

  }

  reset(event) {
    this._productpricecheckingform.reset();
    this._productpriceform.reset();
    this._pricedetails = [];
    this.productcatid = [];
    this.producttypeid = [];
    this.InitializeForm();
  }

  filterProducts(event) {
    let filtered: any[] = [];
    let query = (<string>event.query).toLowerCase();
    this.filteredProducts = this._ProductNames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
  }
  allocatequantity(row: any, event) {
    var Quantity = parseInt(row.quantity) || 1;
    row.usd = Quantity * row.tempusd;
    row.rand = Quantity * row.temprand;
    row.bond = Quantity * row.tempbond;
    row.bank = Quantity * row.tempbank;

  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        //this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  GetProductPriceCheckList() {
    let productpricecheck = [];
    this._pricedetails.forEach(element => {
      let productprice: any = {};
      productprice["PRODUCT NAME"] = element.productname;
      productprice["QUANTITY"] = element.quantity;
      productprice["USD"] = element.usd;
      productprice["RAND"] = element.rand;
      productprice["BOND"] = element.bond;
      productprice["BANK"] = element.bank;
      productprice["STOCK"] = element.stock;
      productpricecheck.push(productprice);
    });
    return productpricecheck;
  }

  exportExcel() {
    let productpricelist = this.GetProductPriceCheckList();
    this.exportUtility.exportExcel(productpricelist, 'Productpricechecking', 'xlsx');
  }

  ExportToPdf() {
    let StockmissingList = this.GetProductPriceCheckList();
    const data = StockmissingList.map(Object.values);
    const head = [[
      'PRODUCT NAME',
      'QUANTITY',
      'USD',
      'RAND',
      'BOND',
      'BANK',
      'STOCK'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('productpricechecking.pdf');
      })
    })
  }
}
