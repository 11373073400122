<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Document Verification</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple title="Save" (click)="onSave()" type="submit"
                                icon="pi pi-save"></button>
                            <!-- All input fields should be cleared. -->
                            <button pButton pRipple title="Clear" icon="pi pi-trash" (click)="reset($event)"
                                class="p-button-danger"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_documentverificationform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_branches" name="branchid"
                                        optionLabel="branchname" optionValue="branchid" formControlName="branchid"
                                        [filter]="true" filterBy="branchname" [showClear]="true">
                                    </p-dropdown>
                                    <label for="branchid"> Branch <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _documentverificationform.get('branchid').touched) && _documentverificationform.get('branchid').errors?.SelectBranch">
                                    Please Select Branch
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_documenttypes" name="functionid"
                                        optionLabel="functionname" optionValue="functionid" formControlName="functionid"
                                        [filter]="true" filterBy="functionname"
                                        (onChange)="OnDocumentRefNoChange($event)" [showClear]="true">
                                    </p-dropdown>
                                    <label for="branchid"> Document Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _documentverificationform.get('functionid').touched) && _documentverificationform.get('functionid').errors?.SelectDocumentType">
                                    Please Select Document Type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Supplier" [hidden]="Supplierdisabled" (onChange)="OnDocumentRefNoChange($event)"
                                        [filter]="true" filterBy="suppliername" optionValue="supplierid" optionLabel="suppliername"
                                        formControlName="supplierid" [showClear]="true">
                                    </p-dropdown>
                                    <label [hidden]="Supplierdisabled" for="supplierid">Supplier</label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _documentverificationform.get('supplierid').touched) && _documentverificationform.get('supplierid').errors?.SelectSupplierName">
                                    Please Select Supplier
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>

                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Document Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-table #dt [value]="_DocumentDetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['docid','doccode','referencename', 'remarks', 'documentstatus']"
                        [rowHover]="true" dataKey="docid" [(selection)]="selectedrows"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [(selection)]="SelectedDocumentDetailsRows"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <!-- <th class="text-center" style="width: 120px;" pSortableColumn="sno">SNo <p-sortIcon
                                        field="sno"></p-sortIcon>
                                </th> -->
                                <th style="text-align: center;width: 50px;">
                                    <p-tableHeaderCheckbox [disabled]="_action == 'view' || _action == 'edit'">
                                    </p-tableHeaderCheckbox>
                                </th>
                                <th pSortableColumn="docid">Document ID<p-sortIcon field="docid"></p-sortIcon>
                                </th>
                                <th pSortableColumn="doccode">Document No<p-sortIcon field="doccode"></p-sortIcon>
                                </th>
                                <th pSortableColumn="suppliername" [hidden]="Supplierdisabled">Supplier Name<p-sortIcon field="suppliername"></p-sortIcon>
                                </th>
                                <th *ngIf="_IsEmployee" pSortableColumn="referencename">Reference Name<p-sortIcon
                                        field="referencename"></p-sortIcon>
                                </th>
                                <th pSortableColumn="documentname">Path<p-sortIcon field="documentname">
                                    </p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="url">URL<p-sortIcon field="url">
                                    </p-sortIcon>
                                </th> -->
                                <th pSortableColumn="remarks">Remarks<p-sortIcon field="remarks">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="documentstatus">Status<p-sortIcon field="documentstatus">
                                    </p-sortIcon>
                                </th>
                                <th>Attachment</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Documentdetail let-i="rowIndex">
                            <tr>
                                <!-- <td style="text-align: center;">
                                    {{i+ 1}}
                                </td> -->
                                <td style="text-align: center;width: 50px;">
                                    <p-tableCheckbox [value]="_Documentdetail">
                                    </p-tableCheckbox>
                                </td>
                                <td>
                                    {{_Documentdetail.docid}}
                                </td>
                                <td>
                                    {{_Documentdetail.doccode}}
                                </td>
                                <td [hidden]="Supplierdisabled">
                                    {{_Documentdetail.suppliername}}
                                </td>
                                <td *ngIf="_IsEmployee">
                                    {{_Documentdetail.referencename}}
                                </td>
                                <!-- <td>
                                    {{_Documentdetail.documentname}}
                                </td> -->
                                <td>
                                    <a href="javascript:void(0);"
                                        (click)="showdocumentGridDialog('bottom-right',_Documentdetail)">
                                        {{_Documentdetail.documentname}}
                                    </a>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" [(ngModel)]="_Documentdetail.remarks" pInputText>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Documentdetail.remarks}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_Status"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                (onChange)="onchangestatus(_Documentdetail,$event)"
                                                [(ngModel)]="_Documentdetail.documentstatus"></p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [disabled]="true" [autoDisplayFirst]="false" [options]="_Status"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                (onChange)="onchangestatus(_Documentdetail,$event)"
                                                [(ngModel)]="_Documentdetail.documentstatus">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <div class="browse-links">
                                                <p-button styleClass="p-button-link"
                                                    label="{{_Documentdetail.attachmentname}}" title="Document"
                                                    (click)="showdocumentDialog(document,'bottom-right',_Documentdetail)"
                                                    [disabled]="_Documentdetail.isenable" tooltipPosition="top">
                                                </p-button>
                                            </div>
                                        </span>

                                        <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                            pTooltip="Browse File" tooltipPosition="top" mode="basic" #document
                                            (onSelect)="onDocumentSelect($event,document,_Documentdetail)"
                                            accept="image/*,.pdf,.xlsx,.xls,.xlsm,.docx,.txt"
                                            [disabled]="_Documentdetail.isenable" maxFileSize="1000000" [auto]="true">
                                        </p-fileUpload>
                                        <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                            (click)="onDocumentClear(_Documentdetail)" tooltipPosition="top"
                                            [disabled]="_Documentdetail.isenable">
                                            <i class="pi pi-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-dialog header="Document Verification" [(visible)]="displaydocumentgridpopup" [position]="position"
                    [style]="{width: '500px'}" [baseZIndex]="10000">
                    <img [src]="document" alt="" class="img-preview">
                    <ng-template pTemplate="footer">
                        <p-button icon="pi pi-check" (click)="closePopupGridDialog()" label="Ok"
                            styleClass="p-button-text">
                        </p-button>
                    </ng-template>
                </p-dialog>
                <table>
                    <td>
                        <!-- Debug -->
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>

                </table>

                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>