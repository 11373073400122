import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent} from './login.component'
//import {PrimeNGModule} from './../../prime-ng/prime-ng.module'
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastModule} from 'primeng/toast';
import {InputTextModule} from 'primeng/inputtext';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PasswordModule } from "primeng/password";
import {CustomExceptionService} from '../../errorHandlers/custom-exception.service'
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
@NgModule({
  declarations: [AccessDeniedComponent],
  providers:[CustomExceptionService],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    InputTextModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    PasswordModule,
    ProgressSpinnerModule,
    LoginRoutingModule
  ]
})
export class LoginModule { }
