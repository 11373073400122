import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  public _Action : IAction ;
  public  _CounponService = environment.CouponService;
  public _httpOptions;
  constructor(private http:HttpClient) { 
  }
  PageOnload(couponid:number): Observable<any> {
    var Query = this._CounponService+ '/' + couponid ;
    // console.log(Query);
    return this.http.get<{data:any}>(Query);
  }
  getProduct(productcategory: object) {
    var Query = this._CounponService + '/getProducts';
    // console.log(Query);
    return this.http.post<{ data: any }>(Query, productcategory);
  }
  create(coupon: object) {
    var Query = this._CounponService;
    // console.log(Query);
    return this.http.post<{ data: any }>(Query, coupon);
  }
  Update(coupon: object) {
    var Query = this._CounponService + '/Update';
    // console.log(Query);
    return this.http.put<{ data: any }>(Query, coupon);
  }
  FetchAll() {
    var Query = this._CounponService + '/FetchAll';
    return this.http.get<{ data: any }>(Query, this._httpOptions).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));

      return data;
    }));
  }
}
