
    export class Customerwisediscountheader {
        customerwiseid: number;
        customerid: number;
        branchid: number;
        effectivefrom: Date ;
        effectiveto: Date ;
        createdby: number;
        createdon: Date ;
        modifiedby: number;
        modifiedon: Date ;
        approvalstatus: string;
        accountingyear: number;

        
    }