import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Productview } from 'src/app/core/Views/Productview';
import { MessageService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { SmsconfigurationService } from 'src/app/core/Services/masters/smsconfiguration.service';
import { Smsemailalertsetting } from 'src/app/core/Models/SmsEmailalertsetting';
import { Smslog } from 'src/app/core/Models/Smslog';
import * as ace from "ace-builds";
import { Emailsmsdocumenttypestatus } from 'src/app/core/Models/Emailsmsdocumenttypestatus';
import { vDocumentTypeSelect } from 'src/app/core/Validators/validation';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';


@Component({
  selector: 'app-c-sms-configuration',
  templateUrl: './c-sms-configuration.component.html',
  styleUrls: ['./c-sms-configuration.component.css']
})
export class CSmsConfigurationComponent implements OnInit {
  toolbarOptions: string;
  _smsemailsettingid: number;
  ref: any;
  VALUE: any;
  selectedrows: any;
  _smsConfigurationform: FormGroup;
  _smsConfigurationRefernceform: FormGroup;
  _smsConfigurationStatusform: FormGroup;
  _smsconfiguration: Smsemailalertsetting;
  pSelectableRow: any;
  _action: string = "create";
  _submitted = false;
  _IsProgressSpinner: boolean = false;
  _DocumentType
  _DocumentStatus
  _DataElement
  _userid = sessionStorage["userid"];
  isDisableaddElement: boolean = false;
  _smsconfigurationOBJ: any;
  selectedtab: number = 0;
  ManageDialog: Boolean;
  _docstatussave: Emailsmsdocumenttypestatus;
  _Action: IAction;

  //Ecommerce
  ecrecipientgroup: any[] = [{ name: 'NewsLetter', value: '1' }, { name: 'ECommerece Customer', value: '2' }];
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  _nlConfigurationform: FormGroup;
  _nlConfigurationRefernceform: FormGroup;
  _nlConfigurationStatusform: FormGroup;
  ecrecipientlist: any[] = [];
  group: any[] = [];
  _smsObj: any;
  recipientlist: any;
  template: any;
  _selectedEmailIDs: any[] = [];
  constructor(private fb: FormBuilder, private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _SmsConfigurationService: SmsconfigurationService,
    private _router: Router, private utility: CommonUtilities,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,
    ) {
    this._smsemailsettingid = history.state?.smsemailsettingid ? history.state?.smsemailsettingid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
  }

  InitializeForm() {
    this._smsConfigurationform = this.fb.group({
      branchid: [],
      smsemailsettingid: [],
      documenttypeid: ['', vDocumentTypeSelect],
      documentstatusid: ['', vDocumentTypeSelect],
      documentstatusname: [],
      documenttypename: [],
      emailtemplatename: ['', vDocumentTypeSelect],
      emailtemplateurl: [],
      messagetemplate: ['', vDocumentTypeSelect],
      messagetemplateinhtml: [],
      customerSms: [],
      customerEmail: [],
      recordstatus: [],
      recordstatusname: [],
      subject: [],
      emailsubject: [],
      smsStarttime: [],
      smsEndtime: [],
      mailGroup: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      //elementdata:[]
    })
    this._smsConfigurationRefernceform = this.fb.group({
      elementdatasms: [],
      elementdataemail: [],
      recipientgroup: [],
      recipientlist: []
    });
    this._smsConfigurationStatusform = this.fb.group({
      documentstatusid: [],
      documenttypeid: [],
      documenttypename: [],
      documentstatusname: [],
      recordstatus: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
    //Ecommerce
    this._nlConfigurationform = this.fb.group({
      branchid: [],
      smsemailsettingid: [0],
      documenttypeid: ['', vDocumentTypeSelect],
      documentstatusid: ['', vDocumentTypeSelect],
      documentstatusname: [],
      documenttypename: [],
      emailtemplatename: ['', vDocumentTypeSelect],
      emailtemplateurl: [],
      messagetemplate: ['', vDocumentTypeSelect],
      messagetemplateinhtml: [],
      customerSms: [],
      customerEmail: [],
      recordstatus: [],
      recordstatusname: [],
      subject: [],
      emailsubject: [],
      smsStarttime: [],
      smsEndtime: [],
      mailGroup: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      template: []
      //elementdata:[]
    })
    this._nlConfigurationRefernceform = this.fb.group({
      elementdatasms: [],
      elementdataemail: [],
      recipientgroup: [],
      recipientlist: []
    });
    this._nlConfigurationStatusform = this.fb.group({
      documentstatusid: [],
      documenttypeid: [],
      documenttypename: [],
      documentstatusname: [],
      recordstatus: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(144);
    this.utility.pageLoadScript();
    this.HotKeyIntegration();
    this.InitializeForm();

    this._IsProgressSpinner = true;
    this._SmsConfigurationService.PageOnLoad(this._smsemailsettingid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._DocumentType = resultJSON.documenttypes;
      this._IsProgressSpinner = false;

      if (this._action == 'edit' || this._action == 'view') {
        // this.hideInCreatePage=false; 
        const updateJSON = JSON.parse(JSON.stringify(result));
        // this._smsconfiguration.branchid =updateJSON.smsemailalertsettings.branchid;
        // this._smsConfigurationform.get('branchid').setValue(updateJSON.smsemailalertsettings.branchid);
        this._smsConfigurationform.setValue(updateJSON.smsemailalertsettings[0]);
      }
      //this._DataElement=resultJSON.dataelements;

    });
  }
  GetDocumentStatus(event) {
    var Documentid = event.value;
    this._IsProgressSpinner = true;
    this._SmsConfigurationService.GetDocumentStatus(Documentid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._DataElement = resultJSON.dataelements;

      this._DocumentStatus = resultJSON.documenttypestatus;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  //ECommerce
  getTomail() {
    this.group = this._nlConfigurationRefernceform.get('recipientgroup').value;
    this._smsObj = { "Group": this.group || [] }
    this._IsProgressSpinner = true;
    this._SmsConfigurationService.getECMailID(this._smsObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      // this.ecrecipientlist = resultJSON.ecrecipientlist; 
      this.ecrecipientlist = [];
      //  mailLists.mailid="";
      // mailLists=resultJSON.ecrecipientlist
      if (resultJSON.ecrecipientlist != null) {
        for (let i = 0; i < resultJSON.ecrecipientlist.length; i++) {
          //mailLists[i]=resultJSON.ecrecipientlist[i];
          this.ecrecipientlist.push({ mailid: resultJSON.ecrecipientlist[i] });
        }
      }
      //this.ecrecipientlist.map((item) => this.ecrecipientlist.push(item.mailid));
      //this._excludeproduccategories = (this._includeproduccategories || []).filter(f => !event.value.includes(f.productcategoryid));
      var emaillistid: number[] = [];
      Object.keys(this.ecrecipientlist).map((Index) => {
        emaillistid.push(this.ecrecipientlist[Index].mailid);
      });
      this._selectedEmailIDs = emaillistid;
      console.log("emailids", this.ecrecipientlist)
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSend() {
    this.recipientlist = this._nlConfigurationRefernceform.get('recipientlist').value;
    // let Recipientlist:any[]=[]
    this.template = this._nlConfigurationform.get('template').value;
    this._nlConfigurationform.patchValue({ "messagetemplate": this.template });
    var newsletter = this._nlConfigurationform.getRawValue();
    // for(let i=0;i<this.recipientlist.length;i++){
    //   Recipientlist[i]=this.recipientlist[i];
    // }
    this._IsProgressSpinner = true;
    this._smsObj = { "Recipientlist": this.recipientlist || [], "Template": this.template || [], "Newsletter": newsletter || [] }
    this._SmsConfigurationService.SendNewsletter(this._smsObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        for (let i = 0; i < resultJSON.ecrecipientlist.length; i++) {
          //mailLists[i]=resultJSON.ecrecipientlist[i];
          this.ecrecipientlist.push({ mailid: resultJSON.ecrecipientlist[i] });
        }
      }
      
      // this.ecrecipientlist = resultJSON.ecrecipientlist; 

      //  mailLists.mailid="";
      // mailLists=resultJSON.ecrecipientlist
    
       
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  AddElementSMS() {
    this.VALUE = this._smsConfigurationform.get('subject').value;
    //var ref = (this._smsConfigurationform.get('messagestatus').value.trim())+'{'+ VALUE+'}'.trim();
    this.ref = this.VALUE + '{' + (this._smsConfigurationRefernceform.get('elementdatasms').value.replaceAll('</p>', '') + '}');
    this._smsConfigurationform.get('subject').setValue(this.ref.replaceAll('<p>', '').replaceAll('</p>', ''));


  }
  AddElementEmail() {
    this.VALUE = this._smsConfigurationform.get('emailsubject').value;
    //var ref = (this._smsConfigurationform.get('messagestatus').value.trim())+'{'+ VALUE+'}'.trim();
    this.ref = this.VALUE + '{' + (this._smsConfigurationRefernceform.get('elementdataemail').value.replaceAll('</p>', '') + '}');
    this._smsConfigurationform.get('emailsubject').setValue(this.ref.replaceAll('<p>', '').replaceAll('</p>', ''));

  }
  showBasicDialog() {
    this.ManageDialog = true;
  }
  DocStatusSave() {
    //this._suppliersubmitted = true;
    if (this._smsConfigurationStatusform.valid) {
      this._docstatussave = this._smsConfigurationStatusform.value;
      this._docstatussave.documentstatusid = 0;
      this._docstatussave.documenttypeid = this._smsConfigurationform.get('documenttypeid').value
      this._docstatussave.documentstatusname = this._smsConfigurationStatusform.get('documentstatusname').value;
      this._docstatussave.accountingyear = new Date().getFullYear();
      this._docstatussave.createdon = new Date();
      this._docstatussave.createdby = sessionStorage["userid"];

    }
    //this.onEd()
    this._IsProgressSpinner = true;
    let documenttype = { "documenttypesave": this._docstatussave || [] };
    this._SmsConfigurationService.CreateStatus(documenttype).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
        this._IsProgressSpinner = false;
        this._DocumentStatus = resultJSON.documenttypestatus;
        // this._Quoterequestform.get("suppliertype").setValue("MSC00135");
        // this.getSupplier();
        // this._IsProgressSpinner = false;
        // this.ManageDialog = false;
        // this.isDisablequicksave = false;
        // this.cancel(null);
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onSave() {
    this._submitted = true;
    if (this._smsConfigurationform.valid) {
      this._smsconfiguration = this._smsConfigurationform.value;
      this._smsconfigurationOBJ = {
        "Smsemailalertsetting": this._smsconfiguration
      };
      // this.onEd();
      if (this._action == 'create') {
        this._smsconfiguration.createdon = new Date();
        this._smsconfiguration.createdby = sessionStorage["userid"];
        this._smsconfiguration.branchid = sessionStorage["currentbranchid"];
        this._smsconfiguration.accountingyear = new Date().getFullYear();
        this._smsconfiguration.smsemailsettingid = 0;
        this._smsconfiguration.recordstatus = "MSC00001";
        this._IsProgressSpinner = true;
        //this.onEd();
        this._IsProgressSpinner = true;
        this._SmsConfigurationService.create(this._smsconfigurationOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._IsProgressSpinner = false;
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsProgressSpinner = false;
            this.reset(null);
          }
          else {

            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
      else if (this._action == 'edit') {
        //  this._smsconfiguration.modifiedon = new Date();
        //  this._smsconfiguration.modifiedby= sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._SmsConfigurationService.edit(this._smsconfigurationOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._IsProgressSpinner = false;
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this._IsProgressSpinner = false;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
    }
  }
  goBack(event) {
    this._router.navigate(['/vSmsConfiguration']);
  }
  reset(event) {

  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._smsconfigurationOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
}
