<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Critical Stock Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_branches" name="Branch"
                                    optionLabel="branchname" optionValue="branchid" [(ngModel)]="branchid"
                                    (onChange)="GetProducts()" [filter]="true" filterBy="branchname"
                                    [virtualScroll]="true" itemSize="30">
                                    <ng-template let-account pTemplate="item">
                                        <div
                                            [style]="account.branchstatus == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                            {{account.branchname}}</div>
                                    </ng-template>
                                </p-dropdown>
                                <label for="deliverytype">Branch Name <span class="hlt-txt"></span></label>
                            </span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_rptcriticalstockviews" [scrollable]="true" scrollHeight="450px" [rows]="10"
                        [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['producttypename','categoryname','productname','brandname','minimumlevel','stock']"
                        [rowHover]="true" dataKey="productname"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 80px;" pSortableColumn="sno">S.No <p-sortIcon
                                        field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="producttypename">Product Type Name<p-sortIcon
                                        field="producttypename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="categoryname">Product Category Name <p-sortIcon
                                        field="categoryname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product Name<p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="brandname">Brand Name<p-sortIcon field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="minimumlevel">Minimum Stock Level<p-sortIcon field="minimumlevel">
                                    </p-sortIcon>
                                <th pSortableColumn="stock">Current Stock<p-sortIcon field="stock">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_rptcriticalstockview let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 80px;">
                                    {{i+ 1}}
                                </td>
                                <td>
                                    {{_rptcriticalstockview.producttypename}}
                                </td>
                                <td>
                                    {{_rptcriticalstockview.categoryname}}
                                </td>
                                <td>
                                    {{_rptcriticalstockview.productname}}
                                </td>
                                <td>
                                    {{_rptcriticalstockview.brandname}}
                                </td>
                                <td>
                                    {{_rptcriticalstockview.minimumlevel}}
                                </td>
                                <td>
                                    {{_rptcriticalstockview.stock}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>