<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Request for Quote</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" [disabled]="isDisabledsave" (click)="onSave()" title="Save" icon="pi pi-save" class=""></button>
                            <button pButton pRipple label="" [disabled]="isDisablereset" (click)="reset($event)" title="Clear" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRipple title="Send Email" icon="las la-envelope" [disabled]="isDisabledSendEmail"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search" (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_Quoterequestform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="quoterequestno">
                                    <label for="id">Quote No</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" name="quoterequestdate" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="2000:2030" formControlName="quoterequestdate"
                                        dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="quoteDate">Quote Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" [minDate]="today" name="expecteddeliverydate"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030"
                                        formControlName="expecteddeliverydate" dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="deliveryDate">Expected Delivery Date <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_Quoterequestform.get('expecteddeliverydate').touched || _submitted) && _Quoterequestform.get('expecteddeliverydate').errors?.SelectExpectedDeliveryDate">
                                    Please select expected delivery date
                                </span>
                                <!-- if "expected delivery date" is not select, show error as "Please select expected delivery date" -->
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <div class="p-formgroup-inline">
                                    <h6>Supplier Type<span class="hlt-txt">*</span></h6>
                                    <div class="p-field-radiobutton" *ngFor="let PF of _suppliertype">
                                        <p-radioButton name="suppliertype" label={{PF.metasubdescription}} value={{PF.metasubcode}} formControlName="suppliertype" (onClick)="getSupplier($event)">
                                        </p-radioButton>
                                    </div>
                                </div>
                                <span class="text-danger" *ngIf="(_Quoterequestform.get('suppliertype').touched || _submitted) && _Quoterequestform.get('suppliertype').errors?.SupplierTypeRequired">
                                    Please select supplier type
                                </span>
                            </td>
                            <td>
                                <form [formGroup]="_Quoterequestsupplierdetailform" (ngSubmit)="onSave()">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <p-multiSelect [options]="_suppliername" [(ngModel)]="_selectedsupplierid"
                                                optionLabel="suppliername" formControlName="supplierid" [filter]="true"
                                                filterBy="nameandmobile" [virtualScroll]="true" itemSize="30"
                                                optionValue="supplierid" (onChange)="onselectSupplier($event)"
                                                [disabled]="isDisableasuplier">
                                            </p-multiSelect>
                                            <label for="deliveryDate">Supplier Name <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <button class="p-inputgroup-addon p-button" [disabled]="isDisableaddsuplier" title="Add" type="button" (click)="showBasicDialog()">
                                            <i class="las la-plus"></i>
                                        </button>
                                    </div>
                                    <span class="text-danger" *ngIf="(_Quoterequestsupplierdetailform.get('supplierid').touched || _submitted) && _Quoterequestsupplierdetailform.get('supplierid').errors?.SelectSupplier">
                                        Please select supplier name
                                    </span>
                                </form>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_Quoterequestproductdetailform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <p-multiSelect [options]="_producttype" optionLabel="producttypename" optionValue="producttypeid" formControlName="producttypeid" (onChange)="getProductCategory($event)" defaultLabel="Product Type">
                                </p-multiSelect>
                            </td>
                            <td style="width: 20%;">
                                <p-multiSelect [options]="_productcategory" optionLabel="categoryname" optionValue="productcategoryid" [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}" (onChange)="getProduct($event)" defaultLabel="Product Category">
                                </p-multiSelect>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">

                                    <p-autoComplete formControlName="productid" [suggestions]="filteredProducts"
                                        (completeMethod)="filterProducts($event)" field="productname"
                                        (onSelect)="getVariant($event)">
                                        <ng-template let-product pTemplate="item">
                                            <div>{{product.productname}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_Quoterequestproductdetailform.get('productid').touched || _productsubmitted) && _Quoterequestproductdetailform.get('productid').errors?.ProductNameRequired">
                                    Please select product
                                </span>
                                <!-- 19.   if "product name" is not select, show error as "Please select product  name" -->
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>

                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_variantdetails"
                                        optionValue="productvariantdetailid" formControlName="variantid"
                                        optionLabel="variantdescription">
                                    </p-dropdown>
                                    <label for="variantid">Variant </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-inputNumber *ngIf="hidequantity == true" formControlName="quantity"
                                        autocomplete="off" maxlength="13" (keypress)="keypress.kpNumber($event)"
                                        mode="decimal" [minFractionDigits]="0"
                                        [maxFractionDigits]="0">
                                    </p-inputNumber>
                                    <label *ngIf="hidequantity == true" for="quantity">Quantity </label>
                                </span>

                                <span class="p-float-label">
                                    <p-inputNumber *ngIf="hidequantity != true" formControlName="quantity"
                                        autocomplete="off" maxlength="13" (keypress)="keypress.kpNumber($event)"
                                        mode="decimal" [minFractionDigits]="3"
                                        [maxFractionDigits]="3">
                                    </p-inputNumber>
                                    <label *ngIf="hidequantity != true" for="quantity"> Quantity <span
                                            class="hlt-txt">*</span>
                                </label>
                                </span>
                                <span class="text-danger" *ngIf="(_Quoterequestproductdetailform.get('quantity').touched || _productsubmitted) && _Quoterequestproductdetailform.get('quantity').errors?.QuantityRequired">
                                    Please select quantity
                                </span>

                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_uomdetails"
                                        optionValue="metasubcode" formControlName="uom" optionLabel="metasubdescription"
                                        [disabled]="isdisableduom">
                                    </p-dropdown>
                                    <label for="metasubdescription">UOM <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_Quoterequestproductdetailform.get('uom').touched || _productsubmitted) && _Quoterequestproductdetailform.get('uom').errors?.SelectUOM">
                                    Select UOM
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Add" icon="las la-check" [disabled]="isDisabledaddproduct" (click)="onAddProduct($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" [disabled]="isDisabledclearproduct" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-table #dt [value]="_Quoterequestproductdetails" [rows]="15" [paginator]="true" [rowHover]="true" [rowsPerPageOptions]="[15,25,50,100,200]" editMode="row" dataKey="productname" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="SelectedProductDetailsrows">
                        <ng-template pTemplate="header">

                            <tr>
                                <th style="width:100px;text-align: center;">Action</th>
                                <th>Product Name</th>
                                <th>Variant</th>
                                <th>Quantity</th>
                                <th>UOM</th>
                                <th>Remarks</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Quoterequestproductdetail let-ri="rowIndex">
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple label="" title="Remove" icon="pi pi-trash" class="p-button-danger" [disabled]="isDisabledremoveproduct" (click)="RemoveProductDetail(_Quoterequestproductdetail)"></button>
                                </td>
                                <td>
                                    {{_Quoterequestproductdetail.productname}}
                                </td>
                                <td>
                                    {{_Quoterequestproductdetail.variantname}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" *ngIf="_Quoterequestproductdetail.uom != 'MSC00039'&& _action != 'view' " maxlength="14" (keypress)="keypress.kpNumber($event)" (Keydown.enter)="keypress.kpNumber($event)" [(ngModel)]="_Quoterequestproductdetail.quantity"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                            </p-inputNumber>
                                            <p-inputNumber autocomplete="off" *ngIf="_Quoterequestproductdetail.uom == 'MSC00039'&& _action != 'view' " maxlength="14" (keypress)="keypress.kpNumber($event)" (Keydown.enter)="keypress.kpNumber($event)" [(ngModel)]="_Quoterequestproductdetail.quantity"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                            </p-inputNumber>
                                            <!-- <input type="text" [(ngModel)]="_Quoterequestproductdetail.quantity" pInputText *ngIf="_action != 'view'" (keyup)="allocatequantity(_Quoterequestproductdetail,$event)" (focusout)="allocatequantity(_Quoterequestproductdetail,$event)" maxlength="14"  (keypress)="keypress.kpNumber($event)"> -->
                                            <div *ngIf="_action == 'view'">{{_Quoterequestproductdetail.quantity}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Quoterequestproductdetail.quantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText name="quantity" maxlength="14" 
                                                (keypress)="keypress.kpNumber($event)"
                                                [(ngModel)]="_Quoterequestproductdetail.quantity"
                                                *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_Quoterequestproductdetail.quantity}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Quoterequestproductdetail.quantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                                <td>
                                    {{_Quoterequestproductdetail.uomname}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText name="remarks" [(ngModel)]="_Quoterequestproductdetail.remarks" *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_Quoterequestproductdetail.remarks}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Quoterequestproductdetail.remarks}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Terms & Conditions</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_quoteothertermsform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_paymentterms" [disabled]="isDisabledpayment"
                                        optionLabel="termsdescription" optionValue="termsandconditionid"
                                        (onChange)="onSelectPaymentTerms($event)" name="_paymenttermsid"
                                        [(ngModel)]="_selectedPaymentIndex" [disabled]="isDisablePaymentTerms"
                                        formControlName="paymenttermsid"></p-multiSelect>
                                    <label for="_paymenttermsid">Payment Terms </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_deliveryterms" [disabled]="isDisableddelivery"
                                        optionLabel="termsdescription" name="_deliverytermsid"
                                        [(ngModel)]="_selectedDeliveryIndex" optionValue="termsandconditionid"
                                        (onChange)="onSelectDeliveryTerms($event)" [disabled]="isDisableDeliveryTerms"
                                        formControlName="deliveryermsid">
                                    </p-multiSelect>
                                    <label for="_deliverytermsid">Delivery Terms </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_otherterms" [disabled]="isDisabledother"
                                        optionLabel="termsdescription" name="_othertermsid"
                                        [(ngModel)]="_selectedOtherIndex" (onChange)="onSelectOtherTerms($event)"
                                        optionValue="termsandconditionid" [disabled]="isDisableOtherTerms"
                                        formControlName="othertermsid">
                                    </p-multiSelect>
                                    <label for="_othertermsid">Other Terms </label>
                                </span>
                            </td>
                            <td style="width: 20%;">

                            </td>
                            <td style="width: 20%;"></td>

                        </tr>
                        <!-- <tr>
                            <td>
                                <button pButton pRipple label="Send Email" [disabled]="isDisabledissendmail"
                                    icon="las la-envelope" class=""></button>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr> -->


                    </table>
                </form>
                <!-- </form> -->
                <!-- <table>
                    <td>
                       
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </table> -->

                <p-dialog header="Supplier Details" [(visible)]="ManageDialog" [style]="{width: '350px',height:'300px'}" [modal]="true" [maximizable]="false">
                    <ng-template pTemplate="content">

                        <table class="normal-table">
                            <tr>
                                <td>
                                    <form [formGroup]="_supplierquicksaveform" (ngSubmit)="onSave()">
                                        <span class="p-float-label">
                                            <input type="text" name="suppliername" formControlName="suppliername"
                                                pInputText>

                                            <label for="barCode">Supplier Name<span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_supplierquicksaveform.get('suppliername').touched || _suppliersubmitted) && _supplierquicksaveform.get('suppliername').errors?.SupplierNameRequired">
                                            Please Enter Supplier Name
                                        </span>
                                    </form>
                                </td>
                            </tr>

                            <tr>
                                <!-- <form [formGroup]="_supplierquicksavedetailform" (ngSubmit)="onSave()">      -->
                                <td>
                                    <form [formGroup]="_supplierquicksavedetailform" (ngSubmit)="onSave()">
                                        <span class="p-float-label">
                                            <input type="text" name="contactpersonname"
                                                formControlName="contactpersonname" pInputText>

                                            <label for="barCode">Contact Person<span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_supplierquicksavedetailform.get('contactpersonname').touched || _suppliersubmitted) && _supplierquicksavedetailform.get('contactpersonname').errors?.ContactPersonNameRequired">
                                            Please Enter Contact Person
                                        </span>
                                    </form>
                                </td>
                                <!-- </form> -->
                            </tr>

                            <tr>

                                <td>
                                    <form [formGroup]="_supplierquicksavedetailform" (ngSubmit)="onSave()">
                                        <span class="p-float-label">
                                            <input type="number" maxlength="10" name="mobileno"
                                                formControlName="mobileno" pInputText>

                                            <label for="barCode">Contact No<span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_supplierquicksavedetailform.get('mobileno').touched || _suppliersubmitted) && _supplierquicksavedetailform.get('mobileno').errors?.MobileNumberRequired">
                                            Please Enter Contact No
                                        </span>
                                    </form>
                                </td>
                                <!-- </form> -->
                            </tr>

                            <tr>
                                <!-- <form [formGroup]="_supplierquicksavedetailform" (ngSubmit)="onSave()">      -->
                                <td>
                                    <form [formGroup]="_supplierquicksavedetailform" (ngSubmit)="onSave()">
                                        <span class="p-float-label">

                                            <input type="text" name="mailid" formControlName="mailid" pInputText>

                                            <label for="barCode">Email Id<span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_supplierquicksavedetailform.get('mailid').touched || _suppliersubmitted) && _supplierquicksavedetailform.get('mailid').errors?.EmailIDRequired">
                                            Please Enter Email Id
                                        </span>
                                    </form>
                                </td>
                                <!-- </form> -->
                            </tr>

                        </table>


                    </ng-template>
                    <ng-template pTemplate="footer">
                        <button pButton pRipple label="Quick Save" [disabled]="isDisablequicksave" (click)="quicksave()" icon="las la-check" class="p-button-text"></button>
                        <button pButton pRipple label="Cancel" (click)="cancel()" icon="pi pi-times" class="p-button-text"></button>
                    </ng-template>
                </p-dialog>
                <!-- <table>
                    <td>
                        
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </table> -->

                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>