<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Month Wise Stock Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_monthwisestockreportform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_yearlist" optionValue="year"
                                        optionLabel="year" formControlName="year">
                                    </p-dropdown>
                                    <label for="customername">Year <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_branchlist" optionLabel="branchname"
                                        formControlName="branchid" optionValue="branchid" displaySelectedLabel=true
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Branch </label>
                                </span>
                            </td>

                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_producttype"
                                        optionLabel="producttypename" formControlName="producttypeid"
                                        optionValue="producttypeid" (onChange)="getProductcategory($event)"
                                        displaySelectedLabel=true
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type </label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _productpriceform.get('producttypeid').touched) && _productpriceform.get('producttypeid').errors?.SelectProductType">
                                    Please Select Atleast One Product Type
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_productcategory"
                                        formControlName="productcategoryid" optionLabel="categoryname"
                                        optionValue="productcategoryid" (onChange)="getProductname($event)"
                                        displaySelectedLabel=true
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category </label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _productpriceform.get('productcategoryid').touched) && _productpriceform.get('productcategoryid').errors?.SelectProductCategory">
                                    Please Select Atleast One Product Category
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_productname" formControlName="productid"
                                        optionLabel="productname" optionValue="productid" displaySelectedLabel=true
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _productpriceform.get('productid').touched) && _productpriceform.get('productid').errors?.SelectProductName">
                                    Please Select Atleast One Product Name
                                </span> -->
                            </td>

                        </tr>

                        <tr>
                            <td>
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox name="isdamaged" binary="true" formControlName="isdamaged">
                                        </p-checkbox>
                                        <label for="isdamaged">Is Damaged?</label>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <button pButton pRipple label="Search" type="button" (click)="search()" title="Search"
                                    icon="pi pi-search"></button>
                                <button pButton pRipple label="Clear" title="Clear" (click)="clear()" icon="pi pi-times"
                                    class="p-button-danger"></button>
                            </td>
                        </tr>

                    </table>
                </form>

                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>

                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>

                    </p-toolbar>

                    <p-table #dt [value]="_MonthWiseStocks" [scrollable]="true" scrollHeight="450px" [rows]="10"
                        [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['productname','purchaserate','retail1m','retail1i','retail1e','retail2m','retail2i','retail2e','onlinem','onlinei','onlinee','wholsalem','wholsalei','wholsalee','specialm','speciali','speciale','projectm','projecti','projecte']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="productname"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" pSortableColumn="productname">Product Type Name<p-sortIcon
                                        field="productname">
                                    </p-sortIcon>
                                </th>
                                <th rowspan="2" pSortableColumn="purchaserate">Product Category Name
                                    <p-sortIcon field="purchaserate">
                                    </p-sortIcon>
                                </th>
                                <th rowspan="2" pSortableColumn="purchaserate">Product Name
                                    <p-sortIcon field="purchaserate">
                                    </p-sortIcon>
                                </th>
                                <th class="text-center" colspan="12">Months
                                </th>

                            </tr>
                            <tr>
                                <th>
                                    Jan
                                </th>
                                <th>
                                    Feb
                                </th>
                                <th>
                                    Mar
                                </th>
                                <th>
                                    Apr
                                </th>
                                <th>
                                    May
                                </th>
                                <th>
                                    Jun
                                </th>
                                <th>
                                    Jul
                                </th>
                                <th>
                                    Aug
                                </th>
                                <th>
                                    Sep
                                </th>
                                <th>
                                    Oct
                                </th>
                                <th>
                                    Nov
                                </th>
                                <th>
                                    Dec
                                </th>

                            </tr>

                        </ng-template>
                        <ng-template pTemplate="body" let-_Monthwisestock let-i="rowIndex">
                            <tr>
                                <td>
                                    {{_Monthwisestock.producttypename }}
                                </td>
                                <td>
                                    {{_Monthwisestock.productcategoryname }}
                                </td>
                                <td>
                                    {{_Monthwisestock.productname }}
                                </td>

                                <td>
                                    {{_Monthwisestock.January}}
                                </td>
                                <td>
                                    {{_Monthwisestock.February }}
                                </td>
                                <td>
                                    {{_Monthwisestock.March }}
                                </td>
                                <td>
                                    {{_Monthwisestock.April }}
                                </td>
                                <td>
                                    {{_Monthwisestock.May }}
                                </td>
                                <td>
                                    {{_Monthwisestock.June }}
                                </td>
                                <td>
                                    {{_Monthwisestock.July }}
                                </td>
                                <td>
                                    {{_Monthwisestock.August }}
                                </td>

                                <td>
                                    {{_Monthwisestock.September }}
                                </td>
                                <td>
                                    {{_Monthwisestock.October }}
                                </td>
                                <td>
                                    {{_Monthwisestock.November }}
                                </td>
                                <td>
                                    {{_Monthwisestock.December }}
                                </td>

                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                <!-- <table>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </table> -->
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>