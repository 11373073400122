import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { disableDebugTools } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { State } from 'src/app/core/Models/State';
import { StateService } from 'src/app/core/Services/masters/state.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Stateview } from 'src/app/core/Views/Stateview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-state',
  templateUrl: './v-state.component.html',
  styleUrls: ['./v-state.component.css']
})
export class VStateComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  hideInCreatePage: Boolean;
  cols: any[];
  _state: State;
  _State: State;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _statesview: Stateview[]=[];
  _stateview: Stateview;
  _Action: IAction;
  _IsProgressSpinner: boolean = true;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _StateService: StateService,
    private router: Router,
    private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,
    private exportUtility: ExportUtility
  ) {
    this._Action = this._AccessRightsService.getAccessRights(11);
    this.HotKeyIntegration();
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(11);
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'countryname', header: 'Contry Name' },
      { field: 'statecode', header: 'State Code' },
      { field: 'statename', header: 'Sate Name' },
      { field: 'statestatusname', header: 'Status' },
    ]
    this._IsProgressSpinner = true;
    this._StateService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._statesview = resultJSON.stateviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  onRowSelect(event) { }
  onRowUnselect(event) { }
  delete(event) { }
  view(event) {
    this.router.navigate(['/cState'], { state: { stateid: event.stateid, action: 'view' } });
  }
  edit(event) {
    this.router.navigate(['/cState'], { state: { stateid: event.stateid, action: 'edit' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cState']);
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this State <b>"' + event.statename + '"</b> ?',
      accept: () => {
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    var stateid = event.stateid;
    this._IsProgressSpinner = true;
    this._StateService.Cancel(stateid, sessionStorage["userid"]).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_15);
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._statesview = resultJSON.stateviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'Province', 'xlsx');
  }
  ExportToPdf() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.ExportToPdf(AssetRegistrationList, 'Province.pdf');
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._statesview.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Country"] = element.countryname;
      assetregistration["Province Code"] = element.statecode;
      assetregistration["Province Name"] = element.statename;
      assetregistration["Status"] = element.statestatusname;
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
}