import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DbsyncComponent } from './dbsync/dbsync.component';
import { GeneratebackupComponent } from './generatebackup/generatebackup.component';

const routes: Routes = [
  { path: 'generatebackup', component: GeneratebackupComponent },
  { path: 'dbsync', component: DbsyncComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnlineOfflineRoutingModule { }
