import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptposummaryService {

  public _Action: IAction;
  public _httpOptions;
  public _RptPOSummaryService = environment.RptPOSummaryService;

  constructor(private http: HttpClient) { }
  // Page Load :
  PageOnload(): Observable<any> {
    var Query = this._RptPOSummaryService + '/PageInitialize';
    
    return this.http.get<{ data: any }>(Query);
  }
  getProductCategory(producttype:object) {
    var Query = this._RptPOSummaryService+'/getProductCategories';
    
    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory:object) {
    var Query = this._RptPOSummaryService + '/getProducts';
    
    return this.http.post<{ data: any }>(Query, productcategory);
  }

  Print(obj:object) {
    var Query = this._RptPOSummaryService + '/Print';
    return this.http.post(Query,obj, { responseType: 'blob' });
  }
}
