<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product Price Change History</h3>
                    </div>
                    <!-- <div class="col-md-7 text-right" [hidden]="true">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add"
                                class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_pricechangeform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="effectivefrom" formControlName="_effectiveFrom" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="effectivefrom"> From Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="effectiveto" formControlName="_effectiveTo" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="effectiveto"> To Date </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <!-- <form [formGroup]="_pricechangetypeform"> -->
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_ProductTypes"
                                        optionLabel="producttypename" optionValue="producttypeid"
                                        formControlName="producttypeid" displaySelectedLabel=true
                                        (onChange)="GetProductCategory($event)" [selectionLimit]="25">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type</label>
                                </span>
                                <!-- </form> -->
                            </td>
                            <td style="width: 20%;">
                                <!-- <form [formGroup]="_pricechangetypeform"> -->
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_ProductCategories"
                                        optionLabel="categoryname" optionValue="productcategoryid"
                                        formControlName="productcategoryid" displaySelectedLabel=true
                                        (onChange)="GetProductName($event)" [selectionLimit]="25">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category</label>
                                </span>
                                <!-- </form> -->
                            </td>
                            <td style="width: 20%;">
                                <!-- <form [formGroup]="_pricechangetypeform"> -->
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_ProductNames" optionLabel="productname"
                                        optionValue="productid" formControlName="productid" displaySelectedLabel=true
                                        [selectionLimit]="25">
                                    </p-multiSelect>
                                    <label for="productid">Product Name</label>
                                </span>
                                <!-- </form> -->
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                                        formControlName="brandid" [virtualScroll]="true" itemSize="30"
                                        [selectionLimit]="25">
                                    </p-multiSelect>
                                    <label for="brandid">Brand Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_userlist" optionLabel="username" optionValue="userid"
                                        formControlName="userid" [virtualScroll]="true" itemSize="30"
                                        [selectionLimit]="25">
                                    </p-multiSelect>
                                    <label for="userid">User Name </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" icon="pi pi-search" class="p-button-success" (click)="ProductPriceDetailSearch()"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-trash" (click)="ClearFields()" class="p-button-danger"></button>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_Pricechangeviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['historydate','producttypename','productcategoryname','productname',
                        'currencyname','purchaserate','purchaserateinusd', 'brandname' , 'modifiedby', 'modifiedon']" [(selection)]="selectedrows" [rowHover]="true" dataKey="salesquotationno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 70px;" pSortableColumn="sno">S.No
                                    <p-sortIcon field="sno">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;width: 120px;" pSortableColumn="version">History Version
                                    <p-sortIcon field="version">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="historydate" style="text-align: center;">Date
                                    <p-sortIcon field="historydate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="producttypename" style="text-align: center;">Product Type
                                    <p-sortIcon field="producttypename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productcategoryname" style="text-align: center; width: 200px;">
                                    Product Category
                                    <p-sortIcon field="productcategoryname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname" style="text-align: center;">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="brandname" style="text-align: center;">Brand Name
                                    <p-sortIcon field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname" style="text-align: center;">Currency
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="purchaserate" style="text-align: center;">Cost Price
                                    <p-sortIcon field="purchaserate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="purchaserateinusd" style="text-align: center;">Cost Price in USD
                                    <p-sortIcon field="purchaserateinusd">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="modifiedby" style="text-align: center;"> Modified By
                                    <p-sortIcon field="modifiedby">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="modifiedon" style="text-align: center;">Modified on
                                    <p-sortIcon field="modifiedon">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productstatus" style="text-align: center;">Status
                                    <p-sortIcon field="productstatus">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Pricechangeview let-i="rowIndex">
                            <tr>
                                <td style="text-align: center; width: 70px;">
                                    {{i+1}}
                                </td>
                                <td style="text-align: center; width: 120px;">
                                    {{_Pricechangeview.version}}
                                </td>
                                <td>
                                    {{_Pricechangeview.historydate | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_Pricechangeview.producttypename}}
                                </td>
                                <td>
                                    {{_Pricechangeview.productcategoryname}}
                                </td>
                                <td>
                                    {{_Pricechangeview.productname}}
                                </td>
                                <td>
                                    {{_Pricechangeview.brandname}}
                                </td>
                                <td>
                                    {{_Pricechangeview.currencyname}}
                                </td>
                                <td style="text-align: right;">
                                    {{_Pricechangeview.purchaserate}}
                                </td>
                                <td style="text-align: right;">
                                    {{_Pricechangeview.purchaserateinusd}}
                                </td>
                                <td>
                                    {{_Pricechangeview.modifiedby}}
                                </td>
                                <td>
                                    {{_Pricechangeview.modifiedon | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_Pricechangeview.productstatus}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>