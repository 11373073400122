import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { MaterialConsumptionreportService } from 'src/app/core/Services/reports/Stock/MaterialConsumptionReport/material-consumptionreport.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
	selector: 'app-material-consumption-report',
	templateUrl: './material-consumption-report.component.html',
	styleUrls: ['./material-consumption-report.component.css']
})
export class MaterialConsumptionReportComponent implements OnInit {
	_BranchList: any[] = []
	_TypeList: any[] = []
	_ProductTypeList: any[] = []
	_ProductCategoryList: any[] = []
	_ProductList: any[] = []
	_BranchId: any[] = []
	_TypeId: any[] = []
	_ProductTypeId: any[] = []
	_ProductCategoryId: any[] = []
	_ProductId: any[] = []
	_GenerateReport: any[] = []
	_IsProgressSpinner: boolean = true;
	fromdate: Date;
	todate: Date;
	datepipe = new DatePipe('en-US');
	TotalQty: number = 0
	TotalPrice: number = 0
	mindate: Date;
	maxdate: Date;
	constructor(
		private _ExceptionService: CustomExceptionService,
		private _Service: MaterialConsumptionreportService,
		private utility: CommonUtilities,
		private exportUtility: ExportUtility
	) { }
	initialise() {
		let headerbranch = parseInt(sessionStorage["BranchID"])
		this._BranchId.push(headerbranch)
		this._BranchId = [...this._BranchId]
	}
	ngOnInit(): void {
		this.utility.pageLoadScript();
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this._IsProgressSpinner = false;
		this.initialise();

		if (sessionStorage['Environmentenddate'] == "null") {
			this.fromdate = new Date()
			this.todate = new Date()
			this.maxdate = new Date();
		}
		else {
			this.fromdate = new Date(sessionStorage['Environmentenddate'])
			this.todate = new Date(sessionStorage['Environmentenddate'])
		}

		let TaskName = "PageOnLoad"
		let Data = {
			"TaskName": TaskName,
			"BranchId": this._BranchId || [],
			"TypeId": this._TypeId || [],
			"ProductTypeId": this._ProductTypeId || [],
			"ProductCategoryId": this._ProductCategoryId || [],
			"ProductId": this._ProductId || [],
			"FromDate": null,
			"ToDate": null
		}
		this._IsProgressSpinner = true;
		this._Service.GenerateReport(Data).subscribe((result: any) => {
			this._IsProgressSpinner = false;
			if (result && result.tranStatus.result) {
				console.log(result, 'objectes');
				this._BranchList = result.branchList;
				this._TypeList = result.typeList;
				this._ProductTypeList = result.productTypeList;
			} else { this._ExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); }
		}, error => { this._ExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	OnChangeBranch() { }
	GetProductCategory() {
		this._ProductCategoryId = []
		this._ProductId = []
		this._GenerateReport = []
		this.TotalQty = 0
		this.TotalPrice = 0
		if (!this._ProductTypeId || !this._ProductTypeId.length) {
			this._ExceptionService.handleWarning("Please Select Atleast One Product Type")
			return
		}
		let TaskName = "GetProductCategory"
		let Data = {
			"TaskName": TaskName,
			"BranchId": this._BranchId || [],
			"TypeId": this._TypeId || [],
			"ProductTypeId": this._ProductTypeId || [],
			"ProductCategoryId": this._ProductCategoryId || [],
			"ProductId": this._ProductId || [],
			"FromDate": null,
			"ToDate": null
		}
		this._IsProgressSpinner = true;
		this._Service.GenerateReport(Data).subscribe((result: any) => {
			this._IsProgressSpinner = false;
			if (result && result.tranStatus.result) {
				console.log(result, 'objectes');
				this._ProductCategoryList = result.productCategoryList;
			} else { this._ExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); }
		}, error => { this._ExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	GetProduct() {
		this._ProductId = []
		this._GenerateReport = []
		this.TotalQty = 0
		this.TotalPrice = 0
		if (!this._ProductTypeId || !this._ProductTypeId.length) {
			this._ExceptionService.handleWarning("Please Select Atleast One Product Type")
			return
		}
		if (!this._ProductCategoryId || !this._ProductCategoryId.length) {
			this._ExceptionService.handleWarning("Please Select Atleast One Product Category")
			return
		}
		let TaskName = "GetProduct"
		let Data = {
			"TaskName": TaskName,
			"BranchId": this._BranchId || [],
			"TypeId": this._TypeId || [],
			"ProductTypeId": this._ProductTypeId || [],
			"ProductCategoryId": this._ProductCategoryId || [],
			"ProductId": this._ProductId || [],
			"FromDate": null,
			"ToDate": null
		}
		this._IsProgressSpinner = true;
		this._Service.GenerateReport(Data).subscribe((result: any) => {
			this._IsProgressSpinner = false;
			if (result && result.tranStatus.result) {
				console.log(result, 'objectes');
				this._ProductList = result.productList;
			} else { this._ExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); }
		}, error => { this._ExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	GenerateReport() {
		this._GenerateReport = []
		this.TotalQty = 0
		this.TotalPrice = 0
		let TaskName = "GenerateReport"
		let Data = {
			"TaskName": TaskName,
			"BranchId": this._BranchId || [],
			"TypeId": this._TypeId || [],
			"ProductTypeId": this._ProductTypeId || [],
			"ProductCategoryId": this._ProductCategoryId || [],
			"ProductId": this._ProductId || [],
			"FromDate": this.datepipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss'),
			"ToDate": this.datepipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss')
		}
		this._IsProgressSpinner = true;
		this._Service.GenerateReport(Data).subscribe((result: any) => {
			this._IsProgressSpinner = false;
			if (result && result.tranStatus.result) {
				console.log(result, 'objectes');
				this._GenerateReport = result.generateReport;
				if (!this._GenerateReport || !this._GenerateReport.length) {
					this.TotalQty = 0
					this.TotalPrice = 0
					this._ExceptionService.handleWarning("No Data Available")
				} else {
					for (let i = 0; i < this._GenerateReport.length; i++) {
						const x = this._GenerateReport[i];
						this.TotalQty += x.deliveryquantity
						this.TotalPrice += x.price
					}
				}
			} else { this._ExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); }
		}, error => { this._ExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	clear() {
		this._ProductList = []
		this._ProductCategoryList = []
		this._ProductId = []
		this._ProductCategoryId = []
		this._ProductTypeId = []
		this._TypeId = []
		this._BranchId = []
		this._GenerateReport = []
		this.TotalQty = 0
		this.TotalPrice = 0
		this.initialise();
	}
	exportExcel() {
		let AssetRegistrationList = this.GetAssetRegistrationList();
		this.exportUtility.exportExcel(AssetRegistrationList, 'Material_Consumption_Report', 'xlsx');
	}
	ExportToPdf() {
		let finalizedList = this.GetAssetRegistrationList();
		const data = finalizedList.map(Object.values);
		const head = [[
			'Date',
			'Product',
			'Delivery Quantity',
			'Price',
			'UOM',
			'Ref No',
			'Material Request Date',
			'Requested By',
			'Issued By'
		]]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					},
					headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					},
					bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('Material_Consumption_Report.pdf');
			})
		})
	}
	GetAssetRegistrationList() {
		let AssetRegistrationList = [];
		this._GenerateReport.forEach(element => {
			let assetregistration: any = {};
			assetregistration["Date"] = this.datepipe.transform(element.evidencedate, 'dd/MM/yyyy');
			assetregistration["Product"] = element.productname;
			assetregistration["Delivery Quantity"] = element.deliveryquantity;
			assetregistration["Price"] = element.price;
			assetregistration["UOM"] = element.uomname;
			assetregistration["Ref No"] = element.refno;
			assetregistration["Material Request Date"] = this.datepipe.transform(element.materialrequestdate, 'dd/MM/yyyy');
			assetregistration["Requested By"] = element.requestedby;
			assetregistration["Issued By"] = element.issuedby;
			AssetRegistrationList.push(assetregistration);
		});
		return AssetRegistrationList;
	}
}