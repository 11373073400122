import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})

export class PricecomparisonService {
  public _Action: IAction;
  public _PricecomparisonService = environment.PricecomparisonService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(pricecomparisonid: number, levelid: number, branchid: number): Observable<any> {
		const headers = this.header;
    var Query = this._PricecomparisonService + '/' + pricecomparisonid + '/' + levelid + '/' + branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetProductCategory(producttype: object) {
		const headers = this.header;
    var Query = this._PricecomparisonService + '/GetProductCategory';
    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }
  GetProductName(productcategory: object) {
		const headers = this.header;
    var Query = this._PricecomparisonService + '/GetProductName';
    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }
  GetProductPrice(productprice: object) {
		const headers = this.header;
    var Query = this._PricecomparisonService + '/GetProductPrice';
    return this.http.post<{ data: any }>(Query, productprice, { headers });
  }
  GetProduct(product: string, branchid: number) {
		const headers = this.header;
    var Query = this._PricecomparisonService + '/GetProductSearch' + '/' + product + '/' + branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  create(pricecomparison: object) {
		const headers = this.header;
    var Query = this._PricecomparisonService;
    return this.http.post<{ data: any }>(Query, pricecomparison, { headers });
  }

  FetchAll() {
		const headers = this.header;
    var Query = this._PricecomparisonService + '/FetchAll';
    return this.http.get<{ data: any }>(Query, { headers });
  }
  edit(pricecomparison: object) {
		const headers = this.header;
    var Query = this._PricecomparisonService;
    return this.http.put<{ data: any }>(Query, pricecomparison, { headers });
  }
}
