<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-6">
                        <h3>Blog</h3>
                    </div>
                    <div class="col-md-6 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="button" label="" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Add" title="Add" [routerLink]="['/cBlog']" icon="las la-plus"></button>
                          </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="las la-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                        <ng-template pTemplate="right">
                         
                        </ng-template>
                    </p-toolbar>

                    <p-table #dt [value]="blogListGrid" [rows]="20" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['pagename','pageurlname','recordstatusname']"
                        [rowHover]="true" dataKey="id"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>                                
                                <th style="width:100px;" class="text-center">Action</th>
                                <th style="width:250px;" pSortableColumn="pagename">Page Name <p-sortIcon field="pagename"></p-sortIcon>
                                </th>
                                <th pSortableColumn="pageurlname">Page URL <p-sortIcon field="pageurlname"></p-sortIcon>
                                </th>
                               
                                <th pSortableColumn="recordstatusname">Status <p-sortIcon field="recordstatusname"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_blogListGrid>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"  [disabled]="!_Action._View"  (click)="view(_blogListGrid)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button-success p-mr-2" (click)="edit(_blogListGrid)" [disabled]="!_Action._Update"></button>
                                    <button pButton pRipple icon="pi pi-times"  title="Delete"  title="Cancel" class="p-button-danger p-mr-2"  (click)="confirm(_blogListGrid)" [disabled]="!_Action._Delete" [disabled]="_blogListGrid.recordstatus == 'MSC00002'"></button>
                                </td>
                                <td>
                                    {{_blogListGrid.pagename}}
                                </td>
                                <td>
                                    {{_blogListGrid.pageurlname}}
                                </td>
                                <td>
                                    {{_blogListGrid.recordstatusname}}
                                </td>
                               
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>