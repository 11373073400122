<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Locked Quantity</h3>
                    </div>
                </div>
            </div>

            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <div class="row">
                    <div class="col-md-6">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Branch wise Locked Qty.</h5>
                                </div>
                            </div>
                        </div>
                        <table class="normal-table">
                            <tr>
                                <td style="width: 40%;">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <p-autoComplete [(ngModel)]="_Productid" [suggestions]="filteredProducts"
                                                (completeMethod)="filterProducts($event)" field="productname"
                                                (onSelect)="onProductChange($event)">
                                                <ng-template let-product pTemplate="item">
                                                    <div>{{product.productname}}</div>
                                                </ng-template>
                                            </p-autoComplete>
                                            <label for="productid">Product Name</label>
                                        </span>
                                        <button class="p-inputgroup-addon p-button" pTooltip="Cancel" (click)="clear()"
                                            tooltipPosition="top">
                                            <i class="pi pi-times"></i>
                                        </button>
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtBranchWiseStocks.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>

                                </ng-template>
                                <ng-template pTemplate="right">

                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                        (click)="exportExcel()" class="p-mr-2" pTooltip="CSV"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                        class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                        (click)="exportExcel()"></button>
                                    <!-- <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                        class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                        (click)="ExportToPdf()"></button> -->
                                </ng-template>

                            </p-toolbar>
                            <p-table #dtBranchWiseStocks [value]="_BranchWiseLockedQtys" selectionMode="single"
                                [rows]="25" [paginator]="true" [rowsPerPageOptions]="[15,25,50,100]" [columns]="cols"
                                [globalFilterFields]="['branchname','salesinvoiceno','salesinvoice','stocktransfer','stockallocation','overalllockedquantity','intransitstocktransfer','intransitstockallocation','overallintransitquantity']"
                                [rowHover]="true" dataKey="branchid"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedBranchwiseLockedqty">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th rowspan="2" pSortableColumn="branchname">Branch Name<p-sortIcon
                                                field="branchname">
                                            </p-sortIcon>
                                        </th>
                                        <th style="text-align: center;" class="green" colspan="3">Locked Qty

                                        </th>
                                        <th rowspan="2" pSortableColumn="lockedquantity">Total Locked Qty<p-sortIcon
                                                field="lockedquantity">
                                            </p-sortIcon>
                                        </th>
                                        <th style="text-align: center;" colspan="2">In-Transit

                                        </th>
                                        <th rowspan="2" pSortableColumn="branchname">Total In-Transit <p-sortIcon
                                                field="branchname">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th pSortableColumn="branchname">Sales Invoice<p-sortIcon field="branchname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="branchname">Stock Transfer Order<p-sortIcon
                                                field="branchname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="branchname">Stock Allocation<p-sortIcon field="branchname">
                                            </p-sortIcon>
                                        </th>

                                        <th pSortableColumn="branchname">Stock Transfer Order<p-sortIcon
                                                field="branchname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="branchname">Stock Allocation<p-sortIcon field="branchname">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_BranchWiseLockedQty>
                                    <tr [pSelectableRow]="_BranchWiseLockedQty">
                                        <td>
                                            {{_BranchWiseLockedQty.branchname}}
                                        </td>
                                        <td class="text-right">
                                            <a href="javascript:void(0);"
                                                (click)="GetLockedSalesinvoice(_BranchWiseLockedQty)">
                                                {{_BranchWiseLockedQty.salesinvoice}}
                                            </a>

                                        </td>
                                        <td class="text-right">
                                            <a href="javascript:void(0);"
                                                (click)="GetLockedStocktrasnfer(_BranchWiseLockedQty)">
                                                {{_BranchWiseLockedQty.stocktransfer}}
                                            </a>
                                        </td>
                                        <td class="text-right">
                                            <a href="javascript:void(0);"
                                                (click)="GetLockedStockAllocation(_BranchWiseLockedQty)">
                                                {{_BranchWiseLockedQty.stockallocation}}
                                            </a>
                                        </td>
                                        <td class="text-right">
                                            {{_BranchWiseLockedQty.overalllockedquantity}}
                                        </td>
                                        <td class="text-right">
                                            <a href="javascript:void(0);"
                                                (click)="GetIntrasitLockedStocktrasnfer(_BranchWiseLockedQty)">
                                                {{_BranchWiseLockedQty.intransitstocktransfer}}
                                            </a>
                                        </td>
                                        <td class="text-right">
                                            <a href="javascript:void(0);"
                                                (click)="GetIntransitStockAllocation(_BranchWiseLockedQty)">
                                                {{_BranchWiseLockedQty.intransitstockallocation}}
                                            </a>
                                        </td>
                                        <td class="text-right">
                                            {{_BranchWiseLockedQty.overallintransitquantity}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-6" [hidden]="IsShowProductwiseLockedqty">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Reference Wise Locked Qty.</h5>
                                </div>
                            </div>
                        </div>
                        <div class="card" [hidden]="IsLockedQty">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtLockedQtydetails.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>

                                </ng-template>
                                <ng-template pTemplate="right">

                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                        (click)="exportExcelProductdetaillockedqty()" class="p-mr-2" pTooltip="CSV"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                        class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                        (click)="exportExcelProductdetaillockedqty()"></button>
                                    <!-- <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                        class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                        (click)="ExportToPdfProductWiseQuantity()"></button> -->
                                </ng-template>

                            </p-toolbar>
                            <p-table #dtLockedQtydetails [value]="LockedQtydetails" selectionMode="single"
                                [(selection)]="selectedrefno" [paginator]="true" [columns]="LockedQtydetailscols"
                                [rows]="10"
                                [globalFilterFields]="['referenceno','referencedate','deliveryno','requestedbranchname','deliverybranchname']"
                                [rowHover]="true" dataKey="referenceno"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="requestedbranchname">Requested Branch
                                            <p-sortIcon field="requestedbranchname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="deliverybranchname">Delivery Branch<p-sortIcon
                                                field="deliverybranchname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="referenceno">Ref.No.<p-sortIcon field="referenceno">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="referencedate">Ref Date<p-sortIcon field="referencedate">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="deliveryno">Delivery No<p-sortIcon field="deliveryno">
                                            </p-sortIcon>
                                        </th>
                                        <!-- <th pSortableColumn="referencetype">Ref.type<p-sortIcon field="referencetype">
                                            </p-sortIcon>
                                        </th> -->
                                        <th pSortableColumn="lockedquantity"> Locked Qty.<p-sortIcon
                                                field="lockedquantity">
                                            </p-sortIcon>
                                        </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-LockedQtydetail>
                                    <tr>
                                        <td>
                                            {{LockedQtydetail.requestedbranchname}}
                                        </td>
                                        <td>
                                            {{LockedQtydetail.deliverybranchname}}
                                        </td>

                                        <td>
                                            <a href="javascript:void(0);" (click)="onRowSelectBranch(LockedQtydetail)">
                                                {{LockedQtydetail.referenceno}}</a>
                                        </td>
                                        <td>
                                            {{LockedQtydetail.referencedate}}
                                        </td>

                                        <td>

                                            {{LockedQtydetail.deliveryno}}
                                        </td>
                                        <!-- <td>
                                            {{LockedQtydetail.referencetype}}
                                        </td> -->
                                        <td class="text-right">
                                            {{LockedQtydetail.lockedquantity}}
                                        </td>

                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="card" [hidden]="IsInTransitQty">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtLockedQtydetails.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>

                                </ng-template>
                                <ng-template pTemplate="right">

                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                        (click)="exportExcelProductdetaillockedqty()" class="p-mr-2" pTooltip="CSV"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                        class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                        (click)="exportExcelProductdetaillockedqty()"></button>
                                    <!-- <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                        class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                        (click)="ExportToPdfProductWiseQuantity()"></button> -->
                                </ng-template>

                            </p-toolbar>
                            <p-table #dtLockedQtydetails [value]="LockedQtydetails" selectionMode="single"
                                [(selection)]="selectedrefno" [paginator]="true" [columns]="LockedQtydetailscols"
                                [rows]="10"
                                [globalFilterFields]="['referenceno','referencedate','deliveryno','requestedbranchname','deliverybranchname']"
                                [rowHover]="true" dataKey="referenceno"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="requestedbranchname">Requested Branch
                                            <p-sortIcon field="requestedbranchname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="deliverybranchname">Delivery Branch<p-sortIcon
                                                field="deliverybranchname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="referenceno">Ref.No.<p-sortIcon field="referenceno">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="referencedate">Ref Date<p-sortIcon field="referencedate">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="deliveryno">Delivery No<p-sortIcon field="deliveryno">
                                            </p-sortIcon>
                                        </th>
                                        <!-- <th pSortableColumn="referencetype">Ref.type<p-sortIcon field="referencetype">
                                            </p-sortIcon>
                                        </th> -->
                                        <th pSortableColumn="lockedquantity"> In-Transit Qty.<p-sortIcon
                                                field="lockedquantity">
                                            </p-sortIcon>
                                        </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-LockedQtydetail>
                                    <tr>
                                        <td>
                                            {{LockedQtydetail.requestedbranchname}}
                                        </td>
                                        <td>
                                            {{LockedQtydetail.deliverybranchname}}
                                        </td>

                                        <td>

                                            {{LockedQtydetail.referenceno}}
                                        </td>
                                        <td>
                                            {{LockedQtydetail.referencedate}}
                                        </td>

                                        <td>
                                            <a href="javascript:void(0);"
                                                (click)="onRowSelectDeliveryno(LockedQtydetail)">
                                                {{LockedQtydetail.deliveryno}}</a>
                                        </td>
                                        <!-- <td>
                                            {{LockedQtydetail.referencetype}}
                                        </td> -->
                                        <td class="text-right">
                                            {{LockedQtydetail.lockedquantity}}
                                        </td>

                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <p-dialog header="Locked Qty. Details" [(visible)]="griddetaillockqty" [modal]="true"
                            [style]="{width: '800px',height: '500px'}" [baseZIndex]="10000">
                            <ng-template pTemplate="content">
                                <div class="popup-body">
                                    <div class="card">
                                        <p-toolbar styleClass="p-mb-4">
                                            <ng-template pTemplate="left">
                                                <span class="p-input-icon-left">
                                                    <i class="pi pi-search"></i>
                                                    <input pInputText type="text"
                                                        (input)="dtProductWiseLockedqty.filterGlobal($event.target.value, 'contains')"
                                                        placeholder="Search..." />
                                                </span>

                                            </ng-template>
                                            <ng-template pTemplate="right">

                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    title="Export CSV" (click)="exportExcelProductWiseQuantity()"
                                                    class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS"
                                                    tooltipPosition="bottom"
                                                    (click)="exportExcelProductWiseQuantity()"></button>
                                                <!-- <button type="button" pButton pRipple icon="pi pi-file-pdf"
                                                    title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF"
                                                    tooltipPosition="bottom"
                                                    (click)="ExportToPdfProductdetaillockedqty()"></button> -->
                                            </ng-template>

                                        </p-toolbar>
                                        <p-table #dtProductWiseLockedqty [value]="_ProductWiseLockedqty"
                                            selectionMode="single" [rows]="25" [paginator]="true"
                                            [rowsPerPageOptions]="[15,25,50,100]" [columns]="ProductWiseLockedcols"
                                            [globalFilterFields]="['productname','lockedquantity']" [rowHover]="true"
                                            dataKey="productid"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                            [showCurrentPageReport]="true" [(selection)]="selectedProductRows">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th pSortableColumn="productname">Product Name<p-sortIcon
                                                            field="productname">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="lockedquantity">In-Transit / Locked Qty.
                                                        <p-sortIcon field="lockedquantity">
                                                        </p-sortIcon>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-_ProductWiseLockedQty
                                                let-i="rowIndex">
                                                <tr>
                                                    <td>
                                                        {{_ProductWiseLockedQty.productname}}
                                                    </td>
                                                    <td class="text-right">

                                                        {{_ProductWiseLockedQty.lockedquantity}}

                                                    </td>

                                                </tr>
                                            </ng-template>

                                        </p-table>

                                    </div>
                                </div>
                            </ng-template>
                        </p-dialog>

                    </div>

                </div>


            </div>
        </div>
    </div>
</div>