<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_Assetregistrationform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Asset Registration</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save" [disabled]="_IsSaveDisable"></button>
                                <button pButton pRipple type="button" (click)="reset()" title="Clear" icon="pi pi-trash" class="" class="p-button-danger" [disabled]="_IsSaveDisable"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search" title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="grnno" disabled formControlName="grnno">
                                        <label for="grnno">GRN No <span class="hlt-txt">*</span></label>
                                    </span>
                                    <button class="p-inputgroup-addon p-button" type="button" (click)="showBasicDialog()" [hidden]="_IsEvidenceNo">
                                        <i class="pi pi-external-link"></i>
                                    </button>
                                </div>
                                <span class="text-danger" *ngIf="(_submitted || _Assetregistrationform.get('grnno').touched) && _Assetregistrationform.get('grnno').errors?.SelectAccountgroup">
                                    Please Select Evidence No
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_Products" optionLabel="productname"
                                        [autoDisplayFirst]="false" optionValue="productid" name="productid"
                                        formControlName="productid" [disabled]="true">
                                    </p-dropdown>
                                    <label for="productid"> Product</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_UomCodes" optionLabel="metasubdescription"
                                        [autoDisplayFirst]="false" optionValue="metasubcode" name="uomcode"
                                        formControlName="uomcode" [disabled]="true">
                                    </p-dropdown>
                                    <label for="metasubcode"> UOM</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="quantity" disabled formControlName="quantity">
                                    <label for="quantity">Quantity</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_Suppliers" optionLabel="suppliername"
                                        [autoDisplayFirst]="false" optionValue="supplierid" name="supplierid"
                                        formControlName="supplierid" [disabled]="true">
                                    </p-dropdown>
                                    <label for="supplierid"> Supplier</label>
                                </span>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText name="purchasedate" disabled 
                                        formControlName="purchasedate">
                                    <label for="purchasedate">Purchase Date</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="unitprice" disabled
                                        formControlName="unitprice">
                                    <label for="unitprice">Unit Pirce</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="totalpurchaseamount" disabled
                                        formControlName="purchaseamount">
                                    <label for="totalpurchaseamount">Total Purchase Amount</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_Currencies" optionLabel="currencyname"
                                        [autoDisplayFirst]="false" (onChange)="ChangeCurrencies($event)"
                                        optionValue="currencyid" name="currentcurrencyid"
                                        formControlName="currentcurrencyid">
                                    </p-dropdown>
                                    <label for="currentcurrencyid"> Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Assetregistrationform.get('currentcurrencyid').touched) && _Assetregistrationform.get('currentcurrencyid').errors?.SelectAccountgroup">
                                    Currency Required
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="exchangerateinusd"
                                        formControlName="exchangerateinusd" disabled>
                                    <label for="exchangerateinusd"> Exchange Rate </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_Branches" optionLabel="branchname" optionValue="branchid"
                                        name="regBranchid" formControlName="regBranchid" [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="regBranchid">Branch <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Assetregistrationform.get('regBranchid').touched) && _Assetregistrationform.get('regBranchid').errors?.SelectAccountgroup">
                                    Branch Required
                                </span>
                            </td>
                            <td>
                                <div class="p-formgroup-inline">
                                    <div class="p-field-radiobutton" *ngFor="let AD of _isappOrDep">
                                        <p-radioButton name="isappOrDep" value="isappOrDep" formControlName="isappOrDep" label={{AD.metasubdescription}} value={{AD.metasubcode}} (onClick)="getAppreciationOrDepreciationAccount($event)"></p-radioButton>

                                    </div>
                                </div>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_appordepAccounts" optionLabel="name" optionValue="ledgerid"
                                        name="appordepAccount" formControlName="appordepAccount"
                                        [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="appordepAccount"> App/Dep Account <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Assetregistrationform.get('appordepAccount').touched) && _Assetregistrationform.get('appordepAccount').errors?.SelectAccountgroup">
                                    App/Dep Account Required
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="appordepPerYear"
                                        formControlName="appordepPerYear"
                                        (keyup)="OnChangeAppOrDepper($event.target.value,'appordepAmountyear')">
                                    <label for="appordepPerYear">App/Dep % Year</label>
                                </span>
                            </td>
                            <td></td>
                            <!-- <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="appordepAmountyear"
                                        formControlName="appordepAmountyear"
                                        (keyup)="OnChangeAppOrDep($event.target.value,'appordepPerYear')">
                                    <label for="appordepAmountyear">App/Dep Amount Year</label>
                                </span>
                            </td> -->

                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <textarea rows="7" cols="35" formControlName="serialno" pInputTextarea></textarea>
                                    <label for="serialno">Serial No</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="currentAssetValue" disabled
                                        formControlName="currentAssetValue">
                                    <label for="currentAssetValue">Current Asset Value</label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </tr>
                    </table>
                    <p-dialog header="GRN No" [(visible)]="displayBasic" [modal]="true" [style]="{width: '70vw',height: '500px'}" [baseZIndex]="10000">
                        <ng-template pTemplate="content">
                            <div class="popup-body">
                                <div class="search-container">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                                        </span>
                                        <button class="p-inputgroup-addon p-button" title="Search">
                                            <i class="pi pi-search"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card">
                                    <p-table #dt [value]="_GRNlist" selectionMode="single" [(selection)]="selectedGRN" [globalFilterFields]="['grnno','productname','uomname','quantity','suppliername','purchasedate','unitprice','purchaseamount']" (onRowSelect)="onRowSelectPopup($event)"
                                        [paginator]="true" [rows]="5">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th pSortableColumn="grnno">GRN No</th>
                                                <th pSortableColumn="productname">Product Name </th>
                                                <th pSortableColumn="uomname">UOM </th>
                                                <th pSortableColumn="quantity">Quantity </th>
                                                <th pSortableColumn="suppliername">Supplier </th>
                                                <th pSortableColumn="purchasedate">Pruchase Date </th>
                                                <th pSortableColumn="unitprice">Unit Price </th>
                                                <th pSortableColumn="amount">Total Purchase Amount</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData let-_GRN>
                                            <tr [pSelectableRow]="rowData">
                                                <td>{{_GRN.grnno}}</td>
                                                <td>{{_GRN.productname}}</td>
                                                <td>{{_GRN.uomname}}</td>
                                                <td>{{_GRN.quantity}}</td>
                                                <td>{{_GRN.suppliername}}</td>
                                                <td>{{_GRN.purchasedate | date:'dd-MMM-yyyy'}}</td>
                                                <td>{{_GRN.unitprice}}</td>
                                                <td>{{_GRN.purchaseamount}}</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </ng-template>
                    </p-dialog>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>