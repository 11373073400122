import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptaccountsmonitorService {
  public _Action: IAction;
  public _httpOptions;
  public _RptAccountsMonitorService = environment.RptAccountsMonitorService;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnload(userid: number): Observable<any> {
    const headers = this.header;
    var Query = this._RptAccountsMonitorService + '/PageOnLoad' + '/' + userid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  Search(accountmonitor: object) {
    const headers = this.header;
    var Query = this._RptAccountsMonitorService + '/Search';
    return this.http.post<{ data: any }>(Query, accountmonitor, { headers });
  }
}
