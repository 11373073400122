<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product Request</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>

                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_MatriealRequst" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['materialrequestno','materialrequestdate','statusname','createdbyname','createdon']" [rowHover]="true" dataKey="materialrequestno"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">

                        <ng-template pTemplate="header">
                            <tr>

                                <th class="text-center" style="width:150px;">Action</th>
                                <th pSortableColumn="materialrequestno">Request Number
                                    <p-sortIcon field="materialrequestno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="materialrequestdate">Request Date
                                    <p-sortIcon field="materialrequestdate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="materialrequestdate">Request Amount
                                    <p-sortIcon field="materialrequestdate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="statusname"> Approval Status
                                    <p-sortIcon field="statusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_MatriealRequst>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" (click)="view(_MatriealRequst)" [disabled]="!_Action._View"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update" [disabled]="_MatriealRequst.status == 'MSC00047'|| _MatriealRequst.status == 'MSC00046' || _MatriealRequst.status == 'MSC00048' || _MatriealRequst.status == 'MSC00150'"
                                        (click)="edit(_MatriealRequst)"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Cancel" class="p-button p-button-danger" [disabled]="!_Action._Delete" [disabled]="_MatriealRequst.status == 'MSC00150' || _MatriealRequst.status == 'MSC00046' || _MatriealRequst.isfullydelivered == 'YES'"
                                        (click)="confirm(_MatriealRequst)"></button>
                                    <button pButton pRipple title="Send To Approval" icon="las la-check" [disabled]="!_Action._Approve" [disabled]="_MatriealRequst.status == 'MSC00047'|| _MatriealRequst.status == 'MSC00046' || _MatriealRequst.status == 'MSC00048' || _MatriealRequst.status == 'MSC00150'"
                                        (click)="SendToApproval(_MatriealRequst)"></button>
                                    <button pButton pRipple icon="las la-print" title="Print" [disabled]="!_Action._Approve" [disabled]="_MatriealRequst.status == 'MSC00045'|| _MatriealRequst.status == 'MSC00046'" class="p-button-warning p-mr-2" (click)="Print(_MatriealRequst)"></button>
                                </td>
                                <td> {{_MatriealRequst.materialrequestno}} </td>
                                <td> {{_MatriealRequst.materialrequestdate |date:'dd/MM/yyyy'}} </td>
                                <td> {{_MatriealRequst.totalamount}} </td>
                                <td> {{_MatriealRequst.statusname}} </td>
                                <td> {{_MatriealRequst.createdbyname}} </td>
                                <td> {{_MatriealRequst.createdon|date:'dd/MM/yyyy'}} </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

            </div>

        </div>
    </div>
</div>