import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class StockmissingService {
  public _Action: IAction;
  public _StockmissingService = environment.StockmissingService;
  token: any;
  header: any;
  public _httpOptions;
  constructor(private http: HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(): Observable<any> {
    const headers = this.header;
    var Query = this._StockmissingService + '/pageload';

    return this.http.get<{ data: any }>(Query, { headers });
  }
  getProductCategories(producttype: object) {
    const headers = this.header;
    var Query = this._StockmissingService + '/getProductCategories';

    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }

  getProducts(productcategory: object) {
    const headers = this.header;
    var Query = this._StockmissingService + '/getProducts';

    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }
  Search(search: object) {
    const headers = this.header;
    var Query = this._StockmissingService + '/Search';

    return this.http.post<{ data: any }>(Query, search, { headers });
  }
  gettobranch(frombranchid: number): Observable<any> {
    const headers = this.header;
    var Query = this._StockmissingService + '/gettobranch/' + frombranchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  getstocktransferno(tobranchid: number, inwardtype: string): Observable<any> {
    const headers = this.header;
    var Query = this._StockmissingService + '/getstocktransferno/' + tobranchid + '/' + inwardtype;;;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  //To Get Supplier Branch Name :
  getSupplierNameorBranchName(inwardtype: string, supplierid: number, userid: number) {
    const headers = this.header;
    var Query = this._StockmissingService + '/getSupplierNameorBranchName/' + inwardtype + '/' + supplierid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  getBrand(products: object) {
    const headers = this.header;
    var Query = this._StockmissingService + '/getBrand';
    return this.http.post<{ data: any }>(Query, products, { headers });
  }

  //To Update the stock
  updatestock(grnno: string, productid: number, qty: number) {
    const headers = this.header;
    var Query = this._StockmissingService + '/updatestock/' + grnno + '/' + productid + '/' + qty;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  updatepostatus(pono: string, productid: number) {
    const headers = this.header;
    var Query = this._StockmissingService + '/PoMissingStatusUpdate/' + pono + '/' + productid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
}
