import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map,catchError} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class AccountclosingService {
  public _Action : IAction ;
  public _AccountClosingService = environment.AccountClosingService;
  public _httpOptions;
  constructor(private http:HttpClient) { 

  }
  ViewPageLoad(id: number) {
    var Query = this._AccountClosingService + '/ViewPageLoad' +'/' + id;
   
    return this.http.post<{ data: any }>(Query,id);
  }
  FetchAll(userid:number)
  { 
    var Query = this._AccountClosingService + '/FetchAll' +'/' + userid  ;
   
    return this.http.get<{data:any}>(Query,this._httpOptions).pipe(map(data=>{
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      
     return data; 
    }),);
  }
  Search(userid:number,datetime:Date){
    var Query = this._AccountClosingService + '/Search' +'/' + userid +'/' +datetime.toISOString();
   
    return this.http.post<{ data: any }>(Query,'');
  }
  create(p_AccountClosing:object,userid:number)
  {
    var Query = this._AccountClosingService +'/Create' +'/' +userid ;
    return this.http.post<{data:any}>(Query,p_AccountClosing);
  }
  Cancel(id: number,userid:number)
  { 
    var Query = this._AccountClosingService  +'/Cancel' + '/'  + id +'/' +userid ; 
    return this.http.put<{data:any}>(Query,"");
  }

}