import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { TripstartService } from 'src/app/core/Services/inventory/tripstart.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Tripstartclosureview } from 'src/app/core/Views/tripstartclosureviews';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable'
@Component({
  selector: 'app-v-tripstart',
  templateUrl: './v-tripstart.component.html',
  styleUrls: ['./v-tripstart.component.css']
})
export class VTripstartComponent implements OnInit {
  _TripStartviews: Tripstartclosureview[];
  _TripStartview: Tripstartclosureview;
  cols: any[];
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _IsProgressSpinner: boolean = true;
  stockdeliverydetail: boolean = false;
  _stockdeliverydetail: any[] = [];
  _Tripstartclosurepopups: any[] = [];

  datepipe = new DatePipe('en-US');
  constructor(private fb: FormBuilder, private router: Router, private utility: CommonUtilities, private _TripstartService: TripstartService,
    private _AccessRightsService: AccessRightsService, private _hotkeysService: HotkeysService, private exportUtility: ExportUtility, private _CustomExceptionService: CustomExceptionService) { }

  ngOnInit(): void {
    this._IsProgressSpinner = true;
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'vehicleno', header: 'Vehicle No' },
      { field: 'drivername', header: 'Driver Name' },
      { field: 'assistancename', header: 'Assistant Name' },
      { field: 'referenceno', header: 'Reference No' },
      { field: 'odometerreading', header: 'Odo Meter Reading' },
      { field: 'averagekm', header: 'Average KM' },
      { field: 'tripstartdate', header: 'Trip Start Date' },
      { field: 'tripenddate', header: 'Trip End Date' },
      { field: 'tripstartclosurename', header: 'Trip Status' },
      { field: 'vehiclestatusname', header: 'Vehicle Status' },
    ]
    this._Action = this._AccessRightsService.getAccessRights(52);
    this.HotKeyIntegration();
    this._TripstartService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._TripStartviews = resultJSON.tripstartclosureviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  view(event) {
    this.router.navigate(['/cTripstart'], { state: { tripstartclosureid: event.tripstartclosureid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cTripstart']);
  }
  // delete(event) {
  //   alert("delete");
  // }
  exportExcel() {
    let TripList = this.GetTripStartList();
    this.exportUtility.exportExcel(TripList, 'TripStart', 'xlsx');
  }

  ExportToPdf() {
    if (!this._TripStartviews || !this._TripStartviews.length)
      return;
    let TripStartList = this.GetTripStartList();
    const data = TripStartList.map(Object.values);
    let head = [["Vehicle No", "Driver Name", "Assistant Name", "Reference No", "Odo Meter Reading", "Average KM", "Trip Start Date", "Trip End Date", "Trip Status", "Vehicle Status"]];


    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          margin: { top: 30 },
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0

          },
          columnStyles: { 2: { halign: 'right' }, 1: { halign: 'right' } },


          didDrawCell: (data) => { },
        });

        doc.save('TripStart.pdf');
      })
    })
  }

  GetTripStartList() {
    if (!this._TripStartviews || !this._TripStartviews.length)
      return;
    let TripStartLists = [];
    this._TripStartviews.forEach(element => {
      let TripStartList: any = {};
      TripStartList["Vehicle No"] = element.vehicleno;
      TripStartList["Driver Name"] = element.drivername;
      TripStartList["Assistant Name"] = element.assistancename;
      TripStartList["Reference No"] = element.referenceno;
      TripStartList["Odo Meter Reading"] = element.odometerreading;
      TripStartList["Average KM"] = element.averagekm;
      TripStartList["Trip Start Date"] = this.datepipe.transform(element.tripstartdate, 'dd/MM/yyyy hh:mm a');
      TripStartList["Trip End Date"] = this.datepipe.transform(element.tripenddate, 'dd/MM/yyyy hh:mm a');
      TripStartList["Trip Status"] = element.tripstartclosurename;
      TripStartList["Vehicle Status"] = element.vehiclestatusname;
      TripStartLists.push(TripStartList);
    });
    return TripStartLists;
  }

  edit(event) {
    this.router.navigate(['/cTripstart'], { state: { tripstartclosureid: event.tripstartclosureid, action: 'edit' } });
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  Getdeliverydetails(_TripStartview) {
    this.stockdeliverydetail = true;
    this._IsProgressSpinner = true;

    var tripno = _TripStartview.tripstartclosureno
    let data = {
      "tripno": tripno
    }

    this._TripstartService.getstockdeliverydetail(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._stockdeliverydetail = resultJSON.tripstartclosurepopups;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
}
