<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_bankform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Bank</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple title="Save" type="submit" icon="pi pi-save" [disabled]="_SaveHide"></button>
                                <!-- 12.All entered input fields should be cleared. -->
                                <button pButton pRipple label="" title="Clear" type="button" icon="pi pi-trash" [disabled]="_ClearHide"
                                    class="p-button-danger" (click)="reset($event)"></button>
                                <!--23. on clicking back button , it Navigates to the search screen -->
                                <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                    class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="bankcode" formControlName="bankcode" pInputText>
                                    <label for="bankcode">Bank Code <span class="hlt-txt">*</span></label>
                                </span>
                                <!--5. if "bank code" is not given, show error as "Please Enter bank code" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _bankform.get('bankcode').touched) && _bankform.get('bankcode').errors?.BankCodeRequired">
                                    Please enter bank Code
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="bankname" formControlName="bankname" pInputText>
                                    <label for="bankcode">Bank Name <span class="hlt-txt">*</span></label>
                                </span>
                                <!--6. if "bank  name" is not given, show error as "Please Enter bank name" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _bankform.get('bankname').touched) && _bankform.get('bankname').errors?.BankNameRequired">
                                    Please enter bank name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_bankstatus"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="bankstatus">
                                    </p-dropdown>
                                    <label for="bankstatus"> Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_bankform.get('bankstatus').touched || _submitted) && _bankform.get('bankstatus').errors?.SelectStatus">
                                Please select bank status
                            </span>
                            </td>
                            <td style="width: 20%;" >
                                <span class="p-float-label" [hidden]="true">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Series"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="series">
                                    </p-dropdown>
                                    <label for="series">Series <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- <span class="text-danger"
                                *ngIf="(_submitted || _bankform.get('series').touched) && _bankform.get('series').errors?.SelectSeries">
                                Please select series
                            </span> -->
                            </td>
                            <td style="width: 20%;">
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </tr>
                    </table>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>