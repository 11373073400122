import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/core/Services/masters/customer.service';
import { Customerview } from 'src/app/core/Views/Customerview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
import { State } from 'src/app/core/Models/State';
import { District } from 'src/app/core/Models/District';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-v-customer',
  templateUrl: './v-customer.component.html',
  styleUrls: ['./v-customer.component.css']
})
export class VCustomerComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _customersview: Customerview[];
  _customerview: Customerview;
  _Action: IAction;
  _IsProgressSpinner: boolean = true;
  _customerid: number;
  cols: any[];
  customertype: any[];
  _selectedtype: number;
  _stateid: number;
  _districtid: number;
  _responsibleperson: number;
  _customerstatus: string = "0";
  _statuslist: any[];
  _States: any[] = [];
  _Cities: any[] = [];
  _userlist: any[] = [];
  datepipe = new DatePipe('en-US');

  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _CustomerService: CustomerService, private _router: Router,
    private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService,
    private confirmationService: ConfirmationService, private exportUtility: ExportUtility) {
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(21);
    this._IsProgressSpinner = true;
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'customercode', header: 'Customer Code' },
      { field: 'customername', header: 'Customer Name' },
      { field: 'currencyname', header: 'Currency' },
      { field: 'mobileno', header: 'Mobile No.' },
      { field: 'mailid', header: 'Email' },
      // { field: 'rewardpoints', header: 'Available Rewardpoints' },
      { field: 'customerstatusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
      { field: 'modifiedbyname', header: 'Modified By' },
      { field: 'modifiedon', header: 'Modified On' },
    ]
    this.customertype = [
      { id: 1, name: 'All Customer' },
      { id: 2, name: 'Quick Customer' },
      { id: 3, name: 'Normal Customer' },
      { id: 4, name: 'Online Customer' },
      { id: 5, name: 'Credit Customer' },
    ]
    this._selectedtype = 1;

    this._statuslist = [
      { statusid: "MSC00001", statusname: 'Active' },
      { statusid: "MSC00002", statusname: 'Inactive' },
      { statusid: "MSC00344", statusname: 'Hold'}
    ]
    this._customerstatus = 'MSC00001'
    this.HotKeyIntegration();
    this.Fetchall();
    this.pageinitialization();
  }
  pageinitialization() {
    this._IsProgressSpinner = true;
    this._customerid = history.state?.customerid ? history.state?.customerid : 0;
    this._CustomerService.PageOnLoad(this._customerid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._States = resultJSON.states;
      //this._Cities = resultJSON.cities;
      this._userlist = resultJSON.users;
    })
  }

  Fetchall() {
    if (!this._stateid) {
      this._stateid = 0;
    }
    if(!this._responsibleperson) {
      this._responsibleperson = 0;
    }
    if(!this._districtid) {
      this._districtid = 0;
    }
    this._IsProgressSpinner = true;
    this._CustomerService.FetchAll(sessionStorage["userid"], false, this._selectedtype, this._customerstatus, this._stateid, this._districtid, this._responsibleperson).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._customersview = resultJSON.customerviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  getDistrict(event) {
    var selectedState = event.value;
    if (selectedState == null){
      return;
    }
    this._IsProgressSpinner = true;
    this._Cities = [];
    
    this._CustomerService.getDistrict(selectedState).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Cities = resultJSON.cities;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event)
  {
    this._stateid = 0;
    this._districtid = 0;
    this._responsibleperson = 0;
    this._selectedtype = 1;
    this._customerstatus = 'MSC00001';
    this.Fetchall();
  }

  onRowSelect(event) {
  }

  onRowUnselect(event) {
  }
  view(event) {
    this._router.navigate(['/cCustomer'], { state: { customerid: event.customerid, action: 'view' } });
  }

  edit(event) {
    this._router.navigate(['/cCustomer'], { state: { customerid: event.customerid, action: 'edit' } });
  }

  GoToCreate(event) {
    this._router.navigate(['/cCustomer']);
  }

  //Export Excel Download:
  exportExcel() {
    let customerList = this.GetCustomerList();
    this.exportUtility.exportExcel(customerList, 'Customer', 'xlsx');
  }

  ExportToPdf() {
    let customerList = this.GetCustomerList();
    const data = customerList.map(Object.values);
    //this.exportUtility.ExportToPdf(customerList, 'Customer.pdf');
    const head = [['Customer Code', 'Customer Name', 'Currency', 'Mobile No.', 'Responsible person','Status',
     'Created By','Created On','Modified By' ,'Modified On'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        // doc.autoTable(this.exportColumns, this._ExpenseReportviews);
        doc.save('Customer.pdf');
      })
    })
  }

  GetCustomerList() {
    let customerList = [];
    this._customersview.forEach(element => {
      let customer: any = {};
      customer["Customer Code"] = element.customercode;
      customer["Customer Name"] = element.customername;
      customer["Currency"] = element.currencyname;
      customer["Mobile No."] = element.mobileno;
      //customer["Email"] = element.mailid;
      customer["Responsible person"] = element.responsiblepersonname;
      customer["Status"] = element.customerstatusname;
      customer["Created By"] = element.createdbyname;
      customer["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      customer["Modified By"] = element.modifiedbyname;
      customer["Modified On"] = this.datepipe.transform(element.modifiedon, 'dd/MM/yyyy');
      customerList.push(customer);
    });
    return customerList;
  }


  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  confirm(event) {
    this._customerid = event.customerid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to deactivate this customer <b>"' + event.customername + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    this._IsProgressSpinner = true;
    this._CustomerService.Cancel(this._customerid, sessionStorage["userid"]).subscribe((result) => {
      const resutJSON = JSON.parse(JSON.stringify(result));
      if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_16);
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._customersview = resultJSON.customerviews;
        this._IsProgressSpinner = false;
      }
      else {
        this._IsProgressSpinner = false;
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
