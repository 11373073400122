import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Supplier } from 'src/app/core/Models/Supplier';
import { RptpurchaseorderdetailreportService } from 'src/app/core/Services/reports/Purchase/RptPurchaseOrderDetailReport/rptpurchaseorderdetailreport.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-purchaseorderdetailreport',
  templateUrl: './purchaseorderdetailreport.component.html',
  styleUrls: ['./purchaseorderdetailreport.component.css']
})
export class PurchaseorderdetailreportComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptPurchaseorderdetailform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _userid: number;
  datepipe: any;
  _supplierlist: Supplier[];
  _src: SafeResourceUrl;
  filteredProducts: any[];
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _SalesReportPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  branchid: number[] = [];
  supplierid: number[] = [];
  productid: number[] = [];
  _currencylist: Currency[] = [];
  currencyid: number[] = [];
  _Action: IAction;
  mindate: Date;
  maxdate: Date;

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptpurchaseorderdetailreportService: RptpurchaseorderdetailreportService,
    private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(142);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptPurchaseorderdetailform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      productid: [0],
      productcategoryid: [0],
      producttypeid: [0],
      supplierid: [0],
      currencyid: [0],
    })
    this._branchlist = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptPurchaseorderdetailform.get("fromdate").setValue(new Date);
      this._rptPurchaseorderdetailform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptPurchaseorderdetailform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptPurchaseorderdetailform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._IsProgressSpinner = true;
    this._RptpurchaseorderdetailreportService.PageOnload(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branches;
      this._producttypelist = resultJSON.producttypeList;
      this._supplierlist = resultJSON.suppliers;
      this._currencylist = resultJSON.currencies;
      this._rptPurchaseorderdetailform.controls['branchid'].setValue(this._branchlist[0].branchid);
      this._rptPurchaseorderdetailform.controls['supplierid'].setValue(this._supplierlist[0].supplierid);
      this._rptPurchaseorderdetailform.controls['currencyid'].setValue(this._currencylist[0].currencyid);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._rptPurchaseorderdetailform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptPurchaseorderdetailform.get("fromdate").setValue(new Date);
      this._rptPurchaseorderdetailform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptPurchaseorderdetailform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptPurchaseorderdetailform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._rptPurchaseorderdetailform.controls['currencyid'].setValue(1);
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this.supplierid = [];
    this.branchid = [];
    this._productcategorylist = [];
    this._productlist = [];
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  GenerateReport(event) {
    var _fromdate = this._rptPurchaseorderdetailform.get("fromdate").value;
    var _todate = this._rptPurchaseorderdetailform.get("todate").value;
    if (!_fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!_todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    if (_fromdate > _todate) {
      this._CustomExceptionService.handleError("From Date should be less than To Date");
      return;
    }
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var Currenctcurrenyid = this._rptPurchaseorderdetailform.get("currencyid").value;
    if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._SalesReportPrintObj = {
      "Productheader": this.productid || [],
      "Branches": this.branchid || [],
      "Suppliers": this.supplierid || [],
      "Branchid": parseInt(sessionStorage["currentbranchid"]),
      "Userid": parseInt(sessionStorage["userid"]),
      "FromDate": fromdate,
      "ToDate": todate,
      "Currencyid": Currenctcurrenyid,
      "ProductType": this.producttypeid || [],
      "ProductCategory": this.productcatid || []
    }
    this._RptpurchaseorderdetailreportService.Print(this._SalesReportPrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptpurchaseorderdetailreportService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("category", resultJSON);
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptpurchaseorderdetailreportService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
}