<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Job Card</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                [disabled]="_IsSaveDisable" (click)="onSave()"></button>
                            <button pButton pRipple type="button" (click)="reset()" title="Clear" icon="pi pi-trash"
                                class="" class="p-button-danger" [disabled]="_IsclearDisable"></button>
                            <button pButton pRiple type="button" (click)="goBack()" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_jobcardform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="jobcardno">
                                    <label>Job Card Code</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="jobcarddate" [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="jobcarddate"> Job Card Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _jobcardform.get('jobcarddate').touched) && _jobcardform.get('jobcarddate').errors?.SelectPaymentDate">
                                    Please Select Job Card Date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_servicerequestno" optionLabel="servicerequestnumber"
                                        [filter]="true" filterBy="servicerequestnumber"
                                        optionValue="servicerequestnumber" formControlName="servicerequestno"
                                        (onChange)="GetServicerequestdetail($event)" [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="servicerequestno">Service Request No <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _jobcardform.get('servicerequestno').touched) && _jobcardform.get('servicerequestno').errors?.SelectAccountgroup">
                                    Please Select Service Request No
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_serviceat" optionLabel="metasubdescription"
                                        optionValue="metasubcode" formControlName="jobcardtype"
                                        (onChange)="Getserviceat($event)" (onBlur)="Getserviceat($event)"
                                        (keydown.tab)="Getserviceat($event)" (keyup.tab)="Getserviceat($event)"
                                        (onfocus)="Getserviceat($event)" [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="jobcardtype">Service At<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _jobcardform.get('jobcardtype').touched) && _jobcardform.get('jobcardtype').errors?.SelectAccountgroup">
                                    Please Select Service At
                                </span>
                            </td>
                            <td style="width: 20%;" rowspan="2">
                                <span class="p-float-label">
                                    <textarea rows="3" name="checklistdescription"
                                        formControlName="checklistdescription" pInputTextarea></textarea>
                                    <label for="remarks">Check List Description </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_employee" optionLabel="employeename" [filter]="true"
                                        filterBy="employeename" optionValue="employeeid" formControlName="employeeid"
                                        [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="employeeid">Employee <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_supplier" optionLabel="suppliername" [filter]="true"
                                        filterBy="suppliername" optionValue="supplierid" formControlName="supplierid"
                                        (onChange)="getdetails($event)" [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="supplierid">Supplier <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td>
                                <div class="p-formgroup-inline">
                                    <div class="p-field-radiobutton" *ngFor="let PB of _payby">
                                        <p-radioButton name="payby" value="payby" formControlName="payby"
                                            label={{PB.metasubdescription}} value={{PB.metasubid}}
                                            (onClick)="onPayBySelect($event)"></p-radioButton>
                                    </div>
                                </div>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _jobcardform.get('payby').touched) && _jobcardform.get('payby').errors?.SelectAccountgroup">
                                    Please Select Pay By
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect optionLabel="metasubdescription" [options]="_checklist"
                                        optionValue="metasubcode" [filter]="true" [virtualScroll]="true" itemSize="30"
                                        [(ngModel)]="_selectedchecklist" (onChange)="getchecklist($event)"
                                        [disabled]="this._action == 'view'||this._action == 'edit'"
                                        [ngModelOptions]="{standalone: true}" displaySelectedLabel="true">
                                    </p-multiSelect>
                                    <label for="regBranchid">Check List <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_currency" optionLabel="currencyname"
                                        optionValue="currencyid" formControlName="currencyid" (onChange)="getversion()"
                                        [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="currencyid">Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _jobcardform.get('currencyid').touched) && _jobcardform.get('currencyid').errors?.SelectAccountgroup">
                                    Please Select Currency
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-inputNumber formControlName="approximateamountinusd" autocomplete="off"
                                        maxlength="13" (onBlur)="allocatePaymentAmount()" mode="decimal"
                                        [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                                    <label for="approximateamountinusd"> Service Cost <span class="hlt-txt">*</span>
                                    </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="vattype" formControlName="vatid"
                                        [disabled]='isexternal' optionLabel="taxname" optionValue="taxid"
                                        (onChange)="getvat()">
                                    </p-dropdown>
                                    <label for="name"> VAT Type <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td rowspan="2">
                                <span class="p-float-label">
                                    <textarea rows="3" name="remarks" disabled [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="Address" pInputTextarea></textarea>
                                    <label for="remarks">Address </label>
                                </span>
                            </td>
                            <td rowspan="2">
                                <span class="p-float-label">
                                    <textarea rows="3" name="remarks" formControlName="remarks"
                                        pInputTextarea></textarea>
                                    <label for="remarks">Remarks </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText disabled [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="mobileno" />
                                    <label for="mobileno">Mobile No </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText disabled [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="mailid" />
                                    <label for="mailid">Mail Id </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_status" optionLabel="metasubdescription"
                                        optionValue="metasubcode" formControlName="status" [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="status">Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _jobcardform.get('status').touched) && _jobcardform.get('status').errors?.SelectAccountgroup">
                                    Please Select Status
                                </span>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_jobcarddetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['productname','pruchaseorinvoicedate','srquantity','uomdesc','unitprice','finalamount']"
                        [rowHover]="true" dataKey="productid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" pSortableColumn="productname">Product Name <p-sortIcon
                                        field="productname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="pruchaseorinvoicedate">Purchase
                                    /Invoice Date <p-sortIcon field="pruchaseorinvoicedate">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="srquantity">Quantity <p-sortIcon
                                        field="srquantity">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="uomdesc">UOM <p-sortIcon
                                        field="uomdesc">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="unitprice">Unit Price<p-sortIcon
                                        field="unitprice"></p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="finalamount">Final Amount <p-sortIcon
                                        field="finalamount">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_jobcarddetail>
                            <tr>
                                <td>
                                    {{_jobcarddetail.productname }}
                                </td>
                                <td>
                                    {{_jobcarddetail.pruchaseorinvoicedate | date:'dd-MMM-yyyy' }}
                                </td>
                                <td>
                                    {{_jobcarddetail.srquantity}}
                                </td>
                                <td>
                                    {{_jobcarddetail.uomdesc }}
                                </td>
                                <td>
                                    {{_jobcarddetail.unitprice }}
                                </td>
                                <td>
                                    {{_jobcarddetail.finalamount}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="row" [hidden]="HideSpc">
                    <div class="col-md-5">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Spares & Consumables</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <div class="card">
                            <p-table [value]="_SparesAndConsumables" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['ocid','ocvalue']"
                                [rowHover]="true" dataKey="ocid"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="SparesAndConsumablesRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;">Product Name</th>
                                        <th style="text-align: center;">UOM</th>
                                        <th style="text-align: center;">Issued Qty</th>
                                        <th style="text-align: center;">Cost</th>
                                        <th style="text-align: center;">Issued By</th>
                                        <th style="text-align: center;">Received By</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_spc>
                                    <tr>
                                        <td>
                                            {{_spc.productname}}
                                        </td>
                                        <td>
                                            {{_spc.uomname}}
                                        </td>
                                        <td style="text-align: right;">
                                            {{_spc.issuedqty}}
                                        </td>
                                        <td style="text-align: right;">
                                            {{_spc.cost}}
                                        </td>
                                        <td>
                                            {{_spc.recievedby}}
                                        </td>
                                        <td>
                                            {{_spc.issuedby}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Additional Charges</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <div class="card">
                            <p-table [value]="_AdditionalCharges" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['ocid','ocvalue']"
                                [rowHover]="true" dataKey="ocid"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedOtherChargesRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:100px;text-align: center;">Action</th>
                                        <th style="text-align: center;">Description</th>
                                        <th style="text-align: center;">Percentage</th>
                                        <th style="text-align: center;">Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_othercharge>
                                    <tr>
                                        <td style="text-align:center">
                                            <button pButton pRipple title="Add" icon="pi pi-plus"
                                                class="p-button-success p-mr-2" [disabled]="isDisabledother"
                                                (click)="AddAdditionalCharges($event)"></button>
                                            <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                class="p-button-danger" [disabled]="isDisabledremove"
                                                (click)="RemoveAdditionalCharges(_othercharge)"></button>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown #id [autoDisplayFirst]="false"
                                                        [options]="_AddDescriptions" *ngIf="_action != 'view'"
                                                        (onChange)="OnchangeAdditionalCharge(_othercharge,$event)"
                                                        optionLabel="metasubdescription" optionValue="metasubid"
                                                        [(ngModel)]="_othercharge.adid">
                                                    </p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                        [options]="_AddDescriptions" optionLabel="metasubdescription"
                                                        optionValue="metasubid" [(ngModel)]="_othercharge.adid">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-inputNumber autocomplete="off" maxlength="13" mode="decimal"
                                                        [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0"
                                                        [max]="100"
                                                        (onBlur)="OnchangeAdditionalChargePer(_othercharge,$event)"
                                                        [(ngModel)]="_othercharge.adper" *ngIf="_action == 'create'">
                                                    </p-inputNumber>
                                                    <div *ngIf="_action != 'create'">{{_othercharge.adper}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_othercharge.adper}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td>
                                            {{_othercharge.advalue}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Other Charges</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <div class="card">
                            <p-table [value]="_OtherCharges" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['ocid','ocvalue']"
                                [rowHover]="true" dataKey="ocid"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedOtherChargesRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:100px;text-align: center;">Action</th>
                                        <th style="text-align: center;">Description</th>
                                        <th style="text-align: center;">Value</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_othercharge>
                                    <tr>
                                        <td style="text-align:center">
                                            <button pButton pRipple title="Add" icon="pi pi-plus"
                                                class="p-button-success p-mr-2" [disabled]="isDisabledother"
                                                (click)="AddOtherCharges($event)"></button>
                                            <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                class="p-button-danger" [disabled]="isDisabledremove"
                                                (click)="RemoveOtherCharges(_othercharge)"></button>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown #id [autoDisplayFirst]="false" [options]="_Descriptions"
                                                        *ngIf="_action != 'view'" optionLabel="otherchargename"
                                                        optionValue="otherchargeid" [(ngModel)]="_othercharge.ocid"
                                                        (onChange)="OnchangeOtherCharge(_othercharge,$event)">
                                                    </p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                        [options]="_Descriptions" optionLabel="otherchargename"
                                                        optionValue="otherchargeid" [(ngModel)]="_othercharge.ocid">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" pInputText name="ocvalue"
                                                        (keyup)="OnChangeotherCharge(_othercharge,$event)"
                                                        [(ngModel)]="_othercharge.ocvalue" *ngIf="_action == 'create'">
                                                    <div *ngIf="_action != 'create'">{{_othercharge.ocvalue}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_othercharge.ocvalue}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <form [formGroup]="_jobcardform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" name="vatAmount" disabled formControlName="vatamount"
                                                pInputText>
                                            <label for="vatAmount"> VAT Amount </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-inputNumber formControlName="advalue" autocomplete="off" maxlength="13"
                                                mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
                                            </p-inputNumber>
                                            <label for="ocValue"> Additional Charges Amount </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-inputNumber formControlName="ocvalue" autocomplete="off" maxlength="13"
                                                mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
                                            </p-inputNumber>
                                            <label for="ocValue"> Other Charges Amount </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" name="netamount" disabled formControlName="netamount"
                                                pInputText>
                                            <label for="netamount"> Net Amount </label>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>