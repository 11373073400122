import { Component, OnInit } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { DamagedproductpricedetailsService } from 'src/app/core/Services/Sales/damagedproductpricedetails.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Damagedproductpriceview } from 'src/app/core/Views/Damagedproductpriceview';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-v-damagedproductpricedetails',
  templateUrl: './v-damagedproductpricedetails.component.html',
  styleUrls: ['./v-damagedproductpricedetails.component.css']
})
export class VDamagedproductpricedetailsComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  cols: any = [];
  _Damagedproductpriceviews: Damagedproductpriceview[];
  _Damagedproductpriceview: Damagedproductpriceview;
  _IsProgressSpinner: boolean = true;
  constructor(
    private fb: FormBuilder,
    private _DamagedproductpricedetailsService: DamagedproductpricedetailsService,
    private router: Router,
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private exportUtility: ExportUtility
  ) {
    this._Action = this._AccessRightsService.getAccessRights(85);
    this.HotKeyIntegration();
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'productname', header: 'Product Name' },
      { field: 'severityname', header: 'Severity Name' },
      { field: 'markuplevelname', header: 'Markup Level' },
      { field: 'vatpercentagename', header: 'VAT Percantage' },
      { field: 'unitprice', header: 'Unit Price' },
      { field: 'damagedprice', header: 'Damaged Unit Price' },
      { field: 'statusname', header: 'Status' }
    ];
    this._IsProgressSpinner = true;
    this._DamagedproductpricedetailsService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Damagedproductpriceviews = resultJSON.damagedproductpriceviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/cdamagedproductpricedetails'], { state: { productid: event.productid, severity: event.severity, markuplevelid: event.markuplevelid, action: 'view' } });
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  edit(event) {
    if (event.status == "MSC00045" || event.status == "45" || event.status == null) {
      this.router.navigate(['/cdamagedproductpricedetails'], { state: { productid: event.productid, severity: event.severity, markuplevelid: event.markuplevelid, action: 'edit' } });
    }
    else {
      this._CustomExceptionService.handleWarning("Approved Record Should not Update");
    }
  }
  GoToCreate(event) {
    this.router.navigate(['/cdamagedproductpricedetails']);
  }
  exportExcel() {
    let productsList = this.GetDamagaeProductPriceList();
    this.exportUtility.exportExcel(productsList, 'DamagedProductPriceDetails', 'xlsx');
  }
  ExportToPdf() {
    let finalizedList = this.GetDamagaeProductPriceList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Product Name',
      'Severity Name',
      'Markup Level',
      'VAT Percentage',
      'Unit Price',
      'Damaged Unit Price',
      'Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('DamagedProductPriceDetails.pdf');
      })
    })
  }
  GetDamagaeProductPriceList() {
    if (!this._Damagedproductpriceviews || !this._Damagedproductpriceviews.length)
      return;
    let productsList = [];
    this._Damagedproductpriceviews.forEach(element => {
      let products: any = {};
      products["Product Name"] = element.productname;
      products["Severity Name"] = element.severityname;
      products["Markup Level"] = element.markuplevelname;
      products["VAT Percentage"] = element.vatpercentagename;
      products["Unit Price"] = element.unitprice;
      products["Damaged Unit Price"] = element.damagedprice;
      products["Status"] = element.statusname;
      productsList.push(products);
    });
    return productsList;
  }
}
