import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductRequestService {
  public _ProductRequestService = environment.ProductRequestService;
  public _httpOptions;
  token: any;
  header: any;
  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
   }
   createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(ProductRequestId: number): Observable<any> {
    const headers = this.header;
    var Query = this._ProductRequestService + '/' + ProductRequestId

    return this.http.get<{ data: any }>(Query, { headers });
  }
  Getproductcategory(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ProductRequestService + '/Getproductcategory';
    return this.http.post(Query, obj, { headers });
  }
  GetProductname(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ProductRequestService + '/GetProductname';
    return this.http.post(Query, obj, { headers });
  }


  GetProductSearch(product: string, branchid: number) {
    const headers = this.header;
    var Query = this._ProductRequestService + '/GetProductSearch' + '/' + product + '/' + branchid;
    console.log(Query);
    return this.http.get<{ data: any }>(Query, { headers });
  }

  getProductVariants(productid: number, currentbranchid: number) {
    const headers = this.header;
    var Query = this._ProductRequestService + '/getProductVariants' + '/' + productid + '/' + currentbranchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }


  create(Data: object) {
    const headers = this.header;
    var Query = this._ProductRequestService;
    return this.http.post<{ data: any }>(Query, Data, { headers });
  }
  update(Data: object) {
    const headers = this.header;
    var Query = this._ProductRequestService + '/Update';
    return this.http.post<{ data: any }>(Query, Data, { headers });
  }
  SendToApproval(workflowid: number, userid: number, materialrequestno: string) {
    const headers = this.header;
    console.log("Send To Approval");
    var Query = this._ProductRequestService + '/SendToApproval' + '/' + workflowid + '/' + userid + '/' + materialrequestno;
    return this.http.post<{ data: any }>(Query, '', { headers });
  }
  changestatus(materialrequestid: number) {
    const headers = this.header;
    console.log("Send To Approval");
    var Query = this._ProductRequestService + '/Changestatus' + '/' + materialrequestid;
    return this.http.post<{ data: any }>(Query, '', { headers });
  }


  FetchAll(branchid: number, userid: number, roleid: number, fromdate: string, todate: string) {
    const headers = this.header;
    var Query = this._ProductRequestService + '/FetchAll' + '/' + branchid + '/' + userid + '/' + roleid + '/' + fromdate + '/' + todate;
    console.log(Query);
    return this.http.get<{ data: any }>(Query, { headers });
  }
  Print(obj: any) {
    const headers = this.header;
    var Query = this._ProductRequestService + '/Print';
    return this.http.post(Query, obj, { responseType: 'blob' ,  headers });
  }
}
