<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product Freezing</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" (click)="OnSave()" title="Save" icon="pi pi-save"
                                class=""></button>
                            <button pButton pRipple label="" (click)="reset()" title="Clear" icon="pi pi-trash" class=""
                                class="p-button-danger"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_productfreezingform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_branchlist"
                                            optionValue="branchid" optionLabel="branchname" [virtualScroll]="true"
                                            itemSize="30" formControlName="branchid" [filter]="true"
                                            filterBy="branchname" [disabled]="isDisablebranch"
                                            (onChange)="GetProductFreezingDetails()">
                                        </p-dropdown>
                                        <label for="branchname">Branch <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <div class="sub-title">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <h5>Multiple Product Search</h5>
                                            </div>
                                            <div class="col-md-7"></div>
                                        </div>
                                    </div>
                                </td>
                                <td colspan="2">
                                    <div class="sub-title">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h5>Single Product Search</h5>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_SupplierFliter"
                                            optionValue="supplierid" optionLabel="suppliername"
                                            formControlName="supplierid" [filter]="true" filterBy="suppliername"
                                            [virtualScroll]="true" itemSize="15" (onChange)="getProductType()"
                                            [disabled]="isDisablesupplier">
                                        </p-dropdown>
                                        <label for="supplierid">Supplier Name <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="true" [options]="_producttypelist" name="producttypeid"
                                            optionLabel="producttypename" selectedItemsLabel="{0} items selected"
                                            [virtualScroll]="true" itemSize="30" optionValue="producttypeid"
                                            (onChange)="GetProductCategories()" formControlName="producttypeid"
                                            displaySelectedLabel=true [disabled]="isDisableproducttype">
                                        </p-multiSelect>
                                        <label for="brandid">Product type<span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="true" [options]="_productcategorylist"
                                            name="productcategoryid" optionLabel="categoryname"
                                            selectedItemsLabel="{0} items selected" [virtualScroll]="true" itemSize="30"
                                            optionValue="productcategoryid" formControlName="productcategoryid"
                                            (onChange)="GetProducts()" displaySelectedLabel=true
                                            [disabled]="isDisableproductcat">
                                        </p-multiSelect>
                                        <label for="brandid">Product Category<span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <!-- <td style="width: 20%;"></td> -->
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-autoComplete formControlName="autoproductid" [suggestions]="filteredProducts"
                                            (completeMethod)="filterProducts($event)" field="productname"
                                            (onSelect)="getproductfrezingdetails($event)"
                                            [disabled]="isDisableautoproduct" [(ngModel)]="autoproductid">
                                            <ng-template let-product pTemplate="item">
                                                <div>{{product.productname}}</div>
                                            </ng-template>
                                        </p-autoComplete>
                                        <label for="autoproductid">Product Name <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;"></td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="true" [options]="_productslist" name="productid"
                                            optionLabel="productname" selectedItemsLabel="{0} items selected"
                                            [virtualScroll]="true" itemSize="30" optionValue="productid"
                                            formControlName="productid" displaySelectedLabel=true
                                            [disabled]="isDisableproduct">
                                        </p-multiSelect>
                                        <label for="brandid">Product Name<span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="true" [options]="_brands" name="brandname"
                                            optionLabel="brandname" optionValue="brandid"
                                            selectedItemsLabel="{0} items selected" [virtualScroll]="true" itemSize="30"
                                            formControlName="brandid" displaySelectedLabel=true
                                            [disabled]="isDisablebrand">
                                        </p-multiSelect>
                                        <label for="brandid">Brand<span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" title="Search" icon="pi pi-search"
                                        (click)="SearchProductFreezing()" class="p-button-success p-mr-2"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="reset()"
                                        class="p-button-danger"></button>
                                </td>

                            </tr>
                        </table>
                    </form>

                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <!-- <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                    (click)="exportExcel()" class="p-mr-2" pTooltip="CSV"
                                    tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                    class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                    (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                    class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                    (click)="ExportToPdf()"></button> -->
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_ProductFreezings" [scrollable]="true" scrollHeight="450px" [rows]="50"
                            [(selection)]="selectedProductFreezings" (onRowSelect)="onRowSelect($event)"
                            (onRowUnselect)="onRowUnselect($event)" [paginator]="true"
                            [rowsPerPageOptions]="[50,100,200,400,500,1000]"
                            [globalFilterFields]="['branchname','producttypename','categoryname','productname']"
                            [rowHover]="true" dataKey="productid"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center;width: 80px;">
                                        <p-tableHeaderCheckbox (click)="SelectAll($event)"></p-tableHeaderCheckbox>

                                    </th>
                                    <th class="text-center" pSortableColumn="branchname">Branch
                                        Name<p-sortIcon field="branchname">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" pSortableColumn="producttypename">Product
                                        Type
                                        <p-sortIcon field="producttypename">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" pSortableColumn="categoryname">Product
                                        Category
                                        <p-sortIcon field="categoryname">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" pSortableColumn="productname">
                                        Product Name<p-sortIcon field="productname">
                                        </p-sortIcon>
                                    </th>


                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_Productfreezing let-i="rowIndex">
                                <tr>
                                    <td style="text-align: center;width: 80px;">
                                        <p-tableCheckbox [value]="_Productfreezing"></p-tableCheckbox>
                                    </td>
                                    <td>
                                        {{_Productfreezing.branchname}}
                                    </td>
                                    <td>
                                        {{_Productfreezing.producttypename}}
                                    </td>
                                    <td>
                                        {{_Productfreezing.categoryname}}
                                    </td>
                                    <td>
                                        {{_Productfreezing.productname}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <p-toast position="bottom-right"></p-toast>
</div>