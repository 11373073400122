import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Ordercanceldetail } from 'src/app/core/Models/Ordercanceldetail';
import { PaymentreturnStatusService } from 'src/app/core/Services/ecommerce/paymentreturn-status.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-payment-return',
  templateUrl: './v-payment-return.component.html',
  styleUrls: ['./v-payment-return.component.css']
})
export class VPaymentReturnComponent implements OnInit {

  defaultDate: Date;
  paymentHistoryGrid: Ordercanceldetail[] = [];
  _OrderTypes: any;
  ordertype: any;
  _PaymentModes: any[];
  PaymentMode: any;
  datepipe = new DatePipe('en-Us');
  fromdate: Date;
  _IsProgressSpinner: boolean = false;
  todate: Date;
  PaymentReturnStatusObj: any;
  CurrencySymbol: string;
  constructor(private router: Router, private utility: CommonUtilities, private _PaymentReturnStatus: PaymentreturnStatusService, private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService, public exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this.CurrencySymbol = "$";
    this.utility.pageLoadScript();
    this.fromdate = new Date();
    this.todate = new Date();
    this.PageOnload();
  // if(this.paymentHistoryGrid.length == 0){

  // }

    // this.paymentHistoryGrid=[
    //   {transactionID:'3958943284216906-8052876',orderNo:'0123445ABC',paymentAmount:'40.00',paymentDate:'29/05/2021 08:00 PM',paymentMode:'BANK',paymentStatus:'Completed',customerName:'Karthik Devaraj'},
    //   {transactionID:'3958943284216906-8052876',orderNo:'0123445ZCB',paymentAmount:'1140.00',paymentDate:'29/05/2021 08:00 PM',paymentMode:'BANK',paymentStatus:'Processing',customerName:'Karthik Devaraj'},
    // ]
  }

  PageOnload() {
    this._IsProgressSpinner = true;
    this._PaymentReturnStatus.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._OrderTypes = resultJSON.ordercanceltypes;
      this._PaymentModes = resultJSON.modeofpayments;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  CheckPaymentReturnStatus() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    if (!this.ordertype) {
      this._CustomExceptionService.handleError("Please Select Order Type");
      return;
    }
    if (!this.PaymentMode) {
      this._CustomExceptionService.handleError("Please Select Paymode Mode");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    let ordercanceltype
    let modeofpayment
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._PaymentReturnStatus.SearchPayment(fromdate, todate, this.ordertype, this.PaymentMode).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON)
      this.paymentHistoryGrid = resultJSON.paymentreturnDetails;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  UpdatePaymentReturnStatus() {
    if (this.paymentHistoryGrid.length == 0) {
      this._CustomExceptionService.handleWarning("Payment Refund should have atleast one order detail");
    }
    else {
      this._IsProgressSpinner = true;
      this.PaymentReturnStatusObj = { "Ordercanceldetail": this.paymentHistoryGrid };
      this._PaymentReturnStatus.Update(this.PaymentReturnStatusObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          this.paymentHistoryGrid = [] = [];
          this.ngOnInit();
          this._CustomExceptionService.handleSuccess("Saved Successfully");
        }
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
}
