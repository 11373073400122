import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class LevelService {
  public _Action: IAction;
  public _LevelService = environment.LevelService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(p_Levelid: number): Observable<any> {
    const headers = this.header;
    var Query = this._LevelService + '/' + p_Levelid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll() {
    const headers = this.header;
    var Query = this._LevelService + '/FetchAll';

    return this.http.get<{ data: any }>(Query , { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      //  let _StateScreenAccess = resultJSON.userData.lstFunctions.find(funct=>funct.functionid=="15");
      //  this._Action  = { 
      //    _functionId : 15,
      //    _functionName : "State",
      //    _Add :   _StateScreenAccess.iscreateallowed,
      //    _Delete : _StateScreenAccess.isdeleteallowed,
      //    _Update : _StateScreenAccess.iseditallowed,
      //    _View : _StateScreenAccess.isviewallowed,
      //    _Approve : _StateScreenAccess.isviewallowed
      //  }   
      return data;
    }),);
  }
  create(p_Level: object) {
    const headers = this.header;
    //console.log("Create");
    var Query = this._LevelService;
    return this.http.post<{ data: any }>(Query, p_Level, { headers });
  }

  edit(p_Level: object) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._LevelService;

    return this.http.put<{ data: any }>(Query, p_Level, { headers });
  }
  Cancel(levelid: number, userid: number) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._LevelService + '/ChangeStatus/' + levelid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });

  }
}

