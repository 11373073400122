import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class AccountgroupService {

  public _Action: IAction;
  public _AccountGroupService = environment.AccountGroupService;
  public _httpOptions;
  token: any;
  header: any;
  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
   }
   createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(p_accountgroupcode: string): Observable<any> {
    const headers = this.header;
    var Query = this._AccountGroupService + '/' + p_accountgroupcode;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll() {
    const headers = this.header;
    var Query = this._AccountGroupService + '/FetchAll';
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
    
      // let _CityScreenAccess = resultJSON.userData.lstFunctions.find(funct=>funct.functionid=="15");
      // this._Action  = { 
      //   _functionId : 15,
      //   _functionName : "City",
      //   _Add :   _CityScreenAccess.iscreateallowed,
      //   _Delete : _CityScreenAccess.isdeleteallowed,
      //   _Update : _CityScreenAccess.iseditallowed,
      //   _View : _CityScreenAccess.isviewallowed,
      //   _Approve : _CityScreenAccess.isviewallowed
      // }   
      return data;
    }));
  }
  create(p_AccountGroup: object) {
    const headers = this.header;
    var Query = this._AccountGroupService;
    return this.http.post<{ data: any }>(Query, p_AccountGroup, { headers });
  }
  
  edit(p_AccountGroup: object) {
    const headers = this.header;
    var Query = this._AccountGroupService;
    return this.http.put<{ data: any }>(Query, p_AccountGroup, { headers });
  }
}
