import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Branch } from 'src/app/core/Models/Branch';
import { Documenttypeworkflowstagebranchview } from 'src/app/core/Models/Documenttypeworkflowstagebranchview';
import { Documenttypeworkflowstageview } from 'src/app/core/Models/Documenttypeworkflowstageview';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { User } from 'src/app/core/Models/User';
import { ApprovalbranchService } from 'src/app/core/Services/users/approvalbranch.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { vBranchSelect, vSelectDocumentName, vWorkflowName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

@Component({
  selector: 'app-c-approvalbranch',
  templateUrl: './c-approvalbranch.component.html',
  styleUrls: ['./c-approvalbranch.component.css']
})
export class CApprovalbranchComponent implements OnInit {
  _Approvalbranchform: FormGroup;
  _Approvalbranchdetailform: FormGroup;
  //_Approvalcorporateworkflowdetailform: FormGroup;
  _action: string = "create";
  _submitted = false;
  _Approvalcorporate: Documenttypeworkflowstageview;

  _workflowdetails: Documenttypeworkflowstagebranchview[] = [];
  _workflowdetail: Documenttypeworkflowstagebranchview;

  selectedrows: any;
  _workflowid: number;
  _documenttypeid: number
  _branchid = sessionStorage["currentbranchid"];;
  _Documentname: Documenttypeworkflowstageview;
  _Workflowname: Documenttypeworkflowstageview[];
  _Workflowlist: Documenttypeworkflowstageview[];
  _Branch: Branch
  _Workflowstatus: Metadatum
  _Primaryapproved: User
  _Secondaryapproved: User
  _approvalbranchViews: Documenttypeworkflowstagebranchview[] = [];
  _approvalViews: Documenttypeworkflowstagebranchview[] = [];
  _workflowdetailform: Documenttypeworkflowstagebranchview[];
  _ApprovalbrachOBJ: any;
  isDisabledSave = false;
  isDisabledClear = false;
  _IsProgressSpinner: boolean = true;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _router: Router, private _CustomExceptionService: CustomExceptionService,
    private _ApprovalbranchService: ApprovalbranchService, private _hotkeysService: HotkeysService) {
    this.HotKeyIntegration();
  }
  //Parent Form
  InitializeForm() {
    this._Approvalbranchform = this.fb.group({
      documenttypeworkflowstageid: [],
      companyid: [],
      branchid: [],
      branchname: [],
      documenttypeid: [],
      documenttypename: [],
      workflowid: [],
      workflowname: [],
      stageid: [],
      stagename: [],
      roleid: [],
      rolename: [],
      isbranchdependent: [],
      branchdependentname: [],
      primaryapprover: [],
      primaryapprovername: [],
      secondaryapprover: [],
      secondaryapprovername: [],
      stageaction: [],
      stageactionname: [],
      approvedstatus: [],
      rejectedstatus: [],
      stagestatus: [],
      status: [],
      stagestatusname: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
    this._Approvalbranchdetailform = this.fb.group({
      companyid: [],
      branchid: [],
      branchname: [],
      documenttypeid: ['', vSelectDocumentName],
      documenttypename: [],
      workflowid: ['', vWorkflowName],
      workflowname: [],
      stageid: [],
      stagename: [],
      primaryapprover: [],
      primaryapprovername: [],
      secondaryapprover: [],
      secondaryapprovername: [],
      stagestatus: [],
      stagestatusname: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      documenttypeworkflowstageid: [],
      roleid: [],
      rolename: [],
      isbranchdependent: [],
      stageaction: [],
      stageactionname: [],
      approvedstatus: [],
      rejectedstatus: [],
    });
  }
  
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._workflowid = history.state?.workflowid ? history.state?.workflowid : 0;
    this._documenttypeid = history.state?.documenttypeid ? history.state?.documenttypeid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;

    if (this._action == 'view') {
      this._Approvalbranchdetailform.disable();
      this.isDisabledSave = true;
      this.isDisabledClear = true;
    }
    if (this._action == 'edit') {
      this.isDisabledSave = false;
      this.isDisabledClear = true;
    }
    this._IsProgressSpinner = true;
    this._ApprovalbranchService.PageOnLoad(this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Documentname = resultJSON.documentlist;
      this._Workflowlist = resultJSON.documenttypeworkflowviews;
      this._Branch = resultJSON.branches;
      this._Workflowstatus = resultJSON.metadata;
      this._Primaryapproved = resultJSON.userviews;
      this._Secondaryapproved = resultJSON.userviews;
      this._approvalbranchViews = resultJSON.documenttypeworkflowstageviews;
      this._approvalViews = resultJSON.documenttypeworkflowstagebranchviews;
      this._IsProgressSpinner = false;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._Approvalbranchdetailform.get("documenttypeid").disable();
        this._Approvalbranchdetailform.get("workflowid").disable();
        this._Branch = updateJSON.branches;
        this._workflowdetails = (this._approvalbranchViews || []).filter(f => f.documenttypeid == this._documenttypeid && f.workflowid == this._workflowid && f.branchid == this._branchid);
        this._workflowdetailform = (this._workflowdetails || []).filter(f => f.workflowid == this._workflowid);
        this._Approvalbranchdetailform.controls["documenttypeid"].setValue(this._workflowdetailform[0].documenttypeid);
        this.ChangeDocument();
        this._Approvalbranchdetailform.controls["workflowid"].setValue(this._workflowdetailform[0].workflowid);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ChangeDocument(): void {
    var documnettype = parseInt(this._Approvalbranchdetailform.get("documenttypeid").value);
    if (!documnettype)
      return;
    this._Workflowname = (this._Workflowlist || []).filter(f => f.documenttypeid == documnettype);
  }

  ChangeWorkflow() {
    var workflowdetail = parseInt(this._Approvalbranchdetailform.get("workflowid").value);
    if (!workflowdetail)
      return;
    this._workflowdetails = (this._approvalbranchViews || []).filter(f => f.workflowid == workflowdetail && f.branchid  == this._branchid);
    if (this._workflowdetails.length <= 0) {
      this._workflowdetails = (this._approvalbranchViews || []).filter(f => f.workflowid == workflowdetail && f.branchid == 1);
      Object.keys(this._workflowdetails).map((Index) => {
        this._workflowdetails[Index].primaryapprover = '';
        this._workflowdetails[Index].secondaryapprover ='';
      });

    }
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  RemoveWorkflow(pWorkflowdetail: Documenttypeworkflowstagebranchview) {
    var index = this._workflowdetails.indexOf(pWorkflowdetail);
    this._workflowdetails.splice(index, 1)
  }
  onSave() {
    this._submitted = true;
    if (this._Approvalbranchdetailform.valid) {
      this._ApprovalbrachOBJ = { "Documenttypeworkflowstagebranch": this._workflowdetails };
      if (this._action == 'create') {
        for (var i in this._workflowdetails) {
          this._workflowdetails[i].branchid = sessionStorage["BranchID"];
          this._workflowdetails[i].companyid = 0;
        }
        // this.onEd();
        this._IsProgressSpinner = true;
        this._ApprovalbranchService.create(this._ApprovalbrachOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsProgressSpinner = false;
            this.reset(null);
            this.InitializeForm();
            this._workflowdetails = [];
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          } this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        this._IsProgressSpinner = true;
        this._ApprovalbranchService.edit(this._ApprovalbrachOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this._IsProgressSpinner = false;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
  reset(event) {
    this._Approvalbranchdetailform.reset();
    this._submitted = false;
  }
  goBack(event) {
    this._router.navigate(['/vApprovalbranch']);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
 
  // onEd() {
  //   alert("test");
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._ApprovalbrachOBJ;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }
}
