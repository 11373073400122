<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Tender </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" type="submit" [disabled]="isDisabledsave" title="Save"
                                icon="pi pi-save" type="button" (click)="onSave()"></button>
                            <button pButton pRipple label="" type="button" [disabled]="isDisabledreset" title="Clear"
                                icon="pi pi-trash" (click)="reset($event)" class="p-button-danger"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search" type="button"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-container scroll-y">
                <form [formGroup]="_tenderform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <!-- if "Tender Reference No" is not enter, show error as "Please Enter Tender Reference No" -->
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="tenderrefno">
                                    <label for="tenderrefno">Tender Reference No. <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_tenderform.get('tenderrefno').touched || _submitted) && _tenderform.get('tenderrefno').errors?.TenderReferenceNoRequired">
                                    Please Enter Tender Reference No.
                                </span>
                            </td>
                            <td>
                                <!-- If Tender Reference Date"  is not select  show error as  "please select Tender Reference Date " -->
                                <span class="p-float-label">


                                    <p-calendar [showIcon]="true" [readonlyInput]="true" dateFormat="dd/mm/yy"
                                        formControlName="tenderrefdate" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="tenderrefdate">Tender Reference Date<span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_tenderform.get('tenderrefdate').touched || _submitted) && _tenderform.get('tenderrefdate').errors?.SelectTenderReferenceDate">
                                    Please Select Tender Reference Date
                                </span>
                            </td>
                            <!-- if "Tender Party" is not enter, show error as "Please Enter Tender Party Name" -->
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="customername" maxlength="90">
                                    <label for="customername">Tender Party <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_tenderform.get('customername').touched || _submitted) && _tenderform.get('customername').errors?.TenderPartyNameRequired">
                                    Please Enter Tender Party Name.
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_tenderresourceform" (ngSubmit)="onSave()">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="false" [options]="_resourceslist" name="employeeid"
                                            optionLabel="employeename" optionValue="employeeid"
                                            formControlName="resourceid" (onChange)="onSelectResources($event)"
                                            [(ngModel)]="_selectedResourceIds" displaySelectedLabel=true>
                                        </p-multiSelect>
                                        <label for="resourceid">Resources Name </label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <!-- If Tender End Date"  is not select  show error as  "please select Tender End Date " -->
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" dateFormat="dd/mm/yy" formControlName="enddate"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="enddate">Tender End Date</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText formControlName="tenderamount"
                                        (keyup)="ChangeCurrencies()"  [(ngModel)] = "decimalValue" (keypress)="validateDecimal($event)" maxlength="90">
                                    <label for="tenderamount" >Tender Value </label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_currency" name="currencyid"
                                        (onChange)="ChangeCurrencies()" formControlName="currencyid"
                                        optionLabel="currencyname" optionValue="currencyid"></p-dropdown>
                                    <label for="currency">Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_tenderform.get('currencyid').touched || _submitted) && _tenderform.get('currencyid').errors?.SelectCurrency">
                                    Please Select Currency
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText formControlName="amountinusd" disabled>
                                    <label for="amountinusd">Value in USD </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <!-- If "Tender Status"  is not select  show error as  " please select Tender Status " -->
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_tenderstatus"
                                        formControlName="tenderstatusid" optionLabel="metasubdescription"
                                        optionValue="metasubid"></p-dropdown>
                                    <label for="gender">Tender Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_tenderform.get('tenderstatusid').touched || _submitted) && _tenderform.get('tenderstatusid').errors?.TenderStatus">
                                    Please Select Tender Status
                                </span>
                            </td>
                            <td style="width: 20%;" rowspan="3">
                                <span class="p-float-label">
                                    <textarea rows="2" cols="30" pInputTextarea
                                        formControlName="tenderdescription" maxlength="90"></textarea>
                                    <label for="tenderdescription">Tender Description </label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="row">
                    <div class="col-md-6">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Product Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <form [formGroup]="_tenderdetailform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-multiSelect [options]="_producttype" optionLabel="producttypename"
                                                optionValue="producttypeid" (onChange)="getProductCategory($event)"
                                                formControlName="producttypeid" [disabled]="_action=='view'">
                                            </p-multiSelect>
                                            <label for="producttypeid">Product Type </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-multiSelect [options]="_productcategory" optionLabel="categoryname"
                                                (onChange)="getProduct($event)" optionValue="productcategoryid"
                                                [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}"
                                                [disabled]="_action=='view'">
                                            </p-multiSelect>
                                            <label for="productcategoryid">Product Category </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">

                                        <span class="p-float-label">
                                            <!-- <p-dropdown [autoDisplayFirst]="false" [options]="productsearchview"
                                                optionValue="productid" optionLabel="productname"
                                                formControlName="productid" (onChange)="getProductVariant($event)"
                                                [filter]="true" filterBy="searchfilter" [showClear]="true">
                                            </p-dropdown> -->
                                            <p-autoComplete formControlName="productid" [suggestions]="filteredProducts"
                                                (completeMethod)="filterProducts($event)" field="productname"
                                                (onSelect)="getProductVariant($event)">
                                                <ng-template let-product pTemplate="item">
                                                    <div>{{product.productname}}</div>
                                                </ng-template>
                                            </p-autoComplete>
                                            <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                        *ngIf="(_submitted1 || _tenderdetailform.get('productid').touched) && _tenderdetailform.get('productid').errors?.SelectProductName">
                                            Please select product
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_variantdetails"
                                                formControlName="variantid" optionValue="productvariantdetailid"
                                                optionLabel="variantdescription">
                                            </p-dropdown>
                                            <label for="variantid">Variant </label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="quantity" formControlName="quantity"
                                                formControlName="quantity" maxlength="7"
                                                (keypress)="keypress.kpNumber($event)" pInputText>
                                            <label for="quantity">Quantity <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                        *ngIf="(_submitted1 || _tenderdetailform.get('quantity').touched) && _tenderdetailform.get('quantity').errors?.QuantityRequired">
                                            Please Enter Quantity
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_uomdetails"
                                                optionValue="metasubcode" optionLabel="metasubdescription"
                                                formControlName="uom"  [disabled]="isdisableduom">
                                            </p-dropdown>
                                            <label for="metasubdescription">UOM </label>
                                        </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_tenderdetailform.get('uom').touched || _submitted1) && _tenderdetailform.get('uom').errors?.SelectUOM">
                                            Please Select UOM
                                        </span> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button pButton pRipple label="Add" icon="pi pi-plus" [disabled]="isDisabledadd"
                                            (click)="onAddProduct($event)"
                                            class="p-button-sm p-button-success"></button>
                                        <button pButton pRipple label="Clear" icon="pi pi-times"
                                            [disabled]="isDisabledclear" (click)="onClearProduct($event)"
                                            class="p-button-danger"></button>
                                    </td>
                                </tr>
                            </table>
                        </form>
                        <div class="card">
                            <p-table [value]="_tenderproductdetails" [rows]="10" [paginator]="true" dataKey="Sno"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [showCurrentPageReport]="true"
                                editMode="row" [rowsPerPageOptions]="[5,10,25,50,100]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:100px;text-align: center;">Action</th>
                                        <th>Product Name</th>
                                        <th>Variant</th>
                                        <th>Quantity</th>
                                        <th>UOM</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_product let-ri="rowIndex">
                                    <tr>
                                        <!-- (dblclick)="onRowtenderselect(_product,ri)" -->
                                        <td style="text-align:center">
                                            <button pButton pRipple label="" title="Remove" icon="pi pi-trash"
                                                class="p-button-danger" [disabled]="isDisabledproductremove"
                                                (click)="RemoveProductDetail(_product)"></button>
                                            {{_product.Action}}
                                        </td>
                                        <td>
                                            {{_product.productname}}
                                        </td>
                                        <td>
                                            {{_product.variantdescription}}
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" pInputText name="quantity"
                                                        [(ngModel)]="_product.quantity" maxlength="7"
                                                        (keypress)="keypress.kpNumber($event)"
                                                        *ngIf="_action != 'view'">
                                                    <div *ngIf="_action == 'view'">{{_product.quantity}}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_product.quantity}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <!-- <td>
                                            {{_product.quantity}}
                                        </td> -->
                                        <td>
                                            {{_product.uomname}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Document Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <form [formGroup]="_tenderdocumentdetailform">
                            <table class="normal-table">
                                <!-- <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="attachmentname">
                                            <label for="attachmentname">Document Name</label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_tenderdocumentdetailform.get('attachmentname').touched || _submitted2) && _tenderdocumentdetailform.get('attachmentname').errors?.AttachmentRequired">
                                            Please Enter Document Name
                                        </span>
                                    </td>
                                  
                                </tr> -->
                                <tr>
                                    <td style="width: 20%;">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <div class="browse-links">
                                                    <p-button label="Tender Document" styleClass="p-button-link"
                                                        (click)="showdocumentDialog(documentgrid,'bottom-right')">
                                                    </p-button>
                                                </div>
                                            </span>
                                            <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                                pTooltip="Browse File" tooltipPosition="top" mode="basic" #documentgrid
                                                accept="image/*,.pdf,.xls,.xlsx,.docx,.doc,.txt" maxFileSize="1000000" [auto]="true"
                                                (onSelect)="onDocumentTenderSelect($event,documentgrid)"
                                                [disabled]="_action=='view'">
                                            </p-fileUpload>
                                            <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                                (click)="onDocumentTenderClear()" tooltipPosition="top"
                                                [disabled]="_action=='view'">
                                                <i class="pi pi-times"></i>
                                            </button>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_submitted2 && _LastAddedGridDocument == null)">
                                            Please Add Document
                                        </span>
                                    </td>
                                    <td style="width: 20%;" rowspan="2">
                                        <!-- <span class="p-float-label">
                                            <textarea cols="30" pInputTextarea
                                                formControlName="documentdescription"></textarea>
                                            <label for="documentdescription">Document Description <span
                                                    class="hlt-txt">*</span>
                                            </label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_tenderdocumentdetailform.get('documentdescription').touched || _submitted2) && _tenderdocumentdetailform.get('documentdescription').errors?.AttachmentDescription">
                                            Please Enter Document Description
                                        </span> -->
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_DocumentDescriptions"
                                                optionValue="documentdescriptionname" optionLabel="documentdescriptionname" formControlName="documentname"
                                                [filter]="true" filterBy="documentdescriptionname">
                                            </p-dropdown>
                                            <label for="documentname">Document Name<span
                                                class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                        *ngIf="(_tenderdocumentdetailform.get('documentname').touched || _submitted2) && _tenderdocumentdetailform.get('documentname').errors?.DocumentNameRequired">
                                        Please Select Document Name
                                    </span> 
                                    
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <button type="submit" pButton pRipple label="Add" title="Add" icon="pi pi-plus"
                                            [disabled]="isDisabledadddocument" (click)="onAddDocuments($event)"
                                            class="p-button-success p-mr-2" ></button>
                                        <button pButton pRipple label="Clear" title="Clear" icon="pi pi-times"
                                            (click)="TenderClear()" [disabled]="isDisabledcleardocument"
                                            class="p-button-danger p-mr-2"></button>
                                    </td>
                                </tr>
                            </table>
                        </form>
                        <div class="card">
                            <p-table #dt [value]="_tenderdocumentdetail" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]"
                                [globalFilterFields]="['documentdescription','documentname']" [rowHover]="true"
                                dataKey="address" [(selection)]="selectedrows"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;width: 100px;">Action</th>
                                        <th>Document Name</th>
                                        <th>Attachment Name</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_tenderdocumentdetail>
                                    <tr>
                                        <td style="text-align: center;">
                                            <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                [disabled]="isDisabledremovedocument"
                                                (click)="RemoveDocumentDetail(__tenderdocumentdetail)"
                                                class="p-button-danger p-mr-2"></button>
                                        </td>
                                        <td>
                                            {{_tenderdocumentdetail.documentdescription}}
                                        </td>

                                        <td>
                                            <a href="javascript:void(0);"
                                                (click)="showdocumentGridDialog('bottom-right',_tenderdocumentdetail)">
                                                {{_tenderdocumentdetail.attachmentname}}
                                            </a>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Tender History</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <p-toolbar styleClass="p-mb-4">
                    <ng-template pTemplate="left">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                        </span>
                    </ng-template>
                </p-toolbar>
                <div class="card">
                    <p-table #dt [value]="_tenderhistory" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['tenderdescription','startdate','enddate','tenderstatusdesc']"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [(selection)]="selectedrows"  [showCurrentPageReport]="true"
                        [rowHover]="true" dataKey="tenderdescription">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:100px;text-align: center;">Action</th>
                                <th>Description</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Status</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_tenderhistory>
                            <tr>
                                <td style="width:100px;text-align: center;">
                                    <button pButton pRipple title="Add" icon="pi pi-plus"
                                        class="p-button-success p-mr-2" [disabled]="isDisabledaddtenderhistory"
                                        (click)="AddTenderHistory($event)"></button>
                                    <button pButton pRipple title="Remove" icon="pi pi-trash"
                                        class="p-button-danger p-mr-2" [disabled]="isDisabledcleartenderhistory"
                                        (click)="RemoveTenderHistory(_tenderhistory)"></button>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <textarea rows="2" cols="30" pInputTextarea
                                                [disabled]="isDisabledtenderdescription"
                                                [(ngModel)]="_tenderhistory.tenderdescription"
                                                style="overflow:auto;resize:none"></textarea>

                                            <!-- <input type="text" [disabled]="isDisabledtenderdescription"
                                               
                                                [(ngModel)]="_tenderhistory.tenderdescription" pInputText> -->

                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_tenderhistory.tenderdescription}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [(ngModel)]="_tenderhistory.startdate"
                                                [disabled]="isDisabledstartdate" dateFormat="dd/mm/yy"
                                                [showIcon]="false" [minDate]="minDate">
                                            </p-calendar>
                                            <!-- <div [hidden]="_action == 'view'">{{_tenderhistory.startdate |
                                                date:'dd/MM/yyyy'}}</div> -->
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_tenderhistory.startdate | date:'dd/MM/yyyy'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [disabled]="isDisabledenddate"
                                                [(ngModel)]="_tenderhistory.enddate" dateFormat="dd/mm/yy"
                                                [showIcon]="false" [minDate]="minDate">
                                            </p-calendar>
                                            <!-- <div [hidden]="_action == 'view'">{{_tenderhistory.enddate |
                                                date:'dd/MM/yyyy'}}</div> -->
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_tenderhistory.enddate | date:'dd/MM/yyyy'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown *ngIf="isDisabledtenderstatus" #id [autoDisplayFirst]="false"
                                                [options]="_tenderstatusdetail" optionLabel="metasubdescription"
                                                optionValue="metasubid" [(ngModel)]="_tenderhistory.tenderstatusid">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                [options]="_tenderstatusdetail" optionLabel="metasubdescription"
                                                optionValue="metasubid" [(ngModel)]="_tenderhistory.tenderstatusid">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-dialog header="Tender Document" [(visible)]="displaydocumentgridpopup" [position]="position"
                    [style]="{width: '500px'}" [baseZIndex]="10000">
                    <img [src]="documentimage" alt="" class="img-preview">
                    <ng-template pTemplate="footer">
                        <p-button icon="pi pi-check" (click)="closePopupGridDialog()" label="Ok"
                            styleClass="p-button-text">
                        </p-button>
                    </ng-template>
                </p-dialog>
            </div>
        </div>
    </div>

    <p-toast position="bottom-right"></p-toast>