export class Jobcarddetail {
    jobcarddetailid: number;
    jobcardid: number;
    productid: number;
    uom: string;
    unitprice: number;
    finalamount: number;
    srquantity: number;


}