export class Tenderhistory {
    tenderhistoryid: number;
    tenderid: number;
    tenderdescription: string;
    startdate: Date;
    enddate: Date;
    tenderstatusid: number;
    tenderstatusdesc: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    accountingyear: number;
}

