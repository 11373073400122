import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';
@Injectable({
	providedIn: 'root'
})
export class GrnService {
	public _GrnService = environment.grnService;
	public _RptGRNPrintService = environment.RptGRNPrintService;
	public _httpOptions;
	token: any;
	header: any;

	constructor(private http: HttpClient) {
		this.token = sessionStorage['AuthToken'];
		this.header = this.createHeaders(this.token, 1)
	}
	createHeaders(token: any, type: number): HttpHeaders {
		return new HttpHeaders({
			Authorization: `Bearer ${token}`, // Include your token here	
		});
	}
	FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._GrnService + '/FetchAllLoad' + '/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;
		return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
			const resultJSON = JSON.parse(JSON.stringify(data));
			return data;
		}));
	}
	PageOnLoad(_GRNno: string): Observable<any> {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._GrnService + '/' + _GRNno;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	onchangeinwardtype(inwardtype: string, branchid: number, userid: number) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._GrnService + '/getInwardNos' + '/' + inwardtype + '/' + branchid + '/' + userid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	onchangeinwardno(inwardid: number, inwardtype: string, branchid: number, barcode: string) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._GrnService + '/getProductDetails' + '/' + inwardid + '/' + inwardtype + '/' + branchid + '/' + barcode;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	edit(_GrnFORM: FormData) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._GrnService;
		return this.http.put<{ data: any }>(Query, _GrnFORM, { headers });
	}
	create(_GrnFORM: FormData) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._GrnService;
		return this.http.post<{ data: any }>(Query, _GrnFORM, { headers });
	}
	SendToApproval(workflowid: number, userid: number, branchid: number, grnid: number, series: string) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._GrnService + '/SendToApproval' + '/' + workflowid + '/' + userid + '/' + branchid + '/' + grnid + '/' + series;
		return this.http.post<{ data: any }>(Query, '', { headers });
	}
	Print(obj: any) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._GrnService + '/GRNPRINT';
		return this.http.post(Query, obj, { responseType: 'blob', headers });
	}
	updatestock(grnno: string, productid: number, qty: number, user: number, lotno: number, oldlotno: number, inwardtype: string) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._GrnService + '/updatestock/' + grnno + '/' + productid + '/' + qty + '/' + user + '/' + lotno + '/' + oldlotno + '/' + inwardtype;
		return this.http.get<{ data: any }>(Query, { headers });
	}

	PriceUpdate(grnno: string, productid: number, user: number) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._GrnService + '/updateprice/' + grnno + '/' + productid + '/' + user;
		return this.http.get<{ data: any }>(Query, { headers });
	}

	UpdateReceiverStock(grnno: string, productid: number, lotno: number) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._GrnService + '/updatereceiverstock/' + grnno + '/' + productid + '/' + lotno;
		return this.http.get<{ data: any }>(Query, { headers });
	}
}