import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { OrderstatusdescriptionService } from 'src/app/core/Services/ecommerce/orderstatusdescription.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-orderdescription',
  templateUrl: './v-orderdescription.component.html',
  styleUrls: ['./v-orderdescription.component.css']
})
export class VOrderdescriptionComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Orderdescriptions: any[] = [];
  // selectedrows: any;
  // pSelectableRow: any;
  // _Add: Boolean;
  // _delete: Boolean;
  // _update: Boolean;
  // _View: Boolean;
  _Action: IAction;
  _IsProgressSpinner: boolean = true;
  cols: any[];
  OSID:number;
  constructor(private fb: FormBuilder,
    private datePipe: DatePipe, private _OrderDescriptionService: OrderstatusdescriptionService,
    private _router: Router, private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,public exportUtility: ExportUtility,private confirmationService: ConfirmationService,) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(303);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'orderstatusname', header: 'Order Status' },
      { field: 'ordersubstatusname', header: 'Order Sub Status' },
      { field: 'orderdescstatusname', header: 'Order Description' },
    ]
    this._IsProgressSpinner = true;
    this._OrderDescriptionService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // console.log(resultJSON);
      this._Orderdescriptions = resultJSON.orderstatusdescheaderviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
    // console.log("eventfired");
    // console.log(event.data);
    // console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    // console.log("eventUnfired");
    // console.log(event.data);
    // console.log(this.selectedrows);
  }

  view(event) {
    this._router.navigate(['/cOrderDescription'], { state: { osdid: event.osdid, action: 'view' } });
  }
  GoToCreate(event) {
    this._router.navigate(['/cOrderDescription']);
  }
  edit(event) {
    // console.log("event.value");
    // console.log(event.osdid);
    this._router.navigate(['/cOrderDescription'], { state: { osdid: event.osdid, action: 'edit' } });
  }
  confirm(event) {
    this.OSID = event.osdid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to Delete  this Order Description <b>"' + event.orderdescstatusname + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Delete(event);
      }
    });
  }
  Delete(event) {
    this._IsProgressSpinner = true;
    this._OrderDescriptionService.Delete(this.OSID).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // console.log(resultJSON);
      this._Orderdescriptions = resultJSON.orderstatusdescheaderviews;
      this._IsProgressSpinner = false;
      this._OrderDescriptionService.FetchAll().subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        // console.log(resultJSON);
        this._Orderdescriptions = resultJSON.orderstatusdescheaderviews;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let OrderDescriptionList = this.GetOrderDescriptionList();
    this.exportUtility.exportExcel(OrderDescriptionList, 'OrderDescription', 'xlsx');
  }

  ExportToPdf() {
    let OrderDescriptionList = this.GetOrderDescriptionList();
    this.exportUtility.ExportToPdf(OrderDescriptionList, 'OrderDescription.pdf');
  }

  GetOrderDescriptionList() {
    if (!this._Orderdescriptions || !this._Orderdescriptions.length)
      return;
    let OrderDescriptionList = [];
    this._Orderdescriptions.forEach(element => {
      let orderdescription: any = {};
      orderdescription["Order Status"] = element.orderstatusname;
      orderdescription["Order Sub Status"] = element.ordersubstatusname;
      orderdescription["Order Description"] = element.orderdescstatusname;
      OrderDescriptionList.push(orderdescription);
    });
    return OrderDescriptionList;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    // if (this._Action._Add) {
    this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
      this.GoToCreate(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
    // }
  }
}


