import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { DbsyncService } from 'src/app/core/Services/online-offline/dbsync.service';
import { vBranchSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-dbsync',
  templateUrl: './dbsync.component.html',
  styleUrls: ['./dbsync.component.css']
})
export class DbsyncComponent implements OnInit {
  DBsyncListGrid: any[];
  viewBy: [];
  viewFilter: Metadatum[] = [];
  _DBSyncForm: FormGroup;
  _Branches: Branch;
  _PageOnloadObj: any;
  _DBSyncObj: any;
  _BackUpObj: any;
  _IsProgressSpinner: boolean = true;
  Backups: any[] = [];
  selectedBackup: any;
  displayBackup: boolean;
  _submitted = false;
  constructor(private fb: FormBuilder, private utility: CommonUtilities, private _DBSyncService: DbsyncService, private _CustomExceptionService: CustomExceptionService) { }
  InitializeDBSyncForm() {
    this._DBSyncForm = this.fb.group({
      fromdate: [],
      todate: [0],
      branchid: ['',vBranchSelect],
      status: []
    })
    this._DBSyncForm.get("fromdate").setValue(new Date());
    this._DBSyncForm.get("todate").setValue(new Date());
    this._DBSyncForm.get("branchid").setValue(parseInt(sessionStorage["currentbranchid"]));
    this._DBSyncForm.get("status").setValue("");
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeDBSyncForm();
    this._PageOnloadObj = {};
    this._IsProgressSpinner = true;
    this._DBSyncService.PageOnload(this._PageOnloadObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Branches = resultJSON.branchlist;
      let viewstatus = new Metadatum();
      viewstatus.metasubcode = "";
      viewstatus.metasubdescription = "All";
      this.viewFilter.push(viewstatus);
      for(let i=0;i<resultJSON.statusList.length;i++){
        this.viewFilter.push(resultJSON.statusList[i]);
      }
      this._DBSyncForm.get("status").setValue("");
      this._IsProgressSpinner = false;
      this.OnSearch();
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  OnSearch() {
    this._submitted = true;
    if( this._DBSyncForm.valid){
      var _fromdate = this._DBSyncForm.get("fromdate").value;
      var _todate = this._DBSyncForm.get("todate").value;
      var branchid = this._DBSyncForm.get("branchid").value;
      var status = this._DBSyncForm.get("status").value || "";
      var datePipe = new DatePipe("en-US");
      var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
      var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
      if (fromdate > todate) {
        this._CustomExceptionService.handleError("From Date should be less than the To Date");
        return;
      }
      this._IsProgressSpinner = true;
      this._DBSyncObj = { "fromDate": fromdate, "toDate": todate, "status": status || "", "branchid": branchid || 0 };
      this._DBSyncService.OnSearch(this._DBSyncObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this.DBsyncListGrid = resultJSON.generatebackupviewlist;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  Backupdetails(backup) {
    var generatebackupid = backup.generateBackupid;
    this._IsProgressSpinner = true;
    this._BackUpObj = { "generatebackupid": generatebackupid || 0 };
    this._DBSyncService.Backupdetails(this._BackUpObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this.Backups = resultJSON.generateBackupdetailviewlist
      this.displayBackup=true;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
