<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Accounts Group Wise Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_rptaccountgroupwiseform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                            yearRange="2021:2030" dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate">
                                        </p-calendar>
                                        <label for="fromdate">From Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                            yearRange="2021:2030" dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate">
                                        </p-calendar>
                                        <label for="todate">To Date</label>
                                    </span>
                                </td>


                                <td>
                                    <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_currencylist" optionValue="currencyid"
                                     optionLabel="currencyname" formControlName="currency" [filter]="true" filterBy="currencyname" [showClear]="true">
                                    </p-dropdown>
                                    <label for="currency">Currency</label>
                                </span>
                                </td>
                                <td style="width: 20%;"></td>
                                <td style="width: 20%;"></td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" icon="las la-search" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                    </form>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[50,100,200,400]" [globalFilterFields]="['Parent Group', 'Account Group', 'Ledger Name', 'Opening Balance', 'Credit Amount', 'Debit Amount', 'Closing Balance']"
                            [rowHover]="true" dataKey="Parent Group" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="colgroup" let-columns>
                                <colgroup id="colgroup"></colgroup>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of cols" style="width: 100px; text-align: center;" pSortableColumn="{{col}}">
                                        {{col}}
                                        <p-sortIcon field="{{col}}"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-col="columns">
                                <tr>
                                    <ng-container *ngFor="let col of cols">
                                        <td [style.text-align]="col === 'Parent Group' || col === 'Account Group' || col === 'Ledger Name' ? 'left' : 'right'" style="width: 100px;">
                                            {{rowData[col]}}
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>