import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { ServiceInvoiceService } from 'src/app/core/Services/workshop/service-invoice.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-service-invoice',
  templateUrl: './v-service-invoice.component.html',
  styleUrls: ['./v-service-invoice.component.css']
})
export class VServiceInvoiceComponent implements OnInit {
  _Action: IAction;
  _serviceinvoiceviews: any[] = []
  _IsProgressSpinner: boolean = true;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _branchid: number;
  cols: any[];
  _roleid = sessionStorage["currentRole"]
  _userid = sessionStorage["userid"];
  mindate: Date;
  maxdate: Date;
  constructor(
    private fb: FormBuilder,
    private _CustomExceptionService: CustomExceptionService,
    private _serviceproductdeliveryService: ServiceInvoiceService,
    private router: Router,
    private utility: CommonUtilities,
    private confirmationService: ConfirmationService,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this.fromdate = new Date();
    this.todate = new Date();
    this._Action = this._AccessRightsService.getAccessRights(333);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.SetFromtoDate();
    this.utility.pageLoadScript();
    this.HotKeyIntegration();
    this._IsProgressSpinner = false;
    this.cols = [
      { field: 'serviceinvoiceno', header: 'Service Invoice Code' },
      { field: 'serviceinvoicedate', header: 'Service Invoice Date' },
      { field: 'customername', header: 'Customer Name' },
      { field: 'servicerequestno', header: 'Service Request No' },
      { field: 'serviceworkorderno', header: 'Service Work Order Code' },
      { field: 'netamount', header: 'Net Amount' },
      { field: 'statusname', header: ' Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this.Fetchall();
  }
  SetFromtoDate(){
    if(sessionStorage['Environmentenddate'] == 'null'){
      this.mindate = new Date();
      this.maxdate = new Date();
      this.fromdate = new Date();
    }
    else{
      this.fromdate = new Date(sessionStorage['Environmentenddate'])
			this.todate = new Date(sessionStorage['Environmentenddate'])
    }
    this.fromdate.setDate(this.fromdate.getDate() - 1);
  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    let data = {
      "fromdate": fromdate,
      "todate": todate,
      "branchid": this._branchid
    }
    this._IsProgressSpinner = true;
    this._serviceproductdeliveryService.FetchAll(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._serviceinvoiceviews = resultJSON.serviceInvoiceView;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/CServiceInvoice'], { state: { serviceinvoiceid: event.serviceinvoiceno, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/CServiceInvoice']);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let purchaseorderList = this.GetPurchaseorderList();
    this.exportUtility.exportExcel(purchaseorderList, 'Service_invoice', 'xlsx');
  }
  ExportToPdf() {
    let purchaseorderList = this.GetPurchaseorderList();
    this.exportUtility.ExportToPdf(purchaseorderList, 'Service_invoice.pdf');
  }
  GetPurchaseorderList() {
    let purchaseorderList = [];
    this._serviceinvoiceviews.forEach(element => {
      let purchaseorder: any = {};
      purchaseorder["Service Invoice Code"] = element.serviceinvoiceno;
      purchaseorder["Service Invoice Date"] = this.datepipe.transform(element.serviceinvoicedate, 'dd/MM/yyyy');
      purchaseorder["Customer Name"] = element.customername;
      purchaseorder["Service Request No"] = element.servicerequestno;
      purchaseorder["Service Work Order Code"] = element.serviceworkorderno;
      purchaseorder["Net Amount"] = element.netamount;
      purchaseorder["Status"] = element.statusname;
      purchaseorder["Created By"] = element.createdbyname;
      purchaseorder["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      purchaseorderList.push(purchaseorder);
    });
    return purchaseorderList;
  }
  Print(event) {
    this._IsProgressSpinner = true;
    this._serviceproductdeliveryService.Print({ "sino": event.serviceinvoiceno, "branchid": this._branchid }).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}