import { SafeUrl } from "@angular/platform-browser";

export class  Messageattachment {
    msgattachmentid: number;
    msgid: number;
    attachmentname: string;
    attachmenturl: string;
    recordstatus: string;
    createdby: number ;
    createdon: Date ;
    modifiedby: number ;
    modifiedon: Date ;
    attachment:SafeUrl;
}