import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Currency } from 'src/app/core/Models/Currency';
import { productsCattegory } from 'src/app/core/Models/overallclass';
import { Producttype } from 'src/app/core/Models/Producttype';
import { ProductpriceService } from 'src/app/core/Services/reports/ProductPriceReport/productprice.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import {
  vProductTypeMultiNameSelect,
  vProductCategoryMultiNameSelect,
  vProductMultiNameSelect,
  vCurrencySelect,
  vLevelNameSelect,
} from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Customer } from 'src/app/core/Models/Customer';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-productpricereport',
  templateUrl: './productpricereport.component.html',
  styleUrls: ['./productpricereport.component.css'],
})
export class ProductpricereportComponent implements OnInit {
  _producttype: Producttype[];
  _Customer: Customer[];
  _productcategory: productsCattegory[];
  _productname: any[];
  _brandlist: any[];
  _productprices: any[];
  _productpriceform: FormGroup;
  ProductpriceObj: any[];
  _currencylist: Currency[];
  _IsProgressSpinner: boolean = false;
  selectedrows: any;
  _submitted = false;
  cols: any[];
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _ProductPriceObj: any;
  _markuplevels: any[];
  customertype: any[];
  _src: SafeResourceUrl;
  _selectedproducttypename: number[] = [];
  vattype: any[];
  _Action: IAction;
  _Tempbrandlist: any[];
  _ProductObj: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private sanitizer: DomSanitizer,
    private _ProductPriceService: ProductpriceService,
    private _CustomExceptionService: CustomExceptionService,
    private exportUtility: ExportUtility
  ) {}
  InitializeProductPriceForm() {
    this._productpriceform = this.fb.group({
      producttypeid: [],
      productcategoryid: [],
      productid: [],
      brandid: [''],
      vattype: [],
      currencyid: ['', vCurrencySelect],
      customerid: ['', vCurrencySelect],
      custometypeid: [2, vCurrencySelect],
      branchid: [sessionStorage['currentbranchid']],
      levelid: ['', vLevelNameSelect],
    });
    this.customertypeid();
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(166);
    this.utility.pageLoadScript();
    this.InitializeProductPriceForm();
    this.PageOnLoad();
    this.cols = [
      { field: 'productname', header: 'Product Name' },
      { field: 'purchaserate', header: 'Purchase Rate' },
      { field: 'retail1m', header: 'Retail 1 Markup %' },
      { field: 'retail1i', header: 'Retail 1 Include' },
      { field: 'retail1e', header: 'Retail 1 Exclude' },
      { field: 'retail2m', header: 'Retail 2 Markup %' },
      { field: 'retail2i', header: 'Retail 2 Include' },
      { field: 'retail2e', header: 'Retail 2 Exclude' },
      { field: 'onlinem', header: 'Online Markup %' },
      { field: 'onlinei', header: 'Online Include' },
      { field: 'onlinee', header: 'Online Exclude' },
      { field: 'wholsalem', header: 'WholeSale Markup %' },
      { field: 'wholsalei', header: 'WholeSale Include' },
      { field: 'wholsalee', header: 'WholeSale Exclude' },
      { field: 'specialm', header: 'Special Markup %' },
      { field: 'speciali', header: 'Special Include' },
      { field: 'speciale', header: 'Special Exclude' },
      { field: 'projectm', header: 'Project Markup%' },
      { field: 'projecti', header: 'Project Include' },
      { field: 'projecte', header: 'Project Exclude' },
    ];
  }

  PageOnLoad() {
    this._IsProgressSpinner = true;
    this._ProductPriceService.PageOnLoad({}).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._producttype = resultJSON.productTypeList;
        this._currencylist = resultJSON.currencies;
        this._markuplevels = resultJSON.levels;
        this._brandlist = resultJSON.brands;
        this._Tempbrandlist = resultJSON.brands;
        this.customertypeid();
        this.customertype = [
          { id: 1, name: 'Quick Customer' },
          { id: 2, name: 'Normal Customer' },
          { id: 3, name: 'Online Customer' },
          { id: 4, name: 'Credit Customer' },
          { id: 5, name: 'Default Customer' },
          { id: 6, name: 'All' },
        ];
        this.vattype = [
          { id: 1, name: 'Include' },
          { id: 2, name: 'Exclude' },
        ];
        this._productpriceform.get('vattype').setValue(1);
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  Customer() {
    let customerid = this._productpriceform.get('customerid').value;
    let levelid = this._Customer.filter((f) => f.customerid == customerid)[0]
      .levelid;
    let currencyid = this._Customer.filter((f) => f.customerid == customerid)[0]
      .currencyid;
    this._productpriceform.get('currencyid').setValue(currencyid);
    this._productpriceform.get('levelid').setValue(levelid);
  }

  customertypeid() {
    this._productpriceform.controls['levelid'].reset();
    this._productpriceform.controls['currencyid'].reset();
    let custype = {
      Customertype: this._productpriceform.get('custometypeid').value || 0,
    };
    this._IsProgressSpinner = true;
    this._ProductPriceService.GetCustomer(custype).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Customer = resultJSON.customerlist;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  getProductcategory(event) {
    let value2: any[] = [];
    for (let i = 0; i < this._selectedproducttypename.length; i++) {
      let value1 = this._producttype.filter(
        (f) => f.producttypeid == event.value[i]
      )[0].producttypeid;
      value2.push(value1);
    }
    this._selectedproducttypename = value2;
    var branchid = sessionStorage['currentbranchid'];
    if (
      this._selectedproducttypename.length == 0 ||
      this._selectedproducttypename == null
    ) {
      this._productpriceform.controls['productcategoryid'].reset();
      this._productpriceform.controls['productid'].reset();
      this._productpriceform.controls['brandid'].reset();
      this._productcategory = [];
      return;
    }
    this._productpriceform.controls['productcategoryid'].reset();
    this._productpriceform.controls['productid'].reset();
    this._productpriceform.controls['brandid'].reset();
    this._productcategory = [];
    this._productname = [];
    this._brandlist = [];
    this._brandlist = this._Tempbrandlist;
    this._IsProgressSpinner = true;
    this._ProductTypeObj = {
      Producttype: this._selectedproducttypename || [],
      branchid: branchid || 0,
    };
    this._ProductPriceService
      .getProductCategories(this._ProductTypeObj)
      .subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._productcategory = resultJSON.productCategoryList;
          this._IsProgressSpinner = false;
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
        }
      );
  }

  getProductname(event) {
    var branchid = sessionStorage['currentbranchid'];
    var selectedProductCategory = event.value;
    if (
      selectedProductCategory.length == 0 ||
      selectedProductCategory == null
    ) {
      this._productname = [];
      return;
    }
    this._IsProgressSpinner = true;
    this._ProductCategoryObj = {
      category: selectedProductCategory || [],
      branchid: branchid,
    };
    this._ProductPriceService.getProducts(this._ProductCategoryObj).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productname = resultJSON.productnames;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  GenerateReport() {
    this._submitted = true;
    if (!this._productpriceform.valid) return;
    let currencyid = this._productpriceform.get('currencyid').value;
    let customerid = this._productpriceform.get('customerid').value;
    let Levelid = this._productpriceform.get('levelid').value;
    let productid = this._productpriceform.get('productid').value;
    let producttypeid = this._selectedproducttypename || [];
    let productcateggoryid =
      this._productpriceform.get('productcategoryid').value;
    let brand = this._productpriceform.get('brandid').value;
    let vattype = this._productpriceform.get('vattype').value;
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this._ProductPriceObj = {
      productid: productid || [],
      productcategoryid: productcateggoryid || [],
      producttypeid: producttypeid || [],
      Currencyid: currencyid,
      Customerid: customerid,
      Brandid: brand || [],
      Vattype: vattype,
      Levelid: Levelid,
      branchid: parseInt(sessionStorage['currentbranchid']),
      userid: parseInt(sessionStorage['userid']),
    };
    this._ProductPriceService.Print(this._ProductPriceObj).subscribe(
      (result) => {
        var blob = new Blob([result], { type: mediaType });
        var url = window.URL.createObjectURL(blob);
        this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  clear() {
    this.InitializeProductPriceForm();
    this._productcategory = [] = [];
    this._productname = [] = [];
    this._productprices = [] = [];
    this._selectedproducttypename = [];
    this._productpriceform.get('vattype').setValue(1);
    this._submitted = false;
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.PageOnLoad();
  }

  exportExcel() {
    let ProductPriceReportList = this.GetProductPriceList();
    this.exportUtility.exportExcel(
      ProductPriceReportList,
      'ProductPriceReport',
      'xlsx'
    );
  }

  ExportToPdf() {
    let base64Img: string = 'assets/images/logo.png';
    let levelid = this._productpriceform.get('levelid').value;
    let currencyid = this._productpriceform.get('currencyid').value;
    let currencyname = this._currencylist.filter(
      (f) => f.currencyid == currencyid
    )[0].currencyname;
    if (!this._productprices || !this._productprices.length) return;
    let ExpenseList = this.GetProductPriceList();
    const data = ExpenseList.map(Object.values);
    let head = [];

    if (levelid == 1) {
      head = [['Product Name', 'Include Vat Price', 'Exclude Vat Price']];
    }
    if (levelid == 2) {
      head = [['Product Name', 'Include Vat Price', 'Exclude Vat Price']];
    }
    if (levelid == 3) {
      head = [['Product Name', 'Include Vat Price', 'Exclude Vat Price']];
    }
    if (levelid == 4) {
      head = [['Product Name', 'Include Vat Price', 'Exclude Vat Price']];
    }
    if (levelid == 5) {
      head = [['Product Name', 'Include Vat Price', 'Exclude Vat Price']];
    }
    if (levelid == 6) {
      head = [['Product Name', 'Include Vat Price', 'Exclude Vat Price']];
    }

    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        const doc = new jsPDF.default('l', 'mm', 'a4');

        autoTable(doc, {
          didDrawPage: function (data) {
            // Header
            doc.setFontSize(12);
            doc.setTextColor(40);
            if (base64Img) {
              doc.addImage(
                base64Img,
                'PNG',
                data.settings.margin.left,
                15,
                30,
                10
              );
            }
            doc.text(
              'Currency: ' + currencyname,
              data.settings.margin.left + 220,
              22
            );
            // // Footer
            // const totalPagesExp = '{total_pages_count_string}';
            // const footer = function (footerData) {
            //   doc.setFontSize(10);
            //   let str = 'Page ' + footerData.pageCount;
            //   // Total page number plugin only available in jspdf v1.0+
            //   if (typeof doc.putTotalPages === 'function') {
            //     str = str + ' of ' + totalPagesExp;
            //     console.log('test');
            //   }
            //   doc.text(str, footerData.settings.margin.left, doc.internal.pageSize.height - 10);
            //   doc.text('https://wwww.example.com', footerData.settings.margin.left + 400, doc.internal.pageSize.height - 10);
            // };
            // // Total page number plugin only available in jspdf v1.0+
            // if (typeof doc.putTotalPages === 'function') {
            //   doc.putTotalPages(totalPagesExp);
            // }
            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            // var pageSize = doc.internal.pageSize;
            // var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            // doc.text(str, data.settings.margin.left, pageHeight - 10);
          },
          margin: { top: 30 },
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0,
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0,
          },
          columnStyles: { 2: { halign: 'right' }, 1: { halign: 'right' } },

          didDrawCell: (data) => {},
        });

        doc.save('ProductPriceReport.pdf');
      });
    });
  }

  GetProductPriceList() {
    let levelid = this._productpriceform.get('levelid').value;
    if (!this._productprices || !this._productprices.length) return;
    let ProductPriceList = [];
    this._productprices.forEach((element) => {
      let productprice: any = {};
      if (levelid == 1) {
        productprice['Product Name'] = element.productname;
        productprice['Include Vat Price'] = element.retail1i;
        productprice['Exclude Vat Price'] = element.retail1e;
      }
      if (levelid == 2) {
        productprice['Product Name'] = element.productname;
        productprice['Include Vat Price'] = element.retail2i;
        productprice['Exclude Vat Price'] = element.retail2e;
      }
      if (levelid == 3) {
        productprice['Product Name'] = element.productname;
        productprice['Include Vat Price'] = element.onlinei;
        productprice['Exclude Vat Price'] = element.onlinee;
      }
      if (levelid == 4) {
        productprice['Product Name'] = element.productname;
        productprice['Include Vat Price'] = element.wholsalei;
        productprice['Exclude Vat Price'] = element.wholsalee;
      }
      if (levelid == 5) {
        productprice['Product Name'] = element.productname;
        productprice['Include Vat Price'] = element.speciali;
        productprice['Exclude Vat Price'] = element.speciale;
      }
      if (levelid == 6) {
        productprice['Product Name'] = element.productname;
        productprice['Include Vat Price'] = element.projecti;
        productprice['Exclude Vat Price'] = element.projecte;
      }
      ProductPriceList.push(productprice);
    });
    return ProductPriceList;
  }

  ChangeProduct(event) {
    var selectedProduct = event.value;
    this._brandlist = [];
    var branchid =
      this._productpriceform.get('branchid').value == null
        ? 0
        : this._productpriceform.get('branchid').value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._brandlist = this._Tempbrandlist;
      return;
    } else {
      this._IsProgressSpinner = true;
      this._ProductObj = {
        Products: selectedProduct || [],
        branchid: parseInt(sessionStorage['currentbranchid']),
      };
      this._ProductPriceService.getBrand(this._ProductObj).subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          let productbrand = resultJSON.brandlists;
          let uniqueChars = [...new Set(productbrand)];
          uniqueChars = productbrand.filter(
            (test, index, array) =>
              index ===
              array.findIndex((findTest) => findTest.brandid === test.brandid)
          );
          this._brandlist = uniqueChars;

          this._IsProgressSpinner = false;
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
        }
      );
    }
  }
}
