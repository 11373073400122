import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class DocumentdescriptionService {
  public _Action: IAction;
  public _DocumentDescriptionService = environment.DocumentDescription;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient, private _CustomExceptionService: CustomExceptionService) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
    createHeaders(token: any): HttpHeaders {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include your token here	
      });
    }

  PageOnLoad(p_descriptionid: number): Observable<any> {
    const headers = this.header;
    var Query = this._DocumentDescriptionService + '/' + p_descriptionid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll() {
    const headers = this.header;
    var Query = this._DocumentDescriptionService + '/FetchAll';
   
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      return data;
    }));
  }
  create(p_documentdescription: object) {
    const headers = this.header;
    //console.log("Create");
    var Query = this._DocumentDescriptionService;
    return this.http.post<{ data: any }>(Query, p_documentdescription, { headers });
  }
 
  edit(p_documentdescription: object) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._DocumentDescriptionService;
   
    return this.http.put<{ data: any }>(Query, p_documentdescription, { headers });
  }

}
