import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class UsercustomermappingService {
  public _Action: IAction;
  public _UsercustomermappingService = environment.UserCustomerMappingService;
  public _httpOptions;
  token: any;
  header: any;


  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(userid: number): Observable<any> { 
    const headers = this.header;
    var Query = this._UsercustomermappingService + "/" + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  create(p_Usercustomernmapping: object) { 
    const headers = this.header;
    console.log("Create");
    var Query = this._UsercustomermappingService;

    return this.http.post<{ data: any }>(Query, p_Usercustomernmapping, { headers });
  }
  getUserMappedCustomer(userid: number) { 
    const headers = this.header;
    var Query = this._UsercustomermappingService + '/getUserMappedCustomer' + '/' + userid;

    console.log("functions");
    return this.http.get<{ data: any }>(Query, { headers });
  }

  view(p_usercustomermappingid: number) { 
    const headers = this.header;
    var Query = this._UsercustomermappingService + '/' + p_usercustomermappingid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
}
