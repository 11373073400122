<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Damaged Product Stock </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" type="submit" title="Save" icon="pi pi-save" type="button"
                                (click)="onSave()"></button>
                            <button pButton pRipple label="" title="Clear" type="button" icon="pi pi-trash"
                                (click)="reset()" class="p-button-danger"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_damagedproductdetailform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_branches" optionValue="branchid"
                                        optionLabel="branchname" formControlName="branchid">
                                    </p-dropdown>
                                    <label for="productid">Branch Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_damagedproductdetailform.get('branchid').touched || _submitted) && _damagedproductdetailform.get('branchid').errors?.BranchNameRequired">
                                    Please Select Branch Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_damagedproductform">
                                    <p-multiSelect [options]="_producttype" optionLabel="producttypename"
                                        optionValue="producttypeid" (onChange)="getProductCategory($event)"
                                        formControlName="producttypeid" defaultLabel="Product Type">
                                    </p-multiSelect>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_damagedproductform">
                                    <p-multiSelect [options]="_productcategory" defaultLabel="Product Category"
                                        optionLabel="categoryname" (onChange)="getProduct($event)"
                                        [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}"
                                        optionValue="productcategoryid">
                                    </p-multiSelect>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-autoComplete formControlName="productid" [suggestions]="filteredProducts"
                                        (completeMethod)="filterProducts($event)" field="productname"
                                        (onSelect)="GetGRN($event)">
                                        <ng-template let-product pTemplate="item">
                                            <div>{{product.productname}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_damagedproductdetailform.get('productid').touched || _submitted) && _damagedproductdetailform.get('productid').errors?.ProductNameRequired">
                                    Please Select Product
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_GRNno" optionValue="grnno"
                                        (onChange)="onChangeGrnno()" optionLabel="grnno" formControlName="grnno">
                                    </p-dropdown>
                                    <label for="productid">GRN No<span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="LotNo">
                                    <label>Lot No</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="DamagedQty">
                                    <label>Damaged Qty</label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()"
                                    class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_damagedproductdetails" [rows]="15" [paginator]="true"
                        [rowsPerPageOptions]="[25,50,100]"
                        [globalFilterFields]="['productname','variantname','severityname','oldstock','uomname','damagedstock','remarks']"
                        [rowHover]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Product Name</th>
                                <th>Variant</th>
                                <th>Lot Number</th>
                                <th>Severity</th>
                                <th>Available Stock</th>
                                <th>UOM</th>
                                <th>Additional Stock</th>
                                <th>Remarks</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_product let-ri="rowIndex">
                            <tr>
                                <td>
                                    {{_product.productname}}
                                </td>
                                <td>
                                    {{_product.variantname}}
                                </td>
                                <td>
                                    {{_product.lotnumber}}
                                </td>
                                <td>
                                    {{_product.severityname}}
                                </td>
                                <td>
                                    {{_product.oldstock}}
                                </td>
                                <td>
                                    {{_product.uomname}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_product.tempDamagedstock" pInputText>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_product.tempDamagedstock}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" [(ngModel)]="_product.remarks" pInputText>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_product.remarks}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>