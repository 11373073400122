import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../../core/Services/users/login.service';
import { usererrors } from '../../../core/errors/usererrors';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { Userrolemappedfunctionsview } from '../../Views/Userrolemappedfunctionsview';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../Services/authentication/authentication.service';
import { HttpClient  } from '@angular/common/http';
//import { IpifyService } from 'ipify';

declare var $: any;
//import { Observable} from 'rxjs'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  // @Output() loginVisible = new EventEmitter();
  @Output() EnableMenus: EventEmitter<any> = new EventEmitter();
  loginform: FormGroup;
  submitted = false;
  SessionPopup = false;
  _IsProgressSpinner: boolean = false;
  ipAddress : any = "1";

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private pLoginService: LoginService,
    private _CustomExceptionService: CustomExceptionService,
    private AuthenticationService: AuthenticationService,
    private location: Location,
    private http:HttpClient,
    //private ipifyService: IpifyService
  ) {
    if (localStorage.getItem('Istimeout') == 'true') {
      this.SessionPopup = true;
      localStorage.clear();
    }
  }
  ngOnInit() {
    this.loginform = this.fb.group({
      username: ['', Validators.required],
      password: ['', [Validators.required]],
    });
    let path = window.location.pathname;
    if (sessionStorage !== null && path && path !== '/') {
      let state = history.state;
      var screenlist = JSON.parse(sessionStorage.getItem('Functions'));
      var vScreenList: Userrolemappedfunctionsview[] = screenlist;
      var obj = vScreenList.find((w) => w.path.indexOf(path) != -1);
      if (obj) {
        this.router.navigate([path], { state: state });
        this.EnableMenus.emit(true);
      } else {
        // path='/accessdenied';
        this.router.navigate([path]);
        this.EnableMenus.emit(true);
      }
    } else {
      sessionStorage.clear();
      this.EnableMenus.emit(false);
    }
    //this.getIpAddress();
  }
  getIpAddress()
  {
    this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.loginform.valid) {
      var lUsername = this.loginform.get('username').value;
      var lPassword = this.loginform.get('password').value;
      this._IsProgressSpinner = true;
      this.pLoginService.validate(lUsername, lPassword,this.ipAddress).subscribe(
        (result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (
            resutJSON.tranStatus.result == true &&
            (resutJSON != null || resutJSON != undefined)
          ) {
            this._CustomExceptionService.handleSuccess(
              usererrors.logged_Success_00
            );
            sessionStorage['AuthToken'] = resutJSON.authToken;
            sessionStorage['SessionID'] = resutJSON.sessionID;
            sessionStorage['userid'] = resutJSON.loggedUser.userid;
            sessionStorage['employeeid'] = resutJSON.loggedUser.employeeid;
            sessionStorage['BranchID'] = resutJSON.loggedBranch.branchid;
            sessionStorage['Series'] = resutJSON.loggedUser.series;
            sessionStorage['username'] = resutJSON.loggedUser.username;
            sessionStorage['psw'] = this.loginform.get('password').value;
            sessionStorage['userdiscountpercentage'] =
              resutJSON.loggedUser.userdiscountpercentage;
            sessionStorage['branchname'] = resutJSON.loggedBranch.branchname;
            sessionStorage['currentbranchid'] = resutJSON.loggedBranch.branchid;
            sessionStorage['currentbranchname'] =
              resutJSON.loggedBranch.branchname;
            sessionStorage['companyid'] = resutJSON.loggedBranch.companyid;
            sessionStorage['userlogopath'] = '';
            sessionStorage['AssociateUser'] =
              resutJSON.loggedUser.associateuser;
            if (resutJSON.employee && resutJSON.employee.employeeimageurl)
              sessionStorage['userlogopath'] =
                resutJSON.employee.employeeimageurl;
            this._IsProgressSpinner = false;
            var objDefault: any[] = resutJSON.loggedUser.userrolemaps;
            var obj = objDefault.find((role) => (role.isdefault = true));
            sessionStorage['defaultRole'] = resutJSON.loggedRole.roleid;
            sessionStorage['currentRole'] = resutJSON.loggedRole.roleid;
            sessionStorage['defaultRoleName'] = resutJSON.loggedRole.rolename;
            sessionStorage['currentRoleName'] = resutJSON.loggedRole.rolename;
            sessionStorage['levelid'] = obj.levelid;
            sessionStorage['Functions'] = JSON.stringify(
              resutJSON.userrolemappedfunctionsviews
            );
            sessionStorage['userbranches'] = JSON.stringify(
              resutJSON.userbranchmapviewList
            );
            sessionStorage['userroles'] = JSON.stringify(
              resutJSON.userrolemapviewList
            );
            sessionStorage['passwordlength'] =
              resutJSON.loggedUser.password.length;
            if (resutJSON.balancecount) {
              var screenWidth = $(window).width();
              var screenHeight = $(window).height();
              var popupWidth = 1000;
              var popupHeight = 500;
              var leftPosition = (screenWidth - popupWidth) / 2;
              var topPosition = (screenHeight - popupHeight) / 2;
              var myWindow = window.open(
                '',
                '',
                'toolbar=yes,scrollbars=yes,resizable=yes,top=' +
                topPosition +
                ',left=' +
                leftPosition +
                ',width=' +
                popupWidth +
                ',height=' +
                popupHeight
              );
              myWindow.document.write(resutJSON.balancecount);
            }
            sessionStorage['CurrentEnvironmentData'] = JSON.stringify(
              resutJSON.posenvironment
            );
            sessionStorage['Environmentstartdate'] = resutJSON.posenvironment.startdate;
            sessionStorage['Environmentenddate'] = resutJSON.posenvironment.closingdate;
            // if (resutJSON.cashbooknegative) {
            //   var screenWidth = $(window).width();
            //   var screenHeight = $(window).height();
            //   var popupWidth = 1000;
            //   var popupHeight = 500;
            //   var leftPosition = (screenWidth - popupWidth) / 2;
            //   var topPosition = (screenHeight - popupHeight) / 2;
            //   var myWindow = window.open(
            //     '',
            //     '',
            //     'toolbar=yes,scrollbars=yes,resizable=yes,top=' +
            //     topPosition +
            //     ',left=' +
            //     leftPosition +
            //     ',width=' +
            //     popupWidth +
            //     ',height=' +
            //     popupHeight
            //   );
            //   myWindow.document.write(resutJSON.cashbooknegative);
            // }
            this.EnableMenus.emit(true);
            this.SelectDefaultScreen(resutJSON);
            localStorage.setItem('userid', resutJSON.loggedUser.userid);
          } else {
            this._CustomExceptionService.handleError(
              resutJSON.tranStatus.lstErrorItem[0].message
            );
          }
          this._IsProgressSpinner = false;
        },
        (error) => {
          this._CustomExceptionService.handleError('Server Not Found');
          this._IsProgressSpinner = false;
        }
      );
    }
  }
  SelectDefaultScreen(resutJSON: any) {
    var selectedRole = sessionStorage['currentRole'];
    let screens = (
      <Userrolemappedfunctionsview[]>JSON.parse(sessionStorage['Functions']) ||
      []
    ).filter((f) => f.roleid == selectedRole);
    let path = '/';
    if (screens && screens.length) {
      screens = screens.sort(
        (a, b) =>
          // your sort logic
          a.moduleid - b.moduleid // example : order by id
      );
      path = screens[0].path;
    }
    this.router.navigate([path]);
  }
  popupClose() {
    localStorage.setItem('Istimeout', 'false');
    this.SessionPopup = false;
  }
}
