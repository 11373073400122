import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Jobcard } from 'src/app/core/Models/jobCard';
import { JobCardAdditionalChargeDetail } from 'src/app/core/Models/JobCardAdditionalChargeDetail';
import { Jobcardchecklistdetail } from 'src/app/core/Models/jobcardchecklistdetail';
import { Jobcarddetail } from 'src/app/core/Models/jobcarddetail';
import { JobCardOtherChargeDetail } from 'src/app/core/Models/Jobcardotherchargedetail';
import { JobcardService } from 'src/app/core/Services/workshop/jobcard.service';
import { vPaymentDateSelect, vSelectAccountgroup } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
@Component({
	selector: 'app-c-job-card',
	templateUrl: './c-job-card.component.html',
	styleUrls: ['./c-job-card.component.css']
})
export class CJobCardComponent implements OnInit {
	_action: string = "create";
	_submitted = false;
	_IsProgressSpinner: boolean = true;
	_IsSaveDisable: boolean = false;
	_IsclearDisable: boolean = false;
	_jobcardform: FormGroup;
	_servicerequestno: any
	_jobcarddetails: any[]
	isDisabledsendapparoval: boolean = true
	_status: any
	_serviceat: any
	_employee: any
	_tempemployee: any
	_tempsupplier: any
	_supplier: any
	_checklist: any
	_selectedchecklist: any
	Selectedchecklist: any[] = []
	_currency: any
	_payby: any
	vattype: any
	_othercharges: any
	mobileno: number
	mailid: any
	Address: any
	jobcardOBJ: Jobcard
	isexternal: boolean = false
	_OtherCharges: any[] = []
	selectedOtherChargesRows: any[] = []
	_Descriptions: any[] = []
	_AdditionalCharges: any[] = []
	_AddDescriptions: any[] = []
	isDisabledother: boolean = false
	isDisabledremove: boolean = false
	sevicecostpopup: boolean = false
	totalcost: any;
	_SparesAndConsumables: any[] = [];
	SparesAndConsumablesRows: any[] = [];
	HideSpc: boolean = true;
	mindate: Date;
	maxdate: Date;
	constructor(
		private fb: FormBuilder,
		private _CustomExceptionService: CustomExceptionService,
		private _jobcardService: JobcardService,
		private _router: Router,
		private utility: CommonUtilities
	) { }
	InitializeFormGroup(): void {
		this._jobcardform = this.fb.group({
			jobcardid: [0],
			jobcardno: [],
			jobcarddate: [(new Date()), vPaymentDateSelect],
			branchid: [],
			servicerequestno: ['', vSelectAccountgroup],
			payby: ['', vSelectAccountgroup],
			jobcardtype: ['', vSelectAccountgroup],
			employeeid: [],
			supplierid: [],
			checklistdescription: [],
			remarks: [],
			approximateamountinusd: [],
			vatid: [],
			vatamount: [],
			currencyid: [1, vSelectAccountgroup],
			currencyuniqerefid: [],
			ocid: [],
			ocvalue: [],
			advalue: [],
			netamount: [],
			status: ['MSC00314', vSelectAccountgroup],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			jobcardchecklistdetails: [],
			jobcarddetails: [],
			jobcardotherchargedetails: [],
			jobcardadditionalchargedetails: []
		});
		if(sessionStorage['Environmentenddate'] == 'null'){
			this._jobcardform.get('jobcarddate').setValue(new Date())
		}
		else{
			let date = new Date(sessionStorage['Environmentenddate'])
			this._jobcardform.get('jobcarddate').setValue(date);
		}
	}
	ngOnInit(): void {
		this.utility.pageLoadScript();
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.InitializeFormGroup();
		this._IsProgressSpinner = false;
		let jobcard = history.state?.jobcardid ?? 0;
		this._action = history.state?.action ?? this._action;
		this._jobcardform.controls["branchid"].setValue(sessionStorage["BranchID"],)
		let obj = {
			"JobCardId": jobcard,
			"branchid": sessionStorage["BranchID"],
		}
		this._IsProgressSpinner = true;
		this._jobcardService.PageOnLoad(obj).subscribe((result: any) => {
			this._IsProgressSpinner = false;
			if (result && result.tranStatus.result) {
				console.log(result, 'objectes');
				this._servicerequestno = result.serviceRequestList;
				this._checklist = result.checkList;
				this._tempemployee = result.employeeList;
				this._tempsupplier = result.supplierList;
				this._othercharges = result.otherChargeList;
				this._currency = result.currencyList;
				this._payby = result.payByList;
				this._serviceat = result.jobCardTypesList;
				this.vattype = result.taxList;
				this._status = result.statusList;
				this._Descriptions = result.otherChargeList;
				this._AddDescriptions = result.serviceCoustList;
				this._jobcardform.get('ocvalue').disable();
				this._jobcardform.get('advalue').disable();
				var l_othercharge = new JobCardOtherChargeDetail();
				l_othercharge.jobcardotherchargesdetailid = 0;
				l_othercharge.jobcardid = 0;
				l_othercharge.ocvalue = 0;
				this._OtherCharges.push(l_othercharge);
				var A_charges = new JobCardAdditionalChargeDetail();
				A_charges.jobcardadditionalchargedetailid = 0;
				A_charges.jobcardid = 0;
				A_charges.advalue = 0;
				A_charges.adper = 0;
				this._AdditionalCharges.push(A_charges);
				if (this._action == 'create') {
					this.getversion();
				}
				if (this._action == 'edit') {
					this._status.forEach(element => {
						if (element.metasubcode == 'MSC00315')
							element.disabled = true
					});
				}
				if (this._action == 'edit' || this._action == 'view') {
					result.jobCard.jobcarddate = new Date(result.jobCard.jobcarddate);
					this._servicerequestno = result.serviceRequestViewList;
					this._employee = this._tempemployee;
					this._supplier = this._tempsupplier;
					this.isDisabledother = true
					this.isDisabledremove = true
					var list = this._supplier.filter(f => f.supplierid == result.jobCard.supplierid)
					if (list.length != 0) {
						this.mailid = list[0].mailid
						this.mobileno = list[0].mobileno
						this.Address = list[0].address
					}
					this._jobcardform.setValue(result.jobCard);
					this._jobcardform.disable();
					this._jobcardform.controls['status'].enable();
					this._jobcarddetails = result.jobCardDetail;
					this.Selectedchecklist = result.jobCardCheckListDetail;
					this._OtherCharges = result.jobCardOtherChargeDetail;
					this._AdditionalCharges = result.jobCardAdditionalChargeDetail;
					var pncode: string[] = [];
					Object.keys(result.jobCardCheckListDetail).map((Index) => {
						pncode.push(result.jobCardCheckListDetail[Index].checklistcode);
					});
					this._selectedchecklist = pncode;
					this._IsclearDisable = true
					if (result.sparesandconsumables && result.sparesandconsumables.length && result.sparesandconsumables.length > 0) {
						this._SparesAndConsumables = result.sparesandconsumables;
						this.HideSpc = false
					} else {
						this.HideSpc = true
					}
				}
				if (this._action == 'view') {
					this._jobcardform.disable();
					this._IsSaveDisable = true
					this.isDisabledother = true
					this.isDisabledremove = true
				}
			} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); }
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	AddOtherCharges(event) {
		let otherChargesExists = (this._OtherCharges.filter(s => s.ocid > 0).length == this._OtherCharges.length) && this._OtherCharges.some(s => s.ocid > 0);
		if (!otherChargesExists) return;
		var l_othercharge = new JobCardOtherChargeDetail();
		l_othercharge.jobcardotherchargesdetailid = 0;
		l_othercharge.jobcardid = 0;
		l_othercharge.ocvalue = 0;
		this._OtherCharges.push(l_othercharge);
	}
	RemoveOtherCharges(pOthercharges: JobCardOtherChargeDetail) {
		var index = this._OtherCharges.indexOf(pOthercharges);
		this._OtherCharges.splice(index, 1)
		this.netcalculation();
		if (this._OtherCharges.length <= 0) {
			var l_othercharge = new JobCardOtherChargeDetail();
			l_othercharge.jobcardotherchargesdetailid = 0;
			l_othercharge.jobcardid = 0;
			l_othercharge.ocvalue = 0;
			this._OtherCharges.push(l_othercharge);
		}
	}
	OnchangeOtherCharge(pOtherCharges: JobCardOtherChargeDetail, event: any) {
		let isAlreadyExists = this._OtherCharges.filter(s => s.ocid == event.value).length > 1;
		if (isAlreadyExists) {
			this._CustomExceptionService.handleWarning("Other Charges Already Exists!");
			var index = this._OtherCharges.indexOf(pOtherCharges);
			this._OtherCharges.splice(index, 1)
			return;
		}
		let OCValue = (this._Descriptions || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount;
		pOtherCharges.ocvalue = OCValue;
		pOtherCharges.ocid = event.value;
		this.netcalculation()
	}
	OnChangeotherCharge(row: any, event) {
		let OtherChargesAmount = row.ocvalue || 0;
		parseFloat(OtherChargesAmount)
		this.netcalculation()
	}
	onPayBySelect(event) {
		let SRno = this._jobcardform.get("servicerequestno").value
		if (!SRno) {
			this._jobcardform.get("payby").reset()
			this._CustomExceptionService.handleError("Please Select Service Request No");
			return
		}
		let RefType = this._servicerequestno.find(f => f.servicerequestnumber == SRno).reftype
		if (RefType == 387) {
			this._jobcardform.get("payby").reset()
			this._jobcardform.get("payby").setValue(389);
		}
	}
	getversion() {
		var currency = this._jobcardform.get('currencyid').value;
		var version = this._currency.filter(f => f.currencyid == currency)[0].currencyuniquerefid
		this._jobcardform.controls['currencyuniqerefid'].setValue(version)
	}
	GetServicerequestdetail(event) {
		var srno = this._jobcardform.get('servicerequestno').value || event;
		let obj = {
			"ServiceRequestNo": srno,
		}
		this._IsProgressSpinner = true;
		this._jobcardService.GetSRDetail(obj).subscribe((result: any) => {
			this._IsProgressSpinner = false;
			if (result && result.tranStatus.result) {
				console.log("refno", result);
				this._jobcarddetails = result.jobCardView;
			} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); }
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	Getserviceat(event) {
		this._jobcardform.get('supplierid').reset()
		this._jobcardform.get('employeeid').reset()
		this.mailid = null;
		this.mobileno = null;
		this.Address = null;
		var servicetype = this._jobcardform.get('jobcardtype').value || event;
		if (servicetype == 'MSC00312') { // Internal
			this.isexternal = false
			this._jobcardform.get('approximateamountinusd').enable()
			this._jobcardform.get('ocvalue').disable()
			this.clear()
			this._employee = this._tempemployee
			this._jobcardform.get('supplierid').disable()
			this._jobcardform.get('employeeid').enable()
		} else if (servicetype == 'MSC00313') { //External
			this.isexternal = false
			this._jobcardform.get('approximateamountinusd').enable()
			this._jobcardform.get('ocvalue').disable()
			this.clear()
			this._supplier = this._tempsupplier
			this._jobcardform.get('employeeid').disable()
			this._jobcardform.get('supplierid').enable()
		}
	}
	clear() {
		this._jobcardform.get('approximateamountinusd').reset();
		this._jobcardform.get('vatid').reset();
		this._jobcardform.get('vatamount').reset();
		this._jobcardform.get('ocid').reset();
		this._jobcardform.get('ocvalue').reset();
		this._jobcardform.get('netamount').reset();
	}
	getdetails(event) {
		var supplier = this._jobcardform.get('supplierid').value
		var details = this._supplier.filter(f => f.supplierid == supplier)
		this.mailid = details[0].mailid
		this.mobileno = details[0].mobileno
		this.Address = details[0].address
	}
	getchecklist(event) {
		var _selectedprcode: Jobcardchecklistdetail[] = [];
		var _pncodes: Jobcardchecklistdetail;
		Object.keys(event.value).map((Index) => {
			_pncodes = new Jobcardchecklistdetail();
			_pncodes.jobcardchecklistdetailid = 0;
			_pncodes.jobcardid = 0;
			_pncodes.checklistcode = event.value[Index];;
			_selectedprcode.push(_pncodes);
		})
		this.Selectedchecklist = _selectedprcode;
	}
	allocatePaymentAmount() { // amount CAlculation
		this._AdditionalCharges = []
		var A_charges = new JobCardAdditionalChargeDetail();
		A_charges.jobcardadditionalchargedetailid = 0;
		A_charges.jobcardid = 0;
		A_charges.advalue = 0;
		A_charges.adper = 0;
		this._AdditionalCharges.push(A_charges);
		let amount: number = 0.00
		amount = this._jobcardform.get('approximateamountinusd').value
		if (!amount || amount == 0) {
			this._jobcardform.get('approximateamountinusd').setValue(0)
			this._CustomExceptionService.handleWarning(" Please Enter Service Cost")
			return
		}
		var currencyId = this._jobcardform.get('currencyid').value
		if (!currencyId) {
			this._jobcardform.get('approximateamountinusd').setValue(0)
			this._CustomExceptionService.handleWarning("Please Select Currency");
			return
		}
		this.netcalculation();
	}
	getvat() { // vat CAlculation
		this.netcalculation();
	}
	getchargesid() {
		var id = this._jobcardform.get('ocid').value;
		if (!id) {
			return
		}
		this._jobcardform.get('ocvalue').enable();
		var amount = this._othercharges.filter(f => f.otherchargeid == id)[0].otherchargeamount
		this._jobcardform.controls["ocvalue"].setValue(amount);
		this.getothercharges();
	}
	getothercharges() { // vat CAlculation
		this.netcalculation()
	}
	onSave() {
		this._submitted = true;
		if (!this._jobcardform.valid) return;
		this.jobcardOBJ = this._jobcardform.getRawValue();
		var Effectivefrom = this._jobcardform.get("jobcarddate").value;
		var datePipe = new DatePipe("en-US");
		var peffectivefrom = datePipe.transform(Effectivefrom, 'yyyy-MM-dd');
		this.jobcardOBJ.jobcarddate = (new Date(peffectivefrom))
		this.jobcardOBJ.createdon = new Date();
		this.jobcardOBJ.createdby = sessionStorage["userid"];
		this.jobcardOBJ.modifiedon = new Date();
		this.jobcardOBJ.modifiedby = sessionStorage["userid"];
		this.jobcardOBJ.branchid = sessionStorage["BranchID"];
		if (this.jobcardOBJ.jobcardtype == 'MSC00313') { //external
			if (!this.jobcardOBJ.supplierid) {
				this._CustomExceptionService.handleWarning("Please Select Supplier");
				return;
			}
		} else if (this.jobcardOBJ.jobcardtype == 'MSC00312') { // internal
			if (!this.jobcardOBJ.employeeid) {
				this._CustomExceptionService.handleWarning("Please Select Employee ");
				return;
			}
		}
		if (this.jobcardOBJ.jobcardtype == 'MSC00313') {
			if (!this.jobcardOBJ.approximateamountinusd) {
				this._CustomExceptionService.handleWarning("Please Entre Service Cost ");
				return;
			}
		}
		for (let i = 0; i < this._OtherCharges.length; i++) {
			if (this._OtherCharges[i].ocid && !this._OtherCharges[i].ocvalue) {
				this._CustomExceptionService.handleError("Please Enter Valid Other Charges")
				return
			}
			if (!this._OtherCharges[i].ocid && this._OtherCharges[i].ocvalue) {
				this._CustomExceptionService.handleError("Please Enter Valid Other Charges")
				return
			}
		}
		for (let i = 0; i < this._AdditionalCharges.length; i++) {
			if (this._AdditionalCharges[i].adid && !this._AdditionalCharges[i].adper) {
				this._CustomExceptionService.handleError("Please Enter Valid Other Charges")
				return
			}
			if (!this._AdditionalCharges[i].adid && this._AdditionalCharges[i].adper) {
				this._CustomExceptionService.handleError("Please Enter Valid Other Charges")
				return
			}
		}
		if (this._action == 'create') {
			if (this.jobcardOBJ.status == 'MSC00316') {
				this._CustomExceptionService.handleWarning("Job Card Cannot be Closed untill Service Work Order is completed");
				return;
			}
		}
		var Tempjobcarddetail = []
		for (var i = 0; i < this._jobcarddetails.length; i++) {
			var jobcarddetail = new Jobcarddetail();
			jobcarddetail.jobcarddetailid = this._jobcarddetails[i].jobcarddetailid || 0;
			jobcarddetail.jobcardid = this._jobcarddetails[i].jobcardid || 0;
			jobcarddetail.productid = this._jobcarddetails[i].productid;
			jobcarddetail.uom = this._jobcarddetails[i].uomcode;
			jobcarddetail.unitprice = this._jobcarddetails[i].unitprice;
			jobcarddetail.finalamount = this._jobcarddetails[i].finalamount;
			jobcarddetail.srquantity = this._jobcarddetails[i].srquantity;
			Tempjobcarddetail.push(jobcarddetail);
		}
		if (!this.Selectedchecklist.length) {
			this._CustomExceptionService.handleWarning("Please Select Check List");
			return
		}
		let OBJ = {
			"JobCard": this.jobcardOBJ,
			"JobCardDetail": Tempjobcarddetail,
			"JobCardCheckListDetail": this.Selectedchecklist,
			"JobCardOtherChargeDetail": this._OtherCharges,
			"JobCardAdditionalChargeDetail": this._AdditionalCharges
		}
		this._IsProgressSpinner = true;
		if (this._action == 'create') {
			this._jobcardService.create(OBJ).subscribe((result) => {
				this._IsProgressSpinner = false;
				const resutJSON = JSON.parse(JSON.stringify(result));
				if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
					this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
					this.reset();
				} else { this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message); this._IsSaveDisable = false; this._IsclearDisable = false; }
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		} else if (this._action == 'edit') {
			this._jobcardService.edit(OBJ).subscribe((result) => {
				this._IsProgressSpinner = false;
				const resutJSON = JSON.parse(JSON.stringify(result));
				if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
					this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
					this._IsSaveDisable = true
					this._IsclearDisable = true;
				} else { this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message); }
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	reset() {
		this._submitted = false
		this._jobcardform.reset();
		this.InitializeFormGroup();
		this.Selectedchecklist = [];
		this._employee = [];
		this._supplier = [];
		this._jobcarddetails = [];
		this._OtherCharges = [];
		this._AdditionalCharges = [];
		this.ngOnInit();
		this.mailid = null;
		this.mobileno = null;
		this.Address = null;
		this._selectedchecklist = []
	}
	goBack() {
		this._router.navigate(['/VJobCard']);
	}
	AddAdditionalCharges(event) {
		let otherChargesExists = (this._AdditionalCharges.filter(s => s.adid > 0).length == this._AdditionalCharges.length) && this._AdditionalCharges.some(s => s.adid > 0);
		if (!otherChargesExists) return;
		for (let i = 0; i < this._AdditionalCharges.length; i++) {
			const x = this._AdditionalCharges[i];
			if (x.adid && (!x.adper || x.adper == 0 || !x.advalue || x.advalue == 0)) {
				this._CustomExceptionService.handleWarning("Please Enter Percentage for the previous line items")
				return
			}
		}
		var A_charges = new JobCardAdditionalChargeDetail();
		A_charges.jobcardadditionalchargedetailid = 0;
		A_charges.jobcardid = 0;
		A_charges.advalue = 0;
		A_charges.adper = 0;
		this._AdditionalCharges.push(A_charges);
	}
	RemoveAdditionalCharges(pOthercharges: JobCardAdditionalChargeDetail) {
		var index = this._AdditionalCharges.indexOf(pOthercharges);
		this._AdditionalCharges.splice(index, 1)
		this.netcalculation();
		if (this._AdditionalCharges.length <= 0) {
			var A_charges = new JobCardAdditionalChargeDetail();
			A_charges.jobcardadditionalchargedetailid = 0;
			A_charges.jobcardid = 0;
			A_charges.advalue = 0;
			A_charges.adper = 0;
			this._AdditionalCharges.push(A_charges);
		}
	}
	OnchangeAdditionalCharge(a_charge: JobCardAdditionalChargeDetail, event: any) {
		let isAlreadyExists = this._AdditionalCharges.filter(s => s.adid == event.value).length > 1;
		if (isAlreadyExists) {
			this._CustomExceptionService.handleWarning("Additional Charges Already Exists!");
			var index = this._AdditionalCharges.indexOf(a_charge);
			this._AdditionalCharges.splice(index, 1)
			return;
		}
		a_charge.advalue = 0;
		a_charge.adid = event.value;
		this.netcalculation()
	}
	OnchangeAdditionalChargePer(a_charge: JobCardAdditionalChargeDetail, event: any) {
		let ServiceCost: number = this._jobcardform.get("approximateamountinusd").value
		if (!ServiceCost || ServiceCost == 0) {
			this._AdditionalCharges = []
			var A_charges = new JobCardAdditionalChargeDetail();
			A_charges.jobcardadditionalchargedetailid = 0;
			A_charges.jobcardid = 0;
			A_charges.advalue = 0;
			A_charges.adper = 0;
			this._AdditionalCharges.push(A_charges);
			this._CustomExceptionService.handleWarning("Please Enter Service Cost");
			return
		}
		if (!a_charge.adid) {
			a_charge.adper = 0
			a_charge.advalue = 0
			this._CustomExceptionService.handleWarning("Please Select Description");
			return
		}
		a_charge.adper = a_charge.adper || 0;
		a_charge.advalue = (a_charge.adper * ServiceCost) / 100;
		this.netcalculation()
	}
	netcalculation() {
		let cost: number = 0,
			percentage: number = 0,
			vatamount: number = 0,
			otherchargesamount: number = 0,
			netamount: number = 0,
			additionalchargesamount: number = 0;
		cost = this._jobcardform.get('approximateamountinusd').value;
		if (!cost) {
			this._CustomExceptionService.handleWarning(" Please Enter Service Cost");
			return
		}
		var taxid = this._jobcardform.get('vatid').value;
		if (taxid) {
			percentage = this.vattype.filter(f => f.taxid == taxid)[0].taxpercentage
		}
		for (let i = 0; i < this._OtherCharges.length; i++) {
			otherchargesamount += this._OtherCharges[i].ocvalue
		}
		for (let i = 0; i < this._AdditionalCharges.length; i++) {
			additionalchargesamount += this._AdditionalCharges[i].advalue
		}
		vatamount = ((cost + additionalchargesamount) * percentage / 100)
		this._jobcardform.controls['vatamount'].setValue(vatamount.toFixed(2))
		this._jobcardform.get('ocvalue').setValue(otherchargesamount)
		this._jobcardform.get('advalue').setValue(additionalchargesamount.toFixed(2))
		netamount = (cost + vatamount + otherchargesamount + additionalchargesamount)
		this._jobcardform.get('netamount').setValue(netamount.toFixed(2))
	}
}