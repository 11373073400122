export class Schemedetail {
    schemedetailid: number;
    schemeid: number;
    productid: number;
    minimumqty: number;
    maximumqty: number;
    discountpercentage: number;
    rewardpoints: number;
    value: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;  
}