import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockadjustmentService {

  public _StockAdjustmentService = environment.StockadjustmentService;
  public _RptStockAdjustmentService = environment.RptStockAdjustmentPrintService;
  public _httpOptions;
  token: any;
  header: any;
  constructor(private http: HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  FetchAll(userid: number, branchid: number,roleid:number,fromdate:string,todate:string) {
    const headers = this.header;
    var Query = this._StockAdjustmentService + '/FetchAllLoad' + '/' + userid + '/' + branchid + '/' + roleid + '/'+fromdate + '/' + todate;
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      return data;
    }));
  }
  PageOnLoad(stocktakeid: number, ): Observable<any> {
    const headers = this.header;
    var Query = this._StockAdjustmentService + '/' + stocktakeid ;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  getProductCategory(producttype:object) {
    const headers = this.header;
    var Query = this._StockAdjustmentService+'/getProductCategories';
    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }
  getProduct(productcategory:object) {
    const headers = this.header;
    var Query = this._StockAdjustmentService + '/getProducts';
    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }
  getproductdetails(productheader:object) {
    const headers = this.header;
    var Query = this._StockAdjustmentService+'/getProductDetails';
    return this.http.post<{ data: any }>(Query, productheader, { headers });
  }
  getVariant(productid: number) {
    const headers = this.header;
    var Query = this._StockAdjustmentService + '/getProductVariants' + '/' + productid;
    return this.http.get<{ data: any }>(Query, { headers });

  }
  edit(p_stadjustment: object) {
    const headers = this.header;
    var Query = this._StockAdjustmentService;
    return this.http.put<{ data: any }>(Query, p_stadjustment, { headers });
  }
  create(p_stadjustment: object) {
    const headers = this.header;
    var Query = this._StockAdjustmentService;
    return this.http.post<{ data: any }>(Query, p_stadjustment, { headers });
  }
  

  GetProduct(product: string, branchid: number) {
    const headers = this.header;
    var Query = this._StockAdjustmentService + '/GetProduct' + '/' + product + '/' + branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  SendToApproval(userid: number, branchid: number, stocktakeid: string) {
    const headers = this.header;
    //console.log("Send To Approval");
    var Query = this._StockAdjustmentService + '/SendToApproval'  + '/' + userid + '/' + branchid + '/' + stocktakeid;
    //console.log(Query,"sendtoapproval");
    return this.http.post<{ data: any }>(Query, '', { headers });
  }

  Cancel(obj:Object) {
    const headers = this.header;
    //console.log("Send To Approval");
    var Query = this._StockAdjustmentService + '/Cancel';
    //console.log(Query,"sendtoapproval");
    return this.http.post<{ data: any }>(Query, obj, { headers });
  }

  Print(stocktakeno: string, branchid: number) {
    var Query = this._RptStockAdjustmentService + '/Print/' + stocktakeno + '/' + branchid;
    // return this.http.get(Query, { responseType: 'blob' });
    window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
  }
}
