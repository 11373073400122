<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">

            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Brand</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                (click)="onSave()"></button>
                            <button pButton pRipple type="button" title="Clear" (click)="reset($event)"
                                icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRiple type="button" icon="pi pi-search" (click)="goBack($event)"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="Brandform">
                    <table class="normal-table">
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText name="brandname" formControlName="brandname">
                                    <label for="brandname"> Enter Brand Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || Brandform.get('brandname').touched) && Brandform.get('brandname').errors?.BrandNameRequired">
                                    Please enter brand name
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <div class="p-inputgroup" *ngIf="_Iscreatefileupload">
                                    <div class="file-upload-wrapper" data-text="Select your file!">
                                        <input name="file-upload-field" type="file"
                                            accept="image/png, image/gif, image/jpeg" class="file-upload-field"
                                            (change)="selectFile($event)" value="">
                                    </div>
                                </div>
                                <div class="p-inputgroup" *ngIf="_Isviewfileupload">
                                    <span class="p-float-label">
                                        <div class="browse-links">
                                            <p-button label="Brand" styleClass="p-button-link" title="{{imageselect}}"
                                                (click)="showImageDialog('bottom-right')">
                                            </p-button>
                                        </div>
                                    </span>
                                    <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                        [disabled]="clearimage" (click)="onImageClear()" tooltipPosition="top">
                                        <i class="pi pi-times"></i>
                                    </button>
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="branchList" optionLabel="branchname"
                                        [disabled]="DisableView" optionValue="branchid" [(ngModel)]="branchid"
                                        [filter]="true" [virtualScroll]="true" itemSize="30"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                    <label for="recordstatusname"> Branch <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_recordstatus" [autoDisplayFirst]="false"
                                        optionLabel="metasubdescription" optionValue="metasubcode" name="status"
                                        formControlName="recordstatus">
                                    </p-dropdown>
                                    <label for="recordstatusname"> Status <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </form>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

        </div>
    </div>
</div>
<p-dialog header="Image Cropper" [(visible)]="imageCropperTool" [modal]="true" [style]="{width: '1200px'}"
    [baseZIndex]="10000">
    <div class="text-center mb10">
        <button pButton pRipple label="" icon="las la-plus" pTooltip="Zoom +" tooltipPosition="top" (click)="zoomIn()"
            class="p-button p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-minus" pTooltip="Zoom -" tooltipPosition="top" (click)="zoomOut()"
            class="p-button p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-redo-alt" pTooltip="Reset Image" tooltipPosition="top"
            (click)="resetImage()" class="p-button-danger p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-save" type="button" pTooltip="Save" tooltipPosition="top"
            (click)="saveImage($event)" class="p-button-success p-mr-2"></button>
    </div>
    <div class="row">
        <div class="col-md-6">
            <h5>Input</h5>
            <div class="crop-input">
                <image-cropper class="img-fluid" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="2 / 1" [resizeToWidth]="400"
                    [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false"
                    [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                    [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed()"></image-cropper>
            </div>
        </div>
        <div class="col-md-6">
            <h5>Output</h5>
            <img [src]="croppedImage" />
        </div>
    </div>
</p-dialog>
<p-dialog header="Brand Image" [(visible)]="displayimagepopup" [position]="position1" [style]="{width: '500px'}"
    [baseZIndex]="10000">
    <img [src]="brand" alt="" class="img-preview">
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="showPopupDialog()" label="Ok" styleClass="p-button-text">
        </p-button>
    </ng-template>
</p-dialog>
<p-toast position="bottom-right"></p-toast>