<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Multi Swipe</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack()" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_swipeForm">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_salesinvoicenos" [filter]="true"
                                    filterBy="salesinvoiceno" optionValue="salesinvoiceno" optionLabel="salesinvoiceno"
                                    formControlName="salesinvoiceno" [showClear]="true"
                                    (onChange)="onChangeSalesinvoiceno()" [virtualScroll]="true" itemSize="30">
                                </p-dropdown> -->
                                    <input type="text" pInputText formControlName="salesinvoiceno" name="salesinvoiceno"
                                        autocomplete="off">
                                    <label for="salesinvoiceid">Salesinvoice No <span class="hlt-txt">*</span>
                                    </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="currencyname" name="currencyname"
                                        autocomplete="off">
                                    <label for="currencyname">Currency Name <span class="hlt-txt">*</span> </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="salesinvoiceamount"
                                        name="salesinvoiceamount" autocomplete="off">
                                    <label for="salesinvoiceamount">Invoice Value<span class="hlt-txt">*</span>
                                    </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="customernetamount"
                                        name="customernetamount" autocomplete="off">
                                    <label for="customernetamount">Net Amount<span class="hlt-txt">*</span> </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="swipedamount" name="swipedamount"
                                        autocomplete="off">
                                    <label for="swipedamount">Paid Amount <span class="hlt-txt">*</span> </label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-table [value]="_swipeDetail" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]" [rowHover]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width:100px;" class="text-center">Action</th>
                                <th>Reference No</th>
                                <th>RRN No</th>
                                <th>IMEI</th>
                                <th>PAN</th>
                                <th>Amount (in Bank)</th>
                                <th>Amount (in USD)</th>
                                <th style="text-align: center;width:100px;"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-swipe let-i="index">
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple title="Remove" icon="pi pi-plus"
                                        class="p-button-primary p-mr-2" (click)="OnAdd(swipe)"></button>
                                    <button pButton pRipple title="Remove" icon="pi pi-trash"
                                        class="p-button-danger p-mr-2" (click)="OnRemove(swipe)"
                                        [disabled]="swipe.rrn"></button>
                                </td>
                                <td>
                                    {{swipe.ref}}
                                </td>
                                <td>
                                    {{swipe.rrn}}
                                </td>
                                <td>
                                    {{swipe.imei}}
                                </td>
                                <td>
                                    {{swipe.pan}}
                                </td>
                                <td>
                                    <p-inputNumber [(ngModel)]="swipe.swipeamount" autocomplete="off"
                                        (onInput)="onSwipeAmountChange(swipe)" (onFocus)="onSwipeAmountChange(swipe)"
                                        (onBlur)="onSwipeAmountChange(swipe)" [disabled]="swipe.rrn != ''"
                                        mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                                    <!-- <input type="text" pInputText name="swipeamount" [(ngModel)]="swipe.swipeamount"
                                        autocomplete="off" (keyup)="onSwipeAmountChange(swipe)"
                                        [disabled]="swipe.rrn != ''" /> -->
                                </td>
                                <td>
                                    {{swipe.swipeamountUsd | number : '1.2-2'}}
                                </td>
                                <td style="text-align:center">
                                    <button pButton pRipple label="Swipe" class="p-button-primary" type="button"
                                        (click)="OnSwipe(swipe)"
                                        [disabled]="swipe.rrn || !swipe.swipeamount"></button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog #cd [style]="{width: '400px'}" header="Payment Request" [(visible)]="paymentModel.visiblePaymentPopup"
    [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
    <ng-template pTemplate="content">
        <label [innerHTML]="paymentModel.Message"></label>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" label="Cancel Request" class="p-button-warning"
            (click)="paymentModel.CancelRequest()" [hidden]="paymentModel.isHideCancelRequestButton"></button>
    </ng-template>
</p-dialog>
<p-toast position="bottom-right"></p-toast>