import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class StockinwardService {

  public _Action: IAction;
  public _httpOptions;
  public _StockinwardService = environment.StockinwardService;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  // Page Load :
  PageOnload(p_stockinwardid: number, p_inwardtypes: string): Observable<any> {
    const headers = this.header;
    var Query = this._StockinwardService + '/' + p_stockinwardid + '/' + p_inwardtypes;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  //To Get Supplier Branch Name :
  getSupplierNameorBranchName(inwardtype: string, branchid: number, supplierid: number, userid: number) {
    const headers = this.header;
    var Query = this._StockinwardService + '/getSupplierNameorBranchName/' + inwardtype + '/' + branchid + '/' + supplierid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  //To Get Product Details :
  getProductDetails(inwardtype: string, referenceno: string, branchorsupplierid: number, deliveryno: string, Currentbranchid: number, productbarcodeno: string) {

    const headers = this.header;
    var Query = this._StockinwardService + '/getProductDetails/' + inwardtype + '/' + referenceno + '/' + branchorsupplierid + '/' + deliveryno + '/' + Currentbranchid + '/' + productbarcodeno;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  getDeliveryno(referenceno: string, branchorsupplierid: number, Currentbranchid: number) {

    const headers = this.header;
    var Query = this._StockinwardService + '/getDeliveryno/' + referenceno + '/' + branchorsupplierid + '/' + Currentbranchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }


  //Save Function:
  create(p_Stockinward: object) {

    const headers = this.header;
    var Query = this._StockinwardService;
    return this.http.post<{ data: any }>(Query, p_Stockinward, { headers });
  }

  // Update Page Load:
  edit(p_Stockinward: object) {

    const headers = this.header;
    var Query = this._StockinwardService;
    return this.http.put<{ data: any }>(Query, p_Stockinward, { headers });
  }

  //Fetch All function call:
  FetchAll(branchid: number, userid: number, roleid: number, fromdate: string, todate: string) {

    const headers = this.header;
    var Query = this._StockinwardService + '/FetchAll/' + branchid + '/' + userid + '/' + roleid + '/' + fromdate + '/' + todate;

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }
  Print(obj: any) {
		const headers = this.header;
		var Query = this._StockinwardService + '/STOCKINWARDPRINT';
		return this.http.post(Query, obj, { responseType: 'blob' ,  headers });
	}

}
