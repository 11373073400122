<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_categoryform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Category</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" [disabled]="isDisabledsave" title="Save" icon="pi pi-save" ></button>
                                <button pButton pRipple type="button" [disabled]="isDisabledreset" (click)="reset($event)" title="Clear"
                                    icon="pi pi-trash" class="" class="p-button-danger"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                    title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <!-- <p-toast position="bottom-right"></p-toast> -->
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_type" optionLabel="metasubdescription"
                                        optionValue="metasubcode" name="type" formControlName="type">
                                    </p-dropdown>
                                    <label for="type"> Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_categoryform.get('categorystatus').touched || _submitted) && _categoryform.get('categorystatus').errors?.typeRequired">
                                    Please select type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="categoryname" formControlName="categoryname">
                                    <label for="categoryname">  Category Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" 
                                     *ngIf="(_submitted || _categoryform.get('categoryname').touched )&& _categoryform.get('categoryname').errors?.CategoryNameRequired"> 
                                    Please enter category name 
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_categorystatus" optionLabel="metasubdescription"
                                        optionValue="metasubcode" name="categorystatus" formControlName="categorystatus">
                                    </p-dropdown>
                                    <label for="categorystatus"> Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_categoryform.get('categorystatus').touched || _submitted) && _categoryform.get('categorystatus').errors?.SelectStatus">
                                    Please select Category status
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                           
                        </tr>
                    </table>
                    <!-- <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog> -->
                </div>
            </form>
        </div>
    </div>
</div>