<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Payment & Recepit Report</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptpaymentform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="fromdate" dateFormat="dd/mm/yy"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2030"
                                        [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="fromdate">From Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="todate" dateFormat="dd/mm/yy"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2030"
                                        [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="todate">To Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">

                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_typelists" optionValue="value"
                                        optionLabel="type" formControlName="partytype"
                                        (onChange)="onchangepartytype($event)">
                                    </p-dropdown>
                                    <label for="partytypename">Party Type <span class="hlt-txt">*</span></label>
                                </span>


                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_accountnamelist"
                                        optionValue="ledgerid" optionLabel="name" formControlName="customer_supplierid"
                                        [filter]="true" filterBy="name" [virtualScroll]="true" itemSize="30">
                                    </p-dropdown>
                                    <label for="customer_suppliername">Party Name <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_branchlist"
                                        optionValue="branchid" optionLabel="branchname" formControlName="branchid"
                                        [filter]="true" filterBy="branchname" [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-dropdown>
                                    <label for="branchname">Branch Name </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_typelist" optionValue="type"
                                        optionLabel="type" formControlName="type" [filter]="true" filterBy="type">
                                    </p-dropdown>
                                    <label for="type">Type</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_currencylist"
                                        optionValue="currencyid" optionLabel="currencyname" formControlName="currencyid"
                                        [filter]="true" filterBy="currencyname">
                                    </p-dropdown>
                                    <label for="type">Currency <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <iframe #iframe height="500" width="100%" [src]="_src"></iframe>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>
        </div>
    </div>

</div>
<p-toast position="bottom-right"></p-toast>