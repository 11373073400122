import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductcategoryService } from 'src/app/core/services/masters/productcategory.service';
import { Productcategoryview } from 'src/app/core/Views/Productcategoryview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-product-category',
  templateUrl: './v-product-category.component.html',
  styleUrls: ['./v-product-category.component.css']
})
export class VProductCategoryComponent implements OnInit {
  selectedrows: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _categoryviews: Productcategoryview[];
  _categoryview: Productcategoryview;
  _Action: IAction;
  _IsProgressSpinner: boolean = true;
  _productcategoryid: number;
  _userid = sessionStorage["userid"];
  cols: any[];
  constructor(
    private _CategoryService: ProductcategoryService,
    private router: Router,
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService,
    private confirmationService: ConfirmationService,
    private _CustomExceptionService: CustomExceptionService,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(16);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'producttypename', header: 'Product Type Name' },
      { field: 'categoryname', header: 'Category Name' },
      { field: 'eccategoryname', header: 'EC-Category Name' },
      { field: 'branchname', header: 'Branch Name' },
      { field: 'categorystatusname', header: 'Status' }
    ]
    this._IsProgressSpinner = true;
    this._CategoryService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._categoryviews = resultJSON.productcategoryviews;
      this._IsProgressSpinner = false;
    });
  }
  onRowSelect(event) { }
  onRowUnselect(event) { }
  view(event) {
    this.router.navigate(['/cProductCategory'], { state: { productcategoryid: event.productcategoryid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cProductCategory']);
  }
  delete(event) {
    alert("delete");
  }
  edit(event) {
    this.router.navigate(['/cProductCategory'], { state: { productcategoryid: event.productcategoryid, action: 'edit' } });
  }
  exportExcel() {
    let BranchList = this.GetBranchList();
    this.exportUtility.exportExcel(BranchList, 'ProductCategory', 'xlsx');
  }
  ExportToPdf() {
    let BranchList = this.GetBranchList();
    this.exportUtility.ExportToPdf(BranchList, 'ProductCategory.pdf');
  }
  GetBranchList() {
    let ProductCategoryList = [];
    this._categoryviews.forEach(element => {
      let productcategory: any = {};
      productcategory["Product Type Name"] = element.producttypename;
      productcategory["Category Name"] = element.categoryname;
      productcategory["EC-Category Name"] = element.eccategoryname;
      productcategory["Branch Name"] = element.branchname;
      productcategory["Status"] = element.categorystatusname;
      ProductCategoryList.push(productcategory);
    });
    return ProductCategoryList;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  confirm(event) {
    this._productcategoryid = event.productcategoryid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Product Category <b>"' + event.categoryname + '"</b> ?',
      accept: () => {
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._CategoryService.Cancel(this._productcategoryid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_07);
        this._CategoryService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._categoryviews = resultJSON.productcategoryviews;
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}