<div class="page-container">
    <div class="inner-page-container">
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-6">
                        <h3>Delivery Note</h3>
                    </div>
                    <div class="col-md-6 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="button" label="" class="p-button p-button-success p-mr-2"
                                title="Add" *ngIf="_Action._Add" (click)="GoToCreate($event)"
                                icon="las la-plus"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="las la-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                (click)="ExportToPdf()" class="p-button-warning p-mr-2" pTooltip="PDF"
                                tooltipPosition="bottom"></button>
                        </ng-template>
                    </p-toolbar>

                    <p-table #dt [value]="jobCardListGrid" [rows]="20" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [globalFilterFields]="['suppliername','returntypename','deliverynotestatusname','approvalstatusname']"
                        [rowHover]="true" dataKey="id"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:120px;" class="text-center">Action</th>
                                <th pSortableColumn="suppliername">Supplier Name<p-sortIcon field="suppliername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="returntypename">Return Type Name<p-sortIcon field="returntypename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="deliverynotestatusname">Delivery Note Status <p-sortIcon
                                        field="deliverynotestatusname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="approvalstatusname">Approved Status<p-sortIcon
                                        field="approvalstatusname">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_jobCardListGrid>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        *ngIf="_Action._View" (click)="view(_jobCardListGrid)"></button>
                                    <button pButton pRipple icon="las la-pen" (click)="edit(_jobCardListGrid)"
                                        *ngIf="_Action._Update"
                                        [disabled]="_jobCardListGrid.approvalstatus == 'MSC00047'|| _jobCardListGrid.approvalstatus == 'MSC00046'"
                                        title="Edit" class="p-button-success p-mr-2"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Delete"
                                        (click)="confirm(_jobCardListGrid)" *ngIf="_Action._Delete"
                                        [disabled]="_jobCardListGrid.approvalstatus == 'MSC00045'||_jobCardListGrid.approvalstatus == 'MSC00047'|| _jobCardListGrid.approvalstatus == 'MSC00046' || _jobCardListGrid.approvalstatus == 'MSC00048' || _jobCardListGrid.approvalstatus == 'MSC00150'"
                                        class="p-button p-button-danger"></button>
                                </td>
                                <td>
                                    {{_jobCardListGrid.suppliername}}
                                </td>
                                <td>
                                    {{_jobCardListGrid.returntypename}}
                                </td>
                                <td>
                                    {{_jobCardListGrid.deliverynotestatusname}}
                                </td>
                                <td>
                                    {{_jobCardListGrid.approvalstatusname}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-toast position="bottom-right"></p-toast>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>