<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Payment Form</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple icon="pi pi-plus" title="Add" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Add" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" yearRange="1980:2030"
                                    [minDate]="mindate" [maxDate]="maxdate">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" yearRange="1980:2030"
                                    [minDate]="mindate" [maxDate]="maxdate">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <p-toast></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                            <!-- <button pButton pRipple label="" icon="pi pi-plus" class="p-button-success p-mr-2"
                       (click)="openNew()"></button>
                      <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger ">
                      </button>-->
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportUtility.exportCSV(dt)" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_paymentsview" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['voucherno','paymentdate','Paymentmodename','branchname','paymentamount','fromaccountname','toaccountname','createdbyname','paymentstatusdesc','createdon','narration']"
                        [rowHover]="true" dataKey="voucherno" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>

                                <th pSortableColumn="action" style="text-align: center;">Action
                                    <p-sortIcon field="action"></p-sortIcon>
                                </th>
                                <th pSortableColumn="voucherno">Voucher No
                                    <p-sortIcon field="voucherno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="paymentdate">Payment Date
                                    <p-sortIcon field="paymentdate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="Paymentmodename">Payment Mode
                                    <p-sortIcon field="Paymentmodename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="Branchname">Branch Name
                                    <p-sortIcon field="Branchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname">Currency
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="paymentamount">Payment Amount
                                    <p-sortIcon field="paymentamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="fromaccountname">From Account
                                    <p-sortIcon field="fromaccountname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="toaccountname">To Account
                                    <p-sortIcon field="toaccountname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="narration">Narration
                                    <p-sortIcon field="narration">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="paymentstatusdesc">Status
                                    <p-sortIcon field="paymentstatusdesc">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_paymentview>
                            <tr>

                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_paymentview)"></button>
                                    <button pButton pRipple icon="las la-times" title="Cancel" class="p-button p-button-danger p-mr-2" (click)="confirm(_paymentview)" [disabled]="!_Action._Delete" *ngIf="!(_paymentview.paymentstatusid == 150 || _paymentview.paymentstatusid == 46)"></button>
                                    <!-- <button pButton pRipple icon="pi pi-trash" title="Delete"
                                        class="p-button p-button-danger" [disabled]="_delete"
                                        (click)="delete(_paymentview)"></button> -->
                                    <button pButton pRipple icon="las la-print" title="Print" class="p-button-warning p-mr-2" (click)="Print(_paymentview)"></button>
                                </td>
                                <td>
                                    {{_paymentview.voucherno }}
                                </td>
                                <td>
                                    {{_paymentview.paymentdate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_paymentview.paymentmodename }}
                                </td>
                                <td>
                                    {{_paymentview.branchname }}
                                </td>
                                <td>
                                    {{_paymentview.currencyname }}
                                </td>
                                <td>
                                    {{_paymentview.paymentamount}}
                                </td>
                                <td>
                                    {{_paymentview.fromaccountname }}
                                </td>
                                <td>
                                    {{_paymentview.toaccountname }}
                                </td>
                                <td>
                                    {{_paymentview.narration }}
                                </td>
                                <td>
                                    {{_paymentview.paymentstatusdesc }}
                                </td>
                                <td>
                                    {{_paymentview.createdbyname}}
                                </td>
                                <td>
                                    {{_paymentview.createdon|date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>


                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

            </div>
        </div>
    </div>
</div>