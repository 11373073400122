import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { RptPendingDeliveryService } from 'src/app/core/Services/reports/Stock/rpt-pending-delivery/rpt-pending-delivery.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-rpt-pending-delivery',
  templateUrl: './rpt-pending-delivery.component.html',
  styleUrls: ['./rpt-pending-delivery.component.css']
})
export class RptPendingDeliveryComponent implements OnInit {
  _IsProgressSpinner: boolean = false;
  _rptpendingdeliveryform: FormGroup
  _invoicetype: any
  mindate: Date;
  maxdate: Date;
  _branches: any[] = []
  _branchesid: any[] = []
  _customers: any[] = []
  _customersid: any[] = []
  _pendingdeliverylists: any[] = []
  _productwise: boolean = false
  customertype: boolean = false
  _ProductWisepending: any[] = []
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private exportUtility: ExportUtility,
    private _RptPendingDeliveryService: RptPendingDeliveryService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,

  ) { }
  InitializeForm() {
    this._rptpendingdeliveryform = this.fb.group({
      fromdate: [],
      todate: [],
      invoicetype: ['MSC00339'],
    })
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptpendingdeliveryform.get("fromdate").setValue(new Date);
      this._rptpendingdeliveryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptpendingdeliveryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptpendingdeliveryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;
    this._RptPendingDeliveryService.PageOnload({ "userid": sessionStorage["userid"] }).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._invoicetype = resultJSON.inwardtypeList;
      this._customers = resultJSON.customerList;
      this._branches = resultJSON.brandList;
      this._IsProgressSpinner = false;
      this.getinwardtype();
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GenerateReport() {

    var _fromdate = this._rptpendingdeliveryform.get("fromdate").value;
    var _todate = this._rptpendingdeliveryform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than To Date");
      return;
    }
    var type = this._rptpendingdeliveryform.get("invoicetype").value;
    let data = {
      "Effectivefrom": fromdate,
      "Effectiveto": todate,
      "Userid": sessionStorage["userid"],
      "invoicetype": type,
      "CustomerId": this._customersid || [],
      "BrandId": this._branchesid || [],
    }

    this._IsProgressSpinner = true;
    this._RptPendingDeliveryService.Getpendinglists(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._pendingdeliverylists = resultJSON.pendingdeliveryreportlists;

      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  clear() {
    this._pendingdeliverylists = [];
    this._rptpendingdeliveryform.reset();
    this._branchesid = [];
    this._customersid = [];
    this._productwise = false
    this._ProductWisepending = [];
  }
  getinwardtype() {
    if (this._rptpendingdeliveryform.get("invoicetype").value == 'MSC00210') {
      this.customertype = true
    }
    else {
      this.customertype = false
    }
  }
  getproductwisedetails(_pendingdelivery: any) {
    this._IsProgressSpinner = true;
    let data = {
      "invoicetype": _pendingdelivery.INVOICETYPE,
      "referanceno": _pendingdelivery.REFERANCENO,
    }

    this._RptPendingDeliveryService.Getproductwisependinglists(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._productwise = true

      this._ProductWisepending = resultJSON.pendingdeliveryproductwiselists;

      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }

  exportExcel(){
    let branchwisestocklists = this.GetCustomerList();
		this.exportUtility.exportExcel(branchwisestocklists, 'Pending_Delivery_Report', 'xlsx');
  }

  ExportToPdf() {
		let branchwisestocklists = this.GetCustomerList();
		const data = branchwisestocklists.map(Object.values);
		const head = [[
			'Invoice Type',
			'Branch Name',
			'Referance No',
			'Referance Date',
			'Customer_or_supplier',
			'Requested Qty',
			'Delivered Qty',
			'Balance Qty'
		]]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					}, headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					}, bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('Pending_Delivery_Report.pdf');
			})
		})
	}

  GetCustomerList() {
		let branchwisestocklists = [];
		this._pendingdeliverylists.forEach(element => {
			let branchwisestock: any = {};
			branchwisestock["Invoice Type"] = element.INVOICETYPE;
			branchwisestock["Branch Name"] = element.BRANCHNAME;
			branchwisestock["Referance No"] = element.REFERANCENO;
			branchwisestock["Referance Date"] = element.REFERANCEDATE;
			branchwisestock["Customer_or_supplier"] = element.CUS_OR_BRANCH_NAME;
			branchwisestock["Requested Qty"] = element.REQUESTEDQTY;
			branchwisestock["Delivered Qty"] = element.DELIVERYQUANTITY || 0;
			branchwisestock["Balance Qty"] = element.BALANCEQUANTITY || 0;
			branchwisestocklists.push(branchwisestock);
		});
		return branchwisestocklists;
	}


}
