<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_currencyform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Currency</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                    [disabled]="isDisabledSave" (click)="showBasicDialog()"></button>
                                <button pButton pRipple title="Clear" type="button" icon="pi pi-trash"
                                    class="p-button-danger" (click)="reset($event)"
                                    [disabled]="isDisabledClear"></button>
                                <button pButton pRiple icon="pi pi-search" title="Back to Search"
                                    class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Country" name="countryid"
                                        optionLabel="countryname" optionValue="countryid" formControlName="countryid">
                                    </p-dropdown>
                                    <label for="countryid">Country </label>
                                </span>

                            </td>
                            <td style="width: 20%;">

                                <span class="p-float-label">
                                    <input type="text" name="currencycode" formControlName="currencycode" pInputText>
                                    <label for="currencycode">Currency Code <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 6. if currency code is not given, show error "Please enter Currency Code" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _currencyform.get('currencycode').touched) && _currencyform.get('currencycode').errors?.CurrencyCodeRequired">
                                    Please enter currency code
                                </span>

                            </td>
                            <td style="width: 20%;">

                                <span class="p-float-label">
                                    <input type="text" name="currencyname" formControlName="currencyname" pInputText>
                                    <label for="currencyname">Currency Name <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 7.if "Currency Name" is not given, show error as "Please enter Currency name" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _currencyform.get('currencyname').touched) && _currencyform.get('currencyname').errors?.CurrencyNameRequired">
                                    Please enter currency name
                                </span>

                            </td>
                            <td style="width: 20%;">

                                <span class="p-float-label">
                                    <input type="number" name="exchangerate" formControlName="exchangerate" pInputText>
                                    <label for="exchangerate">Exchange Rate <span class="hlt-txt">*</span></label>
                                </span>
                                <!--8. if "Exchange Rate" is not given, show error "Please enter Exchange Rate" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _currencyform.get('exchangerate').touched) && _currencyform.get('exchangerate').errors?.ExchangeRateRequired">
                                    Please enter exchange rate
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="currencysymbol" formControlName="currencysymbol"
                                        pInputText>
                                    <label for="currencysymbol">Currency Symbol <span class="hlt-txt">*</span></label>
                                </span>
                                <!---- <span class="text-danger"
                                *ngIf="(_submitted || _currencyform.get('exchangerate').touched) && _currencyform.get('exchangerate').errors?.ExchangeRateRequired">
                                Please enter exchange rate
                            </span>-->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar name="effectivefrom" formControlName="effectivefrom" [showIcon]="true"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2030"
                                        dateFormat="dd/mm/yy" (onSelect)="OnFromDateSelect($event)">
                                    </p-calendar>
                                    <label for="effectivefrom">Effective From <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _currencyform.get('effectivefrom').touched) && _currencyform.get('effectivefrom').errors?.EffectiveFromRequired">
                                    Please enter effective from
                                </span>

                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar name="effectiveto" formControlName="effectiveto" [showIcon]="true"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2030"
                                        dateFormat="dd/mm/yy" [minDate]="minDate"
                                        [disabled]="!_currencyform.get('effectivefrom').value || _action == 'view'">
                                    </p-calendar>
                                    <label for="effectiveto">Effective To </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_currencystatus"
                                        name="currencystatus" optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="currencystatus"> </p-dropdown>
                                    <label for="currencystatus">Status </label>
                                </span>

                            </td>
                            <td></td>
                            <td>
                                <!-- Debug -->
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </tr>

                    </table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
            <p-dialog header="WARNING MESSAGE" [(visible)]="ManageDialog" [style]="{width: '400px',height:'150px'}"
                [modal]="true" [maximizable]="false">
                <ng-template pTemplate="content">

                    <table class="normal-table">
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <label style="font-size: larger; "> Please Click the Currency Update Button in Search Screen </label>
                                </span>
                            </td>
                        </tr>
                    </table>
                </ng-template>
                <ng-template pTemplate="footer">
                    <button pButton pRipple label="Done" (click)="ok()" icon="las la-check"
                        class="p-button-text"></button>
                </ng-template>
            </p-dialog>
        </div>
    </div>
</div>