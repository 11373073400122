import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  public _Action : IAction ;
  public _CmsService = environment.CmsService;
  public _httpOptions;


  constructor(private http:HttpClient) { }

  PageOnload(): Observable<any> {
    var Query = this._CmsService ;
    // console.log(Query);
    return this.http.get<{data:any}>(Query);
  }

  
  Create(cms:object) {
    var Query = this._CmsService+ '/Create' ;
    // console.log(Query);
    return this.http.post<{data:any}>(Query,cms);
  }

  Update(cms:object){
    var Query = this._CmsService+ '/Update' ;
    // console.log(Query);
    return this.http.put<{data:any}>(Query,cms);
  }
  
  View(cmsid:number):  Observable<any>{
    var Query = this._CmsService+ '/View' +'/'+ cmsid;
    // console.log(Query);
    return this.http.get(Query);
  }
  
  FetchAll():  Observable<any>{
    var Query = this._CmsService+ '/FetchAll' ;
    // console.log(Query);
    return this.http.get<{data:any}>(Query);
  }
  Delete(cms:object){
    var Query = this._CmsService+ '/Delete' ;
    // console.log(Query);
    return this.http.put<{data:any}>(Query,cms);
  }


}
