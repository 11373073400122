<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>SMS Configuration</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save" (click)="onSave()"
                                class=""></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            <!-- <button pButton pRipple label="" icon="pi pi-times" class="" (click)="hideDialog()" ></button>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-tabView [(activeIndex)]="selectedtab">
                    <p-tabPanel header="SMS">
                        <form [formGroup]="_smsConfigurationform" (ngSubmit)="onSave()">
                            <p-toast></p-toast>
                            <table class="normal-table">
                                <tr>
                                    <td style="width:20%"></td>
                                    <td style="width:20%">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_DocumentType"
                                                name="documenttypeid" [filter]="true" formControlName="documenttypeid"
                                                filterBy="functionname" (onChange)="GetDocumentStatus($event)"
                                                optionLabel="functionname" optionValue="functionid">
                                            </p-dropdown>
                                            <label for="documenttypeid"> Document Type<span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_smsConfigurationform.get('documenttypeid').touched || _submitted) && _smsConfigurationform.get('documenttypeid').errors?.SelectDocumentType">
                                            Please select document type
                                        </span>
                                    </td>
                                    <td style="width:20%">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <p-dropdown [filter]="true" [autoDisplayFirst]="false"
                                                    [options]="_DocumentStatus" formControlName="documentstatusid"
                                                    optionLabel="documentstatusname" optionValue="documentstatusid">
                                                </p-dropdown>
                                                <label for="documentstatusid">Document Status <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                            <button class="p-inputgroup-addon p-button" [disabled]="isDisableaddElement"
                                                title="Add" type="button" (click)="showBasicDialog()">
                                                <i class="las la-plus"></i>
                                            </button>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_smsConfigurationform.get('documentstatusid').touched || _submitted) && _smsConfigurationform.get('documentstatusid').errors?.SelectDocumentType">
                                            Please select document Status
                                        </span>
                                    </td>
                                    <td style="width:20%">
                                        <span class="p-float-label">
                                            <input type="text" formControlName="messagetemplate" pInputText>
                                            <label for="messagetemplate">Template Name <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_smsConfigurationform.get('messagetemplate').touched || _submitted) && _smsConfigurationform.get('messagetemplate').errors?.SelectDocumentType">
                                            Please Enter Template Name
                                        </span>
                                    </td>
                                    <td style="width:20%"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <!-- <form [formGroup]="_smsConfigurationRefernceform" > -->
                                    <td>
                                        <form [formGroup]="_smsConfigurationRefernceform">
                                            <div class="p-inputgroup">
                                                <span class="p-float-label">
                                                    <p-dropdown [filter]="true" [autofocusFilter]="true"
                                                        [options]="_DataElement" [autoDisplayFirst]="false"
                                                        formControlName="elementdatasms" optionLabel="name"
                                                        optionValue="name">
                                                    </p-dropdown>
                                                    <label for="elementdata">Data Element <span
                                                            class="hlt-txt">*</span></label>
                                                </span>
                                                <button class="p-inputgroup-addon p-button"
                                                    [disabled]="isDisableaddElement" title="Add" type="button"
                                                    (click)="AddElementSMS()">
                                                    <i class="las la-plus"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </td>
                                    <!-- </form> -->
                                    <td>
                                        <form [formGroup]="_smsConfigurationRefernceform">
                                            <span class="p-float-label">
                                                <p-multiSelect [options]="" formControlName="recipientgroup"
                                                    optionLabel="name">
                                                </p-multiSelect>
                                                <label for="recipientgroup">SMS Recipient Group <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                        </form>
                                    </td>
                                    <td>
                                        <form [formGroup]="_smsConfigurationRefernceform">
                                            <span class="p-float-label">
                                                <p-multiSelect [options]="" formControlName="recipientlist"
                                                    optionLabel="name">
                                                </p-multiSelect>
                                                <label for="dropdown">SMS Recipient List <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                        </form>
                                    </td>

                                    <td></td>

                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan="3">
                                        <p-editor #editor [style]="{'height':'320px'}" formControlName="subject">
                                            <p-header>
                                                <span class="ql-formats">
                                                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                                                    <button type="button" class="ql-italic"
                                                        aria-label="Italic"></button>
                                                    <button type="button" class="ql-underline"
                                                        aria-label="Underline"></button>
                                                </span>
                                            </p-header>
                                        </p-editor>

                                        <!-- <p-editor formControlName="subject" [style]="{'height':'320px'}">
                                    <ng-template pTemplate="header">
                                        <span class="ql-format-group">
                                            <span title="Bold" class="ql-format-button ql-bold"></span>
                                            <span class="ql-format-separator"></span>
                                            <span title="Italic" class="ql-format-button ql-italic"></span>
                                            <span class="ql-format-separator"></span>
                                            <span title="Underline" class="ql-format-button ql-underline"></span>
                                            <span class="ql-format-separator"></span>
                                            <span title="Strikethrough" class="ql-format-button ql-strike"></span>
                                        </span>
                                    </ng-template>
                                </p-editor> -->

                                    </td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                        <p-dialog header="Document Status" [(visible)]="ManageDialog"
                            [style]="{width: '300px',height:'200px'}" [modal]="true" [maximizable]="false">
                            <ng-template pTemplate="content">

                                <table class="normal-table">
                                    <tr>
                                        <td>
                                            <form [formGroup]="_smsConfigurationStatusform">
                                                <span class="p-float-label">
                                                    <input type="text" name="documentstatusname"
                                                        formControlName="documentstatusname" pInputText>

                                                    <label for="documentstatusname">Document Status Name <span
                                                            class="hlt-txt">*</span></label>
                                                </span>

                                            </form>
                                        </td>
                                    </tr>
                                </table>


                            </ng-template>
                            <ng-template pTemplate="footer">
                                <button pButton pRipple label="Quick Save" (click)="DocStatusSave()" icon="las la-check"
                                    class="p-button-text"></button>
                                <button pButton pRipple label="Cancel" (click)="cancel()" icon="pi pi-times"
                                    class="p-button-text"></button>
                            </ng-template>
                        </p-dialog>
                    </p-tabPanel>
                    <p-tabPanel header="E-Mail">
                        <form [formGroup]="_smsConfigurationform" (ngSubmit)="onSave()">
                            <p-toast></p-toast>
                            <table class="normal-table">
                                <tr>
                                    <td style="width:20%"></td>
                                    <td style="width:20%">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_DocumentType"
                                                name="documenttypeid" [filter]="true" formControlName="documenttypeid"
                                                filterBy="functionname" (onChange)="GetDocumentStatus($event)"
                                                optionLabel="functionname" optionValue="functionid">
                                            </p-dropdown>
                                            <label for="documenttypeid">Document Type <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_smsConfigurationform.get('documenttypeid').touched || _submitted) && _smsConfigurationform.get('documenttypeid').errors?.SelectDocumentType">
                                            Please select document type
                                        </span>
                                    </td>
                                    <td style="width:20%">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <p-dropdown [filter]="true" [autoDisplayFirst]="false"
                                                    [options]="_DocumentStatus" formControlName="documentstatusid"
                                                    optionLabel="documentstatusname" optionValue="documentstatusid">
                                                </p-dropdown>
                                                <label for="documentstatusid">Document Status <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                            <button class="p-inputgroup-addon p-button" [disabled]="isDisableaddElement"
                                                title="Add" type="button" (click)="showBasicDialog()">
                                                <i class="las la-plus"></i>
                                            </button>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_smsConfigurationform.get('documentstatusid').touched || _submitted) && _smsConfigurationform.get('documentstatusid').errors?.SelectDocumentType">
                                            Please select document Status
                                        </span>
                                    </td>
                                    <td style="width:20%">
                                        <span class="p-float-label">
                                            <input type="text" formControlName="emailtemplatename" pInputText>
                                            <label for="emailtemplatename">Template Name <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_smsConfigurationform.get('emailtemplatename').touched || _submitted) && _smsConfigurationform.get('emailtemplatename').errors?.SelectDocumentType">
                                            Please Enter Template Name
                                        </span>
                                    </td>
                                    <td style="width:20%"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <!-- <form [formGroup]="_smsConfigurationRefernceform" > -->
                                    <td>
                                        <form [formGroup]="_smsConfigurationRefernceform">
                                            <div class="p-inputgroup">
                                                <span class="p-float-label">
                                                    <p-dropdown [filter]="true" [autofocusFilter]="true"
                                                        [options]="_DataElement" [autoDisplayFirst]="false"
                                                        formControlName="elementdataemail" optionLabel="name"
                                                        optionValue="name">
                                                    </p-dropdown>
                                                    <label for="elementdata">Data Element <span
                                                            class="hlt-txt">*</span></label>
                                                </span>
                                                <button class="p-inputgroup-addon p-button"
                                                    [disabled]="isDisableaddElement" title="Add" type="button"
                                                    (click)="AddElementEmail()">
                                                    <i class="las la-plus"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </td>
                                    <!-- </form> -->
                                    <td>
                                        <form [formGroup]="_smsConfigurationRefernceform">
                                            <span class="p-float-label">
                                                <p-multiSelect [options]="" formControlName="recipientgroup"
                                                    optionLabel="name">
                                                </p-multiSelect>
                                                <label for="recipientgroup">SMS Recipient Group <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                        </form>
                                    </td>
                                    <td>
                                        <form [formGroup]="_smsConfigurationRefernceform">
                                            <span class="p-float-label">
                                                <p-multiSelect [options]="" formControlName="recipientlist"
                                                    optionLabel="name">
                                                </p-multiSelect>
                                                <label for="dropdown">SMS Recipient List <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                        </form>
                                    </td>

                                    <td></td>

                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan="3">
                                        <p-editor #editor [style]="{'height':'320px'}" formControlName="emailsubject">
                                            <p-header>
                                                <span class="ql-formats">
                                                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                                                    <button type="button" class="ql-italic"
                                                        aria-label="Italic"></button>
                                                    <button type="button" class="ql-underline"
                                                        aria-label="Underline"></button>
                                                </span>
                                            </p-header>
                                        </p-editor>

                                        <!-- <p-editor formControlName="emailsubject" [style]="{'height':'320px'}">
                                    <ng-template pTemplate="header">
                                        <span class="ql-format-group">
                                            <span title="Bold" class="ql-format-button ql-bold"></span>
                                            <span class="ql-format-separator"></span>
                                            <span title="Italic" class="ql-format-button ql-italic"></span>
                                            <span class="ql-format-separator"></span>
                                            <span title="Underline" class="ql-format-button ql-underline"></span>
                                            <span class="ql-format-separator"></span>
                                            <span title="Strikethrough" class="ql-format-button ql-strike"></span>
                                        </span>
                                    </ng-template>
                                </p-editor> -->

                                    </td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                        <p-dialog header="Document Status" [(visible)]="ManageDialog"
                        [style]="{width: '300px',height:'200px'}" [modal]="true" [maximizable]="false">
                        <ng-template pTemplate="content">

                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <form [formGroup]="_smsConfigurationStatusform">
                                            <span class="p-float-label">
                                                <input type="text" name="documentstatusname"
                                                    formControlName="documentstatusname" pInputText>

                                                <label for="documentstatusname">Document Status Name <span
                                                        class="hlt-txt">*</span></label>
                                            </span>

                                        </form>
                                    </td>
                                </tr>
                            </table>


                        </ng-template>
                        <ng-template pTemplate="footer">
                            <button pButton pRipple label="Quick Save" (click)="DocStatusSave()" icon="las la-check"
                                class="p-button-text"></button>
                            <button pButton pRipple label="Cancel" (click)="cancel()" icon="pi pi-times"
                                class="p-button-text"></button>
                        </ng-template>
                    </p-dialog>

                    </p-tabPanel>
		  <p-tabPanel header="NewsLetter Mail">
                        <form [formGroup]="_nlConfigurationform" (ngSubmit)="onSave()">
                            <p-toast></p-toast>
                            <table class="normal-table">
                                
                                <tr>
                                    <td></td>
                                   
                                    <td>
                                        <form [formGroup]="_nlConfigurationRefernceform">
                                            <span class="p-float-label">
                                                <p-multiSelect [options]="ecrecipientgroup" formControlName="recipientgroup"
                                                 optionValue="value"  [virtualScroll]="true" itemSize="30"  optionLabel="name" (onChange)="getTomail()">
                                                </p-multiSelect>
                                                <label for="recipientgroup">ECommerece Recipient Group <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                        </form>
                                    </td>
                                    <td>
                                        <form [formGroup]="_nlConfigurationRefernceform">
                                            <span class="p-float-label">
                                                <p-multiSelect [options]="ecrecipientlist" formControlName="recipientlist"
                                                [(ngModel)]="_selectedEmailIDs" selectedItemsLabel="{0} items selected" [virtualScroll]="true" itemSize="30" optionValue="mailid" optionLabel="mailid">
                                                </p-multiSelect>
                                                <label for="dropdown">ECommerece Recipient List <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                        </form>
                                    </td>

                                    <td></td>

                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan="3">
                                        <p-editor #editor formControlName="template" [style]="{'height':'320px'}">
                                        </p-editor>

                                  

                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="text-center">
                                        <p-button label="Send" icon="las la-save" type="button" (click)="onSend()"></p-button>
                                    </td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                        <p-dialog header="Document Status" [(visible)]="ManageDialog"
                        [style]="{width: '300px',height:'200px'}" [modal]="true" [maximizable]="false">
                        <ng-template pTemplate="content">

                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <form [formGroup]="_smsConfigurationStatusform">
                                            <span class="p-float-label">
                                                <input type="text" name="documentstatusname"
                                                    formControlName="documentstatusname" pInputText>

                                                <label for="documentstatusname">Document Status Name <span
                                                        class="hlt-txt">*</span></label>
                                            </span>

                                        </form>
                                    </td>
                                </tr>
                            </table>


                        </ng-template>
                        <ng-template pTemplate="footer">
                            <button pButton pRipple label="Quick Save" (click)="DocStatusSave()" icon="las la-check"
                                class="p-button-text"></button>
                            <button pButton pRipple label="Cancel" (click)="cancel()" icon="pi pi-times"
                                class="p-button-text"></button>
                        </ng-template>
                    </p-dialog>

                    </p-tabPanel>				 
                </p-tabView>
                <table>
                    <td>
                        <!-- Debug -->
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </table>
            </div>
        </div>
    </div>
</div>