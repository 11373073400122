import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-v-userscreenmapping',
  templateUrl: './v-userscreenmapping.component.html',
  styleUrls: ['./v-userscreenmapping.component.css']
})
export class VUserscreenmappingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
