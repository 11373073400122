import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class DeliveryChallanService {
  public _Action : IAction ;
  public  _DeliveryChallanService = environment.RptDeliveryChallanService;
  public _httpOptions;
  constructor(private http:HttpClient) { 
  }
  PageInitialize(branchid:number,fromdate:string,todate:string): Observable<any> {
    var Query = this._DeliveryChallanService+ '/PageInitialize/' + branchid+'/'+fromdate+'/'+todate ;
    return this.http.get<{data:any}>(Query);
  }
  GetOrderid(eorderno:string): Observable<any> {
    var Query = this._DeliveryChallanService+ '/Getorderid/' + eorderno;
    return this.http.get<{data:any}>(Query);
  }
  Print(deliverychallan:object) {
    var Query = this._DeliveryChallanService;
    return this.http.post(Query,deliverychallan, { responseType: 'blob' });
  }

  Print1(deliverychallan:object) {
    var Query = this._DeliveryChallanService;
    return this.http.post(Query,deliverychallan);
  }
}
