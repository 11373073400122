import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RptaccountledgerComponent } from './Accounting/rptaccountledger/rptaccountledger.component';
import { RptageingComponent } from './Accounting/rptageing/rptageing.component';
import { RptbalancesheetComponent } from './Accounting/rptbalancesheet/rptbalancesheet.component';
import { RptoutstandingComponent } from './Accounting/rptoutstanding/rptoutstanding.component';
import { RptpayablereceivableComponent } from './Accounting/rptpayablereceivable/rptpayablereceivable.component';
import { RptpaymentComponent } from './Accounting/rptpayment/rptpayment.component';
import { RptprofitlossComponent } from './Accounting/rptprofitloss/rptprofitloss.component';
import { RptreconcilationunreconcilationComponent } from './Accounting/rptreconcilationunreconcilation/rptreconcilationunreconcilation.component';
import { RptrevenueexpenseComponent } from './Accounting/rptrevenueexpense/rptrevenueexpense.component';
import { RptTellercashComponent } from './Accounting/rpt-tellercash/rpt-tellercash.component';
import { RpttrialbalancemonthComponent } from './Accounting/rpttrialbalancemonth/rpttrialbalancemonth.component';
import { RptvatinputoutputComponent } from './Accounting/rptvatinputoutput/rptvatinputoutput.component';
import { PurchaseordersummaryComponent } from './purchase/purchaseordersummary/purchaseordersummary.component';
import { PurchasepricerevisionComponent } from './purchase/purchasepricerevision/purchasepricerevision.component';
import { PurchasereturnComponent } from './purchase/purchasereturn/purchasereturn.component';
import { RptposummaryComponent } from './purchase/rptposummary/rptposummary.component';
import { RptpurchaseinwardComponent } from './purchase/rptpurchaseinward/rptpurchaseinward.component';
import { RptrewardpointstransactionComponent } from './Sales/rptrewardpointstransaction/rptrewardpointstransaction.component';
import { RptsalesComponent } from './Sales/rptsales/rptsales.component';
import { RptsalescommissionComponent } from './Sales/rptsalescommission/rptsalescommission.component';
import { RptsalesquotationComponent } from './Sales/rptsalesquotation/rptsalesquotation.component';
import { RptsalesreturnComponent } from './Sales/rptsalesreturn/rptsalesreturn.component';
import { GrnreportComponent } from './Stock/grnreport/grnreport.component';
import { RptbranchwisestockComponent } from './Stock/rptbranchwisestock/rptbranchwisestock.component';
import { RptdamagedproductComponent } from './Stock/rptdamagedproduct/rptdamagedproduct.component';
import { RptproductdeliveryComponent } from './Stock/rptproductdelivery/rptproductdelivery.component';
import { StocktransferComponent } from './Stock/stocktransfer/stocktransfer.component';
import { StockmovementComponent } from './StockMovement/stockmovement/stockmovement.component';
import { RptsalessummaryreportComponent } from './Sales/rptsalessummaryreport/rptsalessummaryreport.component';
import { DiscountreportComponent } from './Sales/discountreport/discountreport.component';
import { VatincludeexcludereportComponent } from './Sales/vatincludeexcludereport/vatincludeexcludereport.component';
import { ProductbarcodeprintComponent } from './productbarcodeprint/productbarcodeprint.component';
import { RptLockedquantitydetailreportComponent } from './Stock/rptLockedquantitydetailreport/rpt-lockedquantitydetailreport/rpt-lockedquantitydetailreport.component';
import { RptlockedquantitysummaryreportComponent } from './Stock/rptLockedquantitysummaryreport/rptlockedquantitysummaryreport/rptlockedquantitysummaryreport.component';
import { PurchaseorderdetailreportComponent } from './purchase/purchaseorderdetailreport/purchaseorderdetailreport.component';
import { RptstocksummaryComponent } from './Stock/rptstocksummary/rptstocksummary.component';
import { RptpurchaseinwardsummaryComponent } from './purchase/rptpurchaseinwardsummary/rptpurchaseinwardsummary.component';
import { RptpurchaseordersummaryComponent } from './purchase/rptpurchaseordersummary/rptpurchaseordersummary.component';
import { RptsalessummaryComponent } from './Sales/rptsalessummary/rptsalessummary.component';
import { StockvaluereportComponent } from './Stock/stockvaluereport/stockvaluereport.component';
import { RptsalesquotationsummaryComponent } from './Sales/rptsalesquotationsummary/rptsalesquotationsummary.component';
import { RptsalesprofitandlossComponent } from './Sales/rptsalesprofitandloss/rptsalesprofitandloss.component';
import { StockmissingComponent } from './Stock/StockMissing/stockmissing/stockmissing.component';
import { RptstockcountingComponent } from './Stock/rptstockcounting/rptstockcounting.component';
import { RptstockmovementsummaryComponent } from './Stock/rptstockmovementsummary/rptstockmovementsummary.component';
import { StockvaluationComponent } from './Stock/StockValuation/stockvaluation/stockvaluation.component';
import { RptconsignmentstockComponent } from './Stock/rptconsignmentstock/rptconsignmentstock.component';
import { RptproductpricehistoryComponent } from './rptproductpricehistory/rptproductpricehistory.component';
import { ProductpricereportComponent } from './productpricereport/productpricereport.component';
import { RptExpenseComponent } from './Accounting/rpt-expense/rpt-expense.component';
import { UserdiscountreportComponent } from './Sales/userdiscountreport/userdiscountreport.component';
import { MonthwisestockreportComponent } from './Stock/monthwisestockreport/monthwisestockreport.component';
import { RptlockedquantityComponent } from './Stock/rptlockedquantity/rptlockedquantity.component';
import { RptdeliveredNotdeliveredComponent } from './Stock/rptdelivered-notdelivered/rptdelivered-notdelivered.component';
import { RptCashbookComponent } from './Accounting/rpt-cashbook/rpt-cashbook.component';
import { RptcriticalstockComponent } from './Stock/rptcriticalstock/rptcriticalstock/rptcriticalstock.component';
import { ProductbarcodestickerprintComponent } from './productbarcodestickerprint/productbarcodestickerprint/productbarcodestickerprint.component';
import { RptpurchaseinvoiceComponent } from './purchase/rptpurchaseinvoice/rptpurchaseinvoice.component';
import { RptAccountsmonitorComponent } from './Accounting/rpt-accountsmonitor/rpt-accountsmonitor.component';
import { RptOutstandingcreditnoteComponent } from './Accounting/rpt-outstandingcreditnote/rpt-outstandingcreditnote.component';
import { RptLotexpiryageingComponent } from './Stock/rpt-lotexpiryageing/rpt-lotexpiryageing.component';
import { RptLotWiseStockComponent } from './Stock/rpt-lot-wise-stock/rpt-lot-wise-stock.component';
import { RptAssetValueReportComponent } from './Accounting/rpt-asset-value-report/rpt-asset-value-report.component';
import { RptStockadjsutmentreportComponent } from './Stock/rpt-stockadjsutmentreport/rpt-stockadjsutmentreport.component';
import { RptProductMarkupLevelComponent } from './rpt-product-markup-level/rpt-product-markup-level.component';
import { RptPurchaseVsSalesComponent } from './rpt-purchase-vs-sales/rpt-purchase-vs-sales.component';
import { MaterialConsumptionReportComponent } from './Stock/material-consumption-report/material-consumption-report.component';
import { RptsupplierwiseclosingstockComponent } from './Stock/rptsupplierwiseclosingstock/rptsupplierwiseclosingstock.component';
import { PendingApprovalStatusComponent } from './Stock/pending-approval-status/pending-approval-status.component';
import { RptClosingBalanceVerificationComponent } from './Accounting/rpt-closing-balance-verification/rpt-closing-balance-verification.component';
import { AccountsGroupWiseReportComponent } from './Accounting/accounts-group-wise-report/accounts-group-wise-report.component';
import { RptReceivablesPayablesComponent } from './Accounting/rpt-receivables-payables/rpt-receivables-payables.component';
import { RptAgeingDetailReportComponent } from './Accounting/rpt-ageing-detail-report/rpt-ageing-detail-report.component';
import { RptproductpricechangehistoryComponent } from './rptproductpricechangehistory/rptproductpricechangehistory.component';
import { RptSupplierDueComponent } from './Accounting/rpt-supplier-due/rpt-supplier-due/rpt-supplier-due.component';
import { RptbrandwisestockComponent } from './Stock/rptbrandwisestock/rptbrandwisestock.component';
import { RptInvoicedelaytimeComponent } from './Stock/rpt-invoicedelaytime/rpt-invoicedelaytime.component';
import { RptPendingDeliveryComponent } from './Stock/rpt-pending-delivery/rpt-pending-delivery.component';
import { RptMinusStockComponent } from './Stock/rpt-minusstock/rpt-minusstock.component';
import { OutstandingpurchaseorderreportComponent } from './purchase/outstandingpurchaseorderreport/outstandingpurchaseorderreport.component';
import { LaybuyreportComponent } from './Sales/laybuyreport/laybuyreport.component';
import { PurchasetrackingreportComponent } from './purchase/purchasetrackingreport/purchasetrackingreport.component';
const routes: Routes = [
  { path: 'Purchaseordersummaryreport', component: PurchaseordersummaryComponent },
  { path: 'PurchasePriceRevisionReport', component: PurchasepricerevisionComponent },
  { path: 'PurchaseReturnReport', component: PurchasereturnComponent },
  { path: 'StockTransferReport', component: StocktransferComponent },
  { path: 'GRNReport', component: GrnreportComponent },
  { path: 'DamagedProductReport', component: RptdamagedproductComponent },
  { path: 'ProductDeliveryReport', component: RptproductdeliveryComponent },
  { path: 'SalesQuotationReport', component: RptsalesquotationComponent },
  { path: 'SalesCommissionReport', component: RptsalescommissionComponent },
  { path: 'SalesReturnReport', component: RptsalesreturnComponent },
  { path: 'AccountLedgerReport', component: RptaccountledgerComponent },
  { path: 'BalanceSheetReport', component: RptbalancesheetComponent },
  { path: 'ProfitLossReport', component: RptprofitlossComponent },
  { path: 'RevenueExpenseReport', component: RptrevenueexpenseComponent },
  { path: 'OutstandingReport', component: RptoutstandingComponent },
  { path: 'PayableReceiableReport', component: RptpayablereceivableComponent },
  { path: 'PaymentReceiptReport', component: RptpaymentComponent },
  { path: 'ReconcilationUnreconcilationReport', component: RptreconcilationunreconcilationComponent },
  { path: 'VATInputOutputReport', component: RptvatinputoutputComponent },
  { path: 'BranchWiseStockReport', component: RptbranchwisestockComponent },
  { path: 'AgeingReport', component: RptageingComponent },
  { path: 'POSummaryReport', component: RptposummaryComponent },
  { path: 'PurchaseInwardReport', component: RptpurchaseinwardComponent },
  { path: 'RewardPointsReport', component: RptrewardpointstransactionComponent },
  { path: 'SalesReport', component: RptsalesComponent },
  { path: 'TrialBalancereport', component: RpttrialbalancemonthComponent },
  { path: 'TellerCashReport', component: RptTellercashComponent },
  { path: 'vStockMovement', component: StockmovementComponent },
  // SalesSummaryReport
  { path: 'vSalesSummaryReport', component: RptsalessummaryreportComponent },
  { path: 'vDiscountReport', component: DiscountreportComponent },
  { path: 'vVatIncludeExcludeReport', component: VatincludeexcludereportComponent },
  { path: 'ProductBarcodePrint', component: ProductbarcodeprintComponent },
  { path: 'vLockedQuantityDetailReport', component: RptLockedquantitydetailreportComponent },
  { path: 'vLockedQuantitySummaryReport', component: RptlockedquantitysummaryreportComponent },
  { path: 'vPurchaseorderdetailreport', component: PurchaseorderdetailreportComponent },
  { path: 'StockSummary', component: RptstocksummaryComponent },
  { path: 'RptPurchaseInwardSummaryReport', component: RptpurchaseinwardsummaryComponent },
  { path: 'RptPurchaseOrderSummaryReport', component: RptpurchaseordersummaryComponent },
  { path: 'RptSalesSummaryReport', component: RptsalessummaryComponent },
  { path: 'RptStockValueReport', component: StockvaluereportComponent },
  { path: 'RptSalesQuotationSummaryReport', component: RptsalesquotationsummaryComponent },
  { path: 'RptSalesProfitandLossReport', component: RptsalesprofitandlossComponent },
  { path: 'StockmissingReport', component: StockmissingComponent },
  { path: 'StockcountingReport', component: RptstockcountingComponent },
  { path: 'RptStockMovementSummary', component: RptstockmovementsummaryComponent },
  { path: 'StockValuation', component: StockvaluationComponent },
  { path: 'RptConsignmentStock', component: RptconsignmentstockComponent },
  { path: 'RptProductPriceHistory', component: RptproductpricehistoryComponent },
  { path: 'Productpricereport', component: ProductpricereportComponent },
  { path: 'Expenesereport', component: RptExpenseComponent },
  { path: 'userdiscountreport', component: UserdiscountreportComponent },
  { path: 'vMonthWiseStock', component: MonthwisestockreportComponent },
  { path: 'RptLockedQuantity', component: RptlockedquantityComponent },
  { path: 'RptDeliveredandNotDelivered', component: RptdeliveredNotdeliveredComponent },
  { path: 'RptCashBook', component: RptCashbookComponent },
  { path: 'vcriticalstock', component: RptcriticalstockComponent },
  { path: 'ProductBarcodeStickerPrint', component: ProductbarcodestickerprintComponent },
  { path: 'RptPurchaseInvoice', component: RptpurchaseinvoiceComponent },
  { path: 'RptAccountsMonitor', component: RptAccountsmonitorComponent },
  { path: 'OutStandingCreditNoteReport', component: RptOutstandingcreditnoteComponent },
  { path: 'RptLotexpiryageing', component: RptLotexpiryageingComponent },
  { path: 'RptLotWiseStock', component: RptLotWiseStockComponent },
  { path: 'VRptAssetValueReport', component: RptAssetValueReportComponent },
  { path: 'VRptStockAdjustmentReport', component: RptStockadjsutmentreportComponent },
  { path: 'VRptProductMarkupLevel', component: RptProductMarkupLevelComponent },
  { path: 'VRptPurchaseVsSales', component: RptPurchaseVsSalesComponent },
  { path: 'VMaterialConsumptionReport', component: MaterialConsumptionReportComponent },
  { path: 'RptSupplierWiseClosingStock', component: RptsupplierwiseclosingstockComponent },
  { path: 'RptPendingApprovalStatus', component: PendingApprovalStatusComponent },
  { path: 'RptClosingBalanceVerification', component: RptClosingBalanceVerificationComponent },
  { path: 'RptAccountsGroupWiseReport', component: AccountsGroupWiseReportComponent },
  { path: 'RptReceivablesPayablesPartyWise', component: RptReceivablesPayablesComponent },
  { path: 'RptAgeingDetailReportComponent', component: RptAgeingDetailReportComponent },
  { path: 'RptProductPriceChangeHistory', component: RptproductpricechangehistoryComponent },
  { path: 'RptSupplierDue', component: RptSupplierDueComponent },
  { path: 'Rptbrandwisestock', component: RptbrandwisestockComponent },
  { path: 'RptInvoicedelaytime', component: RptInvoicedelaytimeComponent },
  { path: 'Rptpendingdelivery', component: RptPendingDeliveryComponent },
  { path: 'Rptminusstock', component: RptMinusStockComponent},
  { path: 'Rptoutstandingpurchaseorder', component: OutstandingpurchaseorderreportComponent},
  { path: 'Laybuyreport', component: LaybuyreportComponent},
  { path: 'Purchasetrackingreport', component: PurchasetrackingreportComponent }

  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
