import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class SwipeService {
  public _Action: IAction;
  public swipeService = environment.swipeService;
  public posService = environment.SalesinvoiceposService;
  public _salescardapi = environment.salescardintegrationapi;

  constructor(private http: HttpClient) { }

  PageOnLoad(value:any): Observable<any> {
    var Query = this.swipeService + '/PageOnLoad';
    return this.http.post<{ data: any }>(Query,value);
  }

  GetSalesInvoiceData(value:any) {
    var Query = this.swipeService + '/GetSalesInvoiceData';
    return this.http.post<{ data: any }>(Query,value);
  }

  CreateOrUpdate(value:any) {
    var Query = this.swipeService + '/CreateOrUpdate'
    return this.http.post<{ data: any }>(Query,value);
  }

  GetPaymentApiDetails(obj: object) {
    var Query = this._salescardapi;
    return this.http.post<{ data: any }>(Query, obj);
  }

  UpdatePaymentStatus(obj: object) {
    var Query = this.posService + '/UpdatePaymentStatus';
    return this.http.post<{ data: any }>(Query, obj);
  }

}
