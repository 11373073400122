import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
@Injectable({
	providedIn: 'root'
})
export class ContraService {
	public _ContraService = environment.ContraService;
	public _httpOptions;
	token: any;
	header: any;
  
	constructor(private http: HttpClient) {
		this.token = sessionStorage['AuthToken'];
		this.header = this.createHeaders(this.token)	
	 }
	 createHeaders(token: any): HttpHeaders {
		return new HttpHeaders({
		  'Content-Type': 'application/json',
		  Authorization: `Bearer ${token}`, // Include your token here	
		});
	  }
	PageOnLoad(contraid: number, userid: number, voucherno: string): Observable<any> {
		const headers = this.header;
		var Query = this._ContraService + '/' + contraid + '/' + userid + '/' + voucherno;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	FetchAll(fromdate: string, todate: string, userid: number, roleid: number) {
		const headers = this.header;
		var Query = this._ContraService + '/FetchAll' + '/' + fromdate + '/' + todate + '/' + userid + '/' + roleid;
		return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
			const resultJSON = JSON.parse(JSON.stringify(data));
			return data;
		}));
	}
	create(p_Contra: object) {
		const headers = this.header;
		var Query = this._ContraService;
		return this.http.post<{ data: any }>(Query, p_Contra, { headers });
	}
	Cancel(contraid: number, modifiedby: number): Observable<any> {
		const headers = this.header;
		var Query = this._ContraService + '/Cancel' + '/' + contraid + '/' + modifiedby;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetClosingBalance(obj: object) {
		const headers = this.header;
		var Query = this._ContraService + '/GetClosingBalance';
		return this.http.post<{ data: any }>(Query, obj, { headers });
	}
	SendToApproval(obj: object): Observable<any> {
		const headers = this.header;
		var Query = this._ContraService + '/Approval';
		return this.http.post(Query, obj, { headers });
	}
	ContraPrint(obj: any) {
		const headers = this.header;
		var Query = this._ContraService + '/ContraPrint';
		return this.http.post(Query, obj, { responseType: 'blob' , headers });
	}
}
