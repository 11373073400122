import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Salesreturndetailview } from 'src/app/core/Models/SalesReturn';
import { SalesquotationService } from 'src/app/core/Services/sales/salesquotation.service';
import { SalesreturnService } from 'src/app/core/Services/sales/salesreturn.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Salesquotationview } from 'src/app/core/Views/Salesquotationview';
import { Salesreturnview } from 'src/app/core/Views/Salesreturnview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-v-salesreturn',
  templateUrl: './v-salesreturn.component.html',
  styleUrls: ['./v-salesreturn.component.css']
})
export class VSalesreturnComponent implements OnInit {
  _salesreturndetails: Salesreturnview[];
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _Print: Boolean;
  _userid = sessionStorage["userid"];
  _branchid = parseInt(sessionStorage["BranchID"]);
  _roleid = sessionStorage["currentRole"];
  _IsProgressSpinner: boolean = true;
  cols: any[];
  _salesreturnno: string;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  mindate: Date;
  maxdate: Date;
  _Branch: any[] = [];
  _Branchname: number;
  _Branchdrop: boolean = true;

  constructor(private fb: FormBuilder, private _SalesReturnService: SalesreturnService,
    private _CustomExceptionService: CustomExceptionService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService, public exportUtility: ExportUtility, private confirmationService: ConfirmationService) {


  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(57);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this._Branchname = this._branchid;
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);

    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }

    this.cols = [
      { field: 'salesreturnno', header: 'Sales Return No' },
      { field: 'voucherno', header: 'Credit Note No.' },
      { field: 'salesreturndate', header: 'Sales Return Date' },
      { field: 'salesinvoicedate', header: 'Sales Invoice Date' },
      { field: 'customername', header: 'Customer Name' },
      { field: 'salesinvoiceno', header: 'Sales Invoice No' },
      // { field: 'returnquantity', header: 'Return Quantity' },
      { field: 'statusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this.Fetchall();
    if (this._roleid != 1)
    {
      this._Branchdrop = true;
    }
    else
    {
      this._Branchdrop = false;
    }


  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    if (this._roleid != 1)
    {
      this._branchid = sessionStorage["currentbranchid"];
    }
    else
    {
      this._branchid = this._Branchname;
    }
    this._IsProgressSpinner = true;
    this._SalesReturnService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._salesreturndetails = resultJSON.salesreturnviews;
      this._Branch = resultJSON.srbranch;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  view(event) {
    this.router.navigate(['/cSalesreturn'], { state: { salesreturnno: event.salesreturnno, action: 'view' } });
  }

  GoToCreate(event) {
    this.router.navigate(['/cSalesreturn']);
  }
  exportExcel() {
    let SalesReturnList = this.GetSalesReturnList();
    this.exportUtility.exportExcel(SalesReturnList, 'SalesReturn', 'xlsx');
  }

  ExportToPdf() {
    let SalesReturnList = this.GetSalesReturnList();
    this.exportUtility.ExportToPdf(SalesReturnList, 'SalesReturn.pdf');
  }

  GetSalesReturnList() {
    if (!this._salesreturndetails || !this._salesreturndetails.length)
      return;
    let SalesReturnList = [];
    this._salesreturndetails.forEach(element => {
      let salesreturn: any = {};
      salesreturn["Sales Return No"] = element.salesreturnno;
      salesreturn["Credit Note No"] = element.voucherno;
      salesreturn["Sales Return Date"] = element.salesreturndate;
      salesreturn["Sales Invoice Date"] = element.salesinvoicedate;
      salesreturn["Customer Name"] = element.customername;
      salesreturn["Sales Invoice No"] = element.salesinvoiceno;
      // salesreturn["Return Quantity"] = element.returnquantity;
      salesreturn["Status"] = element.statusname;
      salesreturn["Created By"] = element.createdbyname;
      salesreturn["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      SalesReturnList.push(salesreturn);
    });
    return SalesReturnList;
  }
  edit(event) {
    if (event.status == "MSC00045") {
      this.router.navigate(['/cSalesreturn'], { state: { salesreturnno: event.salesreturnno, action: 'edit' } });
    }
    else {
      this._CustomExceptionService.handleWarning("Document Send For Approval!");
    }
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Sales Return <b>"' + event.salesreturnno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._salesreturnno = event.salesreturnno;
    this._IsProgressSpinner = true;
    this._SalesReturnService.Cancel(this._userid, event.salesreturnno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.SalesReturnCancel);
      this._IsProgressSpinner = false;
      this.Fetchall();

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  // Print(event) {
  //   var salesreturnno = event.salesreturnno;
  //   var branchid = sessionStorage["currentbranchid"];
  //   this._IsProgressSpinner = true;
  //   //var mediaType = 'application/pdf';
  //   this._SalesReturnService.Print(salesreturnno, branchid);
  //   this._IsProgressSpinner = false;
  // }
  Print(event) {
    var salesreturnno = event.salesreturnno;
    var branchid = sessionStorage["currentbranchid"];
    this._IsProgressSpinner = true;
    //var mediaType = 'application/pdf';
    this._SalesReturnService.Print({ "srno": salesreturnno, "branchid": parseInt(branchid) }).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}



