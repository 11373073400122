import { SafeUrl } from "@angular/platform-browser";

export class Laybuydocumentdetail {
    documentdetailid: number;
    laybuyid: number;
    documentid: number;
    documentname: string;
    documentdescription: string;
    attachmenturl: SafeUrl;
    attachmentname: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}