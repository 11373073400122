import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
// import autoTable from 'jspdf-autotable';
import { element } from 'protractor';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { User } from 'src/app/core/Models/User';
import { RptcashbookService } from 'src/app/core/Services/reports/Accounting/RptCashBook/rptcashbook.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-rpt-cashbook',
  templateUrl: './rpt-cashbook.component.html',
  styleUrls: ['./rpt-cashbook.component.css'],
})
export class RptCashbookComponent implements OnInit {
  userids: number[] = [];
  _src: SafeResourceUrl;
  _IsProgressSpinner: boolean = false;
  cashbookobj: any;
  _Users: User[];
  _Userid = sessionStorage['userid'];
  _CashBookViews: any[];
  selectedrows: any;
  cols: string[];
  colsvalues: string[];
  _statuslist: Metadatum[];
  status: string = '';
  _Date: Date = new Date();
  mindate: Date;
  maxdate: Date;
  _tempuser: any[];

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptCashBookService: RptcashbookService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer,
    private exportUtility: ExportUtility
  ) {}

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;
    this.status = 'MSC00001';
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);

    if (sessionStorage['Environmentenddate'] == 'null') {
      this._Date = new Date();
      this.maxdate = new Date();
    } else {
      this._Date = new Date(sessionStorage['Environmentenddate']);
    }

    this._RptCashBookService.PageOnload(this._Userid).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));

        this._statuslist = resultJSON.statuslist;
        
        this._Users = resultJSON.userlst;
        this._tempuser = resultJSON.userlst;
        this._IsProgressSpinner = false;
        this.getUsers();
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  search() {
    this.userids = this.userids || [];
    this._CashBookViews = [];
    if (!this.userids.length) {
      this._CustomExceptionService.handleWarning('Select atleast one user.');
      return;
    }

    this._IsProgressSpinner = true;
    var datePipe = new DatePipe('en-US');
    var date = datePipe.transform(this._Date, 'yyyy-MM-dd');

    this.cashbookobj = {
      userid: this.userids || [],
      Loginuserid: this._Userid,
      status: this.status,
      Date: date,
    };
    this._RptCashBookService.SearchCashBook(this.cashbookobj).subscribe(
      (result) => {
        this._IsProgressSpinner = false;
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this._CashBookViews = resultJSON.cashbooklst || [];

        if (!this._CashBookViews.length) {
          this._CustomExceptionService.handleWarning(
            'No data available to show.'
          );
          return;
        }

        var cbcol = this._CashBookViews[0];
        let cols = ['PARTYCODE', 'LEDGERID'];
        this.cols = Object.keys(cbcol);
        this.cols = this.cols.filter((f) => !cols.includes(f));
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  exportPdf() {
    if (!this._CashBookViews || !this._CashBookViews.length) return;
    let CashBooKList = this.GetCashBooKList();
    this.exportUtility.ExportToPdf(CashBooKList, 'CashBook.pdf');
  }
  GetCashBooKList() {
    if (!this._CashBookViews || !this._CashBookViews.length) return;
    let CashBooKList = [];

    let cols = ['PARTYCODE', 'LEDGERID'];
    this.cols = Object.keys(this._CashBookViews[0]);
    this.cols = this.cols.filter((f) => !cols.includes(f));

    this._CashBookViews.forEach((element1) => {
      let cashbook: any = {};
      this.cols.forEach((element2) => {
        cashbook[element2] = element1[element2];
      });
      CashBooKList.push(cashbook);
    });
    return CashBooKList;
  }

  exportExcel() {
    let CashBooKList = this.GetCashBooKList();
    this.exportUtility.exportExcel(CashBooKList, 'CashBook', 'xlsx');
  }

  clear() {
    this.status = 'MSC00001';
    this._CashBookViews = [] = [];
    this.userids = [] = [];
    this._Date = new Date();
  }

  getUsers() {
    this._Users = this._tempuser.filter(s => s.userstatus == this.status)
  }
}
