<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Shipping Line</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" (click)="GoToCreate($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>


                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            </ng-template>

                        </p-toolbar>
                        <p-table #dt [value]="_ShippingLineViews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['shippinglinecode', 'shippinglinename','statusname', 'createdbyname','createdon','modifiedbyname','modifiedon']"
                            [rowHover]="true" dataKey="shippinglinecode" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="text-center" style="width:120px;">Action
                                    </th>


                                    <th pSortableColumn="shippinglinecode">Shipping Line Code
                                        <p-sortIcon field="shippinglinecode">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="shippinglinename">Shipping Line Name
                                        <p-sortIcon field="shippinglinename">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="statusname">Status
                                        <p-sortIcon field="statusname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdbyname">Created By
                                        <p-sortIcon field="createdbyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdon">Created On
                                        <p-sortIcon field="createdon">
                                        </p-sortIcon>
                                        <th pSortableColumn="modifiedbyname">Modified By
                                            <p-sortIcon field="modifiedbyname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="modifiedon">Modified On
                                            <p-sortIcon field="modifiedon">
                                            </p-sortIcon>
                                        </th>
                                </tr>

                            </ng-template>
                            <ng-template pTemplate="body" let-_shippinglineview>
                                <tr>
                                    <td class="text-center">
                                        <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2" [disabled]="!_Action._View" (click)="view(_shippinglineview)"></button>
                                        <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update" (click)="edit(_shippinglineview)"></button>
                                        <button pButton pRipple icon="pi pi-times" title="Delete" class="p-button p-button-danger" (click)="confirm(_shippinglineview)" [disabled]="_shippinglineview.status == 'MSC00002' || !_Action._Delete"></button>

                                    </td>
                                    <td>
                                        {{_shippinglineview.shippinglinecode}}
                                    </td>
                                    <td>
                                        {{_shippinglineview.shippinglinename}}
                                    </td>
                                    <td>
                                        {{_shippinglineview.statusname}}
                                    </td>
                                    <td>
                                        {{_shippinglineview.createdbyname}}
                                    </td>
                                    <td>
                                        {{_shippinglineview.createdon |date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_shippinglineview.modifiedbyname}}
                                    </td>
                                    <td>
                                        {{_shippinglineview.modifiedon |date:'dd/MM/yyyy'}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </form>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>