import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Accountgroup } from 'src/app/core/Models/Accountgroup';
import { Accounttype } from 'src/app/core/Models/Accounttype';
import { vAccountGroupName, vAccountTypeNameSelect, vParentGroupSelect } from 'src/app/core/Validators/validation';
//Debug
import * as ace from "ace-builds";
import { MessageService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { AccountgroupService } from 'src/app/core/Services/masters/accountgroup.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
@Component({
  selector: 'app-c-account-group',
  templateUrl: './c-account-group.component.html',
  styleUrls: ['./c-account-group.component.css']
})
export class CAccountGroupComponent implements OnInit {
  _isSaveHide: boolean = false;
  _isClearHide: boolean = false;
  _accountgroupform: FormGroup;
  _accountgroupstatus: Metadatum;
  _parentgroup: Accountgroup;
  _accountgroupid: any;
  _submitted = false;
  _action: string = "create";
  _AccountGroup: Accountgroup;
  // isDisabledstatus = true;
  _IsProgressSpinner: boolean = true;
  //Debug
  @ViewChild("editor",{static:true}) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, private _AccountGroupService: AccountgroupService,private messageService: MessageService,private _CustomExceptionService: CustomExceptionService,private _hotkeysService: HotkeysService,) 
  {
  }
  InitializeFormGroup() : void {
    this._accountgroupform = this.fb.group({
      companyid: [0],
      accountgroupid:  [0],
      accountgroupname: ['',vAccountGroupName],
      accountparentgroupid:['',vParentGroupSelect],
      accountgroupstatus:  ['MSC00001'],
      accountingyear: [],
      createdby:  [],
      createdon:  [],
      modifiedby:  [],
      modifiedon:  [],
      issystemgenerated: [0]
    });
  }


  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeFormGroup();

    this._accountgroupid = history.state?.accountgroupid ? history.state?.accountgroupid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
    this._isSaveHide = (this._action == 'view');
    this._isClearHide = (this._action == 'view' || this._action == 'edit');

    if( this._action == 'view' ){
      this._accountgroupform.disable();
    }
    this._IsProgressSpinner = true;
    this._AccountGroupService.PageOnLoad(this._accountgroupid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accountgroupstatus = resultJSON.status;
     // 5. Parent group load from Account Group master, it contains group name and code
      this._parentgroup = resultJSON.parentgroups;
      this._IsProgressSpinner = false;
     //  4. "Account Type" should load from account type master, it contains Account Type code, and Name 
      //For Update
      if (this._action == 'view') {
          const updateJSON = JSON.parse(JSON.stringify(result));
          this._accountgroupform.setValue(updateJSON.accountgroup);
      }
    },  
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  //Save
  onSave() {
   // console.log("Submitted");
    //console.log(this._accountgroupform);
    //console.log(this._action);
    this._submitted = true;
    //console.log(this._accountgroupform.value);
 // 11. if all the data is valid allow to save the record in the database
    if (this._accountgroupform.valid) {
      this._AccountGroup = this._accountgroupform.value;
      //console.log(this._AccountGroup);
      //Debug
     
      if (this._action == 'create') {
        //this._AccountGroup.accountgroupcode=0;        
        //console.log("YEs");
        this._AccountGroup.createdon=new Date();
        this._AccountGroup.createdby = sessionStorage["userid"];
        //this.onEd();
        this._IsProgressSpinner = true;
        this._AccountGroupService.create(this._AccountGroup).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true &&  (resultJSON!=null || resultJSON!=undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._parentgroup = resultJSON.accountgroups;
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },

          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        //console.log(this._AccountGroup);
        this._AccountGroup.modifiedon=new Date();
        this._AccountGroup.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._AccountGroupService.edit(this._AccountGroup).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true &&  (resultJSON!=null || resultJSON!=undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else{
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
 // 13.All entered input fields should be cleared.
  reset(event) {
    this._accountgroupform.reset();
    this.InitializeFormGroup();
  }
// 17.on clicking search button , the data must be filtered and display in the grid
  goBack(event) {
    //alert('This');
    // console.log("goBack");
    this._router.navigate(['/vAccountGroup']);
  }
  //Debug
  onEd()
  {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._AccountGroup;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);

  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}


