import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { SalesinvoiceposService } from 'src/app/core/Services/sales/salesinvoicepos.service';
import { SalesinvoicetaxService } from 'src/app/core/Services/sales/salesinvoicetax.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Salesinvoiceview } from 'src/app/core/Views/Salesinvoiceposview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { HotKeyIntegration } from 'src/assets/js/hot-key-integration';
@Component({
	selector: 'app-v-quick-sales-invoice',
	templateUrl: './v-quick-sales-invoice.component.html',
	styleUrls: ['./v-quick-sales-invoice.component.css']
})
export class VQuickSalesInvoiceComponent implements OnInit {
	selectedrows: any;
	pSelectableRow: any;
	_Add: Boolean;
	_Delete: Boolean;
	_Update: Boolean;
	_View: Boolean;
	_roleid: number;
	_Approve: Boolean;
	_Print: Boolean;
	_Series: Boolean;
	_PriceHistory: Boolean;
	_Profit: Boolean;
	_Action: IAction
	_IsProgressSpinner: boolean = false;
	_Saleinvoiceposviews: Salesinvoiceview[];
	_Saleinvoiceposview: Salesinvoiceview;
	_userid = sessionStorage["userid"];
	_branchid = sessionStorage["currentbranchid"]
	cols: any[];
	fromdate: Date;
	todate: Date;
	datepipe = new DatePipe('en-US');
	mindate: Date;
	maxdate: Date;
	
	constructor(
		private _SalesinvoiceposService: SalesinvoiceposService,
		public exportUtility: ExportUtility,
		private router: Router,
		private utility: CommonUtilities,
		private confirmationService: ConfirmationService,
		private _CustomExceptionService: CustomExceptionService,
		private _AccessRightsService: AccessRightsService,
		private _hotkeysService: HotKeyIntegration<void>,
		private _SalesInvoiceTaxService: SalesinvoicetaxService
	) { }
	ngOnInit(): void {
		this.utility.pageLoadScript();
		this._Action = this._AccessRightsService.getAccessRights(348);
		this._roleid = parseInt(sessionStorage["currentRole"]);
		this._hotkeysService.Navigate(event => {
			this.GoToCreate(event);
		}, this._Action, 'right');
		this.SetFromToDate();
		this.FetchAll();
		this.cols = [
			{ field: 'salesinvoiceno', header: 'Sales Invoice No' },
			{ field: 'salesinvoicedate', header: 'Sales Invoice Date' },
			{ field: 'customername', header: 'Customer Name' },
			{ field: 'salesinvoiceamount', header: 'Sales Invoice Amount' },
			{ field: 'statusname', header: 'Status' }
		]
	}
	SetFromToDate() {
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
	
		if (sessionStorage['Environmentenddate'] == "null") {
		  let date = new Date();
		  date.setDate(date.getDate() - 1);
		  this.fromdate = date;
		  this.todate = new Date();
		  this.maxdate = new Date();
		}
		else {
		  let date = new Date(sessionStorage['Environmentenddate']);
		  date.setDate(date.getDate() - 1);
		  this.fromdate = date;
		  this.todate = new Date(sessionStorage['Environmentenddate']);
		}
	  }
	FetchAll() {
		let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
		let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
		if (!fromdate || !todate) {
			this._CustomExceptionService.handleWarning("Please Select From & To Date")
			return
		}
		this._Saleinvoiceposviews = [];
		this._IsProgressSpinner = true;
		this._SalesinvoiceposService.FetchAll(this._branchid, this._userid, fromdate, todate, this._roleid, "Quick")
			.subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._Saleinvoiceposviews = resultJSON.salesinvoiceviews;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	edit(event) {
		this.router.navigate(['/CQuickSalesInvoice'], { state: { salesinvoiceno: event.salesinvoiceno, series: event.series, action: 'edit' } });
	}
	view(event) {
		this.router.navigate(['/CQuickSalesInvoice'], { state: { salesinvoiceno: event.salesinvoiceno, series: event.series, action: 'view' } });
	}
	exportExcel() {
		let SalesInvoicePOSList = this.GetSalesInvoicePOSList();
		this.exportUtility.exportExcel(SalesInvoicePOSList, 'Quick_Sales_Invoice', 'xlsx');
	}
	ExportToPdf() {
		if (!this._Saleinvoiceposviews || !this._Saleinvoiceposviews.length)
			return;
		let SalesInvoicePOSList = this.GetSalesInvoicePOSList();
		const data = SalesInvoicePOSList.map(Object.values);
		const head = [[
			'Sales Invoice No',
			'Sales Invoice Date',
			'Customer Name',
			'Currency',
			'Sales Invoice Amount',
			'Status',
			'Payment Status',
			'Created by']]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					},
					headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					},
					bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('Quick_Sales_Invoice.pdf');
			})
		})
	}
	GetSalesInvoicePOSList() {
		if (!this._Saleinvoiceposviews || !this._Saleinvoiceposviews.length)
			return;
		let SalesInvoicePOSList = [];
		this._Saleinvoiceposviews.forEach(element => {
			let salesinvoicepos: any = {};
			salesinvoicepos["Sales Invoice No"] = element.salesinvoiceno;
			salesinvoicepos["Sales Invoice Date"] = this.datepipe.transform(element.salesinvoicedate, 'dd/MM/yyyy');
			salesinvoicepos["Customer Name"] = element.customername;
			salesinvoicepos["Currency"] = element.currencyname;
			salesinvoicepos["Sales Invoice Amount"] = element.salesinvoiceamount;
			salesinvoicepos["Status"] = element.statusname;
			salesinvoicepos["Payment Status"] = element.ispaymentmadedtext;
			salesinvoicepos["Created By"] = element.username;

			SalesInvoicePOSList.push(salesinvoicepos);
		});
		return SalesInvoicePOSList;
	}
	Cancel(event) {
		let salesinvoiceno = event.salesinvoiceno;
		this._IsProgressSpinner = true;
		this._SalesinvoiceposService.Cancel(salesinvoiceno, this._branchid, this._userid).subscribe((response: any) => {
			if (response.tranStatus && response.tranStatus.result) {
				this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
				this.FetchAll();
			}
			else {
				if (response.tranStatus.lstErrorItem && response.tranStatus.lstErrorItem.length) {
					this._CustomExceptionService.handleError(response.tranStatus.lstErrorItem[0].message)
				}
				else {
					this._CustomExceptionService.handleError("Error Occurred.")
				}
			}
			this._IsProgressSpinner = false;
		});
	}
	confirm(event) {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to cancel this Invoice <b>"' + event.salesinvoiceno + '"</b> ?',
			accept: () => { //Actual logic to perform a confirmation
				this.Cancel(event);
			}
		});
	}
	GoToCreate(event) {
		this.router.navigate(['/CQuickSalesInvoice']);
	}
	RePrint(event) {
		let userid = sessionStorage['userid'];
		var Url = window.open("pos-printer-service:$" + event.salesinvoiceid + "$" + event.salesinvoiceno + "$" + userid);
		setTimeout(() => {
			Url.close();
		}, 10000);
	}
	Print(event) {
		var salesinvoiceno = event.salesinvoiceno;
		this._IsProgressSpinner = true;
		this._SalesInvoiceTaxService.Print({ "sino": salesinvoiceno, "branchid": this._branchid }).subscribe((result: any) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			let url: any = URL.createObjectURL(result);
			window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
			this._IsProgressSpinner = false;
		}, error => {
			this._CustomExceptionService.handleError("PRICE MISMATCH PLEASE CONTACT SUPER ADMIN PERSON");
			this._IsProgressSpinner = false;
		});
	}
	RedirectToMultiSwipe(value: any) {
		this.router.navigate(['/salesMultiSwipe'], {
			state:
			{
				branchid: this._branchid,
				salesinvoiceno: value.salesinvoiceno
			}
		});
	}
}