export class Onlinestockdeliverydetail {
    stockdeliverydetailid: number;
    stockdeliveryid: number;
    eorderid: string;
    eorderno: string;
    salesinvoiceid: number;
    salesinvoiceno: string;
    customerid: number;
    collectingbranchid: number ;
    tellerid: number;
    productid: number;
    variantid: number ;
    uomcode: string;
    invoicequantity: number;
    deliveryquantity: number;
    status: string;
    ispodverified: boolean ;
    remarks: string;
    createdby: number;
    createdon: Date  ;
    IsCollectingBranch:boolean;
    currentstock:number;
}