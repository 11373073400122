<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
          </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Reward</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" class="" type="submit"
                            [disabled]="isDisabledsave"         (click)="onSave()"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button"
                                [disabled]="isDisabledsendapparoval" (click)="SendToApproval($event)"></button>
                            <button pButton pRipple label="" [disabled]="isDisablereset" (click)="reset($event)"
                            title="Clear" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Reward Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-table #dt [value]="_RewardDetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="[]" [rowHover]="true" dataKey=""
                        [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)"
                        (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px;text-align:center">Action</th>
                                <th>Minimum Amount in USD</th>
                                <th>Maximum Amount in USD</th>
                                <th>Applicable Reward Points</th>
                                <th>Reward Value in USD/Points</th>
                                <th>Effective From</th>
                                <th>Effective To</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Reward let-i="rowIndex">
                            <tr>
                                <td style="width: 50px" style="text-align:center">
                                    <button pButton pRipple label="" title="Add" icon="pi pi-plus"
                                    [disabled]="isDisabledadd"     class="p-button-success p-mr-2" (click)="addReward($event)"></button>
                                    <button pButton pRipple label="" title="Remove" icon="pi pi-trash"
                                    [disabled]="isDisabledremove"    class="p-button-danger p-mr-2" (click)="RemoveReward(_Reward)"></button>

                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number"  [disabled]="isDisabledminimumamount" pInputText [(ngModel)]="_Reward.tempminimumamount"
                                            (focusout)="checkDuplicate($event,'tempminimumamount',i)" [disabled]="MinimumRange">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Reward.tempminimumamount}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number"  [disabled]="isDisabledmaximumamount" pInputText [(ngModel)]="_Reward.tempmaximumamount"
                                            (focusout)="checkDuplicate($event,'tempmaximumamount',i)">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Reward.tempmaximumamount}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number"  [disabled]="isDisabledapplicablerewardpoints" pInputText [(ngModel)]="_Reward.tempapplicablerewardpoints"
                                            >
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Reward.tempapplicablerewardpoints}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number"  [disabled]="isdisabledrewardsvalue" pInputText [(ngModel)]="_Reward.temprewardsvalue"
                                            >
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Reward.temprewardsvalue}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [(ngModel)]="_Reward.effectivefrom"  [disabled]="isDisabledeffectivefrom" [showIcon]="false"
                                                [minDate]="minDate">
                                            </p-calendar>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Reward.effectivefrom | date:'dd/MM/yyyy'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [(ngModel)]="_Reward.effectiveto"  [disabled]="isDisabledeffectiveto" [showIcon]="false"
                                                [minDate]="_Reward.effectivefrom ||minDate">
                                            </p-calendar>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Reward.effectiveto | date:'dd/MM/yyyy'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table class="normal-table">
                    <tr>
                        <td>                       
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                        
                    </tr>
                </table>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            <p-toast position="bottom-right"></p-toast>
        </div>
      
    </div>
</div>