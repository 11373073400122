export class Reward {
    rewardid: number;
    minimumamount: number;
    maximumamount: number;
    applicablerewardpoints: number;
    rewardsvalue: number;
    tempminimumamount: number;
    tempmaximumamount: number;
    tempapplicablerewardpoints: number;
    temprewardsvalue: number;
    effectivefrom: Date;
    effectiveto: Date;
    rewardstatus: string;
    accountingyear: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}