<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Account Monitor Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar [showIcon]="true" dateFormat="dd/mm/yy" [(ngModel)]="date"
                                    [monthNavigator]="true" [yearNavigator]="true"  [minDate]="mindate" [maxDate]="maxdate"
                                    yearRange="2021:2050">
                                </p-calendar>
                                <label for="date">From Date</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                        </td>
                        <td style="width: 20%;">
                        </td>
                        <td style="width: 20%;">
                        </td>
                        <td style="width: 20%;">
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Search" type="button" (click)="search()" title="Search" icon="pi pi-search"></button>
                            <button pButton pRipple label="Clear" title="Clear" (click)="clear()" icon="pi pi-times" class="p-button-danger"></button>
                        </td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="exportPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_accountlist" [columns]="cols" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['CURRENCYNAME','CONTRA','JOURNAL']" [rowHover]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="text-center" pSortableColumn="CURRENCYNAME">Currency Name
                                    <p-sortIcon field="CURRENCYNAME"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="PURCHASE">Purchase
                                    <p-sortIcon field="PURCHASE"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="DEBITNOTE">Debit Note
                                    <p-sortIcon field="DEBITNOTE"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="SALES">Sales
                                    <p-sortIcon field="SALES"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="RECEIPT">Receipt
                                    <p-sortIcon field="RECEIPT"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="CREDITNOTE">Credit Note
                                    <p-sortIcon field="CREDITNOTE"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="JOURNAL">Journal
                                    <p-sortIcon field="JOURNAL"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="PAYMENT">Payment
                                    <p-sortIcon field="PAYMENT"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="NETAMOUNT">Net Cash
                                    <p-sortIcon field="NETAMOUNT"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="TELLERCASH">Teller cash
                                    <p-sortIcon field="TELLERCASH"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="TELLERBALCASH">Teller cash Bal
                                    <p-sortIcon field="TELLERBALCASH"></p-sortIcon>
                                </th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_accountlist>
                            <tr>
                                <td class="text-center">
                                    {{_accountlist.CURRENCYNAME}}
                                </td>
                                <td class="text-center">
                                    {{_accountlist.PURCHASE}}
                                </td>
                                <td class="text-center">
                                    {{_accountlist.DEBITNOTE}}
                                </td>
                                <td class="text-center">
                                    {{_accountlist.SALES}}
                                </td>
                                <td class="text-center">
                                    {{_accountlist.RECEIPT}}
                                </td>
                                <td class="text-center">
                                    {{_accountlist.CREDITNOTE}}
                                </td>
                                <td class="text-center">
                                    {{_accountlist.JOURNAL}}
                                </td>
                                <td class="text-center">
                                    {{_accountlist.PAYMENT}}
                                </td>
                                <td class="text-center">
                                    {{_accountlist.NETAMOUNT}}
                                </td>
                                <td class="text-center">
                                    {{_accountlist.TELLERCASH}}
                                </td>
                                <td class="text-center">
                                    {{_accountlist.TELLERBALCASH}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt2.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="exportPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt2 [value]="_cashiersummarylist" [columns]="cols" [rows]="20" [paginator]="true" [rowsPerPageOptions]="[25,50,100]" [globalFilterFields]="['BRANCHNAME','CURRENCYNAME','RECEIVABLEAMOUNT','PAIDAMOUNT','BALANCE']" [rowHover]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="text-center" pSortableColumn="BRANCHNAME">Branch Name
                                    <p-sortIcon field="BRANCHNAME"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="CURRENCYNAME">Currency Name
                                    <p-sortIcon field="CURRENCYNAME"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="PURCHASE">Purchase
                                    <p-sortIcon field="PURCHASE"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="DEBITNOTE">Debit Note
                                    <p-sortIcon field="DEBITNOTE"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="SALES">Sales
                                    <p-sortIcon field="SALES"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="RECEIPT">Receipt
                                    <p-sortIcon field="RECEIPT"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="CREDITNOTE">Credit Note
                                    <p-sortIcon field="CREDITNOTE"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="JOURNAL">Journal
                                    <p-sortIcon field="JOURNAL"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="PAYMENT">Payment
                                    <p-sortIcon field="PAYMENT"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="NETAMOUNT">Net Cash
                                    <p-sortIcon field="NETAMOUNT"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="TELLERCASH">Teller cash
                                    <p-sortIcon field="TELLERCASH"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="TELLERBALCASH">Teller cash Bal
                                    <p-sortIcon field="TELLERBALCASH"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_cashiersummarylist>
                            <tr>

                                <td class="text-center">
                                    {{_cashiersummarylist.BRANCHNAME}}
                                </td>
                                <td class="text-center">
                                    {{_cashiersummarylist.CURRENCYNAME}}
                                </td>
                                <td class="text-center">
                                    {{_cashiersummarylist.PURCHASE}}
                                </td>
                                <td class="text-center">
                                    {{_cashiersummarylist.DEBITNOTE}}
                                </td>
                                <td class="text-center">
                                    {{_cashiersummarylist.SALES}}
                                </td>
                                <td class="text-center">
                                    {{_cashiersummarylist.RECEIPT}}
                                </td>
                                <td class="text-center">
                                    {{_cashiersummarylist.CREDITNOTE}}
                                </td>
                                <td class="text-center">
                                    {{_cashiersummarylist.JOURNAL}}
                                </td>
                                <td class="text-center">
                                    {{_cashiersummarylist.PAYMENT}}
                                </td>
                                <td class="text-center">
                                    {{_cashiersummarylist.NETAMOUNT}}
                                </td>
                                <td class="text-center">
                                    {{_cashiersummarylist.TELLERCASH}}
                                </td>
                                <td class="text-center" *ngIf="_cashiersummarylist.CURRENCYNAME!='BANK'">
                                    <a href="javascript:void(0);" (click)="onselectbalance(_cashiersummarylist)">
                                    {{_cashiersummarylist.TELLERBALCASH}}</a>
                                </td>
                                <td class="text-center" *ngIf="_cashiersummarylist.CURRENCYNAME=='BANK'">
                                    {{_cashiersummarylist.TELLERBALCASH}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-dialog header="CashierWise Details" [(visible)]="cashiersummarygrid" [modal]="true" [style]="{width: '10000px',height: '600px'}" [baseZIndex]="10000">
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="card">
                                <p-toolbar styleClass="p-mb-4">
                                    <ng-template pTemplate="left">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text"
                                                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                                placeholder="Search..." />
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="right">
                                        <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                        <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                        <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="exportPdf()"></button>
                                    </ng-template>
                                </p-toolbar>
                                <p-table #dt1 [value]="_cashierlist" [columns]="cols" [rows]="20" [paginator]="true" [rowsPerPageOptions]="[25,50,100]" [globalFilterFields]="['BRANCHNAME','USERNAME','CURRENCYNAME','RECEIVABLEAMOUNT','PAIDAMOUNT','BALANCE']" [rowHover]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [showCurrentPageReport]="true">
                                    <ng-template pTemplate="header" let-columns>
                                        <tr>
                                            <th class="text-center" pSortableColumn="NAME">
                                                Name
                                                <p-sortIcon field="NAME"></p-sortIcon>
                                            </th>
                                            <th class="text-center" pSortableColumn="BRANCHNAME">
                                                Branch Name
                                                <p-sortIcon field="BRANCHNAME"></p-sortIcon>
                                            </th>
                                            <th class="text-center" pSortableColumn="CURRENCYNAME">
                                                Currency Name
                                                <p-sortIcon field="CURRENCYNAME"></p-sortIcon>
                                            </th>
                                            <th class="text-center" pSortableColumn="PURCHASE">
                                                Purchase
                                                <p-sortIcon field="PURCHASE"></p-sortIcon>
                                            </th>
                                            <th class="text-center" pSortableColumn="DEBITNOTE">
                                                Debit Note
                                                <p-sortIcon field="DEBITNOTE"></p-sortIcon>
                                            </th>
                                            <th class="text-center" pSortableColumn="SALES">Sales
                                                <p-sortIcon field="SALES"></p-sortIcon>
                                            </th>
                                            <th class="text-center" pSortableColumn="RECEIPT">
                                                Receipt
                                                <p-sortIcon field="RECEIPT"></p-sortIcon>
                                            </th>
                                            <th class="text-center" pSortableColumn="CREDITNOTE">
                                                Credit Note
                                                <p-sortIcon field="CREDITNOTE"></p-sortIcon>
                                            </th>
                                            <th class="text-center" pSortableColumn="JOURNAL">
                                                Journal
                                                <p-sortIcon field="JOURNAL"></p-sortIcon>
                                            </th>
                                            <th class="text-center" pSortableColumn="PAYMENT">
                                                Payment
                                                <p-sortIcon field="PAYMENT"></p-sortIcon>
                                            </th>
                                            <th class="text-center" pSortableColumn="NETAMOUNT">Net Cash
                                                <p-sortIcon field="NETAMOUNT"></p-sortIcon>
                                            </th>
                                            <th class="text-center" pSortableColumn="TELLERCASH">
                                                Teller cash
                                                <p-sortIcon field="TELLERCASH"></p-sortIcon>
                                            </th>
                                            <th class="text-center" pSortableColumn="TELLERBALCASH">Teller cash Bal
                                                <p-sortIcon field="TELLERBALCASH"></p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-_cashierlist>
                                        <tr>
                                            <td class="text-center">
                                                {{_cashierlist.NAME}}
                                            </td>
                                            <td class="text-center">
                                                {{_cashierlist.BRANCHNAME}}
                                            </td>
                                            <td class="text-center">
                                                {{_cashierlist.CURRENCYNAME}}
                                            </td>
                                            <td class="text-center">
                                                {{_cashierlist.PURCHASE}}
                                            </td>
                                            <td class="text-center">
                                                {{_cashierlist.DEBITNOTE}}
                                            </td>
                                            <td class="text-center">
                                                {{_cashierlist.SALES}}
                                            </td>
                                            <td class="text-center">
                                                {{_cashierlist.RECEIPT}}
                                            </td>
                                            <td class="text-center">
                                                {{_cashierlist.CREDITNOTE}}
                                            </td>
                                            <td class="text-center">
                                                {{_cashierlist.JOURNAL}}
                                            </td>
                                            <td class="text-center">
                                                {{_cashierlist.PAYMENT}}
                                            </td>
                                            <td class="text-center">
                                                {{_cashierlist.NETAMOUNT}}
                                            </td>
                                            <td class="text-center">
                                                {{_cashierlist.TELLERCASH}}
                                            </td>
                                            <td class="text-center">
                                                {{_cashierlist.TELLERBALCASH}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>