import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptsalessummaryreportService {

  public _Action: IAction;
  public _httpOptions;
  public _RptSalesSummaryService = environment.RptSalesSummaryReportService;
  public _RptsalesService = environment.RptSalesReportService;
  constructor(private http: HttpClient) { }
  
  PageOnload(userid : number): Observable<any> {
    var Query = this._RptSalesSummaryService + '/PageInitialize/'+ userid;
    return this.http.get<{ data: any }>(Query); 
  }
  Print(salesreturn:object) {
    var Query = this._RptSalesSummaryService ;
    return this.http.post(Query,salesreturn, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }

  Printexcel(products:object) {
    var Query = this._RptsalesService + '/salessummeryPrintexcel';
    
    return this.http.post<{ data: any }>(Query, products);
  }
}
