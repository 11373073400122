import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { RequestforquoteService } from 'src/app/core/Services/Purchase/requestforquote.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Quoterequestview } from 'src/app/core/Views/Quoterequestview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-v-requestforquote',
  templateUrl: './v-requestforquote.component.html',
  styleUrls: ['./v-requestforquote.component.css']
})
export class VRequestforquoteComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Quoterequestviews: Quoterequestview[];
  _Quoterequestview: Quoterequestview;
  _Action: IAction
  _branchid = parseInt(sessionStorage["currentbranchid"]);
  _userid: number;
  _roleid: number;
  _quoterequestid: number;
  _IsProgressSpinner: boolean = true;
  cols: any[];
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  mindate: Date;
  maxdate: Date;

  constructor(private fb: FormBuilder, private _RequestforquoteService: RequestforquoteService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService, private confirmationService: ConfirmationService, private exportUtility: ExportUtility) {
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(38);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.cols = [
      { field: 'quoterequestno', header: 'Quote Request No' },
      { field: 'quoterequestdate', header: 'Request Date' },
      { field: 'productname', header: 'Product Name' },
      { field: 'suppliername', header: 'Supplier Name' },
      { field: 'statusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]

    this.Fetchall();
  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._userid = sessionStorage["userid"];
    this._roleid = sessionStorage["currentRole"];
    this._IsProgressSpinner = true;
    this._RequestforquoteService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //46.search page has grid which contains (action,requestforquote,producttype,productcategory,productname,variant,qty,status)
      this._Quoterequestviews = resultJSON.quoterequestviewList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
  }

  onRowUnselect(event) {
  }
  view(event) {
    //48. if all the data is valid allow to view the record from the database based on requestforquote 
    this.router.navigate(['/cRequestForQuote'], { state: { quoterequestid: event.quoterequestid, action: 'view' } });
  }
  edit(event) {
    this.router.navigate(['/cRequestForQuote'], { state: { quoterequestid: event.quoterequestid, action: 'edit' } });
  }

  Cancel(event) {
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');

    this._quoterequestid = event.quoterequestid;
    this._IsProgressSpinner = true;
    this._RequestforquoteService.Cancel(this._quoterequestid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this.Fetchall();
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Request For Quote <b>"' + event.quoterequestno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  GoToCreate(event) {
    this.router.navigate(['/cRequestForQuote']);
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  Print(event) {
    var quoterequestid = event.quoterequestid;
    this._IsProgressSpinner = true;
    //var mediaType = 'application/pdf';
    this._RequestforquoteService.Print(quoterequestid, this._branchid);
    this._IsProgressSpinner = false;
  }

  SendMail(event) {
    // var workflowid = 5;
    var requestno = event.quoterequestno;
    this._userid = sessionStorage["userid"];
    this._branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;

    this._RequestforquoteService.SendMail(this._userid, this._branchid, requestno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && resultJSON) {
        this._CustomExceptionService.handleSuccess(usererrors.MailSent_Success);
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);

      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let TermsAndConditionList = this.GetRequestForQuoteList();
    this.exportUtility.exportExcel(TermsAndConditionList, 'RequestForQuote', 'xlsx');
  }
  ExportToPdf() {
    let TermsAndConditionList = this.GetRequestForQuoteList();
    const data = TermsAndConditionList.map(Object.values);
    const head = [[
      'Quote Request No',
      'Request Date',
      'Product Name',
      'Supplier Name',
      'Status',
      'Created By',
      'Created On'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('RequestForQuote.pdf');
      })
    })
  }
  GetRequestForQuoteList() {
    let RequestforQuoteList = [];
    this._Quoterequestviews.forEach(element => {
      let requestforquote: any = {};
      requestforquote["Quote Request No"] = element.quoterequestno;
      requestforquote["Request Date"] = this.datepipe.transform(element.quoterequestdate, 'dd/MM/yyyy');
      requestforquote["Product Name"] = element.productname;
      requestforquote["Supplier Name"] = element.suppliername;
      requestforquote["Status"] = element.statusname;
      requestforquote["Created By"] = element.createdbyname;
      requestforquote["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      RequestforQuoteList.push(requestforquote);
    });
    return RequestforQuoteList;
  }
}
