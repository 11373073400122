import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Cmsheader } from 'src/app/core/Models/Cmsheader';
import { CmsService } from 'src/app/core/Services/ecommerce/cms.service';
import { vBranchCode } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-c-cms',
  templateUrl: './c-cms.component.html',
  styleUrls: ['./c-cms.component.css']
})
export class CCmsComponent implements OnInit {
  Cmsheaderform: FormGroup;

  toolbarOptions: string;
  disabled: boolean = true;
  _IsProgressSpinner: boolean = true;
  _submitted = false;
  _cmsid: number;
  isDisabledSave: boolean = false;
  isDisabledClear: boolean = false;
  pageURL = '';
  _CMS: Cmsheader
  // _action:boolean=true;
  _action: string = 'create'



  constructor(private utility: CommonUtilities, private _CmsService: CmsService, private fb: FormBuilder, private _router: Router, private _CustomExceptionService: CustomExceptionService) { }
  InitializeForm() {
    this.Cmsheaderform = this.fb.group({
      cmsid: [0],
      pagename: ['', vBranchCode],
      pageurlname: [],
      description: ['', vBranchCode],
      template: [],
      recordstatus: [],
      createdon: [],
      createdby: [],
      modifiedon: [],
      modifiedby: [],
      recordstatusname: [],
      // createbyname:[]
      createdbyname: []
    });

  }


  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    if (this._action == 'create') {
      this.Cmsheaderform.get("pageurlname").disable();
    }
    // this.minDate = new Date();

    this._cmsid = history.state?.cmsid ? history.state?.cmsid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;

    if (this._action == 'view') {
      this.Cmsheaderform.get("pagename").disable();
      this.Cmsheaderform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
    }

    if (this._action == 'edit') {
      this.Cmsheaderform.get("pagename").disable();
      this.isDisabledClear = true;
      this.isDisabledSave = false;
    }
    this._IsProgressSpinner = true;

    this._CmsService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // console.log("PageLoad Data", resultJSON);
      // 23.update initialize should load all fields based on search page selected Currency ID.
      // this._cmsid = resultJSON.
      // this.pageurlname = resultJSON.
      this._IsProgressSpinner = false;
      // this._currencyform.controls['currencystatus'].setValue("MSC00001");
      // console.log(this.Cmsheaderform);
    });
    //For Update
    this._cmsid = history.state.cmsid;
    if (this._action == 'edit' || this._action == 'view') {
      this._CmsService.View(this._cmsid).subscribe((result) => {
        const updateJSON = JSON.parse(JSON.stringify(result));
        // console.log(updateJSON.cmsheaderviews[0]);
        this.Cmsheaderform.setValue(updateJSON.cmsheaderviews[0]);
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }

  }

  onSave() {
    // console.log("Submitted");
    // console.log(this._action);
    this._submitted = true;
    // console.log(this.Cmsheaderform.value);
    
    if (this.Cmsheaderform.valid) {
      this._CMS = this.Cmsheaderform.getRawValue();
      if (this._action == 'create') {
        // console.log("YEs");
        this._CMS.createdon = new Date();
        this._CMS.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._CmsService.Create(this._CMS).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
      else if (this._action == 'edit') {
        this._CMS.modifiedon = new Date();
        this._CMS.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._CmsService.Update(this._CMS).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
          //  this._Currency.effectivefrom = new Date(this._Currency.effectivefrom);
          //  this._Currency.effectiveto = new Date(this._Currency.effectiveto);
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
    }
  }

  onKey() {
    var value = this.Cmsheaderform.get("pagename").value;
    this.pageURL = value.replace(/\s+/g, '-').toLowerCase();
    this.Cmsheaderform.controls["pageurlname"].setValue(this.pageURL)
  }


  reset(event) {
    this._submitted = true;
    this.Cmsheaderform.reset();
    this.isDisabledSave = false;
    this.isDisabledClear = false;
    this.InitializeForm();
    this._submitted = false;
  }
  goBack(event) {
    //alert('This');
    // console.log("goBack");
    this._router.navigate(['/vCms']);
  }

}
