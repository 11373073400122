<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Product Request</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple title="Save" type="submit" [disabled]="isDisabledSave" [disabled]="_button" (click)="onSave()" icon="pi pi-save"></button>
                            <button pButton pRipple title="Clear" icon="pi pi-trash" class="p-button-danger" [disabled]="_button" (click)="reset($event)"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button" [disabled]="!isDisabledSave || _button" (click)="SendToApproval()"></button>
                            <button pButton pRiple icon="pi pi-search" title="Back to Search" (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_productrequstform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="productferno" disabled formControlName="materialrequestno"
                                        pInputText>
                                    <label for="productferno">Request No</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="requstdate" [showIcon]="true"
                                        formControlName="materialrequestdate" dateFormat="dd/mm/yy"
                                        [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="materialrequestdate">Request Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="( _productrequstform.get('materialrequestdate').touched )&& _productrequstform.get('materialrequestdate').errors?.required">
                                    Please select request date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Employees" optionValue="userid"
                                        optionLabel="username" [virtualScroll]="true" itemSize="30"
                                        formControlName="requestedby" [filter]="true" filterBy="username">
                                    </p-dropdown>
                                    <label for="Employeename">Requested By <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="( _productrequstform.get('requestedby').touched )&& _productrequstform.get('requestedby').errors?.required">
                                    Please select Requested By
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Requesttype"
                                        optionValue="metasubcode" optionLabel="metasubdescription"
                                        formControlName="requesttype" [filter]="true"
                                        filterBy="metasubdescription">
                                    </p-dropdown>
                                    <label for="metasubdescription">Request Type <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="( _productrequstform.get('requesttype').touched )&& _productrequstform.get('requesttype').errors?.required">
                                    Please select Request Type
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_requsttypelist"
                                        optionValue="metasubcode" optionLabel="metasubdescription"
                                        (onChange)="getrefno()"
                                        formControlName="materialrequesttype" [filter]="true"
                                        filterBy="metasubdescription">
                                    </p-dropdown>
                                    <label for="metasubdescription">Product Request Type <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="( _productrequstform.get('materialrequesttype').touched )&& _productrequstform.get('materialrequesttype').errors?.required">
                                    Please select Product Request Type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_RefNo" optionValue="jobcardno"
                                        optionLabel="jobcardno" [virtualScroll]="true" itemSize="3"
                                        formControlName="refdocno" [filter]="true" filterBy="jobcardno">
                                    </p-dropdown>
                                    <label for="jobcardno">Ref Doc No <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <textarea rows="3" name="address" pInputTextarea
                                        formControlName="requestreason"></textarea>
                                    <label for="address">Request Reason <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="( _productrequstform.get('requestreason').touched )&& _productrequstform.get('requestreason').errors?.required">
                                    Please Enter Request Reason
                                </span>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_productrequstdetailform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">

                                    <input type="text" name="productferno" disabled formControlName="ProductType"
                                        pInputText>
                                    <label for="producttypeid">Product Type </label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">

                                    <input type="text" name="productferno" disabled formControlName="ProductCategory"
                                        pInputText>
                                    <label for="productcategoryid">Product Category </label>
                                </span>


                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">

                                    <p-autoComplete [suggestions]="filteredProducts" formControlName="productid"
                                        (completeMethod)="filterProducts($event)" field="productname"
                                        (onSelect)="getProductVariants($event)">
                                        <ng-template let-product pTemplate="item">
                                            <div>{{product.productname}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted1|| _productrequstdetailform.get('productid').touched) && _productrequstdetailform.get('productid').errors?.SelectProductName">
                                    Please select product name
                                </span>
                            </td>

                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>

                            <td>
                                <span class="p-float-label">
                                    <input type="text" name="availableuantity" disabled maxlength="7"
                                        formControlName="availableuantity" pInputText>
                                    <label for="availableuantity">Available Quantity <span
                                            class="hlt-txt">*</span></label>
                                </span>

                            </td>

                            <td>
                                <span class="p-float-label">
                                    <p-inputNumber *ngIf="hidequantity == true" formControlName="requestqty"
                                        autocomplete="off" maxlength="10" mode="decimal" [minFractionDigits]="0"
                                        [maxFractionDigits]="0">
                                    </p-inputNumber>
                                    <label *ngIf="hidequantity == true" for="requestqty">Quantity </label>
                                </span>

                                <span class="p-float-label">
                                    <p-inputNumber *ngIf="hidequantity != true" formControlName="requestqty"
                                        autocomplete="off" maxlength="10" mode="decimal" [minFractionDigits]="3"
                                        [maxFractionDigits]="3">
                                    </p-inputNumber>
                                    <label *ngIf="hidequantity != true" for="requestqty"> Quantity <span
                                            class="hlt-txt">*</span>
                                </label>
                                </span>

                                <!-- <span class="p-float-label">
                                    <p-inputNumber type="text" name="requestquantity" formControlName="requestqty"
                                        maxlength="7"></p-inputNumber>
                                    <label for="requestqty">Quantity <span class="hlt-txt">*</span></label>
                                </span> -->
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted1|| _productrequstdetailform.get('requestqty').touched) && _productrequstdetailform.get('requestqty').errors?.QuantityRequired">
                                    Please enter quantity
                                </span> -->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Add" type="button" icon="pi pi-plus" class="p-button-success" (click)="AddProduct($event)" [disabled]="_button"></button>
                                <button pButton pRipple label="Clear" title="Clear" type="button" [disabled]="_button" (click)="Clear($event)" icon="pi pi-trash" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-table [value]="_Productdetails" [rows]="50" [paginator]="true" [rowsPerPageOptions]="[1000,1500,2000,2500,3000]" [globalFilterFields]="['productName','requestqty','uomname']" [rowHover]="true" dataKey="productName" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 100px;">Action</th>
                                <th>Product Name</th>
                                <th>Rate</th>
                                <th>Quantity</th>
                                <th>Final Amount</th>
                                <th>UOM</th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_productdetail>
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple title="Remove" icon="pi pi-trash" class="p-button-danger p-mr-2" [disabled]="_button" (click)="RemoveProduct(_productdetail)">
                                    </button>
                                </td>
                                <td> {{_productdetail.productName}} </td>
                                <td> {{_productdetail.purchaserateinusd}} </td>
                                <td> {{_productdetail.requestqty}} </td>
                                <td> {{_productdetail.finalamount}} </td>
                                <td> {{_productdetail.uomname}} </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </table>

                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>