<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Scheme</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple title="Save" (click)="onSave()" type="submit"
                                [disabled]="isDisabledSave" icon="pi pi-save"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button"
                                [disabled]="isDisabledsendapparoval" (click)="SendToApproval($event)"></button>
                            <button pButton pRipple title="Clear" icon="pi pi-trash" type="button"
                                (click)="reset($event)" [disabled]="isDisabledClear" class="p-button-danger"></button>
                            <button pButton pRipple icon="pi pi-search" title="Back to Search" (click)="goBack($event)"
                                class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_schemeform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" formControlName="schemename" name="schemename" pInputText>
                                    <label for="schemename">Scheme Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _schemeform.get('schemename').touched) && _schemeform.get('schemename').errors?.SchemeNameRequired">
                                    Please Enter Scheme Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="effectivefrom" formControlName="effectivefrom"[showTime]="true" inputId="time"
                                        dateFormat="dd/mm/yy" (onSelect)="OnFromDateSelect($event)" class="default-load"
                                        [showIcon]="true">
                                    </p-calendar>
                                    <label for="effectivefrom">Effective From <span class="hlt-txt">*</span>
                                    </label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _schemeform.get('effectivefrom').touched) && _schemeform.get('effectivefrom').errors?.EffectiveFromRequired">
                                    Please Select Effective From
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="effectiveto" formControlName="effectiveto" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [minDate]="minDate"[showTime]="true" inputId="time"
                                        [disabled]="!_schemeform.get('effectivefrom').value || _action == 'view'">
                                    </p-calendar>
                                    <label for="effectiveto">Effective To </label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>

                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <form [formGroup]="_schemeproductform">
                                <span class="p-float-label">
                                    <p-multiSelect  [options]="_producttype"
                                        optionLabel="producttypename" optionValue="producttypeid"
                                        [virtualScroll]="true" itemSize="30"
                                        formControlName="producttypeid" (onChange)="getProductCategory($event)"
                                        displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type </label>
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_schemeproductform">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productcategory" formControlName="productcategoryid"
                                        optionLabel="categoryname" (onChange)="getProduct($event)"
                                        [virtualScroll]="true" itemSize="30"
                                        optionValue="productcategoryid" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category</label>
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_schemedetailform">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productname" formControlName="productid"
                                        optionLabel="productname" optionValue="productid" displaySelectedLabel=true
                                        [virtualScroll]="true" itemSize="30"
                                        [(ngModel)]="_selectedProductsNames" (onChange)="onSelectProductNames($event)">
                                    </p-multiSelect>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted1 || _schemedetailform.get('productid').touched) && _schemedetailform.get('productid').errors?.SelectProductName">
                                    Please Select Product Name
                                </span>
                            </form>
                        </td>

                        <td style="width: 20%;">
                        </td>
                        <td style="width: 20%;"></td>
                    </tr>
                    <tr>
                        <td>
                            <form [formGroup]="_schemedetailform">
                                <span class="p-float-label">
                                    <input type="number" name="minimumqty" formControlName="minimumqty" pInputText>
                                    <label for="minimumqty">Minimum Quantity<span class="hlt-txt">*</span>
                                    </label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_submitted1 || _schemedetailform.get('minimumqty').touched) && _schemedetailform.get('minimumqty').errors?.MinimumqtyRequired">
                                Please Select Minimum Quantity
                            </span>
                            </form>
                        </td>
                        <td>
                            <form [formGroup]="_schemedetailform">
                                <span class="p-float-label">
                                    <input type="number" name="maximumqty" formControlName="maximumqty" pInputText>
                                    <label for="maximumqty">Maximum Quantity <span class="hlt-txt">*</span>
                                    </label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_submitted1 || _schemedetailform.get('maximumqty').touched) && _schemedetailform.get('maximumqty').errors?.MaximumqtyRequired">
                                Please Select Maximum Quantity
                            </span>
                            </form>
                        </td>
                        <td>
                            <form [formGroup]="_schemedetailform">
                                <span class="p-float-label">
                                    <input type="number" name="discountpercentage" formControlName="discountpercentage"
                                    (keyup)="OnChangeDiscount($event)" pInputText>
                                    <label for="discountpercentage">Discount % </label>
                                </span>
                            </form>

                        </td>
                        <td rowspan="2">
                            <form [formGroup]="_schemeform">
                            <span class="p-float-label">
                                <textarea inputId="textarea" pInputTextarea
                                    formControlName="schemeremarks"></textarea>
                                <label for="textarea">Display Message<span class="hlt-txt">*</span> </label>
                            </span>
                            <span class="text-danger"
                                *ngIf="(_submitted1 || _schemeform.get('schemeremarks').touched) && _schemeform.get('schemeremarks').errors?.AddressRequired">
                                Please Enter Display Message (Will show on invoice)
                            </span>
                        </form>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <form [formGroup]="_schemedetailform">
                                <span class="p-float-label">
                                    <input type="number" name="value" formControlName="value" pInputText>
                                    <label for="value">Product Value In USD 
                                    </label>
                                </span>
                            </form>
                        </td>
                        <td>
                            <form [formGroup]="_schemedetailform">
                                <span class="p-float-label">
                                    <input type="text" name="rewardpoints" formControlName="rewardpoints" pInputText>
                                    <label for="rewardpoints">Reward Points </label>
                                </span>
                            </form>
                        </td>
                        <td>
                            <form [formGroup]="_schemeform">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" formControlName="schemestatus"
                                        [options]="_schemestatus" name="schemestatus" optionLabel="metasubdescription"
                                        optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="Status">Status</label>
                                </span>
                            </form>
                        </td>
                    </tr>
                </table>


                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Free Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_schemefreeproductform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <form [formGroup]="_schemeproductform">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_freeproducttype" optionLabel="producttypename"
                                            optionValue="producttypeid" formControlName="producttypeid"
                                            [virtualScroll]="true" itemSize="30"
                                            (onChange)="getFreeProductCategory($event)" displaySelectedLabel=true>
                                        </p-multiSelect>
                                        <label for="producttypeid">Product Type </label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_schemeproductform">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_freeproductcategory"
                                            formControlName="productcategoryid" optionLabel="categoryname"
                                            [virtualScroll]="true" itemSize="30"
                                            (onChange)="getFreeProduct($event)" optionValue="productcategoryid"
                                            displaySelectedLabel=true>
                                        </p-multiSelect>
                                        <label for="productcategoryid">Product Category </label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_freeproductname"
                                        formControlName="productid" optionValue="productid" optionLabel="productname"
                                        [filter]="true" filterBy="searchfilter" [showClear]="true"
                                        [virtualScroll]="true" itemSize="30"
                                        (onChange)="getProductVariant($event)" displaySelectedLabel=true>
                                    </p-dropdown>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted2 || _schemefreeproductform.get('productid').touched) && _schemefreeproductform.get('productid').errors?.SelectProductName">
                                    Please Select Product Name
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="quantity">
                                    <label for="quantity">Quantity <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted2 || _schemefreeproductform.get('quantity').touched) && _schemefreeproductform.get('quantity').errors?.QuantityRequired">
                                    Please Enter Quantity
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_variant"
                                      optionValue="productvariantdetailid"
                                        formControlName="variantid" name="variantid" optionLabel="variantdescription">
                                    </p-dropdown>
                                    <label for="variantid">Variant <span class="hlt-txt"></span></label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Add"  [disabled]="isDisabledadd" icon="las la-check" type="button"
                                    (click)="onAddFreeProduct($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear"  [disabled]="isDisabledclear" type="button" icon="pi pi-times" (click)="Clear($event)"
                                    class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>

                <div class="card">
                    <p-table [value]="_freeproducts" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]" [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['productid','variantdescription']" [rowHover]="true" dataKey="productid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:100px;text-align: center;">Action</th>
                                <th>Product Name</th>
                                <th>Variant</th>
                                <th>Quantity</th>
                                <th>UOM</th>
                                <th>Price</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_freeproduct let-ri="rowIndex">
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple title="Remove" [disabled]="isDisabledremove" icon="pi pi-trash" class="p-button-danger"
                                        (click)="RemoveFreeProduct(_freeproduct)"></button>
                                </td>
                                <td>
                                    {{_freeproduct.productname}}
                                </td>
                                <td>
                                    {{_freeproduct.variantdescription}}
                                </td>
                                <td>
                                    {{_freeproduct.quantity}}
                                </td>
                                <td>
                                    {{_freeproduct.uomname}}
                                </td>
                                <td>
                                    {{_freeproduct.price}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <tr>
                        <td>
                            <!-- Debug -->
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>

            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

        </div>
    </div>
</div>