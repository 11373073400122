export class Quoterequestsupplierdetail {
    quoterequestsupplierdetailid: number;
    quoterequestid: number;
    supplierid: number;
    submitteddate: Date;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;

   
}