import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CContainertrackingComponent } from './containertracking/c-containertracking/c-containertracking.component';
import { VContainertrackingComponent } from './containertracking/v-containertracking/v-containertracking.component';
import { CImportentryComponent } from './Importentry/c-importentry/c-importentry.component';
import { VImportentryComponent } from './Importentry/v-importentry/v-importentry.component';

const routes: Routes = [
  //Deepak
  { path: 'cContainertracking', component: CContainertrackingComponent },
  { path: 'vContainertracking', component: VContainertrackingComponent },
  {path:'cImportentry',component: CImportentryComponent},
  {path:'vImportentry',component:VImportentryComponent}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrackingRoutingModule { }
