import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Customer } from 'src/app/core/Models/Customer';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Supplier } from 'src/app/core/Models/Supplier';
import { RptpayablereceivableService } from 'src/app/core/Services/reports/Accounting/Rptpayablereceivable/rptpayablereceivable.service';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Currency } from 'src/app/core/Models/Currency';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-rptpayablereceivable',
  templateUrl: './rptpayablereceivable.component.html',
  styleUrls: ['./rptpayablereceivable.component.css']
})
export class RptpayablereceivableComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptpayablereceivableform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _userid: number;
  datepipe: any;
  _typelists = [{ type: 'Customer', value: '11' }, { type: 'Supplier', value: '15' }];
  _currencylist: Currency[] = [];
  _accountnamelist: Accountledger[];
  _customer_supplierlist: Accountledgerview[] = [];
  _src: SafeResourceUrl;
  _fromMaxDate = new Date();
  _Action: IAction;
  _typelist = [{ type: 'PAYABLE' }, { type: 'RECEIVABLE' }];
  mindate: Date;
  maxdate: Date;

  constructor(private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private fb: FormBuilder, private _RptpayablereceivableService: RptpayablereceivableService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(109);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptpayablereceivableform = this.fb.group({
      fromdate: [''],
      todate: [''],
      partytype: [0],
      customer_supplierid: [0],
      branchid: [0],
      type: [0],
      currencyid: [0]
    })
    this._branchlist = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    // this.InitializeForm();
    // var dt = new Date();
    // dt.setDate(dt.getDate() - 7);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptpayablereceivableform.get("fromdate").setValue(new Date);
      this._rptpayablereceivableform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptpayablereceivableform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptpayablereceivableform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

    // this._rptpayablereceivableform.controls['fromdate'].setValue(new Date());
    // this._rptpayablereceivableform.controls['todate'].setValue(new Date());
    this._rptpayablereceivableform.controls['type'].setValue('PAYABLE');
    this._rptpayablereceivableform.controls['currencyid'].setValue(1);
    this._RptpayablereceivableService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //this._partytypelist = resultJSON.partytypeList;
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  clear(event) {
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this._rptpayablereceivableform.reset();
    // var dt = new Date();
    // dt.setDate(dt.getDate() - 7);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptpayablereceivableform.get("fromdate").setValue(new Date);
      this._rptpayablereceivableform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptpayablereceivableform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptpayablereceivableform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._rptpayablereceivableform.controls['type'].setValue('PAYABLE');
    this._rptpayablereceivableform.controls['currencyid'].setValue(1);
  }

  GenerateReport(event) {
    var _fromdate = this._rptpayablereceivableform.get("fromdate").value;
    var _todate = this._rptpayablereceivableform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');

    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleWarning("Please select to Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    var customerorsupplier = this._rptpayablereceivableform.get("customer_supplierid").value == null ? 0 : this._rptpayablereceivableform.get("customer_supplierid").value;
    var partytype = this._rptpayablereceivableform.get("partytype").value == null ? 0 : this._rptpayablereceivableform.get("partytype").value;
    var branchid = this._rptpayablereceivableform.get("branchid").value == null ? 0 : this._rptpayablereceivableform.get("branchid").value;
    var type = this._rptpayablereceivableform.get("type").value == null || this._rptpayablereceivableform.get("type").value == 'Both' ? 0 : this._rptpayablereceivableform.get("type").value;
    let currencyid = this._rptpayablereceivableform.controls['currencyid'].value;
    if (partytype == null || partytype == 0) {
      this._CustomExceptionService.handleError("Please Select Party Type");
      return;
    }
    if (customerorsupplier == null || customerorsupplier == 0) {
      this._CustomExceptionService.handleError("Please Select Customer Or Supplier");
      return;
    }
    if (type == null) {
      this._CustomExceptionService.handleError("Please Select Type");
      return;
    }
    var currentbranchid = parseInt(sessionStorage["currentbranchid"]);
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    // this._RptpayablereceivableService.Print(fromdate, todate, customerorsupplier,partytype, branchid, this._userid);
    this._RptpayablereceivableService.Print(fromdate, todate, partytype, customerorsupplier, branchid, this._userid, type, currentbranchid, currencyid).subscribe((result) => {
      if (result.type != mediaType) {
        let reader = new FileReader();
        var blob = new Blob([result], { type: 'application/json' });
        let message = reader.readAsText(blob);
        return;
      }
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;

    },
      async (error) => {
        const message = await error.error.text();
        this._IsProgressSpinner = false;
        this._CustomExceptionService.handleError(message);
      });
  }
  onchangepartytype(event) {
    this._userid = parseInt(sessionStorage["userid"]);
    var partytype = event.value;
    if (partytype == null && partytype == 0) {
      this._CustomExceptionService.handleError("Please Select Party Type");
      return;
    }
    this._IsProgressSpinner = true;
    this._RptpayablereceivableService.onchangepartytype(partytype, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      this._accountnamelist = resultJSON.accountnameList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
