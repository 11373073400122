<div class="page-container">
  <div class="inner-page-container">
    <div class="page-loader" *ngIf="_IsProgressSpinner">
      <p-progressSpinner strokeWidth="5"></p-progressSpinner>
    </div>
    <div class="white">
      <div class="page-title">
        <div class="row">
          <div class="col-md-5">
            <h3>OutStanding Purchase Order Report</h3>
          </div>
        </div>
      </div>
      <div class="form-container scroll-y">
        <div class="input-container">
          <form [formGroup]="_POoutstandingform">
            <table class="normal-table">
              <tr>
                <td style="width: 20%">
                  <span class="p-float-label">
                    <p-calendar
                      [showIcon]="true"
                      formControlName="fromdate"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="1980:2090"
                      [minDate]="mindate"
                      [maxDate]="maxdate"
                      dateFormat="dd/mm/yy"
                    >
                    </p-calendar>
                    <label for="fromdate">From Date</label>
                  </span>
                </td>
                <td style="width: 20%">
                  <span class="p-float-label">
                    <p-calendar
                      [showIcon]="true"
                      formControlName="todate"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="1980:2090"
                      [minDate]="mindate"
                      [maxDate]="maxdate"
                      dateFormat="dd/mm/yy"
                    >
                    </p-calendar>
                    <label for="todate">To Date</label>
                  </span>
                </td>
                <td style="width: 20%">
                  <span class="p-float-label">
                    <p-multiSelect
                      [filter]="false"
                      [options]="_branches"
                      optionLabel="branchname"
                      optionValue="branchid"
                      [(ngModel)]="branchid"
                      [ngModelOptions]="{ standalone: true }"
                      displaySelectedLabel="true"
                      [filter]="true"
                      filterBy="branchname"
                      [virtualScroll]="true"
                      itemSize="30"
                    >
                    </p-multiSelect>
                    <label>Branch </label>
                  </span>
                </td>
                <td style="width: 20%">
                  <span class="p-float-label">
                    <p-multiSelect
                      [filter]="false"
                      [options]="_suppliers"
                      optionLabel="suppliername"
                      optionValue="supplierid"
                      [(ngModel)]="supplierid"
                      [ngModelOptions]="{ standalone: true }"
                      displaySelectedLabel="true"
                      [filter]="true"
                      filterBy="suppliername"
                      [virtualScroll]="true"
                      itemSize="30"
                    >
                    </p-multiSelect>
                    <label>Supplier </label>
                  </span>
                </td>
                <td></td>
              </tr>

              <tr>
                <td>
                  <button
                    pButton
                    pRipple
                    label="Search"
                    icon="las la-search"
                    (click)="GenerateReport()"
                    class="p-button-sm p-button-success"
                  ></button>
                  <button
                    pButton
                    pRipple
                    label="Clear"
                    icon="pi pi-times"
                    (click)="clear()"
                    class="p-button-danger"
                  ></button>
                </td>
              </tr>
            </table>
          </form>
        </div>
        <div class="card">
          <p-toolbar styleClass="p-mb-4">
            <ng-template pTemplate="left">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search..."
                />
              </span>
            </ng-template>
            <ng-template pTemplate="right">
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-o"
                title="Export CSV"
                (click)="exportExcel()"
                class="p-mr-2"
                pTooltip="CSV"
                tooltipPosition="bottom"
              ></button>
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-excel"
                title="Export Excel"
                class="p-button-success p-mr-2"
                pTooltip="XLS"
                tooltipPosition="bottom"
                (click)="exportExcel()"
              ></button>
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-pdf"
                title="Export PDF"
                class="p-button-warning p-mr-2"
                pTooltip="PDF"
                tooltipPosition="bottom"
                (click)="ExportToPdf()"
              ></button>
            </ng-template>
          </p-toolbar>
          <p-table
            #dt
            [value]="_outstandingpurchasereports"
            [rows]="10"
            [globalFilterFields]="['PURCHASEORDERNO','BRANCHNAME','SUPPLIERNAME','POQUANTITY','INWARDQUANTITY','GRNQUANTITY']"
            [paginator]="true"
            [rowsPerPageOptions]="[10, 25, 50, 100]"
            [rowHover]="true"
            dataKey="PURCHASEORDERNO"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true"
          >
            <ng-template pTemplate="header">
              <tr>
                <th
                  class="text-center"
                  style="width: 60px"
                  pSortableColumn="sno"
                >
                  S.No
                  <p-sortIcon field="sno"></p-sortIcon>
                </th>
                <th pSortableColumn="PURCHASEORDERNO">
                  Purchase Order No
                  <p-sortIcon field="PURCHASEORDERNO"> </p-sortIcon>
                </th>
                <th pSortableColumn="PURCHASEORDERDATE">
                  Purchase Order Date
                  <p-sortIcon field="PURCHASEORDERDATE"></p-sortIcon>
                </th>
                <th pSortableColumn="BRANCHNAME">
                  Branch Name
                  <p-sortIcon field="BRANCHNAME"> </p-sortIcon>
                </th>
                <th pSortableColumn="SUPPLIERNAME">
                  Supplier Name
                  <p-sortIcon field="SUPPLIERNAME"> </p-sortIcon>
                </th>
                <th pSortableColumn="POQUANTITY">
                  Purchase Order Quantity
                  <p-sortIcon field="POQUANTITY"> </p-sortIcon>
                </th>
                <th pSortableColumn="INWARDQUANTITY">
                  Pending Inward Qty
                  <p-sortIcon field="INWARDQUANTITY"> </p-sortIcon>
                </th>
                <th pSortableColumn="GRNQUANTITY">
                  Pending GRN Qty
                  <p-sortIcon field="GRNQUANTITY"> </p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-_outstandingpurchasereports
              let-i="rowIndex"
            >
              <tr>
                <td class="text-center" style="width: 60px">
                  {{ i + 1 }}
                </td>
                <td>
                  {{ _outstandingpurchasereports.PURCHASEORDERNO }}
                </td>
                <td>
                  {{
                    _outstandingpurchasereports.PURCHASEORDERDATE
                      | date : "dd/MM/yyyy"
                  }}
                </td>
                <td>
                  {{ _outstandingpurchasereports.BRANCHNAME }}
                </td>
                <td>
                  {{ _outstandingpurchasereports.SUPPLIERNAME }}
                </td>
                <td>
                  {{ _outstandingpurchasereports.POQUANTITY }}
                </td>
                <td class="text-center" style="width: 100px">
                  <a
                    href="javascript:void(0);"
                    (click)="
                      GetOutStandingPODetails(_outstandingpurchasereports)
                    "
                  >
                    {{ _outstandingpurchasereports.INWARDQUANTITY }}
                  </a>
                </td>

                <td class="text-center" style="width: 100px">
                  <a
                    href="javascript:void(0);"
                    (click)="
                      GetOutStandingPOGrnDetails(_outstandingpurchasereports)
                    "
                  >
                    {{ _outstandingpurchasereports.GRNQUANTITY }}
                  </a>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <p-dialog
          header="Pending Inward PO Wise"
          [(visible)]="_inwardwisewise"
          [modal]="true"
          [style]="{ width: '1000px', height: '500px' }"
          [baseZIndex]="10000"
        >
          <ng-template pTemplate="content">
            <div class="popup-body">
              <div class="card">
                <p-toolbar styleClass="p-mb-4">
                    <ng-template pTemplate="left">
                      <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input
                          pInputText
                          type="text"
                          (input)="inwardTable.filterGlobal($event.target.value, 'contains')"
                          placeholder="Search..."
                        />
                      </span>
                    </ng-template>
                    <ng-template pTemplate="right">
                      <button
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-file-o"
                        title="Export CSV"
                        (click)="exportInwardExcel()"
                        class="p-mr-2"
                        pTooltip="CSV"
                        tooltipPosition="bottom"
                      ></button>
                      <button
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-file-excel"
                        title="Export Excel"
                        class="p-button-success p-mr-2"
                        pTooltip="XLS"
                        tooltipPosition="bottom"
                        (click)="exportInwardExcel()"
                      ></button>
                      <button
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-file-pdf"
                        title="Export PDF"
                        class="p-button-warning p-mr-2"
                        pTooltip="PDF"
                        tooltipPosition="bottom"
                        (click)="InwardExportToPdf()"
                      ></button>
                    </ng-template>
                  </p-toolbar>
                <p-table
                  #inwardTable
                  [value]="_Outstandingpurchases"
                  [rows]="10"
                  [paginator]="true"
                  [rowsPerPageOptions]="[10, 25, 50, 100]"
                  [rowHover]="true"
                  [globalFilterFields]="['PURCHASEORDERNO','PRODUCTNAME','POQUANTITY','INWARDBALANCEQUANTITY','INWARDSTATUS']"
                  dataKey="INWARDBALANCEQUANTITY"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  [showCurrentPageReport]="true"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" style="width: 60px">S.No</th>
                      <th pSortableColumn="PURCHASEORDERNO">
                        Purchase Order No
                        <p-sortIcon field="PURCHASEORDERNO"> </p-sortIcon>
                      </th>
                      <th pSortableColumn="PURCHASEORDERDATE">
                        Purchase Order Date
                        <p-sortIcon field="PURCHASEORDERDATE"> </p-sortIcon>
                      </th>
                      <th pSortableColumn="PRODUCTNAME">
                        Product Name
                        <p-sortIcon field="PRODUCTNAME"> </p-sortIcon>
                      </th>
                      <th pSortableColumn="POQUANTITY">
                        Purchase Order Qty
                        <p-sortIcon field="POQUANTITY"> </p-sortIcon>
                      </th>
                      <th pSortableColumn="INWARDBALANCEQUANTITY">
                        Inward Pending Qty
                        <p-sortIcon field="INWARDBALANCEQUANTITY"> </p-sortIcon>
                      </th>
                      <th pSortableColumn="INWARDSTATUS">
                        Status
                        <p-sortIcon field="INWARDSTATUS"> </p-sortIcon>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="body"
                    let-rowData
                    let-_Outstandingpurchase
                    let-i="rowIndex"
                  >
                    <tr>
                      <td class="text-center" style="width: 60px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        {{ _Outstandingpurchase.PURCHASEORDERNO }}
                      </td>
                      <td>
                        {{
                          _Outstandingpurchase.PURCHASEORDERDATE
                            | date : "dd/MM/yyyy"
                        }}
                      </td>
                      <td>
                        {{ _Outstandingpurchase.PRODUCTNAME }}
                      </td>
                      <td>
                        {{ _Outstandingpurchase.POQUANTITY }}
                      </td>
                      <td>
                        {{ _Outstandingpurchase.INWARDBALANCEQUANTITY }}
                      </td>
                      <td>
                        {{ _Outstandingpurchase.INWARDSTATUS }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </ng-template>
        </p-dialog>
        <p-dialog
          header="Pending GRN PO Wise"
          [(visible)]="_grnwisewise"
          [modal]="true"
          [style]="{ width: '1000px', height: '500px' }"
          [baseZIndex]="10000"
        >
          <ng-template pTemplate="content">
            <div class="popup-body">
              <div class="card">
                <p-toolbar styleClass="p-mb-4">
                    <ng-template pTemplate="left">
                      <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input
                          pInputText
                          type="text"
                          (input)="grnTable.filterGlobal($event.target.value, 'contains')"
                          placeholder="Search..."
                        />
                      </span>
                    </ng-template>
                    <ng-template pTemplate="right">
                      <button
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-file-o"
                        title="Export CSV"
                        (click)="exportGRNExcel()"
                        class="p-mr-2"
                        pTooltip="CSV"
                        tooltipPosition="bottom"
                      ></button>
                      <button
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-file-excel"
                        title="Export Excel"
                        class="p-button-success p-mr-2"
                        pTooltip="XLS"
                        tooltipPosition="bottom"
                        (click)="exportGRNExcel()"
                      ></button>
                      <button
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-file-pdf"
                        title="Export PDF"
                        class="p-button-warning p-mr-2"
                        pTooltip="PDF"
                        tooltipPosition="bottom"
                        (click)="GRNExportToPdf()"
                      ></button>
                    </ng-template>
                  </p-toolbar>
                <p-table
                  #grnTable
                  [value]="_Outstandinggrnpurchases"
                  [rows]="10"
                  [paginator]="true"
                  [globalFilterFields]="['PURCHASEORDERNO','PRODUCTNAME','POQUANTITY','GRNBALANCEQUANTITY','GRNSTATUS']"
                  [rowsPerPageOptions]="[10, 25, 50, 100]"
                  [rowHover]="true"
                  dataKey="GRNBALANCEQUANTITY"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  [showCurrentPageReport]="true"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" style="width: 60px">S.No</th>
                      <th pSortableColumn="PURCHASEORDERNO">
                        Purchase Order No
                        <p-sortIcon field="PURCHASEORDERNO"> </p-sortIcon>
                      </th>
                      <th pSortableColumn="PURCHASEORDERDATE">
                        Purchase Order Date
                        <p-sortIcon field="PURCHASEORDERDATE"> </p-sortIcon>
                      </th>
                      <th pSortableColumn="PRODUCTNAME">
                        Product Name
                        <p-sortIcon field="PRODUCTNAME"> </p-sortIcon>
                      </th>
                      <th pSortableColumn="POQUANTITY">
                        Purchase Order Qty
                        <p-sortIcon field="POQUANTITY"> </p-sortIcon>
                      </th>
                      <th pSortableColumn="GRNBALANCEQUANTITY">
                        GRN Pending Qty
                        <p-sortIcon field="GRNBALANCEQUANTITY"> </p-sortIcon>
                      </th>
                      <th pSortableColumn="GRNSTATUS">
                        Status
                        <p-sortIcon field="GRNSTATUS"> </p-sortIcon>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="body"
                    let-rowData
                    let-_Outstandinggrnpurchase
                    let-i="rowIndex"
                  >
                    <tr>
                      <td class="text-center" style="width: 60px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        {{ _Outstandinggrnpurchase.PURCHASEORDERNO }}
                      </td>
                      <td>
                        {{
                          _Outstandinggrnpurchase.PURCHASEORDERDATE
                            | date : "dd/MM/yyyy"
                        }}
                      </td>
                      <td>
                        {{ _Outstandinggrnpurchase.PRODUCTNAME }}
                      </td>
                      <td>
                        {{ _Outstandinggrnpurchase.POQUANTITY }}
                      </td>
                      <td>
                        {{ _Outstandinggrnpurchase.GRNBALANCEQUANTITY }}
                      </td>
                      <td>
                        {{ _Outstandinggrnpurchase.GRNSTATUS }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </ng-template>
        </p-dialog>
      </div>
    </div>
  </div>
</div>
<p-toast position="bottom-right"></p-toast>
