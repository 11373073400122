import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Branch } from 'src/app/core/Models/Branch';
import { RptoutstandingcreditnoteService } from 'src/app/core/Services/reports/Accounting/RptOutStandingCreditNote/rptoutstandingcreditnote.service';
import { Currencyview } from 'src/app/core/Views/Currencyview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';


type NewType = HotkeysService;

@Component({
  selector: 'app-rpt-outstandingcreditnote',
  templateUrl: './rpt-outstandingcreditnote.component.html',
  styleUrls: ['./rpt-outstandingcreditnote.component.css']
})
export class RptOutstandingcreditnoteComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptoutstandingcreditnoteledgerform: FormGroup;
  _currencylist: Currencyview[] = [];
  _branchlist: Branch[] = [];
  _accountnamelist: Accountledger[];

  _customertypelist = [{ customertype: 'All', value: '4' }, { customertype: 'Credit', value: '1' }, { customertype: 'Default', value: '2' }, { customertype: 'Cash', value: '3' }];
  _userid: number;
  _ReportLists: any[] = [];
  datepipes = new DatePipe("en-US");
  cols: any;
  _statuslist: any[] = [];
  _Action: IAction;
  mindate: Date;
  maxdate: Date;


  constructor
    (
      private utility: CommonUtilities,
      private fb: FormBuilder,
      private _RptoutstandingcreditnoteService: RptoutstandingcreditnoteService,
      private exportUtility: ExportUtility,
      private _router: Router,
      private _CustomExceptionService: CustomExceptionService,
      private _hotkeysService: HotkeysService,
      private _AccessRightsService: AccessRightsService,
      private sanitizer: DomSanitizer
    ) { }

  InitializeForm() {
    this._rptoutstandingcreditnoteledgerform = this.fb.group({
      fromdate: [''],
      todate: [''],
      currencyid: [],
      branchid: [],
      customertype: [],
      ledgerid: [],
      totalcreditamount: [0],
      status: [],
    })

    this._rptoutstandingcreditnoteledgerform.get("currencyid").setValue(1);
    this._rptoutstandingcreditnoteledgerform.get("customertype").setValue('4');

  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(327);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.cols = [
      { field: 'creditnoteno', header: 'Credit Note No' },
      { field: 'creditnotedate', header: 'Credit Note Date' },
      { field: 'branchname', header: 'Branch Name' },
      { field: 'accountname', header: 'Account Name' },
      { field: 'referenceno', header: 'Reference No' },
      { field: 'currencyname', header: 'Currency Name' },
      { field: 'creditamount', header: 'Credit Amount' }
    ]
    this.InitializeForm();

    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptoutstandingcreditnoteledgerform.get("fromdate").setValue(new Date);
      this._rptoutstandingcreditnoteledgerform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptoutstandingcreditnoteledgerform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptoutstandingcreditnoteledgerform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._userid = parseInt(sessionStorage["userid"]);


    this._RptoutstandingcreditnoteService.PageOnload({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._currencylist = resultJSON.currencyList;
      this._branchlist = resultJSON.branchList;
      this._statuslist = resultJSON.creditnotestatus;
      this.OnChangeCustomerType();
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; }
    );
  }
  OnChangeCustomerType() {

    var customertype = this._rptoutstandingcreditnoteledgerform.get("customertype").value;

    let data = { "userid": this._userid, "customertype": customertype }

    this._IsProgressSpinner = true;
    this._RptoutstandingcreditnoteService.OnChangeCustomerType(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accountnamelist = resultJSON.accountnameList;
      this._IsProgressSpinner = false;

    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GenerateReport(event) {

    var _fromdate = this._rptoutstandingcreditnoteledgerform.get("fromdate").value;
    var _todate = this._rptoutstandingcreditnoteledgerform.get("todate").value;
    var _currencyid = this._rptoutstandingcreditnoteledgerform.get("currencyid").value;
    var _branchid = this._rptoutstandingcreditnoteledgerform.get("branchid").value;
    var _ledgerid = this._rptoutstandingcreditnoteledgerform.get("ledgerid").value;
    var _status = this._rptoutstandingcreditnoteledgerform.get("status").value || "";

    var datePipe = new DatePipe("en-US");

    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');

    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than To Date");
      return;
    }

    let data = {
      "FromDate": fromdate,
      "ToDate": todate,
      "CurrencyId": _currencyid,
      "UserId": this._userid,
      "BranchId": _branchid || [],
      "LedgerId": _ledgerid || [],
      "Status": _status
    }

    this._IsProgressSpinner = true;
    this._RptoutstandingcreditnoteService.Search(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ReportLists = [];
      this._rptoutstandingcreditnoteledgerform.get("totalcreditamount").setValue(0);

      if (!resultJSON.rptoutStandingCreditNotes || !resultJSON.rptoutStandingCreditNotes.length) {
        this._CustomExceptionService.handleError("No Data Available");

      }
      else {
        this._ReportLists = resultJSON.rptoutStandingCreditNotes;
        this._ReportLists.forEach(i => {
          if (i.paymentdate == '1900-01-01T00:00:00') {
            i.paymentdate = null;
          }
        });

        this._rptoutstandingcreditnoteledgerform.get("totalcreditamount").setValue(resultJSON.totalcreditamount);
      }

      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._rptoutstandingcreditnoteledgerform.reset();
    this.InitializeForm();
    this._rptoutstandingcreditnoteledgerform.controls['fromdate'].setValue(new Date());
    this._rptoutstandingcreditnoteledgerform.controls['todate'].setValue(new Date());
    this._rptoutstandingcreditnoteledgerform.controls['currencyid'].setValue(1);
    this._rptoutstandingcreditnoteledgerform.controls['customertype'].setValue("4");
    this._ReportLists = [];
  }
  exportExcel() {
    let outstandingreportLists = this.GetOutStandingReportList();
    this.exportUtility.exportExcel(outstandingreportLists, 'OutStanding_Credit_Note_Report', 'xlsx');
  }

  GetOutStandingReportList() {
    let outstandingreportLists = [];

    this._ReportLists.forEach((element, index) => {
      let accountledger: any = {};
      accountledger["Credit Note No"] = element.creditnoteno;
      accountledger["Credit Note Date"] = this.datepipes.transform(element.creditnotedate, 'dd/MM/yyyy'),
        accountledger["Branch Name"] = element.branchname;
      accountledger["Account Name"] = element.accountname;
      accountledger["Reference No"] = element.referenceno;
      accountledger["Adjusted Branch"] = element.adjustbranchname;
      accountledger["Adjusted Invoice No"] = element.adjustedinvoiceno;
      accountledger["Adjusted Invoice Amount"] = element.adjustedinvoiceamount;
      accountledger["Currency Name"] = element.currencyname;
      accountledger["Payment Voucher No"] = element.paymentvoucherno;
      accountledger["Payment Amount"] = element.paymentamount;
      accountledger["Payment Date"] = this.datepipes.transform(element.paymentdate, 'dd/MM/yyyy');
      accountledger["Credit Amount"] = element.creditamount;
      accountledger["Status"] = element.currentstatus;
      outstandingreportLists.push(accountledger);
    });

    return outstandingreportLists;
  }
  ExportToPdf() {

    let outstandingreportLists = this.GetOutStandingReportList();
    const data = outstandingreportLists.map(Object.values);
    const head = [['Credit Note No', 'Credit Note Date', 'Branch Name', 'Account Name', 'Reference No', 'Adjusted Branch', 'Adjusted Invoice No', 'Adjusted Invoice Amount', 'Currency Name', 'Payment Voucher No', 'Payment Amount', 'Payment Date', 'Credit Amount', 'Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('OutStanding_Credit_Note_Report.pdf');
      })
    })
  }
}
