<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Purchase vs Sales Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptproductvssalesform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="sproductvssalesdate"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="1980:2030"  [disabled]="isDisablededit">
                                    </p-calendar>
                                    <label for="sproductvssalesdate">Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;" hidden>
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                        [maxDate]="this._rptproductvssalesform.get('todate').value"
                                        [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="fromdate">From Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;" hidden>
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                        [minDate]="this._rptproductvssalesform.get('fromdate').value"
                                        [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="todate">To Date</label>
                                </span>
                            </td>


                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_BranchList" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchids"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProductCategory()"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="branchid">Branch</label>
                                </span>
                            </td>

                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_producttypelist" optionLabel="producttypename"
                                        optionValue="producttypeid" [(ngModel)]="producttypeid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProductCategory()"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                                        optionValue="productcategoryid" [selectionLimit]="50" [(ngModel)]="productcatid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProduct()"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productlist" optionLabel="productname"
                                        optionValue="productid" [(ngModel)]="productid"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" icon="pi pi-search" (click)="Search()" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="Clear()" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>


                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="PurchaseVsSaleslist" [scrollable]="true" scrollHeight="450px" [rows]="500" [paginator]="true" [rowsPerPageOptions]="[100,200,300]" [rowsPerPageOptions]="[500,1000,1500,2000]" [globalFilterFields]="['BRANCHNAME','PRODUCTTYPENAME','PRODUCTCATEGORYNAME','PRODUCTCODE','PRODUCTNAME','SALESUOM','PURCHASEQUANTITY','SALESQUANTITY']"
                        [rowHover]="true" dataKey="productname" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">

                            <tr>
                                <th style="text-align: center;width: 50px;">S:No
                                </th>
                                <th style="text-align: center;width: 150px;" pSortableColumn="BRANCHNAME">Branch
                                    <p-sortIcon field="BRANCHNAME">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;width: 150px;" pSortableColumn="PRODUCTTYPENAME">Product Type
                                    <p-sortIcon field="PRODUCTTYPENAME">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;width: 200px;" pSortableColumn="PRODUCTCATEGORYNAME">Product Category
                                    <p-sortIcon field="PRODUCTCATEGORYNAME">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;width: 120px;" pSortableColumn="PRODUCTCODE">Product Code
                                    <p-sortIcon field="PRODUCTCODE">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;width: 250px;" pSortableColumn="PRODUCTNAME">Product Name
                                    <p-sortIcon field="PRODUCTNAME">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;width: 120px;" pSortableColumn="SALESUOM">Product UOM

                                    <p-sortIcon field="SALESUOM">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center; width: 120px;" pSortableColumn="PURCHASEQUANTITY">
                                    Purchase Qty

                                    <p-sortIcon field="PURCHASEQUANTITY">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;width: 100px;" pSortableColumn="SALESQUANTITY">Sales Qty

                                    <p-sortIcon field="SALESQUANTITY">
                                    </p-sortIcon>
                                </th>
                                <!-- <th style="text-align: center;" pSortableColumn="SALESQUANTITY">Stock in Hand

                                <p-sortIcon field="SALESQUANTITY">
                                </p-sortIcon>
                            </th> -->
                                <!-- <th style="text-align: center;" pSortableColumn="SALESRETURNQTY">Sales Return Qty

                                <p-sortIcon field="SALESRETURNQTY">
                                </p-sortIcon>
                            </th>
                            <th style="text-align: center;" pSortableColumn="STOCKDELIVERYQTY">Stock Delivery Qty

                                <p-sortIcon field="STOCKDELIVERYQTY">
                                </p-sortIcon>
                            </th> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                            <tr>
                                <td style="text-align: center;width: 50px;">
                                    {{i+1}}
                                </td>
                                <td style="text-align: left;width: 150px;">
                                    {{_ReportList.BRANCHNAME}}
                                </td>
                                <td style="text-align: left;width: 150px;">
                                    {{_ReportList.PRODUCTTYPENAME}}
                                </td>
                                <td style="width: 200px;">
                                    {{_ReportList.PRODUCTCATEGORYNAME}}
                                </td>
                                <td style="text-align: center;width: 120px;">
                                    {{_ReportList.PRODUCTCODE}}
                                </td>
                                <td style="text-align: center;width: 250px;">
                                    {{_ReportList.PRODUCTNAME}}
                                </td>
                                <td style="text-align: center;width: 120px;">
                                    {{_ReportList.SALESUOM}}
                                </td>
                                <td style="text-align: right;width: 120px;">
                                    {{_ReportList.PURCHASEQUANTITY}}
                                </td>
                                <td style="text-align: right;width: 100px;">
                                    {{_ReportList.SALESQUANTITY}}
                                </td>
                                <!-- <td style="text-align: center;">
                                {{_ReportList.STOCKINHAND}}
                            </td> -->
                                <!-- <td style="text-align: center;">
                                {{_ReportList.SALESRETURNQTY }}
                            </td>
                            <td style="text-align: center;">
                                {{_ReportList.STOCKDELIVERYQTY}}
                            </td> -->
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

        </div>
    </div>
</div>