<p-toast></p-toast>
<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Create Page in CMS Configuration</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save and Proceed" icon="las la-save"
                            [disabled]="isDisabledSave"   (click)="onSave()"></button>
                            <button pButton pRipple label="" title="Cancel" icon="pi pi-ban" class="p-button-danger"
                            [disabled]="isDisabledClear" (click)="reset($event)"></button>
                            <button pButton pRiple icon="pi pi-search" title="Back to Search"
                                class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>

                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="Cmsheaderform">
                    <table class="normal-table">
                        <tr>
                            <td style="width:20%"></td>
                            <td style="width:20%">
                                <span class="p-float-label">
                                    <input type="text" #pageName pInputText formControlName="pagename"
                                    (keyup)="onKey()">
                                    <label for="inputtext">Page Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_submitted || Cmsheaderform.get('pagename').touched) && Cmsheaderform.get('pagename').errors?.BranchCodeRequired">
                                Please enter Page Name 
                            </span>
                            </td>
                            <td style="width:40%" colspan="2" rowspan="2">
                                <span class="p-float-label">
                                    <textarea  rows="3" cols="30" formControlName="description" pInputTextarea></textarea>
                                    <label for="textarea">Page Description <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_submitted || Cmsheaderform.get('description').touched) && Cmsheaderform.get('description').errors?.BranchCodeRequired">
                                Please enter Page Description
                            </span>
                            </td>
                            <td style="width:20%"></td>
                            <td style="width:20%"></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" id="inputtext" formControlName="pageurlname"
                                        [disabled]="disabled" pInputText>
                                    <label for="inputtext">Page URL <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="3">
                                <p-editor #editor formControlName="template" [readonly]="isDisabledSave" [style]="{'height':'320px'}">
                                    <!-- <ng-template pTemplate="header">
                                        <span class="ql-format-group">
                                            <span title="Bold" class="ql-format-button ql-bold"></span>
                                            <span class="ql-format-separator"></span>
                                            <span title="Italic" class="ql-format-button ql-italic"></span>
                                            <span class="ql-format-separator"></span>
                                            <span title="Underline" class="ql-format-button ql-underline"></span>
                                            <span class="ql-format-separator"></span>
                                            <span title="Strikethrough" class="ql-format-button ql-strike"></span>
                                        </span>
                                    </ng-template> -->
                                </p-editor>
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </form>
            </div>
        </div>
    </div>
</div>