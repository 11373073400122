<div class="page-container ">
    <div class="inner-page-container">
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Account Type </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add"
                                class="p-button p-button-success p-mr-2" [routerLink]="['/cAccountType']"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                            <!--                            <button pButton pRipple label="" icon="pi pi-plus" class="p-button-success p-mr-2"
                                (click)="openNew()"></button>
                            <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger ">
                            </button>-->
                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button>
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_accounttypesview" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['accounttypecode','accounttypename','accounttypestatusname']" [rowHover]="true"
                        dataKey="accounttypecode" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)"
                        (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>

                                <th class="text-center" style="width: 120px;">Action

                                </th>
                                <th pSortableColumn="accounttypecode">Account Type Code <p-sortIcon field="accounttypecode">
                                </p-sortIcon>
                            </th>
                                <th pSortableColumn="accounttypename">Account Type Name <p-sortIcon field="accounttypename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="accounttypestatusname">Status<p-sortIcon field="accounttypestatusname"></p-sortIcon>
                                </th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_AccountTypeview>
                            <tr>

                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="_View" (click)="view(_AccountTypeview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit"
                                        class="p-button p-button-success p-mr-2" [disabled]="_update"
                                        (click)="edit(_AccountTypeview)"></button>
                                    <button pButton pRipple icon="pi pi-trash" title="Delete"
                                        class="p-button p-button-danger" [disabled]="_delete"
                                        (click)="delete(_AccountTypeview)"></button>

                                </td>
                                <td>
                                    {{_AccountTypeview.accounttypecode}}
                                </td>
                                <td>
                                    {{_AccountTypeview.accounttypename}}
                                </td>
                                <td>
                                    {{_AccountTypeview.accounttypestatusname}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

            </div>
        </div>
    </div>
</div>