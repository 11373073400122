export class Role {
    companyid: number;
    roleid: number;
    rolename: string;
    rolestatus: string;
    createdby: string;
    createdon: Date ;
    modifiedby: string;
    modifiedon: Date ;
    effectivefrom: Date ;
    effectiveto: Date;

  
}