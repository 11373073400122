export class Productquestionandanswer {
    productquestionandanswerid: number;
    customerid: number;
    productid: number;
    variantid: number ;
    question: string;
    answer: string;
    answertype: string;
    answerby: string;
    createdby: number;
    createdon: Date ;
    modifiedby: number ;
    modifiedon: Date ;
    recordstatus:string;
    productcode:string;

}

