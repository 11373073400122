import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';


@Injectable({
  providedIn: 'root'
})
export class StockmovementService {
  public _Action: IAction;
  public _StockmovementService = environment.StockmovementService;
  public _httpOptions;
  constructor(private http: HttpClient) { }

  PageOnLoad(): Observable<any> {
    var Query = this._StockmovementService+'/pageload' ;
    
    return this.http.get<{ data: any }>(Query);
  }
  getProductCategories(producttype:object) {
    var Query = this._StockmovementService+'/getProductCategories';
    
    return this.http.post<{ data: any }>(Query, producttype);
  }

  getProducts(productcategory:object) {
    var Query = this._StockmovementService + '/getProducts';
    
    return this.http.post<{ data: any }>(Query, productcategory);
  }
  getProductwise(productwisestock:object) {
    var Query = this._StockmovementService + '/Productwisestock';
    
    return this.http.post<{ data: any }>(Query, productwisestock);
  }
}
