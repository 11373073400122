import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { productsCattegory } from 'src/app/core/Models/overallclass';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { StockmovementService } from 'src/app/core/Services/reports/Stock/StockMovement/stockmovement.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { vBranchMultiNameSelect, vProductCategoryMultiNameSelect, vProductMultiNameSelect, vProductTypeMultiNameSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
//Debug
import * as ace from "ace-builds";
@Component({
  selector: 'app-stockmovement',
  templateUrl: './stockmovement.component.html',
  styleUrls: ['./stockmovement.component.css']
})
export class StockmovementComponent implements OnInit {
  _producttype: Producttype[];
  _productcategory: productsCattegory[];
  _productname: any[];
  _stockmovementform: FormGroup;
  _StockMovementviews: any[];
  _branch: Branch[];
  selectedrows: any;
  today = new Date();
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _IsProgressSpinner: boolean = true;
  _ProductwisestockObj: any;
  cols: any[];
  _submitted = false;
  mindate: Date;
  maxdate: Date;
   //Debug
   @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _StockmovementService: StockmovementService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private exportUtility: ExportUtility,) { }
  InitializeStockMovementForm() {
    this._stockmovementform = this.fb.group({
      producttypeid: ['', vProductTypeMultiNameSelect],
      productcategoryid: ['', vProductCategoryMultiNameSelect],
      productid: ['', vProductMultiNameSelect],
      fromdate: [],
      todate: [],
      branchid: ['', vBranchMultiNameSelect]
    });
    if (sessionStorage['Environmentenddate'] == "null") {
      this._stockmovementform.get("fromdate").setValue(new Date);
      this._stockmovementform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._stockmovementform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._stockmovementform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeStockMovementForm();
    this.cols = [
      {field:'branchname', header: 'Branch Name'},
      { field: 'productname', header: 'Product Name' },
      { field: 'referenceno', header: 'RefNo' },
      { field: 'openingstock', header: 'Opening Stock' },
      { field: 'closingstock', header: 'Closing Stock' },
      { field: 'transtock', header: 'Moved Stock' },
      { field: 'uom', header: 'UOM' }
    ]
    this._IsProgressSpinner = true;
    this._StockmovementService.PageOnLoad().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._producttype = resultJSON.producttypes;
      this._branch = resultJSON.branches;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductcategory(event) {
    var selectedProductType = event.value;
    var branchid = this._stockmovementform.get("branchid").value || sessionStorage["currentbranchid"];
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._stockmovementform.controls["productcategoryid"].reset();
      this._productcategory = [];
      return;
    }
    this._IsProgressSpinner = true;
    this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": branchid || 0 };
    this._StockmovementService.getProductCategories(this._ProductTypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      let categories = resultJSON.productcategories;
      let uniqueChars = [...new Set(categories)];
      uniqueChars = categories.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.productcategoryid === test.productcategoryid
        )
      );
      this._productcategory = uniqueChars;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  getProductname(event) {
    var selectedProductCategory = event.value;
    var branchid = this._stockmovementform.get("branchid").value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      return;
    }
    this._IsProgressSpinner = true;
    this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": branchid };
    this._StockmovementService.getProducts(this._ProductCategoryObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      let products = resultJSON.productdetails;
      let uniqueChars = [...new Set(products)];
      uniqueChars = products.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.productid === test.productid
        )
      );
      this._productname = uniqueChars;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  clear() {
    this._submitted = true;
    this._stockmovementform.reset();
    this.InitializeStockMovementForm();
    this._submitted = false;
    this._StockMovementviews = [];
    this._productcategory = [];
    this._productname = [];
  }
  search() {
    this._submitted = true;
    if (this._stockmovementform.valid) {
      var _fromdate = this._stockmovementform.get("fromdate").value;
      var _todate = this._stockmovementform.get("todate").value;
      var datePipe = new DatePipe("en-US");
      var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
      var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
      if (fromdate == null) {
        this._CustomExceptionService.handleError("Please Select From Date");
        return;
      }
      if (todate == null) {
        this._CustomExceptionService.handleError("Please Select To Date");
        return;
      }
      if (fromdate > todate) {
        this._CustomExceptionService.handleError("From Date should be less than the To Date");
        return;
      }
      var branchid = this._stockmovementform.get("branchid").value || [];
      var productid = this._stockmovementform.get("productid").value || [];

      this._IsProgressSpinner = true;
      this._ProductwisestockObj = { "ProductId": productid || [], "branchid": branchid || [], "fromdate": fromdate, "todate": todate };
      //  this.onEd();
      this._StockmovementService.getProductwise(this._ProductwisestockObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._StockMovementviews=resultJSON.stockdetails;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  exportExcel() {
    let StockmovementList = this.GetStockmovementList();
    this.exportUtility.exportExcel(StockmovementList, 'Stockmovement', 'xlsx');
  }

  ExportToPdf() {
    let StockmovementList = this.GetStockmovementList();
    this.exportUtility.ExportToPdf(StockmovementList, 'Stockmovement.pdf');
  }

  GetStockmovementList() {
    let StockmovementList = [];
    this._StockMovementviews.forEach(element => {
      let stocktmovement: any = {};
      stocktmovement["Branch Name"] = element.branchname;
      stocktmovement["Product Name"] = element.productname;
      stocktmovement["RefNo"] = element.referenceno;
      stocktmovement["Opening Stock"] = element.openingstock;
      stocktmovement["Closing Stock"] = element.closingstock;
      stocktmovement["Moved Stock"] = element.transtock;
      stocktmovement["UOM"] = element.uom;

      StockmovementList.push(stocktmovement);
    });
    return StockmovementList;
  }
  //  Debug
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._ProductwisestockObj;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
}
