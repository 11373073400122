import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Reward } from 'src/app/core/Models/Reward';
import * as ace from "ace-builds";
import { RewardService } from 'src/app/core/Services/Rewards/reward.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { vMinimumAmount } from 'src/app/core/Validators/validation';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { RewardsModule } from '../../rewards.module';
import { getLocaleDateFormat } from '@angular/common';
import { element } from 'protractor';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-c-reward',
  templateUrl: './c-reward.component.html',
  styleUrls: ['./c-reward.component.css']
})
export class CRewardComponent implements OnInit {
  _RewardDetails: Reward[] = [];
  _rewarddetailsform: FormGroup;
  selectedrows: any;
  _action: string = "create";
  _rewardid: any;
  _RewardOBJ: any;
  _submitted = false;
  isDisabledapplicablerewardpoints = false;
  isDisabledminimumamount = false;
  isDisabledmaximumamount = false;
  isdisabledrewardsvalue = false;
  isDisabledeffectivefrom = false;
  isDisabledeffectiveto = false;
  isDisabledadd = false;
  isDisabledremove = false;
  isDisabledsave = false;
  isDisablereset = false;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"];
  isDisabledsendapparoval = true;
  minDate=new Date()
  _validate: boolean;
  _IsProgressSpinner: boolean = true;
  noDuplicateOccurs: boolean = true;
  duplicateCount = 0;
  MinimumRange: boolean = false;
  _Action: IAction;


  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService, private _RewardService: RewardService,
    private _AccessRightsService: AccessRightsService,
    private _router: Router, private _hotkeysService: HotkeysService) {
    this.HotKeyIntegration();
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(80);
    this.utility.pageLoadScript();
    this._rewarddetailsform = this.fb.group({
      rewardid: [0],
      minimumamount: ['', vMinimumAmount],
      maximumamount: [],
      applicablerewardpoints: [],
      rewardsvalue: [],
      effectivefrom: [''],
      effectiveto: [''],
      rewardstatus: ['MSC00047'],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
    this._rewardid = history.state?.rewardid ? history.state?.rewardid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._IsProgressSpinner = true;
    this._RewardService.PageOnLoad(this._rewardid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;

      if (this._action == 'create') {
        var l_RewardDetail = new Reward();
        l_RewardDetail.createdby = sessionStorage["userid"];
        l_RewardDetail.createdon = new Date();
        l_RewardDetail.modifiedby = sessionStorage["userid"];
        l_RewardDetail.modifiedon = new Date();
        l_RewardDetail.rewardid = 0;
        l_RewardDetail.minimumamount = 0;
        l_RewardDetail.maximumamount = 0;
        l_RewardDetail.applicablerewardpoints = 0;
        l_RewardDetail.effectivefrom = null;
        l_RewardDetail.effectiveto = null;
        l_RewardDetail.rewardsvalue = 0;

        this._RewardDetails.push(l_RewardDetail);

      }
      if (this._action == 'edit' || this._action == 'view') {
        this.isDisablereset = true;
        const updateJSON = JSON.parse(JSON.stringify(result));

        updateJSON.reward.effectivefrom = new Date(updateJSON.reward.effectivefrom);
        updateJSON.reward.effectiveto = new Date(updateJSON.reward.effectiveto);

        this._RewardDetails.push(updateJSON.reward);
      }
      if (this._action == "view") {
        this.isDisabledapplicablerewardpoints = true;
        this.isDisabledminimumamount = true;
        this.isDisabledmaximumamount = true;
        this.isdisabledrewardsvalue = true;
        this.isDisabledeffectivefrom = true;
        this.isDisabledeffectiveto = true;
        this.isDisabledadd = true;
        this.isDisabledremove = true;
        this.isDisabledsave = true;
        this.isDisablereset = true;
      }
    });
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  onRowSelect($event) { }
  onRowUnselect($event) { }
  addReward($event) {

    var rowvalue = 0;
    for (var i = 0; i < this._RewardDetails.length; i++) {
      if (this._RewardDetails[i].tempmaximumamount == null || this._RewardDetails[i].tempmaximumamount == 0) {
        this._CustomExceptionService.handleWarning("Fill the slab detail");
        rowvalue = 1;
      }
    }
    if (rowvalue == 0) {
      var l_RewardDetail = new Reward();
      l_RewardDetail.createdby = sessionStorage["userid"];
      l_RewardDetail.minimumamount = 0;
      l_RewardDetail.maximumamount = 0;
      l_RewardDetail.applicablerewardpoints = 0;
      l_RewardDetail.effectiveto = null;
      l_RewardDetail.effectivefrom = null;
      l_RewardDetail.rewardsvalue = 0;
      l_RewardDetail.createdon = new Date();
      l_RewardDetail.rewardid = 0;
      this._RewardDetails.push(l_RewardDetail);
      this.MinimumRange = true
    }
    for (var i = 1; i < this._RewardDetails.length; i++) {

      this._RewardDetails[i].tempminimumamount = this._RewardDetails[i - 1].tempmaximumamount + 1;
    }
  }
  reset(event) {
    //this._rewarddetailsform.reset();
    for (var i = 0; i < this._RewardDetails.length; i++) {
      this._RewardDetails[i].tempminimumamount = 0;
      this._RewardDetails[i].tempmaximumamount = 0;
      this._RewardDetails[i].tempapplicablerewardpoints = 0;
      this._RewardDetails[i].temprewardsvalue = 0;
      this._RewardDetails[i].effectivefrom = null;
      this._RewardDetails[i].effectiveto = null;

    }
  }
  RemoveReward(pReward: Reward) {
    var index = this._RewardDetails.indexOf(pReward);
    this._RewardDetails.splice(index, 1)
    if (this._RewardDetails.length <= 0) {
      var l_RewardDetail = new Reward();
      l_RewardDetail.createdby = sessionStorage["userid"];
      l_RewardDetail.minimumamount = 0;
      l_RewardDetail.maximumamount = 0;
      l_RewardDetail.applicablerewardpoints = 0;
      l_RewardDetail.effectiveto = null;
      l_RewardDetail.effectivefrom = null;
      l_RewardDetail.rewardsvalue = 0;
      l_RewardDetail.createdon = new Date();
      l_RewardDetail.rewardid = 0;
      this._RewardDetails.push(l_RewardDetail);
      this.MinimumRange = false;
    }
  }

  onSave() {
    this._submitted = true;
    this.validate();
    if (this._validate == true && this.noDuplicateOccurs == true) {

      // Object.keys(this._RewardDetails).map((Index) => {
      // this._RewardDetails.forEach((element,Index)=>{         
      for (let Index = 0; Index < this._RewardDetails.length; Index++) {
        if (this._RewardDetails[Index].effectivefrom == null && !this._RewardDetails[Index].effectivefrom && this._RewardDetails[Index].effectivefrom == undefined) {
          this._RewardDetails[Index].effectivefrom == new Date();
        }
        if (this._RewardDetails[Index].effectiveto == null && !this._RewardDetails[Index].effectiveto && this._RewardDetails[Index].effectiveto == undefined) {
          this._CustomExceptionService.handleError("Please Select Effective To");
          return;
        }
      }
      // });
      this._RewardOBJ = this._RewardDetails;
      if (this._action == 'create') {
        // this.onEd();
        var count = this._RewardOBJ.length;
        this._IsProgressSpinner = true;
        this._RewardService.create(this._RewardOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._rewardid = resutJSON.rewards;
            this.reset(null);
            this._IsProgressSpinner = false;
            this.isDisabledsendapparoval = false;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        this._RewardService.edit(this._RewardOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this._IsProgressSpinner = false;
            this.isDisabledsendapparoval = false;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
    else {
      this._CustomExceptionService.handleError("Please fill atleast one Reward Details")
    }
    // }
  }

  checkDuplicate(event, field, row) {
    if (this._RewardDetails.length > 1) {
      this.duplicateCount = 0;
      this.noDuplicateOccurs = false;
      var value = "";
      var newValue = "";
      for (var i = 0; i < this._RewardDetails.length; i++) {
        if (i == row) {
          continue;
        }
        else {
          value = this._RewardDetails[i][field];
          newValue = event.target.value;
          if (value == newValue) {
            this._CustomExceptionService.handleWarning("Given " + newValue + " already exists at row" + row);
            this.duplicateCount = this.duplicateCount++;
            break;
          }
        }
      }
    }
    if (this.duplicateCount == 0) {
      this.noDuplicateOccurs = true;
    }
  }
  validate(): boolean {
    for (var i = 0; i < this._RewardDetails.length; i++) {
      if (this._RewardDetails[i].tempminimumamount == 0 || this._RewardDetails[i].tempmaximumamount == 0 || this._RewardDetails[i].tempapplicablerewardpoints == 0
        || this._RewardDetails[i].effectivefrom == null) {
        this._validate = false;
      }
      else {
        this._validate = true;
      }
    }
    return this._validate;
  }
  goBack($event) {
    this._router.navigate(['/vReward']);
  }
  onEd() {

    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._RewardOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  SendToApproval(event) {
    if (this._action == 'create') {
      for (var i = 0; i < this._rewardid.length; i++) {
        var workflowid = 55;
        this._userid = sessionStorage["userid"];
        this._branchid = sessionStorage["BranchID"];
        this._IsProgressSpinner = true;
        var rewardid = this._rewardid[i].rewardid;
        this._RewardService.SendToApproval(workflowid, this._userid, this._branchid, rewardid).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
          this.isDisabledsendapparoval = true;
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
    else {
      var workflowid = 55;
      this._userid = sessionStorage["userid"];
      this._branchid = sessionStorage["BranchID"];
      this._IsProgressSpinner = true;
      this._RewardService.SendToApproval(workflowid, this._userid, this._branchid, this._rewardid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
        this.isDisabledsendapparoval = true;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
}
