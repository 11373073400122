import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { Assetregistration } from '../../Models/AssetRegistration';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class AssetregistrationService {
  public _Action: IAction;
  public _AssetregistrationService = environment.AssetregistrationService;
  public _httpOptions;
  public _PettycashList: Assetregistration[] = [];
  token: any;
  header: any;

  constructor(
    private http: HttpClient,
    private _CustomExceptionService: CustomExceptionService
  ){
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(p_Assetregistrationid: string): Observable<any> {
    const headers = this.header;
    var Query = this._AssetregistrationService + '/' + p_Assetregistrationid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll(userid:number,branchid:number,roleid:number,fromdate:string,todate:string) {
    const headers = this.header;
    var Query = this._AssetregistrationService + '/FetchAll'+'/'+userid+'/'+branchid+'/'+roleid+'/'+fromdate+'/'+todate;
   
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }

  getAppreciationOrDepreciationAccount(metasubcode:string)
  {
    const headers = this.header;
    var Query = this._AssetregistrationService +'/getAppreciationOrDepreciationAccount' +'/' + metasubcode; 
   
    return this.http.get<{data:any}>(Query, { headers });
  }
  create(p_Assetregistrationid: object) {
    const headers = this.header;
    console.log("Create");
    var Query = this._AssetregistrationService;
    return this.http.post<{ data: any }>(Query, p_Assetregistrationid, { headers });
  }
  Cancel(Registrationid: number){
    const headers = this.header;
    console.log("Cancel")
    var Query = this._AssetregistrationService + '/Cancel/' + Registrationid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
}
