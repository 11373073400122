import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Currencyview } from 'src/app/core/Views/Currencyview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExportUtility } from 'src/assets/js/export-utility';
import { RptAccountsGroupWiseReportService } from 'src/app/core/Services/accounts/rpt-accounts-group-wise-report.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import autoTable from 'jspdf-autotable';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
@Component({
	selector: 'app-accounts-group-wise-report',
	templateUrl: './accounts-group-wise-report.component.html',
	styleUrls: ['./accounts-group-wise-report.component.css']
})
export class AccountsGroupWiseReportComponent implements OnInit {
	_IsProgressSpinner: boolean = false;
	_rptaccountgroupwiseform: FormGroup;
	_userid: number = parseInt(sessionStorage["userid"]);
	datepipe: any;
	datepipes = new DatePipe("en-US");
	_typelist: any;
	_currencylist: Currencyview[];
	_ReportLists: any[] = [];
	_branchid: number;

	colWidth: any = 100;
	cols: any[] = [];
	_Action: IAction;
	Currency: "USD";
	mindate: Date;
	maxdate: Date;
	constructor(
		private utility: CommonUtilities,
		private fb: FormBuilder,
		private exportUtility: ExportUtility,
		private _RptAccountsGroupWiseReportService: RptAccountsGroupWiseReportService,
		private _CustomExceptionService: CustomExceptionService,
		private _AccessRightsService: AccessRightsService
	) { }
	InitializeForm() {
		this._rptaccountgroupwiseform = this.fb.group({
			fromdate: ['', Validators.required],
			todate: ['', Validators.required],
			currency: [1]
		})
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(21);
		this.utility.pageLoadScript();
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.InitializeForm();
		if (sessionStorage['Environmentenddate'] == "null") {
			this._rptaccountgroupwiseform.get("fromdate").setValue(new Date);
			this._rptaccountgroupwiseform.get("todate").setValue(new Date);
			this.maxdate = new Date();
		}
		else {
			this._rptaccountgroupwiseform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
			this._rptaccountgroupwiseform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
		}
		this._RptAccountsGroupWiseReportService.PageOnLoad({}).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._currencylist = resultJSON.currencylist;
			this._typelist = resultJSON.partytypelist;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	clear(event) {

		this._ReportLists = [];
		this.ngOnInit();
	}
	GenerateReport(event) {
		var _fromdate = this._rptaccountgroupwiseform.get("fromdate").value;
		var _todate = this._rptaccountgroupwiseform.get("todate").value;
		var datePipe = new DatePipe("en-US");
		var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
		var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
		if (!fromdate) {
			this._CustomExceptionService.handleError("Please select from date");
			return;
		}
		if (!todate) {
			this._CustomExceptionService.handleError("Please select To date");
			return;
		}
		if (fromdate > todate) {
			this._CustomExceptionService.handleError("From Date should be less than To Date");
			return;
		}
		if (!this._rptaccountgroupwiseform.valid) {
			this._rptaccountgroupwiseform.markAllAsTouched();
			return;
		}
		let data = {
			"fromdate": _fromdate,
			"todate": _todate,
			"userid": this._userid,
			"currencyid": this._rptaccountgroupwiseform.get('currency').value || 1
		}
		this._IsProgressSpinner = true;
		this._RptAccountsGroupWiseReportService.GenerateReport(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._ReportLists = resultJSON.generatereport || [];
			var onlykeys = this._ReportLists[0];
			this.cols = Object.keys(onlykeys);
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
	}
	exportExcel() {
		let AccountGroupWiseReport = this.GetAccountReport();
		this.exportUtility.exportExcel(AccountGroupWiseReport, 'Account Group Wise Report', 'xlsx');
	}
	ExportToPdf() {
		let AccountGroupWiseReport = this.GetAccountReport();
		const data = AccountGroupWiseReport.map(Object.values);
		const head = [['Parent Group', 'Account Group', 'Ledger Name', 'Opening Balance', 'Credit Amount', 'Debit Amount', 'Closing Balance']]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('p', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					}, headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					}, bodyStyles: {
						textColor: 0,
					}, didDrawCell: (data) => { },
				}); doc.save('Account Group Wise Report.pdf');
			})
		})
	}
	GetAccountReport() {
		let AccountGroupWiseList = [];
		this._ReportLists.forEach((element, index) => {
			let AccountGroupWise: any = {};
			AccountGroupWise["Parent Group"] = element['Parent Group'];
			AccountGroupWise["Account Group"] = element['Account Group'];
			AccountGroupWise["Ledger Name"] = element['Ledger Name'];
			AccountGroupWise["Opening Balance"] = element['Opening Balance'];
			AccountGroupWise["Credit Amount"] = element['Credit Amount'];
			AccountGroupWise["Debit Amount"] = element['Debit Amount'];
			AccountGroupWise["Closing Balance"] = element['Closing Balance'];
			AccountGroupWiseList.push(AccountGroupWise);
		});
		return AccountGroupWiseList;
	}
}