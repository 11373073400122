import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { Journal } from '../../Models/Journal';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class JournalService {
  public _Action: IAction;
  public _JournalService = environment.JournalService;
  public _httpOptions;
  public _JournalList: Journal[] = [];
  token: any;
	header: any;

  constructor(private http: HttpClient, private _CustomExceptionService: CustomExceptionService) {
    this.token = sessionStorage['AuthToken'];
		this.header = this.createHeaders(this.token)	

   }
   createHeaders(token: any): HttpHeaders {
		return new HttpHeaders({
		  'Content-Type': 'application/json',
		  Authorization: `Bearer ${token}`, // Include your token here	
		});
	  }
  PageOnLoad(p_Journalid: number, p_userId: number): Observable<any> {
    const headers = this.header;
    var Query = this._JournalService + '/' + p_Journalid + '/' + p_userId;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
    const headers = this.header;
    var Query = this._JournalService + '/FetchAll/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }
  create(p_Journal: object, userid: number) {
    const headers = this.header;
    console.log("Create");
    var Query = this._JournalService + '/' + userid;
    return this.http.post<{ data: any }>(Query, p_Journal, { headers });
  }
  Cancel(journalid: number, userid: number) {
    const headers = this.header;
    var Query = this._JournalService + '/Cancel' + '/' + userid + '/' + journalid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetReferenceNo(ledgerid: number) {
    const headers = this.header;
    var Query = this._JournalService + '/GetReferenceNo/' + ledgerid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetReferenceNoDetails(ledgerid: number, currencyid: number, refno: string) {
    const headers = this.header;
    var Query = this._JournalService + '/GetReferenceNoDetails/' + ledgerid + '/' + currencyid + '/' + refno;

    return this.http.get<{ data: any }>(Query, { headers });
  }
}
