import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../../domain/appconfig';
@Injectable({
  providedIn: 'root'
})
export class SupplierdashboardService {
  public _SupplierDashBoardService =environment.SupplierDashboardService
  config: AppConfig = {
    theme: 'saga-blue',
    dark: false,
    inputStyle: 'outlined',
    ripple: true
  };
  constructor(private http: HttpClient) { }
  private configUpdate = new Subject<AppConfig>();

  configUpdate$ = this.configUpdate.asObservable();
  // private _customerDashboardService: string;

  updateConfig(config: AppConfig) {
    this.config = config;
    this.configUpdate.next(config);
  }

  getConfig() {
    return this.config;
  }
  PageOnload(): Observable<any> {
    var Query = this._SupplierDashBoardService + '/pageload';
    return this.http.get<{ data: any }>(Query);
  }
  getProductCategory(producttype:object) {
    var Query = this._SupplierDashBoardService+'/getProductCategories';
   
    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory:object) {
    var Query = this._SupplierDashBoardService + '/getProducts';
   
    return this.http.post<{ data: any }>(Query, productcategory);
  }
  ProductWisePurchases(productid: number[], userid: number, supplierid: number, branchid: number, fromdate: Date, todate: Date): Observable<any> {
    let paramvalue = "ProductId="
    paramvalue = paramvalue + productid.join('&ProductId=');
    var datePipe = new DatePipe("en-US");
    var pfromdate = datePipe.transform(fromdate, 'yyyy-MM-dd');
    var ptodate = datePipe.transform(todate, 'yyyy-MM-dd');
    var Query = this._SupplierDashBoardService + '/Productwisepurchase/'  + userid + '/' + supplierid + '/' + branchid + '/' + pfromdate + '/' + ptodate+ '/?' + paramvalue; 
   
    return this.http.get<{ data: any }>(Query);
  }

  Suppliermonthwisepurchase(userid: number, supplierid: number, branchid: number, fromdate: Date, todate: Date): Observable<any> {
    var datePipe = new DatePipe("en-US");
    var pfromdate = datePipe.transform(fromdate, 'yyyy-MM-dd');
    var ptodate = datePipe.transform(todate, 'yyyy-MM-dd');
    var Query = this._SupplierDashBoardService + '/Suppliermonthwisepurchase' + '/' + userid + '/' + supplierid + '/' + branchid + '/' + pfromdate + '/' + ptodate;
   
    return this.http.get<{ data: any }>(Query);
  }

  Supplierwiseoutstanding(supplierid: number, branchid: number): Observable<any> {
    var Query = this._SupplierDashBoardService + '/Supplierwiseoutstanding' + '/' + supplierid + '/' + branchid;
   
    return this.http.get<{ data: any }>(Query);
  }
}