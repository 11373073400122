<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_orderdescriptionform">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Order Description</h3>
                        </div>
                        <div class="col-md-7 text-right">

                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                    [disabled]="isDisabledSave" (click)="onSave()"></button>
                                <!--13. All input fields should be cleared. -->
                                <button pButton pRipple title="Clear" type="button" icon="pi pi-trash"
                                    class="p-button-danger" (click)="reset($event)"
                                    [disabled]="isDisabledClear"></button>
                                <!-- on clicking back button , it Navigates to the search screen -->
                                <button pButton pRiple icon="pi pi-search" title="Back to Search"
                                    class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                                <!-- <button pButton pRipple label="" icon="pi pi-times" class="" (click)="hideDialog()" ></button>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>

                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_OrderStatus"
                                        name="orderstatuscode" optionLabel="metasubdescription"
                                        optionValue="metasubcode" (onChange)="GetSubstatus($event)"
                                        formControlName="orderstatuscode">
                                    </p-dropdown>
                                    <label for="orderstatuscode">Order Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _orderdescriptionform.get('orderstatuscode').touched) && _orderdescriptionform.get('orderstatuscode').errors?.SelectOrderStatus">
                                    Please select order status
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_OrderSubStatus"
                                        name="ordersubstatuscode" optionLabel="metasubdescription"
                                        optionValue="metasubcode" (onChange)="GeSubstatusname($event)"
                                        formControlName="ordersubstatuscode">
                                    </p-dropdown>
                                    <label for="ordersubstatuscode">Order Sub Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _orderdescriptionform.get('ordersubstatuscode').touched) && _orderdescriptionform.get('ordersubstatuscode').errors?.SelectOrderSubStatus">
                                    Please select substatus
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <textarea rows="3" name="orderdescstatusname" pInputTextarea
                                        formControlName="orderdescstatusname"></textarea>
                                    <label for="orderdescstatusname">Order Description <span class="hlt-txt">*</span>
                                    </label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _orderdescriptionform.get('orderdescstatusname').touched) && _orderdescriptionform.get('orderdescstatusname').errors?.OrderStatusDescriptionRequired">
                                    Please enter order description
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>