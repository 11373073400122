import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Brand } from 'src/app/core/Models/Brand';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { RptbranchwisestockService } from 'src/app/core/Services/reports/Stock/RptBranchwiseStock/rptbranchwisestock.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { RptLotWiseStockService } from 'src/app/core/Services/reports/Stock/Rpt-lot-wise-stock/rpt-lot-wise-stock.service';

@Component({
	selector: 'app-rptbranchwisestock',
	templateUrl:
		'./rptbranchwisestock.component.html',
	styleUrls: ['./rptbranchwisestock.component.css']
})
export class RptbranchwisestockComponent implements OnInit {
	_IsProgressSpinner: boolean = true;
	_rptbranchwisestockform: FormGroup;
	_branchlist = sessionStorage["BranchID"];
	_userid: number = parseInt(sessionStorage["userid"]);
	datepipe: any;
	_producttypelist: Producttype[] = [];
	_productcategorylist: Productcategory[];
	_variantlist: Productvariant[];
	_productlist: Productoverallview[] = [];
	_src: SafeResourceUrl;
	filteredProducts: any[];
	_BranchWiseObj: any;
	_ProductTypeObj: any;
	_ProductCategoryObj: any;
	productcatid: number[] = [];
	producttypeid: number[] = [];
	productid: number[] = [];
	branchid: number[] = [];
	_brandlist: any;
	_Tempbrandlist: Brand;
	brandid: number[] = [];
	_stocktypelist: Metadatum[] = [];
	_stockvaluelist: Metadatum[] = [];
	_ProductObj: any;
	_ReportLists: any[] = [];
	cols: any[];
	_branchwisestockViews: any[] = [];
	isdisable = false;
	isDisableaddElement: boolean = true;
	selectedProductRows: any;
	griddetaillockqty: boolean = false;
	griddetaillocked: boolean = false;
	_ProductWiseStockqty: any[] = [];
	_ProductWiselockedqty: any[] = [];
	selectedProductName: any;
	selectedbranchName: any;
	_BrandObj: any;
	_SupplierFliter: any[] = [];

	constructor(
		private utility: CommonUtilities,
		private fb: FormBuilder,
		private _RptbranchwisestockService: RptbranchwisestockService,
		private exportUtility: ExportUtility,
		private _CustomExceptionService: CustomExceptionService,
		private sanitizer: DomSanitizer,
		private _RptLotwisestockService: RptLotWiseStockService,
	) { }
	ngOnInit(): void {
		this.utility.pageLoadScript();
		this.cols = [
			{ field: 'branchname', header: 'Branch Name' },
			{ field: 'productcategoryname', header: 'Category Name' },
			{ field: 'productcode', header: 'Product Code' },
			{ field: 'productname', header: 'Product Name' },
			{ field: 'brandname', header: 'Brand' },
			{ field: 'salesuomname', header: 'Uom' },
			{ field: 'okstock', header: 'UnRestricted Ok Stock' },
			{ field: 'lockedstock', header: 'Blocked / Lock Stock' },
			{ field: 'closingstock', header: 'Closing Stock' },
		]
		this._rptbranchwisestockform = this.fb.group({
			fromdate: [''],
			todate: [''],
			branchid: [0],
			brandid: [0],
			productid: [0],
			currencyid: [0],
			productcategoryid: [0],
			producttypeid: [0],
			variantid: [0],
			stocktype: [],
			withzero: [],
			stockvalue: [],
			supplierid: []

		})
		this._userid = parseInt(sessionStorage["userid"]);
		var dt = new Date();
		dt.setDate(dt.getDate() - 7);
		this._rptbranchwisestockform.controls['fromdate'].setValue(dt);
		this._rptbranchwisestockform.controls['stocktype'].setValue("MSC00243");
		this._rptbranchwisestockform.controls['stockvalue'].setValue("MSC00271");
		this._rptbranchwisestockform.controls['branchid'].setValue(1);
		this._rptbranchwisestockform.controls['brandid'].setValue(0);
		this._rptbranchwisestockform.get("withzero").setValue(true);
		this._IsProgressSpinner = true;
		this._RptbranchwisestockService.PageOnload({}).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._branchlist = resultJSON.branchList;
			this._producttypelist = resultJSON.producttypeList;
			this._stocktypelist = resultJSON.stockType;
			this._stockvaluelist = resultJSON.stockValue;
			this._brandlist = resultJSON.brandList;
			this._Tempbrandlist = resultJSON.brandList;
			this._SupplierFliter = resultJSON.suppliers;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	clear(event) {
		this._rptbranchwisestockform.reset();
		this.productcatid = [];
		this.productid = [];
		this.producttypeid = [];
		this.branchid = [];
		this._productcategorylist = [];
		this._productlist = [];
		this.brandid = [];
		this._ReportLists = [];
		this._rptbranchwisestockform.controls['stocktype'].setValue("MSC00243");
		this._rptbranchwisestockform.controls['stockvalue'].setValue("MSC00271");
		this._rptbranchwisestockform.get("withzero").setValue(true);
		this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
		this.isdisable = false;
	}
	GenerateReport(event) {
		var _fromdate = this._rptbranchwisestockform.get("fromdate").value;
		var _todate = this._rptbranchwisestockform.get("todate").value;
		var datePipe = new DatePipe("en-US");
		var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
		var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
		if (fromdate > todate) {
			this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
			return;
		}
		if (this._userid != 1) {
			if (!this.branchid.length) {
				this._CustomExceptionService.handleError("Please Select Atleast One Branch Name");
				return;
			}
		}
		var branchid = this._rptbranchwisestockform.get("branchid").value == null ? 0 : this._rptbranchwisestockform.get("branchid").value;
		var stocktype = this._rptbranchwisestockform.get("stocktype").value;
		var stockvaluetype = this._rptbranchwisestockform.get("stockvalue").value;
		var withzero = this._rptbranchwisestockform.get("withzero").value;
		var supplierid = this._rptbranchwisestockform.get("supplierid").value;

		this._BranchWiseObj = {
			"Productcategory": this.productcatid || [],
			"Producttype": this.producttypeid || [],
			"Productheader": this.productid || [],
			"branchid": this.branchid || [] || parseInt(sessionStorage["currentbranchid"]),
			"StockType": stocktype,
			"currentbranchid": parseInt(sessionStorage["currentbranchid"]),
			"StockValue": stockvaluetype,
			"userid": this._userid,
			"brandid": this.brandid || [],
			"withzero": withzero,
			"supplierid": supplierid || 0
		};
		this._IsProgressSpinner = true;
		this._RptbranchwisestockService.Print(this._BranchWiseObj).subscribe((result) => {
			this._IsProgressSpinner = false;
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._ReportLists = [];
			if (!resultJSON.reportList || !resultJSON.reportList.length) {
				this._CustomExceptionService.handleError("No Data Available");
			} else {
				this._ReportLists = resultJSON.reportList;
				this._branchwisestockViews = [];
				this._branchwisestockViews = resultJSON.stocklst
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	getProductCategory(event) {
		var selectedProductType = event.value;
		var branchid = this._rptbranchwisestockform.get("branchid").value == null ? 0 : this._rptbranchwisestockform.get("branchid").value;
		this.productcatid = [];
		this.productid = [];
		this._productlist = [];
		if (selectedProductType.length == 0 || selectedProductType == null) {
			this._productcategorylist = [];
			this._rptbranchwisestockform.controls["productcategoryid"].reset();
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductTypeObj = {
				"Producttype": selectedProductType || [],
				"branchid": parseInt(sessionStorage["currentbranchid"])
			};
			this._RptbranchwisestockService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._productcategorylist = resultJSON.productcategories;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	ChangeProduct(event) {
		var selectedProduct = event.value;
		var branchid = this._rptbranchwisestockform.get("branchid").value == null ? 0 : this._rptbranchwisestockform.get("branchid").value;
		if (selectedProduct.length == 0 || selectedProduct == null) {
			this._brandlist = this._Tempbrandlist
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductObj = {
				"Products": selectedProduct || [],
				"branchid": parseInt(sessionStorage["currentbranchid"])
			};
			this._RptbranchwisestockService.getBrand(this._ProductObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				let productbrand = resultJSON.productbrandlist;
				let uniqueChars = [...new Set(productbrand)];
				uniqueChars = productbrand.filter((test, index, array) =>
					index === array.findIndex((findTest) =>
						findTest.brandid === test.brandid
					)
				);
				this._brandlist = uniqueChars;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	filterProducts(event) {
		let filtered: any[] = [];
		let query = (<string>event.query).toLowerCase();
		this.filteredProducts = this._productlist.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
	}
	getProduct(event) {
		var selectedProductCategory = event.value;
		this.productid = [];
		var branchid = this._rptbranchwisestockform.get("branchid").value == null ? 0 : this._rptbranchwisestockform.get("branchid").value;
		if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
			this._productlist = [];
			return;
		} else {
			this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": branchid || parseInt(sessionStorage["currentbranchid"]) };
			this._RptbranchwisestockService.getProduct(this._ProductCategoryObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._productlist = resultJSON.productoverallList;
			});
		}
	}
	showBasicDialog(_ReportList) {
		this._IsProgressSpinner = true;
		var stocktype = this._rptbranchwisestockform.get("stocktype").value;
		let data = {
			"branchid": _ReportList.branchid,
			"productid": _ReportList.productid,
			"stocktype": stocktype,
			"lockedqty": _ReportList.lockedstock || 0,

		}
		this._RptbranchwisestockService.lotquantitypopup(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this.griddetaillockqty = true;

			this._ProductWiseStockqty = resultJSON.productWiseStockqty;
			if (this._ProductWiseStockqty.length) {
				this.selectedProductName = resultJSON.productWiseStockqty[0].PRODUCTNAME;
				this.selectedbranchName = resultJSON.productWiseStockqty[0].BRANCHNAME;
			}
			this._ProductWiselockedqty = resultJSON.productWiselockedqty || [];
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	getProductType() {
		this._brandlist = [];
		this.brandid = [];
		this.producttypeid = [];
		this.productcatid = [];
		this._productcategorylist = [];
		this._productlist = [];
		this.productid = [];
		this._rptbranchwisestockform.controls['stockvalue'].setValue("MSC00271");
		this._rptbranchwisestockform.controls['stocktype'].setValue("MSC00243");
		this._ReportLists = [];

		var selectedSupplier = this._rptbranchwisestockform.get("supplierid").value || 0;

		this._IsProgressSpinner = true;
		this._BrandObj = {
			"Suppliers": selectedSupplier,
			"type": 1
		};
		this._RptLotwisestockService.getBrandforsupplier(this._BrandObj).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._brandlist = resultJSON.brandList;
			this._producttypelist = resultJSON.producttypeList;
			//this._productcategorylist = resultJSON.productcategorylist;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
		if (selectedSupplier == 0) {
			this.ngOnInit();
		}


	}
	showlockedqty(_ReportList) {
		this._IsProgressSpinner = true;
		console.log("function works")
		var stocktype = this._rptbranchwisestockform.get("stocktype").value;
		let data = {
			"branchid": _ReportList.branchid,
			"productid": _ReportList.productid,
			"stocktype": stocktype,
			"lockedqty": _ReportList.lockedstock || 0,

		}
		this._RptbranchwisestockService.lotquantitypopup(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this.griddetaillocked = true;
			// this._ProductWiseStockqty = resultJSON.productWiseStockqty;
			this._ProductWiselockedqty = resultJSON.productWiselockedqty || [];
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	exportExcel() {
		let branchwisestocklists = this.GetCustomerList();
		this.exportUtility.exportExcel(branchwisestocklists, 'Branch Wise Stock', 'xlsx');
	}
	ExportToPdf() {
		let branchwisestocklists = this.GetCustomerList();
		const data = branchwisestocklists.map(Object.values);
		const head = [[
			'Branch',
			'Category',
			'Product Code',
			'Product Name',
			'Brand',
			'Uom',
			'UnRestricted Ok Stock',
			'Blocked / Lock Stock',
			'Closing Stock'
		]]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					}, headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					}, bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('Branch Wise Stock.pdf');
			})
		})
	}
	GetCustomerList() {
		let branchwisestocklists = [];
		this._ReportLists.forEach(element => {
			let branchwisestock: any = {};
			branchwisestock["Branch"] = element.branchname;
			branchwisestock["Category"] = element.productcategoryname;
			branchwisestock["Product Code"] = element.productcode;
			branchwisestock["Product Name"] = element.productname;
			branchwisestock["Brand"] = element.brandname;
			branchwisestock["Uom"] = element.salesuomname;
			branchwisestock["UnRestricted Ok Stock "] = element.okstock || 0;
			branchwisestock["Blocked / Lock Stock"] = element.lockedstock || 0;
			branchwisestock["Closing Stock"] = element.closingstock || 0;
			branchwisestocklists.push(branchwisestock);
		});
		return branchwisestocklists;
	}
	exportPdf1() {
		if (!this._branchwisestockViews || !this._branchwisestockViews.length)
			return;
		let BranchWiseStockList = this.GetCashBooKList1();
		this.exportUtility.ExportToPdf(BranchWiseStockList, 'BranchWiseStock.pdf');
	}
	GetCashBooKList1() {
		if (!this._branchwisestockViews || !this._branchwisestockViews.length)
			return;
		let BranchWiseStockList = [];
		this.cols = Object.keys(this._branchwisestockViews[0]);
		this._branchwisestockViews.forEach(element1 => {
			let branchstock: any = {};
			this.cols.forEach(element2 => {
				branchstock[element2] = element1[element2] || 0;
			});
			BranchWiseStockList.push(branchstock);
		});
		return BranchWiseStockList;
	}
	exportExcel1() {
		let BranchWiseStockList = this.GetCashBooKList1();
		this.exportUtility.exportExcel(BranchWiseStockList, 'BranchWiseStock', 'xlsx');
	}
	OnChangeBranch(event) {
		var userid = parseInt(sessionStorage["userid"]);
		var count = this.branchid.length;
		if (userid == 1)//Arif
		{
			this.isdisable = false;
		}
		else {
			if (count >= 1) {
				this.isdisable = true;
			}
		}
	}
}