<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
          </div>
        <div class="white">
            <form [formGroup]="_Accountledgerform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3> Account Ledger</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save" [disabled]="_isSaveHide"></button>
                                <button pButton pRipple type="button" (click)="reset($event)" title="Clear" [disabled]="_isClearHide"
                                    icon="pi pi-trash" class="" class="p-button-danger"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                    title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="name" formControlName="name">
                                    <label for="name">Account Name<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" 
                                *ngIf="(_submitted || _Accountledgerform.get('name').touched) && _Accountledgerform.get('name').errors?.AccountNameRequired"> 
                                    Please Enter Account Name 
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_parentgroups" optionLabel="accountgroupname"
                                        optionValue="accountgroupid" name="accountgroupid" formControlName="accountgroupid"
                                        [filter]="true" filterBy="accountgroupname" [showClear]="true"
                                        >
                                    </p-dropdown>
                                    <label for="accountgroupid"> Account Group <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" 
                                *ngIf="(_submitted || _Accountledgerform.get('accountgroupid').touched) && _Accountledgerform.get('accountgroupid').errors?.SelectAccountgroup"> 
                                    Please Select Account Group 
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="openingbalance" formControlName="openingbalance">
                                    <label for="openingbalance"> Opening Balance </label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr [hidden]="isHideSeries">
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_series" optionLabel="metasubdescription"
                                        optionValue="metasubid" name="series" formControlName="series"
                                       >
                                    </p-dropdown>
                                    <label for="series"> Series <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" 
                                *ngIf="(_submitted || _Accountledgerform.get('series').touched) && _Accountledgerform.get('series').errors?.SelectSeries"> 
                                    Please Select Series 
                                </span>
                            </td>
                            <td>
                                   <!-- Debug -->
                                   <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>

