import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
//import { CountryService } from 'src/app/core/services/masters/country.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { jsPDF } from "jspdf";
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ConfirmationService } from 'primeng/api';
import { ExportUtility } from 'src/assets/js/export-utility';
import { Competitorview } from 'src/app/core/Views/Competitorview';
import { CompetitorService } from 'src/app/core/Services/masters/competitor.service';

@Component({
  selector: 'app-v-competitor',
  templateUrl: './v-competitor.component.html',
  styleUrls: ['./v-competitor.component.css']
})
export class VCompetitorComponent implements OnInit {

  selectedrows: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _competitorview: Competitorview;
  _competitorsview: Competitorview[];
  _Action: IAction;
  _IsProgressSpinner: boolean = false;

  cols: any[];
  _competitorid: number;
  _userid = sessionStorage["userid"];

  constructor(private fb: FormBuilder, private _CompetitorService: CompetitorService, private router: Router, private utility: CommonUtilities, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService, private exportUtility: ExportUtility) {

  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(153);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();

    this.cols = [
      { field: 'competitorcode', header: 'Competitor Code' },
      { field: 'competitorname', header: 'Competitor Name' },
      { field: 'competitorstatusname', header: 'Competitor Status' }
    ]

    this._IsProgressSpinner = true;
    this._CompetitorService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._competitorsview = resultJSON.competitorviews;
      this.selectedrows = resultJSON.competitorviews;
      this._IsProgressSpinner = false;
     
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  view(event) {
    this.router.navigate(['/cCompetitor'], { state: { competitorid: event.competitorid, action: 'view' } });
  }
  delete(event) {
    alert("delete");
  }
  GoToCreate(event) {
    this.router.navigate(['/cCompetitor']);
  }
  edit(event) {
    // 14.on clicking update button in search grid , it Navigates to the update screen
    this.router.navigate(['/cCompetitor'], { state: { competitorid: event.competitorid, action: 'edit' } });
  }

  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  exportExcel() {
    let competitorList = this.GetCompetitorList();
    this.exportUtility.exportExcel(competitorList, 'Countries', 'xlsx');
  }

  ExportToPdf() {
    let competitorList = this.GetCompetitorList();
    this.exportUtility.ExportToPdf(competitorList, 'Countries.pdf');
  }

  GetCompetitorList() {
    let competitorList = [];
    this._competitorsview.forEach(element => {
      let competitor: any = {};
      competitor["Competitor Name"] = element.competitorname;
      competitor["Competitor Status"] = element.competitorstatusname;
      competitorList.push(competitor);
    });
    return competitorList;
  }

  confirm(event) {
    this._competitorid = event.competitorid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Competitor <b>"' + event.competitorname + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    this._IsProgressSpinner = true;
    this._CompetitorService.Cancel(this._competitorid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_09);
        this._CompetitorService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._competitorsview = resultJSON.competitorviews;
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

}
