<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Vehicle Master</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" [routerLink]="['/cVehicle']" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                                <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                            (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                            pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_vehicleviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['vehicleno', 'vehicletypename','capacity','capacityuomname','engineno','vehiclestatusname']" [rowHover]="true"
                            dataKey="vehicleid" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center;width: 120px;">Action
                                    </th>
                                    <th pSortableColumn="vehicleno">Vehicle Name
                                        <p-sortIcon field="vehicleno"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="vehicletypename">Vehicle Type
                                        <p-sortIcon field="vehicletypename"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="capacity">Vehicle Capacity
                                        <p-sortIcon field="capacity">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="capacityuomname">Vehicle Capacity UOM
                                        <p-sortIcon field="capacityuomname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="engineno">Engine Number
                                        <p-sortIcon field="engineno">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="vehiclestatusname">Vehicle Status
                                        <p-sortIcon field="vehiclestatusname">
                                        </p-sortIcon>
                                    </th>

                                    <th pSortableColumn="createdbyname">Created By
                                        <p-sortIcon field="createdbyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdon">Created On
                                        <p-sortIcon field="createdon">
                                        </p-sortIcon>
                                        <th pSortableColumn="modifiedbyname">Modified By
                                            <p-sortIcon field="modifiedbyname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="modifiedon">Modified On
                                            <p-sortIcon field="modifiedon">
                                            </p-sortIcon>
                                        </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_vehicleview>
                                <tr>
                                    <td class="text-center">
                                        <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2" [disabled]="!_Action._View" (click)="view(_vehicleview)"></button>
                                        <button pButton pRipple icon="las la-pen" title="Edit" [disabled]="!_Action._Update" class="p-button p-button-success p-mr-2" (click)="edit(_vehicleview)"></button>
                                        <button pButton pRipple icon="pi pi-times" title="InActive" [disabled]="_vehicleview.vehiclestatuscode == 'MSC00002' || !_Action._Delete" class="p-button p-button-danger" (click)="confirm(_vehicleview)"></button>

                                    </td>
                                    <td>
                                        {{_vehicleview.vehicleno }}
                                    </td>
                                    <td>
                                        {{_vehicleview.vehicletypename}}
                                    </td>
                                    <td>
                                        {{_vehicleview.capacity }}
                                    </td>
                                    <td>
                                        {{_vehicleview.capacityuomname}}
                                    </td>
                                    <td>
                                        {{_vehicleview.engineno }}
                                    </td>
                                    <td>
                                        {{_vehicleview.vehiclestatusname}}
                                    </td>
                                    <td>
                                        {{_vehicleview.createdbyname}}
                                    </td>
                                    <td>
                                        {{_vehicleview.createdon |date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_vehicleview.modifiedbyname}}
                                    </td>
                                    <td>
                                        {{_vehicleview.modifiedon |date:'dd/MM/yyyy'}}
                                    </td>

                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>