import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/core/domain/appconfig';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Customer } from 'src/app/core/Models/Customer';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Component, OnInit } from '@angular/core';
import { Stockadjustmentdetail } from 'src/app/core/Models/Stockadjustmentdetail';
import { SalesreturndashboardService } from 'src/app/core/Services/dashboard/salesreturndashboard.service';

@Component({
  selector: 'app-salesreturn-dashboard',
  templateUrl: './salesreturn-dashboard.component.html',
  styleUrls: ['./salesreturn-dashboard.component.css']
})
export class SalesreturnDashboardComponent implements OnInit {

  _IsProgressSpinner: boolean = true;

  productsalesdetails: [];

  _Isbarsd: boolean = true; _Islinesd: boolean = true; _Ispiesd: boolean = true; _Isdoughnutsd: boolean = true;
  _Isbarsrd: boolean = true; _Islinesrd: boolean = true; _Ispiesrd: boolean = true; _Isdoughnutsrd: boolean = true;

  lableData: any = [];
  lableDatamonth: any = [];
  trimlableData: any = [];
  trimlableDatamonth: any = [];
  _productname: Productheader[] = [];
  salesValue: any = [];
  salesValuemonth: any = [];
  lableData3: any = [];
  trimlableData3: any = [];
  salesValuepay: any = [];
  salesValuerec: any = [];
  basicData: any;
  basicData1: any;
  basicData2: any;
  _productdetails: Stockadjustmentdetail[] = [];
  basicOptions: any;

  multiAxisData: any;
  _submitted1 = false;
  chartOptions: any;
  _productsubmitted = false;
  subscription: Subscription;
  _salesreturndashboardform: FormGroup;
  _customerwisesalesreturndashboardform: FormGroup;
  filterBranchs: any[] = [];
  _Branches: Branch[] = [];
  _Customers: Customer[];
  filterCustomers: any[] = [];
  config: AppConfig;
  charttype: any;
  userid: number;
  branchid: number;
  fromdate: Date;
  todate: Date;
  customerid: number;
  _producttype: Producttype[] = [];
  _productcategory: Productcategory[] = [];
  constructor(private _salesreturnDashboardService: SalesreturndashboardService, private fb: FormBuilder, private _CustomExceptionService: CustomExceptionService) { }

  ngOnInit() {
    this._salesreturndashboardform = this.fb.group({
      branchid: [],
      fromdate: [],
      todate: [new Date()],
      customerid: [],
      producttypeid: [],
      productcategoryid: [],
      productid: []
    })
    this._customerwisesalesreturndashboardform = this.fb.group({
      branchid: [],
      customerid: [],
    })
    this._Isbarsd = true; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = false;
    this._Isbarsrd= true; this._Islinesrd = false; this._Ispiesrd = false; this._Isdoughnutsrd = false;
    var dt = new Date();
    dt.setDate(dt.getDate() - 30);
    this._salesreturndashboardform.controls['fromdate'].setValue(dt);
    this.config = this._salesreturnDashboardService.config;
    this.updateChartOptions();
    this.subscription = this._salesreturnDashboardService.configUpdate$.subscribe(config => {
      this.config = config;
      this.updateChartOptions();
    });

    this._IsProgressSpinner = true;
    this._salesreturnDashboardService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._Branches = <Branch[]>resultJSON.branches;
      this._Customers = <Customer[]>resultJSON.customers;
      this._producttype = resultJSON.producttypes;
      this.onChangefilterSalesReturn();
      this.onChangefilterMonthWiseSalesReturn();
    });
    this._IsProgressSpinner = false;
  }
  onChangefilterMonthWiseSalesReturn() {
    this._IsProgressSpinner = true;
    var branchid = parseInt(sessionStorage["currentbranchid"]) || 0;
   // var customerid = this._salesreturndashboardform.get("customerid").value.customerid || 0;
    this.userid = parseInt(sessionStorage["userid"]);
    var userid = this.userid;
    var _fromdate = this._salesreturndashboardform.get("fromdate").value;
    var _todate = this._salesreturndashboardform.get("todate").value;
    this._salesreturnDashboardService.MonthwiseSalesReturn(this.userid, branchid, _fromdate,_todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this.lableDatamonth=[]; this.trimlableDatamonth=[]; this.salesValuemonth=[];  this.basicData2 =[];
      for (let i = 0; i < resultJSON.monthwiseSalesReturndetails.length; i++) {
        this.lableDatamonth[i] = resultJSON.monthwiseSalesReturndetails[i].PRODUCTNAME;
        this.trimlableDatamonth[i] = (resultJSON.monthwiseSalesReturndetails[i].MONTH).slice(0, 15) + '...';
        this.salesValuemonth[i] = resultJSON.monthwiseSalesReturndetails[i].SALESRETURNVALUE;
        this.basicData2 = {
          labels: this.trimlableDatamonth,
          datasets: [
            {
              label: '',
              backgroundColor: ['#42A5F5',
              "#FF6384",
              "#36A2EB",
              "#FFCE56"],
              data: this.salesValuemonth
            }
          ]
        };
      }
    });
  }
  MonthwiseSalesReturn(event){
    this._IsProgressSpinner = true;
    var branchid = event.branchid || 0;
   // var customerid = this._salesreturndashboardform.get("customerid").value.customerid || 0;
    this.userid = parseInt(sessionStorage["userid"]);
    var userid = this.userid;
    var _fromdate = this._salesreturndashboardform.get("fromdate").value;
    var _todate = this._salesreturndashboardform.get("todate").value;
    this._salesreturnDashboardService.MonthwiseSalesReturn(this.userid, branchid, _fromdate,_todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this.lableDatamonth=[]; this.trimlableDatamonth=[]; this.salesValuemonth=[];  this.basicData2 =[];
      for (let i = 0; i < resultJSON.monthwiseSalesReturndetails.length; i++) {
        this.lableDatamonth[i] = resultJSON.monthwiseSalesReturndetails[i].PRODUCTNAME;
        this.trimlableDatamonth[i] = (resultJSON.monthwiseSalesReturndetails[i].MONTH).slice(0, 15) + '...';
        this.salesValuemonth[i] = resultJSON.monthwiseSalesReturndetails[i].SALESRETURNVALUE;
        this.basicData2 = {
          labels: this.trimlableDatamonth,
          datasets: [
            {
              label: '',
              backgroundColor: ['#42A5F5',
              "#FF6384",
              "#36A2EB",
              "#FFCE56"],
              data: this.salesValuemonth
            }
          ]
        };
      }
    });
  }

  onChangefilterSalesReturn() {
    this._IsProgressSpinner = true;
    var branchid = parseInt(sessionStorage["currentbranchid"]) || 0;
    var customerid = this._salesreturndashboardform.get("customerid").value || 0;
    this.userid = parseInt(sessionStorage["userid"]);
    var userid = this.userid;
    var _fromdate = this._salesreturndashboardform.get("fromdate").value;
    var _todate = this._salesreturndashboardform.get("todate").value;
    this._salesreturnDashboardService.CustomerMonthWiseSalesReturn(this.userid, customerid, branchid, _fromdate, _todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this.lableData=[]; this.trimlableData=[]; this.salesValue=[]; this.basicData1=[];
      for (let i = 0; i < resultJSON.salesReturndetails.length; i++) {
        this.lableData[i] = resultJSON.salesReturndetails[i].PRODUCTNAME;
        this.trimlableData[i] = (resultJSON.salesReturndetails[i].MONTH).slice(0, 15) + '...';
        this.salesValue[i] = resultJSON.salesReturndetails[i].SALESRETURNVALUE;
        this.basicData1 = {
          labels: this.trimlableData,
          datasets: [
            {
              label: '',
              backgroundColor: ['#42A5F5',
                "#FF6384",
                "#36A2EB",
                "#FFCE56"],
              data: this.salesValue
            }
          ]
        };
      }
    });
  }
  CustomerMonthWiseSalesReturn(event){
    this._IsProgressSpinner = true;
    var branchid = event.branchid || 0;
    var customerid = event.customerid || 0;
    this.userid = parseInt(sessionStorage["userid"]);
    var userid = this.userid;
    var _fromdate = this._salesreturndashboardform.get("fromdate").value;
    var _todate = this._salesreturndashboardform.get("todate").value;
    this._salesreturnDashboardService.CustomerMonthWiseSalesReturn(this.userid, customerid, branchid, _fromdate, _todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this.lableData=[]; this.trimlableData=[]; this.salesValue=[]; this.basicData1=[];
      for (let i = 0; i < resultJSON.salesReturndetails.length; i++) {
        this.lableData[i] = resultJSON.salesReturndetails[i].PRODUCTNAME;
        this.trimlableData[i] = (resultJSON.salesReturndetails[i].MONTH).slice(0, 15) + '...';
        this.salesValue[i] = resultJSON.salesReturndetails[i].SALESRETURNVALUE;
        this.basicData1 = {
          labels: this.trimlableData,
          datasets: [
            {
              label: '',
              backgroundColor: ['#42A5F5',
                "#FF6384",
                "#36A2EB",
                "#FFCE56"],
              data: this.salesValue
            }
          ]
        };
      }
    });
  }
 
  updateChartOptions() {
    if (this.config.dark)
      this.applyDarkTheme();
    else
      this.applyLightTheme();
  }

  applyDarkTheme() {
    this.basicOptions = {
      legend: {
        labels: {
          fontColor: '#ebedef'
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: '#ebedef'
          },
          gridLines: {
            color: 'rgba(255,255,255,0.2)'
          }
        }],
        yAxes: [{
          ticks: {
            fontColor: '#ebedef'
          },
          gridLines: {
            color: 'rgba(255,255,255,0.2)'
          }
        }]
      }
    };
  }

  applyLightTheme() {
    this.basicOptions = {
      legend: {
        labels: {
          fontColor: '#495057'
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: '#495057'
          }
        }],
        yAxes: [{
          ticks: {
            fontColor: '#495057'
          }
        }]
      }
    };
  }

  chartchangesd(charttype1) {

    if (charttype1 == "bar") {
      this._Isbarsd = true; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = false;
    }
    if (charttype1 == "line") {
      this._Isbarsd = false; this._Islinesd = true; this._Ispiesd = false; this._Isdoughnutsd = false;
    }
    if (charttype1 == "pie") {
      this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = true; this._Isdoughnutsd = false;
    }
    if (charttype1 == "doughnut") {
      this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = true;
    }
  }
  // _Isbarsrd: boolean = true; _Islinesrd: boolean = true; _Ispiesrd: boolean = true; _Isdoughnutsrd: boolean = true;

  chartchangesrd(charttype2) {

    if (charttype2 == "bar") {
      this._Isbarsrd = true; this._Islinesrd = false; this._Ispiesrd = false; this._Isdoughnutsrd = false;
    }
    if (charttype2 == "line") {
      this._Isbarsrd = false; this._Islinesrd = true; this._Ispiesrd = false; this._Isdoughnutsrd = false;
    }
    if (charttype2 == "pie") {
      this._Isbarsrd = false; this._Islinesrd = false; this._Ispiesrd = true; this._Isdoughnutsrd = false;
    }
    if (charttype2 == "doughnut") {
      this._Isbarsrd = false; this._Islinesrd = false; this._Ispiesrd = false; this._Isdoughnutsrd = true;
    }
  }

  filterBranch(event) {
    let filtered: any[] = [];
    let query = (<string>event.query).toLowerCase();
    this.filterBranchs = this._Branches.filter(f => f.branchname.toLowerCase().indexOf(query) > -1) || [];
  }
  filterCustomer(event) {
    let filtered: any[] = [];
    let query = (<string>event.query).toLowerCase();
    this.filterCustomers = this._Customers.filter(f => f.customername.toLowerCase().indexOf(query) > -1) || [];
  }
  OnChangeBranch(event) {
    console.log(event);
    this.branchid = event.branchid;

  }
  OnChangeCustomer(event) {
    console.log(event);
    this.customerid = event.customerid;

  }
}
