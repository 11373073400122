<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Ageing Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_rptageingform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar name="Date" formControlName="date" [showIcon]="true"
                                            dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                            [minDate]="mindate" [maxDate]="maxdate" yearRange="2021:2030">
                                        </p-calendar>
                                        <label for="Date"> Date <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_typelist" optionValue="value"
                                            optionLabel="type" (onChange)="onchangepartytype($event)"
                                            formControlName="partytype">
                                        </p-dropdown>
                                        <label for="partytypename">Party Type <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_customertypelist"
                                            optionValue="value" optionLabel="customertype"
                                            (onChange)="onchangecustomertype($event)" formControlName="customertype"
                                            [filter]="true" filterBy="customertype">
                                        </p-dropdown>
                                        <label for="customertype">Type</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [options]="_userlist" [autoDisplayFirst]="false" name="userid"
                                            optionLabel="username" optionValue="userid"
                                            formControlName="_responsibleperson" [virtualScroll]="true" itemSize="30"
                                            (onChange)="onchangeresperson($event)" [showClear]="true"
                                            [filter]="true" [disabled]="isDisabledresper"> 
                                        </p-dropdown>
                                        <label for="userid">Responsible Person </label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_accountnamelist" optionValue="ledgerid"
                                            optionLabel="name" formControlName="customer_supplierid"
                                            displaySelectedLabel="true" [virtualScroll]="true" itemSize="30"
                                            selectedItemsLabel="{0} items selected"
                                            ></p-multiSelect>
                                        <label for="name">Party Name <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_currencylist"
                                            optionValue="currencyid" optionLabel="currencyname" [showClear]="true"
                                            formControlName="transactioncurrencyname" [filter]="true"
                                            filterBy="currencyname">
                                        </p-dropdown>
                                        <label for="currencyname">Transaction Currency</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_currencylist"
                                            optionValue="currencyid" optionLabel="currencyname"
                                            formControlName="currencyid" [filter]="true" filterBy="currencyname">
                                        </p-dropdown>
                                        <label for="currencyname">Report in Currency <span class="hlt-txt">*</span>
                                    </label>
                                    </span>
                                </td>
                                <td style="width:20%">
                                    <div class="p-formgroup-inline">
                                        <div class="p-field-checkbox">
                                            <p-checkbox [(ngModel)]="IsAmountZero" [ngModelOptions]="{standalone: true}" label="Include Zero" binary="true"></p-checkbox>
                                        </div>
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" icon="las la-search" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                    </form>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['NAME','CURRENCYNAME','CURRENT','current','DAYS30','DAYS60','DAYS90',
                            'DAYS120','DAYS150','DAYS180','ABOVEDAYS180','total']" [rowHover]="true" dataKey="NAME" [columns]="cols" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No
                                        <p-sortIcon field="sno"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="NAME">Account Name
                                        <p-sortIcon field="NAME">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="CURRENCYNAME">Currency
                                        <p-sortIcon field="CURRENCYNAME">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="CURRENT">0 Day
                                        <p-sortIcon field="CURRENT">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="DAYS30">30 Days
                                        <p-sortIcon field="DAYS30">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="DAYS60">60 Days
                                        <p-sortIcon field="DAYS60">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="DAYS90">90 Days
                                        <p-sortIcon field="DAYS90">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="DAYS120">120 Days
                                        <p-sortIcon field="DAYS120">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="DAYS150">150 Days
                                        <p-sortIcon field="DAYS150">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="DAYS180">180 Days
                                        <p-sortIcon field="DAYS180">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="ABOVEDAYS180">Above 180 Days
                                        <p-sortIcon field="ABOVEDAYS180">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="total">Total
                                        <p-sortIcon field="total">
                                        </p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                                <tr>
                                    <td class="text-center" style="width: 60px;">
                                        {{i+ 1}}
                                    </td>
                                    <td>
                                        {{_ReportList.NAME}}
                                    </td>
                                    <td>
                                        {{_ReportList.CURRENCYNAME}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_ReportList.CURRENT}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_ReportList.DAYS30}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_ReportList.DAYS60}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_ReportList.DAYS90}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_ReportList.DAYS120}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_ReportList.DAYS150}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_ReportList.DAYS180}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_ReportList.ABOVEDAYS180}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{_ReportList.TOTAL}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>