
 export class Codsettlementdetail {
    codsettlementdetailid: number;
    codsettlementid: number;
    tellerid: number;
    salesdate: Date ;
    deliverydate: Date ;
    amountcollecteddate: Date ;
    totalreceivableamount: number;
    previoussettlementamount: number;
    settlementamount: number;
    createdby: number;
    createdon: Date ;
    overallbalnaceamount:number;
    balanceamount:number;

}