export class Metadatum {
    metadatatypecode: string;
    metatypedescription: string;
    metasubid: number;
    metasubcode: string;
    metasubdescription: string;
    issystemgenerated: string;
    canuseradd: string;
    canuserupdatemetasubdesc: string;
    recordstatus: string;
    subcategory: string;
    createdby: string;
    createdon: Date;
    modifiedby: string;
    modifiedon: Date;
}
