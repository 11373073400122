    export class ApprovalRequest {
        companyid: string;
        branchid: number;
        requesterid: number;
        workflowid: number;
        documentno: string;
        remarks: string;
        logstatus: string;
        approverid: number;
        stageid: string;
        alternateuser: string;
        parentid: string;
        userid: number;
        requestfromdate: Date;
        requesttodate: Date;
        isviewed: boolean;
        documentdetailid:number;
  }