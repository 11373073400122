import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import * as ace from 'ace-builds';
import { RptaccountledgerService } from 'src/app/core/Services/reports/Accounting/RptAccountLedger/rptaccountledger.service';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Port } from 'src/app/core/Models/Port';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Producttype } from 'src/app/core/Models/Producttype';
import { MetadataMaster } from 'src/app/core/Models/overallclass';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Vehicle } from 'src/app/core/Models/Vehicle';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { Currencyview } from 'src/app/core/Views/Currencyview';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Salesinvoice } from 'src/app/core/Models/Salesinvoice';

@Component({
  selector: 'app-rptaccountledger',
  templateUrl: './rptaccountledger.component.html',
  styleUrls: ['./rptaccountledger.component.css'],
})
export class RptaccountledgerComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptaccountledgerform: FormGroup;
  _branchid: number;
  _userid: number;
  datepipe: any;
  datepipes = new DatePipe('en-US');
  _typelist: any;
  // _partytypelist: [{ name: 'Customer', value: '11' }, { name: 'Supplier', value: '15' }];
  _accountnamelist: Accountledger[];
  _currencylist: Currencyview[];
  _branchlist: Branch[];
  _entitylist: Metadatum[];
  _vehiclelist: Vehicle[];
  _IsBranch: boolean = false;
  _IsVehicle: boolean = false;
  minDate: Date;
  maxDate: Date;
  today = new Date();
  _Vouchertypes: any[];
  disabledbillno: boolean = false;
  disabledvoucherno: boolean = true;
  billno: any[] = [];
  _Action: IAction;
  // _typelist = [{ type: 'Customer', value: '11' }, { type: 'Supplier', value: '15' },{type:'Branch',value:'2'},{type:'Product',value:'51'},{type:'Employee',value:'50'},{type:'Bank',value:'6'},{type:'Tax',value:'13'},{type:'Fixed Asset',value:'18'}];
  _customer_supplierlist: Accountledgerview[] = [];
  _customertypelist = [
    { customertype: 'All', value: '4' },
    { customertype: 'Credit', value: '1' },
    { customertype: 'Default', value: '2' },
    { customertype: 'Cash', value: '3' },
  ];
  _suppliertypelist = [
    { customertype: 'All', value: '1' },
    { customertype: 'Product', value: '2' },
    { customertype: 'Vehicle', value: '3' },
    { customertype: 'Service', value: '4' },
    { customertype: 'GPS', value: '5' },
    { customertype: 'Agent', value: '6' },
  ];
  _employeetypelist = [
    { customertype: 'Cash', value: '1' },
    { customertype: 'Salary', value: '2' },
  ];
  _src: SafeResourceUrl;
  _ReportLists: any[] = [];
  _openingbalance: any;
  _closingbalance: any;
  _tempclosingbalance: any;
  _tempfinalclosingbalance: any;
  cols: any;
  _sumdebitamount: any;
  _sumcreditamount: any;
  _statuslist: Metadatum[];
  _tempisbillno: any;
  mindate: Date;
  maxdate: Date;

  constructor(
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private fb: FormBuilder,
    private _RptAccountLedgerService: RptaccountledgerService,
    private exportUtility: ExportUtility,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private sanitizer: DomSanitizer
  ) {}

  InitializeForm() {
    this._rptaccountledgerform = this.fb.group({
      fromdate: [''],
      todate: [''],
      customer_supplierid: [0],
      currencyid: [0],
      r_currencyid: [0],
      partytype: [0],
      vouchertypeid: [],
      openingbalance: [],
      customerremark: [],
      branchid: [],
      entity: [],
      vehicleid: [],
      customertype: [],
      _lopeningbalanace: [],
      _lclosingbalanace: [],
      _TotalDebitamount: [],
      _TotalCreditamount: [],
      status: [],
    });
    this._rptaccountledgerform.get('openingbalance').setValue(true);
    this._rptaccountledgerform.get('customerremark').setValue(false);
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(104);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.cols = [
      { field: 'name', header: 'Ledger Name' },
      { field: 'voucherdate', header: 'Date' },
      { field: 'refno', header: 'Bill No' },
      { field: 'vouchertypedesc', header: 'Voucher Type' },
      { field: 'particulars', header: 'Particulars' },
      { field: 'debitamount', header: 'Debit' },
      { field: 'creditamount', header: 'Credit' },
      { field: 'username', header: 'User' },
    ];
    this.InitializeForm();
    this._branchid = parseInt(sessionStorage['currentbranchid']);
    this._userid = parseInt(sessionStorage['userid']);
    // this.InitializeForm();

    if (sessionStorage['Environmentenddate'] == 'null') {
      this._rptaccountledgerform.get('fromdate').setValue(new Date());
      this._rptaccountledgerform.get('todate').setValue(new Date());
      this.maxdate = new Date();
    } else {
      this._rptaccountledgerform
        .get('fromdate')
        .setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptaccountledgerform
        .get('todate')
        .setValue(new Date(sessionStorage['Environmentenddate']));
    }

    this._rptaccountledgerform.controls['currencyid'].setValue(1);
    this._rptaccountledgerform.controls['customertype'].disable();
    this._rptaccountledgerform.controls['status'].setValue('MSC00001');

    this._RptAccountLedgerService.PageOnload({}).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._currencylist = resultJSON.currencyList;
        this._typelist = resultJSON.partytypeList;
        this._Vouchertypes = resultJSON.vouchertypeList;
        this._branchlist = resultJSON.branchList;
        this._entitylist = resultJSON.entityreferenceList;
        this._vehiclelist = resultJSON.vehicleList;
        this._statuslist = resultJSON.statuslist;
        this.billno = resultJSON.rptAccountLedgerheader;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  OnSelectFromDate(event) {
    //var fromdate=this._rptaccountledgerform.get('fromdate').value
    //this._rptaccountledgerform.get("todate").setValue(fromdate);
  }
  clear(event) {
    this._rptaccountledgerform.controls['customertype'].disable();
    //25.If clear clicking all fields should clear in grid
    this._rptaccountledgerform.reset();
    this.InitializeForm();
    // var dt = new Date();
    // dt.setDate(dt.getDate() - 7);
    this._rptaccountledgerform.controls['fromdate'].setValue(new Date());
    this._rptaccountledgerform.controls['todate'].setValue(new Date());
    this._rptaccountledgerform.controls['currencyid'].setValue(1);
    this._rptaccountledgerform.controls['status'].setValue('MSC00001');
    this._IsBranch = false;
    this._IsVehicle = false;
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this._ReportLists = [];
  }

  GenerateReport(event) {
    var BranchID = parseInt(sessionStorage['currentbranchid']);
    var _fromdate = this._rptaccountledgerform.get('fromdate').value;
    var _todate = this._rptaccountledgerform.get('todate').value;
    var datePipe = new DatePipe('en-US');
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var userid = this._userid;
    // if(userid == 1 || userid == 511 || userid == 512){
    //   this.disabledbillno = true;
    //   this.disabledvoucherno = false;
    // }
    // else{
    //   this.disabledbillno = false;
    //   this.disabledvoucherno = true;
    // }
    if (fromdate == null) {
      this._CustomExceptionService.handleError('Please Select From Date');
      return;
    } else if (todate == null) {
      this._CustomExceptionService.handleError('Please Select To Date');
      return;
    } else if (fromdate > todate) {
      this._CustomExceptionService.handleError(
        'From Date should be less than To Date'
      );
      return;
    }
    var customerorsupplier =
      this._rptaccountledgerform.get('customer_supplierid').value == null
        ? 0
        : this._rptaccountledgerform.get('customer_supplierid').value;

    var partytype =
      this._rptaccountledgerform.get('partytype').value == null
        ? 0
        : this._rptaccountledgerform.get('partytype').value;
    var currencyid =
      this._rptaccountledgerform.get('currencyid').value == null
        ? 0
        : this._rptaccountledgerform.get('currencyid').value;
    var openingbalance = this._rptaccountledgerform.get('openingbalance').value;
    var customerremark = this._rptaccountledgerform.get('customerremark').value;
    var voucherid = this._rptaccountledgerform.get('vouchertypeid').value || 0;
    var r_currencyid =
      this._rptaccountledgerform.get('r_currencyid').value || 0;
    if (partytype == null || partytype == 0) {
      this._CustomExceptionService.handleError('Please Select Party Type');
      return;
    }
    if (customerorsupplier == null || customerorsupplier == 0) {
      this._CustomExceptionService.handleError('Please Select Party Name');
      return;
    }

    var entitytype = this._rptaccountledgerform.get('entity').value || 0;
    var entityreference = 0;
    if (entitytype == 'MSC00280') {
      entityreference = this._rptaccountledgerform.get('branchid').value || 0;
    } else if (entitytype == 'MSC00281') {
      entityreference = this._rptaccountledgerform.get('vehicleid').value || 0;
    }

    let data = {
      Effectivefrom: fromdate,
      Effectiveto: todate,
      Ledgerid: customerorsupplier,
      Userid: this._userid,
      Currencyid: currencyid,
      Branchid: BranchID,
      Vouchertypeid: voucherid,
      Reportingcurrencyid: r_currencyid,
      Entitytype: entitytype,
      Entityreference: entityreference,
      Withopeningbalance: openingbalance,
      Customerremark: customerremark,
    };

    this._IsProgressSpinner = true;
    this._RptAccountLedgerService.Print(data).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._ReportLists = [];

        // if (!resultJSON.rptaccountheaderlist || !resultJSON.rptaccountheaderlist.length) {
        //   this._CustomExceptionService.handleError("No Data Available");

        // }
        // else {

        this._ReportLists = resultJSON.rptaccountheaderlist || [];

        this._openingbalance = resultJSON.openingbalance || 0;
        this._closingbalance = resultJSON.closingbalance || 0;
        this._tempclosingbalance = resultJSON.closingbalance || 0;

        this._rptaccountledgerform
          .get('_lopeningbalanace')
          .setValue(resultJSON.openingbalance.toFixed(2));
        this._rptaccountledgerform
          .get('_lclosingbalanace')
          .setValue(resultJSON.closingbalance.toFixed(2));

        this._sumdebitamount = this._ReportLists.reduce(
          (sum, current) => sum + current.debitamount,
          0
        );
        this._sumcreditamount = this._ReportLists.reduce(
          (sum, current) => sum + current.creditamount,
          0
        );

        this._rptaccountledgerform
          .get('_TotalDebitamount')
          .setValue(this._sumdebitamount.toFixed(2));
        this._rptaccountledgerform
          .get('_TotalCreditamount')
          .setValue(this._sumcreditamount.toFixed(2));
          // FOR AARIF AND HARI ACCOUNTS
          this._tempisbillno = resultJSON.isbillnoenabled;
          
          if(this._tempisbillno == 'YES')
          {
            this.disabledbillno = true;
            this.disabledvoucherno = false;
          }
          else
          {
            this.disabledbillno = false;
            this.disabledvoucherno = true;
          }

        this._ReportLists.forEach((element, index) => {
          if (index == 0) {
            this._tempclosingbalance =
              this._openingbalance - element.creditamount + element.debitamount;
            this._ReportLists[index].finalclosingbalance =
              this._tempclosingbalance.toFixed(2);
          } else {
            this._tempfinalclosingbalance =
              this._ReportLists[index - 1].finalclosingbalance -
              element.creditamount +
              element.debitamount;
            this._ReportLists[index].finalclosingbalance =
              this._tempfinalclosingbalance.toFixed(2);
          }
        });
        // }

        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  onchangepartytype(event) {
    this._rptaccountledgerform.get('currencyid').setValue(1);
    this._userid = parseInt(sessionStorage['userid']);
    var customertype =
      this._rptaccountledgerform.get('customertype').value || 0;
    var status = this._rptaccountledgerform.get('status').value;

    var partytype = event.value;

    this._rptaccountledgerform.get('customertype').setValue(0);

    if (partytype == 104 || partytype == 105 || partytype == 164) {
      // CUSTOMER AND SUPPLIER AND EMPLOYEE
      if (partytype == 104) {
        this._customertypelist = [
          { customertype: 'All', value: '4' },
          { customertype: 'Credit', value: '1' },
          { customertype: 'Default', value: '2' },
          { customertype: 'Cash', value: '3' },
        ];
      } else if (partytype == 105) {
        this._customertypelist = this._suppliertypelist = [
          { customertype: 'All', value: '1' },
          { customertype: 'Product', value: '2' },
          { customertype: 'Vehicle', value: '3' },
          { customertype: 'Service', value: '4' },
          { customertype: 'GPS', value: '5' },
          { customertype: 'Agent', value: '6' },
        ];
      } else {
        this._customertypelist = this._employeetypelist = [
          { customertype: 'Cash', value: '1' },
          { customertype: 'Salary', value: '2' },
        ];
      }

      this._rptaccountledgerform.controls['customertype'].enable();
    } else {
      this._rptaccountledgerform.controls['customertype'].disable();
      this._rptaccountledgerform.get('customertype').setValue(0);
    }

    if (partytype == null && partytype == 0) {
      this._CustomExceptionService.handleError('Please Select Party Type');
      return;
    }

    let data = {
      partytype: partytype,
      userid: this._userid,
      customertype: customertype,
      status: status,
    };

    this._IsProgressSpinner = true;
    this._RptAccountLedgerService.onchangepartytype(data).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._accountnamelist = resultJSON.accountnameList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  // NEW
  onchangecustomertype(event) {
    var customertype = event.value || 0;
    this._rptaccountledgerform.get('currencyid').setValue(1);
    this._userid = parseInt(sessionStorage['userid']);
    var partytype = this._rptaccountledgerform.get('partytype').value || 0;
    var status = this._rptaccountledgerform.get('status').value;

    let data = {
      partytype: partytype,
      userid: this._userid,
      customertype: customertype,
      status: status,
    };

    this._IsProgressSpinner = true;
    this._RptAccountLedgerService.onchangepartytype(data).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._accountnamelist = resultJSON.accountnameList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  onchangestatus(event) {
    var status = event.value || 0;
    this._userid = parseInt(sessionStorage['userid']);
    var partytype = this._rptaccountledgerform.get('partytype').value || 0;
    var customertype =
      this._rptaccountledgerform.get('customertype').value || 0;

    let data = {
      partytype: partytype,
      userid: this._userid,
      customertype: customertype,
      status: status,
    };

    this._IsProgressSpinner = true;
    this._RptAccountLedgerService.onchangepartytype(data).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._accountnamelist = resultJSON.accountnameList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  //Get Customer or supplier default currency
  onSelectPartyName(event) {
    var selectedpartyname = event.value;
    var partytype = this._rptaccountledgerform.get('partytype').value;

    let data = { partytype: partytype, id: selectedpartyname };

    if (partytype == '104' || partytype == '105') {
      this._IsProgressSpinner = true;
      this._RptAccountLedgerService.onchangegetcurrencyid(data).subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          var currencyid = resultJSON.currencyid;
          this._rptaccountledgerform
            .get('currencyid')
            .setValue(parseInt(currencyid));
          this._IsProgressSpinner = false;
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
        }
      );
    } else {
      this._rptaccountledgerform.get('currencyid').setValue(1);
    }
  }
  onChange(event) {
    if (this._rptaccountledgerform.get('entity').value == 'MSC00280') {
      this._IsBranch = true;
      this._IsVehicle = false;
    } else {
      this._IsVehicle = true;
      this._IsBranch = false;
    }
  }

  //  EntityType(event) {
  //   var EntityType = event.value
  //   if (EntityType == "MSC0001") {
  //     this._IsSalesReturn = false;
  //     this._IsNotSalesReturn = true;
  //   }
  //   else {
  //     this._IsSalesReturn = true;
  //     this._IsNotSalesReturn = false;
  //   }
  // }
  exportExcel() {
    let accountledgerLists = this.GetaccountledgerList();
    this.exportUtility.exportExcel(
      accountledgerLists,
      'Account Ledger Report',
      'xlsx'
    );
  }

  ExportToPdf() {
    let accountledgerLists = this.GetaccountledgerList();
    const data = accountledgerLists.map(Object.values);
    const head = [
      [
        'Ledger Name',
        'Date',
        'Branch name',
        'Bill No',
        'Voucher Type',
        'Particulars',
        'Opening Balance',
        'Debit',
        'Credit ',
        'Closing Balance',
        'User',
        'Total Closing Balance',
      ],
    ];
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0,
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0,
          },
          didDrawCell: (data) => {},
        });
        doc.save('Account Ledger Report.pdf');
      });
    });
  }

  GetaccountledgerList() {
    let accountledgerLists = [];

    this._ReportLists.forEach((element, index) => {
      let accountledger: any = {};
      accountledger['Ledger Name'] = element.name;
      (accountledger['Date'] = this.datepipes.transform(
        element.voucherdate,
        'dd/MM/yyyy'
      )),
        (accountledger['Branch name'] = element.branchname),
        (accountledger['Bill No'] = element.refno);
      accountledger['Voucher Type'] = element.vouchertypedesc;
      accountledger['Particulars'] = element.particulars;
      if (index == 0) {
        accountledger['Opening Balance'] = this._openingbalance.toFixed(2);
      } else {
        accountledger['Opening Balance'] = '';
      }
      accountledger['Debit'] = element.debitamount;
      accountledger['Credit'] = element.creditamount;
      accountledger['Closing Balance'] = element.finalclosingbalance;
      accountledger['User'] = element.username;

      if (index == 0) {
        //accountledger["Opening Balance"] = this._openingbalance.toFixed(2);
        accountledger['Total Closing Balance'] =
          this._closingbalance.toFixed(2);
      } else {
        //accountledger["Opening Balance"] = "";
        accountledger['Total Closing Balance'] = '';
      }
      accountledgerLists.push(accountledger);
    });
    return accountledgerLists;
  }


// ONLY FOR HARI AND AARIF LOGIN ONLY 
  BillNoNavigation(_ReportList) {
    let Receiptid = 1;
    let Paymentid = 1;
    let userid = _ReportList.userid;
    let voucherno = _ReportList.voucherno;
    let Branchid = sessionStorage['currentbranchid'];
    let Vouchertype = _ReportList.vouchertypeid;
    let Debitnoteid = '1';
    let Invoiceid = 1;
    let contraid = 1;
    let action = 'view';
    let possales = _ReportList.ispossales;

    switch (Vouchertype) {
      case 1: {
        let data = {
          Paymentid: Paymentid,
          userid: userid,
          branchid: Branchid,
          voucherno: voucherno,
          action: action,
        };
        sessionStorage.setItem('LedgerData', JSON.stringify(data));

        let url = this._router.serializeUrl(
          this._router.createUrlTree(['/cPayment'])
        );
        window.open(url, '_blank');
        break;
      }
      case 2: {
        let data = {
          Receiptid: Receiptid,
          userid: userid,
          branchid: Branchid,
          voucherno: voucherno,
          action: action,
        };
        sessionStorage.setItem('LedgerData', JSON.stringify(data));

        let url = this._router.serializeUrl(
          this._router.createUrlTree(['/cReceipt'])
        );
        window.open(url, '_blank');
        break;
      }
      case 3: {
        let data = {
          userid: userid,
          Invoiceid: Invoiceid,
          invoiceno: voucherno,
          Branchid: Branchid,
          action: action,
        };
        sessionStorage.setItem('LedgerData', JSON.stringify(data));

        let url = this._router.serializeUrl(
          this._router.createUrlTree(['/cPurchaseinvoice'])
        );
        window.open(url, '_blank');
        break;
      }
      case 4: {
        let data = { voucherno: voucherno, userid: userid, action: action };
        sessionStorage.setItem('LedgerData', JSON.stringify(data));

        let url = this._router.serializeUrl(
          this._router.createUrlTree(['/CJournal'])
        );
        window.open(url, '_blank');
        break;
      }
      case 5: {
        let data = {
          contraid: contraid,
          userid: userid,
          voucherno: voucherno,
          action: action,
        };
        sessionStorage.setItem('LedgerData', JSON.stringify(data));

        let url = this._router.serializeUrl(
          this._router.createUrlTree(['/cContra'])
        );
        window.open(url, '_blank');
        break;
      }

      case 6: {
        let data = { userid: userid, voucherno: voucherno, action: action };
        sessionStorage.setItem('LedgerData', JSON.stringify(data));

        let url = this._router.serializeUrl(
          this._router.createUrlTree(['/cCreditnote'])
        );
        window.open(url, '_blank');
        break;
      }
      case 7: {
        let data = {
          Debitnoteid: Debitnoteid,
          userid: userid,
          voucherno: voucherno,
          action: action,
        };
        sessionStorage.setItem('LedgerData', JSON.stringify(data));

        let url = this._router.serializeUrl(
          this._router.createUrlTree(['/CDebitnote'])
        );
        window.open(url, '_blank');
        break;
      }
      case 8: {
        let data = {
          salesposinvoiceno: voucherno,
          userid: userid,
          Branchid: Branchid,
          action: action,
        };
        if ((possales == true)) 
        {
          sessionStorage.setItem('LedgerData', JSON.stringify(data));
          let url = this._router.serializeUrl(
            this._router.createUrlTree(['/cSalesinvoicepos'])
          );
          window.open(url, '_blank');
        } 
        else 
        {
          sessionStorage.setItem('LedgerData', JSON.stringify(data));
          let url = this._router.serializeUrl(
            this._router.createUrlTree(['/cSalesInvoicetax'])
          );
          window.open(url, '_blank');
        }

        break;
      }
    }
  }
}
