<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product Price History</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_productpricehistoryform">
                    <table class="normal-table">
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar name="fromdate" formControlName="fromdate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                        [minDate]="mindate" [maxDate]="maxdate"
                                          yearRange="1950:2050">
                                    </p-calendar>
                                    <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                                </span>

                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar name="todate" formControlName="todate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" 
                                        [minDate]="mindate" [maxDate]="maxdate"
                                        [yearNavigator]="true" yearRange="1950:2050">
                                    </p-calendar>
                                    <label for="todate">To Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>

                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_producttype"
                                        optionLabel="producttypename" formControlName="producttypeid"
                                        optionValue="producttypeid" (onChange)="getProductcategory($event)"
                                        displaySelectedLabel=true [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _productpricehistoryform.get('producttypeid').touched) && _productpricehistoryform.get('producttypeid').errors?.SelectProductType">
                                    Please Select Atleast One Product Type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_productcategory"
                                        formControlName="productcategoryid" optionLabel="categoryname"
                                        optionValue="productcategoryid" (onChange)="getProductname($event)"
                                        displaySelectedLabel=true [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _productpricehistoryform.get('productcategoryid').touched) && _productpricehistoryform.get('productcategoryid').errors?.SelectProductCategory">
                                    Please Select Atleast One Product Category
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_productname" formControlName="productid"
                                        optionLabel="productname" optionValue="productid" displaySelectedLabel=true
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _productpricehistoryform.get('productid').touched) && _productpricehistoryform.get('productid').errors?.SelectProductName">
                                    Please Select Atleast One Product Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_levellist" optionValue="levelid"
                                        optionLabel="levelname" formControlName="levelid" [filter]="true"
                                        filterBy="levelname">
                                    </p-dropdown>
                                    <label for="levelname">Markup Lavel</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>

                        <tr>
                            <td>
                                <button pButton pRipple label="Search" type="button" (click)="search()" title="Search" icon="pi pi-search"></button>
                                <button pButton pRipple label="Clear" title="Clear" (click)="clear()" icon="pi pi-times" class="p-button-danger"></button>
                            </td>
                        </tr>

                    </table>
                </form>

                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>

                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>


                    <p-table #dt [value]="_productPriceHistories" [scrollable]="true" scrollHeight="450px" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['changedbyname,productname','purchaserate','markuplevel','markup','usd','rand','bond','bank']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="productname" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="3" pSortableColumn="changedate">Changed Date
                                    <p-sortIcon field="changedate">
                                    </p-sortIcon>
                                </th>
                                <th rowspan="3" pSortableColumn="changedbyname">Changed by
                                    <p-sortIcon field="changedbyname">
                                    </p-sortIcon>
                                </th>
                                <th rowspan="3" pSortableColumn="productname">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th rowspan="3" pSortableColumn="purchaserate">Purchase Rate
                                    <p-sortIcon field="purchaserate">
                                    </p-sortIcon>
                                </th>
                                <th rowspan="3" pSortableColumn="markuplevel">Markup Level
                                    <p-sortIcon field="markuplevel">
                                    </p-sortIcon>
                                </th>
                                <th rowspan="3" pSortableColumn="markup">Markup %
                                    <p-sortIcon field="markup">
                                    </p-sortIcon>
                                </th>

                                <th class="text-center" colspan="2">USD
                                </th>
                                <th class="text-center" colspan="2">RAND
                                </th>
                                <th class="text-center" colspan="2">BOND
                                </th>
                                <th class="text-center" colspan="2">BANK
                                </th>
                            </tr>
                            <tr>
                                <th pSortableColumn="usd">
                                    Include
                                    <p-sortIcon field="usd">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="usd">
                                    Exclude
                                    <p-sortIcon field="usd">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="rand">
                                    Include
                                    <p-sortIcon field="rand">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="rand">
                                    Exclude
                                    <p-sortIcon field="rand">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="bond">
                                    Include
                                    <p-sortIcon field="bond">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="bond">
                                    Exclude
                                    <p-sortIcon field="bond">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="bank">
                                    Include
                                    <p-sortIcon field="bank">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="bank">
                                    Exclude
                                    <p-sortIcon field="bank">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_productPriceHistory let-i="rowIndex">
                            <tr>
                                <td>
                                    {{_productPriceHistory.changedate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_productPriceHistory.changedbyname }}
                                </td>
                                <td>
                                    {{_productPriceHistory.productname }}
                                </td>
                                <td>
                                    {{_productPriceHistory.purchaserateinusd }}
                                </td>
                                <td>
                                    {{_productPriceHistory.levelname }}
                                </td>
                                <td>
                                    {{_productPriceHistory.markuppercentage }}
                                </td>
                                <td>
                                    {{_productPriceHistory.temp_usd_vatin }}
                                </td>
                                <td>
                                    {{_productPriceHistory.temp_usd }}
                                </td>
                                <td>
                                    {{_productPriceHistory.temp_rand_vatin }}
                                </td>
                                <td>
                                    {{_productPriceHistory.temp_rand }}
                                </td>
                                <td>
                                    {{_productPriceHistory.temp_bond_vatin }}
                                </td>
                                <td>
                                    {{_productPriceHistory.temp_bond }}
                                </td>
                                <td>
                                    {{_productPriceHistory.temp_bank_vatin }}
                                </td>
                                <td>
                                    {{_productPriceHistory.temp_bank }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>