import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import * as ace from "ace-builds";
import { Containertracking } from 'src/app/core/Models/Containertracking';
import { RptpurchasepricerevisionService } from 'src/app/core/Services/reports/RptPurchasepricerevision/rptpurchasepricerevision.service';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Port } from 'src/app/core/Models/Port';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { vContainerNo, vDateSelect, vSupplierName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-purchasepricerevision',
  templateUrl: './purchasepricerevision.component.html',
  styleUrls: ['./purchasepricerevision.component.css']
})
export class PurchasepricerevisionComponent implements OnInit {

  //List Declaration:
  // _containertracking: Containertracking;
  _supplierlist: Supplier[];
  _branchlist: Branch[];
  _productlist: Productoverallview[];
  _currencylist: Currency[];
  _productcategorylist: Productcategory[];
  //_shippingLineList: Metadatum[];
  // _portList: Port[];
  _purchasepricerevisionform: FormGroup;
  _branchid: number;
  _userid: number;

  _IsProgressSpinner: boolean = true;
  _src: SafeResourceUrl;
  mindate: Date;
  maxdate: Date;

  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _RptPurchasePriceRevisionService: RptpurchasepricerevisionService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._purchasepricerevisionform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      productid: [0],
      currencyid: [0],
      productcategoryid: [0]
    })
    this._branchid = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    // this.InitializeForm();
    this._IsProgressSpinner = true;
    if (sessionStorage['Environmentenddate'] == "null") {
      this._purchasepricerevisionform.get("fromdate").setValue(new Date);
      this._purchasepricerevisionform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._purchasepricerevisionform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._purchasepricerevisionform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

    this._purchasepricerevisionform.controls['currencyid'].setValue(1);
    this._RptPurchasePriceRevisionService.PageOnload(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._supplierlist = resultJSON.supplierList;
      this._branchlist = resultJSON.branchList;
      this._productlist = resultJSON.productList;
      this._productcategorylist = resultJSON.productcategoryList;
      this._currencylist = resultJSON.currencyList;
      this._IsProgressSpinner = false;
      //this.isDisabledSave = false;
      // this.isDisabledClear = false;


    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  clear(event) {
    //25.If clear clicking all fields should clear in grid
    this._purchasepricerevisionform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._purchasepricerevisionform.get("fromdate").setValue(new Date);
      this._purchasepricerevisionform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._purchasepricerevisionform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._purchasepricerevisionform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._purchasepricerevisionform.controls['currencyid'].setValue(1);
  }
  GenerateReport(event) {

    var _fromdate = this._purchasepricerevisionform.get("fromdate").value;
    var _todate = this._purchasepricerevisionform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    var supplierid = this._purchasepricerevisionform.get("supplierid").value == null ? 0 : this._purchasepricerevisionform.get("supplierid").value;
    var productid = this._purchasepricerevisionform.get("productid").value == null ? 0 : this._purchasepricerevisionform.get("productid").value;
    var productcategoryid = this._purchasepricerevisionform.get("productcategoryid").value == null ? 0 : this._purchasepricerevisionform.get("productcategoryid").value;
    var currencyid = this._purchasepricerevisionform.get("currencyid").value == null ? 0 : this._purchasepricerevisionform.get("currencyid").value;

    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this._RptPurchasePriceRevisionService.Print(fromdate, todate, productid, supplierid, currencyid, this._branchid).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });


  }

}
