import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { SlidermanagementService } from 'src/app/core/Services/ecommerce/slidermanagement.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { UploadFileService } from 'src/app/core/Utilities/fileupload/upload-file.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-v-slider-management',
  templateUrl: './v-slider-management.component.html',
  styleUrls: ['./v-slider-management.component.css']
})
export class VSliderManagementComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _slidermanagementviews: any;
  _slidemanagementview: any;
  _IsProgressSpinner: boolean = true;
  constructor(private fb: FormBuilder,
    private _uploadService: UploadFileService, private _router: Router,
    private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService,
    private _DomSanitizationService: DomSanitizer, private _SliderManagementService: SlidermanagementService,private confirmationService: ConfirmationService,
    private _AccessRightsService: AccessRightsService) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(305);
    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;
    this._slidermanagementviews = null;
    this._SliderManagementService.Fetchall().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      // console.log(resultJSON);
      this._slidermanagementviews = resultJSON.slidemanagementviews;
      for (var i = 0; i < resultJSON.slidemanagementviews.length; i++) {
        this._slidermanagementviews[i].slideurl = this._DomSanitizationService.bypassSecurityTrustUrl((this._slidermanagementviews[i].slideurl));
        // console.log(this._slidermanagementviews[i].slideurl);
      }
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this slide <b>"' + event.slidename + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event){
    var slidermanagementid =event.slidemanagementid;
    this._IsProgressSpinner = true;
    this._SliderManagementService.Delete(slidermanagementid).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.Deleted_Success_00);
      const resultJSON = JSON.parse(JSON.stringify(result));
      // console.log(resultJSON);
      this._IsProgressSpinner = false;
   
      this._SliderManagementService.Fetchall().subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._IsProgressSpinner = false;
        // console.log(resultJSON);
        this._slidermanagementviews = resultJSON.slidemanagementviews;
        for (var i = 0; i < resultJSON.slidemanagementviews.length; i++) {
          this._slidermanagementviews[i].slideurl = this._DomSanitizationService.bypassSecurityTrustUrl((this._slidermanagementviews[i].slideurl));
          // console.log(this._slidermanagementviews[i].slideurl);
        }
        this._IsProgressSpinner = false;
      },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
}
  view(event) {
    this._router.navigate(['/cslidermanagement'], { state: { slidemanagementid: event.slidemanagementid, action: 'view' } });
  }
  edit(event) {
    this._router.navigate(['/cslidermanagement'], { state: { slidemanagementid: event.slidemanagementid, action: 'edit' } });
  }
  GoToCreate(event) {
    this._router.navigate(['/cslidermanagement']);
  }
  
}
