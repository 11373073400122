<p-toast></p-toast>
<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Shipping Charges</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" (click)="Save()" title="Save"
                                icon="las la-save"></button>
                            <!-- <button pButton pRipple label="" title="Cancel" icon="pi pi-ban"
                                class="p-button-danger"></button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
               
                <div class="row">
                    <div class="col-md-4">
                        <div class="sub-title">
                            <h5>Kilometer Based</h5>
                        </div>
                        <div class="card">
                          
                            <p-table #dt [value]="distanceMasterGrid" [rows]="50" [paginator]="true"
                                [rowsPerPageOptions]="[10,25,50,100]"
                                [globalFilterFields]="['min','max','baseCost','addnCost']" [rowHover]="true"
                                dataKey="id"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Action</th>
                                        <th pSortableColumn="min">Min Kilometer <p-sortIcon field="min"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="max">Max Kilometer <p-sortIcon field="max"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="normalCost">Cost for Normal Delivery <p-sortIcon
                                                field="normalCost"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="expressCost">Cost for Express Delivery <p-sortIcon
                                                field="expressCost"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_distanceMasterGrid>
                                    <tr>
                                        <td style="width: 50px" style="text-align:center">
                                            <button pButton pRipple label="" title="Add" icon="pi pi-plus"
                                                class="p-button-success p-mr-2"
                                                (click)="addkilometer(_distanceMasterGrid)"></button>
                                            <button pButton pRipple label="" title="Remove" icon="pi pi-trash"
                                                class="p-button-danger p-mr-2"
                                                *ngIf="!_distanceMasterGrid.isdisabledremove"  (click)="Removekilometer(_distanceMasterGrid)"></button>

                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" [disabled]="isDisabledminimumkm" pInputText
                                                        [(ngModel)]="_distanceMasterGrid.minkm" (keypress)="keypress.kpNumber($event)"
                                                        (focusout)="checkDuplicate($event,'minkm',i)">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_distanceMasterGrid.minkm| number: '1.2'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                  
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" pInputText
                                                        [(ngModel)]="_distanceMasterGrid.maxkm" [disabled]="_distanceMasterGrid.isdisabledremove"
                                                        (focusout)="checkDuplicate($event,'maxkm',i)" (keypress)="keypress.kpNumber($event)"
                                                        (focusout)="onchangemaxkm(_distanceMasterGrid,$event)">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_distanceMasterGrid.maxkm| number: '1.2'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" pInputText
                                                        [(ngModel)]="_distanceMasterGrid.normaldeliverycost" (keypress)="keypress.kpNumber($event)"
                                                        (focusout)="checkDuplicate($event,'normaldeliverycost',i)">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_distanceMasterGrid.normaldeliverycost| number: '1.2'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" pInputText
                                                        [(ngModel)]="_distanceMasterGrid.expressdeliverycost" (keypress)="keypress.kpNumber($event)"
                                                        (focusout)="checkDuplicate($event,'expressdeliverycost',i)">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_distanceMasterGrid.expressdeliverycost| number: '1.2'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                    </tr>

                                </ng-template>
                            </p-table>
                        </div>
                        <table class="normal-table mt20">
                            <tr>
                                <td style="width:63%">
                                    <form [formGroup]="_shippingform">
                                        <span class="p-float-label">
                                            <input type="text" name="additionalcostperkmexceed"
                                                formControlName="additionalcostperkmexceed" pInputText>
                                            <label for="inputtext">Additional Cost / KM <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                    </form>
                                </td>
                                <td style="width:19%"></td>
                                <td style="width:18%"></td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-8">
                        <div class="sub-title">
                            <h5>Weight Based</h5>
                        </div>
                        <div class="card">
                            <p-table #dt [value]="ShippingChargesWeightbased" [rows]="50" [paginator]="true"
                                [rowsPerPageOptions]="[10,25,50,100]"
                                [globalFilterFields]="['itemID','item','price','qty','total','shipmentID','shipmentStatus']"
                                [rowHover]="true" dataKey="id"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Action</th>
                                        <!-- <th pSortableColumn="deliveryType">Delivery Type <p-sortIcon
                                                field="deliveryType"></p-sortIcon>
                                        </th> -->
                                      
                                        <th pSortableColumn="uom">UOM <p-sortIcon field="uom"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="minweight">Min Weight (in Kg) <p-sortIcon
                                                field="minweight"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="maxweight">Max Weight (in Kg) <p-sortIcon
                                                field="maxweight"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="normalCost">Cost for Normal Delivery <p-sortIcon
                                            field="normalCost"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="deliverytime">Normal Delivery Time <p-sortIcon
                                        field="deliverytime"></p-sortIcon>
                                </th>
                                    <th pSortableColumn="expressCost">Cost for Express Delivery <p-sortIcon
                                            field="expressCost"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="deliverytimeforexpressdelivery">Express Delivery Time <p-sortIcon
                                        field="deliverytimeforexpressdelivery"></p-sortIcon>
                                </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_shippingchargesGrid>
                                    <tr>
                                        <td style="width: 50px" style="text-align:center">
                                            <button pButton pRipple label="" title="Add" icon="pi pi-plus"
                                                (click)="addweight(_shippingchargesGrid)"
                                                class="p-button-success p-mr-2"></button>
                                            <button pButton pRipple label="" title="Remove" icon="pi pi-trash"
                                                (click)="removeweight(_shippingchargesGrid)"
                                                *ngIf="!_shippingchargesGrid.isdisabledremove"    class="p-button-danger p-mr-2"></button>

                                        </td>
                                        <!-- <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown #id [autoDisplayFirst]="false"
                                                        [options]="_DeliveryTypes" optionLabel="metasubdescription"
                                                        optionValue="metasubcode"
                                                        [(ngModel)]="_shippingchargesGrid.deliverytypecode">
                                                    </p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                        [options]="_DeliveryTypes" optionLabel="metasubdescription"
                                                        optionValue="metasubcode"
                                                        [(ngModel)]="_shippingchargesGrid.deliverytypecode">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td> -->
                                      
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown #id [autoDisplayFirst]="false" [options]="_UOM"
                                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                                        [(ngModel)]="_shippingchargesGrid.uom">
                                                    </p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                        [options]="_UOM" optionLabel="metasubdescription"
                                                        optionValue="metasubcode"
                                                        [(ngModel)]="_shippingchargesGrid.uom">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" pInputText [disabled]="isDisabledminimumkm"  (keypress)="keypress.kpNumber($event)"
                                                        [(ngModel)]="_shippingchargesGrid.minweight">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_shippingchargesGrid.minweight| number: '1.2'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" pInputText
                                                        [(ngModel)]="_shippingchargesGrid.maxweight"  [disabled]="_shippingchargesGrid.isdisabledremove" 
                                                        (keypress)="keypress.kpNumber($event)"    (focusout)="onchangemaxweight(_shippingchargesGrid,$event)">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_shippingchargesGrid.maxweight| number: '1.2'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <!-- <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" pInputText
                                                    (keypress)="keypress.kpNumber($event)"  [(ngModel)]="_shippingchargesGrid.basecost">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_shippingchargesGrid.basecost| number: '1.2'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td> -->
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" pInputText
                                                        [(ngModel)]="_shippingchargesGrid.normaldeliverycost" (keypress)="keypress.kpNumber($event)"
                                                     >
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_shippingchargesGrid.normaldeliverycost| number: '1.2'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" pInputText
                                                        [(ngModel)]="_shippingchargesGrid.deliverytime">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_shippingchargesGrid.deliverytime}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" pInputText
                                                        [(ngModel)]="_shippingchargesGrid.expressdeliverycost" (keypress)="keypress.kpNumber($event)"
                                                      >
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_shippingchargesGrid.expressdeliverycost| number: '1.2'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" pInputText
                                                        [(ngModel)]="_shippingchargesGrid.deliverytimeforexpressdelivery">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_shippingchargesGrid.deliverytimeforexpressdelivery}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>

                                    </tr>

                                </ng-template>
                            </p-table>
                        </div>
                        <table class="normal-table mt20">
                            <tr>
                                <td style="width:29%">
                                    <form [formGroup]="_shippingform">
                                    <span class="p-float-label">
                                        <input type="text"  formControlName="additionalcostperkg" pInputText>
                                        <label for="inputtext">Additional Cost / Kg <span
                                                class="hlt-txt">*</span></label>
                                    </span>
                                    </form>
                                </td>
                                <td style="width:35%"></td>
                                <td style="width:36%"></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>