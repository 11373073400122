export class Servicerequestassetbaseddetail {
    servicerequestassetbaseddetailid: number;
    servicerequestid: number;
    grnno: string;
    productid: number;
    supplierid: number;
    uomcode: string;
    quantity: number | null;
    unitprice: number | null;
    taxper: number | null;
    taxamount: number | null;
    finalamount: number | null;
    srquantity: number | null;
    status: string;
    iswarrantyapplicable: boolean;
    warrantydate: Date | string | null;
	searchfilter: any;

}