<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Stock Movement</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_stockmovementform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_branch" optionLabel="branchname"
                                        optionValue="branchid" formControlName="branchid" displaySelectedLabel=true
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="branchid">Branch Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _stockmovementform.get('branchid').touched) && _stockmovementform.get('branchid').errors?.SelectBranch">
                                    Please Select Atleast One Branch
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_producttype"
                                        optionLabel="producttypename" formControlName="producttypeid"
                                        optionValue="producttypeid" (onChange)="getProductcategory($event)"
                                        displaySelectedLabel=true
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _stockmovementform.get('producttypeid').touched) && _stockmovementform.get('producttypeid').errors?.SelectProductType">
                                    Please Select Atleast One Product Type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_productcategory"
                                        formControlName="productcategoryid" optionLabel="categoryname"
                                        optionValue="productcategoryid" (onChange)="getProductname($event)"
                                        displaySelectedLabel=true
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _stockmovementform.get('productcategoryid').touched) && _stockmovementform.get('productcategoryid').errors?.SelectProductCategory">
                                    Please Select Atleast One Product Category
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_productname" formControlName="productid"
                                        optionLabel="productname" optionValue="productid" displaySelectedLabel=true
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _stockmovementform.get('productid').touched) && _stockmovementform.get('productid').errors?.SelectProductName">
                                    Please Select Atleast One Product Name
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar name="fromdate" formControlName="fromdate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                        [minDate]="mindate" [maxDate]="maxdate"
                                        yearRange="1950:2050">
                                    </p-calendar>
                                    <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                                </span>

                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar name="todate" formControlName="todate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate"
                                        [yearNavigator]="true" yearRange="1950:2050">
                                    </p-calendar>
                                    <label for="todate">To Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" type="button" (click)="search()" title="Search" icon="pi pi-search"></button>
                                <button pButton pRipple label="Clear" title="Clear" (click)="clear()" icon="pi pi-times" class="p-button-danger"></button>
                            </td>
                        </tr>

                    </table>
                </form>

                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>

                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>

                    </p-toolbar>


                    <p-table #dt [value]="_StockMovementviews" [scrollable]="true" scrollHeight="450px" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['productname','referenceno','openingstock','closingstock','transtock','stockvalues']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="productname" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 80px;" pSortableColumn="sno">S. No
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="branchname">Branch Name
                                    <p-sortIcon field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="openingstock">Opening Stock
                                    <p-sortIcon field="openingstock">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="referenceno">Reference No
                                    <p-sortIcon field="referenceno">
                                    </p-sortIcon>
                                </th>


                                <th pSortableColumn="movedstock">Moved Stock
                                    <p-sortIcon field="movedstock">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="uom"> UOM
                                    <p-sortIcon field="uom">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="closingstock">Closing Stock
                                    <p-sortIcon field="closingstock">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_stockmovementview let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 80px;">
                                    {{i+ 1}}
                                </td>
                                <td>
                                    {{_stockmovementview.branchname }}
                                </td>
                                <td>
                                    {{_stockmovementview.productname }}
                                </td>
                                <td>
                                    {{_stockmovementview.openingstock }}
                                </td>
                                <td>
                                    {{_stockmovementview.referenceno }}
                                </td>

                                <td>
                                    {{_stockmovementview.transtock }}
                                </td>
                                <td>
                                    {{_stockmovementview.uom }}
                                </td>
                                <td>
                                    {{_stockmovementview.closingstock }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                <!-- <table>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </table> -->
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>