<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Teller Cash Settlement</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add"
                                class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast></p-toast>
                <form [formGroup]="_tellercashform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="fromdate" formControlName="fromdate" [showIcon]="true" [minDate]="mindate" [maxDate]="maxdate"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_searchsubmitted || _tellercashform.get('fromdate').touched) && _tellercashform.get('fromdate').errors?.EffectiveFromRequired">
                                    Please Select From Date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="todate" formControlName="todate" [showIcon]="true" [minDate]="mindate" [maxDate]="maxdate"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="todate">To Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_searchsubmitted || _tellercashform.get('todate').touched) && _tellercashform.get('todate').errors?.EffectiveToRequired">
                                    Please Select To Date
                                </span>
                            </td>
                            <td style="width: 20%;">

                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_tellernames" optionLabel="employeename"
                                        optionValue="userid" formControlName="tellerid" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="tellerid">Teller Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_searchsubmitted || _tellercashform.get('tellerid').touched) && _tellercashform.get('tellerid').errors?.SelectTellerName">
                                    Please Select Teller Name
                                </span>
                            </td>

                            <td style="width: 20%;">
                            </td>
                            <td style="width: 20%;">
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" type="button" (click)="SearchTellercash()"
                                    title="Search" icon="pi pi-search"></button>
                                <button pButton pRipple label="Clear" title="Clear" (click)="Clear($event)"
                                    icon="pi pi-times" class="p-button-danger"></button>
                            </td>
                        </tr>

                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>

                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>

                    </p-toolbar>


                    <p-table #dt [value]="_Tellercashviews" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [globalFilterFields]="['tellername','accountname','salesdatestr','totalreceivableamount','totalpaidamount','totalbalanceamount','tellercashstatusdesc','createdbyname','createdon']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="tellerid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 60px;text-align: center;">Action
                                </th>
                                <th pSortableColumn="tellername" style="width: 90px;text-align: center;">Teller Name
                                    <p-sortIcon field="tellername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="accountname" style="width: 90px;text-align: center;">Account Name
                                    <p-sortIcon field="accountname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="branchname" style="width: 150px;text-align: center;display: none;">
                                    Branch Id
                                    <p-sortIcon field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="branchname" style="width: 150px;text-align: center;">Branch Name
                                    <p-sortIcon field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="salesdatestr" style="width: 80px;text-align: center;">Sales Date
                                    <p-sortIcon field="salesdatestr">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname" style="width: 100px;text-align: center;">Currency
                                    Name<p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="totalreceivableamount" style="width: 100px;text-align: center;">
                                    Total Receivable Amount<p-sortIcon field="totalreceivableamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="paidamount" style="width: 80px;text-align: center;">Paid Amount
                                    <p-sortIcon field="paidamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="alreadyissued" style="width: 90px;text-align: center;">Total Paid
                                    Amount<p-sortIcon field="alreadyissued">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="balancetoissued" style="width: 90px;text-align: center;"> Total
                                    Balance Amount<p-sortIcon field="balancetoissued">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tellercashstatusdesc" style="width: 90px;text-align: center;">
                                    Approval Status<p-sortIcon field="tellercashstatusdesc">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname" style="width: 90px;text-align: center;">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon" style="width: 90px;text-align: center;">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_tellercashview>
                            <tr>
                                <td style="width: 60px;text-align: center;">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="_View || !_Action._View" (click)="view(_tellercashview)"></button>
                                    <!-- <button pButton pRipple icon="las la-pen" title="Edit"  [disabled]=""
                                        class="p-button p-button-success p-mr-2" [disabled]="_update || !_Action._Delete"
                                        (click)="edit(_tellercashview)"></button> -->
                                    <button pButton pRipple title="Send To Approval" icon="las la-check" type="button"
                                        [disabled]="_tellercashview.tellercashstatusid != 45"
                                        (click)="SendToApproval(_tellercashview)"></button>
                                </td>
                                <td style="width: 90px;text-align: center;">
                                    {{_tellercashview.tellername }}
                                </td>
                                <td style="width: 90px;text-align: center;">
                                    {{_tellercashview.accountname }}
                                </td>
                                <td style="width: 150px;text-align: center;display: none;">
                                    {{_tellercashview.branchid }}
                                </td>
                                <td style="width: 150px;text-align: center;">
                                    {{_tellercashview.branchname }}
                                </td>
                                <td style="width: 80px;text-align: center;">
                                    {{_tellercashview.salesdatestr}}
                                </td>
                                <td style="width: 100px;text-align: center;">
                                    {{_tellercashview.currencyname}}
                                </td>
                                <td style="width: 90px;text-align: right;">
                                    {{_tellercashview.totalreceivableamount | number: '1.2-4' }}
                                </td>
                                <td style="width: 90px;text-align: right;">
                                    {{_tellercashview.paidamount | number: '1.2-4' }}
                                </td>
                                <td style="width: 90px;text-align: right;">
                                    {{_tellercashview.alreadyissued | number: '1.2-4' }}
                                </td>
                                <td style="width: 90px;text-align: right;">
                                    {{_tellercashview.balancetoissued | number: '1.2-4' }}
                                </td>
                                <td style="width: 90px;text-align: center;">
                                    {{_tellercashview.tellercashstatusdesc}}
                                </td>
                                <td style="width: 90px;text-align: center;">
                                    {{_tellercashview.createdbyname}}
                                </td>
                                <td style="width: 90px;text-align: center;">
                                    {{_tellercashview.createdon|date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>