import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Country } from 'src/app/core/Models/Country';
import { Documentdescription } from 'src/app/core/Models/DocumentDescription';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { DocumentdescriptionService } from 'src/app/core/Services/masters/documentdescription.service';
import { vCountryName, vMinimumAmount, vStatusSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-c-documentdescription',
  templateUrl: './c-documentdescription.component.html',
  styleUrls: ['./c-documentdescription.component.css']
})
export class CDocumentdescriptionComponent implements OnInit {
  _documentdescriptionform: FormGroup;
  _documentdescriptionstatus: Metadatum;
  _documentdescriptiontype: Metadatum;
  _DocumentDescriptions: Documentdescription;
  _documentdescriptionid: number;
  _action: string = "create";
  _submitted = false;
  _IsProgressSpinner: boolean = true;
  isDisabledSave = false;
  isDisabledClear = false;
  _Action: IAction;


  constructor(private fb: FormBuilder, private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _DocumentDescriptionService: DocumentdescriptionService,
    private _route: ActivatedRoute, private _router: Router, private utility: CommonUtilities,
    private _hotkeysService: HotkeysService, private _AccessRightsService: AccessRightsService) {

    this._documentdescriptionid = history.state?.documentdescriptionid ? history.state?.documentdescriptionid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeForm() {

    this._documentdescriptionform = this.fb.group({
      documentdescriptionid: [0],
      documentdescriptionname: ['', vCountryName],
      documentdescriptiontype: ['', vStatusSelect],
      status: ['', vStatusSelect],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],

    })
    this._documentdescriptionform.controls['status'].setValue("MSC00001");
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(146);
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;

    if (this._action == 'view') {
      this._documentdescriptionform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
    }
    if (this._action == 'edit') {

      this.isDisabledClear = true;
      this.isDisabledSave = false;
    }
    this._DocumentDescriptionService.PageOnLoad(this._documentdescriptionid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      this._documentdescriptionstatus = resultJSON.status;
      this._documentdescriptiontype = resultJSON.documentDescriptionType;
      this._documentdescriptionform.controls['status'].setValue("MSC00001");
      this._IsProgressSpinner = false;

      //For Update
      this._documentdescriptionid = history.state._documentdescriptionid;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._documentdescriptionform.setValue(updateJSON.documentdescription);
      }
    });

  }
  onSave() {
    this._submitted = true;
    //8. if all the data is valid allow to save the record in the database 
    if (this._documentdescriptionform.valid) {
      this._DocumentDescriptions = this._documentdescriptionform.value;
      this._IsProgressSpinner = true;
      // this.onEd();
      if (this._action == 'create') {
        this._DocumentDescriptions.createdon = new Date();
        this._DocumentDescriptions.createdby = sessionStorage["userid"];
        this._DocumentDescriptionService.create(this._DocumentDescriptions).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsProgressSpinner = false;
            this._submitted = false;
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        this._DocumentDescriptions.modifiedon = new Date();
        this._DocumentDescriptions.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._DocumentDescriptionService.edit(this._DocumentDescriptions).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this._IsProgressSpinner = false;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }

  reset(event) {
    //11.clear all the fields which the data has entered
    this._documentdescriptionform.reset();
    this.InitializeForm();
    this._submitted = false;
  }
  goBack(event) {
    // 25.on clicking back button , it Navigates to the search screen
    this._router.navigate(['/vdocumentdescription']);
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      //this._hotkeysService.add(new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
      //  this.router.navigate(['/cBank'], { state: { bankid: this._bankid, action: 'edit' }});
      //  return false; // Prevent bubbling
      //}));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

}
