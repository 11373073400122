import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Level } from 'src/app/core/Models/Level';
import { LevelService } from 'src/app/core/Services/masters/level.service';
import { vLevelName, vLevelStatusSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';



@Component({
  selector: 'app-c-level',
  templateUrl: './c-level.component.html',
  styleUrls: ['./c-level.component.css']
})
export class CLevelComponent implements OnInit {
  _levelform: FormGroup;
  _levelstatus: Metadatum;  //23. Status should load from Metadata (1.Active, 2. Inactive) 
  _submitted = false;
  _action: string = "create";
  _Level: Level;
  _levelid: number;
  _IsProgressSpinner: boolean = true;
  isDisabledSave = false;
  isDisabledClear = false;
  _Action: IAction;

  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _LevelService: LevelService, private _router: Router,
    private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,
    )
    {
      this._levelid = history.state?.levelid ? history.state?.levelid : 0;
      this._action = history.state.action == null ? 'create' : history.state.action;
      this.HotKeyIntegration();
    }
  InitializeForm() {
    this._levelform = this.fb.group({
      levelid: [0],
      levelname: ['', vLevelName],
      levelstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
    this._levelform.controls['levelstatus'].setValue("MSC00001");
  }
 
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(23);
    this.utility.pageLoadScript();
    this.InitializeForm();
  

    if (this._action == 'view') {
      this._levelform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
    }
    
    if(this._action == 'edit'){
      this.isDisabledClear = true;
      this.isDisabledSave = false;
    }
    this._IsProgressSpinner = true;
    this._LevelService.PageOnLoad(this._levelid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._levelstatus = resultJSON.levelStatus;
      this._IsProgressSpinner = false;
      this._levelform.controls['levelstatus'].setValue("MSC00001");
      // for update
      this._levelid = history.state.levelid;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._levelform.setValue(updateJSON.level);
      }
    },  
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSave() {
    this._submitted = true;
    if (this._levelform.valid) {
      this._Level = this._levelform.value;
      if (this._action == 'create') {
        this._Level.createdon = new Date();
        this._Level.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._LevelService.create(this._Level).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            //10.after saving the record get the response A, display the success message as "Level A Registered Succesfully "
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
           this.reset(null);
          }
          //7.check in the Level master table weather level name. is already given, show error as "Level No. already Exists"
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        this._Level.modifiedon = new Date();
        this._Level.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._LevelService.edit(this._Level).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
        
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          } 
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
  reset(event) {
    this._submitted = true;
    this._levelform.reset();
    this.isDisabledSave = false;
    this.isDisabledClear = false;
    this.InitializeForm();
    this._submitted = false;
  }
  goBack(event) {
    this._router.navigate(['/vLevel']);
  }
 // Create
 HotKeyIntegration() {
  this._hotkeysService.reset();
  if (this._action != 'view') {
    this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
      this.onSave();
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  if (this._action == 'create') {
    this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
      this.reset(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
    this.goBack(null);
    return false; // Prevent bubbling
  }, ['INPUT', 'SELECT', 'TEXTAREA']));
}

}





