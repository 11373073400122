<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_productcategoryform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Product Category</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save" [disabled]="isDisabledSave"></button>
                                <button pButton pRipple type="button" (click)="reset($event)" title="Clear" icon="pi pi-trash" class="" class="p-button-danger" [disabled]="isDisabledClear"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search" title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_producttypes" name="producttypeid"
                                        [autoDisplayFirst]="false" optionLabel="producttypename" [filter]="true"
                                        filterBy="producttypename" [showClear]="true" formControlName="producttypeid"
                                        optionValue="producttypeid"></p-dropdown>
                                    <label for="producttypeid">Product Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _productcategoryform.get('producttypeid').touched )&& _productcategoryform.get('producttypeid').errors?.ProductTypeNameRequired">
                                    Please select product type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="categoryname" formControlName="categoryname">
                                    <label for="categoryname"> Product Category Name <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _productcategoryform.get('categoryname').touched )&& _productcategoryform.get('categoryname').errors?.CategoryNameRequired">
                                    Please enter category name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="eccategoryname"
                                        formControlName="eccategoryname">
                                    <label for="eccategoryname">Ecommerce Product Category Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <form [formGroup]="_branchwiseproductcategoryform">
                                    <span class="p-float-label">
                                        <p-multiSelect name="branchid" [options]="_branches"
                                            [(ngModel)]="_selectedBranchIndex" formControlName="branchid"
                                            optionLabel="branchname" optionValue="branchid"
                                            (onChange)="OnChangeofBanches($event)">
                                        </p-multiSelect>
                                        <label for="branchid">Branch <span class="hlt-txt">*</span></label>
                                    </span>
                                    <span class="text-danger" *ngIf="(_submitted || _branchwiseproductcategoryform.get('branchid').touched) && _branchwiseproductcategoryform.get('branchid').errors?.SelectBranchName">
                                        Please select atleast one branch
                                    </span>
                                </form>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_categorystatus" optionLabel="metasubdescription"
                                        formControlName="categorystatus" optionValue="metasubcode"
                                        name="categorystatus"></p-dropdown>
                                    <label for="categorystatus"> Status</label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _productcategoryform.get('categorystatus').touched) && _productcategoryform.get('categorystatus').errors?.SelectStatus">
                                    Please select status
                                </span>
                            </td>

                            <td>
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </form>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>