export class Pettycashexpensedetail {
    pettycashexpensedetalid: number;
    pettycashexpenseid: number;
    expenseamount: number;
    expenseaccount: string;
    attachmentname: string;
    attachmenturl: string;
    remarks: string;
    expensename: string;
    expenseaccountname: string;
    supplieraccount: number;
    supplieraccountname: string;
    currencyuniquerefid: number
}