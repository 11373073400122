export class Productimage {
    productimageid: number;
    productimagename: string;
    productimageurl: string;
    productimage:string
    productid: number ;
    brandid: number ;
    variantid: number ;
    recordstatus: string;
    createdby: string;
    createdon: Date ;
    modifiedby: string;
    modifiedon: Date ;
    isnew:boolean;
    filename: string;
    mobileproducturl: string;
    mobileproducturlname: string;
    imageorder:number;
}