export class Productvariant {
    productvariantdetailid: number;
    productid: number;
    variantdescription: string;
    recordstatus: string;
    createdby: number;
    createdon: Date ;
    modifiedby: number;
    modifiedon: Date ;

   
}


