export class LaybuyProductDetailsView {
    laybuydetailsid: number;
    laybuyid: number;
    producttypeid: number;
    producttypename: string;
    productcategoryid: number;
    categoryname: string;
    productid: number;
    productname: string;
    variant: number;
    variantdescription: string;
    quantity: number;
    price: number;
    changedprice: number;
    incresedby: number;
    totalamount: number;
    taxid: number;
    taxpercentage: number;
    taxamount: number;
}