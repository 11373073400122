<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Customer</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRiple type="button" icon="pi pi-plus" title="Add"
                                    [disabled]="!_Action._Add" (click)="GoToCreate($event)"
                                    class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="customertype" name="id"
                                        [(ngModel)]="_selectedtype" optionLabel="name" optionValue="id" (onChange)="Fetchall()">
                                    </p-dropdown>
                                    <label for="name"> Customer Type <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_States" name="stateid"
                                        optionValue="stateid" optionLabel="statename" [(ngModel)]="_stateid"
                                        (onChange)="getDistrict($event)" [filter]="true" filterBy="statename"
                                        [showClear]="true" [virtualScroll]="true" (onChange)="Fetchall()">
                                    </p-dropdown>
                                    <label for="stateid">Province </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_Cities" [autoDisplayFirst]="false" name="districtid"
                                        optionLabel="districtname" optionValue="districtid" [(ngModel)]="_districtid"
                                        [filter]="true" filterBy="districtname" [showClear]="true" (onChange)="Fetchall()">
                                    </p-dropdown>
                                    <label for="districtid">City Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_userlist" [autoDisplayFirst]="false" name="userid"
                                        optionLabel="username" optionValue="userid" [(ngModel)]="_responsibleperson"
                                        [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="username"
                                        [showClear]="true" (onChange)="Fetchall()">
                                    </p-dropdown>
                                    <label for="userid">Responsible Person </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_statuslist" name="statusid"
                                        [(ngModel)]="_customerstatus" optionLabel="statusname" optionValue="statusid"
                                        (onChange)="Fetchall()">
                                    </p-dropdown>
                                    <label for="name">Status <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td>
                                <button pButton pRipple label="Search" icon="pi pi-search" (click)="Fetchall()"
                                    class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)"
                                    class="p-button-danger"></button>
                            </td>
                        </tr> -->
                    </table>
                    <p-toast position="bottom-right"></p-toast>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>

                                <!-- <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger "></button> -->
                            </ng-template>

                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                    (click)="exportExcel()" class="p-mr-2" pTooltip="CSV"
                                    tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                    class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                    (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                    class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                    (click)="ExportToPdf()"></button>
                            </ng-template>

                        </p-toolbar>
                        <p-table #dt [value]="_customersview" [rows]="10" [paginator]="true"
                            [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['customercode', 'customername','currencyname','mobileno', 'mailid','username','customerstatusname', 
                            'rewardpoints','createdbyname', 'responsiblepersonname']" [rowHover]="true"
                            dataKey="employeecode" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)"
                            (onRowUnselect)="onRowUnselect($event)"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <!-- grid contains saved data (action,Customer Code,Customer Name,Supplier Type,Level and Curreny,VAT,Credit (Yes/No)) -->
                                    <th class="text-center" style="width: 120px;">Action </th>

                                    <!-- 48.based on the Customer Code and customer Name  you need to filter the data and show in the grid -->
                                    <th pSortableColumn="customercode">Customer Code <p-sortIcon field="customercode">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="customername">Customer Name<p-sortIcon field="customername">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="levelname">Markup Level<p-sortIcon field="levelname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="customername" style="width:100px">Credit Status<p-sortIcon
                                            field="customername">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="currencyname">Currency<p-sortIcon field="currencyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="mobileno">Mobile No<p-sortIcon field="mobileno">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="mailid">Email ID<p-sortIcon field="mailid"></p-sortIcon>
                                    </th>
                                    <!-- <th pSortableColumn="rewardpoints">Reward Points Available<p-sortIcon
                                            field="rewardpoints"></p-sortIcon>
                                    </th> -->
                                    <th pSortableColumn="customerstatus">Customer Status<p-sortIcon
                                            field="customerstatus"></p-sortIcon>
                                    </th>

                                    <th pSortableColumn="responsiblepersonname">Responsible Person<p-sortIcon
                                            field="responsiblepersonname"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="approvalstatus">Approval Status<p-sortIcon
                                            field="approvalstatus"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdbyname">Created By<p-sortIcon field="createdbyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdbyname">Created On<p-sortIcon field="createdbyname">
                                        </p-sortIcon>
                                    <th pSortableColumn="createdbyname">Modified By<p-sortIcon field="createdbyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdbyname">Modified On<p-sortIcon field="createdbyname">
                                        </p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_customerview>
                                <tr [ngClass]="{'qs-row': _customerview.customerstatusname === 'Inactive'}">

                                    <td style="text-align: center;">
                                        <!-- on clicking view button, it show the view screen. -->
                                        <button pButton pRipple icon="las la-eye" title="View"
                                            class="p-button p-button p-mr-2" [disabled]="!_Action._View"
                                            (click)="view(_customerview)"></button>
                                        <button pButton pRipple icon="las la-pen" title="Edit"
                                            class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update"
                                            (click)="edit(_customerview)"
                                            [disabled]="_customerview.approvalstatus == 'MSC00046' || !_Action._Update"></button>
                                        <button pButton pRipple icon="pi pi-times" title="Delete"
                                            class="p-button p-button-danger" [disabled]="!_Action._Delete"
                                            (click)="confirm(_customerview)"
                                            [disabled]="_customerview.customerstatus == 'MSC00002' || _customerview.approvalstatus == 'MSC00046' || !_Action._Delete"></button>
                                        <!-- <button pButton pRipple icon="las la-coins" title="Creditlimit" class="p-button"
                                            [disabled]="_customerview.creditapplicable == 'MSC00012' || _customerview.approvalstatus == null "></button> -->
                                    </td>
                                    <td>
                                        {{_customerview.customercode}}
                                    </td>
                                    <td>
                                        {{_customerview.customername}}
                                    </td>
                                    <td>
                                        {{_customerview.levelname}}
                                    </td>
                                    <td class="text-center inactive"
                                        [ngClass]="{'active': _customerview.creditapplicable === 'MSC00011'}">
                                        <i class="las la-coins"></i>
                                    </td>
                                    <td>
                                        {{_customerview.currencyname}}
                                    </td>
                                    <td>
                                        {{_customerview.mobileno}}
                                    </td>
                                    <td>
                                        {{_customerview.mailid}}
                                    </td>
                                    <!-- <td>
                                        {{_customerview.rewardpoints}}
                                    </td> -->
                                    <td>
                                        {{_customerview.customerstatusname }}
                                    </td>
                                    <td>
                                        {{_customerview.responsiblepersonname}}
                                    </td>
                                    <td>
                                        {{_customerview.approvalstatusname}}
                                    </td>
                                    <td>
                                        {{_customerview.createdbyname}}
                                    </td>
                                    <td>
                                        {{_customerview.createdon |date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_customerview.modifiedbyname}}
                                    </td>
                                    <td>
                                        {{_customerview.modifiedon |date:'dd/MM/yyyy'}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </form>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

            <!--   <p-dialog [(visible)]="ManageDialog" [style]="{width: '450px'}"  [modal]="true"[maximizable]="true" [style]="{width: '100vw'}">
                      <p-header>{{pageTitle}}</p-header>
                     <ng-template pTemplate="content">
                     </ng-template>
                     <ng-template pTemplate="footer">
                     </ng-template>
            </p-dialog>-->
        </div>
    </div>
</div>