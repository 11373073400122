import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { IAction } from '../security/IActions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PurchaseinvoiceService {

  public _Action: IAction;
  public _PurchaseinvoiceService = environment.PurchaseinvoiceService;
  public _RptPurchaseInvoiceService = environment.RptPurchaseInvoiceService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
    const headers = this.header;
    var Query = this._PurchaseinvoiceService + '/FetchAll' + '/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;
    return this.http.get<{ data: any }>(Query , { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      return data;
    }));

  }
  // Page Load :
  PageOnload(userid: number, p_purchaseinvoiceid: number, p_purchaseinvoiceno: any, branchid: number): Observable<any> {
    const headers = this.header;
    var Query = this._PurchaseinvoiceService + '/PageInitialize/' + userid + '/' + p_purchaseinvoiceid + '/' + p_purchaseinvoiceno + '/' + branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetGRNNos(userid: number, supplierid: number, branchid: number, invoicetype: string) {
    const headers = this.header;
    var Query = this._PurchaseinvoiceService + '/GetGRNNos' + '/' + userid + '/' + supplierid + '/' + branchid + '/' + invoicetype;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  //Get Product Details:
  GetGRNdetails(grnno: any, invoicetype: any): Observable<any> {
    const headers = this.header;
    var Query = this._PurchaseinvoiceService + '/OnGRNNOChange/' + grnno + '/' + invoicetype;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  create(purchaseinvoice: object) {
    const headers = this.header;
    var Query = this._PurchaseinvoiceService + '/Create';
    return this.http.post<{ data: any }>(Query, purchaseinvoice, { headers });
  }

  edit(purchaseinvoice: object) {
    const headers = this.header;
    var Query = this._PurchaseinvoiceService + '/Update';
    return this.http.put<{ data: any }>(Query, purchaseinvoice, { headers });
  }

  SendToApproval(userid: number, branchid: number, purchaseinvoiceno: string) {
    const headers = this.header;
    var Query = this._PurchaseinvoiceService + '/SendToApproval' + '/' + userid + '/' + branchid + '/' + purchaseinvoiceno;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  Cancel(purchaseinvoiceid: number, userid: number) {
    const headers = this.header;
    var Query = this._PurchaseinvoiceService + '/Cancel' + '/' + purchaseinvoiceid + '/' + userid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  // Print(purchaseinvoiceno: string, branchid: number) {
  //   var Query = this._PurchaseinvoiceService + '/Print/' + purchaseinvoiceno + '/' + branchid;
  //   window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
  // }
  Print(obj: any) {
    const headers = this.header;
    var Query = this._PurchaseinvoiceService + '/Print';
    return this.http.post(Query, obj, { responseType: 'blob' ,  headers });
  }
}

