<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Import Entry</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <!-- 1. Check the user rights for the login user if the user has access to add, then allow to open create page -->
                            <button pButton pRipple icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true" [minDate]="mindate" [maxDate]="maxdate"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate"
                                    [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>

                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <!-- 30.on clicking search button , the data must be filtered and display in the grid -->
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_Importentryviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['importno','importdate','containerno','suppliername','agentinvoiceno','agentinvoiceamount',
                        'dutycharge','importstatusname','paymentstatusname', 'createdbyname','createdon']" [(selection)]="selectedrows" [rowHover]="true" dataKey="salesquotationno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <!-- 29.Grid should contain :action, Import No., Import Date, supplier name, Invoice No., Invoice Amount, Voucher No., Agent Name,Date, B.E No., Container No., Port No., B.L No., Agent Invoice No., Total Amount. -->
                                <th style="text-align: center;" pSortableColumn="Action">Action
                                    <p-sortIcon field="name"></p-sortIcon>
                                </th>
                                <th pSortableColumn="importno">Import No
                                    <p-sortIcon field="importno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="importdate">Import Date
                                    <p-sortIcon field="importdate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="containerno">Container No
                                    <p-sortIcon field="containerno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="suppliername">Agent Name
                                    <p-sortIcon field="suppliername">
                                    </p-sortIcon>
                                </th>

                                <th pSortableColumn="agentinvoiceno">Agent Invoice No
                                    <p-sortIcon field="agentinvoiceno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="agentinvoiceamount">Agent Invoice Amount
                                    <p-sortIcon field="agentinvoiceamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="dutycharge">Duty Charge
                                    <p-sortIcon field="dutycharge">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="importstatusname">Status
                                    <p-sortIcon field="importstatusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="paymentstatusname">Payment Status
                                    <p-sortIcon field="paymentstatusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Importentryview>
                            <tr>
                                <td class="text-center">
                                    <!-- 22.check the user rights for the login user if the user has access to view, then allow to open view page -->
                                    <!-- 23.on clicking view button, it show the view screen. -->
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_Importentryview)"></button>
                                    <!-- 35. on clicking update button, it show the update screen.  -->
                                    <!-- 34.check the user rights for the login user if the user has access to update, then allow to open update page -->
                                    <button pButton pRipple icon="las la-pen" title="Edit" [disabled]="(( _Action._Delete && _Importentryview.paymentstatus == 'MSC00177' )|| !_Action._Update )" class="p-button p-button-success p-mr-2" (click)="edit(_Importentryview)"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Delete" class="p-button p-button-danger" (click)="confirm(_Importentryview)" [disabled]="_Importentryview.importstatus == 'MSC00150' || !_Action._Delete"></button>
                                </td>
                                <td>
                                    {{_Importentryview.importno}}
                                </td>
                                <td>
                                    {{_Importentryview.importdate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_Importentryview.containerno }}
                                </td>
                                <td>
                                    {{_Importentryview.suppliername}}
                                </td>
                                <td>
                                    {{_Importentryview.agentinvoiceno }}
                                </td>
                                <td>
                                    {{_Importentryview.agentinvoiceamount }}
                                </td>
                                <td>
                                    {{_Importentryview.dutycharge}}
                                </td>
                                <td>
                                    {{_Importentryview.importstatusname }}
                                </td>
                                <td>
                                    {{_Importentryview.paymentstatusname }}
                                </td>
                                <td>
                                    {{_Importentryview.createdbyname}}
                                </td>
                                <td>
                                    {{_Importentryview.createdon|date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-toast position="bottom-right"></p-toast>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>