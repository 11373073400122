export class Bankreconciliasationsearch {
    bankreconcilationid: number;
    paymenttype: number;
    paymenttypename: string;
    paymentdate: Date;
    referenceno: string;
    creditamount: number;
    debitamount: number;
    reconcilationdate: Date;
    banktype: number;
    bankname: string;
    series: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    accountingyear: number;
    recordstatusid: number;
    transactionRefno: string;
    transactionbranchid:number
}