<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Db Sync</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <form [formGroup]="_DBSyncForm">
                        <div class="filter-container workflow-filter">
                            View By :
                            <p-dropdown [options]="viewFilter"  [autoDisplayFirst]="false" (onChange)="OnSearch()" formControlName="status" optionValue="metasubcode"  optionLabel="metasubdescription">
                            </p-dropdown>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_DBSyncForm">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="fromdate" formControlName="fromdate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="todate" formControlName="todate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="todate">To Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Branches"
                                        optionLabel="branchname" optionValue="branchid" [filter]="true"
                                        filterBy="branchname" [showClear]="true" formControlName="branchid"
                                        >
                                    </p-dropdown>
                                    <label for="branchid"> Branch <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _DBSyncForm.get('branchid').touched) && _DBSyncForm.get('branchid').errors?.SelectBranch">
                                    Please select branch
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <button pButton pRipple label="Search" type="button" (click)="OnSearch()" title="Search"
                                    icon="pi pi-search"></button>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <!-- <div class="row">
                    <div class="col-md-8"> -->
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="las la-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                        <!-- <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="las la-file-csv" title="Export CSV"
                                class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-filter" title="Filter"
                                class="p-button-info p-ml-auto" pTooltip="Selection Only"
                                tooltipPosition="bottom"></button>
                        </ng-template> -->
                    </p-toolbar>

                    <p-table #dt [value]="DBsyncListGrid" [rows]="20" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['branchname','statusdesc','backupname','username','createdon','transactiondate']" [rowHover]="true"
                        dataKey="id" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:100px;" class="text-center">Action</th>
                                <th style="width:250px;" pSortableColumn="backupname">Backup Name
                                    <p-sortIcon field="backupname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="transactiondate">Backup Date <p-sortIcon field="transactiondate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="branchname">Branch<p-sortIcon field="branchname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="username">Created By<p-sortIcon field="username"></p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On<p-sortIcon field="createdon"></p-sortIcon>
                                </th>
                                <th pSortableColumn="statusdesc">Status<p-sortIcon field="statusdesc"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_DBsyncListGrid>
                            <tr>
                                 <td class="text-center"> 
                                     <button pButton pRipple icon="las la-sync" title="Sync"
                                        class="p-button p-mr-2"></button>
                                </td> 
                                <td>
                                    <a href="javascript:void(0);" (click)="Backupdetails(_DBsyncListGrid)">
                                        {{_DBsyncListGrid.backupname}}
                                    </a>
                                   
                                </td>
                                <td>
                                    {{_DBsyncListGrid.transactiondate| date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_DBsyncListGrid.branchname}}
                                </td>
                                <td>
                                    {{_DBsyncListGrid.username}}
                                </td>
                                <td>
                                    {{_DBsyncListGrid.createdon| date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_DBsyncListGrid.statusdesc}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-dialog header="Processed Files" [(visible)]="displayBackup" [modal]="true"
                [style]="{width: '80vw',height: '500px'}" [baseZIndex]="10000">
                <ng-template pTemplate="content">
                    <div class="popup-body">
                        <!-- <div class="text-right">
                            <div class="action-btn">
                                <button pButton pRiple label="" icon="pi pi-plus" title="Add" 
                                    class="p-button p-button-success p-mr-2" (click)="FAQ()"></button>
                            </div>
                        </div> -->
                      
                        <div class="card">
                            <p-table [value]="Backups" selectionMode="single"
                                [(selection)]="selectedBackup" [paginator]="true" [rows]="5">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="filename">File Name<p-sortIcon field="filename">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="filetype">File Type<p-sortIcon field="filetype">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="filesize">File Size<p-sortIcon field="filesize">
                                        </p-sortIcon>
                                    </th>
                                        <th pSortableColumn="statusdesc">File Status<p-sortIcon field="statusdesc">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="message">Message<p-sortIcon field="message">
                                        </p-sortIcon>
                                    </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-Backup>
                                    <tr [pSelectableRow]="rowData">

                                        <td>
                                            {{Backup.filename}}
                                        </td>
                                        <td>
                                            {{Backup.filetype}}
                                        </td>
                                        <td>
                                            {{Backup.filesize}}
                                        </td>

                                        <td>
                                            {{Backup.statusdesc}}
                                        </td>
                                        <td>
                                            {{Backup.message}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </ng-template>
            </p-dialog>
                <!-- </div> -->
                <!-- <div class="col-md-4">
                        <div class="sync-container">
                            <div class="page-title">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3>DB Sync Lists</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="sync-list">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="sync-name">Backup_Bhola04</div>
                                        <p-progressBar [value]="value"></p-progressBar>
                                    </div>
                                    <div class="col-md-3 text-right">
                                        <button pButton pRipple label="" title="Cancel" icon="las la-times"
                                            class="p-button-danger"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="sync-list">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="sync-name">Backup_Bhola01</div>
                                        <p-progressBar [value]="value1"></p-progressBar>
                                    </div>
                                    <div class="col-md-3 text-right">
                                        <button pButton pRipple label="" title="Cancel" icon="las la-times"
                                            class="p-button-danger"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>