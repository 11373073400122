import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class DamagedproductstockService {
  public _Action: IAction;
  public _DamagedproductstockService = environment.DamagedproductstockService;
  public _httpOptions;
  constructor(private http: HttpClient) { }
  PageOnload(p_productid: number, branchid: number): Observable<any> {
    var Query = this._DamagedproductstockService + '/' + p_productid + '/' + branchid;
    return this.http.get<{ data: any }>(Query);
  }
  getProductCategory(producttype: object) {
    var Query = this._DamagedproductstockService + '/getProductCategories';
    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory: object) {
    var Query = this._DamagedproductstockService + '/getProducts';
    return this.http.post<{ data: any }>(Query, productcategory);
  }
  getProductVariant(obj: object): Observable<any> {
    var Query = this._DamagedproductstockService + '/getProductVariants';
    return this.http.post(Query, obj);
  }
  GetProductSearch(product: string, branchid: number) {
    var Query = this._DamagedproductstockService + '/GetProductSearch' + '/' + product + '/' + branchid;
    return this.http.get<{ data: any }>(Query);
  }
  create(p_damagedproduct: object) {
    var Query = this._DamagedproductstockService;
    return this.http.post<{ data: any }>(Query, p_damagedproduct);
  }
  edit(p_damagedproduct: object) {
    var Query = this._DamagedproductstockService;
    return this.http.put<{ data: any }>(Query, p_damagedproduct);
  }
  SendToApproval(userid: number, branchid: number, documentno: string) {
    console.log("Send To Approval");
    var Query = this._DamagedproductstockService + '/SendToApproval' + '/' + userid + '/' + branchid + '/' + documentno;
    return this.http.get<{ data: any }>(Query);
  }
}