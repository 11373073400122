import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { PricecomparisonService } from 'src/app/core/Services/sales/pricecomparison.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Pricecomparisonview } from 'src/app/core/Views/Pricecomparisonview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-pricecomparison',
  templateUrl: './v-pricecomparison.component.html',
  styleUrls: ['./v-pricecomparison.component.css']
})
export class VPricecomparisonComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _Pricecomparisonviews: any[] = [];
  _Pricecomparisonview: Pricecomparisonview;
  _IsProgressSpinner: boolean = true;
  constructor(private fb: FormBuilder, private router: Router, private utility: CommonUtilities,
    private _PricecomparisonService: PricecomparisonService, private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private exportUtility: ExportUtility) { }
  ngOnInit(): void {
    this._IsProgressSpinner = true;
    this._Action = this._AccessRightsService.getAccessRights(60);
    this.utility.pageLoadScript();
    this.HotKeyIntegration();
    this._PricecomparisonService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Pricecomparisonviews = resultJSON.pricecomparisonviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/cPricecomparison'], { state: { pricecomparisonid: event.pricecomparisonid, action: 'view' } });
  }
  delete(event) {
    alert("delete");
  }
  edit(event) {
    this.router.navigate(['/cPricecomparison'], { state: { pricecomparisonid: event.pricecomparisonid, action: 'edit' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cPricecomparison']);
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let finalizedList = this.GetFinalizedList();
    this.exportUtility.exportExcel(finalizedList, 'Price Comparision', 'xlsx');
  }

  ExportToPdf() {
    let finalizedList = this.GetFinalizedList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Product',
      'Level',
      'Bhola Price',
      'Competitor Name 1',
      'Competitor 1 Price',
      'Competitor Name 2',
      'Competitor 2 Price',
      'Competitor Name 3',
      'Competitor 3 Price',
      'Competitor Name 4',
      'Competitor 4 Price',
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('QuoteFinalize.pdf');
      })
    })
  }

  GetFinalizedList() {
    let finalizedList = [];
    this._Pricecomparisonviews.forEach(element => {
      let quotes: any = {};
      quotes["Product"] = element.productname;
      quotes["Level"] = element.levelname;
      quotes["Bhola Price"] = element.bholaprice;
      if (element.c1name) {
        quotes["Competitor Name 1"] = element.c1name;
      } else {
        quotes["Competitor Name 1"] = "";
      }
      if (element.c1unitprice) {
        quotes["Competitor 1 Price"] = element.c1unitprice;
      } else {
        quotes["Competitor 1 Price"] = "";
      }
      if (element.c2name) {
        quotes["Competitor Name 2"] = element.c2name;
      } else {
        quotes["Competitor Name 2"] = "";
      }
      if (element.c2unitprice) {
        quotes["Competitor 2 Price"] = element.c2unitprice;
      } else {
        quotes["Competitor 2 Price"] = "";
      }
      if (element.c3name) {
        quotes["Competitor Name 3"] = element.c3name;
      } else {
        quotes["Competitor Name 3"] = "";
      }
      if (element.c3unitprice) {
        quotes["Competitor 3 Price"] = element.c3unitprice;
      } else {
        quotes["Competitor 3 Price"] = "";
      }
      if (element.c4name) {
        quotes["Competitor Name 4"] = element.c4name;
      } else {
        quotes["Competitor Name 4"] = "";
      }
      if (element.c4unitprice) {
        quotes["Competitor 4 Price"] = element.c4unitprice;
      } else {
        quotes["Competitor 4 Price"] = "";
      }
      finalizedList.push(quotes);
    });
    return finalizedList;
  }
}
