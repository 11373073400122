import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Shippingline } from 'src/app/core/Models/Shippingline';
import { ShippinglineService } from 'src/app/core/Services/masters/shippingline.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-c-shipping-line',
  templateUrl: './c-shipping-line.component.html',
  styleUrls: ['./c-shipping-line.component.css']
})
export class CShippingLineComponent implements OnInit {
  _ShippingLineform: FormGroup;
  _ShippingLinestatus: Metadatum;
  _ShippingLine: Shippingline;
  _shippinglineid: number;
  _action: string = "create";
  _submitted = false;
  isDisabledsave = false;
  isDisabledreset = false;
  _IsProgressSpinner: boolean = true;
  _Action: IAction;

  constructor(private fb: FormBuilder, private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _ShippingLineService: ShippinglineService, private _router: Router, private utility: CommonUtilities, private _hotkeysService: HotkeysService
    ,private _AccessRightsService: AccessRightsService,
    ) {
    this._shippinglineid = history.state?.shippinglineid ? history.state?.shippinglineid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._ShippingLineform = this.fb.group({
      shippinglineid: [0],
      shippinglinecode: [],
      shippinglinename: [],
      status: [],
      branchid: [sessionStorage["currentbranchid"] ],
      createdby: [sessionStorage["userid"] ],
      createdon: [],
      modifiedby: [],
      modifiedon: [sessionStorage["userid"] ],
    })
    this._ShippingLineform.controls['status'].setValue("MSC00001")
  }
  ngOnInit(): void { 
    this._Action = this._AccessRightsService.getAccessRights(170);
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;
    this._ShippingLineService.PageOnLoad(this._shippinglineid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ShippingLinestatus = resultJSON.shippingLineStatus;
      this._IsProgressSpinner = false;
      //For Update
      this._shippinglineid = history.state.shippinglineid;
      if (this._action == 'edit' || this._action == 'view') {
        this.isDisabledreset = true;
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._ShippingLineform.setValue(updateJSON.shippingline);
        this.isDisabledsave = false;
        this.isDisabledreset = false;
      }
      if (this._action == 'view') {
        this._ShippingLineform.disable();
        this.isDisabledsave = true;
        this.isDisabledreset = true;
      }
      if (this._action == 'edit') {
        this.isDisabledreset = true;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
    onSave(){
      this._submitted = true;
      //8. if all the data is valid allow to save the record in the database 
      if (this._ShippingLineform.valid) {
        this._ShippingLine = this._ShippingLineform.value;
        // this.onEd();
        if (this._action == 'create') {
  
          this._ShippingLine.createdon = new Date();
          this._ShippingLine.createdby = sessionStorage["userid"];
          this._ShippingLine.modifiedon = new Date();
          this._ShippingLine.modifiedby = sessionStorage["userid"];
          this._IsProgressSpinner = true;
          this._ShippingLineService.create(this._ShippingLine).subscribe((result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
              this.isDisabledsave = true;
              this._IsProgressSpinner = false;
              this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
              this.reset(null);
              this.isDisabledsave = false;         
             
            }
            else {
              this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            }
            this._IsProgressSpinner = false;
          }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        }
        else if (this._action == 'edit') {
          this._ShippingLine.modifiedon = new Date();
          this._ShippingLine.modifiedby = sessionStorage["userid"];
          this._IsProgressSpinner = true;
          this._ShippingLineService.edit(this._ShippingLine).subscribe((result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
              this.isDisabledsave = true;
              this._IsProgressSpinner = false;
              this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          
              this.isDisabledsave = false;
              
            }
            else {
              //24.after Updating the record, get the response A, display the success message as "Country  Name A  Modified Succesfully "
              this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            }
            this._IsProgressSpinner = false;
          }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        }
      }
    }
    reset(event){
      this._submitted = true;
      this._ShippingLineform.reset();
      this.InitializeForm();
      this._submitted = false;
    }
    goBack(event){
      this._router.navigate(['/vShippingline']);
    }
    // Create
    HotKeyIntegration() {
      this._hotkeysService.reset();
      if (this._action != 'view') {
        this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
          this.onSave();
          return false; // Prevent bubbling
        }, ['INPUT', 'SELECT', 'TEXTAREA']));
      }
      if (this._action == 'create') {
        this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
          this.reset(null);
          return false; // Prevent bubbling
        }, ['INPUT', 'SELECT', 'TEXTAREA']));
      }
      this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
        this.goBack(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }

  }
