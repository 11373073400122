import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptReconcilationUnreconcilationService {

  public _Action: IAction;
  public _httpOptions;
  public _RptReconcilationUnreconcilationService = environment.RptReconcilationUnreconcilationService;
  constructor(private http: HttpClient) { }
  PageOnload(): Observable<any> {
    var Query = this._RptReconcilationUnreconcilationService + '/PageInitialize';

    return this.http.get<{ data: any }>(Query);
  }
  Print(fromdate: string, todate: string, customerorsupplier: string, branchid: number, currencyid: string, userid: number, currentbranchid: number, conciletype: number, Paymenttype: number) {
    var Query = this._RptReconcilationUnreconcilationService + '/Print/' + fromdate + '/' + todate + '/' + customerorsupplier + '/' + branchid + '/' + currencyid + '/' + userid + '/' + currentbranchid + '/' + conciletype + '/' + Paymenttype;
    // window.open(Query, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    return this.http.get(Query, { responseType: 'blob' });
  }
  onchangepartytype(partytype: string, userid: number, branchid: number) {
    var Query = this._RptReconcilationUnreconcilationService + '/onchangepartytype' + '/' + partytype + '/' + userid + '/' + branchid;

    return this.http.get<{ data: any }>(Query);
  }
}
