import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Branch } from 'src/app/core/Models/Branch';
import { TellercashSalesdetail } from 'src/app/core/Models/Tellercashsalesdetail';
import { TellercashSalesreturndetail } from 'src/app/core/Models/Tellercashsalesreturndetail';
import { TellercashdaysettlementService } from 'src/app/core/Services/accounts/tellercashdaysettlement.service';
import { Salescurrencywisetellercash } from 'src/app/core/Models/Salescurrencywisetellercash';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { MessageService } from 'primeng/api';
//Debug
import * as ace from "ace-builds";
import { vBranchSelect, vSalesDateSelect, vTellerNameSelect } from 'src/app/core/Validators/validation';
import { Employee } from 'src/app/core/Models/Employee';
import { DatePipe } from '@angular/common';
import { Tellernameview } from 'src/app/core/Views/Tellernameview';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Salesinvoicedetailstellercashview } from 'src/app/core/Views/Salesinvoicedetailstellercashview';
import { User } from 'src/app/core/Models/User';
import { Branchsettlementofficer } from 'src/app/core/Models/BranchSettlementOfficer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExportUtility } from 'src/assets/js/export-utility';
import { element } from 'protractor';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-c-teller-cash-day-settlement',
  templateUrl: './c-teller-cash-day-settlement.component.html',
  styleUrls: ['./c-teller-cash-day-settlement.component.css']
})
export class CTellerCashDaySettlementComponent implements OnInit, OnDestroy {
  _tellercashform: FormGroup;
  _tellercashcurrencywiseform: FormGroup;
  _tellercashsalesdetailform: FormGroup;
  _tellercashsalesreturndetailform: FormGroup;
  //Drop Down
  _branches: Branch[] = [];
  _tellernames: Tellernameview[] = [];
  _accountnames: User[];
  _tempaccountnames: User[];
  //Grid
  _Currencywises: Salescurrencywisetellercash[] = [];
  _currencywise: Salescurrencywisetellercash;
  _Salesdetails: Salesinvoicedetailstellercashview[] = [];
  _Bankdetails: Salesinvoicedetailstellercashview[] = [];
  _salesdetail: TellercashSalesdetail;
  _Salesreturndetails: TellercashSalesreturndetail[] = [];
  _salesreturndetail: TellercashSalesreturndetail;
  _tempformValue: any = null;
  //Grid Selection
  selectedsalesreturndetails: TellercashSalesreturndetail;
  selectedsalesdetail: TellercashSalesdetail[] = [];
  SelectedCurrencywises: Salescurrencywisetellercash[] = [];
  _TellerCashObj: any;
  selectedRows: any;
  _TellerCashid: number;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"];
  _submitted = false;
  _searchsubmitted = false;
  _action: string = "create";
  _CurrencyBalance: number;
  _SalesBalance: number;
  _TellerCash: any;
  _SalesDate: Date;
  _IsSaveDisable: boolean = false;
  _IsClearDisable: boolean = false;
  _IsSearchDisable: boolean = false;
  _IsClearsDisable: boolean = false;
  _IsProgressSpinner: boolean = true;
  isDisabledsendapparoval = true;
  private ngUnsubscribe = new Subject();
  mindate: Date;
  maxdate: Date;
  //Debug
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, private _TellerCashDaySettlementService: TellercashdaysettlementService, private messageService: MessageService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService
    , private exportUtility: ExportUtility) {

  }

  InitializeForm() {
    this._tellercashform = this.fb.group({
      tellercashid: [0],
      branchid: ['', vBranchSelect],
      accountid: ['', vTellerNameSelect],
      salesdate: ['', vSalesDateSelect],
      tellercashstatusid: [],
      tellercashstatusdesc: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      tellerid: ['', vTellerNameSelect],
      voucherno: [''],
      tellercashCurrencywises: [],
      isbankacknowledged: [false],
      remarks: []
    });
    this.SetDefaults();
  }


  SetDefaults() {
    let roleid = parseInt(sessionStorage["currentRole"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._tellercashform.get("salesdate").setValue(new Date());
      this.maxdate = new Date();
    }
    else {
      let maxdate = new Date(sessionStorage['Environmentenddate']);
      this._tellercashform.get("salesdate").setValue(maxdate);
    }

    let userid = parseInt(sessionStorage["userid"]);
    let branchid = parseInt(sessionStorage["currentbranchid"]);
    //this._tellercashform.get("accountid").setValue(userid);
    this._tellercashform.get("branchid").setValue(branchid);
    this._tellercashform.get("isbankacknowledged").disable();
    if (userid != 1) {
      this._tellercashform.get("accountid").disable();
    }
    this.OnChangeBranch();
    //this.SetAccountName();
  }

  SetAccountName() {
    let accounterID = 0;
    let branchid = parseInt(this._tellercashform.get("branchid").value);
    let branch = (this._branches || []).find(f => f.branchid == branchid);
    let userid = parseInt(sessionStorage["userid"]);
    let tellerid = parseInt(this._tellercashform.get("tellerid").value || 0);
    let teller = (this._tellernames || []).find(f => f.userid == tellerid);
    if (teller) {
      let accounter = (this._tempaccountnames || []).find(f => (f.associateuser == userid || f.userid == userid) && f.series == teller.series);
      if (accounter) {
        accounterID = accounter.userid;
      }
    }
    if (branch && userid != 1) {
      this._tellercashform.get("accountid").setValue(accounterID);
      this._tellercashform.get("accountid").disable();
    }

    if (userid == 1) {
      if (teller) {
        this._accountnames = (this._tempaccountnames || []).filter(f => f.series == teller.series);
      }
    }
  }


  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();
    // var Salesdate =this._tellercashform.get("salesdate" ).value;
    this._TellerCashid = history.state?.tellercashid ? history.state?.tellercashid : 0;
    let tellerid = history.state?.tellerid ? history.state?.tellerid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._branchid = history.state?.branchid ? history.state?.branchid : this._branchid;
    this.HotKeyIntegration();
    this._SalesDate = history.state?.salesdate == null ? new Date() : history.state.salesdate;
    this._IsProgressSpinner = true;
    this._TellerCashDaySettlementService.PageOnLoad(this._userid, tellerid, this._TellerCashid, this._SalesDate, this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branches = resultJSON.usercurrentbranches;
      //this._tellernames = resultJSON.tellernameviews;
      // this._accountnames = resultJSON.lstUsers;
      if (this._action == 'edit' || this._action == 'view') {
        //this._SalesDate = new Date();
        const updateJSON = JSON.parse(JSON.stringify(result));
        if (updateJSON.tellercashes && updateJSON.tellercashes.length) {
          this._tellernames = resultJSON.tellernameviews;
          updateJSON.tellercashes[0].salesdate = new Date(updateJSON.tellercashes[0].salesdate);
          this._tellercashform.setValue(updateJSON.tellercashes[0]);
          this._Currencywises = updateJSON.salescurrencywisetellercashes;
          this._Salesdetails = (<Salesinvoicedetailstellercashview[]>updateJSON.salesinvoicedetailstellercashviews).filter(f => f.paymenttype == 'MSC00146');
          this._Bankdetails = (<Salesinvoicedetailstellercashview[]>updateJSON.salesinvoicedetailstellerbankview).filter(f => f.paymenttype != 'MSC00146');
          this._Salesreturndetails = updateJSON.salesreturndetailstellercashviews;
        }
      }
      else {
        this.SetDefaults();
      }
      if (this._action == 'view') {
        this._IsSaveDisable = true;
        this._IsClearDisable = true;
        this._tellercashform.disable();
        this._IsClearsDisable = true;
        this._IsSearchDisable = true;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  OnChangeBranch() {
    let data = {
      branchids: [this._tellercashform.get("branchid").value],
      userid: sessionStorage["userid"]
    };
    // this._IsProgressSpinner = true;
    this._tellernames = [];
    this._TellerCashDaySettlementService.TellerCashReportGetTellerNames(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._tellernames = resultJSON.tellernameviews;
      this._accountnames = resultJSON.lstUsers;
      this._tempaccountnames = resultJSON.lstUsers;
      this.SetAccountName();
      //this._IsProgressSpinner = false;

    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  OnChangeTellerName() {
    this.SetAccountName();
  }

  GetDetails() {
    this._searchsubmitted = true;
    let userid = sessionStorage["userid"];
    var Tellerid = this._tellercashform.get("tellerid").value;
    var Salesdate = this._tellercashform.get("salesdate").value;
    this._branchid = parseInt(this._tellercashform.get("branchid").value || 0);
    let accountid = parseInt(this._tellercashform.get("accountid").value || 0);
    this._Currencywises = [];
    this._Salesdetails = [];
    this._Bankdetails = [];
    this._Salesreturndetails = [];
    if (!Tellerid || !Salesdate || !this._branchid)
      return;
    if (!accountid) {
      this._CustomExceptionService.handleWarning("Account name must not empty. Kindly check you have permission / user mapped with branch.")
      return;
    }
    this._tempformValue = this._tellercashform.getRawValue();
    this._IsProgressSpinner = true;
    this._TellerCashDaySettlementService.GetSalesdetails(userid, Tellerid, Salesdate, this._branchid).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.salescurrencywisetellercashes && resultJSON.salescurrencywisetellercashes.length) {
        let id = parseInt(resultJSON.salescurrencywisetellercashes[0].tellercashid || 0);
        this._tellercashform.controls["tellercashid"].setValue(id);
        this._Currencywises = resultJSON.salescurrencywisetellercashes;
      }
      // this._Salesdetails = resultJSON.salesinvoicedetailstellercashviews;
      this._Salesdetails = (<Salesinvoicedetailstellercashview[]>resultJSON.salesinvoicedetailstellercashviews || []);
      this._Bankdetails = (<Salesinvoicedetailstellercashview[]>resultJSON.salesinvoicedetailstellerbankview || []);
      this._Salesreturndetails = resultJSON.salesreturndetailstellercashviews;
      this._tellercashform.get("isbankacknowledged").disable();
      if (this._Bankdetails.length > 0)
        this._tellercashform.get("isbankacknowledged").enable();
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  Clear(event) {
    this._tellercashform.reset();
    this.InitializeForm();
    this._Currencywises = [];
    this._Salesdetails = [];
    this._Salesreturndetails = [];
    this._submitted = false;
    this.SetDefaults();
  }

  allocatecurrencybalanceamount(row: any, event) {
    row.balance = row.tempbalance - row.paidamount;
  }

  onSave() {
    this._submitted = true;
    if (this._tellercashform.valid) {
      var date = this._tellercashform.get("salesdate").value;
      var accountid = this._tellercashform.get("accountid").value;
      var datePipe = new DatePipe("en-US");
      var pfromdate = datePipe.transform(date, 'yyyy-MM-dd');
      //For Model and Form Mapping
      this._TellerCash = this._tempformValue;
      this._TellerCash.salesdate = (new Date(pfromdate));
      this._TellerCash.accountid = accountid;

      if (!this._Currencywises.length) {
        this._CustomExceptionService.handleWarning("Currency Wise Details Should Not Allow to Empty");
        return
      }

      if (this._Currencywises.some(item => item.settlementofficer == "YES")) {
        this._CustomExceptionService.handleWarning("This teller is a settlement officer and should not be allowed to save");
        return
      }

      if (this._Currencywises.every(item => item.paidamount <= 0)) {
        this._CustomExceptionService.handleWarning("Paid amount must be greater than zero.");
        return
      }

      this._TellerCashObj = { "TellercashHeader": this._TellerCash, "TellercashCurrencywise": this._Currencywises };
      if (this._action == 'create') {
        this._TellerCash.createdby = sessionStorage["userid"];
        this._TellerCash.createdon = new Date();
        this._TellerCash.branchid = this._tellercashform.get("branchid").value;
        //this.onEd();
        this._IsProgressSpinner = true;
        this._TellerCashDaySettlementService.create(this._TellerCashObj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._TellerCashid = resultJSON.tellerCashid;
            this.isDisabledsendapparoval = false;
            this.Clear(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },

          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
      else if (this._action == 'edit') {
        this._TellerCash.modifiedon = new Date();
        this._TellerCash.modifiedby = sessionStorage["userid"];
        //this.onEd();
        this._IsProgressSpinner = true;
        this._TellerCashDaySettlementService.edit(this._TellerCashObj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },

          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }

    }

  }

  SendToApproval(event) {
    // var workflowid = 5;
    this._userid = sessionStorage["userid"];
    this._branchid = sessionStorage["BranchID"];
    //76.after clicking the send to approve button, the purchase order is given to 4 levels of approval 
    //this._productid= this._damagedproductpricedetailsform.get("productid").setValue(this._damagedproductpricedetailsform.get("productid").value);
    this._IsProgressSpinner = true;
    this._TellerCashDaySettlementService.SendToApproval(this._userid, this._branchid, this._TellerCashid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
      this._IsSaveDisable = false;
      if (this._action != 'edit') {
        this.reset(null);
      }
      if (this._action == 'edit') {
        this._IsSaveDisable = true;
      }
      this._IsProgressSpinner = false;
      this.isDisabledsendapparoval = true;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ExportToPdfcurrency() {
    let currencylist = this.GetCurrencyList();
    const data = currencylist.map(Object.values);
    const head = [[
      'Currency',
      'Receivable Amount',
      'Paid Amount',
      'Balance'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        // doc.autoTable(this.exportColumns, this._ExpenseReportviews);
        doc.save('Currency_Wise_Detail.pdf');
      })
    })
  }
  ExportToPdfsales() {
    let GetSalesList = this.GetSalesList();
    const data = GetSalesList.map(Object.values);
    const head = [[
      'Bill No',
      'Bill date',
      'Currency name',
      'Bill amount',
      'Currency name 1',
      'Paid Amount 1',
      'Currency name 2',
      'Paid Amount 2',
      'Return currency',
      'Return Amount',
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        // doc.autoTable(this.exportColumns, this._ExpenseReportviews);
        doc.save('Cash_Sales_Detail.pdf');
      })
    })
  }
  ExportToPdfreturn() {
    let GetSalesReturnList = this.GetSalesReturnList();
    const data = GetSalesReturnList.map(Object.values);
    const head = [[
      'Bill No',
      'Bill date',
      'Bill currency',
      'Bill amount'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        // doc.autoTable(this.exportColumns, this._ExpenseReportviews);
        doc.save('Sales_Return_Detail.pdf');
      })
    })
  }
  ExportToPdfbank() {
    let GetBankList = this.GetBankList();
    const data = GetBankList.map(Object.values);
    const head = [[
      'Bill No',
      'Bill date',
      'Bill amount',
      'Currency',
      'Paid'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        // doc.autoTable(this.exportColumns, this._ExpenseReportviews);
        doc.save('Sales_Return_Detail.pdf');
      })
    })
  }
  exportExcel(type: string) {
    if (type == 'currency') {
      let currencylist = this.GetCurrencyList();
      this.exportUtility.exportExcel(currencylist, 'CurrencyWisereport', 'xlsx');
      console.log("currency")
    }
    else if (type == 'sales') {
      let saleslist = this.GetSalesList();
      this.exportUtility.exportExcel(saleslist, 'CashSalesDetails', 'xlsx');
      console.log("sales")
    }
    else if (type == 'return') {
      let salesreturnlist = this.GetSalesReturnList();
      this.exportUtility.exportExcel(salesreturnlist, 'SalesReturnDetails', 'xlsx');
      console.log("return")
    }
    else {
      let banktransactionlist = this.GetBankList();
      this.exportUtility.exportExcel(banktransactionlist, 'BankTransactionDetails', 'xlsx');
      console.log("bank")
    }
  }
  GetCurrencyList() {
    let currencylist = [];
    this._Currencywises.forEach((element, index) => {
      let currency: any = {};
      currency["Currency"] = element.currencyname;
      currency["Receivable Amount"] = element.receivableamount;
      currency["Paid Amount"] = element.paidamount;
      currency["Balance"] = element.balance;
      currencylist.push(currency);
    });
    return currencylist;
  }
  GetSalesList() {
    let saleslist = [];
    this._Salesdetails.forEach((element, index) => {
      let sales: any = {};
      sales["Bill No"] = element.billno;
      sales["Bill date"] = element.billdate;
      sales["Currency name"] = element.currencyname;
      sales["Bill amount"] = element.billamount;
      sales["Currency name 1"] = element.currencyname1;
      sales["Paid Amount 1"] = element.paidamount1;
      sales["Currency name 2"] = element.currencyname2;
      sales["Paid Amount 2"] = element.paidamount2;
      sales["Return currency"] = element.recurrencyname;
      sales["Return Amount"] = element.returnamount;
      saleslist.push(sales);
    });
    return saleslist;
  }
  GetSalesReturnList() {
    let salesreturnlist = [];
    this._Salesreturndetails.forEach((element, index) => {
      let salesreturn: any = {};
      salesreturn["Bill no"] = element.billno;
      salesreturn["Bill date"] = element.billdate;
      salesreturn["Bill currency"] = element.currencyname;
      salesreturn["Bill amount"] = element.amount;
      salesreturnlist.push(salesreturn);
    });
    return salesreturnlist;
  }
  GetBankList() {
    let banklist = [];
    this._Bankdetails.forEach((element, index) => {
      let bank: any = {};
      bank["Bill no"] = element.billno;
      bank["Bill Date"] = element.billdate;
      bank["Bill Amount"] = element.billamount;
      bank["Currency"] = element.currencyname1;
      bank["Paid"] = element.paidamount1;
      banklist.push(bank);
    });
    return banklist
  }
  //Debug
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._TellerCashObj;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);

  }
  goBack(event) {
    //alert('This');
    this._router.navigate(['/vTellerCashDaySettlement']);
  }
  reset(event) {
    this._tellercashform.reset();
    this.InitializeForm();
    this._Currencywises = [];
    this._Salesdetails = [];
    this._Bankdetails = [];
    this._Salesreturndetails = [];
    this._submitted = false;
    this._searchsubmitted = false;
    this._tempformValue = null;
    this.SetDefaults();
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    // if (this._action != 'view') {
    //   this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
    //     this.onSave();
    //     return false; // Prevent bubbling
    //   }, ['INPUT', 'SELECT', 'TEXTAREA']));
    // }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));


  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
