<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Stock Take Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_branchlist" optionLabel="branchname" optionValue="branchid"
                                    [(ngModel)]="branchid" [virtualScroll]="true" itemSize="30" >
                                    <ng-template let-account pTemplate="item">
                                        <div
                                            [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                            {{account.branchname}}</div>
                                    </ng-template>
                                </p-multiSelect>
                                <label for="customername">Branch Name <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                      
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_producttypelist" optionLabel="producttypename"
                                    optionValue="producttypeid" [(ngModel)]="producttypeid"
                                    (onChange)="getProductCategory($event)" [virtualScroll]="true" itemSize="30">
                                </p-multiSelect>
                                <label for="producttypeid">Product Type Name <span class="hlt-txt"></span> </label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                                    [(ngModel)]="productcatid" (onChange)="getProduct($event)"
                                    optionValue="productcategoryid" [virtualScroll]="true" itemSize="30">
                                </p-multiSelect>
                                <label for="productcategoryid">Product Category Name <span
                                        class="hlt-txt"></span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_productlist" optionLabel="productname"
                                    optionValue="productid" (onChange)="GetBrand($event)" [(ngModel)]="productid"
                                    [virtualScroll]="true" itemSize="30">
                                </p-multiSelect>
                                <label for="productid">Product Name</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                                    [(ngModel)]="Brandid" [virtualScroll]="true" itemSize="30">
                                </p-multiSelect>
                                <label for="brandid">Brand Name <span class="hlt-txt"></span> </label>
                            </span>
                        </td>
                       
                    </tr>
                    <tr>
                       
                        <td style="width:20%">
                            <div class="p-formgroup-inline">
                                <div class="p-field-checkbox">
                                    <p-checkbox [(ngModel)]="IsStock" [ngModelOptions]="{standalone: true}"
                                        label="Include Stock" binary="true"></p-checkbox>
                                </div>
                            </div>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_supplierlist"
                                    optionValue="supplierid" optionLabel="suppliername" [(ngModel)]="_supplierid" [ngModelOptions]="{standalone: true}"
                                    [filter]="true" filterBy="suppliername" [virtualScroll]="true" itemSize="15" [showClear]="true">
                                </p-dropdown>
                                <label for="supplierid">Supplier Name</label>
                            </span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Generate" icon="las la-print"
                                (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                            <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()"
                                class="p-button-danger"></button>
                        </td>
                    </tr>
                </table>
                <iframe #iframe height="550" width="100%" [src]="_src"></iframe>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>