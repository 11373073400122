import { Injectable } from '@angular/core';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { IAction } from 'src/app/core/Services/security/IActions';

@Injectable({
    providedIn: 'root'
})
export class HotKeyIntegration<TReturn> {

    hotkeys: {
        leftNavigation: "ctrl+shift+left",
        rightNavigation: "ctrl+shift+right",
        saveKey: "ctrl+s",
        clearKey: "alt+c",
        leftTab: "alt+left",
        rightTab: "alt+right"
    };

    constructor(private _hotkeysService: HotkeysService) {
        _hotkeysService.reset();
        this.hotkeys = {
            leftNavigation: "ctrl+shift+left",
            rightNavigation: "ctrl+shift+right",
            saveKey: "ctrl+s",
            clearKey: "alt+c",
            leftTab: "alt+left",
            rightTab: "alt+right"
        }
    }

    Save(delegate: (event: any) => TReturn, action: IAction): HotKeyIntegration<TReturn> {
        if (action._Add) {
            this.AddHotKey(delegate, this.hotkeys.saveKey);
        }
        return this;
    }

    Navigate(delegate: (event: any) => TReturn, action: IAction, navigateTo: 'left' | 'right'): HotKeyIntegration<TReturn> {
        if (navigateTo == 'left')
            this.AddHotKey(delegate, this.hotkeys.leftNavigation);
        else if (navigateTo == 'right' && action._Add)
            this.AddHotKey(delegate, this.hotkeys.rightNavigation);

        return this;
    }

    TabNavigate(delegate: (event: any) => TReturn, action: IAction, navigateTo: 'left' | 'right'): HotKeyIntegration<TReturn> {
        if (navigateTo == 'left')
            this.AddHotKey(delegate, this.hotkeys.leftTab);
        else if (navigateTo == 'right')
            this.AddHotKey(delegate, this.hotkeys.rightTab);

        return this;
    }

    Clear(delegate: (event: any) => TReturn): HotKeyIntegration<TReturn> {
        this.AddHotKey(delegate, this.hotkeys.clearKey);

        return this;
    }

    private AddHotKey(delegate: (event: any) => TReturn, hotKey: string): void {
        this._hotkeysService.add(new Hotkey(hotKey, (event: KeyboardEvent): boolean => {
            delegate(event);
            return false; // Prevent bubbling
        }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
}
