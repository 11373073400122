export class Couponexcludecategorydetail {
    coupondetailid: number;
    couponid: number;
    excludeproductategoryid: number;
    createdby: number;
    createdon: Date 
    modifiedby: number 
    modifiedon: Date 

    
}
