<div class="page-loader" *ngIf="_IsProgressSpinner">
  <p-progressSpinner strokeWidth="5"></p-progressSpinner>
</div>
<div class='login-page'>
  <div class="body">
    <div class="row">
      <div class="col-md-6 login-left text-center">
        <img src="assets/images/login-bg.png" class="img-fluid animate__animated animate__fadeInLeftBig">
      </div>
      <div class="col-md-6 login-right">
        <div class="login-container white animate__animated animate__fadeInRightBig">
          <div class="login-logo text-center">
            <img src="assets/images/logo.png" alt="CavinKare">
          </div>
          <form [formGroup]="loginform" (ngSubmit)="onSubmit()">
            <div class="login-row">
              <span class="p-float-label">
                <input type="text" pInputText formControlName="username" />
                <label for="inputtext">User Name</label>
              </span>
              <span class="text-danger"
                *ngIf="(loginform.get('username').touched || submitted) && loginform.get('username').errors?.required">
                Name is required
              </span>
            </div>
            <div class="login-row">
              <span class="p-float-label">
                <input type="password" pInputText formControlName="password" />
                <label for="inputtext">Password</label>
              </span>
              <span class="text-danger"
                *ngIf="(loginform.get('password').touched || submitted) && loginform.get('password').errors?.required">
                Password is required
              </span>
            </div>
            <div class="login-btn text-center">
              <button pButton pRipple type="submit" label="Login" icon="pi pi-sign-in"></button>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</div>
<p-dialog header="Session Expired" [modal]="true" [(visible)]="SessionPopup" class="approval-notification session-expiry" [style]="{width: '300px'}">
  <div class="approval-body">
      <p><strong>Your Session has been Expired.</strong></p>
  </div>
</p-dialog>
<p-toast></p-toast>