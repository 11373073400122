export class Salesorderterm {
    salesordertermid: number;
    salesorderid: number;
    termtypeid: number;
    termsandconditionid: number;
    termdescription: string;
    accountingyear: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;

}