import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import { RptsalesreturnService } from 'src/app/core/Services/reports/Sales/RptSalesReturn/rptsalesreturn.service';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Producttype } from 'src/app/core/Models/Producttype';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Customerview } from 'src/app/core/Views/Customerview';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-rptsalesreturn',
  templateUrl: './rptsalesreturn.component.html',
  styleUrls: ['./rptsalesreturn.component.css']
})
export class RptsalesreturnComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _supplierlist: Supplier[];
  _currencylist: Currency[];
  _productlist: Productoverallview[];
  _invoicetypelist: Metadatum[];
  _productcategorylist: Productcategory[];
  _producttypelist: Producttype[];
  _branchlist: Branch[];
  _customerlist: Customerview[];
  _rptsalesreturnform: FormGroup;
  _branchid: number;
  _userid: number;
  _src: SafeResourceUrl;
  _SalesReturnPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  branchid: number[] = [];
  customerid: number[] = [];
  _brandlist: any;
  _Approvalstatuslist: any;
  _Tempbrandlist: any;
  brandid: number[] = [];
  status: any[] = [];
  _ProductObj: any;
  _Action: IAction
  mindate: Date;
  maxdate: Date;

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptSalesReturnService: RptsalesreturnService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(103);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptsalesreturnform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      productid: [0],
      currencyid: [0],
      productcategoryid: [0],
      producttypeid: [0],
      customerid: [0]
    })
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalesreturnform.get("fromdate").setValue(new Date);
      this._rptsalesreturnform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalesreturnform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalesreturnform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptsalesreturnform.get("fromdate").value
    dt.setDate(dt.getDate() - 7);
    this._rptsalesreturnform.controls['fromdate'].setValue(dt);
    this._RptSalesReturnService.PageOnload(this._branchid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._customerlist = resultJSON.customerList;
      this._producttypelist = resultJSON.producttypeList;
      this._brandlist = resultJSON.brandList;
      this._Tempbrandlist = resultJSON.brandList;
      this._Approvalstatuslist = resultJSON.approvalstatuslist;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._rptsalesreturnform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalesreturnform.get("fromdate").setValue(new Date);
      this._rptsalesreturnform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalesreturnform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalesreturnform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptsalesreturnform.get("fromdate").value
    dt.setDate(dt.getDate() - 7);
    this._rptsalesreturnform.controls['fromdate'].setValue(dt);
    this.branchid = [];
    this.customerid = [];
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this.brandid = [];
    this.status = [];
    this._branchlist = this._Tempbrandlist;
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  GenerateReport(event) {
    var _fromdate = this._rptsalesreturnform.get("fromdate").value;
    var _todate = this._rptsalesreturnform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var branchid = this._rptsalesreturnform.get("branchid").value || [];
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._SalesReturnPrintObj = {
      "Productcategory": this.productcatid || [],
      "Producttype": this.producttypeid,
      "Productheader": this.productid,
      "branchids": branchid || [],
      "branchid": parseInt(sessionStorage["currentbranchid"]),
      "Customerid": this.customerid || 0,
      "Userid": parseInt(sessionStorage["userid"]),
      "FromDate": fromdate,
      "ToDate": todate,
      "BrandIds": this.brandid || [],
      "Approvalstatus": this.status || [],
    };
    this._RptSalesReturnService.Print(this._SalesReturnPrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = {
        "Producttype": selectedProductType || [],
        "branchid": parseInt(sessionStorage["currentbranchid"])
      };
      this._RptSalesReturnService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("category", resultJSON);
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptSalesReturnService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  ChangeProduct(event) {
    var selectedProduct = event.value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._brandlist = this._Tempbrandlist
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductObj = { "Products": selectedProduct };
      this._RptSalesReturnService.getBrand(this._ProductObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("brand", resultJSON);
        let productbrand = resultJSON.productbrandlist;
        let uniqueChars = [...new Set(productbrand)];
        uniqueChars = productbrand.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.brandid === test.brandid
          ));
        this._brandlist = uniqueChars;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
}