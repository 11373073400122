import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { AdmanagementService } from 'src/app/core/Services/ecommerce/admanagement.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { UploadFileService } from 'src/app/core/Utilities/fileupload/upload-file.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-v-ad-management',
  templateUrl: './v-ad-management.component.html',
  styleUrls: ['./v-ad-management.component.css']
})
export class VAdManagementComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _addmanagementviews: any;
  _addmanagementview: any
  _IsProgressSpinner: boolean = true;
  ADid: number
  constructor(
    private fb: FormBuilder,
    private _uploadService: UploadFileService,
    private router: Router,
    private utility: CommonUtilities,
    private _CustomExceptionService: CustomExceptionService,
    private _DomSanitizationService: DomSanitizer,
    private _AdManagementService: AdmanagementService,
    private confirmationService: ConfirmationService,
    private _AccessRightsService: AccessRightsService,
    private _router: Router
  ) { }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(304);
    this._addmanagementviews = null
    this._IsProgressSpinner = true;
    this._AdManagementService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this._addmanagementviews = resultJSON.admanagementviews;
      for (var i = 0; i < resultJSON.admanagementviews.length; i++) {
        this._addmanagementviews[i].adurl = this._DomSanitizationService.bypassSecurityTrustUrl((this._addmanagementviews[i].adurl));
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/cadmanagement'], { state: { adid: event.adid, action: 'view' } });
  }
  edit(event) {
    this.router.navigate(['/cadmanagement'], { state: { adid: event.adid, action: 'edit' } });
  }
  confirm(event) {
    this.ADid = event.adid
    this.confirmationService.confirm({
      message: 'Are you sure that you want to Delete  this Ad <b>"' + event.adname + '"</b> ?',
      accept: () => {
        this.Delete(event);
      }
    });
  }
  Delete(event) {
    this._IsProgressSpinner = true;
    this._AdManagementService.Delete(this.ADid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess("Record Inactived Succesfully");
        this._AdManagementService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._addmanagementviews = resultJSON.admanagementviews;
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GoToCreate(event) {
    this._router.navigate(['/cadmanagement']);
  }
}