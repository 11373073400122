<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_numberingschemaform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Numbering Schema</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple label="" icon="pi pi-save" title="Save" type="submit" class=""
                                    [disabled]="isDisabledSave"></button>
                                <!-- clear all the fields which the data has entered -->
                                <button pButton pRipple label="" icon="pi pi-trash" title="Clear" type="button"
                                    (click)="reset($event)" class="p-button-danger"
                                    [disabled]="isDisabledClear"></button>
                                <!-- on clicking back button , it Navigates to the search screen -->
                                <button pButton pRiple label="" icon="pi pi-search" title="Back to Search" type="button"
                                    (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!--8. if document is not select,show error as "Please select document" -->
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Document" name="documentid"
                                    [filter]="true"  formControlName="documentid" filterBy="functionname" optionLabel="functionname"
                                        optionValue="functionid">
                                    </p-dropdown>
                                    <label for="documentid"> Document <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_numberingschemaform.get('documentid').touched || _submitted) && _numberingschemaform.get('documentid').errors?.SelectDocumentType">
                                    Please select document
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <!-- sequence level load from metadata(Branch,All) -->
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Sequencelevel"
                                        name="sequencelevel" formControlName="sequencelevel"
                                        optionLabel="metasubdescription" optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="sequencelevel"> Sequence Level</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <!--9. if "prefix" is not enter, show error as "Please enter prefix" -->
                                <span class="p-float-label">
                                    <!-- prefix must be editable ,because it should allow to update -->
                                    <input type="text" pInputText name="prefix" formControlName="prefix"
                                        maxlength="5" />
                                    <label for="prefix">Enter Prefix <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_numberingschemaform.get('prefix').touched || _submitted) && _numberingschemaform.get('prefix').errors?.prefixRequired">
                                    Please enter prefix
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <!-- symbol fields load from metadata(/,@,#,_,-) -->
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_symbol" name="symbol"
                                        formControlName="symbol" optionLabel="metasubdescription"
                                        optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="symbol"> Symbol Fields</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Dateformat" name="dateformat"
                                        formControlName="dateformat" optionLabel="metasubdescription"
                                        optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="dateformat">Date Format</label>
                                </span>
                            </td>
                            <td>
                                <!--10. if "suffix" is not enter, show error as "Please Enter suffix" -->
                                <span class="p-float-label">
                                    <input type="text" pInputText name="suffix" formControlName="suffix"
                                        maxlength="5" />
                                    <label for="suffix"> Enter Suffix <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_numberingschemaform.get('suffix').touched || _submitted) && _numberingschemaform.get('suffix').errors?.SuffixRequired">
                                    Please Enter suffix
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_numberingschemaform.get('suffix').touched || _submitted) && _numberingschemaform.get('suffix').errors?.invalidSuffix">
                                    Please Enter valid suffix
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Numberingschemastatus"
                                        name="numberingschemastatus" formControlName="numberingschemastatus"
                                        optionLabel="metasubdescription" optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="numberingschemastatus"> Status</label>
                                </span>
                            </td>
                            <td hidden>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_series" name="series"
                                        formControlName="series" optionLabel="metasubdescription" hidden
                                        optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="series"> Series</label>
                                </span>
                            </td>
                            <td>
                                <!-- Debug -->
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </tr>
                    </table>

                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>