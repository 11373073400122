import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { TaxreconcilationService } from 'src/app/core/services/accounts/taxreconcilation.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { usererrors } from 'src/app/core/errors/usererrors';
import * as ace from "ace-builds";
import { Branch } from 'src/app/core/Models/Branch';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { RecPayTaxDetail } from 'src/app/core/Models/RecPayTaxDetail';
import { RecPayTaxHeader } from 'src/app/core/Models/RecPayTaxHeader ';
import { RecPayTaxSummary } from 'src/app/core/Models/RecPayTaxSummary';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
@Component({
  selector: 'app-c-tax-reconcilation',
  templateUrl: './c-tax-reconcilation.component.html',
  styleUrls: ['./c-tax-reconcilation.component.css']
})
export class CTaxReconcilationComponent implements OnInit {
  _IsSaveDisable: boolean = false;
  _IsClearDisable: boolean = false;
  _RecPayTaxHeaderform: FormGroup;
  _RecPayTaxDetailform: FormGroup;
  _RecPayTaxSummaryform: FormGroup;
  _Taxreconcilationid: number;
  _RecPayTaxHeader: RecPayTaxHeader;
  _RecPayTaxSummaries: RecPayTaxSummary;
  minDate: Date;

  _RecTaxDetails: any;
  _PayTaxDetails: any;
  _Summaries: any;

  _Branches: Branch;
  _status: Metadatum;


  // For Grid
  selectedPayTax: any;
  selectedRecTax: any;
  selectedrows: any;
  _action: string = "create";
  _submitted = false;
  displayBasic: boolean;
  _IsProgressSpinner: boolean = true;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _TaxreconcilationService: TaxreconcilationService,
    private utility: CommonUtilities,
    private _router: Router,private _hotkeysService: HotkeysService) 
    {
      //Save Short cut keys
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }));
      //Back To Search Short cut keys
      this._hotkeysService.add(new Hotkey('backspace', (event: KeyboardEvent): boolean => {
        this.goBack(event);
        return false; // Prevent bubbling
      }));
      
      //Clear
      this._hotkeysService.add(new Hotkey('alt+x', (event: KeyboardEvent): boolean => {
        this.reset(event);
        return false; // Prevent bubbling
      }));
    }
  
  InitializeForm() {
    this._RecPayTaxHeaderform = this.fb.group({
      recPayTaxid: [],
      recPayTaxcode: [],
      todate: [],
      branchid: [],
      recordstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: []
    });
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    this.minDate = new Date();
    this._Taxreconcilationid = history.state?.recPayTaxid ? history.state?.recPayTaxid : 0;
    console.log(this._Taxreconcilationid);
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._IsProgressSpinner = true;
    this._TaxreconcilationService.PageOnLoad(this._Taxreconcilationid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      // For DropDown
      this._Branches = resultJSON.branches;
      this._status = resultJSON.recordStatus;
      this._IsProgressSpinner = false;
      console.log(this._action);
      if (this._action == 'edit' || this._action == 'view') {
        this._RecPayTaxHeaderform.controls["branchid"].setValue(resultJSON.recPayTaxHeader.branchid);
        var date = new Date(resultJSON.recPayTaxHeader.todate)
        this._RecPayTaxHeaderform.controls["todate"].setValue(date);
        this._RecTaxDetails = resultJSON.taxreconcilationreceivables;
        this._PayTaxDetails = resultJSON.taxreconcilationpayables;
        this._Summaries = resultJSON.taxreconcilationsummaryviews;
        this._IsSaveDisable = this._IsClearDisable = (this._action == 'view');
        //= (this._action == 'view');
      }
    },  
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSave() {
    this._submitted = true;
    if (this._RecPayTaxHeaderform.valid) {
      if (this._action == 'create') {
        // this._RecPayTaxHeader.createdon = new Date();
        let createdBy = sessionStorage["userid"];
        let branchid = this._RecPayTaxHeaderform.get("branchid").value;
        let date = new Date(this._RecPayTaxHeaderform.get("todate").value);
        let year = date.getFullYear();
        let month = date.getMonth();
        //this.onEd();
        this._IsSaveDisable = true;
        this._IsClearDisable = true;
        this._IsProgressSpinner = true;
        this._TaxreconcilationService.create(branchid, year, month, createdBy, date).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsSaveDisable = true;
            this._IsClearDisable = true;
       

          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsSaveDisable = false;
            this._IsClearDisable = false;
            this._IsProgressSpinner = false;

          }
        }, error => {
          this._CustomExceptionService.handleError(error);
          this._IsSaveDisable = false;
          this._IsClearDisable = false;
          this._IsProgressSpinner = false;
        });
      }
    }
  }

  getTaxDetails() {

    let date = this._RecPayTaxHeaderform.get("todate").value;
    let branchid = this._RecPayTaxHeaderform.get("branchid").value;

    if (!date || !branchid) {
      this._submitted = true;
      return;
    }
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    this._IsProgressSpinner = true;
    this._TaxreconcilationService.getTaxDetails(branchid, month, year).subscribe((result) => {
      const resutJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
        this._RecTaxDetails = resutJSON.taxreconcilationreceivables;
        this._PayTaxDetails = resutJSON.taxreconcilationpayables;
        this._Summaries = resutJSON.taxreconcilationsummaryviews;
      }
      else {
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },

      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  onRowSelect(event) {
    console.log("Selected Suppliers");
    console.log(this.selectedRecTax);
  }
  onRowSelect1(event) {
    console.log("Selected Suppliers");
    console.log(this.selectedPayTax);
  }
  onRowSelect2(event) {
    console.log("Selected Suppliers");
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedRecTax);
  }
  onRowUnselect1(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedPayTax);
  }
  onRowUnselect2(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  goBack(event) {
    console.log("goBack");
    this._router.navigate(['/vTaxreconcilation']);
  }
  reset(event) {
    this._RecPayTaxHeaderform.reset();
    this._RecPayTaxDetailform.reset();
    this._RecPayTaxSummaryform.reset();
  }
  // onEd() {
  //   alert("test");
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._TaxreconcilationOBJ;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }
}
