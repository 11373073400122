import { ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/core/domain/appconfig';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Stockadjustmentdetail } from 'src/app/core/Models/Stockadjustmentdetail';
import { Supplier } from 'src/app/core/Models/Supplier';
import { StockdashboardService } from 'src/app/core/Services/dashboard/stockdashboard.service';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-stock',
  templateUrl: './stock-dashboard.component.html',
  styleUrls: ['./stock-dashboard.component.css']
})
export class StockDashboardComponent implements OnInit {
     data: any ;
     _Action: IAction;

     @ViewChild("chartBody") chartBody

    _IsProgressSpinner: boolean = true;
    productsalesdetails: [];
    _Isbarsd: boolean = true; _Islinesd: boolean = true; _Ispiesd: boolean = true; _Isdoughnutsd: boolean = true;
    _Isbarsd1: boolean = true; _Islinesd1: boolean = true; _Ispiesd1: boolean = true; _Isdoughnutsd1: boolean = true;
    _Isbarrd: boolean = true; _Islinerd: boolean = true; _Ispierd: boolean = true; _Isdoughnutrd: boolean = true;
    _Isbarpd: boolean = true; _Islinepd: boolean = true; _Ispiepd: boolean = true; _Isdoughnutpd: boolean = true;
    _Isbarpdd: boolean = true; _Islinepdd: boolean = true; _Ispiepdd: boolean = true; _Isdoughnutpdd: boolean = true;
    lableData: any = [];
    lableData1: any = [];
    trimlableData: any = [];
    trimlableData1: any = [];
    _productname: Productheader[] = [];
    salesValue: any = [];
    salesValue1: any = [];
    lableData3: any = [];
    trimlableData3: any = [];
    salesValuepay: any = [];
    salesValuerec: any = [];
    trimlableDatareorder : any = [];
  salesValuereorder: any = [];
  basicDatareorder : any = [];
  trimlableDataProduct: any = [];
  trimlableDataProductDots: any = [];
    salesValueProduct: any = [];
    basicData: any;
    basicData1: any;
    basicData2: any;
    basicDataProduct:any;
    trimlableDatapdd: any = [];
    salesValuepdd: any = [];
    basicDatapdd:any;
    _productdetails: Stockadjustmentdetail[] = [];
    basicOptions: any;
    multiAxisData: any;
    _submitted1 = false;
    chartOptions: any;
    _productsubmitted = false;
    subscription: Subscription;
    _stockdashboardform: FormGroup;
    _damagedstockdashboardform:FormGroup;
    _reorderdashboardform:FormGroup;
    _productwisedashboardform:FormGroup;
    _productdamageddashboardform:FormGroup;
    filterBranchs: any[] = [];
    _Branches: any[] = [];
    _Suppliers: Supplier[];
    filterSuppliers: any[] = [];
    config: AppConfig;
    charttype: any;
    userid: number;
    branchid: number;
    fromdate: Date;
    todate: Date;
    supplierid: number;
    _producttype: Producttype[] = [];
    _productcategory: Productcategory[] = [];
    _Severities:Metadatum[]=[];
    _ProductTypeObj: any;
    _ProductCategoryObj: any;
    _Brandsview:any;
    decimalOptions: any;

    _ProductTypeWiseStock: any;
    _ProductTypeWiseStockOpt: any;

    _ProductTypeWiseStockValue: any;
    _ProductTypeWiseStockValueOpt: any;

    totalProductTypeStocks: any = [];
    totalProductTypeStocksSymb: any = [];
    totalProductTypeStocksValues: any = []

    fastMovingStock: any = [];
    fastMovingStockValues: any = []

    stockFreshness: any = [];
    stockFreshnessValues: any = []
    totalStock: number;

    noProductFreshness: boolean = false;
    noFastMovingStock: boolean = false;
    noProductTypeSV: boolean = false;
    noProductTypeS: boolean = false;

    // NGX CHART
    multi: any[];
    view: any[] = [700, 400];

    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = true;
    showLegend: boolean = true;
    showXAxisLabel: boolean = true;
    xAxisLabel: string = 'Country';
    showYAxisLabel: boolean = true;
    yAxisLabel: string = 'Population';
    legendTitle: string = 'Years';

    colorScheme = {
      domain: ['#5AA454', '#C7B42C', '#AAAAAA']
    };

    productTypeStock: any []  = []
    productTypeStockVals: any []  = []
    stockFreshnessVals: any []  = []
    stockFreshnessValsOpt: any []  = []
    domainPercent: any [] = [0, 100]

  barColors = ['#42A5F5', "#FF6384", "#3655eb", "#e757eb", "#04a981","#FFCE56"];


    multiSData = [
      {
        "name": "Germany",
        "series": [
          {
            "name": "2010",
            "value": 7300000
          },
          {
            "name": "2011",
            "value": 8940000
          }
        ]
      },

      {
        "name": "USA",
        "series": [
          {
            "name": "2010",
            "value": 7870000
          },
          {
            "name": "2011",
            "value": 8270000
          }
        ]
      },

      {
        "name": "France",
        "series": [
          {
            "name": "2010",
            "value": 5000002
          },
          {
            "name": "2011",
            "value": 5800000
          }
        ]
      }
    ];

    constructor(private _stockDashboardService: StockdashboardService,
      private utility: CommonUtilities,
      private elementRef: ElementRef,
      private _AccessRightsService: AccessRightsService,
      private fb: FormBuilder, private _CustomExceptionService: CustomExceptionService) {

        // NGX CHART
        this.multi = this.multiSData;
        console.log(this.multi);

      }


    ngOnInit(): void {
      this.utility.pageLoadScript();
      this._Action = this._AccessRightsService.getAccessRights(126);

      this._stockdashboardform = this.fb.group({
        branchid: [parseInt(sessionStorage["currentbranchid"])],
        fromdate: [],
        todate: [new Date()],
        supplierid: [],
        producttypeid: [],
        productcategoryid: [],
        productid: [],
      })
      this._damagedstockdashboardform = this.fb.group({
        branchid: [],

      })
      this._reorderdashboardform = this.fb.group({
        branchid: [],
        productid:[]

      })
      this._productwisedashboardform = this.fb.group({
        branchid: [],
        productid: [],

      })
      this._productdamageddashboardform = this.fb.group({
        branchid: [],
        productid: [],
        severityid:[],

      })

      this._Isbarsd = true; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = false;
      this._Isbarsd1 = true; this._Islinesd1 = false; this._Ispiesd1 = false; this._Isdoughnutsd1 = false;
      this._Isbarrd = false; this._Islinerd = false; this._Ispierd = true; this._Isdoughnutrd = false;
      this._Isbarpd = true; this._Islinepd = false; this._Ispiepd = false; this._Isdoughnutpd = false;
      this._Isbarpdd = true; this._Islinepdd = false; this._Ispiepdd = false; this._Isdoughnutpdd = false;
      var dt = new Date();
      dt.setDate(dt.getDate() - 30);
      this._stockdashboardform.controls['fromdate'].setValue(dt);
      this.config = this._stockDashboardService.config;
      this.updateChartOptions();
      this.subscription = this._stockDashboardService.configUpdate$.subscribe(config => {
        this.config = config;
        this.updateChartOptions();
      });

      this._IsProgressSpinner = true;
      this._stockDashboardService.PageOnload().subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this._IsProgressSpinner = false;
        this._Suppliers = <Supplier[]>resultJSON.suppliers;
        this._producttype=resultJSON.producttypes ;
        this._Severities = resultJSON.severities;
        this._Branches = resultJSON.branches;
      });

      this.InitializeCharts();

      this.data = [
        {
          name: 'Row1',
          series: [
            {name: 'Bar1', value: 150},
            // {name: 'Bar2', value: 200}
          ],
        },
        {
          name: 'Row2',
          series: [
            {name: 'Bar1', value: 300},
            // {name: 'Bar2', value: 400}
          ],
        },
        {
          name: 'Row3',
          series: [
            {name: 'Bar1', value: 500},
            // {name: 'Bar2', value: 1000}
          ],
        }
      ];
    }

    InitializeCharts() {

      this.ProductTypeWiseStock();
      this.FastMovingStock();
      this.StockFreshNess();

    }

    updateChartOptions() {
      if (this.config.dark)
        this.applyDarkTheme();
      else
        this.applyLightTheme();

      this.applyDecimalValue();
      this.applyLightTheme();

    }
    applyDarkTheme() {
      this.basicOptions = {
        legend: {
          labels: {
            fontColor: '#ebedef'
          }
        },
        // tooltips: {
        //   callbacks: {
        //     title: function() {
        //       return 'asdkjasdhk';
        //     },
        //     label: function(item, data) {
        //       // var datasetLabel = data.datasets[item.datasetIndex].label || '';
        //       // var dataPoint = data.datasets[item.datasetIndex].data[item.index];
        //       // return datasetLabel + ': (' + item.xLabel + ', ' + item.yLabel + ', ' + dataPoint.r + ')';
        //       return 'Hello' + ': (' + item.xLabel + ', ' + item.yLabel + ', ' + 'asdkj' + ')';
        //     }
        //   }
        // }

        // scales: {
        //   xAxes: [{
        //     ticks: {
        //       fontColor: '#ebedef'
        //     },
        //     gridLines: {
        //       color: 'rgba(255,255,255,0.2)'
        //     }
        //   }],
        //   yAxes: [{
        //     ticks: {
        //       fontColor: '#ebedef'
        //     },
        //     gridLines: {
        //       color: 'rgba(255,255,255,0.2)'
        //     }
        //   }]
        // }
      };
    }

    applyDecimalValue() {
      this.decimalOptions = {
        // tooltips: {
        //   callbacks: {
        //     label: (tooltipItem, data) => {
        //       let label = data.datasets[tooltipItem.datasetIndex].label || '';
        //       if (label) {
        //         label += ': ';
        //       }
        //       label += tooltipItem.yLabel.toFixed(2);
        //       return label;
        //     },
        //   },
        // },
      };
    }

    applyLightTheme() {
      this.basicOptions = {
        legend: {
          labels: {
            fontColor: '#ebedef'
          }
        },
        // tooltips: {
        //   callbacks: {
        //     title: function() {
        //       return 'asdkjasdhk';
        //     },
        //     label: function(item, data) {
        //       var datasetLabel = data.datasets[item.datasetIndex].label || '';
        //       // var dataPoint = data.datasets[item.datasetIndex].data[item.index];
        //       // return datasetLabel + ': (' + item.xLabel + ', ' + item.yLabel + ', ' + dataPoint.r + ')';
        //       return 'Hello' + ': (' + item.xLabel + ', ' + item.yLabel + ', ' + 'asdkj' + ')';
        //     }
        //   }
        // }
      }
    }

    chartchangesd(charttype1) {

      if (charttype1 == "bar") {
        this._Isbarsd = true; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = false;
      }
      if (charttype1 == "line") {
        this._Isbarsd = false; this._Islinesd = true; this._Ispiesd = false; this._Isdoughnutsd = false;
      }
      if (charttype1 == "pie") {
        this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = true; this._Isdoughnutsd = false;
      }
      if (charttype1 == "doughnut") {
        this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = true;
      }
    }

    chartchangesd1(charttype2) {

      if (charttype2 == "bar") {
        this._Isbarsd1 = true; this._Islinesd1 = false; this._Ispiesd1 = false; this._Isdoughnutsd1 = false;
      }
      if (charttype2 == "line") {
        this._Isbarsd1 = false; this._Islinesd1 = true; this._Ispiesd1 = false; this._Isdoughnutsd1 = false;
      }
      if (charttype2 == "pie") {
        this._Isbarsd1 = false; this._Islinesd1 = false; this._Ispiesd1 = true; this._Isdoughnutsd1 = false;
      }
      if (charttype2 == "doughnut") {
        this._Isbarsd1 = false; this._Islinesd1 = false; this._Ispiesd1 = false; this._Isdoughnutsd1 = true;
      }
    }
    chartchangerd(charttype3) {

      if (charttype3 == "bar") {
        this._Isbarrd = true; this._Islinerd = false; this._Ispierd = false; this._Isdoughnutrd = false;
      }
      if (charttype3 == "line") {
        this._Isbarrd = false; this._Islinerd = true; this._Ispierd = false; this._Isdoughnutrd = false;
      }
      if (charttype3 == "pie") {
        this._Isbarrd = false; this._Islinerd = false; this._Ispierd = true; this._Isdoughnutrd = false;
      }
      if (charttype3 == "doughnut") {
        this._Isbarrd = false; this._Islinerd = false; this._Ispierd = false; this._Isdoughnutrd = true;
      }
    }
    chartchangepd(charttype4) {

      if (charttype4 == "bar") {
        this._Isbarpd = true; this._Islinepd = false; this._Ispiepd = false; this._Isdoughnutpd = false;
      }
      if (charttype4 == "line") {
        this._Isbarpd = false; this._Islinepd = true; this._Ispiepd = false; this._Isdoughnutpd = false;
      }
      if (charttype4 == "pie") {
        this._Isbarpd = false; this._Islinepd = false; this._Ispiepd = true; this._Isdoughnutpd = false;
      }
      if (charttype4 == "doughnut") {
        this._Isbarpd = false; this._Islinepd = false; this._Ispiepd = false; this._Isdoughnutpd = true;
      }
    }
    chartchangepdd(charttype5) {

      if (charttype5 == "bar") {
        this._Isbarpdd = true; this._Islinepdd = false; this._Ispiepdd = false; this._Isdoughnutpdd = false;
      }
      if (charttype5 == "line") {
        this._Isbarpdd = false; this._Islinepdd = true; this._Ispiepdd = false; this._Isdoughnutpdd = false;
      }
      if (charttype5 == "pie") {
        this._Isbarpdd = false; this._Islinepdd = false; this._Ispiepdd = true; this._Isdoughnutpdd = false;
      }
      if (charttype5 == "doughnut") {
        this._Isbarpdd = false; this._Islinepdd = false; this._Ispiepdd = false; this._Isdoughnutpdd = true;
      }
    }
    filterBranch(event) {
      let filtered: any[] = [];
      let query = (<string>event.query).toLowerCase();
      this.filterBranchs = this._Branches.filter(f => f.branchname.toLowerCase().indexOf(query) > -1) || [];
    }
    filterSupplier(event) {
      let filtered: any[] = [];
      let query = (<string>event.query).toLowerCase();
      this.filterSuppliers = this._Suppliers.filter(f => f.suppliername.toLowerCase().indexOf(query) > -1) || [];
    }
    OnChangeBranch(event) {
      console.log(event);
      this.branchid = event.branchid;

    }
    OnChangeSupplier(event) {
      console.log(event);
      this.supplierid = event.supplierid;

    }

    branchChanged() {
      this._Isbarsd = true; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = false;
      this._Isbarsd1 = true; this._Islinesd1 = false; this._Ispiesd1 = false; this._Isdoughnutsd1 = false;
      this._Isbarrd = false; this._Islinerd = false; this._Ispierd = true; this._Isdoughnutrd = false;
      this._Isbarpd = true; this._Islinepd = false; this._Ispiepd = false; this._Isdoughnutpd = false;
      this._Isbarpdd = true; this._Islinepdd = false; this._Ispiepdd = false; this._Isdoughnutpdd = false;

      this.ProductTypeWiseStock();
      this.FastMovingStock();
      this.StockFreshNess();
    }

    ProductTypeWiseStock(){
      this._IsProgressSpinner = true;

      var branchid = this._stockdashboardform.get("branchid").value || null;

      this._stockDashboardService.ProductTypeWiseStock(branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this.trimlableDataProduct=[]; this.totalProductTypeStocks=[]; this.basicDataProduct =[];
        this.trimlableDataProductDots = [];
        this.totalProductTypeStocksValues = [];
        this.productTypeStock = []
        this._ProductTypeWiseStockOpt = []
        this.productTypeStockVals = []
        this.totalProductTypeStocksSymb = []

        if(resultJSON.productwisestockdetails != null &&
          resultJSON.productwisestockdetails) {
            this.noProductTypeSV = false

            if(resultJSON.productwisestockdetails.length > 0) {
              this.totalStock = resultJSON.productwisestockdetails[0].TOTALSTOCK;

                for (let i = 0; i < resultJSON.productwisestockdetails.length; i++) {
                // this.lableData[i] = resultJSON.Stockdetails[i].PRODUCTNAME;
                  this.trimlableDataProduct[i] = (resultJSON.productwisestockdetails[i].PRODUCTTYPENAME)
                  this.trimlableDataProductDots[i] = (resultJSON.productwisestockdetails[i].PRODUCTTYPENAME).slice(0, 7) + '...';

                  this.totalProductTypeStocks[i] = resultJSON.productwisestockdetails[i].PERCENTAGE;

                  this.totalProductTypeStocksSymb[i] = resultJSON.productwisestockdetails[i].PERCENTAGESYMB;
                  this.totalProductTypeStocksValues[i] = resultJSON.productwisestockdetails[i].PURCHASEVALUE;

                  this.productTypeStock.push({
                    name: this.trimlableDataProductDots[i],
                    series: [
                      {
                        name: this.trimlableDataProduct[i],
                        nameSymb: this.totalProductTypeStocksSymb[i],
                        value: this.totalProductTypeStocks[i],
                        color: this.barColors[i],
                        enabled: true
                      }
                    ],
                  })

                  this.productTypeStockVals.push({
                    name: this.trimlableDataProductDots[i],
                    series: [
                      {
                        name: this.trimlableDataProduct[i],
                        nameSymb: this.totalProductTypeStocksValues[i],
                        value: this.totalProductTypeStocksValues[i],
                        color: this.barColors[i],
                        enabled: true
                      }
                    ],
                  })

                  this._ProductTypeWiseStock = {
                    labels: this.trimlableDataProductDots,
                    datasets: [
                        {
                        label: 'Type Wise Stocks',
                        backgroundColor: [
                          '#42A5F5',
                          "#FF6384",
                          "#3655eb",
                          "#e757eb",
                          "#04a981",
                          "#FFCE56"
                        ],
                        data: this.totalProductTypeStocks,
                        lbls: this.trimlableDataProduct
                      },
                    ],

                  };

                  this._ProductTypeWiseStockOpt = {
                      tooltips: {
                        callbacks: {
                          title: (item, data) => {
                            var datasetLabel = data.datasets[0].label || '';
                            return datasetLabel
                          },
                          label: (item, data) => {
                            var datasetLabel = data.datasets[0].lbls[item.index] || '';
                            var datasetVal = data.datasets[0].data[item.index] || '';
                            return `${datasetLabel}:  ${datasetVal}`
                          }
                        }
                      }
                  }

                  this._ProductTypeWiseStockValue = {
                    labels: this.trimlableDataProductDots,
                    datasets: [
                      {
                        label: 'Type Wise Stock Values',
                        backgroundColor:[
                          '#42A5F5',
                          "#FF6384",
                          "#3655eb",
                          "#e757eb",
                          "#04a981",
                          "#FFCE56"
                        ],
                        data: this.totalProductTypeStocksValues,
                        lbls: this.trimlableDataProduct

                      }
                    ],
                  }

                  this._ProductTypeWiseStockValueOpt = {
                    legend: {
                      labels: {
                        fontColor: '#333333',
                        fontWeight: '600'
                      }
                    },
                    tooltips: {
                      callbacks: {
                        title: (item, data) => {
                          var datasetLabel = data.datasets[0].label || '';
                          return datasetLabel
                        },
                        label: (item, data) => {
                          var datasetLabel = data.datasets[0].lbls[item.index] || '';
                          var datasetVal = data.datasets[0].data[item.index] || '';
                          return `${datasetLabel}:  ${datasetVal}`
                        }
                      }
                    }
                  }


                }
            }
            else {
              this.noProductTypeSV = true
              this.totalStock = 0
            }
          }
          else {
            this.noProductTypeSV = true
            this.totalStock = 0
          }
        console.log(this.productTypeStock);

        this._IsProgressSpinner = false;
      });

    }


    StockFreshNess(){
      var branchid = this._stockdashboardform.get("branchid").value || null;
      this._IsProgressSpinner = true;

      this._stockDashboardService.StockFreshNess(branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this.trimlableDataProduct=[];
        this.totalProductTypeStocks=[];
        this.stockFreshness =[];
        this.stockFreshnessValues = [];
        this.stockFreshnessVals = []

        if(resultJSON.stockFreshness != null &&
            resultJSON.stockFreshness) {
          this.noProductFreshness = false

          if(resultJSON.stockFreshness.length > 0) {
              var stockFreshLabel = Object.keys(resultJSON.stockFreshness[0])
              var stockFreshValues = Object.values(resultJSON.stockFreshness[0])
              for (let i = 0; i < stockFreshLabel.length; i++) {
              // this.lableData[i] = resultJSON.Stockdetails[i].PRODUCTNAME;
                this.trimlableDataProduct[i] = (stockFreshLabel[i]).slice(0, 15) + '...';
                this.stockFreshnessValues[i] = stockFreshValues[i];

                this.stockFreshnessVals.push({
                  name: this.trimlableDataProduct[i],
                  series: [
                    {
                      name: this.trimlableDataProduct[i],
                      nameSymb: this.stockFreshnessValues[i],
                      value: this.stockFreshnessValues[i],
                      color: this.barColors[i],
                      enabled: true
                    }
                  ],
                })

                this.stockFreshness = {
                  labels: this.trimlableDataProduct,
                  datasets: [
                    {
                      label: 'Freshness Stock',
                      backgroundColor: [
                        '#42A5F5',
                        "#FF6384",
                        "#3655eb",
                        "#e757eb",
                        "#b64c11",
                        "#FFCE56"
                    ],
                      data: this.stockFreshnessValues
                    }
                  ],
                };

              }
          }
          else {
            this.noProductFreshness = true
          }
        }
        else {
          this.noProductFreshness = true
        }
        this._IsProgressSpinner = false;


      });

    }

    FastMovingStock(){
      var branchid = this._stockdashboardform.get("branchid").value || null;
      this._IsProgressSpinner = true;

      this._stockDashboardService.FastMovingStock(branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this.fastMovingStock = result.fastMovingStock;
        if(!this.fastMovingStock ||
           this.fastMovingStock == null ||
           this.fastMovingStock.length == 0) {
          this.noFastMovingStock = true;
        }
        else {
          this.noFastMovingStock = false;
        }
        this._IsProgressSpinner = false;
      });

    }


    downloadChart(e: any) {
      this._IsProgressSpinner = true

      const childElement = e.target.offsetParent.offsetParent.offsetParent.offsetParent.children[0];
      let innerRgtHtml = childElement.children[0].children[0].children[1].innerHTML
      childElement.children[0].children[0].children[1].style.visibility = 'hidden'

      // console.log(this.chartBody.nativeElement.parentNode.parentNode.childNodes[1].children[0].children[0].children[0].children[0]);
      let svg = childElement;
      const pdf = new jsPDF();
      html2canvas(svg).then((svgCanvas) => {
        // Convert HTML element to canvas
        html2canvas(svg)
          .then((htmlCanvas) => {
            // Get actual SVG and HTML dimensions
            const svgWidth = svgCanvas.width;
            const svgHeight = svgCanvas.height;
            const htmlWidth = htmlCanvas.width;
            const htmlHeight = htmlCanvas.height;

            // Set canvas dimensions to match actual element dimensions
            const canvasWidth = Math.max(svgWidth, htmlWidth);
            const canvasHeight = svgHeight + htmlHeight; // Assuming you want to stack SVG and HTML images vertically

            // Create a new canvas with the required dimensions
            const combinedCanvas = document.createElement('canvas');
            combinedCanvas.width = canvasWidth;
            combinedCanvas.height = canvasHeight;
            const context = combinedCanvas.getContext('2d');

            // Draw HTML canvas first
            // context.drawImage(htmlCanvas, 0, 0);

            // Draw SVG canvas below HTML canvas
            context.drawImage(svgCanvas, 0, 0);

            // Convert the combined canvas to an image
            const combinedImgData = combinedCanvas.toDataURL('image/png');

            // Add the combined image to the PDF
            const margin = 10;
            const padding = 10;
            const imgWidth = 190;
            const imgHeight = (canvasHeight * imgWidth) / canvasWidth;

            pdf.addImage(combinedImgData, 'PNG', margin, margin, imgWidth - 2 * padding, imgHeight - 2 * padding);
            this._IsProgressSpinner = false;

            // Save the PDF
            pdf.save('Chart.pdf');
            childElement.children[0].children[0].children[1].style.visibility = 'visible'

        })
        .catch((err) => {
          this._IsProgressSpinner = false
          console.log("Chart Error:", err);
        })
      });



      // Convert SVG to canvas
      // html2canvas(svgTitle).then((canvas) => {
      //   const imgData = canvas.toDataURL('image/png');
      //   const imgWidth = 210; // A4 page width in mm
      //   const imgHeight = (canvas.height * imgWidth) / canvas.width;

      //   pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      // });
      // html2canvas(svg).then((canvas) => {
      //   const imgData = canvas.toDataURL('image/png');
      //   const imgWidth = 210; // A4 page width in mm
      //   const imgHeight = (canvas.height * imgWidth) / canvas.width;

      //   pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      // });
      // pdf.save('chart.pdf');
  }

    // NGX CHART

    onSelect(data): void {
      console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    }

    onActivate(data): void {
      console.log('Activate', JSON.parse(JSON.stringify(data)));
    }

    onDeactivate(data): void {
      console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }

    getParentElementsList(childElement: HTMLElement): HTMLElement[] {
      const parentElementsList: HTMLElement[] = [];

      let currentElement = childElement.parentElement;
      while (currentElement) {
        parentElementsList.push(currentElement);
        currentElement = currentElement.parentElement;
      }

      return parentElementsList;
    }

  }

