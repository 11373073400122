<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Stock Movement Summary</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_stocksummaryform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_branch" optionLabel="branchname" [(ngModel)]="branchid"
                                        optionValue="branchid" displaySelectedLabel=true  formControlName="branchid"
                                        [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item" >
                                            
                                            <div
                                                [style]="account.branchstatus == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="branchid">Branch Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _stocksummaryform.get('branchid').touched) && _stocksummaryform.get('branchid').errors?.SelectBranch">
                                    Please Select Atleast One Branch
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_producttype"
                                        optionLabel="producttypename" formControlName="producttypeid"
                                        optionValue="producttypeid" (onChange)="getProductcategory($event)"
                                        displaySelectedLabel=true [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type </label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _stocksummaryform.get('producttypeid').touched) && _stocksummaryform.get('producttypeid').errors?.SelectProductType">
                                    Please Select Atleast One Product Type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_productcategory"
                                        formControlName="productcategoryid" optionLabel="categoryname"
                                        optionValue="productcategoryid" (onChange)="getProductname($event)"
                                        displaySelectedLabel=true [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category </label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _stocksummaryform.get('productcategoryid').touched) && _stocksummaryform.get('productcategoryid').errors?.SelectProductCategory">
                                    Please Select Atleast One Product Category
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_productname" formControlName="productid"
                                        [selectionLimit]="10" optionLabel="productname" optionValue="productid"
                                        (onChange)="ChangeProduct($event)" displaySelectedLabel=true [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _stocksummaryform.get('productid').touched) && _stocksummaryform.get('productid').errors?.SelectProductName">
                                    Please Select Atleast One Product Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                                        formControlName="brandid" displaySelectedLabel=true 
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="brandid">Brand Name </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar name="fromdate" formControlName="fromdate" [showIcon]="true"
                                        *ngIf="false" dateFormat="dd/mm/yy" [monthNavigator]="true"
                                        [yearNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1950:2050">
                                    </p-calendar>
                                    <label for="fromdate" *ngIf="false"> From Date </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar name="todate" formControlName="todate" [showIcon]="true" *ngIf="false"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate"
                                        [yearNavigator]="true" yearRange="1950:2050">
                                    </p-calendar>
                                    <label for="todate" *ngIf="false">To Date </label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" type="button" (click)="search()" title="Search" icon="pi pi-search"></button>
                                <button pButton pRipple label="Clear" title="Clear" (click)="clear()" icon="pi pi-times" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_Stocksummaryviews" [scrollable]="true" scrollHeight="450px" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['productname','brandname','referenceno','openingstock','closingstock','transtock','stockvalues']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="productname" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 80px;" pSortableColumn="sno">S. No
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="branchname">Branch Name
                                    <p-sortIcon field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="brandname">Brand Name
                                    <p-sortIcon field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="openingstock">Recon Opening Stock
                                    <p-sortIcon field="openingstock">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="openingstock">April 10th Stock
                                    <p-sortIcon field="openingstock">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="grnqty">GRN Qty
                                    <p-sortIcon field="grnqty">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="damagedqty">Damaged Qty
                                    <p-sortIcon field="damagedqty">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="returnqty">Return Qty
                                    <p-sortIcon field="returnqty">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="invoiceqty">Invoice Qty
                                    <p-sortIcon field="invoiceqty">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="deliveryqty">Delivery Qty
                                    <p-sortIcon field="deliveryqty">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="adjustedstock">Adjusted Qty
                                    <p-sortIcon field="adjustedstock">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="prestock">Previous Day Stock
                                    <p-sortIcon field="prestock">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currentstock">Current Stock
                                    <p-sortIcon field="currentstock">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="stockdiff">Stock Difference
                                    <p-sortIcon field="stockdiff">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Stocksummaryview let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 80px;">
                                    {{i+ 1}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.branchname}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.productname}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.brandname}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.updatedopeningstock}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.aprilstock}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.grnqty}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.damagedqty}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.returnqty}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.invoiceqty}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.deliveryqty}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.adjustedstock}}
                                </td>

                                <td>
                                    {{_Stocksummaryview.prestock}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.currentstock}}
                                </td>
                                <td>
                                    {{_Stocksummaryview.stockdiff}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>