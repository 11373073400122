export class Stocktransferorderdeailview {
    stocktransferorderdetailid: number;
    stocktransferid: number;
    productid: number;
    productname: string;
    variantid: number;
    variantdescription: string;
    uomcode: string;
    uomname: string;
    requestquantity: number;
    approvedquantity: number ;
    deliveredquantity: number ;
    unitprice: number;
    amount: number ;
    currencyreferenceid: string;
    currencyid: number;
    amountinusd: number ;
    isdelivered: boolean;
    accountingyear: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}