import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { BankreconciliationService } from 'src/app/core/Services/accounts/bankreconciliation.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { BankreconcilationView } from 'src/app/core/Views/bankreconciliationview';

import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-bank-reconciliation',
  templateUrl: './v-bank-reconciliation.component.html',
  styleUrls: ['./v-bank-reconciliation.component.css']
})
export class VBankReconciliationComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _bankreconciliationsview: BankreconcilationView[] = [];
  _bankreconciliationview: BankreconcilationView;
  _userid = sessionStorage["userid"];
  _series = sessionStorage["Series"];
  _IsProgressSpinner: boolean = true;
  _Action: IAction;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _branchid: number;
  _roleid = sessionStorage["currentRole"]
  cols: any[];
  type: any[];
  _selectedtype:string = "Receipt";
  mindate: Date;
  maxdate: Date;
  constructor(private fb: FormBuilder, private _BankReconciliationService: BankreconciliationService, private _CustomExceptionService: CustomExceptionService, private router: Router, private utility: CommonUtilities, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService, private exportUtility: ExportUtility) {
    //Go To Create Page
    this._hotkeysService.add(new Hotkey('alt+n', (event: KeyboardEvent): boolean => {
      this.GoToCreate(event);
      return false; // Prevent bubbling
    }));
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(63);
    // this._Action = this._AccessRightsService.getAccessRights(68);
    this.HotKeyIntegration();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.cols = [
      { field: 'reconcilationdate', header: 'Reconciliation Date' },
      { field: 'amount', header: 'Amount' },
      { field: 'paymenttypename', header: 'Type' },
      { field: 'paymentdate', header: 'Date' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this.type = [
      {id: 1, name: 'Payment'},
      {id: 2, name: 'Receipt'},
    
    ]
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.Fetchall();

  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._BankReconciliationService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate,this._selectedtype).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._bankreconciliationsview = resultJSON.bankreconcilationViews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }

  view(event) {
    this.router.navigate(['/cBankReconciliation'], { state: { reconcilationdate: event.reconcilationdate, action: 'view' } });
  }

  delete(event) {
    alert("delete");
  }
  GoToCreate(event) {
    this.router.navigate(['/cBankReconciliation']);
  }
  Cancel(event) {
    var bankreconcilationid = event.bankreconcilationid;
    var series = event.series;
    this._IsProgressSpinner = true;
    this._BankReconciliationService.Cancel(bankreconcilationid, series).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let BankReconciliationList = this.GetBankReconciliationList();
    this.exportUtility.exportExcel(BankReconciliationList, 'BankReconciliation', 'xlsx');
  }

  ExportToPdf() {
    let BankReconciliationList = this.GetBankReconciliationList();
    this.exportUtility.ExportToPdf(BankReconciliationList, 'BankReconciliation.pdf');
  }

  GetBankReconciliationList() {
    let BankReconciliationList = [];
    this._bankreconciliationsview.forEach(element => {
      let bankreconciliation: any = {};
      bankreconciliation["Reconciliation Date"] = element.reconcilationdate;
      bankreconciliation["Amount"] = element.amount;
      bankreconciliation["Type"] = element.paymenttypename;
      bankreconciliation["Date"] = element.paymentdate;
      bankreconciliation["Created By"] = element.createdbyname;
      bankreconciliation["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      BankReconciliationList.push(bankreconciliation);
    });
    return BankReconciliationList;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
}
