<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Sales Commission</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" class="" type="submit"  *ngIf="_action != 'view'"
                                (click)="onSave()"></button>
                            <button pButton pRipple label="" title="Clear" icon="pi pi-trash" class=""  *ngIf="_action != 'view'"
                                (click)="reset($event)" class="p-button-danger"></button>
                                <button pButton pRipple title="Send To Approval" icon="las la-check" type="button"
                                [disabled]="isDisabledsendapparoval" (click)="SendToApproval($event)"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_salescommissionform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_branches" name="branchid" [autoDisplayFirst]="false"
                                    optionLabel="branchname" optionValue="branchid"  
                                        formControlName="branchid">
                                    </p-dropdown>
                                    <label for="branchid">Branch</label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _salescommissionform.get('branchid').touched) && _salescommissionform.get('branchid').errors?.SelectSalesmanType">
                                    please Select branch
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_Salestypes" name="salestype" [autoDisplayFirst]="false"
                                        optionLabel="metasubdescription" optionValue="metasubcode"  
                                      (onChange)="Onsalesmantypechange($event)"  formControlName="salestype">
                                    </p-dropdown>
                                    <label for="salestype">Salesman Type</label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _salescommissionform.get('salestype').touched) && _salescommissionform.get('salestype').errors?.SelectSalesmanType">
                                    please Select Salesman Type
                                </span>
                            </td>
                           <!-- <td style="width: 20%;">
                                 <span class="p-float-label">
                                    <input type="number" name="basetarget" pInputText formControlName="basetarget">
                                    <label for="basetarget">Base Target <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _salescommissionform.get('basetarget').touched) && _salescommissionform.get('basetarget').errors?.BaseTargetRequired">
                                    please Enter Base Target
                                </span> 
                            </td>-->
                             <!--<td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="basecommision" pInputText formControlName="basecommision">
                                    <label for="basecommision">Base Commission <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _salescommissionform.get('basecommision').touched) && _salescommissionform.get('basecommision').errors?.BaseCommisionRequired">
                                    please Enter Base Commision
                                </span> 
                            </td>-->
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="effectivefrom" formControlName="effectivefrom" [showIcon]="true"
                                        (onSelect)="OnFromDateSelect($event)" [minDate]="_MaxFromDate" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true" yearRange="2020:2050">
                                    </p-calendar>
                                    <label for="effectivefrom">Effective From <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _salescommissionform.get('effectivefrom').touched) && _salescommissionform.get('effectivefrom').errors?.EffectiveFromRequired">
                                    please Select Effective From
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="effectiveto" formControlName="effectiveto" [showIcon]="true"
                                      dateFormat="dd/mm/yy"  [minDate]="minDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2020:2050">
                                    </p-calendar>
                                    <label for="effectiveto">Effective To 
                                       <span class="hlt-txt">*</span> 
                                    </label>
                                </span>
                                   <!-- changes done by chandru -->
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _salescommissionform.get('effectiveto').touched) && _salescommissionform.get('effectiveto').errors?.EffectiveToRequired">
                                    please Select Effective To
                                </span> -->
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText [(ngModel)]="Currency"[ngModelOptions]="{standalone: true}">
                                    <label for="servicerequestno">Currency</label>
                                </span>
                            </td>
                        </tr>
                        <!-- <tr>
                            
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_salescommissionstatus"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        [autoDisplayFirst]="false" formControlName="salescommissionstatus">
                                    </p-dropdown>
                                    <label for="salescommissionstatus">Status</label>
                                </span>
                            </td> 
                            <td></td>
                            <td></td>
                        </tr>-->
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Slab Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_SalesCommissionDetail" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['tempminrange','tempmaxrange','tempcommission']" [rowHover]="true"
                        dataKey="customerid" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)"
                        (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px;text-align:center">Action</th>
                                <!-- <th>Slab Name</th> -->
                                <th>Minimum Range</th>
                                <th>Maximum Range</th>
                                <th>Commission</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Slab let-i="rowIndex">
                            <tr>
                                <td style="width: 50px" style="text-align:center">
                                    <button pButton pRipple label="" title="Add" icon="pi pi-plus"
                                        class="p-button-success p-mr-2" (click)="addCommission($event)"></button>
                                    <button pButton pRipple label="" title="Remove" icon="pi pi-trash"
                                    [disabled]="_Slab.isdisabled"  class="p-button-danger p-mr-2" (click)="RemoveCommission(_Slab)"></button>
                                </td>
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text"  *ngIf="_action != 'view'" pInputText (focusout)="checkDuplicate($event,'tempslab',i)" [(ngModel)]="_Slab.tempslab">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Slab.tempslab}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [disabled]="MinimumRange"   *ngIf="_action != 'view'" (focusout)="checkDuplicate($event,'tempminrange',i)" [(ngModel)]="_Slab.tempminrange">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Slab.tempminrange}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [disabled]="_Slab.isdisabled"  *ngIf="_action != 'view'" (focusout)="checkDuplicate($event,'tempmaxrange',i)" [(ngModel)]="_Slab.tempmaxrange">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Slab.tempmaxrange}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText  *ngIf="_action != 'view'" (focusout)="checkDuplicate($event,'tempcommission',i)" [(ngModel)]="_Slab.tempcommission">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Slab.tempcommission}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <!-- <table class="normal-table">
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table> -->
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>