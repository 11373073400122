import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Pettycashsettlement } from '../../Models/Pettycashsettlement';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class PettycashsettlementService {
  public _Action: IAction;
  public _PettycashSettlementService = environment.PettycashSettlementService;
  public _httpOptions;
  token: any;
  header: any;
  public _PettycashList: Pettycashsettlement[] = [];
  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 1)
  }
  createHeaders(token: any, type: number): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(p_Pettycashsettlementid: number, branchid: number, userid: number): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._PettycashSettlementService + '/' + p_Pettycashsettlementid + '/' + branchid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._PettycashSettlementService + '/FetchAll/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }
  // create(p_Pettycashsettlement: object) {
  //   console.log("Create");
  //   var Query = this._PettycashSettlementService;
  //   return this.http.post<{ data: any }>(Query, p_Pettycashsettlement);
  // }

  //Save Function:
  create(p_PettycashSettlementForm: FormData) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._PettycashSettlementService;
    return this.http.post<{ data: any }>(Query, p_PettycashSettlementForm, { headers });
  }
  edit(p_Pettycashsettlement: object) {
    const headers = this.createHeaders(this.token, 2)
    console.log("Update");
    var Query = this._PettycashSettlementService;

    return this.http.put<{ data: any }>(Query, p_Pettycashsettlement, { headers });
  }
  Cancel(pettycashsettlementid: number) {
    const headers = this.createHeaders(this.token, 2)
    console.log("cancel");
    var Query = this._PettycashSettlementService + '/Cancel/' + pettycashsettlementid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
}
