import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SalescommissionService } from 'src/app/core/services/rewards/salescommission.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { Salescommission } from 'src/app/core/Models/Salescommissionheader';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { VSalesmanTypeSelect, vBaseTarget, vBaseCommision, vSlab, vMinRange, vMaxRange, vCommision, vEffectiveFrom, vEffectiveTo } from 'src/app/core/Validators/validation';
import { Salescommissionview } from 'src/app/core/Views/Salescommissionview';
import { KeyPress } from 'src/assets/js/KeyPress';
import { Branch } from 'src/app/core/Models/Branch';
import { Salescommissiondetail } from 'src/app/core/Models/Salescommissiondetail';
import { DatePipe } from '@angular/common';
import { element } from 'protractor';
import * as moment from 'moment';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-c-salescommission',
  templateUrl: './c-salescommission.component.html',
  styleUrls: ['./c-salescommission.component.css']
})
export class CSalescommissionComponent implements OnInit {
  _salescommissionform: FormGroup;
  _salescommissiondetailform: FormGroup;
  _action: string = "create";
  _submitted = false;
  _salescommissionid: number;
  _Salestypes: Metadatum;
  _salescommissionstatus: Metadatum;
  _SalesCommission: Salescommission;
  _SalesCommissionDetail: Salescommissiondetail[] = [];
  _SalesCommissionOBJ: any;
  _IsProgressSpinner: boolean;
  _userid: number = sessionStorage["userid"];
  _branchid: number = sessionStorage["BranchID"];
  // _Slabs: Salescommissiondetail[] = [];
  _Slab: Salescommissionview;
  selectedrows: any;
  minDate: Date;
  noDuplicateOccurs: boolean = true;
  duplicateCount = 0;
  isDisabledsendapparoval = true;
  MinimumRange: boolean = false;
  _branches: Branch;
  datepipe = new DatePipe('en-US');
  _MaxFromDate :Date;
  Currency:string='USD'
  _Action: IAction;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, 
    private _AccessRightsService: AccessRightsService,
    private _SalescommissionService: SalescommissionService,
    private messageService: MessageService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService,
    private _router: Router, public keypress: KeyPress) {
  }
  InitializeForm() {
    this._salescommissionform = this.fb.group({
      salescommissionid: [0],
      salestype: ['', VSalesmanTypeSelect],
      companyid: [0],
      branchid: [parseInt(sessionStorage["currentbranchid"])],
      basetarget: [0],
      basecommision: [0],
      effectivefrom: [],
      // changes done by chandru
      effectiveto: [],
      salescommissionstatus: ["MSC00001"],
      status: [],
      statusremarks: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      salescommissiondetails: []
    });
    this._salescommissiondetailform = this.fb.group({
      salescommissiondetailid: [0],
      salescommissionid: [0],
      slab: ['', vSlab],
      minrange: ['', vMinRange],
      maxrange: ['', vMaxRange],
      commision: ['', vCommision],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      salesCommission: []
    });
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(78);
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._salescommissionid = history.state?.salescommissionid ? history.state?.salescommissionid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
    this._salescommissionform.get("effectiveto").disable();
    this._IsProgressSpinner = true;
    this._SalescommissionService.PageOnLoad(this._salescommissionid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //console.log("pageload", resultJSON);
      this._branches = resultJSON.branches;
      this._Salestypes = resultJSON.salestypes;
      this._salescommissionstatus = resultJSON.salesCommissionStatuses;

      this._IsProgressSpinner = false;
      // if(this._SalesCommissionDetail.length > 1){
      //   alert("ok");
      //   this.MinimumRange = true
      // }

      if (this._action == 'create') {

        var l_CommissionDtail = new Salescommissiondetail();
        l_CommissionDtail.createdby = this._userid;
        l_CommissionDtail.createdon = new Date();
        l_CommissionDtail.modifiedby = this._userid;
        l_CommissionDtail.modifiedon = new Date();
        l_CommissionDtail.salescommissiondetailid = 0;
        l_CommissionDtail.salescommissionid = this._salescommissionform.get("salescommissionid").value;
        l_CommissionDtail.branchid = this._salescommissionform.get("branchid").value;
        l_CommissionDtail.salestype = this._salescommissionform.get("salestype").value;
        this._SalesCommissionDetail.push(l_CommissionDtail);
        this.isDisabledsendapparoval = true;

      }
      if (this._action == 'edit' || this._action == 'view') {
        this._salescommissionform.get("effectiveto").disable();
        this._salescommissionform.get("effectivefrom").disable();
        this._salescommissionform.get("branchid").disable();
        this._salescommissionform.get("salestype").disable();
        const updateJSON = JSON.parse(JSON.stringify(result));
        updateJSON.salesCommission.effectivefrom = new Date(updateJSON.salesCommission.effectivefrom);
        updateJSON.salesCommission.effectiveto = new Date(updateJSON.salesCommission.effectiveto);
        this._salescommissionform.setValue(updateJSON.salesCommission);
        this._SalesCommissionDetail = updateJSON.salescommissiondetails;
      }
      if (this._action == 'view') {
        this._salescommissionform.disable();
      }
      this.SalesCommissionDetailsDisabled();
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  checkDuplicate(event, field, row) {
    if (!this._salescommissionform.get('branchid').value) {
      this._CustomExceptionService.handleError("Please Select Branch")
      return;
    }
    if (!this._salescommissionform.get('salestype').value) {
      this._CustomExceptionService.handleError("Please Select Sales Type")
      return;
    }
    if (this._SalesCommissionDetail.length > 1) {
      this.duplicateCount = 0;
      this.noDuplicateOccurs = false;
      var value = "";
      var newValue = "";
      for (var i = 0; i < this._SalesCommissionDetail.length; i++) {
        if (i == row) {
          continue;
        }
        else {
          value = this._SalesCommissionDetail[i][field];
          newValue = event.target.value;
          if (field == "slab") {
            if (!value && !newValue) {
              value = value.trim();
              value = value.toUpperCase();
              newValue = newValue.trim();
              newValue = newValue.toUpperCase();
            }
          }
          if (value == newValue) {
            let rows = row + 1
            this._CustomExceptionService.handleWarning("Given " + newValue + " already exists at row " + rows);
            this.duplicateCount = this.duplicateCount++;
            break;
          }
        }
      }
    }
    if (this.duplicateCount == 0) {
      this.noDuplicateOccurs = true;
    }
  }
  validateGrid() {
    var slab;
    var minValue = 0;
    var maxValue = 0;
    var commission = 0;
    var valid;
    if (this._SalesCommissionDetail.length != 0) {
      for (var i = 0; i < this._SalesCommissionDetail.length; i++) {
        //slab=this._SalesCommissionDetail[i]["tempslab"];
        minValue = this._SalesCommissionDetail[i]["tempminrange"];
        maxValue = this._SalesCommissionDetail[i]["tempmaxrange"];
        commission = this._SalesCommissionDetail[i]["tempcommission"];
        if (!minValue || !maxValue || !commission) {
          valid = false;
          this._CustomExceptionService.handleWarning("Fill slab details");
          break;
        }
        else if (minValue || maxValue || commission) {
          if (minValue == maxValue || minValue > maxValue) {
            valid = false;
            this._CustomExceptionService.handleWarning("Maximum value must be greater than minimum value");
            break;
          }
          else {
            valid = true;
          }
        }
        else {
          valid = true;
        }
      }
    }
    return valid;
  }
  SalesCommissionDetailsDisabled() {
    let salesCommissiobnGridlength = this._SalesCommissionDetail.length;
    salesCommissiobnGridlength = salesCommissiobnGridlength - 1;
    for (var i = 0; i < this._SalesCommissionDetail.length; i++) {
      if (salesCommissiobnGridlength == i) {
        this._SalesCommissionDetail[i].isdisabled = false;
      } else {
        this._SalesCommissionDetail[i].isdisabled = true;
      }
    }
  }

  onSave() {
    this._submitted = true;
    // 28. if all the data is valid allow to save the record in the database 
    var validGrid = this.validateGrid();
    if (!this._salescommissionform.get("effectivefrom").value) {
      this._CustomExceptionService.handleError("Please Select Effective From");
      return;
    }
    if (!this._salescommissionform.get("effectiveto").value) {
      this._CustomExceptionService.handleError("Please Select Effective To");
      return;
    }
    if (this._salescommissionform.valid && validGrid == true && this.noDuplicateOccurs == true) {
      this._SalesCommissionDetail.forEach(element => {
        element.salestype = this._salescommissionform.get("salestype").value;
      })
      this._salescommissiondetailform.get("salescommissionid").setValue(this._salescommissionid);
      this._SalesCommission = this._salescommissionform.getRawValue();
      if (this._salescommissionid > 0)
        this._SalesCommission.salescommissionid = this._salescommissionid;
      this._SalesCommission.salescommissionstatus = "MSC00045";
      let fromdate = this.datepipe.transform(this._SalesCommission.effectivefrom, 'yyyy-MM-dd');
      let todate = this.datepipe.transform(this._SalesCommission.effectiveto, 'yyyy-MM-dd');
      this._SalesCommission.effectivefrom = (new Date(fromdate));
      this._SalesCommission.effectiveto = (new Date(todate));
      if (fromdate > todate) {
        this._CustomExceptionService.handleError("From Date should be less than the To Date");
        return;
      }
      this._SalesCommissionOBJ = { "SalesCommission": this._SalesCommission, "SalesCommissiondetail": this._SalesCommissionDetail };
      //this.onEd();
      if (this._action == 'create') {
        this._SalesCommission.createdon = new Date();
        this._SalesCommission.createdby = sessionStorage["userid"];
        this._SalesCommission.accountingyear = new Date().getFullYear();
        this._IsProgressSpinner = true;
        // this.onEd();
        this._SalescommissionService.create(this._SalesCommissionOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._salescommissionid = resutJSON.salescommissionid;
            //this.SendToApproval();
            // this._SalesCommissionDetail=[];
            // this._action="create";
            this.isDisabledsendapparoval = false;
            //this.reset(null);
            this._IsProgressSpinner = false;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }

      else if (this._action == 'edit') {
        this._SalesCommission.modifiedon = new Date();
        this._SalesCommission.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._SalescommissionService.edit(this._SalesCommissionOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this.isDisabledsendapparoval = false;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }

  }

  RemoveCommission(pCommissionDetail: Salescommissiondetail) {
    var index = this._SalesCommissionDetail.indexOf(pCommissionDetail);
    this._SalesCommissionDetail.splice(index, 1);
    if (this._SalesCommissionDetail.length <= 0) {
      var l_CommissionDtail = new Salescommissiondetail();
      l_CommissionDtail.createdby = this._userid;
      l_CommissionDtail.createdon = new Date();
      l_CommissionDtail.modifiedby = this._userid;
      l_CommissionDtail.modifiedon = new Date();
      l_CommissionDtail.salescommissiondetailid = 0;
      l_CommissionDtail.salescommissionid = this._salescommissionform.get("salescommissionid").value;
      this._SalesCommissionDetail.push(l_CommissionDtail);
      this.MinimumRange = false;

    }
    this.SalesCommissionDetailsDisabled();
  }
  addCommission(event) {
    if (!this._salescommissionform.get('branchid').value) {
      this._CustomExceptionService.handleError("Please Select Branch")
      return;
    }
    if (!this._salescommissionform.get('salestype').value) {
      this._CustomExceptionService.handleError("Please Select Sales Type")
      return;
    }
    var rowvalue = 0;
    for (var i = 0; i < this._SalesCommissionDetail.length; i++) {
      if (this._SalesCommissionDetail[i].tempmaxrange == null || this._SalesCommissionDetail[i].tempmaxrange == 0) {
        this._CustomExceptionService.handleWarning("Fill the slab detail");
        rowvalue = 1;
      }
      if (this._SalesCommissionDetail[i].tempminrange > this._SalesCommissionDetail[i].tempmaxrange) {
        this._CustomExceptionService.handleError("Maximum value must be greater than minimum value");
        return;
      }
    }
    if (rowvalue == 0) {
      var l_CommissionDtail = new Salescommissiondetail();
      l_CommissionDtail.createdby = this._userid;
      l_CommissionDtail.createdon = new Date();
      l_CommissionDtail.modifiedby = this._userid;
      l_CommissionDtail.modifiedon = new Date();
      l_CommissionDtail.salescommissiondetailid = 0;
      l_CommissionDtail.salescommissionid = this._salescommissionform.get("salescommissionid").value;
      l_CommissionDtail.branchid = this._salescommissionform.get("branchid").value;
      l_CommissionDtail.salestype = this._salescommissionform.get("salestype").value;
      this._SalesCommissionDetail.push(l_CommissionDtail);
      this.MinimumRange = true
    }
    for (var i = 1; i < this._SalesCommissionDetail.length; i++) {

      this._SalesCommissionDetail[i].tempminrange = this._SalesCommissionDetail[i - 1].tempmaxrange + 1;
    }
    this.SalesCommissionDetailsDisabled();
  }
  reset(event) {
    // 32.clear all the fields which the data has entered
    this._salescommissionform.reset();
    this._salescommissiondetailform.reset();
    this._SalesCommissionDetail = [];
    this.addCommission('');
    this.InitializeForm();
  }
  onRowSelect(event) {
    //console.log("Selected Suppliers");
  }
  onRowUnselect(event) {
    //console.log("eventUnfired");
  }
  goBack(event) {
    // 60.on clicking back button , it Navigates to the search screen
    // 61.saved record should show based on the modified date in the search screen 
    //console.log("goBack");
    this._router.navigate(['/vSalescommission']);
  }
  onEd() {
    alert("test");
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._SalesCommissionOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }

  OnFromDateSelect(event) {
    this.minDate = event;
    this._salescommissionform.get("effectiveto").enable();
  }

  Onsalesmantypechange(event) {
    // this._salescommissionform.get("effectivefrom").setValue(new Date());
    // this._salescommissionform.get("effectiveto").setValue(null);
    this._salescommissionform.get("salescommissionid").setValue(0);
    this._salescommissionform.get("salescommissionstatus").setValue("MSC00001");
    this._IsProgressSpinner = true;
    this._SalescommissionService.Salestypechange(event.value, this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.maxToDate) {
        this._salescommissionform.get("effectivefrom").reset();
        let maxdate = new Date(moment(new Date(resultJSON.maxToDate)).format('YYYY-MM-DD HH:mm:ss'));
      
        this._salescommissionform.get("effectivefrom").setValue(new Date(moment(maxdate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss')));
        this.OnFromDateSelect(new Date(this._salescommissionform.get("effectivefrom").value));
        this._MaxFromDate= new Date(this._salescommissionform.get("effectivefrom").value);
      } else {
        this._salescommissionform.get("effectivefrom").setValue(new Date());
        this.OnFromDateSelect(new Date(this._salescommissionform.get("effectivefrom").value));
      }

      //console.log("sales", resultJSON)
      // this._SalesCommissionDetail = resultJSON.salescommissionviews;
      // if (this._SalesCommissionDetail.length > 0) {
      //   this._SalesCommissionDetail[0].effectivefrom = new Date(this._SalesCommissionDetail[0].effectivefrom);
      //   this._SalesCommissionDetail[0].effectiveto = new Date(this._SalesCommissionDetail[0].effectiveto);
      //   this._salescommissionform.get("salescommissionid").setValue(this._SalesCommissionDetail[0].salescommissionid);
      //   this._salescommissionform.get("basetarget").setValue(this._SalesCommissionDetail[0].basetarget);
      //   this._salescommissionform.get("basecommision").setValue(this._SalesCommissionDetail[0].basecommision);
      //   this._salescommissionform.get("effectivefrom").setValue(new Date(this._SalesCommissionDetail[0].effectivefrom));
      //   this._salescommissionform.get("effectiveto").setValue(new Date(this._SalesCommissionDetail[0].effectiveto));
      //   this._salescommissionform.get("salescommissionstatus").setValue(this._SalesCommissionDetail[0].salescommissionstatus);
      // }
      // else {
      //   var l_CommissionDtail = new Salescommissiondetail();
      //   l_CommissionDtail.createdby = this._userid;
      //   l_CommissionDtail.createdon = new Date();
      //   l_CommissionDtail.modifiedby = this._userid;
      //   l_CommissionDtail.modifiedon = new Date();
      //   l_CommissionDtail.salescommissiondetailid = 0;
      //   l_CommissionDtail.salescommissionid = this._salescommissionform.get("salescommissionid").value;
      //   l_CommissionDtail.branchid = this._salescommissionform.get("branchid").value;
      //   l_CommissionDtail.salestype = this._salescommissionform.get("salestype").value;
      //   this._SalesCommissionDetail.push(l_CommissionDtail);
      // }
      // this.SalesCommissionDetailsDisabled();
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

  //Send to approval
  SendToApproval(event) {
    var workflowid = 54;
    this._IsProgressSpinner = true;
    this._SalescommissionService.SendToApproval(workflowid, this._userid, this._branchid, this._salescommissionid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //console.log(resultJSON);
      this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
      this.isDisabledsendapparoval = true;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

}


