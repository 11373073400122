export class Materialreturnrequest {
    
materialreturnrequestid: number;
salesreturnid: number  ;
salesreturnno: string;
returnbranchid: number;
pickedby: number  ;
returnbycustomer: boolean  ;
receivedquantity: number;
eorderid: number;
eorderno: string;
ordercancelid: number;
createdby: number;
createdon: Date  ;
isDisabledPickedby:boolean;
isselfpickup:boolean;
isDisabledSelfPickup:boolean;
orderno:string;
orderid:number;
lotnumber:number;
requestno:string;
totalquantity:number;
returnquantity:number;

}