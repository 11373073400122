<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_cityform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>City</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                    [disabled]="_SaveHide"></button>
                                <!--13. clear all the fields which the data has entered -->
                                <button pButton pRipple title="Clear" type="button" icon="pi pi-trash"
                                    class="p-button-danger" (click)="reset($event)" [disabled]="_ClearHide"></button>
                                <!--33. on clicking back button , it Navigates to the search screen -->
                                <button pButton pRiple icon="pi pi-search" title="Back to Search"
                                    class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                                <!-- <button pButton pRipple label="" icon="pi pi-times" class="" (click)="hideDialog()" ></button>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>

                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">

                                    <p-dropdown [autoDisplayFirst]="false" [options]="_countries"
                                        optionLabel="countryname" optionValue="countryid" formControlName="countryid"
                                        [disabled]="this._action == 'edit'" (onChange)="getStates($event)">
                                    </p-dropdown>
                                    <label for="countryid">Country <span class="hlt-txt">*</span></label>
                                </span>
                                <!--4. if Country Name is not select,show error as "Please select Country Name" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _cityform.get('countryid').touched) && _cityform.get('countryid').errors?.SelectCountryName">
                                    Please select country name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_states" optionLabel="statename"
                                        [disabled]="this._action == 'edit'" optionValue="stateid"
                                        formControlName="stateid">
                                    </p-dropdown>
                                    <label for="stateid">Province <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 5.if "State name" is not select, show error as "Please select State name" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _cityform.get('stateid').touched) && _cityform.get('stateid').errors?.SelectStateName">
                                    Please select Province name
                                </span>

                            </td>
                            <td style="width: 20%;">

                            </td>
                            <td style="width: 20%;">

                            </td>
                            <td style="width: 20%;">

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" name="districtcode" maxlength="10" formControlName="districtcode"
                                        pInputText>
                                    <label for="districtcode">City Code <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 6.if "District code" is not enter, show error as "Please Enter District code" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _cityform.get('districtcode').touched) && _cityform.get('districtcode').errors?.DistrictcodeRequired">
                                    Please enter city code
                                </span>
                            </td>
                            <td>

                                <span class="p-float-label">
                                    <input type="text" name="districtname" maxlength="50" formControlName="districtname"
                                        pInputText>
                                    <label for="districtname">City Name <span class="hlt-txt">*</span></label>
                                </span>
                                <!--7. if "District name" is not enter, show error as "Please Enter District name" -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _cityform.get('districtname').touched) && _cityform.get('districtname').errors?.DistrictNameRequired">
                                    Please enter city name
                                </span>
                            </td>
                            <td> </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>

                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_citystatus" name="districtstatus"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="districtstatus"></p-dropdown>
                                    <label for="districtstatus">Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_cityform.get('districtstatus').touched || _submitted) && _cityform.get('districtstatus').errors?.SelectStatus">
                                    Please select city status
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <!-- Debug -->
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </tr>

                    </table>

                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>