<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <!-- <div class="container-fluid"> -->
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Customer Rebate</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRiple type="button" icon="pi pi-plus" title="Add"
                                    (click)="GoToCreate($event)" [disabled]="!_Action._Add"
                                    class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                            <!-- <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger "></button> -->
                        </ng-template>

                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="exportPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_customerslabwisediscountviews" [rows]="50" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [globalFilterFields]="['slabwisecode','customername','effectivefrom','effectiveto']"
                        [rowHover]="true" dataKey="employeecode" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width:100px;">Action
                                </th>
                                <th pSortableColumn="slabwisecode">Customer Slab Code <p-sortIcon field="slabwisecode">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="customername">Customer Name <p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="effectivefrom">Effective From<p-sortIcon field="effectivefrom">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="effectiveto">Effective To<p-sortIcon field="effectiveto">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="recordstatusname">Status<p-sortIcon field="recordstatusname">
                                    </p-sortIcon>
                                </th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_customerslabwisediscountview>
                            <tr>
                                <td style="text-align: center;">
                                    <!-- 36.on clicking view button, it show the view screen. -->
                                    <button pButton pRipple icon="las la-eye" title="View" [disabled]="!_Action._View"
                                        class="p-button p-button p-mr-2"
                                        (click)="view(_customerslabwisediscountview)"></button>
                                    <button pButton pRipple title="Send To Approval" icon="las la-check"
                                        class="p-button p-button p-mr-2" type="button"
                                        [disabled]="_customerslabwisediscountview.recordstatus != 'MSC00045' || !_Action._Approve " (click)="SendToApproval(_customerslabwisediscountview)"></button>
                                    <!-- 39.on clicking update button, it show the update screen. -->
                                    <!-- <button pButton pRipple icon="las la-pen" title="Edit"
                                        class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update"
                                        (click)="edit(_customerslabwisediscountview)"></button> -->
                                    <button pButton pRipple icon="pi pi-trash" title="Delete"
                                        [disabled]="!_Action._Delete" class="p-button p-button-danger"
                                        (click)="delete(_customerslabwisediscountview)"
                                        ></button>
                                </td>
                                <td>
                                    {{_customerslabwisediscountview.slabwisecode}}
                                </td>
                                <td>
                                    {{_customerslabwisediscountview.customername}}
                                </td>
                                <td>
                                    {{_customerslabwisediscountview.effectivefrom | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_customerslabwisediscountview.effectiveto | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_customerslabwisediscountview.recordstatusname}}
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>