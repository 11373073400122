import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Currency } from 'src/app/core/Models/Currency';
import { Customer } from 'src/app/core/Models/Customer';
import { LaybuyAmountDetail } from 'src/app/core/Models/LaybuyAmountDetail';
import { LaybuyDeliveryterm } from 'src/app/core/Models/LaybuyDeliveryterm';
import { Laybuydocumentdetail } from 'src/app/core/Models/Laybuydocumentdetail';
import { LaybuyExchangeratedetail } from 'src/app/core/Models/LaybuyExchangeratedetail';
import { LaybuyHeader } from 'src/app/core/Models/LaybuyHeader';
import { LaybuyOtherterm } from 'src/app/core/Models/LaybuyOtherterm';
import { LaybuyPaymentterm } from 'src/app/core/Models/LaybuyPaymentterm';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Termsandcondition } from 'src/app/core/Models/Termsandcondition';
import { LaybuyService } from 'src/app/core/services/accounts/laybuy.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { usererrors } from 'src/app/core/errors/usererrors';
import { vCurrencyCode, vCustomerName, vDateSelect, vMobileNumber, vProductCategorySelect, vProductNameSelect, vProductTypeSelect, vQuantity, vSelectCurrency, vToAccountSelect } from 'src/app/core/Validators/validation';
import { LaybuyProductDetailsView } from 'src/app/core/Views/LaybuyProductDetailview';
import { element } from 'protractor';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Laybuyproductpriceview } from 'src/app/core/Views/Laybuyproductpriceview';
import { Customerview } from 'src/app/core/Views/Customerview';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUpload } from 'primeng/fileupload';
import { create } from 'domain';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { productsType } from 'src/app/core/Models/overallclass';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';


@Component({
  selector: 'app-c-laybuy',
  templateUrl: './c-laybuy.component.html',
  styleUrls: ['./c-laybuy.component.css']
})
export class CLaybuyComponent implements OnInit {
  _isSaveHide: boolean = false;
  _isClearHide: boolean = false;
  balance: any = 0;
  tempbalance: any = 0;
  // Forms
  _LaybuyHeaderform: FormGroup;
  _LaybuyAmountDetailform: FormGroup;
  _LaybuyDocumentDetailform: FormGroup;
  _LaybuyProductDetailform: FormGroup;
  _LaybuyOtherTermform: FormGroup;
  _LaybuyDeliveryTermform: FormGroup;
  _LaybuyPaymentTermform: FormGroup;
  _LaybuyExchangerateDetailform: FormGroup;
  _LaybuyProductPriceform: FormGroup;
  productpricedetail: Laybuyproductpriceview;
  _toaccounts: Accountledgerview[] = [];
  _toaccountsTemp: Accountledgerview[] = [];
  _Modes: Metadatum;
  _LaybuyExchangerateDetail: LaybuyExchangeratedetail;
  _LaybuyHeader: LaybuyHeader;
  _LaybuyOBJ: any;
  _LaybuyForm: FormData;
  _Laybuyid: any;
  _Laybuycode: any;
  _Laybuycustomer: number;
  documentimages: SafeUrl;
  _productsearch: any;
  filteredProducts: any[] = [];
  minDate: Date;
  maxDate: Date;
  _HeaderList: any;
  updatelaybuy: boolean = false;
  // For Grid
  _LaybuyAmountDetails: LaybuyAmountDetail[] = [];
  _LaybuyAmountDetail: LaybuyAmountDetail;
  selectedAmountDetail: any;
  _LaybuyProductDetails: LaybuyProductDetailsView[] = [];
  _LaybuyProductDetail: LaybuyProductDetailsView;
  selectedProductDetail: any;
  laybuyDocumentDetails: Laybuydocumentdetail[] = [];

  // For DropDown
  _Customers: Customerview[];
  _tempCustomers: Customerview[];
  _Currencies: Currency[];
  _CurrenciesTemp: Currency[];
  _status: Metadatum;
  _ProductTypes: productsType[];
  _ProductCategories: Productcategory[];
  _Products: Productheader[];
  _Variants: Productvariant[];
  _ProductName: Productheader[];

  // For Multiselect
  _PaymentTerms: Termsandcondition;
  _prevPaymentSelectedTerms: Termsandcondition[] = [];
  _selectedPaymentTermsIDs: number[] = [];
  _DeliveryTerms: Termsandcondition;
  _OtherTerms: Termsandcondition;
  _selectedPayment: number[] = [];
  _selectedDelivery: number[] = [];
  _selectedOther: number[] = [];
  _LaybuyPaymentTerm: LaybuyPaymentterm[];
  _LaybuyDeliveryTerm: LaybuyDeliveryterm[];
  _LaybuyOtherTerm: LaybuyOtherterm[];
  _selectedPaymentIndex: number[] = [];
  _selectedDeliveryIndex: number[] = [];
  _selectedOtherIndex: number[] = [];
  selectedProductType: any;
  selectedCustomer: any;
  _IsProgressSpinner: boolean = true;
  _action: string = "create";
  _submitted = false;
  _productsubmitted = false;

  // For File Upload:
  _LastAddedDocument: File;
  _selectedDocuments: File[] = [];
  position1: string;
  position: string;
  displaydocumentpopup: boolean;
  displayBasic: boolean;
  displayBasic1: boolean;
  documentimage: File;
  displaydocumentgridpopup: boolean = false;
  _Proofs: Laybuydocumentdetail[] = [];
  _Proof: Laybuydocumentdetail;
  selectedtab: number = 0;
  selectedrows: any[] = [];
  imageselect: string = "";
  startDate: any;
  isdisable: any;
  tempfirstpaymentdate: Date;
  tempsecondpaymentdate: Date;
  tempthirdpaymentdate: Date;
  isdisableaddbutton: any = 0;
  _Action: IAction;
  hidequantity: boolean = true
  mindate: Date;
  maxdate: Date;


  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _LaybuyService: LaybuyService,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,
    private _router: Router,
    private messageService: MessageService,
    private _hotkeysService: HotkeysService, private _DomSanitizationService: DomSanitizer,
    private _AccessRightsService: AccessRightsService
  ) {
    //Save Short cut keys
  }

  InitializeForm() {
    this._LaybuyHeaderform = this.fb.group({
      laybuyid: [0],
      laybuycode: [0],
      customerid: ['', vCustomerName],
      startdate: ['', vDateSelect],
      enddate: [null],
      productprice: [0],
      currencyid: [0, vCurrencyCode],
      currentpayment: [0, vCustomerName],
      approvalstatus: [],
      approvalremarks: [],
      approvedby: [],
      approvedtime: [],
      createdby: [1],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      laybuystatusid: [47],
      laybuystatusdesc: ['Approved'],
      branchid: [sessionStorage["BranchID"]],
      mobileno: ['', vMobileNumber],
      voucherno: [],
      laybuyAmountDetails: [],
      laybuyDeliveryterms: [],
      laybuyOtherterms: [],
      laybuyPaymentterms: [],
      laybuyProductDetails: [],
      laybuydocumentdetails: [],
      mode: ['', vCustomerName],
      transactionreferenceno: [],
      toaccount: ['', vToAccountSelect],
      remarks1: [null],
      remarks2: [null],
      firstpaymentdate: [],
      secondpaymentdate: [],
      thirdpaymentdate: [],
      firstpaymentamount: [],
      secondpaymentamount: [],
      thirdpaymentamount: []
    });
    if (sessionStorage['Environmentenddate'] == "null") {
      this._LaybuyHeaderform.get("startdate").setValue(new Date);
    }
    else {
      let date = new Date(sessionStorage['Environmentenddate'])
      this._LaybuyHeaderform.get("startdate").setValue(date);
    }
    this._LaybuyDocumentDetailform = this.fb.group({
      documentdetailid: [0],
      laybuyid: [0],
      documentid: [0],
      documentname: [],
      documentdescription: [],
      attachmenturl: [],
      attachmentname: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
    this._LaybuyAmountDetailform = this.fb.group({
      laybuyamountid: [0],
      laybuyid: [0],
      laybuycode: [],
      paymentdate: [],
      paidamount: [],
      balance: []
    });
    this._LaybuyOtherTermform = this.fb.group({
      laybuyOthertermsid: [0],
      laybuyid: [0],
      othertermsid: []
    });
    this._LaybuyDeliveryTermform = this.fb.group({
      laybuyDeliverytermsid: [0],
      laybuyid: [0],
      deliverytermsid: []
    });
    this._LaybuyPaymentTermform = this.fb.group({
      laybuyPaymenttermsid: [0],
      laybuyid: [0],
      paymenttermsid: []
    });
    this._LaybuyExchangerateDetailform = this.fb.group({
      laybuyexchangeratedetailsid: [0],
      laybuyid: [0],
      currencyid: ['', vSelectCurrency],
      currencyname: [],
      exchangerate: [],
      amount: [],
      currencyuniquereferenceno: [],
      iscurrenttransaction: [],
      paidamount: [],
      currentpayment: []
    });
    this.InitializeProductForm();
    this._LaybuyProductPriceform = this.fb.group({
      userid: [],
      roleid: [],
      levelid: [],
      productid: [],
      productname: [],
      usd: [],
      rand: [],
      zim1: [],
      zim2: []
    });
  }

  InitializeProductForm() {
    this._LaybuyProductDetailform = this.fb.group({
      laybuydetailsid: [0],
      laybuyid: [0],
      producttypeid: [0],
      productcategoryid: [0],
      productid: [0, vProductNameSelect],
      variant: [0],
      quantity: [, vQuantity],
      price: [0.0],
      changedprice: [0.0],
      incresedby: [0.0],
      totalamount: [0.0]
    });
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(74);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();
    // this._action = history.state.action == null ? 'create' : history.state.action;
    this._Laybuyid = history.state?.laybuyid ? history.state?.laybuyid : 0;
    this._Laybuycode = history.state?.laybuycode ? history.state?.laybuycode : 0;
    this._Laybuycustomer = history.state?.customerid ? history.state?.customerid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
    this._isSaveHide = (this._action == 'view');
    this._isClearHide = (this._action == 'view' || this._action == 'edit');
    let userid = sessionStorage["userid"]
    this._IsProgressSpinner = true;
    this.OnChangeMode(null);
    this._LaybuyService.PageOnLoad(userid, this._Laybuyid, sessionStorage["currentbranchid"]).subscribe((result) => {
      this.startDate = new Date();
      const resultJSON = JSON.parse(JSON.stringify(result));
      // For DropDown
      this._CurrenciesTemp = resultJSON.currencyList || [];
      this._Currencies = resultJSON.currencyList;
      this._status = resultJSON.statusList;
      this._Customers = resultJSON.customerList;
      this._tempCustomers = resultJSON.customerList;
      this._ProductTypes = resultJSON.productTypeList;
      //For Multiselect
      this._PaymentTerms = resultJSON.paymentTermsList;
      this._DeliveryTerms = resultJSON.deliveryTermsList;
      this._OtherTerms = resultJSON.otherTermsList;
      this._Modes = resultJSON.lstmode || [];
      this._toaccountsTemp = resultJSON.toaccounts || [];
      this._LaybuyHeaderform.controls['currencyid'].setValue(parseInt('1'));
      let event = { value: 1 };
      this.ChangeCurrencies(event);
      this.OnFromDateSelect(this._LaybuyHeaderform.get("startdate").value);
      // For Grid
      this._IsProgressSpinner = false;
      if (this._action == 'view') {
        this._LaybuyHeaderform.disable();
        this._LaybuyHeaderform.get("mode").disable();
        this._LaybuyProductDetailform.disable();
        this._LaybuyPaymentTermform.disable();
        this._LaybuyDeliveryTermform.disable();
        this._LaybuyOtherTermform.disable();
        this._LaybuyDocumentDetailform.disable();
        this.OnCustomerChange({ value: this._Laybuycustomer });
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ChangeCurrencies(event): void {
    var exchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].exchangerate;
    this._LaybuyExchangerateDetailform.controls['exchangerate'].setValue(exchangerate);
  }

  OnProductTypeChange(event) {
    this.selectedProductType = event.value;
    var branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;
    this._LaybuyService.OnProductTypeChange(this.selectedProductType, branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ProductCategories = resultJSON.productCategoriesList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  OnCustomerChange(event) {
    this.selectedCustomer = event.value;
    this.reset(null);
    this._LaybuyHeaderform.get("customerid").setValue(this.selectedCustomer);
    this._IsProgressSpinner = true;
    let customer = this._Customers.find(f => f.customerid == this.selectedCustomer);
    if (customer) {
      this._LaybuyHeaderform.get("mobileno").setValue(customer.mobileno);
    }
    this._LaybuyService.OnCustomerNameChange(this.selectedCustomer, this._Laybuyid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;

      if (resultJSON.laybuyHeader != null && resultJSON.laybuyHeader != undefined) {
        this.isdisable = 1;
      }
      else {
        this.isdisable = 0;

        this.tempfirstpaymentdate = new Date(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
        this.tempsecondpaymentdate = new Date(moment(this.tempfirstpaymentdate).add(30, 'days').format('YYYY-MM-DD HH:mm:ss'));
        this.tempthirdpaymentdate = new Date(moment(this.tempsecondpaymentdate).add(30, 'days').format('YYYY-MM-DD HH:mm:ss'));

        this._LaybuyHeaderform.controls["firstpaymentdate"].setValue(this.tempfirstpaymentdate);
        this._LaybuyHeaderform.controls["secondpaymentdate"].setValue(this.tempsecondpaymentdate);
        this._LaybuyHeaderform.controls["thirdpaymentdate"].setValue(this.tempthirdpaymentdate);
        this._LaybuyHeaderform.controls["enddate"].setValue(this.tempthirdpaymentdate);
        this._LaybuyHeaderform.get("enddate").disable();
        90
      }


      if (!resultJSON.laybuyHeader) { return; }
      this._toaccounts = this._toaccountsTemp;
      resultJSON.laybuyHeader.startdate = new Date(resultJSON.laybuyHeader.startdate);
      if (resultJSON.laybuyHeader.enddate)
        resultJSON.laybuyHeader.enddate = new Date(resultJSON.laybuyHeader.enddate);
      resultJSON.laybuyHeader.firstpaymentdate = new Date(resultJSON.laybuyHeader.firstpaymentdate);
      resultJSON.laybuyHeader.secondpaymentdate = new Date(resultJSON.laybuyHeader.secondpaymentdate);
      resultJSON.laybuyHeader.thirdpaymentdate = new Date(resultJSON.laybuyHeader.thirdpaymentdate);

      resultJSON.laybuyHeader.currentpayment = 0;
      this._HeaderList = resultJSON.laybuyHeader;
      this._LaybuyHeaderform.setValue(resultJSON.laybuyHeader);
      this._LaybuyProductDetails = resultJSON.productDetailsList;
      this.updatelaybuy = false;
      if (this._LaybuyProductDetails.length > 0) {
        this.updatelaybuy = true;
      }
      this._LaybuyAmountDetails = resultJSON.laybuyHeader.laybuyAmountDetails;
      this._Proofs = resultJSON.laybuyHeader.laybuydocumentdetails || [];
      let paidamount = 0;
      this._LaybuyAmountDetails.forEach(item => {
        if (item.amountstatusid + '' != 150 + '')
          paidamount = (item.paidamount + paidamount);
      });
      let bal = resultJSON.laybuyHeader.productprice - paidamount;
      this.balance = bal.toFixed(2);
      this._LaybuyExchangerateDetailform.get('paidamount').setValue(paidamount);
      this.SetValueForterms(resultJSON);
      let event = { value: this._LaybuyHeaderform.get("currencyid").value };
      this.ChangeCurrencies(event);
      this.OnChangeMode(null);
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  SetValueForterms(resultJSON: any) {
    var laybuyPaymentterms: number[] = [];
    if (resultJSON.laybuyHeader.laybuyPaymentterms && resultJSON.laybuyHeader.laybuyPaymentterms.length) {
      Object.keys(resultJSON.laybuyHeader.laybuyPaymentterms).map((Index) => {
        laybuyPaymentterms.push(resultJSON.laybuyHeader.laybuyPaymentterms[Index].paymenttermsid);
      });
      this._LaybuyPaymentTermform.get("paymenttermsid").setValue(laybuyPaymentterms);
    }

    var laybuyOtherterms: number[] = [];
    if (resultJSON.laybuyHeader.laybuyOtherterms && resultJSON.laybuyHeader.laybuyOtherterms.length) {
      Object.keys(resultJSON.laybuyHeader.laybuyOtherterms).map((Index) => {
        laybuyOtherterms.push(resultJSON.laybuyHeader.laybuyOtherterms[Index].othertermsid);
      });
      this._LaybuyOtherTermform.get("othertermsid").setValue(laybuyOtherterms);
    }

    var laybuyDeliveryterms: number[] = [];
    if (resultJSON.laybuyHeader.laybuyDeliveryterms && resultJSON.laybuyHeader.laybuyDeliveryterms.length) {
      Object.keys(resultJSON.laybuyHeader.laybuyDeliveryterms).map((Index) => {
        laybuyDeliveryterms.push(resultJSON.laybuyHeader.laybuyDeliveryterms[Index].deliverytermsid);
      });
      this._LaybuyDeliveryTermform.get("deliverytermsid").setValue(laybuyDeliveryterms);
    }
  }


  OnProductCategoryChange(event) {
    var selectedProductCategory = event.value;
    this._IsProgressSpinner = true;
    this._LaybuyService.OnProductCategoryChange(this.selectedProductType, selectedProductCategory).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Products = resultJSON.productoverallview;
      this._productsearch = resultJSON.productoverallview;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  OnProductNameChange(event) {
    var selectedProductName = event.productid;
    var userid = sessionStorage["userid"];
    var levelid = sessionStorage["levelid"];
    let customerid = parseInt(this._LaybuyHeaderform.get("customerid").value || 0)
    let customer = this._Customers.find(f => f.customerid == customerid);
    if (customer) {
      if (customer.levelid > 0)
        levelid = customer.levelid;
    }
    this._IsProgressSpinner = true;
    this.productpricedetail = null;
    this._LaybuyService.OnProductNameChange(selectedProductName, userid, levelid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Variants = resultJSON.productVariantList;
      this.productpricedetail = <Laybuyproductpriceview>resultJSON.productPrice;
      this._productsearch = resultJSON.productPrice;
      let uom = this.filteredProducts.filter(f => f.productid = selectedProductName)[0].salesuom

      this._LaybuyProductDetailform.get("quantity").reset();
      if (uom == "MSC00037") {
        this.hidequantity = true;
      }
      else {
        this.hidequantity = false
      }

      if (this.productpricedetail == null || this.productpricedetail == undefined) {
        this._CustomExceptionService.handleError("Product Price Details Not Configured for Lay Buy Level");
        this.isdisableaddbutton = 1;
        this._LaybuyProductDetailform.controls['producttypeid'].setValue("");
        this._LaybuyProductDetailform.controls['productcategoryid'].setValue("");
        this._IsProgressSpinner = false;
        return

      }
      else {
        this.isdisableaddbutton = 0;
        let product = this.filteredProducts.filter(f => f.productid == selectedProductName)[0];
        this._LaybuyProductDetailform.controls['producttypeid'].setValue(product.producttypeid);
        this._LaybuyProductDetailform.controls['productcategoryid'].setValue(product.productcategoryid);
      }

      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onAddProductDetails(event) {
    this._productsubmitted = true;
    let errMsg = "";
    if (this._LaybuyProductDetailform.valid) {
      var Productdetail = new LaybuyProductDetailsView();
      Productdetail.laybuydetailsid = this._LaybuyProductDetailform.get("laybuydetailsid").value ?? 0;
      Productdetail.laybuyid = this._LaybuyHeaderform.get("laybuyid").value;
      Productdetail.producttypeid = this._LaybuyProductDetailform.get("producttypeid").value;
      Productdetail.productcategoryid = this._LaybuyProductDetailform.get("productcategoryid").value;
      Productdetail.productid = this._LaybuyProductDetailform.get("productid").value.productid;
      console.log("productsearch", this._productsearch)
      // let product = this._productsearch;
      let productname = this._productsearch.productname;
      Productdetail.productname = productname;
      Productdetail.variant = this._LaybuyProductDetailform.get("variant").value;
      let variantList = this._Variants.filter(f => f.productvariantdetailid == Productdetail.variant);
      if (variantList.length) {
        let variant = variantList[0];
        Productdetail.variantdescription = variant.variantdescription;
      }

      let currencyId = this._LaybuyHeaderform.get("currencyid").value;
      let price = 0;
      if (currencyId) {
        let currencyObj = this._Currencies.filter(f => f.currencyid == currencyId)[0];
        price = parseFloat((currencyObj.exchangerate).toFixed(2) || '0');
      } else {
        this._CustomExceptionService.handleError("Please select Currency");
        return
      }

      var customerid = this._LaybuyHeaderform.get("customerid").value;

      if (!customerid) {
        this._CustomExceptionService.handleError("Please Select Customer");
        return
      }

      var vatType = this._tempCustomers.filter(f => f.customerid == customerid)[0].vattype;

      if (vatType == null || vatType == undefined) {
        vatType = "MSC00062";
      }

      var qty = this._LaybuyProductDetailform.get("quantity").value;
      Productdetail.variant = this._LaybuyProductDetailform.get("variant").value;
      Productdetail.quantity = this._LaybuyProductDetailform.get("quantity").value;

      let sum: number = 0;
      let withouttax: number = 0;

      if (vatType != "MSC00064") {
        Productdetail.taxid = parseFloat(this.productpricedetail.taxid.toFixed(2) || '0');
        Productdetail.taxpercentage = this.productpricedetail.taxpercentage;
      }
      else {
        Productdetail.taxid = 0;
        Productdetail.taxpercentage = 0;
      }

      if (vatType == "MSC00062") {
        Productdetail.price = parseFloat((price * this.productpricedetail.usdVatin).toFixed(2) || '0');
        withouttax = (price * this.productpricedetail.usd);
        sum = parseFloat((qty * withouttax).toFixed(2) || '0');
        Productdetail.taxamount = parseFloat((sum * (Productdetail.taxpercentage / 100)).toFixed(2) || '0');
        Productdetail.totalamount = parseFloat((Productdetail.price * qty).toFixed(2) || '0');

      }
      else {
        Productdetail.price = parseFloat((price * this.productpricedetail.usd).toFixed(2) || '0');
        sum = parseFloat((qty * Productdetail.price).toFixed(2) || '0');
        Productdetail.taxamount = parseFloat((sum * (Productdetail.taxpercentage / 100)).toFixed(2) || '0');
        Productdetail.totalamount = parseFloat((sum + Productdetail.taxamount).toFixed(2) || '0');
      }



      let isAlreadyExists = this._LaybuyProductDetails.some(f => f.productid == Productdetail.productid && f.variant == Productdetail.variant);

      if (isAlreadyExists) {
        errMsg = "Product Name is Already Exists";
      }
      if (this.ShowErrorMsg(errMsg)) {
        return;
      }

      this._LaybuyProductDetails.push(Productdetail);
      this.CalculateProductPrice();
      this._LaybuyProductDetailform.reset();
      this._productsubmitted = false;
      this.InitializeProductForm();
    }
  }

  RemoveJournal(pProductDetail: LaybuyProductDetailsView) {
    var index = this._LaybuyProductDetails.indexOf(pProductDetail);
    this._LaybuyProductDetails.splice(index, 1);
    this.CalculateProductPrice();
  }
  onRowSelect(event) {
    console.log("Selected Amount");
    console.log(this.selectedAmountDetail);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedAmountDetail);
  }
  onRowSelect1(event) {
    console.log("Selected Product");
    console.log(this.selectedProductDetail);
  }
  onRowUnselect1(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedProductDetail);
  }
  reset(event) {
    this._LaybuyHeaderform.reset();
    this._LaybuyAmountDetailform.reset();
    this._LaybuyProductDetailform.reset();
    this._LaybuyOtherTermform.reset();
    this._LaybuyDeliveryTermform.reset();
    this._LaybuyPaymentTermform.reset();
    this._LaybuyExchangerateDetailform.reset();
    this.InitializeForm();
    this._LaybuyAmountDetails = [];
    this._LaybuyProductDetails = [];
    this.balance = 0;
    this._LaybuyHeaderform.controls['currencyid'].setValue(parseInt('1'));
    let event1 = { value: 1 };
    this.ChangeCurrencies(event1);
  }
  Clear() {
    this._LaybuyProductDetailform.reset();
  }
  onEd() {
    alert("test");
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._LaybuyOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  onSave() {
    let errmsg = '';
    this._submitted = true;
    this.fillPaymentTerm();
    this.fillDeliveryTerm();
    this.fillOtherTerm();
    if (this._LaybuyHeaderform.valid) {
      var Mode = this._LaybuyHeaderform.get("mode").value;
      if (Mode != "MSC00146") // Cash
      {
        if (!this._LaybuyHeaderform.get("toaccount").value) {
          this._CustomExceptionService.handleWarning("Please select bank account");
          return;
        }
      }
      var fromDate = this._LaybuyHeaderform.get("startdate").value;
      var datePipe = new DatePipe("en-US");
      var pDate = datePipe.transform(fromDate, 'yyyy-MM-dd');
      this._LaybuyHeaderform.get("startdate").setValue(new Date(pDate));
      if (this._LaybuyHeaderform.get("enddate").value != null && this._LaybuyHeaderform.get("enddate").value != '' && this._LaybuyHeaderform.get("enddate").value != undefined) {
        var enddatre = this._LaybuyHeaderform.get("enddate").value;
        var datePipe = new DatePipe("en-US");
        var P_enddatre = datePipe.transform(enddatre, 'yyyy-MM-dd');
        this._LaybuyHeaderform.get("enddate").setValue(new Date(P_enddatre));
      }

      this._LaybuyExchangerateDetailform.get("laybuyid").setValue(this._LaybuyHeaderform.get("laybuyid").value);
      this._LaybuyAmountDetailform.get("laybuyid").setValue(this._LaybuyHeaderform.get("laybuyid").value);
      this._LaybuyOtherTermform.get("laybuyid").setValue(this._LaybuyHeaderform.get("laybuyid").value);
      this._LaybuyDeliveryTermform.get("laybuyid").setValue(this._LaybuyHeaderform.get("laybuyid").value);
      this._LaybuyProductDetailform.get("laybuyid").setValue(this._LaybuyHeaderform.get("laybuyid").value);
      this._LaybuyPaymentTermform.get("laybuyid").setValue(this._LaybuyHeaderform.get("laybuyid").value);
      this._LaybuyHeaderform.get("laybuystatusid").setValue(47);
      this._LaybuyHeaderform.get("modifiedby").setValue(sessionStorage["userid"]);
      this._LaybuyHeaderform.get("modifiedon").setValue(new Date());
      if (this._action == 'create') {
        this._LaybuyHeaderform.get("createdby").setValue(sessionStorage["userid"]);
        this._LaybuyHeaderform.get("createdon").setValue(new Date());
      }
      this._LaybuyHeader = this._LaybuyHeaderform.getRawValue();
      this._LaybuyExchangerateDetail = this._LaybuyExchangerateDetailform.getRawValue();
      const resultJSONPayment = JSON.parse(JSON.stringify(this._LaybuyPaymentTerm));
      const resultJSONDelivery = JSON.parse(JSON.stringify(this._LaybuyDeliveryTerm));
      const resultJSONOther = JSON.parse(JSON.stringify(this._LaybuyOtherTerm));
      this._LaybuyForm = this.formconverstion(this._LaybuyHeader, resultJSONDelivery, resultJSONOther,
        resultJSONPayment, this._LaybuyProductDetails, this._Proofs, this._selectedDocuments);
      let alreadyPaidAmount = 0;
      if (this._LaybuyAmountDetails && this._LaybuyAmountDetails.length) {
        this._LaybuyAmountDetails.forEach(element => {
          if (!element.amountstatusid + '' == 150 + '')
            alreadyPaidAmount += element.paidamount || 0;
        })
      }

      // let totalAmount = this._LaybuyHeader.productprice;
      // let paymentAmount = parseFloat((this._LaybuyHeader.currentpayment + alreadyPaidAmount).toFixed(2) || '0');;

      // if (paymentAmount > totalAmount) {
      //   errmsg = "Payment Amount must not exceed Product Price.";
      // }

      var laybuycode = this._LaybuyHeaderform.get("laybuycode").value;

      if (laybuycode == "0") {

        let currentpayment: number = (this._LaybuyHeaderform.get("currentpayment").value) || 0;
        let customeractualpayment: number = ((30 / 100) * this.tempbalance) || 0;

        if (this.balance > 0) {
          if (customeractualpayment > currentpayment) {
            errmsg = "Current Payment Should be Minimum 30% of Balance Amount";
          }
        }
      }


      if (this.ShowErrorMsg(errmsg)) {
        return;
      }

      if (this._action == 'create') {
        this._LaybuyHeader.createdon = new Date();
        this._LaybuyHeader.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._LaybuyService.create(this._LaybuyForm).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
            this._submitted = false;
            this.isdisable = 0;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);

          } this._IsProgressSpinner = false;
        }, error => {
          this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false;
        });
        //this.onEd();
      }
    }
  }
  OnChangeofPayment(event) {
    this._selectedPayment = event.value;
  }
  fillPaymentTerm() {
    var selectedPayment = this._selectedPayment;
    var P_LaybuyPaymentterm: LaybuyPaymentterm[] = [];  //output objects
    var l_laybuyPaymentterm: LaybuyPaymentterm;
    var laybuyPaymenttermsId: any = this._LaybuyPaymentTermform.get("laybuyPaymenttermsid").value;
    var laybuyId: any = this._LaybuyHeaderform.get("laybuyid").value;
    Object.keys(selectedPayment).map(function (Index) {
      l_laybuyPaymentterm = new LaybuyPaymentterm();
      l_laybuyPaymentterm.laybuyPaymenttermsid = laybuyPaymenttermsId;
      l_laybuyPaymentterm.laybuyid = laybuyId;
      l_laybuyPaymentterm.paymenttermsid = selectedPayment[Index];
      P_LaybuyPaymentterm.push(l_laybuyPaymentterm);
    });

    this._LaybuyPaymentTerm = P_LaybuyPaymentterm;
  }
  OnChangeofDelivery(event) {
    this._selectedDelivery = event.value;
  }

  fillDeliveryTerm() {
    var selectedDelivery = this._selectedDelivery;
    var P_LaybuyDeliveryterm: LaybuyDeliveryterm[] = [];  //output objects
    var l_laybuyDeliveryterm: LaybuyDeliveryterm;
    var laybuyDeliverytermsId: any = this._LaybuyDeliveryTermform.get("laybuyDeliverytermsid").value;
    var laybuyId: any = this._LaybuyHeaderform.get("laybuyid").value;
    Object.keys(selectedDelivery).map(function (Index) {
      l_laybuyDeliveryterm = new LaybuyDeliveryterm();
      l_laybuyDeliveryterm.laybuyDeliverytermsid = laybuyDeliverytermsId;
      l_laybuyDeliveryterm.laybuyid = laybuyId;
      l_laybuyDeliveryterm.deliverytermsid = selectedDelivery[Index];
      P_LaybuyDeliveryterm.push(l_laybuyDeliveryterm);
    });
    this._LaybuyDeliveryTerm = P_LaybuyDeliveryterm;
  }
  OnChangeofOther(event) {
    this._selectedOther = event.value;
  }
  fillOtherTerm() {
    var selectedOther = this._selectedOther;
    var P_LaybuyOtherterm: LaybuyOtherterm[] = [];  //output objects
    var l_laybuyOtherterm: LaybuyOtherterm;
    var laybuyDeliverytermsId: any = this._LaybuyOtherTermform.get("laybuyOthertermsid").value;
    var laybuyId: any = this._LaybuyHeaderform.get("laybuyid").value;
    Object.keys(selectedOther).map(function (Index) {
      l_laybuyOtherterm = new LaybuyOtherterm();
      l_laybuyOtherterm.laybuyOthertermsid = laybuyDeliverytermsId;
      l_laybuyOtherterm.laybuyid = laybuyId;
      l_laybuyOtherterm.othertermsid = selectedOther[Index];
      P_LaybuyOtherterm.push(l_laybuyOtherterm);
    });
    this._LaybuyOtherTerm = P_LaybuyOtherterm;
  }
  goBack(event) {
    this._router.navigate(['/vLaybuy']);
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Laybuy <b>"' + event.referenceno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    var paymentid = event.laybuyamountid;
    var customerid = this._LaybuyHeaderform.get("customerid").value;
    this._IsProgressSpinner = true;
    this._LaybuyService.LayBuyAmountCancel(paymentid, customerid).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true) {
        this._LaybuyAmountDetails = resultJSON.laybuyAmountDetails;
        this.Calculation();
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  CalculateProductPrice() {
    let productPrice = 0;
    this._LaybuyProductDetails.forEach(element => {
      productPrice += element.totalamount;
    });
    this._LaybuyHeaderform.controls["productprice"].setValue(productPrice.toFixed(2));
    this.Calculation();

    var laybuycode = this._LaybuyHeaderform.get("laybuycode").value;

    if (laybuycode == "0") {
      let tempproductprice: number = this._LaybuyHeaderform.get("productprice").value;
      let productprice: number = this._LaybuyHeaderform.get("productprice").value;

      let firstpayment: number = ((30 / 100) * tempproductprice);
      let secondpayment: number = ((30 / 100) * tempproductprice);
      let thirdpayment: number = (productprice - (firstpayment + secondpayment));

      this._LaybuyHeaderform.controls["firstpaymentamount"].setValue(firstpayment.toFixed(2));
      this._LaybuyHeaderform.controls["secondpaymentamount"].setValue(secondpayment.toFixed(2));
      this._LaybuyHeaderform.controls["thirdpaymentamount"].setValue(thirdpayment.toFixed(2));
    }
  }


  ShowErrorMsg(errMsg) {
    if (!errMsg)
      return false;

    this.messageService.add({ severity: 'error', summary: 'Error Message', sticky: true, detail: errMsg });
    return true;
  }

  OnChangeMode(event) {
    var Mode = this._LaybuyHeaderform.get("mode").value;
    this._LaybuyHeaderform.controls["toaccount"].setValue("");
    let customerid = parseInt(this._LaybuyHeaderform.get("customerid").value || 0);
    let userid = parseInt(sessionStorage["userid"] || 0);
    let cashgroups = [30, 31];
    this._toaccounts = [];
    if (Mode == "MSC00146") {// Cash
      this._toaccounts = this._toaccountsTemp.filter(f => cashgroups.includes(f.accountgroupid));
      if (userid !== 1) {
        let customer = (this._Customers || []).find(f => f.customerid == customerid);
        let cus_series = 0;
        if (customer)
          cus_series = parseInt(customer.series.substr(6) || '0');
        this._toaccounts = this._toaccountsTemp.filter(f => cashgroups.includes(f.accountgroupid) && f.series == cus_series);
        var ledger = this._toaccounts.find(f => f.series == cus_series);
        if (ledger)
          this._LaybuyHeaderform.get("toaccount").setValue(ledger.ledgerid);
      }
    }
    else {
      this._LaybuyHeaderform.controls["toaccount"].reset();
      this._toaccounts = (this._toaccountsTemp || []).filter(f => !cashgroups.includes(f.accountgroupid));
    }
  }

  filterProducts(event) {
    let branchid = sessionStorage["currentbranchid"];
    let categoryids = this._LaybuyProductDetailform.get("productcategoryid").value || 0;
    let data = {
      product: event.query,
      branchid: branchid,
      category: []
    }
    this._LaybuyService.GetProduct(data).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productsearch = <Productoverallview[]>resultJSON.productoverallview || [];
      this.filteredProducts = this._productsearch;
      this._ProductCategories = result.productCategoriesList;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  //Date Selection validation:
  OnFromDateSelect(event) {
    this.minDate = event;
    this.maxDate = new Date();
    let month = event.getDate() + 90;
    this.maxDate.setDate(month);
  }

  //For document Upload:
  showdocumentDialog(document: FileUpload) {
    let url = null;
    if (this._action != 'view') {
      if (this._LastAddedDocument) {
        this.imageselect = this._LastAddedDocument.name;
        url = URL.createObjectURL(this._LastAddedDocument);
      }
      if (!url)
        return;
      window.open(url, "MsgWindow", "width=800,height=800");
    } else {
      let attachment = this._LaybuyDocumentDetailform.get("attachmentname").value;
      if (attachment) {
        url = attachment;
        window.open(url, "MsgWindow", "width=800,height=800");
      }
    }
  }

  onDocumentClear() {
    this.documentimage = null;
    this._LastAddedDocument = null;
    this._LaybuyDocumentDetailform.get("documentdescription").reset();
    this._LaybuyDocumentDetailform.get("attachmentname").reset();
  }
  onDocumentSelect(event, document) {
    this._LastAddedDocument = event.currentFiles[0];
    document.clear();
  }

  onAddProof(event) {
    if (this._LaybuyDocumentDetailform.valid && this._LastAddedDocument != null) {
      var proofdetail = new Laybuydocumentdetail();
      proofdetail.attachmentname = this._LastAddedDocument.name || '';
      proofdetail.laybuyid = this._LaybuyHeaderform.get("laybuyid").value || 0;
      this.documentimage = this._LastAddedDocument;
      proofdetail.attachmenturl = URL.createObjectURL(this._LastAddedDocument);
      proofdetail.documentdescription = this._LaybuyDocumentDetailform.get("documentdescription").value || '';
      proofdetail.documentdetailid = 0;
      proofdetail.documentid = 0
      proofdetail.createdby = sessionStorage["userid"] || 1;
      proofdetail.modifiedby = sessionStorage["userid"];
      proofdetail.modifiedon = new Date();
      proofdetail.createdon = new Date();
      proofdetail.documentname = this._LastAddedDocument.name || '';
      this._Proofs.push(proofdetail);
      this._selectedDocuments.push(this._LastAddedDocument);
      this.onDocumentClear();
    }
  }

  OpenDocument(event: HTMLAnchorElement, model: Laybuydocumentdetail) {

  }

  RemoveProofDetail(p_proofDetail) {
    var index = this._Proofs.indexOf(p_proofDetail);
    this._Proofs.splice(index, 1)
  }

  showdocumentGridDialog(position: string, _Proof: any) {
    let url = '';
    if (_Proof.attachmenturl) {
      this.documentimages = this._DomSanitizationService.bypassSecurityTrustUrl(_Proof.attachmenturl);
      url = _Proof.attachmenturl;
    }
    this.position = position;
    if (url)
      window.open(url, "_blank");
  }


  closePopupGridDialog(position: string) {
    this.position = position;
    this.displaydocumentgridpopup = false;
  }

  //Form Data Creation:
  formconverstion(p_laybuyheader: LaybuyHeader, p_LaybuyDeliveryterm: LaybuyDeliveryterm, P_LaybuyOtherterm: LaybuyOtherterm, P_LaybuyPaymentterm: LaybuyPaymentterm,
    P_LaybuyProductDetails: LaybuyProductDetailsView[], p_laybuydocumentdetail: Laybuydocumentdetail[], documents: File[]) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    for (var x in documents) {
      var index = p_laybuydocumentdetail.findIndex(obj => obj.attachmentname == documents[x].name);
      if (index != -1) {
        var NewFileName = time.getTime() + '_' + documents[x].name;
        p_laybuydocumentdetail[index].attachmentname = NewFileName;
        p_laybuydocumentdetail[index].attachmenturl = NewFileName;
        formData.append('files', documents[x], NewFileName);
      }
    }
    this._LaybuyOBJ = {
      "LaybuyHeader": p_laybuyheader,
      "laybuyDeliveryterms": p_LaybuyDeliveryterm,
      "laybuyOtherterms": P_LaybuyOtherterm,
      "laybuyPaymentterms": P_LaybuyPaymentterm,
      "laybuyProductDetails": P_LaybuyProductDetails,
      "laybuyDocumentDetails": p_laybuydocumentdetail,
      "currencyid": this._LaybuyExchangerateDetailform.get("currencyid").value,
      "ExchangerateinUSD": this._LaybuyExchangerateDetailform.get("exchangerate").value
    };
    var Container = JSON.stringify(this._LaybuyOBJ);
    formData.append('obj', Container);
    return (formData);
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

  // Paid and Remaining Amount Calculation:
  Calculation() {
    var productamt = this._LaybuyHeaderform.get("productprice").value;
    var paidamt = 0;
    var balanceamt = 0;
    let currentpayment: number = parseFloat(this._LaybuyHeaderform.get("currentpayment").value || 0);
    let paidAmount: number = parseFloat(this._LaybuyExchangerateDetailform.get("paidamount").value);
    if ((currentpayment + paidAmount) > productamt) {
      this._CustomExceptionService.handleError("Current Payment Should not be Greater than Product Price");
      this.balance = 0
      this._LaybuyHeaderform.get("currentpayment").setValue(0);
      return;
    }
    Object.keys(this._LaybuyAmountDetails).map((Index) => {
      if (this._LaybuyAmountDetails[Index].amountstatusid != 150) {
        balanceamt = productamt - this._LaybuyAmountDetails[Index].paidamount;
        paidamt += this._LaybuyAmountDetails[Index].paidamount;
      }
    });
    var balance = (productamt - paidamt - currentpayment).toFixed(2) || '0';
    this._LaybuyExchangerateDetailform.get("paidamount").setValue((paidamt).toFixed(2) || 0);
    this.balance = parseFloat(balance) || 0;
    this.tempbalance = productamt - paidamt || 0;
  }
}