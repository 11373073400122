import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class AdmanagementService {
  public _Action: IAction;
  public _AdManagementService = environment.AdManagementService;
  public _httpOptions;
  constructor(private http: HttpClient) { }
  PageOnload(Adid: number): Observable<any> {
    var Query = this._AdManagementService + '/' + Adid;
    // console.log(Query);
    return this.http.get<{ data: any }>(Query);
  }
  create(_AdFORM: FormData) {
    // console.log("Create");
    var Query = this._AdManagementService;

    // console.log(Query);
    return this.http.post<{ data: any }>(Query, _AdFORM);
  }
  edit(_AdFORM: FormData) {
    // console.log("Update");
    var Query = this._AdManagementService;
    // console.log(Query);
    return this.http.put<{ data: any }>(Query, _AdFORM);
  }
  FetchAll() {
    var Query = this._AdManagementService + '/FetchAll';
    // console.log(Query);
    return this.http.get<{ data: any }>(Query, this._httpOptions).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      // console.log(resultJSON); 
      return data;
    }));
  }
  Delete(Adid: number): Observable<any> {
    var Query = this._AdManagementService + '/Delete/' + Adid;
    return this.http.get<{ data: any }>(Query);
  }
  getProduct(productcategory: object) {
    var Query = this._AdManagementService + '/getProducts';
    return this.http.post<{ data: any }>(Query, productcategory);
  }
}
