import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Currency } from 'src/app/core/Models/Currency';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { vCurrencyCode, vCurrencyName, vEffectiveFrom, vExchangeRate } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { DatePipe } from '@angular/common'
//Debug
import * as ace from "ace-builds";
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CurrencyService } from 'src/app/core/Services/masters/currency.service';

import { Country } from 'src/app/core/Models/Country';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';


@Component({
  selector: 'app-c-currency',
  templateUrl: './c-currency.component.html',
  styleUrls: ['./c-currency.component.css']
})
export class CCurrencyComponent implements OnInit {
  _currencyform: FormGroup;
  _currencystatus: Metadatum[]=[];
  _submitted = false;
  _action: string = "create";
  _Currency: Currency;
  _currencyid: number;
  minDate: Date;
  isDisabledSave = false;
  isDisabledClear = false;
  _IsProgressSpinner: boolean = true;
  _Country: Country;
  _Action: IAction;
  ManageDialog: Boolean = false;
  _tempcurrencystatus: Metadatum[]=[];

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  http: any;
  constructor(private fb: FormBuilder,
    private datePipe: DatePipe,
    private _CurrencyService: CurrencyService, private _router: Router, private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,
    ) {
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._currencyform = this.fb.group({
      createdby: [],
      createdon: [],
      currencycode: ['', vCurrencyCode],
      currencyid: [0],
      currencyname: ['', vCurrencyName],
      currencystatus: [],
      effectivefrom: ['', vEffectiveFrom],
      effectiveto: [],
      exchangerate: ['', vExchangeRate],
      modifiedby: [],
      modifiedon: [],
      currencyuniquerefid: [0],
      countryid: [],
      currencysymbol: [],
      updatedversionid: [],
      currencyVersions: []
    })
    this._currencyform.controls['currencystatus'].setValue("MSC00001");
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(13);

    this.utility.pageLoadScript();
    this.InitializeForm();

    // this.minDate = new Date();

    this._currencyid = history.state?.currencyid ? history.state?.currencyid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;

    if (this._action == 'view') {
      this._currencyform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
    }

    if (this._action == 'edit') {
      this.isDisabledClear = true;
      this.isDisabledSave = false;
    }
    this._IsProgressSpinner = true;
    this._currencyform.controls['effectivefrom'].setValue(new Date());
    this._CurrencyService.PageOnLoad(this._currencyid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      // 23.update initialize should load all fields based on search page selected Currency ID.
      this._currencystatus = resultJSON.status;
      this._tempcurrencystatus = resultJSON.status;
      this._Country = resultJSON.countries;
      this._IsProgressSpinner = false;
      this._currencyform.controls['currencystatus'].setValue("MSC00001");

      //For Update
      this._currencyid = history.state.currencyid;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));

        //updateJSON.currency
        //this.datePipe.transform(this._customerform.get("date").value, 'yyyy-MM-dd')
        //var dat = this.datePipe.transform(updateJSON.currency.get('effectivefrom').value);

        if (updateJSON.currency.effectiveto == null) {

        }
        else {
          updateJSON.currency.effectiveto = new Date(updateJSON.currency.effectiveto);
        }
        updateJSON.currency.effectivefrom = new Date(updateJSON.currency.effectivefrom);

        this._currencyform.setValue(updateJSON.currency);
        this.filterCurrencyStatus(this._currencyid)
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSave() {
    this._submitted = true;
    if (this._currencyform.valid) {

      var Effectivefrom = this._currencyform.get("effectivefrom").value;
      var Effectiveto = this._currencyform.get("effectiveto").value;
      if (Effectiveto == null) {
        this._currencyform.get("effectiveto").setValue(null);
      }
      else {
        var datePipe = new DatePipe("en-US");
        var peffectiveto = datePipe.transform(Effectiveto, 'yyyy-MM-dd');
        // this._Role.effectiveto = (new Date(peffectiveto));
        this._currencyform.get("effectiveto").setValue(new Date(peffectiveto))
        // this._roleform.get("effectiveto").setValue(this._Role.effectiveto);
      }

      var datePipe = new DatePipe("en-US");
      var peffectivefrom = datePipe.transform(Effectivefrom, 'yyyy-MM-dd');

      this._Currency = this._currencyform.value;
      this._Currency.effectivefrom = (new Date(peffectivefrom));
      if (peffectivefrom > peffectiveto) {
        this._CustomExceptionService.handleWarning("Effective From Date should be less than the Effective to Date");
        return;
      }


      // var Effectivefrom = this._currencyform.get("effectivefrom").value;
      // var Effectiveto = this._currencyform.get("effectiveto").value;
      // var datePipe = new DatePipe("en-US");
      // var peffectivefrom = datePipe.transform(Effectivefrom, 'yyyy-MM-dd');
      // var peffectiveto = datePipe.transform(Effectiveto, 'yyyy-MM-dd');
      this._Currency = this._currencyform.value;
      // this._Currency.effectivefrom = (new Date(peffectivefrom));
      // this._Currency.effectiveto = (new Date(peffectiveto));
      //var CityOBJ = { "City": this._City };

      //Debug
      // this.onEd();
      if (this._action == 'create') {
        this.ManageDialog = false;
        this._Currency.createdon = new Date();
        this._Currency.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._CurrencyService.create(this._Currency).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
      else if (this._action == 'edit') {
        this._Currency.modifiedon = new Date();
        this._Currency.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._CurrencyService.edit(this._Currency).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            //this._CustomExceptionService.handleWarning("Please Click the Currency Update Button in Search Screen");
            this.showBasicDialog()
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
          //  this._Currency.effectivefrom = new Date(this._Currency.effectivefrom);
          //  this._Currency.effectiveto = new Date(this._Currency.effectiveto);
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
    }
  }
  showBasicDialog() {
    this.ManageDialog = true;
  }
  ok(){
    this.ManageDialog = false;
  }
  //  13.clear all the fields which the data has entered 
  reset(event) {
    this._submitted = true;
    this._currencyform.reset();
    this.isDisabledSave = false;
    this.isDisabledClear = false;
    this.InitializeForm();
    this._submitted = false;
  }
  // on clicking back button , it Navigates to the search screen
  goBack(event) {
    //alert('This');
    this._router.navigate(['/vCurrency']);
  }
  //Debug
  // onEd()
  // {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._Currency;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }
  OnFromDateSelect(event) {
    this.minDate = event;
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      //this._hotkeysService.add(new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
      //  this.router.navigate(['/cBank'], { state: { bankid: this._bankid, action: 'edit' }});
      //  return false; // Prevent bubbling
      //}));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  
  filterCurrencyStatus(currencyid: number): any[] {
    if (currencyid === 4) {
        return this._currencystatus;
    } else {
        return this._currencystatus = this._tempcurrencystatus.filter(status => 
            status.metasubcode === 'MSC00001' || status.metasubcode === 'MSC00002');
    }
}
}

