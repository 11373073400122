import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IAction} from '../security/IActions';
import {Observable, Subject} from 'rxjs';
import {map,catchError} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { AbstractControl } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class StateService {
  public _Action : IAction ;
  public _StateService = environment.StateService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http:HttpClient) {  
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(p_stateid: number): Observable<any> {
    const headers = this.header;
    var Query = this._StateService + '/' + p_stateid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
 FetchAll()
 {
  const headers = this.header;
   var Query = this._StateService + '/FetchAll' ;
  
   return this.http.get<{data:any}>(Query, { headers }).pipe(map(data=>{
     const resultJSON = JSON.parse(JSON.stringify(data));
     //console.log(resultJSON);
    //  let _StateScreenAccess = resultJSON.userData.lstFunctions.find(funct=>funct.functionid=="15");
    //  this._Action  = { 
    //    _functionId : 15,
    //    _functionName : "State",
    //    _Add :   _StateScreenAccess.iscreateallowed,
    //    _Delete : _StateScreenAccess.isdeleteallowed,
    //    _Update : _StateScreenAccess.iseditallowed,
    //    _View : _StateScreenAccess.isviewallowed,
    //    _Approve : _StateScreenAccess.isviewallowed
    //  }   
    return data; 
   }),);
 }
 create(p_State:object)
  {
    const headers = this.header;
    //console.log("Create");
    var Query = this._StateService  ;
    return this.http.post<{data:any}>(Query,p_State, { headers });
  }

  edit(p_State : object)
  {
    const headers = this.header;
    //console.log("Update");
    var Query = this._StateService;
   
    return this.http.put<{data:any}>(Query,p_State, { headers });
  }

  Cancel(stateid : number,userid :number)
  {
    const headers = this.header;
    //console.log("Update");
    var Query = this._StateService + '/Cancel/' + stateid +'/'+ userid ;
   
    return this.http.get<{ data: any }>(Query, { headers });
    
  }


}
