export class Quoterequestproductdetailsview {
    quoterequestdetailid: number;
    quoterequestid: number;
    producttypeid: number;
    productcategoryid: number;
    productid: number;
    productname: string;
    variantid: number;
    variantname: string;
    quantity: number;
    uom: string;
    uomname: string;
    remarks: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}