import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { OrderstatusService } from 'src/app/core/Services/ecommerce/orderstatus.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';


@Component({
  selector: 'app-v-orderstatus',
  templateUrl: './v-orderstatus.component.html',
  styleUrls: ['./v-orderstatus.component.css']
})
export class VOrderstatusComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _IsProgressSpinner: boolean = false;
  orderstatusListGrid: any[];
  _Action: IAction;
  cols: any[];
  datepipe = new DatePipe('en-Us');
  fromdate: Date;
  todate: Date;
  paymentmode: string;
  _PaymentModes: any[];
  _OrderStatuses: any[];
  orderstatus: string;
  IsOnlinePayment: boolean = false;
  Orderstatusobj: any;
  _ReturnOrderPopup: any[] = []
  _userid = parseInt(sessionStorage["userid"]);
  _branchid = parseInt(sessionStorage["currentbranchid"]);
  openpopup: boolean = false
  selectedProduct: any[]
  constructor(
    private router: Router,
    private utility: CommonUtilities,
    private _OrderStatus: OrderstatusService,
    private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService,
    public exportUtility: ExportUtility,
    private confirmationService: ConfirmationService
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(308);
    this.cols = [
      { field: 'orderno', header: 'Order NO' },
      { field: 'salesinvoiceno', header: 'Invoice NO' },
      { field: 'customername', header: 'Customer Name' },
      { field: 'orderdate', header: 'Order Date' },
      { field: 'ordertime', header: 'Order Time' },
      { field: 'orderamount', header: 'Order Amount' },
      { field: 'cancelledamount', header: 'Cancelled Amount' },
      { field: 'netamount', header: 'Total Amount' },
      { field: 'paymentmodename', header: 'Payment Mode' },
      { field: 'orderstatusname', header: 'Order Status' },
      { field: 'paynowreference', header: 'Gateway Referernce' },
      { field: 'paymentcompletestatus', header: 'Payment Status' },
    ]
    this.utility.pageLoadScript();
    this._PaymentModes = [
      { metasubdescription: "ALL", metasubcode: "ALL" },
      { metasubdescription: "Online Payment", metasubcode: "ECO00052" },
      { metasubdescription: "Cash on Delivery", metasubcode: "ECO00053" },
    ]
    this._OrderStatuses = [
      { metasubdescription: "Pending", metasubcode: "Pending" },
      { metasubdescription: "Delivered", metasubcode: "Delivered" },
      { metasubdescription: "Cancelled", metasubcode: "Cancelled" },
    ]
    this.paymentmode = "ALL";
    this.orderstatus = "Pending";
    this.todate = new Date();
    this.fromdate = new Date();
    this.OrderView();

    // this.orderstatusListGrid=[
    //   {orderid:'0123445ABC',customername:'Karthik Devaraj',date:'29/05/2021 08:00 PM',status:'Completed',total:'40'},
    //   {orderid:'0123487RJB',customername:'Rajesh Dinesh',date:'22/05/2021 08:00 PM',status:'Packed',total:'490'}
    // ]
  }
  ReturnOrderPopup(event) {
    let Orderno = event.orderno
    if (Orderno == null || Orderno == undefined) {
      this._CustomExceptionService.handleWarning("Please Check The Order No")
      return
    }
    this.openpopup = true
    let data = {
      "Orderno": Orderno
    }
    // console.log("Data",data)
    this._IsProgressSpinner = true;
    this._OrderStatus.ReturnOrderPopup(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this._ReturnOrderPopup = resultJSON.returnOrderPopupDetail
      this.selectedProduct = []
      if (this._ReturnOrderPopup.length == 0) {
        this.openpopup = false;
        this._CustomExceptionService.handleError("No Data Availabe");
        return
      }
      this._ReturnOrderPopup=[...this._ReturnOrderPopup]

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ConfirmReturn() {
    if (!this.selectedProduct || !this.selectedProduct.length) {
      this._CustomExceptionService.handleWarning("Please Select Atleast One row");
      return
    }
    for (let i = 0; i < this.selectedProduct.length; i++) {
      if (this.selectedProduct[i].RETURNQUANTITY == 0) {
        this.selectedProduct[i].RETURNQUANTITY = 0;
        this._CustomExceptionService.handleWarning("Return Quantity Should be greater than 0");
        return
      }
      if (this.selectedProduct[i].RETURNQUANTITY > this.selectedProduct[i].QUANTITY) {
        this.selectedProduct[i].RETURNQUANTITY = 0;
        this._CustomExceptionService.handleWarning("Return Quantity Should NOT be greater than Delivered Quantity");
        return
      }
    }
    let data = {
      "ReturnOrder": this.selectedProduct,
      "UserId": parseInt(sessionStorage["userid"])
    }
    this._IsProgressSpinner = true;
    this._OrderStatus.ReturnOrder(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this._ReturnOrderPopup = [];
      this.openpopup = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess("Returned Successfully");
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  returnQtyValidation(row: any) {
    let ReturnQty = row.RETURNQUANTITY;
    let DeiveredQty = row.QUANTITY;
    if (!ReturnQty || ReturnQty == null || ReturnQty == undefined) {
      row.RETURNQUANTITY = 0;
      this._CustomExceptionService.handleWarning("Please enter a valid Return Quantity");
      return
    }
    if (ReturnQty > DeiveredQty) {
      row.RETURNQUANTITY = 0;
      this._CustomExceptionService.handleWarning("Return Quantity Should not be greater than Delivered Qty");
      return
    }
  }
  OrderView() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this.Orderstatusobj = { "fromDate": fromdate, "toDate": todate, "paymentmode": this.paymentmode, "orderstatus": this.orderstatus }
    this._IsProgressSpinner = true;
    this._OrderStatus.Orderview(this.Orderstatusobj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("fetch", resultJSON);
      this.orderstatusListGrid = resultJSON.orderviewlst;
      // for(var i=0;i<this.orderstatusListGrid.length;i++){
      //   if(this.orderstatusListGrid[i].paymentmode=="ECO00052"){
      //     this.orderstatusListGrid[i].isonlinepayment
      //   }
      // }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  PaymentReconcilation(event) {
    this._IsProgressSpinner = true;
    this._OrderStatus.PaymentReconcilation(event.orderno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // console.log("fetch",resultJSON);
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this.OrderView();
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    //33.on clicking view button, it show the view screen.
    this.router.navigate(['/cOrderStatus'], { state: { orderno: event.orderno, action: 'view' } });
  }
  exportExcel() {
    let OrderStatusList = this.GetOrderStatusList();
    this.exportUtility.exportExcel(OrderStatusList, 'OrderStatus', 'xlsx');
  }


  ExportToPdf() {
    if (!this.orderstatusListGrid || !this.orderstatusListGrid.length)
      return;
    let StatusHistoryDetails = this.GetOrderStatusList();
    const data = StatusHistoryDetails.map(Object.values);
    const head = [['Order NO', 'Invoice No', 'Customer Name', 'Order Date', 'Order Time', 'Order Amt', 'Cancelled Amt', 'Total Amt', 'Payment Mode', 'Order Status', 'Gateway Referernce', 'Payment Status']]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('OrderStatus.pdf');
      })
    })

  }
  confirm(event) {

    this.confirmationService.confirm({
      message: 'Are you sure that you want to Cancel this Order No <b>"' + event.orderno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        if (!event.orderno) {
          return;
        }
        if (!event.salesinvoiceno) {
          return;
        }
        let cancelobj = { "orderno": event.orderno, "salesinvoiceno": event.salesinvoiceno, "userid": this._userid, "branchid": this._branchid }
        this.CancelOrder(cancelobj);
      }
    });
  }


  CancelOrder(cancelobj) {
    this._IsProgressSpinner = true;
    this._OrderStatus.CancelOrder(cancelobj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._IsProgressSpinner = false;
        this._CustomExceptionService.handleSuccess("Order Cancelled Successfully");
        this.ngOnInit();
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  GetOrderStatusList() {
    if (!this.orderstatusListGrid || !this.orderstatusListGrid.length)
      return;
    let OrderStatusList = [];
    this.orderstatusListGrid.forEach(element => {
      let orderstatus: any = {};
      orderstatus["Order NO"] = element.orderno;
      orderstatus["Sales Invoice NO"] = element.salesinvoiceno;
      orderstatus["Customer Name"] = element.customername;
      orderstatus["Order Date"] = this.datepipe.transform(element.orderdate, 'dd/MM/yyyy');
      orderstatus["Order Time"] = element.ordertime;
      orderstatus["Order Amt"] = element.orderamount;
      orderstatus["Cancelled Amt"] = element.cancelledamount;
      orderstatus["Total Amt"] = element.netamount;
      orderstatus["Payment Mode"] = element.paymentmodename;
      orderstatus["Order Status"] = element.orderstatusname;
      orderstatus["'Gateway Referernce"] = element.paynowreference;
      orderstatus["Payment Status"] = element.paymentcompletestatus;
      OrderStatusList.push(orderstatus);
    });
    return OrderStatusList;
  }
}
