import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Country } from 'src/app/core/Models/Country';
import { CountryService } from 'src/app/core/services/masters/country.service';
import { Countryview } from 'src/app/core/Views/Countryview ';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { jsPDF } from "jspdf";
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ConfirmationService } from 'primeng/api';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-country',
  templateUrl: './v-country.component.html',
  styleUrls: ['./v-country.component.css']
})
export class VCountryComponent implements OnInit {
  selectedrows: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _countryview: Countryview;
  _countriesview: Countryview[];
  _Action: IAction;
  _IsProgressSpinner: boolean = false;

  cols: any[];
  _countryid: number;
  _userid = sessionStorage["userid"];

  constructor(private fb: FormBuilder, private _CountryService: CountryService, private router: Router, private utility: CommonUtilities, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService, private exportUtility: ExportUtility) {

  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(10);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();

    this.cols = [
      { field: 'countrycode', header: 'Country Code' },
      { field: 'countryname', header: 'Country Name' },
      { field: 'countrystatusname', header: 'Country Status' }
    ]

    this._IsProgressSpinner = true;
    this._CountryService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._countriesview = resultJSON.countryviews;
      this.selectedrows = resultJSON.countryviews;
      this._IsProgressSpinner = false;
      // this._Add = this._CountryService._Action._Add; 
      // this._delete = this._CountryService._Action._Delete;
      // this._update = this._CountryService._Action._Update;
      // this._View = this._CountryService._Action._View;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  view(event) {
    this.router.navigate(['/cCountry'], { state: { countryid: event.countryid, action: 'view' } });
  }
  delete(event) {
    alert("delete");
  }
  GoToCreate(event) {
    this.router.navigate(['/cCountry']);
  }
  edit(event) {
    // 14.on clicking update button in search grid , it Navigates to the update screen
    this.router.navigate(['/cCountry'], { state: { countryid: event.countryid, action: 'edit' } });
  }

  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  exportExcel() {
    let countryList = this.GetCountryList();
    this.exportUtility.exportExcel(countryList, 'Countries', 'xlsx');
  }

  ExportToPdf() {
    let countryList = this.GetCountryList();
    this.exportUtility.ExportToPdf(countryList, 'Countries.pdf');
  }

  GetCountryList() {
    let countryList = [];
    this._countriesview.forEach(element => {
      let country: any = {};
      country["Country Code"] = element.countrycode;
      country["Country Name"] = element.countryname;
      country["Country Status"] = element.countrystatusname;
      countryList.push(country);
    });
    return countryList;
  }

  confirm(event) {
    this._countryid = event.countryid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Country <b>"' + event.countryname + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    this._IsProgressSpinner = true;
    this._CountryService.Cancel(this._countryid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_09);
        this._CountryService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._countriesview = resultJSON.countryviews;
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
} 
