import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptpaymentService {
  public _Action: IAction;
  public _httpOptions;
  public _RptPaymentService = environment.RptPaymentService;
  constructor(private http: HttpClient) { }
  PageOnload(): Observable<any> {
    var Query = this._RptPaymentService + '/PageInitialize';

    return this.http.get<{ data: any }>(Query);
  }
  Print(fromdate: string, todate: string, partytype: string, customerorsupplier: string, branchid: number, userid: number, type: string, currentbranchid: number, currencyid: number) {
    var Query = this._RptPaymentService + '/Print/' + fromdate + '/' + todate + '/' + partytype + '/' + customerorsupplier + '/' + branchid + '/' + userid + '/' + type + '/' + currentbranchid + '/' + currencyid;
    ///window.open(Query, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    return this.http.get(Query, { responseType: 'blob' });
  }
  onchangepartytype(partytype: string, userid: number) {
    var Query = this._RptPaymentService + '/onchangepartytype/' + partytype + '/' + userid;

    return this.http.get<{ data: any }>(Query);
  }
  PaymentPrint(voucherno: string, branchid: number) {
    var Query = this._RptPaymentService + '/PaymentPrint/' + voucherno + '/' + branchid;
    window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
  }
  ReceiptPrint(voucherno: string, branchid: number) {
    var Query = this._RptPaymentService + '/ReceiptPrint/' + voucherno + '/' + branchid;
    window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
  }
}
