import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { BlogService } from 'src/app/core/Services/ecommerce/blog.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-v-blog',
  templateUrl: './v-blog.component.html',
  styleUrls: ['./v-blog.component.css']
})
export class VBlogComponent implements OnInit {
  _Action: IAction;
  blogListGrid: any[];
  _IsProgressSpinner: boolean;
  _BLOG: any;
  BLOGHEADER: { Blogheader: any; };

  constructor(private utility: CommonUtilities, private _BlogService: BlogService,private confirmationService: ConfirmationService, private fb: FormBuilder, private _router: Router, private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
  
    this._Action = this._AccessRightsService.getAccessRights(310);
    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;
    this._BlogService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.blogListGrid = resultJSON.blogheaderlistviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this._router.navigate(['/cBlog'], { state: { blogid: event.blogid, action: 'view' } });
  }
  edit(event) {
    this._router.navigate(['/cBlog'], { state: { blogid: event.blogid, action: 'edit' } });
  }
  GoToCreate(event) {
    this._router.navigate(['/cBlog']);
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to Cancel this Blog <b>"' + event.pagename + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.cancel(event.blogid);
      }
    });
  }
  cancel(event) {
    this._IsProgressSpinner = true;
    this._BlogService.Cancel(event).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess("Blog Cancelled Successfully");
        this.ngOnInit();
      }
    });
  }
}
