import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { ApprovaltypeService } from 'src/app/core/services/users/approvaltype.service';
import { Approvaltypeview } from 'src/app/core/Views/Approvaltypeview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-approvaltype',
  templateUrl: './v-approvaltype.component.html',
  styleUrls: ['./v-approvaltype.component.css']
})
export class VApprovaltypeComponent implements OnInit {
  _workflowdetailviews:Approvaltypeview[];
  _workflowdetailview:Approvaltypeview;
  selectedrows: any;
  pSelectableRow: any;
  _workflowid:number;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _IsProgressSpinner: boolean = true;
  _Action:IAction;
  cols: any[];
  constructor(private fb: FormBuilder,  private _ApprovaltypeService: ApprovaltypeService, private router: Router,private utility:CommonUtilities,
    private _AccessRightsService : AccessRightsService,private _hotkeysService: HotkeysService,private exportUtility: ExportUtility,private _CustomExceptionService: CustomExceptionService) {
       //Go To Create Page
      //  this._hotkeysService.add(new Hotkey('alt+n', (event: KeyboardEvent): boolean => {
      //   this.GoToCreate(event);
      //   return false; // Prevent bubbling
      // }));
      this._Action = this._AccessRightsService.getAccessRights(35);
      this.HotKeyIntegration();
     }
     HotKeyIntegration() {
      this._hotkeysService.reset();
      if (this._Action._Add) {
        this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
          this.GoToCreate(null);
          return false; // Prevent bubbling
        }, ['INPUT', 'SELECT', 'TEXTAREA']));
      }
    }

 
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'documenttypename', header: 'Document Type Name' },
      { field: 'workflowtypename', header: 'Work Flow Type Name' },
      { field: 'workflowname', header: 'Work Flow Name' },
      { field: 'approvaltypestatusname', header: 'Status' },
    ]
    this._IsProgressSpinner = true;
  
    this._ApprovaltypeService.PageOnLoad().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._workflowdetailviews = resultJSON.approvalTypeView;
      this._IsProgressSpinner = false;
    },  
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  view(event) {
    this.router.navigate(['/cApprovaltype'], { state: { workflowid: event.workflowid,documenttypeid: event.documenttypeid, action: 'view' } });
  }
  exportExcel() {
    let ApprovalTypeList = this.GetApprovalTypeList();
    this.exportUtility.exportExcel(ApprovalTypeList, 'ApprovalType', 'xlsx');
  }

  ExportToPdf() {
    let ApprovalTypeList = this.GetApprovalTypeList();
    this.exportUtility.ExportToPdf(ApprovalTypeList, 'ApprovalType.pdf');
  }


  GetApprovalTypeList() {
    let ApprovalTypeList = [];
    this._workflowdetailviews.forEach(element => {
      let ApprovalType: any = {};
      ApprovalType["Document Type Name"] = element.documenttypename;
      ApprovalType["Work Flow Type Name"] = element.workflowtypename;
      ApprovalType["Work Flow Name"] = element.workflowname;
      ApprovalType["Status"] = element.approvaltypestatusname;
      ApprovalTypeList.push(ApprovalType);
    });
    return ApprovalTypeList;
  }
  delete(event) {
    alert("delete");
  }

  edit(event) {

    this.router.navigate(['/cApprovaltype']);  
    this.router.navigate(['/cApprovaltype'], { state: { workflowid: event.workflowid,documenttypeid: event.documenttypeid, action: 'edit' } });
  }
  GoToCreate(event){
    this.router.navigate(['/cApprovaltype']);
  }
}
