import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../../domain/appconfig';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SalesreturndashboardService {
  public _salesreturnDashboardService = environment.salesreturnDashboardService;
  config: AppConfig = {
    theme: 'saga-blue',
    dark: false,
    inputStyle: 'outlined',
    ripple: true
  };


  constructor(private http: HttpClient) { }
  private configUpdate = new Subject<AppConfig>();

  configUpdate$ = this.configUpdate.asObservable();

  updateConfig(config: AppConfig) {
    this.config = config;
    this.configUpdate.next(config);
  }

  getConfig() {
    return this.config;
  }

  PageOnload(): Observable<any> {
    var Query = this._salesreturnDashboardService + '/pageload';
    return this.http.get<{ data: any }>(Query);
  }

  CustomerMonthWiseSalesReturn(userid: number, customerid: number, branchid: number, fromdate: Date, todate: Date): Observable<any> {
    var datePipe = new DatePipe("en-US");
    var pfromdate = datePipe.transform(fromdate, 'yyyy-MM-dd');
    var ptodate = datePipe.transform(todate, 'yyyy-MM-dd');
    var Query = this._salesreturnDashboardService + '/CustomermonthwiseSalesReturn' + '/' + userid + '/' + customerid + '/' + branchid + '/' + pfromdate + '/' + ptodate;
   
    return this.http.get<{ data: any }>(Query);
  }

  MonthwiseSalesReturn(userid: number, branchid: number, fromdate: string, todate: string): Observable<any> {
    var datePipe = new DatePipe("en-US");
    var pfromdate = datePipe.transform(fromdate, 'yyyy-MM-dd');
    var ptodate = datePipe.transform(todate, 'yyyy-MM-dd');
    var Query = this._salesreturnDashboardService + '/MonthwiseSalesReturn' + '/' + userid + '/' + branchid + '/' + pfromdate + '/' + ptodate;
   
    return this.http.get<{ data: any }>(Query);
  }
}
