import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApprovalRequest } from '../../Models/Approvalrequest';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class ApprovalworkflowService {

  public _Action: IAction;
  public _httpOptions;
  public _ApprovalworkflowService = environment.ApprovalworkflowService;

  constructor(private http: HttpClient) { }

   // Popup Load :
   Popupfetch(p_userid:number,p_branch: number,p_roleid :number): Observable<any> {
    var Query = this._ApprovalworkflowService + '/' + p_userid+'/'+p_branch+'/'+p_roleid;
   
    return this.http.get<{ data: any }>(Query);
  }

  //Popup Update:
  Popupupdate(p_userid:number,p_documentdetailid:number,p_approvedstatus: string): Observable<any> {
    var Query = this._ApprovalworkflowService + '/popupupdate/' + p_userid+'/'+p_documentdetailid+'/'+p_approvedstatus;
   
    return this.http.get<{ data: any }>(Query);
  }

  //Work Flow Update:
  ApprovalWorkFlowUpdate(p_ApprovalDocument: ApprovalRequest): Observable<any>  {
    var Query = this._ApprovalworkflowService;
    return this.http.post<{ data: any }>(Query, p_ApprovalDocument);
  }
	// Order Popup Load :
   OrderPopupfetch(p_userid:number,p_branch: number,p_roleid :number,id:number): Observable<any> {
    var Query = this._ApprovalworkflowService + '/Popupfetchorder/' + p_userid+'/'+p_branch+'/'+p_roleid+'/'+id;
    console.log(Query);
    return this.http.get<{ data: any }>(Query);
  } 
}
