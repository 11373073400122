<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_Vehicleform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Vehicle</h3>
                        </div>
                        <div class="col-md-7 text-right">

                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save" class=""
                                    [disabled]="isDisabledSave"></button>
                                <button pButton pRipple type="button" title="Clear" type="button" icon="pi pi-trash"
                                    class="" class="p-button-danger" [disabled]="isDisabledClear"
                                    (click)="reset($event)"></button>
                                <button pButton pRiple type="button" icon="pi pi-search" title="Back to Search"
                                    (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="vehicleno" formControlName="vehicleno">
                                    <label for="vehicleno">Vehicle No <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Vehicleform.get('vehicleno').touched) && _Vehicleform.get('vehicleno').errors?.VehicleNumberRequired">
                                    Please Enter Vehicle No
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Vehicleform.get('vehicleno').touched) && _Vehicleform.get('vehicleno').errors?.VehicleNumberInvalid">
                                    Vehicle No Invalid (format: AAA1111).
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_vehicletypes" [autoDisplayFirst]="false"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="vehicletype" name="vehicletype">
                                    </p-dropdown>
                                    <label for="vehicletype">Vehicle Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Vehicleform.get('vehicletype').touched) && _Vehicleform.get('vehicletype').errors?.SelectVehicleType">
                                    Please Select Vehicle Type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number"  pInputText name="capacity" formControlName="capacity">
                                    <label for="capacity">Capacity <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Vehicleform.get('capacity').touched) && _Vehicleform.get('capacity').errors?.CapacityRequired">
                                    Please Enter Capacity
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [filter]="true" [options]="_UOMcapacities" [autoDisplayFirst]="false"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="capacityuomcode" name="capacityuomcode">
                                    </p-dropdown>
                                    <label for="capacityuomcode">Capacity UOM</label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Vehicleform.get('capacityuomcode').touched) && _Vehicleform.get('capacityuomcode').errors?.SelectCapacity">
                                    Please Select Capacity UOM
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="engineno" formControlName="engineno" maxlength="90">
                                    <label for="engineno">Engine Number <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Vehicleform.get('engineno').touched) && _Vehicleform.get('engineno').errors?.EngineNumberRequired">
                                    Please Enter Engine Number
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText name="chassisno" formControlName="chassisno" maxlength="90">
                                    <label for="chassisno">Chassis Number</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText name="gpsno" formControlName="gpsno" maxlength="90">
                                    <label for="gpsno">GPS Number</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [filter]="true" [options]="_gpssuppliers" [autoDisplayFirst]="false"
                                        optionLabel="suppliername" optionValue="supplierid"
                                        formControlName="gpssupplierid" name="gpssupplierid">
                                    </p-dropdown>

                                    <label for="gpssupplierid">GPS Vendor</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [filter]="true" [options]="_vehiclesuppliers" [autoDisplayFirst]="false"
                                        optionLabel="suppliername" optionValue="supplierid"
                                        formControlName="vehiclesupplierid" name="vehiclesupplierid">
                                    </p-dropdown>

                                    <label for="vehiclesupplierid">Vehicle Vendor</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="averagemilage"
                                        formControlName="averagemilage">
                                    <label for="averagemilage">Average Mileage</label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="meterreading" formControlName="meterreading">
                                    <label for="meterreading">Meter Reading</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [filter]="true" [options]="_drivers" [autoDisplayFirst]="false"
                                        optionLabel="employeename" optionValue="employeeid" formControlName="drivername"
                                        name="drivername">
                                    </p-dropdown>

                                    <label for="drivername">Driver Name</label>
                                </span>

                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [filter]="true" [options]="_cleaners" [autoDisplayFirst]="false"
                                        optionLabel="employeename" optionValue="employeeid"
                                        formControlName="cleanername" name="cleanername">
                                    </p-dropdown>

                                    <label for="cleanername">Cleaner Name</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_vehiclestatus" [autoDisplayFirst]="false"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="vehiclestatus" name="vehiclestatus">
                                    </p-dropdown>
                                    <label for="vehiclestatus">Vehicle Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Vehicleform.get('vehiclestatus').touched) && _Vehicleform.get('vehiclestatus').errors?.SelectStatus">
                                    Please Select vehicle Status
                                </span>
                            </td>

                        </tr>
                    </table>
                    <div class="sub-title">
                        <div class="row">
                            <div class="col-md-5">
                                <h5>Document Details</h5>
                            </div>
                            <div class="col-md-7"></div>
                        </div>
                    </div>
                    <form [formGroup]="_Vehicledocumentdetailform">
                        <table class="normal-table">
                            <tr>

                                <td style="width: 20%;">
                                    <!-- <span class="p-float-label">
                                        <input type="text" pInputText name="documentname"
                                            formControlName="documentname">
                                        <label for="documentname">Document Name <span class="hlt-txt">*</span></label>
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="(_submitted1 || _Vehicledocumentdetailform.get('documentname').touched) && _Vehicledocumentdetailform.get('documentname').errors?.DocumentNameRequired">
                                        Please Enter Document Name
                                    </span> -->
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_DocumentDescriptions"
                                            optionValue="documentdescriptionname" optionLabel="documentdescriptionname" formControlName="documentname"
                                            [filter]="true" filterBy="documentdescriptionname">
                                        </p-dropdown>
                                        <label for="documentname">Document Name<span
                                            class="hlt-txt">*</span></label>
                                    </span>
                                    <span class="text-danger"
                                    *ngIf="(_submitted1 || _Vehicledocumentdetailform.get('documentname').touched) && _Vehicledocumentdetailform.get('documentname').errors?.DocumentNameRequired">
                                    Please Select Document Name
                                </span> 
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar name="effectivefrom" [showIcon]="true" dateFormat="dd/mm/yy"
                                            (onSelect)="OnFromDateSelect($event)" formControlName="effectivefrom">
                                        </p-calendar>
                                        <label for="effectivefrom">Effective From</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar name="effectiveto" [showIcon]="true" formControlName="effectiveto" 
                                        dateFormat="dd/mm/yy" [disabled]="!_Vehicledocumentdetailform.get('effectivefrom').value || _action == 'view'"  [minDate]="minDate">
                                        </p-calendar>
                                        <label for="effectiveto">Effective To</label>
                                    </span>
                                </td>
                                <td style="width: 20%;"></td>
                                <td style="width: 20%;"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="p-float-label">
                                        <p-inputNumber name="noofalerts" inputId="withoutgrouping" maxlength="9"
                                        [useGrouping]="false" formControlName="noofalerts" placeholder="">
                                    </p-inputNumber>
                                        <label for="noofalerts">No Of Alert</label>
                                    </span>
                                </td>
                                <td>
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="remarks" formControlName="remarks" maxlength="90">
                                        <label for="remarks">Remarks</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <div class="browse-links">
                                                <p-button label="Vehicle Document" styleClass="p-button-link">
                                                </p-button>
                                            </div>
                                        </span>
                                        <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                            pTooltip="Browse File" tooltipPosition="top" mode="basic" #documentgrid
                                            accept="image/*,.pdf,.xls,.xlsx,.docx,.doc,.txt*" maxFileSize="1000000" [auto]="true"
                                            (onSelect)="onDocumentSelect($event,documentgrid)"
                                            [disabled]="_action=='view'">
                                        </p-fileUpload>
                                        <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                            (click)="onDocumentClear()" tooltipPosition="top"
                                            [disabled]="_action=='view'">
                                            <i class="pi pi-times"></i>
                                        </button>
                                    </div>
                                    <span class="text-danger" *ngIf="(_submitted1 && _LastAddedGridDocument == null)">
                                        Please Add Document
                                    </span>
                                </td>

                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Add" title="Add" icon="pi pi-plus"
                                        [disabled]="_action == 'view'" (click)="onAddDocuments($event)"
                                        class="p-button-success p-mr-2"></button>
                                    <button pButton pRipple label="Clear" title="Clear" icon="pi pi-times"
                                        (click)="onClear()" class="p-button-danger p-mr-2"
                                        [disabled]="_action == 'view'"></button>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                    </form>
                    <div class="card">
                        <p-table #dt [value]="_Documents" [rows]="5" [paginator]="true"
                            [rowsPerPageOptions]="[5,10,25,50,100]"
                            [globalFilterFields]="['documentname','effectivefrom','effectiveto','noofalerts','attachmenturl']"
                            [rowHover]="true" dataKey="documentid" [(selection)]="selectedrows"
                            (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 100px;text-align: center;">Action</th>
                                    <th>Document Name</th>
                                    <th>Effective From</th>
                                    <th>Effective To</th>
                                    <th>Alert Days</th>
                                    <th>Remarks</th>
                                    <th>Attachment Name</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_Document let-ri="rowIndex">
                                <tr >
                                    <!-- (dblclick)="onRowVehicleselect(_Document,ri)" -->
                                    <td style="text-align:center">
                                        <button pButton pRipple label="" title="Remove" icon="pi pi-trash"
                                            (click)="RemoveDocumentDetail(_Document)" class="p-button-danger p-mr-2"
                                            [disabled]="isDisabledDelete">
                                        </button>
                                    </td>
                                    <td>
                                        {{_Document.documentname}}
                                    </td>
                                    <td>
                                        {{_Document.effectivefrom | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_Document.effectiveto | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_Document.noofalerts}}
                                    </td>
                                    <td>
                                        {{_Document.remarks}}
                                    </td>
                                    <td>
                                        <a href="javascript:void(0);"
                                            (click)="showdocumentGridDialog('bottom-right',_Document)">
                                            {{_Document.attachmentname}}
                                        </a>

                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <p-dialog header="Vehicle Document" [(visible)]="displaydocumentgridpopup" [position]="position"
                        [style]="{width: '500px'}" [baseZIndex]="10000">
                        <img [src]="documentimage" alt="" class="img-preview">
                        <ng-template pTemplate="footer">
                            <p-button icon="pi pi-check" (click)="closePopupGridDialog()" label="Ok"
                                styleClass="p-button-text">
                            </p-button>
                        </ng-template>
                    </p-dialog>
                    <!-- Debug -->
                    <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                </div>
            </form>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>