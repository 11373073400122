<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_subcategoryform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>SubCategory</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" [disabled]="isDisabledsave" title="Save" icon="pi pi-save" ></button>
                                <button pButton pRipple type="button" [disabled]="isDisabledreset" (click)="reset($event)" title="Clear"
                                    icon="pi pi-trash" class="" class="p-button-danger"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                    title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <!-- <p-toast position="bottom-right"></p-toast> -->
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_type"  name="type"  formControlName="type" optionValue="metasubcode" optionLabel="metasubdescription" (onChange)="oncategorychange()">
                                    </p-dropdown>
                                    <label for="type">  Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" 
                                     *ngIf="(_submitted || _subcategoryform.get('type').touched )&& _subcategoryform.get('type').errors?.typeRequired"> 
                                    Please select Type </span>
                            </td>
                            
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Category"  name="categoryname" formControlName="categoryid" optionValue="categoryid" optionLabel="categoryname">
                                    </p-dropdown>
                                    <label for="categoryname">  Category <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" 
                                     *ngIf="(_submitted || _subcategoryform.get('categoryid').touched )&& _subcategoryform.get('categoryid').errors?.SelectCategoryName"> 
                                    Please select Category
                                </span>
                            </td>

                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="subcategoryname" formControlName="subcategoryname">
                                    <label for="subcategoryname">  Subcategory Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" 
                                     *ngIf="(_submitted || _subcategoryform.get('subcategoryname').touched )&& _subcategoryform.get('subcategoryname').errors?.CategoryNameRequired"> 
                                    Please enter subcategory Name
                                </span>
                            </td>

                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_subcategorystatus"  name="subcategorystatus"  optionLabel="metasubdescription" optionValue="metasubcode" formControlName="subcategorystatus">
                                    </p-dropdown>
                                    <label for="subcategorystatus"> Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" 
                                     *ngIf="(_submitted || _subcategoryform.get('subcategorystatus').touched )&& _subcategoryform.get('subcategorystatus').errors?.StatusRequired"> 
                                    Please select status </span>
                            </td>
                        </tr>
                    </table>
                    <!-- <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog> -->
                </div>
            </form>
        </div>
    </div>
</div>