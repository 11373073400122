import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map,catchError} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class PortService {

  public _Action : IAction ;
  public _PortService = environment.PortService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http:HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

   }
   createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(p_portid: number): Observable<any> {
    const headers = this.header;
    var Query = this._PortService + '/' + p_portid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll()
  {
    const headers = this.header;
    var Query = this._PortService + '/FetchAll' ;
   
    return this.http.get<{data:any}>(Query, { headers }).pipe(map(data=>{
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      // let _CityScreenAccess = resultJSON.userData.lstFunctions.find(funct=>funct.functionid=="15");
      // this._Action  = { 
      //   _functionId : 15,
      //   _functionName : "City",
      //   _Add :   _CityScreenAccess.iscreateallowed,
      //   _Delete : _CityScreenAccess.isdeleteallowed,
      //   _Update : _CityScreenAccess.iseditallowed,
      //   _View : _CityScreenAccess.isviewallowed,
      //   _Approve : _CityScreenAccess.isviewallowed
      // }   
     return data; 
    }),);
  }
  create(p_Port:object)
  {
    const headers = this.header;
    //console.log("Create");
    var Query = this._PortService  ;

   
    return this.http.post<{data:any}>(Query,p_Port, { headers });
  }
 
  edit(p_Port : object)
  {    
    const headers = this.header;
    //console.log("Update");
    var Query = this._PortService;
   
    return this.http.put<{data:any}>(Query,p_Port, { headers });
  }
 
  Cancel(portid:number,userid :number)
  {
    const headers = this.header;
    //console.log("Update");
    var Query = this._PortService + '/Cancel/' + portid +'/'+ userid ;
   
    return this.http.get<{ data: any }>(Query, { headers });
    
  }
}

