<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">

            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Teller Cash Day Settlement</h3>
                    </div>
                    <div class="col-md-7 text-right">

                        <div class="action-btn">
                            <button pButton pRipple title="Save" type="submit" (click)="onSave()" icon="pi pi-save" [disabled]="_IsSaveDisable"></button>
                            <button pButton pRipple title="Clear" icon="pi pi-trash" class="p-button-danger" (click)="reset($event)" [disabled]="_IsClearDisable"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button" [disabled]="isDisabledsendapparoval" (click)="SendToApproval($event)"></button>
                            <button pButton pRiple icon="pi pi-search" title="Back to Search" (click)=" goBack($event)" class="p-button p-button-success p-mr-2"></button>

                        </div>
                    </div>
                </div>
            </div>

            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>

                <div class="row">
                    <div class="col-md-6">
                        <form [formGroup]="_tellercashform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width:33%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_branches"
                                                optionLabel="branchname" optionValue="branchid" [filter]="true"
                                                filterBy="branchname" [showClear]="true" formControlName="branchid"
                                                (onChange)="OnChangeBranch()" [virtualScroll]="true" itemSize="30">
                                            </p-dropdown>
                                            <label for="branchid">Branch <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_searchsubmitted || _tellercashform.get('branchid').touched) && _tellercashform.get('branchid').errors?.SelectBranch">
                                            Please Select Branch
                                        </span>
                                    </td>
                                    <td style="width:33%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_tellernames" 
                                                optionLabel="employeename" optionValue="userid" [filter]="true"
                                                filterBy="employeename" [showClear]="true" formControlName="tellerid"
                                                (onChange)="OnChangeTellerName()">
                                            </p-dropdown>
                                            <label for="tellerid">Teller Name <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_searchsubmitted || _tellercashform.get('tellerid').touched) && _tellercashform.get('tellerid').errors?.SelectTellerName">
                                            Please Select Teller Name
                                        </span>
                                    </td>
                                    <td style="width:33%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_accountnames"
                                                optionLabel="username" optionValue="userid" formControlName="accountid"
                                                [filter]="true" filterBy="username" [showClear]="true">
                                            </p-dropdown>
                                            <label for="accountid">Account Name <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_searchsubmitted || _tellercashform.get('accountid').touched) && _tellercashform.get('accountid').errors?.SelectTellerName">
                                            Please Select Account Name
                                        </span>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-calendar [showIcon]="true" formControlName="salesdate"
                                                dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                                [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                            </p-calendar>
                                            <label for="salesdate">Sales Date <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_searchsubmitted || _tellercashform.get('salesdate').touched) && _tellercashform.get('salesdate').errors?.SelectSalesDate">
                                            Please Select Sales Date
                                        </span>
                                    </td>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox formControlName="isbankacknowledged" label="Is Acknowledged" binary="true"></p-checkbox>
                                            </div>
                                        </div>
                                    </td>
                                    <td rowspan="2">
                                        <span class="p-float-label">
                                            <textarea type="text" name="remarks" formControlName="remarks" rowspan="3"
                                                pInputTextarea></textarea>
                                            <label for="remarks">Remarks </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button pButton pRipple label="Search" type="button" (click)="GetDetails()" title="Search" icon="pi pi-search" [hidden]="_IsSearchDisable"></button>
                                        <button pButton pRipple label="Clear" title="Clear" (click)="Clear($event)" icon="pi pi-times" class="p-button-danger" [hidden]="_IsClearsDisable"></button>
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                    <div class="col-md-6">

                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Currency Wise</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>

                                </ng-template>
                                <ng-template pTemplate="right">

                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel('currency')" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel('currency')"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdfcurrency()"></button>
                                </ng-template>

                            </p-toolbar>
                            <p-table #dt [value]="_Currencywises" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['currencyid','receivableamount','paidamount','balanceamount']" [rowHover]="true" dataKey="tellercashcurrencywisedetailid"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" [(selection)]="SelectedCurrencywises">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Currency</th>
                                        <th>Receivable Amount</th>
                                        <th>Paid Amount</th>
                                        <th>Balance</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_currencywise>
                                    <tr>
                                        <td>
                                            {{_currencywise.currencyname}}
                                        </td>
                                        <td>
                                            {{_currencywise.receivableamount | number: '1.2-4'}}
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" [(ngModel)]="_currencywise.paidamount" [disabled]="_currencywise.tempbalance <= 0" 
                                                    (keyup)="allocatecurrencybalanceamount(_currencywise,$event)" pInputText *ngIf="_action != 'view'">
                                                    <div *ngIf="_action == 'view'">{{_currencywise.paidamount | number: '1.2-4'}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_currencywise.paidamount | number: '1.2-4'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td>
                                            {{_currencywise.balance | number: '1.2-4' }}

                                        </td>


                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">

                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Cash Sales Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>

                                </ng-template>
                                <ng-template pTemplate="right">

                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel('sales')" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel('sales')"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdfsales()"></button>
                                </ng-template>

                            </p-toolbar>
                            <p-table #dt [value]="_Salesdetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['billno','billdate','currencyid','paidamount','balanceamount']" [rowHover]="true" dataKey="tellercashsalesdetailid" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedsalesdetail">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 50px;">S.No.</th>
                                        <th>B. No.</th>
                                        <th>B. Date</th>
                                        <th>B. Curr</th>
                                        <th>B. Amnt</th>
                                        <th>Cur 1</th>
                                        <th>Paid</th>
                                        <th>Cur 2</th>
                                        <th>Paid</th>
                                        <th>Ret. Curr</th>
                                        <th>Ret. Amnt</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_salesdetail let-i="rowIndex">
                                    <tr>
                                        <td style="text-align: center;">
                                            {{i+ 1}}
                                        </td>
                                        <td>
                                            {{_salesdetail.billno}}
                                        </td>
                                        <td>
                                            {{_salesdetail.billdate | date:'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{_salesdetail.currencyname}}
                                        </td>
                                        <td>
                                            {{_salesdetail.billamount}}
                                        </td>
                                        <td>
                                            {{_salesdetail.currencyname1}}
                                        </td>
                                        <td>
                                            {{_salesdetail.paidamount1 | number: '1.2-4'}}
                                        </td>
                                        <td>
                                            {{_salesdetail.currencyname2}}
                                        </td>
                                        <td>
                                            {{_salesdetail.paidamount2 | number: '1.2-4'}}
                                        </td>
                                        <td>
                                            {{_salesdetail.recurrencyname}}
                                        </td>
                                        <td>
                                            {{_salesdetail.returnamount}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Sales Return Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>

                                </ng-template>
                                <ng-template pTemplate="right">

                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel('return')" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel('return')"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdfreturn()"></button>
                                </ng-template>

                            </p-toolbar>
                            <p-table #dt [value]="_Salesreturndetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['billno','billdate','currencyid','amount']" [rowHover]="true" dataKey="tellercashsalesreturndetailid" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedsalesreturndetails">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Bill No.</th>
                                        <th>Bill Date</th>
                                        <th>Currency</th>
                                        <th>Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_salesreturndetail let-ri="rowIndex">
                                    <tr>
                                        <td>
                                            {{_salesreturndetail.billno}}
                                        </td>
                                        <td>
                                            {{_salesreturndetail.billdate | date:'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{_salesreturndetail.currencyname}}
                                        </td>
                                        <td>
                                            {{_salesreturndetail.amount | number: '1.2-4'}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Bank Transaction Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>

                                </ng-template>
                                <ng-template pTemplate="right">

                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel('bank')" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel('bank')"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdfbank()"></button>
                                </ng-template>

                            </p-toolbar>
                            <p-table #dt [value]="_Bankdetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['billno','billdate','currencyid','paidamount','balanceamount']" [rowHover]="true" dataKey="tellercashsalesdetailid" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedsalesdetail">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Bill No.</th>
                                        <th>Bill Date</th>
                                        <th>Bill Amount</th>
                                        <th>Currency</th>
                                        <th>Paid</th>
                                        <!-- <th>Balance</th> -->
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_salesdetail let-i="rowIndex">
                                    <tr>
                                        <td style="text-align: center;">
                                            {{i+ 1}}
                                        </td>
                                        <td>
                                            {{_salesdetail.billno}}
                                        </td>
                                        <td>
                                            {{_salesdetail.billdate | date:'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{_salesdetail.billamount}}
                                        </td>
                                        <td>
                                            {{_salesdetail.currencyname1}}
                                        </td>
                                        <td>
                                            {{_salesdetail.paidamount1 | number: '1.2-4'}}
                                        </td>
                                        <!-- <td>
                                            {{_salesdetail.balance | number: '1.2-4'}}
                                        </td> -->
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>