import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Containertracking } from '../../Models/Containertracking';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class ContainertrackingService {

  public _Action: IAction;
  public _httpOptions;
  public _ContainertrackingService = environment.ContainertrackingService;
  token: any;
  header: any;


  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 1)

  }
  createHeaders(token: any, type: number): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  // Page Load :
  PageOnload(p_containertrackingid: number, userid: number): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ContainertrackingService + '/PageInitialize/' + p_containertrackingid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  OnChangeSupplier(supplierid: number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ContainertrackingService + '/OnChangeSupplier/' + supplierid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  //To Get Port Agent Account :
  OnPortAgentChange(Portagentid: any) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ContainertrackingService + '/OnPortAgentChange/' + Portagentid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  //To Get Port Agent Account :
  OnBordernameChange(Borderagentid: any) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ContainertrackingService + '/OnBordernameChange/' + Borderagentid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  //To Get Port Agent Account :
  OnLocalTranpoerterChange(Localtransporterid: any) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ContainertrackingService + '/OnLocalTranpoerterChange/' + Localtransporterid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  //Save Function:
  create(p_Containertracking: object) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ContainertrackingService + "/Create";
    console.log("save", Query);
    return this.http.post<{ data: any }>(Query, p_Containertracking, { headers });
  }


  // create(importentry: object) {

  //   var Query = this._ImportentryService;
  //  
  //   return this.http.post<{ data: any }>(Query, importentry);

  // Update Page Load:
  edit(p_Containertracking: any) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ContainertrackingService;
    return this.http.put<{ data: any }>(Query, p_Containertracking, { headers });
  }

  //Fetch All function call:
  FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ContainertrackingService + '/FetchAll' + '/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }
  Cancel(p_containertrackingid: number): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ContainertrackingService + '/Cancel/' + p_containertrackingid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

}

