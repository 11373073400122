export class Paymentdetailview {
    voucherno: string;
    paymentid: number;
    paymentdetailid: number;
    billno: string;
    billdate: Date;
    vouchertypeid: number;
    vouchertypedesc: string;
    debitamount: number;
    creditamount: number;
    paidamount: number;
    pendingamount: number;
    tempPendingamount: number;
    partycode: number;
    series: number;
    type: number;
    currentcurrencyid: number;
    currencyuniquerefid: number;
    isselected:boolean;
    invoiceno:string;
}