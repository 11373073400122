import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class CodpaymentstatusService {
  public _Action: IAction;
  public _CODPaymentStatusService = environment.CODPaymentStatusService;
  public _httpOptions;

  constructor(private http: HttpClient) { }

  PageOnload(): Observable<any> {
    var Query = this._CODPaymentStatusService;
    // console.log(Query);
    return this.http.get<{ data: any }>(Query);
  }
  getorderdetails(tellerid:number,isamountcollected:boolean,paymentmode:string) {
    var Query = this._CODPaymentStatusService + '/getorderdetails/' + tellerid+'/'+isamountcollected+'/'+paymentmode;
    return this.http.get<{ data: any }>(Query);
  }
  Create(myorderstatus: FormData) {
    var Query = this._CODPaymentStatusService;
    return this.http.post<{ data: any }>(Query, myorderstatus);
  }
}

