<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Direct Sales Return</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" type="button"
                                [disabled]="isDisabledsave" (click)="onSave()"></button>
                            <button pButton pRipple label="" title="Clear" icon="pi pi-trash"
                                [disabled]="isDisabledreset" (click)="reset($event)" class="p-button-danger"></button>
                            <!-- <button pButton pRipple title="Send To Approval" icon="las la-check"
                                [disabled]="isDisabledSendtoapproval" (click)="SendToApproval($event)"></button> -->
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">

                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <form [formGroup]="_salesreturnform" (ngSubmit)="onSave()">
                                <!-- If Sales Return Date"  is not select  show error as  "please select Sales Return Date " -->
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="salesreturndate"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="1980:2030" [disabled]="isDisablededit">
                                    </p-calendar>
                                    <label for="salesreturndate">Sales Return Date <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_salesreturnform.get('salesreturndate').touched || _submitted) && _salesreturnform.get('salesreturndate').errors?.SelectDate">
                                    Please Select Return Date
                                </span>
                            </form>
                        </td>

                        <td style="width: 20%;">
                            <form [formGroup]="_salesreturnform" (ngSubmit)="onSave()">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Customers" [filter]="true"
                                        filterBy="nameandmobile" optionValue="customerid" optionLabel="customername"
                                        formControlName="customerid" [showClear]="true"
                                        (onChange)="onCustomerNameChange($event)">
                                    </p-dropdown>
                                    <label for="salesinvoiceid">Customer <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_salesreturnform.get('customerid').touched || _submitted) && _salesreturnform.get('customerid').errors?.CustomerNameRequired">
                                    Please select cutomer
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>


                    <tr>
                        <!-- if "Sales Invoice No" is not enter, show error as "Please Enter Sales Invoice No" -->
                        <td style="width: 20%;">
                            <form [formGroup]="_salesreturnform" (ngSubmit)="onSave()">
                                <span class="p-float-label">
                                    <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_Invoicedetails"
                                        [disabled]="isDisablededit" (onChange)="OnInvoiceChange($event)" [filter]="true"
                                        filterBy="salesinvoiceno" optionValue="salesinvoiceno"
                                        optionLabel="salesinvoiceno" formControlName="salesinvoiceid"
                                        [showClear]="true">
                                    </p-dropdown> -->
                                    <!-- <p-autoComplete [(ngModel)]="salesinvoiceobj" [suggestions]="filteredInvoices"
                                    [disabled]="_action == 'edit' || _action == 'view'"
                                    (completeMethod)="filterInvoices($event)" field="salesinvoiceno"
                                    (onSelect)="OnInvoiceChange($event)">
                                    <ng-template let-invoice pTemplate="item">
                                        <div>{{invoice.salesinvoiceno}}</div>
                                    </ng-template>
                                </p-autoComplete> -->
                                    <input type="text" pInputText formControlName="salesinvoiceno"
                                        name="salesinvoiceno" autocomplete="off">
                                    <label for="salesinvoiceno">Invoice No <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_salesreturnform.get('salesinvoiceno').touched || _submitted) && _salesreturnform.get('salesinvoiceno').errors?.CustomerNameRequired">
                                    Please Enter Invoice no.
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_salesreturnform" (ngSubmit)="onSave()">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="invoicebranchname" disabled>
                                    <label for="invoicebranchname">Invoice Branch </label>
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_salesreturnform" (ngSubmit)="onSave()">
                                <span class="p-float-label">
                                    <p-dropdown #id [autoDisplayFirst]="false" [options]="_currencylist"
                                        formControlName="currentcurrencyid" optionLabel="currencyname"
                                        optionValue="currencyid">
                                    </p-dropdown>
                                    <label for="currencyname">Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_salesreturnform.get('currentcurrencyid').touched || _submitted) && _salesreturnform.get('currentcurrencyid').errors?.SelectCurrency">
                                    Please select currency
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <div class="row">
                    <div class="col-md-12">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Product Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <div class="card">
                            <p-table [value]="_ProductDetails" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]"
                                [globalFilterFields]="['productname','variantdescription']"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:100px;text-align: center;" *ngIf="_action != 'view'">Action</th>
                                        <th>Product Name</th>
                                        <!-- <th>Variant</th> -->
                                        <th style="width:100px">Invoice Qty</th>
                                        <th style="width:100px">Amount</th>
                                        <th style="width:100px">Return Qty</th>
                                        <th style="width:100px">UOM</th>
                                        <th style="width:100px">Tax</th>
                                        <th style="width:200px">Return Reason</th>
                                        <th>Product Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_ProductDetail let-rowIndex="rowIndex">
                                    <tr>
                                        <td style="text-align:center" *ngIf="_action != 'view'">
                                            <button pButton pRipple title="Add" icon="pi pi-plus"
                                                class="p-button-success p-mr-2" (click)="AddProductToGrid()"></button>
                                            <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                class="p-button-danger"
                                                (click)="RemoveProductFromGrid(rowIndex)"></button>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-autoComplete [suggestions]="_ProductDetail.filteredProducts"
                                                        (completeMethod)="filterProducts($event,_ProductDetail)"
                                                        field="productname" (onClear)="OnProductClear(_ProductDetail)"
                                                        (onSelect)="OnProductSelect($event,_ProductDetail)"
                                                        *ngIf="_action != 'view'">
                                                        <ng-template let-product pTemplate="item">
                                                            <div>{{product.productname}}</div>
                                                        </ng-template>
                                                    </p-autoComplete>
                                                    <div *ngIf="_action == 'view'">{{_ProductDetail.productname}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.productname}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" [(ngModel)]="_ProductDetail.invoicequantity"
                                                        [disabled]="isDisabledreturnqty" pInputText
                                                        *ngIf="_action != 'view'"
                                                        (blur)="allocatequantity(_ProductDetail,$event)" maxlength="7"
                                                        (keypress)="keypress.kpNumber($event)">
                                                    <div *ngIf="_action == 'view'">{{_ProductDetail.invoicequantity}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.invoicequantity}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" [(ngModel)]="_ProductDetail.totalamount"
                                                        [disabled]="isDisabledreturnqty" pInputText
                                                        *ngIf="_action != 'view'"
                                                        (blur)="allocatequantity(_ProductDetail,$event)" maxlength="7"
                                                        (keypress)="keypress.kpDecimalInput($event)">
                                                    <div *ngIf="_action == 'view'">{{_ProductDetail.totalamount}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.totalamount}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" [(ngModel)]="_ProductDetail.returnquantity"
                                                        [disabled]="isDisabledreturnqty" pInputText
                                                        *ngIf="_action != 'view'"
                                                        (keyup)="allocatequantity(_ProductDetail,$event)" maxlength="7"
                                                        (keypress)="keypress.kpNumber($event)">
                                                    <div *ngIf="_action == 'view'">{{_ProductDetail.returnquantity}}
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.returnquantity}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td>
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_uomlist"
                                                optionLabel="metasubdescription" optionValue="metasubcode" [disabled]="_action == 'view'"
                                                [filter]="true" filterBy="metasubdescription" [showClear]="true"
                                                [(ngModel)]="_ProductDetail.uomcode">
                                            </p-dropdown>
                                        </td>
                                        <td>
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_taxlist"
                                                optionLabel="taxname" optionValue="taxid" [filter]="true" [disabled]="_action == 'view'"
                                                filterBy="taxname" [showClear]="true" (onChange)="allocatequantity(_ProductDetail)"
                                                [(ngModel)]="_ProductDetail.taxid">
                                            </p-dropdown>
                                        </td>
                                        <td>
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_Reasons"
                                                optionLabel="metasubdescription" [disabled]="_action == 'view'"
                                                [filter]="true" filterBy="metasubdescription" [showClear]="true"
                                                optionValue="metasubcode" [(ngModel)]="_ProductDetail.returnreason">
                                            </p-dropdown>
                                        </td>
                                        <td>
                                            {{_ProductDetail.productamount | number: '1.2-4' }}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
                <div class="row" [hidden]="true">
                    <div class="col-md-5">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Other Charges</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <div class="card">
                            <p-table [value]="salesreturnothercharges" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]"
                                [globalFilterFields]="['otherchargeid','otherchargeamount']" [rowHover]="true"
                                dataKey="otherchargeid"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedOtherChargesRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:100px;text-align: center;">Action</th>
                                        <th>Description</th>
                                        <th>Value</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_othercharge>
                                    <tr>
                                        <td style="text-align:center">
                                            <button pButton pRipple title="Add" icon="pi pi-plus"
                                                [disabled]="isDisabledadd" class="p-button-success p-mr-2"
                                                (click)="AddOtherCharges($event)"></button>
                                            <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                [disabled]="isDisabledremove" class="p-button-danger"
                                                (click)="RemoveOtherCharges(_othercharge)"></button>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown #id [autoDisplayFirst]="false" [options]="_Descriptions"
                                                        [disabled]="isDisabledothercharges" *ngIf="_action != 'view'"
                                                        optionLabel="otherchargename" optionValue="otherchargeid"
                                                        [(ngModel)]="_othercharge.otherchargeid"
                                                        (onChange)="OnchangeOtherCharge(_othercharge,$event)">
                                                    </p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                        [options]="_Descriptions" optionLabel="otherchargename"
                                                        optionValue="otherchargeid"
                                                        [(ngModel)]="_othercharge.otherchargeid">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" pInputText name="otherchargeamount"
                                                        (keyup)="OnChangeotherCharge(_othercharge,$event)"
                                                        [(ngModel)]="_othercharge.otherchargeamount" maxlength="7"
                                                        (keypress)="keypress.kpNumber($event)"
                                                        *ngIf="_action != 'view'">
                                                    <div *ngIf="_action == 'view'">
                                                        {{_othercharge.otherchargeamount}}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_othercharge.otherchargeamount}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
                <form [formGroup]="_salesreturnform">
                    <div class="sub-title">
                        <div class="row">
                            <div class="col-md-5">
                                <h5>Payment Details</h5>
                            </div>
                            <div class="col-md-7"></div>
                        </div>
                    </div>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">

                                <span class="p-float-label">
                                    <input type="text" name="salesreturngrossamount"
                                        formControlName="salesreturngrossamount" disabled pInputText>
                                    <label for="salesreturngrossamount">Gross Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="salesreturndeductionamount"
                                        formControlName="salesreturndeductionamount" disabled pInputText>
                                    <label for="salesreturndeductionamount">Other Deduction Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="salesreturnamount" formControlName="salesreturnamount"
                                        disabled pInputText>
                                    <label for="salesreturnamount">Net Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <!-- Debug -->
                                <!-- <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div> -->
                            </td>
                        </tr>
                    </table>
                </form>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>