import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IAction } from '../security/IActions';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesreturnService {
  public _Action: IAction;
  public _SalesReturnService = environment.SalesreturnService;
  public _RptSRPrint = environment.RptSalesReturnPrintService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  // Page Load Service:
  PageOnLoad(salesreturnno: string, userid: number): Observable<any> {
		const headers = this.header;
    var Query = this._SalesReturnService + '/' + salesreturnno + '/' + userid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  //Get Product Details:
  GetInvoicedetails(invoiceno: string, userid: number): Observable<any> {
		const headers = this.header;
    var Query = this._SalesReturnService + '/GetInvoicedetails/' + invoiceno + '/' + userid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  create(salesreturn: object) {
		const headers = this.header;
    console.log("Create");
    var Query = this._SalesReturnService;
    return this.http.post<{ data: any }>(Query, salesreturn, { headers });
  }

  FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
		const headers = this.header;
    var Query = this._SalesReturnService + '/FetchAll' + '/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  edit(salesreturn: object) {
		const headers = this.header;
    var Query = this._SalesReturnService;
    return this.http.put<{ data: any }>(Query, salesreturn, { headers });
  }

  SendToApproval(userid: number, branchid: number, salesreturnno: string) {
		const headers = this.header;
    console.log("Send To Approval");
    var Query = this._SalesReturnService + '/SendToApproval' + '/' + userid + '/' + branchid + '/' + salesreturnno;
    return this.http.post<{ data: any }>(Query, '', { headers });
  }
  Cancel(userid: number, salesreturnno: string) {
		const headers = this.header;
    var Query = this._SalesReturnService + '/Cancel' + '/' + userid + '/' + salesreturnno;
    return this.http.post<{ data: any }>(Query, '', { headers });
  }

  //Get Invoice Nos
  GetInvoiceNos(obj: object) {
		const headers = this.header;
    var Query = this._SalesReturnService + '/GetInvoiceNos';
    return this.http.post<{ data: any }>(Query, obj, { headers });
  }
  // Print(salesreturnno: string,branchid:number ) {
  //   var Query = this._RptSRPrint + '/Print/' + salesreturnno + '/'+branchid;
  //   window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes'); 
  // }
  Print(obj: any) {
		const headers = this.header;
    // var Query = this._RptSRPrint + '/Print/' + salesreturnno + '/'+branchid;
    // window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes'); 
    var Query = this._SalesReturnService + '/SRPRINT';
    return this.http.post(Query, obj, { responseType: 'blob',  headers  });
  }
  GetCustomer(obj: object) {
		const headers = this.header;
    var Query = this._SalesReturnService + '/GetCustomer';
    return this.http.post<{ data: any }>(Query, obj, { headers });
  }
}
