import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DebitnoteService } from 'src/app/core/services/accounts/debitnote.service';
import { IAction } from 'src/app/core/services/security/IActions';

import { CommonUtilities } from 'src/assets/js/common-utilities';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Debitnoteview } from 'src/app/core/Views/Debitnoteview';
import { ConfirmationService } from 'primeng/api';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { DatePipe } from '@angular/common';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-debitnote',
  templateUrl: './v-debitnote.component.html',
  styleUrls: ['./v-debitnote.component.css']
})
export class VDebitnoteComponent implements OnInit {
  _Action: IAction;
  selectedrows: any;
  _Debitnoteviews: Debitnoteview[];
  _Debitnoteview: Debitnoteview;
  _IsProgressSpinner: boolean = true;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _branchid:number;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  cols: any[];
  _roleid=sessionStorage["currentRole"]
  _userid = sessionStorage["userid"];
  mindate: Date;
  maxdate: Date;
  constructor(
    private _AccessRightsService: AccessRightsService,
    private fb: FormBuilder,
    private _DebitnoteService: DebitnoteService,
    private router: Router,
    private utility: CommonUtilities,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,
    private _hotkeysService: HotkeysService,
    private exportUtility: ExportUtility
  ) {
    //Go To Create Page

  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(66);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.cols = [
      { field: 'debitnoteno', header: 'Debit Note No' },
      { field: 'debitnotedate', header: 'Debit Note Date' },
      { field: 'accounttype', header: 'Account Type' },
      { field: 'accountname', header: 'Account Name' },
      { field: 'debitamount', header: 'Debit Amount' },
      { field: 'currencyname', header: 'Currency' },
      { field: 'debitnotestatusdesc', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.Fetchall();
  }

  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let branchid = sessionStorage["currentbranchid"]
    let userid = sessionStorage["userid"];   
    this.HotKeyIntegration();
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._DebitnoteService.FetchAll(userid, branchid,this._roleid,fromdate,todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Debitnoteviews = resultJSON.debitnoteviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  view(event) {

    console.log("Debit Note :", event);
    this.router.navigate(['/CDebitnote'], { state: { debitnoteno: event.debitnoteno, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/CDebitnote']);
  }


  Cancel(event) {

    console.log("Debit Note Cancel:", event);
    this._IsProgressSpinner = true;
    this._DebitnoteService.Cancel(this._userid,event.debitnoteno).subscribe((result) => {

      const resutJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
        this.Fetchall();
      }
      else {
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;

    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure do you want to cancel this Debit Note <b>"' + event.debitnoteno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  exportExcel() {
    let DebitNoteList = this.GetDebitNote();
    this.exportUtility.exportExcel(DebitNoteList, 'DebitNote', 'xlsx');
  }

  ExportToPdf() {
    let DebitNoteList = this.GetDebitNote();
    this.exportUtility.ExportToPdf(DebitNoteList, 'DebitNote.pdf');
  }
 
  GetDebitNote() {
    if (!this._Debitnoteviews || !this._Debitnoteviews.length)
    return;
    let DebitNoteList = [];
    this._Debitnoteviews.forEach(element => {
      let debitnote: any = {};
      debitnote["Debit Note No"] = element.debitnoteno;
      debitnote["Debit Note Date"] = element.debitnotedate
      debitnote["Account Type"] = element.accounttype;
      debitnote["Account Name"] = element.accountname;
      debitnote["Debit Amount"] = element.debitamount;
      debitnote["Currency"] = element.currencyname;
      debitnote["Status"] = element.debitnotestatusdesc;
      debitnote["Created By"] = element.createdbyname;
      debitnote["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      DebitNoteList.push(debitnote);
    });
    return DebitNoteList;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

}
