<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Branch</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" (click)="GoToCreate($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>


                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                                <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                            </ng-template>

                        </p-toolbar>
                        <p-table #dt [value]="_branchviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['branchcode', 'branchname','branchtypename','levelname','branchstatusname','currencyname']" [rowHover]="true"
                            dataKey="branchcode" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <!-- 36. grid contains saved data (action,branch code,branch name,branch type ,branch level and status) -->

                                    <th style="text-align: center;width: 120px;" pSortableColumn="action">Action
                                        <p-sortIcon field="id"></p-sortIcon>
                                    </th>

                                    <th pSortableColumn="branchcode">Branch Code
                                        <p-sortIcon field="branchcode">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="branchname">Branch Name
                                        <p-sortIcon field="branchname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="branchtypename">Branch Type
                                        <p-sortIcon field="branchtypename">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="levelname">Level
                                        <p-sortIcon field="levelname"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="currencyid">Currency
                                        <p-sortIcon field="currencyid">
                                        </p-sortIcon>
                                    </th>
                                    <!-- <th pSortableColumn="series">Series <p-sortIcon field="series"></p-sortIcon>
                                </th>  -->
                                    <th pSortableColumn="branchstatusname">Status
                                        <p-sortIcon field="branchstatus">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdbyname">Created By
                                        <p-sortIcon field="createdbyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdon">Created On
                                        <p-sortIcon field="createdbyname">
                                        </p-sortIcon>
                                        <th pSortableColumn="modifiedbyname">Modified By
                                            <p-sortIcon field="createdbyname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="modifiedon">Modified On
                                            <p-sortIcon field="createdbyname">
                                            </p-sortIcon>
                                        </th>



                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_branchview>
                                <tr [ngClass]="{'qs-row': _branchview.branchstatusname === 'Inactive'}">
                                    <td class="text-center">
                                        <!-- 57. on clicking view button, it show the view screen. -->
                                        <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2" (click)="view(_branchview)" [disabled]="!_Action._View"></button>
                                        <!-- 38. on clicking update button in the grid , it opens the update page  -->
                                        <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" (click)="edit(_branchview)" [disabled]="!_Action._Update"></button>
                                        <button pButton pRipple icon="pi pi-times" title="Delete" class="p-button p-button-danger" (click)="confirm(_branchview)" [disabled]="_branchview.branchstatuscode == 'MSC00002' || !_Action._Delete"></button>
                                    </td>
                                    <td>
                                        {{_branchview.branchcode}}
                                    </td>
                                    <td>
                                        {{_branchview.branchname}}
                                    </td>
                                    <td>
                                        {{_branchview.branchtypename}}
                                    </td>
                                    <td>
                                        {{_branchview.levelname}}
                                    </td>
                                    <td>
                                        {{_branchview.currencyname}}
                                    </td>
                                    <!-- <td>
                                    {{_branchview.series}}
                                </td> -->
                                    <td>
                                        {{_branchview.branchstatusname}}
                                    </td>
                                    <td>
                                        {{_branchview.createdbyname}}
                                    </td>
                                    <td>
                                        {{_branchview.createdon |date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_branchview.modifiedbyname}}
                                    </td>
                                    <td>
                                        {{_branchview.modifiedon |date:'dd/MM/yyyy'}}
                                    </td>


                                </tr>

                            </ng-template>
                        </p-table>

                    </div>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>