import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Assetregistration } from 'src/app/core/Models/AssetRegistration';
import { AssetregistrationService } from 'src/app/core/services/accounts/assetregistration.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Assetregistrationview } from 'src/app/core/Views/Assetregistrationview';

import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-asset-regirtration',
  templateUrl: './v-asset-regirtration.component.html',
  styleUrls: ['./v-asset-regirtration.component.css']
})
export class VAssetRegirtrationComponent implements OnInit {
  _Action: IAction;


  selectedrows: any;
  _Assetregistration: Assetregistration;
  _Assetregistrationview: Assetregistrationview;
  _Assetregistrationviews: Assetregistrationview[] = [];
  _IsProgressSpinner: boolean = true;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _branchid: number;
  cols: any[];
  _roleid = sessionStorage["currentRole"]
  _userid = sessionStorage["userid"];
  mindate: Date;
  maxdate: Date;
  constructor(private fb: FormBuilder, private _CustomExceptionService: CustomExceptionService, private _AssetregistrationService: AssetregistrationService, private router: Router, private utility: CommonUtilities,
    private confirmationService: ConfirmationService, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService, private exportUtility: ExportUtility) {

  }


  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(75);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.HotKeyIntegration();
    this.cols = [
      { field: 'registrationcode', header: 'Registration Code' },
      { field: 'grnno', header: 'GRN NO' },
      { field: 'productname', header: 'Product Name' },
      { field: 'uomname', header: 'UOM' },
      { field: 'suppliername', header: 'Supplier' },
      { field: 'purchasedate', header: 'Purchase Date' },
      { field: 'currencyname', header: 'Currency' },
      { field: 'recordstatusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.Fetchall();


  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._AssetregistrationService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Assetregistrationviews = resultJSON.assetregistrationviews;
      this._IsProgressSpinner = false;
      // this._Add = this._CountryService._Action._Add; 
      // this._delete = this._CountryService._Action._Delete;
      // this._update = this._CountryService._Action._Update;
      // this._View = this._CountryService._Action._View;
    });
  }
  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  view(event) {

    console.log("This", event.registrationid);
    this.router.navigate(['/CAssetRegirtration'], { state: { registrationid: event.registrationid, action: 'view' } });

  }
  GoToCreate(event) {
    this.router.navigate(['/CAssetRegirtration']);
  }
  delete(event) {
    alert("delete");
  }

  Cancel(event) {
    //console.log("Debit Note Cancel:", event);
    this._IsProgressSpinner = true;
    this._AssetregistrationService.Cancel(event.registrationid).subscribe((result) => {

      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
        this._Assetregistrationviews = resultJSON.assetregistrationviews;
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
    }, error => this._CustomExceptionService.handleError(error));
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Voucher <b>"' + event.registrationcode + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'AssetRegistration', 'xlsx');
  }
  ExportToPdf() {
    let finalizedList = this.GetAssetRegistrationList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Registration Code',
      'GRN NO',
      'Product Name',
      'UOM',
      'Supplier',
      'Purchase Date',
      'Currency',
      'Status',
      'Created By',
      'Created On'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('AssetRegistration.pdf');
      })
    })
  }

  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._Assetregistrationviews.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Registration Code"] = element.registrationcode;
      assetregistration["GRN NO"] = element.grnno;
      assetregistration["Product Name"] = element.productname;
      assetregistration["UOM"] = element.uomname;
      assetregistration["Supplier"] = element.suppliername;
      assetregistration["Purchase Date"] = element.purchasedate;
      assetregistration["Currency"] = element.currencyname;
      assetregistration["Status"] = element.recordstatusname;
      assetregistration["Created By"] = element.createdbyname;
      assetregistration["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
}

