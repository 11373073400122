import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../../domain/appconfig';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StockdashboardService {
  public _stockDashboardService = environment.StockDashboardService;
  token: any;
  header: any;

  config: AppConfig = {
    theme: 'saga-blue',
    dark: false,
    inputStyle: 'outlined',
    ripple: true
  };
  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  private configUpdate = new Subject<AppConfig>();

  configUpdate$ = this.configUpdate.asObservable();

  updateConfig(config: AppConfig) {
    this.config = config;
    this.configUpdate.next(config);
  }

  getConfig() {
    return this.config;
  }

  PageOnload(): Observable<any> {
		const headers = this.header;
    var Query = this._stockDashboardService + '/pageload';

    return this.http.get<{ data: any }>(Query, { headers });
  }

  ProductTypeWiseStock(branchid: number): Observable<any> {
		const headers = this.header;
    var Query = this._stockDashboardService + '/ProductTypeWiseStock/' + branchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  FastMovingStock(branchid: number): Observable<any> {
		const headers = this.header;
    var Query = this._stockDashboardService + '/FastMovingStock/' + branchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  StockFreshNess(branchid: number): Observable<any> {
		const headers = this.header;
    var Query = this._stockDashboardService + '/StockFreshNess/' + branchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

}
