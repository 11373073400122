import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { RptageingService } from 'src/app/core/Services/reports/Accounting/RptAgeing/rptageing.service';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { Currencyview } from 'src/app/core/Views/Currencyview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-rptageing',
  templateUrl: './rptageing.component.html',
  styleUrls: ['./rptageing.component.css']
})
export class RptageingComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptageingform: FormGroup;
  _branchlist = sessionStorage["BranchID"];;
  _userid: number;
  datepipe: any;
  _typelist = [{ type: 'Customer', value: '11' }, { type: 'Supplier', value: '15' }];
  _customertypelist = [{ customertype: 'All', value: '4' }, { customertype: 'Credit', value: '1' }, { customertype: 'Default', value: '2' }, { customertype: 'Cash', value: '3' }];
  _suppliertypelist = [{ customertype: 'All', value: '1' }, { customertype: 'Product', value: '2' }, { customertype: 'Vehicle', value: '3' }, { customertype: 'Service', value: '4' }, { customertype: 'GPS', value: '5' }, { customertype: 'Agent', value: '6' }];
  _accountnamelist: Accountledger[];
  _src: SafeResourceUrl;
  _currencylist: Currencyview[];
  _customer_supplierlist: Accountledgerview[] = [];
  _PartytypeObj: any;
  _AgeingReportObj: any;
  maxDate: Date = new Date();
  currencyids: number[] = [];
  IsAmountZero: boolean = false;
  _ReportLists: any[] = [];
  cols: any[];
  _Action: IAction;
  mindate: Date;
  maxdate: Date;

  constructor(private utility: CommonUtilities, private _AccessRightsService: AccessRightsService,
    private fb: FormBuilder, private _RptAgeingService: RptageingService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer, private exportUtility: ExportUtility) { }
  InitiliazeForm() {
    this._rptageingform = this.fb.group({
      fromdate: [''],
      todate: [''],
      partytype: [],
      customer_supplierid: [],
      currencyid: [],
      branchid: [],
      month: [(new Date())],
      customertype: [],
    })
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptageingform.controls['month'].setValue(new Date());
      this.maxdate = new Date();
    }
    else {
      this._rptageingform.controls['month'].setValue(new Date(sessionStorage['Environmentenddate']));
    }
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(115);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.cols = [
      { field: 'actualname', header: 'Account Name' },
      { field: 'currencyname', header: 'Currency' },
      { field: 'openingbalance', header: 'Opening Balance' },
      { field: 'current', header: '0 Day' },
      { field: 'days30', header: '30 Days' },
      { field: 'days60', header: '60 Days' },
      { field: 'days90', header: '90 Days' },
      { field: 'days120', header: '120 Days' },
      { field: 'days150', header: '150 Days' },
      { field: 'days180', header: '180 Days' },
      { field: 'abovedays180', header: 'Above 180 Days' },
      { field: 'total', header: 'Total' }
    ]

    this.InitiliazeForm();
    this._branchlist = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    // this.InitializeForm();
    var dt = new Date();
    dt.setDate(dt.getDate() - 7);
    this._rptageingform.controls['fromdate'].setValue(dt);
    this._rptageingform.controls['todate'].setValue(new Date());
    this._rptageingform.controls['currencyid'].setValue(1);
    this._RptAgeingService.PageOnload({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //this._partytypelist = resultJSON.partytypeList;
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this.OnSetCurrency();
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  OnSetCurrency() {
    // this._rptageingform.controls['currencyid'].setValue(1);
    this.currencyids = [];
    this.currencyids.push(1);
  }
  clear(event) {
    this._rptageingform.reset();
    this.IsAmountZero  = false;
    this.InitiliazeForm();
    var dt = new Date();
    dt.setDate(dt.getDate() - 7);
    this._rptageingform.controls['fromdate'].setValue(dt);
    this._rptageingform.controls['todate'].setValue(new Date());
    this._rptageingform.controls['currencyid'].setValue(1);
    this.OnSetCurrency();
    this._ReportLists = [];
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }

  GenerateReport(event) {
    var branchid = parseInt(sessionStorage["currentbranchid"]);
    var _month = this._rptageingform.get("month").value;
    var datePipe = new DatePipe("en-US");
    var month = datePipe.transform(_month, 'yyyy-MM-dd');
    if (month == null) {
      this._CustomExceptionService.handleError("Please Select Month");
      return;
    }
    var customerorsupplier = this._rptageingform.get("customer_supplierid").value || [];
    var partytype = this._rptageingform.get("partytype").value || [];

    if (partytype.length == 0) {
      this._CustomExceptionService.handleError("Please Select Party Type");
      return;
    }
    if (customerorsupplier.length == 0) {
      this._CustomExceptionService.handleError("Please Select Party Name");
      return;
    }
    var currencyid = this._rptageingform.get("currencyid").value || 0;
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._AgeingReportObj = { 
      "userid": sessionStorage["userid"], 
      "ledgerid": customerorsupplier, 
      "currencyid": currencyid, 
      "branchid": branchid, 
      "month": month, 
      "type": this.IsAmountZero,
      
     }
    this._RptAgeingService.Print(this._AgeingReportObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (!resultJSON.reportList || !resultJSON.reportList.length) {
        this._CustomExceptionService.handleError("No Data Available");
      }
      else {
        this._ReportLists = resultJSON.reportList;
        const debitamount = this._ReportLists.reduce((sum, current) => sum + current.debitamount, 0);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onchangepartytype(event) {
    this._ReportLists = [];
    var partytype = event.value || [];
    this._rptageingform.get("customer_supplierid").reset();  
    var userid = parseInt(sessionStorage["userid"]);
    this._rptageingform.controls['customertype'].setValue(0);
    this.IsAmountZero  = false;

    if (partytype == 11) {
      this._customertypelist = [{ customertype: 'All', value: '4' }, { customertype: 'Credit', value: '1' }, { customertype: 'Default', value: '2' }, { customertype: 'Cash', value: '3' }];

    }
    else {
      this._customertypelist = this._suppliertypelist = [{ customertype: 'All', value: '1' }, { customertype: 'Product', value: '2' }, { customertype: 'Vehicle', value: '3' }, { customertype: 'Service', value: '4' }, { customertype: 'GPS', value: '5' }, { customertype: 'Agent', value: '6' }];
    }

    this._IsProgressSpinner = true;
    this._PartytypeObj = { 
      "partytype": partytype, 
      "userid": userid, 
      "Customertype": 0,
      "resperson" : 0
     };
    this._RptAgeingService.onchangepartytype(this._PartytypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accountnamelist = resultJSON.accountnameList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onchangecustomertype(event) {
    var customertype = event.value || 0;
    var userid = parseInt(sessionStorage["userid"]);
    var partytype = this._rptageingform.get("partytype").value || 0;
    this._IsProgressSpinner = true;
    this._PartytypeObj = { 
      "partytype": partytype, 
      "userid": userid, 
      "Customertype": customertype,
      "resperson" : 0 };
    this._RptAgeingService.onchangepartytype(this._PartytypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accountnamelist = resultJSON.accountnameList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  exportExcel() {
    let reportageinglists = this.GetAgeingReportList();
    this.exportUtility.exportExcel(reportageinglists, 'Ageing Report', 'xlsx');
  }

  ExportToPdf() {
    let branchwisestocklists = this.GetAgeingReportList();
    const data = branchwisestocklists.map(Object.values);
    const head = [['Account Name', 'Currency', 'Opening Balance', '0 Day', '30 Days', '60 Days', '90 Days', '120 Days', '150 Days', '180 Days', 'Above 180 Days', 'Total'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Ageing Report.pdf');
      })
    })
  }

  GetAgeingReportList() {
    let ageingreportlists = [];
    this._ReportLists.forEach(element => {
      let ageingreport: any = {};
      ageingreport["Account Name"] = element.actualname;
      ageingreport["Currency"] = element.currencyname;
      ageingreport["Opening Balance"] = element.openingbalance;
      ageingreport["0 Day"] = element.current;
      ageingreport["30 Days"] = element.days30;
      ageingreport["60 Days"] = element.days60;
      ageingreport["90 Days"] = element.days90;
      ageingreport["120 Days"] = element.days120;
      ageingreport["150 Days"] = element.days150;
      ageingreport["180 Days"] = element.days180;
      ageingreport["Above 180 Days"] = element.abovedays180;
      ageingreport["Total"] = element.total;
      ageingreportlists.push(ageingreport);
    });
    return ageingreportlists;
  }

}
