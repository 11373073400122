import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { stringify } from 'querystring';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Employee } from 'src/app/core/Models/Employee';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productdetail } from 'src/app/core/Models/Productdetail';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Stockadjustment } from 'src/app/core/Models/Stockadjustment';
import { Stockadjustmentdetail } from 'src/app/core/Models/Stockadjustmentdetail';
import { StockadjustmentService } from 'src/app/core/Services/inventory/stockadjustment.service';
import { vcycleno, vProductCategorySelect, vProductName, vProductTypeName, vProductTypeSelect, vstockdate, vstocker, vStockTakersSelect, vVariantId } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { KeyPress } from 'src/assets/js/KeyPress';

@Component({
  selector: 'app-c-stockadjustment',
  templateUrl: './c-stockadjustment.component.html',
  styleUrls: ['./c-stockadjustment.component.css']
})
export class CStockadjustmentComponent implements OnInit {

  _producttype: Producttype[] = [];
  _productcategory: Productcategory[] = [];
  _submitted = false;
  _productname: any[] = [];
  _tempproductdetails: any[] = [];
  _brandlist: any[] = [];
  _stockadjustmentform: FormGroup;
  _productsubmitted = false;
  _validate = true;
  _variantdetails: Productvariant[] = [];
  _stockadjustmentid: number;
  _stockadjustmentdetailform: FormGroup;
  _stockadjustmentproductform: FormGroup;
  isDisabledsave = false;
  isDisablereset = false;
  _StockadjustmentObj: any;
  _stockadjustment: Stockadjustment;
  _productdetail: Stockadjustmentdetail;
  _productdetails: Stockadjustmentdetail[] = [];
  selectedProductDetailsRows: Stockadjustmentdetail[] = [];
  isDisabledaddproduct = true;
  _stocktakeid: string;
  isDisabledclearproduct = true;
  _action: string = "create";
  _branchid: number;
  _cycleno: Metadatum[] = [];
  isDisabledremoveproduct = true;
  isDisabledproductcategory = true;
  _stocktakers: Employee[] = [];
  isDisabledprint = true;
  isDisabledsendapparoval = true;
  isDisabledproducttype = true;
  isDisabledButton = false;
  _submitted1 = false;
  _IsProgressSpinner: boolean = true;
  isDisabledselectall = false;
  isdisabledactualstock: boolean = true;
  isdisabledremarks: boolean = true;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _ProductHeaderObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  _severities: Metadatum[] = [];
  _Action: IAction
  mindate: Date;
  maxdate: Date;

  constructor(private fb: FormBuilder, private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    public keypress: KeyPress,
    private _stockadjustservice: StockadjustmentService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private _router: Router) {
    this._stockadjustmentid = history.state?.stocktakeid ? history.state?.stocktakeid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }

  InitializeStockadjustForm() {
    this._stockadjustmentform = this.fb.group({
      companyid: [],
      branchid: [],
      stocktakeid: [],
      stocktakeno: [],
      stocktakedate: ['', vstockdate],
      cycleno: ['', vcycleno],
      teamlead: ['', vstocker],
      stocktaker1: ['', vStockTakersSelect],
      stocktaker2: ['', vStockTakersSelect],
      status: [],
      statusremarks: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      rv: [],
      stockadjustmentdetails: [],
      stockinhand: []
    
    });

    if (sessionStorage['Environmentenddate'] == "null" ) {
      this._stockadjustmentform.get("stocktakedate").setValue(new Date);
    }
    else {
      let date = new Date(sessionStorage['Environmentenddate'])
      this._stockadjustmentform.get("stocktakedate").setValue(date);
    }

    // this._stockadjustmentform.get("stocktakedate").setValue(new Date);
  }
  InitializeStockadjustdetailForm() {
    this._stockadjustmentdetailform = this.fb.group({
      stockadjustmentdetailid: [],
      stocktakeid: [],
      productid: ['', vProductName],
      variantid: [],
      closingstock: [],
      actualstock: [],
      stockdifference: [],
      uomcode: [],
      unitprice: [],
      amount: [],
      remarks: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      stockversion: [],
      brandid: [],
      closingdamagedstock: []
    });
  }
  InitializeStockadjustproductForm() {
    this._stockadjustmentproductform = this.fb.group({
      producttypeid: ['', vProductTypeSelect],
      productcategoryid: ['', vProductCategorySelect]
    })
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(51);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeStockadjustForm();
    this.InitializeStockadjustdetailForm();
    this.InitializeStockadjustproductForm();
    //4.Date: current date should load as default date.


    this._branchid = parseInt(sessionStorage["BranchID"]);
    this._IsProgressSpinner = true;
    this._stockadjustservice.PageOnLoad(this._stockadjustmentid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      ////console.log(resultJSON);
      // this._stockadjustmentform.get("stocktakedate").setValue(new Date);
      // this._stockallocationform.get("frombranch").setValue(parseInt(sessionStorage["BranchID"]))
      this._cycleno = resultJSON.cycleNos;
      // 5.Load "Stock Takers" from Emplyoee Master, it contains Employee Name,code.
      this._stocktakers = resultJSON.stockTakers;
      //6.load "Product Type" from Product Master based on Login Branch, it contains Type name,Type code.
      this._producttype = resultJSON.producttypes;
      this._severities = resultJSON.severities;

      // //7.Product category load from product master, it contains category name,category code.
      // this._productcategory = resultJSON.productcategories
      // this._productname = resultJSON.productsearch;
      this._IsProgressSpinner = false;
      if (this._action == 'create') {
        this.isDisabledproductcategory = false;
        // this._productsubmitted = false;
        // this._submitted = false;
        // this.isDisabledsave = false;
        // this.isDisablereset = false;
        this.isDisabledremoveproduct = false;
        this.isDisabledproducttype = false;
      }
      if (this._action == 'view' || this._action == "edit") {
        //37.update initialize should load all fields based on search page selected Stack Take No.
        const updateJSON = JSON.parse(JSON.stringify(result));
        updateJSON.stockadjustment.stocktakedate = new Date(updateJSON.stockadjustment.stocktakedate);
        updateJSON.stockadjustment.cycleno = updateJSON.stockadjustment.cycleno;
        updateJSON.stockadjustment.teamlead = parseInt(updateJSON.stockadjustment.teamlead);
        updateJSON.stockadjustment.stocktaker1 = parseInt(updateJSON.stockadjustment.stocktaker1);
        updateJSON.stockadjustment.stocktaker2 = parseInt(updateJSON.stockadjustment.stocktaker2);
        this._stockadjustmentform.setValue(updateJSON.stockadjustment);
        this._productdetails = updateJSON.stockadjustmentgetproductdetailsupdateviews;
        this.selectedProductDetailsRows = updateJSON.stockadjustmentgetproductdetailsupdateviews;
        this.isDisabledproductcategory = true;
        this.isDisabledremoveproduct = true;
        // this._submitted = false;
        // this._productsubmitted = false;
        // this.isDisabledsave = false;
        // this.isDisablereset = false;
      }
      if (this._action == "view") {
        //34.View Initialize should load all fields based on search page selected Stock Take No. and all fields should be in read-only mode.
        this._stockadjustmentform.disable();
        this._stockadjustmentdetailform.disable();
        this.isDisabledsave = true;
        this.isDisablereset = true;
        this.isDisabledproductcategory = true;
        this.isDisabledproducttype = true;
        this.isDisabledButton = true;
        this.isDisabledselectall = true;
      }
      if (this._action == 'edit') {
        this.isDisabledsave = false;
        this.isDisablereset = true;
        this.isDisabledremoveproduct = false;
      }

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onchangeactualstock(row: any, event) {

    if (row.actualstock == null || row.actualstock == undefined) {
      row.actualstock = 0;
    }
    if (row.damagedstock == null || row.damagedstock == undefined) {
      row.damagedstock = 0;
    }

    var actual = (row.actualstock).toString();
    var damaged = (row.damagedstock).toString();

    if (actual.length > 9) {
      row.actualstock = 0
      return;
    }
    if (damaged.length > 9) {
      row.damagedstock = 0
      return;
    }

    //let balanceqty: number = (row.grnquantity + row.salesreturnqty) - (row.salesinvoiceqty + row.purchasereturnqty);
    let balanceqty: number = parseFloat((row.grnquantity + row.salesreturnqty - (row.salesinvoiceqty + row.purchasereturnqty)).toFixed(3));

    let actualqty: number = row.actualstock
    let damagedqty: number = row.damagedstock

    if (row.lotnumber == -1) {
      if (row.actualstock != 0) {
        row.actualstock = 0;
        this._CustomExceptionService.handleError("Minus Lot Number Accepts Zero Only");
        return;
      }
    }

    if (row.lotnumber != 1 && row.lotnumber != -1) {
      if (balanceqty < actualqty) {
        row.actualstock = row.closingstock;
        row.damagedstock = 0;
        row.stockdifference = 0;
        row.amount = 0;
        row.stockamount = 0;
        this._CustomExceptionService.handleError("Actual Qty Should not be Greater than Eligible Qty");
        return;
      }
      if (balanceqty < damagedqty) {
        row.actualstock = row.closingstock;
        row.damagedstock = 0;
        row.stockdifference = 0;
        row.amount = 0;
        row.stockamount = 0;
        this._CustomExceptionService.handleError("Actual Qty Should not be Greater than Eligible Qty");
        return;
      }
    }

    if (row.actualstock <= 0) {
      row.isdamagedstock = true;
    }
    else {
      row.isdamagedstock = false;
    }

    if (row.actualstock < row.damagedstock) {
      row.damagedstock = 0;
    }

    if (row.damagedstock < 0) {
      row.damagedstock = 0;
    }

    if (row.actualstock < 0 || row.actualstock == null || row.actualstock == undefined) {
      row.actualstock = 0;
      var Stockdifference = ((row.actualstock) - (row.closingstock)).toFixed(3);
      row.stockdifference = Stockdifference;
      row.amount = parseFloat((row.unitprice * row.stockdifference).toFixed(2));
      row.stockamount = parseFloat((row.unitprice * row.actualstock).toFixed(2));
      row.selected = true;
      return;
    }

    if (row.damagedstock > 0) {
      row.isseverity = false;
      row.severity = '';
    }
    else {
      row.isseverity = true;
    }
    var Stockdifference = ((row.actualstock) - (row.closingstock)).toFixed(3);
    row.stockdifference = Stockdifference;
    row.amount = parseFloat((row.unitprice * row.stockdifference).toFixed(2));
    row.stockamount = parseFloat((row.unitprice * row.actualstock).toFixed(2));
  }

  getProductCategory(event) {
    //9.based on selected Product Type system should filter Product Category
    var selectedProductType = event.value;
    ////console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._stockadjustmentproductform.controls["productcategoryid"].reset();
      this._productcategory = [];
      return;
    }
    else {
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._stockadjustservice.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategory = resultJSON.productcategories;
      },
        error => { this._CustomExceptionService.handleError(error); });
    }
  }

  getVariant(event) {
    var selectedProductVariant = event.value;
    this._stockadjustservice.getVariant(selectedProductVariant).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._variantdetails = resultJSON.productvariants;
    },
      error => { this._CustomExceptionService.handleError(error); });
  }

  getProduct(event) {
    //10.load Product Name based on selected product category, it contains Product code, Product name
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._stockadjustmentdetailform.reset();
      return;
    }
    else {
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._stockadjustservice.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productname = resultJSON.productsearch;

      },
        error => { this._CustomExceptionService.handleError(error); });
    }
  }

  getproductdetails(event) {
    var selectedProduct = event.value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._productdetails = [];
      return;
    }
    else {
      this._ProductHeaderObj = { "Productheader": selectedProduct || [], "Branchid": parseInt(sessionStorage["currentbranchid"]) }
      this._stockadjustservice.getproductdetails(this._ProductHeaderObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productdetails = resultJSON.stockadjustmentproductdetails;

        for (var i = 0; i < this._productdetails.length; i++) {
          this._productdetails[i].isseverity = true;
          if (this._productdetails[i].actualstock > 0) {
            this._productdetails[i].isdamagedstock = false;
          }
          else {
            this._productdetails[i].isdamagedstock = true;
          }
        }

        this._tempproductdetails = this._productdetails;
        let uniqueChars = [...new Set(resultJSON.stockadjustmentproductdetails)];
        uniqueChars = resultJSON.stockadjustmentproductdetails.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.brandid === test.brandid
          )
        );
        this._brandlist = uniqueChars;
      },
        error => { this._CustomExceptionService.handleError(error); });
    }
  }

  getbranddetails(event) {
    var selectedProduct = event.value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._productdetails = this._tempproductdetails;
      return;
    }
    else {
      this._productdetails = this._tempproductdetails.filter(f => (selectedProduct || []).includes(f.brandid));
    }
  }

  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  SelectAll(event) {
  }
  RemoveProduct(pVariant: Stockadjustmentdetail) {
    var index = this._productdetails.indexOf(pVariant);
    this._productdetails.splice(index, 1);
  }

  resetproduct(event) {
    this._productcategory = [];
    this._stockadjustmentdetailform.controls["productid"].reset();
    this._stockadjustmentdetailform.controls["variantid"].reset();
    this._stockadjustmentdetailform.reset();
  }

  validate(): boolean {
    //39.if any of the row was not selected in product details grid, show error "Should select at-least one row in product details"
    for (var i = 0; i < this.selectedProductDetailsRows.length; i++) {
      if (this.selectedProductDetailsRows[i].actualstock == 0) {
        this._validate = true;
      }
      else {
        this._validate = true;
      }

      if (this.selectedProductDetailsRows[i].damagedstock > 0) {

        if (this.selectedProductDetailsRows[i].severity == '' || this.selectedProductDetailsRows[i].severity == null || this.selectedProductDetailsRows[i].severity == undefined) {
          this._validate = false;
          this._CustomExceptionService.handleWarning("Please Select Severity");
          return;
        }
        if (this.selectedProductDetailsRows[i].remarks == '' || this.selectedProductDetailsRows[i].remarks == null || this.selectedProductDetailsRows[i].remarks == undefined) {
          this._validate = false;
          this._CustomExceptionService.handleWarning("Remarks Should Not Empty");
          return;
        }

      }
    }
    return this._validate;
  }
  onSave() {
    this._submitted = true;
    //Product Details
    var l_ProductDetails: Stockadjustmentdetail[] = [];
    Object.keys(this.selectedProductDetailsRows).map((Index) => {
      var currentRow = this.selectedProductDetailsRows[Index];
      var l_productdetails = new Stockadjustmentdetail();
      l_productdetails.stockadjustmentdetailid = 0;
      l_productdetails.stocktakeid = 0;
      l_productdetails.productid = currentRow.productid;
      l_productdetails.variantid = currentRow.variantid;
      l_productdetails.closingstock = currentRow.closingstock;
      l_productdetails.actualstock = currentRow.actualstock;
      l_productdetails.damagedstock = currentRow.damagedstock;
      l_productdetails.severity = currentRow.severity;
      l_productdetails.stockdifference = currentRow.stockdifference;
      l_productdetails.unitprice = currentRow.unitprice;
      l_productdetails.amount = currentRow.amount;
      l_productdetails.stockamount = currentRow.stockamount;
      l_productdetails.remarks = currentRow.remarks;
      l_productdetails.createdby = sessionStorage["userid"];
      l_productdetails.createdon = new Date();
      l_productdetails.modifiedby = sessionStorage["userid"];
      l_productdetails.modifiedon = new Date();
      l_productdetails.accountingyear = new Date().getFullYear();
      l_productdetails.stockversion = currentRow.stockversion;
      l_productdetails.lotnumber = currentRow.lotnumber;
      l_productdetails.grnqty = currentRow.grnquantity;
      l_productdetails.salesinvoiceqty = currentRow.salesinvoiceqty;
      l_productdetails.salesreturnqty = currentRow.salesreturnqty;
      l_productdetails.damagedlotnumber = currentRow.damagedlotnumber;
      l_ProductDetails.push(l_productdetails);
    })

    let uniqueChars = [...new Set(l_ProductDetails)];
    uniqueChars = l_ProductDetails.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        findTest.productid === test.productid && findTest.lotnumber == test.lotnumber
      )
    );
    l_ProductDetails = uniqueChars;

    this.validate();
    if (this._stockadjustmentform.valid) {
      if (this.selectedProductDetailsRows.length > 0) {
        //15.if "Cycle No." is empty, show error "Please Select Cycle No."
        if (this._validate == true) {

          var StockTakeDate = this._stockadjustmentform.get("stocktakedate").value;
          var datePipe = new DatePipe("en-US");
          var pStockTakeDate = datePipe.transform(StockTakeDate, 'yyyy-MM-dd');
          //For Model and Form Mapping
          this._stockadjustment = this._stockadjustmentform.value;
          this._stockadjustment.stocktakedate = (new Date(pStockTakeDate));
          this._StockadjustmentObj = { "Stockadjustment": this._stockadjustment, "Stockadjustmentdetail": l_ProductDetails };
          if (this._action == 'create') {
            this._stockadjustment.createdby = parseInt(sessionStorage["userid"]);
            this._stockadjustment.companyid = 0;

            this._stockadjustment.stocktakeid = 0;
            this._stockadjustment.stocktakeno = "0";
            this._stockadjustment.createdon = new Date();
            this._stockadjustment.accountingyear = new Date().getFullYear();
            this._stockadjustment.branchid = parseInt(sessionStorage["BranchID"]);
            // this._productdetails[0].createdby = parseInt(sessionStorage["userid"]);
            this._IsProgressSpinner = true;
            this._stockadjustservice.create(this._StockadjustmentObj).subscribe((result) => {
              const resultJSON = JSON.parse(JSON.stringify(result));
              ////console.log(result);
              ////console.log(resultJSON);
              this._IsProgressSpinner = false;
              this._stocktakeid = resultJSON.stockTakeId;
              if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
                this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
                //20.then enable the "Send to Approve" button
                this.isDisabledsendapparoval = false;
                this.reset(null);
                // this.isDisabledsave = true;
                // this.isDisabledprint = false;
                this._IsProgressSpinner = false;

              }
              else {
                this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
              }
              this._IsProgressSpinner = false;
            },

              error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
          }
          else if (this._action == 'edit') {
            this._stockadjustment.modifiedon = new Date();
            this._stockadjustment.modifiedby = sessionStorage["userid"];
            this._IsProgressSpinner = true;
            this._stockadjustservice.edit(this._StockadjustmentObj).subscribe((result) => {
              const resultJSON = JSON.parse(JSON.stringify(result));
              this._IsProgressSpinner = false;
              if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
                //40.after Updating  the record, get the response A, display the success message as "Stock Take  A  Modified Succesfully "
                this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
                this.isDisabledsave = true;
                this.isDisabledsendapparoval = false;
                this._stocktakeid = resultJSON.stockTakeId;
                this._IsProgressSpinner = false;
              }
              else {
                this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
              }
              this._IsProgressSpinner = false;
            },

              error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
          }

        }
        // else {
        //   this._CustomExceptionService.handleWarning(usererrors.Grid_Select_04);
        // }
      }
      else {
        this._CustomExceptionService.handleWarning(usererrors.Grid_Select_02);
      }
    }
  }
  SendToApproval(event) {
    //21.Check the user rights for the login user if the user has access to "send to Approve", then allow to "Send to Approve"
    var _Userid = sessionStorage["userid"];
    var _branchid = parseInt(sessionStorage["BranchID"]);
    this._IsProgressSpinner = true;
    this._stockadjustservice.SendToApproval(_Userid, _branchid, this._stocktakeid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //console.log(resultJSON);
      this.reset(null);
      this._CustomExceptionService.handleSuccess(usererrors.StockAdjustmentSendToApproval);
      this.isDisabledsendapparoval = true;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  reset(event) {
    //26.All input fields should be cleared.
    this._submitted = true;
    this._productsubmitted = true;
    this._stockadjustmentform.reset();
    this._stockadjustmentdetailform.reset();
    this._stockadjustmentproductform.reset();
    this._productdetails = [];
    this._brandlist = [];
    this.selectedProductDetailsRows = [];
    this._productcategory = [];
    this._productname = [];
    this.InitializeStockadjustForm();
    this.InitializeStockadjustdetailForm();
    this.InitializeStockadjustproductForm();
    this._submitted = false;
    this._productsubmitted = false;
  }


  goBack(event) {
    //alert('This');
    //console.log("goBack");
    this._router.navigate(['/vStockAdjustment']);
  }
  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}
