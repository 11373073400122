import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/core/Services/masters/customer.service';
import { Customerview } from 'src/app/core/Views/Customerview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-default-customer',
  templateUrl: './v-default-customer.component.html',
  styleUrls: ['./v-default-customer.component.css']
})
export class VDefaultCustomerComponent implements OnInit {

  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _customersview: Customerview[];
  _customerview: Customerview;
  _Action: IAction;
  _IsProgressSpinner: boolean = true;
  _customerid: number;
  cols: any[];
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _CustomerService: CustomerService, private _router: Router,
    private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService,
    private confirmationService: ConfirmationService, private exportUtility: ExportUtility) {
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(147);
    this._IsProgressSpinner = true;
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'customercode', header: 'Customer Code' },
      { field: 'customername', header: 'Customer Name' },
      { field: 'currencyname', header: 'Currency' },
      { field: 'mobileno', header: 'Mobile No.' },
      { field: 'mailid', header: 'Email' },
      { field: 'rewardpoints', header: 'Available Rewardpoints' },
      { field: 'customerstatusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
      { field: 'modifiedbyname', header: 'Modified By' },
      { field: 'modifiedon', header: 'Modified On' }

    ]

    this.HotKeyIntegration();
    this._CustomerService.
      FetchAll(sessionStorage["userid"], true, 0, "0", 0, 0, 0).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._customersview = resultJSON.customerviews;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
  }

  onRowUnselect(event) {
  }
  view(event) {
    this._router.navigate(['/cDefaultCustomer'], { state: { customerid: event.customerid, action: 'view' } });
  }

  edit(event) {
    this._router.navigate(['/cDefaultCustomer'], { state: { customerid: event.customerid, action: 'edit' } });
  }

  GoToCreate(event) {
    this._router.navigate(['/cDefaultCustomer']);
  }
  //Export Excel Download:
  exportExcel() {
    let customerList = this.GetCustomerList();
    this.exportUtility.exportExcel(customerList, 'Customer', 'xlsx');
  }

  ExportToPdf() {
    let customerList = this.GetCustomerList();
    this.exportUtility.ExportToPdf(customerList, 'Customer.pdf');
  }

  GetCustomerList() {
    let customerList = [];
    this._customersview.forEach(element => {
      let customer: any = {};
      customer["Customer Code"] = element.customercode;
      customer["Customer Name"] = element.customername;
      customer["Currency"] = element.currencyname;
      customer["Mobile No."] = element.mobileno;
      customer["Email"] = element.mailid;
      customer["Available Rewardpoints"] = element.rewardpoints;
      customer["Status"] = element.customerstatusname;
      customer["Created By"] = element.createdbyname;
      customer["Created On"] = element.createdon;
      customer["Modified By"] = element.modifiedbyname;
      customer["Modified On"] = element.modifiedon;
      customerList.push(customer);
    });
    return customerList;
  }


  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  confirm(event) {
    this._customerid = event.customerid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to deactivate this customer <b>"' + event.customername + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    this._IsProgressSpinner = true;
    this._CustomerService.Cancel(this._customerid, sessionStorage["userid"]).subscribe((result) => {
      const resutJSON = JSON.parse(JSON.stringify(result));
      if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_16);
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._customersview = resultJSON.customerviews;
        this._IsProgressSpinner = false;
      }
      else {
        this._IsProgressSpinner = false;
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
