import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Schemedetail } from 'src/app/core/Models/Schemedetail';
import { Schemefreeproductdetail } from 'src/app/core/Models/Schemefreeproductdetail';
import { Schemeheader } from 'src/app/core/Models/Schemeheader';
import { SchemeService } from 'src/app/core/Services/Rewards/scheme.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import * as ace from "ace-builds";
import { vAddress, vEffectiveFrom, vMaximumqtyName, vMinimumqtyName, vProductCategorySelect, vProductMultiNameSelect, vProductNameSelect, vProductTypeSelect, vQuantity, vSchemeName, vVariantNameSelect } from 'src/app/core/Validators/validation';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Schemefreeproductdetailview } from 'src/app/core/Models/Schemefreeproductdetailview';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import * as moment from 'moment';
import { variable } from '@angular/compiler/src/output/output_ast';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-c-scheme',
  templateUrl: './c-scheme.component.html',
  styleUrls: ['./c-scheme.component.css']
})
export class CSchemeComponent implements OnInit {
  Tempproductsearchview: Productoverallview[];
  _schemeform: FormGroup;
  _schemedetailform: FormGroup;
  _schemefreeproductform: FormGroup;
  _schemeproductform: FormGroup;
  _schemecategoryform: FormGroup;
  _Scheme: Schemeheader;
  _Schemedetail: Schemedetail[] = [];
  _Schemedetails: Schemedetail[] = [];
  _Schemefreeproduct: Schemefreeproductdetail;
  _SchemeOBJ: any;
  minDate: Date;
  _action: string = "create";
  _submitted = false;
  _submitted1 = false;
  _submitted2 = false;
  _schemeid: number;
  _producttype: Producttype;
  _productcategory: Productcategory;
  _productname: Productheader[];
  _freeproducttype: Producttype;
  _freeproductcategory: Productcategory;
  _freeproductname: Productheader[];
  _schemestatus: Metadatum
  _variant: Productvariant[];
  _freeproducts: Schemefreeproductdetailview[] = [];
  _freeproduct: Schemefreeproductdetailview;
  selectedproducts: Schemedetail[] = [];
  _prevProductNames: Productheader[] = [];
  filteredProducts: any[];
  _selectedProductsNames: number[] = [];
  _Productvariantdetailid: number;
  selectedProductDetailsRows: any;
  _IsProgressSpinner: boolean = true;
  isDisabledSave = false;
  isDisabledClear = false;
  isDisabledadd = false;
  isDisabledclear = false;
  isDisabledremove = false;
  isDisabledsendapparoval = true;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["currentbranchid"]
  _tempproduct: any
  _Action: IAction;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;

  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _SchemeService: SchemeService,
    private messageService: MessageService, private confirmationService: ConfirmationService, 
    private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService
    , private _router: Router, private _hotkeysService: HotkeysService) {
    this._schemeid = history.state?.schemeid ? history.state?.schemeid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeSchemeForm() {
    this._schemeform = this.fb.group({
      schemeid: [0],
      effectivefrom: ['', vEffectiveFrom],
      effectiveto: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      schemestatus: [],
      schemename: ['', vSchemeName],
      accountingyear: [],
      schemedetails: [],
      schemeremarks:['',vAddress],
      schemefreeproductdetails: [],
    });
    this._schemeform.controls['schemestatus'].setValue("MSC00001");
    this._schemeform.controls['effectivefrom'].setValue(new Date());

  }
  InitializeSchemedetailsForm() {
    this._schemedetailform = this.fb.group({
      schemedetailid: [0],
      schemeid: [],
      productid: ['', vProductMultiNameSelect],
      minimumqty: [],
      maximumqty: [],
      discountpercentage: [],
      rewardpoints: [],
      value: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      scheme: []
    });
  }
  InitializeSchemeProductForm() {
    this._schemeproductform = this.fb.group({
      producttypeid: [],
      productcategoryid: []
    });
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(79);
    this.utility.pageLoadScript();
    this._schemefreeproductform = this.fb.group({
      schemefreeproductdetailid: [0],
      schemeid: [],
      productid: ['', vProductNameSelect],
      variantid: [''],
      quantity: ['', vQuantity],
      uom: [],
      price: [],
      recordstatus: ['MSC00001'],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      producttypeid: [''],
      productcategoryid: [''],
    });
    this.InitializeSchemeForm();
    this.InitializeSchemedetailsForm();
    this.InitializeSchemeProductForm();
    //For Page Load 
    this._schemeform.controls['effectivefrom'].setValue(new Date());
    this.minDate = new Date();
    this._IsProgressSpinner = true;
    this._SchemeService.PageOnLoad(this._schemeid, this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._producttype = resultJSON.producttypes;
      this._freeproducttype = resultJSON.producttypes;
      this._schemestatus = resultJSON.status;
      this._freeproductname = resultJSON.productheaders;
      this._productname = resultJSON.productheaders;
      this._tempproduct = resultJSON.productheaders
      this._IsProgressSpinner = false;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        if (updateJSON.schemeheader.effectiveto == null) {
        }
        else {
          updateJSON.schemeheader.effectiveto = new Date(updateJSON.schemeheader.effectiveto);
        }
        updateJSON.schemeheader.effectivefrom = new Date(updateJSON.schemeheader.effectivefrom);
        // updateJSON.schemeheader.effectivefrom = new Date(updateJSON.schemeheader.effectivefrom);
        // updateJSON.schemeheader.effectiveto = new Date(updateJSON.schemeheader.effectiveto);
        this._schemeform.setValue(updateJSON.schemeheader);
        this._schemedetailform.setValue(updateJSON.schemedetails[0]);
        this._productname = updateJSON.productheaders;
        this._prevProductNames = updateJSON.schemedetails;
        var ProductIds: number[] = [];
        // var ProductType = this._tempproduct.filter(f=>f.productid == updateJSON.schemedetails.productid)
        Object.keys(updateJSON.schemedetails).map((Index) => {
          ProductIds.push(updateJSON.schemedetails[Index].productid);
        });
        this._selectedProductsNames = ProductIds;
        this._Schemedetail = updateJSON.schemedetails;
        this._freeproducts = updateJSON.schemefreeproductdetailviews;
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    if (this._action == 'view') {
      this._schemeform.disable();
      this._schemeproductform.disable();
      this._schemedetailform.disable();
      this._schemefreeproductform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
      this.isDisabledadd = true;
      this.isDisabledclear = true;
      this.isDisabledremove = true;
    }

    if (this._action == 'edit') {
      this.isDisabledClear = true;
      this.isDisabledSave = false;
      this.isDisabledsendapparoval = false;
      // this._schemeproductform.disable();
    }
  }

  getProductCategory(event) {
    var selectedCategory = event.value;
    if (selectedCategory.length == 0 || selectedCategory == null) {
      this._schemefreeproductform.reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._SchemeService.getProductCategory(selectedCategory, this._branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategory = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      // this._SelectedProductCategory = undefined;
      //this._selectedProductId = undefined;
    }
  }
  getFreeProductCategory(event) {
    var selectedCategory = event.value;
    if (selectedCategory.length == 0 || selectedCategory == null) {
      this._schemefreeproductform.reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._SchemeService.getProductCategory(selectedCategory, this._branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._freeproductcategory = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      // this._SelectedProductCategory = undefined;
      //this._selectedProductId = undefined;
    }
  }
  getProduct(event) {
    var selectedProduct = event.value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._schemefreeproductform.reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._SchemeService.getProduct(selectedProduct, this._branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productname = resultJSON.productheaders;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getFreeProduct(event) {
    var selectedProduct = event.value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._schemefreeproductform.reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._SchemeService.getProduct(selectedProduct, this._branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._freeproductname = resultJSON.productheaders;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  filterProducts(event) {
    let filtered: any[] = [];
    let query = (<string>event.query).toLowerCase();
    this.filteredProducts = this.Tempproductsearchview.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
  }
  getProductVariant(event) {
    var selectedVariant = event.value;
    this._IsProgressSpinner = true;
    this._SchemeService.getProductVariant(selectedVariant).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._variant = resultJSON.productvariants;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  onSelectProductNames(event) {
    var schemeproducts: Schemedetail[] = [];
    var products: Schemedetail;
    Object.keys(event.value).map((Index) => {
      products = new Schemedetail();
      // var Productnamelist: Productheader = this._productname;
      products = new Schemedetail();
      products.productid = event.value[Index];
      products.schemedetailid = 0;
      schemeproducts.push(products);
    });
    this._Schemedetail = schemeproducts;
  }

  onAddFreeProduct(event) {

    this._submitted2 = true;
    if (this._schemefreeproductform.valid) {
      var variantid = this._schemefreeproductform.get("variantid").value;
      this._IsProgressSpinner = true;
      this._IsProgressSpinner = false;
      var freeproductdetail = new Schemefreeproductdetailview();
      freeproductdetail.schemefreeproductdetailid = 0;
      freeproductdetail.schemeid = this._schemeform.get("schemeid").value;
      var productid = parseInt(this._schemefreeproductform.get("productid").value);
      this._SchemeService.getUOM(productid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        var productName = (this._freeproductname || []).filter(f => f.productid == productid)[0].productname;
        freeproductdetail.productid = productid;
        freeproductdetail.productname = productName;
        if (variantid == "" || variantid == null) {
          freeproductdetail.variantid = 0;
          freeproductdetail.variantdescription = "";
        }
        else {
          var variantName = (this._variant || []).filter(f => f.productvariantdetailid == variantid)[0].variantdescription;
          freeproductdetail.variantid = variantid;
          freeproductdetail.variantdescription = variantName;
        }
        freeproductdetail.quantity = this._schemefreeproductform.get("quantity").value;
        if (resultJSON.fetchuomdetails[0] == "" || resultJSON.fetchuomdetails[0] == null) {
          freeproductdetail.uom = "";
          freeproductdetail.uomname = "";
        } else {
          freeproductdetail.uom = resultJSON.fetchuomdetails[0].salesuom;
          freeproductdetail.uomname = resultJSON.fetchuomdetails[0].uom;
          freeproductdetail.price = resultJSON.fetchuomdetails[0].purchaserateinusd;
        }
        freeproductdetail.recordstatus = "MSC00001";
        freeproductdetail.createdon = new Date();
        freeproductdetail.createdby = sessionStorage["userid"];
        freeproductdetail.modifiedby = this._schemefreeproductform.get("modifiedby").value;
        freeproductdetail.modifiedon = this._schemefreeproductform.get("modifiedon").value;
        this._freeproducts.push(freeproductdetail);

        this._schemefreeproductform.reset();
        this._submitted2 = false;

      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    // }
  }


  RemoveFreeProduct(p_freeproducts: Schemefreeproductdetailview) {
    var index = this._freeproducts.indexOf(p_freeproducts);
    this._freeproducts.splice(index, 1)
  }

  Clear(event) {
    this._submitted2 = true;
    this._schemefreeproductform.reset();
    this._schemeproductform.reset();
    this.InitializeSchemeProductForm();
    this._submitted2 = false;
  }
  onSave() {
    this._submitted = true;
    this._submitted1 = true;
    if (this._schemedetailform.get("minimumqty").value > this._schemedetailform.get("maximumqty").value) {
      this._CustomExceptionService.handleWarning(usererrors.Schmemaxiandmini);
      return;
    }
    if (this._schemeform.valid) {
      if (this._schemedetailform.valid) {
        var schemeproducts: Schemedetail[] = [];
        var products: Schemedetail;
        Object.keys(this._Schemedetail).map((Index) => {
          products = new Schemedetail();
          products.schemedetailid = 0;
          products.schemeid = this._schemeform.get("schemeid").value;
          products.productid = this._Schemedetail[Index].productid;
          products.createdon = new Date();
          products.createdby = sessionStorage["userid"];
          products.modifiedby = sessionStorage["userid"];
          products.modifiedon = new Date();
          products.minimumqty = this._schemedetailform.get("minimumqty").value;
          products.maximumqty = this._schemedetailform.get("maximumqty").value;
          products.discountpercentage = this._schemedetailform.get("discountpercentage").value;
          products.rewardpoints = this._schemedetailform.get("rewardpoints").value;
          products.value = this._schemedetailform.get("value").value;
          schemeproducts.push(products);
        });
        this._Schemedetails = schemeproducts;
        var Effectivefrom = this._schemeform.get("effectivefrom").value;
        var Effectiveto = this._schemeform.get("effectiveto").value;
        if (Effectiveto == null) {
          this._schemeform.get("effectiveto").setValue(null);
        }
        else {
          var datePipe = new DatePipe("en-US");
          var peffectiveto = datePipe.transform(Effectiveto, 'yyyy-MM-ddTHH:mm:ssZ');
          this._schemeform.get("effectiveto").setValue(new Date(peffectiveto));
        }

        var datePipe = new DatePipe("en-US");
        var peffectivefrom = datePipe.transform(Effectivefrom, 'yyyy-MM-ddTHH:mm:ssZ');
        this._Scheme = this._schemeform.value;
        this._Scheme.effectivefrom = this._schemeform.get("effectivefrom").value.toLocaleString();

        if (Effectiveto != null) {
          this._Scheme.effectiveto = this._schemeform.get("effectiveto").value.toLocaleString();
        }
        
        this._Schemefreeproduct = this._schemefreeproductform.value;
        //For Object Call
        this._SchemeOBJ = { "Schemeheader": this._Scheme, "Schemedetail": this._Schemedetails, "Schemefreeproductdetail": this._freeproducts };
        // this.onEd();
        //For Create      
        if (this._action == 'create') {
          this._Scheme.createdon = new Date();
          this._Scheme.createdby = sessionStorage["userid"];
          //          this.onEd();
          this._IsProgressSpinner = true;
          this._SchemeService.create(this._SchemeOBJ).subscribe((result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
              this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
              this._schemeid = resutJSON.schemeheader.schemeid;
              this._IsProgressSpinner = false;
              this.isDisabledsendapparoval = false;
              //this.reset(null);
            }
            else {
              this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            } this._IsProgressSpinner = false;
          },

            error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        }
        //For Edit      
        else if (this._action == 'edit') {
          this._Scheme.modifiedon = new Date();
          this._Scheme.modifiedby = sessionStorage["userid"];
          // this.onEd();
          this._IsProgressSpinner = true;
          this._SchemeService.edit(this._SchemeOBJ).subscribe((result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
              this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
              this._IsProgressSpinner = false;
            }
            else {
              this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            } this._IsProgressSpinner = false;
          },

            error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        }
      }
    }

  }
  OnFromDateSelect(event) {
    this.minDate = event;
  }

  //Discount % Change:
  OnChangeDiscount(event) {
    var value = event.target.value;
    if (value != undefined && value != "" && value != null) {
      let discount = 0;
      if(value>100){
        value=0
        this._schemedetailform.controls["discountpercentage"].setValue(0);
        this._CustomExceptionService.handleWarning("Discount percentage should not greater than 100.");
        return;
      }
      Object.keys(this._Schemedetail).map((index) => {
        var currentproduct: Productheader[];
        currentproduct = this._productname.filter(f => f.productid == this._Schemedetail[index].productid);
        if (value > currentproduct[0].discountpercentage || 0) {
          discount = 1;
        }
      });
      // if (discount) {
      //   this._schemedetailform.get("discountpercentage").setValue('0');
      //   this._CustomExceptionService.handleWarning("Discount percentage must not exceed product discount percentage.");
      //   discount = 0
      // }
    }
  }

  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._SchemeOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);

  }
  goBack(event) {
    this._router.navigate(['/vScheme']);
  }
  reset(event) {
    this._submitted = true;
    this._submitted1 = true;
    this._submitted2 = true;
    this._schemeform.reset();
    this._schemeproductform.reset();
    this._schemedetailform.reset();
    this._schemefreeproductform.reset();
    this._freeproducts = [];
    this._Schemedetail = [];
    this.InitializeSchemeForm();
    this.InitializeSchemedetailsForm();
    this.InitializeSchemeProductForm();
    this._submitted = false;
    this._submitted1 = false;
    this._submitted2 = false;
    this._schemeform.controls['effectivefrom'].setValue(new Date());
    this.minDate = new Date();
  }
  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

  SendToApproval(event) {
    var workflowid = 53;
    this._userid = sessionStorage["userid"];
    this._branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;
    this._SchemeService.SendToApproval(workflowid, this._userid, this._branchid, this._schemeid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
      this.isDisabledsendapparoval = true;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}