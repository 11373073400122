export class Purchaseordergetproductsview {
    purchaseorderdetailid: string;
    purchaseorderno: string;
    quoterequestid: string;
    producttypeid: number;
    productcategoryid: number;
    productid: number;
    productcode: string;
    productname: string;
    variantid: number;
    variantname: string;
    quantity: number;
    unitprice: number;
    finalamount: number;
    taxcode: string;
    taxid:number
    taxpercentage: number;
    taxamount: number;
    accountingyear: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    uomcode:string;
    uomname:string;
    currencyid:number;
    vatapplicable:boolean;
}