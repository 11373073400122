<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Opening Balance Updation</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                (click)="onSave()"></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_screenlist" optionValue="metasubcode"
                                    optionLabel="metasubdescription" [filter]="true" [(ngModel)]="PartyType"
                                    (ngModelChange)="onPartyTypeChange()" filterBy="metasubcode">
                                </p-dropdown>
                                <label for="name">Party Type<span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Search" icon="las la-search" type="button" (click)="search()"
                                class="p-button-sm p-button-success"></button>
                            <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()"
                                class="p-button-danger"></button>
                        </td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="exportPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_openingbalanceview" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['PartyType','PartyCode','LedgerName','OpeningBalanceRand','OpeningBalanceUsd',
                        'OpeningBalanceBond','OpeningBalanceBank','OpeningBalanceUsdsp','OpeningBalanceRtgs','OpeningBalanceNostro','OpeningBalanceZim1','Consolidatedinusd','Zim1','Accountingyear','Status']"
                        [rowHover]="true" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)"
                        (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">

                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 100px;" pSortableColumn="select">Action
                                </th>

                                <th pSortableColumn="PartyType">Party Type<p-sortIcon field="PartyType">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="PartyCode">Party Code<p-sortIcon field="PartyCode">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="LedgerName">Party Name<p-sortIcon field="LedgerName">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="OpeningBalanceUsd" maxlenth="10">USD <p-sortIcon
                                        field="OpeningBalanceUsd">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="OpeningBalanceRand" maxlenth="10">RAND<p-sortIcon
                                        field="OpeningBalanceRand">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="OpeningBalanceBond">BOND<p-sortIcon field="OpeningBalanceBond">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="OpeningBalanceBank">BANK <p-sortIcon field="OpeningBalanceBank">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="OpeningBalanceUsdsp">USDSP <p-sortIcon field="OpeningBalanceUsdsp">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="OpeningBalanceRtgs">RTGS <p-sortIcon field="OpeningBalanceRtgs">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="OpeningBalanceNostro">NOSTRO <p-sortIcon
                                        field="OpeningBalanceNostro">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="OpeningBalanceZim1">ZIM1 <p-sortIcon field="OpeningBalanceZim1">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="Consolidatedinusd">Cons.USD <p-sortIcon field="Consolidatedinusd">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="Status">Status <p-sortIcon field="Status">
                                    </p-sortIcon>
                                </th>

                            </tr>

                        </ng-template>
                        <ng-template pTemplate="body" let-rowdata let-i="index">
                            <tr>
                                <td class="text-center" style="width: 60px;">
                                    <p-tableCheckbox [value]="rowdata">
                                    </p-tableCheckbox>
                                </td>
                                <td>
                                    {{rowdata.PartyType}}
                                </td>
                                <td>
                                    {{rowdata.PartyCode}}
                                </td>
                                <td>
                                    {{rowdata.LedgerName}}
                                </td>

                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" maxlength="13" 
                                                (blur)="onBond(rowdata)"
                                                (focusin)="onBond(rowdata)"
                                                (focusout)="onBond(rowdata)"
                                                (keydown.Tab)="onBond(rowdata)"
                                                [disabled]="rowdata.disablecontrols"
                                                [(ngModel)]="rowdata.OpeningBalanceUsd"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
                                            </p-inputNumber>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowdata.OpeningBalanceUsd}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" maxlength="13" 

                                                (blur)="onBond(rowdata)"
                                                (focusin)="onBond(rowdata)"
                                                (focusout)="onBond(rowdata)"
                                                (keydown.Tab)="onBond(rowdata)"

                                                [disabled]="rowdata.disablecontrols"
                                                [(ngModel)]="rowdata.OpeningBalanceRand"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
                                            </p-inputNumber>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowdata.OpeningBalanceRand}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" maxlength="13"
                                            (blur)="onBond(rowdata)"
                                            (focusin)="onBond(rowdata)"
                                            (focusout)="onBond(rowdata)"
                                            (keydown.Tab)="onBond(rowdata)"
                                                [disabled]="rowdata.disablecontrols"
                                                [(ngModel)]="rowdata.OpeningBalanceBond"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
                                            </p-inputNumber>

                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowdata.OpeningBalanceBond}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" maxlength="13" (blur)="onBond(rowdata)"
                                            (focusin)="onBond(rowdata)"
                                            (focusout)="onBond(rowdata)"
                                            (keydown.Tab)="onBond(rowdata)"
                                                [disabled]="rowdata.disablecontrols"
                                                [(ngModel)]="rowdata.OpeningBalanceBank"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
                                            </p-inputNumber>

                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowdata.OpeningBalanceBank }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" maxlength="13" (blur)="onBond(rowdata)"
                                            (focusin)="onBond(rowdata)"
                                            (focusout)="onBond(rowdata)"
                                            (keydown.Tab)="onBond(rowdata)"
                                                [disabled]="rowdata.disablecontrols"
                                                [(ngModel)]="rowdata.OpeningBalanceUsdsp"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
                                            </p-inputNumber>

                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowdata.OpeningBalanceUsdsp }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" maxlength="13" (blur)="onBond(rowdata)"
                                            (focusin)="onBond(rowdata)"
                                            (focusout)="onBond(rowdata)"
                                            (keydown.Tab)="onBond(rowdata)"
                                                [disabled]="rowdata.disablecontrols"
                                                [(ngModel)]="rowdata.OpeningBalanceRtgs"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
                                            </p-inputNumber>

                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowdata.OpeningBalanceRtgs }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" maxlength="13" (blur)="onBond(rowdata)"
                                            (focusin)="onBond(rowdata)"
                                            (focusout)="onBond(rowdata)"
                                            (keydown.Tab)="onBond(rowdata)"
                                                [disabled]="rowdata.disablecontrols"
                                                [(ngModel)]="rowdata.OpeningBalanceNostro"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
                                            </p-inputNumber>


                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowdata.OpeningBalanceNostro }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" maxlength="13" (blur)="onBond(rowdata)"
                                            (focusin)="onBond(rowdata)"
                                            (focusout)="onBond(rowdata)"
                                            (keydown.Tab)="onBond(rowdata)"
                                                [disabled]="rowdata.disablecontrols"
                                                [(ngModel)]="rowdata.OpeningBalanceZim1"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
                                            </p-inputNumber>


                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowdata.OpeningBalanceZim1  }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{ rowdata.Consolidatedinusd | number:'1.2-2' }}

                                </td>
                                <td>
                                    {{ rowdata.Status === 'MSC00001' ? 'Active' : rowdata.Status }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>