import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CApprovalbranchComponent } from './Approvalbranch/c-approvalbranch/c-approvalbranch.component';
import { VApprovalbranchComponent } from './Approvalbranch/v-approvalbranch/v-approvalbranch.component';
import { CApprovalconfigurationcorporateComponent } from './Approvalconfigurationcorporate/c-approvalconfigurationcorporate/c-approvalconfigurationcorporate.component';
import { VApprovalconfigurationcorporateComponent } from './Approvalconfigurationcorporate/v-approvalconfigurationcorporate/v-approvalconfigurationcorporate.component';
import { CApprovaltypeComponent } from './Approvaltype/c-approvaltype/c-approvaltype.component';
import { VApprovaltypeComponent } from './Approvaltype/v-approvaltype/v-approvaltype.component';
//rajesh
import { CChangePasswordComponent } from './change-password/c-change-password/c-change-password.component';
import { CRoleWiseScreenMappingComponent } from './role-wise-screen-mapping/c-role-wise-screen-mapping/c-role-wise-screen-mapping.component';
import { CRoleComponent } from './role/c-role/c-role.component';
import { VRoleComponent } from './role/v-role/v-role.component';
//rahul
import { CUserComponent } from './user/c-user/c-user.component';
import { VUserComponent } from './user/v-user/v-user.component';
import { CUsercustomermappingComponent } from './usercustomermapping/c-usercustomermapping/c-usercustomermapping.component';
//bharat
import { CUserscreenmappingComponent } from './userscreenmapping/c-userscreenmapping/c-userscreenmapping.component';
import { VUserscreenmappingComponent } from './userscreenmapping/v-userscreenmapping/v-userscreenmapping.component';
import { CWorkflowComponent } from './Workflow/c-workflow/c-workflow.component';


const routes: Routes = [
//rajesh
  {path:'cRole',component:CRoleComponent},
  {path:'vRole',component:VRoleComponent},
  {path:'cRoleWiseScreenWiseMapping',component:CRoleWiseScreenMappingComponent},
  {path:'cUserCustomerMapping',component: CUsercustomermappingComponent},
  {path:'cChangePassword',component:CChangePasswordComponent},
  //rahul
   {path:'cUser',component:CUserComponent},
  {path:'vUser',component:VUserComponent},
  //bharat
  {path:'cUserscreenmapping',component:CUserscreenmappingComponent},
 {path:'vUserscreenmapping',component:VUserscreenmappingComponent},

 {path:'cApprovaltype',component:CApprovaltypeComponent},
  {path:'vApprovaltype',component:VApprovaltypeComponent},
  {path:'cApprovalconfigurationcorporate',component:CApprovalconfigurationcorporateComponent},
  {path:'vApprovalconfigurationcorporate',component:VApprovalconfigurationcorporateComponent},
  {path:'cApprovalbranch',component:CApprovalbranchComponent},
  {path:'vApprovalbranch',component:VApprovalbranchComponent},
  {path:'cWorkflow',component:CWorkflowComponent}


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
