<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Container Tracking</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" type="button"
                                [disabled]="isDisabledSave" (click)="onSave()"></button>
                            <button pButton pRipple label="" type="button" title="Clear" icon="pi pi-trash"
                                (click)="reset($event)" [disabled]="isDisabledClear" class="p-button-danger"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-tabView [(activeIndex)]="selectedtab">
                    <p-tabPanel header="Tracking Detail">
                        <form [formGroup]="_containertrackingform" (ngSubmit)="onSave()">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-calendar [showIcon]="true" formControlName="containertrackingdate"
                                                [disabled]="isDisabledClear" dateFormat="dd/mm/yy"
                                                [monthNavigator]="true" [yearNavigator]="true" yearRange="2020:2030">
                                            </p-calendar>
                                            <label for="containertrackingdate">Date<span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_containertrackingform.get('containertrackingdate').touched || _submitted) && _containertrackingform.get('containertrackingdate').errors?.SelectDate">
                                            Please Select Date
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Status"
                                                formControlName="status" optionLabel="metasubdescription"
                                                optionValue="metasubcode">
                                            </p-dropdown>
                                            <label for="status">Status <span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td colspan="5">
                                        <div class="sub-title">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <h5>Process 1</h5>
                                                </div>
                                                <div class="col-md-7"></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="containerno">
                                            <label for="containerno">Container No.<span class="hlt-txt">*</span>
                                            </label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_containertrackingform.get('containerno').touched || _submitted) && _containertrackingform.get('containerno').errors?.ContainerNumberRequired">
                                            Please Enter Container No.
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="blno">
                                            <label for="blno">BL No. </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_supplierlist"
                                                formControlName="supplierid" optionLabel="suppliername"
                                                (onChange)="OnChangeSupplier($event)" [filter]="true"
                                                filterBy="nameandmobile" optionValue="supplierid">
                                            </p-dropdown>
                                            <label for="gender">Supplier Name <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_containertrackingform.get('supplierid').touched || _submitted) && _containertrackingform.get('supplierid').errors?.SupplierNameRequired">
                                            Please Select Supplier Name
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-multiSelect [options]="_PurchaseOrders" [disabled]="_action == 'view'"
                                                optionLabel="purchaseorderno" optionValue="purchaseorderno"
                                                [ngModelOptions]="{standalone: true}"
                                                (onChange)="onSelectreferenceno($event)"
                                                [(ngModel)]="_selectedpurchaseorderno"></p-multiSelect>
                                            <label for="pono">PO No. </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-calendar [showIcon]="true" formControlName="shippingdate"
                                                [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                                                yearRange="2020:2030">
                                            </p-calendar>
                                            <label for="shippingdate">Shipping Date</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_shippingLineList"
                                                formControlName="shippingline" optionLabel="shippinglinename"
                                                [filter]="true" filterBy="shippinglinename"
                                                optionValue="shippinglineid">
                                            </p-dropdown>
                                            <label for="shippingline">Shipping Line <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_containertrackingform.get('shippingline').touched || _submitted) && _containertrackingform.get('shippingline').errors?.ShippingLine">
                                            Please Select Shipping Line
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_portList"
                                                formControlName="portid" optionLabel="portname" optionValue="portid">
                                            </p-dropdown>
                                            <label for="portid">Port</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5">
                                        <div class="sub-title">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <h5>Process 2</h5>
                                                </div>
                                                <div class="col-md-7"></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_portAgentList"
                                                formControlName="portagent" optionLabel="suppliername" [filter]="true"
                                                filterBy="nameandmobile" (onChange)="OnPortAgentChange($event)"
                                                optionValue="supplierid">
                                            </p-dropdown>
                                            <label for="supplierid">Agent(port)</label>
                                        </span>
                                    </td>

                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-calendar [showIcon]="true" formControlName="portbldate"
                                                dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                                yearRange="2020:2030">
                                            </p-calendar>
                                            <label for="bldate">BL Date</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-calendar [showIcon]="true" formControlName="arraivaldate"
                                                dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                                yearRange="2020:2030">
                                            </p-calendar>
                                            <label for="arraivaldate">Arraival Date</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <textarea inputId="textarea" pInputTextarea
                                                formControlName="portremarks"></textarea>
                                            <label for="textarea">Remarks 1</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <textarea inputId="textarea" pInputTextarea
                                                formControlName="portremarks1"></textarea>
                                            <label for="textarea">Remarks 2</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5">
                                        <div class="sub-title">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <h5>Process 3</h5>
                                                </div>
                                                <div class="col-md-7"></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_borderAgentList"
                                                formControlName="borderagent" optionLabel="suppliername" [filter]="true"
                                                filterBy="nameandmobile" (onChange)="OnBordernameChange($event)"
                                                optionValue="supplierid">
                                            </p-dropdown>
                                            <label for="borderagent">Agent(Border)</label>
                                        </span>
                                    </td>
                                    <!-- <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_borderPaymentlist"
                                        formControlName="borderpaymentlink" optionLabel="name"
                                        optionValue="ledgerid">
                                    </p-dropdown>
                                    <label for="borderpaymentlink">J.V Link</label>
                                </span>
                            </td> -->
                                    <!-- <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-calendar [showIcon]="true" formControlName="borderdate"
                                                dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                                yearRange="2020:2030">
                                            </p-calendar>
                                            <label for="borderdate">BL Date</label>
                                        </span>
                                    </td> -->
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <textarea inputId="textarea" pInputTextarea
                                                formControlName="borderremarks"></textarea>
                                            <label for="textarea">Remarks 1</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <textarea inputId="textarea" pInputTextarea
                                                formControlName="borderremarks1"></textarea>
                                            <label for="textarea">Remarks 2</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5">
                                        <div class="sub-title">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <h5>Process 4</h5>
                                                </div>
                                                <div class="col-md-7"></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_localTransporterList"
                                                formControlName="localtransporter" optionLabel="suppliername"
                                                [filter]="true" filterBy="nameandmobile"
                                                (onChange)="OnLocalTranpoerterChange($event)" optionValue="supplierid">
                                            </p-dropdown>
                                            <label for="localtransporter">Local Transporter</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="transporterno">
                                            <label for="transporterno">Transporter No.<span class="hlt-txt">*</span>
                                            </label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_containertrackingform.get('transporterno').touched || _submitted) && _containertrackingform.get('transporterno').errors?.ContainerNumberRequired">
                                            Please Enter Transporter No.
                                        </span>
                                    </td>
                                    <!-- <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-calendar [showIcon]="true" formControlName="transporterdate"
                                                dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                                yearRange="2020:2030">
                                            </p-calendar>
                                            <label for="transporterdate">BL Date</label>
                                        </span>
                                    </td> -->
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <textarea inputId="textarea" pInputTextarea
                                                formControlName="transporterremarks"></textarea>
                                            <label for="transporterremarks">Remarks 1</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <textarea inputId="textarea" pInputTextarea
                                                formControlName="transporterremarks1"></textarea>
                                            <label for="transporterremarks">Remarks 2</label>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </p-tabPanel>
                    <p-tabPanel header="Document Detail">
                        <!-- <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Proof Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div> -->
                        <form [formGroup]="_ContainerDocumentDetailform">
                            <table class="normal-table">
                                <tr>
                                    <!-- <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="expenseamount" formControlName="expenseamount">
                                        <label for="expenseamount">Proof Value</label>
                                    </span>     SelectSupplierName
                                </td> -->
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_portAgentDetailList"
                                                formControlName="agentdocid" optionLabel="suppliername" [filter]="true"
                                                filterBy="nameandmobile" optionValue="supplierid">
                                            </p-dropdown>
                                            <label for="supplierid">Agent<span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(documentsubmitted || _ContainerDocumentDetailform.get('agentdocid').touched )&& _ContainerDocumentDetailform.get('agentdocid').errors?.SelectSupplierName">
                                            Please select agent
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_DocumentNames"
                                                formControlName="documentid" optionLabel="documentdescriptionname"
                                                [filter]="true" filterBy="documentdescriptionname"
                                                optionValue="documentdescriptionid">
                                            </p-dropdown>
                                            <label for="documentdescriptionid">Document Name<span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(documentsubmitted || _ContainerDocumentDetailform.get('documentid').touched )&& _ContainerDocumentDetailform.get('documentid').errors?.SelectDocumentName">
                                            Please select document name
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="remarks"
                                                formControlName="documentdescription">
                                            <label for="remarks">Description</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <div class="browse-links">
                                                    <p-button label="Link" styleClass="p-button-link"
                                                        (click)="showdocumentDialog(document,'bottom-right')">
                                                    </p-button>
                                                </div>
                                            </span>
                                            <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                                pTooltip="Browse File" tooltipPosition="top" mode="basic" #document
                                                accept="image/*,.pdf,.xlsx,.xls,.xlsm,.docx,.txt" maxFileSize="2147483648"
                                                [auto]="true" chooseLabel=""
                                                (onSelect)="onDocumentSelect($event,document)"
                                                [disabled]=" _action == 'view'">
                                            </p-fileUpload>
                                            <button class="p-inputgroup-addon p-button" pTooltip="Cancel"
                                                (click)="onDocumentClear()" tooltipPosition="top"
                                                [disabled]=" _action == 'view'">
                                                <i class="pi pi-times"></i>
                                            </button>
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <button pButton pRipple label="Add" icon="las la-check"
                                            (click)="onAddProof($event)" class="p-button-sm p-button-success"
                                            [disabled]=" _action == 'view'"></button>
                                        <button pButton pRipple label="Clear" icon="pi pi-times" class="p-button-danger"
                                            (click)="onRemoveProof($event)" [disabled]=" _action == 'view'"></button>
                                    </td>
                                    <td>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                        <div class="card">
                            <p-table #dt [value]="_Proofs" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[10,25,50,100]"
                                [globalFilterFields]="['attachmenturl','proofvalue','settlementaccount','remarks']"
                                [rowHover]="true" dataKey="attachmenturl" [(selection)]="selectedrows"
                                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 100px;text-align: center;">Action</th>
                                        <th>Agent Name</th>
                                        <th>Document Name</th>
                                        <th>Document Description</th>
                                        <th>Attachment Name</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_Proof let-ri="rowIndex">
                                    <tr>
                                        <td style="text-align:center">
                                            <button pButton pRipple label="" title="Remove" icon="pi pi-trash"
                                                [disabled]="_action == 'view'" (click)="RemoveProofDetail(_Proof)"
                                                class="p-button-danger p-mr-2">
                                            </button>
                                        </td>
                                        <td>{{_Proof.agentname}}</td>
                                        <td>{{_Proof.documentname}}</td>
                                        <td>{{_Proof.documentdescription}}</td>
                                        <td>
                                            <a href="javascript:void(0);"
                                                (click)="showdocumentGridDialog('bottom-right',_Proof)">{{_Proof.attachmentname}}</a>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
            <p-dialog header="Image Preview" [(visible)]="displayBasic1" [position]="position"
                [style]="{width: '500px'}" [baseZIndex]="10000">
                <img src="../../assets/images/chart03.jpg" alt="" class="img-preview">
                <button class="p-inputgroup-addon p-button" title="Delete">
                    <i class="pi pi-trash"></i>
                </button>
                <ng-template pTemplate="footer">
                    <p-button icon="pi pi-check" (click)="displayBasic=false" label="Ok" styleClass="p-button-text">
                    </p-button>
                </ng-template>
            </p-dialog>

            <p-dialog header="Tender Document" [(visible)]="displaydocumentgridpopup" [position]="position"
                [style]="{width: '500px'}" [baseZIndex]="10000">
                <img [src]="documentimage" alt="" class="img-preview">
                <ng-template pTemplate="footer">
                    <p-button icon="pi pi-check" (click)="closePopupGridDialog()" label="Ok" styleClass="p-button-text">
                    </p-button>
                </ng-template>
            </p-dialog>





            <!-- <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table> -->

        </div>

    </div>
</div>
<p-toast position="bottom-right"></p-toast>