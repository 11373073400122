import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Supplier } from 'src/app/core/Models/Supplier';
import { RptstockcountingService } from 'src/app/core/Services/reports/Stock/RptStockCounting/rptstockcounting.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
@Component({
  selector: 'app-rptstockcounting',
  templateUrl: './rptstockcounting.component.html',
  styleUrls: ['./rptstockcounting.component.css']
})
export class RptstockcountingComponent implements OnInit {
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  branchid: number[] = [];
  Brandid: number[] = [];
  _branchid: number;
  _userid: number;
  _branchlist: Branch[];
  _brandlist: any;
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _IsProgressSpinner: boolean = true;
  _ProductTypeObj: any;
  _src: SafeResourceUrl;
  _ProductCategoryObj: any;
  _StockCountPrintObj: any;
  IsStock: boolean = false;
  _supplierlist: Supplier[] = [];
  _supplierid: number = 0;
  constructor(
    private utility: CommonUtilities,
    private _RptStockCounting: RptstockcountingService,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;
    this._RptStockCounting.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._producttypelist = resultJSON.producttypeList;
      this._supplierlist = resultJSON.supplierList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = {
        "Producttype": selectedProductType || [],
        "branchid": parseInt(sessionStorage["currentbranchid"])
      };
      this._RptStockCounting.getProductCategories(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptStockCounting.getProducts(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  GetBrand(event) {
    var selectedProduct = event.value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._brandlist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      let brandobj = { "Products": selectedProduct || [] };
      this._RptStockCounting.GetBrand(brandobj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        let productbrand = resultJSON.productbrandlist;
        let uniqueChars = [...new Set(productbrand)];
        uniqueChars = productbrand.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.brandid === test.brandid
          ));
        this._brandlist = uniqueChars;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  clear() {
    this._productcategorylist = [];
    this._productlist = [];
    this.productcatid = [];
    this.producttypeid = [];
    this.productid = [];
    this.branchid = [];
    this.Brandid = [];
    this._brandlist = [];
    this._supplierid = 0;
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  GenerateReport(event) {
    if (this.branchid.length == 0) {
      this._CustomExceptionService.handleError("Please Select Atleast one Branch");
      return;
    }
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._StockCountPrintObj = {

      "ProducttypeIds": this.producttypeid || [],
      "ProductCategoryIds": this.productcatid || [],
      "ProductIds": this.productid || [],
      "BranchIds": this.branchid || [],
      "CurrentBranchId": parseInt(sessionStorage["currentbranchid"]),
      "IncludeStock": this.IsStock,
      "BrandIds": this.Brandid || [],
      "Supplierid": this._supplierid || 0
    };
    this._RptStockCounting.Print(this._StockCountPrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}