import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { ToolbarModule } from 'primeng/toolbar';
import { RatingModule } from 'primeng/rating';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChipsModule } from 'primeng/chips';
import { FieldsetModule, } from 'primeng/fieldset';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { HttpClientModule } from '@angular/common/http';
import { UsersRoutingModule } from './users-routing.module';

//rajesh
import { CRoleComponent } from './role/c-role/c-role.component';
import { VRoleComponent } from './role/v-role/v-role.component';
import { CRoleWiseScreenMappingComponent } from './role-wise-screen-mapping/c-role-wise-screen-mapping/c-role-wise-screen-mapping.component';
import { CChangePasswordComponent } from './change-password/c-change-password/c-change-password.component';

//bharat
import { CUserscreenmappingComponent } from './userscreenmapping/c-userscreenmapping/c-userscreenmapping.component';
import { VUserscreenmappingComponent } from './userscreenmapping/v-userscreenmapping/v-userscreenmapping.component';

//rahul
import { CUserComponent } from './user/c-user/c-user.component';
import { VUserComponent } from './user/v-user/v-user.component';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { CUsercustomermappingComponent } from './usercustomermapping/c-usercustomermapping/c-usercustomermapping.component';
import { CApprovaltypeComponent } from './Approvaltype/c-approvaltype/c-approvaltype.component';
import { VApprovaltypeComponent } from './Approvaltype/v-approvaltype/v-approvaltype.component';
import { CApprovalconfigurationcorporateComponent } from './Approvalconfigurationcorporate/c-approvalconfigurationcorporate/c-approvalconfigurationcorporate.component';
import { VApprovalconfigurationcorporateComponent } from './Approvalconfigurationcorporate/v-approvalconfigurationcorporate/v-approvalconfigurationcorporate.component';
import { CApprovalbranchComponent } from './Approvalbranch/c-approvalbranch/c-approvalbranch.component';
import { VApprovalbranchComponent } from './Approvalbranch/v-approvalbranch/v-approvalbranch.component';
import { CWorkflowComponent } from './Workflow/c-workflow/c-workflow.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [
    //rajesh
    CRoleComponent, VRoleComponent, CRoleWiseScreenMappingComponent, CUsercustomermappingComponent, CChangePasswordComponent,
    //bhrat
    CUserscreenmappingComponent, VUserscreenmappingComponent,
    //rahul
    CUserComponent, VUserComponent,
    CApprovaltypeComponent,
    VApprovaltypeComponent,
    CApprovalconfigurationcorporateComponent,
    VApprovalconfigurationcorporateComponent,
    CApprovalbranchComponent,
    VApprovalbranchComponent,
    CWorkflowComponent
  ],

  providers: [MessageService, ConfirmationService, CustomExceptionService, DatePipe],

  imports: [
    CommonModule,
    UsersRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChipsModule,
    FieldsetModule,
    AutoCompleteModule,
    OverlayPanelModule,
    CheckboxModule,
    PasswordModule,
    ProgressSpinnerModule
  ]
})
export class UsersModule { }
