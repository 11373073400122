import { Supplier } from 'src/app/core/Models/Supplier';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Port } from 'src/app/core/Models/Port';
import { vContainerNo, vDateSelect, vSupplierName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Producttype } from 'src/app/core/Models/Producttype';
import { MetadataMaster } from 'src/app/core/Models/overallclass';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import * as ace from "ace-builds";
import { RptsalesquotationsummaryService } from 'src/app/core/Services/reports/Sales/RptSalesQuotationSummary/rptsalesquotationsummary.service';
import { Customer } from 'src/app/core/Models/Customer';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-rptsalesquotationsummary',
  templateUrl: './rptsalesquotationsummary.component.html',
  styleUrls: ['./rptsalesquotationsummary.component.css']
})
export class RptsalesquotationsummaryComponent implements OnInit {

  _IsProgressSpinner: boolean = true;
  _supplierlist: Supplier[];
  _currencylist: Currency[];
  _invoicetypelist: Metadatum[];
  _branchlist: Branch[];
  _rptsalesquotationform: FormGroup;
  _branchid: number;
  branchid: number[] = [];
  _userid: number;
  _customerlist: Customer[];
  _src: SafeResourceUrl;
  _SalesQuotationPrintObj: any;
  customerid: number[] = [];
  currencyid: number[] = [];
  _quotationstatuslist: Metadatum[];
  _Action: IAction;
  _userlist: any[] = [];
  userid: any;
  mindate: Date;
  maxdate: Date;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _AccessRightsService: AccessRightsService,
    private _RptSalesQuotationSummaryService: RptsalesquotationsummaryService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(152);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptsalesquotationform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      currencyid: [],
      quotationstatusid: [0],
      customerid: [0]

    })
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = parseInt(sessionStorage["userid"]);
    // this.InitializeForm();
    this.branchid.push(this._branchid);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalesquotationform.get("fromdate").setValue(new Date);
      this._rptsalesquotationform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalesquotationform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalesquotationform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._rptsalesquotationform.controls['currencyid'].setValue(1);
    this._RptSalesQuotationSummaryService.PageOnload(this._branchid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._customerlist = resultJSON.customerList;
      this._quotationstatuslist = resultJSON.quotationStatus;
      this._userlist = resultJSON.userList;
      this._rptsalesquotationform.controls['branchid'].setValue(this._branchid);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  clear(event) {
    //25.If clear clicking all fields should clear in grid
    this._rptsalesquotationform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalesquotationform.get("fromdate").setValue(new Date);
      this._rptsalesquotationform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalesquotationform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalesquotationform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._rptsalesquotationform.controls['currencyid'].setValue(1);
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.customerid = this.branchid = [];
  }

  GenerateReport(event) {
    var _fromdate = this._rptsalesquotationform.get("fromdate").value;
    var _todate = this._rptsalesquotationform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var Quotationtype = this._rptsalesquotationform.get("quotationstatusid").value == null ? 0 : this._rptsalesquotationform.get("quotationstatusid").value;
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    if (this.branchid.length == 0) {
      this._CustomExceptionService.handleError("Please Select Branch");
      return;
    }
    var branchid = this._rptsalesquotationform.get("branchid").value == null ? 0 : this._rptsalesquotationform.get("branchid").value;
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this._SalesQuotationPrintObj = {

      "Branchid": this.branchid || [],
      "Customerid": this.customerid || [],
      "Currency": this._rptsalesquotationform.get("currencyid").value,
      "Userid": parseInt(sessionStorage["userid"]),
      "FromDate": fromdate,
      "ToDate": todate,
      "QuotationStatus": Quotationtype,
      "Branch": parseInt(sessionStorage["BranchID"]),
      "QuotationCreatedBy": this.userid || 0
    }
    this._RptSalesQuotationSummaryService.Print(this._SalesQuotationPrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

}
