import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { SalesorderService } from 'src/app/core/Services/sales/salesorder.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Salesorderview } from 'src/app/core/Views/Salesorderview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-sales-order',
  templateUrl: './v-sales-order.component.html',
  styleUrls: ['./v-sales-order.component.css']
})
export class VSalesOrderComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _Print: boolean;
  _Salesorderviews: Salesorderview[];
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["currentbranchid"];
  _roleid = sessionStorage["currentRole"];
  _IsProgressSpinner: boolean = true;
  cols: any[];
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  mindate: Date;
  maxdate: Date;
  constructor(private fb: FormBuilder, public exportUtility: ExportUtility, private _SalesOrderService: SalesorderService, private _CustomExceptionService: CustomExceptionService, private confirmationService: ConfirmationService, private _AccessRightsService: AccessRightsService, private router: Router, private utility: CommonUtilities, private _hotkeysService: HotkeysService) {

  }
  ngOnInit(): void {
    // 1. check in the user rights screen, for the login user if the Sales Order has access to add,then allow to open create page 
    // 85.check in the user rights screen, for the login user if the Sales Order has access to Update ,then allow to open Sales  page  
    this._Action = this._AccessRightsService.getAccessRights(54);
    this.utility.pageLoadScript();
    this.HotKeyIntegration();
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.cols = [
      { field: 'salesorderno', header: 'Sales Order No' },
      { field: 'salesorderdate', header: 'Sales Order Date' },
      { field: 'customername', header: 'Customer Name' },
      { field: 'currencyname', header: 'Currency' },
      { field: 'referenceno', header: 'Reference No' },
      { field: 'totalsalesorderamount', header: 'Sales Amount' },
      { field: 'approvalstatusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this.Fetchall();

  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._SalesOrderService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Salesorderviews = resultJSON.salesorderviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });


  }

  view(event) {
    this.router.navigate(['/cSalesOrder'], { state: { salesorderno: event.salesorderno, action: 'view' } });
  }


  edit(event) {
    if (event.approvalstatus == "MSC00045" || event.approvalstatus == null) {
      this.router.navigate(['/cSalesOrder'], { state: { salesorderno: event.salesorderno, action: 'edit' } });
    }
    else {
      this._CustomExceptionService.handleWarning("Approved Record Should not Update");
    }
  }

  GoToCreate(event) {
    this.router.navigate(['/cSalesOrder']);
  }
  exportExcel() {
    let SalesOrderList = this.GetSalesOrderList();
    this.exportUtility.exportExcel(SalesOrderList, 'SalesOrder', 'xlsx');
  }

  ExportToPdf() {
    let SalesOrderList = this.GetSalesOrderList();
    this.exportUtility.ExportToPdf(SalesOrderList, 'SalesOrder.pdf');
  }

  GetSalesOrderList() {
    if (!this._Salesorderviews || !this._Salesorderviews.length)
      return;
    let SalesOrderList = [];
    this._Salesorderviews.forEach(element => {
      let salesorder: any = {};
      salesorder["Sales Order No"] = element.salesorderno;
      salesorder["Sales Order Date"] = this.datepipe.transform(element.salesorderdate, 'dd/MM/yyyy');
      salesorder["Customer Name"] = element.customername;
      salesorder["Currency"] = element.currencyname;
      salesorder["Sales Amount"] = element.totalsalesorderamount;
      salesorder["Status"] = element.approvalstatusname;
      salesorder["Created By"] = element.createdbyname;
      salesorder["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      SalesOrderList.push(salesorder);
    });
    return SalesOrderList;
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Sales Order <b>"' + event.salesorderno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  //Cancel
  Cancel(event) {
    var salesorderno = event.salesorderno;
    this._IsProgressSpinner = true;
    this._SalesOrderService.Cancel(salesorderno).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.SalesOrderCancel);
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this.Fetchall();

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  Print(event) {
    var salesorderno = event.salesorderno;
    this._IsProgressSpinner = true;
    this._SalesOrderService.Print({ "sono": salesorderno, "branchid": this._branchid }).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    },
      
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }



  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  //this._productid= this._damagedproductpricedetailsform.get("productid").setValue(this._damagedproductpricedetailsform.get("productid").value);
  SendMail(event) {

    var quoteno = event.purchaseorderno;
    this._userid = sessionStorage["userid"];
    this._branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;
    this._SalesOrderService.SendMail(this._userid, this._branchid, quoteno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && resultJSON) {
        this._CustomExceptionService.handleSuccess(usererrors.MailSent_Success);
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);

      }
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }



}
