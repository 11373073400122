import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Level } from 'src/app/core/Models/Level';
import { LevelService } from 'src/app/core/Services/masters/level.service';
import { Levelview } from 'src/app/core/Views/Levelview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-level',
  templateUrl: './v-level.component.html',
  styleUrls: ['./v-level.component.css']
})
export class VLevelComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _levelsview: Levelview[]=[];
  _levelview: Levelview;
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  _levelid: number;
  _userid = sessionStorage["userid"];
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _LevelService: LevelService,
    private router: Router,
    private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,
    private exportUtility: ExportUtility
  ) {
    this._Action = this._AccessRightsService.getAccessRights(23);
    this.HotKeyIntegration();
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;
    this._LevelService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._levelsview = resultJSON.levelviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) { }
  onRowUnselect(event) { }
  view(event) {
    this.router.navigate(['/cLevel'], { state: { levelid: event.levelid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cLevel']);
  }
  delete(event) {
    alert("delete");
  }
  edit(event) {
    this.router.navigate(['/cLevel'], { state: { levelid: event.levelid, action: 'edit' } });
  }
  confirm(event) {
    this._levelid = event.levelid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Level <b>"' + event.levelname + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._LevelService.Cancel(this._levelid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_03);
        this._LevelService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._levelsview = resultJSON.levelviews;
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'Level', 'xlsx');
  }
  ExportToPdf() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.ExportToPdf(AssetRegistrationList, 'Level.pdf');
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._levelsview.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Level Name"] = element.levelname;
      assetregistration["Status"] = element.levelstatusname;
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
}