export class DamagedStockDetail {
    damagestockid: number
    branchid: number
    productid: number
    variantid: number
    lotnumber: number
    parentlotnumber: number
    damagedstock: number
    severity: string
    supplierid: number
    expirydate: Date
    createdby: number
    createdon: Date
    modifiedby: number
    modifiedon: Date
}