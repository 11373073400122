import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';

import { elementAt } from 'rxjs/operators';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Customer } from 'src/app/core/Models/Customer';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Salesinvoice } from 'src/app/core/Models/Salesinvoicepos';
import { Salesreturndetailview } from 'src/app/core/Models/SalesReturn';
import { Salesreturnproductdetailview } from 'src/app/core/Models/Salesreturnproductdetails';
import { Tax } from 'src/app/core/Models/Tax';
import { DirectsalesreturnService } from 'src/app/core/Services/sales/directsalesreturn.service';
import { vDateSelect, vCustomerName, vCurrencySelect } from 'src/app/core/Validators/validation';
import { Customerview } from 'src/app/core/Views/Customerview';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { KeyPress } from 'src/assets/js/KeyPress';

@Component({
  selector: 'app-c-directsalesreturn',
  templateUrl: './c-directsalesreturn.component.html',
  styleUrls: ['./c-directsalesreturn.component.css']
})
export class CDirectsalesreturnComponent implements OnInit {
  //Form Creation
  _salesreturnform: FormGroup;
  //Drop Down
  _ProductNames: Productoverallview[] = [];
  _Status: Metadatum;
  //Grid
  _Salesreturn: Salesreturndetailview;
  _ProductDetails: Salesreturnproductdetailview[] = [];
  _OtherCharges: Othercharge[] = [];
  _Descriptions: Othercharge[] = [];
  _ChargesTemp: Othercharge[] = [];
  salesreturnothercharges: Othercharge[] = [];
  _Invoicedetails: Salesinvoice[] = [];

  _uomlist: Metadatum[] = [];
  _taxlist: Tax[] = [];
  _currencylist: Currency[] = [];

  //Local Variable:
  _OtherBalance: number;
  displayBasic: boolean;
  minDate: Date;
  selectedProductDetailsRows: any;
  _selectedlevel: number;
  selectedOtherChargesRows: any;
  _salesinvoices: string[];
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"];
  _action: string = "create";
  _SalesreturnObj: any;
  _salesreturnid: string;
  _submitted = false;
  _customersubmitted = false;
  isDisabledsave: boolean;
  isDisablededit: boolean;
  isDisabledreturnqty = false;
  isDisabledothercharges = false;
  isDisabledreset = false;
  isDisabledadd = false;
  isDisabledremove = false;
  _IsProgressSpinner: boolean = true;
  _validate: boolean;
  _Validatereason: boolean;
  isDisabledSendtoapproval = true;
  _salesreturnno: string;
  _Reasons: Metadatum;
  _Customers: Customerview[];
  filteredInvoices: any;
  _Invoicenos: string;
  salesinvoiceno: any;
  salesinvoiceobj: any;

  currentbranchname = sessionStorage["currentbranchname"];

  //Debug
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities,
    private _CustomExceptionService: CustomExceptionService, private _SalesReturnService: DirectsalesreturnService,
    private _hotkeysService: HotkeysService, public keypress: KeyPress) {
    this.HotKeyIntegration();
  }
  InitializeSalesreturnForm() {
    this._salesreturnform = this.fb.group({
      companyid: [0],
      branchid: [],
      invoicebranchid: [],
      invoicebranchname: [],
      salesreturnid: [0],
      salesreturndate: ['', vDateSelect],
      salesinvoiceno: ['', vCustomerName],
      salesinvoiceid: [],
      customerid: ['', vCustomerName],
      customername: [],
      currencyid: [],
      currencyname: [],
      salesreturnamount: [],
      salesreturngrossamount: [],
      salesreturndeductionamount: [],
      salesreturnrewardpoints: [],
      status: [],
      statusremarks: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      salesreturnno: [],
      salereturntype: [],
      series: [],
      currentcurrencyid: ['', vCurrencySelect],
      taxamount : [0],
      discountamount : [0],
      salesinvoiceamount : [0]
    });
    this._salesreturnform.controls['salesreturndate'].setValue(new Date());
    this._salesreturnform.get("invoicebranchname").setValue(this.currentbranchname);
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeSalesreturnForm();
    this._salesreturnform.get("invoicebranchname").setValue(this.currentbranchname);
    //Initialize 
    this._salesreturnid = history.state?.salesreturnno ? history.state?.salesreturnno : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._IsProgressSpinner = true;
    this._SalesReturnService.PageOnLoad(this._salesreturnid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._salesreturnform.controls['salesreturndate'].setValue(new Date());
      // this._Invoicedetails = resultJSON.invoicenoview;
      this._Descriptions = resultJSON.othercharges;
      this._OtherCharges = resultJSON.othercharges;
      this._ChargesTemp = resultJSON.othercharges;
      this._Reasons = resultJSON.returnReasonList;
      this._Customers = resultJSON.customers;
      this._uomlist = resultJSON.uomlist;
      this._taxlist = resultJSON.taxlist;
      this._currencylist = resultJSON.currencylist;
      this._IsProgressSpinner = false;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._Invoicedetails = resultJSON.invoicenoview;
        updateJSON.salesreturndetailviews[0].salesreturndate = new Date(updateJSON.salesreturndetailviews[0].salesreturndate);
        this._salesreturnform.setValue(updateJSON.salesreturndetailviews[0]);
        this.salesinvoiceobj = (this._Invoicedetails || []).find(f => f.salesinvoiceno == updateJSON.salesreturndetailviews[0].salesinvoiceno);
        // this._salesreturnform.controls['salesinvoiceid'].setValue(updateJSON.salesreturndetailviews[0].salesinvoiceid);
        this.salesreturnothercharges = updateJSON.salesreturnothercharges;
        this._ProductDetails = updateJSON.salesreturnproductdetailviews;
        this.CalculateotherCharge();
        this._IsProgressSpinner = false;
      } else {
        let product: Salesreturnproductdetailview = new Salesreturnproductdetailview();
        this._ProductDetails.push(product);
        var l_othercharge = new Othercharge();
        l_othercharge.otherchargeid = 0;
        l_othercharge.otherchargeamount = 0;
        l_othercharge.createdby = sessionStorage["userid"];
        l_othercharge.modifiedby = sessionStorage["userid"];
        l_othercharge.createdon = new Date();
        l_othercharge.modifiedon = new Date();
        this.salesreturnothercharges.push(l_othercharge);
      }

      if (this._action == 'view') {
        this._salesreturnform.disable();
        this.isDisabledsave = true;
        this.isDisabledreturnqty = true;
        this.isDisabledothercharges = true;
        this.isDisabledreset = true;
        this.isDisabledadd = true;
        this.isDisabledremove = true;
        this._IsProgressSpinner = false;
      }
      else {
        this.isDisabledsave = false;
        this.isDisablededit = false;
      }

      if (this._action == 'edit') {
        this.isDisablededit = true;
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onCustomerNameChange(event) {
    let customerid = event.value;
    let customer = this._Customers.find(f => f.customerid == customerid);
    if (customer) {
      this._salesreturnform.get("currentcurrencyid").setValue(customer.currencyid);
    }
  }


  filterInvoices(event) {
    let filtered: any[] = [];
    let query = (<string>event.query).toLowerCase();
    this.filteredInvoices = this._Invoicedetails.filter(f => f.salesinvoiceno.toLowerCase().indexOf(query) > -1) || [];
  }

  //Other Charges:
  AddOtherCharges(event) {
    let otherChargesExists = (this.salesreturnothercharges.filter(s => s.otherchargeid > 0).length == this.salesreturnothercharges.length) && this.salesreturnothercharges.some(s => s.otherchargeid > 0);
    if (!otherChargesExists)
      return;
    var l_othercharge = new Othercharge();
    l_othercharge.otherchargeid = 0;
    l_othercharge.otherchargeamount = 0;
    l_othercharge.createdby = sessionStorage["userid"];
    l_othercharge.modifiedby = sessionStorage["userid"];
    l_othercharge.createdon = new Date();
    l_othercharge.modifiedon = new Date();
    this.salesreturnothercharges.push(l_othercharge);
  }

  //Remove Other Charges:
  RemoveOtherCharges(pOthercharges: Othercharge) {
    // if (this.salesreturnothercharges.length > 1) {
    var index = this.salesreturnothercharges.indexOf(pOthercharges);
    this.salesreturnothercharges.splice(index, 1)
    this.CalculateotherCharge();
    if (this.salesreturnothercharges.length <= 0) {
      var l_othercharge = new Othercharge();
      l_othercharge.otherchargeid = 0;
      l_othercharge.otherchargeamount = 0;
      l_othercharge.createdby = sessionStorage["userid"];
      l_othercharge.modifiedby = sessionStorage["userid"];
      l_othercharge.createdon = new Date();
      l_othercharge.modifiedon = new Date();
      this.salesreturnothercharges.push(l_othercharge);

    }
  }
  // }
  OnChangeotherCharge(row: any, event) {
    let OtherChargesAmount = row.otherchargeamount;
    this._salesreturnform.controls['salesreturndeductionamount'].setValue(OtherChargesAmount.toFixed(3));
    this.CalculateotherCharge();
  }
  //Rule18:if invoice no dropdown selected value from sales ordertable then sales orderno column  assigned to referno  in grid based on invoiceno selection
  OnchangeOtherCharge(pOtherCharges: Othercharge, event: any) {

    let isAlreadyExists = this.salesreturnothercharges.filter(s => s.otherchargeid == event.value).length > 1;
    if (isAlreadyExists) {
      this._CustomExceptionService.handleWarning("Other Charges Already Exists!");
      var index = this.salesreturnothercharges.indexOf(pOtherCharges);
      this.salesreturnothercharges.splice(index, 1)
      return;
    }
    var otherchargeamount = (this._ChargesTemp || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount;
    pOtherCharges.otherchargeamount = otherchargeamount;
    this.CalculateotherCharge();
  }

  // Return Quantity Based Calculation:
  allocatequantity(row: Salesreturnproductdetailview) {
    let isError = false;
    let invoiceqty = row.invoicequantity;
    let returnqty = row.returnquantity;
    let invoiceamount = row.totalamount;
    row.salesinvoiceno = this._salesreturnform.get("salesinvoiceno").value;
    let taxPer = 0;
    let tax = this._taxlist.find(f => f.taxid == row.taxid);
    if (tax) {
      taxPer = tax.taxpercentage;
    }
    row.taxamount = 0;
    row.taxpercentage = 0;
    row.productamount = 0;

    if (!invoiceqty || !returnqty || !invoiceamount)
      return;


    let singleqtyAmount = (invoiceamount / invoiceqty);
    let returnqtyAmount = (singleqtyAmount * returnqty);
    let taxAmount = (returnqtyAmount * (taxPer / 100))
    row.unitprice = singleqtyAmount;
    row.taxamount = taxAmount;
    row.taxpercentage = taxPer;
    row.productamount = (returnqtyAmount + taxAmount);
    row.createdby = sessionStorage["userid"];
    row.createdon = new Date();
    this.CalculateotherCharge();
  }

  CalculateotherCharge() {
    this._OtherBalance = 0;
    // this.salesreturnothercharges.every((item) => {
    for (var i = 0; i < this.salesreturnothercharges.length; i++) {
      this._OtherBalance += this.salesreturnothercharges[i].otherchargeamount;
    }
    this._salesreturnform.controls['salesreturndeductionamount'].setValue(this._OtherBalance.toFixed(3));
    // });
    var _FinalTotalAmount = 0
    // this._ProductDetails.every((item) => {
    for (var i = 0; i < this._ProductDetails.length; i++) {
      _FinalTotalAmount += this._ProductDetails[i].productamount;
    }
    this._salesreturnform.controls['salesreturngrossamount'].setValue(_FinalTotalAmount.toFixed(3));
    var salesreturndeductionamount = this._salesreturnform.get("salesreturndeductionamount").value;
    var salesreturnamount = parseFloat(_FinalTotalAmount + '') + parseFloat(salesreturndeductionamount + '');
    this._salesreturnform.controls['salesreturnamount'].setValue(salesreturnamount.toFixed(3));
    // });
  }
  //Sales Order Save
  onSave() {
    this._submitted = true;
    if (this._salesreturnform.valid && !this.ValidateOnSave()) {
      // this.validate();
      //For Model and Form Mapping
      this._Salesreturn = this._salesreturnform.value;
      // if (this._validate == true) {
      // if (this._Validatereason == true) {
      var ReturnQuantity = this._ProductDetails.some(s => s.returnquantity > 0);
      if (!ReturnQuantity) {
        this._CustomExceptionService.handleError(usererrors.SalesReturnQuantity);
        return;
      }
      var ReturnReason = this._ProductDetails.some(s => s.returnquantity > 0 && !s.returnreason);
      if (ReturnReason) {
        this._CustomExceptionService.handleError(usererrors.SalesReturnReason);
        return;
      }
      this._SalesreturnObj = { "SalesReturn": this._Salesreturn, "SalesReturndetails": this._ProductDetails, "SalesReturnothercharges": this.salesreturnothercharges };
      if (this._action == 'create') {

        this._Salesreturn.companyid = 0;
        this._Salesreturn.createdby = sessionStorage["userid"];
        this._Salesreturn.createdon = new Date();
        this._Salesreturn.accountingyear = new Date().getFullYear();
        this._Salesreturn.branchid = sessionStorage["currentbranchid"];
        this._Salesreturn.status = "MSC00045";
        var RequestDate = this._salesreturnform.get("salesreturndate").value;
        var datePipe = new DatePipe("en-US");
        var pRequestDate = datePipe.transform(RequestDate, 'yyyy-MM-dd');
        this._Salesreturn.salesreturndate = (new Date(pRequestDate));
        // this.onEd();
        this._IsProgressSpinner = true;
        this._SalesReturnService.create(this._SalesreturnObj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));

          //Rule46:after saving the record, get the response A, display the success message as "sales return A  registered Succesfully "
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            // this.SendToApproval();
            this._IsProgressSpinner = false;
            this.isDisabledSendtoapproval = false;
            this.isDisabledsave = true;
            this._salesreturnno = resultJSON.salesreturnno;
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          } this._IsProgressSpinner = false;
        },

          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }

  ValidateOnSave() {
    let isGridInValid = (this._ProductDetails.some(s => s.productid == null || s.productid <= 0) && this._ProductDetails.length == 1);
    let isProductInValid = this._ProductDetails.some(s => s.productid == null || s.productid <= 0);
    let isInvoiceQtyInValid = this._ProductDetails.some(s => s.invoicequantity == null || s.invoicequantity <= 0);
    let isReturnQtyInValid = this._ProductDetails.some(s => s.returnquantity == null || s.returnquantity <= 0);
    let isUOMInValid = this._ProductDetails.some(s => s.uomcode == null || s.uomcode == "");
    let isReturnReasonInValid = this._ProductDetails.some(s => s.returnreason == null || s.returnreason == "");
    let isProductAmountInValid = this._ProductDetails.some(s => s.productamount == null || s.productamount <= 0);

    let isError = false;

    if (isGridInValid) {
      isError = true;
      this._CustomExceptionService.handleWarning("Add atleast one product in the grid.");
    } else {
      if (isProductInValid) {
        isError = true;
        this._CustomExceptionService.handleWarning("Enter Product name in the grid.");
      }
      if (isInvoiceQtyInValid) {
        isError = true;
        this._CustomExceptionService.handleWarning("Enter Invoice Qty in the grid.");
      }
      if (isReturnQtyInValid) {
        isError = true;
        this._CustomExceptionService.handleWarning("Enter Return Qty in the grid.");
      }
      if (isUOMInValid) {
        isError = true;
        this._CustomExceptionService.handleWarning("Enter UOM in the grid.");
      }
      if (isReturnReasonInValid) {
        isError = true;
        this._CustomExceptionService.handleWarning("Enter Return Reason in the grid.");
      }
      if (isProductAmountInValid) {
        isError = true;
        this._CustomExceptionService.handleWarning("Product Amount should be calculated.");
      }
    }

    return isError;
  }


  // on clicking back button , it Navigates to the search screen
  goBack(event) {
    this._router.navigate(['/vDirectSalesReturn']);
  }

  reset(event) {
    this._submitted = true;
    this._salesreturnform.reset();
    this.InitializeSalesreturnForm();
    this._submitted = false;
    this._ProductDetails = [];

    let product: Salesreturnproductdetailview = new Salesreturnproductdetailview();
    this._ProductDetails.push(product);

    this.salesreturnothercharges = [];
    var l_othercharge = new Othercharge();
    l_othercharge.otherchargeid = 0;
    l_othercharge.otherchargeamount = 0;
    l_othercharge.createdby = sessionStorage["userid"];
    l_othercharge.modifiedby = sessionStorage["userid"];
    l_othercharge.createdon = new Date();
    l_othercharge.modifiedon = new Date();
    this.salesreturnothercharges.push(l_othercharge);
    this.isDisabledsave = false;
    this.isDisabledSendtoapproval = true;
    this.salesinvoiceobj = [];
  }

  // SendToApproval() {
  //   var workflowid = 57;
  //   this._SalesReturnService.SendToApproval(workflowid, this._userid, this._branchid, this._salesreturnid).subscribe((result) => {
  //     const resultJSON = JSON.parse(JSON.stringify(result));
  //   });
  // }

  SendToApproval(event) {
    this._IsProgressSpinner = true;
    this._SalesReturnService.SendToApproval(this._userid, this._branchid, this._salesreturnno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
      this.isDisabledSendtoapproval = true;
      this.isDisabledsave = false;
      this.reset(null);
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  OnProductSelect(product: Productoverallview, ngModel: Productoverallview) {
    let isProductExists = this._ProductDetails.some(s => s.productid == product.productid);
    if (!isProductExists) {
      ngModel.productid = product.productid;
      ngModel.productname = product.productname;
    } else {
      ngModel.productid = 0;
      ngModel.productname = '';
      this._CustomExceptionService.handleWarning("Product already exists in the grid.");
    }
  }

  OnProductClear(ngModel: Productoverallview) {
    ngModel.productid = 0;
  }

  filterProducts(event: { query: string; }, ngModel) {
    if (event.query == "" || event.query == undefined) {
      return;
    }
    else {
      this._IsProgressSpinner = true;
      // if(event.query){
      this._SalesReturnService.GetProduct(event.query, this._branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._ProductNames = <Productoverallview[]>resultJSON.productsearchlist || [];

        let filtered: any[] = [];
        let query = (<string>event.query).toLowerCase();
        ngModel.filteredProducts = this._ProductNames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

    }
  }

  AddProductToGrid() {
    let product: Salesreturnproductdetailview = new Salesreturnproductdetailview();
    this._ProductDetails.push(product);
  }

  RemoveProductFromGrid(index: number) {
    if (this._ProductDetails.length <= 1)
      return;

    this._ProductDetails.splice(index, 1);
  }


  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

}
