export const usererrors = {
  production: false,
  logged_Success_00: 'Logged in Successfully',
  Logged_failure_01: 'Please enter valid UserName and Password',
  Logged_failure_02: 'Password should be greated 8 characters',
  Saved_Success_00: 'Saved Successfully',
  Update_Success_04: 'Updated Succesfully',
  Cancel_Success_00: 'Cancelled Succesfully',
  Sendtoapproval_Success_05: 'Send to Approved Successfully',
  Password_Success_00: 'Password changed Successfully',
  MailSent_Success: 'Mail Sent Successfully',
  MailSent_failure: 'Mail not Sent',

  Grid_Select_00: 'Please Set atleast one Markuplevel',
  Grid_Select_02: 'Please Set atleast one Product Details',
  Grid_Select_01: 'Damaged Unit Price should not be greater than Unit Price',
  Grid_Select_03: 'Product Name Already Added',
  Grid_Select_04: 'Actual Stock is empty',
  Grid_Select_05: 'Delivery Quantity is empty',
  Grid_Select_06: 'No changes to save',
  Password_Validate: 'New Password And Retype Password Should Be Same',
  Approved_01: 'Approved Succesfully',
  Rejected_01: 'Rejected Success Fully',

  Cancel_Success_01: 'deactivated  succesfully',
  Customeraddress01: 'Please add atleast one row in customer address',
  Customeraddress02: 'Choose a default address in customer address details.',
  Customeraddress03: 'Please fill  customer address details tab.',
  Customeraddress04: 'Please fill Customer account detail tab.',
  Customeraddress05: 'Please fill customer details tab.',

  //Supplier
  SupplierDetails_Tab: 'Please fill supplier details Tab',
  SupplierAccountDetails_Tab: 'Please fill supplier account details Tab',
  SupplierAddressDetails_Tab:
    'Please add at least one row in Supplier Address details Tab',
  SupplierAddressDetails_01:
    'Choose a default address in Supplier Address details',
  VATNumber: 'Please Enter VAT number',
  CreditLimitAndDays: 'Please enter credit limit and credit days',
  SuppilerDeactive: 'Supplier Deactivated Successfully',

  //Tax
  TaxNotEditable: 'System-generated tax should not be editable.',
  TaxDeactive: 'Tax Deactivated Successfully',
  TaxNotDeactive: 'System-generated tax should not be deactivated',

  //Tender
  Tender01: 'Please fill all the tender history.',

  //Employee
  EmployeeDetails_Tab: 'Please fill employee details Tab',
  EmployeeAccountDetails_Tab: 'Please fill employee account details Tab',
  EmployeeDeactive: 'Employee deactivated successfully',

  //Product
  StuffedQuantity: 'Please enter Stuffed Quantity',
  MasterInactive_01: 'Numbering Schema deactivated successfully',
  MasterInactive_02: 'Port deactivated successfully',
  MasterInactive_03: 'Level deactivated successfully',
  MasterInactive_04: 'City deactivated successfully',
  MasterInactive_05: 'Currency deactivated successfully',
  MasterInactive_06: 'Product Type deactivated successfully',
  MasterInactive_07: 'Product Category deactivated successfully',
  MasterInactive_08: 'Bank deactivated successfully',
  MasterInactive_09: 'Country deactivated successfully',
  MasterInactive_10: 'User deactivated successfully',
  MasterInactive_11: 'Role  deactivated successfully',
  MasterInactive_12: 'Approval Type deactivated successfully',
  MasterInactive_13: 'Approval Branch deactivated successfully',
  MasterInactive_14: 'Branch deactivated successfully',
  MasterInactive_15: 'State deactivated successfully',
  MasterInactive_16: 'Customer deactivated successfully',

  //Vehicle
  VehicleDeactive: 'Vehicle deactivated successfully',

  //Ouote finalize
  Quote_Approval_01: 'Finalized Quote has been sent for approval',
  //Request for quote
  RequestForQuote_Cancel: 'Request for quote cancelled successfully',

  AccessrightsView: 'You are not authorized view this information',

  containertracking: 'Document has been cancelled successfully',
  //Stock transfer order
  ProductDetails: 'Please add at least one row in product details',
  StockCheck: 'Quantity should not greater than stock',
  StockTransferOrderSendToApproval:
    'Stock transfer order send to approved successfully',

  //Branch
  BranchDetails_Tab: 'Please fill branch details Tab',
  BranchAccountDetails_Tab: 'Please fill branch account details Tab',
  Rptfromtodate: 'From Date Cannot be Greater Than To Date',
  Rptfromdate: 'Please Select From Date',
  BranchAccountdetails_Tab:
    'Please add at least one row in Branch Account details Tab',
  BranchAccountdetails_01: 'Choose a default address in Branch Account details',

  //Damaged Product Stock
  DamagedProductStockSendToApproval:
    'Damaged product stock send to approved successfully',
  //Stock Adjustment
  StockAdjustmentSendToApproval:
    'Stock adjustment send to approved successfully',

  Schmemaxiandmini: 'Minimum quantity should be less than Maximum Quantity',
  //Stock Allocation
  StockProductDetails: 'Quantity should not greater than stock',

  slabdetails: 'Slab details already exists',
  POreturncancel: 'Purchase return cancelled succesfully',
  //Sales Quotation
  SalesQuotationDetails_Tab: 'Please fill sales quotation details Tab',
  ProductDetails_Tab: 'Please fill product details Tab',
  SalesQuotationSendToApproval: 'Sales quotation send to approved successfully',
  SalesQuotationCancel: 'Sales quotation cancelled successfully',

  //Sales Orders
  SalesOrderDetails_Tab: 'Please fill sales order details Tab',
  SalesOrderSendToApproval: 'Sales order send to approved successfully',
  SalesOrderCancel: 'Sales order cancelled successfully',
  salesinvoicetaxtab: 'Please fill sales invoice details Tab',
  salesinvoicetaxpayabletab: 'Please fill sales invoice payable Tab',

  //SALESINVOICE
  paidamount: 'Paid amount should not less than net amount',
  alreadyexists: 'Other Charges Already Exists',
  //Sales Return
  SalesReturnQuantity: 'Please enter return quantity',
  SalesReturnReason: 'Please select return reason',
  SalesReturnCancel: 'Sales return cancelled successfully',

  //GRN
  GRNReceivedQuantity: 'Please enter received quantity',
  //Product Price Change
  ProductPriceChangeSendToApproval:
    'Product price change send to approved successfully',
  //Stock Delivry
  StockDeliveryCancel: 'Stock delivery cancelled successfully',
  SendEmail_Success: 'Your Message Send Successfully',
  DeliveryNoteSendToApproval: 'Delivery Note send to approved successfully',
  //Slider Management
  Deleted_Success_00: 'Deleted Successfully',
};
