<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Stock Transfer Order</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple title="Save" type="submit" [disabled]="isDisabledSave" (click)="onSave()" icon="pi pi-save"></button>
                            <button pButton pRipple title="Clear" icon="pi pi-trash" class="p-button-danger" [disabled]="isDisabledClear" (click)="reset($event)"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button" (click)="SendToApproval($event)" [disabled]="isDisabledSendtoapproval"></button>
                            <!-- 78. on clicking back button , it Navigates to the search screen -->
                            <button pButton pRiple icon="pi pi-search" title="Back to Search" (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_stocktransferorderform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="stocktransferno" formControlName="stocktransferno" disabled
                                        pInputText>
                                    <label for="stocktransferno">Request No</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="orderdate" formControlName="orderdate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="orderdate">Request Date <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 33.if Request date is not given , show error "Please Enter Request date " -->
                                <span class="text-danger" *ngIf="(_submitted || _stocktransferorderform.get('orderdate').touched) && _stocktransferorderform.get('orderdate').errors?.SelectRequestDate">
                                    Please select request date
                                </span>
                            </td>

                            <td style="width: 20%;">
                                <form [formGroup]="_stocktransferform">
                                    <span class="p-float-label">
                                        <input type="text" name="requestperson" formControlName="requestperson" disabled
                                            pInputText>
                                        <label for="requestperson">Request Person</label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <!-- <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_StockTransferTypes"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="stocktransfertype">
                                    </p-dropdown>
                                    <label for="stocktransfertype">Stock Transfer Type <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _stocktransferorderform.get('stocktransfertype').touched) && _stocktransferorderform.get('stocktransfertype').errors?.SelectStockTransferType">
                                    Please Select Stock Transfer Type
                                </span>
                            </td> -->
                            <td>
                                <form [formGroup]="_stocktransferform">
                                    <span class="p-float-label">
                                        <input type="text" name="fromstore" formControlName="fromstore" disabled
                                            pInputText>
                                        <label for="fromstore">Current Branch</label>
                                    </span>
                                </form>

                            </td>
                            <td>
                                <form [formGroup]="_stocktransferorderdetailform">
                                    <span class="p-float-label">
                                        <input type="number" name="currentbranchstock"
                                            formControlName="currentbranchstock" disabled pInputText>
                                        <label for="currentbranchstock">Current Branch Stock </label>
                                    </span>
                                </form>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Tostores"
                                        (onChange)="getBranch($event)" optionLabel="branchname" optionValue="branchid"
                                        formControlName="tobranchid">
                                    </p-dropdown>
                                    <label for="tobranchid">Request Branch <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 34. if 'To Store'  is not given , show error "Please Select To Store" -->
                                <span class="text-danger" *ngIf="(_submitted || _stocktransferorderform.get('tobranchid').touched) && _stocktransferorderform.get('tobranchid').errors?.SelectToStore">
                                    Please select Request Branch
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_stocktransferorderdetailform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_ProductTypes"
                                        optionLabel="producttypename" optionValue="producttypeid"
                                        formControlName="producttypeid" (onChange)="getProductcategory($event)"
                                        displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type </label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted1 || _stocktransferorderdetailform.get('producttypeid').touched) && _stocktransferorderdetailform.get('producttypeid').errors?.SelectProductType">
                                    Please Select Atleast One Product Type
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_ProductCategories"
                                        optionLabel="categoryname" optionValue="productcategoryid"
                                        [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}"
                                        (onChange)="getProductname($event)" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category </label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted1 || _stocktransferorderdetailform.get('productcategoryid').touched) && _stocktransferorderdetailform.get('productcategoryid').errors?.SelectProductCategory">
                                    Please Select Atleast One Product Category
                                </span> -->

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_ProductNames"
                                        optionLabel="productname" optionValue="productid" formControlName="productid"
                                        [filter]="true" filterBy="searchfilter" [showClear]="true"
                                        (onChange)="getProductVariants($event)">
                                    </p-dropdown>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span> -->
                                <p-autoComplete formControlName="productid" [suggestions]="filteredProducts" (completeMethod)="filterProducts($event)" field="productname" (onSelect)="getProductVariants($event)">
                                    <ng-template let-product pTemplate="item">
                                        <div>{{product.productname}}</div>
                                    </ng-template>
                                </p-autoComplete>
                                <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted1 || _stocktransferorderdetailform.get('productid').touched) && _stocktransferorderdetailform.get('productid').errors?.SelectProductName">
                                    Please select product name
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Varients"
                                        optionLabel="variantdescription" optionValue="productvariantdetailid"
                                        formControlName="variantid" (onChange)="getProductstock($event)">
                                    </p-dropdown>
                                    <label for="variantid">Variant </label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted1 || _stocktransferorderdetailform.get('variantid').touched) && _stocktransferorderdetailform.get('variantid').errors?.SelectVariantName">
                                    Please Select Variant
                                </span> -->
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" name="stock" formControlName="stock" disabled pInputText>
                                    <label for="stock">Request Branch Stock </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-inputNumber *ngIf="hidequantity == true" formControlName="requestquantity"
                                        autocomplete="off" maxlength="13" (keypress)="keypress.kpNumber($event)"
                                        (keyup)="CheckQuantity($event)" mode="decimal" [minFractionDigits]="0"
                                        [maxFractionDigits]="0">
                                    </p-inputNumber>
                                    <label *ngIf="hidequantity == true" for="quantity">Quantity </label>
                                </span>

                                <span class="p-float-label">
                                    <p-inputNumber *ngIf="hidequantity != true" formControlName="requestquantity"
                                        autocomplete="off" maxlength="13" (keypress)="keypress.kpNumber($event)"
                                        (keyup)="CheckQuantity($event)" mode="decimal" [minFractionDigits]="3"
                                        [maxFractionDigits]="3">
                                    </p-inputNumber>
                                    <label *ngIf="hidequantity != true" for="quantity"> Quantity <span
                                            class="hlt-txt">*</span>
                                </label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Add" type="button" icon="pi pi-plus" class="p-button-success" (click)="AddProduct($event)" [disabled]="isDisabledadd"></button>
                                <button pButton pRipple label="Clear" title="Clear" type="button" (click)="Clear($event)" icon="pi pi-trash" class="p-button-danger" [disabled]="isDisabledproductclear"></button>
                            </td>
                    </table>
                </form>
                <div class="card">
                    <p-table [value]="_Productdetails" [rows]="50" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['productid','variantid','requestquantity','uomcode']" [rowHover]="true" dataKey="stocktransferorderdetailid" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="SelectedProductDetailsrows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 100px;">Action</th>
                                <th>Product Name</th>
                                <th>Variant</th>
                                <th>Quantity</th>
                                <th>UOM</th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_productdetail>
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple title="Remove" icon="pi pi-trash" class="p-button-danger p-mr-2" [disabled]="isDisabledremove" (click)="RemoveProduct(_productdetail)"></button>
                                </td>
                                <td>
                                    {{_productdetail.productname}}
                                </td>
                                <td>

                                    {{_productdetail.variantdescription}}

                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" (onBlur)="onchangereceivedquantity(_productdetail,$event)" *ngIf="_productdetail.uomcode != 'MSC00039'&& _action != 'view' " (keyup)="onchangereceivedquantity(_productdetail,$event)" (keyup.enter)="onchangereceivedquantity(_productdetail,$event)"
                                                (Keydown.enter)="onchangereceivedquantity(_productdetail,$event)" (focusout)="onchangereceivedquantity(_productdetail,$event)" maxlength="14" (keypress)="keypress.kpNumber($event)" [(ngModel)]="_productdetail.requestquantity"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                            </p-inputNumber>
                                            <p-inputNumber autocomplete="off" (onBlur)="onchangereceivedquantity(_productdetail,$event)" *ngIf="_productdetail.uomcode == 'MSC00039'&& _action != 'view' " (keyup)="onchangereceivedquantity(_productdetail,$event)" (keyup.enter)="onchangereceivedquantity(_productdetail,$event)"
                                                (Keydown.enter)="onchangereceivedquantity(_productdetail,$event)" (focusout)="onchangereceivedquantity(_productdetail,$event)" maxlength="14" (keypress)="keypress.kpNumber($event)" [(ngModel)]="_productdetail.requestquantity"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                            </p-inputNumber>
                                            <!-- <input type="text" [(ngModel)]="_productdetail.requestquantity" pInputText *ngIf="_action != 'view'" (keyup)="allocatequantity(_productdetail,$event)" (focusout)="allocatequantity(_productdetail,$event)" maxlength="7" (keypress)="keypress.kpNumber($event)"> -->
                                            <div *ngIf="_action == 'view'">{{_productdetail.requestquantity}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_productdetail.requestquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td>

                                    {{_productdetail.uomname}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

                <!-- <table class="normal-table">

                    <tr>
                        <td>
                            <button pButton pRipple label="Send To Approval" type="button" (click)="SendToApproval($event)"
                            [disabled]="isDisabledSendtoapproval"  icon="las la-check"></button>
                        </td>
                    </tr>
                </table> -->
                <table>
                    <td>
                        <!-- Debug -->
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </table>

                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>