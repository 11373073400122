export class Serviceproductdeliverydetail {
    serviceproductdeliverydetailid: number;
    serviceproductdeliveryid: number;
    productid: number;
    uom: string;
    unitprice: number;
    finalamount: number;
    srquantity: number;
    deliveryquantity: number;

}