import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Branch } from 'src/app/core/Models/Branch';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Myorderhistorydetail } from 'src/app/core/Models/Myorderhistorydetails';
import { Myordershistory } from 'src/app/core/Models/Orderstatus';
import { OrderstatusService } from 'src/app/core/Services/ecommerce/orderstatus.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { DatePipe } from '@angular/common';
import { Orderstatusemaillog } from 'src/app/core/Models/Orderstatusemaillog';
import { Orderstatuslog } from 'src/app/core/Models/Orderstatuslog';
import { Myordershistoryheader } from 'src/app/core/Models/Myorderhistoryheader';
import { ConfirmationService } from 'primeng/api';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
import { Router } from '@angular/router';

@Component({
  selector: 'app-c-orderstatus',
  templateUrl: './c-orderstatus.component.html',
  styleUrls: ['./c-orderstatus.component.css']
})
export class COrderstatusComponent implements OnInit {
  _Myordershistoryform: FormGroup;
  _Myordershistoryheaderform: FormGroup;
  _Myordershistorydemoform: FormGroup;
  disabled: boolean = true;
  _Orderid: number
  appBranchList = [];
  MyOrderHistory: Myordershistory;
  Emaillog: Orderstatusemaillog
  TempemaillogList: Orderstatusemaillog[] = [];
  orderstatusGrid: any[];
  statushistoryGrid: Myorderhistorydetail[];
  TempHistoryList: Myorderhistorydetail[] = [];
  _action: string = "view";
  _Branch: Branch
  _Status: any[];
  _SubStatus: Metadatum[];
  _MyorderDetails: any;
  _MyorderDetails1: any;
  StatusHistoryDetails: any;
  StatusHistoryDetailsDemo: any;
  _OrderStatusOBJ: any;
  _userid = sessionStorage["userid"];
  _Desccription: Metadatum
  _Myordershistory: any
  description: number;
  Templogstatus: Orderstatuslog[] = []
  substatusforEmail: string
  Statusid: any
  checkorderstatus: any
  previousstatus: any[] = []
  _IsProgressSpinner: boolean = true;
  _GenerateshipmentObj: any;
  _shipmentList: any[] = [];
  _IsDisabledSavestatus: boolean = false;
  _IsDisabledProcessedbutton: boolean = false;
  _selectedOrderIDs: number[] = [];
  disabledshipment: boolean = false;
  disabledshipmentID: boolean = true;
  MyOrderHistoryHeaderList: any[];
  _AssignedTo: any[];
  _Employees: any[];
  _ServiceProviders: any[];
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  Shipmenttypelst: any[] = [];
  _OrderStausUpdateList: any[] = [];
  _Shipmentidgenerateform: FormGroup;
  _MyOrderHistoryHeader: Myordershistoryheader;
  IsEmployee: boolean = true;
  IsServiceProvider: boolean = false;
  colsorderstatus: any[];
  colsstatushistory: any[];
  datepipe = new DatePipe('en-Us');
  StatusHistoryDetailsTemp: any[]=[];
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private confirmationService: ConfirmationService, private _OrderService: OrderstatusService, private _MailService: OrderstatusService,
    private _CustomExceptionService: CustomExceptionService, public exportUtility: ExportUtility,private _router: Router) {
    this._Orderid = history.state?.orderno ? history.state?.orderno : 0;
    this._action = history.state.action == null ? 'view' : history.state.action;
  }
  InitializeForm() {
    this._Myordershistoryform = this.fb.group({
      orderid: [],
      orderno: [],
      orderdate: [],
      customerid: [],
      currencyid: [],
      currencyreferenceid: [],
      billingaddress: [],
      deliveryaddress: [],
      mobileno: [],
      productid: [],
      brandid: [],
      variantid: [],
      quantity: [],
      unitprice: [],
      taxid: [],
      taxpercentage: [],
      taxamount: [],
      grossamount: [],
      discountpercentage: [],
      discountamount: [],
      netamount: [],
      paymentstatus: [],
      modeofpayment: [],
      orderstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      sellerid: [],
      sellername: [],
      deliverydate: [],
      shipmentno: [],
      shippingaddresscustomername: [],
      deliverymode: [],
      assigneeid: [],
      assigneename: [],
      erporderstatus: [],
      location: [],
      locationid: [],
      paymentstatusname: [],
      currencyname: [],
      paymentname: [],
      orderstatusname: [],
      modeofpaymentname: [],
      deliverymodename: [],
      productname: [],
      variantname: [],
      brandname: [],
      mailid: [],
      salesinvoiceid: [],
      salesinvoiceno: [],
      shippingcharges: [],
      isreconciled: [],
      isamountcollected: [],
      attachmenturl: [],
      attachmentname: [],
      cancelquantity:[],
      orderquantity:[],
      totalquantity:[],
      

      // assignee:[]
    });
    this._Myordershistoryheaderform = this.fb.group({
      orderheaderid: [],
      orderno: [],
      grossamount: [],
      taxamount: [],
      netamount: [],
      discountamount: [],
      shippingchargesamount: [],
      invoiceamount: [],
      recordstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      couponcode: [],
      customerid: [],
      orderdate: [],
      currencyid: [],
      orderstatus: [],
      shipmenttype: [],
      paymentmode: [],
      paymentstatus: [],
      paymentconfirmationstatus: [],
      isreconciled: [],
      paymentcollectedby: [],
      paymentcollecteddate: [],
      salesinvoiceid: [],
      salesinvoiceno: [],
      isselfpickup: [],
      branchid: [],
      totalcouponamount:[]

    })
    this._Shipmentidgenerateform = this.fb.group({
      shipmenttype: [],
      items: [],
      shipmentno: []
    });
    this._Myordershistorydemoform = this.fb.group({
      totalitem: [],
      ordertotal: [],
      ordersubstatus: [],
      statusdetail: [],
      ordertime: [],
      assigneetostatus: [],
      orderdate: [],

    });
    this._Myordershistoryform.controls["assigneename"].setValue(sessionStorage["username"]);
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();

    this.PageonLoad();
  }
  PageonLoad() {
    if(!this._Orderid||this._Orderid==0){
      this._router.navigate(['/vOrderStatus']);
      return;
    }
    this._IsProgressSpinner = true;
    this.colsorderstatus = [
      { field: 'orderid', header: 'Order ID' },
      { field: 'productname', header: 'Product Name' },
      { field: 'orderquantity', header: 'Ordered Quantity' },
      { field: 'cancelquantity', header: 'Cancelled Quantity' },
      { field: 'totalquantity', header: 'Total Quantity' },
      { field: 'unitprice', header: 'Unit Price' },
      { field: 'taxamount', header: 'Tax Amount' },
      { field: 'discountamount', header: 'Discount Amount' },
      { field: 'shippingcharges', header: 'Shipping Charges' },
      { field: 'netamount', header: 'Total Amount' },
      { field: 'shipmentno', header: 'Shipment No' },
      { field: 'orderstatusname', header: 'Order Status' },
    ]
    this.colsstatushistory = [
      { field: 'shipmentno', header: 'Shipment No' },
      { field: 'modifiedon', header: 'Order Date' },
      { field: 'orderstatusname', header: 'Status' },
      { field: 'ordersubstatusname', header: 'Sub Status' },
      { field: 'modifiedbyname', header: 'Staff' }
    ]
  
    this._OrderService.ViewPageload(this._Orderid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this._Branch = resultJSON.branchlst
      this._Status = resultJSON.orderstatuseclst;
      this._SubStatus = resultJSON.orderstatuserplst;
      if (this._action == 'edit' || this._action == 'view') {
        this._selectedOrderIDs = [] = [];
        const updateJSON = JSON.parse(JSON.stringify(result));
        let myorderhistorieslist;

        myorderhistorieslist = updateJSON.myordershistorieslst[0] || {};
        this._Employees = resultJSON.employeelist;
        this._ServiceProviders = resultJSON.deliveryservice
        this._AssignedTo = resultJSON.assignedto;
        this._Myordershistorydemoform.controls['assigneetostatus'].setValue("ECO00054");
        this.ChangeAssignedTo();

        let orderdate = myorderhistorieslist.orderdate;
        var datePipe = new DatePipe("en-US");
        var peffectiveto = datePipe.transform(orderdate, 'HH:mm:ss a');
        this._Myordershistorydemoform.controls['ordertime'].setValue(peffectiveto);
        this._Myordershistorydemoform.controls['orderdate'].setValue(new Date(myorderhistorieslist.orderdate));
        this._Myordershistoryform.setValue(myorderhistorieslist);
        this._Myordershistoryform.get("orderstatus").reset();
        this._Myordershistoryform.get("orderstatus").disable();
        this._Myordershistoryform.get("deliveryaddress").disable();
        this._Myordershistorydemoform.get("ordersubstatus").reset();
        this._Myordershistorydemoform.get("ordersubstatus").disable();

        this.checkorderstatus = updateJSON.myordershistorieslst[0].orderstatus
        // console.log("check", this.checkorderstatus);
        this._Myordershistory = updateJSON.myordershistorieslst[0];
        this._MyorderDetails = updateJSON.myordershistorieslst;
        this._MyorderDetails1 = (updateJSON.myordershistorieslst||[]);
        this._MyorderDetails1=(this._MyorderDetails1||[]).filter(f=>f.totalquantity>0)

        if (updateJSON.myordershistoryheader != null) {
          this._Shipmentidgenerateform.controls["shipmenttype"].setValue(updateJSON.myordershistoryheader[0].shipmenttype);
          this.MyOrderHistoryHeaderList = updateJSON.myordershistoryheader;
          this._Myordershistoryheaderform.setValue(updateJSON.myordershistoryheader[0]);
          if (updateJSON.myordershistoryheader[0].shipmenttype != null) {
            this.disabledshipment = true;
            this.disabledshipmentID = false;
          }
        }
        this.StatusHistoryDetails = updateJSON.orderstatusloghistory;
        this.StatusHistoryDetailsTemp = updateJSON.orderstatusloghistorytemp;
        this.StatusHistoryDetailsDemo = updateJSON.orderstatuserpviews;
        this.Shipmenttypelst = updateJSON.shipmenttypelst;
        this.Statusid = this._Myordershistoryform.get("orderstatus").value
        this._OrderStausUpdateList = updateJSON.orderstatuslst


        this._Myordershistorydemoform.controls['totalitem'].setValue(updateJSON.myordershistorieslst.length);

        this.UpdateOrderStatus();
        var totalamount = 0;
        let orderstatus = ["ECO00034", "ECO00035"]
        for (var i = 0; i < this._MyorderDetails.length; i++) {
          if (this._MyorderDetails[i].orderid && this._MyorderDetails[i].shipmentno) {
            this._MyorderDetails[i].disabled = true;
            this._shipmentList = [] = [];
            let myorderdetails = this._MyorderDetails
            myorderdetails = myorderdetails.filter(f => !orderstatus.includes(f.orderstatus));
            //Group by shipment no
            let shipmentlist: any[] = [];
            shipmentlist = this.groupByType(myorderdetails);
            let values = Object.keys(shipmentlist);
            values = values.filter(f => f != "null")
            for (let i = 0; i < values.length; i++) {

              this._shipmentList.push({ shipmentno: values[i] });
            }

          } else {
            this._MyorderDetails[i].disabled = false;
          }
          if (this._MyorderDetails[i].shipmentno) {

          }

          totalamount += this._MyorderDetails[i].netamount;
          this._Myordershistorydemoform.get("ordertotal").setValue(parseFloat(totalamount.toFixed(2)));
        }
      }

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  CheckOrderStatus() {

    var shipmentno = this._Shipmentidgenerateform.get("shipmentno").value || null;
    let ordersubGroup = ['ECO00014', 'ECO00039', 'ECO00017'];
    let shipmentlist = this._OrderStausUpdateList.filter(f => !ordersubGroup.includes(f.ordersubstatus));
    let isselfpickup = (this.MyOrderHistoryHeaderList || [])[0].isselfpickup == true;
    //if (this._Shipmentidgenerateform.get("shipmentno").value) {
      shipmentlist = (shipmentlist || []).filter(f => f.shipmentno == shipmentno);
    //}

    let orderstatus = shipmentlist.some(f => f.orderstatus == "ECO00001" && f.ordersubstatus == "ECO00015");
    if (!orderstatus) {
      this._Myordershistoryform.controls['orderstatus'].setValue("ECO00001");
      this._Myordershistorydemoform.controls['ordersubstatus'].setValue("ECO00015");
      this.StatusEnableOrDisable("ECO00001");
      return;
    }

    orderstatus = shipmentlist.some(f => f.orderstatus == "ECO00003" && f.ordersubstatus == "ECO00015");
    if (!orderstatus) {
      this._Myordershistoryform.controls['orderstatus'].setValue("ECO00003");
      this._Myordershistorydemoform.controls['ordersubstatus'].setValue("ECO00014");
      this.StatusEnableOrDisable("ECO00003");
      return;
    }

    orderstatus = shipmentlist.some(f => f.orderstatus == "ECO00004" && f.ordersubstatus == "ECO00015");
    if (!orderstatus && !isselfpickup) {
      this._Myordershistoryform.controls['orderstatus'].setValue("ECO00004");
      this._Myordershistorydemoform.controls['ordersubstatus'].setValue("ECO00014");
      this.StatusEnableOrDisable("ECO00004");
      return;
    }

    orderstatus = shipmentlist.some(f => f.orderstatus == "ECO00006" && f.ordersubstatus == "ECO00015");
    if (!orderstatus) {
      this._Myordershistoryform.controls['orderstatus'].setValue("ECO00006");
      this._Myordershistorydemoform.controls['ordersubstatus'].setValue("ECO00014");
      this.StatusEnableOrDisable("ECO00006");
      return;
    }

    orderstatus = shipmentlist.some(f => f.orderstatus == "ECO00006" && f.ordersubstatus == "ECO00015");
    if (orderstatus) {
      this._Myordershistoryform.controls['orderstatus'].setValue("ECO00006");
      this._Myordershistorydemoform.controls['ordersubstatus'].setValue("ECO00015");
      this.StatusEnableOrDisable("ECO00006");
      this._Myordershistoryform.get("orderstatus").disable();
      this._Myordershistorydemoform.get("ordersubstatus").disable();
      this._IsDisabledSavestatus = true;
      return;
    }
  }

  CheckOrderStatus1() {

    if (this._Shipmentidgenerateform.get("shipmentno").value) {
      //Single order based
      var shipmentno = this._Shipmentidgenerateform.get("shipmentno").value;
      // let orderstatus = ["ECO00034", "ECO00035"]
      let shipmentlist = this._OrderStausUpdateList.filter(f => f.shipmentno == shipmentno)
      // shipmentlist = shipmentlist.filter(f => orderstatus.includes(f.orderstatus))
      // let returnandcancel = (shipmentlist || []).some(f => orderstatus.includes(f.orderstatus));
      // let returnandcancel
      // for(let i=0;i<shipmentlist.length;i++){
      //   if(shipmentlist[i].orderstatus=="ECO00034"||shipmentlist[i].orderstatus=="ECO00035"){
      //     returnandcancel=true
      //   }else{
      //     returnandcancel=false
      //   }

      // }
      // if (returnandcancel) {
      //   this._Myordershistoryform.get("orderstatus").disable();
      //   this._Myordershistorydemoform.get("ordersubstatus").disable();
      //   this._IsDisabledSavestatus = true;
      //   return;
      // }
      let shipmentcompleted = shipmentlist.some(s => s.orderstatus == "ECO00006" && s.ordersubstatus == "ECO00015")
      if (shipmentcompleted) {
        this._Myordershistoryform.controls['orderstatus'].setValue("ECO00006");
        this._Myordershistorydemoform.controls['ordersubstatus'].setValue("ECO00015");
        this._Myordershistoryform.get("orderstatus").disable();
        this._Myordershistorydemoform.get("ordersubstatus").disable();
        this._IsDisabledSavestatus = true;
        return;

      }

      let OrderStausUpdateList = this._OrderStausUpdateList.filter(f => f.ordersubstatus !== "ECO00015");


      for (let i = 0; i < OrderStausUpdateList.length; i++) {

        //Cancel and return status update
        if (OrderStausUpdateList[i].orderstatus == "ECO00034" || OrderStausUpdateList[i].orderstatus == "ECO00035") {
          var orderstatuscode = OrderStausUpdateList.filter(f => f.shipmentno == shipmentno)[0].orderstatus
          var metasubid = this._Status.filter(f => f.metasubcode == orderstatuscode)[0].metasubid
          var metasubcode = this._Status.filter(f => f.metasubid == metasubid)[0].metasubcode
          this._Myordershistoryform.controls['orderstatus'].setValue(metasubcode);
          this._Myordershistorydemoform.controls['ordersubstatus'].setValue(OrderStausUpdateList[i].ordersubstatus);
          this.Statusid = metasubcode;
          this.StatusEnableOrDisable();

        }
        if (OrderStausUpdateList[i].orderstatus && OrderStausUpdateList[i].ordersubstatus == "ECO00015") {
          var orderstatuscode = OrderStausUpdateList.filter(f => f.shipmentno == shipmentno)[0].orderstatus
          var metasubid = this._Status.filter(f => f.metasubcode == orderstatuscode)[0].metasubid
          var metasubcode = this._Status.filter(f => f.metasubid == metasubid + 1)[0].metasubcode
          this._Myordershistoryform.controls['orderstatus'].setValue(metasubcode);
          this._Myordershistorydemoform.controls['ordersubstatus'].setValue("ECO00014");
          this.Statusid = metasubcode;
          this.StatusEnableOrDisable();

        } else {
          // let orderstatus = ["ECO00034", "ECO00035"]
          // let shipmentlist = this._OrderStausUpdateList.filter(f => f.shipmentno == shipmentno)
          // shipmentlist = shipmentlist.filter(f => orderstatus.includes(f.orderstatus))
          // let returnandcancel = (shipmentlist || []).some(f => orderstatus.includes(f.orderstatus));
          // if (returnandcancel) {
          //   this._Myordershistoryform.get("orderstatus").disable();
          //   this._Myordershistorydemoform.get("ordersubstatus").disable();
          //   this._IsDisabledSavestatus = true;
          //   return;
          // }
          let shipmentcompleted = shipmentlist.some(s => s.orderstatus == "ECO00006" && s.ordersubstatus == "ECO00015")
          if (shipmentcompleted) {
            this._Myordershistoryform.controls['orderstatus'].setValue("ECO00006");
            this._Myordershistorydemoform.controls['ordersubstatus'].setValue("ECO00015");
            this._Myordershistoryform.get("orderstatus").disable();
            this._Myordershistorydemoform.get("ordersubstatus").disable();
            this._IsDisabledSavestatus = true;

          } else {


            //  let SeparateShipmentNo = this._OrderStausUpdateList.filter(f => f.shipmentno == shipmentno)
            var orderstatuscode = OrderStausUpdateList.filter(f => f.shipmentno == shipmentno)[0].orderstatus
            var metasubid = this._Status.filter(f => f.metasubcode == orderstatuscode)[0].metasubid
            var metasubcode = this._Status.filter(f => f.metasubid == metasubid)[0].metasubcode
            this._Myordershistoryform.controls['orderstatus'].setValue(metasubcode);
            this._Myordershistorydemoform.controls['ordersubstatus'].setValue(OrderStausUpdateList[i].ordersubstatus);
            this.Statusid = metasubcode;
            this.StatusEnableOrDisable();
          }
        }
      }

    } else {

      for (let i = 0; i < this._OrderStausUpdateList.length; i++) {
        //Cancel and return status update
        if (this._OrderStausUpdateList[i].orderstatus == "ECO00034" || this._OrderStausUpdateList[i].orderstatus == "ECO00035") {
          var metasubid = this._Status.filter(f => f.metasubcode == this._OrderStausUpdateList[i].orderstatus)[0].metasubid
          var metasubcode = this._Status.filter(f => f.metasubid == metasubid)[0].metasubcode
          this._Myordershistoryform.controls['orderstatus'].setValue(metasubcode);
          this._Myordershistorydemoform.controls['ordersubstatus'].setValue(this._OrderStausUpdateList[i].ordersubstatus);
          this.Statusid = metasubcode;
          this.StatusEnableOrDisable();

        }
        if (this._OrderStausUpdateList[i].shipmentno == null || this._OrderStausUpdateList[i].shipmentno == undefined) {

          //Take completed metasubcode
          if (this._OrderStausUpdateList[i].orderstatus && this._OrderStausUpdateList[i].ordersubstatus == "ECO00015") {
            var metasubid = this._Status.filter(f => f.metasubcode == this._OrderStausUpdateList[i].orderstatus)[0].metasubid
            var metasubcode = this._Status.filter(f => f.metasubid == metasubid + 1)[0].metasubcode
            this._Myordershistoryform.controls['orderstatus'].setValue(metasubcode);
            this._Myordershistorydemoform.controls['ordersubstatus'].setValue("ECO00014");
            this.Statusid = metasubcode;
            this.StatusEnableOrDisable();
            return;

          } else {
            var metasubid = this._Status.filter(f => f.metasubcode == this._OrderStausUpdateList[i].orderstatus)[0].metasubid
            var metasubcode = this._Status.filter(f => f.metasubid == metasubid)[0].metasubcode
            this._Myordershistoryform.controls['orderstatus'].setValue(metasubcode);
            this._Myordershistorydemoform.controls['ordersubstatus'].setValue(this._OrderStausUpdateList[i].ordersubstatus);
            this.Statusid = metasubcode;
            this.StatusEnableOrDisable();
            return;
          }

        }
      }

    }

  }
  UpdateOrderStatus() {
    if (this._selectedOrderIDs.length > 0) {
      this._Myordershistoryform.get("orderstatus").enable();
      this._Myordershistorydemoform.get("ordersubstatus").enable();
      this._Shipmentidgenerateform.get("shipmentno").disable();
      this._Shipmentidgenerateform.get("shipmentno").reset();
      this._IsDisabledSavestatus = false;
      this.CheckOrderStatus();

    } else {
      this._Myordershistoryform.get("orderstatus").reset();
      this._Myordershistoryform.get("orderstatus").disable();
      this._Shipmentidgenerateform.get("shipmentno").enable();
      this._Myordershistorydemoform.get("ordersubstatus").reset();
      this._Myordershistorydemoform.get("ordersubstatus").disable();
      this._IsDisabledSavestatus = false;
    }
    if (this._Shipmentidgenerateform.get("shipmentno").value) {
      this._Myordershistoryform.get("orderstatus").enable();
      this._Myordershistorydemoform.get("ordersubstatus").enable();
      this._Shipmentidgenerateform.get("shipmentno").enable();
      this._IsDisabledSavestatus = false;
      this.CheckOrderStatus();
    }
  }
  //Status disable & enable
  StatusEnableOrDisable(metaSubCode?: string) {
    for (let j = 0; j < this._Status.length; j++) {
      if (metaSubCode == this._Status[j].metasubcode) {
        this._Status[j].disabled = false;

      } else {
        this._Status[j].disabled = true;
      }
    }
  }
  // "ECO00032"
  OnSelectShipmentType(event) {
    // _selectedProductIDs
    var ProductID: number[] = [];

    for (var i = 0; i < this._MyorderDetails.length; i++) {
      if (event.value == "ECO00032") {
        this._MyorderDetails[i].disabled = true;
        ProductID.push(this._MyorderDetails[i].productid);
        this._selectedOrderIDs = ProductID;
        this.UpdateOrderStatus();
      }
      else {
        this._Shipmentidgenerateform.get("items").reset();
        this._selectedOrderIDs = [] = [];
        this._MyorderDetails[i].disabled = false;
        this.UpdateOrderStatus();
      }
    }


  }

  groupByType(array) {
    return array.reduce((r, a) => {
      r[a.shipmentno] = r[a.shipmentno] || [];
      r[a.shipmentno].push(a);
      // console.log("GroupbyValue", r);
      return r;
    }, Object.create(null));
  }
  OnselectShipmentNo(event) {
    var shipmentno = event.value;
    this.UpdateOrderStatus();


  }
  Generate() {
    //var SubStatus = event.value;
    let Status: any
    Status = this._Shipmentidgenerateform.getRawValue();
    var shipmenttype = this._Shipmentidgenerateform.get("shipmenttype").value;
    var items = this._Shipmentidgenerateform.get("items").value;

    // console.log("sta", Status)
    this._IsProgressSpinner = true;
    this._GenerateshipmentObj = { "shipmenttype": shipmenttype, "items": items }
    this._OrderService.GenerateShippmentID(this._GenerateshipmentObj).subscribe((result) => {
      const resutJSON = JSON.parse(JSON.stringify(result));
      var items = this._Shipmentidgenerateform.get("items").value;
      var count = items.length
      this._CustomExceptionService.handleSuccess(count + " Products moved to Packed");
      this._IsProgressSpinner = false;
      setTimeout(() => {
        this.PageonLoad();
      }, 5000)

      // console.log("des", resutJSON);
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  GetDescription(event) {
    var SubStatus = event.value
    var Status = this._Myordershistoryform.get("orderstatus").value;
    // console.log("sta", Status)
    this._IsProgressSpinner = true;
    this._OrderService.GetDescription(Status, SubStatus).subscribe((result) => {
      const resutJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      // console.log("des", resutJSON);
      this._Desccription = resutJSON.orderstatusdescheaders
      this._Myordershistorydemoform.controls['statusdetail'].setValue(resutJSON.orderstatusdescheaders[0].osdid);

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  Changestatus() {

    this._Myordershistorydemoform.controls["ordersubstatus"].reset();
    // this._Myordershistorydemoform.controls["statusdetail"].reset();
    this.Statusid = this._Myordershistoryform.get("orderstatus").value
    // this.Statusid= this._Status.filter(f => f.metasubcode == 'ECO00015')[0].metasubcode
  }
  Update() {

    if (this._IsDisabledSavestatus) return;

    var assignedtostatus = this._Myordershistorydemoform.get("assigneetostatus").value
    if (assignedtostatus == "ECO00054") {
      let assignedid = this._Myordershistoryform.get("assigneeid").value;
      if (!assignedid) {
        this._CustomExceptionService.handleError("Please Select Employee")
        return;
      }
    } else if (assignedtostatus == "ECO00055") {
      let assignedid = this._Myordershistoryform.get("assigneeid").value;
      let assigneename = this._Myordershistoryform.get("assigneename").value;
      if (!assigneename) {
        this._CustomExceptionService.handleError("Please Enter PickUp Person Name");
        return;
      }
      if (!assignedid) {
        this._CustomExceptionService.handleError("Please Select Service Provider");
        return;
      }
    }

    var ordersubstatus = this._Myordershistorydemoform.get("ordersubstatus").value;
    var shipmentno = this._Shipmentidgenerateform.get("shipmentno").value || "";
    var orderstatus = this._Myordershistoryform.get("orderstatus").value;
    if (!orderstatus) {
      this._CustomExceptionService.handleError("Please Select Order Status");
      return;
    }
    if (!ordersubstatus) {
      this._CustomExceptionService.handleError("Please Select Order Sub Status");
      return;
    }
    let orderstatusname = this._Status.filter(f => f.metasubcode == orderstatus)[0].metasubdescription;
    if (shipmentno != "") {
      //Check Order status Already Exists
      let orderdetails = this.StatusHistoryDetails.filter(f => f.shipmentno == shipmentno)
      for (let i = 0; i < orderdetails.length; i++) {
        if (orderdetails[i].orderstatuscode == orderstatus && orderdetails[i].ordersubstatuscode == ordersubstatus) {
          this._CustomExceptionService.handleError("Order Status already Exists");
          return;
        }
      }
    }
    if (ordersubstatus == "ECO00015") {
      this.confirmationService.confirm({
        message: 'Are You Sure to update ' + "<strong>" + orderstatusname + "</strong>" + ' status to complete?',
        accept: () => {
          this.AcceptOrderstaus();
          // }
        },
        reject: () => {
        }
      });
    } else {
      this.AcceptOrderstaus();
    }


  }
  AcceptOrderstaus() {
    this.Templogstatus=[];  
    let OrderstatusMDs = ["ECO00034", "ECO00035"];
    var ordersubstatus = this._Myordershistorydemoform.get("ordersubstatus").value;
    var shipmentno = this._Shipmentidgenerateform.get("shipmentno").value || "";
    var orderstatus = this._Myordershistoryform.get("orderstatus").value;
    let selectedshipmentlist = []
    if (shipmentno != "") {
      selectedshipmentlist = (this._MyorderDetails || []).filter(f => f.shipmentno == shipmentno);

      selectedshipmentlist = (selectedshipmentlist || []).filter(f => !OrderstatusMDs.includes(f.orderstatus));
      this.TempHistoryList = selectedshipmentlist;
    } else {
      selectedshipmentlist = (this._MyorderDetails || []).filter(f => this._selectedOrderIDs.includes(f.productid));
      selectedshipmentlist = (selectedshipmentlist || []).filter(f => !OrderstatusMDs.includes(f.orderstatus));
      this.TempHistoryList = selectedshipmentlist;
    }

    this.description = this._Myordershistorydemoform.get("statusdetail").value;
    for (var i = 0; i < selectedshipmentlist.length; i++) {
      var OrderorderDetailid = this.StatusHistoryDetailsDemo.filter(f => f.orderstatus == orderstatus && f.orderid == selectedshipmentlist[i].orderid)[0].orderdetailid
      this.TempHistoryList[i].orderid = selectedshipmentlist[i].orderid;
      this.TempHistoryList[i].orderdetailid = OrderorderDetailid;
      this.TempHistoryList[i].orderno = selectedshipmentlist[i].orderno;
      this.TempHistoryList[i].shipmentno = selectedshipmentlist[i].shipmentno;
      this.TempHistoryList[i].orderstatus = this._Myordershistoryform.get("orderstatus").value;
      this.TempHistoryList[i].ordersubstatus = this._Myordershistorydemoform.get("ordersubstatus").value;

      // HistoryDetails.orderstatus = this._Myordershistoryform.get("orderstatus").value;

      this.TempHistoryList[i].orderstatusdetail = this._Myordershistorydemoform.get("statusdetail").value;
      this.TempHistoryList[i].orderstatusstartdate = new Date();
      var date = new Date();
      var datePipe = new DatePipe("en-US");
      var peffectiveto = datePipe.transform(date, 'EEEE');
      this.TempHistoryList[i].orderstatusstartday = peffectiveto;
      this.TempHistoryList[i].orderstatusenddate = new Date();
      var date = new Date();
      var datePipe = new DatePipe("en-US");
      var peffectiveto = datePipe.transform(date, 'EEEE');
      this.TempHistoryList[i].orderstatusendday = peffectiveto;
      this.TempHistoryList[i].recordstatus = this.StatusHistoryDetailsDemo[0].recordstatus;
      this.TempHistoryList[i].createdby = this._userid
      this.TempHistoryList[i].createdon = new Date();
      this.TempHistoryList[i].modifiedby = this._userid
      this.TempHistoryList[i].modifiedon = new Date();
      this.TempHistoryList[i].assignedtostatus = this._Myordershistorydemoform.get("assigneetostatus").value;
      this.TempHistoryList[i].assignedid = this._Myordershistoryform.get("assigneeid").value;
      this.TempHistoryList[i].assignedid = this._Myordershistoryform.get("assigneeid").value;
      this.TempHistoryList[i].salesinvoiceid = selectedshipmentlist[i].salesinvoiceid
      this.TempHistoryList[i].salesinvoiceno = selectedshipmentlist[i].salesinvoiceno
      let assignedto = this._Myordershistorydemoform.get("assigneetostatus").value
      if (assignedto == "ECO00054") {
        let employeename = this._Employees.filter(f => f.employeeid == this._Myordershistoryform.get("assigneeid").value)[0].employeename;
        this.TempHistoryList[i].assignedname = employeename;
      } else if (assignedto == "ECO00055") {
        // Deliveryservice
        this.TempHistoryList[i].assignedname = this._Myordershistoryform.get("assigneename").value;
      }
      let substatus = this._Myordershistorydemoform.get("ordersubstatus").value;
      if (substatus == "ECO00015") {
  
        var logDetails = new Orderstatusemaillog();
        logDetails.logid = 0;
        logDetails.customername = this._Myordershistory.shippingaddresscustomername;
        logDetails.tomail = this._Myordershistory.mailid;
        logDetails.orderid = selectedshipmentlist[i].orderid;
        logDetails.orderno = this._Myordershistory.orderno;
        logDetails.orderstatus = this._Myordershistoryform.get("orderstatus").value;
        logDetails.orderstatusname = this._Myordershistorydemoform.get("ordersubstatus").value;
        logDetails.logstatusid = 1;
        logDetails.logstatus = "No";
        logDetails.recordstatus = "MSC00001";
        logDetails.createdby = this._userid;
        logDetails.createdon = new Date();
        logDetails.modifiedby = this._userid;
        logDetails.modifiedon = new Date();
  
        this.TempemaillogList.push(logDetails);
      } else {
        this.TempemaillogList = [];
      }
  
  
      var logstatus = new Orderstatuslog()
      logstatus.logid = 0;
      logstatus.orderid = selectedshipmentlist[i].orderid;
      logstatus.orderno = this._Myordershistory.orderno;
      logstatus.orderstatuscode = this._Myordershistoryform.get("orderstatus").value;
      logstatus.ordersubstatuscode = this._Myordershistorydemoform.get("ordersubstatus").value;
      logstatus.orderdescstatuscode = this._Myordershistorydemoform.get("statusdetail").value;
      logstatus.recordstatus = "MSC00001";
      logstatus.createdby = this._Myordershistoryform.get("assigneeid").value;
      logstatus.createdon = new Date();
      logstatus.modifiedby = this._Myordershistoryform.get("assigneeid").value;
      logstatus.modifiedon = new Date();
      logstatus.logtype = "ECO00022"
      logstatus.shipmentno = shipmentno;
      logstatus.locationcode = this._Myordershistoryform.get("locationid").value;
      // let assignedto = this._Myordershistorydemoform.get("assigneetostatus").value
      if (assignedto == "ECO00054") {
        let employeename = this._Employees.filter(f => f.employeeid == this._Myordershistoryform.get("assigneeid").value)[0].employeename
        logstatus.assignedname = employeename;
      } else if (assignedto == "ECO00055") {
        // Deliveryservice
        logstatus.assignedname = this._Myordershistoryform.get("assigneename").value;
      }
      logstatus.assignedname = this._Myordershistoryform.get("assigneeid").value;
      logstatus.assignedtostatus = this._Myordershistorydemoform.get("assigneetostatus").value;
      logstatus.assignedid = this._Myordershistoryform.get("assigneeid").value
  
  
      this.Templogstatus.push(logstatus);
    }
    // let substatus = this._Myordershistorydemoform.get("ordersubstatus").value;
    // if (substatus == "ECO00015") {

    //   var logDetails = new Orderstatusemaillog();
    //   logDetails.logid = 0;
    //   logDetails.customername = this._Myordershistory.shippingaddresscustomername;
    //   logDetails.tomail = this._Myordershistory.mailid;
    //   logDetails.orderid = this._Myordershistory.orderid;
    //   logDetails.orderno = this._Myordershistory.orderno;
    //   logDetails.orderstatus = this._Myordershistoryform.get("orderstatus").value;
    //   logDetails.orderstatusname = this._Myordershistorydemoform.get("ordersubstatus").value;
    //   logDetails.logstatusid = 1;
    //   logDetails.logstatus = "No";
    //   logDetails.recordstatus = "MSC00001";
    //   logDetails.createdby = this._userid;
    //   logDetails.createdon = new Date().toLocaleString();;
    //   logDetails.modifiedby = this._userid;
    //   logDetails.modifiedon = new Date().toLocaleString();

    //   this.TempemaillogList.push(logDetails);
    // } else {
    //   this.TempemaillogList = [];
    // }


    // var logstatus = new Orderstatuslog()
    // logstatus.logid = 0;
    // logstatus.orderid = this._Myordershistory.orderid;
    // logstatus.orderno = this._Myordershistory.orderno;
    // logstatus.orderstatuscode = this._Myordershistoryform.get("orderstatus").value;
    // logstatus.ordersubstatuscode = this._Myordershistorydemoform.get("ordersubstatus").value;
    // logstatus.orderdescstatuscode = this._Myordershistorydemoform.get("statusdetail").value;
    // logstatus.recordstatus = "MSC00001";
    // logstatus.createdby = this._Myordershistoryform.get("assigneeid").value;
    // logstatus.createdon = new Date().toLocaleString();
    // logstatus.modifiedby = this._Myordershistoryform.get("assigneeid").value;
    // logstatus.modifiedon = new Date().toLocaleString();
    // logstatus.logtype = "ECO00022"
    // logstatus.shipmentno = shipmentno;
    // logstatus.locationcode = this._Myordershistoryform.get("locationid").value;
    // let assignedto = this._Myordershistorydemoform.get("assigneetostatus").value
    // if (assignedto == "ECO00054") {
    //   let employeename = this._Employees.filter(f => f.employeeid == this._Myordershistoryform.get("assigneeid").value)[0].employeename
    //   logstatus.assignedname = employeename;
    // } else if (assignedto == "ECO00055") {
    //   // Deliveryservice
    //   logstatus.assignedname = this._Myordershistoryform.get("assigneename").value;
    // }
    // logstatus.assignedname = this._Myordershistoryform.get("assigneeid").value;
    // logstatus.assignedtostatus = this._Myordershistorydemoform.get("assigneetostatus").value;
    // logstatus.assignedid = this._Myordershistoryform.get("assigneeid").value


    // this.Templogstatus.push(logstatus);
    this.substatusforEmail = this._Myordershistorydemoform.get("ordersubstatus").value

    if (this._selectedOrderIDs.length > 0) {
      let myorderhistory = (this._MyorderDetails || []).filter(f => this._selectedOrderIDs.includes(f.productid))
      for (let i = 0; i < myorderhistory.length; i++) {
        myorderhistory[i].orderstatus = this._Myordershistoryform.get("orderstatus").value || "";
        myorderhistory[i].erporderstatus = this._Myordershistorydemoform.get("ordersubstatus").value || "";
        let assignedto = this._Myordershistorydemoform.get("assigneetostatus").value
        if (assignedto == "ECO00054") {
          let employeename = this._Employees.filter(f => f.employeeid == this._Myordershistoryform.get("assigneeid").value)[0].employeename
          myorderhistory[i].assigneename = employeename;
        } else if (assignedto == "ECO00055") {
          // Deliveryservice
          myorderhistory[i].assigneename = this._Myordershistoryform.get("assigneename").value;
        }
        myorderhistory[i].assigneetostatus = this._Myordershistorydemoform.get("assigneetostatus").value || "";
        myorderhistory[i].assigneeid = this._Myordershistoryform.get("assigneeid").value || "";
        myorderhistory[i].assigneename = this._Myordershistoryform.get("assigneename").value || "";

      }
      this.MyOrderHistory = myorderhistory
    }
    if (this._Shipmentidgenerateform.get("shipmentno").value) {
      let myorderhistory = (this._MyorderDetails || []).filter(f => f.shipmentno == this._Shipmentidgenerateform.get("shipmentno").value);
      let orderstatus = ["ECO00034", "ECO00035"]
      myorderhistory = (myorderhistory || []).filter(f => !orderstatus.includes(f.orderstatus));
      for (let i = 0; i < myorderhistory.length; i++) {
        myorderhistory[i].orderstatus = this._Myordershistoryform.get("orderstatus").value || "";
        myorderhistory[i].erporderstatus = this._Myordershistorydemoform.get("ordersubstatus").value || "";
      }
      this.MyOrderHistory = myorderhistory
    }


    // myorerhistory.orderid=this._Myordershistoryform
    // this.MyOrderHistory = this._Myordershistoryform.value
    this._MyOrderHistoryHeader = this._Myordershistoryheaderform.getRawValue();
    this._MyOrderHistoryHeader.shipmenttype = this._Shipmentidgenerateform.get("shipmenttype").value;
    this._OrderStatusOBJ = { "Myordershistory": this.MyOrderHistory, "Myorderhistorydetail": this.TempHistoryList, "Orderstatusemaillog": this.TempemaillogList, "Orderstatuslog": this.Templogstatus, "Myordershistoryheader": this._MyOrderHistoryHeader };
    // this.onEd()
    this._IsProgressSpinner = true;
    this._OrderService.Update(this._OrderStatusOBJ).subscribe((result) => {
      const resutJSON = JSON.parse(JSON.stringify(result))
      this._IsProgressSpinner = false;
      if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
        this.reset();
        this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
        this.PageonLoad();
        // if (this.substatusforEmail == "ECO00015") {
        //   this._MailService.OrderStatusMail().subscribe((result) => {
        //     const resutJSON = JSON.parse(JSON.stringify(result));
        //     console.log("resultemail", resutJSON);
        //   },
        //     error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        // }
      }
      else {
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  reset() {
    this.TempHistoryList = [] = [];
    this.Templogstatus = [] = [];
    this.TempemaillogList = [] = [];
    this._Shipmentidgenerateform.get("shipmentno").reset();
  }
  onEd() {
    //alert("test");
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._OrderStatusOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  ChangeAssignedTo() {
    let assignedto = this._Myordershistorydemoform.get("assigneetostatus").value
    if (assignedto == "ECO00054") {
      this.IsEmployee = true;
      this.IsServiceProvider = false;
    } else if (assignedto == "ECO00055") {
      this.IsEmployee = false;
      this.IsServiceProvider = true;
    }
  }

  //Export Order Status PDF,Excel
  exportExcelOrderStatus() {
    let OrderStatusList = this.GetOrderStatusList();
    this.exportUtility.exportExcel(OrderStatusList, 'OrderStatus', 'xlsx');
  }
 
  ExportOrderStatusPdf() {
    if (!this._MyorderDetails || !this._MyorderDetails.length)
      return;
    let OrderStatusList = this.GetOrderStatusList();
    const data = OrderStatusList.map(Object.values);
    const head = [['Order ID', 'Product Name', 'Ordered Qty','Cancelled Qty','Total Qty', 'Unit Price', 'Tax Amt', 'Discount Amt','Shipping Charges', 'Total Amt', 'Shipment No', 'Order Status']]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('OrderStatus.pdf');
      })
    })
  }
  

  GetOrderStatusList() {
    if (!this._MyorderDetails || !this._MyorderDetails.length)
      return;
    let OrderStatusList = [];
    this._MyorderDetails.forEach(element => {
      let orderstatus: any = {};
      orderstatus["Order ID"] = element.orderid;
      orderstatus["Product Name"] = element.productname;
      orderstatus["Ordered Quantity"] = element.orderquantity;
      orderstatus["Cancelled Quantity"] = element.cancelquantity;
      orderstatus["Total Quantity"] = element.totalquantity;
      orderstatus["Unit Price"] = element.unitprice;
      orderstatus["Tax Amount"] = element.taxamount;
      orderstatus["Discount Amount"] = element.discountamount;
      orderstatus["Shipping Charges"] = element.shippingcharges;
      orderstatus["Total Amount"] = element.netamount;
      orderstatus["Shipment No"] = element.shipmentno;
      orderstatus["Order Status"] = element.orderstatusname;
      OrderStatusList.push(orderstatus);
    });
    return OrderStatusList;
  }


  //Export PDF, Excel for order history
  exportExcelStatusHistory() {
    let OrderStatusList = this.GetOrderStatusHistoryList();
    this.exportUtility.exportExcel(OrderStatusList, 'OrderStatusHistory', 'xlsx');
  }

  ExportStatusHistoryPdf() {
    if (!this.StatusHistoryDetails || !this.StatusHistoryDetails.length)
      return;
    let StatusHistoryDetails = this.GetOrderStatusHistoryList();
    const data = StatusHistoryDetails.map(Object.values);
    const head = [['Shipment No', 'Order Date', 'Status', 'Sub Status', 'Staff']]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('OrderStatusHistory.pdf');
      })
    })
  }

  GetOrderStatusHistoryList() {
    if (!this.StatusHistoryDetails || !this.StatusHistoryDetails.length)
      return;
    let OrderStatusList = [];
    this.StatusHistoryDetails.forEach(element => {
      let orderstatus: any = {};
      orderstatus["Shipment No"] = element.shipmentno;
      orderstatus["Order Date"] = this.datepipe.transform(element.modifiedon, 'dd/MM/yyyy');
      orderstatus["Status"] = element.orderstatusname;
      orderstatus["Sub Status"] = element.ordersubstatusname;
      orderstatus["Staff"] = element.modifiedbyname;
      OrderStatusList.push(orderstatus);
    });
    return OrderStatusList;
  }
}