<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Sales Order</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple icon="pi pi-plus" title="Add"
                                class="p-button p-button-success p-mr-2" [disabled]="!_Action._Add"
                                (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true" [minDate]="mindate"
                                    [maxDate]="maxdate" dateFormat="dd/mm/yy" [monthNavigator]="true"
                                    yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [minDate]="mindate" [maxDate]="maxdate" [monthNavigator]="true"
                                    yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search"
                                icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <p-toast></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="exportUtility.exportCSV(dt)" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <!-- 84.grid contains saved data (action, Sales order no, customer name, sales date, total  amount and approval status) -->
                    <p-table #dt [value]="_Salesorderviews" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [globalFilterFields]="['salesorderno','salesorderdate','customername','referenceno','currencyname','totalsalesorderamount','approvalstatusname','createdbyname','createdon']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="salesorderno"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">

                        <ng-template pTemplate="header">
                            <tr>

                                <th class="text-center" style="width:150px;">Action
                                </th>
                                <th pSortableColumn="salesorderno">Sales Order Number <p-sortIcon field="salesorderno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="salesorderdate">Sales Order Date <p-sortIcon field="salesorderno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="customername">Customer <p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="referenceno"> Reference No <p-sortIcon field="referenceno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname">Currency <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="totalsalesorderamount">Sales Amount <p-sortIcon
                                        field="totalsalesorderamount"></p-sortIcon>
                                </th>

                                <!-- <th pSortableColumn="statusname">Status<p-sortIcon field="statusname">
                                    </p-sortIcon>
                                </th> -->
                                <th pSortableColumn="approvalstatusname">Status<p-sortIcon field="approvalstatusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By<p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On<p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>


                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Salesorderview>
                            <tr>

                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="!_Action._View" (click)="view(_Salesorderview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" [disabled]="!_Action._Update"
                                        class="p-button p-button-success p-mr-2"
                                        [disabled]="_Salesorderview.approvalstatus == 'MSC00150' ||_Salesorderview.approvalstatus == 'MSC00046' || _Salesorderview.approvalstatus == 'MSC00047' ||_Salesorderview.approvalstatus == 'MSC00048'"
                                        (click)="edit(_Salesorderview)"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Delete"
                                        [disabled]="!_Action._Delete" class="p-button p-button-danger"
                                        [disabled]="_Salesorderview.approvalstatus == 'MSC00150' ||_Salesorderview.approvalstatus == 'MSC00046' || _Salesorderview.approvalstatus == 'MSC00047' ||_Salesorderview.approvalstatus == 'MSC00048'"
                                        (click)="confirm(_Salesorderview)"></button>
                                    <!-- <button pButton pRipple icon="las la-print" title="Print" [disabled]="_Salesorderview.approvalstatus == 'MSC00150' ||_Salesorderview.approvalstatus == 'MSC00045' || _Salesorderview.approvalstatus == 'MSC00046' ||_Salesorderview.approvalstatus == 'MSC00048'"
                                        class="p-button-warning p-mr-2" (click)="Print(_Salesorderview)"></button> -->
                                    <button pButton pRipple icon="las la-print" title="Print"
                                        [disabled]="!_Action._Print" class="p-button-warning p-mr-2"
                                        (click)="Print(_Salesorderview)"></button>
                                </td>
                                <td>
                                    {{_Salesorderview.salesorderno}}
                                </td>
                                <td>
                                    {{_Salesorderview.salesorderdate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_Salesorderview.customername}}
                                </td>
                                <td>
                                    {{_Salesorderview.referenceno}}
                                </td>
                                <td>
                                    {{_Salesorderview.currencyname}}
                                </td>

                                <td>
                                    {{_Salesorderview.totalsalesorderamount}}
                                </td>

                                <!-- <td>
                                    {{_Salesorderview.statusname }}
                                </td> -->
                                <td>
                                    {{_Salesorderview.approvalstatusname }}
                                </td>
                                <td>
                                    {{_Salesorderview.createdbyname}}
                                </td>
                                <td>
                                    {{_Salesorderview.createdon|date:'dd/MM/yyyy'}}
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


            </div>
        </div>
    </div>
</div>