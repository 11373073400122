import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { User } from 'src/app/core/Models/User';
import { CodSettlementService } from 'src/app/core/Services/ecommerce/cod-settlement.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-cod-settlement',
  templateUrl: './v-cod-settlement.component.html',
  styleUrls: ['./v-cod-settlement.component.css']
})
export class VCodSettlementComponent implements OnInit {
  _tellernames: User;
  Tellerid: number[] = [];
  _CODDetails: any[] = [];
  _Action: IAction;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _IsProgressSpinner: boolean = false;
  _branchid = sessionStorage["currentbranchid"];
  _userid = sessionStorage["userid"];
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _COdSettlementID: number = 0;
  cols: any[];
  FetchAllobj: any;
  constructor(private fb: FormBuilder, private _CODSettlementservice: CodSettlementService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService, private exportUtility: ExportUtility, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this._IsProgressSpinner = true;
    this._Action = this._AccessRightsService.getAccessRights(318);
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'salesdate', header: 'Sales Date' },
      { field: 'tellername', header: 'Teller Name' },
      { field: 'totalreceivableamount', header: 'Total Receivable Amount' },
      { field: 'totalpaidamount', header: 'Total Paid Amount' },
      { field: 'balanceamount', header: 'Balance Amount' }
    ]

    this.fromdate = new Date();
    this.todate = new Date();

    this.PageOnload();

  }
  PageOnload() {
    this._IsProgressSpinner = true;
    this._CODSettlementservice.PageOnload(this._COdSettlementID).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._tellernames = resultJSON.tellers;
      this.Fetchall();
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  Fetchall() {

    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this.FetchAllobj = { "Tellerid": this.Tellerid || [], "fromdate": fromdate, "todate": todate }
    this._CODSettlementservice.FetchAll(this.FetchAllobj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("FetchaLl", resultJSON);
      this._CODDetails = resultJSON.getsettlementorderdetails
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/CCodSettlement'], { state: { codsettlementid: event.codsettlementid, action: 'view' } });
  }

  GoToCreate(event) {
    this.router.navigate(['/CCodSettlement']);
  }

  exportExcel() {
    let COD = this.GetCODSettlementList();
    this.exportUtility.exportExcel(COD, 'PaymentSettlement', 'xlsx');
  }

  ExportToPdf() {
    let COD = this.GetCODSettlementList();
    const data = COD.map(Object.values);
    const head = [[
      'Sales Date',
      'Teller Name',
      'Total Receivable Amount',
      'Total Paid Amount',
      'Balance Amount'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('PaymentSettlement.pdf');
      })
    })
  }

  GetCODSettlementList() {
    let CODSettlement = [];
    this._CODDetails.forEach(element => {
      let cod: any = {};
      cod["Sales Date"] = this.datepipe.transform(element.salesdate, 'dd/MM/yyyy');
      cod["Teller Name"] = element.tellername;
      cod["Total Receivable Amount"] = element.totalreceivableamount || 0;
      cod["Total Paid Amount"] = element.totalpaidamount || 0;
      cod["Balance Amount"] = element.balanceamount || 0;
      CODSettlement.push(cod);
    });
    return CODSettlement;
  }


}
