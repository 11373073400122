import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map,catchError} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {
 
  public _Action : IAction ;
  public  _CategoryService = environment.CusorSupCategoryService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http:HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
 
  PageOnLoad(p_Category : object): Observable<any> {
    const headers = this.header;
    var Query = this._CategoryService + '/PageOnload' ;
    return this.http.post<{ data: any }>(Query,p_Category, { headers });
  }

  FetchAll() {
    const headers = this.header;
   
    var Query = this._CategoryService + '/FetchAll';
    return this.http.get<{ data: any }>(Query, {headers}).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      return data;
    }));
  }
  Create(p_Category:object)
  {
    const headers = this.header;
    //console.log("Create");
    var Query = this._CategoryService  ;


    return this.http.post<{data:any}>(Query,p_Category, { headers });
  }
   edit(p_Category : object)
  {
    const headers = this.header;
    //console.log("Update");
    var Query = this._CategoryService;
   
    return this.http.put<{data:any}>(Query,p_Category, { headers });
  }
  Cancel(categoryid:number,userid :number)
  {
    const headers = this.header;
    //console.log("Update");
    var Query = this._CategoryService + '/Cancel/' + categoryid +'/'+ userid ;
   
    return this.http.get<{ data: any }>(Query, { headers });
    
  }

  View(categoryid: object): Observable<any> {
    const headers = this.createHeaders(this.token)
    var Query = this._CategoryService + '/View' + '/' + categoryid;
    console.log(Query);
    return this.http.get(Query, { headers });
  }
 
}
