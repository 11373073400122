<div class="page-container dashboard-page scroll-y">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Outstanding Receivable Dashboard</h3>
                    </div>
                    <div class="col-md-7 text-right"></div>
                </div>
            </div>
            <form [formGroup]="_outstandingreceivabledashboardform">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_Branches" filter="true"
                                    name="branchid" optionLabel="branchname" optionValue="branchid"
                                    formControlName="branchid" (onChange)="initializeCharts()">
                                </p-dropdown>
                                <label for="branchid">Branch <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 80%">
                            <div class="row workflow-widget"></div>
                        </td>
                    </tr>
                </table>
            </form>
        </div>
        <div class="form-container">
            <div class="row">
                <div class="col-md-12">
                    <div class="white">
                        <div class="widget-title page-title">
                            <div class="row">
                                <div class="col-md-8">
                                    <h3>Outstanding Receivable</h3>
                                </div>
                                <div class="col-md-4">
                                    <div class="widget-filter text-right">
                                        <div class="filter-container">
                                            <a href="javascript:void(0);" data-toggle="dropdown"
                                                data-toggle-second="tooltip" data-placement="top" title="Filter By">
                                                <i class="las la-filter"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right widget-popup big collapse">
                                                <form>
                                                    <table class="normal-table">
                                                        <tr>
                                                            <td>
                                                                <span class="p-float-label">
                                                                    <p-multiSelect name="branch_filter"
                                                                        [options]="_Branches" [virtualScroll]="true"
                                                                        [(ngModel)]="_selectedTopBranch"
                                                                        [selectionLimit]="5"
                                                                        [ngModelOptions]="{ standalone: true }"
                                                                        optionLabel="branchname" optionValue="branchid"
                                                                        [filter]="true"
                                                                        (onChange)="filterBranch($event)">
                                                                    </p-multiSelect>
                                                                    <label for="branchid">Branch</label>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="filter-container">
                                            <a href="javascript:void(0);" data-toggle="dropdown"
                                                data-toggle-second="tooltip" data-placement="top"
                                                title="Choose Chart"><i class="las la-chart-bar"></i></a>
                                            <div
                                                class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                                <a (click)="chartChangedBranch('bar')">
                                                    <i class="icofont-chart-bar-graph"></i>
                                                    Bar Chart
                                                </a>
                                                <a (click)="chartChangedBranch('line')">
                                                    <i class="icofont-chart-line"></i>
                                                    Line Chart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="widget-body">
                            <div class="widget-details" *ngIf="_IsLineBranch">
                                <p-chart type="line" [height]="120" [data]="outstandingreceiveBranchWise"
                                    [options]="basicOptions" *ngIf="!noOutstandingReceivableBranchWise"></p-chart>
                                <div class="widget-details" *ngIf="noOutstandingReceivableBranchWise">
                                    <img style="max-height: 344px; margin: auto; display: block"
                                        src="../../../../../assets/images/no-data.jpg" alt="" />
                                </div>
                            </div>
                            <div class="widget-details" *ngIf="_IsbarBranch">
                                <p-chart type="bar" [height]="120" [data]="outstandingreceiveBranchWise"
                                    [options]="basicOptions" *ngIf="!noOutstandingReceivableBranchWise"></p-chart>
                                <div class="widget-details" *ngIf="noOutstandingReceivableBranchWise">
                                    <img style="max-height: 344px; margin: auto; display: block"
                                        src="../../../../../assets/images/no-data.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>