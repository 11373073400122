import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptpayablereceivableService {
  public _Action: IAction;
  public _httpOptions;
  public _RptpayablereceivableService = environment.RptpayablereceivableService;
  constructor(private http: HttpClient) { }
  PageOnload(): Observable<any> {
    var Query = this._RptpayablereceivableService + '/PageInitialize';
    
    return this.http.get<{ data: any }>(Query);
  }
  Print(fromdate: string, todate: string,  partytype: number,customerorsupplier: number, branchid: number, userid: number, type: string,currentbranchid:number,currencyid:number) {
    var Query = this._RptpayablereceivableService + '/Print/' + fromdate + '/' + todate + '/' + partytype + '/' + customerorsupplier + '/' + branchid + '/' + userid+'/'+type+ '/'+currentbranchid + '/' + currencyid;
    //  window.open(Query, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    return this.http.get(Query, { responseType: 'blob' });
  }
  onchangepartytype(partytype: number, userid:number) {
    var Query = this._RptpayablereceivableService+'/onchangepartytype/'+partytype + '/'+userid;
    
    return this.http.get<{ data: any }>(Query);
  }
}
