<div class="page-container dashboard-page scroll-y">
  <div class="inner-page-container">
    <div class="page-loader" *ngIf="_IsProgressSpinner">
      <p-progressSpinner strokeWidth="5"></p-progressSpinner>
    </div>
    <div class="white">
      <div class="page-title">
        <div class="row">
          <div class="col-md-5">
            <h3>Sales Dashboard</h3>
          </div>
          <div class="col-md-7 text-right"></div>
        </div>
      </div>
      <form [formGroup]="_salesdashboardform">
        <table class="normal-table">
          <tr>
            <td style="width: 20%;">
              <span class="p-float-label">
                <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate"
                  dateFormat="dd/mm/yy" (onSelect)="initializeCharts()">
                  <!-- (onSelect)="onchangefromdate()" -->
                </p-calendar>
                <label for="date">Date</label>
              </span>
            </td>
            <!-- <td style="width: 20%;">
              <span class="p-float-label">
                <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true" yearRange="1980:2030"
                  [maxDate]="maxdate" [minDate]="mindate" dateFormat="dd/mm/yy">
                </p-calendar>
                <label for="todate">To Date</label>
              </span>
            </td> -->
            <td style="width: 20%">
              <span class="p-float-label">
                <p-dropdown
                  [autoDisplayFirst]="false"
                  [options]="_Branches"
                  filter="true"
                  name="branchid"
                  optionLabel="branchname"
                  optionValue="branchid"
                  formControlName="branchid"
                  (onChange)="initializeCharts()"
                >
                </p-dropdown>
                <label for="branchid"
                  >Branch <span class="hlt-txt">*</span></label
                >
              </span>
            </td>
            <td style="width: 60%">
              <div class="row workflow-widget"></div>
            </td>
          </tr>
        </table>
      </form>
    </div>

    <div class="form-container">
      <div class="row workflow-widget">
        <div class="col-md-3">
          <div class="white workflow-summary">
            <div class="color01">
              <a href="javascript:void(0);" class="workflow-links">
                <h5 style="color: #626262">Sales Count
                  <span style="color: #0948cc;font-weight: 600;font-size: 14px;">(In No's)</span>
                </h5>
                <h1>{{ salesReturnHead.SALESCOUNT }}</h1>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="white workflow-summary">
            <div class="color01">
              <a href="javascript:void(0);" class="workflow-links">
                <h5 style="color: #626262">Sales Value
                  <span style="color: #0948cc;font-weight: 600;font-size: 14px;">(In USD)</span>
                </h5>
                <h1>{{ salesReturnHead.SALESINVOICEVALUE }} $</h1>
                <!-- <div *ngIf="salesReturnHead; then SIB; else elseSIV">qw</div> -->
                <!-- <ng-template #SIB>
                      </ng-template> -->
                <!-- <ng-template #elseSIV>
                        <h1>0 $</h1>
                      </ng-template> -->
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="white workflow-summary">
            <div class="color01">
              <a href="javascript:void(0);" class="workflow-links">
                <h5 style="color: #626262">Sales Return Count
                  <span style="color: #0948cc;font-weight: 600;font-size: 14px;">(In No's)</span>
                </h5>
                <h1>{{ salesReturnHead.SALESRETURNCOUNT }}</h1>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="white workflow-summary">
            <div class="color01">
              <a href="javascript:void(0);" class="workflow-links">
                <h5 style="color: #626262">Sales Return Value
                  <span style="color: #0948cc;font-weight: 600;font-size: 14px;">(In USD)</span>
                  </h5>
                <h1>{{ salesReturnHead.SALESRETURNVALUE }} $</h1>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="white">
            <div class="widget-title page-title">
              <div class="row">
                <div class="col-md-8">
                  <h3>
                    Sales/ Return Report -
                    <span style="color: #0948cc;font-size: 16px;padding: 0px 3px;font-weight: 100;">Product Wise</span>
                    (In No's)
                  </h3>
                </div>
                <div class="col-md-4">
                  <div class="widget-filter text-right">
                    <div class="filter-container">
                      <!-- <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="Filter By"><i
                                              class="las la-filter"></i></a> -->
                      <div
                        class="dropdown-menu dropdown-menu-right widget-popup big collapse"
                      >
                        <form>
                          <table class="normal-table">
                            <tr>
                              <td>
                                <span class="p-float-label">
                                  <p-autoComplete field="branchname">
                                    <ng-template let-branch pTemplate="item">
                                      <div>{{ branch.branchname }}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="branchid">Branch</label>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span class="p-float-label">
                                  <!-- <p-multiSelect
                                    [options]="_productname"
                                    formControlName="productid"
                                    optionLabel="productname"
                                    optionValue="productid"
                                    displaySelectedLabel="true"
                                  >
                                  </p-multiSelect> -->
                                  <label for="productid">Product Name </label>
                                </span>
                              </td>
                            </tr>
                          </table>
                        </form>
                      </div>
                    </div>
                    <!-- <div class="filter-container">
                            <a href="javascript:void(0);" data-toggle="dropdown"
                                data-toggle-second="tooltip" data-placement="top" title="Choose Chart"><i
                                    class="las la-chart-bar"></i></a>
                            <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                <a (click)="chartchangepd('bar')"><i class="icofont-chart-bar-graph"></i>Bar
                                    Chart</a>
                                <a (click)="chartchangepd('pie')"><i class="icofont-pie-chart"></i>Pie
                                    Chart</a>
                                <a (click)="chartchangepd('line')"><i class="icofont-chart-line"></i>Line
                                    Chart</a>
                                <a (click)="chartchangepd('doughnut')"><i
                                        class="icofont-chart-pie-alt"></i>Donut Chart</a>
                            </div>
                        </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="widget-body">
              <div class="widget-details" *ngIf="_isDoubleBar">
                <p-chart
                  type="bar"
                  *ngIf="!noDoubleBarChart"
                  [data]="salesReturnRep"
                  [options]="salesReturnRepOpt"
                ></p-chart>
                <div class="widget-details" *ngIf="noDoubleBarChart">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="white">
            <div class="widget-title page-title">
              <div class="row">
                <div class="col-md-8">
                  <h3>
                    Sales By Category -
                    <span style="color: #0948cc;font-size: 16px;padding: 0px 3px;font-weight: 100;">Product Wise</span>
                    (In No's)
                  </h3>
                </div>
                <div class="col-md-4">
                  <div class="widget-filter text-right">
                    <div class="filter-container">
                      <!-- <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="Filter By"><i
                                              class="las la-filter"></i></a> -->
                      <div
                        class="dropdown-menu dropdown-menu-right widget-popup big collapse"
                      >
                        <form>
                          <table class="normal-table">
                            <tr>
                              <td>
                                <span class="p-float-label">
                                  <p-autoComplete field="branchname">
                                    <ng-template let-branch pTemplate="item">
                                      <div>{{ branch.branchname }}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="branchid">Branch</label>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span class="p-float-label">
                                  <!-- <p-multiSelect
                                    [options]="_productname"
                                    formControlName="productid"
                                    optionLabel="productname"
                                    optionValue="productid"
                                    displaySelectedLabel="true"
                                  >
                                  </p-multiSelect> -->
                                  <label for="productid">Product Name </label>
                                </span>
                              </td>
                            </tr>
                          </table>
                        </form>
                      </div>
                    </div>
                    <div class="filter-container">
                      <a
                        href="javascript:void(0);"
                        data-toggle="dropdown"
                        data-toggle-second="tooltip"
                        data-placement="top"
                        title="Choose Chart"
                        ><i class="las la-chart-bar"></i
                      ></a>
                      <div
                        class="dropdown-menu dropdown-menu-right widget-popup collapse text-left"
                      >
                        <a (click)="chartchangepd('bar')"
                          ><i class="icofont-chart-bar-graph"></i>Bar Chart</a
                        >
                        <a (click)="chartchangepd('pie')"
                          ><i class="icofont-pie-chart"></i>Pie Chart</a
                        >
                        <a (click)="chartchangepd('line')"
                          ><i class="icofont-chart-line"></i>Line Chart</a
                        >
                        <a (click)="chartchangepd('doughnut')"
                          ><i class="icofont-chart-pie-alt"></i>Donut Chart</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="widget-body">
              <div class="widget-details" *ngIf="_Isbarpd">
                <p-chart
                  *ngIf="!noSalesByCategory"
                  type="bar"
                  [data]="salesByCategory"
                  [options]="salesByCategoryOpt"
                ></p-chart>
                <div class="widget-details" *ngIf="noSalesByCategory">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Islinepd">
                <p-chart
                  *ngIf="!noSalesByCategory"
                  type="line"
                  [data]="salesByCategory"
                  [options]="salesByCategoryOpt"
                ></p-chart>
                <div class="widget-details" *ngIf="noSalesByCategory">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Ispiepd">
                <p-chart
                  *ngIf="!noSalesByCategory"
                  type="pie"
                  [data]="salesByCategory"
                  [options]="salesByCategoryOpt"
                ></p-chart>
                <div class="widget-details" *ngIf="noSalesByCategory">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Isdoughnutpd">
                <p-chart
                  *ngIf="!noSalesByCategory"
                  type="doughnut"
                  [data]="salesByCategory"
                  [options]="salesByCategoryOpt"
                ></p-chart>
                <div class="widget-details" *ngIf="noSalesByCategory">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="white">
            <div class="widget-title page-title">
              <div class="row">
                <div class="col-md-8">
                  <h3>
                    Top Selling Products (In No's)
                  </h3>
                </div>
                <div class="col-md-4">
                  <div class="widget-filter text-right">
                    <div class="filter-container">
                      <a
                        href="javascript:void(0);"
                        data-toggle="dropdown"
                        data-toggle-second="tooltip"
                        data-placement="top"
                        title="Choose Chart"
                        ><i class="las la-chart-bar"></i
                      ></a>
                      <div
                        class="dropdown-menu dropdown-menu-right widget-popup collapse text-left"
                      >
                        <a (click)="chartchangesd('bar')"
                          ><i class="icofont-chart-bar-graph"></i>Bar Chart</a
                        >
                        <a (click)="chartchangesd('pie')"
                          ><i class="icofont-pie-chart"></i>Pie Chart</a
                        >
                        <a (click)="chartchangesd('line')"
                          ><i class="icofont-chart-line"></i>Line Chart</a
                        >
                        <a (click)="chartchangesd('doughnut')"
                          ><i class="icofont-chart-pie-alt"></i>Donut Chart</a
                        >
                      </div>
                    </div>
                    <!-- <div class="filter-container">
                            <a href="javascript:void(0);" data-toggle="dropdown"
                                data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                    class="las la-ellipsis-v"></i></a>
                            <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                <a href="javascript:void(0);"><i class="icofont-file-pdf"></i>Download
                                    as PDF</a>
                                <a href="javascript:void(0);"><i class="icofont-file-excel"></i>Download
                                    as Excel</a>
                            </div>
                        </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="widget-body">
              <div class="widget-details" *ngIf="_Isbarsd">
                <p-chart
                  type="bar"
                  [data]="topSellingProduct"
                  [options]="topSellingProductOpt"
                  *ngIf="!noTopSelling"
                ></p-chart>
                <div class="widget-details" *ngIf="noTopSelling">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Islinesd">
                <p-chart
                  type="line"
                  [data]="topSellingProduct"
                  [options]="topSellingProductOpt"
                  *ngIf="!noTopSelling"
                ></p-chart>
                <div class="widget-details" *ngIf="noTopSelling">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Ispiesd">
                <p-chart
                  type="pie"
                  [data]="topSellingProduct"
                  [options]="topSellingProductOpt"
                  *ngIf="!noTopSelling"
                ></p-chart>
                <div class="widget-details" *ngIf="noTopSelling">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Isdoughnutsd">
                <p-chart
                  type="doughnut"
                  [data]="topSellingProduct"
                  [options]="topSellingProductOpt"
                  *ngIf="!noTopSelling"
                ></p-chart>
                <div class="widget-details" *ngIf="noTopSelling">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="white">
            <div class="widget-title page-title">
              <div class="row">
                <div class="col-md-8">
                  <h3>Top Return Product (In No's)</h3>
                </div>
                <div class="col-md-4">
                  <div class="widget-filter text-right">
                    <div class="filter-container">
                      <a
                        href="javascript:void(0);"
                        data-toggle="dropdown"
                        data-toggle-second="tooltip"
                        data-placement="top"
                        title="Choose Chart"
                        ><i class="las la-chart-bar"></i
                      ></a>
                      <div
                        class="dropdown-menu dropdown-menu-right widget-popup collapse text-left"
                      >
                        <a (click)="chartchangesd1('bar')"
                          ><i class="icofont-chart-bar-graph"></i>Bar Chart</a
                        >
                        <a (click)="chartchangesd1('pie')"
                          ><i class="icofont-pie-chart"></i>Pie Chart</a
                        >
                        <a (click)="chartchangesd1('line')"
                          ><i class="icofont-chart-line"></i>Line Chart</a
                        >
                        <a (click)="chartchangesd1('doughnut')"
                          ><i class="icofont-chart-pie-alt"></i>Donut Chart</a
                        >
                      </div>
                    </div>
                    <!-- <div class="filter-container">
                                    <a href="javascript:void(0);" data-toggle="dropdown"
                                        data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                            class="las la-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                        <a href="javascript:void(0);"><i class="icofont-file-pdf"></i>Download
                                            as PDF</a>
                                        <a href="javascript:void(0);"><i class="icofont-file-excel"></i>Download
                                            as Excel</a>
                                    </div>
                                </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="widget-body">
              <div class="widget-details" *ngIf="_Isbarsd1">
                <p-chart
                  type="bar"
                  [data]="topReturnProduct"
                  [options]="topReturnProductOpt"
                  *ngIf="!noTopReturn"
                ></p-chart>
                <div class="widget-details" *ngIf="noTopReturn">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Islinesd1">
                <p-chart
                  type="line"
                  [data]="topReturnProduct"
                  [options]="topReturnProductOpt"
                  *ngIf="!noTopReturn"
                ></p-chart>
                <div class="widget-details" *ngIf="noTopReturn">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>

              <div class="widget-details" *ngIf="_Ispiesd1">
                <p-chart
                  type="pie"
                  [data]="topReturnProduct"
                  [options]="topReturnProductOpt"
                  *ngIf="!noTopReturn"
                ></p-chart>
                <div class="widget-details" *ngIf="noTopReturn">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>

              <div class="widget-details" *ngIf="_Isdoughnutsd1">
                <p-chart
                  type="doughnut"
                  [data]="topReturnProduct"
                  [options]="topReturnProductOpt"
                  *ngIf="!noTopReturn"
                ></p-chart>
                <div class="widget-details" *ngIf="noTopReturn">
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="white">
            <div class="widget-title page-title">
              <div class="row">
                <div class="col-md-8">
                  <h3>Sales Breakdown Chart (Branch and Hour wise)</h3>
                </div>
                <div class="col-md-4">
                  <div class="widget-filter text-right">
                    <div class="filter-container">
                      <a
                        href="javascript:void(0);"
                        data-toggle="dropdown"
                        data-toggle-second="tooltip"
                        data-placement="top"
                        title="Filter By"
                        ><i class="las la-filter"></i
                      ></a>
                      <div
                        class="dropdown-menu dropdown-menu-right widget-popup big collapse"
                      >
                        <form>
                          <table class="normal-table">
                            <tr>
                              <td>
                                <span class="p-float-label">

                                  <p-multiSelect name="branch_filter" [options]="_Branches" [virtualScroll]="true"
                                    [(ngModel)]="_selectedTopBranch" [selectionLimit]="5"
                                    [ngModelOptions]="{ standalone: true }" optionLabel="branchname" optionValue="branchid" [filter]="true"
                                    (onChange)="filterBranch($event)">
                                  </p-multiSelect>

                                  <label for="branchid">Branch</label>
                                </span>
                              </td>
                            </tr>
                          </table>
                        </form>
                      </div>
                    </div>
                    <div class="filter-container">
                      <a
                        href="javascript:void(0);"
                        data-toggle="dropdown"
                        data-toggle-second="tooltip"
                        data-placement="top"
                        title="Choose Chart"
                        ><i class="las la-chart-bar"></i
                      ></a>
                      <div
                        class="dropdown-menu dropdown-menu-right widget-popup collapse text-left"
                      >
                        <a (click)="chartChangedBranch('bar')"
                          ><i class="icofont-chart-bar-graph"></i>Bar Chart</a
                        >
                        <!-- <a (click)="chartChangedBranch('pie')"
                          ><i class="icofont-pie-chart"></i>Pie Chart</a
                        > -->
                        <a (click)="chartChangedBranch('line')"
                          ><i class="icofont-chart-line"></i>Line Chart</a
                        >
                        <!-- <a (click)="chartChangedBranch('doughnut')"
                          ><i class="icofont-chart-pie-alt"></i>Donut Chart</a
                        > -->
                      </div>
                    </div>
                    <!-- <div class="filter-container">
                            <a href="javascript:void(0);" data-toggle="dropdown"
                                data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                    class="las la-ellipsis-v"></i></a>
                            <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                <a href="javascript:void(0);"><i class="icofont-file-pdf"></i>Download
                                    as PDF</a>
                                <a href="javascript:void(0);"><i class="icofont-file-excel"></i>Download
                                    as Excel</a>
                            </div>
                        </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="widget-body">

              <div class="widget-details" *ngIf="_IsLineBranch">

                <p-chart
                  type="line"
                  [height]="120"
                  [data]="topSellingProductBranchWiseLine"
                  [options]="basicOptions"
                  *ngIf="!noTopSellingProductBranchWise"

                ></p-chart>

                <div
                  class="widget-details"
                  *ngIf="noTopSellingProductBranchWise"
                >
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>

              <div class="widget-details" *ngIf="_IsbarBranch">
                <p-chart
                  type="bar"
                  [height]="120"
                  [data]="topSellingProductBranchWise"
                  [options]="basicOptions"
                  *ngIf="!noTopSellingProductBranchWise"
                ></p-chart>
                <div
                  class="widget-details"
                  *ngIf="noTopSellingProductBranchWise"
                >
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
              <!-- <div class="widget-details" *ngIf="_IspieBranch">
                <p-chart
                  type="pie"
                  [data]="top5SellingProductBranchWise"
                  [options]="basicOptions"
                  *ngIf="!noTopSellingProductBranchWise"
                  style="max-height: 400px;"
                ></p-chart>
                <div
                  class="widget-details"
                  *ngIf="noTopSellingProductBranchWise"
                >
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div class="widget-details" *ngIf="_IsdoughnutBranch">
                <p-chart
                  type="doughnut"
                  [data]="top5SellingProductBranchWise"
                  [options]="basicOptions"
                  *ngIf="!noTopSellingProductBranchWise"
                  style="max-height: 400px;"
                ></p-chart>
                <div
                  class="widget-details"
                  *ngIf="noTopSellingProductBranchWise"
                >
                  <img
                    style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg"
                    alt=""
                  />
                </div>
              </div> -->


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast position="bottom-right"></p-toast>
