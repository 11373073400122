import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { TaxreconcilationService } from 'src/app/core/services/accounts/taxreconcilation.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Taxreconcilationview } from 'src/app/core/Views/Taxreconcilationview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-tax-reconcilation',
  templateUrl: './v-tax-reconcilation.component.html',
  styleUrls: ['./v-tax-reconcilation.component.css']
})
export class VTaxReconcilationComponent implements OnInit {

  selectedrows:any;
  _branchid : number;
  _userID: number;
  _Taxreconcilationview:Taxreconcilationview ;
  _Taxreconcilationviews:  Taxreconcilationview []=[];
  _IsProgressSpinner: boolean = true;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _roleid=sessionStorage["currentRole"]
  _userid = sessionStorage["userid"];
  cols: any[];
  _Action: IAction;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
    constructor(
      private fb: FormBuilder,
      private _TaxreconcilationService:TaxreconcilationService,
      private router: Router,
      private utility:CommonUtilities,
      private messageService: MessageService,
      private _CustomExceptionService: CustomExceptionService,
      private _AccessRightsService: AccessRightsService,
      public exportUtility: ExportUtility,
      private _hotkeysService: HotkeysService) 
      {
         //Go To Create Page
    this._hotkeysService.add(new Hotkey('alt+n', (event: KeyboardEvent): boolean => {
      this.GoToCreate(event);
      return false; // Prevent bubbling
    }));
   }
 
    
  
    ngOnInit(): void {
      this._Action = this._AccessRightsService.getAccessRights(73);
      this._branchid = sessionStorage["BranchID"];
      this.utility.pageLoadScript();
      this.cols = [
        { field: 'branchname', header: 'Branch Name' },
        { field: 'date', header: 'Date' },
        { field: 'recordstatusname', header: 'Status' },
        { field: 'createdbyname', header: 'Created By' },
        { field: 'createdon', header: 'Created On' },
      ]
      this.fromdate = new Date();
      this.todate = new Date();
      this.Fetchall();
     
    }
    Fetchall(){
      if (!this.fromdate) {
        this._CustomExceptionService.handleError("Please Select From Date");
        return;
      }
      if (!this.todate) {
        this._CustomExceptionService.handleError("Please Select To Date");
        return;
      }
      let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
      let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
      this._branchid = parseInt(sessionStorage["currentbranchid"]);
      if (fromdate > todate) {
        this._CustomExceptionService.handleError("From Date should be less than the To Date");
        return;
      }
      this._IsProgressSpinner = true;
      this._TaxreconcilationService.FetchAll(this._userid,this._branchid,this._roleid,fromdate,todate).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Taxreconcilationviews = resultJSON.taxreconcilationviews;
        this._IsProgressSpinner = false;
        console.log("pageload");
      },  
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    onRowSelect(event) {
    }
    onRowUnselect(event) {
    }
    view(event) {
      this.router.navigate(['/cTaxreconcilation'], { state: { recPayTaxid: event.recPayTaxid , action : 'view' } });
    }
    GoToCreate(event){
      this.router.navigate(['/cTaxreconcilation']);
    }
  
    Cancel(event) {
      this._IsProgressSpinner = true;
      var recPayTaxid = event.recPayTaxid;
      this._TaxreconcilationService.Cancel(recPayTaxid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this._IsProgressSpinner = false;

      },  
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    exportExcel() {
      let TaxReconcilationList = this.GetTaxReconcilationList();
      this.exportUtility.exportExcel(TaxReconcilationList, 'TaxReconcilation', 'xlsx');
    }
  
    ExportToPdf() {
      let TaxReconcilationList = this.GetTaxReconcilationList();
      this.exportUtility.ExportToPdf(TaxReconcilationList, 'TaxReconcilation.pdf');
    }
    // this.cols = [
    //   { field: 'branchname', header: 'Voucher No' },
    //   { field: 'date', header: 'Date' },
    //   { field: 'recordstatusname', header: 'Status' },
    //   { field: 'createdbyname', header: 'Created By' },
    //   { field: 'createdon', header: 'Created On' },
    // ]
  
    GetTaxReconcilationList() {
      if (!this._Taxreconcilationviews || !this._Taxreconcilationviews.length)
        return;
      let ReceiptList = [];
      this._Taxreconcilationviews.forEach(element => {
        let receipt: any = {};
        receipt["Voucher No"] = element.branchname;
        receipt["Date"] = element.date;
        receipt["Status"] = element.recordstatusname;
        receipt["Created By"] = element.createdbyname;
        receipt["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
        ReceiptList.push(receipt);
      });
      return ReceiptList;
    }
}
 