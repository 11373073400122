import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Slidemanagement } from 'src/app/core/Models/SliderManagement';
import { SlidermanagementService } from 'src/app/core/Services/ecommerce/slidermanagement.service';
import { UploadFileService } from 'src/app/core/Utilities/fileupload/upload-file.service';
import * as ace from "ace-builds";
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { NgxImageCompressService } from 'ngx-image-compress';
declare var $: any;
@Component({
  selector: 'app-c-slider-management',
  templateUrl: './c-slider-management.component.html',
  styleUrls: ['./c-slider-management.component.css']
})
export class CSliderManagementComponent implements OnInit {
  _SliderManagementform: FormGroup;
  _IsProgressSpinner: boolean = true;
  _status: Metadatum;
  selectedCities: string[] = [];
  city: string;
  checked1: boolean = false;
  disabled: boolean = true;
  displayBasic: boolean;
  displayBasic1: boolean;
  imageCropperTool: boolean;
  position: string;
  defaultDate: Date;
  uploadedFiles: any[] = [];

  _LastAddedGridDocument: File;
  fileUpload: any;
  imageChangedEvent: any;
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  adimage: string;
  _SliderManagement: Slidemanagement;
  _SliderManagementId: number;
  _action: string = "create";
  iii: string;
  _SliderManagementObj: any;
  _CropImage: File;
  _SliderFORM: FormData;
  slidermanagement: SafeUrl;
  displayimagepopup: boolean;
  position1: string;
  _LastAddedImage: File;
  imageselect: string = "";
  _Iscreatefileupload: boolean = true;
  _Isviewfileupload: boolean = true;
  disablereset: boolean = false;
  isDisabledSave: boolean = false;
  clearimage: boolean = false;
  Weburl: any;
  Mobileurl: any;
  _WebImage: File;
  _MobileImage: File;
  // @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder,
    private _uploadService: UploadFileService, private _router: Router,
    private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService,
    private _DomSanitizationService: DomSanitizer,
    private _SliderManagementService: SlidermanagementService,
    private imageCompress: NgxImageCompressService) {

  }
  InitializeForm() {
    this._SliderManagementform = this.fb.group({
      slidemanagementid: [0],
      slideurl: [],
      slideurlname: [],
      slidename: [],
      status: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      mobileslideurl: [],
      mobileslideurlname: [],

    });
    this._SliderManagementform.controls['status'].setValue("MSC00001");
  }
  showBasicDialog(position: string) {
    this.position = position;
    this.displayBasic = true;
  }
  showBasicDialog1() {
    this.displayBasic1 = true;
  }
  RemoveFile() {
    //this.uploadedFiles.push(file);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    // console.log("Image", this.imageChangedEvent);
    this.imageCropperTool = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    // console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    // console.log('Load failed');
  }
  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._SliderManagementId = history.state?.slidemanagementid ? history.state?.slidemanagementid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._IsProgressSpinner = true;
    this._SliderManagementService.PageOnload(this._SliderManagementId).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false
      this._status = resultJSON.status;
      $(document).on("change", ".file-upload-field", function () {
        $(this).parent(".file-upload-wrapper").attr("data-text", $(this).val().replace(/.*(\/|\\)/, ''));
      });
      this._IsProgressSpinner = false;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        // console.log(updateJSON);
        if (updateJSON.slidemanagement.slideurl) {
          this.slidermanagement = this._DomSanitizationService.bypassSecurityTrustUrl((updateJSON.slidemanagement.slideurl));
        }
        this._SliderManagementform.setValue(updateJSON.slidemanagement);

      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    if (this._action == 'view') {
      this.clearimage = true

      this.isDisabledSave = true
      this._SliderManagementform.disable();
    }

    if (this._action == 'create') {
      this._Iscreatefileupload = true;
      this._Isviewfileupload = false;
    }
    if (this._action == 'edit' || this._action == 'view') {
      this.disablereset = true
      this._Isviewfileupload = true;
      this._Iscreatefileupload = false;
    }

  }
  selectFile(event: any) {

    if (event.target.files && event.target.files[0]) {
      const img = new Image();
      img.src = URL.createObjectURL(event.target.files[0]);
      img.onload = (e: any) => {
        if (e.srcElement.width == 1600 && e.srcElement.height == 540) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            let url = event.target.result;
            this.webcompressFile(url, -1)
          }
          reader.readAsDataURL(event.target.files[0]);
        } else {
          $(".file-upload-wrapper").attr("data-text", 'Select your file!');
          this._CustomExceptionService.handleWarning("Your uploaded image should be minimum Width and height of 1600px X 540px")
        }
      }
     
      
    }
  }

  webcompressFile(image: any, orientation: any) {
    this.imageCompress
      .compressFile(image, orientation, 100, 70)
      .then((result: any) => {
        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        this.Weburl = imageBlob

        this.mobilecompressFile(result, -1);
      });

  }
  mobilecompressFile(image: any, orientation: any) {
    this.imageCompress
      .compressFile(image, orientation, 25, 70)
      .then((result: any) => {
        console.log("New Image 2 : ", result);
        console.warn('Size in bytes is now 2:', this.imageCompress.byteCount(result));
        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        this.Mobileurl = imageBlob
        this.createBlobImageFileAndShow(this.Weburl, this.Mobileurl);
      });
    // });
  }
  createBlobImageFileAndShow(web: any, mobile: any): void {
    // const imageBlob: Blob = base64ToFile(this.croppedImage);
    const webimageName: string = this.generateName();
    const mobileimageName: string = webimageName;
    this._WebImage = new File([web], webimageName, {
      type: "image/webp"
    });
    this._MobileImage = new File([mobile], mobileimageName, {
      type: "image/webp"
    });
  }

  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/webp' });
    return blob;
  }

  formconverstion(_SliderManagement: Slidemanagement, _webimage: File, _mobileimage: File) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    if (_webimage != undefined && _mobileimage != undefined) {
      if (_SliderManagement.slideurlname == this._WebImage.name && _SliderManagement.mobileslideurlname == this._MobileImage.name) {

        // var file = base64ToFile(this.croppedImage);
        var WebNewFileName = time.getTime() + '_' + this._WebImage.name;
        var MobileNewFileName = time.getTime() + '_' + this._MobileImage.name;
        _SliderManagement.slideurlname = WebNewFileName;
        _SliderManagement.slideurl = WebNewFileName;
        _SliderManagement.mobileslideurl = MobileNewFileName;
        _SliderManagement.mobileslideurlname = MobileNewFileName;
        formData.append('webfile', _webimage, WebNewFileName);
        formData.append('mobilefile', _mobileimage, MobileNewFileName);
      }
    }

    this._SliderManagementObj = {
      "Slidemanagement": this._SliderManagement


    };
    var slidermanagements = JSON.stringify(this._SliderManagementObj);
    formData.append('Slidemanagement', slidermanagements);
    return (formData);
  }
  onSave() {
    this._SliderManagement = this._SliderManagementform.value;
    // this._SliderManagement.slidemanagementid = 0;
    if (this._action == 'create') {
      if (this._WebImage == undefined || this._WebImage == null || this._MobileImage == undefined || this._MobileImage == null) {
        this._CustomExceptionService.handleWarning("Please select Slider image");
        return
      } else {
        this._SliderManagement.slideurl = this._WebImage.name;
        this._SliderManagement.slideurlname = this._WebImage.name;
        this._SliderManagement.mobileslideurl = this._MobileImage.name;
        this._SliderManagement.mobileslideurlname = this._MobileImage.name;
      }
      this._SliderManagement.createdby = sessionStorage["userid"];
      this._SliderManagement.createdon = new Date();
      this._SliderManagement.modifiedon = new Date();
      this._SliderManagement.modifiedby = sessionStorage["userid"];
      this._SliderFORM = this.formconverstion(this._SliderManagement, this._WebImage, this._MobileImage);
      // this.onEd()
      this._IsProgressSpinner = true;
      this._SliderManagementService.create(this._SliderFORM).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._IsProgressSpinner = false;
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
          this.reset()
          // console.log("Save", resultJSON);
        }
        else {
          this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          this._IsProgressSpinner = false;
        }
        this._IsProgressSpinner = false;

      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    else if (this._action == 'edit') {
      if (this._WebImage == undefined && this._MobileImage == undefined) {
        this._SliderManagement.slideurl = this._SliderManagementform.get("slideurl").value;
        this._SliderManagement.slideurlname = this._SliderManagementform.get("slideurlname").value;
        this._SliderManagement.mobileslideurl = this._SliderManagementform.get("mobileslideurl").value;
        this._SliderManagement.mobileslideurlname = this._SliderManagementform.get("mobileslideurlname").value;
      }
      if (this._WebImage && this._MobileImage) {
        this._SliderManagement.slideurl = this._WebImage.name;
        this._SliderManagement.slideurlname = this._WebImage.name;
        this._SliderManagement.mobileslideurl = this._MobileImage.name;
        this._SliderManagement.mobileslideurlname = this._MobileImage.name;
      }
      this._SliderManagement.modifiedon = new Date();
      this._SliderManagement.modifiedby = sessionStorage["userid"];
      this._SliderFORM = this.formconverstion(this._SliderManagement, this._WebImage, this._MobileImage);
      // this.onEd();
      this._IsProgressSpinner = true;
      this._SliderManagementService.update(this._SliderFORM).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._IsProgressSpinner = false;
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          // console.log("Save", resultJSON);
        }
        else {
          this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          this._IsProgressSpinner = false;
        }

      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

    }


  }

  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = "";
    const possibleText: string =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + "." + text + ".webp";
  }
  saveImage(event) {
    var imgWidth = $('.source-image').get(0).naturalWidth;
    var imgheight = $('.source-image').get(0).naturalHeight;
    // console.log("width", imgWidth)
    // console.log("height", imgheight)
    // imgWidth >= 494.984 && imgheight >= 266.172
    if (imgWidth >= 1600 && imgheight >= 540) {
      // this.createBlobImageFileAndShow();
    } else {
      this._CustomExceptionService.handleWarning("Your uploaded image should be minimum Width and height of 1600px X 540px")
      // alert("ok")
    }
    // this.createBlobImageFileAndShow();
    this.imageCropperTool = false

  }

  // onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._SliderManagementObj;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }
  showImageDialog(position1: string) {
    if (this._LastAddedImage) {
      this.slidermanagement = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedImage));
      this.imageselect = this._LastAddedImage.name;
    }
    if (this.slidermanagement == null) {
      return;
    }
    this.position1 = position1;
    this.displayimagepopup = true;
  }

  // showImageDialog(position1: string) {
  //   if (this._LastAddedImage) {
  //     this.admanagement = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedImage));
  //     this.imageselect = this._LastAddedImage.name;
  //   }
  //   if (this.admanagement == null) {
  //     return;
  //   }
  //   this.position1 = position1;
  //   this.displayimagepopup = true;
  // }

  onImageClear() {
    this._Iscreatefileupload = true;
    this._Isviewfileupload = false;
    this.slidermanagement = null;
    this._LastAddedImage = null;
  }
  showPopupDialog(position1: string) {
    this.position1 = position1;
    this.displayimagepopup = false;
  }
  reset() {
    this.InitializeForm();
    this.croppedImage = [];
    this.imageselect = "";
    $(".file-upload-wrapper").attr("data-text", 'Select your file!');
  }
}

