<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Sales Commision </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" [routerLink]="['/cSalescommission']" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="exportPdf()"></button>
                                <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                    (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                    pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_Salescommissionviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['salestypename','effectivefrom','effectiveto','salescommissionstatusname']" [rowHover]="true" dataKey="salestypename"
                            [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">

                            <ng-template pTemplate="header">
                                <tr>
                                    <!-- 27.search page has grid which contains (action,Salesman Type,base target,basecommision,effective from,effective to,approval status,status) -->
                                    <th pSortableColumn="Action" style="text-align: center; width: 125px;">Action
                                        <p-sortIcon field="id">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="salestypename">Branch
                                        <p-sortIcon field="salestypename">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="salestypename">Salesman Type
                                        <p-sortIcon field="salestypename">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="effectivefrom">Effective From
                                        <p-sortIcon field="effectivefrom">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="effectiveto">Effective To
                                        <p-sortIcon field="effectiveto">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="salescommissionstatusname">Status
                                        <p-sortIcon field="salescommissionstatusname"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_Salescommissionview>
                                <tr>
                                    <td class="text-center">
                                        <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2" [disabled]="!_Action._View" (click)="view(_Salescommissionview)"></button>
                                        <button pButton pRipple icon="las la-pen" title="Edit" [disabled]="_Salescommissionview.salescommissionstatus=='MSC00002' || !_Action._Update " class="p-button p-button-success p-mr-2" (click)="edit(_Salescommissionview)"></button>
                                        <button pButton pRipple icon="pi pi-times" title="Delete" class="p-button p-button-danger p-mr-2" (click)="confirm(_Salescommissionview)" [disabled]="_Salescommissionview.salescommissionstatus=='MSC00002' || !_Action._Delete"></button>
                                    </td>
                                    <td>
                                        {{_Salescommissionview.branchname}}
                                    </td>
                                    <td>
                                        {{_Salescommissionview.salestypename}}
                                    </td>
                                    <td>
                                        {{_Salescommissionview.effectivefrom |date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_Salescommissionview.effectiveto |date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_Salescommissionview.salescommissionstatusname}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>