import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class BrandService {
  public _Action: IAction;
  public _BrandService = environment.BrandService;
  public _httpOptions;
  token: any;
  header: any;
  constructor(
    private http: HttpClient,
  ) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 1)
  }
  createHeaders(token: any, type: number): HttpHeaders {
    // if (type == 1) {
      return new HttpHeaders({
        Authorization: `Bearer ${token}`, // Include your token here	
      });
    // }
    // else{
    //   return new HttpHeaders({
    //     Authorization: `Bearer ${token}`, // Include your token here	
    //   });
    // }
  }
  PageOnLoad(): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BrandService;
    console.log(Query);
    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll() {
    const headers = this.createHeaders(this.token, 2)
    // 15.all the datas should fetch in particular columns.based on country code
    var Query = this._BrandService + '/FetchAll';
    // console.log(Query);
    return this.http.get<{ data: any }>(Query,{ headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      return data;
    }));
  }

  Create(brand: FormData) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BrandService + '/Create';
    console.log(Query);
    return this.http.post<{ data: any }>(Query, brand, { headers });
  }

  Update(brand: FormData) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BrandService + '/Update';
    console.log(Query);
    return this.http.put<{ data: any }>(Query, brand, { headers });
  }

  edit(brand: object) {
    const headers = this.createHeaders(this.token, 2)
    console.log("Update");
    var Query = this._BrandService;
    console.log(Query);
    return this.http.put<{ data: any }>(Query, brand, { headers });
  }


  View(brandid: number): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BrandService + '/View' + '/' + brandid;
    console.log(Query);
    return this.http.get(Query, { headers });
  }
  Delete(brandid: number): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BrandService + '/Delete' + '/' + brandid;
    console.log(Query);
    return this.http.get(Query, { headers });
  }
}
