import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ContraService } from 'src/app/core/Services/accounts/contra.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Contraview } from 'src/app/core/Views/Contraview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
	selector: 'app-v-contra',
	templateUrl: './v-contra.component.html',
	styleUrls: ['./v-contra.component.css']
})
export class VContraComponent implements OnInit {
	_Add: Boolean;
	_delete: Boolean;
	_update: Boolean;
	_View: Boolean;
	_Action: IAction
	selectedrows: any;
	pSelectableRow: any;
	_Delete: Boolean;
	_Update: Boolean;
	_contraviews: Contraview[];
	_IsProgressSpinner: boolean = true;
	fromdate: Date;
	todate: Date;
	datepipe = new DatePipe('en-US');
	_branchid: number;
	_roleid = sessionStorage["currentRole"];
	cols: any[];
	mindate: Date;
	maxdate: Date;
	constructor(
		private fb: FormBuilder,
		private _ContraService: ContraService,
		private router: Router,
		private utility: CommonUtilities,
		private _AccessRightsService: AccessRightsService,
		private confirmationService: ConfirmationService,
		private _CustomExceptionService: CustomExceptionService,
		private exportUtility: ExportUtility,
		private _hotkeysService: HotkeysService
	) {
		this._hotkeysService.add(new Hotkey('alt+n', (event: KeyboardEvent): boolean => {
			this.GoToCreate(event);
			return false; // Prevent bubbling
		}));
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(64);
		this.utility.pageLoadScript();
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.cols = [
			{ field: 'voucherno', header: 'Voucher No' },
			{ field: 'fromaccountname', header: 'From Account' },
			{ field: 'toaccountname', header: 'To Account' },
			{ field: 'amount', header: 'Amount' },
			{ field: 'currencyname', header: 'Currency' },
			{ field: 'narration', header: 'narration' },
			{ field: 'contrastatusdesc', header: 'Status' },
			{ field: 'createdbyname', header: 'Created By' },
			{ field: 'createdon', header: 'Created On' },
		]
		if (sessionStorage['Environmentenddate'] == "null") {
			this.fromdate = new Date();
			this.todate = new Date();
			this.maxdate = new Date();
		  }
		  else {
			this.fromdate = new Date(sessionStorage['Environmentenddate']);
			this.todate = new Date(sessionStorage['Environmentenddate']);
		  }
		this.Fetchall();
	}
	Fetchall() {
		if (!this.fromdate) {
			this._CustomExceptionService.handleError("Please Select From Date");
			return;
		}
		if (!this.todate) {
			this._CustomExceptionService.handleError("Please Select To Date");
			return;
		}
		let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
		let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
		this._branchid = parseInt(sessionStorage["currentbranchid"]);
		if (fromdate > todate) {
			this._CustomExceptionService.handleError("From Date should be less than the To Date");
			return;
		}
		var userid = parseInt(sessionStorage["userid"]);
		this._IsProgressSpinner = true;
		this._ContraService.FetchAll(fromdate, todate, userid, this._roleid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._contraviews = resultJSON.contraviews;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	onRowSelect(event) {
		console.log("eventfired");
		console.log(event.data);
		console.log(this.selectedrows);
	}
	onRowUnselect(event) {
		console.log("eventUnfired");
		console.log(event.data);
		console.log(this.selectedrows);
	}
	view(event) {
		this.router.navigate(['/cContra'], { state: { contraid: event.contraid, action: 'view' } });
	}
	delete(event) {
		alert("delete");
	}
	Cancel(event) {
		var contraid = event.contraid;
		var userid = parseInt(sessionStorage["userid"]);
		this._IsProgressSpinner = true;
		this._ContraService.Cancel(contraid, userid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this.Fetchall();
			this._IsProgressSpinner = false;
			this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	confirm(event) {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to cancel this Voucher <b>"' + event.voucherno + '"</b> ?',
			accept: () => {
				this.Cancel(event);
			}
		});
	}
	GoToCreate(event) {
		this.router.navigate(['/cContra']);
	}
	exportExcel() {
		let ContraList = this.GetContraList();
		this.exportUtility.exportExcel(ContraList, 'Contra', 'xlsx');
	}
	ExportToPdf() {
		let ContraList = this.GetContraList();
		this.exportUtility.ExportToPdf(ContraList, 'Contra.pdf');
	}
	GetContraList() {
		if (!this._contraviews || !this._contraviews.length)
			return;
		let ContraList = [];
		this._contraviews.forEach(element => {
			let contra: any = {};
			contra["Voucher No"] = element.voucherno;
			contra["From Account"] = element.fromaccountname
			contra["To Account"] = element.toaccountname;
			contra["Amount"] = element.amount;
			contra["Currency"] = element.currencyname;
			contra["narration"] = element.narration;
			contra["Status"] = element.contrastatusdesc;
			contra["Created By"] = element.createdbyname;
			contra["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
			ContraList.push(contra);
		});
		return ContraList;
	}
	SendToApproval(event) {
		var workflowid = 63;
		var _Userid = sessionStorage["userid"];
		var _branchid = parseInt(sessionStorage["BranchID"]);
		this._IsProgressSpinner = true;
		let data = {
			"workflowid": workflowid,
			"Userid": _Userid,
			"branchid": _branchid,
			"Contraid": event.contraid,
		}
		this._ContraService.SendToApproval(data).subscribe((result: any) => {
			if (result && result.tranStatus.result) {
				this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
				this.ngOnInit();
			} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); }
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	Print(event) {
		var voucherno = event.voucherno;
		var branchid = sessionStorage["BranchID"];
		// this._IsProgressSpinner = true;
		this._ContraService.ContraPrint({ "voucherno": voucherno, "branchid": branchid }).subscribe((result: any) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			let url: any = URL.createObjectURL(result);
			window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
}