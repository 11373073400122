import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RewardService {
  //public _Action: IAction;
  public _RewardService = environment.RewardService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(p_rewardid: number): Observable<any> {
		const headers = this.header;
    var Query = this._RewardService + '/PageOnload/' + p_rewardid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll() {
		const headers = this.header;
    var Query = this._RewardService + '/FetchAll';

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);

      return data;
    }));
  }
  // Create
  create(p_reward: object) {
		const headers = this.header;
    console.log("Create");
    var Query = this._RewardService;
    return this.http.post<{ data: any }>(Query, p_reward, { headers });
  }

  // Edit
  edit(p_reward: object) {
		const headers = this.header;
    console.log("Update");
    var Query = this._RewardService;

    return this.http.put<{ data: any }>(Query, p_reward, { headers });
  }
  SendToApproval(workflowid: number, userid: number, branchid: number, rewardid: number) {
		const headers = this.header;
    var Query = this._RewardService + '/SendToApproval' + '/' + workflowid + '/' + userid + '/' + branchid + '/' + rewardid;

    return this.http.post<{ data: any }>(Query, '', { headers });
  }
  Cancel(rewardid: number, userid: number) {
		const headers = this.header;
    var Query = this._RewardService + '/ChangeStatus' + '/' + rewardid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
}
