<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product Price Change</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                [disabled]="isDisabledClear" (click)="onSave()"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                [disabled]="isDisabledClear" icon="pi pi-trash" class=""
                                class="p-button-danger"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check"
                                [disabled]="isDisabledSendtoapproval" (click)="SendToApproval($event)"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_pricechangeform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <form [formGroup]="_pricechangetypeform">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="true" [options]="_ProductTypes"
                                            optionLabel="producttypename" optionValue="producttypeid"
                                            formControlName="producttypeid" displaySelectedLabel=true
                                            (onChange)="GetProductCategory($event)">
                                        </p-multiSelect>
                                        <label for="producttypeid">Product Type</label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_pricechangetypeform">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="true" [options]="_ProductCategories"
                                            optionLabel="categoryname" optionValue="productcategoryid"
                                            formControlName="productcategoryid" displaySelectedLabel=true
                                            (onChange)="GetProductName($event)">
                                        </p-multiSelect>
                                        <label for="productcategoryid">Product Category</label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_pricechangetypeform">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="true" [options]="_ProductNames"
                                            optionLabel="productname" optionValue="productid"
                                            formControlName="productid" displaySelectedLabel=true
                                            (onChange)="GetProductPrice($event)"> </p-multiSelect>
                                        <label for="productid">Product Name</label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_pricechangetypeform">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="true" [options]="_brandlist" optionLabel="brandname"
                                            optionValue="brandid" formControlName="brandid" displaySelectedLabel=true
                                            (onChange)="Onchangebrand($event)"> </p-multiSelect>
                                        <label for="productid">Brand Name </label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [disabled]="true" [options]="_Currencies"
                                        optionLabel="currencyname" optionValue="currencyid" formControlName="currencyid"
                                        (onChange)="OnCurrencychange($event)"></p-dropdown>
                                    <label for="currencyid">Currency<span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox formControlName="isincrease" label="Increase"
                                            (onChange)="disableincrease($event)" binary="true"></p-checkbox>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText name="increased" [disabled]="_isDisabledincrease"
                                        maxlength="7" formControlName="increased"
                                        (keypress)="keypress.kpDecimalInput($event)"
                                        (keyup)="OnIncreasePercentage($event)">
                                    <label for="increased">Increase</label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox formControlName="isdecrease" label="Decrease"
                                            (onChange)="disabledecrease($event)" binary="true"></p-checkbox>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText name="decreased" formControlName="decreased"
                                        maxlength="7" (keypress)="keypress.kpDecimalInput($event)"
                                        (keyup)="OnDecreasedPercentage($event)">
                                    <label for="decreased">Decrease</label>
                                </span>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_pricechangedetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['productname','currencyname','oldrate','newrate']" [rowHover]="true"
                        dataKey="productid" [(selection)]="selectedrows"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Product Name</th>
                                <th>Brand Name</th>
                                <th>Currency Name</th>
                                <th>Old Rate</th>
                                <th>New Rate</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_pricechangedetail>
                            <tr>
                                <td>{{_pricechangedetail.productname}}</td>
                                <td>{{_pricechangedetail.brandname}}</td>
                                <td>{{_pricechangedetail.currencyname}}</td>
                                <td>{{_pricechangedetail.oldrate| number: '1.3' }}</td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_pricechangedetail.newrate" pInputText
                                                *ngIf="_action == 'create'">
                                            <div *ngIf="_action != 'create'">
                                                {{_pricechangedetail.newrate | number: '1.3'}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_pricechangedetail.newrate | number: '1.3'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>