import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/core/domain/appconfig';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Customer } from 'src/app/core/Models/Customer';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Component, OnInit } from '@angular/core';
import { Stockadjustmentdetail } from 'src/app/core/Models/Stockadjustmentdetail';
import { PurchasereturndashboardService } from 'src/app/core/Services/dashboard/purchasereturndashboard.service';
import { Supplier } from 'src/app/core/Models/Supplier';

@Component({
  selector: 'app-purchasereturn-dashboard',
  templateUrl: './purchasereturn-dashboard.component.html',
  styleUrls: ['./purchasereturn-dashboard.component.css']
})
export class PurchasereturnDashboardComponent implements OnInit {

  _IsProgressSpinner: boolean = true;
  productsalesdetails: [];
  _Isbarsd: boolean = true; _Islinesd: boolean = true; _Ispiesd: boolean = true; _Isdoughnutsd: boolean = true;
  _Isbarsd1: boolean = true; _Islinesd1: boolean = true; _Ispiesd1: boolean = true; _Isdoughnutsd1: boolean = true;
  lableData: any = [];
  lableData1: any = [];
  trimlableData: any = [];
  trimlableData1: any = [];
  _productname: Productheader[] = [];
  salesValue: any = [];
  salesValue1: any = [];
  lableData3: any = [];
  trimlableData3: any = [];
  salesValuepay: any = [];
  salesValuerec: any = [];
  basicData: any;
  basicData1: any;
  basicData2: any;
  _productdetails: Stockadjustmentdetail[] = [];
  basicOptions: any;
  multiAxisData: any;
  _submitted1 = false;
  chartOptions: any;
  _productsubmitted = false;
  subscription: Subscription;
  _purchasereturndashboardform: FormGroup;
  _supplierwisedashboardform: FormGroup;
  filterBranchs: any[] = [];
  _Branches: Branch[] = [];
  _Suppliers: Supplier[];
  filterSuppliers: any[] = [];
  config: AppConfig;
  charttype: any;
  userid: number;
  branchid: number;
  fromdate: Date;
  todate: Date;
  supplierid: number;
  _producttype: Producttype[] = [];
  _productcategory: Productcategory[] = [];
  constructor(private _purchasereturnDashboardService: PurchasereturndashboardService, private fb: FormBuilder, private _CustomExceptionService: CustomExceptionService) { }

  ngOnInit() {
    this._purchasereturndashboardform = this.fb.group({
      branchid: [],
      fromdate: [],
      todate: [new Date()],
      supplierid: [],
      producttypeid: [],
      productcategoryid: [],
      productid: []
    })
    this._supplierwisedashboardform = this.fb.group({
      branchid: [],
      supplierid: []
    })
    this._Isbarsd = true; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = false;
    this._Isbarsd1 = true; this._Islinesd1 = false; this._Ispiesd1 = false; this._Isdoughnutsd1 = false;
    var dt = new Date();
    dt.setDate(dt.getDate() - 30);
    this._purchasereturndashboardform.controls['fromdate'].setValue(dt);
    this.config = this._purchasereturnDashboardService.config;
    this.updateChartOptions();
    this.subscription = this._purchasereturnDashboardService.configUpdate$.subscribe(config => {
      this.config = config;
      this.updateChartOptions();
    });

    this._IsProgressSpinner = true;
    this._purchasereturnDashboardService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._Branches = <Branch[]>resultJSON.branches;
      this._Suppliers = <Supplier[]>resultJSON.suppliers;
      this._producttype = resultJSON.producttypes;
      this.onChangefilterSupplierPurchaseReturn();
      this.onChangefilterMonthwisePurchaseReturn();
    });
    this._IsProgressSpinner = false;

  }
  onChangefilterMonthwisePurchaseReturn() {
    var branchid = parseInt(sessionStorage["currentbranchid"]) || 0;
    this.userid = parseInt(sessionStorage["userid"]);
    var userid = this.userid;
    var _fromdate = this._purchasereturndashboardform.get("fromdate").value;
    var _todate = this._purchasereturndashboardform.get("todate").value;
    this._purchasereturnDashboardService.MonthwisePurchaseReturn(this.userid, branchid, _fromdate, _todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this.lableData=[]; this.trimlableData=[]; this.salesValue=[]; this.basicData1=[];
      for (let i = 0; i < resultJSON.monthWisePurchaseReturndetails.length; i++) {
        this.lableData[i] = resultJSON.monthWisePurchaseReturndetails[i].PRODUCTNAME;
        this.trimlableData[i] = (resultJSON.monthWisePurchaseReturndetails[i].MONTH).slice(0, 15) + '...';
        this.salesValue[i] = resultJSON.monthWisePurchaseReturndetails[i].PURCHASERETURNVALUE;
        this.basicData1 = {
          labels: this.trimlableData,
          datasets: [
            {
              label: '',
              backgroundColor: ['#42A5F5',
                "#FF6384",
                "#36A2EB",
                "#FFCE56"],
              data: this.salesValue
            }
          ]
        };
      }
    });
  }
  MonthwisePurchaseReturn(event){
    var branchid =event.branchid || 0;
    this.userid = parseInt(sessionStorage["userid"]);
    var userid = this.userid;
    var _fromdate = this._purchasereturndashboardform.get("fromdate").value;
    var _todate = this._purchasereturndashboardform.get("todate").value;
    this._purchasereturnDashboardService.MonthwisePurchaseReturn(this.userid, branchid, _fromdate, _todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.lableData=[]; this.trimlableData=[]; this.salesValue=[]; this.basicData1 =[];
      console.log(resultJSON);     
      for (let i = 0; i < resultJSON.monthWisePurchaseReturndetails.length; i++) {
        this.lableData[i] = resultJSON.monthWisePurchaseReturndetails[i].PRODUCTNAME;
        this.trimlableData[i] = (resultJSON.monthWisePurchaseReturndetails[i].MONTH).slice(0, 15) + '...';
        this.salesValue[i] = resultJSON.monthWisePurchaseReturndetails[i].PURCHASERETURNVALUE;
        this.basicData1 = {
          labels: this.trimlableData,
          datasets: [
            {
              label: '',
              backgroundColor: ['#42A5F5',
                "#FF6384",
                "#36A2EB",
                "#FFCE56"],
              data: this.salesValue
            }
          ]
        };
      }
    });
  }
  onChangefilterSupplierPurchaseReturn() {
    this._IsProgressSpinner = true;
    var branchid =parseInt(sessionStorage["currentbranchid"]) || 0;
    var supplierid = this._purchasereturndashboardform.get("supplierid").value || 0;
    this.userid = parseInt(sessionStorage["userid"]);
    var userid = this.userid;
    var _fromdate = this._purchasereturndashboardform.get("fromdate").value;
    var _todate = this._purchasereturndashboardform.get("todate").value;
    this._purchasereturnDashboardService.SuppliermonthwisePurchaseReturn(this.userid, supplierid, branchid, _fromdate, _todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this.lableData1=[]; this.trimlableData1=[]; this.salesValue1=[];  this.basicData2 =[];
      for (let i = 0; i < resultJSON.purchaseReturndetails.length; i++) {
        this.lableData1[i] = resultJSON.purchaseReturndetails[i].PRODUCTNAME;
        this.trimlableData1[i] = (resultJSON.purchaseReturndetails[i].MONTH).slice(0, 15) + '...';
        this.salesValue1[i] = resultJSON.purchaseReturndetails[i].PURCHASERETURNVALUE;
        this.basicData2 = {
          labels: this.trimlableData1,
          datasets: [
            {
              label: '',
              backgroundColor: ['#42A5F5',
                "#FF6384",
                "#36A2EB",
                "#FFCE56"],
              data: this.salesValue1
            }
          ]
        };
      }
    });
    this._IsProgressSpinner = false;
  }
  SuppliermonthwisePurchaseReturn(event){
    this._IsProgressSpinner = true;
    var branchid = event.branchid || 0;
    var supplierid = event.supplierid || 0;
    this.userid = parseInt(sessionStorage["userid"]);
    var userid = this.userid;
    var _fromdate = this._purchasereturndashboardform.get("fromdate").value;
    var _todate = this._purchasereturndashboardform.get("todate").value;
    this._purchasereturnDashboardService.SuppliermonthwisePurchaseReturn(this.userid, supplierid, branchid, _fromdate, _todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this.lableData1=[]; this.trimlableData1=[]; this.salesValue1=[]; this.basicData2 =[];
      for (let i = 0; i < resultJSON.purchaseReturndetails.length; i++) {
        this.lableData1[i] = resultJSON.purchaseReturndetails[i].PRODUCTNAME;
        this.trimlableData1[i] = (resultJSON.purchaseReturndetails[i].MONTH).slice(0, 15) + '...';
        this.salesValue1[i] = resultJSON.purchaseReturndetails[i].PURCHASERETURNVALUE;
        this.basicData2 = {
          labels: this.trimlableData1,
          datasets: [
            {
              label: '',
              backgroundColor: ['#42A5F5',
                "#FF6384",
                "#36A2EB",
                "#FFCE56"],
              data: this.salesValue1
            }
          ]
        };
      }
    });
    this._IsProgressSpinner = false;
  }
  updateChartOptions() {
    if (this.config.dark)
      this.applyDarkTheme();
    else
      this.applyLightTheme();
  }
  applyDarkTheme() {
    this.basicOptions = {
      legend: {
        labels: {
          fontColor: '#ebedef'
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: '#ebedef'
          },
          gridLines: {
            color: 'rgba(255,255,255,0.2)'
          }
        }],
        yAxes: [{
          ticks: {
            fontColor: '#ebedef'
          },
          gridLines: {
            color: 'rgba(255,255,255,0.2)'
          }
        }]
      }
    };
  }

  applyLightTheme() {
    this.basicOptions = {
      legend: {
        labels: {
          fontColor: '#495057'
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: '#495057'
          }
        }],
        yAxes: [{
          ticks: {
            fontColor: '#495057'
          }
        }]
      }
    };
  }

  chartchangesd(charttype1) {

    if (charttype1 == "bar") {
      this._Isbarsd = true; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = false;
    }
    if (charttype1 == "line") {
      this._Isbarsd = false; this._Islinesd = true; this._Ispiesd = false; this._Isdoughnutsd = false;
    }
    if (charttype1 == "pie") {
      this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = true; this._Isdoughnutsd = false;
    }
    if (charttype1 == "doughnut") {
      this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = true;
    }
  }

  chartchangesd1(charttype2) {

    if (charttype2 == "bar") {
      this._Isbarsd1 = true; this._Islinesd1 = false; this._Ispiesd1 = false; this._Isdoughnutsd1 = false;
    }
    if (charttype2 == "line") {
      this._Isbarsd1 = false; this._Islinesd1 = true; this._Ispiesd1 = false; this._Isdoughnutsd1 = false;
    }
    if (charttype2 == "pie") {
      this._Isbarsd1 = false; this._Islinesd1 = false; this._Ispiesd1 = true; this._Isdoughnutsd1 = false;
    }
    if (charttype2 == "doughnut") {
      this._Isbarsd1 = false; this._Islinesd1 = false; this._Ispiesd1 = false; this._Isdoughnutsd1 = true;
    }
  }

  filterBranch(event) {
    let filtered: any[] = [];
    let query = (<string>event.query).toLowerCase();
    this.filterBranchs = this._Branches.filter(f => f.branchname.toLowerCase().indexOf(query) > -1) || [];
  }
  filterSupplier(event) {
    let filtered: any[] = [];
    let query = (<string>event.query).toLowerCase();
    this.filterSuppliers = this._Suppliers.filter(f => f.suppliername.toLowerCase().indexOf(query) > -1) || [];
  }
  OnChangeBranch(event) {
    console.log(event);
    this.branchid = event.branchid;

  }
  OnChangeSupplier(event) {
    console.log(event);
    this.supplierid = event.supplierid;

  }
}
