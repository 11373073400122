import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { MessageService } from 'primeng/api';
import { Salescustomerquicksaveview } from 'src/app/core/Models/Salescustomerquicksave';
import { Customer } from 'src/app/core/Models/Customer';
import { Currency } from 'src/app/core/Models/Currency';
import { Level } from 'src/app/core/Models/Level';
import { Salesorderothercharge } from 'src/app/core/Models/Salesorderothercharge';
import { Salesorderterm } from 'src/app/core/Models/Salesorderterm';
import { SalesorderService } from 'src/app/core/Services/sales/salesorder.service';
import { Salesproductpriceview } from 'src/app/core/Models/Salesproductpriceview';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Salesorder } from 'src/app/core/Models/Salesorder';
//Debug
import * as ace from "ace-builds";
import { vAddress, vCurrencyNameSelect, vCustomerName, vCustomerNameSelect, vEffectiveFrom, vEffectiveTo, vEmailID, vLevelNameSelect, vMobileNumber, vProductCategorySelect, vProductNameSelect, vProductTypeSelect, vQuantity, vSalesOrderDateSelect } from 'src/app/core/Validators/validation';
import { Termsandcondition } from 'src/app/core/Models/Termsandcondition';
import { Salesorderproductdetailsview } from 'src/app/core/Models/Salesorderproductdetailview';
import { count } from 'rxjs/operators';
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { KeyPress } from 'src/assets/js/KeyPress';
import { ConfirmationService } from 'primeng/api';
import { Tax } from 'src/app/core/Models/Tax';
import { Customerview } from 'src/app/core/Views/Customerview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
	selector: 'app-c-sales-order',
	templateUrl: './c-sales-order.component.html',
	styleUrls: ['./c-sales-order.component.css']
})
export class CSalesOrderComponent implements OnInit {
	_salesorderform: FormGroup;
	_salesordertermsform: FormGroup;
	_salesorderproductform: FormGroup;
	_customerform: FormGroup;
	//Drop Down

	filteredProducts: any[];


	_Customers: Salescustomerquicksaveview[] = [];
	_Customernames: Customerview[];
	_Currencies: Currency[];
	_CurrenciesTemp: Currency[];
	_CurrenciesRefTemp: Currency[];
	_MarkupLevels: Level;
	_ProductTypes: Producttype;
	_ProductCategories: Productcategory[];
	_ProductNames: Productoverallview[] = [];
	_tempproductname: Productoverallview[] = [];
	_Variants: Productvariant[];
	_Descriptions: Othercharge
	_Customer: Salescustomerquicksaveview;
	_ChargesTemp: Othercharge[];
	_Status: Metadatum;
	taxes: Tax[] = [];
	_ReferenceNos
	//Grid
	_OtherCharges: Salesorderothercharge[] = [];
	_ProductDetails: Salesorderproductdetailsview[] = []
	//Multiselect
	_PaymentTerms: Termsandcondition;
	_DeliveryTerms: Termsandcondition;
	_OtherTerms: Termsandcondition;
	_SelectedPaymentTerms: Salesorderterm[] = [];
	_SelectedOtherTerms: Salesorderterm[] = [];
	_SelectedDeliveryTerms: Salesorderterm[] = [];
	_selectedPaymentTermsIDs: number[] = [];
	_selectedDeliveryTermsIDs: number[] = [];
	_selectedOtherTermsIDs: number[] = [];
	_prevPaymentSelectedTerms: Salesorderterm[] = [];
	_prevDeliverySelectedTerms: Salesorderterm[] = [];
	_prevOtherSelectedTerms: Salesorderterm[] = [];
	displayBasic: boolean;
	minDates: Date;
	_customertype: Metadatum;
	selectedProductDetailsRows: any;
	_selectedlevel: number;
	selectedOtherChargesRows: any;
	_SalesOrderno: string;
	_userid = sessionStorage["userid"];
	_branchid = sessionStorage["currentbranchid"]
	_action: string = "create";
	_selectedcurrency: string;
	_selectedamount: number;
	_selectedproduct: number;
	_selectedcustomer: number;
	_OtherBalance: number;
	_SalesOrder: Salesorder;
	_SalesOrderObj: any;
	_salesorderid: string;
	_submitted = false;
	_customersubmitted = false;
	_submitted1 = false;
	ManageDialog: boolean;
	isDisabledsave = true;
	isDisabledcustomerdiscountpercentage = true;
	isDisabledClear = false;
	isDisabledcustomer = true;
	isDisabledcurrency = true;
	isDisabledlevel = true;
	isDisabledReference = false;
	_UnitPrice: number;
	_IsProgressSpinner: boolean = true;
	isDisabledpaymentterms = false;
	isDisableddeliveryterms = false;
	isDisabledother = false;
	// 11.Sent to approve button should be disabled
	isDisabledSendtoapproval = true;
	isDisabledSendEmail = true;
	// isDisabledPrint = true;
	isDisabledAdd = false;
	isDisabledProductClear = false;
	isDisabledRemove = false;
	isDisabledAddOtherCharges = false;
	isDisabledRemoveOtherCharges = false;
	_netamount: number;
	_Series: string;
	selectedtab: number = 0;
	productcatid: any[] = [];
	producttypeid: any[] = [];
	isDisabledQuicksave = false;
	isDisabledprint = true;
	_stockinhand: any[] = [];
	_ProductTypeObj: any;
	_ProductCategoryObj: any;
	_Action: IAction
	hidequantity: boolean = false;
	mindate: Date;
	maxdate: Date;

	//Debug
	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities,
		private confirmationService: ConfirmationService, public keypress: KeyPress,
		private messageService: MessageService, private _CustomExceptionService: CustomExceptionService,
		private _SalesOrderService: SalesorderService, private _hotkeysService: HotkeysService,
		private _AccessRightsService: AccessRightsService
	) {
		this._SalesOrderno = history.state?.salesorderno ? history.state?.salesorderno : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this.HotKeyIntegration();
	}
	InitializeSalesorderForm() {
		this._salesorderform = this.fb.group({
			companyid: [0],
			branchid: [],
			salesorderid: [0],
			salesorderno: [],
			salesorderdate: ['', vSalesOrderDateSelect],
			referenceno: [],
			customerid: ['', vCustomerNameSelect],
			currencyreferenceid: [],
			currencyid: ['', vCurrencyNameSelect],
			markuplevelid: ['', vLevelNameSelect],
			contactpersonname: [],
			mobileno: ['', vMobileNumber],
			customeraddress: [],
			shippingaddress: [],
			billingaddress: [],
			effectivefrom: ['', vEffectiveFrom],
			effectiveto: [],
			deliverydate: [],
			totalproductamount: [],
			taxamount: [],
			discountamount: [],
			otherchargesamount: [],
			advanceamount: [],
			totalsalesorderamount: [],
			amountinusd: [],
			status: [],
			statusremarks: [],
			accountingyear: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			rv: [],
			approvalstatus: [],
			customer: [],
			salesorderdetails: [],
			salesorderothercharges: [],
			salesorderterms: [],
			vattype: [],
			specificvattype: []
		});
		this._salesorderform.controls['status'].setValue("MSC00001");
		if (sessionStorage['Environmentenddate'] == "null") {
			this._salesorderform.controls['salesorderdate'].setValue(new Date());
			this._salesorderform.controls['effectivefrom'].setValue(new Date());
			this.maxdate = new Date();
			this.minDates = new Date();
		}
		else {
			this._salesorderform.controls['salesorderdate'].setValue(new Date(sessionStorage['Environmentenddate']));
			this._salesorderform.controls['effectivefrom'].setValue(new Date(sessionStorage['Environmentenddate']));
		}
		
	}
	InitializeCustomerForm() {
		this._customerform = this.fb.group({
			customerid: [0],
			customercode: [],
			customername: ['', vCustomerName],
			currencyid: [0],
			mailid: ['', vEmailID],
			mobileno: ['', vMobileNumber],
			address: ['', vAddress],
			vatno: [],
			bpno: [],
			createdby: [],
			createdon: [],
			customertype: []

		});
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(54);
		this.utility.pageLoadScript();
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.minDates = new Date(sessionStorage['Environmentenddate']);
	
		this.InitializeCustomerForm();
		this.InitializeSalesorderForm();
		this._salesordertermsform = this.fb.group({
			paymenttermsid: [],
			deliveryermsid: [],
			othertermsid: []
		});
		this._salesorderproductform = this.fb.group({
			producttypeid: [],
			productcategoryid: [],
			productid: ['', vProductNameSelect],
			variantid: [],
			uomcode: [],
			quantity: [null, [Validators.required]],
			unitprice: [],
			productamount: [],
			taxcode: [],
			taxpercentage: [],
			taxamount: [],
			discountpercentage: [],
			discountamount: [],
			accountingyear: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			stockinhand: []

		});


		this._IsProgressSpinner = true;
		this._SalesOrderService.PageOnLoad(this._SalesOrderno, this._userid, this._branchid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._salesorderform.controls['status'].setValue("MSC00001");
			// this._salesorderform.controls['salesorderdate'].setValue(new Date());
			//  2. these drop downs should be load in pageload 
			this._Customernames = resultJSON.customers;
			this._ProductTypes = resultJSON.producttypes;
			this._Currencies = resultJSON.currencies;
			this._CurrenciesRefTemp = resultJSON.currencies;
			this._MarkupLevels = resultJSON.levels;
			// 6. Delivery terms load from terms and condition master based on Terms Type
			this._DeliveryTerms = resultJSON.deliveryterms;
			// 7. Other terms load from terms and condition master based on Terms Type
			this._OtherTerms = resultJSON.otherterms;
			// 5. payment terms load from terms and condition master based on Terms Type
			this._PaymentTerms = resultJSON.paymentterms;
			this._CurrenciesTemp = resultJSON.currencies;
			this._Descriptions = resultJSON.othercharges;
			this._ChargesTemp = resultJSON.othercharges;
			this._Status = resultJSON.salesOrderstatus;
			this.taxes = resultJSON.taxes
			this._customertype = resultJSON.customertype;
			// this._ProductNames = <Productoverallview[]>resultJSON.productsearch || [];
			// this._tempproductname = <Productoverallview[]>resultJSON.productsearch || [];
			// this._ReferenceNos = resultJSON.salesorderquotationnoviews;
			this._IsProgressSpinner = false;
			if (this._action == 'edit' || this._action == 'view') {
				const updateJSON = JSON.parse(JSON.stringify(result));
				// 106.according to the Sales order no ., all the datas should fetch in particular columns.
				if (updateJSON.salesorder.effectiveto == null) {
				}
				else {
					updateJSON.salesorder.effectiveto = new Date(updateJSON.salesorder.effectiveto);
				}
				if (updateJSON.salesorder.deliverydate == null) {
				}
				else {
					updateJSON.salesorder.deliverydate = new Date(updateJSON.salesorder.deliverydate);
				}
				updateJSON.salesorder.salesorderdate = new Date(updateJSON.salesorder.salesorderdate);
				updateJSON.salesorder.effectivefrom = new Date(updateJSON.salesorder.effectivefrom);
				// updateJSON.salesorder.effectiveto = new Date(updateJSON.salesorder.effectiveto);

				this._salesorderform.setValue(updateJSON.salesorder);
				this._salesorderid = updateJSON.salesorder.salesorderno;
				this._Series = sessionStorage["Series"];
				this._OtherCharges = updateJSON.salesorderothercharges;
				this._ProductDetails = updateJSON.salesorderproductdetailsviews;

				var currencyid = updateJSON.salesorder.currencyid;
				var Name = (this._CurrenciesTemp || []).filter(f => f.currencyid == currencyid)[0].currencyname;
				this._selectedcurrency = Name;

				//Multiselect Payment terms

				var PaymentTermsIds: number[] = [];
				Object.keys(updateJSON.salesorderPaymentTermsView).map((Index) => {
					PaymentTermsIds.push(updateJSON.salesorderPaymentTermsView[Index].termsandconditionid);
				});
				this._selectedPaymentTermsIDs = PaymentTermsIds;
				this._prevPaymentSelectedTerms = updateJSON.salesorderPaymentTermsView;
				this._SelectedPaymentTerms = updateJSON.salesorderPaymentTermsView;


				//Multiselect Delivery terms

				var DeliveryTermsIds: number[] = [];
				Object.keys(updateJSON.salesorderDeliveryTermsView).map((Index) => {
					DeliveryTermsIds.push(updateJSON.salesorderDeliveryTermsView[Index].termsandconditionid);
				});
				this._selectedDeliveryTermsIDs = DeliveryTermsIds;
				this._prevDeliverySelectedTerms = updateJSON.salesorderDeliveryTermsView;
				this._SelectedDeliveryTerms = updateJSON.salesorderDeliveryTermsView;

				//Multiselect Other terms

				var OtherTermsIds: number[] = [];
				Object.keys(updateJSON.salesorderOthersTermsView).map((Index) => {
					OtherTermsIds.push(updateJSON.salesorderOthersTermsView[Index].termsandconditionid);
				});
				this._selectedOtherTermsIDs = OtherTermsIds;
				this._prevOtherSelectedTerms = updateJSON.salesorderOthersTermsView;
				this._SelectedOtherTerms = updateJSON.salesorderOthersTermsView;
				if (this._action == 'edit') {
					var Quotationbased = updateJSON.salesorder.referenceno;
					this.isDisabledSendtoapproval = this._ProductDetails.some(s => s.customerdiscountpercentage > 0 && Quotationbased);
				}
			}
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		// 10.save , clear button must be enabled 
		if (this._action == 'view') {
			this._salesorderform.disable();
			this._salesorderproductform.disable();
			this._salesordertermsform.disable();
			this.isDisabledsave = true;
			this.isDisabledClear = true;
			this.isDisabledAdd = true;
			this.isDisabledProductClear = true;
			this.isDisabledRemove = true;
			this.isDisabledAddOtherCharges = true;
			this.isDisabledRemoveOtherCharges = true;
			this.isDisabledQuicksave = true;
			this._customerform.disable();
		}
		else {
			this.isDisabledsave = false;
		}

		if (this._action == 'edit') {
			this.isDisabledcustomer = true;
			this.isDisabledClear = true;
			this.isDisabledReference = true;

		}
		else {
			this.isDisabledcustomer = false;
		}

		// //Customer Quick save 
		// if (this._action == 'create') {
		//   var l_Customer = new Salescustomerquicksaveview();
		//   l_Customer.customerid = 0;
		//   l_Customer.createdby = sessionStorage["userid"];
		//   l_Customer.createdon = new Date();
		//   this._Customers.push(l_Customer);

		// }

		//Other Charges 
		if (this._action == 'create') {
			var l_othercharge = new Salesorderothercharge();
			l_othercharge.salesorderid = this._salesorderform.get("salesorderid").value;
			l_othercharge.salesorderotherchargesdetailid = 0;
			l_othercharge.createdby = sessionStorage["userid"];
			l_othercharge.modifiedby = sessionStorage["userid"];
			l_othercharge.createdon = new Date();
			l_othercharge.modifiedon = new Date();
			this._OtherCharges.push(l_othercharge);

		}

	}

	//  13. on clicking the customer name, Corresponding customer details will fetch in the customer details (Address , Contact  person, level, currency ) from customer master
	GetCustomer(event): void {
		var selectedcustomer = event.value;
		this._selectedcustomer = selectedcustomer;
		if (selectedcustomer == 0 || selectedcustomer == null) {

			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductDetails = [];
			this._SalesOrderService.GetCustomerDetails(selectedcustomer, this._userid).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._ReferenceNos = resultJSON.salesorderquotationnoviews;
				// this._Currencies = resultJSON.currencies;
				// this._CurrenciesTemp = resultJSON.currencies;
				this._salesorderform.controls['mobileno'].setValue(resultJSON.customerdetails[0].mobileno);
				// 15.currency   fetches to the currency drop down(Disabled)
				this._salesorderform.controls['currencyid'].setValue(resultJSON.customerdetails[0].currencyid);
				var referenceid = this._Currencies.filter(f => f.currencyid == resultJSON.customerdetails[0].currencyid)[0].currencyuniquerefid;
				this._salesorderform.controls['currencyreferenceid'].setValue(referenceid);
				// 14.customer level fetches to the level drop down(Disabled)
				this._salesorderform.controls['markuplevelid'].setValue(resultJSON.customerdetails[0].levelid);
				this._salesorderform.controls['billingaddress'].setValue(resultJSON.customerdetails[0].billingaddress);
				this._salesorderform.controls['shippingaddress'].setValue(resultJSON.customerdetails[0].shippingaddress);
				this._salesorderform.controls['vattype'].setValue(resultJSON.customerdetails[0].vattype);
				this._salesorderform.controls['specificvattype'].setValue(resultJSON.customerdetails[0].specificvattype);

				this._IsProgressSpinner = false;
			},
				error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}

	}

	//  56. if effective to is greater is than effective from , show error "Effective To cannot be greater than effective from"
	OnFromDateSelect(event) {
		this.minDates = event;
	}

	//Other Charges
	AddOtherCharges(event) {
		let otherChargesExists = (this._OtherCharges.filter(s => s.otherchargesid > 0).length == this._OtherCharges.length) && this._OtherCharges.some(s => s.otherchargesid > 0);
		if (!otherChargesExists)
			return;
		var l_othercharge = new Salesorderothercharge();
		l_othercharge.salesorderotherchargesdetailid = 0;
		l_othercharge.salesorderid = this._salesorderform.get("salesorderid").value;
		l_othercharge.createdby = sessionStorage["userid"];
		l_othercharge.modifiedby = sessionStorage["userid"];
		l_othercharge.createdon = new Date();
		l_othercharge.modifiedon = new Date();
		l_othercharge.accountingyear = new Date().getFullYear();
		this._OtherCharges.push(l_othercharge);
	}
	RemoveOtherCharges(event, pOthercharges: Salesorderothercharge) {

		var index = this._OtherCharges.indexOf(pOthercharges);
		this._OtherCharges.splice(index, 1)
		this.CalculateotherCharge();
		if (this._OtherCharges.length <= 0) {
			var l_othercharge = new Salesorderothercharge();
			l_othercharge.salesorderotherchargesdetailid = 0;
			l_othercharge.salesorderid = this._salesorderform.get("salesorderid").value;
			l_othercharge.otherchargesamount = 0;
			l_othercharge.createdby = sessionStorage["userid"];
			l_othercharge.modifiedby = sessionStorage["userid"];
			l_othercharge.createdon = new Date();
			l_othercharge.modifiedon = new Date();
			this._OtherCharges.push(l_othercharge);

		}

	}

	OnchangeOtherCharge(pOtherCharges: Salesorderothercharge, event: any) {
		let isAlreadyExists = this._OtherCharges.filter(s => s.otherchargesid == event.value).length > 1;
		if (isAlreadyExists) {
			this._CustomExceptionService.handleWarning("Other Charges Already Exists!");
			var index = this._OtherCharges.indexOf(pOtherCharges);
			this._OtherCharges.splice(index, 1)
			return;
		}
		var otherchargeamount = (this._ChargesTemp || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount;
		pOtherCharges.otherchargesamount = otherchargeamount;
		this.CalculateotherCharge();
	}
	OnChangeotherCharge(row: any, event) {
		let OtherChargesAmount = row.otherchargesamount || 0;
		parseFloat(OtherChargesAmount);
		this._salesorderform.controls['otherchargesamount'].setValue(OtherChargesAmount.toFixed(3));
		this._salesorderform.controls['totalsalesorderamount'].setValue(OtherChargesAmount.toFixed(3));
		this.CalculateotherCharge();
	}

	CalculateotherCharge() {
		this._OtherBalance = 0;
		this._netamount = 0;
		for (var i = 0; i < this._OtherCharges.length; i++) {
			this._OtherBalance += this._OtherCharges[i].otherchargesamount;
		}
		this._salesorderform.controls['otherchargesamount'].setValue(this._OtherBalance.toFixed(3));
		for (var i = 0; i < this._ProductDetails.length; i++) {
			this._netamount += this._ProductDetails[i].finalamount;
		}
		this._salesorderform.controls['otherchargesamount'].setValue(this._OtherBalance.toFixed(3));
		var NetAmount = 0;

		// this._salesorderform.get("totalsalesorderamount").setValue(0);
		if (this._salesorderform.get("totalsalesorderamount").value != undefined || this._salesorderform.get("totalsalesorderamount").value != 0) {
			NetAmount = parseFloat(this._netamount + '') + parseFloat(this._OtherBalance + '');
			this._salesorderform.controls['totalsalesorderamount'].setValue(NetAmount.toFixed(3));
		} else {
			this._salesorderform.controls['totalsalesorderamount'].setValue(this._OtherBalance.toFixed(3));
		}
	}

	GetLevel(event) {
		var selectedlevel = event.value;
		this._selectedlevel = selectedlevel;
	}

	GetCurrency(event) {
		var selectedcurrency = event.value;
		var Name = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].currencyname;
		var Amount = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].exchangerate;
		this._ProductDetails = [];
		this._selectedcurrency = Name;
		this._selectedamount = Amount;
	}

	GetQuotationdetails(event) {
		var quotaionno = event.value;
		this._IsProgressSpinner = true;
		this._Currencies = this._CurrenciesRefTemp;
		if (quotaionno != null) {
			this._SalesOrderService.GetQuotationdetails(quotaionno, this._userid).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				console.log("total amount ", resultJSON.salesquotation.otherchargesamount)
				this._salesorderform.controls['billingaddress'].setValue(resultJSON.salesquotation.billingaddress);
				this._salesorderform.controls['shippingaddress'].setValue(resultJSON.salesquotation.shippingaddress);
				this._salesorderform.controls['mobileno'].setValue(resultJSON.salesquotation.mobileno);
				this._salesorderform.controls['customerid'].setValue(resultJSON.salesquotation.customerid);
				this._salesorderform.controls['otherchargesamount'].setValue(resultJSON.salesquotation.otherchargesamount);
				this._salesorderform.controls['effectivefrom'].setValue(new Date(resultJSON.salesquotation.effectivefrom));

				this._salesorderform.controls['vattype'].setValue(resultJSON.salesquotation.vattype);
				this._salesorderform.controls['specificvattype'].setValue(resultJSON.salesquotation.specificvattype);
				this._Currencies = resultJSON.currencyreference;
				if (resultJSON.salesquotation.effectiveto == null) {
				}
				else {
					this._salesorderform.controls['effectiveto'].setValue(new Date(resultJSON.salesquotation.effectiveto));
				}
				this._salesorderform.controls['markuplevelid'].setValue(resultJSON.salesquotation.markuplevelid);
				this._salesorderform.controls['totalproductamount'].setValue(resultJSON.salesquotation.productamount.toFixed(3));
				this._salesorderform.controls['currencyid'].setValue(resultJSON.salesquotation.currencyid);
				var referenceid = this._Currencies.filter(f => f.currencyid == resultJSON.salesquotation.currencyid)[0].currencyuniquerefid;
				this._salesorderform.controls['currencyreferenceid'].setValue(resultJSON.salesquotation.currencyreferenceid);
				this._salesorderform.controls['taxamount'].setValue(resultJSON.salesquotation.taxamount.toFixed(3));
				if (resultJSON.salesquotation.salesquotationamount !== null && resultJSON.salesquotation.salesquotationamount !== undefined) {
					this._salesorderform.controls['totalsalesorderamount'].setValue(resultJSON.salesquotation.salesquotationamount.toFixed(3));
				} else {
				}
				//this._salesorderform.controls['totalsalesorderamount'].setValue(resultJSON.salesquotation.totalsalesorderamount.toFixed(3));
				this._OtherCharges = resultJSON.salesquotationothercharges;
				this._ProductDetails = resultJSON.salesquotationproductdetailsviews;
				var FinalDiscountAmount = 0;
				var FinalCustomerAmount = 0;
				var TotalAmount = 0;
				for (var i = 0; i < this._ProductDetails.length; i++) {
					TotalAmount += this._ProductDetails[i].finalamount;
					FinalDiscountAmount += this._ProductDetails[i].discountamount || 0;
					FinalCustomerAmount += this._ProductDetails[i].customerdiscountamount || 0;
				}
				var TotalDiscountAmount = parseFloat(FinalCustomerAmount + '') + parseFloat(FinalDiscountAmount + '');
				this._salesorderform.controls['discountamount'].setValue(TotalDiscountAmount.toFixed(3));
				var UserSelectedCurrency = this._salesorderform.get("currencyid").value;
				var UserCurrency = (this._Currencies || []).filter(f => f.currencyid == UserSelectedCurrency)[0].exchangerate;
				//Net Amount In USD
				var NetAmountInUSD = (TotalAmount) / (UserCurrency);
				this._salesorderform.get('amountinusd').setValue(NetAmountInUSD.toFixed(3));
				//Multiselect Payment terms
				var PaymentTermsIds: number[] = [];
				Object.keys(resultJSON.salesquotationPaymentTermsView).map((Index) => {
					PaymentTermsIds.push(resultJSON.salesquotationPaymentTermsView[Index].termsandconditionid);
				});
				this._selectedPaymentTermsIDs = PaymentTermsIds;
				this._prevPaymentSelectedTerms = resultJSON.salesquotationPaymentTermsView;
				this._SelectedPaymentTerms = resultJSON.salesquotationPaymentTermsView;
				//Multiselect Delivery terms
				var DeliveryTermsIds: number[] = [];
				Object.keys(resultJSON.salesquotationDeliveryTermsView).map((Index) => {
					DeliveryTermsIds.push(resultJSON.salesquotationDeliveryTermsView[Index].termsandconditionid);
				});
				this._selectedDeliveryTermsIDs = DeliveryTermsIds;
				this._prevDeliverySelectedTerms = resultJSON.salesquotationDeliveryTermsView;
				this._SelectedDeliveryTerms = resultJSON.salesquotationDeliveryTermsView;
				//Multiselect Other terms
				var OtherTermsIds: number[] = [];
				Object.keys(resultJSON.salesquotationOthersTermsView).map((Index) => {
					OtherTermsIds.push(resultJSON.salesquotationOthersTermsView[Index].termsandconditionid);
				});
				this._selectedOtherTermsIDs = OtherTermsIds;
				this._prevOtherSelectedTerms = resultJSON.salesquotationOthersTermsView;
				this._SelectedOtherTerms = resultJSON.salesquotationOthersTermsView;
				this._IsProgressSpinner = false;
			},
				error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
		this._IsProgressSpinner = false;
	}
	//  19. on selecting the category name, the materials should load in the material name drop down based on the category 
	// 1.Material category load from product Material category master
	GetProductCategory(event) {
		var selectedProductType = event.value;
		var selectedcurrency = this._salesorderform.get("currencyid").value;
		var selectedlevel = this._salesorderform.get("markuplevelid").value;
		var selectedcustomer = this._salesorderform.get("customerid").value;
		if (selectedcustomer == undefined || selectedlevel == undefined || selectedcurrency == null) {
			this._CustomExceptionService.handleWarning("Please select customer,Currency&level");
			return;
		}
		if (selectedProductType.length == 0 || selectedProductType == null) {
			this._ProductCategories = [];
			this._salesorderproductform.controls["productcategoryid"].reset();
			return;
		}
		else {
			this._IsProgressSpinner = true;
			// 1.Material category load from product Material category master
			this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": this._branchid || 0 };
			this._SalesOrderService.GetProductCategory(this._ProductTypeObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._ProductCategories = resultJSON.productcategories;
				this._IsProgressSpinner = false;
			},
				error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
		let productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
		this._ProductNames = productList.length ? productList : this._tempproductname;
	}
	// 20.  on selecting the Material name, the Varients should load in the Varient drop down based on the Product name 
	// 2.Material name load from Material master
	GetProductName(event) {
		var selectedProductCategory = event.value;
		if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
			this._salesorderproductform.reset();
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": this._branchid || 0 };
			this._SalesOrderService.GetProductName(this._ProductCategoryObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._ProductNames = <Productoverallview[]>resultJSON.productsearch || [];
				this._IsProgressSpinner = false;
			},
				error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
		let productList = [];
		if (this.productcatid.length) {
			productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid) && this.productcatid.includes(f.productcategoryid)) || [];
		} else {
			productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
		}
		this._ProductNames = productList;
	}

	GetProductPrice(event) {
		var selectedproduct = event.productid;
		// var selectedlevel = event.value;
		this._selectedproduct = selectedproduct;
		var selectedcurrency = this._salesorderform.get("currencyid").value;
		var selectedlevel = parseInt(sessionStorage["levelid"]);
		var selectedcustomer = this._salesorderform.get("customerid").value;
		var userid = sessionStorage["userid"];
		let roleid = sessionStorage["currentRole"];
		if (selectedcustomer == undefined || selectedcustomer == 0 || selectedcurrency == undefined || selectedcurrency == 0 || selectedlevel == 0 || selectedlevel == undefined || selectedproduct == 0 || selectedproduct == undefined) {
			this._CustomExceptionService.handleWarning("Please select customer,Currency&level");
			return;
		}
		this._IsProgressSpinner = true;
		this._SalesOrderService.GetProductPrice(selectedproduct, selectedcustomer, userid, selectedlevel, this._branchid, roleid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._Variants = resultJSON.productVariantList;
			this._ProductCategories = resultJSON.productcategories;
			this._stockinhand = resultJSON.stocks;
			let product = this._tempproductname.filter(f => f.productid == selectedproduct)[0];
			this.producttypeid = [];
			this.producttypeid.push(product.producttypeid);
			this.productcatid = [];
			this.productcatid.push(product.productcategoryid);
			this._salesorderproductform.controls['producttypeid'].setValue(this.producttypeid);
			this._salesorderproductform.controls['productcategoryid'].setValue(this.productcatid);
			var uom = product.salesuom;
			this._salesorderproductform.get("quantity").reset();
			if (uom == "MSC00037") {
				this.hidequantity = true;
			}
			else {
				this.hidequantity = false
			}
			let stock = this._stockinhand.filter(f => f.variantid == 0);
			this._salesorderproductform.controls['stockinhand'].setValue(stock[0].stock1);

			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });


	}
	// // 21. check the mandatory fields in the material details section, 
	// if every column is valid , push all the material sections columns to the grid 
	// on clicking add button the product name fetches to the product details grid
	// from the Customer  master ,the  discount fetches based on the customer level
	// from the product master , the selling price of product fetches in the grid(Price)
	//28. the sum of  final amount in the grid and fetches to amount text box in the payment terms
	//Add Products
	AddProducts(event) {
		this._submitted1 = true;
		if (this._salesorderproductform.valid) {
			var ProductId = parseInt(this._salesorderproductform.get("productid").value.productid);
			var UserSelectedCurrency = this._salesorderform.get("currencyid").value;
			var selectedlevel = parseInt(sessionStorage["levelid"]);
			var selectedcustomer = this._salesorderform.get("customerid").value;
			var userid = sessionStorage["userid"];
			let roleid = sessionStorage["currentRole"];
			if (selectedcustomer == undefined || selectedcustomer == 0 || UserSelectedCurrency == undefined || UserSelectedCurrency == 0 || selectedlevel == 0 || selectedlevel == undefined || ProductId == 0 || ProductId == undefined) {
				this._CustomExceptionService.handleWarning("Please select customer,Currency&level");
				return;
			}
			this._IsProgressSpinner = true;
			this._SalesOrderService.GetProductPrice(ProductId, selectedcustomer, userid, selectedlevel, this._branchid, roleid).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._IsProgressSpinner = false;
				let product = this._tempproductname.filter(f => f.productid == ProductId)[0];
				this.producttypeid = [];
				this.producttypeid.push(product.producttypeid);
				this.productcatid = [];
				this.productcatid.push(product.productcategoryid);
				this._salesorderproductform.controls['producttypeid'].setValue(this.producttypeid);
				this._salesorderproductform.controls['productcategoryid'].setValue(this.productcatid);
				var Productdetails = (<Salesorderproductdetailsview[]>resultJSON["salesproductprice"]) || [];;
				var productid = parseInt(this._salesorderproductform.get("productid").value.productid);
				var productName = (this._ProductNames || []).filter(f => f.productid == productid)[0].productname;
				var UserCurrency = (this._Currencies || []).filter(f => f.currencyid == UserSelectedCurrency)[0].exchangerate;
				let quantity = this._salesorderproductform.get("quantity").value || 0;
				let currencyid = this._salesorderform.get("currencyid").value;
				let userSelectedCurrency = this._Currencies.find(f => f.currencyid == currencyid);
				let userselectedExchangeRate = userSelectedCurrency.exchangerate;
				let customerid = this._salesorderform.get("customerid").value;
				let customer = this._Customernames.find(f => f.customerid == customerid);
				let vatType = customer.vattype
				for (var p = 0; p < resultJSON["salesproductprice"].length; p++) {
					// usd
					let product = Productdetails[p];
					product.productid = productid;
					product.productname = productName;
					if (this._salesorderproductform.get("variantid").value == "" || this._salesorderproductform.get("variantid").value == null) {
						product.variantid = 0;
						product.variantdescription = "";
					} else {
						var variantid = parseInt(this._salesorderproductform.get("variantid").value);
						var variantname = (this._Variants || []).filter(f => f.productvariantdetailid == variantid)[0].variantdescription;
						product.variantid = variantid;
						product.variantname = variantname;
					}
					product.salesorderid = this._salesorderform.get("salesorderid").value;
					product.salesorderdetailid = 0;
					product.uomcode = resultJSON.salesproductprice[p].salesuom;
					product.uomname = resultJSON.salesproductprice[p].uomname;
					product.quantity = this._salesorderproductform.get("quantity").value;
					let unitPrice = currencyid == 1 ? product.usd : currencyid == 2 ? product.rand : currencyid == 3 ? product.bond : currencyid == 4 ? product.bank : userselectedExchangeRate * product.usd;
					let unitPrice1 = UserCurrency * product.unitprice;
					let productAmount = (quantity || product.quantity) * unitPrice;
					let productAmount1 = (quantity || product.quantity) * unitPrice1;
					let exculdeTaxAmount = (productAmount * (product.taxpercentage / 100));
					if (product.vattype == "MSC00062") {
						var taxAmount = (productAmount1 * (product.taxpercentage / 100));
					}
					else {
						taxAmount = (productAmount * (product.taxpercentage / 100));
					}
					let app_discountamount = (productAmount * (product.customerdiscountpercentage / 100)) || 0;
					let def_discountamount = (productAmount * (product.discountpercentage / 100)) || 0;
					let finalAmount = (productAmount) - (def_discountamount + app_discountamount)
					let existingProductIndex = this._ProductDetails.findIndex(f => f.productid == product.productid && f.variantid == product.variantid);
					product.customerdiscountamount = product.customerdiscountamount || 0;
					product.approveddiscountamount = product.approveddiscountamount || 0;
					product.approveddiscountpercentage = product.approveddiscountpercentage || 0;
					product.unitprice = unitPrice;
					if (product.vatapplicable) {
						product.taxamount = taxAmount;
						product.taxpercentage = Productdetails[p].taxpercentage;
						product.taxpercentage = resultJSON.salesproductprice[0].taxpercentage || 0;
					}
					else {
						product.taxamount = 0.00;
						product.taxpercentage = 0;
					}
					product.productamount = product.unitprice * quantity;
					product.discountamount = def_discountamount;
					product.customerdiscountamount = app_discountamount;
					product.approveddiscountamount = 0;
					product.finalamount = finalAmount;
					product.discountpercentage = resultJSON.salesproductprice[0].discountpercentage || 0;
					product.customerdiscountpercentage = resultJSON.salesproductprice[0].customerdiscountpercentage || 0;
					//default discount
					product.discountamount = product.productamount * product.discountpercentage / 100;
					var defaultdiscountamount = product.productamount - product.discountamount;
					product.finalamount = defaultdiscountamount;
					if (customer) {
						if (customer.vattype == "MSC00063") // exclude
						{
							var calculatedtaxamount = product.finalamount + product.taxamount;
							product.finalamount = calculatedtaxamount;
						}
					}
					var PurchaseRate = resultJSON.salesproductprice[0].purchaserate;
					var stuffedquantity = parseInt(resultJSON.salesproductprice[0].stuffedqty);
					if (stuffedquantity == 0) {
						product.purchaserate = PurchaseRate;
						var profit = product.quantity * product.purchaserate;
						var finalamtinusd = (product.finalamount) / (UserCurrency)
						product.profitamount = finalamtinusd - profit;
					}
					else {
						var SQPurchaseRate = PurchaseRate / stuffedquantity;
						product.purchaserate = SQPurchaseRate;
						var profit = product.quantity * product.purchaserate;
						var finalamtinusd = (product.finalamount) / (UserCurrency)
						product.profitamount = finalamtinusd - profit;
					}
					this._salesorderform.controls['taxamount'].setValue(product.taxamount.toFixed(3));
					product.createdby = sessionStorage["userid"];
					product.createdon = new Date();
					product.modifiedby = sessionStorage["userid"];
					product.modifiedon = new Date();
					product.accountingyear = new Date().getFullYear();
					product.barcode = resultJSON.salesproductprice[0].barcode;
					product.defaultdiscountpercentage = resultJSON.salesproductprice[0].discountpercentage || 0;
					product.maximumdiscountpercentage = resultJSON.salesproductprice[0].maximumdiscountpercentage;
					product.userdiscountpercentage = resultJSON.salesproductprice[0].userdiscountpercentage;
					product.taxcode = resultJSON.salesproductprice[0].taxid;
					if (this._ProductDetails.length > 0) {
						for (var i = 0; i < this._ProductDetails.length; i++) {
							if (product.productid == this._ProductDetails[i].productid && product.variantid == this._ProductDetails[i].variantid) {
								this._CustomExceptionService.handleWarning("Product or Variant Already Exists!");
								return;
							}
						}
					}
					this._ProductDetails.push(product);
				}
				this._submitted1 = false;
				this.Clear(event);
				var FinalTotalAmount = 0
				var FinalGrossAmount = 0
				var FinalTaxAmount = 0
				var FinalDiscountAmount = 0
				var FinalCustomerAmount = 0
				for (var i = 0; i < this._ProductDetails.length; i++) {
					FinalTotalAmount += this._ProductDetails[i].finalamount;
					FinalGrossAmount += this._ProductDetails[i].productamount;
					FinalTaxAmount += this._ProductDetails[i].taxamount;
					FinalDiscountAmount += this._ProductDetails[i].discountamount || 0;
					FinalCustomerAmount += this._ProductDetails[i].customerdiscountamount || 0;
				}
				var OtherChargesAmount = this._salesorderform.get("otherchargesamount").value || 0;
				this._salesorderform.controls['totalproductamount'].setValue(FinalGrossAmount.toFixed(3));
				this._salesorderform.controls['taxamount'].setValue(FinalTaxAmount.toFixed(3));
				this._salesorderform.controls['totalsalesorderamount'].setValue(FinalTotalAmount.toFixed(3));
				var TotalAmount = 0;
				TotalAmount = parseFloat(FinalTotalAmount + '') + parseFloat(OtherChargesAmount + '');
				this._salesorderform.controls['totalsalesorderamount'].setValue(TotalAmount.toFixed(3));
				var TotalDiscountAmount = parseFloat(FinalCustomerAmount + '') + parseFloat(FinalDiscountAmount + '');
				this._salesorderform.controls['discountamount'].setValue(TotalDiscountAmount.toFixed(3));
				//Net Amount In USD
				var NetAmountInUSD = (TotalAmount) / (UserCurrency);
				this._salesorderform.get('amountinusd').setValue(NetAmountInUSD.toFixed(3));
				// this.OnCustomerCreditChange();









				// var productName = (this._ProductNames || []).filter(f => f.productid == productid)[0].productname;
				// Productdetails.productid = productid;
				// Productdetails.productname = productName;
				// if (this._salesorderproductform.get("variantid").value == "" || this._salesorderproductform.get("variantid").value == null) {
				// 	Productdetails.variantid = 0;
				// 	Productdetails.variantdescription = "";
				// }
				// else {
				// 	var variantid = parseInt(this._salesorderproductform.get("variantid").value);
				// 	var variantname = (this._Variants || []).filter(f => f.productvariantdetailid == variantid)[0].variantdescription;
				// 	Productdetails.variantid = variantid;
				// 	Productdetails.variantname = variantname;
				// }
				// Productdetails.salesorderid = this._salesorderform.get("salesorderid").value;
				// Productdetails.salesorderdetailid = 0;
				// Productdetails.uomcode = resultJSON.salesproductprice[0].salesuom;
				// Productdetails.uomname = resultJSON.salesproductprice[0].uomname;
				// Productdetails.vatapplicable = resultJSON.salesproductprice[0].vatapplicable;
				// Productdetails.quantity = this._salesorderproductform.get("quantity").value;
				// var SelectedCurrency = (this._CurrenciesTemp || []).filter(f => f.currencyid == UserSelectedCurrency)[0].currencyname;
				// var UserCurrency = (this._Currencies || []).filter(f => f.currencyid == UserSelectedCurrency)[0].exchangerate;
				// var USDCurrency = (this._Currencies || []).filter(f => f.currencyid == 1)[0].exchangerate;
				// var pricewithouttax = resultJSON.salesproductprice[0].unitprice * UserCurrency;
				// var productamountwithouttax = Productdetails.quantity * pricewithouttax;
				// //currency calculation
				// if (UserSelectedCurrency == 1) {
				// 	this._UnitPrice = resultJSON.salesproductprice[0].usd;
				// 	Productdetails.productamount = Productdetails.quantity * this._UnitPrice;
				// 	Productdetails.finalamount = Productdetails.productamount;
				// }
				// else if (UserSelectedCurrency == 2) {
				// 	this._UnitPrice = resultJSON.salesproductprice[0].rand;
				// 	Productdetails.productamount = Productdetails.quantity * this._UnitPrice;
				// 	Productdetails.finalamount = Productdetails.productamount;
				// }
				// else if (UserSelectedCurrency == 3) {
				// 	this._UnitPrice = resultJSON.salesproductprice[0].bond
				// 	Productdetails.productamount = Productdetails.quantity * this._UnitPrice
				// 	Productdetails.finalamount = Productdetails.productamount;
				// }
				// else if (UserSelectedCurrency == 4) {
				// 	this._UnitPrice = resultJSON.salesproductprice[0].bank;
				// 	Productdetails.productamount = Productdetails.quantity * this._UnitPrice;
				// 	Productdetails.finalamount = Productdetails.productamount;
				// } else {
				// 	var unitprice = resultJSON.salesproductprice[0].usd;
				// 	var totalamount = UserCurrency * unitprice;
				// 	this._UnitPrice = totalamount;
				// 	Productdetails.productamount = Productdetails.quantity * totalamount;
				// 	Productdetails.finalamount = Productdetails.productamount;
				// }
				// Productdetails.discountpercentage = resultJSON.salesproductprice[0].discountpercentage || 0;;
				// // discount
				// //33. the discount amount field is not editable  , the value has to subract with discount to get the final amount 
				// Productdetails.discountamount = Productdetails.productamount * Productdetails.discountpercentage / 100;
				// var defaultdiscountamount = Productdetails.productamount - Productdetails.discountamount;
				// Productdetails.finalamount = defaultdiscountamount;
				// //Tax 
				// if (Productdetails.vatapplicable) {
				// 	Productdetails.taxpercentage = resultJSON.salesproductprice[0].taxpercentage || 0;
				// 	Productdetails.taxamount = productamountwithouttax * Productdetails.taxpercentage / 100;
				// }
				// else {
				// 	Productdetails.taxpercentage = 0;
				// 	Productdetails.taxamount = 0.00;
				// }


				// if (customer) {
				// 	if (customer.vattype == "MSC00063") // exclude
				// 	{
				// 		var calculatedtaxamount = Productdetails.finalamount + Productdetails.taxamount;
				// 		Productdetails.finalamount = calculatedtaxamount;
				// 	}
				// 	else {
				// 	}
				// }

				// this._salesorderform.controls['taxamount'].setValue(Productdetails.taxamount.toFixed(3));
				// //Profit Calculation
				// var PurchaseRate = resultJSON.salesproductprice[0].purchaserate;
				// var stuffedquantity = parseInt(resultJSON.salesproductprice[0].stuffedqty);
				// if (stuffedquantity == 0) {
				// 	Productdetails.purchaserate = PurchaseRate;
				// 	var profit = Productdetails.quantity * Productdetails.purchaserate;
				// 	var finalamtinusd = (Productdetails.finalamount) / (UserCurrency)
				// 	Productdetails.profitamount = finalamtinusd - profit;
				// }
				// else {
				// 	var SQPurchaseRate = PurchaseRate / stuffedquantity;
				// 	Productdetails.purchaserate = SQPurchaseRate;
				// 	var profit = Productdetails.quantity * Productdetails.purchaserate;
				// 	var finalamtinusd = (Productdetails.finalamount) / (UserCurrency)
				// 	Productdetails.profitamount = finalamtinusd - profit;
				// }

				// Productdetails.barcode = resultJSON.salesproductprice[0].barcode;
				// Productdetails.finalamount = Productdetails.finalamount;
				// Productdetails.productamount = Productdetails.productamount;
				// Productdetails.quantity = this._salesorderproductform.get("quantity").value;
				// Productdetails.createdby = sessionStorage["userid"];
				// Productdetails.createdon = new Date();
				// Productdetails.modifiedby = sessionStorage["userid"];
				// Productdetails.modifiedon = new Date();
				// Productdetails.accountingyear = new Date().getFullYear();
				// Productdetails.unitprice = this._UnitPrice;
				// Productdetails.maximumdiscountpercentage = resultJSON.salesproductprice[0].maximumdiscountpercentage;
				// Productdetails.userdiscountpercentage = resultJSON.salesproductprice[0].userdiscountpercentage;
				// Productdetails.taxcode = resultJSON.salesproductprice[0].taxid;
				// if (this._ProductDetails.length > 0) {

				// 	for (var i = 0; i < this._ProductDetails.length; i++) {
				// 		if (Productdetails.productid == this._ProductDetails[i].productid && Productdetails.variantid == this._ProductDetails[i].variantid) {
				// 			this._CustomExceptionService.handleWarning("Product or Variant Already Exists!");

				// 			return;
				// 		}
				// 	}

				// 	this._ProductDetails.push(Productdetails);

				// } else {
				// 	this._ProductDetails.push(Productdetails);
				// }
				// this.Clear(event);
				// this._submitted1 = false;
				// var _FinalTotalAmount = 0
				// var _FinalProductAmount = 0
				// var _FinalTaxAmount = 0
				// var FinalDiscountAmount = 0
				// var FinalCustomerAmount = 0

				// for (var i = 0; i < this._ProductDetails.length; i++) {
				// 	_FinalTotalAmount += this._ProductDetails[i].finalamount;
				// 	_FinalProductAmount += this._ProductDetails[i].productamount;
				// 	_FinalTaxAmount += this._ProductDetails[i].taxamount;
				// 	FinalDiscountAmount += this._ProductDetails[i].discountamount || 0;
				// 	FinalCustomerAmount += this._ProductDetails[i].customerdiscountamount || 0;
				// }
				// var TotalAmount = 0;
				// var OtherChargesAmount = this._salesorderform.get("otherchargesamount").value || 0;
				// this._salesorderform.controls['totalproductamount'].setValue(_FinalProductAmount.toFixed(3));
				// this._salesorderform.controls['taxamount'].setValue(_FinalTaxAmount.toFixed(3));
				// TotalAmount = parseFloat(_FinalTotalAmount + '') + parseFloat(OtherChargesAmount + '');
				// this._salesorderform.controls['totalsalesorderamount'].setValue(TotalAmount.toFixed(3));
				// var TotalDiscountAmount = parseFloat(FinalCustomerAmount + '') + parseFloat(FinalDiscountAmount + '');
				// this._salesorderform.controls['discountamount'].setValue(TotalDiscountAmount.toFixed(3));
				// //Net Amount In USD
				// var NetAmountInUSD = (TotalAmount) / (UserCurrency);
				// this._salesorderform.get('amountinusd').setValue(NetAmountInUSD.toFixed(3));
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	// 37.Clear All the columns in the Material details Section
	// 82.clear all the columns and grid 
	RemoveProduct(pProduct: Salesorderproductdetailsview) {
		var index = this._ProductDetails.indexOf(pProduct);
		this._ProductDetails.splice(index, 1)
		var UserSelectedCurrency = this._salesorderform.get("currencyid").value;
		var UserCurrency = (this._Currencies || []).filter(f => f.currencyid == UserSelectedCurrency)[0].exchangerate;
		var _FinalTotalAmount = 0
		var _FinalTaxAmount = 0
		var _FinalProductAmount = 0
		var FinalDiscountAmount = 0
		var FinalCustomerAmount = 0
		for (var i = 0; i < this._ProductDetails.length; i++) {
			_FinalTotalAmount += this._ProductDetails[i].finalamount;
			_FinalProductAmount += this._ProductDetails[i].productamount;
			_FinalTaxAmount += this._ProductDetails[i].taxamount;
			FinalDiscountAmount += this._ProductDetails[i].discountamount || 0;
			FinalCustomerAmount += this._ProductDetails[i].customerdiscountamount || 0;
		}
		this._salesorderform.controls['totalproductamount'].setValue(_FinalProductAmount.toFixed(3));
		this._salesorderform.controls['taxamount'].setValue(_FinalTaxAmount.toFixed(3));
		this._salesorderform.controls['totalsalesorderamount'].setValue(_FinalTotalAmount.toFixed(3));
		var TotalAmount = 0;
		TotalAmount = _FinalTotalAmount + this._salesorderform.controls['otherchargesamount'].value;
		this._salesorderform.controls['totalsalesorderamount'].setValue(TotalAmount.toFixed(3));
		var TotalDiscountAmount = parseFloat(FinalCustomerAmount + '') + parseFloat(FinalDiscountAmount + '');
		this._salesorderform.controls['discountamount'].setValue(TotalDiscountAmount.toFixed(3));
		//Net Amount In USD
		var NetAmountInUSD = (_FinalTotalAmount) / (UserCurrency);
		this._salesorderform.get('amountinusd').setValue(NetAmountInUSD.toFixed(3));
	}

	// 26.  By Entering qty in row, the qty multiplies with  price and discount percentage calculation is done to get the final amount
	// 27.the sum of  final amount in the grid and fetches to gross amount text box in the payment details
	//28. the sum of  final amount in the grid and fetches to amount text box in the payment terms

	//Caluculation
	allocatequantity(row: any, event) {
		var UserSelectedCurrency = this._salesorderform.get("currencyid").value;
		var UserCurrency = (this._Currencies || []).filter(f => f.currencyid == UserSelectedCurrency)[0].exchangerate;
		if (row.customerdiscountpercentage == 0) {
			var unitprice = row.unitprice;
			row.productamount = row.quantity * unitprice;
			row.finalamount = row.productamount;
			let unitPrice1 = UserCurrency * row.unitprice;

			let productAmount1 = (row.quantity) * unitPrice1;
			//default discount
			row.discountamount = row.productamount * row.discountpercentage / 100 || 0;
			//Customer discount
			row.customerdiscountamount = row.productamount * row.customerdiscountpercentage / 100 || 0;
			//Tax Calculation
			if (row.vattype == "MSC00062") {
				row.taxamount = (productAmount1 * (row.taxpercentage / 100));
			}
			else {
				row.taxamount = row.productamount * row.taxpercentage / 100;
			}

			//Profit Calculation
			var profit = row.quantity * row.purchaserate;
			row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount);
			if (row.vattype == "MSC00063") // exclude
			{
				var calculatedtaxamount = row.finalamount + row.taxamount;
				row.finalamount = calculatedtaxamount;
			}
			var finalamtinusd = (row.finalamount) / (UserCurrency)
			row.profitamount = finalamtinusd - profit;
			this.Calculate(row);
		}
		else {
			var unitprice = row.unitprice;
			row.productamount = row.quantity * unitprice;
			row.finalamount = row.productamount;
			let unitPrice1 = UserCurrency * row.unitprice;

			let productAmount1 = (row.quantity) * unitPrice1;
			//Customer discount
			row.customerdiscountamount = row.productamount * row.customerdiscountpercentage / 100 || 0;
			//Tax Calculation
			if (row.vattype == "MSC00062") {
				row.taxamount = (productAmount1 * (row.taxpercentage / 100));
			}
			else {
				row.taxamount = row.productamount * row.taxpercentage / 100;
			}
			//Profit Calculation
			var profit = row.quantity * row.purchaserate;
			row.finalamount = (row.productamount) - (row.customerdiscountamount);
			if (row.vattype == "MSC00063") // exclude
			{
				var calculatedtaxamount = row.finalamount + row.taxamount;
				row.finalamount = calculatedtaxamount;
			}

			var finalamtinusd = (row.finalamount) / (UserCurrency)
			row.profitamount = finalamtinusd - profit;
			this.Calculate(row);
		}
	}

	allocatecustomerdiscount(row: any, event) {
		var UserSelectedCurrency = this._salesorderform.get("currencyid").value;
		var UserCurrency = (this._Currencies || []).filter(f => f.currencyid == UserSelectedCurrency)[0].exchangerate;
		var userdiscount = parseInt(sessionStorage["userdiscountpercentage"]);
		var customerdiscount = parseInt(row.customerdiscountpercentage);
		let customerid = this._salesorderform.get("customerid").value
		row.vattype = this._Customernames.find(f => f.customerid == customerid).vattype
		let unitPriceforvatcal = 0;
		if (row.vattype == "MSC00062") {
			unitPriceforvatcal = UserSelectedCurrency == 1 ? row.unitpriceusd : UserSelectedCurrency == 2 ? row.unitpricerand : UserSelectedCurrency == 3 ? row.unitpricebond : UserSelectedCurrency == 4 ? row.unitpricebank : UserCurrency * row.unitpriceusd;
		} else {
			unitPriceforvatcal = UserSelectedCurrency == 1 ? row.unitpriceusd : UserSelectedCurrency == 2 ? row.unitpricerand : UserSelectedCurrency == 3 ? row.unitpricebond : UserSelectedCurrency == 4 ? row.unitpricebank : UserCurrency * row.unitpriceusd;
		}
		row.discountamount = 0;
		row.customerdiscountamount = row.productamount * row.customerdiscountpercentage / 100 || 0;
		//Tax Calculation
		if (row.vattype == "MSC00062" || row.vattype == "MSC00063") {
			row.taxamount = ((row.quantity * unitPriceforvatcal) - (row.discountamount + row.customerdiscountamount)) * (row.taxpercentage / 100);
		} else {
			row.taxamount = 0;
		}
		//Profit Calculation
		var profit = row.quantity * row.purchaserate;
		if (row.vattype == "MSC00063") {
			row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount) + (row.taxamount);
		}
		else {
			row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount);
		}
		var finalamtinusd = (row.finalamount) / (UserCurrency)
		row.profitamount = finalamtinusd - profit;
		this.Calculate(row);
		if (row.customerdiscountpercentage <= 0) {
			//default discount
			row.discountamount = row.productamount * row.discountpercentage / 100 || 0;
			row.customerdiscountpercentage = 0
			row.customerdiscountamount = 0
			//Tax Calculation
			if (row.vattype == "MSC00062" || row.vattype == "MSC00063") {
				row.taxamount = ((row.quantity * unitPriceforvatcal) - row.discountamount) * (row.taxpercentage / 100);
			} else {
				row.taxamount = 0;
			}
			//Profit Calculation
			var profit = row.quantity * row.purchaserate;
			if (row.vattype == "MSC00063") {
				row.finalamount = (row.productamount) - (row.discountamount) + (row.taxamount);
			}
			else {
				row.finalamount = (row.productamount) - (row.discountamount);
			}
			var finalamtinusd = (row.finalamount) / (UserCurrency)
			row.profitamount = finalamtinusd - profit;
			this.Calculate(row);
		}
		if (customerdiscount > row.maximumdiscountpercentage) {
			this._CustomExceptionService.handleWarning("Customer discount percentage should not greater than product discount percentage");
			row.customerdiscountpercentage = 0;
			row.customerdiscountamount = row.productamount * row.customerdiscountpercentage / 100 || 0;
			//default discount
			row.discountamount = row.productamount * row.discountpercentage / 100 || 0;
			//Tax Calculation
			if (row.vattype == "MSC00062" || row.vattype == "MSC00063") {
				row.taxamount = ((row.quantity * unitPriceforvatcal) - (row.discountamount + row.customerdiscountamount)) * (row.taxpercentage / 100);
			} else {
				row.taxamount = 0;
			}
			//Profit Calculation
			var profit = row.quantity * row.purchaserate;
			if (row.vattype == "MSC00063") {
				row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount) + (row.taxamount);
			}
			else {
				row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount);
			}
			var finalamtinusd = (row.finalamount) / (UserCurrency)
			row.profitamount = finalamtinusd - profit;
			this.Calculate(row);
			return;
		}
		if (customerdiscount > userdiscount || customerdiscount > row.discountpercentage) {
			this.confirmationService.confirm({
				message: 'Your discount percentage exceeds allocated percentage.so this document go for approval <b>"' + row.customerdiscountpercentage + '"</b> ?',
				accept: () => {
					//Customer discount
					row.customerdiscountamount = row.productamount * row.customerdiscountpercentage / 100 || 0;
					//Tax Calculation
					if (row.vattype == "MSC00062" || row.vattype == "MSC00063") {
						row.taxamount = ((row.quantity * unitPriceforvatcal) - (row.discountamount + row.customerdiscountamount)) * (row.taxpercentage / 100);
					} else {
						row.taxamount = 0;
					}
					//Profit Calculation
					var profit = row.quantity * row.purchaserate;
					if (row.vattype == "MSC00063") {
						row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount) + (row.taxamount);
					}
					else {
						row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount);
					}
					var finalamtinusd = (row.finalamount) / (UserCurrency)
					row.profitamount = finalamtinusd - profit;
					//Actual logic to perform a confirmation
					this.Calculate(row);
				},
				reject: () => {
					row.customerdiscountpercentage = 0;
					row.customerdiscountamount = row.productamount * row.customerdiscountpercentage / 100 || 0;
					//default discount
					row.discountamount = row.productamount * row.discountpercentage / 100 || 0;
					//Tax Calculation
					if (row.vattype == "MSC00062" || row.vattype == "MSC00063") {
						row.taxamount = ((row.quantity * unitPriceforvatcal) - (row.discountamount + row.customerdiscountamount)) * (row.taxpercentage / 100);
					} else {
						row.taxamount = 0;
					}
					//Profit Calculation
					var profit = row.quantity * row.purchaserate;
					if (row.vattype == "MSC00063") {
						row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount) + (row.taxamount);
					}
					else {
						row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount);
					}
					var finalamtinusd = (row.finalamount) / (UserCurrency)
					row.profitamount = finalamtinusd - profit;
					this.Calculate(row);
				}
			});

		}
	}
	// customerdiscount(row: any, event){
	//   var customerdiscount = parseInt(row.customerdiscountpercentage);
	//   if(customerdiscount){
	//     this.isDisabledSendtoapproval = false;
	//   }
	// }
	Calculate(row) {
		let customerid = this._salesorderform.get("customerid").value;
		let customer = this._Customernames.find(f => f.customerid == customerid);
		let tax = this.taxes.find(f => f.taxid == 1);
		var UserSelectedCurrency = this._salesorderform.get("currencyid").value;
		var UserCurrency = (this._Currencies || []).filter(f => f.currencyid == UserSelectedCurrency)[0].exchangerate;
		var selectedindex = this._ProductDetails.findIndex(obj => obj == row);
		if (this._ProductDetails[selectedindex].customerdiscountamount == undefined) {
			this._ProductDetails[selectedindex].customerdiscountamount = 0;
		}
		if (this._ProductDetails[selectedindex].customerdiscountpercentage == undefined) {
			this._ProductDetails[selectedindex].customerdiscountpercentage = 0;
		}
		if (this._ProductDetails[selectedindex].finalamount == undefined) {
			this._ProductDetails[selectedindex].finalamount = 0;
		}
		if (this._ProductDetails[selectedindex].discountamount == undefined) {
			this._ProductDetails[selectedindex].discountamount = 0;
		}

		var FinalTotalAmount = 0
		var FinalGrossAmount = 0
		var FinalTaxAmount = 0
		var TotalAmount = 0;
		var FinalDiscountAmount = 0
		var FinalCustomerAmount = 0
		let FinalTaxAmout = 0
		for (var i = 0; i < this._ProductDetails.length; i++) {
			const x = this._ProductDetails[i]
			FinalTotalAmount += x.finalamount;
			FinalGrossAmount += x.productamount;
			FinalTaxAmount += x.taxamount;
			FinalTaxAmout += x.taxamount;
			FinalDiscountAmount += x.discountamount;
			FinalCustomerAmount += x.customerdiscountamount;
			x.finalamount = parseFloat(x.finalamount.toFixed(3));
			x.productamount = parseFloat(x.productamount.toFixed(3));
			x.taxamount = parseFloat(x.taxamount.toFixed(3));
			x.taxamount = parseFloat(x.taxamount.toFixed(3));
			x.discountamount = parseFloat((x.discountamount || 0).toFixed(3));
			x.customerdiscountamount = parseFloat((x.customerdiscountamount || 0).toFixed(3));
		}

		if (customer) {
			if (customer.vattype == "MSC00063") // exclude
			{
				let taxAmount = (TotalAmount * (tax.taxpercentage / 100));
				FinalTaxAmout = taxAmount;
				FinalTotalAmount += FinalTaxAmout;
			}
			else {
				let taxAmount = 0;
				FinalTaxAmout = taxAmount;
				FinalTotalAmount += FinalTaxAmout;
			}
		}

		var OtherChargesAmount = this._salesorderform.get("otherchargesamount").value || 0;
		this._salesorderform.controls['totalproductamount'].setValue(FinalGrossAmount.toFixed(3));
		this._salesorderform.controls['taxamount'].setValue(FinalTaxAmount.toFixed(3));
		TotalAmount = parseFloat(FinalTotalAmount + '') + parseFloat(OtherChargesAmount + '');
		this._salesorderform.controls['totalsalesorderamount'].setValue(TotalAmount.toFixed(3));
		var TotalDiscountAmount = parseFloat(FinalCustomerAmount + '') + parseFloat(FinalDiscountAmount + '');
		this._salesorderform.controls['discountamount'].setValue(TotalDiscountAmount.toFixed(3));
		//Net Amount In USD
		var NetAmountInUSD = (TotalAmount) / (UserCurrency);
		this._salesorderform.get('amountinusd').setValue(NetAmountInUSD.toFixed(3));
	}

	Clear(event) {
		this._submitted1 = true;
		this.productcatid = [];
		this._salesorderproductform.reset();
		this._submitted1 = false;
	}



	//Terms &Condition
	onSelectPaymentTerms(event) {
		var l_Paymentterms: Salesorderterm[] = [];
		var l_paymentterm: Salesorderterm;

		Object.keys(event.value).map((Index) => {
			l_paymentterm = new Salesorderterm();
			var l_paymenttermlist: Termsandcondition = this._PaymentTerms;
			l_paymentterm.termsandconditionid = event.value[Index];
			l_paymentterm.salesorderid = this._salesorderform.get("salesorderid").value;
			l_paymentterm.salesordertermid = 0;
			l_paymentterm.accountingyear = new Date().getFullYear();
			l_paymentterm.termdescription = l_paymenttermlist[Index].termsdescription;
			l_paymentterm.termtypeid = event.value[Index];
			l_paymentterm.createdon = new Date();
			l_paymentterm.createdby = sessionStorage["userid"];
			l_paymentterm.modifiedby = sessionStorage["userid"];
			l_paymentterm.modifiedon = new Date();

			l_Paymentterms.push(l_paymentterm);
		})
		this._SelectedPaymentTerms = l_Paymentterms;
	}

	onSelectDeliveryTerms(event) {
		var l_Deliveryterms: Salesorderterm[] = [];
		var l_deliveryterm: Salesorderterm;

		Object.keys(event.value).map((Index) => {
			l_deliveryterm = new Salesorderterm();
			var l_deliverytermlist: Termsandcondition = this._DeliveryTerms;
			l_deliveryterm.termsandconditionid = event.value[Index];
			l_deliveryterm.salesorderid = this._salesorderform.get("salesorderid").value;
			l_deliveryterm.salesordertermid = 0;
			l_deliveryterm.accountingyear = new Date().getFullYear();
			l_deliveryterm.termdescription = l_deliverytermlist[Index].termsdescription;
			l_deliveryterm.termtypeid = event.value[Index];
			l_deliveryterm.createdon = new Date();
			l_deliveryterm.createdby = sessionStorage["userid"];
			l_deliveryterm.modifiedby = sessionStorage["userid"];
			l_deliveryterm.modifiedon = new Date();
			l_Deliveryterms.push(l_deliveryterm);
		})
		this._SelectedDeliveryTerms = l_Deliveryterms;
	}
	onSelectOtherTerms(event) {
		var l_Otherterms: Salesorderterm[] = [];
		var l_otherterm: Salesorderterm;
		Object.keys(event.value).map((Index) => {
			l_otherterm = new Salesorderterm();
			var l_othertermlist: Termsandcondition = this._OtherTerms;
			l_otherterm.termsandconditionid = event.value[Index];
			l_otherterm.salesorderid = this._salesorderform.get("salesorderid").value;
			l_otherterm.salesordertermid = 0;
			l_otherterm.accountingyear = new Date().getFullYear();
			l_otherterm.termdescription = l_othertermlist[Index].termsdescription;
			l_otherterm.termtypeid = event.value[Index];
			l_otherterm.createdon = new Date();
			l_otherterm.createdby = sessionStorage["userid"];
			l_otherterm.modifiedby = sessionStorage["userid"];
			l_otherterm.modifiedon = new Date();
			l_Otherterms.push(l_otherterm);
		})
		this._SelectedOtherTerms = l_Otherterms;

	}

	//Customers Quick save

	showBasicDialog() {
		this.ManageDialog = true;
	}

	Save() {
		this._customersubmitted = true;
		if (this._customerform.valid) {

			this._Customer = this._customerform.value;
			if (!this._Customer.customername || (this._Customer.customername.trim()) === "") {
				this._CustomExceptionService.handleWarning("Customer Name Cannot Be Empty.Please Enter Customer Name")
				return
			}
			this._Customer.customerid = 0;
			this._Customer.createdon = new Date();
			this._Customer.createdby = sessionStorage["userid"];
			this._Customer.levelid = sessionStorage["levelid"];
			this._Customer.branchid = sessionStorage["currentbranchid"];
			// this.onEd();
			this._IsProgressSpinner = true;
			this._SalesOrderService.customercreate(this._Customer).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._IsProgressSpinner = false;
				this._Customernames = resultJSON.customers;
				if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
					this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
					this.Cancel();
					this._IsProgressSpinner = false;
				}
				else {
					this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
				} this._IsProgressSpinner = false;
			},

				error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	Cancel() {
		this._customersubmitted = true;
		this.ManageDialog = false;
		this._customerform.reset();
		this.InitializeCustomerForm();
		this._customersubmitted = false;
	}

	//Sales Order Save

	onSave() {
		this._submitted = true;
		if (this._salesorderform.valid) {
			var SalesOrderDate = this._salesorderform.get("salesorderdate").value;
			var Effectivefrom = this._salesorderform.get("effectivefrom").value;
			var Effectiveto = this._salesorderform.get("effectiveto").value;

			var DeliveryDate = this._salesorderform.get("deliverydate").value;
			if (Effectiveto == null) {
				this._salesorderform.get("effectiveto").setValue(null);
			}
			else {
				var datePipe = new DatePipe("en-US");

				var peffectiveto = datePipe.transform(Effectiveto, 'yyyy-MM-dd');
			}
			if (DeliveryDate == null) {
				this._salesorderform.get("deliverydate").setValue(null);
			}
			else {
				var datePipe = new DatePipe("en-US");
				var pdeliverydate = datePipe.transform(DeliveryDate, 'yyyy-MM-dd');
			}
			var datePipe = new DatePipe("en-US");
			var peffectivefrom = datePipe.transform(Effectivefrom, 'yyyy-MM-dd');
			// var peffectiveto = datePipe.transform(Effectiveto, 'yyyy-MM-dd');
			var psalesorderdate = datePipe.transform(SalesOrderDate, 'yyyy-MM-dd');
			// var pdeliverydate = datePipe.transform(DeliveryDate, 'yyyy-MM-dd');
			//For Model and Form Mapping
			this._SalesOrder = this._salesorderform.value;
			if (peffectivefrom > peffectiveto) {
				this._CustomExceptionService.handleWarning("Effective From Date should be less than the Effective to Date");
				return;
			}
			this._SalesOrder.salesorderdate = (new Date(psalesorderdate));
			this._SalesOrder.effectivefrom = (new Date(peffectivefrom));
			this._SalesOrder.effectiveto = (new Date(peffectiveto));
			this._SalesOrder.deliverydate = (new Date(pdeliverydate));
			this._SelectedPaymentTerms = this._SelectedPaymentTerms.concat(this._SelectedOtherTerms);
			this._SelectedPaymentTerms = this._SelectedPaymentTerms.concat(this._SelectedDeliveryTerms);
			if (!this._ProductDetails || this._ProductDetails.length == 0) {
				this._CustomExceptionService.handleError(usererrors.ProductDetails_Tab);
				this.selectedtab = 1;
				return;
			}
			if (this._ProductDetails.some(s => s.quantity == 0 || !s.quantity)) {
				this._CustomExceptionService.handleError("Product quanity should not allow to zero");
				this.selectedtab = 1;
				return;
			}
			this._SalesOrderObj = { "SalesOrder": this._SalesOrder, "SalesOrderdetails": this._ProductDetails, "SalesOrderothercharges": this._OtherCharges, "SalesOrderterms": this._SelectedPaymentTerms };
			if (this._action == 'create') {
				this._SalesOrder.createdby = sessionStorage["userid"];
				this._SalesOrder.createdon = new Date();
				this._SalesOrder.accountingyear = new Date().getFullYear();
				this._SalesOrder.branchid = sessionStorage["BranchID"];

				// this.onEd();
				this._IsProgressSpinner = true;
				this._SalesOrderService.create(this._SalesOrderObj).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._salesorderid = resultJSON.salesorderno;
					this._Series = resultJSON.series;

					this._IsProgressSpinner = false;

					if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
						this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
						var Quotationbased = this._salesorderform.get("referenceno").value;
						this.isDisabledSendtoapproval = this._ProductDetails.some(s => s.customerdiscountpercentage > 0 && Quotationbased);
						this.isDisabledsave = true;

						this._IsProgressSpinner = false;
						// this.isDisabledSendtoapproval = false;


					}
					else {
						this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
					} this._IsProgressSpinner = false;
				},
					error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
			else if (this._action == 'edit') {
				this._SalesOrder.modifiedon = new Date();
				this._SalesOrder.modifiedby = sessionStorage["userid"];
				this._SalesOrderObj.SalesOrder.customer = "";
				// this.onEd();
				this._IsProgressSpinner = true;
				this._SalesOrderService.edit(this._SalesOrderObj).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._salesorderid = resultJSON.salesorderno;
					this._Series = resultJSON.series;
					this._IsProgressSpinner = false;
					if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
						this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
						this._IsProgressSpinner = false;
						this.isDisabledsave = true;
						this.isDisabledSendtoapproval = false;
					}
					else {
						this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
					} this._IsProgressSpinner = false;
				},

					error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}

		}
		else {
			this._CustomExceptionService.handleError(usererrors.SalesOrderDetails_Tab);
			this.selectedtab = 0;
			return;
		}
	}
	//79. clear all the fields which the data has entered
	reset(event) {
		this._submitted = true;
		this._salesorderform.reset();
		this.InitializeSalesorderForm();
		this._salesordertermsform.reset();
		this._OtherCharges = [];
		this._ProductDetails = [];
		var l_othercharge = new Salesorderothercharge();
		l_othercharge.salesorderotherchargesdetailid = 0;
		l_othercharge.salesorderid = this._salesorderform.get("salesorderid").value;
		l_othercharge.createdby = sessionStorage["userid"];
		l_othercharge.modifiedby = sessionStorage["userid"];
		l_othercharge.createdon = new Date();
		l_othercharge.modifiedon = new Date();
		l_othercharge.accountingyear = new Date().getFullYear();
		this._OtherCharges.push(l_othercharge);
		this._submitted = false;
		this.isDisabledsave = false;
		this.isDisabledSendtoapproval = true;
	}
	//Debug
	// onEd() {
	//   ace.config.set("fontSize", "14px");
	//   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
	//   const aceEditor = ace.edit(this.editor.nativeElement);
	//   aceEditor.getSession().setMode("ace/mode/json");
	//   var myObj = this._SalesOrderObj;
	//   var myJSON = JSON.stringify(myObj);
	//   aceEditor.session.setValue(myJSON);
	// }
	//116. on clicking back button , it Navigates to the search screen
	//81. on clicking search button , the data must be filtered and display in the grid
	goBack(event) {
		this._router.navigate(['/vSalesOrder']);
	}
	// 69.after clicking the send to approve button, the Sales order is given to 2 levels of approval and  if user wants system can allow dynamic level also

	SendToApproval(event) {
		var workflowid = 20;
		this._IsProgressSpinner = true;
		this._SalesOrderService.SendToApproval(workflowid, this._userid, this._branchid, this._salesorderid, this._Series).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			this._CustomExceptionService.handleSuccess(usererrors.SalesOrderSendToApproval);
			this.isDisabledSendtoapproval = true;
			this.isDisabledsave = false;
			this.isDisabledprint = false;
			if (this._action != 'edit') {
				this.reset(null);
			}
			if (this._action == 'edit') {
				this.isDisabledsave = true;
			}
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	filterProducts(event) {
		if (event.query == "" || event.query == undefined) {
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._SalesOrderService.GetProduct(event.query, this._branchid).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._ProductNames = <Productoverallview[]>resultJSON.productsearchlist || [];
				this._tempproductname = <Productoverallview[]>resultJSON.productsearchlist || [];
				let filtered: any[] = [];
				let query = (<string>event.query).toLowerCase();
				this.filteredProducts = this._ProductNames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
				this._IsProgressSpinner = false;
			},
				error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

		}
	}
	// Create
	HotKeyIntegration() {
		this._hotkeysService.reset();
		if (this._action != 'view') {
			this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
				this.onSave();
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		if (this._action == 'create') {
			this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
				this.reset(null);
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
			this.goBack(null);
			return false; // Prevent bubbling
		}, ['INPUT', 'SELECT', 'TEXTAREA']));
	}
	// Print(event) {
	// 	this._IsProgressSpinner = true;

	// 	this._SalesOrderService.Print(this._SalesOrderno, this._branchid);
	// 	this._IsProgressSpinner = false;
	// }

	SendMail(event) {

		var quoteno = event.purchaseorderno;
		this._userid = sessionStorage["userid"];
		this._branchid = sessionStorage["BranchID"];
		this._IsProgressSpinner = true;
		this._SalesOrderService.SendMail(this._userid, this._branchid, quoteno).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && resultJSON) {
				this._CustomExceptionService.handleSuccess(usererrors.MailSent_Success);
			}
			else {
				this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);

			}
			this._IsProgressSpinner = false;

		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
}
