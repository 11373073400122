import { DatePipe, NgStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { GrnService } from 'src/app/core/Services/Inventory/grn.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { vEmployeeSelect } from 'src/app/core/Validators/validation';
import { Grnview } from 'src/app/core/Views/Grnview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-goods-receipt-note',
  templateUrl: './v-goods-receipt-note.component.html',
  styleUrls: ['./v-goods-receipt-note.component.css']
})
export class VGoodsReceiptNoteComponent implements OnInit {
  _Action: IAction;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Print: Boolean;
  selectedRows: any;
  Grnviews: Grnview[] = [];
  _IsProgressSpinner: boolean = true;
  cols: any[];
  _userid = sessionStorage["userid"]
  _branchid = parseInt(sessionStorage["currentbranchid"]);
  _roleid = sessionStorage["currentRole"]
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  mindate: Date;
  maxdate: Date;
  _Branch: any[] = [];
  _Branchdrop: Boolean = true;
  _Branchname: number;
  constructor(private fb: FormBuilder, private _GrnService: GrnService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private confirmationService: ConfirmationService,
    private exportUtility: ExportUtility) { }

  ngOnInit(): void {

    //92.check in the user rights screen, for the login user if the GRN has access to View ,then allow to open  View  page  
    this._Action = this._AccessRightsService.getAccessRights(49);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this._Branchname = this._branchid;
    // this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.cols = [
      { field: 'grnno', header: 'Grn No' },
      { field: 'grndate', header: 'Grn Date' },
      { field: 'receivedbranch', header: 'Received Branch' },
      { field: 'supplierorbranchname', header: 'Name' },
      { field: 'inwardno', header: 'Inward No' },
      { field: 'referenceno', header: 'Reference No' },
      { field: 'purchaseinvoiceno', header: 'Purchase Invoice No' },
      { field: 'inwardtypename', header: 'Inward Type' },
      { field: 'invoicestatus', header: 'Purchase Invoice Status' },
      { field: 'statusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    if (this._roleid != 1)
    {
      this._Branchdrop = true;
    }
    else
    {
      this._Branchdrop = false;
    }
    this.Fetchall();
  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    if (this._roleid != 1)
    {
      this._branchid = parseInt(sessionStorage["currentbranchid"]);
    }
    else 
    {
      this._branchid = this._Branchname;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._GrnService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this.Grnviews = resultJSON.grnviews;
      this._Branch = resultJSON.branches;
      //70. based on the GRN No ,GRN Date, Supplier name  you need to filter the data and show in the grid
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/cGoodsReceiptNote'], { state: { grnno: event.grnno, action: 'view' } });
  }

  GoToCreate(event) {
    this.router.navigate(['/cGoodsReceiptNote']);
  }
  edit(event) {

    //if (event.status == "MSC00045" || event.status == null ) {
    this.router.navigate(['/cGoodsReceiptNote'], { state: { grnno: event.grnno, action: 'edit' } });
    // }
    // else {
    //   this._CustomExceptionService.handleWarning("Approved Record Should not Update");
    // }
  }
  exportExcel() {
    let finalizedList = this.GetFinalizedList();
    this.exportUtility.exportExcel(finalizedList, 'GRN', 'xlsx');
  }

  ExportToPdf() {
    let finalizedList = this.GetFinalizedList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Grn No',
      'Name',
      'Grn Date',
      'Received Branch',
      'Supplier Branch',
      'Inward No',
      'Reference No',
      'Purchase Invoice No',
      'Inward Type',
      'Purchase Invoice Status',
      'Status',
      'Created By',
      'Created On'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('GRN.pdf');
      })
    })
  }

  GetFinalizedList() {
    let finalizedList = [];
    this.Grnviews.forEach(element => {
      let quotes: any = {};
      quotes["Grn No"] = element.grnno;
      quotes["Name"] = element.supplierorbranchname;
      quotes["Grn Date"] = this.datepipe.transform(element.grndate, 'dd/MM/yyyy');
      quotes["Received Branch"] = element.receivedbranch;
      quotes["Supplier Branch"] = element.supplierorbranchname;
      quotes["Inward No"] = element.inwardno;
      quotes["Reference No"] = element.referenceno;
      quotes["Purchase Invoice No"] = element.purchaseinvoiceno;
      quotes["Inward Type"] = element.inwardtypename;
      quotes["Purchase Invoice Status"] = element.invoicestatus;
      quotes["Status"] = element.statusname;
      quotes["Created By"] = element.createdbyname;
      quotes["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      finalizedList.push(quotes);
    });
    return finalizedList;
  }

  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  Print(event) {
    this._IsProgressSpinner = true;
    // this._GrnService.Print(grnno, this._branchid);
    this._GrnService.Print({ "grnno": event.grnno, "branchid": this._branchid }).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

}
