import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Brand } from 'src/app/core/Models/Brand';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { RptbranchwisestockService } from 'src/app/core/Services/reports/Stock/RptBranchwiseStock/rptbranchwisestock.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { PurchasetrackingreportService } from 'src/app/core/Services/reports/Purchase/Purchasetrackingreport/purchasetrackingreport.service';

@Component({
  selector: 'app-purchasetrackingreport',
  templateUrl: './purchasetrackingreport.component.html',
  styleUrls: ['./purchasetrackingreport.component.css'],
})
export class PurchasetrackingreportComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptbranchwisestockform: FormGroup;
  _branchlist = sessionStorage['BranchID'];
  _userid: number = parseInt(sessionStorage['userid']);
  datepipe = new DatePipe('en-US');
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _variantlist: Productvariant[];
  _productlist: Productoverallview[] = [];
  _src: SafeResourceUrl;
  filteredProducts: any[];
  _BranchWiseObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  branchid: number[] = [];
  supplierid: number[] = [];
  _brandlist: any;
  _Tempbrandlist: Brand;
  brandid: number[] = [];
  _stocktypelist: Metadatum[] = [];
  _stockvaluelist: Metadatum[] = [];
  _ProductObj: any;
  _ReportLists: any[] = [];
  cols: any[];
  _branchwisestockViews: any[] = [];
  isdisable = false;
  isDisableaddElement: boolean = true;
  selectedProductRows: any;
  griddetaillockqty: boolean = false;
  griddetaillocked: boolean = false;
  _ProductWiseStockqty: any[] = [];
  _ProductWiselockedqty: any[] = [];
  selectedProductName: any;
  selectedbranchName: any;
  _BrandObj: any;
  _SupplierFliter: any[] = [];
  mindate: Date;
  maxdate: Date;
  _purchasetrackingreports: any[] = [];
  _Purchasetrackingdetails: any[] = [];
  _inwardwise: boolean = false;

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptbranchwisestockService: RptbranchwisestockService,
    private exportUtility: ExportUtility,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer,
    private _RptpurchasetrackingService: PurchasetrackingreportService
  ) {}

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._rptbranchwisestockform = this.fb.group({
      fromdate: [],
      todate: [],
    //   supplierid: [],
    });
    this._userid = parseInt(sessionStorage['userid']);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    if (sessionStorage['Environmentenddate'] == 'null') {
      this._rptbranchwisestockform.get('fromdate').setValue(new Date());
      this._rptbranchwisestockform.get('todate').setValue(new Date());
      this.maxdate = new Date();
    } else {
      this._rptbranchwisestockform
        .get('fromdate')
        .setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptbranchwisestockform
        .get('todate')
        .setValue(new Date(sessionStorage['Environmentenddate']));
    }
    // this._rptbranchwisestockform.controls['branchid'].setValue(1);

    this._RptpurchasetrackingService.pageonload({}).subscribe(
      (result) => {
        this._IsProgressSpinner = true;
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._branchlist = resultJSON.branchList;
        this._SupplierFliter = resultJSON.supplierList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  clear(event) {
    this._rptbranchwisestockform.controls['fromdate'].setValue(new Date());
    this._rptbranchwisestockform.controls['todate'].setValue(new Date());

    this.branchid = [];
    this.supplierid = [];

    this._src = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.isdisable = false;
  }
  GenerateReport() {
    var _fromdate = this._rptbranchwisestockform.get('fromdate').value;
    var _todate = this._rptbranchwisestockform.get('todate').value;
    var datePipe = new DatePipe('en-US');
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError('Please Select From Date');
      return;
    } else if (todate == null) {
      this._CustomExceptionService.handleError('Please Select To Date');
      return;
    } else if (fromdate > todate) {
      this._CustomExceptionService.handleError(
        'From Date should be less than To Date'
      );
      return;
    }
    let data = {
      Effectivefrom: fromdate,
      Effectiveto: todate,
      Branchid: this.branchid || [],
      SupplierId: this.supplierid || [],
      Userid: sessionStorage['userid'],
    };

    this._IsProgressSpinner = true;
    this._RptpurchasetrackingService.GetPurchaseTracking(data).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._purchasetrackingreports = [];
        this._purchasetrackingreports = resultJSON.purchasetrackingList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  exportExcel() {
    let branchwisestocklists = this.GetCustomerList();
    this.exportUtility.exportExcel(
      branchwisestocklists,
      'Purchase Tracking Report',
      'xlsx'
    );
  }
  ExportToPdf() {
    let branchwisestocklists = this.GetCustomerList();
    const data = branchwisestocklists.map(Object.values);
    const head = [
      [
        'Purchase Order No',
        'Purchase Order Date',
        'Branch Name',
        'Supplier Name',
        'Created by',
      ],
    ];
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0,
          },
          bodyStyles: {
            textColor: 0,
          },
          didDrawCell: (data) => {},
        });
        doc.save('Purchase Tracking Report.pdf');
      });
    });
  }
  GetCustomerList() {
    let branchwisestocklists = [];
    this._purchasetrackingreports.forEach((element) => {
      let branchwisestock: any = {};
      branchwisestock['Purchase Order No'] = element.PURCHASEORDERNO;
      branchwisestock['Purchase Order Date'] = this.datepipe.transform(
        element.PURCHASEORDERDATE,
        'dd/MM/yyyy'
      );
      branchwisestock['Branch Name'] = element.BRANCHNAME;
      branchwisestock['Supplier Name'] = element.SUPPLIERNAME;
      branchwisestock['Created by'] = element.CREATEDBY;
      branchwisestocklists.push(branchwisestock);
    });
    return branchwisestocklists;
  }

  OnChangeBranch(event) {
    var userid = parseInt(sessionStorage['userid']);
    var count = this.branchid.length;
    if (userid == 1) {
      //Arif
      this.isdisable = false;
    } else {
      if (count >= 1) {
        this.isdisable = true;
      }
    }
  }

  GetPurchaseTrackingDetails(_purchasetrackingreport: any) {
    this._IsProgressSpinner = true;
    let data = {
      purchaseorderno: _purchasetrackingreport.PURCHASEORDERNO,
    };

    
    this._RptpurchasetrackingService.GetPurchaseTrackingDetails(data).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);

        this._Purchasetrackingdetails = resultJSON.purchasetrackingdetailList;
		// let tracking_detail = _Purchasetrackingdetails
		if (!this._Purchasetrackingdetails.length)
		{
			this._inwardwise = false;
			this._CustomExceptionService.handleError("Stock Inward has not started");
		}
		else{
			this._inwardwise = true;
		}

        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  GetPurchasetrackingdetailList() {
    let branchwisestocklists = [];
    this._Purchasetrackingdetails.forEach((element) => {
      let branchwisestock: any = {};
      branchwisestock['PO No'] = element.PURCHASEORDERNO;
      branchwisestock['SI No'] = element.INWARDNO;
      branchwisestock['SI Date'] = this.datepipe.transform(
        element.INWARDDATE,
        'dd/MM/yyyy'
      );
      branchwisestock['SI CreatedBy '] = element.STOCKINWARDCREATEDBY;
      branchwisestock['Grn No'] = element.GRNNO;
      branchwisestock['Grn Date'] = this.datepipe.transform(
        element.GRNDATE,
        'dd/MM/yyyy'
      );
      branchwisestock['Grn CreatedBy'] = element.GRNCREATEDBY;
      branchwisestock['PI No'] = element.PURCHASEINVOICENO;
      branchwisestock['PI Date'] = this.datepipe.transform(
        element.PURCHASEINVOICEDATE,
        'dd/MM/yyyy'
      );
      branchwisestock['PI CreatedBy'] = element.PURCHASEINVOICECREATEDBY;
      branchwisestock['Payment Voucher No'] = element.PAYMENTVOUCHERNO;
      branchwisestock['Payment Date'] = this.datepipe.transform(
        element.PAYMENTDATE,
        'dd/MM/yyyy'
      );
      branchwisestock['Payment CreatedBy'] = element.PAYMENTCREATEDBY;
      branchwisestocklists.push(branchwisestock);
    });
    return branchwisestocklists;
  }

  exportExcelfortracking() {
    let branchwisestocklists = this.GetPurchasetrackingdetailList();
    this.exportUtility.exportExcel(
      branchwisestocklists,
      'Purchase Tracking detail Report',
      'xlsx'
    );
  }
  ExportToPdffortracking() {
    let branchwisestocklists = this.GetPurchasetrackingdetailList();
    const data = branchwisestocklists.map(Object.values);
    const head = [
      [
        'PO No',
        'SI No',
        'SI Date',
        'SI CreatedBy',
        'Grn No',
        'Grn Date',
        'Grn CreatedBy',
        'PI No',
        'PI Date',
        'PI CreatedBy',
        'Payment Voucher No',
        'Payment Date',
        'Payment CreatedBy'
      ],
    ];
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0,
          },
          bodyStyles: {
            textColor: 0,
          },
          didDrawCell: (data) => {},
        });
        doc.save('Purchase Tracking Detail Report.pdf');
      });
    });
  }
}
