<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_portform" (ngSubmit)="onSave()" >
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Port</h3> 
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple title="Save" type="submit" [disabled]="isDisabledsave" icon="pi pi-save"></button>
                                <!--13. All input fields should be cleared. -->
                                <button pButton pRipple title="Clear" icon="pi pi-trash"  type="button"  [disabled]="isDisabledreset"
                                    class="p-button-danger"  (click)="reset($event)"></button>
                                     <!-- 24.on clicking back button , it Navigates to the search screen -->
                                <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                    class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" maxlength="50" name="portname" formControlName="portname" pInputText>
                                   
                                    <label for="portname"> Enter Port Name <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 6.if "port name" is not given, show error as "Please Enter port name" -->
                                 <span class="text-danger"
                                 *ngIf="(_submitted || _portform.get('portname').touched) && _portform.get('portname').errors?.PortNameRequired">
                                 Please Enter Port Name
                             </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_portstatus" name="portstatus"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="portstatus"> </p-dropdown>
                                    <label for="status"> Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_portform.get('portstatus').touched || _submitted) && _portform.get('portstatus').errors?.SelectStatus">
                                Please select port status
                            </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;">
                            <!-- Debug -->
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </tr>
                    </table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>