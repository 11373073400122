export class Userscreenmapping {
    userwisescreenmappingid: number;
    userid: number;
    roleid: number;
    companyid: number;
    moduleid: number;
    screenid: number;
    create: boolean;
    update: boolean;
    delete: boolean;
    view: boolean;
    print: boolean;
    approval: boolean;
	    tabviews: boolean;
    userscreenmappingstatus: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    series: boolean;
    pricehistory: boolean;
    profit: boolean;

}