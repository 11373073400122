import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { SubcategoryService } from 'src/app/core/Services/masters/subcategory.service';
import { Subcategory } from 'src/app/core/Models/Subcategory';
import { Category } from 'src/app/core/Models/Category';
//Debug
import * as ace from 'ace-builds';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import {
  vCategoryNameSelect,
  vStatusSelect,
  vCategoryName,
  vType,
} from 'src/app/core/Validators/validation';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-c-subcategory',
  templateUrl: './c-subcategory.component.html',
  styleUrls: ['./c-subcategory.component.css'],
})
export class CSubcategoryComponent implements OnInit {
  _IsProgressSpinner: boolean = false;
  _SaveHide: boolean = false;
  _ClearHide: boolean = false;
  isDisabledreset = false;
  isDisabledsave = false;
  _subcategoryform: FormGroup;
  _subcategoryid: object;
  _submitted = false;
  _action: string = 'create';
  _subcategorystatus: Metadatum[];
  _type: Metadatum[];
  _Series: Metadatum;
  _Action: IAction;
  _Subcategory: Subcategory;
  _Category: Category[] = [];
  _tempcategory: Category[] = [];
  subcategory: SafeUrl;

  @ViewChild('editor', { static: true })
  private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private utility: CommonUtilities,
    private _SubcategoryService: SubcategoryService,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private router: Router,
    private _AccessRightsService: AccessRightsService,
    private _DomSanitizationService: DomSanitizer
  ) {
    this._subcategoryid = history.state?.subcategoryid
      ? history.state?.subcategoryid
      : 0;
    this._action =
      history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }

  InitializeForm() {
    this._subcategoryform = this.fb.group({
      subcategoryid: [0],
      categoryid: ['', vCategoryNameSelect],
      subcategoryname: ['', vCategoryName],
      subcategorystatus: ['', vStatusSelect],
      type: ['', vType],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();

    this.InitializeForm();
    this._subcategoryform.controls['subcategorystatus'].setValue('MSC00001');
    this._subcategoryid = history.state?.subcategoryid
      ? history.state?.subcategoryid
      : 0;
    this._action =
      history.state.action == null ? 'create' : history.state.action;

    this._Action = this._AccessRightsService.getAccessRights(365);
    this._IsProgressSpinner = true;
    this._SubcategoryService.PageOnLoad({}).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._tempcategory = resultJSON.category;
        this._subcategorystatus = resultJSON.subcategoryStatus;
        this._type = resultJSON.type;
        this._IsProgressSpinner = false;
        this.isDisabledsave = false;
        if (this._action == 'edit' || this._action == 'view') {
          this._SubcategoryService.View(this._subcategoryid).subscribe(
            (result) => {
              const updateJSON = JSON.parse(JSON.stringify(result));
              // console.log(updateJSON.categoryviews);
              // this.isDisabledsave = false;
              // this.isDisabledreset = false;
              if (updateJSON.subcategory.subcategoryid) {
                this.subcategory =
                  this._DomSanitizationService.bypassSecurityTrustUrl(
                    updateJSON.subcategory.subcategory
                  );
              }
              // this._Category = resultJSON.category;

              this._subcategoryform.setValue(updateJSON.subcategory);
              this._subcategoryid = updateJSON.subcategory.subcategoryid;
              this.oncategorychange();
            },
            (error) => {
              this._CustomExceptionService.handleError(error);
              this._IsProgressSpinner = false;
            }
          );
        }
        if (this._action == 'view') {
          this._subcategoryform.disable();
          this.isDisabledsave = true;
          this.isDisabledreset = true;
        }
        if (this._action == 'edit') {
          this.isDisabledreset = true;
        }
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  onSave() {
    this._submitted = true;
    if (this._subcategoryform.valid) {
      this._Subcategory = this._subcategoryform.value;
      this._IsProgressSpinner = true;
      if (this._action == 'create') {
        this._Subcategory.createdon = new Date();
        this._Subcategory.createdby = sessionStorage['userid'];
        this._IsProgressSpinner = true;
        this._SubcategoryService.Create(this._Subcategory).subscribe(
          (result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (
              resutJSON.tranStatus.result == true &&
              (resutJSON != null || resutJSON != undefined)
            ) {
              this.isDisabledsave = true;
              this._IsProgressSpinner = false;
              //10.after saving the record, get the response A, display the success message as "Country Name A  Registered Succesfully "
              this._CustomExceptionService.handleSuccess(
                usererrors.Saved_Success_00
              );
              this.reset(null);
              this.isDisabledsave = false;
            } else {
              this._CustomExceptionService.handleError(
                resutJSON.tranStatus.lstErrorItem[0].message
              );
            }
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
      } else if (this._action == 'edit') {
        this._Subcategory.modifiedon = new Date();
        this._Subcategory.modifiedby = sessionStorage['userid'];
        this._IsProgressSpinner = true;
        this._SubcategoryService.edit(this._Subcategory).subscribe(
          (result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (
              resutJSON.tranStatus.result == true &&
              (resutJSON != null || resutJSON != undefined)
            ) {
              this.isDisabledsave = true;
              this._IsProgressSpinner = false;
              this._CustomExceptionService.handleSuccess(
                usererrors.Update_Success_04
              );

              this.isDisabledsave = false;
            } else {
              //24.after Updating the record, get the response A, display the success message as "Country  Name A  Modified Succesfully "
              this._CustomExceptionService.handleError(
                resutJSON.tranStatus.lstErrorItem[0].message
              );
            }
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
      }
    }
  }
  // 12. clear all the fields which the data has entered
  reset(event) {
    this._submitted = true;
    this._subcategoryform.reset();
    this.InitializeForm();
    this._submitted = false;
  }
  //23. on clicking back button , it Navigates to the search screen
  goBack(event) {
    //alert('This');
    //console.log("goBack");
    this._router.navigate(['/vSubCategory']);
  }

  handleError(error: any) {
    this._CustomExceptionService.handleError(error);
    this._IsProgressSpinner = false;
  }

  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+s',
          (event: KeyboardEvent): boolean => {
            this.onSave();
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    if (this._action == 'create') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+alt+c',
          (event: KeyboardEvent): boolean => {
            this.reset(null);
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    this._hotkeysService.add(
      new Hotkey(
        'ctrl+left',
        (event: KeyboardEvent): boolean => {
          this.goBack(null);
          return false; // Prevent bubbling
        },
        ['INPUT', 'SELECT', 'TEXTAREA']
      )
    );
  }
  oncategorychange() {
    var types = this._subcategoryform.get('type').value;
    if (types) {
      this._Category = this._tempcategory.filter((f) => f.type == types);
    }
  }
}
