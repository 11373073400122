<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Service Invoice </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" (click)="goBack()" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="text" disabled pInputText [(ngModel)]="Serviceinvoicecode">
                                <label>Service Invoice Code</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar [showIcon]="true" [disabled]=true [(ngModel)]="Date" dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="serviceproductdeliverydate"> Service Invoice Date <span
                                        class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="text" disabled pInputText [(ngModel)]="Customername">
                                <label for="servicerequestno">Customer Name<span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="text" disabled pInputText [(ngModel)]="workordercode">
                                <label for="servicerequestno">Service Work Order Code<span
                                        class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;"></td>
                    </tr>
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="text" disabled pInputText [(ngModel)]="requestcode">
                                <label for="servicerequestno">Service Request Code<span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="text" disabled pInputText [(ngModel)]="invoicecode">
                                <label> Invoice Code</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar [showIcon]="true" [disabled]=true [(ngModel)]="InvoiceDate"
                                    dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="serviceproductdeliverydate"> Invoice Date <span
                                        class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="text" disabled pInputText [(ngModel)]="Currency">
                                <label> Currency</label>
                            </span>
                        </td>
                    </tr>

                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_serviceinvoicedetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['productname','pruchaseorinvoicedate','srquantity','uomdesc','unitprice','finalamount']"
                        [rowHover]="true" dataKey="productid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="productname">Product Name <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="uomdesc">UOM <p-sortIcon field="uomdesc">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="unitprice">Unit Price<p-sortIcon field="unitprice"></p-sortIcon>
                                </th>
                                <th pSortableColumn="srquantity"> Service Request Quantity <p-sortIcon
                                        field="srquantity">
                                    </p-sortIcon>
                                <th pSortableColumn="srquantity">Delivery Quantity <p-sortIcon field="srquantity">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_serviceproductdeliverydetail>
                            <tr>
                                <td>
                                    {{_serviceproductdeliverydetail.productname }}
                                </td>

                                <td>
                                    {{_serviceproductdeliverydetail.uomdesc }}
                                </td>
                                <td>
                                    {{_serviceproductdeliverydetail.unitprice }}
                                </td>
                                <td>
                                    {{_serviceproductdeliverydetail.srquantity}}
                                </td>
                                <td>
                                    {{_serviceproductdeliverydetail.deliveryquantity}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Other Charges</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <div class="card">
                            <p-table [value]="_OtherCharges" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]"
                                [globalFilterFields]="['otherchargename','ocvalue']" [rowHover]="true"
                                dataKey="otherchargename"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedOtherChargesRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Description</th>
                                        <th>Value</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_othercharge>
                                    <tr>
                                        <td>
                                            {{_othercharge.otherchargename}}
                                        </td>
                                        <td>
                                            {{_othercharge.ocvalue}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div>
                            <div class="row">
                                <div class="col-md-5">
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <table class="normal-table">
                            <tr>
                                <td>
                                    <span class="p-float-label">
                                        <input type="text" disabled pInputText [(ngModel)]="serviceamount">
                                        <label for="vatAmount"> Service Amount </label>
                                    </span>
                                </td>
                                <td>
                                    <span class="p-float-label">
                                        <input type="text" disabled pInputText [(ngModel)]="vatAmount">
                                        <label for="vatAmount"> VAT Amount </label>
                                    </span>
                                </td>
                                <td>
                                    <span class="p-float-label">
                                        <input type="text" disabled pInputText [(ngModel)]="ocvalue">
                                        <label for="ocValue"> Other Charges Amount </label>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="p-float-label">
                                        <input type="text" disabled pInputText [(ngModel)]="netamount">
                                        <label for="netamount"> Net Amount </label>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>