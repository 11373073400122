import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { vehicle } from 'src/app/core/Models/overallclass';
import { Stockdelivery } from 'src/app/core/Models/Stockdelivery';
import { StockallocationService } from 'src/app/core/Services/inventory/stockallocation.service';
import { StockdeliveryService } from 'src/app/core/Services/inventory/stockdelivery.service';
import { vDeliveryDateSelect, vdeliverytorequired, vdeliverytype, vevidence, vInvoicetyperequired } from 'src/app/core/Validators/validation';
import { Stockdeliverygetcustomerorbranchview } from 'src/app/core/Views/Stockdeliverygetcustomerorbranchview';
import { Stockdeliverygetinvoicenorequestview } from 'src/app/core/Views/Stockdeliverygetinvoicenorequestview';
import { Stockdeliverygetproductdetail } from 'src/app/core/Views/Stockdeliverygetproductdetail';
import { CommonUtilities } from 'src/assets/js/common-utilities';
//Debug
import * as ace from "ace-builds";
import { DatePipe } from '@angular/common';
import { Employee } from 'src/app/core/Models/Employee';
import { Branch } from 'src/app/core/Models/Branch';
import { KeyPress } from 'src/assets/js/KeyPress';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

declare var $: any
@Component({
  selector: 'app-c-stockdelivery',
  templateUrl: './c-stockdelivery.component.html',
  styleUrls: ['./c-stockdelivery.component.css']
})
export class CStockdeliveryComponent implements OnInit {

  _validatestockrequest = true;
  _submitted = false;
  _stockdeliveryid: number;
  _stockdeliverydetailform: FormGroup;
  _stockform: FormGroup;
  _productsubmitted = true;
  _reservationid: number;
  _invoicetypeid: number;
  _invoicetype: Metadatum[] = [];
  _stockdeliveryform: FormGroup;
  //13.search, save , clear  should be enable , send to approval button should be disable.
  isDisabledsave = false;
  _deliverytype: Metadatum[] = [];
  _deliveryto: Metadatum[] = [];
  isDisablereset = false;
  isdisabledbarcode = false;
  _StockallocationObj: any;
  _invoiceno: any[] = [];
  _customernameorbranch: Stockdeliverygetinvoicenorequestview[] = [];
  _Stockdelivery: Stockdelivery;
  _productdetail: Stockdeliverygetproductdetail;
  _productdetails: Stockdeliverygetproductdetail[] = [];
  _stockproductdetails: Stockdeliverygetproductdetail[] = [];
  selectedProductDetailsRows: Stockdeliverygetproductdetail[] = [];
  isDisabledaddproduct = true;
  isDisabledclearproduct = true;
  _action: string = "create";
  _drivername: Employee[] = [];
  _branch: Branch[] = [];
  isDisabledremoveproduct = true;
  isDisabledproductcategory = true;
  _SalesDiscountLimit: Metadatum[] = [];
  _validate = true;
  _StockdeliveryObj: any;
  ManageDialog: boolean;
  isDisableddriver = true;
  isDisabledvehicle = true;
  _vehicleno: vehicle[] = [];
  _selectedevidencenoIndex: number[] = [];
  isDisabledstockrequest = true;
  isDisabledisdelivered = false;
  isDisabledselectall = false;
  _IsProgressSpinner: boolean = true;
  isdisableddeliveryto = true;
  _branchid = sessionStorage["currentbranchid"];
  productbarcode: string;
  Branchid: any[];
  ProductID: number;
  CurrentRowBranchId: number;
  isDisabledotherbranchid = false;
  isDisabledotherdeliveryrequired = false;
  _userid = sessionStorage["userid"];
  isDisabledisdeliveryQuantity = false;
  _ProductdetailsObj: any;
  _branchlist: Branch[] = [];
  branchids: any[] = [];
  _tripstartclosuregetdrivername: any[];
  Stockmodel: any = {};
  Stockmodel1: any = {};
  Ans = 0
  _Action: IAction;
  customerids: number;
  _customerlist: [];
  mindate: Date;
  maxdate: Date;

  //Debug
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  tableCheckbox: any;
  constructor(private fb: FormBuilder, private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private _StockdeliveryService: StockdeliveryService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private _router: Router, public keypress: KeyPress) {
    this._stockdeliveryid = history.state?.stockdeliveryid ? history.state?.stockdeliveryid : 0;
    this._invoicetypeid = history.state?.invoicetype ? history.state?.invoicetype : 0;
    this.HotKeyIntegration();

  }
  InitializeStockdeliveryForm() {
    this._stockdeliveryform = this.fb.group({
      companyid: [],
      branchid: [],
      stockdeliveryid: [],
      deliveryno: [],
      deliverydate: ['', vDeliveryDateSelect],
      invoicetype: ['', vInvoicetyperequired],
      deliveryto: ['', vdeliverytorequired],
      deliverytype: ['', vdeliverytype],
      vehicleid: [],
      drivername: [],
      ismultidelivery: [],
      deliveryby: [],
      status: [],
      statusremarks: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      rv: [],
      stockdeliverydetails: [],
      inwardstatus: [],
      tripstatus: [],
      transactionincompleted: [1],


    });

    if (sessionStorage['Environmentenddate'] == "null") {
      this._stockdeliveryform.get("deliverydate").setValue(new Date);
    }
    else {
      let date = new Date(sessionStorage['Environmentenddate'])
      this._stockdeliveryform.get("deliverydate").setValue(date);
    }

  }
  InitializeStockdetailForm() {
    this._stockdeliverydetailform = this.fb.group({
      stockdeliverydetailid: [],
      stockdeliveryid: [],
      evidenceid: [],
      evidenceno: [''],
      evidencedate: [],
      customerorbranchid: [],
      customerorbranchname: ['',],
      productid: [],
      productname: [],
      variantid: [],
      variantdescription: [],
      invoicequantity: [],
      deliveryquantity: [],
      balancequantity: [],
      uomcode: [],
      uomname: [],
      isdelivered: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      invoicetype: [],
      serialnumber: [],
      otherbranchid: [0],
      otherbranchdeliveredrequired: [false],

    });
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(50);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeStockdeliveryForm();
    this.InitializeStockdetailForm();


    this._stockform = this.fb.group({
      salesinvoiceid: []
    })

    this._action = history.state.action == null ? 'create' : history.state.action;
    this._IsProgressSpinner = true;

    this._StockdeliveryService.PageOnLoad(this._stockdeliveryid, this._invoicetypeid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //4.Delivery Date should be editable and loaded with Current date as default date
      // this._stockdeliveryform.get("deliverydate").setValue(new Date);
      this._invoicetype = resultJSON.invoiceType;
      //9. Delivery To should be loaded from metadata(1. branch , 2. customer )
      this._deliveryto = resultJSON.deliveryTo;
      this._deliverytype = resultJSON.deliveryType;
      //11.Vehicle No: only load available Vehicle Numbers refered in driver master 
      this._vehicleno = resultJSON.vehicles;
      //12.Driver Name: should load only available Drivers in "Driver Name" from driver master 
      this._drivername = resultJSON.drivers;
      this._branchlist = resultJSON.branches;
      this._branch = resultJSON.branches;
      this._tripstartclosuregetdrivername = resultJSON.tripstartclosuregetdrivernames;
      this._customerlist = resultJSON.customers;

      this._branch = this._branch.filter(f => f.branchid != this._branchid);
      this._stockdeliveryform.controls['invoicetype'].setValue("MSC00210");
      this._stockdeliveryform.controls['deliveryto'].setValue("MSC00212");
      this._stockdeliveryform.controls['deliverytype'].setValue("MSC00205");
      $("input[name='productbarcode']").focus();
      // this._IsProgressSpinner = false;
      this.onchangeinvoicetype();

      // this._SalesDiscountLimit=resultJSON.fromBranches;
      if (this._action == 'create') {
        this.isDisabledproductcategory = false;

        // this.isDisabledsave = false;
        // this.isDisablereset = false;
        this.isDisabledremoveproduct = false;
        this._submitted = false;

        this.isDisabledstockrequest = false;
      }
      if (this._action == 'edit' || this._action == 'view') {
        this._submitted = false;
        // this.isDisabledsave = false;
        // this.isDisablereset = false;
        //47.update initialize should load all fields based on search page selected Delivery No
        const updateJSON = JSON.parse(JSON.stringify(result));
        //console.log(updateJSON);
        updateJSON.stockdeliveries[0].deliverydate = new Date(updateJSON.stockdeliveries[0].deliverydate);
        this._stockdeliveryform.setValue(updateJSON.stockdeliveries[0]);
        this._invoiceno = updateJSON.stockdeliverygetinvoicenorequestviews;
        this._customernameorbranch = updateJSON.stockdeliverygetcustomerorbranchviews;
        var evidenceno: number[] = [];
        Object.keys(updateJSON.stockdeliveryevidencenofileterviews).map((Index) => {
          evidenceno.push(updateJSON.stockdeliveryevidencenofileterviews[Index].salesinvoiceno);
        });
        this._selectedevidencenoIndex = evidenceno;
        this._stockdeliverydetailform.controls['customerorbranchid'].setValue(parseInt(updateJSON.stockdeliverygetproductdetailsfilter[0].customerorbranchid));
        //   this._stockdeliverydetailform.setValue(updateJSON.stockdeliverygetproductdetails[0]);
        this._productdetails = updateJSON.stockdeliverygetproductdetailsfilter;
        this.selectedProductDetailsRows = updateJSON.stockdeliverygetproductdetailsfilter;

        for (var i = 0; i < this.selectedProductDetailsRows.length; i++) {
          this.selectedProductDetailsRows[i].isDisabledotherdeliveryrequired = true

        }

        this.isDisabledproductcategory = true;
        this.isDisabledremoveproduct = false;
        this.isDisabledisdelivered = false;
      }
      if (this._action == "view") {
        this.isDisabledremoveproduct = true;

        this._stockdeliveryform.disable();
        this._stockdeliverydetailform.disable();
        this.isDisabledstockrequest = true;
        this.isDisabledvehicle = true;
        this.isDisableddriver = true;
        this.isDisabledisdelivered = true;
        this.isDisabledselectall = true;
        this.isdisabledbarcode = true;
        this.isDisabledsave = true;
        this.isDisablereset = true;
      }
      if (this._action == 'edit') {
        this.isDisabledsave = false;
        this.isDisablereset = true;
        // this._stockdeliveryform.disable();
        // this._stockdeliverydetailform.disable();
        this._stockdeliveryform.get("invoicetype").disable();
        this._stockdeliveryform.get("deliveryto").disable();
        this._stockdeliveryform.get("deliverytype").disable();
        this._stockdeliveryform.get("deliverydate").disable();
        this.isDisabledvehicle = true;
        this.isdisabledbarcode = true;
        this.isDisableddriver = true;
        this.isDisabledselectall = true;
      }
      this._IsProgressSpinner = false;
    },
      error => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      });

  }
  RemoveProductDetail(pdetail: Stockdeliverygetproductdetail) {
    if (this._productdetails.length > 1) {
      var index = this._productdetails.indexOf(pdetail);
      this._productdetails.splice(index, 1);
    }
  }
  validate(): boolean {
    //28.if any of the row was not selected in product details grid, show error "Should select at-least one row in product details"

    for (var i = 0; i < this.selectedProductDetailsRows.length; i++) {

      if (this._action == 'create') {
        if (this.selectedProductDetailsRows[i].deliveryquantity == 0 && (this.selectedProductDetailsRows[i].otherbranchdeliveredrequired == false || this.selectedProductDetailsRows[i].otherbranchid == 0)) {
          this._validate = false;
          this._CustomExceptionService.handleWarning(usererrors.Grid_Select_05);
          return;
        }
      }

      if (this.selectedProductDetailsRows[i].balancequantity > 0 && this.selectedProductDetailsRows[i].otherbranchdeliveredrequired == true && this.selectedProductDetailsRows[i].otherbranchid == 0) {
        this._validate = false;
        this._CustomExceptionService.handleError('Please Select Other Branch');
        return;
      }

      if (this.selectedProductDetailsRows[i].balancequantity > 0 && this.selectedProductDetailsRows[i].otherbranchdeliveredrequired == false && this.selectedProductDetailsRows[i].otherbranchid != 0) {
        this._validate = false;
        this._CustomExceptionService.handleWarning('Please Select Other Branch Delivery Required');
        return;
      }

      else if (this.selectedProductDetailsRows[i].otherbranchstock == 0 && this.selectedProductDetailsRows[i].otherbranchdeliveredrequired == true && this.selectedProductDetailsRows[i].otherbranchid != 0) {
        this._validate = false;
        this._CustomExceptionService.handleWarning('Other Branch Stock Zero. So you cannot Stock transfer');
        return;
      }

      else {
        this._validate = true;
      }
    }

    var roleid = sessionStorage["currentRole"];

    if (roleid != 1 && roleid != 7 && roleid != 40 && roleid != 15 && roleid != 17) {

      let isNotValid = (this.selectedProductDetailsRows || []).some(s => (s.deliveryquantity > s.invoicequantity + s.previousdeliveryquantity) || ((s.deliveryquantity > s.currentstock) && s.otherbranchid == 0));
      if (isNotValid) {
        this._validate = false;
        this._CustomExceptionService.handleWarning('"Delivery Quantity" should not exceed with "Invoice qty / Current Stock"');
      }
    }
    else {
      let isNotValid = (this.selectedProductDetailsRows || []).some(s => (s.deliveryquantity > s.invoicequantity + s.previousdeliveryquantity));
      if (isNotValid) {
        this._validate = false;
        this._CustomExceptionService.handleWarning('"Delivery Quantity" should not exceed with "Invoice qty');
      }
    }

    return this._validate;
  }


  validatestockrequest(): boolean {
    if (this._stockdeliverydetailform.get("evidenceno").value == 0) {
      this._validatestockrequest = false
    }
    return this._validatestockrequest;

  }

  onchangeinvoiceno(event) {
    var selectedevent = event.value;
    this._productdetails = [];
    this.selectedProductDetailsRows = [];
    if (selectedevent.length == 0 || selectedevent == null) {
      this._selectedevidencenoIndex = [];
      this.event.value = [];
      return;

    }
    else {
      var invoicetype = this._stockdeliveryform.get("invoicetype").value;
      this._branchid = parseInt(sessionStorage["currentbranchid"]);
      this._IsProgressSpinner = true;
      this._ProductdetailsObj = {
        "SalesInvoiceNo": selectedevent,
        "InvoiceType": invoicetype,
        "Branchid": this._branchid,
        "Userid": this._userid,
        "branches": this.branchids || []
      }
      this._StockdeliveryService.getproductdetails(this._ProductdetailsObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        //15.based on selected invoice no. system should filter customer names in customer name drop down and system should load the details in grid from sales invoice
        this._productdetails = resultJSON.stockdeliverygetproductdetails;
        console.log(resultJSON.stockdeliverygetproductdetails);

        this.isDisabledotherbranchid = true;

        // this.Disablecheckbox(row, event);
        for (var i = 0; i < this._productdetails.length; i++) {
          this._productdetails[i].isDisabledotherdeliveryrequired = true;

          if (this._productdetails[i].deliveryquantity > this._productdetails[i].currentstock) {
            this._productdetails[i].deliveryquantity = 0;
          }
          else {
            this._productdetails[i].deliveryquantity = this._productdetails[i].deliveryquantity;
          }
          if (this._productdetails[i].currentstock == 0) {
            this._productdetails[i].balancequantity = this._productdetails[i].invoicequantity;
            this._productdetails[i].deliveryquantity = 0;
          }
          if (this._productdetails[i].otherbranchid > 0) {
            this._productdetails[i].isDisabledisdeliveryQuantity = true;
          }

          this.Disablecheckbox(this._productdetails[i], event);
        }
        this.isDisabledotherdeliveryrequired = true;
        //this.selectedProductDetailsRows = resultJSON.stockdeliverygetproductdetails;

        $("input[name='productbarcode']").focus();
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  // Pop up
  showBasicDialog(view) {
    this.ProductID = view.productid;
    this.CurrentRowBranchId = view.customerorbranchid
    this._stockdeliverydetailform.controls['serialnumber'].setValue(view.serialnumber);
    this.ManageDialog = true;
  }
  confirm(obj: any) {

  }

  showconfirm(message: string, yesHandler: any, NoHandler?: any) {
    this.Stockmodel.showdialog = true;
    this.Stockmodel.message = message;
    this.Stockmodel.Yes = () => {
      if (typeof yesHandler === 'function')
        yesHandler();
      this.Stockmodel.showdialog = false;
      // this.confirm1('')
    };
    this.Stockmodel.Cancel = () => {
      if (typeof NoHandler === 'function')
        NoHandler();
      this.Stockmodel.showdialog = false;
      return
    };
  }
  Yes() {
    this.showconfirm1('Are you sure to finalize stock for delivery?', () => {
      // this.InActive(obj);
    }, null)
    this.Stockmodel.showdialog = false;
    return

  }
  Cancel() {
    this.Stockmodel.showdialog = false;
    return
  }


  Yes1() {
    this.showconfirm1('Are you sure to finalize Stock for delivery?', () => {
      // this.InActive(obj);
    }, null)
    this.Ans = 1
    this.Stockmodel1.showdialog = false;
    this.onSave()

  }
  Cancel1() {
    this.Stockmodel1.showdialog = false;
    return
  }
  confirm1(obj: any) {
    this.showconfirm1('Are you sure to finalize stock  for delivery?', () => {
      // this.InActive(obj);
    }, null)
  }

  showconfirm1(message: string, yesHandler: any, NoHandler?: any) {
    this.Stockmodel1.showdialog = true;
    this.Stockmodel1.message = message;
    this.Stockmodel1.Yes = () => {
      if (typeof yesHandler === 'function')
        yesHandler();
      this.Stockmodel1.showdialog = false;

    };
    this.Stockmodel1.Cancel = () => {
      if (typeof NoHandler === 'function')
        NoHandler();
      this.Stockmodel1.showdialog = false;
      return
    };
  }
  Save() {
    this.ManageDialog = false;
    for (var i = 0; i < this._productdetails.length; i++) {
      if (this._productdetails[i].productid == this.ProductID && this._productdetails[i].customerorbranchid == this.CurrentRowBranchId) {
        this._productdetails[i].serialnumber = this._stockdeliverydetailform.get('serialnumber').value;
      }
    }
  }
  Clear() {
    for (var i = 0; i < this._productdetails.length; i++) {
      if (this._productdetails[i].productid == this.ProductID && this._productdetails[i].customerorbranchid == this.CurrentRowBranchId) {
        this._productdetails[i].serialnumber = "";
      }
    }
    this.ManageDialog = false;
  }
  Disablecheckbox(row: any, event) {
    var selectedindex = this._productdetails.findIndex(obj => obj == row);
    var InvoiceType = this._stockdeliveryform.get("invoicetype").value
    if (InvoiceType == "MSC00210") {
      if (this._productdetails[selectedindex].balancequantity == 0) {
        this._productdetails[selectedindex].isDisabledotherdeliveryrequired = true;
        this._productdetails[selectedindex].otherbranchdeliveredrequired = false;
        this._productdetails[selectedindex].otherbranchid = 0;
      }
      else {
        this._productdetails[selectedindex].isDisabledotherdeliveryrequired = false;
      }
    }

  }
  onchangebalance(row: any, event) {
    if (row.deliveryquantity < 0) {
      this._CustomExceptionService.handleWarning("Delivery Quantity should not be lesser than zero!");
      row.deliveryquantity = 0;
    }
    var selectedindex = this._productdetails.findIndex(obj => obj == row);


    var roleid = sessionStorage["currentRole"];

    if (roleid != 1 && roleid != 7 && roleid != 40 && roleid != 12 && roleid != 15 && roleid != 17) {

      if (this._productdetails[selectedindex].deliveryquantity > this._productdetails[selectedindex].currentstock) {
        this._CustomExceptionService.handleWarning("Delivery Quantity  should not greater than Currenct Stock!");
        this._productdetails[selectedindex].deliveryquantity = 0;
        this._productdetails[selectedindex].balancequantity = 0;
      }

    }

    else {
      if (this._productdetails[selectedindex].deliveryquantity == 0 || this._productdetails[selectedindex].deliveryquantity == null) {
        this._productdetails[selectedindex].deliveryquantity = 0;
        let balance: string = (this._productdetails[selectedindex].invoicequantity + this._productdetails[selectedindex].previousdeliveryquantity - this._productdetails[selectedindex].deliveryquantity).toFixed(3);

        let balanceParsed: number = parseFloat(balance);
        this._productdetails[selectedindex].balancequantity = (balanceParsed);
      }
      else {
        let balance: string = (this._productdetails[selectedindex].invoicequantity + this._productdetails[selectedindex].previousdeliveryquantity - this._productdetails[selectedindex].deliveryquantity).toFixed(3);

        let balanceParsed: number = parseFloat(balance);

        this._productdetails[selectedindex].balancequantity = balanceParsed;
      }

      this.Disablecheckbox(row, event);

    }
    if (this._productdetails[selectedindex].deliveryquantity < 0) {
      this._CustomExceptionService.handleWarning("Delivery Quantity  should not be lesser than zero!");
      this._productdetails[selectedindex].deliveryquantity = 0;
      this._productdetails[selectedindex].balancequantity = 0;
    }

    if (this._productdetails[selectedindex].deliveryquantity > (this._productdetails[selectedindex].invoicequantity + this._productdetails[selectedindex].previousdeliveryquantity)) {
      this._CustomExceptionService.handleWarning("Delivery Quantity  should not greater than Invoice Quantity!");
      this._productdetails[selectedindex].deliveryquantity = 0;
      this._productdetails[selectedindex].balancequantity = 0;
    }
    else {

      if (this._productdetails[selectedindex].deliveryquantity == 0) {
        //this._productdetails[selectedindex].balancequantity = 0;
        //this._productdetails[selectedindex].balancequantity = (this._productdetails[selectedindex].invoicequantity + this._productdetails[selectedindex].previousdeliveryquantity) - this._productdetails[selectedindex].deliveryquantity;
        let balance: string = (this._productdetails[selectedindex].invoicequantity + this._productdetails[selectedindex].previousdeliveryquantity - this._productdetails[selectedindex].deliveryquantity).toFixed(3);

        let balanceParsed: number = parseFloat(balance);
        this._productdetails[selectedindex].balancequantity = (balanceParsed);
      }
      else {
        // this._productdetails[selectedindex].balancequantity = (this._productdetails[selectedindex].invoicequantity + this._productdetails[selectedindex].previousdeliveryquantity) - this._productdetails[selectedindex].deliveryquantity;
        let balance: string = (this._productdetails[selectedindex].invoicequantity + this._productdetails[selectedindex].previousdeliveryquantity - this._productdetails[selectedindex].deliveryquantity).toFixed(3);

        let balanceParsed: number = parseFloat(balance);
        this._productdetails[selectedindex].balancequantity = (balanceParsed);
      }
      this.Disablecheckbox(row, event);

    }


  }
  onchangeinvoicetype() {
    this._productdetails = [];
    this._customernameorbranch = [];
    this._invoiceno = [];
    this.selectedProductDetailsRows = [];
    var selectedevent = this._stockdeliveryform.get("invoicetype").value
    var InvoiceType = this._stockdeliveryform.get("invoicetype").value
    this._selectedevidencenoIndex = [];
    this.event.value = [];
    this._stockform.controls["salesinvoiceid"].reset();
    if (InvoiceType == "MSC00210") {
      this._stockdeliveryform.get("deliveryto").setValue(this._deliveryto[0].metasubcode);
    }
    else {
      this.isDisabledotherdeliveryrequired = false;
      this._stockdeliveryform.get("deliveryto").setValue(this._deliveryto[1].metasubcode);
    }
    this._IsProgressSpinner = true;
    let data = {
      InvoiceType: selectedevent,
      BranchId: this._branchid,
      userid: this._userid,
      branches: this.branchids || [],
      customerid: this.customerids || 0
    }
    this._StockdeliveryService.getinvoicestockrequest(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._customernameorbranch = resultJSON.stockdeliverygetcustomerorbranchviews;
      // this._selectedevidencenoIndex=[];
      this._invoiceno = resultJSON.stockdeliverygetinvoicenorequestviews;

      $("input[name='productbarcode']").focus();
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  // onchangeinvoicetype1() {
  //   this.branchids = [];
  // }
  onRowSelect(event) {
    //console.log(event.data);
    //console.log(this.selectedProductDetailsRows);
  }
  onRowUnselect(event) {
    //console.log(event.data);
    //console.log(this.selectedProductDetailsRows);
  }
  SelectAll(event) {
    //console.log(event.data);
    //console.log(this.selectedProductDetailsRows);
  }
  onSave() {
    this._submitted = true;
    this.validate();
    this.validatestockrequest();
    //Product Details
    var l_ProductDetails: Stockdeliverygetproductdetail[] = [];
    Object.keys(this.selectedProductDetailsRows).map((Index) => {
      var currentRow = this.selectedProductDetailsRows[Index];
      var l_productdetails = new Stockdeliverygetproductdetail();
      l_productdetails.stockdeliverydetailid = 0;
      l_productdetails.stockdeliveryid = 0;
      l_productdetails.isdelivered = currentRow.isdelivered;
      l_productdetails.evidenceid = currentRow.evidenceid;
      l_productdetails.evidenceno = currentRow.evidenceno;
      l_productdetails.productid = currentRow.productid;
      l_productdetails.productname = currentRow.productname;
      l_productdetails.variantid = currentRow.variantid;
      l_productdetails.variantdescription = currentRow.variantdescription;
      l_productdetails.evidencedate = currentRow.evidencedate;
      l_productdetails.invoicequantity = currentRow.invoicequantity;
      l_productdetails.balancequantity = currentRow.balancequantity;
      l_productdetails.deliveryquantity = currentRow.deliveryquantity;
      l_productdetails.uomcode = currentRow.uomcode;
      l_productdetails.uomname = currentRow.uomname;
      l_productdetails.serialnumber = currentRow.serialnumber;
      l_productdetails.customerorbranchname = currentRow.customerorbranchname;
      l_productdetails.customerorbranchid = currentRow.customerorbranchid;
      l_productdetails.otherbranchid = currentRow.otherbranchid || 0;
      l_productdetails.otherbranchdeliveredrequired = currentRow.otherbranchdeliveredrequired || false;
      l_productdetails.createdby = sessionStorage["userid"];
      l_productdetails.createdon = new Date();
      l_productdetails.modifiedby = sessionStorage["userid"];
      l_productdetails.modifiedon = new Date();
      l_productdetails.totalinvoicequantity = currentRow.totalinvoicequnatity;
      //l_productdetails.previousdeliveryquantity = currentRow.previousdeliveryquantity > 0 ? currentRow.previousdeliveryquantity : currentRow.deliveryquantity;
      l_productdetails.previousdeliveryquantity = currentRow.previousdeliveryquantity;
      //l_productdetails.previousdeliveryquantity = currentRow.deliveryquantity;
      l_ProductDetails.push(l_productdetails);
    })
    // this._productdetails = l_ProductDetails;
    if (this._stockdeliveryform.valid) {

      // if (this._validatestockrequest == true) {
      if (this._stockdeliverydetailform.valid) {
        if (this.selectedProductDetailsRows.length > 0) {
          if (this._validate == false) return
          if (this.Ans == 0) {
            this.showconfirm('Are you sure you want to proceed this stocks for delivery?', () => {
              // this.InActive(obj);
            }, null)
            return
          }
          if (this._validate == true) {
            var DeliveryDate = this._stockdeliveryform.get("deliverydate").value;
            var datePipe = new DatePipe("en-US");
            var pDeliveryDate = datePipe.transform(DeliveryDate, 'yyyy-MM-dd');
            //For Model and Form Mapping
            this._Stockdelivery = this._stockdeliveryform.getRawValue();
            this._Stockdelivery.deliverydate = (new Date(pDeliveryDate));
            this._StockdeliveryObj = { "Stockdelivery": this._Stockdelivery, "Stockdeliverydetail": l_ProductDetails };
            if (this._action == 'create') {
              this._Stockdelivery.createdby = parseInt(sessionStorage["userid"]);
              this._Stockdelivery.companyid = 0;
              this._Stockdelivery.deliveryby = parseInt(sessionStorage["userid"]);
              this._Stockdelivery.stockdeliveryid = 0;
              this._Stockdelivery.createdon = new Date();
              this._Stockdelivery.accountingyear = new Date().getFullYear();
              this._Stockdelivery.branchid = parseInt(sessionStorage["BranchID"]);
              this._Stockdelivery.inwardstatus = false;
              this._Stockdelivery.modifiedon = new Date();
              this._Stockdelivery.modifiedby = sessionStorage["userid"];
              // this.onEd();
              this._IsProgressSpinner = true;
              this._StockdeliveryService.create(this._StockdeliveryObj).subscribe((result) => {
                const resultJSON = JSON.parse(JSON.stringify(result));
                //console.log(result);
                //console.log(resultJSON);
                if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
                  //29.after saving the record get the response A, display the success message as "Delivery A  Registered Succesfully "
                  this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
                  this.reset(null);
                  //this.isDisabledsave = true;
                  this._IsProgressSpinner = false;
                }
                else {
                  this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
                }
                this._IsProgressSpinner = false;
              },
                error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
            }
            else if (this._action == 'edit') {
              this._Stockdelivery.modifiedon = new Date();
              this._Stockdelivery.modifiedby = sessionStorage["userid"];
              // this.onEd();
              this._IsProgressSpinner = true;
              //47.update initialize should load all fields based on search page selected Delivery No
              this._StockdeliveryService.edit(this._StockdeliveryObj).subscribe((result) => {
                const resultJSON = JSON.parse(JSON.stringify(result));
                if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
                  //52.after Updating  the record, get the response A, display the success message as "Delivery  A  Modified Succesfully "
                  this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
                  this.isDisabledsave = true;
                  this._IsProgressSpinner = false;

                }
                else {
                  this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
                } this._IsProgressSpinner = false;
              },

                error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
            }

          }
          // else {
          //   this._CustomExceptionService.handleWarning(usererrors.Grid_Select_05);
          // }
        }
        else {
          this._CustomExceptionService.handleWarning(usererrors.Grid_Select_02);
        }
      }
      // }
      // else {
      // //  this._CustomExceptionService.handleWarning("Stock Request Number is required");
      // }
    }
  }
  onchangedeliverytype(event) {
    //vehicle
    if (event.value == "MSC00206") {
      //17.if selected delivery type value is 'vehicle' , vehicle no and driver input text field shouldbe visible, and person input text field shouldbe hide.
      this.isDisableddriver = false;
      this.isDisabledvehicle = false;
    }
    else {
      //16.if selected delivery type value is 'hand' , vehicle no and driver input text field shouldbe hide, and person input text field shouldbe visible.
      this.isDisableddriver = true;
      this.isDisabledvehicle = true;

    }
  }
  reset(event) {
    //36.All entered fields should be cleared.
    this._submitted = true;
    this._stockdeliverydetailform.reset();
    this._stockdeliveryform.reset();
    this._productdetails = [];
    this.selectedProductDetailsRows = [];
    this._stockform.reset();
    this.InitializeStockdeliveryForm();
    this.InitializeStockdetailForm();
    this._stockdeliveryform.controls['invoicetype'].setValue("MSC00210");
    this._stockdeliveryform.controls['deliveryto'].setValue("MSC00212");
    this._stockdeliveryform.controls['deliverytype'].setValue("MSC00205");
    $("input[name='productbarcode']").focus();
    this.onchangeinvoicetype();
    this._submitted = false;
    this.Ans = 0;
  }
  //Search Screen Call:
  goBack(event) {
    //37.should redirect to search page.
    this._router.navigate(['/vStockDelivery']);
  }
  GetotherBranchStock(otherbranch: Stockdeliverygetproductdetail, event: any) {
    var ProductId = otherbranch.productid
    var otherbranchid = event.value || 0
    this._StockdeliveryService.GetotherBranchStock(ProductId, otherbranchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.stock != null) {
        otherbranch.otherbranchstock = resultJSON.stock.stock1;
      }
      else {
        otherbranch.otherbranchstock = 0;
      }


      //console.log(resultJSON);
    });
  }

  ChangeVehicle(event): void {
    let driver;

    driver = (this._tripstartclosuregetdrivername || []).find(f => f.vehicleid == event.value);

    if (driver != null && driver != undefined) {
      if (driver.driverid != 0) {
        this._stockdeliveryform.controls['drivername'].setValue(driver.driverid);
        this.isDisableddriver = true;
      }
      else {
        this._stockdeliveryform.controls['drivername'].setValue(0);
        this.isDisableddriver = false;
      }
    }
    else {
      this._stockdeliveryform.controls['drivername'].setValue(0);
      this.isDisableddriver = false;
    }

  }

  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();

    // if (this._action == 'create') {
    //   this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
    //     this.reset(null);
    //     return false; // Prevent bubbling
    //   }, ['INPUT', 'SELECT', 'TEXTAREA']));
    // }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  //  Debug
  //  onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._StockdeliveryObj;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }

  event: any = {
    value: []
  }

  FetchProductUsingBarcode() {
    this.customerids = 0;
    this._selectedevidencenoIndex = [];
    setTimeout(() => {
      let barcodevalue = <string>this.productbarcode;
      if (!barcodevalue) {
        this.productbarcode = "";
        return;
      }
      //this.event.value = [];
      barcodevalue = barcodevalue.trim();
      this.event.value.push(barcodevalue);
      this._selectedevidencenoIndex = [];
      this._selectedevidencenoIndex = this.event.value;
      //this._invoiceno = [];
      this.onchangeinvoiceno(this.event);
      this.productbarcode = "";
    }, 400)
  }
}
