import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { UploadFileService } from 'src/app/core/Utilities/fileupload/upload-file.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Admanagement } from 'src/app/core/Models/AdManagement';
import { AdmanagementService } from 'src/app/core/Services/ecommerce/admanagement.service';
import * as ace from "ace-builds";
import { Observable, Observer } from 'rxjs';
import { usererrors } from 'src/app/core/errors/usererrors';
import { FileUpload } from 'primeng/fileupload';
import { NgxImageCompressService } from 'ngx-image-compress';
import { products } from 'src/app/core/Models/product-master';
import { AdmanagementDetail } from 'src/app/core/Models/AdmanagementDetail';
import { DatePipe } from '@angular/common';
import { productsCattegory } from 'src/app/core/Models/overallclass';
// import { ViewChild } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-c-ad-management',
  templateUrl: './c-ad-management.component.html',
  styleUrls: ['./c-ad-management.component.css']
})
export class CAdManagementComponent implements OnInit {
  _admanagementform: FormGroup;
  _IsProgressSpinner: boolean = true;
  _status: Metadatum;
  selectedCities: string[] = [];
  city: string;
  checked1: boolean = false;
  disabled: boolean = true;
  displayBasic: boolean;
  displayBasic1: boolean;
  imageCropperTool: boolean;
  position: string;
  defaultDate: Date;
  uploadedFiles: any[] = [];

  _LastAddedGridDocument: File;
  fileUpload: any;
  imageChangedEvent: any;
  croppedImage: any;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  adimage: string;
  _AdManagement: Admanagement;
  _AdId: number;
  _action: string = "create";
  iii: string;
  _AdManagementObj: any;
  _AdFORM: FormData;
  _CropImage: File;
  admanagement: SafeUrl;
  displayimagepopup: boolean;
  position1: string;
  _LastAddedImage: File;
  imageselect: string = "";
  _Iscreatefileupload: boolean = true;
  _Isviewfileupload: boolean = true;
  disablereset: boolean = false;
  isDisabledSave: boolean = false;
  clearimage: boolean = false;
  Weburl: any;
  Mobileurl: any;
  _WebImage: File;
  _MobileImage: File;
  _Selectedtab: number = 0;
  _Product: products[] = []
  _TempProduct: products[] = []
  _Productcategory: productsCattegory[] = []
  _selectedProductArrayIndex: any[] = []
  _selectedProductCategory: any[] = []
  _ProductList: any[]
  minDate: Date;
  MaxDate: Date;
  Today: Date;
  Mintoday = new Date()
  min: Date;
  Max: Date;
  datepipe = new DatePipe("en-US");
  // InputVar: ElementRef
  // @ViewChild('myInput')
  // myInputVariable: ElementRef;
  // @ViewChild('fileUploader') fileUploader:ElementRef;
  // @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private _uploadService: UploadFileService,
    private _router: Router,
    private utility: CommonUtilities,
    private _CustomExceptionService: CustomExceptionService,
    private _DomSanitizationService: DomSanitizer,
    private _AdManagementService: AdmanagementService,
    private imageCompress: NgxImageCompressService
  ) {
    this._AdId = history.state?.adid ? history.state?.adid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
  }
  InitializeForm() {
    this._admanagementform = this.fb.group({
      adid: [0],
      adurl: [],
      adurlname: [],
      adname: [],
      status: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      mobileadurl: [],
      mobileadurlname: [],
      effectivefrom: [null, Validators.required],
      effectiveto: [null, Validators.required],
      discountper: [null, Validators.required],
      ecnavigateurl: [],
      admanagementDetails: []
    });
    this._admanagementform.controls['status'].setValue("MSC00001");
  }
  OnInTimeDateSelect1(event) {
    this._admanagementform.controls["effectiveto"].reset()
    this.minDate = event;
  }
  showBasicDialog(position: string) {
    this.position = position;
    this.displayBasic = true;
  }
  showBasicDialog1() {
    this.displayBasic1 = true;
  }
  RemoveFile() {
    //this.uploadedFiles.push(file);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    // console.log("Image", this.imageChangedEvent);
    this.imageCropperTool = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    // console.log(event, base64ToFile(event.base64));
    //console.log("crop",this.croppedImage);
  }

  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // if (sourceImageDimensions.width <= 580) {
    //   alert("Error");
    // }
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    // console.log('Load failed');
  }
  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm()
    if (this._action == 'create') {
      this._Iscreatefileupload = true;
      this._Isviewfileupload = false;
    }
    if (this._action == 'view') {
      this.isDisabledSave = true
      this._admanagementform.disable();
      this.clearimage = true
    }

    if (this._action == 'edit' || this._action == 'view') {
      this.disablereset = true
      this._Isviewfileupload = true;
      this._Iscreatefileupload = false;
    }
    this._IsProgressSpinner = true;
    this._AdManagementService.PageOnload(this._AdId).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this._admanagementform.controls['status'].setValue("MSC00001");
      console.log(resultJSON);
      this._status = resultJSON.status;
      this._Product = resultJSON.productlist;
      this._TempProduct = resultJSON.productlist;
      this._Productcategory = resultJSON.productcategorylist;
      $(document).on("change", ".file-upload-field", function () {
        $(this).parent(".file-upload-wrapper").attr("data-text", $(this).val().replace(/.*(\/|\\)/, ''));
      });
      this._IsProgressSpinner = false;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._IsProgressSpinner = false;
        if (updateJSON.admanagement.adurl) {
          this.admanagement = this._DomSanitizationService.bypassSecurityTrustUrl((updateJSON.admanagement.adurl));
          this._ProductList = updateJSON.admanagementProductDetail
          var ProductId: number[] = [];
          if (this._ProductList || this._ProductList.length) {
            Object.keys(updateJSON.admanagementProductDetail).map(function (index) {
              ProductId.push(updateJSON.admanagementProductDetail[index].productId)
            });
            this._selectedProductArrayIndex = ProductId;
          }
        }
        updateJSON.admanagement.effectivefrom = new Date(updateJSON.admanagement.effectivefrom)
        updateJSON.admanagement.effectiveto = new Date(updateJSON.admanagement.effectiveto)
        this._admanagementform.setValue(updateJSON.admanagement);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });



  }
  selectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      const img = new Image();
      img.src = URL.createObjectURL(event.target.files[0]);
      img.onload = (e: any) => {
        if (e.srcElement.width == 530 && e.srcElement.height == 285) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            let url = event.target.result;
            this.webcompressFile(url, -1)
          }
          reader.readAsDataURL(event.target.files[0]);
        } else {
          $(".file-upload-wrapper").attr("data-text", 'Select your file!');
          this._CustomExceptionService.handleWarning("Your uploaded image should be minimum Width and height of 530px X 285px")
        }
      }


    }
  }

  webcompressFile(image: any, orientation: any) {
    this.imageCompress
      .compressFile(image, orientation, 100, 70)
      .then((result: any) => {
        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        this.Weburl = imageBlob
        this.mobilecompressFile(result, -1);
      });

  }
  mobilecompressFile(image: any, orientation: any) {
    this.imageCompress
      .compressFile(image, orientation, 75, 70)
      .then((result: any) => {
        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        this.Mobileurl = imageBlob
        this.createBlobImageFileAndShow(this.Weburl, this.Mobileurl);
      });

  }
  createBlobImageFileAndShow(web: any, mobile: any): void {
    const webimageName: string = this.generateName();
    const mobileimageName: string =webimageName;
    this._WebImage = new File([web], webimageName, {
      type: "image/webp"
    });
    this._MobileImage = new File([mobile], mobileimageName, {
      type: "image/webp"
    });
  }



  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = "";
    const possibleText: string =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + "." + text + ".webp";
  }

  saveImage(event) {
    // 530 x 285
    var imgWidth = $('.source-image').get(0).naturalWidth;
    var imgheight = $('.source-image').get(0).naturalHeight;
    // console.log("width", imgWidth)
    // console.log("height", imgheight)
    // imgWidth >= 494.984 && imgheight >= 266.172
    if (imgWidth >= 530 && imgheight >= 285) {
      // this.createBlobImageFileAndShow();
    } else {
      this._CustomExceptionService.handleWarning("Your uploaded image should be minimum Width and height of 530px X 285px")
      // alert("ok")
    }
    // this.createBlobImageFileAndShow();
    this.imageCropperTool = false
  }
  // onSelectedImageClear(){
  //   this.myInputVariable.nativeElement.value = null;

  // }
  // onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._AdManagementObj;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }


  showImageDialog(position1: string) {
    if (this._LastAddedImage) {
      this.admanagement = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedImage));
      this.imageselect = this._LastAddedImage.name;
    }
    if (this.admanagement == null) {
      return;
    }
    this.position1 = position1;
    this.displayimagepopup = true;
  }

  onImageClear() {
    this._Iscreatefileupload = true;
    this._Isviewfileupload = false;
    this.admanagement = null;
    this._LastAddedImage = null;
  }
  showPopupDialog(position1: string) {
    this.position1 = position1;
    this.displayimagepopup = false;
  }
  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/webp' });
    return blob;
  }
  formconverstion(_AdManagement: Admanagement, _AdManagementDetail: AdmanagementDetail[], _webimage: File, _mobileimage: File) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    if (_webimage != undefined && _mobileimage != undefined) {
      if (_AdManagement.adurlname == this._WebImage.name && _AdManagement.mobileadurlname == this._MobileImage.name) {

        // var file = base64ToFile(this.croppedImage);
        var WebNewFileName = time.getTime() + '_' + this._WebImage.name;
        var MobileNewFileName = time.getTime() + '_' + this._MobileImage.name;
        _AdManagement.adurlname = WebNewFileName;
        _AdManagement.adurl = WebNewFileName;
        _AdManagement.mobileadurlname = MobileNewFileName;
        _AdManagement.mobileadurl = MobileNewFileName;
        formData.append('webfile', _webimage, WebNewFileName);
        formData.append('mobilefile', _mobileimage, MobileNewFileName);
      }
    }

    this._AdManagementObj = {
      "Admanagement": this._AdManagement,
      "AdmanagementProductDetail": this._ProductList
    };
    var admanagements = JSON.stringify(this._AdManagementObj);
    formData.append('AdManagement', admanagements);
    return (formData);
  }
  OnChangeofProduct(event) {
    this._ProductList = []
    var l_productlist: AdmanagementDetail[] = [];
    var O_product: AdmanagementDetail;
    Object.keys(event.value).map((Index) => {
      O_product = new AdmanagementDetail();
      O_product.Adid = 0;
      O_product.AdmanagementDetailId = 0;
      O_product.ProductId = event.value[Index];
      l_productlist.push(O_product);
    })
    this._ProductList = l_productlist;
  }
  getProducts() {
    if (this._selectedProductCategory.length == 0 || this._selectedProductCategory == null) {
      this._selectedProductArrayIndex = []
      this._Product = []
      this._Product = this._TempProduct
      return;
    }
    else {
      let data = {
        "Productcategory": this._selectedProductCategory || []
      }
      this._AdManagementService.getProduct(data).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Product = resultJSON.productlist;
      },
        error => { this._CustomExceptionService.handleError(error); });
    }
  }
  onSave() {
    if (this._admanagementform.valid) {
      if (!this._ProductList || !this._ProductList.length) {
        this._CustomExceptionService.handleError("Please Select Atleast One Product");
        return
      }
      this._AdManagement = this._admanagementform.value;
      var datePipe = new DatePipe("en-US");
      let EFD=this._admanagementform.get("effectivefrom").value
      let ETD=this._admanagementform.get("effectiveto").value
      let EF=datePipe.transform(EFD,'yyyy-MM-dd')
      let ET=datePipe.transform(ETD,'yyyy-MM-dd')
      this._AdManagement.effectivefrom =new Date(EF);
      this._AdManagement.effectiveto = new Date(ET);
      
      if (this._action == 'create') {

        this._AdManagement.adid = 0;
        if (this._WebImage == undefined || this._WebImage == null || this._MobileImage == undefined || this._MobileImage == null) {
          this._CustomExceptionService.handleWarning("Please select ad image");
          return
        } else {
          this._AdManagement.adurl = this._WebImage.name;
          this._AdManagement.adurlname = this._WebImage.name;
          this._AdManagement.mobileadurl = this._MobileImage.name;
          this._AdManagement.mobileadurlname = this._MobileImage.name;
        }
        this._AdManagement.createdby = sessionStorage["userid"];
        this._AdManagement.createdon = new Date();
        this._AdManagement.modifiedon = new Date();
        this._AdManagement.modifiedby = sessionStorage["userid"];
        this._AdFORM = this.formconverstion(this._AdManagement, this._ProductList, this._WebImage, this._MobileImage);
        this._IsProgressSpinner = true;
        this._AdManagementService.create(this._AdFORM).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {

            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset()
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          } this._IsProgressSpinner = false;
        },

          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        if (this._WebImage == undefined && this._MobileImage == undefined) {
          this._AdManagement.adurl = this._admanagementform.get("adurl").value;
          this._AdManagement.adurlname = this._admanagementform.get("adurlname").value;
          this._AdManagement.mobileadurl = this._admanagementform.get("mobileadurl").value;
          this._AdManagement.mobileadurlname = this._admanagementform.get("mobileadurlname").value;
        }
        if (this._WebImage && this._MobileImage) {
          this._AdManagement.adurl = this._WebImage.name;
          this._AdManagement.adurlname = this._WebImage.name;
          this._AdManagement.mobileadurl = this._MobileImage.name;
          this._AdManagement.mobileadurlname = this._MobileImage.name;
        }
        this._AdManagement.modifiedon = new Date();
        this._AdManagement.modifiedby = sessionStorage["userid"];
        this._AdFORM = this.formconverstion(this._AdManagement, this._ProductList, this._WebImage, this._MobileImage);
        //this.onEd()
        this._IsProgressSpinner = true;
        this._AdManagementService.edit(this._AdFORM).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {

            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          } this._IsProgressSpinner = false;
        },

          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }

    }
  }

  reset() {
    this.InitializeForm();
    this.croppedImage = [];
    this.imageselect = "";

    $(".file-upload-wrapper").attr("data-text", 'Select your file!');
  }
}
