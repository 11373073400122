import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Documenttypeworkflow } from 'src/app/core/Models/Documenttypeworkflow';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { ApprovaltypeService } from 'src/app/core/services/users/approvaltype.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { vSelectDocumentName, vSelectWorkflowType, vWorkflowName, vSelectConditionType, vConditionValue, vTATinHrs } from 'src/app/core/Validators/validation';
import { Approvaltypeview } from 'src/app/core/Views/Approvaltypeview';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
@Component({
  selector: 'app-c-approvaltype',
  templateUrl: './c-approvaltype.component.html',
  styleUrls: ['./c-approvaltype.component.css']
})
export class CApprovaltypeComponent implements OnInit {
  _Approvaltypeform: FormGroup;
  _workflowform: FormGroup;
  _action: string = "create";
  _submitted = false;

  _Documentname: Function;
  _Workflowtype: Metadatum;
  _Conditiontype: Metadatum;
  _Approvalstatus: Metadatum;
  _Approvaltype: Documenttypeworkflow;
  _workflowdetails: Approvaltypeview[] = [];
  _workflowdetail: Approvaltypeview;
  _workflowdetailform: Approvaltypeview[];
  _approvalTypeView: Approvaltypeview;
  _approvalTypeViews: Approvaltypeview[] = [];
  _updateworkflowlist: Approvaltypeview[] = [];
  selectedrows: any;
  updateworkflow: any;
  _workflowid: number;
  _documenttypeid: number;
  _ApprovaltypeOBJ: any;
  isDisabledSave = false;
  isDisabledClear = false;
  _IsProgressSpinner: boolean = true;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _router: Router,
    private _ApprovaltypeService: ApprovaltypeService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService) {
      this._workflowid = history.state?.workflowid ? history.state?.workflowid : 0;
      this._documenttypeid = history.state?.documenttypeid ? history.state?.documenttypeid : 0;
      this._action = history.state.action == null ? 'create' : history.state.action;
      this.HotKeyIntegration();
  }
  InitializeForm() {
 //Parent Form
 this._Approvaltypeform = this.fb.group({
  documenttypeid: ['', vSelectDocumentName],
  workflowid: [0],
  workflowtype: ['', vSelectWorkflowType],
  workflowname: ['', vWorkflowName],
  conditiontype: [''],
  conditionvalue: [''],
  tatinhours: [''],
  approvaltypestatus: ["MSC00001"],
  createdby: [],
  createdon: [],
  modifiedby: [],
  modifiedon: [],

  documenttypename: [],
  workflowtypename: [],
  conditiontypename: [],
  approvaltypestatusname: []
});
this._workflowform = this.fb.group({
  documenttypeid: [],
  documenttypename: [],
  workflowid: [0],
  workflowtypecode: [],
  workflowtypename: [],
  workflowname: [],
  conditiontypecode: [],
  conditiontypename: [],
  conditionvalue: [],
  tatinhours: [],
  approvaltypestatuscode: [],
  approvaltypestatusname: [],
  createdby: [],
  createdon: [],
  modifiedby: [],
  modifiedon: [],

});

  }
 
  ngOnInit(): void {
    this.utility.pageLoadScript();
   this.InitializeForm();   
    if (this._action == 'view') {
      this._Approvaltypeform.disable();
      this.isDisabledSave = true;
      this.isDisabledClear = true;
    }

    if (this._action == 'edit') {
      // 28.document  name must be disabled ,because it should not allow to update
      // 35.and every column must be disabled
      this.isDisabledSave = false;
      this.isDisabledClear = true;
    }
    this._IsProgressSpinner = true;
    this._ApprovaltypeService.PageOnLoad().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // For DropDown
      this._Documentname = resultJSON.functionlist;
      this._Workflowtype = resultJSON.workflowtype;
      this._Conditiontype = resultJSON.workflowconditiontype;
      this._Approvalstatus = resultJSON.workflowstatus;
      this._approvalTypeViews = resultJSON.approvalTypeView;
      this._IsProgressSpinner = false;


      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        // 27.according to the ,approval type id., all the datas should fetch in particular columns.
        // 34.according to the ,approval type id., all the datas should fetch in particular columns.
        this._workflowdetails = (this._approvalTypeViews || []).filter(f => f.documenttypeid == this._documenttypeid);
        this._workflowdetailform = (this._workflowdetails || []).filter(f => f.workflowid == this._workflowid);
        this._Approvaltypeform.setValue(this._workflowdetailform[0]);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ChangeDocument(): void {
    var documnettype = parseInt(this._Approvaltypeform.get("documenttypeid").value);
    if (!documnettype)
      return;
    this._workflowdetails = (this._approvalTypeViews || []).filter(f => f.documenttypeid == documnettype);
  }
  //  18. on clicking remove button, it delete the particular row in the grid
  RemoveWorkflow(pWorkflowdetail: Approvaltypeview) {
    var index = this._workflowdetails.indexOf(pWorkflowdetail);
    this._workflowdetails.splice(index, 1)
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  onSave() {
    this._submitted = true;
    if (this._Approvaltypeform.valid) {
      this._Approvaltype = this._Approvaltypeform.value;
      if (this._action == 'create') {
        this._Approvaltype.createdon = new Date();
        this._Approvaltype.createdby = sessionStorage["userid"];
        this._Approvaltype.modifiedon = new Date();
        this._Approvaltype.modifiedby = sessionStorage["userid"];
        // 20.if all the data is valid, allow to save the record in the database 
        this._IsProgressSpinner = true;
        this._ApprovaltypeService.create(this._Approvaltype).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
            this._approvalTypeViews = resutJSON.approvalTypeView;
            // 17.if all the data is valid, the header data pushes to the grid
            this._workflowdetails = (resutJSON.approvalTypeView || []).filter(f => f.documenttypeid == resutJSON.approvaltype.documenttypeid);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        // 30.if all the data is valid, allow to Update the record in the database with respect to approval type id 
        //this._workflowdetails.push(this._Approvaltype)
        this.updateworkflow = { "approvaltypelist": this._workflowdetails };
        //this.onEd();
        this._IsProgressSpinner = true;
        this._ApprovaltypeService.edit(this.updateworkflow).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            //  31. then show succes message as "Approval Type Updated succesfully"
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
  reset(event) {
    this._Approvaltypeform.reset();
    this._submitted = false;
    this.InitializeForm();
    this._workflowdetails = [];
  }
  goBack(event) {
    this._router.navigate(['/vApprovaltype']);
  }
  // onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this.updateworkflow;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);

  // }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}
