import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Console } from 'console';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { OpeningBalanceUpdationService } from 'src/app/core/Services/accounts/opening-balance-updation.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { OpeningBalanceLedgerAndCurrencyWise } from 'src/app/core/Models/OpeningBalanceLedgerAndCurrencyWise';
import { usererrors } from 'src/app/core/errors/usererrors';
import autoTable from 'jspdf-autotable';

@Component({
	selector: 'app-opening-balance-updation',
	templateUrl: './opening-balance-updation.component.html',
	styleUrls: ['./opening-balance-updation.component.css']
})

export class OpeningBalanceUpdationComponent implements OnInit {
	_IsProgressSpinner: boolean = false;
	_submitted = false;
	_balanceupdationform: FormGroup;
	_openingbalanceview: any[] = [];
	yearslist: any[] = [];
	selectedrows: any[] = [];
	cols: any[] = [];
	colsvalues: string[];
	_statuslist: Metadatum[];
	status: string = "";
	_screenlist: any[] = []
	PartyType: any
	_BillDetail: boolean;
	selectedIndex: number;
	currencylist: any[] = [];
	currentDate: any = new Date();
	year: any = this.currentDate.getFullYear()
	constructor(private utility: CommonUtilities, private fb: FormBuilder, private _OpeningBalanceUpdationService: OpeningBalanceUpdationService,
		private _router: Router, private _CustomExceptionService: CustomExceptionService, private sanitizer: DomSanitizer, private exportUtility: ExportUtility
	) { }
	InitializeFormGroup() {

	}

	ngOnInit(): void {
		this.utility.pageLoadScript();
		this._IsProgressSpinner = true;
		this._OpeningBalanceUpdationService.PageOnload().subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._screenlist = resultJSON.partytypelist;
			this.currencylist = resultJSON.currencylist;
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	onPartyTypeChange() {
		this._openingbalanceview = [];
	}

	onBond(rowdata) {
		console.log("function is called")
		function convertToUSD(balance, exchangeRate) {
			return balance / exchangeRate;
		}
		let exchangerateUSD = this.currencylist.find(f => f.currencyid == 3).exchangerate;
		let exchangerateRand = this.currencylist.find(f => f.currencyid == 2).exchangerate;
		let exchangerateBANK = this.currencylist.find(f => f.currencyid == 4).exchangerate;
		let exchangerateUSDSP = this.currencylist.find(f => f.currencyid == 8).exchangerate;
		let exchangerateRTGS = this.currencylist.find(f => f.currencyid == 5).exchangerate;
		let exchangerateNOSTRO = this.currencylist.find(f => f.currencyid == 7).exchangerate;
		let exchangerateZIM1 = this.currencylist.find(f => f.currencyid == 6).exchangerate;
		let USD = (rowdata.OpeningBalanceUsd);
		let bondUSD = convertToUSD(rowdata.OpeningBalanceBond, exchangerateUSD);
		let RANDUSD = convertToUSD(rowdata.OpeningBalanceRand, exchangerateRand);
		let BANKUSD = convertToUSD(rowdata.OpeningBalanceBank, exchangerateBANK);
		let USDSPUSD = convertToUSD(rowdata.OpeningBalanceUsdsp, exchangerateUSDSP);
		let RTGSUSD = convertToUSD(rowdata.OpeningBalanceRtgs, exchangerateRTGS);
		let NOSTROUSD = convertToUSD(rowdata.OpeningBalanceNostro, exchangerateNOSTRO);
		let ZIM1USD = convertToUSD(rowdata.OpeningBalanceZim1, exchangerateZIM1);

		rowdata.Consolidatedinusd = USD + bondUSD + RANDUSD + BANKUSD + USDSPUSD + RTGSUSD + NOSTROUSD + ZIM1USD;
	}

	search() {
		if (this.PartyType) {
			let data = {
				"PartyType": this.PartyType
			}
			this._IsProgressSpinner = true;
			this._OpeningBalanceUpdationService.Search(data).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._openingbalanceview = resultJSON.openingBalanceLedgerAndCurrencyWises;
				var currentlist = resultJSON.yearslist;

				for (let i = 0; i < this._openingbalanceview.length; i++) {
					const x = this._openingbalanceview[i];
					x.disablecontrols = true;
				}
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		} else {
			this._CustomExceptionService.handleWarning("Please Select Party Type");
		}
	}

	onRowSelect(event) {
		const data = event.data
		data.disablecontrols = false;
	}
	onRowUnselect(event) {
		const data = event.data
		data.disablecontrols = true;
	}

	onSave() {
		if (!this.selectedrows || !this.selectedrows.length || this.selectedrows.length == 0) {
			this._CustomExceptionService.handleWarning("Please Select Atleast One Row")
			return
		}
		for (let i = 0; i < this.selectedrows.length; i++) {
			this.onBond(this.selectedrows[i]);
			this.selectedrows[i].Accountingyear = this.currentDate.getFullYear();
			this.selectedrows[i].Createdby = sessionStorage["userid"];
			this.selectedrows[i].Modifiedby = sessionStorage["userid"];
			this.selectedrows[i].Createdon = this.currentDate;
			this.selectedrows[i].Modifiedon = this.currentDate;
			this.selectedrows[i].OpeningBalanceUniqueid = 0;
		}
		let Data = {
			"OpenningBalanceUpdation": this.selectedrows
		}

		this._IsProgressSpinner = true;
		this._OpeningBalanceUpdationService.Create(Data).subscribe((result) => {
			this._IsProgressSpinner = false;
			const resutJSON = JSON.parse(JSON.stringify(result));
			if (resutJSON.tranStatus.result) {
				this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
				this._openingbalanceview = []
				this.selectedrows = []
			} else { this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message); this._IsProgressSpinner = false; }
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	exportExcel() {
		let employeeList = this.OpeningbalanceLists();
		import("xlsx").then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(employeeList);
			const workbook = { Sheets: { 'Opening balanceLists Report': worksheet }, SheetNames: ['Opening balanceLists Report'] };
			const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
			this.saveAsExcelFile(excelBuffer, "Opening balanceLists Report");
		});
	}

	saveAsExcelFile(buffer: any, fileName: string): void {
		import("file-saver").then(FileSaver => {
			let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
			let EXCEL_EXTENSION = '.xlsx';
			const data: Blob = new Blob([buffer], {
				type: EXCEL_TYPE
			});
			FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
		});
	}

	exportPdf() {
		let OpeningbalanceList = this.OpeningbalanceLists();
		const data = OpeningbalanceList.map(Object.values);
		const head = [['Party Type', 'Party Code', 'Party Name', 'USD', 'BOND', 'BANK', 'RAND ', 'USDSP', 'RTGS', 'NOSTRO', 'ZIM1', 'Consolidated in USD'
		]]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				data.forEach(row => {
					row.forEach((value, index) => {
						if (value === null) {
							row[index] = '';
						}
					});
				});
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					},
					// theme: 'grid',
					headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
						// fontSize: 15,
					},
					bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('Opening balanceList Report.pdf');
			})
		})
	}

	OpeningbalanceLists() {
		let Openingbalanceupdatelist = [];
		this._openingbalanceview.forEach((element, index) => {
			let openinglist: any = {};
			openinglist["Party Type"] = element.PartyType;
			openinglist["Party Code"] = element.PartyCode;
			openinglist["Party Name"] = element.LedgerName;
			openinglist["USD"] = element.OpeningBalanceUsd;
			openinglist["RAND"] = element.OpeningBalanceRand;
			openinglist["BOND"] = element.OpeningBalanceBond;
			openinglist["BANK"] = element.OpeningBalanceBank;
			openinglist["USDSP"] = element.OpeningBalanceUsdsp;
			openinglist["RTGS"] = element.OpeningBalanceRtgs;
			openinglist["NOSTRO"] = element.OpeningBalanceNostro;
			openinglist["ZIM1"] = element.OpeningBalanceZim1;
			openinglist["Consolidated in USD"] = element.Consolidatedinusd;
			Openingbalanceupdatelist.push(openinglist);
		});
		return Openingbalanceupdatelist;
	}

	//Clear Fucntion:
	clear() {
		this._openingbalanceview = [];
		this.PartyType = null;
	}
}
