<div class="page-container dashboard-page scroll-y">
  <div class="inner-page-container">
    <div class="page-loader" *ngIf="_IsProgressSpinner">
      <p-progressSpinner strokeWidth="5"></p-progressSpinner>
    </div>

    <div class="white">
      <div class="page-title">
        <div class="row">
          <div class="col-md-5">
            <h3>Purchase Dashboard</h3>
          </div>
          <div class="col-md-7 text-right"></div>
        </div>
      </div>
      <form [formGroup]="_purchasedashboardform">
        <table class="normal-table">
          <tr>
            <td style="width: 20%;">
              <span class="p-float-label">
                <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate"
                  dateFormat="dd/mm/yy" (onSelect)="generatePurchase()">
                  <!-- (onSelect)="onchangefromdate()" -->
                </p-calendar>
                <label for="todate">Date</label>
              </span>
            </td>
            <!-- <td style="width: 20%;">
              <span class="p-float-label">
                <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true" yearRange="1980:2030"
                  [maxDate]="maxdate" [minDate]="mindate" dateFormat="dd/mm/yy">
                </p-calendar>
                <label for="todate">To Date</label>
              </span>
            </td> -->
            <td style="width: 20%">
              <span class="p-float-label">
                <p-dropdown [autoDisplayFirst]="false" [options]="_Branches" filter="true" name="branchid"
                  optionLabel="branchname" optionValue="branchid" formControlName="branchid"
                  (onChange)="generatePurchase()">
                </p-dropdown>
                <label for="branchid">Branch <span class="hlt-txt">*</span></label>
              </span>
            </td>
            <td style="width: 60%">
              <div class="row workflow-widget"></div>
            </td>
          </tr>
        </table>
      </form>
    </div>

    <div class="form-container">
      <div class="row workflow-widget">
        <div class="col-md-3">
          <div class="white workflow-summary">
            <div class="color01">
              <a href="javascript:void(0);" class="workflow-links">
                <h5 style="color: #626262">
                  Total PO Raised
                  <span style="color: #0948cc;font-weight: 600;font-size: 14px;">(In No's)</span>
                </h5>
                <h1>{{ purchaseHead.TOTALPOCOUNT }}</h1>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="white workflow-summary">
            <div class="color01">
              <a href="javascript:void(0);" class="workflow-links">
                <h5 style="color: #626262">Inward Completed
                  <span style="color: #0948cc;font-weight: 600;font-size: 14px;">(In No's)</span>
                </h5>
                <h1>{{ purchaseHead.TOTALINWARDCOUNT }}</h1>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="white workflow-summary">
            <div class="color01">
              <a href="javascript:void(0);" class="workflow-links">
                <h5 style="color: #626262">GRN Completed
                  <span style="color: #0948cc;font-weight: 600;font-size: 14px;">(In No's)</span>
                </h5>
                <h1>{{ purchaseHead.TOTALGRNCOUNT }}</h1>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="white workflow-summary">
            <div class="color01">
              <a href="javascript:void(0);" class="workflow-links">
                <h5 style="color: #626262">
                  Purchase Invoice Completed
                  <span style="color: #0948cc;font-weight: 600;font-size: 14px;">(In No's)</span>
                </h5>
                <h1>{{ purchaseHead.TOTALINVOICECOUNT }}</h1>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="white">
            <div class="widget-title page-title">
              <div class="row">
                <div class="col-md-8">
                  <h3>
                    Purchase Invoice Quantity -
                    <span style="color: #0948cc;font-size: 16px;padding: 0px 3px;font-weight: 100;">Product Category
                      Wise</span>
                    (In No's)
                  </h3>
                </div>
                <div class="col-md-4">
                  <div class="widget-filter text-right">
                    <div class="filter-container">
                      <!-- <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="Filter By"><i
                                              class="las la-filter"></i></a> -->
                      <div class="dropdown-menu dropdown-menu-right widget-popup big collapse">
                        <form>
                          <table class="normal-table">
                            <tr>
                              <td>
                                <span class="p-float-label">
                                  <p-autoComplete field="branchname">
                                    <ng-template let-branch pTemplate="item">
                                      <div>{{ branch.branchname }}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="branchid">Branch</label>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span class="p-float-label">
                                  <!-- <p-multiSelect
                                    [options]="_productname"
                                    formControlName="productid"
                                    optionLabel="productname"
                                    optionValue="productid"
                                    displaySelectedLabel="true"
                                  >
                                  </p-multiSelect> -->
                                  <label for="productid">Product Name </label>
                                </span>
                              </td>
                            </tr>
                          </table>
                        </form>
                      </div>
                    </div>
                    <div class="filter-container">
                      <a href="javascript:void(0);" data-toggle="dropdown" data-toggle-second="tooltip"
                        data-placement="top" title="Choose Chart"><i class="las la-chart-bar"></i></a>
                      <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                        <a (click)="chartchangepd('bar')"><i class="icofont-chart-bar-graph"></i>Bar
                          Chart</a>
                        <a (click)="chartchangepd('pie')"><i class="icofont-pie-chart"></i>Pie
                          Chart</a>
                        <a (click)="chartchangepd('line')"><i class="icofont-chart-line"></i>Line
                          Chart</a>
                        <a (click)="chartchangepd('doughnut')"><i class="icofont-chart-pie-alt"></i>Donut Chart</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="widget-body">
              <div class="widget-body">
                <div class="widget-details" *ngIf="_Isbarpd">
                  <p-chart *ngIf="!noDoubleBarChart" type="bar" [data]="purchaseRep"
                    [options]="purchaseRepOpt"></p-chart>
                  <div class="widget-details" *ngIf="noDoubleBarChart">
                    <img style="max-height: 344px; margin: auto; display: block"
                      src="../../../../../assets/images/no-data.jpg" alt="" />
                  </div>
                </div>
                <div class="widget-details" *ngIf="_Islinepd">
                  <p-chart *ngIf="!noDoubleBarChart" type="line" [data]="purchaseRep"
                    [options]="purchaseRepOpt"></p-chart>
                  <div class="widget-details" *ngIf="noDoubleBarChart">
                    <img style="max-height: 344px; margin: auto; display: block"
                      src="../../../../../assets/images/no-data.jpg" alt="" />
                  </div>
                </div>
                <div class="widget-details" *ngIf="_Ispiepd">
                  <p-chart *ngIf="!noDoubleBarChart" type="pie" [data]="purchaseRep"
                    [options]="purchaseRepOpt"></p-chart>
                  <div class="widget-details" *ngIf="noDoubleBarChart">
                    <img style="max-height: 344px; margin: auto; display: block"
                      src="../../../../../assets/images/no-data.jpg" alt="" />
                  </div>
                </div>
                <div class="widget-details" *ngIf="_Isdoughnutpd">
                  <p-chart *ngIf="!noDoubleBarChart" type="doughnut" [data]="purchaseRep"
                    [options]="purchaseRepOpt"></p-chart>
                  <div class="widget-details" *ngIf="noDoubleBarChart">
                    <img style="max-height: 344px; margin: auto; display: block"
                      src="../../../../../assets/images/no-data.jpg" alt="" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="white">
            <div class="widget-title page-title">
              <div class="row">
                <div class="col-md-8">
                  <h3>Purchase Invoice Value -
                    <span style="color: #0948cc;font-size: 16px;padding: 0px 3px;font-weight: 100;">Product Category
                      Wise</span>
                    (In Percentage)
                  </h3>
                </div>
                <div class="col-md-4">
                  <div class="widget-filter text-right">
                    <div class="filter-container">
                      <!-- <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="Filter By"><i
                                              class="las la-filter"></i></a> -->
                      <div class="dropdown-menu dropdown-menu-right widget-popup big collapse">
                        <form>
                          <table class="normal-table">
                            <tr>
                              <td>
                                <span class="p-float-label">
                                  <p-autoComplete field="branchname">
                                    <ng-template let-branch pTemplate="item">
                                      <div>{{ branch.branchname }}</div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <label for="branchid">Branch</label>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span class="p-float-label">
                                  <!-- <p-multiSelect
                                    [options]="_productname"
                                    formControlName="productid"
                                    optionLabel="productname"
                                    optionValue="productid"
                                    displaySelectedLabel="true"
                                  >
                                  </p-multiSelect> -->
                                  <label for="productid">Product Name </label>
                                </span>
                              </td>
                            </tr>
                          </table>
                        </form>
                      </div>
                    </div>
                    <div class="filter-container">
                      <a href="javascript:void(0);" data-toggle="dropdown" data-toggle-second="tooltip"
                        data-placement="top" title="Choose Chart"><i class="las la-chart-bar"></i></a>
                      <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                        <a (click)="chartchangesd1('bar')"><i class="icofont-chart-bar-graph"></i>Bar Chart</a>
                        <a (click)="chartchangesd1('pie')"><i class="icofont-pie-chart"></i>Pie Chart</a>
                        <a (click)="chartchangesd1('line')"><i class="icofont-chart-line"></i>Line Chart</a>
                        <a (click)="chartchangesd1('doughnut')"><i class="icofont-chart-pie-alt"></i>Donut Chart</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="widget-body">
              <div class="widget-details" *ngIf="_Isbarsd1">
                <p-chart *ngIf="!nopurchaseVal" type="bar" [data]="purchaseRepVal"
                  [options]="purchaseRepValOpt"></p-chart>
                <div class="widget-details" *ngIf="nopurchaseVal">
                  <img style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg" alt="" />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Islinesd1">
                <p-chart *ngIf="!nopurchaseVal" type="line" [data]="purchaseRepVal"
                  [options]="purchaseRepValOpt"></p-chart>
                <div class="widget-details" *ngIf="nopurchaseVal">
                  <img style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg" alt="" />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Ispiesd1">
                <p-chart *ngIf="!nopurchaseVal" type="pie" [data]="purchaseRepVal"
                  [options]="purchaseRepValOpt"></p-chart>
                <div class="widget-details" *ngIf="nopurchaseVal">
                  <img style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg" alt="" />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Isdoughnutsd1">
                <p-chart *ngIf="!nopurchaseVal" type="doughnut" [data]="purchaseRepVal"
                  [options]="purchaseRepValOpt"></p-chart>
                <div class="widget-details" *ngIf="nopurchaseVal">
                  <img style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="white">
            <div class="widget-title page-title">
              <div class="row">
                <div class="col-md-8">
                  <h3>Top 5 Product (In No's)</h3>
                </div>
                <div class="col-md-4">
                  <div class="widget-filter text-right">
                    <div class="filter-container">
                      <a href="javascript:void(0);" data-toggle="dropdown" data-toggle-second="tooltip"
                        data-placement="top" title="Choose Chart"><i class="las la-chart-bar"></i></a>
                      <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                        <a (click)="chartchangesd('bar')"><i class="icofont-chart-bar-graph"></i>Bar Chart</a>
                        <a (click)="chartchangesd('pie')"><i class="icofont-pie-chart"></i>Pie Chart</a>
                        <a (click)="chartchangesd('line')"><i class="icofont-chart-line"></i>Line Chart</a>
                        <a (click)="chartchangesd('doughnut')"><i class="icofont-chart-pie-alt"></i>Donut Chart</a>
                      </div>
                    </div>
                    <!-- <div class="filter-container">
                            <a href="javascript:void(0);" data-toggle="dropdown"
                                data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                    class="las la-ellipsis-v"></i></a>
                            <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                <a href="javascript:void(0);"><i class="icofont-file-pdf"></i>Download
                                    as PDF</a>
                                <a href="javascript:void(0);"><i class="icofont-file-excel"></i>Download
                                    as Excel</a>
                            </div>
                        </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="widget-body">
              <div class="widget-details" *ngIf="_Isbarsd">
                <p-chart type="bar" [data]="topPurchaseProduct" [options]="topPurchaseProductOpt"
                  *ngIf="!noTopPurchase"></p-chart>
                <div class="widget-details" *ngIf="noTopPurchase">
                  <img style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg" alt="" />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Islinesd">
                <p-chart type="line" [data]="topPurchaseProduct" [options]="topPurchaseProductOpt"
                  *ngIf="!noTopPurchase"></p-chart>
                <div class="widget-details" *ngIf="noTopPurchase">
                  <img style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg" alt="" />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Ispiesd">
                <p-chart type="pie" [data]="topPurchaseProduct" [options]="topPurchaseProductOpt"
                  *ngIf="!noTopPurchase"></p-chart>
                <div class="widget-details" *ngIf="noTopPurchase">
                  <img style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg" alt="" />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Isdoughnutsd">
                <p-chart type="doughnut" [data]="topPurchaseProduct" [options]="topPurchaseProductOpt"
                  *ngIf="!noTopPurchase"></p-chart>
                <div class="widget-details" *ngIf="noTopPurchase">
                  <img style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="white">
            <div class="widget-title page-title">
              <div class="row">
                <div class="col-md-8">
                  <h3>Top 5 Supplier (In No's)</h3>
                </div>
                <div class="col-md-4">
                  <div class="widget-filter text-right">
                    <div class="filter-container">
                      <a href="javascript:void(0);" data-toggle="dropdown" data-toggle-second="tooltip"
                        data-placement="top" title="Choose Chart"><i class="las la-chart-bar"></i></a>
                      <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                        <a (click)="chartchangesd2('bar')"><i class="icofont-chart-bar-graph"></i>Bar Chart</a>
                        <a (click)="chartchangesd2('pie')"><i class="icofont-pie-chart"></i>Pie Chart</a>
                        <a (click)="chartchangesd2('line')"><i class="icofont-chart-line"></i>Line Chart</a>
                        <a (click)="chartchangesd2('doughnut')"><i class="icofont-chart-pie-alt"></i>Donut Chart</a>
                      </div>
                    </div>
                    <!-- <div class="filter-container">
                          <a href="javascript:void(0);" data-toggle="dropdown"
                              data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                  class="las la-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                              <a href="javascript:void(0);"><i class="icofont-file-pdf"></i>Download
                                  as PDF</a>
                              <a href="javascript:void(0);"><i class="icofont-file-excel"></i>Download
                                  as Excel</a>
                          </div>
                      </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="widget-body">
              <div class="widget-details" *ngIf="_Isbarsd2">
                <p-chart type="bar" [data]="topSupplier" [options]="topSupplierOpt" *ngIf="!noTopSupplier"></p-chart>
                <div class="widget-details" *ngIf="noTopSupplier">
                  <img style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg" alt="" />
                </div>
              </div>
              <div class="widget-details" *ngIf="_Islinesd2">
                <p-chart type="line" [data]="topSupplier" [options]="topSupplierOpt" *ngIf="!noTopSupplier"></p-chart>
                <div class="widget-details" *ngIf="noTopSupplier">
                  <img style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg" alt="" />
                </div>
              </div>

              <div class="widget-details" *ngIf="_Ispiesd2">
                <p-chart type="pie" [data]="topSupplier" [options]="topSupplierOpt" *ngIf="!noTopSupplier"></p-chart>
                <div class="widget-details" *ngIf="noTopSupplier">
                  <img style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg" alt="" />
                </div>
              </div>

              <div class="widget-details" *ngIf="_Isdoughnutsd2">
                <p-chart type="doughnut" [data]="topSupplier" [options]="topSupplierOpt"
                  *ngIf="!noTopSupplier"></p-chart>
                <div class="widget-details" *ngIf="noTopSupplier">
                  <img style="max-height: 344px; margin: auto; display: block"
                    src="../../../../../assets/images/no-data.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>