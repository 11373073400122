import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Productratechange } from 'src/app/core/Models/Productratechange';
import { Producttype } from 'src/app/core/Models/Producttype';
import { ProductpricechangeService } from 'src/app/core/Services/sales/productpricechange.service';
import { vCurrencySelect, vProductCategorySelect, vProductNameSelect, vProductTypeSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Productratechangedetail } from 'src/app/core/Models/Productratechangedetail';
import { KeyPress } from 'src/assets/js/KeyPress';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { ProductCategory } from 'src/app/core/Models/overallclass';
@Component({
	selector: 'app-c-productpricechange',
	templateUrl: './c-productpricechange.component.html',
	styleUrls: ['./c-productpricechange.component.css']
})
export class CProductpricechangeComponent implements OnInit {
	_pricechangeform: FormGroup;
	_pricechangetypeform: FormGroup;
	_ProductTypes: Producttype[];
	_ProductCategories: ProductCategory[];
	_ProductNames: Productoverallview[];
	_TEMPProductNames: Productoverallview[];
	_Currencies: Currency[] = [];
	_action: string = "create";
	selectedrows: any;
	_submitted = false;
	_productpricechange: Productratechange;
	_pricechangedetails: Productratechangedetail[] = [];
	_pricechangedetail: Productratechangedetail[];
	_pricechange: Productratechange[];
	_productratechangeid: number;
	_levelid = sessionStorage["levelid"];
	_branchid = sessionStorage["currentbranchid"]
	_currencyname: string;
	_isDisabledincrease: any;
	_PricechangeOBJ: any;
	_userid = sessionStorage["userid"];
	_IsProgressSpinner: boolean = true;
	isDisabledSave = false;
	isDisabledClear = false;
	_ProductRateChangeID: number;
	isDisabledSendtoapproval = true;
	filteredProducts: any[];
	Product: any[];
	_ProductTypeObj: any;
	_ProductCategoryObj: any;
	_ProductPriceObj: any;
	_brandlist: any;
	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	constructor(
		private utility: CommonUtilities,
		private fb: FormBuilder,
		private _router: Router,
		private _CustomExceptionService: CustomExceptionService,
		private _ProductpricechangeService: ProductpricechangeService,
		private _hotkeysService: HotkeysService,
		public keypress: KeyPress
	) {
		this._productratechangeid = history.state?.productratechangeid ? history.state?.productratechangeid : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this.HotKeyIntegration();
	}
	InitializeForm() {
		this._pricechangeform = this.fb.group({
			productratechangeid: [],
			currencyid: ['', vCurrencySelect],
			accountingyear: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			increased: [],
			decreased: [],
			isincrease: [],
			isdecrease: []
		});
		this._pricechangetypeform = this.fb.group({
			productcategoryid: [],
			producttypeid: [],
			productid: [],
			brandid: [],
		});
		this._pricechangeform.controls['currencyid'].setValue(1);
	}
	ngOnInit(): void {
		this.utility.pageLoadScript();
		this.InitializeForm();
		this._IsProgressSpinner = true;
		this._ProductpricechangeService.PageOnLoad(this._productratechangeid, this._branchid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._ProductTypes = resultJSON.producttypes;
			this._Currencies = resultJSON.currencies;
			this._brandlist = resultJSON.brandList;
			this._IsProgressSpinner = false;
			this._pricechangeform.get("increased").disable();
			this._pricechangeform.get("decreased").disable();
			if (this._action == 'edit' || this._action == 'view') {
				const updateJSON = JSON.parse(JSON.stringify(result));
				console.log("price", updateJSON);
				this._pricechangedetails = updateJSON.productpricechangeview;
				this._pricechangeform.disable();
				this._pricechangetypeform.disable();
				this.isDisabledClear = true;
			}
			if (this._action == 'edit') {
				this.isDisabledSendtoapproval = false;
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		if (this._action == 'view') {
			this.isDisabledClear = true;
			this.isDisabledSave = true;
		}
	}
	OnCurrencychange(event) {
		this._currencyname = (this._Currencies || []).filter(f => f.currencyid == event.value)[0].currencyname;
		for (var i in this._pricechangedetails) {
			this._pricechangedetails[i].oldrate = this._pricechangedetails[i][this._currencyname.toLowerCase()];
			this._pricechangedetails[i].currencyname = this._currencyname;
		}
	}
	disableincrease(event) {
		if (event.checked == true) {
			this._pricechangeform.get("increased").enable();
			this._pricechangeform.get("isdecrease").disable();
			this._pricechangeform.controls.decreased.reset();
		} else {
			this._pricechangeform.get("increased").disable();
			this._pricechangeform.get("isdecrease").enable();
		}
	}
	disabledecrease(event) {
		if (event.checked == true) {
			this._pricechangeform.get("decreased").enable();
			this._pricechangeform.get("isincrease").disable();
			this._pricechangeform.controls.increased.reset();
		} else {
			this._pricechangeform.get("decreased").disable();
			this._pricechangeform.get("isincrease").enable();
		}
	}
	OnIncreasePercentage(event) {
		var Increasepercentage = this._pricechangeform.get("increased").value || 0;
		for (var p = 0; p < this._pricechangedetails.length; p++) {
			var IncreasedAmount = 0
			IncreasedAmount = this._pricechangedetails[p].oldrate * (Increasepercentage / 100);
			this._pricechangedetails[p].newrate = 0;
			if (Increasepercentage > 0)
				this._pricechangedetails[p].newrate = IncreasedAmount + this._pricechangedetails[p].oldrate;
		}
	}
	OnDecreasedPercentage(event) {
		var Decreasepercentage = this._pricechangeform.get("decreased").value;
		for (var p = 0; p < this._pricechangedetails.length; p++) {
			var DecreasedAmount = 0
			DecreasedAmount = Decreasepercentage * this._pricechangedetails[p].oldrate / 100;
			this._pricechangedetails[p].newrate = 0;
			if (Decreasepercentage > 0)
				this._pricechangedetails[p].newrate = this._pricechangedetails[p].oldrate - DecreasedAmount;
		}
	}
	GetProductCategory(event) {
		this._pricechangedetails = []
		this._ProductCategories = [];
		this._ProductNames = [];
		this._pricechangetypeform.get("brandid").reset()
		this._pricechangetypeform.controls["productcategoryid"].reset();
		this._pricechangetypeform.controls["productid"].reset();
		var selectedProductType = event.value;
		if (selectedProductType.length == 0 || !selectedProductType || !selectedProductType.length) {
			this._CustomExceptionService.handleWarning("Please Select Atleast One Product Type")
			return;
		} else {
			this._IsProgressSpinner = true;
			this._ProductTypeObj = {
				"Producttype": selectedProductType || [],
				"branchid": this._branchid || 0
			};
			this._ProductpricechangeService.GetProductCategory(this._ProductTypeObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				let categories = resultJSON.productcategories;
				let uniqueChars = [...new Set(categories)];
				uniqueChars = categories.filter((test, index, array) =>
					index === array.findIndex((findTest) =>
						findTest.productcategoryid === test.productcategoryid
					)
				);
				this._ProductCategories = uniqueChars;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	GetProductName(event) {
		this._pricechangedetails = []
		this._ProductNames = [];
		this._pricechangetypeform.get("brandid").reset()
		this._pricechangetypeform.controls["productid"].reset();
		var selectedProductCategory = event.value;
		var selectedproducttype = this._pricechangetypeform.get("producttypeid").value;
		if (selectedProductCategory.length == 0 || !selectedProductCategory || !selectedProductCategory.length) {
			this._CustomExceptionService.handleWarning("Please Select At Least One Product Category")
			return;
		} else {
			this._IsProgressSpinner = true;
			this._ProductCategoryObj = {
				"Productcategory": selectedProductCategory || [],
				"branchid": this._branchid || 0,
				"selectedproducttype": selectedproducttype || [],
				"levelid": this._levelid || 0,
			};
			this._ProductpricechangeService.GetProductName(this._ProductCategoryObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._pricechangedetails = resultJSON.salesproductprice;
				var Currencyid = this._pricechangeform.get('currencyid').value;
				this._currencyname = (this._Currencies || []).filter(f => f.currencyid == Currencyid)[0].currencyname;
				for (var i in this._pricechangedetails) {
					this._pricechangedetails[i].currencyname = this._currencyname;
				}
				this._ProductNames = resultJSON.products;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	GetProductPrice(event) {
		this._pricechangetypeform.get("brandid").reset()
		this._pricechangedetails = []
		var selectedproduct = this._pricechangetypeform.get("productid").value;
		var brands = null;
		if (selectedproduct.length == 0 || !selectedproduct || !selectedproduct.length) {
			this._CustomExceptionService.handleError("Please Select Atleast One Product")
			return;
		} else {
			this._IsProgressSpinner = true;
			this._ProductPriceObj = {
				"Product": selectedproduct || [],
				"levelid": this._levelid || 0,
				"Brand": brands || []
			}
			this._ProductpricechangeService.GetProductPrice(this._ProductPriceObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._pricechangedetails = resultJSON.salesproductprice;
				var Currencyid = this._pricechangeform.get('currencyid').value;
				this._currencyname = (this._Currencies || []).filter(f => f.currencyid == Currencyid)[0].currencyname;
				for (var i in this._pricechangedetails) {
					this._pricechangedetails[i].currencyname = this._currencyname;
				}
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			this._IsProgressSpinner = true;
		}
		this._IsProgressSpinner = true;
		this._ProductPriceObj = {
			"Products": selectedproduct || [],
			"branchid": parseInt(sessionStorage["currentbranchid"])
		};
		this._ProductpricechangeService.getBrand(this._ProductPriceObj).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			let productbrand = resultJSON.productbrandlist;
			// let uniqueChars = [...new Set(productbrand)];
			// uniqueChars = productbrand.filter((test, index, array) =>
			// 	index === array.findIndex((findTest) =>
			// 		findTest.brandid === test.brandid
			// 	)
			// );
			// this._brandlist = uniqueChars;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	Onchangebrand(event) {
		this._pricechangetypeform.get("productcategoryid").reset()
		this._pricechangetypeform.get("producttypeid").reset()
		this._pricechangetypeform.get("productid").reset()
		this._ProductCategories = [];
		this._ProductNames = [];
		this._pricechangedetails = []
		var selectedbrand = this._pricechangetypeform.get("brandid").value || [];
		if (!selectedbrand || !selectedbrand.length || selectedbrand.length == 0) {
			this._CustomExceptionService.handleError("Please Select Atleast One Brand")
			return
		}
		var products = null;
		this._IsProgressSpinner = true;
		this._ProductPriceObj = {
			"Product": products || [],
			"levelid": this._levelid || 0,
			"Brand": selectedbrand || []
		}
		this._ProductpricechangeService.GetProductPrice(this._ProductPriceObj).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._pricechangedetails = resultJSON.salesproductprice;
			var Currencyid = this._pricechangeform.get('currencyid').value;
			this._currencyname = (this._Currencies || []).filter(f => f.currencyid == Currencyid)[0].currencyname;
			for (var i in this._pricechangedetails) {
				this._pricechangedetails[i].currencyname = this._currencyname;
			}
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		this._IsProgressSpinner = true;
	}
	filterProducts(event) {
		if (event.query == "" || event.query == undefined) {
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductpricechangeService.GetProduct(event.query, this._branchid).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._ProductNames = <Productoverallview[]>resultJSON.productsearchlist || [];
				this._TEMPProductNames = <Productoverallview[]>resultJSON.productsearchlist || [];
				let filtered: any[] = [];
				let query = (<string>event.query).toLowerCase();
				this.filteredProducts = this._ProductNames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	selectedProduct(event) { }
	onSave() {
		this._submitted = true;
		if (this._pricechangeform.valid) {
			if (!this._pricechangedetails || !this._pricechangedetails.length || this._pricechangedetails.length == 0) {
				this._CustomExceptionService.handleWarning("Please Add Atleast one row in the grid")
				return
			}
			this._productpricechange = this._pricechangeform.value;
			this._productpricechange.currencyid = this._pricechangeform.get("currencyid").value;
			this._productpricechange.productratechangeid = 0;
			this._productpricechange.createdby = parseInt(sessionStorage["userid"]);
			this._productpricechange.createdon = new Date();
			this._productpricechange.modifiedby = parseInt(sessionStorage["userid"]);
			this._productpricechange.modifiedon = new Date();
			this._productpricechange.accountingyear = new Date().getFullYear();
			this._pricechangedetails = this._pricechangedetails.filter(f => f.newrate > 0);
			for (var i in this._pricechangedetails) {
				this._pricechangedetails[i].productratechangedetailid = 0;
				this._pricechangedetails[i].productratechangeid = this._productpricechange.productratechangeid;
				this._pricechangedetails[i].currencyid = this._pricechangeform.get("currencyid").value;
			}
			this._PricechangeOBJ = {
				"Productratechange": this._productpricechange,
				"Productratechangedetail": this._pricechangedetails
			};
			if (this._action == 'create') {
				this._IsProgressSpinner = true;
				this._ProductpricechangeService.create(this._PricechangeOBJ).subscribe((result) => {
					const resutJSON = JSON.parse(JSON.stringify(result));
					this._IsProgressSpinner = false;
					if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
						this._productratechangeid = resutJSON.productratechangeid;
						this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
						this.isDisabledSendtoapproval = false;
					} else { this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message); }
				}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
		}
	}
	reset(event) {
		this._submitted = false;
		this._pricechangeform.reset();
		this.InitializeForm();
		this._pricechangedetails = [];
		this._ProductCategories = [];
		this._ProductNames = [];
	}
	goBack(event) {
		this._router.navigate(['/vProductpricechange']);
	}
	HotKeyIntegration() {
		this._hotkeysService.reset();
		if (this._action != 'view') {
			this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
				this.onSave();
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		if (this._action == 'create') {
			this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
				this.reset(null);
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
			this.goBack(null);
			return false; // Prevent bubbling
		}, ['INPUT', 'SELECT', 'TEXTAREA']));
	}
	SendToApproval(event) {
		this._IsProgressSpinner = true;
		this._ProductpricechangeService.SendToApproval(this._userid, this._branchid, this._productratechangeid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._CustomExceptionService.handleSuccess(usererrors.ProductPriceChangeSendToApproval);
			this._IsProgressSpinner = false;
			this.isDisabledSendtoapproval = true;
			if (this._action != 'edit') {
				this.reset(null);
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
}