import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class SmsconfigurationService {
  public _Action: IAction;
  public _SmsConfigurationService = environment.SmsConfigurationService;
  public _SmsLogService = environment.SmsLogService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
   }
   createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(smsemailsettingid: number): Observable<any> {
    const headers = this.header;
    var Query = this._SmsConfigurationService + '/' + smsemailsettingid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetDocumentStatus(documnetid: number, userid: number) {
    const headers = this.header;
    var Query = this._SmsConfigurationService + '/GetDocumentStatus/' + documnetid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll() {
    const headers = this.header;
    var Query = this._SmsConfigurationService + '/FetchAll';

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      return data;
    }));
  }
  CreateStatus(p_Request: object) {
    const headers = this.header;
    var Query = this._SmsConfigurationService + '/CreateStatus';

    return this.http.post<{ data: any }>(Query, p_Request, { headers });
  }
  create(p_State: object) {
    const headers = this.header;
    //console.log("Create");
    var Query = this._SmsConfigurationService;
    return this.http.post<{ data: any }>(Query, p_State, { headers });
  }

  edit(p_State: object) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._SmsConfigurationService;

    return this.http.put<{ data: any }>(Query, p_State, { headers });
  }

  Cancel(stateid: number, userid: number) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._SmsConfigurationService + '/Cancel/' + stateid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });

  }
  // SMS Log 
  SmsLogFetchAll(obj:Object) {
    const headers = this.header;
    var Query = this._SmsLogService + '/smslogfetchall';
    return this.http.post<{ data: any }>(Query,obj, { headers });
  }


  //ECommerece
  getECMailID(group: object) {
    const headers = this.header;
    var Query = this._SmsConfigurationService + '/getECMailID';
    console.log(Query);
    return this.http.post<{ data: any }>(Query, group, { headers });
  }
  SendNewsletter(Newsletter: object) {
    const headers = this.header;
    var Query = this._SmsConfigurationService + '/SendNewsletter';
    console.log(Query);
    return this.http.post<{ data: any }>(Query, Newsletter, { headers });
  }
}
