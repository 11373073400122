import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Currency } from 'src/app/core/Models/Currency';
import { CurrencyService } from 'src/app/core/Services/masters/currency.service';
import { Currencyview } from 'src/app/core/Views/Currencyview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ConfirmationService } from 'primeng/api';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-v-currency',
  templateUrl: './v-currency.component.html',
  styleUrls: ['./v-currency.component.css']
})
export class VCurrencyComponent implements OnInit {
  _currenciesview: Currencyview[] = [];
  _currencyview: Currencyview;
  _Currency: Currency;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  updatecurrencysp: boolean = true;
  _currencyid: number;
  _userid = sessionStorage["userid"];
  datepipe = new DatePipe('en-US');
  constructor(
    private fb: FormBuilder,
    private _CurrencyService: CurrencyService,
    private router: Router,
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private confirmationService: ConfirmationService,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(13);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    if (this._userid == 1) {
      this.updatecurrencysp = false
    }
    this._IsProgressSpinner = true;
    this._CurrencyService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._currenciesview = resultJSON.currencyview;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) { }
  onRowUnselect(event) { }
  view(event) {
    this.router.navigate(['/cCurrency'], { state: { currencyid: event.currencyid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cCurrency']);
  }
  delete(event) {
    alert("delete");
  }
  edit(event) {
    this.router.navigate(['/cCurrency'], { state: { currencyid: event.currencyid, action: 'edit' } });
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  confirm(event) {
    this._currencyid = event.currencyid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Currency <b>"' + event.currencyname + '"</b> ?',
      accept: () => {
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._CurrencyService.Cancel(this._currencyid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_05);
        this._CurrencyService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._currenciesview = resultJSON.currencyview;
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'Currency', 'xlsx');
  }
  ExportToPdf() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.ExportToPdf(AssetRegistrationList, 'Currency.pdf');
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._currenciesview.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Currency Code"] = element.currencycode;
      assetregistration["Currency Name"] = element.currencyname;
      assetregistration["Exchange Rate"] = element.exchangerate;
      assetregistration["Effective From"] = this.datepipe.transform(element.effectivefrom, 'dd/MM/yyyy');
      assetregistration["Effective To"] = this.datepipe.transform(element.effectiveto, 'dd/MM/yyyy');
      assetregistration["Status"] = element.currencystatusname;
      assetregistration["Created By"] = element.createdbyname;
      assetregistration["Created On"] = element.createdon;
      assetregistration["Modified By"] = element.modifiedbyname;
      assetregistration["Modified On"] = element.modifiedon; 
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }

  Currencyupadte() {
    this._IsProgressSpinner = true;
    this._CurrencyService.UpdateCurrency({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
        this._CurrencyService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}

