<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
          </div>
        <div class="white">
            <form [formGroup]="_rolewisescreenmappingform" (ngSubmit)="onSave()" >
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Role-Screen Configuration</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple title="Save" type="submit" icon="pi pi-save"></button>
                                  <!-- All input fields should be cleared. -->
                                <button pButton pRipple title="Clear" type="button" icon="pi pi-trash" [disabled]="!_Action._Delete"
                                    class="p-button-danger" (click)="reset($event)"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_roles" name="roleid"
                                    [filter]="true"  optionLabel="rolename" optionValue="roleid" formControlName="roleid" (onChange)="GetDefualtModules($event)">
                                    </p-dropdown>
                                    <label for="roleid"> Role <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- if role Is not given, show error as "please select role" -->
                                <span class="text-danger"
                                *ngIf="(_submitted || _rolewisescreenmappingform.get('roleid').touched) && _rolewisescreenmappingform.get('roleid').errors?.SelectRoleName">
                                    Please select role
                                </span>
                               
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_modules" name="functionid" optionLabel="functionname" [(ngModel)] = "_selectedModuleArrayIndex"
                                    [filter]="true"   optionValue="functionid" formControlName="functionid" (onChange)="getScreenList($event)"></p-multiSelect>
                                    <label for="functionid">Modules <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- if module is not given , show error as "Please select Module" -->
                                <span class="text-danger"
                                *ngIf="(_submitted || _rolewisescreenmappingform.get('functionid').touched) && _rolewisescreenmappingform.get('functionid').errors?.SelectModuleName">
                                    Please select module
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>

                    </table>
                    <div class="sub-title">
                        <div class="row">
                            <div class="col-md-5">
                                <h5>Role Wise Screen Mapping</h5>
                            </div>
                            <div class="col-md-7"></div>
                        </div>
                    </div>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                              <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                  placeholder="Search..." />
                              </span>
                            </ng-template>
                          </p-toolbar>
                        <p-table #dt [value]="_Screens" [scrollable]="true" scrollHeight="450px" [rows]="50" [paginator]="true" 
                        [rowsPerPageOptions]="[50,100,150,200]"   
                        [globalFilterFields]="['sno','modulename','screenname']"
                        [rowHover]="true"  
                        dataKey="screenid" 
                        [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" 
                        (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="text-center" style="width: 80px;" pSortableColumn="sno">S. No <p-sortIcon field="sno"></p-sortIcon>
                                    </th>
                                    <th style="text-align: center;width: 80px;">
                                    <p-tableHeaderCheckbox  (click)="SelectAll($event)" ></p-tableHeaderCheckbox>
                                    </th>
                                    <th pSortableColumn="modulename">Module <p-sortIcon field="modulename"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="screenname">Screen Name<p-sortIcon field="screenname">
                                        </p-sortIcon></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_Screen let-i="rowIndex">
                                <tr>
                                    <td class="text-center" style="width: 80px;">
                                        {{i+ 1}}
                                    </td>
                                    <td style="text-align: center;width: 80px;">
                                        <p-tableCheckbox  [value]="_Screen"></p-tableCheckbox>
                                    </td>
                                    <td>
                                        {{_Screen.modulename}}
                                    </td> 
                                    <td>
                                        {{_Screen.screenname}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>