<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Sales Return </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" type="button" [disabled]="isDisabledsave" (click)="onSave()"></button>
                            <button pButton pRipple label="" title="Clear" icon="pi pi-trash" [disabled]="isDisabledreset" (click)="reset($event)" class="p-button-danger"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search" (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <form [formGroup]="_salesreturnform" (ngSubmit)="onSave()">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="salesreturndate"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="1980:2030" [disabled]="isDisablededit">
                                    </p-calendar>
                                    <label for="salesreturndate">Sales Return Date <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_salesreturnform.get('salesreturndate').touched || _submitted) && _salesreturnform.get('salesreturndate').errors?.SelectDate">
                                    Please Select Return Date
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_salesreturnform" (ngSubmit)="onSave()">
                                <span class="p-float-label">
                                    <p-autoComplete [suggestions]="_Customers" field="customername"
                                        (completeMethod)="getcustomer($event)" (onSelect)="onChangeCustomer()"
                                        formControlName="customerid">
                                        <ng-template let-customer pTemplate="item">
                                            <div>{{customer.customername}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <label for="salesinvoiceid">Customer <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger" *ngIf="(_salesreturnform.get('customerid').touched || _submitted) && _salesreturnform.get('customerid').errors?.CustomerNameRequired">
                                    Please select cutomers
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-autoComplete [(ngModel)]="salesinvoiceobj" [suggestions]="filteredInvoices"
                                    [disabled]="_action == 'edit' || _action == 'view'"
                                    (completeMethod)="filterInvoices($event)" field="salesinvoiceno"
                                    (onSelect)="OnInvoiceChange($event)">
                                    <ng-template let-invoice pTemplate="item">
                                        <div>{{invoice.salesinvoiceno}}</div>
                                    </ng-template>
                                </p-autoComplete>
                                <label for="salesinvoiceid">Invoice No <span class="hlt-txt">*</span> </label>
                            </span>
                        </td>
                        <td style="width: 20%;" rowspan="2">
                            <form [formGroup]="_salesreturnform">
                                <span class="p-float-label">
                                    <textarea rows="3" cols="30" pInputTextarea name="narration"
                                        formControlName="narration"></textarea>
                                    <label for="narration">Narration</label>
                                </span>
                            </form>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 20%;">
                            <form [formGroup]="_salesreturnform" (ngSubmit)="onSave()">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="invoicebranchname" disabled>
                                    <label for="invoicebranchname">Invoice Branch </label>
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_salesreturnform" (ngSubmit)="onSave()">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="currencyname" disabled>
                                    <label for="currencyname">Currency </label>
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_salesreturnform" (ngSubmit)="onSave()">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="salesinvoiceamount" disabled>
                                    <label for="salesinvoiceamount">Sales Invoice Amount </label>
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_ProductDetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['productname','variantdescription']" [rowHover]="true" dataKey="productid" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Delivery Branch</th>
                                <th>Product Name</th>
                                <th>Variant</th>
                                <th>Lot Number</th>
                                <th style="width:100px">Invoice Qty</th>
                                <th style="width:100px">Return Qty</th>
                                <th style="width:100px">UOM</th>
                                <th style="width:200px">Return Reason</th>
                                <th>Return Product Amt.</th>
                                <th>Discount Amount</th>
                                <th>Tax Amount</th>
                                <th>Return Final Amt.</th>
                                <th style="width:200px">Remarks</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ProductDetail>
                            <tr>
                                <td>
                                    {{_ProductDetail.deliverybranchname}}
                                </td>
                                <td>
                                    {{_ProductDetail.productname}}
                                </td>
                                <td>
                                    {{_ProductDetail.variantdescription}}
                                </td>
                                <td>
                                    {{_ProductDetail.lotnumber}}
                                </td>
                                <td>
                                    {{_ProductDetail.invoicequantity}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber type="number" [(ngModel)]="_ProductDetail.returnquantity" [disabled]="isDisabledreturnqty" pInputText *ngIf="_ProductDetail.salesuom != 'MSC00039' &&  _action != 'view'" (keydown.enter)="allocatequantity(_ProductDetail,$event)" (keydown.Tab)="allocatequantity(_ProductDetail,$event)"
                                                (keypress)="keypress.kpNumber($event)" (keyup)="allocatequantity(_ProductDetail,$event)" (onBlur)="allocatequantity(_ProductDetail,$event)" (onBlur)="allocatequantity(_ProductDetail,$event)" (focusout)="allocatequantity(_ProductDetail,$event)"
                                                maxlength="14" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                            </p-inputNumber>
                                            <p-inputNumber type="number" [(ngModel)]="_ProductDetail.returnquantity" [disabled]="isDisabledreturnqty" pInputText *ngIf="_ProductDetail.salesuom == 'MSC00039' &&  _action != 'view'" (keydown.enter)="allocatequantity(_ProductDetail,$event)" (keydown.Tab)="allocatequantity(_ProductDetail,$event)"
                                                (keypress)="keypress.kpNumber($event)" (keyup)="allocatequantity(_ProductDetail,$event)" (onBlur)="allocatequantity(_ProductDetail,$event)" (onBlur)="allocatequantity(_ProductDetail,$event)" (focusout)="allocatequantity(_ProductDetail,$event)"
                                                maxlength="14" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                            </p-inputNumber>
                                            <div *ngIf="_action == 'view'">{{_ProductDetail.returnquantity}}
                                            </div>
                                        </ng-template>

                                        <ng-template pTemplate="output">
                                            {{_ProductDetail.returnquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_ProductDetail.uomname}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_Reasons" [disabled]="isDisableReason" optionLabel="metasubdescription" optionValue="metasubcode" [(ngModel)]="_ProductDetail.returnreason">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Reasons" optionLabel="metasubdescription" optionValue="metasubcode" [(ngModel)]="_ProductDetail.returnreason">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_ProductDetail.productamount | number: '1.2-4' }}
                                </td>
                                <td>
                                    {{_ProductDetail.discountamount | number: '1.2-4' }}
                                </td>
                                <td>
                                    {{_ProductDetail.taxamount | number: '1.2-4' }}
                                </td>
                                <td>
                                    {{_ProductDetail.totalamount | number: '1.2-4' }}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText name="remarks" [(ngModel)]="_ProductDetail.remarks" maxlength="50" *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">
                                                {{_ProductDetail.remarks}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_ProductDetail.remarks}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <form [formGroup]="_salesreturnform">
                            <div class="sub-title">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Payment Details</h5>
                                    </div>
                                    <div class="col-md-7"></div>
                                </div>
                            </div>
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="salesreturngrossamount"
                                                formControlName="salesreturngrossamount" disabled pInputText>
                                            <label for="salesreturngrossamount">Gross Amount</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="taxamount" formControlName="taxamount" disabled
                                                pInputText>
                                            <label for="taxamount">Tax Amount</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="salesreturndeductionamount"
                                                formControlName="salesreturndeductionamount" disabled pInputText>
                                            <label for="salesreturndeductionamount">Other Deduction Amount</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="salesreturnamount"
                                                formControlName="salesreturnamount" disabled pInputText>
                                            <label for="salesreturnamount">Net Amount</label>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                    <div class="col-md-5" *ngIf="!issalesreturndisable">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Other Charges</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <div class="card">
                            <p-table [value]="salesreturnothercharges" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['otherchargeid','otherchargeamount']" [rowHover]="true" dataKey="otherchargeid" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedOtherChargesRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:100px;text-align: center;">Action</th>
                                        <th>Description</th>
                                        <th>Value</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_othercharge>
                                    <tr>
                                        <td style="text-align:center">
                                            <button pButton pRipple title="Add" icon="pi pi-plus" [disabled]="isDisabledadd" class="p-button-success p-mr-2" (click)="AddOtherCharges($event)"></button>
                                            <button pButton pRipple title="Remove" icon="pi pi-trash" [disabled]="isDisabledremove" class="p-button-danger" (click)="RemoveOtherCharges(_othercharge)"></button>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown #id [autoDisplayFirst]="false" [options]="_Descriptions" [disabled]="isDisabledothercharges" *ngIf="_action != 'view'" optionLabel="otherchargename" optionValue="otherchargeid" [(ngModel)]="_othercharge.otherchargeid" (onChange)="OnchangeOtherCharge(_othercharge,$event)">
                                                    </p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <p-dropdown [disabled]="true" [autoDisplayFirst]="false" [options]="_Descriptions" optionLabel="otherchargename" optionValue="otherchargeid" [(ngModel)]="_othercharge.otherchargeid">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" pInputText name="otherchargeamount" (keyup)="OnChangeotherCharge(_othercharge,$event)" [(ngModel)]="_othercharge.otherchargeamount" maxlength="7" (keypress)="keypress.kpNumber($event)" *ngIf="_action != 'view'">
                                                    <div *ngIf="_action == 'view'">
                                                        {{_othercharge.otherchargeamount}}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_othercharge.otherchargeamount}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>