<div class="page-container dashboard-page scroll-y">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_purchasereturndashboardform">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar [showIcon]="true" name="fromdate" [monthNavigator]="true"
                                    [yearNavigator]="true" yearRange="2000:2030" formControlName="fromdate"
                                    (onSelect)="SuppliermonthwisePurchaseReturn($event)" dateFormat="dd/mm/yy"
                                    (onSelect)="MonthwisePurchaseReturn($event)" >
                                </p-calendar>
                                <label for="quoteDate">From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar [showIcon]="true" name="todate" [monthNavigator]="true"
                                    [yearNavigator]="true" yearRange="2000:2030" formControlName="todate"
                                    (onSelect)="SuppliermonthwisePurchaseReturn($event)" dateFormat="dd/mm/yy"
                                    (onSelect)="MonthwisePurchaseReturn($event)"  >
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                        </td>
                        <td style="width: 20%;">
                        </td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
            </form>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="white">
                    <div class="widget-title page-title">
                        <div class="row">
                            <div class="col-md-8">
                                <h3>Purchase Return Dashboard</h3>
                            </div>
                            <div class="col-md-4">
                                <div class="widget-filter text-right">
                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="Filter By"><i
                                                class="las la-filter"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup big collapse">
                                            <form [formGroup]="_purchasereturndashboardform">
                                                <table class="normal-table">
                                                    <tr>
                                                        <td>
                                                            <span class="p-float-label">
                                                                <p-autoComplete formControlName="branchid"
                                                                    [suggestions]="filterBranchs"
                                                                    (completeMethod)="filterBranch($event)"
                                                                    (onSelect)="MonthwisePurchaseReturn($event)"
                                                                    field="branchname"
                                                                    (onSelect)="OnChangeBranch($event)">
                                                                    <ng-template let-branch pTemplate="item">
                                                                        <div>{{branch.branchname}}</div>
                                                                    </ng-template>
                                                                </p-autoComplete>
                                                                <label for="branchid">Branch</label>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <!-- <tr>
                                                        <td>
                                                            <span class="p-float-label">
                                                                <p-autoComplete formControlName="supplierid"
                                                                    [suggestions]="filterSuppliers"
                                                                    (onSelect)="onChangefilterSupplierPurchaseReturn()"
                                                                    (completeMethod)="filterSupplier($event)"
                                                                    field="suppliername">
                                                                    <ng-template let-supplier pTemplate="item">
                                                                        <div>{{supplier.suppliername}}</div>
                                                                    </ng-template>
                                                                </p-autoComplete>
                                                                <label for="supplierid">Supplier</label>
                                                            </span>
                                                        </td>
                                                    </tr> -->
                                                </table>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="Choose Chart"><i
                                                class="las la-chart-bar"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                            <a (click)="chartchangesd('bar')"><i class="icofont-chart-bar-graph"></i>Bar
                                                Chart</a>
                                            <a (click)="chartchangesd('pie')"><i class="icofont-pie-chart"></i>Pie
                                                Chart</a>
                                            <a (click)="chartchangesd('line')"><i class="icofont-chart-line"></i>Line
                                                Chart</a>
                                            <a (click)="chartchangesd('doughnut')"><i
                                                    class="icofont-chart-pie-alt"></i>Donut Chart</a>
                                        </div>
                                    </div>
                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                                class="las la-ellipsis-v"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                            <a href="javascript:void(0);"><i class="icofont-file-pdf"></i>Download
                                                as PDF</a>
                                            <a href="javascript:void(0);"><i class="icofont-file-excel"></i>Download
                                                as Excel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="widget-body">
                        <div class="widget-details" *ngIf="_Isbarsd">
                            <p-chart type="bar" [data]="basicData1" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Islinesd">
                            <p-chart type="line" [data]="basicData1" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Ispiesd">
                            <p-chart type="pie" [data]="basicData1" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Isdoughnutsd">
                            <p-chart type="doughnut" [data]="basicData1" [options]="basicOptions"></p-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="white">
                    <div class="widget-title page-title">
                        <div class="row">
                            <div class="col-md-8">
                                <h3>Supplier Wise Purchase Return Dashboard</h3>
                            </div>
                            <div class="col-md-4">
                                <div class="widget-filter text-right">
                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="Filter By"><i
                                                class="las la-filter"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup big collapse">
                                            <form [formGroup]="_supplierwisedashboardform">
                                                <table class="normal-table">
                                                    <tr>
                                                        <td>
                                                            <span class="p-float-label">
                                                                <p-autoComplete formControlName="branchid"
                                                                    [suggestions]="filterBranchs"
                                                                    (completeMethod)="filterBranch($event)"
                                                                    (onSelect)="SuppliermonthwisePurchaseReturn($event)"
                                                                    field="branchname">
                                                                    <ng-template let-branch pTemplate="item">
                                                                        <div>{{branch.branchname}}</div>
                                                                    </ng-template>
                                                                </p-autoComplete>
                                                                <label for="branchid">Branch</label>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span class="p-float-label">
                                                                <p-autoComplete formControlName="supplierid"
                                                                    [suggestions]="filterSuppliers"
                                                                    (onSelect)="SuppliermonthwisePurchaseReturn($event)"
                                                                    (completeMethod)="filterSupplier($event)"
                                                                    field="suppliername">
                                                                    <ng-template let-supplier pTemplate="item">
                                                                        <div>{{supplier.suppliername}}</div>
                                                                    </ng-template>
                                                                </p-autoComplete>
                                                                <label for="supplierid">Supplier</label>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="Choose Chart"><i
                                                class="las la-chart-bar"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                            <a (click)="chartchangesd1('bar')"><i class="icofont-chart-bar-graph"></i>Bar
                                                Chart</a>
                                            <a (click)="chartchangesd1('pie')"><i class="icofont-pie-chart"></i>Pie
                                                Chart</a>
                                            <a (click)="chartchangesd1('line')"><i class="icofont-chart-line"></i>Line
                                                Chart</a>
                                            <a (click)="chartchangesd1('doughnut')"><i
                                                    class="icofont-chart-pie-alt"></i>Donut Chart</a>
                                        </div>
                                    </div>
                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                                class="las la-ellipsis-v"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                            <a href="javascript:void(0);"><i class="icofont-file-pdf"></i>Download
                                                as PDF</a>
                                            <a href="javascript:void(0);"><i class="icofont-file-excel"></i>Download
                                                as Excel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="widget-body">
                        <div class="widget-details" *ngIf="_Isbarsd1">
                            <p-chart type="bar" [data]="basicData2" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Islinesd1">
                            <p-chart type="line" [data]="basicData2" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Ispiesd1">
                            <p-chart type="pie" [data]="basicData2" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Isdoughnutsd1">
                            <p-chart type="doughnut" [data]="basicData2" [options]="basicOptions"></p-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>