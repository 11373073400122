import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import * as ace from "ace-builds";
import { Containertracking } from 'src/app/core/Models/Containertracking';
import { ContainertrackingService } from 'src/app/core/Services/tracking/containertracking.service';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Port } from 'src/app/core/Models/Port';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { vContainerNo, vDateSelect, vSelectDocumentName, vShippingLine, vSupplierName, vSupplierNameSelect } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUpload } from 'primeng/fileupload';
import { Containertrackingdocumentdetail } from 'src/app/core/Models/Containertrackingdocumentdetail';
import { Purchaseorder } from 'src/app/core/Models/Purchaseorder';
import { Purchaseorderview } from 'src/app/core/Views/Purchaseorderview';
import { Containertrackingreference } from 'src/app/core/Models/Containertrackingreference';
import { Supplierview } from 'src/app/core/Views/Supplierview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';


@Component({
  selector: 'app-c-containertracking',
  templateUrl: './c-containertracking.component.html',
  styleUrls: ['./c-containertracking.component.css']
})
export class CContainertrackingComponent implements OnInit {

  // Form Group Declaration:
  _containertrackingform: FormGroup;
  _ContainerDocumentDetailform: FormGroup;
  //List Declaration:
  _containertracking: Containertracking;
  _containertrackingdDetail: Containertrackingdocumentdetail;
  _supplierlist: Supplierview[];
  _shippingLineList: Metadatum[];
  _portList: Port[];
  _portAgentList: Supplierview[];
  _portAgentDetailList: Supplierview[] = [];
  _borderAgentList: Supplierview[];
  _localTransporterList: Supplierview[];
  _portJVlist: Accountledgerview[] = [];
  _borderPaymentlist: Accountledgerview[] = [];
  _localtransporterJVlist: Accountledgerview[] = [];
  _PurchaseOrders: Purchaseorderview[] = [];
  _selectedpurchaseorderno: any[] = [];
  _referencedetails: any[] = [];
  //Local Variable Declaration:
  _action: string = "create";
  _submitted = false;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"];
  _containertrackingid: number;
  isDisabledSave = false
  isDisabledClear = false
  _containertrackingObj: any;
  _IsProgressSpinner: boolean = true;
  selectedtab: number = 0;
  _Expenseaccounts
  _Proofs: Containertrackingdocumentdetail[] = [];
  _Proof: Containertrackingdocumentdetail
  selectedrows
  // For File Upload:
  _LastAddedDocument: File;
  _selectedDocuments: File[] = [];
  position1: string;
  position: string;
  displaydocumentpopup: boolean;
  displayBasic: boolean;
  displayBasic1: boolean;
  documentimage: SafeUrl;
  displaydocumentgridpopup: boolean = false;
  _containerFORM: FormData;
  _Status: Metadatum;
  documentsubmitted = false;
  _DocumentNames: any[];
  _Action: IAction

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _ContainertrackingService: ContainertrackingService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService,
    private _DomSanitizationService: DomSanitizer, private _AccessRightsService: AccessRightsService
  ) {
    this._containertrackingid = history.state?.containertrackingid ? history.state?.containertrackingid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._containertrackingform = this.fb.group({
      companyid: [],
      branchid: [],
      containertrackingid: [0],
      containertrackingno: [],
      containertrackingdate: [],
      containerno: ['', vContainerNo],
      blno: [],
      supplierid: [],
      shippingdate: [],
      shippingline: ['', vShippingLine],
      portid: [],
      portagent: [],
      portremarks: [],
      borderagent: [],
      borderdate: [],
      borderremarks: [],
      localtransporter: [],
      transporterno: [],
      transporterdate: [],
      transporterremarks: [],
      status: [],
      statusremarks: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      portbldate: [],
      arraivaldate: [],
      portremarks1: [],
      borderremarks1: [],
      transporterremarks1: [],
      pono: [],
      containerstatus: [],
      //importstatus:[]
      containertrackingdocumentdetails: [],
      containertrackingreferences: []
    });
    this._ContainerDocumentDetailform = this.fb.group({
      containertrackingdocumentdetailid: [],
      containertrackingid: [],
      documentid: ['', vSelectDocumentName],
      documentname: [],
      documentdescription: [],
      attachmenturl: [],
      attachmentname: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      agentdocid: ['', vSupplierNameSelect],
    });
    this._containertrackingform.controls['containertrackingdate'].setValue(new Date());
    this._containertrackingform.controls['status'].setValue("MSC00001");

  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(44);
    //To Avoid BlankScreen On Pageload Below Line Used:
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;
    this._ContainertrackingService.PageOnload(this._containertrackingid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._supplierlist = resultJSON.supplierList;
      this._shippingLineList = resultJSON.shippingLineList;
      this._portList = resultJSON.portList;
      this._portAgentList = resultJSON.portAgentList;
      this._borderAgentList = resultJSON.borderAgentList;
      this._localTransporterList = resultJSON.localTransporterList;
      this._Status = resultJSON.statusList;
      this._DocumentNames = resultJSON.documentdescriptionList
      this._IsProgressSpinner = false;
      this._containertrackingform.controls['containertrackingdate'].setValue(new Date());

      //For View:
      // For Update And View:
      if (this._action == 'edit' || this._action == 'view') {
        this._containertrackingform.get("containerno").disable();
        const updateJSON = JSON.parse(JSON.stringify(result));
        this.isDisabledClear = true;
        this._Status = updateJSON.statusList;
        this._borderPaymentlist = (updateJSON.ledgerlist || []).filter(f => f.partycode == updateJSON.containertrackings[0].borderagent);

        if (updateJSON.containertrackings[0].containertrackingdate == null) {

        }
        else {
          updateJSON.containertrackings[0].containertrackingdate = new Date(updateJSON.containertrackings[0].containertrackingdate);
        }

        if (updateJSON.containertrackings[0].shippingdate == null) {

        }
        else {
          updateJSON.containertrackings[0].shippingdate = new Date(updateJSON.containertrackings[0].shippingdate);
        }
        if (updateJSON.containertrackings[0].transporterdate == null) {

        }
        else {
          updateJSON.containertrackings[0].transporterdate = new Date(updateJSON.containertrackings[0].transporterdate);
        }
        if (updateJSON.containertrackings[0].portbldate == null) {

        }
        else {
          updateJSON.containertrackings[0].portbldate = new Date(updateJSON.containertrackings[0].portbldate);
        }
        if (updateJSON.containertrackings[0].borderdate == null) {
        }
        else {
          updateJSON.containertrackings[0].borderdate = new Date(updateJSON.containertrackings[0].borderdate);
        }
        if (updateJSON.containertrackings[0].arraivaldate == null) {
        }
        else {
          updateJSON.containertrackings[0].arraivaldate = new Date(updateJSON.containertrackings[0].arraivaldate);
        }

        updateJSON.containertrackings[0].createdon = new Date(updateJSON.containertrackings[0].createdon);
        updateJSON.containertrackings[0].modifiedon = new Date(updateJSON.containertrackings[0].modifiedon);
        this._containertrackingform.setValue(updateJSON.containertrackings[0]);
        this._Proofs = updateJSON.containertrackingdocumentdetails
        this._PurchaseOrders = resultJSON.purchaseorders;
        var purchaseordernos: any[] = [];
        Object.keys(resultJSON.purchaseordernos).map((Index) => {
          purchaseordernos.push(resultJSON.purchaseordernos[Index].purchaseorderno);
        });
        this._selectedpurchaseorderno = purchaseordernos;
        this.OnAgentchange();
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    if (this._action == 'view') {
      this._containertrackingform.disable();
      this._ContainerDocumentDetailform.disable();
      this.isDisabledSave = true;
      this.isDisabledClear = true;
    }
  }

  OnChangeSupplier(event) {
    var supplierid = event.value;
    this._IsProgressSpinner = true;
    this._ContainertrackingService.OnChangeSupplier(supplierid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._PurchaseOrders = resultJSON.purchaseorders;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  // On Port Change:
  OnPortAgentChange(event) {
    var portid = event.value;
    this._IsProgressSpinner = true;
    this._ContainertrackingService.OnPortAgentChange(portid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._portJVlist = resultJSON.portJVlist;
      this.OnAgentchange();
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  // On Border Change:
  OnBordernameChange(event) {
    var portid = event.value;
    this._IsProgressSpinner = true;
    this._ContainertrackingService.OnBordernameChange(portid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._borderPaymentlist = resultJSON.borderPaymentlist;
      this.OnAgentchange();
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  // On Local Transport Change:
  OnLocalTranpoerterChange(event) {
    var portid = event.value;
    this._IsProgressSpinner = true;
    this._ContainertrackingService.OnLocalTranpoerterChange(portid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._localtransporterJVlist = resultJSON.localtransporterJVlist;
      this.OnAgentchange();
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  // Document Upload
  showdocumentDialog(document: FileUpload, position: string) {
    if (this._LastAddedDocument) {
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedDocument));
    }
    if (this.documentimage == null) {
      return;
    }
    this.position = position;
    this.displaydocumentpopup = true;
  }


  closePopupGridDialog(position: string) {
    this.position = position;
    this.displaydocumentgridpopup = false;
  }

  onAddProof(event) {
    if (!this._LastAddedDocument) {
      this._CustomExceptionService.handleWarning("Please Select Documet");
      return
    }
    this.documentsubmitted = true;
    if (this._ContainerDocumentDetailform.valid) {
      var proofdetail = new Containertrackingdocumentdetail();
      proofdetail.attachmentname = this._LastAddedDocument.name;
      proofdetail.attachmenturl = URL.createObjectURL(this._LastAddedDocument);
      proofdetail.containertrackingid = this._containertrackingform.get("containertrackingid").value;
      proofdetail.attachmentname = this._LastAddedDocument.name;
      proofdetail.documentid = this._ContainerDocumentDetailform.get("documentid").value;
      proofdetail.documentname = (this._DocumentNames || []).filter(f => f.documentdescriptionid == proofdetail.documentid)[0].documentdescriptionname;
      proofdetail.documentdescription = this._ContainerDocumentDetailform.get("documentdescription").value;
      proofdetail.containertrackingdocumentdetailid = 0;
      proofdetail.agentid = this._ContainerDocumentDetailform.get("agentdocid").value || 0;
      console.log("Agent Id", proofdetail.agentid);
      console.log("Supllier List", this._portAgentDetailList);
      var productName = (this._portAgentDetailList || []).filter(f => f.supplierid == proofdetail.agentid)[0].suppliername;
      proofdetail.agentname = productName;
      proofdetail.createdby = sessionStorage["userid"];
      proofdetail.modifiedby = sessionStorage["userid"];
      proofdetail.modifiedon = new Date();
      proofdetail.createdon = new Date();
      this._Proofs.push(proofdetail);
      this._selectedDocuments.push(this._LastAddedDocument);
      this._ContainerDocumentDetailform.reset();
      this.onDocumentClear()
      this.documentsubmitted = false;
    }
  }
  onRemoveProof(event) {
    this._ContainerDocumentDetailform.reset();
    this.onDocumentClear()
  }

  onDocumentClear() {
    this.documentimage = null;
    this._LastAddedDocument = null;
  }
  onDocumentSelect(event, document) {
    this._LastAddedDocument = event.currentFiles[0];
    document.clear();
  }

  RemoveProofDetail(p_proofDetail) {
    var index = this._Proofs.indexOf(p_proofDetail);
    this._Proofs.splice(index, 1)
  }
  onRowSelect(event) {

  }
  onRowUnselect(event) {

  }

  onSelectreferenceno(event) {
    var refno = event.value;
    var amount = 0;
    var l_Assitantces: Containertrackingreference[] = [];
    var l_Assitantce: Containertrackingreference;
    for (var i = 0; i < refno.length; i++) {
      var val = refno[i];

      l_Assitantce = new Containertrackingreference();
      l_Assitantce.purchaseorderno = refno[i];

      l_Assitantce.supplierid = parseInt((this._PurchaseOrders || []).find(f => f.purchaseorderno == refno[i]).supplierid);
      l_Assitantces.push(l_Assitantce);
    }

    this._referencedetails = l_Assitantces;
  }

  //Form Data Creation:
  formconverstion(p_containertracking: Containertracking, p_containertrackingDetail: Containertrackingdocumentdetail[], documents: File[], referencenos: Containertrackingreference[]) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    for (var x in documents) {
      var index = p_containertrackingDetail.findIndex(obj => obj.attachmentname == documents[x].name);
      if (index != -1) {
        var NewFileName = time.getTime() + '_' + documents[x].name;
        p_containertrackingDetail[index].attachmentname = NewFileName;
        p_containertrackingDetail[index].attachmenturl = NewFileName;
        formData.append('files', documents[x], NewFileName);
      }
    }
    this._containertrackingObj = {
      "Containertracking": p_containertracking,
      "Containertrackingdocumentdetail": p_containertrackingDetail,
      "Containertrackingreferencedetail": referencenos,
    };
    var Container = JSON.stringify(this._containertrackingObj);
    formData.append('obj', Container);
    return (formData);
  }
  //Save Event:
  onSave() {
    this._submitted = true;
    if (this._containertrackingform.valid) {
      var ContainertrackingDate, ShippingDate, BlDate, BorderDate, TransporterDate, ArraivalDate;
      ContainertrackingDate = this._containertrackingform.get("containertrackingdate").value;
      ShippingDate = this._containertrackingform.get("shippingdate").value;
      BlDate = this._containertrackingform.get("portbldate").value;
      BorderDate = this._containertrackingform.get("borderdate").value;
      ArraivalDate = this._containertrackingform.get("arraivaldate").value;
      TransporterDate = this._containertrackingform.get("transporterdate").value;
      var datePipe = new DatePipe("en-US");

      if (ContainertrackingDate == null) {
        // this._schemeform.get("effectiveto").setValue(null);
        ContainertrackingDate = this._containertrackingform.get("containertrackingdate").setValue(null);
      }
      else {
        ContainertrackingDate = datePipe.transform(ContainertrackingDate, 'yyyy-MM-dd');
      }
      if (ShippingDate == null) {
        ShippingDate = this._containertrackingform.get("shippingdate").setValue(null);
      }
      else {
        ShippingDate = datePipe.transform(ShippingDate, 'yyyy-MM-dd');
      }
      if (ArraivalDate == null) {
        ArraivalDate = this._containertrackingform.get("arraivaldate").setValue(null);
      }
      else {
        ArraivalDate = datePipe.transform(ArraivalDate, 'yyyy-MM-dd');
      }
      if (BlDate == null) {
        BlDate = this._containertrackingform.get("portbldate").setValue(null);
      }
      else {
        BlDate = datePipe.transform(BlDate, 'yyyy-MM-dd');
      }
      if (BorderDate == null) {
        BorderDate = this._containertrackingform.get("borderdate").setValue(null);
      }
      else {
        BorderDate = datePipe.transform(BorderDate, 'yyyy-MM-dd');
      }
      if (TransporterDate == null) {
        TransporterDate = this._containertrackingform.get("transporterdate").setValue(null);
      }
      else {
        TransporterDate = datePipe.transform(TransporterDate, 'yyyy-MM-dd');
      }

      this._containertrackingform.get("containerstatus").setValue("MSC00263");
      if (this._containertrackingform.get("portagent").value) {
        this._containertrackingform.get("containerstatus").setValue("MSC00264");
      }
      if (this._containertrackingform.get("borderagent").value) {
        this._containertrackingform.get("containerstatus").setValue("MSC00265");
      }
      if (this._containertrackingform.get("localtransporter").value) {
        this._containertrackingform.get("containerstatus").setValue("MSC00266");
      }


      //For Model and Form Mapping
      this._containertracking = this._containertrackingform.getRawValue();
      this._containertracking.modifiedby = sessionStorage["userid"];
      this._containertracking.modifiedon = new Date();
      this._containertracking.companyid = 0;
      this._containertracking.branchid = parseInt(sessionStorage["currentbranchid"]);
      this._containertracking.accountingyear = new Date().getFullYear();
      this._containertracking.containertrackingdate = (new Date(ContainertrackingDate));
      this._containertracking.shippingdate = (new Date(ShippingDate));
      this._containertracking.arraivaldate = (new Date(ArraivalDate));
      this._containertracking.portbldate = (new Date(BlDate));
      this._containertracking.borderdate = (new Date(BorderDate));
      this._containertracking.transporterdate = (new Date(TransporterDate));
      if (this._action == 'create') {
        this._containertracking.createdby = sessionStorage["userid"];
        this._containertracking.createdon = new Date();
        this._containerFORM = this.formconverstion(this._containertracking, this._Proofs, this._selectedDocuments, this._referencedetails);
        this._IsProgressSpinner = true;
        this._ContainertrackingService.create(this._containerFORM).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          //Rule46:after saving the record, get the response A, display the success message as " A  registered Succesfully "
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsProgressSpinner = false;
            this._submitted = false;
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        this._containertracking.modifiedon = new Date();
        this._containertracking.modifiedby = sessionStorage["userid"];
        this._containerFORM = this.formconverstion(this._containertracking, this._Proofs, this._selectedDocuments, this._referencedetails);
        this._IsProgressSpinner = true;
        this._ContainertrackingService.create(this._containerFORM).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this._IsProgressSpinner = false;

          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
  //Form Reset:
  reset(event) {
    this._containertrackingform.reset();
    this._Proofs = [] = [];
    this._PurchaseOrders = [] = [];
    this._selectedpurchaseorderno = [] = [];
    this.onDocumentClear()
    this.InitializeForm();
    this._submitted = false;
  }

  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._containertrackingObj;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  //Search Screen Call:
  goBack(event) {
    this._router.navigate(['/vContainertracking']);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

  showdocumentGridDialog(position: string, _Proof: any) {
    let url = null;
    if (_Proof.attachmenturl) {
      url = _Proof.attachmenturl;
    }
    // this.position = position;

    if (!url)
      return;

    window.open(url, "MsgWindow", "width=800,height=800");
    // this.displaydocumentgridpopup = true;
  }

  OnAgentchange() {
    var borderagentsupplierid = this._containertrackingform.get("borderagent").value || 0;
    var localtransporter = this._containertrackingform.get("localtransporter").value || 0;
    var portagent = this._containertrackingform.get("portagent").value || 0;

    this._portAgentDetailList = this._portAgentList.filter(f => f.supplierid == borderagentsupplierid || f.supplierid == localtransporter || f.supplierid == portagent);
  }
}
