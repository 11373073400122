<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Price Comparison</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add"
                                class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                (click)="ExportToPdf()" class="p-button-warning p-mr-2" pTooltip="PDF"
                                tooltipPosition="bottom"></button>
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_Pricecomparisonviews" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['pricecomparisonid','productname','levelname','bholaprice','c1name',
                        'c1unitprice','c2name','c2unitprice','c3name','c3unitprice','c4name','c4unitprice','createdByName']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="salesquotationno"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">

                        <ng-template pTemplate="header">
                            <tr>

                                <th class="text-center" style="width:100px;" pSortableColumn="Action">Action<p-sortIcon
                                        field="name"></p-sortIcon>
                                </th>
                                <th hidden pSortableColumn="pricecomparisonid">Product<p-sortIcon
                                        field="pricecomparisonid"></p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product<p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="levelname">Level<p-sortIcon field="levelname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="bholaprice">Bhola Price(USD)<p-sortIcon field="bholaprice">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="c1name">Competitor Name 1<p-sortIcon field="c1name"></p-sortIcon>
                                <th pSortableColumn="c1unitprice">Competitor 1 Price<p-sortIcon field="c1unitprice">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="c2name">Competitor Name 2<p-sortIcon field="c2name"></p-sortIcon>
                                </th>
                                <th pSortableColumn="c2unitprice">Competitor 2 Price<p-sortIcon field="c2unitprice">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="c3name">Competitor Name 3<p-sortIcon field="c3name">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="c3unitprice">Competitor 3 Price<p-sortIcon field="c3unitprice">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="c4name">Competitor Name 4<p-sortIcon field="c4name"></p-sortIcon>
                                </th>
                                <th pSortableColumn="c4unitprice">Competitor 4 Price<p-sortIcon field="c4unitprice">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdByName">Created By<p-sortIcon field="createdByName">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On<p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Pricecomparisonview>
                            <tr>

                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="!_Action._View" (click)="view(_Pricecomparisonview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit"
                                        class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update"
                                        (click)="edit(_Pricecomparisonview)"></button>
                                    <!-- <button pButton pRipple icon="las la-times" title="Cancel"
                                    class="p-button p-button-danger" 
                                    (click)="Cancel(_Pricecomparisonview)"></button> -->
                                </td>
                                <td hidden>
                                    {{_Pricecomparisonview.pricecomparisonid}}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.productname}}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.levelname}}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.bholaprice}}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.c1name}}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.c1unitprice}}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.c2name}}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.c2unitprice}}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.c3name}}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.c3unitprice}}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.c4name }}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.c4unitprice }}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.createdByName }}
                                </td>
                                <td>
                                    {{_Pricecomparisonview.createdon  |date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


            </div>
        </div>
    </div>
</div>