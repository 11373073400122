import { HostListener, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { environment } from 'src/environments/environment';

declare var signalR: any;

@Injectable({
    providedIn: 'root'
})
export class NotificationHandler implements CanDeactivate<NotificationHandler>, OnDestroy {

    connection: any = null;
    //serviceURL: string = environment.ServiceURL;
    constructor(private _CustomExceptionService: CustomExceptionService) {

    }


    InitializeConnection(url: string): NotificationHandler {
        if (!this.connection)
            this.connection = new signalR.HubConnectionBuilder().withUrl(url).build();
        return this;
    }

    Watch(watchingKey: string, callback: any): NotificationHandler {
        this.connection.on(watchingKey, (model) => {
            if (typeof callback === 'function')
                callback(model);
        });
        return this;
    }

    AddWatchToExisting(watchingKey: string, callback: any): NotificationHandler {
        this.Watch(watchingKey, callback);
        return this;
    }

    async invoke(methodName: string, callback: any): Promise<any> {
        return this.connection.invoke(methodName).then((model) => {
            if (typeof callback === 'function')
                callback(model);
        });
    }

    async Start(): Promise<any> {
        return this.connection.start().catch((err: any) => {
            this._CustomExceptionService.handleWarning(err);
            setTimeout(() => this.Start(), 5000);
        }).then(() => {
            this.connection.invoke("GetConnectionId").then((connectionId: any) => {
                console.log(connectionId);
            });
            this.ReConnectOnDisconnect();
        });
    }

    Stop(): void {
        if (this.connection) {
            this.connection.stop();
        }
    }


    private ReConnectOnDisconnect(): void {
        if (this.connection) {
            this.connection.onclose(async () => {
                let session = sessionStorage["userid"];
                if (session) {
                    console.log("Signalr reconnecting...")
                    await this.connection.start();
                }
            });
        }
    }

    canDeactivate(component: NotificationHandler, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        throw new Error('Method not implemented.');
    }
    ngOnDestroy(): void {
        //throw new Error('Method not implemented.');
    }

    @HostListener('window:beforeunload', ['$event'])
    onWindowClose(event: any): void {
        this.Stop();
    }

}
