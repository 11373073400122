import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { User } from 'src/app/core/Models/User';
import { Userscreenmapping } from 'src/app/core/Models/userscreenmapping';
import { UserscreenmappingService } from 'src/app/core/Services/users/userscreenmapping.service';
import * as ace from "ace-builds";
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ElementRef } from '@angular/core';
import { Role } from 'src/app/core/Models/Role';
import { Userrolemappedfunctionsview } from 'src/app/core/Views/Userrolemappedfunctionsview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { vCurrencySelect } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
@Component({
  selector: 'app-c-userscreenmapping',
  templateUrl: './c-userscreenmapping.component.html',
  styleUrls: ['./c-userscreenmapping.component.css']
})
export class CUserscreenmappingComponent implements OnInit {
  _userwisescreenmappingform: FormGroup;
  _action: string = "create";
  _submitted = false;
  selectedrows: any;
  pSelectableRow: any;
  _userscreenmapping: Userscreenmapping;

  // 4.user name drop down must load from user master(user code,user name)
  _Userrole: Role;
  _UserModules: Function;
  _Username: User[];
  _userwisescreenmappingid: number
  _userscreens: Userrolemappedfunctionsview[];
  _userscreen: Userrolemappedfunctionsview;
  _inputOBJ: any;
  selecteduser: any;
  selecteduserid: any;
  selectedroleid: any;
  _Action: IAction;
  isSeriesDisabled: boolean;
  _gridAction: any = {};
  _IsProgressSpinner: boolean = true;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  UserRoleOBJ: any;
  showseries: boolean = true;
  showseriesheader: boolean = true;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _router: Router, private _UserscreenmappingService: UserscreenmappingService
    , private _CustomExceptionService: CustomExceptionService, private _AccessRightsService: AccessRightsService, private _hotkeysService: HotkeysService) {
    this.HotKeyIntegration();
    //  //Save Short cut keys
    //  this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
    //   this.onSave();
    //   return false; // Prevent bubbling
    // }));
    // //Clear
    // this._hotkeysService.add(new Hotkey('alt+x', (event: KeyboardEvent): boolean => {
    //   this.reset(event);
    //   return false; // Prevent bubbling
    // }));
    this._Action = this._AccessRightsService.getAccessRights(31);
  }
  ngOnInit(): void {

    this.utility.pageLoadScript();
    //Parent Form
    this._userwisescreenmappingform = this.fb.group({
      userwisescreenmappingid: [],
      userid: ['', vCurrencySelect],
      roleid: ['', vCurrencySelect],
      companyid: [],
      moduleid: [],
      screenid: [],
      create: [],
      update: [],
      delete: [],
      view: [],
      print: [],
      approval: [],
      pricehistory: [],
      profit: [],
      createdby: [],
      userscreenmappingstatus: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      series: [],
      path: []
    });

    if (this._Action._Series) {
      this.showseries = false;
      this.showseriesheader = false
    }
    this._IsProgressSpinner = true;
    this._UserscreenmappingService.PageOnLoad().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Userrole = resultJSON.roles;
      this._UserModules = resultJSON.modules;
      // this._Username = resultJSON.users;

      // for update
      this._userwisescreenmappingid = history.state.userwisescreenmappingid;
      this._action = history.state.action == null ? 'create' : history.state.action;
      if (this._action == 'edit' || this._action == 'View') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._userwisescreenmappingform.setValue(updateJSON.Userscreenmapping);
        // this._userscreens = updateJSON.userrolemappedmodulefunctions;
        // this.selecteduser = this._userscreens;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  //   checkseriesRights(){

  // }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));


    }

  }
  getUsers(event) {

    var selectedrole = event.value;
    this._IsProgressSpinner = true;

    this._UserscreenmappingService.getUsers(selectedrole,sessionStorage["userid"]).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Username = resultJSON.users;
      this._gridAction = [];
      this._userscreens = [];
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  getModuleandFunction(event) {
    var Selectedmodule = this._userwisescreenmappingform.get("moduleid").value || [0];
    if (Selectedmodule.length == 0 || Selectedmodule == null) {
      return;
    }
    var selecteduser = this._userwisescreenmappingform.get("userid").value || sessionStorage["userid"];
    var selectedrole = this._userwisescreenmappingform.get("roleid").value;
    this._IsProgressSpinner = true;

    this._UserscreenmappingService.getModuleandFunction(Selectedmodule,selecteduser, selectedrole).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //  5. On Selecting One user , corresponding module and screen(Function) should fetch from role module mapping screen
      //  6.all the modules which has been mapped with the role of the particular user should fetch in the grid 
      this._userscreens = resultJSON.userrolemappedmodulefunctions;
      this.selecteduser = this._userscreens;
      if (this._userscreens && this._userscreens.length)
        this.OnGridSelectionChange();
      // this._gridAction = [];

      if (this._userscreens.length > 0) {
        for (var i = 0; i < this._userscreens.length; i++) {
          if (this._userscreens[i].screenid != 21) {
            this.isSeriesDisabled = true;
          }
        }
      }


      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  // UserChange(event){
  //   var selecteduser =event.value;
  //   this.getModuleandFunction(event);
  // }
  //for select checkbox
  onScreenSelect(event, _userscreen: any) {
    var _userscreens: Userscreenmapping[] = [];
    var index = this.selecteduser.findIndex(obj => obj.screenid == _userscreen.screenid);
    this._userscreens[index].screenid = _userscreen.screenid;
    this.OnGridSelectionChange();
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }

  OnGridSelectionChange() {
    let screenListLength = this._userscreens.length;
    this._gridAction._Add = (this._userscreens.filter(f => f.create == true).length == screenListLength);
    this._gridAction._Update = (this._userscreens.filter(f => f.update == true).length == screenListLength);
    this._gridAction._Approve = (this._userscreens.filter(f => f.approval == true).length == screenListLength);
    this._gridAction._Delete = (this._userscreens.filter(f => f.delete == true).length == screenListLength);
    this._gridAction._View = (this._userscreens.filter(f => f.view == true).length == screenListLength);
    this._gridAction._Print = (this._userscreens.filter(f => f.print == true).length == screenListLength);
    this._gridAction._Series = (this._userscreens.filter(f => f.series == true).length == screenListLength);
	 // Ecommerce
    this._gridAction._TabViews = (this._userscreens.filter(f => f.tabviews == true).length == screenListLength);
  																											
  }

  //for save
  // 20.if all the data is valid allow to save the record in the database 
  onSave() {
    this._submitted = true;
    if (this._userwisescreenmappingform.valid) {
      var userscreenmapping: Userscreenmapping[] = [];
      var screenmapping: Userscreenmapping;
      let Valid = this._userscreens.some(s => s.create || s.update || s.delete || s.print || s.approval || s.series)
      if (!Valid) {
        this._CustomExceptionService.handleError(usererrors.Grid_Select_06);
        return;
      }
      Object.keys(this._userscreens).map((Index) => {
        screenmapping = new Userscreenmapping();
        screenmapping.roleid = this._userwisescreenmappingform.get("roleid").value;
        screenmapping.userid = this._userwisescreenmappingform.get("userid").value;
        screenmapping.moduleid = this._userscreens[Index].moduleid
        screenmapping.screenid = this._userscreens[Index].screenid
        screenmapping.create = this._userscreens[Index].create
        screenmapping.update = this._userscreens[Index].update;
        screenmapping.view = this._userscreens[Index].view;
        screenmapping.delete = this._userscreens[Index].delete;
        screenmapping.print = this._userscreens[Index].print;
        screenmapping.approval = this._userscreens[Index].approval;
        screenmapping.series = this._userscreens[Index].series;
        screenmapping.pricehistory = this._userscreens[Index].pricehistory;
		 // Ecommerce
        screenmapping.tabviews = this._userscreens[Index].tabviews;														   
        screenmapping.profit = this._userscreens[Index].profit;
        screenmapping.userscreenmappingstatus = "MSC00001";
        screenmapping.createdon = new Date();
        screenmapping.modifiedon = new Date();
        screenmapping.createdby = sessionStorage["userid"];
        screenmapping.modifiedby = sessionStorage["userid"];
        userscreenmapping.push(screenmapping);
      });
      this.UserRoleOBJ = JSON.stringify({ "Userscreenmapping": userscreenmapping });

      this.UserRoleOBJ = JSON.parse(JSON.stringify({ "Userscreenmapping": userscreenmapping }));

      // this.onEd();
      if (this._action == 'create') {
        this._IsProgressSpinner = true;
        this._UserscreenmappingService.create(this.UserRoleOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            // 22.after saving the record, display the success message as "User  and Screen mapped Succesfully "
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }

    }

  }


  reset(event) {
    this._submitted = true;
    this._userwisescreenmappingform.reset();
    this._userscreens = null;
    this._Username = [];
    this._gridAction = [];
    this._submitted = false;

  }

  // onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this.UserRoleOBJ;

  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }

  Header(event) {
    alert(event);
  }

  CheckHeader(type: string) {
    if (!this._userscreens || !this._userscreens.length)
      return;
    this._userscreens.forEach(element => {
      if (type == 'create') {
        element.create = this._gridAction._Add == true;
      }
      if (type == 'update') {
        element.update = this._gridAction._Update == true;
      }
      if (type == 'approval') {
        element.approval = this._gridAction._Approve == true;
      }
      if (type == 'print') {
        element.print = this._gridAction._Print == true;
      }
      if (type == 'delete') {
        element.delete = this._gridAction._Delete == true;
      }
      if (type == 'view') {
        element.view = this._gridAction._View == true;
      }
      if (type == 'series') {
        if (element.screenid == 21 || element.screenid == 30 || element.screenid == 19) {
          element.series = this._gridAction._Series == true;
        }
      }
      if (type == 'pricehistory') {
        if (element.screenid == 17) {
          element.pricehistory = this._gridAction._PriceHistories == true;
        }
      }
      if (type == 'profit') {
        if (element.screenid == 55 || element.screenid == 56) {
          element.profit = this._gridAction._Profit == true;
        }
      }
		// Ecommerce
      if (type == 'tabviews') {
        if (element.screenid == 17) {
          element.tabviews = this._gridAction._TabViews == true;
        }
      }

    });
  }
}



