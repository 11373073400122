import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../../domain/appconfig';

@Injectable({
  providedIn: 'root'
})
export class SalesDashboardService {

  public _salesDashboardService = environment.SalesDashboardService;
  token: any;
  header: any;
  config: AppConfig = {
    theme: 'saga-blue',
    dark: false,
    inputStyle: 'outlined',
    ripple: true
  };
  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  private configUpdate = new Subject<AppConfig>();

  configUpdate$ = this.configUpdate.asObservable();

  updateConfig(config: AppConfig) {
    this.config = config;
    this.configUpdate.next(config);
  }

  getConfig() {
    return this.config;
  }

  PageOnload(): Observable<any> {
		const headers = this.header;
    var Query = this._salesDashboardService + '/pageload';

    return this.http.get<{ data: any }>(Query, { headers });
  }

  SalesReturnReport(obj: object): Observable<any> {
		const headers = this.header;
    var Query = this._salesDashboardService + '/SalesReturnReport';

    return this.http.post(Query, obj, { headers });
  }

  SalesByCategory(branchid: number): Observable<any> {
		const headers = this.header;
    var Query = this._salesDashboardService + '/FastMovingStock/' + branchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  TopSalesBranchWise(obj:any): Observable<any> {
		const headers = this.header;
    // let paramvalue = "branchid="
    // paramvalue = paramvalue + branchid.join('&branchid=');
    // if (branchid.length == 0) {
    //   paramvalue = ""
    // }
    var Query = this._salesDashboardService + '/TopSalesBranchWise' ;

    return this.http.post<{ data: any }>(Query, obj, { headers });
  }
}
