import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';

import { TellercashdaysettlementService } from 'src/app/core/Services/accounts/tellercashdaysettlement.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { vEffectiveFrom, vEffectiveTo, vTellerNameSelect } from 'src/app/core/Validators/validation';
import { Tellercashview } from 'src/app/core/Views/Tellercashview';
import { Tellernameview } from 'src/app/core/Views/Tellernameview';

import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-teller-cash-day-settlement',
  templateUrl: './v-teller-cash-day-settlement.component.html',
  styleUrls: ['./v-teller-cash-day-settlement.component.css']
})
export class VTellerCashDaySettlementComponent implements OnInit {
  _tellercashform: FormGroup;
  _searchsubmitted = false;
  _userid = sessionStorage["userid"];

  // _branchid=sessionStorage["BranchID"];
  selectedrows: any;
  _Tellercashviews: Tellercashview[] = [];
  _tellernames: Tellernameview;
  _IsProgressSpinner: boolean = true;
  isDisabledsendapparoval = true;
  _Action: IAction;
  _branchid = sessionStorage["currentbranchid"];
  mindate: Date;
  maxdate: Date;
  datepipe = new DatePipe('en-US');
  cols: any[];
  constructor(private fb: FormBuilder, private _TellerCashDaySettlementService: TellercashdaysettlementService, private _CustomExceptionService: CustomExceptionService, private router: Router, private utility: CommonUtilities, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService, private exportUtility: ExportUtility) {
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(73);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.cols = [
      { field: 'tellername', header: 'Teller Name' },
      { field: 'accountname', header: 'Account Name' },
      { field: 'branchname', header: 'Branch Name' },
      { field: 'salesdatestr', header: 'Sales Date' },
      { field: 'currencyname', header: 'Currency Name' },
      { field: 'totalreceivableamount', header: 'Receivable Amount' },
      { field: 'paidamount', header: 'Paid Amount' },
      { field: 'alreadyissued', header: 'Total Paid Amount' },
      { field: 'balancetoissued', header: 'Balance Amount' },
      { field: 'tellercashstatusdesc', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this._tellercashform = this.fb.group({
      tellerid: ['', vTellerNameSelect],
      fromdate: ['', vEffectiveFrom],
      todate: ['', vEffectiveTo]
    });

    if (sessionStorage['Environmentenddate'] == "null") {
      this._tellercashform.controls["fromdate"].setValue(new Date());
      this._tellercashform.controls["todate"].setValue(new Date());
      this.maxdate = new Date();
    }
    else {
      let maxdate = new Date(sessionStorage['Environmentenddate']);
      this._tellercashform.controls["fromdate"].setValue(maxdate);
      this._tellercashform.controls["todate"].setValue(maxdate);
    }
    this._IsProgressSpinner = true;
    var formdate = this._tellercashform.get("fromdate").value;
    var todate = this._tellercashform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var pfromdate = datePipe.transform(formdate, 'dd-MMM-yyyy');

    var ptodate = datePipe.transform(todate, 'dd-MMM-yyyy');
    if (this._userid == 1 || this._userid == 6 || this._userid == 12) {
      var Tellername = this._tellercashform.get("tellerid").value || [];
    }
    else {
      var Tellername = this._tellercashform.get("tellerid").value || [];
      // if (Tellername == "") {
      //   this._IsProgressSpinner = false;
      //   this._CustomExceptionService.handleError("Please Select Teller Name")
      //   return
      // }
    }

    // if (this._tellercashform.valid) {
    // let paramvalue = "?Ids=" + Tellername.join('&Ids=');

    if (!pfromdate || !ptodate)
      return;
    this._IsProgressSpinner = true;
    let data = { "fromdate": pfromdate, "todate": ptodate, "Ids": Tellername, "userid": this._userid, "branchid": parseInt(sessionStorage["currentbranchid"]) }
    this._TellerCashDaySettlementService.Search(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._tellernames = resultJSON.tellernameviews;
      // if (this._userid != 1 || this._userid != 6 || this._userid != 12) {
      //   this._tellercashform.controls["tellerid"].setValue(this._userid);
      // }
      this._Tellercashviews = resultJSON.tellercashviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    // this._TellerCashDaySettlementService.FetchAll(this._userid, sessionStorage["currentbranchid"]).subscribe((result) => {
    //   const resultJSON = JSON.parse(JSON.stringify(result));
    //   console.log(resultJSON);
    //   this._tellernames = resultJSON.tellernameviews;
    //   this._Tellercashviews = resultJSON.tellercashviews;
    //   this._IsProgressSpinner = false;
    // },
    //   error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    // this.SearchTellercash();
  }
  SearchTellercash() {
    // this._searchsubmitted = true;
    var formdate = this._tellercashform.get("fromdate").value;
    var todate = this._tellercashform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var pfromdate = datePipe.transform(formdate, 'dd-MMM-yyyy');

    var ptodate = datePipe.transform(todate, 'dd-MMM-yyyy');
    if (this._userid == 1 || this._userid == 6 || this._userid == 12) {
      var Tellername = this._tellercashform.get("tellerid").value || [];
    }
    else {
      var Tellername = this._tellercashform.get("tellerid").value;
      if (Tellername == "") {
        this._IsProgressSpinner = false;
        this._CustomExceptionService.handleError("Please Select Teller Name")
        return
      }
    }

    // if (this._tellercashform.valid) {
    // let paramvalue = "?Ids=" + Tellername.join('&Ids=');

    if (!pfromdate || !ptodate)
      return;
    this._IsProgressSpinner = true;
    let data = { "fromdate": pfromdate, "todate": ptodate, "Ids": Tellername, "userid": this._userid, "branchid": parseInt(sessionStorage["currentbranchid"]) }
    this._TellerCashDaySettlementService.Search(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._tellernames = resultJSON.tellernameviews;
      // if (this._userid != 1 || this._userid != 6 || this._userid != 12) {
      //   this._tellercashform.controls["tellerid"].setValue(this._userid);
      // }
      this._Tellercashviews = resultJSON.tellercashviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    // }
  }
  Clear(event) {
    this._tellercashform.reset();
    this._tellercashform.controls["fromdate"].setValue(new Date());
    this._tellercashform.controls["todate"].setValue(new Date());
  }
  view(event) {
    this.router.navigate(['/cTellerCashDaySettlement'], { state: { tellercashid: event.tellercashid, tellerid: event.tellerid, salesdate: event.salesdate, branchid: event.branchid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cTellerCashDaySettlement']);
  }
  edit(event) {
    console.log("event.value");
    console.log(event.tellercashid);
    this.router.navigate(['/cTellerCashDaySettlement'], { state: { tellercashid: event.tellercashid, salesdate: event.salesdate, action: 'edit' } });
  }

  SendToApproval(event) {
    // var workflowid = 5;
    let tellercashid = event.tellercashid;
    this._userid = sessionStorage["userid"];
    let _branchid = sessionStorage["BranchID"];
    //76.after clicking the send to approve button, the purchase order is given to 4 levels of approval 
    //this._productid= this._damagedproductpricedetailsform.get("productid").setValue(this._damagedproductpricedetailsform.get("productid").value);
    this._IsProgressSpinner = true;
    this._TellerCashDaySettlementService.SendToApproval(this._userid, _branchid, tellercashid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
      this.isDisabledsendapparoval = true;
      this._IsProgressSpinner = false;
      this.SearchTellercash();
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let TellerCashDaySettlementList = this.GetTellerCashList();
    this.exportUtility.exportExcel(TellerCashDaySettlementList, 'TellerCashDaySettlement', 'xlsx');
  }

  ExportToPdf() {
    let TellerCashDaySettlementList = this.GetTellerCashList();
    this.exportUtility.ExportToPdf(TellerCashDaySettlementList, 'TellerCashDaySettlement.pdf');
  }
  // this.cols = [
  //   { field: 'tellername', header: 'Teller Name' },
  //   { field: 'accountname', header: 'Account Name' },
  //   { field: 'salesdatestr', header: 'Sales Date' },
  //   { field: 'totalreceivableamount', header: 'Receivable Amount' },
  //   { field: 'totalpaidamount', header: 'Paid Amount' },
  //   { field: 'totalbalanceamount', header: 'Balance Amount' },
  //   { field: 'tellercashstatusdesc', header: 'Status' },
  //   { field: 'createdbyname', header: 'Created By' },
  //   { field: 'createdon', header: 'Created On' },
  // ]

  GetTellerCashList() {
    if (!this._Tellercashviews || !this._Tellercashviews.length)
      return;
    let TellerCashDaySettlementList = [];
    this._Tellercashviews.forEach(element => {
      let tellercashday: any = {};
      tellercashday["Teller Name"] = element.tellername;
      tellercashday["Account Name"] = element.accountname;
      tellercashday["Branch Name"] = element.branchname;
      tellercashday["Sales Date"] = element.salesdatestr;
      tellercashday["Currency Name"] = element.currencyname;
      tellercashday["Receivable Amount"] = element.totalreceivableamount;
      tellercashday["Paid Amount"] = element.totalpaidamount;
      tellercashday["Total Paid Amount"] = element.alreadyissued;
      tellercashday["Balance Amount"] = element.balancetoissued;
      tellercashday["Status"] = element.tellercashstatusdesc;
      tellercashday["Created By"] = element.createdbyname;
      tellercashday["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      TellerCashDaySettlementList.push(tellercashday);
    });
    return TellerCashDaySettlementList;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
}
