import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class SlidermanagementService {

  public _Action: IAction;
  public _SliderManagementService = environment.SliderManagementService;
  public _httpOptions;
  constructor(private http: HttpClient) {

  }
  PageOnload(slidemanagementid: number): Observable<any> {
    var Query = this._SliderManagementService + '/' + slidemanagementid;
    // console.log(Query);
    return this.http.get<{ data: any }>(Query);
  }
  create(_SliderFORM: FormData) {
    // console.log("Create");
    var Query = this._SliderManagementService;
    // console.log(Query);
    return this.http.post<{ data: any }>(Query, _SliderFORM);
  }
  update(_SliderFORM: FormData) {
    // console.log("Update");
    var Query = this._SliderManagementService;
    // console.log(Query);
    return this.http.put<{ data: any }>(Query, _SliderFORM);
  }
  Fetchall() {
    var Query = this._SliderManagementService + '/FetchAll';
    // console.log(Query);
    return this.http.get<{ data: any }>(Query);
  }
  Delete(Slidemanagementid: number) {
    var Query = this._SliderManagementService + '/Delete' + '/' + Slidemanagementid;
    // console.log(Query);
    return this.http.get<{ data: any }>(Query);
  }

}
