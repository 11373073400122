export class Stockdeliverygetproductdetail {
    stockdeliverydetailid: number;
    stockdeliveryid: number;
    evidenceid: number;
    evidenceno: string;
    evidencedate: Date | string;
    customerorbranchid: number;
    customerorbranchname: string;
    productid: number;
    productname: string;
    variantid: number;
    variantdescription: string;
    invoicequantity: number;
    deliveryquantity: number;
    balancequantity: number;
    uomcode: string;
    uomname: string;
    isdelivered: boolean;
    accountingyear: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    invoicetype: string;
    serialnumber: string;
    otherbranchid: number;
    otherbranchdeliveredrequired: boolean;
    isDisabledotherdeliveryrequired: boolean;
    otherbranchstock: number;
    currentstock: number;
    isDisabledisdeliveryQuantity: boolean;
    totalinvoicequantity: number;
    previousdeliveryquantity: number;
}