import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { StocktransferorderService } from 'src/app/core/Services/inventory/stocktransferorder.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Stocktransferorderview } from 'src/app/core/Views/Stocktransferorderview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ConfirmationService } from 'primeng/api';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import { RptstocktransferService } from 'src/app/core/Services/reports/Stock/StockTransfer/rptstocktransfer.service';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-v-stock-transfer-order',
  templateUrl: './v-stock-transfer-order.component.html',
  styleUrls: ['./v-stock-transfer-order.component.css']
})
export class VStockTransferOrderComponent implements OnInit {

  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  _Stocktransferorderviews: any[] = [];
  _tempStocktransferorderviews: any[] = []
  cols: any[];
  _branchid: number;
  _userid: number;
  _roleid: number;
  fromdate: Date;
  todate: Date;

  datepipe = new DatePipe('en-US');
  _deliverystatus: any
  deliverystatusid: any;
  mindate: Date;
  maxdate: Date;
  constructor(private fb: FormBuilder,
    private _StockTransferOrderService: StocktransferorderService,
    private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService,
    private router: Router, private utility: CommonUtilities,
    private _hotkeysService: HotkeysService,
    private confirmationService: ConfirmationService,
    private exportUtility: ExportUtility,
    private _StockransferReportService: RptstocktransferService) { }

  ngOnInit(): void {

    this._Action = this._AccessRightsService.getAccessRights(46);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.cols = [
      { field: 'stocktransferno', header: 'Stock Transfer No' },
      { field: 'orderdate', header: 'Order Date' },
      { field: 'fromstore', header: 'From Branch' },
      { field: 'tostore', header: 'To Branch' },

      // { field: 'productname', header: 'Product Name' },
      // { field: 'variantname', header: 'Variant  Name' },
      // { field: 'requestquantity', header: 'Quantity' },
      { field: 'statusname', header: 'Status' },
      { field: 'deliverystatus', header: 'Delivery Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this._deliverystatus = [
      { id: 1, name: 'Delivered' },
      { id: 2, name: 'Shipped' },
      { id: 3, name: 'Partially Delivered' },
      { id: 4, name: 'Partially Shipped' },
      { id: 5, name: 'NOT Delivered' },
    ]
    this.Fetchall();

  }
  Fetchall() {

    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }

    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._userid = sessionStorage["userid"];
    this._roleid = sessionStorage["currentRole"];
    this._IsProgressSpinner = true;
    this._Stocktransferorderviews = []
    this._StockTransferOrderService.FetchAll(this._branchid, this._userid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._tempStocktransferorderviews = resultJSON.stocktransferorderviews;


      if (this.deliverystatusid) {

        for (var i = 0; i < this._tempStocktransferorderviews.length; i++) {
          var lists = resultJSON.stocktransferorderviews.find(f => f.stocktransferno == this._tempStocktransferorderviews[i].stocktransferno && f.deliverystatus == this.deliverystatusid)

          if (lists) {
            this._Stocktransferorderviews.push(lists)
          }
        }
        this._Stocktransferorderviews = [...this._Stocktransferorderviews]

      }
      else {
        this._Stocktransferorderviews = resultJSON.stocktransferorderviews;

      }


      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  //View
  view(event) {
    this.router.navigate(['/cStockTransferOrder'], { state: { stocktransferid: event.stocktransferid, action: 'view' } });
  }

  //Edit 
  edit(event) {
    // if (event.status == "MSC00045" || event.status == null) {
    this.router.navigate(['/cStockTransferOrder'], { state: { stocktransferid: event.stocktransferid, action: 'edit' } });
    // }
    // else {
    //   this._CustomExceptionService.handleWarning("Approved Record Should not Update");
    // }
    // console.log("event.value");

  }
  GoToCreate(event) {
    this.router.navigate(['/cStockTransferOrder']);
  }
  partialconfirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Stock Transfer Order <b>"' + event.stocktransferno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.PartialCancel(event);
      }
    });
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Stock Transfer Order <b>"' + event.stocktransferno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  //Cancel
  // 76.on clicking the cancel button, the stock order gets cancelled and the status changes to cancelled 
  PartialCancel(event) {
    var stocktransferid = event.stocktransferno;
    this._IsProgressSpinner = true;
    this._StockTransferOrderService.Cancel(stocktransferid, sessionStorage["userid"]).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.Fetchall();
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  Cancel(event) {
    var stocktransferid = event.stocktransferno;
    this._IsProgressSpinner = true;
    this._StockTransferOrderService.stocancel(stocktransferid, sessionStorage["userid"]).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.Fetchall();
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }



  exportExcel() {
    let StocktransferorderList = this.GetStocktransferorderList();
    this.exportUtility.exportExcel(StocktransferorderList, 'Stocktransferorder', 'xlsx');
  }

  ExportToPdf() {
    let TermsAndConditionList = this.GetStocktransferorderList();
    const data = TermsAndConditionList.map(Object.values);
    const head = [[
      'Stock Transfer No',
      'Order Date',
      'From Branch',
      'To Branch',
      'Status',
      'Delivery Status',
      'Created By',
      'Created On'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Stocktransferorder.pdf');
      })
    })
  }


  GetStocktransferorderList() {
    let StockTransferOrderList = [];
    this._Stocktransferorderviews.forEach(element => {
      let stocktransferorder: any = {};
      stocktransferorder["Stock Transfer No"] = element.stocktransferno;
      stocktransferorder["Order Date"] = this.datepipe.transform(element.orderdate, 'dd/MM/yyyy');
      stocktransferorder["From Branch"] = element.fromstore;
      stocktransferorder["To Branch"] = element.tostore;

      // stocktransferorder["Product Name"] = element.productname;
      // stocktransferorder["Variant  Status"] = element.variantname;
      // stocktransferorder["Quantity"] = element.requestquantity;
      stocktransferorder["Status"] = element.statusname;
      stocktransferorder["Delivery Status"] = element.deliverystatus;
      stocktransferorder["Created By"] = element.createdbyname;
      stocktransferorder["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      StockTransferOrderList.push(stocktransferorder);
    });
    return StockTransferOrderList;
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  Print(event) {
    var stocktransferno = event.stocktransferno;
    var branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;
    this._StockransferReportService.STOPrint(stocktransferno, branchid);
    this._IsProgressSpinner = false;
  }
}


