<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Asset Registration</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" (click)="GoToCreate($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>


                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_Assetregistrationviews" [rows]="10" [paginator]="true" [columns]="cols" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['registrationcode','grnno', 'productname','uomname','suppliername','purchasedate','currencyname','recordstatusname']"
                        [rowHover]="true" dataKey="grnno" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width:120px;">Action
                                </th>
                                <th pSortableColumn="registrationcode">Registration Code
                                    <p-sortIcon field="registrationcode">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="grnno">GRN No
                                    <p-sortIcon field="grnno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="uomname">UOM
                                    <p-sortIcon field="uomname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="suppliername">Supplier
                                    <p-sortIcon field="suppliername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="purchasedate">Purchase Date
                                    <p-sortIcon field="purchasedate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname">Currency
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="recordstatusname">Status
                                    <p-sortIcon field="recordstatusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Assetregistrationview>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2" (click)="view(_Assetregistrationview)" [disabled]="!_Action._View"></button>
                                    <button pButton pRipple icon="las la-times" title="Cancel" class="p-button p-button-danger p-mr-2" [hidden]="_Assetregistrationview.recordstatus == 'MSC00150'" (click)="confirm(_Assetregistrationview)" [disabled]="!_Action._Delete"></button>
                                </td>
                                <td>
                                    {{_Assetregistrationview.registrationcode}}
                                </td>
                                <td>
                                    {{_Assetregistrationview.grnno}}

                                </td>
                                <td>
                                    {{_Assetregistrationview.productname}}
                                </td>
                                <td>
                                    {{_Assetregistrationview.uomname}}
                                </td>
                                <td>
                                    {{_Assetregistrationview.suppliername}}
                                </td>
                                <td>
                                    {{_Assetregistrationview.purchasedate}}
                                </td>
                                <td>
                                    {{_Assetregistrationview.currencyname}}
                                </td>
                                <td>
                                    {{_Assetregistrationview.recordstatusname}}
                                </td>
                                <td>
                                    {{_Assetregistrationview.createdbyname}}
                                </td>
                                <td>
                                    {{_Assetregistrationview.createdon|date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>