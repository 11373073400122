import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { SchemeService } from 'src/app/core/Services/Rewards/scheme.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Schemeview } from 'src/app/core/Views/Schemeview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import {DatePipe} from '@angular/common';
import { usererrors } from 'src/app/core/errors/usererrors';

@Component({
  selector: 'app-v-scheme',
  templateUrl: './v-scheme.component.html',
  styleUrls: ['./v-scheme.component.css']
})
export class VSchemeComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;

  cols: any[];
  _schemeviews: Schemeview[];
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _IsProgressSpinner: boolean = true;
  constructor(private fb: FormBuilder, private router: Router, private utility: CommonUtilities, private _AccessRightsService: AccessRightsService,
    private _SchemeService: SchemeService, private exportUtility: ExportUtility, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, 
    private confirmationService: ConfirmationService,public datepipe:DatePipe) { }

  ngOnInit(): void {
    this._IsProgressSpinner = true;
    this._Action = this._AccessRightsService.getAccessRights(79);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'schemename', header: 'Scheme Name' },
      // { field: 'productname', header: 'Product Name' },
      // { field: 'maximumqty', header: 'Maximum Quantity' },
      // { field: 'discountpercentage', header: 'Discount percentage' },
      // { field: 'rewardpoints', header: 'Reward points' },
      // { field: 'freeproductname', header: 'Free Product Name' },
      { field: 'effectivefrom', header: 'Effective From' },
      { field: 'effectiveto', header: 'Effective To' },
      { field: 'schemestatusname', header: 'Scheme Status' },

    ]
    this._SchemeService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._schemeviews = resultJSON.schemeviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  onRowSelect(event) {
  }

  onRowUnselect(event) {
  }
  view(event) {
    this.router.navigate(['/cScheme'], { state: { schemeid: event.schemeid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cScheme']);
  }
  
  edit(event) {
    // this.router.navigate(['/cCustomer'])
    this.router.navigate(['/cScheme'], { state: { schemeid: event.schemeid, action: 'edit' } });
    // alert("edittest");
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this scheme <b>"' + event.schemename + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    var schemeid = event.schemeid;
    this._SchemeService.Cancel(schemeid, sessionStorage["userid"]).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._schemeviews = resultJSON.schemeviews;
    },
    error => { this._CustomExceptionService.handleError(error);});
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  exportExcel() {
    let schemeList = this.GetSchemeList();
    this.exportUtility.exportExcel(schemeList, 'Scheme', 'xlsx');
  }

  ExportToPdf() {
    let schemeList = this.GetSchemeList();
    this.exportUtility.ExportToPdf(schemeList, 'Scheme.pdf');
  }

  GetSchemeList() {
    let schemeList = [];
    this._schemeviews.forEach(element => {
      let schemes: any = {};
      schemes["Scheme Name"] = element.schemename;
      // schemes["Product Name"] = element.productname;
      // schemes["Maximum Quantity"] = element.maximumqty;
      // schemes["Discount percentage"] = element.discountpercentage;
      // schemes["Reward points"] = element.rewardpoints;
      // schemes["Free Product Name"] = element.freeproductname;
      schemes["Effective From"] = this.datepipe.transform(element.effectivefrom,'dd/MM/yyyy');
      schemes["Effective To"] = this.datepipe.transform(element.effectiveto,'dd/MM/yyyy');
      schemes["Scheme Status"] = element.schemestatus
      schemeList.push(schemes);
    });
    return schemeList;
  }
}
