<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Stock Transfer Order</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple icon="pi pi-plus" title="Add" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Add" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate" [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_deliverystatus"
                                     optionLabel="name" optionValue="name"
                                    [(ngModel)]="deliverystatusid" [showClear]="true">
                                </p-dropdown>
                                <label for="tobranchid">Delivery Status <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                        </td>

                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <!-- 51. on clicking search button , the data must be filtered and display in the grid -->

                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>
                    </p-toolbar>
                    <!-- 74.according to the stock transfer order no ., all the datas should fetch in particular columns. -->
                    <!-- 50. based on the Stock Transfer Order No ,Request Date, To store  you need to filter the data and show in the grid -->
                    <p-table #dt [value]="_Stocktransferorderviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['stocktransferno','stocktransferno','statusname','orderdate','fromstore','tostore','productname','deliverystatus','variantname','requestquantity','createdbyname','createdon']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="stocktransferno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">

                        <ng-template pTemplate="header">
                            <tr>

                                <th class="text-center" style="width:150px;">Action
                                </th>
                                <th pSortableColumn="stocktransferno">Request Number
                                    <p-sortIcon field="stocktransferno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="orderdate">Request Date
                                    <p-sortIcon field="orderdate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="fromstore">From Branch
                                    <p-sortIcon field="fromstore"></p-sortIcon>
                                </th>
                                <th pSortableColumn="tostore">To Branch
                                    <p-sortIcon field="tostore"></p-sortIcon>
                                </th>

                                <!-- <th pSortableColumn="productname">Product Name<p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="variantname">Variant<p-sortIcon field="variantname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="requestquantity">Quantity<p-sortIcon field="requestquantity">
                                    </p-sortIcon>
                                </th> -->
                                <th pSortableColumn="statusname"> Approval Status
                                    <p-sortIcon field="statusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="deliverystatus"> Delivery Status
                                    <p-sortIcon field="deliverystatus">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Stocktransferorderview>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_Stocktransferorderview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update" [disabled]="_Stocktransferorderview.status == 'MSC00047'|| _Stocktransferorderview.status == 'MSC00046' || _Stocktransferorderview.status == 'MSC00048' || _Stocktransferorderview.status == 'MSC00150'"
                                        (click)="edit(_Stocktransferorderview)"></button>

                                    <button pButton pRipple icon="pi pi-times" title="Cancel" class="p-button p-button-danger" [disabled]="!_Action._Delete" [disabled]="_Stocktransferorderview.status == 'MSC00150' || _Stocktransferorderview.status == 'MSC00046' || _Stocktransferorderview.status == 'MSC00048' ||  _Stocktransferorderview.status == 'MSC00047'"
                                        (click)="confirm(_Stocktransferorderview)"></button>

                                    <button pButton pRipple icon="las la-times-circle" title="Close" [disabled]="!_Action._Delete" [disabled]="_Stocktransferorderview.status == 'MSC00150' ||  _Stocktransferorderview.balancedeliveryquantity == '0' || _Stocktransferorderview.status == 'MSC00048'"
                                        class="p-button p-button-danger" (click)="partialconfirm(_Stocktransferorderview)"></button>
                                    <!-- [disabled]="_Stocktransferorderview.status == 'MSC00150' || _Stocktransferorderview.stocktransferno == _Stocktransferorderview.evidenceno" -->
                                    <button pButton pRipple icon="las la-print" title="Print" class="p-button-warning p-mr-2" (click)="Print(_Stocktransferorderview)"></button>
                                </td>
                                <td>
                                    {{_Stocktransferorderview.stocktransferno}}
                                </td>
                                <td>
                                    {{_Stocktransferorderview.orderdate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_Stocktransferorderview.fromstore}}
                                </td>
                                <td>
                                    {{_Stocktransferorderview.tostore}}
                                </td>

                                <!-- <td>
                                    {{_Stocktransferorderview.productname }}
                                </td>
                                <td>
                                    {{_Stocktransferorderview.variantname}}
                                </td>
                                <td>
                                    {{_Stocktransferorderview.requestquantity}}
                                </td> -->
                                <td>
                                    {{_Stocktransferorderview.statusname}}
                                </td>
                                <td>
                                    {{_Stocktransferorderview.deliverystatus}}
                                </td>
                                <td>
                                    {{_Stocktransferorderview.createdbyname}}
                                </td>
                                <td>
                                    {{_Stocktransferorderview.createdon|date:'dd/MM/yyyy'}}
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


            </div>
        </div>
    </div>
</div>