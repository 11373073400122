export class Purchaseorderothercharge {
    purchaseorderotherchargesid: number;
    purchaseorderid: number;
    otherchargesid: number;
    otherchargename: string;
    otherchargesamount: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date ;
    accountingyear: number;

 
}