import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptproductdeliveryService {
  public _Action: IAction;
  public _httpOptions;
  public _RptProductDeliveryService = environment.RptProductDeliveryService;
  constructor(private http: HttpClient) { }


  PageOnload(branchid: number): Observable<any> {
    var Query = this._RptProductDeliveryService + '/PageInitialize/' + branchid;
    
    return this.http.get<{ data: any }>(Query);
  }
  
  getProductCategory(producttype:object) {
    var Query = this._RptProductDeliveryService+'/getProductCategories';
    
    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory:object) {
    var Query = this._RptProductDeliveryService + '/getProducts';
    
    return this.http.post<{ data: any }>(Query, productcategory);
  }
  
  Print(productdelivery:object) {
    var Query = this._RptProductDeliveryService ;
    
    return this.http.post(Query,productdelivery, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }
  PrintCopy(deliveryno: string,branchid : number) {
    var Query = this._RptProductDeliveryService + '/Print/' + deliveryno +'/'+branchid;
    window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
  }
}
