<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product Barcode Sticker Print</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_productbarcodeprintform">
                    <table class="normal-table">
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_producttypelist" optionLabel="producttypename"
                                        formControlName="producttypeid" optionValue="producttypeid"
                                        (onChange)="getProductCategory($event)" [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type Name </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                                        formControlName="productcategoryid" optionValue="productcategoryid"
                                        (onChange)="getProduct($event)" [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category Name</label>
                                </span>
                            </td>

                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productlist" optionLabel="productname"
                                        [selectionLimit]="10" formControlName="productid" optionValue="productid"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>

                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_levels" name="levelid"
                                        formControlName="level" optionLabel="levelname" optionValue="levelid">
                                    </p-dropdown>
                                    <label for="country"> Markup Level </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_currencies" name="currencyid"
                                        formControlName="currency" optionLabel="currencyname" optionValue="currencyid">
                                    </p-dropdown>
                                    <label for="country"> Currency </label>
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <!-- <td style="width: 20%;">
                                <div class="p-formgroup-inline">
                                    <h6>Print Type <span class="hlt-txt">*</span></h6>
                                    <div class="p-field-radiobutton" *ngFor="let PF of _printtype">
                                        <p-radioButton name="printtype" label={{PF.name}} formControlName="printtype"
                                            value={{PF.id}}>
                                        </p-radioButton>
                                    </div>
                                </div>
                            </td> -->
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false"  [options]="_printtypelist" name="value"
                                        optionValue="value" optionLabel="printtype" formControlName="printtype">
                                    </p-dropdown>
                                    <label for="printtype">Print Type <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox name="includevat" binary="true" formControlName="includevat">
                                        </p-checkbox>
                                        <label for="includevat">Include VAT</label>
                                    </div>
                                </div>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" formControlName="quantity" name="quantity" maxlength="3"
                                        pInputText>
                                    <label for="quantity">Numbers </label>
                                </span>
                            </td>
                           
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Print" icon="las la-print"
                                    (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)"
                                    class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
            </div>
        </div>
    </div>

</div>
<p-toast position="bottom-right"></p-toast>