import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class CustomExceptionService implements ErrorHandler {

  MsgInterval: NodeJS.Timeout;

  constructor(private messageService: MessageService) { }
  handleError(error) {
    console.error(error);
    var errMsg;

    if (error instanceof Response) {
      const body = error.json() || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    }
    else if (error instanceof HttpErrorResponse)
      errMsg = this.getServerErrorMessage(error);
    else {
      errMsg = error.message ? error.message : error.toString();
    }
    this.messageService.add({ severity: 'error', summary: 'Error Message', sticky: true, detail: errMsg });
    this.clearMessageService();
  }

  handleSuccess(error) {
    console.error(error);
    var errMsg;

    if (error instanceof Response) {
      const body = error.json() || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    }
    else if (error instanceof HttpErrorResponse)
      errMsg = this.getServerErrorMessage(error);
    else {
      errMsg = error.message ? error.message : error.toString();
    }
    this.messageService.add({ severity: 'success', summary: 'Success Message', sticky: true, detail: errMsg });
    this.clearMessageService();
  }


  clearMessageService() {
    //if (!this.MsgInterval) {
      //this.MsgInterval = 
      setTimeout(() => {
        this.messageService.clear();
        //clearInterval(this.MsgInterval);
        //this.MsgInterval = null;
      }, 8000);
    // } else {
    //   clearInterval(this.MsgInterval);
    //   this.MsgInterval = null;
    // }
  }

  handleWarning(error) {
    console.error(error);
    var errMsg;

    if (error instanceof Response) {
      const body = error.json() || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    }
    else if (error instanceof HttpErrorResponse)
      errMsg = this.getServerErrorMessage(error);
    else {
      errMsg = error.message ? error.message : error.toString();
    }
    this.messageService.add({ severity: 'warn', summary: 'Warning Message', sticky: true, detail: errMsg });
    this.clearMessageService();
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }
}
