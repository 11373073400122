import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import { RptpurchasereturnService } from 'src/app/core/Services/reports/rptpurchasereturn/rptpurchasereturn.service';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Producttype } from 'src/app/core/Models/Producttype';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-purchasereturn',
  templateUrl: './purchasereturn.component.html',
  styleUrls: ['./purchasereturn.component.css']
})
export class PurchasereturnComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _supplierlist: Supplier[];
  _branchlist: Branch[];
  _productlist: Productoverallview[];
  _currencylist: Currency[];
  _productcategorylist: Productcategory[];
  _purchasereturnform: FormGroup;
  _branchid = sessionStorage["currentbranchid"];;
  _userid: number;
  _producttypelist: Producttype[] = [];
  _PurchaseReturnPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  _src: SafeResourceUrl;
  _Action: IAction;
  mindate: Date;
  maxdate: Date;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptPurchaseReturnService: RptpurchasereturnService,
    private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(92);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._purchasereturnform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      productid: [0],
      currencyid: [0],
      productcategoryid: [0]
    })
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._purchasereturnform.get("fromdate").setValue(new Date);
      this._purchasereturnform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._purchasereturnform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._purchasereturnform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._RptPurchaseReturnService.PageOnload(this._branchid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._supplierlist = resultJSON.supplierList;
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._producttypelist = resultJSON.producttypeList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._purchasereturnform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._purchasereturnform.get("fromdate").setValue(new Date);
      this._purchasereturnform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._purchasereturnform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._purchasereturnform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  GenerateReport(event) {
    var _fromdate = this._purchasereturnform.get("fromdate").value;
    var _todate = this._purchasereturnform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    var supplierid = this._purchasereturnform.get("supplierid").value == null ? 0 : this._purchasereturnform.get("supplierid").value;
    var branchid = this._purchasereturnform.get("branchid").value == null ? 0 : this._purchasereturnform.get("branchid").value;
    this._PurchaseReturnPrintObj = { "Productcategory": this.productcatid || [], "Producttype": this.producttypeid, "Productheader": this.productid, "branchid": branchid || parseInt(sessionStorage["currentbranchid"]), "SupplierId": supplierid, "Userid": parseInt(sessionStorage["userid"]), "FromDate": fromdate, "ToDate": todate };
    this._RptPurchaseReturnService.Print(this._PurchaseReturnPrintObj).subscribe((result) => {
      if (result.type != mediaType) {
        let reader = new FileReader();
        var blob = new Blob([result], { type: 'application/json' });
        let message = reader.readAsText(blob);
        return;
      }
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, async (error) => { const message = await error.error.text(); this._IsProgressSpinner = false; this._CustomExceptionService.handleError(message); });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptPurchaseReturnService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("category", resultJSON);
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptPurchaseReturnService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
}