import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class RptsalessummaryService {

  public _Action: IAction;
  public _httpOptions;
  public _RptSalesSummaryService = environment.RptSalesSummaryReport;
  constructor(private http: HttpClient) { }
  
  PageOnload(userid: number): Observable<any> {
    var Query = this._RptSalesSummaryService + '/PageInitialize' + '/' + userid;
    
    return this.http.get<{ data: any }>(Query); 
  }
  Print(salesreturn:object) {
    var Query = this._RptSalesSummaryService ;
    
    return this.http.post(Query,salesreturn, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }
}
