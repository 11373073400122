<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_taxform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Tax</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <!-- //5. save, clear button must be enabled. -->
                                <!-- 26.save , clear  buttons must be enabled  -->
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save" class=""
                                    [disabled]="_SaveHide"></button>
                                <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                    icon="pi pi-trash" class="" class="p-button-danger" [disabled]="_ClearHide"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                    title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                                <!-- <button pButton pRipple label="" icon="pi pi-times" class="" (click)="hideDialog()" ></button>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>

                    <table class="normal-table">
                        <tr>

                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="taxcode" pInputText formControlName="taxcode">
                                    <label for="taxcode">Tax Code <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _taxform.get('taxcode').touched) && _taxform.get('taxcode').errors?.TaxCodeRequired">
                                    Please Enter Tax Code
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="taxname" pInputText formControlName="taxname">
                                    <label for="taxname">Tax Name <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _taxform.get('taxname').touched) && _taxform.get('taxname').errors?.TaxNameRequired">
                                    Please Enter Tax Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" name="taxpercentage" pInputText formControlName="taxpercentage"
                                        suffix="%">
                                    <label for="taxpercentage">Tax Percentage <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _taxform.get('taxpercentage').touched) && _taxform.get('taxpercentage').errors?.TaxPercentageRequired">
                                    Please Enter Tax Percentage
                                </span>
                            </td>


                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="effectivefrom" formControlName="effectivefrom" [showIcon]="true"
                                    [monthNavigator]="true" [yearNavigator]="true"  yearRange="1980:2030"
                                    (onSelect)="OnFromDateSelect($event)" [readonlyInput]="true" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="effectivefrom">Effective From <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _taxform.get('effectivefrom').touched) && _taxform.get('effectivefrom').errors?.EffectiveFromRequired">
                                    Please Select Effective From
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="effectiveto" formControlName="effectiveto" [showIcon]="true"
                                        [minDate]="minDate" [readonlyInput]="true" dateFormat="dd/mm/yy"  yearRange="1980:2030"
                                        [monthNavigator]="true" [yearNavigator]="true">
                                    </p-calendar>
                                    <label for="effectiveto">Effective To </label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _taxform.get('effectiveto').touched) && _taxform.get('effectiveto').errors?.EffectiveToRequired">
                                    Please Select Effective To
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!-- //4. status should be disabled. -->
                                    <p-dropdown [options]="_taxstatus" formControlName="taxstatus"
                                        optionLabel="metasubdescription" optionValue="metasubcode" name="taxstatus">
                                    </p-dropdown>
                                    <label for="taxstatus">Status</label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _taxform.get('taxstatus').touched) && _taxform.get('taxstatus').errors?.SelectStatus">
                                    Please Select Status
                                </span>
                            </td>
                            <td style="width: 20%;">

                                <!-- Debug -->

                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>


                            </td>
                            <td style="width: 20%;"></td>
                        </tr>

                    </table>

                </div>
            </form>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>