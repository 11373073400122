import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { ServiceWorkOrderService } from 'src/app/core/Services/workshop/service-work-order.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-service-work-order',
  templateUrl: './v-service-work-order.component.html',
  styleUrls: ['./v-service-work-order.component.css']
})
export class VServiceWorkOrderComponent implements OnInit {
  _Action: IAction;
  selectedrows: any;
  _Serviceworkorderviews: any[] = []
  _IsProgressSpinner: boolean = true;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _branchid: number;
  cols: any[];
  _roleid = sessionStorage["currentRole"]
  _userid = sessionStorage["userid"];
  mindate: Date;
  maxdate: Date;
  constructor(
    private _CustomExceptionService: CustomExceptionService,
    private _serviceworkorderService: ServiceWorkOrderService,
    private router: Router,
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this.fromdate = new Date();
    this.todate = new Date();
    this._Action = this._AccessRightsService.getAccessRights(331);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.SetFromtoDate();
    this.utility.pageLoadScript();
    this._IsProgressSpinner = false;
    this.cols = [
      { field: 'serviceworkorderno', header: 'Service Work order Code' },
      { field: 'serviceworkorderdate', header: 'Service Work order Date' },
      { field: 'jobcardno', header: 'Job Card Code' },
      { field: 'jobcardtypename', header: 'Service At' },
      { field: 'servicerequestno', header: ' Service Request No' },
      { field: 'employeeorsuppliername', header: 'Employee/Supplier Name' },
      { field: 'statusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'CREATEDON', header: 'Created On' },
    ]
    this.Fetchall();
  }
  SetFromtoDate(){
    if(sessionStorage['Environmentenddate'] == 'null'){
			this.fromdate = new Date();
			this.todate = new Date();
			this.maxdate = new Date();
		}
		else{
			this.fromdate = new Date(sessionStorage['Environmentenddate'])
			this.todate = new Date(sessionStorage['Environmentenddate'])
		}
		this.fromdate.setDate(this.fromdate.getDate() - 1);
	}
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    let data = {
      "fromdate": fromdate,
      "todate": todate,
      "branchid": this._branchid
    }
    this._IsProgressSpinner = true;
    this._serviceworkorderService.FetchAll(data).subscribe((result) => {
      this._IsProgressSpinner = false;
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._Serviceworkorderviews = resultJSON.serviceWorkOrderView;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/CServiceWorkOrder'], { state: { serviceworkorderid: event.serviceworkorderid, action: 'view' } });
  }
  edit(event) {
    this.router.navigate(['/CServiceWorkOrder'], { state: { serviceworkorderid: event.serviceworkorderid, action: 'edit' } });
  }
  SendToApproval(event) {
    let data = {
      "ServiceWorkOrderId": event.serviceworkorderid,
      "UserId": sessionStorage["userid"],
      "BranchId": sessionStorage["BranchID"],
    }
    this._IsProgressSpinner = true;
    this._serviceworkorderService.SendToApproval(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
      this._IsProgressSpinner = false;
      this.Fetchall();
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GoToCreate(event) {
    this.router.navigate(['/CServiceWorkOrder']);
  }
  exportExcel() {
    let purchaseorderList = this.GetPurchaseorderList();
    this.exportUtility.exportExcel(purchaseorderList, 'Service_work_order', 'xlsx');
  }
  ExportToPdf() {
    if (!this._Serviceworkorderviews || !this._Serviceworkorderviews.length)
      return;
    let employeeList = this.GetPurchaseorderList();
    const data = employeeList.map(Object.values);
    const head = [[
      'Service Work order Code',
      'Service Work order Date',
      'Job Card Code',
      'Service At',
      'Service Request No',
      'Employee/Supplier Name',
      'Status',
      'Created By',
      'Created On'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Service_work_order.pdf');
      })
    })
  }
  GetPurchaseorderList() {
    let purchaseorderList = [];
    this._Serviceworkorderviews.forEach(element => {
      let purchaseorder: any = {};
      purchaseorder["Service Work order Code"] = element.serviceworkorderno;
      purchaseorder["Service Work order Date"] = this.datepipe.transform(element.serviceworkorderdate, 'dd/MM/yyyy');
      purchaseorder["Job Card Code"] = element.jobcardno;
      purchaseorder["Service At"] = element.jobcardtypename;
      purchaseorder["Service Request No"] = element.servicerequestno;
      purchaseorder["Employee/Supplier Name"] = element.employeeorsuppliername;
      purchaseorder["Status"] = element.statusname;
      purchaseorder["Created By"] = element.createdbyname;
      purchaseorder["Created On"] = this.datepipe.transform(element.CREATEDON, 'dd/MM/yyyy');
      purchaseorderList.push(purchaseorder);
    });
    return purchaseorderList;
  }
  Print(event) {
    this._IsProgressSpinner = true;
    this._serviceworkorderService.Print({ "ServiceWorkOrderId": event.serviceworkorderid, "BranchId": this._branchid }).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}