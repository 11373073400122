import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Tender } from 'src/app/core/Models/Tender';
import { Tenderdetailview } from 'src/app/core/Models/Tenderdetail';
import { Tenderdocumentdetail } from 'src/app/core/Models/Tenderdocumentdetail';
import { Tenderhistory } from 'src/app/core/Models/Tenderhistory';
import { Tenderresource } from 'src/app/core/Models/Tenderresource';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Employee } from 'src/app/core/Models/Employee';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { vTenderStatus, vTenderPartyName, vTenderReferenceDate, vTenderReferenceNo, vProductName, vQuantity, vUOMSelect, vAttachmentRequired, vAttachmentDescription, vCurrencySelect, vProductNameSelect, vDocumentName } from 'src/app/core/Validators/validation';
import { Currency } from 'src/app/core/Models/Currency';
import { products, varient } from 'src/app/core/Models/product-master';
import { TenderService } from 'src/app/core/Services/masters/tender.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { DatePipe } from '@angular/common';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { FileUpload } from 'primeng/fileupload';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { KeyPress } from 'src/assets/js/KeyPress';
import { Documentdescription } from 'src/app/core/Models/DocumentDescription';
@Component({
  selector: 'app-c-tender',
  templateUrl: './c-tender.component.html',
  styleUrls: ['./c-tender.component.css']
})

export class CTenderComponent implements OnInit {
  filteredProducts: any[];
  // Form Gropu Declaration:
  _tenderform: FormGroup;
  _tenderresourceform: FormGroup;
  _tenderdetailform: FormGroup;
  _tenderproductform: FormGroup;
  _tenderdocumentdetailform: FormGroup;
  _tenderhistoryform: FormGroup;
  productsearchview: Productoverallview[];
  Tempproductsearchview: Productoverallview[];
  addressRowIndex: number = -1;
  // List Declaration:
  _tender: Tender;
  _producttype: Producttype;
  _productcategory: Productcategory[];
  _productname: Productheader[];
  _productoverallview: Productoverallview[];
  _tenderproductdetails: Tenderdetailview[] = [];
  _tenderhistory: Tenderhistory[] = [];
  _tenderdocumentdetail: Tenderdocumentdetail[] = [];
  _resourceslist: Tenderresource[] = [];
  _resourceslists: Tenderresource[] = [];
  _preresourceslists: Tenderresource[] = [];
  _tenderstatus: Metadatum;
  _tenderstatusdetail: Metadatum;
  _uomdetails: Metadatum[];
  _currency: Currency[] = [];
  _CurrenciesTemp: Currency[];
  _productdetails: Productheader[];
  _products: Productheader[];
  _variantdetails: Productvariant[];
  _tenderdetail: Tenderdetailview[];
  _DocumentDescriptions: Documentdescription[];
  _tenderOBJ: any;
  _tenderFORM: FormData;
  productuom: any;
  productcatid: any[] = [];
  producttypeid: any[] = [];
  documentimage: SafeUrl;
  documentselect: string = "";
  //File Clear:
  @ViewChild('fileUploader') fileUploader: ElementRef;
  document: any;
  _Action: IAction;
  //Local Variable Declaration:
  _action: string = "create";
  _tenderid: number;
  _submitted = false;
  _submitted1 = false;
  _submitted2 = false;
  _validate: boolean;
  displaydocumentpopup: boolean;
  _LastAddedDocument: File;
  _selectedDocuments: File[] = [];// For Documents uploads
  _selectedResourceIds: number[] = [];
  isDisabledsave = false;
  isDisabledreset = false;
  isDisabledclear = false;
  isDisabledadd = false;
  _IsProgressSpinner: boolean = true;
  isDisabledadddocument = false;
  isDisabledcleardocument = false;
  isDisabledaddtenderhistory = false;
  isDisabledcleartenderhistory = false;
  isDisabledtenderdescription = false;
  isDisabledstartdate = false;
  isDisabledenddate = false;
  isDisabledremovedocument = false;
  isDisabledproductremove = false;
  isDisabledlink = false;
  isDisabledtenderstatus = false;
  _LastAddedGridDocument: File;
  displaydocumentgridpopup: boolean = false;
  _Branchid = sessionStorage["currentbranchid"]
  position: string;
  _userid: number = parseInt(sessionStorage["userid"]);
  isdisableduom = true;
  decimalValue: number | null = null;
  

  constructor(private utility: CommonUtilities, private _TenderService: TenderService, private _DomSanitizationService: DomSanitizer, private fb: FormBuilder, private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService, public keypress: KeyPress) {
    this._Action = this._AccessRightsService.getAccessRights(84);
    this._tenderid = history.state?.tenderid ? history.state?.tenderid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }

  InitializeForm() {
    // Tender Form Creation:
    this._tenderform = this.fb.group({
      companyid: [0],
      branchid: [sessionStorage["currentbranchid"]],
      tenderid: [0],
      currencyid: ['', vCurrencySelect],
      tenderrefno: ['', vTenderReferenceNo],
      tenderrefdate: [(new Date()), vTenderReferenceDate],
      customername: ['', vTenderPartyName],
      enddate: [],
      tenderamount: [],
      tenderdescription: [],
      tenderstatusid: ['', vTenderStatus],
      tenderstatusdesc: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      accountingyear: [],
      amountinusd: [],
      currencyrefid: [],
      //Included Only For Update Pageload:
      tenderdetails: [],
      tenderdocumentdetails: [],
      tenderhistories: [],
      tenderresources: []
    });
    this._tenderform.controls['currencyid'].setValue(1);

    //Tender Resour Form Creation:
    this._tenderresourceform = this.fb.group({
      companyid: [],
      branchid: [],
      tenderresourceid: [],
      tenderid: [],
      resourceid: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      accountingyear: []
    });
    //this._tenderresourceform.controls['currencyid'].setValue("USD");


    // Tender History Form Creation:
    this._tenderhistoryform = this.fb.group({
      tenderhistoryid: [],
      tenderid: [],
      tenderdescription: [],
      startdate: [],
      enddate: [],
      tenderstatusid: [],
      tenderstatusdesc: [],
      createdby: [],
      createdon: [],
      modifiedby: [0],
      modifiedon: [],
      accountingyear: []
    });
    this.InitializeDocumentForm();
    this.InitializeDetailForm();
  }

  InitializeDetailForm() {
    //Tender Detail Form Creation:
    this._tenderdetailform = this.fb.group({
      tenderdetailid: [],
      tenderid: [],
      producttypeid: [],
      productcategoryid: [],
      productid: ['', vProductNameSelect],
      productname: [],
      variantdescription: [],
      variantid: [0],
      uomname: [],
      quantity: ['', vQuantity],
      uom: [],
      createdby: [],
      createdon: [],
      modifiedby: [0],
      modifiedon: [],
      accountingyear: []
    });
  }

  InitializeDocumentForm() {
    //Tender Document Form Creation:
    this._tenderdocumentdetailform = this.fb.group({
      tenderdocumentdetailid: [0],
      tenderid: [0],
      documentdescription: [],
      attachmenturl: [],
      attachmentname: [],
      createdby: [],
      createdon: [],
      modifiedby: [0],
      modifiedon: [],
      accountingyear: [],
      documentname: ['', vDocumentName],
    });

  }
  ngOnInit(): void {
    //To Avoid BlankScreen On Pageload Below Line Used:
    this.utility.pageLoadScript();
    this.InitializeForm();
    this.InitializeDocumentForm();
    // Page Load Service Call:
    //For Page Load  

    this._IsProgressSpinner = true;

    this._TenderService.PageOnload(this._tenderid, this._Branchid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("resultjson", resultJSON);
      this._resourceslist = resultJSON.employees;
      this._currency = resultJSON.currencies;
      this._tenderstatus = resultJSON.tenderstatus;
      this._tenderstatusdetail = resultJSON.tenderstatusdetails;
      this._producttype = resultJSON.productTypes;
      this._uomdetails = resultJSON.uomdetails;
      this._CurrenciesTemp = resultJSON.currencies || [];
      this.productsearchview = <Productoverallview[]>resultJSON.productSearch || [];
      this.Tempproductsearchview = <Productoverallview[]>resultJSON.productSearch || [];
      this._DocumentDescriptions = resultJSON.documentdescriptions;
      this.isDisabledsave = false;
      this.isDisabledreset = false;
      this._IsProgressSpinner = false;
      this.isDisabledadd = false;
      this.isDisabledclear = false;
      this.isDisabledadddocument = false;
      this.isDisabledcleardocument = false;
      this.isDisabledaddtenderhistory = false;
      this.isDisabledcleartenderhistory = false;
      this.isDisabledremovedocument = false;
      this.isDisabledproductremove = false;
      this.isDisabledtenderstatus = true;
      this.isDisabledlink = false;



      // For Create:
      if (this._action == 'create') {
        var l_tenderhistory = new Tenderhistory();
        l_tenderhistory.startdate = new Date();
        l_tenderhistory.enddate = new Date();
        l_tenderhistory.tenderstatusid = 0;
        l_tenderhistory.tenderstatusdesc = "";
        this._tenderhistory.push(l_tenderhistory);
      }

      // For Update And View:
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));


        if (updateJSON.tenders[0].enddate == null) {
          // updateJSON.tenders[0].enddate = new Date(null);
          updateJSON.enddate = new Date(null);
          // this._tenderform.get("enddate").setValue(null)
        }
        else {
          updateJSON.tenders[0].enddate = new Date(updateJSON.tenders[0].enddate);
        }
        updateJSON.tenders[0].tenderrefdate = new Date(updateJSON.tenders[0].tenderrefdate);

        this._tenderform.setValue(updateJSON.tenders[0]);
        this._tenderproductdetails = updateJSON.tenderdetailviews;
        this._tenderdocumentdetail = updateJSON.tenderdocumentdetails;
        this._tenderhistory = updateJSON.tenderhistories;
        this._resourceslist = updateJSON.employees;
        if (this._tenderhistory.length > 0) {
          this._tenderhistory.forEach(element => {
            element.startdate = new Date(element.startdate);
            element.enddate = new Date(element.enddate);
          });
        }
        //To Make Resource Dropdown Checked:
        this._preresourceslists = updateJSON.tenderresourceviews;
        var resourceIds: number[] = [];
        Object.keys(updateJSON.tenderresourceviews).map((Index) => {
          resourceIds.push(updateJSON.tenderresourceviews[Index].resourceid);
        });
        this._selectedResourceIds = resourceIds;
        if (this._tenderhistory.length <= 0) {
          var l_tenderhistory = new Tenderhistory();
          l_tenderhistory.startdate = new Date();
          l_tenderhistory.enddate = new Date();
          l_tenderhistory.tenderstatusdesc = "MSC00178";
          this._tenderhistory.push(l_tenderhistory);
        }
        this.isDisabledsave = false;
        this.isDisabledreset = false;
        this.isDisabledadd = false;
        this.isDisabledclear = false;
        this.isDisabledadddocument = false;
        this.isDisabledcleardocument = false;
        this.isDisabledaddtenderhistory = false;
        this.isDisabledcleartenderhistory = false;


        this.isDisabledremovedocument = false;
        this.isDisabledproductremove = false;
        this.isDisabledlink = false;
        this.isDisabledtenderstatus = true;
        this.isDisabledreset = true;
      }
      //For View:
      if (this._action == 'view') {
        this._tenderform.disable();
        this._tenderresourceform.disable();
        this._tenderdetailform.disable();
        this._tenderdocumentdetailform.disable();
        this._tenderhistoryform.disable();
        this.isDisabledsave = true;
        this.isDisabledreset = true;
        this.isDisabledadd = true;
        this.isDisabledclear = true;

        this.isDisabledadddocument = true;
        this.isDisabledcleardocument = true;
        this.isDisabledaddtenderhistory = true;
        this.isDisabledcleartenderhistory = true;
        this.isDisabledtenderdescription = true;
        this.isDisabledstartdate = true;
        this.isDisabledenddate = true;
        this.isDisabledremovedocument = true;
        this.isDisabledproductremove = true;
        this.isDisabledlink = true;
        this.isDisabledtenderstatus = false;
      }
    });
  }

  // Currency change event:
  ChangeCurrencies(): void {
    var currencyId = parseInt(this._tenderform.get("currencyid").value);
    if (!currencyId)
      return;
    let tenderAmount = parseFloat(this._tenderform.get("tenderamount").value || 0);
    var usd_exchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == 1)[0].exchangerate;
    var exchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == currencyId)[0].exchangerate;
    let totalamount = 0;
    if (currencyId == 1)
      totalamount = tenderAmount;
    else
      totalamount = (usd_exchangerate / exchangerate) * tenderAmount;

    this._tenderform.controls['amountinusd'].setValue(totalamount.toFixed(3));
  }

  //Resource selection Multiselect:
  onSelectResources(event) {
    var l_resourcelist: Tenderresource[] = [];
    var l_resource: Tenderresource;
    Object.keys(event.value).map((Index) => {
      l_resource = new Tenderresource();
      l_resource.companyid = 0;
      l_resource.branchid = 0;
      l_resource.tenderresourceid = 0;
      l_resource.tenderid = event.value[Index];
      l_resource.resourceid = event.value[Index];
      l_resource.createdon = new Date();
      l_resource.createdby = sessionStorage["userid"];;
      l_resource.modifiedby = sessionStorage["userid"];;
      l_resource.modifiedon = new Date();
      l_resource.accountingyear = new Date().getFullYear();
      l_resourcelist.push(l_resource);
    })
    this._resourceslists = l_resourcelist;
  }
  // Clear Product
  onClearProduct(event) {
    this._submitted1 = true;
    this._tenderdetailform.reset();
    this.InitializeDetailForm();
    this._productcategory = [];
    this.producttypeid = [];
    this.productcatid = [];
    this._submitted1 = false;

  }
  filterProducts(event) {
    let filtered: any[] = [];
    let query = (<string>event.query).toLowerCase();
    this.filteredProducts = this.productsearchview.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
  }
  //Product Type Multiselect:
  getProductCategory(event) {
    var selectedtype = event.value;
    if (selectedtype.length > 0) {
      this._IsProgressSpinner = true;
      this._TenderService.getProductCategory(selectedtype, this._Branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategory = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    let productList = this.Tempproductsearchview.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
    this.productsearchview = productList.length ? productList : this.Tempproductsearchview;
  }

  //Product Category Multiselect:
  getProduct(event) {
    var selectedCategory = event.value;
    if (selectedCategory.length > 0) {
      this._IsProgressSpinner = true;
      this._TenderService.getProduct(selectedCategory, this._Branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this.productsearchview = <Productoverallview[]>resultJSON.productSearch || [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    let productList = [];
    if (this.productcatid.length) {
      productList = this.Tempproductsearchview.filter(f => this.producttypeid.includes(f.producttypeid) && this.productcatid.includes(f.productcategoryid)) || [];
    } else {
      productList = this.Tempproductsearchview.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
    }
    this.productsearchview = productList;
  }

  // Get Product
  getProductVariant(event) {
    var selectedProduct = event.productid;

    if (selectedProduct) {
      this._IsProgressSpinner = true;
      this._TenderService.getProductVariant(selectedProduct, this._Branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._producttype = resultJSON.productTypes;
        this._productcategory = resultJSON.productcategories;
        this._variantdetails = resultJSON.productvariant;
        // this._producttype = resultJSON.producttype.producttypeid;
        // this._producttype = resultJSON.producttype.producttypeid;
        let product = this.Tempproductsearchview.filter(f => f.productid == selectedProduct)[0];
        //if ((this.producttypeid || []).length == 0) {
        this.producttypeid = [];
        this.producttypeid.push(product.producttypeid);
        //}

        //if ((this.productcatid || []).length == 0) {
        this.productcatid = [];
        this.productcatid.push(product.productcategoryid);
        //}
        this.productuom = "";
        this.productuom = (product.purchaseuom);
        this._tenderdetailform.controls['producttypeid'].setValue(this.producttypeid);
        this._tenderdetailform.controls['productcategoryid'].setValue(this.productcatid);
        this._tenderdetailform.controls['uom'].setValue(this.productuom);
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  // onRowtenderselect(tenderdetail: Tenderdetailview, rowIndex: number) {
  //   // address.customeraddressdetailid = 0;
  //   //address.createdby = 0;
  //   if (this._action == 'view') {
  //     return
  //   }
  //   this._tenderdetailform.setValue(tenderdetail);
  //   this.addressRowIndex = rowIndex;
  // }
  // Add Products in grid
  onAddProduct(event) {
    this._submitted1 = true;
    if (this._tenderdetailform.valid) {

      // if (this.addressRowIndex > -1) {
      //   this._tenderproductdetails[this.addressRowIndex] = this._tenderdetailform.value;
      //   this._tenderproductdetails[this.addressRowIndex].createdon = new Date();
      // } else {
      var productdetail = new Tenderdetailview();

      // var productdetail = new Tenderdetailview();

      productdetail.tenderdetailid = 0;
      productdetail.tenderid = 0;
      var productid = parseInt(this._tenderdetailform.get("productid").value.productid);
      var productName = (this.Tempproductsearchview || []).filter(f => f.productid == productid)[0].productname;
      productdetail.productid = productid;
      productdetail.productname = productName;
      var variantid = parseInt(this._tenderdetailform.get("variantid").value);
      if (variantid > 0) {
        var variantdescription = (this._variantdetails || []).filter(f => f.productvariantdetailid == variantid)[0].variantdescription;
        productdetail.variantid = variantid;
        productdetail.variantdescription = variantdescription;
      }
      var uomcode = this._tenderdetailform.get("uom").value;
      var uomname = (this._uomdetails || []).filter(f => f.metasubcode == uomcode)[0].metasubdescription;
      productdetail.uomcode = uomcode;
      productdetail.uomname = uomname;
      productdetail.quantity = this._tenderdetailform.get("quantity").value;
      productdetail.createdon = new Date();
      productdetail.createdby = sessionStorage["userid"];
      productdetail.modifiedby = sessionStorage["userid"];
      productdetail.modifiedon = new Date();
      this._tenderproductdetails.push(productdetail);
      this._submitted1 = false;
      this.onClearProduct(event);
    }

  }
  // }




  //Remove Product Detail Grid:
  RemoveProductDetail(_product: Tenderdetailview) {
    var index = this._tenderproductdetails.indexOf(_product);
    this._tenderproductdetails.splice(index, 1)
  }

  // History Grid ADD:
  AddTenderHistory(event) {
    var l_tenderhistory = new Tenderhistory();
    l_tenderhistory.startdate = new Date();
    l_tenderhistory.tenderdescription = "";
    l_tenderhistory.tenderstatusid = 0;
    l_tenderhistory.enddate = new Date();
    l_tenderhistory.modifiedby = sessionStorage["userid"];;
    l_tenderhistory.modifiedon = new Date();
    this._tenderhistory.push(l_tenderhistory);
  }
  // History Grid Remove:
  RemoveTenderHistory(_tender: Tenderhistory) {
    if (this._tenderhistory.length > 1) {
      var index = this._tenderhistory.indexOf(_tender);
      this._tenderhistory.splice(index, 1);
    }

  }

  //Save Function Call:
  onSave() {
    this._submitted = true;
    if (this._tenderform.valid) {
      // by vinitha
      var tenderrefdate = this._tenderform.get("tenderrefdate").value;

      var datePipe = new DatePipe("en-US");
      var ptenderrefdate = datePipe.transform(tenderrefdate, 'yyyy-MM-dd');

      this._tender = this._tenderform.value;
      this._tender.tenderrefdate = (new Date(ptenderrefdate));
      this._IsProgressSpinner = true;
      // end
      //Form & Model Mapping:
      this._tender = this._tenderform.value;
      for (var i = 0; i < this._tenderhistory.length; i++) {
        if (this._tenderhistory.length > 1) {
          this.validate();
          // var description = this._tenderhistory[0].tenderdescription;
          // if (this._tenderhistory[i].tenderdescription == description) {
          //   this._IsProgressSpinner = false;
          //   this._CustomExceptionService.handleWarning(usererrors.Tender01);
          //   return;
          // }
        }
        else {
          if (this._tenderhistory[0].tenderdescription != "" && this._tenderhistory[0].tenderstatusid == 0) {
            this._validate = false;
          }
          else {
            this._validate = true;
          }
        }
      }
      if (this._validate == true) {
        if (this._action == 'create' && this._Action._Add) {
          this._tender.createdby = sessionStorage["userid"];
          if (this._tenderform.get("tenderamount").value == null) {
            this._tender.tenderamount = 0;
          }
          else {

          }
          var tenderrefdate = this._tenderform.get("tenderrefdate").value;
          if (tenderrefdate == null) {
            this._tenderform.get("tenderrefdate").setValue(null);
          }
          else {
            var datePipe = new DatePipe("en-US");
            var peffectiveto = datePipe.transform(tenderrefdate, 'yyyy-MM-dd');
            // this._Role.effectiveto = (new Date(peffectiveto));
            this._tenderform.get("tenderrefdate").setValue(new Date(peffectiveto))
            // this._roleform.get("effectiveto").setValue(this._Role.effectiveto);
          }

          this._tender.createdon = new Date();
          this._tenderFORM = this.formconverstion(this._tender, this._resourceslists, this._tenderproductdetails, this._tenderhistory, this._selectedDocuments, this._tenderdocumentdetail);


          this._TenderService.create(this._tenderFORM).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
              this.isDisabledsave = true;
              setTimeout(() => {
                this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
              }, 1000);
              this._tenderform.reset();
              this.reset(null);
              this._tenderproductdetails = [];
              this._tenderdocumentdetail = [];
              this._tenderhistory = [];
              this.isDisabledsave = false;
              if (this._tenderhistory.length <= 0) {
                var l_tenderhistory = new Tenderhistory();
                l_tenderhistory.startdate = new Date();
                l_tenderhistory.enddate = new Date();
                l_tenderhistory.tenderstatusdesc = "MSC00178";
                this._tenderhistory.push(l_tenderhistory);
              }
            }
            else {
              this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            }
            this._IsProgressSpinner = false;
          }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

        }
        else if (this._action == 'edit' && this._Action._Update) {
          // Debug
          this._tender.modifiedby = sessionStorage["userid"];
          this._tender.modifiedon = new Date();
          this._IsProgressSpinner = true;
          this._tenderFORM = this.formconverstion(this._tender, this._resourceslists, this._tenderproductdetails, this._tenderhistory, this._selectedDocuments, this._tenderdocumentdetail);

          this._TenderService.edit(this._tenderFORM).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
              this.isDisabledsave = true;
              setTimeout(() => {
                this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
              }, 1000);
              // this._tenderform.reset();
              this.isDisabledsave = false;
              // this._tenderproductdetails = [];
              // this._tenderdocumentdetail = [];
              // this._tenderhistory = [];
            }
            else {
              this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            }
            this._IsProgressSpinner = false;
          }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        }
      }
      else {
        this._CustomExceptionService.handleError(usererrors.Tender01);
        this._IsProgressSpinner = false;
        return;
      }

    }

  }

  //Document Validation:
  validate(): boolean {
    for (var i = 0; i < this._tenderhistory.length; i++) {
      if (this._tenderhistory[i].tenderdescription == "" || this._tenderhistory[i].tenderstatusid == 0) {
        this._validate = false;
      }
      else {
        this._validate = true;
      }

    }
    return this._validate;
  }
  //Form Reset:
  reset(event) {
    this._submitted = false;
    this._submitted1 = false;
    this._submitted2 = false;
    this._tenderform.reset();
    this._tenderdetailform.reset();
    this._tenderresourceform.reset();
    this._tenderproductdetails = [];
    this._tenderdocumentdetail = [];
    this._tenderhistory = [];
    this.InitializeForm();
    this.producttypeid = [];
    this._selectedResourceIds = [];
    this.productcatid = [];
    this.AddTenderHistory(event);
  }

  //Search Screen Call:
  goBack(event) {
    this._router.navigate(['/vTender']);
  }

  // Document Upload 
  showdocumentDialog(document: FileUpload, position: string) {
    if (this._LastAddedGridDocument) {
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedGridDocument));
    }
    if (this.documentimage == null) {
      return;
    }
    this.position = position;
    this.displaydocumentgridpopup = true;
  }

  //Document Select:
  // onDocumentSelect(event, document) {
  //   this._LastAddedDocument = event.currentFiles[0];
  //   this._tenderdocumentdetailform.get("attachmentname").setValue(this._LastAddedGridDocument.name);
  //   document.clear();
  // }

  // //Document Clear:
  // onDocumentClear() {
  //   this._submitted2 = false;
  //   this._tenderdocumentdetailform.reset();
  //   this._LastAddedGridDocument = null;
  //   this.documentimage = null;
  //   this.InitializeDocumentForm();

  // }

  // Document Upload 
  onAddDocuments(event) {
    this._submitted2 = true;
    if (this._tenderdocumentdetailform.valid && this._LastAddedGridDocument != null) {
      var documentdetail = new Tenderdocumentdetail();
      documentdetail.attachmentname = this._tenderdocumentdetailform.get("attachmentname").value;
      documentdetail.documentname = this._tenderdocumentdetailform.get("documentname").value;
      documentdetail.documentdescription = this._tenderdocumentdetailform.get("documentname").value;
      documentdetail.tenderdocumentdetailid = 0;
      documentdetail.tenderid = 0;
      documentdetail.attachmenturl = URL.createObjectURL(this._LastAddedGridDocument);
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedGridDocument));
      documentdetail.attachmentname = this._LastAddedGridDocument.name;
      documentdetail.createdby = sessionStorage["userid"];
      documentdetail.modifiedby = sessionStorage["userid"];
      documentdetail.modifiedon = new Date();
      documentdetail.createdon = new Date();
      documentdetail.accountingyear = new Date().getFullYear()
      this._tenderdocumentdetail.push(documentdetail);
      this._selectedDocuments.push(this._LastAddedGridDocument);
      this.TenderClear();


      // this._tenderdocumentdetailform.reset();
      // this._submitted2 = false;
      // this._tenderdocumentdetailform.reset();
      // this._LastAddedGridDocument = null;
      // }
      // else {
      //   this._CustomExceptionService.handleError("Please Attach Document");

    }
  }

  //Remove Document in Grid:
  RemoveDocumentDetail(p_documentDetail: Tenderdocumentdetail) {
    var index = this._tenderdocumentdetail.indexOf(p_documentDetail);
    this._tenderdocumentdetail.splice(index, 1)
  }

  onDocumentTenderSelect(event, document) {
    this._LastAddedGridDocument = event.currentFiles[0];
    this._tenderdocumentdetailform.get("attachmentname").setValue(this._LastAddedGridDocument.name);
    document.clear();
  }

  onDocumentTenderClear() {
    // this._submitted2 = true;
    this._tenderdocumentdetailform.reset();
    this.InitializeDocumentForm();
    this.documentimage = null;
    this._LastAddedGridDocument = null;
    // this._submitted2 = false;

  }

  TenderClear() {
    this._submitted2 = true;
    this._tenderdocumentdetailform.reset();
    this.InitializeDocumentForm();
    this.documentimage = null;
    this._LastAddedGridDocument = null;
    this._submitted2 = false;
  }
  showdocumentGridDialog(position: string, _tenderdocumentdetail: any) {
    if (_tenderdocumentdetail.attachmenturl) {
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(_tenderdocumentdetail.attachmenturl);
    }
    this.position = position;
    this.displaydocumentgridpopup = true;
  }
  closePopupGridDialog(position: string) {
    this.position = position;
    this.displaydocumentgridpopup = false;
  }
  //Form Data Creation:
  formconverstion(p_Tender: Tender, p_Tenderresource: Tenderresource[], p_Tenderdetail: Tenderdetailview[], p_Tenderhistory: Tenderhistory[], documents: File[], p_documentdetails: Tenderdocumentdetail[]) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    for (var x in documents) {
      var index = p_documentdetails.findIndex(obj => obj.attachmentname == documents[x].name);
      if (index != -1) {
        var NewFileName = time.getTime() + '_' + documents[x].name;
        p_documentdetails[index].attachmentname = NewFileName;
        p_documentdetails[index].attachmenturl = NewFileName;
        formData.append('files', documents[x], NewFileName);
      }
    }
    var _tenderOBJ = {
      "Tender": p_Tender, "Tenderresource": p_Tenderresource, "Tenderdetail": p_Tenderdetail,
      "Tenderdocumentdetail": p_documentdetails, "Tenderhistory": p_Tenderhistory
    };
    var Tender = JSON.stringify(_tenderOBJ);
    formData.append('strTender', Tender);
    return (formData);
  }
  //selected rows
  selectedrows() {

  }
  deleteRow(event) {
  }

  // GetIncludesLikeArray<R>(firstArray: any[], secondArray: any[], searchProp: string, searchValue: any) : R[] {
  //   if (!firstArray || !firstArray.length)
  //     return;

  //   if (!secondArray || !secondArray.length)
  //     return;
  //   let arr = [];
  //   for(let i=0;i<firstArray.length;i++){
  //     for(let j=0;j<secondArray.length;j++)
  //     {
  //       if(firstArray[i][searchProp] == searchValue )
  //     }
  //   }

  // }


  OnProductClear($event) {

  }

  validateDecimal(event: KeyboardEvent) {
    const inputValueString = this.decimalValue !== null ? this.decimalValue.toString() : '';

    const regex = /^\d+(\.\d{0,1})?$/;

    if (!regex.test(inputValueString)) {
      this.decimalValue = null; 
    }
  }


  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}
