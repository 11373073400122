<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Bank Reconciliation </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" (click)="onSave()" icon="pi pi-save" [disabled]="_IsSaveDisable"></button>
                            <button pButton pRipple title="Clear" icon="pi pi-trash" class="p-button-danger" (click)="Clear($event)" [disabled]="_IsClearDisable"></button>
                            <button pButton pRipple icon="pi pi-search" title="Back to Search" class="p-button p-button-success p-mr-2" (click)=" goBack($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast></p-toast>

                <table class="normal-table" [hidden]="_action == 'view'">
                    <tr>
                        <td style="width: 20%;" *ngIf="false">
                            <form [formGroup]="_banknameform">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_branches"
                                        optionLabel="branchname" optionValue="branchid" formControlName="branchid"
                                        (onChange)="OnChangeBranch()">
                                    </p-dropdown>
                                    <label for="bankname">Branch <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _banknameform.get('branchid').touched) && _banknameform.get('branchid').errors?.SelectBankName">
                                    Please Select Branch.
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="_fromdate" [showIcon]="true"
                                    [monthNavigator]="true" readonlyInput="true" [yearNavigator]="true" 
                                    yearRange="1980:2060" [minDate]="minDate" [maxDate]="maxdate"
                                    dateFormat="dd/mm/yy" (onSelect)="ChangeFromDate($event)" dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="fromdate">From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="_todate" [showIcon]="true"
                                    [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                                    readonlyInput="true" [minDate]="_fromdate"  [maxDate]="maxdate" readonlyInput="true" 
                                    yearRange="1980:2060">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_banknameform">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_banknames" optionLabel="name"
                                        [filter]="true" optionValue="ledgerid" formControlName="bankname"
                                        (onChange)="ChangeCurrentBalance($event)">
                                    </p-dropdown>
                                    <label for="bankname">Bank Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _banknameform.get('bankname').touched) && _banknameform.get('bankname').errors?.SelectBankName">
                                    Please Select Bank Name
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;"></td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Search" (click)="SearchBankReconsilization()" type="button" title="Search" icon="pi pi-search"></button>
                            <button pButton pRipple label="Clear" title="Clear" (click)="Clear($event)" icon="pi pi-times" class="p-button-danger"></button>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>

                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_Bankreconciliations" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['paymenttypename','paymentdate','referenceno','debitamount','creditamount','reconcilationdate','transactionRefno']"
                        [rowHover]="true" dataKey="referenceno" [(selection)]="selectedReconciliationRows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="paymenttypename">Payment Type & Receipt Type
                                    <p-sortIcon field="paymenttypename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="paymentdate">Date
                                    <p-sortIcon field="paymentdate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="referenceno">Bill Reference No.
                                    <p-sortIcon field="referenceno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="referenceno">Bank Reference No.
                                    <p-sortIcon field="referenceno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="debitamount">Debit Amount
                                    <p-sortIcon field="debitamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="creditamount">Credit Amount
                                    <p-sortIcon field="creditamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="reconcilationdate">Reconcilation Date
                                    <p-sortIcon field="reconcilationdate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="reconcilationdate" [hidden]="_action == 'view'">Is Reconciled
                                    <p-sortIcon field="reconcilationdate"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_bankreconciliation let-ri="rowIndex">
                            <tr>
                                <td>
                                    {{_bankreconciliation.paymenttypename}}
                                </td>
                                <td>
                                    {{_bankreconciliation.paymentdate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_bankreconciliation.referenceno}}
                                </td>
                                <td>
                                    {{_bankreconciliation.transactionRefno}}
                                </td>
                                <td>
                                    {{_bankreconciliation.debitamount}}
                                </td>
                                <td>
                                    {{_bankreconciliation.creditamount}}
                                </td>
                                <td>
                                    {{_bankreconciliation.reconcilationdate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td [hidden]="_action == 'view'">
                                    <p-tableCheckbox [value]="_bankreconciliation"></p-tableCheckbox>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Summary</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_bankreconciliationform">
                    <table class="normal-table">
                        <tr>

                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" name="reconciliationcredit" disabled
                                        formControlName="reconciliationcredit" pInputText>
                                    <label for="reconciliationcredit">Reconciliation Credit</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" name="reconciliationdebit"
                                        formControlName="reconciliationdebit" disabled pInputText>
                                    <label for="reconciliationdebit">Reconciliation Debit</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" name="unreconciliationcredit" disabled
                                        [hidden]="_action == 'view'" formControlName="unreconciliationcredit"
                                        pInputText>
                                    <label for="unreconciliationcredit" [hidden]="_action == 'view'">UnReconciliation
                                        Credit</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" name="unreconciliationdebit" disabled
                                        [hidden]="_action == 'view'" formControlName="unreconciliationdebit" pInputText>
                                    <label for="unreconciliationdebit" [hidden]="_action == 'view'">UnReconciliation
                                        Debit</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label" [hidden]="_action == 'view'">
                                    <input type="number" name="balanceasperbook" formControlName="balanceasperbook"
                                        disabled pInputText>
                                    <label for="balanceasperbook">Balance As Per Book</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label" [hidden]="_action == 'view'">
                                    <input type="number" name="balanceinbank" formControlName="balanceinbank" pInputText
                                        disabled>
                                    <label for="balanceinbank">Balance In Bank</label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </tr>
                    </table>
                </form>
            </div>
        </div>
    </div>
</div>