import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

// prime ng control
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ChipsModule} from 'primeng/chips';
import { FieldsetModule, } from 'primeng/fieldset';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { HttpClientModule } from '@angular/common/http';
import {TabViewModule} from 'primeng/tabview';
import {EditorModule} from 'primeng/editor';
import { KeyFilterModule } from 'primeng/keyfilter';
import { OnlineOfflineRoutingModule } from './onlineoffline-routing.module';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DbsyncComponent } from './dbsync/dbsync.component';
import { GeneratebackupComponent } from './generatebackup/generatebackup.component';

@NgModule({
  declarations: [DbsyncComponent,GeneratebackupComponent
    
  ],
  providers:[MessageService,ConfirmationService,CustomExceptionService,DatePipe
  
  ],
  imports: [
    CommonModule,
    OnlineOfflineRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChipsModule,
    FieldsetModule,
    AutoCompleteModule,
    OverlayPanelModule,
    CheckboxModule,
    PasswordModule,
    HttpClientModule,
    TabViewModule,
    EditorModule,
    ProgressSpinnerModule,
    KeyFilterModule,
    ProgressBarModule
  ]
})
export class OnlineOfflineModule { }
