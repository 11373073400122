<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_PettycashSettlementform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Petty Cash Issue</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save" [disabled]="_IsSaveDisable"></button>
                                <button pButton pRipple type="button" (click)="reset($event)" title="Clear" icon="pi pi-trash" class="" class="p-button-danger" [disabled]="_IsClearDisable"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search" title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="pettycashrequestno" disabled
                                            [(ngModel)]="pettycashrequestnos" [ngModelOptions]="{standalone: true}">
                                        <label for="pettycashrequestno">Request No <span
                                                class="hlt-txt">*</span></label>
                                    </span>
                                    <button class="p-inputgroup-addon p-button" type="button" (click)="showBasicDialog()" [disabled]="_action == 'edit' || _action == 'view'">
                                        <i class="pi pi-external-link"></i>
                                    </button>
                                </div>
                                <span class="text-danger" *ngIf="(_submitted && !pettycashrequestnos)">
                                    Please Select Request Number
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                        optionLabel="currencyname" (onChange)="ChangeCurrencies($event)"
                                        optionValue="currencyid" name="currentcurrencyid" [disabled]=true
                                        formControlName="currentcurrencyid">
                                    </p-dropdown>
                                    <label for="currentcurrencyid"> Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _PettycashSettlementform.get('currentcurrencyid').touched) && _PettycashSettlementform.get('currentcurrencyid').errors?.SelectCurrency">
                                    Please Select Currency
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="exchangerateinusd"
                                        formControlName="exchangerateinusd" disabled>
                                    <label for="exchangerateinusd">Exchange Rate </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" pInputText name="requestamount" disabled
                                        [(ngModel)]="requestamount" [ngModelOptions]="{standalone: true}">
                                    <label for="requestamount">Request Amount <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Branches"
                                        optionLabel="branchname" optionValue="branchid" name="settlementbranchid"
                                        formControlName="settlementbranchid" [disabled]="true">
                                    </p-dropdown>
                                    <label for="settlementbranchid">Settlement Branch <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _PettycashSettlementform.get('settlementbranchid').touched) && _PettycashSettlementform.get('settlementbranchid').errors?.SelectSettlementBranch">
                                    Please Select Settlement Branch
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="settlementdate" [showIcon]="true" [minDate]="mindate" [maxDate]="maxdate"
                                        formControlName="settlementdate">
                                    </p-calendar>
                                    <label for="settlementdate">Settlement Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _PettycashSettlementform.get('settlementdate').touched) && _PettycashSettlementform.get('settlementdate').errors?.SelectSettlementDate">
                                    Please Select Settlement Date
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Users" optionLabel="username"
                                        optionValue="userid" name="settledby" formControlName="settledby"
                                        [filter]="true" filterBy="username" [showClear]="true" [virtualScroll]="true"
                                        itemSize="30" (onChange)="OnChangeSettlementType()" [disabled]="true">
                                    </p-dropdown>
                                    <label for="settledby">Settled By <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _PettycashSettlementform.get('settledby').touched) && _PettycashSettlementform.get('settledby').errors?.SelectSettlementType">
                                    Please Select Settledby
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <div class="p-formgroup-inline">
                                    <h6>Settlement Type <span class="hlt-txt">*</span></h6>
                                    <div class="p-field-radiobutton" *ngFor="let st of _Settlementtypes">
                                        <p-radioButton name="settlementtype" value="M" formControlName="settlementtype" label={{st.metasubdescription}} value={{st.metasubcode}} (onClick)="OnChangeSettlementType()">
                                        </p-radioButton>
                                    </div>
                                    <span class="text-danger" *ngIf="(_submitted || _PettycashSettlementform.get('settlementtype').touched) && _PettycashSettlementform.get('settlementtype').errors?.SelectSettlementType">
                                        Please Select Settlement Type
                                    </span>
                                </div>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_fromaccountsList"
                                        optionLabel="name" optionValue="ledgerid" name="fromaccount"
                                        formControlName="fromaccount" [virtualScroll]="true" itemSize="30"
                                        [filter]="true" filterBy="name" [showClear]="true"
                                        (onChange)="OnChangeFromAccount()">
                                    </p-dropdown>
                                    <label for="fromaccount">From Account <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _PettycashSettlementform.get('fromaccount').touched) && _PettycashSettlementform.get('fromaccount').errors?.SelectSettlementType">
                                    Please Select From Account
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="fromaccountclosingbalance"
                                        formControlName="fromaccountclosingbalance" disabled>
                                    <label for="fromaccountclosingbalance"> From Account Closing Balance (in usd)
                                    </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText name="settlementamount"
                                        formControlName="settlementamount" (keyup)="getBalanceAmount($event)">
                                    <label for="settlementamount">Settlement Amount <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _PettycashSettlementform.get('settlementamount').touched) && _PettycashSettlementform.get('settlementamount').errors?.SelectSettlementType">
                                    Please Select From Account
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText name="balanceamount" formControlName="balanceamount"
                                        disabled>
                                    <label for="balanceamount">Balance Amount</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText name="transactionrefno"
                                        formControlName="transactionrefno" disabled>
                                    <label for="transactionrefno">Transaction Ref No</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <textarea pInputTextarea name="remarks" formControlName="remarks"></textarea>
                                    <label for="remarks">Remarks</label>
                                </span>
                            </td>
                        </tr>
                    </table>
                    <p-dialog header="Request No" [(visible)]="displayBasic" [modal]="true" [style]="{width: '50vw',height: '500px'}" [baseZIndex]="10000">
                        <ng-template pTemplate="content">
                            <div class="popup-body">
                                <div class="search-container">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <input type="text" pInputText
                                                (input)="dtlist.filterGlobal($event.target.value, 'contains')"
                                                placeholder="Search..." />
                                        </span>
                                    </div>
                                </div>
                                <div class="card">
                                    <p-table #dtlist [value]="_Requests" selectionMode="single" [(selection)]="selectedRequest" (onRowSelect)="onRowSelectPopup($event)" [globalFilterFields]="['pettycashno','requesrpersonname','requestamount','currencyname']" (onRowUnselect)="onRowUnSelectPopup($event)"
                                        [paginator]="true" [rows]="10">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th *ngIf="_action != 'view'"> Action
                                                </th>
                                                <th pSortableColumn="requestno">Request No
                                                    <p-sortIcon field="requestno">
                                                    </p-sortIcon>
                                                </th>
                                                <th pSortableColumn="requestperson">Requested By
                                                    <p-sortIcon field="requestperson">
                                                    </p-sortIcon>
                                                </th>
                                                <th pSortableColumn="requestedbranch">Requested Branch
                                                    <p-sortIcon field="requestedbranch">
                                                    </p-sortIcon>
                                                </th>
                                                <th pSortableColumn="currencyname">Currency Name
                                                    <p-sortIcon field="currencyname">
                                                    </p-sortIcon>
                                                </th>
                                                <th pSortableColumn="requestamount">Requested Amount
                                                    <p-sortIcon field="requestamount">
                                                    </p-sortIcon>
                                                </th>
                                                <th pSortableColumn="requestamount">Balance Amount
                                                    <p-sortIcon field="requestamount">
                                                    </p-sortIcon>
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData let-_Request>
                                            <tr>
                                                <td style="text-align: center" *ngIf="_action != 'view'">
                                                    <p-tableCheckbox [value]="rowData" [disabled]="_Request.disablecheckbox"></p-tableCheckbox>
                                                </td>
                                                <td>{{_Request.pettycashno}}</td>
                                                <td>{{_Request.requesrpersonname}}</td>
                                                <td>{{_Request.requestedbranchname}}</td>
                                                <td>{{_Request.currencyname}}</td>
                                                <td>{{_Request.requestamount}}</td>
                                                <td>{{_Request.balanceamount}}</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <button pButton pRipple label="OK" icon="las la-check" (click)="ok()" class="p-button-sm"></button>
                        </ng-template>
                    </p-dialog>
                    <p-dialog header="Image Preview" [(visible)]="displayBasic1" [position]="position" [style]="{width: '500px'}" [baseZIndex]="10000">
                        <img src="../../assets/images/chart03.jpg" alt="" class="img-preview">
                        <button class="p-inputgroup-addon p-button" title="Delete">
                            <i class="pi pi-trash"></i>
                        </button>
                        <ng-template pTemplate="footer">
                            <p-button icon="pi pi-check" (click)="displayBasic=false" label="Ok" styleClass="p-button-text">
                            </p-button>
                        </ng-template>
                    </p-dialog>
                    <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>