import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import * as ace from "ace-builds";

import { RptproductdeliveryService } from 'src/app/core/Services/reports/Stock/RptProductDelivery/rptproductdelivery.service';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Port } from 'src/app/core/Models/Port';
import { vContainerNo, vDateSelect, vSupplierName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Producttype } from 'src/app/core/Models/Producttype';
import { MetadataMaster } from 'src/app/core/Models/overallclass';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Brand } from 'src/app/core/Models/Brand';

@Component({
  selector: 'app-rptproductdelivery',
  templateUrl: './rptproductdelivery.component.html',
  styleUrls: ['./rptproductdelivery.component.css']
})
export class RptproductdeliveryComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _supplierlist: Supplier[];
  _deliverytypelist: Metadatum[];
  _invoicetypelist: Metadatum[];
  // _portList: Port[];
  _rptproductdeliveryform: FormGroup;
  _branchid: number;
  _userid: number;
  _src: SafeResourceUrl;
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _ProductDeliveryPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  _branchlist: Branch[] = [];
  branchids: number[] = [];
  _brandlist: Brand[] = [];
  brandid: number[] = [];
  mindate: Date;
  maxdate: Date;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _RptProductDeliveryService: RptproductdeliveryService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptproductdeliveryform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      productid: [0],
      brandid: [0],
      currencyid: [0],
      productcategoryid: [0],
      producttypeid: [0],
      invoicetype: [0],
      deliverytype: [0],
      isdelivered: []

    })
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = parseInt(sessionStorage["userid"]);

    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptproductdeliveryform.get("fromdate").setValue(new Date);
      this._rptproductdeliveryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptproductdeliveryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptproductdeliveryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptproductdeliveryform.get("fromdate").value;
    dt.setDate(dt.getDate() - 30);
    this._rptproductdeliveryform.controls["isdelivered"].setValue(true);
    this._rptproductdeliveryform.controls['fromdate'].setValue(dt);
    this._RptProductDeliveryService.PageOnload(this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._supplierlist = resultJSON.supplierList;
      this._deliverytypelist = resultJSON.deliverytypeList;
      this._invoicetypelist = resultJSON.invoicetypeList;
      this._producttypelist = resultJSON.producttypeList;
      this._branchlist = resultJSON.branchList;
      this._brandlist = resultJSON.brandList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  clear(event) {
    //25.If clear clicking all fields should clear in grid
    this._rptproductdeliveryform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptproductdeliveryform.get("fromdate").setValue(new Date);
      this._rptproductdeliveryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptproductdeliveryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptproductdeliveryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptproductdeliveryform.get("fromdate").value;
    dt.setDate(dt.getDate() - 30);
    this._rptproductdeliveryform.controls['fromdate'].setValue(dt);
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this.brandid = [];
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }

  GenerateReport(event) {
    var _fromdate = this._rptproductdeliveryform.get("fromdate").value;
    var _todate = this._rptproductdeliveryform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than to Date");
      return;
    }
    var invoicetype = this._rptproductdeliveryform.get("invoicetype").value == null ? 0 : this._rptproductdeliveryform.get("invoicetype").value;
    var deliverytype = this._rptproductdeliveryform.get("deliverytype").value == null ? 0 : this._rptproductdeliveryform.get("deliverytype").value;
    let isdeleivered = this._rptproductdeliveryform.get("isdelivered").value || false;
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._ProductDeliveryPrintObj = {
      "Productcategory": this.productcatid || [], "Producttype": this.producttypeid, "Productheader": this.productid, "branchid": parseInt(sessionStorage["currentbranchid"]), "InwardType": invoicetype, "DeliveryType": deliverytype, "FromDate": fromdate, "ToDate": todate, "IsDelivery": isdeleivered
      , "userid": this._userid, "branches": (this.branchids || []), "brandid": this.brandid || []
    };
    this._RptProductDeliveryService.Print(this._ProductDeliveryPrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptProductDeliveryService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("category", resultJSON);
        this._productcategorylist = resultJSON.productcategories;
        //this._productcategory = [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptProductDeliveryService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

}
