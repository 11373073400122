import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptoutstandingcreditnoteService {
  public _Action: IAction;
  public _httpOptions;
  public _RptoutstandingcreditnoteService = environment.RptOutStandingCreditNoteService;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnload(outstandingcreditnotereport: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptoutstandingcreditnoteService + '/PageOnLoad';
    return this.http.post<{ data: any }>(Query, outstandingcreditnotereport, { headers });
  }

  OnChangeCustomerType(outstandingcreditnotereport: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptoutstandingcreditnoteService + '/OnChangeCustomerType/';
    return this.http.post<{ data: any }>(Query, outstandingcreditnotereport, { headers });
  }

  Search(outstandingcreditnotereport: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptoutstandingcreditnoteService + '/Search/';
    return this.http.post<{ data: any }>(Query, outstandingcreditnotereport, { headers });
  }
}
