import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { VehicleService } from 'src/app/core/services/masters/vehicle.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';

import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import jsPDF from 'jspdf'
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Vehicleview } from 'src/app/core/Views/Vehicleview';
import autoTable from 'jspdf-autotable';
const doc = new jsPDF()
@Component({
  selector: 'app-v-vehicle',
  templateUrl: './v-vehicle.component.html',
  styleUrls: ['./v-vehicle.component.css']
})
export class VVehicleComponent implements OnInit {
  selectedrows: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _vehicleviews: Vehicleview[];
  _vehicleview: Vehicleview;
  _Action: IAction;
  _IsProgressSpinner: boolean = true;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"] ;
  cols: any[];
  constructor(private fb: FormBuilder, private _VehicleService: VehicleService, private router: Router, private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService,  private _CustomExceptionService: CustomExceptionService,private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(20);
    this.utility.pageLoadScript();
    this.HotKeyIntegration();
   this.FetchAll();
    this.cols = [
      { field: 'vehicleno', header: 'Vehicle No' },
      { field: 'vehicletypename', header: 'Vehicle Type Name' },
      { field: 'capacity', header: 'Capacity' },
      { field: 'capacityuomname', header: 'Capacity UOM Name' },
      { field: 'engineno', header: 'Engine No' },
      { field: 'vehiclestatusname', header: 'Vehicle Status Name' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
      { field: 'modifiedbyname', header: 'Modified By' },
      { field: 'modifiedon', header: 'Modified On' },
    ]
  }
  FetchAll() {
    this._IsProgressSpinner = true;
    this._VehicleService.FetchAll(this._userid,this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._vehicleviews = resultJSON.vehicleviews;
      console.log(this._vehicleviews);
      this._IsProgressSpinner = false;
    
      // this._Add = this._CountryService._Action._Add; 
      // this._delete = this._CountryService._Action._Delete;
      // this._update = this._CountryService._Action._Update;
      // this._View = this._CountryService._Action._View;
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  view(event) {
    this.router.navigate(['/cVehicle'], { state: { vehicleid: event.vehicleid, action: 'view' } });
  }

  delete(event) {
    alert("delete");
  }

  edit(event) {

    // this.router.navigate(['/cCustomer']);
    this.router.navigate(['/cVehicle'], { state: { vehicleid: event.vehicleid, action: 'edit' } });
    // alert("edittest");
  }
  GoToCreate(event) {
    this.router.navigate(['/cVehicle']);
  }

  ChangeStatus(view) {
    let userid = sessionStorage["userid"];
    this._IsProgressSpinner = true;
    this._VehicleService.Cancel(view.vehicleid,userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON && resultJSON.tranStatus.result) {
        this._CustomExceptionService.handleSuccess(usererrors.VehicleDeactive);
        this._IsProgressSpinner = false; 
        this.FetchAll();
      }
      this._IsProgressSpinner = false; 
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  confirm(view) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to inactive this Vehicle <b>"' + view.vehicleno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.ChangeStatus(view);
      }
    });
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    if (!this._vehicleviews || !this._vehicleviews.length)
      return;
    let vehiclelist = this.GetVehicleList();
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(vehiclelist);
      const workbook = { Sheets: { 'Vehicle': worksheet }, SheetNames: ['Vehicle'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Vehicles");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  ExportToPdf() {
    // if (!this._vehicleviews || !this._vehicleviews.length)
    //   return;
    // let VehicleList = this.GetVehicleList();

    // const doc = new jsPDF({ orientation: "landscape",unit:'px'});
    // doc.table(1, 1, VehicleList, this.createHeaders(['Vehicle No', 'Type Name', 'Capacity', 'Capacity UOM'
    //   , 'Engine No', 'Status Name']), { autoSize: true });
    // doc.save('Vehicle.pdf');

    let vehicleList = this.GetVehicleList();
    const data = vehicleList.map(Object.values);
    const head = [['Vehicle No', 'Type Name', 'Capacity','Capacity UOM', 'Engine No', 'Status Name', 'Created By', 'Created On', 'Modified By', 'Modified On']]
    import("jspdf").then (jsPDF =>{
      import("jspdf-autotable").then(x=> {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					}, headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					}, bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
        doc.save('Vehicle.pdf');
      })
    })
  }

  GetVehicleList() {
    let vehicleList = [];
    this._vehicleviews.forEach(element => {
      let vehicle: any = {};
      vehicle["Vehicle No"] = element.vehicleno;
      vehicle["Type Name"] = element.vehicletypename;
      vehicle["Capacity"] = element.capacity;
      vehicle["Capacity UOM"] = element.capacityuomname;
      vehicle["Engine No"] = element.engineno ;
      vehicle["Status Name"] = element.vehiclestatusname;
      vehicle["Created By"] = element.createdbyname;
      vehicle["Created On"] = element.createdon;
      vehicle["Modified By"] = element.modifiedbyname;
      vehicle["Modified On"] = element.modifiedon;
      vehicleList.push(vehicle);
    });
    return vehicleList;
  }

  createHeaders(keys: any[]) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 50,
        align: "center",
        padding: 0
      });
    }
    return result;
  }

}
