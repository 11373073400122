export class Messagetodetail {
    msgtodetailid: number;
    msgid: number;
    toid: number ;
    userbranchid:number;
    isviewed: boolean ;
    recordstatus: string;
    createdby: number ;
    createdon: Date ;
    modifiedby: number ;
    modifiedon: Date ;

}