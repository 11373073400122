import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import { RptsalesquotationService } from 'src/app/core/Services/reports/Sales/RptSalesQuotation/rptsalesquotation.service';
import { Supplier } from 'src/app/core/Models/Supplier';
import { DatePipe } from '@angular/common';
import { Producttype } from 'src/app/core/Models/Producttype';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Brand } from 'src/app/core/Models/Brand';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-rptsalesquotation',
  templateUrl: './rptsalesquotation.component.html',
  styleUrls: ['./rptsalesquotation.component.css']
})
export class RptsalesquotationComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _supplierlist: Supplier[];
  _currencylist: Currency[];
  _invoicetypelist: Metadatum[];
  _branchlist: Branch[];
  _rptsalesquotationform: FormGroup;
  _branchid: number;
  _userid: number;
  _src: SafeResourceUrl;
  _producttypelist: Producttype[] = [];
  _productcategorylist: any[];
  _productlist: any[] = [];
  _SalesQuotationPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  Branchid: number[] = [];
  currencyid: number[] = [];
  _quotationstatuslist: Metadatum[];
  _brandlist: any;
  brandid: number[] = [];
  _ProductObj: any;
  _Tempbrandlist: Brand;
  _userlist: any[] = [];
  _Action: IAction
  userid: any;
  mindate: Date;
  maxdate: Date;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptSalesQuotationService: RptsalesquotationService,
    private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(100);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptsalesquotationform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      productid: [0],
      currencyid: [],
      quotationstatusid: [0],
      productcategoryid: [0],
      producttypeid: [0]
    })
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalesquotationform.get("fromdate").setValue(new Date);
      this._rptsalesquotationform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalesquotationform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalesquotationform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

    this._rptsalesquotationform.controls['currencyid'].setValue(1);
    this._RptSalesQuotationService.PageOnload(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._producttypelist = resultJSON.producttypeList;
      this._quotationstatuslist = resultJSON.quotationStatus;
      this._brandlist = resultJSON.brandList;
      this._Tempbrandlist = resultJSON.brandList;
      this._userlist = resultJSON.userList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._rptsalesquotationform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalesquotationform.get("fromdate").setValue(new Date);
      this._rptsalesquotationform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalesquotationform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalesquotationform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._rptsalesquotationform.controls['currencyid'].setValue(1);
    this.productcatid = [];
    this.productid = [];
    this.Branchid = [];
    this.producttypeid = [];
    this.brandid = [];
    this._brandlist = this._Tempbrandlist
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  ChangeBranch() {
    this.producttypeid = []
    this.productcatid = []
    this.productid = []
    this._productcategorylist = []
    this._productlist = []
  }
  GenerateReport(event) {
    var _fromdate = this._rptsalesquotationform.get("fromdate").value;
    var _todate = this._rptsalesquotationform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var Quotationtype = this._rptsalesquotationform.get("quotationstatusid").value == null ? 0 : this._rptsalesquotationform.get("quotationstatusid").value;
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this._SalesQuotationPrintObj = {
      "Productcategory": this.productcatid || [],
      "Producttype": this.producttypeid || [],
      "Productheader": this.productid || [],
      "Branchids": this.Branchid || [],
      "Currency": this._rptsalesquotationform.get("currencyid").value,
      "Userid": parseInt(sessionStorage["userid"]),
      "FromDate": fromdate,
      "ToDate": todate,
      "QuotationStatus": Quotationtype,
      "Currentbranchid": parseInt(sessionStorage["currentbranchid"]),
      "brandid": this.brandid || [],
      "QuotationCreatedBy": this.userid || 0
    }
    this._RptSalesQuotationService.Print(this._SalesQuotationPrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": this.Branchid };
      this._RptSalesQuotationService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        let productcategory = resultJSON.productcategories;
        let uniqueChars = [...new Set(productcategory)];
        uniqueChars = productcategory.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.productcategoryid === test.productcategoryid
          ));
        this._productcategorylist = uniqueChars;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": this.Branchid };
      this._RptSalesQuotationService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        let products = resultJSON.productoverallList;
        let uniqueproduduct = [...new Set(products)];
        uniqueproduduct = products.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.productid === test.productid
          ));
        this._productlist = uniqueproduduct;
      });
    }
  }
  ChangeProduct(event) {
    var selectedProduct = event.value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._brandlist = this._Tempbrandlist
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductObj = { "Products": selectedProduct };
      this._RptSalesQuotationService.getBrand(this._ProductObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("brand", resultJSON);
        let productbrand = resultJSON.productbrandlist;
        let uniqueChars = [...new Set(productbrand)];
        uniqueChars = productbrand.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.brandid === test.brandid
          ));
        this._brandlist = uniqueChars;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
}