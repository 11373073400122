import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Messageattachment } from 'src/app/core/Models/MessageAttachment';
import { Messageheader } from 'src/app/core/Models/MessageHeader';
import { Messagetodetail } from 'src/app/core/Models/Messagetodetail';
import { NotificationService } from 'src/app/core/Services/masters/notification.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { vBranchMultiNameSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
//Debug
import * as ace from "ace-builds";
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-c-notification',
  templateUrl: './c-notification.component.html',
  styleUrls: ['./c-notification.component.css']
})
export class CNotificationComponent implements OnInit {
  _IsProgressSpinner: boolean = false;
  _Branchslist: any[];
  _UsersList: any[];
  _notificationform: FormGroup;
  imageselect: string;
  _PageOnLoadObj: any;
  userrequired: number = 0;
  _MessageHeader: Messageheader;
  _Messagetodetail: Messagetodetail[] = [];
  _Messageattachment: Messageattachment[] = [];
  _LastAddedDocument: File;
  _selectedDocuments: File[] = [];
  _NotificationObj: any;
  _NotificationForm: FormData;
  AttachmentLink: any[] = [];
  Branchs: string;
  OpenAttachmentPopup: boolean = false;
  position: string;
  Attachment: SafeUrl;
  IsAttachment: boolean = true;
  _submitted = false;
  _userid:number=parseInt(sessionStorage["userid"]);

  //Debug
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private _router: Router, private fb: FormBuilder, private _AccessRightsService: AccessRightsService, private utility: CommonUtilities,
    private _CustomExceptionService: CustomExceptionService, private _DomSanitizationService: DomSanitizer, private _NotificationService: NotificationService, private confirmationService: ConfirmationService, private _hotkeysService: HotkeysService,) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();

    this._notificationform = this.fb.group({
      branchid: ['', vBranchMultiNameSelect],
      userid: ['', vBranchMultiNameSelect],
      msgid: [0],
      fromid: [sessionStorage["userid"]],
      subject: [],
      body: [],
      messagedate: [],
      recordstatus: ["MSC00001"],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: []
    });

    this._PageOnLoadObj = { "Branchid": [], "userrequired": 0,"userid":this._userid };
    this.PageOnload();
  }
  PageOnload() {
    this._IsProgressSpinner = true;
    this._NotificationService.PageOnLoad(this._PageOnLoadObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Branchslist = resultJSON.branchlst;
      this._UsersList = resultJSON.userlst
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getUsernames(event) {
    var selectedBranch = event.value;
    if (selectedBranch.length == 0 || selectedBranch == null) {
      return;
    }
    this._IsProgressSpinner = true;
    this._PageOnLoadObj = { "Branchid": selectedBranch || [], "userrequired": 1,"userid":this._userid  };
    this.PageOnload();
  }

  onDocumentSelect(event, document) {
    this._LastAddedDocument = event.currentFiles[0];
    // Messageattachment
    var l_messageattachment = new Messageattachment();
    l_messageattachment.msgattachmentid = 0
    l_messageattachment.msgid = 0
    l_messageattachment.attachmentname = this._LastAddedDocument.name;
    l_messageattachment.attachmenturl = URL.createObjectURL(this._LastAddedDocument);
    l_messageattachment.recordstatus = "MSC0001"
    l_messageattachment.createdby = sessionStorage["userid"];
    l_messageattachment.createdon = new Date();
    l_messageattachment.modifiedby = sessionStorage["userid"];
    l_messageattachment.modifiedon = new Date();
    l_messageattachment.attachment = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedDocument));
    this._Messageattachment.push(l_messageattachment);
    this.AttachmentLink.push(l_messageattachment);
    if (this.AttachmentLink.length > 0) {
      this.IsAttachment = false;
    }
    this._selectedDocuments.push(this._LastAddedDocument);
    document.clear();
  }

  //Document Clear:
  onDocumentClear() {
    this._LastAddedDocument = null;
  }
  RemoveAttachment(AL) {
    this.AttachmentLink = this.AttachmentLink.filter(item => item.attachment !== AL.attachment);
    this._Messageattachment = this._Messageattachment.filter(item => item.attachment !== AL.attachment);
    this._selectedDocuments = this._selectedDocuments.filter(item => item.name !== AL.attachmentname);
    if (this.AttachmentLink.length == 0) {
      this.IsAttachment = true;
    }
  }
  OpenAttachment(AL, position: string) {
    let url = null;
    if (AL.attachmenturl) {
      this.Attachment = this._DomSanitizationService.bypassSecurityTrustUrl(AL.attachmenturl);
    }

    url = AL.attachmenturl;

    if (!url)
      return;

    window.open(url, "MsgWindow", "width=800,height=800");

  }
  // closeAttachmentPopup(position: string){
  //   this.position = position;
  //   this.OpenAttachmentPopup = false;
  // }


  OnBranchSelect(event) {
    var branchs = "";
    for (var i = 0; i < event.value.length; i++) {
      if (branchs == "") {
        branchs = event.value[i];
      }
      else {
        branchs = branchs + "," + event.value[i];
      }
    }
    this.Branchs = branchs;
  }
  //Messagetodetail
  Messagetodetail(event) {
    this._Messagetodetail = [] = [];
    Object.keys(event.value).map((Index) => {
      var l_messagetodetail = new Messagetodetail();
      l_messagetodetail.msgtodetailid = 0
      l_messagetodetail.msgid = 0
      l_messagetodetail.toid = event.value[Index];
      l_messagetodetail.userbranchid = this._UsersList.filter(f => f.userid == event.value[Index])[0].isdefaultbranchid;
      l_messagetodetail.isviewed = false;
      l_messagetodetail.recordstatus = "MSC0001"
      l_messagetodetail.createdby = sessionStorage["userid"];
      l_messagetodetail.createdon = new Date();
      l_messagetodetail.modifiedby = sessionStorage["userid"];
      l_messagetodetail.modifiedon = new Date();
      this._Messagetodetail.push(l_messagetodetail);
    })

  }
  SendMail() {
    this._submitted = true;
    if (this._notificationform.valid) {
      //For Model and Form Mapping
      this._MessageHeader = this._notificationform.value
      this._MessageHeader.createdon = new Date();
      this._MessageHeader.msgid = 0;
      var MessageDate =  this._notificationform.get("messagedate").setValue(new Date());
      // var datePipe = new DatePipe("en-US");
      // var pMessageDate = datePipe.transform(MessageDate, 'yyyy-MM-ddTHH:mm:ssZ');
      // this._notificationform.get("messagedate").setValue(new Date(pMessageDate));
      this._MessageHeader.messagedate = this._notificationform.get("messagedate").value.toLocaleString();
      this._MessageHeader.branchid = this.Branchs;
      this._MessageHeader.createdby = sessionStorage["userid"];
      this._MessageHeader.fromid = sessionStorage["userid"];
      this._MessageHeader.modifiedby = sessionStorage["userid"];
      this._MessageHeader.modifiedon = new Date();

      this._NotificationForm = this.formconverstion(this._MessageHeader, this._Messagetodetail, this._Messageattachment,
        this._selectedDocuments);
      // Debug
      this.onEd();
      this._IsProgressSpinner = true;
      this._NotificationService.create(this._NotificationForm).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.SendEmail_Success);
          this._IsProgressSpinner = false;
          this.reset();
        }
        else {
          this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          this._IsProgressSpinner = false;
        }

      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  //Form Data Creation:
  formconverstion(_MessageHeader: Messageheader, _Messagetodetail: Messagetodetail[], _Messageattachment: Messageattachment[], documents: File[]) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    for (var x in documents) {
      var index = _Messageattachment.findIndex(obj => obj.attachmentname == documents[x].name);
      if (index != -1) {
        var NewFileName = documents[x].name;
        _Messageattachment[index].attachmentname = NewFileName;
        _Messageattachment[index].attachmenturl = NewFileName;
        formData.append('files', documents[x], NewFileName);
      }
    }
    this._NotificationObj = {
      "Messageheader": this._MessageHeader,
      "Messagetodetail": this._Messagetodetail,
      "Messageattachment": this._Messageattachment
    };
    var Notification = JSON.stringify(this._NotificationObj);
    formData.append('strNotification', Notification);
    return (formData);
  }
  reset() {
    this._notificationform.reset();
    this._Messagetodetail = [] = []
    this.AttachmentLink = [] = [];
    this._Messageattachment = [] = [];
    this._selectedDocuments = [] = [];
    this.IsAttachment = true;
    this._submitted = false;
  }
  Debug
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._NotificationObj;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
}
