import { DatePipe } from '@angular/common';
import { invalid } from '@angular/compiler/src/render3/view/util';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { StockdeliveryService } from 'src/app/core/Services/inventory/stockdelivery.service';
import { RptproductdeliveryService } from 'src/app/core/Services/reports/Stock/RptProductDelivery/rptproductdelivery.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Stockdeliveryview } from 'src/app/core/Views/Stockdeliveryview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-stockdelivery',
  templateUrl: './v-stockdelivery.component.html',
  styleUrls: ['./v-stockdelivery.component.css']
})
export class VStockdeliveryComponent implements OnInit {

  _Action: IAction;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _branchid: number;
  _UserId: number;
  selectedRows: any;
  _Stockdeliveryviews: Stockdeliveryview[];
  _IsProgressSpinner: boolean = true;
  cols: any[];
  _userid: number;
  _roleid: number;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  mindate: Date;
  maxdate: Date;
  constructor(private fb: FormBuilder, private _StockdeliveryService: StockdeliveryService, private _RptProductDeliveryService: RptproductdeliveryService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private exportUtility: ExportUtility, private confirmationService: ConfirmationService) { }


  ngOnInit(): void {
    this._IsProgressSpinner = true;
    //38.check the user rights for the login user if the user has access to search, then allow to open search page
    this._Action = this._AccessRightsService.getAccessRights(50);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();

    this.cols = [
      { field: 'deliveryno', header: 'Delivery No' },
      { field: 'referenceno', header: 'Reference No' },
      { field: 'grnno', header: 'GRN No' },
      { field: 'customerorbranchname', header: 'Customer / Branch Name' },
      { field: 'deliverydate', header: 'Delivery Date' },
      { field: 'deliveryto', header: 'Delivery To' },
      { field: 'deliverytypename', header: 'Delivery Type' },
      { field: 'invoicetypename', header: 'Invoice Type' },
      { field: 'vehicleno', header: 'Vehicle No' },
      { field: 'drivername', header: 'Driver Name' },
      { field: 'createdbyname', header: 'Created By' },

    ]

    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
     if (sessionStorage['Environmentenddate'] == "null" ) {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }

    this.Fetchall();
  }
  Fetchall() {
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');

    if(fromdate == null){
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }

    if(todate == null){
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    } 

    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = sessionStorage["userid"];
    this._roleid = sessionStorage["currentRole"];
    this._IsProgressSpinner = true;
    this._StockdeliveryService.FetchAll(this._branchid, this._userid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //40. grid should load with updated data, and rows shouldbe in discending order
      this._Stockdeliveryviews = resultJSON.stockdeliveryviews;
      //console.log(result);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    //42.on clicking search button , the data must be filtered and display in the grid
    this.router.navigate(['/cStockDelivery'], { state: { stockdeliveryid: event.stockdeliveryid, invoicetype: event.invoicetype, action: 'view' } });
  }
  edit(event) {
    //46.on clicking update button, it show the update screen.
    this.router.navigate(['/cStockDelivery'], { state: { stockdeliveryid: event.stockdeliveryid, invoicetype: event.invoicetype, action: 'edit' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cStockDelivery']);
  }

  exportExcel() {
    let StockDeliveryList = this.GetStockDeliveryList();
    this.exportUtility.exportExcel(StockDeliveryList, 'StockDelivery', 'xlsx');
  }

  ExportToPdf() {
    let StockDeliveryList = this.GetStockDeliveryList();
    this.exportUtility.ExportToPdf(StockDeliveryList, 'StockDelivery.pdf');
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Stock Delivery <b>"' + event.deliveryno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  //Cancel
  Cancel(event) {
    var Deliveryno = event.deliveryno;
    this._IsProgressSpinner = true;
    this._StockdeliveryService.Cancel(Deliveryno, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.StockDeliveryCancel);
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this.Fetchall();
        this._IsProgressSpinner = false;
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });


  }
  GetStockDeliveryList() {
    let StockDeliveryList = [];
    this._Stockdeliveryviews.forEach(element => {
      let StockdeliveryList: any = {};
      StockdeliveryList["Delivery No"] = element.deliveryno;
      StockdeliveryList["Reference No"] = element.referenceno;
      StockdeliveryList["GRN No"] = element.grnno;
      StockdeliveryList["Customer / Branch Name"] = element.customerorbranchname;
      StockdeliveryList["Delivery Date"] = element.deliverydate;
      StockdeliveryList["Delivery Type"] = element.deliverytypename;
      StockdeliveryList["Invoice Type Name"] = element.invoicetypename;
      StockdeliveryList["Vehicle No"] = element.vehicleno;
      StockdeliveryList["Driver Name"] = element.drivername;
      StockdeliveryList["Created By"] = element.createdbyname;
      StockDeliveryList.push(StockdeliveryList);
    });
    return StockDeliveryList;
  }


  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  // Print(event) {
  //   var DeliveryNo = event.deliveryno;
  //   this._IsProgressSpinner = true;
  //   //var mediaType = 'application/pdf';
  //   this._RptProductDeliveryService.PrintCopy(DeliveryNo, this._branchid);
  //   this._IsProgressSpinner = false;
  // }
  Print(event) {
    var DeliveryNo = event.deliveryno;
    this._IsProgressSpinner = true;
    this._StockdeliveryService.Print({ "DeliveryNo": event.deliveryno, "branchid": this._branchid }).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
