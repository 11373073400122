import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptsalesprofitandlossService {
  public _Action: IAction;
  public _httpOptions;
  public _RptSalesProfitandLossService = environment.RptSalesProfitandLossService;
  constructor(private http: HttpClient) { }

  PageOnload(userid: number): Observable<any> {
    var Query = this._RptSalesProfitandLossService + '/PageInitialize/' + userid;

    return this.http.get<{ data: any }>(Query);
  }

  getProductCategory(producttype: object) {
    var Query = this._RptSalesProfitandLossService + '/getProductCategories';

    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory: object) {
    var Query = this._RptSalesProfitandLossService + '/getProducts';

    return this.http.post<{ data: any }>(Query, productcategory);
  }
  getBrand(products: object) {
    var Query = this._RptSalesProfitandLossService + '/getBrand';

    return this.http.post<{ data: any }>(Query, products);
  }

  Print(productdelivery: object) {
    var Query = this._RptSalesProfitandLossService;

    return this.http.post(Query, productdelivery, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }
}
