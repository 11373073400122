import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BankreconciliationService } from 'src/app/core/Services/accounts/bankreconciliation.service';
import { Bankreconciliasationsearch } from 'src/app/core/Models/Bankreconcilationsearch';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
//Debug
import * as ace from "ace-builds";

import { vBankNameSelect } from 'src/app/core/Validators/validation';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { DatePipe } from '@angular/common';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Branch } from 'src/app/core/Models/Branch';


@Component({
  selector: 'app-c-bank-reconciliation',
  templateUrl: './c-bank-reconciliation.component.html',
  styleUrls: ['./c-bank-reconciliation.component.css']
})
export class CBankReconciliationComponent implements OnInit {
  _IsSaveDisable: boolean = false;
  _IsClearDisable: boolean = false;
  _bankreconciliationform: FormGroup;
  _banknameform: FormGroup;
  _Bankreconciliations: Bankreconciliasationsearch[] = [];
  selectedReconciliationRows: Bankreconciliasationsearch[] = [];
  _Reconciliationid: number;
  _submitted = false;
  _action: string = "create";
  _fromdate: Date;
  _todate: Date;
  _TotalCreditAmount = 0;
  _TotalDebitAmount = 0;
  _UnCreditAmount = 0;
  _UnDebitAmount = 0;
  _branches: Branch[] = [];
  //Drop Down
  _banknames: Accountledgerview[] = [];
  _CurrentBalancesTemp: Accountledgerview[];
  _BankObj: any;
  _userid = parseInt(sessionStorage["userid"]);
  _currentbranchid = parseInt(sessionStorage["currentbranchid"]);
  _reconsilationdate: Date;
  _bankreconciliation: Bankreconciliasationsearch[] = [];
  
  _IsProgressSpinner: boolean = true;
  minDate: Date;
  maxdate: Date;
  //Debug
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, private _BankReconciliationService: BankreconciliationService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService) {
  }


  InitializeForm() {
    this._bankreconciliationform = this.fb.group({
      fromdate: [],
      todate: [],
      bankname: [],
      reconciliationcredit: [],
      reconciliationdebit: [],
      unreconciliationcredit: [],
      unreconciliationdebit: [],
      balanceasperbook: [],
      balanceinbank: []
    });
    this._banknameform = this.fb.group({
      bankname: ['', vBankNameSelect],
      bankreconcilationid: [],
      paymenttype: [],
      paymentdate: [],
      referenceno: [],
      creditamount: [],
      debitamount: [],
      reconcilationdate: [],
      banktype: [],
      series: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      accountingyear: [],
      recordstatus: [],
      branchid: []
    })
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.minDate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();
    this._Reconciliationid = history.state?.bankreconcilationid ? history.state?.bankreconcilationid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
    this._reconsilationdate = new Date();
    this._IsProgressSpinner = true;


    this._BankReconciliationService.PageOnLoad(this._reconsilationdate, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branches = (<Branch[]>resultJSON.branches || []);
      this._banknameform.get("branchid").setValue(this._currentbranchid);

      if (sessionStorage['Environmentenddate'] == "null") {
        this._fromdate = new Date();
        this._todate = new Date();
        this.maxdate = new Date();
      }
      else {
        this._fromdate = new Date(sessionStorage['Environmentenddate']);
        this._todate = new Date(sessionStorage['Environmentenddate']);
      }

      
      this.OnChangeBranch();

      //console.log(resultJSON);
      if (this._action == 'view') {
        this._bankreconciliationform.disable();
        this._banknameform.disable();
      }
      if (this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._IsSaveDisable = true;
        this._IsClearDisable = true;

        this._Bankreconciliations = updateJSON.bankreconciliationupdateviews;
        this.selectedReconciliationRows = updateJSON.bankreconciliationupdateviews;
        this.ConsolidateAmount(null);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  //Search Bank Reconciliation
  SearchBankReconsilization() {
    this._submitted = true;
    this._fromdate = this._fromdate ? this._fromdate : new Date();
    this._todate = this._todate ? this._todate : new Date();
    let datePipe = new DatePipe("en-Us");
    let fromdate = datePipe.transform(this._fromdate);
    let todate = datePipe.transform(this._todate);
    var Bankname = this._banknameform.get("bankname").value;

    if (!fromdate || !todate || !Bankname)
      return;
    
    this._IsProgressSpinner = true;

    let data = {
      fromdate : fromdate,
      todate : todate,
      bank : Bankname,
      userid : this._userid
    }

    this._BankReconciliationService.SearchBankReconsilization(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._Bankreconciliations = resultJSON.bankreconciliasationsearches;
      this.ConsolidateAmount(null);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  Clear(event) {
    this._submitted = true;
    this._banknameform.reset();
    this.InitializeForm()
    this._fromdate = new Date();
    this._todate = new Date();
    this._submitted = false;
    this._Bankreconciliations = [];
    this._IsSaveDisable = false;
  }

  //Current Balance 
  ChangeCurrentBalance(event) {
    var Balance = (this._CurrentBalancesTemp || []).filter(f => f.ledgerid == event.value)[0].currentbalance;
    this._bankreconciliationform.controls['balanceasperbook'].setValue(Balance);
    this._bankreconciliationform.controls['balanceinbank'].setValue(Balance);
  }
  onSave() {
    this._submitted = true;

    if (!(this.selectedReconciliationRows || []).length) {
      this._CustomExceptionService.handleWarning("Select atleat one row in grid.");
      return;
    }

    //Bank Reconciliation
    var l_BankReconciliation: Bankreconciliasationsearch[] = [];

    Object.keys(this.selectedReconciliationRows).map((Index) => {
      var currentRow = this.selectedReconciliationRows[Index];
      var l_bankreconciliation = new Bankreconciliasationsearch();
      l_bankreconciliation.bankreconcilationid = currentRow.bankreconcilationid || 0;
      l_bankreconciliation.paymentdate = currentRow.paymentdate;
      l_bankreconciliation.paymenttype = currentRow.paymenttype;
      l_bankreconciliation.paymenttypename = currentRow.paymenttypename;
      l_bankreconciliation.referenceno = currentRow.referenceno;
      l_bankreconciliation.creditamount = currentRow.creditamount;
      l_bankreconciliation.debitamount = currentRow.debitamount;
      l_bankreconciliation.reconcilationdate = currentRow.reconcilationdate;
      l_bankreconciliation.series = currentRow.series;
      l_bankreconciliation.createdby = parseInt(sessionStorage["userid"]);
      l_bankreconciliation.modifiedby = parseInt(sessionStorage["userid"]);
      l_bankreconciliation.recordstatusid = 45;
      l_bankreconciliation.createdon = new Date();
      l_bankreconciliation.modifiedon = new Date();
      l_bankreconciliation.transactionbranchid = currentRow.transactionbranchid;

      l_BankReconciliation.push(l_bankreconciliation);
    })

    //this._Bankreconciliations = l_BankReconciliation;

    this._BankObj = { "Bankreconcilation": l_BankReconciliation };
    if (this._action == 'create') {
      //this.onEd();
      this._IsProgressSpinner = true;
      this._BankReconciliationService.create(this._BankObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._IsProgressSpinner = false;
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
          this._IsSaveDisable = true;
        }
        else {
          this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        }
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }

  }


  // on clicking back button , it Navigates to the search screen
  goBack(event) {
    //alert('This');
    //console.log("goBack");
    this._router.navigate(['/vBankReconciliation']);
  }
  reset(event) {
    this._bankreconciliationform.reset();
  }
  //Debug
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._BankObj;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);

  }
  onRowSelect(event) {
    this.ConsolidateAmount(event);
  }
  onRowUnselect(event) {
    this.ConsolidateAmount(event);
  }

  ConsolidateAmount(event) {
    this._TotalCreditAmount = 0;
    this._TotalDebitAmount = 0;
    this._UnCreditAmount = 0;
    this._UnDebitAmount = 0;
    if (this.selectedReconciliationRows && this.selectedReconciliationRows.length) {

      // this.selectedReconciliationRows.every((item) => {
      //   // this.selectedReconciliationRows.push(item);
      //   this._TotalCreditAmount += this._TotalCreditAmount + item.creditamount;
      //   this._TotalDebitAmount += this._TotalDebitAmount + item.debitamount;
      // });

      for (var i = 0; i < this.selectedReconciliationRows.length; i++) {
        this._TotalCreditAmount += this.selectedReconciliationRows[i].creditamount;
        this._TotalDebitAmount += this.selectedReconciliationRows[i].debitamount;
      }
    }

    let NotInArray = this._Bankreconciliations.filter(x => !this.selectedReconciliationRows.includes(x));
    if (NotInArray && NotInArray.length) {
      for (var j = 0; j < NotInArray.length; j++) {
        this._UnCreditAmount += NotInArray[j].creditamount;
        this._UnDebitAmount += NotInArray[j].debitamount;
      }
    }
    this._bankreconciliationform.controls['reconciliationcredit'].setValue(this._TotalCreditAmount.toFixed(2));
    this._bankreconciliationform.controls['reconciliationdebit'].setValue(this._TotalDebitAmount.toFixed(2));
    this._bankreconciliationform.controls['unreconciliationcredit'].setValue(this._UnCreditAmount.toFixed(2));
    this._bankreconciliationform.controls['unreconciliationdebit'].setValue(this._UnDebitAmount.toFixed(2));
  }

  ChangeFromDate($event) {
    // this.minDate = $event;
    if(this._fromdate > this._todate){
      this._todate = null
    }
    
    
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

  OnChangeBranch() {
    this._IsProgressSpinner = true;
    let obj = {
      userid: this._userid,
      branchid: this._banknameform.get("branchid").value
    }
    this._BankReconciliationService.GetBankBasedOnBranch(obj).subscribe((result: any) => {
      if (result && result.tranStatus.result) {
        this._banknames = result.accountledgerviews;
        this._CurrentBalancesTemp = result.accountledgerviews || [];
      }
      else {
        this._CustomExceptionService.handleError(result.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); });
  }

}