import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptMarkupLevelService {
  public _Action: IAction;
  public _httpOptions;
  public _Service = environment.RptMarkupLevelService;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._Service + '/GenerateReport';
    return this.http.post(Query, obj, { headers });
  }
}
