import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Customerwisediscount } from 'src/app/core/Models/Customerwisediscount';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import * as ace from "ace-builds";
import { CustomerwisediscountService } from 'src/app/core/Services/Rewards/customerwisediscount.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Customer } from 'src/app/core/Models/Customer';
import { vCategoryNameSelect, vDiscountPercentage, vEffectiveFrom, vProductName, vProductNameSelect, vProductTypeSelect } from 'src/app/core/Validators/validation';
import { ProductCategory } from 'src/app/core/Models/overallclass';
import { Customerwisediscountview } from 'src/app/core/Views/customerwisediscountview';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Branch } from 'src/app/core/Models/Branch';
import { DatePipe } from '@angular/common';
import { Customerwisediscountheader } from 'src/app/core/Models/Customerwisediscountheader';
import { Customerwisediscountdetail } from 'src/app/core/Models/Customerwisediscountdetail';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-c-customerwisediscount',
  templateUrl: './c-customerwisediscount.component.html',
  styleUrls: ['./c-customerwisediscount.component.css']
})
export class CCustomerwisediscountComponent implements OnInit {
  filteredProducts: any[];
  productcatid: any[] = [];
  producttypeid: any[] = [];
  _isSaveHide: boolean = false;
  _isClearHide: boolean = false;
  _IsProgressSpinner: boolean = false;
  _customerwisediscountform: FormGroup;
  _customerwisediscounttypeform: FormGroup
  _action: string = "create";
  _submitted = false;
  selectedrows: Customerwisediscountview[] = [];
  pSelectableRow: any;
  minDate: Date;
  _Branches: Branch[] = [];
  // _Customerwisediscount: Customerwisediscount;
  _ProductType: Producttype;
  _ProductCategory: Productcategory[] = [];
  // _ProductName: Productheader[] = [];
  _ProductName: Productoverallview[] = [];
  //_Products:Productoverallview[]=[];
  _tempproductname: Productoverallview[] = [];
  _customerwisediscount: Customerwisediscount[] = [];
  _customerwisediscountdetailid: number;
  //Added By Tamil
  _SelectedProductType: Producttype;
  _SelectedProductCategory: ProductCategory;
  _selectedProductId: Productheader;
  _inputOBJ: any;
  _validCustomerDiscount: any;
  _customerid: number;
  _productid: number;
  _Customers: Customerwisediscountview[] = [];
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["currentbranchid"];
  isDisabledsendtoapproval = true;
  customertype: any[];
  _selectedcustomertype: number;
  _isdefaultcustomer: boolean;
  _roleid = parseInt(sessionStorage["currentRole"])
  _ProductTypeId: number[] = [];
  _ProductCategoryId: number[] = [];
  _ProductId: number[] = [];
  _BrandId: number[] = [];
  _Brands: any[] = [];
  _CustomerWiseId: number;
  _DiscountDetails: any[] = [];
  _tempCustomerWiseId: number;
  UsdDiscount: number = 0;
  RandDiscount: number = 0;
  BondDiscount: number = 0;
  BankDiscount: number = 0;
  _Action: IAction;


  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private fb: FormBuilder,
    private _AccessRightsService: AccessRightsService,
    private _CustomerwisediscountService: CustomerwisediscountService,
    private _CustomExceptionService: CustomExceptionService, private _router: Router,
    private _hotkeysService: HotkeysService) {
    this._customerid = history.state?.customerid ? history.state?.customerid : 0;
    this._productid = history.state?.productid ? history.state?.productid : 0;
    this._branchid = history.state?.branchid ? history.state?.branchid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._CustomerWiseId = history.state?.customerwiseid ? history.state?.customerwiseid : 0;
  }

  InitializeForm() {
    this._customerwisediscountform = this.fb.group({
      customerwiseid: [0],
      customerid: ['', vProductNameSelect],
      branchid: ['', vProductNameSelect],
      effectivefrom: ['', vEffectiveFrom],
      effectiveto: ['', vEffectiveFrom],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      approvalstatus: [],
      accountingyear: [],
      customerwisediscountdetails: []
    });
    this._customerwisediscounttypeform = this.fb.group({
      producttypeid: [],
      productcategoryid: [],
      productid: [],
      brandid: []
    });
  }


  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(77);
    this.utility.pageLoadScript();
    this.InitializeForm();

    //Parent Form
    this.customertype = [
      { id: 1, name: 'Credit Customer' },
      { id: 2, name: 'Cash Customer' },
      { id: 3, name: 'All' }
    ]
    this._selectedcustomertype = 3;
    // this.HotKeyIntegration();

    this._isSaveHide = (this._action == 'view');
    this._isClearHide = (this._action == 'view' || this._action == 'edit');
    this.PageOnLoad();

  }

  OnFromDateSelect(event) {
    this.minDate = event;
  }
  PageOnLoad() {
    this._customerwisediscountform.get("customerid").reset();
    this._IsProgressSpinner = true;
    this._CustomerwisediscountService.PageOnLoad({
      "roleid": this._roleid, "customertype": this._selectedcustomertype,
      "userid": this._userid, "customerwisediscountid": this._CustomerWiseId
    }).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Branches = resultJSON.branches;
      this._ProductType = resultJSON.producttypes;
      this._Customers = resultJSON.customers;
      this._Brands = resultJSON.brandList;
      this._branchid = sessionStorage["currentbranchid"];
      this._customerwisediscountform.controls['branchid'].setValue(parseInt(this._branchid));
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        if (updateJSON.customerwisediscountheader.effectivefrom != null)
          updateJSON.customerwisediscountheader.effectivefrom = new Date(updateJSON.customerwisediscountheader.effectivefrom);
        if (updateJSON.customerwisediscountheader.effectiveto != null) {
          updateJSON.customerwisediscountheader.effectiveto = new Date(updateJSON.customerwisediscountheader.effectiveto);
        }
        this._customerwisediscountform.setValue(updateJSON.customerwisediscountheader);
        this._tempCustomerWiseId = updateJSON.customerwisediscountheader.customerwiseid;
        this.selectedrows = updateJSON.customerwisediscountdetails;
        this._DiscountDetails = updateJSON.customerwisediscountdetails;
      }
      if (this._action == 'edit') {
        this.isDisabledsendtoapproval = false;
      }
      if (this._action == 'view') {
        this._customerwisediscountform.disable();

      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  DiscountDetails() {
    this._IsProgressSpinner = true;
    this._CustomerwisediscountService.DiscountDetails({
      "Productcategory": this._ProductCategoryId || [],
      "Producttype": this._ProductTypeId || [], "Productheader": this._ProductId || [], "brandid": this._BrandId, "branchid": this._customerwisediscountform.get("branchid").value
    }).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._DiscountDetails = [];
      this.selectedrows = [];
      this._ProductCategory = resultJSON.productcategories;
      this._ProductName = <Productoverallview[]>resultJSON.products || [];
      this._DiscountDetails = resultJSON.discountdetailslist;
      Object.keys(this._DiscountDetails).map((Index) => {
        this._DiscountDetails[Index].tempusddiscount = 0;
        this._DiscountDetails[Index].tempranddiscount = 0;
        this._DiscountDetails[Index].tempbonddiscount = 0;
        this._DiscountDetails[Index].tempbankdiscount = 0;
        this._DiscountDetails[Index].customerwisedetailid = 0;
        this._DiscountDetails[Index].customerwiseid = 0;
      });
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  OnChangeDiscountGrid(objectModel) {
    if (this.selectedrows && this.selectedrows.length) {
      if (objectModel["tempusddiscount"] > 100) {
        this._CustomExceptionService.handleWarning("Discount percentage should not greater than 100.");
        objectModel["tempusddiscount"] = 0;
      }
      else if (objectModel["tempranddiscount"] > 100) {
        this._CustomExceptionService.handleWarning("Discount percentage should not greater than 100.");
        objectModel["tempranddiscount"] = 0;
      }
      else if (objectModel["tempbonddiscount"] > 100) {
        this._CustomExceptionService.handleWarning("Discount percentage should not greater than 100.");
        objectModel["tempbonddiscount"] = 0;
      }
      else if (objectModel["tempbankdiscount"] > 100) {
        this._CustomExceptionService.handleWarning("Discount percentage should not greater than 100.");
        objectModel["tempbankdiscount"] = 0;
      }
    } else {
      this._CustomExceptionService.handleWarning("No Products found");
    }
  }
  onColumnSelect(event) {
    if (event.checked === true) {

    }
    if (event.checked === false) {

    }

  }
  onRowSelect(event) {


  }

  onRowUnselect(event) {

  }

  validateGrid() {
    var valid;
    if (this.selectedrows.length > 0) {
      valid = "Y";
    }
    else {
      valid = "N";
    }
    return valid;
  }
  // 22.if all the data is valid allow to save the record in the database
  onSave() {
    this._submitted = true;
    // var discount = this._customerwisediscountform.controls.discount.value;
    var validGrid = this.validateGrid();
    var Effectivefrom = this._customerwisediscountform.get("effectivefrom").value;
    var Effectiveto = this._customerwisediscountform.get("effectiveto").value;
    if (Effectiveto == null) {
      this._customerwisediscountform.get("effectiveto").setValue(null);
    }
    else {
      var datePipe = new DatePipe("en-US");
      var peffectiveto = datePipe.transform(Effectiveto, 'yyyy-MM-dd');
      this._customerwisediscountform.get("effectiveto").setValue(new Date(peffectiveto))
    }

    var datePipe = new DatePipe("en-US");
    var peffectivefrom = datePipe.transform(Effectivefrom, 'yyyy-MM-dd');
    if (peffectivefrom > peffectiveto) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective To Date");
      return;
    }
    let hasDamagedProduct = this.selectedrows.some(s => s.tempusddiscount == 0 &&
      s.tempranddiscount == 0 && s.tempbankdiscount == 0 && s.tempbonddiscount == 0);
    if (hasDamagedProduct) {
      this._CustomExceptionService.handleError("Discount should not be 0");
      return
    }
    else if (this._customerwisediscountform.valid && validGrid == "Y") {
      var customerwiseDiscount: Customerwisediscountdetail[] = [];
      var customerDiscount: Customerwisediscountdetail;
      Object.keys(this.selectedrows).map((Index) => {
        customerDiscount = new Customerwisediscountdetail();
        customerDiscount.productid = this.selectedrows[Index].productid;
        customerDiscount.customerwisedetailid = this.selectedrows[Index].customerwisedetailid;
        customerDiscount.customerwiseid = this.selectedrows[Index].customerwiseid;
        customerDiscount.usddiscount = 0;
        customerDiscount.tempusddiscount = this.selectedrows[Index].tempusddiscount;
        customerDiscount.randdiscount = 0
        customerDiscount.tempranddiscount = this.selectedrows[Index].tempranddiscount;
        customerDiscount.bonddiscount = 0
        customerDiscount.tempbonddiscount = this.selectedrows[Index].tempbonddiscount;
        customerDiscount.bankdiscount = 0
        customerDiscount.tempbankdiscount = this.selectedrows[Index].tempbankdiscount;
        customerwiseDiscount.push(customerDiscount);
      });
      var CustomerWiseHeader = new Customerwisediscountheader();

      CustomerWiseHeader = this._customerwisediscountform.getRawValue();
      CustomerWiseHeader.effectivefrom = new Date(peffectivefrom);
      CustomerWiseHeader.effectiveto = new Date(peffectiveto);
      CustomerWiseHeader.accountingyear = new Date().getFullYear();
      CustomerWiseHeader.createdby = parseInt(sessionStorage["userid"]);
      CustomerWiseHeader.modifiedby = parseInt(sessionStorage["userid"]);
      CustomerWiseHeader.createdon = new Date();
      CustomerWiseHeader.modifiedon = new Date();
      CustomerWiseHeader.approvalstatus = "MSC00045";
      this._inputOBJ = JSON.parse(JSON.stringify({ "CustomerWiseHeader": CustomerWiseHeader, "CustomerWiseDiscountDetail": customerwiseDiscount }));
      this._IsProgressSpinner = true;
      if (this._action == 'create') {
        this._CustomerwisediscountService.create(this._inputOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._tempCustomerWiseId = resutJSON.customerwisediscountheader.customerwiseid;
            this._IsProgressSpinner = false;
            this.isDisabledsendtoapproval = false;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        // 47.after Updating  the record, get the response A, display the success message as "CustomerWise Discount  A  Modified Succesfully "
        this._CustomerwisediscountService.edit(this._inputOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          // 47.After Update page should redirect to search page. And Search page grid should load with updated data.
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this._tempCustomerWiseId = resutJSON.customerwisediscountheader.customerwiseid;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this.isDisabledsendtoapproval = false;
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
    else {
      this._CustomExceptionService.handleError("Please Select atleast one row");
    }
  }
  // getProductCategory(event) {
  //   var selectedCategory = event.value;
  //   this._ProductName = [];
  //   this._ProductCategory = [];
  //   this._IsProgressSpinner = true;
  //   if (selectedCategory.length > 0) {
  //     this._CustomerwisediscountService.getProductCategory({ "producttypeid": selectedCategory, "branchid": this._branchid }).subscribe((result) => {
  //       const resultJSON = JSON.parse(JSON.stringify(result));
  //       this._ProductCategory = resultJSON.productcategories;
  //       this.DiscountDetails();
  //       this._IsProgressSpinner = false;
  //     }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  //   }
  //   else {
  //     this._IsProgressSpinner = false;
  //     this._SelectedProductCategory = undefined;
  //     this._selectedProductId = undefined;
  //   }
  // }
  // getProduct(event) {
  //   // this.clearDiscounts();
  //   var selectedProduct = event.value;
  //   if (selectedProduct.length == 0 || selectedProduct == null) {
  //     this.reset('');
  //     return;
  //   }
  //   else {
  //     this._IsProgressSpinner = true;
  //     this._CustomerwisediscountService.getProduct({ "ProductCategoryId": selectedProduct, "branchid": this._branchid }).subscribe((result) => {
  //       const resultJSON = JSON.parse(JSON.stringify(result));
  //       this._ProductName = <Productoverallview[]>resultJSON.products || [];

  //       this.DiscountDetails();

  //       this._IsProgressSpinner = false;
  //     }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  //   }


  // }

  // filterProducts(event) {
  //   if (event.query == "" || event.query == undefined) {
  //     return;
  //   }
  //   else {
  //     if (this.productcatid.length > 0) {
  //       let filtered: any[] = [];
  //       let query = (<string>event.query).toLowerCase();
  //       this.filteredProducts = this._ProductName.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
  //       return;
  //     }
  //     else {
  //       this._IsProgressSpinner = true;
  //       this._CustomerwisediscountService.GetProduct(event.query, this._branchid).subscribe((result) => {
  //         const resultJSON = JSON.parse(JSON.stringify(result));
  //         this._ProductName = <Productoverallview[]>resultJSON.productsearchlist || [];
  //         this._tempproductname = <Productoverallview[]>resultJSON.productsearchlist || [];
  //         let filtered: any[] = [];
  //         let query = (<string>event.query).toLowerCase();
  //         this.filteredProducts = this._ProductName.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
  //         this._IsProgressSpinner = false;
  //       },
  //         error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  //     }
  //   }

  // }

  // onChangeProduct(event) {
  //   this.selectedrows = [];
  //   this._IsProgressSpinner = true;
  //   this._CustomerwisediscountService.OnProductNameChange(event.value, this._branchid).subscribe((result) => {
  //     const resultJSON = JSON.parse(JSON.stringify(result));
  //     this.selectedrows = resultJSON.customerslabwisediscounts || [];
  //     if (this.selectedrows.length > 0) {
  //       Object.keys(this.selectedrows).map((Index) => {
  //         var obj = this._Customers.findIndex(obj => obj.customerid == this.selectedrows[Index].customerid);
  //         if (obj >= 0) {
  //           if (this.selectedrows[Index].discount)
  //             this._Customers[obj].tempdiscount = this.selectedrows[Index].discount || 0;
  //           this._Customers[obj].tempusddiscount = this.selectedrows[Index].tempusddiscount || 0;
  //           this._Customers[obj].tempranddiscount = this.selectedrows[Index].tempranddiscount || 0;
  //           this._Customers[obj].tempbonddiscount = this.selectedrows[Index].tempbonddiscount || 0;
  //           this._Customers[obj].tempbankdiscount = this.selectedrows[Index].tempbankdiscount || 0;

  //         }
  //       });
  //     }
  //     this.producttypeid = resultJSON.products[0].producttypeid;
  //     this.productcatid = resultJSON.products[0].productcategoryid;
  //     if (this.selectedrows.length > 0) {
  //       this._customerwisediscountform.get("discount").setValue(resultJSON.customerslabwisediscounts[0].discount || 0);
  //     }
  //     let product = this._ProductName.filter(f => f.productid == resultJSON.products[0].productid)[0];
  //     this.producttypeid = [];
  //     this.producttypeid.push(product.producttypeid);
  //     this.productcatid = [];
  //     this.productcatid.push(product.productcategoryid);
  //     this._IsProgressSpinner = false;
  //   }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  // }
  reset(event) {
    this._customerwisediscountform.reset();
    this.InitializeForm();
    this._customerwisediscountform.controls['branchid'].setValue(parseInt(this._branchid));
    this._submitted = false;
    this._DiscountDetails = [];
    this.selectedrows = [];
    this._ProductTypeId = [];
    this._ProductCategoryId = [];
    this._ProductId = [];
    this._BrandId = [];
    this._ProductName = [];
    this._ProductCategory = [];
    this.UsdDiscount = 0;
    this.RandDiscount = 0;
    this.BankDiscount = 0;
    this.BondDiscount = 0;


  }
  goBack(event) {
    this._router.navigate(['/vCustomerwisediscount']);
  }
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._inputOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

  handleError(error: any) {
    this._CustomExceptionService.handleError(error)
    this._IsProgressSpinner = false;
  }
  SendToApproval(event) {
    var workflowid = 51;
    this._userid = sessionStorage["userid"];
    this._branchid = this._customerwisediscountform.get("branchid").value;
    this._IsProgressSpinner = true;
    //this._productid= this._customerwisediscountform.get("productid").setValue(this._customerwisediscountform.get("productid").value);
    this._CustomerwisediscountService.SendToApproval(workflowid, this._userid, this._branchid, this._tempCustomerWiseId).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
        this.reset(event);
        this.isDisabledsendtoapproval = true;
        this._IsProgressSpinner = false;
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  OnSetDiscount() {
    if (this._DiscountDetails && this._DiscountDetails.length > 0) {
      if (this.UsdDiscount) {
        this._DiscountDetails.forEach(element => {
          element.tempusddiscount = this.UsdDiscount;
        });
      }
      if (this.RandDiscount) {
        this._DiscountDetails.forEach(element => {
          element.tempranddiscount = this.RandDiscount;
        });
      } if (this.BondDiscount) {
        this._DiscountDetails.forEach(element => {
          element.tempbonddiscount = this.BondDiscount;
        });
      } if (this.BankDiscount) {
        this._DiscountDetails.forEach(element => {
          element.tempbankdiscount = this.BankDiscount;
        });
      }
    } else {
      this._CustomExceptionService.handleWarning("No Products found");
    }
  }
}