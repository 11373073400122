import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  public _Action: IAction;
  public _EmployeeService = environment.EmployeeService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(
    private http: HttpClient,
  ) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 1)
  }
  createHeaders(token: any, type: number): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(p_employeeid: number): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._EmployeeService + '/' + p_employeeid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll() {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._EmployeeService + '/FetchAll';

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);

      return data;
    }));
  }

  createWithupload(files: File) {
    const headers = this.createHeaders(this.token, 2)
    let time = new Date();

    // var timeStamp = new Date() || 
    const formData: FormData = new FormData();
    formData.append('file', files, time.getTime() + '_' + files.name);

    //formData.append("files", files[0]);
    //console.log(formData);
    var Query = this._EmployeeService + '/UploadFile';
    return this.http.post(Query, formData, {headers});
  }
  // Create
  create(p_employee: FormData) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._EmployeeService;
    return this.http.post<{ data: any }>(Query, p_employee, { headers });
  }

  // View
  view(p_employeeid: number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._EmployeeService + '/' + p_employeeid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  // Edit
  edit(p_employee: FormData) {
    const headers = this.createHeaders(this.token, 2)
    //console.log("Update");
    var Query = this._EmployeeService;

    //console.log(p_employee);
    return this.http.put<{ data: any }>(Query, p_employee, { headers });
  }

  ChangeStatus(employeid: number, modifiedby: number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._EmployeeService + '/ChangeStatus/' + employeid + '/' + modifiedby;

    return this.http.get<{ data: any }>(Query, { headers });
  }

}





