import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { OnlineStockDeliveryService } from 'src/app/core/Services/ecommerce/online-stock-delivery.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-online-stock-delivery',
  templateUrl: './v-online-stock-delivery.component.html',
  styleUrls: ['./v-online-stock-delivery.component.css']
})
export class VOnlineStockDeliveryComponent implements OnInit {
  _Action: IAction;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _UserId: number;
  selectedRows: any;
  _Stockdeliveryviews: any[];
  _IsProgressSpinner: boolean = true;
  cols: any[];
  _roleid: number;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _branchid = sessionStorage["currentbranchid"];
  _userid = sessionStorage["userid"]

  constructor(private fb: FormBuilder, private _OnlineStockDeliveryservice: OnlineStockDeliveryService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService, private exportUtility: ExportUtility, private confirmationService: ConfirmationService) { }


  ngOnInit(): void {
    this._IsProgressSpinner = true;
    this._Action = this._AccessRightsService.getAccessRights(315);
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'stockdeliveryno', header: 'Stock Delivery No' },
      { field: 'salesinvoiceno', header: 'Invoice No' },
      { field: 'stockdeliverydate', header: 'Delivery Date' },
      { field: 'vehicleno', header: 'Vehicle No' },
      { field: 'drivername', header: 'Driver Name' },
      { field: 'isselfpickupname', header: 'Is Self Pickup' },


    ]
    this.fromdate = new Date();
    this.todate = new Date();
    this.Fetchall();
  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = sessionStorage["userid"];
    this._roleid = sessionStorage["currentRole"];
    this._IsProgressSpinner = true;
    this._OnlineStockDeliveryservice.FetchAll(fromdate, todate, this._userid,this._branchid,this._roleid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Stockdeliveryviews = resultJSON.onlinestockdeliverydetails;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/COnlineStockDelivery'], { state: { stockdeliveryid: event.stockdeliveryid, isselfpickup: event.isselfpickup, action: 'view' } });
  }

  GoToCreate(event) {
    this.router.navigate(['/COnlineStockDelivery']);
  }

  exportExcel() {
    let StockDeliveryList = this.GetStockDeliveryList();
    this.exportUtility.exportExcel(StockDeliveryList, 'OnlineStockDelivery', 'xlsx');
  }

  ExportToPdf() {
    let StockDeliveryList = this.GetStockDeliveryList();
    this.exportUtility.ExportToPdf(StockDeliveryList, 'OnlineStockDelivery.pdf');
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Stock Delivery <b>"' + event.deliveryno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  //Cancel
  Cancel(event) {
    var salesinvoiceno = event.deliveryno;
    var orderid = event.deliveryno;
    var orderno = event.deliveryno;
    var productid = event.deliveryno;
    this._IsProgressSpinner = true;
    this._OnlineStockDeliveryservice.Cancel(salesinvoiceno, orderid, orderno, productid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.StockDeliveryCancel);
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this.Fetchall();
        this._IsProgressSpinner = false;
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });


  }
  GetStockDeliveryList() {
    let StockDeliveryList = [];
    this._Stockdeliveryviews.forEach(element => {
      let StockdeliveryList: any = {};

      StockdeliveryList["Stock Delivery No"] = element.stockdeliveryno;
      StockdeliveryList["Invoice No"] = element.salesinvoiceno;
      StockdeliveryList["Delivery Date"] = this.datepipe.transform(element.stockdeliverydate, 'dd/MM/yyyy');
      StockdeliveryList["Vehicle No"] = element.vehicleno || "";
      StockdeliveryList["Driver Name"] = element.drivername || "";
      StockdeliveryList["Is Self Pickup"] = element.isselfpickupname;
      StockDeliveryList.push(StockdeliveryList);
    });
    return StockDeliveryList;
  }



}
