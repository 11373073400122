<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Account Ledger Report</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_rptaccountledgerform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="fromdate" dateFormat="dd/mm/yy"
                                            [monthNavigator]="true" [yearNavigator]="true" 
                                            [minDate]="mindate" [maxDate]="maxdate"
                                            yearRange="2021:2050"
                                            (onSelect)="OnSelectFromDate($event)">
                                        </p-calendar>
                                        <label for="fromdate">From Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="todate" dateFormat="dd/mm/yy"
                                            [monthNavigator]="true" [yearNavigator]="true"
                                            [minDate]="_rptaccountledgerform.get('fromdate').value"
                                            [maxDate]="maxdate"
                                            yearRange="2021:2050">
                                        </p-calendar>
                                        <label for="todate">To Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_typelist"
                                            optionValue="metasubid" optionLabel="metasubdescription"
                                            formControlName="partytype" [filter]="true" filterBy="metasubdescription"
                                            (onChange)="onchangepartytype($event)">
                                        </p-dropdown>
                                        <label for="partytypename">Party Type <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_customertypelist"
                                            optionValue="value" optionLabel="customertype"
                                            (onChange)="onchangecustomertype($event)" formControlName="customertype"
                                            [filter]="true" filterBy="customertype">
                                        </p-dropdown>
                                        <label for="customertype">Type</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_statuslist"
                                            optionValue="metasubcode" optionLabel="metasubdescription"
                                            (onChange)="onchangestatus($event)" formControlName="status">
                                        </p-dropdown>
                                        <label for="Status">Status</label>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_accountnamelist"
                                            optionValue="ledgerid" optionLabel="name" [virtualScroll]="true"
                                            itemSize="30" formControlName="customer_supplierid"
                                            (onChange)="onSelectPartyName($event)" [filter]="true" filterBy="name">
                                            <ng-template let-account pTemplate="item">
                                                <div
                                                    [style]="account.status == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                    {{account.name}}</div>
                                            </ng-template>
                                        </p-dropdown>
                                        <label for="name">Party Name <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_entitylist"
                                            optionValue="metasubcode" optionLabel="metasubdescription"
                                            formControlName="entity" [filter]="true" filterBy="metasubdescription"
                                            (onChange)="onChange($event)">
                                        </p-dropdown>
                                        <label for="partytypename">Entity</label>
                                    </span>
                                </td>
                                <td style="width: 20%;" *ngIf="_IsBranch">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_branchlist"
                                            optionValue="branchid" optionLabel="branchname" formControlName="branchid"
                                            [filter]="true" filterBy="branchname" [virtualScroll]="true" itemSize="30">
                                        </p-dropdown>
                                        <label for="branchid">Branch Name</label>
                                    </span>
                                </td>
                                <td style="width: 20%;" *ngIf="_IsVehicle">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_vehiclelist"
                                            optionValue="vehicleid" optionLabel="vehicleno" formControlName="vehicleid"
                                            [filter]="true" filterBy="Vehicleno">
                                        </p-dropdown>
                                        <label for="branchname">Vehicle Name </label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_currencylist"
                                            optionValue="currencyid" optionLabel="currencyname"
                                            formControlName="currencyid" [filter]="true" filterBy="currencyname">
                                        </p-dropdown>
                                        <label for="currencyname">Transaction Currency</label>
                                    </span>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_currencylist"
                                            optionValue="currencyid" optionLabel="currencyname"
                                            formControlName="r_currencyid" [filter]="true" filterBy="currencyname"
                                            [showClear]="true">
                                        </p-dropdown>
                                        <label for="currencyname">Report in Currency</label>
                                    </span>
                                </td>
                                <td>
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_Vouchertypes"
                                            optionValue="vouchertypeid" optionLabel="vouchertypedesc"
                                            formControlName="vouchertypeid" [filter]="true" filterBy="vouchertypedesc"
                                            [showClear]="true">
                                        </p-dropdown>
                                        <label for="partytypename">Voucher Type </label>
                                    </span>
                                </td>
                                <td>
                                    <div class="p-formgroup-inline">
                                        <div class="p-field-checkbox">
                                            <p-checkbox name="openingbalance" binary="true" formControlName="openingbalance">
                                            </p-checkbox>
                                            <label for="openingbalance">With Opening Bal.</label>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="p-formgroup-inline">
                                        <div class="p-field-checkbox">
                                            <p-checkbox name="customerremark" binary="true" formControlName="customerremark">
                                            </p-checkbox>
                                            <label for="customerremark">With User Narration</label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" icon="las la-search" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                                </td>
                                <td></td>
                                <td></td>
                                <td style="width: 5%;">
                                    <span class="p-float-label">
                                        <input type="text" disabled pInputText formControlName="_lopeningbalanace">
                                        <label>Opening Balance</label>
                                    </span>
                                </td>
                                <td style="width: 5%;">
                                    <span class="p-float-label">
                                        <input type="text" disabled pInputText formControlName="_lclosingbalanace">
                                        <label>Closing Balance</label>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </form>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100,200,400,500,1000]" [globalFilterFields]="['name','voucherdate','refno','vouchertypedesc','particulars','debitamount','creditamount','username']"
                            [rowHover]="true" dataKey="name" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No
                                        <p-sortIcon field="sno"></p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 180px;" pSortableColumn="name">Ledger Name
                                        <p-sortIcon field="name">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 100px;" pSortableColumn="voucherdate">Date
                                        <p-sortIcon field="voucherdate">
                                        </p-sortIcon>
                                    </th>

                                    <th class="text-center" style="width: 100px;" pSortableColumn="branchname">Branch Name
                                        <p-sortIcon field="branchname">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 100px;" pSortableColumn="refno">Bill No
                                        <p-sortIcon field="voucherdate">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 120px;" pSortableColumn="vouchertypedesc">
                                        Voucher Type
                                        <p-sortIcon field="vouchertypedesc">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 350px;" pSortableColumn="particulars">
                                        Particulars
                                        <p-sortIcon field="particulars">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 80px;" pSortableColumn="debitamount">Debit
                                        <p-sortIcon field="debitamount">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 80px;" pSortableColumn="creditamount">Credit
                                        <p-sortIcon field="creditamount">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 100px;" pSortableColumn="username">User
                                        <p-sortIcon field="username">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 100px;" pSortableColumn="finalclosingbalance">
                                        Closing Balance
                                        <p-sortIcon field="finalclosingbalance"></p-sortIcon>
                                    </th>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                                <tr>
                                    <td class="text-center" style="width: 60px;">
                                        {{i+ 1}}
                                    </td>
                                    <td class="text-center" style="width: 180px;">
                                        {{_ReportList.name}}
                                    </td>
                                    <td class="text-center" style="width: 100px;">
                                        {{_ReportList.voucherdate | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td class="text-center" style="width: 100px;">
                                        {{_ReportList.branchname}}
                                    </td>
                                    <td class="text-center" style="width: 100px;" [hidden]="disabledvoucherno">
                                        <a href="javascript:void(0);" (click)="BillNoNavigation(_ReportList)">
                                            {{_ReportList.refno}}
                                        </a>
                                        
                                    </td>
                                    <td class="text-center" style="width: 100px;" [hidden]="disabledbillno">
                                            {{_ReportList.refno}} 
                                    </td>
                                    <td class="text-center" style="width: 120px;">
                                        {{_ReportList.vouchertypedesc}}
                                    </td>
                                    <td class="text-center" style="width: 350px;">
                                        {{_ReportList.particulars}}
                                    </td>
                                    <td class="text-right" style="width: 80px;">
                                        {{_ReportList.debitamount}}
                                    </td>
                                    <td class="text-right" style="width: 80px;">
                                        {{_ReportList.creditamount}}
                                    </td>
                                    <td class="text-center" style="width: 100px;">
                                        {{_ReportList.username}}
                                    </td>
                                    <td class="text-center" style="width: 100px;">
                                        {{_ReportList.finalclosingbalance}}
                                    </td>

                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="sub-title">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Amount Details</h5>
                                    </div>
                                </div>
                            </div>
                            <form [formGroup]="_rptaccountledgerform">
                                <table class="normal-table column-2">
                                    <tr>
                                        <td style="width: 20%;">
                                            <span class="p-float-label">
                                                <input type="text" disabled pInputText
                                                    formControlName="_TotalDebitamount">
                                                <label>Total Debit Amount</label>
                                            </span>
                                        </td>
                                        <td style="width: 20%;">
                                            <span class="p-float-label">
                                                <input type="text" disabled pInputText
                                                    formControlName="_TotalCreditamount">
                                                <label>Total Credit Amount</label>
                                            </span>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>