import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CAdManagementComponent } from './Ad-Management/c-ad-management/c-ad-management.component';
import { VAdManagementComponent } from './Ad-Management/v-ad-management/v-ad-management.component';
import { CBlogComponent } from './blog/c-blog/c-blog.component';
import { VBlogComponent } from './blog/v-blog/v-blog.component';
import { CCmsComponent } from './cms/c-cms/c-cms.component';
import { VCmsComponent } from './cms/v-cms/v-cms.component';
import { CCodSettlementComponent } from './COD-Settlement/c-cod-settlement/c-cod-settlement.component';
import { VCodSettlementComponent } from './COD-Settlement/v-cod-settlement/v-cod-settlement.component';

import { CCouponComponent } from './Coupon/c-coupon/c-coupon.component';
import { VCouponComponent } from './Coupon/v-coupon/v-coupon.component';
import { COnlineStockDeliveryComponent } from './Online-Stock-Delivery/c-online-stock-delivery/c-online-stock-delivery.component';
import { VOnlineStockDeliveryComponent } from './Online-Stock-Delivery/v-online-stock-delivery/v-online-stock-delivery.component';
import { COrderdescriptionComponent } from './Order-description/c-orderdescription/c-orderdescription.component';
import { VOrderdescriptionComponent } from './Order-description/v-orderdescription/v-orderdescription.component';
import { COrderstatusComponent } from './OrderStatus/c-orderstatus/c-orderstatus.component';
import { VOrderstatusComponent } from './OrderStatus/v-orderstatus/v-orderstatus.component';
import { ShippingChargesComponent } from './Shipping/shipping-charges/shipping-charges.component';
import { CSliderManagementComponent } from './Slider-Management/c-slider-management/c-slider-management.component';
import { VSliderManagementComponent } from './Slider-Management/v-slider-management/v-slider-management.component';
import { VCodHistoryComponent } from './v-cod-history/v-cod-history.component';
import { VOrderPendingStatusComponent } from './v-order-pending-status/v-order-pending-status.component';
import { VOrderReturnComponent } from './v-order-return/v-order-return.component';
import { VPaymentHistoryComponent } from './v-payment-history/v-payment-history.component';
import { VPaymentReturnComponent } from './v-payment-return/v-payment-return.component';
import { DeliveryChallanComponent } from './delivery-challan/delivery-challan.component';
import { COrderReturnComponent } from './c-order-return/c-order-return.component';

const routes: Routes = [
  { path: 'cadmanagement', component: CAdManagementComponent },
  { path: 'vadmanagement', component: VAdManagementComponent },
  { path: 'cCoupon', component: CCouponComponent },
  { path: 'vCoupon', component: VCouponComponent },
  { path: 'cslidermanagement', component: CSliderManagementComponent },
  { path: 'vslidermanagement', component: VSliderManagementComponent },
  { path: 'cOrderStatus', component: COrderstatusComponent },
  { path: 'vOrderStatus', component: VOrderstatusComponent },
  { path: 'cCms', component: CCmsComponent },
  { path: 'vCms', component: VCmsComponent },
  { path: 'cOrderDescription', component: COrderdescriptionComponent },
  { path: 'vOrderDescription', component: VOrderdescriptionComponent },
  // {path:'cShipping',component:ShippingChargesComponent},
  { path: 'vShipping', component: ShippingChargesComponent },
  { path: 'PaymentTransaction', component: VPaymentHistoryComponent },
  { path: 'cBlog', component: CBlogComponent },
  { path: 'vBlog', component: VBlogComponent },
  { path: 'VOnlineStockDelivery', component: VOnlineStockDeliveryComponent },
  { path: 'COnlineStockDelivery', component: COnlineStockDeliveryComponent },
  { path: 'VOrderPendingStatus', component: VOrderPendingStatusComponent },
  { path: 'VCODPaymentStatus', component: VCodHistoryComponent },
  { path: 'VMaterialReturnRequest', component: VOrderReturnComponent },
  { path: 'VCodSettlement', component: VCodSettlementComponent },
  { path: 'CCodSettlement', component: CCodSettlementComponent },
  { path: 'VPaymentReturnStatus', component: VPaymentReturnComponent }, 
  { path: 'deliveryChallan', component: DeliveryChallanComponent },
  { path: 'COrderReturn', component: COrderReturnComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EcommerceRoutingModule { }
