import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class BankreconciliationService {
  public _Action: IAction;
  public _BankReconciliationService = environment.BankReconciliationService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(reconsilationdate: Date,userid:number): Observable<any> {
    const headers = this.header;
    var Query = this._BankReconciliationService  + '/PageOnload' +'/' + reconsilationdate.toISOString() + '/' +userid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll(userid:number,branchid:number,roleid:number,fromdate:string,todate:string,_selectedtype:string)
  {
    const headers = this.header;
    var Query = this._BankReconciliationService + '/FetchAll'+ '/' + userid + '/'+branchid+'/'+roleid+'/'+fromdate+'/'+todate+'/'+_selectedtype;
   
    return this.http.get<{data:any}>(Query, { headers }).pipe(map(data=>{
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
     return data; 
    }));
  }
  create(p_BankReconciliation:object)
  {
    const headers = this.header;
    var Query = this._BankReconciliationService ;
   
    return this.http.post<{data:any}>(Query,p_BankReconciliation, { headers });
  }
  
  SearchBankReconsilization(obj:Object){
    const headers = this.header;
    var Query = this._BankReconciliationService +'/SearchBankReconsilization'; 
   
    return this.http.post<{data:any}>(Query,obj, { headers });
  }

  Cancel(bankreconcilationid : number,series:string)
  { 
    const headers = this.header;
    var Query = this._BankReconciliationService  +'/Cancel/'  + bankreconcilationid  + '/' +series; 
   
    return this.http.get<{data:any}>(Query, { headers });
  }

  GetBankBasedOnBranch(obj:object)
  { 
    const headers = this.header;
    var Query = this._BankReconciliationService  +'/GetBankBasedOnBranch/';
    return this.http.post<{data:any}>(Query,obj, { headers });
  }
}
