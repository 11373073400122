<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>View Notifications</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Create" icon="pi pi-plus" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" [routerLink]="['/cNotification']"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">

                <p-tabView #tab [(activeIndex)]="selectedtab">
                    <p-tabPanel header="Inbox">
                        <p-toast></p-toast>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                    <!-- <button pButton pRipple label="" icon="pi pi-plus" class="p-button-success p-mr-2"
                           (click)="openNew()"></button>
                          <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger ">
                          </button>-->
                                </ng-template>
                                <!-- <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                            </ng-template> -->

                            </p-toolbar>
                            <p-table #dt [value]="_Notificationsview" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['subject','username','messagedate']" [rowHover]="true" dataKey="productid" [(selection)]="selectedrows" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>

                                        <th class="text-center" style="width: 120px;">Action </th>

                                        <th pSortableColumn="username">From
                                            <p-sortIcon field="username">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="subject">Subject
                                            <p-sortIcon field="subject">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="messagedate">Date
                                            <p-sortIcon field="messagedate">
                                            </p-sortIcon>
                                        </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_Notificationview>
                                    <tr [ngClass]="{'unread': _Notificationview.isviewed === false}">

                                        <td class="text-center">
                                            <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" (click)="view(_Notificationview)"></button>
                                        </td>
                                        <td>
                                            {{_Notificationview.username}}
                                        </td>
                                        <td>
                                            {{_Notificationview.subject}}
                                        </td>
                                        <td>
                                            {{_Notificationview.messagedate|date:'dd/MM/yyyy hh:mm a'}}
                                        </td>

                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <p-dialog header="Notifications" [(visible)]="OpenAttachmentPopup" [style]="{width: '800px'}" [baseZIndex]="10000" [modal]="true">
                            <table class="normal-table mail-view">
                                <tr>
                                    <td style="width:150px">
                                        <strong>From :</strong>
                                    </td>
                                    <td>
                                        {{Fromname}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>To :</strong>
                                    </td>
                                    <td>
                                        {{Toname}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Date :</strong>
                                    </td>
                                    <td>
                                        {{MessageDate|date:'dd/MM/yyyy hh:mm a' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Subject :</strong>
                                    </td>
                                    <td>
                                        {{Subject}}

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Attachments :</strong>
                                    </td>
                                    <td>
                                        <div class="attachment-lists view">
                                            <a *ngFor="let AL of AttachmentLink" href="javascript:void(0);" (click)="OpenAttachment(AL)">
                                                {{AL.attachmentname}}
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>

                                    <td colspan="2">
                                        <div class="mail-container">
                                            <div [innerHtml]="safeHtml"></div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <ng-template pTemplate="footer">
                                <p-button icon="pi pi-check" (click)="closeAttachmentPopup()" label="Ok" styleClass="p-button-text">
                                </p-button>
                            </ng-template>
                        </p-dialog>

                    </p-tabPanel>
                    <p-tabPanel header="Send Items">
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtSendItems.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                    <!-- <button pButton pRipple label="" icon="pi pi-plus" class="p-button-success p-mr-2"
                           (click)="openNew()"></button>
                          <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger ">
                          </button>-->
                                </ng-template>
                                <!-- <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                            </ng-template> -->

                            </p-toolbar>
                            <p-table #dtSendItems [value]="_NotificationsSenditmesview" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['subject','toname','messagedate']" [rowHover]="true" dataKey="productid" [(selection)]="selectedrows"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>

                                        <th class="text-center" style="width: 120px;">Action </th>

                                        <th pSortableColumn="toname">To Name
                                            <p-sortIcon field="toname">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="subject">Subject
                                            <p-sortIcon field="subject">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="messagedate">Date
                                            <p-sortIcon field="messagedate">
                                            </p-sortIcon>
                                        </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_Notificationview>
                                    <tr>

                                        <td class="text-center">
                                            <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" (click)="view(_Notificationview)"></button>
                                        </td>
                                        <td>
                                            {{_Notificationview.toname}}
                                        </td>
                                        <td>
                                            {{_Notificationview.subject}}
                                        </td>
                                        <td>
                                            {{_Notificationview.messagedate|date:'dd/MM/yyyy hh:mm a'}}
                                        </td>

                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <p-dialog header="Notifications" [(visible)]="OpenAttachmentPopup" [style]="{width: '600px'}" [baseZIndex]="10000" [modal]="true">
                            <table class="normal-table mail-view">
                                <tr>
                                    <td style="width:150px">
                                        <strong>From :</strong>
                                    </td>
                                    <td>
                                        {{Fromname}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>To :</strong>
                                    </td>
                                    <td>
                                        {{Toname}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Date :</strong>
                                    </td>
                                    <td>
                                        {{MessageDate|date:'dd/MM/yyyy hh:mm a' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Subject :</strong>
                                    </td>
                                    <td>
                                        {{Subject}}

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Attachments :</strong>
                                    </td>
                                    <td>
                                        <div class="attachment-lists view">
                                            <a *ngFor="let AL of AttachmentLink" href="javascript:void(0);" (click)="OpenAttachment(AL)">
                                                {{AL.attachmentname}}
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>

                                    <td colspan="2">
                                        <div class="mail-container">
                                            <div [innerHtml]="safeHtml"></div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <ng-template pTemplate="footer">
                                <p-button icon="pi pi-check" (click)="closeAttachmentPopup()" label="Ok" styleClass="p-button-text">
                                </p-button>
                            </ng-template>
                        </p-dialog>

                    </p-tabPanel>


                </p-tabView>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>