import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { ToolbarModule } from 'primeng/toolbar';
import { RatingModule } from 'primeng/rating';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChipsModule } from 'primeng/chips';
import { FieldsetModule, } from 'primeng/fieldset';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { HttpClientModule } from '@angular/common/http';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { SalesRoutingModule } from './sales-routing.module';
import { CSalesQuotationComponent } from './sales-quotation/c-sales-quotation/c-sales-quotation.component';
import { VSalesQuotationComponent } from './sales-quotation/v-sales-quotation/v-sales-quotation.component';
import { CSalesOrderComponent } from './sales-order/c-sales-order/c-sales-order.component';
import { VSalesOrderComponent } from './sales-order/v-sales-order/v-sales-order.component';
import { CDamagedproductpricedetailsComponent } from './damagedproductpricedetails/c-damagedproductpricedetails/c-damagedproductpricedetails.component';
import { VDamagedproductpricedetailsComponent } from './damagedproductpricedetails/v-damagedproductpricedetails/v-damagedproductpricedetails.component';
import { VSalesreturnComponent } from './salesreturn/v-salesreturn/v-salesreturn.component';
import { CSalesreturnComponent } from './salesreturn/c-salesreturn/c-salesreturn.component';
import { CPricecheckingComponent } from './Pricechecking/c-pricechecking/c-pricechecking.component';
import { VPricecomparisonComponent } from './Pricecomparison/v-pricecomparison/v-pricecomparison.component';
import { CPricecomparisonComponent } from './Pricecomparison/c-pricecomparison/c-pricecomparison.component';
import { VSalesinvoicetaxComponent } from './salesinvoicetax/v-salesinvoicetax/v-salesinvoicetax.component';
import { CSalesinvoicetaxComponent } from './salesinvoicetax/c-salesinvoicetax/c-salesinvoicetax.component';
import { CSalesinvoiceposComponent } from './salesinvoicepos/c-salesinvoicepos/c-salesinvoicepos.component';
import { VSalesinvoiceposComponent } from './salesinvoicepos/v-salesinvoicepos/v-salesinvoicepos.component';
import { VProductpricechangeComponent } from './Productpricechange/v-productpricechange/v-productpricechange.component';
import { CProductpricechangeComponent } from './Productpricechange/c-productpricechange/c-productpricechange.component';
import { VDirectsalesreturnComponent } from './directsalesreturn/v-directsalesreturn/v-directsalesreturn.component';
import { CDirectsalesreturnComponent } from './directsalesreturn/c-directsalesreturn/c-directsalesreturn.component';
import { SalesmultiswipeComponent } from './salesmultiswipe/salesmultiswipe.component';
import { CQuickSalesInvoiceComponent } from './quick-sales-invoice/c-quick-sales-invoice/c-quick-sales-invoice.component';
import { VQuickSalesInvoiceComponent } from './quick-sales-invoice/v-quick-sales-invoice/v-quick-sales-invoice.component';


@NgModule({
  declarations: [CSalesQuotationComponent, VSalesQuotationComponent, CSalesOrderComponent, VSalesOrderComponent,
    CDamagedproductpricedetailsComponent, VDamagedproductpricedetailsComponent
    , VSalesreturnComponent, CSalesreturnComponent, CPricecheckingComponent,
    CPricecomparisonComponent, VPricecomparisonComponent, CSalesinvoicetaxComponent, VSalesinvoicetaxComponent, CSalesinvoiceposComponent,
    VSalesinvoiceposComponent, CProductpricechangeComponent, VProductpricechangeComponent, VDirectsalesreturnComponent, CDirectsalesreturnComponent, SalesmultiswipeComponent, CQuickSalesInvoiceComponent, VQuickSalesInvoiceComponent

  ],
  imports: [
    CommonModule,
    SalesRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChipsModule,
    FieldsetModule,
    AutoCompleteModule,
    OverlayPanelModule,
    CheckboxModule,
    PasswordModule,
    TabViewModule,
    ProgressSpinnerModule
  ]
})
export class SalesModule { }
