import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ReceiptService } from 'src/app/core/Services/accounts/receipt.service';
import { RptpaymentService } from 'src/app/core/Services/reports/Accounting/RptPayment/rptpayment.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Receiptview } from 'src/app/core/Views/Receiptview';

import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-receipt',
  templateUrl: './v-receipt.component.html',
  styleUrls: ['./v-receipt.component.css']
})
export class VReceiptComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _receiptsview: Receiptview[];
  _receiptview: Receiptview;
  _userid = sessionStorage["userid"];
  _Action: IAction;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _series = sessionStorage["Series"];
  _IsProgressSpinner: boolean = true;
  cols: any[];
  fromdate: Date;
  todate: Date;
  mindate: Date;
  maxdate: Date;
  datepipe = new DatePipe('en-US');
  _roleid = sessionStorage["currentRole"]
  _branchid: number;
  // _Action:IAction;
  constructor(private fb: FormBuilder,
    public exportUtility: ExportUtility,
    private _ReceiptService: ReceiptService,
    private router: Router,
    private utility: CommonUtilities,
    private confirmationService: ConfirmationService,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,
    private _ReportService: RptpaymentService) {

  }
  ngOnInit(): void {
    
    

    this.utility.pageLoadScript();

    this._Action = this._AccessRightsService.getAccessRights(69);
    this.HotKeyIntegration();
    this.cols = [
      { field: 'voucherno', header: 'Voucher No' },
      { field: 'receiptdate', header: 'Receipt Date' },
      { field: 'receiptmodename', header: 'Receipt Mode Name' },
      { field: 'currencyname', header: 'Currency Name' },
      { field: 'receiptamount', header: 'Receipt Amount' },
      { field: 'advanceamount', header: 'Advance Amount' },
      { field: 'fromaccountname', header: 'From Acc Name' },
      { field: 'toaccountname', header: 'To Acc Name' },
      { field: 'narration', header: 'Narration' },
      { field: 'receiptstatusdesc', header: 'Receipt Status ' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
      { field: 'ispossales', header: 'Is POS Sales' },
    ]
    // this.fromdate = new Date();
    // this.todate = new Date();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.SetFromToDate();
    this.Fetchall();

  }
  SetFromToDate(){
    if(sessionStorage['Environmentenddate'] == 'null'){
      this.fromdate = new Date();
      this.todate = new Date();
      this. maxdate = new Date();
    }
    else{
      this. fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }    
  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._ReceiptService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result: any) => {
      if (result && result.tranStatus.result) {
        const resultJSON = JSON.parse(JSON.stringify(result));

        this._receiptsview = resultJSON.receiptviews;
        this._IsProgressSpinner = false;
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
  }

  view(event) {
    this.router.navigate(['/cReceipt'], { state: { receiptid: event.receiptid, voucherno: event.voucherno, action: 'view' } });
  }
  exportExcel() {
    let ReceiptList = this.GetReceiptList();
    this.exportUtility.exportExcel(ReceiptList, 'Receipt', 'xlsx');
  }

  ExportToPdf() {
    let ReceiptList = this.GetReceiptList();
    this.exportUtility.ExportToPdf(ReceiptList, 'Receipt.pdf');
  }


  GetReceiptList() {
    if (!this._receiptsview || !this._receiptsview.length)
      return;
    let ReceiptList = [];
    this._receiptsview.forEach(element => {
      let receipt: any = {};
      receipt["Voucher No"] = element.voucherno;
      receipt["Receipt Date"] = element.receiptdate;
      receipt["Receipt Mode Name"] = element.receiptmodename;
      receipt["Currency Name"] = element.currencyname;
      receipt["Receipt Amount"] = element.receiptamount;
      receipt["Advance Amount"] = element.advanceamount;
      receipt["From Acc Name"] = element.fromaccountname;
      receipt["To Acc Name"] = element.toaccountname;
      receipt["Narration"] = element.narration;
      receipt["Receipt Status "] = element.receiptstatusdesc;
      receipt["Created By"] = element.createdbyname;
      receipt["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      receipt["Is POS Sales"] = element.ispossales;
      ReceiptList.push(receipt);
    });
    return ReceiptList;
  }
  GoToCreate(event) {
    this.router.navigate(['/cReceipt']);
  }
  Cancel(event) {
    let voucherno = event.voucherno;
    let series = event.series;
    this._IsProgressSpinner = true;
    this._ReceiptService.Cancel(voucherno, series, this._userid).subscribe((result: any) => {
      if (result && result.tranStatus.result) {
        this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
        this.Fetchall();
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].message);
      }

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Voucher <b>"' + event.voucherno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  Print(event) {
    var voucherno = event.voucherno;
    var branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;
    // this._ReportService.ReceiptPrint(voucherno, branchid);
    this._ReceiptService.ReceiptPrint({ "voucherno": voucherno, "branchid": branchid }).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}