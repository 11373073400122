<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Tender</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" (click)="ExportToPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                        </ng-template>

                    </p-toolbar>

                    <p-table #dt [value]="_tenderviews" [rows]="10" [paginator]="true" [columns]="cols" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['tenderrefno','tenderrefdate','customername','tenderamount','tenderdescription','tenderstatusdesc']" [rowHover]="true"
                        dataKey="productid" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>

                                <th class="text-center" style="width: 120px;">Action </th>
                                <th pSortableColumn="tenderrefno">Tender Reference No.
                                    <p-sortIcon field="tenderrefno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tenderrefdate">Tender Date.
                                    <p-sortIcon field="tenderrefdate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="customername">Tender Party Name.
                                    <p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tenderamount">Tender Amount
                                    <p-sortIcon field="tenderamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tenderdescription">Tender Description
                                    <p-sortIcon field="tenderdescription">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tenderstatusdesc">Status
                                    <p-sortIcon field="tenderstatusdesc">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_tenderview>
                            <tr>

                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_tenderview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update" (click)="edit(_tenderview)"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Delete" class="p-button p-button-danger" [disabled]="_tenderview.tenderstatusid == '192'||!_Action._Delete" (click)="confirm(_tenderview)"></button>

                                </td>
                                <td>
                                    {{_tenderview.tenderrefno}}
                                </td>
                                <td>
                                    {{_tenderview.tenderrefdate | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_tenderview.customername}}
                                </td>
                                <td>
                                    {{_tenderview.tenderamount}}
                                </td>
                                <td>
                                    {{_tenderview.tenderdescription}}
                                </td>
                                <td>
                                    {{_tenderview.tenderstatusdesc}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </div>
        </div>
    </div>