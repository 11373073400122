import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  public _BlogService = environment.BlogService;
  public _httpOptions;


  constructor(private http:HttpClient) { }
  
  PageOnload(): Observable<any> {
    var Query = this._BlogService ;
    console.log(Query);
    return this.http.get<{data:any}>(Query);
  }

  
  Create(blog:FormData) {
    var Query = this._BlogService+ '/Create' ;
    console.log(Query);
    return this.http.post<{data:any}>(Query,blog);
  }

  Update(blog:FormData){
    var Query = this._BlogService+ '/Update' ;
    console.log(Query);
    return this.http.post<{data:any}>(Query,blog);
  }
  
  View(blogid:number):  Observable<any>{
    var Query = this._BlogService+ '/View' +'/'+ blogid;
    console.log(Query);
    return this.http.get(Query);
  }
  
  FetchAll():  Observable<any>{
    var Query = this._BlogService+ '/FetchAll' ;
    console.log(Query);
    return this.http.get<{data:any}>(Query);
  }
  Cancel(blog:number){
    var Query = this._BlogService+ '/Cancel' + '/'+blog ;
    console.log(Query);
    return this.http.get<{data:any}>(Query);
  }
}
