import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkshopRoutingModule } from './workshop-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { ToolbarModule } from 'primeng/toolbar';
import { RatingModule } from 'primeng/rating';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChipsModule } from 'primeng/chips';
import { FieldsetModule, } from 'primeng/fieldset';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { HttpClientModule } from '@angular/common/http';
import { TabViewModule } from 'primeng/tabview';
import { EditorModule } from 'primeng/editor';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxImageCompressService } from 'ngx-image-compress';
import { CServiceRequestComponent } from './Service-Request/c-service-request/c-service-request.component';
import { VServiceRequestComponent } from './Service-Request/v-service-request/v-service-request.component';
import { CJobCardComponent } from './job-card/c-job-card/c-job-card.component';
import { VJobCardComponent } from './job-card/v-job-card/v-job-card.component';
import { CServiceWorkOrderComponent } from './service-work-order/c-service-work-order/c-service-work-order.component';
import { VServiceWorkOrderComponent } from './service-work-order/v-service-work-order/v-service-work-order.component';
import { CServiceProductDeliveryComponent } from './service-product-delivery/c-service-product-delivery/c-service-product-delivery.component';
import { VServiceProductDeliveryComponent } from './service-product-delivery/v-service-product-delivery/v-service-product-delivery.component';
import { CServiceInvoiceComponent } from './service-invoice/c-service-invoice/c-service-invoice.component';
import { VServiceInvoiceComponent } from './service-invoice/v-service-invoice/v-service-invoice.component';
import { CPurchaseServiceInvoiceComponent } from './purchase-service-invoice/c-purchase-service-invoice/c-purchase-service-invoice.component';
import { VPurchaseServiceInvoiceComponent } from './purchase-service-invoice/v-purchase-service-invoice/v-purchase-service-invoice.component';
import { WorkshopSummaryReportComponent } from './workshop-summary-report/workshop-summary-report/workshop-summary-report.component';


@NgModule({
  declarations: [CServiceRequestComponent, VServiceRequestComponent, CJobCardComponent, VJobCardComponent, CServiceWorkOrderComponent, VServiceWorkOrderComponent, CServiceProductDeliveryComponent, VServiceProductDeliveryComponent, CServiceInvoiceComponent, VServiceInvoiceComponent, CPurchaseServiceInvoiceComponent, VPurchaseServiceInvoiceComponent, WorkshopSummaryReportComponent],
  imports: [
    CommonModule,
    WorkshopRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChipsModule,
    FieldsetModule,
    AutoCompleteModule,
    OverlayPanelModule,
    CheckboxModule,
    PasswordModule,
    HttpClientModule,
    TabViewModule,
    ImageCropperModule,
    TooltipModule,
    ProgressSpinnerModule,
    EditorModule
  ],
  providers:[NgxImageCompressService]
})
export class WorkshopModule { }
