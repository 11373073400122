import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Vehicle } from '../../Models/Vehicle';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  public _Action: IAction;
  public _VehicleService = environment.VehicleService;
  public _httpOptions;
  public _VehicleList: Vehicle[] = [];
  token: any;
	header: any;
  constructor(private http: HttpClient) { 
  this.token = sessionStorage['AuthToken'];
  this.header = this.createHeaders(this.token, 1)
}
createHeaders(token: any, type: number): HttpHeaders {
  return new HttpHeaders({
    Authorization: `Bearer ${token}`, // Include your token here	
  });
}
  PageOnLoad(p_vehicleid: number,branchid: number): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._VehicleService + '/' + p_vehicleid + '/' + branchid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll(userid:number,branchid:number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._VehicleService + '/FetchAllLoad'  + '/' + userid + '/' + branchid;
   

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const headers = this.createHeaders(this.token, 2)
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      return data;
    }));
  }

  create(p_branch: FormData) {
    const headers = this.createHeaders(this.token, 2)
    //console.log("Create");
    var Query = this._VehicleService;
    return this.http.post<{ data: any }>(Query, p_branch, { headers });
  }
  edit(p_branch: FormData) {
    const headers = this.createHeaders(this.token, 2)
    //console.log("Update");
    var Query = this._VehicleService;
   
    return this.http.put<{ data: any }>(Query, p_branch, { headers });
  }
  Cancel(vehicleid: number,userid:number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._VehicleService + '/Cancel/' + vehicleid + '/' + userid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
}
