import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Currency } from 'src/app/core/Models/Currency';
import { TellercashdaysettlementService } from 'src/app/core/Services/accounts/tellercashdaysettlement.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Tellercashview } from 'src/app/core/Views/Tellercashview';
import { Tellernameview } from 'src/app/core/Views/Tellernameview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { IAction } from 'src/app/core/Services/security/IActions';
import { element } from 'protractor';


@Component({
  selector: 'app-rpt-tellercash',
  templateUrl: './rpt-tellercash.component.html',
  styleUrls: ['./rpt-tellercash.component.css']
})
export class RptTellercashComponent implements OnInit {

  fromdate: Date;
  todate: Date;
  tellerids: any[] = [];
  currencyid: any[] = [];
  branchids: number[] = [];
  selectedrows: any[] = [];
  _userid = sessionStorage["userid"];
  _IsProgressSpinner: boolean = true;
  _tellernames: Tellernameview[];
  _branches: Branch[];
  _Tellercashviews: any[];
  _Tellerbankviews: any[];
  cash_cols: any[];
  bank_cols: any[];
  datepipe = new DatePipe('en-Us');
  _CurrencyList: Currency[];
  _TotalReceivableAmount: number = 0;
  _TotalPaidAmount: number = 0;
  _TotalBalanceAmount: number = 0;
  _Action: IAction;
  _Creditnoteadjustmentviews: any[] = [];
  mindate: Date;
  maxdate: Date;

  constructor(private _TellerCashDaySettlementService: TellercashdaysettlementService,
    private _CustomExceptionService: CustomExceptionService, private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService, private exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(128);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.cash_cols = [
      { field: 'branchname', header: 'Branch Name' },
      { field: 'tellername', header: 'Teller Name' },
      { field: 'salesdate', header: 'Sales Date' },
      { field: 'currencyname', header: 'Currency' },
      { field: 'receivableamount', header: 'Total Receivable Amount' },
      { field: 'paidamount', header: 'Total Paid Amount' },
      { field: 'balance', header: 'Total Balance Amount' }
    ];

    this.bank_cols = [
      { field: 'branchname', header: 'Branch Name' },
      { field: 'tellername', header: 'Teller Name' },
      { field: 'bankname', header: 'Bank Name' },
      { field: 'transactiondate', header: 'Sales Date' },
      { field: 'transactiontypename', header: 'Transaction Type' },
      { field: 'billno', header: 'Bill No' },
      { field: 'receiptno', header: 'Rec / Pay No' },
      { field: 'totalamount', header: 'Transaction Amount' },
      { field: 'reconciledstatus', header: 'Reconcilation Status' },
      { field: 'reconcilationdate', header: 'Reconcilation Date' }
    ];

    this._IsProgressSpinner = true;
    this._branches = [];
    this._TellerCashDaySettlementService.ReportPageOnLoad({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branches = resultJSON.branchlist;
      this._CurrencyList = resultJSON.currencylist;

      this._IsProgressSpinner = false;
      this.SetFromToDate();
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  SetFromToDate() {
    let date = new Date();
    this.fromdate = date;
    this.todate = date;
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }

    this.branchids = [];
    this.currencyid = [];
    this.branchids.push(parseInt(sessionStorage["currentbranchid"] || 0));
    this.currencyid.push(1);
    this.OnChangeBranch();
  }

  OnChangeBranch() {
    let data = {
      branchids: this.branchids,
      userid: sessionStorage["userid"]
    };
    this._IsProgressSpinner = true;
    this._tellernames = [];
    this._Tellercashviews = [];
    this._TellerCashDaySettlementService.TellerCashReportGetTellerNames(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.tellerids = [];
      this._tellernames = resultJSON.tellernameviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GenerateReport() {
    this._Tellercashviews = [];
    this._Tellerbankviews = [];
    this._Creditnoteadjustmentviews = [];
    if (!this.branchids.length) {
      this._CustomExceptionService.handleWarning("Select atleast one branch.");
      return;
    }

    // if (!this.tellerids.length) {
    //   this._CustomExceptionService.handleWarning("Select atleast one teller.");
    //   return;
    // }

    if (!this.fromdate) {
      this._CustomExceptionService.handleWarning("Select From Date.");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleWarning("Select ToDate.");
      return;
    }
    if (!this.currencyid) {
      this._CustomExceptionService.handleWarning("Please Select Currency.");
      return;
    }
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(this.fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(this.todate, 'yyyy-MM-dd');
    let data = {
      branchids: this.branchids,
      userid: sessionStorage["userid"],
      tellerids: this.tellerids || [],
      fromDate: fromdate,
      toDate: todate,
      currencies: this.currencyid
    };

    this._IsProgressSpinner = true;
    this._TellerCashDaySettlementService.TellerCashReportGetReport(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Tellercashviews = resultJSON.tellercashwisereports || [];
      this._Tellerbankviews = resultJSON.tellerbanktransactionsview || [];
      this._Creditnoteadjustmentviews = resultJSON.rptTellerCashAdjustmentDetails || [];

      this._TotalReceivableAmount = this._Tellercashviews.reduce((sum, current) => sum + current.receivableamount, 0);
      this._TotalPaidAmount = this._Tellercashviews.reduce((sum, current) => sum + current.paidamount, 0);
      this._TotalBalanceAmount = this._Tellercashviews.reduce((sum, current) => sum + current.balance, 0);

      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  clear() {
    this.tellerids = [] = [];
    this.currencyid = [] = [];
    this.branchids = [] = [];
    this._Tellercashviews = [] = [];
    this._Tellerbankviews = [] = [];
    this._Creditnoteadjustmentviews = [] = [];
    this.SetFromToDate();
  }

  exportExcel(type: string) {
    if (type == 'cash') {
      let tellerlist = this.GetCashList();
      this.exportUtility.exportExcel(tellerlist, 'TellerCashReport', 'xlsx');
    }
    else if (type == 'credit') {
      let advanceandcreditlist = this.advanceandcreditlist();
      this.exportUtility.exportExcel(advanceandcreditlist, 'Advance_and_Credit_list', 'xlsx');
    }
    else {
      let tellerlist = this.GetBankList();
      this.exportUtility.exportExcel(tellerlist, 'TellerBankReport', 'xlsx');
    }
  }

  ExportToPdf(type: string) {
    if (type == 'cash') {
      let tellerlist = this.GetCashList();
      this.exportUtility.ExportToPdf(tellerlist, 'TellerCashReport.pdf');
    }
    else {
      let tellerlist = this.GetBankList();
      this.exportUtility.ExportToPdf(tellerlist, 'TellerBankReport.pdf');
    }
  }
  ExportToPdfCredit() {
    let advanceandcreditlist = this.advanceandcreditlist();
    const data = advanceandcreditlist.map(Object.values);
    const head = [[
      'Sales Date',
      'Branch Name',
      'Currency Name',
      'Credit Note Adjusted Amount',
      'Advance Adjusted Amount',
      'Payment Amount',
      'Total Amount'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        // doc.autoTable(this.exportColumns, this._ExpenseReportviews);
        doc.save('Advance_and_Credit_list.pdf');
      })
    })
  }
  ExportToPdf1() {
    let branchwisestocklists = this.GetCashList();
    const data = branchwisestocklists.map(Object.values);
    const head = [[
      'Branch Name',
      'Teller Name',
      'Sales Date',
      'Currency',
      'Receivable Amount',
      'Paid Amount',
      'Balance Amount',
      'Total Receivable Amount',
      'Total Paid Amount',
      'Total Balance Amount'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          }, headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          }, bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('TellerCashReport.pdf');
      })
    })
  }
  GetCashList() {
    let tellerlist = [];
    this._Tellercashviews.forEach((element, index) => {
      let country: any = {};
      country["Branch Name"] = element.branchname;
      country["Teller Name"] = element.tellername;
      country["Sales Date"] = this.datepipe.transform(element.salesdate, 'dd/MM/yyyy');
      country["Currency"] = element.currencyname;
      country["Receivable Amount"] = element.receivableamount;
      country["Paid Amount"] = element.paidamount;
      country["Balance Amount"] = element.balance;
      if (index == 0) {
        country["Total Receivable Amount"] = this._TotalReceivableAmount;
        country["Total Paid Amount"] = this._TotalPaidAmount;
        country["Total Balance Amount"] = this._TotalBalanceAmount;
      }
      else {
        country["Total Receivable Amount"] = "";
        country["Total Paid Amount"] = "";
        country["Total Balance Amount"] = "";
      }
      tellerlist.push(country);
    });
    return tellerlist;
  }

  GetBankList() {
    let tellerlist = [];
    this._Tellerbankviews.forEach(element => {
      let country: any = {};
      country["Branch Name"] = element.branchname;
      country["Teller Name"] = element.tellername;
      country["Bank Name"] = element.bankname;
      country["Sales Date"] = this.datepipe.transform(element.transactiondate, 'dd/MM/yyyy');
      country["Transaction Type"] = element.transactiontypename;
      country["Bill No"] = element.billno;
      country["Rec / Pay No"] = element.receiptno;
      country["Transaction Amount"] = element.totalamount;
      country["Reconcilation Status"] = element.reconciledstatus;
      country["Reconcilation Date"] = this.datepipe.transform(element.reconcilationdate, 'dd-MM-yyyy');
      tellerlist.push(country);
    });
    return tellerlist;
  }
  advanceandcreditlist() {
    let advanceandcredit = [];
    this._Creditnoteadjustmentviews.forEach(element => {
      let Advance: any = {};
      Advance["Sales Date"] = this.datepipe.transform(element.salesDate, 'dd/MM/yyyy');
      Advance["Branch Name"] = element.branchName;
      Advance["Currency Name"] = element.currencyName;
      Advance["Credit Note Adjusted Amount"] = element.creditNoteAmount;
      Advance["Advance Adjusted Amount"] = element.advanceAmount;
      Advance["Payment Amount"] = element.paymentAmount;
      Advance["Total Amount"] = element.totalAmount;
      advanceandcredit.push(Advance);
    });
    return advanceandcredit;
  }
}
