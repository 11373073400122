import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { WorkshopSummaryReportService } from 'src/app/core/Services/workshop/workshop-summary-report.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
  selector: 'app-workshop-summary-report',
  templateUrl: './workshop-summary-report.component.html',
  styleUrls: ['./workshop-summary-report.component.css']
})
export class WorkshopSummaryReportComponent implements OnInit {
  _branchlist: any[] = []
  _requestTypeList: any[] = []
  _serviceReqNoList: any[] = []
  _workshopsummaryreport: any[] = []
  _IsProgressSpinner: boolean = true;
  branchid: any[] = []
  requesttype: any[] = []
  servicerequestno: any[] = []
  fromdate: Date;
	todate: Date;
  datepipe = new DatePipe('en-US');
  OpenJC: any = 0
  ClosedJC: any = 0
  OpenSWO: any = 0
  ClosedSWO: any = 0
  OpenDel: any = 0
  ClosedDel: any = 0
  _Action:IAction;
  mindate: Date;
	maxdate: Date;
  constructor(
    private _CustomExceptionService: CustomExceptionService,
    private _WorkshopSummaryReportService: WorkshopSummaryReportService,
    private utility: CommonUtilities,
    private _AccessRightsService : AccessRightsService,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(338);
    this.utility.pageLoadScript();
    this._IsProgressSpinner = false;
    this.todate = new Date()
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.SetFromToDate();
    let headerbranch = parseInt(sessionStorage["BranchID"])
    this.branchid.push(headerbranch)
    let TaskName = "PageOnLoad"
    let Data = {
      "TaskName": TaskName,
      "BranchId": this.branchid,
      "RequestType": null,
      "ServiceReqNo": null,
      "FromDate": null,
      "ToDate": null
    }
    this._IsProgressSpinner = true;
    this._WorkshopSummaryReportService.GenerateReport(Data).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        console.log(result, 'objectes');
        this._branchlist = result.branchList;
        this._requestTypeList = result.requestTypeList;
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
        this._IsProgressSpinner = false;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  SetFromToDate(){
		if(sessionStorage['Environmentenddate'] == 'null'){
			this.fromdate = new Date();
			this.todate = new Date();
			this.maxdate = new Date();
		}
		else{
			this.fromdate = new Date(sessionStorage['Environmentenddate'])
			this.todate = new Date(sessionStorage['Environmentenddate'])
		}
		this.fromdate.setDate(this.fromdate.getDate() - 1);
	}

  GetServiceRequest() {
    if (!this.branchid || !this.branchid.length) {
      this.requesttype = []
      this.servicerequestno = []
      this._CustomExceptionService.handleError("Please Select Atleast One Branch");
      return;
    }
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      this.requesttype = []
      this.servicerequestno = []
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      this.requesttype = []
      this.servicerequestno = []
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      this.requesttype = []
      this.servicerequestno = []
      return;
    }
    let FromDate = this.fromdate
    let ToDate = this.todate
    let TaskName = "GetServiceRequest"
    let RequestType = this.requesttype
    let Data = {
      "TaskName": TaskName,
      "BranchId": this.branchid,
      "RequestType": RequestType,
      "ServiceReqNo": null,
      "FromDate": FromDate,
      "ToDate": ToDate
    }
    this._IsProgressSpinner = true;
    this._WorkshopSummaryReportService.GenerateReport(Data).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        console.log(result, 'objectes');
        this._serviceReqNoList = result.serviceReqNoList;
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
        this._IsProgressSpinner = false;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  OnChangeBranch() {
    this.requesttype = []
    this.servicerequestno = []
  }
  GenerateReport() {
    if (!this.branchid || !this.branchid.length) {
      this.requesttype = []
      this.servicerequestno = []
      this._CustomExceptionService.handleError("Please Select Atleast One Branch");
      return;
    }
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      this.requesttype = []
      this.servicerequestno = []
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      this.requesttype = []
      this.servicerequestno = []
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      this.requesttype = []
      this.servicerequestno = []
      return;
    }
    if (!this.requesttype || !this.requesttype.length) {
      this._CustomExceptionService.handleError("Please Select Atleast One request Type");
      return
    }
    let FromDate = this.fromdate
    let ToDate = this.todate
    let RequestType = this.requesttype
    let TaskName = "GenerateReport"
    let Data = {
      "TaskName": TaskName,
      "BranchId": this.branchid,
      "RequestType": RequestType,
      "ServiceReqNo": this.servicerequestno,
      "FromDate": FromDate,
      "ToDate": ToDate
    }
    this._IsProgressSpinner = true;
    this._WorkshopSummaryReportService.GenerateReport(Data).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        console.log(result, 'objectes');
        if ((this.servicerequestno.length > 0) && (result.generateReport.length == 0)) {
          this._IsProgressSpinner = false;
          this._CustomExceptionService.handleWarning("No Data Available... Please Check The Job Card Screen Against The Selected Service Request No");
          return
        }
        else if (result.generateReport.length == 0) {
          this._IsProgressSpinner = false;
          this._CustomExceptionService.handleWarning("No Data Available");
          return
        } else {
          this._workshopsummaryreport = result.generateReport;
          this.OpenJC = result.generateReportTotal.openjc
          this.ClosedJC = result.generateReportTotal.closedjc
          this.OpenSWO = result.generateReportTotal.openswo
          this.ClosedSWO = result.generateReportTotal.closedswo
          this.OpenDel = result.generateReportTotal.opendel
          this.ClosedDel = result.generateReportTotal.closeddel
        }
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
        this._IsProgressSpinner = false;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear() {
    this.requesttype = []
    this.servicerequestno = []
    this._serviceReqNoList = []
    this.branchid = []
    this._workshopsummaryreport = [];
    let headerbranch = parseInt(sessionStorage["BranchID"])
    this.branchid.push(headerbranch)
    this.fromdate = new Date(this.fromdate); 
    this.fromdate.setDate(this.maxdate.getDate() - 1);
    this.todate = this.maxdate;
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'Workshop_Summary_Report', 'xlsx');
  }
  ExportToPdf() {
    let finalizedList = this.GetAssetRegistrationList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Service Branch',
      'Request Type',
      'Customer / Product Name',
      'Ser.Req Date',
      'Ser.Req No',
      'Ser.Product Name',
      'Qty',
      'Service At',
      'Employee / Supplier Name',
      'Job Card Status',
      'Service Work Order Status',
      'Delivery Status',
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Workshop_Summary_Report.pdf');
      })
    })
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._workshopsummaryreport.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Service Branch"] = element.servicebranchname;
      assetregistration["Request Type"] = element.reftypename;
      assetregistration["Customer / Product Name"] = element.productorcustomername;
      assetregistration["Ser.Req Date"] = this.datepipe.transform(element.servicerequestdate, 'dd/MM/yyyy');;
      assetregistration["Ser.Req No"] = element.servicerequestnumber;
      assetregistration["Ser.Product Name"] = element.serviceproductname;
      assetregistration["Qty"] = element.srquantity;
      assetregistration["Service At"] = element.serviceatname;
      assetregistration["Employee / Supplier Name"] = element.employeeorsuppliername;
      assetregistration["Job Card Status"] = element.jobcardstatusname;
      assetregistration["Service Work Order Status"] = element.swostatusname;
      assetregistration["Delivery Status"] = element.deliverystatusname;
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
}