<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Account Group</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add"
                                class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                            <!--                            <button pButton pRipple label="" icon="pi pi-plus" class="p-button-success p-mr-2"
                                (click)="openNew()"></button>
                            <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger ">
                            </button>-->
                        </ng-template>
                        <ng-template pTemplate="right">

                            <!-- <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button> -->
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                (click)="ExportToPdf()" class="p-button-warning p-mr-2" pTooltip="PDF"
                                tooltipPosition="bottom"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_accountgroupsview" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['accountgroupname','accounttypename','accountparentgroupname','accountgroupstatusname']"
                        [rowHover]="true" dataKey="accounttypecode" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 120px;">Action</th>
                                <th pSortableColumn="accountgroupname">Account Group Name <p-sortIcon
                                        field="accountgroupname"></p-sortIcon>
                                </th>

                                <th pSortableColumn="accountparentgroupname">Parent Group <p-sortIcon
                                        field="accountparentgroupname"></p-sortIcon>
                                </th>

                                <th pSortableColumn="accountgroupstatusname">Status<p-sortIcon
                                        field="accountgroupstatusname"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_AccountGroupview>
                            <tr>

                                <td class="text-center">
                                    <!-- 19.on clicking view button, it show the view screen. -->
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="!_Action._View" (click)="view(_AccountGroupview)"></button>
                                    <!-- 22.on clicking update button, it show the update screen. -->
                                    <!-- <button pButton pRipple icon="las la-pen" title="Edit"
                                        class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update"
                                        (click)="edit(_AccountGroupview)"></button> -->
                                    <!-- <button pButton pRipple icon="pi pi-trash" title="Delete"
                                        class="p-button p-button-danger" [disabled]="!_Action._Delete"
                                        (click)="delete(_AccountGroupview)"></button> -->
                                </td>
                                <td>
                                    {{_AccountGroupview.accountgroupname }}
                                </td>

                                <td>
                                    {{_AccountGroupview.accountparentgroupname }}
                                </td>

                                <td>
                                    {{_AccountGroupview.accountgroupstatusname }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>