import { Injectable } from '@angular/core';
import { IAction } from '../../../security/IActions';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurchasetrackingreportService {
  public _Action: IAction;
  public _httpOptions;
  public _RptpurchasetrackingService = environment.RptpurchasetrackingService;
  token: any;
  header: any;

  constructor(private http: HttpClient ) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  pageonload(data: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptpurchasetrackingService + '/PageInitialize';
    return this.http.post<{ data: any }>(Query, data, { headers });
  }

  GetPurchaseTracking(data: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptpurchasetrackingService + '/GetPurchaseTracking';
    return this.http.post<{ data: any }>(Query, data, { headers });
  }

  GetPurchaseTrackingDetails(data: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptpurchasetrackingService + '/GetPurchaseTrackingDetails';
    return this.http.post<{ data: any }>(Query, data, { headers });
  }
}
