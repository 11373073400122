<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Purchase Order</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" [disabled]="isDisabledsave" type="submit" (click)="onSave()" title="Save" icon="pi pi-save" class=""></button>
                            <button pButton pRipple label="" [disabled]="isDisablereset" type="button" (click)="reset($event)" title="Clear" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button" [disabled]="isDisabledsendapparoval" (click)="SendToApproval($event)"></button>
                            <button pButton pRipple title="Send Email" icon="las la-envelope" type="button" (click)="SendMail($event)" [disabled]="isDisabledsmail"></button>
                            <!-- <button pButton pRipple title="Print" icon="las la-print" type="button"
                                [disabled]="isDisabledprint" class="p-button-warning p-mr-2" (click)="Print($event)" ></button> -->
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search" type="button" (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_purchaseorderform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">

                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="purchaseorderno">
                                    <label for="id">Purchase Order No</label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" name="purchaseorderdate" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="2020:2030" dateFormat="dd/mm/yy"
                                        [disabled]="isDisabledpurchaseorderdate" [minDate]="mindate" [maxDate]="maxdate" formControlName="purchaseorderdate">
                                    </p-calendar>
                                    <label for="quoteDate">Purchase Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_purchaseorderform.get('purchaseorderdate').touched || _submitted) && _purchaseorderform.get('purchaseorderdate').errors?.SelectPurchaseOrderDate">
                                    Please select purchase date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [disabled]="isDisabledpurchaseorder"
                                        (onChange)="onchangepurchaseordertype($event)" [options]="_purchaseordertype"
                                        optionValue="metasubcode" optionLabel="metasubdescription"
                                        formControlName="purchaseordertype">
                                    </p-dropdown>
                                    <label for="productid">Purchase Order Type<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_purchaseorderform.get('purchaseordertype').touched || _submitted) && _purchaseorderform.get('purchaseordertype').errors?.SelectPurchaseOrderDate">
                                    Please select purchase order type
                                </span>
                                <!-- 61.if PO Type is not given , show error "Please Select PO Type" -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label" [hidden]="HiddenExpiryDate">
                                    <p-calendar [showIcon]="true" name="purchaseorderexpirydate" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="2000:2030" dateFormat="dd/mm/yy"
                                        [disabled]="isDisabledpurchaseorder" formControlName="purchaseorderexpirydate">
                                    </p-calendar>
                                    <label for="quoteDate">Purchase Order Expiry Date <span
                                            class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                            </td>
                        </tr>

                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Suplier Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_purchaseorderform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_suppliertype"
                                        optionValue="metasubcode" optionLabel="metasubdescription"
                                        [disabled]="isDisabledpurchaseorder" formControlName="suppliertype"
                                        (onChange)="getSupplier($event)">
                                    </p-dropdown>
                                    <label for="productid">Supplier Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_purchaseorderform.get('suppliertype').touched || _submitted) && _purchaseorderform.get('suppliertype').errors?.SupplierTypeRequired">
                                    Please select supplier type
                                </span>
                                <!-- 63.if Supplier Type is not given , show error "Please Supplier  Type" -->
                            </td>
                            <td style="width: 20%;">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_Supplierdetails"
                                            (onChange)="onRowSelectsuplier($event)" [filter]="true"
                                            filterBy="nameandmobile" optionValue="supplierid" optionLabel="suppliername"
                                            formControlName="supplierid">
                                        </p-dropdown>
                                        <label for="grnno">Supplier<span class="hlt-txt">*</span> </label>
                                    </span>
                                </div>
                                <span class="text-danger" *ngIf="(_purchaseorderform.get('suppliername').touched || _submitted) && _purchaseorderform.get('suppliername').errors?.SupplierNameRequired">
                                    Please select Supplier
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [disabled]="false" [options]="_currency"
                                        optionValue="currencyid" (onChange)="GetCurrency($event)"
                                        optionLabel="currencyname" formControlName="currencyid">
                                    </p-dropdown>
                                    <label for="productid">Currency Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_purchaseorderform.get('currencyid').touched || _submitted) && _purchaseorderform.get('currencyid').errors?.SelectCurrency">
                                    Please select currency name
                                </span>
                            </td>
                            <td style="width: 20%;" rowspan="2">
                                <span class="p-float-label">
                                    <textarea rows="2" disabled cols="30" name="address" formControlName="address"
                                        pInputTextarea></textarea>
                                    <label for="productid">Address </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_referenceno" [disabled]="isDisabledreferno"
                                        optionLabel="refno" optionValue="refid" formControlName="referenceno"
                                        (onChange)="onSelectreferenceno($event)" [(ngModel)]="_selectedreferencenoindex"
                                        name="_selectrefid"></p-multiSelect>
                                    <label for="_selectrefid">Reference No. </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">

                                    <input type="text" name="contactpersonname" disabled
                                        formControlName="contactpersonname" pInputText>
                                    <label for="quoteDate">Contact Person name <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                    <table class="normal-table">
                        <tr>
                            <td colspan="2">
                                <div class="sub-title">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h5>Delivery Address</h5>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td colspan="3">
                                <div class="sub-title">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h5>Billing Address</h5>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>


                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_deliverybranchname"
                                        optionValue="branchid" (onChange)="onchangedelivery()"
                                        (onChange)="delivertoclear()" optionLabel="branchname"
                                        formControlName="deliveryto" [filter]="true" filterBy="branchname"
                                        [showClear]="true">
                                    </p-dropdown>
                                    <label for="productid">Delivery To <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_purchaseorderform.get('deliveryto').touched || _submitted) && _purchaseorderform.get('deliveryto').errors?.SelectDeliveryToBranch">
                                    Please enter delivery to
                                </span>
                                <!-- 65.if Delivery To is not given , show error "Please Select Delivery To " -->
                            </td>

                            <td style="width: 20%;" rowspan="2">
                                <span class="p-float-label">
                                    <textarea rows="2" cols="30" formControlName="deliverytoaddress"
                                        name="deliverytoaddress" pInputTextarea></textarea>
                                    <label for="productid">Delivery Address <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_purchaseorderform.get('deliverytoaddress').touched || _submitted) && _purchaseorderform.get('deliverytoaddress').errors?.SelectAddress">
                                    Please enter delivery address
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <div class="p-formgroup-inline">
                                    <p-checkbox formControlName="value" (onChange)="onsamedelivery($event)" binary="true" value="val1" label="Same As delivery"></p-checkbox>
                                </div>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_billingbranchname"
                                        optionValue="branchid" optionLabel="branchname" formControlName="billingto"
                                        (onChange)="onchangebarnch($event)" [filter]="true" filterBy="branchname"
                                        [showClear]="true">
                                    </p-dropdown>
                                    <label for="productid">Branch Name</label>
                                </span>
                                <span class="text-danger" *ngIf="(_purchaseorderform.get('billingto').touched || _submitted) && _purchaseorderform.get('billingto').errors?.SelectBranch">
                                    Please enter branch name
                                </span>
                                <!-- 66.if Branch Name is not given , show error "Please Select Branch  name" -->
                            </td>
                            <td style="width: 20%;" rowspan="2">
                                <span class="p-float-label">
                                    <textarea rows="2" cols="30" name="billingaddress" pInputTextarea
                                        formControlName="billingaddress"></textarea>
                                    <label for="productid"> Billing Address </label>

                                </span>
                                <span class="text-danger" *ngIf="(_purchaseorderform.get('billingaddress').touched || _submitted) && _purchaseorderform.get('billingaddress').errors?.SelectBillingAddress">
                                    Please enter billing address
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" name="deliverydate" formControlName="deliverydate"
                                        [monthNavigator]="true" [minDate]="today" [yearNavigator]="true"
                                        dateFormat="dd/mm/yy" yearRange="2000:2030" >
                                    </p-calendar>
                                    <label for="productid">Delivery Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_purchaseorderform.get('deliverydate').touched || _submitted) && _purchaseorderform.get('deliverydate').errors?.SelectDeliveryDate">
                                    Please enter delivery date
                                </span>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                </div>
                <form [formGroup]="_purchaseorderdetailform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <p-multiSelect [options]="_producttype" optionLabel="producttypename" optionValue="producttypeid" formControlName="producttypeid" (onChange)="getProductCategory($event)" defaultLabel="Product Type">
                                </p-multiSelect>
                            </td>
                            <td style="width: 20%;">
                                <p-multiSelect [options]="_productcategory" defaultLabel="Product Category" optionLabel="categoryname" [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}" (onChange)="getProduct($event)" optionValue="productcategoryid">
                                </p-multiSelect>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-autoComplete formControlName="productid" [suggestions]="filteredProducts"
                                        (completeMethod)="filterProducts($event)" field="productname"
                                        (onSelect)="getVariant($event)">
                                        <ng-template let-product pTemplate="item">
                                            <div>{{product.productname}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_purchaseorderdetailform.get('productid').touched || _productsubmitted) && _purchaseorderdetailform.get('productid').errors?.ProductNameRequired">
                                    Please enter product name
                                </span>

                            </td>

                            <td style="width: 20%;">
                            </td>
                            <td style="width: 20%;">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_variantdetails"
                                        optionValue="productvariantdetailid" formControlName="variantid"
                                        optionLabel="variantdescription">
                                    </p-dropdown>
                                    <label for="variantid">Variant </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-inputNumber *ngIf="hidequantity == true" formControlName="quantity"
                                        autocomplete="off" maxlength="13" (keypress)="keypress.kpNumber($event)"
                                        mode="decimal" [minFractionDigits]="0"
                                        [maxFractionDigits]="0">
                                    </p-inputNumber>
                                    <label *ngIf="hidequantity == true" for="quantity">Quantity </label>
                                </span>

                                <span class="p-float-label">
                                    <p-inputNumber *ngIf="hidequantity != true" formControlName="quantity"
                                        autocomplete="off" maxlength="13" (keypress)="keypress.kpNumber($event)"
                                        mode="decimal" [minFractionDigits]="3"
                                        [maxFractionDigits]="3">
                                    </p-inputNumber>
                                    <label *ngIf="hidequantity != true" for="quantity"> Quantity <span
                                            class="hlt-txt">*</span>
                                </label>
                                </span>
                                <!-- <span class="p-float-label">
                                    <input type="number" pInputText formControlName="quantity">
                                    <label for="quantity">Quantity <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_purchaseorderdetailform.get('quantity').touched || _productsubmitted) && _purchaseorderdetailform.get('quantity').errors?.QuantityRequired">
                                    Please enter quantity
                                </span> -->
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" name="stockinhand" formControlName="stockinhand" disabled
                                        pInputText>
                                    <label for="stockinhand">Stock In Hand </label>
                                </span>
                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Add" type="button" icon="las la-check" [disabled]="isDisabledaddproduct" (click)="onAddProduct($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" type="button" (click)="clear($event)" [disabled]="isDisabledclearproduct" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-table [value]="_ProductDetails" [rows]="100" [paginator]="true" [rowsPerPageOptions]="[100,125,150,200,250]" [globalFilterFields]="['productname','variantdescription']" [rowHover]="true" dataKey="productid" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width:100px;" class="text-center">Action</th>
                                <th>Product Name</th>
                                <th>Variant</th>
                                <th>Quantity</th>
                                <th>UOM</th>
                                <th>Price</th>
                                <th>Tax % </th>
                                <th>Tax Amount</th>
                                <th>Product Amount</th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ProductDetail>
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple title="Remove" [disabled]="isDisabledremoveproduct" icon="pi pi-trash" class="p-button-danger p-mr-2" (click)="RemoveProduct(_ProductDetail)"></button>
                                </td>

                                <td>
                                    {{_ProductDetail.productname}}
                                </td>
                                <td>
                                    {{_ProductDetail.variantname}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_ProductDetail.quantity" pInputText disabled (keyup)="allocatequantity(_ProductDetail)" *ngIf="_action != 'view'">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_ProductDetail.quantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_ProductDetail.uomname}}
                                </td>
                                <td>
                                    {{_ProductDetail.unitprice | number: '1.3-4' }}

                                </td>

                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [disabled]="isDisabledtaxpercentage" [options]="_taxdetails" optionLabel="taxcode" optionValue="taxcode" [showClear]="true" [(ngModel)]="_ProductDetail.taxcode" [disabled]="!_ProductDetail.vatapplicable" (onChange)="onchangeTAX(_ProductDetail,$event)"
                                                *ngIf="_action != 'view'">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_taxdetails" optionLabel="taxcode" optionValue="taxcode" [(ngModel)]="_ProductDetail.taxcode">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_ProductDetail.taxamount | number: '1.3-4' }}
                                </td>
                                <td>
                                    {{_ProductDetail.finalamount | number: '1.3-4' }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Other Charges</h5>
                        </div>
                        <div class="col-md-6">

                        </div>
                    </div>
                </div>
                <div class="card">
                    <p-table [value]="_OtherCharges" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['otherchargesid','otherchargesamount']" [rowHover]="true" dataKey="otherchargesid" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="selectedOtherChargesRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:100px;text-align: center;">Action</th>
                                <th>Description</th>
                                <th>Value</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_othercharge>
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple title="Add" icon="pi pi-plus" class="p-button-success p-mr-2" (click)="AddOtherCharges($event)" [disabled]="isDisabledAdd"></button>
                                    <button pButton pRipple title="Remove" icon="pi pi-trash" class="p-button-danger" (click)="RemoveOtherCharges(_othercharge)" [disabled]="isDisabledRemove"></button>
                                </td>

                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_Descriptions" filterBy="otherchargename" optionLabel="otherchargename" optionValue="otherchargeid" [(ngModel)]="_othercharge.otherchargeid" (onChange)="OnchangeOtherCharge(_othercharge,$event)" *ngIf="_action != 'view'">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [disabled]="true" [autoDisplayFirst]="false" [options]="_Descriptions" optionLabel="otherchargename" optionValue="otherchargeid" [(ngModel)]="_othercharge.otherchargeid">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_othercharge.otherchargesamount" pInputText (keyup)="OnChangeotherCharge(_othercharge,$event)" *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_ProductDetail.otherchargesamount | number: '1.3-4'}}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_othercharge.otherchargesamount | number: '1.3-4'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>


                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Terms & Conditions</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_Purchaseorderpaymenttermform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_paymentterms" [disabled]="isDisabledpayment"
                                        optionLabel="termsdescription" optionValue="termsandconditionid"
                                        formControlName="paymenttermsid" (onChange)="onSelectPaymentTerms($event)"
                                        name="_paymenttermsid" [(ngModel)]="_selectedPaymentIndex"></p-multiSelect>
                                    <label for="_paymenttermsid">Payment Terms </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_deliveryterms" [disabled]="isDisableddelivery"
                                        optionLabel="termsdescription" name="_deliverytermsid"
                                        [(ngModel)]="_selectedDeliveryIndex" optionValue="termsandconditionid"
                                        formControlName="deliveryermsid" (onChange)="onSelectDeliveryTerms($event)">
                                    </p-multiSelect>
                                    <label for="_deliverytermsid">Delivery Terms </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_otherterms" [disabled]="isDisabledother"
                                        optionLabel="termsdescription" name="_othertermsid"
                                        formControlName="othertermsid" [(ngModel)]="_selectedOtherIndex"
                                        (onChange)="onSelectOtherTerms($event)" optionValue="termsandconditionid">
                                    </p-multiSelect>
                                    <label for="_othertermsid">Other Terms </label>
                                </span>
                            </td>
                            <td style="width: 20%;">

                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Payment Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_purchaseorderform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">

                                <span class="p-float-label">
                                    <input type="number" name="grossamount" formControlName="grossamount" disabled
                                        pInputText>
                                    <label for="totalpoamount">Gross Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" name="taxamount" formControlName="taxamount" disabled
                                        pInputText>
                                    <label for="taxamount">Tax Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" name="otherchargesamount" formControlName="otherchargesamount"
                                        disabled pInputText>
                                    <label for="otherchargesamount">Other Charges</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" formControlName="totalpoamount" name="totalpoamount" disabled
                                        pInputText>
                                    <label for="totalpoamount">Net Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>

                <table>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </table>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>