import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { PettycashService } from 'src/app/core/services/accounts/pettycash.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Pettycashrequestview } from 'src/app/core/Views/pettycashview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-pettycash',
  templateUrl: './v-pettycash.component.html',
  styleUrls: ['./v-pettycash.component.css']
})
export class VPettycashComponent implements OnInit {

  selectedrows: any;

  _Pettycashview: Pettycashrequestview;
  _Pettycashviews: Pettycashrequestview[]=[];
  _IsProgressSpinner: boolean = true;
  _userid = sessionStorage["userid"];
  _Action: IAction;
  fromdate: Date;
  todate: Date;
  _branchid:number;
  datepipe = new DatePipe('en-US');
  _roleid=sessionStorage["currentRole"]
  cols: any[];
  mindate: Date;
  maxdate: Date;
  constructor(private fb: FormBuilder, private _PettycashService: PettycashService, private router: Router, private utility: CommonUtilities,public exportUtility: ExportUtility,
    private _CustomExceptionService: CustomExceptionService, private confirmationService: ConfirmationService, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService) {

  }


  ngOnInit(): void {
    this.utility.pageLoadScript();
    let BranchId = sessionStorage["BranchID"];
    this._Action = this._AccessRightsService.getAccessRights(71);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.HotKeyIntegration();
    this.cols = [
      { field: 'pettycashno', header: 'Petty Cash No' },
      { field: 'requestdate', header: 'Request Date' },
      { field: 'requestedbranchname', header: 'Branch Name' },
      { field: 'requesrpersonname', header: 'Person Name' },
      { field: 'requestamount', header: 'Amount' },
      { field: 'reason', header: 'Reason' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.Fetchall();
   
  }
  Fetchall(){
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid= parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._PettycashService.FetchAll(this._userid,this._branchid,this._roleid,fromdate,todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Pettycashviews = resultJSON.pettycashrequestviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  view(event) {
    this.router.navigate(['/CPettycash'], { state: { pettycashid: event.pettycashid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/CPettycash']);
  }

  delete(event) {
    alert("delete");
  }
  // edit(event) {
  //   console.log(event);
  //   this.router.navigate(['/CPettycash'], { state: { pettycashid: event.pettycashid , action : 'edit' } });
  // }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Payment <b>"' + event.pettycashno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._PettycashService.Cancel(event.pettycashid).subscribe((result) => {

      const resutJSON = JSON.parse(JSON.stringify(result));
      if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
        this._Pettycashviews = resutJSON.pettycashrequestviews;
        this._IsProgressSpinner = false;
      }
      else {
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },

      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let PaymentList = this.GetPettyCashList();
    this.exportUtility.exportExcel(PaymentList, 'PettyCash', 'xlsx');
  }

  ExportToPdf() {
    let PaymentList = this.GetPettyCashList();
    this.exportUtility.ExportToPdf(PaymentList, 'PettyCash.pdf');
  }
  
  GetPettyCashList() {
    if (!this._Pettycashviews || !this._Pettycashviews.length)
      return;
    let PettyCashList = [];
    this._Pettycashviews.forEach(element => {
      let pettycash: any = {};
      pettycash["Petty Cash No"] = element.pettycashno;
      pettycash["Request Date"] = element.requestdate;
      pettycash["Branch Name"] = element.requestedbranchname;
      pettycash["Person Name"] = element.requesrpersonname;
      pettycash["Amount"] = element.requestamount;
      pettycash["To Account Name"] = element.reason;
      pettycash["Created By"] = element.createdbyname;
      pettycash["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      PettyCashList.push(pettycash);
    });
    return PettyCashList;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
}
