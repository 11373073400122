
 export class Employeedocumentdetail {
    employeedocumentdetailid: number;
    employeeid: number;
    documentid: number;
    documentname: string;
    documentdescription: string;
    attachmenturl: string;
    attachmentname: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;


    
}
