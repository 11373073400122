import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class OrderstatusdescriptionService {
  public _Action : IAction ;
  public _OrderDescriptionService = environment.OrderDescriptionService;
  public _httpOptions;
  constructor(private http:HttpClient) { }

  PageOnLoad(): Observable<any> {
    var Query = this._OrderDescriptionService ;
    // console.log(Query);
    return this.http.get<{ data: any }>(Query);
  }
  FetchAll()
  {
    var Query = this._OrderDescriptionService + '/FetchAll' ;
    // console.log(Query);
    return this.http.get<{data:any}>(Query,this._httpOptions).pipe(map(data=>{
      const resultJSON = JSON.parse(JSON.stringify(data));
      // console.log(resultJSON);
     return data; 
    }),);
  }

  create(orderdescription:object)
  {
    // console.log("Create");
    var Query = this._OrderDescriptionService +'/Create';
    // console.log(Query);
    return this.http.post<{data:any}>(Query,orderdescription);
  }
 
  edit(orderdescription : object)
  {
    // console.log("Update");
    var Query = this._OrderDescriptionService+'/Update';
    // console.log(Query);
    return this.http.put<{data:any}>(Query,orderdescription);
  }
  Delete(osdid:number)
  {
    // console.log("Update");
    var Query = this._OrderDescriptionService+'/Delete'+ '/'+osdid;
    // console.log(Query);
    return this.http.get(Query);
  }
  View(osdid:number):  Observable<any>{
    var Query = this._OrderDescriptionService+ '/View' +'/'+ osdid;
    // console.log(Query);
    return this.http.get(Query);
  }
}
