import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Customeraddressdetail } from 'src/app/core/Models/Customeraddressdetail';
import { User } from 'src/app/core/Models/User';
import { UsercustomermappingService } from 'src/app/core/Services/users/usercustomermapping.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { Usercustomermapping } from 'src/app/core/Models/usercustomermapping';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { vUserName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
@Component({
  selector: 'app-c-usercustomermapping',
  templateUrl: './c-usercustomermapping.component.html',
  styleUrls: ['./c-usercustomermapping.component.css']
})
export class CUsercustomermappingComponent implements OnInit {
  _usercustomermappingform: FormGroup;
  selectedrows: any[]=[];
  pSelectableRow: any;
  _action: string = "create";
  _submitted = false;
  _usernames: User;
  _Customers: any[]=[];
  _Customer: Usercustomermapping;
  _usercustomermappingid: number;
  _UserCustomerOBJ: any;
  _selectedUserId: any;
  _Action: IAction
  isDisabledsave=true;
  _IsProgressSpinner: boolean = true;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  _userid: number;
  constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, private _UsercustomermappingService: UsercustomermappingService,
    private _CustomExceptionService: CustomExceptionService, private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(34);
    this.utility.pageLoadScript();
    this._usercustomermappingform = this.fb.group({
      usercustomermappingid: [],
      userid: ['', vUserName],
      customerid: [],
      customertype: [],
      customermappingstatus: [],
      series: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
    this._userid = parseInt(sessionStorage["userid"]);
    this._IsProgressSpinner = true;
    this._usercustomermappingid = history.state?.usercustomermappingid ? history.state?.usercustomermappingid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._UsercustomermappingService.PageOnLoad(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._usernames = resultJSON.users;
      this._Customers = resultJSON.customers;
      this._IsProgressSpinner = false;
      this.isDisabledsave=false;
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  getUserMappedCustomer(event) {
    var selectedUser = event.value;
    if(!event.value)return;
    this._IsProgressSpinner = true;
    this.selectedrows =[];
    this._Customers=[];
    this._UsercustomermappingService.getUserMappedCustomer(selectedUser).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.selectedrows =[];
      this._Customers=[];
      this._Customers = resultJSON.usermappedcustomers;
      this.selectedrows = resultJSON.usercustomermappingselectedviews;
      this.selectedrows.forEach(element => {
        let customersmainindex = this._Customers.findIndex(f=>f.customerid==element.customerid);
        this._Customers[customersmainindex].usercustomermappingid =element.usercustomermappingid;
      });

     this._Customers.sort(function (a, b) {
        return b.usercustomermappingid - a.usercustomermappingid;
      });
      this._IsProgressSpinner = false;
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  Clear(event)
  {
    this._submitted = false;
    this._usercustomermappingform.reset();
    this._UsercustomermappingService.PageOnLoad(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
   
      this._Customers = resultJSON.customers;
      this.selectedrows =[];
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  onRowSelect(event) {
    // if (this._selectedUserId == undefined) {
    //   //alert("please select user");
    //   return false;
    // }
    // Object.keys(this.selectedrows).map((Index) => {
    //   this.selectedrows[Index].userid = this._selectedUserId;
    // });;
  }
  onRowUnselect(event) {
  }
  onSave() {
    this._submitted = true;
    if (this._usercustomermappingform.valid) {

      // if (this.selectedrows.length>0) {

        var usercustomermapping: Usercustomermapping[] = [];
        var customermapping: Usercustomermapping;
        Object.keys(this.selectedrows).map((Index) => {
          customermapping = new Usercustomermapping();
          customermapping.userid = this._usercustomermappingform.get("userid").value;
          //customermapping.userid = this.selectedrows[Index].userid;
          customermapping.customerid = this.selectedrows[Index].customerid;
          customermapping.customermappingstatus = "MSC00093";
          customermapping.series = this.selectedrows[Index].series;
          customermapping.createdon = new Date();
          customermapping.modifiedon = new Date();
          customermapping.createdby = 1;
          customermapping.modifiedby = 1;
          usercustomermapping.push(customermapping);
        });
      // }
      // else {
      //   this._CustomExceptionService.handleWarning("Please Map Atleast one Customer for this user");
      //   return;
      // }
      this._UserCustomerOBJ = JSON.stringify({ "UserCustomerMapping": usercustomermapping });
      this._UserCustomerOBJ = JSON.parse(JSON.stringify({ "UserCustomerMapping": usercustomermapping }));
      //this.onEd();
      if (this._action == 'create') {
        this._IsProgressSpinner = true;
        this._UsercustomermappingService.create({"UserCustomerMapping": usercustomermapping ||[],"userid":this._usercustomermappingform.get("userid").value}).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this.isDisabledsave = true;
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.isDisabledsave = false;
            //this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
          
      }
    }
  }
  reset(event) {
    this._usercustomermappingform.reset();
    this._submitted = false;
    this._Customers= [];
  }
  // onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._UserCustomerOBJ;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      },['INPUT', 'SELECT', 'TEXTAREA']));

      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      },['INPUT', 'SELECT', 'TEXTAREA']));

      //this._hotkeysService.add(new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
      //  this.router.navigate(['/cBank'], { state: { bankid: this._bankid, action: 'edit' }});
      //  return false; // Prevent bubbling
      //}));
    }
    // this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
    //   this.goBack(null);
    //   return false; // Prevent bubbling
    // },['INPUT', 'SELECT', 'TEXTAREA']));
  }
}
