<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>User</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                [disabled]="isDisabledsave" (click)="onSave()"></button>
                            <button pButton pRipple type="button" title="Clear" icon="pi pi-trash" class=""
                                [disabled]="isDisabledreset" (click)="reset()" class="p-button-danger"></button>
                            <button pButton pRiple type="button" icon="pi pi-search" title="Back to Search"
                                class="p-button p-button-success p-mr-2" [routerLink]="['/vUser']"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_Userform" (ngSubmit)="onSave()" autocomplete="off">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown name="employeeid" [options]="_employees" formControlName="employeeid"
                                        [disabled]="isDisabledreset" [autoDisplayFirst]="false"
                                        optionLabel="employeefullname" optionValue="employeeid" [filter]="true"
                                        filterBy="employeename" [showClear]="true">
                                    </p-dropdown>
                                    <label for="employeeid">Employee Name </label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Userform.get('employeeid').touched) && _Userform.get('employeeid').errors?.SelectEmployee">
                                    Please select employee name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="username" maxlength="25"
                                        (keyup.enter)="getassociateuser()" autocomplete="off">
                                    <label for="username">User Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Userform.get('username').touched) && _Userform.get('username').errors?.UserNameRequired">
                                    Please enter user name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!-- <input [type]="password" pPassword formControlName="password" maxlength="20" 
                                        autocomplete="off">
                                        
                                    <button type="button" (click)="onClick()">
                                        <i class="fa fa-eye"  aria-hidden="false" [hidden]="show"></i> -->
                                        <!-- <i class="fa fa-eye-slash" aria-hidden="false" [hidden]="show1"></i> -->
                                    <!-- </button> -->
                                    <p-password formControlName="password" maxlength="20">
                                        <ng-template pTemplate="header">
                                            <h6>Pick a password</h6>
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                            <p-divider></p-divider>
                                            <p class="p-mt-2">Suggestions</p>
                                            <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                                                <li>At least one lowercase</li>
                                                <li>At least one uppercase</li>
                                                <li>At least one numeric</li>
                                                <li>At least one special character</li>
                                                <li>Minimum 8 characters</li>
                                                <li>Maximum 20 characters</li>
                                            </ul>
                                        </ng-template>
                                    </p-password>
                                    <label for="password">Password <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Userform.get('password').touched) && _Userform.get('password').errors?.NewPasswordRequired">
                                    Please enter password
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Userform.get('password').touched) && _Userform.get('password').errors?.ValidPassword">
                                    Please enter Valid Password
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">

                                    <p-inputNumber maxlength="5" mode="decimal" emptyValue="empty"
                                        [minFractionDigits]="2" name="userdiscountpercentage"
                                        formControlName="userdiscountpercentage" inputId="withoutgrouping"
                                        [useGrouping]="false"> </p-inputNumber>


                                    <label for="userdiscountpercentage">User Discount % <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Userform.get('userdiscountpercentage').touched) && _Userform.get('userdiscountpercentage').errors?.UserDiscountRequired">
                                    Please enter user discount
                                </span>
                            </td>
                           
                            <td rowspan="2">
                                <span class="p-float-label">
                                    <textarea rows="3" name="blockreason" pInputTextarea
                                    *ngIf="showblockreason" formControlName="blockreason"></textarea>
                                    <label *ngIf="showblockreason" for="blockreason">Reason For Block </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" name="logincount" pInputText formControlName="logincount">
                                    <label for="logincount">Login Count</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" name="inactivecount" pInputText
                                        formControlName="inactivecount">
                                    <label for="inactivecount">Inactive Count</label>
                                </span>
                            </td>
                            <td colspan="2">
                                <form [formGroup]="_Userbranchmapform">
                                    <table class="normal-table">
                                        <tr>
                                            <td style="width:50%;">
                                                <span class="p-float-label">
                                                    <p-multiSelect name="branchid" [options]="_branches"
                                                        [(ngModel)]="_selectedBranchIndex" formControlName="branchid"
                                                        optionLabel="branchname" optionValue="branchid" [filter]="false"
                                                        (onChange)="OnChangeofBanches($event)">
                                                    </p-multiSelect>
                                                    <label for="branchid">Branch Name <span
                                                            class="hlt-txt">*</span></label>
                                                </span>
                                                <span class="text-danger"
                                                    *ngIf="(_submitted || _Userbranchmapform.get('branchid').touched) && _Userbranchmapform.get('branchid').errors?.SelectBranchName">
                                                    Please select atleast one branch
                                                </span>
                                            </td>
                                            <td style="width:50%;">
                                                <span class="p-float-label">
                                                    <p-dropdown name="isdefault" [options]="_selectedBranches"
                                                        [autoDisplayFirst]="false" formControlName="isdefault"
                                                        optionLabel="branchname" optionValue="branchid"
                                                        (onChange)="setDefaultBranch($event)">
                                                    </p-dropdown>
                                                    <label for="isdefault">Default Branch <span
                                                            class="hlt-txt">*</span></label>
                                                </span>
                                                <span class="text-danger"
                                                    *ngIf="(_submitted || _Userbranchmapform.get('isdefault').touched) && _Userbranchmapform.get('isdefault').errors?.BranchNameRequired">
                                                    Please select default branch
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </form>
                            </td>
                        </tr>
                        <tr>

                            <td>
                                <span class="p-float-label">
                                    <p-dropdown name="userstatus" [options]="_userstatus" formControlName="userstatus"
                                        optionLabel="metasubdescription" optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="userstatus">Status <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label" [hidden]="showseries">
                                    <p-dropdown name="series" [options]="_series" optionLabel="metasubdescription"
                                        [disabled]="disableseries" formControlName="series" optionValue="metasubcode"
                                        (onChange)="onchangeseries($event)">
                                    </p-dropdown>
                                    <label for="series">Rights <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td *ngIf="_IsshowAssociateuser">
                                <span class="p-float-label">
                                    <p-dropdown name="series" [autoDisplayFirst]="false" [options]="_Associateusers"
                                        optionLabel="username" formControlName="associateuser" optionValue="userid"
                                        [filter]="true" filterBy="username" [showClear]="true">
                                    </p-dropdown>
                                    <label for="series">Associate User <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td>
                                <div class="p-formgroup-inline">
                                    <div  class="p-field-checkbox">
                                        <p-checkbox name="isblocked" binary="true"
                                            formControlName="isblocked"
                                            (onChange)="isblocked($event)">
                                        </p-checkbox>
                                        <label for="isblocked">Block User</label>
                                    </div>
                                </div>
                            </td>
                           
                        </tr>

                    </table>
                </form>
                <!-- Debug -->
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>User Role Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                            <!--                            <button pButton pRipple label="" icon="pi pi-plus" class="p-button-success p-mr-2"
                                (click)="openNew()"></button>
                            <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger ">
                            </button>-->
                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button>
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_UserRoleDetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['rolename','levelname']"
                        [rowHover]="true" dataKey="roleid" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 120px;" pSortableColumn="sno">SNo <p-sortIcon
                                        field="sno"></p-sortIcon>
                                </th>
                                <th class="text-center" style="width: 100px;">Select Role<br>
                                    <p-tableHeaderCheckbox [disabled]="_action == 'view'"></p-tableHeaderCheckbox>
                                </th>
                                <th pSortableColumn="rolename">Role Name <p-sortIcon field="rolename"></p-sortIcon>
                                </th>
                                <th pSortableColumn="levelname">Level<p-sortIcon field="levelname">
                                    </p-sortIcon>
                                </th>
                                <th class="text-center" style="width: 120px;">Default Role </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_RoleDetail let-i="rowIndex">
                            <tr>
                                <td style="text-align: center;">
                                    {{i+ 1}}
                                </td>
                                <td style="text-align: center;width: 50px;">
                                    <p-tableCheckbox [value]="_RoleDetail" [disabled]="_action == 'view'">
                                    </p-tableCheckbox>
                                </td>
                                <td>
                                    {{_RoleDetail.rolename}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_levels"
                                                optionLabel="levelname" optionValue="levelid"
                                                [disabled]="_action == 'view'" [(ngModel)]="_RoleDetail.levelid">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [disabled]="true" [autoDisplayFirst]="false" [options]="_levels"
                                                optionLabel="levelname" optionValue="levelid"
                                                [disabled]="_RoleDetail.isdisable || _action == 'view'"
                                                [(ngModel)]="_RoleDetail.levelid">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td style="text-align: center;width: 50px;">
                                    <p-checkbox [value]="_RoleDetail" [(ngModel)]="_RoleDetail.isdefault"
                                        [disabled]="_RoleDetail.isdisable || _action == 'view'"
                                        (onChange)="OnDefaultRole(_RoleDetail)" binary="true">
                                    </p-checkbox>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <!-- Debug -->
                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
            </div>

            <p-dialog header="Employee Name" [(visible)]="displayBasic" [modal]="true"
                [style]="{width: '50vw',height: '500px'}" [baseZIndex]="10000">
                <ng-template pTemplate="content">
                    <div class="popup-body">
                        <div class="search-container">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <input type="text" id="inputtext" pInputText>
                                </span>
                                <button class="p-inputgroup-addon p-button" title="Search">
                                    <i class="pi pi-search"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card">
                            <p-table [value]="_Employeelist" selectionMode="single" (onRowSelect)="onRowSelect1($event)"
                                [(selection)]="selectedName" [paginator]="true" [rows]="5">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="employeeid">Employee Name<p-sortIcon field="employeeid">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_Employee let-ri="rowIndex">
                                    <tr [pSelectableRow]="rowData">
                                        <td>{{_Employee.employeeid}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </ng-template>
            </p-dialog>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>

    </div>
</div>