<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Delivery Note</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_rptdeliverychallanform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                        (onSelect)="PageOnload()"  [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy">
                                        </p-calendar>
                                        <label for="fromdate">From Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                        (onSelect)="PageOnload()"   [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy">
                                        </p-calendar>
                                        <label for="todate">To Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_OrderNOs"
                                            optionValue="eorderno" optionLabel="eorderno" (onChange)="GetOrderId()"
                                            formControlName="orderno" [filter]="true" filterBy="eorderno">
                                        </p-dropdown>
                                        <label for="customername">Order No</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_OrderIDs" optionValue="eorderid"
                                            optionLabel="eorderid" [(ngModel)]="orderid" displaySelectedLabel="true"
                                            [filter]="true" [virtualScroll]="true" itemSize="30"  [ngModelOptions]="{standalone: true}">
                                        </p-multiSelect>
                                        <label for="levelname">Order ID</label>
                                    </span>
                                </td>

                                <td style="width: 20%;">
                                  
                                </td>
                            </tr>
                          
                            <tr>
                                <td>
                                    <button pButton pRipple label="Generate" icon="las la-print"
                                        (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()"
                                        class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div class="output-container">
                    <iframe #iframe class="scroll-height" width="100%" [src]="_src"></iframe>
                </div>
            </div>
           

        </div>
    </div>

</div>
<p-toast position="bottom-right"></p-toast>