<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Tax</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" [routerLink]="['/cTax']" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" (click)="ExportToPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_taxesview" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['taxcode', 'taxname','taxpercentage','effectivefrom','effectiveto','taxstausname']" [rowHover]="true" dataKey="taxcode" [(selection)]="selectedrows"
                            (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center;width: 120px;">Action
                                    </th>
                                    <th pSortableColumn="taxcode">Tax Code
                                        <p-sortIcon field="taxcode"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="taxname">Tax Name
                                        <p-sortIcon field="taxname"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="taxpercentage">Tax Percentage
                                        <p-sortIcon field="taxpercentage">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="effectivefrom">Effective From
                                        <p-sortIcon field="effectivefrom">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="effectiveto">Effective To
                                        <p-sortIcon field="effectiveto">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="taxstausname">Status
                                        <p-sortIcon field="taxstausname">
                                        </p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_taxview>
                                <tr>
                                    <td class="text-center">
                                        <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2" [disabled]="!_Action._View" (click)="view(_taxview)"></button>
                                        <button pButton pRipple icon="las la-pen" title="Edit" [disabled]="!_Action._Update" class="p-button p-button-success p-mr-2" (click)="edit(_taxview)"></button>
                                        <button pButton pRipple icon="pi pi-times" title="Inactive" [disabled]="_taxview.taxstatus == 'MSC00002'|| !_Action._Delete" class="p-button p-button-danger" (click)="confirm(_taxview)"></button>
                                    </td>
                                    <td>
                                        {{_taxview.taxcode}}
                                    </td>
                                    <td>
                                        {{_taxview.taxname}}
                                    </td>
                                    <td>
                                        {{_taxview.taxpercentage}}
                                    </td>
                                    <td>
                                        {{_taxview.effectivefrom | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_taxview.effectiveto | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_taxview.taxstausname}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>