import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SalescommissionService } from 'src/app/core/services/rewards/salescommission.service';
import { Salescommissionview } from 'src/app/core/Views/Salescommissionview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';

@Component({
  selector: 'app-v-salescommission',
  templateUrl: './v-salescommission.component.html',
  styleUrls: ['./v-salescommission.component.css']
})
export class VSalescommissionComponent implements OnInit {
  _Salescommissionviews: Salescommissionview[] = [];
  _Salescommissionview: Salescommissionview;
  selectedrows: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _IsProgressSpinner: boolean;
  _Action: IAction;
  _userid: number = sessionStorage["userid"];
  _branchid: number = sessionStorage["BranchID"];
  cols: any[];
  constructor(
    private fb: FormBuilder,
    private _SalescommissionService: SalescommissionService,
    private router: Router,
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private exportUtility: ExportUtility,
    public datepipe: DatePipe,
    private confirmationService: ConfirmationService
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(78);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'salestypename', header: 'Salesman Type' },
      { field: 'effectivefrom', header: 'Effective From' },
      { field: 'effectiveto', header: 'Effective To' },
      { field: 'salescommissionstatusname', header: 'Status' }
    ]
    this._IsProgressSpinner = true;
    this._SalescommissionService.FetchAll(this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Salescommissionviews = resultJSON.salescommissionheaderviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) { }
  onRowUnselect(event) { }
  view(event) {
    this.router.navigate(['/cSalescommission'], { state: { salescommissionid: event.salescommissionid, action: 'view' } });
  }
  edit(event) {
    if (event.approvalstatus == "MSC00045" || event.approvalstatus == null) {
      this.router.navigate(['/cSalescommission'], { state: { salescommissionid: event.salescommissionid, action: 'edit' } });
    }
    else {
      this._CustomExceptionService.handleWarning("Document Send For Approval!");
    }
  }
  GoToCreate(event) {
    this.router.navigate(['/cSalescommission']);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let salesList = this.GetCustomerDiscountList();
    this.exportUtility.exportExcel(salesList, 'Sales Commission', 'xlsx');
  }
  exportPdf() {
    let salesList = this.GetCustomerDiscountList();
    this.exportUtility.ExportToPdf(salesList, 'Sales Commission.pdf');
  }
  GetCustomerDiscountList() {
    let salesList = [];
    this._Salescommissionviews.forEach(element => {
      let sales: any = {};
      // sales["Salesman Type"] = element.salestypename;
      // sales["Base Target"] = element.basetarget;
      // sales["Base Commission"] = element.basecommision;
      // sales["Effective From"] = element.effectivefrom;
      // sales["Effective To"] = element.effectiveto;
      // sales["Status"] = element.salescommissionstatusname;
      sales["Salesman Type"] = element.salestypename;
      // sales["Slab"] = element.tempslab;
      // sales["Minimum Range"] = element.tempminrange;
      // sales["Maximum Range"] = element.tempmaxrange;
      // sales["Commission"] = element.tempcommission;
      sales["Effective From"] = this.datepipe.transform(element.effectivefrom, 'dd/MM/yyyy');
      sales["Effective To"] = this.datepipe.transform(element.effectiveto, 'dd/MM/yyyy');
      sales["Status"] = element.salescommissionstatusname;
      salesList.push(sales);
    });
    return salesList;
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this sales commission <b>"' + event.salescommissionid + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    var salesid = event.salescommissionid;
    this._IsProgressSpinner = true;
    this._SalescommissionService.Cancel(salesid, sessionStorage["userid"]).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Salescommissionviews = [];
      this._Salescommissionviews = resultJSON.salescommissionheaderviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
