import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { BrandService } from 'src/app/core/Services/masters/brand.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Brand } from 'src/app/core/Models/Brand';
import { vBrandName } from 'src/app/core/Validators/validation';
import { NgxImageCompressService } from 'ngx-image-compress';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

declare var $: any;
@Component({
	selector: 'app-c-brand',
	templateUrl: './c-brand.component.html',
	styleUrls: ['./c-brand.component.css']
})
export class CBrandComponent implements OnInit {
	Brandform: FormGroup;
	_IsProgressSpinner: boolean = false;
	_status: Metadatum;
	_action: string;
	_brandid: any;
	isDisabledClear: boolean;
	isDisabledSave: boolean;
	_recordstatus: boolean;
	_submitted: boolean;
	_Brand: Brand;
	_Iscreatefileupload: boolean = true;
	_Isviewfileupload: boolean = true;
	imageCropperTool: boolean;
	position: string;
	defaultDate: Date;
	uploadedFiles: any[] = [];
	_LastAddedGridDocument: File;
	fileUpload: any;
	imageChangedEvent: any;
	croppedImage: any;
	canvasRotation = 0;
	rotation = 0;
	scale = 1;
	showCropper = false;
	containWithinAspectRatio = false;
	transform: ImageTransform = {};
	brand: SafeUrl;
	_BrandObj: any;
	_BrandForm: FormData;
	_CropImage: File;
	_LastAddedImage: File;
	imageselect: string = "";
	displayimagepopup: boolean;
	position1: string;
	clearimage: boolean = false
	Weburl: any;
	Mobileurl: any;
	_WebImage: File;
	_MobileImage: File;
	branchList: any[] = []
	branchid: any[] = []
	DisableView: boolean = false;
	_Action: IAction;

	constructor(
		private utility: CommonUtilities,
		private _DomSanitizationService: DomSanitizer,
		private _BrandService: BrandService,
		private fb: FormBuilder,
		private _router: Router,
		private _CustomExceptionService: CustomExceptionService,
		private imageCompress: NgxImageCompressService,
		private _AccessRightsService: AccessRightsService,

	) {
		this._brandid = history.state?.brandid ? history.state?.brandid : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
	}
	InitializeForm() {
		this.Brandform = this.fb.group({
			brandid: [0],
			brandname: ['', vBrandName],
			recordstatus: [],
			createdby: [],
			createdbyname: [],
			modifiedbyname: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			brandurl: [],
			brandurlname: [],
			recordstatusname: [],
			ecbrandUrl: [],
			mobilebrandurl: [],
			mobilebrandurlname: [],
			branchname: []
		})
	}
	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
		this.imageCropperTool = true;
	}
	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}
	imageLoaded() {
		this.showCropper = true;
	}
	cropperReady(sourceImageDimensions: Dimensions) { }
	loadImageFailed() { }
	resetImage() {
		this.scale = 1;
		this.rotation = 0;
		this.canvasRotation = 0;
		this.transform = {};
	}
	zoomOut() {
		this.scale -= .1;
		this.transform = {
			...this.transform,
			scale: this.scale
		};
	}
	zoomIn() {
		this.scale += .1;
		this.transform = {
			...this.transform,
			scale: this.scale
		};
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(309);
		this.InitializeForm();
		this.utility.pageLoadScript();
		$(document).on("change", ".file-upload-field", function () {
			$(this).parent(".file-upload-wrapper").attr("data-text", $(this).val().replace(/.*(\/|\\)/, ''));
		});
		if (this._action == 'create') {
			this._Iscreatefileupload = true;
			this._Isviewfileupload = false;
		}
		if (this._action == 'view') {
			this.isDisabledSave = true
			this.DisableView = true;
			this.Brandform.disable();
			this.clearimage = true
		}
		if (this._action == 'View') {
			this.Brandform.disable();
			this.isDisabledClear = true;
			this.isDisabledSave = true;
		}
		if (this._action == 'edit') {
			this.isDisabledClear = true;
			this.isDisabledSave = false;
		}
		this._IsProgressSpinner = true;
		this._BrandService.PageOnLoad().subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log("PageLoad Data", resultJSON);
			this._recordstatus = resultJSON.recordstatuslst;
			this.branchList = resultJSON.branchList;
			this._IsProgressSpinner = false;
			this.Brandform.controls['recordstatus'].patchValue("MSC00001");
			console.log(this.Brandform);
		});
		if (this._action == 'edit' || this._action == 'view') {
			this._Isviewfileupload = true;
			this._Iscreatefileupload = false;
		}
		this._brandid = history.state.brandid;
		if (this._action == 'edit' || this._action == 'view') {
			this._BrandService.View(this._brandid).subscribe((result) => {
				const updateJSON = JSON.parse(JSON.stringify(result));
				console.log(updateJSON.brandView);
				if (updateJSON.brandView.brandurl) {
					this.brand = this._DomSanitizationService.bypassSecurityTrustUrl((updateJSON.brandView.brandurl));
				}
				this.Brandform.setValue(updateJSON.brandView);
				this.branchid = updateJSON.brandbranchdeatilview;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	goBack(event) {
		console.log("goBack");
		this._router.navigate(['/vBrand']);
	}
	onImageClear() {
		this._Iscreatefileupload = true;
		this._Isviewfileupload = false;
		this.brand = null;
		this._LastAddedImage = null;
	}
	onSave() {
		this._submitted = true;
		if (this.Brandform.valid) {
			this._Brand = this.Brandform.getRawValue();
			if (!this.branchid || !this.branchid.length || this.branchid.length == 0) {
				this._CustomExceptionService.handleWarning("Please Select Atleast One Branch")
				return
			}
			var BranchList: any[] = []
			for (let i = 0; i < this.branchid.length; i++) {
				const x = this.branchid[i];
				const Branch: any = {};
				Branch.brandbranchdetailid = 0;
				Branch.brandid = 0;
				Branch.branchid = x;
				BranchList.push(Branch);
			}
			if (this._action == 'create') {
				this._Brand.createdon = new Date();
				this._Brand.createdby = sessionStorage["userid"];
				this._Brand.modifiedon = new Date();
				this._Brand.modifiedby = sessionStorage["userid"];
				if (this._WebImage == undefined || this._WebImage == null || this._MobileImage == undefined || this._MobileImage == null) {
					this._CustomExceptionService.handleWarning("Please select Brand image");
					return
				} else {
					this._Brand.brandurl = this._WebImage.name;
					this._Brand.brandurlname = this._WebImage.name;
					this._Brand.mobilebrandurl = this._MobileImage.name;
					this._Brand.mobilebrandurlname = this._MobileImage.name;
				}
				this._IsProgressSpinner = true;
				this._BrandForm = this.formconverstion(this._Brand, this._WebImage, this._MobileImage, BranchList);
				this._BrandService.Create(this._BrandForm).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._IsProgressSpinner = false;
					if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
						this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
						this.reset(null);
					} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); }
				}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
			else if (this._action == 'edit') {
				if (this._WebImage == undefined && this._MobileImage == undefined) {
					this._Brand.brandurl = this.Brandform.get("brandurl").value;
					this._Brand.brandurlname = this.Brandform.get("brandurlname").value;
					this._Brand.mobilebrandurl = this.Brandform.get("mobilebrandurl").value;
					this._Brand.mobilebrandurlname = this.Brandform.get("mobilebrandurlname").value;
				}
				if (this._WebImage) {
					this._Brand.brandurl = this._WebImage.name;
					this._Brand.brandurlname = this._WebImage.name;
					this._Brand.mobilebrandurl = this._MobileImage.name;
					this._Brand.mobilebrandurlname = this._MobileImage.name;
				}
				this._Brand.modifiedon = new Date();
				this._Brand.modifiedby = sessionStorage["userid"];
				this._IsProgressSpinner = true;
				this._BrandForm = this.formconverstion(this._Brand, this._WebImage, this._MobileImage, BranchList);
				this._BrandService.Update(this._BrandForm).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._IsProgressSpinner = false;
					if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
						this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
					} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); }
				}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
		}
	}
	reset(event) {
		this._submitted = true;
		this.Brandform.reset();
		this.branchid = [];
		this.isDisabledSave = false;
		this.isDisabledClear = false;
		this.InitializeForm();
		this.Brandform.controls['recordstatus'].patchValue("MSC00001");
		this.croppedImage = [];
		this.imageselect = "";
		$(".file-upload-wrapper").attr("data-text", 'Select your file!');
		this._submitted = false;
	}
	selectFile(event: any) {
		if (event.target.files && event.target.files[0]) {
			const img = new Image();
			img.src = URL.createObjectURL(event.target.files[0]);
			img.onload = (e: any) => {
				if (e.srcElement.width == 300 && e.srcElement.height == 150) {
					var reader = new FileReader();
					reader.onload = (event: any) => {
						let url = event.target.result;
						this.webcompressFile(url, -1)
					}
					reader.readAsDataURL(event.target.files[0]);
				} else {
					$(".file-upload-wrapper").attr("data-text", 'Select your file!');
					this._CustomExceptionService.handleWarning("Your uploaded image should be minimum Width and height of 300px X 150px")
				}
			}
		}
	}
	webcompressFile(image: any, orientation: any) {
		this.imageCompress
			.compressFile(image, orientation, 100, 70)
			.then((result: any) => {
				const imageBlob = this.dataURItoBlob(result.split(',')[1]);
				this.Weburl = imageBlob;
				this.mobilecompressFile(result, -1);
			});
	}
	mobilecompressFile(image: any, orientation: any) {
		this.imageCompress
			.compressFile(image, orientation, 75, 70)
			.then((result: any) => {
				const imageBlob = this.dataURItoBlob(result.split(',')[1]);
				this.Mobileurl = imageBlob
				this.createBlobImageFileAndShow(this.Weburl, this.Mobileurl);
			});
	}
	createBlobImageFileAndShow(web: any, mobile: any): void {
		const webimageName: string = this.generateName();
		const mobileimageName: string = webimageName;
		this._WebImage = new File([web], webimageName, {
			type: "image/webp"
		});
		this._MobileImage = new File([mobile], mobileimageName, {
			type: "image/webp"
		});
	}
	dataURItoBlob(dataURI: any) {
		const byteString = window.atob(dataURI);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([int8Array], { type: 'image/webp' });
		return blob;
	}
	showImageDialog(position1: string) {
		if (this.brand == null) {
			return;
		}
		this.position1 = position1;
		this.displayimagepopup = true;
	}
	generateName(): string {
		const date: number = new Date().valueOf();
		let text: string = "";
		const possibleText: string =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (let i = 0; i < 5; i++) {
			text += possibleText.charAt(
				Math.floor(Math.random() * possibleText.length)
			);
		}
		return date + "." + text + ".webp";
	}
	saveImage(event) {
		var imgWidth = $('.source-image').get(0).naturalWidth;
		var imgheight = $('.source-image').get(0).naturalHeight;
		if (imgWidth >= 400 && imgheight >= 200) {
		} else {
			this._CustomExceptionService.handleWarning("Your uploaded image should be minimum Width and height of 400px X 200px")
		}
		this.imageCropperTool = false
	}
	formconverstion(_Brand: Brand, _webimage: File, _mobileimage: File, BranchList: any) {
		let time = new Date();
		const formData: FormData = new FormData();
		if (_webimage != undefined && _mobileimage != undefined) {
			if (_Brand.brandurlname == this._WebImage.name && _Brand.mobilebrandurlname == this._MobileImage.name) {
				var WebNewFileName = time.getTime() + '_' + this._WebImage.name;
				var MobileNewFileName = time.getTime() + '_' + this._MobileImage.name;
				_Brand.brandurlname = WebNewFileName;
				_Brand.brandurl = WebNewFileName;
				_Brand.mobilebrandurl = MobileNewFileName;
				_Brand.mobilebrandurlname = MobileNewFileName;
				formData.append('webfile', _webimage, WebNewFileName);
				formData.append('mobilefile', _mobileimage, MobileNewFileName);
			}
		}
		this._BrandObj = {
			"Brand": this._Brand,
			"Brandbranchdetail": BranchList
		};
		var brands = JSON.stringify(this._BrandObj);
		formData.append('Brand', brands);
		return (formData);
	}
	showPopupDialog(position1: string) {
		this.position1 = position1;
		this.displayimagepopup = false;
	}
}