import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { ServiceProductDeliveryService } from 'src/app/core/Services/workshop/service-product-delivery.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-service-product-delivery',
  templateUrl: './v-service-product-delivery.component.html',
  styleUrls: ['./v-service-product-delivery.component.css']
})
export class VServiceProductDeliveryComponent implements OnInit {
  _Action: IAction;
  _serviceproductdeliveryviews: any[] = []
  _IsProgressSpinner: boolean = true;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _branchid: number;
  cols: any[];
  _roleid = sessionStorage["currentRole"]
  _userid = sessionStorage["userid"];
  mindate: Date;
	maxdate: Date;

  constructor(private fb: FormBuilder, private _CustomExceptionService: CustomExceptionService, private _serviceproductdeliveryService: ServiceProductDeliveryService, private router: Router, private utility: CommonUtilities,
    private confirmationService: ConfirmationService, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService, private exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this.fromdate = new Date();
    this.todate = new Date();
    this._Action = this._AccessRightsService.getAccessRights(332);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.SetFromToDate();
    this.utility.pageLoadScript();
    this.HotKeyIntegration();
    this._IsProgressSpinner = false;
    this.cols = [
      { field: 'serviceproductdeliveryno', header: 'Service product Delivery Code' },
      { field: 'serviceproductdeliverydate', header: 'Service product Delivery Date' },
      { field: 'customername', header: 'Customer Name' },
      { field: 'servicerequestno', header: 'Service Request No' },
      { field: 'netamount', header: 'Net Amount' },
      { field: 'statusname', header: ' Status' },
      { field: 'username', header: 'Created By' },
      { field: 'CREATEDON', header: 'Created On' },

    ]
    this.Fetchall();
  }
  SetFromToDate(){
		if(sessionStorage['Environmentenddate'] == 'null'){
			this.fromdate = new Date();
			this.todate = new Date();
			this.maxdate = new Date();
		}
		else{
			this.fromdate = new Date(sessionStorage['Environmentenddate'])
			this.todate = new Date(sessionStorage['Environmentenddate'])
		}
		this.fromdate.setDate(this.fromdate.getDate() - 1);
	}
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    let data = {
      "fromdate": fromdate,
      "todate": todate,
      "branchid": this._branchid
    }
    this._IsProgressSpinner = true;
    this._serviceproductdeliveryService.FetchAll(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._serviceproductdeliveryviews = resultJSON.spdView;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/CServiceProductDelivery'], { state: { serviceproductdeliveryid: event.serviceproductdeliveryid, action: 'view' } });

  }

  GoToCreate(event) {
    this.router.navigate(['/CServiceProductDelivery']);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let purchaseorderList = this.GetPurchaseorderList();
    this.exportUtility.exportExcel(purchaseorderList, 'Service_product_delivery', 'xlsx');
  }

  ExportToPdf() {
    let purchaseorderList = this.GetPurchaseorderList();
    this.exportUtility.ExportToPdf(purchaseorderList, 'Service_product_delivery.pdf');
  }

  GetPurchaseorderList() {
    let purchaseorderList = [];
    this._serviceproductdeliveryviews.forEach(element => {
      let purchaseorder: any = {};
      purchaseorder["Service product Delivery Code"] = element.serviceproductdeliveryno;
      purchaseorder["Service product Delivery Date"] = this.datepipe.transform(element.serviceproductdeliverydate, 'dd/MM/yyyy');
      purchaseorder["Customer Name"] = element.customername;
      purchaseorder["Service Request No"] = element.servicerequestno;
      purchaseorder["Net Amount"] = element.netamount;
      purchaseorder["Status"] = element.statusname;
      purchaseorder["Created By"] = element.username;
      purchaseorder["Created On"] = this.datepipe.transform(element.CREATEDON, 'dd/MM/yyyy');
      purchaseorderList.push(purchaseorder);
    });
    return purchaseorderList;
  }
}
