import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Currency } from 'src/app/core/Models/Currency';
import { VatincludeexcludereportService } from 'src/app/core/Services/reports/Sales/Vatincludeexcludereport/vatincludeexcludereport.service';
import { Customerview } from 'src/app/core/Views/Customerview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-vatincludeexcludereport',
  templateUrl: './vatincludeexcludereport.component.html',
  styleUrls: ['./vatincludeexcludereport.component.css']
})
export class VatincludeexcludereportComponent implements OnInit {
  _Vat: any[];
  _branchlist: any[] = [];
  _branchid = sessionStorage["BranchID"];
  _userid: number;
  datepipe: any;
  _customerlist: Customerview[];
  _src: SafeResourceUrl;
  filteredProducts: any[];
  _currencylist: Currency[] = [];
  _SalesReportPrintObj: any;
  _vatincludeexcludereportform: FormGroup;
  today = new Date();
  _IsProgressSpinner: boolean = false;
  VatIncludeExcludeObj: any;
  currencyid: number[] = [];
  isDisabledvatfree = false;
  isDisabledvat = false;
  _Action: IAction;
  mindate: Date;
  maxdate: Date;


  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _AccessRightsService: AccessRightsService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private sanitizer: DomSanitizer, private VatincludeexcludereportService: VatincludeexcludereportService) {

  }
  InitializeDiscountReportForm() {
    this._vatincludeexcludereportform = this.fb.group({
      fromdate: [],
      todate: [],
      branchid: [],
      customerid: [],
      vat: [],
      currencyid: [0],
      isvatfree: []
    });
    if (sessionStorage['Environmentenddate'] == "null") {
      this._vatincludeexcludereportform.get("fromdate").setValue(new Date);
      this._vatincludeexcludereportform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._vatincludeexcludereportform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._vatincludeexcludereportform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
  }



  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(134);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeDiscountReportForm();
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this._userid = parseInt(sessionStorage["userid"]);
    this._IsProgressSpinner = true;
    this.VatincludeexcludereportService.PageOnload(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branches;
      this._customerlist = resultJSON.customers;
      this._Vat = resultJSON.vat;
      this._currencylist = resultJSON.currencyList;
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  GenerateReport(event) {
    var _fromdate = this._vatincludeexcludereportform.get("fromdate").value;
    var _todate = this._vatincludeexcludereportform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    var customerid = this._vatincludeexcludereportform.get("customerid").value || [];
    var branchid = this._vatincludeexcludereportform.get("branchid").value || [];
    var vat = this._vatincludeexcludereportform.get("vat").value;
    var isvatfree = this._vatincludeexcludereportform.get("isvatfree").value || false;
    var currencyid = this.currencyid;

    if (!currencyid.length) {
      this._CustomExceptionService.handleError("Please Select Atleast One Currency Name");
      return;
    }

    if (vat == null && isvatfree == false) {
      this._CustomExceptionService.handleError("Please Select Vat or Is Vat Free");
      return;
    }
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this.VatIncludeExcludeObj = { "FromDate": fromdate, "ToDate": todate, "Branchid": branchid || [], "Customerid": customerid || [], "Userid": parseInt(sessionStorage["userid"]), "Branch": parseInt(sessionStorage["currentbranchid"]), "Vat": vat, "Currencyid": currencyid || [], "ISVatFree": this._vatincludeexcludereportform.get("isvatfree").value || false }
    this.VatincludeexcludereportService.Print(this.VatIncludeExcludeObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this.InitializeDiscountReportForm();
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.isDisabledvatfree = false;
    this.isDisabledvat = false;
    this.currencyid = [];

  }
  onchangevat() {

    var vat = this._vatincludeexcludereportform.get("vat").value || [];

    if (vat != null) {
      this.isDisabledvatfree = true;
    }
    else {
      this.isDisabledvatfree = false;
    }
  }
  onchangevatfree() {
    var vatfree = this._vatincludeexcludereportform.get("isvatfree").value;

    if (vatfree == true) {
      this.isDisabledvat = true;
    }
    else {
      this.isDisabledvat = false;
    }
  }
}
