<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Credit Note</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save" [hidden]="_IsSaveHidden" (click)="onSave()"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear" [hidden]="_IsClearHidden" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search" title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_Creditnoteform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input pInputText name="creditnoteid" formControlName="creditnoteid" disabled>
                                    <label for="creditnoteid">Credit Note No</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="creditnotedate" [showIcon]="true" [readonlyInput]="true"
                                        dateFormat="dd/mm/yy"  [minDate]="mindate" [maxDate]="maxdate" formControlName="creditnotedate">
                                    </p-calendar>
                                    <label for="creditnotedate">Creditnote Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Creditnoteform.get('creditnotedate').touched) && _Creditnoteform.get('creditnotedate').errors?.SelectCreditnoteDate">
                                    Please Select Creditnote Date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Partytypes" [disabled]="true"
                                        optionLabel="metasubdescription" optionValue="metasubid" name="partytypeid"
                                        formControlName="partytypeid">
                                    </p-dropdown>
                                    <label for="partytypeid">Party Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Creditnoteform.get('partytypeid').touched) && _Creditnoteform.get('partytypeid').errors?.SelectPartyType">
                                    Please Select Party Type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_accounts" optionLabel="name"
                                        optionValue="ledgerid" name="accountid" formControlName="accountid" [filter]="true"  filterBy="name"
                                        (onChange)="GetCustomerBasedEvidenceNo($event)">
                                    </p-dropdown>
                                    <label for="accountid">Account Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Creditnoteform.get('accountid').touched) && _Creditnoteform.get('accountid').errors?.SelectAccountName">
                                    Please Select Account Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_creditnotetransactionform">
                                    <span class="p-float-label">
                                        <input pInputText name="evidenceno" formControlName="evidenceno" disabled>
                                        <label for="evidenceno">Evidence Type</label>
                                    </span>
                                </form>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="refNo" disabled formControlName="refNo">
                                        <label for="refNo">Reference No <span class="hlt-txt">*</span></label>
                                    </span>
                                    <button class="p-inputgroup-addon p-button" type="button" (click)="showBasicDialog()" [disabled]="_action == 'view'">
                                        <i class="pi pi-external-link"></i>
                                    </button>
                                </div>
                                <span class="text-danger" *ngIf="(_submitted || _Creditnoteform.get('refNo').touched) && _Creditnoteform.get('refNo').errors?.SelectReferenceNo">
                                    Please Select Reference No
                                </span>
                            </td>

                            <td>
                                <form [formGroup]="_creditnotetransactionform">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="salesinvoicedate"
                                            formControlName="salesinvoicedate" disabled>
                                        <label for="salesinvoicedate">Invoice Date</label>
                                    </span>
                                </form>
                            </td>
                            <td>
                                <form [formGroup]="_creditnotetransactionform">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="amountinusd" formControlName="amountinusd"
                                            disabled>
                                        <label for="amountinusd">Invoice Amount</label>
                                    </span>
                                </form>
                            </td>
                            <td>
                                <form [formGroup]="_creditnotetransactionform">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="customername" formControlName="customername"
                                            disabled>
                                        <label for="customername">customer Name</label>
                                    </span>
                                </form>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                        optionLabel="currencyname" (onChange)="ChangeCurrencies($event)"
                                        optionValue="currencyid" name="currentcurrencyid" [disabled]=true
                                        formControlName="currentcurrencyid">
                                    </p-dropdown>
                                    <label for="currentcurrencyid"> Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Creditnoteform.get('currentcurrencyid').touched) && _Creditnoteform.get('currentcurrencyid').errors?.SelectCurrency">
                                    Please Select Currency
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="exchangerate" formControlName="exchangerate"
                                        disabled>
                                    <label for="exchangerate"> Exchange Rate </label>
                                </span>
                            </td>

                            <td></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Transaction Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_TransactionDetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['purchaseinvoiceno','purchaseinvoicedate','productname','quantity','unitprice','transactionamount','debitamount','reason']"
                        [rowHover]="true" dataKey="productid" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px;text-align:center">Action</th>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>Return Quantity</th>
                                <th>Unit Price</th>
                                <th>Tax Percentage</th>
                                <th>Tax Amount</th>
                                <th>Discount Percentage</th>
                                <th>Transaction Amount</th>
                                <th>Credit Amount</th>
                                <th>Reason</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_TransactionDetail>
                            <tr>
                                <td style="width: 50px" style="text-align:center">
                                    <p-tableCheckbox [value]="_TransactionDetail" [hidden]="_action == 'view'">
                                    </p-tableCheckbox>
                                </td>
                                <td>{{_TransactionDetail.productname}}</td>
                                <td>{{_TransactionDetail.quantity}}</td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [(ngModel)]="_TransactionDetail.returnquantity" [disabled]="!_TransactionDetail.enableQuantity" (keyup)="calcuateCreditAmount(_TransactionDetail)">
                                            <!-- (keyup)="calcuateCreditAmount(_TransactionDetail)" -->
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_TransactionDetail.returnquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_TransactionDetail.unitprice | number: '1.3'}}
                                </td>
                                <td>
                                    {{_TransactionDetail.taxPer}}
                                </td>
                                <td>
                                    {{_TransactionDetail.taxamount| number: '1.3'}}
                                </td>
                                <td>
                                    {{_TransactionDetail.discountPer }}
                                </td>
                                <td>
                                    {{_TransactionDetail.totalamount | number: '1.3'}}
                                </td>
                                <td>
                                    {{_TransactionDetail.creditamount | number: '1.3'}}
                                    <!-- <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText [(ngModel)]="_TransactionDetail.creditamount">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_TransactionDetail.creditamount}}
                                        </ng-template>
                                    </p-cellEditor> -->
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText [(ngModel)]="_TransactionDetail.reason" [disabled]="!_TransactionDetail.enableReason">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_TransactionDetail.reason}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table class="normal-table">
                    <tr>
                        <td colspan="2">
                            <div class="sub-title">
                                <h5>Other Charges</h5>
                            </div>
                        </td>
                        <td colspan="3">
                            <div class="sub-title">
                                <h5>Payment Details</h5>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="card">
                                <p-table [value]="creditnoteothercharges" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['otherchargeid','otherchargeamount']" [rowHover]="true" dataKey="otherchargeid" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [showCurrentPageReport]="true" [(selection)]="selectedOtherChargesRows">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width:100px;text-align: center;">Action</th>
                                            <th>Description</th>
                                            <th>Value</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-_othercharge>
                                        <tr>
                                            <td style="text-align:center">
                                                <button pButton pRipple title="Add" icon="pi pi-plus" [disabled]="isDisabledadd" class="p-button-success p-mr-2" (click)="AddOtherCharges($event)"></button>
                                                <button pButton pRipple title="Remove" icon="pi pi-trash" [disabled]="isDisabledremove" class="p-button-danger" (click)="RemoveOtherCharges(_othercharge)"></button>
                                            </td>
                                            <td pEditableColumn>
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <p-dropdown #id [autoDisplayFirst]="false" [options]="_Descriptions" [disabled]="isDisabledothercharges" *ngIf="_action != 'view'" optionLabel="otherchargename" optionValue="otherchargeid" [(ngModel)]="_othercharge.otherchargeid" (onChange)="OnchangeOtherCharge(_othercharge,$event)">
                                                        </p-dropdown>
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        <p-dropdown [disabled]="true" [autoDisplayFirst]="false" [options]="_Descriptions" optionLabel="otherchargename" optionValue="otherchargeid" [(ngModel)]="_othercharge.otherchargeid">
                                                        </p-dropdown>
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td>
                                            <td pEditableColumn>
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <input type="number" pInputText name="otherchargeamount" (keyup)="OnChangeotherCharge(_othercharge,$event)" [(ngModel)]="_othercharge.otherchargeamount" maxlength="7" (keypress)="keypress.kpNumber($event)" *ngIf="_action != 'view'">
                                                        <div *ngIf="_action == 'view'">
                                                            {{_othercharge.otherchargeamount}}</div>
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{_othercharge.otherchargeamount}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td>

                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </td>
                        <td colspan="3">
                            <table class="normal-table">
                                <td style="width: 25%;">

                                    <span class="p-float-label">
                            <input type="number" name="grossamount" [(ngModel)]="grossamount" disabled pInputText>
                            <label for="grossamount">Gross Amount</label>
                        </span>
                                </td>
                                <td style="width: 25%;">
                                    <span class="p-float-label">
                            <input type="number" name="taxamount" [(ngModel)]="taxamount" disabled pInputText>
                            <label for="taxamount">Tax Amount</label>
                        </span>
                                </td>
                                <td style="width: 25%;">
                                    <span class="p-float-label">
                            <input type="number" name="otherchargesamount" [(ngModel)]="otherchargesamount" disabled
                                pInputText>
                            <label for="otherchargesamount">Other Deduction Amount</label>
                        </span>
                                </td>

                                <td style="width: 25%;">
                                    <span class="p-float-label">
                            <input type="number" name="netamount" [(ngModel)]="netamount" disabled pInputText>
                            <label for="netamount">Net Amount</label>
                        </span>
                                </td>
                            </table>
                        </td>
                    </tr>


                </table>

                <p-dialog header="Evidence No" [(visible)]="displayBasic" [modal]="true" [style]="{width: '50vw',height: '500px'}" [baseZIndex]="10000">
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="card">
                                <p-toolbar styleClass="p-mb-4">
                                    <ng-template pTemplate="left">
                                        <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtReferenceno.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                    </ng-template>
                                </p-toolbar>
                                <p-table #dtReferenceno [value]="_CreditReferences" selectionMode="single" [globalFilterFields]="['salesinvoiceno','salesinvoicedate','customername','currencyname','amountinusd']" [(selection)]="selectedReferenceNo" (onRowSelect)="onRowSelectPopup($event)"
                                    (onRowUnselect)="onRowUnSelectPopup($event)" [paginator]="true" [rows]="5">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn="salesinvoiceno">Invoice Number
                                                <p-sortIcon field="salesinvoiceno">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="salesinvoicedate">Invoice Date
                                                <p-sortIcon field="salesinvoicedate">
                                                </p-sortIcon>
                                            </th>

                                            <th pSortableColumn="customername">Customer Name
                                                <p-sortIcon field="customername">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="currencyname">Currency Name
                                                <p-sortIcon field="currencyname">
                                                </p-sortIcon>
                                            </th>

                                            <th pSortableColumn="amountinusd">Invoice Amount
                                                <p-sortIcon field="amountinusd">
                                                </p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-_CreditReference>
                                        <tr [pSelectableRow]="rowData">
                                            <td>{{_CreditReference.salesinvoiceno}}</td>
                                            <td>{{_CreditReference.salesinvoicedate | date:'dd-MM-yyyy'}}</td>

                                            <td>{{_CreditReference.customername}}</td>
                                            <td>{{_CreditReference.currencyname}}</td>

                                            <td>{{_CreditReference.amountinusd}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
                <!-- <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div> -->
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>

<p-toast position="bottom-right"></p-toast>