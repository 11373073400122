import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';


@Injectable({
  providedIn: 'root'
})
export class RptgrnService {
  public _Action: IAction;
  public _httpOptions;
  public _RptGRNService = environment.RptGRNService;
  constructor(private http: HttpClient) { }

  PageOnload(branchid : number,userid : number): Observable<any> {
    var Query = this._RptGRNService + '/PageInitialize/' + branchid + '/' + userid;
    
    return this.http.get<{ data: any }>(Query); 
  }
  getProductCategory(producttype:object) {
    var Query = this._RptGRNService+'/getProductCategories';
    
    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory:object) {
    var Query = this._RptGRNService + '/getProducts';
    
    return this.http.post<{ data: any }>(Query, productcategory);
  }
  getBrand(product:object) {
    var Query = this._RptGRNService + '/getBrand';
    return this.http.post<{ data: any }>(Query, product);
  }
  
  Print(grnreport:object) {
    var Query = this._RptGRNService ;
    return this.http.post(Query,grnreport, { responseType: 'blob' });
  }
}
