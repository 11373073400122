import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Contra } from 'src/app/core/Models/Contra';
import { usererrors } from 'src/app/core/errors/usererrors';
import * as ace from "ace-builds";
import { Currency } from 'src/app/core/Models/Currency';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { vAmount, vCurrencyNameSelect, vFromAccountNameSelect, vToAccountNameSelect } from 'src/app/core/Validators/validation';
import { ContraService } from 'src/app/core/Services/accounts/contra.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
@Component({
	selector: 'app-c-contra',
	templateUrl: './c-contra.component.html',
	styleUrls: ['./c-contra.component.css']
})
export class CContraComponent implements OnInit {
	_isSaveDisable: boolean = false;
	_isClearDisable: boolean = false;
	_contraform: FormGroup;
	_contrabalanceform: FormGroup;
	_Contraid: number;
	_Contra: Contra;
	_submitted = false;
	_action: string = "create";
	_fromaccounts: Accountledgerview[];
	_toaccounts: Accountledgerview[];
	_fromaccountsTemp: Accountledgerview[];
	_toaccountsTemp: Accountledgerview[];
	_currencies: Currency;
	_CurrenciesTemp: Currency[];
	_IsProgressSpinner: boolean = true;
	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	_userid: number;
	Contraid: number;
	_isDisabledsendtoapproval: boolean = true;
	_Voucherno: string;
	constructor(
		private fb: FormBuilder,
		private _router: Router,
		private _ContraService: ContraService,
		private utility: CommonUtilities,
		private messageService: MessageService,
		private _CustomExceptionService: CustomExceptionService,
		private _hotkeysService: HotkeysService
	) {
		this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
			this.onSave();
			return false; // Prevent bubbling
		})); //Back To Search Short cut keys
		this._hotkeysService.add(new Hotkey('backspace', (event: KeyboardEvent): boolean => {
			this.goBack(event);
			return false; // Prevent bubbling
		})); //Clear
		this._hotkeysService.add(new Hotkey('alt+x', (event: KeyboardEvent): boolean => {
			this.reset(event);
			return false; // Prevent bubbling
		}));
	}
	Initialize() {
		this._contraform = this.fb.group({
			contraid: [0],
			voucherno: [0],
			fromaccountname: ['', vFromAccountNameSelect],
			toaccountname: ['', vToAccountNameSelect],
			amount: ['', vAmount],
			narration: [],
			currencyuniquerefid: [1],
			currentcurrencyid: ['', vCurrencyNameSelect],
			exchangerateinusd: [],
			amountinusd: [0],
			accountingyear: [],
			contrastatusid: [45],
			contrastatusdesc: ["Created"],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: []
		});
		this._contrabalanceform = this.fb.group({
			fromaccountbalance: [],
			toaccountbalance: [],
		});
	}
	ngOnInit(): void {
		this.utility.pageLoadScript();
		this.Initialize();
		this._userid = parseInt(sessionStorage["userid"]);
		this._Contraid = history.state?.contraid ? history.state?.contraid : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this._Voucherno = history.state?.voucherno ? history.state?.voucherno : 0;
		this._IsProgressSpinner = true;
		const data = sessionStorage.getItem('LedgerData');
		if (data) {
			const parsedData = JSON.parse(data);
			this._Contraid = parsedData.contraid;
			this._userid = parsedData.userid;
			this._Voucherno = parsedData.voucherno;
			this._action = parsedData.action;
			sessionStorage.removeItem('LedgerData');
		  }
		this._ContraService.PageOnLoad(this._Contraid, this._userid, this._Voucherno).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._fromaccounts = resultJSON.fromaccounts;
			this._toaccounts = resultJSON.toaccounts;
			this._fromaccountsTemp = resultJSON.fromaccounts || [];
			this._toaccountsTemp = resultJSON.toaccounts || [];
			this._CurrenciesTemp = resultJSON.currencies || [];
			this._currencies = resultJSON.currencies;
			this._IsProgressSpinner = false;
			if (this._action == 'view') {
				const updateJSON = JSON.parse(JSON.stringify(result));
				this._contraform.disable();
				this._fromaccounts = updateJSON.fromaccounts;
				this._toaccounts = updateJSON.toaccounts;
				this._currencies = updateJSON.currencies;
				this._fromaccountsTemp = updateJSON.fromaccounts || [];
				this._toaccountsTemp = updateJSON.toaccounts || [];
				this._CurrenciesTemp = updateJSON.currencies || [];
				this._contraform.setValue(updateJSON.contras[0]);
				this._contrabalanceform.controls['fromaccountbalance'].setValue(this._fromaccounts[0].currentbalance);
				this._contrabalanceform.controls['toaccountbalance'].setValue(this._toaccounts[0].currentbalance);
				this._isSaveDisable = true;
				this._isClearDisable = true;
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	} //Get From Account Balance in Textbox(fromaccountbalance)
	getfromaccountbalance(event): void {
		this._contrabalanceform.controls['fromaccountbalance'].setValue(0);
		this._contrabalanceform.get("fromaccountbalance").disable();
		let obj = {
			ledgerid: event.value
		}
		let ledger = this._fromaccountsTemp.filter(f => f.ledgerid == event.value)[0];
		var _series = ledger.series;
		this._toaccounts = this._toaccountsTemp.filter(f => f.series == _series || f.series == null);
		this._ContraService.GetClosingBalance(obj).subscribe((result: any) => {
			if (result && result.tranStatus.result) {
				this._contrabalanceform.controls['fromaccountbalance'].setValue(result.closingbalance);
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	} //Get To Account Balance in Textbox(toaccountbalance)
	gettoaccountbalance(event): void {
		this._contrabalanceform.controls['toaccountbalance'].setValue(0);
		this._contrabalanceform.get("toaccountbalance").disable();
		let obj = {
			ledgerid: event.value
		}
		if (this._contraform.get("fromaccountname").value) {
			this._ContraService.GetClosingBalance(obj).subscribe((result: any) => {
				if (result && result.tranStatus.result) {
					this._contrabalanceform.controls['toaccountbalance'].setValue(result.closingbalance);
				}
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		} else {
			this.ShowErrorMsg("Please Select From Account");
			this.reset(null);
		}
	} //Get Exchangerate in textbox(exchangerateinusd)
	ChangeCurrencies(event): void {
		var exchangerateinusd = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].exchangerate;
		this._contraform.controls['exchangerateinusd'].setValue(exchangerateinusd);
	}
	onSave() {
		this._submitted = true;
		if (this._contraform.valid) {
			this._Contra = this._contraform.value;
			if (this._action == 'create') {
				this._Contra.contrastatusid = 45;
				this._Contra.contrastatusdesc = "Created";
				this._Contra.createdon = new Date();
				this._Contra.createdby = parseInt(sessionStorage["userid"]);
				this._Contra.accountingyear = new Date().getFullYear();
				var errMsg = null;
				var fromAccount = this._contraform.get("fromaccountname").value;
				var toAccount = this._contraform.get("toaccountname").value;
				if (fromAccount == toAccount)
					errMsg = "From Account and To Account should not be same.";
				if (this.ShowErrorMsg(errMsg))
					return;
				this._IsProgressSpinner = true;
				this._ContraService.create(this._Contra).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._IsProgressSpinner = false;
					if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
						this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
						this._isDisabledsendtoapproval = false
						this._isSaveDisable = true;
						this._isClearDisable=true;
						this.Contraid = resultJSON.contraid;
						// this.reset(null);
					} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); }
				}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
		}
	}
	reset(event) {
		this._contraform.reset();
		this.Initialize();
		this._submitted = false;
	}
	goBack(event) {
		console.log("goBack");
		this._router.navigate(['/vContra']);
	}
	onEd() {
		ace.config.set("fontSize", "14px");
		ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
		const aceEditor = ace.edit(this.editor.nativeElement);
		aceEditor.getSession().setMode("ace/mode/json");
		var myObj = this._Contra;
		var myJSON = JSON.stringify(myObj);
		aceEditor.session.setValue(myJSON);
	}
	ShowErrorMsg(errMsg) {
		if (!errMsg)
			return false;
		this.messageService.add({ severity: 'error', summary: 'Error Message', sticky: true, detail: errMsg });
		return true;
	}
	SendToApproval() {
		var workflowid = 63;
		var _Userid = sessionStorage["userid"];
		var _branchid = parseInt(sessionStorage["BranchID"]);
		this._IsProgressSpinner = true;
		let data = {
			"workflowid": workflowid,
			"Userid": _Userid,
			"branchid": _branchid,
			"Contraid": this.Contraid, 
		}
		this._ContraService.SendToApproval(data).subscribe((result: any) => {
			if (result && result.tranStatus.result) {
				this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
				this._isDisabledsendtoapproval = true;
				this._isClearDisable=true;
				this._isSaveDisable = false;
				if (this._action == 'edit') {
					this._isSaveDisable = true;
				}
				if (this._action != 'edit') {
					this.reset(null);
				}
			} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); }
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
}