<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="SUPPLIER NOT MAPPED PRODUCT LIST" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcelproductnotmapped()" *ngIf="isExcelVisible"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="PRODUCT NOT MAPPED SUPPLIER LIST" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcelproductnotmappedsupplier()" *ngIf="isExcelVisible"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="SUPPLIER MAPPED PRODUCT LIST" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcelproductmapped()" *ngIf="isExcelVisible"></button>

                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [filter]="true" [options]="_Brands" name="brandid"
                                    optionLabel="brandname" optionValue="brandid" [(ngModel)]="_SelectedBrand"
                                    displaySelectedLabel=true itemSize="15">
                                </p-multiSelect>
                                <label for="brandid">Brand<span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_VatTypes" name="vattypename"
                                    [(ngModel)]="_vattype" optionLabel="vattypename" optionValue="vattypeid"
                                    [disabled]="isVatList">
                                </p-dropdown>
                                <label for="name">Vat Types </label>
                            </span>
                        </td>

                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_statuslist" name="statusid"
                                    [(ngModel)]="_productstatus" optionLabel="statusname" optionValue="statusid"
                                    [disabled]="isStatuslist">
                                </p-dropdown>
                                <label for="name">Status </label>
                            </span>
                        </td>
                        <td style="width: 20%;" [formGroup]="_perishableSearchForm">
                            <div class="p-formgroup-inline">
                                <div class="p-field-checkbox">
                                    <p-checkbox name="isperishable" binary="true" formControlName="isperishable" (onChange)="isperishableCheck()">
                                    </p-checkbox>
                                    <label for="isperishable">Is Perishable</label>
                                </div>
                            </div>
                        </td>
                        <td style="width: 20%;"></td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Search" icon="las la-search" (click)="FetchAll(2)" class="p-button-sm p-button-success"></button>
                            <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()" class="p-button-danger"></button>
                        </td>
                    </tr>
                </table>
                <p-toast></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                            <!-- <button pButton pRipple label="" icon="pi pi-plus" class="p-button-success p-mr-2"
                           (click)="openNew()"></button>
                          <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger ">
                          </button>-->
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_productsview" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['productcode','producttypename','categoryname','productname','brandname',
                        'purchaserateinusd','discountpercentage','productstatusname','qacount','reviewcount','createdbyname',
                        'modifiedbyname','createdon','modifiedon','isperishablename','approvalstatusname']" [rowHover]="true" dataKey="productid" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>

                                <th class="text-center" style="width: 100px;">Action </th>

                                <th pSortableColumn="productcode" [hidden]="true">Product Image
                                    <p-sortIcon field="productcode">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productcode">Product Code
                                    <p-sortIcon field="productcode">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="producttypename">Product Type
                                    <p-sortIcon field="producttypename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="categoryname">Product Category
                                    <p-sortIcon field="categoryname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="brandname">Brand Name
                                    <p-sortIcon field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="purchaserateinusd">Purchaserate (USD)
                                    <p-sortIcon field="purchaserateinusd">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="discountpercentage">Discount Percentage
                                    <p-sortIcon field="discountpercentage">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="barcode">Barcode
                                    <p-sortIcon field="barcode">
                                    </p-sortIcon>
                                </th>
                                <!-- <th class="text-center" style="width:80px;" *ngIf="this._Action._TabViews"
                                    pSortableColumn="qacount">FAQ<p-sortIcon field="qacount">
                                    </p-sortIcon>
                                </th>
                                <th class="text-center" style="width: 80px;" *ngIf="this._Action._TabViews"
                                    pSortableColumn="reviewcount">Review<p-sortIcon field="reviewcount">
                                    </p-sortIcon>
                                </th> -->
                                <th pSortableColumn="totalsuppliersmapped">No.of.Suppliers Mapped
                                    <p-sortIcon field="totalsuppliersmapped">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productstatusname">Status
                                    <p-sortIcon field="productstatusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="isperishablename">Is Perishable
                                    <p-sortIcon field="isperishablename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="isproductdescription">Is Product Description?
                                    <p-sortIcon field="isproductdescription">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="isproductimage">Is Product Image?
                                    <p-sortIcon field="isproductimage">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="approvalstatusname">Approval Status
                                    <p-sortIcon field="approvalstatusname">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_productview>
                            <tr [ngClass]="{'qs-row': _productview.productstatusname === 'Inactive'}">

                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_productview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" (click)="edit(_productview)" [disabled]="_productview.approvalstatus == 'MSC00046' || !_Action._Update"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Delete" class="p-button p-button-danger" [disabled]="_productview.productstatus == 'MSC00002' || _productview.approvalstatus == 'MSC00046' || !_Action._Delete" (click)="confirm(_productview)"></button>

                                </td>
                                <td>
                                    {{_productview.productcode}}
                                </td>
                                <td>
                                    {{_productview.producttypename}}
                                </td>
                                <td>
                                    {{_productview.categoryname}}
                                </td>
                                <td>
                                    {{_productview.productname }}
                                </td>
                                <td>
                                    {{_productview.brandname }}
                                </td>
                                <td>
                                    {{_productview.purchaserateinusd | number: '1.2-4' }}
                                </td>
                                <td>
                                    {{_productview.discountpercentage | number: '1.2-4'}}
                                </td>
                                <td>
                                    {{_productview.barcode }}
                                </td>
                                <!-- <td class="text-center" *ngIf="this._Action._TabViews">
                                    <a href="javascript:void(0);" (click)="GetFAQ(_productview)">
                                        {{_productview.qacount}}
                                    </a>
                                </td>
                                <td class="text-center" *ngIf="this._Action._TabViews">
                                    <a href="javascript:void(0);" (click)="GetReview(_productview)">
                                        {{_productview.reviewcount}}
                                    </a>
                                </td> -->
                                <td class="text-center" style="width: 100px;">
                                    <a href="javascript:void(0);" (click)="GetMappedSupplier(_productview)">
                                        {{_productview.totalsuppliersmapped}}
                                    </a>
                                </td>
                                <td>
                                    {{_productview.createdbyname}}
                                </td>
                                <td>
                                    {{_productview.productstatusname }}
                                </td>
                                <td>
                                    {{_productview.isperishablename }}
                                </td>
                                <td>
                                    {{_productview.isproductdescription }}
                                </td>
                                <td>
                                    {{_productview.isproductimage }}
                                </td>
                                <td>
                                    {{_productview.approvalstatusname }}
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
                <p-dialog header="Mapped Supplier Details" [(visible)]="mappedSupplierdetails" [modal]="true" [style]="{width: '50vw',height: '380px'}" [baseZIndex]="10000">
                    <br>
                    <table>
                        <tr>
                            <!-- <td style="width: 25px;"> </td> -->

                            <td style="width: 25px;">
                                <span class="p-float-label">
                                    <input type="text" name="producttypename" disabled="true"
                                        [(ngModel)]="selectedProductType" pInputText>
                                    <label for="producttypename">Product Type </label>
                                </span>
                            </td>
                            <td style="width: 25px;">
                                <span class="p-float-label">
                                    <input type="text" name="categoryname" disabled="true"
                                        [(ngModel)]="selectedProductCategory" pInputText>
                                    <label for="categoryname">Product Category </label>
                                </span>
                            </td>
                            <td style="width: 25px;">
                                <span class="p-float-label">
                                    <input type="text" name="productname" disabled="true"
                                        [(ngModel)]="selectedProductName" pInputText>
                                    <label for="productname">Product Name </label>
                                </span>
                            </td>
                        </tr>
                    </table>
                    <br>
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="card">
                                <p-toolbar styleClass="p-mb-4">
                                    <ng-template pTemplate="left">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text"
                                                (input)="dta.filterGlobal($event.target.value, 'contains')"
                                                placeholder="Search..." />
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="right">
                                    </ng-template>
                                </p-toolbar>
                                <!-- <p-table #dt [value]="_supplierviews" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['supplierid', 'suppliername','suppliertypename','paymodename','currencyname',
                                'supplierstatusname','createdbyname','totalproducts']" [rowHover]="true"
                                dataKey="supplierid" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)"
                                (onRowUnselect)="onRowUnselect($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true"> -->
                                <p-table #dta [value]="_suppliermappedlist" selectionMode="single" [columns]="ProductWiseLockedcols" [rowHover]="true" dataKey="productid" [globalFilterFields]="['SUPPLIERNAME']" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [rows]="10" [paginator]="true">
                                    <!-- <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="PRODUCTNAME" disabled="true" pInputText>
                                            <label for="PRODUCTNAME">Product Name </label>
                                        </span>
                                    </td> -->
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn="SNO" style="width: 60px;">S.no
                                                <p-sortIcon field="SNO">
                                                </p-sortIcon>
                                                <th pSortableColumn="SUPPLIERNAME">Supplier Name
                                                    <p-sortIcon field="SUPPLIERNAME">
                                                    </p-sortIcon>
                                                </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-_SupplierProductmapping let-i="rowIndex">
                                        <tr>
                                            <td style="width: 60px;">
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{_SupplierProductmapping.SUPPLIERNAME}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
                <!-- FAQ -->
                <!-- For pop Up QuestionAndAnswers -->
                <p-dialog header="FAQ" [(visible)]="displayBasic" [modal]="true" [style]="{width: '80vw',height: '500px'}" [baseZIndex]="10000">
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="text-right">
                                <div class="action-btn">
                                    <button pButton pRiple label="" icon="pi pi-plus" title="Add" class="p-button p-button-success p-mr-2" (click)="FAQ()"></button>
                                </div>
                            </div>
                            <!-- <div class="search-container">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input type="text" id="inputtext" pInputText>
                            </span>
                            <button class="p-inputgroup-addon p-button" title="Search">
                                <i class="pi pi-search"></i>
                            </button>
                        </div>
                    </div> -->
                            <div class="card">
                                <p-table [value]="Productquestionanswers" selectionMode="single" [(selection)]="selectedBank" [paginator]="true" [rows]="5">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="text-align: center;width: 100px;">Action</th>
                                            <!-- <th pSortableColumn="productcode">Product Code<p-sortIcon field="productcode">
                                        </p-sortIcon>
                                    </th> -->
                                            <!-- <th pSortableColumn="bankname">Product Name <p-sortIcon field="bankname">
                                        </p-sortIcon>
                                    </th> -->
                                            <th pSortableColumn="question">Question
                                                <p-sortIcon field="question">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="answer">Answer
                                                <p-sortIcon field="answer">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="answerby">Answer By
                                                <p-sortIcon field="answerby">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="recordstatusname">Status
                                                <p-sortIcon field="recordstatusname">
                                                </p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-Productquestionanswer>
                                        <tr [pSelectableRow]="rowData">
                                            <td class="text-center">
                                                <!-- <button pButton pRipple title="Add" icon="pi pi-plus"
                                            class="p-button-success p-mr-2"
                                            (click)="FAQ()"></button> -->
                                                <button pButton pRipple title="Edit" icon="las la-pen" class="p-button p-button-success p-mr-2" (click)="EditFAQ(Productquestionanswer)"></button>
                                            </td>
                                            <!-- <td>{{Productquestionanswer.productcode}}</td> -->
                                            <!-- <td>{{Productquestionanswer.productname}}</td> -->
                                            <td>
                                                {{Productquestionanswer.question}}
                                            </td>
                                            <td>
                                                {{Productquestionanswer.answer}}
                                            </td>
                                            <td>
                                                {{Productquestionanswer.answerby}}
                                            </td>

                                            <td>
                                                {{Productquestionanswer.recordstatusname}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>

                <!-- Review -->
                <!-- For pop Up -->
                <p-dialog header="Review" [(visible)]="displayBasicReview" [modal]="true" [style]="{width: '80vw',height: '500px'}" [baseZIndex]="10000">
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="text-right">
                                <div class="action-btn">
                                    <button pButton pRiple label="" icon="pi pi-plus" title="Add" class="p-button p-button-success p-mr-2" (click)="Review()"></button>
                                </div>
                            </div>
                            <!-- <div class="search-container">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input type="text" id="inputtext" pInputText>
                            </span>
                            <button class="p-inputgroup-addon p-button" title="Search">
                                <i class="pi pi-search"></i>
                            </button>
                        </div>
                    </div> -->
                            <div class="card">
                                <p-table [value]="ProductReviews" selectionMode="single" [(selection)]="selectedBank" [paginator]="true" [rows]="5">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="text-align: center;width: 100px;">Action</th>
                                            <!-- <th pSortableColumn="bankcode">Product Code<p-sortIcon field="bankcode">
                                        </p-sortIcon>
                                    </th> -->
                                            <!-- <th pSortableColumn="bankname">Product Name <p-sortIcon field="bankname">
                                        </p-sortIcon>
                                    </th> -->
                                            <th pSortableColumn="reviewrating">Rating
                                                <p-sortIcon field="reviewrating">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="reviewdescription">Review
                                                <p-sortIcon field="reviewdescription">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="reviewby">Review By
                                                <p-sortIcon field="reviewby">
                                                </p-sortIcon>
                                            </th>

                                            <th pSortableColumn="recordstatusname">Status
                                                <p-sortIcon field="recordstatusname">
                                                </p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-Productreview>
                                        <tr [pSelectableRow]="rowData">
                                            <td class="text-center">
                                                <!-- <button pButton pRipple title="Add" icon="pi pi-plus"
                                            class="p-button-success p-mr-2"
                                            (click)="Review()"></button> -->
                                                <button pButton pRipple title="Edit" icon="las la-pen" class="p-button p-button-success p-mr-2" (click)="EditReview(Productreview)"></button>
                                            </td>
                                            <td>
                                                {{Productreview.reviewrating}}
                                            </td>
                                            <td>
                                                {{Productreview.reviewdescription}}
                                            </td>
                                            <td>
                                                {{Productreview.reviewby}}
                                            </td>

                                            <td>
                                                {{Productreview.recordstatusname}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
                <!-- For pop Up FAQ -->
                <p-dialog header="Product Q&A" [(visible)]="ManageDialogFAQ" [style]="{width: '350px',height:'300px'}" [modal]="true" [maximizable]="false">
                    <ng-template pTemplate="content">
                        <form [formGroup]="_faqform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <textarea rows="2" cols="30" formControlName="question"
                                                pInputTextarea></textarea>
                                            <label for="question">Question </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <textarea rows="2" cols="30" formControlName="answer"
                                                pInputTextarea></textarea>
                                            <label for="answer">Answer </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_FAQStatus"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                formControlName="recordstatus">
                                            </p-dropdown>
                                            <label for="status">FAQ Status </label>
                                        </span>
                                    </td>
                                </tr>

                            </table>
                        </form>
                    </ng-template>
                    <ng-template pTemplate="footer">

                        <button pButton pRipple label="Save" (click)="SaveFAQ()" icon="las la-check" class="p-button-text"></button>
                        <button pButton pRipple label="Cancel" icon="pi pi-times" (click)="Cancel()" class="p-button-text"></button>
                    </ng-template>
                </p-dialog>
                <!-- For Update -->
                <p-dialog header="Product Q&A" [(visible)]="ManageDialogFAQUpdate" [style]="{width: '350px',height:'300px'}" [modal]="true" [maximizable]="false">
                    <ng-template pTemplate="content">
                        <form [formGroup]="_faqform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <textarea rows="2" cols="30" formControlName="question"
                                                pInputTextarea></textarea>
                                            <label for="question">Question </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <textarea rows="2" cols="30" formControlName="answer"
                                                pInputTextarea></textarea>
                                            <label for="answer">Answer </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_FAQStatus"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                formControlName="recordstatus">
                                            </p-dropdown>
                                            <label for="status">FAQ Status </label>
                                        </span>
                                    </td>
                                </tr>

                            </table>
                        </form>
                    </ng-template>
                    <ng-template pTemplate="footer">

                        <button pButton pRipple label="Save" (click)="UpdateFAQ()" icon="las la-check" class="p-button-text"></button>
                        <button pButton pRipple label="Cancel" icon="pi pi-times" (click)="Cancel()" class="p-button-text"></button>
                    </ng-template>
                </p-dialog>
                <!-- Review -->
                <p-dialog header="Product Review" [(visible)]="ManageDialogReview" [style]="{width: '350px',height:'300px'}" [modal]="true" [maximizable]="false">
                    <ng-template pTemplate="content">
                        <form [formGroup]="_Productreviewform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <h5>Rating</h5>
                                        <p-rating [cancel]="false" formControlName="reviewrating"></p-rating>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <textarea rows="2" cols="30" formControlName="reviewdescription"
                                                pInputTextarea></textarea>
                                            <label for="reviewdescription">Review </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_ReviewStatus"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                formControlName="recordstatus">
                                            </p-dropdown>
                                            <label for="status">Review Status </label>
                                        </span>
                                    </td>
                                </tr>

                            </table>
                        </form>
                    </ng-template>
                    <ng-template pTemplate="footer">

                        <button pButton pRipple label="Save" (click)="createReview()" icon="las la-check" class="p-button-text"></button>
                        <button pButton pRipple label="Cancel" icon="pi pi-times" (click)="Cancel()" class="p-button-text"></button>
                    </ng-template>
                </p-dialog>
                <!-- Review -->
                <p-dialog header="Product Review" [(visible)]="ManageDialogReviewUpdate" [style]="{width: '350px',height:'300px'}" [modal]="true" [maximizable]="false">
                    <ng-template pTemplate="content">
                        <form [formGroup]="_Productreviewform">
                            <table class="normal-table">
                                <tr>
                                    <td>
                                        <h5>Rating</h5>
                                        <p-rating [cancel]="false" formControlName="reviewrating"></p-rating>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <textarea rows="2" cols="30" formControlName="reviewdescription"
                                                pInputTextarea></textarea>
                                            <label for="reviewdescription">Review </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_ReviewStatus"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                formControlName="recordstatus">
                                            </p-dropdown>
                                            <label for="status">Review Status </label>
                                        </span>
                                    </td>
                                </tr>

                            </table>
                        </form>
                    </ng-template>
                    <ng-template pTemplate="footer">

                        <button pButton pRipple label="Save" (click)="UpdateReview()" icon="las la-check" class="p-button-text"></button>
                        <button pButton pRipple label="Cancel" icon="pi pi-times" (click)="Cancel()" class="p-button-text"></button>
                    </ng-template>
                </p-dialog>
            </div>
        </div>
    </div>