import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ActOpenCloseBal } from 'src/app/core/Models/ActOpenCloseBal';
import { ClosingBalanceUpdationService } from 'src/app/core/Services/accounts/closing-balance-updation.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-closing-balance-updation',
  templateUrl: './closing-balance-updation.component.html',
  styleUrls: ['./closing-balance-updation.component.css']
})
export class ClosingBalanceUpdationComponent implements OnInit {
  _ActOpenCloseBalform: FormGroup
  _IsProgressSpinner: boolean
  _AccountList: any[] = []
  ClosingBalanceUpdationOBJ: ActOpenCloseBal
  _userid = sessionStorage["userid"];
  _AccountYearList: any[] = []
  todate: Date
  _Action: IAction;
  constructor(
    private fb: FormBuilder,
    private _CustomExceptionService: CustomExceptionService,
    private _ClosingBalanceUpdationService: ClosingBalanceUpdationService,
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService
  ) { }
  InitializeFormGroup() {
    this._ActOpenCloseBalform = this.fb.group({
      companyid: [],
      branchid: [],
      id: [],
      accountid: [],
      accountname: [],
      accountgroupid: [],
      sysOpeningBal: [],
      manOpeningBal: [],
      sysClosingBal: [],
      manClosingBal: [],
      status: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      accountingyear: [],
    });
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(337);
    this.utility.pageLoadScript();
    this.InitializeFormGroup();
    this._IsProgressSpinner = false;
    let TaskName = "PageOnLoad"
    let Data = {
      "TaskName": TaskName,
      "AccountingYear": null,
      "AccountId": null
    }
    this._IsProgressSpinner = true;
    this._ClosingBalanceUpdationService.PageOnLoad(Data).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        this._AccountYearList = result.accountYearList
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
        this._IsProgressSpinner = false;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  OnChangeAccount() {
    this._ActOpenCloseBalform.get("sysOpeningBal").reset();
    this._ActOpenCloseBalform.get("manOpeningBal").reset();
    this._ActOpenCloseBalform.get("sysClosingBal").reset();
    this._ActOpenCloseBalform.get("manClosingBal").reset();
    let AccountId = this._ActOpenCloseBalform.get("accountid").value
    let AccountName = this._AccountList.find(f => f.accountid == AccountId).accountname;
    this._ActOpenCloseBalform.get("accountname").setValue(AccountName);
    let TaskName = "OnChangeAccount"
    let Year = new Date()
    let AccountingYear = this._ActOpenCloseBalform.get("accountingyear").value
    let Data = {
      "TaskName": TaskName,
      "AccountingYear": AccountingYear,
      "AccountId": AccountId
    }
    this._IsProgressSpinner = true;
    this._ClosingBalanceUpdationService.PageOnLoad(Data).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        console.log(result.accountDetailList)
        let ActOpenCloseBal = result.accountDetailList
        this._ActOpenCloseBalform.setValue(ActOpenCloseBal)
        // this.ClosingBalanceUpdationOBJ = this._ActOpenCloseBalform.getRawValue()
        this._ActOpenCloseBalform.get("sysOpeningBal").setValue(ActOpenCloseBal.sysOpeningBal.toFixed(2));
        this._ActOpenCloseBalform.get("manOpeningBal").setValue(ActOpenCloseBal.manOpeningBal.toFixed(2));
        this._ActOpenCloseBalform.get("sysClosingBal").setValue(ActOpenCloseBal.sysClosingBal.toFixed(2));
        this._ActOpenCloseBalform.get("manClosingBal").setValue(ActOpenCloseBal.manClosingBal.toFixed(2));
        this._ActOpenCloseBalform.get("modifiedby").setValue(this._userid);
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
        this._IsProgressSpinner = false;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  OnChangeAccountingYear() { 
    this._ActOpenCloseBalform.get("accountid").reset();
    this._ActOpenCloseBalform.get("sysOpeningBal").reset();
    this._ActOpenCloseBalform.get("manOpeningBal").reset();
    this._ActOpenCloseBalform.get("sysClosingBal").reset();
    this._ActOpenCloseBalform.get("manClosingBal").reset();
    let TaskName = "OnChangeAccountingYear"
    let AccountingYear =this._ActOpenCloseBalform.get("accountingyear").value
    let Data = {
      "TaskName": TaskName,
      "AccountingYear": AccountingYear,
      "AccountId": null
    }
    this._IsProgressSpinner = true;
    this._ClosingBalanceUpdationService.PageOnLoad(Data).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        this._AccountList = result.accountList
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
        this._IsProgressSpinner = false;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSave() {
    let AccountId = this._ActOpenCloseBalform.get("accountid").value
    let manOpeningBal = this._ActOpenCloseBalform.get("manOpeningBal").value
    let manClosingBal = this._ActOpenCloseBalform.get("manClosingBal").value
    if (!AccountId) {
      this._CustomExceptionService.handleWarning("Please Select Account Name");
      return
    }
    if (!manOpeningBal) {
      this._CustomExceptionService.handleWarning("Please Enter Openning Balance");
      return
    }
    if (!manClosingBal) {
      this._CustomExceptionService.handleWarning("Please Enter Closing Balance");
      return
    }
    this.ClosingBalanceUpdationOBJ = this._ActOpenCloseBalform.getRawValue();
    let Data = {
      ActOpenCloseBal: this.ClosingBalanceUpdationOBJ
    }
    this._IsProgressSpinner = true;
    this._ClosingBalanceUpdationService.edit(Data).subscribe((result) => {
      this._IsProgressSpinner = false;
      const resutJSON = JSON.parse(JSON.stringify(result));
      if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
        this._ActOpenCloseBalform.reset()
      }
      else {
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}