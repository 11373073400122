import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderpendingStatusService {
  public OrderPendingService = environment.OrderPendingStatusService;
  public _httpOptions;

  constructor(private http: HttpClient) { }

  PageOnload(): Observable<any> {
    var Query = this.OrderPendingService;
    return this.http.get<{ data: any }>(Query);
  }
  FetchALL(fromdate: string, todate: string,tellerid:number,branchid:number): Observable<any> {
    var Query = this.OrderPendingService + '/FetchALL/' + fromdate + '/' + todate+'/'+tellerid+'/'+branchid;
    return this.http.get<{ data: any }>(Query);
  }
}
