import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Port } from 'src/app/core/Models/Port';
import { vPortName, vStatusSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
//Debug
import * as ace from "ace-builds";
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { PortService } from 'src/app/core/Services/masters/port.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-c-port',
  templateUrl: './c-port.component.html',
  styleUrls: ['./c-port.component.css']
})
export class CPortComponent implements OnInit {
  _portform: FormGroup;
  _portstatus: Metadatum;
  _submitted = false;
  _action: string = "create";
  _Port: Port;
  _portid: number;
  _IsProgressSpinner: boolean = true;
  isDisabledsave = false;
  isDisabledreset = false;
  _Action: IAction;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _PortService: PortService, private _router: Router, private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,
    ) {
    this._portid = history.state?.portid ? history.state?.portid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    //Save Short cut keys    
    this.HotKeyIntegration();
  }
  InitializeForm(){
    this._portform = this.fb.group({
      portid: [0],
      portname: ['', vPortName],
      portstatus: ['',vStatusSelect],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: []
    });
    this._portform.controls['portstatus'].setValue("MSC00001");
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(25);
    this.utility.pageLoadScript();
   this.InitializeForm();
   
    this._IsProgressSpinner = true;
    this._PortService.PageOnLoad(this._portid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._portstatus = resultJSON.status;
      //For Update
      this._portid = history.state.portid;
      this._portform.controls['portstatus'].setValue("MSC00001");
      this._IsProgressSpinner = false;

      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._portform.setValue(updateJSON.port);
      }
      if (this._action == 'view') {
        this._portform.disable();
        this.isDisabledsave = true;
        this.isDisabledreset = true;
      }
      if(this._action == 'edit'){
        this.isDisabledreset = true;
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onSave() {
    this._submitted = true;

    if (this._portform.valid) {
      this._Port = this._portform.value;
      //var CityOBJ = { "City": this._City };
      //Debug
      // this.onEd();
      if (this._action == 'create') {
        this._Port.createdon = new Date();
        this._Port.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._PortService.create(this._Port).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
         this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {

        this._Port.modifiedon = new Date();
        this._Port.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._PortService.edit(this._Port).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
  // 13. clear all the fields which the data has entered 
  reset(event) {
    this._submitted = true;
    this._portform.reset();
    this.InitializeForm();
    this._submitted = false;
  }
  // 24.on clicking back button , it Navigates to the search screen
  goBack(event) {
    //alert('This');
    this._router.navigate(['/vPort']);
  }

  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }



  //Debug
  // onEd()
  // {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._Port;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);

  // }
}
