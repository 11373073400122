import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import * as ace from "ace-builds";

import { RptprofitlossService } from 'src/app/core/Services/reports/Accounting/RptProfitLoss/rptprofitloss.service';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Port } from 'src/app/core/Models/Port';
import { vContainerNo, vDateSelect, vSupplierName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Producttype } from 'src/app/core/Models/Producttype';
import { MetadataMaster } from 'src/app/core/Models/overallclass';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Currencyview } from 'src/app/core/Views/Currencyview';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-rptprofitloss',
  templateUrl: './rptprofitloss.component.html',
  styleUrls: ['./rptprofitloss.component.css']
})
export class RptprofitlossComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptprofitlossform: FormGroup;
  _branchid: number;
  _userid: number;
  _currencylist: Currencyview[];
  _branchlist: Branch[] = [];
  datepipe: any;
  _Action: IAction
  _src: SafeResourceUrl;
  mindate: Date;
  maxdate: Date;
  constructor(private utility: CommonUtilities, private _AccessRightsService: AccessRightsService,
    private fb: FormBuilder, private _RptProfitLossService: RptprofitlossService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(106);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptprofitlossform = this.fb.group({
      month: [''],
      currencyid: [0],
      branchid: []
    })
    this._branchid = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptprofitlossform.get("month").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptprofitlossform.get("month").setValue(new Date(sessionStorage['Environmentenddate']));
    }
    this._rptprofitlossform.controls['currencyid'].setValue(1);
    this._rptprofitlossform.controls['branchid'].setValue(parseInt(sessionStorage["currentbranchid"]));
    this._RptProfitLossService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._currencylist = resultJSON.currencyList;
      this._branchlist = resultJSON.branchList || [];
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }

  clear(event) {
    //25.If clear clicking all fields should clear in grid
    this._rptprofitlossform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptprofitlossform.get("month").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptprofitlossform.get("month").setValue(new Date(sessionStorage['Environmentenddate']));
    }
    this._rptprofitlossform.controls['currencyid'].setValue(1);
    this._rptprofitlossform.controls['branchid'].setValue(parseInt(sessionStorage["currentbranchid"]));
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }

  GenerateReport(event) {


    var _month = this._rptprofitlossform.get("month").value;
    let branchid = this._rptprofitlossform.controls['branchid'].value;
    var datePipe = new DatePipe("en-US");
    var month = datePipe.transform(_month, 'MM-yyyy');
    if (month == null) {
      this._CustomExceptionService.handleError("Please Select Month");
      return;
    }
    var currencyid = this._rptprofitlossform.get("currencyid").value == null ? 0 : this._rptprofitlossform.get("currencyid").value;
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._RptProfitLossService.Print(month, this._userid, currencyid, branchid).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

}
