import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { ServicerequestService } from 'src/app/core/Services/workshop/servicerequest.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
@Component({
	selector: 'app-v-service-request',
	templateUrl: './v-service-request.component.html',
	styleUrls: ['./v-service-request.component.css']
})
export class VServiceRequestComponent implements OnInit {
	_Action: IAction;
	selectedrows: any;
	_servicerequestviews: any[] = []
	_IsProgressSpinner: boolean = true;
	fromdate: Date;
	todate: Date;
	datepipe = new DatePipe('en-US');
	_branchid: number;
	cols: any[];
	_roleid = sessionStorage["currentRole"]
	_userid = sessionStorage["userid"];
	mindate: Date;
	maxdate: Date;
	constructor(
		private fb: FormBuilder,
		private _CustomExceptionService: CustomExceptionService,
		private _ServicerequestService: ServicerequestService,
		private router: Router,
		private utility: CommonUtilities,
		private confirmationService: ConfirmationService,
		private _hotkeysService: HotkeysService,
		private _AccessRightsService: AccessRightsService,
		private exportUtility: ExportUtility
	) { }
	ngOnInit(): void {
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.SetFromToDate();
		this._Action = this._AccessRightsService.getAccessRights(329);
		this.utility.pageLoadScript();
		this.HotKeyIntegration();
		this._IsProgressSpinner = false;
		this.cols = [
			{ field: 'servicerequestnumber', header: 'Service Request Code' },
			{ field: 'servicerequestdate', header: 'Service Request Date' },
			{ field: 'reftypename', header: 'Referance Type' },
			{ field: 'productorcustomername', header: 'Customer/Asset Name' },
			{ field: 'refno', header: ' Referance No' },
			{ field: 'statusname', header: ' Status' },
			{ field: 'createdbyname', header: 'Created By' },
			{ field: 'CREATEDON', header: 'Created On' },
		]
		this.Fetchall();
	}
	SetFromToDate(){
		if(sessionStorage['Environmentenddate'] == 'null'){
			this.fromdate = new Date();
			this.todate = new Date();
			this.maxdate = new Date();
		}
		else{
			this.fromdate = new Date(sessionStorage['Environmentenddate'])
			this.todate = new Date(sessionStorage['Environmentenddate'])
		}
		this.fromdate.setDate(this.fromdate.getDate() - 1);
	}
	Fetchall() {
		if (!this.fromdate) {
			this._CustomExceptionService.handleError("Please Select From Date");
			return;
		}
		if (!this.todate) {
			this._CustomExceptionService.handleError("Please Select To Date");
			return;
		}
		let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
		let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
		this._branchid = parseInt(sessionStorage["currentbranchid"]);
		if (fromdate > todate) {
			this._CustomExceptionService.handleError("From Date should be less than the To Date");
			return;
		}
		let data = {
			"fromdate": fromdate,
			"todate": todate,
			"branchid": this._branchid
		}
		this._IsProgressSpinner = true;
		this._ServicerequestService.FetchAll(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this._servicerequestviews = resultJSON.serviceRequestView;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	view(event) {
		this.router.navigate(['/CServicerequest'], { state: { serviceRequestId: event.servicerequestid, action: 'view' } });
	}
	confirm(event) {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to cancel this Service Request  <b>"' + event.servicerequestnumber + '"</b> ?',
			accept: () => { this.Cancel(event); }
		});
	}
	Cancel(event) {
		let data = {
			"ServiceRequestId": event.servicerequestid
		}
		this._IsProgressSpinner = true;
		this._ServicerequestService.Cancel(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			this.Fetchall();
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	SendToApproval(event) {
		let data = {
			"ServiceRequestId": event.servicerequestid,
			"UserId": sessionStorage["userid"],
			"BranchId": sessionStorage["BranchID"],
		}
		this._IsProgressSpinner = true;
		this._ServicerequestService.SendToApproval(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
			this._IsProgressSpinner = false;
			this.Fetchall();
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	GoToCreate(event) {
		this.router.navigate(['/CServicerequest']);
	}
	HotKeyIntegration() {
		this._hotkeysService.reset();
		if (this._Action._Add) {
			this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
				this.GoToCreate(null);
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
	}
	exportExcel() {
		let purchaseorderList = this.GetPurchaseorderList();
		this.exportUtility.exportExcel(purchaseorderList, 'Service_request', 'xlsx');
	}
	// ExportToPdf() {
	// 	let purchaseorderList = this.GetPurchaseorderList();
	// 	this.exportUtility.ExportToPdf(purchaseorderList, 'Service_request.pdf');
	// }
	ExportToPdf() {
		let UsersList = this.GetPurchaseorderList();
		const data = UsersList.map(Object.values);
		const head = [[
		  'Service Request Code',
		  'Service Request Date',
		  'Referance Type',
		  'Customer/Asset Name',
		  'Referance No',
		  'Status',
		  'Created By',
		  'Created On'
		]]
		import("jspdf").then(jsPDF => {
		  import("jspdf-autotable").then(x => {
			const doc = new jsPDF.default('l', 'mm', 'a4');
			autoTable(doc, {
			  head: head,
			  body: data,
			  tableLineColor: [0, 0, 0],
			  tableLineWidth: 0,
			  styles: {
				lineColor: [0, 0, 0],
				lineWidth: 0.1,
			  },
			  headStyles: {
				fillColor: [211, 211, 211],
				textColor: 0
			  },
			  bodyStyles: {
				textColor: 0
			  },
			  didDrawCell: (data) => { },
			});
			doc.save('ServiceRequest.pdf');
		  })
		})
	  }
	GetPurchaseorderList() {
		let purchaseorderList = [];
		this._servicerequestviews.forEach(element => {
			let purchaseorder: any = {};
			purchaseorder["Service Request Code"] = element.servicerequestnumber;
			purchaseorder["Service Request Date"] = this.datepipe.transform(element.servicerequestdate, 'dd/MM/yyyy');
			purchaseorder["Referance Type"] = element.reftypename;
			purchaseorder["Customer/Asset Name"] = element.productorcustomername;
			purchaseorder["Referance No"] = element.refno;
			purchaseorder["Status"] = element.statusname;
			purchaseorder["Created By"] = element.createdbyname;
			purchaseorder["Created On"] = this.datepipe.transform(element.CREATEDON, 'dd/MM/yyyy');
			purchaseorderList.push(purchaseorder);
		});
		return purchaseorderList;
	}
	Print(event) {
		this._IsProgressSpinner = true;
		let Data = {
			"SRId": event.servicerequestid,
			"BranchId": this._branchid
		}
		this._ServicerequestService.Print(Data).subscribe((result: any) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			let url: any = URL.createObjectURL(result);
			window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
}