<div class="page-container dashboard-page scroll-y">
  <div class="inner-page-container">
      <div class="page-loader" *ngIf="_IsProgressSpinner">
          <p-progressSpinner strokeWidth="5"></p-progressSpinner>
      </div>
      <div class="white">
          <form [formGroup]="_stockdashboardform">
              <table class="normal-table">
                  <tr>
                      <td style="width: 20%;">
                          <span class="p-float-label">

                              <p-dropdown [autoDisplayFirst]="false"  [options]="_Branches"
                                filter="true"
                                name="branchid" optionLabel="branchname"
                                optionValue="branchid" formControlName="branchid"
                                (onChange)="branchChanged()">
                              </p-dropdown>
                              <label for="branchid">Branch <span class="hlt-txt">*</span></label>
                          </span>
                      </td>
                      <td style="width: 80%;">
                          <div class="row workflow-widget">
                              <div style="margin-left: 15px;">
                                  <div class="dashboard-summary color01">
                                      <div class="workflow-title" style="padding-top: 0;margin-top: 3px;width: auto;">
                                          <i class="las la-boxes"></i>
                                          <p>Total Stock Value</p>
                                      </div>
                                      <div class="workflow-links text-right" style="width: auto;">
                                          <h4>{{totalStock}} $</h4>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </td>
                  </tr>
              </table>
          </form>
      </div>
      <div class="row">
          <div class="col-md-6">
              <div class="white">
                  <div class="widget-title page-title">
                      <div class="row">
                          <div class="col-md-8">
                              <h3>Stocks-
                                <span style="color: #0948cc;font-size: 16px;padding: 0px 3px;font-weight: 100;">Product Type Wise</span>
                                (In Percentage)
                              </h3>
                          </div>
                          <div class="col-md-4">
                              <div class="widget-filter text-right">

                                  <div class="filter-container">
                                      <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="Choose Chart"><i
                                              class="las la-chart-bar"></i></a>
                                      <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                          <a (click)="chartchangesd('bar')"><i class="icofont-chart-bar-graph"></i>Bar
                                              Chart</a>
                                          <a (click)="chartchangesd('pie')"><i class="icofont-pie-chart"></i>Pie
                                              Chart</a>
                                          <a (click)="chartchangesd('line')"><i class="icofont-chart-line"></i>Line
                                              Chart</a>
                                          <a (click)="chartchangesd('doughnut')"><i
                                                  class="icofont-chart-pie-alt"></i>Donut Chart</a>
                                      </div>
                                  </div>
                                  <!-- <div class="filter-container">
                                      <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                              class="las la-ellipsis-v"></i></a>
                                      <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                          <a (click)="downloadChart($event)"><i class="icofont-file-pdf"></i>Download
                                              as PDF</a>
                                      </div>
                                  </div> -->
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="widget-body">
                      <div class="widget-details"  *ngIf="_Isbarsd">
                          <p-chart type="bar" *ngIf="!noProductTypeSV"
                                  [data]="_ProductTypeWiseStock"
                                  [options]="_ProductTypeWiseStockOpt"></p-chart>


                          <div class="widget-details"  *ngIf="noProductTypeSV">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>
                      <div class="widget-details"  *ngIf="_Islinesd">
                          <p-chart type="line" *ngIf="!noProductTypeSV" [data]="_ProductTypeWiseStock"
                          [options]="_ProductTypeWiseStockOpt"></p-chart>

                          <div class="widget-details"  *ngIf="noProductTypeSV">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>
                      <div class="widget-details"  *ngIf="_Ispiesd">

                          <p-chart type="pie" *ngIf="!noProductTypeSV" [data]="_ProductTypeWiseStock" [options]="_ProductTypeWiseStockOpt"></p-chart>

                          <div class="widget-details"  *ngIf="noProductTypeSV">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>
                      <div class="widget-details"  *ngIf="_Isdoughnutsd">
                          <p-chart type="doughnut" *ngIf="!noProductTypeSV" [data]="_ProductTypeWiseStock" [options]="_ProductTypeWiseStockOpt"></p-chart>
                          <div class="widget-details"  *ngIf="noProductTypeSV">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-6">
              <div class="white">
                  <div class="widget-title page-title">
                      <div class="row">
                          <div class="col-md-8">
                              <h3>Stock Value-
                                <span style="color: #0948cc;font-size: 16px;padding: 0px 3px;font-weight: 100;">Product Type Wise</span>
                                (In USD)
                              </h3>
                          </div>
                          <div class="col-md-4">
                              <div class="widget-filter text-right">

                                  <div class="filter-container">
                                      <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="Choose Chart"><i
                                              class="las la-chart-bar"></i></a>
                                      <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                          <a (click)="chartchangesd1('bar')"><i
                                                  class="icofont-chart-bar-graph"></i>Bar
                                              Chart</a>
                                          <a (click)="chartchangesd1('pie')"><i class="icofont-pie-chart"></i>Pie
                                              Chart</a>
                                          <a (click)="chartchangesd1('line')"><i class="icofont-chart-line"></i>Line
                                              Chart</a>
                                          <a (click)="chartchangesd1('doughnut')"><i
                                                  class="icofont-chart-pie-alt"></i>Donut Chart</a>
                                      </div>
                                  </div>
                                  <!-- <div class="filter-container">
                                    <a href="javascript:void(0);" data-toggle="dropdown"
                                        data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                            class="las la-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                        <a (click)="downloadChart($event)"><i class="icofont-file-pdf"></i>Download
                                            as PDF</a>
                                    </div>
                                </div> -->
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="widget-body">
                      <div class="widget-details"  *ngIf="_Isbarsd1">
                          <p-chart type="bar" *ngIf="!noProductTypeSV" [data]="_ProductTypeWiseStockValue"
                          [options]="_ProductTypeWiseStockValueOpt"></p-chart>

                          <div class="widget-details"  *ngIf="noProductTypeSV">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>
                      <div class="widget-details"  *ngIf="_Islinesd1">

                          <p-chart type="line" *ngIf="!noProductTypeSV" [data]="_ProductTypeWiseStockValue"
                          [options]="_ProductTypeWiseStockValueOpt"></p-chart>
                          <div class="widget-details"  *ngIf="noProductTypeSV">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>

                      <div class="widget-details"  *ngIf="_Ispiesd1">
                          <p-chart type="pie" *ngIf="!noProductTypeSV" [data]="_ProductTypeWiseStockValue"
                              [options]="_ProductTypeWiseStockValueOpt"></p-chart>

                          <div class="widget-details"  *ngIf="noProductTypeSV">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>

                      <div class="widget-details"  *ngIf="_Isdoughnutsd1">
                          <p-chart type="doughnut" *ngIf="!noProductTypeSV"
                          [data]="_ProductTypeWiseStockValue"
                          [options]="_ProductTypeWiseStockValueOpt"></p-chart>
                          <div class="widget-details"  *ngIf="noProductTypeSV">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-md-6">
              <div class="white">
                  <div class="widget-title page-title">
                      <div class="row">
                          <div class="col-md-8">
                              <h3>
                                Fast Moving Stock -
                                <span style="color: #0948cc;font-size: 16px;padding: 0px 3px;font-weight: 100;">Product Wise</span>
                                (In No's)</h3>
                          </div>
                          <div class="col-md-4">

                              <!-- <div class="widget-filter text-right">
                                  <div class="filter-container">
                                      <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="Filter By"><i
                                              class="las la-filter"></i></a>
                                      <div class="dropdown-menu dropdown-menu-right widget-popup big collapse">
                                          <form [formGroup]="_reorderdashboardform">
                                              <table class="normal-table">
                                                  <tr>
                                                      <td>
                                                          <span class="p-float-label">
                                                              <p-autoComplete formControlName="branchid"
                                                                  [suggestions]="filterBranchs"
                                                                  (completeMethod)="filterBranch($event)"
                                                                  (onSelect)="GetBranchwisestock($event)"
                                                                  field="branchname">
                                                                  <ng-template let-branch pTemplate="item">
                                                                      <div>{{branch.branchname}}</div>
                                                                  </ng-template>
                                                              </p-autoComplete>
                                                              <label for="branchid">Branch</label>
                                                          </span>
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      <td>
                                                          <span class="p-float-label">
                                                              <p-multiSelect [options]="_productname"
                                                                  formControlName="productid"
                                                                  optionLabel="productname" optionValue="productid"
                                                                  (onChange)="GetBranchwisestock($event)"
                                                                  displaySelectedLabel=true>
                                                              </p-multiSelect>
                                                              <label for="productid">Product Name </label>
                                                          </span>
                                                      </td>
                                                  </tr>

                                              </table>
                                          </form>
                                      </div>
                                  </div>
                                  <div class="filter-container">
                                      <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="Choose Chart"><i
                                              class="las la-chart-bar"></i></a>
                                      <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                          <a (click)="chartchangerd('bar')"><i class="icofont-chart-bar-graph"></i>Bar
                                              Chart</a>
                                          <a (click)="chartchangerd('pie')"><i class="icofont-pie-chart"></i>Pie
                                              Chart</a>
                                          <a (click)="chartchangerd('line')"><i class="icofont-chart-line"></i>Line
                                              Chart</a>
                                          <a (click)="chartchangerd('doughnut')"><i
                                                  class="icofont-chart-pie-alt"></i>Donut Chart</a>
                                      </div>
                                  </div>
                                  <div class="filter-container">
                                      <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                              class="las la-ellipsis-v"></i></a>
                                      <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                          <a href="javascript:void(0);"><i class="icofont-file-pdf"></i>Download
                                              as PDF</a>
                                          <a href="javascript:void(0);"><i class="icofont-file-excel"></i>Download
                                              as Excel</a>
                                      </div>
                                  </div>
                              </div> -->
                          </div>
                      </div>
                  </div>
                  <div class="widget-body">
                      <div class="card">
                          <p-toolbar *ngIf="!noFastMovingStock" styleClass="p-mb-4">
                              <ng-template pTemplate="left">
                                  <span class="p-input-icon-left">
                                      <i class="pi pi-search"></i>
                                      <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                          placeholder="Search..." />
                                  </span>
                              </ng-template>
                          </p-toolbar>
                          <div *ngIf="!noFastMovingStock">
                            <p-table #dt [value]="fastMovingStock" [rows]="5" [paginator]="true"
                            [rowsPerPageOptions]="[10,25,50,100]"
                            [globalFilterFields]="['PRODUCTNAME', 'PRODUCTIMAGEURL', 'SOLD']" [rowHover]="true"
                            dataKey="PRODUCTID"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [showCurrentPageReport]="true"
                            [scrollable]="true" scrollHeight="245px" scrollDirection="true">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="PRODUCTNAME">Product Name<p-sortIcon
                                            field="PRODUCTNAME">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="PRODUCTIMAGEURL">Image <p-sortIcon field="PRODUCTIMAGEURL"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="SOLD">Sold/day<p-sortIcon
                                            field="SOLD"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                              <ng-template pTemplate="body" let-_fastmovingstock>
                                  <tr>
                                      <td>
                                          {{_fastmovingstock.PRODUCTNAME}}
                                      </td>
                                      <td>
                                          <img [src]="_fastmovingstock.PRODUCTIMAGEURL" style="height:42px" alt="" class="img-preview">
                                      </td>
                                      <td>
                                          {{_fastmovingstock.SOLD}}
                                      </td>
                                  </tr>
                              </ng-template>
                            </p-table>
                          </div>

                          <div class="widget-details"  *ngIf="noFastMovingStock">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-md-6">
              <div class="white">
                  <div class="widget-title page-title">
                      <div class="row">
                          <div class="col-md-8">
                              <h3>Product Freshness Report -
                                <span style="color: #0948cc;font-size: 16px;padding: 0px 3px;font-weight: 100;">Product Wise</span>
                                (In Percentage)</h3>
                          </div>
                          <div class="col-md-4">
                              <div class="widget-filter text-right">
                                  <div class="filter-container">
                                      <!-- <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="Filter By"><i
                                              class="las la-filter"></i></a> -->
                                      <div class="dropdown-menu dropdown-menu-right widget-popup big collapse">
                                          <form [formGroup]="_productwisedashboardform">
                                              <table class="normal-table">
                                                  <tr>
                                                      <td>
                                                          <span class="p-float-label">
                                                              <p-autoComplete formControlName="branchid"
                                                                  [suggestions]="filterBranchs"
                                                                  (completeMethod)="filterBranch($event)"

                                                                  field="branchname">
                                                                  <ng-template let-branch pTemplate="item">
                                                                      <div>{{branch.branchname}}</div>
                                                                  </ng-template>
                                                              </p-autoComplete>
                                                              <label for="branchid">Branch</label>
                                                          </span>
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      <td>
                                                          <span class="p-float-label">
                                                              <p-multiSelect [options]="_productname"
                                                                  formControlName="productid"
                                                                  optionLabel="productname" optionValue="productid"
                                                                  displaySelectedLabel=true>
                                                              </p-multiSelect>
                                                              <label for="productid">Product Name </label>
                                                          </span>
                                                      </td>
                                                  </tr>
                                              </table>
                                          </form>
                                      </div>
                                  </div>
                                  <div class="filter-container">
                                      <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="Choose Chart"><i
                                              class="las la-chart-bar"></i></a>
                                      <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                          <a (click)="chartchangepd('bar')"><i class="icofont-chart-bar-graph"></i>Bar
                                              Chart</a>
                                          <a (click)="chartchangepd('pie')"><i class="icofont-pie-chart"></i>Pie
                                              Chart</a>
                                          <a (click)="chartchangepd('line')"><i class="icofont-chart-line"></i>Line
                                              Chart</a>
                                          <a (click)="chartchangepd('doughnut')"><i
                                                  class="icofont-chart-pie-alt"></i>Donut Chart</a>
                                      </div>
                                  </div>
                                  <!-- <div class="filter-container">
                                    <a href="javascript:void(0);" data-toggle="dropdown"
                                        data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                            class="las la-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                        <a (click)="downloadChart($event)"><i class="icofont-file-pdf"></i>Download
                                            as PDF</a>
                                    </div>
                                </div> -->
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="widget-body">
                      <div class="widget-details"  *ngIf="_Isbarpd">
                          <p-chart type="bar" [data]="stockFreshness" *ngIf="!noProductFreshness" [options]="stockFreshnessValsOpt"></p-chart>

                          <div class="widget-details"  *ngIf="noProductFreshness">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>
                      <div class="widget-details"  *ngIf="_Islinepd">

                          <p-chart type="line" [data]="stockFreshness" *ngIf="!noProductFreshness" [options]="stockFreshnessValsOpt"></p-chart>
                          <div class="widget-details"  *ngIf="noProductFreshness">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>
                      <div class="widget-details"  *ngIf="_Ispiepd">
                          <p-chart type="pie" [data]="stockFreshness" *ngIf="!noProductFreshness" [options]="stockFreshnessValsOpt"></p-chart>

                          <div class="widget-details"  *ngIf="noProductFreshness">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>
                      <div class="widget-details"  *ngIf="_Isdoughnutpd">
                          <p-chart type="doughnut" [data]="stockFreshness" *ngIf="!noProductFreshness" [options]="stockFreshnessValsOpt"></p-chart>
                          <div class="widget-details"  *ngIf="noProductFreshness">
                            <img  style="max-height: 344px;margin: auto;display: block;" src="../../../../../assets/images/no-data.jpg" alt="">
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>
      <!-- <div class="row">
          <div class="col-md-6">
              <div class="white">
                  <div class="widget-title page-title">
                      <div class="row">
                          <div class="col-md-8">
                              <h3>Product Wise Damaged Stock</h3>
                          </div>
                          <div class="col-md-4">
                              <div class="widget-filter text-right">
                                  <div class="filter-container">
                                      <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="Filter By"><i
                                              class="las la-filter"></i></a>
                                      <div class="dropdown-menu dropdown-menu-right widget-popup big collapse">
                                          <form [formGroup]="_productdamageddashboardform">
                                              <table class="normal-table">
                                                  <tr>
                                                      <td>
                                                          <span class="p-float-label">
                                                              <p-autoComplete formControlName="branchid"
                                                                  [suggestions]="filterBranchs"
                                                                  (completeMethod)="filterBranch($event)"
                                                                  (onSelect)="GetProductwisedamaged($event)"
                                                                  field="branchname">
                                                                  <ng-template let-branch pTemplate="item">
                                                                      <div>{{branch.branchname}}</div>
                                                                  </ng-template>
                                                              </p-autoComplete>
                                                              <label for="branchid">Branch</label>
                                                          </span>
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      <td>
                                                          <span class="p-float-label">
                                                              <p-multiSelect [options]="_productname"
                                                                  formControlName="productid"
                                                                  optionLabel="productname" optionValue="productid"
                                                                  (onChange)="GetProductwisedamaged($event)"
                                                                  displaySelectedLabel=true>
                                                              </p-multiSelect>
                                                              <label for="productid">Product Name <span
                                                                      class="hlt-txt">*</span></label>
                                                          </span>
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      <td>

                                                          <span class="p-float-label">
                                                              <p-dropdown [options]="_Severities"
                                                                  formControlName="severityid"
                                                                  optionLabel="metasubdescription"
                                                                  optionValue="metasubcode"
                                                                  (onChange)="GetProductwisedamaged($event)"
                                                                  >
                                                              </p-dropdown>
                                                              <label for="severityname">Severity Type<span
                                                                      class="hlt-txt">*</span></label>
                                                          </span>
                                                      </td>
                                                  </tr>

                                              </table>
                                          </form>
                                      </div>
                                  </div>
                                  <div class="filter-container">
                                      <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="Choose Chart"><i
                                              class="las la-chart-bar"></i></a>
                                      <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                          <a (click)="chartchangepdd('bar')"><i
                                                  class="icofont-chart-bar-graph"></i>Bar
                                              Chart</a>
                                          <a (click)="chartchangepdd('pie')"><i class="icofont-pie-chart"></i>Pie
                                              Chart</a>
                                          <a (click)="chartchangepdd('line')"><i class="icofont-chart-line"></i>Line
                                              Chart</a>
                                          <a (click)="chartchangepdd('doughnut')"><i
                                                  class="icofont-chart-pie-alt"></i>Donut Chart</a>
                                      </div>
                                  </div>
                                  <div class="filter-container">
                                      <a href="javascript:void(0);" data-toggle="dropdown"
                                          data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                              class="las la-ellipsis-v"></i></a>
                                      <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                          <a href="javascript:void(0);"><i class="icofont-file-pdf"></i>Download
                                              as PDF</a>
                                          <a href="javascript:void(0);"><i class="icofont-file-excel"></i>Download
                                              as Excel</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="widget-body">
                      <div class="widget-details"  *ngIf="_Isbarpdd">
                          <p-chart type="bar" [data]="basicDatapdd" [options]="basicOptions"></p-chart>
                      </div>
                      <div class="widget-details"  *ngIf="_Islinepdd">
                          <p-chart type="line" [data]="basicDatapdd" [options]="basicOptions"></p-chart>
                      </div>
                      <div class="widget-details"  *ngIf="_Ispiepdd">
                          <p-chart type="pie" [data]="basicDatapdd" [options]="basicOptions"></p-chart>
                      </div>
                      <div class="widget-details"  *ngIf="_Isdoughnutpdd">
                          <p-chart type="doughnut" [data]="basicDatapdd" [options]="basicOptions"></p-chart>
                      </div>
                  </div>
              </div>
          </div>
      </div> -->
  </div>
</div>
