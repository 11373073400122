<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Supplier Wise Closing Stock Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptbranchwisestockform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_SupplierFliter"
                                        optionValue="supplierid" optionLabel="suppliername" formControlName="supplierid"
                                        [filter]="true" filterBy="suppliername" [virtualScroll]="true" itemSize="15"
                                        (onChange)="getBrand()">
                                    </p-dropdown>
                                    <label for="supplierid">Supplier Name <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_producttypelist" optionLabel="producttypename"
                                        optionValue="producttypeid" [(ngModel)]="producttypeid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProductCategory($event)"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                                        [selectionLimit]="50" [(ngModel)]="productcatid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProduct($event)"
                                        optionValue="productcategoryid" [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productlist" optionLabel="productname"
                                        optionValue="productid" [(ngModel)]="productid"
                                        [ngModelOptions]="{standalone: true}" [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>
                            </td>

                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                                        [(ngModel)]="brandid" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="brandid">Brand Name </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" icon="pi pi-search" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Supplier Export" (click)="supplierExcel()" class="p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_ReportLists" [columns]="cols" [rows]="10" [paginator]="true" [scrollable]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['PRODUCTNAME']" [rowHover]="true" dataKey="productname" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" scrollHeight="300px">
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup id="colgroup"></colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of cols" style="width: 300px;text-align: center;" pSortableColumn="{{col}}"> {{col}}
                                    <p-sortIcon field="{{col}}">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-colvalues="columns">
                            <tr>
                                <td *ngFor="let col of colvalues" [style.text-align]="col === 'PRODUCTNAME' ? 'left' : 'right'" style="width: 300px;">
                                    {{rowData[col]}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer" let-columns>
                            <tr>
                                <td *ngFor="let col of cols" [style.text-align]="col === 'PRODUCTNAME' ? 'center' : 'right'" style="width: 300px;">
                                    <ng-container>{{ calculateColumnTotal(col)}}</ng-container>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                Total Quantity
                                <input type="number" [(ngModel)]="totalsum" disabled pInputText>
                            </span>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>