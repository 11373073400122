import { state } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Metadatum } from 'src/app/core/Models/metadatum';
//import { CountryService } from 'src/app/core/services/masters/country.service';
import { vCountryCode, vCountryName, vStatusSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { MessageService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Competitor } from 'src/app/core/Models/Competitor';
import { CompetitorService } from 'src/app/core/Services/masters/competitor.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';


@Component({
  selector: 'app-c-competitor',
  templateUrl: './c-competitor.component.html',
  styleUrls: ['./c-competitor.component.css']
})
export class CCompetitorComponent implements OnInit {
  _competitorform: FormGroup;
  _competitorstatus: Metadatum;//16.status dropdown load from metadata table && 20.status is drop down column load from metadata(active, in active)
  _Competitor: Competitor;
  _competitorid: number;
  _action: string = "create";
  _submitted = false;
  isDisabledsave = false;
  isDisabledreset = false;
  _IsProgressSpinner: boolean = true;
  _Action: IAction;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;

  constructor(private fb: FormBuilder, private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _route: ActivatedRoute,
    private _CompetitorService: CompetitorService,    private _AccessRightsService: AccessRightsService,
    private _router: Router, private utility: CommonUtilities, private _hotkeysService: HotkeysService) {
      this._competitorid = history.state?.competitorid ? history.state?.competitorid : 0;
      this._action = history.state.action == null ? 'create' : history.state.action;
      this.HotKeyIntegration();
     }
     InitializeForm(){
      this._competitorform = this.fb.group({
        competitorid: [0],
        competitorname: ['', vCountryName],
        competitorstatus: ['', vStatusSelect],//22.if "Status " is not select, show error as "Please Select Status"
        createdby: [],
        createdon: [],
        modifiedby: [],
        modifiedon: [],
       
      })
      this._competitorform.controls['competitorstatus'].setValue("MSC00001");
     }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(153);
    this.utility.pageLoadScript();
   this.InitializeForm();
  

    this._IsProgressSpinner = true;
    this._CompetitorService.PageOnLoad(this._competitorid).subscribe((result) => {

      const resultJSON = JSON.parse(JSON.stringify(result));
     
      // throw this._CustomExceptionService.handleError("Please  fill customer form properly");
      this._competitorstatus = resultJSON.competitorStatus;
      this._IsProgressSpinner = false;
     
      this.isDisabledsave = false;
      //this.isDisabledreset = false;
      //For Update
      this._competitorid = history.state.competitorid;
      if (this._action == 'edit' || this._action == 'view') {
        this.isDisabledreset = true;
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._competitorform.setValue(updateJSON.competitor);
        this.isDisabledsave = false;
        this.isDisabledreset = false;
      }
      if (this._action == 'view') {
        this._competitorform.disable();
        this.isDisabledsave = true;
        this.isDisabledreset = true;
      }
      if(this._action == 'edit'){
        this.isDisabledreset = true;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onSave() {
  
    this._submitted = true;
   
    //8. if all the data is valid allow to save the record in the database 
    if (this._competitorform.valid) {
      this._Competitor = this._competitorform.value;
    
      // this.onEd();
      if (this._action == 'create') {
        this._Competitor.createdon = new Date();
        this._Competitor.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._CompetitorService.create(this._Competitor).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this.isDisabledsave = true;
            this._IsProgressSpinner = false;
            //10.after saving the record, get the response A, display the success message as "competitor Name A  Registered Succesfully "
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
            this.isDisabledsave = false;         
           
          }
          else {
            //6.check in the country table whether country code is already given, show error as "country code A already Exists"
            //7.check in the country table whether country name is already given, show error as "country Name A already Exists"
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        this._Competitor.modifiedon = new Date();
        this._Competitor.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._CompetitorService.edit(this._Competitor).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this.isDisabledsave = true;
            this._IsProgressSpinner = false;
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
        
            this.isDisabledsave = false;
            
          }
          else {
            //24.after Updating the record, get the response A, display the success message as "Country  Name A  Modified Succesfully "
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
  reset(event) {
    //11.clear all the fields which the data has entered
    this._submitted = true;
    this._competitorform.reset();
    this.InitializeForm();
    this._submitted = false;
  }
  goBack(event) {
    // 25.on clicking back button , it Navigates to the search screen
    this._router.navigate(['/vCompetitor']);
  }

  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

}
