import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { RewardService } from 'src/app/core/Services/Rewards/reward.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Rewardview } from 'src/app/core/Views/Rewardview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';

@Component({
  selector: 'app-v-reward',
  templateUrl: './v-reward.component.html',
  styleUrls: ['./v-reward.component.css']
})
export class VRewardComponent implements OnInit {
  _Rewardviews: Rewardview[];
  selectedrows: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  cols:any[];
  _Action: IAction
  constructor(private fb: FormBuilder, private router: Router, private utility: CommonUtilities,
    private _RewardService: RewardService, private _AccessRightsService: AccessRightsService, private _hotkeysService: HotkeysService,
    private exportUtility:ExportUtility,public datepipe: DatePipe,private confirmationService:ConfirmationService,
    private _CustomExceptionService:CustomExceptionService) {
    this._Action = this._AccessRightsService.getAccessRights(80);
    this.HotKeyIntegration();
  }

  ngOnInit(): void {

    this.utility.pageLoadScript();
    this.cols = [
      { field: 'tempapplicablerewardpoints', header: 'Reward Points' },
      { field: 'temprewardsvalue', header: 'Reward value in USD' },
      {field: 'effectivefrom', header: 'Effective From' },
      {field: 'effectiveto', header: 'Effective To' },
      {field: 'rewardstatusname', header: 'Status' }
    ]
    this._RewardService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Rewardviews = resultJSON.rewardviews;
    });
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  onRowSelect($event) { }
  onRowUnselect($event) { }
  view(event) {
    this.router.navigate(['/cReward'], { state: { rewardid: event.rewardid, action: 'view' } });
  }
  edit(event) {
    this.router.navigate(['/cReward'], { state: { rewardid: event.rewardid, action: 'edit' } });
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this reward <b>"' + event.rewardid + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    var rewardid = event.rewardid;
    this._RewardService.Cancel(rewardid, sessionStorage["userid"]).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Rewardviews = resultJSON.rewardviews;
    },
    error => { this._CustomExceptionService.handleError(error);});
  }


  GoToCreate(event) {
    this.router.navigate(['/cReward']);
  }
  exportExcel() {
    let rewardList = this.GetRewardsList();
    this.exportUtility.exportExcel(rewardList, 'Rewards', 'xlsx');
  }
  exportPdf() {
    let rewardList = this.GetRewardsList();
    this.exportUtility.ExportToPdf(rewardList, 'Rewards.pdf');
  }
  GetRewardsList()
  {
    let rewardList = [];
    this._Rewardviews.forEach(element => {
      let rewards: any = {};
      rewards["Rewards Point"] = element.tempapplicablerewardpoints;
      rewards["Reward value in USD"] = element.temprewardsvalue;
      rewards["Effective From"] = this.datepipe.transform(element.effectivefrom,'dd/MM/yyyy');
      rewards["Effective To"] = this.datepipe.transform(element.effectiveto,'dd/MM/yyyy');
      rewards["Status"] = element.rewardstatusname;
      rewardList.push(rewards);
    });
    return rewardList;
  }

}
