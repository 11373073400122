import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Debitnote } from 'src/app/core/Models/Debitnote';
import { Debitnotedetail } from 'src/app/core/Models/Debitnotedetail';
import { DebitnoteService } from 'src/app/core/services/accounts/debitnote.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { Currency } from 'src/app/core/Models/Currency';
import { Metadatum } from 'src/app/core/Models/metadatum';

import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Debitnotegettransactiondetail } from 'src/app/core/Models/Debitnotegettransactiondetail';
import { vSelectCreditnoteDate, vSelectPartyType } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
@Component({
  selector: 'app-c-debitnote',
  templateUrl: './c-debitnote.component.html',
  styleUrls: ['./c-debitnote.component.css']
})
export class CDebitnoteComponent implements OnInit {
  _IsSaveHidden: boolean = false;
  _IsClearHidden: boolean = false;
  _Debitnoteform: FormGroup;
  _debitnoteevidenceform: FormGroup;
  _Debitnotedetailform: FormGroup;
  _Debitnoteid: any;
  _Debitnote: Debitnote;
  _Debitnotedetail: Debitnotedetail;
  _DebitnoteOBJ: any;
  // For DropDown
  _Currencies: Currency[];
  _CurrenciesTemp: Currency[];
  _accounts: Accountledger;
  _EvidenceTypes: Metadatum[];
  _EvidenceNumbers: Metadatum;
  _Partytypes: Metadatum[];
  _status: Metadatum;
  // For Grid
  _TransactionDetails: Debitnotedetail[] = [];
  _TransactionDetail: Debitnotegettransactiondetail;
  selectedrows: Debitnotegettransactiondetail[] = [];
  // For Poppup
  _Evidences: Debitnote;
  _Evidence: Debitnote;
  selectedEvidenceNo: any;
  _userid = sessionStorage["userid"];
  _action: string = "create";
  _submitted = false;
  displayBasic: boolean;
  _IsProgressSpinner: boolean = true;
  mindate: Date;
	maxdate: Date;
  _Voucherno: any;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _DebitnoteService: DebitnoteService,
    private utility: CommonUtilities,
    private _router: Router,
    private _hotkeysService: HotkeysService) {

  }


  InitializeForm() {
    this._Debitnoteform = this.fb.group({
      debitnoteid: [0],
      debitnoteno: [],
      debitnotedate: ['', vSelectCreditnoteDate],
      partytypecode: [''],
      accountid: [0, vSelectPartyType],
      evidencetypecode: [],
      evidenceno: ['', vSelectPartyType],
      currencyuniquerefid: [1],
      currentcurrencyid: ['', vSelectPartyType],
      exchangerate: [0],
      //amountinusd: [0],
      accountingyear: [],
      debitnotestatusid: [1],
      debitnotestatusdesc: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      voucherno: [],
      branchid: [sessionStorage["BranchID"]],
      invoicedate: [],
      invoiceamount: [],
      duedate: [],
      supplierid: [],
      suppliername: [],
      grnno: [],
      transactionincompleted: [1],
    });
    if (sessionStorage['Environmentenddate'] == "null" ) {
			this._Debitnoteform.get("debitnotedate").setValue(new Date);
		  }
		  else {
			let date = new Date(sessionStorage['Environmentenddate'])
			this._Debitnoteform.get("debitnotedate").setValue(date);
		  }
    this._debitnoteevidenceform = this.fb.group({
      grnno: [],
      invoicedate: [],
      invoiceamount: [],
      suppliername: [],
      duedate: [],
    });
    this._Debitnotedetailform = this.fb.group({
      debitnotedetailid: [0],
      debitnoteid: [0],
      invoiceno: [],
      invoicedate: [],
      productid: [],
      quantity: [],
      returnquantity: [],
      unitprice: [],
      transactionamount: [],
      debitamount: [],
      reason: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      debitnote: [],

    });
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();

    this._Debitnoteid = history.state?.debitnoteno ? history.state?.debitnoteno : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._Voucherno = history.state?.voucherno ? history.state?.voucherno : 0;
    this.HotKeyIntegration();
    const data = sessionStorage.getItem('LedgerData');
		if (data) {
			const parsedData = JSON.parse(data);
      this._Debitnoteid = parsedData.Debitnoteid;
			this._userid = parsedData.userid;
			this._Voucherno = parsedData.voucherno;
			this._action = parsedData.action;
      sessionStorage.removeItem('LedgerData');
		  } 
    //console.log("Debit Note on Create", this._Debitnoteid);
    this._IsProgressSpinner = true;
    this._DebitnoteService.PageOnLoad(this._Debitnoteid,this._userid, this._Voucherno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // For DropDown
      this._Currencies = resultJSON.currencies;
      this._status = resultJSON.recordStatus;
      this._Partytypes = resultJSON.partyTypes;
      this._accounts = resultJSON.accountledgers;
      this._EvidenceTypes = resultJSON.evidenceTypes;
      // this._EvidenceNumbers = resultJSON.debitnotegetpurchaseinvoicenos;
      // For Exchange Rate
      this._CurrenciesTemp = resultJSON.currencies || [];
      // For Grid
      this._TransactionDetails = resultJSON.transactiondetails;
      this._IsProgressSpinner = false;
      //console.log(resultJSON);
      if (this._Partytypes.length)
        this._Debitnoteform.get("partytypecode").setValue(this._Partytypes[0].metasubcode);
      if (this._EvidenceTypes.length)
        this._Debitnoteform.get("evidencetypecode").setValue(this._EvidenceTypes[0].metasubcode);
      //For Update
      //console.log(this._action);
      if (this._action == 'view') {
        this._Debitnoteform.disable();
        const updateJSON = JSON.parse(JSON.stringify(result));
        updateJSON.debitnoteheaderviews[0].debitnotedate = new Date(updateJSON.debitnoteheaderviews[0].debitnotedate);
        this._Debitnoteform.setValue(updateJSON.debitnoteheaderviews[0]);
        this._TransactionDetails = updateJSON.debitnotedetailsviews;
        this._debitnoteevidenceform.controls['invoiceamount'].setValue(updateJSON.debitnoteheaderviews[0].invoiceamount);
        this._debitnoteevidenceform.controls['invoicedate'].setValue(updateJSON.debitnoteheaderviews[0].invoicedate);
        this._debitnoteevidenceform.controls['grnno'].setValue(updateJSON.debitnoteheaderviews[0].grnno);
        this._debitnoteevidenceform.controls['suppliername'].setValue(updateJSON.debitnoteheaderviews[0].suppliername);
        this._Debitnoteform.get("partytypecode").setValue(this._Partytypes[0].metasubcode);
        this.Visiblity(true);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ChangeCurrencies(event): void {
    var exchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].exchangerate;
    this._Debitnoteform.controls['exchangerate'].setValue(exchangerate);
  }
  getTransactionDetails(event) {
    var selectedproduct = event.value;
    this._IsProgressSpinner = true;
    this._DebitnoteService.getTransactionDetails(selectedproduct).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //console.log("Transaction Detail");
      //console.log(resultJSON.purchaseTransactionDetails);
      this._TransactionDetails = resultJSON.purchaseTransactionDetails;
      this._IsProgressSpinner = false;
      // this.selectedrows = this._TransactionDetails;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSave() {
    this._submitted = true;
    let errMsg = '';
    let isDataImproper = this.selectedrows.some(s => s.debitamount <= 0)
    let isQuantityProper = this.selectedrows.some(s => s.returnquantity > s.quantity);
    if (isDataImproper) {
      errMsg = "Debit Amount must be greater than zero in all rows";
    }
    if (!this.selectedrows.length) {
      errMsg = "Please select Atleast one row in a grid.";
    }
    if (isQuantityProper) {
      errMsg = "Return Quantity must not greater than Invoice Quantity";
    }
    if (this.ShowErrorMsg(errMsg))
      return;
    // 28. if all the data is valid allow to save the record in the database
    var l_transactiondetails: Debitnotedetail[] = [];
    Object.keys(this.selectedrows).map((Index) => {
      var currentRow = this.selectedrows[Index];
      var l_Transactiondetails = new Debitnotedetail();
      l_Transactiondetails.debitnotedetailid = currentRow.debitnotedetailid || 0;
      l_Transactiondetails.debitnoteid = this._Debitnoteform.get("debitnoteid").value;
      l_Transactiondetails.debitamount = currentRow.debitamount;
      l_Transactiondetails.transactionamount = currentRow.transactionamount;
      l_Transactiondetails.invoicedate = currentRow.purchaseinvoicedate;
      l_Transactiondetails.invoiceno = currentRow.purchaseinvoiceno;
      l_Transactiondetails.quantity = currentRow.quantity;
      l_Transactiondetails.returnquantity = currentRow.returnquantity;
      l_Transactiondetails.productid = currentRow.productid;
      l_Transactiondetails.unitprice = currentRow.unitprice;
      l_Transactiondetails.reason = currentRow.reason;
      l_transactiondetails.push(l_Transactiondetails);
    })
    if (this._Debitnoteform.valid) {
      this._Debitnotedetailform.get("debitnoteid").setValue(this._Debitnoteform.get("debitnoteid").value);
      this._Debitnote = this._Debitnoteform.value;
      this._Debitnotedetail = this._Debitnotedetailform.value;
      this._DebitnoteOBJ = { "Debitnote": this._Debitnote, "Debitnotedetail": l_transactiondetails };
      this.Visiblity(true);
      if (this._action == 'create') {
        this._Debitnote.branchid = sessionStorage["BranchID"];
        this._Debitnote.createdon = new Date();
        this._Debitnote.createdby = sessionStorage["userid"];
        this._Debitnotedetail.createdon = new Date();
        this._Debitnotedetail.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._DebitnoteService.create(this._DebitnoteOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
          this.Visiblity(false);
        }, error => { this._CustomExceptionService.handleError(error); this.Visiblity(false); this._IsProgressSpinner = false; });
      }
    }
  }
  onRowSelect(event) {
    event.data.enableQuantity = true;
    event.data.enableReason = true;
  }
  onRowUnselect(event) {
    event.data.enableQuantity = false;
    event.data.enableReason = false;
    event.data.returnquantity = 0;
    event.data.reason = '';
  }
  showBasicDialog() {
    this.displayBasic = true;
  }
  onRowSelectPopup(event: any) {
    var row = event.data;
    this._Debitnoteform.controls['evidenceno'].setValue(row.purchaseinvoiceno);
    this._debitnoteevidenceform.controls['invoicedate'].setValue(row.purchaseinvoicedate);
    this._debitnoteevidenceform.controls['grnno'].setValue(row.grnno);
    this._debitnoteevidenceform.controls['suppliername'].setValue(row.suppliername);
    this._debitnoteevidenceform.controls['invoiceamount'].setValue(row.invoiceamount);
    this._Debitnoteform.controls['currentcurrencyid'].setValue(parseInt(row.currencyid));
    var exchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == row.currencyid)[0].exchangerate;
    this._Debitnoteform.controls['exchangerate'].setValue(exchangerate);
    this._TransactionDetails = [];
    this._IsProgressSpinner = true;
    this._DebitnoteService.getTransactionDetails(row.purchaseinvoiceno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._TransactionDetails = resultJSON.debitnotegettransactiondetails;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    this.displayBasic = false;
  }
  onRowUnSelectPopup(event: any) {
    this._Debitnoteform.controls['evidenceno'].setValue(null);
    this._debitnoteevidenceform.controls['invoicedate'].setValue(null);
    this._debitnoteevidenceform.controls['grnno'].setValue(null);
    this._debitnoteevidenceform.controls['suppliername'].setValue(null);
    this._debitnoteevidenceform.controls['invoiceamount'].setValue(null);
    this._Debitnoteform.controls['currentcurrencyid'].setValue(1);
    this._Debitnoteform.controls['exchangerate'].setValue(1);
    this._TransactionDetails = [];
    this.displayBasic = false;
  }
  reset(event) {
    this._submitted = false;
    this._Debitnoteform.reset();
    this.selectedEvidenceNo = null;
    this._Debitnotedetailform.reset();
    this.InitializeForm();
    this._TransactionDetails = [];
    this.selectedrows = [];
    if (this._Partytypes.length)
      this._Debitnoteform.get("partytypecode").setValue(this._Partytypes[0].metasubcode);
    if (this._EvidenceTypes.length)
      this._Debitnoteform.get("evidencetypecode").setValue(this._EvidenceTypes[0].metasubcode);
  }
  goBack(event) {
    //console.log("goBack");
    this._router.navigate(['/VDebitnote']);
  }
  onEd() {
    alert("test");
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._DebitnoteOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  calcuateDebitAmount(Invoice: Debitnotegettransactiondetail) {

    Invoice.debitamount = 0;
    let errMsg = '';
    if (Invoice.returnquantity > Invoice.quantity) {
      Invoice.returnquantity = 0;
      errMsg = "Return Quantity must not greater than Invoice Quantity";
    }

    if (this.ShowErrorMsg(errMsg))
      return;

    var _debitAmount = Invoice.unitprice * Invoice.returnquantity;
    Invoice.debitamount = _debitAmount + (_debitAmount * (Invoice.taxpercentage / 100)) - (_debitAmount * (Invoice.discountpercentage / 100)) //+ (_debitAmount * Invoice.othercharges)
  }

  GetCustomerBasedEvidenceNo($event) {
    let userid = sessionStorage["userid"];
    this._IsProgressSpinner = true;
    this._DebitnoteService.GetCustomerBasedEvidenceNo($event.value, userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Evidences = resultJSON.debitnotegetpurchasedetails;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  Visiblity(ishide: boolean) {
    this._IsSaveHidden = ishide;
    this._IsClearHidden = ishide;
  }

  ShowErrorMsg(errMsg) {
    if (!errMsg)
      return false;

    this.messageService.add({ severity: 'error', summary: 'Error Message', sticky: true, detail: errMsg });
    return true;
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}
