export class Receiptdetailview {
    voucherno :string;
    receiptid: number;
    receiptdetailid: number;
    billno: string;
    billdate: Date;
    vouchertypeid: number;
    vouchertypedesc: string;
    debitamount: number;
    creditamount: number;
    paidamount: number;
    pendingamount: number;
    tempPendingamount: number;
    partycode: number;
    series: number;
    type: number;
    currencyuniquerefid : number;
    isselected : boolean;
}