import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Pettycashsettlement } from '../../Models/Pettycashsettlement';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class PettycashexpenceService {
  public _Action: IAction;
  public _PettycashExpenceService = environment.PettycashExpenceService;
  public _httpOptions;
  public _PettycashList: Pettycashsettlement[] = [];
  token: any;
  header: any;
  createHeaders(token: any, type: number): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 1)
   }
  PageOnLoad(pettycashexpenseid: number, branchid: number,userid:number): Observable<any> {
    const headers = this.createHeaders(this.token, 2);
    var Query = this._PettycashExpenceService + '/' + pettycashexpenseid + '/' + branchid + '/' + userid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  create(p_PettycashExpenseForm: FormData) {
    const headers = this.createHeaders(this.token, 2);
    var Query = this._PettycashExpenceService;
    return this.http.post<{ data: any }>(Query, p_PettycashExpenseForm, { headers });
  }
  update(p_PettycashExpenseForm: FormData) {
    const headers = this.createHeaders(this.token, 2);
    var Query = this._PettycashExpenceService;
    return this.http.put<{ data: any }>(Query, p_PettycashExpenseForm, { headers });
  }
  FetchAll(branchid:number,fromdate:string,todate:string) {
    const headers = this.createHeaders(this.token, 2);
    var Query = this._PettycashExpenceService + '/FetchAll' + '/' + branchid + '/'+fromdate +'/'+todate;
   
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }
}
