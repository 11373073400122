<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-6">
                        <h3>Sales Return Request</h3>
                    </div>
                    <div class="col-md-6 text-right">
                        <div class="action-btn">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <div class="p-formgroup-inline">
                                <div class="p-field-checkbox">
                                    <p-checkbox [(ngModel)]="ShowExistingData" (onChange)="PageOnload()"
                                        label="Show Existing Data" binary="true"></p-checkbox>
                                </div>
                            </div>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    [disabled]="!ShowExistingData" dateFormat="dd/mm/yy" [monthNavigator]="true"
                                    yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [disabled]="!ShowExistingData" [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" title="Search" (click)="PageOnload()"
                                [disabled]="!ShowExistingData" icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="las la-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="las la-file-csv" title="Export CSV"
                                class="p-mr-2" pTooltip="CSV" (click)="exportExcel()" *ngIf="ShowExistingData"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" (click)="exportExcel()"
                                *ngIf="ShowExistingData" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" (click)="ExportToPdf()"
                                *ngIf="ShowExistingData" tooltipPosition="bottom"></button>
                            <!-- <button type="button" pButton pRipple icon="las la-filter" title="Filter" class="p-button-info p-ml-auto"
                          (click)="exportUtility.exportCSV(dt)"      pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="orderstatusListGrid" [rows]="20" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [globalFilterFields]="['orderid','orderno','requestno','totalquantity','returnquantity','receivedquantity','returnbranchid','pickedby']"
                        [rowHover]="true" dataKey="orderid" [(selection)]="selectedOrderReturnRows"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <!-- <th style="text-align: center;width: 50px;" [hidden]="ShowExistingData">
                                    <p-tableHeaderCheckbox (click)="SelectAll($event)"></p-tableHeaderCheckbox>
                                </th> -->
                                <th pSortableColumn="requestno" style="text-align: center;width: 100px;">Action
                                    <p-sortIcon field="requestno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="requestno" style="width: 200px;">Request NO<p-sortIcon
                                        field="requestno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="orderno">Order NO<p-sortIcon field="orderno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="orderid" style="width: 150px;">Order ID <p-sortIcon
                                        field="orderid"></p-sortIcon>
                                </th>
                                <th pSortableColumn="orderid">Product Name<p-sortIcon field="orderid"></p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="requestno">Lot No<p-sortIcon field="requestno"></p-sortIcon>
                                </th> 
                                <th pSortableColumn="totalquantity">Actual Quantity <p-sortIcon field="totalquantity">
                                    </p-sortIcon>
                                </th>-->
                                <th pSortableColumn="returnquantity">Return Quantity <p-sortIcon field="returnquantity">
                                    </p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="receivedquantity">Received Quantity <p-sortIcon
                                        field="receivedquantity">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="returnbranchid">Branch Name <p-sortIcon field="returnbranchid">
                                    </p-sortIcon>
                                </th>
                                <th>Is Return By Customer?</th>
                                <th pSortableColumn="pickedby">Picked By<p-sortIcon field="pickedby"></p-sortIcon>
                                </th> -->

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_orderstatusListGrid>
                            <tr>
                                <!-- <td style="text-align: center;width: 50px;" [hidden]="ShowExistingData">
                                    <p-tableCheckbox [disabled]="isDisabledselectall" [value]="_orderstatusListGrid">
                                    </p-tableCheckbox>
                                </td> -->
                                <td style="text-align: center;width: 100px;">
                                    <button pButton pRipple icon="las la-eye" title="Edit" [hidden]="!ShowExistingData"
                                        class="p-buttonp-mr-" (click)="view(_orderstatusListGrid)">
                                    </button>
                                    <button pButton pRipple icon="las la-pen" title="View" [hidden]="ShowExistingData"
                                        class="p-button p-button-success p-mr-" (click)="edit(_orderstatusListGrid)">
                                    </button>
                                </td>
                                <td>
                                    {{_orderstatusListGrid.salesreturnno}}
                                </td>
                                <td>
                                    {{_orderstatusListGrid.orderno}}
                                </td>
                                <td>
                                    {{_orderstatusListGrid.orderid}}
                                </td>
                                <td>
                                    {{_orderstatusListGrid.productname}}
                                </td>
                                <!-- <td>
                                    {{_orderstatusListGrid.lotnumber}}
                                </td> 
                                <td>
                                    {{_orderstatusListGrid.totalquantity}}
                                </td>-->
                                <td>
                                    {{_orderstatusListGrid.returnquantity}}
                                </td>
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber [(ngModel)]="_orderstatusListGrid.receivedquantity"
                                                 locale="en-US" [useGrouping]="false"[disabled]="ShowExistingData"
                                                 min="0" maxlength="15"></p-inputNumber>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_orderstatusListGrid.receivedquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_orderstatusListGrid.returnbranchid}}
                                </td> -->
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_branches"
                                                [disabled]="_orderstatusListGrid.isDisabledSelfPickup"
                                                [virtualScroll]="true" itemSize="30" optionLabel="branchname"
                                                optionValue="branchid" [filter]="true" [showClear]="true"
                                                filterBy="branchname" [disabled]="ShowExistingData"
                                                [(ngModel)]="_orderstatusListGrid.returnbranchid">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                [options]="_branches" optionLabel="branchname" optionValue="branchid"
                                                [(ngModel)]="_orderstatusListGrid.returnbranchid">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                                <!-- <td style="text-align: center">
                                    <p-checkbox [(ngModel)]="_orderstatusListGrid.returnbycustomer"
                                        (onChange)="OnSelectReturnByCustomer(_orderstatusListGrid)"
                                        [disabled]="_orderstatusListGrid.isDisabledSelfPickup"
                                        [disabled]="ShowExistingData" binary="true">
                                    </p-checkbox>
                                </td> -->
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_Tellernames"
                                                [disabled]="_orderstatusListGrid.isDisabledPickedby"
                                                [virtualScroll]="true" itemSize="30" optionLabel="username"
                                                optionValue="userid" [filter]="true" [showClear]="true"
                                                filterBy="username" [disabled]="ShowExistingData"
                                                [(ngModel)]="_orderstatusListGrid.pickedby">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                [options]="_Tellernames" optionLabel="username" optionValue="userid"
                                                filterBy="username" [(ngModel)]="_orderstatusListGrid.pickedby">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>