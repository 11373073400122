export class Stockadjustmentdetail {
    stockadjustmentdetailid: number;
    stocktakeid: number;
    productid: number;
    variantid: number;
    closingstock: number;
    actualstock: number;
    damagedstock: number;
    severity: string;
    stockdifference: number;
    uomcode: string;
    unitprice: number;
    amount: number;
    remarks: string;
    accountingyear: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    brandid: number;
    brandname: string;
    stockversion: string;
    stockinhand: string;
    isseverity: boolean;
    isdamagedstock: boolean;
    lotnumber: number;
    grnqty: number;
    salesinvoiceqty: number;
    salesreturnqty: number;
    purchasereturnqty: number;
    damagedlotnumber: number;
    stockamount: number | null;
}