import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormControlDirective, FormControlName, FormGroup } from '@angular/forms';
import { Metadatum } from '../../../../Models/metadatum';
import { State } from '../../../../Models/State';
import { Country } from '../../../../Models/Country';
import { District } from 'src/app/core/Models/District';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { vCountryNameSelect, vDistrictCode, vDistrictName, vStateNameSelect, vStatusSelect } from 'src/app/core/Validators/validation';
//Debug
import * as ace from "ace-builds";
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CityService } from 'src/app/core/Services/masters/city.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-c-city',
  templateUrl: './c-city.component.html',
  styleUrls: ['./c-city.component.css']
})
export class CCityComponent implements OnInit {
  _IsProgressSpinner: boolean = false;

  _SaveHide: boolean = false;
  _ClearHide: boolean = false;

  _cityform: FormGroup;
  _countries: Country;
  _states: State[];
  _citystatus: Metadatum[];
  _City: District;
  _submitted = false;
  _action: string = "create";
  _citycode: string;
  _districtid: number;
  _Action: IAction;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _CityService: CityService, private _router: Router, private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService, private router: Router,
    private _AccessRightsService: AccessRightsService,
    ) {
      this._districtid = history.state?.districtid ? history.state?.districtid : 0;
      this._action = history.state.action == null ? 'create' : history.state.action;
      this.HotKeyIntegration();
  }

  InitializeForm() {
    this._cityform = this.fb.group({
      districtid: [0],
      districtcode: ['', vDistrictCode],
      districtname: ['', vDistrictName],
      districtstatus: ['',vStatusSelect],
      countryid: ['', vCountryNameSelect],
      stateid: ['', vStateNameSelect],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      country: [],
      state: []
    })
    this._cityform.controls['districtstatus'].setValue("MSC00001");
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(12);
    this.utility.pageLoadScript();

    this.InitializeForm();
   
    if (this._action == 'edit')
      this._ClearHide = true;

    if (this._action == 'view') {
      this._cityform.disable();
      this._SaveHide = true;
      this._ClearHide = true;
    }
    this._IsProgressSpinner = true;
    this._CityService.PageOnLoad(this._districtid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
     
      // 3. If country name dropdown  selected then state dropdown should load based on country code 
      // 2.Country name should load from country master(countrycode,countryname)
      // 24.If country name  selected then state should load based on country code
      this._countries = resultJSON.countries;
    
      this._states = resultJSON.states;
      this._citystatus = resultJSON.status || [];
      if (this._citystatus.length) {
        this._cityform.get("districtstatus").setValue(this._citystatus[0].metasubcode);
      }
     
      //For Update
      this._districtid = history.state.districtid;

      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._countries = updateJSON.countries;
      
        this._states = updateJSON.states;
        this._cityform.setValue(updateJSON.district);
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getStates(event) {
    var selectedCountry = event.value;
    this._IsProgressSpinner = true;
    this._CityService.getStates(selectedCountry).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._states = resultJSON.states;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onSave() {
    this._submitted = true;
    if (this._cityform.valid) {
      this._City = this._cityform.value;
      //Debug
      // this.onEd();
      this._IsProgressSpinner = true;
      if (this._action == 'create') {
        this._City.createdon = new Date();
        this._City.createdby = sessionStorage["userid"];
        this._CityService.create(this._City).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._IsProgressSpinner = false;
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => this.handleError(error));
      }
      else if (this._action == 'edit') {
        this._City.modifiedon = new Date();
        this._City.modifiedby = sessionStorage["userid"];
        this._CityService.edit(this._City).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._IsProgressSpinner = false;
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => this.handleError(error));
      }
    }
  }                                                     
  // 13. clear all the fields which the data has entered 
  reset(event) {
    this._submitted = true;
    this._cityform.reset();    
    this.InitializeForm();
    this._states=[];
    this._submitted = false;
  
  }
  // 33.on clicking back button , it Navigates to the search screen
  goBack(event) {
    this._router.navigate(['/vCity']);
  }
  //Debug
  // onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._City;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }

  handleError(error: any) {
    this._CustomExceptionService.handleError(error)
    this._IsProgressSpinner = false;
  }

 // Create
 HotKeyIntegration() {
  this._hotkeysService.reset();
  if (this._action != 'view') {
    this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
      this.onSave();
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  if (this._action == 'create') {
    this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
      this.reset(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
    this.goBack(null);
    return false; // Prevent bubbling
  }, ['INPUT', 'SELECT', 'TEXTAREA']));
}


}
