<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product Price Change</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true" [minDate]="mindate"
                                    [maxDate]="maxdate" dateFormat="dd/mm/yy" [monthNavigator]="true"
                                    yearRange="1980:2030" [disabled]="disablefromdate">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [minDate]="mindate" [maxDate]="maxdate" [monthNavigator]="true"
                                    yearRange="1980:2030" [disabled]="disabletodate">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">

                            <span class="p-float-label">
                                <p-multiSelect [filter]="true" [options]="_ProductTypes" optionLabel="producttypename"
                                    optionValue="producttypeid" [(ngModel)]="producttypeid" displaySelectedLabel=true
                                    (onChange)="GetProductCategory($event)">
                                </p-multiSelect>
                                <label for="producttypeid">Product Type</label>
                            </span>

                        </td>
                        <td style="width: 20%;">

                            <span class="p-float-label">
                                <p-multiSelect [filter]="true" [options]="_ProductCategories" optionLabel="categoryname"
                                    optionValue="productcategoryid" [(ngModel)]="productcategoryid"
                                    displaySelectedLabel=true (onChange)="GetProductName($event)">
                                </p-multiSelect>
                                <label for="productcategoryid">Product Category</label>
                            </span>

                        </td>
                        <td style="width: 20%;">

                            <span class="p-float-label">
                                <p-multiSelect [filter]="true" [options]="_ProductNames" optionLabel="productname"
                                    optionValue="productid" [(ngModel)]="productid" displaySelectedLabel=true
                                    (onChange)="ChangeProduct($event)">
                                </p-multiSelect>
                                <label for="productid">Product Name</label>
                            </span>

                        </td>
                    </tr>
                    <tr>
                        <td style="width: 20%;">

                            <span class="p-float-label">
                                <p-multiSelect [filter]="true" [options]="_brandlist" optionLabel="brandname"
                                    optionValue="brandid" [(ngModel)]="brandid" displaySelectedLabel=true>
                                </p-multiSelect>
                                <label for="productid">Brand Name </label>
                            </span>

                        </td>
                        <td style="width:20%">
                            <div class="p-formgroup-inline">
                                <div class="p-field-checkbox">
                                    <p-checkbox [(ngModel)]="onlynewproducts" [ngModelOptions]="{standalone: true}" label="Only New Products" binary="true" (onChange)="getnewproducts()">
                                    </p-checkbox>
                                </div>
                            </div>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                    <tr>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="FetchAll()" title="Search" icon="pi pi-search"></button>
                            <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                        </td>
                    </tr>
                </table>
                <p-toast></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_Pricechangeviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['productname','brandname','oldrate','newrate','currencyname',
                        'remarks','createdbyname']" [(selection)]="selectedrows" [rowHover]="true" dataKey="salesquotationno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width:100px;">Action
                                </th>
                                <!-- <th pSortableColumn="productratechangeidppc">Product Price Change Code<p-sortIcon
                                        field="productratechangeidppc">
                                    </p-sortIcon>
                                </th> -->
                                <th pSortableColumn="productname" style="width:300px;">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname" style="width:150px;">Brand
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="oldrate" style="width:100px;">Old Rate
                                    <p-sortIcon field="oldrate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="newrate" style="width:100px;">New Rate
                                    <p-sortIcon field="newrate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname" style="width:100px;">Currency
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="remarks" style="width:300px;">Status
                                    <p-sortIcon field="remarks">
                                    </p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="isnewproduct">Is New Product<p-sortIcon field="isnewproduct">
                                    </p-sortIcon>
                                </th> -->
                                <th pSortableColumn="modifiedon">Modified on
                                    <p-sortIcon field="modifiedon">
                                    </p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="newrate">Status<p-sortIcon field="newrate">
                                    </p-sortIcon> -->
                                <th pSortableColumn="changedbyname">Modified by
                                    <p-sortIcon field="changedbyname">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Pricechangeview>
                            <tr [ngClass]="{'qs-row': _Pricechangeview.remarks === 'New Product'}">
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_Pricechangeview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update || _Pricechangeview.pricestatus != 'MSC00045' " (click)="edit(_Pricechangeview)"></button>
                                    <button pButton pRipple icon="las la-print" title="Print" class="p-button-warning p-mr-2" (click)="Print(_Pricechangeview)" [disabled]="!_Action._Print"></button>
                                    <!-- <button pButton pRipple icon="las la-times" title="Cancel"
                                    class="p-button p-button-danger" 
                                    (click)="Cancel(_Pricechangeview)"></button> -->
                                </td>
                                <!-- <td>
                                    {{_Pricechangeview.productratechangeidppc}}
                                </td> -->
                                <td style="width:300px;">
                                    {{_Pricechangeview.productname}}
                                </td>
                                <td style="width:150px;">
                                    {{_Pricechangeview.brandname}}
                                </td>
                                <td style="width:100px;">
                                    {{_Pricechangeview.oldrate}}
                                </td>
                                <td style="width:100px;">
                                    {{_Pricechangeview.newrate}}
                                </td>
                                <td style="width:100px;">
                                    {{_Pricechangeview.currencyname}}
                                </td>
                                <td style="width:300px;">
                                    {{_Pricechangeview.remarks}}
                                </td>
                                <!-- <td>
                                    {{_Pricechangeview.isnewproduct}}
                                </td>                                -->
                                <td>
                                    {{_Pricechangeview.modifiedon |date:'dd-MMM-yyyy HH:mm'}}
                                </td>
                                <!-- <td>
                                    {{_Pricechangeview.pricestatusname}}
                                </td> -->
                                <td>
                                    {{_Pricechangeview.createdbyname}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>