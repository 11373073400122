import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { SalesquotationService } from 'src/app/core/Services/sales/salesquotation.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Salesquotationview } from 'src/app/core/Views/Salesquotationview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-sales-quotation',
  templateUrl: './v-sales-quotation.component.html',
  styleUrls: ['./v-sales-quotation.component.css']
})
export class VSalesQuotationComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _Print: boolean;
  _Salesquotationviews: Salesquotationview[];
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["currentbranchid"];
  _roleid = sessionStorage["currentRole"];
  _IsProgressSpinner: boolean = true;
  cols: any[];
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  mindate: Date;
  maxdate: Date;

  constructor(private fb: FormBuilder, public exportUtility: ExportUtility, private _SalesQuotationService: SalesquotationService, private _CustomExceptionService: CustomExceptionService, private _AccessRightsService: AccessRightsService, private router: Router, private utility: CommonUtilities, private _hotkeysService: HotkeysService, private confirmationService: ConfirmationService) {

  }

  ngOnInit(): void {
    //  58. check the user rights for the login user if the user has access to view, then allow to open view page
    this._Action = this._AccessRightsService.getAccessRights(53);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);

    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }

    this.cols = [
      { field: 'salesquotationno', header: 'Sales Quotation No' },
      { field: 'salesquotationdate', header: 'Quotation Date' },
      { field: 'customername', header: 'Customer Name' },
      { field: 'salesquotationamount', header: 'Quotation Value' },
      { field: 'currencyname', header: 'Currency' },
      { field: 'effectivefrom', header: 'Effective From' },
      { field: 'effectiveto', header: 'Effective To' },
      { field: 'approvalstatusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this.Fetchall();


  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._SalesQuotationService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Salesquotationviews = resultJSON.salesquotationviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  // 59.on clicking view button, it show the view screen.
  view(event) {
    this.router.navigate(['/cSalesQuotation'], { state: { salesquotationno: event.salesquotationno, action: 'view' } });
  }


  edit(event) {
    if (event.approvalstatus == "MSC00045" || event.approvalstatus == null) {
      this.router.navigate(['/cSalesQuotation'], { state: { salesquotationno: event.salesquotationno, action: 'edit' } });
    }
    else {
      this._CustomExceptionService.handleWarning("Approved Record Should not Update");
    }
  }
  exportExcel() {
    let SalesQuotationList = this.GetSalesQuotationList();
    this.exportUtility.exportExcel(SalesQuotationList, 'SalesQuotation', 'xlsx');
  }

  ExportToPdf() {
    let SalesQuotationList = this.GetSalesQuotationList();
    this.exportUtility.ExportToPdf(SalesQuotationList, 'SalesQuotation.pdf');
  }


  GetSalesQuotationList() {
    if (!this._Salesquotationviews || !this._Salesquotationviews.length)
      return;
    let SalesQuotationList = [];
    this._Salesquotationviews.forEach(element => {
      let salesquotation: any = {};
      salesquotation["Sales Quotation No"] = element.salesquotationno;
      salesquotation["Quotation Date"] = this.datepipe.transform(element.salesquotationdate, 'dd/MM/yyyy');
      salesquotation["Customer Name"] = element.customername;
      salesquotation["Quotation Value"] = element.salesquotationamount;
      salesquotation["Currency"] = element.currencyname;
      salesquotation["Effective From"] = this.datepipe.transform(element.effectivefrom, 'dd/MM/yyyy');
      salesquotation["Effective To"] = this.datepipe.transform(element.effectiveto, 'dd/MM/yyyy');
      salesquotation["Status"] = element.approvalstatusname;
      salesquotation["Created By"] = element.createdbyname;
      salesquotation["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      SalesQuotationList.push(salesquotation);
    });
    return SalesQuotationList;
  }
  GoToCreate(event) {
    this.router.navigate(['/cSalesQuotation']);
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Sales Quotation <b>"' + event.salesquotationno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  //Cancel
  Cancel(event) {
    var salesquotationno = event.salesquotationno;
    this._IsProgressSpinner = true;
    this._SalesQuotationService.Cancel(salesquotationno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.SalesQuotationCancel);
      this._IsProgressSpinner = false;
      this.Fetchall();
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });


  }
  Print(event) {
    var salesquotationno = event.salesquotationno;
    this._IsProgressSpinner = true;
    //var mediaType = 'application/pdf';
    window.open("quotation-printer-service:$" + event.salesquotationid + "$" + event.salesquotationno + "$" + sessionStorage["userid"], "_blank");
    //this._SalesQuotationService.Print(salesquotationno);
    this._IsProgressSpinner = false;
  }

  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  // DetailPrint(event) {
  //   var salesquotationno = event.salesquotationno;
  //   this._IsProgressSpinner = true;
  //   //var mediaType = 'application/pdf';
  //   this._SalesQuotationService.Print(salesquotationno,this._branchid);
  //   this._IsProgressSpinner = false;
  // }
  DetailPrint(event) {
    var salesquotationno = event.salesquotationno;
    this._IsProgressSpinner = true;
    this._SalesQuotationService.Print({ "sqno": salesquotationno, "branchid": this._branchid }).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  SendMail(event) {
    // var workflowid = 5;
    var quoteno = event.purchaseorderno;
    this._userid = sessionStorage["userid"];
    this._branchid = sessionStorage["BranchID"];
    //76.after clicking the send to approve button, the purchase order is given to 4 levels of approval 
    //this._productid= this._damagedproductpricedetailsform.get("productid").setValue(this._damagedproductpricedetailsform.get("productid").value);
    this._IsProgressSpinner = true;

    this._SalesQuotationService.SendMail(this._userid, this._branchid, quoteno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && resultJSON) {
        this._CustomExceptionService.handleSuccess(usererrors.MailSent_Success);
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);

      }
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


}


