import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RolewisescreenmappingService {
  public _Action: IAction;
  public _RoleWiseScreenMappingService = environment.RoleWiseScreenMappingService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(): Observable<any> {
    const headers = this.header;
    var Query = this._RoleWiseScreenMappingService + '/PageOnload';

    return this.http.get<{ data: any }>(Query, { headers });
    //return this.http.get(Query);

  }
  FetchAll() {
    const headers = this.header;
    var Query = this._RoleWiseScreenMappingService + '/FetchAll';

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      // let _CityScreenAccess = resultJSON.userData.lstFunctions.find(funct=>funct.functionid=="15");
      // this._Action  = { 
      //   _functionId : 15,
      //   _functionName : "City",
      //   _Add :   _CityScreenAccess.iscreateallowed,
      //   _Delete : _CityScreenAccess.isdeleteallowed,
      //   _Update : _CityScreenAccess.iseditallowed,
      //   _View : _CityScreenAccess.isviewallowed,
      //   _Approve : _CityScreenAccess.isviewallowed
      // }   
      return data;
    }),);
  }
  create(p_RoleWiseScreenMapping: object) {
    const headers = this.header;
    console.log("Create");
    var Query = this._RoleWiseScreenMappingService;

    return this.http.post<{ data: any }>(Query, p_RoleWiseScreenMapping, { headers });
  }
  view(p_RoleWiseScreenMappingCode: string) {
    const headers = this.header;
    var Query = this._RoleWiseScreenMappingService + '/' + p_RoleWiseScreenMappingCode;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  edit(p_RoleWiseScreenMapping: object) {
    const headers = this.header;
    console.log("Update");
    var Query = this._RoleWiseScreenMappingService;

    return this.http.put<{ data: any }>(Query, p_RoleWiseScreenMapping, { headers });
  }

  getFunctions(p_moduleIds: number[], roleid: number) {
    const headers = this.header;
    // let params = new HttpParams();
    //  params = params.append('Ids', decodeURIComponent(p_moduleIds.join('&Ids=')));
    // console.log("getFunctions", p_moduleIds);
    let paramvalue = "Ids="
    paramvalue = paramvalue + p_moduleIds.join('&Ids=')
    console.log(paramvalue);
    var Query = this._RoleWiseScreenMappingService + '/GetFunctions/' + roleid + '?' + paramvalue;

    return this.http.get<{ data: any }>(Query, { headers });
  }


  GetDefualtModules(p_roleid: number) {
    const headers = this.header;
    //let paramvalue= "Ids=" + p_moduleIds
    var Query = this._RoleWiseScreenMappingService + '/GetDefualtModules/' + p_roleid;

    return this.http.get<{ data: any }>(Query, { headers });
  }


}
