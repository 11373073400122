<p-toast></p-toast>
<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Payment Status</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" (click)="onSave()" [disabled]="isDisabledsave" title="Save"
                                icon="las la-save"></button>
                                <button pButton pRipple title="Clear" type="button" icon="pi pi-trash" [disabled]="isDisabledsave"
                                class="p-button-danger" (click)="reset()"
                                ></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width:20%">
                            <span class="p-float-label">
                                <p-dropdown [options]="_tellernames" (onChange)="GetOrderDetails()" [autoDisplayFirst]="false"  [filter]="true"
                                [virtualScroll]="true" itemSize="30"  filterBy="username" [(ngModel)]="Tellerid" optionValue="userid"
                                    optionLabel="username"></p-dropdown>
                                <label for="dropdown">Teller Names</label>
                            </span>
                        </td>
                        <td style="width:20%">
                           
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox [(ngModel)]="IsAmountCollected" (onChange)="GetOrderDetails()" label="Is Amount Collected?"
                                         binary="true"></p-checkbox>
                                    </div>
                                </div>
                          </td>
                        <td style="width:20%">
                            <div class="p-formgroup-inline">
                                <h6>Mode Of Payment <span class="hlt-txt">*</span></h6>
                                <div class="p-field-radiobutton" *ngFor="let PM of _PaymentModes">
                                    <p-radioButton name="paymentmode" label={{PM.metasubdescription}}
                                        value={{PM.metasubcode}} [(ngModel)]="PaymentMode"
                                        (onClick)="GetOrderDetails()">
                                    </p-radioButton>
                                </div>
                            </div></td>
                        <td style="width:20%"></td>
                        <td style="width:20%"></td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="las la-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                        <ng-template pTemplate="right">
                            <!-- <button type="button" pButton pRipple icon="las la-file-csv" title="Export CSV"
                                class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-filter" title="Filter"
                                class="p-button-info p-ml-auto" pTooltip="Selection Only"
                                tooltipPosition="bottom"></button> -->
                        </ng-template>
                    </p-toolbar>

                    <p-table #dt [rows]="20" [value]="CODHistoryGrid" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['orderid','orderno','orderNo','salesinvoiceno','orderdate','finalamount','isamountcollected']"
                        [rowHover]="true" dataKey="orderid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [(selection)]="selectedCODHistoriesRows"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <!-- <th style="text-align: center;width: 50px;">
                                    <p-tableHeaderCheckbox 
                                        (click)="SelectAll($event)"></p-tableHeaderCheckbox>
                                </th> -->
                                <th>Order ID</th>
                                <th>Order No</th>
                                <th>Invoice No</th>
                                <th pSortableColumn="orderDate">Order Date & Time <p-sortIcon field="orderDate">
                                    </p-sortIcon>
                                </th>
                                <th class="text-right">Amount (in USD)</th>
                                <th>Is Amount Collected</th>
                                <th>Attachment</th>
                                <!-- <th>Attachment Name</th> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_CODHistoryGrid>
                            <tr>
                                <!-- <td style="text-align: center;width: 50px;">
                                    <p-tableCheckbox  [value]="_CODHistoryGrid">
                                    </p-tableCheckbox>
                                </td> -->
                                <td>
                                    {{_CODHistoryGrid.orderid}}
                                </td>
                                <td>
                                    {{_CODHistoryGrid.orderno}}
                                </td>
                                <td>
                                    {{_CODHistoryGrid.salesinvoiceno}}
                                </td>
                                <td>
                                    {{_CODHistoryGrid.orderdate|date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_CODHistoryGrid.finalamount| number: '1.2'}}
                                </td>
                               
                                <td style="text-align: center;">
                                        <p-tableCheckbox  [value]="_CODHistoryGrid" [disabled]="DisabledPayment">
                                        </p-tableCheckbox>
                                </td>
                                <td>
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <div class="browse-links">
                                                <p-button styleClass="p-button-link" label="{{_CODHistoryGrid.attachmentname}}" title="Document"
                                                    (click)="showdocumentDialog(document,'bottom-right',_CODHistoryGrid)"
                                                    tooltipPosition="top">
                                                </p-button>
                                            </div>
                                        </span>

                                        <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                            pTooltip="Browse File" tooltipPosition="top" mode="basic" #document
                                            (onSelect)="onDocumentSelect($event,document,_CODHistoryGrid)"
                                            accept="image/*,.pdf,.xlsx,.xls,.xlsm,.docx,.txt"
                                            [disabled]="_action=='view'" maxFileSize="1000000" [auto]="true">
                                        </p-fileUpload>
                                        <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                            (click)="onDocumentClear(_CODHistoryGrid)" tooltipPosition="top"
                                            [disabled]="_action=='view'">
                                            <i class="pi pi-times"></i>
                                        </button>
                                    </div>

                                </td>
                                <!-- <td> <a href="javascript:void(0);"
                                        (click)="showdocumentGridDialog('bottom-right',_CODHistoryGrid)">
                                        {{_CODHistoryGrid.attachmentname}}
                                    </a></td> -->
                               
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>