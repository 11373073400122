import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Country } from 'src/app/core/Models/Country';
import { State } from 'src/app/core/Models/State';
import { StateService } from 'src/app/core/Services/masters/state.service';
import { vCountryNameSelect, vStateCode, vStateName, vStatusSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { MessageService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
  selector: 'app-c-state',
  templateUrl: './c-state.component.html',
  styleUrls: ['./c-state.component.css']
})
export class CStateComponent implements OnInit {
  _stateform: FormGroup;
  _countries: Country;  //2.Country name dropdown should load from country master
  _statestatus: Metadatum; //16.status dropdown load from metadata table 
  _submitted = false;
  _State: State;
  _action: string = "create";
  _stateid: number;
  // hideInCreatePage: Boolean;
  isDisabledSave = false;
  isDisabledClear = false;
  _IsProgressSpinner: boolean = true;
  _Action:IAction


  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _StateService: StateService, 
    private _AccessRightsService : AccessRightsService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,private _hotkeysService: HotkeysService) { 
    this._stateid = history.state?.stateid ? history.state?.stateid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
      this.HotKeyIntegration();
    }
    InitializeForm() {
      this._stateform = this.fb.group({
        countryid: ['', vCountryNameSelect],
        stateid: [0],
        statecode: ['', vStateCode],
        statename: ['', vStateName],
        statestatus: ['',vStatusSelect],
        createdby: [],
        createdon: [],
        modifiedby: [],
        modifiedon: [],
        country: [],
        districts:[]
      });
      this._stateform.controls['statestatus'].setValue("MSC00001");
    }
   
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(11);
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;
    this._StateService.PageOnLoad(this._stateid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._countries = resultJSON.countries;
      this._statestatus = resultJSON.stateStatus;
      this._stateform.controls['statestatus'].setValue("MSC00001");
      this._IsProgressSpinner = false;
    if( this._action == 'view' ){
      this._stateform.disable();
      this.isDisabledSave = true;
      this.isDisabledClear = true;
    }
    if (this._action == 'edit') {
      this.isDisabledSave = false;
      this.isDisabledClear = true;
    }
      if (this._action == 'edit' || this._action == 'view') {
        // this.hideInCreatePage=false; 
          const updateJSON = JSON.parse(JSON.stringify(result));
          this._stateform.setValue(updateJSON.countryState);
      }
    },  
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  // 8.if all the data is valid allow to save the record in the database 
  onSave() {
    this._submitted = true;
    if (this._stateform.valid) {
      this._State = this._stateform.value;
      // this.onEd();
      if (this._action == 'create') {
        this._State.createdon = new Date();
        this._State.createdby= sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._StateService.create(this._State).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true &&  (resutJSON!=null || resutJSON!=undefined)) {
            this._IsProgressSpinner = false;
          //  10. after saving the record, get the response A, display the success message as "State Name A  registered Succesfully "
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
          //  6. search page has grid which contains (action,country name,statecode,statename,status)
          //  7. check in the state table whether state name is already given, show error as "state name A already Exists"
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
        error =>  { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
       
     }
    
      else if (this._action == 'edit') { //26.after Updating the record, get the response A, display the success message as "State  Name A  Modified Succesfully "
        this._State.modifiedon = new Date();
        this._State.modifiedby= sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._StateService.edit(this._State).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true &&  (resutJSON!=null || resutJSON!=undefined)) {
            this._IsProgressSpinner = false;
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
        error =>  { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
       
     }
    }
  }
  reset(event) {
       this._submitted = true;
       this._stateform.reset();
       this.InitializeForm();
       this._submitted = false;
    
  }
  goBack(event) {
    this._router.navigate(['/vState']);
  }
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._State;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);

  }
   // Create
   HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

}