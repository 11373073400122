import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../security/IActions';



@Injectable({
  providedIn: 'root'
})
export class RptpurchasepricerevisionService {
  public _Action: IAction;
  public _httpOptions;
  public _RptPurchasePriceRevisionService = environment.RptPurchasePriceRevisionService;

  constructor(private http: HttpClient) { }

// Page Load :
PageOnload(userid:number): Observable<any> {
  var Query = this._RptPurchasePriceRevisionService + '/PageInitialize'+'/'+userid;
  
  return this.http.get<{ data: any }>(Query);
}

getProductCategory(producttype:object) {
  var Query = this._RptPurchasePriceRevisionService+'/getProductCategories';
  
  return this.http.post<{ data: any }>(Query, producttype);
}
getProduct(productcategory:object) {
  var Query = this._RptPurchasePriceRevisionService + '/getProducts';
  
  return this.http.post<{ data: any }>(Query, productcategory);
}

Print(fromdate :string,todate :string,productid:number,supplierid:number,currencyid:number,branchid:number) {
  var Query = this._RptPurchasePriceRevisionService+'/Print/'+fromdate+'/'+ todate+'/'+productid+'/'+supplierid+'/'+currencyid +'/'+branchid;
  //
  //window.open(Query,'_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
  //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
  return this.http.get(Query,{responseType: 'blob'});
}

}
