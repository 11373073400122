import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { EmployeeService } from 'src/app/core/Services/masters/employee.service';
import { Employeeview } from 'src/app/core/Views/Employeeview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import jsPDF from 'jspdf'
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
const doc = new jsPDF()
@Component({
  selector: 'app-v-employee',
  templateUrl: './v-employee.component.html',
  styleUrls: ['./v-employee.component.css']
})
export class VEmployeeComponent implements OnInit {
  _IsProgressSpinner: boolean = false;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _employeesview: Employeeview[];
  _employeeview: Employeeview;
  datepipe = new DatePipe('en-US');
  _Action: IAction
  cols: any[];
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _EmployeeService: EmployeeService,
    private router: Router,
    private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,
    private _hotkeysService: HotkeysService,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(18);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this.FetchAll();
    this.cols = [
      { field: 'employeecode', header: 'Employee Code' },
      { field: 'employeename', header: 'Employee Name' },
      { field: 'employeerolename', header: 'Employee Role Name' },
      { field: 'reportingbranchname', header: 'Reporting Branch Name' },
      { field: 'departmentname', header: 'Department Name' },
      { field: 'primarymobileno', header: 'Primary Mobile No' },
      { field: 'emailid', header: 'Email ID' },
      { field: 'employeestatusname', header: 'Employee Status Name' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
      { field: 'modifiedbyname', header: 'Modified By' },
      { field: 'modifiedon', header: 'Modified On' },
    ]
  }
  FetchAll() {
    this._IsProgressSpinner = true;
    this._EmployeeService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._employeesview = resultJSON.employeeviews;
      this._IsProgressSpinner = false;
      return;
    }, error => this.handleError(error));
  }
  onRowSelect(event) { }
  onRowUnselect(event) { }
  view(event) {
    this.router.navigate(['/cEmployee'], { state: { employeeid: event.employeeid, action: 'view' } });
  }
  ChangeStatus(view) {
    let userid = sessionStorage["userid"];
    this._IsProgressSpinner = true;
    this._EmployeeService.ChangeStatus(view.employeeid, userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON && resultJSON.tranStatus.result) {
        this._CustomExceptionService.handleSuccess(usererrors.EmployeeDeactive);
        this.FetchAll();
      }
      this._IsProgressSpinner = false;
    }, error => this.handleError(error));
  }
  edit(event) {
    this.router.navigate(['/cEmployee'], { state: { employeeid: event.employeeid, action: 'edit' } });
  }
  confirm(view) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to inactive this Employee <b>"' + view.employeename + '"</b> ?',
      accept: () => {
        this.ChangeStatus(view);
      }
    });
  }
  handleError(error: any) {
    this._CustomExceptionService.handleError(error);
    this._IsProgressSpinner = false;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  GoToCreate() {
    this.router.navigate(['/cEmployee']);
  }
  exportExcel() {
    if (!this._employeesview || !this._employeesview.length)
      return;
    let employeeList = this.GetEmployeeList();
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(employeeList);
      const workbook = { Sheets: { 'Employee': worksheet }, SheetNames: ['Employee'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Employees");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  ExportToPdf() {
    if (!this._employeesview || !this._employeesview.length)
      return;
    let employeeList = this.GetEmployeeList();
    const data = employeeList.map(Object.values);
    const head = [[
      'Emp Code',
      'Emp Name',
      'Emp Role',
      'Rep Branch',
      'Dep',
      'Pri Mob No',
      'Email ID',
      'Created On',
      'Modified By',
      'Modified On'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Employees.pdf');
      })
    })
  }
  GetEmployeeList() {
    let employeeList = [];
    this._employeesview.forEach(element => {
      let employee: any = {};
      employee["Emp Code"] = element.employeecode;
      employee["Emp Name"] = element.employeename;
      employee["Emp Role"] = element.employeerolename;
      employee["Rep Branch"] = element.reportingbranchname || 'N/A';
      employee["Dep"] = element.departmentname || 'N/A';
      employee["Pri Mob No"] = element.primarymobileno;
      employee["Email ID"] = element.emailid;
      employee["Status"] = element.employeestatusname;
      employee["Created By"] = element.createdbyname;
      employee["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM.yyyy');
      employee["Modified By"] = element.modifiedbyname;
      employee["Modified On"] = this.datepipe.transform(element.modifiedon, 'dd/MM/yyyy');
      employeeList.push(employee);
    });
    return employeeList;
  }


}
