export class Salesquotationterm {
    salesquotationtermid: number;
    salesquotationid: number;
    termtypeid: string;
    termsandconditionid: number;
    termdescription: string;
    accountingyear: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}