import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
//bharat
import { MastersRoutingModule } from './masters-routing.module';

import { CStateComponent } from '../masters/State/c-state/c-state.component';
import { VStateComponent } from '../masters/State/v-state/v-state.component';
import { CLevelComponent } from './Level/c-level/c-level.component';
import { VLevelComponent } from './Level/v-level/v-level.component';
import { CNumberingSchemaComponent } from './NumberingSchema/c-numbering-schema/c-numbering-schema.component';
import { VNumberingSchemaComponent } from './NumberingSchema/v-numbering-schema/v-numbering-schema.component';
import { CEmployeeComponent } from './Employee/c-employee/c-employee.component';
import { VEmployeeComponent } from './Employee/v-employee/v-employee.component';
import { CCustomerComponent } from './Customer/c-customer/c-customer.component';
import { CAccountTypeComponent } from '../masters/account-type/c-account-type/c-account-type.component';
import { VAccountTypeComponent } from '../masters/account-type/v-account-type/v-account-type.component';

import { CCityComponent } from '../masters/city/c-city/c-city.component';
import { VCityComponent } from '../masters/city/v-city/v-city.component';
import { CCurrencyComponent } from './currency/c-currency/c-currency.component';
import { VCurrencyComponent } from './currency/v-currency/v-currency.component';
import { CBankComponent } from '../masters/bank/c-bank/c-bank.component';
import { VBankComponent } from '../masters/bank/v-bank/v-bank.component';
import { CProductTypeComponent } from './product-type/c-product-type/c-product-type.component';
import { VProductTypeComponent } from './product-type/v-product-type/v-product-type.component';
import { CPortComponent } from './port/c-port/c-port.component';
import { VPortComponent } from './port/v-port/v-port.component';
import { VProductComponent } from './product/v-product/v-product.component';
import { CCountryComponent } from '../masters/country/c-country/c-country.component';
import { VCountryComponent } from '../masters/country/v-country/v-country.component';
import { CTaxComponent } from './tax/c-tax/c-tax.component';
import { VTaxComponent } from './tax/v-tax/v-tax.component';
import { CBranchComponent } from './branch/c-branch/c-branch.component';
import { VBranchComponent } from './branch/v-branch/v-branch.component';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { CProductCategoryComponent } from './product-category/c-product-category/c-product-category.component';
import { VProductCategoryComponent } from './product-category/v-product-category/v-product-category.component';
import { CTermsAndConditionComponent } from './terms-and-condition/c-terms-and-condition/c-terms-and-condition.component';
import { VTermsAndConditionComponent } from './terms-and-condition/v-terms-and-condition/v-terms-and-condition.component';
import { CSupplierComponent } from './supplier/c-supplier/c-supplier.component';
import { VSupplierComponent } from './supplier/v-supplier/v-supplier.component';
import { CVehicleComponent } from './vehicle/c-vehicle/c-vehicle.component';
import { VVehicleComponent } from './vehicle/v-vehicle/v-vehicle.component';
import { CDefaultCustomerComponent } from './Default Customer/c-default-customer/c-default-customer.component';
import { VDefaultCustomerComponent } from './Default Customer/v-default-customer/v-default-customer.component';

  

// prime ng control
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ChipsModule} from 'primeng/chips';
import { FieldsetModule, } from 'primeng/fieldset';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { HttpClientModule } from '@angular/common/http';
import {TabViewModule} from 'primeng/tabview';
import { COtherchargesComponent } from './othercharges/c-othercharges/c-othercharges.component';
import { VOtherchargesComponent } from './othercharges/v-othercharges/v-othercharges.component';
import { CTenderComponent } from './Tender/c-tender/c-tender.component';
import { VTenderComponent } from './Tender/v-tender/v-tender.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { VSmsConfigurationComponent } from './sms-configuration/v-sms-configuration/v-sms-configuration.component';
import { CSmsConfigurationComponent } from './sms-configuration/c-sms-configuration/c-sms-configuration.component';
import {EditorModule} from 'primeng/editor';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CDocumentdescriptionComponent } from './DocumentDescription/c-documentdescription/c-documentdescription.component';
import { VDocumentdescriptionComponent } from './DocumentDescription/v-documentdescription/v-documentdescription.component';
import { CProductComponent } from './product/c-product/c-product.component';
import { VCustomerComponent } from './Customer/v-customer/v-customer.component';
import { CCompetitorComponent } from './competitor/c-competitor/c-competitor.component';
import { VCompetitorComponent } from './competitor/v-competitor/v-competitor.component';
import { CNotificationComponent } from './Notification/c-notification/c-notification.component';
import { VNotificationComponent } from './Notification/v-notification/v-notification.component';
import { CShippingLineComponent } from './shipping-line/c-shipping-line/c-shipping-line.component';
import { VShippingLineComponent } from './shipping-line/v-shipping-line/v-shipping-line.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CBrandComponent } from './brand/c-brand/c-brand.component';
import { VBrandComponent } from './brand/v-brand/v-brand.component';
import { SmsLogComponent } from './sms-log/sms-log.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { VCategoryComponent } from './category/v-category/v-category.component';
import { CCategoryComponent } from './category/c-category/c-category.component';
import { CSubcategoryComponent } from './subcategory/c-subcategory/c-subcategory.component';
import { VSubcategoryComponent } from './subcategory/v-subcategory/v-subcategory.component';

@NgModule({
  declarations: [
  //bharat
  CStateComponent, VStateComponent, CLevelComponent,
   VLevelComponent, CNumberingSchemaComponent, VNumberingSchemaComponent,
    CEmployeeComponent, VEmployeeComponent, CCustomerComponent, VCustomerComponent,
  //rajesh
    // CAccountGroupComponent,
    // VAccountGroupComponent,
    CAccountTypeComponent,
    VAccountTypeComponent,
    CCityComponent,
    VCityComponent,
    CCurrencyComponent,
    VCurrencyComponent,
   
    CBankComponent,
    VBankComponent,
    CProductTypeComponent,
    VProductTypeComponent,
    CPortComponent,
    VPortComponent,
    VProductComponent,
    CProductComponent,
    //CSchemeComponent,
    //VSchemeComponent,
	//rahul
	  CCountryComponent,
    VCountryComponent,
    CTaxComponent,
    VTaxComponent,
    CBranchComponent,
    VBranchComponent,
    CProductCategoryComponent,
    VProductCategoryComponent,
    CSupplierComponent,
    VSupplierComponent,
    CTermsAndConditionComponent,
    VTermsAndConditionComponent,
    CVehicleComponent,
    VVehicleComponent,
    COtherchargesComponent,
    VOtherchargesComponent,
    CTenderComponent,
    VTenderComponent,
	 VSmsConfigurationComponent,
   CSmsConfigurationComponent,
   CDefaultCustomerComponent,
   VDefaultCustomerComponent,
   CDocumentdescriptionComponent,
   VDocumentdescriptionComponent,
   CCompetitorComponent,
	 VCompetitorComponent,
   CNotificationComponent,
   VNotificationComponent,
   CShippingLineComponent,
   VShippingLineComponent,
   CBrandComponent,
   VBrandComponent,
   SmsLogComponent,
   VCategoryComponent,
   CCategoryComponent,
   CSubcategoryComponent,
   VSubcategoryComponent
  
  ],
  providers:[MessageService,ConfirmationService,CustomExceptionService,DatePipe,NgxImageCompressService
  
  ],
  imports: [
   
    CommonModule,
    MastersRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChipsModule,
    FieldsetModule,
    AutoCompleteModule,
    OverlayPanelModule,
    CheckboxModule,
    PasswordModule,
    HttpClientModule,
    TabViewModule,
    EditorModule,
    ProgressSpinnerModule,
    KeyFilterModule,
    ImageCropperModule,
    DragDropModule
    
  ]
})
export class MastersModule { }
