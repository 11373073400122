import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class TellercashdaysettlementService {

  public _Action: IAction;
  public _TellerCashDaySettlementService = environment.TellerCashSettlementService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(userid: number, tellerid: number, tellercashid: number, salesdate: Date, branchid: number): Observable<any> {
    const headers = this.header;
    console.log(salesdate);
    var datePipe = new DatePipe("en-US");
    var psalesdate = datePipe.transform(salesdate, 'yyyy-MM-dd');
    console.log(psalesdate);
    var Query = this._TellerCashDaySettlementService + '/PageOnload' + '/' + userid + '/' + tellerid + '/' + tellercashid + '/' + psalesdate + '/' + branchid;
   
    return this.http.get<{ data: any }>(Query, { headers });

  }

  GetSalesdetails(userid: number, tellerid: number, salesdate: Date, branchid: number) {
    const headers = this.header;
    console.log(salesdate);
    var datePipe = new DatePipe("en-US");
    var psalesdate = datePipe.transform(salesdate, 'yyyy-MM-dd');
    console.log(psalesdate);

    var Query = this._TellerCashDaySettlementService + '/GetSalesdetails/' + userid + '/' + tellerid + '/' + psalesdate + '/' + branchid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  create(tellercashid: object) {
    const headers = this.header;
    console.log("Create");
    var Query = this._TellerCashDaySettlementService;
   
    return this.http.post<{ data: any }>(Query, tellercashid, { headers });
  }

  FetchAll(userid: number,branchid:number) {
    const headers = this.header;
    var Query = this._TellerCashDaySettlementService + '/FetchAll/' + userid + '/' + branchid;
   
    return this.http.get<{ data: any }>(Query , { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);

      return data;
    }));
  }


  Search(obj:any) {
    // let paramvalue= "Ids="
    //  paramvalue = paramvalue + tellerid.join('&Ids=')
    // console.log(paramvalue);
    const headers = this.header;
    var Query = this._TellerCashDaySettlementService + '/Search';
   
    return this.http.post<{ data: any }>(Query,obj, { headers });
  }
  edit(tellercash: object) {
    const headers = this.header;
    console.log("Update");
    var Query = this._TellerCashDaySettlementService;
   
    return this.http.put<{ data: any }>(Query, tellercash, { headers });
  }

  SendToApproval( userid: number, branchid: number, Voucherno: number) {
    const headers = this.header;
    var Query = this._TellerCashDaySettlementService + '/SendToApproval'  + '/' + userid + '/' + branchid + '/' + Voucherno;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }

  ReportPageOnLoad(object : Object): Observable<any> {
    const headers = this.header;
    var Query = this._TellerCashDaySettlementService + '/TellerCashReportPageload';
    return this.http.post<{ data: any }>(Query,object, { headers });
  }

  TellerCashReportGetTellerNames(object : Object): Observable<any> {
    const headers = this.header;
    var Query = this._TellerCashDaySettlementService + '/TellerCashReportGetTellerNames';
    return this.http.post<{ data: any }>(Query,object, { headers });
  }

  TellerCashReportGetReport(object : Object): Observable<any> {
    const headers = this.header;
    var Query = this._TellerCashDaySettlementService + '/TellerCashReportGetReport';
    return this.http.post<{ data: any }>(Query,object, { headers });
  }

}