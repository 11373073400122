import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CreditnoteService } from 'src/app/core/services/accounts/creditnote.service';
import { DirectcreditnoteService } from 'src/app/core/Services/accounts/directcreditnote.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Creditnoteview } from 'src/app/core/Views/Creditnoteview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-direct-credit-note',
  templateUrl: './v-direct-credit-note.component.html',
  styleUrls: ['./v-direct-credit-note.component.css']
})
export class VDirectCreditNoteComponent implements OnInit {
  _Action: IAction;
  selectedrows: any;
  _Creditnoteviews: Creditnoteview[];
  _Creditnoteview: Creditnoteview;
  _branchid = sessionStorage["BranchID"];
  _branch = sessionStorage["BranchID"];
  _userid = sessionStorage["userid"];
  _Creditnoteid: number;
  _salesinvoiceno: number
  _IsProgressSpinner: boolean = true;
  cols: any[];
  _roleid=sessionStorage["currentRole"]
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  mindate: Date;
  maxdate: Date;
  // _branchid = sessionStorage["BranchID"];
  // sessionStorage["BranchID"] = resutJSON.loggedBranch.branchid;
  constructor(
    private _AccessRightsService: AccessRightsService,
    private fb: FormBuilder,
    private _CreditnoteService: DirectcreditnoteService,
    private router: Router,
    private utility: CommonUtilities,
    public exportUtility: ExportUtility,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,
    private _hotkeysService: HotkeysService) {
  }


  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(130);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.HotKeyIntegration();
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.Fetchall();
    console.log("branchid", this._branch);
    this.cols = [
      { field: 'voucherno', header: 'Voucher No' },
      { field: 'creditnotedate', header: 'Credit Note Date' },
      { field: 'accounttype', header: 'Account Type' },
      { field: 'accountname', header: 'Account Name' },
      { field: 'creditamount', header: 'Credit Amount' },
      { field: 'creditnotestatusdesc', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
  }
  
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    let branchid = sessionStorage["currentbranchid"]
    this._IsProgressSpinner = true;
    this._CreditnoteService.FetchAll(this._userid, branchid,this._roleid,fromdate,todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("fetchall", resultJSON);
      this._Creditnoteviews = resultJSON.creditnoteviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  view(event) {

    console.log("Debit Note :", event);
    this.router.navigate(['/cDirectCreditnote'], { state: { voucherno: event.voucherno, userid: event.userid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cDirectCreditnote']);
  }
  exportExcel() {
    let CreditNoteList = this.GetCreditNoteList();
    this.exportUtility.exportExcel(CreditNoteList, 'CreditNote', 'xlsx');
  }

  ExportToPdf() {
    let CreditNoteList = this.GetCreditNoteList();
    this.exportUtility.ExportToPdf(CreditNoteList, 'CreitNote.pdf');
  }

  GetCreditNoteList() {
    if (!this._Creditnoteviews || !this._Creditnoteviews.length)
      return;
    let CreditNoteList = [];
    this._Creditnoteviews.forEach(element => {
      let CreitNote: any = {};
      CreitNote["Voucher No"] = element.voucherno;
      CreitNote["Credit Note Date"] = element.creditnotedate;
      CreitNote["Account Type"] = element.accounttype;
      CreitNote["Account Name"] = element.accountname;
      CreitNote["Credit Amount"] = element.creditamount;
      CreitNote["Status"] = element.creditnotestatusdesc;
      CreitNote["Created By"] = element.createdbyname;
      CreitNote["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      CreditNoteList.push(CreitNote);
    });
    return CreditNoteList;
  }
  // view(event) {
  //   this.router.navigate(['/cTellerCashDaySettlement'], { state: { tellercashid: event.tellercashid,salesdate:event.salesdate, action: 'view' } });
  // }
  Cancel(event) {
    var creditnoteid = event.creditnoteid;
    this._IsProgressSpinner = true;
    this._CreditnoteService.Cancel(this._userid, creditnoteid).subscribe((result) => {
      const resutJSON = JSON.parse(JSON.stringify(result));
      console.log("cancel service", resutJSON);
      this._IsProgressSpinner = false;
      if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
        this.Fetchall();
      }
      else {
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
      }

      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure do you want to cancel this Credit Note <b>"' + event.voucherno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
}
