export class Creditnoteothercharge {
    creditnoteotherchargesdetailid: number;
    creditnoteid: number;
    otherchargeid: number;
    otherchargeamount: number;
    accountingyear: number ;
    createdby: number;
    createdon: Date ;
    modifiedby: number ;
    modifiedon: Date ;

   
}