import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public _Action: IAction;
  public _CustomerService = environment.CustomerService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 1)

  }
  createHeaders(token: any, type: number): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(p_customerid: number): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._CustomerService + '/' + p_customerid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll(userid: number, isdefaultcustomer: boolean, selectedtype: number, customerstatus: string, provinceid:number, cityid:number, responsiblepersonid:number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._CustomerService + '/FetchAll/' + userid + '/' + isdefaultcustomer + '/' + selectedtype + '/' + customerstatus + '/' + provinceid+ '/' + cityid+ '/' + responsiblepersonid;
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      return data;
    }));
  }

  getStates(countryid: string) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._CustomerService + '/getStates/' + countryid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  getDistrict(stateid: string) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._CustomerService + '/getDistrict/' + stateid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  // Create
  create(p_customer: FormData) {

    const headers = this.createHeaders(this.token, 2)
    var Query = this._CustomerService;
    return this.http.post<{ data: any }>(Query, p_customer, { headers });
  }
  // Edit
  edit(p_customer: FormData) {
    const headers = this.createHeaders(this.token, 2)

    var Query = this._CustomerService;

    return this.http.put<{ data: any }>(Query, p_customer, { headers });
  }

  Cancel(customerid: number, modifiedby: number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._CustomerService + '/Cancel/' + customerid + '/' + modifiedby;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  GetCustomer(customer: String) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._CustomerService + '/getcustomer/' + customer;
    return this.http.get<{ data: any }>(Query, { headers });
  }

}
