<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Generate Backup</h3>
                    </div>
                    <div class="col-md-7 text-right">
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_GenerateBackupForm">
                    <table class="normal-table">
                        <tr>
                            <td style="width:20%;">
                                <span class="p-float-label">
                                    <p-calendar name="choosedate" formControlName="choosedate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="choosedate"> Choose Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Branches"
                                        optionLabel="branchname" optionValue="branchid" [filter]="true"
                                        filterBy="branchname" [showClear]="true" formControlName="branchid">
                                    </p-dropdown>
                                    <label for="branchid"> Branch <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _GenerateBackupForm.get('branchid').touched) && _GenerateBackupForm.get('branchid').errors?.SelectBranch">
                                    Please select branch
                                </span>
                            </td>
                            <td>
                                <p-button label="Generate Backup"  title="Generate Backup" icon="las la-file-invoice" (click)="CreateBackup()">
                                </p-button>
                                <!-- <p-button label="Upload Backup" icon="las la-file-upload" class="p-button-success">
                                </p-button> -->
                            </td>
                        </tr>
                    </table>
                </form>
                <p-dialog header="Backup Message" [(visible)]="OpenBackupMessage" [style]="{width: '600px'}"
                    [baseZIndex]="10000" [modal]="true">
                    <table class="normal-table mail-view">
                        <tr>
                            <!-- <td style="width:150px">
                            <strong>Message :</strong>
                        </td> -->
                            <td>
                                <strong>{{Message}}</strong>
                            </td>
                        </tr>

                    </table>
                    <ng-template pTemplate="footer">
                        <p-button label="Upload Backup" title="Upload Backup" icon="las la-file-upload" class="p-button-success" (click)="UploadBackup()">
                        </p-button>
                        <p-button icon="pi pi-check" (click)="closeBackupMessagePopup()" label="Close" title="Close"
                            styleClass="p-button-text">
                        </p-button>
                    </ng-template>
                </p-dialog>

            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>