import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Form, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { MessageService } from 'primeng/api';
import { DocumentverificationService } from 'src/app/core/Services/purchase/documentverification.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Supplier } from 'src/app/core/Models/Supplier';
import { vBranchSelect, vDocumentTypeSelect, vSupplierNameSelect } from 'src/app/core/Validators/validation';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { FileUpload } from 'primeng/fileupload';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
//Debug
import * as ace from "ace-builds";
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Documentverificationdetail } from 'src/app/core/Models/Documentverificationdetail';
import { filter } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-c-documentverification',
  templateUrl: './c-documentverification.component.html',
  styleUrls: ['./c-documentverification.component.css']
})
export class CDocumentverificationComponent implements OnInit {
  _documentverificationform: FormGroup;
  selectedrows: any;
  _DocumentDetails: Documentverificationdetail[] = [];
  _branches: Branch;
  _documenttypes: Function;
  _Supplier: Supplier;
  _Status: Metadatum;
  _action: string = "create";
  _submitted = false;
  _selectedbranch: number;
  _DocumentverificationObj: any;
  _Userid = sessionStorage["userid"];
  _Functionid: number;
  _IsProgressSpinner: boolean = true;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _branchid = parseInt(sessionStorage["currentbranchid"]);
  _roleid = parseInt(sessionStorage["currentRole"]);
  displaydocumentgridpopup: boolean = false;
  document: SafeUrl;
  position: string;
  _IsEmployee: boolean = false;
  _LastAddedDocument: File;
  _selectedDocuments: File[] = [];
  _DOCUMENTFORM: FormData;
  SelectedDocumentDetailsRows: Documentverificationdetail[] = [];
  Supplierdisabled: boolean = true;
  _Supplierid: number;

  //Debug
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, private messageService: MessageService, private _CustomExceptionService: CustomExceptionService, private _DocumentVerificationService: DocumentverificationService, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService, private _DomSanitizationService: DomSanitizer) {
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._documentverificationform = this.fb.group({
      branchid: ['', vBranchSelect],
      functionid: ['', vDocumentTypeSelect],
      supplierid: ['']

    });
    this._documentverificationform.controls['branchid'].setValue(this._branchid);
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(43);

    this.InitializeForm();

    this._IsProgressSpinner = true;
    this._DocumentVerificationService.PageOnLoad().subscribe((result) => {

      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branches = resultJSON.branchList;
      this._Status = resultJSON.documentstatusList;
      this._documenttypes = resultJSON.functionsList;
      this._Supplier = resultJSON.supplierlist;
      this._documentverificationform.controls['branchid'].setValue(this._branchid);
      if (this._branchid != 1) {
        this._documentverificationform.get("branchid").disable();
      }
      this._IsProgressSpinner = false;

    });
  }
  // GetBranch(event) {
  //   var selectedbranch = event.value;
  //   this._selectedbranch = selectedbranch;
  // }
  OnDocumentRefNoChange(event) {
    var selectedDocument = this._documentverificationform.get("functionid").value;
    if (selectedDocument == undefined) {
      this._DocumentDetails = [];
      this.Supplierdisabled = true;
      this._documentverificationform.controls["supplierid"].reset(); // Reset supplier ID when no document is selected
      return;
    }
    
    
    var supplier = this._documentverificationform.get("supplierid").value;
    if (selectedDocument == '18') {
      this._IsEmployee = true;
      this.Supplierdisabled = true;
      this._documentverificationform.controls["supplierid"].reset(); // Reset supplier ID when document type is 18
    } 
    else if (selectedDocument == '49') {
      this.Supplierdisabled = false;
      this._IsEmployee = false;
      if(supplier != 0 && supplier != null)
      {
        this.Supplierdisabled = false;
        this._IsEmployee = false;
      } 
    }
    else {
      this._IsEmployee = false;
      this.Supplierdisabled = true;
      this._documentverificationform.controls["supplierid"].reset(); // Reset supplier ID when no supplier is needed
    }

    var selectedbranch = this._documentverificationform.get("branchid").value;
    var Supplierid = this._documentverificationform.get("supplierid").value || 0;

    this._IsProgressSpinner = true;
    this._DocumentVerificationService.OnDocumentRefNoChange(selectedbranch, selectedDocument, this._roleid, Supplierid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._DocumentDetails = resultJSON.documentDetailsList;

      for (var i = 0; i < this._DocumentDetails.length; i++) {
        this._DocumentDetails[i].isenable = this._DocumentDetails[i].documentstatus !== "MSC00048"; // Enable or disable based on status
      }
      this._IsProgressSpinner = false;
    },
    error => { 
        this._CustomExceptionService.handleError(error); 
        this._IsProgressSpinner = false; 
    });
}

// OnDocumentChange(event) {
//   if (event.value == undefined) {
//     this._DocumentDetails = [];
//     this.Supplierdisabled = true;
//     return;
//   }
  
//   // var selectedDocument = event.value;
//   var supplier = this._documentverificationform.get("supplierid").value;
  
//   // if (selectedDocument == '18') {
//   //   this._IsEmployee = true;
//   //   this.Supplierdisabled = true;
//   //   this._documentverificationform.controls["supplierid"].reset(); // Reset supplier ID when document type is 18
//   // } 
//   // else if (selectedDocument == '49') {
//   //   this.Supplierdisabled = false;
//   //   this._IsEmployee = false;
//   // }
// if(supplier != 0 && supplier != null)
//     {
//       this.Supplierdisabled = false;
//       this._IsEmployee = false;
//     } 

//   var selectedbranch = this._documentverificationform.get("branchid").value;
//   var Supplierid = this._documentverificationform.get("supplierid").value || 0;

//   this._IsProgressSpinner = true;
//   this._DocumentVerificationService.OnDocumentRefNoChange(selectedbranch, selectedDocument, this._roleid, Supplierid).subscribe((result) => {
//     const resultJSON = JSON.parse(JSON.stringify(result));
//     this._DocumentDetails = resultJSON.documentDetailsList;

//     for (var i = 0; i < this._DocumentDetails.length; i++) {
//       this._DocumentDetails[i].isenable = this._DocumentDetails[i].documentstatus !== "MSC00048"; // Enable or disable based on status
//     }
//     this._IsProgressSpinner = false;
//   },
//   error => { 
//       this._CustomExceptionService.handleError(error); 
//       this._IsProgressSpinner = false; 
//   });
// }



  formconverstion(_MyDocumentDetails: Documentverificationdetail[], documents: File[],) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    for (var x in documents) {
      var index = _MyDocumentDetails.findIndex(obj => obj.attachmentname == documents[x].name);
      if (index != -1) {
        var NewFileName = time.getTime() + '_' + documents[x].name;
        _MyDocumentDetails[index].attachmentname = NewFileName;
        _MyDocumentDetails[index].attachmenturl = NewFileName;
        formData.append('files', documents[x], NewFileName);
      }
    }
    this._DocumentverificationObj = {
      "Userid": this._Userid, "DocumentID": this._Functionid, "DocumentDetails": _MyDocumentDetails

    };
    var DocumentUpload = JSON.stringify(this._DocumentverificationObj);
    formData.append('strmo', DocumentUpload);
    return (formData);
  }


  onSave() {
    this._submitted = true;
    if (this._documentverificationform.valid) {
      if(this.SelectedDocumentDetailsRows.length <= 0)
      {
        this._CustomExceptionService.handleWarning("Select Atleast One Row")
      }
      else
      {
        this._Functionid = this._documentverificationform.get("functionid").value;
      var l_DocumentDetails: Documentverificationdetail[] = [];
      // this._DocumentDetails = (this._DocumentDetails || []).filter(f => f.documentstatus == "MSC00219" || f.documentstatus == "MSC00218" || f.documentstatus == "MSC00048");
      Object.keys(this.SelectedDocumentDetailsRows).map((Index) => {
        var currentRow = this.SelectedDocumentDetailsRows[Index];
        var l_documentdetails = new Documentverificationdetail();
        l_documentdetails.documentverificationdetailsid = currentRow.documentverificationdetailsid;
        l_documentdetails.documentid = currentRow.docid
        l_documentdetails.documentno = currentRow.doccode;
        l_documentdetails.remarks = currentRow.remarks;
        l_documentdetails.createdby = sessionStorage["userid"];
        l_documentdetails.createdon = new Date();
        l_documentdetails.modifiedby = sessionStorage["userid"];
        l_documentdetails.modifiedon = new Date();
        l_documentdetails.documentstatus = currentRow.documentstatus;
        l_documentdetails.attachmenturl = currentRow.attachmenturl || currentRow.url;
        l_documentdetails.attachmentname = currentRow.attachmentname || currentRow.documentname;
        l_documentdetails.documenttype = currentRow.documenttype;
        l_documentdetails.verifieddatetime = currentRow.verifieddatetime;
        l_documentdetails.branchid = currentRow.branchid;
        l_DocumentDetails.push(l_documentdetails);
      })
      if(l_DocumentDetails[0].attachmentname.length == 0 && l_DocumentDetails[0].documentstatus == "MSC00048")
      {
        this._CustomExceptionService.handleWarning("Please Upload Atleast One Document")
      }
      else
      this._DOCUMENTFORM = this.formconverstion(l_DocumentDetails, this._selectedDocuments);

      //this._DocumentverificationObj = { "Userid": this._Userid, "DocumentID": this._Functionid, "DocumentDetails": l_DocumentDetails };
      if (this._action == 'create') {
        // this.onEd();
        this._IsProgressSpinner = true;
        this._DocumentVerificationService.create(this._DOCUMENTFORM).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsProgressSpinner = false;
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      }
    }
  }
  showdocumentGridDialog(position: string, _Documentdetail: any) {
    let url = null;
    url = _Documentdetail.url;
    if (!url)
      return;
    // if (_Documentdetail.url) {
    //   this.document = this._DomSanitizationService.bypassSecurityTrustUrl(_Documentdetail.url);
    // }
    // this.position = position;
    // this.displaydocumentgridpopup = true;
    window.open(_Documentdetail.url, "MsgWindow", "width=800,height=800");
  }
  closePopupGridDialog(position: string) {
    this.position = position;
    this.displaydocumentgridpopup = false;
  }
  reset(event) {
    //this._documentverificationform.reset();
    this.InitializeForm();
    this._DocumentDetails = [];
    this._submitted = false;
    this.SelectedDocumentDetailsRows = [];
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  onDocumentSelect(event, document, _documentdetailgrid: any) {
    this._LastAddedDocument = event.currentFiles[0];
    _documentdetailgrid.attachmenturl = URL.createObjectURL(this._LastAddedDocument);
    _documentdetailgrid.attachmentname = this._LastAddedDocument.name;
    this._selectedDocuments.push(this._LastAddedDocument);
    document.clear()
  }
  onDocumentClear(_documentdetailgrid: any) {
    this._LastAddedDocument = null
    _documentdetailgrid.attachmenturl = "";
    _documentdetailgrid.attachmentname = "";
  }
  showdocumentDialog(document: FileUpload, position: string, _DocumentDetail: any) {
    let url = null;
    if (_DocumentDetail.attachmenturl) {
      url = _DocumentDetail.attachmenturl;
    }
    if (!url)
      return;

    window.open(url, "MsgWindow", "width=800,height=800");
  }
  onchangestatus(row: any, event) {
    if (row.documentstatus == "MSC00048") // REJECTED
    {
      row.isenable = false;
    }
    else {
      row.isenable = true;
    }
  }


  //Debug
  // onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._DocumentverificationObj;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }
}
