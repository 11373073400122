export class Customerslabwisediscountdetail
    {
        companyid: number;
        branchid: number;
        customerslabid: number;
        customerslabdetailid: number;
        minimumvalue: number;
        maximumvalue: number;
        discountpercentage: number;
        recordstatus: string;
        createdby: number;
        createdon: Date;
        modifiedby: number;
        modifiedon: Date;
        customerid: number;

        
    }