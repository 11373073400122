import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Assetregistration } from 'src/app/core/Models/AssetRegistration';
import { Currency } from 'src/app/core/Models/Currency';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { AssetregistrationService } from 'src/app/core/services/accounts/assetregistration.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { Branch } from 'src/app/core/Models/Branch';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { DatePipe } from '@angular/common';
import { vSelectAccountgroup } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
@Component({
  selector: 'app-c-asset-regirtration',
  templateUrl: './c-asset-regirtration.component.html',
  styleUrls: ['./c-asset-regirtration.component.css']
})
export class CAssetRegirtrationComponent implements OnInit {
  _IsSaveDisable: boolean = false;
  _IsEvidenceNo: boolean = false;
  _IsClearButton: boolean = false;
  _Assetregistrationform: FormGroup;
  _Assetregistration: Assetregistration;
  _Currencies: Currency;
  _CurrenciesTemp: Currency[];
  _Branches: Branch;
  _appordepAccounts: Accountledger;
  _isappOrDep: Metadatum;
  _UomCodes: Metadatum;
  _Products: Productheader;
  _Suppliers: Supplier;
  _status: Metadatum;
  _GRNlist: Assetregistration;
  _GRN: Assetregistration;
  _Accountledger: Accountledger;
  _Acc: Assetregistration;
  selectedGRN: any;
  displayBasic: boolean;
  _Assetregistrationid: string;
  _action: string = "create";
  _submitted = false;
  _IsProgressSpinner: boolean = true;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _route: ActivatedRoute,
    private _AssetregistrationService: AssetregistrationService,
    private _router: Router,
    private utility: CommonUtilities,
    private _hotkeysService: HotkeysService) {

  }
  InitializeFormGroup(): void {
    this._Assetregistrationform = this.fb.group({
      registrationid: [0],
      registrationcode: [0],
      grnno: ['', vSelectAccountgroup],
      productid: [],
      uomcode: [],
      quantity: [],
      supplierid: [],
      purchasedate: [],
      currencyuniquerefid: [0],
      currentcurrencyid: [0, vSelectAccountgroup],
      exchangerateinusd: [0],
      unitprice: [],
      purchaseamount: [],
      regBranchid: ['', vSelectAccountgroup],
      branchid: [sessionStorage["BranchID"]],
      isappOrDep: [],
      appordepAccount: ['', vSelectAccountgroup],
      appordepPerYear: [0],
      appordepAmountyear: [0],
      recordstatus: ['MSC00001'],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      accountingyear: [2020],
      amountinusd: [0],
      serialno: [],
      currentAssetValue :[]
    });
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeFormGroup();

    //  console.log("Registrationcode" , history.state);
    this._Assetregistrationid = history.state?.registrationid ? history.state?.registrationid : 0;
    // console.log(this._Assetregistrationid);
    this._action = history.state.action == null ? 'create' : history.state.action;

    this.HotKeyIntegration();
    this._IsProgressSpinner = true;
    this._AssetregistrationService.PageOnLoad(this._Assetregistrationid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // For DropDown
      this._status = resultJSON.recordStatus;
      this._Currencies = resultJSON.currencies;
      this._Branches = resultJSON.branches;
      this._isappOrDep = resultJSON.assetTypes;
      //this._appordepAccounts = resultJSON.accounts;
      this._Suppliers = resultJSON.suppliers;
      this._UomCodes = resultJSON.uomCodes;
      this._Products = resultJSON.products;
      this._appordepAccounts = resultJSON.accountledgers;
      this._CurrenciesTemp = resultJSON.currencies || [];
      this._GRNlist = resultJSON.assetregistrationpageloadviews;
      this._Assetregistrationform.controls["isappOrDep"].setValue('MSC00160');
      this._Assetregistrationform.controls["branchid"].setValue(sessionStorage["BranchID"])

      this._IsProgressSpinner = false;
      // console.log(resultJSON);
      // console.log(result);
      // //For Update
      // console.log(this._action);
      if (this._action == 'cancel' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));

        this._IsEvidenceNo = true;
        this._IsClearButton = true;
        this._IsSaveDisable = true;
        updateJSON.assetregistrations[0].isappOrDep = (updateJSON.assetregistrations[0].isappOrDep ? "MSC00159" : "MSC00160");


        console.log(updateJSON.assetregistrations);
        // this._Assetregistration = this._Assetregistrationform.value;
        this._Assetregistrationform.setValue(updateJSON.assetregistrations[0]);
        this._Assetregistrationform.disable();
        this.getAppreciationOrDepreciationAccount(null);
      }
    });
  }
  ChangeCurrencies(event): void {
    var exchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].exchangerate;
    var currencyuniquerefid = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].currencyuniquerefid;
    this._Assetregistrationform.controls['exchangerateinusd'].setValue(exchangerate);
    this._Assetregistrationform.controls['currencyuniquerefid'].setValue(currencyuniquerefid);
  }

  showBasicDialog() {
    this.displayBasic = true;
  }
  onRowSelectPopup(event) {
    var row = event.data;
    this._Assetregistrationform.controls['grnno'].setValue(row.grnno);
    this._Assetregistrationform.controls['productid'].setValue(parseInt(row.productid));
    this._Assetregistrationform.controls['uomcode'].setValue(row.uomcode);
    this._Assetregistrationform.controls['quantity'].setValue(row.quantity);
    this._Assetregistrationform.controls['supplierid'].setValue(parseInt(row.supplierid));

    var datePipe = new DatePipe("en-Us");
    var purchasedate = datePipe.transform(row.purchasedate, "dd-MMM-yyyy")
    this._Assetregistrationform.controls['purchasedate'].setValue(purchasedate);
    this._Assetregistrationform.controls['unitprice'].setValue(row.unitprice);
    this._Assetregistrationform.controls['purchaseamount'].setValue(row.purchaseamount);
    this.displayBasic = false;
  }

  getAppreciationOrDepreciationAccount(event) {
    var isAppordep = this._Assetregistrationform.get("isappOrDep").value;
    this._IsProgressSpinner = true;
    this._AssetregistrationService.getAppreciationOrDepreciationAccount(isAppordep).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._appordepAccounts = resultJSON.appreciationOrDepreciationAccounts;
      this._IsProgressSpinner = false;
    });
  }

  onSave() {
    // console.log("Submitted");
    // console.log(this._action);
    this._submitted = true;
    // console.log(this._Assetregistrationform.valid);
    if (this._Assetregistrationform.valid) {
      this._Assetregistration = this._Assetregistrationform.value;
      //console.log(this._Assetregistration);
      var errMsg = null;
      var appPerYear = parseInt(this._Assetregistrationform.get("appordepPerYear").value);
      if (!appPerYear)
        errMsg = "(App/Dep % Year) is Required.";

      if (this.ShowErrorMsg(errMsg))
        return;

      this._IsSaveDisable = true;
      this._IsClearButton = true;
      if (this._action == 'create') {
        //console.log("IN");
        // this._Assetregistration.accountingyear = new Date("YYYY").getFullYear();
        this._Assetregistration.createdon = new Date();
        this._Assetregistration.createdby = sessionStorage["userid"];
        this._Assetregistration.modifiedon = new Date();
        this._Assetregistration.modifiedby = sessionStorage["userid"];
        this._Assetregistration.branchid = sessionStorage["BranchID"];
        if (this._Assetregistrationform.get('isappOrDep').value == 'MSC00159') { // Appreciation
          this._Assetregistration.isappOrDep = true
        } else { //Depreciation
          this._Assetregistration.isappOrDep = false
        }
        // this._Assetregistration.isappOrDep = (this._Assetregistration.isappOrDep == true);
        this._IsProgressSpinner = true;
        this._AssetregistrationService.create(this._Assetregistration).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset();
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsSaveDisable = false;
            this._IsClearButton = false;
          }
        }, error => { this._CustomExceptionService.handleError(error); this._IsSaveDisable = false; this._IsClearButton = false; });
      }
      // else if (this._action == 'cancel') {
      //   this._Assetregistration.modifiedon = new Date();
      //   this._Assetregistration.modifiedby = sessionStorage["userid"];
      //   this._AssetregistrationService.Cancel(this._Assetregistration).subscribe((result) => {
      //     const resutJSON = JSON.parse(JSON.stringify(result));
      //     if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
      //       this._CustomExceptionService.handleError(usererrors.Cancel_Success_00);
      //     }
      //     else {
      //       this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
      //     }
      //   }, error => this._CustomExceptionService.handleError(error));
      // }
    }
  }


  reset() {
    this._submitted = false;
    this._Assetregistrationform.reset();
    this.InitializeFormGroup();
    this._IsSaveDisable = false;
    this.ngOnInit();
  }
  goBack(event) {
    //console.log("goBack");
    this._router.navigate(['/VAssetRegirtration']);
  }
  onEd() {
    //alert("test");
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._Assetregistration;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }

  OnChangeAppOrDepper(value, controlName) {
    value = parseInt(value || 0);
    this._Assetregistrationform.controls[controlName].enable();
    if (!value)
      return;

    if (value > 100) {
      this._CustomExceptionService.handleWarning("Percentage Should be in 100");
      this._Assetregistrationform.get('appordepPerYear').reset();
      return
    }
    this._Assetregistrationform.controls[controlName].disable();
  }

  OnChangeAppOrDep(value, controlName) {
    value = parseInt(value || 0);
    this._Assetregistrationform.controls[controlName].enable();
    if (!value)
      return;

    if (value > 100) {
      this._CustomExceptionService.handleWarning("");
    }
    this._Assetregistrationform.controls[controlName].disable();
  }



  ShowErrorMsg(errMsg) {
    if (!errMsg)
      return false;

    this.messageService.add({ severity: 'error', summary: 'Error Message', sticky: true, detail: errMsg });
    return true;
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

}
