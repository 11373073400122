<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Debit Note</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" (click)="GoToCreate($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate" [monthNavigator]="true" yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                        [monthNavigator]="true"[minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="todate">To Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                    <p-toast position="bottom-right"></p-toast>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportUtility.exportCSV(dt)" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_Debitnoteviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['debitnoteno', 'debitnotedate','accounttype','accountname','debitamount','debitnotestatusdesc','createdbyname','createdon']"
                            [rowHover]="true" dataKey="debitnoteno" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="text-center" style="width:120px;">Action
                                    </th>
                                    <th pSortableColumn="debitnoteno">Debit Note No
                                        <p-sortIcon field="debitnoteno">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="debitnotedate">Debit Note Date
                                        <p-sortIcon field="debitnotedate">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="accounttype">Account Type
                                        <p-sortIcon field="accounttype">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="accountname">Account Name
                                        <p-sortIcon field="accountname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="debitamount">Amount
                                        <p-sortIcon field="debitamount">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="currencyname">Currency
                                        <p-sortIcon field="currencyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="debitnotestatusdesc">Status
                                        <p-sortIcon field="debitnotestatusdesc">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdbyname">Created By
                                        <p-sortIcon field="createdbyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdon">Created On
                                        <p-sortIcon field="createdon">
                                        </p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_Debitnoteview>
                                <tr>
                                    <td class="text-center">
                                        <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2" [disabled]="!_Action._View" (click)="view(_Debitnoteview)"></button>
                                        <button pButton pRipple icon="las la-times" title="Cancel" class="p-button p-button-danger p-mr-2" [disabled]="true" [hidden]="_Debitnoteview.debitnotestatusid == '150'" (click)="confirm(_Debitnoteview)"></button>
                                    </td>
                                    <td>
                                        {{_Debitnoteview.debitnoteno}}
                                    </td>
                                    <td>
                                        {{_Debitnoteview.debitnotedate | date:'dd-MMM-yyyy'}}
                                    </td>
                                    <td>
                                        {{_Debitnoteview.accounttype}}
                                    </td>
                                    <td>
                                        {{_Debitnoteview.accountname}}
                                    </td>
                                    <td>
                                        {{_Debitnoteview.debitamount}}
                                    </td>
                                    <td>
                                        {{_Debitnoteview.currencyname}}
                                    </td>
                                    <td>
                                        {{_Debitnoteview.debitnotestatusdesc}}
                                    </td>
                                    <td>
                                        {{_Debitnoteview.createdbyname}}
                                    </td>
                                    <td>
                                        {{_Debitnoteview.createdon|date:'dd/MM/yyyy'}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </form>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>