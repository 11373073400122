<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Branch Wise Stock Report</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptbranchwisestockform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchid"
                                        [ngModelOptions]="{standalone: true}" [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="producttypeid">Branch Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_SupplierFliter"
                                        optionValue="supplierid" optionLabel="suppliername" formControlName="supplierid"
                                        [filter]="true" filterBy="suppliername" [virtualScroll]="true" itemSize="15"
                                        (onChange)="getProductType()" [showClear]="true">
                                    </p-dropdown>
                                    <label for="supplierid">Supplier Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_producttypelist" optionLabel="producttypename"
                                        optionValue="producttypeid" [(ngModel)]="producttypeid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProductCategory($event)"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                                        [selectionLimit]="50" [(ngModel)]="productcatid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProduct($event)"
                                        optionValue="productcategoryid" [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productlist" optionLabel="productname"
                                        optionValue="productid" [(ngModel)]="productid"
                                        (onChange)="ChangeProduct($event)" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                                        [(ngModel)]="brandid" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="brandid">Brand Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_stockvaluelist"
                                        optionValue="metasubcode" optionLabel="metasubdescription"
                                        formControlName="stockvalue" [filter]="true" filterBy="metasubdescription">
                                    </p-dropdown>
                                    <label for="stocktype">Positive/Negative</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_stocktypelist"
                                        optionValue="metasubcode" optionLabel="metasubdescription"
                                        formControlName="stocktype" [filter]="true" filterBy="metasubdescription">
                                    </p-dropdown>
                                    <label for="stocktype">Stock Type</label>
                                </span>
                            </td>
                            <td>
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox name="withzero" binary="true" formControlName="withzero">
                                        </p-checkbox>
                                        <label for="withzero">Without 0 Stock </label>
                                    </div>
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" icon="pi pi-search"
                                    (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)"
                                    class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel1()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="500"
                        [paginator]="true" [rowsPerPageOptions]="[100,200,300]"
                        [rowsPerPageOptions]="[500,1000,1500,2000]"
                        [globalFilterFields]="['branchname','productcategoryname','productcode','productname','brandname','salesuomname','severityname','okstock','lockedstock','closingstock']"
                        [rowHover]="true" dataKey="productname" [columns]="cols"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="branchname">Branch Name
                                    <p-sortIcon field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productcategoryname">Category Name
                                    <p-sortIcon field="productcategoryname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productcode">Product Code
                                    <p-sortIcon field="productcode">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="brandname">Brand
                                    <p-sortIcon field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="salesuomname">Uom
                                    <p-sortIcon field="salesuomname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="salesuomname" style="width: 100px;">Stock details
                                    <p-sortIcon field="salesuomname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="okstock">Sellable Stock
                                    <p-sortIcon field="okstock">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="lockedstock">Locked Stock
                                    <p-sortIcon field="lockedstock">
                                    </p-sortIcon>
                                <th pSortableColumn="closingstock">Closing Stock
                                    <p-sortIcon field="closingstock">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 60px;">
                                    {{i+ 1}}
                                </td>
                                <td>
                                    {{_ReportList.branchname}}
                                </td>
                                <td>
                                    {{_ReportList.productcategoryname}}
                                </td>
                                <td>
                                    {{_ReportList.productcode}}
                                </td>
                                <td>
                                    {{_ReportList.productname}}
                                </td>
                                <td>
                                    {{_ReportList.brandname}}
                                </td>
                                <td>
                                    {{_ReportList.salesuomname}}
                                </td>
                                <td class="text-center" style="width: 100px;">
                                    <a href="javascript:void(0);" (click)="showBasicDialog(_ReportList)">
                                        <button class="p-inputgroup-addon p-button" title="Stock details" type="button">
                                            <i class="las la-external-link-alt"></i>
                                        </button>
                                    </a>
                                </td>
                                <td>
                                    {{_ReportList.okstock}}
                                </td>
                                <td>
                                    <a href="javascript:void(0);" (click)="showlockedqty(_ReportList)">
                                        {{_ReportList.lockedstock}}</a>
                                </td>

                                <td>
                                    {{_ReportList.closingstock}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-dialog header="Stock Details" [(visible)]="griddetaillockqty" [modal]="true"
                    [style]="{width: '500px',height: '200px'}" [baseZIndex]="10000">

                    <div class="card">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 25px;">
                                    <span class="p-float-label">
                                        <input type="text" name="productname" disabled="true"
                                            [(ngModel)]="selectedbranchName" pInputText>
                                        <label for="productname">Branch Name </label>
                                    </span>
                                </td>
                                <td style="width: 25px;">
                                    <span class="p-float-label">
                                        <input type="text" name="productname" disabled="true"
                                            [(ngModel)]="selectedProductName" pInputText>
                                        <label for="productname">Product Name </label>
                                    </span>
                                </td>

                                <td style="width: 25px;"></td>
                            </tr>
                        </table>
                    </div>
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="card">
                                <p-table #dtProductWiseStockqty [value]="_ProductWiseStockqty" selectionMode="single"
                                    [columns]="ProductWiseLockedcols" [rowHover]="true" dataKey="productid"
                                    [showCurrentPageReport]="true" [(selection)]="selectedProductRows">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn="SUPPLIERNAME">Supplier Name
                                                <p-sortIcon field="SUPPLIERNAME">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="PLUSLOT"> Qty
                                                <p-sortIcon field="PLUSLOT">
                                                </p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-_ProductWiseStockQty let-i="rowIndex">
                                        <tr>
                                            <td>
                                                {{_ProductWiseStockQty.SUPPLIERNAME}}
                                            </td>
                                            <td>
                                                {{_ProductWiseStockQty.PLUSLOT}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>

                    </ng-template>
                </p-dialog>
                <p-dialog header="Locked Details" [(visible)]="griddetaillocked" [modal]="true"
                    [style]="{width: '600px',height: '300px'}" [baseZIndex]="10000">
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="card">
                                <p-table #dtProductWiselockedqty [value]="_ProductWiselockedqty" selectionMode="single"
                                    [columns]="ProductWiseLockedcols" [rowHover]="true" dataKey="productid"
                                    [showCurrentPageReport]="true" [(selection)]="selectedProductRows">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn="referencetype">Referance Type
                                                <p-sortIcon field="referencetype">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="referenceno">Referance No
                                                <p-sortIcon field="referenceno">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="lockedquantity">Locked Qty
                                                <p-sortIcon field="lockedquantity">
                                                </p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-_ProductWiselockedQty
                                        let-i="rowIndex">
                                        <tr>
                                            <td>
                                                {{_ProductWiselockedQty.referencetype}}
                                            </td>
                                            <td>
                                                {{_ProductWiselockedQty.referenceno}}
                                            </td>
                                            <td>
                                                {{_ProductWiselockedQty.lockedquantity}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>