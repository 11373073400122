import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Country } from 'src/app/core/Models/Country';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { OtherchargesService } from 'src/app/core/Services/masters/othercharges.service';
import { vCountryName, vMinimumAmount, vStatusSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-c-othercharges',
  templateUrl: './c-othercharges.component.html',
  styleUrls: ['./c-othercharges.component.css']
})
export class COtherchargesComponent implements OnInit {
  _otherchargeform: FormGroup;
  _otherchargestatus: Metadatum;
  _otherchargestype: Metadatum;
  _OtherCharges: Othercharge;
  _otherchargeid: number;
  _action: string = "create";
  _submitted = false;
  _IsProgressSpinner: boolean = true;
  isDisabledSave = false;
  isDisabledClear = false;
  _Action: IAction;

  constructor(private fb: FormBuilder, private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _OtherChargesService: OtherchargesService,
    private _route: ActivatedRoute, private _router: Router, private utility: CommonUtilities,
    private _hotkeysService: HotkeysService, private _AccessRightsService: AccessRightsService) {

    this._otherchargeid = history.state?.otherchargeid ? history.state?.otherchargeid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeForm() {

    this._otherchargeform = this.fb.group({
      otherchargeid: [0],
      otherchargename: ['', vCountryName],
      otherchargeamount: ['', vMinimumAmount],
      otherchargestatus: ['', vStatusSelect],//22.if "Status " is not select, show error as "Please Select Status"
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      otherchargestype: ['', vStatusSelect]
    })
    this._otherchargeform.controls['otherchargestatus'].setValue("MSC00001");
  }


  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(28);
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;

    if (this._action == 'view') {
      this._otherchargeform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
    }
    if (this._action == 'edit') {

      this.isDisabledClear = true;
      this.isDisabledSave = false;
    }
    this._OtherChargesService.PageOnLoad(this._otherchargeid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // throw this._CustomExceptionService.handleError("Please  fill customer form properly");
      this._otherchargestatus = resultJSON.otherchargestatus;
      this._otherchargestype = resultJSON.otherchargestype;
      this._otherchargeform.controls['otherchargestatus'].setValue("MSC00001");
      this._IsProgressSpinner = false;

      //For Update
      this._otherchargeid = history.state._otherchargeid;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._otherchargeform.setValue(updateJSON.othercharge);
      }
    });

  }
  onSave() {
    this._submitted = true;
    //8. if all the data is valid allow to save the record in the database 
    if (this._otherchargeform.valid) {
      this._OtherCharges = this._otherchargeform.value;
      this._IsProgressSpinner = true;
      // this.onEd();
      if (this._action == 'create') {
        this._OtherCharges.createdon = new Date();
        this._OtherCharges.createdby = sessionStorage["userid"];
        this._OtherChargesService.create(this._OtherCharges).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsProgressSpinner = false;
            this._submitted = false;
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        this._OtherCharges.modifiedon = new Date();
        this._OtherCharges.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._OtherChargesService.edit(this._OtherCharges).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this._IsProgressSpinner = false;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }

  reset(event) {
    //11.clear all the fields which the data has entered
    this._otherchargeform.reset();
    this.InitializeForm();
    this._submitted = false;
  }
  goBack(event) {
    // 25.on clicking back button , it Navigates to the search screen
    this._router.navigate(['/vOtherCharges']);
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      //this._hotkeysService.add(new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
      //  this.router.navigate(['/cBank'], { state: { bankid: this._bankid, action: 'edit' }});
      //  return false; // Prevent bubbling
      //}));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

}
