import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { ContainertrackingService } from 'src/app/core/Services/tracking/containertracking.service';
import { Containertrackingview } from 'src/app/core/Views/Containertrackingview';
import { Stockinwardview } from 'src/app/core/Views/Stockinwardview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-v-containertracking',
  templateUrl: './v-containertracking.component.html',
  styleUrls: ['./v-containertracking.component.css']
})
export class VContainertrackingComponent implements OnInit {

  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"];
  _roleid=sessionStorage["currentRole"];
  _containertrackings: Containertrackingview[] = [];
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  _containertrackingid:number;
  cols:any[];
  fromdate: Date;
  todate: Date;
  maxdate: Date;
  mindate: Date;
  datepipe = new DatePipe('en-US');
  constructor(private fb: FormBuilder, private _ContainertrackingService: ContainertrackingService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,private confirmationService: ConfirmationService,
    private exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(44);
    this.utility.pageLoadScript();
    this.HotKeyIntegration();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.cols = [
      { field: 'containertrackingno', header: 'Container Tracking No' },
      { field: 'containerno', header: 'Container No' },
      { field: 'blno', header: 'B.L. Number'},
      { field: 'suppliername', header: 'Supplier Name' },
      { field: 'lastupdateddate', header: 'Last Updated Date' },
      { field: 'containerstatusname', header: 'Cont Status' },
      { field: 'shippinglinename', header: 'Shipping Line' },
      { field: 'arraivaldate', header: 'Arrival Date' },
      { field: 'portagent', header: 'Port Agent' },
      { field: 'borderagent', header: 'Border Agent' },
      // { field: 'localtransporter', header: 'Local Transporter' },
      { field: 'statusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
   this.Fetchall();
  }

  Fetchall(){
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._branchid=  sessionStorage["currentbranchid"];
    this._userid=sessionStorage["userid"];
    this._roleid=sessionStorage["currentRole"];
    this._IsProgressSpinner = true;
    this._ContainertrackingService.FetchAll(this._userid, this._branchid,this._roleid,fromdate,todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._containertrackings = resultJSON.containertrackingviewList;
      this._IsProgressSpinner = false;
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  onRowSelect(event) {

  }
  onRowUnselect(event) {

  }

  view(event) {
    this.router.navigate(['/cContainertracking'], { state: {containertrackingid: event.containertrackingid, action: 'view' } });
  }

  // delete(event) {
  //   this._IsProgressSpinner = true;
  //   this._ContainertrackingService.Cancel(event.containertrackingid).subscribe((result) => {
  //     const resultJSON = JSON.parse(JSON.stringify(result));
  //     if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
  //       this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
  //       this._IsProgressSpinner = false;
  //     }
  //     else {
  //       this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
  //       this._IsProgressSpinner = false;
  //     }
  //   },
  //   error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  // }
  confirm(event) {
    this._containertrackingid = event.containertrackingid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Document <b>"' + event.containertrackingno + '"</b> ?',
      accept: () => {
        this.Cancel(event);
      }
    });
  }
  
  Cancel(event) {
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid=  sessionStorage["currentbranchid"];
    this._userid=sessionStorage["userid"];
    this._roleid=sessionStorage["currentRole"];
    this._IsProgressSpinner = true;
    this._ContainertrackingService.Cancel(this._containertrackingid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.containertracking);
        this.Fetchall();
        this._IsProgressSpinner = false;
      }
      
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  edit(event) {

    this.router.navigate(['/cContainertracking'], { state: { containertrackingid: event.containertrackingid, action: 'edit' } });
  }
  GoToCreate(event){
    this.router.navigate(['/cContainertracking']);
  }
    //View
    HotKeyIntegration() {
      this._hotkeysService.reset();
      if (this._Action._Add) {
        this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
          this.GoToCreate(event);
          return false; // Prevent bubbling
        }, ['INPUT', 'SELECT', 'TEXTAREA']));
      }
    }
    // exportExcel() {
    //   let finalizedList = this.GetFinalizedList();
    //   this.exportUtility.exportExcel(finalizedList, 'ContainerTracking', 'xlsx');
    // }
    exportExcel() {
      if (!this._containertrackings || !this._containertrackings.length)
        return;
      let employeeList = this.GetFinalizedList();
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(employeeList);
        const workbook = { Sheets: { 'Containertracking': worksheet }, SheetNames: ['Containertracking'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Containertracking");
      });
    }
    saveAsExcelFile(buffer: any, fileName: string): void {
      import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
    }
  
    // ExportToPdf() {
    //   let finalizedList = this.GetFinalizedList();
    //   this.exportUtility.ExportToPdf(finalizedList, 'ContainerTracking.pdf');
    // }
    ExportToPdf() {
      let TermsAndConditionList = this.GetFinalizedList();
      const data = TermsAndConditionList.map(Object.values);
      const head = [[
        'Container Tracking No',
        'Container No',
        'Supplier Name',
        'Last Updated Date',
        'Container Status',
        'Shipping Line',
        'Arrival Date',
        'Port Agent',
        'Border Agent',
        'Local Transporter',
        'Status',
        'Created By',
        'Created On'
      ]]
      import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
          const doc = new jsPDF.default('l', 'mm', 'a4');
          autoTable(doc, {
            head: head,
            body: data,
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0,
            styles: {
              lineColor: [0, 0, 0],
              lineWidth: 0.1,
            },
            headStyles: {
              fillColor: [211, 211, 211],
              textColor: 0
            },
            bodyStyles: {
              textColor: 0
            },
            didDrawCell: (data) => { },
          });
          doc.save('ContainerTracking.pdf');
        })
      })
    }
  
    GetFinalizedList() {
      let finalizedList = [];
      this._containertrackings.forEach(element => {
        let quotes: any = {};
        quotes["Container Tracking No"] = element.containertrackingno;
        quotes["Container No"] = element.containerno;
        quotes["B.L. Number"] = element.blno;
        quotes["Supplier Name"] = element.suppliername;
        quotes["Last Updated Date"] = element.lastupdateddate;
        quotes["Container Status"] = element.containerstatusname;
        quotes["Shipping Line"] = element.shippinglinename;
        quotes["Arrival Date"] = this.datepipe.transform(element.arraivaldate, 'dd/MM/yyyy');
        quotes["Port Agent"] = element.portagent;
        quotes["Border Agent"] = element.borderagent;
        // quotes["Local Transporter"] = element.localtransporter;
        quotes["Status"] = element.statusname;
        quotes["Created By"] = element.createdbyname;
        quotes["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
        finalizedList.push(quotes);
      });
      return finalizedList;
    }
}

