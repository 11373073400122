import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class CityService {
  public _Action: IAction;
  public _CityService = environment.CityService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(p_districtid: number): Observable<any> {
    const headers = this.header;
    var Query = this._CityService + '/' + p_districtid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll() {
    const headers = this.header;
    var Query = this._CityService + '/FetchAll';
   
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      return data;
    }));
  }
  create(p_City: object) {
    const headers = this.header;
    //console.log("Create");
    var Query = this._CityService;

   
    return this.http.post<{ data: any }>(Query, p_City, { headers });
  }
  edit(p_City: object) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._CityService;
   
    return this.http.put<{ data: any }>(Query, p_City, { headers });
  }
  getStates(countryid: string) {
    const headers = this.header;
    var Query = this._CityService + '/getStates/' + countryid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  Cancel(districtid:number,userid :number)
  {
    const headers = this.header;
    //console.log("Update");
    var Query = this._CityService + '/Cancel/' + districtid +'/'+ userid ;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }

}
