<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Trip Sheet</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_tripsheetform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_vehiclelist"
                                            optionValue="vehicleid" optionLabel="vehicleno" formControlName="vehicleid"
                                            [filter]="true" filterBy="vehicleno" (onChange)="ChangeVehicle($event)">
                                        </p-dropdown>
                                        <label for="vehicleno">Vehicle No</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_driverlist"
                                            optionValue="driverid" optionLabel="drivername" formControlName="driverid"
                                            [filter]="true" filterBy="drivername" (onChange)="ChangeDriver($event)">
                                        </p-dropdown>
                                        <label for="drivername">Driver Name</label>
                                    </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Generate" icon="las la-print"
                                        (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)"
                                        class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div class="output-container">
                    <iframe #iframe class="scroll-height" width="100%" [src]="_src"></iframe>
                </div>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>

        </div>
    </div>

</div>
<p-toast position="bottom-right"></p-toast>