import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Brand } from 'src/app/core/Models/Brand';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { RptminusstockService } from 'src/app/core/Services/reports/Stock/Rptminusstock/rpt-minusstock.service';
import { RptbrandwisestockService } from 'src/app/core/Services/reports/Stock/RptBrandwisestock/rptbrandwisestock.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-rpt-minus-stock',
  templateUrl: './rpt-minusstock.component.html',
  styleUrls: ['./rpt-minusstock.component.css'],
})
export class RptMinusStockComponent implements OnInit {
  [x: string]: any;
  _IsProgressSpinner: boolean = false;
  _rptminusstockform: FormGroup;
  _branchlist = sessionStorage['BranchID'];
  _userid: number = parseInt(sessionStorage['userid']);
  datepipe: any;
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _variantlist: Productvariant[];
  _productlist: Productoverallview[] = [];
  _src: SafeResourceUrl;
  filteredProducts: any[];
  _BranchWiseObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  branchid: number[] = [];
  _brandlist: any;
  _Tempbrandlist: Brand;
  brandid: number[] = [];
  _ProductObj: any;
  _ReportLists: any[] = [];
  cols: any[];
  _branchwisestockViews: any[] = [];
  isdisable = false;
  isDisableaddElement: boolean = true;
  selectedProductRows: any;
  griddetaillockqty: boolean = false;
  griddetaillocked: boolean = false;
  _ProductWiseStockqty: any[] = [];
  _ProductWiselockedqty: any[] = [];
  selectedProductName: any;
  selectedbranchName: any;
  _BrandObj: any;
  _SupplierFliter: any[] = [];
  mindate: Date;
  maxdate: Date;
  datepipes = new DatePipe("en-US");
  _Invoicetype: any[] = [];

  constructor(
    private fb: FormBuilder,
    private _RptminusstockService: RptminusstockService,
    private _CustomExceptionService: CustomExceptionService,
    private exportUtility: ExportUtility,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);

    this._rptminusstockform = this.fb.group({
      fromdate: [''],
      todate: [''],
      invoicetype: [],
    });

    this._userid = parseInt(sessionStorage['userid']);
    if (sessionStorage['Environmentenddate'] == 'null') {
      this._rptminusstockform.get('fromdate').setValue(new Date());
      this._rptminusstockform.get('todate').setValue(new Date());
      this.maxdate = new Date();
    } else {
      this._rptminusstockform
        .get('fromdate')
        .setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptminusstockform
        .get('todate')
        .setValue(new Date(sessionStorage['Environmentenddate']));
    }

    this._IsProgressSpinner = true;

    this._RptminusstockService.PageOnload({}).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._branchlist = resultJSON.branchList;
        this._producttypelist = resultJSON.producttypeList;
        this._brandlist = resultJSON.brandList;
        this._Tempbrandlist = resultJSON.brandList;
		this._Invoicetype =  resultJSON.invoiceType;

        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    
      this._IsProgressSpinner = true;
      this._ProductTypeObj = {
        Producttype: selectedProductType || [],
        branchid: parseInt(sessionStorage['currentbranchid']),
      };
      this._RptminusstockService
        .getProductCategory(this._ProductTypeObj)
        .subscribe(
          (result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            this._productcategorylist = resultJSON.productcategories;
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
    
  }
  ChangeProduct(event) {
    var selectedProduct = event.value;
    
      this._IsProgressSpinner = true;
      this._ProductObj = {
        Products: selectedProduct || [],
        branchid: parseInt(sessionStorage['currentbranchid']),
      };
      this._RptminusstockService.getBrand(this._ProductObj).subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          let productbrand = resultJSON.productbrandlist;
          let uniqueChars = [...new Set(productbrand)];
          uniqueChars = productbrand.filter(
            (test, index, array) =>
              index ===
              array.findIndex((findTest) => findTest.brandid === test.brandid)
          );
          this._brandlist = uniqueChars;
          this._IsProgressSpinner = false;
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
        }
      );
    
  }
  filterProducts(event) {
    let filtered: any[] = [];
    let query = (<string>event.query).toLowerCase();
    this.filteredProducts =
      this._productlist.filter(
        (f) => f.searchfilter.toLowerCase().indexOf(query) > -1
      ) || [];
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    this.productid = [];
    
      this._ProductCategoryObj = {
        Productcategory: selectedProductCategory || [],
        branchid: parseInt(sessionStorage['currentbranchid']),
      };
      this._RptminusstockService
        .getProduct(this._ProductCategoryObj)
        .subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._productlist = resultJSON.productoverallList;
        });
    
  }
  showBasicDialog(_ReportList) {
    this._IsProgressSpinner = true;
    var stocktype = this._rptminusstockform.get('stocktype').value;
    let data = {
      branchid: _ReportList.branchid,
      productid: _ReportList.productid,
      stocktype: stocktype,
      lockedqty: _ReportList.lockedstock || 0,
    };
    this._RptminusstockService.lotquantitypopup(data).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this.griddetaillockqty = true;

        this._ProductWiseStockqty = resultJSON.productWiseStockqty;
        if (this._ProductWiseStockqty.length) {
          this.selectedProductName =
            resultJSON.productWiseStockqty[0].PRODUCTNAME;
          this.selectedbranchName =
            resultJSON.productWiseStockqty[0].BRANCHNAME;
        }
        this._ProductWiselockedqty = resultJSON.productWiselockedqty || [];
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  GenerateReport(event) {
    var _fromdate = this._rptminusstockform.get('fromdate').value;
    var _todate = this._rptminusstockform.get('todate').value;
	var invoicetype = this._rptminusstockform.get('invoicetype').value;
    var datePipe = new DatePipe('en-US');
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError(
        'Effective From Date should be less than the Effective to Date'
      );
      return;
    }

	let data = 
	{
		"FromDate" : fromdate,
		"ToDate" : todate,
		"Producttype" : this.producttypeid || [],
		"Productcategory" : this.productcatid || [],
		"Productheader" : this.productcatid || [],
		"Branchid" : this.branchid || [],
		"Brandid" : this.brandid || [],
		"Invoicetype" : invoicetype || ""
	}

    this._IsProgressSpinner = true;
    this._RptminusstockService.MinusStockPrint(data).subscribe(
      (result) => {
        this._IsProgressSpinner = false;
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._ReportLists = [];
        if (!resultJSON.reportList || !resultJSON.reportList.length) {
          this._CustomExceptionService.handleError('No Data Available');
        } else {
          this._ReportLists = resultJSON.reportList;
       
        }
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  clear(event) {
    this._rptminusstockform.reset();
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this.branchid = [];
    this._productcategorylist = [];
    this._productlist = [];
    this.brandid = [];
    this._ReportLists = [];
    this.isdisable = false;
    this.ngOnInit();
  }

  exportExcel() {
    let minusstockreportlist = this.GetReportList();
    this.exportUtility.exportExcel(minusstockreportlist, 'MinusStockReport', 'csv');
  }

  exportExcel1() {
    let stockadjustmentstocklists = this.GetReportList();
    this.exportUtility.exportExcel(stockadjustmentstocklists, 'MinusStockReport', 'xlsx');
  }

  ExportToPdf() {
    let branchwisestocklists = this.GetReportList();
    const data = branchwisestocklists.map(Object.values);
    const head = [['Invoice Type', 'Reference No', 'Reference Date', 'Delivery Branch', 'Customer Branch', 'Product Type', 'Product Category', 'Product Name', 'Brand Name', 'Quantity', 'Created By'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        // doc.autoTable(this.exportColumns, this._ExpenseReportviews);
        doc.save('MinusStockReport.pdf');
      })
    })

  }


  GetReportList() {
    let minusstockreportlist = [];
    this._ReportLists.forEach(element => {
      let stockadjustmentstock: any = {};
      stockadjustmentstock["Invoice Type"] = element.INVOICETYPE;
      stockadjustmentstock["Reference No"] = element.REFERENCENO;
      stockadjustmentstock["Reference Date"] = this.datepipes.transform(element.REFERENCEDATE, 'dd/MM/yyyy');
      stockadjustmentstock["Delivery Branch"] = element.DELIVERYBRANCHNAME;
      stockadjustmentstock["Customer Branch"] = element.CUSTOMERORBRANCHNAME;
      stockadjustmentstock["Product Type"] = element.PRODUCTTYPENAME;
      stockadjustmentstock["Product Category"] = element.PRODUCTCATEGORYNAME;
      stockadjustmentstock["Product Name"] = element.PRODUCTNAME;
      stockadjustmentstock["Brand Name"] = element.BRANDNAME;
      stockadjustmentstock["Quantity"] = element.QUANTITY;
      stockadjustmentstock["Created By"] = element.CREATEDBY;
      minusstockreportlist.push(stockadjustmentstock);
    });
    return minusstockreportlist;
  }
}
