import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { MessageService } from 'primeng/api';
import { Branch } from 'src/app/core/Models/Branch';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Stocktransferorderdetail } from 'src/app/core/Models/Stocktransferorderdetail';
import { Stocktransferorder } from 'src/app/core/Models/Stocktransferorder';
//Debug
import * as ace from "ace-builds";
import { Stocktransferorderdeailview } from 'src/app/core/Models/Stocktransferorderdetailview';
import { vProductCategorySelect, vProductNameSelect, vProductTypeSelect, vQuantity, vRequestDateSelect, vStockTransferTypeSelect, vToStoreSelect, vUOMSelect, vVariantNameSelect } from 'src/app/core/Validators/validation';
import { StocktransferorderService } from 'src/app/core/Services/inventory/stocktransferorder.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { KeyPress } from 'src/assets/js/KeyPress';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
  selector: 'app-c-stock-transfer-order',
  templateUrl: './c-stock-transfer-order.component.html',
  styleUrls: ['./c-stock-transfer-order.component.css']
})
export class CStockTransferOrderComponent implements OnInit {
  _stocktransferorderform: FormGroup;
  _stocktransferorderdetailform: FormGroup;
  _stocktransferform: FormGroup;
  //Drop Down
  _Tostores: Branch;
  _StockTransferTypes: Metadatum;
  _ProductTypes: Producttype[];
  _ProductCategories: Productcategory[];
  _ProductNames: Productoverallview[] = [];
  _tempproductname: Productoverallview[] = [];
  _Varients: Productvariant[];
  _UomValues: Metadatum[];

  //Grid
  _Productdetails: Stocktransferorderdeailview[] = [];
  _productdetail: Stocktransferorderdeailview;
  SelectedProductDetailsrows: any;

  _Stocktransferid: number;
  _submitted = false;
  _submitted1 = false;
  _action: string = "create";
  _username = sessionStorage["username"];
  _branchname = sessionStorage["currentbranchname"];
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["currentbranchid"];
  _tobranchid: number;
  _StockTransferOrder: Stocktransferorder;
  _StockTransferOrderObj: any;
  _StockId: string;
  isDisabledSave = false;
  isDisabledClear = false;
  isDisabledproductclear = false;
  isDisabledadd = false;
  isDisabledremove = false;
  isDisabledSendtoapproval = true;
  _IsProgressSpinner: boolean = true;
  filteredProducts: any[];
  productcatid: any[] = [];
  producttypeid: any[] = [];
  productuom: any;
  Stock: number;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  minimumDate = new Date();
  _Action: IAction
  hidequantity: boolean = true;
  mindate: Date;
  maxdate: Date;

  //Debug
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _router: Router, private _AccessRightsService: AccessRightsService,
    private utility: CommonUtilities, private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _StockTransferOrderService: StocktransferorderService,
    private _hotkeysService: HotkeysService,
    public keypress: KeyPress) {
    this._Stocktransferid = history.state?.stocktransferid ? history.state?.stocktransferid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();

  }
  InitializeStocktransferForm() {
    this._stocktransferorderform = this.fb.group({
      companyid: [0],
      branchid: [],
      stocktransferid: [0],
      stocktransferno: [],
      stocktransfertype: [],
      orderdate: ['', vRequestDateSelect],
      frombranchid: [],
      tobranchid: ['', vToStoreSelect],
      orderedby: [],
      status: [],
      statusreamrks: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      stocktransferorderdetails: [],
      rv: [],
      deliverystatus: [0],
      isfullydelivered: [],
      transactionincompleted: [1]

    });
    //5. fetch todays date in requested date text box

    if (sessionStorage['Environmentenddate'] == "null") {
      this._stocktransferorderform.get("orderdate").setValue(new Date);
    }
    else {
      let date = new Date(sessionStorage['Environmentenddate'])
      this._stocktransferorderform.get("orderdate").setValue(date);
    }
  }
  InitializeStocktransferorderdetailForm() {
    this._stocktransferorderdetailform = this.fb.group({
      stocktransferorderdetailid: [0],
      stocktransferid: [],
      producttypeid: [],
      productcategoryid: [],
      productid: ['', vProductNameSelect],
      variantid: [],
      uomcode: [],
      requestquantity: [],
      approvedquantity: [],
      deliveredquantity: [],
      unitprice: [],
      amount: [],
      currencyreferenceid: [],
      currencyid: [],
      amountinusd: [],
      isdelivered: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      stock: [],
      currentbranchstock: [],

    });

  }
  InitializeStocktransferform() {
    this._stocktransferform = this.fb.group({
      requestperson: [],
      fromstore: []
    });

    // 11.Load defualt Username of the user in the 'Requested From' text box
    this._stocktransferform.controls['requestperson'].setValue(this._username);
    //10. Load defualt branch of the user in the 'from store' text box
    this._stocktransferform.controls['fromstore'].setValue(this._branchname);
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(96);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeStocktransferForm();
    this.InitializeStocktransferorderdetailForm();
    this.InitializeStocktransferform();


    this._IsProgressSpinner = true;
    this.hidequantity = true;
    this._StockTransferOrderService.PageOnLoad(this._Stocktransferid, this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);

      this._stocktransferform.controls['requestperson'].setValue(this._username);
      this._stocktransferform.controls['fromstore'].setValue(this._branchname);
      // this._stocktransferorderform.get("orderdate").setValue(new Date);
      // 6.these drop downs should be load in pageload 
      this._Tostores = resultJSON.toStore;
      this._StockTransferTypes = resultJSON.stockTransferOrderTypes;
      this._ProductTypes = resultJSON.producttypes;
      // this._ProductNames = <Productoverallview[]>resultJSON.productsearch || [];
      // this._tempproductname = <Productoverallview[]>resultJSON.productsearch || [];
      this._UomValues = resultJSON.uomCodes;
      this._IsProgressSpinner = false;
      console.log(this._action);
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        console.log(updateJSON);
        updateJSON.stocktransferorder.orderdate = new Date(updateJSON.stocktransferorder.orderdate);
        this._stocktransferorderform.setValue(updateJSON.stocktransferorder);
        this._Productdetails = updateJSON.stocktransferorderdeailviews;
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    // 12.save , clear button must be enabled 
    if (this._action == 'view') {
      // 69.every column must be disabled
      this._stocktransferorderform.disable();
      this._stocktransferorderdetailform.disable();
      this._stocktransferform.disable();
      this.isDisabledSave = true;
      this.isDisabledClear = true;
      this.isDisabledadd = true;
      this.isDisabledproductclear = true;
      this.isDisabledremove = true;
    }
    //13. Sent to approve button should be disabled
    if (this._action == 'edit') {
      this.isDisabledSave = false;
      this.isDisabledClear = true;
      this.isDisabledadd = false;
      this.isDisabledproductclear = false;
      this.isDisabledSendtoapproval = true;
    }
  }
  getBranch(event) {
    var FromBranch = parseInt(this._branchid);
    var ToBranch = event.value;
    if (FromBranch == ToBranch) {
      this._CustomExceptionService.handleWarning("Current Branch and Request Branch Should not be same");
      this._stocktransferorderform.controls['tobranchid'].reset();
    }
    this.Clear(event);
    this._Productdetails = [];
  }
  CheckQuantity(event) {
    var quantity = this._stocktransferorderdetailform.get("requestquantity").value;
    var stock = this._stocktransferorderdetailform.get("stock").value;
    this.Stock = stock;
    if (stock < quantity) {
      this._CustomExceptionService.handleError(usererrors.StockCheck);
      return;
    }
  }
  onchangereceivedquantity(row: any, event) {
    var selectedindex = this._Productdetails.findIndex(obj => obj == row);
    var quantity = this._Productdetails[selectedindex].requestquantity;
    if (this.Stock < quantity) {
      this._CustomExceptionService.handleError(usererrors.StockCheck);
      this._Productdetails[selectedindex].requestquantity = 0;
      return;
    }
  }
  //Add Products
  AddProduct(event) {
    this._submitted1 = true;
    if (this._stocktransferorderdetailform.valid) {
      var quantity = this._stocktransferorderdetailform.get("requestquantity").value;
      var stock = this._stocktransferorderdetailform.get("stock").value;
      if (stock < quantity) {
        this._CustomExceptionService.handleError(usererrors.StockCheck);
        return;
      }
      else {
        var Productdetail = new Stocktransferorderdeailview();
        Productdetail.stocktransferorderdetailid = 0;
        Productdetail.stocktransferid = this._stocktransferorderform.get("stocktransferid").value;
        var productid = parseInt(this._stocktransferorderdetailform.get("productid").value.productid);
        var productName = (this._ProductNames || []).filter(f => f.productid == productid)[0].productname;
        var uom = (this._ProductNames || []).filter(f => f.productid == productid)[0].salesuom;

        Productdetail.productid = productid;
        Productdetail.productname = productName;
        if (this._stocktransferorderdetailform.get("variantid").value == "" || this._stocktransferorderdetailform.get("variantid").value == null) {
          Productdetail.variantid = 0;
          Productdetail.variantdescription = "";
        }
        else {
          var variantid = this._stocktransferorderdetailform.get("variantid").value;
          var variantName = (this._Varients || []).filter(f => f.productvariantdetailid == variantid)[0].variantdescription;
          Productdetail.variantid = variantid;
          Productdetail.variantdescription = variantName;
        }
        Productdetail.requestquantity = this._stocktransferorderdetailform.get("requestquantity").value;
        Productdetail.approvedquantity = 0;
        Productdetail.deliveredquantity = 0;
        // var uomcode = this._stocktransferorderdetailform.get("uomcode").value;
        var uomName = (this._UomValues || []).filter(f => f.metasubcode == uom)[0].metasubdescription;
        Productdetail.uomcode = uom;
        Productdetail.uomname = uomName;
        Productdetail.unitprice = 0;
        Productdetail.amount = 0;
        Productdetail.currencyid = 0;
        Productdetail.currencyreferenceid = "0";
        Productdetail.amountinusd = 0;
        Productdetail.isdelivered = true;
        Productdetail.accountingyear = new Date().getFullYear();
        Productdetail.createdby = sessionStorage["userid"];
        Productdetail.createdon = new Date();
        // this._Productdetails.push(Productdetail);
        this.Clear(event);
        this._submitted1 = false;
        if (this._Productdetails.length > 0) {
          for (var i = 0; i < this._Productdetails.length; i++) {
            if (Productdetail.productid == this._Productdetails[i].productid && Productdetail.variantid == this._Productdetails[i].variantid) {
              console.log("matched");
              this._CustomExceptionService.handleWarning("Product or Variant Already Exists!");
              return;
            }
          }
          this._Productdetails.push(Productdetail);

        } else {
          this._Productdetails.push(Productdetail);
        }
      }
    }

  }
  // 30.Clear All the columns in the product details Section
  Clear(event) {
    this._submitted1 = true;
    this._stocktransferorderdetailform.reset();
    this.InitializeStocktransferorderdetailForm();
    this._ProductCategories = [];
    this.productcatid = [];
    this.producttypeid = [];
    this._Varients = [];
    this._submitted1 = false;

  }

  //Remove Products
  RemoveProduct(pProduct: Stocktransferorderdeailview) {
    var index = this._Productdetails.indexOf(pProduct);
    this._Productdetails.splice(index, 1)
  }
  // 1.product category load from product product category master
  getProductcategory(event) {
    var selectedProductType = event.value;
    var branchid = this._stocktransferorderform.get("tobranchid").value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {

      this._stocktransferorderdetailform.controls["productcategoryid"].reset();
      this._ProductCategories = [];
      return;
    }
    else if (branchid == null || branchid == 0) {
      this._stocktransferorderdetailform.controls["producttypeid"].reset();
      this._CustomExceptionService.handleWarning("Please select to store");
      this._IsProgressSpinner = false;
      return;
    } else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": branchid || 0 };
      this._StockTransferOrderService.getProductCategories(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this._ProductCategories = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    let productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
    this._ProductNames = productList.length ? productList : this._tempproductname;
  }
  // 2.product name load from product master(product code, product name and varient)
  getProductname(event) {
    var selectedProductCategory = event.value;
    var branchid = this._stocktransferorderform.get("tobranchid").value;
    console.log(selectedProductCategory);
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._stocktransferorderdetailform.reset();
      return;
    }
    else if (branchid == null || branchid == 0) {
      this._stocktransferorderdetailform.controls["productcategoryid"].reset();
      this._CustomExceptionService.handleWarning("Please select to store");
      this._IsProgressSpinner = false;
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": branchid };
      this._StockTransferOrderService.getProducts(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        // this._ProductNames = resultJSON.products;
        this._ProductNames = <Productoverallview[]>resultJSON.productsearch || [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    let productList = [];
    if (this.productcatid.length) {
      productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid) && this.productcatid.includes(f.productcategoryid)) || [];
    } else {
      productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
    }
    this._ProductNames = productList;
  }
  // 17.on selecting the product name, the Varients should fetch in the Varient drop down based on the Product name 
  getProductVariants(event) {
    var selectedProductVariant = event.productid;
    console.log(selectedProductVariant);
    var variantid = this._stocktransferorderdetailform.get("variantid").value || 0;
    var branchid = this._stocktransferorderform.get("tobranchid").value;
    if (branchid == null || branchid == 0) {
      this._CustomExceptionService.handleWarning("Please select to store");
      this._stocktransferorderdetailform.controls["productid"].reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._StockTransferOrderService.getProductVariants(selectedProductVariant, variantid, branchid, this._branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this._Varients = resultJSON.productvariants;
        this._ProductTypes = resultJSON.producttypes;
        this._ProductCategories = resultJSON.productCategoriesList;
        let product = this._tempproductname.filter(f => f.productid == selectedProductVariant)[0];
        this.producttypeid = [];
        this.producttypeid.push(product.producttypeid);
        this.productcatid = [];
        this.productcatid.push(product.productcategoryid);
        this._stocktransferorderdetailform.controls['producttypeid'].setValue(this.producttypeid);
        this._stocktransferorderdetailform.controls['productcategoryid'].setValue(this.productcatid);
        this.productuom = "";
        this.productuom = (product.salesuom);

        if (this.productuom == "MSC00037") {
          this.hidequantity = true;
        }
        else {
          this.hidequantity = false
        }

        // To Branch Stock

        if (resultJSON.stock == null) {
          this._stocktransferorderdetailform.controls['stock'].setValue(0);
          this._IsProgressSpinner = false;
          return;
        }
        this._stocktransferorderdetailform.controls['stock'].setValue(resultJSON.stock.stock1);

        // From Branch Stock

        if (resultJSON.currentBranchStock == null) {
          this._stocktransferorderdetailform.controls['currentbranchstock'].setValue(0);
          this._IsProgressSpinner = false;
          return;
        }

        this._stocktransferorderdetailform.controls['currentbranchstock'].setValue(resultJSON.currentBranchStock.stock1);
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }

  }

  getProductstock(event) {
    var variant = event.value;
    var branchid = this._stocktransferorderform.get("tobranchid").value;
    var productid = this._stocktransferorderdetailform.get("productid").value.productid;
    this._IsProgressSpinner = true;
    if (branchid == null || branchid == 0) {
      this._stocktransferorderdetailform.controls["producttypeid"].reset();
      this._stocktransferorderdetailform.controls["productid"].reset();
      this._CustomExceptionService.handleWarning("Please select to store");
      return;
    }
    else {
      this._StockTransferOrderService.getProductVariants(productid, variant, branchid, this._branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        // this._Varients = resultJSON.productvariants;
        this._ProductTypes = resultJSON.producttypes;
        this._ProductCategories = resultJSON.productcategories;
        let product = this._tempproductname.filter(f => f.productid == productid)[0];
        this.producttypeid = [];
        this.producttypeid.push(product.producttypeid);
        this.productcatid = [];
        this.productcatid.push(product.productcategoryid);
        this.productuom = "";
        this.productuom = (product.salesuom);
        this._stocktransferorderdetailform.controls['producttypeid'].setValue(this.producttypeid);
        this._stocktransferorderdetailform.controls['productcategoryid'].setValue(this.productcatid);
        if (resultJSON.stock == null) {
          this._stocktransferorderdetailform.controls['stock'].setValue(0);
          this._IsProgressSpinner = false;
          return;
        }
        this._stocktransferorderdetailform.controls['stock'].setValue(resultJSON.stock.stock1);

        this._IsProgressSpinner = false;

      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }

  }
  filterProducts(event) {
    var branchid = this._stocktransferorderform.get("tobranchid").value;
    if (branchid == null || branchid == 0) {
      this._stocktransferorderdetailform.controls["producttypeid"].reset();
      this._stocktransferorderdetailform.controls["productid"].reset();
      this._CustomExceptionService.handleWarning("Please select to store");
      return;
    }
    if (event.query == "" || event.query == null) {
      return;
    }
    this._IsProgressSpinner = true;
    this._StockTransferOrderService.GetProductSearch(event.query, this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._ProductNames = <Productoverallview[]>resultJSON.productsearchlist || [];
      this._tempproductname = <Productoverallview[]>resultJSON.productsearchlist || [];
      let filtered: any[] = [];
      let query = (<string>event.query).toLowerCase();
      this.filteredProducts = this._ProductNames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }


  onSave() {
    console.log("Submitted");
    console.log(this._action);
    this._submitted = true;
    //35. if there is no record in the product details, show error as "Please add atleast one row in the  products grid"
    if (!this._Productdetails || this._Productdetails.length == 0) {
      this._CustomExceptionService.handleError(usererrors.ProductDetails);
      return;
    }
    if (this._stocktransferorderform.valid) {
      var StockTransferDate = this._stocktransferorderform.get("orderdate").value;
      var datePipe = new DatePipe("en-US");
      var pStockTransferDate = datePipe.transform(StockTransferDate, 'yyyy-MM-dd');
      //For Model and Form Mapping
      this._StockTransferOrder = this._stocktransferorderform.value;
      this._StockTransferOrder.orderdate = (new Date(pStockTransferDate));


      this._StockTransferOrderObj = { "Stocktransferorder": this._StockTransferOrder, "Stocktransferorderdetail": this._Productdetails };
      if (this._action == 'create') {
        this._StockTransferOrder.createdby = sessionStorage["userid"];
        this._StockTransferOrder.createdon = new Date();
        this._StockTransferOrder.modifiedby = sessionStorage["userid"];
        this._StockTransferOrder.modifiedon = new Date();
        this._StockTransferOrder.accountingyear = new Date().getFullYear();
        this._StockTransferOrder.branchid = sessionStorage["BranchID"];
        this._StockTransferOrder.frombranchid = this._branchid;
        this._StockTransferOrder.orderedby = sessionStorage["userid"];
        this._StockTransferOrder.stocktransfertype = "MSC00185";
        // this.onEd();
        this._IsProgressSpinner = true;
        this._StockTransferOrderService.create(this._StockTransferOrderObj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          console.log(result);
          console.log(resultJSON);
          this._StockId = resultJSON.stockId;
          this._tobranchid = resultJSON.branchId;
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
            // 65.  then enable the "sent to approve" button
            this.isDisabledSendtoapproval = false;
            this._IsProgressSpinner = false;
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          } this._IsProgressSpinner = false;
        },

          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }

      else if (this._action == 'edit') {
        this._StockTransferOrder.modifiedon = new Date();
        this._StockTransferOrder.modifiedby = sessionStorage["userid"];
        this._StockTransferOrder.frombranchid = sessionStorage["BranchID"];
        this._StockTransferOrder.orderedby = sessionStorage["userid"];
        // this.onEd();
        this._IsProgressSpinner = true;
        this._StockTransferOrderService.edit(this._StockTransferOrderObj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._StockId = resultJSON.stockId;
          this._tobranchid = resultJSON.branchId;
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this.isDisabledSendtoapproval = false;
            this._IsProgressSpinner = false;
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },

          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }

    }


  }
  // 49.clear all the fields which the data has entered

  reset(event) {
    this._submitted = true;
    this._stocktransferorderform.reset();
    this._Productdetails = [];
    this.InitializeStocktransferForm();
    this.Clear(event);
    this._submitted = false;
  }
  //Debug
  // onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._StockTransferOrderObj;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }

  // 78.  on clicking back button , it Navigates to the search screen
  goBack(event) {
    //alert('This');
    console.log("goBack");
    this._router.navigate(['/vStockTransferOrder']);
  }
  //Send to approval
  // 46.if the approval process is correct, then show success message as "Sent to Approved successfully"
  SendToApproval(event) {
    var workflowid = 8;
    this._IsProgressSpinner = true;
    this._StockTransferOrderService.SendToApproval(workflowid, this._userid, this._tobranchid, this._StockId).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._CustomExceptionService.handleSuccess(usererrors.StockTransferOrderSendToApproval);
      this._IsProgressSpinner = false;
      this.isDisabledSendtoapproval = true;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}