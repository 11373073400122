import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ProductRequestService } from 'src/app/core/Services/inventory/product-request.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
	selector: 'app-v-product-request',
	templateUrl: './v-product-request.component.html',
	styleUrls: ['./v-product-request.component.css']
})
export class VProductRequestComponent implements OnInit {
	fromdate: Date;
	_IsProgressSpinner: boolean = false
	todate: Date;
	_userid: any;
	_roleid: any;
	_branchid: any;
	_View: boolean
	_Update: boolean
	_Delete: boolean
	datepipe = new DatePipe('en-US');
	_MatriealRequst: any[] = [];
	_Action: IAction
	mindate: Date;
	maxdate: Date;

	constructor(
		private router: Router,
		private utility: CommonUtilities,
		public productRequestService: ProductRequestService,
		private _CustomExceptionService: CustomExceptionService,
		private confirmationService: ConfirmationService,
		private exportUtility: ExportUtility,
		private _AccessRightsService: AccessRightsService

	) { }
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(343);
		this.utility.pageLoadScript();
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		if (sessionStorage['Environmentenddate'] == "null") {
			this.fromdate = new Date();
			this.todate = new Date();
			this.maxdate = new Date();
		}
		else {
			this.fromdate = new Date(sessionStorage['Environmentenddate']);
			this.todate = new Date(sessionStorage['Environmentenddate']);
		}
		this.fromdate.setDate(this.fromdate.getDate() - 7);
		this.Fetchall()
	}
	Fetchall() {
		if (!this.fromdate) {
			this._CustomExceptionService.handleError("Please Select From Date");
			return;
		  }
		  if (!this.todate) {
			this._CustomExceptionService.handleError("Please Select To Date");
			return;
		  }
		let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
		let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
		this._branchid = parseInt(sessionStorage["currentbranchid"]);
		if (fromdate > todate) {
			this._CustomExceptionService.handleError("From Date should be less than the To Date");
			return;
		}
		this._userid = sessionStorage["userid"];
		this._roleid = sessionStorage["currentRole"];
		this._IsProgressSpinner = true;
		this.productRequestService.FetchAll(this._branchid, this._userid, this._roleid, fromdate, todate).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._MatriealRequst = resultJSON.materialrequestList;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	GoToCreate(event) {
		this.router.navigate(['/cProductRequest']);
	}
	confirm(event) {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to cancel this Product Request  <b>"' + event.materialrequestno + '"</b> ?',
			accept: () => {
				this.Cancel(event);
			}
		});

	}
	Cancel(event: any) {
		this._IsProgressSpinner = true;
		this.productRequestService.changestatus(event.materialrequestid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this._IsProgressSpinner = false;
			this.ngOnInit()
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	partialconfirm(event) { }
	view(event) {
		this.router.navigate(['/cProductRequest'], { state: { materialrequestid: event.materialrequestid, action: 'view' } });
	}
	edit(event) {
		this.router.navigate(['/cProductRequest'], { state: { materialrequestid: event.materialrequestid, action: 'edit' } });
	}
	SendToApproval(event) {
		var workflowid = 62;
		this._IsProgressSpinner = true;
		this.productRequestService.SendToApproval(workflowid, this._userid, event.materialrequestno).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this._CustomExceptionService.handleSuccess(usererrors.Approved_01);
			// this._CustomExceptionService.handleSuccess(usererrors.StockTransferOrderSendToApproval);
			this._IsProgressSpinner = false;
			this.ngOnInit()
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	exportExcel() {
		let StockDeliveryList = this.GetStockDeliveryList();
		this.exportUtility.exportExcel(StockDeliveryList, 'Product_Request', 'xlsx');
	}
	ExportToPdf() {
		let StockDeliveryList = this.GetStockDeliveryList();
		this.exportUtility.ExportToPdf(StockDeliveryList, 'Product_Request.pdf');
	}
	GetStockDeliveryList() {
		let StockDeliveryList = [];
		this._MatriealRequst.forEach(element => {
			let StockdeliveryList: any = {};
			StockdeliveryList["Product Request No"] = element.materialrequestno;
			StockdeliveryList["Product Request Date"] = this.datepipe.transform(element.materialrequestdate, 'dd/MM/yyyy');
			StockdeliveryList["Status"] = element.statusname;
			StockdeliveryList["Created By"] = element.createdbyname;
			StockdeliveryList["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
			StockDeliveryList.push(StockdeliveryList);
		});
		return StockDeliveryList;
	}
	Print(event) {
		let Data = {
			"MaterialRequestNo": event.materialrequestno,
			"MaterialRequestId": event.materialrequestid,
			"BranchId": this._branchid
		}
		this._IsProgressSpinner = true;
		this.productRequestService.Print(Data).subscribe((result: any) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			let url: any = URL.createObjectURL(result);
			window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
}