export class Creditnotedetail {
        creditnotedetailid: number;
        creditnoteid: number;
        invoiceno: string;
        invoicedate: Date ;
        productname : string;
        productid: number;
        quantity: number;
        returnquantity: number;
        unitprice: number;
        totalamount: number;
        creditamount: number;
        reason: string;
        taxPer: number;
        taxAmount: number ;
        taxId: number ;
        discountPer: number ;
        discountAmount: number ;
        productamount:number;
        returnreason:string;
    }