<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Banner</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" [disabled]= "isDisabledSave" class="" (click)="onSave()"></button>
                            <button pButton pRipple label="" title="Clear" type="button" [disabled]="disablereset" icon="pi pi-trash" class="" (click)="reset()"
                                class="p-button-danger"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search" [routerLink]="['/vslidermanagement']"
                                class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_SliderManagementform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="slidename">
                                    <label for="slidename">Slider Name <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width:20%;">
                                <!-- <div class="p-inputgroup">
                                    <div class="file-upload-wrapper" data-text="Select your file!">
                                        <input name="file-upload-field" type="file" class="file-upload-field" (change)="fileChangeEvent($event)" value=""
                                        >
                                    </div> -->
                                    <div class="p-inputgroup" *ngIf="_Iscreatefileupload">
                                        <div class="file-upload-wrapper" data-text="Select your file!">
                                            <!-- <input name="file-upload-field" type="file" class="file-upload-field"
                                                (change)="fileChangeEvent($event)" value=""> -->
                                                <input name="file-upload-field" type="file" accept="image/png, image/gif, image/jpeg" class="file-upload-field"
                                                (change)="selectFile($event)" value="">
                                        </div>
                                    </div>
                                    <div class="p-inputgroup" *ngIf="_Isviewfileupload">
                                        <span class="p-float-label">
                                            <div class="browse-links">
                                                <p-button label="Slide" styleClass="p-button-link"
                                                    title="{{imageselect}}" (click)="showImageDialog('bottom-right')">
                                                </p-button>
                                            </div>
                                        </span>
                                        <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button" [disabled]="clearimage"
                                            (click)="onImageClear()" tooltipPosition="top">
                                            <i class="pi pi-times"></i>
                                        </button>
                                    </div>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_status"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="status">
                                    </p-dropdown>
                                    <label for="status"> Status <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                        
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table> 
        </div>
    </div>
</div>
<p-dialog header="Image Cropper" [(visible)]="imageCropperTool" [modal]="true" [style]="{width: '90%'}" [baseZIndex]="10000">
    <div class="text-center mb10">
        <button pButton pRipple label="" icon="las la-plus" pTooltip="Zoom +" tooltipPosition="top" (click)="zoomIn()" class="p-button p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-minus" pTooltip="Zoom -" tooltipPosition="top"   (click)="zoomOut()" class="p-button p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-redo-alt" pTooltip="Reset Image" tooltipPosition="top" (click)="resetImage()" class="p-button-danger p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-save" pTooltip="Save" tooltipPosition="top" (click)="saveImage($event)" class="p-button-success p-mr-2"></button>
    </div>
    <div class="row">
        <div class="col-md-3">
            <h5>Input</h5>
            <div class="crop-input">
                <image-cropper class="img-fluid"
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true"
                    [containWithinAspectRatio]="containWithinAspectRatio"
                    [aspectRatio]="80 / 27"
                    [resizeToWidth]="1600"
                    [cropperMinWidth]="128"
                    [onlyScaleDown]="true"
                    [roundCropper]="false"
                    [canvasRotation]="canvasRotation"
                    [transform]="transform"
                    [alignImage]="'left'"
                    [style.display]="showCropper ? null : 'none'"
                    format="png"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()"
                    (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed()"
                ></image-cropper>
            </div>        
        </div>
        <div class="col-md-9">
            <h5>Output</h5>
            <img [src]="croppedImage"  />
        </div>
    </div>
</p-dialog>
<p-dialog header="Slide" [(visible)]="displayimagepopup" [position]="position1" [style]="{width: '90%'}"
    [baseZIndex]="10000">
    <img [src]="slidermanagement" alt="" class="img-preview">

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="showPopupDialog()" label="Ok" styleClass="p-button-text">
        </p-button>
    </ng-template>
</p-dialog>