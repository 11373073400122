export class Purchaseorderpaymentterm {
    purchaseorderpaymentdetailid: number;
    purchaseorderid: number;
    termstype: string;
    termsandconditionid: number;
    termdescription: string;
    recordstatus: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;

 
}