export class Couponincludecategorydetail {
    coupondetailid: number;
    couponid: number;
    includeproductategoryid: number;
    createdby: number;
    createdon: Date ;
    modifiedby: number ;
    modifiedon: Date ;

   
}