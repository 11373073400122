<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Stock Adjustment Report</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptstockadjustmentform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy"[minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="fromdate">From Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                        yearRange="1980:2030" dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="todate">To Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchid"
                                        [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="producttypeid">Branch Name </label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_producttypelist" optionLabel="producttypename"
                                        optionValue="producttypeid" [(ngModel)]="producttypeid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProductCategory($event)"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type Name </label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                                        [selectionLimit]="50" [(ngModel)]="productcatid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProduct($event)"
                                        optionValue="productcategoryid"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category Name</label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productlist" optionLabel="productname"
                                        optionValue="productid" [(ngModel)]="productid"
                                        [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" icon="pi pi-search" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel1()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>

                    <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="500" [paginator]="true" [rowsPerPageOptions]="[100,200,300]" [rowsPerPageOptions]="[500,1000,1500,2000]" [globalFilterFields]="['stocktakeno','branchname','productname','lotnumber','brandname','closingstock','actualstock','damagedstock','stockdifference','amount','createdbyname']"
                        [rowHover]="true" dataKey="productname" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="stocktakeno">Stock Adjustment No
                                    <p-sortIcon field="stocktakeno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="stocktakedate">Stock Adjustment Date
                                    <p-sortIcon field="stocktakedate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="branchname">Branch Name
                                    <p-sortIcon field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="lotnumber">Lot Number
                                    <p-sortIcon field="lotnumber">
                                    </p-sortIcon>
                                    <th pSortableColumn="closingstock">Closing Stock
                                        <p-sortIcon field="closingstock">
                                        </p-sortIcon>
                                        <th pSortableColumn="actualstock">Actual Stock
                                            <p-sortIcon field="actualstock">
                                            </p-sortIcon>
                                            <th pSortableColumn="damagedstock">Damaged Stock
                                                <p-sortIcon field="damagedstock">
                                                </p-sortIcon>
                                                <th pSortableColumn="stockdifference">Stock Difference
                                                    <p-sortIcon field="stockdifference">
                                                    </p-sortIcon>
                                                    <th pSortableColumn="amount">Amount
                                                        <p-sortIcon field="amount">
                                                        </p-sortIcon>
                                                        <th pSortableColumn="createdbyname">Created By
                                                            <p-sortIcon field="createdbyname">
                                                            </p-sortIcon>
                                                        </th>


                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 60px;">
                                    {{i+ 1}}
                                </td>
                                <td>
                                    {{_ReportList.stocktakeno}}
                                </td>
                                <td>
                                    {{_ReportList.stocktakedate | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_ReportList.branchname}}
                                </td>
                                <td>
                                    {{_ReportList.productname}}
                                </td>
                                <td>
                                    {{_ReportList.lotnumber}}
                                </td>
                                <td>
                                    {{_ReportList.closingstock}}
                                </td>
                                <td>
                                    {{_ReportList.actualstock}}
                                </td>
                                <td>
                                    {{_ReportList.damagedstock}}
                                </td>
                                <td>
                                    {{_ReportList.stockdifference}}
                                </td>
                                <td>
                                    {{_ReportList.amount}}
                                </td>
                                <td>
                                    {{_ReportList.createdbyname}}
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>

</div>
<p-toast position="bottom-right"></p-toast>