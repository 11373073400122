import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { AccountclosingService } from 'src/app/core/Services/accounts/accountclosing.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccountClosureView } from 'src/app/core/Views/AccountClosingview';

import { CommonUtilities } from 'src/assets/js/common-utilities';
@Component({
  selector: 'app-v-account-closing',
  templateUrl: './v-account-closing.component.html',
  styleUrls: ['./v-account-closing.component.css']
})
export class VAccountClosingComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Action: IAction;
  _userid = sessionStorage["userid"];
  _accountsclosingview: AccountClosureView;
  _accountclosingview: AccountClosureView;
  _IsProgressSpinner: boolean = true;
  constructor(private fb: FormBuilder, private _AccountClosingService: AccountclosingService, private router: Router, private utility: CommonUtilities,
    private confirmationService: ConfirmationService, private _hotkeysService: HotkeysService, private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService) {

  }


  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(76);
    this._IsProgressSpinner = true;
    this.HotKeyIntegration();
    this._AccountClosingService.FetchAll(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accountsclosingview = resultJSON.headerList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  view(event) {
    this.router.navigate(['/cAccountClosing'], { state: { id: event.id, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cAccountClosing']);
  }
  delete(event) {
    alert("delete");
  }


  Cancel(event) {
    var id = event.id;
    this._IsProgressSpinner = true;
    this._AccountClosingService.Cancel(id, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  confirm(event) {
    let date = new Date(event.month);
    let month = date.getMonth() + " " + date.getFullYear();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Accounting Month <b>("' + month + '")</b> ? Kindly make sure if you cancel this previous month it will not be accessible in your next month.',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
}
