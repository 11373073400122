<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Purchase Tracking Report</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptbranchwisestockform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%">
                                <span class="p-float-label">
                                  <p-calendar
                                    formControlName="fromdate"
                                    [showIcon]="true"
                                    [monthNavigator]="true"
                                    [yearNavigator]="true"
                                    yearRange="1980:2090"
                                    dateFormat="dd/mm/yy"
                                    [minDate]="mindate"
                                    [maxDate]="maxdate"
                                  >
                                  </p-calendar>
                                  <label for="fromdate">From Date</label>
                                </span>
                              </td>
                              <td style="width: 20%">
                                <span class="p-float-label">
                                  <p-calendar
                                    formControlName="todate"
                                    [showIcon]="true"
                                    [monthNavigator]="true"
                                    [yearNavigator]="true"
                                    yearRange="1980:2090"
                                    dateFormat="dd/mm/yy"
                                    [minDate]="mindate"
                                    [maxDate]="maxdate"
                                  >
                                  </p-calendar>
                                  <label for="todate">To Date</label>
                                </span>
                              </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchid"
                                        [ngModelOptions]="{standalone: true}" [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="producttypeid">Branch Name </label>
                                </span>
                            </td>
                            <td>
                              <span class="p-float-label">
                                <p-multiSelect
                                  [filter]="false"
                                  [options]="_SupplierFliter"
                                  optionLabel="suppliername"
                                  optionValue="supplierid"
                                  [(ngModel)]="supplierid"
                                  [ngModelOptions]="{ standalone: true }"
                                  displaySelectedLabel="true"
                                  [filter]="true"
                                  filterBy="suppliername"
                                  [virtualScroll]="true"
                                  itemSize="30"
                                >
                                </p-multiSelect>
                                <label>Supplier Name</label>
                              </span>
                            </td>
                            
                            <td></td>

                        </tr>

                        <tr>
                            <td>
                                <button pButton pRipple label="Search" icon="pi pi-search"
                                    (click)="GenerateReport()" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)"
                                    class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_purchasetrackingreports" [scrollable]="true" scrollHeight="450px" [rows]="10"
                        [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['PURCHASEORDERNO','PURCHASEORDERDATE','BRANCHNAME','SUPPLIERNAME','CREATEDBY']"
                        [rowHover]="true" dataKey="productname" [columns]="cols"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="PURCHASEORDERNO">Purchase Order No
                                    <p-sortIcon field="PURCHASEORDERNO">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="PURCHASEORDERDATE">Purchase Order Date
                                    <p-sortIcon field="PURCHASEORDERDATE">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="BRANCHNAME">Branch Name
                                    <p-sortIcon field="BRANCHNAME">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="SUPPLIERNAME">Supplier Name
                                    <p-sortIcon field="SUPPLIERNAME">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="CREATEDBY">Created by
                                    <p-sortIcon field="CREATEDBY">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_purchasetrackingreport let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 60px;">
                                    {{i+ 1}}
                                </td>
                                <td><a href="javascript:void(0);" (click)="GetPurchaseTrackingDetails(_purchasetrackingreport)"
                                >
                                    {{_purchasetrackingreport.PURCHASEORDERNO}}</a>
                                </td>
                                <td>
                                    {{_purchasetrackingreport.PURCHASEORDERDATE | date : "dd/MM/yyyy"}}
                                </td>
                                <td>
                                    {{_purchasetrackingreport.BRANCHNAME}}
                                </td>
                                <td>
                                    {{_purchasetrackingreport.SUPPLIERNAME}}
                                </td>
                                <td>
                                    {{_purchasetrackingreport.CREATEDBY}}
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-dialog
                header="Purchase Tracking Details"
                [(visible)]="_inwardwise"
                [modal]="true"
                [style]="{ width: '1000px', height: '500px' }"
                [baseZIndex]="10000"
              >
                <ng-template pTemplate="content">
                  <div class="popup-body">
                    <div class="card">
                      <p-toolbar styleClass="p-mb-4">
                          <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                              <i class="pi pi-search"></i>
                              <input
                                pInputText
                                type="text"
                                (input)="Tracking.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..."
                              />
                            </span>
                          </ng-template>
                          <ng-template pTemplate="right">
                            <button
                              type="button"
                              pButton
                              pRipple
                              icon="pi pi-file-o"
                              title="Export CSV"
                              (click)="exportExcelfortracking()"
                              class="p-mr-2"
                              pTooltip="CSV"
                              tooltipPosition="bottom"
                            ></button>
                            <button
                              type="button"
                              pButton
                              pRipple
                              icon="pi pi-file-excel"
                              title="Export Excel"
                              class="p-button-success p-mr-2"
                              pTooltip="XLS"
                              tooltipPosition="bottom"
                              (click)="exportExcelfortracking()"
                            ></button>
                            <button
                              type="button"
                              pButton
                              pRipple
                              icon="pi pi-file-pdf"
                              title="Export PDF"
                              class="p-button-warning p-mr-2"
                              pTooltip="PDF"
                              tooltipPosition="bottom"
                              (click)="ExportToPdffortracking()"
                            ></button>
                          </ng-template>
                        </p-toolbar>
                      <p-table
                        #Tracking
                        [value]="_Purchasetrackingdetails"
                        [rows]="10"
                        [paginator]="true"
                        [rowsPerPageOptions]="[10, 25, 50, 100]"
                        [globalFilterFields]="['PURCHASEORDERNO','INWARDNO','STOCKINWARDCREATEDBY','GRNNO','GRNCREATEDBY','PURCHASEINVOICENO','PURCHASEINVOICECREATEDBY','PAYMENTVOUCHERNO','PAYMENTCREATEDBY']"
                        [rowHover]="true"
                        dataKey="PURCHASEORDERNO"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true"
                        [scrollable]="true"
                        
                      >
                        <ng-template pTemplate="header">
                          <tr>
                            <th class="text-center" style="width: 60px">S.No</th>
                            
                            <th pSortableColumn="INWARDNO" style="width: 100px;">
                              SI No
                              <p-sortIcon field="INWARDNO"> </p-sortIcon>
                            </th>
                            <th pSortableColumn="INWARDDATE" style="width: 100px;">
                              SI Date
                              <p-sortIcon field="INWARDDATE"> </p-sortIcon>
                            </th>
                            <th pSortableColumn="STOCKINWARDCREATEDBY" style="width: 140px;">
                              SI Created By 
                              <p-sortIcon field="STOCKINWARDCREATEDBY"> </p-sortIcon>
                            </th>
                            <th pSortableColumn="GRNNO" style="width: 100px;">
                              Grn No
                              <p-sortIcon field="GRNNO"> </p-sortIcon>
                            </th>
                            <th pSortableColumn="GRNDATE" style="width: 100px;">
                              Grn Date
                              <p-sortIcon field="GRNDATE"> </p-sortIcon>
                            </th>
                            <th pSortableColumn="GRNCREATEDBY" style="width: 140px;">
                              Grn CreatedBy
                              <p-sortIcon field="GRNCREATEDBY"> </p-sortIcon>
                            </th>
                            <th pSortableColumn="PURCHASEINVOICENO" style="width: 100px;">
                              PI No
                              <p-sortIcon field="PURCHASEINVOICENO"> </p-sortIcon>
                            </th>
                            <th pSortableColumn="PURCHASEINVOICEDATE" style="width: 100px;">
                              PI Date
                              <p-sortIcon field="PURCHASEINVOICEDATE"> </p-sortIcon>
                            </th>
                            <th pSortableColumn="PURCHASEINVOICECREATEDBY" style="width: 140px;">
                              PI Created By
                              <p-sortIcon field="PURCHASEINVOICECREATEDBY"> </p-sortIcon>
                            </th>
                            <th pSortableColumn="PAYMENTVOUCHERNO" style="width: 140px;">
                              Pay Voucher No
                              <p-sortIcon field="PAYMENTVOUCHERNO"> </p-sortIcon>
                            </th>
                            <th pSortableColumn="PAYMENTDATE" style="width: 100px;">
                              Pay Date
                              <p-sortIcon field="PAYMENTDATE"> </p-sortIcon>
                            </th>
                            <th pSortableColumn="PAYMENTCREATEDBY" style="width: 140px;">
                              Pay Created By
                              <p-sortIcon field="PAYMENTCREATEDBY"> </p-sortIcon>
                            </th>
                          </tr>
                        </ng-template>
                        <ng-template
                          pTemplate="body"
                          let-rowData
                          let-_Purchasetrackingdetail
                          let-i="rowIndex"
                        >
                          <tr>
                            <td class="text-center" style="width: 60px">
                              {{ i + 1 }}
                            </td>
                            
                            <td style="width: 100px;">
                              {{
                                _Purchasetrackingdetail.INWARDNO }}
                            </td>
                            <td style="width: 100px;">
                              {{ _Purchasetrackingdetail.INWARDDATE | date : "dd/MM/yyyy" }}
                            </td>
                            <td style="width: 140px;">
                              {{ _Purchasetrackingdetail.STOCKINWARDCREATEDBY }}
                            </td>
                            <td style="width: 100px;">
                              {{ _Purchasetrackingdetail.GRNNO }}
                            </td>
                            <td style="width: 100px;">
                              {{ _Purchasetrackingdetail.GRNDATE | date : "dd/MM/yyyy" }}
                            </td>
                            <td style="width: 140px;">
                              {{ _Purchasetrackingdetail.GRNCREATEDBY }}
                            </td>
                            <td style="width: 100px;">
                              {{ _Purchasetrackingdetail.PURCHASEINVOICENO }}
                            </td>
                            <td style="width: 100px;">
                              {{ _Purchasetrackingdetail.PURCHASEINVOICEDATE | date : "dd/MM/yyyy"}}
                            </td>
                            <td style="width: 140px;">
                              {{ _Purchasetrackingdetail.PURCHASEINVOICECREATEDBY }}
                            </td>
                            <td style="width: 140px;">
                              {{ _Purchasetrackingdetail.PAYMENTVOUCHERNO }}
                            </td>
                            <td style="width: 100px;">
                              {{ _Purchasetrackingdetail.PAYMENTDATE | date : "dd/MM/yyyy" }}
                            </td>
                            <td style="width: 140px;">
                              {{ _Purchasetrackingdetail.PAYMENTCREATEDBY }}
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </div>
                  </div>
                </ng-template>
              </p-dialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>