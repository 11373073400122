import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { PaymentService } from 'src/app/core/Services/accounts/payment.service';
import { RptpaymentService } from 'src/app/core/Services/reports/Accounting/RptPayment/rptpayment.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Paymentview } from 'src/app/core/Views/Paymentview';

import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-payment',
  templateUrl: './v-payment.component.html',
  styleUrls: ['./v-payment.component.css']
})
export class VPaymentComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Action: IAction;;
  _paymentsview: Paymentview[];
  _paymentview: Paymentview;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"];
  _series = sessionStorage["Series"];
  _IsProgressSpinner: boolean = true;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  cols: any[];
  _roleid = sessionStorage["currentRole"]
  mindate: Date;
  maxdate: Date;
  // _Action:IAction;
  constructor(private fb: FormBuilder,
    private _PaymentService: PaymentService,
    public exportUtility: ExportUtility,
    private router: Router, private utility: CommonUtilities,
    private confirmationService: ConfirmationService,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,
    private _ReportService: RptpaymentService) {

  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._Action = this._AccessRightsService.getAccessRights(68);
    this.HotKeyIntegration();
    this.cols = [
      { field: 'voucherno', header: 'Voucher No' },
      { field: 'paymentdate', header: 'Payment Date' },
      { field: 'paymentmodename', header: 'Payment Mode Name' },
      { field: 'currencyname', header: 'Currency Name' },
      { field: 'paymentamount', header: 'Payment Amount' },
      { field: 'fromaccountname', header: 'From Account Name' },
      { field: 'toaccountname', header: 'To Account Name' },
      { field: 'narration', header: 'Narration' },
      { field: 'paymentstatusdesc', header: 'Payment Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.Fetchall();

  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._PaymentService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._paymentsview = resultJSON.paymentviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  exportExcel() {
    let PaymentList = this.GetPaymentList();
    this.exportUtility.exportExcel(PaymentList, 'Payment', 'xlsx');
  }

  ExportToPdf() {
    let PaymentList = this.GetPaymentList();
    this.exportUtility.ExportToPdf(PaymentList, 'Payment.pdf');
  }

  GetPaymentList() {
    if (!this._paymentsview || !this._paymentsview.length)
      return;
    let PaymentList = [];
    this._paymentsview.forEach(element => {
      let payment: any = {};
      payment["Voucher No"] = element.voucherno;
      payment["Payment Date"] = element.paymentdate;
      payment["Payment Mode Name"] = element.paymentmodename;
      payment["Currency Name"] = element.currencyname;
      payment["Payment Amount"] = element.paymentamount;
      payment["From Account Name"] = element.fromaccountname;
      payment["To Account Name"] = element.toaccountname;
      payment["Narration"] = element.narration;
      payment["Payment Status"] = element.paymentstatusdesc;
      payment["Created By"] = element.createdbyname;
      payment["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      PaymentList.push(payment);
    });
    return PaymentList;
  }
  view(event) {
    this.router.navigate(['/cPayment'], { state: { paymentid: event.paymentid, voucherno: event.voucherno, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cPayment']);
  }
  delete(event) {
    alert("delete");
  }

  Cancel(event) {
    let paymentid = event.paymentid;
    let series = event.series;
    let paymentno = event.voucherno;
    this._IsProgressSpinner = true;
    this._PaymentService.Cancel(paymentid, series, this._userid,paymentno).subscribe((result: any) => {
      //this._paymentsview = resultJSON.paymentviews;

      if (result && result.tranStatus.result) {
        this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
        this.Fetchall();
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].message);
      }

      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Voucher <b>"' + event.voucherno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  Print(event) {
    var voucherno = event.voucherno;
    var branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;
    // this._ReportService.PaymentPrint(voucherno, branchid);
    this._PaymentService.PaymentPrint({ "voucherno": voucherno, "branchid": branchid}).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
   
  }
}
