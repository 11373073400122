import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { RptbalancesheetService } from 'src/app/core/Services/reports/Accounting/RptBalanceSheet/rptbalancesheet.service';
import { vMonthSelect } from 'src/app/core/Validators/validation';
import { HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Currencyview } from 'src/app/core/Views/Currencyview';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-rptbalancesheet',
  templateUrl: './rptbalancesheet.component.html',
  styleUrls: ['./rptbalancesheet.component.css']
})
export class RptbalancesheetComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptbalancesheetform: FormGroup;
  _branchid: number;
  _userid: number;
  _src: SafeResourceUrl;
  _currencylist: Currencyview[];
  datepipe: any;
  _Action: IAction
  mindate: Date;
  maxdate: Date;

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptBalanceSheetService: RptbalancesheetService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(105);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptbalancesheetform = this.fb.group({
      fromdate: [''],
      todate: [''],
      currencyid: [0],
      month: ['', vMonthSelect]
    })
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptbalancesheetform.get("month").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptbalancesheetform.get("month").setValue(new Date(sessionStorage['Environmentenddate']));
    }

    this._branchid = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    var dt = new Date();
    dt.setDate(dt.getDate() - 7);
    this._rptbalancesheetform.controls['fromdate'].setValue(dt);
    this._rptbalancesheetform.controls['currencyid'].setValue(1);
    this._RptBalanceSheetService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._currencylist = resultJSON.currencyList;
      this._IsProgressSpinner = false;
      this._rptbalancesheetform.get("currencyid").setValue(1);
      this._rptbalancesheetform.get("currencyid").disable();
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._rptbalancesheetform.reset();
    var dt = new Date();
    dt.setDate(dt.getDate() - 7);
    this._rptbalancesheetform.controls['fromdate'].setValue(dt);
    this._rptbalancesheetform.controls['currencyid'].setValue(1);
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  GenerateReport(event) {
    var branchid = parseInt(sessionStorage["currentbranchid"]);
    var _month = this._rptbalancesheetform.get("month").value;
    var datePipe = new DatePipe("en-US");
    var month = datePipe.transform(_month, 'MM-yyyy');
    if (month == null) {
      this._CustomExceptionService.handleError("Please Select Month");
      return;
    }
    var currencyid = this._rptbalancesheetform.get("currencyid").value == null ? 0 : this._rptbalancesheetform.get("currencyid").value;
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._RptBalanceSheetService.Print(month, this._userid, currencyid, branchid).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}