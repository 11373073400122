import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptbalancesheetService {
  public _Action: IAction;
  public _httpOptions;
  public _RptBalanceSheetService = environment.RptBalanceSheetService;
  constructor(private http: HttpClient) { }
  
  PageOnload(): Observable<any> {
    var Query = this._RptBalanceSheetService + '/PageInitialize';
    
    return this.http.get<{ data: any }>(Query);
  
  
  }
  Print(month :string,userid:number,currencyid:number,branchid:number) {
    var Query = this._RptBalanceSheetService+'/Print/'+month+'/'+userid+'/'+currencyid+ '/'+branchid;
    //
    //window.open(Query,'_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    return this.http.get(Query,{responseType: 'blob'});
  }

}
