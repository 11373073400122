<p-toast></p-toast>
<div class="page-container">
    <div class="inner-page-container">
        
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Payment Refund Status</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <button pButton pRipple label="" (click)="UpdatePaymentReturnStatus()" title="Save"
                            icon="las la-save" class="p-button-success"></button>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width:20%">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_OrderTypes" [(ngModel)]="ordertype" optionValue="metasubcode"
                                    optionLabel="metasubdescription"></p-dropdown>
                                <label for="dropdown">Order Type <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width:20%">
                            <div class="p-formgroup-inline">
                                <h6>Payment Mode <span class="hlt-txt">*</span></h6>
                                <div class="p-field-radiobutton" *ngFor="let PM of _PaymentModes">
                                    <p-radioButton name="paymentmode" label={{PM.metasubdescription}}
                                        value={{PM.metasubcode}} [(ngModel)]="PaymentMode">
                                    </p-radioButton>
                                </div>
                            </div>
                        </td>
                        <td style="width:20%"></td>
                        <td style="width:20%"></td>
                        <td style="width:20%"></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <p-calendar dateFormat="dd/mm/yy" [(ngModel)]="fromdate" [showIcon]="true"></p-calendar>
                                <label for="inputtext">From Date</label>
                            </span>
                        </td>
                        <td>
                            <span class="p-float-label">
                                <p-calendar dateFormat="dd/mm/yy" [(ngModel)]="todate" [showIcon]="true"></p-calendar>
                                <label for="inputtext">To Date</label>
                            </span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Search" type="button" (click)="CheckPaymentReturnStatus()"
                                title="Search" icon="pi pi-search"></button>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="las la-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                        <!-- <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="las la-file-csv" title="Export CSV"
                                class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-filter" title="Filter"
                                class="p-button-info p-ml-auto" pTooltip="Selection Only"
                                tooltipPosition="bottom"></button>
                        </ng-template> -->
                    </p-toolbar>

                    <p-table #dt [rows]="20" [value]="paymentHistoryGrid" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['action','transactionID','orderNo','paymentAmount','customerName','paymentDate','paymentMode','paymentStatus']"
                        [rowHover]="true" dataKey="id"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <!-- <th>Transaction ID</th> -->
                                <th>Order ID</th>
                                <th>Order No.</th>
                                <th class="text-right">Amount (in USD)</th>
                                <th pSortableColumn="paymentDate"> Date <p-sortIcon field="paymentDate">
                                    </p-sortIcon>
                                </th>
                                <th class="text-center">Is Refunded Status?</th>
                                <th>Customer Name</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_paymentHistoryGrid>
                            <tr>
                                <!-- <td>
                                    {{_paymentHistoryGrid.transactionID}}
                                </td> -->
                                <td>
                                    {{_paymentHistoryGrid.orderid}}
                                </td>
                                <td>
                                    {{_paymentHistoryGrid.orderno}}
                                </td>
                                <td class="text-right">
                                    {{CurrencySymbol}}{{_paymentHistoryGrid.cancelamount}}
                                </td>
                                <td>
                                    {{_paymentHistoryGrid.cancelorderdate|date:'dd/MM/yyyy'}}
                                </td>
                                <td style="text-align: center;width: 50px;">
                                    <p-checkbox [(ngModel)]="_paymentHistoryGrid.isrefunded" binary="true">
                                    </p-checkbox>
                                </td>
                                <td>
                                    {{_paymentHistoryGrid.customername}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>