import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/core/Models/User';
import { UserService } from 'src/app/core/services/users/user.service';
import { Userview } from 'src/app/core/Views/Userview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-v-user',
  templateUrl: './v-user.component.html',
  styleUrls: ['./v-user.component.css']
})
export class VUserComponent implements OnInit {
  selectedrows: any;
  _userviews: Userview[] = [];
  _tempuserviews: Userview[] = [];
  _userview: Userview;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _IsProgressSpinner: boolean = false;
  _Currentuserid: number;
  _userid = sessionStorage["userid"];
  cols: any[] = [];
  isblock:any;

  constructor(private fb: FormBuilder, private _UserService: UserService, private router: Router, private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService, private _hotkeysService: HotkeysService, private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService, private exportUtility: ExportUtility
  ) {

  }

  ngOnInit(): void {


    this._Action = this._AccessRightsService.getAccessRights(30);
    this._IsProgressSpinner = true;
    this.utility.pageLoadScript();
    this.HotKeyIntegration();


    this.cols = [
      { field: 'username', header: 'User Name' },
      { field: 'employeename', header: 'Employee Name' },
      { field: 'userdiscountpercentage', header: 'User Discount' },
      { field: 'isdefaultrolename', header: 'Default Role' },
      { field: 'isdefaultbranchname', header: 'Default Branch' },
      { field: 'userstatusname', header: 'Status' }
    ]

    this._UserService.FetchAll(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._userviews = resultJSON.userviews;
      this._tempuserviews = resultJSON.userviews;
      this._IsProgressSpinner = false;
    });
  }

  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  view(event) {
    this.router.navigate(['/cUser'], { state: { userid: event.userid, action: 'view' } });
  }

  delete(event) {
    alert("delete");
  }

  edit(event) {
    this.router.navigate(['/cUser'], { state: { userid: event.userid, action: 'edit' } });
  }

  GoToCreate(event) {
    this.router.navigate(['/cUser']);
  }


  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  confirm(event) {
    this._Currentuserid = event.userid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this User <b>"' + event.username + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._UserService.Cancel(this._Currentuserid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_10);
        this._UserService.FetchAll(this._userid).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._userviews = resultJSON.userviews;
          this._tempuserviews = resultJSON.userviews;
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  exportExcel() {
    let usersList = this.GetUsersList();
    this.exportUtility.exportExcel(usersList, 'Users', 'xlsx');
  }

  // ExportToPdf() {
  //   let usersList = this.GetUsersList();
  //   this.exportUtility.ExportToPdf(usersList, 'Users.pdf');
  // }

  ExportToPdf() {
    let UsersList = this.GetUsersList();
    const data = UsersList.map(Object.values);
    const head = [[
      'User Name',
      'Employee Name',
      'User Discount',
      'Default Role',
      'Default Branch',
      'Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Users.pdf');
      })
    })
  }

  GetUsersList() {
    let userList = [];
    (this._userviews || []).forEach(element => {
      let user: any = {};
      user["User Name"] = element.username;
      user["Employee Name"] = element.employeename;
      user["User Discount"] = element.userdiscountpercentage;
      user["Default Role"] = element.isdefaultrolename;
      user["Default Branch"] = element.isdefaultbranchname;
      user["Status"] = element.userstatusname;
      userList.push(user);
    });
    return userList;
  }

  isblocked(event)
  {
    if(event.checked)
    {
      this._userviews=this._tempuserviews.filter(s=>s.isblocked==true);
    }
    else
      this._userviews=this._tempuserviews;
  }

}
