<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_documentdescriptionform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Document Description</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save" [disabled]="isDisabledSave"></button>
                                <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                    icon="pi pi-trash" class="" class="p-button-danger" [disabled]="isDisabledClear"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                    title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td >
                                <span class="p-float-label">
                                    <input type="text" pInputText name="documentdescriptionname" formControlName="documentdescriptionname">
                                    <label for="documentdescriptionname"> Document Description Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" 
                                     *ngIf="(_submitted || _documentdescriptionform.get('documentdescriptionname').touched) && _documentdescriptionform.get('documentdescriptionname').errors?.CountryNameRequired"> 
                                    Please Enter Document Description Name
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_documentdescriptiontype" [autoDisplayFirst]="false" optionLabel="metasubdescription"
                                        optionValue="metasubcode"  name="documentdescriptiontype" formControlName="documentdescriptiontype"> 
                                    </p-dropdown>
                                    <label for="documentdescriptiontype"> Document Descripton Type <span class="hlt-txt">*</span></label>
                            </span>   
                            <span class="text-danger"
                            *ngIf="(_documentdescriptionform.get('documentdescriptiontype').touched || _submitted) && _documentdescriptionform.get('documentdescriptiontype').errors?.SelectStatus">
                            Please select Document Descripton Type
                        </span>                            
                            </td>                         
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_documentdescriptionstatus" [autoDisplayFirst]="false" optionLabel="metasubdescription"
                                        optionValue="metasubcode"  name="status" formControlName="status">
                                    </p-dropdown>
                                    <label for="status"> Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_documentdescriptionform.get('status').touched || _submitted) && _documentdescriptionform.get('status').errors?.SelectStatus">
                                    Please Select Status
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>

