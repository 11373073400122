import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RptbrandwisestockService } from 'src/app/core/Services/reports/Stock/RptBrandwisestock/rptbrandwisestock.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-rptbrandwisestock',
  templateUrl: './rptbrandwisestock.component.html',
  styleUrls: ['./rptbrandwisestock.component.css']
})
export class RptbrandwisestockComponent implements OnInit {

  _IsProgressSpinner: boolean = false;
  _rptbrandwisestockform: FormGroup;
  _branchlists = sessionStorage["BranchID"];
  _userid: number = parseInt(sessionStorage["userid"]);
  datepipe: any;
  brandid: number[] = [];
  branchid: number[] = [];
  _branchlist: any[] = [];
  _brandlist: any[] = [];
  cols: string[];
  _ReportLists: any[] = [];
  colWidth: any = 180;
  totalsum: number = 0;
  mindate: Date;
  maxdate: Date;

  constructor(
    private fb: FormBuilder,
    private _RptbrandwisestockService: RptbrandwisestockService,
    private _CustomExceptionService: CustomExceptionService,
    private exportUtility: ExportUtility,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptbrandwisestockform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      brandid: [0],
    })

    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptbrandwisestockform.get("fromdate").setValue(new Date);
      this._rptbrandwisestockform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptbrandwisestockform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptbrandwisestockform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

    this._IsProgressSpinner = true;

    this._RptbrandwisestockService.PageOnload({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branches;
      this._brandlist = resultJSON.brands;

      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  GenerateReport(event) {
    var _fromdate = this._rptbrandwisestockform.get("fromdate").value;
    var _todate = this._rptbrandwisestockform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (!fromdate) {
      this._CustomExceptionService.handleError("Please select from date");
      return;
    }
    if (!todate) {
      this._CustomExceptionService.handleError("Please select to date");
      return;
    }
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    var Branch = this.branchid;
    var Brand = this.brandid;

    let data = {
      "fromdate": fromdate,
      "todate": todate,
      "branches": Branch,
      "brands": Brand,
    }
    this._IsProgressSpinner = true;
    this._RptbrandwisestockService.GenerateReport(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.cols = [];
      this._ReportLists = [];
      this.totalsum = 0;
      this._ReportLists = resultJSON.reportList;

      if (!resultJSON.reportList || !resultJSON.reportList.length) {
        this._CustomExceptionService.handleWarning("No Data Available");
      }
      else {
        var cbcol = resultJSON.reportList[0];
        //let cols = ["BRANCHNAME", "SALESINVOICEDATE", "BRANDNAME"]
        this.cols = Object.keys(cbcol);
        //this.cols = this.cols.filter(f => !cols.includes(f));
        // const colgroup = document.getElementById('colgroup');
        // const colHTML = `<col style="width: 215px;">`.repeat(this._ReportLists.length);
        // colgroup.innerHTML = colHTML;
      }
      this.totalsum = this.calculateTotalSum()
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  calculateColumnTotal(columnName: string): any {
		if (this._ReportLists.length === 0) {
			return 0;
		}
		if (columnName == "BRANDNAME") {
			return ""
		}
    if (columnName == "SALESINVOICEDATE") {
			return "Total"
		}
    if (columnName == "BRANCHNAME") {
			return ""
		}
		else {
			var result = this._ReportLists.reduce((total, item) => total + item[columnName], 0);
			var x = result.toFixed(3);
			return parseFloat(x);
		}
	}
  calculateTotalSum(): number {
		if (this._ReportLists.length === 0) {
			return 0;
		}
		let totalSum = 0;
		const colKeys = this.cols.filter(col => col !== 'BRANCHNAME' && col !== 'SALESINVOICEDATE'  && col !== 'BRANDNAME');
		for (const col of colKeys) {
			totalSum += this.calculateColumnTotal(col);
		}
		return totalSum;
	}
  exportExcel() {
		let brandwiselist = this.GetbrandsalesList();
		this.exportUtility.exportExcel(brandwiselist, 'Brand Wise Sales', 'xlsx');
	}
  GetbrandsalesList(){
    if (!this._ReportLists || this._ReportLists.length === 0) {
			return [];
		}

		let brandwiselist = [];
		this._ReportLists.forEach(element1 => {
      let brandwisesale: any = {};
      this.cols.forEach(a => {
        if (a === 'SALESINVOICEDATE' && element1[a]) {
          // Apply dd/MM/yyyy format to SALESINVOICEDATE
          brandwisesale[a] = this.datePipe.transform(element1[a], 'dd/MM/yyyy');
        } else {
          brandwisesale[a] = element1[a];
        }
      });
      brandwiselist.push(brandwisesale);
    });
		return brandwiselist;
  }
  clear($event){
    this.branchid = [];
    this.brandid = [];
    this._ReportLists = [];
    this.totalsum = 0
    this._rptbrandwisestockform.reset();
    this.ngOnInit();
  }

}
