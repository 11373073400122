export class Supplieraddressdetail {
    supplieraddressdetailid: number;
    supplierid: number;
    contactpersonname: string;
    mobileno: string;
    phoneno: string;
    address: string;
    mailid: string;
    countryid: number;
    stateid: number;
    districtid: number;
    faxno: string;
    isdfaultaddress: boolean;
}