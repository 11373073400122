import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptminusstockService {

  public _Action: IAction;
  public _httpOptions;
  public _RptBranchwisestockService = environment.RptBranchwisestockService;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
   }
   createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnload(branchwise: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptBranchwisestockService + '/PageInitialize';

    return this.http.post<{ data: any }>(Query, branchwise, { headers });

  }



  getProductCategory(producttype: object) {
    const headers = this.header;
    var Query = this._RptBranchwisestockService + '/getProductCategories';

    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }
  getProduct(productcategory: object) {
    const headers = this.header;
    var Query = this._RptBranchwisestockService + '/getProducts';

    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }
  getBrand(products: object) {
    const headers = this.header;
    var Query = this._RptBranchwisestockService + '/getBrand';

    return this.http.post<{ data: any }>(Query, products, { headers });
  }

 
  lotquantitypopup(obj: object): Observable<any> {
		const headers = this.header;
		var Query = this._RptBranchwisestockService + '/lotquantitypopup';
		return this.http.post(Query, obj, { headers });
	}
  
  MinusStockPrint(products: object) {
    const headers = this.header;
    var Query = this._RptBranchwisestockService + '/MinusStockPrint';

    return this.http.post<{ data: any }>(Query, products, { headers });
  }

}
