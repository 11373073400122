import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { CustomerslabwisediscountService } from 'src/app/core/Services/Rewards/customerslabwisediscount.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Customerslabdiscountview } from 'src/app/core/Views/Customerslabwisediscountview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-v-customerslabwisediscount',
  templateUrl: './v-customerslabwisediscount.component.html',
  styleUrls: ['./v-customerslabwisediscount.component.css']
})
export class VCustomerslabwisediscountComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _customerslabwisediscountviews: Customerslabdiscountview[];
  _customerslabwisediscountview: Customerslabdiscountview[];
  _Action: IAction;
  _IsProgressSpinner: boolean = true;
  cols: any[];
  _userid = sessionStorage["userid"];
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _router: Router, private _AccessRightsService: AccessRightsService,
    private _CustomerslabwisediscountService: CustomerslabwisediscountService, private _hotkeysService: HotkeysService, private router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService, private exportUtility: ExportUtility, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(83);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();

    this.cols = [
      { field: 'customername', header: 'Customer Name' },
      { field: 'effectivefrom', header: 'Effective From' },
      { field: 'effectiveto', header: 'Effective To' },
      { field: 'recordstatusname', header: 'Status' }
    ]
    this.FetchAll();

  }

  FetchAll()
  {
    this._IsProgressSpinner = true;
    this._CustomerslabwisediscountService.FetchAll(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._customerslabwisediscountviews = resultJSON.customerslabdiscountviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  onRowSelect(event) {
  }

  onRowUnselect(event) {
  }
  view(event) {
    this._router.navigate(['/cCustomerslabwisediscount'], { state: { slabwisecode: event.slabwisecode, action: 'view' } });
  }

  delete(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this customer discount <b>"' + event.customerid + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.changeStatus(event);
      }
    });
  }
  changeStatus(event) {
    var customerid = event.customerid;
    this._IsProgressSpinner = true;
    this._CustomerslabwisediscountService.Cancel(customerid, sessionStorage["userid"]).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      const resultJSON = JSON.parse(JSON.stringify(result));
      //this._customerslabwisediscountviews = resultJSON._customerslabwisediscountviews;
      this._IsProgressSpinner = false;
      this.ngOnInit();
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  edit(event) {
    // this.router.navigate(['/cCustomer']);
    this._router.navigate(['/cCustomerslabwisediscount'], { state: { customerid: event.customerid, action: 'edit' } });

  }
  GoToCreate(event) {
    this.router.navigate(['/cCustomerslabwisediscount']);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let slabList = this.GetSlabDiscountList();
    this.exportUtility.exportExcel(slabList, 'Customer Slab wise Discount', 'xlsx');
  }
  exportPdf() {
    let slabList = this.GetSlabDiscountList();
    this.exportUtility.ExportToPdf(slabList, 'Customer Slab wise Discount.pdf');
  }
  GetSlabDiscountList() {
    let slabList = [];
    this._customerslabwisediscountviews.forEach(element => {
      let slab: any = {};
      slab["Customer Name"] = element.customername;
      slab["Effective From"] = this.datepipe.transform(element.effectivefrom, 'dd/MM/yyyy');
      slab["Effective To"] = this.datepipe.transform(element.effectiveto, 'dd/MM/yyyy');
      slab["Status"] = element.recordstatusname;
      slabList.push(slab);
    });
    return slabList;
  }
  SendToApproval(event) {
    var workflowid = 32;
    this._userid = sessionStorage["userid"];
    let _branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;
    //this._productid= this._customerwisediscountform.get("productid").setValue(this._customerwisediscountform.get("productid").value);
    this._CustomerslabwisediscountService.SendToApproval(workflowid, this._userid, _branchid, event.slabwisecode).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
        this._IsProgressSpinner = false;
        this.FetchAll();
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
