<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Payment Form</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple title="Save" type="submit" (click)="onSave()" icon="pi pi-save" [hidden]="_IsSaveDisable"></button>
                            <button pButton pRipple title="Clear" type="submit" (click)="reset($event)" icon="pi pi-trash" class="p-button-danger" [hidden]="_IsClearDisable"></button>
                            <button pButton pRipple icon="pi pi-search" title="Back to Search" (click)=" goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_paymentform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <div class="p-formgroup-inline">
                                    <h6>Payment Mode <span class="hlt-txt">*</span></h6>
                                    <div class="p-field-radiobutton" *ngFor="let PM of _PaymentModes">
                                        <p-radioButton label={{PM.metasubdescription}} value={{PM.metasubcode}} formControlName="paymentmode" (onClick)="OnChangePaymentMode($event)">
                                        </p-radioButton>
                                    </div>
                                </div>
                                <span class="text-danger" *ngIf="(_submitted || _paymentform.get('paymentmode').touched) && _paymentform.get('paymentmode').errors?.SelectPaymentMode">
                                    Please Select Payment Mode
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" name="voucherno" formControlName="voucherno" disabled pInputText>
                                    <label for="voucherno">Voucher No </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="paymentdate" [monthNavigator]="true" [disabled]="_isdatedisabled"
                                        dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate"></p-calendar>
                                    <label for="paymentdate"> Payment Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _paymentform.get('paymentdate').touched) && _paymentform.get('paymentdate').errors?.SelectPaymentDate">
                                    Please Select Payment Date
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_partyTypeList"
                                        optionLabel="metasubdescription" optionValue="metasubid"
                                        formControlName="partytype" (onChange)="GetCustomerByPartyType($event)">
                                    </p-dropdown>
                                    <label for="partytype">Party Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _paymentform.get('partytype').touched) && _paymentform.get('partytype').errors?.SelectFromAccount">
                                    Please Select Party Type
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_fromaccounts" optionLabel="name"
                                        optionValue="ledgerid" formControlName="fromaccount" [filter]="true"
                                        filterBy="name" [showClear]="true"
                                        (onChange)="getfromsupplierclosingbalance($event)">
                                    </p-dropdown>
                                    <label for="fromaccount">From Account <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _paymentform.get('fromaccount').touched) && _paymentform.get('fromaccount').errors?.SelectFromAccount">
                                    Please Select From Account
                                </span>
                            </td>
                            <td>
                                <form [formGroup]="_paymentbalanceform">
                                    <span class="p-float-label">
                                        <input type="number" name="fromaccountclosingbalance"
                                            formControlName="fromaccountclosingbalance" disabled pInputText>
                                        <label for="fromaccountclosingbalance"> Closing Balance (in Usd)</label>
                                    </span>
                                </form>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr [hidden]="_action == 'view'">
                            <td>
                                <form [formGroup]="_paymentotherform">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_PaymentsFor"
                                            optionLabel="metasubdescription" optionValue="metasubcode"
                                            (onChange)="gettoclosingbalance()" formControlName="paymentfor"
                                            [filter]="true" filterBy="name" [showClear]="true">
                                        </p-dropdown>
                                        <label for="paymentfor">Payment For <span class="hlt-txt">*</span></label>
                                    </span>
                                </form>
                            </td>
                            <td>
                                <form [formGroup]="_paymentotherform">
                                    <span class="p-float-label">
                                        <input type="text" name="invoiceno" formControlName="documentno" pInputText
                                            (keyup.enter)="gettoclosingbalance()">
                                        <label for="documentno">Document No </label>
                                    </span>
                                </form>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_toaccounts" optionLabel="name"
                                        optionValue="ledgerid" formControlName="toaccount" [filter]="true"
                                        [virtualScroll]="true" itemSize="30" filterBy="name" [showClear]="true"
                                        (onChange)="gettoclosingbalance()">
                                    </p-dropdown> -->
                                    <p-dropdown [options]="_toaccounts" formControlName="toaccount" optionLabel="name"
                                        [autoDisplayFirst]="false" [showClear]="true" optionValue="ledgerid"
                                        [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="name"
                                        [showClear]="true" (onChange)="gettoclosingbalance1()">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.docstatus == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.name}}</div>
                                        </ng-template>
                                    </p-dropdown>
                                    <label for="toaccount">To Account <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _paymentform.get('toaccount').touched) && _paymentform.get('toaccount').errors?.SelectToAccount">
                                    Please Select To Account
                                </span>
                            </td>
                            <td>
                                <form [formGroup]="_paymentbalanceform">
                                    <span class="p-float-label">
                                        <input type="number" name="toaccountclosingbalance"
                                            formControlName="toaccountclosingbalance" disabled pInputText>
                                        <label for="toaccountclosingbalance">Closing Balance (in Usd)</label>
                                    </span>
                                </form>
                            </td>
                            <td *ngIf="!_containerbased">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_containernos" [disabled]="_action == 'view'"
                                        optionLabel="containerno" optionValue="containerno"
                                        [ngModelOptions]="{standalone: true}" (onChange)="onSelectcontno($event)"
                                        [(ngModel)]="_selectedcontainernos" name="_selectcontid"></p-multiSelect>
                                    <label for="_selectcontid">Container No. </label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <!-- <input type="number" name="paymentamount" formControlName="paymentamount" pInputText
                                        (keyup)="allocatePaymentAmount($event)">
                                    <label for="paymentamount"> Payment Amount <span class="hlt-txt">*</span></label> -->
                                <p-inputNumber name="paymentamount" maxlength="16" formControlName="paymentamount" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" (onFocus)="allocatePaymentAmount($event)" (onBlur)="allocatePaymentAmount($event)">
                                </p-inputNumber>
                                <label for="paymentamount"> Payment Amount <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _paymentform.get('paymentamount').touched) && _paymentform.get('paymentamount').errors?.PaymentAmountRequired">
                                    Please Enter Payment Amount
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" name="refNo" formControlName="refNo" pInputText>
                                    <label for="refNo">Transaction No </label>
                                </span>
                            </td>
                            <td>
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <div class="browse-links">
                                            <p-button label="Document" styleClass="p-button-link"
                                                title="{{imageselect}}" (click)="showdocumentDialog(document)">
                                            </p-button>
                                        </div>
                                    </span>
                                    <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only" pTooltip="Browse File" tooltipPosition="top" mode="basic" #document [disabled]="isDisabledButton" [auto]="true" accept="image/*,.pdf,.xlsx,.xls,.xlsm,.docx,.txt" (onSelect)="onDocumentSelect($event,document)">
                                    </p-fileUpload>

                                    <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button" (click)="onDocumentClear(document)" [disabled]="isDisabledButton" tooltipPosition="top">
                                        <i class="pi pi-times"></i>
                                    </button>
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_currencies"
                                        optionLabel="currencyname" optionValue="currencyid"
                                        formControlName="currentcurrencyid" (onChange)="ChangeCurrencies($event)">
                                    </p-dropdown>
                                    <label for="currentcurrencyid"> Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _paymentform.get('currentcurrencyid').touched) && _paymentform.get('currentcurrencyid').errors?.SelectCurrencyName">
                                    Please Select Currency Name
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" name="exchangerateinusd" formControlName="exchangerateinusd"
                                        disabled pInputText>
                                    <label for="exchangerateinusd">Exchange Rate</label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <form [formGroup]="_paymentbalanceform">
                                    <span class="p-float-label">
                                        <input type="number" name="totalpaymentamount" disabled
                                            formControlName="totalpaymentamount" pInputText>
                                        <label for="totalpaymentamount">Total Payment Amount </label>
                                    </span>
                                </form>
                            </td>
                            <td rowspan="2" *ngIf="_containerbased">
                                <span class="p-float-label">
                                    <textarea rows="2" cols="30" formControlName="narration" pInputTextarea></textarea>
                                    <label for="narration">Narration</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" name="advanceamount" formControlName="advanceamount" disabled
                                        pInputText>
                                    <label for="advanceamount">Advance Amount </label>
                                </span>
                            </td>
                            <td></td>
                            <td> </td>
                        </tr>
                    </table>
                </form>

                <div class="sub-title" *ngIf="_containerbased">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Payment Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card" *ngIf="_containerbased">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_PaymentDetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" (onRowSelect)="PaymentRowSelect($event)" (onRowUnselect)="PaymentRowUnSelect($event)" [globalFilterFields]="['billdate','billno','invoiceno','billamount','paidamount','branchname']"
                        [rowHover]="true" dataKey="voucherno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" [(selection)]="selectedPaymentDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 50px;" *ngIf="_action != 'view'">
                                    <p-tableHeaderCheckbox (click)="selectall()"></p-tableHeaderCheckbox>
                                </th>
                                <th>Voucher No</th>
                                <th>Voucher Type</th>
                                <th pSortableColumn="branchname">Branch Name
                                    <p-sortIcon field="branchname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="billdate">Bill Date
                                    <p-sortIcon field="billdate"></p-sortIcon>
                                </th>
                                <th>Bill No</th>
                                <th>Supplier Invoice No</th>
                                <th pSortableColumn="creditamount">Credit Amount
                                    <p-sortIcon field="creditamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="debitamount">Debit Amount
                                    <p-sortIcon field="debitamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="paidamount">Paid
                                    <p-sortIcon field="paidamount"></p-sortIcon>
                                </th>
                                <th pSortableColumn="pendingamount">Pending
                                    <p-sortIcon field="pendingamount">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_PaymentDetail let-ri="rowIndex">
                            <tr>
                                <td style="text-align: center;width: 50px;" *ngIf="_action != 'view'">
                                    <p-tableCheckbox [value]="_PaymentDetail"></p-tableCheckbox>
                                </td>
                                <td>
                                    {{_PaymentDetail.voucherno}}
                                </td>
                                <td>
                                    {{_PaymentDetail.vouchertypedesc}}
                                </td>
                                <td>
                                    {{_PaymentDetail.branchname}}
                                </td>
                                <td>
                                    {{_PaymentDetail.billdate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_PaymentDetail.billno}}
                                </td>
                                <td>
                                    {{_PaymentDetail.invoiceno}}
                                </td>
                                <td>
                                    {{_PaymentDetail.creditamount | number:'1.3'}}
                                </td>
                                <td>
                                    {{_PaymentDetail.debitamount | number:'1.3'}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_PaymentDetail.paidamount" pInputText [disabled]="_PaymentDetail.debitamount==0" *ngIf="_action != 'view' && _PaymentDetail.vouchertypeid != 6 && _PaymentDetail.isselected" (keyup)="OnChangePaidAmount($event.target.value,_PaymentDetail)">
                                            <div *ngIf="_action == 'view' || _PaymentDetail.vouchertypeid == 6 || !_PaymentDetail.isselected">
                                                {{_PaymentDetail.paidamount | number:'1.3' }}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_PaymentDetail.paidamount | number:'1.3'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_PaymentDetail.pendingamount | number:'1.3'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            <p-dialog #warning [style]="{width: '400px'}" header="Confirm" [(visible)]="paymentmodel.showdialog" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
                <ng-template pTemplate="content">
                    <label [innerHTML]="paymentmodel.message"></label>
                </ng-template>
                <ng-template pTemplate="footer">
                    <button type="button" pButton icon="las la-check" label="Yes" autofocus (click)="paymentmodel.Yes()"></button>
                    <button type="button" pButton icon="pi pi-times" label="No" autofocus (click)="paymentmodel.Cancel()"></button>
                </ng-template>
            </p-dialog>
        </div>
    </div>
</div>