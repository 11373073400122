<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product Markup Level Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_producttypelist" optionLabel="producttypename"
                                    optionValue="producttypeid" [(ngModel)]="producttypeid"
                                    [ngModelOptions]="{standalone: true}" (onChange)="getProductCategory()"
                                    [virtualScroll]="true" itemSize="30">
                                </p-multiSelect>
                                <label for="producttypeid">Product Type Name </label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                                    [selectionLimit]="50" [(ngModel)]="productcatid"
                                    [ngModelOptions]="{standalone: true}" (onChange)="getProduct()"
                                    optionValue="productcategoryid"
                                    [virtualScroll]="true" itemSize="30">
                                </p-multiSelect>
                                <label for="productcategoryid">Product Category Name</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_productlist" optionLabel="productname"
                                    optionValue="productid" [(ngModel)]="productid"
                                    [ngModelOptions]="{standalone: true}"
                                    [virtualScroll]="true" itemSize="30">
                                </p-multiSelect>
                                <label for="productid">Product Name </label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                                    [(ngModel)]="brandid" [ngModelOptions]="{standalone: true}"
                                    [virtualScroll]="true" itemSize="30">
                                </p-multiSelect>
                                <label for="brandid">Brand Name </label>
                            </span>
                        </td>
                        <td style="width: 20%;"></td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Search" icon="pi pi-search" (click)="GenerateReport()"
                                class="p-button-sm p-button-success"></button>
                            <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()"
                                class="p-button-danger"></button>
                        </td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="500"
                        [paginator]="true" [rowsPerPageOptions]="[100,200,300]"
                        [rowsPerPageOptions]="[500,1000,1500,2000]"
                        [globalFilterFields]="['producttypename','categoryname','productname','brandname']"
                        [rowHover]="true" dataKey="productname" [columns]="cols"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" pSortableColumn="producttypename">Product
                                    Type
                                    <p-sortIcon field="producttypename">
                                    </p-sortIcon>
                                </th>
                                <th rowspan="2" style="text-align: center;" pSortableColumn="categoryname">Product
                                    Category
                                    <p-sortIcon field="categoryname">
                                    </p-sortIcon>
                                </th>
                                <th rowspan="2" style="text-align: center;" pSortableColumn="productname">Product
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th rowspan="2" style="text-align: center;" pSortableColumn="brandname">Brand<p-sortIcon
                                        field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th rowspan="2" style="text-align: center;" pSortableColumn="brandname">Cost Price<p-sortIcon
                                        field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th colspan="7" style="text-align: center;">Markup Percentage </th>
                            </tr>
                            <tr>
                                <th style="text-align: center;" pSortableColumn="suppliername">RETAIL 1<p-sortIcon
                                        field="suppliername">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="lotnumber">RETAIL 2<p-sortIcon
                                        field="lotnumber">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="severityname">ONLINE<p-sortIcon
                                        field="severityname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="stock">WHOLESALE<p-sortIcon
                                        field="stock">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="expirydate">SPECIAL<p-sortIcon
                                        field="expirydate">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="isconsignmentstockname">PROJECT
                                    <p-sortIcon field="isconsignmentstockname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="isconsignmentstockname">LAY BUY
                                    <p-sortIcon field="isconsignmentstockname">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                            <tr>
                                <td>
                                    {{_ReportList.producttypename}}
                                </td>
                                <td>
                                    {{_ReportList.productcategoryname}}
                                </td>
                                <td>
                                    {{_ReportList.productname}}
                                </td>
                                <td>
                                    {{_ReportList.brandname}}
                                </td>
                                <td style="text-align: right;">
                                    {{_ReportList.costprice}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.retail1}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.retail2}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.online}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.wholsale}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.special}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.project}}
                                </td>
                                <td style="text-align: center;">
                                    {{_ReportList.laybuy}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>