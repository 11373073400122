import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ProductCategory } from 'src/app/core/Models/overallclass';
import { Productratechangedetail } from 'src/app/core/Models/Productratechangedetail';
import { Producttype } from 'src/app/core/Models/Producttype';
import { ProductpricechangeService } from 'src/app/core/Services/sales/productpricechange.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Productratechangeview } from 'src/app/core/Views/Productratechangeview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable';


@Component({
  selector: 'app-rptproductpricechangehistory',
  templateUrl: './rptproductpricechangehistory.component.html',
  styleUrls: ['./rptproductpricechangehistory.component.css']
})
export class RptproductpricechangehistoryComponent implements OnInit {
  _pricechangeform: FormGroup;
  _pricechangetypeform: FormGroup;
  _ProductTypes: Producttype[];
  _ProductCategories: ProductCategory[];
  _ProductNames: Productoverallview[];
  _pricechangedetails: Productratechangedetail[] = [];
  _ProductTypeObj: any;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _Pricechangeviews: Productratechangeview[];
  _Pricechangeview: Productratechangeview;
  _IsProgressSpinner: boolean = true;
  _branchid = sessionStorage["currentbranchid"]
  _productratechangeid: number;
  _ProductCategoryObj: any;
  _effectiveTo: Date;
  _effectiveFrom: Date;
  datepipe = new DatePipe('en-US');
  cols: any[];
  _brandlist: any[] = [];
  _userlist: any[] = [];
  mindate: Date;
  maxdate: Date;

  constructor(private fb: FormBuilder, private router: Router, private utility: CommonUtilities, public exportUtility: ExportUtility,
    private _ProductpricechangeService: ProductpricechangeService, private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService) {
    this._productratechangeid = history.state?.productratechangeid ? history.state?.productratechangeid : 0;
  }
  InitializeForm() {
    this._pricechangeform = this.fb.group({
      _effectiveFrom: [''],
      _effectiveTo: [''],
      productcategoryid: [],
      producttypeid: [],
      productid: [],
      brandid: [],
      userid: []
    });
    if (sessionStorage['Environmentenddate'] == "null") {
      this._pricechangeform.get("_effectiveFrom").setValue(new Date);
      this._pricechangeform.get("_effectiveTo").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._pricechangeform.get("_effectiveFrom").setValue(new Date(sessionStorage['Environmentenddate']));
      this._pricechangeform.get("_effectiveTo").setValue(new Date(sessionStorage['Environmentenddate']))
    }
  }

  ngOnInit(): void {
    this._IsProgressSpinner = true;
    // this._Action = this._AccessRightsService.getAccessRights(357);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();
    this._ProductpricechangeService.PageOnLoad(this._productratechangeid, this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ProductTypes = resultJSON.producttypes;
      this._brandlist = resultJSON.brandList;
      this._userlist = resultJSON.userlist;
      this._IsProgressSpinner = false;
    })
    this.HotKeyIntegration();
    // this._ProductpricechangeService.FetchAll().subscribe((result) => {
    //   const resultJSON = JSON.parse(JSON.stringify(result));
    //   this._Pricechangeviews = resultJSON.priceHistoryview;
    //   this._IsProgressSpinner = false;      
    // },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; };
  }
  GetProductCategory(event) {
    this._pricechangedetails = []
    this._ProductCategories = [];
    this._ProductNames = [];
    // this._pricechangetypeform.controls["productcategoryid"].reset();
    // this._pricechangetypeform.controls["productid"].reset();
    var selectedProductType = event.value;
    if (selectedProductType.length == 0 || !selectedProductType || !selectedProductType.length) {
      this._CustomExceptionService.handleWarning("Please Select Atleast One Product Type")
      return;
    } else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = {
        "Producttype": selectedProductType || [],
        "branchid": this._branchid || 0
      };
      this._ProductpricechangeService.GetProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        let categories = resultJSON.productcategories;
        let uniqueChars = [...new Set(categories)];
        uniqueChars = categories.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.productcategoryid === test.productcategoryid
          )
        );
        this._ProductCategories = uniqueChars;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  GetProductName(event) {
    this._pricechangedetails = []
    this._ProductNames = [];
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || !selectedProductCategory || !selectedProductCategory.length) {
      this._CustomExceptionService.handleWarning("Please Select At Least One Product Category")
      return;
    } else {
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = {
        "Productcategory": selectedProductCategory || [],
        "branchid": this._branchid || 0
      };
      this._ProductpricechangeService.GetProductName(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._ProductNames = resultJSON.productsearch;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  ProductPriceDetailSearch() {
    // this._effectiveFrom = this._effectiveFrom ? this._effectiveFrom : new Date();
    // this._effectiveTo = this._effectiveTo ? this._effectiveTo : new Date();
    var fromdate = this._pricechangeform.get("_effectiveFrom").value;
    var todate = this._pricechangeform.get("_effectiveTo").value;
    var productid = this._pricechangeform.get("productid").value || [];
    var producttype = this._pricechangeform.get("producttypeid").value || [];
    var productcategory = this._pricechangeform.get("productcategoryid").value || [];
    var branchids = this._pricechangeform.get("brandid").value || [];
    var userids = this._pricechangeform.get("userid").value || [];

    if (!fromdate) {
      this._CustomExceptionService.handleError("Please select From Date")
      return;
    }
    else if (!todate) {
      this._CustomExceptionService.handleError("Please select To Date")
      return;
    }
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From date should be lesser than To date")
      return;
    }
    let data = {
      "fromdate": fromdate,
      "todate": todate,
      "producttype": producttype,
      "productcategory": productcategory,
      "productid": productid,
      "brandid": branchids,
      "userid": userids
    }
    this._ProductpricechangeService.getPriceHistory(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Pricechangeviews = resultJSON.priceHistoryview;
      console.log(this._Pricechangeviews)
      if (this._Pricechangeviews.length === 0) {
        throw new Error("No data available");
      }
    }, error => { this._CustomExceptionService.handleError(error); });
  }
  ClearFields() {
    if (sessionStorage['Environmentenddate'] == "null") {
      this._pricechangeform.get("_effectiveFrom").setValue(new Date);
      this._pricechangeform.get("_effectiveTo").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._pricechangeform.get("_effectiveFrom").setValue(new Date(sessionStorage['Environmentenddate']));
      this._pricechangeform.get("_effectiveTo").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._pricechangeform.get("producttypeid").reset();
    this._pricechangeform.get("productcategoryid").reset();
    this._pricechangeform.get("productid").reset();
    this._pricechangeform.get("brandid").reset();
    this._pricechangeform.get("userid").reset();
    this._Pricechangeviews = [];
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        //this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let ProductpricechangeList = this.getproductpricedetails();
    this.exportUtility.exportExcel(ProductpricechangeList, 'Product_price_change_list', 'xlsx');
  }

  ExportToPdf() {
    let getproductpricedetail = this.getproductpricedetails();
    const data = getproductpricedetail.map(Object.values);
    const head = [['History Version', 'Date', 'Product Type', 'Product Category', 'Product Name', 'Brand Name', 'Currency', 'Cost Price',
      'Cost Price in USD ', ' Modified By', 'Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        data.forEach(row => {
          row.forEach((value, index) => {
            if (value === null) {
              row[index] = '';
            }
          });
        });
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Product price change list.pdf');
      })
    })
  }
  getproductpricedetails() {
    if (!this._Pricechangeviews || !this._Pricechangeviews.length)
      return;
    let ProductpricechangeList = [];
    this._Pricechangeviews.forEach(element => {
      let productpricechange: any = {};
      productpricechange["History Version"] = element.version;
      productpricechange["Date"] = this.datepipe.transform(element.historydate, 'dd/MM/yyyy');
      productpricechange["Product Type"] = element.producttypename;
      productpricechange["Product Category"] = element.productcategoryname;
      productpricechange["Product Name"] = element.productname;
      productpricechange["Brand Name"] = element.brandname;
      productpricechange["Currency"] = element.currencyname;
      productpricechange["Cost Price"] = element.purchaserate;
      productpricechange["Cost Price in USD"] = element.purchaserateinusd;
      productpricechange["Modified by"] = element.modifiedby;
      productpricechange["Status"] = element.productstatus;

      ProductpricechangeList.push(productpricechange);
    });
    return ProductpricechangeList;
  }

}
