<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>OutStanding Credit Note Report</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_rptoutstandingcreditnoteledgerform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="fromdate" dateFormat="dd/mm/yy"
                                            [monthNavigator]="true" [yearNavigator]="true"  [minDate]="mindate" [maxDate]="maxdate"
                                             yearRange="2021:2050">
                                        </p-calendar>
                                        <label for="fromdate">From Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="todate" dateFormat="dd/mm/yy"
                                            [monthNavigator]="true" [yearNavigator]="true"
                                            [minDate]="_rptoutstandingcreditnoteledgerform.get('fromdate').value"
                                            [maxDate]="maxdate"
                                            yearRange="2021:2050">
                                        </p-calendar>
                                        <label for="todate">To Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_currencylist"
                                            optionValue="currencyid" optionLabel="currencyname"
                                            formControlName="currencyid" [filter]="true" filterBy="currencyname">
                                        </p-dropdown>
                                        <label for="currencyname">Currency</label>
                                    </span>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_branchlist" optionValue="branchid"
                                            optionLabel="branchname" formControlName="branchid"
                                            displaySelectedLabel="true" [virtualScroll]="true" itemSize="30"
                                            selectedItemsLabel="{0} items selected">
                                            <ng-template let-account pTemplate="item">
                                                <div
                                                    [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                    {{account.branchname}}</div>
                                            </ng-template>
                                        </p-multiSelect>
                                        <label for="name">Branch Name </label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_customertypelist"
                                            optionValue="value" optionLabel="customertype"
                                            (onChange)="OnChangeCustomerType()" formControlName="customertype"
                                            [filter]="true" filterBy="customertype">
                                        </p-dropdown>
                                        <label for="customertype">Type</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_accountnamelist" optionValue="ledgerid"
                                            optionLabel="name" formControlName="ledgerid" displaySelectedLabel="true"
                                            [virtualScroll]="true" itemSize="30" [selectionLimit]="10"
                                            selectedItemsLabel="{0} items selected">
                                        </p-multiSelect>
                                        <label for="name">Customer Name</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_statuslist" name="status"
                                            optionLabel="metasubdescription" optionValue="metasubcode"
                                            [showClear]="true" formControlName="status"> </p-dropdown>
                                        <label for="status">Status </label>
                                    </span>

                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" icon="las la-search" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                        </table>
                    </form>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100,200,400,500,1000]" [globalFilterFields]="['creditnoteno','creditnotedate','branchname','referenceno','currencyname','debitamount','creditamount']"
                            [rowHover]="true" dataKey="name" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="text-center" style="width: 40px;" pSortableColumn="sno">S.No
                                        <p-sortIcon field="sno"></p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 70px;" pSortableColumn="creditnoteno">Credit Note No
                                        <p-sortIcon field="creditnoteno">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 70px;" pSortableColumn="creditnotedate">
                                        Credit Note Date
                                        <p-sortIcon field="creditnotedate">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 100px;" pSortableColumn="branchname">Branch Name
                                        <p-sortIcon field="branchname">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 100px;" pSortableColumn="accountname">
                                        Account Name
                                        <p-sortIcon field="accountname">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 70px;" pSortableColumn="referenceno">
                                        Reference No
                                        <p-sortIcon field="referenceno">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 100px;" pSortableColumn="adjustbranchname">
                                        Adjusted Branch
                                        <p-sortIcon field="adjustbranchname">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 70px;" pSortableColumn="adjustedinvoiceno">
                                        Adjusted Invoice No
                                        <p-sortIcon field="adjustedinvoiceno">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 80px;" pSortableColumn="adjustedinvoiceamount">
                                        Adjusted Invoice Amount
                                        <p-sortIcon field="adjustedinvoiceamount">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 60px;" pSortableColumn="currencyname">
                                        Currency
                                        <p-sortIcon field="currencyname">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 60px;" pSortableColumn="paymentvoucherno">
                                        Payment Voucher No
                                        <p-sortIcon field="paymentvoucherno">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 60px;" pSortableColumn="paymentamount">
                                        Payment Amount
                                        <p-sortIcon field="paymentamount">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 60px;" pSortableColumn="paymentdate">
                                        Payment Date
                                        <p-sortIcon field="paymentdate">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 60px;" pSortableColumn="creditamount">Credit Note Amount
                                        <p-sortIcon field="creditamount">
                                        </p-sortIcon>
                                    </th>
                                    <th class="text-center" style="width: 60px;" pSortableColumn="currentstatus">Status
                                        <p-sortIcon field="currentstatus"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                                <tr>
                                    <td class="text-center" style="width: 40px;">
                                        {{i+ 1}}
                                    </td>
                                    <td class="text-center" style="width: 70px;">
                                        {{_ReportList.creditnoteno}}
                                    </td>
                                    <td class="text-center" style="width: 70px;">
                                        {{_ReportList.creditnotedate | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td class="text-center" style="width: 100px;">
                                        {{_ReportList.branchname}}
                                    </td>
                                    <td class="text-center" style="width: 100px;">
                                        {{_ReportList.accountname}}
                                    </td>
                                    <td class="text-center" style="width: 70px;">
                                        {{_ReportList.referenceno}}
                                    </td>
                                    <td class="text-center" style="width: 100px;">
                                        {{_ReportList.adjustbranchname}}
                                    </td>
                                    <td class="text-center" style="width: 70px;">
                                        {{_ReportList.adjustedinvoiceno}}
                                    </td>
                                    <td class="text-right" style="width: 80px;">
                                        {{_ReportList.adjustedinvoiceamount}}
                                    </td>
                                    <td class="text-center" style="width: 60px;">
                                        {{_ReportList.currencyname}}
                                    </td>
                                    <td class="text-center" style="width: 60px;">
                                        {{_ReportList.paymentvoucherno}}
                                    </td>
                                    <td class="text-right" style="width: 60px;">
                                        {{_ReportList.paymentamount}}
                                    </td>
                                    <td class="text-center" style="width: 60px;">
                                        {{_ReportList.paymentdate | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td class="text-right" style="width: 60px;">
                                        {{_ReportList.creditamount}}
                                    </td>
                                    <td class="text-center" style="width: 60px;">
                                        {{_ReportList.currentstatus}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Amount Details</h5>
                                </div>
                            </div>
                        </div>
                        <form [formGroup]="_rptoutstandingcreditnoteledgerform">
                            <table class="normal-table column-2">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" style="text-align: left;" disabled pInputText
                                                formControlName="totalcreditamount">
                                            <label>Total Credit Note Amount</label>
                                        </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>