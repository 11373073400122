import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Tax } from 'src/app/core/Models/Tax';
import { TaxService } from 'src/app/core/services/masters/tax.service';
import { vEffectiveFrom, vEffectiveTo, vStatusSelect, vTaxCode, vTaxName, vTaxPercentage } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { DatePipe } from '@angular/common'
import * as ace from "ace-builds";
import { MessageService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';



@Component({
  selector: 'app-c-tax',
  templateUrl: './c-tax.component.html',
  styleUrls: ['./c-tax.component.css']
})


export class CTaxComponent implements OnInit {
  _taxform: FormGroup;
  _taxstatus: Metadatum[];
  _action: string = "create";
  _Tax: Tax;
  _submitted = false;
  _taxid: number;
  minDate: Date;
  _IsProgressSpinner: boolean = false;
  _SaveHide: boolean = false;
  _ClearHide: boolean = false;
  _Action: IAction;


  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;

  constructor(private fb: FormBuilder, private messageService: MessageService,
    private datePipe: DatePipe, private _CustomExceptionService: CustomExceptionService,
    private _TaxService: TaxService, private _router: Router, private utility: CommonUtilities,
    private _hotkeysService: HotkeysService,     private _AccessRightsService: AccessRightsService    ) {
      this._taxid = history.state?.taxid ? history.state?.taxid : 0;
      this._action = history.state.action == null ? 'create' : history.state.action;
      this.HotKeyIntegration();
    }

  InitializeForm() {
    this._taxform = this.fb.group({
      accountingyear: [],
      createdby: [],
      createdon: [],
      //7. if Tax code not given, show error "Please enter Tax code."
      //8. if Tax Name is not given, show error "Please enter Tax Name."
      //9. if Tax % not given, show error "Please enter Tax %"
      //10. if Effective to not selected, show error "Please select Effective To."
      // 28.if status not seletected, show error "Please select Status."
      effectivefrom: ['', vEffectiveFrom],
      effectiveto: [],
      modifiedby: [],
      modifiedon: [],
      series: [],
      taxcode: ['', vTaxCode],
      taxid: [0],
      taxname: ['', vTaxName],
      taxpercentage: ['', vTaxPercentage],
      taxstatus: ['', vStatusSelect],
      issystemgenerated: []
    })
    this._IsProgressSpinner = false;
    if (this._taxstatus && this._taxstatus.length) {
      this._taxform.get("taxstatus").setValue(this._taxstatus[0].metasubcode);
    }
    this._taxform.get("effectivefrom").setValue(new Date());
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(26);
    this.utility.pageLoadScript();
    
    this.InitializeForm();
    

    if (this._action == 'view') {
      this._taxform.disable();
      this._SaveHide = true;
      this._ClearHide = true;
    }
    if(this._action == 'edit'){
      this._ClearHide = true;
    }
    this._IsProgressSpinner = true;
    this._TaxService.PageOnLoad(this._taxid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._taxstatus = resultJSON.taxStatus;
      if (this._taxstatus.length) {
        this._taxform.get("taxstatus").setValue(this._taxstatus[0].metasubcode);
      }
      this._taxform.get("effectivefrom").setValue(new Date());
      //For Update
      this._taxid = history.state.taxid;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        if (updateJSON.tax.effectiveto == null) {

        }
        else {
          updateJSON.tax.effectiveto = new Date(updateJSON.tax.effectiveto);
        }
        updateJSON.tax.effectivefrom = new Date(updateJSON.tax.effectivefrom);
        // updateJSON.tax.effectivefrom = new Date(updateJSON.tax.effectivefrom);
        // updateJSON.tax.effectiveto = new Date(updateJSON.tax.effectiveto);
        updateJSON.tax.accountingyear = new Date(updateJSON.tax.accountingyear).getFullYear();
        this._taxform.setValue(updateJSON.tax);
      }
      this._IsProgressSpinner = false;
    });
  }
  OnFromDateSelect(event) {
    this.minDate = event;
  }

  onSave() {
    this._submitted = true;
    // 11.check in the Tax master table weather Tax code. is already given, show error "Tax code already Exists"
    // 12.if all the data is valid allow to save the record in the database
    if (this._taxform.valid) {
      var Effectivefrom = this._taxform.get("effectivefrom").value;
      var Effectiveto = this._taxform.get("effectiveto").value;
      if (Effectiveto == null) {
        this._taxform.get("effectiveto").setValue(null);
      }
      else {
        var datePipe = new DatePipe("en-US");
        var peffectiveto = datePipe.transform(Effectiveto, 'yyyy-MM-dd');
        // this._Role.effectiveto = (new Date(peffectiveto));
        this._taxform.get("effectiveto").setValue(new Date(peffectiveto))
        // this._roleform.get("effectiveto").setValue(this._Role.effectiveto);
      }

      var datePipe = new DatePipe("en-US");
      var peffectivefrom = datePipe.transform(Effectivefrom, 'yyyy-MM-dd');
      this._Tax = this._taxform.value;
      this._Tax.effectivefrom = (new Date(peffectivefrom));
      if (peffectivefrom > peffectiveto) {
        this._CustomExceptionService.handleWarning("Effective From Date should be less than the Effective to Date");
        return;
      }
      this._Tax = this._taxform.value;
      if (this._action == 'create') {
        //this.datePipe.transform(this._Tax.);
        this._Tax.createdon = new Date();
        this._Tax.accountingyear = new Date("YYYY").getFullYear();
        this._Tax.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._TaxService.create(this._Tax).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            // 13.after saving the record get the response A, display the success message as "Tax A Saved Succesfully "
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        // this.onEd();
      }
      else if (this._action == 'edit') {
        //29. if all the data is valid allow to Update the record in the database With Respect To Tax code.
        //30. after Updating  the record, get the response A, display the success message as "Tax  A  Modified Succesfully "
        //31. After Update page should redirect to search page. And Search page grid should load with updated data.
        this._Tax.modifiedon = new Date();
        this._Tax.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._TaxService.edit(this._Tax).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
    // else {
    //   this._submitted=false;
    // }
  }
  //15. should redirect to search page.Search grid should load with updated data and that data should be in descending order
  //16. All input fields should be cleared.
  //19.  on clicking search button , the data must be filtered and display in the grid
  reset(event) {
    this._submitted = false;
    this._taxform.reset();
    this.InitializeForm();
  }
  goBack(event) {
    this._router.navigate(['/vTax']);
  }
  // onEd() {
  //   alert("test");
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._Tax;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);

  // }

  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

}
