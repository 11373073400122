<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Trial Balance Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_rpttrialbalancform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar name="fromdate" formControlName="fromdate" [showIcon]="true"
                                            [readonlyInput]="true" dateFormat="dd/mm/yy" [minDate]="mindate"
                                            [maxDate]="maxdate" [monthNavigator]="true" yearRange="1980:2030">
                                        </p-calendar>
                                        <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar name="todate" formControlName="todate" [showIcon]="true"
                                            [readonlyInput]="true" dateFormat="dd/mm/yy" [minDate]="mindate"
                                            [maxDate]="maxdate" [monthNavigator]="true" yearRange="1980:2030">
                                        </p-calendar>
                                        <label for="fromdate"> To Date <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [options]="_currencies" name="currencyid" optionLabel="currencyname"
                                            [autoDisplayFirst]="false" optionValue="currencyid"
                                            formControlName="currencyid">
                                        </p-dropdown>
                                        <label for="currencyid"> Currency <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_ParentAccountGroups" optionLabel="accountgroupname"
                                            [selectionLimit]="50" formControlName="parentaccountgroupid" (onChange)="onchangeparentgroup($event)"
                                            optionValue="accountgroupid" [virtualScroll]="true" itemSize="30">
                                        </p-multiSelect>
                                        <label for="accountgroupid">Parent Group</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_AccountGroups" optionLabel="accountgroupname"
                                            [selectionLimit]="50" formControlName="accountgroupid"
                                            optionValue="accountgroupid" [virtualScroll]="true" itemSize="30">
                                        </p-multiSelect>
                                        <label for="accountgroupid">Account Group</label>
                                    </span>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="p-formgroup-inline">
                                        <div class="p-field-checkbox">
                                            <p-checkbox name="includezero" binary="true" formControlName="includezero">
                                            </p-checkbox>
                                            <label for="includezero">Include Closing Balance Zero </label>
                                        </div>
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" icon="las la-search"
                                        (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)"
                                        class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                    </form>
                    <iframe #iframe height="500" width="100%" [src]="_src"></iframe>
                </div>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>