export class Documentverificationdetail {
    documentverificationdetailsid: number;
    documentid: number;
    documentno: string;
    documenttype: string;
    attachmenturl: string;
    attachmentname: string;
    verifieddatetime: Date;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    remarks: string;
    branchid: number;
    documentstatus: string;
    documentstatusremarks: string;
    isenable: boolean

}