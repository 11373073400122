import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Customer } from 'src/app/core/Models/Customer';
import { CustomerslabwisediscountService } from 'src/app/core/Services/Rewards/customerslabwisediscount.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { usererrors } from 'src/app/core/errors/usererrors';
import * as ace from "ace-builds";
import { Customerslabwisediscount } from 'src/app/core/Models/Customerslabwisediscount';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { DatePipe } from '@angular/common';
import { KeyPress } from 'src/assets/js/KeyPress';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Customerslabwisediscountdetail } from 'src/app/core/Models/Customerslabwisediscountdetail';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-c-customerslabwisediscount',
  templateUrl: './c-customerslabwisediscount.component.html',
  styleUrls: ['./c-customerslabwisediscount.component.css']
})

export class CCustomerslabwisediscountComponent implements OnInit {

  _customerslabwisediscountform: FormGroup;
  _action: string = "create";
  _submitted = false;
  selectedrows: any;
  _customerid: number;

  _slabcode: string = '';
  _isDisableSave: boolean = false;

  _customerslabwisediscountresult: Customerslabwisediscount[] = [];
  _customerslabwisediscount: Customerslabwisediscount[];
  _Customerslabwisediscounts: Customerslabwisediscount[] = [];
  _customerwisediscountdetailid: number;
  _Customernames: Customer;
  _Recordstatus: Metadatum;
  _customerslabdetails: Customerslabwisediscount[] = [];
  _customerwiseslabdiscountOBJ: any;
  _selectedCustomersIds: number[] = [];
  _prevSelectedCustomers: Customer[] = [];
  minDate: Date;
  _IsProgressSpinner: boolean = true;
  CustomerSlabdetails: Customerslabwisediscountdetail[];
  noDuplicateOccurs: boolean = true;
  duplicateCount = 0;
  isDisabledsendapparoval = true;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"];
  MinimumRange = false;
  _Action: IAction;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService,
    private _router: Router, private _CustomerslabwisediscountService: CustomerslabwisediscountService, private _hotkeysService: HotkeysService, public keypress: KeyPress
  ) {
    this._customerid = history.state?.customerid ? history.state?.customerid : 0;
    this._slabcode = history.state?.slabwisecode ? history.state?.slabwisecode : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._customerslabwisediscountform = this.fb.group({
      companyid: [],
      branchid: [],
      customerslabid: [],
      customerid: [],
      minimumvalue: [],
      maximumvalue: [],
      discountpercentage: [],
      effectivefrom: [],
      effectiveto: [],
      recordstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      customer: []
    });
    this._customerslabwisediscountform.controls['effectivefrom'].setValue(new Date());
    this.minDate = new Date();
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(83);
    this.utility.pageLoadScript();
    //Parent Form
    this.InitializeForm();

    this._IsProgressSpinner = true;
    this._CustomerslabwisediscountService.PageOnLoad(this._slabcode, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Customernames = resultJSON.customers;
      this._Recordstatus = resultJSON.recordStatus;
      this._IsProgressSpinner = false;

      // this._customerslabwisediscountform.controls['effectivefrom'].setValue(new Date());
      if (this._action == 'edit' || this._action == 'view') {
        //this._customerslabwisediscountform.disable();
        const updateJSON = JSON.parse(JSON.stringify(result));
        //this._slabcode = updateJSON.customerslabwisediscounts[0].slabwisecode;
        updateJSON.customerslabwisediscounts[0].effectivefrom = new Date(updateJSON.customerslabwisediscounts[0].effectivefrom);
        updateJSON.customerslabwisediscounts[0].effectiveto = new Date(updateJSON.customerslabwisediscounts[0].effectiveto);
        // this._customerslabwisediscountform.setValue(updateJSON.customerslabwisediscounts[0]);
        this._customerslabwisediscountform.controls['effectivefrom'].setValue(updateJSON.customerslabwisediscounts[0].effectivefrom);
        this._customerslabwisediscountform.controls['effectiveto'].setValue(updateJSON.customerslabwisediscounts[0].effectiveto);
        for (var i = 0; i < updateJSON.customerslabdiscountdetails.length; i++) {
          var l_slabdetail = new Customerslabwisediscount();
          l_slabdetail.customerslabid = updateJSON.customerslabwisediscounts[0].customerslabid;
          l_slabdetail.modifiedon = new Date();
          l_slabdetail.modifiedby = sessionStorage["userid"];
          l_slabdetail.createdby = sessionStorage["userid"];
          l_slabdetail.createdon = new Date();
          l_slabdetail.minimumvalue = updateJSON.customerslabdiscountdetails[i].minimumvalue;
          l_slabdetail.maximumvalue = updateJSON.customerslabdiscountdetails[i].maximumvalue;
          l_slabdetail.discountpercentage = updateJSON.customerslabdiscountdetails[i].discountpercentage;
          this._customerslabdetails.push(l_slabdetail);
        }
        // this._customerslabdetails. = this._customerslabdetails[i].minimumvalue;
        // this._customerslabdetails.maximumvalue = this._customerslabdetails[i].maximumvalue;
        // this._customerslabdetails.discountpercentage = this._customerslabdetails[i].discountpercentage;
        //for view the multiselect in pageload
        this._prevSelectedCustomers = updateJSON.customers;
        var customerIds: number[] = [];
        Object.keys(updateJSON.customers).map((Index) => {
          customerIds.push(updateJSON.customers[Index].customerid);
        });
        this._selectedCustomersIds = customerIds;
        //this._customerslabdetails = updateJSON.customerslabwisediscounts;
        this._customerslabwisediscount = updateJSON.customers;
      }
      if (this._action == 'view') {
        this._customerslabwisediscountform.disable();
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

    //To create the empty space in grid
    if (this._action == 'create') {
      var l_slabdetail = new Customerslabwisediscount();
      l_slabdetail.customerslabid = 0;
      l_slabdetail.minimumvalue = 0;
      l_slabdetail.maximumvalue = 0;
      l_slabdetail.discountpercentage = 0;

      l_slabdetail.modifiedon = new Date();
      l_slabdetail.modifiedby = sessionStorage["userid"];
      l_slabdetail.createdby = sessionStorage["userid"];
      l_slabdetail.createdon = new Date();

      // if (this._customerslabdetails.length > 0) {
      //   var count =0;
      //   for (var i = 0; i < this._customerslabdetails.length; i++) {
      //     if (l_slabdetail.minimumvalue == this._customerslabdetails[i].minimumvalue && l_slabdetail.maximumvalue ==this._customerslabdetails[i].maximumvalue ) {
      //       this._CustomExceptionService.handleWarning("Product or Variant Already Exists!");
      //       count +=1;
      //       return;
      //     }
      //   } 
      this._customerslabdetails.push(l_slabdetail);
      // }
    }
    if (this._action == 'edit') {
      this.isDisabledsendapparoval = false;
    }
    if (this._action == 'view') {
      this._customerslabwisediscountform.disable();
    }

  }
  //for date picker validation(from date,to date)
  OnFromDateSelect(event) {
    this.minDate = event
  }
  //for add the data in grid
  AddNewSlab(event) {
    var rowvalue = 0;


    for (var i = 0; i < this._customerslabdetails.length; i++) {
      if (this._customerslabdetails[i].maximumvalue == null || this._customerslabdetails[i].maximumvalue == 0) {

        this._CustomExceptionService.handleWarning("Fill the slab detail");
        rowvalue = 1;
      }
    }

    if (rowvalue == 0) {
      var l_slabdetail = new Customerslabwisediscount();
      l_slabdetail.customerslabid = 0;

      l_slabdetail.modifiedon = new Date();
      l_slabdetail.modifiedby = sessionStorage["userid"];
      l_slabdetail.createdby = sessionStorage["userid"];
      l_slabdetail.createdon = new Date();
      this._customerslabdetails.push(l_slabdetail);
      this.MinimumRange = true;
    }
    for (var i = 1; i < this._customerslabdetails.length; i++) {

      this._customerslabdetails[i].minimumvalue = this._customerslabdetails[i - 1].maximumvalue + 1;
    }


    // if (this._customerslabdetails.length > 0) {


    // }


  }
  RemoveVariant(pSlabdetail: Customerslabwisediscount) {
    if (this._customerslabdetails.length > 1) {
      var index = this._customerslabdetails.indexOf(pSlabdetail);
      this._customerslabdetails.splice(index, 1)
    }
    else {
      var index = this._customerslabdetails.indexOf(pSlabdetail);
      this._customerslabdetails.splice(index, 1);
      this.AddNewSlab('');
      this.MinimumRange = false;
    }
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  //For multiselect
  onSelectCustomers(event) {
    var slabcustomers: Customerslabwisediscount[] = [];
    var customers: Customerslabwisediscount;
    var l_customerlist: Customer = this._Customernames;
    Object.keys(event.value).map((Index) => {
      customers = new Customerslabwisediscount();
      customers.customerid = event.value[Index];
      slabcustomers.push(customers);
    });
    this._customerslabwisediscount = slabcustomers;
    var selectedcustomer = event.value;
    // if(selectedcustomer>0)
    // {
    //   this._CustomerslabwisediscountService.customersSlab(selectedcustomer).subscribe((result) => {
    //     const resutJSON = JSON.parse(JSON.stringify(result));
    //     if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
    //       this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
    //       this._IsProgressSpinner = false;
    //     }
    //     else {
    //       this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
    //     }
    //     this._IsProgressSpinner = false;
    //   },
    //     error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    // }
  }
  checkDuplicate(event, field, row) {
    if (this._customerslabdetails.length > 1) {
      this.duplicateCount = 0;
      this.noDuplicateOccurs = false;
      var value = "";
      var newValue = "";
      for (var i = 0; i < this._customerslabdetails.length; i++) {
        if (i == row) {
          continue;
        }
        else {
          value = this._customerslabdetails[i][field];
          newValue = event.target.value;
          if (value == newValue) {
            this._CustomExceptionService.handleWarning("Given " + newValue + " already exists at row" + row);
            this.duplicateCount = this.duplicateCount++;
            break;
          }
        }
      }
    }
    if (this.duplicateCount == 0) {
      this.noDuplicateOccurs = true;
    }
  }
  validateGrid() {
    var minValue;
    var maxValue;
    var discount;
    var valid;
    if (this._customerslabdetails.length != 0) {
      for (var i = 0; i < this._customerslabdetails.length; i++) {
        minValue = this._customerslabdetails[i].minimumvalue;
        maxValue = this._customerslabdetails[i].maximumvalue;
        discount = this._customerslabdetails[i].discountpercentage;
        if (!maxValue || !discount) {
          valid = false;
          this._CustomExceptionService.handleWarning("Fill slab discount details");
          break;
        }
        if (minValue == maxValue || maxValue < minValue) {
          valid = false;
          this._CustomExceptionService.handleWarning("Maximum value must be greater than minimum value");
          break;
        }
        if (discount > 100) {
          valid = false;
          this._CustomExceptionService.handleWarning("Discount percentage should not be greater than 100");
          break;
        }
        else {
          valid = true;
        }
      }
    }
    return valid;
  }
  onSave() {
    if ((this._selectedCustomersIds||[]).length == 0) {
      this._CustomExceptionService.handleError("Please select atleast one customer");
      return;
    }
    var EffectivefromDate = this._customerslabwisediscountform.get("effectivefrom").value;
    var EffectivetoDate = this._customerslabwisediscountform.get("effectiveto").value;
    var datePipe = new DatePipe("en-US");
    var pEffectivefromDate = datePipe.transform(EffectivefromDate, 'yyyy-MM-dd');
    var pEffectivetoDate = datePipe.transform(EffectivetoDate, 'yyyy-MM-dd');
    if (!this._customerslabwisediscountform.get("effectivefrom").value) {
      this._CustomExceptionService.handleError("Please select Effective From");
      return;
    }
    if (!this._customerslabwisediscountform.get("effectiveto").value) {
      this._CustomExceptionService.handleError("Please select Effective To");
      return;
    }
    if (pEffectivefromDate > pEffectivetoDate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._submitted = true;
    var validGrid = this.validateGrid();
    if (this._customerslabwisediscountform.valid && validGrid == true && this.noDuplicateOccurs == true) {

      //for loop condition for save the formgroup and grid in the same list

      this.CustomerSlabdetails = [];
      for (var i = 0; i < (this._customerslabdetails || []).length; i++) {
        var _customerslabwisediscountdetail = new Customerslabwisediscountdetail();
        _customerslabwisediscountdetail.companyid = 0;
        _customerslabwisediscountdetail.branchid = sessionStorage["BranchID"];
        _customerslabwisediscountdetail.customerslabid = 0;
        _customerslabwisediscountdetail.customerslabdetailid = 0;
        _customerslabwisediscountdetail.minimumvalue = this._customerslabdetails[i].minimumvalue;
        _customerslabwisediscountdetail.maximumvalue = this._customerslabdetails[i].maximumvalue;
        _customerslabwisediscountdetail.discountpercentage = this._customerslabdetails[i].discountpercentage;
        _customerslabwisediscountdetail.recordstatus = "MSC00001";
        _customerslabwisediscountdetail.createdby = sessionStorage["userid"];
        _customerslabwisediscountdetail.createdon = new Date();
        _customerslabwisediscountdetail.modifiedby = sessionStorage["userid"];
        _customerslabwisediscountdetail.modifiedon = new Date();
        this.CustomerSlabdetails.push(_customerslabwisediscountdetail);
      }
      this._customerslabwisediscountresult = [];
      Object.keys(this._customerslabwisediscount).map((value, index, array) => {
        var _customerslabwisediscounts = new Customerslabwisediscount;
        _customerslabwisediscounts.companyid = 0;
        _customerslabwisediscounts.slabwisecode = this._customerslabwisediscount[index].slabwisecode;
        _customerslabwisediscounts.branchid = sessionStorage["BranchID"];
        _customerslabwisediscounts.customerslabid = 0;
        _customerslabwisediscounts.customerid = this._customerslabwisediscount[index].customerid;
        _customerslabwisediscounts.effectivefrom = (new Date(pEffectivefromDate));
        _customerslabwisediscounts.effectiveto = (new Date(pEffectivetoDate));
        _customerslabwisediscounts.recordstatus = "MSC00045";
        _customerslabwisediscounts.createdby = sessionStorage["userid"];
        _customerslabwisediscounts.createdon = new Date();
        _customerslabwisediscounts.modifiedby = sessionStorage["userid"];
        _customerslabwisediscounts.modifiedon = new Date();
        this._customerslabwisediscountresult.push(_customerslabwisediscounts);
      });
      if (this._customerslabwisediscountform.valid) {
        // this._customerslabwisediscountresult = this.CustomerSlabdetails;
        this._customerwiseslabdiscountOBJ = { "Customerslabwisediscount": this._customerslabwisediscountresult, "Customerslabwisediscountdetail": this.CustomerSlabdetails };
        if (this._action == 'create') {
          // this.onEd();
          this._IsProgressSpinner = true;
          this._CustomerslabwisediscountService.create(this._customerwiseslabdiscountOBJ).subscribe((result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
              this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
              this._slabcode = resutJSON.slabcode;
              this.isDisabledsendapparoval = false;
              this._isDisableSave = true;
              this._IsProgressSpinner = false;
            }
            else {
              this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            }
            this._IsProgressSpinner = false;
          },
            error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

        }
        // For Edit      
        else if (this._action == 'edit') {
          // this.onEd();
          this._IsProgressSpinner = true;
          this._CustomerslabwisediscountService.edit(this._customerwiseslabdiscountOBJ).subscribe((result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
              //this._slabcode = resutJSON.slabcode;
              this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
              this._IsProgressSpinner = false;
            }
            else {
              this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            }
            this._IsProgressSpinner = false;
          },
            error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

        }
      }
    }
    else {
      // this._CustomExceptionService.handleWarning("Fill slab discount details");
    }
  }
  reset(event) {
    this._customerslabwisediscountform.reset();
    this._submitted = false;
    this._customerslabdetails = [];
    if (this._action == 'create') {
      var l_slabdetail = new Customerslabwisediscount();
      l_slabdetail.customerslabid = this._customerslabwisediscountform.get("customerslabid").value;
      l_slabdetail.customerid = this._customerslabwisediscountform.get("customerid").value;
      l_slabdetail.minimumvalue = this._customerslabwisediscountform.get("minimumvalue").value;
      l_slabdetail.maximumvalue = this._customerslabwisediscountform.get("maximumvalue").value;
      l_slabdetail.discountpercentage = this._customerslabwisediscountform.get("discountpercentage").value;
      l_slabdetail.modifiedon = new Date();
      l_slabdetail.modifiedby = sessionStorage["userid"];
      l_slabdetail.createdby = sessionStorage["userid"];
      l_slabdetail.createdon = new Date();
      this._customerslabdetails.push(l_slabdetail);

    }
    this._isDisableSave = false;
    this.InitializeForm();
  }
  goBack($event) {
    this._router.navigate(['/vCustomerslabwisediscount']);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

  // onEd() {
  //   alert("test");
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._customerwiseslabdiscountOBJ;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }
  SendToApproval(event) {
    var workflowid = 32;
    this._userid = sessionStorage["userid"];
    this._branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;
    //this._productid= this._customerwisediscountform.get("productid").setValue(this._customerwisediscountform.get("productid").value);
    this._CustomerslabwisediscountService.SendToApproval(workflowid, this._userid, this._branchid, this._slabcode).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
        this.reset(null);
        this.isDisabledsendapparoval = true;
        this._IsProgressSpinner = false;
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
