import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Country } from 'src/app/core/Models/Country';
import { Currency } from 'src/app/core/Models/Currency';
import { Importentry } from 'src/app/core/Models/Importentry';
import { Importentryaccountdetail } from 'src/app/core/Models/Importentryaccountdetail';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Port } from 'src/app/core/Models/Port';
import { Supplier } from 'src/app/core/Models/Supplier';
import { ImportentryService } from 'src/app/core/Services/tracking/importentry.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { vSelectImportDate, vSelectImportCountry, vSelectSupplierName, vSupplierCurrency, vAgentName, vDate, vBENo, vSelectPort, vContainerNo, vBLNo, vBLDate, vSupplierInvoiceNo, vAgentCurrency, vSupplierInvoiceAmount, vAgentInvoiceAmont, vAgentInvoiceNo, vImportnoSelect } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Containertracking } from 'src/app/core/Models/Containertracking';
import { Paymentadvancereferenceview } from 'src/app/core/Views/Paymentadvancereferenceview';
import { Importentryreference } from 'src/app/core/Models/ImportentryReference';
import { Supplierview } from 'src/app/core/Views/Supplierview';
import { Importentrychargesdetailview } from 'src/app/core/Views/Importentrychargesdetailview';
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { Console } from 'console';
import { ConfirmationService } from 'primeng/api';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';


@Component({
  selector: 'app-c-importentry',
  templateUrl: './c-importentry.component.html',
  styleUrls: ['./c-importentry.component.css']
})
export class CImportentryComponent implements OnInit {

  selectedtab: number = 0;
  _importentrychargesform: FormGroup;
  importentrychargesdetails: Importentrychargesdetailview[] = [];
  otherchargeslist: Othercharge[] = [];
  totalChargeAmount: number = 0;
  _importentryform: FormGroup;
  _importentry: Importentry;
  _agentaccountdetails: any[] = [];
  _referencedetails: any[] = [];
  _selectedreference: any[] = [];
  _accountdetail: any;
  _userid: number;
  _branchid: number;
  isDisabledsendtoapproval = true;
  _importentryid: string;
  //5. "Country" should load from Country master, it contains  Country name, code.
  _Importcountry: Country;
  // <!-- 4."Supplier Name" should load from supplier master, it contains  supplier name, code. -->   
  _Suppliername: Supplierview[];
  _Suppliercurrencies: Currency
  _Agentcurrencies: Currency;
  // <!-- 6. "Agent Name" should load from "Supplier Master", it contains Agent name,code. -->
  _Agentname: Supplierview[];
  _TempAgentname: Supplierview[];
  _Portname: Port;
  _Shippingline: Metadatum;
  _referenceno: Paymentadvancereferenceview[] = [];
  isDisabledSave: boolean = false;
  isDisabledClear: boolean = false;
  isDisabledimport = false;
  _action: string = "create";
  _submitted = false;
  _submittedchargeform = false;
  selectedrows: any;
  selectedProductDetailsRows: any;
  _importentryOBJ: any;
  _IsProgressSpinner: boolean = true;
  isDisabledreferno: boolean;
  _ContainerNo: Containertracking[] = [];
  _TempContainerNo: any;
  _ContainerDetail: any;
  _PaymentStatus: any[] = [];
  editallowed: any;
  expenseledgers: Accountledgerview[] = [];
  _Action:IAction


  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _router: Router, private _CustomExceptionService: CustomExceptionService,
    private _ImportentryService: ImportentryService, private _hotkeysService: HotkeysService, 
    private _AccessRightsService : AccessRightsService,
    private confirmationService: ConfirmationService) {
    this._importentryid = history.state?.importno ? history.state?.importno : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.editallowed = history.state?.editallowed ? history.state?.editallowed : 0;
    this.HotKeyIntegration();
  }
  InitializeForm() {

    this._importentryform = this.fb.group({
      companyid: [],
      branchid: [],
      importid: [0],
      importno: [],
      importdate: [''],
      //2.Import Date: current date should be load as default date.
      importcountry: ['', vSelectImportCountry],
      supplierid: ['', vSelectSupplierName],
      suppliercurrencyid: [''],
      agentid: ['', vAgentName],
      date: [''],
      beno: [''],
      bedate: [],
      shippingline: [],
      portno: ['', vSelectPort],
      containerno: ['', vContainerNo],
      value: [],
      blno: ['', vBLNo],
      bldate: [''],
      supplierinvoiceno: [''],
      importstatus: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      agentcurrencyid: [''],
      supplierinvoiceamount: [''],
      agentinvoiceamount: ['', vAgentInvoiceAmont],
      agentinvoiceno: ['', vAgentInvoiceNo],
      importentryaccountdetails: [],
      advanceamount: [0],
      jvoucherno: [],
      containertrackingid: [],
      importentryreferences: [],
      currencyreferenceid: [],
      importentrychargesdetails: [],
      paymentstatus: ['', vSelectImportCountry]

    });
    this._importentryform.controls['importdate'].setValue(new Date());
    this._importentryform.controls['paymentstatus'].setValue("MSC00176");
    this._importentryform.get('paymentstatus').disable();

    this.InitializeChargesForm();

  }

  InitializeChargesForm() {
    this._importentrychargesform = this.fb.group({
      chargesid: ['', vSelectSupplierName],
      amount: [0, vSelectSupplierName]
    });
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(45);
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;
    this._userid = sessionStorage["userid"];

    this._ImportentryService.PageOnload(this._importentryid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Suppliercurrencies = resultJSON.currencies;
      this._Agentcurrencies = resultJSON.currencies;
      // this._Suppliername = resultJSON.suppliers;
      this._Portname = resultJSON.ports
      this._Agentname = resultJSON.agents;
      this._TempAgentname = resultJSON.agents;
      this._Importcountry = resultJSON.countries;
      this._Shippingline = resultJSON.shippingline;
      this.otherchargeslist = resultJSON.otherchargesList;
      this.expenseledgers = resultJSON.expenseledgers;

      this._PaymentStatus = resultJSON.paymentstatus;
      this._importentryform.controls['importdate'].setValue(new Date());
      this._importentryform.controls['date'].setValue(new Date());
      this._importentryform.controls['bedate'].setValue(new Date());
      this._importentryform.controls['bldate'].setValue(new Date());

      this._IsProgressSpinner = false;
      // 33.View Initialize should load all fields based on search page selected Import No. and all fields should be in read-only mode.
      if (this._action == 'view') {
        this._importentryform.disable();
        this.isDisabledSave = true;
        this.isDisabledClear = true;
        this.isDisabledimport = true;
      }

      // var currencyid = this._Suppliername[0].suppliercurrencyid;
      // this._importentryform.controls['suppliercurrencyid'].setValue(currencyid);

      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._Suppliername = updateJSON.suppliers;
        this.isDisabledClear = true;
        this.importentrychargesdetails = (updateJSON._importentrychargesdetails || []);
        updateJSON.importentry.importdate = new Date(updateJSON.importentry.importdate);
        updateJSON.importentry.date = new Date(updateJSON.importentry.date);
        if (updateJSON.importentry.bedate == null) {

        }
        else {
          updateJSON.importentry.bedate = new Date(updateJSON.importentry.bedate);
        }

        if (updateJSON.importentry.bldate == null) {

        }
        else {
          updateJSON.importentry.bldate = new Date(updateJSON.importentry.bldate);
        }

        this._importentryform.setValue(updateJSON.importentry);
        this._importentryform.controls['shippingline'].setValue(parseInt(updateJSON.importentry.shippingline));
        this._importentryform.controls['portno'].setValue(parseInt(updateJSON.importentry.portno));
        this._agentaccountdetails = updateJSON.advancepayments;
        this._referenceno = updateJSON.importentryreferences;
        this._ContainerNo = updateJSON.containertrackings;
        this._TempContainerNo = updateJSON.containertrackings;
        this._selectedreference = updateJSON.importentryreferences;
        var referencenos: any[] = [];
        Object.keys(resultJSON.importentryreferences).map((Index) => {
          referencenos.push(updateJSON.importentryreferences[Index].refNo);
        });
        this._selectedreference = referencenos;

        this.CalculateChargesAmount();
        if (this._action == 'edit' && this.editallowed == 1) {
          this._importentryform.disable();
          this._importentrychargesform.disable();
          this._importentryform.get('paymentstatus').enable();
        }
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }

  Onchangesupplier(event) {
    var Supplierid = event.value;
    var _currencyname = (this._Suppliername || []).filter(f => f.supplierid == Supplierid)[0].currencyid;
    this._importentryform.controls['suppliercurrencyid'].setValue(_currencyname);
    this._IsProgressSpinner = true;
    this._ImportentryService.GetContainerNos(Supplierid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._ContainerNo = resultJSON.containertrackings
        this._TempContainerNo = resultJSON.containertrackings
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  // On Container Change:
  OnchangeContainer(event) {
    var Containertrackingid = (this._ContainerNo || []).filter(f => f.containerno == event.value)[0].containertrackingid;
    this._ImportentryService.GetContainerDetails(Containertrackingid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        var cont = resultJSON.containertrackings[0];
        this._Agentname = this._TempAgentname.filter(s => s.supplierid == cont.borderagent || s.supplierid == cont.portagent || s.supplierid == cont.localtransporter);
        resultJSON.containertrackings[0].portbldate = new Date(resultJSON.containertrackings[0].portbldate);
        this._ContainerDetail = resultJSON.containertrackings
        this._importentryform.controls['blno'].setValue(resultJSON.containertrackings[0].blno);
        this._importentryform.controls['portno'].setValue(resultJSON.containertrackings[0].portid);
        this._importentryform.controls['shippingline'].setValue(resultJSON.containertrackings[0].shippingline);
        this._importentryform.get('paymentstatus').enable();
        this._importentryform.get('portno').disable();
        this._importentryform.get('shippingline').disable();
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  Onchangeagent() {
    var Agentid = this._importentryform.controls['agentid'].value;
    var containerno = this._importentryform.get("containerno").value;
    this._importentryform.get("advanceamount").setValue(0);
    this._selectedreference = [];
    this._agentaccountdetails = [];
    this._referenceno = [];
    if (!Agentid || !containerno)
      return;
    if (!this._importentryform.controls['agentcurrencyid'].value) {
      var _currencyname = (this._Agentname || []).filter(f => f.supplierid == Agentid)[0].currencyid;
      this._importentryform.controls['agentcurrencyid'].setValue(_currencyname);
    }
    // 10.check, if selected agent have any previous transtations, than fetch all the previous transations in the Account Details grid.
    this._IsProgressSpinner = true;
    var currencyid = this._importentryform.controls['agentcurrencyid'].value || 1;
    this._ImportentryService.getAgentAccountDetails(Agentid, currencyid, containerno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this._agentaccountdetails = resultJSON.advancepayments;
      this._referenceno = resultJSON.paymentadvancereference;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  Onchangecountry(event) {
    var countryid = event.value;
    this._IsProgressSpinner = true;
    this._ImportentryService.getSupplierDetails(countryid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this._Suppliername = resultJSON.countrybasedsuppliers;
      // this._agentaccountdetails[0].accountname = resultJSON.accountname;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  AgentInvoiceAmount() {
    var AgentAmount = this._importentryform.get("agentinvoiceamount").value || 0;
    for (var p = 0; p < this._agentaccountdetails.length; p++) {
      this._agentaccountdetails[p].amount = AgentAmount;
      this._agentaccountdetails[p].totalamount = this._agentaccountdetails[p].amount + this._agentaccountdetails[p].balance;
    }
  }

  onSelectreferenceno(event) {
    var refno = event.value;
    var amount = 0;
    var l_Assitantces: Importentryreference[] = [];
    var l_Assitantce: Importentryreference;
    for (var i = 0; i < refno.length; i++) {
      var val = refno[i];
      amount += (this._referenceno || []).find(f => f.refNo == refno[i]).remainingamount;
      var reflist = (this._referenceno || []).find(f => f.refNo == refno[i]);
      l_Assitantce = new Importentryreference();
      l_Assitantce.refNo = refno[i];
      l_Assitantce.currencyid = this._importentryform.controls['agentcurrencyid'].value;
      l_Assitantce.currencyreferenceid = reflist.currencyuniquerefid;
      l_Assitantce.advanceamount = (this._referenceno || []).find(f => f.refNo == refno[i]).remainingamount;
      l_Assitantces.push(l_Assitantce);
    }
    this._importentryform.get("advanceamount").setValue(amount);

    this._referencedetails = l_Assitantces;
  }


  onSave() {

    this._submitted = true;
    var pBEDate, pBLDate;
    if (this._importentryform.valid) {
      var ImportDate = this._importentryform.get("importdate").value;
      var TDate = this._importentryform.get("date").value;
      var BEDate = this._importentryform.get("bedate").value;
      var BLDate = this._importentryform.get("bldate").value;
      var datePipe = new DatePipe("en-US");
      var pImportDate = datePipe.transform(ImportDate, 'yyyy-MM-dd');
      var pDate = datePipe.transform(TDate, 'yyyy-MM-dd');
      pBEDate = datePipe.transform(BEDate, 'yyyy-MM-dd');
      pBLDate = datePipe.transform(BLDate, 'yyyy-MM-dd');
      this._importentry = this._importentryform.getRawValue();

      if (this._importentry.agentinvoiceamount.toFixed(2) !== this.totalChargeAmount.toFixed(2)) {
        this._CustomExceptionService.handleWarning("Supplier Invoice amount not matched with charge breakups.");
        return;
      }

      this._importentry.importdate = (new Date(pImportDate));
      this._importentry.date = (new Date(pDate));
      if (pBEDate == null) {
        // this._schemeform.get("effectiveto").setValue(null);
        pBEDate = this._importentryform.get("containertrackingdate").setValue(null);
      }
      else {
        pBEDate = datePipe.transform(pBEDate, 'yyyy-MM-dd');
      }
      if (pBLDate == null) {
        pBLDate = this._importentryform.get("shippingdate").setValue(null);
      }
      else {
        pBLDate = datePipe.transform(pBLDate, 'yyyy-MM-dd');
      }
      //  this._importentry.bedate = (new Date(pBEDate));
      //  this._importentry.bldate = (new Date(pBLDate));
      //Debug
      this._importentryOBJ = {
        "Importentry": this._importentry, "Importentryaccountdetail": this._agentaccountdetails, "Importentryreferencedetail": this._referencedetails,
        "ImportEntryChargesDetail": this.importentrychargesdetails
      };
      if (this._action == 'create') {
        this._importentry.createdby = sessionStorage["userid"];
        this._importentry.companyid = 0;
        this._importentry.createdon = new Date();
        this._importentry.importstatus = "MSC00001";
        this._importentry.branchid = sessionStorage["BranchID"];
        this._importentry.accountingyear = new Date().getFullYear();
        //this.onEd();
        this._IsProgressSpinner = true;
        this._ImportentryService.create(this._importentryOBJ).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          //  this._importentryid = resultJSON.importentry.importid;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsProgressSpinner = false;
            this.isDisabledsendtoapproval = false;
            this.reset(null);
            this._agentaccountdetails = [];
            this._submitted = false;

          }
          else {
            this._IsProgressSpinner = false;
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },

          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

        // this.isDisabledSave = true;
        // this.isDisabledClear = true;
      }
      else if (this._action == 'edit') {
        // 52.After Update page should redirect to search page. And Search page grid should load with updated data.
        this._importentry.modifiedby = sessionStorage["userid"];
        this._importentry.modifiedon = new Date();
        this._IsProgressSpinner = true;
        this._ImportentryService.edit(this._importentryOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          // this._importentryid = resutJSON.importentry.importid;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            // 51.after Updating  the record, get the response A, display the success message as "Import Entry  A  Modified Succesfully "
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this._IsProgressSpinner = false;
            this.isDisabledsendtoapproval = false;
            this._submitted = false;

          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

        // this.isDisabledSave = true;
        // this.isDisabledClear = true;
      }
    }
  }
  SendToApproval(event) {
    var workflowid = 47;
    this._userid = sessionStorage["userid"];
    this._branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;
    //this._productid= this._damagedproductpricedetailsform.get("productid").setValue(this._damagedproductpricedetailsform.get("productid").value);
    this._ImportentryService.SendToApproval(workflowid, this._userid, this._branchid, this._importentryid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
        this.isDisabledsendtoapproval = true;
        this._IsProgressSpinner = false;
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  goBack(event) {
    // 27.should redirect to search page. Search grid should load with updated data and that data should be in descending order
    this._router.navigate(['/vImportentry']);
  }
  reset(event) {
    // 26.All input fields should be cleared.
    this._submitted = true;
    this.isDisabledSave = false;
    this.isDisabledClear = false;
    this._importentryform.reset();
    this.InitializeForm();
    this._agentaccountdetails = [];
    this.importentrychargesdetails = [];
    this._referenceno = [];
    this._selectedreference = [];
    this._submitted = false;
    this._importentryform.controls['importdate'].setValue(new Date());
    this._importentryform.controls['date'].setValue(new Date());
    this._importentryform.controls['bedate'].setValue(new Date());
    this._importentryform.controls['bldate'].setValue(new Date());
    this.totalChargeAmount = 0;
    this._importentryform.get('paymentstatus').disable();
  }


  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._importentryOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }

  onAddCharges() {
    this._submittedchargeform = true;
    if (!this._importentrychargesform.valid) return;

    let charge: Importentrychargesdetailview = (<Importentrychargesdetailview>this._importentrychargesform.value);
    charge.importentrychargesdetailsid = 0;
    charge.importid = 0;
    charge.amount = parseFloat(parseFloat(charge.amount + '' || '0').toFixed(2) || '0')
    charge.currencyid = this._importentryform.get("agentcurrencyid").value;

    if (charge.amount <= 0) {
      this._CustomExceptionService.handleWarning("Please enter a valid charge amount");
      return;
    }

    if (!charge.currencyid) {
      this._CustomExceptionService.handleWarning("Please choose currency.");
      return;
    }

    let hasExists = (this.importentrychargesdetails || []).some(s => s.chargesid == charge.chargesid);

    if (hasExists) {
      this._CustomExceptionService.handleWarning("Charge already exists.");
      return;
    }

    let ot = (this.expenseledgers || []).find(f => f.ledgerid == charge.chargesid);
    if (ot) {
      charge.otherchargename = ot.name;
    }
    this.importentrychargesdetails.push(charge);
    this.OnClearCharges();
    this.CalculateChargesAmount();
  }

  OnClearCharges() {
    this._submittedchargeform = false;
    this._importentrychargesform.reset();
    this.InitializeChargesForm();
  }
  OnRemoveCharges(charge: Importentrychargesdetailview) {
    let index = (this.importentrychargesdetails || []).findIndex(f => f == charge);
    if (index > -1) {
      (this.importentrychargesdetails || []).splice(index, 1);
    }
    this.CalculateChargesAmount();
  }

  CalculateChargesAmount() {
    this.totalChargeAmount = 0;
    (this.importentrychargesdetails || []).forEach(element => {
      this.totalChargeAmount += element.amount;
    });
  }

  ChangePaymentStatus() {
    if (this._importentryform.get("paymentstatus").value == "MSC00177") {
      this.confirmationService.confirm({
        message: 'Are You Sure to Close Import Entry for this Container No. Kindly Ensure the Payment Entry completion. ?',
        accept: () => {
          //Actual logic to perform a confirmation

        },
        reject: () => {
          this._importentryform.controls['paymentstatus'].setValue("MSC00176");
          return;
        }

      });


    }



  }

  ConfirmPaymentStatusSave() {
    if (this._importentryform.get("paymentstatus").value == "MSC00177") {
      this.confirmationService.confirm({
        message: 'Are You Sure to Close Import Entry for this Container No. Kindly Ensure the Payment Entry completion. ?',
        accept: () => {
          //Actual logic to perform a confirmation
          this.onSave();
        },
        reject: () => {
          this._importentryform.controls['paymentstatus'].setValue("MSC00176");
          return;
        }

      });


    }
    else {
      this.onSave();
    }
  }
}
