import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptreceivablespayablesService {
  public _Action: IAction;
  public _httpOptions;
  public _RptreceivablespayablesService = environment.RptreceivablespayablesService;
  constructor(private http: HttpClient) { }

  PageOnload(): Observable<any> {
    var Query = this._RptreceivablespayablesService + '/PageOnLoad';
    return this.http.get<{ data: any }>(Query);
  }

  PayableReceivableReport(object : Object): Observable<any> {
    var Query = this._RptreceivablespayablesService + '/PayableReceivableReport';
    return this.http.post<{ data: any }>(Query,object);
  }
}
