import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Shippingchargesheader } from 'src/app/core/Models/Shippingchargesheader';
import { Shippingchargeskmbasedcost } from 'src/app/core/Models/Shippingchargeskmbasedcost';
import { Shippingchargesweightbasedcost } from 'src/app/core/Models/Shippingchargesweightbased';
import { ShippingService } from 'src/app/core/Services/ecommerce/shipping.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { KeyPress } from 'src/assets/js/KeyPress';

@Component({
  selector: 'app-shipping-charges',
  templateUrl: './shipping-charges.component.html',
  styleUrls: ['./shipping-charges.component.css']
})
export class ShippingChargesComponent implements OnInit {
  ShippingChargesWeightbased: Shippingchargesweightbasedcost[];
  deliveryTypeList = [];
  deliveryType = [];
  UOMTypeList = [];
  UOMType = [];
  suburbNameList = [];
  suburbName = [];
  distanceMasterGrid: Shippingchargeskmbasedcost[];
  ShippingObj: any;
  isDisabledminimumkm = true;
  _shippingform: FormGroup;
  _IsProgressSpinner: boolean = false;
  _DeliveryTypes: Metadatum;
  _UOM: Metadatum
  _ShippingChargeHeader: any;
  removeshippingchargeskmid:number[]=[];
  removeshippingchargesweightid:number[]=[];
  constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, public keypress: KeyPress, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService,
    private _ShippingService: ShippingService, private confirmationService: ConfirmationService) { }
  InitiliazeForm() {
    this._shippingform = this.fb.group({
      additionalcostperkg: [],
      additionalcostperkmexceed: [],
      // maxallowedweightatfreeofcost:[]
    })
  }
  ngOnInit(): void {
    this.InitiliazeForm();
    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;
    this._ShippingService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // console.log("PageLoad Data", resultJSON);
      this._DeliveryTypes = resultJSON.deliverytypelst;
      this._UOM = resultJSON.uom;
      this._ShippingChargeHeader = resultJSON.scheader || []
      this.distanceMasterGrid = resultJSON.shippingchargeskmbasedcosts || []
      this.ShippingChargesWeightbased = resultJSON.shippingchargesweightbasedcosts || []
      this._shippingform.controls["additionalcostperkmexceed"].setValue(this.distanceMasterGrid.length > 0 ? this.distanceMasterGrid[0].additionalcostperkmexceed : 0)
      this._shippingform.controls["additionalcostperkg"].setValue(this.ShippingChargesWeightbased.length > 0 ? this.ShippingChargesWeightbased[0].additionalcostperkg : 0)


      if (this.distanceMasterGrid.length == 0) {
        this.distanceMasterGrid = [];
        var l_distance = new Shippingchargeskmbasedcost();
        l_distance.sckmid = 0;
        l_distance.scid = 0;
        l_distance.minkm = 0;
        // l_distance.maxkm = 0;
        l_distance.currencyid = 1;
        l_distance.normaldeliverycost = 0;
        l_distance.expressdeliverycost = 0;
        l_distance.maxallowedweightatfreeofcost = 1;
        l_distance.additionalcostperkmexceed = 1;
        l_distance.recordstatus = "MSC00001";
        l_distance.createdby = sessionStorage["userid"];
        l_distance.modifiedby = sessionStorage["userid"];
        l_distance.createdon = new Date().toLocaleString();
        l_distance.modifiedon = new Date().toLocaleString();
        this.distanceMasterGrid.push(l_distance);
      }
      if (this.ShippingChargesWeightbased.length == 0) {
        this.ShippingChargesWeightbased = [];
        var l_weight = new Shippingchargesweightbasedcost();
        l_weight.scid = 0;
        l_weight.scwid = 0;
        l_weight.currencyid = 1;
        l_weight.minweight = 0;
        // l_weight.maxweight = 0;
        l_weight.basecost = 0;
        l_weight.createdby = sessionStorage["userid"];
        l_weight.modifiedby = sessionStorage["userid"];
        l_weight.createdon = new Date().toLocaleString();
        l_weight.modifiedon = new Date().toLocaleString();
        this.ShippingChargesWeightbased.push(l_weight);
      }
      this._IsProgressSpinner = false;
      this.KillometerBasedDisabled();
      this.weightBasedDisabled();
    });

  }
  KillometerBasedDisabled() {
    let distanceMasterGridlength = this.distanceMasterGrid.length;
    distanceMasterGridlength = distanceMasterGridlength - 1;
    for (var i = 0; i < this.distanceMasterGrid.length; i++) {
      if (distanceMasterGridlength == i) {
        this.distanceMasterGrid[i].isdisabledremove = false;
      } else {
        this.distanceMasterGrid[i].isdisabledremove = true;
      }
    }
  }

  weightBasedDisabled() {
    let weightbasedlength = this.ShippingChargesWeightbased.length;
    weightbasedlength = weightbasedlength - 1;
    for (var i = 0; i < this.ShippingChargesWeightbased.length; i++) {
      if (weightbasedlength == i) {
        this.ShippingChargesWeightbased[i].isdisabledremove = false;
      } else {
        this.ShippingChargesWeightbased[i].isdisabledremove = true;
      }
    }
  }
  addkilometer(km) {
    //   let kmExists = (this.distanceMasterGrid.filter(s => s.maxkm > 0).length == this.distanceMasterGrid.length) && this.distanceMasterGrid.some(s => s.maxkm > 0);
    //   if (!kmExists){
    //     this._CustomExceptionService.handleWarning("Fill the km details");
    //     return;
    // }
    var rowvalue = 0;
    for (var i = 0; i < this.distanceMasterGrid.length; i++) {
      if (this.distanceMasterGrid[i].maxkm == null || this.distanceMasterGrid[i].maxkm == 0) {
        this._CustomExceptionService.handleWarning("Fill the km details");
        rowvalue = 1;
      }
      if (this.distanceMasterGrid[i].minkm > this.distanceMasterGrid[i].maxkm) {
        this._CustomExceptionService.handleError("Maximum Kilometer should greater than minimum Killometer");
        return;
      }
    }
    if (rowvalue == 0) {

      var l_distance = new Shippingchargeskmbasedcost();
      l_distance.sckmid = 0;
      l_distance.scid = 0;
      l_distance.minkm = 0;
      // l_distance.maxkm = 0;
      l_distance.currencyid = 1;
      l_distance.normaldeliverycost = 0;
      l_distance.expressdeliverycost = 0;
      l_distance.maxallowedweightatfreeofcost = 0;
      l_distance.additionalcostperkmexceed = 0;
      l_distance.recordstatus = "MSC00001";
      l_distance.createdby = sessionStorage["userid"];
      l_distance.modifiedby = sessionStorage["userid"];
      l_distance.createdon = new Date().toLocaleString();
      l_distance.modifiedon = new Date().toLocaleString();
      this.distanceMasterGrid.push(l_distance);
    }
    for (var i = 1; i < this.distanceMasterGrid.length; i++) {

      this.distanceMasterGrid[i].minkm = this.distanceMasterGrid[i - 1].maxkm + 0.01;
    }
    this.KillometerBasedDisabled();
  }
  Removekilometer(km) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to Delete this row ?',
      accept: () => {
        this.removeshippingchargeskmid.push(km.sckmid)
        var index = this.distanceMasterGrid.indexOf(km);
        this.distanceMasterGrid.splice(index, 1);
        if (this.distanceMasterGrid.length <= 0) {
          var l_distance = new Shippingchargeskmbasedcost();
          l_distance.sckmid = 0;
          l_distance.scid = 0;
          l_distance.minkm = 0;
          // l_distance.maxkm = 0;
          l_distance.currencyid = 1;
          l_distance.normaldeliverycost = 0;
          l_distance.expressdeliverycost = 0;
          l_distance.maxallowedweightatfreeofcost = 0;
          l_distance.additionalcostperkmexceed = 0;
          l_distance.recordstatus = "MSC00001";
          l_distance.createdby = sessionStorage["userid"];
          l_distance.modifiedby = sessionStorage["userid"];
          l_distance.createdon = new Date().toLocaleString();
          l_distance.modifiedon = new Date().toLocaleString();
          this.distanceMasterGrid.push(l_distance);
        }
        this.KillometerBasedDisabled();
      }
    });

  }

  addweight(weight) {
    var rowvalue = 0;
    for (var i = 0; i < this.ShippingChargesWeightbased.length; i++) {
      if (this.ShippingChargesWeightbased[i].maxweight == null || this.ShippingChargesWeightbased[i].maxweight == 0) {
        this._CustomExceptionService.handleWarning("Fill the weight details");
        rowvalue = 1;
      }
      if (this.ShippingChargesWeightbased[i].minweight > this.ShippingChargesWeightbased[i].maxweight) {
        this._CustomExceptionService.handleError("Maximum Weight should greater than minimum Weight");
        return;
      }
    }
    if (rowvalue == 0) {
      var l_weight = new Shippingchargesweightbasedcost();
      l_weight.scid = 0;
      l_weight.scwid = 0;
      l_weight.currencyid = 1;
      l_weight.minweight = 0;
      // l_weight.maxweight = 0;
      l_weight.basecost = 0;
      l_weight.createdby = sessionStorage["userid"];
      l_weight.modifiedby = sessionStorage["userid"];
      l_weight.createdon = new Date().toLocaleString();
      l_weight.modifiedon = new Date().toLocaleString();
      this.ShippingChargesWeightbased.push(l_weight);
    }
    for (var i = 1; i < this.ShippingChargesWeightbased.length; i++) {

      this.ShippingChargesWeightbased[i].minweight = this.ShippingChargesWeightbased[i - 1].maxweight + 0.01;
    }

    this.weightBasedDisabled();
  }
  removeweight(weight) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to Delete this row ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.removeshippingchargesweightid.push(weight.scwid)
        var index = this.ShippingChargesWeightbased.indexOf(weight);
        this.ShippingChargesWeightbased.splice(index, 1);
        if (this.ShippingChargesWeightbased.length <= 0) {
          var l_weight = new Shippingchargesweightbasedcost();
          l_weight.scid = 0;
          l_weight.scwid = 0;
          l_weight.currencyid = 1;
          l_weight.minweight = 0;
          // l_weight.maxweight = 0;
          l_weight.basecost = 0;
          l_weight.createdby = sessionStorage["userid"];
          l_weight.modifiedby = sessionStorage["userid"];
          l_weight.createdon = new Date().toLocaleString();
          l_weight.modifiedon = new Date().toLocaleString();
          this.ShippingChargesWeightbased.push(l_weight);
        }
        this.weightBasedDisabled();
      }
    });

  }
  checkDuplicate(event, km, i) {

  }
  onchangemaxkm(row: any, event) {

    if (row.minkm > row.maxkm) {
      this._CustomExceptionService.handleError("Maximum Kilometer should greater than minimum Killometer");
      return;
    }

  }
  onchangemaxweight(row: any, event) {

    if (row.minweight > row.maxweight) {
      this._CustomExceptionService.handleError("Maximum Weight should greater than minimum Weight");
      return;
    }
  }


  Save() {

    //ShippingChargeskm
    for (let i = 0; i < this.distanceMasterGrid.length; i++) {
      this.distanceMasterGrid[i].additionalcostperkmexceed = this._shippingform.get("additionalcostperkmexceed").value;
      if (this.distanceMasterGrid[i].maxkm == null || this.distanceMasterGrid[i].maxkm == 0) {
        this._CustomExceptionService.handleWarning("Fill the km details");
        return;
      }
      if (this.distanceMasterGrid[i].minkm > this.distanceMasterGrid[i].maxkm) {
        this._CustomExceptionService.handleError("Maximum Kilometer should greater than minimum Killometer");
        return;
      }
    }

    //ShippingChargesWeight
    for (let i = 0; i < this.ShippingChargesWeightbased.length; i++) {
      if (this.ShippingChargesWeightbased[i].maxweight == null || this.ShippingChargesWeightbased[i].maxweight == 0) {
        this._CustomExceptionService.handleWarning("Fill the weight details");
        return;
      }
      this.ShippingChargesWeightbased[i].additionalcostperkg = this._shippingform.get("additionalcostperkg").value;
      if (this.ShippingChargesWeightbased[i].minweight > this.ShippingChargesWeightbased[i].maxweight) {
        this._CustomExceptionService.handleError("Maximum Weight should greater than minimum Weight");
        return;
      }

    }

    //ShippingChargesHeader
    const shippingchargesheader = <Shippingchargesheader>{};
    if (this._ShippingChargeHeader.length == 0) {
      shippingchargesheader.scid = 0;
      shippingchargesheader.effectivefrom = new Date().toLocaleString();
      // shippingchargesheader.effecttiveto = null;
      shippingchargesheader.recordstatus = "MSC00001";
      shippingchargesheader.createdby = sessionStorage["userid"];
      shippingchargesheader.createdon = new Date().toLocaleString();
      shippingchargesheader.modifiedby = sessionStorage["userid"];
      shippingchargesheader.modifiedon = new Date().toLocaleString();

    } else {

      shippingchargesheader.scid = this._ShippingChargeHeader.length > 0 ? this._ShippingChargeHeader[0].scid : 0;
      shippingchargesheader.effectivefrom = this._ShippingChargeHeader.length > 0 ? this._ShippingChargeHeader[0].effectivefrom : new Date().toLocaleString();
      shippingchargesheader.effecttiveto = this._ShippingChargeHeader.length > 0 ? this._ShippingChargeHeader[0].effecttiveto : null;
      shippingchargesheader.recordstatus = "MSC00001";
      shippingchargesheader.createdby = this._ShippingChargeHeader.length > 0 ? this._ShippingChargeHeader[0].createdby : sessionStorage["userid"];
      shippingchargesheader.createdon = this._ShippingChargeHeader.length > 0 ? this._ShippingChargeHeader[0].createdon : new Date().toLocaleString();
      shippingchargesheader.modifiedby = sessionStorage["userid"];
      shippingchargesheader.modifiedon = new Date().toLocaleString();
    }


    this._IsProgressSpinner = true;
    this.ShippingObj = { "Shippingchargesheader": shippingchargesheader, "Shippingchargeskmbasedcost": this.distanceMasterGrid, "Shippingchargesweightbasedcost": this.ShippingChargesWeightbased }
    this._ShippingService.Create(this.ShippingObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

}
