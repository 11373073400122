export class Productreview {
    reviewid: number;
    reviewdate: Date ;
    reviewrating: number ;
    reviewdescription: string;
    customerid: number ;
    emailid: string;
    productid: number;
    variantid: number ;
    createdby: number;
    createdon: Date ;
    modifiedby: number ;
    modifiedon: Date ;
    reviewby:string;
    reviewtype:string;
    recordstatus:string;
}
