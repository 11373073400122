import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { AccounttypeService } from 'src/app/core/Services/masters/accounttype.service';
import { Accounttype } from 'src/app/core/Models/Accounttype';
import { vAccountTypeName } from 'src/app/core/Validators/validation';
declare var $:any;
//Debug
import * as ace from "ace-builds";
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
@Component({
  selector: 'app-c-account-type',
  templateUrl: './c-account-type.component.html',
  styleUrls: ['./c-account-type.component.css']
})
export class CAccountTypeComponent implements OnInit {
  _accounttypeform: FormGroup;
  _accounttypestatus: Metadatum;
  _submitted = false;
  _action: string = "create";
  _AccountType: Accounttype;
  _accounttypecode: string;
  @ViewChild("editor",{static:true}) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, private _AccountTypeService: AccounttypeService,private messageService: MessageService,private _CustomExceptionService: CustomExceptionService) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    $(document).on('keydown',null, "ctrl+s", function (e) {
       e.preventDefault();
      alert('Clicked');
    });
    this._accounttypeform = this.fb.group({
      accountingyear: [],
      accounttypecode: [0],
      accounttypename: ['',vAccountTypeName],
      accounttypestatus: [],
      companycode: [],
      createdby: ['1'],
      createdon: [],
      issystemgenerated: [],
      modifiedby: [],
      modifiedon: [],
      series: []      
    });
    this._AccountTypeService.PageOnLoad().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accounttypestatus = resultJSON.status;
      //console.log(this._accounttypestatus);
      //For Update
      // 20.update initialize should load all fields based on search page selected Account Type code
      this._accounttypecode = history.state.accounttypecode;
      this._action = history.state.action == null ? 'create' : history.state.action;


      if (this._action == 'edit' || this._action == 'view') {

        this._AccountTypeService.view(this._accounttypecode).subscribe((result) => {
          const updateJSON = JSON.parse(JSON.stringify(result));
          //console.log(updateJSON.accounttype);
          this._accounttypeform.setValue(updateJSON.accounttype);
        });
      }

    });

  }

  //Save
  onSave() {
    //console.log("Submitted");
    //console.log(this._action);
    this._submitted = true;

    //console.log(this._accounttypeform.value);

    if (this._accounttypeform.valid) {
      this._AccountType = this._accounttypeform.value;
      //console.log(this._AccountType);
//Debug
this.onEd();

      if (this._action == 'create') {
        //console.log("YEs");
        this._AccountType.createdon=new Date();
        this._AccountTypeService.create(this._AccountType).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true &&  (resultJSON!=null || resultJSON!=undefined)) {
            this._CustomExceptionService.handleError(usererrors.Saved_Success_00);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
        },
        error => this._CustomExceptionService.handleError(error));
      }
      else if (this._action == 'edit') {
        this._AccountType.modifiedon=new Date();
        this._AccountTypeService.edit(this._AccountType).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true &&  (resultJSON!=null || resultJSON!=undefined)) {
            this._CustomExceptionService.handleError(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }         

        },
        error => this._CustomExceptionService.handleError(error));
      }

    }
   
  }
  //10. All entered input fields should be cleared.
  reset(event) {
    this._accounttypeform.reset();
  }
// 14.on clicking search button , the data must be filtered and display in the grid
  goBack(event) {
    //alert('This');
    //console.log("goBack");
    this._router.navigate(['/vAccountType']);
  }
//Debug
  onEd()
  {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._AccountType;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);

  }
}

