import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountgroupService } from 'src/app/core/Services/masters/accountgroup.service';
import { Accountgroupview } from 'src/app/core/Views/Accountgroupview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-account-group',
  templateUrl: './v-account-group.component.html',
  styleUrls: ['./v-account-group.component.css']
})
export class VAccountGroupComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _accountgroupsview: Accountgroupview[]=[];
  _AccountGroupview: Accountgroupview;
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  constructor(
    private fb: FormBuilder,
    private _AccountGroupService: AccountgroupService,
    private router: Router,
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService,
    private _CustomExceptionService: CustomExceptionService,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(14);
    this.utility.pageLoadScript();
    this.HotKeyIntegration();
    this._IsProgressSpinner = true;
    this._AccountGroupService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accountgroupsview = resultJSON.accountgroupviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  view(event) {
    this.router.navigate(['/cAccountGroup'], { state: { accountgroupid: event.accountgroupid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cAccountGroup']);
  }
  delete(event) {
    alert("delete");
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'AccountGroup', 'xlsx');
  }
  ExportToPdf() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.ExportToPdf(AssetRegistrationList, 'AccountGroup.pdf');
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._accountgroupsview.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Account Group Name"] = element.accountgroupname;
      assetregistration["Parent Group"] = element.accountparentgroupname;
      assetregistration["Status"] = element.accountgroupstatusname;
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
}