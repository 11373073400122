import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { AccountsRoutingModule } from './accounts-routing.module';
import { CAccountComponent } from './account/c-account/c-account.component';
import { VAccountComponent } from './account/v-account/v-account.component';
//Prime ng 
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CAccountGroupComponent } from './account-group/c-account-group/c-account-group.component';
import { VAccountGroupComponent } from './account-group/v-account-group/v-account-group.component';
import { VAssetRegirtrationComponent } from './asset-registration/v-asset-registration/v-asset-regirtration.component';
import { CAssetRegirtrationComponent } from './asset-registration/c-asset-registration/c-asset-regirtration.component';
import { CDebitnoteComponent } from './debit-note/c-debitnote/c-debitnote.component';
import { VDebitnoteComponent } from './debit-note/v-debitnote/v-debitnote.component';
import { CPettycashSettlementComponent } from './petty-cash-settlement/c-pettycash-settlement/c-pettycash-settlement.component';
import { VPettycashSettlementComponent } from './petty-cash-settlement/v-pettycash-settlement/v-pettycash-settlement.component';
import { CPettycashComponent } from './petty-cash/c-pettycash/c-pettycash.component';
import { VPettycashComponent } from './petty-cash/v-pettycash/v-pettycash.component';
import { CAccountClosingComponent } from './account-closing/c-account-closing/c-account-closing.component';
import { VAccountClosingComponent } from './account-closing/v-account-closing/v-account-closing.component';
import { CBankReconciliationComponent } from './bank-reconciliation/c-bank-reconciliation/c-bank-reconciliation.component';
import { VBankReconciliationComponent } from './bank-reconciliation/v-bank-reconciliation/v-bank-reconciliation.component';
import { CContraComponent } from './contra/c-contra/c-contra.component';
import { VContraComponent } from './contra/v-contra/v-contra.component';
import { CPaymentComponent } from './payment/c-payment/c-payment.component';
import { VPaymentComponent } from './payment/v-payment/v-payment.component';
import { CReceiptComponent } from './receipt/c-receipt/c-receipt.component';
import { VReceiptComponent } from './receipt/v-receipt/v-receipt.component';
import { CTellerCashDaySettlementComponent } from './teller-cash-day-settlement/c-teller-cash-day-settlement/c-teller-cash-day-settlement.component';
import { VTellerCashDaySettlementComponent } from './teller-cash-day-settlement/v-teller-cash-day-settlement/v-teller-cash-day-settlement.component';
import { CJournalComponent } from './journal/c-journal/c-journal.component';
import { VJournalComponent } from './journal/v-journal/v-journal.component';
import { CLaybuyComponent } from './laybuy/c-laybuy/c-laybuy.component';
import { VLaybuyComponent } from './laybuy/v-laybuy/v-laybuy.component';
import { CTaxReconcilationComponent } from './tax-reconcilation/c-tax-reconcilation/c-tax-reconcilation.component';
import { VTaxReconcilationComponent } from './tax-reconcilation/v-tax-reconcilation/v-tax-reconcilation.component';
import { CCreditnoteComponent } from './credit-note/c-creditnote/c-creditnote.component';
import { VCreditnoteComponent } from './credit-note/v-creditnote/v-creditnote.component';
import { CPettycashExpenceComponent } from './pettycash-expence/c-pettycash-expence/c-pettycash-expence.component';
import { VPettycashExpenceComponent } from './pettycash-expence/v-pettycash-expence/v-pettycash-expence.component';
import { VDirectCreditNoteComponent } from './direct-credit-note/v-direct-credit-note/v-direct-credit-note.component';
import { CDirectCreditNoteComponent } from './direct-credit-note/c-direct-credit-note/c-direct-credit-note.component';
import { AdvanceAdjustmentComponent } from './advance-adjustment/advance-adjustment.component';
import { ClosingBalanceUpdationComponent } from './closing-balance-updation/closing-balance-updation/closing-balance-updation.component';
import { OpeningBalanceUpdationComponent } from './opening-balance-updation/opening-balance-updation.component';

@NgModule({
	declarations: [
		CAccountComponent,
		VAccountComponent,
		CAccountGroupComponent,
		VAccountGroupComponent,
		CPettycashComponent,
		VPettycashComponent,
		CPettycashSettlementComponent,
		VPettycashSettlementComponent,
		VAssetRegirtrationComponent,
		CAssetRegirtrationComponent,
		CJournalComponent,
		VJournalComponent,
		CDebitnoteComponent,
		VDebitnoteComponent,
		CTaxReconcilationComponent,
		VTaxReconcilationComponent,
		CLaybuyComponent,
		VLaybuyComponent,
		CCreditnoteComponent,
		VCreditnoteComponent,
		CReceiptComponent,
		VReceiptComponent,
		CPaymentComponent,
		VPaymentComponent,
		CBankReconciliationComponent,
		VBankReconciliationComponent,
		CContraComponent,
		VContraComponent,
		CTellerCashDaySettlementComponent,
		VTellerCashDaySettlementComponent,
		CAccountClosingComponent,
		VAccountClosingComponent,
		CPettycashExpenceComponent,
		VPettycashExpenceComponent,
		VDirectCreditNoteComponent,
		CDirectCreditNoteComponent,
		AdvanceAdjustmentComponent,
		ClosingBalanceUpdationComponent,
		OpeningBalanceUpdationComponent
	],
	providers: [
		MessageService,
		ConfirmationService,
		DatePipe,
		CustomExceptionService
	],
	imports: [
		CommonModule,
		AccountsRoutingModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		TableModule,
		CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
		InputTextModule,
		ProgressBarModule,
		FileUploadModule,
		ToolbarModule,
		RatingModule,
		FormsModule,
		RadioButtonModule,
		InputNumberModule,
		ConfirmDialogModule,
		InputTextareaModule,
		ChipsModule,
		FieldsetModule,
		AutoCompleteModule,
		OverlayPanelModule,
		CheckboxModule,
		PasswordModule,
		HttpClientModule,
		TabViewModule,
		ProgressSpinnerModule
	]
})
export class AccountsModule { }