

export class User {

  companyid: number;
  userid: number;
  username: string;
  password: string;
  employeeid: number;
  userdiscountpercentage: number;
  logincount: number;
  inactivecount: number;
  userstatus: string;
  accountingyear: number;
  createdby: number;
  createdon: Date;
  modifiedby: number;
  modifiedon: Date;
  series: string;
  associateuser:number;
  isbloked:boolean;
  blockreason:string;
}
