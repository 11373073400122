import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Productfreezing } from 'src/app/core/Models/ProductFreezing';
import { ProductFreezingService } from 'src/app/core/Services/inventory/product-freezing.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Productheader } from 'src/app/core/Models/Productheader';
import { vProductName } from 'src/app/core/Validators/validation';
import { RptLotWiseStockService } from 'src/app/core/Services/reports/Stock/Rpt-lot-wise-stock/rpt-lot-wise-stock.service';


@Component({
  selector: 'app-c-product-freezing',
  templateUrl: './c-product-freezing.component.html',
  styleUrls: ['./c-product-freezing.component.css']
})
export class CProductFreezingComponent implements OnInit {
  _ProductFreezings: any[] = [];
  _branchlist: any[] = [];
  _producttypelist: any[] = [];
  _productcategorylist: any[] = [];
  _productslist: any[] = [];
  _brands: any[] = []
  _productfreezingform: FormGroup;
  _IsProgressSpinner: boolean = true;
  _action: string = "create";
  selectedProductFreezings: any[] = [];
  _Action: IAction;
  filteredProducts: any[];
  _productname: Productheader[] = [];
  _tempproductname: Productheader[] = [];
  _ProductObj: any;
  _branchid = sessionStorage["currentbranchid"];
  isDisablebrand: boolean = false;
  isDisableproductcat: boolean = false;
  isDisableproducttype: boolean = false;
  isDisablebranch: boolean = false;
  isDisableproduct: boolean = false;
  isDisableautoproduct: boolean = false;
  _singleproductid: any = 0;
  autoproductid: any;
  _BrandObj: any;
  _SupplierFliter: any[] = [];
  isDisablesupplier: boolean = false;


  constructor(private fb: FormBuilder, private _productfreezingservice: ProductFreezingService, private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService, private _RptLotwisestockService: RptLotWiseStockService,
  ) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(325);
    this.utility.pageLoadScript();
    this.InitalizeForm();
    this.PagonLoad();
  }
  InitalizeForm() {
    this._productfreezingform = this.fb.group({
      productfreezingid: [],
      branchid: [parseInt(sessionStorage["currentbranchid"])],
      producttypeid: [],
      productcategoryid: [],
      productid: ['', vProductName],
      brandid: [],
      isproductfreezing: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      autoproductid: [],
      supplierid: []
    })

  }
  SelectAll(event) {

  }
  PagonLoad() {
    this._IsProgressSpinner = true;
    this._productfreezingservice.PageOnLoad({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._IsProgressSpinner = false;
      this._branchlist = resultJSON.branches;
      this._producttypelist = resultJSON.producttypes;
      this._brands = resultJSON.brands;
      this._SupplierFliter = resultJSON.suppliers;

      this.SearchProductFreezing();
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GetProductCategories() {
    this._IsProgressSpinner = true;
    this.isDisableautoproduct = true;
    this._productfreezingservice.GetProductCategories({ "ProductTypeId": this._productfreezingform.get("producttypeid").value }).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this.SearchProductFreezing();
      this._productcategorylist = resultJSON.productcategories;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GetProductFreezingDetails() {
    this._IsProgressSpinner = true;
    this.isDisableautoproduct = false;
    this._productfreezingform.get("producttypeid").reset();
    this._productfreezingform.get("productcategoryid").reset();
    //this._productfreezingform.get("autoproductid").reset();
    this._productfreezingform.get("productid").reset();
    this._productfreezingform.get("brandid").reset();
    this._productfreezingservice.GetProductCategories({ "ProductTypeId": this._productfreezingform.get("producttypeid").value }).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this.SearchProductFreezing();
      this._productcategorylist = resultJSON.productcategories;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GetProducts() {
    this._IsProgressSpinner = true;
    this.isDisableautoproduct = true;
    this._productfreezingservice.GetProducts({
      "ProductTypeids": this._productfreezingform.get("producttypeid").value,
      "ProductCategoryids": this._productfreezingform.get("productcategoryid").value
    }).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._productslist = resultJSON.products;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  SearchProductFreezing() {
    if (!this._productfreezingform.get("branchid").value) {
      this._CustomExceptionService.handleError("Please Select Branch");
      return;
    }
    this._IsProgressSpinner = true;

    let searchproductfreezingobj = {
      "branchid": this._productfreezingform.get("branchid").value,
      "productcategoryids": this._productfreezingform.get("productcategoryid").value || [],
      "producttypeids": this._productfreezingform.get("producttypeid").value || [],
      "productids": this._productfreezingform.get("productid").value || [],
      "brandids": this._productfreezingform.get("brandid").value || [],
      "singleproductid": this._singleproductid || 0,
      "supplierid": this._productfreezingform.get("supplierid").value || 0,

    }
    this._ProductFreezings = [];
    this.selectedProductFreezings = [];
    this._productfreezingservice.SearchProductFreezing(searchproductfreezingobj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._ProductFreezings = resultJSON.searchproductfreezings;

      this._ProductFreezings = this._ProductFreezings.sort((a, b) => b.productfreezingid - a.productfreezingid);
      this.selectedProductFreezings = this._ProductFreezings.filter(f => f.isproductfreezing == true);
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  OnSave() {

    if (!this._productfreezingform.get("branchid").value) {
      this._CustomExceptionService.handleError("Please Select Branch");
      return;
    }
    // if (this.selectedProductFreezings.length == 0) {
    //   this._CustomExceptionService.handleError("Please Select atleast one product");
    //   return;
    // }
    // if (!this.selectedProductFreezings || !this.selectedProductFreezings.length) {
    //   this._CustomExceptionService.handleError("Please Select Atleast One Product");
    //   return;
    // }
    this.selectedProductFreezings.forEach(element => {
      element.createdby = parseInt(sessionStorage["userid"])
      element.createdon = new Date();
      element.modifiedby = parseInt(sessionStorage["userid"])
      element.modifiedon = new Date();
      element.isproductfreezing = true
      element.productfreezingid = 0;
    });
    this._IsProgressSpinner = true;
    this._productfreezingservice.SaveProductFreezing({
      "ProductFreezing": this.selectedProductFreezings,
      "branchid": this._productfreezingform.get("branchid").value,
      "productcategoryids": this._productfreezingform.get("productcategoryid").value || [],
      "producttypeids": this._productfreezingform.get("producttypeid").value || [],
      "productids": this._productfreezingform.get("productid").value || [],
      "brandids": this._productfreezingform.get("brandid").value || [],
      "singleproductid": this._singleproductid || 0,
    }).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
        this._IsProgressSpinner = false;
        this.reset();
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },

      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event: any) {

  }
  onRowUnselect(event: any) {

  }
  reset() {
    this.InitalizeForm();
    this.isDisableproducttype = false;
    this.isDisableproductcat = false;
    this.isDisablebrand = false;
    this.isDisableproduct = false;
    this.isDisableautoproduct = false;
    this.isDisablesupplier = false;
    this._ProductFreezings = [];
    this._productcategorylist = [];
    this._productslist = [];
    this.selectedProductFreezings = [];
    this._productfreezingform.get('autoproductid').reset();
    this._singleproductid = 0;
    this.PagonLoad();
  }
  Checkisproductfreezing() {

  }

  filterProducts(event) {
    this._ProductObj = { "product": event.query || [], "branchid": this._branchid || 0 };
    this._IsProgressSpinner = true;
    this._productfreezingservice.GetProductauto(this._ProductObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productname = <Productheader[]>resultJSON.productsearchlist || [];
      this._tempproductname = <Productheader[]>resultJSON.productsearchlist || [];
      let filtered: any[] = [];
      let query = (<string>event.query).toLowerCase();
      this.filteredProducts = this._productname.filter(f => f.productname.toLowerCase().indexOf(query) > -1) || [];
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getproductfrezingdetails(event) {
    this.isDisableproducttype = true;
    this.isDisableproductcat = true;
    this.isDisablebrand = true;
    this.isDisableproduct = true;
    this.isDisablesupplier = true;

    this._singleproductid = event.productid;
    this.SearchProductFreezing();
    // let data = {
    //   "productid": singleproductid,
    //   "branchid": this._productfreezingform.get("branchid").value,
    // }
    // this._ProductFreezings = [];
    // this.selectedProductFreezings = [];
    // this._productfreezingservice.SearchProductFreezing(data).subscribe((result) => {
    //   const resultJSON = JSON.parse(JSON.stringify(result));
    //   console.log(resultJSON);
    //   this._ProductFreezings = resultJSON.searchproductfreezings;

    //   this._ProductFreezings = this._ProductFreezings.sort((a, b) => b.productfreezingid - a.productfreezingid);
    //   this.selectedProductFreezings = this._ProductFreezings.filter(f => f.isproductfreezing == true);
    //   this._IsProgressSpinner = false;

    // },
    //   error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clearproductdetails() {
    this._productfreezingform.controls['productid'].reset();
    this._productfreezingform.controls['producttypeid'].reset();
    this._productfreezingform.controls['productcategoryid'].reset();
    this._productfreezingform.controls['brandid'].reset();
    this._productfreezingform.controls['supplierid'].reset();
    this._ProductFreezings = [];
    this.isDisableproducttype = false;
    this.isDisableproductcat = false;
    this.isDisablebrand = false;
    this.isDisableproduct = false;
    this.isDisableautoproduct = false;
    this.isDisablesupplier = false;
  }
  getProductType() {
    this.isDisableautoproduct = true;

    this._producttypelist = [];
    this._productfreezingform.get("producttypeid").reset();

    this._productcategorylist = [];
    this._productfreezingform.get("productcategoryid").reset();

    this._productslist = [];
    this._productfreezingform.get("productid").reset();

    this._brands = [];
    this._productfreezingform.get("brandid").reset();

    this._ProductFreezings = [];

    var selectedSupplier = this._productfreezingform.get("supplierid").value;

    this._IsProgressSpinner = true;
    this._BrandObj = {
      "Suppliers": selectedSupplier,
      "type": 1
    };
    this._RptLotwisestockService.getBrandforsupplier(this._BrandObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._brands = resultJSON.brandList;
      this._producttypelist = resultJSON.producttypeList;
      //this._productcategorylist = resultJSON.productcategorylist;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })

  }
}
