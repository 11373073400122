import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ProducttypeService } from 'src/app/core/Services/masters/producttype.service';
import { Producttypeview } from 'src/app/core/Views/Producttypeview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-v-product-type',
  templateUrl: './v-product-type.component.html',
  styleUrls: ['./v-product-type.component.css']
})
export class VProductTypeComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _ProductTypeview: Producttypeview;
  _producttypesview: Producttypeview[]=[];
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  _producttypeid:number;
  _userid = sessionStorage["userid"];
  constructor(private fb: FormBuilder, private _ProductTypeService: ProducttypeService, private router: Router, private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService, private _hotkeysService: HotkeysService, private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,
    private exportUtility: ExportUtility) {
    
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(15);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;
    this._ProductTypeService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._producttypesview = resultJSON.producttypeview;
      this._IsProgressSpinner = false;
    });
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }

  view(event) {
    this.router.navigate(['/cProductType'], { state: { producttypeid: event.producttypeid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cProductType']);
  }
  delete(event) {
    alert("delete");
  }

  edit(event) {

    // this.router.navigate(['/cCustomer']);

    this.router.navigate(['/cProductType'], { state: { producttypeid: event.producttypeid, action: 'edit' } });
  }

  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  confirm(event) {
    this._producttypeid = event.producttypeid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Product Type <b>"' + event.producttypename + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._ProductTypeService.Cancel(this._producttypeid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_06);
        this._ProductTypeService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._producttypesview = resultJSON.producttypeview;
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    this.exportUtility.exportExcel(TermsAndConditionList, 'ProductType', 'xlsx');
  }
  ExportToPdf() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    const data = TermsAndConditionList.map(Object.values);
    const head = [[
      'Product Type Name',
      'Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('ProductType.pdf');
      })
    })
  }
  GetTermsAndConditionList() {
    let TermsAndConditionList = [];
    this._producttypesview.forEach(element => {
      let TermsAndCondition: any = {};
      TermsAndCondition["Product Type Name"] = element.producttypename;
      TermsAndCondition["Status"] = element.producttypestatusname;
      TermsAndConditionList.push(TermsAndCondition);
    });
    return TermsAndConditionList;
  }
}

