<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">

            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Petty Cash Expense</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save" (click)="onSave()"
                                [disabled]="_IsSaveDisable"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_PettycashExpenceform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="pettycashsettlmentid" disabled
                                            formControlName="pettycashsettlmentid">
                                        <label for="pettycashrequestno">Pettycash Issue No <span
                                                class="hlt-txt">*</span></label>
                                        <!-- [(ngModel)]="pettycashsettlementno"     [ngModelOptions]="{standalone: true}"-->
                                    </span>
                                    <button class="p-inputgroup-addon p-button" type="button"
                                        (click)="showBasicDialog()" [disabled]=" _action == 'view'">
                                        <i class="pi pi-external-link"></i>
                                    </button>
                                </div>
                                <!-- <span class="text-danger" *ngIf="(_submitted && !pettycashrequestnos)">
                                    Please Select Request Number
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" pInputText name="settlementamount" disabled
                                        [(ngModel)]="settlementamount" [ngModelOptions]="{standalone: true}">
                                    <label for="requestamount">Pettycash Issue Amount <span
                                            class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">


                                    <input type="number" pInputText name="balanceamount" disabled
                                        [(ngModel)]="balanceamount" [ngModelOptions]="{standalone: true}">

                                    <label for="settlementbranchid">Pettycash Issue Balance<span
                                            class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Expense Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_PettycashSettlementDetailform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <div class="browse-links">
                                            <p-button label="Link" styleClass="p-button-link" title="{{documentselect}}"
                                                (click)="showdocumentDialog('bottom-right')">
                                            </p-button>
                                        </div>
                                    </span>
                                    <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                        pTooltip="Browse File" tooltipPosition="top" mode="basic" #document
                                        accept="image/*,.pdf,.xlsx,.xls,.xlsm,.docx,.txt" maxFileSize="1000000"
                                        [auto]="true" chooseLabel="" (onSelect)="onDocumentSelect($event,document)"
                                        [disabled]=" _action == 'view' || _action == 'edit'">
                                    </p-fileUpload>
                                    <button class="p-inputgroup-addon p-button" pTooltip="Cancel"
                                        (click)="onDocumentClear()" tooltipPosition="top"
                                        [disabled]=" _action == 'view' || _action == 'edit'">
                                        <i class="pi pi-times"></i>
                                    </button>
                                </div>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="number" pInputText name="expenseamount" formControlName="expenseamount"
                                    [attr.disabled]="_action === 'edit' ? true : null">
                                    <label for=" expenseamount">Expense Value</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Expenseaccounts"
                                        optionLabel="name" optionValue="ledgerid" name="expenseaccount" itemSize="15"
                                        [filter]="true" formControlName="expenseaccount">
                                    </p-dropdown>
                                    <label for="expenseaccount">Expense Account <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Supplieraccounts"
                                        optionLabel="name" optionValue="ledgerid" name="supplieraccount" itemSize="15"
                                        [filter]="true" formControlName="supplieraccount">
                                    </p-dropdown>
                                    <label for="supplieraccount">Supplier Account <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="remarks" formControlName="remarks">
                                    <label for="remarks">Remarks</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Add" icon="las la-check" (click)="onAddProof($event)"
                                    class="p-button-sm p-button-success"
                                    [disabled]="_action == 'view' || isdisabled == true  "></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" class="p-button-danger"
                                    [disabled]="_action == 'view' || _action == 'edit' ||  isdisabled == true"></button>
                            </td>
                            <td>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-table #dt [value]="_Proofs" [rows]="5" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['attachmenturl','proofvalue','settlementaccount','remarks']"
                        [rowHover]="true" dataKey="attachmenturl" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px;text-align: center;">Action</th>
                                <th>Attachment Name</th>
                                <th>Attachment URL</th>
                                <th>Proof Value</th>
                                <th>Expense Account</th>
                                <th>Supplier Account</th>
                                <th>Remarks</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Proof let-ri="rowIndex">
                            <tr (dblclick)="OnRowSelect(_Proof,ri)">
                                <td style="text-align:center">
                                    <button pButton pRipple label="" title="Remove" icon="pi pi-trash"
                                        [disabled]="_IsRemoveDisable || _action == 'view'  || _action == 'edit' "
                                        (click)="RemoveProofDetail(_proof)" class="p-button-danger p-mr-2">
                                    </button>
                                </td>
                                <td>{{_Proof.attachmentname}}</td>
                                <td>
                                    <a href="javascript:void(0);"
                                        (click)="showdocumentGridDialog('bottom-right',_Proof)">
                                        {{_Proof.attachmenturl}}
                                    </a>
                                </td>
                                <td>{{_Proof.expenseamount}}</td>
                                <td>{{_Proof.expenseaccountname}}</td>
                                <td>{{_Proof.supplieraccountname}}</td>
                                <td>{{_Proof.remarks}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-dialog header="Request No" [(visible)]="displayBasic" [modal]="true"
                    [style]="{width: '50vw',height: '500px'}" [baseZIndex]="10000">
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="search-container">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input type="text" pInputText
                                            (input)="dtlist.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </div>
                            </div>
                            <div class="card">
                                <p-table [value]="_Expenses" [(selection)]="selectedRequest" #dtlist
                                    (onRowSelect)="onRowSelectPopup($event)"
                                    [disabled]="_action == 'view'  || _action == 'edit' "
                                    [globalFilterFields]="['pettycashsettlementid','currencyname','settlementamount','balanceamount']"
                                    (onRowUnselect)="onRowUnSelectPopup($event)" [paginator]="true" [rows]="10">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th *ngIf="_action != 'view'">
                                            </th>
                                            <th pSortableColumn="requestno">Pettycash Issue No<p-sortIcon
                                                    field="requestno">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="currencyname">Currency name<p-sortIcon
                                                    field="currencyname">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="requestperson">Pettycash Issue Amount<p-sortIcon
                                                    field="requestperson">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="requestedbranch">Pettycash Issue Balance
                                                <p-sortIcon field="requestedbranch">
                                                </p-sortIcon>
                                            </th>

                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-_Expense>
                                        <tr>
                                            <td style="text-align: center" *ngIf="_action != 'view'">
                                                <p-tableCheckbox [value]="rowData"
                                                    [disabled]="_Expense.disablecheckbox"></p-tableCheckbox>
                                            </td>
                                            <td>{{_Expense.pettycashsettlementid}}</td>
                                            <td>{{_Expense.currencyname}}</td>
                                            <td>{{_Expense.settlementamount}}</td>
                                            <td>{{_Expense.balanceamount}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>