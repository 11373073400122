import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Brand } from 'src/app/core/Models/Brand';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Supplier } from 'src/app/core/Models/Supplier';
import { RptbranchwisestockService } from 'src/app/core/Services/reports/Stock/RptBranchwiseStock/rptbranchwisestock.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

import { RptLotWiseStockService } from 'src/app/core/Services/reports/Stock/Rpt-lot-wise-stock/rpt-lot-wise-stock.service';

@Component({
	selector: 'app-rptsupplierwiseclosingstock',
	templateUrl: './rptsupplierwiseclosingstock.component.html',
	styleUrls: ['./rptsupplierwiseclosingstock.component.css']
})
export class RptsupplierwiseclosingstockComponent implements OnInit {

	_IsProgressSpinner: boolean = true;
	_rptbranchwisestockform: FormGroup;
	_branchlist = sessionStorage["BranchID"];
	_userid: number = parseInt(sessionStorage["userid"]);
	_src: SafeResourceUrl;
	_BranchWiseObj: any;
	_ReportLists: any[] = [];
	cols: string[];
	cols1: string[];
	colsvalues: string[];
	isdisable = false;
	_Suppliers: Supplier[] = [];
	_SupplierFliter: any[] = [];
	supplierid: number[] = [];
	lotwisestockreport: any[];
	listLength: any;
	colWidth: any = 300;
	_brandlist: any;
	brandid: any[] = [];
	_Tempbrandlist: Brand;
	_BrandObj: any;
	totalsum: number = 0;
	_Action: IAction;
	productcatid: number[] = [];
	producttypeid: number[] = [];
	productid: number[] = [];
	_productlist: any[] = [];
	_productcategorylist: any[] = [];
	_ProductTypeObj: any;
	_producttypelist: Producttype[] = [];
	_ProductCategoryObj: any;
	_TempExcellists: any[] = []

	constructor(
		private utility: CommonUtilities,
		private fb: FormBuilder,
		private _RptbranchwisestockService: RptbranchwisestockService,
		private _RptLotwisestockService: RptLotWiseStockService,
		private exportUtility: ExportUtility,
		private _CustomExceptionService: CustomExceptionService,
		private _AccessRightsService: AccessRightsService,
		private sanitizer: DomSanitizer
	) { }
	ngOnInit(): void {
		this.utility.pageLoadScript();
		this._Action = this._AccessRightsService.getAccessRights(347);
		this._rptbranchwisestockform = this.fb.group({
			supplierid: [null, Validators.required]
		})
		this._userid = parseInt(sessionStorage["userid"]);
		this._IsProgressSpinner = true;
		this._RptbranchwisestockService.PageOnload({}).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._Suppliers = resultJSON.suppliers;
			this._brandlist = resultJSON.brandList;
			this._Tempbrandlist = resultJSON.brandList;
			this._SupplierFliter = this._Suppliers;
			//this._producttypelist = resultJSON.producttypeList;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	getProductCategory(event) {
		this.brandid = []
		var selectedSupplier = this._rptbranchwisestockform.get("supplierid").value
		var producttypeid = this.producttypeid

		if (!selectedSupplier) {
			this._CustomExceptionService.handleWarning("Please Select Supplier");
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._BrandObj = {
				"Suppliers": selectedSupplier,
				"producttypeid": producttypeid || [],
				"type": 2
			};
			this._RptLotwisestockService.getBrandforsupplier(this._BrandObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				// this._brandlist = resultJSON.brandList;
				// this._producttypelist = resultJSON.producttypeList;
				this._productcategorylist = resultJSON.productcategorylist;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
		}
	}
	getProduct(event) {
		var selectedProductCategory = event.value;
		this.productid = [];
		if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
			this._productlist = [];
			return;
		} else {
			this._ProductCategoryObj = {
				"Productcategory": selectedProductCategory || [],
				"branchid": parseInt(sessionStorage["currentbranchid"])
			};
			this._RptbranchwisestockService.getProduct(this._ProductCategoryObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._productlist = resultJSON.productoverallList;
			});
		}
	}
	clear(event) {
		this.supplierid = [];
		this._productlist = [];
		this._productcategorylist = [];
		this.producttypeid = [];
		this.productid = [];
		this._brandlist = [];
		this._ReportLists = [];
		this.productcatid = [];
		this._rptbranchwisestockform.reset();
		this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
		this.isdisable = false;
		this.brandid = [];
		this.totalsum = 0;
	}
	GenerateReport(event) {
		const selectedSupplier = this._rptbranchwisestockform.get("supplierid").value;
		// var producttypeid = this._rptbranchwisestockform.get("producttypeid").value || [];
		// var productcatid =  this._rptbranchwisestockform.get("productcatid").value || [];
		// var productid = this._rptbranchwisestockform.get("productid").value || [];
		var producttypeid = this.producttypeid;
		var productcatid = this.productcatid;
		var productid = this.productid;

		if (!selectedSupplier) {
			this._CustomExceptionService.handleError("Please Select Supplier Name");
			return;
		}
		this._BranchWiseObj = {
			"producttypeid": producttypeid,
			"productcatid": productcatid,
			"productid": productid,
			"Supplier": this._rptbranchwisestockform.get("supplierid").value || 0,
			"brandid": this.brandid,
			"isexcel": false
		};
		this._IsProgressSpinner = true;
		this._RptLotwisestockService.GenerateReportSWCS(this._BranchWiseObj).subscribe((result) => {
			this._IsProgressSpinner = false;
			const resultJSON = JSON.parse(JSON.stringify(result));
			this.cols = [];
			this._ReportLists = [];
			this.totalsum = 0;
			this._ReportLists = resultJSON.lotwisestockreport;
			if (!this._ReportLists || !this._ReportLists.length || this._ReportLists.length == 0) {
				this._CustomExceptionService.handleWarning("No data Available");
				return
			}

			
			var cbcol = resultJSON.lotwisestockreport[0];
			let cols = ["SUPPLIERID", "PRODUCTID", "SUPPLIERNAME"]
			this.cols = Object.keys(cbcol);
			this.cols = this.cols.filter(f => !cols.includes(f));
			const colgroup = document.getElementById('colgroup');
			const colHTML = `<col style="width: ${this.colWidth}px;">`.repeat(this._ReportLists.length);
			colgroup.innerHTML = colHTML;
			this.totalsum = this.calculateTotalSum()
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	calculateColumnTotal(columnName: string): any {
		if (this._ReportLists.length === 0) {
			return 0;
		}
		if (columnName == "PRODUCTNAME") {
			return "Total"
		}
		else {
			var result = this._ReportLists.reduce((total, item) => total + item[columnName], 0);
			var x = result.toFixed(3);
			return parseFloat(x);
		}
	}
	calculateTotalSum(): number {
		if (this._ReportLists.length === 0) {
			return 0;
		}
		let totalSum = 0;
		const colKeys = this.cols.filter(col => col !== 'PRODUCTNAME' && col !== 'TOTALSTOCK');
		for (const col of colKeys) {
			totalSum += this.calculateColumnTotal(col);
		}
		return totalSum;
	}
	exportExcel() {
		// let supplierwiselist = this.GetCustomerList();
		this.exportUtility.exportExcel(this._ReportLists, 'Supplier Wise Closing Stock', 'xlsx');
	}

	// GetCustomerList() {
	// 	if (!this._ReportLists || this._ReportLists.length === 0) {
	// 		return [];
	// 	}

	// 	let supplierwiselist = [];
	// 	let cols = ["SUPPLIERID", "PRODUCTID", "SUPPLIERNAME"];
	// 	this.cols = Object.keys(this._ReportLists[0]);
	// 	this.cols = this.cols.filter(f => !cols.includes(f));

	// 	this._ReportLists.forEach(element1 => {
	// 		let supplierwisestock: any = {};
	// 		this.cols.forEach(element2 => {
	// 			supplierwisestock[element2] = element1[element2];
	// 		});
	// 		supplierwiselist.push(supplierwisestock);
	// 	});
	// 	return supplierwiselist;
	// }
	getBrand() {
		this.brandid = []
		var selectedSupplier = this._rptbranchwisestockform.get("supplierid").value

		if (!selectedSupplier) {
			this._CustomExceptionService.handleWarning("Please Select Supplier");
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._BrandObj = {
				"Suppliers": selectedSupplier,
				"type": 1
			};
			this._RptLotwisestockService.getBrandforsupplier(this._BrandObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._brandlist = resultJSON.brandList;
				this._producttypelist = resultJSON.producttypeList;
				//this._productcategorylist = resultJSON.productcategorylist;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
		}
	}

	supplierExcel() {

		this._BranchWiseObj = {
			"producttypeid": [],
			"productcatid": [],
			"productid": [],
			"Supplier": 0,
			"brandid": [],
			"isexcel": true
		};
		this._IsProgressSpinner = true;
		this._RptLotwisestockService.GenerateReportSWCS(this._BranchWiseObj).subscribe((result) => {
			this._IsProgressSpinner = false;
			const resultJSON = JSON.parse(JSON.stringify(result));

			this._TempExcellists = [];

			this._TempExcellists = resultJSON.lotwisestockreport;

			this.exportUtility.exportExcel(this._TempExcellists, 'All_Supplier_Wise_Closing_Stock', 'xlsx');


		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

	}

}
