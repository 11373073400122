import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Tender } from '../../Models/Tender';
import { Tenderdetailview } from '../../Models/Tenderdetail';
import { Tenderdocumentdetail } from '../../Models/Tenderdocumentdetail';
import { Tenderhistory } from '../../Models/Tenderhistory';
import { Tenderresource } from '../../Models/Tenderresource';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class TenderService {

  public _Action: IAction;
  public _TenderService = environment.TenderService;
  public _httpOptions;
  token: any;
	header: any;


  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 1)  
   }
   createHeaders(token: any, type: number): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  // Page Load :
  PageOnload(p_tenderid: number,branchid:number,userid:number): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._TenderService + '/' + p_tenderid+ '/' +branchid+ '/' +userid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  //To Get Product Category :
  getProductCategory(producttypeid: number[],branchid:number) {
    const headers = this.createHeaders(this.token, 2)
    let paramvalue = "Ids="
    paramvalue = paramvalue + producttypeid.join('&Ids=')
    //console.log(paramvalue);
    var Query = this._TenderService + '/GetProductCategory/' +branchid+ '/?' + paramvalue;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  //To Get Product :
  getProduct(productcategoryid: number[],branchid:number) {
    const headers = this.createHeaders(this.token, 2)
    let productcategory = "Ids="
    productcategory = productcategory + productcategoryid.join('&Ids=')
    var Query = this._TenderService + '/FetchProductName/' +branchid+ '/?' + productcategory;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  //To Get Product Variant :
  getProductVariant(productid: number,branchid:number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._TenderService + '/GetVariantName/' + productid+'/'+branchid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  //Save Function:
  create(p_Tenderform: FormData) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._TenderService;
    return this.http.post<{ data: any }>(Query, p_Tenderform, { headers });
  }

   // Update Page Load:
   edit(p_Tenderform: FormData) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._TenderService;
    return this.http.put<{ data: any }>(Query, p_Tenderform, { headers });
  }

  //Fetch All function call:
  FetchAll() {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._TenderService + '/FetchAll';
   
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      return data;
    }));
  }

  Cancel(tenderid: number,modifiedby:number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._TenderService + '/Cancel/' + tenderid +'/'+ modifiedby;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
}
