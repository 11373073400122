<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Ageing Detail Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_rptageingform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="2000:3000"
                                            [showIcon]="true" formControlName="month" [readonlyInput]="true"
                                            [minDate]="mindate" [maxDate]="maxdate"></p-calendar>
                                        <label for="month">Month <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_typelist" optionValue="value"
                                            optionLabel="type" (onChange)="onchangepartytype($event)"
                                            formControlName="partytype">
                                        </p-dropdown>
                                        <label for="partytypename">Party Type <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_customertypelist"
                                            optionValue="value" optionLabel="customertype"
                                            (onChange)="onchangecustomertype($event)" formControlName="customertype"
                                            [filter]="true" filterBy="customertype">
                                        </p-dropdown>
                                        <label for="customertype">Type</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_accountnamelist" optionValue="ledgerid"
                                            optionLabel="name" formControlName="customer_supplierid"
                                            displaySelectedLabel="true" [virtualScroll]="true" itemSize="30"
                                            selectedItemsLabel="{0} items selected"></p-multiSelect>
                                        <label for="name">Party Name <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_currencylist"
                                            optionValue="currencyid" optionLabel="currencyname"
                                            formControlName="currencyid" [filter]="true" filterBy="currencyname">
                                        </p-dropdown>
                                        <label for="currencyname">Currency</label>
                                    </span>
                                </td>

                            </tr>
                            <tr>
                                <td style="width:20%">
                                    <div class="p-formgroup-inline">
                                        <div class="p-field-checkbox">
                                            <p-checkbox [(ngModel)]="IsAmountZero" [ngModelOptions]="{standalone: true}" label="Include Zero" binary="true"></p-checkbox>
                                        </div>
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" icon="las la-search" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                    </form>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['actualname','currencyname','openingbalance','current','days30','days60','days90','days120','days150','days180','abovedays180','total']"
                            [rowHover]="true" dataKey="actualname" [columns]="cols" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No
                                        <p-sortIcon field="sno"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="actualname">Account Name
                                        <p-sortIcon field="actualname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="currencyname">Currency
                                        <p-sortIcon field="currencyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="openingbalance">Opening Balance
                                        <p-sortIcon field="openingbalance">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="current">0 Day
                                        <p-sortIcon field="current">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="days30">30 Days
                                        <p-sortIcon field="days30">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="days60">60 Days
                                        <p-sortIcon field="days60">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="days90">90 Days
                                        <p-sortIcon field="days90">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="days120">120 Days
                                        <p-sortIcon field="days120">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="days150">150 Days
                                        <p-sortIcon field="days150">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="days180">180 Days
                                        <p-sortIcon field="days180">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="abovedays180">Above 180 Days
                                        <p-sortIcon field="abovedays180">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="total">Total
                                        <p-sortIcon field="total">
                                        </p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                                <tr>
                                    <td class="text-center" style="width: 60px;">
                                        {{i+ 1}}
                                    </td>
                                    <td>
                                        {{_ReportList.actualname}}
                                    </td>
                                    <td>
                                        {{_ReportList.currencyname}}
                                    </td>
                                    <td>
                                        {{_ReportList.openingbalance}}
                                    </td>
                                    <td>
                                        {{_ReportList.current}}
                                    </td>
                                    <td>
                                        {{_ReportList.days30}}
                                    </td>
                                    <td>
                                        {{_ReportList.days60}}
                                    </td>
                                    <td>
                                        {{_ReportList.days90}}
                                    </td>
                                    <td>
                                        {{_ReportList.days120}}
                                    </td>
                                    <td>
                                        {{_ReportList.days150}}
                                    </td>
                                    <td>
                                        {{_ReportList.days180}}
                                    </td>
                                    <td>
                                        {{_ReportList.abovedays180}}
                                    </td>
                                    <td>
                                        {{_ReportList.total}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>