<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
          </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Incoming/Outgoing Tax</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save" (click)="onSave()"
                                [disabled]="_IsSaveDisable"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear" [disabled]="_IsClearDisable"
                                icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_RecPayTaxHeaderform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_Branches" optionLabel="branchname"
                                        [autoDisplayFirst]="false" (onChange)="getTaxDetails()" optionValue="branchid"
                                        name="branchid" formControlName="branchid">
                                    </p-dropdown>
                                    <label for="branchid">Branch</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar view="month" dateFormat="mm/yy" name="todate" [maxDate]="minDate"
                                        (onSelect)="getTaxDetails()" formControlName="todate" [showIcon]="true"
                                        [yearNavigator]="true" yearRange="1980:2070" [readonlyInput]="true">
                                    </p-calendar>
                                    <label for="todate">To Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _RecPayTaxHeaderform.get('todate').touched) && _RecPayTaxHeaderform.get('todate').errors?.SelectReceiptDate">
                                    Date Required.
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 50%;">
                                <div class="sub-title">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <h5>Recievable Tax</h5>
                                        </div>
                                        <div class="col-md-7"></div>
                                    </div>
                                </div>
                                <div class="card">
                                    <p-toolbar styleClass="p-mb-4">
                                        <ng-template pTemplate="left">
                                            <span class="p-input-icon-left">
                                                <i class="pi pi-search"></i>
                                                <input pInputText type="text"
                                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                    placeholder="Search..." />
                                            </span>
                                        </ng-template>
                                    </p-toolbar>
                                    <p-table #dt [value]="_RecTaxDetails" [rows]="10" [paginator]="true"
                                        [rowsPerPageOptions]="[5,10,25,50,100]"
                                        [globalFilterFields]="['account','refType','refNo','debitamount','creditamount']"
                                        [rowHover]="true" dataKey="customerid" [(selection)]="selectedRecTax"
                                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                        [showCurrentPageReport]="true">
                                        <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 50px;text-align:center">SNo</th>
                            <th>Date</th>
                            <th>Reference No</th>
                            <th>Currency</th>
                            <th>Amount</th>
                        </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_RecTaxDetail let-i="rowIndex">
                            <tr>
                                <td style="width: 50px" style="text-align:center">
                                    {{i+ 1}}
                                </td>
                                <td>{{_RecTaxDetail.purchaseinvoicedate | date:'dd/MM/yyyy'}}</td>
                                <td>{{_RecTaxDetail.purchaseinvoiceno}}</td>
                                <td>{{_RecTaxDetail.currencyname}}</td>
                                <td>{{_RecTaxDetail.taxamount}}</td>
                            </tr>
                        </ng-template>
                        </p-table>
            </div>
            </td>
            <td style="width: 50%;">
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Payable Tax</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_PayTaxDetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['account','refType','refNo','debitamount','creditamount']"
                        [rowHover]="true" dataKey="customerid" [(selection)]="selectedPayTax"
                        (onRowSelect)="onRowSelect1($event)" (onRowUnselect)="onRowUnselect1($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 50px;text-align:center">SNo</th>
                                <th>Date</th>
                                <th>Reference No</th>
                                <th>Currency</th>
                                <th>Amount</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_PayTaxDetail let-i="rowIndex">
                            <tr>
                                <td style="width: 50px" style="text-align:center">
                                    {{i + 1}}
                                </td>
                                <td>{{_PayTaxDetail.purchaseinvoicedate | date:'dd/MM/yyyy'}}</td>
                                <td>{{_PayTaxDetail.purchaseinvoiceno}}</td>
                                <td>{{_PayTaxDetail.currencyname}}</td>
                                <td>{{_PayTaxDetail.taxamount}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
            </tr>
            <tr>
                <td style="width: 50%;">
                    <div class="sub-title">
                        <div class="row">
                            <div class="col-md-5">
                                <h5>Summary</h5>
                            </div>
                            <div class="col-md-7"></div>
                        </div>
                    </div>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_Summaries" [rows]="10" [paginator]="true"
                            [rowsPerPageOptions]="[5,10,25,50,100]"
                            [globalFilterFields]="['account','refType','refNo','debitamount','creditamount']"
                            [rowHover]="true" dataKey="customerid" [(selection)]="selectedrows"
                            (onRowSelect)="onRowSelect2($event)" (onRowUnselect)="onRowUnselect2($event)"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
            <tr>
                <th style="width: 100px;text-align:center">Action</th>
                <th>Currency</th>
                <th>Payable</th>
                <th>Receivable</th>
                <th>Tax Payable Amount</th>
            </tr>
            </ng-template>
            <ng-template pTemplate="body" let-_Summary let-i="rowIndex">
                <tr>
                    <td style="width: 50px" style="text-align:center">
                        {{i+ 1}}
                    </td>
                    <td>{{_Summary.currencyname}}</td>
                    <td>{{_Summary.payable}}</td>
                    <td>{{_Summary.receivable}}</td>
                    <td>{{_Summary.difference}}</td>
                </tr>
            </ng-template>
            </p-table>
        </div>
        </td>
        <td style="width: 50%;">
            <!-- Debug -->
            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
        </td>
        </tr>
        </table>
        </form>
    </div>
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
</div>
</div>
</div>
<p-toast position="bottom-right"></p-toast>