import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { element } from 'protractor';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { AdvanceAdjustmentService } from 'src/app/core/Services/accounts/advance-adjustment.service';
import { vFromAccountSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-advance-adjustment',
  templateUrl: './advance-adjustment.component.html',
  styleUrls: ['./advance-adjustment.component.css']
})
export class AdvanceAdjustmentComponent implements OnInit {
  _AdvanceAdjustmentForm: FormGroup;
  _IsProgressSpinner: boolean = true;
  _Customers: any[] = [];
  _Currency: any[] = [];
  _AdvanceDetails: any[] = [];
  _SalesDetails: any[] = [];
  _submitted = false;
  _isselectallsales: boolean = false;
  _isdisabledsales: boolean = true;
  _Partytypes: any[] = [];
  _Action: IAction;
  constructor(public fb: FormBuilder,private _AccessRightsService: AccessRightsService, private utility: CommonUtilities, private _AdvanceAdjustmentService: AdvanceAdjustmentService, private _CustomExceptionService: CustomExceptionService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(326);
    this.utility.pageLoadScript();
    this.Initializeform();
    this.PageOnLoad();

  }
  PageOnLoad() {
    this._IsProgressSpinner = true;
    this._AdvanceAdjustmentService.PageOnLoad({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Customers = resultJSON.accountnameList;
      this._Currency = resultJSON.currencyList;
      this._Partytypes = resultJSON.partytypelist;
      this.OnChangePartyType();
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GetAdvanceAndSalesDetails() {
    this._SalesDetails = [];
    this._isselectallsales = false
    this._isdisabledsales = true;
    this._AdvanceDetails = [];
    if (!this._AdvanceAdjustmentForm.get("customerid").value) return;
    if (!this._AdvanceAdjustmentForm.get("currencyid").value) return;

    this._IsProgressSpinner = true;
    let legerid = this._AdvanceAdjustmentForm.get("customerid").value
    let series = this._Customers.find(f => f.ledgerid == legerid).series;
    let partytype = this._AdvanceAdjustmentForm.get("partytype").value;

    this._AdvanceAdjustmentService.GetAdvanceAndSalesDetails(
      {
        "LedgerId": legerid, "series": series, "documentno": "", "advancepaymentfor": "MSC00292", "salespaymentfor": "MSC00295",
        "currencyid": this._AdvanceAdjustmentForm.get("currencyid").value,
        "PartyType": partytype
      }
    ).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._SalesDetails = resultJSON.receiptdetailviews;
      this._AdvanceDetails = resultJSON.paymentdetailviews;
      let advanceamount = 0
      let salesinvoiceamount = 0;
      this._SalesDetails.forEach(element => {
        salesinvoiceamount += element.pendingamount
        element.issalesselect = false;
        element.issalesdisabled = true;
      });
      this._AdvanceDetails.forEach(element => {
        advanceamount += element.pendingamount;
        element.isadvanceselect = false;
      });

      this._AdvanceAdjustmentForm.controls["advanceamount"].setValue(advanceamount.toFixed(2));
      this._AdvanceAdjustmentForm.controls["salesinvoiceamount"].setValue(salesinvoiceamount.toFixed(2));

      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  CheckHeader(event) {
    if (this._AdvanceDetails.some(f => f.isadvanceselect == true)) {
      if (event.checked) {
        this._SalesDetails.forEach(element => {
          element.issalesselect = event.checked;
        })
      } else {
        this._SalesDetails.forEach(element => {
          element.issalesselect = false;
        })
      }
    }
    else {
      this._CustomExceptionService.handleError("Please Select Advance Details row")
      this._isselectallsales = false;
      this._SalesDetails.forEach(element => {
        element.issalesselect = false;
      })
    }
  }

  OnSalesSelect(event, rowobj: any) {

    if (this._AdvanceDetails.some(f => f.isadvanceselect == true)) {
      let advanceamount: number = this._AdvanceDetails.find(f => f.isadvanceselect == true).pendingamount;
      // let salesinvoiceamount: number = rowobj.pendingamount;
      let salesinvoiceamount: number = 0;
      this._SalesDetails.forEach(element => {
        if (element.issalesselect == true) {
          salesinvoiceamount += element.pendingamount
        }
      });

      if (advanceamount >= salesinvoiceamount) {
        rowobj.issalesselect = event.checked;
      } else {
        rowobj.issalesselect = false;
        rowobj.issalesselect = 0;
        rowobj.issalesdisabled = true;
        this.cdRef.detectChanges();
        this._CustomExceptionService.handleError("Advance Amount Should be Greater than invoice amount")
        return;
      }

      this._SalesDetails.length == this._SalesDetails.filter(f => f.issalesselect == true).length ? this._isselectallsales = true : this._isselectallsales = false;
    } else {
      this._CustomExceptionService.handleError("Please Select Advance Details row")
      rowobj.issalesselect = false;
      rowobj.issalesselect = '';

    }
  }
  OnAdvanceSelect(event, rowobj: any, rowindex) {
    this._AdvanceDetails.forEach((element, index) => {
      if (rowindex != index) {
        element.isadvanceselect = false;
      } else {
        element.isadvanceselect = event.checked;
      }
    });
    //select All sales details
    if (event.checked) {
      let advanceamount: number = rowobj.pendingamount;
      let salesinvoiceamount: number = this._AdvanceAdjustmentForm.get("salesinvoiceamount").value;
      if (advanceamount >= salesinvoiceamount) {
        this._SalesDetails.forEach(element => {
          element.issalesselect = true;
          element.issalesdisabled = false;

        })
        this._isselectallsales = true
        this._isdisabledsales = false;
      } else {
        this._isdisabledsales = true;
        this._isselectallsales = false
        this._SalesDetails.forEach(element => {
          if (advanceamount >= element.pendingamount) {
            element.issalesdisabled = false;
          } else {
            element.issalesdisabled = true;
          }
          element.issalesselect = false;


        })
      }
    } else {
      this._SalesDetails.forEach(element => {
        element.issalesselect = false;
        element.issalesdisabled = true;
      })
      this._isselectallsales = false
      this._isdisabledsales = true;
    }
  }

  UpdateRefNo() {

    this._submitted = true;
    if (this._AdvanceAdjustmentForm.valid && (this._SalesDetails.some(f => f.issalesselect == true) && this._AdvanceDetails.some(f => f.isadvanceselect == true))) {
      let SalesInvoiceno = this._SalesDetails.filter(f => f.issalesselect == true).map(m => m.voucherno);
      let Refno = this._AdvanceDetails.find(f => f.isadvanceselect == true).billno;
      let legerid = this._AdvanceAdjustmentForm.get("customerid").value
      let partytype = this._AdvanceAdjustmentForm.get("partytype").value;
      let series = this._Customers.find(f => f.ledgerid == legerid).series;
      this._IsProgressSpinner = true;
      this._AdvanceAdjustmentService.UpdateReferenceNo(
        {
          "RefNo": Refno, "Voucherno": SalesInvoiceno, "series": series, "PartyType": partytype
        }
      ).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));

        this._IsProgressSpinner = false;
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
          this.reset();
        }
        else {
          this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          this._IsProgressSpinner = false;
        }

        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    } else {
      this._CustomExceptionService.handleError("Please Select Advance or sales Details for your adjustment")
    }

  }
  onSave() {

  }

  Initializeform() {
    this._AdvanceAdjustmentForm = this.fb.group({
      customerid: ['', vFromAccountSelect],
      currencyid: ['', vFromAccountSelect],
      advanceamount: [],
      salesinvoiceamount: [],
      partytype: ["MSC00104"]
    })
  }

  reset() {
    this._submitted = false
    this.Initializeform();
    this._SalesDetails = [];
    this._AdvanceDetails = [];
    this._isselectallsales = false
    this._isdisabledsales = true;
    this.OnChangePartyType();
  }

  OnChangePartyType() {
    this._IsProgressSpinner = true;
    this._AdvanceDetails = [];
    this._SalesDetails = [];
    this._Customers = [];

    var partytype = this._AdvanceAdjustmentForm.get("partytype").value;
    this._AdvanceAdjustmentForm.get("advanceamount").reset();
    this._AdvanceAdjustmentForm.get("salesinvoiceamount").reset();

    let data =
    {
      "PartyType": partytype
    }

    this._AdvanceAdjustmentService.OnChangePartyType(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Customers = resultJSON.accountnameList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
