<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Closing Balance Updation</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                (click)="onSave()"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_ActOpenCloseBalform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_AccountYearList" optionLabel="accountingyear"
                                        [filter]="true" filterBy="accountingyear" optionValue="accountingyear"
                                        formControlName="accountingyear" (onChange)="OnChangeAccountingYear()"
                                        [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="accountname">Accounting Year<span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_AccountList" optionLabel="accountname" [filter]="true"
                                        filterBy="accountname" optionValue="accountid" formControlName="accountid"
                                        (onChange)="OnChangeAccount()" [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="accountname">Account Name<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_ActOpenCloseBalform.get('accountname').touched) && _ActOpenCloseBalform.get('accountname').errors?.SelectAccountgroup">
                                    Please Select Account Name
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="sysOpeningBal">
                                    <label>System Opening Bal</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-inputNumber formControlName="manOpeningBal" autocomplete="off" maxlength="13"
                                        mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                                    <label>Manual Opening Bal</label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_ActOpenCloseBalform.get('manOpeningBal').touched) && _ActOpenCloseBalform.get('manOpeningBal').errors?.SelectAccountgroup">
                                    Please Enter Opening Balance
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="sysClosingBal">
                                    <label>System Closing Bal</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-inputNumber formControlName="manClosingBal" autocomplete="off" maxlength="13"
                                        mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                                    <label>Manual Closing Bal</label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_ActOpenCloseBalform.get('manClosingBal').touched) && _ActOpenCloseBalform.get('manClosingBal').errors?.SelectAccountgroup">
                                    Please Enter Closing Balance
                                </span>
                            </td>

                        </tr>
                    </table>
                </form>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>