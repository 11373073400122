import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Pettycashrequest } from 'src/app/core/Models/Pettycashrequest';
import { PettycashService } from 'src/app/core/services/accounts/pettycash.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { Branch } from 'src/app/core/Models/Branch';
import { User } from 'src/app/core/Models/User';
import { Currency } from 'src/app/core/Models/Currency';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { vReason, vRequestAmount, vSelectCurrency, vSelectRequestDate } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
@Component({
  selector: 'app-c-pettycash',
  templateUrl: './c-pettycash.component.html',
  styleUrls: ['./c-pettycash.component.css']
})
export class CPettycashComponent implements OnInit {

  _Pettycashform: FormGroup;
  _Pettycash: Pettycashrequest;
  _Branches: Branch;
  _Users: User;
  _Currencies: Currency;
  _CurrenciesTemp: Currency[];
  _recordstatus: Metadatum;
  _Pettycashid: number;
  _action: string = "create";
  _submitted = false;
  _PettyCashId: number;

  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["currentbranchid"];

  today = new Date();
  _IsSaveDisable: boolean = false;
  _IsClearDisable: boolean = false;
  _IsProgressSpinner: boolean = true;
  mindate: Date;
  maxdate: Date;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _route: ActivatedRoute,
    private _PettycashService: PettycashService,
    private _router: Router,
    private utility: CommonUtilities,
    private _hotkeysService: HotkeysService) {

  }

  InitializeForm() {
    this._Pettycashform = this.fb.group({
      pettycashid: [0],
      pettycashno: [],
      requestdate: ['', vSelectRequestDate],
      requesterbranchid: [],
      requestedbranchid: [],
      requestperson: [],
      currencyuniquerefid: [1],
      currentcurrencyid: ['', vSelectCurrency],
      exchangerateinusd: [],
      amountinusd: [0],
      requestamount: ['', vRequestAmount],
      reason: ['', vReason],
      pettycashrequeststatusid: [],
      pettycashrequeststatusdesc: [],
      approvalremarks: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      accountingyear: [],
    });
    if (sessionStorage['Environmentenddate'] == "null") {
      this._Pettycashform.get("requestdate").setValue(new Date);
    }
    else {
      let date = new Date(sessionStorage['Environmentenddate'])
      this._Pettycashform.get("requestdate").setValue(date);
      this.today = date
      this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    }
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    
    this.InitializeForm();
    this._Pettycashid = history.state?.pettycashid ? history.state?.pettycashid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
    if (this._action == 'view') {
      this._Pettycashform.disable();

      this._IsSaveDisable = true;
      this._IsClearDisable = true;
    }
    this._IsProgressSpinner = true;
    this._PettycashService.PageOnLoad(this._Pettycashid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // For DropDown
      this._Branches = resultJSON.branches;
      this._recordstatus = resultJSON.recordStatus;
      this._Users = resultJSON.users;
      this._Currencies = resultJSON.currencies;
      this._CurrenciesTemp = resultJSON.currencies || [];
      this._IsProgressSpinner = false;
      //For Update
      let userid = sessionStorage["userid"];
      this._Pettycashform.get("requestperson").setValue(sessionStorage["Series"] == "MSC00094" ? parseInt(sessionStorage["AssociateUser"]) : parseInt(userid));
      let BranchID = sessionStorage["currentbranchid"];
      this._Pettycashform.get("requestedbranchid").setValue(parseInt(BranchID));
      this._Pettycashform.get("requestedbranchid").disable();
      this._Pettycashform.get("requestperson").disable();
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        updateJSON.pettycashrequest.requestdate = new Date(updateJSON.pettycashrequest.requestdate);
        this._Currencies = updateJSON.currencies;
        this._recordstatus = updateJSON.recordStatus;
        this._Pettycashform.setValue(updateJSON.pettycashrequest);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ChangeCurrencies(event): void {
    var exchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].exchangerate;
    this._Pettycashform.controls['exchangerateinusd'].setValue(exchangerate);
  }
  onSave() {
    this._submitted = true;
    // if (this._branchid == 1) {
    //   this._CustomExceptionService.handleWarning("Can't Request petty cash from Head office.");
    //   return;
    // }
    if (this._Pettycashform.valid) {
      this._Pettycash = this._Pettycashform.value;
      if (this._action == 'create') {
        this._Pettycash.requestedbranchid = this._Pettycashform.get("requestedbranchid").value;
        this._Pettycash.requestperson = this._Pettycashform.get("requestperson").value;
        this._Pettycash.createdon = new Date();
        this._Pettycash.createdby = sessionStorage["userid"];
        this._Pettycash.accountingyear = new Date().getFullYear();
        this._Pettycash.requesterbranchid = parseInt(sessionStorage["BranchID"]);
        this._IsProgressSpinner = true;
        this._PettycashService.create(this._Pettycash).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          this._PettyCashId = resutJSON.pettyCashId;

          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsSaveDisable = false;
            this._IsClearDisable = false;
            this._IsProgressSpinner = false;
          }
        }, error => {
          this._CustomExceptionService.handleError(error)
          this._IsSaveDisable = true;
          this._IsClearDisable = true;
          this._IsProgressSpinner = false;
        });
      }
      // else if (this._action == 'edit') {
      //   this._Pettycash.modifiedon = new Date();
      //   this._Pettycash.modifiedby = sessionStorage["userid"];

      //   this._Pettycash.requesterbranchid = parseInt(sessionStorage["BranchID"]);
      //   this._PettycashService.edit(this._Pettycash).subscribe((result) => {
      //     const resutJSON = JSON.parse(JSON.stringify(result));
      //     if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
      //       this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
      //     }
      //     else {
      //       this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
      //     }
      //   }, error => this._CustomExceptionService.handleError(error));
      // }
    }
  }
  reset(event) {
    this._submitted = true;
    this._IsSaveDisable = false;
    this._IsClearDisable = false;
    this._Pettycashform.reset();
    this.InitializeForm();
    let userid = sessionStorage["userid"];
    this._Pettycashform.get("requestperson").setValue(parseInt(userid));
    let BranchID = sessionStorage["BranchID"];
    this._Pettycashform.get("requestedbranchid").setValue(parseInt(BranchID));
    this._submitted = false;
  }
  goBack(event) {
    console.log("goBack");
    this._router.navigate(['/VPettycash']);
  }
  SendToApproval(event) {
    this._IsProgressSpinner = true;
    this._PettycashService.SendToApproval(this._userid, this._branchid, this._PettyCashId).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onEd() {
    alert("test");
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._Pettycash;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}
