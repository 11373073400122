<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Coupon</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add"
                                class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>

                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="exportUtility.exportCSV(dt)" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_couponviews" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [globalFilterFields]="['couponcode','couponname','fromdate','todate','couponstatusname','usagelimitpercoupon','currentusagelimitpercoupon']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="stocktakeid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:100px;text-align: center;">Action</th>

                                <th pSortableColumn="couponcode">Coupon Code<p-sortIcon field="couponcode">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="couponname">Coupon Name <p-sortIcon field="couponname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="fromdate">Coupon From Date<p-sortIcon field="fromdate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="todate">Coupon To Date<p-sortIcon field="todate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="usagelimitpercoupon">Coupon Limit<p-sortIcon
                                        field="usagelimitpercoupon">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currentusagelimitpercoupon">Coupon Usage<p-sortIcon
                                        field="currentusagelimitpercoupon">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="couponstatusname">Status<p-sortIcon field="couponstatusname">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_couponview let-ri="rowIndex">
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="!_Action._View" (click)="view(_couponview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" (click)="edit(_couponview)"
                                        [disabled]="!_Action._Update" class="p-button p-button-success p-mr-2"
                                       
                                        (click)="edit(_couponview)"></button>

                                    {{_couponview.Action}}
                                </td>
                                <!-- [disabled]="_couponview.status == 'MSC00047'|| _couponview.status == 'MSC00046' || _couponview.status == 'MSC00048'" -->

                                <td>
                                    {{_couponview.couponcode}}
                                </td>
                                <td>
                                    {{_couponview.couponname}}
                                </td>
                                <td>
                                    {{_couponview.fromdate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_couponview.todate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_couponview.usagelimitpercoupon}}
                                </td>
                                <td>
                                    {{_couponview.currentusagelimitpercoupon}}
                                </td>

                                <td>
                                    {{_couponview.couponstatusname}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>

                <p-toast position="bottom-right"></p-toast>
            </div>



        </div>
    </div>
</div>