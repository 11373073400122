import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { TenderService } from 'src/app/core/Services/masters/tender.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Tenderview } from 'src/app/core/Views/Tenderview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import jsPDF from 'jspdf'
const doc = new jsPDF()
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-v-tender',
  templateUrl: './v-tender.component.html',
  styleUrls: ['./v-tender.component.css']
})
export class VTenderComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _tenderview: Tenderview;
  _tenderviews: Tenderview[] = [];
  _Action: IAction;
  _IsProgressSpinner: boolean = false;
  cols: any[];

  constructor(private fb: FormBuilder, private _TenderService: TenderService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _hotkeysService: HotkeysService,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,private exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this._IsProgressSpinner = true;
    this._Action = this._AccessRightsService.getAccessRights(84);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this._TenderService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._tenderviews = resultJSON.tenderviews;
      this._IsProgressSpinner = false;
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    this.cols = [
      { field: 'tenderrefno', header: 'Tender Reference No' },
      { field: 'tenderrefdate', header: 'Tender Reference Date' },
      { field: 'customername', header: 'Customer Name' },
      { field: 'tenderamount', header: 'Tender Amount' },
      { field: 'tenderdescription', header: 'Tender Description' },
      { field: 'tenderstatusdesc', header: 'Tender Status Description' }
    ]
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }

  view(event) {
    this.router.navigate(['/cTender'], { state: { tenderid: event.tenderid, action: 'view' } });
  }

  delete(event) {
    alert("delete");
  }

  edit(event) {
    this.router.navigate(['/cTender'], { state: { tenderid: event.tenderid, action: 'edit' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cTender']);
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Tender <b>"' + event.tenderrefno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    var tenderid = event.tenderid;
    this._IsProgressSpinner = true;
    this._TenderService.Cancel(tenderid, sessionStorage["userid"]).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._tenderviews = resultJSON.tenderviews;
      this._IsProgressSpinner = false;
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  exportExcel() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    this.exportUtility.exportExcel(TermsAndConditionList, 'Tender', 'xlsx');
  }
  ExportToPdf() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    const data = TermsAndConditionList.map(Object.values);
    const head = [[
      'Tender Ref No',
      'Document Type Name',
      'Party Name',
      'Tender Amount',
      'Tender Description',
      'Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Tender.pdf');
      })
    })
  }
  GetTermsAndConditionList() {
    let TermsAndConditionList = [];
    this._tenderviews.forEach(element => {
      let TermsAndCondition: any = {};
      TermsAndCondition["Tender Ref No"] = element.tenderrefno;
      TermsAndCondition["Document Type Name"] = element.tenderrefdate;
      TermsAndCondition["Party Name"] = element.customername;
      TermsAndCondition["Tender Amount"] = element.tenderamount;
      TermsAndCondition["Tender Description"] = element.tenderdescription;
      TermsAndCondition["Status"] = element.tenderstatusdesc;
      TermsAndConditionList.push(TermsAndCondition);
    });
    return TermsAndConditionList;
  }

 

}

