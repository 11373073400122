<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Petty Cash Expense</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" (click)="GoToCreate($event)" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true"[minDate]="mindate" [maxDate]="maxdate"  yearRange="1980:2030">
                                    </p-calendar>
                                    <label for="todate">To Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                    <p-toast position="bottom-right"></p-toast>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>


                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" (click)="ExportToPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                            </ng-template>

                        </p-toolbar>
                        <p-table #dt [value]="_PettycashExpensesviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['expensedate','pettycashsettlementno', 'expenseamount','settledby','expenseaccountname','createdbyname','createdon']" [rowHover]="true" dataKey="pettycashno"
                            [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="text-center" style="width:80px;">Action
                                    </th>
                                    <th pSortableColumn="expensedate">Petty Cash Expense No
                                        <p-sortIcon field="expensedate">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="expensedate">Petty Cash Expense Id
                                        <p-sortIcon field="expensedate">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="expensedate">Expense Date
                                        <p-sortIcon field="expensedate">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="expenseamount">Expense Amount
                                        <p-sortIcon field="expenseamount">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="pettycashsettlementno">Petty Cash Settlement No
                                        <p-sortIcon field="pettycashsettlementno">
                                        </p-sortIcon>
                                    </th>
                                    <!-- <th pSortableColumn="expenseaccountname">Account Name
                                        <p-sortIcon field="expenseaccountname">
                                        </p-sortIcon>
                                    </th> -->

                                    <th pSortableColumn="settledby">Issued By
                                        <p-sortIcon field="settledby"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdbyname">Created By
                                        <p-sortIcon field="createdbyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdon">Created On
                                        <p-sortIcon field="createdon">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="pettycashsettlementstatusdesc">Status
                                        <p-sortIcon field="pettycashsettlementstatusdesc">
                                        </p-sortIcon>
                                    </th>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_PettycashExpenseviews>
                                <tr>
                                    <td class="text-center">
                                        <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2" [disabled]="!_Action._View" (click)="view(_PettycashExpenseviews)"></button>
                                        <!-- <button pButton pRipple icon="las la-pen" title="Update" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update"  (click)="edit(_PettycashExpenseviews)"></button> -->
                                        <!-- <button pButton pRipple icon="las la-times" title="Cancel"
                                            class="p-button p-button-danger p-mr-2" 
                                            (click)="confirm(_PettycashExpenseviews)" ></button> -->

                                    </td>
                                    <td>
                                        {{_PettycashExpenseviews.pettycashexpenseno}}
                                    </td>
                                    <td>
                                        {{_PettycashExpenseviews.pettycashexpenseid}}
                                    </td>
                                    <td>
                                        {{_PettycashExpenseviews.expensedate | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_PettycashExpenseviews.expenseamount}}
                                    </td>
                                    <td>
                                        {{_PettycashExpenseviews.pettycashsettlementno}}
                                    </td>
                                    <!-- <td>
                                        {{_PettycashExpenseviews.expenseaccountname}}
                                    </td> -->
                                    <td>
                                        {{_PettycashExpenseviews.settledby}}
                                    </td>
                                    <td>
                                        {{_PettycashExpenseviews.createdbyname}}
                                    </td>
                                    <td>
                                        {{_PettycashExpenseviews.createdon|date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_PettycashExpenseviews.pettycashsettlementstatusdesc}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </form>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>