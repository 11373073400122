<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Sales Quotation</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple title="Save" type="submit" (click)="onSave()" [disabled]="isDisabledsave" icon="pi pi-save"></button>
                            <button pButton pRipple title="Clear" icon="pi pi-trash" [disabled]="isDisabledClear" class="p-button-danger" (click)="reset($event)"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" *ngif="false" (click)="SendToApproval($event)"></button>
                            <button pButton pRipple title="Send Email" icon="las la-envelope" [disabled]="isDisabledSendEmail"></button>
                            <!-- <button pButton pRipple title="Print" icon="las la-print" type="button"
                                [disabled]="isDisabledprint" class="p-button-warning p-mr-2" (click)="Print($event)" ></button> -->
                            <button pButton pRipple icon="pi pi-search" title="Back to Search" class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-container scroll-y">

                <p-tabView [(activeIndex)]="selectedtab">
                    <p-tabPanel header="Sales Quotation Details">
                        <form [formGroup]="_salesquotationform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="salesquotationno"
                                                formControlName="salesquotationno" disabled pInputText>
                                            <label for="salesquotationno">Quotation No</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-calendar [showIcon]="true" name="salesquotationdate"
                                                formControlName="salesquotationdate" [monthNavigator]="true"
                                                [minDate]="mindate" [maxDate]="maxdate"  dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="1980:2030">
                                            </p-calendar>
                                            <label for="salesquotationdate">Date <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_submitted || _salesquotationform.get('salesquotationdate').touched) && _salesquotationform.get('salesquotationdate').errors?.SelectSalesQuotationDate">
                                            Please Select Sales Quotation Date
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_Customernames"
                                                    optionLabel="customername" optionValue="customerid" [filter]="true"
                                                    filterBy="nameandmobile" [showClear]="true" [virtualScroll]="true"
                                                    itemSize="15" formControlName="customerid"
                                                    (onChange)="GetCustomer($event)" [disabled]="isDisabledcustomer">
                                                </p-dropdown>
                                                <label for="customerid"> Customer Name <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                            <button class="p-inputgroup-addon p-button" title="Add" type="button" [disabled]="isDisabledQuicksave" (click)="showBasicDialog()">
                                                <i class="las la-plus"></i>
                                            </button>
                                        </div>
                                        <span class="text-danger" *ngIf="(_submitted || _salesquotationform.get('customerid').touched) && _salesquotationform.get('customerid').errors?.SelectCustomerName">
                                            Please Select Customer Name
                                        </span>
                                    </td>
                                    <td style="width: 20%;" rowspan="2">
                                        <span class="p-float-label">
                                            <textarea rows="3" name="billingaddress" formControlName="billingaddress"
                                                pInputTextarea></textarea>
                                            <label for="billingaddress">Billing Address</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;" rowspan="2">
                                        <span class="p-float-label">
                                            <textarea rows="3" name="shippingaddress" formControlName="shippingaddress"
                                                pInputTextarea></textarea>
                                            <label for="textarea">Shipping Address</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                                optionLabel="currencyname" optionValue="currencyid" [filter]="true"
                                                filterBy="currencyname" [showClear]="true" formControlName="currencyid"
                                                (onChange)="GetCurrency($event)">
                                            </p-dropdown>
                                            <label for="currencyid"> Currency <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_submitted || _salesquotationform.get('currencyid').touched) && _salesquotationform.get('currencyid').errors?.SelectCurrencyName">
                                            Please Select Currency
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_MarkupLevels"
                                                optionLabel="levelname" optionValue="levelid" [filter]="true"
                                                filterBy="levelname" [showClear]="true" formControlName="markuplevelid"
                                                (onChange)="GetLevel($event)" [disabled]="isDisabledlevel">
                                            </p-dropdown>
                                            <label for="markuplevelid">Markup Level <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_submitted || _salesquotationform.get('markuplevelid').touched) && _salesquotationform.get('markuplevelid').errors?.SelectLevelName">
                                            Please Select Markup Level
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="mobileno" formControlName="mobileno" pInputText
                                                (keypress)="keypress.kpMobileNumber($event)" maxlength="20">
                                            <label for="mobileno">Mobile No <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_submitted || _salesquotationform.get('mobileno').touched) && _salesquotationform.get('mobileno').errors?.MobileNumberRequired">
                                            Please enter mobile number
                                        </span>
                                        <span class="text-danger" *ngIf="(_submitted || _salesquotationform.get('mobileno').touched) && _salesquotationform.get('mobileno').errors?.MobileNumberInvalid">
                                            Mobile number invalid (only 20 digits allowed)
                                        </span>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Quotationsby"
                                                optionLabel="username" optionValue="userid" [filter]="true"
                                                [showClear]="true" filterBy="username" [showClear]="true"
                                                formControlName="quotationpreparedby">
                                            </p-dropdown>
                                            <label for="quotationpreparedby"> Quotation Prepared By <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_submitted || _salesquotationform.get('quotationpreparedby').touched) && _salesquotationform.get('quotationpreparedby').errors?.SelectQuotationPreparedBy">
                                            Please Select Quotation Prepared By
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-calendar name="effectivefrom" formControlName="effectivefrom"
                                                [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                                                dateFormat="dd/mm/yy" yearRange="1980:2030"
                                                (onSelect)="OnFromDateSelect($event)"></p-calendar>
                                            <label for="effectivefrom">Effective From <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_submitted || _salesquotationform.get('effectivefrom').touched) && _salesquotationform.get('effectivefrom').errors?.EffectiveFromRequired">
                                            Please Select Effective From
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-calendar name="effectiveto" formControlName="effectiveto"
                                                [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                                                dateFormat="dd/mm/yy" yearRange="1980:2030" [minDate]="minDate">
                                            </p-calendar>
                                            <label for="effectiveto">Effective To </label>
                                        </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_submitted || _salesquotationform.get('effectiveto').touched) && _salesquotationform.get('effectiveto').errors?.EffectiveToRequired">
                                            Please Select Effective To
                                        </span> -->
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_bankDetails"
                                                optionLabel="bankname" optionValue="bankid" [filter]="true"
                                                filterBy="bankname" formControlName="bankid">
                                            </p-dropdown>
                                            <label for="bankid"> Bhola Bank Details
                                            </label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_submitted || _salesquotationform.get('bankid').touched) && _salesquotationform.get('bankid').errors?.SelectBholaBankDetails">
                                            Please Select Bhola Bank Details
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Status"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                formControlName="status">
                                            </p-dropdown>
                                            <label for="status"> Sales Quotation Status </label>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </form>
                        <table class="normal-table">
                            <tr>
                                <td colspan="3">
                                    <div class="sub-title">
                                        <h5>Terms & Conditions</h5>
                                    </div>
                                </td>
                                <td colspan="2">
                                    <div class="sub-title">
                                        <h5>Other Charges</h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <form [formGroup]="_salestermsform">
                                        <table class="normal-table">
                                            <tr>
                                                <td style="width: 33%;">
                                                    <span class="p-float-label">
                                                        <p-multiSelect [options]="_PaymentTerms"
                                                            [disabled]="isDisabledpaymentterms"
                                                            optionLabel="termsdescription"
                                                            optionValue="termsandconditionid"
                                                            formControlName="paymenttermsid"
                                                            (onChange)="onSelectPaymentTerms($event)"
                                                            [(ngModel)]="_selectedPaymentTermsIDs"
                                                            displaySelectedLabel=true>
                                                        </p-multiSelect>
                                                        <label for="termsandconditionid">Payment Terms </label>
                                                    </span>

                                                </td>
                                                <td style="width: 33%;">
                                                    <span class="p-float-label">
                                                        <p-multiSelect [options]="_DeliveryTerms"
                                                            [disabled]="isDisableddeliveryterms"
                                                            optionLabel="termsdescription"
                                                            optionValue="termsandconditionid"
                                                            formControlName="deliveryermsid"
                                                            (onChange)="onSelectDeliveryTerms($event)"
                                                            [(ngModel)]="_selectedDeliveryTermsIDs"
                                                            displaySelectedLabel=true>
                                                        </p-multiSelect>
                                                        <label for="termsandconditionid">Delivery Terms</label>
                                                    </span>
                                                </td>
                                                <td style="width: 33%;">
                                                    <span class="p-float-label">
                                                        <p-multiSelect [options]="_OtherTerms"
                                                            [disabled]="isDisabledother" optionLabel="termsdescription"
                                                            optionValue="termsandconditionid"
                                                            formControlName="othertermsid"
                                                            (onChange)="onSelectOtherTerms($event)"
                                                            [(ngModel)]="_selectedOtherTermsIDs"
                                                            displaySelectedLabel=true>
                                                        </p-multiSelect>
                                                        <label for="termsandconditionid">Other Terms </label>
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </form>
                                </td>
                                <td colspan="2">
                                    <div class="card">
                                        <p-table [value]="_OtherCharges" [globalFilterFields]="['otherchargesid','otherchargesamount']" dataKey="otherchargesid" [(selection)]="selectedOtherChargesRows">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th style="width:100px;text-align: center;">Action</th>
                                                    <th>Description</th>
                                                    <th>Value</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-_othercharge>
                                                <tr>
                                                    <td style="text-align:center">
                                                        <button pButton pRipple title="Add" icon="pi pi-plus" class="p-button-success p-mr-2" [disabled]="isDisabledAddOtherCharges" (click)="AddOtherCharges($event)"></button>
                                                        <button pButton pRipple title="Remove" icon="pi pi-trash" class="p-button-danger" [disabled]="isDisabledRemoveOtherCharges" (click)="RemoveOtherCharges(_othercharge)"></button>
                                                    </td>
                                                    <td pEditableColumn>
                                                        <p-cellEditor>
                                                            <ng-template pTemplate="input">
                                                                <p-dropdown #id [autoDisplayFirst]="false" [options]="_Descriptions" optionLabel="otherchargename" optionValue="otherchargeid" *ngIf="_action != 'view'" [(ngModel)]="_othercharge.otherchargesid" (onChange)="OnchangeOtherCharge(_othercharge,$event)">
                                                                </p-dropdown>
                                                            </ng-template>
                                                            <ng-template pTemplate="output">
                                                                <p-dropdown [disabled]="true" [autoDisplayFirst]="false" [options]="_Descriptions" optionLabel="otherchargename" optionValue="otherchargeid" [(ngModel)]="_othercharge.otherchargesid">
                                                                </p-dropdown>
                                                            </ng-template>
                                                        </p-cellEditor>
                                                    </td>
                                                    <td pEditableColumn>
                                                        <p-cellEditor>
                                                            <ng-template pTemplate="input">
                                                                <input type="number" pInputText name="otherchargesamount" (keyup)="OnChangeotherCharge(_othercharge,$event)" (focusout)="OnChangeotherCharge(_othercharge,$event)" [(ngModel)]="_othercharge.otherchargesamount" maxlength="7" (keypress)="keypress.kpNumber($event)"
                                                                    *ngIf="_action != 'view'">
                                                                <div *ngIf="_action == 'view'">
                                                                    {{_othercharge.otherchargesamount}}</div>
                                                            </ng-template>
                                                            <ng-template pTemplate="output">
                                                                {{_othercharge.otherchargesamount}}
                                                            </ng-template>
                                                        </p-cellEditor>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </td>
                            </tr>
                        </table>

                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-5">
                                    <h5>Estimation Details</h5>
                                </div>
                                <div class="col-md-7"></div>
                            </div>
                        </div>
                        <form [formGroup]="_salesquotationform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">

                                        <span class="p-float-label">
                                <input type="number" name="productamount" formControlName="productamount" disabled
                                    pInputText>
                                <label for="productamount">Gross Amount</label>
                            </span>

                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                <input type="number" name="taxamount" formControlName="taxamount" disabled pInputText>
                                <label for="taxamount">Tax Amount</label>
                            </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                <input type="number" name="otherchargesamount" formControlName="otherchargesamount"
                                    disabled pInputText>
                                <label for="otherchargesamount">Other Amount</label>
                            </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                <input type="number" name="salesquotationamount" formControlName="salesquotationamount"
                                    disabled pInputText>
                                <label for="salesquotationamount">Net Amount</label>
                            </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                </tr>
                            </table>
                        </form>

                        <!-- <table class="normal-table">
                            <tr>
                                <td>
                                    <button pButton pRipple label="Send To Approval" (click)="SendToApproval($event)"
                                        [disabled]="isDisabledSendtoapproval" icon="las la-check"></button>
                                    <button pButton pRipple label="Send Email" [disabled]="isDisabledSendEmail"
                                        icon="las la-envelope"></button>
                                    <button pButton pRipple label="Print" [disabled]="isDisabledPrint"
                                        icon="las la-print"></button>
                                </td>
                                <td> </td>
                                <td></td>

                            </tr>
                        </table> -->
                        <!-- For pop Up -->
                        <p-dialog header="Customers" [(visible)]="ManageDialog" [style]="{width: '350px',height:'350px'}" [modal]="true" [maximizable]="false">
                            <ng-template pTemplate="content">
                                <form [formGroup]="_customerform" (ngSubmit)="Save()">
                                    <table class="normal-table">
                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                        <input type="text" name="customername" formControlName="customername"
                                            pInputText>

                                        <label for="customername">Customer Name <span class="hlt-txt">*</span></label>
                                                </span>
                                                <span class="text-danger" *ngIf="(_customersubmitted || _customerform.get('customername').touched) && _customerform.get('customername').errors?.CustomerNameRequired">
                                        Please Enter Customer Name
                                    </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                        <input type="text" name="mobileno" formControlName="mobileno" pInputText>

                                        <label for="mobileno">Phone Number <span class="hlt-txt">*</span></label>
                                                </span>
                                                <span class="text-danger" *ngIf="(_customersubmitted || _customerform.get('mobileno').touched) && _customerform.get('mobileno').errors?.MobileNumberRequired">
                                        Please Enter Phone Number
                                    </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                        <input type="text" name="mailid" formControlName="mailid" pInputText>
                                        <label for="mailid">Email <span class="hlt-txt">*</span></label>
                                                </span>
                                                <span class="text-danger" *ngIf="(_customersubmitted || _customerform.get('mailid').touched) && _customerform.get('mailid').errors?.EmailIDRequired">
                                        Please Enter Email
                                    </span>
                                                <span class="text-danger" *ngIf="(_customersubmitted || _customerform.get('mailid').touched) && _customerform.get('mailid').errors?.ValidEmailID">
                                        Please Enter Vaild Email
                                    </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                        <input type="text" name="address" formControlName="address" pInputText>
                                        <label for="address"> Address <span class="hlt-txt">*</span></label>
                                                </span>
                                                <span class="text-danger" *ngIf="(_customersubmitted || _customerform.get('address').touched) && _customerform.get('address').errors?.AddressRequired">
                                        Please Enter Address
                                    </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                        <p-dropdown [options]="_customertype" [autoDisplayFirst]="false"
                                            name="customertype" optionLabel="metasubdescription"
                                            optionValue="metasubcode" formControlName="customertype">
                                        </p-dropdown>
                                        <label for="customertype"> Customer Type <span class="hlt-txt">*</span></label>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                        <input type="text" name="vatno" formControlName="vatno" pInputText>
                                        <label for="vatno"> VAT No </label>
                                    </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="p-float-label">
                                        <input type="text" name="bpno" formControlName="bpno" pInputText>
                                        <label for="bpno"> BP No </label>
                                    </span>
                                            </td>
                                        </tr>
                                    </table>
                                </form>
                            </ng-template>

                            <ng-template pTemplate="footer">
                                <button pButton pRipple label="Save" (click)="Save()" icon="las la-check" class="p-button-text"></button>
                                <button pButton pRipple label="Cancel" icon="pi pi-times" (click)="Cancel()" class="p-button-text"></button>
                            </ng-template>

                        </p-dialog>
                        <table>
                            <td>
                                <!-- Debug -->
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </table>
                    </p-tabPanel>
                    <p-tabPanel header="Product Details">
                        <form [formGroup]="_salesproductform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                    <p-multiSelect [options]="_ProductTypes" optionLabel="producttypename"
                                        optionValue="producttypeid" formControlName="producttypeid"
                                        displaySelectedLabel=true (onChange)="GetProductCategory($event)">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type </label>
                                </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_submitted1 || _salesproductform.get('producttypeid').touched) && _salesproductform.get('producttypeid').errors?.SelectProductType">
                                            Please Select Atleast One Product Type
                                        </span> -->
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                    <p-multiSelect [options]="_ProductCategories" optionLabel="categoryname"
                                        optionValue="productcategoryid" [(ngModel)]="productcatid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="GetProductName($event)">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category </label>
                                </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_submitted1 || _salesproductform.get('productcategoryid').touched) && _salesproductform.get('productcategoryid').errors?.SelectProductCategory">
                                            Please Select Atleast One Product Category
                                        </span> -->
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                    <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_ProductNames"
                                                optionLabel="productname" optionValue="productid" [filter]="true"
                                                filterBy="searchfilter" [showClear]="true" formControlName="productid"
                                                (onChange)="GetProductPrice($event)"></p-dropdown> -->
                                    <p-autoComplete formControlName="productid" [suggestions]="filteredProducts"
                                        (completeMethod)="filterProducts($event)" field="productname"
                                        (onSelect)="GetProductPrice($event)">
                                        <ng-template let-product pTemplate="item">
                                            <div>{{product.productname}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger" *ngIf="(_submitted1 || _salesproductform.get('productid').touched) && _salesproductform.get('productid').errors?.SelectProductName">
                                    Please Select Product Name
                                </span>
                                    </td>


                                </tr>
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Variants"
                                        optionLabel="variantdescription" optionValue="productvariantdetailid"
                                        formControlName="variantid">
                                    </p-dropdown>
                                    <label for="variantid">Variant </label>
                                </span>

                                    </td>
                                    <td style="width: 20%;">

                                        <span class="p-float-label">
                                    <p-inputNumber *ngIf="hidequantity == true" formControlName="quantity" autocomplete="off" maxlength="14"
                                        (keypress)="keypress.kpNumber($event)" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                    </p-inputNumber>
                                    <label *ngIf="hidequantity == true" for="quantity">Quantity </label>
                                    </span>

                                        <span class="p-float-label">
                                        <p-inputNumber *ngIf="hidequantity != true" formControlName="quantity" autocomplete="off" maxlength="14"
                                            (keypress)="keypress.kpNumber($event)" mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                        </p-inputNumber>
                                        <label *ngIf="hidequantity != true" for="quantity"> Quantity <span class="hlt-txt">*</span>
                                        </label>
                                        </span>

                                        <!-- <span class="p-float-label">
                                    <input type="text" name="quantity" formControlName="quantity" maxlength="7"
                                        (keypress)="keypress.kpNumber($event)" pInputText>
                                    <label for="quantity">Quantity <span class="hlt-txt">*</span></label>
                                </span> -->
                                        <span class="text-danger" *ngIf="(_submitted1 || _salesproductform.get('quantity').touched) && _salesproductform.get('quantity').errors?.required">
                                    Please Enter Quantity
                                </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                    <input type="text" name="stockinhand" formControlName="stockinhand" disabled
                                        pInputText>
                                    <label for="stockinhand">Stock In Hand </label>
                                </span>
                                    </td>
                                    <!-- <td>
                                <span class="p-float-label">
                                    <input type="number" name="_customercreditlimit"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="_customercreditlimit"
                                        disabled pInputText>
                                    <label for="_customercreditlimit">Customer Credit Limit</label>
                                </span>
                            </td> -->
                                    <td></td>
                                    <td> </td>

                                </tr>
                                <tr>
                                    <td>
                                        <button pButton pRipple label="Add" icon="pi pi-plus" class="p-button-success" [disabled]="isDisabledAdd" (click)="AddProducts($event)"></button>
                                        <button pButton pRipple label="Clear" icon="pi pi-trash" class="p-button-danger" [disabled]="isDisabledProductClear" (click)="Clear($event)"></button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                        <div class="card">
                            <p-table [value]="_ProductDetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['productname','variantdescription']" [rowHover]="true" dataKey="billno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;width:100px;" class="text-center">Action</th>
                                        <th>Barcode Number</th>
                                        <th>Product Name</th>
                                        <th>Variant</th>
                                        <th>Quantity</th>
                                        <th>UOM</th>
                                        <th>Unit Price</th>
                                        <th>Price</th>
                                        <th>Default Discount %</th>
                                        <th>Customer Discount %</th>
                                        <th>Tax %</th>
                                        <th>Default Discount Amount</th>
                                        <th>Customer Discount Amount</th>
                                        <th>Tax Amount</th>
                                        <th>Final Amount</th>
                                        <th [hidden]="true">Profit Amount In USD</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_ProductDetail>
                                    <tr>
                                        <td style="text-align:center">
                                            <button pButton pRipple title="Remove" icon="pi pi-trash" class="p-button-danger p-mr-2" [disabled]="isDisabledRemove" (click)="RemoveProduct(_ProductDetail)"></button>
                                        </td>
                                        <td>
                                            {{_ProductDetail.barcode}}

                                        </td>
                                        <td>
                                            {{_ProductDetail.productname}}

                                        </td>
                                        <td>
                                            {{_ProductDetail.variantname}}

                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-inputNumber autocomplete="off" (onBlur)="allocatequantity(_ProductDetail,$event)" (onInput)="allocatequantity(_ProductDetail,$event)" *ngIf="_ProductDetail.uomcode != 'MSC00039'&& _action != 'view' " (Keydown.enter)="allocatequantity(_ProductDetail,$event)"
                                                        (focusout)="allocatequantity(_ProductDetail,$event)" maxlength="14" (keypress)="keypress.kpNumber($event)" [(ngModel)]="_ProductDetail.quantity" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="0"
                                                        [maxFractionDigits]="0">
                                                    </p-inputNumber>
                                                    <p-inputNumber autocomplete="off" (onBlur)="allocatequantity(_ProductDetail,$event)" (onInput)="allocatequantity(_ProductDetail,$event)" *ngIf="_ProductDetail.uomcode == 'MSC00039'&& _action != 'view' " (Keydown.enter)="allocatequantity(_ProductDetail,$event)"
                                                        (focusout)="allocatequantity(_ProductDetail,$event)" maxlength="14" (keypress)="keypress.kpNumber($event)" [(ngModel)]="_ProductDetail.quantity" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="3"
                                                        [maxFractionDigits]="3">
                                                    </p-inputNumber>
                                                    <!-- <input type="text" [(ngModel)]="_ProductDetail.quantity" pInputText *ngIf="_action != 'view'" (keyup)="allocatequantity(_ProductDetail,$event)" (focusout)="allocatequantity(_ProductDetail,$event)" maxlength="7" (keypress)="keypress.kpNumber($event)"> -->
                                                    <div *ngIf="_action == 'view'">{{_ProductDetail.quantity}}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.quantity}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td>
                                            {{_ProductDetail.uomname}}

                                        </td>
                                        <td>
                                            {{_ProductDetail.unitprice | number: '1.2-4'}}

                                        </td>
                                        <td>
                                            {{_ProductDetail.productamount | number: '1.2-4'}}
                                        </td>
                                        <td>

                                            {{_ProductDetail.discountpercentage}}

                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="number" [(ngModel)]="_ProductDetail.customerdiscountpercentage" pInputText *ngIf="_action != 'view'" (keyup)="allocatecustomerdiscount(_ProductDetail,$event)" (focusout)="allocatecustomerdiscount(_ProductDetail,$event)" maxlength="7" (keypress)="keypress.kpDecimalInput($event)">
                                                    <div *ngIf="_action == 'view'">
                                                        {{_ProductDetail.customerdiscountpercentage}}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_ProductDetail.customerdiscountpercentage}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>

                                        <td>
                                            {{_ProductDetail.taxpercentage}}

                                        </td>
                                        <td>
                                            {{_ProductDetail.discountamount | number: '1.2-4' }}

                                        </td>
                                        <td>
                                            {{_ProductDetail.customerdiscountamount | number: '1.2-4' }}

                                        </td>

                                        <td>
                                            {{_ProductDetail.taxamount | number: '1.2-4'}}

                                        </td>
                                        <td>
                                            {{_ProductDetail.finalamount | number: '1.2-4'}}

                                        </td>
                                        <td [hidden]="true">

                                            {{_ProductDetail.profitamount | number: '1.2-4'}}

                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                    </p-tabPanel>
                </p-tabView>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>