import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { OutstandingPayableDashboardComponent } from './outstanding-payable-dashboard/outstanding-payable-dashboard.component';
import { OutstandingReceivableDashboardComponent } from './outstanding-receivable-dashboard/outstanding-receivable-dashboard.component';
import { ProductDashboardComponent } from './product-dashboard/product-dashboard.component';
import { PurchaseDashboardComponent } from './purchase-dashboard/purchase-dashboard.component';
import { PurchasereturnDashboardComponent } from './purchasereturn-dashboard/purchasereturn-dashboard.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { SalesreturnDashboardComponent } from './salesreturn-dashboard/salesreturn-dashboard.component';
import { StockDashboardComponent } from './stock-dashboard/stock-dashboard.component';
import { SupplierDashboardComponent } from './supplier-dashboard/supplier-dashboard.component';
import { TellerDashboardComponent } from './teller-dashboard/teller-dashboard.component';

const routes: Routes = [
	{ path: 'customerDashboard', component: CustomerDashboardComponent },
	{ path: 'productDashboard', component: ProductDashboardComponent },
	{ path: 'stockDashboard', component: StockDashboardComponent },
	{ path: 'TellerDashboard', component: TellerDashboardComponent },
	{ path: 'supplierDashboard', component: SupplierDashboardComponent },
	{ path: 'salesreturnDashboard', component: SalesreturnDashboardComponent },
	{ path: 'purchasereturnDashboard', component: PurchasereturnDashboardComponent },
	{ path: 'salesDashboard', component: SalesDashboardComponent },
	{ path: 'purchaseDashboard', component: PurchaseDashboardComponent },
	{ path: 'OutstandingReceivable ', component: OutstandingReceivableDashboardComponent },
	{ path: 'OutstandingPayableDashboard', component: OutstandingPayableDashboardComponent },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DashboardRoutingModule { }
