import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { IAction } from '../security/IActions';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DocumentverificationService {
  public _Action: IAction;
  public _DocumentVerificationService = environment.DocumentverificationService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 1)
  }
  createHeaders(token: any, type: number): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  //PageOnload
  PageOnLoad(): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._DocumentVerificationService + '/PageInitialize';
    return this.http.get<{ data: any }>(Query, { headers });
  }

  OnDocumentRefNoChange(branchid: number, documenttypeid: number, roleid: number, supplierid: number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._DocumentVerificationService + '/OnDocumentRefNoChange' + '/' + branchid + '/' + documenttypeid + '/' + roleid + '/' + supplierid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  //Create
  create(DocumentVerification: FormData) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._DocumentVerificationService;
    return this.http.post<{ data: any }>(Query, DocumentVerification, { headers });
  }

}
