import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockdeliveryService {

  public _StockdeliveryService = environment.StockdeliveryService;
  public _httpOptions;
  controls: any;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
   }
   createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(_stockdeliveryid: number, _invoicetypeid: number): Observable<any> {
    const headers = this.header;
    var Query = this._StockdeliveryService + '/' + _stockdeliveryid + '/' + _invoicetypeid
   

    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll(branchid: number, userid: number, roleid: number, fromdate: string, todate: string) {
    const headers = this.header;
    var Query = this._StockdeliveryService + '/FetchAllLoad' + '/' + branchid + '/' + userid + '/' + roleid + '/' + fromdate + '/' + todate;
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));

      return data;
    }));
  }
  getinvoicestockrequest(obj:Object) {
    const headers = this.header;
    var Query = this._StockdeliveryService + '/getCustomerNameorBranchName';
   
    return this.http.post<{ data: any }>(Query,obj, { headers });
  }
  getproductdetails(productdetails:object) {
    const headers = this.header;
    // let paramvalue = "SalesInvoiceNo="
    // paramvalue = paramvalue + SalesInvoiceNo.join('&SalesInvoiceNo=');

    var Query = this._StockdeliveryService + '/getProducts';
   
    return this.http.post<{ data: any }>(Query,productdetails, { headers });
  }
  edit(p_stdeliveryment: object) {
    const headers = this.header;
    var Query = this._StockdeliveryService;
   
    return this.http.put<{ data: any }>(Query, p_stdeliveryment, { headers });
  }
  create(p_stdeliveryment: object) {
    const headers = this.header;
    var Query = this._StockdeliveryService;
   
    return this.http.post<{ data: any }>(Query, p_stdeliveryment, { headers });
  }
  GetotherBranchStock(prodctid: number, branchid: number) {
    const headers = this.header;
    var Query = this._StockdeliveryService + '/GetotherBranchStock' + '/' + prodctid + '/' + branchid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  Cancel(deliveryno: string, userid: number) {
    const headers = this.header;
    var Query = this._StockdeliveryService + '/Cancel/' + deliveryno + '/' + userid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  Print(obj:any) {
    const headers = this.header;
    var Query = this._StockdeliveryService + '/SDPRINT' ;
    return this.http.post(Query,obj, { responseType: 'blob' ,  headers });
  }
}
