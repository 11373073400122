import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Bank } from 'src/app/core/Models/Bankmaster';
import { vBankCode, vBankName, vSeriesSelect, vStatusSelect } from 'src/app/core/Validators/validation';
//Debug
import * as ace from "ace-builds";
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { BankService } from 'src/app/core/Services/masters/bank.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
  selector: 'app-c-bank',
  templateUrl: './c-bank.component.html',
  styleUrls: ['./c-bank.component.css']
})
export class CBankComponent implements OnInit {
  _IsProgressSpinner: boolean = false;
  _SaveHide: boolean = false;
  _ClearHide: boolean = false;

  _bankform: FormGroup;
  _bankid: number;
  _submitted = false;
  _action: string = "create";
  _bankstatus: Metadatum[];
  _Series: Metadatum;
  _Action: IAction;
  _Bank: Bank;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, private _BankService: BankService, private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService, private router: Router, private _AccessRightsService: AccessRightsService,
  ) {
    this._bankid = history.state?.bankid ? history.state?.bankid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }

  InitializeForm() {
    this._bankform = this.fb.group({
      bankid: [0],
      bankcode: ['', vBankCode],
      bankname: ['', vBankName],
      bankstatus: ['', vStatusSelect],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      series: ['', vSeriesSelect]
    });
    if (this._bankstatus && this._bankstatus.length) {
      this._bankform.get("bankstatus").setValue(this._bankstatus[0].metasubcode);
    }
    this._bankform.controls['series'].setValue("MSC00093");
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(81);
    this.utility.pageLoadScript();

    this.InitializeForm();

    if (this._action == 'view') {
      this._bankform.disable();
      this._SaveHide = true;
      this._ClearHide = true;
    }
    else if (this._action == 'edit') {
      this._ClearHide = true;
    }

    this._IsProgressSpinner = true;
    this._BankService.PageOnLoad(this._bankid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      ////console.log(resultJSON);
      this._bankstatus = resultJSON.status;
      this._Series = resultJSON.series;

      if (this._bankstatus && this._bankstatus.length) {
        this._bankform.get("bankstatus").setValue(this._bankstatus[0].metasubcode);
      }
      this._bankform.controls['series'].setValue("MSC00093");
      //console.log(this._bankstatus);
      //For Update
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        //console.log(updateJSON);
        this._bankform.setValue(updateJSON.bank);
      }
      this._IsProgressSpinner = false;
    }, error => this.handleError(error));
  }
  onSave() {
    this._submitted = true;
    // 9.if all the data is valid allow to save the record in the database 
    if (this._bankform.valid) {
      this._Bank = this._bankform.value;
      //console.log(this._Bank);
      //Debug
      // this.onEd();
      this._IsProgressSpinner = true;
      if (this._action == 'create') {
        this._Bank.createdon = new Date();
        this._Bank.createdby = sessionStorage["userid"];
        this._BankService.create(this._Bank).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => this.handleError(error));
      }
      else if (this._action == 'edit') {
        this._Bank.modifiedon = new Date();
        this._Bank.modifiedby = sessionStorage["userid"];
        //console.log("Updates");
        //console.log(this._Bank);
        this._IsProgressSpinner = true;
        this._BankService.edit(this._Bank).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._IsProgressSpinner = false;
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => this.handleError(error));
      }
    }
  }
  // 12. clear all the fields which the data has entered 
  reset(event) {
    this._submitted = true;
    this._bankform.reset();
    this.InitializeForm();
    this._submitted = false;
  }
  //23. on clicking back button , it Navigates to the search screen
  goBack(event) {
    //alert('This');
    //console.log("goBack");
    this._router.navigate(['/vBank']);
  }

  handleError(error: any) {
    this._CustomExceptionService.handleError(error)
    this._IsProgressSpinner = false;
  }

  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }


}



