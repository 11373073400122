import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/core/domain/appconfig';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Supplier } from 'src/app/core/Models/Supplier';
import { SupplierdashboardService } from 'src/app/core/Services/dashboard/supplierdashboard.service';

@Component({
    selector: 'app-supplier-dashboard',
    templateUrl: './supplier-dashboard.component.html',
    styleUrls: ['./supplier-dashboard.component.css']
})
export class SupplierDashboardComponent implements OnInit {
    _supplierdashboardform: FormGroup;
    _IsProgressSpinner: boolean = true;
    productsalesdetails: [];
    _Isbarpws: boolean = true; _Islinepws: boolean = true; _Ispiepws: boolean = true; _Isdoughnutpws: boolean = true;
    _Isbarsd: boolean = true; _Islinesd: boolean = true; _Ispiesd: boolean = true; _Isdoughnutsd: boolean = true;
    _Isbarrd: boolean = true; _Islinerd: boolean = true; _Ispierd: boolean = true; _Isdoughnutrd: boolean = true;
    _Isbarod: boolean = true; _Islineod: boolean = true; _Ispieod: boolean = true; _Isdoughnutod: boolean = true;
    lableData: any = [];
    trimlableData: any = [];
    trimlableData1: any = [];
    trimlableData2: any = [];
    _productname: Productheader[] = [];
    salesValue: any = [];
    purchasevalue: any = [];
    lableData3: any = [];
    purchasevalue1: any = [];
    trimlableData3: any = [];
    salesValuepay: any = [];
    salesValuerec: any = [];
    basicData: any;
    basicData1: any;
    basicData2: any;
    basicOptions: any;
    multiAxisData: any;
    _submitted1 = false;
    chartOptions: any;
    _productsubmitted = false;
    subscription: Subscription;
    _customerdashboardform: FormGroup;
    _suppliermonthwisedashboardform: FormGroup;
    _supplieroutstandingdashboardform: FormGroup;
    filterBranchs: any[] = [];
    _Branches: Branch[] = [];
    _Suppliers: Supplier[];
    filterSuppliers: any[] = [];
    config: AppConfig;
    charttype: any;
    userid: number;
    branchid: number;
    fromdate: Date;
    todate: Date;
    customerid: number;
    _producttype: Producttype[] = [];
    _productcategory: Productcategory[] = [];
    _ProductTypeObj: any;
    _ProductCategoryObj: any;
    constructor(private fb: FormBuilder, private _CustomExceptionService: CustomExceptionService, private _SupplierDashBoardService: SupplierdashboardService) { }

    ngOnInit(): void {
        this._supplierdashboardform = this.fb.group({
            branchid: [],
            fromdate: [],
            todate: [new Date()],
            supplierid: [],
            producttypeid: [],
            productcategoryid: [],
            productid: []
        })
        this._suppliermonthwisedashboardform = this.fb.group({
            branchid: [],
            supplierid: [],
        })
        this._supplieroutstandingdashboardform = this.fb.group({
            branchid: [],
            supplierid: [],
        })
        this._Isbarpws = true; this._Islinepws = false; this._Ispiepws = false; this._Isdoughnutpws = false;
        this._Isbarsd = true; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = false;
        this._Isbarrd = true; this._Islinerd = false; this._Ispierd = false; this._Isdoughnutrd = false;
        this._Isbarod = true; this._Islineod = false; this._Ispieod = false; this._Isdoughnutod = false;
        var dt = new Date();
        dt.setDate(dt.getDate() - 30);
        this._supplierdashboardform.controls['fromdate'].setValue(dt);
        this.config = this._SupplierDashBoardService.config;
        this.updateChartOptions();
        this.subscription = this._SupplierDashBoardService.configUpdate$.subscribe(config => {
            this.config = config;
            this.updateChartOptions();
        });
        this._IsProgressSpinner = true;
        this._SupplierDashBoardService.PageOnload().subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);
            this._Branches = <Branch[]>resultJSON.branches;
            this._Suppliers = <Supplier[]>resultJSON.suppliers;
            this._producttype = resultJSON.producttypes;
            this._IsProgressSpinner = false;
            this.onChangefilterPurchases();
            this.onChangefilter();
            this.onChangefilterOutstanding();
        },
            error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }

    getProductCategory(event) {
        var selectedProductType = event.value;
        var branchid = parseInt(sessionStorage["currentbranchid"]);
        console.log(selectedProductType);
        if (selectedProductType.length == 0 || selectedProductType == null) {
            this._supplierdashboardform.controls["productcategoryid"].reset();
            this._productcategory = [];
            return;
        }
        else {
            this._IsProgressSpinner = true;
            this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": branchid || 0 };
            this._SupplierDashBoardService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
                const resultJSON = JSON.parse(JSON.stringify(result));
                console.log(resultJSON);
                this._productcategory = resultJSON.productcategories;
                this._IsProgressSpinner = false;
            },
                error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        }
    }
    getProduct(event) {
        var selectedProductCategory = event.value;
        var branchid = parseInt(sessionStorage["currentbranchid"]);
        console.log(selectedProductCategory);
        if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
            this._supplierdashboardform.reset();
            return;
        }
        else {
            this._IsProgressSpinner = true;
            this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": branchid || 0 };
            this._SupplierDashBoardService.getProduct(this._ProductCategoryObj).subscribe((result) => {
                const resultJSON = JSON.parse(JSON.stringify(result));
                console.log(resultJSON);
                this._productname = resultJSON.productsearch;
                this._IsProgressSpinner = false;
            },
                error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        }
    }

    onChangefilter() {
        var branchid = parseInt(sessionStorage["currentbranchid"]);
        var supplierid = this._supplierdashboardform.get("supplierid").value || 0;
        this.userid = parseInt(sessionStorage["userid"]);
        var userid = this.userid;
        var _fromdate = this._supplierdashboardform.get("fromdate").value;
        var _todate = this._supplierdashboardform.get("todate").value;
        var productid = this._supplierdashboardform.get("productid").value || [0];
        this._SupplierDashBoardService.ProductWisePurchases(productid, userid, supplierid, branchid, _fromdate, _todate).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);
            this.lableData=[]; this.trimlableData=[]; this.purchasevalue=[];  this.basicData =[];
            console.log(resultJSON.productsalesdetails);
            this.productsalesdetails = resultJSON.productpurchasedetails;
            for (let i = 0; i < resultJSON.productpurchasedetails.length; i++) {
                this.lableData[i] = resultJSON.productpurchasedetails[i].PRODUCTNAME;
                this.trimlableData[i] = (resultJSON.productpurchasedetails[i].PRODUCTNAME).slice(0, 10) + '...';
                this.purchasevalue[i] = resultJSON.productpurchasedetails[i].PURCHASEVALUE;
                // this.lableData[i].slice(0, 10)+'...';
                // alert(this.lableData[i]);
                this.basicData = {
                    labels: this.trimlableData,
                    datasets: [
                        {
                            label: '',
                            backgroundColor: ['#42A5F5',
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"],
                            data: this.purchasevalue
                        }
                    ]
                };
            }
            console.log("Label list", this.lableData);
        });


    }
    ProductWise(event) {
        var branchid = event.branchid || 0;
        var supplierid = event.supplierid || 0;
        this.userid = parseInt(sessionStorage["userid"]);
        var userid = this.userid;
        var _fromdate = this._supplierdashboardform.get("fromdate").value;
        var _todate = this._supplierdashboardform.get("todate").value;
        var productid = this._supplierdashboardform.get("productid").value || [0];
        this._SupplierDashBoardService.ProductWisePurchases(productid, userid, supplierid, branchid, _fromdate, _todate).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);
            console.log(resultJSON.productsalesdetails);
            this.trimlableData=[]; this.lableData=[]; this.purchasevalue=[]; this.basicData =[];
            this.productsalesdetails = resultJSON.productpurchasedetails;
            for (let i = 0; i < resultJSON.productpurchasedetails.length; i++) {
                this.lableData[i] = resultJSON.productpurchasedetails[i].PRODUCTNAME;
                this.trimlableData[i] = (resultJSON.productpurchasedetails[i].PRODUCTNAME).slice(0, 10) + '...';
                this.purchasevalue[i] = resultJSON.productpurchasedetails[i].PURCHASEVALUE;
                // this.lableData[i].slice(0, 10)+'...';
                // alert(this.lableData[i]);
                this.basicData = {
                    labels: this.trimlableData,
                    datasets: [
                        {
                            label: '',
                            backgroundColor: ['#42A5F5',
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"],
                            data: this.purchasevalue
                        }
                    ]
                };
            }
            console.log("Label list", this.lableData);
        });

    }
    onChangefilterPurchases() {
        var branchid = parseInt(sessionStorage["currentbranchid"])|| 0;
        var supplierid = this._suppliermonthwisedashboardform.get("supplierid").value || 0;
        this.userid = parseInt(sessionStorage["userid"]);
        var userid = this.userid;
        var _fromdate = this._supplierdashboardform.get("fromdate").value;
        var _todate = this._supplierdashboardform.get("todate").value;
        this._SupplierDashBoardService.Suppliermonthwisepurchase(this.userid, supplierid, branchid, _fromdate, _todate).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);
            this.trimlableData1=[]; this.purchasevalue1=[]; this.basicData1 =[];
            for (let i = 0; i < resultJSON.productpurchasedetails.length; i++) {
                //   this.lableData[i] = resultJSON.productpurchasedetails[i].PRODUCTNAME;
                this.trimlableData1[i] = (resultJSON.productpurchasedetails[i].MONTH).slice(0, 5) + '...';
                this.purchasevalue1[i] = resultJSON.productpurchasedetails[i].PURCHASEVALUE;
                // this.lableData[i].slice(0, 10)+'...';
                // alert(this.lableData[i]);
                this.basicData1 = {
                    labels: this.trimlableData1,
                    datasets: [
                        {
                            label: '',
                            backgroundColor: ['#42A5F5',
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"],
                            data: this.purchasevalue1
                        }
                    ]
                };
            }
        });
    }
    onChangePurchases(event) {
        var branchid = event.branchid || 0;
        var supplierid = event.supplierid || 0;
        this.userid = parseInt(sessionStorage["userid"]);
        var userid = this.userid;
        var _fromdate = this._supplierdashboardform.get("fromdate").value;
        var _todate = this._supplierdashboardform.get("todate").value;
        this._SupplierDashBoardService.Suppliermonthwisepurchase(this.userid, supplierid, branchid, _fromdate, _todate).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);
            this.trimlableData1=[]; this.purchasevalue1=[];  this.basicData1 =[];
            for (let i = 0; i < resultJSON.productpurchasedetails.length; i++) {
                //   this.lableData[i] = resultJSON.productpurchasedetails[i].PRODUCTNAME;
                this.trimlableData1[i] = (resultJSON.productpurchasedetails[i].MONTH).slice(0, 5) + '...';
                this.purchasevalue1[i] = resultJSON.productpurchasedetails[i].PURCHASEVALUE;
                // this.lableData[i].slice(0, 10)+'...';
                // alert(this.lableData[i]);
                this.basicData1 = {
                    labels: this.trimlableData1,
                    datasets: [
                        {
                            label: '',
                            backgroundColor: ['#42A5F5',
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"],
                            data: this.purchasevalue1
                        }
                    ]
                };
            }
        });
    }
    onChangefilterOutstanding() {
        var branchid = parseInt(sessionStorage["currentbranchid"])|| 0;
        var supplierid = this._supplieroutstandingdashboardform.get("supplierid").value || 0;
        this._SupplierDashBoardService.Supplierwiseoutstanding(supplierid, branchid).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);
            this.lableData3=[]; this.trimlableData2=[];  this.salesValuepay=[];
            this.salesValuerec=[]; this.basicData2 =[];
            var pay = resultJSON.productpurchasedetails.filter(out => out.TYPE == "PAYABLE");
            var rec = resultJSON.productpurchasedetails.filter(out => out.TYPE == "RECEIVABLE");
            for (let i = 0; i < pay.length; i++) {
                this.lableData3[i] = pay[i].BRANCHNAME;
                this.trimlableData2[i] = (pay[i].SUPPLIERNAME).slice(0, 5) + '...';
                this.salesValuepay[i] = pay[i].BALANCE;
            }
            for (let i = 0; i < rec.length; i++) {

                this.lableData3[i] = rec[i].BRANCHNAME;
                this.trimlableData2[i] = (rec[i].SUPPLIERNAME).slice(0, 5) + '...';
                this.salesValuerec[i] = rec[i].BALANCE;
            }
            this.basicData2 = {
                labels: this.trimlableData2,
                datasets: [
                    {
                        label: 'Payable',
                        backgroundColor: ['#42A5F5',
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56"],
                        data: this.salesValuepay
                    },
                    {
                        label: 'Receivable',
                        backgroundColor: ['#42A5F5',
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56"],
                        data: this.salesValuerec
                    }
                ]
            };

        });
    }
    Outstanding(event) {
        var branchid = event.branchid || 0;
        var supplierid = event.supplierid || 0;
        this._SupplierDashBoardService.Supplierwiseoutstanding(supplierid, branchid).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            console.log(resultJSON);
            this.lableData3=[]; this.trimlableData2=[];  this.salesValuepay=[];
            this.salesValuerec=[]; this.basicData2 =[];
            var pay = resultJSON.productpurchasedetails.filter(out => out.TYPE == "PAYABLE");
            var rec = resultJSON.productpurchasedetails.filter(out => out.TYPE == "RECEIVABLE");
            for (let i = 0; i < pay.length; i++) {
                this.lableData3[i] = pay[i].BRANCHNAME;
                this.trimlableData2[i] = (pay[i].SUPPLIERNAME).slice(0, 5) + '...';
                this.salesValuepay[i] = pay[i].BALANCE;
            }
            for (let i = 0; i < rec.length; i++) {

                this.lableData3[i] = rec[i].BRANCHNAME;
                this.trimlableData2[i] = (rec[i].SUPPLIERNAME).slice(0, 5) + '...';
                this.salesValuerec[i] = rec[i].BALANCE;
            }
            this.basicData2 = {
                labels: this.trimlableData2,
                datasets: [
                    {
                        label: 'Payable',
                        backgroundColor: ['#42A5F5',
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56"],
                        data: this.salesValuepay
                    },
                    {
                        label: 'Receivable',
                        backgroundColor: ['#42A5F5',
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56"],
                        data: this.salesValuerec
                    }
                ]
            };

        });
    }
    updateChartOptions() {
        if (this.config.dark)
            this.applyDarkTheme();
        else
            this.applyLightTheme();
    }

    applyDarkTheme() {
        this.basicOptions = {
            legend: {
                labels: {
                    fontColor: '#ebedef'
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#ebedef'
                    },
                    gridLines: {
                        color: 'rgba(255,255,255,0.2)'
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: '#ebedef'
                    },
                    gridLines: {
                        color: 'rgba(255,255,255,0.2)'
                    }
                }]
            }
        };
    }

    applyLightTheme() {
        this.basicOptions = {
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#495057'
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: '#495057'
                    }
                }]
            }
        };
    }

    chartchangepws(charttype) {

        if (charttype == "bar") {
            this._Isbarpws = true; this._Islinepws = false; this._Ispiepws = false; this._Isdoughnutpws = false;
        }
        if (charttype == "line") {
            this._Isbarpws = false; this._Islinepws = true; this._Ispiepws = false; this._Isdoughnutpws = false;
        }
        if (charttype == "pie") {
            this._Isbarpws = false; this._Islinepws = false; this._Ispiepws = true; this._Isdoughnutpws = false;
        }
        if (charttype == "doughnut") {
            this._Isbarpws = false; this._Islinepws = false; this._Ispiepws = false; this._Isdoughnutpws = true;
        }
    }
    chartchangesd(charttype1) {

        if (charttype1 == "bar") {
            this._Isbarsd = true; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = false;
        }
        if (charttype1 == "line") {
            this._Isbarsd = false; this._Islinesd = true; this._Ispiesd = false; this._Isdoughnutsd = false;
        }
        if (charttype1 == "pie") {
            this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = true; this._Isdoughnutsd = false;
        }
        if (charttype1 == "doughnut") {
            this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = true;
        }
    }
    chartchangerd(charttype2) {

        if (charttype2 == "bar") {
            this._Isbarrd = true; this._Islinerd = false; this._Ispierd = false; this._Isdoughnutrd = false;
        }
        if (charttype2 == "line") {
            this._Isbarrd = false; this._Islinerd = true; this._Ispierd = false; this._Isdoughnutrd = false;
        }
        if (charttype2 == "pie") {
            this._Isbarrd = false; this._Islinerd = false; this._Ispierd = true; this._Isdoughnutrd = false;
        }
        if (charttype2 == "doughnut") {
            this._Isbarrd = false; this._Islinerd = false; this._Ispierd = false; this._Isdoughnutrd = true;
        }
    }
    chartchangeod(charttype3) {

        if (charttype3 == "bar") {
            this._Isbarod = true; this._Islineod = false; this._Ispieod = false; this._Isdoughnutod = false;
        }
        if (charttype3 == "line") {
            this._Isbarod = false; this._Islineod = true; this._Ispieod = false; this._Isdoughnutod = false;
        }
        if (charttype3 == "pie") {
            this._Isbarod = false; this._Islineod = false; this._Ispieod = true; this._Isdoughnutod = false;
        }
        if (charttype3 == "doughnut") {
            this._Isbarod = false; this._Islineod = false; this._Ispieod = false; this._Isdoughnutod = true;
        }
    }

    filterBranch(event) {
        let filtered: any[] = [];
        let query = (<string>event.query).toLowerCase();
        this.filterBranchs = this._Branches.filter(f => f.branchname.toLowerCase().indexOf(query) > -1) || [];
    }
    filterSupplier(event) {
        let filtered: any[] = [];
        let query = (<string>event.query).toLowerCase();
        this.filterSuppliers = this._Suppliers.filter(f => f.suppliername.toLowerCase().indexOf(query) > -1) || [];
    }
    OnChangeBranch(event) {
        console.log(event);
        this.branchid = event.branchid;

    }
    OnChangeCustomer(event) {
        console.log(event);
        this.customerid = event.customerid;

    }

}

function obj(obj: any, arg1: (value: any, key: any) => void) {
    throw new Error('Function not implemented.');
}


