import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Orderstatusdescheader } from 'src/app/core/Models/OrderDescription';
import { OrderstatusdescriptionService } from 'src/app/core/Services/ecommerce/orderstatusdescription.service';
import { vOrderDescription, vSelectOrderStatus, vSelectOrderSubStatus } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-c-orderdescription',
  templateUrl: './c-orderdescription.component.html',
  styleUrls: ['./c-orderdescription.component.css']
})
export class COrderdescriptionComponent implements OnInit {
  _orderdescriptionform: FormGroup;
  _OrderDescription: Orderstatusdescheader;
  _OrderStatus: any;
  _OrderSubStatus: any;
  _OrderSubStatusList: any;
  _submitted = false;
  _action: string = "create";
  isDisabledSave = false;
  isDisabledClear = false;
  _IsProgressSpinner: boolean = true;
  _orderid: number;
  constructor(private fb: FormBuilder,
    private datePipe: DatePipe,
    private _router: Router, private utility: CommonUtilities, private _OrderDescriptionService: OrderstatusdescriptionService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService) { }
  InitiliazeForm() {
    this._orderdescriptionform = this.fb.group({
      osdid: [0],
      orderstatuscode: ['',vSelectOrderStatus],
      orderstatusname: [],
      ordersubstatuscode: ['',vSelectOrderSubStatus],
      ordersubstatusname: [],
      orderdescstatuscode: [],
      orderdescstatusname: ['',vOrderDescription],
      recordstatus: ["MSC00001"],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      recordstatusname:[""],

    })
  }
  ngOnInit(): void {
    this.InitiliazeForm();
    this.utility.pageLoadScript();
    this._orderid = history.state?.orderid ? history.state?.orderid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    if (this._action == 'view') {
      this._orderdescriptionform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
    }

    if (this._action == 'edit') {
      this.isDisabledClear = true;
      this.isDisabledSave = false;
    }
    this._IsProgressSpinner = true;
    this._OrderDescriptionService.PageOnLoad().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // console.log("PageLoad Data", resultJSON);
      this._OrderStatus = resultJSON.orderstatuslst;

      this._OrderSubStatusList = resultJSON.ordersubstatuslst;

      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    //For Update
    this._orderid = history.state.osdid;

    if (this._action == 'edit' || this._action == 'view') {
      this._IsProgressSpinner = true;
      this._OrderDescriptionService.View(this._orderid).subscribe((result) => {
        const updateJSON = JSON.parse(JSON.stringify(result));
        // console.log("View Data", updateJSON);
        // Orderstatusdescheaderviews
        this._orderdescriptionform.setValue(updateJSON.orderstatusdescheaderviews[0]);
        this._OrderSubStatus=updateJSON.ordersubstatuslst;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  GetSubstatus(event){
    var status = this._OrderStatus.filter(f => f.metasubcode == event.value)[0].metasubdescription;
    this._orderdescriptionform.controls['orderstatusname'].setValue(status);
    if(event.value=="ECO00001"){
      this._OrderSubStatus = this._OrderSubStatusList.filter(f => f.metasubcode == "ECO00015")
    }
    else{
      this._OrderSubStatus = this._OrderSubStatusList;
    }

  }
  GeSubstatusname(event){
    var status = this._OrderSubStatus.filter(f => f.metasubcode == event.value)[0].metasubdescription;
    this._orderdescriptionform.controls['ordersubstatusname'].setValue(status);
  }
  onSave() {
    this._submitted = true;
    if (this._orderdescriptionform.valid) {
      //For Model Mapping
      this._OrderDescription = this._orderdescriptionform.value;
      // console.log(this._OrderDescription);
      if (this._action == 'create') {
        // console.log("YEs");
        this._OrderDescription.createdon = new Date();
        this._OrderDescription.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._OrderDescriptionService.create(this._OrderDescription).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
      else if (this._action == 'edit') {
        this._OrderDescription.modifiedon = new Date();
        this._OrderDescription.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._OrderDescriptionService.edit(this._OrderDescription).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
    }
  }
  reset(event) {
    this._submitted = true;
    this.isDisabledSave = false;
    this.isDisabledClear = false;
    this.InitiliazeForm();
    this._submitted = false;
  }
  goBack(event) {
    this._router.navigate(['/vOrderDescription']);
  }


  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

}

