<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_otherchargeform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Other Charges</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save" [disabled]="isDisabledSave"></button>
                                <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                    icon="pi pi-trash" class="" class="p-button-danger" [disabled]="isDisabledClear"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                    title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td >
                                <span class="p-float-label">
                                    <input type="text" pInputText name="otherchargename" formControlName="otherchargename">
                                    <label for="otherchargename"> Enter Other Charges Name <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 4.if "country code" is not enter, show error as "Please Enter country code" -->
                                <span class="text-danger" 
                                     *ngIf="(_submitted || _otherchargeform.get('otherchargename').touched) && _otherchargeform.get('otherchargename').errors?.CountryNameRequired"> 
                                    Please enter other charges name 
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="otherchargeamount" formControlName="otherchargeamount">
                                    <label for="otherchargeamount"> Other Charges Amount ( USD )  <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 5.if "country name" is not enter, show error as "Please Enter country name" -->
                                <!-- 18.country name must be editable ,because it should  allow to update -->
                                <!-- 21.if "country name" is not enter, show error as "Please Enter country name" -->
                                <span class="text-danger" 
                                     *ngIf="(_submitted || _otherchargeform.get('otherchargeamount').touched )&& _otherchargeform.get('otherchargeamount').errors?.MinimumAmountRequired"> 
                                    Please enter other charges amount in usd
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_otherchargestype" [autoDisplayFirst]="false" optionLabel="metasubdescription"
                                        optionValue="metasubcode"  name="otherchargestype" formControlName="otherchargestype"> 
                                    </p-dropdown>
                                    <label for="otherchargestatus"> Other Charges Type <span class="hlt-txt">*</span></label>
                            </span>   
                            <span class="text-danger"
                            *ngIf="(_otherchargeform.get('otherchargestype').touched || _submitted) && _otherchargeform.get('otherchargestype').errors?.SelectStatus">
                            Please select other charges type
                        </span>                            
                            </td>
                           
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <!-- <td style="width: 20%;"></td> -->
                        </tr>
                        <tr>
                            <td>
                                <!-- 9.status must save  default as active while saving. -->
                                <!-- 19. status column need to show in the update screen  -->
                                <span class="p-float-label">
                                    <p-dropdown [options]="_otherchargestatus" [autoDisplayFirst]="false" optionLabel="metasubdescription"
                                        optionValue="metasubcode"  name="otherchargestatus" formControlName="otherchargestatus">
                                    </p-dropdown>
                                    <label for="otherchargestatus"> Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_otherchargeform.get('otherchargestatus').touched || _submitted) && _otherchargeform.get('otherchargestatus').errors?.SelectStatus">
                                    Please Select Status
                                </span>
                            </td>
                            <td>
                                   <!-- Debug -->
                                   <!-- <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div> -->
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>

