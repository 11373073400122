import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Codsettlement } from 'src/app/core/Models/CODSettlement';
import { Codsettlementdetail } from 'src/app/core/Models/CODSettlementdetail';
import { User } from 'src/app/core/Models/User';
import { CodSettlementService } from 'src/app/core/Services/ecommerce/cod-settlement.service';
import { vDeliveryDateSelect, vTellerNameSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { KeyPress } from 'src/assets/js/KeyPress';

@Component({
  selector: 'app-c-cod-settlement',
  templateUrl: './c-cod-settlement.component.html',
  styleUrls: ['./c-cod-settlement.component.css']
})
export class CCodSettlementComponent implements OnInit {
  _CODSettlementForm: FormGroup;
  _CODSettlement: Codsettlement;
  _CODSettlementDetails: Codsettlementdetail[] = [];
  selectedCODSettlementRows: Codsettlementdetail[] = [];
  _CODSettletmentObj: any;
  _Tellernames: User;
  _CODSettlementOfficers: User;
  _IsProgressSpinner: boolean = false;
  _branchid = sessionStorage["currentbranchid"];
  _userid = sessionStorage["userid"];
  _COdSettlementID: number;
  _action: string = "create";
  isDisabledsave = false;
  isDisablereset = false;
  _submitted = false;
  cols: any[];
  TotalprevioussettlementAmt: number = 0;
  TotalcurrentsettlementAmt: number = 0;
  TotalAmt: number = 0;

  constructor(private fb: FormBuilder, private utility: CommonUtilities, private _CODSettlementservice: CodSettlementService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private _router: Router, public keypress: KeyPress) {
    this._COdSettlementID = history.state?.codsettlementid ? history.state?.codsettlementid : 0;
  }
  InitializeForm() {
    this._CODSettlementForm = this.fb.group({
      codsettlementid: [0],
      codsettlementno: [0],
      codsettlementdate: ['', vDeliveryDateSelect],
      tellerid: [],
      settlementofficerid: ['', vTellerNameSelect],
      branchid: [],
      createdby: [],
      createdon: [],
      totalonlineamount: []
      // 
    });
    this._CODSettlementForm.get("codsettlementdate").setValue(new Date());
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    this.PageOnload();
  }
  PageOnload() {
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._IsProgressSpinner = true;
    this._CODSettlementservice.PageOnload(this._COdSettlementID).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("Pageonload", resultJSON);
      this._Tellernames = resultJSON.tellers;      
      this._CODSettlementOfficers = resultJSON.users;
      this._IsProgressSpinner = false;
      if (this._action == 'view') {
        this._CODSettlementForm.disable();
        this.isDisabledsave = true;
        this.isDisablereset = true;
      }
      if (this._action == 'edit' || this._action == 'view') {
        this._submitted = false;
        const updateJSON = JSON.parse(JSON.stringify(result));
        console.log("updatedPageonload", resultJSON);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  entersettlementamount(_codsettlementdetail: any, event) {
    if (_codsettlementdetail.settlementamount > _codsettlementdetail.overallbalnaceamount) {
      this._CustomExceptionService.handleError("Settlement Amount Should not be greater than Balance Amount");
      _codsettlementdetail.settlementamount = 0;
      _codsettlementdetail.balanceamount = _codsettlementdetail.overallbalnaceamount - _codsettlementdetail.settlementamount;
      return;
    } else {
      this.TotalcurrentsettlementAmt = _codsettlementdetail.settlementamount;
      this.TotalAmt = this.TotalcurrentsettlementAmt + this.TotalprevioussettlementAmt;
      _codsettlementdetail.balanceamount = _codsettlementdetail.overallbalnaceamount - _codsettlementdetail.settlementamount;
    }

  }
  GetSalesdetails() {

    let tellerid = 0;
    let settlementofficerid = this._CODSettlementForm.get("settlementofficerid").value;
    let Date = this._CODSettlementForm.get("codsettlementdate").value;
    var datePipe = new DatePipe("en-US");
    var pcodDate = datePipe.transform(Date, 'yyyy-MM-dd');
    this._IsProgressSpinner = true;
    this._CODSettlementservice.GetSalesdetails(pcodDate, tellerid, this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("GetSalesdetails", resultJSON);
      this._CODSettlementDetails = resultJSON.getsettlementorderdetails;
      this._CODSettlementForm.get("totalonlineamount").setValue(resultJSON.totalInvoiceAmount || 0)

      for (let i = 0; i < this._CODSettlementDetails.length; i++) {
        this._CODSettlementDetails[i].overallbalnaceamount = this._CODSettlementDetails[i].balanceamount;
        this.TotalprevioussettlementAmt += this._CODSettlementDetails[i].previoussettlementamount;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  onSave() {
    if (!this.selectedCODSettlementRows || !this.selectedCODSettlementRows.length) {
      this._CustomExceptionService.handleError("Please select atleast one row");
      return;
    }
    for (let i = 0; i < this.selectedCODSettlementRows.length; i++) {
      if (this.selectedCODSettlementRows[i].settlementamount > this.selectedCODSettlementRows[i].overallbalnaceamount) {
        this._CustomExceptionService.handleError("Settlement Amount Should not be greater than Balance Amount");
        this.selectedCODSettlementRows[i].settlementamount = 0;
        return;
      }
      if (!this.selectedCODSettlementRows[i].settlementamount) {
        this._CustomExceptionService.handleError("Please enter settlement Amount");
        return;
      }
      if (!this.selectedCODSettlementRows[i].tellerid) {
        this._CustomExceptionService.handleError("Please enter teller name");
        return;
      }

    }
    this._submitted = true;


    //COD Settlement Details
    var l_CODDetails: Codsettlementdetail[] = [];

    Object.keys(this.selectedCODSettlementRows).map((Index) => {
      var currentRow = this.selectedCODSettlementRows[Index];
      var l_coddetails = new Codsettlementdetail();

      l_coddetails.codsettlementdetailid = 0;
      l_coddetails.codsettlementid = 0;
      l_coddetails.salesdate = currentRow.salesdate;
      l_coddetails.deliverydate = currentRow.stockdeliverydate;
      l_coddetails.amountcollecteddate = currentRow.amountcollecteddate;
      l_coddetails.totalreceivableamount = currentRow.totalreceivableamount;
      l_coddetails.previoussettlementamount = currentRow.previoussettlementamount;
      l_coddetails.tellerid = currentRow.tellerid;
      l_coddetails.settlementamount = currentRow.settlementamount || 0;
      l_coddetails.createdby = sessionStorage["userid"];
      l_coddetails.createdon = new Date();
      l_CODDetails.push(l_coddetails);
    })
    if (this._CODSettlementForm.valid) {
      var codDate = this._CODSettlementForm.get("codsettlementdate").value;
      var datePipe = new DatePipe("en-US");
      var pCoddate = datePipe.transform(codDate, 'yyyy-MM-dd');

      //For Model and Form Mapping
      this._CODSettlement = this._CODSettlementForm.getRawValue();
      this._CODSettlement.codsettlementdate = (new Date(pCoddate));
      this._CODSettlement.createdby = parseInt(sessionStorage["userid"]);
      this._CODSettlement.createdon = new Date();
      this._CODSettlement.branchid = this._branchid;
      this._CODSettletmentObj = { "Codsettlement": this._CODSettlement, "Codsettlementdetail": l_CODDetails };
      if (this._action == 'create') {
        this._IsProgressSpinner = true;
        this._CODSettlementservice.Create(this._CODSettletmentObj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
            this.ngOnInit();
            this._IsProgressSpinner = false;
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

      }
    }

  }
  reset(event) {
    this._submitted = false;
    this.InitializeForm();
    this._CODSettlementDetails = [] = [];
    this.selectedCODSettlementRows = [] = [];
    this.TotalprevioussettlementAmt = 0;
    this.TotalcurrentsettlementAmt = 0;
    this.TotalAmt = 0;
  }
  goBack(event) {
    this._router.navigate(['/VCodSettlement']);
  }
  SelectAll(event) {

  }
  onRowSelect(event) {

  }
  onRowUnselect(event) {

  }
}
