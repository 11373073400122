<div class="page-container">
  <div class="inner-page-container">
    <div class="page-loader" *ngIf="_IsProgressSpinner">
        <p-progressSpinner strokeWidth="5"></p-progressSpinner>
    </div>
    <div class="white">
      <div class="page-title">
          <div class="row">
              <div class="col-md-5">
                  <h3>Blog</h3>
              </div>
              <div class="col-md-7 text-right">
                <div class="action-btn">
                  <button pButton pRipple label="" title="Save and Proceed" icon="las la-save"  (click)="onSave()" *ngIf="isview"></button>
                  <button pButton pRipple label="" title="Clear" type="button" (click)="reset($event)" *ngIf="isview" icon="pi pi-trash" class="" class="p-button-danger"></button>
                  <button pButton pRiple icon="pi pi-search" title="Back to Search"
                  class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                </div>
              </div>
          </div>
      </div>
        <div class="form-container scroll-y">
            <form [formGroup]="blogheaderform">
            <table class="normal-table">
                <tr>
                    <td style="width:20%"></td>
                    <td style="width:20%">
                        <span class="p-float-label">
                            <p-calendar view="month" dateFormat="mm/yy" [yearNavigator]="true" [showIcon]="true" yearRange="2000:2080" 
                            [readonlyInput]="true" inputId="monthpicker" formControlName="blogdate"></p-calendar>
                            <label for="inputtext">Month & Year <span class="hlt-txt">*</span></label>
                        </span>
                        <span class="text-danger"
                        *ngIf="(_submitted || blogheaderform.get('blogdate').touched) && blogheaderform.get('blogdate').errors?.SelectDate">
                        Please select month & year 
                    </span>
                    </td>
                    <td style="width:20%;">
                        <div class="p-inputgroup" *ngIf="_Iscreatefileupload">
                            <div class="file-upload-wrapper"   data-text="Select your file!">
                                <!-- <input #myInput name="file-upload-field" type="file" accept="image/png, image/gif, image/jpeg"  class="file-upload-field"
                                    (change)="fileChangeEvent($event)" value=""> -->

                                    <input name="file-upload-field" type="file" accept="image/png, image/gif, image/jpeg" class="file-upload-field"
                                    (change)="selectFile($event)" value="">
                            </div>
                           
                        </div>
                        <div class="p-inputgroup" *ngIf="_Isviewfileupload">
                            <span class="p-float-label">
                                <div class="browse-links">
                                    <p-button label="Blog" styleClass="p-button-link"
                                        title="{{imageselect}}" (click)="showImageDialog('bottom-right')">
                                    </p-button>
                                </div>
                            </span>
                            <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                [disabled]="!isview" (click)="onImageClear()" tooltipPosition="top">
                                <i class="pi pi-times"></i>
                            </button>
                        </div>

                    </td>
                  
                    <td style="width:20%"></td>
                </tr>
                <tr>
                    <td style="width:20%;"></td>
                    <td style="width:20%;">
                        <span class="p-float-label">
                            <input type="text" #pageName pInputText formControlName="pagename" (keyup)="onKey(pageName.value)"> 
                            <label for="inputtext">Page Name <span class="hlt-txt">*</span></label>
                        </span>
                        <span class="text-danger"
                        *ngIf="(_submitted || blogheaderform.get('pagename').touched) && blogheaderform.get('pagename').errors?.BranchCodeRequired">
                        Please enter Page Name 
                    </span>
                    </td>
                    <td>                
                        <span class="p-float-label">
                            <input type="text" id="inputtext" [ngModel]="pageURL" [disabled]="disabled" formControlName="pageurlname" pInputText> 
                            <label for="inputtext">Page URL <span class="hlt-txt">*</span></label>
                        </span>
                    </td>
                   <td>
                    <span class="p-float-label">
                        <p-dropdown [autoDisplayFirst]="false" [options]="_status"
                            optionLabel="metasubdescription" optionValue="metasubcode"
                            formControlName="recordstatus">
                        </p-dropdown>
                        <label for="status"> Status <span class="hlt-txt">*</span></label>
                    </span>
                   </td>
                </tr>
                <tr>
                    <td></td>
                    <td colspan="3">
                        <p-editor #editor [(ngModel)]="toolbarOptions" formControlName="template" [readonly]="iseditorview" [style]="{'height':'320px'}">
                        </p-editor>
                    </td>
                    <td></td>
                </tr>
            </table>
            </form>
        </div>
    </div>
  </div>
</div>
<p-dialog header="Image Cropper" [(visible)]="imageCropperTool" [modal]="true" [style]="{width: '1200px'}"
    [baseZIndex]="10000">
    <div class="text-center mb10">
        <button pButton pRipple label="" icon="las la-plus" pTooltip="Zoom +" tooltipPosition="top" (click)="zoomIn()"
            class="p-button p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-minus" pTooltip="Zoom -" tooltipPosition="top" (click)="zoomOut()"
            class="p-button p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-redo-alt" pTooltip="Reset Image" tooltipPosition="top"
            (click)="resetImage()" class="p-button-danger p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-save" type="button" pTooltip="Save" tooltipPosition="top"
            (click)="saveImage($event)" class="p-button-success p-mr-2"></button>
    </div>
    <div class="row">
        <div class="col-md-6">
            <h5>Input</h5>
            <div class="crop-input">
                <image-cropper class="img-fluid" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="16 / 9" [resizeToWidth]="800"
                    [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false"
                    [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                    [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed()"></image-cropper>
            </div>
        </div>
        <div class="col-md-6">
            <h5>Output</h5>
            <img [src]="croppedImage" />
        </div>
    </div>
</p-dialog>
<p-toast position="bottom-right"></p-toast>