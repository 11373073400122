import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { PettycashsettlementService } from 'src/app/core/services/accounts/pettycashsettlement.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Pettycashsettlementview } from 'src/app/core/Views/Pettycashsettlementview';
import { ConfirmationService } from 'primeng/api';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { DatePipe } from '@angular/common';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-pettycash-settlement',
  templateUrl: './v-pettycash-settlement.component.html',
  styleUrls: ['./v-pettycash-settlement.component.css']
})
export class VPettycashSettlementComponent implements OnInit {

  selectedrows: any;
  _Action: IAction;
  _PettycashSettlementview: Pettycashsettlementview;
  _PettycashSettlementviews: Pettycashsettlementview[];
  _IsProgressSpinner: boolean = true;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _branchid:number;
  _roleid=sessionStorage["currentRole"]
  _userid = sessionStorage["userid"];
  cols: any[];
  mindate: Date;
  maxdate: Date;
  constructor(private fb: FormBuilder, private _PettycashSettlementService: PettycashsettlementService, private router: Router, private utility: CommonUtilities,
    private _CustomExceptionService: CustomExceptionService, private confirmationService: ConfirmationService,public exportUtility: ExportUtility,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService) {
  }


  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._Action = this._AccessRightsService.getAccessRights(71);
    this.HotKeyIntegration();
    this.cols = [
      { field: 'pettycashrequestname', header: 'Employee Name' },
      { field: 'requestamount', header: 'Request Amount' },
      { field: 'settlementamount', header: 'Settled Amount' },
      { field: 'balanceamount', header: 'Balance' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.Fetchall();
  
  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._PettycashSettlementService.FetchAll(this._userid,this._branchid,this._roleid,fromdate,todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._PettycashSettlementviews = resultJSON.pettycashsettlementviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  view(event) {
    this.router.navigate(['/CPettycashSettlement'], { state: { pettycashsettlementid: event.pettycashsettlementid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/CPettycashSettlement']);
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Payment <b>"' + event.referenceno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._PettycashSettlementService.Cancel(event.pettycashsettlementid).subscribe((result) => {
      const resutJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
        this._CustomExceptionService.handleSuccess("Updated Successfully..");
      }
      else {
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
    }, error => {
      this._CustomExceptionService.handleError(error);
      this._IsProgressSpinner = false;
    });
  }
 

  exportExcel() {
    let PaymentList = this.GetPettyCashList();
    this.exportUtility.exportExcel(PaymentList, 'PettyCash', 'xlsx');
  }

  ExportToPdf() {
    let PaymentList = this.GetPettyCashList();
    this.exportUtility.ExportToPdf(PaymentList, 'PettyCash.pdf');
  }

  GetPettyCashList() {
    if (!this._PettycashSettlementviews || !this._PettycashSettlementviews.length)
      return;
    let PettyCashList = [];
    this._PettycashSettlementviews.forEach(element => {
      let pettycash: any = {};
      pettycash["Employee Name"] = element.pettycashrequestname;
      pettycash["Request Amount"] = element.requestamount;
      pettycash["Settled Amount"] = element.settlementamount;
      pettycash["Balance"] = element.balanceamount;
      pettycash["Created By"] = element.createdbyname;
      pettycash["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      PettyCashList.push(pettycash);
    });
    return PettyCashList;
  }

  delete(event) {
    alert("delete");
  }
  edit(event) {
    console.log(event);
    this.router.navigate(['/CPettycashSettlement'], { state: { pettycashsettlementid: event.pettycashsettlementid, action: 'edit' } });
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
}
