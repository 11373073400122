import { Component, OnInit } from '@angular/core';
import { CheckboxControlValueAccessor } from '@angular/forms';
import { watch } from 'fs';
import { MenuItem } from 'primeng/api';
import { CommonUtilities } from '../../../../assets/js/common-utilities';
import { Userrolemappedfunctionsview } from '../../Views/Userrolemappedfunctionsview';
import {PanelMenuModule} from 'primeng/panelmenu';
declare var $: any;

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css']
})
export class LeftMenuComponent implements OnInit {
  menuMaster: MenuItem[];
  menuPurchase: MenuItem[];
  menuSales: MenuItem[];
  menuUsers: MenuItem[];
  menuApproval: MenuItem[];
  menuAccounts: MenuItem[];
  menuRewards: MenuItem[];
  menuTracking: MenuItem[];
  menuStock: MenuItem[];
  menuReport: MenuItem[];
  menuDashboard: MenuItem[];
  menuOffline: MenuItem[];
  _Rewards: boolean = false;
  _Report: boolean = false;
  _Accounts: boolean = false;
  _Stock: boolean = false;
  _Tracking: boolean = false;
  _Users: boolean = false;
  _Approval: boolean = false;
  _Sales: boolean = false;
  _Purchase: boolean = false;
  _Master: boolean = false;
  _Dashboard: boolean = false;
  _Offline: boolean = false;
  reloadWatcher: NodeJS.Timeout;
  // Ecommerce
  menuEcommerce: MenuItem[];
  menuWorkshop: MenuItem[];
  _Ecommerce: boolean = false;	  
  _Workshop: boolean = false;	  

  constructor(private utility: CommonUtilities) { }

  getMenu(ScreensList: any, ModuleId: number, selectedRole: number) {
    var ParentMenu: MenuItem[] = [];
    var MasterScreenList: Userrolemappedfunctionsview[] = ScreensList.filter((obj) => { return (obj.roleid == selectedRole && obj.moduleid == ModuleId) });
    Object.keys(MasterScreenList).map((Index) => {
      var vMenu: MenuItem;
      vMenu = { "label": MasterScreenList[Index].screenname, "routerLink": MasterScreenList[Index].path };
      ParentMenu.push(vMenu);
    });
    return ParentMenu;
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.utility.customScrollbar();
    // ================================================
    // 1	Master	~/Master	0
    // 2	User	~/User	0
    // 3	Purchase	~/Purchase	0
    // 4	Tracking	~/Tracking	0
    // 5	Stock	~/Stock	0
    // 6	Sales	~/Sales	0
    // 7	Accounts	~/Accounts	0
    // 8	Rewards And Schemes	~/RewardsAndSchemes	0
    // 9	Reports	~/Reports	0
    // ======================================================= 
    this.PageInitialize();
    this.MenuClickInitialize();
    this.ReloadWatcher();

  }

  MenuClickInitialize() {
    $(document).ready(function () {
      $(document).on('click', ".left-menu li.has-sub>a", function () {
        var element = $(this).parent('li');
        if (element.hasClass('open')) {
          element.removeClass('open');
          element.find('li').removeClass('open');
          element.find('ul').slideUp();
        }
        else {
          element.addClass('open');
          element.children('ul').slideDown();
          element.siblings('li').children('ul').slideUp();
          element.siblings('li').removeClass('open');
          element.siblings('li').find('li').removeClass('open');
          element.siblings('li').find('ul').slideUp();
        }
      });
    });
  }



  PageInitialize() {
    var screenlist = JSON.parse(sessionStorage.getItem("Functions"));
    var selectedRole = sessionStorage["currentRole"];
    var vScreenList: Userrolemappedfunctionsview[] = screenlist;
    vScreenList = vScreenList.filter(f => f.roleid == selectedRole);

    //Master Menu 1
    this.menuMaster = this.getMenu(vScreenList, 1, selectedRole);
    this._Master = (this.menuMaster.length == 0) ? true : false;

    this.menuUsers = this.getMenu(vScreenList, 2, selectedRole);
    this._Users = (this.menuUsers.length == 0) ? true : false;

    this.menuApproval = this.getMenu(vScreenList, 102, selectedRole);
    this._Approval = (this.menuApproval.length == 0) ? true : false;

    this.menuPurchase = this.getMenu(vScreenList, 3, selectedRole);
    this._Purchase = (this.menuPurchase.length == 0 ? true : false);

    this.menuTracking = this.getMenu(vScreenList, 4, selectedRole);
    this._Tracking = (this.menuTracking.length == 0 ? true : false);

    this.menuStock = this.getMenu(vScreenList, 5, selectedRole);
    this._Stock = (this.menuStock.length == 0 ? true : false);

    this.menuSales = this.getMenu(vScreenList, 6, selectedRole);
    this._Sales = (this.menuSales.length == 0 ? true : false);

    this.menuAccounts = this.getMenu(vScreenList, 7, selectedRole);
    this._Accounts = (this.menuAccounts.length == 0) ? true : false;

    this.menuRewards = this.getMenu(vScreenList, 8, selectedRole);
    this._Rewards = (this.menuRewards.length == 0) ? true : false;

    // this.menuReport = this.getMenu(vScreenList, 9, selectedRole);
    // this._Report = (this.menuReport.length == 0) ? true : false;

    this.menuDashboard = this.getMenu(vScreenList, 127, selectedRole);
    this._Dashboard = (this.menuDashboard.length == 0) ? true : false;

    this.menuOffline = this.getMenu(vScreenList, 158, selectedRole);
    this._Offline = (this.menuOffline.length == 0) ? true : false;
     // Ecommerce
    this.menuEcommerce = this.getMenu(vScreenList, 300, selectedRole);
    this._Ecommerce = (this.menuEcommerce.length == 0 ? true : false);	

    this.menuWorkshop = this.getMenu(vScreenList, 328, selectedRole);
    this._Workshop = (this.menuWorkshop.length == 0 ? true : false);																  
    this.menuReport = [
      {
        label: 'Purchase', 
        items:this.getMenu(vScreenList, 135, selectedRole)
      },
      {
        label: 'Stock', 
        items:this.getMenu(vScreenList, 136, selectedRole)
      },
      {
        label: 'Sales', 
        items:this.getMenu(vScreenList, 137, selectedRole)
      },
      {
        label: 'Accounts', 
        items:this.getMenu(vScreenList, 138, selectedRole)
      },
      {
        label: 'Others', 
        items:this.getMenu(vScreenList, 139, selectedRole)
      }
      ]
      this._Report = (this.menuReport.length == 0) ? true : false;
  }

  ReloadWatcher() {
    if (!this.reloadWatcher) {
      this.reloadWatcher = setInterval(() => {
        let isReload = (sessionStorage["isReload"] == "true");
        if (isReload) {
          this.PageInitialize();
          sessionStorage["isReload"] = false;
        }
      }, 5000);
    }
  }

}