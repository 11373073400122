<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Product Delivery Report</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_rptproductdeliveryform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                            [yearNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                                        </p-calendar>
                                        <label for="fromdate">From Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                            [yearNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                                        </p-calendar>
                                        <label for="todate">To Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_invoicetypelist"
                                            optionValue="metasubcode" optionLabel="metasubdescription"
                                            formControlName="invoicetype" [filter]="true" filterBy="metasubdescription">
                                        </p-dropdown>
                                        <label for="invoicetypename">Inward Type </label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_deliverytypelist"
                                            optionValue="metasubcode" optionLabel="metasubdescription"
                                            formControlName="deliverytype" [filter]="true"
                                            filterBy="metasubdescription">
                                        </p-dropdown>
                                        <label for="deliverytypename">Delivery Type</label>
                                    </span>

                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_branchlist"
                                        optionValue="branchid" optionLabel="branchname" formControlName="branchid"
                                        [filter]="true" filterBy="branchname">                                     
                                    </p-dropdown>
                                    <label for="branchname">Branch Name </label> -->
                                        <p-multiSelect [filter]="false" [options]="_branchlist" optionLabel="branchname"
                                            optionValue="branchid" [(ngModel)]="branchids"
                                            [ngModelOptions]="{standalone: true}" displaySelectedLabel="true"
                                            [filter]="true" filterBy="branchname" [virtualScroll]="true" itemSize="30">
                                        </p-multiSelect>
                                        <label>Branch </label>
                                    </span>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_producttypelist" optionLabel="producttypename"
                                            optionValue="producttypeid" [(ngModel)]="producttypeid"
                                            [ngModelOptions]="{standalone: true}"
                                            (onChange)="getProductCategory($event)"
                                            [virtualScroll]="true" itemSize="30">
                                        </p-multiSelect>
                                        <label for="producttypeid">Product Type Name </label>
                                    </span>
                                </td>
                                <td>
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                                            [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}"
                                            (onChange)="getProduct($event)" optionValue="productcategoryid"
                                            [virtualScroll]="true" itemSize="30">
                                        </p-multiSelect>
                                        <label for="productcategoryid">Product Category Name</label>
                                    </span>
                                </td>
                                <td>
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_productlist" optionLabel="productname"
                                            optionValue="productid" [(ngModel)]="productid"
                                            [ngModelOptions]="{standalone: true}"
                                            [virtualScroll]="true" itemSize="30">
                                        </p-multiSelect>
                                        <label for="productid">Product Name </label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_brandlist" optionLabel="brandname"
                                            optionValue="brandid"   [(ngModel)]="brandid" [ngModelOptions]="{standalone: true}"
                                            [virtualScroll]="true" itemSize="30">
                                        </p-multiSelect>
                                        <label for="brandid">Brand Name </label>
                                    </span>
                                </td>
                                <td>
                                    <div class="p-formgroup-inline">
                                        <div class="p-field-checkbox">
                                            <p-checkbox name="isdelivered" binary="true" formControlName="isdelivered">
                                            </p-checkbox>
                                            <label for="isdelivered">Is Delivered?</label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div class="output-container">
                    <iframe #iframe class="scroll-height" width="100%" [src]="_src"></iframe>
                </div>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>

        </div>
    </div>

</div>
<p-toast position="bottom-right"></p-toast>