import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  public _Action: IAction;
  public _PaymentService = environment.PaymentService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(paymentid: number, userid: number, branchid: number, voucherno: string): Observable<any> {
    const headers = this.header;
    var Query = this._PaymentService + '/PageOnload' + '/' + paymentid + '/' + userid + '/' + branchid + '/' + voucherno;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
    const headers = this.header;
    var Query = this._PaymentService + '/FetchAll' + '/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      return data;
    }));
  }

  create(payment: object) {
    const headers = this.header;
    var Query = this._PaymentService;
    return this.http.post<{ data: any }>(Query, payment, headers);
  }

  Cancel(paymentid: number, series: number, modifiedby: number, paymentno: string): Observable<any> {
    const headers = this.header;
    console.log("cancel");
    var Query = this._PaymentService + '/Cancel/' + paymentid + '/' + series + '/' + modifiedby + '/' + paymentno;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchBillDetails(fetchbilldetails: object) {
    const headers = this.header;
    var Query = this._PaymentService + '/FetchBillDetails';
    return this.http.post<{ data: any }>(Query, fetchbilldetails, { headers });
  }
  // FetchBillDetails(partyType: number, Partycode: number, series: number) {
  //   var Query = this._PaymentService + '/FetchBillDetails' + '/' + partyType + '/' + Partycode + '/' + series;
  //   return this.http.get<{ data: any }>(Query);
  // }

  GetCustomerByPartyType(userid: number, partyType: number) {
    const headers = this.header;
    var Query = this._PaymentService + '/GetCustomerByPartyType' + '/' + userid + '/' + partyType;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  GetFromAccountClosingBalance(ledgerid: number) {
    const headers = this.header;
    var Query = this._PaymentService + '/GetFromAccountClosingBalance/' + ledgerid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  PaymentPrint(obj: any) {
    const headers = this.header;
    var Query = this._PaymentService + '/PAYMENTPRINT';
    return this.http.post(Query, obj, { responseType: 'blob', headers });
  }
}