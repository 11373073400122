<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Purchase Order </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;" [hidden]="_Branchdrop">
                            <span class="p-float-label">
                                <p-dropdown 
                                 [options]="_Branch" [(ngModel)]="_Branchname"
                                 [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="branchname"
                                    optionValue="branchid" optionLabel="branchname">
                                </p-dropdown>
                                <label for="productid">Branch <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                            (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                            pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_Purchaseorderviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['purchaseorderno','suppliername','currencyname','totalpoamount','statusame','purchaseorderinwardstatusname','createdbyname']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="purchaseinvoiceno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <!-- <p-table [value]="_Purchaseorderviews" [rows]="10" [paginator]="true" dataKey="Sno" editMode="row"> -->
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width:210px;">Action
                                </th>
                                <th [hidden]="true"></th>
                                <th pSortableColumn="purchaseorderno">Purchase Request No
                                    <p-sortIcon field="purchaseorderno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="purchaseorderdate">Purchase Date
                                    <p-sortIcon field="purchaseorderdate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="suppliername">Supplier Name
                                    <p-sortIcon field="suppliername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname">Currency Name
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="totalpoamount">Total Po amount
                                    <p-sortIcon field="totalpoamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="statusame">Status
                                    <p-sortIcon field="statusame">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="purchaseorderinwardstatusname">Inward Status
                                    <p-sortIcon field="purchaseorderinwardstatusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Purchaseorderview let-ri="rowIndex">
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_Purchaseorderview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update" [disabled]="_Purchaseorderview.status == 'MSC00150' ||_Purchaseorderview.status == 'MSC00046' || _Purchaseorderview.status == 'MSC00047' ||_Purchaseorderview.status == 'MSC00048'"
                                        (click)="edit(_Purchaseorderview)"></button>
                                    <button pButton pRipple icon="las la-times" title="Cancel" [disabled]="!_Action._Delete" [disabled]="_Purchaseorderview.status == 'MSC00150' || _Purchaseorderview.status == 'MSC00046' || _Purchaseorderview.status == 'MSC00047' || _Purchaseorderview.status == 'MSC00048' "
                                        class="p-button p-button-danger" (click)="confirm(_Purchaseorderview)"></button>
                                    <button pButton pRipple title="Send Email" icon="las la-paper-plane" type="button" (click)="SendMail(_Purchaseorderview)"></button>
                                    <button pButton pRipple icon="las la-print" title="Print" [disabled]="_Purchaseorderview.status != 'MSC00047' || !_Action._Print" class="p-button-warning p-mr-2" (click)="Print(_Purchaseorderview)"></button>
                                    <button pButton pRipple icon="las la-times-circle" title="Close" [disabled]="!_Action._Delete" [disabled]="_Purchaseorderview.purchaseorderinwardstatus == 'MSC00270'" class="p-button p-button-danger" (click)="confirmcancel(_Purchaseorderview)"></button>

                                    <button pButton pRipple icon="las la-print" title="Print" [disabled]="_Purchaseorderview.status != 'MSC00047' || !_Action._Print" class="p-button-warning p-mr-2" (click)="TempPrint(_Purchaseorderview)">
                                    </button> {{_Purchaseorderview.Action}}
                                </td>
                                <td [hidden]="true">
                                    {{_Purchaseorderview.purchaseorderid}}
                                </td>
                                <td>
                                    {{_Purchaseorderview.purchaseorderno}}
                                </td>
                                <td>
                                    {{_Purchaseorderview.purchaseorderdate | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_Purchaseorderview.suppliername}}
                                </td>
                                <td>
                                    {{_Purchaseorderview.currencyname}}
                                </td>
                                <td>
                                    {{_Purchaseorderview.totalpoamount}}
                                </td>
                                <td>
                                    {{_Purchaseorderview.statusame}}
                                </td>
                                <td>
                                    {{_Purchaseorderview.purchaseorderinwardstatusname}}
                                </td>
                                <td>
                                    {{_Purchaseorderview.createdbyname}}
                                </td>
                                <td>
                                    {{_Purchaseorderview.createdon|date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </div>
        </div>
    </div>
</div>

<p-toast position="bottom-right"></p-toast>