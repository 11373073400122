export class Orderstatuslog {
    logid: number;
    orderid: number ;
    orderno: string;
    orderstatuscode: string;
    ordersubstatuscode: string;
    orderdescstatuscode: string;
    recordstatus: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    logtype:string;
    locationcode:number;
    shipmentno:string;
    assignedname: string;
    assignedtostatus: string;
    assignedid: string;
}

