<p-toast position="bottom-right"></p-toast>
<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Edit Order Status</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <button pButton pRipple label="" title="Back to View" icon="pi pi-eye" class="p-button-success"
                            [routerLink]="['/vOrderStatus']"></button>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_Myordershistoryform">
                    <table class="normal-table">
                        <tr>
                            <td style="width:20%">
                                <span class="p-float-label">
                                    <!-- <input type="text" pInputText disabled formControlName="orderid"> -->
                                    <input type="text" name="orderno" disabled formControlName="orderno" pInputText>
                                    <label for="inputtext">Order NO <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <!-- <td style="width:20%">
                                <span class="p-float-label">
                                   
                                    <input type="text" name="orderid" disabled formControlName="orderid" pInputText>
                                    <label for="inputtext">Order ID <span class="hlt-txt">*</span></label>
                                </span>
                            </td> -->
                            <td style="width:20%">
                                <form [formGroup]="_Myordershistorydemoform">

                                    <span class="p-float-label">
                                        <input type="text" pInputText formControlName="totalitem" disabled>
                                        <label for="inputtext">Total Items <span class="hlt-txt">*</span></label>
                                    </span>
                                </form>
                            </td>
                            <td style="width:20%">
                                <form [formGroup]="_Myordershistorydemoform">
                                    <span class="p-float-label">
                                        <!-- <input type="text" pInputText disabled formControlName="ordertotal"> -->
                                        <input type="text" name="ordertotal" disabled formControlName="ordertotal"
                                            pInputText>
                                        <label for="inputtext">Order Total <span class="hlt-txt">*</span></label>
                                    </span>
                                </form>
                            </td>
                            <td style="width:20%"></td>
                            <td style="width:20%"></td>
                        </tr>
                        <tr>
                            <td>
                                <form [formGroup]="_Shipmentidgenerateform">
                                    <span class="p-float-label">
                                        <!--  -->
                                        <p-dropdown [autoDisplayFirst]="false" [options]="Shipmenttypelst"
                                            [disabled]="disabledshipment" formControlName="shipmenttype"
                                            (onChange)="OnSelectShipmentType($event)" optionLabel="metasubdescription"
                                            optionValue="metasubcode">
                                        </p-dropdown>
                                        <label for="dropdown">Shipment Type</label>
                                    </span>
                                </form>
                            </td>
                            <td>
                                <form [formGroup]="_Shipmentidgenerateform">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_MyorderDetails1" formControlName="items"
                                            [virtualScroll]="true" itemSize="30" [filter]="true"
                                            [(ngModel)]="_selectedOrderIDs" optionLabel="productname"
                                            (onChange)="UpdateOrderStatus()" optionValue="productid"
                                            displaySelectedLabel=true>
                                        </p-multiSelect>
                                        <label for="producttypeid">Choose Product
                                        </label>
                                    </span>
                                </form>
                            </td>
                            <td>
                                <form [formGroup]="_Shipmentidgenerateform">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_shipmentList"
                                            [disabled]="disabledshipmentID" formControlName="shipmentno"
                                            (onChange)="OnselectShipmentNo($event)" optionLabel="shipmentno"
                                            optionValue="shipmentno">
                                        </p-dropdown>
                                        <label for="dropdown">Choose Shipment ID</label>
                                    </span>
                                </form>
                            </td>

                            <!-- <td>
                                <p-button label="Generate" icon="las la-save" type="button" (click)="Generate()">
                                </p-button>
                            </td> -->
                            <td> </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Status"
                                        (onChange)="Changestatus()" formControlName="orderstatus"
                                        optionLabel="metasubdescription" optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="dropdown">Order Status</label>
                                </span>
                            </td>
                            <td>
                                <form [formGroup]="_Myordershistorydemoform">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_SubStatus"
                                            (onChange)="GetDescription($event)" formControlName="ordersubstatus"
                                            optionLabel="metasubdescription" optionValue="metasubcode">
                                        </p-dropdown>
                                        <label for="dropdown">Sub Status</label>
                                    </span>
                                </form>
                            </td>
                            <td hidden>
                                <form [formGroup]="_Myordershistorydemoform">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_Desccription"
                                            formControlName="statusdetail" optionLabel="orderdescstatusname"
                                            optionValue="osdid">
                                        </p-dropdown>
                                        <label for="dropdown">Description</label>
                                    </span>
                                </form>
                            </td>
                            <td>
                                <form [formGroup]="_Myordershistorydemoform">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_AssignedTo"
                                            formControlName="assigneetostatus" (onChange)="ChangeAssignedTo()"
                                            optionLabel="metasubdescription" optionValue="metasubcode">
                                        </p-dropdown>
                                        <label for="assigneetostatus">Assigned To</label>
                                    </span>
                                </form>
                            </td>
                            <td *ngIf="IsEmployee">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Employees" [filter]="true"
                                        [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="employeename"
                                        [showClear]="true" formControlName="assigneeid" optionLabel="employeename"
                                        optionValue="employeeid">
                                    </p-dropdown>
                                    <label for="dropdown">Employee Name</label>
                                </span>
                            </td>
                            <td *ngIf="IsServiceProvider">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_ServiceProviders"
                                        formControlName="assigneeid" optionLabel="metasubdescription"
                                        optionValue="metasubdescription">
                                    </p-dropdown>
                                    <label for="inputtext">Service Providers <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td *ngIf="IsServiceProvider">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="assigneename">
                                    <label for="inputtext">PickUp Person Name <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" class="text-center">
                                <p-button label="Save" icon="las la-save" [disabled]="_IsDisabledSavestatus"
                                    type="button" (click)="Update()"></p-button>
                                <!-- <button pButton pRipple label="Processed" class="p-button-success" icon="las la-save"
                                    type="button" (click)="Process()"></button> -->
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </form>
                <p-tabView>
                    <p-tabPanel header="Order">
                        <form [formGroup]="_Myordershistoryform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width:20%">
                                        <form [formGroup]="_Myordershistorydemoform">
                                            <span class="p-float-label">
                                                <!-- <input type="text" pInputText disabled
                                                formControlName="orderdate"> -->
                                                <p-calendar [showIcon]="true" formControlName="orderdate"
                                                    disabled="true" [monthNavigator]="true" dateFormat="dd/mm/yy"
                                                    [yearNavigator]="true" yearRange="1980:2030"></p-calendar>
                                                <!-- <input type="text" name="orderdate" disabled="true" dateFormat="dd/mm/yy"
                                                formControlName="orderdate" pInputText> -->
                                                <label for="inputtext">Order Date <span class="hlt-txt">*</span></label>
                                            </span>
                                        </form>
                                    </td>
                                    <td style="width:20%">
                                        <form [formGroup]="_Myordershistorydemoform">
                                            <span class="p-float-label">
                                                <!-- <input type="text" pInputText disabled
                                                formControlName="value3"> -->
                                                <input type="text" disabled="true" formControlName="ordertime"
                                                    pInputText>
                                                <label for="inputtext">Order Time <span class="hlt-txt">*</span></label>
                                            </span>
                                        </form>
                                    </td>
                                    <td style="width:20%">
                                        <span class="p-float-label">
                                            <!-- <input type="text" pInputText disabled
                                                formControlName="modeofpayment"> -->
                                            <input type="text" name="modeofpaymentname" disabled="true"
                                                formControlName="modeofpaymentname" pInputText>
                                            <label for="inputtext">Payment Method <span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td style="width:20%" rowspan="2">
                                        <span class="p-float-label">
                                            <textarea rows="3" cols="30" pInputTextarea
                                                formControlName="deliveryaddress"></textarea>
                                            <label for="inputtext">Delivery Address <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td style="width:20%"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <!-- <input type="text" pInputText disabled
                                                formControlName="shippingaddresscustomername"> -->
                                            <input type="text" name="deliverymodename" disabled="true"
                                                formControlName="deliverymodename" pInputText>
                                            <label for="inputtext">Delivery Mode <span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <!-- <input type="text" pInputText disabled
                                                formControlName="shippingaddresscustomername"> -->
                                            <input type="text" name="shippingaddresscustomername" disabled="true"
                                                formControlName="shippingaddresscustomername" pInputText>
                                            <label for="inputtext">Customer Name <span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <!-- <input type="text" pInputText disabled
                                                formControlName="shippingaddresscustomername"> -->
                                            <input type="text" name="mobileno" disabled="true"
                                                formControlName="mobileno" pInputText>
                                            <label for="inputtext">Mobile No<span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <!-- <td>
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <input type="text" pInputText disabled
                                                    formControlName="value3">
                                                    <input type="text" name="productcode" disabled="true"
                                                formControlName="productcode" pInputText>
                                            </span>
                                            <button class="p-inputgroup-addon p-button" pTooltip="View Invoice"
                                                tooltipPosition="top">
                                                <i class="las la-eye"></i>
                                            </button>
                                        </div>
                                    </td> -->
                                    <td></td>
                                    <td></td>
                                </tr>
                                <!-- <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div> -->
                            </table>
                        </form>
                    </p-tabPanel>
                    <p-tabPanel header="Order Status">
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="las la-search"></i>
                                        <input pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </ng-template>

                                <ng-template pTemplate="right">
                                    <button type="button" pButton pRipple icon="las la-file-csv" title="Export CSV"
                                        class="p-mr-2" pTooltip="CSV" (click)="exportUtility.exportCSV(dt)"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="las la-file-excel" title="Export Excel"
                                        class="p-button-success p-mr-2" pTooltip="XLS"
                                        (click)="exportExcelOrderStatus()" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="las la-file-pdf" title="Export PDF"
                                        class="p-button-warning p-mr-2" pTooltip="PDF" (click)="ExportOrderStatusPdf()"
                                        tooltipPosition="bottom"></button>
                                </ng-template>
                            </p-toolbar>

                            <p-table #dt [value]="_MyorderDetails" [rows]="10" [paginator]="true" [scrollable]="true"
                                [rowsPerPageOptions]="[10,25,50,100]" [columns]="colsorderstatus"
                                [globalFilterFields]="['sno','orderid','productname','orderquantity','cancelquantity','totalquantity','unitprice','taxamount','shippingcharges','discountamount','shipmentno','netamount','orderstatusname']"
                                [rowHover]="true" dataKey="id" [style]="{'overflow':'auto!important'}"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                scrollHeight="200px" [showCurrentPageReport]="true">
                                <ng-template pTemplate="colgroup" let-columns>
                                    <colgroup>
                                        <col style="width:80px;">
                                        <col style="width:150px">
                                        <col style="width:200px">
                                        <col style="width: 150px;">
                                        <col style="width: 150px;">
                                        <col style="width: 150px;">
                                        <col style="width:150px">
                                        <col style="width:150px">
                                        <col style="width: 150px;">
                                        <col style="width: 150px;">
                                        <col style="width: 150px;">
                                        <col style="width:200px">
                                        <col style="width:200px">
                                    </colgroup>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <!-- <th style="width:100px;" class="text-center">Action</th> -->
                                        <th style="width:80px;" pSortableColumn="sno">S. No <p-sortIcon field="sno">
                                            </p-sortIcon>
                                        </th>
                                        <th style="width:150px;" pSortableColumn="orderid">Order ID <p-sortIcon
                                                field="orderid"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="productname">Order Item <p-sortIcon field="productname">
                                            </p-sortIcon>
                                        </th>

                                        <th style="width:150px;" pSortableColumn="orderquantity">Ordered Quantity
                                            <p-sortIcon field="orderquantity"></p-sortIcon>
                                        </th>
                                        <th style="width:150px;" pSortableColumn="cancelquantity">Cancel/Return Quantity
                                            <p-sortIcon field="cancelquantity"></p-sortIcon>
                                        </th>
                                        <th style="width:150px;" pSortableColumn="totalquantity">Total Quantity
                                            <p-sortIcon field="totalquantity"></p-sortIcon>
                                        </th>
                                        <th style="width:150px;" pSortableColumn="unitprice">Unit Price <p-sortIcon
                                                field="unitprice"></p-sortIcon>
                                        </th>
                                        <th style="width:150px;" pSortableColumn="taxamount">Tax Amount <p-sortIcon
                                                field="taxamount"></p-sortIcon>
                                        </th>
                                        <th style="width:150px;" pSortableColumn="discountamount">Discount Amount
                                            <p-sortIcon field="discountamount">
                                            </p-sortIcon>
                                        </th>
                                        <th style="width:150px;" pSortableColumn="shippingcharges">Shipping Charges
                                            <p-sortIcon field="shippingcharges"></p-sortIcon>
                                        </th>
                                        <th style="width:150px;" pSortableColumn="netamount">Total <p-sortIcon
                                                field="netamount"></p-sortIcon>
                                        </th>
                                        <!--  -->
                                        <th pSortableColumn="shipmentno">Shipment No <p-sortIcon field="shipmentno">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="orderstatusname">Order Status <p-sortIcon
                                                field="orderstatusname">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_orderstatusGrid let-i="rowIndex">
                                    <tr>
                                        <td class="text-right">
                                            {{i+ 1}}
                                        </td>
                                        <td>
                                            {{_orderstatusGrid.orderid}}
                                        </td>
                                        <td>
                                            {{_orderstatusGrid.productname}}
                                        </td>
                                        <td class="text-right">
                                            {{_orderstatusGrid.orderquantity}}
                                        </td>
                                        <td class="text-right">
                                            {{_orderstatusGrid.cancelquantity}}
                                        </td>
                                        <td class="text-right">
                                            {{_orderstatusGrid.totalquantity}}
                                        </td>
                                        <td class="text-right">
                                            {{_orderstatusGrid.unitprice| number: '1.2'}}
                                        </td>
                                        <td class="text-right">
                                            {{_orderstatusGrid.taxamount| number: '1.2'}}
                                        </td>
                                        <td class="text-right">
                                            {{_orderstatusGrid.discountamount| number: '1.2'}}
                                        </td>
                                        <td class="text-right">
                                            {{_orderstatusGrid.shippingcharges| number: '1.2'}}
                                        </td>
                                        <td class="text-right">
                                            {{_orderstatusGrid.netamount| number: '1.2'}}
                                        </td>
                                        <td>
                                            {{_orderstatusGrid.shipmentno}}
                                        </td>
                                        <td>
                                            {{_orderstatusGrid.orderstatusname}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Status History">
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="las la-search"></i>
                                        <input pInputText type="text"
                                            (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </ng-template>

                                <ng-template pTemplate="right">
                                    <button type="button" pButton pRipple icon="las la-file-csv" title="Export CSV"
                                        class="p-mr-2" pTooltip="CSV" (click)="exportUtility.exportCSV(dt1)"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="las la-file-excel" title="Export Excel"
                                        class="p-button-success p-mr-2" pTooltip="XLS"
                                        (click)="exportExcelStatusHistory()" tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="las la-file-pdf" title="Export PDF"
                                        class="p-button-warning p-mr-2" pTooltip="PDF"
                                        (click)="ExportStatusHistoryPdf()" tooltipPosition="bottom"></button>
                                </ng-template>
                            </p-toolbar>

                            <p-table #dt1 [value]="StatusHistoryDetailsTemp" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[10,20,50,100]"
                                [globalFilterFields]="['shipmentno','modifiedon','orderstatusname','ordersubstatusname','modifiedbyname']"
                                [rowHover]="true" dataKey="id" [columns]="colsstatushistory"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="shipmentno">Shipment No <p-sortIcon field="shipmentno">
                                            </p-sortIcon>
                                        </th>
                                        <th style="width:200px;" pSortableColumn="modifiedon">Order Date <p-sortIcon
                                                field="modifiedon">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="orderstatusname">Status <p-sortIcon
                                                field="orderstatusname"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="ordersubstatusname">Sub Status <p-sortIcon
                                                field="ordersubstatusname"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="modifiedbyname">Staff/Customer Name <p-sortIcon
                                                field="modifiedbyname">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_statushistoryGrid>
                                    <tr>
                                        <td>
                                            {{_statushistoryGrid.shipmentno}}
                                        </td>
                                        <td>
                                            {{_statushistoryGrid.modifiedon}}
                                        </td>
                                        <td>
                                            {{_statushistoryGrid.orderstatusname}}
                                        </td>
                                        <td>
                                            {{_statushistoryGrid.ordersubstatusname}}
                                        </td>
                                        <td>
                                            {{_statushistoryGrid.modifiedbyname}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog [style]="{width: '450px'}" header="Confirmation"></p-confirmDialog>