import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Category } from 'src/app/core/Models/Category';
import { CategoryService } from 'src/app/core/Services/masters/category.service';
//Debug
import * as ace from 'ace-builds';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import {
  vCategoryName,
  vStatusSelect,
  vType,
} from 'src/app/core/Validators/validation';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-c-category',
  templateUrl: './c-category.component.html',
  styleUrls: ['./c-category.component.css'],
})
export class CCategoryComponent implements OnInit {
  _IsProgressSpinner: boolean = false;
  _SaveHide: boolean = false;
  _ClearHide: boolean = false;
  isDisabledreset = false;
  isDisabledsave = false;

  _categoryform: FormGroup;
  _categoryid: object;
  _submitted = false;
  _action: string = 'create';
  _categorystatus: Metadatum[];
  _Series: Metadatum;
  _type: Metadatum;
  _Action: IAction;
  _Category: Category;
  category: SafeUrl;

  @ViewChild('editor', { static: true })
  private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private utility: CommonUtilities,
    private _CategoryService: CategoryService,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private router: Router,
    private _AccessRightsService: AccessRightsService,
    private _DomSanitizationService: DomSanitizer
  ) {
    this._categoryid = history.state?.categoryid
      ? history.state?.categoryid
      : 0;
    this._action =
      history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }

  InitializeForm() {
    this._categoryform = this.fb.group({
      categoryid: [0],
      categoryname: ['', vCategoryName],
      categorystatus: ['', vStatusSelect],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      series: [''],
      type:['', vType]
    });
    this._categoryform.controls['series'].setValue('MSC00093');
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();

    this.InitializeForm();
    // this._categoryid = history.state.categoryid;
    this._Action = this._AccessRightsService.getAccessRights(364);


    
    this._IsProgressSpinner = true;
    this._CategoryService.PageOnLoad({}).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._categorystatus = resultJSON.categoryStatus;
        this._type = resultJSON.type;
        this._IsProgressSpinner = false;

        this.isDisabledsave = false;
        // if (this._action == 'edit' || this._action == 'view') {
        //   this.isDisabledreset = true;
        //   const updateJSON = JSON.parse(JSON.stringify(result));
        //   this._categoryform.setValue(updateJSON.categoryid);
        //   this.isDisabledsave = false;
        //   this.isDisabledreset = false;
        // }
        this._categoryid = history.state.categoryid;
        if (this._action == 'edit' || this._action == 'view') {
          this._CategoryService
            .View(this._categoryid)
            .subscribe(
              (result) => {
                const updateJSON = JSON.parse(JSON.stringify(result));
                // console.log(updateJSON.categoryviews);
                if (updateJSON.category.categoryid) {
                  this.category =
                    this._DomSanitizationService.bypassSecurityTrustUrl(
                      updateJSON.category.category
                    );
                }
                this._categoryform.setValue(updateJSON.category);
                this._categoryid = updateJSON.category.categoryid;
              },
              (error) => {
                this._CustomExceptionService.handleError(error);
                this._IsProgressSpinner = false;
              }
            );
        }
        if (this._action == 'view') {
          this._categoryform.disable();
          this.isDisabledsave = true;
          this.isDisabledreset = true;
        }
        if (this._action == 'edit') {
          this.isDisabledreset = true;
        }
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  onSave() {
    this._submitted = true;
    if (this._categoryform.valid) {
      this._Category = this._categoryform.value;
      this._IsProgressSpinner = true;
      if (this._action == 'create') {
        this._Category.createdon = new Date();
        this._Category.createdby = sessionStorage['userid'];
        this._IsProgressSpinner = true;
        this._CategoryService.Create(this._Category).subscribe(
          (result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (
              resutJSON.tranStatus.result == true &&
              (resutJSON != null || resutJSON != undefined)
            ) {
              this.isDisabledsave = true;
              this._IsProgressSpinner = false;
              //10.after saving the record, get the response A, display the success message as "Country Name A  Registered Succesfully "
              this._CustomExceptionService.handleSuccess(
                usererrors.Saved_Success_00
              );
              this.reset(null);
              this.isDisabledsave = false;
            } else {
              //6.check in the country table whether country code is already given, show error as "country code A already Exists"
              //7.check in the country table whether country name is already given, show error as "country Name A already Exists"
              this._CustomExceptionService.handleError(
                resutJSON.tranStatus.lstErrorItem[0].message
              );
            }
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
      } else if (this._action == 'edit') {
        this._Category.modifiedon = new Date();
        this._Category.modifiedby = sessionStorage['userid'];
        this._IsProgressSpinner = true;
        this._CategoryService.edit(this._Category).subscribe(
          (result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (
              resutJSON.tranStatus.result == true &&
              (resutJSON != null || resutJSON != undefined)
            ) {
              this.isDisabledsave = true;
              this._IsProgressSpinner = false;
              this._CustomExceptionService.handleSuccess(
                usererrors.Update_Success_04
              );

              this.isDisabledsave = false;
            } else {
              //24.after Updating the record, get the response A, display the success message as "Country  Name A  Modified Succesfully "
              this._CustomExceptionService.handleError(
                resutJSON.tranStatus.lstErrorItem[0].message
              );
            }
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
      }
    }
  }
  // 12. clear all the fields which the data has entered
  reset(event) {
    this._submitted = true;
    this._categoryform.reset();
    this.InitializeForm();
    this._submitted = false;
  }
  //23. on clicking back button , it Navigates to the search screen
  goBack(event) {
    //alert('This');
    //console.log("goBack");
    this._router.navigate(['/vCategory']);
  }

  handleError(error: any) {
    this._CustomExceptionService.handleError(error);
    this._IsProgressSpinner = false;
  }

  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+s',
          (event: KeyboardEvent): boolean => {
            this.onSave();
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    if (this._action == 'create') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+alt+c',
          (event: KeyboardEvent): boolean => {
            this.reset(null);
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    this._hotkeysService.add(
      new Hotkey(
        'ctrl+left',
        (event: KeyboardEvent): boolean => {
          this.goBack(null);
          return false; // Prevent bubbling
        },
        ['INPUT', 'SELECT', 'TEXTAREA']
      )
    );
  }
}
