import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NumberingschemaService } from 'src/app/core/Services/masters/numberingschema.service';
import { Numberingschemaview } from 'src/app/core/Views/Numberingschemaview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-v-numbering-schema',
  templateUrl: './v-numbering-schema.component.html',
  styleUrls: ['./v-numbering-schema.component.css']
})

export class VNumberingSchemaComponent implements OnInit {
  _numberingschemaform: FormGroup;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _numberingschemasview: Numberingschemaview[]=[];
  _numberingschemaview: Numberingschemaview;
  _Action: IAction;
  _numberschemaid: number
  _IsProgressSpinner: boolean = true;
  datepipe = new DatePipe('en-US');
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _NumberingschemaService: NumberingschemaService,
    private router: Router,
    private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,
    private exportUtility: ExportUtility
  ) {
    this._Action = this._AccessRightsService.getAccessRights(24);
    this.HotKeyIntegration();
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(24);
    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;
    this._NumberingschemaService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._numberingschemasview = resultJSON.numberingschemaviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  onRowSelect(event) { }
  onRowUnselect(event) { }
  delete(event) { }
  view(event) {
    this.router.navigate(['/cNumberingschema'], { state: { numberschemaid: event.numberschemaid, action: 'view' } });
  }
  edit(event) {
    this.router.navigate(['/cNumberingschema'], { state: { numberschemaid: event.numberschemaid, action: 'edit' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cNumberingschema']);
  }
  confirm(event) {
    this._numberschemaid = event.numberschemaid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Numbering Schema <b>"' + event.documentname + '"</b> ?',
      accept: () => {
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._NumberingschemaService.Cancel(this._numberschemaid, sessionStorage["userid"]).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_01);
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._numberingschemasview = resultJSON.numberingschemaviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'NumberingSchema', 'xlsx');
  }
  ExportToPdf() {
    let finalizedList =  this.GetAssetRegistrationList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Document Name',
      'Prefix',
      'Suffix',
      'Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('NumberingSchema.pdf');
      })
    })
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._numberingschemasview.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Document Name"] = element.documentname;
      assetregistration["Prefix"] = element.prefix;
      assetregistration["Suffix"] = element.suffix;
      assetregistration["Status"] = element.numberingschemastatusname;
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
}