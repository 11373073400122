import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Tax } from 'src/app/core/Models/Tax';
import { TaxService } from 'src/app/core/services/masters/tax.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { DatePipe } from '@angular/common'
import { Taxview } from 'src/app/core/Views/Taxview ';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ConfirmationService } from 'primeng/api';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-tax',
  templateUrl: './v-tax.component.html',
  styleUrls: ['./v-tax.component.css']
})
export class VTaxComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _taxesview: Taxview[]=[];
  _taxview: Taxview;
  selectedrows: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _Action: IAction;
  datepipe = new DatePipe('en-US');
  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private _TaxService: TaxService, 
    private router: Router, 
    private utility: CommonUtilities, 
    private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService, 
    private confirmationService: ConfirmationService,
    private _hotkeysService: HotkeysService,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this._IsProgressSpinner = true;
    this._Action = this._AccessRightsService.getAccessRights(26);
    this.HotKeyIntegration();
    this.FetchAll();
  }

  FetchAll() {
    this.utility.pageLoadScript();
    this._TaxService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._taxesview = resultJSON.taxviews;
      this._IsProgressSpinner = false;
    }, error => this.handleError(error));
  }

  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  view(event) {
    // 22.View Initialize should load all fields based on search page selected Tax code and all fields should be in read-only mode.
    this.router.navigate(['/cTax'], { state: { taxid: event.taxid, action: 'view' } });
  }

  Cancel(view: Taxview) {
    let userid = sessionStorage["userid"];


    this._IsProgressSpinner = true;
    this._TaxService.Cancel(view.taxid, userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON && resultJSON.tranStatus.result) {
        this._CustomExceptionService.handleSuccess(usererrors.TaxDeactive);
        this.FetchAll();
      }
      this._IsProgressSpinner = false;
    }, error => this.handleError(error));
  }


  // 25.update initialize should load all fields based on search page selected Tax code
  edit(event) {
    var TaxName = (<string>event.taxname).toUpperCase().trim();
    if (TaxName != "VAT") {

      this.router.navigate(['/cTax'], { state: { taxid: event.taxid, action: 'edit' } });
    }
    else {
      this._CustomExceptionService.handleError(usererrors.TaxNotEditable);
    }
  }

  confirm(view: Taxview) {
    var TaxName = (<string>view.taxname).toUpperCase().trim();
    if (TaxName != "VAT") {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to Inactive this Tax <b>"' + view.taxname + '"</b> ?',
        accept: () => {
          //Actual logic to perform a confirmation
          this.Cancel(view);
        }
      });
    }
    else {
      this._CustomExceptionService.handleError(usererrors.TaxNotDeactive);
    }

  }

  handleError(error: any) {
    this._CustomExceptionService.handleError(error);
    this._IsProgressSpinner = false;
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.router.navigate(['/cTax']);
        return false; // Prevent bubbling
      }));
    }
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'Tax', 'xlsx');
  }
  ExportToPdf() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.ExportToPdf(AssetRegistrationList, 'Tax.pdf');
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._taxesview.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Tax Code"] = element.taxcode;
      assetregistration["Tax Name"] = element.taxname;
      assetregistration["Tax Percentage"] = element.taxpercentage;
      assetregistration["Effective From"] = this.datepipe.transform(element.effectivefrom, 'dd/MM/yyyy');
      assetregistration["Effective To"] =this.datepipe.transform(element.effectiveto, 'dd/MM/yyyy');
      assetregistration["Status"] = element.taxstausname;
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
}
