<p-toast></p-toast>
<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Generate Delivery Note</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save and Proceed" icon="las la-save"
                                [disabled]="isDisabledsave" (click)="onSave()"></button>
                            <button pButton pRipple label="" title="Cancel" icon="pi pi-trash" (click)="reset($event)"
                                [disabled]="isDisablereset" class="p-button-danger"></button>
                            <button pButton pRipple type="button" label="" class="p-button p-button-success p-mr-2"
                                title="Back to Search" (click)="goBack($event)" icon="las la-search"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_Deliverynoteform">
                    <table class="normal-table">
                        <!-- <tr>
                        <td >
                        
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr> -->
                        <tr>
                            <td style="width:20%">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_ReturnTypes"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="returntype" (onChange)="ReturnType($event)">
                                    </p-dropdown>

                                    <label>Return Type <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width:20%" *ngIf="_IsNotSalesReturn">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_JobCards"
                                        optionLabel="returntypename" optionValue="returntype"
                                        formControlName="jobcardid"></p-dropdown>
                                    <label>Job Card ID <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width:20%" *ngIf="_IsNotSalesReturn">
                                <div class="p-formgroup-inline">
                                    <h6>Deliver To : </h6>
                                    <div class="p-field-checkbox">
                                        <p-checkbox></p-checkbox>
                                        <label>Customer</label>
                                    </div>
                                    <div class="p-field-checkbox">
                                        <p-checkbox></p-checkbox>
                                        <label>Supplier</label>
                                    </div>
                                </div>
                            </td>
                            <td style="width:20%">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Suppliernames"
                                        optionLabel="suppliername" optionValue="supplierid" [filter]="true"
                                        filterBy="nameandmobile" [showClear]="true" formControlName="supplierid"
                                        (onChange)="OnSelectSupplier($event)">
                                    </p-dropdown>
                                    <label>Supplier Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Deliverynoteform.get('supplierid').touched) && _Deliverynoteform.get('supplierid').errors?.SelectSupplierName">
                                    Please select supplier name
                                </span>
                            </td>
                            <td style="width:20%" *ngIf="_IsNotSalesReturn">
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox formControlName="ismultipledc" label="Is Multiple DC?"></p-checkbox>
                                        <!-- <label>Is Multiple DC?</label> -->
                                    </div>
                                </div>
                            </td>
                            <!-- <td style="width:20%" *ngIf="_IsSalesReturn"></td>
                        <td style="width:20%" *ngIf="_IsSalesReturn"></td>
                        <td style="width:20%" *ngIf="_IsSalesReturn"></td> -->
                        </tr>

                        <tr>

                            <td>
                                <form [formGroup]="_Deliverynoteproductform">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="true" [options]="_ProductTypes"
                                            optionLabel="producttypename" optionValue="producttypeid"
                                            formControlName="producttypeid" (onChange)="getProductcategory($event)"
                                            displaySelectedLabel=true>
                                        </p-multiSelect>
                                        <label for="producttypeid">Product Type </label>
                                    </span>
                                </form>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_ProductCategories"
                                        optionLabel="categoryname" optionValue="productcategoryid"
                                        [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}"
                                        (onChange)="getProductname($event)" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category </label>
                                </span>
                            </td>
                            <td>
                                <form [formGroup]="_Deliverynoteproductform">
                                    <span class="p-float-label">
                                        <p-autoComplete formControlName="productid" [suggestions]="filteredProducts"
                                            (completeMethod)="filterProducts($event)" field="productname"
                                            (onSelect)="OnSelectProducts($event)">
                                            <ng-template let-product pTemplate="item">
                                                <div>{{product.productname}}</div>
                                            </ng-template>
                                        </p-autoComplete>
                                        <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="(_submitted || _Deliverynoteproductform.get('productid').touched) && _Deliverynoteproductform.get('productid').errors?.SelectProductName">
                                        Please select product name
                                    </span>
                                </form>
                            </td>
                            <td>
                                <form [formGroup]="_Deliverynoteproductform">
                                    <span class="p-float-label">
                                        <input type="text" name="amount" formControlName="amount" disabled pInputText>
                                        <label>Amount <span class="hlt-txt">*</span></label>
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="(_submitted || _Deliverynoteproductform.get('amount').touched) && _Deliverynoteproductform.get('amount').errors?.AmountRequired">
                                        Please enter amount
                                    </span>
                                </form>
                            </td>
                            <td>
                                <form [formGroup]="_Deliverynoteproductform">
                                    <span class="p-float-label">
                                        <input type="text" name="quantity" formControlName="quantity" maxlength="7"
                                            (keypress)="keypress.kpNumber($event)" (keyup)="CheckQuantity($event)"
                                            pInputText>
                                        <label>Quantity <span class="hlt-txt">*</span></label>
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="(_submitted || _Deliverynoteproductform.get('quantity').touched) && _Deliverynoteproductform.get('quantity').errors?.ReceivedQuantityRequired">
                                        Please enter quantity
                                    </span>
                                </form>
                            </td>

                        </tr>

                        <tr *ngIf="_IsNotSalesReturn">
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText>
                                    <label>Spare Part Details <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td>
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox></p-checkbox>
                                        <label>Is Damaged?</label>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox></p-checkbox>
                                        <label>Is Return DC?</label>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Status"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="deliverynotestatus"></p-dropdown>
                                    <label>Return DC Status <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td rowspan="2">
                                <form [formGroup]="_Deliverynoteproductform">
                                    <span class="p-float-label">
                                        <textarea inputId="textarea" rows="3" formControlName="remarks" cols="30"
                                            pInputTextarea></textarea>
                                        <label for="textarea">Reason / Remarks</label>
                                    </span>
                                </form>
                            </td>
                        </tr>
                        <tr *ngIf="_IsSalesReturn">
                            <td>
                                <form [formGroup]="_Deliverynoteproductform">
                                    <div class="p-formgroup-inline">
                                        <div class="p-field-checkbox">
                                            <p-checkbox formControlName="isdamaged" label="Is Damaged?" binary="true">
                                            </p-checkbox>

                                        </div>
                                    </div>
                                </form>
                            </td>
                            <td>
                                <form [formGroup]="_Deliverynoteproductform">
                                    <div class="p-formgroup-inline">
                                        <div class="p-field-checkbox">
                                            <p-checkbox formControlName="isreurndc" label="Is Return DC?"></p-checkbox>

                                        </div>
                                    </div>
                                </form>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Status"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        formControlName="deliverynotestatus"></p-dropdown>
                                    <label>Delivery Note Status <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td rowspan="2">
                                <form [formGroup]="_Deliverynoteproductform">
                                    <span class="p-float-label">
                                        <textarea inputId="textarea" rows="3" cols="30" formControlName="remarks"
                                            pInputTextarea></textarea>
                                        <label for="textarea">Reason / Remarks</label>
                                    </span>
                                </form>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5" class="text-center">
                                <button pButton pRipple label="Add" [disabled]="isDisabledremoveproduct"
                                    (click)="AddProducts($event)" title="Add"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="las la-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                        <!-- <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="las la-file-csv" title="Export CSV"
                                class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="las la-filter" title="Filter"
                                class="p-button-info p-ml-auto" pTooltip="Selection Only"
                                tooltipPosition="bottom"></button>
                        </ng-template> -->
                    </p-toolbar>

                    <p-table #dt [value]="ServiceRequestProductDetails" [rows]="20" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['productname','quantity','amount','remarks','isdamaged']"
                        [rowHover]="true" dataKey="productid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:50px;" class="text-center" *ngIf="_IsNotSalesReturn">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th style="text-align: center;width: 100px;" *ngIf="_IsSalesReturn">Action</th>
                                <th pSortableColumn="productname">Product Name <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th *ngIf="_IsNotSalesReturn" pSortableColumn="sparePartName">Spare Part Details
                                    <p-sortIcon field="sparePartName">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="unitprice">Unit Price <p-sortIcon field="unitprice">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="quantity">Quantity <p-sortIcon field="quantity">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="amount">Amount <p-sortIcon field="amount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="remarks">Reason / Remarks <p-sortIcon field="remarks"></p-sortIcon>
                                </th>
                                <th pSortableColumn="isdamaged">Is Damaged? <p-sortIcon field="isdamaged">
                                    </p-sortIcon>
                                </th>
                                <th *ngIf="_IsNotSalesReturn" pSortableColumn="returnDCStatus">Return DC Status
                                    <p-sortIcon field="returnDCStatus">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-ServiceRequestProductDetail>
                            <tr>
                                <td style="text-align:center" *ngIf="_IsSalesReturn">
                                    <button pButton pRipple title="Remove" icon="pi pi-trash"
                                        class="p-button-danger p-mr-2" [disabled]="isDisabledremoveproduct"
                                        (click)="RemoveProduct(ServiceRequestProductDetail)"></button>
                                </td>
                                <td class="text-center" *ngIf="_IsNotSalesReturn">
                                    <p-tableCheckbox [value]="ServiceRequestProductDetail"></p-tableCheckbox>
                                </td>
                                <td>
                                    {{ServiceRequestProductDetail.productname}}
                                </td>
                                <td *ngIf="_IsNotSalesReturn">
                                    {{ServiceRequestProductDetail.sparePartName}}
                                </td>
                                <td>
                                    {{ServiceRequestProductDetail.unitprice}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText name="quantity"
                                                [(ngModel)]="ServiceRequestProductDetail.quantity"
                                                (keyup)="onchangereceivedquantity(ServiceRequestProductDetail,$event)"
                                                *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{ServiceRequestProductDetail.quantity}}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ServiceRequestProductDetail.quantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{ServiceRequestProductDetail.amount}}
                                </td>
                                <td>
                                    {{ServiceRequestProductDetail.remarks}}
                                </td>
                                <td>
                                    {{ServiceRequestProductDetail.isdamaged}}
                                </td>
                                <td *ngIf="_IsNotSalesReturn">
                                    {{ServiceRequestProductDetail.returnDCStatus}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table class="normal-table">
                    <tr>
                        <td colspan="5" class="text-center">
                            <button pButton pRipple label="Send to Approval" [disabled]="_isDisabledsendtoapproval"
                                title="Send to Approval" (click)="SendToApproval($event)"></button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>