<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <!-- <div class="container-fluid"> -->
                <div class="row">
                    <div class="col-md-5">
                        <h3>Customerwise Discount</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" [routerLink]="['/cCustomerwisediscount']" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                            <!-- <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger "></button> -->
                        </ng-template>

                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="exportPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_customerwisediscountviews" [rows]="50" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['customername', 'branchname','tempranddiscount','tempusddiscount','producttypename','productcategoryname','productname', 'tempbonddiscount','tempbankdiscount','effectivefrom','effectiveto','customerwisediscountstatusdesc']"
                        [rowHover]="true" dataKey="employeecode" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <!-- 33. Grid should contain :action,Product Name,Description, From Date,To Date,Party Name,Qty,Discount %. -->
                                <th pSortableColumn="action" style="text-align: center;">Action
                                    <p-sortIcon field="id">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="branchname">Branch Name
                                    <p-sortIcon field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="customername">Customer Name
                                    <p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="producttypename">Product Type<p-sortIcon field="producttypename">
                                </p-sortIcon>
                            </th>
                            <th pSortableColumn="productcategoryname">Product Category<p-sortIcon
                                    field="productcategoryname">
                                </p-sortIcon>
                            </th>
                            <th pSortableColumn="productname">Product Name<p-sortIcon field="productname"></p-sortIcon>
                            </th>
                                <th pSortableColumn="tempusddiscount">USD Discount<p-sortIcon field="tempusddiscount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tempranddiscount">RAND Discount<p-sortIcon
                                        field="tempranddiscount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tempbonddiscount">BOND Discount<p-sortIcon
                                        field="tempbonddiscount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tempbankdiscount">BANK Discount<p-sortIcon
                                        field="tempbankdiscount">
                                    </p-sortIcon>
                                </th> -->

                                <th pSortableColumn="effectivefrom">Effective From
                                    <p-sortIcon field="effectivefrom">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="effectiveto">Effective To
                                    <p-sortIcon field="effectiveto">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="customerwisediscountstatusdesc"> Status
                                    <p-SortIcon field="customerwisediscountstatusdesc"></p-SortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_customerwisediscountview>
                            <tr>
                                <td style="text-align: center;">
                                    <!-- 36.on clicking view button, it show the view screen. -->
                                    <button pButton pRipple icon="las la-eye" title="View" [disabled]="!_Action._View" class="p-button p-button p-mr-2" (click)="view(_customerwisediscountview)"></button>
                                    <!-- 39.on clicking update button, it show the update screen. -->
                                    <!-- <button pButton pRipple icon="las la-pen" title="Edit"
                                        class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update"
                                        [disabled]="_customerwisediscountview.approvalstatus=='MSC00002'"
                                        (click)="edit(_customerwisediscountview)"></button> -->
                                    <button pButton pRipple icon="pi pi-times" title="Delete" class="p-button p-button-danger" [disabled]="_customerwisediscountview.approvalstatus=='MSC00002' || !_Action._Delete" (click)="confirm(_customerwisediscountview)"></button>
                                </td>
                                <td>
                                    {{_customerwisediscountview.branchname}}
                                </td>
                                <td>
                                    {{_customerwisediscountview.customername}}
                                </td>
                                <!-- <td>
                                    {{_customerwisediscountview.producttypename}}
                                </td>
                                <td>
                                    {{_customerwisediscountview.productcategoryname}}
                                </td>
                                <td>
                                    {{_customerwisediscountview.productname}}
                                </td>
                                <td>
                                    {{_customerwisediscountview.tempusddiscount}}

                                </td>
                                <td>
                                    {{_customerwisediscountview.tempranddiscount}}
                                </td>
                                <td>
                                    {{_customerwisediscountview.tempbonddiscount}}

                                </td>
                                <td>
                                    {{_customerwisediscountview.tempbankdiscount}}
                                </td>-->
                                <td>
                                    {{_customerwisediscountview.effectivefrom | date:'dd-MM-yyyy'}}
                                </td>
                                <td>
                                    {{_customerwisediscountview.effectiveto | date:'dd-MM-yyyy'}}
                                </td>
                                <td>
                                    {{_customerwisediscountview.approvalstatusname}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            <!--   <p-dialog [(visible)]="ManageDialog" [style]="{width: '450px'}"  [modal]="true"[maximizable]="true" [style]="{width: '100vw'}">
                      <p-header>{{pageTitle}}</p-header>
                     <ng-template pTemplate="content">
                     </ng-template>
                     <ng-template pTemplate="footer">
                     </ng-template>
            </p-dialog>-->
        </div>
    </div>
</div>