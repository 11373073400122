export class Othercharge {
    otherchargeid: number;
    otherchargename: string;
    otherchargeamount: number;
    otherchargestatus: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date ;
    otherchargestype: string;
}