import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { LaybuyHeader } from '../../Models/LaybuyHeader';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class LaybuyService {
  public _Action: IAction;
  public _LaybuyService = environment.LaybuyService;
  public _httpOptions;
  public _LaybuyList: LaybuyHeader[] = [];
  token: any;
  header: any;

  constructor(
    private http: HttpClient,
    private _CustomExceptionService: CustomExceptionService
  ) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 1)
  }
  createHeaders(token: any, type: number): HttpHeaders {
      return new HttpHeaders({
        Authorization: `Bearer ${token}`, // Include your token here	
      });
  }

  PageOnLoad(userid: number, laubuyid: string, branchid: number): Observable<any> {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._LaybuyService + '/PageInitialize/' + userid + '/' + laubuyid + '/' + branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll(userid: number, p_branchid: number, roleid: number, fromdate: string, todate: string) {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._LaybuyService + '/FetchAll/' + userid + '/' + p_branchid + '/' + roleid + '/' + fromdate + '/' + todate;
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }

  create(p_Laybuyid: FormData) {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._LaybuyService + '/Create';
    return this.http.post<{ data: any }>(Query, p_Laybuyid, { headers });
  }

  Cancel(p_Laybuy: string) {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._LaybuyService + '/LayBuyCancel/' + p_Laybuy;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  LayBuyAmountCancel(p_laybuyid: number, p_customerid: number) {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._LaybuyService + '/LayBuyAmountCancel/' + p_laybuyid + '/' + p_customerid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  OnProductCategoryChange(producttypeid: number, productcategoryid: number) {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._LaybuyService + '/OnProductCategoryChange' + '/' + producttypeid + '/' + productcategoryid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  OnProductNameChange(productid: number, userid: number, levelid: number) {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._LaybuyService + '/OnProductNameChange/' + productid + '/' + userid + '/' + levelid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  OnProductTypeChange(producttypeid: number, branchid: number) {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._LaybuyService + '/OnProductTypeChange/' + producttypeid + '/' + branchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  OnCustomerNameChange(customerid: number, laybuyid: number) {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._LaybuyService + '/OnCustomerNameChange/' + customerid + '/' + laybuyid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  GetProduct(data: object) {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._LaybuyService + '/GetProduct';

    return this.http.post<{ data: any }>(Query, data, { headers });
  }

  Print(obj: any) {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._LaybuyService + '/LayBuyPrint';
    return this.http.post(Query, obj, { responseType: 'blob',  headers  });
  }
}
