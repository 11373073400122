export class Salesorderothercharge {
    salesorderotherchargesdetailid: number;
    salesorderid: number;
    otherchargesid: number;
    otherchargesamount: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    accountingyear: number;

}
