import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { PurchasereturnService } from 'src/app/core/Services/purchase/purchasereturn.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Purchasereturnview } from 'src/app/core/Views/Purchasereturnview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { HotKeyIntegration } from 'src/assets/js/hot-key-integration';
import { DatePipe } from '@angular/common';
@Component({
	selector: 'app-v-purchasereturn',
	templateUrl: './v-purchasereturn.component.html',
	styleUrls: ['./v-purchasereturn.component.css']
})
export class VPurchasereturnComponent implements OnInit {
	_purchasereturndetails: Purchasereturnview[] = [];
	selectedrows: any;
	pSelectableRow: any;
	_Add: Boolean;
	_Delete: Boolean;
	_Update: Boolean;
	_View: Boolean;
	_Action: IAction;
	_userid = sessionStorage["userid"]
	_branchid = sessionStorage["currentbranchid"]
	_roleid = sessionStorage["currentRole"]
	_IsProgressSpinner: boolean = true;
	_purchasereturnid: number;
	_purchasereturnno: string;
	cols: any[];
	_isDisabledsendapparoval: boolean = false;
	fromdate: Date;
	todate: Date;
	datepipe = new DatePipe('en-US');
	mindate: Date;
	maxdate: Date;
	constructor(
		private _purchaseReturnService: PurchasereturnService,
		private _CustomExceptionService: CustomExceptionService,
		private router: Router,
		private utility: CommonUtilities,
		private _AccessRightsService: AccessRightsService,
		private _hotkeysService: HotKeyIntegration<void>,
		private confirmationService: ConfirmationService,
		private exportUtility: ExportUtility
	) { }
	ngOnInit(): void {
		this.utility.pageLoadScript();
		this._Action = this._AccessRightsService.getAccessRights(42);
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this._hotkeysService.Navigate(event => {
			this.GoToCreate(event);
		}, this._Action, 'right');
		if (sessionStorage['Environmentenddate'] == "null") {
			this.fromdate = new Date();
			this.todate = new Date();
			this.maxdate = new Date();
		  }
		  else {
			this.fromdate = new Date(sessionStorage['Environmentenddate']);
			this.todate = new Date(sessionStorage['Environmentenddate']);
		  }
		this.cols = [
			{ field: 'purchasereturndate', header: 'Purchase Return Date' },
			{ field: 'purchasereturnno', header: 'Purchase Return No' },
			{ field: 'suppliername', header: 'Supplier' },
			{ field: 'purchasereturnamount', header: 'Purchase Return Amount'},
			{ field: 'taxamount', header: 'Tax Amount'},
			{ field: 'amountinusd', header: 'Amount In USD'},
			{ field: 'invoiceno', header: 'Invoice No' },
			{ field: 'purchasereturnstatusname', header: 'Status' },
			{ field: 'createdbyname', header: 'Created By' },
			{ field: 'createdon', header: 'Created On' },
		]
		this.Fetchall();
	}
	Fetchall() {
		if (!this.fromdate) {
			this._CustomExceptionService.handleError("Please Select From Date");
			return;
		}
		if (!this.todate) {
			this._CustomExceptionService.handleError("Please Select To Date");
			return;
		}
		let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
		let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
		this._branchid = parseInt(sessionStorage["currentbranchid"]);
		if (fromdate > todate) {
			this._CustomExceptionService.handleError("From Date should be less than the To Date");
			return;
		}
		this._IsProgressSpinner = true;
		this._purchaseReturnService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._purchasereturndetails = resultJSON.purchasereturnviews;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	view(event) {
		this.router.navigate(['/cPurchasereturn'], { state: { purchasereturnid: event.purchasereturnid, purchasereturnno: event.purchasereturnno, action: 'view' } });
	}
	edit(event) {
		this.router.navigate(['/cPurchasereturn'], { state: { purchasereturnid: event.purchasereturnid, purchasereturnno: event.purchasereturnno, action: 'edit' } });
	}
	confirm(event) {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to cancel this Purchase Return <b>"' + event.purchasereturnno + '"</b> ?',
			accept: () => { this.Cancel(event); }
		});
	}
	Cancel(event) {
		let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
		let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
		this._purchasereturnno = event.purchasereturnno;
		this._IsProgressSpinner = true;
		this._purchaseReturnService.Cancel(this._userid, event.purchasereturnno).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._CustomExceptionService.handleSuccess(usererrors.POreturncancel);
			this._IsProgressSpinner = false;
			this._IsProgressSpinner = true;
			this._purchaseReturnService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._purchasereturndetails = resultJSON.purchasereturnviews;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	SendToApproval(event) {
		let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
		let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
		this._IsProgressSpinner = true;
		this._purchaseReturnService.SendToApproval(this._userid, this._branchid, event.purchasereturnno).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
			this._IsProgressSpinner = false;
			this._isDisabledsendapparoval = true;
			this._purchaseReturnService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._purchasereturndetails = resultJSON.purchasereturnviews;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	GoToCreate(event): void {
		this.router.navigate(['/cPurchasereturn']);
	}
	exportExcel() {
		let finalizedList = this.GetFinalizedList();
		this.exportUtility.exportExcel(finalizedList, 'Purchase Return', 'xlsx');
	}
	ExportToPdf() {
		let finalizedList = this.GetFinalizedList();
		this.exportUtility.ExportToPdf(finalizedList, 'Purchase Return.pdf');
	}
	GetFinalizedList() {
		let finalizedList = [];
		this._purchasereturndetails.forEach(element => {
			let quotes: any = {};
			quotes["Purchase Return Date"] = element.purchasereturndate;
			quotes["Purchase Return No"] = element.purchasereturnno;
			quotes["Supplier"] = element.suppliername;
			quotes["Purchase Return Amount"] = element.purchasereturnamount;
			quotes["Tax Amount"] = element.taxamount;
			quotes["Amount In USD"] = element.amountinusd;
			quotes["Invoice No"] = element.invoiceno;
			quotes["Status"] = element.purchasereturnstatusname;
			quotes["Created By"] = element.createdbyname;
			quotes["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
			finalizedList.push(quotes);
		});
		return finalizedList;
	}
	Print(event) {
		this._IsProgressSpinner = true;
		let Data = {
			"PRNo": event.purchasereturnno,
			"BranchId": this._branchid
		}
		this._purchaseReturnService.Print(Data).subscribe((result: any) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			let url: any = URL.createObjectURL(result);
			window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
}