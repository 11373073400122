import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { StockvaluationService } from 'src/app/core/Services/reports/Stock/StockValuationreport/stockvaluation.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
	selector: 'app-stockvaluation',
	templateUrl: './stockvaluation.component.html',
	styleUrls: ['./stockvaluation.component.css']
})
export class StockvaluationComponent implements OnInit {
	_BranchWiseStocks: any[];
	_TempBranchWiseStocks: any[];
	selectedBranchwiseStocks: any;
	_ProductCategoryWiseStocks: any[];
	selectedProductCategoryRows: any;
	displayBasic: boolean;
	ProductWiseStocks: any[];
	_ProductObj: any;
	_ProductCategoryObj: any;
	_IsProgressSpinner: boolean = true;
	IsShowProductCategorywiseStock: boolean = true;
	OverallBranchStock: number;
	OverallCategoryStock: number;
	cols: any[];
	ProductCategoryWiseStockscols: any[];
	ProductWiseStockscols: any[];
	CurrencySymbol: string;
	_userid: number = parseInt(sessionStorage["userid"]);
	_BrandList: any[] = []
	selectedBrandId: any[] = []
	_SupplierList: any[];
	selectedSupplierId: any[] = [];
	constructor(
		private _router: Router,
		private _AccessRightsService: AccessRightsService,
		private utility: CommonUtilities,
		private _StockValuationservice: StockvaluationService,
		private _CustomExceptionService: CustomExceptionService,
		private exportUtility: ExportUtility,
		private confirmationService: ConfirmationService,
		private _hotkeysService: HotkeysService,
	) { }
	ngOnInit(): void {
		this.utility.pageLoadScript();
		this.CurrencySymbol = "$";
		this.cols = [
			{ field: 'branchname', header: 'Branch Name' },
			{ field: 'closingstock', header: 'Closing Stock Value' },
		]
		this.ProductCategoryWiseStockscols = [
			{ field: 'productcategoryname', header: 'Product Category Name' },
			{ field: 'closingstock', header: 'Closing Stock Value' },
		]
		this.ProductWiseStockscols = [
			{ field: 'productname', header: 'Product Name' },
			{ field: 'closingstock', header: 'Closing Stock Value' },
		]
		let TaskName = "BRANCHSTOCKVALUE"
		let Data = {
			"BrandId": null,
			"BranchId": null,
			"ProductCategoryId": null,
			"TaskName": TaskName,
			"Supplier": []
		}
		this._IsProgressSpinner = true;
		this._StockValuationservice.StockValuationReport(Data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._SupplierList = resultJSON.suppliers;
			this._BranchWiseStocks = resultJSON.branchwisestocklst;
			this._TempBranchWiseStocks = resultJSON.branchwisestocklst;
			this.OverallBranchStock = resultJSON.overallstock.STOCKVALUEINUSD;
			if (resultJSON.brandList) {
				this._BrandList = resultJSON.brandList;
			}
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	getDetail() {
		this._IsProgressSpinner = true;

		if (this.selectedBrandId.length > 0 || this.selectedSupplierId.length > 0) {
			let TaskName = "BRANDSTOCKVALUE"
			let Data = {
				"BrandId": this.selectedBrandId || [],
				"BranchId": null,
				"ProductCategoryId": null,
				"TaskName": TaskName,
				"Supplier": this.selectedSupplierId || []
			}
			this._IsProgressSpinner = true;
			this._StockValuationservice.StockValuationReport(Data).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				if (resultJSON.branchwisestocklst.length > 0) {
					this._BranchWiseStocks = resultJSON.branchwisestocklst;
					this.OverallBranchStock = resultJSON.overallstock.STOCKVALUEINUSD;
				} else {
					this._IsProgressSpinner = false;
					this._CustomExceptionService.handleError("No Data Available for This Brand/Supplier. Try Another")
					return
				}
				if (resultJSON.brandList) {
					this._BrandList = resultJSON.brandList;
				}
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		} else {
			this._BranchWiseStocks = this._TempBranchWiseStocks
		}
		this._IsProgressSpinner = false;

	}
	onRowSelectBranch(event: any) {
		var row = event.data;
		let TaskName = "BRANCHCATEGORYSTOCKVALUE"
		let Data = {
			"BrandId": this.selectedBrandId || [],
			"BranchId": row.BRANCHID,
			"ProductCategoryId": null,
			"TaskName": TaskName,
			"Supplier": this.selectedSupplierId || []
		}
		this._IsProgressSpinner = true;
		this._StockValuationservice.StockValuationReport(Data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._ProductCategoryWiseStocks = resultJSON.branchwisestocklst;
			this.OverallCategoryStock = resultJSON.overallstock.STOCKVALUEINUSD;
			this.OverallCategoryStock = parseFloat(this.OverallCategoryStock.toFixed(3));
			this.IsShowProductCategorywiseStock = false;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	onRowSelectCategory(event: any) {
		var Categoryrow = event.data;
		let TaskName = "BRANCHCATEGORYPRODUCTSTOCKVALUE"
		let Data = {
			"BrandId": this.selectedBrandId || [],
			"BranchId": Categoryrow.BRANCHID,
			"ProductCategoryId": Categoryrow.PRODUCTCATEGORYID,
			"TaskName": TaskName,
			"Supplier": this.selectedSupplierId || []
		}
		this._IsProgressSpinner = true;
		this._StockValuationservice.StockValuationReport(Data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this.ProductWiseStocks = resultJSON.branchwisestocklst
			this.ProductWiseStocks = [...this.ProductWiseStocks]
			this.displayBasic = true;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	exportExcelProductCategoryWise() {
		let ProductCategoryWiseStockList = this.GetProductCategoryWiseStockList();
		this.exportUtility.exportExcel(ProductCategoryWiseStockList, 'ProductCategoryWiseStock', 'xlsx');
	}
	ExportToPdfProductCategoryWise() {
		let finalizedList = this.GetProductCategoryWiseStockList();
		const data = finalizedList.map(Object.values);
		const head = [[
			'Branch',
			'Product Category',
			'Closing Stock Value'
		]]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					},
					headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					},
					bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('ProductCategoryWiseStock.pdf');
			})
		})
	}
	GetProductCategoryWiseStockList() {
		if (!this._ProductCategoryWiseStocks || !this._ProductCategoryWiseStocks.length)
			return;
		let ProductCategoryWiseStocklist = [];
		this._ProductCategoryWiseStocks.forEach(element => {
			let ProductCategoryWiseStock: any = {};
			ProductCategoryWiseStock["Branch"] = element.BRANCHNAME;
			ProductCategoryWiseStock["Product Category"] = element.CATEGORYNAME;
			ProductCategoryWiseStock["Closing Stock Value"] = element.STOCKVALUEINUSD;
			ProductCategoryWiseStocklist.push(ProductCategoryWiseStock);
		});
		return ProductCategoryWiseStocklist;
	}
	getSupplierDetail(event) {
		var selectedbrand = this.selectedBrandId
		this._IsProgressSpinner = true;
		let data = {
			"selectedbrand": selectedbrand
		}
		this._StockValuationservice.BrandSuppliersearch(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._SupplierList = resultJSON.brandbasedsupplierlist;
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
	}
	reset() {
		this.selectedBrandId = [];
		this._SupplierList = [];
		this.selectedSupplierId = [];
		this.IsShowProductCategorywiseStock = true;
		this.ngOnInit();
	}
	exportExcel() {
		let BranchWiseStockList = this.GetBranchWiseStockList();
		this.exportUtility.exportExcel(BranchWiseStockList, 'BranchWiseStock', 'xlsx');
	}
	ExportToPdf() {
		let finalizedList = this.GetBranchWiseStockList();
		const data = finalizedList.map(Object.values);
		const head = [[
			'Branch Name',
			'Closing Stock Value'
		]]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					},
					headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					},
					bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('BranchWiseStock.pdf');
			})
		})
	}
	GetBranchWiseStockList() {
		if (!this._BranchWiseStocks || !this._BranchWiseStocks.length)
			return;
		let Branchwisestocklist = [];
		this._BranchWiseStocks.forEach(element => {
			let BranchWiseStock: any = {};
			BranchWiseStock["Branch"] = element.BRANCHNAME;
			BranchWiseStock["Closing Stock Value"] = element.STOCKVALUEINUSD;
			Branchwisestocklist.push(BranchWiseStock);
		});
		return Branchwisestocklist;
	}
	exportExcelProductWiseStock() {
		let ProductWiseStockList = this.GetProductWiseStockList();
		this.exportUtility.exportExcel(ProductWiseStockList, 'ProductWiseStock', 'xlsx');
	}
	ExportToPdfProductWiseStock() {
		let finalizedList = this.GetProductWiseStockList();
		const data = finalizedList.map(Object.values);
		const head = [[
			'Branch',
			'Product Category',
			'Product',
			'Closing Stock Value'
		]]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					},
					headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					},
					bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('ProductWiseStock.pdf');
			})
		})
	}
	GetProductWiseStockList() {
		if (!this.ProductWiseStocks || !this.ProductWiseStocks.length)
			return;
		let ProductWiseStockList = [];
		this.ProductWiseStocks.forEach(element => {
			let ProductWiseStock: any = {};
			ProductWiseStock["Branch"] = element.BRANCHNAME;
			ProductWiseStock["Product Category"] = element.CATEGORYNAME;
			ProductWiseStock["Product Name"] = element.PRODUCTNAME;
			ProductWiseStock["Closing Stock Value"] = element.STOCKVALUEINUSD;
			ProductWiseStockList.push(ProductWiseStock);
		});
		return ProductWiseStockList;
	}
}
