import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Customer } from 'src/app/core/Models/Customer';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Supplier } from 'src/app/core/Models/Supplier';
import { RptoutstandingService } from 'src/app/core/Services/reports/Accounting/RptOutstanding/rptoutstanding.service';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Currency } from 'src/app/core/Models/Currency';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-rptoutstanding',
  templateUrl: './rptoutstanding.component.html',
  styleUrls: ['./rptoutstanding.component.css']
})
export class RptoutstandingComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptoutstandingform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _currencies: Currency[];
  _userid: number;
  datepipe: any;
  _typelist = [{ type: 'Customer', value: '11' }, { type: 'Supplier', value: '15' }, { type: 'Employee', value: '30' },];
  _accountnamelist: Accountledger[];
  _customer_supplierlist: Accountledgerview[] = [];
  _src: SafeResourceUrl;
  _fromMaxDate = new Date();
  _Action: IAction;
  today = new Date();
  mindate: Date;
  maxdate: Date;

  constructor(private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private fb: FormBuilder, private _RptoutstandingService: RptoutstandingService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }


  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(108);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptoutstandingform = this.fb.group({
      fromdate: [''],
      todate: [''],
      partytype: [0],
      customer_supplierid: [0],
      branchid: [0],
      currencyid: [0]
    });
    this._rptoutstandingform.get("currencyid").setValue(1);
    this._branchlist = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptoutstandingform.controls['fromdate'].setValue(new Date(sessionStorage['Environmentstartdate']));
      this._rptoutstandingform.controls['todate'].setValue(new Date());
      this.maxdate = new Date();
    }
    else {
      this._rptoutstandingform.controls['fromdate'].setValue(new Date(sessionStorage['Environmentstartdate']));
      this._rptoutstandingform.controls['todate'].setValue(new Date(sessionStorage['Environmentenddate']));
    }

    this._RptoutstandingService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // this._partytypelist = resultJSON.partytypeList;
      this._branchlist = resultJSON.branchList;
      this._currencies = resultJSON.currencies;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  clear(event) {
    this._rptoutstandingform.reset();
    var dt = new Date();
    dt.setDate(dt.getDate() - 7);
    this._rptoutstandingform.controls['fromdate'].setValue(this.today);
    this._rptoutstandingform.controls['todate'].setValue(new Date());
    this._rptoutstandingform.get("currencyid").setValue(1);
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }

  GenerateReport(event) {
    var _fromdate = this._rptoutstandingform.get("fromdate").value;
    var _todate = this._rptoutstandingform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    var customerorsupplier = this._rptoutstandingform.get("customer_supplierid").value == null ? 0 : this._rptoutstandingform.get("customer_supplierid").value;
    var partytype = this._rptoutstandingform.get("partytype").value == null ? 0 : this._rptoutstandingform.get("partytype").value;
    if (partytype == null || partytype == 0) {
      this._CustomExceptionService.handleError("Please Select Party Type");
      return;
    }
    if (customerorsupplier == null || customerorsupplier == 0) {
      this._CustomExceptionService.handleError("Please Select Party Name");
      return;
    }
    let currencyid = this._rptoutstandingform.get("currencyid").value;
    var branchid = sessionStorage["currentbranchid"]; //this._rptoutstandingform.get("branchid").value == null ? 0 : this._rptoutstandingform.get("branchid").value;
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._RptoutstandingService.Print(fromdate, todate, partytype, customerorsupplier, branchid, this._userid, currencyid).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });


  }

  onchangepartytype(event) {
    var partytype = event.value;
    this._userid = parseInt(sessionStorage["userid"]);
    this._RptoutstandingService.onchangepartytype(partytype, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accountnamelist = resultJSON.accountnameList;
    });
  }

}
