import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Customer } from 'src/app/core/Models/Customer';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Supplier } from 'src/app/core/Models/Supplier';
import { RptReconcilationUnreconcilationService } from 'src/app/core/Services/reports/Accounting/RptReconcilationUnreconcilation/rpt-reconcilation-unreconcilation.service';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { Currencyview } from 'src/app/core/Views/Currencyview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-rptreconcilationunreconcilation',
  templateUrl: './rptreconcilationunreconcilation.component.html',
  styleUrls: ['./rptreconcilationunreconcilation.component.css']
})
export class RptreconcilationunreconcilationComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptreconcilationunconcilationform: FormGroup;
  _branchlist = sessionStorage["BranchID"];;
  _userid: number;
  datepipe: any;
  //_typelist : Metadatum[] = [];
  _typelist = [{ type: 'Bank', value: '6' }];
  _conciletypelist = [{ type: 'All', value: 2 }, { type: 'Reconciled', value: 1 }, { type: 'UnReconciled', value: 0 }];
  _Paymenttypelist = [{ type: 'Credit', value: 1 }, { type: 'Debit', value: 2 }];
  _customer_supplierlist: Accountledgerview[] = [];
  _accountnamelist: Accountledger[];
  _currencylist: Currencyview[];
  _src: SafeResourceUrl;
  _Action: IAction;
  mindate: Date;
  maxdate: Date;
  // _typelist = [{ type: 'Yes', value: '1' }, { type: 'No', value: '0' }];
  constructor(private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private fb: FormBuilder, private _RptReconcilationService: RptReconcilationUnreconcilationService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(112);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptreconcilationunconcilationform = this.fb.group({
      fromdate: [],
      todate: [],
      branchid: [0],
      currencyid: [0],
      customer_supplierid: [0],
      type: [0],
      partytype: [0],
      Paymenttype: [0],
      conciletype: [2]
    })

    this._userid = parseInt(sessionStorage["userid"]);
    // this.InitializeForm();

    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptreconcilationunconcilationform.get("fromdate").setValue(new Date);
      this._rptreconcilationunconcilationform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptreconcilationunconcilationform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptreconcilationunconcilationform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

    var dt = this._rptreconcilationunconcilationform.get("todate").value;
    dt.setDate(dt.getDate() - 7);
    this._rptreconcilationunconcilationform.controls['fromdate'].setValue(dt);
    // this._rptreconcilationunconcilationform.controls['todate'].setValue(new Date());
    this._rptreconcilationunconcilationform.controls['currencyid'].setValue(4);
    this._rptreconcilationunconcilationform.controls['conciletype'].setValue(2);
    this._rptreconcilationunconcilationform.controls['branchid'].setValue(parseInt(sessionStorage["currentbranchid"]));
    this._RptReconcilationService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      //this._typelist = resultJSON.partytypeList;
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._rptreconcilationunconcilationform.controls['branchid'].setValue(parseInt(sessionStorage["currentbranchid"]));
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  clear(event) {
    this._rptreconcilationunconcilationform.reset();
    var dt = new Date();
    dt.setDate(dt.getDate() - 7);
    this._rptreconcilationunconcilationform.controls['fromdate'].setValue(dt);
    this._rptreconcilationunconcilationform.controls['todate'].setValue(new Date());
    this._rptreconcilationunconcilationform.controls['currencyid'].setValue(4);
    this._rptreconcilationunconcilationform.controls['conciletype'].setValue(2);
    this._rptreconcilationunconcilationform.controls['branchid'].setValue(parseInt(sessionStorage["currentbranchid"]));
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  onchangeBranch() {
    this._rptreconcilationunconcilationform.get("partytype").reset();
  }
  GenerateReport(event) {

    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    var _fromdate = this._rptreconcilationunconcilationform.get("fromdate").value;
    var _todate = this._rptreconcilationunconcilationform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var Paymenttype = this._rptreconcilationunconcilationform.get("Paymenttype").value || 0;
    var conciletype = this._rptreconcilationunconcilationform.get("conciletype").value;
    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    var customerorsupplier = this._rptreconcilationunconcilationform.get("customer_supplierid").value == null ? 0 : this._rptreconcilationunconcilationform.get("customer_supplierid").value;
    var currencyid = this._rptreconcilationunconcilationform.get("currencyid").value == null ? 0 : this._rptreconcilationunconcilationform.get("currencyid").value;
    var branchid = this._rptreconcilationunconcilationform.get("branchid").value == null ? 0 : this._rptreconcilationunconcilationform.get("branchid").value;
    // var type = this._rptreconcilationunconcilationform.get("type").value == null || this._rptreconcilationunconcilationform.get("type").value == 'Both' ? 0 : this._rptreconcilationunconcilationform.get("type").value;
    var partytype = this._rptreconcilationunconcilationform.get("partytype").value == null ? 0 : this._rptreconcilationunconcilationform.get("partytype").value;
    if (partytype == null || partytype == 0) {
      this._CustomExceptionService.handleError("Please Select Party Type");
      return;
    }
    if (customerorsupplier == null || customerorsupplier == 0) {
      this._CustomExceptionService.handleError("Please Select Party Name");
      return;
    }
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._RptReconcilationService.Print(fromdate, todate, customerorsupplier, branchid, currencyid, this._userid, sessionStorage["currentbranchid"], conciletype, Paymenttype).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (result.type != mediaType) {
        let reader = new FileReader();
        var blob = new Blob([result], { type: 'application/json' });
        let message = reader.readAsText(blob);
        return;
      }
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    },
      //error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
      async (error) => {
        const message = await error.error.text();
        this._IsProgressSpinner = false;
        this._CustomExceptionService.handleError(message);
      });

  }
  onchangepartytype(event) {
    let branchid = this._rptreconcilationunconcilationform.get("branchid").value;
    var partytype = event.value;
    this._IsProgressSpinner = true;
    this._RptReconcilationService.onchangepartytype(partytype, sessionStorage["userid"], branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._accountnamelist = resultJSON.accountnameList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
