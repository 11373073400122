import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { TripsheetService } from 'src/app/core/Services/inventory/tripsheet.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';



@Component({
  selector: 'app-c-tripsheet',
  templateUrl: './c-tripsheet.component.html',
  styleUrls: ['./c-tripsheet.component.css']
})
export class CTripsheetComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _tripsheetform: FormGroup;
  _tripsheetObj: any;
  _branchid: number;
  _userid: number;
  _vehiclelist: any[] = [];
  _driverlist: any[] = [];
  _src: SafeResourceUrl;
  _Action:IAction;


  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _TripsheetService: TripsheetService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, 
    private _AccessRightsService : AccessRightsService,
    private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(319);
    this.utility.pageLoadScript();
    this._tripsheetform = this.fb.group({
      vehicleid: [0],
      driverid: [0]
    })
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = parseInt(sessionStorage["userid"]);
    
    this._TripsheetService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._vehiclelist = resultJSON.vehicles;
      this._driverlist = resultJSON.vehicles;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GenerateReport(event) {
  
    var vehicleid = this._tripsheetform.get("vehicleid").value || 0;
    var driverid = this._tripsheetform.get("driverid").value || 0 ;
 
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._tripsheetObj = { "vehicleid": vehicleid, "branchid": this._branchid, "driverid": driverid }
    this._TripsheetService.Print(this._tripsheetObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }

  clear(event) {
    this._tripsheetform.reset();
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }

  ChangeVehicle(event): void {
    let driver;

    driver = (this._vehiclelist || []).find(f => f.vehicleid == event.value);
    if (driver != null && driver != undefined) {
      if (driver.driverid != 0) {
        this._tripsheetform.controls['driverid'].setValue(driver.driverid);
      }
      else {
        this._tripsheetform.controls['driverid'].setValue(0);
      }
    }
    else {
      this._tripsheetform.controls['driverid'].setValue(0);
    }
  }

  ChangeDriver(event): void {
    let vehicle;

    vehicle = (this._driverlist || []).find(f => f.driverid == event.value);
    if (vehicle != null && vehicle != undefined) {
      if (vehicle.vehicleid != 0) {
        this._tripsheetform.controls['vehicleid'].setValue(vehicle.vehicleid);
      }
      else {
        this._tripsheetform.controls['vehicleid'].setValue(0);
      }
    }
    else {
      this._tripsheetform.controls['vehicleid'].setValue(0);
    }
  }
}
