// export class Salescommissionview {
//     Salescommissionid: number;
//     Salescommissiondetailid: number;
//     Salestype: string;
//     Slab: string;
//     Minrange: number ;
//     Maxrange: number;
//     Commission: number ;
//     Salestypename: string;
//     Companyid: number;
//     Branchid: number;
//     Basetarget: number;
//     Basecommision: number;
//     Effectivefrom: Date;
//     Effectiveto: Date;
//     Salescommissionstatus: string;
//     Salescommissionstatusname: string;
//     Status: string;
//     Statusremarks: string;
//     Accountingyear: number;
//     Createdby: number;
//     Createdon: Date ;
//     Modifiedby: number;
//     Modifiedon: Date;
// }




export class Salescommissiondetail {
    salescommissiondetailid: number;
    salescommissionid: number;
    branchid: number;
    salestype: string;
    Slab: string;
    minrange: number;
    maxrange: number;
    commission: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    tempslab: string;
    tempminrange: number;
    tempmaxrange: number;
    tempcommission: number;
    salescommissionstatus: string;
    status: string;
    statusremarks: string;
    effectivefrom: Date;
    effectiveto: Date;
    basetarget: number;
    basecommision: number;
    isdisabled:boolean;
}