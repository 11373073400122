import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { PurchaseorderService } from 'src/app/core/Services/Purchase/purchaseorder.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Purchaseorderview } from 'src/app/core/Views/Purchaseorderview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-v-purchaseorder',
  templateUrl: './v-purchaseorder.component.html',
  styleUrls: ['./v-purchaseorder.component.css']
})
export class VPurchaseorderComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Purchaseorderviews: Purchaseorderview[];
  _Purchaseorderview: Purchaseorderview;
  _Action: IAction
  _branchid: number;
  _userid: number;
  _roleid: number;
  _purchaseorderid: number;
  _IsProgressSpinner: boolean = true;
  cols: any[];
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  mindate: Date;
  maxdate: Date;
  _Branch: any[] = [];
  _Branchdrop: Boolean = true;
  _Branchname: number;
  constructor(private fb: FormBuilder, private _PurchaseorderService: PurchaseorderService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService, private confirmationService: ConfirmationService, private exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(40);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = parseInt(sessionStorage["userid"]);
    this._roleid = parseInt(sessionStorage["currentRole"]);
    this._Branchname  = this._branchid;
    this.utility.pageLoadScript();
    this.HotKeyIntegration();

    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.cols = [
      { field: 'purchaseorderno', header: 'Purchase order Code' },
      { field: 'purchaseorderdate', header: 'Purchase order Date' },
      { field: 'suppliername', header: 'Supplier Name' },
      { field: 'totalpoamount', header: 'Total Amount' },
      { field: 'statusame', header: ' Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },

    ]
    if(this._roleid == 1) 
    {
      this._Branchdrop = false;
        
    }
    else{
      this._Branchdrop = true;
    }
    this.Fetchall();

  }
  view(event) {
    this.router.navigate(['/cPurchaseOrder'], { state: { purchaseorderno: event.purchaseorderno, purchaseorderid: event.purchaseorderid, action: 'view' } });
  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    if (this._roleid != 1){
      this._branchid = parseInt(sessionStorage["currentbranchid"]);
    }
    else
    {
      this._branchid = this._Branchname;
    }
    this._IsProgressSpinner = true;
    this._PurchaseorderService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Purchaseorderviews = resultJSON.purchaseorderview;
      this._Branch = resultJSON.billingBranchList;
      //89. based on the Purchase Order no ,PO Date, supplier name  you need to filter the data and show in the grid
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  edit(event) {
    //123.the Purchased Order Status Changes To "Cancel", so this record does not allow to update or approve
    //if (event.status == "MSC00046" || event.status == null ) {
    this.router.navigate(['/cPurchaseOrder'], { state: { purchaseorderno: event.purchaseorderno, purchaseorderid: event.purchaseorderid, action: 'edit' } });
    //}
    // else {
    //   this._CustomExceptionService.handleWarning("Approved Record Should not Update");
    // }
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Purchase Order  <b>"' + event.purchaseorderno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');

    this._purchaseorderid = event.purchaseorderid;
    this._IsProgressSpinner = true;
    //117.check in the user rights screen, for the login user if the Purchase Order has access toCancel,then allow to open Cancel  page  
    this._PurchaseorderService.Cancel(this._purchaseorderid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this.Fetchall();
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  GoToCreate(event) {
    this.router.navigate(['/cPurchaseOrder']);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  //vinitha
  // Print(event) {
  //   var purchaseorderno = event.purchaseorderno;
  //   this._IsProgressSpinner = true;
  //   var tempprint = 0;
  //   //var mediaType = 'application/pdf';
  //   this._PurchaseorderService.Print(purchaseorderno, this._branchid,tempprint);
  //   this._IsProgressSpinner = false;
  // }
  Print(event) {
    var purchaseorderno = event.purchaseorderno;
    this._IsProgressSpinner = true;
    var tempprint = 0;
    //var mediaType = 'application/pdf';
    this._PurchaseorderService.Print({"pono":purchaseorderno, "branchid":this._branchid,"type":tempprint}).subscribe((result:any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      let url:any=URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false; 
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  TempPrint(event) {
    var purchaseorderno = event.purchaseorderno;
    this._IsProgressSpinner = true;
    var tempprint = 1;
    //var mediaType = 'application/pdf';
    // this._PurchaseorderService.Print(purchaseorderno, this._branchid,tempprint);
    this._PurchaseorderService.Print({"pono":purchaseorderno, "branchid":this._branchid,"type":tempprint}).subscribe((result:any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      let url:any=URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false; 
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  confirmcancel(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to close this Half inwarded Purchase Order <b>"' + event.purchaseorderno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.CancelOrder(event);
      }
    });
  }
  CancelOrder(event) {
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');

    var _purchaseorderid = event.purchaseorderno;
    this._IsProgressSpinner = true;
    //117.check in the user rights screen, for the login user if the Purchase Order has access toCancel,then allow to open Cancel  page  
    this._PurchaseorderService.CancelInwardedorder(_purchaseorderid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      this._IsProgressSpinner = true;
      this._PurchaseorderService.FetchAll(this._branchid, this._userid, this._roleid, fromdate, todate).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Purchaseorderviews = resultJSON.purchaseorderview;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }


  exportExcel() {
    let purchaseorderList = this.GetPurchaseorderList();
    this.exportUtility.exportExcel(purchaseorderList, 'PurchaseOrder', 'xlsx');
  }

  ExportToPdf() {
    let purchaseorderList = this.GetPurchaseorderList();
    this.exportUtility.ExportToPdf(purchaseorderList, 'PurchaseOrder.pdf');
  }

  GetPurchaseorderList() {
    let purchaseorderList = [];
    this._Purchaseorderviews.forEach(element => {
      let purchaseorder: any = {};
      purchaseorder["Purchase order Code"] = element.purchaseorderno;
      purchaseorder["Purchase order Date"] = element.purchaseorderdate;
      purchaseorder["Supplier Name"] = element.suppliername;
      purchaseorder["Total Amount"] = element.totalpoamount;
      purchaseorder["Status"] = element.statusame;
      purchaseorder["Created By"] = element.createdbyname;
      purchaseorder["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      purchaseorderList.push(purchaseorder);
    });
    return purchaseorderList;
  }

  SendMail(event) {
    // var workflowid = 5;
    var purchaseorderno = event.purchaseorderno;
    this._userid = sessionStorage["userid"];
    this._branchid = sessionStorage["BranchID"];
    //76.after clicking the send to approve button, the purchase order is given to 4 levels of approval 
    //this._productid= this._damagedproductpricedetailsform.get("productid").setValue(this._damagedproductpricedetailsform.get("productid").value);
    this._IsProgressSpinner = true;

    this._PurchaseorderService.SendMail(this._userid, this._branchid, purchaseorderno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && resultJSON) {
        this._CustomExceptionService.handleSuccess(usererrors.MailSent_Success);
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);

      }
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
