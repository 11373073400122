import { AbstractControl } from '@angular/forms';

let regexWithAlphabetspecialcharacter = new RegExp('^[ A-Za-z0-9]*$');


//Supplier Name Validation:
export function vInputNamewithoutspecialcharater(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value || !control.value.length) {
        return { 'NameEmpty': true };
    }
    if (!regexWithAlphabetspecialcharacter.test(control.value)) {
        return { 'vInputNamewithoutspecialcharater': true };
    }
    return null;
}


// Vehicle Master
export function vVehicleNumber(control: AbstractControl): { [key: string]: any } | null {
    // let mn=/^((\\+91-?)|0)?[0-9]{15}$/;
    let vehicleno_regex = new RegExp("^[A-Z]{3}[0-9]{4}$");
    if (!control.value) {
        return { 'VehicleNumberRequired': true };
    }
    if (control.value && !vehicleno_regex.test(control.value)) {
        return { 'VehicleNumberInvalid': true };
    }

    return null;
}
export function vEngineNumber(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'EngineNumberRequired': true };
    }
    return null;
}
export function vEngineNumberSelect(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'SelectEngineNumber': true };
    }
    return null;
}
export function vVehicleTypeSelect(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'SelectVehicleType': true };
    }
    return null;
}
export function vVehicleNumberSelect(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'SelectVehicleNumber': true };
    }
    return null;
}
export function vDocumentName(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'DocumentNameRequired': true };
    }
    return null;
}
export function vEffectiveFrom(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'EffectiveFromRequired': true };
    }
    return null;
}
export function vEffectiveTo(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'EffectiveToRequired': true };
    }
    return null;
}
export function vCapacity(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'CapacityRequired': true };
    }
    return null;
}
export function vCapacitySelect(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'SelectCapacity': true };
    }
    return null;
}
// Product Category
export function vProductTypeSelect(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'SelectProductType': true };
    }
    return null;
}
export function vCategoryCode(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'categoryCodeRequired': true };
    }
    return null;
}
export function vCategoryName(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'CategoryNameRequired': true };
    }
    return null;
}
export function vCategoryNameSelect(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'SelectCategoryName': true };
    }
    return null;
}
export function vBranchSelect(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'SelectBranch': true };
    }
    return null;
}
//Level Master Validation

export function vLevelNumber(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'LevelNumberRequired': true };
    }
    return null;
}

export function vLevelName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'LevelNameRequired': true };
    }
    return null;
}
export function vLevelNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectLevelName': true };
    }
    return null;
}
export function vLevelStatusSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectLevelStatus': true };
    }
    return null;
}


//Terms And Condition Validation

export function vPartyTypeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPartType': true };
    }
    return null;
}
export function vBuisnessTypeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectBuisnessType': true };
    }
    return null;
}
export function vTermsTypeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectTermsType': true };
    }
    return null;
}
export function vDetails(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'DetailsRequired': true };
    }
    return null;
}

// Tax Master Validation

export function vTaxPercentage(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'TaxPercentageRequired': true };
    }
    return null;
}
export function vTaxName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'TaxNameRequired': true };
    }
    return null;
}
export function vTaxNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectTaxName': true };
    }
    return null;
}
export function vTaxCode(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'TaxCodeRequired': true };
    }
    return null;
}
export function vTaxCodeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectTaxCode': true };
    }
    return null;
}

//Numbering Schema Validation

export function vDocumentTypeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDocumentType': true };
    }
    return null;
}
export function vSuffix(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'SuffixRequired': true };
    }

    if (!control.value.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) {
        return { 'invalidSuffix': true };
    }
    return null;
}
export function vPrefix(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'prefixRequired': true };
    }

    if (!control.value.toString().match(/^[A-Za-z0-9]+(\.?[A-Za-z0-9]+)?$/)) {
        return { 'prefixRequired': true };
    }
    return null;
}


//Branch Master Validation

export function vBranchCode(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'BranchCodeRequired': true };
    }
    return null;
}

export function vBranchCodeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectBranchCode': true };
    }
    return null;
}
export function vBranchName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'BranchNameRequired': true };
    }
    return null;
}
export function vBankBranch(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'BankBranchRequired': true };
    }
    return null;
}
export function vBranchNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value.length) {
        return { 'SelectBranchName': true };
    }
    return null;
}

export function vBranchType(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'BranchTypeRequired': true };
    }
    return null;
}
export function vBranchTypeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectBranchType': true };
    }
    return null;
}

export function vBranchLevel(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'BranchLevelRequired': true };
    }
    return null;
}

export function vBranchLevelSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectBranchLevel': true };
    }
    return null;
}
export function vAccountNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AccountNoRequired': true };
    }
    return null;
}
export function vSwiftCode(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SwiftCodeRequired': true };
    }
    return null;
}
//validation for product master
export function vProductCategorySelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectProductCategory': true };
    }
    return null;
}
export function vProductCode(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ProductCodeRequired': true };
    }
    return null;

}

export function vProductName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ProductNameRequired': true };
    }
    return null;
}

export function vPurchaseUOMSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPurchaseUOM': true };
    }
    return null;
}


export function vSalesUOMSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSalesUOM': true };
    }
    return null;
}

export function vPurchaseFromSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPurchaseFrom': true };
    }
    return null;
}
export function vSupplierNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSupplierName': true };
    }
    return null;
}
export function vSupplierSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value.length) {
        return { 'SelectSupplierName': true };
    }
    return null;
}

export function vPurchaseRate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'PurchaseRateRequired': true };
    }
    return null;
}
export function vDiscountPercentage(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'DiscountPercentageRequired': true };
    }
    return null;
}

export function vDiscountApplicableSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDiscountApplicable': true };
    }
    return null;
}
export function vWarrantyApplicableSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectWarrantyApplicable': true };
    }
    return null;
}

export function vWarranty(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'WarrantyRequired': true };
    }
    return null;
}

export function vWarrantyUOMSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectWarrantyUOM': true };
    }
    return null;
}

export function vVatApplicableSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectVatApplicable': true };
    }
    return null;
}

export function vPackageApplicableSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPackageApplicable': true };
    }
    return null;
}

export function vPackage(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'PackageRequired': true };
    }
    return null;
}

export function vMinimumLevel(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'MinimumLevelRequired': true };
    }
    return null;
}

export function vMaximumLevel(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'MaximumLevelRequired': true };
    }
    return null;
}

export function vOpeningStock(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'OpeningStockRequired': true };
    }
    return null;
}

export function vWeight(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'WeightRequired': true };
    }
    return null;
}

export function vWeightUOMSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectWeightUOM': true };
    }
    return null;
}

export function vVariantDescription(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'VariantDescriptionRequired': true };
    }
    return null;
}


export function vReorderLevel(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ReorderLevelRequired': true };
    }
    return null;
}

//Common Fields
export function vStatusSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectStatus': true };
    }
    return null;
}

export function vImageUpload(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ImageUploadRequired': true };
    }
    return null;
}

// export function vEmailID(control: AbstractControl): { [key: string]: any } | null {

//     if (!control.value) {
//         return { 'EmailIDRequired': true };
//     }
//     if (!control.value.toString().match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")) {
//         return { 'ValidEmailID': true };
//     }
//     return null;

// }
export function vEmailID(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
        return { 'EmailIDRequired': true };
    }
    if (!control.value.toString().match("^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,6}(?:\\.[A-Za-z]{2,6})?)$")) {
        return { 'ValidEmailID': true };
    }
    return null;

}

export function vAddress(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AddressRequired': true };
    }
    return null;
}

export function vSeriesSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSeries': true };
    }
    return null;
}

export function vVatNumber(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'VatNumberRequired': true };
    }
    return null;
}

export function vFaxNumber(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'FaxNumberRequired': true };
    }
    return null;
}

export function vBPNumber(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'BPNumberRequired': true };
    }
    return null;
}



export function vDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDate': true };
    }
    return null;
}
export function vDate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'DateRequired': true };
    }
    return null;
}
export function vMobileNumber(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'MobileNumberRequired': true };
    }
    if (control.value && (control.value).toString().length > 20) {
        return { 'MobileNumberInvalid': true };
    }
    return null;
}

export function vTelePhoneNumber(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'TelePhoneNumberRequired': true };
    }
    if (control.value && control.value.length != 15) {
        return { 'TelePhoneNumberInvalid': true };
    }

    return null;

}

export function vDiscount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'DiscountRequired': true };
    }
    if (control.value > 100) {
        return { 'DiscountInvalid': true };
    }

    return null;

}

export function vQuantity(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'QuantityRequired': true };
    }
    if (control.value < 1) {
        return { 'QuantityInvalid': true };
    }

    return null;

}
export function vDescription(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'DescriptionRequired': true };
    }
    return null;
}
export function vUOM(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'UOMRequired': true };
    }
    return null;
}

export function vUOMSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectUOM': true };
    }
    return null;
}


//Account Type master


export function vAccountType(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AccountTypeRequired': true };
    }
    return null;
}

export function vAccountTypeName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AccountTypeNameRequired': true };
    }
    return null;
}
export function vAccountTypeNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectAccountTypeName': true };
    }
    return null;
}


//Account Group Master
export function vAccountGroupName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AccountGroupNameRequired': true };
    }
    return null;
}
export function vAccountGroupNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectAccountGroupName': true };
    }
    return null;
}


export function vParentGroupSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectParentGroup': true };
    }
    return null;
}

//Sales Invoice Validations

export function vInvoiceNoSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectInvoiceNumber': true };
    }
    return null;
}

export function vInvoiceDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectInvoiceDate': true };
    }
    return null;
}
export function vSalesReferanceSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSalesReferance': true };
    }
    return null;
}
export function vDeliveryModeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDeliveryMode': true };
    }
    return null;
}
export function vSalesAccountSelect(control: AbstractControl): { [Key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSalesAccount': true };
    }
    return null;

}
export function vDebitNoteApplicableSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDebitNoteApplicable': true };
    }
    return null;
}
export function vDebitNote(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'DebitNoteRequired': true };
    }
    return null;
}
export function vAppicableRewardPointsSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectAppicableRewardPoints': true };
    }
    return null;
}
export function vAppicableRewardPoints(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AppicableRewardPointsRequired': true };
    }
    return null;
}
export function vRemainingPoints(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'RemainingPointsRequired': true };
    }
    return null;
}

//Bank Details Validation

export function vBankCodeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectBankCode': true };
    }
    return null;
}
export function vBankNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectBankName': true };
    }
    return null;
}
export function vBankBranchSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectBankBranch': true };
    }
    return null;
}
export function vSwiftCodeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSwiftCode': true };
    }
    return null;
}
export function vAccountTypeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectAccountType': true };
    }
    return null;
}
export function vAccountNumber(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AccountNumberRequired': true };
    }
    return null;
}

// Employee Master validations 

export function vEmployeeCode(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'EmployeeCodeRequired': true };
    }
    return null;
}
export function vEmployeeName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'EmployeeNameRequired': true };
    }
    return null;
}
export function vFatherName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'FatherNameRequired': true };
    }
    return null;
}
export function vGenderSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectGender': true };
    }
    return null;
}
export function vDateofBirthSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDateofBirth': true };
    }
    return null;
}
export function vDateofJoiningSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDateofJoining': true };
    }
    return null;
}
export function vMaritalStatusSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectMaritalStatus': true };
    }
    return null;
}
export function vEmployeeRoleSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectEmployeeRole': true };
    }
    return null;
}

export function vBloodGroupSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectBloodGroup': true };
    }
    return null;
}
export function vRelationshipSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectRelationship': true };
    }
    return null;
}



//Customer Master
export function vCustomerName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'CustomerNameRequired': true };
    }
    return null;
}
export function vMarkuplevelSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectMarkupLevel': true };
    }
    return null;
}
export function vCurrencySelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectCurrency': true };
    }
    return null;
}
export function vContactPersonName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ContactPersonNameRequired': true };
    }
    return null;
}


//country master (bharath-11.12.2020)

export function vCountryCode(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'CountryCodeRequired': true };
    }
    return null;
}
export function vCountryCodeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectCountryCode': true };
    }
    return null;
}
export function vCountryName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'CountryNameRequired': true };
    }
    return null;
}
export function vCountryNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectCountryName': true };
    }
    return null;
}
//State master 
export function vStateCode(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'StateCodeRequired': true };
    }
    return null;
}
export function vStateCodeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectStateCode': true };
    }
    return null;
}
export function vStateName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'StateNameRequired': true };
    }
    return null;
}
export function vStateNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectStateName': true };
    }
    return null;
}

//City Master
export function vDistrictCode(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'DistrictcodeRequired': true };
    }
    return null;
}
export function vDistrictCodeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDistrictcode': true };
    }
    return null;
}
export function vDistrictName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'DistrictNameRequired': true };
    }
    return null;
}
export function vDistrictNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDistrictName': true };
    }
    return null;
}

//currency master
export function vCurrencyCode(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'CurrencyCodeRequired': true };
    }
    return null;
}
export function vCurrencyCodeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectCurrencyCode': true };
    }
    return null;
}
export function vCurrencyName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'CurrencyNameRequired': true };
    }
    return null;
}
export function vCurrencyNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectCurrencyName': true };
    }
    return null;
}
export function vExchangeRate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ExchangeRateRequired': true };
    }
    return null;
}
//port master
export function vPortCode(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'PortCodeRequired': true };
    }
    return null;
}
export function vPortCodeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPortCode': true };
    }
    return null;
}
export function vPortName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'PortNameRequired': true };
    }
    return null;
}
export function vPortNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPortName': true };
    }
    return null;
}
//Import entry

export function vImportDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectImportDate': true };
    }
    return null;
}
export function vInvoiceNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'InvoiceNoRequired': true };
    }
    return null;
}
export function vInvoiceAmount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'InvoiceAmountRequired': true };
    }
    return null;
}
export function vVoucherNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'VoucherNumberRequired': true };
    }
    return null;
}
export function vAgentNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectAgentName': true };
    }
    return null;
}
export function vAgentReceivingDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectAgentReceivingDate': true };
    }
    return null;
}
export function vBENo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'BENumberRequired': true };
    }
    return null;
}
export function vContainerNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ContainerNumberRequired': true };
    }
    return null;
}
export function vBLNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'BLNumberRequired': true };
    }
    return null;
}
export function vAgentInvoiceNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AgentInvoiceNumberRequired': true };
    }
    return null;
}
export function vImportnoSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectImportno': true };
    }
    return null;
}
//SalesMan master
export function VSalesmanTypeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSalesmanType': true };
    }
    return null;
}
export function vBaseTarget(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'BaseTargetRequired': true };
    }
    return null;
}
export function vBaseCommision(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'BaseCommisionRequired': true };
    }
    return null;
}
export function vSlab(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SlabRequired': true };
    }
    return null;
}
export function vMaxRange(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'MaxRangeRequired': true };
    }
    return null;
}
export function vMinRange(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'MinRangeRequired': true };
    }
    return null;
}
export function vCommision(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'CommisionRequired': true };
    }
    return null;
}
//purchase Inward
export function vInwardDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectInwardDate': true };
    }
    return null;
}
export function vInwardTypeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectInwardType': true };
    }
    return null;
}
export function vDriverName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'DriverNameRequired': true };
    }
    return null;
}
export function vOrderQuantity(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'OrderQuantityRequired': true };
    }
    return null;
}
export function vReceivedQuantity(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ReceivedQuantityRequired': true };
    }
    return null;
}
export function vLotNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'LotNumberRequired': true };
    }
    return null;
}
export function vInvoiceQuntity(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'InvoiceQuantityRequired': true };
    }
    return null;
}
export function vInwardNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'InwardNumberRequired': true };
    }
    return null;
}
export function vDebitAmountSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDebitAmount': true };
    }
    return null;
}
// Purchase Order
export function vPurchaseOrderDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPurchaseOrderDate': true };
    }
    return null;
}
export function vPurchaseOrderTypeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPurchaseOrderDate': true };
    }
    return null;
}
export function vDeliveryDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDeliveryDate': true };
    }
    return null;
}
export function vDeliveryToBranchSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDeliveryToBranch': true };
    }
    return null;
}

//Supplier Master
export function vSupplierName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SupplierNameRequired': true };
    }
    return null;
}

export function vSupplierType(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SupplierTypeRequired': true };
    }
    return null;
}

export function vPayModeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPayMode': true };
    }
    return null;
}

export function vSupplierCategorySelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSupplierCategory': true };
    }
    return null;
}
export function vSupplierTypeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSupplierType': true };
    }
    return null;
}
export function vVatTypeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectVatType': true };
    }
    return null;
}

//Sales Order

// export function vDeliveryDateSelect(control: AbstractControl): { [key: string]: any } | null {
//     if (!control.value) {
//         return { 'SelectDeliveryDate': true };
//     }
//     return null;
// }

export function vAdvancePercentage(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AdvancePercentageRequired': true };
    }
    return null;
}

export function vDeliveryPercentage(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'DeliveryPercentageRequired': true };
    }
    return null;
}

export function vAdvanceAmount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AdvanceAmountRequired': true };
    }
    return null;
}

export function vDeliveryAmount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'DeliveryAmountRequired': true };
    }
    return null;
}

export function vCreditApplicableSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectCreditApplicable': true };
    }
    return null;
}

export function vEligibleDays(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'EligibleDaysRequired': true };
    }
    return null;
}

export function vReferenceNoSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectReferenceNumber': true };
    }
    return null;
}

export function vPaymentTermsSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPaymentTerms': true };
    }
    return null;
}

export function vDeliveryTermsSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDeliveryTerms': true };
    }
    return null;
}

export function vOtherTermsSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectOtherTerms': true };
    }
    return null;
}

export function vOtherChargesDescriptionSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectOtherChargesDescription': true };
    }
    return null;
}


//Sales Return
export function vSalesReturnDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSalesReturnDate': true };
    }
    return null;
}

export function vReceiveQuantity(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ReceiveQuantityRequired': true };
    }
    return null;
}

export function vLocationSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectLocation': true };
    }
    return null;
}


//Quote Finalized
export function vFinalizedDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectFinalizedDate': true };
    }
    return null;
}


export function vQuoteAmount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'QuoteAmountRequired': true };
    }
    return null;
}


//Request For Quote
export function vQuoteDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectQuoteDate': true };
    }
    return null;
}

export function vExpectedDeliveryDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectExpectedDeliveryDate': true };
    }
    return null;
}
export function vSelectSupplier(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSupplier': true };
    }
    return null;
}

//Delivery Master
export function vPersonNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPersonName': true };
    }
    return null;
}
export function vDriverNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDriverName': true };
    }
    return null;
}

//Stock Adjustment
export function vCycleNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'CycleNumberRequired': true };
    }
    return null;
}
export function vStockAdjustmentDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectStockAdjustmentDate': true };
    }
    return null;
}
export function vStockTakersSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectStockTakers': true };
    }
    return null;
}
//Role Creation
export function vRoleName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'RoleNameRequired': true };
    }
    return null;
}
//Bank Master
export function vBankCode(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'BankCodeRequired': true };
    }
    return null;
}

export function vBankName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'BankNameRequired': true };
    }
    return null;
}

//Product Type
export function vProductTypeName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ProductTypeNameRequired': true };
    }
    return null;
}

//Role Wise Screen Mapping
export function vRoleNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectRoleName': true };
    }
    return null;
}
export function vModuleNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value.length) {
        return { 'SelectModuleName': true };
    }
    return null;
}


//Change password
export function vOldPassword(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'OldPasswordRequired': true };
    }
    if (control.value && control.value.length > 20) {
        return { 'OldPasswordMustHave8Characters': true };
    }
    // return null;
}
export function vNewPassword(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'NewPasswordRequired': true };
    }
    if (!control.value.toString().match('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@^#()+={$};:,!<%>/*?|"`~&._-])[A-Za-z\d$@^#()+={$};:,!<%>/*?|"`~&._-].{7,20}')) {
        return { 'ValidPassword': true };
    }
    // "^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,12}$";
    // if (control.value && control.value.length < 8) {
    //     return { 'NewPasswordMustHave8Characters': true };
    // }
    // return null;
}

export function vReTypePassword(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'RetypePasswordRequired': true };
    }
    // if (control.value && control.value.length < 8) {
    //     return { 'RetypePasswordMustHave8Characters': true };
    // }
    // return null;
}
export function vUserSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectUser': true };
    }
    return null;
}
//user master


export function vUserName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'UserNameRequired': true };
    }
    return null;
}
export function vEmployeeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectEmployee': true };
    }
    return null;
}

// Reward Master

export function vMinimumAmount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'MinimumAmountRequired': true };
    }
    return null;
}
export function vMaximumAmount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'MaximumAmount': true };
    }
    return null;
}


//Receipt form
export function vReceiptDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectReceiptDate': true };
    }
    return null;
}
export function vFromAccountSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectFromAccount': true };
    }
    return null;
}
export function vToAccountSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectToAccount': true };
    }
    return null;
}
export function vReceiptAmount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ReceiptAmountRequired': true };
    }
    return null;
}
export function vReceiptModeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectReceiptMode': true };
    }
    return null;
}
//Payment Form
export function vPaymentDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPaymentDate': true };
    }
    return null;
}
export function vPaymentModeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPaymentMode': true };
    }
    return null;
}
export function vPaymentAmount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'PaymentAmountRequired': true };
    }
    return null;
}
//Contra
export function vFromAccountNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectFromAccountName': true };

    }
}
export function vToAccountNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectToAccountName': true };

    }
}
export function vAmount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AmountRequired': true };
    }
    return null;
}
//Account Closing
export function vMonthSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectMonth': true };
    }
    return null;
}

//TellerCash 
export function vTellerNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectTellerName': true };

    }
}
export function vSalesDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSalesDate': true };
    }
    return null;
}
// Account Ledger


export function vAccountName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AccountNameRequired': true };
    }
    return null;
}
export function vSelectAccountgroup(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectAccountgroup': true };
    }
    return null;
}
export function vSelectSeries(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSeries': true };
    }
    return null;
}

//petty cash request
export function vSelectRequestDate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectRequestDate': true };
    }
    return null;
}
export function vRequestAmount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'RequestAmountRequired': true };
    }
    return null;
}
export function vReason(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ReasonRequired': true };
    }
    return null;
}
export function vSelectCurrency(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectCurrency': true };
    }
    return null;
}
//petty cash settlement

export function vSelectRequestNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectRequestno': true };
    }
    return null;
}
export function vSelectRequestAmount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectRequestAmount': true };
    }
    return null;
}
export function vSelectSettlementBranch(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSettlementBranch': true };
    }
    return null;
}
export function vSelectSettlementType(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSettlementType': true };
    }
    return null;
}
export function vSelectSettlementDate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSettlementDate': true };
    }
    return null;
}

export function vSelectSettlementAccount(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSettlementAccount': true };
    }
    return null;
}



//Stock Transfer Order
export function vRequestDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectRequestDate': true };
    }
    return null;
}

export function vToStoreSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectToStore': true };

    }
}

export function vStockTransferTypeSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectStockTransferType': true };

    }
}
export function vProductNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectProductName': true };

    }
}
export function vVariantNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectVariantName': true };

    }
}



//Tender Registration:
export function vTenderReferenceNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'TenderReferenceNoRequired': true };
    }
    return null;
}
export function vTenderReferenceDate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectTenderReferenceDate': true };
    }
    return null;
}
export function vTenderPartyName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'TenderPartyNameRequired': true };
    }
    return null;
}

export function vTenderStatus(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'TenderStatus': true };
    }
    return null;
}
export function vAttachmentRequired(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AttachmentRequired': true };
    }
    return null;
}

export function vAttachmentDescription(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'AttachmentDescription': true };
    }
    return null;
}

//approval type
export function vSelectDocumentName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectDocumentName': true };
    }
    return null;
}
export function vSelectWorkflowType(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectWorkflowType': true };
    }
    return null;
}
export function vWorkflowName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'WorkflowName': true };
    }
    return null;
}
export function vSelectConditionType(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectConditionType': true };
    }
    return null;
}
export function vConditionValue(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'ConditionValue': true };
    }
    return null;
}
export function vTATinHrs(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'TATinHrs': true };
    }
    return null;
}



//credit note
export function vSelectCreditnoteDate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectCreditnoteDate': true };
    }
    return null;
}
export function vSelectPartyType(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectPartyType': true };
    }
    return null;
}
export function vSelectAccountName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectAccountName': true };
    }
    return null;
}
export function vSelectReferenceNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectReferenceNo': true };
    }
    return null;
}
//stock inward

export function vSelectInwardDate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectInwardDate': true };
    }
    return null;
}
export function vSelectInwardType(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectInwardType': true };
    }
    return null;
}


export function vVehicleNo(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'VehicleNoRequired': true };
    }
    return null;
}
export function vPersonName(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'PersonNameRequired': true };
    }
    return null;
}

export function vseverity(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SeverityRequired': true };
    }
    return null;
}
export function vDamagedunitprice(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'DamagedunitpriceRequired': true };
    }
    return null;
}


//Sales Quotation
export function vSalesQuotationDateSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectSalesQuotationDate': true };
    }
    return null;
}

export function vCustomerNameSelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectCustomerName': true };
    }
    return null;
}

export function vQuotationPreparedBySelect(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectQuotationPreparedBy': true };
    }
    return null;
}

export function vBholaBankDetails(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
        return { 'SelectBholaBankDetails': true };
    }
    return null;
}

    //Sales Order
    export function vSalesOrderDateSelect(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectSalesOrderDate': true };
        }
        return null;
    }

    // Quote Finalize:
    export function vQuoteFinalizeDate(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectQuoteFinalizedDate': true };
        }
        return null;
    }
    export function vTaxTypeSelect(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectTaxType': true };
        }
        return null;
    }

    export function vBranchnameSelect(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectBranch': true };
        }
        return null;
    }

    export function vBillingaddress(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectBillingAddress': true };
        }
        return null;
    }
    export function vaddress(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectAddress': true };
        }
        return null;
    }
    export function vReceivedBranchSelect(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectReceivedBranch': true };
        }
        return null;
    }
    export function vsuplierorbranch(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SupplierorbranchRequired': true };
        }
        return null;
    }

    export function venddate(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'EnddateRequired': true };
        }
        return null;
    }

    export function vreservationdate(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'ReservationdateRequired': true };
        }
        return null;
    }
    export function vstockdate(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'StockAdjustmentdateRequired': true };
        }
        return null;
    }
    export function vstocker(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'StocktakerRequired': true };
        }
        return null;
    }
    export function vcycleno(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'CyclenoRequired': true };
        }
        return null;
    }

    export function vVariantId(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectVariantname': true };
        }
        return null;
    }
    export function vevidence(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectInvoiceno': true };
        }
        return null;
    }

    export function vInvoicetyperequired(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectInvoicetype': true };
        }
        return null;
    }
    export function vdeliverytorequired(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectDeliveryto': true };
        }
        return null;
    }

    export function vdeliverytype(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectDeliverytype': true };
        }
        return null;
    }


    //Import Entry
    export function vSelectImportDate(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectImportDate': true };
        }
        return null;
    }
    export function vSelectImportCountry(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectImportCountry': true };
        }
        return null;
    }
    export function vSelectSupplierName(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectSupplierName': true };
        }
        return null;
    }
    export function vSupplierInvoiceNo(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SupplierInvoiceNo': true };
        }
        return null;
    }
    export function vSupplierInvoiceAmount(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SupplierInvoiceAmount': true };
        }
        return null;
    }
    export function vSupplierCurrency(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SupplierCurrency': true };
        }
        return null;
    }
    export function vAgentName(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'AgentName': true };
        }
        return null;
    }
    export function vAgentCurrency(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'AgentCurrency': true };
        }
        return null;
    }
    export function vBErefDate(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'BErefDate': true };
        }
        return null;
    }
    export function vShippingLine(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'ShippingLine': true };
        }
        return null;
    }
    export function vSelectPort(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectPort': true };
        }
        return null;
    }
    export function vBLDate(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'BLDate': true };
        }
        return null;
    }
    export function vAgentInvoiceAmont(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'AgentInvoiceAmont': true };
        }
        return null;
    }
    export function vUserDiscount(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'UserDiscountRequired': true };
        }
        return null;
    }

    export function vBranchSupplierSelect(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectBranchSupplierName': true };
        }
        return null;
    }
    //Scheme Master

    export function vSchemeName(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SchemeNameRequired': true };
        }
        return null;
    }
    export function vAccountHolderName(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'AccountHolderNameRequired': true };
        }
        return null;
    }
    export function vMinimumqtyName(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'MinimumqtyRequired': true };
        }
        return null;
    }
    export function vMaximumqtyName(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'MaximumqtyRequired': true };
        }
        return null;
    }


    export function vProductMultiNameSelect(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value || !control.value.length) {
            return { 'SelectProductName': true };

        }
        return null;
    }

    export function vBranchMultiNameSelect(control: AbstractControl): { [key: string]: any } | null {

        if (!control.value || !control.value.length) {
            return { 'SelectBranch': true };
        }
        return null;
    }
    export function vProductTypeMultiNameSelect(control: AbstractControl): { [key: string]: any } | null {

        if (!control.value || !control.value.length) {
            return { 'SelectProductType': true };
        }
        return null;
    }

    export function vProductCategoryMultiNameSelect(control: AbstractControl): { [key: string]: any } | null {

        if (!control.value || !control.value.length) {
            return { 'SelectProductCategory': true };
        }
        return null;
    }
    //Ecommerce
    //coupon master
    export function vCouponName(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'CouponNameRequired': true };
        }
        return null;
    }
    export function vCouponAmount(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'CouponAmountRequired': true };
        }
        return null;
    }
    export function vDiscountType(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'DiscountTypeRequired': true };
        }
        return null;
    }
    export function vMinimumSpend(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'MinimumSpendRequired': true };
        }
        return null;
    }
    export function vMaximumSpend(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'MaximumSpendRequired': true };
        }
        return null;
    }
    export function vUsageLimitPerCoupan(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'UsageLimitPerCoupanRequired': true };
        }
        return null;
    }
    export function vUsageLimitPerUsers(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'UsageLimitPerUsersRequired': true };
        }
        return null;
    }
    //Order Description Master
    export function vSelectOrderStatus(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectOrderStatus': true };
        }
        return null;
    }
    export function vSelectOrderSubStatus(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'SelectOrderSubStatus': true };
        }
        return null;
    }
    export function vOrderDescription(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'OrderStatusDescriptionRequired': true };
        }
        return null;
    }
    export function vShippingCharge(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'ShippingChargeRequired': true };
        }
        return null;
    }
    export function vDeliveryTime(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'DeliveryTimeRequired': true };
        }
        return null;
    }

    export function vBrandName(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'BrandNameRequired': true };
        }
        return null;
    }
    export function vType(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'typeRequired': true };
        }
        return null;
    }
    export function vPurchaseReturnReason(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'PurchaseReturnReasonRequired': true };
        }
        return null;
    }
    export function vDialCode(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'DialCodeRequired': true };
        }
        return null;
    }
    export function vPhonenumberLength(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return { 'PhonenumberLengthRequired': true };
        }
        return null;
    }



