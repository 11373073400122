import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from 'src/app/core/domain/appconfig';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { SalesDashboardService } from 'src/app/core/Services/dashboard/sales-dashboard.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.css'],
})
export class SalesDashboardComponent implements OnInit {
  _Action: IAction;
  datePipe = new DatePipe('en-US');
  _IsProgressSpinner: boolean = false;
  _Branches: any[] = [];
  _salesdashboardform: FormGroup;
  documentStyle: any;
  _SalesDashboardObj: any;
  mindate: Date
  maxdate: Date

  basicOptions: any;

  _Isbarsd: boolean = true;
  _Islinesd: boolean = true;
  _Ispiesd: boolean = true;
  _Isdoughnutsd: boolean = true;
  _Isbarsd1: boolean = true;
  _Islinesd1: boolean = true;
  _Ispiesd1: boolean = true;
  _Isdoughnutsd1: boolean = true;
  _Isbarpd: boolean = true;
  _Islinepd: boolean = true;
  _Ispiepd: boolean = true;
  _Isdoughnutpd: boolean = true;

  _IsLineBranch: boolean = false;
  _IsbarBranch: boolean = true;
  _IspieBranch: boolean = false;
  _IsdoughnutBranch: boolean = false;

  config: AppConfig;

  trimlableDataProduct: any = [];
  salesByCategoryLabel: any = [];
  salesByCategoryLabelDots: any = [];

  salesReturnRep: any = [];
  salesReturnRepOpt: any = [];
  salesReturn: any = [];
  salesReturnValues: any = [];
  topSellingProductBranchWise: any = [];
  topSellingProductBranchWiseLine: any = [];
  topSellingProductBranchWiseLineOpt: any = [];
  tempTopSellingProductBranchWise: any = [];
  top5SellingProductBranchWise: any;

  salesByCategory: any;
  salesByCategoryOpt: any;
  salesByCategoryValue: any;
  noTopSellingProductBranchWise: boolean = false;

  _selectedTopBranch: any[] = [];

  salesReturnHead: any;
  data: any;
  topSelling: any;
  topReturn: any;

  _isDoubleBar: boolean = true;

  noDoubleBarChart: boolean = true;
  noSalesByCategory: boolean = true;

  topSellingProduct: any;
  topSellingProductOpt: any;
  topSellingProductLabel: any;
  topSellingProductLabelDots: any;
  topSellingProductValue: any;

  topReturnProduct: any;
  topReturnProductOpt: any;
  topReturnProductLabel: any;
  topReturnProductLabelDots: any;
  topReturnProductValue: any;

  topSellingBranchWiseLabel: any;
  topSellingBranchWiseLabelDots: any;
  topSellingBranchWiseValue: any;

  noTopSelling: boolean = false;
  noTopReturn: boolean = false;

  topSellingBwDataSet: any[] = [];
  topSellingLineDataSet: any[] = [];

  topSellingBw: any[] = [];
  topSellingLine: any[] = [];
  bIds: any[] = [];

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private salesDashboardService: SalesDashboardService,
    private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService
  ) { }

  ngOnInit() {
    this._Action = this._AccessRightsService.getAccessRights(345);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.utility.pageLoadScript();
    this.documentStyle = getComputedStyle(document.documentElement);
    this._salesdashboardform = this.fb.group({
      branchid: [parseInt(sessionStorage['currentbranchid'])],
      fromdate: [new Date(), Validators.required]
      
      // todate: [new Date(), Validators.required],
    });
    if(sessionStorage['Environmentenddate'] == 'null'){
      this._salesdashboardform.get('fromdate').setValue(new Date);
    }
    else{
      let date = new Date(sessionStorage['Environmentenddate'])
      this._salesdashboardform.get('fromdate').setValue(date)
    }
    // let today = new Date();
    // let twoDaysBefore = new Date(today);
    // twoDaysBefore.setDate(today.getDate() - 2)
    // console.log("Date", twoDaysBefore)
    // this._salesdashboardform.get("fromdate").setValue(twoDaysBefore);
    // this.maxdate = today
    // this.mindate = twoDaysBefore
    this.salesReturnHead = {
      SALESCOUNT: 0,
      SALESINVOICEVALUE: 0,
      SALESRETURNCOUNT: 0,
      SALESRETURNVALUE: 0,
    };

    this.topSellingBw = [
      '#FF5733',
      '#FFC300',
      '#DA70D6',
      '#00CED1',
      '#8B0000',
      '#8B008B',
      '#32CD32',
      '#FF1493',
      '#9400D3',
      '#1E90FF',
      '#FF4500',
      '#FF00FF',
      '#FF7F50',
      '#FFFF00',
      '#9932CC',
      '#00FF7F',
      '#FF69B4',
      '#FF6347',
      '#00FFFF',
      '#FF8C00',
      '#FF00FF',
      '#8A2BE2',
      '#00FF00',
      '#0000FF',
    ];

    this.topSellingLine = ['rgba(255, 87, 51, 0.2)', 'rgba(255, 195, 0, 0.2)', 'rgba(218, 112, 214, 0.2)', 'rgba(0, 206, 209, 0.2)', 'rgba(139, 0, 0, 0.2)', 'rgba(139, 0, 139, 0.2)', 'rgba(50, 205, 50, 0.2)', 'rgba(255, 20, 147, 0.2)', 'rgba(148, 0, 211, 0.2)', 'rgba(30, 144, 255, 0.2)', 'rgba(255, 69, 0, 0.2)', 'rgba(255, 0, 255, 0.2)', 'rgba(255, 127, 80, 0.2)', 'rgba(255, 255, 0, 0.2)', 'rgba(153, 50, 204, 0.2)', 'rgba(0, 255, 127, 0.2)', 'rgba(255, 105, 180, 0.2)', 'rgba(255, 99, 71, 0.2)', 'rgba(0, 255, 255, 0.2)', 'rgba(255, 140, 0, 0.2)', 'rgba(255, 0, 255, 0.2)', 'rgba(138, 43, 226, 0.2)', 'rgba(0, 255, 0, 0.2)', 'rgba(0, 0, 255, 0.2)']


    this._Isbarsd = false;
    this._Islinesd = false;
    this._Ispiesd = true;
    this._Isdoughnutsd = false;
    this._Isbarsd1 = false;
    this._Islinesd1 = false;
    this._Ispiesd1 = true;
    this._Isdoughnutsd1 = false;
    this._Isbarpd = false;
    this._Islinepd = false;
    this._Ispiepd = true;
    this._Isdoughnutpd = false;

    this._IsLineBranch = false;
    this._IsbarBranch = true;
    this._IspieBranch = false;
    this._IsdoughnutBranch = false;

    this.salesDashboardService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._Branches = resultJSON.branches;
      this.bIds = [26, 29, 1, 28, 35];
      // this._selectedTopBranch = this._Branches.filter(a => bIds.includes(a.branchid))
      this._selectedTopBranch = this.bIds;
    });

    this.initializeCharts();
  }

  initializeCharts() {
    this.generateDoubleBar();
    this.topSalesBranchWise();
  }
  onchangefromdate() {
		let fromdate = this._salesdashboardform.get("fromdate").value
		this._salesdashboardform.get("todate").reset();
		this.mindate = fromdate;
		let twoDaysAfter = new Date(fromdate);
		twoDaysAfter.setDate(fromdate.getDate() + 2)
		this.maxdate = twoDaysAfter
	}

  chartchangepd(charttype4) {
    if (charttype4 == 'bar') {
      this._Isbarpd = true;
      this._Islinepd = false;
      this._Ispiepd = false;
      this._Isdoughnutpd = false;
    }
    if (charttype4 == 'line') {
      this._Isbarpd = false;
      this._Islinepd = true;
      this._Ispiepd = false;
      this._Isdoughnutpd = false;
    }
    if (charttype4 == 'pie') {
      this._Isbarpd = false;
      this._Islinepd = false;
      this._Ispiepd = true;
      this._Isdoughnutpd = false;
    }
    if (charttype4 == 'doughnut') {
      this._Isbarpd = false;
      this._Islinepd = false;
      this._Ispiepd = false;
      this._Isdoughnutpd = true;
    }
  }

  chartchangesd(charttype1) {
    if (charttype1 == 'bar') {
      this._Isbarsd = true;
      this._Islinesd = false;
      this._Ispiesd = false;
      this._Isdoughnutsd = false;
    }
    if (charttype1 == 'line') {
      this._Isbarsd = false;
      this._Islinesd = true;
      this._Ispiesd = false;
      this._Isdoughnutsd = false;
    }
    if (charttype1 == 'pie') {
      this._Isbarsd = false;
      this._Islinesd = false;
      this._Ispiesd = true;
      this._Isdoughnutsd = false;
    }
    if (charttype1 == 'doughnut') {
      this._Isbarsd = false;
      this._Islinesd = false;
      this._Ispiesd = false;
      this._Isdoughnutsd = true;
    }
  }
  chartChangedBranch(charttype1) {
    if (charttype1 == 'bar') {
      this._IsLineBranch = false;
      this._IsbarBranch = true;
      this._IspieBranch = false;
      this._IsdoughnutBranch = false;
    }
    if (charttype1 == 'line') {
      this._IsLineBranch = true;
      this._IsbarBranch = false;
      this._IspieBranch = false;
      this._IsdoughnutBranch = false;
    }
    if (charttype1 == 'pie') {
      this._IsLineBranch = false;
      this._IsbarBranch = false;
      this._IspieBranch = true;
      this._IsdoughnutBranch = false;
    }
    if (charttype1 == 'doughnut') {
      this._IsLineBranch = false;
      this._IsbarBranch = false;
      this._IspieBranch = false;
      this._IsdoughnutBranch = true;
    }
  }

  chartchangesd1(charttype2) {
    if (charttype2 == 'bar') {
      this._Isbarsd1 = true;
      this._Islinesd1 = false;
      this._Ispiesd1 = false;
      this._Isdoughnutsd1 = false;
    }
    if (charttype2 == 'line') {
      this._Isbarsd1 = false;
      this._Islinesd1 = true;
      this._Ispiesd1 = false;
      this._Isdoughnutsd1 = false;
    }
    if (charttype2 == 'pie') {
      this._Isbarsd1 = false;
      this._Islinesd1 = false;
      this._Ispiesd1 = true;
      this._Isdoughnutsd1 = false;
    }
    if (charttype2 == 'doughnut') {
      this._Isbarsd1 = false;
      this._Islinesd1 = false;
      this._Ispiesd1 = false;
      this._Isdoughnutsd1 = true;
    }
  }

  updateChartOptions() {
    if (this.config.dark) this.applyDarkTheme();
    else this.applyLightTheme();
  }
  applyDarkTheme() {
    this.basicOptions = {
      legend: {
        labels: {
          fontColor: '#ebedef',
        },
      },
      // scales: {
      //   xAxes: [{
      //     ticks: {
      //       fontColor: '#ebedef'
      //     },
      //     gridLines: {
      //       color: 'rgba(255,255,255,0.2)'
      //     }
      //   }],
      //   yAxes: [{
      //     ticks: {
      //       fontColor: '#ebedef'
      //     },
      //     gridLines: {
      //       color: 'rgba(255,255,255,0.2)'
      //     }
      //   }]
      // }
    };
  }

  applyLightTheme() {
    this.basicOptions = {
      legend: {
        labels: {
          fontColor: '#495057',
        },
      },
      // scales: {
      //   xAxes: [{
      //     ticks: {
      //       fontColor: '#495057'
      //     }
      //   }],
      //   yAxes: [{
      //     ticks: {
      //       fontColor: '#495057'
      //     }
      //   }]
      // }
    };
  }

  generateDoubleBar() {
    var branchid = this._salesdashboardform.get('branchid').value || null;
    var _fromdate = this._salesdashboardform.get("fromdate").value;
		// var _todate = this._salesdashboardform.get("todate").value;
		var datePipe = new DatePipe("en-US");
		var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
		// var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (!fromdate) {
			this._CustomExceptionService.handleError("Please select from date");
			return;
		}
		// if (!todate) {
		// 	this._CustomExceptionService.handleError("Please select To date");
		// 	return;
		// }
		// if (fromdate > todate) {
		// 	this._CustomExceptionService.handleError("From Date should be less than To Date");
		// 	return;
		// }
    this._SalesDashboardObj = {
			"branchid": this._salesdashboardform.get("branchid").value,
			"fromdate": fromdate,
			"ToDate": null,
		}
    this._IsProgressSpinner = true;
    this.salesDashboardService.SalesReturnReport(this._SalesDashboardObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this.trimlableDataProduct = [];
        this.salesReturn = [];
        this.salesReturnValues = [];
        this.salesReturnHead = {
          SALESCOUNT: 0,
          SALESINVOICEVALUE: 0,
          SALESRETURNCOUNT: 0,
          SALESRETURNVALUE: 0,
        };

        this.salesByCategoryLabel = [];
        this.salesByCategoryLabelDots = [];
        this.salesByCategoryValue = [];

        this.topSellingProductLabel = [];
        this.topSellingProductLabelDots = [];
        this.topSellingProductValue = [];

        this.topReturnProductLabel = [];
        this.topReturnProductLabelDots = [];
        this.topReturnProductValue = [];

        if (
          resultJSON.salesReturnReport != null &&
          resultJSON.salesReturnReport
        ) {
          this.noDoubleBarChart = false;
          if (
            resultJSON.salesReturnHeader[0] ||
            resultJSON.salesReturnHeader[0] != null
          ) {
            this.salesReturnHead = resultJSON.salesReturnHeader[0];
          } else {
            this.salesReturnHead = {
              SALESCOUNT: 0,
              SALESINVOICEVALUE: 0,
              SALESRETURNCOUNT: 0,
              SALESRETURNVALUE: 0,
            };
          }

          if (resultJSON.salesReturnReport.length > 0) {
            // var stockFreshValues = Object.values(resultJSON.salesReturnReport[0])
            for (let i = 0; i < resultJSON.salesReturnReport.length; i++) {
              // this.lableData[i] = resultJSON.Stockdetails[i].PRODUCTNAME;
              this.trimlableDataProduct[i] = this.datePipe.transform(
                resultJSON.salesReturnReport[i].DATE,
                'dd-MM-yyyy'
              );
              this.salesReturn[i] = resultJSON.salesReturnReport[i].SALESCOUNT;
              this.salesReturnValues[i] =
                resultJSON.salesReturnReport[i].SALESRETURNCOUNT;

              this.salesReturnRep = {
                labels: this.trimlableDataProduct,
                datasets: [
                  {
                    label: 'Sales',
                    backgroundColor:
                      this.documentStyle.getPropertyValue('--blue-500'),
                    borderColor:
                      this.documentStyle.getPropertyValue('--blue-500'),
                    data: this.salesReturn,
                    lbls: this.trimlableDataProduct

                  },
                  {
                    label: 'Sales Return',
                    backgroundColor:
                      this.documentStyle.getPropertyValue('--pink-500'),
                    borderColor:
                      this.documentStyle.getPropertyValue('--pink-500'),
                    data: this.salesReturnValues,
                    lbls: this.trimlableDataProduct

                  },
                ],
              };

              this.salesReturnRepOpt = {
                tooltips: {
                  callbacks: {
                    title: (item, data) => {
                      var datasetLabel = data.datasets[0].label || '';
                      return datasetLabel
                    },
                    label: (item, data) => {
                      var datasetLabel = data.datasets[0].lbls[item.index] || '';
                      var datasetVal = data.datasets[0].data[item.index] || '';
                      return `${datasetLabel}:  ${datasetVal}`
                    }
                  }
                }
              }
            }
          } else {
            this.noDoubleBarChart = true;
          }
        } else {
          this.noDoubleBarChart = true;
        }

        if (
          resultJSON.topReturnedProduct != null &&
          resultJSON.topReturnedProduct
        ) {
          this.noTopReturn = false;

          if (resultJSON.topReturnedProduct.length > 0) {
            for (let i = 0; i < resultJSON.topReturnedProduct.length; i++) {
              this.topReturnProductLabelDots[i] =
                resultJSON.topReturnedProduct[i].PRODUCTNAME.slice(0, 15) +
                '...';
              this.topReturnProductLabel[i] =
                resultJSON.topReturnedProduct[i].PRODUCTNAME
              this.topReturnProductValue[i] =
                resultJSON.topReturnedProduct[i].SALESRETURNCOUNT;

              this.topReturnProduct = {
                labels: this.topReturnProductLabelDots,
                datasets: [
                  {
                    label: 'Top Return Product',
                    backgroundColor: [
                      '#42A5F5',
                      '#FF6384',
                      '#3655eb',
                      '#e757eb',
                      '#b64c11',
                      '#FFCE56',
                    ],
                    data: this.topReturnProductValue,
                    lbls: this.trimlableDataProduct

                  },
                ],
              };

              this.topReturnProductOpt = {
                tooltips: {
                  callbacks: {
                    title: (item, data) => {
                      var datasetLabel = data.datasets[0].label || '';
                      return datasetLabel
                    },
                    label: (item, data) => {
                      var datasetLabel = data.datasets[0].lbls[item.index] || '';
                      var datasetVal = data.datasets[0].data[item.index] || '';
                      return `${datasetLabel}:  ${datasetVal}`
                    }
                  }
                }
              }
            }
          } else {
            this.noTopReturn = true;
          }
        } else {
          this.noTopReturn = true;
        }

        if (
          resultJSON.topSellingProduct != null &&
          resultJSON.topSellingProduct
        ) {
          this.noTopSelling = false;

          if (
            resultJSON.salesReturnHeader[0] ||
            resultJSON.salesReturnHeader[0] != null
          ) {
            this.salesReturnHead = resultJSON.salesReturnHeader[0];
          } else {
            this.salesReturnHead = {
              SALESCOUNT: 0,
              SALESINVOICEVALUE: 0,
              SALESRETURNCOUNT: 0,
              SALESRETURNVALUE: 0,
            };
          }

          if (resultJSON.topSellingProduct.length > 0) {
            for (let i = 0; i < resultJSON.topSellingProduct.length; i++) {
              this.topSellingProductLabelDots[i] =
                resultJSON.topSellingProduct[i].PRODUCTNAME.slice(0, 15) +
                '...';
              this.topSellingProductLabel[i] =
                resultJSON.topSellingProduct[i].PRODUCTNAME
              this.topSellingProductValue[i] =
                resultJSON.topSellingProduct[i].SALESCOUNT;

              this.topSellingProduct = {
                labels: this.topSellingProductLabelDots,
                datasets: [
                  {
                    label: 'Top Selling Product',
                    backgroundColor: [
                      '#42A5F5',
                      '#FF6384',
                      '#3655eb',
                      '#e757eb',
                      '#b64c11',
                      '#FFCE56',
                    ],
                    data: this.topSellingProductValue,
                    lbls: this.topSellingProductLabel

                  },
                ],
              };

              this.topSellingProductOpt = {
                tooltips: {
                  callbacks: {
                    title: (item, data) => {
                      var datasetLabel = data.datasets[0].label || '';
                      return datasetLabel
                    },
                    label: (item, data) => {
                      var datasetLabel = data.datasets[0].lbls[item.index] || '';
                      var datasetVal = data.datasets[0].data[item.index] || '';
                      return `${datasetLabel}:  ${datasetVal}`
                    }
                  }
                }
              }
            }
          } else {
            this.noTopSelling = true;
          }
        } else {
          this.noTopSelling = true;
        }

        if (resultJSON.salesByCategory != null && resultJSON.salesByCategory) {
          this.noSalesByCategory = false;

          if (resultJSON.salesByCategory.length > 0) {
            for (let i = 0; i < resultJSON.salesByCategory.length; i++) {
              this.salesByCategoryLabel[i] =
                resultJSON.salesByCategory[i].PRODUCTCATEGORYNAME
              this.salesByCategoryLabelDots[i] =
                resultJSON.salesByCategory[i].PRODUCTCATEGORYNAME.slice(0, 15) +
                '...';
              this.salesByCategoryValue[i] =
                resultJSON.salesByCategory[i].SALESINVOICEVALUEPERCENTAGE;

              this.salesByCategory = {
                labels: this.salesByCategoryLabelDots,
                datasets: [
                  {
                    label: 'Sales By Category',
                    backgroundColor: [
                      '#42A5F5',
                      '#FF6384',
                      '#3655eb',
                      '#e757eb',
                      '#b64c11',
                      '#FFCE56',
                    ],
                    data: this.salesByCategoryValue,
                    lbls: this.salesByCategoryLabel

                  },
                ],
              };
              this.salesByCategoryOpt = {
                tooltips: {
                  callbacks: {
                    title: (item, data) => {
                      var datasetLabel = data.datasets[0].label || '';
                      return datasetLabel
                    },
                    label: (item, data) => {
                      var datasetLabel = data.datasets[0].lbls[item.index] || '';
                      var datasetVal = data.datasets[0].data[item.index] || '';
                      return `${datasetLabel}:  ${datasetVal}`
                    }
                  }
                }
              }
            }
          } else {
            this.noSalesByCategory = true;
          }
        } else {
          this.noSalesByCategory = true;
        }

        this._IsProgressSpinner = false;
      });
  }

  topSalesBranchWise() {
    this._IsProgressSpinner = true;

    this.fetchTopSalesBranchWise();
  }

  filterBranch(event) {
    this._IsProgressSpinner = true;



    if (this._selectedTopBranch.length > 5) {
      this._IsProgressSpinner = false;
      return;
    } else if (this._selectedTopBranch.length <= 5 && this._selectedTopBranch.length > 0) {
      this.fetchTopSalesBranchWise();
      this._IsProgressSpinner = false;
    }

    if (this._selectedTopBranch.length == 0) {
      this.topSellingProductBranchWise = [];
      this.topSellingProductBranchWiseLine = [];
      this._IsProgressSpinner = false;
    }
    //   this._CustomExceptionService.handleWarning("More than 5 Branch can't be Selected")
    //   this._IsProgressSpinner = false;
    //   return
  }

  fetchTopSalesBranchWise() {
    var datePipe = new DatePipe("en-US");
    var pdate = this._salesdashboardform.get("fromdate").value;
    var selecteddate = datePipe.transform(pdate, 'dd-MMM-yyyy');

    var selectedbranch = this._selectedTopBranch
    let data = {
      "selectedbranch" : selectedbranch,
      "selecteddate" : selecteddate
    }
    this._IsProgressSpinner = true;
    this.salesDashboardService
      .TopSalesBranchWise(data)
      .subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this.topSellingBranchWiseLabel = [];
        this.topSellingBranchWiseLabelDots = [];
        if (
          resultJSON.topSalesBranchWise != null &&
          resultJSON.topSalesBranchWise
        ) {
          this._IsProgressSpinner = true;
          this.noTopSellingProductBranchWise = false;
          this.tempTopSellingProductBranchWise = {};

          this.topSellingBranchWiseLabel = [];
          this.topSellingBranchWiseLabelDots = [];
          this.topSellingBranchWiseValue = [];

          this.top5SellingProductBranchWise = {
            datasets: [],
            labels: [],
          };
          this.topSellingBwDataSet = [];
          this.topSellingLineDataSet = [];

          if (resultJSON.topSalesBranchWise.length > 0) {
            for (let i = 0; i < resultJSON.topSalesBranchWise.length; i++) {
              this.topSellingBranchWiseLabel[i] =
                resultJSON.topSalesBranchWise[i].BRANCHNAME
              this.topSellingBranchWiseLabelDots[i] =
                resultJSON.topSalesBranchWise[i].BRANCHNAME.slice(0, 30) +
                '...';
              let timeKeys = Object.keys(resultJSON.topSalesBranchWise[i])
                .filter((a) => a != 'BRANCHID')
                .filter((a) => a != 'BRANCHNAME');

              timeKeys.forEach((a) => {
                this.topSellingBranchWiseValue.push(
                  resultJSON.topSalesBranchWise[i][a]
                );
              });

              this.topSellingBwDataSet.push({
                label: this.topSellingBranchWiseLabel[i],
                data: this.topSellingBranchWiseValue,
                borderColor: this.topSellingBw[i],
                backgroundColor: this.topSellingBw[i],
              });

              this.topSellingLineDataSet.push({
                label: this.topSellingBranchWiseLabel[i],
                data: this.topSellingBranchWiseValue,
                borderColor: this.topSellingBw[i],
                backgroundColor: this.topSellingLine[i],
              });

              this.topSellingBranchWiseValue = [];

              this.topSellingProductBranchWise = {
                labels: timeKeys,
                datasets: this.topSellingBwDataSet,
                lbls: this.topSellingBranchWiseLabel

              };
              this.topSellingProductBranchWiseLine = {
                labels: timeKeys,
                datasets: this.topSellingLineDataSet,
                lbls: this.topSellingBranchWiseLabel

              };

              this.topSellingProductBranchWiseLineOpt = {
                tooltips: {
                  callbacks: {
                    title: (item, data) => {
                      var datasetLabel = data.datasets[0].label || '';
                      return datasetLabel
                    },
                    label: (item, data) => {
                      var datasetLabel = data.datasets[0].lbls[item.index] || '';
                      var datasetVal = data.datasets[0].data[item.index] || '';
                      return `${datasetLabel}:  ${datasetVal}`
                    }
                  }
                }
              }
            }
          } else {
            this.noTopSellingProductBranchWise = true;
            this._IsProgressSpinner = false;
          }
        } else {
          this.noTopSellingProductBranchWise = true;
          this._IsProgressSpinner = false;
        }
        this._IsProgressSpinner = false;
      });
  }
}
