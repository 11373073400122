import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { Table } from 'primeng/table';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class ExportUtility {
  constructor() { }

  exportCSV(dt: Table) {
    if (!dt.value || !dt.value.length)
      return;

    dt.exportCSV()
  }

  exportExcel<R>(exportList: R[], excelName: string, extenstion: 'xlsx' | 'csv') {
    if (!exportList || !exportList.length)
      return;

    let propList = Object.keys(exportList[0]);
    let countryList = this.GetExportList(exportList, propList);
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(countryList);
      const workbook = { Sheets: { 'Sheet_1': worksheet }, SheetNames: ['Sheet_1'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: extenstion, type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelName);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  ExportToPdf<R>(exportList: R[], pdfName: string) {
    if (!exportList || !exportList.length)
      return;
    let propList = Object.keys(exportList[0]);
    let countryList = this.GetExportList(exportList, propList);
    const doc = new jsPDF({ orientation: "landscape" });
    //var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });
    doc.table(2, 2, countryList, this.createHeaders(propList), { autoSize: true });

    // doc.autoTable(exp_cols, countryList);
    if (pdfName.indexOf('.pdf') == -1)
      pdfName += '.pdf';
    doc.save(pdfName);
  }

  GetExportList<R>(exportList: R[], propList: string[]) {
    let resList = [];
    exportList.forEach(exp => {
      let data: any = {};
      propList.forEach(prop => {
        data[prop] = (exp[prop] + '') || '';
      });
      resList.push(data);
    });
    return resList;
  }

  createHeaders(keys) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 65,
        align: "center",
        padding: 0
      });
    }
    return result;
  }



}
