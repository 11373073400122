import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private dataSubject = new Subject<any>();
  constructor() {}
  setData(data: any) {
    this.dataSubject.next(data);
  }
  getData(): Observable<string> {
    return this.dataSubject.asObservable();
  }
}
