<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Teller Cash Report</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_branches" optionLabel="branchname"
                                    optionValue="branchid" [filter]="true" filterBy="branchname" [showClear]="true"
                                    [(ngModel)]="branchid" (onChange)="OnChangeBranch()" [virtualScroll]="true"
                                    itemSize="15">
                                </p-dropdown> -->
                                <p-multiSelect [filter]="false" [options]="_branches" optionLabel="branchname"
                                    optionValue="branchid" [(ngModel)]="branchids" displaySelectedLabel="true"
                                    [filter]="true" filterBy="branchname" [virtualScroll]="true" itemSize="30"
                                    (onChange)="OnChangeBranch()">
                                    <ng-template let-account pTemplate="item">
                                        <div
                                            [style]="account.branchstatus == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                            {{account.branchname}}</div>
                                    </ng-template>
                                </p-multiSelect>
                                <label for="tellerid">Branch Name <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [filter]="false" [options]="_tellernames" optionLabel="employeename"
                                    optionValue="userid" [(ngModel)]="tellerids" displaySelectedLabel="true"
                                    [selectionLimit]="10" [filter]="true" filterBy="employeename" [virtualScroll]="true"
                                    itemSize="30">
                                </p-multiSelect>
                                <label for="tellerid">Teller Name </label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [filter]="false" [options]="_CurrencyList" optionLabel="currencyname"
                                    optionValue="currencyid" [(ngModel)]="currencyid" displaySelectedLabel="true"
                                    [filter]="true" filterBy="currencyname" [virtualScroll]="true" itemSize="30">
                                </p-multiSelect>
                                <label for="currencyid">Currency<span class="hlt-txt">*</span></label>
                            </span>

                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar [showIcon]="true" [(ngModel)]="fromdate" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2030"
                                    [minDate]="mindate" [maxDate]="maxdate">
                                </p-calendar>
                                <label for="fromdate">From Date</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar [showIcon]="true" [(ngModel)]="todate" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2030"
                                    [minDate]="mindate" [maxDate]="maxdate">
                                </p-calendar>
                                <label for="todate">To Date</label>
                            </span>
                        </td>

                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport()" class="p-button-sm p-button-success"></button>
                            <button pButton pRipple label="Clear" title="Clear" (click)="clear()" icon="pi pi-times" class="p-button-danger"></button>
                        </td>
                    </tr>
                </table>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Teller Cash Wise</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt_Tellercashviews.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>

                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel('cash')" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel('cash')"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf1()"></button>
                        </ng-template>
                    </p-toolbar>
                    <table class="normal-table">
                        <tr>
                            <td></td>
                            <td></td>
                            <td style="width: 14%;">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText [(ngModel)]="_TotalReceivableAmount"
                                        [ngModelOptions]="{standalone: true}">
                                    <label>Total Receivable Amount</label>
                                </span>
                            </td>
                            <td style="width: 14%;">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText [(ngModel)]="_TotalPaidAmount"
                                        [ngModelOptions]="{standalone: true}">
                                    <label>Total Paid Amount</label>
                                </span>
                            </td>
                            <td style="width: 14%;">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText [(ngModel)]="_TotalBalanceAmount"
                                        [ngModelOptions]="{standalone: true}">
                                    <label>Total Balance Amount</label>
                                </span>
                            </td>
                        </tr>
                    </table>
                    <p-table #dt_Tellercashviews [value]="_Tellercashviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [(selection)]="selectedrows" [globalFilterFields]="['tellername','salesdate','branchname','currencyname','receivableamount','paidamount','totalreceivableamount','totalpaidamount','totalbalanceamount','balance']"
                        [rowHover]="true" dataKey="tellerid" [columns]="cash_cols" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="branchname">Branch Name
                                    <p-sortIcon field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tellername">Teller Name
                                    <p-sortIcon field="tellername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="salesdate">Sales Date
                                    <p-sortIcon field="salesdate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname">Currency
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="receivableamount">Total Receivable Amount
                                    <p-sortIcon field="receivableamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="paidamount">Total Paid Amount
                                    <p-sortIcon field="paidamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="balance"> Total Balance Amount
                                    <p-sortIcon field="balance">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_tellercashview>
                            <tr>
                                <td>
                                    {{_tellercashview.branchname }}
                                </td>
                                <td>
                                    {{_tellercashview.tellername }}
                                </td>
                                <td>
                                    {{_tellercashview.salesdate | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_tellercashview.currencyname }}
                                </td>
                                <td>
                                    {{_tellercashview.receivableamount | number: '1.2' }}
                                </td>

                                <td>
                                    {{_tellercashview.paidamount | number: '1.2' }}
                                </td>
                                <td>
                                    {{_tellercashview.balance | number: '1.2' }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Teller Bank Wise</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt_Tellerbankviews.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>

                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt_Tellerbankviews.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel('bank')"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf('bank')"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt_Tellerbankviews [value]="_Tellerbankviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [(selection)]="selectedrows" [rowHover]="true" dataKey="tellerid" [columns]="bank_cols" [globalFilterFields]="['branchname','tellername','bankname','transactiondate','transactiontypename','billno','receiptno','totalamount','receiptno','bankname','reconcilationdate','reconciledstatus']"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="branchname">Branch Name
                                    <p-sortIcon field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tellername">Teller Name
                                    <p-sortIcon field="tellername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="bankname">Bank Name
                                    <p-sortIcon field="bankname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="transactiondate">Sales Date
                                    <p-sortIcon field="transactiondate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="transactiontypename">Transaction Type
                                    <p-sortIcon field="transactiontypename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="billno">Bill No
                                    <p-sortIcon field="billno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="receiptno">Rec/Pay No
                                    <p-sortIcon field="receiptno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="totalamount">Transaction Amount
                                    <p-sortIcon field="totalamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="reconciledstatus">Reconciled Status
                                    <p-sortIcon field="reconciledstatus">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="reconcilationdate"> Reconciled Date
                                    <p-sortIcon field="reconcilationdate">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_tellercashview>
                            <tr>
                                <td>
                                    {{_tellercashview.branchname }}
                                </td>
                                <td>
                                    {{_tellercashview.tellername }}
                                </td>
                                <td>
                                    {{_tellercashview.bankname }}
                                </td>
                                <td>
                                    {{_tellercashview.transactiondate | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_tellercashview.transactiontypename}}
                                </td>
                                <td>
                                    {{_tellercashview.billno}}
                                </td>
                                <td>
                                    {{_tellercashview.receiptno}}
                                </td>
                                <td>
                                    {{_tellercashview.totalamount | number: '1.2' }}
                                </td>
                                <td>
                                    {{_tellercashview.reconciledstatus }}
                                </td>
                                <td>
                                    {{_tellercashview.reconcilationdate | date:'dd/MM/yyyy' }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Advance/Credit Adjustment</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt_Creditnoteadjustmentviews.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>

                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel('credit')" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel('credit')"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdfCredit()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt_Creditnoteadjustmentviews [value]="_Creditnoteadjustmentviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [(selection)]="selectedrows" [rowHover]="true" dataKey="tellerid" [globalFilterFields]="['salesDate','branchName','currencyName','creditNoteAmount','advanceAmount','paymentAmount','totalAmount']"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="salesDate">Sales Date
                                    <p-sortIcon field="salesDate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="branchName">Branch Name
                                    <p-sortIcon field="branchName">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyName">Currency Name
                                    <p-sortIcon field="currencyName">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="creditNoteAmount">Credit Note Adjusted Amount
                                    <p-sortIcon field="creditNoteAmount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="advanceAmount">Advance Adjusted Amount
                                    <p-sortIcon field="advanceAmount">
                                    </p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="paymentAmount">Payment Amount <p-sortIcon field="paymentAmount">
                                    </p-sortIcon>
                                </th> -->
                                <th pSortableColumn="totalAmount">Total Amount
                                    <p-sortIcon field="totalAmount">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Creditnoteadjustmentviews>
                            <tr>
                                <td>
                                    {{_Creditnoteadjustmentviews.salesDate | date:'dd/MM/yyyy' }}
                                </td>
                                <td>
                                    {{_Creditnoteadjustmentviews.branchName }}
                                </td>
                                <td>
                                    {{_Creditnoteadjustmentviews.currencyName }}
                                </td>
                                <td>
                                    {{_Creditnoteadjustmentviews.creditNoteAmount }}
                                </td>
                                <td>
                                    {{_Creditnoteadjustmentviews.advanceAmount }}
                                </td>
                                <!-- <td>
                                    {{_Creditnoteadjustmentviews.paymentAmount }}
                                </td> -->
                                <td>
                                    {{_Creditnoteadjustmentviews.totalAmount }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>

</div>
<p-toast position="bottom-right"></p-toast>