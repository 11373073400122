<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Invoice Delay Time Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptinvoicedelayform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                    [minDate]="mindate" [maxDate]="maxdate" [yearNavigator]="true" yearRange="1980:2030"
                                        dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="fromdate">From Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                    [minDate]="mindate" [maxDate]="maxdate"   [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="todate">To Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchid"
                                        [ngModelOptions]="{standalone: true}" [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="brandid">Branch Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_customerlist" optionLabel="customername" optionValue="customerid"
                                        [(ngModel)]="customerid" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="customerid">Customer Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" icon="pi pi-search" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="500" [paginator]="true" [rowsPerPageOptions]="[100,200,300]" [rowsPerPageOptions]="[500,1000,1500,2000]" [globalFilterFields]="['invoiceno','invoicedate','invoicecreatedby','deliveryno','deliverydate',
                        'deliverycreatedby','timedelay']" [rowHover]="true" dataKey="productname" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="invoiceno">Invoice No.
                                    <p-sortIcon field="invoiceno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="invoicedate">Invoice Date
                                    <p-sortIcon field="invoicedate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="invoicecreatedby">Invoice Created by
                                    <p-sortIcon field="invoicecreatedby">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="deliveryno">Delivery No.
                                    <p-sortIcon field="deliveryno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="deliverydate">Delivery Date
                                    <p-sortIcon field="deliverydate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="deliverycreatedby">Delivery Created by
                                    <p-sortIcon field="deliverycreatedby">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="timedelay">Time delayed
                                    <p-sortIcon field="timedelay">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 60px;">
                                    {{i+ 1}}
                                </td>
                                <td>
                                    {{_ReportList.invoiceno}}
                                </td>
                                <td>
                                    {{_ReportList.invoicedate | date: 'dd/MM/yyyy' }}
                                </td>
                                <td>
                                    {{_ReportList.invoicecreatedby}}
                                </td>
                                <td>
                                    {{_ReportList.deliveryno}}
                                </td>
                                <td>
                                    {{_ReportList.deliverydate | date: 'dd/MM/yyyy' }}
                                </td>
                                <td>
                                    {{_ReportList.deliverycreatedby}}
                                </td>
                                <td>
                                    {{_ReportList.timedelay}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>