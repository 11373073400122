export class Branch {
 
    companyid: number;
    branchid: number;
    branchcode: string;
    branchname: string;
    branchtype: string;
    levelid: number;
    currencyid: number;
    address: string;
    contactperson: string;
    contactnumber: string;
    emailid: string;
    countryid: number;
    stateid: number;
    districtid: number;
    series: string;
    branchstatus: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    attachmenturl:string;
    attachmentname:string;
    settlementofficer:number;
    ziglimitinusd:number

}
