import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Currency } from 'src/app/core/Models/Currency';
import { Level } from 'src/app/core/Models/Level';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { ProductbarcodeprintService } from 'src/app/core/Services/reports/ProductBarcodePrint/productbarcodeprint.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';


@Component({
  selector: 'app-productbarcodestickerprint',
  templateUrl: './productbarcodestickerprint.component.html',
  styleUrls: ['./productbarcodestickerprint.component.css']
})
export class ProductbarcodestickerprintComponent implements OnInit {
  _producttypelist: Producttype[] = [];
  _currencies: Currency[] = [];
  _levels: Level[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _src: SafeResourceUrl;
  _productbarcodeprintform: FormGroup;
  _IsProgressSpinner: boolean = false;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _ProductPrintObj: any;
  _printtype: any;
  _userlevel = sessionStorage["levelid"];
  _currenctbranchid = sessionStorage["currenctbranchid"];
  _printtypelist: any[] = [];
  _Action: IAction;


  constructor(private utility: CommonUtilities, private fb: FormBuilder,
    private _router: Router, private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService,
    private sanitizer: DomSanitizer, private _ProductBarcodeService: ProductbarcodeprintService) { }
  InitializeProductbarcodeprintForm() {
    this._productbarcodeprintform = this.fb.group({
      producttypeid: [],
      productcategoryid: [],
      productid: [],
      printtype: [],
      quantity: [],
      level: [],
      currency: [],
      includevat:[]
    });
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(322);
    this.utility.pageLoadScript();
    this.InitializeProductbarcodeprintForm();
    // //this._printtype = [{
    //   "id": "0",
    //   "name": "Small"
    // },
    // {
    //   "id": "1",
    //   "name": "Large"
    // }
    // ];

    this._IsProgressSpinner = true;
    this._ProductBarcodeService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._producttypelist = resultJSON.productTypeList;
      this._currencies = resultJSON.currencylist;
      this._levels = resultJSON.levellist;

      this._printtypelist =
        [
          {
            printtype: '30mm * 20mm (Without Price)', value: 1
          },
          {
            printtype: '60mm * 30mm (With Price)', value: 2
          }
          ,
          { printtype: '60mm * 50mm (With Price)', value: 3 }
          ,
        ];

      this._productbarcodeprintform.controls["printtype"].setValue(1);
      this._productbarcodeprintform.get("quantity").setValue(1);
      this._productbarcodeprintform.get("currency").setValue(1);
      this._productbarcodeprintform.get("level").setValue(1);
      this._productbarcodeprintform.get("includevat").setValue(true);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  getProductCategory(event) {
    var selectedProductType = event.value;
    this._productcategorylist = [];
    this._productlist = [];
    if (!selectedProductType || !selectedProductType.length) {
      return;
    }
    this._IsProgressSpinner = true;
    this._ProductTypeObj = { "productType": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
    this._ProductBarcodeService.GetProductCategory(this._ProductTypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productcategorylist = resultJSON.productCategoryList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  getProduct(event) {
    var selectedProductCategory = event.value;
    this._productlist = [];
    if (!selectedProductCategory || !selectedProductCategory.length) {
      return;
    }
    this._ProductCategoryObj = { "category": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
    this._ProductBarcodeService.GetProduct(this._ProductCategoryObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productlist = resultJSON.productsearch;
    });
  }

  GenerateReport(event) {

    var ProductId = this._productbarcodeprintform.get("productid").value || [];
    var printtype = this._productbarcodeprintform.get("printtype").value || 1;
    var quantity = this._productbarcodeprintform.get("quantity").value || 1;
    var level = this._productbarcodeprintform.get("level").value || 1;
    var currency = this._productbarcodeprintform.get("currency").value || 1;
    var includevat = this._productbarcodeprintform.get("includevat").value || false;

    var includetax = "NO"


    if (ProductId.length == 0 || ProductId == null) {
      this._CustomExceptionService.handleError("Please Select Atleast One Product Name");
      return;
    }

    if(includevat == true)
    {
      includetax = "YES"
    }
    else
    {
      includetax = "NO"
    }

    this._IsProgressSpinner = true;

    if (printtype == 1)
      window.open("small-printer-service:$" + ProductId + "$" + quantity + " $ " + level + " $ " + currency, "_blank");

    if (printtype == 2)
     window.open("medium-printer-service:$" + ProductId + "$" + quantity + "$" + level + "$" + currency + "$" + includetax, "_blank");


    if (printtype == 3)
     window.open("large-printer-service:$" + ProductId + "$" + quantity + "$" + level + "$" + currency + "$" + includetax, "_blank");

    this._IsProgressSpinner = false;
  }

  clear(event) {
    this.InitializeProductbarcodeprintForm();
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this._productbarcodeprintform.get("printtype").setValue(1);
    this._productbarcodeprintform.get("quantity").setValue(1);
    this._productbarcodeprintform.get("currency").setValue(1);
    this._productbarcodeprintform.get("level").setValue(1);
  }

}
