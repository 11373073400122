<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Material Consumption Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_BranchList" optionLabel="branchname" optionValue="branchid"
                                    [(ngModel)]="_BranchId" [filter]="true" [virtualScroll]="true" itemSize="30"
                                    (onChange)="OnChangeBranch()" [ngModelOptions]="{standalone: true}">
                                    <ng-template let-account pTemplate="item">
                                        <div
                                            [style]="account.branchstatus == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                            {{account.branchname}}</div>
                                    </ng-template>
                                </p-multiSelect>
                                <label for="producttypeid">Branch</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_TypeList" optionLabel="metasubdescription"
                                    optionValue="metasubcode" [(ngModel)]="_TypeId" [filter]="true" [virtualScroll]="true"
                                    itemSize="30" [ngModelOptions]="{standalone: true}">
                                </p-multiSelect>
                                <label for="stocktype">Issue Type</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_ProductTypeList" optionLabel="producttypename"
                                    optionValue="producttypeid" [(ngModel)]="_ProductTypeId" [filter]="true"
                                    [selectionLimit]="50" [virtualScroll]="true" itemSize="30"
                                    (onChange)="GetProductCategory()" [ngModelOptions]="{standalone: true}">
                                </p-multiSelect>
                                <label for="stocktype">Product Type</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_ProductCategoryList" optionLabel="categoryname"
                                    optionValue="productcategoryid" [(ngModel)]="_ProductCategoryId" [filter]="true"
                                    [virtualScroll]="true" itemSize="30" (onChange)="GetProduct()"
                                    [ngModelOptions]="{standalone: true}">
                                </p-multiSelect>
                                <label for="stocktype">Product Category</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_ProductList" optionLabel="productname"
                                    optionValue="productid" [(ngModel)]="_ProductId" [filter]="true"
                                    [virtualScroll]="true" itemSize="30" [ngModelOptions]="{standalone: true}">
                                </p-multiSelect>
                                <label for="stocktype">Product</label>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true" [minDate]="mindate" [maxDate]="maxdate"
                                    dateFormat="dd/mm/yy" (onSelect)="OnChangeBranch()" [monthNavigator]="true"
                                    yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td>
                            <span class="p-float-label">
                                <p-calendar name="todate" (onSelect)="OnChangeBranch()" [(ngModel)]="todate"
                                    [showIcon]="true" dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate"
                                    [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Search" icon="pi pi-search" (click)="GenerateReport()" class="p-button-sm p-button-success"></button>
                            <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()" class="p-button-danger"></button>
                        </td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_GenerateReport" [scrollable]="true" scrollHeight="450px" [rows]="500" [paginator]="true" [rowsPerPageOptions]="[100,200,300]" [rowsPerPageOptions]="[500,1000,1500,2000]" [globalFilterFields]="['materialrequestdate','productname',
                        'deliveryquantity','price','uomname','refno','requestedby','issuedby','evidencedate']" [rowHover]="true" dataKey="productname" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" pSortableColumn="evidencedate">Date
                                    <p-sortIcon field="evidencedate"></p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="productname">Product
                                    <p-sortIcon field="productname"></p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="deliveryquantity">Delivery Quantity
                                    <p-sortIcon field="deliveryquantity"></p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="price">Price
                                    <p-sortIcon field="price">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="uomname">UOM
                                    <p-sortIcon field="uomname"></p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="refno">Ref No
                                    <p-sortIcon field="refno">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="materialrequestdate">
                                    Material Req Date
                                    <p-sortIcon field="materialrequestdate"></p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="requestedby">Requested By
                                    <p-sortIcon field="requestedby"></p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="issuedby">Issued By
                                    <p-sortIcon field="issuedby"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{_ReportList.evidencedate |date:'dd/MM/yyyy'}}</td>
                                <td>{{_ReportList.productname}}</td>
                                <td style="text-align: center;">{{_ReportList.deliveryquantity}}</td>
                                <td style="text-align: right;">{{_ReportList.price}}</td>
                                <td style="text-align: center;">{{_ReportList.uomname}}</td>
                                <td style="text-align: center;">{{_ReportList.refno}}</td>
                                <td style="text-align: center;">{{_ReportList.materialrequestdate |date:'dd/MM/yyyy'}}</td>
                                <td style="text-align: center;">{{_ReportList.requestedby}}</td>
                                <td style="text-align: center;">{{_ReportList.issuedby}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" style="text-align: right;">
                                    Total
                                </td>
                                <td style="text-align: center;">
                                    {{TotalQty}}
                                </td>
                                <td style="text-align: right;">
                                    {{TotalPrice| number : '1.2'}}
                                </td>
                                <td colspan="5"></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>