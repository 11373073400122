import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CreditnoteService } from 'src/app/core/services/accounts/creditnote.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { vSelectCreditnoteDate, vSelectPartyType, vSelectAccountName, vSelectReferenceNo, vSelectCurrency } from 'src/app/core/Validators/validation';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Creditnote } from 'src/app/core/Models/Creditnote';
import { Creditnotedetail } from 'src/app/core/Models/Creditnotedetail';
import { Creditnotegettransactiondetailsview } from 'src/app/core/Models/Credinotegettransactiondetailsview';
import { Creditnotegettransactionview } from 'src/app/core/Models/Creditnotegettransactionview';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Customer } from 'src/app/core/Models/Customer';
import { DatePipe } from '@angular/common';
import { Creditnoteothercharge } from 'src/app/core/Models/Creditnoteothercharges';
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { KeyPress } from 'src/assets/js/KeyPress';

@Component({
  selector: 'app-c-creditnote',
  templateUrl: './c-creditnote.component.html',
  styleUrls: ['./c-creditnote.component.css']
})
export class CCreditnoteComponent implements OnInit {
  _IsSaveHidden: boolean = false;
  _IsClearHidden: boolean = false;
  _Creditnoteform: FormGroup;
  _creditnotetransactionform: FormGroup;
  _Creditnotedetailform: FormGroup;
  _Creditnoteid: string;
  _Creditnote: Creditnote;
  _Creditnotedetail: Creditnotedetail;
  _CreditnoteOBJ: any;
  // For DropDown
  _Currencies: Currency;
  _CurrenciesTemp: Currency[];
  _accounts: Accountledger[];
  _EvidenceTypes: Metadatum[];
  _EvidenceNumbers: Metadatum[];
  _Partytypes: Metadatum[];
  _status: Metadatum[];
  // For Grid
  _TransactionDetails: Creditnotegettransactiondetailsview[] = [];
  _TransactionDetail: Creditnotegettransactiondetailsview;
  selectedrows: Creditnotegettransactiondetailsview[] = [];
  // For Poppup
  _CreditReferences: Creditnotegettransactionview[];
  _CreditReference: Creditnotegettransactionview;
  selectedReferenceNo: any;
  _customers: Customer[];
  _action: string = "create";
  _submitted = false;
  displayBasic: boolean;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["BranchID"];
  _IsProgressSpinner: boolean = true;
  _creditnoteOBJ: any;

  datepipe = new DatePipe("en-Us");
  creditnoteothercharges: Creditnoteothercharge[] = [];
  _Descriptions: Othercharge[] = [];
  _ChargesTemp: Othercharge[] = [];
  _OtherBalance: number;
  grossamount: number;
  otherchargesamount: number;
  netamount: number;
  taxamount: number;
  selectedOtherChargesRows: any;
  VatType: string;
  mindate: Date;
	maxdate: Date;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _CreditnoteService: CreditnoteService,
    //_CreditnoteService = environment.CreditnoteService
    private utility: CommonUtilities,
    private _router: Router,
    private _hotkeysService: HotkeysService,
    public keypress: KeyPress) {
  }


  InitializeForm() {
    this._Creditnoteform = this.fb.group({
      creditnoteid: [0],
      voucherno: [0],
      creditnotedate: ['', vSelectCreditnoteDate],
      partytypeid: ['', vSelectPartyType],
      accountid: ['', vSelectAccountName],
      refNo: ['', vSelectReferenceNo],
      currencyuniquerefid: [1],
      currentcurrencyid: ['', vSelectCurrency],
      exchangerate: [],
      amountinusd: [0],
      creditnotestatusid: [1],
      creditnotestatusdesc: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      branchid: [sessionStorage["BranchID"]],
      invoicedate: [],
      salesinvoiceamount: [],
      duedate: [],
      customerid: [],
      customername: [],
      series: [],
      invoiceamount: [],
      transactionincompleted: [1],
    });
    if (sessionStorage['Environmentenddate'] == "null" ) {
			this._Creditnoteform.get("creditnotedate").setValue(new Date);
		  }
		  else {
			let date = new Date(sessionStorage['Environmentenddate'])
			this._Creditnoteform.get("creditnotedate").setValue(date);
		  }
    this._creditnotetransactionform = this.fb.group({
      salesinvoiceno: [],
      salesinvoicedate: [],
      customerid: [],
      customername: [],
      amountinusd: [],
      taxamount: [],
      series: [],
      evidenceno: ["sales"]

    });
    this._Creditnotedetailform = this.fb.group({
      creditnotedetailid: [],
      creditnoteid: [],
      invoiceno: [],
      invoicedate: [],
      productid: [],
      quantity: [],
      returnquantity: [],
      unitprice: [],
      totalamount: [],
      creditamount: [],
      reason: [],
      taxPer: [],
      taxAmount: [],
      taxId: [],
      discountPer: [],
      discountAmount: [],
    });
  }


  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();

    this._Creditnoteid = history.state?.creditnoteid ? history.state?.creditnoteid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    console.log("credit Note on Create", this._Creditnoteid);
    this.HotKeyIntegration();
    const data = sessionStorage.getItem('LedgerData');
		if (data) {
			const parsedData = JSON.parse(data);
			this._userid = parsedData.userid;
			this._Creditnoteid = parsedData.voucherno;
			this._action = parsedData.action;
			sessionStorage.removeItem('LedgerData');
		  }
    this._IsProgressSpinner = true;
    this._CreditnoteService.PageOnLoad(this._userid, this._Creditnoteid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("pageload", resultJSON);
      // For DropDown
      this._Currencies = resultJSON.currencies;
      this._status = resultJSON.recordStatus;
      this._Partytypes = resultJSON.partyTypes || [];
      this._accounts = resultJSON.accountledgers;
      this._customers = resultJSON.customers;
      this._Descriptions = resultJSON.othercharges;
      this._ChargesTemp = resultJSON.othercharges;

      this._IsProgressSpinner = false;
      if (this._Partytypes.length)
        this._Creditnoteform.get("partytypeid").setValue(this._Partytypes[0].metasubid);
      // For Exchange Rate
      this._CurrenciesTemp = resultJSON.currencies || [];
      // For Grid
      //Other Charges 
      if (this._action == 'create') {
        var l_othercharge = new Creditnoteothercharge();
        l_othercharge.creditnoteotherchargesdetailid = 0;
        l_othercharge.creditnoteid = 0;
        l_othercharge.otherchargeid = 0;
        l_othercharge.otherchargeamount = 0;
        l_othercharge.createdby = sessionStorage["userid"];
        l_othercharge.modifiedby = sessionStorage["userid"];
        l_othercharge.createdon = new Date();
        l_othercharge.modifiedon = new Date();
        l_othercharge.accountingyear = new Date().getFullYear();
        this.creditnoteothercharges.push(l_othercharge);
      }
      if (this._action == 'view') {

        this._Creditnoteform.disable();

        const updateJSON = JSON.parse(JSON.stringify(result));

        console.log("view pageload", updateJSON);

        updateJSON.creditnoteheaderviews[0].creditnotedate = new Date(updateJSON.creditnoteheaderviews[0].creditnotedate);
        let salesinvoicedate = this.datepipe.transform(updateJSON.creditnoteheaderviews[0].salesinvoicedate, "dd/MMM/yyyy");
        this._TransactionDetails = updateJSON.creditnotedetailsviews;
        this.selectedrows = updateJSON.creditnotedetailsviews;
        this._Creditnoteform.setValue(updateJSON.creditnoteheaderviews[0]);
        this.creditnoteothercharges = updateJSON.creditnoteotherchargesviews || [0]


        this._creditnotetransactionform.controls['amountinusd'].setValue(updateJSON.creditnoteheaderviews[0].salesinvoiceamount);
        this._creditnotetransactionform.controls['salesinvoicedate'].setValue(salesinvoicedate);
        this._creditnotetransactionform.controls['customername'].setValue(updateJSON.creditnoteheaderviews[0].customername);

        this.Visiblity(true);
        this.CalculateotherCharge();
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ChangeCurrencies(event): void {
    var exchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].exchangerate;
    this._Creditnoteform.controls['exchangerate'].setValue(exchangerate);
  }
  onSave() {
    console.log("Submitted");
    console.log(this._action);
    this._submitted = true;

    console.log("test");
    let errMsg = '';
    let isDataImproper = this.selectedrows.some(s => s.creditamount <= 0);
    let isQuantityProper = this.selectedrows.some(s => s.returnquantity > s.quantity);
    if (isDataImproper) {
      errMsg = "Credit Amount must be greater than zero in all rows";
    }
    if (!this.selectedrows.length) {
      errMsg = "Please select Atleast one row in a grid.";
    }
    if (isQuantityProper) {
      errMsg = "Return Quantity must not greater than Invoice Quantity";
    }

    if (this.ShowErrorMsg(errMsg))
      return;

    var l_transactiondetails: Creditnotedetail[] = [];
    Object.keys(this.selectedrows).map((Index) => {
      var currentRow = this.selectedrows[Index];
      console.log("current row", currentRow);
      var l_creditnotedetails = new Creditnotedetail();
      l_creditnotedetails.creditnotedetailid = currentRow.creditnotedetailid || 0;
      l_creditnotedetails.creditnoteid = this._Creditnoteform.get("creditnoteid").value;
      l_creditnotedetails.creditamount = currentRow.creditamount;
      l_creditnotedetails.totalamount = currentRow.totalamount;
      l_creditnotedetails.invoicedate = currentRow.invoicedate;
      l_creditnotedetails.invoiceno = currentRow.invoiceno;
      l_creditnotedetails.quantity = currentRow.quantity;
      l_creditnotedetails.taxPer = currentRow.taxPer;
      l_creditnotedetails.discountPer = currentRow.discountPer;
      l_creditnotedetails.taxAmount = currentRow.taxamount;
      l_creditnotedetails.taxId = currentRow.taxid;
      l_creditnotedetails.productamount = currentRow.unitprice * currentRow.returnquantity;
      l_creditnotedetails.discountAmount = currentRow.discountamount;
      l_creditnotedetails.returnquantity = currentRow.returnquantity;
      l_creditnotedetails.productid = currentRow.productid;
      l_creditnotedetails.unitprice = currentRow.unitprice;
      l_creditnotedetails.reason = currentRow.reason;
      l_transactiondetails.push(l_creditnotedetails);
      // console.log("credit note", l_creditnotedetails);
    })
    // this._TransactionDetails = l_transactiondetails;
    // console.log("tracsaction", l_transactiondetails);
    if (this.creditnoteothercharges.length > 0) {
      if (this.creditnoteothercharges[0].otherchargeamount == 0 || null) {
        this.creditnoteothercharges = [];
      }
    }
    if (this._Creditnoteform.valid) {
      console.log("test123");
      this._Creditnotedetailform.get("creditnoteid").setValue(this._Creditnoteform.get("creditnoteid").value);
      this._Creditnote = this._Creditnoteform.value;
      this._Creditnotedetail = this._Creditnotedetailform.value;
      this._creditnoteOBJ = { "Creditnote": this._Creditnote, "Creditnotedetail": l_transactiondetails, "Creditnoteothercharge": this.creditnoteothercharges };
      console.log(this._creditnoteOBJ);

      if (this._action == 'create') {
        console.log("IN");
        this._Creditnote.createdon = new Date();
        this._Creditnote.createdby = sessionStorage["userid"];
        this._Creditnote.branchid = sessionStorage["BranchID"];
        this._Creditnote.accountingyear = new Date().getFullYear();
        //this.onEd();
        this.Visiblity(true);
        this._IsProgressSpinner = true;
        this._CreditnoteService.create(this._creditnoteOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this.Visiblity(false);
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this.Visiblity(true); this._IsProgressSpinner = false; });
      }
    }

  }
  //Other Charges:
  AddOtherCharges(event) {
    let otherChargesExists = (this.creditnoteothercharges.filter(s => s.otherchargeid > 0).length == this.creditnoteothercharges.length) && this.creditnoteothercharges.some(s => s.otherchargeid > 0);
    if (!otherChargesExists)
      return;
    var l_othercharge = new Creditnoteothercharge();
    l_othercharge.otherchargeid = 0;
    l_othercharge.creditnoteid = 0;
    l_othercharge.creditnoteotherchargesdetailid = 0;
    l_othercharge.otherchargeamount = 0;
    l_othercharge.createdby = sessionStorage["userid"];
    l_othercharge.modifiedby = sessionStorage["userid"];
    l_othercharge.createdon = new Date();
    l_othercharge.modifiedon = new Date();
    this.creditnoteothercharges.push(l_othercharge);
  }
  //Remove Other Charges:
  RemoveOtherCharges(pOthercharges: Creditnoteothercharge) {
    // if (this.salesreturnothercharges.length > 1) {
    var index = this.creditnoteothercharges.indexOf(pOthercharges);
    this.creditnoteothercharges.splice(index, 1)
    this.CalculateotherCharge();
    if (this.creditnoteothercharges.length <= 0) {
      var l_othercharge = new Creditnoteothercharge();
      l_othercharge.otherchargeid = 0;
      l_othercharge.creditnoteid = 0;
      l_othercharge.creditnoteotherchargesdetailid = 0;
      l_othercharge.otherchargeamount = 0;
      l_othercharge.createdby = sessionStorage["userid"];
      l_othercharge.modifiedby = sessionStorage["userid"];
      l_othercharge.createdon = new Date();
      l_othercharge.modifiedon = new Date();
      this.creditnoteothercharges.push(l_othercharge);

    }
  }
  // }
  OnChangeotherCharge(row: any, event) {
    let OtherChargesAmount = row.otherchargeamount;
    // this._salesreturnform.controls['salesreturndeductionamount'].setValue(OtherChargesAmount.toFixed(3));
    this.CalculateotherCharge();
  }
  //Rule18:if invoice no dropdown selected value from sales ordertable then sales orderno column  assigned to referno  in grid based on invoiceno selection
  OnchangeOtherCharge(pOtherCharges: Creditnoteothercharge, event: any) {

    let isAlreadyExists = this.creditnoteothercharges.filter(s => s.otherchargeid == event.value).length > 1;
    if (isAlreadyExists) {
      this._CustomExceptionService.handleWarning("Other Charges Already Exists!");
      var index = this.creditnoteothercharges.indexOf(pOtherCharges);
      this.creditnoteothercharges.splice(index, 1)
      return;
    }
    var otherchargeamount = (this._ChargesTemp || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount;
    console.log(pOtherCharges);
    console.log(otherchargeamount);
    pOtherCharges.otherchargeamount = otherchargeamount;
    this.CalculateotherCharge();
  }
  CalculateotherCharge() {
    this._OtherBalance = 0;
    this.netamount = 0;
    this.taxamount = 0;
    this.otherchargesamount = 0;
    for (var i = 0; i < this.creditnoteothercharges.length; i++) {
      this._OtherBalance += this.creditnoteothercharges[i].otherchargeamount;

    }
    this.otherchargesamount = parseFloat(this._OtherBalance.toFixed(3));

    var GrossAmount: number = 0
    var FinalAmount: number = 0;
    var TaxAmount: number = 0;
    for (var i = 0; i < this.selectedrows.length; i++) {
      GrossAmount += this.selectedrows[i].unitprice * this.selectedrows[i].returnquantity;
      FinalAmount += this.selectedrows[i].creditamount;
      TaxAmount += this.selectedrows[i].taxamount;
    }
    this.grossamount = parseFloat(GrossAmount.toFixed(3));
    this.taxamount = parseFloat(TaxAmount.toFixed(3));
    //this.netamount  =this.otherchargesamount + FinalAmount;
    //Paaventhan Changes on 16-08-2022
    //this.netamount = parseFloat(FinalAmount.toFixed(3)) - parseFloat(this.otherchargesamount.toFixed(3));

    let netamount = (FinalAmount - this.otherchargesamount);

    this.netamount = parseFloat(netamount.toFixed(3));
  }

  onRowSelect(event) {
    console.log("Selected Suppliers");
    console.log(this.selectedrows);
    event.data.enableQuantity = true;
    event.data.enableReason = true;
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
    event.data.enableQuantity = false;
    event.data.enableReason = false;
    event.data.returnquantity = 0;
    event.data.reason = '';
    var _creditAmount = event.data.unitprice * event.data.returnquantity;

    if (this.VatType == "MSC00063") {
      event.data.discountamount = (_creditAmount * (event.data.discountPer / 100));
      event.data.taxamount = (_creditAmount * (event.data.taxPer / 100));
      event.data.creditamount = (_creditAmount + event.data.taxamount) - (_creditAmount * (event.data.discountPer / 100))
    }
    else if (this.VatType == "MSC00062") {
      event.data.discountamount = (_creditAmount * (event.data.discountPer / 100));
      event.data.taxamount = (_creditAmount * (event.data.taxPer / 100));
      event.data.creditamount = _creditAmount - (_creditAmount * (event.data.discountPer / 100))
    }
    else {
      event.data.creditamount = _creditAmount
    }
    this.CalculateotherCharge();
  }
  showBasicDialog() {
    this.displayBasic = true;
  }
  onRowSelectPopup(event: any) {

    var row = event.data;
    this._Creditnoteform.controls['refNo'].setValue(row.salesinvoiceno);
    this._creditnotetransactionform.controls['salesinvoicedate'].setValue(row.salesinvoicedate);
    this._creditnotetransactionform.controls['customername'].setValue(row.customername);
    this._Creditnoteform.controls['currentcurrencyid'].setValue(row.currencyid);
    this._creditnotetransactionform.controls['amountinusd'].setValue(row.amountinusd);

    this._IsProgressSpinner = true;
    this._CreditnoteService.getTransactionDetails(row.salesinvoiceno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("popup and grid view", resultJSON);
      this._TransactionDetails = resultJSON.creditnotegettransactiondetailsviews;

      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    this.displayBasic = false;
  }
  onRowUnSelectPopup(event: any) {
    this._Creditnoteform.controls['refNo'].setValue(null);
    this._creditnotetransactionform.controls['salesinvoicedate'].setValue(null);
    this._creditnotetransactionform.controls['customername'].setValue(null);
    this._creditnotetransactionform.controls['amountinusd'].setValue(null);
    this._TransactionDetails = [];
    this.displayBasic = false;
  }
  // onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._creditnoteOBJ;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }
  reset(event) {
    this._Creditnoteform.reset();
    this._TransactionDetails = [];
    this.creditnoteothercharges = [];
    var l_othercharge = new Creditnoteothercharge();
    l_othercharge.creditnoteotherchargesdetailid = 0;
    l_othercharge.creditnoteid = 0;
    l_othercharge.otherchargeid = 0;
    l_othercharge.otherchargeamount = 0;
    l_othercharge.createdby = sessionStorage["userid"];
    l_othercharge.modifiedby = sessionStorage["userid"];
    l_othercharge.createdon = new Date();
    l_othercharge.modifiedon = new Date();
    l_othercharge.accountingyear = new Date().getFullYear();
    this.creditnoteothercharges.push(l_othercharge);
    this.grossamount = 0;
    this.netamount = 0;
    this.otherchargesamount = 0;
    this.InitializeForm();
    this._submitted = false;
    this._CreditReferences = [];
    this.selectedrows = [];
    if (this._Partytypes.length)
      this._Creditnoteform.get("partytypeid").setValue(this._Partytypes[0].metasubid);
  }
  goBack(event) {
    console.log("goBack");
    this._router.navigate(['/vCreditnote']);
  }
  calcuateCreditAmount(Invoice: Creditnotegettransactiondetailsview) {

    Invoice.creditamount = 0;
    let errMsg = '';
    if (Invoice.returnquantity > Invoice.quantity) {
      Invoice.returnquantity = 0;
      errMsg = "Return Quantity must not greater than Invoice Quantity";
    }
    if (this.ShowErrorMsg(errMsg))
      return;
    var _creditAmount = Invoice.unitprice * Invoice.returnquantity;
    // Invoice.productamount = Invoice.unitprice * Invoice.returnquantity;

    // Invoice.discountamount = (_creditAmount * (Invoice.discountPer / 100));
    // Invoice.taxamount = (_creditAmount * (Invoice.taxPer / 100));
    // Invoice.creditamount = _creditAmount + (_creditAmount * (Invoice.taxPer / 100)) - (_creditAmount * (Invoice.discountPer / 100))

    if (this.VatType == "MSC00063") {
      Invoice.discountamount = (_creditAmount * (Invoice.discountPer / 100));
      Invoice.taxamount = (_creditAmount * (Invoice.taxPer / 100));
      Invoice.creditamount = (_creditAmount + Invoice.taxamount) - (_creditAmount * (Invoice.discountPer / 100))
    }
    else if (this.VatType == "MSC00062") {
      Invoice.discountamount = (_creditAmount * (Invoice.discountPer / 100));
      Invoice.taxamount = (_creditAmount * (Invoice.taxPer / 100));
      Invoice.creditamount = _creditAmount - (_creditAmount * (Invoice.discountPer / 100))
    }
    else {
      Invoice.creditamount = _creditAmount
    }

    this.CalculateotherCharge();
  }

  GetCustomerBasedEvidenceNo($event) {
    let userid = sessionStorage["userid"];
    this._IsProgressSpinner = true;
    let account = this._accounts.find(f => f.ledgerid == $event.value)
    let customer = this._customers.find(f => f.customerid == account.partycode);

    if (customer) {
      this._Creditnoteform.get("currentcurrencyid").setValue(customer.currencyid);
    }

    this._CreditnoteService.GetCustomerBasedEvidenceNo($event.value, userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CreditReferences = resultJSON.creditnotegettransactionviews;
      this.VatType = resultJSON.creditnotegettransactionviews[0].vattype || "";
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  Visiblity(ishide: boolean) {
    this._IsSaveHidden = ishide;
    this._IsClearHidden = ishide;
  }

  ShowErrorMsg(errMsg) {
    if (!errMsg)
      return false;

    this.messageService.add({ severity: 'error', summary: 'Error Message', sticky: true, detail: errMsg });
    return true;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

}
