import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptproductpricehistoryService {

  public _Action: IAction;
  public _httpOptions;
  public _RptProductPriceHistoryService = environment.RptProductPriceHistoryService;
  token: any;
  header: any;


  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  // Page Load :
  PageOnload(): Observable<any> {
    const headers = this.header;
    var Query = this._RptProductPriceHistoryService + '/PageInitialize';

    return this.http.get<{ data: any }>(Query, { headers });
  }

  getProductCategory(producttype: object) {
    const headers = this.header;
    var Query = this._RptProductPriceHistoryService + '/getProductCategories';

    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }
  getProduct(productcategory: object) {
    const headers = this.header;
    var Query = this._RptProductPriceHistoryService + '/getProducts';

    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }

  getProductPriceHistory(productcategory: object) {
    const headers = this.header;
    var Query = this._RptProductPriceHistoryService + '/getPriceHistory';

    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }



}
