import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class QuotefinalizeService {
  public _Action: IAction;
  public _QuotefinalizeService = environment.QuotefinalizeService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  //Fetch All function call:
  FetchAll(userid: number, branchid: number,roleid:number,fromdate:string,todate:string) {
    const headers = this.header;
    var Query = this._QuotefinalizeService + '/FetchAll' + '/' + userid + '/' + branchid + '/' + roleid + '/'+fromdate + '/' + todate;
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      return data;
    }));
  }

  // Page Load :
  // p_productid: number,
  PageOnload(supplierquotesno:number,branchid:number): Observable<any> {
    const headers = this.header;
    var Query = this._QuotefinalizeService + '/PageInitialize/' + supplierquotesno + '/'+ branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  //To Get Product Variant :
  getQuoteDetails(p_productid: number) {
    const headers = this.header;
    var Query = this._QuotefinalizeService + '/OnProductNameChange'+ '/'+ p_productid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  //Save Function:
  create(p_supplierdetails: object) {
    const headers = this.header;
    var Query = this._QuotefinalizeService+'/Create';
    return this.http.post<{ data: any }>(Query,p_supplierdetails, { headers });
  }

  SendToApproval(userid: number, branchid: number,Quoterequestno: number,supplierquoteno:string) {
    const headers = this.header;
    var Query = this._QuotefinalizeService + '/SendToApproval' + '/' + userid + '/' + branchid + '/' + Quoterequestno + '/' +supplierquoteno ;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  GetProduct(product:string,branchid:number){
    const headers = this.header;
    var Query = this._QuotefinalizeService + '/GetProduct' + '/' + product+ '/' +branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  Cancel(supplierquotesid : number): Observable<any>
  { 
    const headers = this.header;
    var Query = this._QuotefinalizeService  +'/Cancel' + '/'  + supplierquotesid ;
    return this.http.get<{data:any}>(Query, { headers });
  }
}
