import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Currency } from 'src/app/core/Models/Currency';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Supplier } from 'src/app/core/Models/Supplier';
import { RptposummaryService } from 'src/app/core/Services/reports/Purchase/Rptposummary/rptposummary.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
@Component({
  selector: 'app-rptposummary',
  templateUrl: './rptposummary.component.html',
  styleUrls: ['./rptposummary.component.css']
})
export class RptposummaryComponent implements OnInit {
  _supplierlist: Supplier[];
  _branchlist: Branch[];
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _PurchasePrintObj: any;
  _posummaryform: FormGroup;
  _branchid: number;
  _userid: number;
  _src: SafeResourceUrl;
  _IsProgressSpinner: boolean = true;
  _currencylist: Currency[] = [];
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  _yearlist = [{ year: '2020' }, { year: '2021' }, { year: '2022' }, { year: '2023' }, { year: '2024' }, { year: '2025' }
    , { year: '2026' }, { year: '2027' }, { year: '2028' }, { year: '2029' }, { year: '2030' }, { year: '2031' }];
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptposummaryService: RptposummaryService,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.SetFromToDate();
    this._posummaryform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      currencyid: [0],
      year: []
    })
    this._branchid = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    this._IsProgressSpinner = true;
    this._posummaryform.controls['fromdate'].setValue(new Date());
    this._RptposummaryService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._supplierlist = resultJSON.supplierList;
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._producttypelist = resultJSON.producttypeList;
      this._posummaryform.controls['year'].setValue(this._yearlist[1].year);
      this._posummaryform.controls['branchid'].setValue(this._branchlist[0].branchid);
      this._posummaryform.controls['currencyid'].setValue(this._currencylist[0].currencyid);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    
  }

  SetFromToDate(){
    if(sessionStorage['Environmentenddate'] === 'null') {
      const currentYear = new Date().getFullYear();
      this._yearlist = this._yearlist.filter(yearObj => parseInt(yearObj.year) > 2022 && parseInt(yearObj.year) <= currentYear);
    } else {
      const endDateYear = new Date(sessionStorage['Environmentenddate']).getFullYear();
      this._yearlist = this._yearlist.filter(yearObj => parseInt(yearObj.year) <= endDateYear);
    }
  }

  clear(event) {
    this._posummaryform.reset();
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  GenerateReport(event) {
    var year = this._posummaryform.get("year").value;
    var branchid = this._posummaryform.get("branchid").value;
    var currencyid = this._posummaryform.get("currencyid").value;
    if (year == null) {
      this._CustomExceptionService.handleError("Please Select Year");
      return;
    }
    if (currencyid == null) {
      this._CustomExceptionService.handleError("Please Select Currency");
      return;
    }
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this._PurchasePrintObj = {
      "Productheader": this.productid || [],
      "year": year,
      "branchid": branchid || parseInt(sessionStorage["currentbranchid"]),
      "currencyid": currencyid,
      "Userid": this._userid
    };
    var mediaType = 'application/pdf';
    this._RptposummaryService.Print(this._PurchasePrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptposummaryService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("category", resultJSON);
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptposummaryService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
}