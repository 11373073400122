import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { StockallocationService } from 'src/app/core/Services/inventory/stockallocation.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Stockallocationview } from 'src/app/core/Views/Stockallocationview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';

@Component({
  selector: 'app-v-stockallocation',
  templateUrl: './v-stockallocation.component.html',
  styleUrls: ['./v-stockallocation.component.css']
})
export class VStockallocationComponent implements OnInit {
  _Action: IAction;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _branchid: number;
  _UserId: number;
  _userid:number;
  _roleid:number;
  selectedRows: any;
  _IsProgressSpinner: boolean = true;
  cols: any[];
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _stockallocationviews: Stockallocationview[];
  mindate: Date;
  maxdate: Date;
  constructor(private fb: FormBuilder, private _StockallocationService: StockallocationService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService,private exportUtility: ExportUtility, 
    private _CustomExceptionService: CustomExceptionService,private _hotkeysService: HotkeysService,
    private confirmationService: ConfirmationService,
    ) { }


  ngOnInit(): void {
    this._IsProgressSpinner = true;
    this._Action = this._AccessRightsService.getAccessRights(89);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();  
     
    this.cols = [
      { field: 'reserveno', header: 'Reserve No' },
      { field: 'frombranchname', header: 'From Branch Name' },
      { field: 'tobranchname', header: 'To Branch Name' },
      { field: 'productname', header: 'Product Name' },
      { field: 'variantdescription', header: 'Variant Name' },     
      { field: 'quantity', header: 'Quantity' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },  
    ]
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
     if (sessionStorage['Environmentenddate'] == "null" ) {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }

  this.Fetchall();
  }
  Fetchall(){
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid=sessionStorage["userid"];
    this._roleid=sessionStorage["currentRole"];
    this._StockallocationService.FetchAll(this._userid, this._branchid,this._roleid,fromdate,todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._stockallocationviews = resultJSON.stockallocationviews;
      this._IsProgressSpinner = false;
    },  
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/cstockallocation'], { state: { reserveid: event.reserveid, action: 'view' } });
  }

  GoToCreate(event){
    this.router.navigate(['/cstockallocation']);
  }

  exportExcel() {
    let StockAlocationList = this.GetStockAllocationList();
    this.exportUtility.exportExcel(StockAlocationList, 'Stockallocation', 'xlsx');
  }

  ExportToPdf() {
    let StockAlocationList = this.GetStockAllocationList();
    this.exportUtility.ExportToPdf(StockAlocationList, 'Stockallocation.pdf');
  }
   

  GetStockAllocationList() {
    let StockAlocationList = [];
    this._stockallocationviews.forEach(element => {
      let stockallocation: any = {};
      stockallocation["Reserve No"] = element.reserveno;
      stockallocation["From Branch Name"] = element.frombranchname;
      stockallocation["To Branch Name"] = element.tobranchname;
      stockallocation["Product Name"] = element.productname;
      stockallocation["Variant Name"] = element.variantdescription;
      stockallocation["Quantity"] = element.quantity;
      stockallocation["Created By"] = element.createdbyname;
      stockallocation["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      StockAlocationList.push(stockallocation);
    });
    return StockAlocationList;
  }

  Print(event) {
    var stockallocationno = event.reserveno;
    var branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;
    this._StockallocationService.Print(stockallocationno, branchid);
    this._IsProgressSpinner = false;
  }
  partialconfirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Stock Allocation <b>"' + event.reserveno + '"</b> ?' ,
      accept: () => {
        //Actual logic to perform a confirmation
        this.PartialCancel(event);
      }
    });
  }
  PartialCancel(event){
    console.log("function called")
    var stockallocationid = event.reserveno;
    this._IsProgressSpinner = true;
    let data = {
      "stockallocationid" : stockallocationid,
      "userid" : sessionStorage["userid"],
    }
    this._StockallocationService.Cancel(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.Fetchall();
      this._IsProgressSpinner = false;
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Stock Allocation <b>"' + event.reserveno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    var stockallocationid = event.reserveno;
    this._IsProgressSpinner = true;
    let data = {
      "stockallocationid" : stockallocationid,
      "userid" : sessionStorage["userid"],
    }
    this._StockallocationService.sacancel(data).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.Fetchall();
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

 //View
 HotKeyIntegration() {
  this._hotkeysService.reset();
  if (this._Action._Add) {
    this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
      this.GoToCreate(event);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

  
}
}
