import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { User } from 'src/app/core/Models/User';
import { OrderpendingStatusService } from 'src/app/core/Services/ecommerce/orderpending-status.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-order-pending-status',
  templateUrl: './v-order-pending-status.component.html',
  styleUrls: ['./v-order-pending-status.component.css']
})
export class VOrderPendingStatusComponent implements OnInit {

  defaultDate: Date;
  paymentPendingHistory
  viewTransactionList=[];
  viewTransaction=[];
  viewPaymentModeList=[];
  viewPaymentMode=[];
  datepipe = new DatePipe('en-Us');
  fromdate: Date;
  _IsProgressSpinner: boolean = false;
  todate: Date;
  Tellerid:any=0;
  _tellernames:User;
  _branchid = sessionStorage["currentbranchid"];
  _userid = sessionStorage["userid"]
  constructor(private router: Router,private utility:CommonUtilities,private _OrderPendingStatus:OrderpendingStatusService,private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService,public exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.fromdate = new Date();
    this.todate = new Date();
    this.PageOnload();
    
  
  }
PageOnload(){
  this._IsProgressSpinner = true;
  this._OrderPendingStatus.PageOnload().subscribe((result) => {
    const resultJSON = JSON.parse(JSON.stringify(result));
    this._tellernames=resultJSON.users;
    this._IsProgressSpinner = false;
  },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

}
FetchALL(){
 
  if (!this.fromdate) {
    this._CustomExceptionService.handleError("Please Select From Date");
    return;
  }
  if (!this.todate) {
    this._CustomExceptionService.handleError("Please Select To Date");
    return;
  }
  let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
  let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
  if (fromdate > todate) {
    this._CustomExceptionService.handleError("From Date should be less than the To Date");
    return;
  }
  this._IsProgressSpinner = true;
  this._OrderPendingStatus.FetchALL(fromdate,todate,this.Tellerid,this._branchid).subscribe((result) => {
    const resultJSON = JSON.parse(JSON.stringify(result));
    this.paymentPendingHistory=resultJSON.orderPendingStatusDetails;
    this._IsProgressSpinner = false;
  },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
}
}
