import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Vehicle } from 'src/app/core/Models/Vehicle';
import { Vehicledocumentdetail } from 'src/app/core/Models/Vehicledocumentdetail';
import { VehicleService } from 'src/app/core/services/masters/vehicle.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Employee } from 'src/app/core/Models/Employee';
import { vCapacity, vCapacitySelect, vDocumentName, vEngineNumber, vStatusSelect, vVehicleNumber, vVehicleTypeSelect } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUpload } from 'primeng/fileupload';
import { Documentdescription } from 'src/app/core/Models/DocumentDescription';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
  selector: 'app-c-vehicle',
  templateUrl: './c-vehicle.component.html',
  styleUrls: ['./c-vehicle.component.css']
})
export class CVehicleComponent implements OnInit {
  _Vehicleform: FormGroup;
  _Vehicledocumentdetailform: FormGroup;
  _action: string = "create";
  _submitted = false;
  _submitted1 = false;
  _vehicleid: number;
  _vehicledetailid: number;
  _Vehicle: Vehicle;
  _Vehicledocumentdetail: Vehicledocumentdetail;
  _VehicleOBJ: any;
  //6. vehicle type load from metadata (ATE40,T3500,etc... ) 
  //7. UOM load from metadata(kg,numbers,litres,etc..)
  //8. gps vendor load from supplier master(suplier code, supplier name)
  _vehicletypes: Metadatum;
  _UOMcapacities: Metadatum;
  _vehiclestatus: Metadatum;
  _vehiclerecordstatus: Metadatum;
  _gpssuppliers: Supplier;
  _vehiclesuppliers: Supplier;
  _drivers: Employee;
  _cleaners: Employee;
  _Documents: Vehicledocumentdetail[] = [];
  _Document: Vehicledocumentdetail;
  _DocumentDescriptions: Documentdescription[];
  displayBasic: boolean;
  position: string;
  minDate: Date;
  selectedrows: any;
  isButtonDisabled: boolean = true;
  _LastAddedDocument: File;
  _selectedDocuments: File[] = [];// For Documents uploads
  _selectedDocumentIndex: number[] = [];
  _IsProgressSpinner: boolean = true;
  isDisabledSave = false;
  isDisabledClear = false;
  isDisabledDelete = false;
  _branchid = sessionStorage["BranchID"];
  addressRowIndex: number = -1;
  documentimage: SafeUrl;
  displaydocumentgridpopup: boolean = false;
  _LastAddedGridDocument: File;
  _Action: IAction;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private messageService: MessageService, private _CustomExceptionService: CustomExceptionService,
    private _VehicleService: VehicleService, private utility: CommonUtilities, private _hotkeysService: HotkeysService,
    private _router: Router, private _AccessRightsService: AccessRightsService,
    private _DomSanitizationService: DomSanitizer) {
    this._vehicleid = history.state?.vehicleid ? history.state?.vehicleid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }

  InitializeForm() {
    this._Vehicleform = this.fb.group({
      companyid: [0],
      branchid: [sessionStorage["BranchID"]],
      vehicleid: [0],
      vehicleno: ['', vVehicleNumber],
      vehicletype: ['', vVehicleTypeSelect],
      capacity: ['', vCapacity],
      capacityuomcode: ['', vCapacitySelect],
      engineno: ['', vEngineNumber],
      chassisno: [],
      gpsno: [],
      gpssupplierid: [],
      vehiclesupplierid: [],
      averagemilage: [],
      meterreading: [],
      drivername: [],
      cleanername: [],
      vehiclestatus: ['MSC00125', vStatusSelect],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],

      vehicledocumentdetails: []
    });
    this.InitializeDocumentForm();
  }
  InitializeDocumentForm() {
    this._Vehicledocumentdetailform = this.fb.group({
      vehicledocumentdetailid: [],
      vehicleid: [],
      //vehicleno: [],
      noofalerts: [],
      effectivefrom: [],
      effectiveto: [],
      documentname: ['', vDocumentName],
      remarks: [],
      attachmenturl: [],
      attachmentname: [],
      documentstatus: ['MSC00001'],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      vehicle: []
    });
    this._Vehicledocumentdetailform.controls['effectivefrom'].setValue(new Date());
    this._Vehicledocumentdetailform.controls['effectiveto'].setValue(new Date());
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(20);
    this.utility.pageLoadScript();

    this.InitializeForm();

    this._IsProgressSpinner = true;
    if (this._action == 'view') {
      this._Vehicleform.disable();
      this._Vehicledocumentdetailform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
      this.isDisabledDelete = true;
    }
    if (this._action == 'edit') {
      this.isDisabledClear = true;
      this.isDisabledSave = false;
      this.isDisabledDelete = false;

    }

    // if( this._action == 'create' ){
    // this._Vehicleform.get("recordstatus").disable();
    // }
    this._VehicleService.PageOnLoad(this._vehicleid, this._branchid).subscribe((result) => {
      // this.hideInCreatePage=true; 
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._vehiclestatus = resultJSON.vehicleStatus;
      this._vehiclerecordstatus = resultJSON.recordStatus;
      this._vehicletypes = resultJSON.vehicletypes;
      this._UOMcapacities = resultJSON.vehicleUom;
      this._cleaners = resultJSON.cleanerNames;
      this._drivers = resultJSON.driverNames;
      this._gpssuppliers = resultJSON.gpsSuppliers;
      this._vehiclesuppliers = resultJSON.vehicleSuppliers;
      this._DocumentDescriptions = resultJSON.documentdescriptions;
      this._Vehicledocumentdetailform.controls['effectivefrom'].setValue(new Date());
      this._Vehicledocumentdetailform.controls['effectiveto'].setValue(new Date());
      this._IsProgressSpinner = false;

      //For Update
      this._vehicleid = history.state.vehicleid;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));

        this._Vehicleform.setValue(updateJSON.vehicle);
        // this._Vehicledocumentdetailform.setValue(updateJSON.vehicledocumentdetail);
        // return;
        // var DocumentDetailsArrayIndex: any[] = [];
        // var selectedDocumentObj = updateJSON.vehicledocumentdetails;
        // Object.keys(selectedDocumentObj).map(function (index) {
        //   DocumentDetailsArrayIndex.push(selectedDocumentObj[index].vehicledocumentdetailid);
        // });
        // this._selectedDocumentIndex = DocumentDetailsArrayIndex;
        this._Documents = updateJSON.vehicledocumentdetails;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSave() {
    this._submitted = true;
    // this.FillDocumentDetails();

    if (this._Vehicleform.valid) {
      this._Vehicledocumentdetailform.get("vehicleid").setValue(this._Vehicleform.get("vehicleid").value);
      this._Vehicle = this._Vehicleform.value;
      this._Vehicledocumentdetail = this._Vehicledocumentdetailform.value;


      if (this._action == 'create') {
        // this._Vehicle.accountingyear= new Date("YYYY").getFullYear(); 
        this._Vehicle.createdon = new Date();
        this._Vehicle.createdby = sessionStorage["userid"];

        this._VehicleOBJ = this.formconverstion(this._Vehicle, this._Documents, this._selectedDocuments);
        this._IsProgressSpinner = true;
        this._VehicleService.create(this._VehicleOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsProgressSpinner = false;
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        // this.onEd();
      }
      else if (this._action == 'edit') {
        this._Vehicle.modifiedon = new Date();
        this._Vehicle.modifiedby = sessionStorage["userid"];
        this._Vehicledocumentdetail.modifiedby = sessionStorage["userid"];
        this._Vehicledocumentdetail.modifiedon = new Date();

        this._VehicleOBJ = this.formconverstion(this._Vehicle, this._Documents, this._selectedDocuments);
        this._IsProgressSpinner = true;
        this._VehicleService.edit(this._VehicleOBJ).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
  // onRowVehicleselect(address: Vehicledocumentdetail, rowIndex: number) {
  //   if(this._action == 'view'){
  //     return;
  //   }
  //   address.effectivefrom = new Date(address.effectivefrom);
  //   address.effectiveto = new Date(address.effectiveto);
  //   this._Vehicledocumentdetailform.setValue(address);
  //   this.addressRowIndex = rowIndex;
  // }

  //Form Data Creation:
  formconverstion(p_Vehicle: Vehicle, p_documentdetails: Vehicledocumentdetail[], documents: File[]) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    if (documents.length > 0) {
      for (var x in documents) {
        if (documents[x] != null) {

          var index = p_documentdetails.findIndex(obj => obj.attachmentname == documents[x].name);
          if (index != -1) {
            var NewFileName = time.getTime() + '_' + documents[x].name;
            p_documentdetails[index].attachmentname = NewFileName;
            p_documentdetails[index].attachmenturl = NewFileName;
            formData.append('files', documents[x], NewFileName);
          }
        }
      }
    }
    var _VehicleOBJ = { "Vehicle": p_Vehicle, "Vehicledocumentdetail": p_documentdetails };
    var Vehicle = JSON.stringify(_VehicleOBJ);
    formData.append('strVehicle', Vehicle);
    return (formData);
  }

  reset(event) {
    this._submitted = false;
    this._submitted1 = false;
    this._Vehicleform.reset();
    this._Vehicledocumentdetailform.reset();
    this._selectedDocuments = [];
    this.InitializeForm();
    this.onClear();
    this._Documents = [];
    this.addressRowIndex = -1;
  }
  goBack(event) {

    this._router.navigate(['/vVehicle']);
  }

  showBasicDialog(position: string) {
    this.position = position;
    this.displayBasic = true;
  }
  //For Grid

  onAddDocuments($event) {
    this._submitted1 = true;
    // if (this.addressRowIndex > -1) {
    //   this._Documents[this.addressRowIndex] = this._Vehicledocumentdetailform.value;
    //   this._Documents[this.addressRowIndex].createdon = new Date();
    // } else {
    if (this._Vehicledocumentdetailform.valid && this._LastAddedGridDocument != null) {
      var documentdetail = new Vehicledocumentdetail();
      documentdetail.effectivefrom = this._Vehicledocumentdetailform.get("effectivefrom").value;
      documentdetail.effectiveto = this._Vehicledocumentdetailform.get("effectiveto").value;
      if (documentdetail.effectiveto && documentdetail.effectivefrom > documentdetail.effectiveto) {
        this._CustomExceptionService.handleWarning("Effective From Date should be less than the Effective to Date");
        return;
      }
      documentdetail.documentname = this._Vehicledocumentdetailform.get("documentname").value;
      documentdetail.noofalerts = this._Vehicledocumentdetailform.get("noofalerts").value;
      documentdetail.attachmentname = this._Vehicledocumentdetailform.get("attachmentname").value;
      documentdetail.attachmenturl = URL.createObjectURL(this._LastAddedGridDocument);
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedGridDocument));
      documentdetail.attachmentname = this._LastAddedGridDocument.name;
      documentdetail.vehicledocumentdetailid = 0;
      documentdetail.vehicleid = 0;
      documentdetail.remarks = this._Vehicledocumentdetailform.get("remarks").value;
      documentdetail.documentstatus = this._Vehicledocumentdetailform.get("documentstatus").value;
      documentdetail.createdby = sessionStorage["userid"];
      documentdetail.modifiedby = sessionStorage["userid"];
      documentdetail.modifiedon = new Date();
      documentdetail.createdon = new Date();
      // documentdetail.vehicle = null;
      this._Documents.push(documentdetail);
      this._selectedDocuments.push(this._LastAddedGridDocument);
      this.onClear();
      // }
    }
  }
  showdocumentDialog(document: FileUpload, position: string) {
    if (this._LastAddedGridDocument) {
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedGridDocument));
    }
    if (this.documentimage == null) {
      return;
    }
    this.position = position;
    this.displaydocumentgridpopup = true;
  }
  showdocumentGridDialog(position: string, _Document: any) {
    let url = '';
    if (_Document.attachmenturl) {
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(_Document.attachmenturl);
      url = _Document.attachmenturl;
    }
    if (url)
      window.open(url, "_blank");
  }

  //Document Select:
  onDocumentSelect(event, document) {
    this._LastAddedGridDocument = event.currentFiles[0];
    this._Vehicledocumentdetailform.get("attachmentname").setValue(this._LastAddedGridDocument.name);
    document.clear();
  }

  //Document Clear:
  onDocumentClear() {
    // this._submitted1 = false;
    this._LastAddedGridDocument = null;
    this._Vehicledocumentdetailform.reset();
    this.InitializeDocumentForm();
    this.documentimage = null;
    this.addressRowIndex = -1;
  }
  onClear() {
    this._submitted1 = true;
    this._LastAddedGridDocument = null;
    this._Vehicledocumentdetailform.reset();
    this.InitializeDocumentForm();
    this.documentimage = null;
    this.addressRowIndex = -1;
    this._submitted1 = false;
  }

  closePopupGridDialog(position: string) {
    this.position = position;
    this.displaydocumentgridpopup = false;
  }
  // Document Upload 

  //Remove Document in Grid:
  RemoveDocumentDetail(p_documentDetail: Vehicledocumentdetail) {
    var index = this._Documents.indexOf(p_documentDetail);
    this._Documents.splice(index, 1)
  }

  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  // onEd() {
  //   alert("test");
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._VehicleOBJ;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);

  // }
  OnFromDateSelect(event) {
    this.minDate = event;
  }

  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}
// addRow() {
  //   let filtered: varient = {Sno: '', VarientDescription: '' };
  //   this.VARIENTDETAILS.push(filtered);
  // }