import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { PettycashexpenceService } from 'src/app/core/Services/accounts/pettycashexpence.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-pettycash-expence',
  templateUrl: './v-pettycash-expence.component.html',
  styleUrls: ['./v-pettycash-expence.component.css']
})
export class VPettycashExpenceComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _PettycashExpensesviews:any[];
  _PettycashExpenseviews:any;
  _IsProgressSpinner: boolean = false;
  _branchid = sessionStorage["currentbranchid"];
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  cols: any[];
  mindate: Date;
  maxdate: Date;
  constructor(private router: Router, private utility: CommonUtilities, private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService, private _PettycashExpenceService: PettycashexpenceService, private exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(122);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.cols = [
      { field: 'expensedate', header: 'Expense Date' },
      { field: 'expenseamount', header: 'Expense Amount' },
      { field: 'settledby', header: 'Settled BY' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this.Fetchall();

  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    // this.HotKeyIntegration();
    this._PettycashExpenceService.FetchAll(this._branchid,fromdate,todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._PettycashExpensesviews = resultJSON.pettycashexpenseviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  GoToCreate(event) {
    this.router.navigate(['/cPettycashexpence']);
  }
  view(event) {
    this.router.navigate(['/cPettycashexpence'], { state: { pettycashexpenseid: event.pettycashexpenseid, action: 'view' } });
  }
  edit(event) {
    console.log(event);
    this.router.navigate(['/cPettycashexpence'], { state: { pettycashexpenseid: event.pettycashexpenseid, action: 'edit' } });
  }
  exportExcel() {
    let PettyCashExpenseList = this.GetPettyCashExpenseList();
    this.exportUtility.exportExcel(PettyCashExpenseList, 'PettycashExpense', 'xlsx');
  }
  ExportToPdf() {
    let PettyCashExpenseList = this.GetPettyCashExpenseList();
    const data = PettyCashExpenseList.map(Object.values);
    const head = [[
      'Expense Date',
      'Expense Amount',
      'Petty Cash Settlement No',
      'Account Name',
      'Settled By',
      'Created By',
      'Created On'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('PettycashExpense.pdf');
      })
    })
  }
  GetPettyCashExpenseList() {
    if (!this._PettycashExpensesviews || !this._PettycashExpensesviews.length)
    return;
    let PettyCashExpenseList = [];
    this._PettycashExpensesviews.forEach(element => {
      let PettyCashExpense: any = {};
      PettyCashExpense["Expense Date"] = this.datepipe.transform(element.expensedate, 'dd/MM/yyyy');
      PettyCashExpense["Expense Amount"] = element.expenseamount;
      PettyCashExpense["Petty Cash Settlement No"] = element.pettycashsettlementno;
      PettyCashExpense["Account Name"] = element.expenseaccountname;
      PettyCashExpense["Settled By"] = element.settledby;
      PettyCashExpense["Created By"] = element.createdbyname;
      PettyCashExpense["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      PettyCashExpenseList.push(PettyCashExpense);
    });
    return PettyCashExpenseList;
  }
}