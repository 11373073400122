import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class RptoutstandingService {

  public _Action: IAction;
  public _httpOptions;
  public _RptOutstandingService = environment.RptOutstandingService;
  constructor(private http: HttpClient) { }
  PageOnload(): Observable<any> {
    var Query = this._RptOutstandingService + '/PageInitialize';
    
    return this.http.get<{ data: any }>(Query);

  }
  Print(fromdate: string, todate: string,partytype:string,customerorsupplier:string,branchid:number,userid:number,currencyid:number) {
    var Query = this._RptOutstandingService + '/Print/' + fromdate + '/' + todate +'/' + partytype +'/'+customerorsupplier+'/'+ branchid+'/'+userid +'/' + currencyid;
    return this.http.get(Query,{responseType: 'blob'});
  }
  onchangepartytype(partytype:number,userid:number): Observable<any> {
    var Query = this._RptOutstandingService + '/onchangepartytype/'+partytype+ '/'+userid;
    
    return this.http.get<{ data: any }>(Query);

  }
}
