<div class="page-container dashboard-page scroll-y">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_supplierdashboardform">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar [showIcon]="true" name="fromdate" [monthNavigator]="true"
                                    [yearNavigator]="true" yearRange="2000:2030" formControlName="fromdate"
                                    (onSelect)="ProductWise($event)" (onSelect)="onChangePurchases($event)" dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="quoteDate">From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar [showIcon]="true" name="todate" [monthNavigator]="true"
                                    [yearNavigator]="true" yearRange="2000:2030" formControlName="todate"
                                    (onSelect)="ProductWise($event)" (onSelect)="onChangePurchases($event)" dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_producttype" optionLabel="producttypename"
                                    formControlName="producttypeid" optionValue="producttypeid"
                                    (onChange)="getProductCategory($event)" displaySelectedLabel=true>
                                </p-multiSelect>
                                <label for="producttypeid">Product Type <span class="hlt-txt">*</span></label>
                            </span>

                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_productcategory" formControlName="productcategoryid"
                                    optionLabel="categoryname" optionValue="productcategoryid"
                                    (onChange)="getProduct($event)" displaySelectedLabel=true>
                                </p-multiSelect>
                                <label for="productcategoryid">Product Category <span class="hlt-txt">*</span></label>
                            </span>

                        </td>
                        <td></td>
                    </tr>
                </table>
            </form>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="white">
                    <div class="widget-title page-title">
                        <div class="row">
                            <div class="col-md-8">
                                <h3>Product Wise Purchase Dashboard</h3>
                            </div>
                            <div class="col-md-4">
                                <div class="widget-filter text-right">
                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="Filter By"><i
                                                class="las la-filter"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup big collapse">
                                            <form [formGroup]="_supplierdashboardform">
                                                <table class="normal-table">
                                                    <tr>
                                                        <td>
                                                            <span class="p-float-label">
                                                                <p-autoComplete formControlName="branchid"
                                                                    [suggestions]="filterBranchs"
                                                                    (completeMethod)="filterBranch($event)"
                                                                    (onSelect)="ProductWise($event)" field="branchname"
                                                                    (onSelect)="OnChangeBranch($event)">
                                                                    <ng-template let-branch pTemplate="item">
                                                                        <div>{{branch.branchname}}</div>
                                                                    </ng-template>
                                                                </p-autoComplete>
                                                                <label for="branchid">Branch</label>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span class="p-float-label">
                                                                <p-autoComplete formControlName="supplierid"
                                                                    [suggestions]="filterSuppliers"
                                                                    (onSelect)="ProductWise($event)"
                                                                    (completeMethod)="filterSupplier($event)"
                                                                    field="suppliername">
                                                                    <ng-template let-supplier pTemplate="item">
                                                                        <div>{{supplier.suppliername}}</div>
                                                                    </ng-template>
                                                                </p-autoComplete>
                                                                <label for="supplierid">Supplier</label>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span class="p-float-label">
                                                                <p-multiSelect [options]="_productname"
                                                                    formControlName="productid"
                                                                    optionLabel="productname" optionValue="productid"
                                                                    (onChange)="ProductWise($event)"
                                                                    displaySelectedLabel=true>
                                                                </p-multiSelect>
                                                                <label for="productid">Product Name <span
                                                                        class="hlt-txt">*</span></label>

                                                            </span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </form>
                                        </div>
                                    </div>

                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="Choose Chart"><i
                                                class="las la-chart-bar"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                            <a (click)="chartchangepws('bar')"><i
                                                    class="icofont-chart-bar-graph"></i>Bar Chart</a>
                                            <a (click)="chartchangepws('pie')"><i class="icofont-pie-chart"></i>Pie
                                                Chart</a>
                                            <a (click)="chartchangepws('line')"><i class="icofont-chart-line"></i>Line
                                                Chart</a>
                                            <a (click)="chartchangepws('doughnut')"><i
                                                    class="icofont-chart-pie-alt"></i>Donut Chart</a>
                                        </div>
                                    </div>
                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                                class="las la-ellipsis-v"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                            <a href="javascript:void(0);"><i class="icofont-file-pdf"></i>Download
                                                as PDF</a>
                                            <a href="javascript:void(0);"><i class="icofont-file-excel"></i>Download
                                                as Excel</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="widget-body">
                        <div class="widget-details" *ngIf="_Isbarpws">
                            <p-chart type="bar" [data]="basicData" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Islinepws">
                            <p-chart type="line" [data]="basicData" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Ispiepws">
                            <p-chart type="pie" [data]="basicData" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Isdoughnutpws">
                            <p-chart type="doughnut" [data]="basicData" [options]="basicOptions"></p-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="white">
                    <div class="widget-title page-title">
                        <div class="row">
                            <div class="col-md-8">
                                <h3>Purchase Dashboard</h3>
                            </div>
                            <div class="col-md-4">
                                <div class="widget-filter text-right">
                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="Filter By"><i
                                                class="las la-filter"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup big collapse">
                                            <form [formGroup]="_suppliermonthwisedashboardform">
                                                <table class="normal-table">
                                                    <tr>
                                                        <td>
                                                            <span class="p-float-label">
                                                                <p-autoComplete formControlName="branchid"
                                                                    [suggestions]="filterBranchs"
                                                                    (completeMethod)="filterBranch($event)"
                                                                    (onSelect)="onChangePurchases($event)"
                                                                    field="branchname"
                                                                    (onSelect)="OnChangeBranch($event)">
                                                                    <ng-template let-branch pTemplate="item">
                                                                        <div>{{branch.branchname}}</div>
                                                                    </ng-template>
                                                                </p-autoComplete>
                                                                <label for="branchid">Branch</label>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span class="p-float-label">
                                                                <p-autoComplete formControlName="supplierid"
                                                                    [suggestions]="filterSuppliers"
                                                                    (onSelect)="onChangePurchases($event)"
                                                                    (completeMethod)="filterSupplier($event)"
                                                                    field="suppliername">
                                                                    <ng-template let-supplier pTemplate="item">
                                                                        <div>{{supplier.suppliername}}</div>
                                                                    </ng-template>
                                                                </p-autoComplete>
                                                                <label for="supplierid">Supplier</label>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </form>
                                        </div>
                                    </div>

                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="Choose Chart"><i
                                                class="las la-chart-bar"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                            <a (click)="chartchangesd('bar')"><i class="icofont-chart-bar-graph"></i>Bar
                                                Chart</a>
                                            <a (click)="chartchangesd('pie')"><i class="icofont-pie-chart"></i>Pie
                                                Chart</a>
                                            <a (click)="chartchangesd('line')"><i class="icofont-chart-line"></i>Line
                                                Chart</a>
                                            <a (click)="chartchangesd('doughnut')"><i
                                                    class="icofont-chart-pie-alt"></i>Donut Chart</a>
                                        </div>
                                    </div>
                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                                class="las la-ellipsis-v"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                            <a href="javascript:void(0);"><i class="icofont-file-pdf"></i>Download
                                                as PDF</a>
                                            <a href="javascript:void(0);"><i class="icofont-file-excel"></i>Download
                                                as Excel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="widget-body">
                        <div class="widget-details" *ngIf="_Isbarsd">
                            <p-chart type="bar" [data]="basicData1" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Islinesd">
                            <p-chart type="line" [data]="basicData1" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Ispiesd">
                            <p-chart type="pie" [data]="basicData1" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Isdoughnutsd">
                            <p-chart type="doughnut" [data]="basicData1" [options]="basicOptions"></p-chart>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="white">
                    <div class="widget-title page-title">
                        <div class="row">
                            <div class="col-md-8">
                                <h3>Outstanding Dashboard</h3>
                            </div>
                            <div class="col-md-4">
                                <div class="widget-filter text-right">
                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="Filter By"><i
                                                class="las la-filter"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup big collapse">
                                            <form [formGroup]="_supplieroutstandingdashboardform">
                                                <table class="normal-table">
                                                    <tr>
                                                        <td>
                                                            <span class="p-float-label">
                                                                <p-autoComplete formControlName="branchid"
                                                                    [suggestions]="filterBranchs"
                                                                    (completeMethod)="filterBranch($event)"
                                                                    (onSelect)="Outstanding($event)"
                                                                    field="branchname">
                                                                    <ng-template let-branch pTemplate="item">
                                                                        <div>{{branch.branchname}}</div>
                                                                    </ng-template>
                                                                </p-autoComplete>
                                                                <label for="branchid">Branch</label>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span class="p-float-label">
                                                                <p-autoComplete formControlName="supplierid"
                                                                    [suggestions]="filterSuppliers"
                                                                    (onSelect)="Outstanding($event)"
                                                                    (completeMethod)="filterSupplier($event)"
                                                                    field="suppliername">
                                                                    <ng-template let-supplier pTemplate="item">
                                                                        <div>{{supplier.suppliername}}</div>
                                                                    </ng-template>
                                                                </p-autoComplete>
                                                                <label for="supplierid">Supplier</label>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </form>
                                        </div>
                                    </div>

                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="Choose Chart"><i
                                                class="las la-chart-bar"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                            <a (click)="chartchangeod('bar')"><i class="icofont-chart-bar-graph"></i>Bar
                                                Chart</a>
                                            <a (click)="chartchangeod('pie')"><i class="icofont-pie-chart"></i>Pie
                                                Chart</a>
                                            <a (click)="chartchangeod('line')"><i class="icofont-chart-line"></i>Line
                                                Chart</a>
                                            <a (click)="chartchangeod('doughnut')"><i
                                                    class="icofont-chart-pie-alt"></i>Donut Chart</a>
                                        </div>
                                    </div>
                                    <div class="filter-container">
                                        <a href="javascript:void(0);" data-toggle="dropdown"
                                            data-toggle-second="tooltip" data-placement="top" title="More Actions"><i
                                                class="las la-ellipsis-v"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right widget-popup collapse text-left">
                                            <a href="javascript:void(0);"><i class="icofont-file-pdf"></i>Download
                                                as PDF</a>
                                            <a href="javascript:void(0);"><i class="icofont-file-excel"></i>Download
                                                as Excel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="widget-body">
                        <div class="widget-details" *ngIf="_Isbarod">
                            <p-chart type="bar" [data]="basicData2" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Islineod">
                            <p-chart type="line" [data]="basicData2" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Ispieod">
                            <p-chart type="pie" [data]="basicData2" [options]="basicOptions"></p-chart>
                        </div>
                        <div class="widget-details" *ngIf="_Isdoughnutod">
                            <p-chart type="doughnut" [data]="basicData2" [options]="basicOptions"></p-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>