import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Console } from 'console';
// import autoTable from 'jspdf-autotable';
import { element } from 'protractor';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { User } from 'src/app/core/Models/User';
import { RptaccountsmonitorService } from 'src/app/core/Services/reports/Accounting/RptAccountsMonitor/rptaccountsmonitor.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';


@Component({
  selector: 'app-rpt-accountsmonitor',
  templateUrl: './rpt-accountsmonitor.component.html',
  styleUrls: ['./rpt-accountsmonitor.component.css']
})
export class RptAccountsmonitorComponent implements OnInit {

  userids: number[] = [];
  _src: SafeResourceUrl;
  _IsProgressSpinner: boolean = false;
  date: Date;
  monitorobj: any;
  _Userid = sessionStorage["userid"];
  _accountlist: any[];
  _cashierlist: any[];
  _tempcashierlist: any[];
  _cashiersummarylist: any[];
  _tempcashiersummarylist: any[];
  selectedrows: any;
  cols: string[];
  colsvalues: string[];
  _statuslist: Metadatum[];
  status: string = "";
  datepipe = new DatePipe('en-US');
  cashiersummarygrid: boolean = false;
  _Action: IAction;
  mindate: Date;
  maxdate: Date;

  constructor(private utility: CommonUtilities, private fb: FormBuilder,
    private _AccessRightsService: AccessRightsService,
    private _RptaccountsmonitorService: RptaccountsmonitorService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private sanitizer: DomSanitizer, private exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(324);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._IsProgressSpinner = true;
    this._RptaccountsmonitorService.PageOnload(this._Userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._statuslist = resultJSON.statuslist;

      if (sessionStorage['Environmentenddate'] == "null") {
        this.date = new Date();
        this.maxdate = new Date();
      }
      else {
        this.date = new Date(sessionStorage['Environmentenddate']);
      }

      this._IsProgressSpinner = false;
      this.cashiersummarygrid = false;
    },
      error => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      });
  }


  search() {
    this.userids = this.userids || [];
    this._accountlist = [];
    this._cashierlist = [];
    this._cashiersummarylist = [];
    this._IsProgressSpinner = true;
    let date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    this.monitorobj = { "date": date };
    this._RptaccountsmonitorService.Search(this.monitorobj).subscribe((result) => {
      this._IsProgressSpinner = false;
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("list", resultJSON);
      this._accountlist = resultJSON.summarylist || [];
      this._tempcashierlist = resultJSON.cashierlist || [];
      this._cashiersummarylist = resultJSON.cashiersummarylist || [];
      if (!this._accountlist.length) {
        this._CustomExceptionService.handleWarning("No data available to show.");
        return;
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  // Grid Function Click :
  onselectbalance(details: any) {
    this._cashierlist = this._tempcashierlist.filter(s => s.BRANCHNAME == details.BRANCHNAME);
    console.log("list", this._cashierlist);
    this.cashiersummarygrid = true;
  }

  exportPdf() {
    if (!this._accountlist || !this._accountlist.length)
      return;
    let CashBooKList = this.GetCashBooKList();
    this.exportUtility.ExportToPdf(CashBooKList, 'CashBook.pdf');
  }

  GetCashBooKList() {
    if (!this._accountlist || !this._accountlist.length)
      return;
    let CashBooKList = [];
    let cols = ["PARTYCODE", "LEDGERID"]
    this.cols = Object.keys(this._accountlist[0]);
    this.cols = this.cols.filter(f => !cols.includes(f));
    this._accountlist.forEach(element1 => {
      let cashbook: any = {};
      this.cols.forEach(element2 => {
        cashbook[element2] = element1[element2];
      });
      CashBooKList.push(cashbook);
    });
    return CashBooKList;
  }

  exportExcel() {
    let CashBooKList = this.GetCashBooKList();
    this.exportUtility.exportExcel(CashBooKList, 'AccountsMonitorReport', 'xlsx');
  }

  clear() {
    if (sessionStorage['Environmentenddate'] == "null") {
      this.date = new Date();
      this.maxdate = new Date();
    }
    else {
      this.date = new Date(sessionStorage['Environmentenddate']);
    }
    this._accountlist = [];
    this._cashierlist = [];
    this._cashiersummarylist = [];
  }

}
