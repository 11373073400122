import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LaybuyService } from 'src/app/core/services/accounts/laybuy.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { LaybuysearchView } from 'src/app/core/Views/LaybuysearchView';
import { ConfirmationService } from 'primeng/api';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { DatePipe } from '@angular/common';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-laybuy',
  templateUrl: './v-laybuy.component.html',
  styleUrls: ['./v-laybuy.component.css']
})
export class VLaybuyComponent implements OnInit {
  selectedrows: any;
  _LayBySearchViews: LaybuysearchView[] = [];
  _LayBySearchView: LaybuysearchView;
  _BranchID: number;
  _IsProgressSpinner: boolean = true;
  _Action: IAction;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _roleid = sessionStorage["currentRole"]
  _branchid = sessionStorage["BranchID"]
  cols: any[];
  mindate: Date;
  maxdate: Date;
  constructor(
    private fb: FormBuilder,
    private _LaybuyService: LaybuyService,
    private router: Router,
    private utility: CommonUtilities,
    public exportUtility: ExportUtility,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService) {
    //Go To Create Page
  }


  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(74);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.HotKeyIntegration();
    this.cols = [
      { field: 'laybuycode', header: 'Laybuy No' },
      { field: 'customername', header: 'Customer Name' },
      { field: 'startdate', header: 'Start Date' },
      { field: 'enddate', header: 'End Date' },
      { field: 'productprice', header: 'Product Price' },
      { field: 'paidAmount', header: 'Paid Amount' },
      { field: 'balance', header: 'Balance' },
      { field: 'laybuystatusdesc', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.Fetchall();
    console.log("pageload");

  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let userid = sessionStorage["userid"];
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');

    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._BranchID = sessionStorage["currentbranchid"]
    this._IsProgressSpinner = true;
    this._LaybuyService.FetchAll(userid, this._BranchID, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._LayBySearchViews = resultJSON.laybuysearchViewList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }

  view(event) {
    this.router.navigate(['/cLaybuy'], { state: { laybuyid: event.laybuyid, laybuycode: event.laybuycode, customerid: event.customerid, action: 'view' } });
  }

  GoToCreate(event) {
    this.router.navigate(['/cLaybuy']);
  }
  exportExcel() {
    let LayBuyList = this.GetLayBuyList();
    this.exportUtility.exportExcel(LayBuyList, 'LayBuy', 'xlsx');
  }

  ExportToPdf() {
    let LayBuyList = this.GetLayBuyList();
    this.exportUtility.ExportToPdf(LayBuyList, 'LayBuy.pdf');
  }

  GetLayBuyList() {
    if (!this._LayBySearchViews || !this._LayBySearchViews.length)
      return;
    let LayBuyList = [];
    this._LayBySearchViews.forEach(element => {
      let laybuy: any = {};
      laybuy["Laybuy No"] = element.laybuycode;
      laybuy["Customer Name"] = element.customername;
      laybuy["Start Date"] = element.startdate;
      laybuy["End Date"] = element.enddate;
      laybuy["Product Price"] = element.productprice;
      laybuy["paidAmount"] = element.paidamount;
      laybuy["Balance"] = element.balance;
      laybuy["Status"] = element.laybuystatusdesc;
      laybuy["Created By"] = element.createdbyname;
      laybuy["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      LayBuyList.push(laybuy);
    });
    return LayBuyList;
  }
  Cancel(event) {
    var laybuyid = event.laybuycode;
    this._IsProgressSpinner = true;
    this._LaybuyService.Cancel(laybuyid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      this._LayBySearchViews = resultJSON.laybuysearchViewList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    this.Fetchall();
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Laybuy Amount <b>"' + event.laybuycode + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  Print(event) {
    var laybuycode = event.laybuycode;
    this._IsProgressSpinner = true;
    this._LaybuyService.Print({ "LayBuyNo": laybuycode, "BranchId": this._branchid }).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
