export class Shippingchargesweightbasedcost {
    scwid: number;
    scid: number;
    currencyid: number  ;
    // deliverytypecode: string;
    deliverytime: string;
    uom: string;
    minweight: number  ;
    maxweight: number  ;
    basecost: number  ;
    maxallowedweightatfreeofcost: number  ;
    additionalcostperkg: number  ;
    recordstatus: string;
    createdby: number  ;
    createdon: string   ;
    modifiedby: number  ;
    modifiedon: string   ;
    isdisabledremove:boolean;
    normaldeliverycost: number ;
    expressdeliverycost: number ;
    deliverytimeforexpressdelivery: string;

 
}