import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Branch } from 'src/app/core/Models/Branch';
import { Materialrequestpageloadview } from 'src/app/core/Models/MaterialrequestPageonloadView';
import { Materialreturnrequest } from 'src/app/core/Models/Materialreturnrequest';
import { User } from 'src/app/core/Models/User';
import { MaterialrequestService } from 'src/app/core/Services/ecommerce/materialrequest.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { KeyPress } from 'src/assets/js/KeyPress';

@Component({
  selector: 'app-v-order-return',
  templateUrl: './v-order-return.component.html',
  styleUrls: ['./v-order-return.component.css']
})
export class VOrderReturnComponent implements OnInit {

  orderstatusListGrid: Materialreturnrequest[] = [];
  branchNames = [];
  branchName = [];
  selectedOrderReturnRows: Materialrequestpageloadview[] = [];
  _branches: Branch[] = [];
  _Tellernames: User[] = [];
  _PageLoadObj: any;
  _MaterialRequestObj: any;
  _IsProgressSpinner: boolean = false;
  ShowExistingData: boolean = false;
  datepipe = new DatePipe('en-Us');
  fromdate: Date;
  todate: Date;
  cols: any[];
  List1: any[] = []
  List2: any[] = []
  _View: boolean = false
  constructor(
    private fb: FormBuilder,
    private utility: CommonUtilities,
    public exportUtility: ExportUtility,
    private _MaterialRequestService: MaterialrequestService,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private _router: Router,
    public keypress: KeyPress,
    private router: Router,
  ) { }
  ngOnInit(): void {
    // window.location.reload();
    this.todate = new Date();
    this.fromdate = new Date();
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'orderno', header: 'Order No' },
      { field: 'orderid', header: 'Order ID' },
      { field: 'requestno', header: 'Request No' },
      { field: 'totalquantity', header: 'Actual Quantity' },
      { field: 'returnquantity', header: 'Return Quantity' },
      { field: 'receivedquantity', header: 'Received Quantity' },
      { field: 'returnbranchid', header: 'Branch Name' },
      { field: 'pickedby', header: 'Picked By' },
    ]
    this.PageOnload();
  }
  PageOnload() {
    this.orderstatusListGrid = [];
    this.selectedOrderReturnRows = [];
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._PageLoadObj = { "isExistsData": this.ShowExistingData, "fromDate": fromdate, "toDate": todate }
    this._MaterialRequestService.Pageonload(this._PageLoadObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("PageOnload", resultJSON);
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._branches = resultJSON.branchlist
        if (this.ShowExistingData) {
          this.orderstatusListGrid = resultJSON.materialexistrequestList || [];
        } else {
          this.orderstatusListGrid = resultJSON.materialrequestList || [];
        }
        for (let i = 0; i < this.orderstatusListGrid.length; i++) {
          if (!this.ShowExistingData) {
            this.orderstatusListGrid[i].receivedquantity = 0;
          }
          if (this.orderstatusListGrid[i].isselfpickup == true) {
            this.orderstatusListGrid[i].isDisabledSelfPickup = true;
            this.orderstatusListGrid[i].isDisabledPickedby = true;
            this.orderstatusListGrid[i].returnbycustomer = true;
          } else if (this.orderstatusListGrid[i].isselfpickup == false) {
            this.orderstatusListGrid[i].isDisabledSelfPickup = false;
            this.orderstatusListGrid[i].isDisabledPickedby = false;
          }
        }
        this._Tellernames = resultJSON.userslist;
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  OnSave() {
    for (let i = 0; i < this.selectedOrderReturnRows.length; i++) {
      if (this.selectedOrderReturnRows[i].receivedquantity > this.selectedOrderReturnRows[i].returnquantity) {
        this._CustomExceptionService.handleError("Received Quantity Should not be greater than return quantity");
        return;
      }
      if (this.selectedOrderReturnRows[i].receivedquantity < this.selectedOrderReturnRows[i].returnquantity) {
        this._CustomExceptionService.handleError("Received Quantity Should not be less than return quantity");
        return;
      }
      if (!this.selectedOrderReturnRows[i].returnbranchid) {
        this._CustomExceptionService.handleError("Please Select Branch");
        return;
      }
      if (this.selectedOrderReturnRows[i].returnbycustomer == false) {
        if (!this.selectedOrderReturnRows[i].pickedby) {
          this._CustomExceptionService.handleError("Please select picked by");
          return;
        }
      }
    }
    var l_OrderReturns: Materialreturnrequest[] = [];
    if (this.selectedOrderReturnRows.length > 0) {
      Object.keys(this.selectedOrderReturnRows).map((Index) => {
        var currentRow = this.selectedOrderReturnRows[Index];
        var l_orderreturns = new Materialreturnrequest();
        l_orderreturns.materialreturnrequestid = 0;
        l_orderreturns.salesreturnid = 0;
        l_orderreturns.salesreturnno = "";
        l_orderreturns.returnbranchid = currentRow.returnbranchid;
        l_orderreturns.pickedby = currentRow.pickedby;
        l_orderreturns.ordercancelid = currentRow.ordercancelid;
        l_orderreturns.returnbycustomer = currentRow.returnbycustomer;
        l_orderreturns.receivedquantity = currentRow.receivedquantity || 0;
        l_orderreturns.eorderid = currentRow.orderid;
        l_orderreturns.eorderno = currentRow.orderno || 0;
        l_orderreturns.createdby = sessionStorage["userid"];
        l_orderreturns.createdon = new Date();
        l_OrderReturns.push(l_orderreturns);
      })
      this._IsProgressSpinner = true;
      this._MaterialRequestObj = { "Materialreturnrequest": l_OrderReturns }
      this._MaterialRequestService.Create(this._MaterialRequestObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._IsProgressSpinner = false;
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
          this.reset();
          this.orderstatusListGrid = resultJSON.materialrequestList;
        }
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    } else {
      this._CustomExceptionService.handleWarning("Please Select atleast one row");
    }
  }
  SelectAll(event) { }
  OnSelectReturnByCustomer(_orderstatusListGrid) {
    if (_orderstatusListGrid.returnbycustomer == true) {
      _orderstatusListGrid.pickedby = 0;
      _orderstatusListGrid.isDisabledPickedby = true;
    } else {
      _orderstatusListGrid.isDisabledPickedby = false;
    }
  }
  reset() {
    this.selectedOrderReturnRows = [];
    this.orderstatusListGrid = []
  }
  exportExcel() {
    let OrderReturnList = this.GetOrderReturnList();
    this.exportUtility.exportExcel(OrderReturnList, 'Material-Return', 'xlsx');
  }
  ExportToPdf() {
    if (!this.orderstatusListGrid || !this.orderstatusListGrid.length)
      return;
    let StatusHistoryDetails = this.GetOrderReturnList();
    const data = StatusHistoryDetails.map(Object.values);
    const head = [['Order No', 'Order ID', 'Request No', 'Actual Quantity', 'Return Quantity', 'Received Quantity', 'Branch Name', 'Picked By']]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Material-Return.pdf');
      })
    })
  }
  GetOrderReturnList() {
    if (!this.orderstatusListGrid || !this.orderstatusListGrid.length)
      return;
    let OrderStatusList = [];
    this.orderstatusListGrid.forEach(element => {
      let orderstatus: any = {};
      orderstatus["Order NO"] = element.orderno;
      orderstatus["Order ID"] = element.orderid;
      orderstatus["Request No"] = element.requestno;
      orderstatus["Actual Quantity"] = element.totalquantity;
      orderstatus["Return Quantity"] = element.returnquantity;
      orderstatus["Received Quantity"] = element.receivedquantity;
      let teller = (this._Tellernames || []).find(f => f.userid == element.pickedby);
      let branch = (this._branches || []).find(f => f.branchid == element.returnbranchid);
      orderstatus["Branch Name"] = branch ? branch.branchname : "";
      orderstatus["Picked By"] = teller ? teller.username : "";
      OrderStatusList.push(orderstatus);
    });
    return OrderStatusList;
  }
  edit(_orderstatusListGrid) {
    var data = _orderstatusListGrid
    this.router.navigate(['/COrderReturn'], { state: { salesreturnno: _orderstatusListGrid, ShowExistingData: this.ShowExistingData, action: 'edit' } });
  }
  view(_orderstatusListGrid) {
    var data = _orderstatusListGrid
    this.router.navigate(['/COrderReturn'], { state: { salesreturnno: _orderstatusListGrid, ShowExistingData: this.ShowExistingData, action: 'view' } });
  }
}