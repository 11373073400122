import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Pettycashexpense } from 'src/app/core/Models/Pettycashexpence';
import { Pettycashexpensedetail } from 'src/app/core/Models/Pettycashexpensedetail';
import { Pettycashsettlement } from 'src/app/core/Models/Pettycashsettlement';
import { Pettycashsettlementdetail } from 'src/app/core/Models/Pettycashsettlementdetail';
import { PettycashexpenceService } from 'src/app/core/Services/accounts/pettycashexpence.service';
import { PettycashsettlementService } from 'src/app/core/services/accounts/pettycashsettlement.service';
import { UploadFileService } from 'src/app/core/Utilities/fileupload/upload-file.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as ace from "ace-builds";
import { element } from 'protractor';

@Component({
  selector: 'app-c-pettycash-expence',
  templateUrl: './c-pettycash-expence.component.html',
  styleUrls: ['./c-pettycash-expence.component.css']
})
export class CPettycashExpenceComponent implements OnInit {
  _PettycashExpenceform: FormGroup;
  _PettycashSettlementDetailform: FormGroup;
  _PettycashExpenceid: number;
  _branchid = sessionStorage["currentbranchid"];
  _PettycashExpence: Pettycashexpense;
  _Expenses: any;
  _Expense: any;

  pettycashsettlementno: string;
  requestamount: number;
  settlementamount: number;
  balanceamount: number;
  documentselect: any;
  displayBasic: boolean;
  displayBasic1: boolean;
  position: string;
  selectedrows: any;
  _Proofs: Pettycashexpensedetail[] = [];
  _tempProofs: any[] = [];
  _Proof: Pettycashexpensedetail;
  _Expenseaccounts: Accountledger[] = [];
  _action: string = "create";
  _IsProgressSpinner: boolean = false;
  documentimage: SafeUrl;
  position1: string;
  displaydocumentpopup: boolean;
  _submitted = false;
  selectedRequest: any[] = [];
  // For File Upload:
  _LastAddedDocument: File;
  _selectedDocuments: File[] = [];
  _PettycashExpenseFORM: any;
  _PettycashexpenseOBJ: any
  _Result: any
  _IsSaveDisable: boolean = false;
  _IsRemoveDisable: boolean = false;
  _Supplieraccounts: Accountledger[] = [];
  _TempSupplieraccounts: Accountledger[] = [];
  ExpenseRowIndex: number = -1;
  isdisabled: boolean = false;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _PettycashExpenceService: PettycashexpenceService,
    private utility: CommonUtilities,
    private _uploadService: UploadFileService,
    private _router: Router,
    private _hotkeysService: HotkeysService,
    private _DomSanitizationService: DomSanitizer
  ) {
    this._PettycashExpenceid = history.state?.pettycashexpenseid ? history.state?.pettycashexpenseid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
  }
  InitializeForm() {
    this._PettycashExpenceform = this.fb.group({
      pettycashexpenseid: [0],
      expensedate: [],
      pettycashsettlmentid: [],
      pettycashsettlementstatusid: [],
      pettycashsettlementstatusdesc: [],
      accountingyear: [],
      branchid: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      pettycashexpensedetails: [],
      currentcurrencyid: [],
      currencyuniquerefid: [],
      jrnVoucherno: []
    });
    this.InitializeDetailForm();
  }

  InitializeDetailForm() {
    this._PettycashSettlementDetailform = this.fb.group({
      pettycashexpensedetalid: [0],
      pettycashexpenseid: [0],
      expenseamount: [],
      expenseaccount: [],
      attachmentname: [''],
      remarks: [''],
      supplieraccount: [0],
      supplieraccountname: [''],
      expenseaccountname: [''],
      currencyuniquerefid: [0],
      attachmenturl: ['']

    });
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._PettycashExpenceService.PageOnLoad(this._PettycashExpenceid, this._branchid, sessionStorage["userid"]).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Expenseaccounts = resultJSON.expenseaccount;
      this._Expenses = resultJSON.pettycashsettlement;
      this._Supplieraccounts = resultJSON.supplieraccount;
      this._TempSupplieraccounts = resultJSON.supplieraccount;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._PettycashExpenceform.setValue(updateJSON.pettycashexpense);
        this._Proofs = updateJSON.pettycashexpensedetails;
        this._tempProofs = updateJSON.pettycashexpensedetails;
        this._selectedDocuments = updateJSON.pettycashexpensedetails;
        this._Result = updateJSON.pettycashexpense;
        if (this._Expenses.length > 0) {
          var Amount = this._Expenses.filter(f => f.pettycashsettlementid == this._Result.pettycashsettlmentid);
          if (Amount.length > 0)
            this.settlementamount = Amount[0].settlementamount || 0;

          var BalAmount = this._Expenses.filter(f => f.pettycashsettlementid == this._Result.pettycashsettlmentid);
          if (BalAmount.length > 0)
            this.balanceamount = BalAmount[0].balanceamount || 0;
        }
        this.isdisabled = true;
        if (this._action == 'view') {
          this._PettycashSettlementDetailform.disable();
          this._IsSaveDisable = true;
          this._IsRemoveDisable = true;
        }
      }
    });
  }

  // Document Upload 
  showdocumentDialog(position1: string) {
    this.position1 = position1;
    this.displaydocumentpopup = true;
  }
  showBasicDialog() {
    this.displayBasic = true;
  }
  onDocumentSelect(event, document) {
    this._LastAddedDocument = event.currentFiles[0];
    this.documentselect = this._LastAddedDocument.name;
    document.clear();
  }
  onAddProof(event) {


    var proofdetail = new Pettycashexpensedetail();
    proofdetail.attachmentname = '';
    proofdetail.attachmenturl = ''
    if (this._action == 'create') {
      if (this._LastAddedDocument) {
        proofdetail.attachmentname = this._LastAddedDocument.name;
        this._selectedDocuments.push(this._LastAddedDocument);
      }
    }
    proofdetail.pettycashexpensedetalid = this._PettycashSettlementDetailform.get("pettycashexpensedetalid").value;
    proofdetail.pettycashexpenseid = this._PettycashSettlementDetailform.get("pettycashexpenseid").value;
    proofdetail.expenseamount = parseFloat(this._PettycashSettlementDetailform.get("expenseamount").value || 0);
    proofdetail.remarks = this._PettycashSettlementDetailform.get("remarks").value;
    if (this._action == 'create') {
      proofdetail.attachmenturl = URL.createObjectURL(this._LastAddedDocument);
    }

    var Expenseaccountid = this._PettycashSettlementDetailform.get("expenseaccount").value
    if (!Expenseaccountid) {
      this._CustomExceptionService.handleWarning("Please select expense account.");
      return;
    }
    var supplieraccountid = this._PettycashSettlementDetailform.get("supplieraccount").value
    if (!supplieraccountid) {
      this._CustomExceptionService.handleWarning("Please select supplier account.");
      return;
    }
    var expenceaccountname = (this._Expenseaccounts || []).filter(f => f.ledgerid == Expenseaccountid)[0].name;
    proofdetail.expenseaccount = Expenseaccountid;
    proofdetail.expenseaccountname = expenceaccountname;

    var supplieraccountname = (this._TempSupplieraccounts || []).filter(f => f.ledgerid == supplieraccountid)[0].name;
    proofdetail.supplieraccount = supplieraccountid;
    proofdetail.supplieraccountname = supplieraccountname;

    if (!proofdetail.expenseamount || proofdetail.expenseamount <= 0) {
      this._CustomExceptionService.handleWarning("Please enter expense amount.");
      return;
    }

    if (proofdetail.expenseamount > this.settlementamount) {
      this._CustomExceptionService.handleWarning("Expense amount must be less than settlement amount.");
      return;
    }
    if (this.ExpenseRowIndex > -1) {
      this._Proofs[this.ExpenseRowIndex] = proofdetail;

    }
    else {
      this._Proofs.push(proofdetail);
    }
    let url: string = "";


    for (var j = 0; j < this._Proofs.length; j++) {
      let tempProof = this._tempProofs.find(i => i.pettycashexpensedetalid == this._Proofs[j].pettycashexpensedetalid);
      if (tempProof) {
        this._Proofs[j].attachmenturl = tempProof.attachmenturl;
        this._Proofs[j].attachmentname = tempProof.attachmentname;
      }
    }


    this.InitializeDetailForm();
    this.ExpenseRowIndex = -1;

  }
  RemoveProofDetail(p_proofDetail: Pettycashexpensedetail) {
    var index = this._Proofs.indexOf(p_proofDetail);
    this._Proofs.splice(index, 1)
  }

  PopupRowValidation(row: any) {
    let s_request = (this.selectedRequest || []);

    (this._Expenses || []).forEach(element => {
      element.disablecheckbox = false;
    });

    if (!s_request || !s_request.length) return;

    (this._Expenses || []).filter(f => f.pettycashsettlementid != s_request[0].pettycashsettlementid).forEach(element => {
      element.disablecheckbox = true;
    });

    this._PettycashExpenceform.get("currentcurrencyid").setValue(row.currentcurrencyid);


  }

  //To Open Attachment:
  showdocumentGridDialog(position: string, _Proof: any) {
    let url = '';
    if (_Proof.attachmenturl) {
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(_Proof.attachmenturl);
      url = _Proof.attachmenturl;
    }
    this.position = position;
    if (url)
      window.open(url, "_blank");
  }

  formconverstion(p_Pettycashexpense: Pettycashexpense, documents: File[], p_documentdetails: Pettycashexpensedetail[]) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    for (var x in documents) {
      var index = p_documentdetails.findIndex(obj => obj.attachmentname == documents[x].name);
      if (index != -1) {
        var NewFileName = time.getTime() + '_' + documents[x].name;
        p_documentdetails[index].attachmentname = NewFileName;
        // p_documentdetails[index].attachmenturl = NewFileName;
        formData.append('files', documents[x], NewFileName);
      }
    }
    // let referenceList = this.GetAdjustedAmount();
    this._PettycashexpenseOBJ = {
      "Pettycashexpense": p_Pettycashexpense,
      "Pettycashexpensedetail": p_documentdetails,
      // "Pettycashsettlementrequestno": referenceList
    };
    var PettycashExpense = JSON.stringify(this._PettycashexpenseOBJ);
    formData.append('strPettyCashSettlement', PettycashExpense);
    return (formData);
  }
  //Document Clear:
  onDocumentClear() {
  }
  getBalanceAmount(event) {

  }
  onSave() {
    this._submitted = true;
    this._submitted = true;

    if (!(this._Proofs || []).length) {
      this._CustomExceptionService.handleWarning("Please add atleat one expense details.");
      return;
    }
    let expenseamount = 0;
    (this._Proofs || []).forEach(element => {
      expenseamount += element.expenseamount;
    });

    if (expenseamount > this.settlementamount) {
      this._CustomExceptionService.handleWarning("Expense amount should not greater than settlement.");
      return;
    }

    if (this._PettycashExpenceform.valid) {
      this._PettycashSettlementDetailform.get("pettycashexpenseid").setValue(this._PettycashExpenceform.get("pettycashexpenseid").value);
      this._PettycashExpence = this._PettycashExpenceform.getRawValue();
      if (this._action == 'create') {
        this._PettycashExpence.createdon = new Date();
        this._PettycashExpence.expensedate = new Date();
        this._PettycashExpence.createdby = sessionStorage["userid"];
        this._PettycashExpence.accountingyear = new Date().getFullYear();
        this._PettycashExpence.branchid = parseInt(sessionStorage["currentbranchid"]);
        this._PettycashExpenseFORM = this.formconverstion(this._PettycashExpence, this._selectedDocuments, this._Proofs);
        this._IsProgressSpinner = true;
        this._PettycashExpenceService.create(this._PettycashExpenseFORM).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._Expenses = resultJSON.pettycashsettlement;
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        this._PettycashExpenseFORM = this.formconverstion(this._PettycashExpence, this._selectedDocuments, this._Proofs);
        this._IsProgressSpinner = true;
        this._PettycashExpenceService.update(this._PettycashExpenseFORM).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this._Expenses = resultJSON.pettycashsettlement;
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }

    }
  }

  reset(event) {
    this._submitted = true;
    this._IsSaveDisable = false;
    this._PettycashExpenceform.reset();
    this.settlementamount = 0;
    this._PettycashSettlementDetailform.reset();
    this._Proofs = [];
    this.InitializeForm();
    this._submitted = false;
    this.requestamount = 0;
    this.balanceamount = 0;
    this.selectedRequest = [];
  }


  goBack(event) {
    this._router.navigate(['/vPettycashexpence']);
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  onRowSelectPopup(event: any) {
    this.PopupRowValidation(event.data);
    this.CombinePettyCashExpenseNo();
  }

  onRowUnSelectPopup(event: any) {
    this.PopupRowValidation(event.data);
    this.CombinePettyCashExpenseNo();
  }
  CombinePettyCashExpenseNo() {
    this.pettycashsettlementno = "";
    let balanceAmount = 0;
    let settlementAmount = 0
    let arry = [];
    (this.selectedRequest || []).forEach((element, index) => {
      arry.push(element.pettycashsettlementid);
      balanceAmount += element.balanceamount;
      settlementAmount += element.settlementamount;
    });

    this._PettycashExpenceform.get("pettycashsettlmentid").setValue(arry.join(","));
    this.balanceamount = balanceAmount;
    this.settlementamount = settlementAmount;
  }

  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._PettycashexpenseOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  OnRowSelect(rowdatas: Pettycashexpensedetail, rowIndex: number) {
    // this._PettycashSettlementDetailform.setValue(rowdatas);
    console.log(rowdatas);
    if(this._action == 'edit')
    {
      this._PettycashSettlementDetailform.setValue(rowdatas);
      let expenseid: number = parseInt(
      rowdatas.expenseaccount as unknown as string,10);
      this._PettycashSettlementDetailform.get('expenseaccount').setValue(expenseid);
      this.ExpenseRowIndex = rowIndex;
      this.isdisabled = false;
    }
    else
    {
      return
    }
  }

}
