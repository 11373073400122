import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EcommerceRoutingModule } from './ecommerce-routing.module';

// prime ng control
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { ToolbarModule } from 'primeng/toolbar';
import { RatingModule } from 'primeng/rating';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChipsModule } from 'primeng/chips';
import { FieldsetModule, } from 'primeng/fieldset';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { HttpClientModule } from '@angular/common/http';
import { TabViewModule } from 'primeng/tabview';
import { EditorModule } from 'primeng/editor';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TooltipModule } from 'primeng/tooltip';
import { CSliderManagementComponent } from './Slider-Management/c-slider-management/c-slider-management.component';
import { VSliderManagementComponent } from './Slider-Management/v-slider-management/v-slider-management.component';
import { VAdManagementComponent } from './Ad-Management/v-ad-management/v-ad-management.component';
import { CAdManagementComponent } from './Ad-Management/c-ad-management/c-ad-management.component';
import { CCouponComponent } from './Coupon/c-coupon/c-coupon.component';
import { VCouponComponent } from './Coupon/v-coupon/v-coupon.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { COrderstatusComponent } from './OrderStatus/c-orderstatus/c-orderstatus.component';
import { VOrderstatusComponent } from './OrderStatus/v-orderstatus/v-orderstatus.component';
import { COrderdescriptionComponent } from './Order-description/c-orderdescription/c-orderdescription.component';
import { VOrderdescriptionComponent } from './Order-description/v-orderdescription/v-orderdescription.component';
import { VCmsComponent } from './cms/v-cms/v-cms.component';
import { CCmsComponent } from './cms/c-cms/c-cms.component';
import { VPaymentHistoryComponent } from './v-payment-history/v-payment-history.component';
import { ShippingChargesComponent } from './Shipping/shipping-charges/shipping-charges.component';
import { VBlogComponent } from './blog/v-blog/v-blog.component';
import { CBlogComponent } from './blog/c-blog/c-blog.component';
import { COnlineStockDeliveryComponent } from './Online-Stock-Delivery/c-online-stock-delivery/c-online-stock-delivery.component';
import { VOnlineStockDeliveryComponent } from './Online-Stock-Delivery/v-online-stock-delivery/v-online-stock-delivery.component';
import { VCodHistoryComponent } from './v-cod-history/v-cod-history.component';
import { VOrderPendingStatusComponent } from './v-order-pending-status/v-order-pending-status.component';
import { CCodSettlementComponent } from './COD-Settlement/c-cod-settlement/c-cod-settlement.component';
import { VCodSettlementComponent } from './COD-Settlement/v-cod-settlement/v-cod-settlement.component';
import { VOrderReturnComponent } from './v-order-return/v-order-return.component';
import { VPaymentReturnComponent } from './v-payment-return/v-payment-return.component';
import { DeliveryChallanComponent } from './delivery-challan/delivery-challan.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { COrderReturnComponent } from './c-order-return/c-order-return.component';



@NgModule({
  declarations: [CSliderManagementComponent, VSliderManagementComponent, VAdManagementComponent, CAdManagementComponent, CCouponComponent,
    VCouponComponent, COrderstatusComponent, VOrderstatusComponent, COrderdescriptionComponent,
    VOrderdescriptionComponent, VCmsComponent, CCmsComponent, VPaymentHistoryComponent, ShippingChargesComponent
    , VBlogComponent, CBlogComponent,
    COnlineStockDeliveryComponent, VOnlineStockDeliveryComponent, VCodHistoryComponent, VOrderPendingStatusComponent,
    VOrderReturnComponent, CCodSettlementComponent, VCodSettlementComponent,VPaymentReturnComponent,DeliveryChallanComponent, COrderReturnComponent
  ],
  imports: [
    CommonModule,
    EcommerceRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChipsModule,
    FieldsetModule,
    AutoCompleteModule,
    OverlayPanelModule,
    CheckboxModule,
    PasswordModule,
    HttpClientModule,
    TabViewModule,
    ImageCropperModule,
    TooltipModule,
    ProgressSpinnerModule,
    EditorModule

  ],
  providers:[NgxImageCompressService]
})
export class EcommerceModule { }
