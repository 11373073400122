import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Smslog } from 'src/app/core/Models/Smslog';
import { SmsconfigurationService } from 'src/app/core/Services/masters/smsconfiguration.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Smsemailalertview } from 'src/app/core/Views/Smsemailalertview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-sms-log',
  templateUrl: './sms-log.component.html',
  styleUrls: ['./sms-log.component.css']
})
export class SmsLogComponent implements OnInit {

  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  // _productsview: Productview;
  // _productview: Productview;
  _smslogviews: Smslog[]=[];
  _Tempsmslogviews: Smslog[]=[];
  _smslogview: Smslog;
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  bodymsgtypes:any[]=[];
  _bodymsgtype:any="ALL";
  datepipe = new DatePipe('en-US');
  constructor(private fb: FormBuilder, private _SmsConfigurationService: SmsconfigurationService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService, private confirmationService: ConfirmationService,private exportUtility: ExportUtility) {

  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(311);
    this._IsProgressSpinner = true;
    this.bodymsgtypes=[
      {name:"ALL",value:"ALL"},
      {name:"PASSWORD",value:"PASSWORD"},
      {name:"ORDER",value:"ORDER"},
      {name:"Refund",value:"Refund"},
      {name:"Successfully placed",value:"Successfully placed"},
      {name:"Successfully packed",value:"Successfully packed"},
      {name:"Successfully shipped",value:"Successfully shipped"},
      {name:"Successfully delivered",value:"Successfully delivered"},
  ]
    this.utility.pageLoadScript();
    this._SmsConfigurationService.SmsLogFetchAll({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._smslogviews = resultJSON.smslogs;
      this._Tempsmslogviews = resultJSON.smslogs;
      let searchtext ="password"
      searchtext.toLowerCase()
     
     
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  OnChangeBodyMsgType(){
    if(this._bodymsgtype=="ALL" ||!this._bodymsgtype){
      this._smslogviews = this._Tempsmslogviews;
    }else {
      this._smslogviews =  (this._Tempsmslogviews || []).filter(f => f.bodymessage.indexOf((this._bodymsgtype || '').toLowerCase()) !== -1);
    }
  }
  exportExcel() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    this.exportUtility.exportExcel(TermsAndConditionList, 'SMS_Log', 'xlsx');
  }
  ExportToPdf() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    const data = TermsAndConditionList.map(Object.values);
    const head = [[
      'Mobile No',
      'Body Message',
      'Message Status',
      'Error Message',
      'Sent Date'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('SMS_Log.pdf');
      })
    })
  }
  GetTermsAndConditionList() {
    let TermsAndConditionList = [];
    this._smslogviews.forEach(element => {
      let TermsAndCondition: any = {};
      TermsAndCondition["Mobile No"] = element.mobileno;
      TermsAndCondition["Body Message"] = element.bodymessage;
      TermsAndCondition["Message Status"] = element.messagestatus;
      TermsAndCondition["Error Message"] = element.errormessage;
      TermsAndCondition["Sent Date"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      TermsAndConditionList.push(TermsAndCondition);
    });
    return TermsAndConditionList;
  }
}

