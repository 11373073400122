import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class ServiceWorkOrderService {
  public _Action: IAction;
  public _serviceworkorderService = environment.ServiceWorkOrderService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._serviceworkorderService + '/PageOnload';
    return this.http.post(Query, obj, { headers });
  }
  GetSRDetail(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._serviceworkorderService + '/GetSRDetail';
    return this.http.post(Query, obj, { headers });
  }
  create(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._serviceworkorderService + '/Create';
    return this.http.post(Query, obj, { headers });
  }
  edit(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._serviceworkorderService + '/Update';
    return this.http.post(Query, obj, { headers });
  }
  Delete(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._serviceworkorderService + '/Delete';
    return this.http.post(Query, obj, { headers });
  }
  FetchAll(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._serviceworkorderService + '/FetchAll';
    return this.http.post(Query, obj, { headers });
  }
  SendToApproval(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._serviceworkorderService + '/SendToApproval';
    return this.http.post(Query, obj, { headers });
  }
  Print(obj: any) {
    const headers = this.header;
    var Query = this._serviceworkorderService + '/ServiceWorkOrderPrint';
    return this.http.post(Query, obj, { responseType: 'blob', headers });
  }
}