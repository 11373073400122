import { Component, OnInit } from '@angular/core';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { RptlockedquantityService } from 'src/app/core/Services/reports/Stock/RptLockedQuantity/rptlockedquantity.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-rptlockedquantity',
  templateUrl: './rptlockedquantity.component.html',
  styleUrls: ['./rptlockedquantity.component.css']
})
export class RptlockedquantityComponent implements OnInit {

  OverallBranchLockedQty: number = 0;
  _BranchWiseLockedQtys: any[];
  IsShowProductwiseLockedqty: boolean = true;
  OverallProductwiseLockedqty: number = 0;
  _ProductWiseLockedqty: any[];
  selectedProductRows: any;
  _IsProgressSpinner: boolean = true;
  _ProductObj: any;
  LockedQtydetails: any[];
  cols: any[];
  ProductWiseLockedcols: any[];
  LockedQtydetailscols: any[];
  griddetaillockqty: boolean;
  selectedBranchwiseLockedqty: any;
  _userid: number = parseInt(sessionStorage["userid"]);
  _branchid: number = parseInt(sessionStorage["currentbranchid"]);
  _Referencetype: string;
  _Referenceno: string;
  _Evidenceno: string;
  _Referencebranchid: number;
  selectedrefno: any;
  IsLockedQty: boolean = true;
  IsInTransitQty: boolean = true;
  _Productid: number = 0;
  ProductId: number = 0;
  _productname: any;
  _tempproductname: any;
  filteredProducts: any

  constructor(private exportUtility: ExportUtility, private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService,
    private _LockedQuantityservice: RptlockedquantityService) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'branchname', header: 'Branch Name' },
      { field: 'lockedquantity', header: 'Locked Qty.' },
    ]
    this.ProductWiseLockedcols = [
      { field: 'productname', header: 'Product Name' },
      { field: 'lockedquantity', header: 'Locked Qty.' },
    ]
    this.LockedQtydetailscols = [
      { field: 'referenceno', header: 'Ref. No.' },
      { field: 'referencedate', header: 'Ref Date' },
      { field: 'referencetype', header: 'Ref. Type.' },
      { field: 'lockedquantity', header: 'Locked Qty.' },
    ]
    this._IsProgressSpinner = true;
    this._LockedQuantityservice.PageOnload(this._userid, this._Productid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      if (!resultJSON.overAllLockedQuantity || !resultJSON.overAllLockedQuantity.length) {
        this._CustomExceptionService.handleError("No Data Available");
      }
      else {
        this._BranchWiseLockedQtys = resultJSON.overAllLockedQuantity;

      }


      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  //BranchWise Stock
  exportExcel() {
    let BranchWiseLockedQtyList = this.GetBranchWiseLockedQtyList();
    this.exportUtility.exportExcel(BranchWiseLockedQtyList, 'BranchWiseLockedQuantity', 'xlsx');
  }

  ExportToPdf() {
    let BranchWiseStockList = this.GetBranchWiseLockedQtyList();
    this.exportUtility.ExportToPdf(BranchWiseStockList, 'BranchWiseLockedQuantity.pdf');
  }

  GetBranchWiseLockedQtyList() {
    if (!this._BranchWiseLockedQtys || !this._BranchWiseLockedQtys.length)
      return;
    let BranchWiseLockedQtyList = [];
    this._BranchWiseLockedQtys.forEach(element => {
      let BranchWiseLocked: any = {};
      BranchWiseLocked["Branch Name"] = element.branchname;
      BranchWiseLocked["Sales Invoice Locked Qty"] = element.salesinvoice;
      BranchWiseLocked["Stock Transfer Locked Qty"] = element.stocktransfer;
      BranchWiseLocked["Stock Allocation Locked Qty"] = element.stockallocation;
      BranchWiseLocked["Total Locked Qty"] = element.overalllockedquantity;
      BranchWiseLocked["Stock Transfer In-Transit"] = element.intransitstocktransfer;
      BranchWiseLocked["Stock Allocation In-Transit"] = element.intransitstockallocation;
      BranchWiseLocked["Total In-Transit"] = element.overallintransitquantity;
      BranchWiseLockedQtyList.push(BranchWiseLocked);
    });
    return BranchWiseLockedQtyList;
  }

  GetLockedStocktrasnfer(_BranchWiseLockedQty) {
    this._branchid = _BranchWiseLockedQty.branchid;
    this._Referencetype = "STOCKTRANSFER"
    this.GetDetailWiseStock();
  }
  //In-Transit stock transfer
  GetIntrasitLockedStocktrasnfer(_BranchWiseLockedQty) {
    this._branchid = _BranchWiseLockedQty.branchid;
    this._Referencetype = "INTRANSITSTOCKTRANSFER"
    this.GetDetailWiseStock();
  }

  GetLockedSalesinvoice(_BranchWiseLockedQty) {
    this._branchid = _BranchWiseLockedQty.branchid;
    this._Referencetype = "SALESINVOICE"
    this.GetDetailWiseStock();
  }

  GetLockedStockAllocation(_BranchWiseLockedQty) {
    this._branchid = _BranchWiseLockedQty.branchid;
    this._Referencetype = "STOCKALLOCATION"
    this.GetDetailWiseStock();
  }
  //In-Transit stock allocation
  GetIntransitStockAllocation(_BranchWiseLockedQty) {
    this._branchid = _BranchWiseLockedQty.branchid;
    this._Referencetype = "INTRANSITSTOCKALLOCATION"
    this.GetDetailWiseStock();
  }

  GetDetailWiseStock() {
    this._IsProgressSpinner = true;

    this._LockedQuantityservice.DetailWiseLockedQuantity(this._branchid, this._Referencetype, this._userid, this.ProductId).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.LockedQtydetails = resultJSON.detailWiseLockedQuantity;
      this.IsShowProductwiseLockedqty = false;
      if (this._Referencetype == "STOCKTRANSFER" || this._Referencetype == "SALESINVOICE" || this._Referencetype == "STOCKALLOCATION") {
        this.IsLockedQty = false;
        this.IsInTransitQty = true;
      } else if (this._Referencetype == "INTRANSITSTOCKALLOCATION" || this._Referencetype == "INTRANSITSTOCKTRANSFER") {
        this.IsLockedQty = true;
        this.IsInTransitQty = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }



  onRowSelectBranch(LockedQtydetail) {
    this._Referenceno = LockedQtydetail.referenceno;
    this._Referencetype = LockedQtydetail.referencetype;
    this._Evidenceno = "0";

    if (this._Referencetype == "INTRANSITSTOCKALLOCATION" || (this._Referencetype == "STOCKALLOCATION")) {
      this._Referencebranchid = LockedQtydetail.requestedbranchid;
    }
    else {
      this._Referencebranchid = 0;
    }


    this._IsProgressSpinner = true;
    this._LockedQuantityservice.getProductwiselockedqty(this._branchid, this._Referencetype, this._Referenceno, this._userid, this._Referencebranchid, this._Evidenceno, this.ProductId).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ProductWiseLockedqty = resultJSON.productWiseLockedQuantity;
      this.griddetaillockqty = true;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onRowSelectDeliveryno(LockedQtydetail) {
    this._Referenceno = LockedQtydetail.deliveryno;
    this._Referencetype = LockedQtydetail.referencetype;
    this._Referencebranchid = LockedQtydetail.requestedbranchid;
    this._Evidenceno = LockedQtydetail.referenceno;

    if (this._Referencetype == "INTRANSITSTOCKALLOCATION" || (this._Referencetype == "STOCKALLOCATION")) {
      this._Referencebranchid = LockedQtydetail.requestedbranchid;
    }
    else {
      this._Referencebranchid = 0;
    }


    this._IsProgressSpinner = true;
    //this.IsShowProductwiseLockedqty = false;
    this._LockedQuantityservice.getProductwiselockedqty(this._branchid, this._Referencetype, this._Referenceno, this._userid, this._Referencebranchid, this._Evidenceno, this.ProductId).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ProductWiseLockedqty = resultJSON.productWiseLockedQuantity;
      // this.IsShowProductwiseLockedqty = false;
      this.griddetaillockqty = true;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  exportExcelProductWiseQuantity() {
    let ProductWiseLockedQtyList = this.GetProductWiseLockedQtyList();
    this.exportUtility.exportExcel(ProductWiseLockedQtyList, 'ProductWiseLockedQuantity', 'xlsx');
  }

  ExportToPdfProductWiseQuantity() {
    let ProductWiseLockedQtyList = this.GetProductWiseLockedQtyList();
    this.exportUtility.ExportToPdf(ProductWiseLockedQtyList, 'ProductWiseLockedQuantity.pdf');
  }

  GetProductWiseLockedQtyList() {
    if (!this._ProductWiseLockedqty || !this._ProductWiseLockedqty.length)
      return;
    let ProductWiseLockedQtyList = [];
    this._ProductWiseLockedqty.forEach(element => {
      let ProductCategoryWiseStock: any = {};
      ProductCategoryWiseStock["Product Name"] = element.productname;
      ProductCategoryWiseStock["Locked Quantity"] = element.lockedquantity;
      ProductWiseLockedQtyList.push(ProductCategoryWiseStock);
    });
    return ProductWiseLockedQtyList;
  }



  exportExcelProductdetaillockedqty() {
    let ProductDetailLockedQtyList = this.GetProductDetailLockedQtyList();
    this.exportUtility.exportExcel(ProductDetailLockedQtyList, 'ReferenceNoWiseLockedQuantity', 'xlsx');
  }

  ExportToPdfProductdetaillockedqty() {
    let ProductDetailLockedQtyList = this.GetProductDetailLockedQtyList();
    this.exportUtility.ExportToPdf(ProductDetailLockedQtyList, 'ProductDetailLockedQuantity.pdf');
  }

  GetProductDetailLockedQtyList() {
    if (!this.LockedQtydetails || !this.LockedQtydetails.length)
      return;
    let ProductDetailLockedQtyList = [];
    this.LockedQtydetails.forEach(element => {
      let ProductdetaillockedStock: any = {};
      ProductdetaillockedStock["Requested Branch"] = element.requestedbranchname;
      ProductdetaillockedStock["Delivery Branch"] = element.deliverybranchname;
      ProductdetaillockedStock["Reference No"] = element.referenceno;
      ProductdetaillockedStock["Reference Date"] = element.referencedate;
      ProductdetaillockedStock["Locked Qty"] = element.lockedquantity;
      ProductDetailLockedQtyList.push(ProductdetaillockedStock);
    });
    return ProductDetailLockedQtyList;
  }

  filterProducts(event) {

    if (event.query == "" || event.query == undefined) {
      return;
    }
    else {

      this._ProductObj = { "product": event.query, "branchid": this._branchid || 0 };
      this._IsProgressSpinner = true;
      this._LockedQuantityservice.GetProduct(this._ProductObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productname = <Productoverallview[]>resultJSON.productsearchlist || [];
        this._tempproductname = <Productoverallview[]>resultJSON.productsearchlist || [];
        let filtered: any[] = [];
        let query = (<string>event.query).toLowerCase();
        this.filteredProducts = this._productname.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });


    }
  }
  onProductChange(event) {
    this.ProductId = event.productid;
    this.Onload();
    this.IsLockedQty = true;
    this.IsInTransitQty = true;
    this.IsShowProductwiseLockedqty = true;

  }
  Onload() {
    this.LockedQtydetails = [];
    this._ProductWiseLockedqty = [];
    this._IsProgressSpinner = true;

    this._LockedQuantityservice.PageOnload(this._userid, this.ProductId).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      if (!resultJSON.overAllLockedQuantity || !resultJSON.overAllLockedQuantity.length) {
        this._CustomExceptionService.handleError("No Data Available");
      }
      else {
        this._BranchWiseLockedQtys = resultJSON.overAllLockedQuantity;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear() {
    this._Productid = 0;
    this.ProductId = 0;
    this.Onload();
    this.IsLockedQty = true;
    this.IsInTransitQty = true;
    this.IsShowProductwiseLockedqty = true;
  }
}
