import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BranchService } from 'src/app/core/services/masters/branch.service';
import { Branchview } from 'src/app/core/Views/Branchview ';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { DatePipe } from '@angular/common';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-v-branch',
  templateUrl: './v-branch.component.html',
  styleUrls: ['./v-branch.component.css']
})
export class VBranchComponent implements OnInit {
  selectedrows: any;
  _branchviews: Branchview[];
  _branchview: Branchview;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _Action: IAction;
  datepipe = new DatePipe('en-US');
  _IsProgressSpinner: boolean = false;
  cols: any[];
  constructor(private fb: FormBuilder, private _BranchService: BranchService, private router: Router, private utility: CommonUtilities, private _hotkeysService: HotkeysService
    , private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService, private exportUtility: ExportUtility) {

  }

  ngOnInit(): void {

    this._Action = this._AccessRightsService.getAccessRights(22);
    this.HotKeyIntegration();

    this.utility.pageLoadScript();
    this.cols = [
      { field: 'branchcode', header: 'Branch Code' },
      { field: 'branchname', header: 'Branch Name' },
      { field: 'branchtypename', header: 'Branch Type Name' },
      { field: 'levelname', header: 'Level Name' },
      { field: 'currencyname', header: 'Currency Name' },
      { field: 'branchstatusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
      { field: 'modifiedbyname', header: 'Modified By' },
      { field: 'modifiedon', header: 'Modified On' },
    ]
    this._BranchService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchviews = resultJSON.branchviews;
      // console.log(this._branchviews)
      // this._Add = this._CountryService._Action._Add; 
      // this._delete = this._CountryService._Action._Delete;
      // this._update = this._CountryService._Action._Update;
      // this._View = this._CountryService._Action._View;
    });
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  view(event) {
    // 58. according to the branch code ., all the datas should fetch in particular columns.
    // 59. every column must be disabled
    this.router.navigate(['/cBranch'], { state: { branchid: event.branchid, action: 'view' } });
  }

  delete(event) {
    alert("delete");
  }

  edit(event) {
    // 39.according to the ,branch code., all the datas should fetch in particular columns.

    this.router.navigate(['/cBranch'], { state: { branchid: event.branchid, action: 'edit' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cBranch']);
  }
  exportExcel() {
    let BranchList = this.GetBranchList();
    this.exportUtility.exportExcel(BranchList, 'Branch', 'xlsx', );
  }

  // ExportToPdf() {
  //   let BranchList = this.GetBranchList();
  //   this.exportUtility.ExportToPdf(BranchList, 'Branch.pdf');
  // }

  ExportToPdf() {
    let BranchList = this.GetBranchList();
    const data = BranchList.map(Object.values);
    const head = [[
      'Branch Code',
      'Branch Name',
      'Branch Type Name',
      'Level Name',
      'Currrncy Name',
      'Status',
      'Created By',
      'Created On',
      'Modified By',
      'Modified On'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Branch.pdf');
      })
    })
  }


  GetBranchList() {
    let BranchList = [];
    this._branchviews.forEach(element => {
      let branch: any = {};
      branch["Branch Code"] = element.branchcode;
      branch["Branch Name"] = element.branchname;
      branch["Branch Type Name"] = element.branchtypename;
      branch["Level Name"] = element.levelname;
      branch["Currency Name"] = element.currencyname;
      branch["Status"] = element.branchstatusname;
      branch["Created By"] = element.createdbyname;
      branch["Created On"] = this.datepipe.transform(element.createdon,'dd/MM/yyyy');
      branch["Modified By"] = element.modifiedbyname;
      branch["Modified On"] = this.datepipe.transform(element.modifiedon, 'dd/MM/yyyy');
      BranchList.push(branch);
    });
    return BranchList;
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this branch <b>"' + event.branchname + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    var branchid = event.branchid;
    this._IsProgressSpinner = true;
    this._BranchService.Cancel(branchid, sessionStorage["userid"]).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_14);
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._branchviews = resultJSON.branchviews;
      } else { this._CustomExceptionService.handleError(result.tranStatus.lstErrorItem[0].message); this._IsProgressSpinner = false; }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
}
