
<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Approval Configuration Branch</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add"
                            (click)="GoToCreate($event)"
                                class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                            <!-- <button pButton pRipple label="" icon="pi pi-plus" class="p-button-success p-mr-2"
                       (click)="openNew()"></button>
                      <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger ">
                      </button>-->
                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                            (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                            tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                            class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                            (click)="exportExcel()"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                            class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                            (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_workflowdetailviews" [rows]="10" [paginator]="true" [columns]="cols"
                        [rowsPerPageOptions]="[5,10,25,50,100]" [columns]="cols"
                        [globalFilterFields]="['documenttypename','workflowtypename','stageid','stagename','stageactionname','stagestatusname']"
                        [rowHover]="true" dataKey="documenttypename" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px;text-align:center">Action</th>
                                <th pSortableColumn="documenttypename">Document Type Name<p-sortIcon field="documenttypename"></p-sortIcon>
                                </th>
                                <th pSortableColumn="workflowname">WorkFlow Name<p-sortIcon field="workflowname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="stagename">Stage Name<p-sortIcon field="stagename"></p-sortIcon>
                                </th>
                                <th pSortableColumn="branchname">Stage Name<p-sortIcon field="branchname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="primaryapprovername">Primary Approver Name<p-sortIcon field="primaryapprovername"></p-sortIcon>
                                </th>
                                <th pSortableColumn="secondaryapprovername">Secondary Approver Name<p-sortIcon field="secondaryapprovername"></p-sortIcon>
                                </th>
                                <th pSortableColumn="stagestatusname">Stage Status<p-sortIcon field="stagestatusname"></p-sortIcon>
                                </th>
                                <!-- <th>Document Type Name</th>
                                <th>WorkFlow Name</th>
                                <th>Stage Name</th>
                                <th>Branch Name</th>
                                <th>Primary Approver Name</th>
                                <th>Secondary Approver Name</th>
                                <th>Stage Status</th> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_workflowdetailview>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                    [disabled]="_View" (click)="view(_workflowdetailview)"></button>
                                <button pButton pRipple icon="las la-pen" title="Edit"
                                    class="p-button p-button-success p-mr-2" [disabled]="_update"
                                    (click)="edit(_workflowdetailview)"></button>
                                    <button pButton pRipple icon="las la-times" title="Cancel"
                                    class="p-button p-button-danger" 
                                    (click)="confirm(_workflowdetailview)" [disabled]="_workflowdetailview.stagestatusname=='Inactive'"></button>
                                </td>
                                <td>
                                    {{_workflowdetailview.documenttypename}}
                                </td>
                                <td>
                                    {{_workflowdetailview.workflowname}}
                                </td>
                                <td>
                                    {{_workflowdetailview.stagename}}
                                </td>
                                <td>
                                    {{_workflowdetailview.branchname}}
                                </td>
                                <td>
                                    {{_workflowdetailview.primaryapprovername}}
                                </td>
                                <td>
                                    {{_workflowdetailview.secondaryapprovername}}
                                </td>
                                <td>
                                    {{_workflowdetailview.stagestatusname}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>