<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Province</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add"
                                class="p-button p-button-success p-mr-2" [disabled]="!_Action._Add"
                                (click)="GoToCreate($event)">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                (click)="ExportToPdf()" class="p-button-warning p-mr-2" pTooltip="PDF"
                                tooltipPosition="bottom"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_statesview" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['countryname','statecode','statename','statestatusname']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="statecode"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width:120px;">Action
                                </th>
                                <th pSortableColumn="countryname">Country <p-sortIcon field="countryname"></p-sortIcon>
                                </th>
                                <th [hidden]="true">Province ID</th>
                                <th pSortableColumn="statecode">Province Code<p-sortIcon field="statecode"></p-sortIcon>
                                </th>
                                <th pSortableColumn="statename">Province Name<p-sortIcon field="statename"></p-sortIcon>
                                </th>
                                <th pSortableColumn="statestatusname">Status <p-sortIcon field="statestatusname">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_stateview>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        (click)="view(_stateview)" [disabled]="!_Action._View"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" [disabled]="!_Action._Update"
                                        class="p-button p-button-success p-mr-2" (click)="edit(_stateview)"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Delete"
                                        class="p-button p-button-danger" 
                                        [disabled]="_stateview.statestatus == 'MSC00002' || !_Action._Delete"
                                        (click)="confirm(_stateview)"></button>
                                </td>
                                <td>
                                    {{_stateview.countryname}}
                                </td>
                                <td [hidden]="true">
                                    {{_stateview.stateid}}
                                </td>
                                <td>
                                    {{_stateview.statecode}}
                                </td>
                                <td>
                                    {{_stateview.statename}}
                                </td>
                                <td>
                                    {{_stateview.statestatusname}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>