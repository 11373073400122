import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { RptClosingBalanceVerificationService } from 'src/app/core/Services/reports/Accounting/RptClosingBalanceVerification/rpt-closing-balance-verification.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';


@Component({
  selector: 'app-rpt-closing-balance-verification',
  templateUrl: './rpt-closing-balance-verification.component.html',
  styleUrls: ['./rpt-closing-balance-verification.component.css']
})
export class RptClosingBalanceVerificationComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptClosingbalanceform: FormGroup;
  _fromMaxDate = new Date();
  today = new Date();
  datepipe: any;
  _src: SafeResourceUrl;
  cols: any[];
  _typelist = [
    { typeid: 1, typename: 'Customer' },
    { typeid: 2, typename: 'Supplier' },
    { typeid: 3, typename: 'Employee' },
  ];
  _PartytypeList: any[];
  _ClosingReportObj: any;
  mindate: Date;
  maxdate: Date;

  private _closingBalanceVerificationview: any;


  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptClosingBalanceVerificationService: RptClosingBalanceVerificationService,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer,
    private exportUtility: ExportUtility
  ) { }

  InitiliazeForm() {
    this._rptClosingbalanceform = this.fb.group({
      fromdate: [''],
      todate: [''],
      partytype: [0]
    })
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'ledgername', header: 'Leadger Name' },
      { field: 'receiptscreen', header: 'Receipt Screen' },
      { field: 'paymentscreen', header: 'Payment Screen' },
      { field: 'accountledgerreport', header: 'Account Ledger Report ' },
      { field: 'ageingreport', header: 'Ageing Report' },
      { field: 'outstandingreport', header: 'Outstanding Report' }
    ]
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitiliazeForm();
    // this.today = new Date("2021-07-18");
    // var dt = new Date();
    // dt.setDate(dt.getDate() - 7);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptClosingbalanceform.get("fromdate").setValue(new Date);
      this._rptClosingbalanceform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptClosingbalanceform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptClosingbalanceform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    // this._rptClosingbalanceform.controls['fromdate'].setValue(this.today);
    // this._rptClosingbalanceform.controls['todate'].setValue(new Date());
    this._IsProgressSpinner = false;
  }

  clear(event) {
    this._rptClosingbalanceform.reset();
    this.InitiliazeForm();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptClosingbalanceform.get("fromdate").setValue(new Date);
      this._rptClosingbalanceform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptClosingbalanceform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptClosingbalanceform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._rptClosingbalanceform.controls['partytype'].setValue(0);
    this._PartytypeList = [];
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }


  GenerateReport(event) {
    var _fromdate = this._rptClosingbalanceform.get("fromdate").value;
    var _todate = this._rptClosingbalanceform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    var typeid = this._rptClosingbalanceform.get("partytype").value || 0;
    if (typeid == null || typeid == 0) {
      this._CustomExceptionService.handleError("Please Select Party Type");
      return;
    }
    var typeid = this._rptClosingbalanceform.get("partytype").value || 0;
    this._IsProgressSpinner = true;
    this._ClosingReportObj = { "fromdate": fromdate, "todate": todate, "typeid": typeid }
    this._RptClosingBalanceVerificationService.Print(this._ClosingReportObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (!resultJSON.reportList || !resultJSON.reportList.length) {
        this._IsProgressSpinner = false
        this._CustomExceptionService.handleError("No Data Available");
      }
      else {
        this._IsProgressSpinner = false
        this._PartytypeList = resultJSON.reportList;
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  exportExcel() {
    let closingBalanceLists = this.closingBalanceList();
    this.exportUtility.exportExcel(closingBalanceLists, 'Closing Balance Verification List', 'xlsx');
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  ExportToPdf() {
    let closingBalanceLists = this.closingBalanceList();
    const data = closingBalanceLists.map(Object.values);
    const head = [[
      'Ledger Name ',
      'Receipt Screen',
      'Payment Screen',
      'Account Ledger Report',
      'Ageing Report',
      'Outstanding Report',
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          }, headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          }, bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Closing Balance Verification List.pdf');
      })
    })
  }
  closingBalanceList() {
    let closingBalanceLists = [];
    this._PartytypeList.forEach(element => {
      let closingBalanceStatus: any = {};
      closingBalanceStatus["ledgername"] = element.LEDGERID;
      closingBalanceStatus["receiptscreen"] = element.RECEIPTVALUE;
      closingBalanceStatus["paymentscreen"] = element.PAYMENTVALUE;
      closingBalanceStatus["accountledgerreport"] = element.ACCOUNTLEDGERVALUE;
      closingBalanceStatus["ageingreport"] = element.AGEINGVALUE;
      closingBalanceStatus["outstandingreport"] = element.OUTSTANDINGVALUE;
      closingBalanceLists.push(closingBalanceStatus);
    });
    return closingBalanceLists;
  }
}
