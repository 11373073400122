<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
          </div>
        <div class="white">
            <form [formGroup]="_levelform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Level</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <!--4. save, clear button must be enabled. -->
                                <button pButton pRipple title="Save" icon="pi pi-save" type="submit" [disabled]="isDisabledSave" ></button>
                                <!--11. All input fields should be cleared -->
                                <button pButton pRipple title="Clear"  type="button" icon="pi pi-trash" type="button"
                                    (click)="reset($event)" class="p-button-danger" [disabled]="isDisabledClear"></button>
                                <!--14. on clicking search button , the data must be filtered and display in the grid -->
                                <!--12. should redirect to search page. Search grid should load with updated data and that data should be in descending order -->
                                <button pButton pRiple icon="pi pi-search" title="Back to Search" type="button"
                                    (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">

                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <!--6. if "Level Name" is not given, show error as "Please enter Level Name." -->
                                <span class="p-float-label">
                                    <input type="text" maxlength="50" name="levelname" pInputText formControlName="levelname">
                                    <label for="levelname">Level Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_levelform.get('levelname').touched || _submitted) && _levelform.get('levelname').errors?.LevelNameRequired">
                                    Please enter level name
                                </span>
                            </td>
                            <td style="width: 20%;">
                               
                                <span class="p-float-label">
                                    
                                    <p-dropdown [autoDisplayFirst]="true" [options]="_levelstatus" name="levelstatus"
                                        formControlName="levelstatus" optionLabel="metasubdescription"
                                        optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="levelstatus">Status</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;">
                                <!-- <span class="p-float-label">
                                    <input type="text" id="inputtext" name="levelNo" required pInputText
                                        [(ngModel)]="level.levelNo">
                                    <label for="levelNo">Level No <span class="hlt-txt">*</span></label>
                                </span> -->
                            </td>
                        </tr>
                    </table>

                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>