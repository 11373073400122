<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Lot Wise Stock </h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptlotwisestockform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchid" [selectionLimit]="1"
                                        [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatus == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="producttypeid">Branch</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_producttypelist" optionLabel="producttypename"
                                        optionValue="producttypeid" [(ngModel)]="producttypeid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProductCategory()"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                                        [selectionLimit]="50" [(ngModel)]="productcatid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProduct()"
                                        optionValue="productcategoryid"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productlist" optionLabel="productname"
                                        optionValue="productid" [(ngModel)]="productid" (onChange)="ChangeProduct()"
                                        [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">

                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                                        [(ngModel)]="brandid" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="brandid">Brand Name </label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_supplierlist" optionLabel="suppliername"
                                        optionValue="supplierid" [(ngModel)]="supplierid"
                                        [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="brandid">Supplier</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_stocktypelist"
                                        optionValue="metasubcode" optionLabel="metasubdescription"
                                        (onChange)="onChangeStockType()" formControlName="stocktype" [filter]="true"
                                        filterBy="metasubdescription">
                                    </p-dropdown>
                                    <label for="stocktype">Stock Type</label>
                                </span>
                            </td>
                            <td>
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox name="withzero" binary="true" formControlName="withzero">
                                        </p-checkbox>
                                        <label for="withzero">Without 0 Stock </label>
                                    </div>
                                </div>
                            </td>
                            <!-- <td>
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox name="consignmentstock" binary="true"
                                            formControlName="consignmentstock">
                                        </p-checkbox>
                                        <label for="consignmentstock">Consignment Stock</label>
                                    </div>
                                </div>
                            </td> -->
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" icon="pi pi-search" (click)="GenerateReport()"
                                    class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()"
                                    class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="500"
                        [paginator]="true" [rowsPerPageOptions]="[100,200,300]"
                        [rowsPerPageOptions]="[500,1000,1500,2000]" [globalFilterFields]="['branchname','producttypename','categoryname','productname','brandname','suppliername',
                        'lotnumber','severityname','stock','expirydate','isconsignmentstockname']" [rowHover]="true"
                        dataKey="productname" [columns]="cols"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" pSortableColumn="branchname">Branch<p-sortIcon
                                        field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="producttypename">Product Type
                                    <p-sortIcon field="producttypename">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="categoryname">Product Category
                                    <p-sortIcon field="categoryname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="productname">Product<p-sortIcon
                                        field="productname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="brandname">Brand<p-sortIcon
                                        field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="suppliername">Supplier<p-sortIcon
                                        field="suppliername">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="lotnumber">Lot Number<p-sortIcon
                                        field="lotnumber">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="severityname">Severity<p-sortIcon
                                        field="severityname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="stock">Stock<p-sortIcon field="stock">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="expirydate">Expiry Date<p-sortIcon
                                        field="expirydate">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="isconsignmentstockname">Is Consignment
                                    Stock<p-sortIcon field="isconsignmentstockname">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                            <tr>
                                <td>
                                    {{_ReportList.branchname}}
                                </td>
                                <td>
                                    {{_ReportList.producttypename}}
                                </td>
                                <td>
                                    {{_ReportList.categoryname}}
                                </td>
                                <td>
                                    {{_ReportList.productname}}
                                </td>
                                <td>
                                    {{_ReportList.brandname}}
                                </td>
                                <td>
                                    {{_ReportList.suppliername}}
                                </td>
                                <td>
                                    {{_ReportList.lotnumber}}
                                </td>
                                <td>
                                    {{_ReportList.severityname}}
                                </td>
                                <td>
                                    {{_ReportList.stock}}
                                </td>
                                <td>
                                    {{_ReportList.expirydate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_ReportList.isconsignmentstockname}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>