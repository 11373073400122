<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Promotion</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" class=""
                                [disabled]="isDisabledSave" (click)="onSave()"></button>
                            <button pButton pRipple label="" title="Clear" type="button" [disabled]="disablereset"
                                (click)="reset()" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                [routerLink]="['/vadmanagement']" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-tabView [(activeIndex)]="_Selectedtab">
                    <p-toast position="bottom-right"></p-toast>
                    <p-tabPanel header="Promotion Details">
                        <form [formGroup]="_admanagementform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="adname">
                                            <label for="adname">Ad Name <span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                    <td style="width:20%;">
                                        <span class="p-float-label">
                                            <p-calendar (onSelect)="OnInTimeDateSelect1($event)" [minDate]="Mintoday"
                                                dateFormat="dd/mm/yy" [showIcon]="true" formControlName="effectivefrom">
                                            </p-calendar>
                                            <label for="inputtext">Effective From<span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="( _admanagementform.get('effectivefrom').touched )&& _admanagementform.get('effectivefrom').errors?.required">
                                            Please Select Effective From
                                        </span>

                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-calendar formControlName="effectiveto" dateFormat="dd/mm/yy"
                                                [minDate]="minDate" [maxDate]="MaxDate" [showIcon]="true">
                                            </p-calendar>
                                            <label for="inputtext">Effective To<span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="( _admanagementform.get('effectiveto').touched )&& _admanagementform.get('effectiveto').errors?.required">
                                            Please Select Effective To
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-inputgroup" *ngIf="_Iscreatefileupload">
                                            <div class="file-upload-wrapper" data-text="Select your file!">
                                                <input #myInput name="file-upload-field"
                                                    accept="image/png, image/gif, image/jpeg" type="file"
                                                    class="file-upload-field" (change)="selectFile($event)" value="">
                                            </div>
                                        </div>
                                        <div class="p-inputgroup" *ngIf="_Isviewfileupload">
                                            <span class="p-float-label">
                                                <div class="browse-links">
                                                    <p-button label="Advertisement" styleClass="p-button-link"
                                                        title="{{imageselect}}"
                                                        (click)="showImageDialog('bottom-right')">
                                                    </p-button>
                                                </div>
                                            </span>
                                            <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                                [disabled]="clearimage" (click)="onImageClear()" tooltipPosition="top">
                                                <i class="pi pi-times"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-inputNumber formControlName="discountper" inputId="locale-us" mode="decimal"
                                                locale="en-US" [minFractionDigits]="1" [maxFractionDigits]="2"
                                                [useGrouping]="false" [min]="0" [max]="100" maxlength="5">
                                            </p-inputNumber>
                                            <label for="adname">Discount %</label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="( _admanagementform.get('discountper').touched )&& _admanagementform.get('discountper').errors?.required">
                                            Please Enter Discount %
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_status"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                formControlName="status">
                                            </p-dropdown>
                                            <label for="status">Status</label>
                                        </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                    </p-tabPanel>
                    <p-tabPanel header="Product Details">
                        <table class="normal-table">
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect optionLabel="categoryname" [options]="_Productcategory"
                                        optionValue="productcategoryid" [virtualScroll]="true" itemSize="30"
                                        selectedItemsLabel="{0} items selected" [(ngModel)]="_selectedProductCategory"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProducts()"
                                        [disabled]="_action == 'view'||_action == 'edit'" displaySelectedLabel="true">
                                    </p-multiSelect>
                                    <label for="adname">Product Category</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect optionLabel="productname" [options]="_Product"
                                        optionValue="productid" [virtualScroll]="true" itemSize="30"
                                        selectedItemsLabel="{0} items selected" [(ngModel)]="_selectedProductArrayIndex"
                                        [ngModelOptions]="{standalone: true}" (onChange)="OnChangeofProduct($event)"
                                        [disabled]="_action == 'view'" displaySelectedLabel="true">
                                    </p-multiSelect>
                                    <label for="adname">Product</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </table>
                    </p-tabPanel>
                </p-tabView>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>

<p-dialog header="Image Cropper" [(visible)]="imageCropperTool" [modal]="true" [style]="{width: '1200px'}"
    [baseZIndex]="10000">
    <div class="text-center mb10">
        <button pButton pRipple label="" icon="las la-plus" pTooltip="Zoom +" tooltipPosition="top" (click)="zoomIn()"
            class="p-button p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-minus" pTooltip="Zoom -" tooltipPosition="top" (click)="zoomOut()"
            class="p-button p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-redo-alt" pTooltip="Reset Image" tooltipPosition="top"
            (click)="resetImage()" class="p-button-danger p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-save" type="button" pTooltip="Save" tooltipPosition="top"
            (click)="saveImage($event)" class="p-button-success p-mr-2"></button>
    </div>
    <div class="row">
        <div class="col-md-6">
            <h5>Input</h5>
            <div class="crop-input">
                <image-cropper class="img-fluid" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="80 / 43" [resizeToWidth]="800"
                    [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false"
                    [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                    [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed()"></image-cropper>
            </div>
        </div>
        <div class="col-md-6">
            <h5>Output</h5>
            <img [src]="croppedImage" />
        </div>
    </div>
</p-dialog>
<!-- Image Upload -->
<p-dialog header="Ad Management" [(visible)]="displayimagepopup" [position]="position1" [style]="{width: '500px'}"
    [baseZIndex]="10000">
    <img [src]="admanagement" alt="" class="img-preview">

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="showPopupDialog()" label="Ok" styleClass="p-button-text">
        </p-button>
    </ng-template>
</p-dialog>