
export class Usercustomermapping {
    usercustomermappingid: number;
    userid: number;
    customerid: number;
    customertype: string;
    customermappingstatus: string;
    series: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}