<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>

        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Online Stock Delivery</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" [disabled]="isDisabledsave" type="submit"
                                (click)="onSave()" title="Save" icon="pi pi-save" class=""></button>
                            <button pButton pRipple label="" [disabled]="isDisablereset" type="button"
                                (click)="reset($event)" title="Clear" icon="pi pi-trash" class=""
                                class="p-button-danger"></button>
                            <button pButton pRiple label="" type="submit" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">

                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <form [formGroup]="_OnlineStockdeliveryform">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="stockdeliveryno">
                                    <label for="id">Delivery No</label>
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_OnlineStockdeliveryform">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" name="stockdeliverydate"
                                        formControlName="stockdeliverydate" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="id">Delivery Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_OnlineStockdeliveryform.get('stockdeliverydate').touched || _submitted) && _OnlineStockdeliveryform.get('stockdeliverydate').errors?.SelectDeliveryDate">
                                    Please Select Delivery Date
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <form [formGroup]="_OnlineStockdeliveryform">
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox formControlName="isselfpickup" label="Is Self Pickup?"
                                         (onChange)="OnSelectSelfPickup($event)"   binary="true"></p-checkbox>
                                    </div>
                                </div>
                            </form>
                        </td>
                        <td style="width: 20%;" *ngIf="IsNotOnlineBranch">
                            <form [formGroup]="onlineStockform">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" 
                                        [filter]="true" filterBy="username" [showClear]="true" [options]="_Tellernames" [virtualScroll]="true" itemSize="30"
                                        optionValue="userid" optionLabel="username" formControlName="tellerid">
                                    </p-dropdown>
                                    <label for="id">Teller Name</label>
                                </span>
                            </form>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <input type="text" name="productbarcode" autocomplete="off" [(ngModel)]="productbarcode"
                                    pInputText [disabled]=isdisabledbarcode (keyup.enter)="FetchProductUsingBarcode()">
                                <label for="productbarcode">Bar code</label>
                            </span>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <form [formGroup]="onlineStockform">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_invoiceno" optionLabel="salesinvoiceno"
                                        (onChange)="GetInvoiceProducts($event)" optionValue="salesinvoiceno"
                                        name="_evidenceno" [virtualScroll]="true" itemSize="30" 
                                        [(ngModel)]="_selectedevidencenoIndex" formControlName="salesinvoiceid">
                                    </p-multiSelect>
                                    <label for="id">Invoice No<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted && !_selectedevidencenoIndex.length)">
                                    Please Sales Invoice No
                                </span>
                            </form>
                        </td>
                        <td *ngIf="IsNotOnlineBranch">
                            <form [formGroup]="_OnlineStockdeliveryform">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_deliverytype"
                                        (onChange)="onchangedeliverytype($event)" optionValue="metasubcode"
                                        optionLabel="metasubdescription" formControlName="deliverytype">
                                    </p-dropdown>
                                    <label for="deliverytype">Delivery Type <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_OnlineStockdeliveryform.get('deliverytype').touched || _submitted) && _OnlineStockdeliveryform.get('deliverytype').errors?.SelectDeliverytype">
                                    Please Select Delivery Type
                                </span>
                            </form>
                        </td>
                        <td *ngIf="IsNotOnlineBranch"> 
                            <form [formGroup]="_OnlineStockdeliveryform">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [disabled]="isDisabledvehicle"
                                    (onChange)="ChangeVehicle($event)"  [filter]="true" filterBy="vehicleno" [showClear]="true" [options]="_vehicleno"
                                        optionValue="vehicleid" optionLabel="vehicleno" formControlName="vehicleid">
                                    </p-dropdown>
                                    <label for="id">Vehicle no</label>
                                </span>
                            </form>
                        </td>
                        <td *ngIf="IsNotOnlineBranch">
                            <form [formGroup]="_OnlineStockdeliveryform">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [disabled]="isDisableddriver" [filter]="true"
                                    filterBy="employeename" [showClear]="true" [virtualScroll]="true" itemSize="30"
                                        [options]="_drivername" optionValue="employeeid" optionLabel="employeename"
                                        formControlName="driverid">
                                    </p-dropdown>
                                    <label for="id">Driver Name</label>
                                </span>
                            </form>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <form [formGroup]="_OnlineStockdeliveryform">
                                <span class="p-float-label" rowspan="2">
                                    <textarea rows="3" cols="30" pInputTextarea formControlName="remarks"></textarea>
                                    <label for="inputtext">Remarks </label>
                                </span>
                            </form>

                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                </table>


                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_productdetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]" [rowHover]="true"
                        [globalFilterFields]="['customerorbranchname','productname','variantdescription','evidenceno','evidencedate','uomname','isDisabledotherdeliveryrequired','otherbranchstock','otherbranchid','isdelivered','totalinvoicequnatity','invoicequantity','currentstock','deliveryquantity','balancequantity']"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 50px;">
                                    <p-tableHeaderCheckbox [disabled]="_action == 'view' || _action == 'edit'"
                                        (click)="SelectAll($event)"></p-tableHeaderCheckbox>
                                </th>
                                <th>Order ID</th>
                                <th>Order No</th>
                                <th>Invoice No.</th>
                                <th>Invoice Quantity</th>
                                <th>Customer Name</th>
                                <th>Product Name</th>
                                <th>Variant Name</th>
                                <th>UOM</th>
                                <th>Collecting Branch</th>
                                <th>Current Stock</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_productdetail let-ri="rowIndex">
                            <tr>

                                <td style="text-align: center;width: 50px;">
                                    <p-tableCheckbox [disabled]="_action == 'view' || _action == 'edit'" [value]="_productdetail">
                                    </p-tableCheckbox>
                                </td>
                                <td>
                                    {{_productdetail.eorderid}}
                                </td>
                                <td>
                                    {{_productdetail.eorderno}}
                                </td>
                                <td>
                                    {{_productdetail.salesinvoiceno}}
                                </td>
                                <td>
                                    {{_productdetail.invoicequantity}}
                                </td>
                                <td>
                                    {{_productdetail.customername}}
                                </td>
                                <td>
                                    {{_productdetail.productname}}
                                </td>
                                <td >
                                    {{_productdetail.variantname }}
                                </td>
                               
                                <td>
                                    {{_productdetail.salesuomname}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_branches" [disabled]="_productdetail.IsCollectingBranch"
                                                optionLabel="branchname" (onChange)="GetStock(_productdetail)" optionValue="branchid"  [filter]="true" [showClear]="true"
                                                filterBy="branchname" 
                                                *ngIf="_action != 'view'" [(ngModel)]="_productdetail.collectingbranchid"
                                               >
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                [options]="_branches" optionLabel="branchname"
                                                optionValue="branchid"
                                                [(ngModel)]="_productdetail.collectingbranchid">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_productdetail.currentstock}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

            </div>
            <p-toast position="bottom-right"></p-toast>
        </div>
    </div>
</div>