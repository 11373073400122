<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Quote Finalize</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>

                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" (click)="ExportToPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_quotefinalizedetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['supplierquotesno','quotefinalizeddate','supplierquotesno','suppliername','productname','statusname','createdbyname','createdon']" [rowHover]="true"
                        dataKey="suppliername" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" [columns]="cols" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>

                                <th class="text-center" style="width: 120px;">Action </th>
                                <th pSortableColumn="supplierquotesno" [hidden]="true">Quote Finalize ID.
                                    <p-sortIcon field="supplierquotesno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="quotefinalizeddate">Quote Finalize Date.
                                    <p-sortIcon field="quotefinalizeddate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="supplierquotesno">Quote Finalize NO
                                    <p-sortIcon field="supplierquotesno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="suppliername">Supplier Name.
                                    <p-sortIcon field="suppliername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product Name.
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="customername">Product Price.<p-sortIcon field="customername">
                                </p-sortIcon>
                            </th> -->
                                <th pSortableColumn="statusname">Status.
                                    <p-sortIcon field="statusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_quotefinalizedetail>
                            <tr>

                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_quotefinalizedetail)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update" [disabled]="_quotefinalizedetail.status == 'MSC00047'|| _quotefinalizedetail.status == 'MSC00046'" (click)="edit(_quotefinalizedetail)"></button>
                                    <button pButton pRipple icon="las la-times" title="Cancel" [disabled]="_quotefinalizedetail.status == 'MSC00150' || _quotefinalizedetail.status == 'MSC00047' || !_Action._Delete " class="p-button p-button-danger" (click)="confirm(_quotefinalizedetail)"></button>

                                </td>
                                <td [hidden]="true">
                                    {{_quotefinalizedetail.supplierquotesno}}
                                </td>
                                <td>
                                    {{_quotefinalizedetail.quotefinalizeddate | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_quotefinalizedetail.supplierquotesno}}
                                </td>
                                <td>
                                    {{_quotefinalizedetail.suppliername}}
                                </td>
                                <td>
                                    {{_quotefinalizedetail.productname}}
                                </td>
                                <!-- <td>
                                    {{_quotefinalizedetail.productprice}}
                                </td> -->
                                <td>
                                    {{_quotefinalizedetail.statusname}}
                                </td>
                                <td>
                                    {{_quotefinalizedetail.createdbyname}}
                                </td>
                                <td>
                                    {{_quotefinalizedetail.createdon|date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <p-toast position="bottom-right"></p-toast>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </div>
        </div>
    </div>