<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Asset Value Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_branchlist" optionLabel="branchname" optionValue="branchid"
                                    [(ngModel)]="branchid" [filter]="true" [virtualScroll]="true" itemSize="30"
                                    [ngModelOptions]="{standalone: true}">
                                    <ng-template let-account pTemplate="item">
                                        <div
                                            [style]="account.branchstatus == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                            {{account.branchname}}</div>
                                    </ng-template>
                                </p-multiSelect>
                                <label for="producttypeid">Branch</label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-multiSelect [options]="_ProductList" optionLabel="productname"
                                    optionValue="productid" [(ngModel)]="productid" [filter]="true"
                                    [virtualScroll]="true" itemSize="30" [ngModelOptions]="{standalone: true}">
                                </p-multiSelect>
                                <label for="stocktype">Product</label>
                            </span>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                    <tr>
                        <td>
                            <button pButton pRipple label="Search" icon="pi pi-search" (click)="GenerateReport()"
                                class="p-button-sm p-button-success"></button>
                            <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()"
                                class="p-button-danger"></button>
                        </td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_assetValueReport" [scrollable]="true" scrollHeight="450px" [rows]="500"
                        [paginator]="true" [rowsPerPageOptions]="[100,200,300]"
                        [rowsPerPageOptions]="[500,1000,1500,2000]"
                        [globalFilterFields]="['RegistrationCode','ProductName','PurchaseDate','PurchaseAmount','CurrentAssetValue','ApporDepPerYear', 'ApporDepPerYearAmount']"
                        [rowHover]="true" dataKey="productname"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" pSortableColumn="RegistrationCode">Registration Code
                                    <p-sortIcon field="RegistrationCode">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="ProductName">Product Name
                                    <p-sortIcon field="ProductName">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="PurchaseDate">Purchase Date
                                    <p-sortIcon field="PurchaseDate">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="PurchaseAmount">Purchase Amount
                                    <p-sortIcon field="PurchaseAmount">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="CurrentAssetValue">Current Asset
                                    Value
                                    <p-sortIcon field="CurrentAssetValue">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="serviceatname">App Or Dep Per Year
                                    <p-sortIcon field="serviceatname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="serviceatname">App Or Dep Per Year
                                    Amount
                                    <p-sortIcon field="serviceatname">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                            <tr>
                                <td>
                                    {{_ReportList.registrationCode}}
                                </td>
                                <td>
                                    {{_ReportList.productName}}
                                </td>
                                <td style="text-align: right;">
                                    {{_ReportList.purchaseDate |date:'dd/MM/yyyy'}}
                                </td>
                                <td style="text-align: right;">
                                    {{_ReportList.purchaseAmount | number : '1.3'}}
                                </td>
                                <td style="text-align: right;">
                                    {{_ReportList.currentAssetValue | number : '1.3'}}
                                </td>
                                <td style="text-align: right;">
                                    {{_ReportList.apporDepPerYear | number : '1.2'}}
                                </td>
                                <td style="text-align: right;">
                                    {{_ReportList.apporDepPerYearAmount | number : '1.3'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>