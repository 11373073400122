import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class CodSettlementService {
  public _Action : IAction ;
  public  _CODSettlementService = environment.CODSettlementService;
  constructor(private http:HttpClient) { }

  PageOnload(CodSettlementId:number): Observable<any> {
    var Query = this._CODSettlementService+ '/PageOnLoad/' + CodSettlementId;
    return this.http.get<{data:any}>(Query);
  }
  GetSalesdetails(Date:string,tellerid:number,branchid:number): Observable<any> {
    var Query = this._CODSettlementService+ '/GetSalesdetails/' + Date+'/'+tellerid+'/'+branchid;
    return this.http.get<{data:any}>(Query);
  }
  Create(codsettlement:object) {
    var Query = this._CODSettlementService + '/Create'
    return this.http.post<{ data: any }>(Query,codsettlement);
  }
  FetchAll(fetchall:object) {
    var Query = this._CODSettlementService+ '/FetchAll';
    return this.http.post<{ data: any }>(Query,fetchall);
  }
 
 
}
