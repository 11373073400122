import { IfStmt, THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Branch } from 'src/app/core/Models/Branch';
import { Currency } from 'src/app/core/Models/Currency';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { TermsCondition } from 'src/app/core/Models/overallclass';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Purchaseorder } from 'src/app/core/Models/Purchaseorder';
import { Purchaseorderdetail } from 'src/app/core/Models/Purchaseorderdetail';
import { Purchaseorderothercharge } from 'src/app/core/Models/Purchaseorderothercharge';
import { Purchaseorderpaymentterm } from 'src/app/core/Models/Purchaseorderpaymentterm';
import { Quoterequest } from 'src/app/core/Models/Quoterequest';
import { Quoterequestproductdetail } from 'src/app/core/Models/Quoterequestproductdetail';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Supplierproductdetail } from 'src/app/core/Models/Supplierproductdetail';
import { Tax } from 'src/app/core/Models/Tax';
import { Termsandcondition } from 'src/app/core/Models/Termsandcondition';
import { PurchaseorderService } from 'src/app/core/Services/Purchase/purchaseorder.service';
import { Purchaseordergetproductsview } from 'src/app/core/Views/Purchaseordergetproductsview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { vaddress, vBillingaddress, vBranchnameSelect, vCurrencySelect, vDeliveryDateSelect, vDeliveryToBranchSelect, vProductCategorySelect, vProductName, vProductTypeName, vPurchaseOrderDateSelect, vPurchaseOrderTypeSelect, vQuantity, vSupplierName, vSupplierType, vTaxTypeSelect } from 'src/app/core/Validators/validation';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { stringify } from '@angular/compiler/src/util';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';
import { isNullOrUndefined } from 'util';
import { Supplierview } from 'src/app/core/Views/Supplierview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { KeyPress } from 'src/assets/js/KeyPress';

@Component({
  selector: 'app-c-purchaseorder',
  templateUrl: './c-purchaseorder.component.html',
  styleUrls: ['./c-purchaseorder.component.css']
})
export class CPurchaseorderComponent implements OnInit {
  _purchaseorderform: FormGroup;
  _purchaseorderdetailform: FormGroup;
  _Quoterequestsupplierdetailform: FormGroup;
  _Purchaseorderpaymenttermform: FormGroup;
  _supplierquicksaveform: FormGroup;
  _supplierquicksavedetailform: FormGroup;
  _Purchaseorderid: number;
  _Purchaseorderno: number;
  _PurchaseOrderNo: string;
  _action: string = "create";
  isDisabledsave = true;
  _purchaseorder: Purchaseorder;
  _productcategory: Productcategory[] = [];
  isDisablereset = true;
  _productname: Productoverallview[] = [];
  _tempproductname: Productoverallview[] = [];
  _value: boolean = false;
  _submitted = false;
  _productsubmitted = false;
  _currency: Currency[] = [];
  _referenceno: Quoterequest[] = [];
  _selectedreferencenoindex: number[] = [];
  _purchaseordertype: Metadatum[] = [];
  _taxtype: Metadatum[] = [];
  displayBasic: Boolean;
  _CurrenciesTemp: Currency[];
  _SelectedExchangerate: number;
  _grossamount: number;
  isDisabledclearproduct = true;
  isDisabledaddproduct = true;
  _uomdetails: Metadatum[] = [];
  _variantdetails: Productvariant[] = [];
  _producttype: Producttype[] = [];
  _ProductDetails: Purchaseordergetproductsview[] = [];
  _ProductDetail: Purchaseordergetproductsview;
  _Descriptions: Othercharge[] = [];
  _OtherCharges: Purchaseorderothercharge[] = [];
  _Charges: Purchaseorderothercharge[] = [];
  _OtherCharge: Purchaseorderothercharge[];
  _paymentterms: Termsandcondition[];
  selectedProductDetailsRows: any;
  selectedOtherChargesRows: any;
  _deliveryterms: Termsandcondition[];
  _otherterms: Termsandcondition[];
  _SelectedPaymentTerm: Purchaseorderpaymentterm;
  _SelectedPaymentTerms: Purchaseorderpaymentterm[] = [];
  _SelectedDeliveryTerm: Purchaseorderpaymentterm;
  _SelectedDeliveryTerms: Purchaseorderpaymentterm[] = [];
  _SelectedOtherTerm: Purchaseorderpaymentterm;
  _SelectedOtherTerms: Purchaseorderpaymentterm[] = [];
  _PurchaseOrderObj: any;
  _purchaseorderid: number;
  _selecteddeliveryid: number[] = [];
  _deliverybranchname: Branch[] = [];
  _billingbranchname: Branch[] = [];
  _suppliertype: Metadatum[] = [];
  displayBasictax: boolean;
  selectedEvidenceNo: any;
  _taxdetails: Tax[] = [];
  _taxname: string;
  _taxpercentage: string;
  _Supplierdetails: Supplierview[] = [];
  isDisabledreferno = false;
  _isshow: boolean;
  _ChargesTemp: Purchaseorderothercharge[];
  _OtherBalance: number;
  _otherchargesid: number;
  _selectedcurrency: string;
  _selectedamount: number;
  _purchaserate: number;
  taxpercentage: number;
  isDisabledtaxname = false;
  _selectedpaymentid: number[] = [];
  _selectedothertermsid: number[] = [];
  isDisabledremovesupplier = true;
  isDisabledremoveproduct = true;
  isDisabledsendapparoval = true;
  isDisabledsmail = true;
  isDisabledsendmail = true;
  isDisabledprint = true;
  isDisabledtaxpercentage = false;
  isDisabledtaxcode = false;
  isDisabledpurchaseorder = true;
  isDisabledpurchaseorderdate = true;
  isDisabledpayment = true;
  isDisableddelivery = true;
  isDisabledother = true;
  isDisabledsuppliepopup = false;
  _selectedPaymentIndex: number[] = [];
  _selectedDeliveryIndex: number[] = [];
  _selectedOtherIndex: number[] = [];
  _otherchargeamount: number;
  filteredProducts: any[];
  _validate: boolean;
  _IsProgressSpinner: boolean = true;
  isDisabledAdd = false;
  isDisabledRemove = false;
  _otherchargeamounttemp: any;
  _finlAmount: number;
  _TotalTaxAmount: number;
  today = new Date();
  HiddenExpiryDate: boolean = true;
  selectedsuppliers: any;
  productcatid: any[] = [];
  producttypeid: any[] = [];
  _ProductCategoryObj: any;
  _ProductTypeObj: any;
  _ProductObj: any;
  _Action: IAction;
  hidequantity: boolean = true;
  mindate: Date;
  maxdate: Date;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  _userid = sessionStorage["userid"];
  maildata: any;
  _branchid = sessionStorage["currentbranchid"]
  //_taxtype: Metadatum[] = [];
  constructor(private fb: FormBuilder, private utility: CommonUtilities, private _Purchaseorderservice: PurchaseorderService, private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService, private _router: Router, private _AccessRightsService: AccessRightsService,
    public keypress: KeyPress
  ) {
    this._Purchaseorderid = history.state?.purchaseorderid ? history.state?.purchaseorderid : 0;
    this._PurchaseOrderNo = history.state?.purchaseorderno ? history.state?.purchaseorderno : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._purchaseorderform = this.fb.group({
      companyid: [],
      branchid: [],
      purchaseorderid: [],
      purchaseorderno: [],
      purchaseorderdate: ['', vPurchaseOrderDateSelect],
      suppliertype: ['', vSupplierType],
      purchaseordertype: ['', vPurchaseOrderTypeSelect],
      taxtype: [''],
      currencyreferenceid: [],
      currencyid: ['', vCurrencySelect],
      supplierid: ['', vSupplierName],
      referenceno: [],
      deliverydate: ['', vDeliveryDateSelect],
      deliveryto: ['', vDeliveryToBranchSelect],
      deliverytoaddress: ['', vaddress],
      billingto: ['', vBranchnameSelect],
      billingaddress: ['', vBillingaddress],
      taxcode: [],
      taxamount: [],
      otherchargesamount: [],
      totalpoamount: [],
      amountinusd: [],
      status: [],
      statusremarks: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      rv: [],
      taxpercentage: [],
      taxname: [],
      suppliername: [],
      contactpersonname: [],
      address: [],
      grossamount: [],
      series: [],
      value: [],
      purchaseorderexpirydate: [],
      transactionincompleted: [1]
    });
    this._purchaseorderdetailform = this.fb.group({
      quoterequestdetailid: [],
      quoterequestid: [],
      producttypeid: [],
      productcategoryid: [],
      productid: ['', vProductName],
      variantid: [],
      quantity: [],
      stockinhand: [],
      uom: [],
      remarks: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
    if (sessionStorage['Environmentenddate'] == "null") {
      this._purchaseorderform.controls['purchaseorderdate'].setValue(new Date());
      this.maxdate = new Date();
    }
    else {
      this._purchaseorderform.controls['purchaseorderdate'].setValue(new Date(sessionStorage['Environmentenddate']));
    }


  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(40);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();
    this.hidequantity = true;
    this._Purchaseorderpaymenttermform = this.fb.group({
      paymenttermsid: [],
      deliveryermsid: [],
      othertermsid: []
    });
    this._IsProgressSpinner = true;
    var _userid = sessionStorage["userid"];
    this._Purchaseorderservice.PageOnLoad(this._Purchaseorderid, this._PurchaseOrderNo, this._branchid, _userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._currency = resultJSON.currencyList;
      this._CurrenciesTemp = resultJSON.currencyList;
      //5. supplier type load from metadata(local, international)
      this._suppliertype = resultJSON.supplierTypeList;
      //3. PO Type load from metadata(Quote based, Material Request,Direct)
      this._purchaseordertype = resultJSON.purchaseOrderTypeList
      this._Descriptions = resultJSON.otherChargeList
      this._taxdetails = resultJSON.taxList;
      //4. tax type load from metadata (item level, PO level)
      this._taxtype = resultJSON.taxTypeList;
      this._Supplierdetails = resultJSON.supplierList;
      this._producttype = resultJSON.productTypeList;

      this._productcategory = resultJSON.productCategoriesList;
      this._variantdetails = resultJSON.productVariantList;
      this._uomdetails = resultJSON.uomList;
      //9. Delivery terms load from terms and condition master based on Terms Type
      this._deliveryterms = resultJSON.deliveryTermsList;
      //11. Other terms load from terms and condition master based on Terms Type
      this._otherterms = resultJSON.otherTermsList;
      //9. payment terms load from terms and condition master based on Terms Type
      this._paymentterms = resultJSON.paymentTermsList;
      this._ChargesTemp = resultJSON.otherChargeList;
      this.isDisabledtaxcode = true;
      this._billingbranchname = resultJSON.billingBranchList;
      //6. deleivery to  load from branch master (all branch name)
      this._deliverybranchname = resultJSON.deliveryBranchList;
      this._purchaseorderform.controls['purchaseorderdate'].setValue(new Date());
      this._purchaseorderform.controls['deliverydate'].setValue(new Date());
      this._purchaseorderform.controls['deliveryto'].setValue(parseInt(sessionStorage["BranchID"]));
      this._purchaseorderform.controls['purchaseordertype'].setValue(this._purchaseordertype[0].metasubcode);

      this._IsProgressSpinner = false;
      for (var i = 0; i < this._ProductDetails.length; i++) {
        this._ProductDetails[i].taxcode = this._taxdetails[0].taxcode;
      }
      if (this._action == 'create') {
        var l_othercharge = new Purchaseorderothercharge();
        l_othercharge.purchaseorderid = 0;
        l_othercharge.otherchargesid = 0;
        l_othercharge.createdby = sessionStorage["userid"];
        l_othercharge.modifiedby = sessionStorage["userid"];
        l_othercharge.createdon = new Date();
        l_othercharge.modifiedon = new Date();
        this._OtherCharges.push(l_othercharge);
        this._Charges = this._OtherCharges;
      }
      if (this._action == 'create') {
        this.isDisabledtaxpercentage = false;
        //12.save , clear button must be enabled 
        this.isDisabledsave = false;
        this.isDisablereset = false;
        //13. Sent to approve button should be disabled
        this.isDisabledsendapparoval = true;
        this.isDisabledsendmail = true;
        this.isDisabledprint = true;
        this.isDisabledremoveproduct = false;
        this.isDisabledpurchaseorder = false;
        this.isDisabledpurchaseorderdate = true
        this.isDisabledpayment = false;
        this.isDisableddelivery = false;
        this.isDisabledother = false;
        this.onchangedelivery();

        if (this._purchaseorderform.get("purchaseordertype").value == "MSC00198") {
          //this._purchaseorderdetailform.get('quantity').setValidators(vQuantity);
          this.isDisabledreferno = true;
          this.isDisabledaddproduct = false;
          this.isDisabledclearproduct = false;
        }
      }
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));

        if (updateJSON.purchaseorderheaderlist[0].purchaseorderexpirydate != null) {
          updateJSON.purchaseorderheaderlist[0].purchaseorderexpirydate = new Date(updateJSON.purchaseorderheaderlist[0].purchaseorderexpirydate);
          this.HiddenExpiryDate = false;
        }

        //114. according to the purchase order no ., all the datas should fetch in particular columns.
        updateJSON.purchaseorderheaderlist[0].purchaseorderdate = new Date(updateJSON.purchaseorderheaderlist[0].purchaseorderdate);
        updateJSON.purchaseorderheaderlist[0].deliverydate = new Date(updateJSON.purchaseorderheaderlist[0].deliverydate);
        if (updateJSON.purchaseorderheaderlist[0].taxtype == "MSC00201") {
          this.isDisabledtaxcode = false;
        }
        this._billingbranchname = updateJSON.billingBranchList;
        this._deliverybranchname = updateJSON.deliveryBranchList;
        this._purchaseorderform.setValue(updateJSON.purchaseorderheaderlist[0]);
        this._purchaseorderform.controls['billingto'].setValue(parseInt(updateJSON.purchaseorderheaderlist[0].billingto));
        this._purchaseorderform.controls['deliveryto'].setValue(parseInt(updateJSON.purchaseorderheaderlist[0].deliveryto));
        this._ProductDetails = updateJSON.purchaseorderdetaillist;
        this._OtherCharges = updateJSON.purchaseorderotherchargelist;
        this._Descriptions = updateJSON.purchaseorderotherchargelist;
        this._producttype = resultJSON.productTypeList;
        this.isDisabledsave = false;
        this.isDisablereset = true;
        this.isDisabledaddproduct = false;
        this.isDisabledclearproduct = false;
        this.isDisabledpurchaseorder = true;
        this.isDisabledremoveproduct = false;
        this.isDisabledpayment = false;
        this.isDisableddelivery = false;
        this.isDisabledother = false;
        this.isDisabledsuppliepopup = true;

        if (updateJSON.purchaseorderheaderlist[0].purchaseordertype == "MSC00199") {
          this._referenceno = updateJSON.refNoList;
          var referenceid: number[] = [];
          Object.keys(updateJSON.purchaseorderupdatereflist).map((Index) => {
            referenceid.push(updateJSON.purchaseorderupdatereflist[Index].refid);
          });
          this._selectedreferencenoindex = referenceid;
          this.isDisabledreferno = false;
        }
        else {
          this.isDisabledreferno = true;
        }
        var termsandconditionidpayment: number[] = [];
        Object.keys(updateJSON.purchaseorderpaymenttermlist).map((Index) => {
          termsandconditionidpayment.push(updateJSON.purchaseorderpaymenttermlist[Index].termsandconditionid);
        });
        this._selectedPaymentIndex = termsandconditionidpayment;
        this._SelectedPaymentTerms = updateJSON.purchaseorderpaymenttermlist;

        var termsandconditioniddelivery: number[] = [];
        Object.keys(updateJSON.purchaseorderdeliverytermlist).map((Index) => {
          termsandconditioniddelivery.push(updateJSON.purchaseorderdeliverytermlist[Index].termsandconditionid);
        });
        this._selectedDeliveryIndex = termsandconditioniddelivery;
        this._SelectedDeliveryTerms = updateJSON.purchaseorderdeliverytermlist;

        var termsandconditionidother: number[] = [];
        Object.keys(updateJSON.purchaseorderothertermlist).map((Index) => {
          termsandconditionidother.push(updateJSON.purchaseorderothertermlist[Index].termsandconditionid);
        });
        this._selectedOtherIndex = termsandconditionidother;
        this._SelectedOtherTerms = updateJSON.purchaseorderothertermlist;
      }
      if (this._action == 'view') {

        this.isDisabledaddproduct = true;
        this.isDisabledclearproduct = true;
        this.isDisabledremoveproduct = true;

        if (this._purchaseorderform.get("purchaseordertype").value == "MSC00198") {
          this.isDisabledreferno = true;
        }
        this._purchaseorderform.disable();
        this._purchaseorderdetailform.disable();
        this._Purchaseorderpaymenttermform.disable();
        this.isDisabledsave = true;
        this.isDisabledpayment = true;
        this.isDisableddelivery = true;
        this.isDisabledother = true;
        this.isDisabledAdd = true;
        this.isDisabledRemove = true;
      }
      if (this._action == 'edit') {
        this.isDisabledsendapparoval = false;
      }

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  onsamedelivery(event) {
    if (event.checked == true) {
      this._purchaseorderform.controls['billingaddress'].setValue(this._purchaseorderform.controls['deliverytoaddress'].value);
      this._purchaseorderform.controls['billingto'].setValue(this._purchaseorderform.controls['deliveryto'].value);
    }
    else {
      this._purchaseorderform.controls.billingaddress.reset();
      this._purchaseorderform.controls.billingto.reset();
    }
  }
  taxtype(event) {
    if (this._purchaseorderform.get("taxtype").value == "MSC00200") {
      this.isDisabledtaxcode = true;
      this.isDisabledtaxpercentage = false;
      var userid = sessionStorage
      this._IsProgressSpinner = true;

      this._Purchaseorderservice.PageOnLoad(this._Purchaseorderid, this._PurchaseOrderNo, this._branchid, this._userid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._taxdetails = resultJSON.taxList;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

    }
    else {
      //17.   if item level selected, Tax can be given for each and every item(Disable tax section)
      this.isDisabledtaxcode = false;
      this.isDisabledtaxpercentage = true;
      this._taxdetails = [];
      for (var i = 0; i < this._ProductDetails.length; i++) {
        this._ProductDetails[i].taxamount = 0;
        this._IsProgressSpinner = false;
      }
    }
  }

  allocatequantity(row: Purchaseordergetproductsview) {
    let taxAmount = 0;
    let unitPrice = row.unitprice;
    let productAmount = row.quantity * unitPrice;
    taxAmount = (productAmount * (row.taxpercentage / 100));
    row.taxamount = taxAmount;
    let finalAmount = (productAmount + taxAmount);
    row.finalamount = finalAmount;
    this.calculateamountdetails();
  }

  getSupplier(event) {
    var suppliertypecode = event.value;
    //24.on clicking one particular supplier , the pop up gets closed and the row data fetches to the supplier code, supplier name, supplier mobile no,currency and supplier address
    if (suppliertypecode.length == 0 || suppliertypecode == null) {
      this._Supplierdetails = [];
      this._purchaseorderform.controls['suppliername'].reset();
      this._purchaseorderform.controls['address'].reset();
      this._purchaseorderform.controls['referenceno'].reset();
      this._purchaseorderform.controls['contactpersonname'].reset();
      return;
    }
    this._IsProgressSpinner = true;
    this._purchaseorderform.controls['suppliername'].reset();
    this._purchaseorderform.controls['address'].reset();
    this._purchaseorderform.controls['referenceno'].reset();
    this._purchaseorderform.controls['contactpersonname'].reset();
    this._Purchaseorderservice.getSupplier(suppliertypecode, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Supplierdetails = resultJSON.supplierList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSelectDeliveryTerms(event) {
    var l_Deliveryterms: Purchaseorderpaymentterm[] = [];
    var l_deliveryterm: Purchaseorderpaymentterm;
    var l_deliverytermlist: Termsandcondition[] = this._deliveryterms;
    Object.keys(event.value).map((Index) => {
      l_deliveryterm = new Purchaseorderpaymentterm();
      l_deliveryterm.termsandconditionid = event.value[Index];
      l_deliveryterm.termdescription = l_deliverytermlist[Index].termsdescription;
      l_deliveryterm.purchaseorderid = 0;
      l_deliveryterm.purchaseorderpaymentdetailid = 0;
      l_deliveryterm.termstype = l_deliverytermlist[Index].termstype;
      l_deliveryterm.createdon = new Date();
      l_deliveryterm.createdby = parseInt(sessionStorage["userid"]);
      l_deliveryterm.modifiedby = parseInt(sessionStorage["userid"]);
      l_deliveryterm.modifiedon = new Date();
      l_Deliveryterms.push(l_deliveryterm);
    })
    this._SelectedDeliveryTerms = l_Deliveryterms;
  }
  onSelectPaymentTerms(event) {
    var l_paymentterms: Purchaseorderpaymentterm[] = [];
    var l_paymentterm: Purchaseorderpaymentterm;
    var l_paymentlist: Termsandcondition[] = this._paymentterms;
    Object.keys(event.value).map((Index) => {
      l_paymentterm = new Purchaseorderpaymentterm();
      l_paymentterm.termsandconditionid = event.value[Index];
      l_paymentterm.termdescription = l_paymentlist[Index].termsdescription;
      l_paymentterm.termstype = l_paymentlist[Index].termstype;
      l_paymentterm.purchaseorderpaymentdetailid = 0;
      l_paymentterm.purchaseorderid = 0;
      l_paymentterm.createdon = new Date();
      l_paymentterm.createdby = parseInt(sessionStorage["userid"]);
      l_paymentterm.modifiedby = parseInt(sessionStorage["userid"]);
      l_paymentterm.modifiedon = new Date();
      l_paymentterms.push(l_paymentterm);
    })
    this._SelectedPaymentTerms = l_paymentterms;
  }
  onSelectOtherTerms(event) {
    var l_Otherterms: Purchaseorderpaymentterm[] = [];
    var l_otherterm: Purchaseorderpaymentterm;
    var l_othertermlist: Termsandcondition[] = this._otherterms;
    Object.keys(event.value).map((Index) => {
      l_otherterm = new Purchaseorderpaymentterm();
      l_otherterm.termsandconditionid = event.value[Index];
      l_otherterm.termdescription = l_othertermlist[Index].termsdescription;
      l_otherterm.termstype = l_othertermlist[Index].termstype;
      l_otherterm.purchaseorderpaymentdetailid = 0;
      l_otherterm.purchaseorderid = 0;
      l_otherterm.createdon = new Date();
      l_otherterm.createdby = parseInt(sessionStorage["userid"]);
      l_otherterm.modifiedby = parseInt(sessionStorage["userid"]);
      l_otherterm.modifiedon = new Date();
      l_Otherterms.push(l_otherterm);
    })
    this._SelectedOtherTerms = l_Otherterms;
  }
  reset(event) {
    this._submitted = true;
    this._productsubmitted = true;
    this._Purchaseorderpaymenttermform.reset();
    this._purchaseorderform.reset();
    this._purchaseorderdetailform.reset();
    this.InitializeForm();
    this._OtherCharges = [];
    this._ProductDetails = [];
    this._submitted = false;
    this._productsubmitted = false;
    var l_othercharge = new Purchaseorderothercharge();
    l_othercharge.purchaseorderotherchargesid = 0;
    l_othercharge.purchaseorderid = 0;
    l_othercharge.createdby = sessionStorage["userid"];
    l_othercharge.modifiedby = sessionStorage["userid"];
    l_othercharge.createdon = new Date();
    l_othercharge.modifiedon = new Date();
    l_othercharge.accountingyear = new Date().getFullYear();
    this.isDisabledsave = false;
    this.isDisabledsendapparoval = true;
    this._OtherCharges.push(l_othercharge);
    this._Charges = this._OtherCharges;
  }
  OnchangeOtherCharge(pOtherCharges: Purchaseorderothercharge, event: any) {
    let errorMsg = '';
    let isAlreadyExists = this._OtherCharges.filter(s => s.otherchargesid == event.value).length > 1;
    if (isAlreadyExists) {
      errorMsg = 'Already Exists';
      return;
    }
    this._otherchargeamount = (this._Descriptions || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount;
    pOtherCharges.otherchargesamount = this._otherchargeamount;
    pOtherCharges.otherchargesid = event.value;
    this.calculateamountdetails();
  }
  //Change Other charges amount
  OnChangeotherCharge(row: any, event) {
    var selectedindex = this._OtherCharges.findIndex(obj => obj == row);
    let OhterChargesAmount = this._OtherCharges[selectedindex].otherchargesamount;
    this._purchaseorderform.controls['otherchargesamount'].setValue(OhterChargesAmount ? OhterChargesAmount : 0);
    this.calculateamountdetails();
  }

  calculateamountdetails() {
    if (this._ProductDetails.length > 0) {
      var grossamount = 0.0;
      var taxamount = 0.0;
      var otherchargeamount = 0.0;
      for (var i = 0; i < this._ProductDetails.length; i++) {
        grossamount += this._ProductDetails[i].finalamount;
        taxamount += this._ProductDetails[i].taxamount;
      }
      for (var i = 0; i < this._OtherCharges.length; i++) {
        if (this._OtherCharges[i].otherchargesamount != undefined || this._OtherCharges[i].otherchargesamount != null) {
          otherchargeamount += this._OtherCharges[i].otherchargesamount;
        }
      }
      this._finlAmount = parseFloat(grossamount + '') + parseFloat(otherchargeamount + '') + parseFloat(taxamount + '');
      this._purchaseorderform.controls['grossamount'].setValue(parseFloat(grossamount + '').toFixed(3));
      this._purchaseorderform.controls['taxamount'].setValue(parseFloat(taxamount + '').toFixed(3));
      this._purchaseorderform.controls['otherchargesamount'].setValue(parseFloat(otherchargeamount + '').toFixed(3));
      this._purchaseorderform.controls['totalpoamount'].setValue(this._finlAmount.toFixed(3));
      //  calculating amountinusd
      var currencyid = this._purchaseorderform.get("currencyid").value
      if (currencyid != 0) {
        var selectedcurrencyexchangrate = (this._currency || []).filter(f => f.currencyid == currencyid)[0].exchangerate;
        var amountinusd = this._finlAmount / selectedcurrencyexchangrate;
        this._purchaseorderform.controls['amountinusd'].setValue(amountinusd);
      }
    }
  }

  getProductCategory(event) {
    var selectedProductType = event.value;
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategory = [];
      this._purchaseorderdetailform.controls["productcategoryid"].reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": this._branchid || 0 };
      this._Purchaseorderservice.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategory = resultJSON.productCategoriesList;
        //this._productcategory = [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    let productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
    this._productname = productList.length ? productList : this._tempproductname;
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productname = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": this._branchid || 0 };
      this._Purchaseorderservice.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        // this._productname = resultJSON.productList;
        this._IsProgressSpinner = false;
        this._productname = <Productoverallview[]>resultJSON.productList || [];
        this._tempproductname = <Productoverallview[]>resultJSON.productList || [];
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    let productList = [];
    if (this.productcatid.length) {
      productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid) && this.productcatid.includes(f.productcategoryid)) || [];
    } else {
      productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
    }
    this._productname = productList;
  }
  getVariant(event) {
    var productid = event.productid;
    // var productid = (this._purchaseorderdetailform.get("productid").value.productid);
    var purchaserateinusd = (this._productname || []).filter(f => f.productid == productid)[0].purchaserateinusd;
    var currencyid = this._purchaseorderform.get("currencyid").value
    if (!currencyid) {
      return;
    }
    if (currencyid != 0) {
      this._SelectedExchangerate = (this._currency || []).filter(f => f.currencyid == currencyid)[0].exchangerate;
      this._purchaserate = this._SelectedExchangerate * purchaserateinusd;
      if (currencyid == 1) {
        this._purchaserate = purchaserateinusd;
      }

    }

    if (productid == undefined || productid == null) {
      this._variantdetails = [];
    }
    else {
      this._IsProgressSpinner = true;
      this._Purchaseorderservice.getVariant(productid, this._purchaseorderform.get("deliveryto").value || this._branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategory = resultJSON.productCategoriesList;
        this._variantdetails = resultJSON.productVariantList;
        this._purchaseorderdetailform.controls['stockinhand'].setValue(resultJSON.collectingBranchStock.stock1 || 0)
        this._IsProgressSpinner = false;
        let product = this._tempproductname.filter(f => f.productid == productid)[0];
        this.producttypeid = [];
        this.producttypeid.push(product.producttypeid);
        this.producttypeid = [];
        this.productcatid.push(product.productcategoryid);
        this._purchaseorderdetailform.controls['producttypeid'].setValue(this.producttypeid);
        this._purchaseorderdetailform.controls['productcategoryid'].setValue(this.productcatid);
        var uom = resultJSON.productheader[0].purchaseuom;
        if (uom == "MSC00037") {
          this.hidequantity = true;
        }
        else {
          this.hidequantity = false
        }
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  delivertoclear() {
    this._purchaseorderdetailform.controls['productid'].reset();
    this._purchaseorderdetailform.controls['stockinhand'].setValue(0);
    this._purchaseorderdetailform.controls['quantity'].reset();
    this._purchaseorderdetailform.controls['variantid'].reset();
    this._purchaseorderdetailform.controls['producttypeid'].reset();
    this.productcatid = []
  }

  onAddProduct(event) {
    var role = sessionStorage["currentRole"];
    if (!this._purchaseorderform.get("currencyid").value) {
      this._CustomExceptionService.handleWarning("Please select Currency Name");
      return;
    }
    //40.check the mandatory fields in the material details section, 
    this._productsubmitted = true;
    if (this._purchaseorderform.controls['purchaseordertype'].value == "MSC00202") {

      this._purchaseorderdetailform.controls['quantity'].updateValueAndValidity();
    }
    // else {
    // this._purchaseorderdetailform.controls['quantity'].setValidators(vQuantity);
    // }
    if (this._purchaseorderdetailform.valid) {

      var purchaseorderproductdetail = new Purchaseordergetproductsview();
      purchaseorderproductdetail.productid = this._purchaseorderdetailform.get("productid").value.productid;
      purchaseorderproductdetail.quantity = this._purchaseorderdetailform.get("quantity").value;
      if (this._purchaseorderform.controls['purchaseordertype'].value == "MSC00202") { //Open Purchase
        if (this._purchaseorderdetailform.get("quantity").value == "" || this._purchaseorderdetailform.get("quantity").value == null) {
          purchaseorderproductdetail.quantity = 0;
        }
      }
      if (this._purchaseorderdetailform.get("variantid").value == "" || this._purchaseorderdetailform.get("variantid").value == null) {
        purchaseorderproductdetail.variantid = 0;
        purchaseorderproductdetail.variantname = "";
      }
      else {
        var variantid = (this._purchaseorderdetailform.get("variantid").value);
        var variantname = (this._variantdetails || []).filter(f => f.productvariantdetailid == variantid)[0].variantdescription;
        purchaseorderproductdetail.variantid = parseInt(variantid);
        purchaseorderproductdetail.variantname = variantname;
      }
      var productid = (this._purchaseorderdetailform.get("productid").value.productid);
      var productname = (this._productname || []).filter(f => f.productid == productid)[0].productname;
      var productcode = (this._productname || []).filter(f => f.productid == productid)[0].productcode;
      var purchaseuomname = (this._productname || []).filter(f => f.productid == productid)[0].purchaseuomname;
      var tax = (this._productname || []).filter(f => f.productid == productid)[0].vatapplicable;
      var purchaseuomcode = (this._productname || []).filter(f => f.productid == productid)[0].purchaseuom;
      var purchasetaxapplicable = (this._Supplierdetails || []).filter(f => f.supplierid == parseInt(this._purchaseorderform.get("supplierid").value))[0].vattype;
      purchaseorderproductdetail.productid = parseInt(productid);
      purchaseorderproductdetail.productname = productname;
      purchaseorderproductdetail.productcode = productcode;
      purchaseorderproductdetail.uomname = purchaseuomname;
      purchaseorderproductdetail.uomcode = purchaseuomcode;
      purchaseorderproductdetail.createdby = parseInt(sessionStorage["userid"]);
      purchaseorderproductdetail.modifiedby = parseInt(sessionStorage["userid"]);
      purchaseorderproductdetail.modifiedon = new Date();
      purchaseorderproductdetail.vatapplicable = tax;

      var currencyid = this._purchaseorderform.get("currencyid").value
      if (currencyid != 0) {
        //44. the qty multiplies with unit price and discount percentage calculation is done to get  the final amount 
        purchaseorderproductdetail.unitprice = this._purchaserate;
        purchaseorderproductdetail.finalamount = purchaseorderproductdetail.unitprice * purchaseorderproductdetail.quantity
      }
      else {
        this._CustomExceptionService.handleWarning("Please Select Currency!");
        return;
      }
      if (this._taxdetails.length > 0 && purchasetaxapplicable == "MSC00102") {
        var TaxPercentage = (this._taxdetails || []).filter(f => f.taxcode == 'VAT')[0].taxpercentage;
        var Taxid = (this._taxdetails || []).filter(f => f.taxcode == 'VAT')[0].taxid;
        if (purchaseorderproductdetail.vatapplicable) {
          purchaseorderproductdetail.taxpercentage = TaxPercentage;
          purchaseorderproductdetail.taxamount = purchaseorderproductdetail.finalamount * TaxPercentage / 100;
          purchaseorderproductdetail.taxcode = 'VAT';
          purchaseorderproductdetail.taxid = Taxid;

        }
        else {
          purchaseorderproductdetail.taxpercentage = 0;
          purchaseorderproductdetail.taxamount = 0;
          purchaseorderproductdetail.taxid = 0;
          purchaseorderproductdetail.taxid = 0;
        }

      } else {
        purchaseorderproductdetail.taxamount = 0;
        purchaseorderproductdetail.taxid = 0;
        purchaseorderproductdetail.taxpercentage = 0;
      }

      purchaseorderproductdetail.createdon = new Date();
      if (this._ProductDetails.length > 0) {
        var count = 0;
        for (var i = 0; i < this._ProductDetails.length; i++) {
          if (purchaseorderproductdetail.productid == this._ProductDetails[i].productid && purchaseorderproductdetail.variantid == this._ProductDetails[i].variantid) {
            this._CustomExceptionService.handleWarning("Product or Variant Already Exists!");
            count += 1;
            return;
          }
        }
        this._ProductDetails.push(purchaseorderproductdetail);
      } else {
        //41.if every column is valid , push all the material sections columns to the grid 
        this._ProductDetails.push(purchaseorderproductdetail);
      }
      this._productsubmitted = false;
      this.calculateamountdetails();
      this._purchaseorderdetailform.reset();
      this.productcatid = [];
    }
  }

  onSelectreferenceno(event) {
    var selectedProductType = event.value;
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._ProductDetails = [];
      return;
    } else {
      //27.On selecting quote no , the Correspoding Material Details Should be fetched in Material Details Grid 
      this._IsProgressSpinner = true;
      var supplierid = this._purchaseorderform.get("supplierid").value;
      this._Purchaseorderservice.OnRefNoChange(selectedProductType, supplierid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._ProductDetails = resultJSON.productDetailsList;
        //this._purchaseorderform.get("currencyid").setValue(resultJSON.productDetailsList[0].currencyid);
        this._purchaseorderform.controls['currencyid'].setValue(parseInt(resultJSON.productDetailsList[0].currencyid));

        this.calculateamountdetails();

        //calculating amountinusd
        // var currencyid = this._purchaseorderform.get("currencyid").value
        // if (currencyid != 0) {
        //   var currencyid = this._purchaseorderform.get("currencyid").value;
        //   var usd = 1;
        //   var usdexchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == usd)[0].exchangerate;
        //   var ExchangeRate = (this._currency || []).filter(f => f.currencyid == currencyid)[0].exchangerate;
        //   this._SelectedExchangerate = ExchangeRate;
        //   var _SelectedExchangerate = usdexchangerate / this._SelectedExchangerate;
        //   var amountinusd = _SelectedExchangerate * this._purchaseorderform.get("totalpoamount").value;
        //   //  this._purchaseorderform.controls['totalpoamount'].setValue(amountinusd.toFixed(3));
        //   this._purchaseorderform.controls['amountinusd'].setValue(amountinusd);
        // }
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

    }
  }

  validate(): boolean {
    if (this._ProductDetails.length == 0) {
      //105.if there is no record in the material details, show error as "Please add atleast one row in the  materials grid"
      this._validate = false;
    }
    else {
      this._validate = true;
    }
    return this._validate;
  }
  onSave() {
    this._submitted = true;

    if (this._purchaseorderform.valid) {
      if (!this.validate()) {
        this._CustomExceptionService.handleWarning("Please add atleast one row in the  materials grid!");
        return;
      }
      if (this._purchaseorderform.get("purchaseordertype").value == "MSC00202" && !this._purchaseorderform.get("purchaseorderexpirydate").value) {
        this._CustomExceptionService.handleWarning("Please give Purchase order Expiry date!");
        return;
      }
      this.isDisabledsave = true;
      //For Model and Form Mapping
      this._purchaseorder = this._purchaseorderform.value;
      var datePipe = new DatePipe("en-US");
      var PurchaseOrderDate = this._purchaseorderform.get("purchaseorderdate").value;
      if (this._purchaseorderform.get("purchaseorderexpirydate").value) {
        var PurchaseOrderExpiryDate = this._purchaseorderform.get("purchaseorderexpirydate").value;
        var pPurchaseOrderExpiryDate = datePipe.transform(PurchaseOrderExpiryDate, 'yyyy-MM-dd');
        this._purchaseorder.purchaseorderexpirydate = (new Date(pPurchaseOrderExpiryDate));
      }
      var DeliveryDate = this._purchaseorderform.get("deliverydate").value;
      var pPurchaseOrderDate = datePipe.transform(PurchaseOrderDate, 'yyyy-MM-dd');
      var pDeliveryDate = datePipe.transform(DeliveryDate, 'yyyy-MM-dd');

      this._purchaseorder.purchaseorderdate = (new Date(pPurchaseOrderDate));
      this._purchaseorder.deliverydate = (new Date(pDeliveryDate));
      this._SelectedPaymentTerms = this._SelectedPaymentTerms.concat(this._SelectedOtherTerms);
      this._SelectedPaymentTerms = this._SelectedPaymentTerms.concat(this._SelectedDeliveryTerms);
      this._PurchaseOrderObj = { "Purchaseorder": this._purchaseorder, "Purchaseorderdetails": this._ProductDetails, "Purchaseorderothercharges": this._OtherCharges, "Purchaseorderpaymentterms": this._SelectedPaymentTerms };
      if (this._action == 'create') {
        this._purchaseorder.createdby = parseInt(sessionStorage["userid"]);
        this._purchaseorder.companyid = 0;
        this._purchaseorder.currencyreferenceid = 0;
        this._purchaseorder.purchaseorderid = 0;
        this._purchaseorder.referenceno = null;
        this._purchaseorder.createdon = new Date();
        this._purchaseorder.accountingyear = new Date().getFullYear();
        this._purchaseorder.taxtype = "MSC00200";
        this._purchaseorder.branchid = parseInt(sessionStorage["BranchID"]);
        // this.onEd();
        this._IsProgressSpinner = true;
        this._Purchaseorderservice.create(this._PurchaseOrderObj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          this._PurchaseOrderNo = resultJSON.purchaseorderno;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsProgressSpinner = false;
            //75.then enable the "sent to approve" button
            this.isDisabledsendapparoval = false;
            this.isDisabledsendmail = false;
            this.isDisabledprint = false;
            this.isDisabledsave = true;
            this.isDisabledsmail = false;

          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            this.isDisabledsave = false;
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isDisabledsave = false; });

      }
      else if (this._action == 'edit') {
        this._purchaseorder.modifiedon = new Date();
        this._purchaseorder.modifiedby = sessionStorage["userid"];
        this._purchaseorder.referenceno = null;
        //this.onEd();
        this._IsProgressSpinner = true;

        this._Purchaseorderservice.edit(this._PurchaseOrderObj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this._PurchaseOrderNo = resultJSON.purchaseorderno;
            this._IsProgressSpinner = false;
            this.isDisabledsendapparoval = false;
            this.isDisabledsendmail = false;
            this.isDisabledprint = false;
            this.isDisabledsave = true;
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            this.isDisabledsave = true;
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isDisabledsave = true; });

      }
    }
  }
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._PurchaseOrderObj;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  onchangepurchaseordertype(event) {
    //14.On changing PO type , if quote based selected . The "quote no" from Purchase Quotation screen  fetches to ref no column 
    this.isDisabledreferno = false;
    this.isDisabledaddproduct = false;
    this.isDisabledclearproduct = false;
    if (event.value == "MSC00199") { //Quote Based
      this.HiddenExpiryDate = true;
      //this._purchaseorderdetailform.get('quantity').setValidators(vQuantity);
      this.isDisabledaddproduct = true;
      this.isDisabledclearproduct = true;
      this.ClearonChangePOtype();
    }
    else if (event.value == "MSC00198") { //Direct Purchase
      this.HiddenExpiryDate = true;
      // this._purchaseorderdetailform.get('quantity').setValidators(vQuantity);

      this.isDisabledreferno = true;
      this.ClearonChangePOtype();
    }
    else {  //Open Purchase
      this.HiddenExpiryDate = false;
      this.isDisabledreferno = true;
      this._purchaseorderdetailform.controls['quantity'].clearValidators();
      // this._purchaseorderform.controls.referenceno.reset();
      this.ClearonChangePOtype();
    }
  }

  ClearonChangePOtype() {
    this._ProductDetails = [];
    this._purchaseorderform.controls.referenceno.reset();
    this._purchaseorderform.controls.grossamount.reset();
    this._purchaseorderform.controls.taxamount.reset();
    this._purchaseorderform.controls.otherchargesamount.reset();
    this._purchaseorderform.controls.totalpoamount.reset();
    this._purchaseorderform.controls.suppliername.reset();
    this._purchaseorderform.controls.suppliertype.reset();
    this._purchaseorderform.controls.address.reset();
    this._purchaseorderform.controls.contactpersonname.reset();
    this._purchaseorderform.controls.currencyid.reset();

  }

  disablepo(event, objectModel) {
    if (this._purchaseorderform.get("taxtype").value == "MSC00200") {
      objectModel["isDisabledtaxpercentage"] = true;
    }
    else {
      objectModel["isDisabledtaxpercentage"] = false;
    }
  }
  clear(event) {
    //88.clear all the fields which the data has entered
    // this._purchaseorderform.reset();
    // this._Purchaseorderpaymenttermform.reset();
    this._purchaseorderdetailform.reset();
    this.productcatid = [];
  }

  //currency change:
  GetCurrency(event) {

    var currencyid = this._purchaseorderform.get("currencyid").value;
    var usdExchangeRate = (this._currency || []).filter(f => f.currencyid == currencyid)[0].exchangerate;
    this._SelectedExchangerate = usdExchangeRate;
    if (this._purchaseorderform.controls['purchaseordertype'].value == "MSC00199") {
      this.changecurrenctandrate();
    } else {
      this._ProductDetails = [];
      this._purchaseorderdetailform.get("producttypeid").reset();
      this.productcatid = [];
      this._purchaseorderdetailform.get("productid").reset();
      this._purchaseorderdetailform.get("variantid").reset();
      this._purchaseorderdetailform.get("quantity").reset();

    }

  }

  changecurrenctandrate() {
    var currencyid = this._purchaseorderform.get("currencyid").value;
    var usdExchangeRate = (this._currency || []).filter(f => f.currencyid == currencyid)[0].exchangerate;
    this._SelectedExchangerate = ExchangeRate;
    var finalamount = 0;
    if (this._ProductDetails.length > 0) {
      for (var i = 0; i < this._ProductDetails.length; i++) {
        var ExchangeRate = (this._CurrenciesTemp || []).filter(f => f.currencyid == this._ProductDetails[i].currencyid)[0].exchangerate;
        this._ProductDetails[i].currencyid = currencyid;
        var purchaserate = this._ProductDetails[i].unitprice / ExchangeRate;
        this._ProductDetails[i].unitprice = purchaserate * usdExchangeRate;
        this._ProductDetails[i].finalamount = this._ProductDetails[i].unitprice * this._ProductDetails[i].quantity;

        if (this._ProductDetails[i].taxamount > 0) {
          var TaxPercentage = (this._taxdetails || []).filter(f => f.taxcode == 'VAT')[0].taxpercentage;
          var Taxid = (this._taxdetails || []).filter(f => f.taxcode == 'VAT')[0].taxid;
          this._ProductDetails[i].taxpercentage = TaxPercentage;
          this._ProductDetails[i].taxamount = this._ProductDetails[i].finalamount * TaxPercentage / 100;
          this._ProductDetails[i].taxcode = 'VAT';
          this._ProductDetails[i].taxid = Taxid;
        }
      }

    }

    for (var i = 0; i < this._ProductDetails.length; i++) {
      finalamount += this._ProductDetails[i].finalamount;
    }
    this._purchaseorderform.controls['grossamount'].setValue(finalamount.toFixed(3));
    this._purchaseorderform.controls['totalpoamount'].setValue(finalamount + this._purchaseorderform.controls['taxamount'].value + this._purchaseorderform.controls['otherchargesamount'].value);
    //  var amountinusd = this._SelectedExchangerate * this._purchaseorderform.get("totalpoamount").value;
    // // this._purchaseorderform.controls['totalpoamount'].setValue(amountinusd.toFixed(3));
    //  this._purchaseorderform.controls['amountinusd'].setValue(amountinusd.toFixed(3));

    this.calculateamountdetails();

  }


  onchangeTAX(row: any, event) {
    var selectedindex = this._ProductDetails.findIndex(obj => obj == row);
    var totalamount = row.quantity * row.unitprice;
    this._ProductDetails[selectedindex].finalamount = totalamount;
    if (this._purchaseorderform.get("taxtype").value == "MSC00201") { //PO Level
      this.isDisabledtaxcode = true;
      this.isDisabledtaxpercentage = false;
    }
    else {
      var taxid = row.taxcode;
      if (taxid) {
        var TaxPercentage = (this._taxdetails || []).filter(f => f.taxcode == taxid)[0].taxpercentage;
        row.taxid = (this._taxdetails || []).filter(f => f.taxcode == taxid)[0].taxid;
        row.taxpercentage = TaxPercentage;
        this._ProductDetails[selectedindex].taxamount = totalamount * TaxPercentage / 100;
        this._purchaseorderform.controls['taxamount'].setValue(this._ProductDetails[selectedindex].taxamount);
      } else {
        this._ProductDetails[selectedindex].taxamount = 0;
        this._purchaseorderform.controls['taxamount'].setValue(0);
      }
    }
    if (this._purchaseorderform.get("currencyid").value != null && this._purchaseorderform.get("currencyid").value != "") {
      this.calculateamountdetails();
    }
  }


  RemoveProduct(pProduct: Purchaseordergetproductsview) {
    var index = this._ProductDetails.indexOf(pProduct);
    this._ProductDetails.splice(index, 1)
    var _FinalTotalAmount = 0
    this.calculateamountdetails();
  }

  AddOtherCharges(event) {
    let otherChargesExists = (this._OtherCharges.filter(s => (s.otherchargesid) > 0).length == this._OtherCharges.length);
    if (!otherChargesExists)
      return;
    var l_othercharge = new Purchaseorderothercharge();
    l_othercharge.purchaseorderotherchargesid = 0;
    l_othercharge.purchaseorderid = 0;
    l_othercharge.createdby = sessionStorage["userid"];
    l_othercharge.modifiedby = sessionStorage["userid"];
    l_othercharge.createdon = new Date();
    l_othercharge.modifiedon = new Date();
    l_othercharge.accountingyear = new Date().getFullYear();
    this._OtherCharges.push(l_othercharge);
    this._Charges = this._OtherCharges;
  }

  RemoveOtherCharges(pOthercharges: Purchaseorderothercharge) {
    var index = this._OtherCharges.indexOf(pOthercharges);
    this._OtherCharges.splice(index, 1)
    this.calculateamountdetails();
    if (this._OtherCharges.length <= 0) {
      var l_othercharge = new Purchaseorderothercharge();
      l_othercharge.purchaseorderotherchargesid = 0;
      l_othercharge.purchaseorderid = 0;
      l_othercharge.createdby = sessionStorage["userid"];
      l_othercharge.modifiedby = sessionStorage["userid"];
      l_othercharge.createdon = new Date();
      l_othercharge.modifiedon = new Date();
      l_othercharge.accountingyear = new Date().getFullYear();
      this._OtherCharges.push(l_othercharge);
    }
  }

  SendToApproval(event) {
    // var workflowid = 5;
    this._userid = sessionStorage["userid"];
    this._branchid = sessionStorage["BranchID"];
    //76.after clicking the send to approve button, the purchase order is given to 4 levels of approval 
    //this._productid= this._damagedproductpricedetailsform.get("productid").setValue(this._damagedproductpricedetailsform.get("productid").value);
    this._IsProgressSpinner = true;
    this._Purchaseorderservice.SendToApproval(this._userid, this._branchid, this._PurchaseOrderNo).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
      this.isDisabledsave = false;
      if (this._action != 'edit') {
        this.reset(null);
      }
      if (this._action == 'edit') {
        this.isDisabledsave = true;
      }
      this._IsProgressSpinner = false;
      this.isDisabledsendapparoval = true;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  SendMail(event) {
    // var workflowid = 5;
    this._userid = sessionStorage["userid"];
    this._branchid = sessionStorage["BranchID"];
    //76.after clicking the send to approve button, the purchase order is given to 4 levels of approval 
    //this._productid= this._damagedproductpricedetailsform.get("productid").setValue(this._damagedproductpricedetailsform.get("productid").value);
    this._IsProgressSpinner = true;

    this._Purchaseorderservice.SendMail(this._userid, this._branchid, this._PurchaseOrderNo).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && resultJSON) {
        this._CustomExceptionService.handleSuccess(usererrors.MailSent_Success);
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);

      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  onRowSelecttaxcode(event) {
    var row = event.data;
    this._purchaseorderform.controls['taxcode'].setValue(row.taxcode);
    this._purchaseorderform.controls['taxname'].setValue(row.taxname);
    this._purchaseorderform.controls['taxpercentage'].setValue(row.taxpercentage);
    this.displayBasictax = false;
    var _vat = 0, _taxamount = 0;
    if (this._purchaseorderform.get("taxpercentage").value != undefined) {
      _vat = this._purchaseorderform.get("taxpercentage").value
      _taxamount = _vat / 100;
      this._purchaseorderform.controls['taxamount'].setValue(_taxamount);
    }
  }
  showBasicDialogtax() {
    this.displayBasictax = true;
  }
  onRowSelectsuplier(event) {
    if (!this._purchaseorderform.get("suppliertype").value) {
      this._CustomExceptionService.handleWarning("Please select Supplier type");
      return;
    }
    this._ProductDetails = [];
    this._IsProgressSpinner = true;
    this._purchaseorderform.controls['supplierid'].setValue(parseInt(event.value));
    this._Purchaseorderservice.PageOnLoad(this._Purchaseorderid, this._PurchaseOrderNo, this._branchid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._taxdetails = resultJSON.taxList;
      this._IsProgressSpinner = false;

    });
    this.displayBasic = false;
    var supplier = this._purchaseorderform.get("supplierid").value
    this._IsProgressSpinner = true;
    this._Purchaseorderservice.onchangesuppliername(supplier).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._purchaseorderform.controls['contactpersonname'].setValue(resultJSON.supplierAddressList[0].contactpersonname);
      this._purchaseorderform.controls['address'].setValue(resultJSON.supplierAddressList[0].address);

      var type = (this._purchaseorderform.get("purchaseordertype").value);
      if (type == "MSC00199") { //Quote Based
        if (resultJSON.refNoList.length) {
          this._referenceno = resultJSON.refNoList;
        } else {
          this._CustomExceptionService.handleWarning("No Reference no.");
        }
      }
      this._producttype = resultJSON.productTypeList;
      this._currency = resultJSON.currencyList;
      this._purchaseorderform.controls['currencyid'].setValue(resultJSON.supplier.currencyid);
      this._IsProgressSpinner = false;
      if (this._producttype.length == 0 && type != "MSC00199") { //Quote Based
        this._CustomExceptionService.handleWarning("No products mapped for this supplier");
      }

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onchangedelivery() {
    //30.on selection of  branch , the the corresponding branch address should be fetched in address text box
    if (!this._purchaseorderform.get("deliveryto").value) return
    this._IsProgressSpinner = true;
    this._Purchaseorderservice.PageOnLoad(this._Purchaseorderid, this._PurchaseOrderNo, this._branchid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      var branchid = (this._purchaseorderform.get("deliveryto").value);
      var address = (this._billingbranchname || []).filter(f => f.branchid == branchid)[0].address;
      this._deliverybranchname = resultJSON.deliveryBranchList;
      this._IsProgressSpinner = false;
      this._purchaseorderform.controls['deliverytoaddress'].setValue(address);
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onchangebarnch(event) {
    var selectedProductType = event.value;
    ////33.On Selection of the Billing Branch name , the Billing  address should be fetched in address text box
    this._IsProgressSpinner = true;
    this._Purchaseorderservice.PageOnLoad(this._Purchaseorderid, this._PurchaseOrderNo, this._branchid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      var branchid = (this._purchaseorderform.get("billingto").value);
      var address = (this._billingbranchname || []).filter(f => f.branchid == branchid)[0].address;
      this._billingbranchname = resultJSON.billingBranchList;
      this._IsProgressSpinner = false;
      this._purchaseorderform.controls['billingaddress'].setValue(address);
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }

  filterProducts(event) {
    var supplier = this._purchaseorderform.get("supplierid").value;
    if (supplier == "" || supplier == null || supplier == undefined) {
      { this._CustomExceptionService.handleError("please select supplier"); this._IsProgressSpinner = false; }
      return;
    }
    if (event.query == "" || event.query == undefined) {
      return;
    }
    else {
      if (this.productcatid.length > 0) {
        let filtered: any[] = [];
        let query = (<string>event.query).toLowerCase();
        this.filteredProducts = this._productname.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
        return;
      }
      else {
        //{ this._CustomExceptionService.handleError("please select product category"); this._IsProgressSpinner = false; }

        this._ProductObj = { "product": event.query || [], "branchid": this._branchid || 0, "supplier": supplier || 0 };
        this._IsProgressSpinner = true;
        this._Purchaseorderservice.GetProduct(this._ProductObj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._productname = <Productoverallview[]>resultJSON.productsearchlist || [];
          this._tempproductname = <Productoverallview[]>resultJSON.productsearchlist || [];
          let filtered: any[] = [];
          let query = (<string>event.query).toLowerCase();
          this.filteredProducts = this._productname.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }

      // this._IsProgressSpinner = true;
      // this._Purchaseorderservice.GetProduct(event.query, this._branchid).subscribe((result) => {
      //   const resultJSON = JSON.parse(JSON.stringify(result));
      //   this._productname = <Productoverallview[]>resultJSON.productsearchlist || [];
      //   this._tempproductname = <Productoverallview[]>resultJSON.productsearchlist || [];
      //   let filtered: any[] = [];
      //   let query = (<string>event.query).toLowerCase();
      //   this.filteredProducts = this._productname.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
      //   this._IsProgressSpinner = false;
      // },
      //   error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  goBack(event) {
    this._router.navigate(['/vPurchaseOrder']);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view' && !this.isDisabledsave) {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  //Natraj
  Print(event) {
    //var purchaseorderno = event.purchaseorderno;
    this._IsProgressSpinner = true;
    var tempprint = 0;
    //var mediaType = 'application/pdf';
    // this._Purchaseorderservice.Print(this._PurchaseOrderNo, this._branchid, tempprint);
    this._IsProgressSpinner = false;
  }

}
