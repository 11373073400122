import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { parse } from 'path';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Deliverynote } from 'src/app/core/Models/DeliveryNote';
import { Deliverynotedetailview } from 'src/app/core/Models/Deliverynotedetailview';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { ProductType } from 'src/app/core/Models/overallclass';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Supplier } from 'src/app/core/Models/Supplier';
import { DeliverynoteService } from 'src/app/core/Services/inventory/deliverynote.service';
import { vAmount, vProductNameSelect, vReceivedQuantity, vSupplierNameSelect } from 'src/app/core/Validators/validation';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Supplierview } from 'src/app/core/Views/Supplierview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { KeyPress } from 'src/assets/js/KeyPress';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-c-delivery-challan',
  templateUrl: './c-delivery-challan.component.html',
  styleUrls: ['./c-delivery-challan.component.css']
})
export class CDeliveryChallanComponent implements OnInit {
  ServiceRequestProductDetails: Deliverynotedetailview[] = [];
  _Deliverynoteform: FormGroup;
  _Deliverynoteproductform: FormGroup;
  _action: string = "create";
  _DeliverynoteNo: number;
  _IsProgressSpinner: boolean = true;
  _IsSalesReturn: boolean = true;
  _IsNotSalesReturn: boolean = true;
  _ReturnTypes: any;
  _JobCards: any;
  _Suppliernames: Supplierview;
  _ProductTypes: ProductType;
  _ProductCategories: Productcategory[] = [];
  _ProductNames: Productoverallview[] = [];
  _tempproductname: Productoverallview[] = [];
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _DeliveryNoteObj: any;
  filteredProducts: any[];
  productcatid: any[] = [];
  producttypeid: any[] = [];
  _Status: Metadatum;
  _branchid = sessionStorage["currentbranchid"];
  _userid = sessionStorage["userid"];
  _DeliveryNote: Deliverynote;
  _submitted = false;
  consignmentstock: number = 0;
  soldstock: number = 0;
  Stock: number = 0;
  _isDisabledsendtoapproval = true;
  isDisabledsave = false;
  isDisablereset = false;
  isDisabledremoveproduct = false;
  deliverynoteid: number;
  _Action: IAction;

  constructor(private utility: CommonUtilities, public keypress: KeyPress, private fb: FormBuilder,
    private _AccessRightsService: AccessRightsService, private _router: Router, private messageService: MessageService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private confirmationService: ConfirmationService, private _DeliverynoteService: DeliverynoteService) {
    this._DeliverynoteNo = history.state?.deliverynoteid ? history.state?.deliverynoteid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeDeliveryNoteForm() {
    this._Deliverynoteform = this.fb.group({
      deliverynoteid: [0],
      companyid: [0],
      branchid: [],
      supplierid: ['', vSupplierNameSelect],
      returntype: [],
      ismultipledc: [],
      jobcardid: [],
      deliverto: [],
      deliverynotestatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      totalamount: [],
      approvalstatus: [],
      deliverynotedetails: []

    });
    this._Deliverynoteproductform = this.fb.group({
      producttypeid: [],
      productcategoryid: [],
      productid: ['', vProductNameSelect],
      remarks: [],
      isdamaged: [],
      amount: ['', vAmount],
      isreurndc: [],
      quantity: ['', vReceivedQuantity],
    })
    this._Deliverynoteform.get("deliverynotestatus").setValue("MSC00001");
    this._Deliverynoteproductform.get("isdamaged").setValue(true);
    this._Deliverynoteproductform.get("isreurndc").setValue(false);
    this._Deliverynoteform.get("returntype").disable();
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(164);
    this.utility.pageLoadScript();
    this.InitializeDeliveryNoteForm();
    // this.ServiceRequestProductDetails = [
    //   { productName: 'Prod 01', sparePartName: 'SP 01, SP 02', qty: 'Yes', amount: '1000', reason: 'Reason', isreturnDC: 'Yes', returnDCStatus: 'Yes' },
    //   { productName: 'Prod 02', sparePartName: 'SP 04', qty: 'No', amount: '1000', reason: '', isreturnDC: 'No', returnDCStatus: 'Yes' }
    // ]
    // this._ReturnTypes = [
    //   { id: 1, returntype: 'MSC0001', returntypename: "Material Return" },
    //   { id: 2, returntype: 'MSC0002', returntypename: "Sales Return" },

    // ]

    //PageOnload
    this._IsProgressSpinner = true;
    this._DeliverynoteService.PageOnLoad(this._DeliverynoteNo, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Status = resultJSON.recordstatus;
      this._Suppliernames = resultJSON.suppliers;
      this._ReturnTypes = resultJSON.returntype;
      this._Deliverynoteform.get("returntype").setValue("MSC00282");

      var Returntype = "MSC00282";
      if (Returntype == "MSC00282") {
        this._IsSalesReturn = true;
        this._IsNotSalesReturn = false;
      }
      else {
        this._IsSalesReturn = false;
        this._IsNotSalesReturn = true;
      }
      if (this._action == 'view') {
        this.isDisabledsave = true;
        this.isDisablereset = true;
        this.isDisabledremoveproduct = true;
        this._Deliverynoteform.disable();
        this._Deliverynoteproductform.disable();
      }
      if (this._action == 'edit') {
        this.isDisablereset = true;
        this._isDisabledsendtoapproval = false;
      }
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        ////console.log(updateJSON);
        this._Deliverynoteform.setValue(updateJSON.deliverynote);
        this.deliverynoteid = updateJSON.deliverynote.deliverynoteid
        this.ServiceRequestProductDetails = updateJSON.deliverynotedetailviews;
        // deliverynote
        // deliverynotedetails
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  ReturnType(event) {
    var Returntype = event.value
    if (Returntype == "MSC00282") {
      this._IsSalesReturn = true;
      this._IsNotSalesReturn = false;
    }
    else {
      this._IsSalesReturn = false;
      this._IsNotSalesReturn = true;
    }
  }
  OnSelectSupplier(event) {
    this.ServiceRequestProductDetails = [] = [];
    var supplierid = event.value;
    if (supplierid == 0 || supplierid == null || !supplierid) {
      // this._CustomExceptionService.handleError("Please select supplier");
      return;
    }
    this._IsProgressSpinner = true;
    this._DeliverynoteService.OnSupplierNameChange(supplierid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //console.log(resultJSON);
      this._ProductTypes = resultJSON.productTypeList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  //Get Product Category
  getProductcategory(event) {
    var selectedProductType = event.value;
    //console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._ProductCategories = [];
      return;
    }
    this._IsProgressSpinner = true;
    this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": this._branchid || 0 };
    this._DeliverynoteService.GetProductCategory(this._ProductTypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //console.log(resultJSON);
      this._ProductCategories = resultJSON.productcategories;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  //Get Product Name
  getProductname(event) {
    var selectedProductCategory = event.value;
    //console.log(selectedProductCategory);
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._Deliverynoteform.reset();
      return;
    }
    else if (this._branchid == null || this._branchid == 0) {
      this._Deliverynoteform.controls["productcategoryid"].reset();
      this._CustomExceptionService.handleWarning("Please select to store");
      this._IsProgressSpinner = false;
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": this._branchid };
      this._DeliverynoteService.GetProductName(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        //console.log(resultJSON);
        // this._ProductNames = resultJSON.products;
        this._ProductNames = <Productoverallview[]>resultJSON.productsearch || [];
        this._tempproductname = <Productoverallview[]>resultJSON.productsearch || [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }

  }
  //On Select Product
  OnSelectProducts(event) {

    var selectedProduct = event.productid;
    //console.log(selectedProduct);
    let product = this._tempproductname.filter(f => f.productid == selectedProduct)[0];
    var amount = product.purchaserateinusd;
    this._Deliverynoteproductform.controls['amount'].setValue(amount);
    this._IsProgressSpinner = true;
    this._DeliverynoteService.GetProductStock(selectedProduct, this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //console.log(resultJSON);
      if (resultJSON.stocks.length > 0) {
        this.consignmentstock = resultJSON.stocks[0].consignmentstock || 0
        this.soldstock = resultJSON.stocks[0].soldstock || 0
        this.Stock = this.consignmentstock - this.soldstock;
      }

      this._ProductCategories = resultJSON.productcategories;
      this.producttypeid = [];
      this.producttypeid.push(product.producttypeid);
      this.productcatid = [];
      this.productcatid.push(product.productcategoryid);
      this._Deliverynoteproductform.controls['producttypeid'].setValue(this.producttypeid);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  //Product Search
  filterProducts(event) {
    var supplierid = this._Deliverynoteform.get("supplierid").value || 0;
    if (supplierid == 0 || supplierid == null || !supplierid) {
      this._CustomExceptionService.handleError("Please select supplier");
      return;
    }
    if (event.query == "" || event.query == undefined) {
      return;
    }
    else {
      if (this.productcatid.length > 0) {
        let filtered: any[] = [];
        let query = (<string>event.query).toLowerCase();
        this.filteredProducts = this._ProductNames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
        return;
      }
      else {
        this._IsProgressSpinner = true;
        this._DeliverynoteService.GetProduct(event.query, this._branchid, supplierid).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          //console.log(resultJSON);
          this._ProductNames = <Productoverallview[]>resultJSON.productsearchlist || [];
          this._tempproductname = <Productoverallview[]>resultJSON.productsearchlist || [];
          let filtered: any[] = [];
          let query = (<string>event.query).toLowerCase();
          this.filteredProducts = this._ProductNames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
          this._IsProgressSpinner = false;

        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }

  }
  CheckQuantity(event) {
    let quantity = parseInt(this._Deliverynoteproductform.get("quantity").value || 0)
    if (quantity > this.Stock) {
      this._CustomExceptionService.handleError(usererrors.StockCheck);
      this._Deliverynoteproductform.get("quantity").setValue(0);
      return;
    }
  }
  onchangereceivedquantity(row: any, event) {
    var selectedindex = this.ServiceRequestProductDetails.findIndex(obj => obj == row);
    var quantity = this.ServiceRequestProductDetails[selectedindex].quantity;
    var stock = this.ServiceRequestProductDetails[selectedindex].stock;
    if (quantity > stock) {
      this._CustomExceptionService.handleError(usererrors.StockCheck);
      this.ServiceRequestProductDetails[selectedindex].quantity = 0;
      return;
    }
  }
  //Add To Products in Grid
  AddProducts(event) {
    this._submitted = true;
    if (this._Deliverynoteproductform.valid && this._Deliverynoteform.valid) {
      var productid = parseInt(this._Deliverynoteproductform.get("productid").value.productid);
      var productName = (this._ProductNames || []).filter(f => f.productid == productid)[0].productname;
      var Productdetails = new Deliverynotedetailview();
      Productdetails.deliverynotedetailid = 0;
      Productdetails.deliverynoteid = 0;
      Productdetails.productid = productid;
      Productdetails.productname = productName;
      Productdetails.isdamaged = this._Deliverynoteproductform.get("isdamaged").value || false;
      Productdetails.unitprice = this._Deliverynoteproductform.get("amount").value || 0;
      Productdetails.quantity = this._Deliverynoteproductform.get("quantity").value || 0;
      var totalamount = (parseFloat(this._Deliverynoteproductform.get("amount").value || 0) * parseFloat(this._Deliverynoteproductform.get("quantity").value || 0));
      Productdetails.amount = totalamount;
      Productdetails.stock = this.Stock || 0
      Productdetails.remarks = this._Deliverynoteproductform.get("remarks").value || "";
      if (this.ServiceRequestProductDetails.length > 0) {
        for (var i = 0; i < this.ServiceRequestProductDetails.length; i++) {
          if (Productdetails.productid == this.ServiceRequestProductDetails[i].productid) {
            this._CustomExceptionService.handleWarning("This Product Already Exists!");
            return;
          }
        }

      }
      this.ServiceRequestProductDetails.push(Productdetails);
      this.clear();
      this._submitted = false;
    }
  }
  //Remove the products to grid
  RemoveProduct(pProduct: Deliverynotedetailview) {
    var index = this.ServiceRequestProductDetails.indexOf(pProduct);
    this.ServiceRequestProductDetails.splice(index, 1)
  }

  //Clear the records
  clear() {
    this._Deliverynoteproductform.get("productid").reset();
    // this._Deliverynoteproductform.get("isdamaged").reset();
    this._Deliverynoteproductform.get("amount").reset();
    this._Deliverynoteproductform.get("quantity").reset();
    this._Deliverynoteproductform.get("remarks").reset();
    this._Deliverynoteproductform.get("producttypeid").reset();
    this.productcatid = [];
    this._ProductCategories = [] = [];
  }
  //Save Delivery note
  onSave() {
    //console.log("Submitted");
    //console.log(this._action);
    // this._submitted = true;
    if (!this.ServiceRequestProductDetails || this.ServiceRequestProductDetails.length == 0) {
      this._CustomExceptionService.handleError(usererrors.ProductDetails);
      return;
    }
    // if (this._Deliverynoteform.valid) {
    // //For Model and Form Mapping
    this._DeliveryNote = this._Deliverynoteform.getRawValue();
    if (this._action == 'create') {
      this._DeliveryNote.branchid = sessionStorage["currentbranchid"];
      this._DeliveryNote.createdby = sessionStorage["userid"];
      this._DeliveryNote.createdon = new Date();
      this._DeliveryNote.deliverynoteid = 0;
      this._DeliveryNote.jobcardid = 0;
      this._DeliveryNote.deliverto = "";
      this._DeliveryNote.ismultipledc = false;
      this._DeliveryNote.modifiedby = sessionStorage["userid"];
      this._DeliveryNote.modifiedon = new Date();
      // this.onEd();
      this._DeliveryNoteObj = { "Deliverynote": this._DeliveryNote, "Deliverynotedetail": this.ServiceRequestProductDetails };
      this._IsProgressSpinner = true;
      this._DeliverynoteService.create(this._DeliveryNoteObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        //console.log(result);
        //console.log(resultJSON);
        this.deliverynoteid = resultJSON.deliverynoteid

        this._IsProgressSpinner = false;
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
          this._isDisabledsendtoapproval = false;
          this.reset(null);
          this._IsProgressSpinner = false;
        }
        else {
          this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        } this._IsProgressSpinner = false;
      },

        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }

    else if (this._action == 'edit') {
      // this.onEd();
      this._IsProgressSpinner = true;
      this._DeliveryNoteObj = { "Deliverynote": this._DeliveryNote, "Deliverynotedetail": this.ServiceRequestProductDetails };
      this._DeliverynoteService.edit(this._DeliveryNoteObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._IsProgressSpinner = false;
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          this._isDisabledsendtoapproval = false;
          this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          this._IsProgressSpinner = false;
        }
        else {
          this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        }
        this._IsProgressSpinner = false;
      },

        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }

    // }
  }
  SendToApproval(event) {
    this._IsProgressSpinner = true;
    this._DeliverynoteService.SendToApproval(this._userid, this._branchid, this.deliverynoteid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      //console.log(resultJSON);
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._isDisabledsendtoapproval = true;
        this._CustomExceptionService.handleSuccess(usererrors.DeliveryNoteSendToApproval);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  //Clear the Records
  reset(event) {
    // this._submitted = true;
    this._Deliverynoteform.reset();
    this.ServiceRequestProductDetails = [];
    this.InitializeDeliveryNoteForm();

  }
  //Back To Search
  goBack(event) {
    this._router.navigate(['/vDeliveryNote']);
  }
  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}

