<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Import Entry</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <!-- 9. save, clear button must be enabled. -->
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" type="button"
                                [disabled]="isDisabledSave" (click)="ConfirmPaymentStatusSave()"></button>
                            <button hidden pButton pRipple title="Send To Approval" icon="las la-check"
                                [disabled]="isDisabledsendtoapproval" (click)="SendToApproval($event)"></button>
                            <button pButton pRipple label="" title="Clear" type="button" icon="pi pi-trash"
                                (click)="reset($event)" [disabled]="isDisabledClear" class="p-button-danger"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_importentryform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" [disabled]="isDisabledimport" pInputText name="importno" disabled
                                        formControlName="importno">

                                    <label for="importno"> Import No</label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _importentryform.get('importno').touched) && _importentryform.get('importno').errors?.SelectImportno">
                                    Please Select Import No
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="importdate" [showIcon]="true" formControlName="importdate">
                                    </p-calendar>
                                    <label for="importdate">Import Date <span class="hlt-txt">*</span></label>
                                </span>


                                <!-- <span class="text-danger"
                            *ngIf="(_submitted || _importentryform.get('importdate').touched) && _importentryform.get('importdate').errors?.SelectImportDate">
                            Please Select Import Date
                        </span> -->

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">

                                    <!-- 13. if "Country" is not selected, show error as "Please select Country" -->
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Importcountry"
                                        optionLabel="countryname" optionValue="countryid" name="importcountry"
                                        formControlName="importcountry" (onChange)="Onchangecountry($event)"
                                        [filter]="true" filterBy="countryname">
                                    </p-dropdown>
                                    <label for="importcountry">Import Country <span class="hlt-txt">*</span></label>
                                </span>

                                <span class="text-danger"
                                    *ngIf="(_submitted || _importentryform.get('importcountry').touched) && _importentryform.get('importcountry').errors?.SelectImportCountry">
                                    Please Select Import Country
                                </span>
                            </td>
                            <td style="width: 20%;">

                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <!-- 12. if "Supplier Name" is not selected, show error as "Please Select Supplier Name" -->
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Suppliername"
                                        optionLabel="suppliername" optionValue="supplierid" name="supplierid"
                                        [virtualScroll]="true" itemSize="30" formControlName="supplierid"
                                        (onChange)="Onchangesupplier($event)" [filter]="true" filterBy="nameandmobile">
                                    </p-dropdown>
                                    <label for="supplierid"> Supplier Name <span class="hlt-txt">*</span></label>
                                </span>

                                <span class="text-danger"
                                    *ngIf="(_submitted || _importentryform.get('supplierid').touched) && _importentryform.get('supplierid').errors?.SelectSupplierName">
                                    Please Select Supplier Name
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <!-- 12. if "Supplier Name" is not selected, show error as "Please Select Supplier Name" -->
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_ContainerNo"
                                        optionLabel="containerno" optionValue="containerno" name="containerno"
                                        formControlName="containerno" (onChange)="OnchangeContainer($event)"
                                        [filter]="true" filterBy="suppliername">
                                    </p-dropdown>
                                    <label for="supplierid">Container No<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_importentryform.get('containerno').touched || _submitted) && _importentryform.get('containerno').errors?.ContainerNumberRequired">
                                    Please Enter Container No.
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_PaymentStatus"
                                    optionLabel="metasubdescription"
                                    optionValue="metasubcode" name="paymentstatus" (onChange)="ChangePaymentStatus()"
                                        formControlName="paymentstatus" >
                                    </p-dropdown>
                                    <label for="suppliercurrencyid">Payment Status </label>
                                </span>

                                 <span class="text-danger"
                            *ngIf="(_submitted || _importentryform.get('paymentstatus').touched) && _importentryform.get('paymentstatus').errors?.SupplierCurrency">
                            Please Select Payment Status
                        </span> 
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Agent Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_importentryform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">

                                    <!-- 17.if "Agent Name" is not selected, show error as "Please select Agent Name" -->
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Agentname"
                                        optionLabel="suppliername" optionValue="supplierid" name="agentid"
                                        formControlName="agentid" (onChange)="Onchangeagent()" [filter]="true"
                                        filterBy="nameandmobile">
                                    </p-dropdown>
                                    <label for="agentid">Agent Name <span class="hlt-txt">*</span></label>
                                </span>

                                <span class="text-danger"
                                    *ngIf="(_submitted || _importentryform.get('agentid').touched) && _importentryform.get('agentid').errors?.AgentName">
                                    Please Select Agent Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Agentcurrencies"
                                        optionLabel="currencyname" optionValue="currencyid" name="agentcurrencyid"
                                        (onChange)="Onchangeagent()" formControlName="agentcurrencyid" [filter]="true"
                                        filterBy="currencyname">
                                    </p-dropdown>
                                    <label for="agentcurrencyid">Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- <span class="text-danger"
                            *ngIf="(_submitted || _importentryform.get('agentcurrencyid').touched) && _importentryform.get('agentcurrencyid').errors?.AgentCurrency">
                            Please Select Currency
                        </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">

                                    <!--7. Date: current date should be load as default date. -->
                                    <p-calendar name="date" [showIcon]="true" formControlName="date">
                                    </p-calendar>
                                    <label for="date">Date <span class="hlt-txt">*</span></label>
                                </span>

                                <span class="text-danger"
                                    *ngIf="(_submitted || _importentryform.get('date').touched) && _importentryform.get('date').errors?.DateRequired">
                                    Please Enter Invoice Amount
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="beno" formControlName="beno">
                                    <label for="beno">B.E No <span class="hlt-txt">*</span></label>
                                </span>

                                <span class="text-danger"
                                    *ngIf="(_submitted || _importentryform.get('beno').touched) && _importentryform.get('beno').errors?.BENumberRequired">
                                    Please Enter B.E No
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="bedate" [showIcon]="true" formControlName="bedate">
                                    </p-calendar>
                                    <label for="bedate">B.E Ref Date</label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <!-- 8. "Shipping Line" should load from Metadata, it contains  -->
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Shippingline"
                                        optionLabel="shippinglinename" optionValue="shippinglineid" name="shippingline"
                                        formControlName="shippingline">
                                    </p-dropdown>
                                    <label for="shippingline">Shipping Line</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <!-- 20.if "Port No." is not given, show error as "Please enter Port No." -->
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Portname" optionLabel="portname"
                                        optionValue="portid" name="portno" formControlName="portno">
                                    </p-dropdown>
                                    <label for="portno">Port<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _importentryform.get('portno').touched) && _importentryform.get('portno').errors?.SelectPort">
                                    Please Select Port
                                </span>
                            </td>

                            <td>
                                <span class="p-float-label">
                                    <!-- 21.if "B.L No." is not given, show error as "Please enter B.L No." -->
                                    <input type="text" pInputText name="blno" formControlName="blno">
                                    <label for="blno">BL No <span class="hlt-txt">*</span></label>
                                </span>


                                <span class="text-danger"
                                    *ngIf="(_submitted || _importentryform.get('blno').touched) && _importentryform.get('blno').errors?.BLNumberRequired">
                                    Please Enter BL No
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar name="bldate" [showIcon]="true" formControlName="bldate">
                                    </p-calendar>
                                    <label for="bldate">BL Date</label>
                                </span>

                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _importentryform.get('bldate').touched) && _importentryform.get('bldate').errors?.BLDate">
                                    Please Select BL Date
                                </span> -->
                            </td>
                            <td>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <!-- 21.if "Invoice No." is not given, show error as "Please enter Invoice No." -->
                                    <input type="text" pInputText name="agentinvoiceno"
                                        formControlName="agentinvoiceno">
                                    <label for="agentinvoiceno">Invoice No <span class="hlt-txt">*</span></label>
                                </span>

                                <span class="text-danger"
                                    *ngIf="(_submitted || _importentryform.get('agentinvoiceno').touched) && _importentryform.get('agentinvoiceno').errors?.AgentInvoiceNumberRequired">
                                    Please Enter Invoice No
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="agentinvoiceamount"
                                        formControlName="agentinvoiceamount" (keyup)="AgentInvoiceAmount()">
                                    <label for="agentinvoiceamount">Invoice Amount <span
                                            class="hlt-txt">*</span></label>
                                </span>

                                <span class="text-danger"
                                    *ngIf="(_submitted || _importentryform.get('agentinvoiceamount').touched) && _importentryform.get('agentinvoiceamount').errors?.AgentInvoiceAmont">
                                    Please Enter Invoice Amount
                                </span>
                            </td>
                            <!-- <td>
                                 <span class="p-float-label">
                                    <textarea rows="2" name="narration" pInputTextarea formControlName="narration"
                                        autoResize="autoResize"></textarea>
                                    <label for="narration">Narration </label>
                                </span>
                                35. If  Address is empty show "please enter  Address  " 
                              
                            </td> -->
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_referenceno" [disabled]="_action != 'create'"
                                        optionLabel="refNo" optionValue="refNo" [ngModelOptions]="{standalone: true}"
                                        (onChange)="onSelectreferenceno($event)" [(ngModel)]="_selectedreference"
                                        name="_selectrefid"></p-multiSelect>
                                    <label for="_selectrefid">Reference No. </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText name="advanceamount"
                                        formControlName="advanceamount">
                                    <label for="containerno">Advance Amount</label>
                                </span>
                            </td>
                        </tr>
                    </table>
                </form>
                <p-tabView [(activeIndex)]="selectedtab">
                    <p-tabPanel header="Charges Breakup Details">
                        <form [formGroup]="_importentrychargesform" [hidden]="_action == 'view'">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <!-- 17.if "Agent Name" is not selected, show error as "Please select Agent Name" -->
                                            <p-dropdown [autoDisplayFirst]="false" [options]="expenseledgers"
                                                optionLabel="name" optionValue="ledgerid"
                                                name="chargesid" formControlName="chargesid" [filter]="true"
                                                filterBy="name">
                                            </p-dropdown>
                                            <label for="chargesid">Charge for <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submittedchargeform || _importentrychargesform.get('chargesid').touched) && _importentrychargesform.get('chargesid').errors?.SelectSupplierName">
                                            Please select charge for
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="amount" formControlName="amount">
                                            <label for="amount">Charge Amount</label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submittedchargeform || _importentrychargesform.get('amount').touched) && _importentrychargesform.get('amount').errors?.SelectSupplierName">
                                            Please enter charge amount
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                    </td>
                                    <td style="width: 20%;">
                                    </td>
                                    <td style="width: 20%;">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button type="submit" pButton pRipple label="Add" title="Add" icon="pi pi-plus"
                                            (click)="onAddCharges()" class="p-button-success p-mr-2"></button>
                                        <button pButton pRipple label="Clear" title="Clear" icon="pi pi-times"
                                            type="button" class="p-button-danger p-mr-2"
                                            (click)="OnClearCharges()"></button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                        <div class="card" style="width: 50%;">
                            <p-table [value]="importentrychargesdetails" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;width: 100px;" [hidden]="_action == 'view'">Action
                                        </th>
                                        <th>Charges For</th>
                                        <th>Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_charge>
                                    <tr>
                                        <td style="text-align: center;width: 100px;" [hidden]="_action == 'view'">
                                            <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                (click)="OnRemoveCharges(_charge)"
                                                class="p-button-danger p-mr-2"></button>
                                        </td>
                                        <td>
                                            {{_charge.otherchargename}}
                                        </td>
                                        <td style="text-align: right;">
                                            {{_charge.amount | number:'1.2'}}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td style="text-align: center;width: 100px;" [hidden]="_action == 'view'">
                                        </td>
                                        <td>
                                        </td>
                                        <td style="text-align: right;">
                                            {{totalChargeAmount | number:'1.2'}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Payment Details">
                        <div class="card">
                            <p-table [value]="_agentaccountdetails" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]"
                                [globalFilterFields]="['productname','variantdescription']" [rowHover]="true"
                                dataKey="billno"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Account Name</th>
                                        <th>Ref No</th>
                                        <th>Amount</th>
                                        <th>Balance</th>
                                        <th>Total Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_accountdetail>
                                    <tr>
                                        <td>
                                            {{_accountdetail.name}}
                                        </td>
                                        <td>
                                            {{_accountdetail.voucherno}}
                                        </td>
                                        <td>
                                            {{_accountdetail.creditamount}}
                                        </td>
                                        <td>
                                            {{_accountdetail.tempPendingamount}}
                                        </td>
                                        <td>
                                            {{_accountdetail.creditamount}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                </p-tabView>
                <!-- <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Payment Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div> -->


                <!-- <table class="normal-table">

                    <tr>
                        <td>
                            <button pButton pRipple label="Send To Approval" [disabled]="isDisabledsendtoapproval"
                                (click)="SendToApproval($event)" icon="las la-check"></button>
                        </td>
                    </tr>
                </table> -->

                <table>
                    <tr>
                        <td>
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>
            </div>

            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>