import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { RptLotWiseStockService } from 'src/app/core/Services/reports/Stock/Rpt-lot-wise-stock/rpt-lot-wise-stock.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-rpt-lot-wise-stock',
  templateUrl: './rpt-lot-wise-stock.component.html',
  styleUrls: ['./rpt-lot-wise-stock.component.css']
})
export class RptLotWiseStockComponent implements OnInit {
  _rptlotwisestockform: FormGroup;
  _IsProgressSpinner: boolean = true;
  _branchlist: any[] = []
  _producttypelist: any[] = []
  _productcategorylist: any[] = []
  _productlist: any[] = []
  _brandlist: any[] = []
  _stocktypelist: any[] = []
  _supplierlist: any[] = []
  branchid: any[] = []
  producttypeid: any[] = []
  productcatid: any[] = []
  productid: any[] = []
  brandid: any[] = []
  supplierid: any[] = []
  _ReportLists: any[] = []
  _userid: number = parseInt(sessionStorage["userid"]);
  datepipe = new DatePipe('en-US');
  cols: any[];
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private RptLotWiseStockService: RptLotWiseStockService,
    private exportUtility: ExportUtility,
    private _CustomExceptionService: CustomExceptionService
  ) { }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'branchname', header: 'Branch Name' },
      { field: 'producttypename', header: 'Product Type Name' },
      { field: 'productcategoryname', header: 'Category Name' },
      { field: 'productname', header: 'Product Name' },
      { field: 'brandname', header: 'Brand' },
      { field: 'salesuomname', header: 'Uom' },
      { field: 'severityname', header: 'Severity' },
      { field: 'stock', header: 'UnRestricted Ok Stock' },
    ]
    this._rptlotwisestockform = this.fb.group({
      stocktype: [],
      withzero: [],
      consignmentstock: []
    })
    this._rptlotwisestockform.controls['stocktype'].setValue("MSC00243");
    this._rptlotwisestockform.get("withzero").setValue(true);
    this._rptlotwisestockform.get("consignmentstock").setValue(false);
    this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
    let StockType = this._rptlotwisestockform.get("stocktype").value
    let WithZero = this._rptlotwisestockform.get("withzero").value
    let ConsignmentStock = this._rptlotwisestockform.get("consignmentstock").value
    let TaskName = "PageOnLoad"
    let Data = {
      "UserId": this._userid,
      "TaskName": TaskName,
      "BranchId": this.branchid || [],
      "SupplierId": this.supplierid || [],
      "ProducttypeId": this.producttypeid || [],
      "CategoryId": this.productcatid || [],
      "ProductId": this.productid || [],
      "BrandId": this.brandid || [],
      "StockType": StockType,
      "WithZero": WithZero
    }
    this._IsProgressSpinner = true;
    this.RptLotWiseStockService.PageOnLoad(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._producttypelist = resultJSON.producttypeList;
      this._stocktypelist = resultJSON.stockType;
      this._supplierlist = resultJSON.supplierList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory() {
    this._productcategorylist = []
    this._productlist = []
    this._brandlist = []
    this.productcatid = []
    this.productid = []
    this.brandid = []
    this._ReportLists = []
    this._rptlotwisestockform.get("stocktype").setValue("MSC00243");
    this._rptlotwisestockform.get("withzero").setValue(true);
    if (!this.branchid || !this.branchid.length) {
      this.producttypeid = []
      this._CustomExceptionService.handleWarning("Please Select Branch")
      return
    }
    if (!this.producttypeid || !this.producttypeid.length) {
      this._CustomExceptionService.handleWarning("Please Select At Least One Product Type")
      return
    }
    let TaskName = "GetProductCategory"
    let StockType = this._rptlotwisestockform.get("stocktype").value;
    let WithZero = this._rptlotwisestockform.get("withzero").value;
    let ConsignmentStock = this._rptlotwisestockform.get("consignmentstock").value
    let Data = {
      "UserId": this._userid,
      "TaskName": TaskName,
      "BranchId": this.branchid,
      "SupplierId": this.supplierid || [],
      "ProducttypeId": this.producttypeid,
      "CategoryId": this.productcatid || [],
      "ProductId": this.productid || [],
      "BrandId": this.brandid || [],
      "StockType": StockType,
      "WithZero": WithZero
    }
    this._IsProgressSpinner = true
    this.RptLotWiseStockService.PageOnLoad(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productcategorylist = resultJSON.productcategorylist;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProduct() {
    this._productlist = []
    this._brandlist = []
    this.productid = []
    this.brandid = []
    this._ReportLists = []
    this._rptlotwisestockform.get("stocktype").setValue("MSC00243");
    this._rptlotwisestockform.get("withzero").setValue(true);
    if (!this.branchid || !this.branchid.length) {
      this.producttypeid = []
      this.productcatid = []
      this._CustomExceptionService.handleWarning("Please Select Branch")
      return
    }
    if (!this.producttypeid || !this.producttypeid.length) {
      this.productcatid = []
      this._CustomExceptionService.handleWarning("Please Select At Least One Product Type")
      return
    }
    if (!this.productcatid || !this.productcatid.length) {
      this._CustomExceptionService.handleWarning("Please Select At Least One Product Category")
      return
    }
    let TaskName = "GetProduct"
    let StockType = this._rptlotwisestockform.get("stocktype").value;
    let WithZero = this._rptlotwisestockform.get("withzero").value;
    let ConsignmentStock = this._rptlotwisestockform.get("consignmentstock").value
    let Data = {
      "UserId": this._userid,
      "TaskName": TaskName,
      "BranchId": this.branchid,
      "SupplierId": this.supplierid || [],
      "ProducttypeId": this.producttypeid,
      "CategoryId": this.productcatid,
      "ProductId": this.productid || [],
      "BrandId": this.brandid || [],
      "StockType": StockType,
      "WithZero": WithZero
    }
    this._IsProgressSpinner = true
    this.RptLotWiseStockService.PageOnLoad(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productlist = resultJSON.productlist;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ChangeProduct() {
    this._brandlist = []
    this.brandid = []
    this._ReportLists = []
    this._rptlotwisestockform.get("stocktype").setValue("MSC00243");
    this._rptlotwisestockform.get("withzero").setValue(true);
    if (!this.branchid || !this.branchid.length) {
      this.producttypeid = []
      this.productcatid = []
      this.productid = []
      this._CustomExceptionService.handleWarning("Please Select Branch")
      return
    }
    if (!this.producttypeid || !this.producttypeid.length) {
      this.productcatid = []
      this.productid = []
      this._CustomExceptionService.handleWarning("Please Select At Least One Product Type")
      return
    }
    if (!this.productcatid || !this.productcatid.length) {
      this.productid = []
      this._CustomExceptionService.handleWarning("Please Select At Least One Product Category")
      return
    }
    if (!this.productid || !this.productid.length) {
      this._CustomExceptionService.handleWarning("Please Select At Least One Product")
      return
    }
    let TaskName = "GetBrand"
    let StockType = this._rptlotwisestockform.get("stocktype").value;
    let WithZero = this._rptlotwisestockform.get("withzero").value;
    let ConsignmentStock = this._rptlotwisestockform.get("consignmentstock").value
    let Data = {
      "UserId": this._userid,
      "TaskName": TaskName,
      "BranchId": this.branchid,
      "SupplierId": this.supplierid || [],
      "ProducttypeId": this.producttypeid,
      "CategoryId": this.productcatid,
      "ProductId": this.productid,
      "BrandId": this.brandid || [],
      "StockType": StockType,
      "WithZero": WithZero
    }
    this._IsProgressSpinner = true
    this.RptLotWiseStockService.PageOnLoad(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      let productbrand = resultJSON.brandlist;
      let uniqueChars = [...new Set(productbrand)];
      uniqueChars = productbrand.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.brandid === test.brandid
        )
      );
      this._brandlist = uniqueChars;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onChangeStockType() {
    let StockType = this._rptlotwisestockform.get("stocktype").value;
    let ConsignmentStock = this._rptlotwisestockform.get("consignmentstock").value
    if (StockType == "MSC00244" && ConsignmentStock == true) {
      this._rptlotwisestockform.get("consignmentstock").setValue(false);
    } else {
      this._rptlotwisestockform.get("consignmentstock").setValue(ConsignmentStock);
    }
  }
  GenerateReport() {
    if (!this.branchid || !this.branchid.length) {
      this._CustomExceptionService.handleWarning("Please Select Branch")
      return
    }
    let StockType = this._rptlotwisestockform.get("stocktype").value;
    if (!StockType) {
      this._CustomExceptionService.handleWarning("Please Select Stock Type")
      return
    }
    let TaskName = "GenerateReport"
    let WithZero = this._rptlotwisestockform.get("withzero").value;
    let ConsignmentStock = this._rptlotwisestockform.get("consignmentstock").value
    if (StockType == "MSC00244" && ConsignmentStock == true) {
      this._rptlotwisestockform.get("consignmentstock").setValue(false);
      ConsignmentStock = false
    }
    let Data = {
      "UserId": this._userid,
      "TaskName": TaskName,
      "BranchId": this.branchid || [],
      "SupplierId": this.supplierid || [],
      "ProducttypeId": this.producttypeid || [],
      "CategoryId": this.productcatid || [],
      "ProductId": this.productid || [],
      "BrandId": this.brandid || [],
      "StockType": StockType,
      "WithZero": WithZero
    }
    this._IsProgressSpinner = true
    this.RptLotWiseStockService.PageOnLoad(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ReportLists = resultJSON.lotwisestockreport;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear() {
    this._ReportLists = []
    this._productcategorylist = []
    this._productlist = []
    this._brandlist = []
    this.branchid = []
    this.producttypeid = []
    this.productcatid = []
    this.productid = []
    this.brandid = []
    this.supplierid = []
    this._rptlotwisestockform.controls['stocktype'].setValue("MSC00243");
    this._rptlotwisestockform.get("withzero").setValue(true);
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'Lot_Wise_Stock', 'xlsx');
  }
  ExportToPdf() {
    let finalizedList = this.GetAssetRegistrationList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Branch',
      'Product Type',
      'Product Category',
      'Product',
      'Brand',
      'Supplier',
      'Lot Number',
      'Severity',
      'Stock',
      'Expiry Date'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Lot_Wise_Stock.pdf');
      })
    })
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    if (!this._ReportLists || !this._ReportLists.length) {
      this._CustomExceptionService.handleWarning("No Data Available");
      return
    }
    this._ReportLists.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Branch"] = element.branchname;
      assetregistration["Product Type"] = element.producttypename;
      assetregistration["Product Category"] = element.categoryname;
      assetregistration["Product"] = element.productname;
      assetregistration["Brand"] = element.brandname;
      assetregistration["Supplier"] = element.suppliername;
      assetregistration["Lot Number"] = element.lotnumber;
      if (element.severityname == null || element.severityname == undefined) {
        assetregistration["Severity"] = "";
      } else {
        assetregistration["Severity"] = element.severityname;
      }
      assetregistration["Stock"] = element.stock;
      if (element.expirydate == null || element.expirydate == undefined) {
        assetregistration["Expiry Date"] = "";
      } else {
        assetregistration["Expiry Date"] = this.datepipe.transform(element.expirydate, 'dd/MM/yyyy');
      }
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
}