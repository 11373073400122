import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Journal } from 'src/app/core/Models/Journal';
import { Journaldetail } from 'src/app/core/Models/Journaldetail';
import { Metadatum } from 'src/app/core/Models/metadatum';
import * as ace from "ace-builds";
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { vSelectCurrency, vSelectRequestDate } from 'src/app/core/Validators/validation';
import { JournalService } from 'src/app/core/services/accounts/journal.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Entityreferenceview } from 'src/app/core/Views/Entityreferenceview';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-c-journal',
  templateUrl: './c-journal.component.html',
  styleUrls: ['./c-journal.component.css']
})

export class CJournalComponent implements OnInit {
  _IsClearVisible: boolean = false;
  _IsSaveVisible: boolean = false;
  _Journalform: FormGroup;
  _Journaldetailform: FormGroup;
  _Journalid: number;
  _Journal: Journal;
  _lstEntity: Metadatum[] = [];
  _lstEntityReferences: Entityreferenceview[] = [];
  _templstEntityReferences: Entityreferenceview[] = [];
  _templstEntityReferences1: Entityreferenceview[] = [];
  _Journaldetails: any[] = [];
  _Journaldetail: Journaldetail;
  selectedrows: any;
  _JournalOBJ: any;
  _totalDebitAmount: number = 0;
  _totalCreditAmount: number = 0;
  _CurrenciesTemp: Currency[] = [];
  _Currencies: Currency;
  _status: Metadatum;
  _accounts: Accountledger[] = [];
  _tempaccounts: Accountledger[] = [];
  _UserId: any;
  _refTypes: any;
  _refNos: any;
  _action: string = "create";
  _submitted = false;
  _IsProgressSpinner: boolean = true;
  mindate: Date;
  maxdate: Date;
  _totalBalanceAmount: number = 0;
  creditbalance: boolean = false
  debitbalance: boolean = false
  balanceamount : boolean = true
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  private _currentcurrencyform: any;

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _JournalService: JournalService,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _router: Router,
    private _hotkeysService: HotkeysService) {
  }

  InitializeForm() {
    this._Journalform = this.fb.group({
      journalid: [0],
      journaldate: [, vSelectRequestDate],
      narration: [],
      //recordstatus: [],
      currentcurrencyid: ['', vSelectCurrency],
      exchangerate: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      journaldetails: [],
      transactionincompleted: [1]
    });
    if (sessionStorage['Environmentenddate'] == "null") {
      this._Journalform.get('journaldate').setValue(new Date());
      this.maxdate = new Date();
    }
    else {
      this._Journalform.get('journaldate').setValue(new Date(sessionStorage['Environmentenddate']));
    }

    this._Journalform.controls['exchangerate'].setValue("1");
    if (this._CurrenciesTemp && this._CurrenciesTemp.length)
      this._Journalform.controls['currentcurrencyid'].setValue(this._CurrenciesTemp[0].currencyid);



    this._Journaldetailform = this.fb.group({
      journaldetailid: [0],
      journalid: [0],
      account: [],
      refType: [],
      refNo: [],
      debitamount: [],
      creditamount: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      currencyuniquerefid: []
    });
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();
    this._UserId = sessionStorage["userid"];
    this._Journalid = history.state?.journalid ? history.state?.journalid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration()
    const data = sessionStorage.getItem('LedgerData');
		if (data) {
			const parsedData = JSON.parse(data);
			this._Journalid = parsedData.voucherno;
			this._UserId = parsedData.userid;
			this._action = parsedData.action;
			sessionStorage.removeItem('LedgerData');
		  }
    this._IsProgressSpinner = true;
    this._JournalService.PageOnLoad(this._Journalid, this._UserId).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // For DropDown
      this._CurrenciesTemp = resultJSON.currencies || [];
      console.log("this", this._CurrenciesTemp)
      this._Currencies = resultJSON.currencies;
      this._status = resultJSON.recordStatus;
      this._accounts = resultJSON.accountledgers;
      this._tempaccounts = resultJSON.accountledgers;
      this._refTypes = resultJSON.referenceTypes;
      this._lstEntity = resultJSON.lstentity;
      this._templstEntityReferences = resultJSON.lstentityreferenceview;

      if (this._CurrenciesTemp && this._CurrenciesTemp.length)
        this._Journalform.controls['currentcurrencyid'].setValue(this._CurrenciesTemp[0].currencyid);


      this._IsProgressSpinner = false;
      //For Update
      if (this._action == 'create') {
        var l_JournalDtail = new Journaldetail();
        l_JournalDtail.createdby = sessionStorage["userid"];
        l_JournalDtail.createdon = new Date();
        l_JournalDtail.modifiedby = 0;
        l_JournalDtail.modifiedon = new Date();
        l_JournalDtail.journaldetailid = 0;
        l_JournalDtail.journalid = this._Journalform.get("journalid").value;
        this._Journaldetails.push(l_JournalDtail);
      }
      if (this._action == 'view') {
        this._lstEntityReferences = (resultJSON.lstentityreferenceview || []);
        this._IsClearVisible = true;
        const updateJSON = result;//JSON.parse(JSON.stringify(result));
        updateJSON.journal.journaldate = new Date(updateJSON.journal.journaldate);

        var obj = {
          journalid: updateJSON.journal.journalid,
          journaldate: updateJSON.journal.journaldate,
          narration: updateJSON.journal.narration,
          currentcurrencyid: updateJSON.journal.currentcurrencyid,
          exchangerate: updateJSON.journal.exchangerate,
          createdby: updateJSON.journal.createdby,
          createdon: updateJSON.journal.createdon,
          modifiedby: updateJSON.journal.modifiedby,
          modifiedon: updateJSON.journal.modifiedon,
          transactionincompleted: updateJSON.journal.transactionincompleted,
          journaldetails: []
        }

        this._Journalform.setValue(obj);
        this._Journaldetails = (updateJSON.journaldetailsviews || []);

        this._Journaldetails.forEach(element => {
          var entityreferencelist = this._lstEntityReferences.filter(f => f.type == element.entity);
          element._lstEntityReferences = entityreferencelist
        });

        this._Journalform.disable();
        this.CalculateTotalCreditDebit();
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ChangeCurrencies(event): void {
    var exchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].exchangerate;
    this._Journalform.controls['exchangerate'].setValue(exchangerate);
    this._Journaldetails = [];
    this.AddRow();
    this.CalculateTotalCreditDebit();

  }

  RemoveJournal(pJournalDetail: Journaldetail) {

    var index = this._Journaldetails.indexOf(pJournalDetail);
    this._Journaldetails.splice(index, 1);
    if (this._Journaldetails.length == 0) {
      this.AddRow();
      this._accounts = this._tempaccounts;
    }
    this.CalculateTotalCreditDebit();
  }

  addJournal(event) {
    if (!this.IsValidateToNextRow())
      return;
    this.AddRow();
  }

  AddRow() {
    var l_JournalDtail = new Journaldetail();
    l_JournalDtail.createdby = sessionStorage["userid"];
    l_JournalDtail.createdon = new Date();
    l_JournalDtail.modifiedby = 0;
    l_JournalDtail.modifiedon = new Date();
    l_JournalDtail.journaldetailid = 0;
    l_JournalDtail.journalid = this._Journalform.get("journalid").value;
    this._Journaldetails.push(l_JournalDtail);
  }

  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  onSave() {
    this._submitted = true;
    if (this._Journalform.valid) {
      var errMsg = null;

      if (this._totalDebitAmount <= 0 || this._totalCreditAmount <= 0 || (this._totalDebitAmount != this._totalCreditAmount))
        errMsg = "Total Debit Amount must be Equal to Total Credit Amount";

      if (!this._Journaldetails.length)
        errMsg = "Grid must not be empty.";

      if (this.ShowErrorMsg(errMsg))
        return;
      this._Journaldetailform.get("journalid").setValue(this._Journalform.get("journalid").value);
      this._Journal = this._Journalform.value;
      var Dates = this._Journalform.get("journaldate").value;
      var datePipe = new DatePipe("en-US");
      this._Journal.journaldate = new Date(datePipe.transform(Dates, 'yyyy-MM-dd'));

      this._Journaldetails
      this._JournalOBJ = { "Journal": this._Journal, "Journaldetail": this._Journaldetails, "UserId": this._UserId };
      this._IsSaveVisible = true;

      if (this._action == 'create') {
        this._Journal.branchid = sessionStorage["currentbranchid"];
        this._Journal.createdon = new Date();
        this._Journal.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._JournalService.create(this._JournalOBJ, this._Journal.createdby).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resutJSON && resutJSON.tranStatus.result == true) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsSaveVisible = false;
            this._IsProgressSpinner = false;
          }
        }, error => {
          this._CustomExceptionService.handleError(error); this._IsSaveVisible = false; this._IsProgressSpinner = false;
        });
      }
    }
  }
  reset(event) {
    this._Journalform.reset();
    this._Journaldetailform.reset();
    this._IsSaveVisible = false;
    this._IsClearVisible = false;
    this.InitializeForm();
    this._submitted = false;
    this._Journaldetails = [];
    this.AddRow();
  }
  goBack(event) {
    this._router.navigate(['/VJournal']);
  }
  onEd() {
    alert("test");
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._JournalOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }

  ChangeAccount($event, model, model2) {
    model2["_showRefType"] = true;
    model["_refNos"] = [];
    if (!$event.value) return;
    let ledger = this._accounts.filter(f => f.ledgerid == $event.value)[0];
    model.accountdesc = ledger.name;
    this._IsProgressSpinner = true;
    this._JournalService.GetReferenceNo(ledger.ledgerid).subscribe((result) => {
      const resutJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resutJSON && resutJSON.tranStatus.result == true) {
        model2["_showRefType"] = !(resutJSON.journalreferencenoviews.length > 0);
        model["_refNos"] = resutJSON.journalreferencenoviews;
        this._templstEntityReferences1 = resutJSON.journalreferencenoviews;
      }
      else {
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
      } this._IsProgressSpinner = false;
    }, error => {
      this._CustomExceptionService.handleError(error);
      this._IsProgressSpinner = false;
    });
    var _series = ledger.series;
    this._accounts = this._tempaccounts.filter(f => f.series == _series || f.series == null);
  }

  ChangeReference($event, model) {
    var metadesc = this._refTypes.filter(f => f.metasubid == $event.value)[0].metasubdescription;
    model.refTypeDesc = metadesc;
  }

  ChangeReferenceNO($event, model) {
    if (!$event.value) return;
    var currencyuniquerefid = model._refNos.filter(f => f.referenceno == $event.value)[0].currencyuniquerefid || null;
    model.currencyuniquerefid = currencyuniquerefid;
    var ledgerid = model.account;
    var currencyid = this._Journalform.get("currentcurrencyid").value;

    if (!currencyid) {
      this._CustomExceptionService.handleError("Please Select Currency");
      return;
    }

    var refno = $event.value;

    this._JournalService.GetReferenceNoDetails(ledgerid, currencyid, refno).subscribe((result) => {
      const resutJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resutJSON && resutJSON.tranStatus.result == true) {
        model.balanceamount = resutJSON.journalreferencenodetails[0].balanceamount;
      }
      else {
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
      } this._IsProgressSpinner = false;
    }, error => {
      this._CustomExceptionService.handleError(error);
      this._IsProgressSpinner = false;
    });

  }


  ChangeDebitAmount(value, oprModel) {
    oprModel["debitamount"] = oprModel["debitamount"] || 0;
    oprModel.isCreditDisable = false;
    if (value)
      oprModel.isCreditDisable = true;

    if (oprModel.balanceamount) {
      if (oprModel.balanceamount < oprModel.debitamount) {
        this._CustomExceptionService.handleError("Debit Amount Should be not greater than balance amount");
        oprModel.debitamount = 0;
        return;
      }
    }
    this.CalculateTotalCreditDebit();
  }

  ChangeCreditAmount(value, oprModel) {
    oprModel["creditamount"] = oprModel["creditamount"] || 0;
    oprModel.isDebitDisable = false;
    if (value)
      oprModel.isDebitDisable = true;

    if (oprModel.balanceamount) {
      if (oprModel.balanceamount < oprModel.creditamount) {
        this._CustomExceptionService.handleError("Credit Amount Should be not greater than balance amount");
        oprModel.creditamount = 0;
        return;
      }
    }
    this.CalculateTotalCreditDebit();
  }

  ShowErrorMsg(errMsg) {
    if (!errMsg)
      return false;
    this.messageService.add({ severity: 'error', summary: 'Error Message', sticky: true, detail: errMsg });
    return true;
  }

  CalculateTotalCreditDebit() {
    var creditAmount = 0;
    var debitAmount = 0;
    if (!this._Journaldetails || this._Journaldetails.length < 0)
      return;
    var startIndex = 0;
    var endIndex = this._Journaldetails.length;
    for (var i = startIndex; i < endIndex; i++) {
      creditAmount += (this._Journaldetails[i].creditamount || 0);
      debitAmount += (this._Journaldetails[i].debitamount || 0);
    }
    this._totalCreditAmount = creditAmount;
    this._totalDebitAmount = debitAmount;

    /// calculation for balancess
    if (creditAmount > debitAmount) {
      this.balanceamount = false
      this.debitbalance = true
      this.creditbalance = false
      this._totalBalanceAmount = (creditAmount) - (debitAmount)

    }
    else if (creditAmount < debitAmount) {
      this.balanceamount = false
      this.debitbalance = false
      this.creditbalance = true
      this._totalBalanceAmount = (debitAmount) - (creditAmount)

    }
    else if (creditAmount == debitAmount) {
      this.balanceamount = true
      this.debitbalance = false
      this.creditbalance = false
      this._totalBalanceAmount =0
    }
    else if ((creditAmount == 0) && (debitAmount == 0)) {
      this.balanceamount = true
      this.debitbalance = false
      this.creditbalance = false
      this._totalBalanceAmount =0
    }

  }

  IsValidateToNextRow() {
    var res = false;
    var startIndex = 0;
    var endIndex = this._Journaldetails.length;
    for (var i = startIndex; i < endIndex; i++) {
      if (this._Journaldetails[i].account && ((this._Journaldetails[i].creditamount || 0) > 0 || (this._Journaldetails[i].debitamount) > 0))
        res = true
      else {
        res = false
        break;
      }
    }
    return res;
  }

  IsGridHasDuplicateLedger() {
    this._Journaldetails = this._Journaldetails || [];
    for (let i = 0; i < this._Journaldetails.length; i++) {
      let filter = this._Journaldetails.filter(f => f.account == this._Journaldetails[i].account)
      if (filter && filter.length > 1) {
        return true;
      }
    }
    return false;
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }


  ChangeEntityReference(detail: Journaldetail) {
    this._lstEntityReferences = this._templstEntityReferences.filter(f => f.type == detail.entity);
  }


}
