import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Currency } from 'src/app/core/Models/Currency';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Vehicle } from 'src/app/core/Models/Vehicle';
import { RptexpenseService } from 'src/app/core/Services/reports/Accounting/RptExpense/rptexpense.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { IAction } from 'src/app/core/Services/security/IActions';

import autoTable from 'jspdf-autotable'
@Component({
  selector: 'app-rpt-expense',
  templateUrl: './rpt-expense.component.html',
  styleUrls: ['./rpt-expense.component.css']
})
export class RptExpenseComponent implements OnInit {
  _expensereportform: FormGroup;
  _entitylist: Metadatum[];
  _branchlist: Branch[];
  _vehiclelist: Vehicle[];
  _currencylist: Currency[];
  _ExpenseReportviews: any[]
  _IsBranch: boolean = false;
  _IsVehicle: boolean = false;
  selectedrows: any[];
  cols: any[];
  _IsProgressSpinner: boolean = false;
  ExpenseObj: any;
  datepipe = new DatePipe('en-US');
  exportColumns: any[];
  _Action: IAction;
  mindate: Date;
  maxdate: Date;
  constructor(private fb: FormBuilder, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _ExpenseReport: RptexpenseService, private _CustomExceptionService: CustomExceptionService, private exportUtility: ExportUtility) { }
  InitializeExpenseReportForm() {
    this._expensereportform = this.fb.group({
      fromdate: [],
      todate: [],
      branchid: [],
      entity: [],
      currencyid: [],
      vehicleid: []
    });
    // this._expensereportform.get("fromdate").setValue(new Date);
    // this._expensereportform.get("todate").setValue(new Date);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._expensereportform.get("fromdate").setValue(new Date);
      this._expensereportform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._expensereportform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._expensereportform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

    this._expensereportform.get("currencyid").setValue(1);
    this._expensereportform.get("entity").setValue("MSC00280");
    this._expensereportform.get("branchid").setValue(parseInt(sessionStorage["currentbranchid"]));
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(167);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeExpenseReportForm();
    this.cols = [
      // {field:'sno', header: 'SNO'},
      { field: 'narration', header: 'Narration' },
      { field: 'journaldate', header: 'Transaction Date' },
      { field: 'creditamount', header: 'Credit Amount' },
      { field: 'debitamount', header: 'Debit Amount' },
      { field: 'remarks', header: 'Remarks' },
      { field: 'createdbyname', header: 'Created By' },
    ]
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this._IsProgressSpinner = true;
    this._ExpenseReport.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._currencylist = resultJSON.currencyList;
      this._branchlist = resultJSON.branchList;
      this._entitylist = resultJSON.entityreferenceList;
      this._vehiclelist = resultJSON.vehicleList;
      if (this._expensereportform.get("entity").value == "MSC00280") {
        this._IsBranch = true;
        this._IsVehicle = false;
      }
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  search() {
    let entityreference;
    if (this._expensereportform.get("entity").value == "MSC00280") {
      entityreference = this._expensereportform.get("branchid").value || "0";
    }
    else {
      entityreference = this._expensereportform.get("vehicleid").value || "0";
    }
    let Fromdate = this._expensereportform.get("fromdate").value;
    let Todate = this._expensereportform.get("todate").value;
    let convertfromdate = this.datepipe.transform(Fromdate, 'yyyy-MM-dd');
    let converttodate = this.datepipe.transform(Todate, 'yyyy-MM-dd');
    let currency = this._expensereportform.get("currencyid").value;
    let entitytype = this._expensereportform.get("entity").value || "0";
    if (convertfromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (converttodate == null) {
      this._CustomExceptionService.handleError("Please Select TO Date");
      return;
    }

    if (convertfromdate > converttodate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this.ExpenseObj = { "entityreference": entityreference || '0', "currency": currency, "fromdate": convertfromdate, "todate": converttodate, "entitytype": entitytype, "userid": sessionStorage["userid"] };
    this._ExpenseReport.SearchExpense(this.ExpenseObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._ExpenseReportviews = resultJSON.rptexpenselst;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  exportExcel() {
    let ExpenseList = this.GetExpenseReportList();
    this.exportUtility.exportExcel(ExpenseList, 'ExpenseReport', 'xlsx');
  }

  // ExportToPdf() {
  //   let ExpenseList = this.GetExpenseReportList();
  //   this.exportUtility.ExportToPdf(ExpenseList, 'ExpenseReport.pdf');
  // }

  exportPdf() {
    if (!this._ExpenseReportviews || !this._ExpenseReportviews.length)
      return;
    let ExpenseList = this.GetExpenseReportList();
    const data = ExpenseList.map(Object.values);
    const head = [['Narration', 'Transaction Date', 'Credit Amount', 'Debit Amount', 'Remarks', 'Created By']]

    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        // const doc = new jsPDF.default(0,0);
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        // doc.autoTable(this.exportColumns, this._ExpenseReportviews);
        doc.save('ExpenseReport.pdf');
      })
    })
  }
  GetExpenseReportList() {
    if (!this._ExpenseReportviews || !this._ExpenseReportviews.length)
      return;
    let ExpenseList = [];
    this._ExpenseReportviews.forEach(element => {
      let expense: any = {};
      // expense["SNO"] = element+1;
      expense["Narration"] = element.narration;
      expense["Transaction Date"] = this.datepipe.transform(element.journaldate, 'dd/MM/yyyy');
      expense["Credit Amount"] = element.creditamount;
      expense["Debit Amount"] = element.debitamount;
      expense["Remarks"] = element.remarks;
      expense["Created By"] = element.createdbyname;
      ExpenseList.push(expense);
    });
    return ExpenseList;
  }
  clear() {
    this.InitializeExpenseReportForm();
    if (this._expensereportform.get("entity").value == "MSC00280") {
      this._IsBranch = true;
      this._IsVehicle = false;
    }
    this._ExpenseReportviews = [] = [];
  }
  onChange(event) {
    this._expensereportform.get("branchid").setValue(parseInt(sessionStorage["currentbranchid"]));
    this._expensereportform.get("vehicleid").reset();
    if (this._expensereportform.get("entity").value == "MSC00280") {
      this._IsBranch = true;
      this._IsVehicle = false;
    }
    else {
      this._IsVehicle = true;
      this._IsBranch = false;
    }
  }
}
