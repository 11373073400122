import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { AbstractControl } from '@angular/forms';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { IAction } from '../security/IActions';
import { Competitor } from '../../Models/Competitor';
@Injectable({
  providedIn: 'root'
})
export class CompetitorService {
  public _Action: IAction;
  public _CompetitorService = environment.CompetitorService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient, private _CustomExceptionService: CustomExceptionService) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(p_competitorid: number): Observable<any> {
    const headers = this.header;
    var Query = this._CompetitorService + '/' + p_competitorid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll() {
    const headers = this.header;
    // 15.all the datas should fetch in particular columns.based on country code
    var Query = this._CompetitorService + '/FetchAll';


    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      return data;
    }));
  }

  create(p_competitor: object) {
    const headers = this.header;
    //console.log("Create");
    var Query = this._CompetitorService;
    return this.http.post<{ data: any }>(Query, p_competitor, { headers });
  }

  edit(p_competitor: object) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._CompetitorService;

    return this.http.put<{ data: any }>(Query, p_competitor, { headers });
  }
  Cancel(competitorid: number, userid: number) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._CompetitorService + '/Cancel/' + competitorid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
}
