import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { productsCattegory } from 'src/app/core/Models/overallclass';
import { Producttype } from 'src/app/core/Models/Producttype';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { RptstocksummaryService } from 'src/app/core/Services/reports/Stock/RptStockSummary/rptstocksummary.service';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-rptstocksummary',
  templateUrl: './rptstocksummary.component.html',
  styleUrls: ['./rptstocksummary.component.css']
})
export class RptstocksummaryComponent implements OnInit {
  _producttype: Producttype[];
  _productcategory: productsCattegory[];
  _productname: any[];
  _stocksummaryform: FormGroup;
  _branchid: number;
  _userid: number;
  _branch: Branch[];
  selectedrows: any;
  today = new Date();
  _ProductTypeObj: any;
  _src: SafeResourceUrl;
  _ProductCategoryObj: any;
  _IsProgressSpinner: boolean = true;
  _ProductwisestockObj: any;
  _stocksummaryObj: any;
  _branchlist: Branch[] = [];
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  mindate: Date;
  maxdate: Date;
  cols: any[];
  _submitted = false;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private utility: CommonUtilities,
    private _StockSummaryService: RptstocksummaryService,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer
  ) { }
  InitializeStocksummaryForm() {
    this._stocksummaryform = this.fb.group({
      producttypeid: [''],
      productcategoryid: [''],
      productid: [''],
      fromdate: [],
      todate: [],
      branchid: ['']
    });
    this._stocksummaryform.get("fromdate").setValue(new Date);
    this._stocksummaryform.get("todate").setValue(new Date);
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._stocksummaryform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      currencyid: [0],
      levelid: [0]
    })

    if (sessionStorage['Environmentenddate'] == "null") {
      this._stocksummaryform.get("fromdate").setValue(new Date);
      this._stocksummaryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._stocksummaryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._stocksummaryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = parseInt(sessionStorage["userid"]);

    this._stocksummaryform.controls['branchid'].setValue(this._branchid);
    this._StockSummaryService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._producttypelist = resultJSON.producttypeList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._StockSummaryService.getProductCategories(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("category", resultJSON);
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._StockSummaryService.getProducts(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  clear() {
    this._submitted = true;
    this._stocksummaryform.reset();
    this.InitializeStocksummaryForm();
    this._productcategorylist = [];
    this._productlist = [];
    this.productcatid = [];
    this.producttypeid = [];
    this.productid = [];
    this._stocksummaryform.controls['branchid'].setValue(this._branchid);
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  GenerateReport(event) {
    if (this.producttypeid.length == 0) {
      this._CustomExceptionService.handleError("Please Select Atleast one Product Type");
      return;
    }
    if (this.productcatid.length == 0) {
      this._CustomExceptionService.handleError("Please Select Atleast one Product Category");
      return;
    }
    if (this.productid.length == 0) {
      this._CustomExceptionService.handleError("Please Select Atleast one Product");
      return;
    }
    var _fromdate = this._stocksummaryform.get("fromdate").value;
    var _todate = this._stocksummaryform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than to Date");
      return;
    }
    var Branchid = this._stocksummaryform.get("branchid").value == null ? 0 : this._stocksummaryform.get("branchid").value;
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._stocksummaryObj = { "FromDate": fromdate, "ToDate": todate, "Branchid": Branchid, "ProductIds": this.productid, "CurrentBranchId": parseInt(sessionStorage["currentbranchid"]), "Userid": parseInt(sessionStorage["userid"]) };
    this._StockSummaryService.Print(this._stocksummaryObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}