import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Branch } from 'src/app/core/Models/Branch';
import { Employee } from 'src/app/core/Models/Employee';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Onlinestockdelivery } from 'src/app/core/Models/OnlineStockDelivery';
import { Onlinestockdeliverydetail } from 'src/app/core/Models/OnlineStockDeliveryDetail';
import { User } from 'src/app/core/Models/User';
import { OnlineStockDeliveryService } from 'src/app/core/Services/ecommerce/online-stock-delivery.service';
import { vDeliveryDateSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { KeyPress } from 'src/assets/js/KeyPress';
declare var $: any

@Component({
  selector: 'app-c-online-stock-delivery',
  templateUrl: './c-online-stock-delivery.component.html',
  styleUrls: ['./c-online-stock-delivery.component.css']
})
export class COnlineStockDeliveryComponent implements OnInit {
  _OnlineStockdeliveryform: FormGroup;
  onlineStockform: FormGroup;
  _invoiceno: any = [] = [];
  _deliverytype: Metadatum;
  _productdetails: Onlinestockdeliverydetail[] = [];
  _drivername: Employee[] = [];
  _branches: Branch[] = []
  isDisabledselectall = false;
  _selectedevidencenoIndex: number[] = [];
  isDisabledstockrequest = false;
  _submitted = false;
  selectedProductDetailsRows: Onlinestockdeliverydetail[] = [];
  _Stockdelivery: Onlinestockdelivery;
  _OnlineStockdeliveryObj: any;
  isDisableddriver = true;
  isDisabledvehicle = true;
  isDisabledsave = false;
  isDisablereset = false;
  _vehicleno: any;
  _evidenceno: any;
  _IsProgressSpinner: boolean = false;
  _branchid = sessionStorage["currentbranchid"];
  _userid = sessionStorage["userid"]
  _isselfpickup: boolean = false;
  _stockdeliveryid: number;
  _ProductdetailsObj: any;
  _action: string = "create";
  IsCollectingBranch: boolean;
  _Tellernames: User;
  IsNotOnlineBranch: boolean = false;
  isdisabledbarcode = false;
  _tripstartclosuregetdrivername: any[];
  productbarcode: string;

  constructor(private fb: FormBuilder, private utility: CommonUtilities, private _OnlineStockDeliveryservice: OnlineStockDeliveryService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private _router: Router, public keypress: KeyPress) {
    this._stockdeliveryid = history.state?.stockdeliveryid ? history.state?.stockdeliveryid : 0;
    this._isselfpickup = history.state?.isselfpickup ? history.state?.isselfpickup : false;
  }

  InitializeStockdetailForm() {
    this._OnlineStockdeliveryform = this.fb.group({
      stockdeliveryid: [0],
      stockdeliveryno: [0],
      stockdeliverydate: ['', vDeliveryDateSelect],
      isselfpickup: [false],
      deliverytype: [],
      vehicleid: [],
      driverid: [],
      remarks: [],
      tripstatus: [],
      rv: [],
      branchid: [],
      createdby: [],
      createdon: [],

    });
    this.onlineStockform = this.fb.group({
      salesinvoiceid: [],
      tellerid: []
    })
    this._OnlineStockdeliveryform.get("stockdeliverydate").setValue(new Date);
    this._OnlineStockdeliveryform.get("branchid").setValue(parseInt(this._branchid));
    this._OnlineStockdeliveryform.get("deliverytype").setValue("MSC00205");


  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeStockdetailForm();
    if (this._branchid != 60) {
      this.IsNotOnlineBranch = true;
    }
    this.PageOnload();
  }
  PageOnload() {
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._IsProgressSpinner = true;
    this._OnlineStockDeliveryservice.PageOnload(this._stockdeliveryid, this._branchid, this._isselfpickup).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // branches deliveryType drivers vehicles salesInvoiceNos
      this._vehicleno = resultJSON.vehicles;
      this._deliverytype = resultJSON.deliveryType;
      this._branches = resultJSON.branches;
      this._branches = resultJSON.branches.filter(f => f.branchid !== 60)
      this._drivername = resultJSON.drivers;
      this._invoiceno = resultJSON.salesInvoiceNos;
      this._Tellernames = resultJSON.users;
      this._tripstartclosuregetdrivername = resultJSON.tripstartclosuregetdrivernames;
      this._IsProgressSpinner = false;
      $("input[name='productbarcode']").focus();
      if (this._action == 'view') {
        this._OnlineStockdeliveryform.disable();
        this.onlineStockform.disable();
        this.isDisabledsave = true;
        this.isDisablereset = true;
        this.isdisabledbarcode = true;
      }
      if (this._action == 'edit' || this._action == 'view') {
        this._submitted = false;
        this.isdisabledbarcode = true;
        const updateJSON = JSON.parse(JSON.stringify(result));
        if (updateJSON.onlinestockdelivery.length > 0) {
          updateJSON.onlinestockdelivery[0].stockdeliverydate = new Date(updateJSON.onlinestockdelivery[0].stockdeliverydate);
          this._OnlineStockdeliveryform.setValue(updateJSON.onlinestockdelivery[0])
        }
        this._productdetails = updateJSON.onlinestockdeliverydetail;
        if (this._branchid != 60) {
          this.onlineStockform.controls["tellerid"].setValue(updateJSON.onlinestockdeliverydetail.length>0?updateJSON.onlinestockdeliverydetail[0].tellerid:0);
        }
      

        this._IsProgressSpinner = false;
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  OnSelectSelfPickup(event) {
    this.onlineStockform.controls['salesinvoiceid'].reset()
    this._IsProgressSpinner = true;
    var selectedselfpickup = event.checked;
    this._isselfpickup = selectedselfpickup;
    this._productdetails = [] = [];
    this.selectedProductDetailsRows = [] = [];
    this._invoiceno = [] = [];
    this._OnlineStockDeliveryservice.PageOnload(this._stockdeliveryid, this._branchid, this._isselfpickup).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._invoiceno = resultJSON.salesInvoiceNos;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GetInvoiceProducts(event) {
    var selectedevent = event.value;
    this._productdetails = [] = [];
    this.selectedProductDetailsRows = [] = [];
    if (selectedevent.length == 0 || selectedevent == null) {
      return;
    }
    else {
      this._IsProgressSpinner = true;
      var isselfpickup = this._OnlineStockdeliveryform.get("isselfpickup").value;
      this._ProductdetailsObj = { "SalesInvoiceNo": selectedevent, "Branchid": this._branchid, "Userid": this._userid, "Isselfpickup": isselfpickup }
      this._OnlineStockDeliveryservice.GetInvoiceProducts(this._ProductdetailsObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productdetails = resultJSON.getinvoiceproductdetails;
        for (let i = 0; i < this._productdetails.length; i++) {
          this._productdetails[i].deliveryquantity = this._productdetails[i].invoicequantity;
          if (this._productdetails[i].collectingbranchid != 0) {
            this._productdetails[i].IsCollectingBranch = true;
            this._productdetails[i].collectingbranchid = 0;
          } else {
            this._productdetails[i].IsCollectingBranch = false;
          }

        }
        $("input[name='productbarcode']").focus();
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  onchangedeliverytype(event) {
    //vehicle
    if (event.value == "MSC00206") {
      //17.if selected delivery type value is 'vehicle' , vehicle no and driver input text field shouldbe visible, and person input text field shouldbe hide.
      this.isDisableddriver = false;
      this.isDisabledvehicle = false;
    }
    else {
      //16.if selected delivery type value is 'hand' , vehicle no and driver input text field shouldbe hide, and person input text field shouldbe visible.
      this.isDisableddriver = true;
      this.isDisabledvehicle = true;
      this._OnlineStockdeliveryform.controls['driverid'].setValue(0);
      this._OnlineStockdeliveryform.controls['vehicleid'].setValue(0);

    }
  }

  ChangeVehicle(event): void {
    let driver;

    driver = (this._tripstartclosuregetdrivername || []).find(f => f.vehicleid == event.value);

    if (driver != null && driver != undefined) {
      if (driver.driverid != 0) {
        this._OnlineStockdeliveryform.controls['driverid'].setValue(driver.driverid);
        this.isDisableddriver = true;
      }
      else {
        this._OnlineStockdeliveryform.controls['driverid'].setValue(0);
        this.isDisableddriver = false;
      }
    }
    else {
      this._OnlineStockdeliveryform.controls['driverid'].setValue(0);
      this.isDisableddriver = false;
    }

  }

  GetStock(_productdetail) {
    let branchid = _productdetail.collectingbranchid;
    if (!branchid) {
      _productdetail.currentstock = 0;
      return;

    }
    let productid = _productdetail.productid;
    this._IsProgressSpinner = true;
    this._OnlineStockDeliveryservice.GetStock(productid, branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.stock) {
        _productdetail.currentstock = resultJSON.stock.stock1 || 0;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSave() {
    this._submitted = true;
    let deliverytype = this._OnlineStockdeliveryform.get("deliverytype").value;
    let vehicleid = this._OnlineStockdeliveryform.get("vehicleid").value;
    let driverid = this._OnlineStockdeliveryform.get("driverid").value;

    if (deliverytype == "MSC00206") {
      if (!vehicleid) {
        this._CustomExceptionService.handleError("Please select vechicle");
        return;
      }
      if (!driverid) {
        this._CustomExceptionService.handleError("Please select driver");
        return;
      }
    }

    let tellerid = this.onlineStockform.get("tellerid").value;
    if (this._branchid != 60) {
      if (!tellerid) {
        this._CustomExceptionService.handleError("Please select Teller Name");
        return;
      }

    }
    for (let i = 0; i < this.selectedProductDetailsRows.length; i++) {
      if (this._branchid == 60) {
        if (!this.selectedProductDetailsRows[i].collectingbranchid || this.selectedProductDetailsRows[i].collectingbranchid == 0) {
          this._CustomExceptionService.handleError("Please select collecting branch");
          return;
        }
        if (this.selectedProductDetailsRows[i].currentstock < this.selectedProductDetailsRows[i].invoicequantity) {
          this._CustomExceptionService.handleError("Current Stock value should be greater than or equal to invoice quantity Or Please select other branch ");
          return;
        }
      }


    }
    //Product Details
    var l_ProductDetails: Onlinestockdeliverydetail[] = [];

    Object.keys(this.selectedProductDetailsRows).map((Index) => {
      var currentRow = this.selectedProductDetailsRows[Index];
      var l_productdetails = new Onlinestockdeliverydetail();
      if (currentRow.IsCollectingBranch == true) {
        l_productdetails.collectingbranchid = 0;
      } else {
        l_productdetails.collectingbranchid = currentRow.collectingbranchid;
      }
      l_productdetails.stockdeliverydetailid = 0;
      l_productdetails.stockdeliveryid = 0;
      l_productdetails.eorderid = currentRow.eorderid;
      l_productdetails.eorderno = currentRow.eorderno;
      l_productdetails.salesinvoiceid = currentRow.salesinvoiceid;
      l_productdetails.salesinvoiceno = currentRow.salesinvoiceno;
      l_productdetails.customerid = currentRow.customerid;
      l_productdetails.tellerid = tellerid || 0;
      l_productdetails.uomcode = currentRow.uomcode;
      l_productdetails.productid = currentRow.productid;
      l_productdetails.variantid = currentRow.variantid;
      l_productdetails.invoicequantity = currentRow.invoicequantity;
      l_productdetails.deliveryquantity = currentRow.deliveryquantity;
      l_productdetails.status = "MSC00001"
      l_productdetails.remarks = "";
      l_productdetails.ispodverified = false;
      l_productdetails.uomcode = currentRow.uomcode;
      l_productdetails.createdby = sessionStorage["userid"];
      l_productdetails.createdon = new Date();
      l_ProductDetails.push(l_productdetails);
    })
    if (this._OnlineStockdeliveryform.valid) {
      if (this.selectedProductDetailsRows.length > 0) {
        var DeliveryDate = this._OnlineStockdeliveryform.get("stockdeliverydate").value;
        var datePipe = new DatePipe("en-US");
        var pDeliveryDate = datePipe.transform(DeliveryDate, 'yyyy-MM-dd');
        //For Model and Form Mapping
        this._Stockdelivery = this._OnlineStockdeliveryform.getRawValue();
        this._Stockdelivery.stockdeliverydate = (new Date(pDeliveryDate));
        this._OnlineStockdeliveryObj = { "Onlinestockdelivery": this._Stockdelivery, "Onlinestockdeliverydetail": l_ProductDetails };
        if (this._action == 'create') {
          this._Stockdelivery.createdby = parseInt(sessionStorage["userid"]);
          this._Stockdelivery.stockdeliveryid = 0;
          this._Stockdelivery.createdon = new Date();
          this._Stockdelivery.branchid = parseInt(sessionStorage["BranchID"]);
          this._IsProgressSpinner = true;
          this._OnlineStockDeliveryservice.Create(this._OnlineStockdeliveryObj).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
              this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
              this.reset(null);
              this.ngOnInit();
              this._IsProgressSpinner = false;
            }
            else {
              this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
              this._IsProgressSpinner = false;
            }
          },
            error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

        }
      } else {
        this._CustomExceptionService.handleWarning(usererrors.Grid_Select_02);
      }
    }
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {

  }
  reset(event) {
    this.InitializeStockdetailForm();
    this._productdetails = [] = [];
    this.selectedProductDetailsRows = [] = [];
    this._invoiceno = [] = [];
    $("input[name='productbarcode']").focus();
  }

  goBack(event) {
    this._router.navigate(['/VOnlineStockDelivery']);
  }
  SelectAll(event) {

  }

  // For barcode Scanning Option:
  event: any = {
    value: []
  }


  FetchProductUsingBarcode() {
    setTimeout(() => {
      let barcodevalue = <string>this.productbarcode;
      if (!barcodevalue) {
        this.productbarcode = "";
        return;
      }
      barcodevalue = barcodevalue.trim();
      this.event.value.push(barcodevalue);
      this._selectedevidencenoIndex = [];
      this._selectedevidencenoIndex = this.event.value;
      this.GetInvoiceProducts(this.event);
      this.productbarcode = "";
    }, 400)
  }

}
