import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Numberingschema } from 'src/app/core/Models/Numberingschema';
import { NumberingschemaService } from 'src/app/core/Services/masters/numberingschema.service';
import { vDocumentTypeSelect, vPrefix, vSuffix } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-c-numbering-schema',
  templateUrl: './c-numbering-schema.component.html',
  styleUrls: ['./c-numbering-schema.component.css']
})
export class CNumberingSchemaComponent implements OnInit {
  _numberingschemaform: FormGroup;
  _Document: Function; //4.document dropdown should load from function table(function id,function name)(eg:salesinvoice,purchase invoice)
  _Sequencelevel: Metadatum; //5.sequence level load from metadata(Branch,All)
  _symbol: Metadatum; //5.symbol fields load from metadata(/,@,#,_,-)
  _Dateformat: Metadatum; //6.date format load from metadata(ddmmyyyy) 
  _Numberingschemastatus: Metadatum;  // 22.status dropdown load from metadata table
  _submitted = false;
  _action: string = "create";
  _numberschemaid: number;
  _numberschema: Numberingschema;
  _series: Metadatum;
  isDisabledSave = false;
  isDisabledClear = false;
  _IsProgressSpinner: boolean = true;
  _Action: IAction;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;

  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _NumberingschemaService: NumberingschemaService, private _router: Router,
    private _CustomExceptionService: CustomExceptionService,private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService
    ) { 
      // //Save Short cut keys
      // this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
      //   this.onSave();
      //   return false; // Prevent bubbling
      // }));
      // //Back To Search Short cut keys
      // this._hotkeysService.add(new Hotkey('backspace', (event: KeyboardEvent): boolean => {
      //   this.goBack(event);
      //   return false; // Prevent bubbling
      // }));
      
      // //Clear
      // this._hotkeysService.add(new Hotkey('alt+x', (event: KeyboardEvent): boolean => {
      //   this.reset(event);
      //   return false; // Prevent bubbling
      // }));
      this.HotKeyIntegration();
    }
  InitializeForm() {
    this._numberingschemaform = this.fb.group({
      numberschemaid: [0],
      companycode: [0],
      branchid: [],
      documentid: [0, vDocumentTypeSelect],
      sequencelevel: [],
      prefix: ['', vPrefix],
      symbol: [],
      dateformat: [],
      suffix: ['', vSuffix],
      numberingschemastatus: [],
      accountingyear: [],
      series: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      sequencename: [],
      wSequencename: [],
    });
    this._numberingschemaform.controls['numberingschemastatus'].setValue("MSC00001");
    this._numberingschemaform.controls['series'].setValue("MSC00093");
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));

      //this._hotkeysService.add(new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
      //  this.router.navigate(['/cBank'], { state: { bankid: this._bankid, action: 'edit' }});
      //  return false; // Prevent bubbling
      //}));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(24);
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._numberschemaid = history.state?.numberschemaid ? history.state?.numberschemaid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;

    if (this._action == 'view') {
      this._numberingschemaform.disable();
      this.isDisabledSave = true;
      this.isDisabledClear = true;
    }
    if (this._action == 'edit') {
      this.isDisabledSave = false;
      this.isDisabledClear = true;
    }
    this._IsProgressSpinner = true;
    this._NumberingschemaService.PageOnLoad(this._numberschemaid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Numberingschemastatus = resultJSON.numberingschemaStatus;
      this._Sequencelevel = resultJSON.sequencelevels;
      this._Dateformat = resultJSON.dateformats;
      this._symbol = resultJSON.symbolfields;
      this._Document = resultJSON.functions;
      this._series = resultJSON.series;
      this._numberingschemaform.controls['numberingschemastatus'].setValue("MSC00001");
      this._numberingschemaform.controls['series'].setValue("MSC00093");
      this._IsProgressSpinner = false;

      // for update
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._numberingschemaform.setValue(updateJSON.numberingschema);
      }
    },  
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSave() {
    this._submitted = true;
    if (this._numberingschemaform.valid) {
      this._numberschema = this._numberingschemaform.value;
      if (this._action == 'create') {
        this._numberschema.createdon = new Date();
        this._numberschema.branchid =sessionStorage["BranchID"] ;
        this._numberschema.createdby = sessionStorage["userid"];
        this._numberschema.accountingyear = new Date().getFullYear();
        //this.onEd();
        this._IsProgressSpinner = true;
        this._NumberingschemaService.create(this._numberschema).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          //15.after saving the record, get the response A, display the success message as "numbering schema A registered Succesfully "
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error =>  { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        this._numberschema.modifiedon = new Date();
        this._numberschema.modifiedby = sessionStorage["userid"];
        this._numberschema.branchid =sessionStorage["BranchID"] ;
        this._IsProgressSpinner = true;
        this._NumberingschemaService.edit(this._numberschema).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          //32.after Updating the record, get the response A, display the success message as "Numbering schema A Modified Succesfully "
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
  reset(event) {
    //this._submitted = true;
    this._numberingschemaform.reset();
    this.InitializeForm();
    this._submitted = false;
    this.isDisabledSave = false;
    this.isDisabledClear = false;
  }
  goBack(event) {
    this._router.navigate(['/vNumberingschema']);
  }
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._numberschema;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
}






