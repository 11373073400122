import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Tax } from '../../Models/Tax';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class TaxService {
  public _Action: IAction;
  public _TaxService = environment.TaxService;
  public _httpOptions;
  public _TaxList: Tax[] = [];
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

   }
   createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(p_taxid: number): Observable<any> {
    const headers = this.header;
    var Query = this._TaxService + '/' + p_taxid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  
  FetchAll() {
    const headers = this.header;
    var Query = this._TaxService + '/FetchAll';
   

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      return data;
    }));
  }

  Cancel(taxid: number,modifiedby:number) {
    const headers = this.header;
    var Query = this._TaxService + '/Cancel/' + taxid + '/' + modifiedby;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }

  create(p_tax: object) {
    const headers = this.header;
    //console.log("Create");
    var Query = this._TaxService;
    return this.http.post<{ data: any }>(Query, p_tax, { headers });
  }
  edit(p_tax: object) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._TaxService;
   
    return this.http.put<{ data: any }>(Query, p_tax,  { headers });
  }
}
