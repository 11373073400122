import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptdamagedproductService {
  public _Action: IAction;
  public _httpOptions;
  public _RptDamagedProductService = environment.RptDamagedProductService;
  constructor(private http: HttpClient) { }

  PageOnload(branchid: number): Observable<any> {
    var Query = this._RptDamagedProductService + '/PageInitialize/' + branchid;
    
    return this.http.get<{ data: any }>(Query);
  }
  Print(damagedproduct:object) {
    var Query = this._RptDamagedProductService ;
    
    return this.http.post(Query,damagedproduct, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }
  getProductCategory(producttype:object) {
    var Query = this._RptDamagedProductService+'/getProductCategories';
    
    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory:object) {
    var Query = this._RptDamagedProductService + '/getProducts';
    
    return this.http.post<{ data: any }>(Query, productcategory);
  }
  getBrand(products:object) {
    var Query = this._RptDamagedProductService + '/getBrand';
    
    return this.http.post<{ data: any }>(Query, products);
  }

}
