import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CDamagedproductpricedetailsComponent } from './damagedproductpricedetails/c-damagedproductpricedetails/c-damagedproductpricedetails.component';
import { VDamagedproductpricedetailsComponent } from './damagedproductpricedetails/v-damagedproductpricedetails/v-damagedproductpricedetails.component';
import { CDirectsalesreturnComponent } from './directsalesreturn/c-directsalesreturn/c-directsalesreturn.component';
import { VDirectsalesreturnComponent } from './directsalesreturn/v-directsalesreturn/v-directsalesreturn.component';
import { CPricecheckingComponent } from './Pricechecking/c-pricechecking/c-pricechecking.component';
import { CPricecomparisonComponent } from './Pricecomparison/c-pricecomparison/c-pricecomparison.component';
import { VPricecomparisonComponent } from './Pricecomparison/v-pricecomparison/v-pricecomparison.component';
import { CProductpricechangeComponent } from './Productpricechange/c-productpricechange/c-productpricechange.component';
import { VProductpricechangeComponent } from './Productpricechange/v-productpricechange/v-productpricechange.component';
import { CQuickSalesInvoiceComponent } from './quick-sales-invoice/c-quick-sales-invoice/c-quick-sales-invoice.component';
import { VQuickSalesInvoiceComponent } from './quick-sales-invoice/v-quick-sales-invoice/v-quick-sales-invoice.component';
import { CSalesOrderComponent } from './sales-order/c-sales-order/c-sales-order.component';
import { VSalesOrderComponent } from './sales-order/v-sales-order/v-sales-order.component';
import { CSalesQuotationComponent } from './sales-quotation/c-sales-quotation/c-sales-quotation.component';
import { VSalesQuotationComponent } from './sales-quotation/v-sales-quotation/v-sales-quotation.component';
import { CSalesinvoiceposComponent } from './salesinvoicepos/c-salesinvoicepos/c-salesinvoicepos.component';
import { VSalesinvoiceposComponent } from './salesinvoicepos/v-salesinvoicepos/v-salesinvoicepos.component';
import { CSalesinvoicetaxComponent } from './salesinvoicetax/c-salesinvoicetax/c-salesinvoicetax.component';
import { VSalesinvoicetaxComponent } from './salesinvoicetax/v-salesinvoicetax/v-salesinvoicetax.component';
import { SalesmultiswipeComponent } from './salesmultiswipe/salesmultiswipe.component';
import { CSalesreturnComponent } from './salesreturn/c-salesreturn/c-salesreturn.component';
import { VSalesreturnComponent } from './salesreturn/v-salesreturn/v-salesreturn.component';

const routes: Routes = [
  { path: 'cSalesQuotation', component: CSalesQuotationComponent },
  { path: 'vSalesQuotation', component: VSalesQuotationComponent },
  { path: 'cSalesOrder', component: CSalesOrderComponent },
  { path: 'vSalesOrder', component: VSalesOrderComponent },
  { path: 'cdamagedproductpricedetails', component: CDamagedproductpricedetailsComponent },
  { path: 'vdamagedproductpricedetails', component: VDamagedproductpricedetailsComponent },
  { path: 'cSalesreturn', component: CSalesreturnComponent },
  { path: 'vSalesreturn', component: VSalesreturnComponent },
  { path: 'cPricechecking', component: CPricecheckingComponent },
  { path: 'cPricecomparison', component: CPricecomparisonComponent },
  { path: 'vPricecomparison', component: VPricecomparisonComponent },
  { path: 'cSalesInvoicetax', component: CSalesinvoicetaxComponent },
  { path: 'vSalesInvoicetax', component: VSalesinvoicetaxComponent },
  { path: 'cSalesinvoicepos', component: CSalesinvoiceposComponent },
  { path: 'vSalesinvoicepos', component: VSalesinvoiceposComponent },
  { path: 'cProductpricechange', component: CProductpricechangeComponent },
  { path: 'vProductpricechange', component: VProductpricechangeComponent },
  { path:'vDirectSalesReturn',component:VDirectsalesreturnComponent},
  { path:'cDirectSalesReturn',component:CDirectsalesreturnComponent},
  { path:'salesMultiSwipe',component:SalesmultiswipeComponent},
  { path: 'CQuickSalesInvoice', component: CQuickSalesInvoiceComponent },
  { path: 'VQuickSalesInvoice', component: VQuickSalesInvoiceComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalesRoutingModule { }
