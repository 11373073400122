<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Default Customer</h3>
                        </div>
                        <div class="col-md-7 text-right">
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_customersview" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['customercode', 'customername','currencyname','mobileno', 'mailid','customerstatusname', 'rewardpoints']" [rowHover]="true"
                            dataKey="employeecode" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="action" style="text-align: center;">Action
                                        <p-sortIcon field="id"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="customercode">Customer Code
                                        <p-sortIcon field="customercode">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="customername">Customer Name
                                        <p-sortIcon field="customername">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="currencyname">Currency
                                        <p-sortIcon field="currencyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="mobileno">Mobile No
                                        <p-sortIcon field="mobileno">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="mailid">Email ID
                                        <p-sortIcon field="mailid"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="rewardpoints">Reward Points Available
                                        <p-sortIcon field="rewardpoints"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="customerstatus">Customer Status
                                        <p-sortIcon field="customerstatus"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="approvalstatus">Approval Status
                                        <p-sortIcon field="approvalstatus"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdbyname">Created By
                                        <p-sortIcon field="createdbyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="createdon">Created On
                                        <p-sortIcon field="createdon">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="modifiedbyname">Modified By
                                        <p-sortIcon field="modifiedbyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="modifiedon">Modified On
                                        <p-sortIcon field="modifiedon">
                                        </p-sortIcon>
                                    </th>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_customerview>
                                <tr [ngClass]="{'qs-row': _customerview.customerstatusname === 'Inactive'}">
                                    <td style="text-align: center;">
                                        <button pButton pRipple icon="las la-eye" title="View" class="p-button p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_customerview)"></button>
                                        <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" (click)="edit(_customerview) " [disabled]="_customerview.approvalstatus=='MSC00046' || !_Action._Update "></button>
                                        <button pButton pRipple icon="pi pi-times " title="Delete " class="p-button p-button-danger " (click)="confirm(_customerview) " [disabled]="_customerview.customerstatus=='MSC00002' || _customerview.approvalstatus=='MSC00046' || !_Action._Delete "></button>
                                    </td>
                                    <td>
                                        {{_customerview.customercode}}
                                    </td>
                                    <td>
                                        {{_customerview.customername}}
                                    </td>
                                    <td>
                                        {{_customerview.currencyname}}
                                    </td>
                                    <td>
                                        {{_customerview.mobileno}}
                                    </td>
                                    <td>
                                        {{_customerview.mailid}}
                                    </td>
                                    <td>
                                        {{_customerview.rewardpoints}}
                                    </td>
                                    <td>
                                        {{_customerview.customerstatusname }}
                                    </td>
                                    <td>
                                        {{_customerview.approvalstatusname}}
                                    </td>
                                    <!-- feno -->
                                    <td>
                                        {{_customerview.createdbyname}}
                                    </td>
                                    <td>
                                        {{_customerview.createdon |date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{_customerview.modifiedbyname}}
                                    </td>
                                    <td>
                                        {{_customerview.modifiedon |date:'dd/MM/yyyy'}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </form>
            <p-confirmDialog [style]="{width: '450px'} "></p-confirmDialog>
        </div>
    </div>
</div>