export class Tenderdetailview {
    tenderid: number;
    tenderdetailid:number;
    productid: number;
    productname: string;
    variantid: number;
    variantdescription: string;
    quantity: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    uomcode: string;
    uomname: string;
    accountingyear: number;
}