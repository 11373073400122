import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptpurchaseinwardService {

  public _Action: IAction;
  public _httpOptions;
  public _RptpurchaseinwardService = environment.RptPurchaseInwardService;

  constructor(private http: HttpClient) { }
  // Page Load :
  PageOnload(branchid: number, userid: number): Observable<any> {
    var Query = this._RptpurchaseinwardService + '/PageInitialize/' + branchid + '/' + userid;
    
    return this.http.get<{ data: any }>(Query);
  }

  Print(purchaseinward: object) {
    var Query = this._RptpurchaseinwardService;
    
    return this.http.post(Query, purchaseinward, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }
  getProductCategory(producttype: object) {
    var Query = this._RptpurchaseinwardService + '/getProductCategories';
    
    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory: object) {
    var Query = this._RptpurchaseinwardService + '/getProducts';
    
    return this.http.post<{ data: any }>(Query, productcategory);
  }
}
