<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Debit Note</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save" [hidden]="_IsSaveHidden" (click)="onSave()"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear" [hidden]="_IsClearHidden" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search" title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_Debitnoteform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input pInputText name="debitnoteno" formControlName="debitnoteno" disabled>
                                    <label for="debitnoteno">Debit Note No</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar name="debitnotedate" [showIcon]="true" formControlName="debitnotedate"
                                        [readonlyInput]="true" [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="debitnotedate">Debitnote Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Debitnoteform.get('debitnotedate').touched) && _Debitnoteform.get('debitnotedate').errors?.SelectCreditnoteDate">
                                    Please Select Debitnote Date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Partytypes" [disabled]="true"
                                        optionLabel="metasubdescription" optionValue="metasubcode" name="partytypecode"
                                        formControlName="partytypecode">
                                    </p-dropdown>
                                    <label for="partytypecode">Party Type <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_accounts" optionLabel="name"
                                    [filter]="true" optionValue="ledgerid" name="accountid" formControlName="accountid"
                                        (onChange)="GetCustomerBasedEvidenceNo($event)">
                                    </p-dropdown>
                                    <label for="accountid">Account Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _Debitnoteform.get('accountid').touched) && _Debitnoteform.get('accountid').errors?.SelectPartyType">
                                    Please Select Account Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <!-- <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_EvidenceTypes"
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        name="evidencetypecode" formControlName="evidencetypecode">
                                    </p-dropdown>
                                    <label for="evidencetypecode">Evidence Type</label>
                                </span> -->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="evidenceno" disabled
                                            formControlName="evidenceno">
                                        <label for="evidenceno">Evidence No <span class="hlt-txt">*</span></label>
                                    </span>
                                    <button class="p-inputgroup-addon p-button" type="button" [disabled]="_action == 'view'" (click)="showBasicDialog()">
                                        <i class="pi pi-external-link"></i>
                                    </button>
                                    <span class="text-danger" *ngIf="(_submitted || _Debitnoteform.get('evidenceno').touched) && _Debitnoteform.get('evidenceno').errors?.SelectPartyType">
                                        Please Select Evidence No
                                    </span>
                                </div>
                                <!-- <span class="p-float-label">
                                    <p-dropdown [options]="_EvidenceNumbers" [autoDisplayFirst]="false" optionLabel="purchaseinvoiceno"
                                       (onChange)="getTransactionDetails($event)" optionValue="purchaseinvoiceid"
                                        name="evidenceno" formControlName="evidenceno">
                                    </p-dropdown>
                                    <label for="evidenceno">Evidence No</label>
                                </span> -->
                            </td>

                            <td>
                                <form [formGroup]="_debitnoteevidenceform">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="grnno" formControlName="grnno" disabled>
                                        <label for="grnno">GRN No</label>
                                    </span>
                                </form>
                            </td>
                            <!-- <td>
                                <form [formGroup]="_debitnoteevidenceform">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="invoicedate" formControlName="invoicedate"
                                            disabled>
                                        <label for="invoicedate">Invoice Date</label>
                                    </span>
                                </form>
                            </td> -->
                            <td>
                                <form [formGroup]="_debitnoteevidenceform">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="invoiceamount"
                                            formControlName="invoiceamount" disabled>
                                        <label for="invoiceamount">Invoice Amount</label>
                                    </span>
                                </form>
                            </td>
                            <td>
                                <form [formGroup]="_debitnoteevidenceform">
                                    <span class="p-float-label">
                                        <input type="text" pInputText name="suppliername" formControlName="suppliername"
                                            disabled>
                                        <label for="suppliername">Supplier Name</label>
                                    </span>
                                </form>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <form [formGroup]="_Debitnoteform">
                                    <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                        optionLabel="currencyname" (onChange)="ChangeCurrencies($event)"
                                        optionValue="currencyid" name="currentcurrencyid"
                                        formControlName="currentcurrencyid" [disabled]="true">
                                    </p-dropdown>
                                    <label for="currentcurrencyid"> Currency <span class="hlt-txt">*</span></label>
                                    </span>
                                    <span class="text-danger" *ngIf="(_submitted || _Debitnoteform.get('currentcurrencyid').touched) && _Debitnoteform.get('currentcurrencyid').errors?.SelectPartyType">
                                        Please Select Currency
                                    </span>
                                </form>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="exchangerate"
                                        formControlName="exchangerate" disabled>
                                    <label for="exchangerate"> Exchange Rate </label>
                                </span>
                            </td>
                            <!-- <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_status" 
                                        optionLabel="metasubdescription" optionValue="metasubcode"
                                        name="debitnotestatusid" formControlName="debitnotestatusid">
                                    </p-dropdown>
                                    <label for="debitnotestatusid">Status</label>
                                </span>
                            </td> -->
                            <td></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Transaction Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_TransactionDetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['purchaseinvoiceno','purchaseinvoicedate','productname','quantity','unitprice','transactionamount','debitamount','reason']"
                        [rowHover]="true" dataKey="productid" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px;text-align:center">Action</th>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>Return Quantity</th>
                                <th>Unit Price</th>
                                <th>Tax Percentage</th>
                                <th>Discount Percentage</th>
                                <th>Transaction Amount</th>
                                <th>Debit Amount</th>
                                <th>Reason</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_TransactionDetail>
                            <tr>
                                <td style="width: 50px" style="text-align:center">
                                    <p-tableCheckbox [value]="_TransactionDetail" [disabled]="_action == 'view'"></p-tableCheckbox>
                                </td>
                                <td>{{_TransactionDetail.productname}}</td>
                                <td>{{_TransactionDetail.quantity}}</td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [(ngModel)]="_TransactionDetail.returnquantity" (keyup)="calcuateDebitAmount(_TransactionDetail)" [disabled]="!_TransactionDetail.enableQuantity">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_TransactionDetail.returnquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_TransactionDetail.unitprice}}
                                </td>
                                <td>
                                    {{_TransactionDetail.taxpercentage}}
                                </td>
                                <td>
                                    {{_TransactionDetail.discountpercentage}}
                                </td>
                                <td>
                                    {{_TransactionDetail.transactionamount}}
                                </td>
                                <td>
                                    {{_TransactionDetail.debitamount}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText [(ngModel)]="_TransactionDetail.reason" [disabled]="!_TransactionDetail.enableReason">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_TransactionDetail.reason}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-dialog header="Evidence No" [(visible)]="displayBasic" [modal]="true" [style]="{width: '50vw',height: '500px'}" [baseZIndex]="10000">
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="card">
                                <p-toolbar styleClass="p-mb-4">
                                    <ng-template pTemplate="left">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text"
                                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                placeholder="Search..." />
                                        </span>
                                    </ng-template>
                                </p-toolbar>
                                <p-table [value]="_Evidences" selectionMode="single" [(selection)]="selectedEvidenceNo" (onRowSelect)="onRowSelectPopup($event)" (onRowUnselect)="onRowUnSelectPopup($event)" [paginator]="true" [rows]="10">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn="purchaseinvoiceno">Invoice Number
                                                <p-sortIcon field="purchaseinvoiceno">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="purchaseinvoicedate">Invoice Date
                                                <p-sortIcon field="purchaseinvoicedate">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="grnno">GRN No
                                                <p-sortIcon field="grnno">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="suppliername">Supplier Name
                                                <p-sortIcon field="suppliername">
                                                </p-sortIcon>
                                            </th>

                                            <th pSortableColumn="invoiceamount">Invoice Amount
                                                <p-sortIcon field="invoiceamount">
                                                </p-sortIcon>
                                            </th>
                                            <th *ngFor="false" pSortableColumn="currencyid">Currency
                                                <p-sortIcon field="currencyid">
                                                </p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-_Evidence>
                                        <tr [pSelectableRow]="rowData">
                                            <td>{{_Evidence.purchaseinvoiceno}}</td>
                                            <td>{{_Evidence.purchaseinvoicedate | date:'dd-MM-yyyy'}}</td>
                                            <td>{{_Evidence.grnno}}</td>
                                            <td>{{_Evidence.suppliername}}</td>
                                            <td>{{_Evidence.invoiceamount}}</td>
                                            <td *ngFor="false">{{_Evidence.currencyid}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>