import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { UserdiscountreportService } from 'src/app/core/Services/reports/Sales/UserDiscountReport/userdiscountreport.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-userdiscountreport',
  templateUrl: './userdiscountreport.component.html',
  styleUrls: ['./userdiscountreport.component.css']
})
export class UserdiscountreportComponent implements OnInit {
  _BranchWiseUserDiscounts: any[];
  selectedBranchwiseDiscount: any;
  _BranchWiseDiscounts: any[];
  selectedBranchWiseUserDiscountRows: any;
  displayBasic: boolean;
  _IsProgressSpinner: boolean = false;
  IsShowBranchWiseUserDiscount: boolean = false;
  branchwisecols: any[];
  branchuserwisediscountcols: any[];
  WiseUserDiscountscols: any[];
  CurrencySymbol: string;
  _UserDiscountreportform: FormGroup;
  datepipe = new DatePipe('en-US');
  _UserDiscountPageonloadObj: any;
  _SearchBranchObj: any;
  Overalluserdiscount: number;
  _Action: IAction;
  mindate: Date;
  maxdate: Date;

  constructor(private fb: FormBuilder, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _UserDiscountReport: UserdiscountreportService, private _CustomExceptionService: CustomExceptionService, private exportUtility: ExportUtility) { }
  InitializeUserDiscountreportForm() {
    this._UserDiscountreportform = this.fb.group({
      fromdate: [],
      todate: [],

    });
    if (sessionStorage['Environmentenddate'] == "null") {
      this._UserDiscountreportform.get("fromdate").setValue(new Date);
      this._UserDiscountreportform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._UserDiscountreportform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._UserDiscountreportform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(169);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.CurrencySymbol = "$";
    this.InitializeUserDiscountreportForm();
    this.branchwisecols = [
      { field: 'branchname', header: 'Branch Name' },
      { field: 'discountamount', header: 'Discount Amount' },
    ]
    this.branchuserwisediscountcols = [
      { field: 'branchname', header: 'Branch Name' },
      { field: 'createdbyname', header: 'User' },
      { field: 'salesinvoiceno', header: 'Invoice NO' },
      { field: 'salesinvoiceamount', header: 'Invoice Amount' },
      { field: 'discountpercentage', header: 'Discount %' },
      { field: 'discountamount', header: 'Discount Amount' },
      { field: 'finalamount', header: 'Final Amount' },
    ]
    this.PageOnLoad();
  }
  PageOnLoad() {
    let Fromdate = this._UserDiscountreportform.get("fromdate").value;
    let Todate = this._UserDiscountreportform.get("todate").value;
    let convertfromdate = this.datepipe.transform(Fromdate, 'yyyy-MM-dd');
    let converttodate = this.datepipe.transform(Todate, 'yyyy-MM-dd');
    let userid = sessionStorage["userid"];
    if (convertfromdate  == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (converttodate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    if (convertfromdate > converttodate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._UserDiscountPageonloadObj = { "fromdate": convertfromdate, "todate": converttodate, "userid": userid }
    this._UserDiscountReport.PageOnload(this._UserDiscountPageonloadObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._BranchWiseDiscounts = resultJSON.branchwisediscountlst

      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onRowSelectBranch(event: any) {
    let branchrowdata = event.data;
    let Fromdate = this._UserDiscountreportform.get("fromdate").value;
    let Todate = this._UserDiscountreportform.get("todate").value;
    let convertfromdate = this.datepipe.transform(Fromdate, 'yyyy-MM-dd');
    let converttodate = this.datepipe.transform(Todate, 'yyyy-MM-dd');
    let userid = sessionStorage["userid"];
    let selectedbranchid = branchrowdata.branchid;
    if (convertfromdate > converttodate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._SearchBranchObj = { "fromdate": convertfromdate, "todate": converttodate, "userid": userid, "branchid": selectedbranchid }
    this._UserDiscountReport.SearchBranchDetails(this._SearchBranchObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._BranchWiseUserDiscounts = resultJSON.branchwisediscountdetails;
      this.Overalluserdiscount = 0;
      for (var i = 0; i < this._BranchWiseUserDiscounts.length; i++) {
        this.Overalluserdiscount += this._BranchWiseUserDiscounts[i].discountamount
      }
      this.Overalluserdiscount = parseFloat(this.Overalluserdiscount.toFixed(2));
      this.IsShowBranchWiseUserDiscount = true;
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  exportExcel() {
    let BranchWiseList = this.GetBranchWiseList();
    this.exportUtility.exportExcel(BranchWiseList, 'BranchWise', 'xlsx');
  }

  ExportToPdf() {
    let BranchWiseList = this.GetBranchWiseList();
    this.exportUtility.ExportToPdf(BranchWiseList, 'BranchWise.pdf');
  }

  GetBranchWiseList() {
    if (!this._BranchWiseDiscounts || !this._BranchWiseDiscounts.length)
      return;
    let BranchWiseList = [];
    this._BranchWiseDiscounts.forEach(element => {
      let branchwise: any = {};
      branchwise["Branch Name"] = element.branchname;
      branchwise["Discount Amount"] = element.discountamount;
      BranchWiseList.push(branchwise);
    });
    return BranchWiseList;
  }
  exportExcelBranchWiseUserDiscount() {
    let BranchWiseList = this.GetBranchWiseuserdiscountList();
    this.exportUtility.exportExcel(BranchWiseList, 'BranchWiseuserdiscount', 'xlsx');
  }
  ExportToPdfBranchWiseUserDiscount() {
    let BranchWiseList = this.GetBranchWiseuserdiscountList();
    this.exportUtility.ExportToPdf(BranchWiseList, 'BranchWiseuserdiscount.pdf');
  }

  GetBranchWiseuserdiscountList() {
    if (!this._BranchWiseUserDiscounts || !this._BranchWiseUserDiscounts.length)
      return;
    let BranchWiseuserdiscountList = [];
    this._BranchWiseUserDiscounts.forEach(element => {
      let branchwiseuserdiscount: any = {};
      branchwiseuserdiscount["Branch Name"] = element.branchname;
      branchwiseuserdiscount["User"] = element.createdbyname;
      branchwiseuserdiscount["Invoice NO"] = element.salesinvoiceno;
      branchwiseuserdiscount["Invoice Amount"] = element.salesinvoiceamount;
      branchwiseuserdiscount["Discount %"] = element.discountpercentage;
      branchwiseuserdiscount["Discount Amount"] = element.discountamount;
      branchwiseuserdiscount["Final Amount"] = element.finalamount;
      BranchWiseuserdiscountList.push(branchwiseuserdiscount);
    });
    return BranchWiseuserdiscountList;
  }
}
