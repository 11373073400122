
export class Userbranchmap {
    companyid: number;
    userid: number;
    branchid: number;
    userbranchstatus: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date ;
    accountingyear: number;
    isdefault: boolean ;

}
