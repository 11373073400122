import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppConfig } from 'src/app/core/domain/appconfig';
import { OutstandingReceivableDashboardServiceService } from 'src/app/core/Services/dashboard/outstanding-receivable-dashboard-service.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
@Component({
	selector: 'app-outstanding-receivable-dashboard',
	templateUrl: './outstanding-receivable-dashboard.component.html',
	styleUrls: ['./outstanding-receivable-dashboard.component.css']
})
export class OutstandingReceivableDashboardComponent implements OnInit {
	datePipe = new DatePipe('en-US');
	_IsProgressSpinner: boolean = false;
	_Branches: any[] = [];
	_outstandingreceivabledashboardform: FormGroup;
	documentStyle: any;
	basicOptions: any;
	_Isbarsd: boolean = true;
	_Islinesd: boolean = true;
	_Ispiesd: boolean = true;
	_Isdoughnutsd: boolean = true;
	_Isbarsd1: boolean = true;
	_Islinesd1: boolean = true;
	_Ispiesd1: boolean = true;
	_Isdoughnutsd1: boolean = true;
	_Isbarpd: boolean = true;
	_Islinepd: boolean = true;
	_Ispiepd: boolean = true;
	_Isdoughnutpd: boolean = true;
	_IsLineBranch: boolean = false;
	_IsbarBranch: boolean = true;
	_IspieBranch: boolean = false;
	_IsdoughnutBranch: boolean = false;
	config: AppConfig;
	trimlableDataProduct: any[] = [];
	salesByCategoryLabel: any = [];
	salesReturnRep: any = [];
	salesReturn: any = [];
	salesReturnValues: any = [];
	topSellingProductBranchWise: any = [];
	topSellingProductBranchWiseLine: any = [];
	tempTopSellingProductBranchWise: any = [];
	top5SellingProductBranchWise: any;
	topSellingProductBranchWiseValues: any = []
	salesByCategory: any;
	salesByCategoryValue: any;
	noOutstandingReceivableBranchWise: boolean = false;
	_selectedTopBranch: any[] = [];
	salesReturnHead: any;
	data: any;
	topSelling: any;
	topReturn: any;
	_isDoubleBar: boolean = true;
	noDoubleBarChart: boolean = true;
	noSalesByCategory: boolean = true;
	topSellingProduct: any;
	topSellingProductLabel: any;
	topSellingProductValue: any;
	topReturnProduct: any;
	topReturnProductLabel: any;
	topReturnProductValue: any;
	topSellingBranchWiseLabel: any;
	topSellingBranchWiseValue: any;
	noTopSelling: boolean = false;
	noTopReturn: boolean = false;
	topSellingBwDataSet: any[] = [];
	topSellingLineDataSet: any[] = [];
	topSellingBw: any[] = [];
	topSellingLine: any[] = [];
	bIds: any[] = [];
	noProductTypeSV: boolean = false;
	totalProductTypeStocks: any = [];
	totalProductTypeStocksValues: any = []
	outstandingreceiveBranchWise: any;
	constructor(
		private utility: CommonUtilities,
		private fb: FormBuilder,
		private outstandingReceivableDashboardServiceService: OutstandingReceivableDashboardServiceService,
	) { }
	ngOnInit() {
		this.utility.pageLoadScript();
		this.documentStyle = getComputedStyle(document.documentElement);
		this._outstandingreceivabledashboardform = this.fb.group({
			branchid: [parseInt(sessionStorage['currentbranchid'])],
		});
		this.salesReturnHead = {
			SALESCOUNT: 0,
			SALESINVOICEVALUE: 0,
			SALESRETURNCOUNT: 0,
			SALESRETURNVALUE: 0,
		};
		this.topSellingBw = [
			'#FF5733',
			'#FFC300',
			'#DA70D6',
			'#00CED1',
			'#8B0000',
			'#8B008B',
			'#32CD32',
			'#FF1493',
			'#9400D3',
			'#1E90FF',
			'#FF4500',
			'#FF00FF',
			'#FF7F50',
			'#FFFF00',
			'#9932CC',
			'#00FF7F',
			'#FF69B4',
			'#FF6347',
			'#00FFFF',
			'#FF8C00',
			'#FF00FF',
			'#8A2BE2',
			'#00FF00',
			'#0000FF',
		];
		this.topSellingLine = ['rgba(255, 87, 51, 0.2)', 'rgba(255, 195, 0, 0.2)', 'rgba(218, 112, 214, 0.2)', 'rgba(0, 206, 209, 0.2)', 'rgba(139, 0, 0, 0.2)', 'rgba(139, 0, 139, 0.2)', 'rgba(50, 205, 50, 0.2)', 'rgba(255, 20, 147, 0.2)', 'rgba(148, 0, 211, 0.2)', 'rgba(30, 144, 255, 0.2)', 'rgba(255, 69, 0, 0.2)', 'rgba(255, 0, 255, 0.2)', 'rgba(255, 127, 80, 0.2)', 'rgba(255, 255, 0, 0.2)', 'rgba(153, 50, 204, 0.2)', 'rgba(0, 255, 127, 0.2)', 'rgba(255, 105, 180, 0.2)', 'rgba(255, 99, 71, 0.2)', 'rgba(0, 255, 255, 0.2)', 'rgba(255, 140, 0, 0.2)', 'rgba(255, 0, 255, 0.2)', 'rgba(138, 43, 226, 0.2)', 'rgba(0, 255, 0, 0.2)', 'rgba(0, 0, 255, 0.2)']
		this._Isbarsd = false;
		this._Islinesd = false;
		this._Ispiesd = true;
		this._Isdoughnutsd = false;
		this._Isbarsd1 = false;
		this._Islinesd1 = false;
		this._Ispiesd1 = true;
		this._Isdoughnutsd1 = false;
		this._Isbarpd = false;
		this._Islinepd = false;
		this._Ispiepd = true;
		this._Isdoughnutpd = false;
		this._IsLineBranch = false;
		this._IsbarBranch = true;
		this._IspieBranch = false;
		this._IsdoughnutBranch = false;
		this.outstandingReceivableDashboardServiceService.PageOnload().subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this._IsProgressSpinner = false;
			this._Branches = resultJSON.branches;
			this.bIds = [26, 29, 1, 28, 35];
			this._selectedTopBranch = this.bIds;
			console.log('branch', this._Branches)
		});
		this.initializeCharts();
	}
	initializeCharts() {
		this.OutstandingreceiveBranchWise();
	}
	chartChangedBranch(charttype1) {
		if (charttype1 == 'bar') {
			this._IsLineBranch = false;
			this._IsbarBranch = true;
			this._IspieBranch = false;
			this._IsdoughnutBranch = false;
		}
		if (charttype1 == 'line') {
			this._IsLineBranch = true;
			this._IsbarBranch = false;
			this._IspieBranch = false;
			this._IsdoughnutBranch = false;
		}
		if (charttype1 == 'pie') {
			this._IsLineBranch = false;
			this._IsbarBranch = false;
			this._IspieBranch = true;
			this._IsdoughnutBranch = false;
		}
		if (charttype1 == 'doughnut') {
			this._IsLineBranch = false;
			this._IsbarBranch = false;
			this._IspieBranch = false;
			this._IsdoughnutBranch = true;
		}
	}
	OutstandingreceiveBranchWise() {
		this._IsProgressSpinner = true;
		this.fetchOutstandingreceiveBranchWise();
	}
	filterBranch(event) {
		this._IsProgressSpinner = true;
		if (this._selectedTopBranch.length > 5) {
			this._IsProgressSpinner = false;
			return;
		} else if (this._selectedTopBranch.length <= 5 && this._selectedTopBranch.length > 0) {
			this.fetchOutstandingreceiveBranchWise();
			this._IsProgressSpinner = false;
		}
		if (this._selectedTopBranch.length == 0) {
			this.topSellingProductBranchWise = [];
			this.topSellingProductBranchWiseLine = [];
			this._IsProgressSpinner = false;
		}
	}
	fetchOutstandingreceiveBranchWise() {
		this._IsProgressSpinner = true;
		this.outstandingReceivableDashboardServiceService.OutstandingreceiveBranchWise(this._selectedTopBranch).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this.trimlableDataProduct = [];
			this.outstandingreceiveBranchWise = [];
			this.topSellingProductBranchWiseValues = [];
			if (resultJSON.outstandingreceiveBranchWise != null &&
				resultJSON.outstandingreceiveBranchWise) {
				this.noOutstandingReceivableBranchWise = false
				if (resultJSON.outstandingreceiveBranchWise.length > 0) {
					var stockFreshLabel = resultJSON.outstandingreceiveBranchWise;
					for (let i = 0; i < stockFreshLabel.length; i++) {
						this.trimlableDataProduct[i] = (stockFreshLabel[i].BRANCHNAME).slice(0, 15) + '...';
						this.topSellingProductBranchWiseValues[i] = stockFreshLabel[i].AMOUNT;
						this.outstandingreceiveBranchWise = {
							labels: this.trimlableDataProduct,
							datasets: [
								{
									label: 'Branch wise Outstanding',
									backgroundColor: [
										'#42A5F5',
										"#FF6384",
										"#3655eb",
										"#e757eb",
										"#b64c11",
										"#FFCE56"
									],
									data: this.topSellingProductBranchWiseValues
								},
							],
						};
					}
				} else { this.noOutstandingReceivableBranchWise = true }
			} else { this.noOutstandingReceivableBranchWise = true }
			this._IsProgressSpinner = false;
		});
	}
}