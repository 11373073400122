import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { RptLockedQuantityDetailReportService } from 'src/app/core/Services/reports/Stock/RptLockedQuantityDetailReport/rpt-locked-quantity-detail-report.service';
import { RptlockedquantitysummaryreportService } from 'src/app/core/Services/reports/Stock/RptLockedQuantitySummaryReport/rptlockedquantitysummaryreport.service';
import { Customerview } from 'src/app/core/Views/Customerview';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-rptlockedquantitysummaryreport',
  templateUrl: './rptlockedquantitysummaryreport.component.html',
  styleUrls: ['./rptlockedquantitysummaryreport.component.css']
})
export class RptlockedquantitysummaryreportComponent implements OnInit {

  _IsProgressSpinner: boolean = true;
  _rptLockedSummaryform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _userid: number;
   datepipe: any;
  _src: SafeResourceUrl;
  filteredProducts: any[];
  _SalesReportPrintObj:any;
  branchid: number[] = [];
  _ProductTypeObj:any;
  _ProductCategoryObj:any;
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];

  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _RptLockedQunatitySummaryService: RptlockedquantitysummaryreportService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._rptLockedSummaryform = this.fb.group({
      branchid: [0],
      productid: [0],
      productcategoryid: [0],
      producttypeid: [0]
    
    })
    this._branchlist = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    // this.InitializeForm();
    this._IsProgressSpinner = true;
    this._RptLockedQunatitySummaryService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branches;
      this._producttypelist = resultJSON.producttypeList;
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    //25.If clear clicking all fields should clear in grid
    this._rptLockedSummaryform.reset();
    this.branchid=[];
    this.productcatid=[];
    this.productid=[];
    this.producttypeid=[];
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = {"Producttype":selectedProductType || [],"branchid": parseInt(sessionStorage["currentbranchid"])};
      this._RptLockedQunatitySummaryService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("category", resultJSON);
        this._productcategorylist = resultJSON.productcategories;
        //this._productcategory = [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = {"Productcategory":selectedProductCategory || [],"branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptLockedQunatitySummaryService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  GenerateReport(event) {
     
     var mediaType = 'application/pdf';
     this._IsProgressSpinner = true;
     this._SalesReportPrintObj={"Branches":this.branchid||[] ,"branchid":sessionStorage["BranchID"],"Userid":parseInt(sessionStorage["userid"]),"Productheader":this.productid||[]}
     this._RptLockedQunatitySummaryService.Print(this._SalesReportPrintObj).subscribe((result) => {
       var blob = new Blob([result], { type: mediaType });
       var url = window.URL.createObjectURL(blob);
       this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
       this._IsProgressSpinner = false;
 
     },
       error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
   }
}
