import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptpurchasereturnService {
  public _Action: IAction;
  public _httpOptions;
  public _RptPurchaseReturnService = environment.RptPurchaseReturnService;
  constructor(private http: HttpClient) { }


  PageOnload(branchid: number,userid:number): Observable<any> {
    var Query = this._RptPurchaseReturnService + '/PageInitialize/' + branchid+'/'+ +userid;
    
    return this.http.get<{ data: any }>(Query);
  
  }

  getProductCategory(producttype:object) {
    var Query = this._RptPurchaseReturnService+'/getProductCategories';
    
    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory:object) {
    var Query = this._RptPurchaseReturnService + '/getProducts';
    
    return this.http.post<{ data: any }>(Query, productcategory);
  }
  
  Print(purchasereturn:object) {
    var Query = this._RptPurchaseReturnService ;
    
    return this.http.post(Query,purchasereturn, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }

}
