<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_termsandconditionform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Terms And Condition</h3>
                        </div>
                        <div class="col-md-7 text-right">

                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" icon="pi pi-save" [disabled]="isDisabledSave"></button>
                                <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                    icon="pi pi-trash" class="" class="p-button-danger"  [disabled]="isDisabledClear"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                    title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                                <!-- <button pButton pRipple label="" icon="pi pi-times" class="" (click)="hideDialog()" ></button>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_partytypes" name="partytype" [autoDisplayFirst]="false"
                                        optionLabel="metasubdescription" formControlName="partytype"
                                        optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="partytype">Party Type <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _termsandconditionform.get('partytype').touched) && _termsandconditionform.get('partytype').errors?.SelectPartType">
                                    Please select party type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_businesstypes" optionLabel="metasubdescription"
                                        [autoDisplayFirst]="false" formControlName="businesstype"
                                        optionValue="metasubcode" name="businesstype">
                                    </p-dropdown>
                                    <label for="businesstype"> Buisness Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _termsandconditionform.get('businesstype').touched) && _termsandconditionform.get('businesstype').errors?.SelectBuisnessType">
                                    Please select buisness type
                                </span>
                            </td>
                            <td style="width: 20%;" rowspan="2">
                                <span class="p-float-label">
                                    <textarea rows="3" cols="30" name="termsdescription" maxlength="200"
                                        formControlName="termsdescription" pInputTextarea></textarea>
                                    <label for="termsdescription">Terms and Condition <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _termsandconditionform.get('termsdescription').touched) && _termsandconditionform.get('termsdescription').errors?.DetailsRequired">
                                    Please enter terms and condition
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_termstypes" optionLabel="metasubdescription"
                                        [autoDisplayFirst]="false" formControlName="termstype" optionValue="metasubcode"
                                        name="termstype">
                                    </p-dropdown>
                                    <label for="termstype"> Terms For <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _termsandconditionform.get('termstype').touched) && _termsandconditionform.get('termstype').errors?.SelectTermsType">
                                    Please select term for
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <!-- 30.status column need to show in the update screen -->
                                    <p-dropdown [options]="_termsandconditionstatus" optionLabel="metasubdescription"
                                        formControlName="termsandconditionstatus" optionValue="metasubcode"
                                        name="termsandconditionstatus">
                                    </p-dropdown>
                                    <label for="termsandconditionstatus"> Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _termsandconditionform.get('termsandconditionstatus').touched) && _termsandconditionform.get('termsandconditionstatus').errors?.SelectStatus">
                                    Please Select Status
                                </span>
                            </td>
                            <td></td>
                            <td>

                                <!-- Debug -->

                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>

                            </td>
                            <td></td>
                        </tr>

                    </table>
                </div>
            </form>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>