import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApprovalRequest } from '../../Models/Approvalrequest';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  public _Action: IAction;
  public _WorkflowService = environment.WorkflowService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  ApprovalWorkFlowSummary(p_approvalworkflow: object) { 
    const headers = this.header;
    var Query = this._WorkflowService + '/ApprovalWorkFlowSummary';

    return this.http.post<{ data: any }>(Query, p_approvalworkflow, { headers });
    //return this.http.get<{data:any}>(Query);
  }
  // p_approvalworkflow:object,p_approvalworkflow
  ApprovalWorkFlowUpdate(p_approvalworkflow: ApprovalRequest) { 
    const headers = this.header;
    var Query = this._WorkflowService;
    console.log("query", Query);
    return this.http.post<{ data: any }>(Query, p_approvalworkflow, { headers });
  }
  ApprovalWorkFlowStage(workflowid: number, documentno: string, userid: number, logid: number) { 
    const headers = this.header;
    documentno = documentno.replace("/", "-");
    var Query = this._WorkflowService + '/ApprovalWorkFlowStage' + '/' + workflowid + '/' + documentno + '/' + userid + '/' + logid;
    console.log("bh", Query);
    return this.http.get<{ data: any }>(Query, { headers });
  }
  Search(branchwise: object): Observable<any> { 
    const headers = this.header;
    var Query = this._WorkflowService + '/Search';

    return this.http.post<{ data: any }>(Query, branchwise, { headers });
  }
}

