<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Service Invoice</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <!-- <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add"
                                [disabled]="!_Action._Add" (click)="GoToCreate($event)"
                                class="p-button p-button-success p-mr-2"></button>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true" [minDate]="mindate" [maxDate]="maxdate"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate"
                                    [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search"
                                icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>


                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_serviceinvoiceviews" [rows]="10" [paginator]="true" [columns]="cols"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['serviceproductdeliveryno','serviceproductdeliverydate', 'customername','servicerequestno','netamount','statusname','createdbyname','CREATEDON']"
                        [rowHover]="true" dataKey="serviceproductdeliveryno"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width:120px;">Action
                                </th>
                                <th pSortableColumn="serviceproductdeliveryno">Service Invoice Code<p-sortIcon
                                        field="serviceproductdeliveryno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="serviceproductdeliverydate">Service Invoice Date
                                    <p-sortIcon field="serviceproductdeliverydate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="customername">Customer Name <p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="servicerequestno">Service Request No<p-sortIcon
                                        field="servicerequestno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="servicerequestno">Service Work Order Code<p-sortIcon
                                        field="servicerequestno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="netamount">Net Amount<p-sortIcon field="netamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="statusname">Status<p-sortIcon field="statusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By<p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On<p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_serviceinvoiceview>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2"
                                        (click)="view(_serviceinvoiceview)" [disabled]="!_Action._View"></button>
                                    <button pButton pRipple icon="las la-print" title="Print"
                                        class="p-button-warning p-mr-2" (click)="Print(_serviceinvoiceview)"></button>
                                </td>
                                <td>
                                    {{_serviceinvoiceview.serviceinvoiceno}}
                                </td>
                                <td>
                                    {{_serviceinvoiceview.serviceinvoicedate|date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_serviceinvoiceview.customername}}
                                </td>
                                <td>
                                    {{_serviceinvoiceview.servicerequestno}}
                                </td>
                                <td>
                                    {{_serviceinvoiceview.serviceworkorderno}}
                                </td>
                                <td>
                                    {{_serviceinvoiceview.netamount}}
                                </td>
                                <td>
                                    {{_serviceinvoiceview.statusname}}
                                </td>
                                <td>
                                    {{_serviceinvoiceview.createdbyname}}
                                </td>
                                <td>
                                    {{_serviceinvoiceview.createdon|date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>