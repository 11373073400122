export class Stockinwardgetinvoicedetail {
    purchaseinwarinvoicedetailid: number;
    inwardid: number;
    invoiceid: number;
    invoiceno: string;
    invoicedate: Date;
    invoiceamount: number;
    currencyid: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}