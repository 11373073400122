export class Grnothercharge {
    grnnotherchargesdetailid: number;
    grnid: number;
    otherchargesid: number;
    otherchargeid: number;
    otherchargesamount: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}