<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>User</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add"
                                [disabled]="!_Action._Add" [routerLink]="['/cUser']"
                                class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <form #myForm="ngForm">
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <div class="p-formgroup-inline">
                                    <div  class="p-field-checkbox">
                                        <p-checkbox name="isblock" binary="true"
                                            (onChange)="isblocked($event)">
                                        </p-checkbox>
                                        <label for="isblock">Blocked User</label>
                                    </div>
                                </div>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                    <div class="card">
                        <p-toolbar styleClass="p-mb-4">
                            <ng-template pTemplate="left">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                    (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                    tooltipPosition="bottom"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                    class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                    (click)="exportExcel()"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                    class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                    (click)="ExportToPdf()"></button>
                            </ng-template>
                        </p-toolbar>
                        <p-table #dt [value]="_userviews" [rows]="10" [paginator]="true"
                            [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                            [globalFilterFields]="['userid', 'username','rolename','branchname','userdiscountpercentage','userstatusname']"
                            [rowHover]="true" dataKey="userid" [(selection)]="selectedrows"
                            (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [showCurrentPageReport]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="action" class="text-center" style="width: 120px;">Action
                                        <p-sortIcon field="id">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="username">User Name <p-sortIcon field="username"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="employeename">Employee Name<p-sortIcon field="employeename">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="userdiscountpercentage">User Discount <p-sortIcon
                                            field="userdiscountpercentage">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="isdefaultrolename">Default Role <p-sortIcon
                                            field="isdefaultrolename">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="isdefaultbranchname">Default Branch <p-sortIcon
                                            field="isdefaultbranchname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="userstatusname">Status <p-sortIcon field="userstatusname">
                                        </p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_userview>
                                <tr>
                                    <td class="text-center">
                                        <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2"
                                            [disabled]="!_Action._View" (click)="view(_userview)"></button>
                                        <button pButton pRipple icon="las la-pen" title="Edit"
                                            class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update"
                                            (click)="edit(_userview)" [hidden]="_userview.userid == 1"></button>
                                        <button pButton pRipple icon="pi pi-times" title="Delete"
                                            class="p-button p-button-danger" [disabled]="!_Action._Delete"
                                            (click)="confirm(_userview)"
                                            [disabled]="_userview.userstatuscode == 'MSC00002'"></button>
                                    </td>
                                    <td>
                                        {{_userview.username}}
                                    </td>
                                    <td>
                                        {{_userview.employeename }}
                                    </td>
                                    <td>
                                        {{_userview.userdiscountpercentage }}
                                    </td>
                                    <td>
                                        {{_userview.isdefaultrolename}}
                                    </td>
                                    <td>
                                        {{_userview.isdefaultbranchname }}
                                    </td>
                                    <td>
                                        {{_userview.userstatusname }}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                </div>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>