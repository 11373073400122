
export class Schemefreeproductdetailview {
    schemefreeproductdetailid: number;
    schemeid: number;
    productid: number;
    productname: string;
    variantid: number;
    variantdescription: string;
    quantity: number;
    uom: string;
    uomname: string;
    price: number;
    recordstatus: string;
    createdby: number;
    modifiedon: Date;
    modifiedby: number;
    createdon: Date;
}