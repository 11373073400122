import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptLockedQuantityDetailReportService {

  public _Action: IAction;
  public _httpOptions;
  public _RptLockedQuantityDetailService = environment.RptLockedQuantityDetailService;
  constructor(private http: HttpClient) { }

  PageOnload(): Observable<any> {
    var Query = this._RptLockedQuantityDetailService + '/PageInitialize';
    
    return this.http.get<{ data: any }>(Query);

  }
  getProductCategory(producttype:object) {
    var Query = this._RptLockedQuantityDetailService+'/getProductCategories';
    
    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory:object) {
    var Query = this._RptLockedQuantityDetailService + '/getProducts';
    
    return this.http.post<{ data: any }>(Query, productcategory);
  }
  getBrand(products:object) {
    var Query = this._RptLockedQuantityDetailService + '/getBrand';
    
    return this.http.post<{ data: any }>(Query, products);
  }
  
  Print(salesreport:object) {
    var Query = this._RptLockedQuantityDetailService ;
    
    return this.http.post(Query,salesreport, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
}
}
