import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CAccountComponent } from './account/c-account/c-account.component';
import { VAccountComponent } from './account/v-account/v-account.component';
import { CAssetRegirtrationComponent } from './asset-registration/c-asset-registration/c-asset-regirtration.component';
import { VAssetRegirtrationComponent } from './asset-registration/v-asset-registration/v-asset-regirtration.component';
import { CAccountGroupComponent } from './account-group/c-account-group/c-account-group.component';
import { VAccountGroupComponent } from './account-group/v-account-group/v-account-group.component';
import { CDebitnoteComponent } from './debit-note/c-debitnote/c-debitnote.component';
import { VDebitnoteComponent } from './debit-note/v-debitnote/v-debitnote.component';
import { CPettycashSettlementComponent } from './petty-cash-settlement/c-pettycash-settlement/c-pettycash-settlement.component';
import { VPettycashSettlementComponent } from './petty-cash-settlement/v-pettycash-settlement/v-pettycash-settlement.component';
import { CPettycashComponent } from './petty-cash/c-pettycash/c-pettycash.component';
import { VPettycashComponent } from './petty-cash/v-pettycash/v-pettycash.component';
import { CReceiptComponent } from './receipt/c-receipt/c-receipt.component';
import { CAccountClosingComponent } from './account-closing/c-account-closing/c-account-closing.component';
import { VAccountClosingComponent } from './account-closing/v-account-closing/v-account-closing.component';
import { CBankReconciliationComponent } from './bank-reconciliation/c-bank-reconciliation/c-bank-reconciliation.component';
import { VBankReconciliationComponent } from './bank-reconciliation/v-bank-reconciliation/v-bank-reconciliation.component';
import { CContraComponent } from './contra/c-contra/c-contra.component';
import { VContraComponent } from './contra/v-contra/v-contra.component';
import { CPaymentComponent } from './payment/c-payment/c-payment.component';
import { VPaymentComponent } from './payment/v-payment/v-payment.component';
import { VReceiptComponent } from './receipt/v-receipt/v-receipt.component';
import { CTellerCashDaySettlementComponent } from './teller-cash-day-settlement/c-teller-cash-day-settlement/c-teller-cash-day-settlement.component';
import { VTellerCashDaySettlementComponent } from './teller-cash-day-settlement/v-teller-cash-day-settlement/v-teller-cash-day-settlement.component';
import { CJournalComponent } from './journal/c-journal/c-journal.component';
import { VJournalComponent } from './journal/v-journal/v-journal.component';
import { CLaybuyComponent } from './laybuy/c-laybuy/c-laybuy.component';
import { VLaybuyComponent } from './laybuy/v-laybuy/v-laybuy.component';
import { CTaxReconcilationComponent } from './tax-reconcilation/c-tax-reconcilation/c-tax-reconcilation.component';
import { VTaxReconcilationComponent } from './tax-reconcilation/v-tax-reconcilation/v-tax-reconcilation.component';
import { VCreditnoteComponent } from './credit-note/v-creditnote/v-creditnote.component';
import { CCreditnoteComponent } from './credit-note/c-creditnote/c-creditnote.component';
import { CPettycashExpenceComponent } from './pettycash-expence/c-pettycash-expence/c-pettycash-expence.component';
import { VPettycashExpenceComponent } from './pettycash-expence/v-pettycash-expence/v-pettycash-expence.component';
import { CDirectCreditNoteComponent } from './direct-credit-note/c-direct-credit-note/c-direct-credit-note.component';
import { VDirectCreditNoteComponent } from './direct-credit-note/v-direct-credit-note/v-direct-credit-note.component';
import { AdvanceAdjustmentComponent } from './advance-adjustment/advance-adjustment.component';
import { ClosingBalanceUpdationComponent } from './closing-balance-updation/closing-balance-updation/closing-balance-updation.component';
import { OpeningBalanceUpdationComponent } from './opening-balance-updation/opening-balance-updation.component';

const routes: Routes = [
  { path: 'cAccount', component: CAccountComponent },
  { path: 'vAccount', component: VAccountComponent },
  { path: 'vAccountGroup', component: VAccountGroupComponent },
  { path: 'cAccountGroup', component: CAccountGroupComponent },
  { path: 'CPettycash', component: CPettycashComponent },
  { path: 'VPettycash', component: VPettycashComponent },
  { path: 'CPettycashSettlement', component: CPettycashSettlementComponent },
  { path: 'VPettycashSettlement', component: VPettycashSettlementComponent },
  { path: 'CAssetRegirtration', component: CAssetRegirtrationComponent },
  { path: 'VAssetRegirtration', component: VAssetRegirtrationComponent },
  { path: 'CJournal', component: CJournalComponent },
  { path: 'VJournal', component: VJournalComponent },
  { path: 'CDebitnote', component: CDebitnoteComponent },
  { path: 'VDebitnote', component: VDebitnoteComponent },
  { path: 'cTaxreconcilation', component: CTaxReconcilationComponent },
  { path: 'vTaxreconcilation', component: VTaxReconcilationComponent },
  { path: 'cLaybuy', component: CLaybuyComponent },
  { path: 'vLaybuy', component: VLaybuyComponent },
  { path: 'cReceipt', component: CReceiptComponent },
  { path: 'vReceipt', component: VReceiptComponent },
  { path: 'cPayment', component: CPaymentComponent },
  { path: 'vPayment', component: VPaymentComponent },
  { path: 'cBankReconciliation', component: CBankReconciliationComponent },
  { path: 'vBankReconciliation', component: VBankReconciliationComponent },
  { path: 'cContra', component: CContraComponent },
  { path: 'vContra', component: VContraComponent },
  { path: 'cTellerCashDaySettlement', component: CTellerCashDaySettlementComponent },
  { path: 'vTellerCashDaySettlement', component: VTellerCashDaySettlementComponent },
  { path: 'cAccountClosing', component: CAccountClosingComponent },
  { path: 'vAccountClosing', component: VAccountClosingComponent },
  { path: 'cCreditnote', component: CCreditnoteComponent },
  { path: 'vCreditnote', component: VCreditnoteComponent },
  { path: 'cDirectCreditnote', component: CDirectCreditNoteComponent },
  { path: 'vDirectCreditnote', component: VDirectCreditNoteComponent },
  { path: 'cPettycashexpence', component: CPettycashExpenceComponent },
  { path: 'vPettycashexpence', component: VPettycashExpenceComponent },
  { path: 'AdvanceAdjustment', component: AdvanceAdjustmentComponent },
  { path: 'VClosingBalanceUpdation', component: ClosingBalanceUpdationComponent },
  { path: 'OpeningBalanceUpdate', component: OpeningBalanceUpdationComponent },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountsRoutingModule { }
