import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CJobCardComponent } from './job-card/c-job-card/c-job-card.component';
import { VJobCardComponent } from './job-card/v-job-card/v-job-card.component';
import { CPurchaseServiceInvoiceComponent } from './purchase-service-invoice/c-purchase-service-invoice/c-purchase-service-invoice.component';
import { VPurchaseServiceInvoiceComponent } from './purchase-service-invoice/v-purchase-service-invoice/v-purchase-service-invoice.component';
import { CServiceInvoiceComponent } from './service-invoice/c-service-invoice/c-service-invoice.component';
import { VServiceInvoiceComponent } from './service-invoice/v-service-invoice/v-service-invoice.component';
import { CServiceProductDeliveryComponent } from './service-product-delivery/c-service-product-delivery/c-service-product-delivery.component';
import { VServiceProductDeliveryComponent } from './service-product-delivery/v-service-product-delivery/v-service-product-delivery.component';
import { CServiceRequestComponent } from './Service-Request/c-service-request/c-service-request.component';
import { VServiceRequestComponent } from './Service-Request/v-service-request/v-service-request.component';
import { CServiceWorkOrderComponent } from './service-work-order/c-service-work-order/c-service-work-order.component';
import { VServiceWorkOrderComponent } from './service-work-order/v-service-work-order/v-service-work-order.component';
import { WorkshopSummaryReportComponent } from './workshop-summary-report/workshop-summary-report/workshop-summary-report.component';

const routes: Routes = [
  { path: 'CServicerequest', component: CServiceRequestComponent },
  { path: 'VServicerequest', component: VServiceRequestComponent },
  { path: 'CJobCard', component: CJobCardComponent },
  { path: 'VJobCard', component: VJobCardComponent },
  { path: 'CServiceWorkOrder', component: CServiceWorkOrderComponent },
  { path: 'Vserviceworkorder', component: VServiceWorkOrderComponent },
  { path: 'CServiceProductDelivery', component: CServiceProductDeliveryComponent },
  { path: 'VServiceProductDelivery', component: VServiceProductDeliveryComponent },
  { path: 'CServiceInvoice', component: CServiceInvoiceComponent },
  { path: 'VServiceInvoice', component: VServiceInvoiceComponent },
  { path: 'CPurchaseServiceInvoice', component: CPurchaseServiceInvoiceComponent },
  { path: 'VPurchaseServiceInvoice', component: VPurchaseServiceInvoiceComponent },
  { path: 'VWorkshopSummaryReport', component: WorkshopSummaryReportComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkshopRoutingModule { }
