
 export class Supplierproductdetail {
    supplierproductdetailid: number;
    supplierid: number;
    producttypeid: number;
    productcategoryid: number ;
    productid: number ;
    createdby: number;
    createdon: Date ;
    modifiedby: number;
    modifiedon: Date;
}