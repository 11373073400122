<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Goods Receipt Note</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>

                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [monthNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;" [hidden]="_Branchdrop">
                            <span class="p-float-label">
                                <p-dropdown 
                                 [options]="_Branch" [(ngModel)]="_Branchname"
                                 [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="branchname"
                                    optionValue="branchid" optionLabel="branchname">
                                </p-dropdown>
                                <label for="_Branchname">Branch <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="Grnviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['purchaseinvoiceno','grnno','referenceno','inwardno','inwardtypename','receivedbranch','statusname','supplierorbranchname','createdbyname','createdon','invoicestatus']"
                        [columns]="cols" [rowHover]="true" dataKey="grnno" [(selection)]="selectedrows" [rowHover]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width:100px;" class="text-center">Action</th>
                                <th pSortableColumn="grnno">Grn No
                                    <p-sortIcon field="grnno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="grndate">Grn Date
                                    <p-sortIcon field="grndate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="receivedbranch">Received Branch
                                    <p-sortIcon field="receivedbranch">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="supplierorbranchname">Supplier / Branch Name
                                    <p-sortIcon field="supplierorbranchname">
                                    </p-sortIcon>
                                </th>
                                <th [hidden]="true"></th>
                                <th pSortableColumn="inwardtypename">Inward Type
                                    <p-sortIcon field="inwardtypename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="inwardno">Inward No
                                    <p-sortIcon field="inwardno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="referenceno">Reference No
                                    <p-sortIcon field="referenceno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="purchaseinvoiceno">Purchase Invoice No
                                    <p-sortIcon field="purchaseinvoiceno">
                                    </p-sortIcon>
                                </th>


                                <th pSortableColumn="invoicestatus">Purchase Invoice Status
                                    <p-sortIcon field="invoicestatus">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="statusname">Status
                                    <p-sortIcon field="statusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-Grnview let-i="rowIndex">
                            <tr [ngClass]="{'qs-row': Grnview.isgrnamountchanged === 'YES'}">
                                <td style="text-align:center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(Grnview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update" [disabled]="Grnview.status == 'MSC00047'|| Grnview.status == 'MSC00046'" (click)="edit(Grnview)"></button>
                                    <!-- <button pButton pRipple icon="las la-print" title="Print" [disabled]="Grnview.status == 'MSC00045'|| Grnview.status == 'MSC00046' || Grnview.status == 'MSC00048' || Grnview.status == 'MSC00150'"
                                        class="p-button-warning p-mr-2"
                                        (click)="Print(Grnview)"></button> -->
                                    <button pButton pRipple icon="las la-print" title="Print" [disabled]="!_Action._Print || Grnview.status != 'MSC00047'" class="p-button-warning p-mr-2" (click)="Print(Grnview)"></button>
                                </td>
                                <td>
                                    {{Grnview.grnno}}
                                </td>
                                <td>
                                    {{Grnview.grndate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{Grnview.receivedbranch}}
                                </td>
                                <td>
                                    {{Grnview.supplierorbranchname}}
                                </td>
                                <td [hidden]="true">
                                    {{Grnview.grnid}}
                                </td>
                                <td>
                                    {{Grnview.inwardtypename}}
                                </td>
                                <td>
                                    {{Grnview.inwardno}}
                                </td>
                                <td>
                                    {{Grnview.referenceno}}
                                </td>
                                <td>
                                    {{Grnview.purchaseinvoiceno}}
                                </td>
                                <td>
                                    {{Grnview.invoicestatus}}
                                </td>
                                <td>
                                    {{Grnview.statusname}}
                                </td>
                                <td>
                                    {{Grnview.createdbyname}}
                                </td>
                                <td>
                                    {{Grnview.createdon|date:'dd/MM/yyyy'}}
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-toast position="bottom-right"></p-toast>
            </div>
        </div>
    </div>
</div>