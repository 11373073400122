import { IfStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { PurchaseinvoiceService } from 'src/app/core/Services/purchase/purchaseinvoice.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Purchaseinvoiceview } from 'src/app/core/Views/Purchaseinvoiceview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-v-purchaseinvoice',
  templateUrl: './v-purchaseinvoice.component.html',
  styleUrls: ['./v-purchaseinvoice.component.css']
})
export class VPurchaseinvoiceComponent implements OnInit {
  _Purchaseinvoicedetails: Purchaseinvoiceview[];
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _Print: boolean;
  _branchid: number;
  _userid: number;
  _roleid: number;
  _IsProgressSpinner: boolean = true;
  _purchaseinvoiceid: number;
  isDisabledsendapparoval
  //_PurchaseInvoiceNo:number;
  cols: any[];
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _Branch: any[] = [];
  _Branchdrop: Boolean = true;
  _Branchname: number;
  constructor(private fb: FormBuilder, private _PurchaseinvoiceService: PurchaseinvoiceService, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService, private router: Router, private utility: CommonUtilities,
    private _hotkeysService: HotkeysService, private confirmationService: ConfirmationService,
    private exportUtility: ExportUtility) {
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(41);
    this.HotKeyIntegration();
    this._branchid=  parseInt(sessionStorage["currentbranchid"]);
    this._Branchname  = this._branchid;
    this.fromdate = new Date();
    this.todate = new Date();
    this.cols = [
      { field: 'purchaseinvoiceno', header: 'Purchase Invoice No' },
      { field: 'purchaseinvoicedate', header: 'Purchase Invoice Date' },
      { field: 'grnno', header: 'GRN NO' },
      { field: 'suppliername', header: 'Supplier Name' },
      { field: 'invoiceamount', header: 'Invoice Amount' },
      { field: 'statusame', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this.Fetchall();
    if(this._roleid == 1) 
    {
      this._Branchdrop = false;       
    }
    else{
      this._Branchdrop = true;
    }
    // this._branchid=  sessionStorage["currentbranchid"];
    // this._userid=sessionStorage["userid"];
    // this._roleid=sessionStorage["currentRole"];
    // this._IsProgressSpinner = true;
    // this._PurchaseinvoiceService.FetchAll(this._branchid, this._userid,this._roleid,fromdate,todate).subscribe((result) => {
    //   const resultJSON = JSON.parse(JSON.stringify(result));
    //   this._Purchaseinvoicedetails = resultJSON.purchaseinvoiceview;
    //   this._IsProgressSpinner = false;
    // },
    // error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    if (this._roleid != 1){
      this._branchid = parseInt(sessionStorage["currentbranchid"]);
    }
    else
    {
      this._branchid = this._Branchname;
    }
    // this._branchid = sessionStorage["currentbranchid"];
    this._userid = sessionStorage["userid"];
    this._roleid = sessionStorage["currentRole"];
    this._IsProgressSpinner = true;
    this._PurchaseinvoiceService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Purchaseinvoicedetails = resultJSON.purchaseinvoiceview;
      this._Branch = resultJSON.invoiceBranchList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/cPurchaseinvoice'], { state: { purchaseinvoiceid: event.purchaseinvoiceid, purchaseinvoiceno: event.purchaseinvoiceno, action: 'view' } });
  }
  SendToApproval(event) {
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._IsProgressSpinner = true;
    this._PurchaseinvoiceService.SendToApproval(this._userid, this._branchid, event.purchaseinvoiceno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
      this._IsProgressSpinner = false;
      this.isDisabledsendapparoval = true;
      this._PurchaseinvoiceService.FetchAll(this._userid, this._branchid, this._roleid, fromdate, todate).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Purchaseinvoicedetails = resultJSON.purchaseinvoiceview;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    },

      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  edit(event) {
    this.router.navigate(['/cPurchaseinvoice'], { state: { purchaseinvoiceid: event.purchaseinvoiceid, purchaseinvoiceno: event.purchaseinvoiceno, action: 'edit' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cPurchaseinvoice']);
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Purchase Invoice  <b>"' + event.purchaseinvoiceno + '"</b> ?',
      accept: () => {
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._purchaseinvoiceid = event.purchaseinvoiceno;
    this._IsProgressSpinner = true;
    this._PurchaseinvoiceService.Cancel(this._purchaseinvoiceid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._IsProgressSpinner = false;
        this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      }
      this.Fetchall();
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let finalizedList = this.GetFinalizedList();
    this.exportUtility.exportExcel(finalizedList, 'Purchase-Invoice', 'xlsx');
  }

  ExportToPdf() {
    // let finalizedList = this.GetFinalizedList();
    // this.exportUtility.ExportToPdf(finalizedList, 'Purchase-Invoice.pdf');
    if (!this._Purchaseinvoicedetails || !this._Purchaseinvoicedetails.length)
      return;
    let StatusHistoryDetails = this.GetFinalizedList();
    const data = StatusHistoryDetails.map(Object.values);
    const head = [['Purchase Invoice No', 'Purchase Invoice Date', 'GRN NO', 'Supplier Name', 'Supplier Invoice No', 'Currency', 'Invoice Amount', 'Status', 'Created By', 'Created ON']]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,

          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Purchase-Invoice.pdf');
      })
    })
  }

  GetFinalizedList() {
    let finalizedList = [];
    this._Purchaseinvoicedetails.forEach(element => {
      let quotes: any = {};
      quotes["Purchase Invoice No"] = element.purchaseinvoiceno;
      quotes["Purchase Invoice Date"] = element.purchaseinvoicedate;
      quotes["GRN NO"] = element.grnno;
      quotes["Supplier Name"] = element.suppliername;
      quotes["Supplier Invoice No"] = element.supplierinvoiceno;
      quotes["Currency"] = element.currencyname;
      quotes["Invoice Amount"] = element.invoiceamount;
      quotes["Status"] = element.statusame;
      quotes["Created By"] = element.createdbyname;
      quotes["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      finalizedList.push(quotes);
    });
    return finalizedList;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  // Print(event) {
  //   var purchaseinvoiceno = event.purchaseinvoiceno;
  //   this._IsProgressSpinner = true;
  //   //var mediaType = 'application/pdf';
  //   this._PurchaseinvoiceService.Print(purchaseinvoiceno, this._branchid);
  //   this._IsProgressSpinner = false;
  // }
  Print(event) {
    let Data = {
      "purchaseinvoiceno": event.purchaseinvoiceno,
      "branchid": this._branchid
    }
    this._IsProgressSpinner = true;
    this._PurchaseinvoiceService.Print(Data).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


}
