import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { BrandService } from 'src/app/core/Services/masters/brand.service';
import { CountryService } from 'src/app/core/services/masters/country.service';
import { OtherchargesService } from 'src/app/core/Services/masters/othercharges.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Countryview } from 'src/app/core/Views/Countryview ';
import { Otherchargesview } from 'src/app/core/Views/Otherchargesview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-v-brand',
  templateUrl: './v-brand.component.html',
  styleUrls: ['./v-brand.component.css']
})
export class VBrandComponent implements OnInit {
  _Brandsview: any[]=[];
  selectedrows: any;
  _IsProgressSpinner: boolean = true;
  _userid = sessionStorage["userid"];
  datepipe = new DatePipe('en-US');
  _Action: IAction;
  _brandview: any;
  constructor(private fb: FormBuilder, private _BrandService: BrandService, private router: Router, private utility: CommonUtilities,
    private _CustomExceptionService: CustomExceptionService, private confirmationService: ConfirmationService,
    private _AccessRightsService: AccessRightsService,private exportUtility: ExportUtility, private _hotkeysService: HotkeysService, private _DomSanitizationService: DomSanitizer) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.FetchAll();
    this._Action = this._AccessRightsService.getAccessRights(309);
    this.HotKeyIntegration();
   
  }
//FetchaLL
FetchAll(){
  this._IsProgressSpinner = true;
  this._BrandService.FetchAll().subscribe((result) => {
    const resultJSON = JSON.parse(JSON.stringify(result));
    this._Brandsview = resultJSON.brandviews||[];
    for (var i = 0; i < this._Brandsview.length; i++) {
      this._Brandsview[i].brandurl = this._DomSanitizationService.bypassSecurityTrustUrl((this._Brandsview[i].brandurl));
    }

    this._IsProgressSpinner = false;
  },
  error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
}

  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    // if (this._Action._Add) {
    this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
      this.GoToCreate();
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
    // }
  }
  GoToCreate() {
    this.router.navigate(['/cBrand']);
  }
  view(event) {
    //33.on clicking view button, it show the view screen.
    this.router.navigate(['/cBrand'], { state: { brandid: event.brandid, action: 'view' } });
  }
  edit(event) {
    //36.on clicking update button, it show the update screen.
    // if (event.status == "MSC00045" || event.status == null) {
    this.router.navigate(['/cBrand'], { state: { brandid: event.brandid, action: 'edit' } });
  }
  delete(event) {
    var brandid = event.brandid;
    this._BrandService.Delete(brandid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
        this.FetchAll();
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
     
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'Brand', 'xlsx');
  }
  ExportToPdf() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.ExportToPdf(AssetRegistrationList, 'Brand.pdf');
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._Brandsview.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Brand Code"] = element.brandname;
      assetregistration["Status"] = element.recordstatusname;
      assetregistration["Created By"] = element.createdbyname;
      assetregistration["Created On"] = this.datepipe.transform(element.createdon,'dd/MM/yyyy');
      assetregistration["Modified By"] = element.modifiedbyname;
      assetregistration["Modified On"] = this.datepipe.transform(element.modifiedon, 'dd/MM/yyyy');
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
}
