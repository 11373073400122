export class Tenderresource {
    companyid: number;
    branchid: number;
    tenderresourceid: number;
    tenderid: number;
    resourceid: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date ;
    accountingyear: number;
}