import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Branch } from '../../Models/Branch';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  public _Action: IAction;
  public _BranchService = environment.BranchService;
  public _httpOptions;
  public _BranchList: Branch[] = [];
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 1)
  }
  createHeaders(token: any, type: number): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  // For pageLoad
  PageOnLoad(p_branchid: number, userid: number): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BranchService + '/' + p_branchid + '/' + userid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  // For States
  getStates(countryid: string) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BranchService + '/getStates/' + countryid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  // For City
  getDistrict(stateid: string) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BranchService + '/getDistricts/' + stateid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  // For Fetching data
  FetchAll() {
    const headers = this.createHeaders(this.token, 2)
    //33. based on the branch code ,branch name, branch type  you need to filter the data and show in the grid
    // 34. on clicking search button , the data must be filtered and display in the grid
    var Query = this._BranchService + '/FetchAll';

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      // let _CountryScreenAccess = resultJSON.userData.lstFunctions.find(funct => funct.functionid == "15");
      // this._Action = {
      //   _functionId: 15,
      //   _functionName: "Customer",
      //   _Add: _CountryScreenAccess.iscreateallowed,
      //   _Delete: _CountryScreenAccess.isdeleteallowed,
      //   _Update: _CountryScreenAccess.iseditallowed,
      //   _View: _CountryScreenAccess.isviewallowed,
      //   _Approve: _CountryScreenAccess.isviewallowed
      //}
      return data;
    }));
  }
  //For CREATE
  create(p_branch: FormData) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BranchService;
    return this.http.post<{ data: any }>(Query, p_branch, { headers });
  }
  // create(p_Tenderform: FormData) {
  //   var Query = this._TenderService;
  //   return this.http.post<{ data: any }>(Query, p_Tenderform);
  // }
  // For EDIT
  edit(p_branch: FormData) {
    const headers = this.createHeaders(this.token, 2)
    //console.log("Update");
    var Query = this._BranchService;

    return this.http.put<{ data: any }>(Query, p_branch, { headers });
  }

  //added by vinitha
  getProductCategory(producttype: object) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BranchService + '/GetProductCategory';

    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }
  //To Get Product :
  getProduct(productcategory: object) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BranchService + '/GetProductName';

    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }

  getProductdetails(product: object) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BranchService + '/FetchProductdetails';

    return this.http.post<{ data: any }>(Query, product, { headers });
  }

  Cancel(branchid: number, modifiedby: number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._BranchService + '/Cancel/' + branchid + '/' + modifiedby;

    return this.http.get<{ data: any }>(Query, { headers });
  }
}
