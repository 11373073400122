<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Damaged Product Price Details</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" [disabled]="isDisabledsave || !_Action._Add" (click)="onSave()" title="Save"
                                icon="pi pi-save" class=""></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button"
                                [disabled]="isDisabledsendtoapproval" (click)="SendToApproval($event)"></button>
                            <button pButton pRipple label="" [disabled]="isDisablereset" (click)="reset($event)"
                                title="Clear" icon="pi pi-trash" class="" class="p-button-danger"
                                [disabled]="_action=='edit'"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_damagedproductpricedetailsform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <p-multiSelect [options]="_producttype" optionLabel="producttypename"
                                    [disabled]="isDisableproducttype" optionValue="producttypeid"
                                    (onChange)="getProductCategory($event)" defaultLabel="Product Type"
                                    [(ngModel)]="producttypeid" [ngModelOptions]="{standalone: true}">
                                </p-multiSelect>
                            </td>
                            <td style="width: 20%;">
                                <p-multiSelect [options]="_productcategory" defaultLabel="Product Category"
                                    [disabled]="isDisablecategory" optionLabel="categoryname"
                                    (onChange)="getProduct($event)" optionValue="productcategoryid"
                                    [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}">
                                </p-multiSelect>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-autoComplete [(ngModel)]="_Product" [disabled]="isDisablecategory"
                                        [suggestions]="filteredProducts" [ngModelOptions]="{standalone: true}"
                                        (completeMethod)="filterProducts($event)" field="productname"
                                        (onSelect)="getVariant($event)">
                                        <ng-template let-product pTemplate="item">
                                            <div>{{product.productname}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_severities"
                                        [disabled]="isDisableseverity" optionValue="metasubid"
                                        formControlName="severity" optionLabel="metasubdescription">
                                    </p-dropdown>
                                    <label for="variantid">Severity <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _damagedproductpricedetailsform.get('severity').touched) && _damagedproductpricedetailsform.get('severity').errors?.SeverityRequired">
                                    Please Select Severity
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" name="unitprice" disabled="true" formControlName="unitprice"
                                        pInputText>
                                    <label for="currencyid"> Unit Price <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" name="damagedunitprice" formControlName="damagedunitprice"
                                        [disabled]="isDisabledamaged" (keyup)="onKeyupRatechange()" pInputText>
                                    <label for="currencyid"> Damaged unit price <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _damagedproductpricedetailsform.get('damagedunitprice').touched) && _damagedproductpricedetailsform.get('damagedunitprice').errors?.DamagedunitpriceRequired">
                                    Please Enter Damaged unit price
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" name="commonmarkuppercentage" formControlName="commonmarkuppercentage"
                                       (keyup)="markuppercentage()" pInputText>
                                    <label for="currencyid"> Common Markup Percentage </label>
                                </span>
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-table #dt [value]="_damagedproductpriceDetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['LEVELNAME','markuppercentage','vatpercentage','USD','CAD','ZAR','Currency4']"
                        [rowHover]="true" dataKey="productid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th [hidden]="true">Level ID</th>
                                <th [hidden]="true">Product ID</th>
                                <th>Markup Level</th>
                                <th>Markup Percentage </th>
                                <th>Tax Type</th>
                                <th>USD</th>
                                <th>RAND</th>
                                <th>BOND</th>
                                <th>BANK</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_damagedproductpriceDetail>
                            <tr>
                                <td [hidden]="true">
                                    {{_damagedproductpriceDetail.markuplevel}}
                                </td>
                                <td [hidden]="true">
                                    {{_damagedproductpriceDetail.productid}}
                                </td>
                                <td>
                                    {{_damagedproductpriceDetail.markuplevelname}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber [(ngModel)]="_damagedproductpriceDetail.markuppercentage"
                                                autocomplete="off" maxlength="5"
                                                (onBlur)="onKeyupSellingPrice(_damagedproductpriceDetail,$event)"
                                                mode="decimal" [disabled]="isDisabledmarkuppercentage"
                                                [minFractionDigits]="2" [maxFractionDigits]="2">
                                            </p-inputNumber>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_damagedproductpriceDetail.markuppercentage}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_vatpercentage"
                                                optionLabel="taxcode" optionValue="taxid" [disabled]="isDisabledvat"
                                                [showClear]="true"
                                                [(ngModel)]="_damagedproductpriceDetail.vatpercentageid"
                                                (onChange)="onKeyupSellingPrice(_damagedproductpriceDetail,$event)">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_vatpercentage"
                                                optionLabel="taxcode" optionValue="taxid" [disabled]="isDisabledvat"
                                                [(ngModel)]="_damagedproductpriceDetail.vatpercentageid">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_damagedproductpriceDetail.tempUsd | number: '1.2-4'}}
                                </td>
                                <td>
                                    {{_damagedproductpriceDetail.tempRand | number: '1.2-4'}}
                                </td>
                                <td>
                                    {{_damagedproductpriceDetail.tempBond | number: '1.2-4'}}
                                </td>
                                <td>
                                    {{_damagedproductpriceDetail.tempBank | number: '1.2-4'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table class="normal-table">
                    <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                </table>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>