<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Closing Balance Verification Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptClosingbalanceform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="fromdate" dateFormat="dd/mm/yy"
                                        [monthNavigator]="true"  [minDate]="mindate" [maxDate]="maxdate"
                                        [yearNavigator]="true" yearRange="2021:2050">
                                    </p-calendar>
                                    <label for="fromdate">From Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="todate" dateFormat="dd/mm/yy"
                                        [monthNavigator]="true" [yearNavigator]="true" 
                                        [minDate]="mindate" [maxDate]="maxdate" yearRange="2021:2050">
                                    </p-calendar>
                                    <label for="todate">To Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_typelist" optionValue="typeid"
                                        optionLabel="typename" formControlName="partytype">
                                    </p-dropdown>
                                    <label for="partytypename">Party Type <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>

                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_PartytypeList" [scrollable]="true" scrollHeight="450px" [rows]="500" [paginator]="true" [rowsPerPageOptions]="[100,200,300]" [rowsPerPageOptions]="[500,1000,1500,2000]" [globalFilterFields]="['ledgername','receiptscreen', 'paymentscreen', 'accountledgerreport','ageingreport','outstandingreport']"
                        [rowHover]="true" dataKey="partytype" [columns]="cols" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="ledgername">Ledger Name
                                    <p-sortIcon field="ledgername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="receiptscreen">Receipt Screen
                                    <p-sortIcon field="receiptscreen">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="paymentscreen">Payment Screen
                                    <p-sortIcon field="paymentscreen">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="accountledgerreport">Account Ledger Report
                                    <p-sortIcon field="accountledgerreport">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="ageingreport">Ageing Report
                                    <p-sortIcon field="ageingreport">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="outstandingreport">Outstanding Report
                                    <p-sortIcon field="outstandingreport">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_closingBalanceVerificationview let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 60px;">
                                    {{i+1}}
                                </td>
                                <td>
                                    {{_closingBalanceVerificationview.LEDGERID}}
                                </td>
                                <td>
                                    {{_closingBalanceVerificationview.RECEIPTVALUE}}
                                </td>
                                <td>
                                    {{_closingBalanceVerificationview.PAYMENTVALUE}}
                                </td>
                                <td>
                                    {{_closingBalanceVerificationview.ACCOUNTLEDGERVALUE}}
                                </td>
                                <td>
                                    {{_closingBalanceVerificationview.AGEINGVALUE}}
                                </td>
                                <td>
                                    {{_closingBalanceVerificationview.OUTSTANDINGVALUE}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>