import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { ToolbarModule } from 'primeng/toolbar';
import { RatingModule } from 'primeng/rating';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChipsModule } from 'primeng/chips';
import { FieldsetModule, } from 'primeng/fieldset';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { HttpClientModule } from '@angular/common/http';
import { TabViewModule } from 'primeng/tabview';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { TellerDashboardComponent } from './teller-dashboard/teller-dashboard.component';
import { ProductDashboardComponent } from './product-dashboard/product-dashboard.component';
import { StockDashboardComponent } from './stock-dashboard/stock-dashboard.component';
import { ChartModule } from 'primeng/chart';
import { SupplierDashboardComponent } from './supplier-dashboard/supplier-dashboard.component';
import { PurchasereturnDashboardComponent } from './purchasereturn-dashboard/purchasereturn-dashboard.component';
import { SalesreturnDashboardComponent } from './salesreturn-dashboard/salesreturn-dashboard.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { PurchaseDashboardComponent } from './purchase-dashboard/purchase-dashboard.component';
import { OutstandingReceivableDashboardComponent } from './outstanding-receivable-dashboard/outstanding-receivable-dashboard.component';
import { OutstandingPayableDashboardComponent } from './outstanding-payable-dashboard/outstanding-payable-dashboard.component';
@NgModule({
	declarations: [
		CustomerDashboardComponent,
		TellerDashboardComponent,
		ProductDashboardComponent,
		StockDashboardComponent,
		SupplierDashboardComponent,
		PurchasereturnDashboardComponent,
		SalesreturnDashboardComponent,
		SalesDashboardComponent,
		PurchaseDashboardComponent,
		OutstandingReceivableDashboardComponent,
		OutstandingPayableDashboardComponent
	],
	imports: [
		CommonModule,
		DashboardRoutingModule,
		ChartModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		TableModule,
		CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
		InputTextModule,
		ProgressBarModule,
		FileUploadModule,
		ToolbarModule,
		RatingModule,
		FormsModule,
		RadioButtonModule,
		InputNumberModule,
		ConfirmDialogModule,
		InputTextareaModule,
		ChipsModule,
		FieldsetModule,
		AutoCompleteModule,
		OverlayPanelModule,
		CheckboxModule,
		PasswordModule,
		HttpClientModule,
		TabViewModule,
		ProgressSpinnerModule
	]
})
export class DashboardModule { }