import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptOutstandingpoService {

  public _Action: IAction;
  public _httpOptions;
  public _OutdstandingPurchaseOrderService = environment.OutdstandingPurchaseOrderService;
  token: any;
  header: any;

  constructor(private http: HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
    }
    createHeaders(token: any): HttpHeaders {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include your token here	
      });
    }

    PageOnload(data: object): Observable<any> {
      const headers = this.header;
      var Query = this._OutdstandingPurchaseOrderService + '/PageInitialize';
      return this.http.post<{ data: any }>(Query, data, { headers });
    }

    GetoutstandingPO(data: object): Observable<any> {
      const headers = this.header;
      var Query = this._OutdstandingPurchaseOrderService + '/GetoutstandingPO';
  
      return this.http.post<{ data: any }>(Query, data, { headers });
    }

    GetOutStandingPODetails(data: object): Observable<any> {
      const headers = this.header;
      var Query = this._OutdstandingPurchaseOrderService + '/GetOutStandingPODetails';
  
      return this.http.post<{ data: any }>(Query, data, { headers });
    }

  }
