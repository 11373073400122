import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { Accountledger } from '../../Models/Accountledger';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class AccountledgerService {
  public _Action: IAction;
  public _AccountledgerService = environment.AccountledgerService;
  public _httpOptions;
  public _AccountledgerList: Accountledger[] = [];
  token: any;
  header: any;
  
  constructor(private http: HttpClient, private _CustomExceptionService: CustomExceptionService) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
   }
   createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(p_Accountledgerid: number): Observable<any> {
    const headers = this.header;
    var Query = this._AccountledgerService + '/' + p_Accountledgerid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll(userid:number) {
    const headers = this.header;
    var Query = this._AccountledgerService + '/FetchAll'+'/'+userid;
   
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }
  create(p_Accountledger: object) {
    const headers = this.header;
    console.log("Create");
    var Query = this._AccountledgerService;
    return this.http.post<{ data: any }>(Query, p_Accountledger, { headers });
  }
  edit(p_Accountledger: object) {
    const headers = this.header;
    console.log("Update");
    var Query = this._AccountledgerService;
    return this.http.put<{ data: any }>(Query, p_Accountledger, { headers });
  }
}
