import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class DirectsalesreturnService {

  public _SalesReturnService = environment.DirectsalesreturnService;
  public _RptSRPrint = environment.RptSalesReturnPrintService;
  public _httpOptions;
  constructor(private http: HttpClient) { }

  // Page Load Service:
  PageOnLoad(salesreturnno: string, userid: number): Observable<any> {
    var Query = this._SalesReturnService + '/' + salesreturnno + '/' + userid;
    return this.http.get<{ data: any }>(Query);
  }
  
  create(salesreturn: object) {
    var Query = this._SalesReturnService;
    return this.http.post<{ data: any }>(Query, salesreturn);
  }

  FetchAll(userid: number,branchid: number, roleid:number,fromdate:string,todate:string) {
    var Query = this._SalesReturnService + '/FetchAll' + '/' + userid + '/' + branchid + '/' + roleid+'/'+fromdate+'/'+todate;
    return this.http.get<{ data: any }>(Query);
  }

  GetProduct(product: string, branchid: number) {
    var Query = this._SalesReturnService + '/GetProduct' + '/' + product + '/' + branchid;
    return this.http.get<{ data: any }>(Query);
  }

  SendToApproval(userid: number, branchid: number, salesreturnno: string) {
    var Query = this._SalesReturnService + '/SendToApproval' + '/' + userid + '/' + branchid + '/' + salesreturnno;
    return this.http.post<{ data: any }>(Query, '');
  }
  Cancel(userid: number,salesreturnno: string) {
    var Query = this._SalesReturnService + '/Cancel' +'/'+userid+'/'+ salesreturnno;
    return this.http.post<{ data: any }>(Query,'');
  }
  Print(salesreturnno: string,branchid:number ) {
    var Query = this._RptSRPrint + '/Print/' + salesreturnno + '/'+branchid;
    window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes'); 
  }
}
