import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Brand } from 'src/app/core/Models/Brand';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { RptbranchwisestockService } from 'src/app/core/Services/reports/Stock/RptBranchwiseStock/rptbranchwisestock.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-rpt-lotexpiryageing',
  templateUrl: './rpt-lotexpiryageing.component.html',
  styleUrls: ['./rpt-lotexpiryageing.component.css']
})
export class RptLotexpiryageingComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptexpiryageingform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _userid: number = parseInt(sessionStorage["userid"]);
  datepipe = new DatePipe('en-US');
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _variantlist: Productvariant[];
  _productlist: Productoverallview[] = [];
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  branchid: number[] = [];
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _LotExpiryAgeingObj: any;
  _ReportLists: any[] = [];
  brandid: number[] = [];
  _stocktypelist: Metadatum[] = [];
  _brandlist: any;
  _ProductObj: any;
  _Tempbrandlist: Brand;
  Reportdetails: any[] = [];
  ExpiryDetailReport: boolean = false;
  ExpiryHeaderReport: boolean = false;
  ExpiredReport: boolean = true;

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptbranchwisestockService: RptbranchwisestockService,
    private exportUtility: ExportUtility,
    private _router: Router, private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._rptexpiryageingform = this.fb.group({
      branchid: [0],
      brandid: [0],
      productid: [0],
      productcategoryid: [0],
      producttypeid: [0],
      variantid: [0],
      stocktype: [],
      isexpired: [],
      type: []

    })
    this._userid = parseInt(sessionStorage["userid"]);

    this._IsProgressSpinner = true;
    this._RptbranchwisestockService.PageOnload({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._producttypelist = resultJSON.producttypeList;
      this._stocktypelist = resultJSON.stockType;
      this._rptexpiryageingform.get("stocktype").setValue("MSC00243");
      this._rptexpiryageingform.get("branchid").setValue(parseInt(sessionStorage["currentbranchid"]));
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  getProductCategory(event) {
    var selectedProductType = event.value;
    var branchid = this._rptexpiryageingform.get("branchid").value;
    this.productcatid = [];
    this.productid = [];
    this._productlist = [];
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      this._rptexpiryageingform.controls["productcategoryid"].reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptbranchwisestockService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    this.productid = [];
    var branchid = this._rptexpiryageingform.get("branchid").value == null;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": branchid || parseInt(sessionStorage["currentbranchid"]) };
      this._RptbranchwisestockService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
      });
    }
  }

  GenerateReport() {

    var branchid = this._rptexpiryageingform.get("branchid").value;
    var stocktype = this._rptexpiryageingform.get("stocktype").value;
    var isexpired = this._rptexpiryageingform.get("isexpired").value || false;
    var stocktype = this._rptexpiryageingform.get("stocktype").value

    this._LotExpiryAgeingObj =
    {
      "Productcategory": this.productcatid || [],
      "Producttype": this.producttypeid || [],
      "Productheader": this.productid || [],
      "brandid": this.brandid || [],
      "Branchid": branchid,
      "IsExpired": isexpired,
      "Type": 0,
      "StockType": stocktype
    };
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this._RptbranchwisestockService.ExpiryAgeingPrint(this._LotExpiryAgeingObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      this._ReportLists = [];

      if (!resultJSON.reportList || !resultJSON.reportList.length) {
        this._CustomExceptionService.handleError("No Data Available");
      }
      else {
        this._ReportLists = resultJSON.reportList;
      }

      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ChangeProduct(event) {
    var selectedProduct = event.value;

    var branchid = this._rptexpiryageingform.get("branchid").value;
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._brandlist = this._Tempbrandlist
      // this._rptbranchwisestockform.controls["productcategoryid"].reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductObj = { "Products": selectedProduct || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptbranchwisestockService.getBrand(this._ProductObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        let productbrand = resultJSON.productbrandlist;
        let uniqueChars = [...new Set(productbrand)];
        uniqueChars = productbrand.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.brandid === test.brandid
          )
        );
        this._brandlist = uniqueChars;
        // this._brandlist = resultJSON.productbrandlist;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  commonfilter() {

    var branchid = this._rptexpiryageingform.get("branchid").value;
    var stocktype = this._rptexpiryageingform.get("stocktype").value;
    var isexpired = this._rptexpiryageingform.get("isexpired").value || false;
    var type = this._rptexpiryageingform.get("type").value;
    var stocktype = this._rptexpiryageingform.get("stocktype").value


    this._LotExpiryAgeingObj =
    {
      "Productcategory": this.productcatid || [],
      "Producttype": this.producttypeid || [],
      "Productheader": this.productid || [],
      "brandid": this.brandid || [],
      "Branchid": branchid,
      "IsExpired": isexpired,
      "Type": type,
      "StockType": stocktype
    };
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this._RptbranchwisestockService.ExpiryAgeingPrint(this._LotExpiryAgeingObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      this.Reportdetails = [];

      if (!resultJSON.reportdetails || !resultJSON.reportdetails.length) {
        this._CustomExceptionService.handleError("No Data Available");
      }
      else {
        this.ExpiryDetailReport = true;
        this.Reportdetails = resultJSON.reportdetails;
      }

      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  thirtydays() {
    this._rptexpiryageingform.get("type").setValue(1);
    this.commonfilter();
  }
  sixtydays() {
    this._rptexpiryageingform.get("type").setValue(2);
    this.commonfilter();
  }
  ninetydays() {
    this._rptexpiryageingform.get("type").setValue(3);
    this.commonfilter();
  }
  onetwentydays() {
    this._rptexpiryageingform.get("type").setValue(4);
    this.commonfilter();
  }
  onefiftydays() {
    this._rptexpiryageingform.get("type").setValue(5);
    this.commonfilter();
  }
  oneeightydays() {
    this._rptexpiryageingform.get("type").setValue(6);
    this.commonfilter();
  }
  oneeightydaysequal() {
    this._rptexpiryageingform.get("type").setValue(7);
    this.commonfilter();
  }
  aboveoneeightydays() {
    this._rptexpiryageingform.get("type").setValue(8);
    this.commonfilter();
  }
  onchangeexpired() {
    var isexpired = this._rptexpiryageingform.get("isexpired").value;
    if (isexpired == true) {
      this.ExpiredReport = false;
      this.ExpiryHeaderReport = true;
      this.ExpiryDetailReport = false;
      this._ReportLists = [];
      this.Reportdetails = [];

    }
    else {
      this.ExpiryHeaderReport = false;
      this.ExpiredReport = true;
      this._ReportLists = [];
      this.Reportdetails = [];
    }
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'Lot_Wise_Ageing_Report', 'xlsx');
  }
  ExportToPdf() {
    let finalizedList = this.GetAssetRegistrationList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Branch',
      'Product Type',
      'Product Category',
      'Product',
      'Lot Number',
      'Severity',
      'Stock',
      'Expiry Date',
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Lot_Wise_Ageing_Report.pdf');
      })
    })
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    if (!this._ReportLists || !this._ReportLists.length) {
      this._CustomExceptionService.handleWarning("No Data Available");
      return
    }
    this._ReportLists.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Branch"] = element.branchname;
      assetregistration["Product Type"] = element.producttypename;
      assetregistration["Product Category"] = element.productcategoryname;
      assetregistration["Product"] = element.productname;
      assetregistration["Lot Number"] = element.lotno;
      if (element.severity == null || element.severity == undefined) {
        assetregistration["Severity"] = "";
      } else {
        assetregistration["Severity"] = element.severity;
      }
      assetregistration["Stock"] = element.stock;
      assetregistration["Expiry Date"] = element.expirydate;
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }



  exportExcel1() {
    let AssetRegistrationList = this.GetAssetRegistrationList1();
    this.exportUtility.exportExcel(AssetRegistrationList, 'Lot_Wise_Ageing_Report', 'xlsx');
  }
  ExportToPdf1() {
    let finalizedList = this.GetAssetRegistrationList1();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Branch',
      'Product Type',
      'Product Category',
      'Product',
      'Lot Number',
      'Severity',
      'Stock',
      'Expiry Date',
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Lot_Wise_Ageing_Report.pdf');
      })
    })
  }
  GetAssetRegistrationList1() {
    let AssetRegistrationList = [];
    if (!this._ReportLists || !this._ReportLists.length) {
      this._CustomExceptionService.handleWarning("No Data Available");
      return
    }
    this.Reportdetails.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Branch"] = element.branchname;
      assetregistration["Product Type"] = element.producttypename;
      assetregistration["Product Category"] = element.productcategoryname;
      assetregistration["Product"] = element.productname;
      assetregistration["Lot Number"] = element.lotno;
      if (element.severity == null || element.severity == undefined) {
        assetregistration["Severity"] = "";
      } else {
        assetregistration["Severity"] = element.severity;
      }
      assetregistration["Stock"] = element.stock;

      assetregistration["Expiry Date"] = element.expirydate;

      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
  clear() {
    this.productcatid = [];
    this.producttypeid = [];
    this.productid = [];
    this._productcategorylist= [];
    this._productlist= [];
    this._rptexpiryageingform.reset();
    this._rptexpiryageingform.get("stocktype").setValue("MSC00243");
    this._rptexpiryageingform.get("branchid").setValue(parseInt(sessionStorage["currentbranchid"]));
  }
}

