import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  public _Action : IAction ;
  public _ShippingService = environment.ShippingService;
  public _httpOptions;


  constructor(private http:HttpClient) { }

  PageOnload(): Observable<any> {
    var Query = this._ShippingService ;
    // console.log(Query);
    return this.http.get<{data:any}>(Query);
  }
  Create(shipping:object) {
    var Query = this._ShippingService+ '/Create' ;
    // console.log(Query);
    return this.http.post<{data:any}>(Query,shipping);
  }
  edit(shipping : object)
  {
    // console.log("Update");
    var Query = this._ShippingService+'/Update';
    // console.log(Query);
    return this.http.put<{data:any}>(Query,shipping);
  }
  FetchAll()
  {
    var Query = this._ShippingService + '/FetchAll' ;
    // console.log(Query);
    return this.http.get<{data:any}>(Query,this._httpOptions).pipe(map(data=>{
      const resultJSON = JSON.parse(JSON.stringify(data));
      // console.log(resultJSON);
     return data; 
    }),);
  }
  View(scid:number):  Observable<any>{
    var Query = this._ShippingService+ '/View' +'/'+ scid;
    // console.log(Query);
    return this.http.get(Query);
  }
  Delete(scid:number)
  {
    // console.log("Update");
    var Query = this._ShippingService+'/Delete' +'/'+ scid;
    // console.log(Query);
    return this.http.get(Query);
  }
  // Delete(scid:number){
  //   var Query = this._ShippingService+ '/Delete' +'/'+ scid ;
  //   console.log(Query);
  //   return this.http.put<{data:any}>(Query,scid);
  // }
}
