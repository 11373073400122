<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
          </div>
        <div class="white">
            <form [formGroup]="_countryform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Country</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple type="submit" [disabled]="isDisabledsave" title="Save" icon="pi pi-save" ></button>
                                <button pButton pRipple type="button" [disabled]="isDisabledreset" (click)="reset($event)" title="Clear"
                                    icon="pi pi-trash" class="" class="p-button-danger"></button>
                                <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                    title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="countrycode" formControlName="countrycode">
                                    <label for="countrycode"> Enter Country Code <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 4.if "country code" is not enter, show error as "Please Enter country code" -->
                                <span class="text-danger" 
                                     *ngIf="(_submitted || _countryform.get('countrycode').touched) && _countryform.get('countrycode').errors?.CountryCodeRequired"> 
                                    Please enter country code 
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" pInputText name="countryname" formControlName="countryname">
                                    <label for="countryname"> Enter Country Name <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 5.if "country name" is not enter, show error as "Please Enter country name" -->
                                <!-- 18.country name must be editable ,because it should  allow to update -->
                                <!-- 21.if "country name" is not enter, show error as "Please Enter country name" -->
                                <span class="text-danger" 
                                     *ngIf="(_submitted || _countryform.get('countryname').touched )&& _countryform.get('countryname').errors?.CountryNameRequired"> 
                                    Please enter country name 
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="dialcode" formControlName="dialcode">
                                    <label for="dialcode"> Dial Code <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 4.if "country code" is not enter, show error as "Please Enter country code" -->
                                <span class="text-danger" 
                                     *ngIf="(_submitted || _countryform.get('dialcode').touched) && _countryform.get('dialcode').errors?.DialCodeRequired"> 
                                    Please enter Dial Code 
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText name="phonenumberlength" formControlName="phonenumberlength">
                                    <label for="phonenumberlength"> Mobile No Length <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- 4.if "country code" is not enter, show error as "Please Enter country code" -->
                                <span class="text-danger" 
                                     *ngIf="(_submitted || _countryform.get('phonenumberlength').touched) && _countryform.get('phonenumberlength').errors?.PhonenumberLengthRequired"> 
                                    Please enter Mobile No Length
                                </span>
                            </td>
                            <td>
                                <!-- 9.status must save  default as active while saving. -->
                                <!-- 19. status column need to show in the update screen  -->
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_countrystatus" optionLabel="metasubdescription"
                                        optionValue="metasubcode" name="countrystatus" formControlName="countrystatus">
                                    </p-dropdown>
                                    <label for="countrystatus"> Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_countryform.get('countrystatus').touched || _submitted) && _countryform.get('countrystatus').errors?.SelectStatus">
                                    Please select country status
                                </span>
                            </td>
                        </tr>
                        
                    </table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>

