import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class GeneratebackupService {
  public _Action: IAction;
  public _httpOptions;
  public _GenerateBackupOfflineService = environment.GenerateBackupOfflineService;
  public _GenerateBackupOnlineService = environment.GenerateBackupOnlineService;
  constructor(private http: HttpClient) { }

  PageOnload(Backup:object) {
    var Query = this._GenerateBackupOnlineService+'/Pageload';
    console.log(Query);
    return this.http.post<{ data: any }>(Query, Backup);
  }
  CreateBackup(Backup:object) {
    var Query = this._GenerateBackupOfflineService + '/CreateBackup';
    console.log(Query);
    return this.http.post<{ data: any }>(Query, Backup);
  }
  UploadBackup(Backup:object) {
    var Query = this._GenerateBackupOfflineService + '/UploadBackup';
    console.log(Query);
    return this.http.post<{ data: any }>(Query, Backup);
  }
  // Create(Backup:FormData) {
  //   var Query = this._GenerateBackupOfflineService + '/CreateBackup';
  //   console.log(Query);
  //   return this.http.post<{ data: any }>(Query, Backup);
  // }
}
