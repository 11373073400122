import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';


@Injectable({
  providedIn: 'root'
})
export class RptstocktransferService {
  public _Action: IAction;
  public _httpOptions;
  public _RptStockTransferService = environment.RptStockTransferService;


  constructor(private http: HttpClient) { }

  PageOnload(branchid: number): Observable<any> {
    var Query = this._RptStockTransferService + '/PageInitialize/' + branchid;

    return this.http.get<{ data: any }>(Query);
  }

  getProductCategory(producttype: object) {
    var Query = this._RptStockTransferService + '/getProductCategories';

    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory: object) {
    var Query = this._RptStockTransferService + '/getProducts';

    return this.http.post<{ data: any }>(Query, productcategory);
  }
  getBrand(products: object) {
    var Query = this._RptStockTransferService + '/getBrand';

    return this.http.post<{ data: any }>(Query, products);
  }

  Print(stocktransferorder: object) {
    var Query = this._RptStockTransferService;

    return this.http.post(Query, stocktransferorder, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }
  STOPrint(stono: string, branchid: number) {
    var Query = this._RptStockTransferService + '/STO/' + stono + '/' + branchid;
    window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
  }

}
