import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Form, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { MessageService } from 'primeng/api';
import { Salesreturndetailview } from 'src/app/core/Models/SalesReturn';
import { Salesreturnproductdetailview } from 'src/app/core/Models/Salesreturnproductdetails';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Metadatum } from 'src/app/core/Models/metadatum';
//Debug
import * as ace from "ace-builds";
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { SalesreturnService } from 'src/app/core/Services/sales/salesreturn.service';
import { Salesinvoice } from 'src/app/core/Models/Salesinvoice';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { vCustomerName, vDateSelect, vInvoiceNoSelect } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { KeyPress } from 'src/assets/js/KeyPress';
import { Customer } from 'src/app/core/Models/Customer';
import { Customerview } from 'src/app/core/Views/Customerview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
	selector: 'app-c-salesreturn',
	templateUrl: './c-salesreturn.component.html',
	styleUrls: ['./c-salesreturn.component.css']
})
export class CSalesreturnComponent implements OnInit {
	//Form Creation
	_salesreturnform: FormGroup;

	//Drop Down
	_ProductNames: Productheader[];
	_Status: Metadatum;

	//Grid
	_Salesreturn: Salesreturndetailview;
	_ProductDetails: Salesreturnproductdetailview[] = [];
	_OtherCharges: Othercharge[] = [];
	_Descriptions: Othercharge[] = [];
	_ChargesTemp: Othercharge[] = [];
	salesreturnothercharges: Othercharge[] = [];
	_Invoicedetails: Salesinvoice[] = [];

	//Local Variable:
	_OtherBalance: number;
	displayBasic: boolean;
	minDate: Date;
	selectedProductDetailsRows: any;
	_selectedlevel: number;
	selectedOtherChargesRows: any;
	_salesinvoices: string[];
	_userid = sessionStorage["userid"];
	_branchid = sessionStorage["BranchID"];
	_action: string = "create";
	_SalesreturnObj: any;
	_salesreturnid: string;
	_submitted = false;
	_customersubmitted = false;
	isDisabledsave: boolean;
	isDisablededit: true;
	isDisabledreturnqty = false;
	isDisabledothercharges = false;
	isDisabledreset = false;
	isDisabledadd = false;
	isDisabledremove = false;
	_IsProgressSpinner: boolean = true;
	_validate: boolean;
	_Validatereason: boolean;
	isDisabledSendtoapproval = true;
	_salesreturnno: string;
	_salesreturnids: any;
	_Reasons: Metadatum;
	_Customers: Customerview[] = [];
	filteredInvoices: any;
	_Invoicenos: string;
	salesinvoiceno: any;
	salesinvoiceobj: any;
	VatType: string;
	isDisableReason: boolean;
	_CurrencyList: any[];
	issalesreturndisable = true;
	_Action: IAction;



	//Debug
	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, public keypress: KeyPress,
		private messageService: MessageService,
		private _CustomExceptionService: CustomExceptionService, private _SalesReturnService: SalesreturnService,
		private _hotkeysService: HotkeysService,
		private _AccessRightsService: AccessRightsService
	) {
		this.HotKeyIntegration();
	}
	InitializeSalesreturnForm() {
		this._salesreturnform = this.fb.group({
			companyid: [0],
			branchid: [],
			invoicebranchid: [],
			invoicebranchname: [],
			salesreturnid: [0],
			salesreturndate: ['', vDateSelect],
			salesinvoiceno: [],
			salesinvoiceid: [],
			customerid: [, vCustomerName],
			customername: [],
			currencyid: [],
			currencyname: [],
			salesinvoiceamount: [],
			salesreturnamount: [],
			salesreturngrossamount: [],
			salesreturndeductionamount: [],
			salesreturnrewardpoints: [],
			taxamount: [],
			discountamount: [],
			status: [],
			statusremarks: [],
			accountingyear: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			salesreturnno: [],
			salereturntype: [],
			series: [],
			currentcurrencyid: [],
			currencyreferenceid: [],
			amountinusd: [],
			narration: [],
			otherchargesamount: [0],
			transactionincompleted: [1],

		});
		this._salesreturnform.controls['salesreturndate'].setValue(new Date());
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(57);
		this.utility.pageLoadScript();
		this.InitializeSalesreturnForm();
		this._salesreturnid = history.state?.salesreturnno ? history.state?.salesreturnno : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this._IsProgressSpinner = true;
		this._SalesReturnService.PageOnLoad(this._salesreturnid, this._userid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._salesreturnform.controls['salesreturndate'].setValue(new Date());
			this._Descriptions = resultJSON.othercharges;
			this._OtherCharges = resultJSON.othercharges;
			this._ChargesTemp = resultJSON.othercharges;
			this._Reasons = resultJSON.returnReasonList;
			this._Customers = resultJSON.customers;
			this._IsProgressSpinner = false;
			if (this._action == 'edit' || this._action == 'view') {
				const updateJSON = JSON.parse(JSON.stringify(result));
				this._Invoicedetails = resultJSON.invoicenoview;
				updateJSON.salesreturndetailviews[0].salesreturndate = new Date(updateJSON.salesreturndetailviews[0].salesreturndate);
				this._salesreturnform.setValue(updateJSON.salesreturndetailviews[0]);
				this.salesinvoiceobj = (this._Invoicedetails || []).find(f => f.salesinvoiceno == updateJSON.salesreturndetailviews[0].salesinvoiceno);
				var customername = resultJSON.customers.find(f => f.customerid == updateJSON.salesreturndetailviews[0].customerid);
				this._salesreturnform.get("customerid").setValue(customername);
				this.salesreturnothercharges = updateJSON.salesreturnothercharges;
				this._ProductDetails = updateJSON.salesreturnproductdetailviews;
				this._IsProgressSpinner = false;
				var othercharge = this._salesreturnform.get("otherchargesamount").value;
				if (othercharge > 0) {
					this.issalesreturndisable = false;
				}
				else {
					this.issalesreturndisable = true;
				}
			}
			if (this._action == 'view') {
				this.isDisableReason = true;
				this._salesreturnform.disable();
				this.isDisabledsave = true;
				this.isDisabledreturnqty = true;
				this.isDisabledothercharges = true;
				this.isDisabledreset = true;
				this.isDisabledadd = true;
				this.isDisabledremove = true;
				this._IsProgressSpinner = false;
			}
			else {
				this.isDisabledsave = false;
				this.isDisablededit = true;
			}
			if (this._action == 'edit') {
				this.isDisablededit = true;
			}
			if (this._action == 'create') {
				var l_othercharge = new Othercharge();
				l_othercharge.otherchargeid = 0;
				l_othercharge.otherchargeamount = 0;
				l_othercharge.createdby = sessionStorage["userid"];
				l_othercharge.modifiedby = sessionStorage["userid"];
				l_othercharge.createdon = new Date();
				l_othercharge.modifiedon = new Date();
				this.salesreturnothercharges.push(l_othercharge);
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	GetInvoices(event) {
		this._salesreturnform.controls['salesinvoiceid'].reset();
		this._salesreturnform.controls['invoicebranchname'].reset();
		this._salesreturnform.controls['currencyname'].reset();
		this._ProductDetails = [];
		this.salesreturnothercharges = [];
		var l_othercharge = new Othercharge();
		l_othercharge.otherchargeid = 0;
		l_othercharge.otherchargeamount = 0;
		l_othercharge.createdby = sessionStorage["userid"];
		l_othercharge.modifiedby = sessionStorage["userid"];
		l_othercharge.createdon = new Date();
		l_othercharge.modifiedon = new Date();
		this.salesreturnothercharges.push(l_othercharge);
		this._salesreturnform.controls['salesreturngrossamount'].reset();
		this._salesreturnform.controls['salesreturndeductionamount'].reset();
		this._salesreturnform.controls['salesreturnamount'].reset();
		var selectedcustomers = event.value;
		this._IsProgressSpinner = true;
	}
	getcustomer(event) {
		this._Invoicenos = ""
		this.salesinvoiceobj = ""
		this._ProductDetails = []
		this._salesreturnform.get("salesinvoiceamount").reset()
		this._salesreturnform.get("invoicebranchname").reset()
		this._salesreturnform.get("currencyname").reset()
		if (event.query == "" || event.query == undefined) {
			return;
		}
		else {
			let data = {
				"userid": parseInt(sessionStorage["userid"]),
				"text": event.query
			}
			this._IsProgressSpinner = true;
			this._SalesReturnService.GetCustomer(data).subscribe((result: any) => {
				this._IsProgressSpinner = false;
				if (result && result.tranStatus.result) {
					this._Customers = result.customers;
				} else { this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message); this._IsProgressSpinner = false; }
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	filterInvoices(event) {
		let filtered: any[] = [];
		this._Invoicenos = "";
		this._ProductDetails = []
		let query = (<string>event.query).toLowerCase();
		let customerid = this._salesreturnform.controls['customerid'].value.customerid
		if (!customerid) return;

		var roleid = sessionStorage["currentRole"]

		let obj = {
			customerid: customerid,
			userid: this._userid,
			query: query,
			roleid: roleid

		};

		this._SalesReturnService.GetInvoiceNos(obj).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._Invoicedetails = <Salesinvoice[]>resultJSON.invoicenoview || [];
			this.VatType = resultJSON.vattype;
			this.filteredInvoices = [...this._Invoicedetails];
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	OnInvoiceChange(event) {
		var invoiceno = event.salesinvoiceno;
		this._Invoicenos = event.salesinvoiceno;
		if (invoiceno == 0 || invoiceno == null) {

			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._SalesReturnService.GetInvoicedetails(invoiceno, this._userid).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._Salesreturn = resultJSON.salesreturndetailviews;
				this._salesreturnform.controls['invoicebranchid'].setValue(this._Salesreturn[0].invoicebranchid);
				this._salesreturnform.controls['invoicebranchname'].setValue(this._Salesreturn[0].invoicebranchname);
				this._salesreturnform.controls['currencyid'].setValue(this._Salesreturn[0].currencyid);
				this._salesreturnform.controls['currentcurrencyid'].setValue(this._Salesreturn[0].currencyid);
				this._salesreturnform.controls['currencyname'].setValue(this._Salesreturn[0].currencyname);
				this._salesreturnform.controls['salesinvoiceamount'].setValue(this._Salesreturn[0].salesinvoiceamount);
				this._ProductDetails = resultJSON.returnproductdetails;
				this._CurrencyList = resultJSON.currencyreference;
				var currencyid = this._salesreturnform.get("currencyid").value;
				var currencyreferenceid = this._CurrencyList.filter(f => f.currencyid == currencyid)[0].versionid;
				this._salesreturnform.controls['currencyreferenceid'].setValue(currencyreferenceid);
				this._IsProgressSpinner = false;
				this._ProductDetails = this._ProductDetails.filter(r => r.invoicequantity > 0);
				if (this._ProductDetails.length > 0) {
					this._ProductDetails.forEach(element => {
						if (element.returnreason == "MSC00226") { //Damaged
							this.isDisableReason = true;
						} else {
							this.isDisableReason = false;
						}
					});
				}
				let invoicebranch = parseInt(this._Salesreturn[0].invoicebranchid || 0);
				let currentbranch = parseInt(sessionStorage["currentbranchid"] || 0);
				if (invoicebranch !== currentbranch) {
					this._CustomExceptionService.handleWarning("Your doing another branch invoice return.");
				}
				if (this._Salesreturn[0].otherchargesamount > 0) {
					this.issalesreturndisable = false;
				}
				else {
					this.issalesreturndisable = true;
				}
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}

	}

	//Other Charges:
	AddOtherCharges(event) {
		let otherChargesExists = (this.salesreturnothercharges.filter(s => s.otherchargeid > 0).length == this.salesreturnothercharges.length) && this.salesreturnothercharges.some(s => s.otherchargeid > 0);
		if (!otherChargesExists)
			return;
		var l_othercharge = new Othercharge();
		l_othercharge.otherchargeid = 0;
		l_othercharge.otherchargeamount = 0;
		l_othercharge.createdby = sessionStorage["userid"];
		l_othercharge.modifiedby = sessionStorage["userid"];
		l_othercharge.createdon = new Date();
		l_othercharge.modifiedon = new Date();
		this.salesreturnothercharges.push(l_othercharge);
	}
	//Remove Other Charges:
	RemoveOtherCharges(pOthercharges: Othercharge) {
		// if (this.salesreturnothercharges.length > 1) {
		var index = this.salesreturnothercharges.indexOf(pOthercharges);
		this.salesreturnothercharges.splice(index, 1)
		this.CalculateotherCharge();
		if (this.salesreturnothercharges.length <= 0) {
			var l_othercharge = new Othercharge();
			l_othercharge.otherchargeid = 0;
			l_othercharge.otherchargeamount = 0;
			l_othercharge.createdby = sessionStorage["userid"];
			l_othercharge.modifiedby = sessionStorage["userid"];
			l_othercharge.createdon = new Date();
			l_othercharge.modifiedon = new Date();
			this.salesreturnothercharges.push(l_othercharge);
		}
	}

	OnChangeotherCharge(row: any, event) {
		let OtherChargesAmount = row.otherchargeamount;
		this._salesreturnform.controls['salesreturndeductionamount'].setValue(OtherChargesAmount.toFixed(3));
		this.CalculateotherCharge();
	}
	//Rule18:if invoice no dropdown selected value from sales ordertable then sales orderno column  assigned to referno  in grid based on invoiceno selection
	OnchangeOtherCharge(pOtherCharges: Othercharge, event: any) {
		let isAlreadyExists = this.salesreturnothercharges.filter(s => s.otherchargeid == event.value).length > 1;
		if (isAlreadyExists) {
			this._CustomExceptionService.handleWarning("Other Charges Already Exists!");
			var index = this.salesreturnothercharges.indexOf(pOtherCharges);
			this.salesreturnothercharges.splice(index, 1)
			return;
		}
		var otherchargeamount = (this._ChargesTemp || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount;
		pOtherCharges.otherchargeamount = otherchargeamount;
		this.CalculateotherCharge();
	}

	// Return Quantity Based Calculation:
	allocatequantity(row: any, event) {
		var selectedindex = this._ProductDetails.findIndex(obj => obj == row);
		//Product Amount calculation:
		if (this._ProductDetails[selectedindex].invoicequantity >= this._ProductDetails[selectedindex].returnquantity) {
			var productamnt = row.returnquantity * this._ProductDetails[selectedindex].unitprice;
			this._ProductDetails[selectedindex].productamount = productamnt;
			var discount = (this._ProductDetails[selectedindex].productamount / 100) * row.discountpercentage;
			this._ProductDetails[selectedindex].discountamount = discount;
			var productamountwithdiscount = productamnt - discount;
			var taxamount = (productamountwithdiscount / 100) * row.taxpercentage;
			if (this.VatType == "MSC00062") {
				taxamount = (this._ProductDetails[selectedindex].temptaxamount / row.invqty) || 0;
			}
			this._ProductDetails[selectedindex].taxamount = taxamount;
			if (this.VatType == "MSC00063") { //exclude
				this._ProductDetails[selectedindex].totalamount = productamountwithdiscount + taxamount;

			} else if (this.VatType == "MSC00062" || this.VatType == "") { //include
				this._ProductDetails[selectedindex].totalamount = productamountwithdiscount;
				this._ProductDetails[selectedindex].taxamount = taxamount * row.returnquantity;
			} else {
				this._ProductDetails[selectedindex].taxamount = 0;
				this._ProductDetails[selectedindex].totalamount = productamountwithdiscount;
			}
			var _FinalTotalAmount = 0
			for (var i = 0; i < this._ProductDetails.length; i++) {
				_FinalTotalAmount += this._ProductDetails[i].totalamount;
			}
			this._salesreturnform.controls['salesreturngrossamount'].setValue(_FinalTotalAmount.toFixed(3));
			var salesreturndeductionamount = this._salesreturnform.get("salesreturndeductionamount").value;
			var salesreturnamount = parseFloat(_FinalTotalAmount + '') + parseFloat(salesreturndeductionamount + '');
			this._salesreturnform.controls['salesreturnamount'].setValue(salesreturnamount.toFixed(3));
			// });
			this.CalculateotherCharge();
		}
		else {

			//Rule19:if return qty is exceeded to sales qty then show error as "return qty should not exceeded to sales qty"
			this._ProductDetails[selectedindex].returnquantity = 0;
			this.allocatequantity(this._ProductDetails[selectedindex], event);
			this._CustomExceptionService.handleWarning("Return Quantity Should not greater than Invoice Quantity");
			this.CalculateotherCharge();
		}

	}

	CalculateotherCharge() {
		this._OtherBalance = 0;
		// this.salesreturnothercharges.every((item) => {
		for (var i = 0; i < this.salesreturnothercharges.length; i++) {
			this._OtherBalance += this.salesreturnothercharges[i].otherchargeamount;
		}
		this._salesreturnform.controls['salesreturndeductionamount'].setValue(this._OtherBalance.toFixed(3));
		// });
		var _FinalTotalAmount = 0
		var _FinalTaxTotalAmount = 0
		var _FinalDiscountTotalAmount = 0
		var amountinusd = 0
		// this._ProductDetails.every((item) => {
		for (var i = 0; i < this._ProductDetails.length; i++) {
			_FinalTotalAmount += this._ProductDetails[i].totalamount;
			_FinalTaxTotalAmount += this._ProductDetails[i].taxamount;
			_FinalDiscountTotalAmount += this._ProductDetails[i].discountamount;
		}
		this._salesreturnform.controls['salesreturngrossamount'].setValue(_FinalTotalAmount.toFixed(3));
		this._salesreturnform.controls['taxamount'].setValue(_FinalTaxTotalAmount.toFixed(3));
		this._salesreturnform.controls['discountamount'].setValue(_FinalDiscountTotalAmount.toFixed(3));
		var salesreturndeductionamount = this._salesreturnform.get("salesreturndeductionamount").value;

		//var salesreturnamount = parseFloat(_FinalTotalAmount + '') + parseFloat(salesreturndeductionamount + '');
		//Changes by Paaventhan on 16-08-2022
		var salesreturnamount = parseFloat(_FinalTotalAmount + '') - parseFloat(salesreturndeductionamount + '');

		if (salesreturnamount < 0) {
			this._CustomExceptionService.handleError("Net Amount Should Not Allow Negative Value");
		}
		//

		this._salesreturnform.controls['salesreturnamount'].setValue(salesreturnamount.toFixed(3));
		// });
		//Calculate amount in usd
		var currencyid = this._salesreturnform.get("currencyid").value;
		var usercurrencyexchangerate = this._CurrencyList.filter(f => f.currencyid == currencyid)[0].exchangerate;
		amountinusd = salesreturnamount / usercurrencyexchangerate;
		this._salesreturnform.controls['amountinusd'].setValue(amountinusd);
	}
	onSave() {
		this._submitted = true;
		if (this._salesreturnform.valid) {
			this._Salesreturn = this._salesreturnform.value;
			this._Salesreturn.customerid = this._salesreturnform.get("customerid").value.customerid;
			this._Salesreturn.salesinvoiceno = this._Invoicenos;
			if (!this._Salesreturn.salesinvoiceno || this._Salesreturn.salesinvoiceno == "") {
				this._CustomExceptionService.handleError("Please select Invoice Number");
				return;
			}
			var ReturnQuantity = this._ProductDetails.some(s => s.returnquantity > 0);
			if (!ReturnQuantity) {
				this._CustomExceptionService.handleError(usererrors.SalesReturnQuantity);
				return;
			}
			var ReturnReason = this._ProductDetails.some(s => s.returnquantity > 0 && !s.returnreason);
			if (ReturnReason) {
				this._CustomExceptionService.handleError(usererrors.SalesReturnReason);
				return;
			}
			this._ProductDetails = this._ProductDetails.filter(r => r.returnquantity > 0);
			var netamount = this._salesreturnform.get("salesreturnamount").value;// Paaventhan Added on 16-08-2022
			if (netamount < 0) {
				this._CustomExceptionService.handleError("Net Amount Should Not Allow Negative Value");
				return;
			}
			this._Salesreturn.companyid = 0;
			this._Salesreturn.createdby = sessionStorage["userid"];
			this._Salesreturn.createdon = new Date();
			this._Salesreturn.modifiedby = sessionStorage["userid"];
			this._Salesreturn.modifiedon = new Date();
			this._Salesreturn.accountingyear = new Date().getFullYear();
			this._Salesreturn.branchid = sessionStorage["BranchID"];
			this._Salesreturn.status = "MSC00045";
			let userid = sessionStorage["userid"];
			var RequestDate = this._salesreturnform.get("salesreturndate").value;
			var datePipe = new DatePipe("en-US");
			var pRequestDate = datePipe.transform(RequestDate, 'yyyy-MM-dd');
			this._Salesreturn.salesreturndate = (new Date(pRequestDate));
			this._SalesreturnObj = {
				"SalesReturn": this._Salesreturn,
				"SalesReturndetails": this._ProductDetails,
				"SalesReturnothercharges": this.salesreturnothercharges
			};
			this._IsProgressSpinner = true;
			this._SalesReturnService.create(this._SalesreturnObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
					this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
					this._IsProgressSpinner = false;
					this.isDisabledSendtoapproval = false;
					this.isDisabledsave = true;
					this._salesreturnno = resultJSON.salesreturnno;
					this._salesreturnids = resultJSON.salesreturnid;

					var Url = window.open("salesreturn-printer-service:$" + this._salesreturnids + "$" + this._salesreturnno + "$" + userid);
					setTimeout(() => {
						Url.close();
					}, 10000);

					this.reset(null);
				} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); }
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	goBack(event) {
		this._router.navigate(['/vSalesreturn']);
	}
	reset(event) {
		this._submitted = true;
		this._salesreturnform.reset();
		this.InitializeSalesreturnForm();
		this._submitted = false;
		this._ProductDetails = [];
		this.salesreturnothercharges = [];
		var l_othercharge = new Othercharge();
		l_othercharge.otherchargeid = 0;
		l_othercharge.otherchargeamount = 0;
		l_othercharge.createdby = sessionStorage["userid"];
		l_othercharge.modifiedby = sessionStorage["userid"];
		l_othercharge.createdon = new Date();
		l_othercharge.modifiedon = new Date();
		this.salesreturnothercharges.push(l_othercharge);
		this.isDisabledsave = false;
		this.isDisabledSendtoapproval = true;
		this.salesinvoiceobj = [];
	}

	SendToApproval(event) {
		this._IsProgressSpinner = true;
		this._SalesReturnService.SendToApproval(this._userid, this._branchid, this._salesreturnno).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
			this.isDisabledSendtoapproval = true;
			this.isDisabledsave = false;
			this.reset(null);
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	HotKeyIntegration() {
		this._hotkeysService.reset();
		// if (this._action != 'view') {
		//   this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
		//     this.onSave();
		//     return false; // Prevent bubbling
		//   }, ['INPUT', 'SELECT', 'TEXTAREA']));
		// }
		if (this._action == 'create') {
			this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
				this.reset(null);
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
			this.goBack(null);
			return false; // Prevent bubbling
		}, ['INPUT', 'SELECT', 'TEXTAREA']));
	}
	onChangeCustomer() {
		this._ProductDetails = [];
		this._salesreturnform.controls['invoicebranchid'].setValue(0);
		this._salesreturnform.controls['invoicebranchname'].setValue("");
		this._salesreturnform.controls['currencyid'].setValue(0);
		this._salesreturnform.controls['currentcurrencyid'].setValue(0);
		this._salesreturnform.controls['currencyname'].setValue("");
		this._salesreturnform.controls['salesinvoiceamount'].setValue(0);
		this.salesinvoiceobj = [];
		this._salesreturnform.controls['narration'].setValue("");
	}
}
