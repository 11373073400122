import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAction } from '../security/IActions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
@Injectable({
	providedIn: 'root'
})
export class ProductService {
	public _Action: IAction;
	public _ProductService = environment.ProductService;
	public _httpOptions;
	token: any;
	header: any;

	constructor(private http: HttpClient) {
		this.token = sessionStorage['AuthToken'];
		this.header = this.createHeaders(this.token, 1)
	}
	createHeaders(token: any, type: number): HttpHeaders {
		return new HttpHeaders({
			Authorization: `Bearer ${token}`, // Include your token here	
		});
	}
	PageOnLoad(p_Productid: number): Observable<any> {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/' + p_Productid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	FetchAll(obj: any) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/FetchAll';
		return this.http.post<{ data: any }>(Query, obj, { headers });
	}
	create(p_Product: FormData) {
		const headers = this.createHeaders(this.token, 2)
		console.log("Create");
		var Query = this._ProductService;
		return this.http.post<{ data: any }>(Query, p_Product, { headers });
	}
	edit(p_Product: FormData) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService;
		return this.http.put<{ data: any }>(Query, p_Product, { headers });
	}
	getProductCategory(categoryid: string) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/getProductCategory/' + categoryid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	getSupplier(supplierid: string) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/getSupplier/' + supplierid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	getPriceHistory(obj: object) {
		const headers = this.header;
		var Query = this._ProductService + '/getPriceHistory';
		return this.http.post(Query, obj, { headers });
	}
	changestatus(productid: number, p_userid: number, branchid: number) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/ChangeStatus/' + productid + '/' + p_userid + '/' + branchid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetProduct(product: object) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/GetProduct';
		return this.http.post<{ data: any }>(Query, product, { headers });
	}
	GetFAQ(productid: number) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/getFAQ/' + productid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetProductmappedlist(type: number) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/GetProductmappedlist/' + type;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	createFAQ(productfaq: object) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/ProductFAQCreate';
		return this.http.post<{ data: any }>(Query, productfaq, { headers });
	}
	UpdateFAQ(productfaq: object) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/ProductFAQUpdate';
		return this.http.put<{ data: any }>(Query, productfaq, { headers });
	}

	GetReview(productid: number) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/Reviewfetch/' + productid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	createReview(productreview: object) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/ProductReviewRatingsCreate';
		return this.http.post<{ data: any }>(Query, productreview, { headers });
	}
	UpdateReview(productreview: object) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/ProductReviewRatingsUpdate';
		return this.http.put<{ data: any }>(Query, productreview, { headers });
	}
	SearchAllProducts(obj: any) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/SearchAllProducts';
		return this.http.post<{ data: any }>(Query, obj, { headers });
	}
	getSupplierMappedproducts(obj: any) {
		const headers = this.createHeaders(this.token, 2)
		var Query = this._ProductService + '/getSupplierMappedproducts';
		return this.http.post<{ data: any }>(Query, obj, { headers });
	}

}