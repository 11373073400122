import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { JobcardService } from 'src/app/core/Services/workshop/jobcard.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
@Component({
	selector: 'app-v-job-card',
	templateUrl: './v-job-card.component.html',
	styleUrls: ['./v-job-card.component.css']
})
export class VJobCardComponent implements OnInit {
	_Action: IAction;
	selectedrows: any;
	_jobcardviews: any[] = []
	_IsProgressSpinner: boolean = true;
	fromdate: Date;
	todate: Date;
	datepipe = new DatePipe('en-US');
	_branchid: number;
	cols: any[];
	_roleid = sessionStorage["currentRole"]
	_userid = sessionStorage["userid"];
	mindate: Date;
	maxdate: Date;
	constructor(
		private _CustomExceptionService: CustomExceptionService,
		private _jobcardService: JobcardService,
		private router: Router,
		private utility: CommonUtilities,
		private _AccessRightsService: AccessRightsService,
	) { }
	ngOnInit(): void {
		this.fromdate = new Date();
		this.todate = new Date();
		this._Action = this._AccessRightsService.getAccessRights(330);
		this.utility.pageLoadScript();
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.SetFromToDate();
		this._IsProgressSpinner = false;
		this.cols = [
			{ field: 'purchaseorderno', header: 'Purchase order Code' },
			{ field: 'purchaseorderdate', header: 'Purchase order Date' },
			{ field: 'suppliername', header: 'Supplier Name' },
			{ field: 'totalpoamount', header: 'Total Amount' },
			{ field: 'statusame', header: ' Status' },
			{ field: 'createdbyname', header: 'Created By' },
			{ field: 'createdon', header: 'Created On' },
		]
		this.Fetchall();
	}
	SetFromToDate(){
		if(sessionStorage['Environmentenddate'] == 'null'){
			this.fromdate = new Date();
			this.todate = new Date();
			this.maxdate = new Date();
		}
		else{
			this.fromdate = new Date(sessionStorage['Environmentenddate']);
			this.todate = new Date(sessionStorage['Environmentenddate']);
		}
		this.fromdate.setDate(this.fromdate.getDate() - 1);
	}
	Fetchall() {
		if (!this.fromdate) {
			this._CustomExceptionService.handleError("Please Select From Date");
			return;
		}
		if (!this.todate) {
			this._CustomExceptionService.handleError("Please Select To Date");
			return;
		}
		let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
		let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
		this._branchid = parseInt(sessionStorage["currentbranchid"]);
		if (fromdate > todate) {
			this._CustomExceptionService.handleError("From Date should be less than the To Date");
			return;
		}
		let data = {
			"fromdate": fromdate,
			"todate": todate,
			"branchid": this._branchid
		}
		this._IsProgressSpinner = true;
		this._jobcardService.FetchAll(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this._jobcardviews = resultJSON.jobCardView;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	view(event) {
		this.router.navigate(['/CJobCard'], { state: { jobcardid: event.jobcardid, action: 'view' } });
	}
	edit(event) {
		this.router.navigate(['/CJobCard'], { state: { jobcardid: event.jobcardid, action: 'edit' } });
	}
	GoToCreate(event) {
		this.router.navigate(['/CJobCard']);
	}
	confirm(event) {
		let data = {
			"JobCardId": event.jobcardid
		}
		this._IsProgressSpinner = true;
		this._jobcardService.Delete(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			this.Fetchall();
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	Print(event) {
		this._IsProgressSpinner = true;
		this._jobcardService.Print({ "JobCardId": event.jobcardid, "BranchId": this._branchid }).subscribe((result: any) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			let url: any = URL.createObjectURL(result);
			window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
}