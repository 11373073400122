import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productheader } from 'src/app/core/Models/Productheader';
import { products, varient } from 'src/app/core/Models/product-master';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Branch } from 'src/app/core/Models/Branch';
import { vBranchName, vProductName, vQuoteFinalizeDate } from 'src/app/core/Validators/validation';
import { QuotefinalizeService } from 'src/app/core/Services/Purchase/quotefinalize.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Quotesupplierdetailsview } from 'src/app/core/Models/Quotefinalizesupplierdetails';
import { Quotefinlizedview } from 'src/app/core/Views/Quotefinalizeview';
//Debug
import * as ace from "ace-builds";
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';


@Component({
  selector: 'app-c-quotefinalize',
  templateUrl: './c-quotefinalize.component.html',
  styleUrls: ['./c-quotefinalize.component.css']
})
export class CQuotefinalizeComponent implements OnInit {

  // Form Gropu Declaration:
  _quotefinalizeform: FormGroup;

  // List Declaration:
  _productname: Productoverallview[];
  _productdetails: Productoverallview[];
  _product: Productoverallview;
  _recordstatus: Metadatum[];
  _branches: Branch[];
  _quotesupplierdetails: Quotesupplierdetailsview[] = [];
  _quotesupplierdetail: Quotesupplierdetailsview;


  //Local Variable Declaration:
  selectedsupplierDetailsRows: Quotesupplierdetailsview[] = [];
  _action: string = "create";
  _quotefinalizeid: number;
  _submitted = false;
  _productid: any;
  _branchid = sessionStorage["currentbranchid"]
  _DamagedproductOBJ: any;
  // Local Variable Creation:
  supplierid: any;
  _QuotefinalizeOBJ: any;
  _IsProgressSpinner: boolean = true;
  _userid = sessionStorage["userid"];
  _quoterequestid: number;
  isDisabledSendtoapproval: boolean = true;
  _quoterequestno: number;
  isdefault: boolean;
  selectedrows: Quotesupplierdetailsview[] = [];
  isDisabledSave: boolean = false;
  isDisabledClear: boolean = false;
  _supplierquotesno: number
  filteredProducts: any[];
  _SupplierQuotesno: string
  isDisabledcheckbox: boolean = false
  _Action: IAction
  mindate: Date;
  maxdate: Date;
  // _productdetails
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private _QuotefinalizeService: QuotefinalizeService,
    private _AccessRightsService: AccessRightsService,
    private fb: FormBuilder, private _router: Router, private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService) {
    this._productid = history.state?.productid ? history.state?.productid : 0;
    this._supplierquotesno = history.state?.supplierquotesno ? history.state?.supplierquotesno : 0;

    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._quotefinalizeform = this.fb.group({
      companyid: [0],
      productid: ['', vProductName],
      quotefinalizeddate: ['', vQuoteFinalizeDate]
    });
    if (sessionStorage['Environmentenddate'] == "null") {
      this._quotefinalizeform.controls['quotefinalizeddate'].setValue(new Date());
      this.maxdate = new Date();
    }
    else {
      this._quotefinalizeform.controls['quotefinalizeddate'].setValue(new Date(sessionStorage['Environmentenddate']));
    }
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(39);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    //To Avoid BlankScreen On Pageload Below Line Used:
    this.utility.pageLoadScript();
    this.InitializeForm();
    // Form Creation:

    // Page Load Service Call:
    //For Page Load
    //this._productid, 
    this._IsProgressSpinner = true;
    this._QuotefinalizeService.PageOnload(this._supplierquotesno, this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // this._quotefinalizeform.controls['quotefinalizeddate'].setValue(new Date());
      this._productdetails = <Productoverallview[]>resultJSON.productoverallList;
      this.selectedsupplierDetailsRows = resultJSON.quotefinalizefetch;

      this._IsProgressSpinner = false;
      //For View:
      if (this._action == 'view') {
        this._quotefinalizeform.disable();
        this.isDisabledSendtoapproval = true;
        this.isDisabledSave = true;
        this.isDisabledClear = true;
        this.isDisabledcheckbox = true

      }
      // For Update And View:
      if (this._action == 'edit' || this._action == 'view') {
        this.filteredProducts = this._productdetails.filter(f => f.productid == this._productid);
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._quotesupplierdetails = resultJSON.quoterequestupdate;
        this.selectedsupplierDetailsRows = (resultJSON.quoterequestupdate || []).filter(f => f.isselectedquote == true);
        this._quotefinalizeform.controls['productid'].setValue(this._productdetails.filter(f => f.productid == this._productid)[0]);
        this._IsProgressSpinner = false;
        //this._quotefinalizeform.disable();
        this._quotefinalizeform.controls['quotefinalizeddate'].disable();
        this._quotefinalizeform.controls['productid'].disable();


      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  // Get Quote:
  //Rule:9-->if productcode checkbox selection then grid details(quotedate,quoteno,materialname ,suppliername,uom,qty) should fetch from requestforquote table and supplier table
  OnProductNameChange(event) {
    var selectedproduct = event.productid;
    this._IsProgressSpinner = true;
    this._QuotefinalizeService.getQuoteDetails(selectedproduct).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._quotesupplierdetails = resultJSON.quoterequests;
      this.selectedsupplierDetailsRows = resultJSON.quotefinalizefetch;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  changeunitprice(event: Quotesupplierdetailsview) {
    var i = event.unitprice;
    if (event.unitprice <= 0) {
      this._CustomExceptionService.handleWarning("Unit price should be greater than 0.");
      event.unitprice = 0;
      //this._quotesupplierdetail.unitprice = 0;
    }
  }
  //Save Function Call:
  onSave() {
    this._submitted = true;
    if (this._quotefinalizeform.valid && !this.isDisabledSave) {
      if (this._quotesupplierdetails.length > 0) {
        let selectedquote = this._quotesupplierdetails.filter(q => q.isselectedquote == true)
        if (selectedquote.length > 0) {
          for (var i = 0; i < selectedquote.length; i++) {
            if (selectedquote[i].unitprice <= 0) {
              this._CustomExceptionService.handleWarning("Unit price should be greater than 0.");
              return;
            }
          }


          if (this._action == 'create') {
            Object.keys(this._quotesupplierdetails).map((Index) => {
              this._quotesupplierdetails[Index].supplierquotesid = 0;
              this._quotesupplierdetails[Index].companyid = 0;
              this._quotesupplierdetails[Index].productid = this._quotefinalizeform.get("productid").value.productid;
              this._quotesupplierdetails[Index].quotefinalizeddate = this._quotefinalizeform.get("quotefinalizeddate").value;
              this._quotesupplierdetails[Index].branchid = sessionStorage["BranchID"];
              this._quotesupplierdetails[Index].totalamount = this._quotesupplierdetails[Index].quantity * this._quotesupplierdetails[Index].unitprice;
              //this.selectedsupplierDetailsRows[Index].unitprice = this._quotesupplierdetails[Index].unitprice;
              this._quotesupplierdetails[Index].createdon = new Date();
              this._quotesupplierdetails[Index].createdby = sessionStorage["userid"];
              this._quotesupplierdetails[Index].modifiedby = sessionStorage["userid"];
              this._quotesupplierdetails[Index].modifiedon = new Date();
              this._quotesupplierdetails[Index].accountingyear = new Date().getFullYear();
              this._quotesupplierdetails[Index].accountingyear = new Date().getFullYear();

              this.selectedsupplierDetailsRows.filter(f => f.isselectedquote == true);
            });
            this._QuotefinalizeOBJ = {
              "Supplierquotes": this._quotesupplierdetails
            };
            //this.onEd();
            this._IsProgressSpinner = true;
            this._QuotefinalizeService.create(this._QuotefinalizeOBJ).subscribe((result) => {
              const resultJSON = JSON.parse(JSON.stringify(result));
              this._quoterequestno = resultJSON.quoteFinalizeId;
              this._SupplierQuotesno = resultJSON.supplierquotesno;
              //Rule23:after saving the record, get the response A, display the success message as "quote finalize A  registered Succesfully "
              if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
                this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
                this.isDisabledSendtoapproval = false
                this.isDisabledSave = true
                this._IsProgressSpinner = false;
              }
              else {
                this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
              }
              this._IsProgressSpinner = false;
            },
              error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
          }

          else if (this._action == 'edit') {

            Object.keys(this._quotesupplierdetails).map((Index) => {
              // this._quotesupplierdetails[Index].supplierquotesid =0;
              this._quotesupplierdetails[Index].companyid = 0;
              this._quotesupplierdetails[Index].productid = this._quotefinalizeform.get("productid").value.productid;
              this._quotesupplierdetails[Index].quotefinalizeddate = this._quotefinalizeform.get("quotefinalizeddate").value;
              this._quotesupplierdetails[Index].branchid = sessionStorage["BranchID"];
              this._quotesupplierdetails[Index].totalamount = this._quotesupplierdetails[Index].unitprice * this._quotesupplierdetails[Index].quantity;
              this._quotesupplierdetails[Index].createdon = new Date();
              this._quotesupplierdetails[Index].createdby = sessionStorage["userid"];
              this._quotesupplierdetails[Index].modifiedby = sessionStorage["userid"];
              this._quotesupplierdetails[Index].modifiedon = new Date();
              this._quotesupplierdetails[Index].accountingyear = new Date().getFullYear();
              this.selectedsupplierDetailsRows.filter(f => f.isselectedquote == true);
            });
            this._QuotefinalizeOBJ = {
              "Supplierquotes": this._quotesupplierdetails
            };
            this._IsProgressSpinner = true;

            this._QuotefinalizeService.create(this._QuotefinalizeOBJ).subscribe((result) => {
              const resultJSON = JSON.parse(JSON.stringify(result));
              this._quoterequestno = resultJSON.quoteFinalizeId;
              this._SupplierQuotesno = resultJSON.supplierquotesno
              if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
                this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
                this.isDisabledSave = true
                this.isDisabledSendtoapproval = false
                this._IsProgressSpinner = false;
              }
              else {
                this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
              }
              this._IsProgressSpinner = false;
            },
              error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
          }
        }
        else {
          this._CustomExceptionService.handleWarning("Please select Atleast One Supplier Quote");
          return;
        }
      }
      else {
        this._CustomExceptionService.handleWarning("Please select Atleast One Supplier Quote");
        return;
      }
    }
  }
  //Form Reset:
  reset(event) {
    this._quotefinalizeform.reset();
    this._quotesupplierdetails = [];
    this.InitializeForm();
  }
  //Search Screen Call:
  goBack(event) {
    this._router.navigate(['/vQuotefinalize']);
  }
  //selected rows
  //int WorkFlowId, int UserId, int BranchId, int Quoterequestid
  SendToApproval(event) {
    var workflowid = 4;
    this._IsProgressSpinner = true;
    //this._quoterequestid = this._quotesupplierdetails[0].quoterequestid;
    this._QuotefinalizeService.SendToApproval(sessionStorage["userid"], this._branchid, this._quoterequestno, this._SupplierQuotesno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.isDisabledSendtoapproval = true;
      this._CustomExceptionService.handleSuccess(usererrors.Quote_Approval_01);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
    event.data.isdisable = false;
    this.isdefault = false;
    //this.selectedrows = Object.assign({}, this.selectedrows);
  }
  onRowUnselect(event) {
    event.data.isdisable = true;
    // event.data.unitprice = false;
    this.isdefault = false;
    //this.selectedrows = Object.assign({}, this.selectedrows);
  }
  //Debug
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._QuotefinalizeOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }


  filterProducts(event) {
    if (event.query == "" || event.query == undefined) {
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._QuotefinalizeService.GetProduct(event.query, this._branchid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productname = <Productoverallview[]>resultJSON.productsearchlist || [];
        let filtered: any[] = [];
        let query = (<string>event.query).toLowerCase();
        this.filteredProducts = this._productname.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

}

