<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Price Comparison</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                [disabled]="isDisabledSave" (click)="onSave()"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                [disabled]="isDisabledClear" icon="pi pi-trash" class=""
                                class="p-button-danger"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_pricecomparisonform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <form [formGroup]="_pricecomparisontypeform">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="true" [options]="_ProductTypes"
                                            optionLabel="producttypename" optionValue="producttypeid"
                                            [disabled]="_action == 'edit' || _action == 'view'"
                                            [(ngModel)]="producttypeid" [ngModelOptions]="{standalone: true}"
                                            displaySelectedLabel=true (onChange)="GetProductCategory($event)">
                                        </p-multiSelect>
                                        <label for="producttypeid">Product Type <span class="hlt-txt">*</span></label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_pricecomparisontypeform">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="true" [options]="_ProductCategories"
                                            optionLabel="categoryname" optionValue="productcategoryid"
                                            [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}"
                                            [disabled]="_action == 'edit' || _action == 'view'"
                                            (onChange)="GetProductName($event)">
                                        </p-multiSelect>
                                        <label for="productcategoryid">Product Category <span
                                                class="hlt-txt">*</span></label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_ProductNames" [(ngModel)]="productid"
                                        [ngModelOptions]="{standalone: true}"
                                        [disabled]="_action == 'edit' || _action == 'view'" optionLabel="productname"
                                        optionValue="productid" (onChange)="GetProductPrice($event)"
                                        displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Levels" optionLabel="levelname"
                                        optionValue="levelid" [disabled]="_action == 'edit' || _action == 'view'"
                                        (onChange)="GetProductPrice($event)" formControlName="levelid"></p-dropdown>
                                    <label for="levelid">Level<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _pricecomparisonform.get('levelid').touched) && _pricecomparisonform.get('levelid').errors?.SelectCurrency">
                                    Please Select Level
                                </span>
                            </td>
                            <td hidden style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                        optionLabel="currencyname" optionValue="currencyid"
                                        [disabled]="_action == 'edit' || _action == 'view'"
                                        (onChange)="OnCurrencychange($event)" formControlName="currencyid"></p-dropdown>
                                    <label for="currencyid">Currency<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _pricecomparisonform.get('currencyid').touched) && _pricecomparisonform.get('currencyid').errors?.SelectCurrency">
                                    Please Select Currency
                                </span>
                            </td>
                            <td>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_pricecomparisondetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['usd','rand','zim1','zim2']"
                        [rowHover]="true" dataKey="productid" [(selection)]="selectedrows"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Product Name</th>
                                <th>Bhola Price (USD Vat Inc)</th>
                                <th>Competitor1 Name</th>
                                <th>Competitor1 Price</th>
                                <th>Competitor2 Name</th>
                                <th>Competitor2 Price</th>
                                <th>Competitor3 Name</th>
                                <th>Competitor3 Price</th>
                                <th>Competitor4 Name</th>
                                <th>Competitor4 Price</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_pricecomparisondetail>
                            <tr>
                                <td>{{_pricecomparisondetail.productname}}</td>
                                <td>{{_pricecomparisondetail.bholaprice}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false"
                                                [disabled]="isDisabledtaxpercentage" [options]="_competitors"
                                                filterBy="competitorname" optionLabel="competitorname"
                                                (onChange)="Competitor1change(_pricecomparisondetail)"
                                                optionValue="competitorid" [(ngModel)]="_pricecomparisondetail.c1id"
                                                *ngIf="_action != 'view'">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_competitors"
                                                optionLabel="competitorname" optionValue="competitorid"
                                                [(ngModel)]="_pricecomparisondetail.c1id">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText
                                                [(ngModel)]="_pricecomparisondetail.c1unitprice"
                                                *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_pricecomparisondetail.c1unitprice}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_pricecomparisondetail.c1unitprice }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false"
                                                [disabled]="isDisabledtaxpercentage" [options]="_competitors"
                                                filterBy="competitorname" optionLabel="competitorname"
                                                (onChange)="Competitor2change(_pricecomparisondetail)"
                                                optionValue="competitorid" [(ngModel)]="_pricecomparisondetail.c2id"
                                                *ngIf="_action != 'view'">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_competitors"
                                                optionLabel="competitorname" optionValue="competitorid"
                                                [(ngModel)]="_pricecomparisondetail.c2id">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText
                                                [(ngModel)]="_pricecomparisondetail.c2unitprice"
                                                *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_pricecomparisondetail.c2unitprice}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_pricecomparisondetail.c2unitprice }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false"
                                                [disabled]="isDisabledtaxpercentage" [options]="_competitors"
                                                filterBy="competitorname" optionLabel="competitorname"
                                                (onChange)="Competitor3change(_pricecomparisondetail)"
                                                optionValue="competitorid" [(ngModel)]="_pricecomparisondetail.c3id"
                                                *ngIf="_action != 'view'">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_competitors"
                                                optionLabel="competitorname" optionValue="competitorid"
                                                [(ngModel)]="_pricecomparisondetail.c3id">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText
                                                [(ngModel)]="_pricecomparisondetail.c3unitprice"
                                                *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_pricecomparisondetail.c3unitprice}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_pricecomparisondetail.c3unitprice }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false"
                                                [disabled]="isDisabledtaxpercentage" [options]="_competitors"
                                                filterBy="competitorname" optionLabel="competitorname"
                                                (onChange)="Competitor4change(_pricecomparisondetail)"
                                                optionValue="competitorid" [(ngModel)]="_pricecomparisondetail.c4id"
                                                *ngIf="_action != 'view'">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_competitors"
                                                optionLabel="competitorname" optionValue="competitorid"
                                                [(ngModel)]="_pricecomparisondetail.c4id">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText
                                                [(ngModel)]="_pricecomparisondetail.c4unitprice"
                                                *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_pricecomparisondetail.c4unitprice}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_pricecomparisondetail.c4unitprice }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <tr>
                        <td>
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>