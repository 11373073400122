import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Customerwisediscount } from 'src/app/core/Models/Customerwisediscount';
import { CustomerwisediscountService } from 'src/app/core/Services/Rewards/customerwisediscount.service';
import { Customerwisediscountlistview } from 'src/app/core/Views/Customerwisediscountlistview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-v-customerwisediscount',
  templateUrl: './v-customerwisediscount.component.html',
  styleUrls: ['./v-customerwisediscount.component.css']
})
export class VCustomerwisediscountComponent implements OnInit {
  _IsProgressSpinner:boolean= false;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _customerwisediscountviews: any[]=[];
  _customerwisediscountview: Customerwisediscountlistview;
  cols:any[];
  _Action: IAction
  datepipe = new DatePipe('en-Us');
  _userid=sessionStorage["userid"];
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _CustomerwisediscountService: CustomerwisediscountService,
    private _router: Router, private _AccessRightsService: AccessRightsService,
    private confirmationService: ConfirmationService,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,private exportUtility:ExportUtility) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(77);
    this.HotKeyIntegration();
    this.utility.pageLoadScript();
    this.cols = [
      {field: 'branchname', header: 'Branch Name' },
      {field: 'customername', header: 'Customer Name' },
      {field: 'tempdiscount', header: 'Discount' },
      {field: 'producttypename', header: 'Product Type' },
      {field: 'productcategoryname', header: 'Product Category' },
      {field: 'productname', header: 'Product Name' },
      {field: 'effectivefrom', header: 'Effective From' },
      {field: 'effectiveto', header: 'Effective To' },
      {field: 'customerwisediscountstatusdesc', header: 'Status' }
    ]
    this._IsProgressSpinner = true;
    this._CustomerwisediscountService.FetchAll(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._customerwisediscountviews = resultJSON.customerwisediscountheaderviews;
      this._IsProgressSpinner = false;
    },error=> {this._IsProgressSpinner = false;});
  }
  onRowSelect(event) {
  }

  onRowUnselect(event) {
  }
  view(event) {
    // 37.View Initialize should load all fields based on, search page selected Customer. and all fields should be in read-only mode.
    this._router.navigate(['/cCustomerwisediscount'], { state: { customerid: event.customerid, action: 'view', productid: event.productid,branchid:event.branchid,customerwiseid:event.customerwiseid } });
  }

  delete(event) {
    alert("delete");
  }

  edit(event) {
    //  40. update initialize should load all fields based on search page selected Customer.
    // this.router.navigate(['/cCustomer']);
    this._router.navigate(['/cCustomerwisediscount'], { state: { customerid: event.customerid, action: 'edit', productid: event.productid,branchid:event.branchid,customerwiseid:event.customerwiseid } });
    // alert("edittest");
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }

  exportExcel() {
    let customerList = this.GetCustomerDiscountList();
    this.exportUtility.exportExcel(customerList, 'Customer-wise-Discount', 'xlsx');
  }
  exportPdf() {
    // let customerList = this.GetCustomerDiscountList();
    // this.exportUtility.ExportToPdf(customerList, 'Customer wise Discount.pdf');

    if (!this._customerwisediscountviews || !this._customerwisediscountviews.length)
    return;
  let StatusHistoryDetails = this.GetCustomerDiscountList();
  const data = StatusHistoryDetails.map(Object.values);
  const head = [['Branch Name', 'Customer Name','Effective From', 'Effective To', 'Status']]
  import("jspdf").then(jsPDF => {
    import("jspdf-autotable").then(x => {
      const doc = new jsPDF.default('l', 'mm', 'a4');
      autoTable(doc, {
        head: head,
        body: data,
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0,
        styles: {
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
        },
        // theme: 'grid',
        headStyles: {
          fillColor: [211, 211, 211],
          textColor: 0
          // fontSize: 15,
        },
        bodyStyles: {
          textColor: 0
        },
        didDrawCell: (data) => { },
      });
      doc.save('Customer-wise-Discount.pdf');
    })
  })

  }
  GetCustomerDiscountList()
  {
    let customerList = [];
    this._customerwisediscountviews.forEach(element => {
      let customers: any = {};
      customers["Branch Name"] = element.branchname;
      customers["Customer Name"] = element.customername;
      // customers["Product Type"] = element.producttypename;
      // customers["Product Category"] = element.productcategoryname;
      // customers["Product Name"] = element.productname;
      // customers["USD Discount"] = element.tempusddiscount;
      // customers["RAND Discount"] = element.tempranddiscount;
      // customers["BOND Discount"] = element.tempbonddiscount;
      // customers["BANK Discount"] = element.tempbankdiscount;
      customers["Effective From"] =this.datepipe.transform(element.effectivefrom, 'dd-MM-yyyy');
      customers["Effective To"] = this.datepipe.transform(element.effectiveto, 'dd-MM-yyyy');
      customers["Status"] = element.approvalstatusname;
      customerList.push(customers);
    });
    return customerList;
  }

  GoToCreate()
  {
    this._router.navigate(['/cCustomerwisediscount']);
  }

  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this customer discount <b>"' + event.customername + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }

  Cancel(event) {
    var discountid = event.customerwiseid;
    this._IsProgressSpinner = true;
    this._CustomerwisediscountService.Cancel(discountid, sessionStorage["userid"]).subscribe((result) => {
      this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._customerwisediscountviews=[];
      this._customerwisediscountviews = resultJSON.customerwisediscountheaderviews;
      this._IsProgressSpinner = false;
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

}
