 export class Debitnotedetail {
    debitnotedetailid: number;
    debitnoteid: number;
    invoiceno: string;
    invoicedate: Date;
    productid: number;
    productname: number;
    quantity: number;
    returnquantity: number;
    unitprice: number;
    transactionamount: number;
    debitamount: number;
    reason: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
}