export class Stockinwardgetproductdetailview {
    purchaseinwarddetailid: number;
    purchaseinvoiceid: number;
    purchaseorderno: string;
    productid: number;
    productname: string;
    variantid: number;
    variantdescription: string;
    quantity: number;
    orderqunatity: number;
    receivedquantity: number;
    invoicequantity: number;
    shortagequantity: number;
    uomcode: string;
    uomname: string;
    lotno: string;
    requesttype: string;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    eveidenceno: string;
    purchaseordertype:string;
}