import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptPurchaseVsSalesService {
  public _Action: IAction;
  public _httpOptions;
  public _Service = environment.PurchaseVsSalesReportService;
  constructor(private http: HttpClient) { }
  PageOnLoad(obj: object): Observable<any> {
    var Query = this._Service + '/PageInitialize';
    return this.http.post(Query, obj);
  }
  Getproductcategory(obj: object): Observable<any> {
    var Query = this._Service + '/Getproductcategory';
    return this.http.post(Query, obj);
  }
  GetProductheader(obj: object): Observable<any> {
    var Query = this._Service + '/GetProductheader';
    return this.http.post(Query, obj);
  }
  Search(obj:object ):Observable<any>{
    var Query =this._Service +'/Search';
    return this.http.post(Query,obj)
  }
}
