<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Stock Valuation</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <div class="row">
                    <div class="col-md-6">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Branch Wise Stock</h5>
                                </div>
                                <div class="col-md-6 text-right">
                                    <h3>Overall Branch Stock Value : {{CurrencySymbol}}{{OverallBranchStock}}</h3>
                                </div>
                            </div>
                        </div>
                        <table class="normal-table">
                            <tr>
                                <td style="width: 40%;">
                                    <span class="p-float-label">
                                        <p-multiSelect optionLabel="brandname" [options]="_BrandList"
                                            optionValue="brandid" [filter]="true" [virtualScroll]="true" itemSize="30"
                                            [(ngModel)]="selectedBrandId" (onChange)="getSupplierDetail($event)"
                                            [ngModelOptions]="{standalone: true}" displaySelectedLabel="true">
                                        </p-multiSelect>
                                        <label for="productid">Brand Name</label>
                                    </span>
                                </td>
                                <td style="width: 40%;">
                                    <span class="p-float-label">
                                        <p-multiSelect optionLabel="suppliername" [options]="_SupplierList"
                                            optionValue="supplierid" [filter]="true" [virtualScroll]="true"
                                            itemSize="30" [(ngModel)]="selectedSupplierId"
                                            [ngModelOptions]="{standalone: true}" displaySelectedLabel="true">
                                        </p-multiSelect>
                                        <label for="productid">Supplier Name</label>
                                    </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" title="Search" icon="pi pi-search"
                                        (click)="getDetail()" class="p-button-success p-mr-2"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="reset()"
                                        class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtBranchWiseStocks.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </ng-template>
                                <ng-template pTemplate="right">
                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                        (click)="dtBranchWiseStocks.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                        class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                        (click)="exportExcel()"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                        class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                        (click)="ExportToPdf()"></button>
                                </ng-template>
                            </p-toolbar>
                            <p-table #dtBranchWiseStocks [value]="_BranchWiseStocks" selectionMode="single" [rows]="15"
                                [paginator]="true" [rowsPerPageOptions]="[15,25,50,100]"
                                (onRowSelect)="onRowSelectBranch($event)" [columns]="cols"
                                [globalFilterFields]="['BRANCHNAME','STOCKVALUEINUSD']" [rowHover]="true"
                                dataKey="BRANCHNAME"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedBranchwiseStocks">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="BRANCHNAME">Branch<p-sortIcon field="BRANCHNAME">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="STOCKVALUEINUSD">Closing Stock Value<p-sortIcon
                                                field="STOCKVALUEINUSD">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_BranchWisestock>
                                    <tr [pSelectableRow]="_BranchWisestock">
                                        <td>
                                            {{_BranchWisestock.BRANCHNAME}}
                                        </td>
                                        <td class="text-right">
                                            {{CurrencySymbol}}{{_BranchWisestock.STOCKVALUEINUSD}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-6" [hidden]="IsShowProductCategorywiseStock">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Product Category Wise Stock</h5>
                                </div>
                                <div class="col-md-6  text-right">
                                    <h3>Overall Category Stock Value :{{CurrencySymbol}}{{OverallCategoryStock}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtProductCategoryWiseStocks.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </ng-template>
                                <ng-template pTemplate="right">
                                    <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                        (click)="dtProductCategoryWiseStocks.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                        tooltipPosition="bottom"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                        class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                        (click)="exportExcelProductCategoryWise()"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                        class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                        (click)="ExportToPdfProductCategoryWise()"></button>
                                </ng-template>
                            </p-toolbar>
                            <p-table #dtProductCategoryWiseStocks [value]="_ProductCategoryWiseStocks"
                                selectionMode="single" [rows]="15" [paginator]="true"
                                [rowsPerPageOptions]="[15,25,50,100]" [columns]="ProductCategoryWiseStockscols"
                                [globalFilterFields]="['BRANCHNAME','CATEGORYNAME','STOCKVALUEINUSD']" [rowHover]="true"
                                dataKey="CATEGORYNAME" (onRowSelect)="onRowSelectCategory($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [(selection)]="selectedProductCategoryRows">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;" pSortableColumn="BRANCHNAME">Branch<p-sortIcon
                                                field="BRANCHNAME">
                                            </p-sortIcon>
                                        </th>
                                        <th style="text-align: center;" pSortableColumn="CATEGORYNAME">Product
                                            Category Name<p-sortIcon field="CATEGORYNAME">
                                            </p-sortIcon>
                                        </th>
                                        <th style="text-align: center;" pSortableColumn="STOCKVALUEINUSD">Closing Stock
                                            Value<p-sortIcon field="STOCKVALUEINUSD">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-_ProductCategoryWiseStock
                                    let-i="rowIndex">
                                    <tr [pSelectableRow]="rowData">
                                        <td>
                                            {{_ProductCategoryWiseStock.BRANCHNAME}}
                                        </td>
                                        <td>
                                            {{_ProductCategoryWiseStock.CATEGORYNAME}}
                                        </td>
                                        <td class="text-right">
                                            {{CurrencySymbol}}{{_ProductCategoryWiseStock.STOCKVALUEINUSD}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <p-dialog header="Product Wise Stock" [(visible)]="displayBasic" [modal]="true"
                            [style]="{width: '70%',height: '500px'}" [baseZIndex]="10000">
                            <ng-template pTemplate="content">
                                <div class="popup-body">
                                    <div class="card">
                                        <p-toolbar styleClass="p-mb-4">
                                            <ng-template pTemplate="left">
                                                <span class="p-input-icon-left">
                                                    <i class="pi pi-search"></i>
                                                    <input pInputText type="text"
                                                        (input)="dtProductWiseStocks.filterGlobal($event.target.value, 'contains')"
                                                        placeholder="Search..." />
                                                </span>
                                            </ng-template>
                                            <ng-template pTemplate="right">
                                                <button type="button" pButton pRipple icon="pi pi-file-o"
                                                    title="Export CSV" (click)="dtProductWiseStocks.exportCSV()"
                                                    class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS"
                                                    tooltipPosition="bottom"
                                                    (click)="exportExcelProductWiseStock()"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-pdf"
                                                    title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF"
                                                    tooltipPosition="bottom"
                                                    (click)="ExportToPdfProductWiseStock()"></button>
                                            </ng-template>
                                        </p-toolbar>
                                        <p-table #dtProductWiseStocks [value]="ProductWiseStocks" selectionMode="single"
                                            [(selection)]="selectedBank" [paginator]="true"
                                            [columns]="ProductWiseStockscols" [rows]="10"
                                            [globalFilterFields]="['BRANCHNAME','CATEGORYNAME','PRODUCTNAME','STOCKVALUEINUSD']"
                                            [rowHover]="true" dataKey="PRODUCTNAME" [rowsPerPageOptions]="[15,25,50]"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                            [showCurrentPageReport]="true">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th pSortableColumn="BRANCHNAME">Branch<p-sortIcon
                                                            field="BRANCHNAME">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="CATEGORYNAME">Product Category<p-sortIcon
                                                            field="CATEGORYNAME">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="PRODUCTNAME">Product Name<p-sortIcon
                                                            field="PRODUCTNAME">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="STOCKVALUEINUSD">Closing Stock Value<p-sortIcon
                                                            field="STOCKVALUEINUSD">
                                                        </p-sortIcon>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-ProductWiseStock>
                                                <tr>
                                                    <td>
                                                        {{ProductWiseStock.BRANCHNAME}}
                                                    </td>
                                                    <td>
                                                        {{ProductWiseStock.CATEGORYNAME}}
                                                    </td>
                                                    <td>
                                                        {{ProductWiseStock.PRODUCTNAME}}
                                                    </td>
                                                    <td class="text-right">
                                                        {{CurrencySymbol}}{{ProductWiseStock.STOCKVALUEINUSD}}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </ng-template>
                        </p-dialog>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>