export class Deliverynotedetailview {
    deliverynotedetailid: number;
    deliverynoteid: number ;
    productid: number ;
    productname: string ;
    quantity: number ;
    unitprice:number;
    amount: number ;
    isdamaged: boolean ;
    remarks: string;
    stock:number;
}