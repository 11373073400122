import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class PaymentgatewayService {
  public _Action: IAction;
  public _httpOptions;
  public _PaymentTransactionService = environment.PaymentTransactionService;
  constructor(private http: HttpClient) { }
  PageOnload(): Observable<any> {
    var Query = this._PaymentTransactionService;
    // console.log(Query);
    return this.http.get<{ data: any }>(Query);
  }
  SearchPaymentTransaction(payment:object) {
    var Query = this._PaymentTransactionService;
    // console.log(Query);
    return this.http.post<{ data: any }>(Query, payment);
  }
}
