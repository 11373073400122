import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';


@Injectable({
  providedIn: 'root'
})
export class TripsheetService {

  public _Action: IAction;
  public _httpOptions;
  public _TripSheetService = environment.RptTripSheetPrintService;
  constructor(private http: HttpClient) { }

  PageOnload(): Observable<any> {
    var Query = this._TripSheetService + '/PageInitialize';
    return this.http.get<{ data: any }>(Query);
  }

  Print(ageingreport:object) {
    var Query = this._TripSheetService +'/Print';    
    return this.http.post(Query,ageingreport, { responseType: 'blob' });
  }
}
