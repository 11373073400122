import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptrewardpointtransactionService {

  public _Action: IAction;
  public _httpOptions;
  public _RptrewardpointtransactionService = environment.RptrewardpointtransactionService;
  constructor(private http: HttpClient) { }

  PageOnload(): Observable<any> {
    var Query = this._RptrewardpointtransactionService + '/PageInitialize';

    return this.http.get<{ data: any }>(Query);
  }
  GetProduct(productcategory: object) {
    var Query = this._RptrewardpointtransactionService + '/GetProduct';

    return this.http.post<{ data: any }>(Query, productcategory);
  }
  Print(fromdate: string, todate: string, productid: number, branchid: number, customerid: number) {
    var Query = this._RptrewardpointtransactionService + '/Print/' + fromdate + '/' + todate + '/' + productid + '/' + branchid + '/' + customerid;
    return this.http.get(Query, { responseType: 'blob' });
  }

}
