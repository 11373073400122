import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { IAction } from '../security/IActions';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DeliverynoteService {
  public _Action: IAction;
  public _DeliveryNoteService = environment.DeiveryNoteService;
  constructor(private http: HttpClient) { }

  PageOnLoad(deliveryno:number,userid:number): Observable<any> {
    var Query = this._DeliveryNoteService + '/'+deliveryno+'/'+userid;
   
    return this.http.get<{ data: any }>(Query);
  }
  
  OnSupplierNameChange(supplierid:number): Observable<any> {
    var Query = this._DeliveryNoteService + '/OnSupplierNameChange'+ '/'+supplierid;
   
    return this.http.get<{ data: any }>(Query);
  }
  GetProductCategory(producttype:object) {
    var Query = this._DeliveryNoteService+'/GetProductCategory';
   
    return this.http.post<{ data: any }>(Query, producttype);
  }
  GetProductName(productcategory:object) {
    var Query = this._DeliveryNoteService + '/GetProductName';
   
    return this.http.post<{ data: any }>(Query, productcategory);
  }
 

  GetProduct(product: string,branchid:number,supplierid:number) {
    var Query = this._DeliveryNoteService + '/GetProduct' + '/' + product + '/'+branchid+ '/'+supplierid;
   
    return this.http.get<{ data: any }>(Query);
  }
  GetProductStock(productid:number,branchid:number){
    var Query = this._DeliveryNoteService + '/GetProductStock' + '/' + productid + '/'+branchid;
   
    return this.http.get<{ data: any }>(Query);
  }
  create(deliverynote: object) {
    console.log("Create");
    var Query = this._DeliveryNoteService;
   
    return this.http.post<{ data: any }>(Query, deliverynote);
  }

  FetchAll(branchid: number) {
    var Query = this._DeliveryNoteService + '/FetchAll' + '/' + branchid;
   
    return this.http.get<{ data: any }>(Query);
  }
  Cancel(userid:number,deliverynoteid: number) {
    var Query = this._DeliveryNoteService + '/Cancel' + '/' + userid+ '/' + deliverynoteid;
   
    return this.http.get<{ data: any }>(Query);
  }
  edit(deliverynote: object) {
    console.log("Edit");
    var Query = this._DeliveryNoteService;
   
    return this.http.put<{ data: any }>(Query, deliverynote);
  }

  SendToApproval(userid: number, branchid: number,deliverynoteid:number) {
    console.log("Send To Approval");
    var Query = this._DeliveryNoteService + '/SendToApproval' + '/' + userid + '/' + branchid + '/' + deliverynoteid;
   
    return this.http.get<{ data: any }>(Query);
  }
}
