import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class RptAccountsGroupWiseReportService {
  public _Action: IAction;
  public _httpOptions;
  public _RptAccountsGroupWiseReportService = environment.RptAccountsGroupWiseReportService;
  constructor(private http: HttpClient) { }
  PageOnLoad(obj: object): Observable<any> {
    var Query = this._RptAccountsGroupWiseReportService + '/PageOnLoad';
    return this.http.post(Query, obj);
  }
  GenerateReport(obj: object): Observable<any> {
    var Query = this._RptAccountsGroupWiseReportService + '/GenerateReport';
    return this.http.post(Query, obj);
  }
}