<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Service Request</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save" [disabled]="_IsSaveDisable" (click)="onSave()"></button>
                            <button pButton pRipple type="button" (click)="reset()" title="Clear" icon="pi pi-trash" class="" class="p-button-danger" [disabled]="_IsSaveDisable"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button" [disabled]="isDisabledsendapparoval" (click)="SendToApproval($event)"></button>
                            <button pButton pRiple type="button" (click)="goBack()" icon="pi pi-search" title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_servicerequestform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="servicerequestdate" [minDate]="mindate" [maxDate]="maxdate"
                                        dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="servicerequestdate"> Service Request Date <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _servicerequestform.get('servicerequestdate').touched) && _servicerequestform.get('servicerequestdate').errors?.SelectPaymentDate">
                                    Please Select Service Request Date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!-- <div class="p-field-radiobutton" *ngFor="let AD of _reftype">
                                        <p-radioButton name="reftype" value="reftype" formControlName="reftype"
                                            label={{AD.metasubdescription}} value={{AD.metasubid}}
                                            (onClick)="getcustomerorasset()"></p-radioButton>
                                    </div> -->
                                    <p-dropdown name="reftype" formControlName="reftype" [options]="_reftype"
                                        optionLabel="metasubdescription" optionValue="metasubid"
                                        (onChange)="getcustomerorasset()">
                                    </p-dropdown>
                                    <label for="name">Service Request Type<span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_BranchList" optionLabel="branchname" [filter]="true"
                                        filterBy="branchname" optionValue="branchid" formControlName="servicebranchid"
                                        [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="name">Service Branch<span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;" rowspan="2">
                                <span class="p-float-label">
                                    <textarea rows="3" name="remarks" formControlName="remarks"
                                        pInputTextarea></textarea>
                                    <label for="remarks">Remarks </label>
                                </span>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="width: 20%;" [hidden]="iscustomerview">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="customertype" name="id"
                                        [(ngModel)]="_selectedtype" optionLabel="name" optionValue="id"
                                        [disabled]='iscustomer' [ngModelOptions]="{standalone: true}">
                                    </p-dropdown>
                                    <label for="name" [hidden]="iscustomerview"> Customer Type <span
                                            class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <!-- <span class="p-float-label">
                                    <p-dropdown [options]="_cusorpronames" optionLabel="productorcustomername"
                                        [filter]="true" filterBy="productorcustomername"
                                        optionValue="productorcustomerid" formControlName="productorcustomerid"
                                        (onChange)="getrefno()" [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="regBranchid">Customer / product Name <span
                                            class="hlt-txt">*</span></label>
                                </span> -->
                                <span class="p-float-label">
                                    <p-autoComplete [suggestions]="_cusorproname" field="productorcustomername"
                                        (completeMethod)="getcustomer($event)" formControlName="productorcustomerid"
                                        (onSelect)="getrefno()">
                                        <ng-template let-customerOrProduct pTemplate="item">
                                            <div>{{customerOrProduct.productorcustomername}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <label for="regBranchid">Customer / Product Name <span
                                            class="hlt-txt">*</span></label>
                                </span>

                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _servicerequestform.get('productorcustomerid').touched) && _servicerequestform.get('productorcustomerid').errors?.SelectAccountgroup">
                                    Please Select Customer / product Name
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_refno" optionLabel="refcode" [filter]="true"
                                        filterBy="refcode" optionValue="refcode" formControlName="refno"
                                        (onChange)="GetAssetBasedDetail()" [autoDisplayFirst]="false" [disabled]='isrefno'>
                                    </p-dropdown>
                                    <label for="regBranchid">Ref No <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_submitted || _servicerequestform.get('refno').touched) && _servicerequestform.get('refno').errors?.SelectAccountgroup">
                                    Please Select Ref No
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr *ngIf="Disablecustomer">
                            <td>
                                <span class="p-float-label" *ngIf="_selectedtype !== 2">
                                    <input type="text" pInputText disabled [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="mobileno" />
                                    <label for="mobileno">Mobile No </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label" *ngIf="_selectedtype !== 2">
                                    <input type="text" pInputText disabled [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="mailid" />
                                    <label for="mailid">Mail Id </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label" *ngIf="_selectedtype !== 2">
                                    <textarea rows="3" name="remarks" disabled [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="Address" pInputTextarea></textarea>
                                    <label for="remarks">Address </label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </form>
                <div class="card" *ngIf="Disablecustomer">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_Customerrequests" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['productname','invoicedate','quantity','uomdesc','unitprice',
                        'finalamount','isschemeappliedname','iswarrantyapplicablename','warrantydate','srquantity']" [rowHover]="true" dataKey="productid" [(selection)]="selectedRows" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        (onRowSelect)="onRowSelectPopup($event)" (onRowUnselect)="onRowUnSelectPopup($event)" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 50px;">
                                    <!-- <p-tableHeaderCheckbox [disabled]="_action != 'create'"></p-tableHeaderCheckbox> -->
                                </th>
                                <th pSortableColumn="productname">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="invoicedate">Sales Date
                                    <p-sortIcon field="invoicedate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="quantity">Available Qty
                                    <p-sortIcon field="quantity">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="uomdesc">UOM
                                    <p-sortIcon field="uomdesc">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="unitprice">Unit Price
                                    <p-sortIcon field="unitprice"></p-sortIcon>
                                </th>
                                <th pSortableColumn="finalamount">Final Amount
                                    <p-sortIcon field="finalamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="iswarrantyapplicablename">Is Warranty Applied
                                    <p-sortIcon field="iswarrantyapplicablename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="warrantydate"> Warranty Date
                                    <p-sortIcon field="warrantydate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="isschemeappliedname">Is Scheme Applied
                                    <p-sortIcon field="isschemeappliedname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="srquantity">Service Request Quantity
                                    <p-sortIcon field="srquantity">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Customerrequest>
                            <tr>
                                <td style="text-align: center;width: 50px;">
                                    <p-tableCheckbox [value]="_Customerrequest" [disabled]="_action != 'create'||_Customerrequest.disablecheckbox">
                                    </p-tableCheckbox>
                                </td>
                                <td>
                                    {{_Customerrequest.productname }}
                                </td>
                                <td>
                                    {{_Customerrequest.invoicedate | date:'dd-MMM-yyyy' }}
                                </td>
                                <td>
                                    {{_Customerrequest.quantity}}
                                </td>
                                <td>
                                    {{_Customerrequest.uomdesc }}
                                </td>
                                <td>
                                    {{_Customerrequest.unitprice }}
                                </td>
                                <td>
                                    {{_Customerrequest.finalamount }}
                                </td>
                                <td>
                                    {{_Customerrequest.iswarrantyapplicablename }}
                                </td>
                                <td>
                                    {{_Customerrequest.warrantydate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_Customerrequest.isschemeappliedname }}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_Customerrequest.srquantity" [disabled]="Iscustomersrq" (keyup)="allocatecustomerquantity(_Customerrequest)" pInputText *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_Customerrequest.srquantity}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Customerrequest.srquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="card" *ngIf="Disableasset">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dts.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dts [value]="_Assetrequests" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['grnno','purchasedate','productname','suppliername','quantity','uomdesc','unitprice','finalamount','iswarrantyapplicablename','warrantydate','srquantity']"
                        [rowHover]="true" dataKey="voucherno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <!-- <th style="text-align: center;"> Action</th> -->
                                <th pSortableColumn="grnno">GRN No
                                    <p-sortIcon field="grnno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="purchasedate">Purchase Date
                                    <p-sortIcon field="purchasedate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="suppliername">Supplier Name
                                    <p-sortIcon field="suppliername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="quantity">Quantity
                                    <p-sortIcon field="quantity"></p-sortIcon>
                                </th>
                                <th pSortableColumn="uomdesc">UOM
                                    <p-sortIcon field="uomdesc"></p-sortIcon>
                                </th>
                                <th pSortableColumn="unitprice">Unit Price
                                    <p-sortIcon field="unitprice">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="finalamount">Final Amount
                                    <p-sortIcon field="finalamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="iswarrantyapplicablename">Is Warranty Applied
                                    <p-sortIcon field="iswarrantyapplicablename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="warrantydate"> Warranty Date
                                    <p-sortIcon field="warrantydate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="srquantity">Service Request Quantity
                                    <p-sortIcon field="srquantity">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Assetrequest>
                            <tr>
                                <!-- <td style="text-align: center;">
                                    <button pButton pRipple title="Remove" [disabled]="isDisabledremoveproduct"
                                        icon="pi pi-trash" class="p-button-danger p-mr-2"
                                        (click)="RemoveProduct(_Assetrequest)"></button>
                                </td> -->
                                <td>
                                    {{_Assetrequest.grnno}}
                                </td>
                                <td>
                                    {{_Assetrequest.purchasedate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_Assetrequest.productname }}
                                </td>
                                <td>
                                    {{_Assetrequest.suppliername }}
                                </td>
                                <td>
                                    {{_Assetrequest.quantity}}
                                </td>
                                <td>
                                    {{_Assetrequest.uomdesc}}
                                </td>
                                <td>
                                    {{_Assetrequest.unitprice }}
                                </td>
                                <td>
                                    {{_Assetrequest.finalamount }}
                                </td>
                                <td>
                                    {{_Assetrequest.iswarrantyapplicablename }}
                                </td>
                                <td>
                                    {{_Assetrequest.warrantydate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_Assetrequest.srquantity }}
                                </td>


                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>