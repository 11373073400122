<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>

        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Payment Settlement</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" [disabled]="isDisabledsave" type="submit"
                                (click)="onSave()" title="Save" icon="pi pi-save" class=""></button>
                            <button pButton pRipple label="" [disabled]="isDisablereset" type="button"
                                (click)="reset($event)" title="Clear" icon="pi pi-trash" class=""
                                class="p-button-danger"></button>
                            <button pButton pRiple label="" type="submit" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_CODSettlementForm">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" name="codsettlementdate"
                                        formControlName="codsettlementdate" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="id">Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_CODSettlementForm.get('codsettlementdate').touched || _submitted) && _CODSettlementForm.get('codsettlementdate').errors?.SelectDeliveryDate">
                                    Please select Date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <button pButton pRipple label="Search" type="button" (click)="GetSalesdetails()" title="Search"
                                icon="pi pi-search"></button></td>
                            <!-- <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [filter]="true" filterBy="username"
                                        [showClear]="true" [options]="_Tellernames" [virtualScroll]="true" itemSize="30" (onChange)="GetSalesdetails()"
                                        optionValue="userid" optionLabel="username" formControlName="tellerid">
                                    </p-dropdown>
                                    <label for="id">Teller Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_CODSettlementForm.get('tellerid').touched || _submitted) && _CODSettlementForm.get('tellerid').errors?.SelectTellerName">
                                Please select Teller Name
                            </span>
                            </td> -->
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [filter]="true" filterBy="username"
                                        [showClear]="true" [options]="_CODSettlementOfficers" [virtualScroll]="true" itemSize="30"
                                        optionValue="userid" optionLabel="username" formControlName="settlementofficerid">
                                    </p-dropdown>
                                    <label for="id">Settlement Officer <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_CODSettlementForm.get('settlementofficerid').touched || _submitted) && _CODSettlementForm.get('settlementofficerid').errors?.SelectTellerName">
                                Please select Settlement officer
                            </span>
                            </td>
                        
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!-- <input type="text" pInputText disabled formControlName="orderid"> -->
                                    <input type="text" name="totalonlineamount" disabled formControlName="totalonlineamount" pInputText>
                                    <label for="inputtext">Total Receivable Amount <span class="hlt-txt">*</span></label>
                                </span></td>
                            <td style="width: 20%;"></td>
                        </tr>
                       
                    </table>
                </form>


                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>COD Settlement Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_CODSettlementDetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]" [rowHover]="true"
                        [globalFilterFields]="['customerorbranchname','productname','variantdescription','evidenceno','evidencedate','uomname','isDisabledotherdeliveryrequired','otherbranchstock','otherbranchid','isdelivered','totalinvoicequnatity','invoicequantity','currentstock','deliveryquantity','balancequantity']"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="selectedCODSettlementRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 50px;">
                                    <p-tableHeaderCheckbox [disabled]="_action == 'view' || _action == 'edit'"
                                        (click)="SelectAll($event)"></p-tableHeaderCheckbox>
                                </th>
                                <th>Sales Date</th>
                                <!-- <th>Stock Delivery Date</th> -->
                                <th>Total Receivable Amount</th>
                                <th>Previous Settlement Amount</th>
                                <th>Teller Name</th>
                                <th>Settlement Amount</th>
                                <th>Balance Amount</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_codsettlementdetail let-ri="rowIndex">
                            <tr>

                                <td style="text-align: center;width: 50px;">
                                    <p-tableCheckbox [disabled]="_action == 'view'" [value]="_codsettlementdetail">
                                    </p-tableCheckbox>
                                </td>
                                <td>
                                    {{_codsettlementdetail.salesdate|date:'dd/MM/yyyy'}}
                                </td>
                                <!-- <td>
                                    {{_codsettlementdetail.stockdeliverydate|date:'dd/MM/yyyy'}}
                                </td> -->
                                <td>
                                    {{_codsettlementdetail.totalreceivableamount| number: '1.2'}}
                                </td>
                                <td>
                                    {{_codsettlementdetail.previoussettlementamount| number: '1.2'}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_Tellernames" [disabled]="true"
                                            [virtualScroll]="true" itemSize="30"   optionLabel="username" optionValue="userid" [filter]="true"
                                                [showClear]="true" filterBy="username"
                                                [(ngModel)]="_codsettlementdetail.tellerid">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [autoDisplayFirst]="false" [disabled]="true"
                                                [options]="_Tellernames" optionLabel="username" optionValue="userid"
                                                [(ngModel)]="_codsettlementdetail.tellerid">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number"  pInputText
                                                [(ngModel)]="_codsettlementdetail.settlementamount"  (keyup)="entersettlementamount(_codsettlementdetail,$event)" (keypress)="keypress.kpDecimalInput($event)"
                                                >
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_codsettlementdetail.settlementamount| number: '1.2'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_codsettlementdetail.balanceamount| number: '1.2'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table class="normal-table">
                    <tr>
                        <td >
                            <span class="p-float-label">
                                <input type="text" name="totalonlineamount" disabled [(ngModel)]="TotalprevioussettlementAmt" pInputText>
                                <label for="inputtext">Total Previous Settlement Amount <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td >
                            <span class="p-float-label">
                                <input type="text" name="totalonlineamount" disabled [(ngModel)]="TotalcurrentsettlementAmt" pInputText>
                                <label for="inputtext">Total current Settlement Amount <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td >
                            <span class="p-float-label">
                                <input type="text" name="totalonlineamount" disabled [(ngModel)]="TotalAmt" pInputText>
                                <label for="inputtext">Total Amount <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>

            </div>
            <p-toast position="bottom-right"></p-toast>
        </div>
    </div>
</div>