import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockallocationService {

  public _StockallocationService = environment.StockallocationService;
  public _RptStockAllocationService = environment.RptStockAllocationPrintService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(_reservationid: number): Observable<any> {
    const headers = this.header;
    var Query = this._StockallocationService + '/' + _reservationid

    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
    const headers = this.header;
    var Query = this._StockallocationService + '/FetchAllLoad' + '/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }
  getProductCategory(producttype: object) {
    const headers = this.header;
    var Query = this._StockallocationService + '/getProductCategories';

    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }
  getProduct(productcategory: object) {
    const headers = this.header;
    var Query = this._StockallocationService + '/getProducts';

    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }
  getProductSearch(productheader: object) {
    const headers = this.header;
    var Query = this._StockallocationService + '/getProductSearch';

    return this.http.post<{ data: any }>(Query, productheader, { headers });
  }
  getBranchProduct(branchid: number[], frombranchid: number) {
    const headers = this.header;
    let paramvalue = "BranchId="
    paramvalue = paramvalue + branchid.join('&BranchId=');
    var Query = this._StockallocationService + '/getBranchProduct' + '/' + frombranchid + '/?' + paramvalue;
    return this.http.get<{ data: any }>(Query, { headers });
  }


  getVariant(productid: number) {
    const headers = this.header;
    var Query = this._StockallocationService + '/getProductVariants' + '/' + productid;

    return this.http.get<{ data: any }>(Query, { headers });

  }
  create(p_stockallocation: object) {
    const headers = this.header;
    var Query = this._StockallocationService;

    return this.http.post<{ data: any }>(Query, p_stockallocation, { headers });
  }

  Print(stocktakeno: string, branchid: number) {
    //const headers = this.header;
    var Query = this._RptStockAllocationService + '/Print/' + stocktakeno + '/' + branchid;
    // return this.http.get(Query, { responseType: 'blob' });
    window.open(Query, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
  }
  
  Cancel(obj: object) {
		const headers = this.header;
		var Query = this._StockallocationService + '/Cancel';
		return this.http.post(Query, obj, { headers });
	}

  sacancel(obj: object) {
		const headers = this.header;
		var Query = this._StockallocationService + '/sacancel';
		return this.http.post(Query, obj, { headers });
	}
}
