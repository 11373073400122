import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TermsandconditionService } from 'src/app/core/services/masters/termsandcondition.service';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Termsandconditionview } from 'src/app/core/Views/Termsandconditionview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';

import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common'; 
@Component({
  selector: 'app-v-terms-and-condition',
  templateUrl: './v-terms-and-condition.component.html',
  styleUrls: ['./v-terms-and-condition.component.css']
})
export class VTermsAndConditionComponent implements OnInit {
  selectedrows: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _Action: IAction
  _termsandconditionviews: Termsandconditionview[];
  _termsandconditionview: Termsandconditionview;
  datepipe = new DatePipe('en-US');
  _IsProgressSpinner: boolean = true;
  cols: any[];
  constructor(private fb: FormBuilder, private _TermsandconditionService: TermsandconditionService, private router: Router, private utility: CommonUtilities,
    private _CustomExceptionService: CustomExceptionService, private confirmationService: ConfirmationService,
    private _AccessRightsService: AccessRightsService, private _hotkeysService: HotkeysService, private exportUtility: ExportUtility) {

    this._Action = this._AccessRightsService.getAccessRights(27);
    this.HotKeyIntegration();
  }

  ngOnInit(): void {

    this._Action = this._AccessRightsService.getAccessRights(27);
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'partytypename', header: 'Party Type Name' },
      { field: 'businesstypename', header: 'Business Type Name' },
      { field: 'termstypename', header: 'Terms Type Name' },
      { field: 'termsdescription', header: 'Terms Description' },
      { field: 'createdon', header: 'Created On' },
      { field: 'modifiedon', header: 'Modified On' },
      { field: 'termsandconditionstatusname', header: 'Status' }
    ]
    this._IsProgressSpinner = true;
    this._TermsandconditionService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._termsandconditionviews = resultJSON.termsandconditionviews;
      this._IsProgressSpinner = false;
    });
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  view(event) {
    //37. if all the data is valid allow to view the record from the database based on terms id
    //38. all the controls should disable
    this.router.navigate(['/cTermsAndCondition'], { state: { termsandconditionid: event.termsandconditionid, action: 'view' } });
  }

  delete(event) {
    alert("delete");
  }
  GoToCreate(event) {
    this.router.navigate(['/cTermsAndCondition']);
  }

  edit(event) {
    // 23.all the datas should fetch in particular columns.based on terms id (autogeneration column)
    // this.router.navigate(['/cCustomer']);

    this.router.navigate(['/cTermsAndCondition'], { state: { termsandconditionid: event.termsandconditionid, action: 'edit' } });
    // alert("edittest");
  }
  exportExcel() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    this.exportUtility.exportExcel(TermsAndConditionList, 'TermsAndCondition', 'xlsx');
  }

  ExportToPdf() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    this.exportUtility.ExportToPdf(TermsAndConditionList, 'TermsAndCondition.pdf');
  }

  // { field: 'partytypename', header: 'Party Type Name' },
  // { field: 'businesstypename', header: 'Business Type Name' },
  // { field: 'termstypename', header: 'Terms Type Name' },
  // { field: 'termsdescription', header: 'Terms Description' },
  // { field: 'termsandconditionstatusname', header: 'Status' }
  GetTermsAndConditionList() {
    let TermsAndConditionList = [];
    this._termsandconditionviews.forEach(element => {
      let TermsAndCondition: any = {};
      TermsAndCondition["Party Type Name"] = element.partytypename;
      TermsAndCondition["Business Type Name"] = element.businesstypename;
      TermsAndCondition["Terms Type Name"] = element.termstypename;
      TermsAndCondition["Terms Description"] = element.termsdescription;
      TermsAndCondition["createdon"] = this.datepipe.transform(element.createdon,'dd/MM/yyyy');
      TermsAndCondition["modifiedon"] = this.datepipe.transform(element.modifiedon, 'dd/MM/yyyy');
      TermsAndCondition["Status"] = element.termsandconditionstatusname;
      TermsAndConditionList.push(TermsAndCondition);
    });
    return TermsAndConditionList;
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._TermsandconditionService.Cancel(event.termsandconditionid).subscribe((result) => {

      const resutJSON = JSON.parse(JSON.stringify(result));
      if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
        this._TermsandconditionService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._termsandconditionviews = resultJSON.termsandconditionviews;
          this._IsProgressSpinner = false;

          // this._Add = this._CountryService._Action._Add; 
          // this._delete = this._CountryService._Action._Delete;
          // this._update = this._CountryService._Action._Update;
          // this._View = this._CountryService._Action._View;
        });
      }
      else {
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },

      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Terms & Condition <b>"' + event.termsdescription + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
}
