import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class UserscreenmappingService {

  public _Action: IAction;
  public _UserscreenmappingService = environment.UserscreenmappingService;
  public _httpOptions;
  token: any;
  header: any;


  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(): Observable<any> { 
    const headers = this.header;
    var Query = this._UserscreenmappingService;

    return this.http.get<{ data: any }>(Query, { headers });
    //return this.http.get(Query);

  }

  create(p_Userscreenmapping: object) { 
    const headers = this.header;
    console.log("Create");
    var Query = this._UserscreenmappingService;

    return this.http.post<{ data: any }>(Query, p_Userscreenmapping, { headers });
  }
  getModuleandFunction(moduleid: number[], userid: number, roleid: number) { 
    const headers = this.header;
    let paramvalue = "ModuleId="
    paramvalue = paramvalue + moduleid.join('&ModuleId=');

    var Query = this._UserscreenmappingService + '/getModuleFunctions/' + userid + '/' + roleid + '/?' + paramvalue;


    console.log("functions");
    return this.http.get<{ data: any }>(Query, { headers });
  }
  getUsers(roleid: number, userid: number) { 
    const headers = this.header;
    var Query = this._UserscreenmappingService + '/getUsers/' + roleid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
}



