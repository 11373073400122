import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RptinvoicedelaytimeService } from 'src/app/core/Services/reports/Stock/Rpt-invoice-delay-time/rptinvoicedelaytime.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';

@Component({
  selector: 'app-rpt-invoicedelaytime',
  templateUrl: './rpt-invoicedelaytime.component.html',
  styleUrls: ['./rpt-invoicedelaytime.component.css']
})
export class RptInvoicedelaytimeComponent implements OnInit {

  _IsProgressSpinner: boolean = false;
  _rptinvoicedelayform: FormGroup;
  _branchlists = sessionStorage["BranchID"];
  _userid: number = parseInt(sessionStorage["userid"]);
  datepipe: any;
  customerid: number[] = [];
  branchid: number[] = [];
  _branchlist: any[] = [];
  _customerlist: any[] = [];
  _ReportLists: any[] = [];
  mindate: Date;
  maxdate: Date;
  constructor(
    private fb: FormBuilder,
    private _RptinvoicedelayService: RptinvoicedelaytimeService,
    private _CustomExceptionService: CustomExceptionService,


  ) { }

  ngOnInit(): void {
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptinvoicedelayform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      customerid: [0],
    })

    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptinvoicedelayform.get("fromdate").setValue(new Date);
      this._rptinvoicedelayform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptinvoicedelayform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptinvoicedelayform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

    this._IsProgressSpinner = true;

    this._RptinvoicedelayService.PageOnload({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branches;
      this._customerlist = resultJSON.customers;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GenerateReport(event) {
    var _fromdate = this._rptinvoicedelayform.get("fromdate").value;
    var _todate = this._rptinvoicedelayform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (!fromdate) {
      this._CustomExceptionService.handleError("Please select from date");
      return;
    }
    if (!todate) {
      this._CustomExceptionService.handleError("Please select to date");
      return;
    }
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    var Branch = this.branchid;
    var Customer = this.customerid;

    let data = {
      "fromdate": fromdate,
      "todate": todate,
      "branches": Branch,
      "Customers": Customer,
    }
    this._IsProgressSpinner = true;
    this._RptinvoicedelayService.GenerateReport(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ReportLists = [];
      this._ReportLists = resultJSON.reportList;
      if (!resultJSON.reportList || !resultJSON.reportList.length) {
        this._CustomExceptionService.handleWarning("No Data Available");
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
  }
  clear($event) {
    this.branchid = [];
    this.customerid = [];
    this._ReportLists = [];
    this._rptinvoicedelayform.reset();
    this.ngOnInit();
  }

}
