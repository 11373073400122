import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class OrderstatusService {
  public _Action : IAction ;
  public  _OrderService = environment.OrderStatusService;
  public  _MailService = environment.MailService;

  public _httpOptions;

  constructor(private http:HttpClient) { }

  PageOnload(id:number): Observable<any> {
    var Query = this._OrderService+ '/' + id ;
    // console.log(Query);
    return this.http.get<{data:any}>(Query);
  }
  
  Orderview(orderstatus:object){
    var Query = this._OrderService + '/OrderView';
    return this.http.post<{ data: any }>(Query,orderstatus);
  }
  
ViewPageload(orderid:number){
  var Query = this._OrderService+ '/ViewPageload' +'/'+ orderid ;
    // console.log(Query);
    return this.http.get<{data:any}>(Query);
}
// Update(orderid:object){
//   var Query = this._OrderService+ '/Update';
//     console.log(Query);
//     return this.http.get<{data:any}>(Query);
// }
// Update(orderid:object[])
// {
//   // console.log("customermanageaddress");
//   var Query = this._OrderService+'/Update';
//   console.log(Query);
//   return this.http.get<{data:any}>(Query,orderid);
// }
Update(orderid : object[])
  {
    // console.log("Update");
    var Query = this._OrderService+'/Update';
    // console.log(Query);
    return this.http.put<{data:any}>(Query,orderid);
  }
 
  // OrderStatusMail() {
  //   console.log("Create");
  //   var Query = this._MailService +'/OrderStatusMail';
  //   return this.http.post<{ data: any }>(Query);
  // }
  OrderStatusMail() {
    var Query = this._MailService + '/OrderStatusMail';
    // console.log(Query)
    return this.http.get<{ data: any }>(Query, this._httpOptions).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));

      return data;
    }));
  }
  GetDescription(orderstatus:string,substatus:string) {
    var Query = this._OrderService + '/GetDescription/'+orderstatus +'/'+ substatus;

    return this.http.get<{ data: any }>(Query, this._httpOptions).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));

      return data;
    }));
  }
  GenerateShippmentID(orderstatus:object) {
    var Query = this._OrderService + '/GenerateShippmentID'
    // console.log(Query);
    return this.http.post<{ data: any }>(Query,orderstatus);
  }

  PaymentReconcilation(orderno:string){
    var Query = this._OrderService+ '/PaymentReconcilation' +'/'+ orderno ;
      // console.log(Query);
      return this.http.get<{data:any}>(Query);
  }
  CancelOrder(cancelorder:object) {
    var Query = this._OrderService + '/CancelOrder'
    // console.log(Query);
    return this.http.post<{ data: any }>(Query,cancelorder);
  }
  ReturnOrderPopup(cancelorder:object) {
    var Query = this._OrderService + '/ReturnOrderPopup'
    // console.log(Query);
    return this.http.post<{ data: any }>(Query,cancelorder);
  }
  ReturnOrder(cancelorder:object) {
    var Query = this._OrderService + '/ReturnOrder'
    // console.log(Query);
    return this.http.post<{ data: any }>(Query,cancelorder);
  }
}
