import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService {

  public _Action: IAction;
  public _ReceiptService = environment.ReceiptService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 1)
  }
  createHeaders(token: any, type: number): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(receiptid: number, userid: number, branchid: number, voucherno: string): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ReceiptService + '/PageOnload' + '/' + receiptid + '/' + userid + '/' + branchid + '/' + voucherno;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ReceiptService + '/FetchAll' + '/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }
  create(receipt: object) {
    const headers = this.createHeaders(this.token, 2)
    console.log("Create");
    var Query = this._ReceiptService;


    return this.http.post<{ data: any }>(Query, receipt, { headers });
  }
  // view(p_ReceiptCode : string)
  // {
  //   var Query = this._ReceiptService + '/' + p_ReceiptCode   ;
  //  
  //   return this.http.get<{data:any}>(Query);
  // }

  Cancel(voucherno: string, series: number, modifiedby: string): Observable<any> {
    const headers = this.createHeaders(this.token, 2)
    console.log("cancel");
    var Query = this._ReceiptService + '/Cancel/' + voucherno + '/' + series + '/' + modifiedby;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchBillDetails(obj: object) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ReceiptService + '/FetchBillDetails';

    return this.http.post<{ data: any }>(Query, obj, { headers });
  }
  GetCustomerByPartyType(userid: number, partyType: number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ReceiptService + '/GetCustomerByPartyType' + '/' + userid + '/' + partyType;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  GetToAccountClosingBalance(ledgerid: number, partytype: number) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ReceiptService + '/GetToAccountClosingBalance/' + ledgerid + '/' + partytype;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  SendMail(userid: number, branchid: number, Voucherno: string) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ReceiptService + '/ReceiptMail' + '/' + userid + '/' + branchid + '/' + Voucherno;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  ReceiptPrint(obj: any) {
    const headers = this.createHeaders(this.token, 2)
    var Query = this._ReceiptService + '/RECEIPTPRINT';
    return this.http.post(Query, obj, { responseType: 'blob', headers  });
  }
}