import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import { Supplier } from 'src/app/core/Models/Supplier';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { RptpurchaseordersummaryService } from 'src/app/core/Services/reports/Purchase/RptPurchaseOrderSummary/rptpurchaseordersummary.service';
@Component({
  selector: 'app-rptpurchaseordersummary',
  templateUrl: './rptpurchaseordersummary.component.html',
  styleUrls: ['./rptpurchaseordersummary.component.css']
})
export class RptpurchaseordersummaryComponent implements OnInit {
  _IsPO: boolean = true;
  _IsStocktransfer: boolean = false;
  _supplierlist: Supplier[];
  _branchlist: Branch[];
  _currencylist: Currency[];
  _inwardtypelist: Metadatum[];
  _purchaseinwardsummaryform: FormGroup;
  _branchid = sessionStorage["currentbranchid"];
  _userid: number;
  _src: SafeResourceUrl;
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _IsProgressSpinner: boolean = true;
  _PurchaseInwardPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  _Action: IAction;
  mindate: Date;
  maxdate: Date;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptpurchaseordersummaryService: RptpurchaseordersummaryService,
    private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(114);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._purchaseinwardsummaryform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      productid: [0],
      currencyid: [0],
      supplierorbranchid: [0]
    })
    this._userid = parseInt(sessionStorage["userid"]);
    this._IsProgressSpinner = true;
    if (sessionStorage['Environmentenddate'] == "null") {
      this._purchaseinwardsummaryform.get("fromdate").setValue(new Date);
      this._purchaseinwardsummaryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._purchaseinwardsummaryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._purchaseinwardsummaryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._purchaseinwardsummaryform.controls['currencyid'].setValue(1);
    this._RptpurchaseordersummaryService.PageOnload(this._branchid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._supplierlist = resultJSON.supplierList;
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._producttypelist = resultJSON.producttypeList;
      this._purchaseinwardsummaryform.controls['branchid'].setValue(this._branchid);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._purchaseinwardsummaryform.reset();
    this.productcatid = [];
    this.producttypeid = [];
    this.productid = [];
    this._productcategorylist = [];
    this._productlist = [];
    if (sessionStorage['Environmentenddate'] == "null") {
      this._purchaseinwardsummaryform.get("fromdate").setValue(new Date);
      this._purchaseinwardsummaryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._purchaseinwardsummaryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._purchaseinwardsummaryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._purchaseinwardsummaryform.controls['currencyid'].setValue(1);
    this._purchaseinwardsummaryform.controls['branchid'].setValue(this._branchid);
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  GenerateReport(event) {
    this._IsProgressSpinner = true;
    var _fromdate = this._purchaseinwardsummaryform.get("fromdate").value;
    var _todate = this._purchaseinwardsummaryform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    var supplierid = this._purchaseinwardsummaryform.get("supplierid").value;
    var Currencyid = this._purchaseinwardsummaryform.get("currencyid").value;
    var branchid = this._purchaseinwardsummaryform.get("branchid").value == null ? this._branchid : this._purchaseinwardsummaryform.get("branchid").value;
    var productid = this._purchaseinwardsummaryform.get("productid").value == null ? 0 : this._purchaseinwardsummaryform.get("productid").value;
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this._PurchaseInwardPrintObj = {
      "Productcategory": this.productcatid || [],
      "Producttype": this.producttypeid,
      "Productheader": this.productid,
      "branchid": branchid,
      "SupplieridOrBranchName": supplierid,
      "FromDate": fromdate,
      "ToDate": todate,
      "userid": this._userid,
      "Currencyid": Currencyid,
      "Currentbranchid": parseInt(sessionStorage["BranchID"])
    };
    this._RptpurchaseordersummaryService.Print(this._PurchaseInwardPrintObj).subscribe((result) => {
      if (result.type != mediaType) {
        let reader = new FileReader();
        var blob = new Blob([result], { type: 'application/json' });
        let message = reader.readAsText(blob);
        return;
      }
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, async (error) => { const message = await error.error.text(); this._IsProgressSpinner = false; this._CustomExceptionService.handleError(message); });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    console.log(selectedProductType);
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptpurchaseordersummaryService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("category", resultJSON);
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      console.log(selectedProductCategory);
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
      this._RptpurchaseordersummaryService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productList;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
}