<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_contraform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3> Contra</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple title="Save" type="submit" icon="pi pi-save"
                                    [disabled]="_isSaveDisable"></button>
                                <button pButton pRipple title="Clear" icon="pi pi-trash" class="p-button-danger"
                                    (click)="reset($event)" [disabled]="_isClearDisable"></button>
                                <button pButton pRipple title="Send To Approval" icon="las la-check" type="button"
                                    [disabled]="_isDisabledsendtoapproval" (click)="SendToApproval()"></button>
                                <button pButton pRipple icon="pi pi-search" title="Back to Search"
                                    class="p-button p-button-success p-mr-2" (click)=" goBack($event)"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_fromaccounts" optionLabel="name"
                                        optionValue="ledgerid" formControlName="fromaccountname" [virtualScroll]="true"
                                        itemSize="30" [filter]="true" filterBy="name" [showClear]="true"
                                        (onChange)="getfromaccountbalance($event)">
                                    </p-dropdown>
                                    <label for="fromAccount">Form Account Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _contraform.get('fromaccountname').touched) && _contraform.get('fromaccountname').errors?.SelectFromAccountName">
                                    Please Select From Account Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_contrabalanceform">
                                    <span class="p-float-label">
                                        <input type="number" name="fromaccountbalance" disabled
                                            formControlName="fromaccountbalance" pInputText>
                                        <label for="fromaccountbalance">Account Balance (in USD)</label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_toaccounts" optionLabel="name"
                                        optionValue="ledgerid" formControlName="toaccountname" [filter]="true"
                                        filterBy="name" [showClear]="true" [virtualScroll]="true" itemSize="30"
                                        (onChange)="gettoaccountbalance($event)">
                                    </p-dropdown>
                                    <label for="toaccountname">To Account Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _contraform.get('toaccountname').touched) && _contraform.get('toaccountname').errors?.SelectToAccountName">
                                    Please Select To Account Name
                                </span>
                            </td>
                            <td>
                                <form [formGroup]="_contrabalanceform">
                                    <span class="p-float-label">
                                        <input type="number" pInputText name="toaccountbalance"
                                            formControlName="toaccountbalance" disabled>
                                        <label for="toaccountbalance">Account Balance (in USD)</label>
                                    </span>
                                </form>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_currencies"
                                        (onChange)="ChangeCurrencies($event)" optionLabel="currencyname"
                                        optionValue="currencyid" formControlName="currentcurrencyid">
                                    </p-dropdown>
                                    <label for="currentcurrencyid"> Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _contraform.get('currentcurrencyid').touched) && _contraform.get('currentcurrencyid').errors?.SelectCurrencyName">
                                    Please Select Currency Name
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" name="exchangerateinusd" formControlName="exchangerateinusd"
                                        disabled pInputText>
                                    <label for="exchangerateinusd">Exchange Rate </label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" pInputText name="amount" formControlName="amount">
                                    <label for="amount">Amount <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _contraform.get('amount').touched) && _contraform.get('amount').errors?.AmountRequired">
                                    Please Enter Amount
                                </span>
                            </td>
                            <td rowspan="4">
                                <span class="p-float-label">
                                    <textarea rows="3" cols="30" name="narration" formControlName="narration"
                                        pInputTextarea></textarea>
                                    <label for="narration"> Narration</label>
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </tr>
                    </table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>