import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class TripstartService {
  public _Action: IAction;
  public _TripstartService = environment.TripstartService;
  public _httpOptions;
  token: any;
	header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
		this.header = this.createHeaders(this.token, 1)

   }
   createHeaders(token: any, type: number): HttpHeaders {
		return new HttpHeaders({
			Authorization: `Bearer ${token}`, // Include your token here	
		});
	}
  PageOnLoad(tripstartclosureid: number): Observable<any> {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._TripstartService + '/' + tripstartclosureid;
    console.log(Query);
    return this.http.get<{ data: any }>(Query, { headers });
  }
  create(tripstartclosure:object)
  {
		const headers = this.createHeaders(this.token, 2)
    console.log("Create");
    var Query = this._TripstartService  ;
    console.log(Query);
    return this.http.post<{data:any}>(Query,tripstartclosure, { headers });
  }
  FetchAll() {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._TripstartService + '/FetchAll';
    console.log(Query);
    return this.http.get<{ data: any }>(Query, { headers });

  }
  edit(tripstartclosure: FormData) {
		const headers = this.createHeaders(this.token, 2)
    var Query = this._TripstartService +'/Update';
    return this.http.post<{ data: any }>(Query, tripstartclosure, { headers });
  }
  getstockdeliverydetail(obj: object): Observable<any> {
		const headers = this.header;
		var Query = this._TripstartService + '/getstockdeliverydetail';
		return this.http.post(Query, obj, { headers });
	}
}
