import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class ProductbarcodeprintService {
  public _Action: IAction;
  public _httpOptions;
  public _ProductBarcodePrintService = environment.ProductBarcodePrintService;
  constructor(private http: HttpClient) { }
  
  PageOnload(): Observable<any> {
    var Query = this._ProductBarcodePrintService + '/PageInitialize';
    
    return this.http.get<{ data: any }>(Query);
  }

  GetProductCategory(producttype:object) {
    var Query = this._ProductBarcodePrintService+'/GetProductCategory';
    
    return this.http.post<{ data: any }>(Query, producttype);
  }
  GetProduct(productcategory:object) {
    var Query = this._ProductBarcodePrintService + '/GetProduct';
    
    return this.http.post<{ data: any }>(Query, productcategory);
  }
  
  Print(productprint:object) {
    var Query = this._ProductBarcodePrintService +'/Print';
    
    return this.http.post(Query,productprint, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }
}
