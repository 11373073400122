<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Customerwise Discount </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" class="" type="submit"
                                (click)="onSave()" [hidden]="_isSaveHide"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button"
                                [disabled]="isDisabledsendtoapproval" (click)="SendToApproval($event)"></button>
                            <!-- 30.All input fields should be cleared. -->
                            <button pButton pRipple label="" title="Clear" icon="pi pi-trash" class=""
                                [disabled]="_isClearHide" (click)="reset($event)" class="p-button-danger"></button>
                            <!-- 31.should redirect to search page. Search grid should load with updated data and that data should be in descending order -->
                            <!-- 34.on clicking search button , the data must be filtered and display in the grid -->
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_customerwisediscountform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Branches"
                                       [disabled]="_isClearHide"
                                        formControlName="branchid" optionValue="branchid" optionLabel="branchname">
                                    </p-dropdown>
                                    <label for="productid">Branch <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_submitted || _customerwisediscountform.get('branchid').touched) && _customerwisediscountform.get('branchid').errors?.SelectProductName">
                                Please Select Branch
                            </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="customertype" name="id"
                                        [disabled]="_action == 'view' ||_action == 'edit'"
                                        [(ngModel)]="_selectedcustomertype" [ngModelOptions]="{standalone: true}"
                                        optionLabel="name" optionValue="id" (onChange)="PageOnLoad()">
                                    </p-dropdown>
                                    <label for="name"> Customer Type <span class="hlt-txt">*</span></label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Customers" name="id"
                                        [disabled]="_action == 'view' ||_action == 'edit'"
                                        [filter]="true" filterBy="customername" [showClear]="true"
                                        formControlName="customerid"  [virtualScroll]="true" itemSize="30"
                                        optionLabel="customername" optionValue="customerid">
                                    </p-dropdown>
                                    <label for="name"> Customer <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_submitted || _customerwisediscountform.get('customerid').touched) && _customerwisediscountform.get('customerid').errors?.SelectProductName">
                                Please Select Customer
                            </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="effectivefrom"
                                        (onSelect)="OnFromDateSelect($event)" [readonlyInput]="true"
                                        dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="2020:2050">
                                    </p-calendar>
                                    <label for="effectivefrom">Effective From <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_submitted || _customerwisediscountform.get('effectivefrom').touched) && _customerwisediscountform.get('effectivefrom').errors?.EffectiveFromRequired">
                                Please Select Effective From
                            </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="effectiveto" [minDate]="minDate"
                                        [readonlyInput]="true" dateFormat="dd/mm/yy" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="2020:2050">
                                    </p-calendar>
                                    <label for="effectiveto">Effective To <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_submitted || _customerwisediscountform.get('effectiveto').touched) && _customerwisediscountform.get('effectiveto').errors?.EffectiveFromRequired">
                                Please Select Effective To
                            </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_ProductType" [(ngModel)]="_ProductTypeId"
                                            [ngModelOptions]="{standalone: true}" optionValue="producttypeid"
                                            [virtualScroll]="true" itemSize="30" [filter]="true"
                                            [disabled]="_action == 'view' ||_action == 'edit'"
                                            [disabled]="_isClearHide" optionLabel="producttypename"
                                            (onChange)="DiscountDetails()">
                                        </p-multiSelect>
                                        <label for="producttypeid">Product Type </label>
                                    </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_ProductCategory" [(ngModel)]="_ProductCategoryId"
                                        [virtualScroll]="true" itemSize="30" [filter]="true"
                                        [disabled]="_action == 'view' ||_action == 'edit'"
                                        [ngModelOptions]="{standalone: true}" optionValue="productcategoryid"
                                        [disabled]="_isClearHide" optionLabel="categoryname"
                                        (onChange)="DiscountDetails()"></p-multiSelect>
                                    <label for="productcategoryid">Product Category </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_ProductName" [(ngModel)]="_ProductId"
                                        [virtualScroll]="true" itemSize="30" [filter]="true"
                                        [disabled]="_action == 'view' ||_action == 'edit'"
                                        (onChange)="DiscountDetails()"  [ngModelOptions]="{standalone: true}" optionValue="productid"
                                        [disabled]="_isClearHide" optionLabel="productname"
                                        ></p-multiSelect>
                                    <label for="productcategoryid">Product</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_Brands" [(ngModel)]="_BrandId"
                                        [virtualScroll]="true" itemSize="30" [filter]="true"
                                        [disabled]="_action == 'view' ||_action == 'edit'"
                                        (onChange)="DiscountDetails()" [ngModelOptions]="{standalone: true}" optionValue="brandid"
                                        [disabled]="_isClearHide" optionLabel="brandname"
                                        ></p-multiSelect>
                                    <label for="productcategoryid">Brand</label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-inputNumber 
                                         name="userdiscountpercentage" [(ngModel)]="UsdDiscount"
                                         mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [ngModelOptions]="{standalone: true}" [min]="0" [max]="100"
                                        [useGrouping]="false" (onFocus)="OnSetDiscount()" (onBlur)="OnSetDiscount()"   [disabled]="_action == 'view' ||_action == 'edit'"> </p-inputNumber>
                                    <label for="userdiscountpercentage">USD Discount % <span
                                            class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-inputNumber  mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"
                                    [(ngModel)]="RandDiscount"
                                        [ngModelOptions]="{standalone: true}" [min]="0" [max]="100"
                                          (onFocus)="OnSetDiscount()" (onBlur)="OnSetDiscount()"   [disabled]="_action == 'view' ||_action == 'edit'"> </p-inputNumber>
                                    <label for="userdiscountpercentage">RAND Discount % <span
                                            class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-inputNumber  mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"
                                         name="userdiscountpercentage" [(ngModel)]="BondDiscount"
                                         [ngModelOptions]="{standalone: true}" [min]="0" [max]="100"
                                         (onFocus)="OnSetDiscount()" (onBlur)="OnSetDiscount()"   [disabled]="_action == 'view' ||_action == 'edit'"> </p-inputNumber>
                                    <label for="userdiscountpercentage">BOND Discount % <span
                                            class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-inputNumber 
                                       name="userdiscountpercentage" [(ngModel)]="BankDiscount"
                                       mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [ngModelOptions]="{standalone: true}" [min]="0" [max]="100"
                                          (onFocus)="OnSetDiscount()" (onBlur)="OnSetDiscount()"   [disabled]="_action == 'view' ||_action == 'edit'"> </p-inputNumber>
                                    <label for="userdiscountpercentage">BANK Discount % <span
                                            class="hlt-txt">*</span></label>
                                </span>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Discount Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_DiscountDetails" [rows]="50" [paginator]="true" [scrollable]="true" scrollHeight="350px"
                        [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['sno','productname','discount','i']"
                        [rowHover]="true" dataKey="productid" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        (onHeaderCheckboxToggle)="onColumnSelect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 120px;" pSortableColumn="sno">SNo <p-sortIcon
                                        field="sno"></p-sortIcon>
                                </th>
                                <th class="text-center" style="width: 120px;">Action <br>
                                    <p-tableHeaderCheckbox  [disabled]="_action == 'view' ||_action == 'edit'"></p-tableHeaderCheckbox>
                                </th>
                                <th pSortableColumn="productname">Product Name <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tempusddiscount" style="width:115px;">USD Discount<p-sortIcon
                                        field="tempusddiscount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tempranddiscount" style="width:115px;">RAND Discount<p-sortIcon
                                        field="tempranddiscount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tempbonddiscount" style="width:115px;">BOND Discount<p-sortIcon
                                        field="tempbonddiscount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tempbankdiscount" style="width:115px;">BANK Discount<p-sortIcon
                                        field="tempbankdiscount">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Customer let-i="rowIndex">
                            <tr>
                                <td style="text-align: center;width: 120px;"  >
                                    {{i+ 1}}
                                </td>
                                <td style="text-align:center;width: 120px;">
                                    <p-tableCheckbox [value]="_Customer"  [disabled]="_action == 'view'">
                                    </p-tableCheckbox>
                                </td>
                                <td>
                                    {{_Customer.productname }}
                                </td>
                                <td pEditableColumn style="width:115px;">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [(ngModel)]="_Customer.tempusddiscount"
                                                [disabled]="_action == 'view'"
                                                (input)="OnChangeDiscountGrid(_Customer)">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Customer.tempusddiscount}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn style="width:115px;">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [(ngModel)]="_Customer.tempranddiscount"
                                                [disabled]="_action == 'view'"
                                                (input)="OnChangeDiscountGrid(_Customer)">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Customer.tempranddiscount}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn style="width:115px;">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [(ngModel)]="_Customer.tempbonddiscount"
                                                [disabled]="_action == 'view'"
                                                (input)="OnChangeDiscountGrid(_Customer)">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Customer.tempbonddiscount}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn style="width:115px;">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [(ngModel)]="_Customer.tempbankdiscount"
                                                [disabled]="_action == 'view'"
                                                (input)="OnChangeDiscountGrid(_Customer)">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_Customer.tempbankdiscount}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <tr>
                        <td>
                            <!-- Debug -->
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>