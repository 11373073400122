import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Accounttype } from 'src/app/core/Models/Accounttype';
import { Branch } from 'src/app/core/Models/Branch';
import { Employee } from 'src/app/core/Models/Employee';
import { Employeeaccountdetail } from 'src/app/core/Models/Employeeaccountdetail';
import { Employeedocumentdetail } from 'src/app/core/Models/Employeedocumentdetail';
import { Role } from 'src/app/core/Models/Role';
import { EmployeeService } from 'src/app/core/Services/masters/employee.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { vAccountNo, vBankBranchSelect, vBankCodeSelect, vBankNameSelect, vBloodGroupSelect, vBranchName, vDateofBirthSelect, vDateofJoiningSelect, vDocumentName, vEmailID, vEmployeeCode, vEmployeeName, vEmployeeRoleSelect, vFatherName, vGenderSelect, vMaritalStatusSelect, vMobileNumber, vRelationshipSelect, vSwiftCode } from 'src/app/core/Validators/validation';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Bank } from 'src/app/core/Models/Bankmaster';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { KeyPress } from 'src/assets/js/KeyPress';
import { FileUpload } from 'primeng/fileupload';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Calendar } from 'primeng/calendar';
import { DatePipe } from '@angular/common';
import { Documentdescription } from 'src/app/core/Models/DocumentDescription';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


declare let $: any;
@Component({
  selector: 'app-c-employee',
  templateUrl: './c-employee.component.html',
  styleUrls: ['./c-employee.component.css']
})
export class CEmployeeComponent implements OnInit {
  displaydocumentgridpopup: boolean = false;
  _IsProgressSpinner: boolean = false;
  _SaveHide: boolean = false;
  _ClearHide: boolean = false;
  _removedocoumenthidden: boolean = false;
  selectedtab: number = 0;
  _employeeform: FormGroup;
  _employeeaccountdetailform: FormGroup;
  _employeedocumentdetailform: FormGroup;
  selectedFiles: File;
  _action: string = "create";
  _submitted = false;
  displayBasic: boolean;
  displayBasicpopup: boolean;
  displaydocumentpopup: boolean;
  displaydocument: boolean;
  position: string;
  position1: string;
  selectedrows: any;
  _Employee: Employee;
  _Employeeaccountdetail: Employeeaccountdetail;
  _Employeedocumentdetail: Employeedocumentdetail[] = [];
  employeeimage: SafeUrl;
  documentimage: SafeUrl;
  // Dropdowns
  _Gender: Metadatum;
  _Maritalstatus: Metadatum;
  _Bloodgroups: Metadatum;
  _Departments: Metadatum;
  _Employeroles: Role;
  _Branches: Branch;
  _Reportingpersons: Employee
  _Employestatus: Metadatum[];
  _Relationship: Metadatum[];
  _Accounttypes: Accounttype[];
  _DocumentDescriptions: Documentdescription[];
  _employeeid: number;
  imageselect: string = "";
  documentselect: string = "";
  _Employeedocumentdetails: Employeedocumentdetail[] = [];

  // For Pop Up
  Banklist: Bank;
  EBank: Bank
  _bankcode: Bank
  _bankname: Bank
  _EmployeeOBJ: any;
  _TEMPEmployeeOBJ: any;
  _LastAddedDocument: File;
  _LastAddedGridDocument: File;
  _selectedDocuments: File[] = [];// For Documents uploads
  _submitted1 = false;
  _submitted2 = false;
  isDisabledButton = false;
  today = new Date();
  maxdate: any;
  mindate: any;
  dojmindate: any;

  _Action: IAction;


  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _EmployeeService: EmployeeService, private _router: Router,
    private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, public keypress: KeyPress, private _DomSanitizationService: DomSanitizer,
    private _AccessRightsService: AccessRightsService,
    ) {
    //For Page Load  
    this._employeeid = history.state?.employeeid ? history.state?.employeeid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }


  InitializeForm() {
    this._employeeform = this.fb.group({
      companyid: [0],
      branchid: [],
      employeeid: [0],
      employeecode: [],
      employeename: ['', vEmployeeName],
      employeelastname: [],
      fathername: ['', vFatherName],
      gender: ['', vGenderSelect],
      dob: ['', vDateofBirthSelect],
      doj: ['', vDateofJoiningSelect],
      age: [],
      maritalstatus: ['', vMaritalStatusSelect],
      department: [],
      reportingperson: [],
      employeerole: ['', vEmployeeRoleSelect],
      bloodgroup: ['', vBloodGroupSelect],
      primarymobileno: ['', vMobileNumber],
      secondarymobileno: [],
      emailid: ['', vEmailID],
      address: [],
      contactpersonname: [],
      contactpersonno: [],
      contactpersonrelationship: [''],
      reportingbranch: ['', vEmployeeRoleSelect],
      employeeimageurl: [],
      employeeimagename: [],
      series: [],
      employeestatus: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [1],
      modifiedon: [],
      releavingdate: [],
      nationalid: [],
      uniformsize: [],
      employeeaccountdetails: [],
      employeedocumentdetails: []
    });
    this._employeeaccountdetailform = this.fb.group({
      employeedetailid: [0],
      employeeid: [0],
      bankcode: ['', vBankCodeSelect],
      bankname: ['', vBankNameSelect],
      swiftcode: ['', vSwiftCode],
      branchname: ['', vBranchName],
      accountholdername: [],
      accountno: ['', vAccountNo],
      accounttype: [],
      accountstatus: ['MSC00001'],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      employee: []
    });
    this.InitializeDocumentForm();
    if (this._Employestatus && this._Employestatus.length) {
      this._employeeform.get("employeestatus").setValue(this._Employestatus[0].metasubcode);
    }
  }


  InitializeDocumentForm() {
    this._employeedocumentdetailform = this.fb.group({
      employeedocumentdetailid: [0],
      employeeid: [0],
      documentid: [1],
      documentname: ['', vDocumentName],
      documentdescription: [],
      attachmenturl: [],
      attachmentname: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      employee: []
    });
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(18);
    this.utility.pageLoadScript();
    this.InitializeForm();
    if (this._action == 'view') {
      this._employeedocumentdetailform.disable();
      this._employeeaccountdetailform.disable();
      this._employeeform.disable();
      this._SaveHide = true;
      this._ClearHide = true;
      this._removedocoumenthidden = true;
      this.isDisabledButton = true;
    }
    this.maxdate = new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate());
    this._IsProgressSpinner = true;
    this._EmployeeService.PageOnLoad(this._employeeid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Gender = resultJSON.gender;
      this._Maritalstatus = resultJSON.maritalstatus;
      this._Bloodgroups = resultJSON.bloodgroups;
      this._Departments = resultJSON.department;
      this._Employeroles = resultJSON.employeeRoles;
      this._Branches = resultJSON.reportingbranches;
      this._Reportingpersons = resultJSON.reportingPerson;
      this._Employestatus = resultJSON.employeeStatus;
      this._Relationship = resultJSON.employeeRelationships;
      this._Accounttypes = resultJSON.accountTypes;
      this.Banklist = resultJSON.banks;
      this._DocumentDescriptions = resultJSON.documentdescriptions;


      // for Create
      if (this._Employestatus && this._Employestatus.length) {
        this._employeeform.get("employeestatus").setValue(this._Employestatus[0].metasubcode);
      }
      if (this._action == 'edit' || this._action == 'view') {

        this._employeeform.get("employeecode").disable();
        this._employeeform.get("employeename").disable();
        this._employeeform.get("employeelastname").disable();
        this._ClearHide = true;
        const updateJSON = JSON.parse(JSON.stringify(result));
        // For Date Format
        updateJSON.employee.dob = new Date(updateJSON.employee.dob);

        updateJSON.employee.doj = new Date(updateJSON.employee.doj);
        // updateJSON.employee.releavingdate = new Date(updateJSON.employee.releavingdate);
        if (updateJSON.employee.releavingdate == null) {

        }

        else {
          updateJSON.employee.releavingdate = new Date(updateJSON.employee.releavingdate);
        }


        updateJSON.employee.reportingbranch = parseInt(updateJSON.employee.reportingbranch);
        //updateJSON.employee.accountingyear = new Date(updateJSON.employee.accountingyear);
        // updateJSON.employee.createdon = new Date(updateJSON.employee.createdon);
        // updateJSON.employee.modifiedon = new Date(updateJSON.employee.modifiedon);
        this._employeeform.setValue(updateJSON.employee);
        if (updateJSON.employeeaccountdetails) {
          this._employeeaccountdetailform.setValue(updateJSON.employeeaccountdetails);
        }
        // return;
        this._Employeedocumentdetails = updateJSON.employeedocumentdetail || [];

        if (updateJSON.employee.employeeimageurl) {
          this.employeeimage = this._DomSanitizationService.bypassSecurityTrustUrl((updateJSON.employee.employeeimageurl));
        }
      }
      this._IsProgressSpinner = false;
    }, error => this.handleError(error));
  }

  onSelect(event) {
    this.selectedFiles = event.files[0];
  }

  onUpload() {
    if (this.selectedFiles) {
      this._EmployeeService.createWithupload(this.selectedFiles).subscribe((result) => {
      })
    }

  }
  onRemove() {
    this.selectedFiles = null
  }

  onSave() {
    this._submitted = true;
    if (this._employeeform.valid) {
      if (this._employeeaccountdetailform.valid) {
        this._employeeaccountdetailform.get("employeeid").setValue(this._employeeform.get("employeeid").value);
        this._employeedocumentdetailform.get("employeeid").setValue(this._employeeform.get("employeeid").value);
        var DateOfBirth = this._employeeform.get("dob").value;
        var DateOfJoining = this._employeeform.get("doj").value;
        var ReleavingDate = this._employeeform.get("releavingdate").value;
        var datePipe = new DatePipe("en-US");
        var pDateOfBirth = datePipe.transform(DateOfBirth, 'yyyy-MM-dd');
        var pDateOfJoining = datePipe.transform(DateOfJoining, 'yyyy-MM-dd');
        // var pReleavingDate = datePipe.transform(ReleavingDate,'yyyy-MM-dd');
        if (ReleavingDate == null) {
          this._employeeform.get("releavingdate").setValue(null);
        }
        else if (DateOfJoining > ReleavingDate) {
          var datePipe = new DatePipe("en-US");
          var pReleavingDate = datePipe.transform(ReleavingDate, 'yyyy-MM-dd');
          this._employeeform.get("releavingdate").setValue(new Date(pReleavingDate))
          return;
        }



        // else {
        //   var datePipe = new DatePipe("en-US");
        //   var pReleavingDate = datePipe.transform(ReleavingDate, 'yyyy-MM-dd');

        //   this._employeeform.get("releavingdate").setValue(new Date(pReleavingDate))
        // }

        //For Model and Form Mapping
        this._Employee = this._employeeform.getRawValue();
        this._Employee.dob = (new Date(pDateOfBirth));
        this._Employee.doj = (new Date(pDateOfJoining));
        // this._Employee.releavingdate=(new Date(pReleavingDate));
        this._Employeeaccountdetail = this._employeeaccountdetailform.getRawValue();
        // this._Employeedocumentdetail = this._employeedocumentdetailform.value;
        //this._Employeedocumentdetail = this._employeedocumentdetailform.getRawValue();
        //For Object Call
        this._IsProgressSpinner = true;
        //For Create      
        if (this._action == 'create') {
          this._Employee.createdon = new Date();
          this._Employee.modifiedon = new Date();
          this._Employeeaccountdetail.createdon = new Date();
          //this._Employeedocumentdetail.createdon = new Date();
          this._Employee.createdby = sessionStorage["userid"];
          this._Employee.branchid = sessionStorage["BranchID"];
          this._Employee.accountingyear = new Date().getFullYear();
          this._Employeeaccountdetail.createdby = parseInt(sessionStorage["userid"]);
          //this._Employeedocumentdetail.createdby = sessionStorage["userid"];

          this._EmployeeOBJ = this.formconverstion(this._Employee, this._Employeeaccountdetail, this._Employeedocumentdetails, this._selectedDocuments);
          this._EmployeeService.create(this._EmployeeOBJ).subscribe((result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
              this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
              this.reset(null);
            }
            else {
              this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            }
            this._IsProgressSpinner = false;
          },
            error => this.handleError(error));
        }
        //For Edit      
        else if (this._action == 'edit') {
          this._Employee.modifiedon = new Date();
          this._Employeeaccountdetail.modifiedon = new Date();

          this._Employee.branchid = sessionStorage["BranchID"];
          this._Employee.accountingyear = new Date().getFullYear();
          //  this._Employeedocumentdetail.modifiedon = new Date();
          this._Employee.modifiedby = sessionStorage["userid"];
          this._Employeeaccountdetail.modifiedby = sessionStorage["userid"];
          if (this._Employeeaccountdetail.createdby == null) {
            this._Employeeaccountdetail.createdby = parseInt(sessionStorage["userid"]);
          }
          if (this._Employeeaccountdetail.createdon == null) {
            this._Employeeaccountdetail.createdon = new Date();
          }
          this._EmployeeOBJ = this.formconverstion(this._Employee, this._Employeeaccountdetail, this._Employeedocumentdetails, this._selectedDocuments);

          //  this._Employeedocumentdetail.modifiedby = sessionStorage["userid"];
          this._EmployeeService.edit(this._EmployeeOBJ).subscribe((result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
              this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            }
            else {
              this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            }
            this._IsProgressSpinner = false;
          },
            error => this.handleError(error));
        }
      }
      else {
        this._CustomExceptionService.handleError(usererrors.EmployeeAccountDetails_Tab);
        this.selectedtab = 1;
        return;
      }
    }
    else {
      this._CustomExceptionService.handleError(usererrors.EmployeeDetails_Tab);
      this.selectedtab = 0;
      return;
    }

  }
  // Document Upload 
  showdocumentDialog(document: FileUpload, position1: string) {

    if (this._LastAddedDocument) {
      this.employeeimage = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedDocument));
      this.imageselect = this._LastAddedDocument.name;
    }
    if (this.employeeimage == null) {
      return;
    }
    this.position1 = position1;
    this.displaydocumentpopup = true;
  }

  showdocumentGridDialog(position: string, _Employeedocumentdetail: any) {
    let url = '';
    if (_Employeedocumentdetail.attachmenturl) {
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(_Employeedocumentdetail.attachmenturl);
      url = _Employeedocumentdetail.attachmenturl;
    }
    this.position = position;
    this.displaydocumentgridpopup = true;

    if (url)
      window.open(url, "_blank");
  }
  showemployeegrid(document: FileUpload, position1: string) {
    let url = '';
    if (this._LastAddedGridDocument) {
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedGridDocument));
      this.documentselect = this._LastAddedGridDocument.name;
      url = URL.createObjectURL(this._LastAddedGridDocument);
    }
    // if (url) {
    //   return;
    // }
    // this.position = position1;
    // this.displaydocumentgridpopup = true;

    if (url)
      window.open(url, "_blank");
  }
  //Document Select:
  onDocumentSelect(event, document) {
    this._LastAddedDocument = event.currentFiles[0];
    this._employeeform.get("employeeimagename").setValue(this._LastAddedDocument.name);
    document.clear();
  }

  //Document Clear:
  onDocumentClear() {
    this._employeeform.get("employeeimageurl").setValue(undefined);
    this._employeeform.get("employeeimagename").setValue(undefined);
    this.employeeimage = null;
    this._LastAddedDocument = null;
  }
  showPopupDialog(position: string) {
    this.position = position;
    this.displaydocumentpopup = false;
  }
  showPopupDialog1(position: string) {
    this.position1 = position;
    this.displaydocumentpopup = true;
  }

  closePopupGridDialog(position: string) {
    this.position1 = position;
    this.displaydocumentgridpopup = false;
  }
  //Form Data Creation:
  formconverstion(p_Employee: Employee, p_Employeeaccountdetail: Employeeaccountdetail, p_Employeedocumentdetails: Employeedocumentdetail[], documents: File[]) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    if (this._LastAddedDocument) {
      formData.append('profilePic', this._LastAddedDocument, this._LastAddedDocument.name);
      p_Employee.employeeimageurl = this._LastAddedDocument.name;
      p_Employee.employeeimagename = this._LastAddedDocument.name;
    }
    for (var x in documents) {
      var index = p_Employeedocumentdetails.findIndex(obj => obj.attachmentname == documents[x].name);
      if (index != -1) {
        var NewFileName = time.getTime() + '_' + documents[x].name;
        p_Employeedocumentdetails[index].attachmentname = NewFileName;
        p_Employeedocumentdetails[index].attachmenturl = NewFileName;
        if (documents[x] instanceof Blob)
          formData.append('files', documents[x], NewFileName);
        else
          formData.append('files', NewFileName);
      }
    }

    var EmployeeOBJ = { "Employee": p_Employee, "Employeeaccountdetail": p_Employeeaccountdetail, "Employeedocumentdetail": p_Employeedocumentdetails };
    var Employee = JSON.stringify(EmployeeOBJ);
    formData.append('strEmployee', Employee);
    return (formData);
  }

  onDocumentEmployeeSelect(event, document) {
    this._LastAddedGridDocument = event.currentFiles[0];
    this.documentselect = this._LastAddedGridDocument.name;
    this._employeedocumentdetailform.get("attachmentname").setValue(this._LastAddedGridDocument.name);
    document.clear();
  }

  onDocumentEmployeeClear() {
    // this._submitted2 = true;
    // this._employeedocumentdetailform.reset();
    // this.InitializeDocumentForm();
    this._LastAddedGridDocument = null;
    this.documentimage = null;
    // this._submitted2 = false;
  }


  EmployeeClear() {
    this._submitted2 = true;
    this._employeedocumentdetailform.reset();
    this.InitializeDocumentForm();
    this._LastAddedGridDocument = null;
    this.documentimage = null;
    this._submitted2 = false;
  }
  // // Document Upload 
  // showdocumentDialog(position1: string) {
  //   this.position1 = position1;
  //   this.displaydocumentpopup = true;
  // }

  //Document Select:
  // onDocumentSelect(event, document) {
  //   this._LastAddedDocument = event.currentFiles[0];
  //   document.clear();
  // }

  //Document Clear:
  // onDocumentClear() {
  //   this._submitted2 = true;
  //   this._employeedocumentdetailform.reset();
  //   this._LastAddedDocument = null;
  //   this.InitializeDocumentForm();
  //   this._submitted1 = false;
  // }

  // Document Upload 
  onAddDocuments(event) {
    this._submitted2 = true;
    if (this._employeedocumentdetailform.valid && this._LastAddedGridDocument != null) {
      var documentdetail = new Employeedocumentdetail();
      documentdetail.employeedocumentdetailid = this._employeedocumentdetailform.get("employeedocumentdetailid").value;
      documentdetail.employeeid = this._employeedocumentdetailform.get("employeeid").value;
      documentdetail.documentid = this._employeedocumentdetailform.get("documentid").value;
      documentdetail.documentname = this._employeedocumentdetailform.get("documentname").value;
      documentdetail.documentdescription = this._employeedocumentdetailform.get("documentdescription").value;
      documentdetail.attachmenturl = URL.createObjectURL(this._LastAddedGridDocument);
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedGridDocument));
      documentdetail.attachmentname = this._LastAddedGridDocument.name;
      documentdetail.createdby = sessionStorage["userid"];
      documentdetail.modifiedby = sessionStorage["userid"];
      documentdetail.modifiedon = new Date();
      documentdetail.createdon = new Date();
      this._Employeedocumentdetails.push(documentdetail);
      this._selectedDocuments.push(this._LastAddedGridDocument);
      this.EmployeeClear();
    }
  }

  RemoveDocumentDetail(p_documentDetail: Employeedocumentdetail) {
    var index = this._Employeedocumentdetails.indexOf(p_documentDetail);
    this._Employeedocumentdetails.splice(index, 1)
  }

  reset(event) {
    this._employeeform.reset();
    this._employeeaccountdetailform.reset();
    this._employeedocumentdetailform.reset();
    this.InitializeForm();
    this.onDocumentClear();
    this.onDocumentEmployeeClear();
    this._submitted = false;
    this._Employeedocumentdetails = [];

  }
  goBack(event) {

    this._router.navigate(['/vEmployee']);
  }

  onRowSelect(event) {

  }
  onRowUnselect(event) {

  }

  onRowSelectPopup(event: any) {
    var row = event.data;
    this._employeeaccountdetailform.controls['bankcode'].setValue(row.bankcode);
    this._employeeaccountdetailform.controls['bankname'].setValue(row.bankname);
    this.displayBasic = false;
    // var employee = this._employeeform.controls['employeename'].value;
    // var employeelastname = this._employeeform.controls['employeelastname'].value || "";
    // this._employeeaccountdetailform.controls['accountholdername'].setValue(employee + ' ' + employeelastname);
    // //
    // var 
    //this._employeeaccountdetailform['accountholdername'].setValue(this._employeeform.controls['employeename'].value + '' + this._employeeform.controls['employeelastname'].value);
    //this._Employeeaccountdetail.accountholdername=this._employeeaccountdetailform.controls['employeename'].setValue+ '-'+ this._employeeaccountdetailform.controls['employeelastname'].setValue;
  }
  showBasicDialog() {
    this.displayBasic = true;

  }
  // showPopupDialog(position: string) {
  //   this.position = position;
  //   this.displayBasicpopup = true;
  // }

  // onEd() {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._TEMPEmployeeOBJ;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }

  // onDOBSelect(event: any) {
  //   if (event instanceof FocusEvent) {
  //     let split = (<string>event.target["value"]).split('/');
  //     if (split.length < 3)
  //       return;
  //     let day = parseInt(split[0]), month = parseInt(split[1]), year = parseInt(split[2]);
  //     event = new Date(year, month, day);
  //     this.mindate = new Date((year + 18), month, day);
  //   }
  //   var currentYear = (new Date()).getFullYear();
  //   var selectedYear = event.getFullYear();
  //   var age = (currentYear - selectedYear) - 1;
  //   if (age < 0) age = 0;
  //   this._employeeform.controls['age'].setValue(age);
  // }
  onDOBSelect(event: any) {
    let selectedDate: Date;
    if (event instanceof Date) {
      selectedDate = event;
    } else if (event instanceof FocusEvent) {
      let split = (<string>event.target["value"]).split('/');
      if (split.length < 3) return;
      let day = parseInt(split[0]), month = parseInt(split[1]), year = parseInt(split[2]);
      selectedDate = new Date(year, month - 1, day);
    } else {
      return;
    }
  
    this.mindate = new Date(selectedDate.getFullYear() + 18, selectedDate.getMonth(), selectedDate.getDate());
  
    var currentYear = new Date().getFullYear();
    var selectedYear = selectedDate.getFullYear();
    var age = currentYear - selectedYear;
    if (age < 0) age = 0;
    this._employeeform.controls['age'].setValue(age);
  }

  onDOJSelect(event: any) {
    let selectedDate: Date;
    if (event instanceof Date) {
      selectedDate = event;
    } else if (event instanceof FocusEvent) {
      let split = (<string>event.target["value"]).split('/');
      if (split.length < 3) return;
      let day = parseInt(split[0]), month = parseInt(split[1]), year = parseInt(split[2]);
      selectedDate = new Date(year, month - 1, day);
    } else {
      return;
    }
  
    this.dojmindate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1);
  }

  // onDOJSelect(event: any) {
  //   if (event instanceof FocusEvent) {
  //     let split = (<string>event.target["value"]).split('/');
  //     if (split.length < 3)
  //       return;
  //     let day = parseInt(split[0]), month = parseInt(split[1]), year = parseInt(split[2]);
  //     this.dojmindate = new Date(year, month, (day+1));
  //   }
  // }


  handleError(error: any) {
    this._CustomExceptionService.handleError(error);
    this._IsProgressSpinner = false;
  }

  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

}

