import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Brand } from 'src/app/core/Models/Brand';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { RptbranchwisestockService } from 'src/app/core/Services/reports/Stock/RptBranchwiseStock/rptbranchwisestock.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-rpt-stockadjsutmentreport.component',
  templateUrl: './rpt-stockadjsutmentreport.component.html',
  styleUrls: ['./rpt-stockadjsutmentreport.component.css']
})
export class RptStockadjsutmentreportComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptstockadjustmentform: FormGroup;
  _userid: number = parseInt(sessionStorage["userid"]);
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  branchid: number[] = [];
  _branchlist = sessionStorage["BranchID"];
  _producttypelist: Producttype[] = [];
  _brandlist: any;
  _Tempbrandlist: Brand;
  _ProductTypeObj: any;
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _ProductCategoryObj: any;
  _ReportLists: any[] = [];
  _StockAdjustmentObj: any;
  datepipes = new DatePipe("en-US");
  mindate: Date;
  maxdate: Date;

  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _RptbranchwisestockService: RptbranchwisestockService,
    private exportUtility: ExportUtility, private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptstockadjustmentform = this.fb.group({
      fromdate: [''],
      todate: [''],

    })
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptstockadjustmentform.get("fromdate").setValue(new Date);
      this._rptstockadjustmentform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptstockadjustmentform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptstockadjustmentform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptstockadjustmentform.get("fromdate").value
    dt.setDate(dt.getDate() - 7);
    this._rptstockadjustmentform.controls['fromdate'].setValue(dt);
    this._IsProgressSpinner = true;
    this._RptbranchwisestockService.PageOnload({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._producttypelist = resultJSON.producttypeList;
      this._brandlist = resultJSON.brandList;
      this._Tempbrandlist = resultJSON.brandList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory(event) {
    var selectedProductType = event.value;
    this.productcatid = [];
    this.productid = [];
    this._IsProgressSpinner = true;
    this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
    this._RptbranchwisestockService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productcategorylist = resultJSON.productcategories;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; }
    );
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    this.productid = [];
    this._IsProgressSpinner = true;
    this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": parseInt(sessionStorage["currentbranchid"]) };
    this._RptbranchwisestockService.getProduct(this._ProductCategoryObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productlist = resultJSON.productoverallList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; }
    );

  }
  GenerateReport(event) {
    var _fromdate = this._rptstockadjustmentform.get("fromdate").value;
    var _todate = this._rptstockadjustmentform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate== null) {
      this._CustomExceptionService.handleError("Please select From Date");
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleError("Please select To Date");
      return;
    }
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }

    this._StockAdjustmentObj = {
      "FromDate": _fromdate,
      "ToDate": _todate,
      "Producttype": this.producttypeid || [],
      "Productcategory": this.productcatid || [],
      "Productheader": this.productid || [],
      "Branchid": this.branchid || []

    };
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this._RptbranchwisestockService.StockAdjustmentPrint(this._StockAdjustmentObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ReportLists = [];
      if (!resultJSON.reportList || !resultJSON.reportList.length) {
        this._CustomExceptionService.handleError("No Data Available");
      }
      else {
        this._ReportLists = resultJSON.reportList;
      }

      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._rptstockadjustmentform.reset();
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this.branchid = [];
    this._productcategorylist = [];
    this._productlist = [];
    this._ReportLists = [];
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptstockadjustmentform.get("fromdate").setValue(new Date);
      this._rptstockadjustmentform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptstockadjustmentform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptstockadjustmentform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptstockadjustmentform.get("fromdate").value
    dt.setDate(dt.getDate() - 7);
    this._rptstockadjustmentform.controls['fromdate'].setValue(dt);
  }

  GetReportList() {
    let stockadjustmentstocklists = [];
    this._ReportLists.forEach(element => {
      let stockadjustmentstock: any = {};
      stockadjustmentstock["Stock Adjustment No"] = element.stocktakeno;
      stockadjustmentstock["Stock Adjustment Date"] = this.datepipes.transform(element.stocktakedate, 'dd/MM/yyyy');
      stockadjustmentstock["Branch Name"] = element.branchname;
      stockadjustmentstock["Product Name"] = element.productname;
      stockadjustmentstock["Lot Number"] = element.lotnumber;
      stockadjustmentstock["Closing Stock"] = element.closingstock;
      stockadjustmentstock["Actual Stock"] = element.actualstock;
      stockadjustmentstock["Damaged Stock"] = element.damagedstock;
      stockadjustmentstock["Stock Difference"] = element.stockdifference;
      stockadjustmentstock["Amount"] = element.amount;
      stockadjustmentstock["Created By"] = element.createdbyname;
      stockadjustmentstocklists.push(stockadjustmentstock);
    });
    return stockadjustmentstocklists;
  }
  exportExcel() {
    let stockadjustmentstocklists = this.GetReportList();
    this.exportUtility.exportExcel(stockadjustmentstocklists, 'Stock Adjustment Report', 'xlsx');
  }
  exportExcel1() {
    let stockadjustmentstocklists = this.GetReportList();
    this.exportUtility.exportExcel(stockadjustmentstocklists, 'Stock Adjustment Report', 'xlsx');
  }
  ExportToPdf() {
    let branchwisestocklists = this.GetReportList();
    const data = branchwisestocklists.map(Object.values);
    const head = [['Stock Adjustment No', 'Stock Adjustment Date', 'Branch Name', 'Product Name', 'Lot Number', 'Closing Stock', 'Actual Stock', 'Damaged Stock', 'Stock Difference', 'Amount', 'Created By'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        // doc.autoTable(this.exportColumns, this._ExpenseReportviews);
        doc.save('Stock Adjustment Report.pdf');
      })
    })

  }
}


