import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class CustomerwisediscountService {
  public _Action: IAction;
  public _CustomerwisediscountService = environment.CustomerwisediscountService;
  public _httpOptions;
  token: any;
  header: any;


  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`, // Include your token here	
		});
	}

  PageOnLoad(obj: object): Observable<any> {
		const headers = this.header;
    var Query = this._CustomerwisediscountService + '/PageOnload';
    return this.http.post<{ data: any }>(Query, obj, { headers });
  }
  FetchAll(userid: number) {
		const headers = this.header;
    var Query = this._CustomerwisediscountService + '/FetchAll' + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);

      return data;
    }));
  }
  getProductCategory(obj: object) {
		const headers = this.header;
    var Query = this._CustomerwisediscountService + '/getProductCategory';
    return this.http.post<{ data: any }>(Query, obj, { headers });
  }
  getProduct(obj: object) {
		const headers = this.header;
    var Query = this._CustomerwisediscountService + '/getProduct';
    return this.http.post<{ data: any }>(Query, obj, { headers });
  }
  GetProduct(product: string, branchid: number) {
		const headers = this.header;
    var Query = this._CustomerwisediscountService + '/GetProduct' + '/' + product + '/' + branchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  // Create
  create(p_Customerwisediscount: object) {
		const headers = this.header;
    console.log("Create");
    var Query = this._CustomerwisediscountService;
    return this.http.post<{ data: any }>(Query, p_Customerwisediscount, { headers });
  }
  // View

  // Edit
  edit(p_Customerwisediscount: object) {
		const headers = this.header;
    console.log("Update");
    var Query = this._CustomerwisediscountService;

    return this.http.put<{ data: any }>(Query, p_Customerwisediscount, { headers });
  }

  Cancel(customerwisedetailid: number, userid: number) {
		const headers = this.header;
    var Query = this._CustomerwisediscountService + '/ChangeStatus' + '/' + customerwisedetailid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  SendToApproval(workflowid: number, userid: number, branchid: number, customerwiseid: number) {
		const headers = this.header;
    var Query = this._CustomerwisediscountService + '/SendToApproval' + '/' + workflowid + '/' + userid + '/' + branchid + '/' + customerwiseid;

    return this.http.post<{ data: any }>(Query, '', { headers });
  }
  OnProductNameChange(productid: string, branchid: number) {
		const headers = this.header;
    var Query = this._CustomerwisediscountService + '/OnProductNameChange/' + productid + '/' + branchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  DiscountDetails(inputdata: object) {
		const headers = this.header;
    var Query = this._CustomerwisediscountService + '/DiscountDetails';
    return this.http.post<{ data: any }>(Query, inputdata, { headers });
  }
}
