import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RptlockedquantityService {
  public _RptLockedQtyService = environment.LockedQuantityService;
  token: any;
  header: any;

  constructor(private http: HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnload(userid: number, productid: number): Observable<any> {
    const headers = this.header;
    var Query = this._RptLockedQtyService + "/" + userid + "/" + productid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  getProductwiselockedqty(branchid: number, referencetype: string, referenceno: string, userid: number, requestedbranchid: number, evidenceno: string, productid: number) {
    const headers = this.header;
    var Query = this._RptLockedQtyService + '/ProductWiseLockedQuantity/' + branchid + "/" + referencetype + "/" + referenceno + "/" + userid + "/" + requestedbranchid + "/" + evidenceno + "/" + productid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  DetailWiseLockedQuantity(branchid: number, referencetype: string, userid: number, productid: number) {
    const headers = this.header;
    var Query = this._RptLockedQtyService + '/DetailWiseLockedQuantity/' + branchid + '/' + referencetype + "/" + userid + "/" + productid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetProduct(product: object) {
    const headers = this.header;
    var Query = this._RptLockedQtyService + '/GetProduct';
    return this.http.post<{ data: any }>(Query, product, { headers });
  }

}
