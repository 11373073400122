import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
import { NumberValueAccessor } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ProductpricechangeService {
  public _Action: IAction;
  public _ProductpricechangeService = environment.ProductpricechangeService;
  public _httpOptions;
  token: any;
  header: any;
  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(productratechangeid: number, branchid: number): Observable<any> {
    const headers = this.header;
    var Query = this._ProductpricechangeService + '/' + productratechangeid + '/' + branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }

  GetProductPrice(product: object) {
    const headers = this.header;
    var Query = this._ProductpricechangeService + '/GetProductPrice';
    return this.http.post<{ data: any }>(Query, product, { headers });
  }
  GetProductCategory(producttype: object) {
    const headers = this.header;
    var Query = this._ProductpricechangeService + '/GetProductCategory';
    return this.http.post<{ data: any }>(Query, producttype, { headers });
  }
  GetProductName(productcategory: object) {
    const headers = this.header;
    var Query = this._ProductpricechangeService + '/GetProductName';
    return this.http.post<{ data: any }>(Query, productcategory, { headers });
  }
  getproductdetails(productheader: object) {
    const headers = this.header;
    var Query = this._ProductpricechangeService + '/getProductDetails';
    return this.http.post<{ data: any }>(Query, productheader, { headers });
  }
  GetProduct(product: string, branchid: number) {
    const headers = this.header;
    var Query = this._ProductpricechangeService + '/GetProduct' + '/' + product + '/' + branchid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  create(pricecomparison: object) {
    const headers = this.header;
    var Query = this._ProductpricechangeService;
    return this.http.post<{ data: any }>(Query, pricecomparison, { headers });
  }
  FetchAll(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ProductpricechangeService + '/FetchAll';
    return this.http.post<{ data: any }>(Query,obj, { headers });
  }
  SendToApproval(userid: number, branchid: number, productratechangeid: number) {
    const headers = this.header;
    var Query = this._ProductpricechangeService + '/SendToApproval' + '/' + userid + '/' + branchid + '/' + productratechangeid;
    return this.http.get<{ data: any }>(Query, { headers });
  }
  getBrand(products: object) {
    const headers = this.header;
    var Query = this._ProductpricechangeService + '/getBrand';
    return this.http.post<{ data: any }>(Query, products, { headers });
  }
  getPriceHistory(obj: object) {
		const headers = this.header;
		var Query = this._ProductpricechangeService + '/getPriceHistory';
		return this.http.post(Query, obj, { headers });
	}
  datechange(int: number) {
		const headers = this.header;
		var Query = this._ProductpricechangeService + '/datechange';
		return this.http.post(Query, int, { headers });
  }
  
  Print(obj:any) {
    const headers = this.header;
    var Query = this._ProductpricechangeService + '/PPCPRINT' ;
    return this.http.post(Query,obj, { responseType: 'blob' ,  headers });
  }

}
