import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { RewardsRoutingModule } from './rewards-routing.module';
import { CCustomerwisediscountComponent } from './Customerwisediscount/c-customerwisediscount/c-customerwisediscount.component';
import { VCustomerwisediscountComponent } from './Customerwisediscount/v-customerwisediscount/v-customerwisediscount.component';
import { CSalescommissionComponent } from './Salescommission/c-salescommission/c-salescommission.component';
import { VSalescommissionComponent } from './Salescommission/v-salescommission/v-salescommission.component';
import { CSchemeComponent } from './Scheme/c-scheme/c-scheme.component';
import { VSchemeComponent } from './Scheme/v-scheme/v-scheme.component';


// prime ng control
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ChipsModule} from 'primeng/chips';
import { FieldsetModule, } from 'primeng/fieldset';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { HttpClientModule } from '@angular/common/http';
import {TabViewModule} from 'primeng/tabview';

import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { CRewardComponent } from './Reward/c-reward/c-reward.component';
import { VRewardComponent } from './Reward/v-reward/v-reward.component';
import { CCustomerslabwisediscountComponent } from './Customerslabwisediscount/c-customerslabwisediscount/c-customerslabwisediscount.component';
import { VCustomerslabwisediscountComponent } from './Customerslabwisediscount/v-customerslabwisediscount/v-customerslabwisediscount.component';
import { ProgressSpinner, ProgressSpinnerModule } from 'primeng/progressspinner';







@NgModule({
  declarations: [CCustomerwisediscountComponent, VCustomerwisediscountComponent, CSalescommissionComponent, 
    VSalescommissionComponent, CSchemeComponent, VSchemeComponent, CRewardComponent, VRewardComponent,
    CCustomerslabwisediscountComponent,VCustomerslabwisediscountComponent],
  providers:[MessageService,ConfirmationService,CustomExceptionService,DatePipe],
  imports: [
    CommonModule,
    RewardsRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChipsModule,
    FieldsetModule,
    AutoCompleteModule,
    OverlayPanelModule,
    CheckboxModule,
    PasswordModule,
    HttpClientModule,
    TabViewModule,
    ProgressSpinnerModule
  ]
})
export class RewardsModule { }
