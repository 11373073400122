<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
          </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>User-Customer Configuration</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit"  [disabled]="isDisabledsave"  title="Save" icon="pi pi-save"
                                (click)="onSave()"></button>
                            <button pButton pRipple title="Clear"   (click)="Clear($event)" icon="pi pi-times" class="p-button-danger"
                                [disabled]="!_Action._Delete"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_usercustomermappingform">
                    <table class="normal-table">
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_usernames" optionLabel="username"
                                        (onChange)="getUserMappedCustomer($event)" optionValue="userid"
                                        [filter]="true" filterBy="username" [showClear]="true" [virtualScroll]="true" itemSize="15"
                                        formControlName="userid">
                                    </p-dropdown>
                                    <label for="userid">User Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_submitted || _usercustomermappingform.get('userid').touched) && _usercustomermappingform.get('userid').errors?.UserNameRequired">
                                Please Select User Name
                            </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_Customers" [scrollable]="true" scrollHeight="450px" [rows]="50" [paginator]="true" 
                    [rowsPerPageOptions]="[50,100,150,200]"   
                        [globalFilterFields]="['customername','mobileno','mailid','address','mappedusername']" [rowHover]="true"
                        dataKey="customerid" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)"
                        (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 80px;">Action </th>
                                <th pSortableColumn="customername">Customer Name <p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="mobileno">Customer Mobile Number<p-sortIcon field="mobileno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="mailid">Customer Email ID<p-sortIcon field="mailid">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="address">Customer Address<p-sortIcon field="address">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="mappedusername">Mapped User Name<p-sortIcon field="mappedusername">
                                </p-sortIcon>
                            </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Customer>
                            <tr>
                                <td class="text-center" style="width: 80px;">
                                    <p-tableCheckbox [value]="_Customer" ></p-tableCheckbox>
                                </td>
                                <td>
                                    {{_Customer.customername}}
                                </td>
                                <td>
                                    {{_Customer.mobileno}}
                                </td>
                                <td>
                                    {{_Customer.mailid}}
                                </td>
                                <td>
                                    {{_Customer.address}}
                                </td>
                                <td>
                                    {{_Customer.mappedusername}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <tr>
                        <td>
                            <!-- Debug -->
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>