import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map,catchError} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class BankService {
 
  public _Action : IAction ;
  public  _BankService = environment. BankService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http:HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
 
  PageOnLoad(p_bankid: number): Observable<any> {
    const headers = this.header;
    var Query = this._BankService + '/' + p_bankid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll()
  {
    const headers = this.header;
    var Query = this._BankService + '/FetchAll' ;
   
    return this.http.get<{data:any}>(Query, { headers }).pipe(map(data=>{
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      // let _CityScreenAccess = resultJSON.userData.lstFunctions.find(funct=>funct.functionid=="15");
      // this._Action  = { 
      //   _functionId : 15,
      //   _functionName : "City",
      //   _Add :   _CityScreenAccess.iscreateallowed,
      //   _Delete : _CityScreenAccess.isdeleteallowed,
      //   _Update : _CityScreenAccess.iseditallowed,
      //   _View : _CityScreenAccess.isviewallowed,
      //   _Approve : _CityScreenAccess.isviewallowed
      // }   
     return data; 
    }),);
  }
  create(p_Bank:object)
  {
    const headers = this.header;
    //console.log("Create");
    var Query = this._BankService  ;

   
    return this.http.post<{data:any}>(Query,p_Bank, { headers });
  }
   edit(p_Bank : object)
  {
    const headers = this.header;
    //console.log("Update");
    var Query = this._BankService;
   
    return this.http.put<{data:any}>(Query,p_Bank, { headers });
  }
  Cancel(bankid:number,userid :number)
  {
    const headers = this.header;
    //console.log("Update");
    var Query = this._BankService + '/Cancel/' + bankid +'/'+ userid ;
   
    return this.http.get<{ data: any }>(Query, { headers });
    
  }
 
}
