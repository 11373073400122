<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Brand Wise Sales Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptbrandwisestockform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                    [minDate]="mindate" [maxDate]="maxdate" [yearNavigator]="true" yearRange="1980:2030"
                                        dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="fromdate">From Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="todate">To Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchid"
                                        [ngModelOptions]="{standalone: true}" [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="brandid">Branch Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                                        [(ngModel)]="brandid" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="brandid">Brand Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" icon="pi pi-search" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>

                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_ReportLists" [columns]="cols" [rows]="10" [paginator]="true" [scrollable]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['PRODUCTNAME']" [rowHover]="true" dataKey="productname" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" scrollHeight="300px">
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup id="colgroup"></colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of cols" style="width: 150px;text-align: center;" pSortableColumn="{{col}}"> {{col}}
                                    <p-sortIcon field="{{col}}">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-colvalues="columns">
                            <tr>
                                <td *ngFor="let col of colvalues" [ngStyle]="{'text-align': (col === 'BRANDNAME' || col === 'SALESINVOICEDATE' || col === 'BRANCHNAME') ? 'left' : 'right'}" style="width: 150px;">
                                    <ng-container *ngIf="col === 'SALESINVOICEDATE'; else notSalesDate">
                                        {{ rowData[col] | date: 'dd/MM/yyyy' }}
                                    </ng-container>
                                    <ng-template #notSalesDate>
                                        {{ rowData[col] }}
                                    </ng-template>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer" let-columns>
                            <tr>
                                <td *ngFor="let col of cols" [style.text-align]="col === 'BRANDNAME' ? 'center' : 'right'" style="width: 150px;">
                                    <ng-container>{{ calculateColumnTotal(col)}}</ng-container>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                Total Amount
                                <input type="number" [(ngModel)]="totalsum" disabled pInputText>
                            </span>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>