import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Tax } from 'src/app/core/Models/Tax';
import { DamagedproductpricedetailsService } from 'src/app/core/Services/Sales/damagedproductpricedetails.service';
import { vDamagedunitprice, vseverity } from 'src/app/core/Validators/validation';
import { Damagedgetproductpriceview } from 'src/app/core/Views/DamagedProductpricedetailView';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Router } from '@angular/router';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
  selector: 'app-c-damagedproductpricedetails',
  templateUrl: './c-damagedproductpricedetails.component.html',
  styleUrls: ['./c-damagedproductpricedetails.component.css']
})
export class CDamagedproductpricedetailsComponent implements OnInit {
  filteredProducts: any[];
  productcatid: any[] = [];
  producttypeid: any[] = [];
  _damagedproductpricedetailsform: FormGroup;
  _productid: number;
  _LevelId: number;
  _branchid = parseInt(sessionStorage["currentbranchid"]);
  _severityid: number;
  _severities: Metadatum[] = [];
  _vatpercentage: Tax[] = [];
  _action: string = "create";
  _productcategory: Productcategory[] = [];
  _producttype: Producttype[] = [];
  _productname: Productoverallview[] = [];
  _variantdetails: Productvariant[] = [];
  _damagedproductpriceOBJ: any;
  _damagedproductpriceDetails: Damagedgetproductpriceview[] = [];
  _damagedproductpriceDetail: Damagedgetproductpriceview[] = [];
  _currency: Currency[] = [];
  _validate: boolean;
  _userid: number;
  _severity: number;
  _damagedproductprice: Damagedgetproductpriceview;
  isDisabledsave = false;
  isDisablereset = false;
  isDisabledsendtoapproval = false;
  private _unitPrice: number;
  _IsProgressSpinner: boolean = true;
  _submitted = false;
  isDisableproducttype = false;
  isDisablecategory = false;
  isDisableproductname = false;
  isDisablevariant = false;
  isDisableseverity = false;
  isDisablecurrencyid = false;
  isDisabledamaged = false;
  isDisabledvat = false;
  isDisabledmarkuppercentage = false;
  Products: any;
  _IsVatParcentage = false;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _Product: any;
  _Action: IAction;
  iscommonmarkuppercentagedisabled: boolean = true;


  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _DamagedproductpricedetailsService: DamagedproductpricedetailsService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService

  ) { this.HotKeyIntegration(); }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  goBack(event) {
    this._router.navigate(['/vdamagedproductpricedetails']);
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(85);
    this.utility.pageLoadScript();
    this._damagedproductpricedetailsform = this.fb.group({
      productid: [],
      severity: ['', vseverity],
      markuplevelid: [],
      vatpercentageid: [],
      markuppercentage: [],
      usd: [],
      rand: [],
      zim1: [],
      zim2: [],
      tempUsd: [],
      tempRand: [],
      tempZim1: [],
      tempZim2: [],
      status: [],
      statusremarks: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      currencyid: [],
      unitprice: [],
      damagedunitprice: ['', vDamagedunitprice],
      purchaserate: [],
      productdetailid: [0],
      productname: [],
      damagedproductpricestatusid: [1],
      currencyname: [],
      taxpercentage: [],
      taxid: [],
      producttypeid: [],
      productcategoryid: [],
      productvariantdetailid: [],
      commonmarkuppercentage: []
    });
    this._productid = history.state?.productid ? history.state?.productid : 0;
    this._severityid = history.state?.severity ? history.state?.severity : 0;
    this._severity = history.state?.severity ? history.state?.severity : 0;
    this._LevelId = history.state?.markuplevelid ? history.state?.markuplevelid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._DamagedproductpricedetailsService.PageOnLoad(this._productid, this._severityid, this._LevelId, this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._producttype = resultJSON.producttypes;
      this._vatpercentage = resultJSON.taxes
      this._currency = resultJSON.currencies;
      this._severities = resultJSON.severities;
      this._damagedproductpricedetailsform.get("commonmarkuppercentage").disable();
      this._IsProgressSpinner = false;
      if (this._action == 'create') {
        this.isDisabledsave = false;
        this.isDisablereset = false;
        this.isDisabledsendtoapproval = true;
      }
      if (this._action == 'view' || this._action == 'edit') {
        this._damagedproductpricedetailsform.get("commonmarkuppercentage").enable();
        const updateJSON = JSON.parse(JSON.stringify(result));
        this._productcategory = resultJSON.productcategorylist;
        this._vatpercentage = updateJSON.taxes;
        this._damagedproductpriceDetails = updateJSON.damagedproductpricedetailviews;
        this._damagedproductpricedetailsform.get("damagedunitprice").setValue(updateJSON.damagedproductpricedetailviews[0].damagedunitprice);
        this._damagedproductpricedetailsform.get("severity").setValue(updateJSON.damagedproductpricedetailviews[0].metasubdescription);
        this._damagedproductpricedetailsform.get("severity").setValue(updateJSON.damagedproductpricedetailviews[0].metasubid);
        this._productname = updateJSON.damagedproductpricedetailviews;
        this._damagedproductpricedetailsform.get("currencyid").setValue(updateJSON.damagedproductpricedetailviews[0].currencyid);
        this._damagedproductpricedetailsform.get("currencyname").setValue(updateJSON.damagedproductpricedetailviews[0].currencyname);
        this._damagedproductpricedetailsform.get("unitprice").setValue(updateJSON.damagedproductpricedetailviews[0].purchaserateinusd);
        this._Product = (this._productname || []).find(f => f.productname == updateJSON.damagedproductpricedetailviews[0].productname);
        this.producttypeid = [];
        this.producttypeid.push(updateJSON.damagedproductpricedetailviews[0].producttypeid);
        this.productcatid = [];
        this.productcatid.push(updateJSON.damagedproductpricedetailviews[0].productcategoryid);
        this._damagedproductpricedetailsform.controls['producttypeid'].setValue(this.producttypeid);
        this._damagedproductpricedetailsform.controls['productcategoryid'].setValue(this.productcatid);
        this.calculateUnitPrice();
        this._IsProgressSpinner = false;
      }
      if (this._action == 'edit') {
        this.isDisabledsave = false;
        this.isDisablereset = false;
        this.isDisabledsendtoapproval = false;
        this._IsProgressSpinner = false;
      }
      if (this._action == 'view') {
        this.isDisabledsave = true;
        this.isDisablereset = true;
        this.isDisabledsendtoapproval = true;
        this._IsProgressSpinner = false;
        this.isDisableproducttype = true;
        this.isDisablecategory = true;
        this.isDisableproductname = true;
        this.isDisablevariant = true;
        this.isDisableseverity = true;
        this.isDisablecurrencyid = true;
        this.isDisabledamaged = true;
        this.isDisabledvat = true;
        this.isDisabledmarkuppercentage = true;
        this._damagedproductpricedetailsform.disable();
      }
    });
  }
  reset(event) {
    this._damagedproductpricedetailsform.reset();
    this._damagedproductpriceDetails = [];
    this.productcatid = [];
    this.producttypeid = [];
    this._Product = [];
    this._submitted = false;
  }
  onSave() {
    this.validate();
    this._submitted = true;
    if (this._damagedproductpricedetailsform.valid) {
      let DUnitPrice = this._damagedproductpricedetailsform.get("damagedunitprice").value
      if (!DUnitPrice || DUnitPrice == 0) {
        this._damagedproductpricedetailsform.get("damagedunitprice").setValue(0)
        this._damagedproductpriceDetails
        this._damagedproductpriceDetails.forEach(e => {
          e.tempUsd = 0;
          e.tempRand = 0;
          e.tempBank = 0;
          e.tempBond = 0;
        });
        this._CustomExceptionService.handleWarning("Damaged Unit Price Cannot be Empty Or Zero");
        return
      }
      if ((this._damagedproductpricedetailsform.get("damagedunitprice").value) > (this._damagedproductpricedetailsform.get("unitprice").value)) {
        this._damagedproductpricedetailsform.get("damagedunitprice").setValue(0)
        this._damagedproductpriceDetails
        this._damagedproductpriceDetails.forEach(e => {
          e.tempUsd = 0;
          e.tempRand = 0;
          e.tempBank = 0;
          e.tempBond = 0;
        });
        this._CustomExceptionService.handleWarning(usererrors.Grid_Select_01);
        return
      }
      if (this._validate == true) {
        this._damagedproductpricedetailsform.get("severity").setValue(this._damagedproductpricedetailsform.get("severity").value);
        this._damagedproductpricedetailsform.get("productid").setValue(this._Product.productid);
        for (var i = 0; i < this._damagedproductpriceDetails.length; i++) {
          this._damagedproductpriceDetails[i].severity = this._damagedproductpricedetailsform.get("severity").value;
          this._damagedproductpriceDetails[i].damagedunitprice = this._damagedproductpricedetailsform.get("damagedunitprice").value;
          this._damagedproductpriceDetails[i].createdby = parseInt(sessionStorage["userid"]);
          this._damagedproductpriceDetails[i].createdon = new Date();
          this._damagedproductpriceDetails[i].modifiedby = parseInt(sessionStorage["userid"]);
          this._damagedproductpriceDetails[i].modifiedon = new Date();
          this._damagedproductpriceDetails[i].accountingyear = new Date().getFullYear();
        }
        this._damagedproductprice = this._damagedproductpricedetailsform.value;
        this._damagedproductpriceOBJ = { "Damagedproductpricedetail": this._damagedproductpriceDetails };
        this._IsProgressSpinner = true;
        if (this._action == 'create') {
          this._damagedproductprice.createdon = new Date();
          this._damagedproductprice.createdby = sessionStorage["userid"];
          this._damagedproductprice.accountingyear = new Date().getFullYear();
          this._damagedproductprice.damagedproductpricestatusid = (this._damagedproductpricedetailsform.get("damagedproductpricestatusid").value);
          this._DamagedproductpricedetailsService.create(this._damagedproductpriceOBJ).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            this._productid = resultJSON.productId;
            this._severity = resultJSON.severity;
            if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
              this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
              this.isDisabledsendtoapproval = false;
            }
            else {
              this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            }
            this._IsProgressSpinner = false;
          }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
          this._submitted = false;
        }
        else if (this._action == 'edit') {
          this._damagedproductprice.accountingyear = new Date().getFullYear();
          this._damagedproductprice.damagedproductpricestatusid = (this._damagedproductpricedetailsform.get("damagedproductpricestatusid").value);
          this._damagedproductprice.modifiedon = new Date();
          this._damagedproductprice.modifiedby = sessionStorage["userid"];
          this._DamagedproductpricedetailsService.edit(this._damagedproductpriceOBJ).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            this._productid = resultJSON.productId;
            this._severity = resultJSON.severity;
            if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
              this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
              this.isDisabledsendtoapproval = false;
            }
            else {
              this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            }
            this._IsProgressSpinner = false;
          }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
          this._submitted = false;
        }
      }
      else {
        this._CustomExceptionService.handleWarning(usererrors.Grid_Select_00);
      }
    }
  }
  validate(): boolean {
    if (this._damagedproductpriceDetails.length > 0) {
      if (this._damagedproductpriceDetails[0].markuppercentage == 0) {
        this._validate = false;
      }
      else {
        this._validate = true;
      }
    }
    return this._validate;
  }
  calculateUnitPrice() {
    var productid = this._Product.productid;
    var purchaserateinusd;
    if (this._action == 'edit' || this._action == 'view') {
      purchaserateinusd = this._damagedproductpricedetailsform.get("unitprice").value;
    }
    else {
      purchaserateinusd = (this._productname || []).filter(f => f.productid == productid)[0].purchaserateinusd || 0;
      var _isStuffed = (this._productname || []).filter(f => f.productid == productid)[0].stuffed;
      var stuffedqty = (this._productname || []).filter(f => f.productid == productid)[0].stuffedqty;
    }
    if (purchaserateinusd != 0 && Boolean(_isStuffed) == true) {
      this._unitPrice = purchaserateinusd / Number(stuffedqty);
      this._damagedproductpricedetailsform.get("unitprice").setValue(this._unitPrice);
    }
    else {
      this._unitPrice = purchaserateinusd;
      this._damagedproductpricedetailsform.get("unitprice").setValue(this._unitPrice);
    }
  }
  onKeyupSellingPrice(_damagedproductpriceDetail: Damagedgetproductpriceview, event: any) {
    for (var i = 0; i < this._damagedproductpriceDetails.length; i++) {
      this._damagedproductpriceDetails[i].effectivefrom = new Date();
      this._damagedproductpriceDetails[i].effectiveto = new Date();
      this._damagedproductpriceDetails[i].modifiedon = new Date();
      this._damagedproductpriceDetails[i].createdon = new Date();
      this._damagedproductpriceDetails[i].modifiedby = sessionStorage["userid"];
      this.calculateUnitPrice();
      var currencies: Currency[] = this._currency;
      var usdExchangeRate = currencies.find(obj => obj.currencyid == 1).exchangerate;
      var randExchangeRate = currencies.find(obj => obj.currencyid == 2).exchangerate;
      var bondExchangeRate = currencies.find(obj => obj.currencyid == 3).exchangerate;
      var bankExchangeRate = currencies.find(obj => obj.currencyid == 4).exchangerate;
      var damagedunitprice = (this._damagedproductpricedetailsform.get("damagedunitprice").value);
      this._IsVatParcentage = false;
      if (this._damagedproductpriceDetails[i].vatpercentageid != 0 && this._damagedproductpriceDetails[i].vatpercentageid != null)
        this._IsVatParcentage = true;
      if (damagedunitprice != "") {
        if (this._IsVatParcentage) {
          var DefaultVAT = this._vatpercentage.find(obj => obj.taxcode == "VAT").taxpercentage ? this._vatpercentage.find(obj => obj.taxcode == "VAT").taxpercentage : 1;
          var VAT = DefaultVAT;
          var Taxid = this._damagedproductpriceDetails[i].vatpercentageid ? this._damagedproductpriceDetails[i].vatpercentageid : 0;
          if (Taxid != 0)
            VAT = this._vatpercentage.find(obj => obj.taxid == this._damagedproductpriceDetails[i].vatpercentageid).taxpercentage;
        }
        else {
          VAT = 0;
        }
        //For USD Calculation
        var valueInUSD = (usdExchangeRate ? usdExchangeRate : 1) * (damagedunitprice ? damagedunitprice : 0);
        var markuplevel = this._damagedproductpriceDetails[i].markuppercentage ? this._damagedproductpriceDetails[i].markuppercentage : 0;
        var valueInUSDwithMarkupPercentage = (((markuplevel / 100) * valueInUSD) + valueInUSD);
        var valueInUSDwithVatPercentage = (valueInUSDwithMarkupPercentage) * (VAT / 100)
        this._damagedproductpriceDetails[i].tempUsd = valueInUSDwithMarkupPercentage + valueInUSDwithVatPercentage;
        //For BOND Calculation
        var valueInZIM1 = (bondExchangeRate ? bondExchangeRate : 1) * (damagedunitprice ? damagedunitprice : 0);
        var markuplevel = this._damagedproductpriceDetails[i].markuppercentage ? this._damagedproductpriceDetails[i].markuppercentage : 0;
        var valueInZIM1withMarkupPercentage = ((markuplevel / 100) * valueInZIM1) + valueInZIM1;
        var valueInZIM1withVatPercentage = (valueInZIM1withMarkupPercentage) * (VAT / 100)
        this._damagedproductpriceDetails[i].tempBond = valueInZIM1withMarkupPercentage + valueInZIM1withVatPercentage;
        //For ZIM2 Calculation
        var valueInZIM2 = (bankExchangeRate ? bankExchangeRate : 1) * (damagedunitprice ? damagedunitprice : 0);
        var markuplevel = this._damagedproductpriceDetails[i].markuppercentage ? this._damagedproductpriceDetails[i].markuppercentage : 0;
        var valueInZIM2withMarkupPercentage = ((markuplevel / 100) * valueInZIM2) + valueInZIM2;
        var valueInZIM2withVatPercentage = (valueInZIM2withMarkupPercentage) * (VAT / 100)
        this._damagedproductpriceDetails[i].tempBank = valueInZIM2withMarkupPercentage + valueInZIM2withVatPercentage;
        //For Rand Calculation
        var valueInrand = (randExchangeRate ? randExchangeRate : 1) * (damagedunitprice ? damagedunitprice : 0);
        var markuplevel = this._damagedproductpriceDetails[i].markuppercentage ? this._damagedproductpriceDetails[i].markuppercentage : 0;
        var valueInrandwithMarkupPercentage = ((markuplevel / 100) * valueInrand) + valueInrand;
        var valueInZIM2withVatPercentage = (valueInrandwithMarkupPercentage) * (VAT / 100)
        this._damagedproductpriceDetails[i].tempRand = valueInrandwithMarkupPercentage + valueInZIM2withVatPercentage;
      }
      else {
        this._CustomExceptionService.handleWarning("Please Fill Damaged Unit Price");
        this._damagedproductpriceDetails[i].markuppercentage = 0;
        this._IsVatParcentage = false;
        break
      }
    }
  }
  onKeyupRatechange() {

    if (this._damagedproductpricedetailsform.get("damagedunitprice").value == 0) {
      this._damagedproductpricedetailsform.get("commonmarkuppercentage").disable();
    }

    if ((this._damagedproductpricedetailsform.get("damagedunitprice").value) > (this._damagedproductpricedetailsform.get("unitprice").value)) {
      this._damagedproductpricedetailsform.get("damagedunitprice").setValue(0);
      this._damagedproductpriceDetails
      this._damagedproductpriceDetails.forEach(e => {
        e.tempUsd = 0;
        e.tempRand = 0;
        e.tempBank = 0;
        e.tempBond = 0;
      });
      this._CustomExceptionService.handleWarning(usererrors.Grid_Select_01);
      return
    }
    else {
      for (var i = 0; i < this._damagedproductpriceDetails.length; i++) {
        this._damagedproductpricedetailsform.get("commonmarkuppercentage").enable();
        var _damagedproductpriceDetail: Damagedgetproductpriceview;
        _damagedproductpriceDetail = new Damagedgetproductpriceview();
        _damagedproductpriceDetail.effectivefrom = new Date();
        _damagedproductpriceDetail.effectiveto = new Date();
        _damagedproductpriceDetail.modifiedon = new Date();
        _damagedproductpriceDetail.createdon = new Date();
        _damagedproductpriceDetail.modifiedby = sessionStorage["userid"];
        var damagedunitprice = (this._damagedproductpricedetailsform.get("damagedunitprice").value);
        this.calculateUnitPrice();
        var currencies: Currency[] = this._currency;
        var usdExchangeRate = currencies.find(obj => obj.currencyid == 1).exchangerate;
        var randExchangeRate = currencies.find(obj => obj.currencyid == 2).exchangerate;
        var bondExchangeRate = currencies.find(obj => obj.currencyid == 3).exchangerate;
        var bankExchangeRate = currencies.find(obj => obj.currencyid == 4).exchangerate;
        if (this._IsVatParcentage) {
          var DefaultVAT = this._vatpercentage.find(obj => obj.taxcode == "VAT").taxpercentage ? this._vatpercentage.find(obj => obj.taxcode == "VAT").taxpercentage : 1;
          var VAT = DefaultVAT;
          var Taxid = _damagedproductpriceDetail.vatpercentageid ? _damagedproductpriceDetail.vatpercentageid : 0;
          if (Taxid != 0)
            VAT = this._vatpercentage.find(obj => obj.taxid == _damagedproductpriceDetail.vatpercentageid).taxpercentage;
        }
        else {
          VAT = 0;
        }
        //For USD Calculation
        var valueInUSD = (usdExchangeRate ? usdExchangeRate : 1) * (damagedunitprice ? damagedunitprice : 0);
        var markuplevel = _damagedproductpriceDetail.markuppercentage ? _damagedproductpriceDetail.markuppercentage : this._damagedproductpriceDetails[i].markuppercentage;
        var valueInUSDwithMarkupPercentage = (((markuplevel / 100) * valueInUSD) + valueInUSD);
        var valueInUSDwithVatPercentage = (valueInUSDwithMarkupPercentage) * (VAT / 100)
        _damagedproductpriceDetail.tempUsd = valueInUSDwithMarkupPercentage + valueInUSDwithVatPercentage;
        //For BOND Calculation
        var valueInZIM1 = (bondExchangeRate ? bondExchangeRate : 1) * (damagedunitprice ? damagedunitprice : 0);
        var markuplevel = _damagedproductpriceDetail.markuppercentage ? _damagedproductpriceDetail.markuppercentage : this._damagedproductpriceDetails[i].markuppercentage;
        var valueInZIM1withMarkupPercentage = ((markuplevel / 100) * valueInZIM1) + valueInZIM1;
        var valueInZIM1withVatPercentage = (valueInZIM1withMarkupPercentage) * (VAT / 100)
        _damagedproductpriceDetail.tempBond = valueInZIM1withMarkupPercentage + valueInZIM1withVatPercentage;
        //For BANK Calculation
        var valueInZIM2 = (bankExchangeRate ? bankExchangeRate : 1) * (damagedunitprice ? damagedunitprice : 0);
        var markuplevel = _damagedproductpriceDetail.markuppercentage ? _damagedproductpriceDetail.markuppercentage : this._damagedproductpriceDetails[i].markuppercentage;
        var valueInZIM2withMarkupPercentage = ((markuplevel / 100) * valueInZIM2) + valueInZIM2;
        var valueInZIM2withVatPercentage = (valueInZIM2withMarkupPercentage) * (VAT / 100)
        _damagedproductpriceDetail.tempBank = valueInZIM2withMarkupPercentage + valueInZIM2withVatPercentage;
        //For Rand Calculation
        var valueInrand = (randExchangeRate ? randExchangeRate : 1) * (damagedunitprice ? damagedunitprice : 0);
        var markuplevel = _damagedproductpriceDetail.markuppercentage ? _damagedproductpriceDetail.markuppercentage : this._damagedproductpriceDetails[i].markuppercentage;
        var valueInrandwithMarkupPercentage = ((markuplevel / 100) * valueInrand) + valueInrand;
        var valueInZIM2withVatPercentage = (valueInrandwithMarkupPercentage) * (VAT / 100)
        _damagedproductpriceDetail.tempRand = valueInrandwithMarkupPercentage + valueInZIM2withVatPercentage;

        this._damagedproductpriceDetails[i].tempRand = _damagedproductpriceDetail.tempRand;
        this._damagedproductpriceDetails[i].tempUsd = _damagedproductpriceDetail.tempUsd;
        this._damagedproductpriceDetails[i].tempBond = _damagedproductpriceDetail.tempBond;
        this._damagedproductpriceDetails[i].tempBank = _damagedproductpriceDetail.tempBank;
      }
    }
  }
  getProductCategory(event) {
    this._IsProgressSpinner = true;
    var selectedProductType = event.value;
    if (selectedProductType.length > 0) {
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": this._branchid || 0 };
      this._DamagedproductpricedetailsService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategory = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      });
    }
    else { this._IsProgressSpinner = false; }
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._damagedproductpricedetailsform.reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": this._branchid };
      this._DamagedproductpricedetailsService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productname = <Productoverallview[]>resultJSON.productoverallList || [];
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    let productList = [];
    if (this.productcatid.length) {
      productList = this._productname.filter(f => this.producttypeid.includes(f.producttypeid) && this.productcatid.includes(f.productcategoryid)) || [];
    } else {
      productList = this._productname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
    }
    this._productname = productList;
  }
  getVariant(event) {
    this._IsProgressSpinner = true;
    var selectedProductVariant = event.productid;
    if (selectedProductVariant != undefined) {
      this._DamagedproductpricedetailsService.getVariant(selectedProductVariant).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._damagedproductpriceDetails = resultJSON.productPriceDetails;
        this._productcategory = resultJSON.productcategories;
        this._IsProgressSpinner = false;
        let product = this._productname.filter(f => f.productid == selectedProductVariant)[0];
        this.producttypeid = [];
        this.producttypeid.push(product.producttypeid);
        this.productcatid = [];
        this.productcatid.push(product.productcategoryid);
        this._damagedproductpricedetailsform.controls['producttypeid'].setValue(this.producttypeid);
        this._damagedproductpricedetailsform.controls['productcategoryid'].setValue(this.productcatid);
        this.calculateUnitPrice();
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    else { this._IsProgressSpinner = false; }
  }
  SendToApproval(event) {
    var workflowid = 11;
    this._userid = sessionStorage["userid"];
    this._branchid = sessionStorage["BranchID"];
    this._IsProgressSpinner = true;
    this._DamagedproductpricedetailsService.SendToApproval(workflowid, this._userid, this._branchid, this._productid, this._severity).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
        this.reset(event);
        this.isDisabledsendtoapproval = true;
        this._IsProgressSpinner = false;
      }
      else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); }
    });
  }
  filterProducts(event) {
    if (event.query == "" || event.query == undefined) {
      return;
    }
    else {
      if (this.productcatid.length > 0) {
        let query = (<string>event.query).toLowerCase();
        this.filteredProducts = this._productname.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
        return;
      } else {
        this._IsProgressSpinner = true;
        this._DamagedproductpricedetailsService.GetProductSearch(event.query, this._branchid).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._productname = <Productoverallview[]>resultJSON.productsearchlist || [];
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
        let query = (<string>event.query).toLowerCase();
        this.filteredProducts = this._productname.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
      }
    }
  }
  markuppercentage() {
    var commonmarkuppercentage = this._damagedproductpricedetailsform.get("commonmarkuppercentage").value;
    if (commonmarkuppercentage != 0) {
      for (var i = 0; i < this._damagedproductpriceDetails.length; i++) {
        this._damagedproductpriceDetails[i].markuppercentage = commonmarkuppercentage;
        this._damagedproductpriceDetails[i].effectivefrom = new Date();
        this._damagedproductpriceDetails[i].effectiveto = new Date();
        this._damagedproductpriceDetails[i].modifiedon = new Date();
        this._damagedproductpriceDetails[i].createdon = new Date();
        this._damagedproductpriceDetails[i].modifiedby = sessionStorage["userid"];
        this.calculateUnitPrice();
        var currencies: Currency[] = this._currency;
        var usdExchangeRate = currencies.find(obj => obj.currencyid == 1).exchangerate;
        var randExchangeRate = currencies.find(obj => obj.currencyid == 2).exchangerate;
        var bondExchangeRate = currencies.find(obj => obj.currencyid == 3).exchangerate;
        var bankExchangeRate = currencies.find(obj => obj.currencyid == 4).exchangerate;
        var damagedunitprice = (this._damagedproductpricedetailsform.get("damagedunitprice").value);
        this._IsVatParcentage = false;
        if (this._damagedproductpriceDetails[i].vatpercentageid != 0 && this._damagedproductpriceDetails[i].vatpercentageid != null)
          this._IsVatParcentage = true;
        if (damagedunitprice != "") {
          if (this._IsVatParcentage) {
            var DefaultVAT = this._vatpercentage.find(obj => obj.taxcode == "VAT").taxpercentage ? this._vatpercentage.find(obj => obj.taxcode == "VAT").taxpercentage : 1;
            var VAT = DefaultVAT;
            var Taxid = this._damagedproductpriceDetails[i].vatpercentageid ? this._damagedproductpriceDetails[i].vatpercentageid : 0;
            if (Taxid != 0)
              VAT = this._vatpercentage.find(obj => obj.taxid == this._damagedproductpriceDetails[i].vatpercentageid).taxpercentage;
          }
          else {
            VAT = 0;
          }
          //For USD Calculation
          var valueInUSD = (usdExchangeRate ? usdExchangeRate : 1) * (damagedunitprice ? damagedunitprice : 0);
          var markuplevel = commonmarkuppercentage;
          var valueInUSDwithMarkupPercentage = (((markuplevel / 100) * valueInUSD) + valueInUSD);
          var valueInUSDwithVatPercentage = (valueInUSDwithMarkupPercentage) * (VAT / 100)
          this._damagedproductpriceDetails[i].tempUsd = valueInUSDwithMarkupPercentage + valueInUSDwithVatPercentage;
          //For BOND Calculation
          var valueInZIM1 = (bondExchangeRate ? bondExchangeRate : 1) * (damagedunitprice ? damagedunitprice : 0);
          var markuplevel = commonmarkuppercentage;
          var valueInZIM1withMarkupPercentage = ((markuplevel / 100) * valueInZIM1) + valueInZIM1;
          var valueInZIM1withVatPercentage = (valueInZIM1withMarkupPercentage) * (VAT / 100)
          this._damagedproductpriceDetails[i].tempBond = valueInZIM1withMarkupPercentage + valueInZIM1withVatPercentage;
          //For ZIM2 Calculation
          var valueInZIM2 = (bankExchangeRate ? bankExchangeRate : 1) * (damagedunitprice ? damagedunitprice : 0);
          var markuplevel = commonmarkuppercentage;
          var valueInZIM2withMarkupPercentage = ((markuplevel / 100) * valueInZIM2) + valueInZIM2;
          var valueInZIM2withVatPercentage = (valueInZIM2withMarkupPercentage) * (VAT / 100)
          this._damagedproductpriceDetails[i].tempBank = valueInZIM2withMarkupPercentage + valueInZIM2withVatPercentage;
          //For Rand Calculation
          var valueInrand = (randExchangeRate ? randExchangeRate : 1) * (damagedunitprice ? damagedunitprice : 0);
          var markuplevel = commonmarkuppercentage;
          var valueInrandwithMarkupPercentage = ((markuplevel / 100) * valueInrand) + valueInrand;
          var valueInZIM2withVatPercentage = (valueInrandwithMarkupPercentage) * (VAT / 100)
          this._damagedproductpriceDetails[i].tempRand = valueInrandwithMarkupPercentage + valueInZIM2withVatPercentage;
        }
        else {
          this._CustomExceptionService.handleWarning("Please Fill Damaged Unit Price");
          this._damagedproductpriceDetails[i].markuppercentage = 0;
          this._IsVatParcentage = false;
          break
        }
      }
    }
  }
}