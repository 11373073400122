import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productheader } from 'src/app/core/Models/Productheader';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { DamagedproductstockService } from 'src/app/core/Services/inventory/damagedproductstock.service';
import { Branch } from 'src/app/core/Models/Branch';
import { vBranchName, vProductName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { DamagedStockDetail } from 'src/app/core/Models/DamagedStockDetail';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
  selector: 'app-c-damagedproductstock',
  templateUrl: './c-damagedproductstock.component.html',
  styleUrls: ['./c-damagedproductstock.component.css']
})
export class CDamagedproductstockComponent implements OnInit {
  _damagedproductdetailform: FormGroup;
  _damagedproductform: FormGroup;
  _damagedproductdetails: any[] = [];
  _producttype: Producttype[];
  _productcategory: Productcategory[];
  _GRNno: any[] = [];
  _productname: Productheader[];
  _productdetails: Productoverallview[] = [];
  _tempproductname: Productoverallview[] = [];
  _product: Productheader;
  _severities: Metadatum[];
  _recordstatus: Metadatum[];
  _uomdetails: Metadatum[];
  _branches: Branch[];
  document: any;
  _action: string = "create";
  _tenderid: number;
  _submitted = false;
  _validate: boolean;
  _productid: any;
  _branchid: any
  position1: any;
  _DamagedproductOBJ: any
  displaydocumentpopup: boolean;
  _LastAddedDocument: File;
  _selectedDocuments: File[] = [];// For Documents uploads
  _selectedResourceIds: number[] = [];
  _IsProgressSpinner: boolean = true;
  _BranchID = parseInt(sessionStorage["currentbranchid"]);
  _UserID = sessionStorage["userid"];
  isDisabledSendtoapproval = true;
  _DocumentNo: any;
  filteredProducts: any[];
  productcatid: number[] = [];
  producttypeid: any[] = [];
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  DamagedQty: any
  LotNo: any
  _Action:IAction

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private utility: CommonUtilities,
    private _DamagedproductstockService: DamagedproductstockService,
    private fb: FormBuilder,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService : AccessRightsService

  ) {
    this._productid = history.state?.productid ? history.state?.tenderid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeDamagedproductdetailForm() {
    this._damagedproductdetailform = this.fb.group({
      companyid: [0],
      branchid: ['', vBranchName],
      productid: ['', vProductName],
      grnno: []
    });
    this._damagedproductdetailform.controls['branchid'].setValue(this._BranchID);
  }
  InitializeproductForm() {
    this._damagedproductform = this.fb.group({
      producttypeid: [],
      productcategoryid: []
    })
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(98);
    this.utility.pageLoadScript();
    this.InitializeDamagedproductdetailForm();
    this.InitializeproductForm();
    this._IsProgressSpinner = true;
    this._DamagedproductstockService.PageOnload(this._productid, this._BranchID).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._producttype = resultJSON.producttypes;
      this._severities = resultJSON.severities;
      this._recordstatus = resultJSON.recordStatus;
      this._branches = resultJSON.branches;
      this._damagedproductdetailform.controls['branchid'].setValue(this._BranchID);
      if (this._BranchID != 1) {
        this._damagedproductdetailform.get("branchid").disable();
      }
      this._IsProgressSpinner = false;
      if (this._action == 'view') {
        this._damagedproductdetailform.disable();
      }
      if (this._action == 'edit') {
        this.isDisabledSendtoapproval = true;
      }
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        updateJSON.tenders[0].tenderrefdate = new Date(updateJSON.tenders[0].tenderrefdate);
        updateJSON.tenders[0].enddate = new Date(updateJSON.tenders[0].enddate);
        this._damagedproductdetailform.setValue(updateJSON.tenders[0]);
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory(event) {
    var selectedtype = event.value;
    this._BranchID = this._damagedproductdetailform.get("branchid").value;
    if (selectedtype.length == 0 || selectedtype == null) {
      this._damagedproductform.controls["productcategoryid"].reset();
      this._productcategory = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedtype || [], "branchid": this._BranchID || 0 };
      this._DamagedproductstockService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategory = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    let productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
    this._productdetails = productList.length ? productList : this._tempproductname;
  }
  getProduct(event) {
    var selectedCategory = event.value;
    this._BranchID = this._damagedproductdetailform.get("branchid").value;
    if (selectedCategory.length == 0 || selectedCategory == null) {
      this._damagedproductdetailform.reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedCategory || [], "branchid": this._BranchID };
      this._DamagedproductstockService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productdetails = <Productoverallview[]>resultJSON.productsearch || [];
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
    let productList = [];
    if (this.productcatid.length) {
      productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid) && this.productcatid.includes(f.productcategoryid)) || [];
    } else {
      productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
    }
    this._productdetails = productList;
  }
  GetGRN(event) {
    this._GRNno = []
    this._damagedproductdetailform.get("grnno").reset()
    this._damagedproductdetails = []
    this.LotNo = ""
    this.DamagedQty = ""
    let selectedproduct = event.productid;
    this._IsProgressSpinner = true;
    this._BranchID = this._damagedproductdetailform.get("branchid").value;
    let TaskName = "GetGRN"
    let Data = {
      "ProductId": selectedproduct,
      "BranchId": this._BranchID,
      "TaskName": TaskName,
      "UserId": this._UserID,
      "GrnNo": null,
      "LotNo": null
    }
    this._DamagedproductstockService.getProductVariant(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (!resultJSON.grnlist) {
        let product = this._tempproductname.filter(f => f.productid == selectedproduct)[0];
        this.producttypeid = [];
        this.producttypeid.push(product.producttypeid);
        this.productcatid = [];
        this.productcatid.push(product.productcategoryid);
        this._damagedproductform.controls['producttypeid'].setValue(this.producttypeid);
        this._damagedproductform.controls['productcategoryid'].setValue(this.productcatid);
        this._IsProgressSpinner = false;
        this._CustomExceptionService.handleWarning("No Damaged GRN against this Product")
        return
      }
      this._GRNno = resultJSON.grnlist;
      this._productcategory = resultJSON.grnlist;
      this._IsProgressSpinner = false;
      let product = this._tempproductname.filter(f => f.productid == selectedproduct)[0];
      this.producttypeid = [];
      this.producttypeid.push(product.producttypeid);
      this.productcatid = [];
      this.productcatid.push(product.productcategoryid);
      this._damagedproductform.controls['producttypeid'].setValue(this.producttypeid);
      this._damagedproductform.controls['productcategoryid'].setValue(this.productcatid);
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onChangeGrnno() {
    let grnno = this._damagedproductdetailform.get("grnno").value
    let productid = this._damagedproductdetailform.get("productid").value.productid;
    let dq = this._GRNno.find(f => f.grnno == grnno && f.productid == productid).damagequantity
    this.LotNo = this._GRNno.find(f => f.grnno == grnno && f.productid == productid).damagedlotnumber
    this.DamagedQty = dq
    let TaskName = "GetDamagedDetails"
    let Data = {
      "ProductId": productid,
      "BranchId": this._BranchID,
      "TaskName": TaskName,
      "UserId": this._UserID,
      "GrnNo": grnno,
      "LotNo": this.LotNo
    }
    this._IsProgressSpinner = true;
    this._DamagedproductstockService.getProductVariant(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (!resultJSON.damagedgetproductdetailsviews) {
        this._IsProgressSpinner = false;
        this._CustomExceptionService.handleWarning("No Data Available")
        return
      }
      this._damagedproductdetails = resultJSON.damagedgetproductdetailsviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  filterProducts(event) {
    if (event.query == "" || event.query == undefined) {
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._BranchID = this._damagedproductdetailform.get("branchid").value;
      this._DamagedproductstockService.GetProductSearch(event.query, this._BranchID).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productdetails = <Productoverallview[]>resultJSON.productsearchlist || [];
        this._tempproductname = <Productoverallview[]>resultJSON.productsearchlist || [];
        let filtered: any[] = [];
        let query = (<string>event.query).toLowerCase();
        this.filteredProducts = this._productdetails.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  onSave() {
    this._submitted = true;
    if (this._damagedproductdetailform.valid) {
      let DQty: number = 0, AvailableQty: number = 0
      for (let i = 0; i < this._damagedproductdetails.length; i++) {
        AvailableQty += this._damagedproductdetails[i].oldstock || 0
        DQty += this._damagedproductdetails[i].tempDamagedstock || 0
      }
      if (DQty > AvailableQty) {
        this._CustomExceptionService.handleWarning("Sum of the Additional Stock Should be equal to the GRN Damaged Qty")
        return
      }
      if (DQty < AvailableQty) {
        this._CustomExceptionService.handleWarning("Sum of the Additional Stock Should be equal to the GRN Damaged Qty")
        return
      }
      if (DQty != AvailableQty) {
        this._CustomExceptionService.handleWarning("Sum of the Additional Stock Should be equal to the GRN Damaged Qty")
        return
      }
      if (this._action == 'create') {
        var DamagedStockDetails: DamagedStockDetail[] = [];
        Object.keys(this._damagedproductdetails).map((Index) => {
          var l_DamagedStockDetails = new DamagedStockDetail();
          l_DamagedStockDetails.damagestockid = 0
          l_DamagedStockDetails.branchid = this._damagedproductdetails[Index].branchid
          l_DamagedStockDetails.productid = this._damagedproductdetails[Index].productid
          l_DamagedStockDetails.variantid = this._damagedproductdetails[Index].variantid
          l_DamagedStockDetails.lotnumber = this._damagedproductdetails[Index].lotnumber
          l_DamagedStockDetails.parentlotnumber = null
          if (this._damagedproductdetails[Index].tempDamagedstock == null || this._damagedproductdetails[Index].tempDamagedstock == undefined) {
            this._damagedproductdetails[Index].tempDamagedstock = 0
          }
          l_DamagedStockDetails.damagedstock = this._damagedproductdetails[Index].tempDamagedstock
          l_DamagedStockDetails.severity = this._damagedproductdetails[Index].severity
          l_DamagedStockDetails.supplierid = this._damagedproductdetails[Index].supplierid
          l_DamagedStockDetails.expirydate = this._damagedproductdetails[Index].expirydate
          l_DamagedStockDetails.createdby = this._UserID
          l_DamagedStockDetails.createdon = new Date()
          DamagedStockDetails.push(l_DamagedStockDetails);
        });
        this._DamagedproductOBJ = {
          "Damagedstockdetail": DamagedStockDetails
        };
        this._IsProgressSpinner = true;
        this._DamagedproductstockService.create(this._DamagedproductOBJ).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          this.reset();
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsProgressSpinner = false;
            this.isDisabledSendtoapproval = false;
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        Object.keys(this._damagedproductdetails).map((Index) => {
          this._damagedproductdetails[Index].modifiedby = sessionStorage["userid"];
          this._damagedproductdetails[Index].modifiedon = new Date();
        })
        this._DamagedproductOBJ = {
          "Damagedproductstock": this._damagedproductdetails
        };
        this._IsProgressSpinner = true;
        this._DamagedproductstockService.edit(this._DamagedproductOBJ).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          this.reset();
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this._IsProgressSpinner = false;
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          } this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
  reset() {
    this._submitted = true;
    this._damagedproductdetailform.reset();
    this._damagedproductform.reset();
    this._damagedproductdetails = [];
    this._productcategory = [];
    this._GRNno = [];
    this.LotNo = ""
    this.DamagedQty = ""
    this._damagedproductdetailform.get("grnno").reset()
    this.InitializeDamagedproductdetailForm();
    this.InitializeproductForm();
    this._submitted = false;
    this.productcatid = [];
  }
  showdocumentDialog(position1: string) {
    this.position1 = position1;
    this.displaydocumentpopup = true;
  }
  onDocumentClear() { }
  selectedrows() { }
  deleteRow(event) { }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  SendToApproval(event) {
    this._IsProgressSpinner = true;
    this._DamagedproductstockService.SendToApproval(this._UserID, this._BranchID, this._DocumentNo).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._CustomExceptionService.handleSuccess(usererrors.DamagedProductStockSendToApproval);
      this._IsProgressSpinner = false;
      this.isDisabledSendtoapproval = true;
      this.reset();
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear() {
    this.productcatid = [];
    this._productcategory = [];
    this._damagedproductdetailform.get("productid").reset();
    this._damagedproductform.get("producttypeid").reset();
    this._damagedproductdetails = [];
    this._damagedproductdetailform.controls['branchid'].setValue(this._BranchID);
    this.LotNo = ""
    this.DamagedQty = ""
    this._damagedproductdetailform.get("grnno").reset()
  }
}