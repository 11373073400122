<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_producttypeform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Product Type</h3>
                        </div>
                        <div class="col-md-7 text-right">

                            <div class="action-btn">
                                <button pButton pRipple type="submit" title="Save" [disabled]="isDisabledSave"
                                    icon="pi pi-save"></button>
                                <!--12. clear all the fields which the data has entered -->
                                <button pButton pRipple title="Clear" type="button" icon="pi pi-trash"
                                    class="p-button-danger" (click)="reset($event)"
                                    [disabled]="isDisabledClear"></button>
                                <!-- 23.on clicking back button , it Navigates to the search screen -->
                                <button pButton pRiple icon="pi pi-search" title="Back to Search"
                                    class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                                <!-- <button pButton pRipple label="" icon="pi pi-times" class="" (click)="hideDialog()" ></button>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-toast position="bottom-right"></p-toast>

                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="producttypename" formControlName="producttypename" maxlength="100"
                                        pInputText>
                                    <label for="producttypename">Product Type Name <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <!-- 6.if "Product type  name" is not given, show error as "Please Enter Product type name" -->
                                <span class="text-danger"
                                *ngIf="(_submitted || _producttypeform.get('producttypename').touched) && _producttypeform.get('producttypename').errors?.ProductTypeNameRequired">
                                Please Enter Product Type Name
                            </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_producttypestatus"
                                        name="producttypestatus" optionLabel="metasubdescription"
                                        optionValue="metasubcode" formControlName="producttypestatus"> </p-dropdown>
                                    <label for="producttypestatus">Status </label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;">
                                <!-- Debug -->
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </tr>
                    </table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>