import { Injectable } from '@angular/core';
import { IAction } from '../../../security/IActions';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class RptlaybuyService {
  
  public _Action: IAction;
  public _httpOptions;
  public _RptlaybuyService = environment.LaybuyreportService;
  header: any;
  token: any;
  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
   }

   createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(branchwise: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptlaybuyService + '/PageInitialize';
    return this.http.post<{ data: any }>(Query, branchwise, { headers });
  }

  GetLaybuysummary(data: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptlaybuyService + '/GetLaybuysummary';

    return this.http.post<{ data: any }>(Query, data, { headers });
  }

  GetLaybuyDetails(data: object): Observable<any> {
    const headers = this.header;
    var Query = this._RptlaybuyService + '/GetLaybuyDetails';

    return this.http.post<{ data: any }>(Query, data, { headers });
  }
}
