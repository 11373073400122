import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { SupplierService } from 'src/app/core/services/masters/supplier.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Supplierview } from 'src/app/core/Views/Supplierview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-v-supplier',
  templateUrl: './v-supplier.component.html',
  styleUrls: ['./v-supplier.component.css']
})
export class VSupplierComponent implements OnInit {

  selectedrows: any;
  _supplierviews: Supplierview[];
  _supplierview: Supplierview;
  _Action: IAction;
  cols: any[];
  suppliertype: any[];
  _selectedtype: number;
  _IsProgressSpinner: boolean = true;
  _supplierstatus: string = "0";
  _statuslist: any[];
  mappedproductsdetails: boolean = false;
  _SupplierProductmapping: FormGroup;
  _productmappedlist: any[] = [];
  selectedSupplier: any;
  onesuppliermappedproductlist: boolean = false;
  _onesuppliermappedproductlist: any[] = [];
  datepipe = new DatePipe('en-US');
  _responsibleperson: number;
  _userlist: any[] = [];
  _supplierid: number;


  constructor(private fb: FormBuilder, private _SupplierService: SupplierService, private confirmationService: ConfirmationService, private router: Router, private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService, private exportUtility: ExportUtility) {
    this._Action = this._AccessRightsService.getAccessRights(19);
    this.HotKeyIntegration();
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();

    this.cols = [
      { field: 'suppliername', header: 'Supplier Name' },
      { field: 'suppliertypename', header: 'Supplier Type' },
      { field: 'paymodename', header: 'Paymode' },
      { field: 'currencyname', header: 'Currency' },
      { field: 'mobileno', header: 'Mobile No' },
      { field: 'mailid', header: 'Mail ID' },
      { field: 'address', header: 'Address' },
      { field: 'supplierstatusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
      { field: 'modifiedbyname', header: 'Modified By' },
      { field: 'modifiedon', header: 'Modified On' },
    ]
    this.suppliertype = [
      { id: 1, name: 'Quick Supplier' },
      { id: 2, name: 'Normal Supplier' }
    ]
    this._selectedtype = 2;

    this._statuslist = [
      { statusid: "MSC00001", statusname: 'Active' },
      { statusid: "MSC00002", statusname: 'Inactive' },
      { statusid: "MSC00344", statusname: 'Hold' }
    ]
    this.pageinitialization();
    this.Fetchall();
  }

  pageinitialization() {
    this._IsProgressSpinner = true;
    this._supplierid = history.state?.supplierid ? history.state?.supplierid : 0;
    this._SupplierService.PageOnLoad(this._supplierid, sessionStorage["userid"]).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._userlist = resultJSON.users;
    })
  }

  Fetchall() {
    if(!this._responsibleperson){
      this._responsibleperson = 0;
    }
    this._IsProgressSpinner = true;
    this._SupplierService.FetchAll(sessionStorage["userid"], this._selectedtype, this._supplierstatus, this._responsibleperson).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log("_responsibleperson:", this._responsibleperson);
      console.log("fetch", resultJSON);
      this._supplierviews = resultJSON.supplierviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  view(event) {
    this.router.navigate(['/cSupplier'], { state: { supplierid: event.supplierid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cSupplier']);
  }
  delete(event) {
    alert("delete");
  }

  edit(event) {

    this.router.navigate(['/cSupplier'], { state: { supplierid: event.supplierid, action: 'edit' } });

    // alert("edittest");
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to deactivate this Supplier <b>"' + event.suppliername + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.ChangeInactive(event);
      }
    });
  }
  GetTotalProducts(view) {
    var selectedSupplierId = view.supplierid;
    this.selectedSupplier = view.suppliername;
    this.mappedproductsdetails = true;
    this._IsProgressSpinner = true;
    let data = {
      "supplier": selectedSupplierId
    }
    this._SupplierService.getSupplierMappedproducts(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productmappedlist = resultJSON.supplierproductmappedlist
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
    this._IsProgressSpinner = false;

  }
  // ChangeInactive(event) {
  //   var SupplierId = event.supplierid;
  //   this._IsProgressSpinner = true;
  //   this._SupplierService.ChangeInactive(SupplierId, sessionStorage["userid"]).subscribe((result) => {

  //     const resultJSON = JSON.parse(JSON.stringify(result));
  //     if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
  //       this._supplierviews = resultJSON.supplierviews;
  //       this._CustomExceptionService.handleSuccess(usererrors.SuppilerDeactive);
  //       this._IsProgressSpinner = false;
  //     }
  //     else {
  //       this._IsProgressSpinner = false;
  //       this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
  //     }
  //   },
  //     error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  // }
  ChangeInactive(event) {
    var SupplierId = event.supplierid;
    this._IsProgressSpinner = true;
    this._SupplierService.ChangeInactive(SupplierId, sessionStorage["userid"]).subscribe((result) => {

      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._supplierviews = resultJSON.supplierviews;
        this._onesuppliermappedproductlist = resultJSON.suppliernotmappedproductlist;
        this._CustomExceptionService.handleSuccess(usererrors.SuppilerDeactive);
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        if (resultJSON.suppliernotmappedproductlist != null) {
          this.onesuppliermappedproductlist = true;
          this._onesuppliermappedproductlist = resultJSON.suppliernotmappedproductlist;
        }
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
      this.Fetchall();
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  //Export Excel Download:
  exportExcel() {
    let customerList = this.GetCustomerList();
    this.exportUtility.exportExcel(customerList, 'Supplier', 'xlsx');
  }
  exportExcelonesuppliermappedlist() {
    let productlist = this.onesuppliermappedlist();
    this.exportUtility.exportExcel(productlist, 'onesuppliermappedproductlist', 'xlsx');
  }

  ExportToPdf() {
    let customerList = this.GetCustomerList();
    const data = customerList.map(Object.values);
    //this.exportUtility.ExportToPdf(customerList, 'Supplier.pdf');
    const head = [['Supplier Name', 'Supplier Type', 'Paymode', 'Currency', 'Mobile No', 'Mail ID',
    'Status','No.of.Products Mapped','Created By','Created On','Modified By','Modified On'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        // doc.autoTable(this.exportColumns, this._ExpenseReportviews);
        doc.save('Supplier.pdf');
      })
    })

  }

  GetCustomerList() {
    let customerList = [];
    this._supplierviews.forEach(element => {
      let customer: any = {};
      customer["Supplier Name"] = element.suppliername;
      customer["Supplier Type"] = element.suppliertypename;
      customer["Paymode"] = element.paymodename;
      customer["Currency"] = element.currencyname;
      customer["Mobile No"] = element.mobileno;
      customer["Mail ID"] = element.mailid;
      customer["Status "] = element.supplierstatusname;
      customer["No.of.Products Mapped"] = element.totalproducts;
      customer["Created By"] = element.createdbyname;

      let Createdon = this.datepipe.transform(element.createdon, 'yyyy-MM-dd');
      let modifiedon = this.datepipe.transform(element.modifiedon, 'yyyy-MM-dd');

      customer["Created On"] = Createdon;
      customer["Modified By"] = element.modifiedbyname;
      customer["Modified On"] = modifiedon;
      customerList.push(customer);
    });
    return customerList;
  }
  onesuppliermappedlist() {
    let productlist = [];
    this._onesuppliermappedproductlist.forEach(element => {
      let product: any = {};
      product["Product Type"] = element.producttypename;
      product["Product Category"] = element.categoryname;
      product["Product Code"] = element.productcode;
      product["Product Name"] = element.productname;
      product["Brand"] = element.brandname;
      productlist.push(product);
    });
    return productlist;
  }
  ExportToPdfonesuppliermappedlist() {
    let productlist = this.onesuppliermappedlist();
    const data = productlist.map(Object.values);
    //this.exportUtility.ExportToPdf(customerList, 'Supplier.pdf');
    const head = [['Product Type', 'Product Category', 'Product Code', 'Product Name', 'Brand'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        // doc.autoTable(this.exportColumns, this._ExpenseReportviews);
        doc.save('onesuppliermappedproductlist.pdf');
      })
    })

  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
}
