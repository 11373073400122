<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Notification</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <button pButton pRipple label="" title="View All Notifications" icon="pi pi-eye" class="p-button-success"
                            [routerLink]="['/vNotification']"></button>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">

                <p-toast></p-toast>
                <form [formGroup]="_notificationform">
                    <table class="normal-table">
                        <tr>
                            <td style="width:20%"></td>
                            <td style="width:20%">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_Branchslist" optionLabel="branchname"
                                        optionValue="branchid" formControlName="branchid" (onChange)="OnBranchSelect($event)"
                                        (onChange)="getUsernames($event)">
                                    </p-multiSelect>
                                    <label for="producttypeid">Branch <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_submitted || _notificationform.get('branchid').touched) && _notificationform.get('branchid').errors?.SelectBranch">
                                Please select atleast one branch
                            </span>
                            </td>
                            <td style="width:20%">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_UsersList" optionLabel="username"
                                    (onChange)="Messagetodetail($event)" formControlName="userid" optionValue="userid">
                                    </p-multiSelect>
                                    <label for="producttypeid">User <span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_submitted || _notificationform.get('userid').touched) && _notificationform.get('userid').errors?.SelectBranch">
                                Please select atleast one user
                            </span>
                            </td>
                            <td style="width:20%">
                            </td>
                            <td style="width:20%"></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="2">
                                <span class="p-float-label">
                                    <textarea name="subject" rows="1" pInputTextarea formControlName="subject"></textarea>
                                    <label for="subject">Subject</label>
                                </span>
                            </td>
                            <td>

                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <div class="browse-links">
                                            <p-button  styleClass="p-button-link"
                                            label="Attachment" title="Attachment" tooltipPosition="top">
                                            </p-button>
                                        </div>
                                    </span>
                                    <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only" 
                                        pTooltip="Browse File" tooltipPosition="top" mode="basic" #document
                                        (onSelect)="onDocumentSelect($event,document)" accept="image/*,.pdf,.xlsx,.xls,.xlsm,.docx,.txt" maxFileSize="1000000" [auto]="true"
                                       >
                                    </p-fileUpload>

                                    <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                    (click)="onDocumentClear()" tooltipPosition="top">
                                        <i class="pi pi-times"></i>
                                    </button>
                                </div>
                            </td>
                            <td>
                             
                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        <tr [hidden]="IsAttachment">
                            <td></td>
                            <td colspan="3">
                                
                                <div class="attachment-lists" >
                                    <a  *ngFor="let AL of AttachmentLink" href="javascript:void(0);" (click)="OpenAttachment(AL,'bottom-right')">
                                       {{AL.attachmentname}} 
                                        <span class="close" (click)="RemoveAttachment(AL)"><i class="las la-times-circle"></i></span>
                                    </a>
                                </div>
    
                            </td>
                     <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="3">
                                <p-editor #editor [style]="{'height':'320px'}" formControlName="body">
                                    <p-header>
                                        <span class="ql-formats">
                                            <button type="button" class="ql-bold" aria-label="Bold"></button>
                                            <button type="button" class="ql-italic" aria-label="Italic"></button>
                                            <button type="button" class="ql-underline" aria-label="Underline"></button>
                                        </span>
                                    </p-header>
                                </p-editor>

                                <!-- <p-editor formControlName="subject" [style]="{'height':'320px'}">
                            <ng-template pTemplate="header">
                                <span class="ql-format-group">
                                    <span title="Bold" class="ql-format-button ql-bold"></span>
                                    <span class="ql-format-separator"></span>
                                    <span title="Italic" class="ql-format-button ql-italic"></span>
                                    <span class="ql-format-separator"></span>
                                    <span title="Underline" class="ql-format-button ql-underline"></span>
                                    <span class="ql-format-separator"></span>
                                    <span title="Strikethrough" class="ql-format-button ql-strike"></span>
                                </span>
                            </ng-template>
                        </p-editor> -->

                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td> <button pButton pRipple title="Send Email" label="Send Email" icon="las la-paper-plane" type="button"
                                (click)="SendMail()"  ></button></td>
                            <td></td>
                            <td></td>
                            <td></td>

                        </tr>
                    </table>
                </form>
                <!-- <p-dialog header="Document" [(visible)]="OpenAttachmentPopup" [position]="position"
                [style]="{width: '500px'}" [baseZIndex]="10000">
                <img [src]="Attachment" alt="" class="img-preview">
                <ng-template pTemplate="footer">
                    <p-button icon="pi pi-check" (click)="closeAttachmentPopup()" label="Ok" styleClass="p-button-text">
                    </p-button>
                </ng-template>
            </p-dialog> -->
            <table>
                <td>
                    <!-- Debug -->
                    <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                </td>
            </table>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>