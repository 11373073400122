import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Termsandcondition } from '../../Models/Termsandcondition';
import { IAction } from '../security/IActions';


@Injectable({
  providedIn: 'root'
})
export class TermsandconditionService {

 
  public _Action: IAction;
  public _TermsandconditionService = environment.TermsandconditionService;
  public _httpOptions;
  public _TermsandcondtionList: Termsandcondition[] = [];
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
   }
   createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(p_termsandconditionid: number): Observable<any> {
    const headers = this.header;
    var Query = this._TermsandconditionService + '/' + p_termsandconditionid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  FetchAll() {
    const headers = this.header;
    var Query = this._TermsandconditionService + '/FetchAll';
   
    
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      // let _CountryScreenAccess = resultJSON.userData.lstFunctions.find(funct => funct.functionid == "15");
      // this._Action = {
      //   _functionId: 15,
      //   _functionName: "Customer",
      //   _Add: _CountryScreenAccess.iscreateallowed,
      //   _Delete: _CountryScreenAccess.isdeleteallowed,
      //   _Update: _CountryScreenAccess.iseditallowed,
      //   _View: _CountryScreenAccess.isviewallowed,
      //   _Approve: _CountryScreenAccess.isviewallowed
      //}
      return data;
    }));
  }
  create(p__termsandcondition: object) {
    const headers = this.header;
    //console.log("Create");
    var Query = this._TermsandconditionService;
    return this.http.post<{ data: any }>(Query, p__termsandcondition, { headers });
  }
  edit(p__termsandcondition: object) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._TermsandconditionService;
   
    return this.http.put<{ data: any }>(Query, p__termsandcondition, { headers });
  }
  Cancel(termsandconditionid : number)
  { 
    const headers = this.header;
    var Query = this._TermsandconditionService  +'/Cancel/' + termsandconditionid ; 
   
    return this.http.get<{data:any}>(Query, { headers });
  }
 
}
