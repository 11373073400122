import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Pettycashsettlement } from 'src/app/core/Models/Pettycashsettlement';
import { Pettycashsettlementdetail } from 'src/app/core/Models/Pettycashsettlementdetail';
import { PettycashsettlementService } from 'src/app/core/services/accounts/pettycashsettlement.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Observable } from 'rxjs/internal/Observable';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { User } from 'src/app/core/Models/User';
import { vSelectSettlementBranch, vSelectSettlementDate, vSelectSettlementType, vSelectRequestNo, vSelectRequestAmount, vSelectCurrency, vSelectSettlementAccount } from 'src/app/core/Validators/validation';
import { UploadFileService } from 'src/app/core/Utilities/fileupload/upload-file.service';
import { Bank } from 'src/app/core/Models/Bankmaster';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Pettycashrequestview } from 'src/app/core/Views/pettycashview';
import { element } from 'protractor';
import { Pettycashsettlementrequestno } from 'src/app/core/Views/Pettycashsettlementrequestno';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { CurrencyVersion } from 'src/app/core/Models/CurrencyVersion';
import { PaymentService } from 'src/app/core/Services/accounts/payment.service';
@Component({
  selector: 'app-c-pettycash-settlement',
  templateUrl: './c-pettycash-settlement.component.html',
  styleUrls: ['./c-pettycash-settlement.component.css']
})
export class CPettycashSettlementComponent implements OnInit {

  _fromaccountsListTemp: Accountledgerview[] = [];
  _fromaccountsList: Accountledgerview[] = [];
  _currencyVersions: CurrencyVersion[] = [];
  _PettycashSettlementform: FormGroup;
  _PettycashSettlementDetailform: FormGroup;
  _PettycashSettlementid: number;
  _action: string = "create";
  _submitted = false;
  pettycashrequestnos: string;
  requestamount: number;
  _PettycashSettlement: Pettycashsettlement;
  _PettycashSettlementDetail: Pettycashsettlementdetail
  _PettycashSettlementOBJ: any;
  hideInCreatePage: boolean;
  displayBasic: boolean;
  displayBasic1: boolean;
  position: string;
  _Proofs: Pettycashsettlementdetail[] = [];
  _proof: Pettycashsettlementdetail;
  selectedrows: any;

  _Requests: Pettycashrequestview[];
  _Request: Pettycashsettlement;
  selectedRequest: Pettycashrequestview[];
  _CurrenciesTemp: Currency[];
  _Branches: Branch[];
  _Settlementtypes: Metadatum;
  _Currencies: Currency;
  _Settlementaccounts: Bank[];
  _status: Metadatum;
  _Users: User[];
  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  message = '';
  fileInfos: Observable<any>;

  // For File Upload:
  _LastAddedDocument: File;
  _selectedDocuments: File[] = [];
  position1: string;
  _validate: boolean;
  displaydocumentpopup: boolean;
  _PettycashSettlementFORM: any;
  _IsSaveDisable: boolean = false;
  _IsClearDisable: boolean = false;
  _IsProgressSpinner: boolean = true;
  _userid = sessionStorage["userid"]
  mindate: Date;
  maxdate: Date;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _PettycashSettlementService: PettycashsettlementService,
    private utility: CommonUtilities,
    private _PaymentService: PaymentService,
    private _uploadService: UploadFileService,
    private _router: Router,
    private _hotkeysService: HotkeysService) {
  }

  InitializeForm() {
    this._PettycashSettlementform = this.fb.group({
      pettycashsettlementid: [0],
      pettycashsettlementno: [''],
      settlementbranchid: ['', vSelectSettlementBranch],
      settlementdate: ['', vSelectSettlementDate],
      settlementtype: ['', vSelectSettlementType],
      settlementamount: [0, vSelectSettlementType],
      settledby: [0, vSelectSettlementType],
      returnamount: [],
      issettled: [],
      pettycashsettlementstatusid: [],
      pettycashsettlementstatusdesc: [],
      accountingyear: [],
      branchid: [0],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      currencyuniquerefid: [0],
      currentcurrencyid: ['', vSelectCurrency],
      exchangerateinusd: [1],
      amountinusd: [0],
      balanceamount: [0],
      pettycashsettlementdetails: [],
      pettycashsettlementrequestnos: [],
      remarks: [],
      transactionrefno: [],
      fromaccount: [0, vSelectSettlementType],
      fromaccountclosingbalance: [0],
    });
    if (sessionStorage['Environmentenddate'] == "null" ) {
			this._PettycashSettlementform.get("settlementdate").setValue(new Date);
		  }
		  else {
			let date = new Date(sessionStorage['Environmentenddate'])
			this._PettycashSettlementform.get("settlementdate").setValue(date);
		  }
    this._PettycashSettlementDetailform = this.fb.group({
      pettycashsettlemendetalid: [0],
      pettycashsettlementid: [0],
      proofvalue: [],
      settlementaccount: ['', vSelectSettlementAccount],
      attachmenturl: [],
      attachmentname: [],
      remarks: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });

    if (this._Users && this._Users.length)
      this._PettycashSettlementform.get("settledby").setValue(parseInt(sessionStorage["userid"]));

    // if (this._Branches && this._Branches.length)
    //   this._PettycashSettlementform.get("settlementbranchid").setValue(parseInt(sessionStorage["currentbranchid"]));
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();
    this._PettycashSettlementid = history.state?.pettycashsettlementid ? history.state?.pettycashsettlementid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
    this._IsProgressSpinner = true;
    this._PettycashSettlementService.PageOnLoad(this._PettycashSettlementid, sessionStorage["currentbranchid"], this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // For DropDown
      this._CurrenciesTemp = this._Currencies = (resultJSON.currencies || []);
      this._currencyVersions = resultJSON.currencyVersions;
      this._Branches = resultJSON.branches;
      this._fromaccountsListTemp = (resultJSON.fromaccountlist || []);
      this._Settlementtypes = resultJSON.cashTypes;
      this._Settlementaccounts = resultJSON.banks;
      this._status = resultJSON.recordStatus;
      this._Users = resultJSON.users;
      // this._PettycashSettlementform.controls['settledby'].setValue(sessionStorage["userid"]);
      this._Requests = resultJSON.pettycashrequestviews;
      this._IsProgressSpinner = false;

      if (this._Users && this._Users.length)
        this._PettycashSettlementform.get("settledby").setValue(parseInt(sessionStorage["userid"]));

      //For Update
      if (this._action == 'view') {
        this._PettycashSettlementform.disable();
        this._PettycashSettlementDetailform.disable();

        this._IsSaveDisable = true;
        this._IsClearDisable = true;
      }
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        updateJSON.pettycashsettlement.settlementdate = new Date(updateJSON.pettycashsettlement.settlementdate);
        this.pettycashrequestnos = updateJSON.requestnos;
        // this.requestamount = updateJSON.pettycashsettlement.requestamount;
        let settlementAmount = 0;
        this.requestamount = 0;
        (<Pettycashsettlementrequestno[]>updateJSON.pettycashsettlementrequestnos || []).forEach(element => {
          settlementAmount += parseFloat(element.settlementamount + '');
          this.requestamount += parseFloat(element.requestamount + '');
        });
        updateJSON.pettycashsettlement.settlementamount = settlementAmount;
        updateJSON.pettycashsettlement.fromaccountclosingbalance = 0;
        // this._PettycashSettlementform.get("settlementamount").setValue(settlementAmount);
        this._PettycashSettlementform.setValue(updateJSON.pettycashsettlement);
        this._Proofs = updateJSON.pettycashsettlementdetails;
        this._fromaccountsList = this._fromaccountsListTemp;
        // this.ChangeCurrencies(null);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  ChangeCurrencies(event): void {
    let currencyid = parseInt(this._PettycashSettlementform.controls['currentcurrencyid'].value || 1);
    var exchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == currencyid)[0].exchangerate;
    this._PettycashSettlementform.controls['exchangerateinusd'].setValue(exchangerate);
    this.CombinePettyCashRequestNo();
  }

  onSave() {
    this._submitted = true;
    if (this._PettycashSettlementform.valid) {
      let length = (this.selectedRequest || []).length;
      let errMsg = '';
      if (!(this._Requests || []).length) {
        errMsg += 'No pending request found to proceed. ';
      }
      else if (!length) {
        errMsg += 'please select atleast one row in request no popup. ';
      }

      if (length > 0) {
        let series = this.selectedRequest[0].series;

        let hasInvalid = (this.selectedRequest || []).some(f => f.series != series);

        if (hasInvalid) {
          errMsg += 'Invalid request no\'s are selected kinldy review. ';
        }
        let fromaccountid = parseInt(this._PettycashSettlementform.get("fromaccount").value || 0);
        let ledger = this._fromaccountsList.find(f => f.ledgerid == fromaccountid);
        if (!ledger) {
          errMsg += "from account ledger not found";
        }
        // if (ledger) {
        //   let ledgerseries = series == "MSC00094" ? 94 : 93;
        //   if (ledger.series != ledgerseries && ledger.accountgroupid != 6) errMsg += 'please review your requests and fromaccount.';
        // }
      }

      if (errMsg) { this._CustomExceptionService.handleWarning(errMsg); return; }


      this._PettycashSettlementDetailform.get("pettycashsettlementid").setValue(this._PettycashSettlementform.get("pettycashsettlementid").value);
      this._PettycashSettlement = this._PettycashSettlementform.getRawValue();
      
      if (this._action == 'create') {
        this._PettycashSettlement.createdon = new Date();
        this._PettycashSettlement.createdby = sessionStorage["userid"];
        this._PettycashSettlement.accountingyear = new Date().getFullYear();
        this._PettycashSettlement.settlementbranchid = parseInt(sessionStorage["currentbranchid"]);
        this._PettycashSettlement.branchid = parseInt(sessionStorage["currentbranchid"]);
        this._PettycashSettlementFORM = this.formconverstion(this._PettycashSettlement, this._selectedDocuments, this._Proofs);
        //this.onEd();
        this._IsSaveDisable = true;
        this._IsClearDisable = true;
        this._IsProgressSpinner = true;
        this._PettycashSettlementService.create(this._PettycashSettlementFORM).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
            this._Requests = resutJSON.pettycashrequestviews;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsSaveDisable = false;
            this._IsClearDisable = false;
            this._IsProgressSpinner = false;
          }
        }, error => {
          this._CustomExceptionService.handleError(error)
          this._IsSaveDisable = true;
          this._IsClearDisable = true;
          this._IsProgressSpinner = false;
        });
      }
    }
  }

  OnChangeSettlementType() {
    var receiptMode = this._PettycashSettlementform.get("settlementtype").value;
    this._PettycashSettlementform.get("fromaccount").reset();
    let userid = parseInt(this._PettycashSettlementform.get("settledby").value || 0);
    let cashgroups = [30, 31];
    if (receiptMode == "MSC00146") { // Cash
      this._fromaccountsList = this._fromaccountsListTemp.filter(f => cashgroups.includes(f.accountgroupid));
      if (userid !== 1) {
        let selected = (this.selectedRequest || []);
        if (!selected || !selected.length) return;
        let loginuserseries = selected[0].series // sessionStorage["Series"];
        let toseries = 93;
        if (loginuserseries == "MSC00094") toseries = 94;
        var ledger = this._fromaccountsList.find(f => f.series == toseries);
        if (ledger)
          this._PettycashSettlementform.get("fromaccount").setValue(ledger.ledgerid);
      }
      this.OnChangeFromAccount();
    }
    else
      this._fromaccountsList = this._fromaccountsListTemp.filter(f => !cashgroups.includes(f.accountgroupid));
  }

  reset(event) {
    this._submitted = true;
    this._IsSaveDisable = false;
    this._IsClearDisable = false;
    this._PettycashSettlementform.reset();
    this._PettycashSettlementDetailform.reset();
    this._Proofs = [];
    this.InitializeForm();
    this._submitted = false;
    this.pettycashrequestnos = "";
    this.requestamount = 0;
    this.selectedRequest = [];
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  goBack(event) {
    this._router.navigate(['/VPettycashSettlement']);
  }
  showBasicDialog() {
    this.displayBasic = true;
  }
  onRowSelectPopup(event: any) {
    var row = event.data;
    this.PopupRowValidation();
    this._PettycashSettlementform.controls['settlementbranchid'].setValue(row.requestedbranchid);
    this._PettycashSettlementform.controls['currentcurrencyid'].setValue(row.currentcurrencyid);
    this._PettycashSettlementform.controls['exchangerateinusd'].setValue(row.exchangerateinusd);
    this._PettycashSettlementform.controls['currentcurrencyid'].disable();
    this.CombinePettyCashRequestNo();
  }

  onRowUnSelectPopup(event: any) {
    var row = event.data;
    this.PopupRowValidation();
    this._PettycashSettlementform.controls['settlementbranchid'].setValue(row.requestedbranchid);
    this.CombinePettyCashRequestNo();
  }

  PopupRowValidation() {
    let s_request = (this.selectedRequest || []);

    (this._Requests || []).forEach(element => {
      element.disablecheckbox = false;
    });

    if (!s_request || !s_request.length) return;

    let pettycashno = (this.selectedRequest || [])[0].pettycashno;

    (this._Requests || []).filter(f => f.pettycashno != pettycashno).forEach(element => {
      element.disablecheckbox = true;
    });
  }

  showBasicDialog1(position: string) {
    this.position = position;
    this.displayBasic1 = true;
  }
  onUpload(event) {
    this.selectedFiles = event.files
  }
  onEd() {
    alert("test");
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._PettycashSettlementFORM;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  getBalanceAmount(event) {
    let requestAmount = this.requestamount;
    let settlementAmount = parseFloat(this._PettycashSettlementform.get("settlementamount").value || 0);
    var BalanceAmount = parseFloat((requestAmount - settlementAmount).toFixed(2) || '0');
    this._PettycashSettlementform.controls['balanceamount'].setValue(BalanceAmount);

    if (settlementAmount > requestAmount) {
      this._CustomExceptionService.handleWarning("Settlement amount must not exceed with request amount")
      this._PettycashSettlementform.controls['balanceamount'].setValue(requestAmount);
      this._PettycashSettlementform.controls['settlementamount'].setValue(0);
    }
  }
  // Document Upload 
  showdocumentDialog(position1: string) {
    this.position1 = position1;
    this.displaydocumentpopup = true;
  }

  //Document Select:
  onDocumentSelect(event, document) {
    this._LastAddedDocument = event.currentFiles[0];
    document.clear();
  }

  //Form Data Creation:
  formconverstion(p_Pettycashsettlement: Pettycashsettlement, documents: File[], p_documentdetails: Pettycashsettlementdetail[]) {
    let time = new Date();
    const formData: FormData = new FormData();
    //TimeStamp Add:
    for (var x in documents) {
      console.log(documents[x].name);
      var index = p_documentdetails.findIndex(obj => obj.attachmentname == documents[x].name);
      console.log("Index", index);
      if (index != -1) {
        var NewFileName = time.getTime() + '_' + documents[x].name;
        p_documentdetails[index].attachmentname = NewFileName;
        p_documentdetails[index].attachmenturl = NewFileName;
        formData.append('files', documents[x], NewFileName);
      }
    }
    let referenceList = this.GetAdjustedAmount();
    var _PettycashsettlementOBJ = {
      "Pettycashsettlement": p_Pettycashsettlement,
      "Pettycashsettlementdetail": [],
      "Pettycashsettlementrequestno": referenceList
    };
    var Pettycashsettlement = JSON.stringify(_PettycashsettlementOBJ);
    formData.append('strPettycashsettlement', Pettycashsettlement);
    return (formData);
  }

  CombinePettyCashRequestNo() {
    this.pettycashrequestnos = "";
    let requestAmount = 0;
    let arry = [];
    let currencyid = parseInt(this._PettycashSettlementform.get("currentcurrencyid").value || 0);
    (this.selectedRequest || []).forEach((element, index) => {
      arry.push(element.pettycashno);
      let rec_currency = (this._currencyVersions || []).find(f => f.versionid == element.currencyuniquerefid && f.currencyid == element.currentcurrencyid);
      let cus_currency = (this._currencyVersions || []).find(f => f.versionid == element.currencyuniquerefid && f.currencyid == currencyid);
      if (rec_currency && cus_currency) {
        requestAmount += (cus_currency.exchangerate * (element.balanceamount / rec_currency.exchangerate));
      }
    });
    this.pettycashrequestnos = arry.join(",");
    this.requestamount = this.roundTo(requestAmount, 3);
    this.OnChangeSettlementType();
  }

  //round off
  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  GetAdjustedAmount() {
    let settlementAmount = (this._PettycashSettlementform.get("settlementamount").value || 0);
    let currencyid = parseInt(this._PettycashSettlementform.get("currentcurrencyid").value || 0);
    let referenceList: Pettycashsettlementrequestno[] = [];
    let s_request = (this.selectedRequest || []);
    s_request.forEach(element => {
      let rec_currency = (this._currencyVersions || []).find(f => f.versionid == element.currencyuniquerefid && f.currencyid == element.currentcurrencyid);
      let cus_currency = (this._currencyVersions || []).find(f => f.versionid == element.currencyuniquerefid && f.currencyid == currencyid);
      if (rec_currency && cus_currency) {
        element.requestamount = parseFloat((cus_currency.exchangerate * (element.balanceamount / rec_currency.exchangerate)).toFixed(2) || '0');
        element.balanceamount = parseFloat((cus_currency.exchangerate * (element.balanceamount / rec_currency.exchangerate)).toFixed(2) || '0');
      }
    });
    s_request.forEach((element, index) => {
      let reference: Pettycashsettlementrequestno = new Pettycashsettlementrequestno();
      reference.pettycashrequestid = element.pettycashid;
      reference.pettycashsettlementid = 0;
      reference.requestamount = element.balanceamount;
      if (element.balanceamount < settlementAmount) {
        reference.settlementamount = element.balanceamount;
        settlementAmount -= element.balanceamount;
      } else {
        reference.settlementamount = settlementAmount;
        settlementAmount = 0;
      }
      referenceList.push(reference);
    });

    return referenceList;
  }

  OnChangeFromAccount() {
    let fromaccountid = parseInt(this._PettycashSettlementform.get("fromaccount").value || 0);
    this._IsProgressSpinner = true;
    this._PaymentService.GetFromAccountClosingBalance(fromaccountid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus && resultJSON.tranStatus.result) {
        this._PettycashSettlementform.controls['fromaccountclosingbalance'].setValue(resultJSON.closingbalance);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
  ok()
  {
    this.displayBasic = false;
    let s_request = (this.selectedRequest || []);
    if (!s_request || !s_request.length) 
    {
      this._CustomExceptionService.handleWarning("Please Select Atleast One Request No");
      this.displayBasic = true;
      return;
    }
  }
}
