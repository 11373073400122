import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { OtherchargesService } from 'src/app/core/Services/masters/othercharges.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Otherchargesview } from 'src/app/core/Views/Otherchargesview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-othercharges',
  templateUrl: './v-othercharges.component.html',
  styleUrls: ['./v-othercharges.component.css']
})
export class VOtherchargesComponent implements OnInit {
  selectedrows: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _otherchargeview: Otherchargesview;
  _otherchargesview: Otherchargesview[]=[];
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  _userid = sessionStorage["userid"];
  constructor(
    private fb: FormBuilder,
    private _OtherChargesService: OtherchargesService,
    private router: Router,
    private utility: CommonUtilities,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,
    private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;
    this._Action = this._AccessRightsService.getAccessRights(28);
    this.HotKeyIntegration();
    this._OtherChargesService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._otherchargesview = resultJSON.otherchargesviews;
      this._IsProgressSpinner = false;
    });
  }
  view(event) {
    this.router.navigate(['/cOtherCharges'], { state: { otherchargeid: event.otherchargeid, action: 'view' } });
  }
  delete(event) {
    alert("delete");
  }
  edit(event) {
    this.router.navigate(['/cOtherCharges'], { state: { otherchargeid: event.otherchargeid, action: 'edit' } });
  }
  onRowSelect(event) { }
  onRowUnselect(event) { }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  GoToCreate() {
    this.router.navigate(['/cOtherCharges']);
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._OtherChargesService.ChangeInactive(event.otherchargeid, this._userid).subscribe((result) => {
      const resutJSON = JSON.parse(JSON.stringify(result));
      if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
        this._OtherChargesService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._otherchargesview = resultJSON.otherchargesviews;
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this other charges name <b>"' + event.otherchargename + '"</b> ?',
      accept: () => {
        this.Cancel(event);
      }
    });
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'OtherCharge', 'xlsx');
  }
  ExportToPdf() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.ExportToPdf(AssetRegistrationList, 'OtherCharge.pdf');
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._otherchargesview.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Other Charge Name"] = element.otherchargename;
      assetregistration["Other Charge Amount"] = element.otherchargeamount;
      assetregistration["Other Charge Type"] = element.otherchargestype;
      assetregistration["Status"] = element.otherchargestatusname;
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
}