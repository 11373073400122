import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppConfig } from 'src/app/core/domain/appconfig';
import { PurchaseDashboardService } from 'src/app/core/Services/dashboard/purchase-dashboard.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';



@Component({
	selector: 'app-purchase-dashboard',
	templateUrl: './purchase-dashboard.component.html',
	styleUrls: ['./purchase-dashboard.component.css']
})
export class PurchaseDashboardComponent implements OnInit {
	_Action: IAction;

	datePipe = new DatePipe('en-US');
	_IsProgressSpinner: boolean = false;
	_Branches: any[] = [];
	_purchasedashboardform: FormGroup
	documentStyle: any;
	_PurchaseDashboardObj: any;
	basicOptions: any;

	_Isbarsd: boolean = true; _Islinesd: boolean = true; _Ispiesd: boolean = true; _Isdoughnutsd: boolean = true;
	_Isbarsd1: boolean = true; _Islinesd1: boolean = true; _Ispiesd1: boolean = true; _Isdoughnutsd1: boolean = true;
	_Isbarpd: boolean = true; _Islinepd: boolean = true; _Ispiepd: boolean = true; _Isdoughnutpd: boolean = true;
	_Isbarsd2: boolean = true; _Islinesd2: boolean = true; _Ispiesd2: boolean = true; _Isdoughnutsd2: boolean = true;


	config: AppConfig;

	trimlableDataProduct: any = [];
	trimlableDataProductDots: any = [];
	purchaseByCategoryLabel: any = [];

	purchaseRep: any = [];
	purchaseRepOpt: any = [];
	purchaseRepVal: any = [];
	purchaseRepValOpt: any = [];
	purchaseQty: any = [];
	purchaseVal: any = [];
	purchaseReturnValues: any = []

	purchaseByCategory: any;
	purchaseByCategoryValue: any;

	purchaseHead: any
	data: any
	topSelling: any
	topReturn: any

	_isDoubleBar: boolean = true

	noDoubleBarChart: boolean = true;
	nopurchaseVal: boolean = true

	topPurchaseProduct: any;
	topPurchaseProductOpt: any;
	topPurchaseProductLabel: any;
	topPurchaseProductLabelDots: any;
	topPurchaseProductValue: any;

	topSupplier: any;
	topSupplierOpt: any;
	topSupplierProductLabel: any;
	topSupplierProductLabelDots: any;
	topSupplierProductValue: any;

	noTopPurchase: boolean = false
	noTopSupplier: boolean = false

	mindate: Date
	maxdate: Date

	constructor(
		private utility: CommonUtilities,
		private fb: FormBuilder,
		private purchaseDashboardService: PurchaseDashboardService,
		private _CustomExceptionService: CustomExceptionService,
		private _AccessRightsService: AccessRightsService,

	) { }
	ngOnInit() {
		this._Action = this._AccessRightsService.getAccessRights(346);
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.utility.pageLoadScript();
		this.documentStyle = getComputedStyle(document.documentElement);
		this._purchasedashboardform = this.fb.group({
			branchid: [parseInt(sessionStorage['currentbranchid'])],
			fromdate: [new Date(), Validators.required],
			// todate: [new Date(), Validators.required],
		});
		if(sessionStorage['Environmentenddate'] == "null"){
			this._purchasedashboardform.get("fromdate").setValue(new Date)
		}
		else{
			let date = new Date(sessionStorage['Environmentenddate'])
			this._purchasedashboardform.get('fromdate').setValue(date);
		}

		
		// let today = new Date();
		// let twoDaysBefore = new Date(today);
		// twoDaysBefore.setDate(today.getDate() - 2)
		// console.log("Date", twoDaysBefore)
		// this._purchasedashboardform.get("fromdate").setValue(twoDaysBefore);
		// this.maxdate = today
		// this.mindate = twoDaysBefore
		// this.purchaseHead = {
		// 	TOTALPOCOUNT: 0,
		// 	TOTALINWARDCOUNT: 0,
		// 	TOTALGRNCOUNT: 0,
		// 	TOTALINVOICECOUNT: 0
		// }
		this._Isbarpd = false; this._Islinepd = false; this._Ispiepd = true; this._Isdoughnutpd = false;
		this._Isbarsd1 = false; this._Islinesd1 = false; this._Ispiesd1 = true; this._Isdoughnutsd1 = false;
		this._Isbarsd2 = false; this._Islinesd2 = false; this._Ispiesd2 = true; this._Isdoughnutsd2 = false;
		this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = true; this._Isdoughnutsd = false;
		this.purchaseDashboardService.PageOnload().subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this._IsProgressSpinner = false;
			this._Branches = resultJSON.branches;
		});
		this.generatePurchase();
	}

	onchangefromdate() {
		let fromdate = this._purchasedashboardform.get("fromdate").value
		this._purchasedashboardform.get("todate").reset();
		this.mindate = fromdate;
		let twoDaysAfter = new Date(fromdate);
		twoDaysAfter.setDate(fromdate.getDate() + 2)
		this.maxdate = twoDaysAfter
	}
	chartchangepd(charttype4) {
		if (charttype4 == "bar") {
			this._Isbarpd = true; this._Islinepd = false; this._Ispiepd = false; this._Isdoughnutpd = false;
		}
		if (charttype4 == "line") {
			this._Isbarpd = false; this._Islinepd = true; this._Ispiepd = false; this._Isdoughnutpd = false;
		}
		if (charttype4 == "pie") {
			this._Isbarpd = false; this._Islinepd = false; this._Ispiepd = true; this._Isdoughnutpd = false;
		}
		if (charttype4 == "doughnut") {
			this._Isbarpd = false; this._Islinepd = false; this._Ispiepd = false; this._Isdoughnutpd = true;
		}
	}
	chartchangesd(charttype1) {
		if (charttype1 == "bar") {
			this._Isbarsd = true; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = false;
		}
		if (charttype1 == "line") {
			this._Isbarsd = false; this._Islinesd = true; this._Ispiesd = false; this._Isdoughnutsd = false;
		}
		if (charttype1 == "pie") {
			this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = true; this._Isdoughnutsd = false;
		}
		if (charttype1 == "doughnut") {
			this._Isbarsd = false; this._Islinesd = false; this._Ispiesd = false; this._Isdoughnutsd = true;
		}
	}
	chartchangesd1(charttype2) {
		if (charttype2 == "bar") {
			this._Isbarsd1 = true; this._Islinesd1 = false; this._Ispiesd1 = false; this._Isdoughnutsd1 = false;
		}
		if (charttype2 == "line") {
			this._Isbarsd1 = false; this._Islinesd1 = true; this._Ispiesd1 = false; this._Isdoughnutsd1 = false;
		}
		if (charttype2 == "pie") {
			this._Isbarsd1 = false; this._Islinesd1 = false; this._Ispiesd1 = true; this._Isdoughnutsd1 = false;
		}
		if (charttype2 == "doughnut") {
			this._Isbarsd1 = false; this._Islinesd1 = false; this._Ispiesd1 = false; this._Isdoughnutsd1 = true;
		}
	}
	chartchangesd2(charttype3) {
		if (charttype3 == "bar") {
			this._Isbarsd2 = true; this._Islinesd2 = false; this._Ispiesd2 = false; this._Isdoughnutsd2 = false;
		}
		if (charttype3 == "line") {
			this._Isbarsd2 = false; this._Islinesd2 = true; this._Ispiesd2 = false; this._Isdoughnutsd2 = false;
		}
		if (charttype3 == "pie") {
			this._Isbarsd2 = false; this._Islinesd2 = false; this._Ispiesd2 = true; this._Isdoughnutsd2 = false;
		}
		if (charttype3 == "doughnut") {
			this._Isbarsd2 = false; this._Islinesd2 = false; this._Ispiesd2 = false; this._Isdoughnutsd2 = true;
		}
	}
	updateChartOptions() {
		if (this.config.dark)
			this.applyDarkTheme();
		else
			this.applyLightTheme();
	}
	applyDarkTheme() {
		this.basicOptions = {
			legend: {
				labels: {
					fontColor: '#ebedef'
				}
			},
		};
	}
	applyLightTheme() {
		this.basicOptions = {
			legend: {
				labels: {
					fontColor: '#495057'
				}
			},
		};
	}
	generatePurchase() {
		var branchid = this._purchasedashboardform.get("branchid").value || null;
		var _fromdate = this._purchasedashboardform.get("fromdate").value;
		// var _todate = this._purchasedashboardform.get("todate").value;
		var datePipe = new DatePipe("en-US");
		var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
		// var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
		if (!fromdate) {
			this._CustomExceptionService.handleError("Please select from date");
			return;
		}
		// if (!todate) {
		// 	this._CustomExceptionService.handleError("Please select To date");
		// 	return;
		// }
		// if (fromdate > todate) {
		// 	this._CustomExceptionService.handleError("From Date should be less than To Date");
		// 	return;
		// }
		this._PurchaseDashboardObj = {
			"branchid": this._purchasedashboardform.get("branchid").value,
			"FromDate": fromdate,
			"ToDate": null,
		}
		this._IsProgressSpinner = true;
		this.purchaseDashboardService.PurchaseReport(this._PurchaseDashboardObj).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this.trimlableDataProduct = [];
			this.trimlableDataProductDots = [];
			this.purchaseRep = [];
			this.purchaseRepOpt = [];
			this.purchaseQty = [];
			this.purchaseVal = [];
			this.purchaseReturnValues = [];
			this.purchaseHead = []
			this.purchaseByCategoryLabel = []
			this.purchaseByCategoryValue = []
			this.topPurchaseProductLabel = []
			this.topPurchaseProductLabelDots = []
			this.topPurchaseProductValue = []
			this.topSupplierProductLabel = []
			this.topSupplierProductLabelDots = []
			this.topSupplierProductValue = []
			if (resultJSON.purchaseRep != null && resultJSON.purchaseRep) {
				this.noDoubleBarChart = false
				this.nopurchaseVal = false
				this.purchaseHead = resultJSON.purchaseHeader[0];
				if (resultJSON.purchaseRep.length > 0) {
					for (let i = 0; i < resultJSON.purchaseRep.length; i++) {
						this.trimlableDataProductDots[i] = (resultJSON.purchaseRep[i].PRODUCTCATEGORYNAME).slice(0, 15) + '...';
						this.trimlableDataProduct[i] = (resultJSON.purchaseRep[i].PRODUCTCATEGORYNAME);
						this.purchaseQty[i] = (resultJSON.purchaseRep[i].PURCHASEINVOICEQUANTITY);
						this.purchaseVal[i] = (resultJSON.purchaseRep[i].PURCHASEINVOICEVALUEPERCENTAGE);
						this.purchaseRep = {
							labels: this.trimlableDataProductDots,
							datasets: [
								{
									label: 'Purchase Quantity',
									backgroundColor: [
										'#42A5F5',
										"#FF6384",
										"#3655eb",
										"#e757eb",
										"#b64c11",
										"#FFCE56"
									],
									data: this.purchaseQty,
									lbls: this.trimlableDataProduct
								}
							],
						};
						this.purchaseRepOpt = {
							tooltips: {
								callbacks: {
									title: (item, data) => {
										var datasetLabel = data.datasets[0].label || '';
										return datasetLabel
									},
									label: (item, data) => {
										var datasetLabel = data.datasets[0].lbls[item.index] || '';
										var datasetVal = data.datasets[0].data[item.index] || '';
										return `${datasetLabel}:  ${datasetVal}`
									}
								}
							}
						}
						this.purchaseRepVal = {
							labels: this.trimlableDataProductDots,
							datasets: [
								{
									label: 'Purchase Value',
									backgroundColor: [
										'#42A5F5',
										"#FF6384",
										"#3655eb",
										"#e757eb",
										"#b64c11",
										"#FFCE56"
									],
									data: this.purchaseVal,
									lbls: this.trimlableDataProduct
								}
							],
						};
						this.purchaseRepValOpt = {
							tooltips: {
								callbacks: {
									title: (item, data) => {
										var datasetLabel = data.datasets[0].label || '';
										return datasetLabel
									},
									label: (item, data) => {
										var datasetLabel = data.datasets[0].lbls[item.index] || '';
										var datasetVal = data.datasets[0].data[item.index] || '';
										return `${datasetLabel}:  ${datasetVal}`
									}
								}
							}
						}
					}
				} else { this.noDoubleBarChart = true; this.nopurchaseVal = true }
			} else { this.noDoubleBarChart = true; this.nopurchaseVal = true }
			if (resultJSON.topSuppliers != null && resultJSON.topSuppliers) {
				this.noTopSupplier = false
				if (resultJSON.topSuppliers.length > 0) {
					for (let i = 0; i < resultJSON.topSuppliers.length; i++) {
						this.topSupplierProductLabelDots[i] = (resultJSON.topSuppliers[i].SUPPLIERNAME).slice(0, 15) + '...';
						this.topSupplierProductLabel[i] = (resultJSON.topSuppliers[i].SUPPLIERNAME)
						this.topSupplierProductValue[i] = (resultJSON.topSuppliers[i].INVOICEQUANTITY);
						this.topSupplier = {
							labels: this.topSupplierProductLabelDots,
							datasets: [
								{
									label: 'Top Suppliers',
									backgroundColor: [
										'#42A5F5',
										"#FF6384",
										"#3655eb",
										"#e757eb",
										"#b64c11",
										"#FFCE56"
									],
									data: this.topSupplierProductValue,
									lbls: this.topSupplierProductLabel
								}
							],

						};
						this.topSupplierOpt = {
							tooltips: {
								callbacks: {
									title: (item, data) => {
										var datasetLabel = data.datasets[0].label || '';
										return datasetLabel
									},
									label: (item, data) => {
										var datasetLabel = data.datasets[0].lbls[item.index] || '';
										var datasetVal = data.datasets[0].data[item.index] || '';
										return `${datasetLabel}:  ${datasetVal}`
									}
								}
							}
						}
					}
				} else { this.noTopSupplier = true }
			} else { this.noTopSupplier = true }
			if (resultJSON.topProducts != null && resultJSON.topProducts) {
				this.noTopPurchase = false
				if (resultJSON.topProducts.length > 0) {
					for (let i = 0; i < resultJSON.topProducts.length; i++) {
						this.topPurchaseProductLabel[i] = (resultJSON.topProducts[i].PRODUCTNAME)
						this.topPurchaseProductLabelDots[i] = (resultJSON.topProducts[i].PRODUCTNAME).slice(0, 15) + '...';
						this.topPurchaseProductValue[i] = (resultJSON.topProducts[i].INVOICEQUANTITY);
						this.topPurchaseProduct = {
							labels: this.topPurchaseProductLabelDots,
							datasets: [
								{
									label: 'Top Products',
									backgroundColor: [
										'#42A5F5',
										"#FF6384",
										"#3655eb",
										"#e757eb",
										"#b64c11",
										"#FFCE56"
									],
									data: this.topPurchaseProductValue,
									lbls: this.topPurchaseProductLabel
								}
							],
						};
						this.topPurchaseProductOpt = {
							tooltips: {
								callbacks: {
									title: (item, data) => {
										var datasetLabel = data.datasets[0].label || '';
										return datasetLabel
									},
									label: (item, data) => {
										var datasetLabel = data.datasets[0].lbls[item.index] || '';
										var datasetVal = data.datasets[0].data[item.index] || '';
										return `${datasetLabel}:  ${datasetVal}`
									}
								}
							}
						}
					}
				} else { this.noTopPurchase = true }
			} else { this.noTopPurchase = true }
			this._IsProgressSpinner = false;
		});
	}
}