import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Producttype } from 'src/app/core/Models/Producttype';
import { vProductTypeName } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
//Debug
import * as ace from "ace-builds";
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ProducttypeService } from 'src/app/core/Services/masters/producttype.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
@Component({
  selector: 'app-c-product-type',
  templateUrl: './c-product-type.component.html',
  styleUrls: ['./c-product-type.component.css']
})
export class CProductTypeComponent implements OnInit {
  _producttypeform: FormGroup;
  _submitted = false;
  _action: string = "create";
  _producttypestatus: Metadatum;
  _producttypeid: number;
  _ProductType: Producttype;
  isDisabledSave = false;
  isDisabledClear = false;
  _IsProgressSpinner: boolean = true;
  _Action: IAction;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _ProductTypeService: ProducttypeService, 
    private _AccessRightsService: AccessRightsService,
    private _router: Router, private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService) {
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._producttypeform = this.fb.group({
      producttypeid: [0],
      producttypename: ['', vProductTypeName],
      producttypestatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      productcategories: [],
      customerwisediscounts: []
    })
    this._producttypeform.controls['producttypestatus'].setValue("MSC00001");
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(15);
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._producttypeid = history.state?.producttypeid ? history.state?.producttypeid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;

    if (this._action == 'view') {
      this._producttypeform.disable();
      this.isDisabledClear = true;
      this.isDisabledSave = true;
    }

    if (this._action == 'edit') {
      this.isDisabledClear = true;
      this.isDisabledSave = false;
    }
    this._IsProgressSpinner = true;
    this._ProductTypeService.PageOnLoad(this._producttypeid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // 17.status is drop down column load from metadata(active, in active)
      this._producttypestatus = resultJSON.status;
      this._IsProgressSpinner = false;
      this._producttypeform.controls['producttypestatus'].setValue("MSC00001");
      //For Update
      this._producttypeid = history.state.producttypeid;

      if (this._action == 'edit' || this._action == 'view') {

        const updateJSON = JSON.parse(JSON.stringify(result));
        this._producttypeform.setValue(updateJSON.producttype);
        //updateJSON.currency
        //this.datePipe.transform(this._customerform.get("date").value, 'yyyy-MM-dd')
        //var dat = this.datePipe.transform(updateJSON.currency.get('effectivefrom').value);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onSave() {
    this._submitted = true;

    if (this._producttypeform.valid) {
      this._ProductType = this._producttypeform.value;
      //var CityOBJ = { "City": this._City };
      //Debug
      // this.onEd();
      if (this._action == 'create') {
        this._ProductType.createdon = new Date();
        this._ProductType.createdby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._ProductTypeService.create(this._ProductType).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
      else if (this._action == 'edit') {
        this._ProductType.modifiedon = new Date();
        this._ProductType.modifiedby = sessionStorage["userid"];
        this._IsProgressSpinner = true;
        this._ProductTypeService.edit(this._ProductType).subscribe((result) => {
          //  this._Currency.effectivefrom = new Date(this._Currency.effectivefrom);
          //  this._Currency.effectiveto = new Date(this._Currency.effectiveto);
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);

          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
  }
  // 12. clear all the fields which the data has entered 
  reset(event) {
    this._submitted = true;
    this._producttypeform.reset();
    this.isDisabledSave = false;
    this.isDisabledClear = false;
    this.InitializeForm();
    this._submitted = false;

  }
  // 23.on clicking back button , it Navigates to the search screen
  goBack(event) {
    //alert('This');
    this._router.navigate(['/vProductType']);
  }
  //Debug
  // onEd()
  // {
  //   ace.config.set("fontSize", "14px");
  //   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //   const aceEditor = ace.edit(this.editor.nativeElement);
  //   aceEditor.getSession().setMode("ace/mode/json");
  //   var myObj = this._ProductType;
  //   var myJSON = JSON.stringify(myObj);
  //   aceEditor.session.setValue(myJSON);
  // }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      },['INPUT', 'SELECT', 'TEXTAREA']));

      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      },['INPUT', 'SELECT', 'TEXTAREA']));

      //this._hotkeysService.add(new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
      //  this.router.navigate(['/cBank'], { state: { bankid: this._bankid, action: 'edit' }});
      //  return false; // Prevent bubbling
      //}));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    },['INPUT', 'SELECT', 'TEXTAREA']));
  }
}

