import { Component, OnInit } from '@angular/core';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { RptdeliverednotdeliveredService } from 'src/app/core/Services/reports/Stock/RptDelivered-Notdelivered/rptdeliverednotdelivered.service';
import { RptlockedquantityService } from 'src/app/core/Services/reports/Stock/RptLockedQuantity/rptlockedquantity.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-rptdelivered-notdelivered',
  templateUrl: './rptdelivered-notdelivered.component.html',
  styleUrls: ['./rptdelivered-notdelivered.component.css']
})
export class RptdeliveredNotdeliveredComponent implements OnInit {
  OverallBranchDeliveredQty: number = 0;
  OverallBranchNotDeliveredQty: number = 0;
  _BranchDeliveredNotdelivereds: any[];
  IsShowProductWiseDeliveredNotDelivered: boolean = true;
  OverallPrddeliveredqty: number = 0;
  OverallPrdnotdeliveredqty: number = 0;
  _PrdDeliveredNotDeliveredQtys: any[];
  selectedProductRows: any;
  _IsProgressSpinner: boolean = true;
  _ProductObj: any;
  _DeliveredNotDelivereddetails: any[];
  cols: any[];
  ProductdeliverednotDeliveredcols: any[];
  _DeliveredNotDelivereddetailscols: any[];
  griddetaillockqty: boolean;
  selectedBranchwiseLockedqty: any;
  _userid:number=parseInt(sessionStorage["userid"]);
  constructor(private exportUtility: ExportUtility, private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService,
    private _DeliveredNotDeliveredservice: RptdeliverednotdeliveredService) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'branchname', header: 'Branch Name' },
      { field: 'invoicequantity', header: 'Invoice Qty.' },
      { field: 'deliveredquantity', header: 'Delivered Qty.' },
      { field: 'notdeliveredquantity', header: 'Not Delivered Qty.' },
    ]
    this.ProductdeliverednotDeliveredcols = [
      { field: 'productname', header: 'Product Name' },
      { field: 'invoicequantity', header: 'Invoice Qty.' },
      { field: 'deliveredquantity', header: 'Delivered Qty.' },
      { field: 'notdeliveredquantity', header: 'Not Delivered Qty.' },
    ]
    this._DeliveredNotDelivereddetailscols = [
      { field: 'customerorbranchname', header: 'Customer / Branch Name' },
      { field: 'referenceno', header: 'Ref. No.' },
      { field: 'invoicequantity', header: 'Invoice Qty.' },
      { field: 'deliveredquantity', header: 'Delivered Qty.' },
      { field: 'notdeliveredquantity', header: 'Not Delivered Qty.' },
    ]
    this._IsProgressSpinner = true;
    this._DeliveredNotDeliveredservice.PageOnload(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // 
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._BranchDeliveredNotdelivereds = resultJSON.overAllDeliveredUnDeliveredQuantity;
        for (var i = 0; i < this._BranchDeliveredNotdelivereds.length; i++) {
          this.OverallBranchDeliveredQty += this._BranchDeliveredNotdelivereds[i].deliveredquantity;
          this.OverallBranchNotDeliveredQty += this._BranchDeliveredNotdelivereds[i].notdeliveredquantity;
        }
      } else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  //BranchWise Stock
  exportExcel() {
    let BranchWiseLockedQtyList = this.GetBranchWiseLockedQtyList();
    this.exportUtility.exportExcel(BranchWiseLockedQtyList, 'BranchWiseStock', 'xlsx');
  }

  ExportToPdf() {
    let BranchWiseStockList = this.GetBranchWiseLockedQtyList();
    this.exportUtility.ExportToPdf(BranchWiseStockList, 'BranchWiseStock.pdf');
  }

  GetBranchWiseLockedQtyList() {
    if (!this._BranchDeliveredNotdelivereds || !this._BranchDeliveredNotdelivereds.length)
      return;
    let BranchWiseLockedQtyList = [];
    this._BranchDeliveredNotdelivereds.forEach(element => {
      let BranchWiseLocked: any = {};
      BranchWiseLocked["Branch Name"] = element.branchname;
      BranchWiseLocked["Invoice Qty."] = element.invoicequantity;
      BranchWiseLocked["Delivered Qty."] = element.deliveredquantity;
      BranchWiseLocked["Not Delivered Qty."] = element.notdeliveredquantity;
      BranchWiseLockedQtyList.push(BranchWiseLocked);
    });
    return BranchWiseLockedQtyList;
  }


  onRowSelectBranch(event: any) {
    var row = event.data;
    this._IsProgressSpinner = true;
    this.IsShowProductWiseDeliveredNotDelivered = false;
    this.OverallPrddeliveredqty = 0;
    this.OverallPrdnotdeliveredqty=0;
    this._DeliveredNotDeliveredservice.getProductwiselockedqty(row.branchid,this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._PrdDeliveredNotDeliveredQtys = resultJSON.productWiseDeliveredUnDeliveredQuantity;
        for (var i = 0; i < this._PrdDeliveredNotDeliveredQtys.length; i++) {
          this.OverallPrddeliveredqty += this._PrdDeliveredNotDeliveredQtys[i].deliveredquantity;
          this.OverallPrdnotdeliveredqty += this._PrdDeliveredNotDeliveredQtys[i].notdeliveredquantity;
        }
        this.OverallPrddeliveredqty = parseFloat(this.OverallPrddeliveredqty.toFixed(3));
        this.IsShowProductWiseDeliveredNotDelivered = false;
        // 
      } else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  exportExcelProductWiseQuantity() {
    let ProductWiseLockedQtyList = this.GetProductWiseLockedQtyList();
    this.exportUtility.exportExcel(ProductWiseLockedQtyList, 'ProductWiseLockedQuantity', 'xlsx');
  }

  ExportToPdfProductWiseQuantity() {
    let ProductWiseLockedQtyList = this.GetProductWiseLockedQtyList();
    this.exportUtility.ExportToPdf(ProductWiseLockedQtyList, 'ProductWiseLockedQuantity.pdf');
  }

  GetProductWiseLockedQtyList() {
    if (!this._PrdDeliveredNotDeliveredQtys || !this._PrdDeliveredNotDeliveredQtys.length)
      return;
    let ProductWiseLockedQtyList = [];
    this._PrdDeliveredNotDeliveredQtys.forEach(element => {
      let Productdelivereddetail: any = {};
      Productdelivereddetail["Product Name"] = element.productname;
      Productdelivereddetail["Invoice Qty."] = element.invoicequantity;
      Productdelivereddetail["Delivered Qty."] = element.deliveredquantity;
      Productdelivereddetail["Not Delivered Qty."] = element.notdeliveredquantity;
      ProductWiseLockedQtyList.push(Productdelivereddetail);
    });
    return ProductWiseLockedQtyList;
  }

  onRowSelectProduct(event: any) {
    var Productrow = event.data;
    this._IsProgressSpinner = true;
    this._DeliveredNotDeliveredservice.getlockedqtydetail(Productrow.branchid, Productrow.productid,this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._DeliveredNotDelivereddetails = resultJSON.detailWiseDeliveredUnDeliveredQuantity
        this.griddetaillockqty = true;
      } else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  exportExcelDeliveredNotDelivereddetails() {
    let ProductDetailLockedQtyList = this.GetProductDetailLockedQtyList();
    this.exportUtility.exportExcel(ProductDetailLockedQtyList, 'ProductDetailLockedQuantity', 'xlsx');
  }

  ExportToPdfDeliveredNotDelivereddetails() {
    let ProductDetailLockedQtyList = this.GetProductDetailLockedQtyList();
    this.exportUtility.ExportToPdf(ProductDetailLockedQtyList, 'ProductDetailLockedQuantity.pdf');
  }

  GetProductDetailLockedQtyList() {
    if (!this._DeliveredNotDelivereddetails || !this._DeliveredNotDelivereddetails.length)
      return;
    let ProductDetailLockedQtyList = [];
    this._DeliveredNotDelivereddetails.forEach(element => {
      let detaileddelivered: any = {};
      detaileddelivered["Customer/Branch Name"] = element.customerorbranchname;
      detaileddelivered["Reference No."] = element.referenceno;
      detaileddelivered["Invoice Qty."] = element.invoicequantity;
      detaileddelivered["Delivered Qty."] = element.deliveredquantity;
      detaileddelivered["Not Delivered Qty."] = element.notdeliveredquantity;
      ProductDetailLockedQtyList.push(detaileddelivered);
    });
    return ProductDetailLockedQtyList;
  }


}