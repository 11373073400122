<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Promotion</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add"
                                class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                            <!--                            <button pButton pRipple label="" icon="pi pi-plus" class="p-button-success p-mr-2"
                                (click)="openNew()"></button>
                            <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger ">
                            </button>-->
                        </ng-template>
                        <ng-template pTemplate="right">

                            <!-- <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                 class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                 class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_addmanagementviews" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['adname','statusname','adurl']"
                        [rowHover]="true" dataKey="bankid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>

                                <th class="text-center" style="width: 120px;">Action

                                </th>
                                <th pSortableColumn="adurl">Image Preview <p-sortIcon field="adurl"></p-sortIcon>
                                </th>
                                <th pSortableColumn="adname">Image Name <p-sortIcon field="adname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="adname">Effective From <p-sortIcon field="adname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="adname">Effective To <p-sortIcon field="adname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="statusname">Status<p-sortIcon field="statusname"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_addmanagementview>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="!_Action._View" (click)="view(_addmanagementview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit"
                                        (click)="edit(_addmanagementview)" [disabled]="!_Action._Update"
                                        class="p-button p-button-success p-mr-2"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Delete"
                                        (click)="confirm(_addmanagementview)" [disabled]="!_Action._Delete"
                                        class="p-button p-button-danger"></button>
                                </td>
                                <td>
                                    <img [src]="_addmanagementview.adurl" width="45%" height="85%" alt=""
                                        class="img-preview">
                                </td>
                                <td>
                                    {{_addmanagementview.adname}}
                                </td>
                                <td>
                                    {{_addmanagementview.effectivefrom |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_addmanagementview.effectiveto |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_addmanagementview.statusname}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

            </div>
        </div>
    </div>
</div>