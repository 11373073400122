<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Stock Allocation</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" [disabled]="isDisabledsave" (click)="onSave()"
                                title="Save" icon="pi pi-save" class=""></button>
                            <button pButton pRipple type="button" [disabled]="isDisablereset" (click)="reset($event)"
                                title="Clear" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_stockallocationform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="reserveno">
                                    <label for="id">Reserve No</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_stockForm">
                                    <span class="p-float-label">
                                        <input type="text" disabled pInputText formControlName="frombranchname">
                                        <label for="id">Branch Name<span class="hlt-txt">*</span></label>
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" name="reservedate" formControlName="reservedate" [minDate]="mindate" [maxDate]="maxdate"
                                        dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="reservedate">Reservation Date<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_stockallocationform.get('reservedate').touched || _submitted) && _stockallocationform.get('reservedate').errors?.ReservationdateRequired">
                                    Please Select Reservation Date
                                </span>

                            </td>
                            <td style="width: 20%;">
                            </td>
                            <td>
                            </td>
                        </tr>
                    </table>
                </form>

                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_stockdetailform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branches" optionLabel="branchname"
                                        formControlName="tobranch" optionValue="branchid"
                                        (onChange)="getBranchProduct($event)" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="tobranch">Branch</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_producttype" optionLabel="producttypename"
                                        formControlName="producttypeid" optionValue="producttypeid"
                                        (onChange)="getProductCategory($event)" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type </label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productcategory" formControlName="productcategoryid"
                                        (onChange)="getProduct($event)" optionLabel="categoryname"
                                        optionValue="productcategoryid" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productname" formControlName="productid"
                                        (onChange)="getProductSearch($event)" optionLabel="productname"
                                        optionValue="productid" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>

                            </td>

                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_productdetails" [rows]="10" [paginator]="true" [scrollable]="true" scrollHeight="450px"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['tobranchname','productname','variantdescription','quantity']"
                        [rowHover]="true" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 50px;">
                                    <p-tableHeaderCheckbox (click)="SelectAll($event)" [disabled]="_action == 'view' || _action == 'edit'">
                                    </p-tableHeaderCheckbox>
                                </th>
                                <th>Branch Name</th>
                                <th>Product Name</th>
                                <th>Variant Name</th>
                                <th  [hidden]="_action == 'view'">Stock</th>
                                <th>Quantity</th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_productdetail let-ri="rowIndex">
                            <tr>
                                <td style="text-align: center;width: 50px;">
                                    <p-tableCheckbox [value]="_productdetail"
                                        [disabled]="isDisabledselectall">
                                    </p-tableCheckbox>
                                </td>
                                <td>
                                    {{_productdetail.tobranchname}}
                                </td>
                                <td>
                                    {{_productdetail.productname}}
                                </td>
                                <td>
                                    {{_productdetail.variantdescription}}
                                </td>
                                <td [hidden]="_action == 'view'">
                                    {{_productdetail.stock}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off"
                                                (onBlur)="onchangequantity(_productdetail,$event)"
                                                *ngIf="_productdetail.salesuom != 'MSC00039'&& _action != 'view' "
                                                (keyup)="onchangequantity(_productdetail,$event)"
                                                (keyup.enter)="onchangequantity(_productdetail,$event)"
                                                (Keydown.enter) = "onchangequantity(_productdetail,$event)"
                                                (focusout)="onchangequantity(_productdetail,$event)" maxlength="14"
                                                (keypress)="keypress.kpNumber($event)"
                                                [(ngModel)]="_productdetail.quantity" inputId="minmaxfraction"
                                                mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                            </p-inputNumber>
                                            <p-inputNumber autocomplete="off"
                                                (onBlur)="onchangequantity(_productdetail,$event)"
                                                *ngIf="_productdetail.salesuom == 'MSC00039'&& _action != 'view' "
                                                (keyup)="onchangequantity(_productdetail,$event)"
                                                (keyup.enter)="onchangequantity(_productdetail,$event)"
                                                (Keydown.enter) = "onchangequantity(_productdetail,$event)"
                                                (focusout)="onchangequantity(_productdetail,$event)" maxlength="14"
                                                (keypress)="keypress.kpNumber($event)"
                                                [(ngModel)]="_productdetail.quantity" inputId="minmaxfraction"
                                                mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                            </p-inputNumber>
                                            <div *ngIf="_action == 'view'">{{_productdetail.quantity}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_productdetail.quantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>
                <table>
                    <td>
                        <!-- Debug -->
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </table>
                <p-toast position="bottom-right"></p-toast>
            </div>
        </div>
    </div>
</div>