
    export class Customerwisediscountdetail {
        customerwisedetailid: number;
        customerwiseid: number;
        productid: number;
        usddiscount: number;
        randdiscount: number;
        bonddiscount: number;
        bankdiscount: number;
        tempusddiscount: number;
        tempranddiscount: number;
        tempbonddiscount: number;
        tempbankdiscount: number;

    }