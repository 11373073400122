
<div class="page-container ">
    <div class="inner-page-container">
      <div class="page-loader" *ngIf="_IsProgressSpinner">
        <p-progressSpinner strokeWidth="5"></p-progressSpinner>
      </div>
      <div class="white">
        <div class="page-title">
          <div class="row">
            <div class="col-md-5">
              <h3>Minus Stock Report</h3>
            </div>
          </div>
        </div>
        <div class="form-container scroll-y">
          <form [formGroup]="_rptminusstockform">
            <table class="normal-table">
              <tr>
                <td style="width: 20%;">
                  <span class="p-float-label">
                    <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy">
                    </p-calendar>
                    <label for="fromdate">From Date</label>
                  </span>
                </td>
                <td style="width: 20%;">
                  <span class="p-float-label">
                    <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                    </p-calendar>
                    <label for="todate">To Date</label>
                  </span>
                </td>
                <td style="width: 20%;">
                  <span class="p-float-label">
                    <p-dropdown [autoDisplayFirst]="false" [options]="_Invoicetype" optionValue="metasubcode" optionLabel="metasubdescription" formControlName="invoicetype" [filter]="true" filterBy="metasubdescription" [virtualScroll]="true" itemSize="15" [showClear]="true">
                    </p-dropdown>
                    <label for="supplierid">Invoice Type</label>
                  </span>
                </td>
                
                <td style="width: 20%;">
                  <span class="p-float-label">
                    <p-multiSelect [options]="_branchlist" optionLabel="branchname" optionValue="branchid" [(ngModel)]="branchid" [ngModelOptions]="{standalone: true}" [virtualScroll]="true" itemSize="30">
                      <ng-template let-account pTemplate="item">
                        <div [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                          {{account.branchname}}
                        </div>
                      </ng-template>
                    </p-multiSelect>
                    <label for="producttypeid">Branch Name</label>
                  </span>
                </td>
              </tr>
                <tr>
                <td style="width: 20%;">
                  <span class="p-float-label">
                    <p-multiSelect [options]="_producttypelist" optionLabel="producttypename" optionValue="producttypeid" [(ngModel)]="producttypeid" [ngModelOptions]="{standalone: true}" (onChange)="getProductCategory($event)" [virtualScroll]="true" itemSize="30">
                    </p-multiSelect>
                    <label for="producttypeid">Product Type Name</label>
                  </span>
                </td>
                <td style="width: 20%;">
                  <span class="p-float-label">
                    <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname" [selectionLimit]="50" [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}" (onChange)="getProduct($event)" optionValue="productcategoryid" [virtualScroll]="true" itemSize="30">
                    </p-multiSelect>
                    <label for="productcategoryid">Product Category Name</label>
                  </span>
                </td>
                <td style="width: 20%;">
                  <span class="p-float-label">
                    <p-multiSelect [options]="_productlist" optionLabel="productname" optionValue="productid" [(ngModel)]="productid" (onChange)="ChangeProduct($event)" [ngModelOptions]="{standalone: true}" [virtualScroll]="true" itemSize="30">
                    </p-multiSelect>
                    <label for="productid">Product Name</label>
                  </span>
                </td>
              </tr>
              <tr>
                <td style="width: 20%;">
                  <span class="p-float-label">
                    <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid" [(ngModel)]="brandid" [ngModelOptions]="{standalone: true}" [virtualScroll]="true" itemSize="30">
                    </p-multiSelect>
                    <label for="brandid">Brand Name</label>
                  </span>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                    <button pButton pRipple label="Search" icon="pi pi-search"
                        (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)"
                        class="p-button-danger"></button>
                </td>
            </tr>
            </table>
          </form>
          <div class="card">
            <p-toolbar styleClass="p-mb-4">
              <ng-template pTemplate="left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                </span>
              </ng-template>
              <ng-template pTemplate="right">
                <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel1()"></button>
                <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
              </ng-template>
            </p-toolbar>
           
            <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="50"
              [paginator]="true" [rowsPerPageOptions]="[100,200,300]" [globalFilterFields]="['INVOICETYPE','REFERENCENO','REFERENCEDATE','DELIVERYBRANCHNAME','CUSTOMERORBRANCHNAME','PRODUCTTYPENAME','PRODUCTCATEGORYNAME','PRODUCTNAME','BRANDNAME','QUANTITY',CREATEDBY]" [rowHover]="true" [columns]="cols" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="sno" style="width: 60px;">S.No<p-sortIcon field="sno"></p-sortIcon></th>
                  <th pSortableColumn="INVOICETYPE">Invoice Type<p-sortIcon field="INVOICETYPE"></p-sortIcon></th>
                  <th pSortableColumn="REFERENCENO">Reference No<p-sortIcon field="REFERENCENO"></p-sortIcon></th>
                  <th pSortableColumn="REFERENCEDATE">Reference Date<p-sortIcon field="REFERENCEDATE"></p-sortIcon></th>
                  <th pSortableColumn="DELIVERYBRANCHNAME">Delivery Branch<p-sortIcon field="DELIVERYBRANCHNAME"></p-sortIcon></th>
                  <th pSortableColumn="CUSTOMERORBRANCHNAME">CustomerName/BranchName<p-sortIcon field="CUSTOMERORBRANCHNAME"></p-sortIcon></th>
                  <th pSortableColumn="PRODUCTTYPENAME">Product Type<p-sortIcon field="PRODUCTTYPENAME"></p-sortIcon></th>
                  <th pSortableColumn="PRODUCTCATEGORYNAME">Product Category<p-sortIcon field="PRODUCTCATEGORYNAME"></p-sortIcon></th>
                  <th pSortableColumn="PRODUCTNAME">Product Name<p-sortIcon field="PRODUCTNAME"></p-sortIcon></th>
                  <th pSortableColumn="BRANDNAME">Brand Name<p-sortIcon field="BRANDNAME"></p-sortIcon></th>
                  <th pSortableColumn="QUANTITY">Quantity<p-sortIcon field="QUANTITY"></p-sortIcon></th>
                  <th pSortableColumn="CREATEDBY">Created By<p-sortIcon field="CREATEDBY"></p-sortIcon></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                <tr>
                  <td class="text-center" style="width: 60px;">
                    {{i+ 1}}
                  </td>
                  <td>
                    {{_ReportList.INVOICETYPE}}
                </td>
                <td>
                    {{_ReportList.REFERENCENO}}
                </td>
                <td>
                    {{_ReportList.REFERENCEDATE | date:'dd/MM/yyyy' }}
                </td>
                <td>
                    {{_ReportList.DELIVERYBRANCHNAME}}
                </td>
                <td>
                    {{_ReportList.CUSTOMERORBRANCHNAME}}
                </td>
                <td>
                    {{_ReportList.PRODUCTTYPENAME}}
                </td>
                <td>
                    {{_ReportList.PRODUCTCATEGORYNAME}}
                </td>
                <td>
                    {{_ReportList.PRODUCTNAME}}
                </td>
                <td>
                    {{_ReportList.BRANDNAME}}
                </td>
                <td>
                    {{_ReportList.QUANTITY}}
                </td>
                <td>
                    {{_ReportList.CREATEDBY}}
                </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-toast position="bottom-right"></p-toast>