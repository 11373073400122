import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { AccountledgerService } from 'src/app/core/services/accounts/accountledger.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-account',
  templateUrl: './v-account.component.html',
  styleUrls: ['./v-account.component.css']
})
export class VAccountComponent implements OnInit {
  selectedrows: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _Accountledgerview: Accountledgerview;
  _Accountledgerviews: Accountledgerview[]=[];
  _TempAccountledgerviews: any[];
  _IsProgressSpinner: boolean = true;
  _
  _Action: IAction;
  _userid = sessionStorage["userid"];
  constructor(
    private fb: FormBuilder,
    private _AccountledgerService: AccountledgerService,
    private _CustomExceptionService: CustomExceptionService,
    private router: Router,
    private utility: CommonUtilities,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(62);
    this.HotKeyIntegration();
    this._IsProgressSpinner = true;
    this._AccountledgerService.FetchAll(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Accountledgerviews = resultJSON.accountledgerviews;
      this._TempAccountledgerviews = resultJSON.accountledgerviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  view(event) {
    this.router.navigate(['/cAccount'], { state: { ledgerid: event.ledgerid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cAccount']);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'AccountLedger', 'xlsx');
  }
  ExportToPdf() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.ExportToPdf(AssetRegistrationList, 'AccountLedger.pdf');
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._Accountledgerviews.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Ledger Name"] = element.name;
      assetregistration["Parent Group"] = element.accountgroupname;
      assetregistration["Status"] = element.status;
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }

}