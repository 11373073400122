<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Pending Approval Status</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptpendingapprovalstatusform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchid"
                                        [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="name"> Branch Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_screenlist"
                                        optionValue="functionid" optionLabel="functionname" formControlName="screenlist"
                                        [filter]="true" filterBy="functionname">
                                    </p-dropdown>
                                    <label for="stocktype">Screen Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" icon="pi pi-search" (click)="GenerateReport()"
                                    class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)"
                                    class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>

                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="Pendingapprovalview" [scrollable]="true" scrollHeight="450px" [rows]="500" [paginator]="true"
                        [rowsPerPageOptions]="[100,200,300]" [rowsPerPageOptions]="[500,1000,1500,2000]"
                        [globalFilterFields]="['branchname','screenname', 'refno', 'createdby','createdon','approvalstatus']"
                        [rowHover]="true" dataKey="brandname" [columns]="cols"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No <p-sortIcon
                                        field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="branchname">Branch Name <p-sortIcon field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="screenname">Screen Name <p-sortIcon field="screenname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="refno">Ref No <p-sortIcon field="refno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="refno">Ref Date <p-sortIcon field="refno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdby">Created By <p-sortIcon field="createdby">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="approvalstatus">Approval Status <p-sortIcon field="approvalstatus">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Pendingapprovalview let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 60px;">
                                    {{i+1}}
                                </td>
                                <td>
                                    {{_Pendingapprovalview.BRANCHNAME}}
                                </td>
                                <td>
                                    {{_Pendingapprovalview.SCREENNAME}}
                                </td>
                                <td>
                                    {{_Pendingapprovalview.REFNO}}
                                </td>
                                <td>
                                    {{_Pendingapprovalview.REFDATE |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_Pendingapprovalview.CREATEDBY}}
                                </td>
                                <td>
                                    {{_Pendingapprovalview.CREATEDON |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_Pendingapprovalview.APPROVALSTATUS}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>