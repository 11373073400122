import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import * as moment from 'moment';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Customer } from 'src/app/core/Models/Customer';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Supplier } from 'src/app/core/Models/Supplier';
import { RptvatinputoutputService } from 'src/app/core/Services/reports/Accounting/RptVatinputoutput/rptvatinputoutput.service';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { Currencyview } from 'src/app/core/Views/Currencyview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';


@Component({
  selector: 'app-rptvatinputoutput',
  templateUrl: './rptvatinputoutput.component.html',
  styleUrls: ['./rptvatinputoutput.component.css']
})
export class RptvatinputoutputComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptvatinputoutputform: FormGroup;
  _branchlist: any[] = []
  _accountnamelist: Accountledger[];
  _userid: any;
  datepipe: any;
  _typelist = [{ type: 'Customer', value: '11' }, { type: 'Supplier', value: '15' }];
  _currencylist: Currencyview[];
  _customer_supplierlist: Accountledgerview[] = [];
  _src: SafeResourceUrl;
  branchid: number[] = [];
  currentbranch: number
  IsFullRights: boolean = true;
  isCheckboxVisible: boolean = false;
  _UserList: any[];
  _Action: IAction;
  mindate: Date;
  maxdate: Date;

  constructor(private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private fb: FormBuilder, private _RptVATInputOutputService: RptvatinputoutputService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(113);
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptvatinputoutputform = this.fb.group({
      fromdate: [''],
      todate: [''],
      partytype: [0],
      customer_supplierid: [0],
      branchid: [0],
      currencyid: [0],
      issummary: [true],
      IsFullRights: [false]
    })
    this.currentbranch = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);

    // this.InitializeForm();

    this.branchid.push(this.currentbranch);

    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptvatinputoutputform.get("fromdate").setValue(new Date);
      this._rptvatinputoutputform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptvatinputoutputform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptvatinputoutputform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptvatinputoutputform.get("todate").value;
    dt.setDate(dt.getDate() - 7);

    this._rptvatinputoutputform.controls['fromdate'].setValue(dt);
    // this._rptvatinputoutputform.controls['todate'].setValue(new Date());
    this._rptvatinputoutputform.controls['currencyid'].setValue(1);
    this._rptvatinputoutputform.controls['branchid'].setValue(parseInt(sessionStorage["currentbranchid"]));
    this._RptVATInputOutputService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // this._partytypelist = resultJSON.partytypeList;
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._UserList = resultJSON.userList;

      var series = this._UserList.find(f => f.userid == this._userid).series;

      if (this._userid == 1) {
        this.isCheckboxVisible = true;
      }
      this._IsProgressSpinner = false;
    },


      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this._rptvatinputoutputform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptvatinputoutputform.get("fromdate").setValue(new Date);
      this._rptvatinputoutputform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptvatinputoutputform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptvatinputoutputform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptvatinputoutputform.get("todate").value;
    dt.setDate(dt.getDate() - 7);

    this._rptvatinputoutputform.controls['fromdate'].setValue(dt);
    this._rptvatinputoutputform.controls['currencyid'].setValue(1);
    this._rptvatinputoutputform.controls['branchid'].setValue(parseInt(sessionStorage["currentbranchid"]));
    this.branchid = [];
    this.branchid.push(this.currentbranch);
    // this._rptvatinputoutputform.controls['issummary'].setValue(true);
  }

  GenerateReport(event) {
    var _fromdate = this._rptvatinputoutputform.get("fromdate").value;
    var _todate = this._rptvatinputoutputform.get("todate").value;
    var issummary = this._rptvatinputoutputform.get("issummary").value || false;

    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var IsFullRights = this._rptvatinputoutputform.get("IsFullRights").value || false;

    if (this.branchid.length >= 2)// branch id length
    {
      var from_date = moment(this._rptvatinputoutputform.get("fromdate").value, "YYYY-MM-DD");
      var to_date = moment(this._rptvatinputoutputform.get("todate").value, "YYYY-MM-DD");
      var caldays = to_date.diff(from_date, 'days');

      if (caldays > 30) {
        this._CustomExceptionService.handleError("From Date and ToDate should not be exceed 30 days");
        return;
      }
    }

    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }

    var currencyid = this._rptvatinputoutputform.get("currencyid").value == null ? 0 : this._rptvatinputoutputform.get("currencyid").value;

    //this._RptVATInputOutputService.Print(fromdate, todate,customerorsupplier,partytype, branchid, this._userid);
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;

    let data =
    {

      "FromDate": fromdate,
      "ToDate": todate,
      "Currencyid": currencyid,
      "Branchid": this.branchid,
      "CurrentBranchid": this.currentbranch,
      "IsSummary": issummary,
      "Userid": this._userid,
      "IsFullRights": IsFullRights
    }

    this._RptVATInputOutputService.Print(data).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });


  }
  // onchangepartytype(event) {
  //   var partytype = event.value;
  //   this._IsProgressSpinner = true;
  //   this._RptVATInputOutputService.onchangepartytype(partytype).subscribe((result) => {
  //     const resultJSON = JSON.parse(JSON.stringify(result));
  //     this._accountnamelist = resultJSON.accountnameList;
  //   },
  //     error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  // }

}
