import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
//Debug
import * as ace from "ace-builds";
import { PaymentService } from 'src/app/core/Services/accounts/payment.service';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Currency } from 'src/app/core/Models/Currency';
import { Payment } from 'src/app/core/Models/Payment';
import { usererrors } from 'src/app/core/errors/usererrors';
import { vCurrencyNameSelect, vFromAccountSelect, vPaymentAmount, vPaymentDateSelect, vPaymentModeSelect, vToAccountSelect } from 'src/app/core/Validators/validation';
import { Paymentdetailview } from 'src/app/core/Models/Paymentdetailview';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { flatten } from '@angular/compiler';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Creditnoteheaderview } from 'src/app/core/Models/Creditnoteheaderview';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUpload } from 'primeng/fileupload';
import { HotKeyIntegration } from 'src/assets/js/hot-key-integration';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-c-payment',
	templateUrl: './c-payment.component.html',
	styleUrls: ['./c-payment.component.css']
})
export class CPaymentComponent implements OnInit {
	paymentmodel: any = {};
	_IsSaveDisable: boolean = false;
	_IsClearDisable: boolean = false;
	_paymentform: FormGroup;
	_paymentbalanceform: FormGroup;
	_paymentotherform: FormGroup;
	selectedrows: any = [];
	_PaymentDetail: Paymentdetailview;
	_PaymentDetails: Paymentdetailview[] = [];
	selectedPaymentDetailsRows: Paymentdetailview[] = [];
	_OldPaymentDetails: Paymentdetailview[] = [];
	_Payment: Payment;
	_Paymentid: number;
	_userid = sessionStorage["userid"];
	_submitted = false;
	_action: string = "create";
	_toaccountsTemp: Accountledgerview[];
	_fromaccountsTemp: Accountledgerview[];
	_PaymentOBJ: any;
	_CurrenciesTemp: Currency[];
	_paymode: any;
	_partyTypeList: Metadatum[];
	_PaymentModes: Metadatum;
	_fromaccounts: Accountledgerview[] = [];
	_toaccounts: Accountledgerview[] = [];
	_currencies: Currency[] = [];
	_withoutfilterCurrenciesTemp: Currency[] = [];
	_TotalPaymentAmount = 0;
	_AdvancePaymentAmount = 0;
	_IsProgressSpinner: boolean = true;
	imageselect: string = "";
	currentDocument: File;
	currentDocumentSafeURL: SafeUrl;
	isDisabledButton = false;
	isdisabledocumentno = false;
	_PaymentsFor: Metadatum;
	FetchBilldetailsObj: any;
	_Action: IAction;
	currencyid: number;
	//For Container Based Advance:
	_containernos: any[] = [];
	_selectedcontainernos: string[] = [];
	_containerbased: boolean = true;
	mindate: Date;
	maxdate: Date;
	_isdatedisabled: boolean = true;
	_branchid = sessionStorage["currentbranchid"];
	
	//Debug
	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, private messageService: MessageService, private _CustomExceptionService: CustomExceptionService, private _PaymentService: PaymentService,
		private _DomSanitizationService: DomSanitizer, private _hotkeysService: HotKeyIntegration<void>, private _AccessRightsService: AccessRightsService) {
	}

	InitializeForm() {
		this.utility.pageLoadScript();
		this._paymentform = this.fb.group({
			paymentid: [0],
			paymentmode: ['', vPaymentModeSelect],
			voucherno: [0],
			paymentdate: ['', vPaymentDateSelect],
			fromaccount: ['', vFromAccountSelect],
			toaccount: ['', vToAccountSelect],
			paymentamount: ['', vPaymentAmount],
			refNo: [],
			currencyuniquerefid: [1],
			currentcurrencyid: ['', vCurrencyNameSelect],
			exchangerateinusd: [1],
			amountinusd: [0],
			narration: [],
			paymentstatusid: [],
			paymentstatusdesc: [],
			branchid: [0],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			accountingyear: [],
			advanceamount: [0],
			metasubdescription: [],
			fromaccountname: [],
			toaccountname: [],
			series: [],
			partytype: [],
			attachmentname: [],
			transactionincompleted: [1]
		});
		if (sessionStorage['Environmentenddate'] == "null") {
			this._paymentform.get('paymentdate').setValue(new Date());
			this.maxdate = new Date();
		}
		else {
			this._paymentform.get('paymentdate').setValue(new Date(sessionStorage['Environmentenddate']));
		}
		this._paymentbalanceform = this.fb.group({
			fromaccountclosingbalance: [0],
			toaccountclosingbalance: [0],
			totalpaymentamount: [0]
		});
		this._paymentotherform = this.fb.group({
			paymentfor: [],
			documentno: []
		})
	}

	ngOnInit(): void {
		this._Paymentid = history.state?.paymentid ? history.state?.paymentid : 0;
		let voucherno = history.state?.voucherno ? history.state?.voucherno : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.InitializeForm();
		this._Action = this._AccessRightsService.getAccessRights(68);
		// this.HotKeyIntegration();
		this._hotkeysService.Save(event => { this.onSave(); }, this._Action).Clear(event => { this.reset(event); }).Navigate(event => {
			this.goBack(event);
		}, this._Action, 'left');
		this._paymentform.get("paymentdate").disable();
		const data = sessionStorage.getItem('LedgerData');
		if (data) {
			const parsedData = JSON.parse(data);
			this._Paymentid = parsedData.Paymentid;
			this._userid = parsedData.userid;
			this._branchid = parsedData.branchid;
			voucherno = parsedData.voucherno;
			this._action = parsedData.action;
			sessionStorage.removeItem('LedgerData');
		  }
		this._IsProgressSpinner = true;
		this._PaymentService.PageOnLoad(this._Paymentid, this._userid, this._branchid, voucherno).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._PaymentModes = resultJSON.metadata;
			this._fromaccountsTemp = resultJSON.fromaccounts;
			this._currencies = resultJSON.currencies;
			this._CurrenciesTemp = resultJSON.currencies || [];
			this._withoutfilterCurrenciesTemp = resultJSON.currencies || [];
			this._toaccountsTemp = resultJSON.toaccounts || [];
			this._partyTypeList = resultJSON.partyTypeList || [];
			this._PaymentsFor = resultJSON.paymentfor || [];
			this._IsProgressSpinner = false;
			if (this._action == 'view') {
				const updateJSON = resultJSON;
				this._fromaccounts = updateJSON.fromaccounts;
				this._toaccounts = updateJSON.toaccounts;
				updateJSON.paymentupdateview.paymentdate = new Date(updateJSON.paymentupdateview.paymentdate);
				this._paymentform.setValue(updateJSON.paymentupdateview);
				this._PaymentDetails = updateJSON.paymentdetailupdateviews;
				this.selectedPaymentDetailsRows = updateJSON.paymentdetailupdateviews;
				this._paymentform.disable();
				this._IsSaveDisable = true;
				this._IsClearDisable = true;
				this.isDisabledButton = true;

			}
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	//Save Operation:
	onSave() {
		if (this._IsSaveDisable) return;
		this._submitted = true;
		let errMsg = '';
		let totalpaymentamount = parseFloat(this._paymentbalanceform.get("totalpaymentamount").value || 0);
		let paymentamount = parseFloat(this._paymentform.get("paymentamount").value || 0);
		let fromaccount = parseFloat(this._paymentform.get("fromaccount").value || 0);
		let toaccount = parseFloat(this._paymentform.get("toaccount").value || 0);
		let fromledger = this._fromaccounts.find(f => f.ledgerid == fromaccount);
		let toLedger = this._toaccounts.find(f => f.ledgerid == toaccount);
		let isPaidAmountInvalid = (this.selectedPaymentDetailsRows || []).some(f => !parseFloat(f.paidamount + '') || parseFloat(f.paidamount + '') <= 0);
		if (totalpaymentamount <= 0 && paymentamount <= 0) {
			errMsg = "0 or Negative value not permitted in total payment amount.";
		}
		if (isPaidAmountInvalid) {
			errMsg = "Paid amount should be greater than 0 in all selected rows.";
		}
		if (!fromaccount || !fromledger || !toaccount || !toLedger || (fromledger.series !== toLedger.series && fromledger.accountgroupid != 6)) {
			errMsg = "Invalid payment, please look at your chosed from and to account.";
		}
		var paymentfor = this._paymentotherform.get("paymentfor").value;
		if (paymentfor == 'MSC00329') {
			if (this._selectedcontainernos.length == 0) {
				errMsg = "Select Atleast one container No.";
			}
			else {
				const narration: string = `Advance Payment for these containers ${this._selectedcontainernos}`;
				this._paymentform.controls['narration'].setValue(narration);
			}
		}
		if (this.ShowErrorMsg(errMsg))
			return;

		//Bill Details
		let paymentTotalAmount = 0;
		var PaymentDetails: Paymentdetailview[] = [];
		Object.keys(this.selectedPaymentDetailsRows).map((Index) => {
			var currentRow = this.selectedPaymentDetailsRows[Index];
			var l_paymentdetails = new Paymentdetailview();
			l_paymentdetails.voucherno = currentRow.voucherno;
			l_paymentdetails.paymentdetailid = currentRow.paymentdetailid || 0;
			l_paymentdetails.paymentid = this._paymentform.get("paymentid").value;
			l_paymentdetails.billdate = currentRow.billdate;
			l_paymentdetails.billno = currentRow.billno;
			l_paymentdetails.creditamount = currentRow.creditamount;
			l_paymentdetails.debitamount = currentRow.debitamount;
			l_paymentdetails.paidamount = currentRow.paidamount;
			l_paymentdetails.pendingamount = currentRow.pendingamount;
			l_paymentdetails.series = currentRow.series;
			l_paymentdetails.vouchertypeid = currentRow.vouchertypeid;
			l_paymentdetails.vouchertypedesc = currentRow.vouchertypedesc;
			l_paymentdetails.currentcurrencyid = currentRow.currencyid;
			l_paymentdetails.currencyuniquerefid = currentRow.currencyuniquerefid;
			l_paymentdetails.invoiceno = currentRow.invoiceno ?? '';
			PaymentDetails.push(l_paymentdetails);
			paymentTotalAmount += currentRow.paidamount;
		})

		if (this._paymentform.valid) {
			this._Payment = this._paymentform.getRawValue();
			this._PaymentOBJ = { "Payment": this._Payment, "Paymentdetail": PaymentDetails };
			if (this._action == 'create') {
				if (!this._Payment.paymentamount || this._Payment.paymentamount <= 0)
					this._Payment.paymentamount = paymentTotalAmount;
				this._Payment.createdon = new Date();
				var paymentdate = this._paymentform.get("paymentdate").value;
				var datePipe = new DatePipe("en-US");
				var paymentdates = datePipe.transform(paymentdate, 'yyyy-MM-dd');
				this._Payment.paymentdate = (new Date(paymentdates))
				//this._Payment.paymentdate = new Date();
				//this._Payment.paymentdate = new Date();
				this._Payment.createdby = parseInt(sessionStorage["userid"]);
				this._Payment.branchid = parseInt(sessionStorage["BranchID"]);
				this._Payment.accountingyear = new Date().getFullYear();
				//Debug
				this._IsSaveDisable = true;
				this._IsClearDisable = true;
				this._IsProgressSpinner = true;
				// convert to form.
				this._PaymentOBJ = this.ConvertToFormObject(this._PaymentOBJ);
				if (parseFloat(this._Payment.advanceamount + '' || '0') > 0) {
					let currencyname = this._CurrenciesTemp.find(f => f.currencyid == this._Payment.currentcurrencyid).currencyname;
					let message = 'Please confirm to put the remaining amount as advance amount ' + this._Payment.advanceamount + '(' + currencyname + ')';
					this.ShowConfirm(message, () => { this.OnSaveDbCall(); });
				} else {
					this.OnSaveDbCall();
				}
			}
		}
	}


	OnSaveDbCall() {
		this._PaymentService.create(this._PaymentOBJ).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			if (resultJSON.tranStatus.result == true && resultJSON) {
				this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
				this.reset(null);
			}
			else {
				this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
				this._IsSaveDisable = false;
				this._IsClearDisable = false;
				this._IsProgressSpinner = false;
			}
		},
			error => {
				this._CustomExceptionService.handleError(error); this._IsSaveDisable = false;
				this._IsProgressSpinner = false; this._IsClearDisable = false;
			});
	}


	ConvertToFormObject(object: Object): any {
		const formData: FormData = new FormData();
		//TimeStamp Add:
		if (this.currentDocument) {
			formData.append('file', this.currentDocument, this.currentDocument.name);
		}
		let jsonData = JSON.stringify(object);
		formData.append('payment', jsonData);
		return formData;
	}

	getfromsupplierclosingbalance(event): void {
		this._IsProgressSpinner = true;
		this._PaymentService.GetFromAccountClosingBalance(event.value).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus && resultJSON.tranStatus.result) {
				this._paymentbalanceform.controls['fromaccountclosingbalance'].setValue(resultJSON.closingbalance);
			}
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	gettoclosingbalance() {
		this._containerbased = true;
		let toaccount: any;
		var paymentfor = this._paymentotherform.get("paymentfor").value;
		if (paymentfor == "MSC00329") { //MSC00329-->>Container based
			this._containerbased = false;
			this._paymentform.get("toaccount").reset();//MSC00214-->Agent  MSC00133-->Service
			this._toaccounts = this._toaccountsTemp.filter(f => f.categorytype == "MSC00214" || f.categorytype == "MSC00133")
			toaccount = null
		} else {
			toaccount = this._paymentform.get("toaccount").value;
			this._toaccounts = this._toaccountsTemp
		}
		this._PaymentDetails = [];
		this._OldPaymentDetails = [];
		this._paymentbalanceform.controls['toaccountclosingbalance'].setValue(0);
		this._paymentform.controls['paymentamount'].setValue(0);
		this._paymentbalanceform.controls['totalpaymentamount'].setValue(0);
		this._paymentform.controls['advanceamount'].setValue(0);
		if (!toaccount) {
			return;
		}
		var ledger = (this._toaccountsTemp || []).find(f => f.ledgerid == toaccount);
		if (ledger) {
			var partyCode = ledger.partycode;
			var partyType = this._paymentform.get("partytype").value;
			var series = ledger.series;
			var documentno = this._paymentotherform.get("documentno").value || "";
		}
		if (paymentfor == null || paymentfor == undefined) {
			this._CustomExceptionService.handleError("Please select payment for");
			return;
		}
		if (ledger) {


			if (ledger.customerorsupplierseries == "MSC00094")// W Series
			{
				this._currencies = this._CurrenciesTemp.filter(f => f.currencyid != 4);// Bank currency
			}
			else {
				this._currencies = this._withoutfilterCurrenciesTemp;
			}
		}

		this.OnChangePaymentMode(null);
		this._IsProgressSpinner = true;
		this._paymentform.get("paymentamount").setValue(0);
		this.FetchBilldetailsObj = { "partyType": partyType, "Partycode": partyCode, "series": series, "documentno": documentno, "paymentfor": paymentfor, "currencyid": this._paymentform.get("currentcurrencyid").value || 1 }
		this._PaymentService.FetchBillDetails(this.FetchBilldetailsObj).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._PaymentDetails = resultJSON.paymentdetailviews || [];
			this._OldPaymentDetails = resultJSON.paymentdetailviews || [];
			this._containernos = resultJSON.containernos || [];
			this._IsProgressSpinner = false;
			this._paymentform.controls['currentcurrencyid'].setValue(1);
			this._paymentform.controls['exchangerateinusd'].setValue(1);
			Object.keys(this._PaymentDetails).map((Index) => {
				var fromexchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == this._PaymentDetails[Index].currencyid)[0].exchangerate;
				var toexchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == 1)[0].exchangerate;
				this._PaymentDetails[Index].currencyid = 1;
				this._PaymentDetails[Index].creditamount = ((toexchangerate / fromexchangerate) * this._PaymentDetails[Index].creditamount).toFixed(2);
				this._PaymentDetails[Index].debitamount = ((toexchangerate / fromexchangerate) * this._PaymentDetails[Index].debitamount).toFixed(2);
				this._PaymentDetails[Index].paidamount = ((toexchangerate / fromexchangerate) * this._PaymentDetails[Index].paidamount).toFixed(2);
				this._PaymentDetails[Index].pendingamount = ((toexchangerate / fromexchangerate) * this._PaymentDetails[Index].pendingamount).toFixed(2);
				this._PaymentDetails[Index].tempPendingamount = ((toexchangerate / fromexchangerate) * this._PaymentDetails[Index].tempPendingamount).toFixed(2);
			});
			if (resultJSON.supplier)
				this._paymode = resultJSON.supplier.paymodecode; //paymode -- MSC00065 --Manual /  MSC00066 -- Fifo
			else if (resultJSON.customer) {
				this._paymode = resultJSON.customer.paymode; //paymode -- MSC00065 --Manual /  MSC00066 -- Fifo
			}
			else {
				this._paymentform.get("paymentamount").enable();
				if (this._paymode == "MSC00065" && this._PaymentDetails.length)
					this._paymentform.get("paymentamount").disable();
			}
			this._paymentbalanceform.controls['toaccountclosingbalance'].setValue(resultJSON.closingbalance);
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	gettoclosingbalance1() {
		this._containerbased = true;
		let toaccount: any;
		var paymentfor = this._paymentotherform.get("paymentfor").value;
		if (paymentfor == "MSC00329") { //MSC00329-->>Container based
			this._containerbased = false;
			// this._paymentform.get("toaccount").reset();//MSC00214-->Agent  MSC00133-->Service
			this._toaccounts = this._toaccountsTemp.filter(f => f.categorytype == "MSC00214" || f.categorytype == "MSC00133")
			toaccount = this._paymentform.get("toaccount").value;
		} else {
			toaccount = this._paymentform.get("toaccount").value;
			this._toaccounts = this._toaccountsTemp
		}
		this._PaymentDetails = [];
		this._OldPaymentDetails = [];
		this._paymentbalanceform.controls['toaccountclosingbalance'].setValue(0);
		this._paymentform.controls['paymentamount'].setValue(0);
		this._paymentbalanceform.controls['totalpaymentamount'].setValue(0);
		this._paymentform.controls['advanceamount'].setValue(0);
		if (!toaccount) {
			return;
		}
		var ledger = (this._toaccountsTemp || []).find(f => f.ledgerid == toaccount);
		var partyCode = ledger.partycode;
		var partyType = this._paymentform.get("partytype").value;
		var series = ledger.series;
		var documentno = this._paymentotherform.get("documentno").value || "";
		if (paymentfor == null || paymentfor == undefined) {
			this._CustomExceptionService.handleError("Please select payment for");
			return;
		}
		if (ledger.customerorsupplierseries == "MSC00094")// W Series
		{
			this._currencies = this._CurrenciesTemp.filter(f => f.currencyid != 4);// Bank currency
		}
		else {
			this._currencies = this._withoutfilterCurrenciesTemp;
		}

		this.OnChangePaymentMode(null);
		this._IsProgressSpinner = true;
		this._paymentform.get("paymentamount").setValue(0);
		this.FetchBilldetailsObj = { "partyType": partyType, "Partycode": partyCode, "series": series, "documentno": documentno, "paymentfor": paymentfor, "currencyid": this._paymentform.get("currentcurrencyid").value || 1 }
		this._PaymentService.FetchBillDetails(this.FetchBilldetailsObj).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._PaymentDetails = resultJSON.paymentdetailviews || [];
			this._OldPaymentDetails = resultJSON.paymentdetailviews || [];
			this._containernos = resultJSON.containernos || [];
			this._IsProgressSpinner = false;
			this._paymentform.controls['currentcurrencyid'].setValue(1);
			this._paymentform.controls['exchangerateinusd'].setValue(1);
			Object.keys(this._PaymentDetails).map((Index) => {
				var fromexchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == this._PaymentDetails[Index].currencyid)[0].exchangerate;
				var toexchangerate = (this._CurrenciesTemp || []).filter(f => f.currencyid == 1)[0].exchangerate;
				this._PaymentDetails[Index].currencyid = 1;
				this._PaymentDetails[Index].creditamount = ((toexchangerate / fromexchangerate) * this._PaymentDetails[Index].creditamount).toFixed(2);
				this._PaymentDetails[Index].debitamount = ((toexchangerate / fromexchangerate) * this._PaymentDetails[Index].debitamount).toFixed(2);
				this._PaymentDetails[Index].paidamount = ((toexchangerate / fromexchangerate) * this._PaymentDetails[Index].paidamount).toFixed(2);
				this._PaymentDetails[Index].pendingamount = ((toexchangerate / fromexchangerate) * this._PaymentDetails[Index].pendingamount).toFixed(2);
				this._PaymentDetails[Index].tempPendingamount = ((toexchangerate / fromexchangerate) * this._PaymentDetails[Index].tempPendingamount).toFixed(2);
			});
			if (resultJSON.supplier)
				this._paymode = resultJSON.supplier.paymodecode; //paymode -- MSC00065 --Manual /  MSC00066 -- Fifo
			else
				this._paymode = resultJSON.customer.paymode; //paymode -- MSC00065 --Manual /  MSC00066 -- Fifo

			this._paymentform.get("paymentamount").enable();
			if (this._paymode == "MSC00065" && this._PaymentDetails.length)
				this._paymentform.get("paymentamount").disable();

			this._paymentbalanceform.controls['toaccountclosingbalance'].setValue(resultJSON.closingbalance);
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	ChangeCurrencies(event): void {
		let toaccount = this._paymentform.get("toaccount").value;
		this._PaymentDetails = [];
		this._OldPaymentDetails = [];
		this.selectedPaymentDetailsRows = [];
		this._paymentbalanceform.controls['toaccountclosingbalance'].setValue(0);
		this._paymentform.controls['paymentamount'].setValue(0);
		this._paymentbalanceform.controls['totalpaymentamount'].setValue(0);
		this._paymentform.controls['advanceamount'].setValue(0);
		if (!toaccount) {
			return;
		}
		var ledger = (this._toaccountsTemp || []).find(f => f.ledgerid == toaccount);
		var partyCode = ledger.partycode;
		var partyType = this._paymentform.get("partytype").value;
		var series = ledger.series;
		var paymentfor = this._paymentotherform.get("paymentfor").value;
		var documentno = this._paymentotherform.get("documentno").value || "";
		if (paymentfor == null || paymentfor == undefined) {
			this._CustomExceptionService.handleError("Please select payment for");
			return;
		}
		this.OnChangePaymentMode(null);
		this._IsProgressSpinner = true;
		this._paymentform.get("paymentamount").setValue(0);
		var exchangerateinusd = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].exchangerate;
		this._paymentform.controls['exchangerateinusd'].setValue(exchangerateinusd);
		this.FetchBilldetailsObj = { "partyType": partyType, "Partycode": partyCode, "series": series, "documentno": documentno, "paymentfor": paymentfor, "currencyid": this._paymentform.get("currentcurrencyid").value || 1 }
		this._PaymentService.FetchBillDetails(this.FetchBilldetailsObj).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._PaymentDetails = resultJSON.paymentdetailviews || [];
			this._OldPaymentDetails = resultJSON.paymentdetailviews || [];
			this._IsProgressSpinner = false;
		});
		this.TotalAmount();
	}


	reset(event) {
		this._submitted = true;
		this._IsClearDisable = false;
		this._IsSaveDisable = false;
		this.selectedPaymentDetailsRows = [];
		this._paymentform.reset();
		this.InitializeForm();
		this._fromaccounts = [];
		this._toaccounts = [];
		this._OldPaymentDetails = [];
		this._PaymentDetails = [];
		this._submitted = false;
		this.currentDocument = null;
		this.currentDocumentSafeURL = null;
		this.paymentmodel = {};
		this._containernos = [];
	}
	// on clicking back button , it Navigates to the search screen
	goBack(event) {
		this._router.navigate(['/vPayment']);
	}
	//Debug
	onEd() {
		ace.config.set("fontSize", "14px");
		ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
		const aceEditor = ace.edit(this.editor.nativeElement);
		aceEditor.getSession().setMode("ace/mode/json");
		var myObj = this._PaymentOBJ;
		var myJSON = JSON.stringify(myObj);
		aceEditor.session.setValue(myJSON);
	}


	//get Paymode from supplier master and check if it is FIFO . MSC00065 --Manual /  MSC00066
	allocatePaymentAmount(event) {
		this.selectedPaymentDetailsRows = [];
		if (this._paymode != 'MSC00066' && this._PaymentDetails.length)
			return;
		var RemainingAmount = 0;
		var PaymentAmount = this._paymentform.get("paymentamount").value;
		this._PaymentDetails.every((item, index) => {
			item.isselected = true;
			this.selectedPaymentDetailsRows.push(item);
			item.pendingamount = item.tempPendingamount;
			RemainingAmount = PaymentAmount - item.tempPendingamount;
			if (RemainingAmount >= 0)
				item.paidamount = item.tempPendingamount;
			else
				item.paidamount = PaymentAmount;
			item.pendingamount -= (item.paidamount);
			if (RemainingAmount <= 0)
				return false;
			else {
				PaymentAmount = RemainingAmount;
				return true;
			}
		});
		this.TotalAmount();
	}

	OnChangePaymentMode(event) {
		var receiptMode = this._paymentform.get("paymentmode").value;
		this._isdatedisabled = receiptMode !== "MSC00148"
		this._paymentform.get('paymentdate').setValue(new Date());
		this._paymentform.get("fromaccount").reset();
		let userid = parseInt(sessionStorage["userid"] || 0);
		let cashgroups = [30, 31];
    this.PaymentmodebasedAccount();
		if (receiptMode == "MSC00146") { // Cash
			this._fromaccounts = this._fromaccountsTemp.filter(f => cashgroups.includes(f.accountgroupid));
			if (userid !== 1) {
				let toaccount = this._paymentform.get("toaccount").value;
				let toaccountledger = this._toaccounts.find(f => f.ledgerid == toaccount);
				let toseries = 0;
				if (toaccountledger) toseries = toaccountledger.series;
				var ledger = this._fromaccounts.find(f => f.series == toseries);
				if (ledger)
					this._paymentform.get("fromaccount").setValue(ledger.ledgerid);
			}
		}
		else
			this._fromaccounts = this._fromaccountsTemp.filter(f => !cashgroups.includes(f.accountgroupid));
	}

	TotalAmount() {
		let advanceAmount = 0;
		let paidAmount = 0;
		let paymentAmount = parseFloat(this._paymentform.get("paymentamount").value || 0);
		let creditamount = 0;
		let selectedList = this._PaymentDetails.filter(f => this.selectedPaymentDetailsRows.includes(f));
		selectedList.forEach(element => {
			if (element.debitamount > 0) {
				paidAmount += parseFloat(element.paidamount + '' || '0');
			}
		});

		if ((this._paymode == "MSC00065" && this._PaymentDetails.length)) // -- Manual
		{
			paymentAmount = paidAmount;//- creditamount;
			this._paymentform.get("paymentamount").setValue(paymentAmount);
		}

		if (paidAmount >= paymentAmount) {
			paymentAmount = paidAmount;
			this._paymentform.get("paymentamount").setValue(paymentAmount);
		}
		this._paymentbalanceform.controls['totalpaymentamount'].setValue(paymentAmount);
		advanceAmount = (paymentAmount - paidAmount);
		if (advanceAmount > 0) {
			this._paymentform.controls['advanceamount'].setValue(advanceAmount);
		} else {
			this._paymentform.controls['advanceamount'].setValue(0);
		}
		//}
	}

	OnChangePaidAmount(value, modelObject) {
		value = parseFloat(value || 0);
		modelObject["paidamount"] = value;
		var pendingAmount = parseFloat(modelObject['tempPendingamount'] || 0);
		let creditAmount = parseFloat(modelObject["creditamount"] || 0);
		var paidAmount = parseFloat(value || 0);
		var amount = creditAmount == 0 ? (pendingAmount - paidAmount) : (paidAmount + pendingAmount);
		if (amount < 0 && creditAmount == 0) {
			if (this.ShowErrorMsg("Paid Amount must be less than Pending Amount.")) {
				modelObject['paidamount'] = pendingAmount;
				modelObject['pendingamount'] = 0;
			}
		}
		else {
			if (creditAmount > 0 && paidAmount > Math.abs(creditAmount)) {
				if (this.ShowErrorMsg("Paid Amount must be less than Pending Amount.")) {
					amount = 0;
					return;
				}
			}
			modelObject['pendingamount'] = amount;
		}
		this.TotalAmount();
	}

	ShowErrorMsg(errMsg) {
		if (!errMsg)
			return false;
		this._CustomExceptionService.handleWarning(errMsg);
		return true;
	}

	GetCustomerByPartyType($event) {
		let userid = sessionStorage["userid"];
		this._toaccounts = [];
		this._toaccountsTemp = [];
		this._IsProgressSpinner = true;
		this._PaymentDetails = [];
		this._PaymentService.GetCustomerByPartyType(userid, $event.value).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._toaccounts = resultJSON.toaccounts;
			this._toaccountsTemp = resultJSON.toaccounts || [];
      this.PaymentmodebasedAccount();
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	PaymentRowSelect(event) {
		let data = <Paymentdetailview>event.data;
		data.paidamount = parseFloat(data.paidamount + '' || '0');
		data.isselected = true;
		if (data.vouchertypeid == 6)
			data.paidamount = Math.abs(data.debitamount);
		this.TotalAmount();
	}

	PaymentRowUnSelect(event) {
		let data = <Paymentdetailview>event.data;
		data.isselected = false;
		//if (data.vouchertypeid == 6)
		data.paidamount = 0;
		data.pendingamount = data.tempPendingamount;
		this.TotalAmount();
	}

	//Document Select:
	onDocumentSelect(event, document) {
		this.currentDocument = event.currentFiles[0];
		this._paymentform.get("attachmentname").setValue(this.currentDocument.name);
		document.clear();
	}

	// Document Upload 
	showdocumentDialog(document: FileUpload) {
		let url = null;
		if (this._action != 'view') {
			if (this.currentDocument) {
				this.imageselect = this.currentDocument.name;
				url = URL.createObjectURL(this.currentDocument);
			}
			if (!url)
				return;
			window.open(url, "MsgWindow", "width=800,height=800");
		} else {
			let attachment = this._paymentform.get("attachmentname").value;
			if (attachment) {
				url = attachment;
				window.open(url, "MsgWindow", "width=800,height=800");
			}
		}
	}

	//Document Clear:
	onDocumentClear(document: FileUpload) {
		this._paymentform.get("attachmentname").reset();
		this.currentDocumentSafeURL = null;
		this.currentDocument = null;
		document.clear();
	}


	ShowConfirm(message: string, yesHandler: any, NoHandler?: any) {
		this.paymentmodel.showdialog = true;
		this.paymentmodel.message = message;
		this.paymentmodel.Yes = () => {
			if (typeof yesHandler === 'function')
				yesHandler();
			this.paymentmodel.showdialog = false;
			this._IsProgressSpinner = false;
		};

		this.paymentmodel.Cancel = () => {
			if (typeof NoHandler === 'function')
				NoHandler();
			this._IsSaveDisable = false;
			this.paymentmodel.showdialog = false;
			this._IsProgressSpinner = false;
		};
	}

	//For Container Based Advance:
	onSelectcontno(event) {
		var refno = event.value;
		var paymentfor = this._paymentotherform.get("paymentfor").value;
		if (paymentfor == 'MSC00329') {
			const message: string = `Advance Payment for these containers ${this._selectedcontainernos}`;
		}
	}
	selectall() {
		let isChecked = (this.selectedPaymentDetailsRows.length > 0);
		if (isChecked) {
			for (var i = 0; i < this.selectedPaymentDetailsRows.length; i++) {
				let event = { data: this.selectedPaymentDetailsRows[i] };
				this.PaymentRowSelect(event);
			}
		}
		else {
			for (var i = 0; i < this._PaymentDetails.length; i++) {
				let event = { data: this._PaymentDetails[i] };
				this.PaymentRowUnSelect(event);
			}
		}
	}

  PaymentmodebasedAccount() {
    let paymentMode = this._paymentform.get('paymentmode').value;
    let partytype = this._paymentform.get('partytype').value;

    this._toaccounts = this._toaccountsTemp.filter(account => {
      if (paymentMode === 'MSC00148') {
        return account.customerorsupplierseries !== 'MSC00094';
    } else {
        return true;
    }
    });
}
}

