export class Couponincludeproductdetail {
    coupondetailid: number;
    couponid: number;
    includeproductid: number;
    createdby: number;
    createdon: Date | string;
    modifiedby: number | null;
    modifiedon: Date | string | null;

   
}