<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Approval Type</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save" (click)="onSave()"
                                [disabled]="isDisabledSave"></button>
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                icon="pi pi-trash" class="" class="p-button-danger"
                                [disabled]="isDisabledClear"></button>
                            <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_Approvaltypeform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!-- 11. if document name is not given, show error as "please select document name" -->
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Documentname"
                                        optionLabel="functionname" optionValue="functionid" name="documenttypeid"
                                        formControlName="documenttypeid" (onChange)="ChangeDocument()" [filter]="true"
                                        filterBy="functionname" [showClear]="true">
                                    </p-dropdown>
                                    <label for="documenttypeid">Document Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Approvaltypeform.get('documenttypeid').touched) && _Approvaltypeform.get('documenttypeid').errors?.SelectDocumentName">
                                    Please Select Document Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!-- 12.if workflow type is not given, show error as "please select  workflow type" -->
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Workflowtype"
                                        optionLabel="metasubdescription" optionValue="metasubcode" name="workflowtype"
                                        formControlName="workflowtype">
                                    </p-dropdown>
                                    <label for="workflowtype">Workflow Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Approvaltypeform.get('workflowtype').touched) && _Approvaltypeform.get('workflowtype').errors?.SelectWorkflowType">
                                    Please Select Workflow Type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <!-- 13.if workflow name  is not given, show error as "please enter workflow name  -->
                                    <input type="text" pInputText name="workflowname" formControlName="workflowname">
                                    <label for="workflowname">Workflow Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Approvaltypeform.get('workflowname').touched) && _Approvaltypeform.get('workflowname').errors?.WorkflowName">
                                    Please Enter Workflow Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                            </td>
                            <td style="width: 20%;">
                            </td>
                        </tr>
                        <tr>
                            <td *ngIf="false">
                                <span class="p-float-label">
                                    <!-- 15. if condition  type is not given, show error as "please select  condition type" -->
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Conditiontype"
                                        optionLabel="metasubdescription" optionValue="metasubcode" name="conditiontype"
                                        formControlName="conditiontype">
                                    </p-dropdown>

                                    <label for="conditiontype">Condition Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _Approvaltypeform.get('conditiontype').touched) && _Approvaltypeform.get('conditiontype').errors?.SelectConditionType">
                                    Please Select Condition Type
                                </span>
                            </td>
                            <td *ngIf="false">
                                <span class="p-float-label">
                                    <!-- 16. if condition value  is not given, show error as "please enter condition value " -->
                                    <input type="number" pInputText name="conditionvalue"
                                        formControlName="conditionvalue">
                                    <label for="conditionvalue">Condition Value</label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _Approvaltypeform.get('conditionvalue').touched) && _Approvaltypeform.get('conditionvalue').errors?.ConditionValue">
                                    Please Enter Condition Value
                                </span> -->
                            </td>
                            <td *ngIf="false">
                                <span class="p-float-label">
                                    <input type="number" pInputText name="tatinhours" formControlName="tatinhours">
                                    <label for="tatinhours">TAT in hrs</label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _Approvaltypeform.get('tatinhours').touched) && _Approvaltypeform.get('tatinhours').errors?.TATinHrs">
                                    Please Enter TAT in hrs
                                </span> -->
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Workflow Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <!-- 22.based on the document name, approval type id  you need to filter the data and show in the grid -->
                    <!-- 23. on clicking search button , the data must be filtered and display in the grid -->
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_workflowdetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['documenttypename','workflowtypename','workflowname','conditiontypename','unitprice','transactionamount','debitamount','reason']"
                        [rowHover]="true" dataKey="productid" [(selection)]="selectedrows"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <!-- <th style="width: 100px;text-align:center">Action</th> -->
                                <th>Document Name</th>
                                <th>WorkFlow Type</th>
                                <th>Workflow Name</th>
                                <th *ngIf="false">Condition Type</th>
                                <th *ngIf="false">Condition Value</th>
                                <th *ngIf="false">TAT in Hours</th>

                                <th>Work Flow Status</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_workflowdetail>
                            <tr>
                                <!-- <td>
                                    <button pButton pRipple title="Remove" icon="pi pi-trash"
                                    class="p-button-danger p-mr-2"
                                    (click)="RemoveWorkflow(_workflowdetail)"></button>
                                </td> -->
                                <td>{{_workflowdetail.documenttypename}}</td>
                                <td>{{_workflowdetail.workflowtypename}}</td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText [(ngModel)]="_workflowdetail.workflowname"
                                                *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_workflowdetail.workflowname}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_workflowdetail.workflowname}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td  *ngIf="false">
                                    {{_workflowdetail.conditiontypename}}
                                </td>
                                 <td pEditableColumn *ngIf="false">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText [(ngModel)]="_workflowdetail.conditionvalue"
                                                *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_workflowdetail.conditionvalue}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_workflowdetail.conditionvalue}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                    <td pEditableColumn *ngIf="false">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input type="text" pInputText [(ngModel)]="_workflowdetail.tatinhours"
                                                    *ngIf="_action != 'view'">
                                                <div *ngIf="_action == 'view'">{{_workflowdetail.tatinhours}}</div>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{_workflowdetail.tatinhours}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Approvalstatus"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                name="approvaltypestatusname" [disabled]="_action == 'view'"
                                                [(ngModel)]="_workflowdetail.approvaltypestatus">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Approvalstatus"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                name="approvaltypestatusname" [disabled]="true"
                                                [(ngModel)]="_workflowdetail.approvaltypestatus">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <tr>
                        <td>
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>

            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>