export class Servicerequestcustomerbaseddetail {
    servicerequestcustomerbaseddetailid: number;
    servicerequestid: number;
    barcode: string;
    producttypeid: number;
    productid: number;
    variantid: number;
    quantity: number | null;
    uomcode: string;
    unitprice: number | null;
    finalamount: number | null;
    taxid: number | null;
    taxpercentage: number | null;
    taxamount: number | null;
    discountpercentage: number | null;
    discountamount: number | null;
    isschemeapplied: boolean | null;
    srquantity: number | null;
    status: string;
    iswarrantyapplicable: boolean;
    warrantydate: Date | string | null;

}