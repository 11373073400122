import { Injectable } from '@angular/core';
declare var $: any;
@Injectable({
	providedIn: 'root'
})
export class CommonUtilities {
	timer: NodeJS.Timeout;
	pageLoadScript() {
		$(document).ready(() => {
			GetClock();
			if (!this.timer)
				this.timer = setInterval(GetClock, 1000);
			findURL();
			pageHeight();
			$(window).bind('resize', function () {
				pageHeight();
			});
			$(document).on('click', ".product-listing", function () {
				$(this).addClass('select');
				setTimeout(function () {
					$('.product-listing.select').removeClass('select');
				}, 500)
			});
			$(document).on('click', ".filter-container .dropdown-menu.big", function (event) {
				event.stopPropagation();
			});
			$(document).on('click', '.p-menu-list li a', function () { //p-menu-list
				var pageURL = $(this).attr('href');
				if (pageURL !== "javascript:void(0);") {
					$('.p-menu-list li a').removeClass('select');
					$('.p-menu-list li.has-sub').removeClass('open');
					$('.p-menu-list li.has-sub .submenu').hide();
					$(this).addClass('select');
					//$(this).parents('li').addClass('open').parents('.submenu').show();
					findURL();
					pageHeight();
				}
			});
			$(document).on('click', ".menu-tabs > a", function () {
				var relID = $(this).attr('rel');
				if (relID !== undefined) {
					$('.apps-center').animate({ left: 60 }, 0);
					var parentRelID = $(this).parent().attr('rel');
					var parentClass = $(this).parent().attr('class');
					var trimClass = parentClass.split(" ")[1];
					if (trimClass == null) {
						$('.menu-tabs-container').removeClass('select');
						$('.menu-tabs > a').removeClass('select');
						$(this).addClass('select');
						$('.' + parentRelID + ' .' + parentClass + '-container' + '.' + relID).addClass('select');
					}
				}
				else {
					findURL();
					pageHeight();
				}
			});
			$(document).on('click', ".main-tab a", function () {
				var parentRelID = $(this).parent().attr('rel');
				var parentClassName = $(this).parent().attr('class');
				var relID = $(this).attr('rel');
				$('.' + parentRelID + ' .' + parentClassName + '-container').hide();
				$(this).parent().find('a').removeClass('select');
				$(this).addClass('select');
				$('.' + parentRelID + ' .' + parentClassName + '-container.' + relID).show();
				pageHeight();
			});
			$(document).on('click', function (event) {
				if (!$(event.target).closest('.apps-left,.apps-center').length) {
					$('.apps-center').animate({ left: -500 }, 0);
					$('.header-search.all input').removeClass('focus-bg');
				}
			});
			$(document).on('click', ".approval-close", function () {
				$(this).parents('.approval-notification').hide();
			});
			$(document).on('click', ".workflow-links", function () {
				$('.workflow-links').removeClass('select');
				$(this).addClass('select');
			});
		});
		function GetClock() {
			var tday = new Array("Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday");
			var tmonth = new Array("01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12");
			var d = new Date();
			let nday: number;
			let nmonth: number;
			let ndate: number;
			let nyear: number;
			let nhour: number;
			let nmin: number;
			let nsec: number;
			let ap: string;
			let ndate01: string;
			let nhour01: string;
			let nmin01: string;
			let nsec01: string;
			//alert(d.getFullYear());
			nday = d.getDay();
			nmonth = d.getMonth();
			ndate = d.getDate();
			ndate01 = d.getDate().toString();
			nhour01 = d.getHours().toString();
			nmin01 = d.getMinutes().toString();
			nsec01 = d.getSeconds().toString();
			nyear = d.getFullYear();
			nhour = d.getHours();
			nmin = d.getMinutes();
			nsec = d.getSeconds();
			ap;

			if (nhour == 0) { ap = " AM"; nhour = 12; }
			else if (nhour < 12) { ap = " AM"; }
			else if (nhour == 12) { ap = " PM"; }
			else if (nhour > 12) { ap = " PM"; nhour -= 12; }

			//if (nyear < 1000) nyear += 1900;
			if (ndate <= 9) ndate01 = '0' + ndate;
			if (nhour <= 9) nhour01 = '0' + nhour;
			if (nmin <= 9) nmin01 = '0' + nmin;
			if (nsec <= 9) nsec01 = '0' + nsec;

			document.getElementById('clockbox').innerHTML = ndate01 + "/" + tmonth[nmonth] + "/" + nyear + " " + nhour01 + ":" + nmin01 + ":" + nsec01 + ap + "";
		}
		function pageHeight() {
			$('.apps-center').animate({ left: -500 }, 0);
			var bdyHeight = $(window).outerHeight(true);
			var copyHeight = $('.copyright').outerHeight(true);
			var pageTitleHeight = $('.page-title').outerHeight(true);
			var inputHeight = $('.input-container').outerHeight(true);
			var pageHeight01 = bdyHeight - 70;
			var pageHeight02 = bdyHeight - copyHeight;
			var pageHeight03 = pageHeight01 - pageTitleHeight - 20;
			var outputHeight = pageHeight03 - inputHeight - 10;
			$('body').attr('style', 'height:' + bdyHeight + 'px;');
			$('.page-container.dashboard-page,.page-container:not(.dashboard-page) .inner-page-container > .white').attr('style', 'height:' + pageHeight01 + 'px;');
			$('.sidebar').attr('style', 'height:' + pageHeight02 + 'px;');
			$('.main-menu').attr('style', 'height:' + (pageHeight02 - 60) + 'px;');
			$('.form-container').attr('style', 'height:' + pageHeight03 + 'px;');
			$('.scroll-height').attr('style', 'height:' + outputHeight + 'px;');
		};
		function findURL() {
			var URL = window.location.pathname;
			var PageName = URL.substring(URL.lastIndexOf('/') + 1);
			$('.menu-tabs > a').each(function () {
				var pageURL = $(this).attr('href');
				if (pageURL !== "javascript:void(0);") {
					if (pageURL === ('/' + PageName)) {
						$('.menu-tabs > a').removeClass('select');
						$(this).addClass('select');
					}
				}
			});
			$('.p-menu-list li a').each(function () { //replace p-menu-list instead of nav
				var pageURL = $(this).attr('href');
				if (pageURL !== "javascript:void(0);") {
					if (pageURL === ('/' + PageName)) {
						//$(this).parents('li').addClass('open').parents('.submenu').show();
						$(this).toggleClass('select');
						$('.menu-tabs-container,.menu-tabs a').removeClass('select');
						var parentClass = $(this).parents('.menu-tabs-container').attr('class');
						var trimClass = parentClass.split(" ")[1];
						$(this).parents('.menu-tabs-container').addClass('select');
						$(('.menu-tabs a[rel=' + trimClass + ']')).addClass('select');
						pageHeight();
					}
				}
			});
		}
	}
	widgetFilter() {
		$(document).on('click', ".settings-trigger .collapsed", function () {
			$('.settings-container').animate({ right: 0 }, 350).addClass('expand');
			$(this).removeClass('collapsed').addClass('expanded');
			$(this).parent().animate({ right: 250 }, 350).addClass('expand');
		});
		$(document).on('click', ".settings-trigger .expanded", function () {
			$('.settings-container').animate({ right: -250 }, 350).removeClass('expand');
			$(this).removeClass('expanded').addClass('collapsed');
			$(this).parent().animate({ right: 0 }, 350).removeClass('expand');
		});
	}
	customScrollbar() {
		$(".sidebar").mCustomScrollbar({ theme: 'dark' });
	}
	workflowStatus() {
		// if ($('.workflow-container').css('right') == '0px') {
		//   $('.workflow-container').animate({ right: -300 }, 350).removeClass('expand');
		//   $('.workflow-animation .workflow-details').removeClass('completed').removeClass('rejected');
		//   $('.workflow-container').animate({ right: 0 }, 350).addClass('expand');
		//   var startLevel = 1000;
		//   workflowStart(startLevel);
		// }
		//else {
		$('.workflow-container').animate({ right: 0 }, 350).addClass('expand');
		var startLevel = 500;
		workflowStart(startLevel);
		//}
		function workflowStart(startLevel) {
			var completedLevel, rejectedLevel, completedDelay = 0;
			completedLevel = $('.workflow-animation').attr('data-level-completed');
			rejectedLevel = $('.workflow-animation').attr('data-level-rejected');
			if (rejectedLevel > 1) rejectedLevel = 1;
			completedDelay = startLevel + (completedLevel * 500);

			var i = 0;
			initiateComplete(i);
			$('.workflow-animation .workflow-details').removeClass('completed').removeClass('rejected');
			function initiateComplete(i) {
				setTimeout(function () { checkComplete(i) }, startLevel);
				setTimeout(function () { checkReject(i) }, completedDelay);
			}
			function checkComplete(i) {
				if (i < completedLevel) {
					$('.workflow-animation .workflow-details').eq(i).addClass('completed');
					i++;
					initiateComplete(i);
				}
			}
			function checkReject(i) {
				if (i < rejectedLevel) {
					$('.workflow-animation .workflow-details:not(.completed)').eq(i).addClass('rejected');
					i++;
					initiateComplete(i);
				}
			}
			$(document).on('click', ".workflow-container .page-title .close", function () {
				$('.workflow-container').animate({ right: -300 }, 350).removeClass('expand');
				$('.workflow-animation .workflow-details').removeClass('completed').removeClass('rejected');
			});
		}
	}

	ClearTimer() {
		//this.timer.
		if (this.timer) {
			clearInterval(this.timer);
		}
	}

	ngOnDestroy() {
		this.ClearTimer();
	}
	swipeToAction() {
		$(".invoice-list:not(.invoice-list.invoice-total) li div.wrapper").on("swipeleft", function (e) {
			$(this).addClass('swipe-left');
			e.stopImmediatePropagation();
		});
		$(".invoice-list:not(.invoice-list.invoice-total) li div.wrapper").on("click", function (e) {
			$(this).toggleClass('swipe-left');
			e.stopImmediatePropagation();
		});
		$('.quick-container').on('click', function (e) { // if the target of the click isn't the container nor a descendant of the container
			var container = $('.invoice-list:not(.invoice-list.invoice-total) li div.wrapper');
			if (!$(e.target).closest(container).length) {
				container.removeClass('swipe-left');
				e.stopImmediatePropagation();
			}
		});
	}

	constructor() { }
}
