
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { RptOutstandingpoService } from 'src/app/core/Services/reports/Purchase/Purchase/rpt-outstandingpo.service';
import { RptminusstockService } from 'src/app/core/Services/reports/Stock/Rptminusstock/rpt-minusstock.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-outstandingpurchaseorderreport',
  templateUrl: './outstandingpurchaseorderreport.component.html',
  styleUrls: ['./outstandingpurchaseorderreport.component.css']
})
export class OutstandingpurchaseorderreportComponent implements OnInit {
  _IsProgressSpinner: boolean = false;
  _POoutstandingform: FormGroup
  _invoicetype: any
  mindate: Date;
  maxdate: Date;
  _branches: any[] = []
  branchid: any[] = []
  currencyid: any[] = []
  _suppliers: any[] = []
  supplierid: any[] = []
  brandid: any[] = []
  _producttypeid: any[] = []
  _outstandingpurchasereports: any[] = []
  _outstandingpurchasereport: any[] = []
  _inwardwisewise: boolean = false
  _grnwisewise: boolean = false
  customertype: boolean = false
  _Outstandingpurchases: any[] = []
  _Outstandinggrnpurchases: any[] = []
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  _Brands: any[] = []
  _Currencies: any[] = []
  _ProductTypeObj: any;
  _ProductObj: any;
  _brandlist: any;
  filteredProducts: any[];
  _ProductCategoryObj: any;
  _inwardcount: boolean = true;
  _grncount: boolean = true;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private exportUtility: ExportUtility,
    private _OutdstandingPurchaseOrderService: RptOutstandingpoService,
    private _RptminusstockService: RptminusstockService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,

  ) { }
  InitializeForm() {
    this._POoutstandingform = this.fb.group({
      fromdate: [],
      todate: [],
    })
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._POoutstandingform.get("fromdate").setValue(new Date);
      this._POoutstandingform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._POoutstandingform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._POoutstandingform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;
    this._OutdstandingPurchaseOrderService.PageOnload({ "userid": sessionStorage["userid"] }).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._suppliers = resultJSON.supplierList;
      this._branches = resultJSON.branchList;
      this._producttypelist = resultJSON.producttypeList;
      this._Brands = resultJSON.brandList;
      this._Currencies = resultJSON.currencyList;
      this._IsProgressSpinner = false;
      // this.getinwardtype();
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GenerateReport() {

    var _fromdate = this._POoutstandingform.get("fromdate").value;
    var _todate = this._POoutstandingform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than To Date");
      return;
    }
    let data = {
      "Effectivefrom": fromdate,
      "Effectiveto": todate,
      "Branchid": this.branchid || [],
      "SupplierId": this.supplierid || [],
      "Userid": sessionStorage["userid"],
    }

    this._IsProgressSpinner = true;
    this._OutdstandingPurchaseOrderService.GetoutstandingPO(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._outstandingpurchasereports = [];
      this._outstandingpurchasereports = resultJSON.outstandingpurchaseorderlists;
      this._outstandingpurchasereport = resultJSON.outstandingpurchaseorderlists;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  clear() {
    this._POoutstandingform.controls['fromdate'].setValue(new Date());
    this._POoutstandingform.controls['todate'].setValue(new Date());
    this.branchid = [];
    this.supplierid = [];
    // this.brandid = [];
  }

  GetOutStandingPODetails(_outstandingpurchasereports: any) {
    this._IsProgressSpinner = true;
    let data = {
      "purchaseorderno": _outstandingpurchasereports.PURCHASEORDERNO,
    }
    let Inward_data = _outstandingpurchasereports.INWARDQUANTITY
    if (Inward_data == 0) {
      this._inwardwisewise = false
      this._IsProgressSpinner = false;
    }
    else {
      this._OutdstandingPurchaseOrderService.GetOutStandingPODetails(data).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this._inwardwisewise = true

        this._Outstandingpurchases = resultJSON.outstandingpurchaseorderdetaillists;

        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  exportExcel() {
    let branchwisestocklists = this.GetCustomerList();
    this.exportUtility.exportExcel(branchwisestocklists, 'Outstanding_PurchseOrder_Report', 'xlsx');
  }

  ExportToPdf() {
    let branchwisestocklists = this.GetCustomerList();
    const data = branchwisestocklists.map(Object.values);
    const head = [[
      'Purchase Order No',
      'Purchase Order Date',
      'Branch Name',
      'Supplier Name',
      'Purchase Order Quantity',
      'Pending Inward Qty',
      'Pending GRN Qty'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          }, headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          }, bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Outstanding_Purchase_Order_Report.pdf');
      })
    })
  }

  GetCustomerList() {
    let branchwisestocklists = [];
    this._outstandingpurchasereports.forEach(element => {
      let branchwisestock: any = {};
      branchwisestock["Purchase Order No"] = element.PURCHASEORDERNO;
      branchwisestock["Purchase Order Date"] = element.PURCHASEORDERDATE;
      branchwisestock["Branch Name"] = element.BRANCHNAME;
      branchwisestock["Supplier Name"] = element.SUPPLIERNAME;
      branchwisestock["Purchase Order Quantity"] = element.POQUANTITY || 0;
      branchwisestock["Pending Inward Qty"] = element.INWARDQUANTITY || 0;
      branchwisestock["Pending GRN Qty"] = element.GRNQUANTITY || 0;
      branchwisestocklists.push(branchwisestock);
    });
    return branchwisestocklists;
  }

  getProductCategory(event) {
    var selectedProductType = event.value;

    this._IsProgressSpinner = true;
    this._ProductTypeObj = {
      Producttype: selectedProductType || [],
      branchid: parseInt(sessionStorage['currentbranchid']),
    };
    this._RptminusstockService
      .getProductCategory(this._ProductTypeObj)
      .subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._productcategorylist = resultJSON.productcategories;
          this._IsProgressSpinner = false;
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
        }
      );

  }
  ChangeProduct(event) {
    var selectedProduct = event.value;

    this._IsProgressSpinner = true;
    this._ProductObj = {
      Products: selectedProduct || [],
      branchid: parseInt(sessionStorage['currentbranchid']),
    };
    this._RptminusstockService.getBrand(this._ProductObj).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        let productbrand = resultJSON.productbrandlist;
        let uniqueChars = [...new Set(productbrand)];
        uniqueChars = productbrand.filter(
          (test, index, array) =>
            index ===
            array.findIndex((findTest) => findTest.brandid === test.brandid)
        );
        this._brandlist = uniqueChars;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );

  }
  filterProducts(event) {
    let filtered: any[] = [];
    let query = (<string>event.query).toLowerCase();
    this.filteredProducts =
      this._productlist.filter(
        (f) => f.searchfilter.toLowerCase().indexOf(query) > -1
      ) || [];
  }
  getProduct(event) {
    var selectedProductCategory = event.value;
    this.productid = [];

    this._ProductCategoryObj = {
      Productcategory: selectedProductCategory || [],
      branchid: parseInt(sessionStorage['currentbranchid']),
    };
    this._RptminusstockService
      .getProduct(this._ProductCategoryObj)
      .subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
      });

  }

  GetOutStandingPOGrnDetails(_outstandingpurchasereports: any) {
    this._IsProgressSpinner = true;
    let data = {
      "purchaseorderno": _outstandingpurchasereports.PURCHASEORDERNO,
    }
    let grn_data = _outstandingpurchasereports.GRNQUANTITY
    if (grn_data == 0) {
      this._grnwisewise = false
      this._IsProgressSpinner = false;
    }
    else {
      this._OutdstandingPurchaseOrderService.GetOutStandingPODetails(data).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this._grnwisewise = true

        this._Outstandinggrnpurchases = resultJSON.outstandingpurchaseorderdetaillists;

        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }

  }

  exportInwardExcel() {
    let branchwisestocklists = this.GetInwardList();
    this.exportUtility.exportExcel(branchwisestocklists, 'Outstanding_PO_pendingInward_Report', 'xlsx');
  }

  InwardExportToPdf() {
    let branchwisestocklists = this.GetInwardList();
    const data = branchwisestocklists.map(Object.values);
    const head = [[
      'Purchase Order No',
      'Purchase Order Date',
      'Product Name',
      'Purchase Order Qty',
      'Inward Pending Qty',
      'Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          }, headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          }, bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Outstanding_PO_pendingInward_Report.pdf');
      })
    })
  }

  GetInwardList() {
    let branchwisestocklists = [];
    this._Outstandingpurchases.forEach(element => {
      let branchwisestock: any = {};
      branchwisestock["Purchase Order No"] = element.PURCHASEORDERNO;
      branchwisestock["Purchase Order Date"] = element.PURCHASEORDERDATE;
      branchwisestock["Product Name"] = element.PRODUCTNAME;
      branchwisestock["Purchase Order Qty"] = element.POQUANTITY;
      branchwisestock["Inward Pending Qty"] = element.INWARDBALANCEQUANTITY || 0;
      branchwisestock["Status"] = element.INWARDSTATUS;
      branchwisestocklists.push(branchwisestock);
    });
    return branchwisestocklists;
  }

  exportGRNExcel() {
    let branchwisestocklists = this.GetGRNList();
    this.exportUtility.exportExcel(branchwisestocklists, 'Outstanding_PO_GRN_Report', 'xlsx');
  }

  GRNExportToPdf() {
    let branchwisestocklists = this.GetGRNList();
    const data = branchwisestocklists.map(Object.values);
    const head = [[
      'Purchase Order No',
      'Purchase Order Date',
      'Product Name',
      'Purchase Order Qty',
      'GRN Pending Qty',
      'Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          }, headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          }, bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Outstanding_PO_GRN_Report.pdf');
      })
    })
  }

  GetGRNList() {
    let branchwisestocklists = [];
    this._Outstandinggrnpurchases.forEach(element => {
      let branchwisestock: any = {};
      branchwisestock["Purchase Order No"] = element.PURCHASEORDERNO;
      branchwisestock["Purchase Order Date"] = element.PURCHASEORDERDATE;
      branchwisestock["Product Name"] = element.PRODUCTNAME;
      branchwisestock["Purchase Order Qty"] = element.POQUANTITY;
      branchwisestock["GRN Pending Qty"] = element.GRNBALANCEQUANTITY || 0;
      branchwisestock["Status"] = element.GRNSTATUS;
      branchwisestocklists.push(branchwisestock);
    });
    return branchwisestocklists;
  }
}
