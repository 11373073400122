import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { AccountledgerService } from 'src/app/core/services/accounts/accountledger.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Accountgroup } from 'src/app/core/Models/Accountgroup';

import { vAccountName, vSelectAccountgroup, vSelectSeries } from 'src/app/core/Validators/validation';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
  selector: 'app-c-account',
  templateUrl: './c-account.component.html',
  styleUrls: ['./c-account.component.css']
})
export class CAccountComponent implements OnInit {
  _isSaveHide: boolean = false;
  _isClearHide: boolean = false;
  _Accountledgerform: FormGroup;
  _Accountledger: Accountledger;
  _parentgroups: Accountgroup;
  _series: Metadatum[] = [];
  _Accountledgerid: number;
  _action: string = "create";
  _submitted = false;
  isHideSeries: Boolean = false;
  _IsProgressSpinner: boolean = true;
  _Action: IAction;
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _route: ActivatedRoute,
    private _AccountledgerService: AccountledgerService, private _router: Router, private utility: CommonUtilities,
    private _hotkeysService: HotkeysService,  private _AccessRightsService: AccessRightsService) {

  }


  Initialize() {
    this._Accountledgerform = this.fb.group({
      ledgerid: [0],
      name: ['', vAccountName],
      alias: [],
      accountgroupid: ['', vSelectAccountgroup],
      openingbalance: [0],
      currentbalance: [0],
      series: [93, vSelectSeries],
      partycode: [],
      partytype: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      isactive: [1],
      issysgenerated: [0],
      closingbalance: [0],
      debitamount: [0],
      creditamount: [0],
      actualName: [],
      openingbalancecredit: [0],
      closingbalancecredit: [0],
      currentbalancecredit: [0],
    });
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(62);
    this.utility.pageLoadScript();
    this._Accountledgerid = history.state?.ledgerid ? history.state?.ledgerid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;

    this._isSaveHide = (this._action == 'view');
    this._isClearHide = (this._action == 'view' || this._action == 'edit');

    this.Initialize();
    this.HotKeyIntegration();
    if (this._action == 'view') {
      this._Accountledgerform.disable();
    }
    this.isHideSeries = (sessionStorage["Series"] == "MSC00093");

    this._IsProgressSpinner = true;
    this._AccountledgerService.PageOnLoad(this._Accountledgerid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._series = resultJSON.series;
      this._parentgroups = resultJSON.accountgroups;
      this._IsProgressSpinner = false;
      console.log(resultJSON);

      // throw this._CustomExceptionService.handleError("Please  fill customer form properly");
      console.log(result);
      //For Update

      console.log(this._action);
      if (this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        console.log(updateJSON.accountgroupledger);
        this._Accountledgerform.setValue(updateJSON.accountledger);
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onSave() {

    this._submitted = true;
    if (this._Accountledgerform.valid) {
      this._Accountledger = this._Accountledgerform.value;

      if (this._action == 'create') {
        console.log("IN");
        this._Accountledger.createdon = new Date();
        this._Accountledger.createdby = sessionStorage["userid"];
        this._Accountledger.actualName = this._Accountledger.name;
        // this.onEd();
        this._IsProgressSpinner = true;
        this._AccountledgerService.create(this._Accountledger).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null)
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
          }
        }, error => {
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
        });
      }
    }
  }
  reset(event) {
    this._Accountledgerform.reset();
    this.Initialize();
    this._submitted = false;
  }
  goBack(event) {
    console.log("goBack");
    this._router.navigate(['/vAccount']);
  }

  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}
