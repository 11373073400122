<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Trip Start</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple icon="pi pi-plus" title="Add"
                                class="p-button p-button-success p-mr-2" [disabled]="!_Action._Add"
                                (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                            (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                            pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_TripStartviews" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [globalFilterFields]="['tripstartclosureno','vehicleno','drivername','referenceno','odometerreading','averagekm','assistancename','tripstartdate','tripenddate','vehiclestatusname']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="salesquotationno"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">

                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" pSortableColumn="Action">Action<p-sortIcon field="name">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="vehicleno">Trip No<p-sortIcon field="vehicleno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="vehicleno">Vehicle No<p-sortIcon field="vehicleno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="drivername">Driver Name<p-sortIcon field="drivername"></p-sortIcon>
                                </th>
                                <th pSortableColumn="assistancename">Assistant Name<p-sortIcon field="assistancename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="referenceno">Reference No<p-sortIcon field="referenceno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="odometerreading">Odometer Reading<p-sortIcon
                                        field="odometerreading"></p-sortIcon>
                                </th>
                                <th pSortableColumn="averagekm">Average KM<p-sortIcon field="averagekm"></p-sortIcon>
                                </th>
                                <th pSortableColumn="tripstartdate">Trip Start Date Time<p-sortIcon
                                        field="tripstartdate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="tripenddate">Trip End Date Time<p-sortIcon field="tripenddate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tripstartclosurename">Trip Status<p-sortIcon
                                        field="tripstartclosurename"></p-sortIcon>
                                </th>
                                <th pSortableColumn="vehiclestatusname">Vehicle Status<p-sortIcon
                                        field="vehiclestatusname"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_TripStartview>
                            <tr>

                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="!_Action._View" (click)="view(_TripStartview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit"
                                        class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update"
                                        [disabled]="_TripStartview.tripstartclosurestatus == 'MSC00204'"
                                        (click)="edit(_TripStartview)"></button>
                                    <!-- <button pButton pRipple icon="pi pi-trash" title="Delete"
                                        class="p-button p-button-danger" [disabled]="!_Action._Delete"
                                        (click)="delete(_TripStartview)"></button>  tripstartclosurename -->
                                </td>
                                <td>
                                    {{_TripStartview.tripstartclosureno}}
                                </td>
                                <td>
                                    {{_TripStartview.vehicleno}}
                                </td>
                                <td>
                                    {{_TripStartview.drivername}}
                                </td>
                                <td>
                                    {{_TripStartview.assistancename}}
                                </td>
                                <!-- <td>
                                    {{_TripStartview.referenceno}}
                                </td> -->
                                <td class="text-center" style="width: 100px;">
                                    <a href="javascript:void(0);" (click)="Getdeliverydetails(_TripStartview)">
                                        <button class="p-inputgroup-addon p-button" title="Delivery details"
                                            type="button">
                                            <i class="las la-external-link-alt"></i>
                                        </button>
                                    </a>
                                </td>
                                <td>
                                    {{_TripStartview.odometerreading}}
                                </td>
                                <td>
                                    {{_TripStartview.averagekm }}
                                </td>
                                <td>
                                    {{_TripStartview.tripstartdate|date:'dd/MM/yyyy hh:mm a' }}
                                </td>
                                <td>
                                    {{_TripStartview.tripenddate|date:'dd/MM/yyyy hh:mm a' }}
                                </td>
                                <td>
                                    {{_TripStartview.tripstartclosurename }}
                                </td>
                                <td>
                                    {{_TripStartview.vehiclestatusname }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
            <p-dialog header="Stock Delivery Details" [(visible)]="stockdeliverydetail" [modal]="true"
                [style]="{width: '80vw',height: '500px'}" [baseZIndex]="10000">
                <p-table #dt [value]="_stockdeliverydetail" [scrollable]="true" scrollHeight="450px" [rows]="50"
                    [paginator]="true" [rowsPerPageOptions]="[10,50,100,200]" [columns]="cols"
                    [globalFilterFields]="['invoicetype','deliveryno','evidenceno', 'evidencedate','customerorbranchname']"
                    [(selection)]="selectedrows" [rowHover]="true" dataKey="tripno"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [showCurrentPageReport]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="SNO" style="width: 60px;">S.No<p-sortIcon field="SNO">
                                </p-sortIcon>
                            </th>
                            <th pSortableColumn="invoicetype">Invoice Type<p-sortIcon field="invoicetype"></p-sortIcon>
                            </th>
                            <th pSortableColumn="deliveryno">Stock Delivery No<p-sortIcon field="deliveryno">
                                </p-sortIcon>
                            </th>
                            <th pSortableColumn="evidenceno">Evidence No<p-sortIcon field="evidenceno"></p-sortIcon>
                            </th>
                            <th pSortableColumn="evidencedate">Evidence Date<p-sortIcon field="evidencedate">
                                </p-sortIcon>
                            </th>
                            <th pSortableColumn="customerorbranchname">Customer/Branch Name<p-sortIcon
                                    field="customerorbranchname"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-_TripStartview let-i="rowIndex">
                        <tr>
                            <td style="width: 60px;">
                                {{i+1}}
                            </td>
                            <td>
                                {{_TripStartview.invoicetype}}
                            </td>
                            <td>
                                {{_TripStartview.deliveryno}}
                            </td>
                            <td>
                                {{_TripStartview.evidenceno}}
                            </td>
                            <td>
                                {{_TripStartview.evidencedate | date:'dd-MMM-yyyy'}}
                            </td>
                            <td>
                                {{_TripStartview.customerorbranchname}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-dialog>
        </div>
    </div>
</div>