export class Documenttypeworkflowstageview {
    documenttypeworkflowstageid: number;
    companyid: number ;
    branchid: number ;
    branchname: string ;
    documenttypeid: number;
    documenttypename: string;
    workflowid: number;
    workflowname: string;
    stageid: number;
    stagename: string;
    roleid: number;
    rolename: string;
    isbranchdependent: boolean;
    branchdependentname: string;
    primaryapprover: number ;
    primaryapprovername: string;
    secondaryapprover: number;
    secondaryapprovername: string;
    stageaction: string;
    stageactionname: string;
    approvedstatus: string;
    rejectedstatus: string;
    stagestatus: string;
    status: string;
    stagestatusname: string;
    createdby: number;
    createdon: Date;
    modifiedby: number ;
    modifiedon: Date ;
}