<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product Price</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_productpriceform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="customertype" name="id"
                                        formControlName="custometypeid" optionLabel="name" optionValue="id"
                                        (onChange)="customertypeid()">
                                    </p-dropdown>
                                    <label for="name"> Customer Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _productpriceform.get('custometypeid').touched) && _productpriceform.get('custometypeid').errors?.SelectCurrency">
                                    Please Select Customer Type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Customer"
                                        optionValue="customerid" optionLabel="customername" formControlName="customerid"
                                        [filter]="true" [virtualScroll]="true" itemSize="30"
                                        [filter]="true" (onChange)="Customer()" filterBy="customername">
                                    </p-dropdown>
                                    <label for="customername">Customer Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _productpriceform.get('customerid').touched) && _productpriceform.get('customerid').errors?.SelectCurrency">
                                    Please Select Customer Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_markuplevels"
                                        optionValue="levelid" optionLabel="levelname" formControlName="levelid"
                                        [filter]="true" [virtualScroll]="true" itemSize="30"
                                        filterBy="levelname">
                                    </p-dropdown>
                                    <label for="customername">Level Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _productpriceform.get('levelid').touched) && _productpriceform.get('levelid').errors?.SelectLevelName">
                                    Please Select Level Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_currencylist"
                                        optionValue="currencyid" optionLabel="currencyname" formControlName="currencyid"
                                        [filter]="true" [virtualScroll]="true" itemSize="30" filterBy="currencyname">
                                    </p-dropdown>
                                    <label for="customername">Currency Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _productpriceform.get('currencyid').touched) && _productpriceform.get('currencyid').errors?.SelectCurrency">
                                    Please Select Currency Name
                                </span>
                            </td>
                            <!-- <td style="width: 20%;"></td> -->
                            <td style="width: 20%;"></td>

                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">

                                    <p-multiSelect [options]="_producttype" optionLabel="producttypename" 
                                        optionValue="producttypeid" [filter]="true" [virtualScroll]="true" itemSize="30"
                                        [(ngModel)]="_selectedproducttypename" (onChange)="getProductcategory($event)"
                                        [ngModelOptions]="{standalone: true}" displaySelectedLabel="true">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type </label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _productpriceform.get('producttypeid').touched) && _productpriceform.get('producttypeid').errors?.SelectProductType">
                                    Please Select Atleast One Product Type
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productcategory"
                                        formControlName="productcategoryid" optionLabel="categoryname"
                                        [filter]="true" [virtualScroll]="true" itemSize="30"
                                        optionValue="productcategoryid" (onChange)="getProductname($event)"
                                        displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category </label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _productpriceform.get('productcategoryid').touched) && _productpriceform.get('productcategoryid').errors?.SelectProductCategory">
                                    Please Select Atleast One Product Category
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_productname" formControlName="productid" (onChange)="ChangeProduct($event)"
                                    [filter]="true" [virtualScroll]="true" itemSize="30" optionLabel="productname" optionValue="productid" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_submitted || _productpriceform.get('productid').touched) && _productpriceform.get('productid').errors?.SelectProductName">
                                    Please Select Atleast One Product Name
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_brandlist" optionLabel="brandname"
                                    [filter]="true" [virtualScroll]="true" itemSize="30" optionValue="brandid"   formControlName="brandid" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="brandid">Brand Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="vattype" name="id"
                                        formControlName="vattype" optionLabel="name" optionValue="id">
                                    </p-dropdown>
                                    <label for="name"> VAT Type <span class="hlt-txt">*</span></label>
                                </span>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport()"
                                    class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()"
                                    class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <iframe #iframe height="500" width="100%" [src]="_src"></iframe>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>

        </div>
    </div>

</div>
<p-toast position="bottom-right"></p-toast>