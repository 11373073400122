import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApprovalbranchService {
  public _Action: IAction;
  public _ApprovalbranchService = environment.ApprovalbranchService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(branchid: number): Observable<any> { 
    const headers = this.header;
    var Query = this._ApprovalbranchService + '/' + branchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll(branchid: number) { 
    const headers = this.header;
    var Query = this._ApprovalbranchService + '/FetchAll' + '/' + branchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  create(Approvalbranch: object[]) { 
    const headers = this.header;
    console.log("Create");
    var Query = this._ApprovalbranchService;
    return this.http.post<{ data: any }>(Query, Approvalbranch, { headers });
  }
  edit(Approvalbranch: object) { 
    const headers = this.header;
    console.log("Update");
    var Query = this._ApprovalbranchService;

    return this.http.put<{ data: any }>(Query, Approvalbranch, { headers });
  }
  Cancel(workflowid: number, userid: number, branchid: number) { 
    const headers = this.header;
    var Query = this._ApprovalbranchService + '/Cancel' + '/' + workflowid + '/' + userid + '/' + branchid;

    return this.http.get<{ data: any }>(Query, { headers });
  }
}
