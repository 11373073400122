<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Stock Missing Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_StockMissingform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate"
                                        (onSelect)="onchangefromdate()">
                                    </p-calendar>
                                    <label for="fromdate">From Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy"
                                        [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="todate">To Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_inwardtype"
                                        formControlName="inwardtype" optionLabel="metasubdescription"
                                        (onChange)="getSupplierNameorBranchName($event)" optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="gender">Inward Type </label>
                                </span>
                                <span class="text-danger" *ngIf="(_StockMissingform.get('inwardtype').touched || _submitted) && _StockMissingform.get('inwardtype').errors?.SelectInwardType">
                                    Please Select Inward Type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_tobranch" name="tobranch"
                                        optionLabel="suppliername" optionValue="supplierid" [showClear]="true"
                                        (onChange)="getstocktransferno($event)" formControlName="supplieridorbranchid"
                                        [filter]="true" filterBy="suppliername" [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatus == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.suppliername}}
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <label for="deliverytype">Supplier / Branch <span class="hlt-txt"></span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_stocktransfer"
                                        optionLabel="purchaseorderno" formControlName="stocktransferno"
                                        optionValue="purchaseorderno" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="producttypeid">Reference No </label>
                                </span>
                            </td>
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_producttype"
                                        optionLabel="producttypename" formControlName="producttypeid"
                                        optionValue="producttypeid" (onChange)="getProductcategory($event)"
                                        displaySelectedLabel=true [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type </label>
                                </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_productcategory"
                                        formControlName="productcategoryid" optionLabel="categoryname"
                                        optionValue="productcategoryid" (onChange)="getProductname($event)"
                                        displaySelectedLabel=true [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category </label>
                                </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                    <p-multiSelect [filter]="true" [options]="_productname" formControlName="productid"
                                        optionLabel="productname" optionValue="productid"
                                        (onChange)="ChangeProduct($event)" displaySelectedLabel=true
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>
                                </td>
                                <td style="width: 20%;">

                                    <span class="p-float-label">
                                    <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                                        [(ngModel)]="brandid" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="brandid">Brand Name </label>
                                </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                    <p-multiSelect [filter]="false" [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchids" 
                                        [ngModelOptions]="{standalone: true}" displaySelectedLabel="true"
                                        [filter]="true" filterBy="branchname" [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatus == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label>Receiver Branch </label>
                                </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" type="button" (click)="search()" title="Search" icon="pi pi-search"></button>
                                    <button pButton pRipple label="Clear" title="Clear" (click)="clear()" icon="pi pi-times" class="p-button-danger"></button>
                                </td>
                            </tr>

                    </table>
                </form>

                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>

                        </ng-template>
                        <ng-template pTemplate="right">

                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>

                    </p-toolbar>


                    <p-table #dt [value]="_StockMissingviews" [scrollable]="true" scrollHeight="450px" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['stocktransferno','frombranchname','tobranchname','productname','requestquantity','deliveryquantity',
                        'balancedeliveryquantity','inwardquantity','inwardmissingquantity','grnno','grnquantity','grnmissingquantity','salesuomname']" [(selection)]="selectedrows" [rowHover]="true" dataKey="productname" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="stocktransferno">Reference No
                                    <p-sortIcon field="stocktransferno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="date">Ref Date
                                    <p-sortIcon field="date">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="frombranchname">Supplier
                                    <p-sortIcon field="frombranchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="tobranchname">Receiver
                                    <p-sortIcon field="tobranchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="brandname">Brand Name
                                    <p-sortIcon field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="requestquantity">PO / STO Qty
                                    <p-sortIcon field="requestquantity">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="deliveryquantity"> Delivery Qty
                                    <p-sortIcon field="deliveryquantity">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="balancedeliveryquantity">Balance Delivery Qty
                                    <p-sortIcon field="balancedeliveryquantity">
                                    </p-sortIcon>
                                    <th pSortableColumn="inwardquantity">Inward Qty
                                        <p-sortIcon field="inwardquantity">
                                        </p-sortIcon>

                                        <th pSortableColumn="inwardmissingquantity">Inward Missing Qty
                                            <p-sortIcon field="inwardmissingquantity">
                                            </p-sortIcon>
                                            <!-- <th pSortableColumn="grnno">GRN No<p-sortIcon field="grnno">
                                    </p-sortIcon> -->
                                            <th pSortableColumn="grnquantity">GRN Qty
                                                <p-sortIcon field="grnquantity">
                                                </p-sortIcon>
                                                <th pSortableColumn="grnmissingquantity">GRN Missing Qty
                                                    <p-sortIcon field="grnmissingquantity">
                                                    </p-sortIcon>
                                                    <th pSortableColumn="salesuomname">UOM
                                                        <p-sortIcon field="salesuomname">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="status">Status
                                                        <p-sortIcon field="status">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th>Remove Missing
                                                    </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_stockmissingview let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 60px;">
                                    {{i+ 1}}
                                </td>
                                <td>
                                    {{_stockmissingview.stocktransferno}}
                                </td>
                                <td>
                                    {{_stockmissingview.date | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_stockmissingview.frombranchname}}
                                </td>
                                <td>
                                    {{_stockmissingview.tobranchname}}
                                </td>
                                <td>
                                    {{_stockmissingview.productname}}
                                </td>
                                <td>
                                    {{_stockmissingview.brandname}}
                                </td>

                                <td>
                                    {{_stockmissingview.requestquantity}}
                                </td>
                                <td>
                                    {{_stockmissingview.deliveryquantity}}
                                </td>
                                <td>
                                    {{_stockmissingview.balancedeliveryquantity}}
                                </td>
                                <td>
                                    {{_stockmissingview.inwardquantity}}
                                </td>
                                <td>
                                    {{_stockmissingview.inwardmissingquantity}}
                                </td>
                                <!-- <td>
                                    {{_stockmissingview.grnno}}
                                </td> -->
                                <td>
                                    {{_stockmissingview.grnquantity}}
                                </td>
                                <td>
                                    {{_stockmissingview.grnmissingquantity}}
                                </td>
                                <td>
                                    {{_stockmissingview.salesuomname}}
                                </td>
                                <td>
                                    {{_stockmissingview.status}}
                                </td>
                                <!-- <td class="text-center">
                                    <button pButton pRipple title="Update Stock" icon="las la-check" 
                                    [disabled]="_stockmissingview.grnmissingquantity==0 || _stockmissingview.grnno==0|| _stockmissingview.grnquantity==0"
                                    (click)="stockupdate(_stockmissingview)">
                                </button>
                                </td> -->
                                <td class="text-center">
                                    <button pButton pRipple label="Remove" title="Remove Missing" class="p-button-danger" [hidden]="_stockmissingview.inwardttype=='sto' || _stockmissingview.inwardttype=='sa' || (_stockmissingview.userid!=1 && _stockmissingview.userid!=500 && _stockmissingview.userid!=499 )"
                                        (click)="postatusupdate(_stockmissingview)"></button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
        <p-dialog #warning [style]="{width: '500px'}" header="Confirm" [(visible)]="Stockmodel.showdialog" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
            <ng-template pTemplate="content">
                <label [innerHTML]="Stockmodel.message"></label>
            </ng-template>
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="las la-check" label="Yes" autofocus (click)="Yes()"></button>
                <button type="button" pButton icon="pi pi-times" label="No" autofocus (click)="Cancel()"></button>
            </ng-template>
        </p-dialog>
        <p-dialog #warning [style]="{width: '500px'}" header="Confirm" [(visible)]="Stockmissing.showdialog" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
            <ng-template pTemplate="content">
                <label [innerHTML]="Stockmissing.message"></label>
            </ng-template>
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="las la-check" label="Yes" autofocus (click)="Yes1()"></button>
                <button type="button" pButton icon="pi pi-times" label="No" autofocus (click)="Cancel1()"></button>
            </ng-template>
        </p-dialog>


        <p-confirmDialog [style]="{width: '750px'}"></p-confirmDialog>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>