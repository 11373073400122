import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class ServicerequestService {
  public _Action: IAction;
  public _ServicerequestService = environment.ServicerequestService;
  public _httpOptions;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)

  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }
  PageOnLoad(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ServicerequestService + '/PageOnload';

    return this.http.post(Query, obj, { headers });
  }
  GetProductOrCustomer(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ServicerequestService + '/GetProductOrCustomer';

    return this.http.post(Query, obj, { headers });
  }
  GetRefNumber(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ServicerequestService + '/GetRefNumber';

    return this.http.post(Query, obj, { headers });
  }
  GetAssetBasedDetail(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ServicerequestService + '/GetAssetBasedDetail';

    return this.http.post(Query, obj, { headers });
  }
  GetCustomerBasedDetail(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ServicerequestService + '/GetCustomerBasedDetail';

    return this.http.post(Query, obj, { headers });
  }
  create(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ServicerequestService + '/Create';

    return this.http.post(Query, obj, { headers });
  }
  edit(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ServicerequestService;

    return this.http.post(Query, obj, { headers });
  }
  SendToApproval(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ServicerequestService + '/SendToApproval';

    return this.http.post(Query, obj, { headers });
  }
  FetchAll(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ServicerequestService + '/FetchAll';

    return this.http.post(Query, obj, { headers });
  }
  Cancel(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ServicerequestService + '/Delete';

    return this.http.post(Query, obj, { headers });
  }
  Print(obj: any) {
    const headers = this.header;
    var Query = this._ServicerequestService + '/Print';
    return this.http.post(Query, obj, { responseType: 'blob', headers });
  }
  GetProduct(data) {
    const headers = this.header;
		var Query = this._ServicerequestService + '/GetProduct';
		return this.http.post<{ data: any }>(Query, data, { headers });
	}

  getproductdetails(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._ServicerequestService + '/getproductdetails';
    return this.http.post(Query, obj, { headers });
  }
  
}
