import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { QuotefinalizeService } from 'src/app/core/Services/Purchase/quotefinalize.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Quotefinlizedview } from 'src/app/core/Views/Quotefinalizeview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-v-quotefinalize',
  templateUrl: './v-quotefinalize.component.html',
  styleUrls: ['./v-quotefinalize.component.css']
})
export class VQuotefinalizeComponent implements OnInit {
  // List Declaration:
  _quotefinalizedetails: Quotefinlizedview[] = [];
  _quotefinalizedetail: Quotefinlizedview;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction
  _userid=sessionStorage["userid"]
  _branchid=sessionStorage["currentbranchid"]
  _roleid=sessionStorage["currentRole"]
  _IsProgressSpinner: boolean = true;
  _supplierquotesid : number;
  cols:any[];
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  mindate: Date;
  maxdate: Date;
  constructor(private fb: FormBuilder, private _QuotefinalizeService: QuotefinalizeService, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService, private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,private exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(39);
    this.HotKeyIntegration();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.utility.pageLoadScript();
    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.cols = [
      { field: 'supplierquotesno', header: 'Quote Finalize ID' },
      { field: 'quotefinalizeddate', header: 'Quote Finalize Date' },
      { field: 'suppliername', header: 'Supplier Name' },
      { field: 'productname', header: 'Product Name' },
      { field: 'statusname', header: 'Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]

    
   this.Fetchall();

  }
  Fetchall(){
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;
    this._QuotefinalizeService.FetchAll(this._userid,this._branchid,this._roleid,fromdate,todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._quotefinalizedetails = resultJSON.quotefinlizedviewList;
      this._IsProgressSpinner = false;
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  view(event) {
    this.router.navigate(['/cQuotefinalize'], { state: { productid: event.productid,supplierquotesno: event.supplierquotesno, action: 'view' } });
  }
  delete(event) {
    alert("delete");
  }
  edit(event) {
    // if (event.status == "MSC00045" || event.status == null) {
       this.router.navigate(['/cQuotefinalize'], { state: { productid: event.productid,supplierquotesno: event.supplierquotesno, action: 'edit' } });
    // }
    // else {
      //this._CustomExceptionService.handleWarning("Approved Record Should not Update");
    // }
  }
  confirm(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Quote Finalize <b>"' + event.supplierquotesno + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event)
  {
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');

    this._supplierquotesid=event.quoterequestid;
    this._IsProgressSpinner = true;
    this._QuotefinalizeService.Cancel(this._supplierquotesid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      
      this._IsProgressSpinner = false;
      this.Fetchall();
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  exportExcel() {
    let finalizedList = this.GetFinalizedList();
    this.exportUtility.exportExcel(finalizedList, 'Quote Finalize', 'xlsx');
  }

  ExportToPdf() {
    let finalizedList = this.GetFinalizedList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Quote Finalize ID',
      'Quote Finalize Date',
      'Supplier Name',
      'Product Name',
      'Status',
      'Created By',
      'Created On'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('QuoteFinalize.pdf');
      })
    })
  }

  GetFinalizedList() {
    let finalizedList = [];
    this._quotefinalizedetails.forEach(element => {
      let quotes: any = {};
      quotes["Quote Finalize ID"] = element.supplierquotesno;
      quotes["Quote Finalize Date"] = element.quotefinalizeddate;
      quotes["Supplier Name"] = element.suppliername;
      quotes["Product Name"] = element.productname;
      quotes["Status"] = element.statusname;
      quotes["Created By"] = element.createdbyname;
      quotes["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      finalizedList.push(quotes);
    });
    return finalizedList;
  }



 
  GoToCreate(event) {
    this.router.navigate(['/cQuotefinalize']);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
}

