import { Injectable } from '@angular/core';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { IAction } from '../security/IActions';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class CreditnoteService {
  public _Action: IAction;
  public _CreditnoteService = environment.CreditnoteService;
  public _httpOptions;
  token: any;
	header: any;

  constructor(
    private http: HttpClient,
    private _CustomExceptionService: CustomExceptionService
  ) { 
    this.token = sessionStorage['AuthToken'];
		this.header = this.createHeaders(this.token)	

  }
  createHeaders(token: any): HttpHeaders {
		return new HttpHeaders({
		  'Content-Type': 'application/json',
		  Authorization: `Bearer ${token}`, // Include your token here	
		});
	  }
  PageOnLoad(userid:number,creditnoteno: string): Observable<any> {
    const headers = this.header;
    var Query = this._CreditnoteService + '/' + userid +'/' + creditnoteno;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  getTransactionDetails(salesinvoiceno:number)
  {
    const headers = this.header;
    var Query = this._CreditnoteService + '/getTransactionDetails/' + salesinvoiceno;
   
    return this.http.get<{data:any}>(Query, { headers });
  }
  create(creditnote: object) {
    const headers = this.header;
    console.log("Create");
    var Query = this._CreditnoteService;
    return this.http.post<{ data: any }>(Query, creditnote, { headers });
  }
  FetchAll(userid:number,branchid:number,roleid:number,fromdate: string, todate: string) {
    const headers = this.header;
    var Query = this._CreditnoteService + '/FetchAll/' + userid +'/' + branchid + '/'+roleid+'/' + fromdate + '/' + todate ;
   
    return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }
  Cancel(userid:number,creditnoteid: number){
    const headers = this.header;    
    console.log("Cancel")
    var Query = this._CreditnoteService + '/Cancel/' + userid +'/' + creditnoteid;
   
    return this.http.get<{ data: any }>(Query, { headers });
  }
  GetCustomerBasedEvidenceNo(ledgerid:number,userid:number)
  {
    const headers = this.header;
    var Query = this._CreditnoteService + '/GetCustomerBasedEvidenceNo/' + ledgerid + '/' + userid;
   
    return this.http.get<{data:any}>(Query, { headers });
  }

}

