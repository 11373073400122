import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { CmsService } from 'src/app/core/Services/ecommerce/cms.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CommonUtilities } from 'src/assets/js/common-utilities';


@Component({
  selector: 'app-v-cms',
  templateUrl: './v-cms.component.html',
  styleUrls: ['./v-cms.component.css']
})
export class VCmsComponent implements OnInit {

  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  cmsListGrid:any;
  _IsProgressSpinner:boolean=false;
  _cmsListGrid:any;
  _Action: IAction;
  

  constructor(private utility:CommonUtilities,private _CmsService: CmsService, private fb: FormBuilder, private _router: Router, private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(301);
    this.utility.pageLoadScript();
    this._CmsService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // console.log("fetch",resultJSON);
      this.cmsListGrid= resultJSON.cmsheaderlistviews
      // this._couponviews = resultJSON.couponviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    //33.on clicking view button, it show the view screen.
    this._router.navigate(['/cCms'], { state: { cmsid: event.cmsid, action: 'view' } });
  }
  edit(event) {
    //36.on clicking update button, it show the update screen.
    // if (event.status == "MSC00045" || event.status == null) {
      this._router.navigate(['/cCms'], { state: { cmsid: event.cmsid, action: 'edit' } });
    }
  GoToCreate(event) {
    this._router.navigate(['/cCms']);
  }
    
    // this.cmsListGrid=[
    //   {pageName:'About Us',pageURL:'about-us',pageContent:'About Page Content',status:'Active'},
    //   {pageName:'Contact Us',pageURL:'contact-us',pageContent:'Contact Us Page Content',status:'Active'},
    // ]
  }


