<p-toast></p-toast>
<div class="page-container">
  <div class="inner-page-container">
    <div class="white">
      <div class="page-title">
          <div class="row">
              <div class="col-md-5">
                  <h3>Order Pending Status</h3>
              </div>
              <div class="col-md-7 text-right">
              </div>
          </div>
      </div>
        <div class="form-container scroll-y">
            <table class="normal-table">
                <!-- <tr>
                    <td style="width:20%">
                        <span class="p-float-label">
                            <p-dropdown [options]="viewTransactionList" [(ngModel)]="viewTransaction" optionLabel="viewTransaction"></p-dropdown>
                            <label for="dropdown">Order Status By</label>
                        </span>
                    </td>
                    <td style="width:20%"></td>
                    <td style="width:20%"></td>
                    <td style="width:20%"></td>
                    <td style="width:20%"></td>
                </tr> -->
                <tr>
                    <td>
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <p-calendar dateFormat="dd/mm/yy" [(ngModel)]="fromdate" [showIcon]="true"></p-calendar>
                                <label for="inputtext">From Date</label>
                            </span>
                        </span>
                    </td>
                    <td>
                        <span class="p-float-label">
                            <p-calendar dateFormat="dd/mm/yy" [(ngModel)]="todate" [showIcon]="true"></p-calendar>
                            <label for="inputtext">To Date</label>
                        </span>
                    </td>
                    <td>
                        <!-- <span class="p-float-label">
                            <p-dropdown [options]="_tellernames" [autoDisplayFirst]="false"  [filter]="true"
                            [virtualScroll]="true" itemSize="30"  filterBy="username"   [(ngModel)]="Tellerid" optionValue="userid"
                                optionLabel="username"></p-dropdown>
                            <label for="dropdown">Teller Names</label>
                        </span> -->
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <button pButton pRipple label="Search" type="button" (click)="FetchALL()" title="Search"
                                icon="pi pi-search"></button>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </table>
            <div class="card">
                <p-toolbar styleClass="p-mb-4">
                    <ng-template pTemplate="left">
                        <span class="p-input-icon-left">
                            <i class="las la-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                        </span>
                    </ng-template>

                    <!-- <ng-template pTemplate="right">
                        <button type="button" pButton pRipple icon="las la-file-csv" title="Export CSV" class="p-mr-2" pTooltip="CSV"
                            tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="las la-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS"
                            tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="las la-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF"
                            tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="las la-filter" title="Filter" class="p-button-info p-ml-auto"
                            pTooltip="Selection Only" tooltipPosition="bottom"></button>
                    </ng-template> -->
                </p-toolbar>

                <p-table #dt [rows]="20" [value]="paymentPendingHistory" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]"
                    [globalFilterFields]="['orderid','orderno','salesinvoiceno','assignedto','assignedon']"
                    [rowHover]="true" dataKey="id"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [showCurrentPageReport]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Order ID</th>
                            <th>Order No</th>
                            <th>Invoice No</th>
                            <th>Assigned To</th>
                            <th>Assigned Date</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-_paymentPendingHistory>
                        <tr>
                            <td>
                                {{_paymentPendingHistory.orderid}}
                            </td>
                            <td>
                                {{_paymentPendingHistory.orderno}}
                            </td>
                            <td>
                                {{_paymentPendingHistory.salesinvoiceno}}
                            </td>
                            <td>
                                {{_paymentPendingHistory.assignedto}}
                            </td>
                            <td>
                                {{_paymentPendingHistory.assignedon|date:'dd/MM/yyyy'}}
                            </td>
                            <!-- <td>
                                {{_paymentPendingHistory.orderStatus}}
                            </td>
                            <td class="text-right">
                                {{_paymentPendingHistory.returnAmount}}
                            </td>
                            <td>
                                {{_paymentPendingHistory.returnOn}}
                            </td>
                            <td>
                                {{_paymentPendingHistory.returnStatus}}
                            </td> -->
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
  </div>
</div>