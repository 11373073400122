import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map,catchError} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class AccounttypeService {

  public _Action : IAction ;
  public _AccountTypeService = environment.AccountTypeService;
  public _httpOptions;
  constructor(private http:HttpClient) { }

  PageOnLoad() : Observable<any>{
    var Query = this._AccountTypeService + '/PageOnload' ;
   
    return this.http.get<{data:any}>(Query);
    //return this.http.get(Query);

}
FetchAll()
  {
    var Query = this._AccountTypeService + '/FetchAll' ;
   
    return this.http.get<{data:any}>(Query,this._httpOptions).pipe(map(data=>{
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
   
      // let _CityScreenAccess = resultJSON.userData.lstFunctions.find(funct=>funct.functionid=="15");
      // this._Action  = { 
      //   _functionId : 15,
      //   _functionName : "City",
      //   _Add :   _CityScreenAccess.iscreateallowed,
      //   _Delete : _CityScreenAccess.isdeleteallowed,
      //   _Update : _CityScreenAccess.iseditallowed,
      //   _View : _CityScreenAccess.isviewallowed,
      //   _Approve : _CityScreenAccess.isviewallowed
      // }   
     return data; 
    }),);
  }
  create(p_AccountType:object)
  {
    var Query = this._AccountTypeService  ;
    return this.http.post<{data:any}>(Query,p_AccountType);
  }
  view(p_AccountTypeCode : string)
  {
    var Query = this._AccountTypeService + '/' + p_AccountTypeCode;
   
    return this.http.get<{data:any}>(Query);
  }
  edit(p_AccountType : object)
  {
    var Query = this._AccountTypeService;
    return this.http.put<{data:any}>(Query,p_AccountType);
  }
 

}
