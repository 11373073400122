import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import { Supplier } from 'src/app/core/Models/Supplier';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { PurchaseordersummaryService } from 'src/app/core/Services/reports/Purchase/PurchaseOrderSummary/purchaseordersummary.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-purchaseordersummary',
  templateUrl: './purchaseordersummary.component.html',
  styleUrls: ['./purchaseordersummary.component.css']
})
export class PurchaseordersummaryComponent implements OnInit {
  _supplierlist: Supplier[];
  _branchlist: Branch[];
  _productlist: Productoverallview[];
  _currencylist: Currency[];
  _purchasesummaryform: FormGroup;
  _branchid: number;
  _userid: number;
  _src: SafeResourceUrl;
  _IsProgressSpinner: boolean = true;
  mindate: Date;
  maxdate: Date;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptPurchaseSummaryService: PurchaseordersummaryService,
    private _CustomExceptionService: CustomExceptionService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._purchasesummaryform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      productid: [0],
      currencyid: [0]
    })
    this._branchid = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._purchasesummaryform.get("fromdate").setValue(new Date);
      this._purchasesummaryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._purchasesummaryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._purchasesummaryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._IsProgressSpinner = true;
    this._RptPurchaseSummaryService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._supplierlist = resultJSON.supplierList;
      this._branchlist = resultJSON.branchList;
      this._productlist = resultJSON.productList;
      this._currencylist = resultJSON.currencyList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._purchasesummaryform.reset();
    var dt = new Date();
    dt.setDate(dt.getDate());
    this._purchasesummaryform.controls['fromdate'].setValue(dt);
    this._purchasesummaryform.controls['todate'].setValue(new Date());
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  GenerateReport(event) {
    var _fromdate = this._purchasesummaryform.get("fromdate").value;
    var _todate = this._purchasesummaryform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var supplierid = this._purchasesummaryform.get("supplierid").value;
    var productid = this._purchasesummaryform.get("productid").value;
    var currencyid = this._purchasesummaryform.get("currencyid").value;
    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    var branchid = this._purchasesummaryform.get("branchid").value == null ? 0 : this._purchasesummaryform.get("branchid").value;
    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this._RptPurchaseSummaryService.Print(branchid, this._userid, supplierid, productid, currencyid, fromdate, todate).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}