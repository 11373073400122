import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { DeliveryChallanService } from 'src/app/core/Services/ecommerce/delivery-challan.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-delivery-challan',
  templateUrl: './delivery-challan.component.html',
  styleUrls: ['./delivery-challan.component.css']
})
export class DeliveryChallanComponent implements OnInit {
  _rptdeliverychallanform: FormGroup;
  _OrderNOs: any[] = [];
  _OrderIDs: any[] = [];
  orderid: any[] = [];
  datepipe = new DatePipe('en-Us');
  _IsProgressSpinner: boolean = true;
  _src: SafeResourceUrl;
  _deliverychallanobj: any;
  _branchid = parseInt(sessionStorage["currentbranchid"]);
  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _Deliverychallanservice: DeliveryChallanService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private sanitizer: DomSanitizer) { }
  InitializeForm() {
    this._rptdeliverychallanform = this.fb.group({
      fromdate: [],
      todate: [],
      orderno: [],
    })
    this._rptdeliverychallanform.controls['fromdate'].setValue(new Date());
    this._rptdeliverychallanform.controls['todate'].setValue(new Date());
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    this.PageOnload();
  }
  PageOnload() {
    if (!this._rptdeliverychallanform.get("fromdate").value) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this._rptdeliverychallanform.get("todate").value) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this._rptdeliverychallanform.get("fromdate").value, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this._rptdeliverychallanform.get("todate").value, 'yyyy-MM-dd');
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }

    this._IsProgressSpinner = false;
    this._Deliverychallanservice.PageInitialize(this._branchid, fromdate, todate).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._OrderNOs = resultJSON.eordernumbers;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });


  }
  GetOrderId() {
    let orderno = this._rptdeliverychallanform.get("orderno").value;
    if (!this._rptdeliverychallanform.get("orderno").value) {
      this._CustomExceptionService.handleError("Please Select Order Number");
      return;
    }
    this._IsProgressSpinner = false;
    this._Deliverychallanservice.GetOrderid(orderno).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.orderid = [];
      this._OrderIDs = resultJSON.eorderids;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }

  GenerateReport1(event) {
    if (!this.orderid || !this.orderid.length) {
      this._CustomExceptionService.handleError("Please Select Order ID");
      return;
    }

    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._deliverychallanobj = { "OrderId": this.orderid, "branchid": this._branchid };
    this._Deliverychallanservice.Print1(this._deliverychallanobj).subscribe((result: any) => {

      // var blob = new Blob([result], { type: mediaType });
      // var url = window.URL.createObjectURL(blob);
      // this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GenerateReport(event) {
    if (!this.orderid || !this.orderid.length) {
      this._CustomExceptionService.handleError("Please Select Order ID");
      return;
    }

    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._deliverychallanobj = { "OrderId": this.orderid, "branchid": this._branchid };
    this._Deliverychallanservice.Print(this._deliverychallanobj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    },error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear() {
    this.ngOnInit();
    this.InitializeForm();
    this._OrderIDs = [];
    this._OrderNOs = [];
    this.orderid = [];
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
}
