import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Hotkey } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { PurchaseServiceInvoiceService } from 'src/app/core/Services/workshop/purchase-service-invoice.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-purchase-service-invoice',
  templateUrl: './v-purchase-service-invoice.component.html',
  styleUrls: ['./v-purchase-service-invoice.component.css']
})
export class VPurchaseServiceInvoiceComponent implements OnInit {
  _Action: IAction;
  _serviceinvoiceviews: any[] = []
  _IsProgressSpinner: boolean = true;
  fromdate: Date;
  todate: Date;
  datepipe = new DatePipe('en-US');
  _branchid: number;
  cols: any[];
  _roleid = sessionStorage["currentRole"]
  _userid = sessionStorage["userid"];
  mindate: Date;
  maxdate: Date;
  constructor(
    private _CustomExceptionService: CustomExceptionService,
    private _serviceproductdeliveryService: PurchaseServiceInvoiceService,
    private router: Router,
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this.fromdate = new Date();
    this.todate = new Date();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._Action = this._AccessRightsService.getAccessRights(334);
    this.SetFromToDate();
    this.utility.pageLoadScript();
    this._IsProgressSpinner = false;
    this.cols = [
      { field: 'purchaseserviceinvoiceno', header: 'Purchase Service Invoice Code' },
      { field: 'purchaseserviceinvoicedate', header: 'Purchase Service Invoice Date' },
      { field: 'suppliername', header: 'Supplier Name' },
      { field: 'servicerequestno', header: 'Service Request No' },
      { field: 'serviceworkorderno', header: 'Service Work Order Code' },
      { field: 'netamount', header: 'Net Amount' },
      { field: 'statusname', header: ' Status' },
      { field: 'createdbyname', header: 'Created By' },
      { field: 'createdon', header: 'Created On' },
    ]
    this.Fetchall();
  }
  SetFromToDate(){
		if(sessionStorage['Environmentenddate'] == 'null'){
			this.fromdate = new Date();
			this.todate = new Date();
			this.maxdate = new Date();
		}
		else{
			this.fromdate = new Date(sessionStorage['Environmentenddate'])
			this.todate = new Date(sessionStorage['Environmentenddate'])
		}
		this.fromdate.setDate(this.fromdate.getDate() - 1);
	}
  Fetchall() {
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    let data = {
      "fromdate": fromdate,
      "todate": todate,
      "branchid": this._branchid
    }
    this._IsProgressSpinner = true;
    this._serviceproductdeliveryService.FetchAll(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._serviceinvoiceviews = resultJSON.purchaseServiceInvoiceView;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/CPurchaseServiceInvoice'], { state: { serviceinvoiceid: event.purchaseserviceinvoiceno, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/CPurchaseServiceInvoice']);
  }
  exportExcel() {
    let purchaseorderList = this.GetPurchaseorderList();
    this.exportUtility.exportExcel(purchaseorderList, 'Service_product_delivery', 'xlsx');
  }
  ExportToPdf() {
    let purchaseorderList = this.GetPurchaseorderList();
    this.exportUtility.ExportToPdf(purchaseorderList, 'Service_product_delivery.pdf');
  }
  GetPurchaseorderList() {
    let purchaseorderList = [];
    this._serviceinvoiceviews.forEach(element => {
      let purchaseorder: any = {};
      purchaseorder["Purchase Service Invoice Code"] = element.purchaseserviceinvoiceno;
      purchaseorder["Purchase Service Invoice Date"] = this.datepipe.transform(element.purchaseserviceinvoicedate, 'dd/MM/yyyy');
      purchaseorder["Supplier Name"] = element.suppliername;
      purchaseorder["Service Request No"] = element.servicerequestno;
      purchaseorder["Service Work Order Code"] = element.serviceworkorderno;
      purchaseorder["Net Amount"] = element.netamount;
      purchaseorder["Status"] = element.statusname;
      purchaseorder["Created By"] = element.createdbyname;
      purchaseorder["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
      purchaseorderList.push(purchaseorder);
    });
    return purchaseorderList;
  }
  Print(event) {
    this._IsProgressSpinner = true;
    this._serviceproductdeliveryService.Print({ "PurchaseServiceInvoiceNo": event.purchaseserviceinvoiceno, "branchid": this._branchid }).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}