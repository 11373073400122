<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">

            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Employee </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" type="submit"
                                (click)="onSave()" [disabled]="_SaveHide"></button>
                            <button pButton pRipple label="" title="Clear" type="button" icon="pi pi-trash"
                                (click)="reset($event)" class="p-button-danger" [disabled]="_ClearHide"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">

                <!-- Employee Details -->
                <p-tabView [(activeIndex)]="selectedtab">
                    <p-tabPanel header="Employee Details">
                        <form [formGroup]="_employeeform" (ngSubmit)="onSave()">
                            <table class="normal-table">
                                <tr>
                                    <!-- if "employee code" is not enter, show error as "Please Enter employee code" -->
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText disabled formControlName="employeecode">
                                            <label for="employeecode">Employee Code </label>
                                        </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_employeeform.get('employeecode').touched || _submitted) && _employeeform.get('employeecode').errors?.EmployeeCodeRequired">
                                            Please Enter Employee Code
                                        </span> -->
                                    </td>
                                    <!-- if "first name" is not enter, show error as "Please Enter first name" -->
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="employeename"  required maxlength="19" ngModel pattern="[a-zA-Z ]*">
                                            <label for="employeename">First Name <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_employeeform.get('employeename').touched || _submitted) && _employeeform.get('employeename').errors?.EmployeeNameRequired">
                                            Please enter first name
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="employeelastname"  maxlength="19" ngModel pattern="[a-zA-Z ]*">
                                            <label for="employeelastname">Last Name </label>
                                        </span>
                                    </td>
                                    <!-- If "Father Name" is not  enter, show error as  "please enter Father Name" -->
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="fathername" required maxlength="19" ngModel pattern="[a-zA-Z ]*">
                                            <label for="fathername">Father's Name <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_employeeform.get('fathername').touched || _submitted) && _employeeform.get('fathername').errors?.FatherNameRequired">
                                            Please enter father's name
                                        </span>
                                    </td>
                                    <!-- If "gender"  is not select  show error as  " please select gender " -->
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Gender"
                                                formControlName="gender" optionLabel="metasubdescription"
                                                optionValue="metasubcode"></p-dropdown>
                                            <label for="gender">Gender <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_employeeform.get('gender').touched || _submitted) && _employeeform.get('gender').errors?.SelectGender">
                                            Please select gender
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <!-- If "Date of birth"  is not select  show error as  "please select dateofbirth " -->
                                        <span class="p-float-label">
                                            <!-- <p-calendar [showIcon]="true" formControlName="dob">
                                            </p-calendar> -->
                                            <p-calendar [showIcon]="true" dateFormat="dd/mm/yy" inputId="spanish"
                                                formControlName="dob" [monthNavigator]="true" [yearNavigator]="true"
                                                yearRange="1970:2010" [maxDate]="maxdate" (onSelect)="onDOBSelect($event)"
                                                (onBlur)="onDOBSelect($event)"></p-calendar>

                                            <!-- <p-calendar [showIcon]="true" [locale]="es" dateFormat="dd/mm/yy" inputId="spanish" formControlName="dob" [monthNavigator]="true"
                                                [yearNavigator]="true" yearRange="1980:2030" [maxDate]="today"
                                                (onSelect)="onDOBSelect($event)"></p-calendar> -->
                                            <label for="dob">Date of Birth <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_employeeform.get('dob').touched || _submitted) && _employeeform.get('dob').errors?.SelectDateofBirth">
                                            Please select date of birth
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="number" pInputText formControlName="age" disabled>
                                            <label for="age">Age </label>
                                        </span>
                                    </td>
                                    <td>
                                        <!-- If "marital status"  is not select  show error as  "please select maritalstatus " -->
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Maritalstatus"
                                                formControlName="maritalstatus" optionLabel="metasubdescription"
                                                optionValue="metasubcode"></p-dropdown>
                                            <label for="maritalstatus">Marital Status <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_employeeform.get('maritalstatus').touched || _submitted) && _employeeform.get('maritalstatus').errors?.SelectMaritalStatus">
                                            Please select marital status
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [filter]="true"
                                                [options]="_Bloodgroups" formControlName="bloodgroup" [showClear]="true"
                                                optionLabel="metasubdescription" optionValue="metasubcode"></p-dropdown>
                                            <label for="bloodgroup">Blood Group <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_employeeform.get('bloodgroup').touched || _submitted) && _employeeform.get('bloodgroup').errors?.SelectBloodGroup">
                                            Please select blood group
                                        </span>
                                    </td>
                                    <td>
                                        <!-- If "Date of joining"  is not select  show error as  "please select dateofjoining " -->
                                        <span class="p-float-label">
                                            <p-calendar [showIcon]="true" dateFormat="dd/mm/yy" inputId="spanish"
                                                formControlName="doj" [minDate]="mindate" [monthNavigator]="true" [yearNavigator]="true"
                                                yearRange="2000:2025" (onSelect)="onDOJSelect($event)" (onBlur)="onDOJSelect($event)">
                                            </p-calendar>
                                            <label for="doj">Date of Joining <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_employeeform.get('doj').touched || _submitted) && _employeeform.get('doj').errors?.SelectDateofJoining">
                                            Please select date of joining
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [filter]="true"
                                                [options]="_Departments" formControlName="department" [showClear]="true"
                                                optionLabel="metasubdescription" optionValue="metasubcode"></p-dropdown>
                                            <label for="department">Department </label>
                                        </span>
                                    </td>
                                    <td>
                                        <!-- If "employee role"  is not select  show error as  "please select employee role " -->
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [filter]="true"
                                                [options]="_Employeroles" formControlName="employeerole"
                                                [showClear]="true" optionLabel="rolename" optionValue="roleid">
                                            </p-dropdown>
                                            <label for="employeerole">Employee Role <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_employeeform.get('employeerole').touched || _submitted) && _employeeform.get('employeerole').errors?.SelectEmployeeRole">
                                            Please select employee role
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [filter]="true" [autoDisplayFirst]="false" [options]="_Branches"
                                                formControlName="reportingbranch" optionLabel="branchname"
                                                [showClear]="true" optionValue="branchid"></p-dropdown>
                                            <label for="reportingbranch">Branch <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_employeeform.get('reportingbranch').touched || _submitted) && _employeeform.get('reportingbranch').errors?.SelectEmployeeRole">
                                            Please select branch
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [filter]="true"
                                                [options]="_Reportingpersons" formControlName="reportingperson"
                                                [showClear]="true" optionLabel="employeename" optionValue="employeeid"  >
                                            </p-dropdown>
                                            <label for="reportingperson">Reporting Person </label>
                                        </span>
                                    </td>

                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Employestatus"
                                                formControlName="employeestatus" optionLabel="metasubdescription"
                                                optionValue="metasubcode"></p-dropdown>
                                            <label for="employeestatus">Status </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="uniformsize"  maxlength="90">
                                            <label for="uniformsize">Uniform Size </label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="nationalid"  maxlength="20">
                                            <label for="nationalid">National ID </label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-calendar [showIcon]="true" dateFormat="dd/mm/yy" inputId="spanish"
                                                formControlName="releavingdate"  [minDate]="dojmindate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2025">
                                            </p-calendar>
                                            <label for="releavingdate">Releaving Date</label>
                                        </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                            <div class="sub-title">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Contact Details</h5>
                                    </div>
                                    <div class="col-md-7"></div>
                                </div>
                            </div>
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="primarymobileno" pInputText
                                                formControlName="primarymobileno"
                                                (keypress)="keypress.kpMobileNumber($event)" maxlength="20">
                                            <label for="primarymobileno">Primary Mobile Number <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_employeeform.get('primarymobileno').touched || _submitted) && _employeeform.get('primarymobileno').errors?.MobileNumberRequired">
                                            Please enter primary mobile number
                                        </span>
                                        <!-- <span class="text-danger"
                                    *ngIf="(_employeeform.get('primarymobileno').touched || _submitted) && _employeeform.get('primarymobileno').errors?.MobileNumberInvalid">
                                    Please Enter Valid Mobile Number
                                </span> -->
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="secondarymobileno" pInputText
                                                formControlName="secondarymobileno"
                                                (keypress)="keypress.kpMobileNumber($event)" maxlength="20">
                                            <label for="secondarymobileno">Secondary Mobile Number </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;" rowspan="3">
                                        <span class="p-float-label">
                                            <textarea rows="2" cols="30" pInputTextarea
                                                formControlName="address"  maxlength="40"></textarea>
                                            <label for="address">Address </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="emailid">
                                            <label for="emailid">Mail ID <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_employeeform.get('emailid').touched || _submitted) && _employeeform.get('emailid').errors?.EmailIDRequired">
                                            Please Enter Email ID
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_employeeform.get('emailid').touched || _submitted) && _employeeform.get('emailid').errors?.ValidEmailID">
                                            Please enter valid email ID
                                        </span>
                                    </td>
                                    <td>
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <div class="browse-links">
                                                    <p-button label="Employee Image(JPEG)" styleClass="p-button-link" title="{{imageselect}}" 
                                                        (click)="showdocumentDialog(document,'bottom-right')">
                                                    </p-button>
                                                </div>
                                            </span>
                                            <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                                pTooltip="Browse File" tooltipPosition="top" mode="basic" #document
                                                [disabled]="isDisabledButton" accept="image/*" maxFileSize="1000000"
                                                [auto]="true" chooseLabel="jpeg,jpg,png,gif"
                                                (onSelect)="onDocumentSelect($event,document)">
                                            </p-fileUpload>

                                            <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                                (click)="onDocumentClear()" [disabled]="isDisabledButton"
                                                tooltipPosition="top">
                                                <i class="pi pi-times"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                            <div class="sub-title">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Emergency Contact Details</h5>
                                    </div>
                                    <div class="col-md-7"></div>
                                </div>
                            </div>
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="contactpersonname"  maxlength="19" ngModel pattern="[a-zA-Z ]*">
                                            <label for="contactpersonname">Person Name </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [filter]="true"
                                                [options]="_Relationship" formControlName="contactpersonrelationship"
                                                optionLabel="metasubdescription" optionValue="metasubcode"></p-dropdown>
                                            <label for="contactpersonrelationship">Relationship
                                                <!-- <span class="hlt-txt">*</span> -->
                                            </label>
                                        </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_employeeform.get('contactpersonrelationship').touched || _submitted) && _employeeform.get('contactpersonrelationship').errors?.SelectRelationship">
                                            Please Select Relationship
                                        </span> -->
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="contactpersonno" pInputText
                                                formControlName="contactpersonno"
                                                (keypress)="keypress.kpMobileNumber($event)" maxlength="20">
                                            <label for="contactpersonno">Contact Number </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                    </td>
                                    <td style="width: 20%;"></td>
                                </tr>
                            </table>
                            <!-- Image Upload -->
                            <p-dialog header="Employee Image" [(visible)]="displaydocumentpopup" [position]="position1"
                                [style]="{width: '500px'}" [baseZIndex]="10000">
                                <img [src]="employeeimage" alt="" class="img-preview">

                                <ng-template pTemplate="footer">
                                    <p-button icon="pi pi-check" (click)="showPopupDialog()" label="Ok"
                                        styleClass="p-button-text">
                                    </p-button>
                                </ng-template>
                            </p-dialog>
                        </form>
                    </p-tabPanel>

                    <!-- Employee Account Details -->

                    <p-tabPanel header="Employee Account Details">
                        <form [formGroup]="_employeeaccountdetailform" autocomplete="off">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <input type="text" pInputText name="bankcode" disabled
                                                    formControlName="bankcode">
                                                <label for="bankcode">Bank Code <span class="hlt-txt">*</span></label>
                                            </span>
                                            <button class="p-inputgroup-addon p-button" (click)="showBasicDialog()"
                                                [disabled]="_action == 'view'">
                                                <i class="pi pi-external-link"></i>
                                            </button>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _employeeaccountdetailform.get('bankcode').touched) && _employeeaccountdetailform.get('bankcode').errors?.SelectBankCode">
                                            Please select bank code
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="bankname" disabled
                                                formControlName="bankname">
                                            <label for="bankname"> Enter Bank Name <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _employeeaccountdetailform.get('bankname').touched) && _employeeaccountdetailform.get('bankname').errors?.SelectBankName">
                                            Please select bank name
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="branchname"
                                                formControlName="branchname">
                                            <label for="branchname"> Enter Bank Branch <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _employeeaccountdetailform.get('branchname').touched) && _employeeaccountdetailform.get('branchname').errors?.BranchNameRequired">
                                            Please enter bank branch
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <!-- <input type="text" pInputText name="swiftcode" formControlName="swiftcode"> -->
                                            <input type="text" pInputText name="swiftcode" maxlength="30"
                                                formControlName="swiftcode">
                                            <label for="swiftcode"> Enter Swift Code <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted1 || _employeeaccountdetailform.get('swiftcode').touched) && _employeeaccountdetailform.get('swiftcode').errors?.SwiftCodeRequired">
                                            Please enter swift code
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="accountno" maxlength="20"
                                                (keypress)="keypress.kpNumber($event)" formControlName="accountno">
                                            <!-- <p-inputNumber name="accountno" maxlength="20" formControlName="accountno"
                                                inputId="withoutgrouping" [useGrouping]="false"> </p-inputNumber> -->
                                            <label for="accountno">Bank Account Number <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted1 || _employeeaccountdetailform.get('accountno').touched) && _employeeaccountdetailform.get('accountno').errors?.AccountNoRequired">
                                            Please enter account number
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Accounttypes"
                                                name="accounttype" optionLabel="metasubdescription"
                                                optionValue="metasubcode" formControlName="accounttype">
                                            </p-dropdown>
                                            <label for="accounttype">Account Type</label>
                                        </span>
                                    </td>
                                    <td> <span class="p-float-label">
                                            <input type="text" pInputText name="accountholdername"
                                                formControlName="accountholdername">
                                            <label for="accountholdername"> Enter Account Holder Name</label>
                                        </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>

                        <!-- For pop Up -->
                        <p-dialog header="Bank Code" [(visible)]="displayBasic" [modal]="true"
                            [style]="{width: '50vw',height: '500px'}" [baseZIndex]="10000">
                            <ng-template pTemplate="content">
                                <div class="popup-body">
                                    <div class="search-container">
                                        <p-toolbar styleClass="p-mb-4">
                                            <ng-template pTemplate="left">
                                                <span class="p-input-icon-left">
                                                    <i class="pi pi-search"></i>
                                                    <input pInputText type="text"
                                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                        placeholder="Search..." />
                                                </span>


                                                <!-- <button pButton pRipple label="" icon="pi pi-trash" class="p-button-danger "></button> -->
                                            </ng-template>
                                        </p-toolbar>
                                    </div>
                                    <div class="card">
                                        <p-table #dt [value]="Banklist" selectionMode="single"
                                            [(selection)]="selectedBank" [globalFilterFields]="['bankcode','bankname']"
                                            (onRowSelect)="onRowSelectPopup($event)" [paginator]="true" [rows]="5">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th pSortableColumn="bankcode">Code<p-sortIcon field="bankcode">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="bankname">Name <p-sortIcon field="bankname">
                                                        </p-sortIcon>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-sBank>
                                                <tr [pSelectableRow]="rowData">
                                                    <td>{{sBank.bankcode}}</td>
                                                    <td>{{sBank.bankname}}</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </ng-template>
                        </p-dialog>
                    </p-tabPanel>

                    <!-- Employee Document Detail -->
                    <p-tabPanel header="Employee Document Details">

                        <form [formGroup]="_employeedocumentdetailform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <!-- <span class="p-float-label">
                                            <input type="text" pInputText formControlName="documentname">
                                            <label for="documentname">Document Name <span
                                                    class="hlt-txt">*</span></label>
                                        </span> -->
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_DocumentDescriptions"
                                                optionValue="documentdescriptionname" optionLabel="documentdescriptionname" formControlName="documentname"
                                                [filter]="true" filterBy="documentdescriptionname">
                                            </p-dropdown>
                                            <label for="documentname">Document Name<span
                                                class="hlt-txt">*</span></label>

                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted2 || _employeedocumentdetailform.get('documentname').touched) && _employeedocumentdetailform.get('documentname').errors?.DocumentNameRequired">
                                            Please Select Document Name
                                        </span>
                                    </td>
                                    <!-- <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText formControlName="documentdescription">
                                            <label for="documentdescription">Document Description</label>
                                        </span>
                                    </td> -->
                                    <td style="width: 20%;">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <div class="browse-links">
                                                    <p-button label="Employee Document" styleClass="p-button-link" title="{{documentselect}}" 
                                                        (click)="showemployeegrid(documentgrid,'bottom-right')">
                                                    </p-button>
                                                </div>
                                            </span>
                                            <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                                pTooltip="Browse File" tooltipPosition="top" mode="basic" #documentgrid
                                                accept="image/*,.pdf,.xls,.xlsx,.docx,.doc,.txt" maxFileSize="1000000" [auto]="true"
                                                (onSelect)="onDocumentEmployeeSelect($event,documentgrid)"
                                                [disabled]="_action=='view'">
                                            </p-fileUpload>
                                            <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                                (click)="onDocumentEmployeeClear()" tooltipPosition="top"
                                                [disabled]="_action=='view'">
                                                <i class="pi pi-times"></i>
                                            </button>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_submitted2 && _LastAddedGridDocument == null)">
                                            Please Add Document
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <button type="submit" pButton pRipple label="Add" title="Add" icon="pi pi-plus"
                                            [disabled]="_action == 'view'" (click)="onAddDocuments($event)"
                                            class="p-button-success p-mr-2"></button>
                                        <button pButton pRipple label="Clear" title="Clear" icon="pi pi-times" type="button"
                                            [disabled]="_action == 'view'" class="p-button-danger p-mr-2"
                                            (click)="EmployeeClear()"></button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                        <!-- Form group -->

                        <div class="card">
                            <p-table #dt [value]="_Employeedocumentdetails" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]"
                                [globalFilterFields]="['documentdescription','documentname']" [rowHover]="true"
                                dataKey="address" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)"
                                (onRowUnselect)="onRowUnselect($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;width: 100px;">Action</th>
                                        <th>Document Name</th>
                                        <!-- <th>Document Description</th> -->
                                        <th>Attachment Name</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_Employeedocumentdetail>
                                    <tr>
                                        <td style="text-align: center;">
                                            <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                [disabled]="_removedocoumenthidden"
                                                (click)="RemoveDocumentDetail(_Employeedocumentdetail)"
                                                class="p-button-danger p-mr-2"></button>
                                        </td>
                                        <td>
                                            {{_Employeedocumentdetail.documentname}}
                                        </td>
                                        <!-- <td>
                                            {{_Employeedocumentdetail.documentdescription}}
                                        </td> -->
                                        <td>
                                            <a href="javascript:void(0);"
                                                (click)="showdocumentGridDialog('bottom-right',_Employeedocumentdetail)">
                                                {{_Employeedocumentdetail.attachmentname}}
                                            </a>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <!-- Document Upload -->
                        <!-- <p-dialog header="Employee Document" [(visible)]="displaydocumentgridpopup"
                            [position]="position" [style]="{width: '500px'}" [baseZIndex]="10000">
                            <img [src]="documentimage" alt="" class="img-preview">
                            <ng-template pTemplate="footer">
                                <p-button icon="pi pi-check" (click)="closePopupGridDialog()" label="Ok"
                                    styleClass="p-button-text">
                                </p-button>
                            </ng-template>
                        </p-dialog> -->
                      
                        <table>
                            <tr>
                                <td>
                                    <!-- Debug -->
                                    <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                                </td>
                            </tr>
                        </table>


                    </p-tabPanel>
                </p-tabView>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

        </div>
    </div>
</div>

<p-toast position="bottom-right"></p-toast>