export class Productpricedetail {
    productdetailid: number;
    productid: number;
    markuplevel: number ;
    vatpercentageid: number ;
    markuppercentage: number ;
    effectivefrom: Date  ;
    effectiveto: Date  ;
    createdby: number;
    createdon: Date ;
    modifiedby: number;
    modifiedon: Date ;
    usd: number ;
    rand: number ;
    bond: number;
    bank: number;
    usdvatin: number ;
    randvatin: number ;
    bondvatin: number;
    bankvatin: number;
    tempusd: number;
    temprand: number;
    tempbond: number;
    tempbank: number;
    tempusdvatin: number ;
    temprandvatin: number ;
    tempbondvatin: number;
    tempbankvatin: number;
    currencyid: number ;
}

