import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptsalescommissionService {
  public _Action: IAction;
  public _httpOptions;
  public _RptSalesCommissionService = environment.RptSalesCommissionService;
  token: any;
  header: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnload(obj: any): Observable<any> {
    const headers = this.header;
    var Query = this._RptSalesCommissionService + '/PageOnLoad';
    return this.http.post<{ data: any }>(Query, obj, { headers });
  }
  Print(fromdate: string, todate: string, salestypeid: number, branchid: number) {
    const headers = this.header;
    var Query = this._RptSalesCommissionService + '/Print/' + fromdate + '/' + todate + '/' + salestypeid + '/' + branchid;
    return this.http.get(Query, { responseType: 'blob',  headers });
    //
    // window.open(Query,'_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }
  GetSalesCommissionDetails(obj: any): Observable<any> {
    const headers = this.header;
    var Query = this._RptSalesCommissionService + '/GetSalesCommissionDetails';
    return this.http.post<{ data: any }>(Query, obj, { headers });
  }

}
