import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { GenerateBackup } from 'src/app/core/Models/GenerateBackup';
import { GeneratebackupService } from 'src/app/core/Services/online-offline/generatebackup.service';
import { vBranchSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-generatebackup',
  templateUrl: './generatebackup.component.html',
  styleUrls: ['./generatebackup.component.css']
})
export class GeneratebackupComponent implements OnInit {
  _GenerateBackupForm: FormGroup;
  _PageOnloadObj: any;
  _CreateBackupObj: any;
  _UpdateBackupObj: any;
  _IsProgressSpinner: boolean = true;
  _Branches: Branch;
  _submitted = false;
  _Backuppath: string;
  Message:string;
  OpenBackupMessage:boolean;
  _GenerateBackup:GenerateBackup;
  constructor(private fb: FormBuilder, private utility: CommonUtilities, 
    private _GenerateBackupService: GeneratebackupService, private _CustomExceptionService: CustomExceptionService) { }
  InitializeGenerateForm() {
    this._GenerateBackupForm = this.fb.group({
      choosedate: [],
      branchid: ['', vBranchSelect]
    })
    this._GenerateBackupForm.get("choosedate").setValue(new Date());
    this._GenerateBackupForm.get("branchid").setValue(parseInt(sessionStorage["currentbranchid"]));
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeGenerateForm();
    this._PageOnloadObj = {};
    this._IsProgressSpinner = true;
    this._GenerateBackupService.PageOnload(this._PageOnloadObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._Branches = resultJSON.branchlist;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  CreateBackup() {
    this._submitted = true;
    if (this._GenerateBackupForm.valid) {
      var _choosedate = this._GenerateBackupForm.get("choosedate").value;
      var branchid = this._GenerateBackupForm.get("branchid").value;
      var datePipe = new DatePipe("en-US");
      var choosedate = datePipe.transform(_choosedate, 'yyyy-MM-dd');
      this._IsProgressSpinner = true;
      this._CreateBackupObj = { "date": choosedate, "branchid": branchid || 0 };
      this._GenerateBackupService.CreateBackup(this._CreateBackupObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
          this.OpenBackupMessage=true;
          this._Backuppath = resultJSON.backuppath;
          this.Message="Back up Generated Successfully";
          this._IsProgressSpinner = false;
        }else{
          this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        }
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }

  }

  UploadBackup() {
    this._submitted = true;
    var backuppath = this._Backuppath;
    this._GenerateBackup=this._GenerateBackupForm.value;
    this._GenerateBackup.generateBackupid=0;
    this._GenerateBackup.branchid=this._GenerateBackupForm.get("branchid").value;
    this._GenerateBackup.createdby=sessionStorage["userid"];
    this._GenerateBackup.transactiondate=new Date();
    this._GenerateBackup.excecutedon=new Date();
    this._GenerateBackup.message="";
    this._GenerateBackup.createdon=new Date();
    this._IsProgressSpinner = true;
    this._UpdateBackupObj = { "backuppath": backuppath, "backup":this._GenerateBackup };
    this._GenerateBackupService.UploadBackup(this._UpdateBackupObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess("Backup is uploaded Successfully");
        this.OpenBackupMessage=false;
        this._IsProgressSpinner = false;
      }else{
        this.OpenBackupMessage=false;
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  
  closeBackupMessagePopup(){
    this.OpenBackupMessage=false;
  }
}
