import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';

import { Country } from '../../Models/country'
import { AbstractControl } from '@angular/forms';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { IAction } from '../security/IActions';

@Injectable({
  providedIn: 'root'
})


export class CountryService {
  public _Action: IAction;
  public _CountryService = environment.CountryService;
  public _httpOptions;
  public _CountryList: Country[] = [];
  token: any;
  header: any;
  constructor(private http: HttpClient, private _CustomExceptionService: CustomExceptionService) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }
  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }


  PageOnLoad(p_countryid: number): Observable<any> {
    const headers = this.header;
    var Query = this._CountryService + '/' + p_countryid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

  FetchAll() {
    const headers = this.header;
    // 15.all the datas should fetch in particular columns.based on country code
    var Query = this._CountryService + '/FetchAll';


    return this.http.get<{ data: any }>(Query, {headers}).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      //console.log(resultJSON);
      // let _CountryScreenAccess = resultJSON.userData.lstFunctions.find(funct => funct.functionid == "15");
      // this._Action = {
      //   _functionId: 15,
      //   _functionName: "Customer",
      //   _Add: _CountryScreenAccess.iscreateallowed,
      //   _Delete: _CountryScreenAccess.isdeleteallowed,
      //   _Update: _CountryScreenAccess.iseditallowed,
      //   _View: _CountryScreenAccess.isviewallowed,
      //   _Approve: _CountryScreenAccess.isviewallowed
      //}
      return data;
    }));
  }

  create(p_country: object) {
    const headers = this.header;
    //console.log("Create");
    var Query = this._CountryService;
    return this.http.post<{ data: any }>(Query, p_country, { headers });
  }

  edit(p_country: object) {
    const headers = this.header;
    //console.log("Update");
    var Query = this._CountryService;

    return this.http.put<{ data: any }>(Query, p_country, { headers });
  }
  Cancel(countryid: number, userid: number) {
    //console.log("Update");
    const headers = this.header;
    var Query = this._CountryService + '/Cancel/' + countryid + '/' + userid;

    return this.http.get<{ data: any }>(Query, { headers });
  }

}
