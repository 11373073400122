import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Currency } from 'src/app/core/Models/Currency';
import { ProductService } from 'src/app/core/Services/masters/product.service';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Productsupplierdetail } from 'src/app/core/Models/Productsupplierdetail';
import { Productpricedetail } from 'src/app/core/Models/Productpricedetail';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Tax } from 'src/app/core/Models/Tax';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import * as ace from 'ace-builds';
import {
  vDiscountPercentage,
  vMaximumLevel,
  vMinimumLevel,
  vProductCategorySelect,
  vProductName,
  vProductTypeSelect,
  vPurchaseFromSelect,
  vPurchaseRate,
  vPurchaseUOMSelect,
  vReorderLevel,
  vSalesUOMSelect,
} from 'src/app/core/Validators/validation';
import { TabView } from 'primeng/tabview';
import { Productpricedetailssearch } from 'src/app/core/Models/Productpricedetailssearch';
import { MarkupLevel } from 'src/app/core/Models/overallclass';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { FileUpload } from 'primeng/fileupload';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { KeyPress } from 'src/assets/js/KeyPress';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { ConfirmationService } from 'primeng/api';
import {
  base64ToFile,
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { Productquestionandanswer } from 'src/app/core/Models/Productquestionandanswer';
import { Productimage } from 'src/app/core/Models/Productimage';
import { Productreview } from 'src/app/core/Models/Productreview';
import { Productdescription } from 'src/app/core/Models/Productdescription';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Brand } from 'src/app/core/Models/Brand';
import { Productbrandmapping } from 'src/app/core/Models/Productbrandmapping';
import { Productratechange } from 'src/app/core/Models/Productratechange';
import { Productratechangedetail } from 'src/app/core/Models/Productratechangedetail';
import { ProductpricechangeService } from 'src/app/core/Services/sales/productpricechange.service';
import { Productcurrency } from 'src/app/core/Models/ProductCurrency';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Supplierproductdetail } from 'src/app/core/Models/Supplierproductdetail';
declare let $: any;
@Component({
  selector: 'app-c-product',
  templateUrl: './c-product.component.html',
  styleUrls: ['./c-product.component.css'],
})
export class CProductComponent implements OnInit {
  _productform: FormGroup;
  _productsupplierdetailform: FormGroup;
  _productpricedetailform: FormGroup;
  _productvariantform: FormGroup;
  _Product: Productheader;
  _Productsupplierdetail: Productsupplierdetail;
  _Productpricedetail: Productpricedetail;
  _Productvariantdetail: Productvariant[] = [];
  _Productid: number;
  _PriceDetails: Productpricedetail[] = [];
  _TempPriceDetails: Productpricedetail[] = [];
  selectedtab: number = 0;
  _VariantDetails: Productvariant;
  _levels: MarkupLevel;
  displayBasicpopup: boolean;
  productimage: SafeUrl;
  _SendtoApproval: boolean = false;
  imageselect: string = '';
  _ProductNames: Productoverallview[] = [];
  _tempproductname: Productoverallview[] = [];
  filteredProducts: any[];
  _branchid = sessionStorage['currentbranchid'];
  _actionCopyproduct: boolean = false;
  isDisabledcopy: boolean = false;
  _producttypes: Producttype;
  _productcategories: Productcategory;
  _purchaseuom: Metadatum;
  _salesuom: Metadatum;
  _weightuom: Metadatum;
  _productstatus: Metadatum;
  _suppliernames: Supplier;
  _brandnames: Brand;
  _currencies: Currency[] = [];
  _currencylist: Currency[] = [];
  _warrantyperiods: Metadatum;
  _vatpercentage: Tax[] = [];
  _tempvatpercentage: Tax[] = [];
  _PurchaseFrom: Metadatum;
  _SalesDiscountLimit: Metadatum;
  _VatApplicable: boolean;
  _NotImport: boolean;
  _NotExport: boolean;
  _WarrantyApplicable: boolean;
  _Stuffed: boolean;
  _ManufacturingApplicable: boolean;
  _DisallowNegativeQuantity: boolean;
  _IsAvailableForECommerce: boolean;
  _Special: boolean;
  _submitted = false;
  _action: string = 'create';
  displayBasic: boolean;
  position: string;
  _ProductOBJ: any;
  _ProductOBJlist: any;
  _selectedTax: Tax;
  _SelectVat: any;
  _selectedSupplierIds: number[] = [];
  _selectedBrandIds: number[] = [];
  _Productsupplierdetails: Supplierproductdetail[] = [];
  _prevSelectedSuppliers: Productsupplierdetail;
  _PriceHistories: Productpricedetailssearch[] = [];
  _warrantyEnable: boolean = false;
  _StuffedEnable: boolean = false;
  _SalesDiscountLimitEnable: boolean = false;
  disabled: boolean = true;
  _unitPrice: number = 0;
  _effectiveTo: Date;
  _effectiveFrom: Date;
  _selectedlevelId: number;
  position1: string;
  _validate: boolean;
  displaydocumentpopup: boolean;
  _LastAddedDocument: File;
  _selectedDocuments: File[] = []; // For Documents uploads
  _productFORM: FormData;
  _IsVatParcentage = true;
  _IsDisableinView = true;
  _IsProgressSpinner: boolean = true;
  isDisabledSave = false;
  isDisabledClear = false;
  isDisabledButton = false;
  _Action: IAction;
  hidePriceHistory: boolean = true;
  _ProductObj: any;
  //E-Commerce
  _ProductBranddetails: Productbrandmapping[] = [];
  _ProductDescriptions: Productdescription[] = [];
  _ProductFAQ: Productquestionandanswer[] = [];
  _ProductImage: Productimage[] = [];
  _TempProductImage: any[] = [];
  _SelectedImage: File[] = [];
  _ProductReview: Productreview[] = [];
  _productdescriptionform: FormGroup;
  imageChangedEvent: any;
  croppedImage: any;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  adimage: string;
  displayBasic1: boolean;
  imageCropperTool: boolean;
  _CropImage: File;
  _ProductFORM: FormData;
  _Productreviewform: FormGroup;
  _faqform: FormGroup;
  _productbranddetailform: FormGroup;
  displayimagepopup: boolean;
  _LastAddedImage: File;
  _Iscreatefileupload: boolean = true;
  _Isviewfileupload: boolean = true;
  _FAQStatus: Metadatum;
  _ReviewStatus: Metadatum;
  _ProductImages: Productimage[] = [];
  clearimage: boolean = false;
  productLists: any[];
  _IshideTabs: boolean = false;
  _Copiedproduct: boolean = false;
  _oldproduct: Productheader;
  _productrate: Productratechange;
  _productratedetail: Productratechangedetail[] = [];
  _SelectedCurrencies: any[] = [];
  _ProductSelectedCurrencies: Productcurrency[] = [];
  _Copiedsupplier: Supplier[] = [];
  Weburl: any;
  Mobileurl: any;
  _WebImage: File[] = [];
  _MobileImage: File[] = [];
  @ViewChild('editor', { static: true })
  private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private _AccessRightsService: AccessRightsService,
    private utility: CommonUtilities,
    private _ProductService: ProductService,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService,
    private _hotkeysService: HotkeysService,
    private _DomSanitizationService: DomSanitizer,
    public keypress: KeyPress,
    private _ProductpricechangeService: ProductpricechangeService,
    private imageCompress: NgxImageCompressService
  ) {
    this._Productid = history.state?.productid ? history.state?.productid : 0;
    this._action =
      history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this.utility.pageLoadScript();
    this._productform = this.fb.group({
      productid: [0],
      producttypeid: ['', vProductTypeSelect],
      productcategoryid: ['', vProductCategorySelect],
      productcode: [],
      productname: ['', vProductName],
      specification: [],
      purchaseuom: ['', vPurchaseUOMSelect],
      salesuom: ['', vSalesUOMSelect],
      weight: [],
      weightuom: [],
      barcode: [],
      minimumlevel: ['', vMinimumLevel],
      maximumlevel: ['', vMaximumLevel],
      reorderlevel: ['', vReorderLevel],
      openingstock: [],
      purchasefrom: ['', vPurchaseFromSelect],
      salesdiscount: [],
      discountpercentage: [0, vDiscountPercentage],
      purchaserate: ['', vPurchaseRate],
      currencyid: [],
      purchaserateinusd: [],
      vatapplicable: [],
      notimport: [],
      notexport: [],
      warrantyapplicable: [],
      warranty: [],
      warrantyperiod: [],
      stuffed: [],
      stuffedqty: [],
      manufacturingapplicable: [],
      disallownegativeqty: [],
      productimageurl: [],
      productimageurlname: [],
      productstatus: [],
      accountingyear: [],
      createdby: [sessionStorage['userid']],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      special: [],
      isavailable: [],
      approvalstatus: [],
      approvalremarks: [],
      customerwisediscounts: [],
      productpricedetails: [],
      productsupplierdetails: [],
      productvariants: [],
      fixedassets: [],
      productbrands: [],
      productdescriptions: [],
      productquestionandanswers: [],
      productimages: [],
      productreviews: [],
      iscashondelivery: [],
      productbrandmappings: [],
      onlinediscount: [0],
      ecnavigateurl: [],
      productcurrencies: [],
      isperishable: [],
      copiedproduct: [],
      isbranchlevelprice: [false],
      nolaybuy: [],
      cashonly: [],
    });
    this._productsupplierdetailform = this.fb.group({
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      product: [],
      productid: [],
      productsupplierdetailid: [],
      supplier: [],
      supplierid: [],
    });
    this._productpricedetailform = this.fb.group({
      productdetailid: [],
      productid: [],
      markuplevel: [],
      vatpercentageid: [],
      markuppercentage: [],
      effectivefrom: [],
      effectiveto: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      usd: [],
      rand: [],
      zim1: [],
      zim2: [],
      productheader: [],
      copiedproduct: [],
    });
    this._productvariantform = this.fb.group({
      productvariantdetailid: [],
      productid: [],
      variantdescription: [],
      recordstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      productheader: [],
    });
    this._productdescriptionform = this.fb.group({
      productdescriptiondetailid: [0],
      productid: [],
      shortdescription: [],
      longdescription: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      product: [],
    });
    this._Productreviewform = this.fb.group({
      reviewid: [0],
      reviewdate: [],
      reviewrating: [],
      reviewdescription: [],
      customerid: [],
      emailid: [],
      productid: [],
      variantid: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      recordstatus: ['MSC00001'],
      product: [],
      productcode: [],
      reviewby: [],
      reviewtype: ['ECO00022'],
      reviewno: [],
    });
    this._Productreviewform.controls['reviewby'].setValue(
      sessionStorage['username']
    );
    this._faqform = this.fb.group({
      productquestionandanswerid: [0],
      customerid: [0],
      productid: [],
      variantid: [0],
      question: [],
      answer: [''],
      answertype: ['ECO00022'],
      answerby: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      recordstatus: ['MSC0001'],
      productcode: [],
      product: [],
    });
    this._faqform.controls['answerby'].setValue(sessionStorage['username']);
    this._productbranddetailform = this.fb.group({
      productbrandmapid: [0],
      brandid: [],
      productid: [],
      recordstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
  }
  RemoveFile() {}
  fileChangeEvent(event: any): void {
    if (this._ProductImage.length == 5) {
      this._CustomExceptionService.handleError(
        'Maximum 5 product images allowed'
      );
      $('.file-upload-wrapper').attr('data-text', 'Select your file!');
      return;
    }
    this.imageChangedEvent = event;
    this.imageCropperTool = true;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64));
  }
  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }
  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }
  loadImageFailed() {
    console.log('Load failed');
  }
  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }
  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
  ngOnInit(): void {
    this._effectiveFrom = new Date();
    this._effectiveTo = new Date();
    this._Action = this._AccessRightsService.getAccessRights(17);
    this._IsProgressSpinner = true;
    this.InitializeForm();
    this.checkpricehistoryvisible();
    this._ProductService.PageOnLoad(this._Productid).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._producttypes = resultJSON.producttype;
        this._productcategories = resultJSON.productcategory;
        this._purchaseuom = resultJSON.purchaseuom;
        this._salesuom = resultJSON.salesuom;
        this._weightuom = resultJSON.weightuom;
        this._productstatus = resultJSON.status;
        this._suppliernames = resultJSON.suppliers;
        this._brandnames = resultJSON.brands;
        this._currencies = resultJSON.currencies;
        this._currencylist = resultJSON.currencylist;
        this._warrantyperiods = resultJSON.warrantyperiod;
        this._vatpercentage = resultJSON.taxes;
        this._tempvatpercentage = resultJSON.taxes;
        this._PurchaseFrom = resultJSON.purchasefrom;
        this._SalesDiscountLimit = resultJSON.salesdiscount;
        this._VatApplicable = resultJSON.vatapplicable;
        this._NotImport = resultJSON.notimport;
        this._NotExport = resultJSON.notexport;
        this._WarrantyApplicable = resultJSON.warrantyappicable;
        this._Stuffed = resultJSON.stuffed;
        this._ManufacturingApplicable = resultJSON.ownmanufacturing;
        this._DisallowNegativeQuantity = resultJSON.disallownegativity;
        this._IsAvailableForECommerce = resultJSON.isavaliablecommerce;
        this._Special = resultJSON.special;
        this._Copiedproduct = resultJSON.copiedproduct;
        console.log('p', this._Copiedproduct);
        this._PriceDetails = resultJSON.pricedetails;
        this._TempPriceDetails = resultJSON.pricedetails;
        this._FAQStatus = resultJSON.status;
        this._ReviewStatus = resultJSON.status;
        this._levels = resultJSON.levels;
        this._SelectedCurrencies = this._currencies.map((m) => m.currencyid);
        if (this._currencies == null || this._currencies.length == 0) {
          this._CustomExceptionService.handleWarning(
            'Please add currency in Currency Master'
          );
        } else if (
          this._vatpercentage == null ||
          this._vatpercentage.length == 0
        ) {
          this._CustomExceptionService.handleWarning(
            'Please add Tax in Tax Master'
          );
        } else if (this._levels == null) {
          this._CustomExceptionService.handleWarning(
            'Please add Level in Level Master'
          );
        }
        if (this._actionCopyproduct) {
          this.isDisabledcopy = true;
        } else {
          this.isDisabledcopy = false;
        }
        this.ResetPageLoad();
        if (this._action == 'edit' || this._action == 'view') {
          const updateJSON = JSON.parse(JSON.stringify(result));
          this.isDisabledcopy = true;
          this._productcategories = updateJSON.productcategory;
          this._productform.setValue(updateJSON.productheader);
          if (this._actionCopyproduct == false) {
            this._productform.get('purchaseuom').disable();
            this._productform.get('salesuom').disable();
          }

          this._oldproduct = updateJSON.productheader;
          this._prevSelectedSuppliers = updateJSON.productsupplierdetails;
          if (this._actionCopyproduct == false) {
            this._Productsupplierdetails = updateJSON.productsupplierdetails;
          }

          this._ProductBranddetails = updateJSON.productbrands;
          this._ProductSelectedCurrencies = updateJSON.productcurrencies;
          this._SelectedCurrencies = [];
          this._SelectedCurrencies = this._ProductSelectedCurrencies.map(
            (m) => m.currencyid
          );
          var selectedsuppllierArray: Supplier[] = [];
          var suppllierIds: number[] = [];
          if (this._actionCopyproduct == false) {
            Object.keys(updateJSON.productsupplierdetails).map((Index) => {
              suppllierIds.push(
                updateJSON.productsupplierdetails[Index].supplierid
              );
              selectedsuppllierArray.push(
                updateJSON.productsupplierdetails[Index]
              );
            });
            this._selectedSupplierIds = suppllierIds;
          }

          var brandIds: number[] = [];
          Object.keys(updateJSON.productbrands).map((Index) => {
            brandIds.push(updateJSON.productbrands[Index].brandid);
          });
          this._selectedBrandIds = brandIds;
          if (
            updateJSON.productdescriptions.length > 0 &&
            this._actionCopyproduct == false
          ) {
            this._productdescriptionform.setValue(
              updateJSON.productdescriptions[0]
            );
            this._ProductDescriptions = updateJSON.productdescriptions || [];
          }
          this._PriceDetails = updateJSON.pricedetails;
          this.calculateUnitPrice();
          this.onKeyupRatechange();
          this._Productvariantdetail = updateJSON.productvariants;
          this.isDisabledClear = true;
          if (this._Productvariantdetail.length == 0) {
            var l_productvariant = new Productvariant();
            l_productvariant.productid =
              this._productform.get('productid').value;
            l_productvariant.productvariantdetailid = 0;
            l_productvariant.modifiedon = new Date();
            l_productvariant.recordstatus = 'MSC00001';
            l_productvariant.createdby = sessionStorage['userid'];
            l_productvariant.modifiedby = sessionStorage['userid'];
            l_productvariant.createdon = new Date();
            l_productvariant.modifiedon = new Date();
            this._Productvariantdetail.push(l_productvariant);
          }
          if (this._action == 'view') {
            this.isDisabledcopy = true;
            this._productform.disable();
            this._productsupplierdetailform.disable();
            this._productpricedetailform.disable();
            this._productvariantform.disable();
            this._productbranddetailform.disable();
            this.isDisabledSave = true;
            this.isDisabledButton = true;
            this._IsVatParcentage = false;
            this._productdescriptionform.disable();
            this._faqform.disable();
            this._Productreviewform.disable();
          }
          if (
            updateJSON.productheader.productimageurl &&
            this._actionCopyproduct == false
          ) {
            this.productimage =
              this._DomSanitizationService.bypassSecurityTrustUrl(
                updateJSON.productheader.productimageurl
              );
            this.imageselect = updateJSON.productheader.productimageurlname;
          }
          if (this._actionCopyproduct == false) {
            Object.assign(this._ProductImage, updateJSON.productimages);
            Object.assign(this._TempProductImage, updateJSON.productimages);
            for (var i = 0; i < (this._ProductImage || []).length; i++) {
              this._ProductImage[i].productimage =
                this._ProductImage[i].productimageurl;
            }
          }
          if (this._actionCopyproduct == true) {
            //this._productsupplierdetailform.get(this._selectedSupplierIds).reset();
            this._selectedSupplierIds = [];
			this._Productsupplierdetails = [];
          }
        }
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  selectFile(event: any) {
    if (this._ProductImage.length == 5) {
      this._CustomExceptionService.handleError(
        'Maximum 5 product images allowed'
      );
      $('.file-upload-wrapper').attr('data-text', 'Select your file!');
      return;
    }
    if (event.target.files && event.target.files[0]) {
      const img = new Image();
      img.src = URL.createObjectURL(event.target.files[0]);
      img.onload = (e: any) => {
        if (e.srcElement.width == 400 && e.srcElement.height == 300) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            let url = event.target.result;
            this.webcompressFile(url, -1);
          };
          reader.readAsDataURL(event.target.files[0]);
        } else {
          $('.file-upload-wrapper').attr('data-text', 'Select your file!');
          this._CustomExceptionService.handleWarning(
            'Your uploaded image should be minimum Width and height of 800px X 600px'
          );
        }
      };
    }
  }
  webcompressFile(image: any, orientation: any) {
    this.imageCompress
      .compressFile(image, orientation, 100, 70)
      .then((result: any) => {
        this.Weburl = result;
        this.mobilecompressFile(result, -1);
      });
  }
  mobilecompressFile(image: any, orientation: any) {
    this.imageCompress
      .compressFile(image, orientation, 75, 70)
      .then((result: any) => {
        this.Mobileurl = result;
        this.createBlobImageFileAndShow(this.Weburl, this.Mobileurl);
      });
  }
  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/webp' });
    return blob;
  }
  createBlobImageFileAndShow(weburl: any, mobileurl: any): void {
    const webimageBlob = this.dataURItoBlob(weburl.split(',')[1]);
    const mobileimageBlob = this.dataURItoBlob(mobileurl.split(',')[1]);
    const webimageName: string = this.generateName();
    const mobileimageName: string = webimageName;
    let webimage = new File([webimageBlob], webimageName, {
      type: 'image/webp',
    });
    let mobileimage = new File([mobileimageBlob], mobileimageName, {
      type: 'image/webp',
    });
    if (this._action == 'create') {
      let l_productimage = new Productimage();
      l_productimage.productid = this._productform.get('productid').value;
      l_productimage.productimagename = webimage.name;
      l_productimage.productimageurl = webimage.name;
      l_productimage.mobileproducturl = mobileimage.name;
      l_productimage.mobileproducturlname = mobileimage.name;
      l_productimage.brandid = 0;
      l_productimage.modifiedon = new Date();
      l_productimage.createdby = sessionStorage['userid'];
      l_productimage.modifiedby = sessionStorage['userid'];
      l_productimage.createdon = new Date();
      l_productimage.modifiedon = new Date();
      l_productimage.productimage = weburl;
      this._ProductImage.push(l_productimage);
    }
    if (this._action == 'edit') {
      let time = new Date();
      let l_productimage: any = {};
      l_productimage.productid = this._productform.get('productid').value;
      l_productimage.productimagename = webimage.name;
      l_productimage.productimageurl = webimage.name;
      l_productimage.mobileproducturl = mobileimage.name;
      l_productimage.mobileproducturlname = mobileimage.name;
      l_productimage.brandid = 0;
      l_productimage.modifiedon = new Date();
      l_productimage.createdby = sessionStorage['userid'];
      l_productimage.modifiedby = sessionStorage['userid'];
      l_productimage.createdon = new Date();
      l_productimage.modifiedon = new Date();
      l_productimage.productimage = weburl;
      l_productimage.isnew = true;
      l_productimage.filename = webimage.name;
      this._ProductImages.push(l_productimage);
      this._ProductImage.push(l_productimage);
    }
    this._SelectedImage.push(this._CropImage);
    this._WebImage.push(webimage);
    this._MobileImage.push(mobileimage);
    $('.file-upload-wrapper').attr('data-text', 'Select your file!');
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this._ProductImage,
      event.previousIndex,
      event.currentIndex
    );
    this._ProductImage = [...this._ProductImage];
  }
  removeImage(product: Productimage) {
    if (this._action == 'edit') {
      let webremoveIndex = this._ProductImage.findIndex((f) => f == product);
      let mobileremoveIndex = this._ProductImage.findIndex((f) => f == product);
      if (webremoveIndex > -1) {
        this._ProductImage.splice(webremoveIndex, 1);
        this._ProductImages.splice(webremoveIndex, 1);
        this._TempProductImage.splice(webremoveIndex, 1);
      }
      if (
        this._WebImage &&
        this._WebImage.length &&
        this._MobileImage &&
        this._MobileImage.length
      ) {
        webremoveIndex = this._WebImage.findIndex(
          (f) => f.name == product.filename
        );
        mobileremoveIndex = this._MobileImage.findIndex(
          (f) => f.name == product.filename
        );
        if (webremoveIndex > -1 && mobileremoveIndex > -1) {
          this._WebImage.splice(webremoveIndex, 1);
          this._MobileImage.splice(mobileremoveIndex, 1);
        }
      }
    }
    if (this._action == 'create') {
      let webremoveIndex = this._ProductImage.findIndex((f) => f == product);
      let mobileremoveIndex = this._ProductImage.findIndex((f) => f == product);
      if (webremoveIndex > -1 && mobileremoveIndex > -1) {
        this._ProductImage.splice(webremoveIndex, 1);
        this._ProductImages.splice(webremoveIndex, 1);
        this._TempProductImage.splice(webremoveIndex, 1);
      }
      if (
        this._WebImage &&
        this._WebImage.length &&
        this._MobileImage &&
        this._MobileImage.length
      ) {
        webremoveIndex = this._WebImage.findIndex(
          (f) => f.name == product.filename
        );
        mobileremoveIndex = this._MobileImage.findIndex(
          (f) => f.name == product.filename
        );
        if (webremoveIndex == -1 && mobileremoveIndex == -1) {
          this._WebImage.splice(webremoveIndex, 1);
          this._MobileImage.splice(mobileremoveIndex, 1);
        }
      }
    }
  }
  removeImage1(productimageurl) {
    if (this._action == 'edit') {
      this._ProductImage = this._ProductImage.filter(
        (item) => item.productimageurl !== productimageurl
      );
      this._ProductImages = this._ProductImages.filter(
        (item) => item.productimageurl !== productimageurl
      );
      this._TempProductImage = this._TempProductImage.filter(
        (item) => item.productimageurl !== productimageurl
      );
      if (this._SelectedImage) {
        this._SelectedImage = this._SelectedImage.filter(
          (item) => item.name !== productimageurl
        );
      }
    }
    if (this._action == 'create') {
      this._ProductImage = this._ProductImage.filter(
        (item) => item.productimageurl !== productimageurl
      );
      if (this._SelectedImage) {
        this._SelectedImage = this._SelectedImage.filter(
          (item) => item.name !== productimageurl
        );
      }
    }
  }
  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = '';
    const possibleText: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    return date + '.' + text + '.webp';
  }
  saveImage(event) {
    var imgWidth = $('.source-image').get(0).naturalWidth;
    var imgheight = $('.source-image').get(0).naturalHeight;
    if (imgWidth >= 800 && imgheight >= 600) {
    } else {
      this._CustomExceptionService.handleWarning(
        'Your uploaded image should be minimum Width and height of 800px X 600px'
      );
    }
    this.imageCropperTool = false;
  }
  checkpricehistoryvisible() {
    if (this._Action._PriceHistory) {
      this.hidePriceHistory = false;
    } else {
      this.hidePriceHistory = true;
    }
  }
  DiscountPercentage() {
    var discount = parseFloat(
      this._productform.get('discountpercentage').value || 0
    );
    let validate = new RegExp('^[0-9]*$');
    if (
      discount <= 0 ||
      !validate.test(this._productform.get('discountpercentage').value)
    )
      this._productform.controls['discountpercentage'].setValue(0);
    if (discount > 100) {
      this._CustomExceptionService.handleError(
        'Discount Percentage should not greater than 100'
      );
      this._productform.controls['discountpercentage'].setValue(0);
      return;
    }
  }
  OnlineDiscountPercentage() {
    var discount = parseFloat(
      this._productform.get('onlinediscount').value || 0
    );
    let validate = new RegExp('^[0-9]*$');
    if (
      discount <= 0 ||
      !validate.test(this._productform.get('onlinediscount').value)
    )
      this._productform.controls['onlinediscount'].setValue(0);
    if (discount > 100) {
      this._CustomExceptionService.handleError(
        'Online discount percentage should not greater than 100'
      );
      this._productform.controls['onlinediscount'].setValue(0);
      return;
    }
  }
  ResetPageLoad() {
    if (this._action == 'create') {
      this._productform.controls['vatapplicable'].setValue(true);
      this._productform.controls['isavailable'].setValue(true);
      this._productform.get('warrantyperiod').disable();
      this._productform.get('warranty').disable();
      this._productform.get('stuffedqty').disable();
      this._productform.controls['currencyid'].setValue(
        this._currencies[0].currencyid
      );
      this._productform.controls['productstatus'].setValue('MSC00001');
      for (var i = 0; i < this._PriceDetails.length; i++) {
        this._PriceDetails[i].vatpercentageid = this._vatpercentage[0].taxid;
      }
      var l_productvariant = new Productvariant();
      l_productvariant.productid = this._productform.get('productid').value;
      l_productvariant.productvariantdetailid = 0;
      l_productvariant.modifiedon = new Date();
      l_productvariant.recordstatus = 'MSC00001';
      l_productvariant.createdby = sessionStorage['userid'];
      l_productvariant.modifiedby = sessionStorage['userid'];
      l_productvariant.createdon = new Date();
      l_productvariant.modifiedon = new Date();
      this._Productvariantdetail.push(l_productvariant);
    }
  }
  AddNewVariant(event) {
    var l_productvariant = new Productvariant();
    l_productvariant.productid = this._productform.get('productid').value;
    l_productvariant.productvariantdetailid = 0;
    l_productvariant.modifiedon = new Date();
    l_productvariant.createdby = sessionStorage['userid'];
    l_productvariant.modifiedby = sessionStorage['userid'];
    l_productvariant.createdon = new Date();
    l_productvariant.modifiedon = new Date();
    l_productvariant.recordstatus = 'MSC00001';
    this._Productvariantdetail.push(l_productvariant);
  }
  RemoveVariant(pVariant: Productvariant) {
    if (this._Productvariantdetail.length > 1) {
      var index = this._Productvariantdetail.indexOf(pVariant);
      this._Productvariantdetail.splice(index, 1);
    }
  }
  onSave() {
    this._submitted = true;
    if (
      this._productform.get('stuffed').value &&
      this._productform.get('stuffedqty').value <= 0
    ) {
      this._CustomExceptionService.handleError(usererrors.StuffedQuantity);
      this.selectedtab = 0;
      return;
    }
    if (!this._Productsupplierdetails || !this._Productsupplierdetails.length) {
      this._CustomExceptionService.handleError('Please Select Supplier Name');
      this.selectedtab = 0;
      return;
    }
    if (!this._selectedBrandIds.length) {
      this._CustomExceptionService.handleError('Please Select Brand');
      this.selectedtab = 0;
      return;
    }
    if (this._Productvariantdetail.length > 0) {
      if (this._Productvariantdetail[0].variantdescription == null) {
        this._Productvariantdetail = [];
      }
    }
    if (this._ProductBranddetails.length > 0) {
      if (this._ProductBranddetails[0].brandid == null) {
        this._ProductBranddetails = [];
      }
    }
    if (this._ProductDescriptions.length == 0) {
      if (
        this._productdescriptionform.get('shortdescription').value ||
        this._productdescriptionform.get('longdescription').value
      ) {
        var l_productdescription = new Productdescription();
        l_productdescription.productid =
          this._productform.get('productid').value;
        l_productdescription.productdescriptiondetailid =
          this._productdescriptionform.get('productdescriptiondetailid').value;
        l_productdescription.shortdescription =
          this._productdescriptionform.get('shortdescription').value;
        var longdesc =
          this._productdescriptionform.get('longdescription').value;
        l_productdescription.longdescription = longdesc;
        l_productdescription.modifiedon = new Date();
        l_productdescription.createdby = sessionStorage['userid'];
        l_productdescription.modifiedby = sessionStorage['userid'];
        l_productdescription.createdon = new Date();
        l_productdescription.modifiedon = new Date();
        this._ProductDescriptions.push(l_productdescription);
      }
    } else if (this._ProductDescriptions.length > 0) {
      this._ProductDescriptions[0].shortdescription =
        this._productdescriptionform.get('shortdescription').value;
      this._ProductDescriptions[0].longdescription =
        this._productdescriptionform.get('longdescription').value;
      this._ProductDescriptions[0].modifiedby = sessionStorage['userid'];
      this._ProductDescriptions[0].modifiedon = new Date();
    }
    if (this._ProductFAQ.length == 0) {
      if (
        this._faqform.get('question').value &&
        this._faqform.get('answer').value
      ) {
        var l_productFAQ = new Productquestionandanswer();
        l_productFAQ.productid = this._productform.get('productid').value;
        l_productFAQ.productquestionandanswerid = 0;
        l_productFAQ.question = this._faqform.get('question').value;
        l_productFAQ.answer = this._faqform.get('answer').value;
        l_productFAQ.answertype = 'ECO00022';
        l_productFAQ.answerby = this._faqform.get('answerby').value;
        l_productFAQ.modifiedon = new Date();
        l_productFAQ.createdby = sessionStorage['userid'];
        l_productFAQ.modifiedby = sessionStorage['userid'];
        l_productFAQ.createdon = new Date();
        l_productFAQ.modifiedon = new Date();
        l_productFAQ.recordstatus = this._faqform.get('recordstatus').value;
        this._ProductFAQ.push(l_productFAQ);
      }
    } else if (this._ProductFAQ.length > 0) {
      this._ProductFAQ[0].question = this._faqform.get('question').value;
      this._ProductFAQ[0].answer = this._faqform.get('answer').value;
      this._ProductFAQ[0].answerby = this._faqform.get('answerby').value;
      this._ProductFAQ[0].recordstatus =
        this._faqform.get('recordstatus').value;
      this._ProductFAQ[0].modifiedby = sessionStorage['userid'];
      this._ProductFAQ[0].modifiedon = new Date();
    } //ProductReview
    if (this._ProductReview.length == 0) {
      if (this._Productreviewform.get('reviewrating').value) {
        var l_productReview = new Productreview();
        l_productReview.productid = this._productform.get('productid').value;
        l_productReview.reviewdescription =
          this._Productreviewform.get('reviewdescription').value;
        l_productReview.reviewrating =
          this._Productreviewform.get('reviewrating').value;
        l_productReview.reviewtype = 'ECO00022';
        l_productReview.reviewby =
          this._Productreviewform.get('reviewby').value;
        l_productReview.reviewdate = new Date();
        l_productReview.createdby = sessionStorage['userid'];
        l_productReview.customerid = sessionStorage['userid'];
        l_productReview.modifiedby = sessionStorage['userid'];
        l_productReview.createdon = new Date();
        l_productReview.modifiedon = new Date();
        l_productReview.recordstatus =
          this._Productreviewform.get('recordstatus').value;
        this._ProductReview.push(l_productReview);
      }
    } else if (this._ProductReview.length > 0) {
      this._ProductReview[0].reviewrating =
        this._Productreviewform.get('reviewrating').value;
      this._ProductReview[0].reviewdescription =
        this._Productreviewform.get('reviewdescription').value;
      this._ProductReview[0].reviewby =
        this._Productreviewform.get('reviewby').value;
      this._ProductReview[0].recordstatus =
        this._Productreviewform.get('recordstatus').value;
      this._ProductReview[0].modifiedby = sessionStorage['userid'];
      this._ProductReview[0].modifiedon = new Date();
    }
    if (this._action == 'edit' && this._actionCopyproduct == true) {
      if (this._actionCopyproduct) {
        this.confirmationService.confirm({
          message: 'Save as a Copy Product',
          accept: () => {
            this._action = 'create';
            this._Copiedproduct = true;
            this.onSaveProduct();
          },
          reject: () => {
            this._action = 'edit';
          },
        });
      }
    } else {
      if (
        this._productform.get('barcode').value == '' ||
        this._productform.get('barcode').value == null ||
        this._productform.get('barcode').value == undefined
      ) {
        this.confirmationService.confirm({
          message: 'Please enter Barcode or else system will auto generate',
          accept: () => {
            this.onSaveProduct();
          },
          reject: () => {},
        });
      } else {
        this.onSaveProduct();
      }
    }
  }
  onSaveProduct() {
    var priceObj = this._PriceDetails;
    var l_PriceDetails: Productpricedetail[] = [];
    Object.keys(priceObj).map((Index) => {
      var l_pricedetails = new Productpricedetail();
      if (this._action == 'create') {
        l_pricedetails.productdetailid = 0;
      } else {
        l_pricedetails.productdetailid = priceObj[Index].productdetailid
          ? priceObj[Index].productdetailid
          : 0;
      }
      l_pricedetails.productid = this._productform.get('productid').value;
      l_pricedetails.effectiveto = new Date();
      l_pricedetails.effectivefrom = new Date();
      l_pricedetails.createdon = new Date();
      l_pricedetails.modifiedon = new Date();
      l_pricedetails.createdby = sessionStorage['userid'];
      l_pricedetails.modifiedby = sessionStorage['userid'];
      l_pricedetails.tempusdvatin = priceObj[Index].tempusdvatin;
      l_pricedetails.temprandvatin = priceObj[Index].temprandvatin;
      l_pricedetails.tempbondvatin = priceObj[Index].tempbondvatin;
      l_pricedetails.tempbankvatin = priceObj[Index].tempbankvatin;
      l_pricedetails.markuppercentage = priceObj[Index].markuppercentage;
      l_pricedetails.markuplevel = priceObj[Index].levelid;
      l_pricedetails.vatpercentageid = priceObj[Index].vatpercentageid;
      l_pricedetails.tempusd = priceObj[Index].tempusd;
      l_pricedetails.temprand = priceObj[Index].temprand;
      l_pricedetails.tempbond = priceObj[Index].tempbond;
      l_pricedetails.tempbank = priceObj[Index].tempbank;
      l_pricedetails.markuppercentage = priceObj[Index].markuppercentage;
      l_pricedetails.markuplevel = priceObj[Index].levelid;
      l_pricedetails.vatpercentageid = priceObj[Index].vatpercentageid;
      l_PriceDetails.push(l_pricedetails);
    });
    var l_SelectedCurrencies: Productcurrency[] = [];
    Object.keys(this._SelectedCurrencies || []).map((Index) => {
      var l_selectcurrency = new Productcurrency();
      l_selectcurrency.productcurrencyid = 0;
      l_selectcurrency.productid = this._productform.get('productid').value;
      l_selectcurrency.currencyid = this._SelectedCurrencies[Index];
      l_selectcurrency.createdby = parseInt(sessionStorage['userid']);
      l_selectcurrency.modifiedby = parseInt(sessionStorage['userid']);
      l_selectcurrency.createdon = new Date();
      l_selectcurrency.modifiedon = new Date();
      l_SelectedCurrencies.push(l_selectcurrency);
    });
    this._ProductSelectedCurrencies = l_SelectedCurrencies;
    if (
      !this._ProductSelectedCurrencies ||
      !this._ProductSelectedCurrencies.length
    ) {
      this._CustomExceptionService.handleError(
        'Please Select Atleast One Selling Currency'
      );
      this.selectedtab = 0;
      return;
    }
    if (this._productform.valid) {
      if (this._productsupplierdetailform.valid) {
        this._productform
          .get('productid')
          .setValue(this._productform.get('productid').value);
        this._Product = this._productform.getRawValue();
        if (this._actionCopyproduct) {
          this._Product.createdby = sessionStorage['userid'];
          this._Product.createdon = new Date();
          this._Product.copiedproduct = true;
        }
        if (this._action == 'create') {
          this._Product.createdon = new Date();
          this._Product.accountingyear = new Date().getFullYear();
          this._ProductOBJ = this.formconverstion(
            this._Product,
            this._Productsupplierdetails,
            l_PriceDetails,
            this._Productvariantdetail,
            this._LastAddedDocument,
            sessionStorage['BranchID'],
            this._SendtoApproval,
            this._ProductBranddetails,
            this._ProductDescriptions || [],
            this._ProductFAQ || [],
            this._ProductReview || [],
            this._ProductImage,
            this._WebImage,
            this._MobileImage,
            this._ProductSelectedCurrencies || []
          );
          this._IsProgressSpinner = true;
          this._ProductService.create(this._ProductOBJ).subscribe(
            (result) => {
              const resultJSON = JSON.parse(JSON.stringify(result));
              if (
                resultJSON.tranStatus.result == true &&
                (resultJSON != null || resultJSON != undefined)
              ) {
                this._CustomExceptionService.handleSuccess(
                  usererrors.Saved_Success_00
                );
                this.reset(null);
                this._submitted = false;
                this._vatpercentage = this._tempvatpercentage;
                this.ResetPageLoad();
                this._IsProgressSpinner = false;
              } else {
                this._CustomExceptionService.handleError(
                  resultJSON.tranStatus.lstErrorItem[0].message
                );
                this._IsProgressSpinner = false;
              }
            },
            (error) => {
              this._CustomExceptionService.handleError(error);
              this._IsProgressSpinner = false;
            }
          );
        } else if (this._action == 'edit') {
          this._Product.modifiedon = new Date();
          this._Product.modifiedby = sessionStorage['userid'];
          this._ProductOBJ = this.formconverstion(
            this._Product,
            this._Productsupplierdetails,
            l_PriceDetails,
            this._Productvariantdetail,
            this._LastAddedDocument,
            sessionStorage['BranchID'],
            this._SendtoApproval,
            this._ProductBranddetails,
            this._ProductDescriptions,
            this._ProductFAQ,
            this._ProductReview,
            this._ProductImage,
            this._WebImage,
            this._MobileImage,
            this._ProductSelectedCurrencies || []
          );
          this._IsProgressSpinner = true;
          this._ProductService.edit(this._ProductOBJ).subscribe(
            (result) => {
              const resultJSON = JSON.parse(JSON.stringify(result));
              if (
                resultJSON.tranStatus.result == true &&
                (resultJSON != null || resultJSON != undefined)
              ) {
                if (resultJSON.tranStatus.lstErrorItem) {
                  this._CustomExceptionService.handleSuccess(
                    resultJSON.tranStatus.lstErrorItem[0].message
                  );
                  this._submitted = false;
                } else {
                  this._CustomExceptionService.handleSuccess(
                    usererrors.Update_Success_04
                  );
                  this._submitted = false;
                }
              } else {
                this._CustomExceptionService.handleError(
                  resultJSON.tranStatus.lstErrorItem[0].message
                );
              }
              this._IsProgressSpinner = false;
            },
            (error) => {
              this._CustomExceptionService.handleError(error);
              this._IsProgressSpinner = false;
            }
          );
        }
      } else {
        this._CustomExceptionService.handleWarning(
          'Please fill Product Details Tab'
        );
        this.selectedtab = 1;
        return;
      }
    } else {
      this._CustomExceptionService.handleWarning(
        'Please fill Product Details Tab'
      );
      this.selectedtab = 0;
      return;
    }
  }
  formconverstion(
    p_product: Productheader,
    p_Productsupplierdetails: Supplierproductdetail[],
    p_PriceDetails: Productpricedetail[],
    p_Productvariantdetail: Productvariant[],
    document: File,
    p_branchid,
    p_sendtoapproval,
    p_productbrand: Productbrandmapping[],
    p_productdescriptions: Productdescription[],
    p_productquestionandanswer: Productquestionandanswer[],
    p_productreview: Productreview[],
    p_productimages: Productimage[],
    _WebImage: File[],
    _MobileImage: File[],
    _SelectedCurrencies: Productcurrency[]
  ) {
    let time = new Date();
    const formData: FormData = new FormData();
    if (this._action == 'edit') {
      if (_WebImage) {
        for (var x in _WebImage) {
          var index = parseInt(x);
          if (index != -1) {
            var webFileName = time.getTime() + '_' + _WebImage[x].name;
            var mobileFileName = time.getTime() + '_' + _MobileImage[x].name;
            let webindex = p_productimages.findIndex(
              (f) => f.productimageurl == _WebImage[index].name
            );
            let mobileindex = p_productimages.findIndex(
              (f) => f.mobileproducturl == _MobileImage[index].name
            );
            p_productimages[webindex].productimagename = webFileName;
            p_productimages[webindex].productimageurl = webFileName;
            p_productimages[mobileindex].mobileproducturl = mobileFileName;
            p_productimages[mobileindex].mobileproducturlname = mobileFileName;
            formData.append('webfile', _WebImage[x], webFileName);
            formData.append('mobilefile', _MobileImage[x], mobileFileName);
          }
        }
      }
    }
    if (this._action == 'create') {
      for (var x in _WebImage) {
        var index = parseInt(x);
        if (index != -1) {
          var webFileName = time.getTime() + '_' + _WebImage[x].name;
          var mobileFileName = time.getTime() + '_' + _MobileImage[x].name;
          p_productimages[index].productimagename = webFileName;
          p_productimages[index].productimageurl = webFileName;
          p_productimages[index].mobileproducturl = mobileFileName;
          p_productimages[index].mobileproducturlname = mobileFileName;
          formData.append('webfile', _WebImage[x], webFileName);
          formData.append('mobilefile', _MobileImage[x], mobileFileName);
        }
      }
    }
    p_productimages.forEach((element, index) => {
      element.imageorder = index + 1;
    });
    this._ProductOBJ = {
      Productheader: p_product,
      Productsupplierdetail: p_Productsupplierdetails,
      Productpricedetail: p_PriceDetails,
      Productvariant: p_Productvariantdetail,
      Branchid: p_branchid,
      SendtoApproval: p_sendtoapproval,
      Productbrand: p_productbrand,
      Productdescription: p_productdescriptions,
      Productquestionandanswer: p_productquestionandanswer,
      Productreview: p_productreview,
      Productimages: p_productimages,
      ProductCurrencies: _SelectedCurrencies,
    };
    this._ProductOBJlist = this._ProductOBJ;
    var product = JSON.stringify(this._ProductOBJ);
    formData.append('strProduct', product);
    return formData;
  }
  onSelectSuppliers(event) {
    var l_Productsupplierdetails: Supplierproductdetail[] = [];
    var l_Productsupplierdetail: Supplierproductdetail;
    var l_supplierlist: Supplier = this._suppliernames;
    Object.keys(event.value).map((Index) => {
      l_Productsupplierdetail = new Supplierproductdetail();
      l_Productsupplierdetail.supplierproductdetailid = 0;
      l_Productsupplierdetail.supplierid = event.value[Index];
      l_Productsupplierdetail.producttypeid =
        this._productform.get('producttypeid').value;
      l_Productsupplierdetail.productcategoryid =
        this._productform.get('productcategoryid').value;
      l_Productsupplierdetail.productid =
        this._productform.get('productid').value;
      l_Productsupplierdetail.createdon = new Date();
      l_Productsupplierdetail.createdby = sessionStorage['userid'];
      l_Productsupplierdetail.modifiedby = sessionStorage['userid'];
      l_Productsupplierdetail.modifiedon = new Date();
      l_Productsupplierdetails.push(l_Productsupplierdetail);
    });
    this._Productsupplierdetails = l_Productsupplierdetails;
  }
  onSelectBrands(event) {
    var l_Productbranddetails: Productbrandmapping[] = [];
    var l_Productbranddetail: Productbrandmapping;
    var l_brandlist: Brand = this._brandnames;
    Object.keys(event.value).map((Index) => {
      l_Productbranddetail = new Productbrandmapping();
      l_Productbranddetail.productid = this._productform.get('productid').value;
      l_Productbranddetail.brandid = event.value[Index];
      l_Productbranddetail.productbrandmapid = 0;
      l_Productbranddetail.createdon = new Date();
      l_Productbranddetail.createdby = sessionStorage['userid'];
      l_Productbranddetail.modifiedby = sessionStorage['userid'];
      l_Productbranddetail.modifiedon = new Date();
      l_Productbranddetails.push(l_Productbranddetail);
    });
    this._ProductBranddetails = l_Productbranddetails;
  }
  OnSelectProductCurrencies(event) {
    var l_SelectedCurrencies: Productcurrency[] = [];
    Object.keys(event.value || []).map((Index) => {
      var l_selectcurrency = new Productcurrency();
      l_selectcurrency.productcurrencyid = 0;
      l_selectcurrency.productid = this._productform.get('productid').value;
      l_selectcurrency.currencyid = event.value[Index];
      l_selectcurrency.createdby = parseInt(sessionStorage['userid']);
      l_selectcurrency.modifiedby = parseInt(sessionStorage['userid']);
      l_selectcurrency.createdon = new Date();
      l_selectcurrency.modifiedon = new Date();
      l_SelectedCurrencies.push(l_selectcurrency);
    });
    this._ProductSelectedCurrencies = l_SelectedCurrencies;
  }
  onEd() {
    ace.config.set('fontSize', '14px');
    ace.config.set(
      'basePath',
      'https://unpkg.com/ace-builds@1.4.12/src-noconflict'
    );
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode('ace/mode/json');
    var myObj = this._ProductOBJlist;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  reset(event) {
    this._productform.reset();
    this._PriceDetails = this._TempPriceDetails;
    for (var i = 0; i < this._PriceDetails.length; i++) {
      this._PriceDetails[i].markuppercentage = null;
      this._PriceDetails[i].rand = 0;
      this._PriceDetails[i].usd = 0;
      this._PriceDetails[i].bond = 0;
      this._PriceDetails[i].bank = 0;
      this._PriceDetails[i].randvatin = 0;
      this._PriceDetails[i].usdvatin = 0;
      this._PriceDetails[i].bondvatin = 0;
      this._PriceDetails[i].bankvatin = 0;
      this._PriceDetails[i].temprand = 0;
      this._PriceDetails[i].tempusd = 0;
      this._PriceDetails[i].tempbond = 0;
      this._PriceDetails[i].tempbank = 0;
      this._PriceDetails[i].temprandvatin = 0;
      this._PriceDetails[i].tempusdvatin = 0;
      this._PriceDetails[i].tempbondvatin = 0;
      this._PriceDetails[i].tempbankvatin = 0;
    }
    this._productpricedetailform.reset();
    this._productvariantform.reset();
    this._Productvariantdetail = [];
    this.InitializeForm();
    this._submitted = false;
  }
  goBack(event) {
    this._router.navigate(['/vProduct']);
  }
  fillsupplierproductdetails() {}
  checkPurchaseandSalesUOM(event) {
    this._SendtoApproval = true;
    var puchaseuom = this._productform.get('salesuom').value;
    if (
      this._productform.get('purchaseuom').value &&
      this._productform.get('salesuom').value
    ) {
      if (
        this._productform.get('purchaseuom').value !=
        this._productform.get('salesuom').value
      ) {
        // if (this._productform.get("stuffedqty").value > 0) {
        // 	this._productform.get("stuffed").disable({ onlySelf: true });
        // 	if (this._productform.get("stuffed").value) {
        // 		this._productform.get("stuffedqty").enable({ onlySelf: true });
        // 		var purchaseprice = this._productform.get("purchaserate").value ? this._productform.get("purchaserate").value : 0;
        // 		if (purchaseprice > 0) {
        // 			this.onKeyupRatechange();
        // 		}
        // 	} else {
        // 		this._productform.get("stuffedqty").enable({ onlySelf: true });
        // 		this._CustomExceptionService.handleWarning("Please give Stuffed Quantity");
        // 	}
        // } else {
        // 	this._productform.get("stuffed").disable({ onlySelf: true });
        // 	this._productform.get("stuffed").setValue(true);
        // 	this._productform.get("stuffedqty").enable({ onlySelf: true });
        // }
      } else {
        this._productform.get('stuffed').enable({ onlySelf: true });
        this._productform.get('stuffed').setValue(false);
        this._productform.get('stuffedqty').disable({ onlySelf: true });
        this._productform.controls['stuffedqty'].setValue(null);
        var purchaseprice = this._productform.get('purchaserate').value
          ? this._productform.get('purchaserate').value
          : 0;
        if (purchaseprice > 0) {
          this.onKeyupRatechange();
        }
      }
    }
  }
  getProductCategory(event) {
    var selectedCategory = event.value;
    this._ProductService.getProductCategory(selectedCategory).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategories = resultJSON.productcategory;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
      }
    );
  }
  getSupplier(event) {
    this._productsupplierdetailform.controls['supplierid'].reset();
    var purchasefrom = this._productform.get('purchasefrom').value;
    this._ProductService.getSupplier(purchasefrom).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._suppliernames = resultJSON.suppliers;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
      }
    );
  }
  disableWarrenty(event) {
    this._warrantyEnable = this._productform.get('warrantyapplicable').value; // this flag should be set correctly based on value from database when it selected for update
    if (this._warrantyEnable == true) {
      this._productform.get('warranty').enable({ onlySelf: true });
      this._productform.get('warrantyperiod').enable({ onlySelf: true });
    } else {
      this._productform.get('warranty').disable({ onlySelf: true });
      this._productform.get('warrantyperiod').disable({ onlySelf: true });
      this._productform.controls['warranty'].setValue(null);
      this._productform.controls['warrantyperiod'].setValue(null);
    }
  }
  disableStuffedQty(event) {
    this._StuffedEnable = this._productform.get('stuffed').value; // this flag should be set correctly based on value from database when it selected for update
    if (this._StuffedEnable == true) {
      this._productform.get('stuffedqty').enable({ onlySelf: true });
    } else {
      this._productform.get('stuffedqty').disable({ onlySelf: true });
      this._productform.controls['stuffedqty'].setValue(null);
    }
  }
  checkMinimumandMaximumLevel(event) {
    if (
      this._productform.get('minimumlevel').value ||
      this._productform.get('minimumlevel').value > 0
    ) {
      if (
        this._productform.get('minimumlevel').value >
          this._productform.get('maximumlevel').value &&
        this._productform.get('maximumlevel').value > 0
      ) {
        this._CustomExceptionService.handleWarning(
          'Maximum level should be greater than minimum level'
        );
        this._productform.get('maximumlevel').setValue(null);
      }
    }
  }
  checkReorderLevel(event) {
    this.checkMinimumandMaximumLevel(null);
    if (
      this._productform.get('reorderlevel').value ||
      this._productform.get('reorderlevel').value > 0
    ) {
      if (
        (this._productform.get('minimumlevel').value ||
          this._productform.get('minimumlevel').value > 0) &&
        (this._productform.get('maximumlevel').value ||
          this._productform.get('maximumlevel').value > 0)
      ) {
        if (
          this._productform.get('minimumlevel').value >
            this._productform.get('reorderlevel').value ||
          this._productform.get('reorderlevel').value >
            this._productform.get('maximumlevel').value
        ) {
          this._CustomExceptionService.handleWarning(
            'Reorder level should between minimum and maximum level'
          );
          this._productform.get('reorderlevel').setValue(null);
        }
      }
    }
  }
  onEnterofStuffedQty(event) {
    var purchaseprice = this._productform.get('purchaserate').value
      ? this._productform.get('purchaserate').value
      : 0;
    if (purchaseprice > 0) {
      this.onKeyupRatechange();
    } else {
      this._CustomExceptionService.handleWarning('Please set Purchase Rate');
    }
  }
  calculateUnitPrice() {
    var purchaseprice = this._productform.get('purchaserate').value
      ? this._productform.get('purchaserate').value
      : 0;
    if (purchaseprice > 0) {
      var purchasepriceUSD = this._productform.get('purchaserateinusd').value
        ? this._productform.get('purchaserateinusd').value
        : 0;
      if (purchasepriceUSD == 0)
        this._CustomExceptionService.handleWarning(
          'Purchase price in USD not calcuated'
        );
      var isStuffed = this._productform.get('stuffed').value;
      var stuffedqty = this._productform.get('stuffedqty').value;
      stuffedqty = stuffedqty <= 1 ? 1 : stuffedqty; //stuffed qty is 0 or null or lessthan 1 , default it with 1
      if (purchasepriceUSD != 0 && isStuffed == true) {
        this._unitPrice = purchasepriceUSD / stuffedqty;
      } else {
        this._unitPrice = purchasepriceUSD;
      }
    } else {
      this._CustomExceptionService.handleWarning('Please set Purchase Rate');
    }
  }
  UpdateUnitPrice() {}
  activateMenu(tab: TabView) {}
  setPurchasepriceUSD(event) {
    var PurchaseCurrencyid = this._productform.get('currencyid').value;
    var purchaseprice = this._productform.get('purchaserate').value
      ? this._productform.get('purchaserate').value
      : 0;
    if (purchaseprice > 0) {
      if (PurchaseCurrencyid != undefined || PurchaseCurrencyid != null) {
        var PurchaseCurrencyExchangeRate = this._currencies.find(
          (obj) => obj.currencyid == PurchaseCurrencyid
        ).exchangerate;
        var pruchasePrice =
          (this._productform.get('purchaserate').value
            ? this._productform.get('purchaserate').value
            : 1) /
          (PurchaseCurrencyExchangeRate ? PurchaseCurrencyExchangeRate : 1);
        this._productform
          .get('purchaserateinusd')
          .setValue(pruchasePrice.toFixed(3));
        this.onKeyupRatechange();
      } else {
        this._CustomExceptionService.handleWarning('Please select Currency');
        return;
      }
    } else {
    }
  }
  onKeyupSellingPrice(pPriceDetails: Productpricedetail, event: any) {
    this._SendtoApproval = true;
    pPriceDetails.effectivefrom = new Date();
    pPriceDetails.effectiveto = new Date();
    pPriceDetails.modifiedon = new Date();
    pPriceDetails.createdon = new Date();
    pPriceDetails.modifiedby = sessionStorage['userid'];
    pPriceDetails.productid = 0;
    this.calculateUnitPrice();
    var currencies: Currency[] = this._currencies;
    var PurchaseCurrencyid = this._productform.get('currencyid').value;
    var usdExchangeRate = currencies.find(
      (obj) => obj.currencyid == 1
    ).exchangerate;
    var randExchangeRate = currencies.find(
      (obj) => obj.currencyid == 2
    ).exchangerate;
    var bondExchangeRate = currencies.find(
      (obj) => obj.currencyid == 3
    ).exchangerate;
    var bankExchangeRate = currencies.find(
      (obj) => obj.currencyid == 4
    ).exchangerate;
    if (this._IsVatParcentage) {
      var DefaultVAT = this._vatpercentage.find((obj) => obj.taxcode == 'VAT')
        .taxpercentage
        ? this._vatpercentage.find((obj) => obj.taxcode == 'VAT').taxpercentage
        : 1;
      var VAT = DefaultVAT;
      var Taxid = pPriceDetails.vatpercentageid
        ? pPriceDetails.vatpercentageid
        : 0;
      if (Taxid != 0)
        VAT = this._vatpercentage.find(
          (obj) => obj.taxid == pPriceDetails.vatpercentageid
        ).taxpercentage;
    }
    var valueInUSD =
      (usdExchangeRate ? usdExchangeRate : 1) *
      (this._unitPrice ? this._unitPrice : 0);
    var markuplevel = pPriceDetails.markuppercentage
      ? pPriceDetails.markuppercentage
      : 0;
    var valueInUSDwithMarkupPercentage =
      (markuplevel / 100) * valueInUSD + valueInUSD;
    pPriceDetails.tempusd = parseFloat(
      valueInUSDwithMarkupPercentage.toFixed(2)
    );
    var valueInUSDwithVatPercentage =
      valueInUSDwithMarkupPercentage * (VAT / 100);
    pPriceDetails.tempusdvatin = parseFloat(
      (valueInUSDwithMarkupPercentage + valueInUSDwithVatPercentage).toFixed(2)
    );
    var valueInBOND =
      (bondExchangeRate ? bondExchangeRate : 1) *
      (this._unitPrice ? this._unitPrice : 0);
    var markuplevel = pPriceDetails.markuppercentage
      ? pPriceDetails.markuppercentage
      : 0;
    var valueInBONDwithMarkupPercentage =
      (markuplevel / 100) * valueInBOND + valueInBOND;
    pPriceDetails.tempbond = parseFloat(
      valueInBONDwithMarkupPercentage.toFixed(2)
    );
    var valueInBONDwithVatPercentage =
      valueInBONDwithMarkupPercentage * (VAT / 100);
    pPriceDetails.tempbondvatin = parseFloat(
      (valueInBONDwithMarkupPercentage + valueInBONDwithVatPercentage).toFixed(
        2
      )
    );
    var valueInBANK =
      (bankExchangeRate ? bankExchangeRate : 1) *
      (this._unitPrice ? this._unitPrice : 0);
    var markuplevel = pPriceDetails.markuppercentage
      ? pPriceDetails.markuppercentage
      : 0;
    var valueInBANKwithMarkupPercentage =
      (markuplevel / 100) * valueInBANK + valueInBANK;
    pPriceDetails.tempbank = parseFloat(
      valueInBANKwithMarkupPercentage.toFixed(2)
    );
    var valueInBANKwithVatPercentage =
      valueInBANKwithMarkupPercentage * (VAT / 100);
    pPriceDetails.tempbankvatin = parseFloat(
      (valueInBANKwithMarkupPercentage + valueInBANKwithVatPercentage).toFixed(
        2
      )
    );
    var valueInrand =
      (randExchangeRate ? randExchangeRate : 1) *
      (this._unitPrice ? this._unitPrice : 0);
    var markuplevel = pPriceDetails.markuppercentage
      ? pPriceDetails.markuppercentage
      : 0;
    var valueInrandwithMarkupPercentage =
      (markuplevel / 100) * valueInrand + valueInrand;
    pPriceDetails.temprand = parseFloat(
      valueInrandwithMarkupPercentage.toFixed(2)
    );
    var valueInrandwithVatPercentage =
      valueInrandwithMarkupPercentage * (VAT / 100);
    pPriceDetails.temprandvatin = parseFloat(
      (valueInrandwithMarkupPercentage + valueInrandwithVatPercentage).toFixed(
        2
      )
    );
  }
  onKeyupRatechange() {
    this._SendtoApproval = true;
    for (var i = 0; i < this._PriceDetails.length; i++) {
      this._PriceDetails[i].effectivefrom = new Date();
      this._PriceDetails[i].effectiveto = new Date();
      this._PriceDetails[i].modifiedon = new Date();
      this._PriceDetails[i].createdon = new Date();
      this._PriceDetails[i].modifiedby = sessionStorage['userid'];
      this._PriceDetails[i].productid = 0;
      this.calculateUnitPrice();
      var currencies: Currency[] = this._currencies;
      var PurchaseCurrencyid = this._productform.get('currencyid').value;
      var usdExchangeRate = currencies.find(
        (obj) => obj.currencyid == 1
      ).exchangerate;
      var randExchangeRate = currencies.find(
        (obj) => obj.currencyid == 2
      ).exchangerate;
      var bondExchangeRate = currencies.find(
        (obj) => obj.currencyid == 3
      ).exchangerate;
      var bankExchangeRate = currencies.find(
        (obj) => obj.currencyid == 4
      ).exchangerate;
      if (this._IsVatParcentage) {
        var DefaultVAT = this._vatpercentage.find((obj) => obj.taxcode == 'VAT')
          .taxpercentage
          ? this._vatpercentage.find((obj) => obj.taxcode == 'VAT')
              .taxpercentage
          : 1;
        var VAT = DefaultVAT;
        var Taxid = this._PriceDetails[i].vatpercentageid
          ? this._PriceDetails[i].vatpercentageid
          : 0;
        if (Taxid != 0)
          VAT = this._vatpercentage.find(
            (obj) => obj.taxid == this._PriceDetails[i].vatpercentageid
          ).taxpercentage;
      }
      var valueInUSD =
        (usdExchangeRate ? usdExchangeRate : 1) *
        (this._unitPrice ? this._unitPrice : 0);
      var markuplevel = this._PriceDetails[i].markuppercentage
        ? this._PriceDetails[i].markuppercentage
        : 0;
      var valueInUSDwithMarkupPercentage =
        (markuplevel / 100) * valueInUSD + valueInUSD;
      this._PriceDetails[i].tempusd = parseFloat(
        valueInUSDwithMarkupPercentage.toFixed(2)
      );
      var valueInUSDwithVatPercentage =
        valueInUSDwithMarkupPercentage * (VAT / 100);
      this._PriceDetails[i].tempusdvatin = parseFloat(
        (valueInUSDwithMarkupPercentage + valueInUSDwithVatPercentage).toFixed(
          2
        )
      );
      var valueInZIM1 =
        (bondExchangeRate ? bondExchangeRate : 1) *
        (this._unitPrice ? this._unitPrice : 0);
      var markuplevel = this._PriceDetails[i].markuppercentage
        ? this._PriceDetails[i].markuppercentage
        : 0;
      var valueInZIM1withMarkupPercentage =
        (markuplevel / 100) * valueInZIM1 + valueInZIM1;
      this._PriceDetails[i].tempbond = parseFloat(
        valueInZIM1withMarkupPercentage.toFixed(2)
      );
      var valueInZIM1withVatPercentage =
        valueInZIM1withMarkupPercentage * (VAT / 100);
      this._PriceDetails[i].tempbondvatin = parseFloat(
        (
          valueInZIM1withMarkupPercentage + valueInZIM1withVatPercentage
        ).toFixed(2)
      );
      var valueInZIM2 =
        (bankExchangeRate ? bankExchangeRate : 1) *
        (this._unitPrice ? this._unitPrice : 0);
      var markuplevel = this._PriceDetails[i].markuppercentage
        ? this._PriceDetails[i].markuppercentage
        : 0;
      var valueInZIM2withMarkupPercentage =
        (markuplevel / 100) * valueInZIM2 + valueInZIM2;
      this._PriceDetails[i].tempbank = parseFloat(
        valueInZIM2withMarkupPercentage.toFixed(2)
      );
      var valueInZIM2withVatPercentage =
        valueInZIM2withMarkupPercentage * (VAT / 100);
      this._PriceDetails[i].tempbankvatin = parseFloat(
        (
          valueInZIM2withMarkupPercentage + valueInZIM2withVatPercentage
        ).toFixed(2)
      );
      var valueInrand =
        (randExchangeRate ? randExchangeRate : 1) *
        (this._unitPrice ? this._unitPrice : 0);
      var markuplevel = this._PriceDetails[i].markuppercentage
        ? this._PriceDetails[i].markuppercentage
        : 0;
      var valueInrandwithMarkupPercentage =
        (markuplevel / 100) * valueInrand + valueInrand;
      this._PriceDetails[i].temprand = parseFloat(
        valueInrandwithMarkupPercentage.toFixed(2)
      );
      var valueInZIM2withVatPercentage =
        valueInrandwithMarkupPercentage * (VAT / 100);
      this._PriceDetails[i].temprandvatin = parseFloat(
        (
          valueInrandwithMarkupPercentage + valueInZIM2withVatPercentage
        ).toFixed(2)
      );
    }
  }
  ProductPriceDetailSearch() {
    this._effectiveFrom = this._effectiveFrom
      ? this._effectiveFrom
      : new Date();
    this._effectiveTo = this._effectiveTo ? this._effectiveTo : new Date();
    var productid = this._productform.get('productid').value;
    if (productid == undefined || productid == null || productid == 0) {
      this._CustomExceptionService.handleWarning(
        'Please select Product in the view screen'
      );
      return;
    }
    // if (this._selectedlevelId == undefined || this._selectedlevelId == null) {
    // 	this._CustomExceptionService.handleWarning("Please select level");
    // 	return;
    // }
    let data = {
      fromdate: this._effectiveFrom,
      todate: this._effectiveTo,
      productid: productid,
    };
    this._ProductService.getPriceHistory(data).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._PriceHistories = resultJSON.priceHistoryview;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
      }
    );
  }
  showdocumentDialog(document: FileUpload, position1: string) {
    if (this._LastAddedDocument) {
      this.productimage = this._DomSanitizationService.bypassSecurityTrustUrl(
        URL.createObjectURL(this._LastAddedDocument)
      );
      this.position1 = position1;
      this.displaydocumentpopup = true;
    } else if (this.productimage) {
      this.position1 = position1;
      this.displaydocumentpopup = true;
    }
  }
  onDocumentSelect(event, document) {
    this._LastAddedDocument = event.currentFiles[0];
    this.imageselect = this._LastAddedDocument.name;
    this._productform
      .get('productimageurlname')
      .setValue(this._LastAddedDocument.name);
    document.clear();
  }
  onDocumentClear(document: FileUpload) {
    this._productform.get('productimageurl').setValue(undefined);
    this._productform.get('productimageurlname').setValue(undefined);
    document.clear();
    this.productimage = null;
    this._LastAddedDocument = null;
  }
  showPopupDialog(position: string) {
    this.position = position;
    this.displaydocumentpopup = false;
  }
  ClearFields() {
    this._effectiveFrom = new Date();
    this._effectiveTo = new Date();
    this._PriceHistories = [];
  }
  onImageClear() {
    this._Iscreatefileupload = true;
    this._Isviewfileupload = false;
    this.productimage = null;
    this._LastAddedImage = null;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+s',
          (event: KeyboardEvent): boolean => {
            this.onSave();
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    if (this._action == 'create') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+alt+c',
          (event: KeyboardEvent): boolean => {
            this.reset(null);
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    this._hotkeysService.add(
      new Hotkey(
        'ctrl+left',
        (event: KeyboardEvent): boolean => {
          this.goBack(null);
          return false; // Prevent bubbling
        },
        ['INPUT', 'SELECT', 'TEXTAREA']
      )
    );
  }
  filterProducts(event) {
    if (event.query == '' || event.query == undefined) {
      return;
    } else {
      this._IsProgressSpinner = true;
      this._Copiedproduct = true;
      this._ProductObj = {
        product: event.query || [],
        branchid: this._branchid || 0,
      };
      this._ProductService.GetProduct(this._ProductObj).subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._ProductNames =
            <Productoverallview[]>resultJSON.productsearchlist || [];
          this._tempproductname =
            <Productoverallview[]>resultJSON.productsearchlist || [];
          let filtered: any[] = [];
          let query = (<string>event.query).toLowerCase();
          this.filteredProducts =
            this._ProductNames.filter(
              (f) => f.searchfilter.toLowerCase().indexOf(query) > -1
            ) || [];
          this._IsProgressSpinner = false;
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
        }
      );
    }
  }
  GetProductDetails(event) {
    var productid = event.productid;
    this._Productid = productid;
    this._action = 'edit';
    this._actionCopyproduct = true;
    this.ngOnInit();
  }
}
