import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { DeliverynoteService } from 'src/app/core/Services/inventory/deliverynote.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-delivery-challan',
  templateUrl: './v-delivery-challan.component.html',
  styleUrls: ['./v-delivery-challan.component.css']
})
export class VDeliveryChallanComponent implements OnInit {
  serviceReqGrid: any[];
  jobCardListGrid: any[];
  _Action: IAction;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _IsProgressSpinner: boolean = true;
  _branchid = sessionStorage["currentbranchid"];
  _userid = sessionStorage["userid"];
  deliverynoteid:number;
  cols:any[];
  constructor(private fb: FormBuilder, private _DeliverynoteService: DeliverynoteService, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService, private router: Router, private utility: CommonUtilities, private _hotkeysService: HotkeysService, private confirmationService: ConfirmationService, private exportUtility: ExportUtility) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(164);
    this.utility.pageLoadScript();
    this.HotKeyIntegration();
    this.cols = [
      { field: 'suppliername', header: 'Supplier Name' },
      { field: 'returntypename', header: 'Return Type Name' },
      { field: 'deliverynotestatusname', header: 'Delivery Note Status' },
      { field: 'approvalstatusname', header: 'Approved Status' },
     
    ]
    // this.jobCardListGrid = [
    //   { jobCardID: 'JC00000002', deliverTo: 'Supplier 04', returnDCStatus: 'Yes' },
    //   { jobCardID: 'JC00000001', deliverTo: 'Supplier 02', returnDCStatus: 'Yes' }
    // ]
    this.Fetchall();
  }
  Fetchall() {
    this._IsProgressSpinner = true;
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = sessionStorage["userid"];
    this._DeliverynoteService.FetchAll(this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.jobCardListGrid = resultJSON.deliverynoteviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  //View
  view(event) {
    this.router.navigate(['/cDeliveryNote'], { state: { deliverynoteid: event.deliverynoteid, action: 'view' } });
  }

  //Edit 
  edit(event) {
    this.router.navigate(['/cDeliveryNote'], { state: { deliverynoteid: event.deliverynoteid, action: 'edit' } });
  }

  //Change Status
  confirm(event) {
    this.deliverynoteid = event.deliverynoteid
    this.confirmationService.confirm({
      message: 'Are you sure that you want to Cancel  this Delivery Note for this <b>"' + event.suppliername + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._DeliverynoteService.Cancel(this._userid,this.deliverynoteid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess("Delivery note cancelled successfully");       
      this._IsProgressSpinner = false;
      this.Fetchall();
      }
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GoToCreate(event) {
    this.router.navigate(['/cDeliveryNote']);
  }

  //PDF,EXCEL
  exportExcel() {
    let finalizedList = this.GetDeliveryNoteList();
    this.exportUtility.exportExcel(finalizedList, 'DeliveryNote', 'xlsx');
  }

  ExportToPdf() {
    let finalizedList = this.GetDeliveryNoteList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Supplier Name',
      'Return Type Name',
      'Delivery Note Status',
      'Approved Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('DeliveryNote.pdf');
      })
    })
  }
 
  GetDeliveryNoteList() {
    let DeliveryNoteList = [];
    if (!this.jobCardListGrid || !this.jobCardListGrid.length)
    return;
    this.jobCardListGrid.forEach(element => {
      let quotes: any = {};
      quotes["Supplier Name"] = element.suppliername;
      quotes["Return Type Name"] = element.returntypename;
      quotes["Delivery Note Status"] = element.deliverynotestatusname;
      quotes["Approved Status"] = element.approvalstatusname;
      DeliveryNoteList.push(quotes);
    });
    return DeliveryNoteList;
  }
    //View Shortcut keys
    HotKeyIntegration() {
      this._hotkeysService.reset();
      if (this._Action._Add) {
        this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
          this.GoToCreate(event);
          return false; // Prevent bubbling
        }, ['INPUT', 'SELECT', 'TEXTAREA']));
      }
    }
}
