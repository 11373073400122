import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { IAction } from '../security/IActions';
@Injectable({
  providedIn: 'root'
})
export class TaxreconcilationService {
  public _Action: IAction;
  public _TaxreconcilationService = environment.TaxreconcilationService;
  public _httpOptions;
  public _PettycashList: [] = [];
  constructor(
    private http: HttpClient,
    private _CustomExceptionService: CustomExceptionService
  ) { }
  PageOnLoad(p_Taxreconcilationid: number): Observable<any> {
    var Query = this._TaxreconcilationService + '/' + p_Taxreconcilationid;
   
    return this.http.get<{ data: any }>(Query);
  }
  FetchAll(userid:number,branchid: number,roleid:number,fromdate:string,todate:string) {
    var Query = this._TaxreconcilationService + '/FetchAll/' + userid+'/'+branchid+'/'+roleid+'/'+fromdate+'/'+todate;
   
    return this.http.get<{ data: any }>(Query, this._httpOptions).pipe(map(data => {
      const resultJSON = JSON.parse(JSON.stringify(data));
      console.log(resultJSON);
      return data;
    }));
  }

  getTaxDetails(p_branchid: number, p_Month: number, p_Year: number) {
    console.log("getTaxDetails");
    var Query = this._TaxreconcilationService + '/getTaxDetails/' + p_branchid + '/' + p_Year + '/' + p_Month;
    return this.http.get<{ data: any }>(Query);
  }


  create(branchid: number, year: number, month: number, createdBy: number, todate: Date) {
    let datePipe = new DatePipe("en-Us");
    let date = datePipe.transform(todate);
    var Query = this._TaxreconcilationService + '/Create/' + branchid + '/'+ year + '/'+ month + '/'+ createdBy + '/'+ date;
    return this.http.get<{ data: any }>(Query);
  }
  Cancel(journalid: number) {
    console.log("cancel");
    var Query = this._TaxreconcilationService + '/' + journalid;
   
    return this.http.get<{ data: any }>(Query);
  }
}
