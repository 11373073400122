import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class StockvaluereportService {

  public _Action: IAction;
  public _httpOptions;
  public _RptStockValueReportService = environment.RptStockValueReportService;
  constructor(private http: HttpClient) { }

  PageOnload(): Observable<any> {
    var Query = this._RptStockValueReportService + '/PageInitialize';
    
    return this.http.get<{ data: any }>(Query);

  }
  getProductCategory(producttype:object) {
    var Query = this._RptStockValueReportService+'/getProductCategories';
    
    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory:object) {
    var Query = this._RptStockValueReportService + '/getProducts';
    
    return this.http.post<{ data: any }>(Query, productcategory);
  }
  
  Print(salesreport:object) {
    var Query = this._RptStockValueReportService ;
    
    return this.http.post(Query,salesreport, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
}
GetProductSearch(product: string,branchid:number) {
  var Query = this._RptStockValueReportService + '/GetProductSearch' + '/' + product + '/'+branchid;
  
  return this.http.get<{ data: any }>(Query);
}

}
