import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApprovalconfigurationcorporateService } from 'src/app/core/Services/users/approvalconfigurationcorporate.service';
import { Documenttypeworkflowview } from 'src/app/core/Views/Approvalcorporateview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { ConfirmationService } from 'primeng/api';
import { Session } from 'inspector';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-approvalconfigurationcorporate',
  templateUrl: './v-approvalconfigurationcorporate.component.html',
  styleUrls: ['./v-approvalconfigurationcorporate.component.css']
})
export class VApprovalconfigurationcorporateComponent implements OnInit {
  _workflowdetailviews: Documenttypeworkflowview[]=[];
  _workflowdetailview: Documenttypeworkflowview;
  selectedrows: any;
  pSelectableRow: any;
  _workflowid: number;
  _stageid:number;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _IsProgressSpinner: boolean = true;
  _Action: IAction;
  _userid = sessionStorage["userid"];
  cols:any[];
  constructor(private fb: FormBuilder, private _ApprovalconfigurationcorporateService: ApprovalconfigurationcorporateService, private router: Router, private utility: CommonUtilities,
    private _hotkeysService: HotkeysService, private _CustomExceptionService: CustomExceptionService, private _AccessRightsService: AccessRightsService,
    private confirmationService: ConfirmationService,private exportUtility: ExportUtility) {
    //Go To Create Page
    //  this._hotkeysService.add(new Hotkey('alt+n', (event: KeyboardEvent): boolean => {
    //   this.GoToCreate(event);
    //   return false; // Prevent bubbling
    // }));
    this._Action = this._AccessRightsService.getAccessRights(36);
    this.HotKeyIntegration();
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  ngOnInit(): void {

    this.utility.pageLoadScript();
    this.cols = [
      { field: 'documenttypename', header: 'Document Type Name' },
      { field: 'workflowname', header: 'WorkFlow Name' },
      { field: 'stageid', header: 'Stage ID' },
      { field: 'stagename', header: 'Stage Name' },
      { field: 'stagestatusname', header: 'Stage Status' },
     
    ]
   
    this._IsProgressSpinner = true;

    this._ApprovalconfigurationcorporateService.PageOnLoad(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // 17.so that the fetched record is for update process
      this._workflowdetailviews = resultJSON.documenttypeworkflowstageviews;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }
  view(event) {
    this.router.navigate(['/cApprovalconfigurationcorporate'], { state: { workflowid: event.workflowid, documenttypeid: event.documenttypeid, action: 'view' } });
  }
  delete(event) {
    alert("delete");
  }
  edit(event) {
    this.router.navigate(['/cApprovalconfigurationcorporate'], { state: { workflowid: event.workflowid, documenttypeid: event.documenttypeid, action: 'edit' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cApprovalconfigurationcorporate']);
  }
  confirm(event) {
    this._workflowid= event.workflowid
    this._stageid = event.stageid
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Approval Corporate <b>"' + event.workflowname + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._ApprovalconfigurationcorporateService.Cancel(this._workflowid,this._stageid,this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
        this._ApprovalconfigurationcorporateService.PageOnLoad(this._userid).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._workflowdetailviews = resultJSON.documenttypeworkflowstageviews;
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      this._IsProgressSpinner = false;
      }
    this._IsProgressSpinner = false;
  },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let finalizedList = this.GetFinalizedList();
    this.exportUtility.exportExcel(finalizedList, 'Approval Corporate', 'xlsx');
  }

  ExportToPdf() {
    let finalizedList = this.GetFinalizedList();
    this.exportUtility.ExportToPdf(finalizedList, 'Approval Corporate.pdf');
  }

  GetFinalizedList() {
    let finalizedList = [];
    this._workflowdetailviews.forEach(element => {
      let quotes: any = {};
      quotes["Document Type Name"] = element.documenttypename;
      quotes["WorkFlow Name"] = element.workflowname;
      quotes["Stage ID"] = element.stageid;
      quotes["Stage Name"] = element.stagename;
      quotes["Stage Status"] = element.stagestatusname;
      finalizedList.push(quotes);
    });
    return finalizedList;
  }
}
