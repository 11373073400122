import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { PaymentgatewayService } from 'src/app/core/Services/ecommerce/paymentgateway.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { vEffectiveFrom, vEffectiveTo, vSelectOrderStatus } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-payment-history',
  templateUrl: './v-payment-history.component.html',
  styleUrls: ['./v-payment-history.component.css']
})
export class VPaymentHistoryComponent implements OnInit {
  _paymenttransactionform: FormGroup;
  paymentHistoryGrid: any[];
  PaymentStatus: Metadatum;
  PaymentGatewayObj: any;
  _IsProgressSpinner: boolean = false;
  _submitted = false;
  datepipe = new DatePipe('en-US');
  cols: any[];
  constructor(private fb: FormBuilder, private router: Router,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _PaymnetHIstoryService: PaymentgatewayService, private _CustomExceptionService: CustomExceptionService, private exportUtility: ExportUtility) { }
  InitializePaymnetTransactionForm() {
    this._paymenttransactionform = this.fb.group({
      fromdate: ['', vEffectiveFrom],
      todate: ['', vEffectiveTo],
      paymentstatus: ['', vSelectOrderStatus]
    });
    this._paymenttransactionform.get("fromdate").setValue(new Date());
    this._paymenttransactionform.get("todate").setValue(new Date());
    this._paymenttransactionform.get("paymentstatus").setValue("Paid");
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.cols = [
      { field: 'transactionid', header: 'Transaction ID ' },
      { field: 'orderno', header: 'Order No.' },
      { field: 'amountinusd', header: 'Amount (in USD)' },
      { field: 'paymentdate', header: 'Payment Date & Time' },
      { field: 'paymentmode', header: 'Payment Mode' },
      { field: 'paymentstatus', header: 'Payment Status' },
      { field: 'customername', header: 'Customer Name' },
    ]
    this.InitializePaymnetTransactionForm();
    this._IsProgressSpinner = true;
    this._PaymnetHIstoryService.PageOnload().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // console.log(resultJSON);
      this.PaymentStatus = resultJSON.paymentgatewaystatus;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  search() {
    this._submitted = true;
    let fromdate = this._paymenttransactionform.get("fromdate").value;
    let todate = this._paymenttransactionform.get("todate").value;
    let convertfromdate = this.datepipe.transform(fromdate, 'yyyy-MM-dd');
    let converttodate = this.datepipe.transform(todate, 'yyyy-MM-dd');
    let paymentstatus = this._paymenttransactionform.get("paymentstatus").value;
    this._IsProgressSpinner = true;
    this.PaymentGatewayObj = { "fromdate": convertfromdate, "todate": converttodate, "paymentstatus": paymentstatus };
    this._PaymnetHIstoryService.SearchPaymentTransaction(this.PaymentGatewayObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.paymentHistoryGrid = resultJSON.paymentgatewaydetails;
      // console.log(resultJSON);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear() {
    this._submitted = true;
    this.InitializePaymnetTransactionForm();
    this.paymentHistoryGrid = [];
  }

  //Export PDF,Excel
  exportExcel() {
    if (!this.paymentHistoryGrid || !this.paymentHistoryGrid.length)
      return;
    let OrderStatusList = this.GetPaymentTranscationsList();
    this.exportUtility.exportExcel(OrderStatusList, 'PaymentTranscations', 'xlsx');
  }
  
  ExportToPdf() {
    if (!this.paymentHistoryGrid || !this.paymentHistoryGrid.length)
      return;
    let PaymentTranscations = this.GetPaymentTranscationsList();
    const data = PaymentTranscations.map(Object.values);
    const head = [['Transaction ID', 'Order No.', 'Amount (in USD)', 'Payment Date & Time', 'Payment Mode','Payment Status','Customer Name']]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('PaymentTranscations.pdf');
      })
    })

  }

  GetPaymentTranscationsList() {
    if (!this.paymentHistoryGrid || !this.paymentHistoryGrid.length)
      return;
    let PaymentTranscations = [];
    this.paymentHistoryGrid.forEach(element => {
      let paymenttranscation: any = {};
     paymenttranscation["Transaction ID"] = element.transactionid;
     paymenttranscation["Order No."] = element.orderno;
     paymenttranscation["Amount (in USD)"] = element.amountinusd;
     paymenttranscation["Payment Date & Time"] = this.datepipe.transform(element.paymentdate, 'dd/MM/yyyy  HH:mm:ss a');
     paymenttranscation["Payment Mode"] = element.paymentmode;
     paymenttranscation["Payment Status"] = element.paymentstatus;
     paymenttranscation["Customer Name"] = element.customername;
     PaymentTranscations.push(paymenttranscation);
    });
    return PaymentTranscations;
  }
}
