import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { PurchaseServiceInvoiceService } from 'src/app/core/Services/workshop/purchase-service-invoice.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-c-purchase-service-invoice',
  templateUrl: './c-purchase-service-invoice.component.html',
  styleUrls: ['./c-purchase-service-invoice.component.css']
})
export class CPurchaseServiceInvoiceComponent implements OnInit {
  _action: string = "create";
  _submitted = false;
  _IsProgressSpinner: boolean = true;
  _IsSaveDisable: boolean = false;
  _servicerequestno: any
  _servicerequest: any
  Date: any
  Serviceinvoicecode: any
  Customername: string
  workordercode: string
  requestcode: string
  Currency: string
  _serviceinvoicedetails: any[]
  serviceinvoiceid: any
  Purchaseinvoicecode: any
  PurchaseDate: any
  _OtherCharges: any[] = []
  selectedOtherChargesRows: any[] = []
  vatAmount: string
  ocvalue: string
  netamount: string
  serviceamount: string
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _route: ActivatedRoute,
    private _serviceProductDeliveryService: PurchaseServiceInvoiceService,
    private _router: Router,
    private utility: CommonUtilities,
    private _hotkeysService: HotkeysService
  ) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._IsProgressSpinner = false;
    let serviceinvoice = history.state?.serviceinvoiceid ?? 0;

    this._action = history.state?.action ?? this._action;
    let obj = {
      "PurchaseServiceInvoiceNo": serviceinvoice,
    }
    this._IsProgressSpinner = true;
    this._serviceProductDeliveryService.PageOnLoad(obj).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        console.log(result, 'objectes');
        this._servicerequestno = result.purchaseServiceInvoiceHeaderList
        this._serviceinvoicedetails = result.purchaseServiceInvoiceDetailList
        this._OtherCharges = result.purchaseServiceInvoiceOtherChargeDetailList
        if (this._servicerequestno.length) {
          this.Date = new Date(this._servicerequestno[0].purchaseserviceinvoicedate);
          this.Serviceinvoicecode = this._servicerequestno[0].purchaseserviceinvoiceno;
          this.Customername = this._servicerequestno[0].suppliername;
          this.workordercode = this._servicerequestno[0].serviceworkorderno;
          this.requestcode = this._servicerequestno[0].servicerequestno;
          this.Purchaseinvoicecode = this._servicerequestno[0].jobcardno;
          this.PurchaseDate = new Date(this._servicerequestno[0].jobcarddate);
          this.Currency = this._servicerequestno[0].currencyname;
          this.vatAmount=this._servicerequestno[0].vatamount
          this.ocvalue=this._servicerequestno[0].ocvalue
          this.serviceamount=this._servicerequestno[0].approximateamountinusd
          this.netamount=this._servicerequestno[0].netamount 
        }
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
        this._IsProgressSpinner = false;
      }
    },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  goBack() {
    this._router.navigate(['/VPurchaseServiceInvoice']);
  }


}
