export class Damagedgetproductpriceview  {
    productdetailid: number;
    productid: number;
    productname: string;
    markuplevelid: number;
    markuplevelname: string;
    vatpercentageid: number;
    markuppercentage: number;
    effectivefrom: Date;
    effectiveto: Date;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    usd: number;
    rand: number;
    bond: number;
    bank: number ;
    tempUsd: number ;
    tempRand: number ;
    tempBond: number;
    tempBank: number;
    damagedproductpricestatusid:number;
    damagedunitprice:number;
    accountingyear:number;
    severity:number;
    currencyid:number;
    currencyname:string;
    taxid:number;
    taxpercentage:number;

}