export class Shippingchargeskmbasedcost {
    sckmid: number;
    scid: number;
    minkm: number ;
    maxkm: number ;
    currencyid: number ;
    normaldeliverycost: number ;
    expressdeliverycost: number ;
    maxallowedweightatfreeofcost: number ;
   additionalcostperkmexceed: number ;
    recordstatus: string;
    createdby: number ;
    createdon: string  ;
    modifiedby: number ;
    modifiedon: string  ;
    isdisabledremove:boolean;
}