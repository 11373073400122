import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Serviceproductdelivery } from 'src/app/core/Models/serviceproductdelivery';
import { Serviceproductdeliverydetail } from 'src/app/core/Models/serviceproductdeliverydetail';
import { Serviceproductdeliveryotherchargedetail } from 'src/app/core/Models/Serviceproductdeliveryotherchargedetail';
import { ServiceProductDeliveryService } from 'src/app/core/Services/workshop/service-product-delivery.service';
import { vPaymentAmount, vPaymentDateSelect, vSelectAccountgroup } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-c-service-product-delivery',
  templateUrl: './c-service-product-delivery.component.html',
  styleUrls: ['./c-service-product-delivery.component.css']
})
export class CServiceProductDeliveryComponent implements OnInit {
  _action: string = "create";
  _submitted = false;
  _IsProgressSpinner: boolean = true;
  _IsSaveDisable: boolean = false;
  _serviceproductdeliveryform: FormGroup;
  _servicerequestno: any
  _servicerequest: any
  _serviceproductdeliverydetails: any[]
  isDisabledsendapparoval: boolean = true
  isexternal: boolean = false
  _customer: any
  _currency: any
  vattype: any
  _othercharges: any
  deliveryOBJ: Serviceproductdelivery
  _OtherCharges: any[] = []
  selectedOtherChargesRows: any[] = []
  _Descriptions: any[] = []
  isDisabledother: boolean = false
  isDisabledremove: boolean = false
  mindate: Date;
	maxdate: Date;
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _route: ActivatedRoute,
    private _serviceProductDeliveryService: ServiceProductDeliveryService,
    private _router: Router,
    private utility: CommonUtilities,
    private _hotkeysService: HotkeysService
  ) { }
  InitializeFormGroup(): void {
    this._serviceproductdeliveryform = this.fb.group({
      serviceproductdeliveryid: [0],
      serviceproductdeliveryno: [],
      serviceproductdeliverydate: [(new Date()), vPaymentDateSelect],
      branchid: [],
      customerid: ['', vSelectAccountgroup],
      servicerequestno: ['', vSelectAccountgroup],
      serviceworkorderno: [],
      invoiceno: [],
      invoicedate: [],
      approximateamountinusd: ['', vPaymentAmount],
      vatid: [],
      vatamount: [],
      currencyid: [1, vSelectAccountgroup],
      currencyuniqerefid: [],
      ocid: [],
      ocvalue: [],
      netamount: [],
      remarks: [],
      status: ['MSC00001'],
      createdby: [sessionStorage["userid"]],
      createdon: [new Date],
      modifiedby: [],
      modifiedon: [],
      serviceproductdeliverydetails: [],
      serviceproductdeliveryotherchargedetails: [],
    });
    if(sessionStorage['Environmentenddate'] == 'null'){
			this._serviceproductdeliveryform.get('serviceproductdeliverydate').setValue(new Date)
		}
		else{
			let date = new Date(sessionStorage['Environmentenddate'])
			this._serviceproductdeliveryform.get('serviceproductdeliverydate').setValue(date);
		}
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeFormGroup();
    this._IsProgressSpinner = false;
    let jobcard = history.state?.serviceproductdeliveryid ?? 0;
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._action = history.state?.action ?? this._action;
    this._serviceproductdeliveryform.controls["branchid"].setValue(sessionStorage["BranchID"],)
    let obj = {
      "ServiceProductDeliveryId": jobcard,
      "branchid": sessionStorage["BranchID"],
    }
    this._IsProgressSpinner = true;
    this._serviceProductDeliveryService.PageOnLoad(obj).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        console.log(result, 'objectes');
        this._servicerequestno = result.serviceRequestList;
        this._customer = result.customerList
        this._currency = result.currencyList
        this.vattype = result.taxList
        this._othercharges = result.otherChargeList
        this._Descriptions = result.otherChargeList;
        var l_othercharge = new Serviceproductdeliveryotherchargedetail();
        l_othercharge.Serviceproductdeliveryotherchargedetailid = 0;
        l_othercharge.Serviceproductdeliveryid = 0;
        l_othercharge.ocvalue = 0;
        this._OtherCharges.push(l_othercharge);
        if (this._action == 'create') {
          this.getversion();
          this._serviceproductdeliveryform.get('ocvalue').disable();
        }
        if (this._action == 'edit' || this._action == 'view') {
          result.serviceproductdelivery.serviceproductdeliverydate = new Date(result.serviceproductdelivery.serviceproductdeliverydate);
          result.serviceproductdelivery.invoicedate = new Date(result.serviceproductdelivery.invoicedate);
          this._servicerequest = result.getServiceRequestNoListView;
          this._serviceproductdeliveryform.setValue(result.serviceproductdelivery);
          this._serviceproductdeliverydetails = result.serviceproductdeliverydetail;
          this._OtherCharges = result.serviceproductdeliveryotherchargedetail;
        }
        if (this._action == 'view') {
          this._serviceproductdeliveryform.disable();
          this._IsSaveDisable = true
          this.isDisabledother = true
          this.isDisabledremove = true
        }
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
        this._IsProgressSpinner = false;
      }
    },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  AddOtherCharges(event) {
    let otherChargesExists = (this._OtherCharges.filter(s => s.ocid > 0).length == this._OtherCharges.length) && this._OtherCharges.some(s => s.ocid > 0);
    if (!otherChargesExists)
      return;
    var l_othercharge = new Serviceproductdeliveryotherchargedetail();
    l_othercharge.Serviceproductdeliveryotherchargedetailid = 0;
    l_othercharge.Serviceproductdeliveryid = 0;
    l_othercharge.ocvalue = 0;
    this._OtherCharges.push(l_othercharge);
  }
  RemoveOtherCharges(pOthercharges: Serviceproductdeliveryotherchargedetail) {
    var index = this._OtherCharges.indexOf(pOthercharges);
    this._OtherCharges.splice(index, 1)
    this.netcalculation();
    if (this._OtherCharges.length <= 0) {
      var l_othercharge = new Serviceproductdeliveryotherchargedetail();
      l_othercharge.Serviceproductdeliveryotherchargedetailid = 0;
      l_othercharge.Serviceproductdeliveryid = 0;
      l_othercharge.ocvalue = 0;
      this._OtherCharges.push(l_othercharge);
    }
  }
  OnchangeOtherCharge(pOtherCharges: Serviceproductdeliveryotherchargedetail, event: any) {
    let isAlreadyExists = this._OtherCharges.filter(s => s.ocid == event.value).length > 1;
    if (isAlreadyExists) {
      this._CustomExceptionService.handleWarning("Other Charges Already Exists!");
      var index = this._OtherCharges.indexOf(pOtherCharges);
      this._OtherCharges.splice(index, 1)
      return;
    }
    let OCValue = (this._Descriptions || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount;
    pOtherCharges.ocvalue = OCValue;
    pOtherCharges.ocid = event.value;
    this.netcalculation()
  }
  OnChangeotherCharge(row: any, event) {
    let OtherChargesAmount = row.ocvalue || 0;
    parseFloat(OtherChargesAmount)
    this.netcalculation()
  }
  GetServiceRequestNo(event) {
    this._serviceproductdeliveryform.get('servicerequestno').reset();
    var customer = this._serviceproductdeliveryform.get('customerid').value;
    if (!customer) {
      this._CustomExceptionService.handleWarning("Please Select Customer Name");
      return
    }
    let data = {
      "CustomerId": customer,
      "branchid": sessionStorage["BranchID"],
    }
    this._IsProgressSpinner = true;
    this._serviceProductDeliveryService.GetServiceRequestNo(data).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        console.log("srno", result);
        this._servicerequest = result.getServiceRequestNoList;
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
        this._IsProgressSpinner = false;
      }
    },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  Getinvoiceno() {
    var requestno = this._serviceproductdeliveryform.get('servicerequestno').value;
    var numbers = this._servicerequest.filter(f => f.servicerequestnumber == requestno)[0]
    this._serviceproductdeliveryform.controls['invoiceno'].setValue(numbers.invoiceno)
    this._serviceproductdeliveryform.controls['serviceworkorderno'].setValue(numbers.serviceworkorderno)
    this._serviceproductdeliveryform.controls['invoicedate'].setValue(new Date(numbers.invoicedate))


    let data = {
      "ServiceRequestNo": requestno,

    }
    this._IsProgressSpinner = true;
    this._serviceProductDeliveryService.GetServiceRequestNoDetail(data).subscribe((result: any) => {
      this._IsProgressSpinner = false;
      if (result && result.tranStatus.result) {
        console.log("detail", result);
        this._serviceproductdeliverydetails = result.getServiceRequestNoDetailList;
        let SPD=result.getServiceRequestNoDetailList
        this._serviceproductdeliveryform.get("approximateamountinusd").setValue(SPD[0].approximateamountinusd);
        this._serviceproductdeliveryform.get("vatid").setValue(SPD[0].vatid);
        this._serviceproductdeliveryform.get("vatamount").setValue(SPD[0].vatamount);
        this._serviceproductdeliveryform.get("ocvalue").setValue(SPD[0].ocvalue);
        this._serviceproductdeliveryform.get("netamount").setValue(SPD[0].netamount);
        this._OtherCharges = result.getServiceRequestNoOCDetailList;
      }
      else {
        this._CustomExceptionService.handleWarning(result.tranStatus.lstErrorItem[0].Message);
        this._IsProgressSpinner = false;
      }
    },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );

  }
  getversion() {
    var currency = this._serviceproductdeliveryform.get('currencyid').value;
    var version = this._currency.filter(f => f.currencyid == currency)[0].currencyuniquerefid
    this._serviceproductdeliveryform.controls['currencyuniqerefid'].setValue(version)
  }
  allocatePaymentAmount() { // amount CAlculation
    var amount = 0.00
    amount = this._serviceproductdeliveryform.get('approximateamountinusd').value
    var currencyId = this._serviceproductdeliveryform.get('currencyid').value
    if (!currencyId) {
      this._CustomExceptionService.handleWarning("Please Select Currency");
      return
    }
    this.netcalculation();
  }
  getvat() { // vat CAlculation
    this.netcalculation();
  }
  getchargesid() {
    var id = this._serviceproductdeliveryform.get('ocid').value;
    if (!id) {
      return
    }
    this._serviceproductdeliveryform.get('ocvalue').enable();
    var amount = this._othercharges.filter(f => f.otherchargeid == id)[0].otherchargeamount
    this._serviceproductdeliveryform.controls["ocvalue"].setValue(amount);
    this.getothercharges();
  }

  getothercharges() { // vat CAlculation
    this.netcalculation()
  }

  ////Calculation
  netcalculation() {
    var cost: number = 0, percentage: number = 0, vatamount: number = 0, otherchargesamount: number = 0, netamount: number = 0
    cost = this._serviceproductdeliveryform.get('approximateamountinusd').value;
    if (!cost) {
      this._CustomExceptionService.handleWarning(" Please Entre Service Cost");
      return
    }
    var taxid = this._serviceproductdeliveryform.get('vatid').value;
    if (taxid) {
      percentage = this.vattype.filter(f => f.taxid == taxid)[0].taxpercentage
    }
    vatamount = (cost * percentage / 100)
    this._serviceproductdeliveryform.controls['vatamount'].setValue(vatamount.toFixed(2))
    for (let i = 0; i < this._OtherCharges.length; i++) {
      otherchargesamount += this._OtherCharges[i].ocvalue;
    }
    this._serviceproductdeliveryform.get('ocvalue').setValue(otherchargesamount)
    netamount = (cost + vatamount + otherchargesamount)
    this._serviceproductdeliveryform.get('netamount').setValue(netamount)
  }
  onSave() {
    this._submitted = true;
    if (!this._serviceproductdeliveryform.valid) return;
    this.deliveryOBJ = this._serviceproductdeliveryform.getRawValue();
    var Effectivefrom = this._serviceproductdeliveryform.get("serviceproductdeliverydate").value;
    var Effectiveto = this._serviceproductdeliveryform.get("invoicedate").value;
    var datePipe = new DatePipe("en-US");
    var peffectivefrom = datePipe.transform(Effectivefrom, 'yyyy-MM-dd');
    var peffectiveto = datePipe.transform(Effectiveto, 'yyyy-MM-dd');
    this.deliveryOBJ.serviceproductdeliverydate = (new Date(peffectivefrom))
    this.deliveryOBJ.invoicedate = (new Date(peffectiveto))
    this.deliveryOBJ.modifiedon = new Date();
    this.deliveryOBJ.modifiedby = sessionStorage["userid"];
    this.deliveryOBJ.branchid = sessionStorage["BranchID"];


    if (this.deliveryOBJ.ocid) {
      if (!this.deliveryOBJ.ocvalue || this.deliveryOBJ.ocvalue <= 0) {
        this._CustomExceptionService.handleWarning("Please Ensure Other charges value");
        return
      }
    }

    var Tempdeliverydetail = []
    for (var i = 0; i < this._serviceproductdeliverydetails.length; i++) {
      var deliverydetail = new Serviceproductdeliverydetail();
      deliverydetail.serviceproductdeliverydetailid = this._serviceproductdeliverydetails[i].serviceproductdeliverydetailid || 0;
      deliverydetail.serviceproductdeliveryid = this._serviceproductdeliverydetails[i].jobcardid || 0;
      deliverydetail.productid = this._serviceproductdeliverydetails[i].productid;
      deliverydetail.uom = this._serviceproductdeliverydetails[i].uom;
      deliverydetail.unitprice = this._serviceproductdeliverydetails[i].unitprice;
      deliverydetail.finalamount = this._serviceproductdeliverydetails[i].finalamount;
      deliverydetail.srquantity = this._serviceproductdeliverydetails[i].srquantity;
      deliverydetail.deliveryquantity = this._serviceproductdeliverydetails[i].deliveryquantity;
      Tempdeliverydetail.push(deliverydetail);
    }
    let OBJ = {
      "Serviceproductdelivery": this.deliveryOBJ,
      "Serviceproductdeliverydetail": Tempdeliverydetail,
      "Serviceproductdeliveryotherchargedetail": this._OtherCharges,
    }

    this._IsProgressSpinner = true;
    if (this._action == 'create') {

      this._serviceProductDeliveryService.create(OBJ).subscribe((result) => {
        this._IsProgressSpinner = false;
        const resutJSON = JSON.parse(JSON.stringify(result));

        if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
          this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
          this.reset();
        }
        else {
          this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          this._IsProgressSpinner = false;
          this._IsSaveDisable = false;
        }
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  reset() {
    this._submitted = false
    this._serviceproductdeliveryform.reset();
    this.InitializeFormGroup();
    this._serviceproductdeliverydetails = [];
    this._OtherCharges = [];
    this.ngOnInit();
  }
  goBack() {
    this._router.navigate(['/VServiceProductDelivery']);
  }
}
