import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountClosureDetail } from 'src/app/core/Models/Accountclosingdetail';
import { AccountclosingService } from 'src/app/core/Services/accounts/accountclosing.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
//Debug
import * as ace from "ace-builds";
import { vMonthSelect } from 'src/app/core/Validators/validation';
import { AccountClosure } from 'src/app/core/Models/Accountclosing';
import { DatePipe } from '@angular/common';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
@Component({
  selector: 'app-c-account-closing',
  templateUrl: './c-account-closing.component.html',
  styleUrls: ['./c-account-closing.component.css']
})
export class CAccountClosingComponent implements OnInit {
  _IsSaveHidden: boolean = false;
  _IsClearHidden: boolean = false;
  _IsSearchHidden: boolean = false;
  datepipe = new DatePipe("en-Us");
  minDate: Date;
  maxDate: Date;
  _accountclosingform: FormGroup;
  _Accountsclosing: AccountClosureDetail[] = [];
  selectedrows: any;
  _id: number;
  _submitted = false;
  _action: string = "create";
  _userid = sessionStorage["userid"];
  _AccountClosingObj: any;
  _AccountClosing: AccountClosure;
  _TotalOpeningBalance = 0;
  _TotalClosingBalance = 0;
  _TotalCreditAmount = 0;
  _TotalDebitAmount = 0;
  _IsProgressSpinner: boolean = false;

  //Debug
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _router: Router, private utility: CommonUtilities, private _AccountClosingService: AccountclosingService, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService) {

  }

  InitializeForm() {
    this._accountclosingform = this.fb.group({
      id: [0],
      month: [(new Date()), vMonthSelect],
      openingbalance: [0],
      closingbalance: [0],
      approvalstatus: [],
      approvalremarks: [],
      approvedon: [],
      createdon: [],
      createdby: [],
      modiefiedby: [],
      modiefiedon: [],
      accountclosurestatusid: [],
      accountclosurestatusdesc: [],
      branchid: [0],
      creditamount: [0],
      debitamount: [0],
      accountClosureDetails: []
    });
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();

    this.setMinMaxDate();
    this._id = history.state?.id ? history.state?.id : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this.InitializeForm();
    this.HotKeyIntegration();
    if (this._action == 'view') {
      this._accountclosingform.disable();
      this._IsProgressSpinner = true;
      this._AccountClosingService.ViewPageLoad(this._id).subscribe((result) => {
        const updateJSON = JSON.parse(JSON.stringify(result));
        console.log(updateJSON);
        updateJSON.accountclosingbalanceheader.month = new Date(updateJSON.accountclosingbalanceheader.month);
        //this._accountclosingform.setValue(updateJSON.accountclosingbalanceheader);
        this._accountclosingform.get("month").setValue(updateJSON.accountclosingbalanceheader.month);
        this._accountclosingform.get("openingbalance").setValue(updateJSON.accountclosingbalanceheader.openingbalance);
        this._accountclosingform.get("closingbalance").setValue(updateJSON.accountclosingbalanceheader.closingbalance);
        this._accountclosingform.get("creditamount").setValue(updateJSON.accountclosingbalanceheader.credit);
        this._accountclosingform.get("debitamount").setValue(updateJSON.accountclosingbalanceheader.debit);

        this._Accountsclosing = updateJSON.searchViewReloadList;
        this._IsProgressSpinner = false;
        this.Visiblity(true);
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  SearchMonthWise() {
    this._submitted = true;
    this._userid = sessionStorage["userid"];
    var Month = this._accountclosingform.get("month").value;
    let date = this.datepipe.transform(Month, "yyyy-MM-dd");
    Month = new Date(date);
    this._Accountsclosing = [];
    this._IsProgressSpinner = true;
    this._AccountClosingService.Search(this._userid, Month).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        console.log(resultJSON);
        this._Accountsclosing = resultJSON.searchViewList || [];
        this._IsProgressSpinner = false;
        this._TotalOpeningBalance = 0;
        this._TotalClosingBalance = 0;
        (this._Accountsclosing || []).every((item) => {
          for (var i = 0; i < this._Accountsclosing.length; i++) {
            this._TotalOpeningBalance += this._Accountsclosing[i].openingbalance;
            this._TotalClosingBalance += this._Accountsclosing[i].closingbalance;
            this._TotalCreditAmount += this._Accountsclosing[i].credit;
            this._TotalDebitAmount += this._Accountsclosing[i].debit;
          }
          this._accountclosingform.controls['openingbalance'].setValue(this._TotalOpeningBalance);
          this._accountclosingform.controls['closingbalance'].setValue(this._TotalClosingBalance);
          this._accountclosingform.controls['debitamount'].setValue(this._TotalDebitAmount);
          this._accountclosingform.controls['creditamount'].setValue(this._TotalCreditAmount);
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });


  }

  onRowSelect(event) {
    console.log("eventfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onRowUnselect(event) {
    console.log("eventUnfired");
    console.log(event.data);
    console.log(this.selectedrows);
  }
  onSave() {
    // console.log("Submitted");
    // console.log(this._action);
    this._submitted = true;
    if (this._accountclosingform.valid) {
      //For Model and Form Mapping
      this._AccountClosing = this._accountclosingform.value;
      var Month = this._accountclosingform.get("month").value;
      let date = this.datepipe.transform(Month, "yyyy-MM-dd");
      Month = new Date(date);
      this._AccountClosing.month = Month;
      if (this._Accountsclosing.length <= 0)
        return;
      if (this._action == 'create') {
        this._AccountClosing.createdby = sessionStorage["userid"];
        this._AccountClosing.createdon = new Date();
        this._AccountClosing.modiefiedby = 0;
        this._AccountClosing.accountClosureDetails = this._Accountsclosing;
        //this.onEd();
        this.Visiblity(true);
        this._IsProgressSpinner = true;
        this._AccountClosingService.create(this._AccountClosing, this._userid).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._IsProgressSpinner = false;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.reset(null);
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this.Visiblity(false);
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this.Visiblity(false); this._IsProgressSpinner = false; });
      }
    }
  }


  // on clicking back button , it Navigates to the search screen
  goBack(event) {
    //alert('This');
    console.log("goBack");
    this._router.navigate(['/vAccountClosing']);
  }
  reset(event) {
    this._accountclosingform.reset();
    this.InitializeForm();
    this._Accountsclosing = [];
    this._submitted = false;
  }
  //Debug
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._AccountClosing;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);

  }

  Visiblity(ishide: boolean) {
    this._IsSaveHidden = ishide;
    this._IsClearHidden = ishide;
    this._IsSearchHidden = ishide;
  }

  setMinMaxDate() {
    let date = new Date();
    let previousDate = new Date(date.getFullYear(), date.getMonth() - 1);
    this.minDate = previousDate;
    this.maxDate = date;
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
        this.onSave();
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    if (this._action == 'create') {
      this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
        this.reset(null);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
    this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
      this.goBack(null);
      return false; // Prevent bubbling
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }
}
