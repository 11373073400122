import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAction } from '../../../security/IActions';

@Injectable({
  providedIn: 'root'
})
export class RptpurchaseorderdetailreportService {

  public _Action: IAction;
  public _httpOptions;
  public _RptPurchaseOrderDetailReport = environment.RptPurchaseOrderDetailReport;
  constructor(private http: HttpClient) { }

  
  PageOnload(userid:number): Observable<any> {
    var Query = this._RptPurchaseOrderDetailReport+'/PageInitialize'+'/'+userid;
    
    return this.http.get<{ data: any }>(Query);

  }
  getProductCategory(producttype:object) {
    var Query = this._RptPurchaseOrderDetailReport+'/getProductCategories';
    
    return this.http.post<{ data: any }>(Query, producttype);
  }
  getProduct(productcategory:object) {
    var Query = this._RptPurchaseOrderDetailReport+'/getProducts';
    
    return this.http.post<{ data: any }>(Query, productcategory);
  }
  
  Print(salesreport:object) {
    var Query = this._RptPurchaseOrderDetailReport ;
    
    return this.http.post(Query,salesreport, { responseType: 'blob' });
    //window.open($scope.PDFURL, '_blank', 'location=yes,height=570,width=800,scrollbars=yes,status=yes');
    //return this.http.get<{ data: any }>(Query);
  }

}
