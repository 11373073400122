import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/core/Services/users/role.service';
import { Roleview } from 'src/app/core/Views/Roleview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ConfirmationService } from 'primeng/api';
import { ExportUtility } from 'src/assets/js/export-utility';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-v-role',
  templateUrl: './v-role.component.html',
  styleUrls: ['./v-role.component.css']
})
export class VRoleComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _rolesview: any[] = [];
  _Roleview: Roleview;
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  _roleid: number;
  _userid = sessionStorage["userid"];
  datepipe = new DatePipe('en-US');
  constructor(private fb: FormBuilder, private _RoleService: RoleService, private router: Router, private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService, private _hotkeysService: HotkeysService, private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService, private exportUtility: ExportUtility) {
    this._Action = this._AccessRightsService.getAccessRights(32);
    this.HotKeyIntegration();
    //  //Go To Create Page
    //  this._hotkeysService.add(new Hotkey('alt+n', (event: KeyboardEvent): boolean => {
    //   this.GoToCreate(event);
    //   return false; // Prevent bubbling
    // }));
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  ngOnInit(): void {

    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;
    this._RoleService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._rolesview = resultJSON.roleview;
      this._IsProgressSpinner = false;
      // this._Add = this._CityService._Action._Add;
      // this._delete = this._CityService._Action._Delete;
      // this._update = this._CityService._Action._Update;
      // this._View = this._CityService._Action._View;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onRowSelect(event) {
  }
  onRowUnselect(event) {
  }

  view(event) {
    this.router.navigate(['/cRole'], { state: { roleid: event.roleid, action: 'view' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cRole']);
  }
  delete(event) {

  }

  edit(event) {

    this.router.navigate(['/cRole'], { state: { roleid: event.roleid, action: 'edit' } });
  }
  confirm(event) {
    this._roleid = event.roleid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this Role <b>"' + event.rolename + '"</b> ?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._RoleService.Cancel(this._roleid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_11);
        this._RoleService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._rolesview = resultJSON.roleview;
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    this.exportUtility.exportExcel(TermsAndConditionList, 'Role', 'xlsx');
  }
  ExportToPdf() {
    let TermsAndConditionList = this.GetTermsAndConditionList();
    const data = TermsAndConditionList.map(Object.values);
    const head = [[
      'Role Name',
      'Effective From',
      'Effective To',
      'Status'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Role.pdf');
      })
    })
  }
  GetTermsAndConditionList() {
    let TermsAndConditionList = [];
    this._rolesview.forEach(element => {
      let TermsAndCondition: any = {};
      TermsAndCondition["Role Name"] = element.rolename;
      TermsAndCondition["Effective From"] = this.datepipe.transform(element.effectivefrom, 'dd/MM/yyyy');
      if (!element.effectiveto) {
        TermsAndCondition["Effective To"] = '';
      } else {
        TermsAndCondition["Effective To"] = this.datepipe.transform(element.effectiveto, 'dd/MM/yyyy');
      }
      TermsAndCondition["Status"] = element.rolestatusname;
      TermsAndConditionList.push(TermsAndCondition);
    });
    return TermsAndConditionList;
  }
}


