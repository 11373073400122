<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_stateform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Province</h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple title="Save" icon="pi pi-save" type="submit" class=""
                                    [disabled]="isDisabledSave"></button>
                                <button pButton pRipple title="Clear" icon="pi pi-trash" type="button"
                                    (click)="reset($event)" class="p-button-danger"
                                    [disabled]="isDisabledClear"></button>
                                <!--27. on clicking back button , it Navigates to the search screen -->
                                <button pButton pRiple title="Back to Search" icon="pi pi-search" type="button"
                                    (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <!--3. Country name dropdown should load from country master -->
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_countries" name="countryid"
                                        [disabled]="this._action == 'edit'" formControlName="countryid"
                                        optionLabel="countryname" optionValue="countryid">
                                    </p-dropdown>
                                    <label for="country"> Country <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_stateform.get('countryid').touched || _submitted) && _stateform.get('countryid').errors?.SelectCountryName">
                                    Please select country name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <!--4. if "State code" is not given, show error as "Please Enter State code" -->
                                <span class="p-float-label">
                                    <input type="text" pInputText name="statecode" formControlName="statecode" />
                                    <label for="statecode"> Enter Province Code <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_stateform.get('statecode').touched || _submitted) && _stateform.get('statecode').errors?.StateCodeRequired">
                                    Please enter province code
                                </span>
                            </td>
                            <td style="width: 20%;">
                            </td>
                            <td style="width: 20%;">
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <!--5. if "State name" is not given, show error as "Please Enter State name" -->
                                <span class="p-float-label">
                                    <input type="text" pInputText name="statename" formControlName="statename" />
                                    <label for="statename"> Enter Province Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_stateform.get('statename').touched || _submitted) && _stateform.get('statename').errors?.StateNameRequired">
                                    Please enter province name
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_statestatus" name="statestatus"
                                        formControlName="statestatus" optionLabel="metasubdescription"
                                        optionValue="metasubcode"></p-dropdown>
                                    <label for="statestatus"> Status <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_stateform.get('statestatus').touched || _submitted) && _stateform.get('statestatus').errors?.SelectStatus">
                                    Please select province status
                                </span>
                            </td>
                            <td></td>
                            <td></td>

                            <td>
                                <!-- Debug -->
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>


                        </tr>

                    </table>

                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>