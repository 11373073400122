import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import * as ace from "ace-builds";

import { RptaccountledgerService } from 'src/app/core/Services/reports/Accounting/RptAccountLedger/rptaccountledger.service';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Port } from 'src/app/core/Models/Port';
import { vContainerNo, vDateSelect, vSupplierName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Producttype } from 'src/app/core/Models/Producttype';
import { MetadataMaster } from 'src/app/core/Models/overallclass';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Accountledgerview } from 'src/app/core/Views/Accountledgerview';
import { RpttrialbalancemonthService } from 'src/app/core/Services/reports/Accounting/RptTrialBalance/rpttrialbalancemonth.service';
import autoTable from 'jspdf-autotable';
import { ExportUtility } from 'src/assets/js/export-utility';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Accountgroup } from 'src/app/core/Models/Accountgroup';

@Component({
  selector: 'app-rpttrialbalancemonth',
  templateUrl: './rpttrialbalancemonth.component.html',
  styleUrls: ['./rpttrialbalancemonth.component.css']
})
export class RpttrialbalancemonthComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rpttrialbalancform: FormGroup;
  _branchid: number;
  _userid: number;
  datepipe: any;
  _currencies: Currency[] = [];
  _Partytypes: Metadatum;
  _PartyNames: any[];
  _src: SafeResourceUrl;
  _trialbalanceObj: any;
  _fromMaxDate = new Date();
  _fromMinDate = new Date("2021-07-01")
  _ReportLists: any[] = [];
  _Action: IAction;
  cols: any[]
  _customertypelist =
    [
      {
        type: 'Credit', value: 1
      },
      {
        type: 'Default', value: 2
      },
      {
        type: 'Cash', value: 3
      }
    ];

  mindate: Date;
  maxdate: Date;
  _AccountGroups: any[] = [];
  _TempAccountGroups: any[] = [];
  _ParentAccountGroups: any[] = [];

  constructor(private utility: CommonUtilities, private _AccessRightsService: AccessRightsService,
    private fb: FormBuilder, private _RptTrialBalanceService: RpttrialbalancemonthService, private exportUtility: ExportUtility,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(118);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    if (sessionStorage['Environmentenddate'] == "null") {

      this.maxdate = new Date();
    }

    this.cols = [
      { field: 'ledgername', header: 'Account Name' },
      { field: 'openingbalancedebit', header: 'Opening Debit Balance' },
      { field: 'openingbalancecredit', header: 'Opening Credit Balance' },
      { field: 'debitamount', header: 'Debit' },
      { field: 'creditamount', header: 'Credit' },
      { field: 'closingbalancedebit', header: 'Closing Debit Balance' },
      { field: 'closingbalancecredit', header: 'Closing Credit Balance' },
    ]
    this._rpttrialbalancform = this.fb.group({
      fromdate: [''],
      todate: [''],
      currencyid: [],
      partyname: [],
      partytype: [],
      type: [],
      parentaccountgroupid: [],
      accountgroupid: [],
      includezero: [false]

    })
    this._branchid = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    this._rpttrialbalancform.controls['fromdate'].setValue(new Date());
    this._rpttrialbalancform.controls['todate'].setValue(new Date());
    this._rpttrialbalancform.controls['currencyid'].setValue(1);
    this._rpttrialbalancform.controls["type"].disable();
    this._RptTrialBalanceService.PageOnload({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._currencies = resultJSON.currencies || [];
      this._Partytypes = resultJSON.partytypeList;
      this._TempAccountGroups = resultJSON.accountgroups;
      this._ParentAccountGroups = resultJSON.parentAccountgroups;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onchangepartytype(event) {
    this._rpttrialbalancform.get("partyname").reset();
    var partytype = event.value;
    if (partytype == null && partytype == 0) {
      this._CustomExceptionService.handleError("Please Select Party Type");
      return;
    }

    var type = this._rpttrialbalancform.get("type").value || 1;

    if (partytype == 104) // CUSTOMER
    {
      this._rpttrialbalancform.controls['type'].setValue(1);
      this._rpttrialbalancform.controls["type"].enable();
    }
    else {
      this._rpttrialbalancform.controls["type"].disable();
      this._rpttrialbalancform.controls['type'].setValue(0);
    }

    let data = { "partytype": partytype, "userid": this._userid, "type": type }

    this._IsProgressSpinner = true;
    this._RptTrialBalanceService.onchangepartytype(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._PartyNames = resultJSON.accountnameList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    //25.If clear clicking all fields should clear in grid
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this._rpttrialbalancform.reset();
    var dt = new Date();
    dt.setDate(dt.getDate() - 7);
    this._rpttrialbalancform.controls['fromdate'].setValue(new Date());
    this._rpttrialbalancform.controls['todate'].setValue(new Date());
    this._rpttrialbalancform.controls['currencyid'].setValue(1);
    this._PartyNames = [] = [];
    this._ReportLists = [];
    this._rpttrialbalancform.controls["type"].disable();
  }

  GenerateReport(event) {
    var BranchID = parseInt(sessionStorage["currentbranchid"]);
    var _fromdate = this._rpttrialbalancform.get("fromdate").value;
    var _todate = this._rpttrialbalancform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    let currencyid = this._rpttrialbalancform.controls['currencyid'].value;
    let parentaccountgroupid = this._rpttrialbalancform.controls['parentaccountgroupid'].value;
    let accountgroupid = this._rpttrialbalancform.controls['accountgroupid'].value;
    let includezero = this._rpttrialbalancform.controls['includezero'].value;

    this._trialbalanceObj =
    {
      "effectivefrom": fromdate,
      "effectiveto": todate,
      "branchid": BranchID,
      "userid": this._userid,
      "currencyid": currencyid,
      "parentgroupid": parentaccountgroupid || [],
      "accountgroupid": accountgroupid || [],
      "includezero": includezero || false
    
    }
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._RptTrialBalanceService.Print(this._trialbalanceObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  // Starting point
  exportExcel() {
    let trialbalanceLists = this.GettrialbalanceList();
    this.exportUtility.exportExcel(trialbalanceLists, 'Trail Balance Report', 'xlsx');
  }

  ExportToPdf() {
    let trialbalanceLists = this.GettrialbalanceList();
    const data = trialbalanceLists.map(Object.values);
    const head = [['Account Name', 'Opening Debit Balance', 'Opening Credit Balance', 'Debit', 'Credit', 'Closing Debit Balance', 'Closing Credit Balance'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          // theme: 'grid',
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
            // fontSize: 15,
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Trail Balance Monthly Report.pdf');
      })
    })
  }

  GettrialbalanceList() {
    let trialbalanceLists = [];
    this._ReportLists.forEach(element => {
      let trialbalance: any = {};
      trialbalance["Account Name"] = element.ledgername;
      trialbalance["Opening Debit Balance"] = element.openingbalancedebit;
      trialbalance["Opening Credit Balance"] = element.openingbalancecredit;
      trialbalance["Debit"] = element.debitamount;
      trialbalance["Credit"] = element.creditamount;
      trialbalance["Closing Debit Balance"] = element.closingbalancedebit;
      trialbalance["Closing Credit Balance"] = element.closingbalancecredit;
      trialbalanceLists.push(trialbalance);
    });
    return trialbalanceLists;
  }

  onchangetype(event) {
    this._rpttrialbalancform.get("partyname").reset();
    var type = event.value;
    var partytype = this._rpttrialbalancform.get("partytype").value;

    if (partytype == null && partytype == 0) {
      this._CustomExceptionService.handleError("Please Select Party Type");
      return;
    }
    let data = { "partytype": partytype, "userid": this._userid, "type": type }
    this._IsProgressSpinner = true;
    this._RptTrialBalanceService.onchangepartytype(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      console.log(resultJSON);
      this._PartyNames = resultJSON.accountnameList;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onchangeparentgroup(event) {
    this._IsProgressSpinner = true;
    var parentgroupid = event.value;

    if (parentgroupid) {
      this._AccountGroups = this._TempAccountGroups.filter(f => parentgroupid.includes(f.accountparentgroupid));
    }
    this._IsProgressSpinner = false;
  }
}
