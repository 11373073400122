<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Quote Finalize </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" type="button" *ngIf="_action != 'view'" (click)="onSave()" [disabled]="isDisabledSave"></button>
                            <button pButton pRipple label="" title="Clear" icon="pi pi-trash" (click)="reset($event)" *ngIf="_action != 'view'" class="p-button-danger" [disabled]="isDisabledClear"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" [disabled]="isDisabledSendtoapproval" (click)="SendToApproval($event)"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search" (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_quotefinalizeform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="quotefinalizeddate"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy"
                                        [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="quotefinalizeddate"> Quote Finalize Date <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <!-- Rule17:if "Material code" is not select, show error as "Please select material code" -->
                                <span class="text-danger" *ngIf="(_submitted || _quotefinalizeform.get('quotefinalizeddate').touched) && _quotefinalizeform.get('quotefinalizeddate').errors?.SelectQuoteFinalizedDate">
                                    Please Select Quote Finalize Date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_quotefinalizeform">
                                    <span class="p-float-label">
                                        <p-autoComplete formControlName="productid" [suggestions]="filteredProducts"
                                        (completeMethod)="filterProducts($event)" field="productname" (onSelect)="OnProductNameChange($event)">
                                        <ng-template let-product pTemplate="item">
                                            <div>{{product.productname}}</div>
                                        </ng-template>
                                    </p-autoComplete>

                                        <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                    </span>
                                    <!-- Rule18:if "Material code" is not select, show error as "Please select material code" -->
                                    <span class="text-danger" *ngIf="(_quotefinalizeform.get('productid').touched || _submitted) && _quotefinalizeform.get('productid').errors?.ProductNameRequired">
                                        Please Select Product
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table [value]="_quotesupplierdetails" [rows]="15" [paginator]="true" [rowsPerPageOptions]="[15,25,50,100]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                        [globalFilterFields]="['suppliername','productname','quoterequestno','currencyname']" [rowHover]="true" dataKey="quoterequestno" [showCurrentPageReport]="true" [(selection)]="selectedsupplierDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 100px;">
                                    Select Quote
                                </th>
                                <th>Supplier Name</th>
                                <th>Product Name</th>
                                <th>Variant</th>
                                <th>Quotation No.</th>
                                <th>Quotation Date.</th>
                                <th>Quantity</th>
                                <th>UOM</th>
                                <th>Unit Price(Quoted)</th>
                                <th>Currency</th>
                                <th>Remarks</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_quotesupplierdetail let-ri="rowIndex">
                            <tr>
                                <td style="text-align: center;width: 100px;">
                                    <p-checkbox [(ngModel)]="_quotesupplierdetail.isselectedquote" [value]="_quotesupplierdetail" [disabled]="isDisabledcheckbox" binary="true"></p-checkbox>
                                </td>
                                <td>
                                    {{_quotesupplierdetail.suppliername}}
                                </td>
                                <td>
                                    {{_quotesupplierdetail.productname}}
                                </td>
                                <td>
                                    {{_quotesupplierdetail.variantname}}
                                </td>
                                <td>
                                    {{_quotesupplierdetail.quoterequestno}}
                                </td>
                                <td>
                                    {{_quotesupplierdetail.quoterequestdate | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_quotesupplierdetail.quantity}}
                                </td>
                                <td>
                                    {{_quotesupplierdetail.uomname}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_quotesupplierdetail.unitprice" [disabled]="_quotesupplierdetail.isdisable || _action == 'view'" *ngIf="_action != 'view'" pInputText (keyup)="changeunitprice(_quotesupplierdetail)">
                                            <!-- [disabled]="_quotesupplierdetail.unitprice" -->
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_quotesupplierdetail.unitprice}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_quotesupplierdetail.currencyname}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" [(ngModel)]="_quotesupplierdetail.remarks" *ngIf="_action != 'view'" pInputText>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_quotesupplierdetail.remarks}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <!-- <table class="normal-table">
                        <tr>
                            <td>
                                <button pButton pRipple  label="Send To Approval"
                                    (click)="SendToApproval($event)" icon="las la-check"></button>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table> -->

                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>