import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangepasswordService } from 'src/app/core/Services/users/changepassword.service';
import { User } from 'src/app/core/Models/User';
//Debug
import * as ace from "ace-builds";
import { vNewPassword, vOldPassword, vReTypePassword, vUserName } from 'src/app/core/Validators/validation';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { HotkeysService } from 'angular2-hotkeys';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-c-change-password',
  templateUrl: './c-change-password.component.html',
  styleUrls: ['./c-change-password.component.css']
})
export class CChangePasswordComponent implements OnInit {
  _changepasswordform: FormGroup;
  _submitted = false;
  _action: string = "create";
  _Action: IAction
  _IsProgressSpinner: boolean = true;
  _Username: User;
  _Defaultrole = sessionStorage["defaultRole"];
  //Debug
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(private fb: FormBuilder, private _router: Router, private _ChangePasswordService: ChangepasswordService, private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService, private _hotkeysService: HotkeysService, private messageService: MessageService) {

  }
  InitializeForm() {
    this._changepasswordform = this.fb.group({
      username: ['', vUserName],
      oldpassword: ['', vOldPassword],
      newpassword: ['', vNewPassword],
      retypepassword: ['', vReTypePassword]
    });
    this._changepasswordform.controls['username'].setValue(sessionStorage["username"]);
  }
  ngOnInit(): void {
    // this._Action = this._AccessRightsService.getAccessRights(11);
    this._IsProgressSpinner = false;
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._hotkeysService.reset();
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._ChangePasswordService.PageOnLoad().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Username = resultJSON.users;
    });
    this._changepasswordform.controls['username'].setValue(sessionStorage["username"]);

    if (this._Defaultrole != 1) {
      this._changepasswordform.get("username").disable();
    }

  }
// Changepassword(){
//   alert("ok")
//   if(this._changepasswordform.get("newpassword").value.match(new RegExp('((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})')))
//   {
//     alert("ok")
//   }
  
// }


  onSave() {
    this._submitted = true;
    if (this._changepasswordform.valid) {
      var Username = this._changepasswordform.get("username").value;
      var OldPassword = this._changepasswordform.get("oldpassword").value;
      var Newpassword = this._changepasswordform.get("newpassword").value;
      var Retypepassword = this._changepasswordform.get("retypepassword").value;

      if (Retypepassword.length < 7) {
        var errMsg = null;
        errMsg = "Password Should have Atleast 7 Characters";
        this.ShowErrorMsg(errMsg);
        return;
      }
      if (Newpassword == Retypepassword) {
        this._IsProgressSpinner = true;
        var obj={
          "username":Username,
          "oldpassword":OldPassword,
          "newpassword":Newpassword,
          "retypepassword":Retypepassword
        }
        this._ChangePasswordService.edit(obj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Password_Success_00);
            this._IsProgressSpinner = false;
            this.reset(null);
            sessionStorage["SessionID"] = "";
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }


      else {

        this._CustomExceptionService.handleError(usererrors.Password_Validate);
        return;
      }
    }
  }
  //  15.clear all the fields which the data has entered 
  reset(event) {
    this._submitted = true;
    this._changepasswordform.reset();
    this.InitializeForm();
    this._submitted = false;
  }
  //Debug
  //  onEd()
  //  {
  //    ace.config.set("fontSize", "14px");
  //    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
  //    const aceEditor = ace.edit(this.editor.nativeElement);
  //    aceEditor.getSession().setMode("ace/mode/json");
  //    var myObj = this._changepasswordform.value;
  //    var myJSON = JSON.stringify(myObj);
  //    aceEditor.session.setValue(myJSON);

  //  }

  //For Array Error Msg:
  ShowErrorMsg(errMsg) {
    if (!errMsg)
      return false;
    this.messageService.add({ severity: 'error', summary: 'Error Message', sticky: true, detail: errMsg });
    return true;
  }

}






