import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Tripstartclosure } from 'src/app/core/Models/Tripstartclosure';
import { Vehicle } from 'src/app/core/Models/Vehicle';
import { TripstartService } from 'src/app/core/Services/inventory/tripstart.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { vVehicleNumber, vVehicleNumberSelect } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Employee } from 'src/app/core/Models/Employee';
import { Vehicleassistance } from 'src/app/core/Models/VehicleAssistance';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUpload } from 'primeng/fileupload';
import { ConfirmationService } from 'primeng/api';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';


@Component({
	selector: 'app-c-tripstart',
	templateUrl: './c-tripstart.component.html',
	styleUrls: ['./c-tripstart.component.css']
})
export class CTripstartComponent implements OnInit {
	_tripstartform: FormGroup;
	_tripstartassiantanceform: FormGroup;
	_tripform: FormGroup;
	_Tripstart: any
	_submitted = false;
	selectedTripStartSrockDetailsRows: any[] = [];
	documentselect: string = "";
	_action: string = "create";
	_Vehicleno: Tripstartclosure[];
	_DriverName: Employee[];
	_previousodometerreading: number
	_tripstartclosureid: number;
	_TripStartObj: any;
	_TripStartSrockDetailsList: any[] = [];
	_IsonstarttripDisable: boolean = true;
	_IsonclosetripDisable: boolean = true;
	_IsProgressSpinner: boolean = true;
	isDisabledClear = false;
	_selectedDrivernameIndex: number[] = [];
	_SelectedAssitance: Vehicleassistance[] = [];
	isDisabledShow = true;
	_LastAddedDocument: File;
	_selectedDocuments: File[] = [];
	documentimage: SafeUrl;
	TripStartSrockOpj: any;
	_LastAddedGridDocument: File;
	_Action: IAction;
	disablestartingkm: boolean = false;
	disableendingkm: boolean = false;

	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	constructor(private fb: FormBuilder, private _DomSanitizationService: DomSanitizer,
		private _AccessRightsService: AccessRightsService,
		private _router: Router, private utility: CommonUtilities, private _CustomExceptionService: CustomExceptionService,
		private _TripstartService: TripstartService, private confirmationService: ConfirmationService, private _hotkeysService: HotkeysService) {
		this._tripstartclosureid = history.state?.tripstartclosureid ? history.state?.tripstartclosureid : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this.HotKeyIntegration();
	}
	InitializeForm() {
		this._tripstartform = this.fb.group({
			tripstartclosureid: [0],
			vehicleid: ['', vVehicleNumberSelect],
			referenceno: [],
			drivername: [],
			assistantname: [],
			lastodometerreading: [],
			odometerreading: [],
			averagekm: [],
			tripstartclosurestatus: [],
			remarks: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			vehicleassistances: [],
			tripstartclosureno: [],
			receivername: [],
			receivermobileno: [],
			// Tripstartclosuredetails :[],
			tripstartclosuredetails: [],
			startkm: [],
			endkm: []
		});
		this._tripstartassiantanceform = this.fb.group({
			uniqueid: [0],
			tripstartclosureid: [0],
			assistanceid: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: []
		});
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(52);
		this.utility.pageLoadScript();
		this.InitializeForm();
		this._IsProgressSpinner = true;
		this._TripstartService.PageOnLoad(this._tripstartclosureid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			//console.log("pageload", resultJSON);
			if (this._action == 'create') {
				this._Vehicleno = resultJSON.tripstartclosurevehiclenos;
			}
			else {
				this._Vehicleno = resultJSON.vehicles;
			}

			this._DriverName = resultJSON.employees;
			this._IsProgressSpinner = false;
			if (this._action == 'create') {
				// this._tripstartform.controls['startkm'].setValue(1.000);
				// this.disablestartingkm = true;
				this.disableendingkm = true;
				this._tripstartform.get("referenceno").disable();
				this._tripstartform.get("odometerreading").disable();
				this._tripstartform.get("drivername").disable();
				//this._tripstartform.get("assistantname").disable();
				this._tripstartform.get("averagekm").disable();
				this._IsonclosetripDisable = true;
				this._IsonstarttripDisable = false;
			}
			if (this._action == 'edit') {
				this.disablestartingkm = true;
				this.disableendingkm = false;
			}
			if (this._action == 'view') {
				this.disablestartingkm = true;
				this.disableendingkm = true;
			}
			if (this._action == 'edit' || this._action == 'view') {
				this.isDisabledShow = false;
				const updateJSON = JSON.parse(JSON.stringify(result));
				//console.log("update pageload", updateJSON);
				this._tripstartform.setValue(updateJSON.tripstartclosure);
				this._previousodometerreading = updateJSON.tripstartclosure.odometerreading;
				this._tripstartform.controls['drivername'].setValue(parseInt(updateJSON.tripstartclosure.drivername));
				this._TripStartSrockDetailsList = updateJSON.getTripStartSrockDetailsList
				//this._DriverName = updateJSON.vehicleassistances;

				//Multiselect Assistance ids

				var assistanceIds: number[] = [];
				Object.keys(updateJSON.vehicleassistances).map((Index) => {
					assistanceIds.push(updateJSON.vehicleassistances[Index].assistanceid);
				});
				this._selectedDrivernameIndex = assistanceIds;
				this._SelectedAssitance = updateJSON.vehicleassistances
				//this._tripstartform.controls['assistantname'].setValue(parseInt(updateJSON.tripstartclosure.assistantname));
				// var DriverID = (this._DriverName || []).filter(f => f.employeeid == updateJSON.tripstartclosure.driverid);
				// this._tripstartform.controls['driverid'].setValue(DriverID);
			}
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		if (this._action == 'view') {
			this._tripstartform.disable();
			this.isDisabledClear = true;
		}
		if (this._action == 'edit') {
			this._tripstartform.get("vehicleid").disable();
			this._tripstartform.get("referenceno").disable();
			this._tripstartform.get("odometerreading").enable();
			this._tripstartform.get("drivername").disable();
			//this._tripstartform.get("assistantname").disable();
			this._tripstartform.get("averagekm").disable();
			this._IsonstarttripDisable = true;
			this._IsonclosetripDisable = false;
			this.isDisabledClear = true;
		}
	}
	ChangeOdometerReading(event) {
		var currentodometerreading = this._tripstartform.get("odometerreading").value;
		if (this._previousodometerreading >= currentodometerreading) {
			this._CustomExceptionService.handleError("Odometer Reading should be greater than Previous Odometer Reading");
		}
	}
	ChangeVehicle(event): void {
		var DriverId = (this._Vehicleno || []).filter(f => f.vehicleid == event.value)[0].driverid;
		this._tripstartform.controls['drivername'].setValue(DriverId);

		// var AssistantId = (this._Vehicleno || []).filter(f => f.vehicleid == event.value)[0].assistantid;
		// this._tripstartform.controls['assistantname'].setValue(AssistantId);

		var Referenceno = (this._Vehicleno || []).filter(f => f.vehicleid == event.value)[0].referenceno;
		this._tripstartform.controls['referenceno'].setValue(Referenceno);

		var Odometerreading = (this._Vehicleno || []).filter(f => f.vehicleid == event.value)[0].odometerreading;
		this._tripstartform.controls['odometerreading'].setValue(Odometerreading);

		var Averagekm = (this._Vehicleno || []).filter(f => f.vehicleid == event.value)[0].averagekm;
		this._tripstartform.controls['averagekm'].setValue(Averagekm);
	}
	OnStartTrip() {
		this._submitted = true;
		//console.log(this._tripstartform.value);
		if (this._tripstartform.valid) {
			this._Tripstart = this._tripstartform.getRawValue();
			//console.log(this._Tripstart);
			if (this._action == 'create') {
				//console.log("YEs");
				this._Tripstart.createdon = new Date().toLocaleString();
				// this._Tripstart.modifiedon =new Date().toLocaleString();
				this._Tripstart.createdby = sessionStorage["userid"];
				this._Tripstart.modifiedby = sessionStorage["userid"];
				// this.onEd();
				this._TripStartObj = { "Tripstartclosure": this._Tripstart, "Vehicleassistance": this._SelectedAssitance }
				this._IsProgressSpinner = true;
				this._TripstartService.create(this._TripStartObj).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._IsProgressSpinner = false;
					if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
						this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
						this._IsProgressSpinner = false;
						this.reset(null);
					}
					else {
						this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
					} this._IsProgressSpinner = false;
				},

					error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
		}
	}
	getAssitanceList(event) {
		var l_Assitantces: Vehicleassistance[] = [];
		var l_Assitantce: Vehicleassistance;

		Object.keys(event.value).map((Index) => {
			l_Assitantce = new Vehicleassistance();
			l_Assitantce.uniqueid = 0;
			l_Assitantce.tripstartclosureid = this._tripstartform.get("tripstartclosureid").value;
			l_Assitantce.assistanceid = event.value[Index];
			l_Assitantce.createdon = new Date();
			l_Assitantce.createdby = sessionStorage["userid"];
			l_Assitantce.modifiedby = sessionStorage["userid"];
			l_Assitantce.modifiedon = new Date();
			l_Assitantces.push(l_Assitantce);
		})
		this._SelectedAssitance = l_Assitantces;
	}
	OnCloseTrip() {
		this._submitted = true;
		//console.log(this._tripstartform.value);
		var currentodometerreading = this._tripstartform.get("odometerreading").value;
		if (this._previousodometerreading >= currentodometerreading) {
			this._CustomExceptionService.handleError("Odometer Reading should be greater than Previous Odometer Reading");
			return;
		}
		if (this._tripstartform.valid) {
			this._Tripstart = this._tripstartform.getRawValue();
			//var CityOBJ = { "City": this._City };
			//console.log(this._Tripstart);
			if (this._action == 'edit') {
				this._Tripstart.modifiedon = new Date().toLocaleString();
				this._Tripstart.modifiedby = sessionStorage["userid"];

				var receivername = this._tripstartform.get("receivername").value;
				var receivermobileno = this._tripstartform.get("receivermobileno").value;

				// if (!receivername) {
				// 	this._CustomExceptionService.handleError("Please Enter Receiver Name");
				// 	return;
				// }

				// if (!receivermobileno) {
				// 	this._CustomExceptionService.handleError("Please Enter Receiver Mobile No");
				// 	return;
				// }

				if (this._TripStartSrockDetailsList.length != this._selectedDocuments.length) {

					this.confirmationService.confirm({
						message: 'Re-confirming to close the trip Check whether PODs have been uploaded!',
						accept: () => {
							this.tripclose();
						}
					});
				} else { this.tripclose(); }
			}
		}
	}
	tripclose() {
		this._IsProgressSpinner = true;
		this._TripStartObj = this.formconverstion(this._TripStartSrockDetailsList,
			this._Tripstart,
			this._selectedDocuments);
		this._TripstartService.edit(this._TripStartObj).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
				this._IsProgressSpinner = false;
			}
			else {
				this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
			} this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	reset(event) {
		this._submitted = true;
		this._tripstartform.reset();
		this.InitializeForm();
		this._submitted = false;
	}
	goBack(event) {
		this._router.navigate(['/vTripstart']);
	}
	// onEd() {
	//   ace.config.set("fontSize", "14px");
	//   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
	//   const aceEditor = ace.edit(this.editor.nativeElement);
	//   aceEditor.getSession().setMode("ace/mode/json");
	//   var myObj = this._Tripstart;
	//   var myJSON = JSON.stringify(myObj);
	//   aceEditor.session.setValue(myJSON);
	// }
	// Create
	HotKeyIntegration() {
		this._hotkeysService.reset();
		if (this._action != 'view') {
			// this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
			//   this.onSave();
			//   return false; // Prevent bubbling
			// }, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		if (this._action == 'create') {
			this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
				this.reset(null);
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
			this.goBack(null);
			return false; // Prevent bubbling
		}, ['INPUT', 'SELECT', 'TEXTAREA']));
	}
	showdocumentDialog(document: FileUpload, position: string, _TripStartview: any) {
		let url = null;
		if (_TripStartview.attachmenturl) {
			url = _TripStartview.attachmenturl;
		}
		if (!url)
			return;

		window.open(url, "MsgWindow", "width=800,height=800");
	}



	onDocumentSelect(event, document, _TripStartview: any) {
		this._LastAddedDocument = event.currentFiles[0];
		_TripStartview.attachmenturl = URL.createObjectURL(this._LastAddedDocument);
		_TripStartview.attachmentname = this._LastAddedDocument.name;
		this._selectedDocuments.push(this._LastAddedDocument);
		document.clear()
	}
	onDocumentClear(_TripStartview: any) {
		this._LastAddedDocument = null
		_TripStartview.attachmenturl = "";
		_TripStartview.attachmentname = "";
	}
	formconverstion(_TripStartSrockDetailsList: any[], _Tripstart: Tripstartclosure, documents: File[]) {
		let time = new Date();
		const formData: FormData = new FormData();
		//TimeStamp Add:
		for (var x in documents) {
			var index = _TripStartSrockDetailsList.findIndex(obj => obj.attachmentname == documents[x].name);
			if (index != -1) {
				var NewFileName = time.getTime() + '_' + documents[x].name;
				_TripStartSrockDetailsList[index].attachmentname = NewFileName;
				_TripStartSrockDetailsList[index].attachmenturl = NewFileName;
				formData.append('files', documents[x], NewFileName);
			}
		}
		this._Tripstart.tripstartclosuredetails = _TripStartSrockDetailsList
		this.TripStartSrockOpj = {
			// "Myordershistory": this.selectedTripStartSrockDetailsRows,
			// // "Branchid": this._branchid,
			// // "Tellerid": this.Tellerid,
			// // "AmountCollectedDate":
			"Tripstartclosure": this._Tripstart,
			"Vehicleassistance": this._SelectedAssitance,
			// "TripStartSrockDetailsList":_TripStartSrockDetailsList
		};
		var TripStartSrockList = JSON.stringify(this.TripStartSrockOpj);
		formData.append('data', TripStartSrockList);
		return (formData);
	}

}
