<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Stock Adjustment</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple label="" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>

                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate" [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="Fetchall()" title="Search" icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <!--29. Grid should contain :action, Cycle No, Stock Take No, Date, Stock Takers Name, Product, Closing Stock, Stock Take, Different, Rate, Varient -->
                    <p-table #dt [value]="_productdetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['stocktakeno','stocktakedate','cycleno','stocktaker','statusname','createdbyname','createdon']" [(selection)]="selectedrows"
                        [rowHover]="true" dataKey="stocktakeid" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:130px;text-align: center;">Action</th>
                                <th [hidden]="true"></th>
                                <th pSortableColumn="stocktakeno">Stock Take No
                                    <p-sortIcon field="stocktakedate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="stocktakedate">Stock Take Date
                                    <p-sortIcon field="stocktakedate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="cycleno">Cycle No
                                    <p-sortIcon field="cycleno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="stocktaker">Team Lead
                                    <p-sortIcon field="stocktaker">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="stocktaker">Stock Taker 1
                                    <p-sortIcon field="stocktaker">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="stocktaker">Stock Taker 2
                                    <p-sortIcon field="stocktaker">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="statusname">Status
                                    <p-sortIcon field="statusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_productdetail let-ri="rowIndex">
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_productdetail)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" [disabled]="!_Action._Update" [disabled]="_productdetail.status !='MSC00045'" (click)="edit(_productdetail)"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Delete" class="p-button p-button-danger" (click)="confirm(_productdetail)" [disabled]="!_Action._Delete || _productdetail.status !='MSC00045'"></button>
                                    <button pButton pRipple icon="las la-print" title="Print" [disabled]="_productdetail.status != 'MSC00047'" class="p-button-warning p-mr-2" [disabled]="!_Action._Print" (click)="Print(_productdetail)"></button>

                                </td>
                                <td [hidden]="true">
                                    {{_productdetail.stocktakeid}}
                                </td>
                                <td>
                                    {{_productdetail.stocktakeno}}
                                </td>
                                <td>
                                    {{_productdetail.stocktakedate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_productdetail.cycleno}}
                                </td>
                                <td>
                                    {{_productdetail.teamlead}}
                                </td>
                                <td>
                                    {{_productdetail.stocktaker1}}
                                </td>
                                <td>
                                    {{_productdetail.stocktaker2}}
                                </td>
                                <td>
                                    {{_productdetail.statusname}}
                                </td>
                                <td>
                                    {{_productdetail.createdbyname}}
                                </td>
                                <td>
                                    {{_productdetail.createdon|date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
                <p-toast position="bottom-right"></p-toast>
            </div>
        </div>
    </div>
</div>