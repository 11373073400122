<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Sales Commission Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptsalescommissionform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                        dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate" [yearNavigator]="true" yearRange="2000:2099">
                                    </p-calendar>
                                    <label for="fromdate">From Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                        dateFormat="dd/mm/yy" [yearNavigator]="true" [minDate]="mindate" [maxDate]="maxdate" yearRange="2000:2099">
                                    </p-calendar>
                                    <label for="todate">To Date <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_salestypelist"
                                        optionValue="metasubcode" optionLabel="metasubdescription"
                                        formControlName="salestypeid" [filter]="true" filterBy="productname">
                                    </p-dropdown>
                                    <label for="salestypename">Sales Man Type <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect optionLabel="username" [options]="SalesMans" optionValue="userid"
                                        [virtualScroll]="true" itemSize="30" selectedItemsLabel="{0} items selected"
                                        [filter]="true" [(ngModel)]="_selectedSalesMans"
                                        [ngModelOptions]="{standalone: true}" displaySelectedLabel="true">
                                    </p-multiSelect>
                                    <label for="username">Sales Man <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect optionLabel="branchname" [options]="_Branch" optionValue="branchid"
                                        [virtualScroll]="true" itemSize="30" selectedItemsLabel="{0} items selected"
                                        [filter]="true" [(ngModel)]="_selectedBranch"
                                        [ngModelOptions]="{standalone: true}" displaySelectedLabel="true">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatus == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="salestypename">Branch <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText [(ngModel)]="Currency"
                                        [ngModelOptions]="{standalone: true}">
                                    <label for="servicerequestno">Currency</label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="SalesCommissionDetails" [scrollable]="true" scrollHeight="450px" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['username','salestypename','customername','salesinvoiceno','salesinvoiceamount','amountinusd','currencyname','commissionamount']"
                        [rowHover]="true" dataKey="salesinvoiceno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 80px;" pSortableColumn="sno">S. No
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="customername">Customer Name
                                    <p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="customername">Branch
                                    <p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="salesinvoiceno">Invoice Date
                                    <p-sortIcon field="salesinvoiceno">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="salesinvoiceno">Invoice No
                                    <p-sortIcon field="salesinvoiceno">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="salesinvoiceamount">Invoice Amt
                                    <p-sortIcon field="salesinvoiceamount">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="currencyname">Invoice Currency
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="amountinusd">Amt In USD
                                    <p-sortIcon field="amountinusd">
                                    </p-sortIcon>
                                </th>
                                <th style="text-align: center;" pSortableColumn="commissionamount">Commission Amt In USD
                                    <p-sortIcon field="commissionamount">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_SalesCommissionDetail let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 80px;">
                                    {{i+ 1}}
                                </td>
                                <td>
                                    {{_SalesCommissionDetail.customername }}
                                </td>
                                <td style="text-align: center;">
                                    {{_SalesCommissionDetail.branchname }}
                                </td>
                                <td style="text-align: center;">
                                    {{_SalesCommissionDetail.salesinvoicedate |date:'dd/MM/yyyy'}}
                                </td>
                                <td style="text-align: center;">
                                    {{_SalesCommissionDetail.salesinvoiceno }}
                                </td>
                                <td style="text-align: right;">
                                    {{_SalesCommissionDetail.salesinvoiceamount | number : '1.2'}}
                                </td>
                                <td style="text-align: center;">
                                    {{_SalesCommissionDetail.currencyname }}
                                </td>
                                <td style="text-align: right;">
                                    {{_SalesCommissionDetail.amountinusd| number : '1.2' }}
                                </td>
                                <td style="text-align: right;">
                                    {{_SalesCommissionDetail.commissionamount| number : '1.2' }}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="9" class="text-right">Total Commission Amount : {{totalCommissionAmount}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>