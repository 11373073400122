import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KeyPress {
  constructor() { }

  kpNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  kpMobileNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    let plusSymbol = ((<string>event.target.value || '').indexOf('+') >= 0 && charCode == 43);
    if (charCode > 31 && ((charCode != 43 && charCode < 48) || charCode > 57) || plusSymbol) {
      return false;
    }
    // if (plusSymbol)
    //   return false;

    return true;
  }

  kpDecimalInput(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    let decimalDot = ((<string>event.target.value || '').indexOf('.') > 0 && charCode == 46);
    if (charCode > 31 && (charCode < 48 || charCode > 57) && decimalDot) {
      return false;
    }
    return true;
  }

}
