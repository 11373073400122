<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Account Closing</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple icon="pi pi-plus" title="Add"
                                class="p-button p-button-success p-mr-2" [disabled]="!_Action._Add"
                                (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>

                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_accountsclosingview" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]"
                        [globalFilterFields]="['month','openingbalance','closingbalance','credit','debit']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="id"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">

                        <ng-template pTemplate="header">
                            <tr>

                                <th style="text-align: center;" pSortableColumn="Action">Action<p-sortIcon
                                        field="price"></p-sortIcon>
                                </th>
                                <th pSortableColumn="month"> Month <p-sortIcon field="month"></p-sortIcon>
                                </th>
                                <th pSortableColumn="openingbalance">Opening Balance <p-sortIcon field="openingbalance">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="closingbalance">Closing Balance<p-sortIcon field="closingbalance">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="credit">Credit<p-sortIcon field="credit">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="debit">Debit<p-sortIcon field="debit">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_accountclosingview>
                            <tr>

                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="!_Action._View" (click)="view(_accountclosingview)"></button>
                                    <button pButton pRipple icon="las la-times" title="Cancel"
                                        class="p-button p-button-danger p-mr-2" [disabled]="!_Action._Delete"
                                        (click)="confirm(_accountclosingview)"></button>
                                </td>
                                <td>
                                    {{_accountclosingview.month | date:'dd-MM-yyyy'}}
                                </td>
                                <td>
                                    {{_accountclosingview.openingbalance }}
                                </td>
                                <td>
                                    {{_accountclosingview.closingbalance }}
                                </td>
                                <td>
                                    {{_accountclosingview.creditamount }}
                                </td>
                                <td>
                                    {{_accountclosingview.debitAmount }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>