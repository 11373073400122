export class Orderstatusemaillog {
    logid: number;
    customername: string;
    tomail: string;
    orderid: number ;
    orderno: string;
    orderstatus: string;
    logstatusid: number;
    logstatus: string;
    recordstatus: string;
    createdby: string;
    createdon: Date ;
    modifiedby: string;
    modifiedon: Date ;
    orderstatusname: string;
}