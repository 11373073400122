export class Salesinvoicerewarddetail {
    salesinvoicerewardid: number;
    salesinvoiceid: number;
    salesinvoicedate: Date;
    customerid: number;
    effectivefrom: Date;
    effectiveto: Date;
    expirydate: Date;
    rewardpoints: number ;
    createdby: number;
    createdon: Date;
    rewardstatus: string;

}