import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { productsCattegory } from 'src/app/core/Models/overallclass';
import { Producttype } from 'src/app/core/Models/Producttype';
import { vBranchMultiNameSelect } from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { RptstockmovementsummaryService } from 'src/app/core/Services/reports/Stock/RptStockMovementSummary/rptstockmovementsummary.service';
import autoTable from 'jspdf-autotable';
import { Brand } from 'src/app/core/Models/Brand';
@Component({
  selector: 'app-rptstockmovementsummary',
  templateUrl: './rptstockmovementsummary.component.html',
  styleUrls: ['./rptstockmovementsummary.component.css']
})
export class RptstockmovementsummaryComponent implements OnInit {
  _producttype: Producttype[];
  _productcategory: productsCattegory[];
  _productname: any[];
  _stocksummaryform: FormGroup;
  _Stocksummaryviews: any[];
  _branch: Branch[];
  selectedrows: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _IsProgressSpinner: boolean = true;
  _ProductwisestockObj: any;
  cols: any[];
  _submitted = false;
  _userid: number;
  _brandlist: any;
  _Tempbrandlist: Brand;
  branchid: number[] = [];
  mindate: Date;
  maxdate: Date;

  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private utility: CommonUtilities,
    private _StockSummaryService: RptstockmovementsummaryService,
    private _CustomExceptionService: CustomExceptionService,
    private exportUtility: ExportUtility
  ) { }
  InitializeStocksummaryForm() {
    this._stocksummaryform = this.fb.group({
      producttypeid: [''],
      productcategoryid: [''],
      productid: [''],
      fromdate: [],
      todate: [],
      branchid: ['', vBranchMultiNameSelect],
      brandid: [''],
    });
    if (sessionStorage['Environmentenddate'] == "null") {
      this._stocksummaryform.get("fromdate").setValue(new Date);
      this._stocksummaryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._stocksummaryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._stocksummaryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this._userid = sessionStorage["userid"];
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeStocksummaryForm();
    this.cols = [
      { field: 'branchname', header: 'Branch Name' },
      { field: 'productname', header: 'Product Name' },
      { field: 'brandname', header: 'Brand Name' },
      { field: 'updatedopeningstock', header: 'Recon Opening Stock' },
      { field: 'aprilstock', header: 'April 10th Stock' },
      { field: 'grnqty', header: 'GRN Qty' },
      { field: 'damagedqty', header: 'Damaged Qty' },
      { field: 'returnqty', header: 'Return Qty' },
      { field: 'invoiceqty', header: 'Invoice Qty' },
      { field: 'deliveryqty', header: 'Delivery Qty' },
      { field: 'adjustedstock', header: 'Adjusted Qty' },
      { field: 'prestock', header: 'Previous Day Stock' },
      { field: 'currentstock', header: 'Closing Stock' },
      { field: 'stockdiff', header: 'Stock Diff' }
    ]
    this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
    this._IsProgressSpinner = true;
    this._StockSummaryService.PageOnLoad().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._producttype = resultJSON.producttypes;
      this._branch = resultJSON.branches;
      this._brandlist = resultJSON.brandList;
      this._Tempbrandlist = resultJSON.brandList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductcategory(event) {
    var selectedProductType = event.value;
    var branchid = this._stocksummaryform.get("branchid").value || sessionStorage["currentbranchid"];
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productname = [];
      this._stocksummaryform.controls["productcategoryid"].reset();
      return;
    }
    this._IsProgressSpinner = true;
    this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": branchid || 0 };
    this._StockSummaryService.getProductCategories(this._ProductTypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      let categories = resultJSON.productcategories;
      let uniqueChars = [...new Set(categories)];
      console.log([...new Set(categories)]);
      uniqueChars = categories.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.productcategoryid === test.productcategoryid
        ));
      this._productcategory = uniqueChars;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductname(event) {
    var selectedProductCategory = event.value;
    var branchid = this._stocksummaryform.get("branchid").value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._stocksummaryform.controls["productid"].reset();
      this._productname = [];
      return;
    }
    this._IsProgressSpinner = true;
    this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": branchid };
    this._StockSummaryService.getProducts(this._ProductCategoryObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      let products = resultJSON.productdetails;
      let uniqueChars = [...new Set(products)];
      uniqueChars = products.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.productid === test.productid
        ));
      this._productname = uniqueChars;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear() {
    this._submitted = true;
    this._stocksummaryform.reset();
    this.InitializeStocksummaryForm();
    this._submitted = false;
    this._Stocksummaryviews = [];
    this._productcategory = [];
    this._productname = [];
    this._brandlist = this._Tempbrandlist;
    this._stocksummaryform.controls['branchid'].setValue([1]);
  }
  search() {
    this._submitted = true;
    if (this._stocksummaryform.valid) {
      var _fromdate = this._stocksummaryform.get("fromdate").value;
      var _todate = this._stocksummaryform.get("todate").value;
      var datePipe = new DatePipe("en-US");
      var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
      var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
      if (fromdate > todate) {
        this._CustomExceptionService.handleError("From Date should be less than the To Date");
        return;
      }
      var branchid = this._stocksummaryform.get("branchid").value || [];
      var brandid = this._stocksummaryform.get("brandid").value || [];
      var productid = this._stocksummaryform.get("productid").value || [];
      var producttypeid = this._stocksummaryform.get("producttypeid").value || [];
      var productcategoryid = this._stocksummaryform.get("productcategoryid").value || [];
      this._IsProgressSpinner = true;
      this._ProductwisestockObj = {
        "ProductId": productid || [], "branchid": branchid || [], "fromdate": fromdate,
        "todate": todate, "brandid": brandid, "producttypeid": producttypeid || [], "productcategoryid": productcategoryid || []
      };
      this._StockSummaryService.getProductwise(this._ProductwisestockObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Stocksummaryviews = resultJSON.stockdetails;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  exportExcel() {
    let StocksummaryList = this.GetStocksummaryList();
    this.exportUtility.exportExcel(StocksummaryList, 'Stocksummary', 'xlsx');
  }
  ExportToPdf() {
    let StocksummaryList = this.GetStocksummaryList();
    const data = StocksummaryList.map(Object.values);
    const head = [['Branch Name', 'Product Name', 'Brand Name', 'Recon Opening Stock', 'April 10th Stock', 'GRN Qty', 'Damaged Qty', 'Return Qty', 'Invoice Qty ', 'Delivery Qty', 'Adjusted Qty', 'Previous Day Stock', 'Current Stock', 'Stock Diff'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Stock Movement Summary.pdf');
      })
    })
  }
  GetStocksummaryList() {
    let StocksummaryList = [];
    this._Stocksummaryviews.forEach(element => {
      let stocktsummary: any = {};
      stocktsummary["Branch Name"] = element.branchname;
      stocktsummary["Product Name"] = element.productname;
      stocktsummary["Brand Name"] = element.brandname;
      stocktsummary["Recon Opening Stock"] = element.updatedopeningstock;
      stocktsummary["April 10th Stock"] = element.aprilstock;
      stocktsummary["GRN Qty"] = element.grnqty;
      stocktsummary["Damaged Qty"] = element.damagedqty;
      stocktsummary["Return Qty"] = element.returnqty;
      stocktsummary["Invoice Qty"] = element.invoiceqty;
      stocktsummary["Delivery Qty"] = element.deliveryqty;
      stocktsummary["Adjusted Qty"] = element.adjustedstock;
      stocktsummary["Pre Day Stock"] = element.prestock;
      stocktsummary["Current Stock"] = element.currentstock;
      stocktsummary["Stock Diff"] = element.stockdiff;
      StocksummaryList.push(stocktsummary);
    });
    return StocksummaryList;
  }
  ChangeProduct(event) {
    var selectedProduct = event.value;
    var branchid = this._stocksummaryform.get("branchid").value || [];
    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._brandlist = this._Tempbrandlist
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductwisestockObj = { "Products": selectedProduct || [], "branchid": branchid || [] };
      this._StockSummaryService.getBrand(this._ProductwisestockObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        let productbrand = resultJSON.productbrandlist;
        let uniqueChars = [...new Set(productbrand)];
        uniqueChars = productbrand.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.brandid === test.brandid
          ));
        this._brandlist = uniqueChars;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
}