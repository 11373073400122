export class Salesquotationothercharge {
    salesquotationotherchargesdetailsid: number;
    salesquotationid: number;
    otherchargesid: number;
    otherchargesamount: number;
    createdby: number;
    createdon: Date;
    modifiedby: number;
    modifiedon: Date;
    accountingyear: number;

}
