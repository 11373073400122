import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { District } from 'src/app/core/Models/District';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { CityService } from 'src/app/core/Services/masters/city.service';
import { Districtview } from 'src/app/core/Views/Districtview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-city',
  templateUrl: './v-city.component.html',
  styleUrls: ['./v-city.component.css']
})
export class VCityComponent implements OnInit {
  _IsProgressSpinner: boolean = false;
  _District: District;
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _delete: Boolean;
  _update: Boolean;
  _View: Boolean;
  _districtview: Districtview[]=[];
  _Action: IAction;
  _districtid: number;
  _userid = sessionStorage["userid"];
  constructor(
    private fb: FormBuilder,
    private exportUtility: ExportUtility,
    private _CityService: CityService,
    private router: Router,
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    private _hotkeysService: HotkeysService,
    private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(12);
    this.utility.pageLoadScript();
    this.HotKeyIntegration()
    this._IsProgressSpinner = true;
    this._CityService.FetchAll().subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._districtview = resultJSON.districtview;
      this._IsProgressSpinner = false;
    });
  }
  onRowSelect(event) { }
  onRowUnselect(event) { }
  view(event) {
    this.router.navigate(['/cCity'], { state: { districtid: event.districtid, action: 'view' } });
  }
  edit(event) {
    this.router.navigate(['/cCity'], { state: { districtid: event.districtid, action: 'edit' } });
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.router.navigate(['/cCity']);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  confirm(event) {
    this._districtid = event.districtid;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this city <b>"' + event.districtname + '"</b> ?',
      accept: () => {
        this.Cancel(event);
      }
    });
  }
  Cancel(event) {
    this._IsProgressSpinner = true;
    this._CityService.Cancel(this._districtid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_04);
        this._CityService.FetchAll().subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._districtview = resultJSON.districtview;
          this._IsProgressSpinner = false;
        });
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'City', 'xlsx');
  }
  ExportToPdf() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.ExportToPdf(AssetRegistrationList, 'City.pdf');
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    this._districtview.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Country"] = element.countryname;
      assetregistration["Province Name"] = element.statename;
      assetregistration["City Code"] = element.districtcode;
      assetregistration["City Name"] = element.districtname;
      assetregistration["Status"] = element.districtstatusname;
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
}