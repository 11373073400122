import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentreturnStatusService {
  public _PaymentReturnService = environment.PaymentReturnStatusService;
  public _httpOptions;

  constructor(private http: HttpClient) { }
  PageOnload(): Observable<any> {
    var Query = this._PaymentReturnService + '/PageOnLoad';
    return this.http.get<{ data: any }>(Query);
  }
  SearchPayment(fromdate: string, todate: string,ordercanceltype:string,modeofpayment:string): Observable<any> {
    var Query = this._PaymentReturnService + '/SearchPayment/' + fromdate + '/' + todate+'/'+ordercanceltype+'/'+modeofpayment;
    return this.http.get<{ data: any }>(Query);
  }

  // PaymentReturnStatusUpdate
  Update(Paymentreturnstatusupdateobj: object[]) {
    var Query = this._PaymentReturnService ;
    return this.http.post<{ data: any }>(Query, Paymentreturnstatusupdateobj);
  }
}
